import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IAdminNavItem, IAdminNavSubItem } from '../../interfaces/index';

import { SharedDashboardService } from '../../../portal-shared/services/shared-dashboard.service';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'admin',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {
  public managerPortalId: number = 14;
  public tabContainerName = 'administrator';
  public title: string = 'App Administration';
  public navItems$: BehaviorSubject<IAdminNavItem[]> = new BehaviorSubject([]);

  constructor(
    public activeRoute: ActivatedRoute,
    public adminService: AdminService,
    public sharedDashboardService: SharedDashboardService
  ) {}

  ngOnInit() {
    this.activeRoute.url.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public toggleItem(item: IAdminNavItem): void {
    item.expanded = !item.expanded;
  }

  public openTab(subItem: IAdminNavSubItem): void {
    this.sharedDashboardService.openDashboardTab(this.managerPortalId, subItem.label, subItem.componentName, 'app-admin');
  }

  newRequest() {
    this.navItems$.next(this.adminService.getAdminNavItems());
  }

}
