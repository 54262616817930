
export interface IJobScheduleJobParameter
{
  jobScheduleJobParameterId: number;
  jobScheduleJob_jobScheduleJobId: number;
  jobParameter_jobParameterId: number;
  parameterValue: string;

  //ExtraData
  jobId: number;
  contextList: string;
  parameterDisplayName: string;
  allowMultiSelect: boolean;
  isEntity: boolean;
}

export class JobScheduleJobParameter implements IJobScheduleJobParameter {

  constructor(
    public jobScheduleJobParameterId: number = 0,
    public jobScheduleJob_jobScheduleJobId: number = 0,
    public jobParameter_jobParameterId: number = 0,
    public parameterValue: string = '',
    public jobId: number = 0,
    public contextList: string = '',
    public parameterDisplayName: string = '',
    public allowMultiSelect: boolean = false,
    public isEntity: boolean = false,
  ) { }
}
