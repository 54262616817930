import { Component, Input, Output, EventEmitter,  Inject } from '@angular/core';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

@Component({
    selector: 'fw-crud-buttons',
    templateUrl: './fw-crud-buttons.component.html'
})
export class CrudButtonsComponent  {

    @Input() operation: string;
    @Input() disabled: boolean;
     @Input() showNext: boolean = true;

    //do not use the emitter service, want these events only consumed by the container component
    @Output() public cancelEvent = new EventEmitter();
    @Output() public createEvent = new EventEmitter();
    @Output() public saveEvent = new EventEmitter<string>();

    constructor(
        @Inject(appConstants) public myConstants: IAppConstants) { }

    public onCancel() {
         this.cancelEvent.emit();
      }

    public onCreate() {
        this.createEvent.emit();
      }

    public onSave(action: string) {
      this.saveEvent.emit(action);
    }

}
