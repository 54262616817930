export interface IPayout {
  payoutId: number;
  provider_providerId: number;
  payoutName: string;
  payoutDescription: string;
  payoutStatus_lookupValueId: number;
  controlsCapacity: boolean;
  unitMeasure_unitMeasureId: number;
  glCodeOverride: string;
  serviceCodeOverride: string;

  //extra data
  payoutEffectiveDate: string;
}

export class Payout implements IPayout {
  payoutId: number;
  provider_providerId: number;
  payoutName: string;
  payoutDescription: string;
  payoutStatus_lookupValueId: number;
  controlsCapacity: boolean;
  unitMeasure_unitMeasureId: number;
  glCodeOverride: string;
  serviceCodeOverride: string;
  payoutEffectiveDate: string;

  constructor(providerId: number, defaultStatusId: number = -1) {
    this.payoutId = 0;
    this.provider_providerId = providerId;
    this.payoutName = '';
    this.payoutDescription = '';
    this.payoutStatus_lookupValueId = defaultStatusId;
    this.controlsCapacity = false;
    this.unitMeasure_unitMeasureId = 0;
    this.glCodeOverride = '';
    this.serviceCodeOverride = '';
  }
}


