<div class="schedule-editor" #topOfEditor>
  <fw-component-title [title]="title" class="schedule-editor__title"></fw-component-title>
  <div *ngIf="installerWarning" class="schedule-editor__warning alert-box alert-box--danger">
    {{ installerWarning }}
  </div>
  <div class="schedule-editor__avail" [ngClass]="{'schedule-editor__hide': screenIsSmall && activePage !== 1 }">
    <div class="schedule-editor__avail__for">
      <div class="schedule-editor__avail__for__range">
        <label>1 Week Range</label>
        <fw-switch-button class="schedule-editor__avail__for__range__val"
                          [defaultChecked]="false"
                          [disabled]="false"
                          [title]="'Click to toggle between pulling for 1 week or 1 day'"
                          (toggleEvent)="onRangeChange($event)">
        </fw-switch-button>
      </div>
      <div class="schedule-editor__avail__for__duration">
        <label>Duration</label>
        <div class="schedule-editor__avail__for__duration__val">
          <select #durationTypeSel (change)="onDurationTypeChange(durationTypeSel.value)">
            <option [selected]="durationType === 'Days'" value="Days">Days</option>
            <option [selected]="durationType === 'Hours'" value="Hours">Hours</option>
            <option [selected]="durationType === 'Minutes'" value="Minutes">Minutes</option>
          </select>
          <ng-container *ngIf="durationType !== 'Minutes'">
            <input #durationNbr type="text"
                   class="schedule-editor__avail__for__duration__val--nbr"
                   digitOnlyMask
                   [pattern]="durationPattern"
                   [placeholder]="1"
                   title="Between 1 and {{durationType === 'Days' ? 7 : 23 }}"
    [value]="duration"
    (change)="onDurationNbrChange(durationNbr.value)" />
    </ng-container>
    <ng-container *ngIf="durationType === 'Minutes'">
      <select #durationSel (change)="onDurationSelChange(durationSel.value)">
        <option [selected]="duration === 15" value="15">15</option>
        <option [selected]="duration === 30" value="30">30</option>
        <option [selected]="duration === 45" value="45">45</option>
      </select>
    </ng-container>
  </div>
      </div>
    </div>
    <div class="schedule-editor__avail__find">
      <button type="button"
              class="app-btn app-btn--primary"
              title="Click to Find Availability"
              [disabled]="!startDateTime"
              (click)="findAvailability()">
        Find Availability
      </button>
    </div>
    <div class="schedule-editor__avail__calendar">
      <schedule-calendar [durationType]="durationType"
                         [duration]="duration"
                         [availability]="availability"
                         [pullLength]="pullLength"
                         [defaultStart]="currentSchedule ? currentSchedule.scheduleStartDate : null"
                         (customEvent)="onCustom($event)"></schedule-calendar>
    </div>

    <div class="schedule-editor__avail__timespan" *ngIf="durationType !== 'Days' && daySelected && daySelected.availability">
      <schedule-timespan [durationType]="durationType"
                         [duration]="duration"
                         [pullLength]="pullLength"
                         [daySelected]="daySelected"
                         (customEvent)="onCustom($event)"></schedule-timespan>
    </div>
  </div>
  <div class="schedule-editor__summary" [ngClass]="{'schedule-editor__hide': screenIsSmall && activePage !== 3 }">
    <div *ngIf="operation === myConstants.operationTypeEdit">
      <div class="schedule-editor__summary__title">Existing Schedule</div>
      <schedule [schedule]="currentSchedule"
                [showButtons]="false"
                [canIEdit]="false">
      </schedule>
    </div>
    <div *ngIf="!firstRequest && (workingSchedule$ | async) as workingSchedule">
      <div class="schedule-editor__summary__title"
           [ngClass]="{'schedule-editor__summary__title--new': !unavailableReason, 'schedule-editor__summary__title--cannot': unavailableReason }">
        {{ !unavailableReason ? 'New ' : 'Cannot ' }} Schedule
      </div>
      <schedule [schedule]="workingSchedule"
                [showButtons]="false"
                [canIEdit]="false"
                [unavailableReason]="unavailableReason"
                [customCardCss]="unavailableReason ? 'schedule--cannot' : 'schedule--new' "
                [customIconCss]="unavailableReason ? 'schedule__date--icon--cannot': 'schedule_date--icon--new' "
                [nonWorking]="hideInstallers">
      </schedule>
    </div>
  </div>
  <div *ngIf="!firstRequest" class="schedule-editor__installers" [ngClass]="{'schedule-editor__hide': screenIsSmall && activePage !== 2 }">
    <div class="schedule-editor__summary__mini" [ngClass]="{'schedule-editor__hide': !screenIsSmall }">
      <div *ngIf="operation === myConstants.operationTypeEdit" class="schedule-editor__installers__mini-schedule">
        <div class="schedule-editor__summary__title schedule-editor__summary__title--mini">Existing Schedule</div>
        <schedule [schedule]="currentSchedule"
                  [showButtons]="false"
                  [canIEdit]="false"
                  [miniMe]="true">
        </schedule>
      </div>
      <div *ngIf="(workingSchedule$ | async) as workingSchedule" class="schedule-editor__installers__mini-schedule">
        <div class="schedule-editor__summary__title schedule-editor__summary__title--mini"
             [ngClass]="{'schedule-editor__summary__title--new': !unavailableReason, 'schedule-editor__summary__title--cannot': unavailableReason }">
          {{ !unavailableReason ? 'New ' : 'Cannot ' }} Schedule
        </div>
        <schedule [schedule]="workingSchedule"
                  [showButtons]="false"
                  [canIEdit]="false"
                  [unavailableReason]="unavailableReason"
                  [customCardCss]="unavailableReason ? 'schedule--cannot' : 'schedule--new' "
                  [customIconCss]="unavailableReason ? 'schedule__date--icon--cannot': 'schedule_date--icon--new' "
                  [miniMe]="true"
                  [nonWorking]="hideInstallers">
        </schedule>
      </div>
    </div>

    <div [ngClass]="{ 'loading-mask': requestingInstallers}">
      <schedule-installers *ngIf="startDateTime && !requestingInstallers"
                           [availableInstallers]="availableInstallers"
                           [workOrderId]="workOrder.workOrderId"
                           [startDate]="startDateTime"
                           [duration]="duration"
                           [durationType]="durationType"
                           [scheduleId]="operation === myConstants.operationTypeCreate ? 0 : currentSchedule.scheduleId"
                           [unavailableReason]="unavailableReason"
                           [hideInstallers]="hideInstallers"
                           (customEvent)="onCustom($event)">
      </schedule-installers>
    </div>
  </div>

  <div *ngIf="screenIsSmall" class="schedule-editor__pager">
    <button class="app-btn-icon app-btn-icon--primary schedule-editor__pager--left"
            title="Previous"
            [disabled]="activePage === 1"
            (click)="page('prev')">
      <i class="fad fa-arrow-circle-left"></i>
    </button>
    <button class="app-btn-icon app-btn-icon--primary schedule-editor__pager--right"
            title="Next"
            [disabled]="firstRequest || (durationType !== typeDays && !timeSet)  || (hideInstallers && activePage === 2) || activePage === 3"
            (click)="page('next', topOfEditor)">
      <i class="fad fa-arrow-circle-right"></i>
    </button>
  </div>
</div>
<div class="app-btn-box schedule-editor__btns">
  <button type="button"
          class="app-btn app-btn--cancel"
          title="Cancel"
          (click)="cancel()">
    Cancel
  </button>
  <button type="button"
          class="app-btn app-btn--primary"
          [title]="operation === myConstants.operationTypeCreate ? 'Create' : 'Save' "
          [disabled]="!startDateTime || firstRequest || unavailableReason  || !availableInstallers || !availableInstallers.length || (screenIsSmall && activePage !== 3)"
          (click)="save()">
    {{ operation === myConstants.operationTypeCreate ? 'Create' : 'Save'   }}
  </button>
</div>
