import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IManagerTool } from '../../interfaces/i-manager-tool';
import { ManagerToolEvent } from '../../enums/manager-tool-event.enums';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'manager-tools',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './manager-tools.component.html'
})
export class ManagerToolsComponent implements OnInit {
  public mgrTools: IManagerTool[] = [];

  constructor(public modalService: ModalService) { }

  ngOnInit(): void {
    this.loadTools();
  }

  onClick(event: ManagerToolEvent): void {
    switch (event) {
      case ManagerToolEvent.createPO:
        this.createPo();
        break;
      default:
        break;
    }
  }

  createPo(): void {
    this.modalService.open({
      title: 'Create Purchase Order',
      path: 'create-purchase-order',
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.large
    });
  }

  loadTools(): void {
    this.mgrTools.push({
      icon: 'fad fa-ballot app-btn-icon--custom-3',
      stackedIcon: 'fas fa-plus app-icon--success',
      title: 'Create Purchase Order',
      description: 'Create a new purchase order and add it to a new project',
      event: ManagerToolEvent.createPO
    });
  }
}
