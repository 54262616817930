<div *ngIf="workOrderId" class="portal">
  <div class="portal__detail--item" [ngClass]="{ 'loading-mask': !(workOrder$ | async) || !(providerLocations$ | async) }">
    <work-order-general-information *ngIf="(workOrder$ | async) && (providerLocations$ | async)"
                                    [workOrder]="workOrder$ | async"
                                    [storeName]="storeName"
                                    [displayFields]="woDisplayFields"
                                    [canIEdit]="canIEdit$ | async"
                                    [operation]="woOperation$ | async"
                                    [staleData]="hasStaleData$ | async"
                                    [errorData]="errorData$ | async"
                                    [locations]="providerLocations$ | async"
                                    [services]="services$ | async"
                                    (locationChangeEvent)="onLocationChange($event)"
                                    (customEvent)="onCustom($event)">
    </work-order-general-information>
    <fw-error-box [errorData]="customError$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
    <fw-error-box *ngIf="hasPendingCreate$ | async" [errors]="myConstants.errorPendingCreateEntity"></fw-error-box>
  </div>

  <div class="portal__detail--item" *ngIf="(canPushToAccounting$ | async) && !(hasPendingCreate$ | async)">
    <work-order-push-container [workOrder]="workOrder$ | async" [canIEdit]="canIEdit$ | async">
    </work-order-push-container>
  </div>

  <div class="portal__detail--item" [ngClass]="{ 'loading-mask': !(workOrder$ | async) }">
    <schedule-manager *ngIf="(workOrder$ | async) as workOrder"
                      [workOrder]="workOrder"
                      (customEvent)="onScheduleChange($event)">
    </schedule-manager>
  </div>

  <ng-container *ngIf="(aqtChangesCount$ | async) as aqtChangesCount">
    <div *ngIf="aqtChangesCount > 0" class="portal__detail--item">
      <fw-component-title title='Work Order Finalized Approvals'></fw-component-title>
      <project-aqt-changes [projectId]="projectId" [workOrderId]="workOrderId"> </project-aqt-changes>
    </div>
  </ng-container>

  <div class="portal__detail--item">
    <fw-component-title [title]="'Work Order Line Items'"></fw-component-title>
    <project-work-order-items [workOrderId]="workOrderId"
                              [canIEdit]="canIEdit$ | async">
    </project-work-order-items>
  </div>
</div>

