import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { take  } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { IListDefinition, ListDefinition, IListColumn, ListColumn, IListFilter, ListFilter, IListButtonType } from '../../../../../../fw/dynamic-list/interfaces/index';
import { SearchType } from '../../../../../../fw/dynamic-list/enums/search-type.enums';
import { ButtonType } from '../../../../../../fw/fw-shared/enums/button-type.enum';

//store actions and reducers
import * as fromRoot from '../../../../../store/reducers/index';
import * as fromAuth from '../../../../../../auth/store/auth.reducer';
import { DynamicListService } from '../../../../../../fw/dynamic-list/services';
import { appConstants, IAppConstants } from '../../../../../../shared/constants';

@Component({
  selector: 'user-schedules',
  templateUrl: './user-schedules.component.html'
})
export class UserSchedulesComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;
  authState: fromAuth.IState;
  subscription: Subscription = new Subscription();

  constructor(public rootStore: Store<fromRoot.IState>,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
      this.subscription.add(this.rootStore.select('auth')
        .pipe(take(1))
        .subscribe((authState: fromAuth.IState) => {
          this.authState = authState;
          this.loadListDefinition();
        }));
    }

   loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Schedules';
    const listObjectController = 'JobSchedule';
    const listStoreName = 'userJobSchedules';
    const detailRoutePath = 'user-schedule'; 
    const listRowKeyId = 'jobScheduleId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [{ term: 'jobScheduleType', value: 'Job', searchType: SearchType.Equals, columnName: 'jobScheduleType', fieldType: this.myConstants.dataTypeString }];
    defaultListFilter.getAll = true;

    let listDefinition = this.dynamicListService.createListDefinition('commonDetailOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'Index';
    listDefinition.methodParameters = this.authState.providerUser.currentUserId.toString();
    listDefinition.showFilter = false;

    let crudButtons: IListButtonType[] = [{ type: ButtonType.delete, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    this.listDefinition = listDefinition;
  }

  /*
      Define the columns in the list to be displayed.  Columns pulled back are set by the db.
      May change this to be defined in the db.
  */
  loadListColumns(): IListColumn[] {
      let columnDefinitions: Array<IListColumn> = [];

      let colDef = new ListColumn('isActive');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('jobScheduleName');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('jobScheduleDescription');
      columnDefinitions.push(colDef);

      return columnDefinitions;
    }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }

}
