/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit,  OnDestroy, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import {  tap, map, take, filter} from 'rxjs/operators';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IProject, IWorkOrder, IWorkOrderItem, IPurchaseOrder, IPurchaseOrderItem, IChargeBack } from '../../../view-models/index';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { IErrorData } from '../../../../../shared/interfaces';

import { UserPriviledgesService } from '../../../../../auth/services/index';
import { ProjectService } from '../../services/project.service';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as fromDynamicObject from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { IListObjectData, IDynamicListState} from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import {  getProviderId } from '../../../../../auth/store/index';
import { getListByType, listDataExists } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { ProjectListStore } from '../../enums/project.enums';
import { DomainObjectService } from '../../../../../shared/services';

//Component is routed to
@Component({
  selector: 'project-detail-summary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project-detail-summary.component.html'
})
export class ProjectDetailSummaryComponent implements OnInit, OnDestroy {
  public project$: Observable<IProject>;
  public wos$: Observable<IWorkOrder[]>;
  public woItems$: Observable<IWorkOrderItem[]>;
  public pos$: Observable<IPurchaseOrder[]>;
  public poItems$: Observable<IPurchaseOrderItem[]>;
  public cbs$: Observable<IChargeBack[]>;
  public hasUnreceivedInventory$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public reqdDocsOpen$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public  projectId: number;
  public warrantyId: number;
  public isWarranty$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEdit$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEditCbs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEditSansStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public providerId$: Observable<number>;
  public acctErrors$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  projectStoreName: string = 'projectInformation';
  dispatchedUnreceived: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(    
      public rootStore: Store<fromRoot.IState>,
      public store: Store<fromFeature.IAllDynamicData>,
      public userPriviledgesService: UserPriviledgesService,
      public projectService: ProjectService,
      public dos: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get('id');
      this.dispatchedUnreceived = false;
      this.getData();
    });

    this.providerId$ = this.rootStore.pipe(select(getProviderId));

    //Listen to project data change.
    this.project$.subscribe((data: IProject) => {
      if (data && !this.dispatchedUnreceived) {
        this.dispatchUnreceivedInventory();
      }
    })
  }

  public onSectionToggle(isOpen: boolean): void {
    this.reqdDocsOpen$.next(isOpen);
  }

  getData(): void {
    this.project$ = this.store.pipe(
      select(fromDynamicObject.getObjectDataById(this.projectStoreName, this.projectId)),
      tap((objData: IProject) => {
        if (objData) {
          this.warrantyId = objData.warranty_warrantyId;
          this.isWarranty$.next(objData.warranty_warrantyId && objData.warranty_warrantyId > 0 ? true : false);
          this.canIEdit$.next(this.userPriviledgesService.canIEdit(objData) && objData.currentProjectStatusCode !== this.myConstants.projectStatusClosed);
          this.canIEditSansStatus$.next(this.userPriviledgesService.canIEdit(objData));
          this.canIEditCbs$.next(this.userPriviledgesService.canIEdit(objData) && this.userPriviledgesService.manageWarrantyProjects$.value);
        }
      }));

    this.wos$ = this.store.pipe(select(getListByType(ProjectListStore.projectWorkOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map((state: IListObjectData) => {
          if (state && state.event && state.event && (!state.errorData || !state.errorData.length)) {
            this.projectService.dispatchRequiredDocumentsGet(this.projectId, this.canIEditSansStatus$.value);
            this.projectService.emitTaskReloadEvent('project-detail-summary', this.projectId);
         }
         return state && state.data ? state.data : [];
        }));

    this.woItems$ = this.store.pipe(select(getListByType(ProjectListStore.projectWorkOrderItems)))
      .pipe(
        map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map((state: IListObjectData) => {
          return state && state.data ? state.data : [];
        }));

    this.pos$ = this.store.pipe(select(getListByType(ProjectListStore.projectPurchaseOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map((objData: IListObjectData) => {
          if (objData && objData.event && (!objData.errorData || !objData.errorData.length)) {
            this.projectService.dispatchWorkOrders('purchase-order-summary', this.projectId, this.canIEdit$.getValue(), false);
            this.projectService.dispatchPurchaseOrders('purchase-order-summary', this.projectId, this.canIEdit$.getValue());
         }
          const pos: IPurchaseOrder[] = objData && objData.data ? objData.data : [];
          this.setPoErrors(pos);
          return pos;
        }));

    this.poItems$ = this.store.pipe(select(getListByType(ProjectListStore.projectPurchaseOrderItems)))
      .pipe(
        filter((listsState: IDynamicListState) => listsState.objData['parentId'] === this.projectId),
        map((listsState: IDynamicListState) => listsState[0]),
        map((state: IListObjectData) => {
          return state && state.data ? state.data : [];
        }));

    this.cbs$ = this.store.pipe(select(getListByType(ProjectListStore.projectChargeBacks)))
      .pipe(
        filter((listsState: IDynamicListState) => listsState.objData['parentId'] === this.projectId),
        map((listsState: IDynamicListState) => listsState[0]),
        map((state: IListObjectData) => {
          return state && state.data ? state.data : [];
        }));

    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectUnreceivedInventory)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.data) {
          this.hasUnreceivedInventory$.next(state.data.length > 0);
        }
      }));
  }

  //Get Unreceived, if needed
  dispatchUnreceivedInventory(): void {
    //check to see if need to go get po inv data
    //const unReceivedDef: IListDefinition = this.projectService.loadProjectUnreceivedInventoryListDefinition(this.projectId, this.canIEdit$.getValue());
    this.subscription.add(this.store.select(listDataExists(ProjectListStore.projectUnreceivedInventory, this.projectId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe((nodata) => {
        this.projectService.dispatchUnreceivedInventoryGet(this.projectId, this.canIEdit$.value, true);
      }));


  }

  setPoErrors(pos: IPurchaseOrder[]): void {
    let errorData: IErrorData[] = [];

    if (pos && pos.length) {
      pos.forEach(po => {
        if (po.purchaseOrderAccntSyncLog) {
          let value: string[] = [];
          value.push(po.purchaseOrderNumber.toString().concat(': ', po.purchaseOrderAccntSyncLog));
          errorData.push({ 'key': '__Model', 'value': value });
        }
      })
    };
    const current = this.acctErrors$.getValue();
    this.acctErrors$.next(errorData);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
