/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {  Observable } from 'rxjs';
import {  map} from 'rxjs/operators';

import { IWorkOrderItem, IPurchaseOrderItem, IMarginResults } from '../../../view-models/index';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import {  getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import {  IDynamicListState } from '../../../../../fw/dynamic-list/store';

@Component({
  selector: 'project-margins-container',
  templateUrl: './project-margins-container.component.html',
})
export class ProjectMarginsContainerComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() providerMarginHigh: number;
  @Input() providerMarginLow: number;
  @Input() reload: boolean;

  @Output() public marginSetEvent = new EventEmitter<IMarginResults>();

  public poItems$: Observable<IPurchaseOrderItem[]>;
  public woItems$: Observable<IWorkOrderItem[]>;

  poItemStoreName: string = 'projectPurchaseOrderItems';
  woItemStoreName: string = 'projectWorkOrderItems';

  constructor(public store: Store<fromFeature.IAllDynamicData>) { }

  public onMarginSet(event: IMarginResults): void {
    this.marginSetEvent.emit(event);
  }

  ngOnInit() {
    this.newRequest();
  }

  newRequest(): void {
    //listening for purchase order items store 
    this.poItems$ = this.store.pipe(select(getListByType(this.poItemStoreName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map(objData => objData && objData.data ? objData.data : null)
      );

    //listening for work order items store 
    this.woItems$ = this.store.pipe(select(getListByType(this.woItemStoreName)))
  .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
    map(objData => objData && objData.data ? objData.data : null)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] && !(changes['projectId'].firstChange)) {
      this.newRequest();
    }
  }

  ngOnDestroy() {
  }
}
