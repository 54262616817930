import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { ProjectService } from '../../../project/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'charge-backs-summary',
  templateUrl: './charge-backs-summary.component.html'
})
export class ChargeBacksSummaryComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() canIEdit: boolean;

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public projectService: ProjectService,
    public modalService: ModalService) { }

  ngOnInit() {
    this.setListDefinition();
  }

  setListDefinition(): void {
    let listDefinition = this.projectService.loadProjectChargeBackListDefinition(this.projectId, this.canIEdit, true, this.modalService.opened);
    this.listDefinition$.next(listDefinition);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] && !changes['projectId'].isFirstChange()) {
      this.setListDefinition();
    }
  }
  
}
