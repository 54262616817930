import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { cloneDeep, isArray, toString, startCase} from 'lodash';

import {
  IListColumn, IListDefinition, IListScreenSize, ISearchTerm,
  IListFilter, ListFilter, ListDefinition, ListColumn, IListComponent, IUserListFilter, IListButtonType, OrderTerm, IOrderTerm
} from '../interfaces';
import { IFieldDefinition } from '../../dynamic-forms/interfaces';
import { IEntityStatus } from '../../fw-shared/components/entity-status/interfaces/i-entity-status';
import { SearchType } from '../enums/search-type.enums';
import { DynamicListStore, DynamicListEvent, DynamicListMethod } from '../enums/dynamic-list.enum';
import { ICustomButton } from '../../fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../fw-shared/enums/button-type.enum';
import * as DynamicListActions from '../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromFeature from '../../../fw/dynamic-list/store/reducers/feature.reducer';

import { UserPriviledgesService } from '../../../auth/services/user-priviledges.service';
import { ProjectDynamicListService } from '../services/project-dynamic-list.service'; 
import { PoImportDynamicListService } from '../services/po-import-dynamic-list.service'; 
import { AdminDashDynamicListService } from '../services/admin-dash-dynamic-list.service'; 
import { ManagerDashDynamicListService } from '../services/manager-dash-dynamic-list.service'; 
import { UserDashDynamicListService } from '../services/user-dash-dynamic-list.service'; 
import { UtilitiesDynamicListService } from '../services/utilities-dynamic-list.service'; 
import { WarrantyDynamicListService } from '../services/warranty-dash-dynamic-list.service'; 
import { AdminDynamicListService } from '../services/admin-dynamic-list.service'; 
import { HomCommonUtility } from '../../../shared/services/hom-common.utility';
import { DynamicFieldService } from '../../dynamic-forms/services/dynamic-field.service';
import { HomDataUtility } from '../../../shared/services';
import { ComplianceDynamicListService } from './compliance-dynamic-list.service';
import { appConstants, IAppConstants } from '../../../shared/constants';
import { DatePipe } from '@angular/common';

export interface INavChange {
  closed: boolean;
  storeName: string;
  childOutlet: string;
}

@Injectable()
export class DynamicListService {
  constructor(public userPriviledgesService: UserPriviledgesService,
    public store: Store<fromFeature.IAllDynamicData>,
    public dynamicFieldService: DynamicFieldService,
    public projectDynamicListService: ProjectDynamicListService, 
    public poImportDynamicListService: PoImportDynamicListService, 
    public adminDashDynamicListService: AdminDashDynamicListService, 
    public managerDashDynamicListService: ManagerDashDynamicListService, 
    public userDashDynamicListService: UserDashDynamicListService, 
    public utilitiesDynamicListService: UtilitiesDynamicListService,
    public warrantyDynamicListService: WarrantyDynamicListService,
    public adminDynamicListService: AdminDynamicListService,
    public datePipe: DatePipe,
    public complianceDynamicListService: ComplianceDynamicListService,
    public homCommonUtility: HomCommonUtility,
    public dataUtils: HomDataUtility,
    @Inject(appConstants) public myConstants: IAppConstants  ) { }

  public navChange$: BehaviorSubject<INavChange> = new BehaviorSubject(null);
  public listTimeout: number = 20;  //minutes

  /*
      Returns the sort key to use for the column when the sort is not be id
      Created to handle fkey requirements.
  */
  sortKey(colKey: string) {
    let sortKey = colKey;
    if (colKey.length) {
      const objName = colKey.split('@');

      if (objName.length > 1) {
        //working with an extra data object -  modify the object's c# OrderService to handle this.
        sortKey = objName[1];
      }
    }
    return sortKey;
  }

  isObjectField(key: string) {
    const colName = key.split('@');
    return colName.length > 1;
  }

  handleCustomMethod(serviceName: string, methodName: string, row: any, portalEntityId: number): any {
    if (!serviceName || !methodName) {
      return false;
    }
    switch (serviceName) {
      case 'projectDynamicListService':
        return this.projectDynamicListService.handleCustomMethod(methodName, row, portalEntityId);
      case 'poImportDynamicListService':
        return this.poImportDynamicListService.handleCustomMethod(methodName, row, portalEntityId);
      case 'adminDashDynamicListService':
        return this.adminDashDynamicListService.handleCustomMethod(methodName, row, portalEntityId);
      case 'managerDashDynamicListService':
        return this.managerDashDynamicListService.handleCustomMethod(methodName, row, portalEntityId);
      case 'userDashDynamicListService':
        return this.userDashDynamicListService.handleCustomMethod(methodName, row, portalEntityId);
      case 'utilitiesDynamicListService':
        return this.utilitiesDynamicListService.handleCustomMethod(methodName, row, portalEntityId);
      case 'warrantyDynamicListService':
        return this.warrantyDynamicListService.handleCustomMethod(methodName, row, portalEntityId);
      case 'adminDynamicListService':
        return this.adminDynamicListService.handleCustomMethod(methodName, row, portalEntityId);
      case 'complianceDynamicListService':
        return this.complianceDynamicListService.handleCustomMethod(methodName, row, portalEntityId);
      case 'dynamicListService':
        return this.handleGenericMethod(methodName, row, portalEntityId);
      default:
        break;
    }
  }

  handleGenericMethod(methodName: string, row: any, portalEntityId: number): any {
      switch (methodName) {
        case 'hasDetailUrl':
          return this.hasDetailUrl(row);
        case 'hasUpdateUrl':
          return this.hasUpdateUrl(row);
        case 'hasNewUrl':
          return this.hasNewUrl(row);
        case 'hasDeleteUrl':
          return this.hasDeleteUrl(row);
        default:
          return false;
      }
    }

  hasNewUrl(row: any): boolean {
    if (!row || !row.hasOwnProperty('newUrl'))
      return false;

    return row.newUrl !== null && row.newUrl.length > 0;
  }

  hasDetailUrl(row: any): boolean {
    let metaData = row.hasOwnProperty('metaData') ? row['metaData'] : null;
    if (!metaData || !metaData.hasOwnProperty('crud'))
      return false;
    else if (!metaData.crud.hasOwnProperty('detailUrl'))
      return false;

    return metaData.crud.detailUrl !== null && metaData.crud.detailUrl.length > 0;
  }

  hasUpdateUrl(row: any): boolean {
    let metaData = row.hasOwnProperty('metaData') ? row['metaData'] : null;
    if (!metaData || !metaData.hasOwnProperty('crud'))
      return false;
    else if (!metaData.crud.hasOwnProperty('updateUrl'))
      return false;

    return metaData.crud.updateUrl !== null && metaData.crud.updateUrl.length > 0;
  }

  hasDeleteUrl(row: any): boolean {
    let metaData = row.hasOwnProperty('metaData') ? row['metaData'] : null;
    if (!metaData || !metaData.hasOwnProperty('crud'))
      return false;
    else if (!metaData.crud.hasOwnProperty('deleteUrl'))
      return false;

    return metaData.crud.deleteUrl !== null && metaData.crud.deleteUrl.length > 0;
  }


  hasPending(row: any): boolean {
    const hasUpdatePending: boolean = (row
            && row.hasOwnProperty('approvalQueueTransaction_approvalQueueTransactionId')
      && row['approvalQueueTransaction_approvalQueueTransactionId'] !== null);

    const hasCreatePending: boolean = (row
      && row.hasOwnProperty('metaData')
      && row['metaData'].hasOwnProperty('isPendingCreate')
      && row['metaData']['isPendingCreate']) ? row['metaData']['isPendingCreate'] : false;
    return hasUpdatePending || hasCreatePending;
  }

  canEdit(listDefinition: IListDefinition, row: any): boolean {
    return this.hasEditBtn(listDefinition);
  }

  canDelete(listDefinition: IListDefinition, row: any): boolean {
    return this.hasDeleteBtn(listDefinition);
  }

  hasCreateBtn(listDefinition: IListDefinition): boolean {
    return (!listDefinition.toolButtons
      || !listDefinition.toolButtons.length
      || listDefinition.toolButtons.find(x => x.eventName === DynamicListEvent.createRow) === undefined)
      ? false : true;
  }

  hasEditBtn(listDefinition: IListDefinition): boolean {
    return (!listDefinition.rowButtons
      || !listDefinition.rowButtons.length
      || listDefinition.rowButtons.find(x => x.eventName === DynamicListEvent.editRow) === undefined)
      ? false : true;
  }

  hasDeleteBtn(listDefinition: IListDefinition): boolean {
    return (!listDefinition.rowButtons
      || !listDefinition.rowButtons.length
      || listDefinition.rowButtons.find(x => x.eventName === DynamicListEvent.deleteRow) === undefined)
      ? false : true;
  }

  listHasPendingChildCreates(rows: any[]): boolean {
    let hasPending: boolean = false;
    if (!rows) {
      return false;
    }

    rows.forEach(x => {
      if (this.dataUtils.hasPendingChildCreate(x)) {
        hasPending = true;
      }
    });
    return hasPending;
  }

  /*Methods used to load up the column definitions used in rendering header, filter and row */
  getFieldDefinition(fieldDefinitions: IFieldDefinition[], colKey: string): IFieldDefinition {
    let fieldDef = fieldDefinitions.find(x => x.key == colKey);
    return fieldDef;
  }

  colFilterable(fieldDefinitions: IFieldDefinition[], colKey: string): boolean {
    let fieldDef = this.getFieldDefinition(fieldDefinitions, colKey);
    return fieldDef && fieldDef.filterable;
  }

  colSortable(fieldDefinitions: IFieldDefinition[], colKey: string): boolean {
    let fieldDef = this.getFieldDefinition(fieldDefinitions, colKey);
    return fieldDef && fieldDef.sortable;
  }

  //for each data row, create an object for the col
  getGridCol(listDefinition: IListDefinition, screenSize: IListScreenSize, col: IListColumn): string {
    if (!listDefinition) return '';
    let maxList = listDefinition.listColumns.filter(c => !c.onlyOnSmall);
    let largeList = listDefinition.listColumns.filter(c => c.visibleOnLarge);
    let medList = listDefinition.listColumns.filter(c => c.visibleOnMedium);
    let smallList = listDefinition.listColumns.filter(c => c.visibleOnSmall || c.onlyOnSmall);
    let idx: number = -1;

    if (screenSize.screenIsSmall) {
      idx = smallList.findIndex(x => x.key === col.key);
    } else if (screenSize.screenIsMedium) {
      idx = medList.findIndex(x => x.key === col.key);
    } else if (screenSize.screenIsLarge) {
      idx = largeList.findIndex(x => x.key === col.key);
    } else {
      idx = maxList.findIndex(x => x.key === col.key);
    }
    return (idx + 1).toString() + ' / ' + (idx + 2).toString();
  }

  isFiltered(searchTerms: ISearchTerm[], key: string): boolean {
    let filteredBy: boolean = false;
    if (searchTerms.find(x => x.columnName == key)) {
      filteredBy = true;
    }

    return filteredBy;
  }

  getSortKey(fieldDefinitions: IFieldDefinition[], colKey: string): string[] {
    const def = this.getFieldDefinition(fieldDefinitions, colKey);
    if (!def || !def.sortable) {
      return [];
    }
    let terms: string[] = def.orderTerms && def.orderTerms.length > 0 ? def.orderTerms : [def.key];
    terms.forEach((term: string) => {
      term = this.sortKey(term);
    })
    return terms;
  }

  isSingleSort(fieldDefinitions: IFieldDefinition[], colKey: string): boolean {
    const def = this.getFieldDefinition(fieldDefinitions, colKey);
    return def && def.sortable && def.singleSort ? true : false;
  }

  isVisible(screenSize: IListScreenSize, col: IListColumn): boolean {
    return (screenSize.screenisGreaterThanMax && !col.onlyOnSmall)
      || (screenSize.screenIsMax && !col.onlyOnSmall)
      || (screenSize.screenIsLarge && col.visibleOnLarge)
      || (screenSize.screenIsMedium && col.visibleOnMedium)
      || (screenSize.screenIsSmall && (col.visibleOnSmall || col.onlyOnSmall));
  }

  /*
Returns the css class to use for the row:  dynamic
*/
  rowClass(listDefinition: IListDefinition, row: any, activeDeleteId: number, rowIndex: number, portalEntityId: number, isSelected: boolean, activeOptionIndex: number, navClosed: boolean, colIdx: number = -1): string {
    let cssName = '';
    let customCss = '';
    if (row[listDefinition.rowKeyId] != activeDeleteId) {
      let entityStatus = this.getEntityStatus(row, 'Row');
      if (entityStatus != null) {
        cssName = entityStatus.severity === 'Error'
          ? 'dynamic-list__item--danger'
          : entityStatus.severity === 'Warning'
            ? 'dynamic-list__item--warning'
            : 'dynamic-list__item--info';
      }
      if (rowIndex % 2 !== 0) { cssName += ' dynamic-list--odd'; }

      //overrides
      if (listDefinition.groupSelect) {
        if (isSelected) {
          customCss = 'dynamic-list__item--selected';
        }
      }
      if (listDefinition.singleSelect) {
        if (activeOptionIndex === rowIndex) {
          customCss = 'dynamic-list__item--selected';
        }
      }
      //overrides
      if (listDefinition.rowStatus) {
        const rowCss = this.customRowClass(listDefinition, row, portalEntityId);
        customCss = customCss ? customCss : rowCss; //selected will trump custom css
      }
      if (this.hasPending(row)) {
        customCss = 'dynamic-list__item--pending';
      }
      cssName = customCss ? customCss : cssName;
    }

    if (navClosed) {
      cssName += ' dynamic-list__item--collapsed';
    }

    return cssName;
  }

  /*
Returns the entityStatus record for the requested level
*/
  getEntityStatus(row: any, level: string): IEntityStatus {
    let entityStatus: IEntityStatus = null;
    if (row.hasOwnProperty('entityStatus')) {
      let entityStatuses: IEntityStatus[] = row.entityStatus;
      entityStatus = entityStatuses.find(x => x.level === level); //find just returns first match
    }

    return entityStatus;
  }

  customRowClass(listDefinition: IListDefinition, row: any, portalEntityId: number): string {
    let methodCss = '';
    if (listDefinition.rowStatus.cssMethod && listDefinition.rowStatus.methodService) {
      let result = this.handleCustomMethod(listDefinition.rowStatus.methodService, listDefinition.rowStatus.cssMethod, row, portalEntityId);
      methodCss = result ? result : '';
    }
    return methodCss;
  }


  selectDisabled(listDefinition: IListDefinition, row: any, portalEntityId: number): boolean {
    if (!listDefinition.groupSelect || !listDefinition.groupSelect.enabled) {
      return true;
    }
    let disabled = false;
    if (listDefinition.groupSelect.enabledMethod && listDefinition.groupSelect.methodService) {
      let result = this.handleCustomMethod(listDefinition.groupSelect.methodService, listDefinition.groupSelect.enabledMethod, row, portalEntityId);
      disabled = !result ? true : false;
    }
    return disabled;
  }

  optionDisabled(listDefinition: IListDefinition, row: any, portalEntityId: number): boolean {
    if (!listDefinition.singleSelect || !listDefinition.singleSelect.enabled) {
      return false;
    }
    let disabled = null;
    if (listDefinition.singleSelect.enabledMethod && listDefinition.singleSelect.methodService) {
      let result = this.handleCustomMethod(listDefinition.singleSelect.methodService, listDefinition.singleSelect.enabledMethod, row, portalEntityId);
      disabled = !result ? true : false;
    }
    return disabled;
  }

  getFormattedRelativeDateFilterTerm(termValue: string): string {
    if (!termValue.includes(this.myConstants.userListFilterRelativeDate)) return termValue;
    const dateParts = this.dynamicFieldService.getDateNormalizedFilterTerm(termValue);
    let returnString = '';
    dateParts.dateParts.forEach(x => {
      returnString += returnString ? ' and ' : '';
      const offset = x.dateOffsetSign === '-' ? -1 * x.dateOffset : x.dateOffset;
      switch (offset) {
        case 0:
          returnString += 'Today (' + this.datePipe.transform(new Date(), 'MM/dd/yyyy').toString() + ')';
          break;
        case 1:
          returnString += 'Tomorrow (' + this.datePipe.transform(new Date().addDays(1), 'MM/dd/yyyy').toString() + ')';
          break;
        case -1:
          returnString += 'Yesterday (' + this.datePipe.transform(new Date().addDays(-1), 'MM/dd/yyyy').toString() + ')';
          break;
        default:
          returnString += Math.abs(offset) + ' days ' + (offset < 0 ? 'ago (' : 'from now (') + this.datePipe.transform(new Date().addDays(offset), 'MM/dd/yyyy').toString() + ')';
          break;
      }
    });
    return returnString
  }

  getFormattedFieldName(searchTerm: ISearchTerm): string {
    const term = searchTerm.columnName ? searchTerm.columnName.split('_') : searchTerm.term.split('_');
    return this.homCommonUtility.splitWordsByCase(startCase(term[(term.length >= 2 ? term.length - 2 : term.length - 1)]));
  }

  getFormattedFilterValue(searchType: string, formattedSearchType: string, termName: string, fieldType: string, displayValues: string, termValue: string): string {
    if (!(fieldType === this.myConstants.dataTypeDate || fieldType === this.myConstants.dataTypeDateTime || fieldType === this.myConstants.dataTypeDateTimeNull))
      return (searchType === SearchType.NoValue || !termValue) ? termName + " has No Value" : termName + formattedSearchType + ' ' + (displayValues ? displayValues : termValue);
    if (searchType === 'Range')
      formattedSearchType = ' Between';
    return (searchType === SearchType.NoValue || !termValue) ? termName + " has No Value" : termName + formattedSearchType + ' ' + this.getFormattedRelativeDateFilterTerm(displayValues ? displayValues : termValue);
  }

  getFormattedFilterTermInfo(searchTerm: ISearchTerm): string {
    let terms: string = '';
    if (searchTerm && (searchTerm.value !== '' || (isArray(searchTerm.value) && searchTerm.value.length > 0))) {
      terms += terms ? ' and ' : '';
      const searchType = searchTerm.searchType === SearchType.Default ? '' : ' ' + this.homCommonUtility.splitWordsByCase(searchTerm.searchType);
      const name: string = this.getFormattedFieldName(searchTerm);
      if (isArray(searchTerm.value)) {
        let listOfItems = searchTerm.displayValues && isArray(searchTerm.displayValues) && searchTerm.displayValues.length > 0 ? searchTerm.displayValues.join(', ') : toString(searchTerm.value);
        terms += name + searchType + ' ' + listOfItems;
      } else {
        terms += this.getFormattedFilterValue(searchTerm.searchType, searchType, name, searchTerm.fieldType, searchTerm.displayValues, searchTerm.value);
      }
    }
    return terms;
  }

  getFormattedFilterInfo(searchTerms: ISearchTerm[]): string {
    let displayReadyTerms: ISearchTerm[] = cloneDeep(searchTerms);
    if (!displayReadyTerms.length)
      return '';

    let terms: string = '';
    displayReadyTerms.forEach(item => {
      terms += terms ? ' and ' + this.getFormattedFilterTermInfo(item) : '' + this.getFormattedFilterTermInfo(item);
    });
    return terms;
  }

  getDateNormalizedUserFilterTerms(searchTerms: ISearchTerm[]): ISearchTerm[] {
    let workingCopy = cloneDeep(searchTerms);

    workingCopy.forEach((searchTerm: ISearchTerm) => {   
      const dateParts = this.dynamicFieldService.getDateNormalizedFilterTerm(searchTerm.value);
      if (dateParts.isRelativeDateTerm) searchTerm.value = dateParts.fixedTerm
    })
    return workingCopy;
  }

  dispatchUserListFiltersGet(): void {
    const listDef: IListDefinition = this.loadUserListFiltersDefinition(-1, true);
    this.store.dispatch(new DynamicListActions.SetListDefinition({ storeName: listDef.storeName, parentId: -1, listDefinition: listDef }));
    //includes the splitKey property so will call the Split List action post Set List action
    const methodParameters = this.userPriviledgesService.currentUserId$.getValue().toString() + '?listComponentId=';
    this.store.dispatch(new DynamicListActions.GetList({
      listDefinition: listDef,
      listFilter: listDef.defaultListFilter,
      parentId: listDef.parentId,
      splitOnConfig: { splitOnKey: 'listComponent_listComponentId', controllerMethod: 'ByProviderUserByStore', methodParameters: methodParameters}
    }));
  }


  loadUserListFiltersDefinition(listComponentId: number, pullAll: boolean = false): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadUserListFiltersListColumns();
    const listObjectLabel = 'Manage Personal Filters';
    const listObjectController = 'UserListFilter';
    const listStoreName = DynamicListStore.userListFilters;
    const detailRoutePath = 'user-list-filter-edit';
    const listRowKeyId = 'userListFilterId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.searchTerm = [];
    defaultListFilter.orderTerm = [new OrderTerm('lastUsed', false)];

    let listDefinition = this.createListDefinition('userFilterDetailOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = pullAll ? -1 : listComponentId;
    listDefinition.parentKey = pullAll ? '' : 'listComponentId';
    listDefinition.controllerMethod = pullAll ? 'ByProviderUser' : 'ByProviderUserByStore';
    listDefinition.methodParameters = pullAll
      ? currentUserId.toString()
      : currentUserId.toString() + '?listComponentId=' + listComponentId.toString();
    listDefinition.showFilter = false;

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.singleSelect = { title:'Select Filter', enabled: true,  eventName: DynamicListEvent.selectUserFilter  };

    return listDefinition;
  }

  loadUserListFiltersListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('isDefault');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('filterName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('description');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('lastUsed');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('usedCount');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  getListComponentId(storeName: string): number {
    if (!this.userPriviledgesService.authenticated$.value) {
      //logging out
      return -1;
    }

    const listComponent: IListComponent = this.userPriviledgesService.getListComponentByStore(storeName);
    return !listComponent ? -1 : listComponent.listComponentId;
  }

  loadSavedFilterToSearchTerms(selectedUserFilter: IUserListFilter, requiredTerms: ISearchTerm[], fieldDefinition: IFieldDefinition[]): ISearchTerm[] {
    let searchTerms: ISearchTerm[] = [];
    const workingRequiredTerms = requiredTerms ? cloneDeep(requiredTerms) : [];

    //load up searchTerms with the user saved terms
    selectedUserFilter.filterColumns.forEach((col) => {
      const fieldDef = fieldDefinition.find(x => x.key.toLowerCase() == col.modelName.toLowerCase());
      if (fieldDef) {
       const isSelectListField = this.dynamicFieldService.isSelectListField(fieldDef);
        const values: string[] = col.searchValue ? col.searchValue.split(',') : [''];
        const displayValues: string[] = col.displayValue ? col.displayValue.split(',') : [''];
        searchTerms.push({
          term: col.term,
          searchType: col.searchType,
          value: col.searchValue ? isSelectListField || values.length > 1 ? values : col.searchValue : col.searchValue,
          displayValues: col.displayValue ? isSelectListField || values.length > 1 ? displayValues : col.displayValue : col.displayValue,
          fieldType: fieldDef.fieldType,
          columnName: fieldDef.key
        })
      }
    });

    //add in the required terms from the list definition, if they are not already in the user saved filter.
    //add to start of array for visual consistency on ui
    workingRequiredTerms.forEach((term: ISearchTerm) => {
      if (!searchTerms.find((term) => term.columnName === term.columnName)) {
        searchTerms.unshift(term);
      }
    });

    return searchTerms;
  }


  /* If have no buttons to show, don't show the row tools column */
  showRowTools(listDefinition: IListDefinition): boolean {
    return (listDefinition.rowButtons && listDefinition.rowButtons.length > 0)
      || listDefinition.groupSelect
      || listDefinition.singleSelect ? true : false;
  }

  createListDefinition(routerOutlet: string,
    objectLabel: string,
    controllerName: string,
    storeName: string,
    rowKeyId: string,
    defaultListFilter: IListFilter,
    listColumns: IListColumn[],
    detailRoutePath: string = '',
    includeReload: boolean = true ): IListDefinition {
      let def = new ListDefinition(routerOutlet,
        objectLabel,
        controllerName,
        storeName,
        rowKeyId,
        defaultListFilter,
        listColumns,
        detailRoutePath);

      if (includeReload) {
        let toolButtons: IListButtonType[] = [{ type: ButtonType.reload, defaults: null }];
        def.toolButtons = this.loadListToolButtons(toolButtons, objectLabel);
      }

      return def;
  }

  /*
  * Loads the typical row level crud buttons 
  * Force returned order of buttons of: info, print, details, edit, delete
  * Some, All or No types may be requested
  * Allows for custom methods (event name) to be called
  * Does not allow label, icon, css override
  */
  loadListCrudButtons(btnTypes: IListButtonType[], objectLabel: string): ICustomButton[] {
    let crudBtns: ICustomButton[] = [];
    let btn: ICustomButton = null;

    if (!btnTypes || !btnTypes.length) {
      return crudBtns;
    }

    //PRINT - Generic print not currently used anywhere
    let type: IListButtonType = btnTypes.find(x => x.type === ButtonType.print);
    if (type) {
      btn = this.createButton('Print Row',
        'fas fa-print', 'app-btn-icon--info', DynamicListEvent.printRow,
        type.defaults);
      crudBtns.push(btn);
    }
    //DETAIL
    type = btnTypes.find(x => x.type === ButtonType.detail);
    if (type) {
      if (!this.isTypeAlwaysEnabled(type)) {
        if (!type.defaults) {
          type.defaults = this.setGenericDefaults('hasDetailUrl', 'dynamicListService');
        } else {
          type.defaults = this.setEnabledMethodDefaults(type.defaults, 'hasDetailUrl', 'dynamicListService')
        }
      }
      btn = this.createButton('View ' + objectLabel + ' Details',
        'fas fa-folder-open', 'app-btn-icon--primary', DynamicListEvent.showDetail,
        type.defaults);
      crudBtns.push(btn);
    }
    //EDIT
    type = btnTypes.find(x => x.type === ButtonType.edit);
    if (type) {
      if (!this.isTypeAlwaysEnabled(type)) {
        if (!type.defaults) {
          type.defaults = this.setGenericDefaults('hasUpdateUrl', 'dynamicListService');
        } else {
          type.defaults = this.setEnabledMethodDefaults(type.defaults, 'hasUpdateUrl', 'dynamicListService')
        }
      }
      btn = this.createButton('Edit ' + objectLabel,
        'fas fa-edit', 'app-btn-icon--warning', DynamicListEvent.editRow,
        type.defaults);
      crudBtns.push(btn);
    }
    //DELETE
    type = btnTypes.find(x => x.type === ButtonType.delete);
    if (type) {
      if (!this.isTypeAlwaysEnabled(type)) {
        if (!type.defaults) {
          type.defaults = this.setGenericDefaults('hasDeleteUrl', 'dynamicListService');
        } else {
          type.defaults = this.setEnabledMethodDefaults(type.defaults, 'hasDeleteUrl', 'dynamicListService')
        }
      }
      btn = this.createButton('Delete ' + objectLabel,
        'fas fa-trash', 'app-btn-icon--danger', DynamicListEvent.requestDelete,
        type.defaults);
      crudBtns.push(btn);
    }

    return crudBtns;
  }

  /*
* Loads the typical list level crud buttons 
* Force returned order of buttons of: create, reload
* Some, All or No types may be requested
* Allows for custom methods (event name) to be called
* Does not allow label, icon, css override
*/
  loadListToolButtons(btnTypes: IListButtonType[], objectLabel: string): ICustomButton[] {
    let toolBtns: ICustomButton[] = [];
    let btn: ICustomButton = null;

    if (!btnTypes || !btnTypes.length) {
      return toolBtns;
    }

    //CREATE
    let type: IListButtonType = btnTypes.find(x => x.type === ButtonType.create);
    if (type) {
      if (!this.isTypeAlwaysEnabled(type) && !type.defaults) {
        type.defaults = this.setGenericDefaults('hasNewUrl', 'dynamicListService');
      }
      btn = this.createButton('Add New ' + objectLabel,
        'fas fa-plus', 'app-btn-icon--success', DynamicListEvent.createRow,
        type.defaults);
      toolBtns.push(btn);
    }
    //RELOAD 
    type = btnTypes.find(x => x.type === ButtonType.reload);
    if (type) {
      btn = this.createButton('Reload List',
        'fas fa-sync', 'app-btn-icon--primary', DynamicListEvent.reloadList,
        type.defaults);
      toolBtns.push(btn);
    }

    return toolBtns;
  }

  isTypeAlwaysEnabled(type: IListButtonType): boolean {
    return type && type.defaults && type.defaults.enabledMethod && type.defaults.enabledMethod === DynamicListMethod.alwaysEnabled;
  }

  setGenericDefaults(methodName: string, serviceName: string): ICustomButton {
    return {
      title: '',
      icon: '',
      cssName: '',
      eventName: '',
      enabled: true,
      enabledMethod: methodName,
      methodService: serviceName
    }
  }

  setEnabledMethodDefaults(defaultsIn: ICustomButton, methodName: string, serviceName: string): ICustomButton {
    let defaultsOut: ICustomButton = cloneDeep(defaultsIn);
    defaultsOut.enabledMethod = defaultsOut.enabledMethod ? defaultsOut.enabledMethod : methodName;
    defaultsOut.methodService = defaultsOut.methodService ? defaultsOut.methodService : serviceName;
    return defaultsOut;
  }

  createButton(title: string, icon: string, cssName: string, eventName: string, defaults: ICustomButton): ICustomButton {
    let btn: ICustomButton = {
      title: defaults && defaults.title ? defaults.title : title,
      icon: defaults && defaults.icon ? defaults.icon : icon,
      cssName: defaults && defaults.cssName ? defaults.cssName : cssName,
      eventName: defaults && defaults.eventName ? defaults.eventName : eventName,
      enabled: defaults ? defaults.enabled : true,
    }

    btn.inOverflow = defaults && defaults.inOverflow ? defaults.inOverflow : false;

    //only set these non required properties if supplied
    if (defaults) {
      if (defaults.linkUrl) {
        btn.linkUrl = defaults.linkUrl;
      }
      if (defaults.isDownload) {
        btn.isDownload = defaults.isDownload;
      }
      if (defaults.enabledMethod && defaults.enabledMethod !== DynamicListMethod.alwaysEnabled) {
        btn.enabledMethod = defaults.enabledMethod;
      }
      if (defaults.cssMethod) {
        btn.cssMethod = defaults.cssMethod;
      }
      if (defaults.methodService) {
        btn.methodService = defaults.methodService;
      }
    }

    return btn;
  }

}




