import { Action } from '@ngrx/store';

import { IFieldDefinition } from '../../../dynamic-forms/index';
import { IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';

//** Add namespace by prefixing with [store type]
export const GET_FIELD_DEFINITIONS = '[MetaData] GET_FIELD_DEFINITIONS';
export const GET_META_DATA = '[MetaData] GET_META_DATA';
export const GET_META_DATA_WITH_FIELD_DEFS = '[MetaData] GET_META_DATA_WITH_FIELD_DEFS';
export const SET_FIELD_DEFINITIONS = '[MetaData] SET_FIELD_DEFINITIONS';
export const INITIALIZE_META_DATA = '[MetaData] INITIALIZE_META_DATA';
export const RESET_ALL_META_STORES = '[MetaData] RESET_ALL_META_STORES';


export class GetFieldDefinitions implements Action {
  readonly type = GET_FIELD_DEFINITIONS;
  constructor(
    public payload: { storeName: string, metaData: any }
  ) { }
}

export class SetFieldDefinitions implements Action {
  readonly type = SET_FIELD_DEFINITIONS;
  constructor(
    public payload: { storeName: string,  fieldDefinitions: IFieldDefinition[], seedData: any }
  ) { }
}

export class GetMetaData implements Action {
  readonly type = GET_META_DATA;
  constructor(
    public payload: { storeName: string, url: string, setListMetaData: boolean }
  ) { }
}


export class InitializeMetaData implements Action {
  readonly type = INITIALIZE_META_DATA;
  constructor(
    public payload: { storeName: string }
  ) { }
}

export class ResetAllMetaStores implements Action {
  readonly type = RESET_ALL_META_STORES;
}



export type MetaDataActions = GetFieldDefinitions |
  SetFieldDefinitions |
  GetMetaData |
  InitializeMetaData |
  ResetAllMetaStores;

