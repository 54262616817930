  <div class="dynamic-list__item dynamic-list__nav__item" [ngClass]="rowDef.rowClass" [class.router-link-active]="index === activeIndex">
    <div class="dynamic-list__item--link " (click)="showDetail()">
      <a *ngIf="!detailError" href="javascript:void(0)" routerLinkActive="router-link-active" [title]="row['entityLabel']">
        {{ row['entityLabel'] }}
      </a>
    </div>
  </div>
  <div *ngIf="screenSize.screenIsMax" class="dynamic-list__item dynamic-list__nav__icon"
       [ngClass]="rowDef.rowClass" [class.router-link-active]="index === activeIndex">
    <div class="dynamic-list__item--detail-info">
      <span *ngIf="detailError">{{ row['entityLabel'] }}</span>
      <span *ngIf="index === activeIndex" title="Active Record"><i class="fas fa-folder-open app-icon--primary"></i></span>
      <span *ngIf="index === activeIndex && detailError" title="Error on Detail."><i class="app-icon--danger fas fa-exclamation"></i></span>
    </div>
  </div>
