import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {  HttpClient } from '@angular/common/http';

import { Observable} from 'rxjs';
import { map,   mergeMap} from 'rxjs/operators';

/** Imports for custom services */
import { FieldDefinitionService, DomainObjectService } from '../../../../shared/services/index';
import { IFieldDefinition } from '../../../dynamic-forms/index';
import { IResponseBase } from '../../../../shared/interfaces/index';

import * as fromMetaData from '../actions/meta-data.actions';
import * as fromDynamicList from '../actions/dynamic-list.actions';
import * as LoadingIndicatorActions from '../../../../shared/store/loadingIndicator/loadingIndicator.actions';

 //Actions used in these Effects that need to trigger Spinner hide/show
type ShowSpinnerTypes = 
  | fromMetaData.GetFieldDefinitions
  | fromMetaData.GetMetaData

const showSpinnerActions = [
  fromMetaData.GET_FIELD_DEFINITIONS,
  fromMetaData.GET_META_DATA
];

type HideSpinnerTypes =
  | fromMetaData.SetFieldDefinitions

const hideSpinnerActions = [
  fromMetaData.SET_FIELD_DEFINITIONS
];


//using Effects so can assign actions to an observable result set
@Injectable()
export class MetaDataEffects {

  constructor(
    public http: HttpClient,
    public actions$: Actions<fromMetaData.MetaDataActions>,
    public domainObjectService: DomainObjectService,
    public fieldDefinitionService: FieldDefinitionService ) {}

  /********************************************************************************************************************************************
   * Triggered when included in ShowSpinnerTypes/ HideSpinnerTypes - cannot use a common spinner here as lists
   * are dynamic and each need their own spinner indicator
   * either modify the spinner to have dynamic values or keep as is.
  **********************************************************************************************************************************************/
  showSpinner$ = createEffect(() =>  this.actions$.pipe(
    ofType<ShowSpinnerTypes>(...showSpinnerActions),
    map((action) => {
        //pull the store name out of the incominb payload
        const requestor: string =  action.payload['storeName'];
        return new LoadingIndicatorActions.ShowSpinner({ requestor: requestor, id: -1 });
      }
  )));

  hideSpinner$ = createEffect(() =>  this.actions$.pipe(
    ofType<HideSpinnerTypes>(...hideSpinnerActions),
    map((action) => {
        const requestor: string =  action.payload['storeName'];
        return new LoadingIndicatorActions.HideSpinner({ requestor: requestor, id: -1 });
      }
  )));


/*********************************************************************************************************************************************
 GET_LIST_FIELD_DEFINITIONS Effect
  Listens for SetListResults to be called and tags on to it
  interprets the field definitions provided into field definitions for use by angular 
**********************************************************************************************************************************************/
  fieldDefintionsGet$ = createEffect(() => this.actions$.pipe(
      ofType(fromMetaData.GET_FIELD_DEFINITIONS) ,
      map((action: fromMetaData.GetFieldDefinitions) => action.payload),
      map((payload) => {
          const storeName = payload.storeName;
          if (!storeName) {
             console.log('MetaData Effects DEV Error:  fieldDefinitionsGet effect missing store with objectName: ', storeName);
          }

          let fieldDefinitions: IFieldDefinition[] = null;

        const displayFields: string[] = Object.keys(payload && payload.metaData && payload.metaData.fieldMetaData 
          ? payload.metaData.fieldMetaData
          : []);
          //on call to load definitions, do not load drop downs - only need that information on initial load of the filter bar (null param)
          fieldDefinitions = this.fieldDefinitionService.loadFieldDefinitions(displayFields, payload.metaData.fieldMetaData, null);
         return { storeName: storeName,  fieldDefinitions: fieldDefinitions, seedData: payload.metaData.seedData };
      }),
    mergeMap((results) => {
          const data = { storeName: results.storeName, fieldDefinitions: results.fieldDefinitions, seedData: results.seedData };

          return [ { type: fromMetaData.SET_FIELD_DEFINITIONS, payload: data } ];
        }
      )
  ));

/*********************************************************************************************************************************************
GET_META_DATA_WITH_FIELD_DEFS Effect
Called when need field metadata and the meta data store for this object is empty
**********************************************************************************************************************************************/


    /*********************************************************************************************************************************************
  GET_META_DATA Effect
  Called when need field metadata and the meta data store for this object is empty
  **********************************************************************************************************************************************/
  metaDataGet$ = createEffect(() => this.actions$.pipe(
      ofType(fromMetaData.GET_META_DATA),
      map((action: fromMetaData.GetMetaData) => action.payload),
      mergeMap((payload) => {
        // Construct the URL to be called.
        let result: Observable<IResponseBase> = this.domainObjectService.getByUrl(payload.url);

        return result.pipe(
          mergeMap((response) => {

            let metaData = {};
            if (response.success) {
              //will have seedData and fieldMetaData
              metaData = response.data.hasOwnProperty('fieldMetaData') ? response.data : {};
              if (Object.keys(metaData).length === 0) {
                console.log('DEV WARNING:  fromDynamicList.GetList and no metaData returned', response);
              }
            }

            if (payload.setListMetaData) {
              return [
                { type: fromMetaData.GET_FIELD_DEFINITIONS, payload: { storeName: payload.storeName, listDefinition: null, metaData: metaData } },
                { type: fromDynamicList.DynamicListActionTypes.SET_LIST_META_DATA, payload: { storeName: payload.storeName, parentId: -1, metaData: metaData } }
              ]
            } else {
              return [
                { type: fromMetaData.GET_FIELD_DEFINITIONS, payload: { storeName: payload.storeName, listDefinition: null, metaData: metaData } }
              ]
            }

            })
          );
        })
      ));
}

