import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IEntityDocument } from '../../../portals/view-models/index_two';

@Component({
  selector: 'file-viewer-thumbnails',
  templateUrl: './file-viewer-thumbnails.component.html'
}) export class FileViewerThumbnailsComponent {

  @Input() idx: number;
  @Input() data: IEntityDocument[];
  @Output() selectEvent: EventEmitter<number> = new EventEmitter(); 

  show(idx: number): void {
    this.selectEvent.emit(idx);
  }

  isPdf(str: string): boolean {
    return str.split('.')[1] === 'pdf';
  }

}
