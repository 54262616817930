<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <div [ngClass]="{ 'loading-mask': (working$ | async)}">
    <ng-container *ngIf="objectData && mds.fieldDefinitionsLoaded && mds.selectionListsLoaded">
      <form *ngIf="form" [formGroup]="form" novalidate class="form portal__form" [ngClass]="{'portal__form--edit': operation !== myConstants.operationTypeDetails}">
        <fw-dynamic-field [field]="mds.getFieldDefinition('generalContractorName')"
                          [form]="form"
                          [operation]="operation"
                          [selectItems]="generalContractors">
        </fw-dynamic-field>
        <div class="float-label">
          <select #pgmSvc
                  [disabled]="operation === myConstants.operationTypeDetails"
                  (change)="changePgmSvc(pgmSvc.value)">
            <option selected value="0">Choose...</option>
            <option *ngFor="let ps of (sssProgramServices$ | async)"
                    [value]="ps.programService_programServiceId"
                    [selected]="!objectData ? false : objectData.sssProgramService_sssProgramServiceId === ps.sssProgramServiceId">
              {{ps.entityLabel}}
            </option>
          </select>
          <label [title]="mds.getLabel('sssProgramService')">{{ mds.getLabel('sssProgramService') }}</label>
        </div>
        <div class="float-label">
          <select #brchPgm
                  [disabled]="operation === myConstants.operationTypeDetails"
                  (change)="changeBranchProgram(brchPgm.value)">
            <option value="0">Choose...</option>
            <option *ngFor="let bp of (branchPgms$ | async)"
                    [value]="bp.branchProgramId"
                    [selected]="!objectData ? false : objectData.branchProgram_branchProgramId === bp.branchProgramId">
              {{bp.branchName}}
            </option>
          </select>
          <label [title]="mds.getLabel('branchName')">{{ mds.getLabel('branchName') }}</label>
        </div>
        <fw-dynamic-field [field]="mds.getFieldDefinition('dateRange')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('allowSlotBufferReservations')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('etaBuffer')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('minimumDuration')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
      </form>
      <fw-component-footer [lastUpdate]="objectData && objectData.hasOwnProperty('updateDate') ? objectData.updateDate : '' "></fw-component-footer>
      <fw-crud-buttons *ngIf="form"
                       [operation]="operation"
                       [disabled]="!form.dirty || !form.valid || selectedBranchProgramId === 0"
                       [showNext]="operation !== myConstants.operationTypeCreate"
                       (cancelEvent)="onCancel($event)"
                       (createEvent)="onCreate($event)"
                       (saveEvent)="onSave($event)">
      </fw-crud-buttons>
    </ng-container>
  </div>
</fw-dynamic-detail-container>
