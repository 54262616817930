import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomSignatureEvent } from 'hom-lib/hom-signature';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { getListByType, IDynamicListState, IListObjectData, GetList } from '../../../../../fw/dynamic-list/store';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { IEntityDocument, IPurchaseOrderExternalData, IEntityDocumentActionViewModel } from '../../../view-models/index_two';
import { DocumentTypeName } from '../../../../file-upload/enums/file-upload.enums';
import { IPoLswpOverrideReason } from '../../interfaces';
import { ProjectListStore } from '../../../project/enums/project.enums';
import { IPurchaseOrder } from '../../../view-models';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { LswpProcessingStatus } from '../../enums/lswp-processing-status.enums';
import { RequiredDocumentService } from '../../services';
import { DomainObjectService } from '../../../../../shared/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { FlexFieldDefinitionName, LeadDocumentStatus } from '../../enums/flex-field-definition.enums';
import { ProjectService } from '../../../project/services';

@Component({
  selector: 'lswp-document-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './lswp-document-manager.component.html'
})
export class LswpDocumentMangagerComponent implements OnInit, OnDestroy {
  public showExceptionReasons$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public poList$: BehaviorSubject<IPoLswpOverrideReason[]> = new BehaviorSubject(null);
  public staticError$: BehaviorSubject<string> = new BehaviorSubject('');
  public infoMsg$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');

  subscription: Subscription = new Subscription();
  projectId: number = 0;
  entityDocumentId: number = 0;
  actionId: number = 0;
  entityDocument: IEntityDocument = null;
  listDefinition: IListDefinition = null;
  posToChange: IPoLswpOverrideReason[] = [];
  onInit: boolean = true;
  skipPoUpdates: boolean = false;
  projectPoListDefintion: IListDefinition = null;
  pushToGcPreviouslyCompleted: boolean = false;
  previousPushCompleteMsg: string = 'Document has already been pushed to the gc for all open purchase orders. You are all done.';

  constructor(public activeRoute: ActivatedRoute,
    public router: Router,
    public store: Store<fromFeature.IAllDynamicData>,
    public rds: RequiredDocumentService,
    public dos: DomainObjectService,
    public modalService: ModalService,
    public emitterService: HomEventEmitterService,
    public projectService: ProjectService  ) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.entityDocumentId = +paramMap.get('id');
      this.actionId = +paramMap.get('actionId');
      this.projectId = +paramMap.get('projectId');
      this.newRequest();
    });
  }

  public changeReason(item: IPoLswpOverrideReason): void {
    //update the list of pos to update
    //the list should already include any po that is not currently set to ensure they get updated.
    const idx = this.posToChange.findIndex(x => x.purchaseOrderId == item.purchaseOrderId);
    if (idx >= 0) {
      this.posToChange[idx].reasonCodeId = item.reasonCodeId;
    } else {
      this.posToChange.push(item);
    }
  }

  public onSave(): void {
    //update the reason codes, the regenerate the lswp exception document
    this.updateReasonCodes();
  }

  public onNext(): void {
    this.routeToSignature();
  }

  public onPushToGc(): void {
    this.pushFileForNewPos();
  }

  newRequest(): void {
    this.listDefinition = this.rds.loadProjectRequiredDocumentsListDefinition(this.projectId, true);
    this.posToChange = [];

    //retrieve selected entity document
    this.subscription.add(this.store.pipe(select(getListByType(this.listDefinition.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.data) {
          const allDocs: IEntityDocument[] = cloneDeep(state.data);
          const entityDocument = allDocs.find(x => x.entityDocumentId == this.entityDocumentId);
          if (entityDocument) {
            this.entityDocument = entityDocument;
            let pushToGcAction: IEntityDocumentActionViewModel = this.entityDocument.actions ?
              this.entityDocument.actions.find(x => x.actionName.toLowerCase() == 'push to gc') : null;
            this.pushToGcPreviouslyCompleted = pushToGcAction == null ? false : pushToGcAction.completionDate ? true : false;
            if (entityDocument.documentTypeName === DocumentTypeName.leadExceptionForm) {
              //display the lswp reasons
              this.showExceptionReasons$.next(true);
            } else {
              //route to signature page
              if (!this.pushToGcPreviouslyCompleted) {
                this.routeToSignature();
              }

            }
          } else {
            //error:  unable to find entity document - dev error
            this.errorData$.next([]);
            this.errors$.next('Unable to find entity document.  Please close and refresh document list.');
          }
        }
        if (this.onInit) {
          this.loading$.next(false);
          this.onInit = false;
        }

      }
    ));

    //retrieve existing project purchase orders from store
    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectPurchaseOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((state: IListObjectData) => {
        if (this.skipPoUpdates) {
          return;
        }
        if (state.working) {
          this.loading$.next(true);
          this.infoMsg$.next('Retrieving purchase orders.');
          return;
        }
        this.projectPoListDefintion = cloneDeep(state.listDefinition);
        if (state && state.data) {
          const allPos: IPurchaseOrder[] = cloneDeep(state.data);
          let poList: IPoLswpOverrideReason[] = [];
          this.skipPoUpdates = true;
          allPos.forEach(po => {
            if (po.purchaseOrderStatus.toLowerCase() !== 'void') {
              if (!po.purchaseOrderProgramServiceName.toLowerCase().includes('carpet')) {
                const reasonCode: string = this.getExternalDataValueByName(FlexFieldDefinitionName.overrideReasonCode, po.externalExtraData);
                const docStatus: string = this.getExternalDataValueByName(FlexFieldDefinitionName.leadDocumentStatus, po.externalExtraData) || '';
                const externalStatus: string = this.getExternalDataValueByName(FlexFieldDefinitionName.externalStatus, po.externalExtraData) || '';

                //if external status is canceled/done, then mark as cancelled
                //if override reason code is null and any other status, need to allow processing
                //if completed (so has override reason code as well), will mark as completed
                const overrideStatus: LswpProcessingStatus = this.getOverrideStatus(reasonCode);

                const item: IPoLswpOverrideReason = {
                  uiStatus: externalStatus.toLowerCase().includes('done') || externalStatus.toLowerCase().includes('canceled')
                    ? LswpProcessingStatus.canceled
                    : overrideStatus == LswpProcessingStatus.none
                      ? overrideStatus
                      : docStatus.toLowerCase() === LeadDocumentStatus.completed
                        ? LswpProcessingStatus.completed
                        : overrideStatus,
                  purchaseOrderId: po.purchaseOrderId,
                  purchaseOrderNumber: po.purchaseOrderNumber,
                  reasonCodeId: !reasonCode ? 1 : +reasonCode,
                  documentStatus: docStatus,
                  error: []
                };
                poList.push(item);
                if (item.uiStatus !== LswpProcessingStatus.completed && item.uiStatus !== LswpProcessingStatus.canceled) {
                  //If no reason code, must update, set to one.
                  //older pos pulled in from prod may be completed with a null reason code, exclude those
                  this.posToChange.push(item);
                }
              }
            }
          });

          let staticError: string = '';
          let infoMsg: string = '';
          if (this.entityDocument.documentTypeName === DocumentTypeName.leadExceptionForm) {
            if (this.posToChange && this.posToChange.length > 0) {
              if (this.pushToGcPreviouslyCompleted) {
                infoMsg = 'Update reason code for new purchase order(s) and push existing document for new purchase order(s).'
              }
            } else {
              if (!this.entityDocument.fileUrl) {
                staticError = 'Form must exist to sign. Try generating form to continue';
              } else if (!this.pushToGcPreviouslyCompleted) {
                //still need to push this guy
                infoMsg = 'You are ready to sign and push the file to the general contractor.'
              } else {
                infoMsg = this.previousPushCompleteMsg;
              }
            }
          } else if (!this.posToChange || this.posToChange.length === 0) {
            //lswp safe doc type
            infoMsg = this.pushToGcPreviouslyCompleted ? this.previousPushCompleteMsg : '';
          } else {
            infoMsg = '';
          }

          this.staticError$.next(staticError);
          this.infoMsg$.next(infoMsg);
          this.poList$.next(poList);
          this.loading$.next(false);
        }
      }));

    //Listen for response from signature pad manager
    this.subscription.add(this.emitterService.signatureEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e) {
          switch (e.event) {
            case HomSignatureEvent.CaptureSuccessful:
              this.reloadAndClose();
              break;
            case HomSignatureEvent.CaptureFailed:
              this.errorData$.next([]);
              this.errors$.next('Signature Capture Failed.  Please refresh your documents and retry.');
              break;
            default:
              break;
          }
        }
      }));

  }

  routeToSignature(): void {
    this.showExceptionReasons$.next(false);
    this.router.navigate([{ outlets: { lswpOutlet: ['sign-doc', this.entityDocument.entityDocumentId, this.actionId, 1] } }], {
      relativeTo: this.activeRoute
    });
  }

  getExternalDataValueByName(fieldName: string, externalExtraData: IPurchaseOrderExternalData[]): string {
    const prop = externalExtraData ? externalExtraData.find(x => x.fieldName == fieldName) : null;
    return prop ? prop.fieldValue : '';
  }

  getOverrideStatus(reasonCode: string): LswpProcessingStatus {
    switch (reasonCode) {
      case '':
        return LswpProcessingStatus.none;
      case '1':
      case '7':
      case '10':
        return LswpProcessingStatus.set;
      default:
        return LswpProcessingStatus.none;
    }
  }

  updateReasonCodes(): void {
    this.loading$.next(true);
    this.infoMsg$.next('Sending request to update exception reason and generate form to general contractor.');

    this.posToChange.forEach(x => {
      let poList: IPoLswpOverrideReason[] = cloneDeep(this.poList$.value);
      const indx = poList.findIndex(i => i.purchaseOrderId == x.purchaseOrderId);
      if (indx >= 0) {
        poList[indx].uiStatus = LswpProcessingStatus.working;
        this.poList$.next(poList);

        const params: string = this.projectId.toString() + '?poId=' + x.purchaseOrderId.toString() + '&codeId=' + x.reasonCodeId.toString();
        this.subscription.add(this.dos.updateByMethodParams('EntityDocument', 'UploadLeadWorkplaceDetails', params) //int id, int poId, int codeId
          .subscribe((response: IResponseBase) => {
            poList = cloneDeep(this.poList$.value);
            if (response.success) {
              //reload because we need updated url
              this.rds.dispatchRequiredDocumentsGet(this.projectId, true, false, 0);
              this.projectService.emitTaskReloadEvent('lswp-doc-mgr', this.projectId);
              poList[indx].uiStatus = LswpProcessingStatus.success;
            } else {
              poList[indx].uiStatus = LswpProcessingStatus.error;
              poList[indx].error = response.errorData
            }
            this.poList$.next(poList);
            this.setLoadingStatus();
          }));
      }
    });
  }

  setLoadingStatus(): void {
    let allDone = false;
    const firstFound = this.poList$.value.find(x => x.uiStatus == LswpProcessingStatus.working || x.uiStatus == LswpProcessingStatus.none);
    const anySuccess = this.poList$.value.find(x => x.uiStatus == LswpProcessingStatus.success || x.uiStatus == LswpProcessingStatus.completed);
    allDone = firstFound ? false : true;
    this.loading$.next(!allDone);
    if (allDone) {
      if (this.pushToGcPreviouslyCompleted) {
        this.pushFileForNewPos();
      } else if (anySuccess) {
        //if need to regen the exception form, execute that request now.
        this.generateFile();
      } else {
        this.infoMsg$.next('');
      }
    }
  }

  generateFile(): void {
    this.loading$.next(true);
    //document is at project level, so just generate once.

   this.subscription.add(this.dos.updateByMethodById('EntityDocument', 'GenerateFileByEntityDocument', this.entityDocumentId)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.showExceptionReasons$.next(false);
          //RELOAD purchase orders here.
          this.skipPoUpdates = true;
          this.store.dispatch(new GetList({ listDefinition: this.projectPoListDefintion, listFilter: this.projectPoListDefintion.defaultListFilter, parentId: this.projectPoListDefintion.parentId }));
          this.routeToSignature();
        } else {
          this.errorData$.next(response.errorData);
          this.errors$.next(response.message);
        }
        this.loading$.next(false);
        this.infoMsg$.next('');
      }));
  }

  //will push files of this type for all pos that need to be pushed
  pushFileForNewPos(): void {
    this.loading$.next(true);
    this.infoMsg$.next('Pushing existing document on file for new pos');

    const params: string = this.entityDocumentId.toString();
    this.subscription.add(this.dos.updateByMethodParams('EntityDocument', 'PushExistingFileToGc', params)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.infoMsg$.next('Push Successful.');
          this.reloadAndClose();
        } else {
          this.errorData$.next(response.errorData);
          this.errors$.next(response.message);
          this.infoMsg$.next('');
        }
        this.loading$.next(false);
      }));
  }

  reloadAndClose(): void {
    this.rds.dispatchRequiredDocumentsGet(this.projectId, true, false, 0);
    this.projectService.emitTaskReloadEvent('lswp-doc-mgr', this.projectId);
   if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
