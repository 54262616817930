<div class="button-title-bar ">
  <fw-component-title [title]="title"
                      [entityLabel]="entityLabel"
                      [entityColor]="entityColor"
                      [entityStatus]="entityStatus"
                      [forHighLevelSummary]="forHighLevelSummary">
  </fw-component-title>
  <fw-custom-buttons [buttons]="customButtons"
                     (customEvent)="onEvent($event)">
    <ng-content ></ng-content>

    <fw-crud-icon-buttons *ngIf="showEdit || showRefresh || showDelete || printUrl"
                          [wrap]="false"
                          [entityLabel]="entityLabel ? entityLabel : title"
                          [canIEdit]="canIEdit"
                          [operation]="operation"
                          [disabled]="disabled"
                          [staleData]="staleData"
                          [showEdit]="showEdit"
                          [showDelete]="showDelete"
                          [showRefresh]="showRefresh"
                          [printUrl]="printUrl"
                          (customEvent)="onEvent($event)">
    </fw-crud-icon-buttons>

  </fw-custom-buttons>

</div>
  <hr *ngIf="includeBorder" />
