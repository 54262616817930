import { Injectable, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { cloneDeep, orderBy } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ITabRequest } from '../../fw-app-tabs/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IUserRecent } from '../interfaces/i-user-recent';
import { IErrorData } from '../../../../../shared/interfaces';
import { ModalSizeType } from '../../../../fw-modal/interfaces/i-modal';
import { AppAction } from '../../../../../shared/enums/app-action.enums';

import { getUserRecents, getAuthErrorData } from '../../../../../auth/store/auth.selectors';
import * as fromRoot from '../../../../../app/store/reducers/index';
import * as fromAuth from '../../../../../auth/store/index';
import { TabsService } from '../../fw-app-tabs/services/fw-app-tabs.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { ModalService } from '../../../../fw-modal/services/fw-modal.service';

@Injectable({
  providedIn: 'root'
})
export class UserRecentsService {
  subscription: Subscription = new Subscription();
  public projectUserRecents$: BehaviorSubject<IUserRecent[]> = new BehaviorSubject(null);
  public installerUserRecents$: BehaviorSubject<IUserRecent[]> = new BehaviorSubject(null);
  public showPopover: boolean = false;
  public workerItems: { entityId: number, entityPkId: number }[] = [];
  public rejectedRequest: BehaviorSubject<{ managerPortalId: number, entityPkId: number }> = new BehaviorSubject(null);

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public datePipe: DatePipe,
    public tabsService: TabsService,
    public userPriviledgesService: UserPriviledgesService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants
  ) {
    this.subscription.add(this.rootStore.pipe(select(getUserRecents)).subscribe((data: IUserRecent[]) => {
      let recents = orderBy(cloneDeep(data), ['timestamp'], ['desc']);
      this.projectUserRecents$.next(recents.filter(x => x.entityName === 'Project'));
      this.installerUserRecents$.next(recents.filter(x => x.entityName === 'Installer'));
      this.workerItems = [];
    }));

    this.subscription.add(this.rootStore.pipe(select(getAuthErrorData)).subscribe((errors: IErrorData[]) => {
      const error = cloneDeep(errors.find(x => x.key === 'user-recent'));
      if (error) {
        this.modalService.open({
          title: 'Error',
          path: 'app-action-msg/' + AppAction.error + '/' + error.value[0],
          sizeType: ModalSizeType.xsmall,
          castExit: true,
          hasTabs: false,
          isSubModal: false
        });
        this.workerItems = [];
        this.rootStore.dispatch(new fromAuth.ClearUserRecentError());
      }
    }));
  }
  
  public createUserRecent(entityId: number, entityPkId: number) {
    const objData = {
      providerUser_providerUserId: this.userPriviledgesService.currentUserId$.value,
      entity_entityId: entityId,
      entityPkId: entityPkId,
      timestamp: this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm:ss')
    };
    this.rootStore.dispatch(new fromAuth.CreateUserRecent({ updateData: { objectType: 'UserRecent', methodName: 'Create', objectData: objData, arrayName: null } }))
  }

  public deleteUserRecent(userRecentId: number): void {
    this.rootStore.dispatch(new fromAuth.DeleteUserRecent({ id: userRecentId }));
  }

  public isBookmarked(managerPortalId: number, entityPkId: number): boolean {
    return this[managerPortalId === 1 ? 'projectUserRecents$' : 'installerUserRecents$'].value.findIndex(x => x.entityPkId === entityPkId) !== -1;
  }

  public isWorkerItem(entityId: number, entityPkId: number): boolean {
    return this.workerItems.findIndex(x => x.entityId === entityId && x.entityPkId === entityPkId) !== -1;
  }
  
  public openUserRecentTab(item: IUserRecent): void {
    const data: ITabRequest = {
      text: item.entityName === 'Project' ? (item.entityName + ' ' + item.entityPkId) : (item.entityName + ' ' + item.entityValue),
      portalId: item.entityName === 'Project' ? 1 : 7,
      portalEntityId: item.entityPkId,
      level2EntityName: null,
      level2EntityId: null,
      level3EntityName: null,
      level3EntityId: null,
      level2Text: null,
      level3Text: null
    };
    const event: IHomEventEmitter = {
      requestor: 'user-recents',
      event: this.myConstants.emitterEventTabLevel1Open,
      action: '',
      data: data
    };
    this.tabsService.emitTabEvent(event);
    this.showPopover = false;
  }

}
