/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit,  ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { cloneDeep, orderBy } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IScheduleRouteParameter, IListFilter,  ISearchTerm, ListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { IWorkOrderViewModel, ISlotScheduleExclusion, ISlotScheduleCalendar, IProviderLocation, IService, IGeneralContractor } from '../../../view-models';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { UtilitiesStore, UtilitiesEvent } from '../../enums/utilities.enums';
import { SearchType } from '../../../../../fw/dynamic-list/enums/search-type.enums';
import { ICalendarFilterRequest } from '../../interfaces';
import { WorkOrderStatus } from '../../../scheduler/enums/schedule.enums';
import { DynamicListEvent } from '../../../../../fw/dynamic-list/enums/dynamic-list.enum';

import { IAllDynamicData, getListByType, IListObjectData,  IDynamicListState, DeleteObjectByUrlList, getEntityListByParentId, SetWorkingList, GetList, SetListDefinition, ClearEventList, UpdateObjectCustomList } from '../../../../../fw/dynamic-list/store';
import { DeleteObjectModel, IKey, UpdateObjectCustomModel } from  '../../../../../fw/dynamic-list/store/interfaces/index';
import * as fromRoot from '../../../../../app/store/reducers/index';
import { IErrorData, IResponseBase, ILookupData } from '../../../../../shared/interfaces';
import { DispatchCalendarSearchTerm } from '../../enums/dispatch-calendar.enums';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';

import { UtilitiesService } from '../../services/utilities.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ProjectService } from '../../../project/services';
import { UserPriviledgesService } from '../../../../../auth/services';
import { DispatchCalendarService } from '../../services/dispatch-calendar.service';
import { DomainObjectService } from '../../../../../shared/services';

@Component({
  selector: 'dispatch-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dispatch-calendar.component.html'
})
export class DispatchCalendarComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public data$: BehaviorSubject<IWorkOrderViewModel[]> = new BehaviorSubject(null);
  public homHolidays$: BehaviorSubject<string[]> = new BehaviorSubject(null);
  public loading$: Observable<boolean>;
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public showList: boolean = false;
  public slotScheduleCalendars$: BehaviorSubject<ISlotScheduleCalendar[]> = new BehaviorSubject([]);
  public displayView: string;
  public selectedDate: string;
  public isDayLocked$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public currentFilter: IListFilter = null;
  public today: string;
  public displayFilterText: string;
  public displayDateRange: string;
  public locations: IProviderLocation[];
  public services: IService[];
  public woStatuses: ILookupData[];
  public generalContractors: IGeneralContractor[];
  public autoFilter: boolean = true;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<IAllDynamicData>,
    public modalService: ModalService,
    public utilitiesService: UtilitiesService,
    public projectService: ProjectService,
    public datePipe: DatePipe,
    public dispatchCalendarService: DispatchCalendarService,
    public userPriviledgesService: UserPriviledgesService,
    public domainObjectService: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  //Handle custom events from child components
  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case UtilitiesEvent.goToWorkOrder:
        this.goToWorkOrder(event.data);
        break;
      case UtilitiesEvent.rescheduleWorkOrder:
        this.showScheduler(event.data);
        break;
      case UtilitiesEvent.dispatchWorkOrder:
        this.dispatch(event.data);
        break;
      case UtilitiesEvent.unDispatchWorkOrder:
        this.unDispatch(event.data);
        break;
      case UtilitiesEvent.showWoPacket:
        this.showWoPacket(event.data);
        break;
      case UtilitiesEvent.showWoPacketsForDay:
        this.printPackets(event.data);
        break;
      case UtilitiesEvent.addWorkOrderNote:
        this.addNote(event.data);
        break;
      case UtilitiesEvent.deleteSchedule:
        this.deleteSchedule(event.data);
        break;
      case UtilitiesEvent.filterChanged:
        this.filterList(event);
        break;
      case UtilitiesEvent.filterTextChanged:
        this.displayFilterText = event.data;
        break;
      case UtilitiesEvent.goToDay:
        this.goTo(event.data, this.dispatchCalendarService.viewDay);
        break;
      case UtilitiesEvent.goToWeek:
        this.goTo(event.data, this.dispatchCalendarService.viewWeek);
        break;
      case UtilitiesEvent.showLockDayManager:
        this.lockDay(event.data);
        break;
    default:
        break;
    }
  }

  public toggleList(): void {
    this.showList = !this.showList;
  }

  //Previous Day / Week / Month
  public goToPrevNext(toPrev: boolean): void {
    this.autoFilter = true;
    switch (this.displayView) {
      case this.dispatchCalendarService.viewDay:
        this.selectedDate = this.datePipe.transform(new Date(this.selectedDate).addDays(toPrev ? -1 : 1), 'MM/dd/yyyy').toString();
        break;
      case this.dispatchCalendarService.viewWeek:
        this.selectedDate =this.datePipe.transform(new Date(this.selectedDate).addDays(toPrev ? -7 : 7), 'MM/dd/yyyy').toString();
        break;
      case this.dispatchCalendarService.viewMonth:
        this.selectedDate = this.datePipe.transform(new Date(this.selectedDate).addMonths(toPrev ? -1 : 1), 'MM/dd/yyyy').toString();
        break;
      default:
    }
    this.setDisplayDate();
    this.changeDateSearchTerm();
  }

  public goTo(day: string, view: string): void {
    this.autoFilter = true;
    this.selectedDate = this.datePipe.transform(new Date(day), 'MM/dd/yyyy').toString();
    this.displayView = view;
    this.setDisplayDate();
    this.changeDateSearchTerm();
  }

  //Switch to day view and go to today
  public goToToday(): void {
    this.goTo(this.datePipe.transform(Date.now(), 'MM/dd/yyyy').toString(), this.dispatchCalendarService.viewDay);
  }

  //Print the packets for the selected day
  public printPackets(data: IWorkOrderViewModel[] = null): void {
    const forData: IWorkOrderViewModel[] = !data ? this.data$.value : data;
    if (!forData || !forData.length) {
      return;
    }
    let workOrderParams: string = '';
    //remove any row where wo is not work complete or not dispatched
    forData.filter(x => x.workOrderStatus === WorkOrderStatus.workComplete || x.workOrderStatus === WorkOrderStatus.dispatched)
      .forEach(wo => {
        workOrderParams = workOrderParams.concat(workOrderParams ? '&' : '');
        workOrderParams = workOrderParams.concat('ids=', wo.workOrderId.toString());
      });
    if (!workOrderParams) {
      alert('No Work Orders were found to print packets.  Work Orders must be in Work Complete or Dispatched status to be printed.')
    } else {
      let url: string = '/Image/WorkOrderPackets/?' + workOrderParams;
      if (url) {
        window.open(url);
      }
    }
  }

  public lockDay(day: string = ''): void {
    const defaultDay: string = !day ? this.selectedDate : day;
    this.modalService.open({
      title: 'Lock Day Manager',
      path: 'schedule-locked-days/dispatch-calendar/' + new Date(defaultDay).toString(),
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false
    });
  }

  //Change Display View: Day, Week, Month
  public changeDisplayView(type: string): void {
    this.displayView = type;
    this.autoFilter = true;
    this.setDisplayDate();
    this.changeDateSearchTerm();
  }

  ngOnInit() {
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(
        filter(x => x.requestor === UtilitiesStore.dashCalendar || x.requestor === UtilitiesStore.homHolidays),
        map(x => x.show));

    this.today = this.datePipe.transform(Date.now(), 'MM/dd/yyyy').toString();
    this.listenForData();
    if (this.dispatchCalendarService.lastFilter) {
      this.resetFromSavedSettings();
   }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.store.dispatch(new ClearEventList({ storeName: UtilitiesStore.dashCalendar, parentId: -1 }));
  }

  listenForData(): void {
    this.subscription.add(this.store.pipe(select(getListByType(UtilitiesStore.dashCalendar)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == -1)))
      .subscribe((state: IListObjectData) => {
        if (state && state.data && !state.working) {
          if (!state.event || (state.event && state.event.event !== DynamicListEvent.inMemorySort)) {
            this.data$.next(cloneDeep(this.sortData(state.data)));
          }
        }
      }));

      this.subscription.add(this.store.pipe(select(getEntityListByParentId(UtilitiesStore.homHolidays, -1)))
      .subscribe((state: IListObjectData) => {
        if (state && state.data) {
          const holidays: ISlotScheduleExclusion[] = cloneDeep(state.data);
          this.homHolidays$.next(holidays.map(x => this.datePipe.transform(new Date(x.day), 'MM/dd/yyyy')));
        }
      }));

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('dispatchProviderLocation')))
      .subscribe((data) => {
        if (data) {
          this.locations = data;
        }
      }));

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('dispatchService')))
      .subscribe((data) => {
        if (data) {
          this.services = data;
        }
      }));

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('dispatchWorkOrderStatus')))
      .subscribe((data) => {
        if (data) {
          this.woStatuses = data;
        }
      }));

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('generalContractor')))
      .subscribe((data) => {
        if (data) {
          this.generalContractors = data;
        }
      }));

    this.subscription.add(this.slotScheduleCalendars$.subscribe((data: ISlotScheduleCalendar[]) => {
      if (this.displayView === this.dispatchCalendarService.viewDay) {
        if (data) {
          //check to see if this day is locked
          const dayData = data.find(x => this.datePipe.transform(new Date(x.day), 'MM/dd/yyyy') == this.selectedDate);
          this.isDayLocked$.next(dayData && dayData.isLocked);
        }
      }
    }));
  }

  changeDateSearchTerm(): void {
    const dateSearchValue: string = this.dispatchCalendarService.getDateSearchValueByTimeFrame(this.displayView, this.selectedDate);
    let listFilter: IListFilter = cloneDeep(this.currentFilter);
    const idx: number = this.currentFilter.searchTerm.findIndex(x => x.term == 'scheduleStartDate');
    if (idx >= 0) {
      listFilter.searchTerm[idx].value = dateSearchValue;
      listFilter.searchTerm[idx].searchType = this.displayView === this.dispatchCalendarService.viewDay ? SearchType.Equals : SearchType.Range;
      this.currentFilter = listFilter;
    } else {
      console.log('DEV ERROR: Did not find scheduleStartDate searchTerm.  No updates made');
    }
  }

  filterList(event: IHomEventEmitter): void {
    this.autoFilter = false;
    const data: ICalendarFilterRequest = event.data;
    this.currentFilter = data.listFilter;
    this.selectedDate = data.scheduleStartDate;
    this.displayFilterText = data.displayFilterText;
    this.displayView = data.viewType;
    this.setDisplayDate();
    this.requestData();
  }

  setDisplayDate(): void {
    this.displayDateRange =
      this.displayView === this.dispatchCalendarService.viewDay ? this.datePipe.transform(this.selectedDate, 'fullDate') : this.displayView === this.dispatchCalendarService.viewWeek
        ? this.dispatchCalendarService.getDateSearchValueByTimeFrame(this.displayView, this.selectedDate)
        : this.datePipe.transform(this.selectedDate, 'MMMM');
  }

  saveOffSettings(): void {
    this.dispatchCalendarService.lastFilter = this.currentFilter;
    this.dispatchCalendarService.lastSelectedDate = this.selectedDate;
    this.dispatchCalendarService.lastFilterText = this.displayFilterText;
    this.dispatchCalendarService.lastDisplayView = this.displayView;
  }

  resetFromSavedSettings(): void {
    //set the values
    //list will reload because store didn't init
    this.displayView = this.dispatchCalendarService.lastDisplayView;
    this.currentFilter = this.dispatchCalendarService.lastFilter;
    this.selectedDate = this.dispatchCalendarService.lastSelectedDate;
    this.displayFilterText = this.dispatchCalendarService.lastFilterText;
    this.slotScheduleCalendars$.next(this.dispatchCalendarService.lastSlotScheduleCalendars);
    let listDef: IListDefinition = this.utilitiesService.loadDispatchCalendarListDefinition();
    listDef.defaultListFilter = this.currentFilter;
    this.listDefinition$.next(listDef);
    this.setDisplayDate();
  }

  requestData(): void {
    this.data$.next(null);
    this.slotScheduleCalendars$.next(null);
    this.getLockDayForDateSelection();
    this.saveOffSettings();
    this.showList = false;

    let listDef: IListDefinition = this.utilitiesService.loadDispatchCalendarListDefinition();
    listDef.defaultListFilter = this.currentFilter;

    //First load it for the calendar
    this.store.dispatch(new SetListDefinition({ storeName: listDef.storeName, parentId: listDef.parentId, listDefinition: listDef }));
    this.store.dispatch(new SetWorkingList({ storeName: listDef.storeName, parentId: listDef.parentId, working: true }));
    this.store.dispatch(new GetList({ listDefinition: listDef, listFilter: listDef.defaultListFilter, parentId: listDef.parentId }));

    //Second set it up for the list.
    this.listDefinition$.next(listDef);

  }

  sortData(data: IWorkOrderViewModel[]): IWorkOrderViewModel[] {
    let sorted = cloneDeep(data);
    if (sorted.length) {
      sorted = orderBy(sorted, ['durationType', 'duration', 'scheduleStartDate', 'workOrderId'], ['asc', 'desc', 'asc', 'asc']);
    }
    return sorted;
  }

  goToWorkOrder(workOrder: IWorkOrderViewModel): void {
    this.projectService.openProjectWorkOrderTab(workOrder.projectId, workOrder.workOrderId, 'dispatch-calendar');
  }

  showWoPacket(workOrder: IWorkOrderViewModel): void {
    const url = workOrder.hasOwnProperty('metaData') && workOrder['metaData'].hasOwnProperty('workOrderWorkPacket') && workOrder['metaData']['workOrderWorkPacket'];
    if (url) {
      window.open(url);
    } else {
      const msg: string = 'Work Order Packet Not Found for WO #: '.concat(workOrder.workOrderId.toString());
      alert(msg);
    }
  }

  addNote(workOrder: IWorkOrderViewModel): void {
    if (!this.validModel) return;

    const stateData = { projectId: workOrder.projectId };
    const scheduleDate = workOrder.scheduleStartDate ? this.datePipe.transform(workOrder.scheduleStartDate, 'MM/dd/yyyy') : '';
    this.modalService.open({
      title: 'Schedule Notes for Work Order '.concat(workOrder.workOrderId.toString(), scheduleDate ? ' on '.concat(scheduleDate) : ''),
      path: 'utilities-work-order-notes/' + workOrder.workOrderId.toString() + '/' + this.myConstants.routeActionAdd,
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      state: stateData
    });
  }

  dispatch(workOrder: IWorkOrderViewModel): void {
    if (!this.validModel) return;

    const stateData = { projectId: workOrder.projectId, scheduleId: workOrder.scheduleId, hasPostIts: workOrder.specialInstructionAlert, storeName: UtilitiesStore.dashCalendar };
    this.modalService.open({
      title: 'Select Work Crew to Dispatch Work Order ',
      path: 'utilities-dispatch/' + workOrder.workOrderId.toString(),
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.small,
      state: stateData
    });
  }

  getLockDayForDateSelection(): void {
    this.errorData$.next([]);
    const serviceTerm: ISearchTerm = this.dispatchCalendarService.getCalendarFilterValueByTerm(this.currentFilter, DispatchCalendarSearchTerm.service);
    const locationTerm: ISearchTerm = this.dispatchCalendarService.getCalendarFilterValueByTerm(this.currentFilter, DispatchCalendarSearchTerm.providerLocation);
    const gcTerm: ISearchTerm = this.dispatchCalendarService.getCalendarFilterValueByTerm(this.currentFilter, DispatchCalendarSearchTerm.generalContractor);
    const dateTerm: ISearchTerm = this.dispatchCalendarService.getCalendarFilterValueByTerm(this.currentFilter, DispatchCalendarSearchTerm.scheduleStartDate);

    let searchTerms: ISearchTerm[] = [{ term: 'isLocked', value: true, searchType: SearchType.Equals, columnName: 'isLocked', fieldType: this.myConstants.dataTypeBool }];

    if (serviceTerm && serviceTerm.value.length !== this.services.length) {
      searchTerms.push({ term: 'slotSchedule_providerLocationService_service_serviceId', value: serviceTerm.value, searchType: serviceTerm.searchType, displayValues: serviceTerm.displayValues, columnName: 'serviceName', fieldType: this.myConstants.dataTypeInt });
    }
    if (locationTerm && locationTerm.value.length !== this.locations.length) {
      searchTerms.push({ term: 'slotSchedule_providerLocationService_providerLocation_providerLocationId', value: locationTerm.value, searchType: locationTerm.searchType, displayValues: locationTerm.displayValues, columnName: 'providerLocationName', fieldType: this.myConstants.dataTypeInt });
    }
    if (dateTerm) {
      searchTerms.push({ term: 'day', value: dateTerm.value, searchType: dateTerm.searchType, columnName: 'day', fieldType: this.myConstants.dataTypeDate});
    }
    if (gcTerm && gcTerm.value.length !== this.generalContractors.length) {
      searchTerms.push({ term: 'generalContractorId', value: gcTerm.value, searchType: gcTerm.searchType, displayValues: gcTerm.displayValues, columnName: 'generalContractor', fieldType: this.myConstants.dataTypeInt });
    }

    let listFilter: IListFilter = new ListFilter();
    listFilter.searchTerm = searchTerms;

    this.subscription.add(this.domainObjectService.getListByMethod('SlotScheduleCalendar', 'Index', '', listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.slotScheduleCalendars$.next(response.data);
          this.dispatchCalendarService.lastSlotScheduleCalendars = response.data;
       } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  deleteSchedule(workOrder: IWorkOrderViewModel): void {
    const def = this.listDefinition$.value;
    const deleteUrl = this.userPriviledgesService.getDeleteUrl(workOrder);
    if (this.userPriviledgesService.canIDelete(workOrder) && deleteUrl) {
      const keyData: IKey = { storeName: def.storeName, parentId: def.parentId, key: def.rowKeyId, id: workOrder.workOrderId }
      const event: IHomEventEmitter = { requestor: 'dispatch-calendar', event: this.myConstants.emitterEventDelete, action: '', data: null };

      const deleteData = new DeleteObjectModel(keyData, deleteUrl, event);
      this.store.dispatch(new DeleteObjectByUrlList({ deleteData }));
    }
  }

  showScheduler(workOrder: IWorkOrderViewModel) {
    if (!this.validModel) return;

    const workOrderId: number = workOrder && workOrder.hasOwnProperty('workOrderId') ? workOrder['workOrderId'] : 0;
    if (workOrderId === 0) {
      console.log('DEV ERROR:  missing wo id on event', event);
      return;
    }

    let params: IScheduleRouteParameter = {
      scheduleId: 0,
      operation: this.myConstants.operationTypeDetails,
      projectId: workOrder.projectId,
      storeName: this.listDefinition$.getValue().storeName,
      parentId: this.listDefinition$.getValue().parentId
    }
    this.modalService.open({
      title: 'Work Order Schedule',
      path: 'utilities-scheduler',
      id: workOrderId,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.xlarge,
      optionalParams: params
    });
  }

  unDispatch(workOrder: IWorkOrderViewModel): void {
    const keyData: IKey = { storeName: this.listDefinition$.value.storeName, parentId: this.listDefinition$.value.parentId, key: this.listDefinition$.value.rowKeyId, id: workOrder.workOrderId }
    const updateData = new UpdateObjectCustomModel(keyData, 'WorkOrderViewModel', 'UnDispatch', workOrder, null, null);
    this.store.dispatch(new UpdateObjectCustomList({ updateData }));
  }

  validModel(workOrder: IWorkOrderViewModel): boolean {
    const woId: number = workOrder && workOrder.hasOwnProperty('workOrderId') ? workOrder['workOrderId'] : 0;
    if (woId === 0) {
      console.log('DEV ERROR:  missing wo id on event', event);
    }
    return woId > 0;
  }

}
