// Resolver for a specific installer
import { Injectable,  OnDestroy} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable,  Subscription} from 'rxjs';
import { take, filter, map, first, mergeMap } from 'rxjs/operators';

//store actions and reducers
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../store/reducers/index';
import { metaDataExists } from '../../../../fw/dynamic-list/store/selectors/meta-data.selectors';
import * as DynamicObjectActions from '../../../../fw/dynamic-list/store/actions/dynamic-object.actions';

import { getObjectByTypeById } from '../../../../fw/dynamic-list/store/index';
import { ProjectObjectStore } from '../enums/project.enums';
import { IObjectData } from '../../../../fw/dynamic-list/store/interfaces';
import { IDetailRequest } from '../../../../fw/dynamic-detail/interfaces';
import { ProjectService } from '../services';
import { SelectionListService } from '../../../../shared/services/selection-list.service';
import { ISelectResolverData } from '../../../../shared/interfaces/i-select-resolver-data';

@Injectable()
export class WorkOrderInformationResolver  implements OnDestroy {
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public selectionListService: SelectionListService,
    public projectService: ProjectService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const workOrderId: number = +route.paramMap.get('id');
    const projectIdIn: number = +route.paramMap.get('projectId');
    const idFromParent = +route.parent.paramMap.get('portalEntityId');
    const projectId = projectIdIn ? projectIdIn : !idFromParent ? +route.paramMap.get('portalEntityId') : idFromParent;

    this.subscription.add(this.store.pipe(select(getObjectByTypeById(ProjectObjectStore.workOrderInformation, workOrderId)), take(1))
      .subscribe((data: IObjectData) => {
        if (!data || !data.data) {
          let objectRequest: IDetailRequest = this.projectService.loadWorkOrderObjectRequest(workOrderId, this.projectService.woDisplayFields);
          this.store.dispatch(new DynamicObjectActions.GetObject({ detailRequest: objectRequest }));
        }
      }));


    //wait for meta data for this object to load, then request selection list data and wait for it to load.
    return this.metaLoaded().pipe(
      mergeMap((loaded: boolean) => {
        ////for wo selection list, parent will be project id
        let resolverData: ISelectResolverData[] = this.selectionListService.getListNames(ProjectObjectStore.workOrderInformation);
        this.selectionListService.requestListData(ProjectObjectStore.workOrderInformation, projectId);
        resolverData.forEach(x => x.parentId = x.parentDependent ? projectId : -1);

        return this.selectionListService.waitForDataToLoad(resolverData)
          .pipe(
            map((complete: boolean) => { return complete }),
            filter((complete: boolean) => complete === true),
            take(1),
            map(() => {
              return true;
            }),
            first()
          );
      })
    );
  }

  metaLoaded(): Observable<boolean> {
    return this.store.select(metaDataExists(ProjectObjectStore.workOrderInformation))
      .pipe(
        map((exists: boolean) => {
          return exists;
        }),
        filter((exists: boolean) => exists === true),
        take(1),
        map(() => { return true; }),
        first()
      );
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
