import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { orderBy } from 'lodash';

import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { ScheduleEvent, ScheduleAvailability, DurationType, UnavailableType } from '../../enums/schedule.enums';
import { IDateTimeEvent, ICalendarDay, ISlotAvailability } from '../../interfaces';
import { SchedulerService } from '../../services/scheduler.service';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'schedule-timespan',
  templateUrl: './schedule-timespan.component.html'
})
export class ScheduleTimespanComponent implements OnInit, OnChanges {
  @Input() durationType: string;
  @Input() duration: number;
  @Input() pullLength: number;
  @Input() daySelected: ICalendarDay;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public startIdx: number;
  public endIdx: number;
  public hideTimespan: boolean = false;

  constructor(public datePipe: DatePipe,
    public ups: UserPriviledgesService,
    public scheduleService: SchedulerService) { }

  /* Derived property using input slot availability check */
  public get hasSlotAvailability(): boolean {
    return this.daySelected.availability
      && this.daySelected.availability.slotAvailability
      && this.daySelected.availability.slotAvailability.length > 0;
  }

  /* Initial initialization of component */
  ngOnInit(): void {
    this.newRequest(false)
  }

  /* Received a change to an input */
  ngOnChanges(changes: SimpleChanges): void {
    let keyFieldsChanged: boolean = false;
    if (changes['pullLength'] && !(changes['pullLength'].firstChange)
      || changes['duration'] && !(changes['duration'].firstChange)
      || changes['durationType'] && !(changes['durationType'].firstChange)) {
        keyFieldsChanged = true;
        this.newRequest(false);
    }

    if (changes['daySelected'] && !(changes['daySelected'].firstChange)) {
      if (!keyFieldsChanged) {
        this.newRequest(true);
      }
    }
  }

  /*  User clicked on the timespan  */
  public adjustSpan(i: number): void {
    this.getOpenSlotGroup(i, false);
  }

  /*  User clicked on the Set button
 *    When no time has been selected due to unavailable not allowing selection, as in Scheduling on Locked Days, set to first slot
 *      error will be the same for all slot instances in that case. */
  public setTimeSpan(): void {
    const startDate: string = !this.hasSlotAvailability
      ? this.daySelected.availability.startDateTime
      : this.daySelected.availability.slotAvailability[this.startIdx].startTime;
    const selectedDateTime = this.datePipe.transform(new Date(startDate), 'MM/dd/yyyy h:mm a');

    const data: IDateTimeEvent = {
      dateTimeSelected: selectedDateTime,
      hasAvailabilityData: true,
      unavailableType: this.getWorseCaseUnavailabileType(),
      daySelected: null //do not need to set
    };
    this.customEvent.emit({ requestor: 'schedule-timespan', event: ScheduleEvent.setTimespan, action: '', data: data });
  }

  /*  Called when receive new values for key input fields:
   *    durationType, duration, pullLength.
   *    On new request, try to find available first, if do not find, go back and return first slot group
   */
  newRequest(reuseTime: boolean): void {
    if (!this.daySelected || !this.daySelected.availability) {
      return;
    }
    this.hideTimespan = false;
    if (!reuseTime) {
      this.startIdx = this.endIdx = 0;
    }

    //unavailability will only be set to scheduleDayLocked if you cannot schedule on this date
    if (!this.hasSlotAvailability || this.daySelected.availability.unavailabilityType === UnavailableType.scheduleDayLocked) {
      this.startIdx = this.endIdx = 0;
      this.hideTimespan = true;
      this.setTimeSpan();
    } else {
      //try one with existing start time index skipping unavailable
      let success = this.getOpenSlotGroup(this.startIdx, true);
      if (!success) {
        //try two with start of 0 but still skipping unavailable
        success = this.getOpenSlotGroup(0, true);
        if (!success) {
          //try three with start of 0 and just get the first grouping regardless of status
          success = this.getOpenSlotGroup(0, false);
        }
      }
    }
  }

  /* Sets the first slot availability based on Duration Type and Duration.
   *    Slot Availability arrives in 15 minute chunks.
   *    If onlyAvailable is set, then count only consecutive available/warning slots
   *    If reuseTime is set, then on return, return true if incoming and outgoing start index are the same
   */
  getOpenSlotGroup(startIdx: number, onlyAvailable: boolean): boolean {
    const slotIncrement: number = (this.durationType === DurationType.hours ? 4 * this.duration : this.duration / 15);
    const initialStart: number = this.startIdx;
    const len: number = this.daySelected.availability.slotAvailability.length;
    let counter: number = 0;
    let foundOne: boolean = false;

    if (startIdx + slotIncrement > len) {
      //look backward
      for (var i = startIdx; i >= 0; i--) {
        counter = this.daySelected.availability.slotAvailability[i].status === ScheduleAvailability.unavailable && onlyAvailable ? 0 : counter + 1;
        if (counter === slotIncrement) {
          this.startIdx = i;
          this.endIdx = i + slotIncrement - 1;
          foundOne = true;
          break;
        }
      }
    } else {
      //look forward
      for (var i = startIdx; i < len; i++) {
        counter = this.daySelected.availability.slotAvailability[i].status === ScheduleAvailability.unavailable && onlyAvailable ? 0 : counter + 1;
        if (counter === slotIncrement) {
          this.startIdx = (i + 1) - slotIncrement;
          this.endIdx = i;
          foundOne = true;
          break;
        }
      }
    }

    //If callee needs to know if found a valid match, can listen to return
    if (foundOne && this.endIdx === (this.startIdx + slotIncrement - 1)) {
      //valid grouping
      return true;
    } else {
      return false;
    }
  }

  /* Return the worse case unavailability for the selected grouping  */
  getWorseCaseUnavailabileType(): UnavailableType {
    let filtered: ISlotAvailability[] = this.daySelected.availability.slotAvailability.filter((x, i) => i >= this.startIdx && i <= this.endIdx);
    if (!this.hasSlotAvailability) {
      return this.daySelected.availability.unavailabilityType;
    }

    const sorted = orderBy(filtered, ['unavailabilityRank'], ['desc']);

    return sorted.length > 0 ? sorted[0].unavailabilityType : this.daySelected.availability.unavailabilityType;
  }

}
