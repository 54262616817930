<div *ngIf="mds.fieldDefinitionsLoaded" class="technician-create">
  <form [formGroup]="technicianForm" novalidate class="technician-create__form">
    <fw-dynamic-field class="technician-create__form--desc"
                      [field]="mds.getFieldDefinition('technicianDescription')"
                      [form]="technicianForm"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field class="technician-create__form--active"
                      [field]="mds.getFieldDefinition('isActive')"
                      [form]="technicianForm"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field class="technician-create__form--lead"
                      [field]="mds.getFieldDefinition('isWorkCrewLead')"
                      [form]="technicianForm"
                      [operation]="operation">
    </fw-dynamic-field>
  </form>
</div>
