export interface IGeneralContractor {
  generalContractorId: number;
  generalContractorName: string;
  provider_providerId: number;
  lienWaiver: string;
  providerMarginLow: number;
  providerMarginHigh: number;
  poNumberPrefixType: string;
  poNumFieldPrefix: string;
  poNumberFormatType: string;
  poNumFieldLength: number;
  poNumRandomGenerated: string;
  hasExternalInterface: boolean;
  interfaceBaseUrl: string;
  clientId: string;
  clientSecret: string;
  adjustMinimumSku_skuId: number;
  measureSku_skuId: number;
  approvalQueueTransaction_approvalQueueTransactionId: number;
  poImportDirectory: string;
}

export class GeneralContractor implements IGeneralContractor {
  generalContractorId: number;
  generalContractorName: string;
  provider_providerId: number;
  lienWaiver: string;
  providerMarginLow: number;
  providerMarginHigh: number;
  poNumberPrefixType: string;
  poNumFieldPrefix: string;
  poNumberFormatType: string;
  poNumFieldLength: number;
  poNumRandomGenerated: string;
  hasExternalInterface: boolean;
  interfaceBaseUrl: string;
  clientId: string;
  clientSecret: string;
  adjustMinimumSku_skuId: number;
  measureSku_skuId: number;
  approvalQueueTransaction_approvalQueueTransactionId: number;
  poImportDirectory: string;

  constructor() {
    this.generalContractorId = 0;
    this.provider_providerId = null;
    this.generalContractorName = '';
  }
}
