import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppRegExp {
  numberPattern: RegExp = /^-?\d*$/;
  decimalPattern: RegExp = /^-?\d*\.?\d*$/;

  constructor() { }
}
