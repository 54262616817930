<div *ngIf="createForm">
  <form [formGroup]="createForm" novalidate>
    <fw-dynamic-field [field]="mds.getFieldDefinition('providerLocation')"
                      [form]="createForm"
                      [operation]="operation"
                      [selectItems]="providerLocations">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('service')"
                      [form]="createForm"
                      [selectItems]="availableServices$ | async"
                      [operation]="!(availableServices$ | async) || !(availableServices$ | async).length ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
  </form>
  <fw-crud-buttons [operation]="operation"
                   [disabled]="!createForm.valid"
                   showNext="false"
                   (cancelEvent)="cancel()"
                   (createEvent)="create()">
  </fw-crud-buttons>
</div>
