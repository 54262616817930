        

<div class="app-btn-box" >
    <button type="button" class="app-btn app-btn--cancel" [title]="operation === myConstants.operationTypeDetails ? 'Close' : 'Cancel'" (click)="onCancel()" >
        {{ operation === myConstants.operationTypeDetails ? 'Close' : 'Cancel' }}
    </button>
    <ng-container *ngIf="operation === myConstants.operationTypeCreate 
                      || operation === myConstants.operationTypeEdit  
                      || operation === myConstants.operationTypeSingleEdit" >
     
        <button *ngIf="operation === myConstants.operationTypeEdit" type="button" class="app-btn app-btn--primary" title="Save"
                (click)="onSave('hold')" [disabled]="disabled">
            Save
        </button>
        <button *ngIf="operation === myConstants.operationTypeEdit  || operation === myConstants.operationTypeSingleEdit" type="button" class="app-btn app-btn--primary"
                [title]="operation === myConstants.operationTypeEdit ? 'Save & Close' : 'Save' "
                (click)="onSave('close')" [disabled]="disabled">
            {{ operation === myConstants.operationTypeEdit ? 'Save & Close' : 'Save' }}
        </button>
        <button *ngIf="operation === myConstants.operationTypeEdit " type="button" class="app-btn app-btn--primary"
                title="Save and Next"
                (click)="onSave('next')" [disabled]="disabled || !showNext">
            Save & Next
        </button>
        <button *ngIf="operation === myConstants.operationTypeCreate" type="button" class="app-btn app-btn--primary"
                title="Create"
                (click)="onCreate()" [disabled]="disabled">
            Create
        </button>
    </ng-container>
</div>
