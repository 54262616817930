// Resolver if going from outside installer portal into a tab request for a technician
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, filter, map, first, mergeMap } from 'rxjs/operators';

import { IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';
import { IPoImportHeaderViewModel, IPoImportHeader } from '../../view-models';
import { IDynamicListState, IListObjectData } from '../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { PoImportStore } from '../enums/po-import.enums';

import { PoImportService } from '../services/po-import.service';
import { SelectionListService } from '../../../../shared/services/selection-list.service';

//store actions and reducers
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../store/reducers/index';
import * as fromDynamicList from '../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as LoadingIndicatorActions from '../../../../shared/store/loadingIndicator/loadingIndicator.actions';
import { metaDataExists } from '../../../../fw/dynamic-list/store/selectors/meta-data.selectors';
import { ISelectResolverData } from '../../../../shared/interfaces/i-select-resolver-data';

@Injectable()
export class PoImportHeaderResolver  {
  storeName: string;
  groupedStoreName: string;
  vendorOrderNumber: string = '';
  branchName: string = '';
  uniqueId: number;
  requested: boolean = false;
  haveKeys$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public poImportService: PoImportService,
    public selectionListService: SelectionListService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.requested = false;
    this.uniqueId = +route.paramMap.get('id');
    this.storeName = PoImportStore.poImportHeaders;
    this.groupedStoreName = route.paramMap.get('storeName');

    this.store.pipe(select(fromDynamicList.getSelectedRecord(this.groupedStoreName, -1, 'uniqueId', this.uniqueId)))
      .pipe(take(1))
      .subscribe((row: IPoImportHeaderViewModel) => {
        if (row) {
          this.vendorOrderNumber = row.vendorOrderNumber;
          this.branchName = row.branchName;

          this.haveKeys$.next(true);
        }
      });

    this.haveKeys$.subscribe((val) => {
      if (val) {
        this.getData();
      }
    });

    //wait for meta data for this object to load, then request selection list data and wait for it to load.
    return this.metaLoaded().pipe(
      mergeMap((loaded: boolean) => {
        let resolverData: ISelectResolverData[] = this.selectionListService.getListNames(this.storeName);
        this.selectionListService.requestListData(this.storeName, -1);
        resolverData.forEach(x => x.parentId = -1);

        return this.selectionListService.waitForDataToLoad(resolverData)
          .pipe(
            map((complete: boolean) => { return complete }),
            filter((complete: boolean) => complete === true),
            take(1),
            map(() => {
              return true;
            }),
            first()
          )
      })
    );
  }

  getData(): void {
    this.store.pipe(select(fromDynamicList.getListByType(PoImportStore.poImportHeaders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.uniqueId)), take(1))
      .subscribe((entityList: IListObjectData) => {
        if (!this.requested) {
          if (!entityList || !entityList.data) {
            this.dispatch();
          } else if (entityList.data.length > 0) {
            let data: IPoImportHeader = entityList.data.find(x => x['vendorOrderNumber'] == this.vendorOrderNumber && x['branchName'] == this.branchName);
            if (!data) {
              this.dispatch();
            } 
          }
        }
      });
  }

  dispatch(): void {
    this.requested = true;
    const listDefinition: IListDefinition = this.poImportService.loadPoImportHeaderListDefinition(this.vendorOrderNumber, this.branchName);
    this.store.dispatch(new fromStore.InitializeMetaData({ storeName: this.storeName }));
    this.store.dispatch(new fromStore.InitializeList({ storeName: this.storeName, parentId: -1, forOnDestroy: false }));

    this.store.dispatch(new fromStore.SetListDefinition({ storeName: this.storeName, parentId:-1, listDefinition: listDefinition }));
    this.store.dispatch(new fromStore.GetList({ listDefinition: listDefinition, listFilter: listDefinition.defaultListFilter, parentId: -1}));
  }

  metaLoaded(): Observable<boolean> {
    return this.store.select(metaDataExists(this.storeName))
      .pipe(
        map((exists: boolean) => {
          return exists;
        }),
        filter((exists: boolean) => exists === true),
        take(1),
        map(() => { return true; }),
        first()
      );
  }

}
