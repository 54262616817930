import { Component, OnInit, OnDestroy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IUnitMeasureXmlMapping } from '../../../view-models/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { UpdateObjectCustomModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';

//store actions, reducers, interfaces
import { getSelectedRecord, getSelectedParentListDefinition } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import {  UpdateObjectCustomList } from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  selector: 'unit-measure-xml-mapping',
  templateUrl: './unit-measure-xml-mapping.component.html'
})
export class UnitMeasureXmlMappingComponent implements OnInit, OnDestroy  {
  parentId: number = -1;  
  myPathName: string = '';
  myStoreName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string;
  subscription: Subscription = new Subscription();

  public objectData: IUnitMeasureXmlMapping;
  public displayFields = ['unitMeasure', 'mappingText'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  constructor(public  activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');

      this.setDetailConfig(paramMap);
      this.getDetail();
    }));

    this.subscription.add(this.emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          default:
            break;
        }
      }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.parentId;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(this.myStoreName, this.parentId, this.key, this.objectId)))
      .subscribe(entity => {
        this.objectData = cloneDeep(entity);
      })
    );

    this.subscription.add(this.store
      .pipe(select(getSelectedParentListDefinition(this.myStoreName, this.parentId)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.myPathName = listDefinition.detailRoutePath;
        this.controllerName = listDefinition.controllerName;
      }
    ));
  }

  updateRecord(e: IHomEventEmitter) {
    let rec: IUnitMeasureXmlMapping = this.setCommonProperties(e.data);

      const keyData: IKey = { storeName: this.myStoreName, parentId: this.parentId, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
      const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', rec, null, emitter);
      this.store.dispatch(new UpdateObjectCustomList({ updateData }));
    }

  setCommonProperties(formData: IUnitMeasureXmlMapping): IUnitMeasureXmlMapping {

    let rec = cloneDeep(this.objectData);
    rec.unitMeasure_unitMeasureId = formData['unitMeasure']['unitMeasureId'];
    rec.updateDate = new Date().toDateString();
    return rec;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
