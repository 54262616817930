import { IPortalTab } from "../../../view-models";
const managerPortalName: string = 'user-jobs-tabs';
export let UserJobsTabs: IPortalTab[] = [
  {
    portalLevel: 1,
    portalTabContainerId: -1,
    portalTabId: 0,
    managerPortalName: managerPortalName,
    managerPortalId: -1,
    tabLabel: 'My Alerts',
    tabIcon: 'fas fa-bell',
    isDefault: true,
    isDynamic: false,
    routeName: 'user-alerts',
    securityDependent: false,
    componentName: '',
    entityName: '',
    tabOrder: 1,
    entityId: 0
  },
  {
    portalLevel: 1,
    portalTabContainerId: -1,
    portalTabId: 1,
    managerPortalName: managerPortalName,
    managerPortalId: -1,
    tabLabel: 'My Jobs',
    tabIcon: 'fas fa-list-alt',
    isDefault: false,
    isDynamic: false,
    routeName: 'user-jobs',
    securityDependent: false,
    componentName: '',
    entityName: '',
    tabOrder: 2,
    entityId: 0
  },
  {
    portalLevel: 1,
    portalTabContainerId: -1,
    portalTabId: 2,
    managerPortalName: managerPortalName,
    managerPortalId: -1,
    tabLabel: 'Schedules',
    tabIcon: 'fas fa-calendar-alt',
    isDefault: false,
    isDynamic: false,
    routeName: 'user-schedules',
    securityDependent: false,
    componentName: '',
    entityName: '',
    tabOrder: 3,
    entityId: 0
  }
]
