export interface IProjectContactMechanism {
  projectContactMechanismId: number;
  project_projectId: number;
  contactMechanism_contactMechanismId: number;
  createDate: string;
  updateDate: string;

  //unique object extra data can be composed of address structure, email structure or phone structure
  addressType: string;
  addressLine1: string;
  addressLine2: string;
  addresssCity: string;
  sanitizeOveride: boolean;
  latitude: number;
  longitude: number;
  addressState: string;
  zoneName: string;
  addressZip: string;
  yearBuilt: string;

  phoneType: string;
  phoneNumber: string;

  emailType: string;
  emailAddress: string;
}


export class ProjectContactMechanism implements IProjectContactMechanism {
  projectContactMechanismId: number;
  project_projectId: number;
  contactMechanism_contactMechanismId: number;
  createDate: string;
  updateDate: string;

  //extraData
  addressType: string;
  addressLine1: string;
  addressLine2: string;
  addresssCity: string;
  sanitizeOveride: boolean;
  latitude: number;
  longitude: number;
  addressState: string;
  zoneName: string;
  addressZip: string;
  yearBuilt: string;

  phoneType: string;
  phoneNumber: string;

  emailType: string;
  emailAddress: string;

  constructor(project_projectId: number,
    contactMechanism_contactMechanismId: number  ) {
    this.projectContactMechanismId = 0;
    this.project_projectId = project_projectId;
    this.contactMechanism_contactMechanismId = contactMechanism_contactMechanismId;
  }
}
