<fw-component-title [title]="'Add Required Document'"></fw-component-title>
<div class="component--wrapper form-wrapper form-wrapper--edit">
  <required-document-create [documentTypes]="documentTypes$ | async"
                            [projectChildren]="applicableChildren$ | async"
                            [storeName]="myStoreName"
                            [detailConfig]="detailConfig$ | async"
                            (changeEvent)="onChange($event)"
                            (cancelEvent)="onCancel()"
                            (createEvent)="onCreate($event)">
  </required-document-create>
  <fw-error-box [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>
