import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject  } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import {  map } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { IKey, UpdateObjectCustomModel, DeleteObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IPurchaseOrder } from '../../../view-models';
import { IErrorData } from '../../../../../shared/interfaces';
import { IListObjectData, IDynamicListState } from '../../../../../fw/dynamic-list/store';
import { ProjectEvent, ProjectListStore } from '../../enums/project.enums';

import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ProjectService } from '../../services/project.service';
import { UserPriviledgesService } from '../../../../../auth/services/index';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import {  getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';

@Component({
  selector: 'project-purchase-orders',
  templateUrl: './project-purchase-orders.component.html'
})
export class ProjectPurchaseOrdersComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() canIEdit: boolean;
  @Input() purchaseOrders: IPurchaseOrder[];
  @Input() errorData: IErrorData[];

  public selectedPos: IPurchaseOrder[] = [];
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public ready$: Observable<boolean>;
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subscription = new Subscription();

  constructor(
    public projectService: ProjectService,
    public modalService: ModalService,
    public userPriviledgesService: UserPriviledgesService,
    public store: Store<fromFeature.IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public closePOs(bypassPriceCheck: boolean = false): void {
    const def = this.listDefinition$.getValue();
    const keyData: IKey = { storeName: def.storeName, parentId: this.projectId, key: def.rowKeyId, id: this.selectedPos[0].purchaseOrderId, skipDataUpdate: true };
    const emitter: IHomEventEmitter = { requestor: 'project-purchase-orders', event: this.myConstants.emitterEventListReloadPortal, action: '', data: null };
    const updateObj = { purchaseOrderIds: this.selectedPos.map(x => x.purchaseOrderId), bypassExternalPriceCheck: bypassPriceCheck }
    const updateData = new UpdateObjectCustomModel(keyData, def.controllerName, 'ClosePurchaseOrders', updateObj, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
    this.selectedPos = [];
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case ProjectEvent.addNote:
        this.addNote(event.data);
        break;
      case ProjectEvent.uploadExternalDoc:
        this.uploadExternalDoc(event.data);
        break;
      case ProjectEvent.viewMeasures:
        this.viewMeasures(event.data);
        break;
      case ProjectEvent.peekPOItemSummary:
        this.showMiniItemSummary(event.data);
        break;
      case ProjectEvent.deletePurchaseOrder:
        const data: IPurchaseOrder = event.data;
        if (confirm('Are you sure you want to delete Purchase Order ' + data.purchaseOrderNumber.toString())) {
          this.deletePurchaseOrder(event.data);
        }
        break;
      case ProjectEvent.viewExternalData:
        this.viewExternalData(event.data);
      default:
        break;
    }
  }

  public onSelect(event: IHomEventEmitter) {
    switch (event.event) {
      case ProjectEvent.closePurchaseOrder:
        this.updateSelected(event);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.newRequest();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] && !changes['projectId'].isFirstChange()) {
      this.selectedPos = [];
      this.newRequest();
    }
    if (changes['purchaseOrders'] && !changes['purchaseOrders'].isFirstChange()) {
      this.selectedPos = [];
    }
  }

  newRequest(): void {
    let listDefinition = this.projectService.loadProjectPurchaseOrderListDefinition(this.projectId, this.canIEdit);
    listDefinition.noGet = true;
    this.listDefinition$.next(listDefinition);

    //listen for working, loading will be handled by dynamic-list, but since this is a noGet, need to check working to set the mask correctly
    this.subscription.add(this.store.pipe(select(getListByType(listDefinition.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == listDefinition.parentId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          this.working$.next(state.working);
        }
      }));

  }

  addNote(data: IPurchaseOrder): void {
    const poId: number = data && data.hasOwnProperty('purchaseOrderId') ? data['purchaseOrderId'] : 0;
    if (poId === 0) {
      //console.log('DEV ERROR:  missing po id on event', event);
      return;
    }

    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: 'purchaseOrderNotes',
      key: 'purchaseOrder_purchaseOrderId',
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.projectId
    }
    this.modalService.open({ title: 'Purchase Order Notes', path: 'purchase-order-notes', id: data.purchaseOrderId, optionalParams: params, onExit: null, castExit: false, hasTabs: false });
  }

  
  uploadExternalDoc(data: IPurchaseOrder): void {
    const poId: number = data && data.hasOwnProperty('purchaseOrderId') ? data['purchaseOrderId'] : 0;
    if (poId === 0) {
      //console.log('DEV ERROR:  missing po id on event', event);
      return;
    }

    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.purchaseOrderFiles,
      key: 'purchaseOrder_purchaseOrderId',
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.projectId
    }
    this.modalService.open({
      title: 'Upload External Document For Purchase Order '.concat(data.purchaseOrderNumber.toString()),
      path: 'po-files',
      id: data.purchaseOrderId,
      sizeType: ModalSizeType.medium,
      onExit: this.reloadProjectFiles,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

  viewExternalData(data: IPurchaseOrder): void {
    this.modalService.open({
      title: 'View External Data For Purchase Order '.concat(data.purchaseOrderNumber.toString()),
      path: 'po-external-data/' + data.purchaseOrderId,
      id: data.purchaseOrderId,
      sizeType: ModalSizeType.medium,
      onExit: this.reloadProjectFiles,
      castExit: false,
      hasTabs: false
    });
  }

  reloadProjectFiles(): void {

  }

  viewMeasures(data: IPurchaseOrder): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.purchaseOrderFiles,
      key: 'purchaseOrderId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.projectId
    }

    this.modalService.open({
      title: 'Measures for Purchase Order'.concat(' ', data.purchaseOrderNumber.toString()),
      path: 'po-measures',
      id: data.purchaseOrderId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }


  showMiniItemSummary(data: IPurchaseOrder): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.purchaseOrderItems,
      key: 'purchaseOrder_purchaseOrderId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.projectId
    }

    this.modalService.open({
      title: 'Line Item Summary for PO'.concat(' ', data.purchaseOrderNumber.toString()),
      path: 'project-purchase-order-item-summary',
      id: data.purchaseOrderId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

  deletePurchaseOrder(data: IPurchaseOrder): void {
    const listDefinition = this.projectService.loadProjectPurchaseOrderListDefinition(this.projectId, this.canIEdit);

    const keyData: IKey = { storeName: listDefinition.storeName, parentId: listDefinition.parentId, key: listDefinition.rowKeyId, id: data.purchaseOrderId }
    const event: IHomEventEmitter = { requestor: 'project-purchase-orders', event: this.myConstants.emitterEventDelete, action: '', data: null };

    const api: string = '/Api/PurchaseOrder/Delete/'.concat(data.purchaseOrderId.toString());
    const deleteData = new DeleteObjectModel(keyData, api, event);

    this.store.dispatch(new DynamicListActions.DeleteObjectByUrlList({ deleteData }));
  }

  updateSelected(event: IHomEventEmitter): void {
    const po: IPurchaseOrder = event.data;
    const exists: number = this.selectedPos.findIndex(x => x.purchaseOrderId === po.purchaseOrderId);
    if (event.action) {
      if (exists === -1) {
        this.selectedPos.push(po);
      }
    } else {
      if (exists > -1) {
        this.selectedPos.splice(exists, 1);
      }
    }
  }

}
