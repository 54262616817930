import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { AdminStore, ServiceGroupCreationWizardEvent, ServiceGroupCreationWizardAction } from '../../enums/admin.enums';
import { appConstants, IAppConstants } from '../../../../../shared/constants';

@Component({
  selector: 'service-group-creation-wizard',
  templateUrl: './service-group-creation-wizard.component.html'
})

export class ServiceGroupCreationWizardComponent implements OnInit, OnDestroy {

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public serviceGroupName$: BehaviorSubject<string> = new BehaviorSubject(null);
  subscription: Subscription = new Subscription();

  ngOnInit() {
    this.router.navigate([{ outlets: { wizard: ['service-group-create', -1] } }], { relativeTo: this.activeRoute, skipLocationChange: true });
    this.subscription.add(this.emitterService.serviceGroupWizardEventEmitted$.subscribe((e: IHomEventEmitter) => {
      this.handleEvent(e);
    }));
  }

  handleEvent(e: IHomEventEmitter) {
    switch (e.event) {
      case ServiceGroupCreationWizardEvent.serviceGroupCreated:
        switch (e.action) {
          case ServiceGroupCreationWizardAction.next:
            const id = e.data.serviceGroupId;
            let params: IDetailRouteParameter = {
              rowIndex: -1,
              storeName: AdminStore.serviceGroupServices,
              key: 'serviceGroupServiceId',
              operation: this.myConstants.operationTypeCreate,
              showNext: false,
              showPrev: false,
              requestTime: new Date(),
              portalEntityId: -1
            };
            this.serviceGroupName$.next(e.data.serviceGroupName);
            this.router.navigate([{ outlets: { wizard: ['service-group-service-create', id, params] } }], { relativeTo: this.activeRoute, skipLocationChange: true });
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
