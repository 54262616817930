<div class="search-bar" *ngIf="fcs.showSearchBar">
  <form class="search-bar__form" (ngSubmit)="search(1)">
    <input #searchInput
           [(ngModel)]="value"
           [ngModelOptions]="{standalone: true}"
           class="search-bar__form__input"
           placeholder="Search..."
           [ngClass]="{'search-bar__form--invalid': invalid}"
           (keyup)="onKeyup(searchInput.value)">
    <span class="search-bar__icon">
      <i *ngIf="!loading"
         class="fal fa-search search-bar__icon__btn"
         [ngClass]="{'search-bar__icon--btn--disabled': searchInput.value.length < 4}"
         (click)="search(1)">
      </i>
      <i *ngIf="loading" class="fal fa-spinner search-bar__icon--loading"></i>
    </span>
  </form>

    <div class="search-bar__history" *ngIf="!loading && !ready && searchInput.value">
        <div class="search-bar__history__item" *ngFor="let item of container">
          <div class="search-bar__history__item__txt" (click)="getListFromHistory(item, 1)" title="Go to {{ item }}">
            {{ item.substring(0, searchInput.value.length) }}<span>{{ item.substring(searchInput.value.length, item.length) }}</span>
          </div>
            <button class="search-bar__history__item__btn app-btn-icon app-icon--danger" (click)="removeFromHistory(item)" title="Remove {{ item }}">
              <i class="far fa-times"></i>
            </button>
        </div>
    </div>
    <div class="search-bar__results" *ngIf="ready">
      <div class="search-bar__results__header">
        <button class="search-bar__results__header__btn app-btn-icon" (click)="clearDetail()" title="Go back" *ngIf="detail !== null">
          <i class="far fa-angle-left"></i>
        </button>
        <span class="search-bar__results__header__txt" *ngIf="!errorData">{{ response.metaData.pageMetaData.totalItems }} result<span *ngIf="response.metaData.pageMetaData.totalItems !== 1">s </span> for "<span class="search-bar__results__header--term">{{ term }}</span>"</span>
        <button class="search-bar__results__header__btn app-btn-icon app-icon--danger" title="Clear" (click)="clear(searchInput)">
          <i class="far fa-times"></i>
        </button>
      </div>
      <div class="search-bar__results__list" *ngIf="detail === null">
        <div class="search-bar__results__list__item" *ngFor="let item of response.data">
          <span class="search-bar__results__list__item__label dynamic-list__item--link" (click)="openLink(item, false, false)" title="Go to {{ item.entityName === 'Installer' ? item.contactName : item.entityId }}">{{ item.label }}</span>
          <div class="search-bar__results__list__item__sub-label">
            <span [ngClass]="{'is-link': item.subEntityName === 'WorkOrder' || item.subEntityName === 'PurchaseOrder'}"
                  (click)="openLink(item, true, (item.subEntityName == null))"
                  title="{{ item.subEntityName == null ? '' : ('Go to ' + item.childEntityId) }}">{{ item.subLabel }}
            </span>&nbsp;|&nbsp;
            <span class="search-entity-status"
                  [ngClass]="{'search-entity-status--green': item.entityStatus == 'open' || item.entityStatus == 'active' || item.entityStatus == 'work complete', 'search-entity-status--red': item.entityStatus == 'closed' || item.entityStatus == 'inactive'}">
              {{ item.entityStatus | uppercase }}
            </span>&nbsp;|&nbsp;{{ item.branchName }}
          </div>
          <div class="search-bar__results__list__item__btn">
            <button class="search-bar__results__list__item__btn__btn app-btn-icon"
                    (click)="showDetail(item)"
                    title="Show details for {{ item.entityName === 'Installer' ? item.contactName : item.entityId }}">
              <i class="far fa-angle-right"></i>
            </button>
          </div>
        </div>
        <div class="search-bar__results__overlay" [ngClass]="{'search-bar__results__overlay--loading': loading}">
          <i class="fal fa-spinner"></i>
        </div>
      </div>
      <div class="search-bar__results__error" *ngIf="errorData">
        <fw-error-box [errors]="errorData"></fw-error-box>
        <div class="search-bar__results__error__info">
          <fw-info [infoTitle]="'Valid formats'">
            <div class="app-info app-info--options">
              <div><i class="fas fa-square-full app-icon--xs app-info--icon"></i>You can search by project, WO, PO number, name, phone or email, for a customers projects, or an installer.</div>
              <div><i class="fas fa-square-full app-icon--xs app-info--icon"></i>Only one search term is allowed.</div>
              <div><i class="fas fa-square-full app-icon--xs app-info--icon"></i>A name search with quotes requires 2 terms.</div>
            </div>
          </fw-info>
        </div>
      </div>
      <div class="search-bar__results__detail" *ngIf="detail !== null">
        <div class="search-bar__results__detail__contact">
          <i class="fas fa-user-circle search-bar__results__detail__contact__icon"></i>
          <span class="search-bar__results__detail__contact__txt">{{ detail.contactName }}</span>
        </div>
        <div class="search-bar__results__detail__contact">
          <i class="fas fa-globe-americas search-bar__results__detail__contact__icon"></i>
          <span class="search-bar__results__detail__contact__txt">{{ detail.contactAddress == null ? '' : detail.contactAddress }}</span>
        </div>
        <div class="search-bar__results__detail__pcm-loading" *ngIf="!contact$.value">
          <i class="fal fa-spinner search-bar__icon--loading"></i>
        </div>
        <div class="search-bar__results__detail__cmp" *ngIf="(contact$ | async) as contact">
          <fw-communication-preference [row]="contact"
                                       [forHighLevelSummary]="true"
                                       [displayType]="ces.displayTypeBoth">
          </fw-communication-preference> 
        </div>
        <div class="search-bar__results__detail__contact">
          <span class="search-bar__results__detail__contact__link dynamic-list__item--link" (click)="openLink(detail, false)" title="Go to {{ detail.entityName === 'Installer' ? detail.contactName : detail.entityId }}">{{ getWarrantyLabel(detail.label) }}{{ detail.entityName === 'Installer' ? detail.contactName : 'Project ' + detail.entityId }}</span>
          <div class="search-entity-status search-entity-status--detail" [ngClass]="{'search-entity-status--green': detail.entityStatus == 'open' || detail.entityStatus == 'active' || detail.entityStatus == 'work complete', 'search-entity-status--red': detail.entityStatus == 'closed' || detail.entityStatus == 'inactive'}">{{ detail.entityStatus | uppercase }}</div>
        </div>
        <div class="search-bar__results__detail__con">
          <div class="search-bar__results__detail__con__header">
            <div class="search-bar__results__detail__con__header__item" *ngIf="detail.entityName !== 'Installer'">
              <fw-section-title title="Work Orders" [underline]="true"></fw-section-title>
            </div>
            <div class="search-bar__results__detail__con__header__item" *ngIf="detail.entityName !== 'Installer'">
              <fw-section-title title="Purchase Orders" [underline]="true"></fw-section-title>
            </div>
            <div class="search-bar__results__detail__con__header__item search-bar__results__detail__con__header__item--installer" *ngIf="detail.entityName === 'Installer'">
              <fw-section-title title="Open Projects" [underline]="true"></fw-section-title>
            </div>
          </div>
          <div class="search-bar__results__detail__con__items">
            <div class="search-bar__results__detail__con__items__con" *ngIf="detail.entityName !== 'Installer'">
              <div class="search-bar__results__detail__con__items__con__item" *ngFor="let wo of detail.projectInfo.workOrders.workOrderIds" (click)="openDetailLink(wo, true)" title="Go to {{ wo }}">
                <span class="search-bar__results__detail__con__items__con__item__txt">{{ wo }}</span>
              </div>
            </div>
            <div class="search-bar__results__detail__con__items__con" *ngIf="detail.entityName !== 'Installer'">
              <div class="search-bar__results__detail__con__items__con__item" *ngFor="let po of detail.projectInfo.purchaseOrders.purchaseOrders" (click)="openDetailLink(po, false)" title="Go to {{ po.purchaseOrderNumber }}">
                <span class="search-bar__results__detail__con__items__con__item__txt" href="javascript: void(0)">{{ po.purchaseOrderNumber }}</span>
              </div>
            </div>
            <div class="search-bar__results__detail__con__items__con search-bar__results__detail__con__items__con--installer" *ngIf="detail.entityName === 'Installer'">
              <div class="search-bar__results__detail__con__items__con__item" *ngFor="let item of detail.installerInfo.projects" (click)="openDetailLink(item.projectId, false)" title="Go to {{ item.projectId }}">
                <span class="search-bar__results__detail__con__items__con__item__txt">{{ item.label }}</span><br />
                <span class="search-bar__results__detail__con__items__con__item__date">Schedule date: {{ item.scheduleDate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pagination *ngIf="ready && detail == null && response.metaData && response.metaData.pageMetaData" [config]="response.metaData.pageMetaData"
                  (action)="getPagedList($event)"
                  [restrict]="loading">
      </pagination>
    </div>
</div>



