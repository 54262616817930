import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import {  map,  filter, take } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IErrorData } from '../../../../../shared/interfaces/index';
import { GcInstallerRequirement } from '../../../view-models/index';
import { CreateObjectModel, UpdateObjectByIdModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';

import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';

@Component({
  selector: 'gc-installer-requirement-detail',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './gc-installer-requirement-detail.component.html'
})
export class GcInstallerRequirementDetailComponent implements OnInit, OnDestroy {

  myPathName: string = '';
  myStoreName: string = '';
  activeIndex: number = -1;
  key: string = ''; //certificationId, gcInstallerRequirementId, etc.
  operation: string;
  objectId: number = 0;
  controllerName: string;

  public objectData$: Observable<GcInstallerRequirement>;
  public displayFields =['installerRequirementName', 'required', 'installerRequirementDescription', 'generalContractor', 'requirementLevel', 'validatesParent'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  errorMessage: string = '';
  errorData: IErrorData[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {

    this.subscription.add(emitterService.detailEventEmitted$.subscribe(
      (e: IHomEventEmitter) => {
        if (e.requestor !== this.myPathName) {
          return;
        } //can change this to create event listeners for each object controller in stead

        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case this.myConstants.emitterEventCreate:
            this.createRecord(e);
            break;
          case this.myConstants.emitterEventClose:
            this.emitterService.emitListEvent(e);
            break;
          default:
            break;
        }
      }));
  }

  ngOnInit() {
   this.activeRoute.paramMap.subscribe(paramMap => {
      this.activeIndex = +paramMap.get('rowIndex');
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.setDetailConfig(paramMap);
      this.getDetail();
   });
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    this.detailConfig$.next(params);
  }

  getDetail(){
    this.objectData$ = this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.myStoreName, -1, this.key, this.objectId)),
      map(entity => {
        return entity === undefined && this.operation === this.myConstants.operationTypeCreate ? new GcInstallerRequirement() : entity;
      }));

    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.myStoreName, -1)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
          this.myPathName = listDefinition.detailRoutePath;
          this.controllerName = listDefinition.controllerName;
        }));
  }
  
  /*
      Insert the new record
  */
  createRecord(e: IHomEventEmitter) {
    let newRec =  this.setCommonProperties(e.data);

    //Set create specific defaults
    newRec.createDate = new Date().toDateString();
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.myStoreName, -1, this.controllerName, 'Create', newRec, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  /*
      Update the record
  */
  updateRecord(e: IHomEventEmitter) {
    let newRec =  this.setCommonProperties(e.data);
    newRec.generalContractorInstallerRequirementId = this.objectId;
    const keyData: IKey = { storeName: this.myStoreName, parentId: -1, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectByIdModel(keyData, this.controllerName, 'Update', this.key, this.objectId, newRec, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectByIdList({ updateData }));
  }

  /*
      Set common create/update properties
  */
  setCommonProperties(formData: GcInstallerRequirement) {
    let newRec = new GcInstallerRequirement();

    newRec.required = formData.required;
    newRec.installerRequirementName = formData.installerRequirementName;
    newRec.installerRequirementDescription = formData.installerRequirementDescription;
    newRec.validatesParent = formData.validatesParent;
    //    //Special handling for enum
    if (formData.hasOwnProperty('requirementLevel')) {
      newRec.requirementLevel = formData.requirementLevel['name'];
    }
    //Special handling for fkey
    if (formData.hasOwnProperty('generalContractor')) {
      newRec.generalContractor_generalContractorId = formData.generalContractor['generalContractorId'];
      newRec.generalContractorName = formData.generalContractor['generalContractorName'];
    }

    newRec.updateDate = new Date().toDateString();
    return newRec;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
