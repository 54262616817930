import * as fromLoadingIndicator from './loadingIndicator.actions';

export interface IState {
  requestor: string;
  id: number;
  show: boolean;
}

const initialState: IState = {
  requestor: '',
  id: -1,
  show: false
};

export function loadingIndicatorReducer(state: IState = initialState, action: fromLoadingIndicator.LoadingIndicatorActions): IState {
  switch (action.type) {
    case fromLoadingIndicator.SPINNER_HIDE:
      //console.log('loadingIndicator HIDE with: ', action.payload);
      return { ...state, 
        requestor: action.payload.requestor,
        id: action.payload.id,
        show: false
      }

    case fromLoadingIndicator.SPINNER_SHOW:
      //console.log('loadingIndicator SHOW with: ', action.payload);
      return { ...state, 
        requestor: action.payload.requestor,
        id: action.payload.id,
        show: true
      }
    default:
      return state;
  }
}

