  <div [ngClass]="{ 'loading-mask': working$ | async }">
    <form *ngIf="workingInventoryOrders">
      <fw-component-title [title]="title"></fw-component-title>
      <table class="app-table">
        <thead>
          <tr>
            <th>Materials</th>
            <th>Job Req</th>
            <th>Un-Allocated</th>
            <th>Buffer</th>
            <th>Order Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of workingInventoryOrders; let indx = index;">
            <td>
              {{ row.workOrderItemName }}
            </td>
            <td>{{ row.orderAmountEstimate }}</td>
            <td>{{ row.unallocated }}</td>
            <td>{{ row.buffer }}</td>
            <td>
              <input type="text" digitOnlyMask #orderAmount class="small-input"
                     [disabled]="!canOrderMoreThanRecommendedInventory"
                     [value]="row.orderAmount" />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="app-btn-box">
        <button class="app-btn app-btn--primary"
                type="submit"
                (click)="submitOrder()">
          Submit Order
        </button>
      </div>
      <fw-error-box *ngIf="(errorData$ | async)?.length" [errorData]="errorData$ | async" [joinErrors]="true"></fw-error-box>
    </form>
  </div>
