import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';
import { AdminEvent } from '../../enums/admin.enums';

@Component({
  selector: 'document-types',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './document-types.component.html'
})
export class DocumentTypesComponent implements OnInit {

  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.showFile:
        this.showFile(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadDocumentTypesListDefinition();
  }

  showFile(data: any): void {
    if (!data) return;

    if (data.hasOwnProperty('fileUrl')) {
      var w = window.open(data.fileUrl, '_blank');
      w.focus();
    }
  }

}
