import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { HomLocalStorage } from '../../auth/enums/hom-local-storage.enums';
import * as fromRoot from '../store/reducers/index';
import * as AuthActions from '../../auth/store/auth.actions';
import { UserPriviledgesService } from '../../auth/services';
import { RouteToCrmService } from './route-to-crm.service';

@Component({
  templateUrl: './route-to-crm.component.html'
}) export class RouteToCrmComponent implements OnInit {

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public userPrivsService: UserPriviledgesService,
    public routeToCrmService: RouteToCrmService
  ) {}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe((params) => {
      if (this.validateUrl(params)) {
        localStorage.setItem(HomLocalStorage.HomRouteToCrm, JSON.stringify(params));
        if (!this.userPrivsService.authenticated$.value) this.routeToCrmService.params = JSON.parse(JSON.stringify(params)).params;
      } else {
        this.router.navigate(['404']);
      }
      if (!this.userPrivsService.authenticated$.value) {
        this.rootStore.dispatch(new AuthActions.Logout({ initLogoutUserSessionEvent: false }));
      }
    });
  }

  validateUrl(params): boolean {
    const entityName = params.get('entityName');
    if (entityName !== 'Project' && entityName !== 'Installer') return false;
    const entityId = params.get('entityId');
    if (isNaN(entityId) || entityId <= 0) return false;
    if (params.get('childEntityName')) {
      const childEntityName = params.get('childEntityName');
      if (childEntityName !== 'WorkOrder' && childEntityName !== 'PurchaseOrder') return false;
      const childEntityId = params.get('childEntityId');
      if (isNaN(childEntityId) || childEntityId <= 0) return false;
    }
    return true;
  }

}
