<div *ngIf="mds.mdsReady$ | async">
  <fw-button-title-bar *ngIf="formDefinition"
                       [title]="formDefinition.formTitle"
                       [entityLabel]="formDefinition.entityLabel"
                       [entityStatus]="formDefinition.entityStatus"
                       [canIEdit]="canIEdit"
                       [operation]="operation"
                       [disabled]="!form.dirty || !form.valid  || staleData"
                       [staleData]="staleData"
                       [showEdit]="showEdit"
                       [showRefresh]="showRefresh"
                       [showDelete]="showDelete"
                       [printUrl]="printUrl"
                       [customButtons]="customIcons"
                       (customEvent)="onCustom($event)">
  </fw-button-title-bar>

  <form [formGroup]="form" novalidate *ngIf="form && dataLoaded()" class="form-wrapper form-wrapper--below-title-bar {{ formCssName }}"
        [ngClass]="{'form-wrapper--edit': operation !== myConstants.operationTypeDetails }">
    <fw-dynamic-field *ngFor="let key of displayFields"
                      [field]="mds.getFieldDefinition(key)"
                      [selectItems]="mds.getSelectItems(key)"
                      [form]="form"
                      [operation]="operation"
                      [submitted]="submitted">
    </fw-dynamic-field>
    <fw-error-box [errorData]="errorData"></fw-error-box>
  </form>
  <fw-component-footer [lastUpdate]="data && data.hasOwnProperty('updateDate') ? data.updateDate : '' "></fw-component-footer>

</div>
