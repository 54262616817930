import { Component, OnInit,  Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IProviderUserPrivilegeViewModel, IUserRoleViewModel } from '../../../view-models';
import { AdminEvent } from '../../enums/admin.enums';
import { CheckBoxComponent } from '../../../../../fw/fw-shared/components';
import { IInputButton } from '../../../../../fw/fw-shared/interfaces/i-input-button';
import { AdminSecurityService, IAddUserAccessItem, IAddUserAccessItemKey } from '../../services/admin-security.service';

@Component({
  selector: 'user-access-create-item',
  templateUrl: './user-access-create-item.component.html'
})
export class UserAccessCreateItemComponent implements OnInit, OnChanges  {
  @Input() item: IProviderUserPrivilegeViewModel | IUserRoleViewModel;
  @Input() name: string;
  @Input() customClass: string;
  @Input() isAllSelected: boolean = false;

  @ViewChild('selectAllSubItems') public selectAllSubItems: CheckBoxComponent;

  @Output() public selectEvent = new EventEmitter<IHomEventEmitter>();

  public count$: BehaviorSubject<string> = new BehaviorSubject('');
  public hasSubItems: boolean = false;
  public isSelected: boolean = false;
  public isParentSelectAll: boolean = false;
  public isExpanded: boolean = false;
  public nbrSelected: number = 0;
  public selectAllConfig: IInputButton;
  requestor: string = 'user-access-create-item';

  constructor(public adminService: AdminSecurityService) { }


  public selectItem(): void {
    if (this.hasSubItems) {
      return;
    }
    this.isParentSelectAll = !this.isSelected;
    this.emitSelect(!this.isSelected);
  }

  public toggleSubItems(): void {
    this.isExpanded = !this.isExpanded;
    event.stopPropagation();
  }

  public onSelectSubItem(event: IHomEventEmitter): void {
    //if parent (this guy) is not selected, select him.
    const data: IAddUserAccessItem = event.data;
    if (!this.isSelected && data.add) {
      let allBox: CheckBoxComponent = this.selectAllSubItems;
      allBox.value = true;
      this.emitSelect(true);
    }

    //keep track of selected count
    this.nbrSelected = data.add ? this.nbrSelected = this.nbrSelected + 1 : this.nbrSelected = this.nbrSelected - 1;
    if (this.nbrSelected < 0) {
      this.nbrSelected = 0;
    }

    this.calcSelectedSubItems();

   //then reemit for parent to track selected at parent level
    this.selectEvent.emit(event);
}

  public selectAll(event) {
    let allBox: CheckBoxComponent = this.selectAllSubItems;
    this.isParentSelectAll = allBox.value;
    this.emitSelect(allBox.value);
  } 

  ngOnInit() {
    this.hasSubItems = this.item.hasOwnProperty('workingBranches') && this.item['workingBranches'] && this.item['workingBranches'].length > 0;
    this.count$.next(!this.hasSubItems ? '' : '0');

    this.selectAllConfig = {
      label: {
        label: '',
        alignRight: true,
        title: 'Click to Select Branches'
      }
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isAllSelected'] && !(changes['isAllSelected'].firstChange)) {
      if (this.isAllSelected || (!this.isAllSelected && this.isSelected)) {
        this.isParentSelectAll = this.isAllSelected;
        this.emitSelect(this.isAllSelected);
      }
    }
  }

  emitSelect(val: boolean): void {
    //do not update isParentSelectAll here
    let key: IAddUserAccessItemKey;
    if (this.item.hasOwnProperty('privilegeRuleEntitySpecifierValueId')) {
      key = { id: this.item['entityId'], key: 'entityId' };
    } else {
      key = { id: this.item['providerRoleId'], key: 'providerRoleId' };
    }
    const data: IAddUserAccessItem = {
      add: val,
      key: key,
      item: this.item
    }

    this.isSelected = val;

    this.selectEvent.emit({ requestor: this.requestor, event: AdminEvent.selectNewAccess, action: null, data: data });
  }

  calcSelectedSubItems(): void {
    const item = this.item as IProviderUserPrivilegeViewModel;
    this.count$.next(this.nbrSelected === item.workingBranches.length ? 'All' : this.nbrSelected.toString());
  }

}
