import { Component, OnInit,  OnDestroy, Inject, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription, BehaviorSubject, pipe } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IProviderUserPrivilegeViewModel, IUserRoleViewModel, IProviderRole } from '../../../view-models';
import { AdminEvent, PrivilegeRuleEntitySpecifierType } from '../../enums/admin.enums';
import { AdminSecurityService, IAddUserAccessItem } from '../../services/admin-security.service';
import { IInputButton } from '../../../../../fw/fw-shared/interfaces/i-input-button';
import { CheckBoxComponent } from '../../../../../fw/fw-shared/components';

@Component({
  selector: 'user-access-create-items',
  templateUrl: './user-access-create-items.component.html'
})
export class UserAccessCreateItemsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: string;
  @Input() currentAccess: IProviderUserPrivilegeViewModel[] | IUserRoleViewModel[];
  @Input() specifierType: number;
  @Input() isWorking: boolean;

  @ViewChild('selectAllItems') public selectAllItems: CheckBoxComponent;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public operation$: BehaviorSubject<string> = new BehaviorSubject('');
  public workingItems$: BehaviorSubject<IProviderUserPrivilegeViewModel[] | IProviderRole[]> = new BehaviorSubject([]);
  public selectAllConfig: IInputButton;
  public selectedItems: IUserRoleViewModel[] | IProviderUserPrivilegeViewModel[] = [];
  public isAllSelected: boolean = false;
  public timestamp: string;
  requestor: string = 'user-access-create-items';
  subscription: Subscription = new Subscription();

  constructor(public adminSecurityService: AdminSecurityService,
    public changeDetectorRef: ChangeDetectorRef,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public getItemClass(item: IUserRoleViewModel | IProviderUserPrivilegeViewModel): string {
    return this.specifierType === PrivilegeRuleEntitySpecifierType.userRole ? 'user-access-create-item--roles'
      : this.specifierType === PrivilegeRuleEntitySpecifierType.primaryService || this.specifierType === PrivilegeRuleEntitySpecifierType.secondaryService
        ? 'user-access-create-item--flooring'
      : 'user-access-create-item--'.concat(item['name'].replace(' ', '-').replace('.', '').toLowerCase());
  }

  public getItemName(item: any): string {
    return this.specifierType === PrivilegeRuleEntitySpecifierType.userRole ? item['roleName'] : item['name'];
  }

  public selectItem(event: IHomEventEmitter): void {
    const data: IAddUserAccessItem = event.data;
    let items = cloneDeep(this.selectedItems);
    let idx: number = -1;
    if (this.adminSecurityService.isLocationType(this.specifierType)) {
      //may be adding a sub item so idx by entity id is not sufficient
      //chedk to see if specifier types also match
      const item = event.data.item as IProviderUserPrivilegeViewModel;
      const explicitItems = items as IProviderUserPrivilegeViewModel[];
      const useSpecifierType: number = item.privilegeRuleEntitySpecifierId;
      idx = explicitItems.findIndex(x => x.privilegeRuleEntitySpecifierId === useSpecifierType &&  x[data.key.key] === data.key.id);
    } else {
      idx = items.findIndex(x =>  x[data.key.key] === data.key.id);
    }
    if (idx === -1 && data.add) {
      items.push(data.item);
    } else if (!data.add) {
      items.splice(idx, 1);
    }
    this.selectedItems = cloneDeep(items);
    this.changeDetectorRef.detectChanges();
  }

  public cancel() {
    this.customEvent.emit({ requestor: this.requestor, event: AdminEvent.cancelAdd, action: null, data: null })
  }

  public save() {
    //emit event back to container
    this.customEvent.emit({ requestor: this.requestor, event: AdminEvent.saveUserAccess, action: this.specifierType.toString(), data: this.selectedItems  })
  }

  public getEntityName(item: IUserRoleViewModel | IProviderUserPrivilegeViewModel): string {
    return this.specifierType === PrivilegeRuleEntitySpecifierType.userRole
      ? item['providerRoleName']
      : item['name'];
  }


  //TODO:  when go to add the select all
  public selectAll(event: any) {
    let allBox: CheckBoxComponent = this.selectAllItems;
    this.isAllSelected = allBox.value;
  }

  ngOnInit() {
    this.selectAllConfig = {
      label: {
        label: '',
        alignRight: true,
        title: 'Click to Select All'
      }
    };
    this.setWorkingItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentAccess'] && !(changes['currentAccess'].firstChange)) {
      this.setWorkingItems();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setWorkingItems(): void {
    switch (this.specifierType) {
      case PrivilegeRuleEntitySpecifierType.userRole:
        this.setUserRoleWorkingItems();
        break;
      case PrivilegeRuleEntitySpecifierType.primaryProviderLocation:
      case PrivilegeRuleEntitySpecifierType.secondaryProviderLocation:
        this.setLocWorkingItems();
        break;
      default:
        this.setNotLocWorkingItems();
        break;
    }
  }

  setUserRoleWorkingItems(): void {
    let currentItems: IUserRoleViewModel[] = cloneDeep(this.currentAccess) as IUserRoleViewModel[];
    let finalItems: IProviderRole[] = cloneDeep(this.getAllItems() as IProviderRole[]);

    currentItems.forEach(x => {
      var idx = finalItems.findIndex(c => x.providerRoleId === c.providerRoleId);
      if (idx >= 0) {
        finalItems.splice(idx, 1);
      }
    });

    this.workingItems$.next(finalItems)
  }

  setNotLocWorkingItems(): void {
    let currentItems: IProviderUserPrivilegeViewModel[] = cloneDeep(this.currentAccess) as IProviderUserPrivilegeViewModel[];
    let finalItems: IProviderUserPrivilegeViewModel[] = cloneDeep(this.getAllItems() as IProviderUserPrivilegeViewModel[]);
    currentItems.forEach(x => {
      var idx = finalItems.findIndex(c => x.entityId === c.entityId);
      if (idx >= 0) {
        finalItems.splice(idx, 1);
      }
    });

    this.workingItems$.next(finalItems)
  }

  setLocWorkingItems(): void {
    let alIItems = this.adminSecurityService.setWorkingBranches(this.adminSecurityService.allLocations, this.adminSecurityService.allBranches);
    let currentLocsWBranches: IProviderUserPrivilegeViewModel[] = cloneDeep(this.currentAccess) as IProviderUserPrivilegeViewModel[];
    let finalItems: IProviderUserPrivilegeViewModel[] = cloneDeep(alIItems);

    currentLocsWBranches.forEach(x => {
      var pIdx = finalItems.findIndex(c => x.entityId === c.entityId);
      if (pIdx >= 0 && x.workingBranches && x.workingBranches.length > 0) {
        x.workingBranches.forEach(b => {
          const idx = finalItems[pIdx].workingBranches.findIndex(y => y.entityId === b.entityId);
          if (idx >= 0) {
            //remove it from the overall list of branches
            finalItems[pIdx].workingBranches.splice(idx, 1);
          }
        });
      }
    });

    //remove any locations that do not have working branches
    this.workingItems$.next(finalItems.filter(x => x.workingBranches && x.workingBranches.length > 0));
  }

  getAllItems(): IProviderUserPrivilegeViewModel[] | IProviderRole[] {
    switch (this.specifierType) {
      case PrivilegeRuleEntitySpecifierType.userRole:
        return this.adminSecurityService.allRoles;
      case PrivilegeRuleEntitySpecifierType.primaryGeneralContractor:
      case PrivilegeRuleEntitySpecifierType.secondaryGeneralContractor:
        return this.adminSecurityService.allGcs;
      case PrivilegeRuleEntitySpecifierType.primaryService:
      case PrivilegeRuleEntitySpecifierType.secondaryService:
        return this.adminSecurityService.allServices;
      case PrivilegeRuleEntitySpecifierType.warrantyBranch:
        return this.adminSecurityService.allBranches;
      default:
        break;
    }
  }

}
