
export interface IBranch {
  branchId: number;
  generalContractor_generalContractorId: number;
  branchName: string;
  accountingCustomerMapId: string;
  branchContact_contactId: number;
}


export class Branch implements IBranch {
  branchId: number;
  generalContractor_generalContractorId: number;
  branchName: string;
  accountingCustomerMapId: string;
  branchContact_contactId: number;

  constructor() {
    this.branchId = 0;
    this.generalContractor_generalContractorId = null;
    this.branchName = '';
    this.accountingCustomerMapId = '';
    this.branchContact_contactId = null;
  }
}
