/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomPhonePipe } from 'hom-lib/hom-pipes';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IListDefinition, IListFilter, ListFilter, IListButtonType } from '../../../../fw/dynamic-list/interfaces/index';
import { IMultiTierOuput } from '../../../../fw/fw-menus/interfaces/i-multi-tier-output';
import { IMultiTierMenu } from '../../../../fw/fw-menus/interfaces/i-multi-tier-menu';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';

import {
  IContactMechanismEmail,
  IContactMechanismAddress, IContactMechanismPhone,
  IProjectContactMechanism, ProjectContactMechanism
} from '../../../portals/view-models/index';

import * as fromRoot from '../../../store/reducers/index';
import * as fromFeature from '../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as DynamicListActions from '../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { listDataExists, getListByType } from '../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IListObjectData, IDynamicListState } from '../../../../fw/dynamic-list/store';
import { CreateObjectModel } from '../../../../fw/dynamic-list/store/interfaces/index';
import { getObjectDataByType } from '../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { IDynamicObject } from '../../../../fw/dynamic-list/store/reducers/dynamic-object.reducer';
import { IObjectData } from '../../../../fw/dynamic-list/store/interfaces/index';
import { ContactConstantsService, ContactManagerService, ContactUtilityService } from '../../services/index';
import { ProjectService } from '../../../portals/project/services';
import { MapService } from '../../../../fw/fw-shared/components/fw-map/services/map.service';
import { DynamicListService } from '../../../../fw/dynamic-list/services';

@Component({
  selector: 'job-site-manager',
  templateUrl: './job-site-manager.component.html',
  providers: [ContactManagerService]
})
export class JobSiteManagerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() projectId: number;
  @Input() contactId: number;
  @Input() canIEdit: boolean;
  @Input() hasStaleData: boolean;
  @Input() projectStatus: string;
  @Input() reload: string;
  @Input() showTitle: boolean = true;
  @Input() justInfo: boolean = false;

  public yearClass: string = '';
  public jobSiteAddress$: BehaviorSubject<IProjectContactMechanism> = new BehaviorSubject(null);
  public jobSiteEmail$: BehaviorSubject<IProjectContactMechanism> = new BehaviorSubject(null);
  public jobSitePhone$: BehaviorSubject<IProjectContactMechanism> = new BehaviorSubject(null);
  public lastUpdated$: BehaviorSubject<string> = new BehaviorSubject('');
  public contactPhones$: BehaviorSubject<IMultiTierMenu[]> = new BehaviorSubject(null);
  public contactEmails$: BehaviorSubject<IMultiTierMenu[]> = new BehaviorSubject(null);
  public contactAddresses$: BehaviorSubject<IMultiTierMenu[]> = new BehaviorSubject(null);

  controllerName: string = 'ProjectContactMechanism';
  loaded: boolean = false;
  myStoreName: string = 'projectContactMechanisms';
  phoneStore: string = 'phones';
  emailStore: string = 'emails';
  addressStore: string = 'addresses';
  detailVisible: boolean = false;
  operation: string = '';
  subscription: Subscription = new Subscription();
  contactSub: Subscription;
  phoneSub: Subscription;
  emailSub: Subscription;
  addrSub: Subscription;

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromFeature.IAllDynamicData>,
    public contactConstantsService: ContactConstantsService,
    public contactManagerService: ContactManagerService,
    public contactUtilityService: ContactUtilityService,
    public projectService: ProjectService,
    public phonePipe: HomPhonePipe,
    public mapService: MapService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }


  public onMechanismChanged(output: IMultiTierOuput, type: string) {
    const contactMechanismId: number = output.item ? +output.item : 0;
    const currentProjectContactMechanism: IProjectContactMechanism = this.getCurrentByType(type);
    if (currentProjectContactMechanism === null || contactMechanismId !== currentProjectContactMechanism.contactMechanism_contactMechanismId) {
      let rec: IProjectContactMechanism = new ProjectContactMechanism(this.projectId, contactMechanismId);
      rec.projectContactMechanismId = !currentProjectContactMechanism ? 0 : currentProjectContactMechanism.projectContactMechanismId;
      if (contactMechanismId > 0) {
        this.updateJobSiteDetail(rec); 
      }
    }
  }

  ngOnInit() {
    this.subscription.add(this.jobSiteAddress$.subscribe(addr => {
      let yearClass: string = '';
      if (addr) {
        const yearBuilt: number = !addr.yearBuilt ? 0 : +addr.yearBuilt; //coming in as string - ensure number
        if (!yearBuilt || yearBuilt === 0) {
          yearClass = 'app-text--danger';
        } else if (yearBuilt <= 1978) { //todo get from config setting
          yearClass = 'app-text--warning';
        } else {
          yearClass = '';
        }
      }
      this.yearClass = yearClass;
    }));

    this.newRequest();
  }
   
  public openMap(): void {
    this.mapService.openMap({
      address: this.jobSiteAddress$.value.addressLine1 + ', ' + this.jobSiteAddress$.value['addressCity'] + ', ' + this.jobSiteAddress$.value.addressState,
      latitude: this.jobSiteAddress$.value.latitude,
      longitude: this.jobSiteAddress$.value.longitude
    });
  }

  //move this to container and push the data into here
  newRequest() {
    this.jobSiteAddress$.next(null);
    this.jobSiteEmail$.next(null);
    this.jobSitePhone$.next(null); 
    this.contactAddresses$.next(null);
    this.contactEmails$.next(null);
    this.contactPhones$.next(null);
    this.checkForContactData();
    let listDefinition = this.loadListDefinition();

    this.subscription.add(this.store.select(listDataExists(this.myStoreName, this.projectId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe((exists) => {
        this.store.dispatch(new DynamicListActions.SetListDefinition({ storeName: this.myStoreName, parentId: listDefinition.parentId, listDefinition: listDefinition }));
        this.dispatchJobSiteDetailsGet();
      }));

    this.subscription.add(this.store.pipe(select(fromDynamicList.getListByType(this.myStoreName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.event) {
          //event will be a create event and need to reload list of data
          this.dispatchJobSiteDetailsGet();
          this.projectService.dispatchRequiredDocumentsGet(this.projectId, this.canIEdit);
          this.projectService.emitTaskReloadEvent('job-site-manager', this.projectId);
          this.store.dispatch(new fromStore.ClearEventList({ storeName: this.myStoreName, parentId: this.projectId }));
        } else if (state && state.data && !state.working) {
            //will return a record per contact mechanism
            this.jobSiteAddress$.next(state.data.length > 0 ? cloneDeep(state.data[0]) : null);
            this.jobSitePhone$.next(state.data.length > 1 ? cloneDeep(state.data[1]) : null);
            this.jobSiteEmail$.next(state.data.length > 2 ? cloneDeep(state.data[2]) : null);
            this.lastUpdated$.next(this.getLastUpdate());
        }
      }));

    this.listenForContactStoreInfo();

  }

    //listen for changes to the contact, and their addresses, emails and phones
  listenForContactStoreInfo(): void {
    this.contactAddresses$.next(null);
    this.contactEmails$.next(null);
    this.contactPhones$.next(null);

    if (this.phoneSub) {
      this.phoneSub.unsubscribe();
    }
    this.phoneSub = this.store.pipe(select(getListByType(this.phoneStore)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.contactId)))
      .subscribe((state: IListObjectData) => {
        const objData = state;
        if (objData && objData.data && objData.data.length) {
            const phones: IContactMechanismPhone[] = objData.data;
            this.loadPhoneList(phones);
            this.verifyCurrentJobPhoneValid(phones);
        }
      });

    if (this.emailSub) {
      this.emailSub.unsubscribe();
    }
    this.emailSub = this.store.pipe(select(getListByType(this.emailStore)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.contactId)))
      .subscribe((state: IListObjectData) => {
        const objData = state;
        if (objData && objData.data && objData.data.length) {
            const emails: IContactMechanismEmail[] = objData.data;
            this.loadEmailList(emails);
            this.verifyCurrentJobEmailValid(emails);
        }
      });

    if (this.addrSub) {
      this.addrSub.unsubscribe();
    }
    this.addrSub = this.store.pipe(select(getListByType(this.addressStore)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.contactId)))
      .subscribe((state: IListObjectData) => {
        const objData = state;
        if (objData && objData.data && objData.data.length) {
            const addresses: IContactMechanismAddress[] = objData.data;
            this.loadAddressList(addresses);
            this.verifyCurrentJobAddressValid(addresses);
        }
      });

    //listen for changes to the contact store for this contact id
    if (this.contactSub) {
      this.contactSub.unsubscribe();
    }
    this.contactSub = this.store.pipe(select(getObjectDataByType('contactInformation')))
      .pipe(map((state: IDynamicObject) => state.objData.find(x => x.objectId == this.contactId)))
      .subscribe((state: IObjectData) => {
        if (state) {
          if (state.event && (!state.errorData || !state.errorData.length)) {
            this.dispatchJobSiteDetailsGet();
            this.projectService.emitTaskReloadEvent('job-site-manager', this.projectId);
          }
        }
      });
  }

  verifyCurrentJobAddressValid(addresses: IContactMechanismAddress[]): void {
    const jobSiteAddress: IProjectContactMechanism = this.jobSiteAddress$.getValue();
    if (!jobSiteAddress) {
      return;
    }
    if (addresses.findIndex(x => x.contactMechanism_contactMechanismId === jobSiteAddress.contactMechanism_contactMechanismId) === -1) {
      this.dispatchJobSiteDetailsGet();
    }
  }

  verifyCurrentJobPhoneValid(phones: IContactMechanismPhone[]): void {
    const jobSitePhone: IProjectContactMechanism = this.jobSitePhone$.getValue();
    if (!jobSitePhone) {
      return;
    }
    if (phones.findIndex(x => x.contactMechanism_contactMechanismId === jobSitePhone.contactMechanism_contactMechanismId) === -1) {
     this.dispatchJobSiteDetailsGet();
    }
  }


  verifyCurrentJobEmailValid(emails: IContactMechanismEmail[]): void {
    const jobSiteEmail: IProjectContactMechanism = this.jobSiteEmail$.getValue();
    if (!jobSiteEmail) {
      return;
    }
    if (emails.findIndex(x => x.contactMechanism_contactMechanismId === jobSiteEmail.contactMechanism_contactMechanismId) === -1) {
     this.dispatchJobSiteDetailsGet();
    }
  }


  getLastUpdate(): string {
    const defaultDate: Date = new Date('01/01/1776');
    const address = this.jobSiteAddress$.value;
    const email = this.jobSiteEmail$.value;
    const phone = this.jobSitePhone$.value;
    const addrLastUp: Date = address && address.updateDate
      ? new Date(address.updateDate)
      : defaultDate;
    const emailLastUp: Date = email && email.updateDate
      ? new Date(email.updateDate)
      : defaultDate;
    const phoneLastUp: Date = phone && phone.updateDate
      ? new Date(phone.updateDate)
      : defaultDate;
    const lastUpdate: string = addrLastUp > emailLastUp && addrLastUp > phoneLastUp ? addrLastUp.toDateString()
      : emailLastUp > addrLastUp && emailLastUp > phoneLastUp ? emailLastUp.toDateString()
        : phoneLastUp > addrLastUp && phoneLastUp > emailLastUp ? phoneLastUp.toDateString()
          : addrLastUp.toDateString();
    return lastUpdate.indexOf('1776') > -1 ? '' :  lastUpdate;
  }

  dispatchJobSiteDetailsGet() {
    let listDefinition = this.loadListDefinition();
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: listDefinition, listFilter: listDefinition.defaultListFilter, parentId: listDefinition.parentId }));
  }
  dispatchPhoneGet() {
    this.getEntityData(this.contactConstantsService.contactMechanismCategoryPhone, this.phoneStore, false);
  }
  dispatchEmailGet() {
    this.getEntityData(this.contactConstantsService.contactMechanismCategoryEmail, this.emailStore, false);
  }
  dispatchAddressGet() {
    this.getEntityData(this.contactConstantsService.contactMechanismCategoryAddress, this.addressStore, false);
  }

  //retrieves existing and missing (missing will have a 0 in installerCertificationId) certifications
  loadListDefinition(): IListDefinition {

    const listColumns = [];
    const listObjectLabel = 'Job Site Information';
    const listObjectController = 'ProjectContactMechanism';
    const listStoreName = 'projectContactMechanisms';
    const detailRoutePath = '';
    const detailRouterOutlet = '';
    const listRowKeyId = 'projectContatMechanismId';
    const parentId: number = this.projectId;
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;

    let listDefinition = this.dynamicListService.createListDefinition(detailRouterOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath,
      false ); //load tool buttons manually

    listDefinition.controllerMethod = 'ByProject';
    listDefinition.methodParameters = parentId.toString();
    listDefinition.parentId = parentId;
    listDefinition.parentKey = 'projectId';

    let toolButtons: IListButtonType[] = [
      { type: ButtonType.reload, defaults: null },
      { type: ButtonType.create, defaults: null }
    ];
    listDefinition.toolButtons = this.dynamicListService.loadListToolButtons(toolButtons, listDefinition.objectLabel);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadAddressList(addresses: IContactMechanismAddress[]) {
    let items: IMultiTierMenu[] = [];

    addresses.forEach((address: IContactMechanismAddress) => {
      const addr = this.concatAddress(address);
      if (addr) {
        items.push({
          label: addr,
          subItems: null,
          disabled: false,
          data: address.contactMechanism_contactMechanismId
        });
      }
    });
    this.contactAddresses$.next(items);
  }

  loadEmailList(emails: IContactMechanismEmail[]) {
    let items: IMultiTierMenu[] = [];
    emails.forEach((email: IContactMechanismEmail) => {
      items.push({
        label: email.emailAddress,
        subItems: null,
        disabled: false,
        data: email.contactMechanism_contactMechanismId
      });
    });
    this.contactEmails$.next(items);
  }

  loadPhoneList(phones: IContactMechanismPhone[]) {
    let items: IMultiTierMenu[] = [];
    phones.forEach((phone: IContactMechanismPhone) => {
      items.push({
        label: this.phonePipe.transform(phone.phoneNumber, ''),
        subItems: null,
        disabled: false,
        data: phone.contactMechanism_contactMechanismId
      });
    });

    this.contactPhones$.next(items);
  }

  //move this to contact service
  concatAddress(address: IContactMechanismAddress): string {
    if (!address) {
      return '';
    }
    let addr: string = 'Type: '.concat(address.addressType, '\n', address.line1.trim());
    if (address.line2) {
      addr = addr.concat('\n', address.line2.trim());
    }
    if (address.line3) {
      addr = addr.concat('\n', address.line3.trim());
    }
    addr = addr.concat('\n', address.city.trim(), ', ', address.addressState);
    addr = addr.concat();
    addr = address.zipcode4 ? addr.concat('  ', address.zipcode5, '-', address.zipcode4) : addr.concat('  ', address.zipcode5);
    return addr;
  }

  checkForContactData(): void {
    //phones
    this.subscription.add(this.store.select(listDataExists(this.phoneStore, this.contactId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe(() => { this.getEntityData(this.contactConstantsService.contactMechanismCategoryPhone, this.phoneStore); }));
    //emails
    this.subscription.add(this.store.select(listDataExists(this.emailStore, this.contactId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe(() => { this.getEntityData(this.contactConstantsService.contactMechanismCategoryEmail, this.emailStore); }));
    //addresses
    this.subscription.add(this.store.select(listDataExists(this.addressStore, this.contactId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe(() => { this.getEntityData(this.contactConstantsService.contactMechanismCategoryAddress, this.addressStore); }));
  }

  getEntityData(mechanism: string, storeName, setList: boolean = true): void {
    const defaultListFilter: IListFilter = new ListFilter();

    //set list definition
    let listDefinition = this.dynamicListService.createListDefinition('', this.contactUtilityService.getEntityLabel(mechanism),
      this.contactUtilityService.getEntityController(mechanism),
      storeName, 'contactMechanism_contactMechanismId', defaultListFilter, null, '');

    listDefinition.parentId = this.contactId;
    listDefinition.parentKey = 'contactId';
    listDefinition.controllerMethod = 'ByContact';
    listDefinition.methodParameters = this.contactId.toString();

    if (setList) {
      this.store.dispatch(new fromStore.SetListDefinition({ storeName: storeName, parentId: listDefinition.parentId, listDefinition: listDefinition }));
    }
    this.store.dispatch(new fromStore.GetList({ listDefinition: listDefinition, listFilter: defaultListFilter, parentId: listDefinition.parentId }));
  }

  getCurrentByType(type: string): IProjectContactMechanism {
    switch (type) {
      case this.myConstants.emitterEventAddressChanged:
        return this.jobSiteAddress$.getValue();
      case this.myConstants.emitterEventEmailChanged:
        return this.jobSiteEmail$.getValue();
      case this.myConstants.emitterEventPhoneChanged:
        return this.jobSitePhone$.getValue();
      default:
        return null;
    }
  }
  /*
 *  this is always a create record implementation
 *  service handles deleting old record and inserting new
 */
  updateJobSiteDetail(rec: IProjectContactMechanism) {
    const emitter: IHomEventEmitter = {
      requestor: 'job-site-manager',
      event: this.myConstants.emitterEventCreate,
      action: '',
      data: null
    };
    const createData = new CreateObjectModel(this.myStoreName, this.projectId, this.controllerName, 'Create', rec, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] && !changes['projectId'].isFirstChange()) {
      this.newRequest();
    }
    if ((changes['reload'] && !changes['reload'].isFirstChange())
      || (changes['contactId'] && !changes['contactId'].isFirstChange())) {
      //stop current subs for current contact id and start new ones
      this.listenForContactStoreInfo();
      this.dispatchJobSiteDetailsGet();
      this.dispatchAddressGet();
      this.dispatchEmailGet();
      this.dispatchPhoneGet();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.contactSub) {
      this.contactSub.unsubscribe();
    }
    if (this.addrSub) {
      this.addrSub.unsubscribe();
    }
    if (this.emailSub) {
      this.emailSub.unsubscribe();
    }
    if (this.phoneSub) {
      this.phoneSub.unsubscribe();
    }
  }
}
