import { Injectable, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { upperFirst, lowerFirst, lowerCase, isObject } from 'lodash'; //pulling for just the methods needed is not working at this time, but keep trying

import { IAppConstants, appConstants } from '../constants/index';
import { IFieldDefinition, IOperationTerm, IRange } from '../../fw/dynamic-forms/interfaces/index';
import { ISelectList } from '../interfaces/index';
import { ISearchTerm, IOrderTerm } from '../../fw/dynamic-list/interfaces';
import { ValidateNumericRange, ValidateIsObject } from '../../fw/fw-shared/validators/index';
import { FieldDefinitionPropertyType, FieldDefintionTerm, FieldDefinitionDisplayType, FieldDefinitionFilterFieldType } from '../enums/field-definition.enums';
import { AccessLevel } from '../../fw/dynamic-list/enums/access-level.enums';

interface ISelectListWork {
  entityName: string,
  keyField: string,
  valueField: string;
  labelField: string;
  methodName: string;
  methodByKey: string;
  filterFor: string;
  filterContext: string;
  searchTerm: ISearchTerm[];
  defaultOrderTerm: string[];
  getAll: boolean;
  accessLevel: number;
  useCustomResolver: boolean;
  isLookup: boolean;
  customStore: string;
  isSubList: boolean;
  forFilterOnly: boolean;
  noCache: boolean;
}
interface IFilterWork {
  singleSelect: boolean;
}

/**
 * The FieldDefinitionService contains common operations used during load for each component.
 */
@Injectable()
export class FieldDefinitionService {

  constructor(@Inject(appConstants) public myConstants: IAppConstants) { }

  
  /*
      definitionObject:  of type Array<IFieldDefinition>
      metaDataObject:  component specific along with generic metaData properties (see:  ICertificationMetaData)
      displayFields:  component specific list of fields (order = rendered order) that should be utilized/displayed
      selectList:   array of any select lists used by the component
  */
  public loadFieldDefinitions(displayFields: string[], fieldMetaData: any, selectionListMetaData: any) {
    let fieldDefinitions: IFieldDefinition[] = [];
    for (let key of displayFields) {
      //key never changes, but accessing fieldMetaData uses first part only.  
      let fieldSplit = key.split("_");
      let fieldName = fieldSplit[0];

      const metaData = fieldMetaData.hasOwnProperty(fieldName) ? fieldMetaData[fieldName] : null;
      let rawDataType = this.getStringPropertyValue(metaData, "dataType", FieldDefintionTerm.DataType);
      let displayType = this.getDisplayTypeString(this.getNumberPropertyValue(metaData, "displayType", FieldDefinitionPropertyType.Text, -1));
      let isIdentifier = this.getBooleanPropertyValue(metaData, "isPrimaryKey", FieldDefintionTerm.Flag, false);

        // set the definition of this field
      let fieldDefinition: IFieldDefinition = {
        key: key,
        fieldType: this.getFieldType(metaData, rawDataType, displayType), //refine dataType
        isId: isIdentifier,
        isEditable: this.getBooleanPropertyValue(metaData, "isEditable", FieldDefintionTerm.Flag, true),
        isExtraData: this.getBooleanPropertyValue(metaData, "isExtraData", FieldDefintionTerm.Flag, false),
        linkSettings: {
          flag: this.getBooleanPropertyValue(metaData, "linkSettings", FieldDefintionTerm.Flag, false),
          portalId: this.getStringPropertyValue(metaData, "linkSettings", FieldDefintionTerm.PortalId),
          portalLevel1IdKey: this.getStringPropertyValue(metaData, "linkSettings", FieldDefintionTerm.PortalLevel1IdKey),
          portalLevel2EntityName: this.getStringPropertyValue(metaData, "linkSettings", FieldDefintionTerm.PortalLevel2EntityName),
          portalLevel2IdKey: this.getStringPropertyValue(metaData, "linkSettings", FieldDefintionTerm.PortalLevel2IdKey),
          labelKey: this.getStringPropertyValue(metaData, "linkSettings", FieldDefintionTerm.LabelField),
          portalLevel3EntityName: this.getStringPropertyValue(metaData, "linkSettings", FieldDefintionTerm.PortalLevel3EntityName),
          portalLevel3IdKey: this.getStringPropertyValue(metaData, "linkSettings", FieldDefintionTerm.PortalLevel3IdKey),
          portalLevel2LabelKey: this.getStringPropertyValue(metaData, "linkSettings", FieldDefintionTerm.PortalLevel2LabelKey),
          portalLevel3LabelKey: this.getStringPropertyValue(metaData, "linkSettings", FieldDefintionTerm.PortalLevel3LabelKey)
        },
        calcSettings: {
          flag: this.getBooleanPropertyValue(metaData, "calcSettings", FieldDefintionTerm.Flag, false),
          column1: this.getStringPropertyValue(metaData, "calcSettings", FieldDefintionTerm.Column1),
          column2: this.getStringPropertyValue(metaData, "calcSettings", FieldDefintionTerm.Column2),
          operationTerm: this.getOperationTermPropertyValue(key, metaData, "calcSettings", FieldDefintionTerm.OperationTerm),
        },
        label: metaData ? this.getStringPropertyValue(metaData, "displayName", FieldDefinitionPropertyType.Text) : upperFirst(fieldName),
        placeholder: this.getStringPropertyValue(metaData, "hint", FieldDefinitionPropertyType.Text),
        visibleOnEdit: this.getBooleanPropertyValue(metaData, "isVisibleDuringEdit", FieldDefinitionPropertyType.Flag, metaData ? true : false),
        alwaysReadOnly: !this.getBooleanPropertyValue(metaData, "isEditable", FieldDefinitionPropertyType.Flag, true) || isIdentifier,
        required: this.getBooleanPropertyValue(metaData, "required", FieldDefinitionPropertyType.Flag, false),
        maxStringLength: this.getNumberPropertyValue(metaData, "stringLength", FieldDefinitionPropertyType.Text),
        range: this.getRange(rawDataType, metaData, displayType),
        textRows: this.getNumberPropertyValue(metaData, "textRows", FieldDefinitionPropertyType.Text,
          this.getFieldType(metaData, rawDataType, displayType) === this.myConstants.fieldTypeTextArea ? 4 : 0),  //text area
        validations: this.getValidations(rawDataType, metaData, fieldName, displayType),
        validationMessages: this.getValidationMessages(metaData, fieldName),
        pattern: this.getRegularExpressionPropertyValue(metaData, "patternSettings", FieldDefinitionPropertyType.Text), 
        validationMessage: '',
        filterable: this.getBooleanPropertyValue(metaData, "filterable", FieldDefinitionPropertyType.Flag, metaData ? true : false),
        filterLabel: this.getStringPropertyValue(metaData, "displayName", FieldDefinitionPropertyType.Text),
        sortable: this.getBooleanPropertyValue(metaData, "sortable", FieldDefinitionPropertyType.Flag, metaData ? true : false),
        visibleOnSmall: true,
        defaultSearch: this.getStringPropertyValue(metaData, "defaultSearch", FieldDefinitionPropertyType.Text),
        selectListDefinition: this.getSelectListDefinition(rawDataType, metaData, key, displayType),
        filterTerm: this.getStringPropertyValue(metaData, "filterTerm", FieldDefinitionPropertyType.Text),
        orderTerms: this.getArrayPropertyValue(metaData, "orderTerm", FieldDefinitionPropertyType.Text),
        includeSearchTypes: this.getStringPropertyValue(metaData, "includeSearchTypes", FieldDefinitionPropertyType.Text),
        excludeSearchTypes: this.getStringPropertyValue(metaData, "excludeSearchTypes", FieldDefinitionPropertyType.Text),
        filterType: this.getFilterFieldTypeString(this.getNumberPropertyValue(metaData, "filterFieldType", FieldDefinitionPropertyType.Text, -1), rawDataType),
        listOverrides: this.getNumberPropertyValue(metaData, "listDisplayType", FieldDefinitionPropertyType.Text, -1) === -1 ? null : {
          displayType: this.getListDisplayTypeString(this.getNumberPropertyValue(metaData, "listDisplayType", FieldDefinitionPropertyType.Text, -1)),
          selectLabel: this.getStringPropertyValue(metaData, "listDisplaySettings", FieldDefintionTerm.SelectLabel),
          eventName: this.getStringPropertyValue(metaData, "listDisplaySettings", FieldDefintionTerm.EventName),
        },
        singleSort: this.getBooleanPropertyValue(metaData, "singleSort", FieldDefinitionPropertyType.Flag, false)
      }
      /* the validations property will display as null if you stringify this object */
      fieldDefinitions.push(fieldDefinition);
    }

    return fieldDefinitions;
  }

  getRange(rawDataType: string, metaData: any, displayType: string): IRange {
    const fieldType: string = this.getFieldType(metaData, rawDataType, displayType);

    if (fieldType === this.myConstants.fieldTypeNumber
      || fieldType === this.myConstants.fieldTypeCurrency
      || fieldType === this.myConstants.fieldTypeDoubleDecimal) {
      const max: number = this.getNumberPropertyValue(metaData, "range", FieldDefintionTerm.MaxValue, 100000);
      const min: number = this.getNumberPropertyValue(metaData, "range", FieldDefintionTerm.MinValue, 0);
      return { min: min, max: max } 
    } else {
      return null
    }
  }

  getSelectListDefinition(dataType: string, fieldMetaData: any, key: string, displayType: string): ISelectList {
    if (!fieldMetaData) {
      return null;
    }

    const workingType = displayType != '' ? displayType : dataType;
    let workingFields: ISelectListWork = this.getWorkingSelectField(workingType, fieldMetaData, key);
    let filterSettings: IFilterWork = this.getWorkingFilterField(fieldMetaData);

    //if want to display extra data, as example, in lookup or allow searching on extra data field, seed with select info
    const hasSettings = workingFields.entityName && workingFields.valueField && workingFields.labelField;

    if (workingType === this.myConstants.dataTypeEnum ||
      workingType === this.myConstants.dataTypeLookup ||
      (workingType === this.myConstants.dataTypeEntity && hasSettings) ||
      (workingType === this.myConstants.dataTypeEditableSelect && hasSettings) ||
      (workingType === this.myConstants.dataTypeFilterableSelect && hasSettings)
    ) {
      const fieldSplit = key.split('_');
      const fieldKey = fieldSplit[0];
      const selectListDefinition: ISelectList = {
        keyField: workingFields.keyField,
        labelProperty: workingType === this.myConstants.dataTypeEnum ? 'name'
          : workingType === this.myConstants.dataTypeLookup ? 'lookupMeaning'
            : workingFields.labelField, //invalid data type for select
        valueProperty: dataType === this.myConstants.dataTypeEnum ? 'name'
          : dataType === this.myConstants.dataTypeLookup ? 'lookupValueId'
            : workingFields.valueField,
        listType: dataType,
        objectType: workingFields.entityName ? workingFields.entityName : fieldKey,
        methodName: workingFields.methodName,
        methodByKey: workingFields.methodByKey,
        useCustomResolver: workingFields.useCustomResolver,
        listFilter: null,
        storeName: workingFields.customStore ? workingFields.customStore
          : workingFields.entityName ? workingFields.entityName
            : fieldKey,
        isSubList: workingFields.isSubList,
        forFilterOnly: workingFields.forFilterOnly,
        filterSettings: { singleSelect: filterSettings.singleSelect },
        noCache: workingFields.noCache
      };

      //default asc and desc
      let defaultOrderTerms: IOrderTerm[] = [];
      workingFields.defaultOrderTerm.forEach(x => {
        defaultOrderTerms.push({term: x.toString(), orderAsc: true, sortOrder: 1})
      });

      if (dataType === this.myConstants.dataTypeEnum ||
        dataType === this.myConstants.dataTypeLookup) {
        selectListDefinition.listFilter = null;
      } else {
        selectListDefinition.listFilter = {
          isLookup: workingFields.isLookup, //default is true
          getCount: false,
          filterFor: workingFields.filterFor,
          filterContext: workingFields.filterContext,
          accessLevel: workingFields.accessLevel === 1 ? AccessLevel.ReadOnly : workingFields.accessLevel === 0 ? AccessLevel.None : AccessLevel.FullAccess,
          getAll: workingFields.getAll,
          currentPage: 1,
          searchTerm: workingFields.searchTerm,
          orderTerm: defaultOrderTerms
        };
      }

      return selectListDefinition;
    } else {
      return null;
    }

}

  getWorkingSelectField(workingType: string, fieldMetaData: any, key: string): ISelectListWork {
    let workingFields: ISelectListWork = {
      entityName: '',
      keyField: '',
      valueField: '',
      labelField: '',
      methodName: '',
      methodByKey: '',
      filterFor: '',
      filterContext: '',
      searchTerm: [],
      defaultOrderTerm: [],
      getAll: false,
      accessLevel: 2,  
      useCustomResolver: false,
      isLookup: true,
      customStore: '',
      isSubList: false,
      forFilterOnly: false,
      noCache: false
    }
    //grab the selectSettings, if found, lookup/enum will override at this time
    const entityName: string = this.getStringPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.EntityName);
    workingFields.keyField = this.getStringPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.KeyField);
    if (!workingFields.keyField) {
      workingFields.keyField = workingType === this.myConstants.dataTypeLookup ? key.concat('_lookupValueId') :
        workingType === this.myConstants.dataTypeEntity ? key.concat('_', key, 'Id')
          :key;
    }
    workingFields.entityName = entityName.length > 0 ? lowerFirst(entityName) : entityName; //will equate to the selection list store name so must begin with lower
    const valueField: string = this.getStringPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.ValueField);
    workingFields.valueField = valueField.length > 0 ? lowerFirst(valueField) : valueField;
    const labelField: string = this.getStringPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.LabelField);
    workingFields.labelField = labelField.length > 0 ? lowerFirst(labelField) : labelField;
    workingFields.methodName = this.getStringPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.MethodName);
    workingFields.methodByKey = this.getStringPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.MethodByKey);

    workingFields.filterFor = this.getStringPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.FilterFor);
    workingFields.filterContext = this.getStringPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.FilterContext);
    workingFields.searchTerm = this.getArrayPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.SearchTerm);
    workingFields.defaultOrderTerm = this.getArrayPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.DefaultOrderTerm);
    workingFields.getAll = this.getBooleanPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.GetAll, true); //**true**//
    workingFields.accessLevel = this.getNumberPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.AccessLevel, 2);//default to full access
    workingFields.noCache = this.getBooleanPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.NoCache, false);
    workingFields.useCustomResolver = this.getBooleanPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.UseCustomResolver, false);
    workingFields.isLookup = this.getBooleanPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.IsLookUp, true);
    workingFields.customStore = this.getStringPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.CustomStore);
    workingFields.isSubList = this.getBooleanPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.IsSubList, false);
    workingFields.forFilterOnly = this.getBooleanPropertyValue(fieldMetaData, "selectSettings", FieldDefintionTerm.ForFilterOnly, false);

    return workingFields;
  }

  getWorkingFilterField(fieldMetaData: any): IFilterWork {
    let workingFields: IFilterWork = {
      singleSelect: false
    }
    workingFields.singleSelect = this.getBooleanPropertyValue(fieldMetaData, "filterSettings", FieldDefintionTerm.SingleSelect, false);  

    return workingFields;
  }

  getFieldType(fieldMetaData: any, dataType: string, displayType: string): string {
    if (!fieldMetaData) return this.myConstants.dataTypeString;

    const workingType = !displayType ? dataType :  displayType;
    let fieldType = workingType;

    switch (workingType) {
      case this.myConstants.dataTypeDateTimeNull:
        fieldType = this.myConstants.fieldTypeDateTime;
        break;
      case this.myConstants.dataTypeDoubleDecimalNull:
        fieldType = this.myConstants.fieldTypeDoubleDecimal;
        break;
      case this.myConstants.dataTypeEnum:
      case this.myConstants.dataTypeLookup:
        fieldType = this.myConstants.fieldTypeSelect;
        break;
      case this.myConstants.dataTypeInt:
      case this.myConstants.dataTypeIntNull:
      case this.myConstants.dataTypeInt32:
        fieldType = this.myConstants.fieldTypeNumber;
        break;
      case this.myConstants.dataTypeBoolNull:
        fieldType = this.myConstants.fieldTypeBoolean;
        break;
      case this.myConstants.dataTypeString:
        const length = this.getNumberPropertyValue(fieldMetaData, "stringLength", FieldDefinitionPropertyType.Text);
        const textRows = this.getNumberPropertyValue(fieldMetaData, "textRows", FieldDefinitionPropertyType.Text);
        fieldType = length > 200 ||  textRows > 0 ? this.myConstants.fieldTypeTextArea : dataType;
        break;
      default:
        break;

    }
    return fieldType; //datatype should also exist as fieldType if not overridden
  }

  getDisplayTypeString(displayType: number): string {

    switch (displayType.toString()) {
      case FieldDefinitionDisplayType.Phone.toString():
        return this.myConstants.fieldTypePhone;
      case FieldDefinitionDisplayType.Email.toString():
        return this.myConstants.fieldTypeEmail;
      case FieldDefinitionDisplayType.ZipCode.toString():
        return this.myConstants.fieldTypeZipCode;
      case FieldDefinitionDisplayType.Currency.toString():
        return this.myConstants.fieldTypeCurrency;
      case FieldDefinitionDisplayType.Percentage.toString():
        return this.myConstants.fieldTypePercentage;
      case FieldDefinitionDisplayType.EditableSelect.toString():
        return this.myConstants.fieldTypeEditableSelect;
      case FieldDefinitionDisplayType.FilterableSelect.toString():
        return this.myConstants.fieldTypeFilterableSelect;
      case FieldDefinitionDisplayType.Date.toString():
        return this.myConstants.fieldTypeDate;
      default:
        return '';
    }
  }


  getFilterFieldTypeString(filterType: number, rawDataType: string): string {
    switch (filterType.toString()) {
      case FieldDefinitionFilterFieldType.String.toString():
        return this.myConstants.fieldTypeString;
      case FieldDefinitionFilterFieldType.Int.toString():
      case FieldDefinitionFilterFieldType.IntNull.toString():
        return this.myConstants.fieldTypeNumber;
      case FieldDefinitionFilterFieldType.Bool.toString():
      case FieldDefinitionFilterFieldType.BoolNull.toString():
        return this.myConstants.fieldTypeBoolean;
      case FieldDefinitionFilterFieldType.Date.toString():
        return this.myConstants.fieldTypeDate;
      case FieldDefinitionFilterFieldType.DateTime.toString():
      case FieldDefinitionFilterFieldType.DateTimeNull.toString():
        return this.myConstants.fieldTypeDateTime;
      case FieldDefinitionFilterFieldType.Enum.toString():
      case FieldDefinitionFilterFieldType.LookupValue.toString():
      case FieldDefinitionFilterFieldType.Entity.toString():
        return this.myConstants.fieldTypeMultiSelect;
      case FieldDefinitionFilterFieldType.Double.toString():
      case FieldDefinitionFilterFieldType.DoubleNull.toString():
        return this.myConstants.fieldTypeDouble;
      case FieldDefinitionFilterFieldType.TimeSpan.toString():
        return this.myConstants.fieldTypeTimeSpan;
      case FieldDefinitionFilterFieldType.DoubleDecimal.toString():
      case FieldDefinitionFilterFieldType.DoubleDecimalNull.toString():
        return this.myConstants.fieldTypeDoubleDecimal;
      default:
        //handle filterType defaults when fieldType will not support
        switch (rawDataType) {
          case this.myConstants.dataTypeDateTime:
          case this.myConstants.dataTypeBoolNull:
          case this.myConstants.dataTypeDateTimeNull:
          case this.myConstants.dataTypeIntNull:
          case this.myConstants.dataTypeDoubleDecimalNull:
          case this.myConstants.dataTypeDoubleNull:
            return rawDataType;
          default:
            return '';
        }
    }
  }

  getListDisplayTypeString(displayType: number): string {

    switch (displayType.toString()) {
      case FieldDefinitionDisplayType.DropDown.toString():
        return this.myConstants.listOverrideDropDown;
      case FieldDefinitionDisplayType.DecimalInput.toString():
        return this.myConstants.listOverrideDecimalInput;
      case FieldDefinitionDisplayType.UnavailableReasons.toString():
        return this.myConstants.listOverrideUnavailableReasons;
      case FieldDefinitionDisplayType.Specialnstructions.toString():
        return this.myConstants.listOverrideSpecialInstructions;
      case FieldDefinitionDisplayType.WorkOrderInfo.toString():
        return this.myConstants.listOverrideWorkOrderInfo;
      case FieldDefinitionDisplayType.CustomerAlert.toString():
        return this.myConstants.listOverrideCustomerAlert;
      case FieldDefinitionDisplayType.CustomerFlag.toString():
        return this.myConstants.listOverrideCustomerFlag;
      case FieldDefinitionDisplayType.CommunicationPreference.toString():
        return this.myConstants.listOverrideCommunicationPreference;
      case FieldDefinitionDisplayType.CrewCompatibility.toString():
        return this.myConstants.listOverrideCrewCompatibility;
      case FieldDefinitionDisplayType.NoteContext.toString():
        return this.myConstants.listOverrideNoteContext;
      case FieldDefinitionDisplayType.CustomerContact.toString():
        return this.myConstants.listOverrideCustomerContact;
      case FieldDefinitionDisplayType.InstallerCertificationExpirationDate.toString():
        return this.myConstants.listOverrideInstallerCertificationExpirationDate;
      case FieldDefinitionDisplayType.DocumentActions.toString():
        return this.myConstants.listOverrideDocumentActions;
      case FieldDefinitionDisplayType.DocumentProperties.toString():
        return this.myConstants.listOverrideDocumentProperties;
      case FieldDefinitionDisplayType.InstallerCrew.toString():
        return this.myConstants.listOverrideInstallerCrew;
      default:
        return '';
    }
  } 

  /*
      For data types of string, find and load the field data
      propertyTerm is used for complext types:  types with sub types like selectSettings
  */
  getStringPropertyValue(fieldMetaData: any,  metaDataName: string, propertyTerm: string) {
    let value = '';
    if ( fieldMetaData && fieldMetaData.hasOwnProperty(metaDataName)) {
      if (isObject(fieldMetaData[metaDataName])) {
        //pull property from 'text'
        if (fieldMetaData[metaDataName].hasOwnProperty(propertyTerm)) {
          value = fieldMetaData[metaDataName][propertyTerm];
        }
      }
    }
    return value;
  }

  /*
    For data types of string, find and load the field data
    propertyTerm is used for complext types:  types with sub types like selectSettings
*/
  getOperationTermPropertyValue(key: string, fieldMetaData: any, metaDataName: string, propertyTerm: string): IOperationTerm {
    let value = this.getStringPropertyValue(fieldMetaData, metaDataName, propertyTerm);


    const typedValue: IOperationTerm | undefined = (<any>IOperationTerm)[value];
    if (typedValue === undefined) {
      return IOperationTerm.notSet;
    }
    
    return typedValue;
  }

  /*
   For data types of array, find and load the field data - seed to db as  ,'{ "term": "jobScheduleType", "value": "Job" }&&{ "term": "col2", "value": "col2" }'
      or 'lastName&&firstName'
  */
  getArrayPropertyValue(fieldMetaData: any, metaDataName: string, propertyTerm: string) {
    let value = [];
    if ( fieldMetaData && fieldMetaData.hasOwnProperty(metaDataName)) {
      if (isObject(fieldMetaData[metaDataName])) {
        if (fieldMetaData[metaDataName].hasOwnProperty(propertyTerm)) {
          const terms = fieldMetaData[metaDataName][propertyTerm].split('&&');
          terms.forEach((t: string) => {
            if (this.isJsonObject(t)) {
              value.push(JSON.parse(t));
            } else {
              value.push(t);
            }
          });
        }
      }
    }
    return value;
  }

  /*
      For data types of boolean, find and load the field data
  */
  getBooleanPropertyValue(fieldMetaData: any,  metaDataName: string, propertyTerm: string, defaultTo: boolean) {
    let value = defaultTo;
    if (fieldMetaData && fieldMetaData.hasOwnProperty(metaDataName)) {
      if (isObject(fieldMetaData[metaDataName])) {
        if (fieldMetaData[metaDataName].hasOwnProperty(propertyTerm)) {
          let temp = lowerCase(fieldMetaData[metaDataName][propertyTerm]);
          if ( temp === 'false' || temp === '0')
            value = false;
          else if ( temp === 'true' || temp === '1')
            value = true;
          else
            value = <boolean>fieldMetaData[metaDataName][propertyTerm];
        }
      }
    }

    return value;
  }


  /*
      For data types of number, find and load the field data
  */
  getNumberPropertyValue(fieldMetaData: any,  metaDataName: string, propertyType: string, defaultValue: number = 0) {
    let value = defaultValue;
    if (fieldMetaData && fieldMetaData.hasOwnProperty(metaDataName)) {
      if (isObject(fieldMetaData[metaDataName])) {
        if (fieldMetaData[metaDataName].hasOwnProperty(propertyType)) {
          value = <number>fieldMetaData[metaDataName][propertyType];
        }
      }
    }
    return value;
  }

  getRegularExpressionPropertyValue(fieldMetaData: any, metaDataName: string, propertyType: string, defaultValue: RegExp = null) {
    let value = defaultValue;
    const pattern: unknown = this.getStringPropertyValue(fieldMetaData, "patternSettings", FieldDefintionTerm.Pattern)
    if (pattern) {
      value = <RegExp>pattern;
    }
    return value;
  }

  /*
      NEW approach with store:  one at a time not fork join
      For data types of select, define the selectItems object
      selectItems.items are loaded after all the fields are defined.
  */
  //  getSelectFieldDefinition(dataType: string, fieldMetaData: any, key: string) {
  //    const selectListDefinition: ISelectList = this.getSelectListDefinition(dataType, fieldMetaData, key);
  //    if (selectListDefinition == null) return null;
  //    return { valueField: selectListDefinition.valueProperty, labelField: selectListDefinition.labelProperty, metaData: null, items: null };
  //}

  /*
      Load the validations up for this field.
      Max validation is used to set the maxlength of the field, so the need for the validation message when you exceed that length is moot.
      JSON.stringify on the resulting validations will return null, but they really are there.
  */
  getValidations(rawDataType: string, fieldMetaData: any, fieldName: string, displayType: string) {
    let validations = [];

    const required = this.getBooleanPropertyValue(fieldMetaData, "required", FieldDefinitionPropertyType.Flag, false);
    const maxLength = this.getNumberPropertyValue(fieldMetaData, "stringLength", FieldDefinitionPropertyType.Text);
    const pattern = this.getStringPropertyValue(fieldMetaData, "patternSettings", FieldDefintionTerm.Pattern)
    const range: IRange = this.getRange(rawDataType, fieldMetaData, displayType);

    if (required) {
      validations.push(Validators.required );
    }

    if (maxLength > 0) {
      validations.push(Validators.maxLength(maxLength));
    }

    if (pattern) {
      validations.push(Validators.pattern);
    }

    if (range) {
      const fieldType: string = this.getFieldType(fieldMetaData, rawDataType, displayType);
      validations.push(ValidateNumericRange(range.min, range.max, fieldType !== this.myConstants.fieldTypeNumber));
    }

    if (displayType === this.myConstants.fieldTypeFilterableSelect) {
      const fieldType: string = this.getFieldType(fieldMetaData, rawDataType, displayType);
      validations.push(ValidateIsObject());
    }

    return validations;
  }

  /*
      The Keys for these messages must EXACTLY match the validator key
      else though the validation will trigger, you will not see your message.
      Also, please note the funky way to reference a variable when creating a dynamic string.
  */
  getValidationMessages(fieldMetaData: any, fieldName: string) {
    let requiredMsg: string = this.getStringPropertyValue(fieldMetaData, 'required', FieldDefintionTerm.ValidationText);
    let maxLengthMsg: string = this.getStringPropertyValue(fieldMetaData, 'stringLength', FieldDefintionTerm.ValidationText);
    let patternMsg: string = this.getStringPropertyValue(fieldMetaData, 'patternSettings', FieldDefintionTerm.ValidationText)

    const label: string = fieldMetaData ? this.getStringPropertyValue(fieldMetaData, "displayName", FieldDefinitionPropertyType.Text) : upperFirst(fieldName);
    //messages are only displayed if they have corresponding validations, so even though am creating them here, they won't be used unless the validation exists
    return {
      required: requiredMsg === '' ? `${label} is required.` : requiredMsg,
      maxlength: maxLengthMsg === '' ? `${label} can be no longer than ${this.getNumberPropertyValue(fieldMetaData, "stringLength", FieldDefinitionPropertyType.Text)} characters.` : maxLengthMsg,
      homNbrRange: `${label} can not be more than ${this.getNumberPropertyValue(fieldMetaData, "range", FieldDefintionTerm.MaxValue, 100000)} and can not be less than ${this.getNumberPropertyValue(fieldMetaData, "range", FieldDefintionTerm.MinValue, 0)}.`,
      pattern: patternMsg === '' ? `${label} is an invalid format.` : patternMsg,
      homIsObject: `${label} must be a selected item.`,
    };
  }

  isJsonObject(data: any): boolean {
      try {
        const json = JSON.parse(data);
        return (typeof json === 'object');
      } catch (e) {
        return false;
      }
    }

}


