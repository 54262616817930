export interface IContactCustomerFlag {
  contactCustomerFlagId: number;
  contact_contactId: number;
  customerFlag_customerFlagId: number;
  flagReasonText: string;
  isActive: boolean;
  noteId: number;

  createDate: string;
  updateDate: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  //extra data
  customerFlagColor: string;
  customerFlagName: string;
}

export class ContactCustomerFlag implements IContactCustomerFlag {
  contactCustomerFlagId: number;
  contact_contactId: number;
  customerFlag_customerFlagId: number;
  flagReasonText: string;
  isActive: boolean;
  noteId: number;

  createDate: string;
  updateDate: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  customerFlagColor: string;
  customerFlagName: string;

  constructor() {
    this.contactCustomerFlagId = 0;
    this.contact_contactId = 0;
    this.customerFlag_customerFlagId = 0;
    this.flagReasonText = '';
    this.isActive = false;
    this.noteId = null;
    this.createDate = '';
    this.updateDate = '';
    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';
    this.customerFlagColor = 'inherit';
    this.customerFlagName = '';

  }

}
