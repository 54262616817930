<div class="form wcp-create">
  <div class="form-group wcp-create__select">
    <div [ngClass]="{'loading-mask': !payouts || !form}">
      <fw-editable-select *ngIf="payouts && form"
                          [list]="payouts"
                          [form]="form"
                          [field]="mds.getFieldDefinition('payout')"
                          (selectEvent)="selectEvent($event)">
      </fw-editable-select>
    </div>
  </div>
  <div>
    <div class="wcp-create__selected">
      <div class="wcp-create__selected--header">Selected Work Category Payouts</div>
      <ul class="wcp-create__selected--items" [ngClass]="{'loading-mask': !(selectedPayouts$ | async)}">
        <li *ngFor="let item of (selectedPayouts$ | async)" class="wcp-create__selected--item">
          {{ item.payoutName }}
          <div class="wcp-create__selected--item--btn">
            <button class="app-btn-icon app-btn-icon--danger" (click)="removeItem(item.payoutId)">
              <i class="far fa-times"></i>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<fw-crud-buttons [operation]="myConstants.operationTypeCreate"
                 [disabled]="false"
                 [showNext]="false"
                 (cancelEvent)="onCancel()"
                 (createEvent)="onCreate()">
</fw-crud-buttons>
<fw-error-box [errors]="errors" [onForm]="true" [ignoreKey]="true"></fw-error-box>


