import { Injectable } from '@angular/core';
import { FileUploadType } from '../enums/file-upload.enums';
import { IFileUploadConfig } from '../interfaces/i-file-upload-config';

@Injectable() export class FileUploaderService {
  fileUploadConfigs: IFileUploadConfig[];
  projectAllowedFileTypes: string[] = ['jpg', 'jpeg', 'png', 'txt', 'tif', 'bmp', 'xls', 'xlsx', 'wav', 'odt', 'docx', 'doc', 'pdf', 'xml', 'heic'];
  woPacketAllowedFileTypes: string[] = ['jpg', 'jpeg', 'png', 'txt', 'tif', 'bmp', 'pdf', 'heic'];
  surveyAllowedFileTypes: string[] = ['csv'];
  base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  constructor() {
    this.fileUploadConfigs = [{
      uploadType: FileUploadType.contactImage,
      controllerName: 'Contact',
      methodName: 'ImportContactFile',
      objKey: 'contactId',
      fileKey: 'imageFile'
      },
      {
      uploadType: FileUploadType.survey,
      controllerName: 'SurveyFileImport',
      methodName: 'ImportSurveyFile',
      objKey: 'surveyId',
      fileKey: 'fileUpload'
      }
    ]
  }

  isPdf(file: File): boolean {
    return file.type === 'application/pdf';
  }
  isPdf2(str: string): boolean {
    if (str.split('.').length < 2) return false;
    return str.split(".")[str.split('.').length -1] === "pdf";
  }

  base64ToBlob(base64): Blob {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(':')[0];
    let byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charAt(i);
    }
    const ia = new Uint8Array(byteNumbers);
    return new Blob([ia], { type: mimeString });
  }

  validateSelected(fileType: string, allowedTypes: string[]): boolean {
    const testTypes: string[] = allowedTypes && allowedTypes.length > 0
      ? allowedTypes
      : this.projectAllowedFileTypes;

    const typeParts: string[] = fileType.split('/');
    const testPart = typeParts.length > 1 ? typeParts[1] : typeParts[0];
    return testTypes.find(y => y.toLowerCase() === testPart.toLowerCase()) ? true : false;
  }

  getFileExtension(fileName: string): string {
    const typeParts: string[] = fileName.split('.');
    return typeParts.length > 1 ? typeParts[typeParts.length - 1] : typeParts[0]
  }
}
