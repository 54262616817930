<div class="invalid-user">
  <h2 class="invalid-user__title">
    <span class="invalid-user__title--icon"><i class="fad fa-frown"></i></span>
    {{ title }}
  </h2>
    <div class="invalid-user__text">
      You have an incomplete user setup.  <br />Please contact your user administrator to resolve the setup issues.
      <span class="invalid-user__text--icon"><i class="fal fa-poo"></i></span>

    </div>
</div>

