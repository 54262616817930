import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,  Inject} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services/index'
import { JobScheduleService, IDaysOfWeek } from '../../../portal-shared/services/index';
import { IInstallerWorkCrewScheduleExclusionViewModel, JobSchedule } from '../../../view-models/index';
import { CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';

//store actions, reducers, interfaces
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { HomCommonUtility } from '../../../../../shared/services';

@Component({
  selector: 'installer-work-crew-schedule-exclusion',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './installer-work-crew-schedule-exclusion.component.html',
  providers: [MetaDataService]
})
export class InstallerWorkCrewScheduleExclusionComponent implements OnInit, OnDestroy  {
  public operation: string;
  public form: FormGroup;
  public showNext: boolean = false;
  public jobFrequencyTypes: any[] = [];
  public jobFrequencyUnits: any[] = [];
  public daysOfWeek: IDaysOfWeek[] = [];
  public selectedDaysOfWeek: {
    dayOfWeek: IDaysOfWeek,
    checked: boolean
  }[] = []
  public showDays$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public scheduleSummary$: BehaviorSubject<string> = new BehaviorSubject('');
  public duration$: BehaviorSubject<string> = new BehaviorSubject('');
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public displayFields: string[] = ['jobFrequencyType', 'jobFrequencyRecurUnit1', 'jobStartDate', 'allDayEvent', 'jobEndDate'];

  myPathName: string = '';
  myStoreName: string = '';
  key: string = '';
  activeIndex: number = -1;
  workCrewId: number = -1;
  installerId: number = 0;
  requestTime: string = '';
  isInValidRange: boolean = false;
  errors: string;

  objectData: IInstallerWorkCrewScheduleExclusionViewModel = null;
  controllerName: string = 'InstallerWorkCrewScheduleExclusionViewModel';
  loaded: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(
    public activatedRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public datePipe: DatePipe,
    public mds: MetaDataService,
    public jobScheduleService: JobScheduleService,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public utils: HomCommonUtility,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public updateSelectedDays(index: number, checked: boolean) {
    this.selectedDaysOfWeek[index].checked = checked;
    this.scheduleSummary$.next(this.jobScheduleService.jobScheduleSummary(this.setJobSchedule()));
  }

  public onCancel() {
    const emitter: IHomEventEmitter = { requestor: 'installer-work-crew-schedule-exclusion', event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public onCreate() {
    let data = this.setCommonProperties();
    const emitter: IHomEventEmitter = {
      requestor: 'installer-work-crew-schedule-exclusion',
      event: this.myConstants.emitterEventCreate,
      action: '',
      data: null
    };
    const createData = new CreateObjectModel(this.myStoreName, this.workCrewId, this.controllerName, 'Create', data, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }

  public formDisabled() {
    return !this.form || !this.form.dirty || !this.form.valid || this.isInValidRange;
  }

  
  ngOnInit() {
    this.subscription.add(this.activatedRoute.parent.paramMap.subscribe(paramMap => {
      this.workCrewId = +paramMap.get('id');
    }));

    /* Subscribe to this objects route params  */
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
     // this.installerWorkCrewSchedulExlusionId = +paramMap.get('id'); -- not applicable only supporting new at this time
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName'); //should be workCrewScheduleExclusions
      this.requestTime = paramMap.get('requestTime'); 
      this.showNext = paramMap.get('showNext') === 'true';
      this.installerId = +paramMap.get('portalEntityId');
      this.setDetailConfig(paramMap);
      this.initForm();
    });

    this.subscription.add(this.mds.mdsReady$.pipe(filter(flag => flag === true), take(1))
      .subscribe((ready: boolean) => {
        this.loadForm();
      }));

    this.subscription.add( this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null))
      .subscribe((obj: IValueChanged) => {
        this.handleValueChanged(obj);
        this.scheduleSummary$.next(this.jobScheduleService.jobScheduleSummary(this.setJobSchedule()));
      }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.workCrewId;
    params.useRouterOutlet = false;
    params.showCancel = false;
    params.showNav = false;
    params.showTitle = false;
    params.wrapsForm = true;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  initForm() {
    this.mds.setFieldDefinitions(this.myStoreName);

    this.daysOfWeek = this.jobScheduleService.daysOfWeek;
    this.selectedDaysOfWeek.forEach(x => x.checked = false);

    if (this.selectedDaysOfWeek.length === 0) {
      this.daysOfWeek.forEach(day => {
        this.selectedDaysOfWeek.push({ dayOfWeek: day, checked: false });
      })
    }
    this.duration$.next(' day');
      //always working wtih new, so use seedData to set the default object.

    this.objectData = cloneDeep(this.mds.getSeedData());
    this.objectData.installerId = this.installerId;
    this.objectData.installerWorkCrewId = this.workCrewId;
    this.showDays$.next(false);
    this.objectData.jobFrequencyType = null;
    this.objectData.jobFrequencyRecurUnit1 = 1;
    this.objectData.allDayEvent = true;
    const today = this.datePipe.transform(Date.now(), 'MM/dd/yyyy');
    this.objectData.jobStartDate = today;
    this.objectData.jobEndDate = '';
    this.objectData.jobScheduleRecurrenceType = 'Recurring';
    this.objectData.jobScheduleWeekdays = [];


    this.scheduleSummary$.next(this.jobScheduleService.jobScheduleSummary(this.setJobSchedule()));
    if (this.loaded) {
      //mds already loaded just reload form to init values
      this.loadForm();
    }
  }

  //just once
  loadForm() {
    //if specific fields were not passed in, will use all fields definied in field definition
    this.mds.loadSelectionLists(this.displayFields, this.myConstants.requestorTypeDetails, this.workCrewId);
    const allTypes = this.mds.getSelectItems('jobFrequencyType');
    const modifiedTypes = allTypes.filter(x => x['name'].toLowerCase() !== 'monthly');
    this.jobFrequencyTypes = modifiedTypes;
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.objectData, this.operation);

    this.loaded = true;
  }

  handleValueChanged(obj: IValueChanged) {
      switch (obj.key) {
      case 'allDayEvent':
        this.objectData.allDayEvent = obj.value;
        break;
        case 'jobFrequencyType':
          const type = obj.value ? obj.value['name'] : 'Daily';
          this.selectedDaysOfWeek.forEach(x => x.checked = false);
          this.objectData.jobFrequencyType = type;
          this.setDuration();
          this.showDays$.next(type === 'Daily' ? false : true);
        break;
      case 'jobFrequencyRecurUnit1':
          this.objectData.jobFrequencyRecurUnit1 = Number(obj.value) ? +obj.value : 0;
         this.setDuration();
        break;
      case 'jobStartDate':
          this.objectData.jobStartDate = obj.value;
          this.checkValidStartEnd();
        break;
      case 'jobEndDate':
          this.objectData.jobEndDate = obj.value;
          this.checkValidStartEnd();
        break;
      default:
        break;
    }
  }

  //end date is not required, but if set must be after start date
  //custom validation error:  distinct fields but used as a range
  checkValidStartEnd(): void {
    let isGreaterThan: boolean = false;
    if (this.form.valid && this.objectData.jobEndDate) {
      isGreaterThan = new Date(this.objectData.jobStartDate) > new Date(this.objectData.jobEndDate);
    }
    if (isGreaterThan) {
      this.errors = 'End Date can not be prior to the Start Date';
    } else {
      this.errors = null;
    }
    this.isInValidRange = isGreaterThan;
  }

  setDuration() {
    let duration: string = 'day';
    switch (this.objectData.jobFrequencyType) {
      case 'Daily':
        duration = this.objectData.jobFrequencyRecurUnit1 > 1 ? 'days' : 'day';
        break;
      case 'Weekly':
        duration = this.objectData.jobFrequencyRecurUnit1 > 1 ? 'weeks' : 'week';
        break;
      default:
    }
    this.duration$.next(duration);
  }

  setJobSchedule(): JobSchedule {
    let js = new JobSchedule();
    js.allDayEvent = true;//always for exclusions this.objectData.allDayEvent;
    js.jobFrequencyType = this.objectData.jobFrequencyType;
    js.jobFrequencyRecurUnit1 = this.objectData.jobFrequencyRecurUnit1;
    js.jobStartDate = this.objectData.jobStartDate;
    js.jobEndDate = this.objectData.jobEndDate;
    js.jobScheduleRecurrenceType = this.objectData.jobScheduleRecurrenceType;
    js.jobScheduleWeekdays = this.selectedDaysOfWeek.filter(x=> x.checked).map(x => x.dayOfWeek.longName);
    return js;
  }

  /*
      Set common create properties
  */
  setCommonProperties(): IInstallerWorkCrewScheduleExclusionViewModel {
    const form = this.form.value;
    let data: IInstallerWorkCrewScheduleExclusionViewModel = cloneDeep(this.objectData);
    data.jobFrequencyType = form.hasOwnProperty('jobFrequencyType') ? form['jobFrequencyType']['name'] : '';
    data.jobFrequencyRecurUnit1 = form['jobFrequencyRecurUnit1'];
    data.jobStartDate = form['jobStartDate'];
    data.jobEndDate =  form['jobEndDate'];
    data.jobScheduleWeekdays = [];
    const selectedDays = this.selectedDaysOfWeek.filter(x => x.checked === true);
    selectedDays.forEach(x => {
      data.jobScheduleWeekdays.push(x.dayOfWeek.longName);
    })

    //required by object
    data.jobScheduleRecurrenceType = 'Recurring';
    data.jobScheduleType = 'InstallerVacation';
    data.jobScheduleName = (new Date()).valueOf().toString(); //just need a unique name
    data.jobFrequencyRecurUnit2 = 1;
    data.jobFrequencyRecurUnit3 = 1;
    return data;
  }


  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
