import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../shared/shared.module';
import { FwModule } from '../../../fw/fw.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { FileUploadModule } from '../../file-upload/file-upload.module';
import { PoImportRoutingModule } from './po-import-routing.module';
import { ContactModule } from '../../contact/contact.module';

//services
import { PoImportService } from './services/po-import.service';

//components
import { 
  PoImportHeaderSummaryComponent,
  PoImportHeaderComponent,
  PoImportLineComponent,
  PoDownloadImportComponent
} from './components/index';

//containers
import {
  PoImportGroupedHeadersComponent,
  PoImportHeadersComponent,
  PoImportHeaderContainerComponent,
  PoImportLineContainerComponent,
  PoImportContactIManagerComponent,
  PoDownloadImportManagerComponent
 } from './containers/index';

@NgModule({
  declarations: [
    PoImportHeaderSummaryComponent,
    PoImportHeaderComponent,
    PoImportLineComponent,
    PoImportGroupedHeadersComponent,
    PoImportHeadersComponent,
    PoImportHeaderContainerComponent,
    PoImportLineContainerComponent,
    PoImportContactIManagerComponent,
    PoDownloadImportComponent,
    PoDownloadImportManagerComponent
],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FwSharedModule,
    FwModule,
    SharedModule,
    FileUploadModule,
    ContactModule,
    PoImportRoutingModule
  ],
  exports: [
    PoImportGroupedHeadersComponent,
    PoImportHeaderSummaryComponent,
    PoImportGroupedHeadersComponent,
    PoImportHeadersComponent,
    PoImportHeaderContainerComponent,
    PoImportLineContainerComponent,
    PoImportContactIManagerComponent,
    PoDownloadImportManagerComponent
 ]
})
export class PoImportModule {
  static forRoot(): ModuleWithProviders<PoImportModule> {
    return {
      ngModule: PoImportModule,
      providers: [ PoImportService]
    };
  }
}
