
<fw-dynamic-detail-container *ngIf="!inWizard && (detailConfig$ | async) && (objectData$ | async)"
                             [detailConfig]="detailConfig$ | async">
  <ng-container *ngTemplateOutlet="payoutPriceTemplate"></ng-container>
</fw-dynamic-detail-container>

<ng-container *ngIf="inWizard">
  <ng-container *ngTemplateOutlet="payoutPriceTemplate"></ng-container>
</ng-container>

<ng-template #payoutPriceTemplate>
  <div class="app-padding" [ngClass]="{ 'loading-mask': (working$ | async)}">
    <div *ngIf="(objectData$ | async) as payoutPrice">
      <div class="float-label">
        <select #overrideType
                [disabled]="operation === myConstants.operationTypeDetails"
                (change)="changeOverride(overrideType.value)" appFocus [autoFocus]="true">
          <option selected value="0">Choose...</option>
          <option *ngFor="let type of overrideTypes"
                  [value]="type"
                  [selected]="selectedOverride === type">
            {{type}}
          </option>
        </select>
        <label title="Override Type">Override Type</label>
      </div>

      <div class="float-label">
        <select #gc
                [disabled]="operation === myConstants.operationTypeDetails || !generalContractors.length || (selectedOverride !== gcType)"
                (change)="changeGc(gc.value)">
          <option selected value="0">Choose...</option>
          <option *ngFor="let gc of generalContractors"
                  [value]="gc.generalContractorId"
                  [selected]="gc.generalContractorId === payoutPrice.generalContractor_generalContractorId">
            {{gc.generalContractorName}}
          </option>
        </select>
        <label title="Narrow by General Contractor">Narrow by General Contractor</label>
      </div>

      <div class="float-label">
        <select #pLocation
                [disabled]="operation === myConstants.operationTypeDetails || !providerLocations.length || !(selectedOverride === gcType || selectedOverride === plType)"
                (change)="changeProviderLocation(pLocation.value)">
          <option selected value="0">Choose...</option>
          <option *ngFor="let pl of providerLocations"
                  [value]="pl.providerLocationId"
                  [selected]="pl.providerLocationId === payoutPrice.providerLocation_providerLocationId">
            {{pl.locationName}}
          </option>
        </select>
        <label title="Narrow by Provider Location">Narrow by Provider Location</label>
      </div>

      <div *ngIf="selectedOverride !== serviceType" class="float-label">
        <label title="Narrow by Location Service">Narrow by Location Service</label>
        <fw-multiselect-dropdown #locServices [options]="multiSelectOptions"
                                 [texts]="multiSelectLabels"
                                 [settings]="multiSelectSettings"
                                 [isDisabled]="operation === myConstants.operationTypeDetails || !multiSelectOptions.length || !pLocation.value > 0"
                                 (onAdded)="addLocService($event)"
                                 (onRemoved)="removeLocService($event)">
        </fw-multiselect-dropdown>
      </div>

      <div *ngIf="selectedOverride === serviceType" class="float-label">
        <select #service
                [disabled]="operation === myConstants.operationTypeDetails || !services.length"
                (change)="changeService(service.value)">
          <option selected value="0">Choose...</option>
          <option *ngFor="let s of services"
                  [value]="s.serviceId"
                  [selected]="s.serviceId === payoutPrice.service_serviceId">
            {{s.serviceName}}
          </option>
        </select>
        <label title="Narrow by Service">Narrow by Service</label>
      </div>

      <div class="float-label">
        <input #payoutPrice type="text" digitOnlyMask [decimal]="true"
               class="number-width"
               id="payoutPrice"
               required
               [value]="payoutPrice.effectiveDeckPrice ? payoutPrice.effectiveDeckPrice : 0"
               [disabled]="operation === myConstants.operationTypeDetails || !(isValid$ | async)"
               placeholder="0.00"
               (change)="priceChange($event.target.value)" />
        <label title="Price">Price</label>
      </div>

      <div class="app-btn-box">
        <button type="button"
                class="app-btn app-btn--cancel"
                (click)="onCancel()"
                title="Cancel">
          Cancel
        </button>
        <button class="app-btn app-btn--primary"
                type="button"
                (click)="onSave()"
                [disabled]="!(isValid$ | async)"
                [title]="operation === myConstants.operationTypeCreate ? 'Create and Close' : 'Save'">
          {{ operation === myConstants.operationTypeCreate ? 'Create and Close' : 'Save'}}
        </button>
      </div>

      <fw-error-box [errorData]="errorData$ | async" [errors]="errors$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
    </div>
  </div>
</ng-template>
