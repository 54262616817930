export interface IChargeBack {
  chargeBackId: number;   
  project_projectId: number;
  installer_installerId: number;
  acknowledged: boolean;
  submitted: boolean;
  chargeBackNotes: string;
  chargeBackStatusId: number;///field doesn't exist
  chargeBackStatus: any; //enum
  installmentFrequency: number;
  numInstallments: number;
  installmentAmount: number;
  createDate: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  //extraData
  canAddItems: boolean;
  amountTotal: number;
  customerName: string;
  remainingBalance: number;
  lastInstallmentDue: number;
  chargeBackInstallmentsMax: number;
  //metaData = new { chargeBackNoticeUrl }, chargeBackInstallmentsMax = 0 };

}


export class ChargeBack implements IChargeBack {
  chargeBackId: number;
  project_projectId: number;
  installer_installerId: number;
  acknowledged: boolean;
  submitted: boolean;
  chargeBackNotes: string;
  chargeBackStatusId: number;
  chargeBackStatus: any;
  installmentFrequency: number;
  numInstallments: number;
  installmentAmount: number;
  createDate: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  //extraData
  canAddItems: boolean;
  amountTotal: number;
  customerName: string;
  installerName: string;
  remainingBalance: number;
  lastInstallmentDue: number;
  chargeBackInstallmentsMax: number;

  constructor() {
    this.chargeBackId = 0;
    this.project_projectId = null;
    this.installer_installerId = null;
    this.acknowledged = false;
    this.submitted = false;
    this.chargeBackNotes = null;
    this.chargeBackStatusId = null;
    this.chargeBackStatus = null;
    this.installmentFrequency = null;
    this.numInstallments = null;
    this.installmentAmount = null;
    this.createDate = Date.now.toString();
    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';

  }

}

