<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <form *ngIf="(form$ | async) as form" [formGroup]="form" novalidate class="form user-security" >
    <fw-dynamic-field *ngIf="operation === myConstants.operationTypeCreate"
                      [field]="mds.getFieldDefinition('newAdUsers')"
                      [form]="form"
                      [operation]="isExternalUser ? myConstants.operationTypeDetails : operation"
                      [selectItems]="ldapUsers">
    </fw-dynamic-field>
    <fw-dynamic-field *ngIf="operation !== myConstants.operationTypeCreate"
                      [field]="mds.getFieldDefinition('isLdapUser')"
                      [form]="form"
                      [operation]="isExternalUser ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('firstName')"
                      [form]="form"
                      [operation]="isExternalUser ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('middleName')"
                      [form]="form"
                      [operation]="isExternalUser ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('lastName')"
                      [form]="form"
                      [operation]="isExternalUser ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('membershipUserName')"
                      [form]="form"
                      [operation]="isLdapUser || isExternalUser ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('supervisor')"
                      [form]="form"
                      [operation]="isExternalUser ? myConstants.operationTypeDetails : operation"
                      [selectItems]="supervisors">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('phoneNumber')"
                      [form]="form"
                      [operation]="isExternalUser ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('extension')"
                      [form]="form"
                      [operation]="isExternalUser ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('emailAddress')"
                      [form]="form"
                      [operation]="isLdapUser || isExternalUser ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
  </form>
  <fw-component-footer [lastUpdate]="objectData && objectData.hasOwnProperty('updateDate') ? objectData.updateDate : '' "></fw-component-footer>
  <fw-crud-buttons *ngIf="(form$ | async) as form"
                   [operation]="isExternalUser ? myConstants.operationTypeDetails : operation"
                   [disabled]="!form.dirty || !form.valid"
                   [showNext]="operation !== myConstants.operationTypeCreate"
                   (cancelEvent)="onCancel($event)"
                   (createEvent)="onCreate($event)"
                   (saveEvent)="onSave($event)">
  </fw-crud-buttons>
    <div *ngIf="isExternalUser" class="alert-box alert-box--danger">
      This provider user is an external user.  Editing disabled.
    </div>
</fw-dynamic-detail-container>
