import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminSecurityService } from '../../services/admin-security.service';

@Component({
  selector: 'privilege-template-privilege-values',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './privilege-template-privilege-values.component.html'
})
export class PrivilegeTemplatePrivilegeValuesComponent implements OnInit {
  public listDefinition: IListDefinition;
  privilegeTemplatePrivilegeId: number;

  constructor(public activeRoute: ActivatedRoute,
    public securityService: AdminSecurityService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.privilegeTemplatePrivilegeId = +paramMap.get('id');
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.securityService.loadValuesForPrivilegeTemplatePrivilegeListDefinition(this.privilegeTemplatePrivilegeId);
  }

}
