export interface ICustomerFlag {
  customerFlagId: number;
  provider_providerId: number;
  customerFlagName: string;
  customerFlagDescription: string;
  customerFlagColor: string;
}

export class CustomerFlag implements ICustomerFlag {
  customerFlagId: number;
  provider_providerId: number;
  customerFlagName: string;
  customerFlagDescription: string;
  customerFlagColor: string;

  constructor() {
    this.customerFlagId = 0;
    this.provider_providerId = 0;
    this.customerFlagName = '';
    this.customerFlagDescription = '';
    this.customerFlagColor = '';
  }

}
