import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { IPoImportNote,  IPurchaseOrderInventoryAllocation, IWorkOrderViewModel, INote, IProviderUserProjectTaskRollupModel, IDispatchedWorkOrderViewModel } from '../../../app/portals/view-models/index';
import { UserPriviledgesService } from '../../../auth/services/index';

@Injectable()
export class UserDashDynamicListService {

  constructor(public  userPriviledgesService: UserPriviledgesService,
    public datePipe: DatePipe) { }

  handleCustomMethod(methodName: string, row: any, portalEntityId: number): any { 
    switch (methodName) {
      case 'getOverdueInventoryCss':
        return this.getOverdueInventoryCss(row);
      case 'getSpecialInstructionsCss':
        return this.getSpecialInstructionsCss(row);
      case 'getOverdueOpenExternalNote':
        return this.getOverdueOpenExternalNoteCss(row);
      case 'getOverdueUnassignedExternalNote':
        return this.getOverdueUnassignedExternalNoteCss(row);
      case 'getOverDueTaskCss':
        return this.getOverDueTaskCss(row);
      case 'canDispatch':
        return this.canDispatch(row);
      case 'getOverdueCallLogCss':
        return this.getOverdueCallLogCss(row);
     default:
        return false;
    }
  }
  /*CUSTOM  METHODS TO SET CSS */
  getOverdueInventoryCss(row: IPurchaseOrderInventoryAllocation): string {
    if (!row || !row.estimatedDeliveryDate || row.receiveDate) {
      return '';
    }
    const todayDate = new Date(this.datePipe.transform(Date.now(), 'MM/dd/yyyy'));
    const deliveryDate = new Date(this.datePipe.transform(row.estimatedDeliveryDate, 'MM/dd/yyyy'));
    return (todayDate > deliveryDate) ? 'dynamic-list__item--danger' : '';
  }

  getSpecialInstructionsCss(row: IWorkOrderViewModel | IDispatchedWorkOrderViewModel): string {
    if (!row) {
      return '';
    }
    return row.specialInstructionAlert ? 'dynamic-list__item--warning' : '';
  }

  getOverdueOpenExternalNoteCss(row: INote): string {
    if (!row || !row.externalData) {
      return '';
    }
    const today = new Date();
    const createdOn = new Date(this.datePipe.transform(row.createDate, 'MM/dd/yyyy'));
    //console.log('check with: ', createdOn, today, row.externalData.dateClosed);
    return !row.externalData.dateClosed && createdOn.addDays(2) < today ? 'dynamic-list__item--danger' : '';
  }


  getOverdueUnassignedExternalNoteCss(row: IPoImportNote): string {
    if (!row ) {
      return '';
    }
    const today = new Date();
    const createdOn = new Date(this.datePipe.transform(row.dateCreated, 'MM/dd/yyyy'));
    //console.log('check with: ', createdOn, today, row.externalData.dateClosed);
    return  createdOn.addDays(2) < today ? 'dynamic-list__item--danger' : '';
  }

  getOverDueTaskCss(row: IProviderUserProjectTaskRollupModel): string {
    if (!row) {
      return '';
    }
    return row.overdueTasks > 0 ? 'dynamic-list__item--danger' : '';
  }

  canDispatch(row: IWorkOrderViewModel | IDispatchedWorkOrderViewModel): boolean {
    return row && row.hasOwnProperty('scheduleId') && row.scheduleId && row.scheduleId > 0;
  }

  getOverdueCallLogCss(row: IWorkOrderViewModel): string {
    return row && row.isCallLogOverDue ? 'dynamic-list__item--danger' : '';
  }

 /*CUSTOM METHODS TO DETERMINE BUTTON ENABLED */
}
