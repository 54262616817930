<div class="notelist-item">
  <div class="notelist-item--label">From</div>
  <div class="notelist-item--text">{{createUser}}</div>
  <div class="notelist-item--label">Date</div>
  <div class="notelist-item--text">{{createDate | date:'MM/dd/yyyy h:mm a' }}</div>
  <ng-container *ngIf="!isPoImportNote">
    <div class="notelist-item--label">Context</div>
    <div class="notelist-item--text">{{getContext()}}</div>
    <div class="notelist-item__postIt">
      <div *ngIf="isProjectNote" class="check-box-field">
        <fw-check-box #checkBox [config]="cbConfig"
                      [isChecked]="(note.postIt)"
                      (change)="changePostIt(checkBox.value)"></fw-check-box>
      </div>
    </div>
  </ng-container>


  <p class="notelist-item__note">
    <span *ngIf="note.postIt" title="Project Special Instructions" class="app-icon app-icon--warning notelist__action">
      <i class="fas fa-siren-on"></i>
    </span>
    {{noteText}}
  </p>
</div>

<div *ngIf="isPoImportNote || note.isExternal" class="notelist-item notelist__external">
  <div class="notelist-item__header">External Note Information</div>
  <div class="notelist-item--label">Origin</div>
  <div class="notelist-item--text">{{origination}}</div>
  <div class="notelist-item--label">Created By</div>
  <div class="notelist-item--text">{{externalUser }}</div>
  <div class="notelist-item--label">Date Created</div>
  <div class="notelist-item--text">{{externalDateCreated | date:'MM/dd/yyyy'}}</div>
  <div *ngIf="externalDateClosed" class="notelist-item--label">Date Closed</div>
  <div *ngIf="externalDateClosed" class="notelist-item--text">{{externalDateClosed |  date:'MM/dd/yyyy'}}</div>
  <div *ngIf="!externalDateClosed && (isPoImportNote || origination.toLowerCase() === 'store' )">
    <note-reply [noteId]="note[key]"
                [errorData]="errorAction === actionFromReply ? errorData : ''"
                [errorId]="errorId"
                [isPoImportNote]="isPoImportNote"
                (replyEvent)="onSendReply($event)"></note-reply>
  </div>
</div>
