import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

/** Imports for services */
import { DomainObjectService } from '../../../../shared/services/domain-object.service';

/** Imports for interfaces */
import { IErrorData } from '../../../../shared/interfaces/index';
import { IManagerPortal, IPortalSection, } from '../interfaces/index';

import { DynamicItem } from '../../../../fw/dynamic-host/index';
import * as complianceContainers from '../../compliance/containers/index';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PortalBaseService implements  OnDestroy {
  // The ID of this portal (from the ManagerPortals table)
  public portalId: number;  // 
  public portalEntityId: number;  // 
  // The manager portal object
  public portalData: IManagerPortal;
  // The sections and their widgets that are associated to this portal
  public portalSections: IPortalSection[] = [];

  // Set when an error is encountered in a service
  public errorMessage: string;
  public errorData: IErrorData[] = [];

  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public domainObjectService: DomainObjectService) {
  }

  ///**
  // * Called when the component is initialized
  // */
  //ngOnInit(): void {
  //  console.log('portalbase init');
  //  this.portalSections = [];
  //  this.portalData = null;

  //  this.errorMessage = '';
  //  this.activeRoute.paramMap.subscribe(paramMap => {
  //    this.portalId = +paramMap.get('portalId');
  //    this.portalEntityId = paramMap.has('portalEntityId ') ? +paramMap.get('portalEntityId ') : -1;
  //    if (Number.isNaN(this.portalEntityId)) {
  //      this.portalEntityId = -1;
  //    }
  //    this.getManagerPortalDetails();
  //  });
  //}

  getManagerPortalDetails() {
    this.portalData = null;

    this.subscription.add(this.domainObjectService.get("ManagerPortal", this.portalId)
      .subscribe(result => {
        if (result.success) {
          this.portalData = <IManagerPortal>result.data;
          this.getPortalSections();
        } else {
          this.errorData = result.errorData;
        }
      }, error => this.errorMessage = <any>error));
  }

  /**
   * Retrieves all the widget layout sections which the user has configured for this portal.
   * We are assuming this comes back ordered by OrderInPortal.
   */
  getPortalSections(): void {
    this.portalSections = [];
    // Get all sections associated with this portal
    this.subscription.add(this.domainObjectService
      .getByMethodById("UserWidgetLayoutSection", "CurrentByUser", this.portalId)
      .subscribe(result => {
        if (result.success) {
          this.portalSections = <IPortalSection[]>result.data;
          //initialize widget seedData to trigger creation of dynamic component via dynamice-host.component directive
          for (let s of this.portalSections) {
            for (let w of s.userWidgetLayouts) {
              if (w.componentName) {
                //if no component name - skip
                w.componentSeedData = this.resolveComponentByName(w.componentName);
              }
            }
          }

        } else {
          this.errorData = result.errorData;
        }
      },
        error => this.errorMessage = <any>error));
  }

  //so do not have to handle all containers (component-lists) here - may want to do this in the portal specific initializer
  //Initialize the component data object
  // Pass in any necessary starter data within seedData: IDynamicObject}
  resolveComponentByName(componentName: string) {
    let seedData = { portalId: this.portalId, componentName: componentName, portalEntityId: this.portalEntityId };

    switch (this.portalId) {
      case 6:
        return new DynamicItem(complianceContainers[componentName], seedData);
      default:
        return null;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
