import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'schedule-ineligible',
  templateUrl: './schedule-ineligible.component.html'
})
export class ScheduleIneligibleComponent implements OnInit {
  @Input() workOrderId: number;
  @Input() reason: string;

  constructor( public activeRoute: ActivatedRoute) { }

  ngOnInit() {
    //schedule id will always be > 0 for this component
  }


}
