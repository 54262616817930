
export enum PoImportEvent {
  viewContactInformation = 'viewContactInformation',
  importPurchaseOrder = 'importPurchaseOrder',
  selectImportToProject = 'selectImportToProject', //used in seeding, don't change name
  goToProject = 'goToProject',
  showPricingWizard = 'showPricingWizard',
  deletePoImport = 'deletePoImport',
  deletePoImportHeader = 'deletePoImportHeader',
  showDownloadAndImport = 'showDownloadAndImport',
  downloadAndImport = 'downloadAndImport',
  cancel = 'cancel'
}

export enum PoImportStore {
  poImportGroupedHeaders = 'poImportGroupedHeaders',
  poImportGroupedErrors = 'poImportGroupedErrors',
  poImportHeaders = 'poImportHeaders',
  poImportLines = 'poImportLines',
}
