<div *ngIf="workCrewId$ | async" class="portal">
  <div class="portal__detail--item">
    <work-crew-general-information [installerId]="installerId"
                                   [workCrewId]="workCrewId$ | async"
                                   [canIEdit]="(userPriviledgesService.canManageInstallers$ | async)"></work-crew-general-information>
  </div>
  <div class="portal__detail--item">
    <installer-eligibility-summary [installerId]="installerId"
                                   [workCrewId]="workCrewId$ | async "
                                   [canIEdit]="(userPriviledgesService.canManageInstallers$ | async)"></installer-eligibility-summary>
  </div>
  <div class="portal__detail--item">
    <installer-work-crew-technicians [providerId]="providerId"
                                     [installerId]="installerId"
                                     [workCrewId]="workCrewId$ | async"
                                     [includeOldestRequirement]="includeOldestRequirement"
                                     [canCreate]="userPriviledgesService.canManageInstallers$ | async">
    </installer-work-crew-technicians>
    <div class="workcrew-manager__add-tech" [ngClass]="{ 'loading-mask-no-spinner': loading$ | async }">
      <label for="availableTechnicians" [htmlFor]="availableTechnicians">Available Technicians</label>
      <select id="availableTechnicians" name="availableTechnicians"
              [formControl]="form.controls['availableTechnicians']" required>
        <option value="">Choose A Technician.....</option>
        <option *ngFor="let tech of (availableTechnicians$ | async)" [value]="tech.installerWorkCrewTechnicianId">{{tech.contactName}}</option>
        <!--item will be the full select item-->
      </select>
      <button type="button" class="app-btn app-btn--primary"
        [disabled]="!(userPriviledgesService.canManageInstallers$ | async) || (form.controls['availableTechnicians'].value === '')"
        (click)="onAdd()">
        Add To Work Crew
      </button>
    </div>
  </div>
  <div class="portal__detail--item">
    <fw-component-title title='Locations and Services'></fw-component-title>
    <installer-work-crew-pl-services
      [installerId]="installerId"
      [workCrewId]="workCrewId$ | async"
      [canIEdit]="(userPriviledgesService.canManageInstallers$ | async)">
    </installer-work-crew-pl-services>
  </div>
  <div class="portal__detail--item">
    <fw-component-title title='Work Categories'></fw-component-title>
    <installer-work-crew-work-categories
      [installerId]="installerId"
      [workCrewId]="workCrewId$ | async"
      [canIEdit]="(userPriviledgesService.canManageInstallers$ | async) || (userPriviledgesService.canManageWorkCategories$ | async)">
    </installer-work-crew-work-categories>
  </div>
  <div class="portal__detail--item">
    <fw-component-title title='Zones'></fw-component-title>
    <installer-work-crew-zones [installerId]="installerId"
                               [workCrewId]="workCrewId$ | async"></installer-work-crew-zones>
  </div>
  <div class="portal__detail--item">
    <installer-work-crew-schedule-exclusions
      [installerId]="installerId"
      [workCrewId]="workCrewId$ | async"
      [canIEdit]="(userPriviledgesService.canManageInstallers$ | async)">
    </installer-work-crew-schedule-exclusions>
  </div>

</div>
