  <div [ngClass]="{ 'loading-mask': !detailConfig$ | async }">
  <fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
    <form *ngIf="form && (mds.mdsReady$ | async)" [formGroup]="form" novalidate class="job-sched">

      <fieldset>
        <legend>Recurrence</legend>
        <div class="job-sched__recur">

          <div class="job-sched__recur__1">
            <fw-dynamic-field class="job-sched__dynamic-field"
                              [field]="mds.getFieldDefinition('jobFrequencyType')"
                              [selectItems]="jobFrequencyTypes"
                              [form]="form"
                              [operation]="operation">
            </fw-dynamic-field>

          </div>
          <div class="job-sched__recur__2"> </div>
          <div class="job-sched__recur__3">
            <div class="job-sched__recur__3--units">
              <fw-dynamic-field class="job-sched__dynamic-field"
                                [field]="mds.getFieldDefinition('jobFrequencyRecurUnit1')"
                                [form]="form"
                                [operation]="operation">
              </fw-dynamic-field>
              <label class="job-sched__duration">{{duration$ | async}}</label>
            </div>
            <div class="job-sched__recur__3--days">
              <div class="job-sched__day" *ngFor="let item of selectedDaysOfWeek; let i = index">
                <fw-check-box #checkBox [config]="{label: {label: item.dayOfWeek.shortName, alignRight: false}, form: {form: form, name: 'daysOfWeek', value: item.dayOfWeek.longName}}"
                              [isDisabled]="!(showDays$ | async)"
                              (change)="updateSelectedDays(i, checkBox.value)"></fw-check-box>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="job-sched__dates">
        <fieldset>
          <legend>Duration</legend>
          <fw-dynamic-field class="job-sched__dynamic-field"
                            [field]="mds.getFieldDefinition('jobStartDate')"
                            [form]="form"
                            [error]="isInValidRange"
                            [operation]="operation">
          </fw-dynamic-field>

          <fw-dynamic-field class="job-sched__dynamic-field"
                            [field]="mds.getFieldDefinition('jobEndDate')"
                            [form]="form"
                            [error]="isInValidRange"
                            [operation]="operation">
          </fw-dynamic-field>
          <fw-error-box [errors]="errors" [joinErrors]="true"></fw-error-box>
        </fieldset>
      </div>
      <fieldset>
        <legend>Summary</legend>
        <div class="app-text">{{ scheduleSummary$ | async }}</div>
      </fieldset>
</form>

    <fw-crud-buttons [operation]="operation" [disabled]="formDisabled()" [showNext]="showNext" (cancelEvent)="onCancel($event)" (createEvent)="onCreate($event)"></fw-crud-buttons>
  </fw-dynamic-detail-container>
  </div>
