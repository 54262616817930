import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { PricingEvent } from '../../../pricing-shared/enums/pricing-shared.enums';
import { IPayout } from '../../../view-models';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { AdminPricingService } from '../../services/admin-pricing.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'provider-payouts',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './provider-payouts.component.html'
})
export class ProviderPayoutsComponent implements OnInit {

  public listDefinition: IListDefinition;

  constructor(
    public activeRoute: ActivatedRoute,
    public adminService: AdminPricingService,
    public modalService: ModalService  ) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case PricingEvent.viewSkuPayouts:
        this.openSkuPayouts(event.data);
        break;
      case PricingEvent.managePayoutPrices:
        this.openPayoutPrices(event.data);
        break;
      default:
        break;
    }
  }

  openSkuPayouts(p: IPayout): void {
    this.modalService.open({
      title: 'Skus for Payout'.concat(' ', p.payoutName),
      path: 'payout-skus/' + p.payoutId.toString(),
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      hasTabs: false
    });
  }

  openPayoutPrices(p: IPayout): void {
    this.modalService.open({
      title: 'Prices for Payout'.concat(' ', p.payoutName),
      path: 'payout-prices/' + p.payoutId.toString(),
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      hasTabs: false
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadProviderPayoutsListDefinition();
  }

}
