/*A child component for Installer and Technician Dashboard*/
import { Component, OnInit, Input,  SimpleChanges, OnChanges } from '@angular/core';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, IListButtonType } from '../../../../../fw/dynamic-list/interfaces/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'installer-work-crew-work-categories',
  templateUrl: './installer-work-crew-work-categories.component.html'
})
export class InstallerWorkCrewWorkCategoriesComponent implements OnInit, OnChanges {
  @Input() installerId: number;
  @Input() workCrewId: number;
  @Input() canIEdit: boolean = false;

  listDefinition: IListDefinition;

  constructor(public dynamicListService: DynamicListService) { }

  ngOnInit() {
    this.loadListDefinition();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workCrewId'] && !(changes['workCrewId'].firstChange)) {
      this.workCrewId = changes['workCrewId'].currentValue;
      this.loadListDefinition();
    }
  }

  /*
      Parent is Work Crew Id
  */
  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Work Preferences and Capacities';
    const listObjectController = 'InstallerWorkCrewWorkCategory';
    const listStoreName = 'workCrewWorkCategories';
    const detailRoutePath = 'installer-work-crew-work-category';
    const listRowKeyId = 'installerWorkCrewWorkCategoryId';
    const detailRouterOutlet = 'workCrewWorkCategoryOutlet';
    const defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition(detailRouterOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = this.workCrewId;
    listDefinition.parentKey = 'installerWorkCrew_installerWorkCrewId';
    listDefinition.controllerMethod = 'ByInstallerWorkCrew';
    listDefinition.methodParameters = this.workCrewId.toString();
    listDefinition.allowSaveFilter = false;

    if (this.canIEdit) {
      let crudButtons: IListButtonType[] = [
        { type: ButtonType.detail, defaults: null },
        { type: ButtonType.edit, defaults: null },
        { type: ButtonType.delete, defaults: null }
      ];

      listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);
   }
    this.listDefinition = listDefinition; //set at end to signify ready to instantiate the list.

  }

  /*
      Define the columns in the list to be created.
  */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('workCategory');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('dailyCrewCapacity');
    columnDefinitions.push(colDef);

      colDef = new ListColumn('workCategoryUnit');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

    colDef = new ListColumn('capacityComments');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);


    return columnDefinitions;
  }

}
