<div [ngClass]="{'loading-mask': (loading$ | async)}">
  <div class="form-wrapper form-wrapper--edit">
    <div class="form provider-schedule-exclusions">
      <div class="form-group provider-schedule-exclusions__form">
        <div class="provider-schedule-exclusions__form--sd">
          <fw-calendar [isMultiSelect]="true" [singlePicker]="true" [label]="'Dates'" [multiSelectDates]="(selectedDates$ | async)" (onSelected)="onSelected($event)"></fw-calendar>
        </div>
      </div>
      <div>
        <div class="provider-schedule-exclusions__selected">
          <div class="provider-schedule-exclusions__selected--header">Selected Dates</div>
          <ul class="provider-schedule-exclusions__selected--items">
            <li *ngFor="let item of (selectedDates$ | async);" class="provider-schedule-exclusions__selected--item">
              {{ item | date:'MM/dd/yyyy' }}
              <fw-error-box *ngIf="responseItems.length && hasError(item)" [errors]="getResponseMessage(item)"></fw-error-box>
              <div class="alert-box alert-box--success form__alert-box" *ngIf="responseItems.length && !hasError(item) && getResponseMessage(item)">{{ getResponseMessage(item) }}</div>
            </li>
          </ul> 
        </div>
      </div>
    </div>
    <fw-crud-buttons [operation]="myConstants.operationTypeCreate" [disabled]="!(selectedDates$ | async).length" [showNext]="false" (cancelEvent)="onCancel()" (createEvent)="onCreate()"></fw-crud-buttons>
  </div>
</div>

