import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

import { IEntityDocument } from '../../../portals/view-models/index_two';
import { FileUploaderService } from '../../services/file-uploader.service';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html'
})

export class FileUploaderComponent implements OnInit {
  @Input() entityDocument: IEntityDocument;
  @Input() allowedTypes: string[];
  @Output() public addEvent = new EventEmitter<File>();

  constructor(
    public fus: FileUploaderService) {
  }

  ngOnInit(): void {
  }

  public onDragOver(ev): void {
    ev.preventDefault();
  }

  public onDrop(ev): void {
    ev.preventDefault();
    this.processFiles(Array.from(ev.dataTransfer.files || []));
  }

  public onSelected(fileList: FileList): void {
    this.processFiles(Array.from(fileList || []));
  }

  processFiles(files: File[]): void {
    event.preventDefault();
    const rejectedItems: string[] = [];
    if (!files.length) return;

    if (this.entityDocument && files.length > 1) {
      alert('Only one file is allowed for this action.  The first file will be selected');
    }

    //Validate and reject or emit each file selected.
    files.forEach(file => {
      const fileExt = file.type !== null && (file.type.length > 3 || file.type.length === 0)
        ? this.fus.getFileExtension(file.name)
        : file.type;
      if (this.fus.validateSelected(fileExt, this.allowedTypes)) {
        this.addEvent.emit(file);
      } else {
        rejectedItems.push(file.name);
      }
    });

    if (rejectedItems.length > 0) {
      alert("The following items can not be uploaded: \r\n".concat('\u2022 ', rejectedItems.join('\r\n\u2022 '), ". \r\nOnly files of type: (", this.allowedTypes.join(', '), ") are allowed."));
    }
  }

}
