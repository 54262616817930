<div class="signature">
  <div class="signature__hdr">
    <div class="signature__hdr__title">Draw Your Signature</div>
    <div class="app-btn-box signature__hdr__btns">
      <button class="app-btn app-btn--danger" type="button" (click)="onClear()">Clear</button>
    </div>
  </div>

  <canvas #signaturePad height="100" width="200" class="signature__pad"> </canvas>

  <div class="app-btn-box signature__btns">
    <button *ngIf="displayType === '0'" class="app-btn app-btn--cancel" type="button"
            (click)="goBack()">
      Back
    </button>
    <button class="app-btn app-btn--primary" type="button"
            [disabled]="!(haveSignature$ | async)"
            (click)="onSubmit()">
      Sign
    </button>
  </div>
</div>
