export interface ISearchTypeOption {
  label: string;
  value: string;
  isDefault: boolean;
}
export interface ISearchTypeConfig {
  isVisible: boolean;
  options: ISearchTypeOption[];
}


export class SearchTypeConfig implements ISearchTypeConfig {
  isVisible: boolean;
  options: ISearchTypeOption[];

  constructor(isVisible: boolean = false) {
    this.isVisible = isVisible;
    this.options = [];
  }
}
