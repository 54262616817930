<div *ngIf="authorizedTabs">
  <fw-app-tabs *ngIf="authorizedTabs"
               apptabs
               [portalId]="-1"
               [portalEntityId]="-1"
               [level]="1"
               [limit]="authorizedTabs.length"
               [tabDefinition]="authorizedTabs"
               [isLocallyDefined]="true">
  </fw-app-tabs>

</div>
<div class="modal-content">
  <div class="app-panel app-panel--concat-tab">
    <div class="component--wrapper">
      <!--for animation-->
      <router-outlet></router-outlet>
    </div>
    </div>
  </div>

