<div [ngClass]="{ 'loading-mask': (working$ | async)}">
  <fw-component-title [title]="title"></fw-component-title>
  <div class="schedule-manager">
    <div class="schedule-manager__toolbar">
      <button class="dynamic-list-toolbar--btn app-btn-icon app-btn-icon--success"
              (click)="createSchedule()"
              title="Create New Schedule"
              [disabled]="!(canIEdit$ | async)
                                  || operation !== myConstants.operationTypeDetails
                                  || (operation === myConstants.operationTypeDetails && workOrder.notSchedulableReason)">
        <i class="fas fa-plus"></i>
      </button>
    </div>

    <div class="schedule-manager__view" *ngIf="operation === myConstants.operationTypeDetails && !workOrder.notSchedulableReason">
      <ng-container *ngFor="let schedule of (schedules$ | async)">
        <schedule [schedule]="schedule"
                  [showButtons]="true"
                  [canIEdit]="canIEdit$ | async"
                  [canIDelete]="canIDelete$ | async"
                  [providerLocationId]="workOrder.providerLocation_providerLocationId"
                  [moveUpVisible]="moveUpVisible && schedule.scheduleId === activeScheduleId"
                  (customEvent)="onCustom($event)">
        </schedule>
      </ng-container>
    </div>

    <div class="schedule-manager__ineligible" *ngIf="operation === myConstants.operationTypeDetails && workOrder.notSchedulableReason">
      <schedule-ineligible *ngIf="!(working$ | async)" [reason]="workOrder.notSchedulableReason">
      </schedule-ineligible>
    </div>

    <div class="schedule-manager__editor" *ngIf="operation !== myConstants.operationTypeDetails">
      <schedule-editor [workOrder]="workOrder"
                       [currentSchedule]="activeSchedule$ | async"
                       [operation]="operation"
                       [availability]="availability$ | async"
                       [availableInstallers]="availableInstallers$ | async"
                       [scheduleCount]="(schedules$ | async)?.length || 0"
                       [screenIsSmall]="screenIsSmall$ | async"
                       [errorData]="errorData$ | async"
                       (customEvent)="onCustom($event)">
      </schedule-editor>
    </div>
  </div>
  <fw-error-box [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>

