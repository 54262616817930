/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { AdminDashEvent } from '../../enums/admin-dashboard.enums';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';

@Component({
  selector: 'customer-surveys',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './customer-surveys.component.html'
})
export class CustomerSurveysComponent implements OnInit {
  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public adminDashService: AdminDashboardService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminDashEvent.launchSurveyImport:
        this.openSurveyImport();
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.adminDashService.loadWorkOrderSurveyListDefinition(true);
  }

  openSurveyImport(): void {
    const params = {
      rowIndex: -1,
      storeName: this.listDefinition.storeName,
      key: this.listDefinition.rowKeyId,
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: -1
    };


    this.modalService.open({
      title: 'Import Customer Survey',
      path: 'customer-survey-import',
      id: 0,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      hasTabs: false,
      optionalParams: params,
      isSubModal: this.modalService.opened
    });
  }

}
