<div class="sms-manager">

  <div *ngIf="newMessageAlert$ | async" class="sms-manager__alert">
    <div class="sms-manager__alert--text">{{ newMessageAlert$ | async }}</div>
    <button type="button"
            class="app-btn-icon app-btn-icon--primary sms-manager__alert--btn"
            title="Refresh"
            (click)="refreshContacts()">
      <i class="fas fa-sync"></i>
    </button>
  </div>

  <div class="sms-manager__contacts" [ngClass]="{'sms-manager__hide': (screenIsSmall$ | async) && uiPage !== 1 }">
    <fw-accordian-single [name]="filtersItem"
                         [title]="'Filters'"
                         [activeItem]="activeListItem$ | async"
                         [scollable]="false"
                         (toggled)="onToggleListItem($event)">
      <sms-filters *ngIf="(providerLocations$ | async) as locations"
                   [locations]="locations"
                   [branches]="branches$ | async"
                   [providerUsers]="providerUsers$ | async"
                   [resetOn]="resetTimestamp$ | async"
                   (filter)="onCustom($event)">
      </sms-filters>
    </fw-accordian-single>
    <fw-accordian-single [name]="contactsItem"
                         [title]="'Contacts'"
                         [activeItem]="activeListItem$ | async"
                         [scollable]="false"
                         [customBtn]="refreshBtn"
                         (customEvent)="onCustom($event)"
                         (toggled)="onToggleListItem($event)">
      <div [ngClass]="{'loading-mask': (working$ | async) }">
        <div *ngIf="activeFilterText" class="sms-manager__contacts__filterInfo">
          <span class="sms-manager__filterInfo--label">Active <i class="fas fa-filter app-icon--success"></i>:</span>
          <span class="sms-manager__filterInfo--val">{{ activeFilterText }}</span>
        </div>
        <div *ngIf="(totalItems$ | async) > -1" class="sms-manager__contacts__container"
             infiniteScroll
             [options]="scrollOptions"
             [listContainer]="listContainer"
             [refreshOn]="refreshOn"
             (scrolledToEnd)="onScrollEnd($event)">
          <div class="sms-manager__contacts__contents">
            <ng-container #listContainer *ngFor="let contact of (contacts$ | async);">
              <sms-contact [contact]="contact"
                           [activeId]="activeContact ? activeContact.contactId : -1"
                           [mgrFiltered]="mgrFilter && mgrFilter.providerUser ? mgrFilter.providerUser.providerUserId !== (userPriviledgesService.currentUserId$ | async) : false"
                           (selected)="onCustom($event)">
              </sms-contact>
            </ng-container>
          </div>
        </div>
      </div>
    </fw-accordian-single>

    <div *ngIf="(totalItems$ | async) > -1 && (activeListItem$ | async) === contactsItem"
         class="sms-manager__contacts__count">
      {{totalItems$ | async}} Items
    </div>

  </div>

  <div class="sms-manager__convo" [ngClass]="{'sms-manager__hide': (screenIsSmall$ | async) && uiPage !== 2 }">
    <router-outlet name="chat"></router-outlet>
  </div>

  <div class="sms-manager__info" [ngClass]="{'sms-manager__hide': (screenIsSmall$ | async) && uiPage !== 3 }">
    <fw-accordian-single [name]="contactInfoItem"
                         [title]="'Contact Information'"
                         [activeItem]="activeDetailItem$ | async"
                         [scollable]="false"
                         (toggled)="onToggleDetailItem($event)">
      <div class="sms-manager__info--contact">
        <router-outlet name="infobar"></router-outlet>
      </div>
    </fw-accordian-single>

    <ng-container *ngFor="let project of (projectMins$ | async); let i = index;">
      <fw-accordian-single [name]="projectItem.concat(' ', project.projectId)"
                           [title]="'Project '.concat(project.projectId)"
                           [subTitle]="(project.status | titlecase)"
                           [activeItem]="activeDetailItem$ | async"
                           [scollable]="false"
                           [wrapPad]="false"
                           (toggled)="onToggleDetailItem($event, project.projectId)">
        <div class="sms-manager__info--project">
          <sms-project-summary *ngIf="(activeDetailItem$ | async) === projectItem.concat(' ', project.projectId)"
                               [projectId]="project.projectId"
                               (activated)="onActivated($event)">
          </sms-project-summary>
        </div>
      </fw-accordian-single>
    </ng-container>
  </div>

  <div *ngIf="(screenIsSmall$ | async) && uiPage > 1" class="app-btn-box sms-manager__uipager">
    <button type="button"
            class="app-btn app-btn--primary"
            (click)="changeUiPage('contacts')"
            [title]="'Show Contacts'">
      Contacts
    </button>
    <button *ngIf="uiPage === 3"
            type="button"
            class="app-btn app-btn--primary"
            (click)="changeUiPage('convo')"
            [title]="'Show Conversation'">
      Conversation
    </button>
    <button *ngIf="uiPage < 3"
            type="button"
            class="app-btn app-btn--primary"
            (click)="changeUiPage('info')"
            [title]="'Show Contact Information'">
      Contact Info
    </button>

  </div>

<fw-error-box [errorData]="errorData$ | async" [errors]="errors$ | async" [ignoreKey]="true"></fw-error-box>

</div>
