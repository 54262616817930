import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IHomEventEmitter, HomEventEmitterService } from 'hom-lib/hom-event-emitter';

import { HomSignatureType } from './hom-signature-type.enums';
import { HomSignatureAction } from './hom-signature-action.enums';
import { HomSignatureEvent } from './hom-signature-event.enums';
import { HomDataAccessService } from 'hom-lib/hom-data-access';

export interface IUploadFile {
  file: File;
  name: string;
  fileType: string;
  imageSize: string;
  rotateClockwise: number;
  previewUrl: any;
}

@Component({
  selector: 'hom-signature-manager',
  templateUrl: './hom-signature-manager.component.html'
})
export class HomSignatureManagerComponent implements OnInit, OnDestroy {
  public errors$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public signatureSuccess$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  entityDocumentId: number = 0;
  actionId: number = 0;
  displayType: HomSignatureType = HomSignatureType.StandAlone;
  blah: boolean = false;
  crmFormQuestionId: number = 0;

  subscription = new Subscription();

  constructor(public router: Router,
    public activeRoute: ActivatedRoute,
    public dos: HomDataAccessService,
    public location: Location,
    public emitterService: HomEventEmitterService  ) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.signatureSuccess$.next(false);
      this.entityDocumentId = +paramMap.get('id');
      this.actionId = +paramMap.get('actionId');
      this.displayType = paramMap.get('type') as HomSignatureType | HomSignatureType.StandAlone;
      this.blah = paramMap.get('crmForm') === 'true';
      this.crmFormQuestionId = +paramMap.get('crmFormQuestionId')
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onPadEvent(emitter: IHomEventEmitter): void {
    switch (emitter.event) {
      case HomSignatureAction.Save:
        this.saveSignature(emitter.data);
        break;
      case HomSignatureAction.GoBack:
        if (this.displayType === HomSignatureType.StandAlone) {
          this.location.back();
        }
        break;
      default:
        break;
    }
    event.stopPropagation();
  }

  saveSignature(base64Sig: any): void {
    this.errors$.next([]);
    this.loading$.next(true);
    let formData: FormData = new FormData();

    formData.append('id', this.entityDocumentId.toString());
    formData.append('actionId', this.actionId.toString());
    formData.append('signature', base64Sig);
    if (this.blah) formData.append('crmFormQuestionId', this.crmFormQuestionId.toString())

    this.subscription.add(this.dos.createByMethodWithForm(this.blah ? 'EntityCrmFormSectionQuestionViewModel':'EntityDocument', 'SignDocument', formData)
      .subscribe((result: any) => {
        if (result.success) {
          if (this.displayType === HomSignatureType.StandAlone) {
            this.location.back();
          } else {
            this.signatureSuccess$.next(true);
            let event: IHomEventEmitter = {
              requestor: 'hom-signature',
              event: HomSignatureEvent.CaptureSuccessful,
              action: '',
              data: null
            };
            this.emitterService.emitSignatureEvent(event);
          }
        } else {
          this.errors$.next(result.errorData);
          let event: IHomEventEmitter = {
            requestor: 'hom-signature',
            event: HomSignatureEvent.CaptureFailed,
            action: '',
            data: null
          };
          this.emitterService.emitSignatureEvent(event);
        }
        this.loading$.next(false);
      })
    );
  }

}
