import { Component, OnInit } from '@angular/core';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'lookup-types',
  templateUrl: './lookup-types.component.html'
})
export class LookupTypesComponent implements OnInit {

  listDefinition: IListDefinition;

  constructor(public adminProviderService: AdminProviderService) {
  }

  ngOnInit() {
    this.listDefinition = this.adminProviderService.loadLookupTypesListDefinition();
  }

}
