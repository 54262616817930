import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FwModule } from '../../../fw/fw.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../shared/shared.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { UserPriviledgesService } from '../../../auth/services/index';
import { WarrantyDashboardService } from './services/warranty-dashboard.service';

import { WarrantyDashboardRoutingModule } from './warranty-dashboard-routing.module';

//components
import {
  WarrantyAssignUserComponent
} from './components/index';

//containers
import {
  WarrantyDashboardComponent,
  OpenWarrantiesComponent,
  UnassignedWarrantiesComponent
} from './containers/index';

//guards
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';


@NgModule({
  declarations: [
    WarrantyDashboardComponent,
    OpenWarrantiesComponent,
    UnassignedWarrantiesComponent,
    WarrantyAssignUserComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      FwModule,
      FwSharedModule,
      SharedModule,
      WarrantyDashboardRoutingModule,
    PortalSharedModule
  ],
  providers: [
    IsCurrentGuard,
    UserPriviledgesService,
    WarrantyDashboardService
  ]
})

export class WarrantyDashboardModule {}
