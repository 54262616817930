import { Component, OnInit, Input, OnChanges, Inject, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';

import {  IDynamicListDataCol, } from '../../interfaces/index';
import { TabsService } from '../../../fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';
import { ITabRequest } from '../../../fw-shared/components/fw-app-tabs/interfaces/i-tab-request';
import { ModalService } from '../../../fw-modal/services/fw-modal.service';
import { DynamicListService } from '../../services/dynamic-list.service';
import { CommunicationEventService } from '../../../../app/portals/portal-shared/services/communication-event.service';

@Component({
  selector: 'fw-dynamic-list-item',
  templateUrl: './dynamic-list-item.component.html'
})
export class DynamicListItemComponent implements OnInit, OnChanges {
  @Input() col: IDynamicListDataCol;
  @Input() row: any;
  @Input() rowIndex: number;
  @Input() activeIndex: number;
  @Input() detailError: boolean = false;
  @Input() storeName: string;
  @Input() screenIsSmall: boolean;

  public displayType: string;
  public dropDownData: any;
  public dropDownLabel: string;
  public dropDownEvent: string;

  constructor(
    public  router: Router,
    public  tabsService: TabsService,
    public  modalService: ModalService,
    public  dynamicListService: DynamicListService,
    public emitterService: HomEventEmitterService,
    public communicationEventService: CommunicationEventService,
   @Inject(appConstants) public  myConstants: IAppConstants) { }

  ngOnInit() {
    this.setDisplayType();
  }

  ngOnChanges(changes: SimpleChanges) {
 //   console.log('changes: ', changes);
  }

  setDisplayType(): void {
    if (this.col.fieldDefinition.listOverrides === null ) {
      this.displayType = this.col.fieldDefinition.fieldType;
    } else {
      this.displayType = this.col.fieldDefinition.listOverrides.displayType;
      if (this.displayType === this.myConstants.listOverrideDropDown) {
        this.dropDownData = this.row[this.col.fieldDefinition.key];
        this.dropDownLabel = this.col.fieldDefinition.listOverrides.selectLabel;
        this.dropDownEvent = this.col.fieldDefinition.listOverrides.eventName || 'selectEvent';
      }
    }
  }

  openLink(): void {
    const isObject: boolean = this.dynamicListService.isObjectField(this.col.key);

    if (!isObject && !this.row.hasOwnProperty(this.col.fieldDefinition.linkSettings.portalLevel1IdKey)) return;
    if (isObject && !this.getObjectEntityId(1)) return;

    let portalId: number = isObject ? this.getObjectPortalId() : +this.col.fieldDefinition.linkSettings.portalId,
      level2EntityName: string = isObject ? this.getObjectEntityName(2) : this.col.fieldDefinition.linkSettings.portalLevel2EntityName, //equates to a c# model class
      level2KeyId: number = this.col.fieldDefinition.linkSettings.portalLevel2IdKey ? isObject ? this.getObjectEntityId(2) : this.row[this.col.fieldDefinition.linkSettings.portalLevel2IdKey] : 0,
      level3EntityName: string = isObject ? this.getObjectEntityName(3) : this.col.fieldDefinition.linkSettings.portalLevel3EntityName, //equates to a c# model class
      level3KeyId: number = this.col.fieldDefinition.linkSettings.portalLevel3IdKey ? isObject ? this.getObjectEntityId(3) : this.row[this.col.fieldDefinition.linkSettings.portalLevel3IdKey] : 0;

    const data: ITabRequest = {
      text: isObject ? this.getObjectTabLabel(1) : this.getTabLabel(this.col.fieldDefinition.linkSettings.labelKey),
      portalId: portalId,
      portalEntityId: isObject ? this.getObjectEntityId(1) : this.row[this.col.fieldDefinition.linkSettings.portalLevel1IdKey],
      level2EntityName:  level2EntityName ? level2EntityName : null,
      level2EntityId: level2EntityName ? level2KeyId : null,
      level3EntityName: level3EntityName ? level3EntityName : null,
      level3EntityId: level3EntityName ? level3KeyId : null,
      level2Text: level2EntityName ? isObject ? this.getObjectTabLabel(2) : this.getTabLabel(this.col.fieldDefinition.linkSettings.portalLevel2LabelKey) : null,
      level3Text: level3EntityName ? isObject ? this.getObjectTabLabel(3) : this.getTabLabel(this.col.fieldDefinition.linkSettings.portalLevel3LabelKey) : null,
      fromModal: this.modalService.opened
    },
      event: IHomEventEmitter = {
        requestor: 'dynamic-list-item',
        event: this.myConstants.emitterEventTabLevel1Open,
        action: '',
        data: data
      };

    //If modal is open, close modal, clear router outle
    if (this.modalService.opened) {
      this.modalService.close();
      if (this.router.url.includes('(modal:')) {
        this.router.navigate([{ outlets: { modal: null } }]);
      }

    }
    this.tabsService.emitTabEvent(event);
  };

  //most entities will be seeded with a portalId value (this.col.fieldDefinition.linkSettings.portalId will contain the portalId), not a key to the value
  getObjectPortalId(): number {
    const objName: string[] = this.col.key.split('@');
    const portalIdField: string[] = this.col.fieldDefinition.linkSettings.portalId.split('@');

    let portalId: number = portalIdField.length > 1 && this.row[objName[0]].hasOwnProperty(portalIdField[1])
      ? this.row[objName[0]][portalIdField[1]]
      : this.col.fieldDefinition.linkSettings.portalId;

    return portalId;
  }

  getTabLabel(key: string): string {
    //a lot of missing property handling here to avoid errors
    //determine if the entity label is within the main row object or within the fks property of the row object
    //fks.installer.entityLabel would be in the row and the seedData would be installer_entityLabel, as an example

    let label: string = this.row.entityLabel;
    let labelKeySplit: string[] = key.split('_');

    label = this.row.hasOwnProperty(key) ? this.row[key]
      : labelKeySplit.length === 1 ? this.row.entityLabel //not working with a fky so default to entity label
        : !this.row.hasOwnProperty('fks') ? this.row.entityLabel
          : this.row.fks.hasOwnProperty(labelKeySplit[0]) ? this.row.fks[labelKeySplit[0]].hasOwnProperty([labelKeySplit[1]])
            ? this.row.fks[labelKeySplit[0]][labelKeySplit[1]] //use fk object property
            : this.row.entityLabel
            : this.row.entityLabel;

    
    return label;
  }

  getObjectTabLabel(level: number): string {
    let label: string = null;
    let objName = this.col.key.split('@');
    let objKeyField = level === 1 ? this.col.fieldDefinition.linkSettings.labelKey.split('@') :
      level === 2 ? this.col.fieldDefinition.linkSettings.portalLevel2LabelKey.split('@') :
        level === 3 ? this.col.fieldDefinition.linkSettings.portalLevel3LabelKey.split('@') : '';
    if (!objKeyField) {
      return level === 1 ? 'Not Set' : null;
    }

    if (objName.length > 1 && this.row.hasOwnProperty(objName[0]) && objKeyField.length > 1 && this.row[objName[0]].hasOwnProperty(objKeyField[1])) {
      label = this.row[objName[0]][objKeyField[1]];
    }
    return label;
  }

  getObjectEntityName(level: number): string {
    let name: string = null;
    let objName = this.col.key.split('@');
    let objKeyField = level === 2 ? this.col.fieldDefinition.linkSettings.portalLevel2EntityName.split('@') : level === 3 ? this.col.fieldDefinition.linkSettings.portalLevel3EntityName.split('@') : '';
    if (!objKeyField) {
      return null;
    }

    if (objName.length > 1 && this.row.hasOwnProperty(objName[0]) && objKeyField.length > 1 && this.row[objName[0]].hasOwnProperty(objKeyField[1])) {
      name = this.row[objName[0]][objKeyField[1]];
    }
    return name;
    }

    getObjectEntityId(level: number): number {
      let childId = 0;

      let objName = this.col.key.split('@');
      let objKeyField = level === 1 ? this.col.fieldDefinition.linkSettings.portalLevel1IdKey.split('@') :
          level === 2 ? this.col.fieldDefinition.linkSettings.portalLevel2IdKey.split('@') :
          level === 3 ? this.col.fieldDefinition.linkSettings.portalLevel3IdKey.split('@') : '';
      if (!objKeyField) {
        return childId;
      }
      if (objName.length > 1 && this.row.hasOwnProperty(objName[0]) && objKeyField.length > 1 && this.row[objName[0]].hasOwnProperty(objKeyField[1])) {
        childId = this.row[objName[0]][objKeyField[1]];
      }

      return childId;
    }
  

  
  /* Emit a custom list event so list event knows to roll up to parent component */
   onDecimalChange(value: any): void {
      let event: IHomEventEmitter = {
        requestor: 'dynamic-list-item',
        event: this.col.fieldDefinition.listOverrides && this.col.fieldDefinition.listOverrides.eventName ? this.col.fieldDefinition.listOverrides.eventName : 'valueChange',
        action: '',
        data: { row: this.row, value: value }
      };
      this.emitterService.emitListCustomEvent(event);
   }

 }
