/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter, IScheduleRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { IWorkOrderViewModel} from '../../../view-models';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { SetEventList } from '../../../../../fw/dynamic-list/store/index';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { UserDashboardService } from '../../services/user-dashboard.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { UserDashEvent } from '../../enums/user-dashboard.enums';
import { ProjectListStore, ProjectEvent } from '../../../project/enums/project.enums';

@Component({
  selector: 'unscheduled-work-orders',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './unscheduled-work-orders.component.html'
})
export class UnscheduledWorkOrdersComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public modalService: ModalService,
    public userDashboardService: UserDashboardService,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case UserDashEvent.scheduleWorkOrder:
        this.showScheduler(event.data);
        break;
      case ProjectEvent.logCustomerAction:
        this.logCustomerAction(event.data);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.userDashboardService.loadUnscheduledWorkOrdersListDefinition());
    });

    this.subscription.add(this.emitterService.callLogEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e.event === this.myConstants.emitterEventCallLogged) {
          const emitter: IHomEventEmitter = { requestor: 'unscheduled-work-orders', event: this.myConstants.emitterEventListReload, action: '', data: null };
          this.store.dispatch(new SetEventList({
            storeName: this.listDefinition$.value.storeName,
            parentId: this.listDefinition$.value.parentId,
            event: emitter
          }));

        }
      }));
  }

  logCustomerAction(data: IWorkOrderViewModel): void {
   const woId: number = data && data.hasOwnProperty('workOrderId') ? data['workOrderId'] : 0;
    if (woId === 0) {
      console.log('DEV ERROR:  missing wo id on event', event);
      return;
    }

    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.workOrderCallLogResponses,
      key: 'project_call_log_response_id',
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.projectId
    } 
    this.modalService.open({
      title: 'Work Order Customer Action Call Log',
      path: 'user-dash-work-order-call-log',
      id: data.workOrderId,
      optionalParams: params,
      onExit: null,
      castExit: false,
      hasTabs: false
    });
  }

  showScheduler(data: IWorkOrderViewModel) {
    const workOrderId: number = data && data.hasOwnProperty('workOrderId') ? data['workOrderId'] : 0;
    if (workOrderId === 0) {
      console.log('DEV ERROR:  missing wo id on event', event);
      return;
    }

    let params: IScheduleRouteParameter = {
      scheduleId: 0,
      operation: this.myConstants.operationTypeCreate,
      projectId: data.projectId,
      storeName: this.listDefinition$.getValue().storeName,
      parentId: this.listDefinition$.getValue().parentId
    }
    this.modalService.open({
      title: 'Work Order Schedule',
      path: 'user-dash-scheduler',
      id: workOrderId,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.xlarge,
      optionalParams: params
    });

  }
}
