import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppAction } from '../../enums/app-action.enums';

@Component({
  selector: 'app-action-result',
  templateUrl: './app-action-result.component.html'
})
export class AppActionResultComponent implements OnInit {

  public actionText: string;
  public actionTextLine2: string;
  public actionIcon: string;
  public actionIconColor: string;

  actionIn: string;
  textIn: string;

  constructor( public activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.actionIn = paramMap.get('type');
      this.textIn = paramMap.get('message');
      this.actionTextLine2 = '';

      switch (this.actionIn) {
        case AppAction.error:
          this.actionText = this.textIn ? this.textIn : 'Oops! An error occurred';
          this.actionIcon = 'fa-exclamation-circle';
          this.actionIconColor = 'app-icon--error';
          break;
        case AppAction.accessError:
          this.actionText = 'Unable to access '.concat(this.textIn ? this.textIn : 'entity', '.');
          this.actionTextLine2 = 'Please verify access settings.';
          this.actionIcon = 'fa-exclamation-circle';
          this.actionIconColor = 'app-icon--error';
          break;
        case AppAction.warning:
          this.actionText = this.textIn ? this.textIn : 'Warning!';
          this.actionIcon = 'fa-exclamation-triangle';
          this.actionIconColor = 'app-icon--warning';
          break;
        case AppAction.info:
          this.actionText = this.textIn ? this.textIn : 'For your information';
          this.actionIcon = 'fa-info-circle';
          this.actionIconColor = 'app-icon--info';
          break;
        case AppAction.success:
          this.actionText = this.textIn ? this.textIn : 'Your update was successful!';
          this.actionIcon = ' fa-check-circle';
          this.actionIconColor = 'app-icon--success';
          break;
        case AppAction.uploadFailed:
          this.actionText = this.textIn ? this.textIn : 'File upload failed!';
          this.actionIcon = 'fa-exclamation-circle';
          this.actionIconColor = 'app-icon--error';
          break;
        case AppAction.versionMismatch:
          this.actionText = 'Your version of the application is outdated.  The application will be refreshed.';
          this.actionIcon = 'fa-exclamation-circle';
          this.actionIconColor = 'app-icon--error';
          break;
        default:
          break;
      }
    });
  }

}
