import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { FwSharedModule } from '../../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../../shared/shared.module';
import { DynamicListModule } from '../../../../fw/dynamic-list/dynamic-list.module';
import { DynamicFormModule } from '../../../../fw/dynamic-forms/dynamic-form.module';
import { DynamicDetailModule } from '../../../../fw/dynamic-detail/dynamic-detail.module';


//containers
import { UserPendingChangesComponent } from './index';


//guards
import { IsCurrentGuard } from '../../../store/services/is-current-guard.service';
import { AQTChangeComponent } from '../../portal-shared/components';

export const ROUTES = [
  {
    path: 'user-pending-changes',
    outlet: 'modal',
    component: UserPendingChangesComponent,
    children: [
      {
        path: 'user-pending-change/:id',
        outlet: 'commonDetailOutlet',  //for dynamic-list, must add to navigateDetail in  dynamic-list.component.ts
        component: AQTChangeComponent,
        canActivate: [IsCurrentGuard]
      }
    ],
      },
];


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FwSharedModule,
    SharedModule,
    DynamicListModule,
    DynamicFormModule,
    DynamicDetailModule,
    RouterModule.forChild(ROUTES)
  ],
  declarations: [
    UserPendingChangesComponent
  ],
  providers: [ IsCurrentGuard],
})
export class UserPendingChangesModule { }
