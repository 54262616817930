import { Injectable, OnDestroy} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable,  Subscription } from 'rxjs';
import { take, map, filter, first } from 'rxjs/operators';

//store actions and reducers
import * as fromStore from '../../../fw/dynamic-list/store/index';
import * as DynamicListActions from '../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { IListDefinition, ListFilter } from '../../../fw/dynamic-list/interfaces';
import { listDataExists, getListByType } from '../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import {  IDynamicListState } from '../../../fw/dynamic-list/store';
import { DynamicListService } from '../../../fw/dynamic-list/services';

@Injectable()
export class EntityResolver  implements OnDestroy {
  //ONLY RETURNS A SUBSET OF ENTITIES:  unique loads to a list, not selection list
  storeName: string = 'entities';
  listDefinition: IListDefinition;
  dispatched: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public dynamicListService: DynamicListService  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>{
    this.listDefinition = this.loadListDef();

    this.requestData();

    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<boolean> {
    return this.store.pipe(select(getListByType(this.storeName)))
      .pipe(
        filter((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == -1) !== undefined && listsState.objData[0].data !== null),
        map((state) => {
          if (state && state.objData && state.objData.length > 0) {
            return true;
          }
        }),first()
      );
  }

  requestData(): void {
     this.subscription.add(this.store.select(listDataExists(this.storeName, -1))
      .pipe(filter((exists: boolean) => exists === false), take(1))
       .subscribe((exists) => {
         this.store.dispatch(new DynamicListActions.SetListDefinition({ storeName: 'entities', parentId: -1, listDefinition: this.listDefinition }));
         this.store.dispatch(new DynamicListActions.GetList({ listDefinition: this.listDefinition, listFilter: this.listDefinition.defaultListFilter, parentId: this.listDefinition.parentId }))
       }));
  }

  loadListDef() {
    const listColumns = null
    const listObjectLabel = 'Entities';
    const listObjectController = 'Entity';
    const listStoreName = this.storeName;
    const listRowKeyId = 'entityId';
    let defaultListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.isLookup = true;

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      '');

    listDefinition.controllerMethod = 'ProjectEntities';
    listDefinition.parentId = -1;

    //no crud buttons
    return listDefinition;  
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
