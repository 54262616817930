
export interface IPurchaseOrderInventoryAllocation {
  purchaseOrderInventoryAllocationId: number;   
  purchaseOrder_purchaseOrderId: number;
  purchaseOrderItem_purchaseOrderItemId: number;
  inventoryId: number;
  isAllocated: boolean;
  materialName: string;
  vendorPoNumber: string;
  units: number;
  manufacturerName: string;
  estimatedDeliveryDate: string;
  receiveDate: string;
  flexField1: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
 

  //extra data
  customerName: string;
  purchaseOrderNumber: number;
  purchaseOrderId: number;
  projectId: number;
  measureDescription: string;
  sssStatus: string;
}


export class PurchaseOrderInventoryAllocation implements IPurchaseOrderInventoryAllocation {
  purchaseOrderInventoryAllocationId: number;
  purchaseOrder_purchaseOrderId: number;
  purchaseOrderItem_purchaseOrderItemId: number;
  inventoryId: number;
  isAllocated: boolean;
  materialName: string;
  vendorPoNumber: string;
  units: number;
  manufacturerName: string;
  estimatedDeliveryDate: string;
  receiveDate: string;
  flexField1: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;


  //extra data
  customerName: string;
  purchaseOrderNumber: number;
  purchaseOrderId: number;
  projectId: number;
  measureDescription: string;
  sssStatus: string;

  constructor() {
    this.purchaseOrderInventoryAllocationId = 0;
    this.purchaseOrder_purchaseOrderId = 0;
    this.purchaseOrderItem_purchaseOrderItemId = 0;
    this.inventoryId = 0;
    this.isAllocated = false;
    this.materialName = '';
    this.vendorPoNumber = '';
    this.units = 0;
    this.manufacturerName = '';
    this.estimatedDeliveryDate = '';
    this.receiveDate = '';
    this.flexField1 = '';

    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';

  }

}

