import { createSelector} from '@ngrx/store';
  
import * as fromFeature from '../reducers/feature.reducer';

export const getAllMetaData = createSelector(
  fromFeature.getAllDynamicData,
  allStores  => allStores.metaData
);

export const getMetaDataByType = (name: string) => createSelector(
  getAllMetaData,
  allData  => allData[name]
);


export const metaDataExists = (name: string) => createSelector(
  getMetaDataByType(name),
  data => {
    return data && data.fieldDefinitions && data.fieldDefinitions.length > 0 ? true : false;
  }
);

export const metaDataExistsForAll = (names: string[]) => createSelector(
  getAllMetaData,
  allStores => {
    let exists: boolean = true;
    names.forEach(name => {
      if (allStores[name].fieldDefinitions.length === 0) {
        exists = false;
      }
    });
    return exists;
  }
);


