import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { IPageMetaData } from '../../../../shared/interfaces/metadata/i-page-metadata';

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html'
})
export class Pagination implements OnInit, OnChanges {
  @Input() restrict: boolean;
  @Input() config: IPageMetaData;
  @Output() action: any = new EventEmitter();

  currentPage: number = 1;
  pages: [] | any;

  ngOnInit(): void {
    this.setPages();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['config'] && !changes['config'].firstChange) {
      this.setPages();
    }
  }

  setPages(): void {
    const len: number = Math.ceil(this.config.totalItems / this.config.itemsPerPage);
    let i: number = 0;
    this.pages = [];
    this.currentPage = this.config.currentPage;
    for (; i < len; i++) this.pages.push(i+1);
  }

  getPage(page: number): void {
    if (!this.restrict && page > 0 && page <= this.pages.length) {
      this.currentPage = Number(page);
      this.action.emit(this.currentPage);
    }
  }
}
