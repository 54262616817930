<div [ngClass]="{ 'loading-mask':  (working$ | async)}">
    <div *ngIf="(listDefinition$ | async)">
      <fw-dynamic-list [vmListDefinition]="listDefinition$ | async"
                       (selectEvent)="onProjectSelect($event)"
                       (pageEvent)="onPage($event)">
      </fw-dynamic-list>
      <div class="app-btn-box">
        <button class="app-btn app-btn--primary"
                type="button"
                (click)="confirmSelect()"
                [disabled]="!selectedProject">
          Select
        </button>
      </div>
    </div>
</div>
