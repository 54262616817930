import { Component, Input } from '@angular/core';
import { InstallerCertificationViewModel } from '../../../../app/portals/view-models';

@Component({
  selector: 'installer-certifications-expiration-date',
  templateUrl: './installer-certifications-expiration-date.component.html'
})
export class InstallerCertificationsExpirationDateComponent {
  @Input() row: InstallerCertificationViewModel;
}
