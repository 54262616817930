//also referred to as filter
export interface IPrivilegeTemplatePrivilegeRuleValue {
  privilegeTemplatePrivilegeRuleValueId: number;
  privilegeTemplatePrivilege_privilegeTemplatePrivilegeId: number;
  privilegeRuleId: string;
  ruleValue: string;
}

export class PrivilegeTemplatePrivilegeRuleValue implements IPrivilegeTemplatePrivilegeRuleValue {
  privilegeTemplatePrivilegeRuleValueId: number;
  privilegeTemplatePrivilege_privilegeTemplatePrivilegeId: number;
  privilegeRuleId: string;
  ruleValue: string;

  constructor() { }

}
