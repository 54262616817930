import { Component, OnInit, OnDestroy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { UpdateObjectCustomModel, IKey, CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { ISssProgramService, SssProgramService, ISssGeneralContractorProgram, ISssGeneralContractor } from '../../../view-models/index_two';
import { IListFilter, ListFilter, OrderTerm } from '../../../../../fw/dynamic-list/interfaces';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { IGeneralContractor, IProgramService } from '../../../view-models';

//store actions, reducers, interfaces
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services';
import { DomainObjectService } from '../../../../../shared/services';

@Component({
  selector: 'sss-program-service',
  templateUrl: './sss-program-service.component.html',
  providers: [MetaDataService]
})
export class SSSProgramServiceComponent implements OnInit, OnDestroy  {
  public objectData: ISssProgramService;
  public displayFields = ['generalContractorName', 'excludeAllBranchPrograms', 'dateRange', 'allowSlotBufferReservations', 'etaBuffer', 'minimumDuration'];
  public generalContractors: IGeneralContractor[] = [];
  public sssPrograms$: BehaviorSubject<ISssGeneralContractorProgram[]> = new BehaviorSubject(null);
  public services$: BehaviorSubject<IProgramService[]> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hasEntity$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public form: FormGroup;
  storeName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string;
  detailRoutePath: string = '';
  selectedGcProgramId: number = 0;
  selectedProgramServiceId: number = 0;
  
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public dos: DomainObjectService,
    public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants  ) {}

  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.setDetailConfig(paramMap);
      this.getDetail();
      this.newRequest();
    }));
  }

  public changeProgram(programId: number): void {
    const selectedPgm: ISssGeneralContractorProgram = this.sssPrograms$.value.find(x => x.program_programId == programId);
    if (selectedPgm) {
      this.selectedGcProgramId = selectedPgm.sssGeneralContractorProgramId;
      this.selectedProgramServiceId = 0;
      if (this.selectedGcProgramId > 0) {
        this.getProgramServices(programId);
      }
    }
  }

  public changeProgramService(val: number): void {
    this.selectedProgramServiceId = val || 0;
    this.form.markAsDirty();
  }

  public onCancel() {
    const emitter: IHomEventEmitter = { requestor: 'sss-program-service', event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public onCreate() {
    if (this.form.valid) {
      this.createRecord();
    }
  }

  public onSave(action: string) {
    if (this.form.valid) {
      this.updateRecord(action);
    }
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  getDetail(): void {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.storeName, -1, this.key, this.objectId)))
      .subscribe(entity => {
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate
          ? new SssProgramService() : entity;
        this.selectedGcProgramId = this.objectData.sssGeneralContractorProgram_sssGeneralContractorProgramId;
        this.selectedProgramServiceId = this.objectData.programService_programServiceId || 0;
        this.hasEntity$.next(true);
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.storeName, -1)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.detailRoutePath = listDefinition.detailRoutePath;
        this.controllerName = listDefinition.controllerName;
      }
    ));
  }

  newRequest(): void {
    this.mds.setFieldDefinitions(this.storeName);
    this.mds.loadSelectionLists(this.displayFields, this.operation, this.objectData.sssProgramServiceId);

    this.subscription.add(this.mds.mdsReady$.pipe(filter(flag => flag === true), take(1))
      .subscribe((ready: boolean) => {
        this.loadForm();
        this.generalContractors = this.mds.getSelectItems('generalContractorName');
    }));

    this.subscription.add(this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null))
      .subscribe((obj: IValueChanged) => {
        if (this.form.dirty) {
          if (obj.key === 'generalContractorName') {
            const gc: ISssGeneralContractor = obj.value as ISssGeneralContractor;
            this.selectedGcProgramId = 0;
            this.selectedProgramServiceId = 0;
            this.services$.next([]);
            this.getSssGcPrograms(gc.sssGeneralContractorId);
          } 
        }
      }));

    this.subscription.add(this.hasEntity$.pipe(filter(flag => flag === true), take(1))
      .subscribe((ready: boolean) => {
        if (this.operation === this.myConstants.operationTypeCreate || !this.objectData.generalContractorId) {
          this.sssPrograms$.next([]);
        } else {
          this.getSssGcPrograms(this.objectData.sssGeneralContractorId);
        }
        if (this.operation === this.myConstants.operationTypeCreate || !this.objectData.programId) {
          this.services$.next([]);
        } else {
          this.getProgramServices(this.objectData.programId);
        }
      }));
  }

  loadForm(): void {
    const obj = Object.assign({}, this.objectData);
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, obj, this.operation);
  }

  createRecord() {
    let rec: ISssProgramService = this.setCommonProperties();
    const emitter: IHomEventEmitter = { requestor: 'sss-program-service', event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, -1, this.controllerName, 'Create', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  updateRecord(action: string) {
    let rec: ISssProgramService = this.setCommonProperties();
    const keyData: IKey = { storeName: this.storeName, parentId: -1, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: 'sss-program-service', event: this.myConstants.emitterEventUpdate, action: action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  setCommonProperties(): ISssProgramService {
    const formData: ISssProgramService = this.form.getRawValue();
    let rec = cloneDeep(this.objectData);
    rec.sssGeneralContractorProgram_sssGeneralContractorProgramId = this.selectedGcProgramId;
    rec.programService_programServiceId = this.selectedProgramServiceId;
    rec.excludeAllBranchPrograms = formData.excludeAllBranchPrograms;
    rec.dateRange = formData.dateRange;
    rec.allowSlotBufferReservations = formData.allowSlotBufferReservations;
    rec.etaBuffer = formData.etaBuffer;
    rec.minimumDuration = formData.minimumDuration;

    return rec;
  }

  getSssGcPrograms(id: number): void {
    this.working$.next(true);
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.orderTerm = [new OrderTerm('program_programName')]
    this.subscription.add(this.dos.getByMethodById('SssGeneralContractorProgram', 'BySssGeneralContractor', id, listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.sssPrograms$.next(response.data);
        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => {
          this.errors$.next(error);
        }
      ));
  }

  getProgramServices(id: number): void {
    this.working$.next(true);
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.orderTerm = [new OrderTerm('service_serviceName')]
    this.subscription.add(this.dos.getByMethodById('ProgramService', 'ByProgram', id, listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.services$.next(response.data);
        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => {
          this.errors$.next(error);
        }
      ));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
