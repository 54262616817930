import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IPayout, Payout } from '../../../view-models';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { ILookupData } from '../../../../../shared/interfaces';
import { IPricingCreateResult } from '../../interfaces/i-pricing-create-result';
import { SkuCreationWizardEvent, SkuCreationWizardAction } from '../../enums/pricing-shared.enums';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import {  getEntityListByParentId } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import { CreateObjectModel, IKey, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IListObjectData, ClearEventList, CreateObjectList, InitializeListData, UpdateObjectCustomList } from '../../../../../fw/dynamic-list/store';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { PricingSharedService } from '../../services/pricing-shared.service';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'payout',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './payout.component.html'
})
export class PayoutComponent implements OnInit, OnDestroy {
  public objectData$: BehaviorSubject<IPayout> = new BehaviorSubject(null);
  public displayFields: string[] = ['payoutName', 'payoutStatus', 'payoutDescription', 'controlsCapacity', 'unitMeasure',  'glCodeOverride', 'serviceCodeOverride'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  subscription = new Subscription();
  storeName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  detailRoutePath: string = '';
  skuId: number;
  gcId: number;
  inWizard: boolean = false; 
  actionOnSuccess: string;
  defaultStatus: ILookupData;
  paramMap: ParamMap;
  newPayoutName: string;

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public pricingService: PricingSharedService,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public rootStore: Store<fromRoot.IState>,
    public userPriviledgesService: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.paramMap = paramMap;
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.skuId = +paramMap.get('skuId');
      this.gcId = +paramMap.get('gcId');
      this.inWizard = paramMap.get('wiz') ? true : false;
      if (this.objectId === 0) {
        this.getDefaultStatus();
      }
      this.getDetail();
      if (!this.inWizard) {
        this.setDetailConfig();
      }
    });

    //Wizard needs this
    this.subscription.add(this.objectData$.pipe(
      filter((data) => data !== null), take(1))
      .subscribe((data: IPayout) => {
        if (this.inWizard) {
          this.setDetailConfig();
        }
      }));

    this.subscription.add(this.emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventClose:
            if (this.inWizard) {
              e.event = SkuCreationWizardEvent.cancel;
              this.emitterService.emitSkuWizardEvent(e);
            }
            break;
          case SkuCreationWizardEvent.createPayout:
          case this.myConstants.emitterEventCreate:
            this.actionOnSuccess = e.action;
            this.createRecord(e);
            break;
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          default:
            break;
        }
      }));

      if (this.inWizard) {
        this.listenForUpdateSuccess();
      }
    }

  setDetailConfig(): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(this.paramMap);
    params.parentId = -1;
   if (this.inWizard) {
      params.customButtons = [
        { title: 'Create and Close', css: 'app-btn--primary', event: SkuCreationWizardEvent.createPayout, action: SkuCreationWizardAction.close },
        { title: 'Create and Next', css: 'app-btn--primary', event: SkuCreationWizardEvent.createPayout, action: SkuCreationWizardAction.next }
      ];
    }
    this.detailConfig$.next(params);
  }

  getDefaultStatus(): void {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('payoutStatus')))
      .subscribe((data: ILookupData[]) => {
        this.defaultStatus = data ? data.find(x => x.lookupMeaning === 'Current') : null;
      }));
  }

  getDetail() {
      this.subscription.add(this.store.pipe(
        select(fromDynamicList.getSelectedRecord(this.storeName, -1, this.key, this.objectId)))
        .subscribe(entity => {
          this.objectData$.next(!entity && this.operation === this.myConstants.operationTypeCreate
            ? new Payout(this.userPriviledgesService.providerId$.value, this.defaultStatus ? this.defaultStatus.lookupValueId : -1)
            : cloneDeep(entity));
        })
      );
  }

  /*
    Insert the new payout and associate it to the sku
*/
  createRecord(e: IHomEventEmitter) {
    let payout = this.setCommonProperties(e.data);
    payout.provider_providerId = this.userPriviledgesService.providerId$.value;
    const obj = this.inWizard ? { model: payout, skuId: this.skuId } : { model: payout };
    const method = this.inWizard ? 'CreateForSku' : 'Create';
    const createData = new CreateObjectModel(this.storeName, -1, 'Payout', method, obj, null, e);
    this.store.dispatch(new CreateObjectList({ createData }));
  }

  updateRecord(e: IHomEventEmitter) {
    let rec: IPayout = this.setCommonProperties(e.data);

    const keyData: IKey = { storeName: this.storeName, parentId: -1, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, 'Payout', 'Update', rec, null, emitter);
    this.store.dispatch(new UpdateObjectCustomList({ updateData }));
  }

  /*
      Set common create/update properties
  */
  setCommonProperties(formData: IPayout): IPayout {
    let data = cloneDeep(this.objectData$.value);
    this.newPayoutName = formData.payoutName; //for wizard
    data.payoutName = formData.payoutName;
    data.payoutDescription = formData.payoutDescription;
    if (formData.hasOwnProperty('payoutStatus')) {
      data.payoutStatus_lookupValueId = formData['payoutStatus'].lookupValueId;
    }
    data.unitMeasure_unitMeasureId = formData['unitMeasure']['unitMeasureId'];
    data.controlsCapacity = formData.controlsCapacity;
    data.glCodeOverride = formData.glCodeOverride;
    data.serviceCodeOverride = formData.serviceCodeOverride;
    return data;
  }

  listenForUpdateSuccess() {
    this.subscription.add(this.store.pipe(select(getEntityListByParentId(this.storeName, -1)))
      .subscribe((state: IListObjectData) => {
        if (state && state.data && !state.working && !state.errorData.length && state.event && this.inWizard) {
          const event: IHomEventEmitter = cloneDeep(state.event);
          if (event.event !== this.myConstants.emitterEventListReload) {
            //emit event to container
            const data: IPayout[] = cloneDeep(state.data);
            let result: IPricingCreateResult = { parentId: 0, parentName: '' };
            //get payout id from payout record
            const payout = data.find(x => x.payoutName == this.newPayoutName);
            result.parentId = payout ? payout.payoutId : 0;
            result.parentName = payout ? payout.payoutName : '';
            event.event = SkuCreationWizardEvent.payoutCreated;
            event.data = result;
            this.emitterService.emitSkuWizardEvent(event);
            this.store.dispatch(new ClearEventList({ storeName: this.storeName, parentId: -1 }));
          }
        }    
      }));
  }

  ngOnDestroy(): void {
    if (this.inWizard) {
      this.store.dispatch(new InitializeListData({ storeName: this.storeName, parentId: -1 }));
    }
   this.subscription.unsubscribe();
  }
}
