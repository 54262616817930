<fw-component-title [title]="'Enter ' + generalContractorName + ' Purchase Order Numbers'"></fw-component-title>
<br />
<br />
<div class="float-label">
  <input #poNumbers type="text"
         class="number-width-wide" />
  <label title="Enter comma delimited purchase order numbers">Enter up to 10 comma delimited purchase order numbers</label>
</div>

<div class="app-btn-box">
  <button type="button"
          class="app-btn app-btn--primary"
          [disabled]="!poNumbers.value"
          (click)="onDownload(poNumbers.value)">
    Download and Import
  </button>
  <button type="button" class="app-btn app-btn--cancel" (click)="onCancel()">Cancel</button>
</div>
<fw-error-box [errors]="errorMessage"></fw-error-box>
