
export interface IJobSchedule
{
  jobScheduleId: number;
  jobScheduleName: string;
  isActive: boolean;
  providerUser_providerUserId: number;
  allDayEvent: boolean;
  jobScheduleType: string; //enum
  jobScheduleRecurrenceType: string; //enum
  jobFrequencyType: string; //enum
  jobFrequencyRecurUnit1: number;
  jobFrequencyRecurUnit2: number;
  jobFrequencyRecurUnit3: number;
  dailyFrequencyTime: string;
  dailyFrequencyUnit: number;  
  dailyFrequencyStartTime: string;
  dailyFrequencyEndTime: string;
  jobStartDate: string;
  jobEndDate: string;
  jobScheduleWeekdays: string[];
  dailyFrequency: string; //extra data
}

export class JobSchedule implements IJobSchedule {

  constructor(
    public jobScheduleId: number = 0,
    public jobScheduleName: string = '',
    public providerUser_providerUserId: number = 0,
    public isActive: boolean = false,
    public allDayEvent: boolean = false,
    public jobScheduleType: string = '',
    public jobScheduleRecurrenceType: string = '',
    public jobFrequencyType: string = '',
    public jobFrequencyRecurUnit1: number = 0,
    public jobFrequencyRecurUnit2: number = 0,
    public jobFrequencyRecurUnit3: number = 0,
    public dailyFrequencyTime: string = '',
    public dailyFrequencyUnit: number = 0,
    public dailyFrequencyStartTime: string = '',
    public dailyFrequencyEndTime: string = '',
    public jobStartDate: string = '',
    public jobEndDate: string = '',
    public jobScheduleWeekdays: string[] = [],
    public dailyFrequency: string = '' //extra data
  ) { }
}
