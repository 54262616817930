<div class="task-action-template">
  <form *ngIf="form && canBypass" [formGroup]="form" novalidate class="form  task-action-template__form">
    <fw-dynamic-field [field]="metaDataService.getFieldDefinition('bypassExternalPriceCheck')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
  </form>
  <div class="task-action-template__btn">
    <button type="button"
            class="app-btn app-btn--white "
            [disabled]="!canIEdit || !form && !form.valid"
            (click)="completeAction()">
      {{projectTaskAction.taskActionName}}
    </button>
  </div>
</div>
