/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { take, filter } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import * as fromRoot from '../../../../store/reducers/index';
import * as  fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';

import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { DomainObjectService } from '../../../../../shared/services/domain-object.service';
import { WarrantyDashboardService } from '../../services/warranty-dashboard.service';
import { ProviderUserService } from '../../../portal-shared/services';

import { IProviderUser } from '../../../view-models';
import { IKey, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { WarrantyDashStore } from '../../enums/warranty-dashboard.enums';
import { IErrorData } from '../../../../../shared/interfaces';
import { IAllDynamicData } from '../../../../../fw/dynamic-list/store';

@Component({
  selector: '/warranty-assign-user',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './warranty-assign-user.component.html'
})
export class WarrantyAssignUserComponent implements OnInit {
  public assignedId: number;
  public providerUsers$: BehaviorSubject<IProviderUser[]> = new BehaviorSubject(null);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public errorData$: BehaviorSubject<IErrorData[]>;
  warrantyId: number;
  projectId: number;
  subscription: Subscription = new Subscription();

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<IAllDynamicData>,
    public modalService: ModalService,
    public dos: DomainObjectService,
    public activeRoute: ActivatedRoute,
    public warrantyDashboardService: WarrantyDashboardService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public providerUserService: ProviderUserService) { }

  public onSave(): void {
    const formData: FormData = new FormData();
    formData.append('warrantyId', this.warrantyId.toString());
    formData.append('assignedUserId', this.assignedId.toString());
    this.loading$.next(true);
    const keyData: IKey = { storeName: WarrantyDashStore.dashUnassignedWarranties, parentId: -1, key: 'projectId', id: this.projectId, skipDataUpdate: true };
    const emitter: IHomEventEmitter = { requestor: 'warranty-assign-user', event: this.myConstants.emitterEventListReload, action: '', data: null };
    const updateData = new UpdateObjectCustomModel(keyData, 'Warranty', 'AssignUser', { 'warrantyId': this.warrantyId, 'assignedUserId': this.assignedId }, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData })); 
  }

  public selectUser(val: any): void {
    this.assignedId = Number(val);
  }

  public onCancel(): void {
    this.modalService.close();
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get('portalEntityId');
      this.warrantyId = +paramMap.get('id');
    });
    this.getProviderUsers();
    this.subscription.add(this.store.pipe(select(fromStore.getListByType(WarrantyDashStore.dashUnassignedWarranties)))
      .subscribe((data) => {
        if (data.objData && data.objData.length && data.objData[0].event) {
          if (data.objData[0].event.event == this.myConstants.emitterEventListReload && data.objData[0].errorData !== "" && !data.objData[0].errorData.length) {
            this.loading$.next(false);
            this.modalService.close();
          } else {
            if (data.objData[0].errorData.length) this.errorData$.next(data.objData[0].errorData);
          }
        }
      }));
  }

  getProviderUsers(): void {
    this.subscription.add(this.rootStore.select(fromSelectionLists.listExistsByType(this.providerUserService.storeName))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe((exists) => {
        this.rootStore.dispatch(new fromSelectionLists.GetEntityList('ProviderUser', this.providerUserService.defaultListFilter));
      }));

    //listen for provider users
    this.subscription.add(this.rootStore.select(fromSelectionLists.getSelectionListDataByType(this.providerUserService.storeName))
      .pipe(filter((data: IProviderUser[]) => data !== null && data.length > 0), take(1))
      .subscribe((data) => {
        this.providerUsers$.next(data);
      }));
  }

}
