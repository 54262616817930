<div *ngIf="listDefinition$ | async" [ngClass]="{ 'loading-mask': (working$ | async)}">
  <fw-dynamic-list [vmListDefinition]="listDefinition$ | async"
                   (selectEvent)="onSelect($event)"
                   (filterEvent)="onFilter($event)"
                   (pageEvent)="onListPage($event)">
  </fw-dynamic-list>
  <div class="app-btn-box">
    <button type="button"
            class="app-btn app-btn--cancel"
            (click)="cancel()"
            title="Cancel">
      Cancel
    </button>
    <button class="app-btn app-btn--primary"
            type="button"
            (click)="linkItems(close)"
            [disabled]="!selectedPayouts.length && !selectedSkus.length">
      Link and Close
    </button>
  </div>
  <fw-error-box [errorData]="errorData$ | async" [errors]="errorMessage$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>

