/*Used for both installer list on compliance and technician list on workcrew manager - sublist */
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, OrderTerm } from '../../../../../fw/dynamic-list/interfaces/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'installer-technician-with-certification-range',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './installer-technician-with-certification-range.component.html'
})
export class InstallerTechnicianWithCertificationRangeComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  parentId: number = -1;
  objectId: number = 0;
  myObjectName: string = '';
  myStoreName: string = '';
  activeIndex: number = -1;
  key: string = ''; //certificationId, gcInstallerRequirementId, etc.
  operation: string;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public dynamicDetailService: DynamicDetailService,
    public dynamicListService: DynamicListService) {}

  ngOnInit() {
    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      if (paramMap.has('id')) {
        this.parentId = +paramMap.get('id');
      }
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      //get the installer id, use the installer id to define the list
      this.activeIndex = +paramMap.get('rowIndex');
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.setDetailConfig(paramMap);
      this.loadListDefinition();  //this detail page is a sublist
    });
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId =this.parentId;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.showCancel = true;
    this.detailConfig$.next(params);
  }

  /*
      Define the list to be created
  */
  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Certifications With Range';
    const listObjectController = 'InstallerCertificationViewModel';
    const listStoreName = this.key=== 'installerId' ? 'installerCertificationsByRange' : 'technicianCertificationsByRange';
    const detailRoutePath = '';
    const listRowKeyId = 'installerCertificationId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('expirationDate')];
    defaultListFilter.getAll = true;

    //no detail from this list
    let listDefinition = this.dynamicListService.createListDefinition(null,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = this.key === 'installerId' ? -1 : this.parentId;
    listDefinition.parentKey = this.key;
    listDefinition.controllerMethod = this.key === 'installerId' ? 'GetAllInstallerCertificationsByDateRange' : 'GetAllTechnicianCertificationsByDateRange';
    listDefinition.methodParameters = this.objectId.toString();
    listDefinition.methodParameters += '?dateRange=30'; //TODO:  hard-coded - not a dateRange but #days to check to find expiring certs

    //no crud buttons
    listDefinition.showFilter = false;
    listDefinition.revokeSort = true;
    listDefinition.itemsPerPage = 30;

    this.listDefinition$.next(listDefinition);
  }

  /*
      Define the columns in the list to be created.
  */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('providerName'); 
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

    colDef =  new ListColumn('certificationName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isRequired');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('certificationLevel');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('expirationDate');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  onClose() {

  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
