import { Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';
import { IProjectTaskActionViewModel } from '../../../view-models';
import { IHomDictionary } from '../../../../../shared/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { MetaDataService } from '../../../../../fw/dynamic-list/services';
import { FormGroup } from '@angular/forms';
import { TaskManagerService, ITaskComplete } from '../../services/task-manager.service';
import { UserPriviledgesService } from '../../../../../auth/services';

interface ITaskActionKeyRec {
  bypassExternalPriceCheck: boolean;
}
@Component({
  selector: 'task-action-key-rec',
  templateUrl: './task-action-key-rec.component.html',
  providers: [MetaDataService]
})
export class TaskActionKeyRecComponent implements OnInit  {
  @Input() projectTaskId: number;
  @Input() projectTaskAction: IProjectTaskActionViewModel;
  @Input() operation: string;
  @Input() canIEdit: boolean;
  @Output() public completeEvent = new EventEmitter<ITaskComplete>();

  public displayFields: string[] = ['bypassExternalPriceCheck'];
  public form: FormGroup;
  public canBypass: boolean = false;

  constructor(public  metaDataService: MetaDataService,
    public  taskManagerService: TaskManagerService,
    public  userPriviledgesService: UserPriviledgesService,
    @Inject(appConstants) public  myConstants: IAppConstants) { }

  public completeAction(): void {
    const val: ITaskActionKeyRec = this.form.getRawValue();
    let dictionary: IHomDictionary[] = [
      { key: 'bypassExternalPriceCheck', value: this.canBypass ? val.bypassExternalPriceCheck : false }
    ];
    const taskComplete: ITaskComplete = { projectTaskId: this.projectTaskId, projectTaskActionId: this.projectTaskAction.projectTaskActionId, data: dictionary }
    this.completeEvent.emit(taskComplete);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.metaDataService.setFieldDefinitionsManually(this.taskManagerService.fieldDefinitions, {});
    this.canBypass = this.userPriviledgesService.canBypassExternalPriceCheck$.getValue();
    let obj: ITaskActionKeyRec = { 'bypassExternalPriceCheck': false };
    this.form = this.metaDataService.loadDynamicFormGroup(this.displayFields, obj, this.operation);
  }
}
