import { ErrorHandler, Injectable } from '@angular/core';
import { HomErrorLevel } from './enums/hom-error-level';
import { HomDataAccessService } from 'hom-lib/hom-data-access';
import { IHomEventEmitter, HomEventEmitterService } from 'hom-lib/hom-event-emitter';
import { HomErrorEvent } from './hom-error-event.enums';
import { IApplicationClientError } from './i-application-client-error';

@Injectable({
  providedIn: 'root'
})
export class HomErrorHandlerService implements ErrorHandler {

  constructor(private dos: HomDataAccessService,
    public emitterService: HomEventEmitterService) { }

  handleError(error: Error, errorLevel: HomErrorLevel = HomErrorLevel.fatal) {
    const formData = new FormData();
    const clientVersion: string = localStorage.getItem('hom-version') || '';
    formData.append('applicationClientErrorName', error.name);
    formData.append('applicationClientErrorDescription', error.message);
    formData.append('applicationClientErrorStack', error.stack ? error.stack : 'None');
    formData.append('applicationClientErrorLocation', window.location.href);
    formData.append('applicationClientErrorLevel', errorLevel);
    formData.append('version', clientVersion);

    this.dos.createByMethodWithForm('ApplicationClientError', 'Create', formData)
      .subscribe((response) => {
        if (response.success) {
          const data: IApplicationClientError = response.data;
          if (data.version !== clientVersion) {
            let event: IHomEventEmitter = {
              requestor: 'hom-error-handler',
              event: HomErrorEvent.VersionMismatchEvent,
              action: '',
              data: null
            };
            this.emitterService.emitErrorEvent(event);
          }
        } else {
            console.log('Insert of Client Error Failed with: ', response);
        }
      });
    console.log('Application Error Encountered: ', error);
  }

}
