import { Injectable} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, map, filter , first } from 'rxjs/operators';

import { IListFilter, ISearchTerm, OrderTerm } from '../../../fw/dynamic-list/interfaces/index';
import { ICommunicationDocumentType } from '../view-models/index';

//store actions and reducers
import * as fromStore from '../../../fw/dynamic-list/store/index';
import * as fromSelectionLists from '../../../shared/store/selectionLists/index';
import { AccessLevel } from '../../../fw/dynamic-list/enums/access-level.enums';
import { SearchType } from '../../../fw/dynamic-list/enums/search-type.enums';
import { UserPriviledgesService } from '../../../auth/services';

@Injectable()
export class CommunicationDocumentTypeResolver  {
  providerId: number;

  listFilter: IListFilter = {
    isLookup: true,
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true,  
    currentPage: 1,
    searchTerm: [],
    orderTerm: [new OrderTerm('sortOrder')]
  };

  constructor(public selectionListStore: Store<fromSelectionLists.IStoreState>,
    public store: Store<fromStore.IAllDynamicData>,
    public ups: UserPriviledgesService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICommunicationDocumentType[]> {
    this.providerId = this.ups.providerId$.value;
    this.requestData();
    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<ICommunicationDocumentType[]> {
    return this.selectionListStore.select(fromSelectionLists.getSelectionListDataByType('communicationDocumentType', this.providerId))
      .pipe(
        filter((data: any[]) => data !== null),
        map((data: any[]) => { return data; }),
        first()
      );
  }

  requestData(): void {
    this.selectionListStore.select(fromSelectionLists.getSelectionListDataByType('communicationDocumentType', this.providerId))
      .pipe(take(1))
      .subscribe((data: any[]) => {
        if (data === null) {
          this.store.dispatch(new fromSelectionLists.GetEntityListById('CommunicationDocumentType', 'ByProvider', this.providerId, this.listFilter));
        }
      });
  }
}
