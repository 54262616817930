<div *ngIf="installers && workOrder">
  <div class="float-label">
    <select #installerList id="installer" name="installer" required (change)="onChange($event)">
      <option value="">{{myConstants.choose}}</option>
      <option *ngFor="let item of installers" value="{{item['installerId']}}">{{item['installerName']}}</option>
    </select>
    <label for="installer">Installer</label>
  </div>
  <div class="app-btn-box">
    <button type="button" *ngIf="isWarranty"
            class="app-btn app-btn--primary"
            [disabled]="(canPush$ | async) || !canIEdit"
            (click)="onPush(0)">
      Push With Original Installer
    </button>
    <button type="button"
            class="app-btn app-btn--primary"
            [disabled]="!(canPush$ | async) || !canIEdit"
            (click)="onPush(installerList.value)">
      Push To Accounting
    </button>
  </div>
</div>
