import { Directive, Input} from '@angular/core';
import { NgControl } from '@angular/forms';
import { skip, distinctUntilChanged, debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[dateMask]',
})
export class DateMaskDirective {

  @Input() disableMask: boolean;

  constructor(public ngControl: NgControl) {
    this.ngControl.valueChanges
      .pipe(
        skip(1), 
        distinctUntilChanged(),
        debounceTime(700))
      .subscribe((value) => {
        this.onInputChange(value);
      });
  }

  onInputChange(event) {
    if (this.disableMask) return;
    let newVal = event ? event.replace(/\D/g, '') : '';
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length === 1) {
      newVal = newVal.replace(/^(\d{0,1})/, '$1');
    } else if (newVal.length === 2) {
      newVal = newVal.replace(/^(\d{0,2})/, '$1/');
    } else if (newVal.length <= 4) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1/$2/');
    } else {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,4})/, '$1/$2/$3');
    }
    this.ngControl.valueAccessor.writeValue(newVal);
  }
}
