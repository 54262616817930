export interface IProjectCallLog {
  projectCallLogId: number;
  isComplete: boolean;
  project_projectId: number;
  workOrder_workOrderId: number;
  callLogType_callLogTypeId: number;
  dueDateOverride: string;
  dueDateOverrideReason: string;

  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
}

export class ProjectCallLog implements IProjectCallLog {
  projectCallLogId: number;
  isComplete: boolean;
  project_projectId: number;
  workOrder_workOrderId: number;
  callLogType_callLogTypeId: number;
  dueDateOverride: string;
  dueDateOverrideReason: string;

  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  constructor() {
    this.projectCallLogId = 0;
    this.project_projectId = null;
    this.workOrder_workOrderId = null;
    this.isComplete = false;
    this.callLogType_callLogTypeId = null;
    this.dueDateOverride = null;
    this.dueDateOverrideReason = null;
    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';

  }

}
