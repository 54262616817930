import { Component, OnInit,  Inject, Input, Output, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IUserRoleViewModel } from '../../../view-models';
import { AdminService } from '../../services/admin.service';

@Component({
 selector: 'user-roles',
  templateUrl: './user-roles.component.html'
})
export class UserRolesComponent implements OnInit  {
  @Input() userRoles: IUserRoleViewModel[];
  @Input() isWorking: boolean;
  @Input() haveAllRoles: boolean;
  @Input() specifierType: number;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  requestor: string = 'user-roles';

  constructor(public adminService: AdminService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onEvent(event: IHomEventEmitter) {
    let eventOut: IHomEventEmitter = cloneDeep(event);
    eventOut.action = this.specifierType.toString();
    this.customEvent.emit(eventOut);
  }

  ngOnInit() {}

}
