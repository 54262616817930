import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IMultiTierMenu } from '../interfaces/i-multi-tier-menu';
import { IMultiTierOuput } from '../interfaces/i-multi-tier-output';

@Component({
    selector: 'fw-multi-tier-item',
    templateUrl: './fw-multi-tier-item.component.html'
})
export class MultiTierItemComponent {

  @Input() item: IMultiTierMenu;
  @Input() idx: number;
  @Input() level: number;
  @Input() toggled: boolean;

  @Output() public selected: EventEmitter<IMultiTierOuput> = new EventEmitter();
  @Output() public initIdx: EventEmitter<{idx: number, level: number}> = new EventEmitter();

    public toggleItem(): void {
      this.initIdx.emit({ level: this.level, idx: this.idx });
      if (!this.item.disabled && (!this.item.subItems || this.item.subItems.length === 0)) {
            return this.initSelected({
                item: this.item.data
            });
        } else this.toggled = !this.toggled;
    }

    initSelected(output: IMultiTierOuput) {
        this.selected.emit(output);
    }
    
}
