import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, SimpleChanges, ElementRef, Renderer2, OnChanges, AfterViewInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep, orderBy } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IProviderLocation, IProviderLocationBranch, IProviderUser } from '../../../portals/view-models';
import {
  MultiSelectDropdown,
  MultiSelectTexts,
  MultiSelectSettings,
  IMultiSelectOption
} from '../../../../fw/fw-shared/components/fw-multi-select-dropdown';
import { IParameterSearchType } from '../../../../fw/dynamic-list/interfaces';
import { ISmsFilterEvent, ISmsExcludeId } from '../../interfaces/index';
import { IInputButton } from '../../../../fw/fw-shared/interfaces';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { SmsEvent } from '../../enums/sms.enums';

import { CheckBoxComponent } from '../../../../fw/fw-shared/components';
import { UserPriviledgesService } from '../../../../auth/services';
import { HomCommonUtility } from '../../../../shared/services';

@Component({
  selector: 'sms-filters',
  templateUrl: './sms-filters.component.html'
})
export class SmsFiltersComponent implements OnInit, OnChanges  {
  @Input() locations: IProviderLocation[];
  @Input() branches: IProviderLocationBranch[]; //all, for all locations
  @Input() providerUsers: IProviderUser[];
  @Input() resetOn: string;

  @Output() public filter = new EventEmitter<IHomEventEmitter>();

  @ViewChild('locations') public locMultiSelect: MultiSelectDropdown;
  @ViewChild('branches') public branchMultiSelect: MultiSelectDropdown;
  @ViewChild('providerUser') public providerUserSelect: ElementRef;
  @ViewChild('searchType') public searchTypeInput: ElementRef;
  @ViewChild('contactName') public contactNameInput: ElementRef;
  @ViewChild('onlyActiveSubs') public onlyActiveSubs: CheckBoxComponent;
  @ViewChild('excludeId') public excludeIdSelect: ElementRef;

  public multiSelectLabels: MultiSelectTexts = new MultiSelectTexts();
  public multiSelectSettings: MultiSelectSettings = new MultiSelectSettings();
  public searchTypes: IParameterSearchType[] = [];
  public locationOptions: IMultiSelectOption[] = [];
  public activeSubsConfig: IInputButton;
  public excludeIds: ISmsExcludeId[] = [];
  public branchOptions$: BehaviorSubject<IMultiSelectOption[]> = new BehaviorSubject([]);
  public isValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  selectedLocations: IProviderLocation[] = [];
  selectedBranches: IProviderLocationBranch[] = [];
  selectedProviderUser: IProviderUser = null;
  selectedExcludeId: number = 0;
  searchName: string = '';
  selectedSearchType: IParameterSearchType = null;
  changedFilter: boolean = false;

  constructor(
    public renderer: Renderer2,
    public userPriviledgesService: UserPriviledgesService,
    public homCommonUtility: HomCommonUtility) { }

  ngOnInit(): void {
    this.multiSelectLabels = new MultiSelectTexts();
    this.multiSelectSettings = new MultiSelectSettings();
    this.multiSelectSettings.selectionLimit = 0;
    this.multiSelectSettings.closeOnSelect = false;
    this.multiSelectSettings.autoUnselect = false;
    this.multiSelectSettings.showCheckAll = true;
    this.searchTypes = this.userPriviledgesService.getSearchTypesByParameterType('string');
    this.selectedSearchType = this.searchTypes.length > 0 ? this.searchTypes[0] : null;

    this.activeSubsConfig = {
      label: {
        label: 'Show Only Active Subscriptions',
        alignRight: true,
        title: 'Show Only Active Subscriptions'
      }
    };
    this.changedFilter = false;
    this.loadExcludeOptions();
    this.loadLocationOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resetOn'] && !changes['resetOn'].isFirstChange()) {
      this.clearFilters();
    }
  }

  public uncheckedAllLocations(): void {
    if (this.branchMultiSelect) {
      this.branchMultiSelect.uncheckAll();
    }
  }

  public addLocation(id: number): void {
    const loc: IProviderLocation = this.locations.find(x => x.providerLocationId == id);
    if (!loc) {
      return;
    }
    const exists: IProviderLocation = this.selectedLocations.find(x => x.providerLocationId === id);
    if (!exists) {
      this.loadPLBranches(id, true, []);
      this.selectedLocations = [...this.selectedLocations, loc];
    }
    this.setIsValid();
  }

  public removeLocation(id: number): void {
    const loc: IProviderLocation = this.locations.find(x => x.providerLocationId == id);
    if (!loc) {
      return;
    }
    const exists: number = this.selectedLocations.findIndex(x => x.providerLocationId === id);
    if (exists > -1) {
      this.loadPLBranches(id, false, []);
      this.selectedLocations.splice(exists, 1);
    }
    this.setIsValid();
  }

  public addBranch(id: number): void {
    const plBranch: IProviderLocationBranch = this.branches.find(x => x.providerLocationBranchId == id);
    if (!plBranch) {
      return;
    }
    const exists: IProviderLocationBranch = this.selectedBranches.find(x => x.providerLocationBranchId === id);
    if (!exists) {
      this.selectedBranches = [...this.selectedBranches, plBranch];
    }
  }

  public removeBranch(id: number): void {
    const plBranch: IProviderLocationBranch = this.branches.find(x => x.providerLocationBranchId == id);
    if (!plBranch) {
      return;
    }
    const exists: number = this.selectedBranches.findIndex(x => x.providerLocationBranchId === id);
    if (exists > -1) {
      this.selectedBranches.splice(exists, 1);
    }
  }

  public selectProviderUser(id: number): void {
    this.selectedProviderUser = id === 0 ? null : this.providerUsers.find(x => x.providerUserId == id);
    this.setIsValid();
  }

  public selectSearchType(id: number): void {
    this.selectedSearchType = id === 0 ? null :this.searchTypes.find(x => x.parameterSearchTypeId == id);
  }

  public changeName(val: string): void {
    this.searchName = val;
    this.setIsValid();
  }

  public onChangeSubView(val: boolean): void {
    this.changedFilter = true;
    this.setIsValid();
  }

  public selectExcludeId(id: number): void {
    this.selectedExcludeId = id;
    this.changedFilter = true;
    this.setIsValid();
  }

  public runFilter(): void {
    const emitter: IHomEventEmitter = {
      requestor: 'sms-filters', event: SmsEvent.filterContacts, action: '', data: this.setFilter() };
    this.filter.emit(emitter);
  }

  public resetFilter(): void {
    this.clearFilters();
    const emitter: IHomEventEmitter = {
      requestor: 'sms-filters', event: SmsEvent.refreshContacts, action: '', data: this.setFilter()
    };
    this.filter.emit(emitter);
  }

  loadExcludeOptions(): void {
    this.excludeIds = [];
    this.excludeIds.push({ id: 0, value: 'None' });
    this.excludeIds.push({ id: 1, value: 'Customers' });
    this.excludeIds.push({ id: 4, value: 'Installers' });
  }

  loadLocationOptions(): void {
    this.locationOptions = [];
    this.locations.forEach((loc: IProviderLocation) => {
      let selected: boolean = false;
      this.locationOptions.push({ id: loc.providerLocationId, name: loc.locationName, selected: selected });
    });
  }

  //Local subfilter
  //Load Provider Location Branches for the selected provider location
  loadPLBranches(providerLocationId: number, addLocation: boolean, selectedPLBIds: number[]): void {
    let options = cloneDeep(this.branchOptions$.value);
    const plbs: IProviderLocationBranch[] = this.branches.filter(x => x.providerLocation_providerLocationId === providerLocationId);

    plbs.forEach(plb => {
      const indx: number = options.findIndex(x => x.id === plb.providerLocationBranchId);
      const selected: number = selectedPLBIds.findIndex(x => x === plb.providerLocationBranchId);
      if (addLocation) {
        if (indx === -1) {
          const name: string = plb.locationName.trim() === plb.branchName.trim()
            ? plb.branchName.concat(' ', plb.generalContractorName)
            : plb.locationName.concat(' ', plb.branchName);
          options.push({ id: plb.providerLocationBranchId, name: name, selected: selected > -1 });
        }
      } else {
        if (indx > -1) {
          options.splice(indx, 1);
        }
      }
    });

    this.branchOptions$.next(orderBy(options, 'name'));
  }

  setIsValid(): void {
    const hasUser: boolean = this.selectedProviderUser ? true : false;
    this.isValid$.next(this.selectedLocations.length > 0
      || hasUser
      || this.searchName.length > 2
      || this.changedFilter);
  }

  setFilter(): ISmsFilterEvent {
    let onlyActiveEl: CheckBoxComponent = this.onlyActiveSubs ? this.onlyActiveSubs : null;
   return {
      providerLocations: this.selectedLocations,
      providerLocationBranches: this.selectedBranches,
      providerUser: this.selectedProviderUser,
      searchType: this.searchName.length > 2 ? this.selectedSearchType : null,
      searchName: this.searchName.length > 2 ? this.searchName : '',
      onlyActiveSubs: onlyActiveEl ? onlyActiveEl.value : true,
      excludeId: this.selectedExcludeId
    };
  }

  clearFilters(): void {
    //reset drop downs
    this.locMultiSelect.uncheckAll();
    this.branchMultiSelect.uncheckAll();
    if (this.providerUserSelect) {
      this.renderer.setProperty(this.providerUserSelect.nativeElement, 'value', '0');
    }
    if (this.searchTypeInput) {
      const searchDefault = this.searchTypes.find(x => x.searchType === SearchType.StartsWith);
      if (searchDefault) {
        this.renderer.setProperty(this.searchTypeInput.nativeElement, 'value', searchDefault.parameterSearchTypeId);
      }
    }
    if (this.contactNameInput) {
      this.renderer.setProperty(this.contactNameInput.nativeElement, 'value', '');
    }
    if (this.onlyActiveSubs) {
      let onlyActiveSubs: CheckBoxComponent = this.onlyActiveSubs;
      onlyActiveSubs.value = true;
    }

    if (this.excludeIdSelect) {
      this.renderer.setProperty(this.excludeIdSelect.nativeElement, 'value', '0');
    }

    this.changedFilter = false;

    this.setIsValid();
  }

}
