import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminEvent } from '../../enums/admin.enums';
import { IBranchProgram, IBranch, IProgramService, IRegion } from '../../../view-models';
import { getSelectedRecord, IAllDynamicData } from '../../../../../fw/dynamic-list/store';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'branch-regions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './branch-regions.component.html'
})
export class BranchRegionsComponent implements OnInit, OnDestroy {

  public listDefinition: IListDefinition;
  public title: string;
  subscription: Subscription = new Subscription();
  regionId: number = 0;
  branchId: number = 0;
  currentBranch: IBranch;
  currentRegion: IRegion;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<IAllDynamicData>,
    public adminService: AdminProviderService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.regionId = +paramMap.get('regionId');
      this.branchId = +paramMap.get('branchId');
      this.title = this.regionId > 0 ? 'Branches' : 'Regions';
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.showRelatedEntityManager:
        this.openEntityManager(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadBranchRegionsListDefinition(this.regionId, this.branchId);
    const parentListDef = this.branchId > 0
      ? this.adminService.loadBranchesListDefinition()
      : this.adminService.loadRegionsListDefinition();

    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(parentListDef.storeName, -1, parentListDef.rowKeyId,
        this.branchId > 0 ? this.branchId : this.regionId), take(1)))
      .subscribe(entity => {
        this.currentBranch = this.branchId > 0 ? entity : null;
        this.currentRegion = this.regionId > 0 ? entity : null;
      })
    );
  }

  openEntityManager(br: IBranchProgram): void {
    const parentName: string = this.branchId > 0
      ? 'Branch '.concat(this.currentBranch.branchName)
      : 'Region '.concat(this.currentRegion.regionName);
    const parentId: number = this.branchId > 0 ? this.branchId : this.regionId;
    this.adminService.openRelatedEntityManager(this.listDefinition, parentName, parentId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
