import { Component, OnInit, Input, ChangeDetectionStrategy, Inject } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants';
import { IListDefinition, IListFilter, IListColumn, ListColumn, IListButtonType, OrderTerm, ListFilter } from '../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../fw/fw-modal/interfaces/i-modal';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';

import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { DocumentTypeName, ProjectFileEvent } from '../../enums/file-upload.enums';
import { ProjectListStore } from '../../../portals/project/enums/project.enums';
import { UtilitiesStore } from '../../../portals/utilities/enums/utilities.enums';
import { IEntityDocument } from '../../../portals/view-models/index_two';

@Component({
  selector: 'project-files',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project-files.component.html'
})

export class ProjectFilesComponent implements OnInit {
  @Input() isProject: boolean;
  @Input() parentId: number;  
  @Input() canIEdit: boolean;

  public listDefinition: IListDefinition;
  storeName: string;
  constructor(
    public modalService: ModalService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }


  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case ProjectFileEvent.launchFileViewer:
        this.showFileViewer(event.data);
        break;
      default:
        break;
    }
  }

  showFileViewer(data: IEntityDocument) {
    const params = {
      rowIndex: -1,
      storeName: this.storeName,
      key: 'entityDocumentId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.parentId
    };

    this.modalService.open({
      title: 'File Viewer',
      path: 'file-viewer',
      castExit: true,
      id: data.latestFileUploadId,
      optionalParams: params,
      sizeType: ModalSizeType.full,
      hasTabs: false,
      onExit: null
    });
  }

  ngOnInit(): void {
    this.storeName = this.isProject ? ProjectListStore.projectFiles : UtilitiesStore.dashFileUploads;
    this.listDefinition = this.loadListDefinition();
  }

  loadListDefinition(): IListDefinition {
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [{
        term: 'documentType_documentTypeName',
        value: DocumentTypeName.localProjectFile,
        searchType: SearchType.Equals,
        fieldType: this.myConstants.dataTypeString,
        columnName: 'documentType'
      }];
    defaultListFilter.orderTerm = [new OrderTerm('CreateDate', true)];

    let listDefinition = this.dynamicListService.createListDefinition(
      this.isProject ? 'projectFilesOutlet' : '', // outlet
        'File Viewer', // label
        'EntityDocument', // controller
        this.storeName,  
        'entityDocumentId', // listRowKeyId
        defaultListFilter, // listFilter
        this.loadListColumns(), // listColumns
      this.isProject ? 'file-uploader' : 'project-file-viewer' // detailRoutePath
      );

    listDefinition.controllerMethod = this.isProject ? 'ByProjectWithFile' : 'RecentUploads';
    listDefinition.methodParameters = this.parentId.toString();
    listDefinition.parentId = this.parentId;
    listDefinition.parentKey = 'parentEntityPkid';
    listDefinition.showFilter = true;
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;

    if (this.canIEdit) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);

      let rowButtons: IListButtonType[] = [{ type: ButtonType.delete, defaults: null }];
      listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(rowButtons, listDefinition.objectLabel);
    }

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'View Slideshow',
        icon: 'fas fa-eye',
        cssName: 'app-btn-icon--primary',
        enabled: true,
        eventName: ProjectFileEvent.launchFileViewer
      },
      {
        title: 'Download File',
        icon: 'fas fa-download',
        cssName: 'app-btn-icon--custom-1',
        enabled: true,
        eventName: ProjectFileEvent.downloadFile,
        isDownload: true
      }
    );

    return listDefinition;
  }

  loadListColumns(): IListColumn[] {
    let colDef: IListColumn;
    const columnDefinitions: IListColumn[] = [];
    if (this.isProject) {
      colDef = new ListColumn('thumbUrl');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('fileName');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('properties');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

     colDef = new ListColumn('uploadDate');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('includeInWorkOrderPacket');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

    } else {
      let colDef = new ListColumn('entityPkid');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('customerName');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('thumbUrl');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('fileName');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('uploadDate');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);
    }
    return columnDefinitions;
  }


}
