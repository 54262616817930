import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ICommunicationShortcut } from '../../../portals/view-models';

@Component({
  selector: 'sms-shortcuts',
  templateUrl: './sms-shortcuts.component.html'
})
export class SmsShortcutsComponent implements OnChanges {
  @Input() shortcuts: ICommunicationShortcut[];
  @Input() timestamp: string;
  @ViewChild('shortSearch') public searchInput: ElementRef;

  @Output() public select = new EventEmitter<string>();

  public filteredValues$: BehaviorSubject<ICommunicationShortcut[]> = new BehaviorSubject(null);

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['shortcuts']) {
      this.filteredValues$.next(this.shortcuts);
    }
    if (changes['timestamp'] && !changes['timestamp'].isFirstChange()) {
      this.filteredValues$.next(this.shortcuts);
      if (this.searchInput) {
        this.searchInput.nativeElement.value = '';
        this.searchInput.nativeElement.focus();
      }
    }
  }

  selectTemplate(id: number): void {
    const shortcut: ICommunicationShortcut = this.shortcuts.find(x => x.communicationShortcutId == id);
    if (shortcut) {
      this.select.emit(shortcut.communicationShortcutTemplate);
    }
  }

  selectFirstItem(event: any): void {
    const items = this.filteredValues$.value;
    this.select.emit(items ? items[0].communicationShortcutTemplate : '');
  }

  onKeyup(val: string): void {
    this.filteredValues$.next(
      this.shortcuts.filter(x => x.communicationShortcutTemplate.toLowerCase().indexOf(val.toLowerCase()) !== -1)
    );
  }
}
