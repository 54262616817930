import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IAllDynamicData, getSelectedRecord } from '../../../../../fw/dynamic-list/store';
import { IPrivilegeViewModel } from '../../../view-models/i-privilege-view-model';
import { AdminSecurityService } from '../../services/admin-security.service';

@Component({
  selector: 'privileges-where-used',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './privileges-where-used.component.html'
})
export class PrivilegesWhereUsedComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;
  uniqueId: number;
  privilegeId: string; //yes, it is a string refer to c#
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<IAllDynamicData>,
    public securityService: AdminSecurityService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.uniqueId = +paramMap.get('id');
      this.newRequest();
    });
  }

  newRequest() {
    //go get the record so can go get the list of privilege template privileges that correspond
    const privsListDef = this.securityService.loadPrivilegesListDefinition();
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(privsListDef.storeName, -1, privsListDef.rowKeyId, this.uniqueId), take(1)))
      .subscribe((entity: IPrivilegeViewModel) => {
        this.listDefinition = this.securityService.loadPrivilegeWhereUsedListDefinition(entity.privilegeId, this.uniqueId);
     })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
