<div *ngIf="(days$ | async)?.length" class="dispatch-week">
    <div *ngFor="let item of (days$ | async); let i = index"
        class="dispatch-week-day"
        [ngStyle]="{'grid-row': (i+1) + '/' + (i+2) }">
      <div class="dispatch-week-day__header" >
        <div class="dispatch-week-day__header__day" title="Go To {{ item.day | date:'MM/dd' }}" (click)="goTo(item.day)">
          <span>{{ item.day | date:'EEEE'}}</span>
          <span>{{ item.day | date:'MM/dd' }}</span>
        </div>
        <div class="dispatch-week-day__header__count">
          {{ item.data.length }} Items
        </div>
        <div class="dispatch-week-day__header__btns">
          <!--LOCK DAY-->
          <ng-container *ngIf="canScheduleLockDays">
            <button  class="app-btn-icon"
                    [ngClass]="{'app-btn-icon--success': !item.isLocked, 'app-btn-icon--danger': item.isLocked }"
                    (click)="lockDay(item.day)"
                    title="{{ item.isLocked ? 'Unlock' :  'Lock' }} Day">
              <i class="fas fa-lock-{{ !item.isLocked ? 'open-' : '' }}alt"></i>
            </button>
          </ng-container>
          <ng-container *ngIf="!(canScheduleLockDays)">
            <div  [ngClass]="{'app-icon--success': !item.isLocked, 'app-icon--danger': item.isLocked }"
                 title="{{ item.isLocked ?  'Day is Locked' : 'Day is Unlocked'  }} Day">
              <i class="fas fa-lock-{{ !item.isLocked ? 'open-' : '' }}alt"></i>
            </div>
          </ng-container>
          <!--PRINT-->
          <button class="app-btn-icon app-btn-icon--print"
                  [disabled]="item.data.length === 0"
                  (click)="printPackets(item.day)"
                  title="Print All Work Order Packets For {{ item.day | date:'MM/dd' }}">
            <i class="fas fa-print"></i>
          </button>

        </div>
      </div>
      <div class="dispatch-week-day__view">
          <dispatch-day-view [data]="item.data"
                             [selectedDate]="item.day"
                             [forView]="myView"
                             [holidays]="holidays"
                             [showHeader]="i===0"
                             (customEvent)="onCustom($event)">
          </dispatch-day-view>
    </div>
  </div>
</div>
