import { Injectable } from '@angular/core';
import { ISiteSearchViewModel } from '../../../app/portals/view-models/i-site-search-view-model';

export interface ISiteSearchHistory {
  page: number;
  data: ISiteSearchViewModel;
}

@Injectable()
export class FwSearchBarService {
  public historyItems: ISiteSearchHistory[] = [];
}
