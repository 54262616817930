<ng-container *ngIf="ready">
  <fw-button-title-bar [title]="title"
                       [canIEdit]="canIEdit"
                       [operation]="(forceShowCancel$ | async) ? myConstants.operationTypeEdit : (operation$ | async)"
                       [disabled]="(isBarDisabled$ | async)"
                       [showEdit]="isPoImport ? false : true"
                       [showRefresh]="true"
                       [showDelete]="false"
                       [customButtons]="customButtons$ | async"
                       (customEvent)="onTitleBarEvent($event)">
  </fw-button-title-bar>

  <contact-manager #contactManager
                   [contactId]="contactId"
                   [operationIn]="operation$ | async"
                   [isOrganization]="isOrganization"
                   [contactTypeId]="contactTypeId"
                   [displayType]="contactConstantsService.displayTypePortalDetail"
                   [canIEdit]="canIEdit"
                   [seedData]="seedDataOut$ | async"
                   [checkForMatch]="checkForMatch"
                   [isPoImport]="isPoImport"
                   [defaults]="defaults"
                   (managerEvent)="onManagerEvent($event)">
  </contact-manager>

</ng-container>
