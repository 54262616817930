import { Component, OnInit,  Inject, Input, Output, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IProviderUserPrivilegeViewModel } from '../../../view-models';
import {  PrivilegeRuleEntitySpecifierType } from '../../enums/admin.enums';

@Component({
  selector: 'user-general-contractors',
  templateUrl: './user-general-contractors.component.html'
})
export class UserGeneralContractorsComponent implements OnInit {
  @Input() userGcs: IProviderUserPrivilegeViewModel[];
  @Input() specifierType: number;
  @Input() isWorking: boolean;
  @Input() haveAllGcs: boolean;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public title: string;
  public accessType: string;
  requestor: string = 'user-general-contractors';

  constructor( @Inject(appConstants) public myConstants: IAppConstants) { }

  public onEvent(event: IHomEventEmitter) {
    let eventOut: IHomEventEmitter = cloneDeep(event);
    eventOut.action = this.specifierType.toString();

    this.customEvent.emit(eventOut);
  }

  ngOnInit() {
    const type: string = this.specifierType === PrivilegeRuleEntitySpecifierType.primaryGeneralContractor ? 'Primary' : 'Secondary';
    this.title = type.concat(' ', 'General Contractor');
  }
}
