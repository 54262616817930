import { Component, Input} from '@angular/core';

@Component({
    selector: 'fw-component-footer',
  templateUrl: './fw-component-footer.component.html'
})
export class ComponentFooterComponent  {
  @Input() lastUpdate: string = '';
  @Input() isFormatted: boolean = false;
  @Input() label: string = 'Last Update';

  //expand config as requirements grow
  forPortal: boolean = true;

    constructor() { }

}
