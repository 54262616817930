<div *ngIf="sanitizedAddr" class="verify-results">
  <fw-component-title [title]="componentTitle"></fw-component-title>
  <div class="app-card verify-results__card">
    <ng-container *ngIf="address">
      <div class="app-card__header verify-results__card__orig-head">
        <div class="app-card__header--title">Unverified UnMappable Address</div>
      </div>
      <div class="app-card__content verify-results__card__orig-content">
        <div *ngIf="address.line1">{{address.line1}}</div>
        <div *ngIf="address.line2">{{address.line2}}</div>
        <div *ngIf="address.line3">{{address.line3}}</div>
        <div *ngIf="address.city || address.stateAbbr || address.zipcode5 || address.zipcode4">
          {{ address.city }} <span *ngIf="address.city && address.stateAbbr">{{ address.stateAbbr }} </span>
          {{ address.zipcode5 }}<span *ngIf="address.zipcode5 && address.zipcode4">-{{address.zipcode4}}</span>
        </div>
      </div>
    </ng-container>

    <hr class="u-margin-top-small u-margin-bottom-small verify-results__card__sep" />

    <ng-container *ngIf="sanitizedAddr && !badAddress">
      <div class="app-card__header verify-results__card__sani-head">
        <div class="app-card__header--title">Use Verified Mappable Address?</div>
      </div>
      <div class="app-card__content verify-results__card__sani-content">
        <div *ngIf="sanitizedAddr.line1">{{sanitizedAddr.line1}}</div>
        <div *ngIf="sanitizedAddr.line2">{{sanitizedAddr.line2}}</div>
        <div *ngIf="sanitizedAddr.line3">{{sanitizedAddr.line3}}</div>
        <div *ngIf="sanitizedAddr.city || sanitizedAddr.stateAbbr || sanitizedAddr.zipcode5 || sanitizedAddr.zipcode4">
          {{ sanitizedAddr.city }} <span *ngIf="sanitizedAddr.city && sanitizedAddr.stateAbbr">{{ sanitizedAddr.stateAbbr }} </span>
          {{ sanitizedAddr.zipcode5 }}<span *ngIf="sanitizedAddr.zipcode5 && sanitizedAddr.zipcode4">-{{sanitizedAddr.zipcode4}}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="badAddress">
      <div class="app-card__header verify-results__card__sani-head">
        <div class="app-card__header--title app-card__header--title--bad">
          Invalid Address
        </div>
      </div>

      <div class="app-card__content  verify-results__card__sani-content">
        <span class="app-text--danger"><i class="fas fa-map-marker-alt-slash"></i></span>
        The address is either malformed or the address is not currently registered with the search utility.<br />
        Please review the address for obvious errors and correct, if possible.
      </div>
    </ng-container>
    <div class="app-btn-box">
      <button *ngIf="!badAddress" type="button" class="app-btn app-btn--primary" (click)="onChoice(true)"> Yes</button>
      <button type="button" class="app-btn app-btn--cancel" (click)="onChoice(false)">No</button>
      <button type="button" class="app-btn app-btn--warning" (click)="onChoice(false, true)">Use Unverified</button>
    </div>
  </div>
</div>



