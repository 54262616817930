  <div *ngIf="createForm">
  <form [formGroup]="createForm" novalidate>
    <fw-dynamic-field [field]="mds.getFieldDefinition('documentType')"
                      [form]="createForm"
                      [operation]="operation"
                      [selectItems]="documentTypes">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('children')"
                      [form]="createForm"
                      [selectItems]="projectChildren" 
                      [operation]="!projectChildren.length ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
  </form>

  <fw-crud-buttons [operation]="operation"
                   [disabled]="!createForm.valid"
                   showNext="false"
                   (cancelEvent)="cancel()"
                   (createEvent)="create()">
  </fw-crud-buttons>
</div>
