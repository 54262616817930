import { Component, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { IUploadFile } from '../../interfaces';
import { FilePreviewItemComponent } from '../file-preview-item/file-preview-item.component';
import { IEntityDocument } from '../../../portals/view-models/index_two';

@Component({
  selector: 'file-previewer',
  templateUrl: './file-previewer.component.html'
})

export class FilePreviewerComponent  {
  @Input() files: IUploadFile[];
  @Input() allowMultiple: boolean;
  @Input() listType: string;
  @Input() entityDocument: IEntityDocument;

  @Output() saveEvent = new EventEmitter<IUploadFile>();
  @Output() deleteEvent = new EventEmitter<string>();
  @Output() uploadEvent = new EventEmitter<IUploadFile>();
  @Output() changeEvent = new EventEmitter<IUploadFile>();

  @ViewChildren('previewItem', { read: FilePreviewItemComponent }) public previewItems: QueryList<FilePreviewItemComponent>;

  public uploadAll(): void {
    if (this.previewItems) {
      //execute the upload item on the item component itself
      let allItems: FilePreviewItemComponent[] = this.previewItems.toArray();
      allItems.forEach(fpComponent => {
        fpComponent.uploadItem();
      });
    }
  }

  public removeItem(id: string): void {
    this.deleteEvent.emit(id);
  }

  public uploadItem(fileUpload: IUploadFile): void {
    this.uploadEvent.emit(fileUpload);
  }

  public changeItem(fileUpload: IUploadFile): void {
    this.changeEvent.emit(fileUpload);
  }
}
