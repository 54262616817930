<div class="unavail-reasons">
  <ng-container *ngFor="let item of reasons">
    <span class="unavail-reasons__item"
          [ngClass]="{ 'unavail-reasons__item--compliance': item.trim() === 'Compliance',
          'unavail-reasons__item--vacation': item.trim() === 'Vacation',
          'unavail-reasons__item--schedule': item.trim() === 'Schedule',
          'unavail-reasons__item--score': item.trim() === 'Score',
          'unavail-reasons__item--sss': item.trim() === 'Sss' }">
      <i class="fas fa-circle"></i></span>
  </ng-container>
</div>

