
export class PlsInstallerWorkCrewViewModel {

    constructor(
      public installerId: number = 0,
      public installerWorkCrewId: number = 0,
      public workCrewName: string = '',
      public providerLocationId: number = 0,
      public locationName: string  = '',
      public serviceNames: string = '', 
      public providerLocationServiceIds: number[] = [],
    ) {
    }

}
