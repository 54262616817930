import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { Observable, Subscription} from 'rxjs';
import {  map, mergeMap } from 'rxjs/operators';
import { upperFirst, lowerFirst } from 'lodash';

/** Imports for custom services */
import { IResponseBase } from '../../interfaces/index';
import {  DomainObjectService } from '../../services/index';
import * as SelectListActions from './selectionLists.actions';

import * as fromRoot from '../../../app/store/reducers/index';
import * as fromAuth from '../../../auth/store/index';

//using Effects so can assign actions to an observable result set
@Injectable()
export class SelectionListEffects implements OnDestroy {
  authStore: fromAuth.IState;
  subscription: Subscription = new Subscription();

  constructor(
    public actions$: Actions,
    public domainObjectService: DomainObjectService,
    public rootStore: Store<fromRoot.IState>
  ) {
    this.subscription.add(this.rootStore.select('auth').subscribe((state: fromAuth.IState) => {
      this.authStore = state;
    }));
  }


  /*********************************************************************************************************************************************
  GET_ENTITY_LIST Effect
  **********************************************************************************************************************************************/
  entityListGet$ = createEffect(() => this.actions$.pipe(
    ofType<SelectListActions.GetEntityList>(SelectListActions.SelectionListActionTypes.GET_ENTITY_LIST),
    map((action: SelectListActions.GetEntityList) => action.payload),
    //need mergeMap, not switchMap as there will be multiple requests for this effect that we need to resolve
    mergeMap((payload) => {
      const entityName = payload.entityName;
      const listFilter = payload.listFilter;
      if (!this.authStore.authenticated) {
        return [{ type: SelectListActions.SelectionListActionTypes.RESET_ALL_SELECTION_LIST_STORES }];
      } else {
        return this.domainObjectService.getListByProvider(upperFirst(entityName), this.authStore.providerUser ? this.authStore.providerUser.providerId : 1, listFilter)
          .pipe(
            mergeMap((response: IResponseBase) => {
              //take the results, mapped into IResponseBase and stuff them into their appropriate buckets
              //then trigger the action that will utilize the returned data (set list results)
              //let actionType = null;
              const dataObject = {
                storeName: payload.storeName ? payload.storeName : lowerFirst(entityName),
                parentId: -1,
                data: response.success ? response.data : null,
                listMetaData: response.success ? response.metaData : {},
                errorData: response.success ? null : response.errorData
              };

              return [{ type: SelectListActions.SelectionListActionTypes.SET_ENTITY_LIST, payload: dataObject }];
            }
            )
          );
      }
    }),
    //catchError(this.handleError)
 ) );


  /*********************************************************************************************************************************************
GET_LOOKUP_LIST Effect
**********************************************************************************************************************************************/
  listGet$ = createEffect(() => this.actions$.pipe(
    ofType<SelectListActions.GetLookupList>(SelectListActions.SelectionListActionTypes.GET_LOOKUP_LIST),
    map((action: SelectListActions.GetLookupList) => action.payload),
    //need mergeMap, not switchMap as there will be multiple requests for this effect that we need to resolve
    mergeMap((payload) => {
      const api = payload.api;
      const objectType = payload.typeName;

      return this.domainObjectService.getListByMetaUrl(api)
        .pipe(
          mergeMap((response: IResponseBase) => {
            //take the results, mapped into IResponseBase and stuff them into their appropriate buckets
            //then trigger the action that will utilize the returned data (set list results)

            const dataObject = {
              storeName: objectType,
              parentId: -1,
              data: response.success ? response.data : null,
              listMetaData: response.success ? response.metaData : {},
              errorData: response.success ? null : response.errorData
            };
            return [{ type: SelectListActions.SelectionListActionTypes.SET_ENTITY_LIST, payload: dataObject }];
          }
          )
        );
    }),
    //catchError(this.handleError)
  )
  );


  /*********************************************************************************************************************************************
  GET_ENTITY_LIST_BY_ID Effect
  **********************************************************************************************************************************************/
entityListGetById$ = createEffect(() => this.actions$.pipe(
  ofType<SelectListActions.GetEntityListById>(SelectListActions.SelectionListActionTypes.GET_ENTITY_LIST_BY_ID),
  map((action: SelectListActions.GetEntityListById) => action.payload),
    mergeMap((payload) => {
      const entityName = payload.entityName;
      const methodName = payload.methodName;
      const id = payload.id;
      const listFilter = payload.listFilter;

      return this.domainObjectService.getByMethodById(upperFirst(entityName), methodName, id, listFilter)
        .pipe(
          mergeMap((response: IResponseBase) => {
            //take the results, mapped into IResponseBase and stuff them into their appropriate buckets
            //then trigger the action that will utilize the returned data (set list results)

            const dataObject = {
              storeName: payload.storeName ? payload.storeName : lowerFirst(entityName),
              parentId: payload.id,
              data: response.success ? response.data : null,
              listMetaData: response.success ? response.metaData : {},
              errorData: response.success ? null : response.errorData
            };

            return [{
              type: SelectListActions.SelectionListActionTypes.SET_ENTITY_LIST,
              payload: dataObject
            }];
          }
          )
        );
    }),
    //catchError(this.handleError)
  )
  );

  /*********************************************************************************************************************************************
GET_ENTITY_LIST_BY_METHODPARAMS Effect
**********************************************************************************************************************************************/
  entityListGetByMethodParams$ = createEffect(() => this.actions$.pipe(
    ofType<SelectListActions.GetEntityListByMethodParams>(SelectListActions.SelectionListActionTypes.GET_ENTITY_LIST_BY_METHODPARAMS),
    map((action: SelectListActions.GetEntityListByMethodParams) => action.payload),
    mergeMap((payload) => {
      const entityName: string = payload.entityName;
      const methodName: string = payload.methodName;
      const methodParameters: string = payload.methodParams;
      const listFilter = payload.listFilter;

      return this.domainObjectService.getListByMethod(entityName, methodName, methodParameters, listFilter)
        .pipe(
          mergeMap((response: IResponseBase) => {
            const dataObject = {
              storeName: payload.storeName ? payload.storeName : lowerFirst(entityName),
              parentId: payload.parentId,
              data: response.success ? response.data : null,
              listMetaData: response.success ? response.metaData : {},
              errorData: response.success ? null : response.errorData
            };

            return [{
              type: SelectListActions.SelectionListActionTypes.SET_ENTITY_LIST,
              payload: dataObject
            }];
          }
          )
        );
    }),
    //catchError(this.handleError)
    )
  );

  /*********************************************************************************************************************************************
   * handleError - TODO: modify to log
  **********************************************************************************************************************************************/
   handleError(error: Response | any) {
      let errMsg: string;

      if (error instanceof Response) {
        const body = error.json() || '';
        const err = body.hasOwnProperty('errorData') ? body['errorData'] : JSON.stringify(body);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      } else {
        errMsg = error.message ? error.message : error.toString();
      }
      console.error(errMsg);
      return Observable.throw(errMsg);
    }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
