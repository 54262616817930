<div [ngClass]="{ 'loading-mask': working$ | async }">
  <div *ngIf="listDefinition$ | async">
    <div *ngIf="isSummary" class="dynamic-list__summary">
      <div class="dynamic-list__summary--label">
        Total Outstanding:
      </div>
      <div class="dynamic-list__summary--text">
        {{ outstandingCount$ | async }}
      </div>
    </div>
    <fw-error-box *ngIf="!isSummary" [errorData]="errorData$ | async" [errors]="errors$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
    <fw-dynamic-list [vmListDefinition]="listDefinition$ | async"
                     [isSummary]="isSummary"
                     (customEvent)="onCustom($event)"
                     (pageEvent)="onPage($event)">

    </fw-dynamic-list>
  </div>

  <fw-error-box [errorData]="errorData$ | async" [errors]="errors$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>
