import { Injectable, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import * as Bowser from 'bowser';
import { BehaviorSubject } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomErrorHandlerService, HomErrorLevel } from 'hom-lib/hom-error-logger';

import { IAppConstants, appConstants } from '../../shared/constants';
import { HomLocalStorage } from '../enums/hom-local-storage.enums';
import { environment } from '../../environments/environment';
import { SmsEvent } from '../../app/sms/enums/sms.enums';
import * as fromRoot from '../../app/store/reducers/index';
import * as fromAuth from '../store/index';
import { UserPriviledgesService } from './user-priviledges.service';

export interface IHomServerAlert
{
  Name: string,
  Data: any
}

declare var $: any;
@Injectable()
export class HomHubService {

  public connectionEstablished$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public connectionRequested$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hubConnection: any;
  hubProxy: any;

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public datePipe: DatePipe,
    public emitterService: HomEventEmitterService,
    public ups: UserPriviledgesService,
    public errorHandlerService: HomErrorHandlerService,
    @Inject(appConstants) public myConstants: IAppConstants  ) {
    }

  initHubConnection() {
    this.connectionRequested$.next( true );
    this.hubConnection = $.hubConnection(environment.hubUrl);
    this.hubProxy = this.hubConnection.createHubProxy('homHub');
    this.hubConnection.logging = false;
    this.registerOnServerEvents();
    this.startConnection();
  }

  disconnectHub() {
    if (this.connectionEstablished$.value) {
      this.stopConnection();
    }
  }
  
  startConnection(): void {
    this.hubConnection.start()
      .done((data: any) => {
        this.initConnection();
      }).fail((error: any) => {
        this.connectionRequested$.next(false);
        this.errorHandlerService.handleError({name: 'SignalRError', message: 'Hom Hub could not connect -> ' + error}, HomErrorLevel.fatal);
      });
  }

  stopConnection(): void {
    this.hubConnection.stop();
    this.connectionRequested$.next(false);
    this.connectionEstablished$.next(false);
 }

  initConnection(): void {
    this.getUpdatedUserNotifications(this.ups.currentUserId$.value);
  }

  //Register the events from the server you want to react to
  registerOnServerEvents(): void {
    this.hubProxy.on('ReceiveHomAppNotification', (alertObject) => {
      // Loop through object
      let objectArray = alertObject['AlertData'] as IHomServerAlert[];
      objectArray.forEach(newAlert => {
        switch (newAlert.Name) {
          case 'ReceiveOpenNotificationsCountUpdate': {
            this.rootStore.dispatch(new fromAuth.SetHubOpenNotificationCount({ count: newAlert.Data }));
            break;
          }
          case 'ReceivePendingChangesCountUpdate': {
            this.rootStore.dispatch(new fromAuth.SetHubPendingChangesCount({ count: newAlert.Data }));
            break;
          }
          case 'ReceiveUnrespondedToContacts': {
            this.rootStore.dispatch(new fromAuth.SetHubUnrespondedToContacts({ model: newAlert.Data }));
            break;
          }
          case 'ReceiveApplicationVersion': {
            const doReload: boolean = localStorage.getItem(HomLocalStorage.HomVersion) !== newAlert.Data;
            if (doReload) {
              localStorage.setItem(HomLocalStorage.HomVersion, newAlert.Data);
              this.rootStore.dispatch(new fromAuth.SetApplicationVersion(doReload));
            }
            break;
          }
          case 'ReceivePendingUpdateDate': {
            const doRedirect: boolean = newAlert.Data !== '0001-01-01T00:00:00' && new Date() >= new Date(newAlert.Data);
            if (doRedirect) {
              this.rootStore.dispatch(new fromAuth.SetPendingUpdateDate(doRedirect))
            }
            break;
          }
          case 'ReceiveHasNewMessages': {
            let emitter: IHomEventEmitter = {
              requestor: 'hom-hub',
              event: SmsEvent.receivedNewText,
              action: '',
              data: newAlert.Data
            };
            this.emitterService.emitSmsEvent(emitter);
            this.rootStore.dispatch(new fromAuth.AddUnrespondedToContact({ model: newAlert.Data}));
            break;
          }
          case 'ReceiveHasSmsSendError': {
            let emitter: IHomEventEmitter = {
              requestor: 'hom-hub',
              event: SmsEvent.smsSendError,
              action: '',
              data: newAlert.Data
            };
            this.emitterService.emitSmsEvent(emitter);
            break;
          }
        }
      });
    });
    
    this.hubProxy.on('NotifyOfReconnection', () => {
      this.connectionRequested$.next(true);
      this.connectionEstablished$.next(false);
      this.initConnection();
    });

    this.hubProxy.on('NotifyConnectionEstablished', (val) => {
      this.connectionEstablished$.next(val);
      this.connectionRequested$.next(false);
    });
  }

  //Request what you want to listen to
  getUpdatedUserNotifications(providerUserId: number): void {
  const browser = Bowser.getParser(window.navigator.userAgent);
  this.hubProxy.invoke('GetHomAppNotification', providerUserId, browser.getBrowserName())
    .catch((error: any) => {
        this.errorHandlerService.handleError({name: 'SignalRError', message: 'Hom Hub Get Updated User Notifications Listener -> ' + error}, HomErrorLevel.fatal);
      });
  }
}    
