import { Injectable, Inject } from '@angular/core';

import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';

import { IListColumn, ListColumn, ListDefinition, IListFilter,  IDetailRouteParameter, ListFilter, ISearchTerm, IListButtonType, OrderTerm } from '../../../../fw/dynamic-list/interfaces/index';
import { IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';

import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';

import { AdminStore, AdminEvent, PrivilegeRuleEntitySpecifierType } from '../enums/admin.enums';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { ModalSizeType } from '../../../../fw/fw-modal/interfaces/i-modal';
import { appConstants, IAppConstants } from '../../../../shared/constants';
import { IProviderRole, IProviderUserPrivilegeViewModel } from '../../view-models';

import { UserPriviledgesService } from '../../../../auth/services/index';
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { IPrivilegeTemplate } from '../../view-models/i-privilege-template';
import { cloneDeep } from 'lodash';


export interface IAddUserAccessItemKey {
  id: number;
  key: string;
}
export interface IAddUserAccessItem {
  add: boolean;
  key: IAddUserAccessItemKey;
  item: any;
}

@Injectable()
export class AdminSecurityService {
  public allRoles: IProviderRole[] = [];
  public allGcs: IProviderUserPrivilegeViewModel[] = [];
  public allServices: IProviderUserPrivilegeViewModel[] = [];
  public allLocations: IProviderUserPrivilegeViewModel[] = [];
  public allBranches: IProviderUserPrivilegeViewModel[] = [];

  constructor(public userPriviledgesService: UserPriviledgesService,
    public dynamicListService: DynamicListService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public isLocationType(specifierType: number): boolean {
    return specifierType === PrivilegeRuleEntitySpecifierType.primaryProviderLocation
      || specifierType === PrivilegeRuleEntitySpecifierType.secondaryProviderLocation;
  }

  public initAllRolesAndPrivs(): void {
    this.allRoles = [];
    this.allGcs = [];
    this.allServices = [];
    this.allLocations = [];
    this.allBranches = [];
  }


  public loadUserSecurityListDefinition(): IListDefinition {
    const listColumns = this.loadUserSecurityListColumns();
    const listObjectLabel = 'Users Security';
    const listObjectController = 'ProviderUser';
    const listStoreName = AdminStore.userSecurity;
    const detailRoutePath = 'user-security-detail';
    const listRowKeyId = 'providerUserId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('lastName',true, 1), new OrderTerm('firstName', true, 2)];
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.searchTerm = [{ term: 'contact_contactId', value: null, searchType: SearchType.Equals, columnName: 'contact', fieldType: this.myConstants.dataTypeInt }];

    let listDefinition = this.dynamicListService.createListDefinition('userSecurityDetailOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'View/Edit Security for User',
        icon: 'fas fa-key',
        cssName: 'app-btn-icon--custom-4',
        eventName: AdminEvent.viewUserSecurity,
        enabled: true,
        enabledMethod: 'canViewUserSecurity',
        methodService: 'adminDynamicListService'
      },
      {
        title: 'Sync Ldap User',
        icon: 'fas fa-exchange',
        cssName: 'app-btn-icon--neutral',
        eventName: AdminEvent.syncLdapUser,
        enabled: true,
        enabledMethod: 'canSyncUser',
        methodService: 'adminDynamicListService'
      });


    return listDefinition;
  }

  loadUserSecurityListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('fullName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('membershipUserName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('supervisor');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('primaryLocations');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('primaryStores');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  setWorkingBranches(locationsIn: IProviderUserPrivilegeViewModel[], branchesIn: IProviderUserPrivilegeViewModel[]): IProviderUserPrivilegeViewModel[] {
    let locationsOut = cloneDeep(locationsIn);

    branchesIn.forEach(b => {
      if (b.parentEntityId) {
        //handle one branch associated to multiple locations (1516)
        b.parentEntityId.forEach(p => {
          let idx: number = locationsOut.findIndex(x => x.entityId == p);
          if (idx >= 0) {
            if (!locationsOut[idx].workingBranches) {
              locationsOut[idx].workingBranches = [];
            }
            locationsOut[idx].workingBranches.push(b);
          }
        });
      }
    });
    return locationsOut;
  }

  setOrphanBranches(locationsIn: IProviderUserPrivilegeViewModel[], branchesIn: IProviderUserPrivilegeViewModel[]): IProviderUserPrivilegeViewModel[] {
    let branchesOut: IProviderUserPrivilegeViewModel[] = [];
    branchesIn.forEach(b => {
      if (!b.parentEntityId) {
        branchesOut.push(b);
      } else {
        //handle one branch associated to multiple locations (1516) - just need one loc to be in list not be an orphan
        let pIdx: number = -1;
        b.parentEntityId.forEach(p => {
          const idx = locationsIn.findIndex(x => x.entityId == p);
          if (idx >= 0) {
            pIdx = idx;
          }
        });
        if (pIdx === -1) {
          branchesOut.push(b);
        }
      }
    });
    return branchesOut;
  }

  openSecurityEntityManager(listDefinition: IListDefinition, parentEntity: IProviderRole | IPrivilegeTemplate): void {
    const params = {
      rowIndex: -1,
      storeName: listDefinition.storeName,
      key: listDefinition.rowKeyId,
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: -1
    }

    const parentName: string = parentEntity.hasOwnProperty('providerRoleId')
      ? parentEntity['roleName'] + ' Role'
      : parentEntity['name'] + ' Template';
    const parentId: number = parentEntity.hasOwnProperty('providerRoleId')
      ? parentEntity['providerRoleId']
      : parentEntity['privilegeTemplateId'];

    this.modalService.open({
      title: 'Add '.concat(listDefinition.objectLabel, 's to ', parentName),
      path: 'related-entity-manager',
      id: parentId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false,
      isSubModal: true
    });
  }

  loadProvideRolesListDefinition(): IListDefinition {
    const listColumns = this.loadProvideRolesListColumns();
    const listObjectLabel = 'Provider Role';
    const listObjectController = 'ProviderRole';
    const listStoreName = AdminStore.providerRoles;
    const detailRoutePath = 'provider-role';
    const listRowKeyId = 'providerRoleId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('roleName')];

    let listDefinition = this.dynamicListService.createListDefinition('roleOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';
    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    //No Edit at this time
    let crudButtons: IListButtonType[] = [
      { type: ButtonType.delete, defaults: null }
    ];

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Associated Widgets',
        icon: 'fad fa-cogs',
        cssName: 'app-btn-icon--custom-1',
        eventName: AdminEvent.manageRoleWidgets,
        enabled: true
      },
      {
        title: 'Manage Associated Users',
        icon: 'fad fa-users',
        cssName: 'app-btn-icon--custom-2',
        eventName: AdminEvent.manageRoleUsers,
        enabled: true
      },
      {
        title: 'Manage Associated Privilege Templates',
        icon: 'fad fa-bring-front',
        cssName: 'app-btn-icon--custom-3',
        eventName: AdminEvent.manageRolePrivilegeTemplates,
        enabled: true
      }
    );

   return listDefinition;
  }

  loadProvideRolesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('roleName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadWidgetsForProviderRoleListDefinition(providerRoleId: number): IListDefinition {
    const listColumns = this.loadWidgetsForProviderRoleListColumns();
    const listObjectLabel = 'Widget';
    const listObjectController = 'WidgetProviderRole';
    const listStoreName = AdminStore.roleWidgets;
    const detailRoutePath = 'role-widgets-detail';
    const listRowKeyId = 'widgetProviderRoleId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('widget_widgetName')];

    let listDefinition = this.dynamicListService.createListDefinition('roleWidgetOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = providerRoleId;
    listDefinition.parentKey = 'providerRoleId';
    listDefinition.controllerMethod = 'ByRole';
    listDefinition.methodParameters = providerRoleId.toString();
    listDefinition.toolButtons.splice(0, 0,
      {
        title: 'Add New Widgets To Role',
        icon: 'fas fa-plus',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: AdminEvent.showRelatedEntityManager
      });

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.delete, defaults: null }
    ];

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.allowSaveFilter = false;
    return listDefinition;
  }

  loadWidgetsForProviderRoleListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('widgetName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadUsersForProviderRoleListDefinition(providerRoleId: number): IListDefinition {
    const listColumns = this.loadUsersForProviderRoleListColumns();
    const listObjectLabel = 'Provider User';
    const listObjectController = 'UserRoleViewModel';
    const listStoreName = AdminStore.roleUsers;
    const detailRoutePath = 'role-users-detail';
    const listRowKeyId = 'providerUserId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('providerUserName')];

    let listDefinition = this.dynamicListService.createListDefinition('roleUserOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = providerRoleId;
    listDefinition.parentKey = 'providerRoleId';
    listDefinition.controllerMethod = 'ByRole';
    listDefinition.methodParameters = providerRoleId.toString();
    listDefinition.toolButtons.splice(0, 0,
      {
        title: 'Add New Provider Users To Role',
        icon: 'fas fa-plus',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: AdminEvent.showRelatedEntityManager
      });

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.delete, defaults: null }
    ];

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.allowSaveFilter = false;

    return listDefinition;
  }

  loadUsersForProviderRoleListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('providerUserName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPrivilegeTemplatesForProviderRoleListDefinition(providerRoleId: number): IListDefinition {
    const listColumns = this.loadPrivilegeTemplatesForProviderRoleListColumns();
    const listObjectLabel = 'Privilege Template';
    const listObjectController = 'PrivilegeTemplateProviderRole';
    const listStoreName = AdminStore.rolePrivilegeTemplates;
    const detailRoutePath = 'role-privilege-template-detail';
    const listRowKeyId = 'privilegeTemplateProviderRoleId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('privilegeTemplateName')];

    let listDefinition = this.dynamicListService.createListDefinition('rolePrivilegeTemplateOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = providerRoleId;
    listDefinition.parentKey = 'providerRoleId';
    listDefinition.controllerMethod = 'ByRole';
    listDefinition.methodParameters = providerRoleId.toString();
    listDefinition.toolButtons.splice(0, 0,
      {
        title: 'Add New Privilege Templates To Role',
        icon: 'fas fa-plus',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: AdminEvent.showRelatedEntityManager
      });

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.delete, defaults: null }
    ];

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.allowSaveFilter = false;
    return listDefinition;
  }

  loadPrivilegeTemplatesForProviderRoleListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('privilegeTemplateName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPrivilegeTemplatesListDefinition(): IListDefinition {
    const listColumns = this.loadPrivilegeTemplateListColumns();
    const listObjectLabel = 'Privilege Template';
    const listObjectController = 'PrivilegeTemplate';
    const listStoreName = AdminStore.privilegeTemplates;
    const detailRoutePath = 'privilege-template';
    const listRowKeyId = 'privilegeTemplateId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('name')];

    let listDefinition = this.dynamicListService.createListDefinition('privTemplateOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';
    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Associated Provider Roles',
        icon: 'fad fa-theater-masks',
        cssName: 'app-btn-icon--custom-1',
        eventName: AdminEvent.managePrivTemplateProviderRoles,
        enabled: true
      },
      {
        title: 'Manage Associated Privileges',
        icon: 'fad fa-user-shield',
        cssName: 'app-btn-icon--custom-3',
        eventName: AdminEvent.managePrivTemplatePrivileges,
        enabled: true
      }
    );

    return listDefinition;
  }

  loadPrivilegeTemplateListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('name');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('description');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadProviderRolesForPrivilegeTemplateListDefinition(privilegeTemplateId: number): IListDefinition {
    const listColumns = this.loadProviderRolesForPrivilegeTemplateListColumns();
    const listObjectLabel = 'Provider Role';
    const listObjectController = 'PrivilegeTemplateProviderRole';
    const listStoreName = AdminStore.privilegeTemplateRoles;
    const detailRoutePath = '';
    const listRowKeyId = 'privilegeTemplateProviderRoleId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('providerRoleName')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = privilegeTemplateId;
    listDefinition.parentKey = 'privilegeTemplateId';
    listDefinition.controllerMethod = 'ByPrivilegeTemplate';
    listDefinition.methodParameters = privilegeTemplateId.toString();
    listDefinition.toolButtons.splice(0, 0,
      {
        title: 'Add New Provider Roles To Privilege Template',
        icon: 'fas fa-plus',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: AdminEvent.showRelatedEntityManager
      });

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.delete, defaults: null }
    ];

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.allowSaveFilter = false;

    return listDefinition;
  }

  loadProviderRolesForPrivilegeTemplateListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('providerRoleName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPrivilegesForPrivilegeTemplateListDefinition(privilegeTemplateId: number): IListDefinition {
    const listColumns = this.loadPrivilegesForPrivilegeTemplateListColumns();
    const listObjectLabel = 'Privilege';
    const listObjectController = 'PrivilegeTemplatePrivilege';
    const listStoreName = AdminStore.privilegeTemplatePrivileges;
    const detailRoutePath = 'privilege-template-privilege';
    const listRowKeyId = 'privilegeTemplatePrivilegeId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('privilegeTemplateName')];

    let listDefinition = this.dynamicListService.createListDefinition('privTempPrivOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = privilegeTemplateId;
    listDefinition.parentKey = 'privilegeTemplateId';
    listDefinition.controllerMethod = 'ByPrivilegeTemplate';
    listDefinition.methodParameters = privilegeTemplateId.toString();
    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    //No Edit at this time
   let crudButtons: IListButtonType[] = [
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Associated Rule Values',
        icon: 'fad fa-abacus',
        cssName: 'app-btn-icon--custom-1',
        eventName: AdminEvent.managePrivilegeValues,
        enabled: true,
        enabledMethod: 'canViewPrivFilters',
        methodService: 'adminDynamicListService'
      }
    );

    listDefinition.allowSaveFilter = false;
    return listDefinition;
  }

  loadPrivilegesForPrivilegeTemplateListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('privilegeId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('privilegeAccessLevel');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('privilegeDefinitionType');
    columnDefinitions.push(colDef);

    
    return columnDefinitions;
  }

  loadValuesForPrivilegeTemplatePrivilegeListDefinition(privilegeTemplatePrivilegeId: number): IListDefinition {
    const listColumns = this.loadValuesForPrivilegeTemplatePrivilegeListColumns();
    const listObjectLabel = 'Rule Value';
    const listObjectController = 'PrivilegeTemplatePrivilegeRuleValue';
    const listStoreName = AdminStore.privilegeTemplatePrivilegeValues;
    const detailRoutePath = 'ptp-value';
    const listRowKeyId = 'privilegeTemplatePrivilegeRuleValueId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('privilegeRuleId')];

    let listDefinition = this.dynamicListService.createListDefinition('ptprvOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = privilegeTemplatePrivilegeId;
    listDefinition.parentKey = 'privilegeTemplatePrivilegeId';
    listDefinition.controllerMethod = 'ByPrivilegeTemplatePrivilege';
    listDefinition.methodParameters = privilegeTemplatePrivilegeId.toString();
    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    //No Edit at this time
    let crudButtons: IListButtonType[] = [
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;
    return listDefinition;
  }

  loadValuesForPrivilegeTemplatePrivilegeListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('privilegeRuleId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('privilegeRuleEntitySpecifierName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPrivilegeFiltersListDefinition(): IListDefinition {
    const listColumns = this.loadPrivilegeFiltersListColumns();
    const listObjectLabel = 'Privilege Filter';
    const listObjectController = 'PrivilegeRuleEntitySpecifier';
    const listStoreName = AdminStore.privilegeRuleEntitySpecifiers;
    const detailRoutePath = 'privilege-filter';
    const listRowKeyId = 'privilegeRuleEntitySpecifierId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('privilegeRuleId')];

    let listDefinition = this.dynamicListService.createListDefinition('privFilterOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';
    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];

    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Show Where Used',
        icon: 'fad fa-chart-network',
        cssName: 'app-btn-icon--custom-1',
        eventName: AdminEvent.showFiltersWhereUsed,
        enabled: true
      }
    );

    return listDefinition;
  }

  loadPrivilegeFiltersListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('privilegeRuleId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('name');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('description');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadFilterWhereUsedListDefinition(privilegeRuleEntitySpecifierId: number): IListDefinition {
    const listColumns = this.loadFilterWhereUsedListColumns();
    const listObjectLabel = 'Where Used';
    const listObjectController = 'PrivilegeRuleEntitySpecifierViewModel';
    const listStoreName = AdminStore.privilegeFilterWhereUsed;
    const detailRoutePath = '';
    const listRowKeyId = 'uniqueId'; //handmade

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('privilegeTemplateName')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = privilegeRuleEntitySpecifierId;
    listDefinition.parentKey = 'privilegeRuleEntitySpecifierId';
    listDefinition.controllerMethod = 'BySpecifier';
    listDefinition.methodParameters = privilegeRuleEntitySpecifierId.toString();
    listDefinition.allowSaveFilter = false;
    return listDefinition;
  }

  loadFilterWhereUsedListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('privilegeTemplateName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('privilegeId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('privilegeRuleId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('specifierName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('privilegeAccessLevel');
    columnDefinitions.push(colDef);

    //colDef = new ListColumn('specifierDescription');
    //columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPrivilegesListDefinition(): IListDefinition {
    const listColumns = this.loadPrivilegesListColumns();
    const listObjectLabel = 'Privilege';
    const listObjectController = 'PrivilegeViewModel';
    const listStoreName = AdminStore.privileges;
    const detailRoutePath = '';
    const listRowKeyId = 'uniqueId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('privilegeId')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Show Where Used',
        icon: 'fad fa-chart-network',
        cssName: 'app-btn-icon--custom-1',
        eventName: AdminEvent.showPrivilegesWhereUsed,
        enabled: true
      }
    );

    return listDefinition;
  }

  loadPrivilegesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('privilegeId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('privilegeType');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPrivilegeWhereUsedListDefinition(privilegeId: string, uniqueId: number): IListDefinition {
    const listColumns = this.loadPrivilegeWhereUsedListColumns();
    const listObjectLabel = 'Where Used';
    const listObjectController = 'PrivilegeTemplatePrivilege';
    const listStoreName = AdminStore.privilegeWhereUsed;
    const detailRoutePath = '';
    const listRowKeyId = 'privilegeTemplatePrivilegeId'; 

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('privilegeTemplateName')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = uniqueId;
    listDefinition.parentKey = 'uniqueId';
    listDefinition.controllerMethod = 'ByPrivilege';
    listDefinition.methodParameters = privilegeId;
    listDefinition.allowSaveFilter = false;
    return listDefinition;
  }

  loadPrivilegeWhereUsedListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('privilegeTemplateName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('privilegeDefinitionType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('privilegeAccessLevel');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
