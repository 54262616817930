<div class="dispatch-day" [ngClass]="{ 'dispatch-day--instance': forView !== dispatchCalendarService.viewDay }" >
  <ng-container *ngIf="showHeader">
    <div *ngFor="let header of headers; let a = index" class="dispatch-day__header" [ngStyle]="{'grid-column': header.colStart.toString() + '/' + header.colEnd.toString() }">
      {{ header.label}}
    </div>
  </ng-container>

<ng-container *ngIf="(gridData$ | async)?.length ">
  <!--body-->
  <ng-container>
    <div *ngFor="let row of maxRows; let b = index"
         class="dispatch-day__row"
         [ngClass]="{ 'dispatch-day__row--last': (b+1) === maxRows.length, 'dispatch-day__row--holiday': isHoliday }"
         [ngStyle]="{'grid-row': (b+2).toString() + '/' + (b+3).toString() }">
    </div>
  </ng-container>
  <!--workorders-->
  <ng-container *ngFor="let row of gridData$ | async; let c = index">
    <div *ngIf="showWOPrevArrow(row.data)"
         class="dispatch-day__wo dispatch-day__wo__arrow dispatch-day__wo__arrow--left app-border-right-color--{{row.cssName}}"
         [ngStyle]="{ 'grid-row': row.rowStart.toString() + '/' + row.rowEnd.toString(), 'grid-column': (row.colStart-1).toString() + '/' + row.colStart.toString() }"></div>

    <div class="dispatch-day__wo app-bg-color--{{row.cssName }}"
         [ngStyle]="{'grid-row': row.rowStart.toString() + '/' + row.rowEnd.toString(), 'grid-column': row.colStart.toString() + '/' + row.colEnd.toString() }">
      <div class="dispatch-day__wo__item">
        <div *ngIf="includeTools" class="dispatch-day__wo__item--info">
          <fw-work-order-info [row]="row.data" [storeName]="storeName"></fw-work-order-info>
        </div>
        <div *ngIf="includeTools" class="dispatch-day__wo__item--tools">
          <quick-work-order-tools [title]="'Work Order Tools'" [row]="row.data" (customEvent)="onCustom($event)"></quick-work-order-tools>
        </div>
        <div class="dispatch-day__wo__item--text"
             [ngClass]="{ 'dispatch-day__wo__item--text--noTools': !includeTools }"
             title="{{row.data.workOrderId}} - {{ row.data.customerName}} - {{ row.data.serviceName}} - {{row.data.workOrderCity}} - {{row.data.scheduleStartDate | date:'short'}} - {{row.data.duration}} {{row.data.durationType}}">
          {{row.data.workOrderId}} - {{ row.data.customerName}} - {{ row.data.serviceName}} - {{row.data.workOrderCity}} - {{row.data.scheduleStartDate | date:'short'}} - {{row.data.duration}} {{row.data.durationType}}
        </div>
      </div>
    </div>

    <div *ngIf="showWONextArrow(row.data)"
         class="dispatch-day__wo dispatch-day__wo__arrow dispatch-day__wo__arrow--right  app-border-left-color--{{row.cssName}}"
         [ngStyle]="{ 'grid-row': row.rowStart.toString() + '/' + row.rowEnd.toString(), 'grid-column': row.colEnd.toString() + '/' + (row.colEnd+1).toString() }"></div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!(gridData$ | async).length && !isHoliday">
  <div class="dispatch-day__row"></div>
  <div class="dispatch-day__row"></div>
  <div class="dispatch-day__row dispatch-day__row--last"></div>
</ng-container>

<ng-container *ngIf="isHoliday">
  <div class="dispatch-holiday">
    <div class="dispatch-holiday__cloud-intro">
      <div class="dispatch-holiday__logo">HŌM  Holiday</div>
    </div>
  </div>
</ng-container>

</div>
