/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { ProjectService } from '../../services';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  selector: 'communication-event-actions',
  templateUrl: './communication-event-actions.component.html'
})
export class CommunicationEventActionsComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  constructor(
    public activeRoute: ActivatedRoute,
    public projectService: ProjectService,
    public dynamicDetailService: DynamicDetailService) {}

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
        const communicationEventId = +paramMap.get('id');
        this.listDefinition$.next(null);
        this.setDetailConfig(paramMap);
       this.listDefinition$.next(this.projectService.loadProjectCommunicationEventActionsListDefinition(communicationEventId));
        }
      );
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = +paramMap.get('portalEntityId'); //to get entity label from parent
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.showCancel = true;
    this.detailConfig$.next(params);
  }

}
