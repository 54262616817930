<fw-info [infoTitle]="'Row Information'" [customColor]="'#333333'">
  <div class="info-box">
    <div class="info-box__label">Created By:</div>
    <div class="info-box__value">{{ row.createdByName }}</div>
    <div class="info-box__label">Created On:</div>
    <div class="info-box__value">{{ row.createDate | date:'MM/dd/yyyy h:mm a' }}</div>
    <div class="info-box__label">Updated By:</div>
    <div class="info-box__value">{{ row.updatedByName }}</div>
    <div class="info-box__label">Updated On:</div>
    <div class="info-box__value">{{ row.updateDate | date:'MM/dd/yyyy h:mm a'  }}</div>
  </div>
</fw-info>
