import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: '/report-viewer',
  templateUrl: './report-viewer.component.html'
})

export class ReportViewerComponent implements OnDestroy {

  public documentHeight$: BehaviorSubject<number> = new BehaviorSubject(0);
  public stage$: BehaviorSubject<number> = new BehaviorSubject(0);
  public observer;


  public setContent(iframe: HTMLIFrameElement): void {
    const doc: Document = iframe.contentDocument,
      body: HTMLElement = doc.body,
      callback = () => {
        if (!this.stage$.value) return this.stage$.next(1);
        const height: number = doc.body.offsetHeight;
        if (height !== this.documentHeight$.value) this.documentHeight$.next(height);
      };
    this.observer = new MutationObserver(callback);
    this.observer.observe(body, { attributes: true, childList: true, subtree: true });
    //SCRIPTS
    doc.getElementById("windowing").remove();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

}
