<div *ngIf="form">
  <fw-button-title-bar [title]="title"
                       [entityLabel]="'Purchase Order'"
                       [entityStatus]="purchaseOrder.purchaseOrderStatus"
                       [canIEdit]="canIEdit"
                       [operation]="operation"
                       [disabled]="!form.dirty || !form.valid  || staleData"
                       [staleData]="staleData"
                       [showEdit]="true"
                       [showDelete]="false"
                       [showRefresh]="true"
                       [printUrl]="printUrl"
                       (customEvent)="onCustom($event)">
  </fw-button-title-bar>
  <form [formGroup]="form" novalidate class="form">
    <fw-dynamic-field [field]="mds.getFieldDefinition('branchProgram')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="branchPrograms">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('orderDate')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('orderStatus')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="orderStatuses">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('sum')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
  </form>
  <fw-component-footer [lastUpdate]="purchaseOrder && purchaseOrder.hasOwnProperty('updateDate') ? purchaseOrder.updateDate : '' "></fw-component-footer>
</div>
