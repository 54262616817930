import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomSignatureAction } from './hom-signature-action.enums';
import { HomSignatureType } from './hom-signature-type.enums';

@Component({
  selector: 'hom-signature-pad',
  templateUrl: './hom-signature-pad.component.html'
})
export class HomSignaturePadComponent implements OnInit, AfterViewInit {
  @ViewChild('signaturePad', { static: false }) public signaturePad: ElementRef<HTMLCanvasElement>;
  @Input() displayType: string;
  @Output() padEvent = new EventEmitter<IHomEventEmitter>();

  haveSignature$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  signPadEl: HTMLCanvasElement;
  signContext: any;
  isDrawing: boolean = false;

  constructor() { }

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onMousedown(event: MouseEvent) { this.onDrawStart(event); }

  @HostListener('mouseup', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  onMouseup(event: MouseEvent) { this.onDrawEnd(event); }

  @HostListener('mousemove', ['$event'])
  @HostListener('touchmove', ['$event'])
  onMousemove(event: MouseEvent) { this.onCanvasMovement(event); }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.signPadEl = this.signaturePad.nativeElement;
    this.signContext = this.signPadEl.getContext('2d');
    this.signContext.strokeStyle = "#333";
    this.signContext.lineWidth = 1;
    // to make the signature less blurry and the lines less fat
    this.signPadEl.width = this.signPadEl.clientWidth * 1.2;
    this.signPadEl.height = this.signPadEl.clientHeight * 1.2;
  }

  public onClear(): void {
    this.signContext.clearRect(0, 0, this.signPadEl.width, this.signPadEl.height);
    this.signContext.beginPath();
    this.haveSignature$.next(false);
  }

  public onSubmit(): void {
    const base64Sig = this.signPadEl.toDataURL('image/png');

    const event: IHomEventEmitter = {
      event: HomSignatureAction.Save,
      action: '',
      data: base64Sig,
      requestor: 'signature-pad'
    }
    this.padEvent.emit(event);
  }

  public goBack(): void {
    if (this.displayType === HomSignatureType.StandAlone) {
      const event: IHomEventEmitter = {
        event: HomSignatureAction.GoBack,
        action: '',
        data: null,
        requestor: 'signature-pad'
      }
      this.padEvent.emit(event);
    }
  }

  onDrawStart(ev: Event): void {
    const coords = this.relativeCoords(ev);

    if (!coords) {
      return;
    }

    this.signContext.beginPath();
    this.signContext.moveTo(coords.x, coords.y);
    this.isDrawing = true;
  }

  onCanvasMovement(ev: Event): void {
    if (!this.isDrawing) {
      return;
    }

    const coords = this.relativeCoords(ev);
    if (!coords) {
      return;
    }

    this.signContext.lineTo(coords.x, coords.y);
    this.signContext.stroke();

  }

  onDrawEnd(ev: Event): void {
    this.isDrawing = false;
    this.haveSignature$.next(true);
  }

  relativeCoords(event: any): { x: number, y: number } {
    let x: number = 0;
    let y: number = 0;

    const mEvent = <MouseEvent>event;
    if (!mEvent.offsetX || !mEvent.offsetY) {
      //handle touch event
      const tEvent = <TouchEvent>event;
      const touches = tEvent.touches;

      if (!touches || touches.length > 1) {
        return;
      }

      const touch = touches[0];
      const rect = event.target.getBoundingClientRect();
      x = touch.pageX - rect.x
      y = touch.pageY - rect.y;

    } else {
      x = mEvent.offsetX;
      y = mEvent.offsetY;
    }

    return {
      x: x / this.signPadEl.offsetWidth * this.signPadEl.width,
      y: y / this.signPadEl.offsetHeight * this.signPadEl.height
    };
  }

}
