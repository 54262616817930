import { Component, OnInit, Output, Inject, Input, OnChanges, SimpleChanges, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { IValueChanged } from '../../../../../fw/dynamic-list/services';
import { IWorkOrder, IService, IProviderLocation } from '../../../view-models/index';
import { IFieldDefinition } from '../../../../../fw/dynamic-forms';
import { IListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { UpdateObjectByIdModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { MetaDataService } from '../../../../../fw/dynamic-list/services/index';
import { UserPriviledgesService } from '../../../../../auth/services/index';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import { IErrorData } from '../../../../../shared/interfaces';

@Component({
  selector: 'work-order-general-information',
  templateUrl: './work-order-general-information.component.html',
  providers: [MetaDataService]

})
export class WorkOrderGeneralInformationComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() workOrder: IWorkOrder;
  @Input() displayFields: string[];
  @Input() storeName: string;
  @Input() canIEdit: boolean
  @Input() operation: string;
  @Input() locations: IProviderLocation[];
  @Input() services: IService[];
  @Input() staleData: boolean;
  @Input() errorData: IErrorData[];

  @Output() public customEvent: EventEmitter<IHomEventEmitter> = new EventEmitter();
  @Output() public locationChangeEvent: EventEmitter<{ id: number, listFilter: IListFilter }> = new EventEmitter();

  public title: string = 'Overview';
  public entityLabel: string = 'Work Order';
  public entityStatus: string;
  public services$: BehaviorSubject<IService[]> = new BehaviorSubject([]);
  public loading$: Observable<boolean>;
  public printUrl: string;
  public form: FormGroup;

  requestor: string = 'work-order-general-information';
  storeKeyName: string = 'workOrderId';
  controllerName: string = 'WorkOrder';
  subscription: Subscription = new Subscription();

  constructor(
    public mds: MetaDataService,
    public ups: UserPriviledgesService,
    public rootStore: Store<fromRoot.IState>,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case ButtonType.cancel:
        this.initForm();
        this.customEvent.emit(event);
        break;
      case ButtonType.save:
        this.save(event);
        break;
      default:
        this.customEvent.emit(event);
        break;
    }
  }

  ngOnInit() {
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(filter(x => x.requestor === this.storeName), map(x => x.show));

    this.subscription.add(this.mds.valueChanged$
      .subscribe((obj: IValueChanged) => {
        if (obj && obj.key === 'providerLocation') {
          const data: IProviderLocation = obj.value ? obj.value as IProviderLocation : null;
          this.locationSelected(data ? data.providerLocationId : 0);
        }
      }));

    this.initForm();
  }

  ngAfterViewInit(): void {
    this.setSelectedProviderLocation();
  }

  public initForm(): void {
    this.entityStatus = this.workOrder.workOrderStatusText;
    this.printUrl = this.ups.getPrintUrl(this.workOrder);
    this.mds.setFieldDefinitions(this.storeName);
    this.mds.loadSelectionLists(this.displayFields, this.operation, this.workOrder.project_projectId);
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.workOrder, this.operation);
  }

  //get the services for this location
  locationSelected(id: number): void {
    const def: IFieldDefinition = this.mds.getFieldDefinition('service');
    const filter: IListFilter = def && def.selectListDefinition ? def.selectListDefinition.listFilter : null;
    this.locationChangeEvent.emit({ id: id, listFilter: filter });
  }

  //collect the data and send back to the parent
  save(event: IHomEventEmitter): void {
    let saveEvent = event;
    let wo = this.setCommonProperties();
    wo.workOrderId = this.workOrder.workOrderId;
    wo.project_projectId = this.workOrder.project_projectId;
    const keyData: IKey = { storeName: this.storeName, parentId: this.workOrder.project_projectId, key: this.storeKeyName, id: this.workOrder.workOrderId }
    const emitter: IHomEventEmitter = { requestor: 'work-order-general-information', event: this.myConstants.emitterEventUpdate, action: '', data: null };
    const updateData = new UpdateObjectByIdModel(keyData, this.controllerName, 'Update', this.storeKeyName, this.workOrder.workOrderId, wo, null, emitter);
    saveEvent.data = updateData;
    this.customEvent.emit(saveEvent)
  }

  setCommonProperties() {
    let wo = { ...this.workOrder };
    const formData: IWorkOrder = this.form.getRawValue();

    wo.service_serviceId = formData.hasOwnProperty('service') && formData['service'].hasOwnProperty('serviceId') ? formData['service']['serviceId'] : null;
    wo.providerLocation_providerLocationId = formData.hasOwnProperty('providerLocation') && formData['providerLocation'].hasOwnProperty('providerLocationId') ? formData['providerLocation']['providerLocationId'] : null;
    wo.scheduleLocked = formData.hasOwnProperty('scheduleLocked') ? formData.scheduleLocked : false;
    return wo;
  }

  setSelectedProviderLocation(): void {
    if (!this.locations) {
      return;
    }
    const match: IProviderLocation = this.workOrder.providerLocation_providerLocationId
      ? this.locations.find(x => x.providerLocationId === this.workOrder.providerLocation_providerLocationId) : null;

    if (match) {
      const control = this.form.controls['providerLocation'];
      control.setValue(match);
    }
  }

  setSelectedService(): void {
    if (!this.services) {
      return;
    }
    this.mds.loadSelectionLists(['service'], this.operation, this.workOrder.project_projectId);
    const match: IService = this.workOrder.service_serviceId
      ? this.services.find(x => x.serviceId === this.workOrder.service_serviceId)
      : null;

    if (match) {
      const control = this.form.controls['service'];
      control.setValue(match);
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['locations'] && !(changes['locations'].firstChange)) {
      this.setSelectedProviderLocation();
    }

    if (changes['services'] && !(changes['services'].firstChange)) {
      this.services$.next(this.services);
      this.setSelectedService();
    }
    if (changes['operation'] && !(changes['operation'].firstChange)) {
      this.setSelectedProviderLocation();
      this.setSelectedService();
    }

    if (changes['workOrder'] && !(changes['workOrder'].firstChange)) {
      this.initForm();
      this.setSelectedProviderLocation();
      this.setSelectedService();
    }

    if (changes['errorData'] && !(changes['errorData'].firstChange)) {
      this.mds.setErrorMessages(this.errorData);
    }

  }
}
