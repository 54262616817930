import { createSelector} from '@ngrx/store';
  
import * as fromFeature from '../reducers/feature.reducer';
import * as fromDynamicObject from '../reducers/dynamic-object.reducer';

export const getAllDynamicObjects = createSelector(
  fromFeature.getAllDynamicData,
  allStores  => allStores.dynamicObjects
);

export const getObjectDataByType  = (name: string) =>  createSelector(
      getAllDynamicObjects,
      allObjects => allObjects[name]
);

export const getObjectByTypeById = (name: string, id: number) => createSelector(
  getObjectById(name, id),
  objData => {
    return objData === undefined || !objData[0] ? null : objData[0];
  } 
);
 
export const getObjectById  = (name: string, id: number) =>  createSelector(
    getObjectDataByType(name),
    (state: fromDynamicObject.IDynamicObject) => state.objData.filter(obj => obj.objectId === id)
  ); //returns specific instance of that object or undefined

export const getObjectDataById  = (name: string, id: number) =>  createSelector(
  getObjectById(name, id),
  objData => {
    return objData === undefined ? null : objData[0] && objData[0].data ? objData[0].data : null
    }
); //returns specific instance of that object or undefined

export const getObjectErrorsById  = (name: string, id: number) =>  createSelector(
  getObjectById(name, id),
  objData => {
    return objData === undefined ? [] : objData[0] && objData[0].errorData && objData[0].errorData.length > 0 ? objData[0].errorData : []
  }
);

export const getObjectErrorMessageById = (name: string, id: number) => createSelector(
  getObjectById(name, id),
  objData => {
    return objData === undefined ? '' : objData[0] && objData[0].message ? objData[0].message : ''
  }
);

export const getObjectEventById  = (name: string, id: number) =>  createSelector(
  getObjectById(name, id),
  objData=> {
    return objData === undefined ? [] : objData[0] && objData[0].event ? objData[0].event : null
    }
);

//Entity has stale Data
export const isSelectedEntityStale = (name: string, id: number) => createSelector(
  getObjectDataById(name, id),
  (objData) => {
    return !objData || !objData.data || objData.data.length === 0 ? false : objData.staleData;
  } 
);
