<div class="portal">
  <div class="portal__detail--item">
    <detail-contact-manager *ngIf="(project$ | async) as project"
                            [canIEdit]="canIEditProject$ | async"
                            [contactId]="contactId$ | async"
                            [isOrganization]="false"
                            [contactTypeId]="contactConstantsService.contactTypeIdCustomer"
                            [checkForMatch]="checkForMatch$ | async"
                            [linkedContact]="true"
                            [isPoImport]="false"
                            [allowUnlink]="true"
                            (crudEvent)="onCrudEvent($event)">
    </detail-contact-manager>
  </div>
</div>

