import { Component, OnInit, OnChanges, Inject, SimpleChanges,  Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IProject, Project, IWorkOrder, IWarrantyType, IWarrantySubType, Warranty, INote, Note, IProjectCreate } from '../../../view-models/index';
import { IFormDefinition, FormDefinition } from '../../../../../fw/dynamic-forms/index';

import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services/index'
import { ProjectService } from '../../services/project.service';
import { ProjectListStore } from '../../enums/project.enums';

@Component({
  selector: 'warranty-create',
  templateUrl: './warranty-create.component.html',
  providers: [ { provide: 'mdsInstance1', useClass: MetaDataService },
               { provide: 'mdsInstance2', useClass: MetaDataService } ]
})
export class WarrantyCreateComponent implements OnInit  {
  @Input() project: IProject;
  @Input() workOrder: IWorkOrder;
  @Input() displayFields: string[];
  @Input() warrantySubTypes: IWarrantySubType[];

  @Output() public warrantyTypeChangeEvent = new EventEmitter<number>();
  @Output() public createEvent = new EventEmitter();
  @Output() public cancelEvent = new EventEmitter<IHomEventEmitter>();

  public operation: string;
  public createForm: FormGroup;
  public noteForm: FormGroup;
  noteStoreName: string;
  public noteFormDef: IFormDefinition;
  public noteFields = ['noteSubject', 'noteText', 'isPrivate'];

  public warrantyTypes$: BehaviorSubject<IWarrantyType[]> = new BehaviorSubject([]);
  subscription: Subscription = new Subscription();

  constructor(@Inject('mdsInstance1') public projectMds: MetaDataService,
    @Inject('mdsInstance2') public noteMds: MetaDataService,
    public projectService: ProjectService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public cancel(): void {
    this.cancelEvent.emit();
  }

  public create(): void {
    let warranty = new Warranty();
    let note = new Note(); //set this to null if no note
    const formData: IProject = this.createForm.value;
    const noteData: INote = this.noteForm.value;
    const subTypeId = formData.hasOwnProperty('warrantySubTypeName') && formData.warrantySubTypeName.hasOwnProperty('warrantySubTypeId')
      ? formData.warrantySubTypeName['warrantySubTypeId']
      : -1;
    const matchFound = this.warrantySubTypes ? this.warrantySubTypes.find(x => x.warrantySubTypeId == subTypeId) : null;
    warranty.warrantyWorkOrder_workOrderId = this.workOrder.workOrderId;
    warranty.warrantyType_warrantyTypeId = formData.warrantyTypeName['warrantyTypeId'];
    warranty.warrantySubType_warrantySubTypeId = matchFound ? subTypeId : null;
    warranty.isUrgent = formData.hasOwnProperty('warrantyIsUrgent') ? formData.warrantyIsUrgent : false;
    
    if (noteData.noteSubject && noteData.noteText) {
      note.noteSubject = noteData.noteSubject;
      note.noteText = noteData.noteText;
      note.isPrivate = noteData.isPrivate;
    } else {
      note = null;
    }
    let data: IProjectCreate = { customer_contactId: this.project.customer_contactId, branch_branchId: this.workOrder.warrantyBranchId, warranty: warranty, note: note };
    let event: IHomEventEmitter = {
      requestor: 'warranty-create', event: this.myConstants.emitterEventCreate, action: '', data: data
    };
    this.createEvent.emit(event);
  }

  ngOnInit(): void {
    this.operation = this.myConstants.operationTypeCreate;
    this.noteFormDef = new FormDefinition('Note Detail', false, false, ProjectListStore.projectNotes); // 'userNote, installerNote, etc'
    this.noteStoreName = ProjectListStore.projectNotes;
    this.initForm();
    this.subscription.add(this.projectMds.valueChanged$
      .subscribe((obj: IValueChanged) => {
        if (obj && obj.key === 'warrantyTypeName') {
          if (obj.value) {
            const data: IWarrantyType = obj.value as IWarrantyType;
            this.warrantyTypeSelected(data.warrantyTypeId);
          } else {
            this.warrantyTypeChangeEvent.emit(0);
          }
        }
      }));
  }

  public initForm(): void {
    let project = new Project();
    let note = new Note();
    this.projectMds.setFieldDefinitions(ProjectListStore.workOrderWarranties);
    this.noteMds.setFieldDefinitions(ProjectListStore.projectNotes);
    this.projectMds.loadSelectionLists(this.displayFields, this.operation, project.projectId);
    const types = this.projectMds.getSelectItems('warrantyTypeName');
    this.warrantyTypes$.next(types ? types : []);
    this.createForm = this.projectMds.loadDynamicFormGroup(this.displayFields, project, this.myConstants.operationTypeCreate);
    this.noteForm = this.noteMds.loadDynamicFormGroup(this.noteFields, note, this.myConstants.operationTypeCreate);
  }

  warrantyTypeSelected(id: number): void {
    this.warrantyTypeChangeEvent.emit(id);
  }
}
