import { Component, OnInit, OnDestroy,   Inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {  map  } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

import { InstallerWorkCrew  } from '../../../view-models/index';
import { UpdateObjectByIdModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';

//store actions, reducers, interfaces
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IDynamicListState,  IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { HomEventEmitterService } from '../../../../../../../hom-lib/hom-event-emitter';

@Component({
  selector: 'work-crew-general-information',
  templateUrl: './work-crew-general-information.component.html'
})
export class WorkCrewGeneralInformationComponent implements OnInit, OnChanges, OnDestroy  {
  @Input()  installerId: number;
  @Input() workCrewId: number;
  @Input() canIEdit: boolean = false;

  public displayFields: string[] = ['installerWorkCrewName', 'isActive', 'doesWarranty'];
  public title: string = 'Overview';
  public entityLabel: string = 'Work Crew';
  public myStoreName: string = 'installerWorkCrews';
  public requestor: string = 'work-crew-general-information';
  public storeKeyName: string = 'installerWorkCrewId';
  public controllerName: string = 'InstallerWorkCrew';
  installerCrews: IListObjectData = null;;
  originalObject: InstallerWorkCrew = null;
  subscription: Subscription = new Subscription();

  constructor(
    public emitterService: HomEventEmitterService,
    public store: Store<fromStore.IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case ButtonType.save:
        this.updateRecord(event);
        break;
      default:
        break;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit() {
    this.subscription.add(this.store.pipe(select(fromDynamicList.getListByType(this.myStoreName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId ==  this.installerId)))
      .subscribe((state: IListObjectData) => {
        this.installerCrews = state ? Object.assign({}, state) : state;
        if (this.installerCrews && this.installerCrews.data) {
          this.originalObject = this.installerCrews.data.find(row => row[this.storeKeyName] == this.workCrewId);
        }
      }));      
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['workCrewId'] && !(changes['workCrewId'].firstChange)) {
      this.workCrewId = changes['workCrewId'].currentValue;
      if (this.installerCrews && this.installerCrews.data) {
        this.originalObject = this.installerCrews.data.find(row => row[this.storeKeyName] == this.workCrewId);
      }
    }
  }

  updateRecord(e: IHomEventEmitter) {
      let iwc =  this.setCommonProperties(e.data);
      iwc.installerWorkCrewId = this.workCrewId;
      const keyData: IKey = { storeName: this.myStoreName, parentId: this.installerId, key: this.storeKeyName, id: this.workCrewId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
      const updateData = new UpdateObjectByIdModel(keyData, this.controllerName, 'Update', this.storeKeyName, this.workCrewId, iwc, null, emitter);
      this.store.dispatch(new DynamicListActions.UpdateObjectByIdList({ updateData }));
    }

  setCommonProperties(formData: InstallerWorkCrew) {
    let iwc = new InstallerWorkCrew();
    iwc.installer_installerId = this.installerId

    iwc.installerWorkCrewName = formData.installerWorkCrewName;
    iwc.isActive = formData.isActive;
    iwc.doesWarranty = formData.doesWarranty;

    iwc.rowVersion = this.originalObject.rowVersion;

    iwc.updateDate = new Date().toDateString();

    return iwc;
  }

}
