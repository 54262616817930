import { Injectable, Inject } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ITabRequest } from '../../../../fw/fw-shared/components/fw-app-tabs/interfaces';
import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { TabsService } from '../../../../fw/fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';
import { UserPriviledgesService } from '../../../../auth/services';
import * as fromRoot from '../../../store/reducers/index';
import * as fromAuth from '../../../../auth/store/index';
import { Store, select } from '@ngrx/store';
import { HomDataUtility } from '../../../../shared/services';

@Injectable({
  providedIn: 'root'
})
export class SharedDashboardService  {

  constructor(public tabsService: TabsService,
    public userPriviledgesService: UserPriviledgesService,
    public dataUtils: HomDataUtility,
    @Inject(appConstants) public myConstants: IAppConstants,
    public rootStore: Store<fromRoot.IState>) { }

  openDashboardTab(portalId: number, label: string, componentName: string, requestor: string): void {
    const data: ITabRequest = {
      text: label,
      portalId: portalId,
      portalEntityId: -1,
      level2EntityName: '',
      level2EntityId: -1,
      level2Text: null,
      level2ComponentName: componentName,
      level3EntityName: null,
      level3EntityId: 0,
      level3Text: null
    },
      event: IHomEventEmitter = {
        requestor: requestor,
        event: this.myConstants.emitterEventTabLevel1Open,
        action: '',
        data: data
      };

    this.tabsService.emitTabEvent(event);    
  }

  /*
   * Opens a new tab to display the rendered document.  User still needs to click print from there.
   * If no print url, will open ''
  */
  printDoc(row: any, urlPropertyName: string): void {
    const printUrl: string = this.dataUtils.getUrlByName(row, urlPropertyName);
    window.open(printUrl || '', '_blank').focus();
  }


  canFlagCustomer() {
    return this.rootStore.pipe(
      select(fromAuth.canIFlagCustomer)
    );
  }

}
