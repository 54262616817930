<fw-component-title [title]="step === 3 ? 'Create New PO Or Link To Existing PO?' : 'Create Purchase Order'"
                    [entityLabel]="step > 1 ? ((gc$ | async).generalContractorName + ', ' + program.programServiceName + ', ' + (poDefaults$ | async).poNumPrefix + this.poNumber) : ''">
</fw-component-title>
<div [ngClass]="{'component__detail-box': step !== 3}">
  <div [ngClass]="{ 'loading-mask': loading$ | async }">
    <div *ngIf="step !== 3" [ngStyle]="{'display': step === 1 ? 'block' : 'none'}">
      <form [formGroup]="form" class="form-wrapper form-wrapper--below-title-bar" novalidate *ngIf="form">
        <fw-dynamic-field [field]="projectMds.getFieldDefinition('generalContractorId')"
                          [form]="form"
                          [operation]="operation"
                          [selectItems]="generalContractors">
        </fw-dynamic-field>

        <fw-dynamic-field *ngIf="(gc$ | async)" [field]="projectMds.getFieldDefinition('branch')"
                          [form]="form"
                          [selectItems]="branches"
                          [operation]="!(gc$ | async) ? myConstants.operationTypeDetails : operation">
        </fw-dynamic-field>
      </form>
      <div class="cpo-con">
        <purchase-order-create *ngIf="(branchPrograms$ | async) && (poDefaults$ | async)"
                               [branchPrograms]="branchPrograms$ | async"
                               [isWarranty]="false"
                               [storeName]="'projectPurchaseOrders'"
                               [displayFields]="poDisplayFields"
                               [showButtons]="false"
                               [poDefaults]="poDefaults$ | async"
                               (changeEvent)="changeEvent($event)">
        </purchase-order-create>
      </div> 
    </div>
    <div *ngIf="step !== 3" [ngStyle]="{'display': step === 1 ? 'none' : 'block'}">
      <contact-manager *ngIf="step !== 3" #contactManager
                       [contactId]="0"
                       [operationIn]="operation"
                       [isOrganization]="false"
                       [contactTypeId]="contactConstantsService.contactTypeIdCustomer"
                       [canIEdit]="true"
                       [checkForMatch]="true"
                       [allowText]="true"
                       (managerEvent)="onManagerEvent($event)">
      </contact-manager>
    </div>
    <fw-error-box [errorData]="errorData$ | async" [ignoreKey]="true"></fw-error-box>
    <div class="app-btn-box" [ngClass]="{'po-create-wizard-btns app-btn-box--center': step === 3}">
      <button class="app-btn app-btn--cancel"
              (click)="cancel()">CANCEL</button>
      <button *ngIf="step !== 3" class="app-btn app-btn--primary"
              (click)="previous()"
              [disabled]="step === 1 || step === 3">PREVIOUS</button>
      <button *ngIf="step !== 3" class="app-btn app-btn--primary"
              (click)="next()"
              [disabled]="step !== 1 || !(gc$ | async) || !branch || !program || poNumber === null">NEXT</button>
      <button *ngIf="step !== 3" class="app-btn app-btn--primary"
              [disabled]="!contactManager || (!this.existingContactId && !(contactManager.isFormValid$ | async)) || step === 3"
              (click)="create()">CREATE PROJECT</button>
      <button *ngIf="step === 3" class="app-btn app-btn--primary"
              (click)="goToPo()">
        OPEN PURCHASE ORDER
      </button>
      <button class="app-btn app-btn--primary" *ngIf="step === 3"
              (click)="reset()">
        CREATE NEW PURCHASE ORDER
      </button>
    </div>
  </div>
</div>

