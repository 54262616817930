/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IProject } from '../../../view-models/index';
import { ProjectService } from '../../services/project.service';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getObjectDataById } from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { ProjectObjectStore } from '../../enums/project.enums';


@Component({
  selector: 'originating-project-summary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './originating-project-summary.component.html'
})
export class OriginatingProjectSummaryComponent implements OnInit {
  public project$: Observable<IProject>;
  projectId: number;

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromFeature.IAllDynamicData>,
    public projectService: ProjectService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get('id');
      this.getData();
    });
  }

  getData(): void {
    this.project$ = this.store.pipe(
      select(getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)));
  }
}
