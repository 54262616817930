import { Component, OnInit,  Input, Output, EventEmitter } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IProviderUserPrivilegeViewModel, IUserRoleViewModel } from '../../../view-models';
import { AdminEvent } from '../../enums/admin.enums';

@Component({
  selector: 'user-access-item',
  templateUrl: './user-access-item.component.html'
})
export class UserAccessItemComponent implements OnInit  {
  @Input() item: IProviderUserPrivilegeViewModel | IUserRoleViewModel;
  @Input() name: string;
  @Input() customClass: string;

  @Output() public deleteEvent = new EventEmitter<IHomEventEmitter>();

  requestor: string = 'user-access-item';

  constructor() { }

  public delete(): void {
    this.deleteEvent.emit({ requestor: this.requestor, event: AdminEvent.deleteUserAccess, action: null, data: this.item });
  }

  ngOnInit() {
  }

}
