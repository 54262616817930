import { Directive, ElementRef, HostListener } from '@angular/core';
import { TabsComponent } from '../components/fw-app-tabs/fw-app-tabs.component';

@Directive({selector: '[apptabs]'}) export class AppTabsDirective {

  @HostListener('window:resize') onresize() {
    this.instance.initResponsive();
  }

  constructor(public element: ElementRef,
    public instance: TabsComponent ) {
        this.instance.element = element;
  }
}
