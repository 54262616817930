import { Component, Inject,  Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IHomeDepotMeasure } from '../../../view-models/index';

@Component({
  selector: 'home-depot-measures',
  templateUrl: './home-depot-measures.component.html'
})
export class HomeDepotMeasuresComponent implements OnInit   {
  @Input() externalData: IHomeDepotMeasure[];
  @Input() canEdit: boolean;
  @Input() loading: boolean;

  @Output() public viewMeasure = new EventEmitter<IHomEventEmitter>();
  @Output() public useDefault = new EventEmitter();
  @Output() public selectEvent = new EventEmitter<IHomEventEmitter>();
  @Output() public deleteEvent = new EventEmitter<IHomEventEmitter>();

  constructor(
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit(): void {
  }

  public selectItem(idx: number, id: number): void {
    let data: IHomeDepotMeasure[] = this.getData(idx, id);
    let event: IHomEventEmitter = { requestor: 'home-depot-measures', event: this.myConstants.emitterEventSelect, action: id === -1 ? 'bulk' : 'line', data: data};
    this.selectEvent.emit(event);
  }

  public  unSelectItem(idx: number, id: number): void {
    let data: IHomeDepotMeasure[] = this.getData(idx, id);
    let event: IHomEventEmitter = { requestor: 'home-depot-measures', event: this.myConstants.emitterEventDelete, action: id === -1 ? 'bulk' : 'line', data: data};
    this.deleteEvent.emit(event);
  }

  public openLink(idx: number, id: number): void {
    //launch work order packet in a new tab
    let data: IHomeDepotMeasure[] = this.getData(idx, id);
    if (!data.length) {
      return;
    }
    let event: IHomEventEmitter = { requestor: 'home-depot-measures', event: 'view-measure', action: id === -1 ? 'bulk' : 'line', data: data[0] };
    this.viewMeasure.emit(event);
  }

  public switchToDefault(): void {
    this.useDefault.emit();
  }

    //id = -1, select bulk/all lines
  getData(idx: number, id: number): IHomeDepotMeasure[] {
   const row: IHomeDepotMeasure = this.externalData[idx];
    let data: IHomeDepotMeasure[] = [];
    if (id === -1) {
      data = this.externalData.filter(x => x.calcDate === row.calcDate);
    } else {
      data.push(row);
    }
    return data;
  }

}
