import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FwModule } from '../../../fw/fw.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../shared/shared.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { UserPriviledgesService } from '../../../auth/services/index';
import { AdminDashboardService } from './services/admin-dashboard.service';

import { AdminDashboardRoutingModule } from './admin-dashboard-routing.module';
import { PricingSharedModule } from '../pricing-shared/pricing-shared.module';
import { FileUploadModule } from '../../file-upload/file-upload.module';

//components
import {
  ProgramServiceXmlMappingComponent,
  BranchXmlMappingComponent,
  SkuXmlMappingComponent,
  UnitMeasureXmlMappingComponent,
  CustomerSurveyComponent
} from './components/index';

//containers
import {
  AdminDashboardComponent,
  ErroredLineItemsComponent,
  BranchXmlMappingsComponent,
  UnitMeasureXmlMappingsComponent,
  ProgramServiceXmlMappingsComponent,
  SkuXmlMappingsComponent,
  CustomerSurveysComponent,
  CustomerSurveyImportComponent
} from './containers/index';

//guards
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';
import { SkuTypeResolver } from './resolvers/sku-type-resolver.service';
import { SkuStatusResolver } from './resolvers/sku-status-resolver.service';
import { SurveyResolver } from './resolvers/survey-resolver.service';
import { WorkOrderSurveyResolver } from './resolvers/work-order-survey-resolver.service';


@NgModule({
  declarations: [
    AdminDashboardComponent,
    ErroredLineItemsComponent,
    BranchXmlMappingComponent,
    BranchXmlMappingsComponent,
    UnitMeasureXmlMappingComponent,
    UnitMeasureXmlMappingsComponent,
    ProgramServiceXmlMappingComponent,
    ProgramServiceXmlMappingsComponent,
    SkuXmlMappingComponent,
    SkuXmlMappingsComponent,
    CustomerSurveysComponent,
    CustomerSurveyComponent,
    CustomerSurveyImportComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      FwModule,
      FwSharedModule,
      SharedModule,
      AdminDashboardRoutingModule,
      PricingSharedModule,
      PortalSharedModule,
      FileUploadModule
  ],
  providers: [
    IsCurrentGuard,
    UserPriviledgesService,
    AdminDashboardService,
    SkuTypeResolver,
    SkuStatusResolver,
    SurveyResolver,
    WorkOrderSurveyResolver
  ]
})

export class AdminDashboardModule {}
