import { Component, OnInit, Input, Inject, OnChanges, SimpleChanges  } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHomEventEmitter, HomEventEmitterService } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IListDefinition, IDocumentActionRequest, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces/index';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IEntityDocument, IEntityDocumentActionViewModel } from '../../../view-models/index_two';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { INoteRequiredData } from '../../../portal-shared/interfaces/i-note-required-data';
import { FileUploadType, DocumentTypeName, DocumentTypeActionTypeName } from '../../../../file-upload/enums/file-upload.enums';
import { ProjectEvent } from '../../../project/enums/project.enums';
import { IKey, DeleteObjectModel } from '../../../../../fw/dynamic-list/store/interfaces';

import { getListByType, IDynamicListState, IListObjectData, DeleteObjectByUrlList } from '../../../../../fw/dynamic-list/store';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { DomainObjectService } from '../../../../../shared/services';
import { RequiredDocumentService } from '../../services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { UserPriviledgesService } from '../../../../../auth/services/index';
import { ProjectService } from '../../../project/services';
import { CrmFormsEvent } from '../../../project/containers/crm-form-entry/crm-forms.enums';

@Component({
  selector: 'project-required-documents',
  templateUrl: './project-required-documents.component.html'
})
export class ProjectRequiredDocumentsComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() canIEdit: boolean;  //sans project status check
  @Input() workOrderId: number = 0;
  @Input() forScheduler: boolean = false;
  @Input() isSummary: boolean = false;
  @Input() detailActive: boolean = true;

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public ready$: Observable<boolean>;
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public outstandingCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  subscription = new Subscription();
  needCount: boolean = true;


  constructor(
    public store: Store<fromFeature.IAllDynamicData>,
    public rds: RequiredDocumentService,
    public modalService: ModalService,
    public userPriviledgesService: UserPriviledgesService,
    public dos: DomainObjectService,
    public projectService: ProjectService,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case ProjectEvent.uploadDocument:
        const data: IDocumentActionRequest = event.data;
        if (data.entityDocument.documentTypeName === DocumentTypeName.measure) {
          this.uploadMeasure(event.data);
        } else {
          this.uploadFile(event.data);
        }
        break;
      case ProjectEvent.signDocument:
        this.captureSignature(event.data);
        break;
      case ProjectEvent.generateFile:
      case ProjectEvent.markActionComplete:
        this.completeAction(event);
        break;
      case ProjectEvent.showFile:
        this.showFile(event.data);
        break;
      case ProjectEvent.deleteEntityDocument:
        this.handleDelete(event.data);
        break;
      case ProjectEvent.fillForm:
        this.fillCrmForm(event.data);
        break;
      default:
        break;
    }
  }

  public onShowChild(val: boolean): void {
    this.errorData$.next([]);
    this.errors$.next('');
  }

  public onPage(): void {
    this.errorData$.next([]);
    this.errors$.next('');
  }

  ngOnInit() {
    this.newRequest();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.detailActive && this.isSummary && this.needCount) {
      this.getOutstandingCount();
    }
  }

  newRequest(): void {
    let listDefinition =
      this.forScheduler
        ? this.rds.loadSchedulerWORequiredDocumentsListDefinition(this.projectId, this.workOrderId)
        : this.rds.loadProjectRequiredDocumentsListDefinition(this.projectId, this.canIEdit);
    this.listDefinition$.next(listDefinition);

    //listen for changes
    this.subscription.add(this.store.pipe(select(getListByType(listDefinition.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((state: IListObjectData) => {
        this.needCount = true;

        if (state && state.event && state.event.event === this.myConstants.emitterEventDelete) {
          if (state.event.action === DocumentTypeName.leadExceptionForm || state.event.action === DocumentTypeName.leadSafeForm) {
            this.projectService.emitTaskReloadEvent('project-required-docs', this.projectId);
          }
        }
      }
    ));
  }

  uploadFile(data: IDocumentActionRequest): void {
    const entityDocumentId: number = data.entityDocument.entityDocumentId;
    const entityIdLabel: string = data.entityDocument.entityIdLabel;
    const entityName: string = data.entityDocument.entityName;
    const docType: string = data.entityDocument.documentTypeName;
    const titlePrefix = docType === DocumentTypeName.measure ? 'Finalize ' : 'Upload ';

    const otherActions: IEntityDocumentActionViewModel[] = data.entityDocument && data.entityDocument.actions
      ? data.entityDocument.actions.filter(x => x.entityDocumentActionId !== data.selectedAction.entityDocumentActionId
        && x.actionTypeName !== DocumentTypeActionTypeName.automatic
      )
      : null;

    let routeName: string = otherActions && otherActions.length > 0 ? 'doc-upload-mgr/' : 'file-upload/';
    let path: string = routeName + entityDocumentId.toString()
      + '/' + this.projectId.toString()
      + '/' + data.selectedAction.entityDocumentActionId.toString()
      + '/' + (this.forScheduler ? FileUploadType.poMeasure : FileUploadType.projectRequired);
    if (!otherActions) {
      path = path + '/'
    }

    this.modalService.open({
      title: titlePrefix.concat(docType, ' For ', entityName, ' ', entityIdLabel),
      path: path,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false,
      isSubModal: this.forScheduler
    });
  }

  uploadMeasure(data: IDocumentActionRequest): void {
    const entityDocumentId: number = data.entityDocument.entityDocumentId;
    const mainPath: string = this.forScheduler ? 'schedule-po-measures/' : 'po-measures/';

    this.modalService.open({
      title: 'Finalize Measure for Purchase Order'.concat(' ', data.entityDocument.entityIdLabel.toString()),
      path: mainPath + entityDocumentId.toString()
        + '/' + data.entityDocument.entityPkid.toString()
        + '/' + this.projectId.toString()
        + '/' + data.selectedAction.entityDocumentActionId.toString()
        + '/' + (this.forScheduler ? FileUploadType.poMeasure : FileUploadType.projectRequired),
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false,
      isSubModal: this.forScheduler
    });
  }

  completeAction(event: IHomEventEmitter): void {
    this.working$.next(true);
    this.errorData$.next([]);
    this.errors$.next('');

    const methodName: string = event.event === ProjectEvent.generateFile ? 'GenerateFile' : 'MarkComplete';

    const params: string = event.data.entityDocument.entityDocumentId.toString() + '?actionId=' + event.data.selectedAction.entityDocumentActionId.toString();
    this.subscription.add(this.dos.updateByMethodParams('EntityDocument', methodName, params)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.rds.dispatchRequiredDocumentsGet(this.projectId, this.canIEdit, this.forScheduler, this.workOrderId);
          this.projectService.emitTaskReloadEvent('project-required-documents', this.projectId);
       } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));
  }

  captureSignature(data: IDocumentActionRequest): void {
    const path: string = data.entityDocument.documentTypeName === DocumentTypeName.leadExceptionForm || data.entityDocument.documentTypeName === DocumentTypeName.leadSafeForm
      ? 'lswp-mgr/' + data.entityDocument.entityDocumentId.toString() + '/' + data.selectedAction.entityDocumentActionId.toString() + '/' + this.projectId.toString()
      : 'sign-doc/' + data.entityDocument.entityDocumentId.toString() + '/' + data.selectedAction.entityDocumentActionId.toString() + '/1';

    this.modalService.open({
      title: 'Sign'.concat(' ', data.entityDocument.entityLabel ),
      path: path,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false,
      isSubModal: this.forScheduler
    });
  }

  showFile(data: IEntityDocument): void {
    if (!data || !data.fileUrl) return;

    var w = window.open(data.fileUrl, '_blank');
    w.document.title = data.documentTypeName;
    w.focus();
  }

  fillCrmForm(data: IDocumentActionRequest): void {
    if (!data ) return;

    this.modalService.open({
      title: data.selectedAction.actionName.concat(' ', data.entityDocument.entityLabel ),
      path: 'crm-forms-manager/' + data.entityDocument.latestVersionId.toString() + '/' + this.projectId,
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false
    });
  }

  reloadList(): void {
    this.projectService.dispatchRequiredDocumentsGet(this.projectId, true);
    this.projectService.emitTaskReloadEvent('project-required-documents', this.projectId);
  }
  //require note on delete
  handleDelete(data: IEntityDocument): void {
    if (this.userPriviledgesService.canDeleteWithoutNote$.value
      || (this.userPriviledgesService.canManageLSWP$.value && (data.documentTypeName === DocumentTypeName.leadSafeForm || data.documentTypeName === DocumentTypeName.leadExceptionForm))
      ) {
      //just delete the doc
      if (!data.metaData.crud || !data.metaData.crud.deleteUrl || data.metaData.crud.deleteUrl.length <= 0) {
        this.errors$.next('DeleteUrl is undefined.  Delete cancelled.');
      } else {
        if (data.metaData.crud.deleteUrl && data.metaData.crud.deleteUrl.length > 0) {
          const listDef = this.listDefinition$.value;
          const keyData: IKey = { storeName: listDef.storeName, parentId: listDef.parentId, key: listDef.rowKeyId, id: data.entityDocumentId }
          const event: IHomEventEmitter = { requestor: listDef.detailRoutePath, event: this.myConstants.emitterEventDelete, action: data.documentTypeName, data: null };

          const deleteData = new DeleteObjectModel(keyData, data.metaData.crud.deleteUrl, event);
          this.store.dispatch(new DeleteObjectByUrlList({ deleteData }));
        }
      }
    } else {
      //require a note
      var requiredData: INoteRequiredData = {
        entityType: 'EntityDocument',
        entityPkId: data.entityDocumentId,
        entityLabel: data.documentTypeName.concat(' for ', data.entityName, ' ', data.entityIdLabel)
      }

      const woId: number = data.entityName.toLowerCase() === 'work order' ? data.entityPkid : 0;
      const poId: number = data.entityName.toLowerCase() === 'purchase order' ? data.entityPkid : 0;

      this.modalService.open({
        title: 'Enter a Delete Reason',
        path: 'delete-reason/' + this.projectId.toString() + '/' + poId.toString() + '/' + woId.toString(),
        optionalParams: null,
        onExit: null,
        castExit: false,
        hasTabs: false,
        state: requiredData
      });
    }
  }

  getOutstandingCount(): void {
    this.working$.next(true);
    this.errorData$.next([]);
    this.errors$.next('');

    this.subscription.add(this.dos.getByMethodById('EntityDocument', 'CountRequiredOutstanding', this.projectId)
      .subscribe((response: IResponseBase) => {
       if (response.success) {
          this.outstandingCount$.next(response.data);
          this.needCount = false;
        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));
  }
}
