import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy, OnChanges, SimpleChanges, ViewChild,  EventEmitter, Output } from '@angular/core';
import { Router, RouterOutlet, ActivationStart, Event, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';
import { orderBy } from 'lodash';

import * as fromRoot from '../../../../store/reducers/index';
import { getMyPortalComponentNames, getTabContainerByName } from '../../../../../auth/store/auth.selectors';

import { IWidgetManager } from '../../../portal/interfaces/index'; 
import { IPortalTabContainer, IPortalTab } from '../../../../portals/view-models/index';
import { IIcon } from '../../interfaces/index';

@Component({
  selector: 'portal-just-tabs-layout-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './portal-just-tabs-layout-container.component.html'
})

//Wrapper Requirements:
//  •	Retrieve widgets I can use for this portal
//  Renders only tabs
export class PortalJustTabsLayoutContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() portalId: number;
  @Input() portalEntityId: number;
  @Input() title: string;
  @Input() info: string = '';
  @Input() subTitle: string = '';
  @Input() tabContainerName: string;
  @Input() titleIcon: IIcon = null;

  @ViewChild(RouterOutlet) public outlet: RouterOutlet;
  @Output() public tabsLoadedEvent = new EventEmitter<boolean>();

  public myTabs$: BehaviorSubject<IPortalTab[]> = new BehaviorSubject([]);
  public maxTabs: number = 0;
  public portalLevel: number;
  availableWidgets: IWidgetManager[];
  subscription: Subscription = new Subscription();

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>) {
  }


  ngOnInit() {
    //this.router.events
    //  .subscribe(e => {
    //    if (e instanceof ActivationStart && e.snapshot.outlet === "portal-just-tabs") {
    //      this.outlet.deactivate();
    //    }
    //});
      this.activeRoute.paramMap.subscribe(paramMap => {
        this.newRequest();
      });
    }

  ngOnChanges(changes: SimpleChanges) {
    if ( (changes['portalId'] && !changes['portalId'].isFirstChange()) ) {
      this.newRequest();
    }
  }

  newRequest() {
    this.subscription.add(this.getTabContainers().subscribe((container) => {
      if (container) {
        this.setMyTabs(container);
      }
    }));
  }

  getTabContainers(): Observable<IPortalTabContainer> {
    return this.rootStore.pipe(select(getTabContainerByName(this.tabContainerName)), filter(container => container !== null), take(1),
      map((container: IPortalTabContainer) => {
        return container;
      }));
  }

  setMyTabs(container: IPortalTabContainer) {
    this.subscription.add(this.rootStore.pipe(select(getMyPortalComponentNames(this.portalId)),
      filter((componentNames: string[]) => componentNames !== null && componentNames.length > 0),
      take(1))
      .subscribe((componentNames: string[]) => {
        this.maxTabs = container.defaultMaxTabs;
        this.portalLevel = container.portalLevel ;
        if (componentNames) {
          const tabs: IPortalTab[] = orderBy(container.portalTabs, ['tabOrder'], ['asc']);
          let newTabs: IPortalTab[] = [];
          tabs.forEach(tab => {
            if (!tab.securityDependent || (tab.securityDependent && componentNames.includes(tab.componentName))) {
              newTabs.push(tab);
            }
          });
          this.myTabs$.next(newTabs);
          this.tabsLoadedEvent.emit(true);
       }
      }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
