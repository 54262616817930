import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { ICommunicationDocumentType} from '../../../portals/view-models';
import { SmsMessages } from '../../enums/sms.enums';

@Component({
  selector: 'sms-document-types',
  templateUrl: './sms-document-types.component.html'
})
export class SmsDocumentTypesComponent implements OnInit {
  @Input() documentTypes: ICommunicationDocumentType[];
  @Input() errorMessage: string;

  @Output() public select = new EventEmitter<ICommunicationDocumentType>();

  public maxInfo: string;

  constructor() {
  }

  ngOnInit(): void {
    this.maxInfo = SmsMessages.maxDocs;
  }

  selectDoc(id: number): void {
    const type: ICommunicationDocumentType = this.documentTypes.find(x => x.communicationDocumentTypeId == id);
    if (type) {
      this.select.emit(type);
    }
  }

}
