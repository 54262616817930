<div *ngIf="uploadFile" class="hom-file">
  <div *ngIf="deleteMode" class="hom-file__delete-mode dynamic-list__deleting">
    <div class="dynamic-list__deleting--text">Confirm Deletion: {{ uploadFile.name }}</div>
  </div>
  <ng-container *ngIf="!deleteMode">
    <div #thumbnail
         class="hom-file__thumbnail"
         [attr.rotate]="(rotateValue$ | async)"
         [ngStyle]="{'background-image': uploadFile.previewUrl ? 'url(' + uploadFile.previewUrl + ')' : null, 'transform': uploadFile.previewUrl ? 'rotate(' + (90 * (+(rotateValue$ | async))) + 'deg)' : null}">
      <span *ngIf="!uploadFile.previewUrl">{{ uploadFile.name.split('.')[uploadFile.name.split('.').length-1] }}</span>
    </div>
    <button *ngIf="uploadFile.previewUrl"
            title="Rotate Image"
            class="hom-file__rotate app-btn-icon app-btn-icon--custom-3"
            (click)="rotate()">
      <i class="fad fa-undo fa-flip-horizontal"></i>
    </button>
    <div class="hom-file__caption">
      <input #imageCaption
             value="{{uploadFile.name}}"
             placeholder="Add caption..."
             (keyup)="changeCaption(imageCaption.value)" />
    </div>
    <div *ngIf="showEntityIdInput" class="hom-file__entityId">
      <input #entityId
             class="hom-file__entityId--input"
             digitOnlyMask
             type="text"
             value=""
             [placeholder]="entityIdPlaceHolder"
             (input)="setEntityId(entityId.value)" />
    </div>
    <div *ngIf="!entityDocument" class="hom-file__include-in">
      <fw-check-box #checkBox [config]="cbConfig"
                    [isChecked]="entityDocument ? entityDocument.includeInWorkOrderPacket : false"
                    [isDisabled]="!canIncludeInWOPacket"
                   (change)="changeInclude(checkBox.value)"></fw-check-box>
    </div>
  </ng-container>
  <button *ngIf="!deleteMode"
          title="Upload"
          class="hom-file__upload app-btn-icon app-btn-icon--secondary"
          [disabled]="deleteMode || (uploadDisabled$ | async) || uploadFile.errorData ? true : null"
          (click)="uploadItem()">
    <i class="fas fa-upload"></i>
  </button>
  <button *ngIf="deleteMode"
          title="Cancel Delete"
          class="hom-file__cancel app-btn-icon app-btn-icon--neutral"
          (click)="cancelDelete()">
    <i class="fas fa-ban"></i>
  </button>
  <button title="Delete"
          class="hom-file__delete app-btn-icon app-btn-icon--danger"
          (click)="removeItem()">
    <i class="fas fa-trash"></i>
  </button>

</div>
<fw-error-box *ngIf="uploadFile.errorData" [errorData]="uploadFile.errorData" [onForm]="false" [ignoreKey]="true"></fw-error-box>
