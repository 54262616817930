<div *ngIf="(detailConfig$ | async) as detailConfig">
  <po-import-line-item *ngIf="( poImportLine$ | async) && (skusPrices$ | async)"
                       [poImportLine]="poImportLine$ | async"
                       [skuPrices]="skusPrices$ | async"
                       [storeName]="storeName"
                       [operation]="operation"
                       [detailConfig]="detailConfig"
                       (customEvent)="onCustom($event)">
  </po-import-line-item>
  <fw-error-box  [errors]="errors$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>
