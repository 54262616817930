import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { AdminEvent, AdminStore } from '../../enums/admin.enums';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { getSelectedRecord } from '../../../../../fw/dynamic-list/store/index';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'service-group-services',
  templateUrl: './service-group-services.component.html'
})
export class ServiceGroupServicesComponent implements OnInit, OnDestroy {

  public listDefinition: IListDefinition;
  serviceGroupId: number;
  serviceGroupName: string;
  subscription: Subscription = new Subscription();

  constructor(
    public adminProviderService: AdminProviderService,
    public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.serviceGroupId = +paramMap.get('id');
      this.listDefinition = this.adminProviderService.loadServiceGroupServicesListDefinition(this.serviceGroupId);
      
      this.subscription.add(this.store.pipe(
        select(getSelectedRecord(AdminStore.serviceGroups, -1, 'serviceGroupId', this.serviceGroupId)))
        .subscribe(entity => {
          this.serviceGroupName = entity.serviceGroupName;
        })
      );
      
    });
  }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case AdminEvent.launchServiceGroupsWizard:
        this.adminProviderService.openRelatedEntityManager(this.listDefinition, this.serviceGroupName, this.serviceGroupId);
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
