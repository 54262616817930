import {ModuleWithProviders, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedRoutingModule } from './shared-routing.module';
import { PopoverModule } from './components/fw-popover/fw-popover.module';

import {
  //LoadingComponent,
  AppActionResultComponent,
  CrewWorkOrderCompatibilityComponent,
  InfoComponent,
  LegendComponent,
  NoteContextComponent,
  LoadingPageComponent
} from './components/index';
import * as fromSelLists from './store/selectionLists/selectionLists.reducer';
import * as fromLoadingIndiator from './store/loadingIndicator/loadingIndicator.reducers';
import { SelectionListEffects } from './store/selectionLists/selectionLists.effects';
import { SelectionListService } from './services/selection-list.service';


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    PopoverModule,
    SharedRoutingModule,
    StoreModule.forFeature('selectionLists', fromSelLists.selListReducer),
    StoreModule.forFeature('loadingIndicator', fromLoadingIndiator.loadingIndicatorReducer),
    EffectsModule.forFeature([SelectionListEffects]),
  ],
  declarations: [
    //LoadingComponent,
    AppActionResultComponent,
    CrewWorkOrderCompatibilityComponent,
    InfoComponent,
    LegendComponent,
    NoteContextComponent,
    LoadingPageComponent
  ],
  exports: [
    //LoadingComponent,
    AppActionResultComponent,
    CrewWorkOrderCompatibilityComponent,
    InfoComponent,
    LegendComponent,
    PopoverModule,
    NoteContextComponent,
    LoadingPageComponent
  ],
  providers: [
    SelectionListService
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}

