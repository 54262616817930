import { Injectable, Inject, OnDestroy } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { HomLocalStorage } from '../../auth/enums/hom-local-storage.enums';
import { ITabRequest } from '../../fw/fw-shared/components/fw-app-tabs/interfaces';
import { IPortalTab, IPortalTabContainer } from '../portals/view-models';
import { appConstants, IAppConstants } from '../../shared/constants';

import * as fromRoot from '../../app/store/reducers/index';
import { getTabContainerByName } from '../../auth/store/auth.selectors';

import { TabsService } from '../../fw/fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';
import { UserPriviledgesService } from '../../auth/services';

@Injectable()
export class RouteToCrmService implements OnDestroy {

  constructor(
    public tabsService: TabsService,
    public userPriviledgesService: UserPriviledgesService,
    public rootStore: Store<fromRoot.IState>,
    @Inject(appConstants) public myConstants: IAppConstants
  ) { }

  public tabRequest: ITabRequest;
  public params: any;
  public handleUnauthenticatedRequest: boolean;

  subscription: Subscription = new Subscription();
  portalTabs: IPortalTab[];

  public init(): void {
    this.subscription.add(this.rootStore.pipe(
      select(getTabContainerByName('app-tabs')),
      filter(container => container !== null), take(1))
      .subscribe((container: IPortalTabContainer) => {
        this.portalTabs = container.portalTabs;
      })); 
  }

  public initRouteToCrm(): void {
    const routeToCrm: string = localStorage.getItem(HomLocalStorage.HomRouteToCrm);
    if (!routeToCrm) return;
    const params = JSON.parse(localStorage.getItem(HomLocalStorage.HomRouteToCrm)).params;
    if (this.userPriviledgesService.authenticated$.value) {
      this.createTabRequest(params);
      this.handleRouting();
    } else {
      this.params = params;
    }
    localStorage.removeItem(HomLocalStorage.HomRouteToCrm);
  }

  createTabRequest(params): void {
    const portalTab = this.portalTabs.find(x => x.entityName.toLowerCase() === params.entityName.toLowerCase());
    this.tabRequest = {
      text: params.installerName ? params.installerName.replace("HASH", "#") : params.entityId,
      portalId: portalTab.managerPortalId,
      portalEntityId: +params.entityId,
      level2EntityName: params.childEntityName || null,
      level2EntityId: +params.childEntityId || null,
      level2Text: params.childLabel || params.childEntityId || null,
      level3EntityName: null,
      level3EntityId: null,
      level3Text: null,
      fromModal: false
    };
  }

  public createPortalTab(): IPortalTab {
    let portalTab = cloneDeep(this.portalTabs.find(x => x.entityName.toLowerCase() === this.params.entityName.toLowerCase()));
    portalTab.tabLabel = portalTab.entityName + " " + (this.params.installerName ? this.params.installerName.replace("HASH", "#") : this.params.entityId);
    portalTab.routeName = this.tabsService.setItemRoute(portalTab, portalTab.portalLevel, this.params.entityId);
    portalTab.entityId = +this.params.entityId;
    portalTab.isDefault = true;
    portalTab.closeable = true;
    if (this.params.childEntityId) {
      this.createTabRequest(this.params);
      this.handleUnauthenticatedRequest = true;
    }
    this.params = null;
    return portalTab;
  }

  public handleRouting(): void {
    let event: IHomEventEmitter = {
      requestor: 'route-to-crm-service',
      event: this.myConstants.emitterEventTabLevel1Open,
      action: '',
      data: this.tabRequest
    };
    this.tabsService.emitTabEvent(event);
    this.tabRequest = null;
    if (this.handleUnauthenticatedRequest) this.handleUnauthenticatedRequest = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
