import { Component, Input, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IRadioButton } from './interfaces/i-radio-button';

const RADIOBUTTON_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioButtonComponent),
  multi: true
};

@Component({
  selector: 'fw-radio-button',
  templateUrl: './fw-radio-button.component.html',
  providers: [RADIOBUTTON_VALUE_ACCESSOR]
})
export class RadioButtonComponent implements OnInit, ControlValueAccessor  {
  @Input() groupName: string;
  @Input() buttons: IRadioButton[];
  @Input() isDisabled: boolean = false;
  @Input() isHorizontal: boolean = true;

  ngOnInit() {}

  public onClick(value: string) {
    this.onChange(value);
  }

  onChange = (val: string) => { };

  onTouch: Function = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
  }

}
