  /*
Common Methods Used by Manager Dashboard components
*/
import { Injectable, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListColumn, ListColumn, IListFilter, ListFilter, IListButtonType, OrderTerm } from '../../../../fw/dynamic-list/interfaces/index';
import { IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';
import { IAppConstants, appConstants } from '../../../../shared/constants';
import { ManagerDashStore, ManagerDashEvent } from '../enums/manager-dashboard.enums';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { UserPriviledgesService } from '../../../../auth/services';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { IApprovalQueueTransaction } from '../../../../shared/interfaces';
import { ITabRequest } from '../../../../fw/fw-shared/components/fw-app-tabs/interfaces';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { DynamicListMethod } from '../../../../fw/dynamic-list/enums/dynamic-list.enum';

import { TabsService } from '../../../../fw/fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { DynamicListService } from '../../../../fw/dynamic-list/services';

@Injectable()
export class ManagerDashboardService {

  constructor(public userPriviledgesService: UserPriviledgesService,
    public tabsService: TabsService,
    public modalService: ModalService,
    public dynamicListService: DynamicListService,
    public datePipe: DatePipe,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public loadCommunicationEventLogsListDefinition(): IListDefinition {
    const listColumns = this.loadCommunicationEventLogsListColumns();
    const listObjectLabel = 'Communication Event Logs';
    const listObjectController = 'CommunicationEventLog';
    const listStoreName = ManagerDashStore.dashCommunicationEventLogs; 
    const detailRoutePath = 'dash-communication-event-log';
    const listRowKeyId = 'communicationEventLogId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [
      { term: 'resolved', value: ['false'], searchType: SearchType.Equals, columnName: 'resolved', fieldType: this.myConstants.dataTypeBool }
    ];
    defaultListFilter.orderTerm = [new OrderTerm('logDate', false)];

    let listDefinition = this.dynamicListService.createListDefinition('communicationEventLogOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';

    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Mark Resolved',
        icon: 'fas fa-times-square',
        cssName: 'app-btn-icon--danger',
        enabled: true,
        eventName: ManagerDashEvent.markResolved
      }
    );

    return listDefinition;
  }

  loadCommunicationEventLogsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    
    let colDef = new ListColumn('communicationEventType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('contactName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('contactType');
    columnDefinitions.push(colDef);
    colDef.visibleOnSmall = false;

    colDef = new ListColumn('entity');
    columnDefinitions.push(colDef);
    colDef.visibleOnSmall = false;
    
    colDef = new ListColumn('entityPrimaryKeyId');
    columnDefinitions.push(colDef);
    colDef.visibleOnSmall = false;

    colDef = new ListColumn('logMessage');
    columnDefinitions.push(colDef);
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;

    colDef = new ListColumn('communicationText');
    columnDefinitions.push(colDef);
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;

    colDef = new ListColumn('providerUser');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('logDate');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadDashPendingApprovalListDefinition(): IListDefinition {
    const listColumns = this.loadDashPendingApprovalListColumns();
    const listObjectLabel = 'Pending Approval';
    const listObjectController = 'ApprovalQueueTransaction';
    const listStoreName = ManagerDashStore.dashPendingApprovals;
    const detailRoutePath = 'dash-pending-approval';
    const listRowKeyId = 'approvalQueueTransactionId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [
      { term: 'expiresOn', value: this.datePipe.transform(new Date(), 'MM/dd/yyyy'), searchType: SearchType.GreaterThan, columnName: 'expiresOn', fieldType: this.myConstants.dataTypeDate },
      { term: 'entityPrimaryKeyId', value: "0", searchType: SearchType.GreaterThan, columnName: 'entityPrimaryKeyId', fieldType: this.myConstants.dataTypeInt },
      { term: 'status', value: 'Pending', searchType: SearchType.Equals, columnName: 'status', fieldType: this.myConstants.dataTypeString },
   ];
    defaultListFilter.orderTerm = [new OrderTerm('createDate')];

    let listDefinition = this.dynamicListService.createListDefinition('pendingApprovalOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm.filter(x => x.term !== 'expiresOn');

    listDefinition.showFilter = true;

    //readonly
    const canIApprove: boolean = this.userPriviledgesService.canApproveApprovalQueue$.getValue();

    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    if (canIApprove) {
      crudButtons.push({ type: ButtonType.edit, defaults: null });
    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Go to Work Order',
        icon: 'fas fa-share-square',
        cssName: 'app-btn-icon--custom-3',
        enabled: true,
        eventName: ManagerDashEvent.openLink
      },
      {
        title: 'Approve',
        icon: 'fas fa-check-square',
        cssName: 'app-btn-icon--success',
        enabled: true,
        enabledMethod: 'canIApproveApprovalQueue',
        methodService: 'managerDashDynamicListService',
        eventName: ManagerDashEvent.approvePendingChange
      },
      {
        title: 'Reject',
        icon: 'fas fa-times-square',
        cssName: 'app-btn-icon--danger',
        enabled: canIApprove,
        eventName: ManagerDashEvent.denyPendingChange
      }
    );
    listDefinition.rowStatus = {
      cssMethod: 'getIsApprovalWindowOpenCss',
      methodService: 'managerDashDynamicListService'
    };
    return listDefinition;
  }

  loadDashPendingApprovalListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('entityName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('transactionContext');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('createDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('approvalStartDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('approvalRequestUser');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('expiresOn');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);


    return columnDefinitions;
  }

  public loadDashInventoryOrdersListDefinition(): IListDefinition {
    const listColumns = this.loadDashInventoryOrdersListColumns();
    const listObjectLabel = 'Inventory Orders';
    const listObjectController = 'ShipperPurchaseOrder';
    const listStoreName = ManagerDashStore.dashShipperPos;
    const detailRoutePath = 'dash-shipper-po-items';
    const listRowKeyId = 'shipperPurchaseOrderId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('orderDate', false)];

    let listDefinition = this.dynamicListService.createListDefinition('shipperPOItemsOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';

    listDefinition.rowStatus = {
      cssMethod: 'getIsOrderQtyOutOfRangeCss',
      methodService: 'managerDashDynamicListService'
    };

    listDefinition.showFilter = true;
    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadDashInventoryOrdersListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('shipperName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('purchaseOrderNumber');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('locationName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('orderDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('expectedDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);


    return columnDefinitions;
  }

  public loadDashInventoryOrderItemsListDefinition(shipperPurchaseOrderId: number): IListDefinition {
    const listColumns = this.loadDashInventoryOrderItemsListColumns();
    const listObjectLabel = 'Inventory Order Items';
    const listObjectController = 'ShipperPurchaseOrderItem';
    const listStoreName = ManagerDashStore.dashShipperPoItems;
    const detailRoutePath = '';
    const listRowKeyId = 'shipperPurchaseOrderItemId';
    const defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = shipperPurchaseOrderId;
    listDefinition.parentKey = 'shipperPurchaseOrderId';
    listDefinition.controllerMethod = 'ByShipperPurchaseOrder';
    listDefinition.methodParameters = shipperPurchaseOrderId.toString();

    //readonly, no crud buttons
    listDefinition.showFilter = false;

    listDefinition.rowStatus = {
      cssMethod: 'getIsOrderItemQtyOutOfRangeCss',
      methodService: 'managerDashDynamicListService'
    };

    return listDefinition;
  }

  loadDashInventoryOrderItemsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('materialName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('suggestedAmount');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('quantity');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadDashInventoryNeedsListDefinition(startDate: string, endDate: string, providerLocationShipperInventorySetupId: number): IListDefinition {
    const listColumns = this.loadDashInventoryNeedsListColumns();
    const listObjectLabel = 'Inventory Need';
    const listObjectController = 'InventoryRollupViewModel';
    const listStoreName = ManagerDashStore.dashInventoryNeeds;
    const detailRoutePath = 'dash-inventory-work-orders';
    const listRowKeyId = 'uniqueId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;

    let listDefinition = this.dynamicListService.createListDefinition('inventoryWosOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'GetInventoryNeeds';
    listDefinition.methodParameters = providerLocationShipperInventorySetupId.toString() + '?startDate=' + startDate + '&endDate=' + endDate;

    listDefinition.groupSelect = {
      title: 'Include In Order',
      enabled: true,
      eventName: ManagerDashEvent.includeInOrder,
      allowSelectAll: true
    };

    listDefinition.showFilter = false;
    const enabledDefaults: ICustomButton = {
      title: '', icon: '', cssName: '', enabled: true,
      enabledMethod: DynamicListMethod.alwaysEnabled, eventName: ''
    };
    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: enabledDefaults }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadDashInventoryNeedsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('startDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('locationName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderItemName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('orderAmount');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadDashInventoryWorkOrdersListDefinition(uniqueId: number, providerLocationId: number, startDate: string, materialName: string): IListDefinition {
    const listColumns = this.loadDashInventoryWorkOrdersListColumns();
    const listObjectLabel = 'Work Orders Needing Inventory';
    const listObjectController = 'WorkOrder';
    const listStoreName = ManagerDashStore.dashInventoryWos;
    const detailRoutePath = '';
    const listRowKeyId = 'workOrderId';

    const defaultListFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false,
      currentPage: 1,
      searchTerm: [],
      orderTerm: null
    };

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = uniqueId;
    listDefinition.parentKey = 'uniqueId';
    listDefinition.controllerMethod = 'ByInventoryNeed';
    listDefinition.methodParameters = '?providerLocationId=' + providerLocationId.toString() + '&startDate=' + startDate + '&materialName=' + materialName;

    //readonly, no crud buttons
    listDefinition.showFilter = false;

    return listDefinition;
  }

  loadDashInventoryWorkOrdersListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('workOrderId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('project');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('branchName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderServiceName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderScheduleText');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  openLink(data: IApprovalQueueTransaction): void {
    if (this.modalService.opened) this.modalService.close();
    const tabRequest: ITabRequest = {
      text: data.aggregateRootEntityName.concat(' ', data.aggregateRootPrimaryKeyId.toString()),
      portalId: data.entityPortalId,
      portalEntityId: data.aggregateRootPrimaryKeyId,
      level2EntityName: data.parentEntityName,
      level2EntityId: data.parentPrimaryKeyId, 
      level2Text: data.parentPrimaryKeyId.toString(),
      level3EntityName: null,
      level3EntityId: null,
      level3Text: null
    }
    const event: IHomEventEmitter = {
      requestor: 'pending-approvals',
      event: this.myConstants.emitterEventTabLevel1Open,
      action: '',
      data: tabRequest
    };
    this.tabsService.emitTabEvent(event);
  }

  public loadOutstandingDocumentsListDefinition(): IListDefinition {
    const listColumns = this.loadOutstandingDocumentsListColumns();
    const listObjectLabel = 'Outstanding Required Documents';
    const listObjectController = 'OutstandingEntityDocumentViewModel';
    const listStoreName = ManagerDashStore.dashOutstandingDocuments;
    const detailRoutePath = '';
    const listRowKeyId = 'parentEntityPkid';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('parentEntityPkid', true)];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';

    return listDefinition;
  }

  loadOutstandingDocumentsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('parentEntityPkid');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    columnDefinitions.push(colDef);
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;

    colDef = new ListColumn('locationName');
    columnDefinitions.push(colDef);
    colDef.visibleOnSmall = false;

    colDef = new ListColumn('projectStartDate');
    columnDefinitions.push(colDef);
    colDef.visibleOnSmall = false;

    colDef = new ListColumn('anyPosClosed');
    columnDefinitions.push(colDef);
    colDef.visibleOnSmall = false;

    colDef = new ListColumn('documentTypes');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }


}
