<fw-component-title [title]="'Select All Actions Applicable with this Upload'"></fw-component-title>
<div class="doc-up-mgr">
  <ng-container *ngFor="let cbBtn of cbButtons">
    <fw-check-box #cbAction [config]="cbBtn"
                  (change)="onSelect(cbBtn.label?.label, cbAction.value)">
    </fw-check-box>
  </ng-container>
</div>
<div class="app-btn-box">
  <button class="app-btn app-btn--cancel"
          (click)="onCancel()">
    Cancel
  </button>
  <button type="button" class="app-btn app-btn--primary"
          (click)="onNext()">
    Next
  </button>
</div>
