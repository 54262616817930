import { Component, OnInit, OnChanges, SimpleChanges,  OnDestroy, Inject, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { HomDecimalPipe } from 'hom-lib/hom-pipes';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IPoImportLine, ISkuPrice, SkuPrice } from '../../../view-models';
import { ILookupData } from '../../../../../shared/interfaces';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
//store actions, reducers, interfaces
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services';
import { HomCommonUtility } from '../../../../../shared/services';

@Component({
  selector: 'po-import-line-item',
  templateUrl: './po-import-line-item.component.html',
  providers: [MetaDataService]
})
export class PoImportLineComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() poImportLine: IPoImportLine;
  @Input() skuPrices: ISkuPrice[];
  @Input() storeName: string;
  @Input() operation: string;
  @Input() detailConfig: IDetailContainerConfig;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public displayFields: string[] = ['itemName', 'quantity', 'unitPrice', 'unitOfMeasure', 'unitMeasure', 'isCustom', 'overrideSystemPrice', 'poImportStatus', 'poImportStatusText'];
  public title: string = 'Purchase Order Line Details';
  public form: FormGroup;
  public uoms: ILookupData[];
  public statuses: ILookupData[];

  constructor(public mds: MetaDataService,
    public homDecimalPipe: HomDecimalPipe,
    public utils: HomCommonUtility,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.setSelectedSku();
  }

  initForm(): void {
    this.mds.setFieldDefinitions(this.storeName);
    this.mds.loadSelectionLists(this.displayFields, this.operation, this.poImportLine.poImportHeader_poImportHeaderId);
    this.uoms = this.mds.getSelectItems('unitMeasure');
    this.statuses = this.mds.getSelectItems('poImportStatus');
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.poImportLine, this.operation);
  }

  setSelectedSku(): void {
    const match: ISkuPrice = this.poImportLine.sku_skuId
      ? this.skuPrices.find(x => x.sku_skuId === this.poImportLine.sku_skuId) : null;

    if (match) {
      const control = this.form.controls['itemName'];
      control.setValue(match);
    }
  }

  onSave(action: string): void {
    const formData = this.form.getRawValue();
    let rec: IPoImportLine = this.setCommonProperties(formData);
    this.customEvent.emit({ requestor: 'po-import-line-item', event: this.myConstants.emitterEventUpdate, action: action, data: rec });
  }

  onCancel(): void {
    this.customEvent.emit({ requestor: 'po-import-line-item', event: this.myConstants.emitterEventClose, action: '', data: null });
  }

  setCommonProperties(data: IPoImportLine): IPoImportLine {
    let rec: IPoImportLine = cloneDeep(this.poImportLine);
    if (this.utils.isObject(data.itemName)) {
      const item: any = data.itemName;
      const sku: ISkuPrice = item as SkuPrice;
      rec.itemName = sku.skuName;
      rec.sku_skuId = sku.hasOwnProperty('skuId') ? sku['skuId'] : sku.sku_skuId;
    } else {
      const sku = this.skuPrices.find(x => x.skuName == data.itemName);
      rec.itemName = data.itemName;
      rec.sku_skuId = sku ? sku.hasOwnProperty('skuId') ? sku['skuId'] : sku.sku_skuId : null;
    }

    rec.unitMeasure_unitMeasureId = data.hasOwnProperty('unitMeasure') ? data['unitMeasure']['unitMeasureId'] : null;
    const qtyStr = data.quantity.toString();
    const priceStr = data.unitPrice.toString();
    rec.quantity = !qtyStr ? 0 : parseFloat(this.homDecimalPipe.transform(qtyStr.toString()));
    rec.unitPrice = !priceStr ? 0 : parseFloat(this.homDecimalPipe.transform(priceStr.toString()));

    if (data.hasOwnProperty('isCustom')) {
      rec.isCustom = data.isCustom;
    }
    if (data.hasOwnProperty('overrideSystemPrice')) {
      rec.overrideSystemPrice = data.overrideSystemPrice;
    }
    return rec;
  }



  ngOnChanges(changes: SimpleChanges) {
    if (changes['poImportLine'] && !(changes['poImportLine'].firstChange)) {
      this.initForm();
      this.setSelectedSku();
    }
    if (changes['skuPrices'] && !(changes['skuPrices'].firstChange)) {
      this.setSelectedSku();
    }
  }
}
