import { Injectable, OnDestroy} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, map, filter , first } from 'rxjs/operators';

import { IListFilter, OrderTerm } from '../../../fw/dynamic-list/interfaces/index';
import { IGeneralContractor } from '../view-models/index';

//store actions and reducers
import * as fromSelectionLists from '../../../shared/store/selectionLists/index';
import { AccessLevel } from '../../../fw/dynamic-list/enums/access-level.enums';
import { ISelectListData } from '../../../shared/store/selectionLists/index';

@Injectable()
export class GeneralContractorsResolver  {

  providerLocationId: number = 0;
  filter: IListFilter = {
    isLookup: false,
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true, //do not want paged data
    currentPage: 1,
    searchTerm: [],
    orderTerm: [new OrderTerm('generalContractorName')]
  };
  subscription: Subscription = new Subscription();

  constructor( public store: Store<fromSelectionLists.IStoreState> ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IGeneralContractor[]> {
    this.requestData();
    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<IGeneralContractor[]> {
    return this.store.select(fromSelectionLists.getSelectionListByTypeByParent('generalContractor', -1))
      .pipe(
        filter((list: ISelectListData) => list !== null && list.data !== null),
        map((list: ISelectListData) => { return list.data; }),
        first()
      );
  }

  requestData(): void {
    this.subscription.add(this.store.select(fromSelectionLists.getSelectionListByTypeByParent('generalContractor', -1))
      .pipe(take(1))
      .subscribe((list: ISelectListData) => {
        if (!list || !list.data) {
          this.store.dispatch(
            new fromSelectionLists.GetEntityList('GeneralContractor', this.filter,  'generalContractor' ));
        }
      }));
  }

}
