import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { IContactProjectSelectEvent } from '../../../portals/portal-shared/interfaces';

@Component({
  selector: 'sms-entity-selection',
  templateUrl: './sms-entity-selection.component.html'
})
export class SmsEntitySelectionComponent implements OnChanges {
  @Input() contactId: number;
  @Input() contactTypeId: number;
  @Input() requiresWO: boolean;
  @Input() requiresProject: boolean;
  @Input() requiresPO: boolean = false;
  @Input() projectId: number;

  @Output() public select = new EventEmitter<IContactProjectSelectEvent>();

  public title: string = 'Select an item';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.title = 'Select a '.concat(this.requiresProject ? 'Project' : this.requiresPO ? 'Purchase Order': 'Work Order');
  }

  public onSelect(event: IContactProjectSelectEvent): void {
    if (event && event.projectId) {
      this.select.emit(event);
    } else if (event instanceof Event) {
      event.stopPropagation();
    }
  }

}
