import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ICommunicationEvent } from "../../../portals/view-models/i-communication-event";
import { ICommunicationEventFileData } from '../../../portals/view-models/i-communication-event-file-data';
import { IDocumentType } from '../../../portals/view-models';
import { FileToolEvent } from '../../../portals/portal-shared/enums/file-tool-event.enums';
import { IFileTool } from '../../../portals/portal-shared/interfaces';

@Component({
  selector: 'sms-received',
  templateUrl: './sms-received.component.html'
})
export class SmsReceivedComponent implements OnInit   {
  @Input() smsEvent: ICommunicationEvent;
  @Input() documentTypes: IDocumentType[];
  @Input() showMarkRead: boolean;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public fileTools: IFileTool[] = [];
  public toolsVisible: boolean = false;
  
  ngOnInit(): void {
    this.fileTools.push({
      icon: 'fad fa-comment-alt-check',
      title: 'Mark Read',
      event: FileToolEvent.markRead,
      disabled: false,
      color: 'app-btn-icon--success'
    });
  }

  public onFileEvent(event: IHomEventEmitter, eventFileData: ICommunicationEventFileData): void {
    let outEvent: IHomEventEmitter = event;
    switch (event.event) {
      case FileToolEvent.saveAssociation:
        //event.data is of type IContactProjectSelectEvent
        this.customEvent.emit(outEvent);
        break;
      case FileToolEvent.download:
      case FileToolEvent.openInNewTab:
        outEvent.data = eventFileData;
        this.customEvent.emit(outEvent);
       break;
      default:
        break;
    }
  }

  public showTools(event): void {
    if (this.showMarkRead) {
      this.toolsVisible = !this.toolsVisible;
    } else {
      event.stopPropagation();
    }
    event.preventDefault();
  }

  public hoverTools(event, show: boolean): void {
    if (this.showMarkRead) {
      this.toolsVisible = show;
    } else {
      event.stopPropagation();
    }
    event.preventDefault();
  }

  public selectTool(event: FileToolEvent): void {
    switch (event) {
      case FileToolEvent.markRead:
        this.customEvent.emit({
          requestor: 'sms-received',
          action: '',
          event: event,
          data: null
        });
        break;
      default:
        break;
    }
  }
}
