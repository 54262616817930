import { Component, OnInit, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IGeneralContractor } from '../../../view-models';
import { AdminStore, AdminEvent } from '../../enums/admin.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { AdminProviderService } from '../../services/admin-provider.service';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'general-contractors',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './general-contractors.component.html'
})
export class GeneralContractorsComponent implements OnInit {
  public listDefinition: IListDefinition;
  params: IDetailRouteParameter;

  constructor(public activeRoute: ActivatedRoute,
    public userPriviledgesService: UserPriviledgesService,
    public adminService: AdminProviderService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.manageGCBranches:
        this.openGCBranches(event.data);
        break;
      case AdminEvent.manageGCPrograms:
        this.openGCPrograms(event.data);
        break;
      case AdminEvent.manageGCRegions:
        this.openGCRegions(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.params = {
      rowIndex: -1,
      storeName: AdminStore.generalContractors,
      key: 'generalContractorId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: -1
    }

    this.listDefinition = this.adminService.loadGeneralContractorsListDefinition();
  }

  openGCBranches(gc: IGeneralContractor): void {
    this.modalService.open({
      title: 'Branches for General Contractor'.concat(' ', gc.generalContractorName),
      path: 'gc-branches',
      id: gc.generalContractorId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }

  openGCPrograms(gc: IGeneralContractor): void {
    this.modalService.open({
      title: 'Programs for General Contractor'.concat(' ', gc.generalContractorName),
      path: 'gc-programs',
      id: gc.generalContractorId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }

  openGCRegions(gc: IGeneralContractor): void {
    this.modalService.open({
      title: 'Regions for General Contractor'.concat(' ', gc.generalContractorName),
      path: 'gc-regions',
      id: gc.generalContractorId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }
}
