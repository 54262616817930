<div class="portal">
  <div class="portal__detail--item">
    <fw-component-title title='Installer Scoring'></fw-component-title>
    <div [ngClass]="{ 'loading-mask': !scoring$ | async }">

      <ng-container *ngIf="operation !== myConstants.operationTypeEdit">
        <div class="app-btn-box">
          <button class="app-btn-icon app-btn-icon--warning dynamic-list__item--actions-icon"
                  title="Edit Scoring"
                  (click)="onEdit()">
            <i class="fa-edit fas"></i>
          </button>
        </div>
        <installer-scoring-items *ngIf="operation !== myConstants.operationTypeEdit"
                                 [objectData]="objectData"
                                 [scoring]="(scoring$ | async)"
                                  [operation]="operation">
        </installer-scoring-items>
      </ng-container>

      <installer-scoring-edit *ngIf="operation === myConstants.operationTypeEdit"
                              [row]="objectData" [operation]="operation" [controllerName]="controllerName"
                              (save)="onSave($event)"
                              (cancel)="onCancel()">
      </installer-scoring-edit>
    </div>
  </div>
</div>
