<div class="portal">
  <div class="portal__detail--item">
    <fw-component-title [title]="'Sku Maintenance'"></fw-component-title>
    <div class="po-imports-for">
      <div class="float-label">
        <select #importGc
                (change)="changeGC(importGc.value)">
          <option *ngFor="let gc of generalContractors"
                  [selected]="gc.generalContractorId === 1"
                  [value]="gc.generalContractorId">
            {{gc.generalContractorName}}
          </option>
        </select>
        <label>General Contractor</label>
      </div>
    </div>
    <router-outlet name="skusOutlet"></router-outlet>
  </div>
</div>
