<div class="dispatch-calendar__accordion" (click)="toggleFilters()">
  <fw-component-title class="dispatch-calendar__accordion__title" [title]="'Select Filter Options'"></fw-component-title>
  <div class="dispatch-calendar__accordion__btn">
    <button class="app-btn-icon app-btn-icon--primary"
            title="{{ showFilters ? 'Collapse ' : 'Show' }} Filters">
      <i class="fas fa-chevron-{{ showFilters ? 'down' : 'right' }}"></i>
    </button>
  </div>
</div>
<form novalidate class="form-wrapper form-wrapper--edit" [ngClass]="{'dispatch-filters--hide': !showFilters }">
  <div class="dispatch-filters">

    <div class="dispatch-filters__date">
      <fw-calendar [singlePicker]="true"
                   [label]="'Time frame '"
                   [placeHolder]="'Filter for Date:'"
                   [dateValue]="dateValue"
                   [isRequired]="true"
                   (onSelected)="selectDate($event)">
      </fw-calendar>
    </div>

    <div class="dispatch-filters__timeframe">
      <div class="app-group-btn-box">
        <button type="button"
                class="app-group-btn app-btn--primary"
                [ngClass]="{'app-group-btn--selected': selectedDisplayView === dispatchCalendarService.viewDay }"
                (click)="changeDisplayView(dispatchCalendarService.viewDay)">
          {{ dispatchCalendarService.viewDay }}
        </button>
        <button type="button"
                class="app-group-btn app-btn--primary"
                [ngClass]="{'app-group-btn--selected': selectedDisplayView === dispatchCalendarService.viewWeek }"
                (click)="changeDisplayView(dispatchCalendarService.viewWeek)">
          {{ dispatchCalendarService.viewWeek }}
        </button>
        <button type="button"
                class="app-group-btn app-btn--primary"
                [ngClass]="{'app-group-btn--selected': selectedDisplayView === dispatchCalendarService.viewMonth }"
                (click)="changeDisplayView(dispatchCalendarService.viewMonth)">
          {{ dispatchCalendarService.viewMonth }}
        </button>
      </div>
    </div>

    <div class="dispatch-filters__locations">
      <div class="float-label">
        <fw-multiselect-dropdown #locationMultiSelect
                                 [options]="locationMultiSelectOptions"
                                 [texts]="multiSelectLabels"
                                 [settings]="multiSelectSettings"
                                 (onAdded)="addItem(typeLocation,$event)"
                                 (onRemoved)="removeItem(typeLocation, $event)">
        </fw-multiselect-dropdown>
        <label title="Filter By Location(s)">Location(s)</label>
      </div>
    </div>

    <div class="dispatch-filters__services">
      <div class="float-label">
        <fw-multiselect-dropdown #services [options]="serviceMultiSelectOptions"
                                 [texts]="multiSelectLabels"
                                 [settings]="multiSelectSettings"
                                 (onAdded)="addItem(typeService,$event)"
                                 (onRemoved)="removeItem(typeService, $event)">
        </fw-multiselect-dropdown>
        <label title="Filter By Service(s)">Service(s)</label>
      </div>
    </div>

    <div class="dispatch-filters__statuses">
      <div class="float-label">
        <fw-multiselect-dropdown #woStatuses [options]="statusMultiSelectOptions"
                                 [texts]="multiSelectLabels"
                                 [settings]="multiSelectSettings"
                                 (onAdded)="addItem(typeStatus,$event)"
                                 (onRemoved)="removeItem(typeStatus, $event)">
        </fw-multiselect-dropdown>
        <label title="Filter By Work Order Status(es)">Status(es)</label>
      </div>
    </div>

    <div class="dispatch-filters__gcs">
      <div class="float-label">
        <fw-multiselect-dropdown #generalContractors [options]="gcMultiSelectOptions"
                                 [texts]="multiSelectLabels"
                                 [settings]="multiSelectSettings"
                                 (onAdded)="addItem('gc',$event)"
                                 (onRemoved)="removeItem('gc', $event)">
        </fw-multiselect-dropdown>
        <label title="Filter By General Contractor(s)">General Contractor(s)</label>
      </div>
    </div>

    <div class="dispatch-filters__installer">
      <div class="float-label">
        <select #searchType>
          <option *ngFor="let item of installerSearchTypes" [selected]="item.isDefault" [value]="item.value"> {{item.label}} </option>
        </select>
        <label>Installer</label>
      </div>
      <input #searchVal type="text" maxlength="50" placeholder="Search..." (keyup)="changeInstaller($event)">
    </div>

  </div>

  <div class="app-btn-box dispatch-filters__search">
    <fw-info [infoTitle]="''">
      <div class="app-info app-info--options">
        <div><i class="fas fa-square-full app-icon--xs app-info--icon"></i>A date is required.</div>
        <div><i class="fas fa-square-full app-icon--xs app-info--icon"></i>At least one location must be selected.</div>
        <div><i class="fas fa-square-full app-icon--xs app-info--icon"></i>Seattle and Denver cannot both be selected.</div>
        <div><i class="fas fa-square-full app-icon--xs app-info--icon"></i>A maximum of {{ maxLocations }} locations can be selected.</div>
      </div>
    </fw-info>
    <button type="button"
            class="app-btn app-btn--primary"
            [disabled]="disabled$ | async"
            (click)="runFilter()">
      Apply Filters
    </button>
  </div>
  <div class="dispatch-filters__pending">
    <i class="fas fa-filter"></i>
    <span class="dispatch-filters__pending--label">Pending Filter:</span>
    <span class="dispatch-filters__pending--text">{{ pendingFilters }}</span>
  </div>
</form>
<fw-error-box [errors]="errors$ | async"></fw-error-box>
