<input style="position: absolute; height: 0px; width: 0px; opacity: 0" (focus)="onTabFocus()"
      [disabled]="isDisabled"/>
<div #elem class="multi-select-dropdown" [class.open]="isVisible" [ngClass]="{'multi-select-dropdown--disabled': isDisabled}">
    <button class="multi-select-dropdown__button" aria-label="Choose" (click)="toggleDropdown()" [disabled]="isDisabled">
      <div class="multi-select-dropdown__button--label">
        <span class="multi-select-dropdown__button--label--title" [title]="title">{{ title }}</span>
      </div>
        <span class="multi-select-dropdown__button--icon"><i class="fas fa-angle-down"></i></span>
    </button>

    <ul *ngIf="isVisible" id="scrollCon" #scrollCon
        class="multi-select-dropdown__content"
        [ngClass]="settings.containerClasses" 
        tabindex="0"
        (keydown)="onKeyDown($event)">
        <!--begin of search filter box-->
        <li *ngIf="settings.enableSearch" class="multi-select-dropdown__search-box">
            <div *ngIf="settings.showPagingInfo" class="multi-select-dropdown__search">
                <input type="text" class="multi-select-dropdown__search--input" placeholder="Search">
                <button *ngIf="searchFilterText.length > 0"
                        class="multi-select-dropdown__search--button" type="submit"
                        aria-label="Search" aria-describedby="sizing-addon3"
                        [(ngModel)]="searchFilterText"
                        [ngModelOptions]="{standalone: true}" autofocus>
                    <span class="multi-select-dropdown__search--icon"><i class="fas fa-search"></i></span>
                </button>
                <!--<button class="multi-select-dropdown__search--button" *ngIf="searchFilterText.length > 0"  type="button"  
                            aria-label="Clear" (click)="clearSearch($event)">
                    <span class="multi-select-dropdown__search--icon multi-select-dropdown__search--icon-clear"><i class="fas fa-times"></i></span>
                </button>
                -->
            </div>
            <div *ngIf="settings.showPagingInfo && settings.totalItems > 0" class="multi-select-dropdown__paging-info">
                <span class="multi-select-dropdown__paging-info--text">
                    {{ settings.itemsPerPage > settings.totalItems ? settings.totalItems : settings.itemsPerPage}} of {{settings.totalItems}} displayed.</span>
                <br/>
                <span class="multi-select-dropdown__paging-info--sub-text">{{texts.findHelpText}}</span>
            </div>
        </li>

        <li *ngIf="settings.enableSearch" class="multi-select-dropdown--divider"></li>

        <li *ngIf="settings.showCheckAll" class="multi-select-dropdown__check-control">
            <button type="button" class="app-btn-icon" (click)="checkAll()">
                <span class="fas fa-check multi-select-dropdown__check-control--check"></span>
                {{ texts.checkAll }}
            </button>
        </li>
        <li *ngIf="settings.showUncheckAll" class="multi-select-dropdown__check-control">
            <button type="button" class="app-btn-icon" (click)="uncheckAll()">
                <span class="fas fa-times multi-select-dropdown__check-control--uncheck"></span>
                {{ texts.uncheckAll }}
            </button>
        </li>

        <li *ngIf="settings.showCheckAll || settings.showUncheckAll" class="multi-select-dropdown--divider"></li>
        <!--begin of list items-->
        <li #listItems *ngFor="let option of useOptions | searchFilter:searchFilterText; let i = index;"
            class="multi-select-dropdown__item"
            [ngClass]="{'tabbed': i === tabIdx}"
            tabindex="-1"
            (click)="toggleSelected($event, i)">
            <span *ngIf="option.selected" class="multi-select-dropdown__item--icon" >
              <i class="fas fa-check" aria-hidden="true"></i>
            </span>
            <span class="multi-select-dropdown__item--label" [title]="option.name"> 
              {{ option.name }}
              <span *ngIf="filterTxt.length && filterMatch(option.name)" class="multi-select-dropdown__item--label--bold" >{{ getFilterTxt(option.name) }}</span>
            </span>
        </li>
    </ul>
</div>
