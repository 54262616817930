import {
  Component, OnInit, OnDestroy, ViewChild, ElementRef,
  Inject, ViewChildren, QueryList, Renderer2
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription,BehaviorSubject } from 'rxjs';
import { cloneDeep, orderBy, max } from 'lodash';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IResponseBase, IErrorData, IEntity } from '../../../../shared/interfaces';
import { IAppConstants, appConstants } from '../../../../shared/constants';
import {
  ICommunicationEvent, IContact, IUnrespondedToContactModel,
  ICommunicationShortcut, ICommunicationDocumentType, IProject,
  IWorkOrder, ICommunicationDocumentTypeViewModel, IDocumentType, ICommunicationEventFileAssociationViewModel
} from '../../../portals/view-models';
import { CommunicationEventType } from '../../../portals/portal-shared/enums/communication-event.enums';
import { ListFilter, IListFilter, OrderTerm } from '../../../../fw/dynamic-list/interfaces';

import { IAllDynamicData, getObjectDataById, getListByType, IDynamicListState } from '../../../../fw/dynamic-list/store';
import { IInfiniteScrollOptions } from '../../../../fw/fw-shared/interfaces';
import { ContactStore } from '../../../contact/enums/contact.enums';
import { SmsEvent, SmsMessages, SmsEntityName, ContactType } from '../../enums/sms.enums';
import { CommunicationShortcutCategoryId } from '../../../portals/portal-shared/enums/communication-shortcut.enums';
import { ISmsDocument } from '../../interfaces/i-sms-document';
import { IContactProjectSelectEvent } from '../../../portals/portal-shared/interfaces';
import { FileToolEvent } from '../../../portals/portal-shared/enums/file-tool-event.enums';
import { ICommunicationEventFileData } from '../../../portals/view-models/i-communication-event-file-data';

import { environment } from '../../../../environments/environment';
import * as fromRoot from '../../../store/reducers/index';
import { RemoveUnrespondedToContact } from '../../../../auth/store';
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { ContactUtilityService } from '../../../contact/services';
import { DomainObjectService } from '../../../../shared/services';
import { getSelectionListDataByType } from '../../../../shared/store/selectionLists';
import { UserPriviledgesService } from '../../../../auth/services';

@Component({
  selector: 'sms-conversation',
  templateUrl: './sms-conversation.component.html'
})
export class SmsConversationComponent implements OnInit, OnDestroy {

  @ViewChild('convoText') public convoText: ElementRef;
  @ViewChild('fileDiv', { static: false }) fileDiv: ElementRef;

  @ViewChildren('listContainer') listContainer: QueryList<ElementRef>
  public charactersLeft$: BehaviorSubject<number> = new BehaviorSubject(0);
  public smsEvents$: BehaviorSubject<ICommunicationEvent[]> = new BehaviorSubject([]);
  public contact$: BehaviorSubject<IContact> = new BehaviorSubject(null);
  public texting$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public allShortCuts: ICommunicationShortcut[] = null;
  public shortcuts$: BehaviorSubject<ICommunicationShortcut[]> = new BehaviorSubject(null);
  public allCommDocumentTypes: ICommunicationDocumentType[] = null;
  public documentTypes$: BehaviorSubject<IDocumentType[]> = new BehaviorSubject(null);
  public commDocumentTypes$: BehaviorSubject<ICommunicationDocumentType[]> = new BehaviorSubject(null);
  public selectedDocumentTypes$: BehaviorSubject<ISmsDocument[]> = new BehaviorSubject([]);
  public activeDocumentType$: BehaviorSubject<ISmsDocument> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errorMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  public docTypeError$: BehaviorSubject<string> = new BehaviorSubject('');
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public selectedProject: IProject = null;
  public selectedWorkOrder: IWorkOrder = null;
  public emojisVisible: boolean = false;
  public shortcutsVisible: boolean = false;
  public docTypesVisible: boolean = false;
  public scrollOptions: IInfiniteScrollOptions = { scrollDown: false };
  public refreshOn: string = '';
  public noClose: boolean = false;
  public fromMgr: boolean = false;
  public smsSendDocEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public timestamp: string = '';
  public canText: boolean = false;
  public showMarkRead: boolean = true;

  contactId: number;
  projectId: number;
  phoneNumber: string;
  toName: string;
  parentName: string;
  outGoingId: number;
  incomingId: number;
  maxChars: number = 350;
  currentPage: number = 0;
  totalItems: number = -1;
  itemsPerPage: number = -1;
  projectEntities: IEntity[] = [];
  //keep individual subscribes
  smsSub: Subscription = new Subscription();
  contactSub: Subscription = new Subscription();
  textSub: Subscription = new Subscription();
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public router: Router,
    public renderer: Renderer2,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<IAllDynamicData>,
    public domainObjectService: DomainObjectService,
    public emitterService: HomEventEmitterService,
    public modalService: ModalService,
    public contactUtilityService: ContactUtilityService,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) {
    this.getSelectionListData();
  }

  ngOnInit(): void {

    this.activeRoute.parent.paramMap.subscribe((params) => {
      if (params.get('portalEntityId') && (params.get('portalId') && params.get('portalId') === '1')) {
        this.projectId = Number(params.get('portalEntityId'));
      }
    });

    this.outGoingId = CommunicationEventType.smsOutgoing;
    this.incomingId = CommunicationEventType.smsIncoming;
    this.activeRoute.data
      .subscribe(d => {
        //this is a property on the route
        this.fromMgr = d.hasOwnProperty('isMgr') && d['isMgr'] === 'true' ? true : false;
      });

    this.activeRoute.paramMap.subscribe((paramMap) => {
      this.working$.next(true);
      this.contactId = +paramMap.get('id');
      this.phoneNumber = paramMap.get('phone');
      this.toName = paramMap.get('name');
      this.parentName = paramMap.get('parent') || '';
      this.noClose = paramMap.has('noclose') && paramMap.get('noclose') === 'true' ? true : false;

      this.initNewRequest();
      this.contact$.next(null);
      this.initText();
      this.getContact(); 
      this.getSmsEvents();
    });

    this.subscription.add(this.emitterService.smsEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e.event === SmsEvent.receivedNewText) {
          const data: IUnrespondedToContactModel[] = e.data;
          if (data && data.findIndex(x => x.ContactId === this.contactId) > -1) {
            this.initNewRequest();
            this.getSmsEvents();
          }
        }
        if (e.event === SmsEvent.smsSendError) {
          let smsEvents = this.smsEvents$.value;
          let smsEvent = smsEvents ? smsEvents.find(x => x.communicationEventId === e.data.CommunicationEventId) : null;
          if (smsEvent) {
            smsEvent.hasErrorCondition = true;
            smsEvent.statusText = e.data.StatusMessage;
            smsEvent.errorMessage = e.data.ErrorMessage;
          }
          this.smsEvents$.next(smsEvents);
        }
      }));
  }

  public onScrollEnd(): void {
    this.getSmsEvents();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.contactSub) {
      this.contactSub.unsubscribe();
    }
    if (this.smsSub) {
      this.smsSub.unsubscribe();
    }
    if (this.textSub) {
      this.textSub.unsubscribe();
    }
  }

  public msgChange(val: string): void {
    this.setCharLeft();
    this.setIsDisabled();
  }

  public toggleShortCuts(): void {
    this.shortcutsVisible = !this.shortcutsVisible;
    this.timestamp = new Date().toTimeString();
  }

  public showOrHideDocTypes(show: boolean) {
    if (this.docTypesVisible === show) return;
    if (this.selectedDocumentTypes$.value.length <= 10) {
      this.docTypeError$.next('');
    }
    if (!show) this.activeDocumentType$.next(null);
    this.docTypesVisible = show;
  }

  public showOrHidePopover(popoverType: string, show: boolean): void {
    this[popoverType] = show;
  }

  public showEmojis(): void {
    this.emojisVisible = !this.emojisVisible;
  }

  public addEmoji(selected: Emoji): void {
    const emoji: string = (selected.emoji as any).native;
    const input = this.convoText.nativeElement;

    var startPos = input.selectionStart;
    input.focus();
    input.value = input.value.substr(0, input.selectionStart) + emoji + input.value.substr(input.selectionStart, input.value.length);
    this.emojisVisible = false;
    this.setCharLeft();
    this.setIsDisabled();
  }

  public selectTemplate(template: string): void {
    const input = this.convoText.nativeElement;
    input.value = template;
    this.toggleShortCuts();
    this.setCharLeft();
    this.setIsDisabled();
  }

  public selectDocumentType(documentType: ICommunicationDocumentType): void {
    const selectedItems = this.selectedDocumentTypes$.getValue();
    let last: number = selectedItems.length > 0 ? max(selectedItems.map(x => x.id)) : 0;

    if (selectedItems.length >= 10) {
      this.docTypeError$.next(SmsMessages.maxDocsError);
      return;
    }

    this.activeDocumentType$.next({
      id: last + 1,
      documentType: documentType,
      icon: 'fas fa-calendar',
      entityId: -1,
      entityLabel: '',
      projectId: -1,
      workOrderId: -1
    });

    if (documentType && !documentType.requiresProject && !documentType.requiresWorkOrder) {
      this.pushActiveDocument();
      this.showOrHideDocTypes(false);
    }
  }

  public selectEntity(event: IContactProjectSelectEvent): void {
    this.pushActiveDocument(event);
    this.showOrHideDocTypes(false);
  }

  public deleteDocument(arrayId: number): void {
    //remove from list of selected documents
    let selectedItems = this.selectedDocumentTypes$.getValue();
    if (selectedItems) {
      this.selectedDocumentTypes$.next(selectedItems.filter(x => x.id !== arrayId));
    }

    //if active document, null out active document
    let activeDoc = this.activeDocumentType$.getValue();
    if (activeDoc && activeDoc.id === arrayId) {
      this.activeDocumentType$.next(null);
    }
    if (selectedItems.length <= 10) {
      this.docTypeError$.next('');
    }
  }

  public close(): void {
    if (this.modalService.opened) {
      this.router.navigate([{ outlets: { chatmodal: null } }],
        { relativeTo: this.activeRoute.parent });

    } else {
      this.router.navigate([{ outlets: { chatbar: null } }],
        { relativeTo: this.activeRoute.parent });
    }
  }

  public markRead(): void {
    this.selectedDocumentTypes$.next([]);
    this.sendText('', true);
  }

  public sendText(text: string, markRead: boolean = false): void {
    let documents: ICommunicationDocumentTypeViewModel[] = [];
    this.texting$.next(true);

    this.selectedDocumentTypes$.value.forEach(doc => {
      documents.push({
        communicationDocumentTypeId: doc.documentType.communicationDocumentTypeId,
        projectId: doc.projectId,
        workOrderId: doc.workOrderId
      })
    });
    const obj = { contactId: this.contactId, phoneNumber: this.phoneNumber, text: text, documents: documents, markRead: markRead }

    //extra check to ensure texts do not go out until enabled.
    const contact = this.contact$.value;
    if (!this.canText) {
      this.texting$.next(false);
      return;
    }

    if (this.textSub) {
      this.textSub.unsubscribe();
    }

    this.errorMessage$.next('');
    this.errorData$.next([]);

    this.textSub = this.domainObjectService.createByMethod('Sms', 'SendText', obj)
      .subscribe((result: IResponseBase) => {
        if (result.success) {
          this.selectedDocumentTypes$.next([]);
          this.activeDocumentType$.next(null);
          if (result.data) {
            let existing: ICommunicationEvent[] = cloneDeep(this.smsEvents$.value);
            existing.push(result.data);
            this.smsEvents$.next(existing);
          } 
          this.rootStore.dispatch(new RemoveUnrespondedToContact({ contactId: this.contactId }));
          this.emitSendNotice();
        } else {
          this.errorMessage$.next(result.message ? result.message : 'Send Text Failed');
          this.errorData$.next(result.errorData);
        }
        this.initText();
        this.texting$.next(false);
      },
        (error: any) => { console.log(error) }
      );
  }

  public onCustomEvent(event: IHomEventEmitter): void {
    switch (event.event) {
      case FileToolEvent.saveAssociation:
        this.saveFileAssociation(event.data);
        break;
      case FileToolEvent.download:
        this.downloadFile(event.data);
        break;
      case FileToolEvent.openInNewTab:
        this.showFileInNewTab(event.data);
        break;
      case FileToolEvent.markRead:
        this.markRead();
        break;
      default:
        break;
    }
  }

  emitSendNotice(): void {
    let sendEvent: IHomEventEmitter = { requestor: 'sms-conversation', event: SmsEvent.sentText, action: '', data: this.contactId };
    this.emitterService.emitSmsEvent(sendEvent);
  }

  initNewRequest(): void {
    this.currentPage = 0;
    this.totalItems = -1;
    this.smsEvents$.next([]);
    this.selectedDocumentTypes$.next([]);
    this.activeDocumentType$.next(null);
    this.errorData$.next([]);
    this.errorMessage$.next('');
  }

  getContact(): void {
    let requested: boolean = false;

    if (this.contactSub) {
      this.contactSub.unsubscribe();
    }

    this.contactSub = this.store.pipe(select(getObjectDataById(ContactStore.contactInformation, this.contactId)))
      .subscribe((data: IContact) => {
        if (!data && !requested) {
          //get the contact object
          this.contactUtilityService.dispatchGetContact(this.contactId);
          requested = true;
        } else {
          this.canText =
            (data.contactTypeName === ContactType.installer && environment.smsInstallerEnabled)
            || (data.contactTypeName !== ContactType.installer && environment.smsCustomerEnabled)
            || this.ups.haveDeveloperAccess$.value;
          if (this.allShortCuts && data) {
            this.setShortCuts(data.contactType_contactTypeId);
          }
          if (this.allCommDocumentTypes && data) {
            this.setCommDocumentTypes(data.contactType_contactTypeId);
          }
          this.smsSendDocEnabled$.next((data.contactTypeName === ContactType.installer && environment.smsInstallerSendDocEnabled)
            || (data.contactTypeName !== ContactType.installer && environment.smsCustomerSendDocEnabled)
            || this.ups.haveDeveloperAccess$.value);
          this.contact$.next(data);
        }
      });
  }

  getSmsEvents(): void {
    let listFilter: IListFilter = new ListFilter();

    if (this.smsSub) {
      this.smsSub.unsubscribe();
    }

    //total items is initialized to -1, set to 0 at min after getting records once
    if (this.totalItems > -1) {
      if (this.totalItems === 0 || (this.itemsPerPage * this.currentPage > this.totalItems)) {
        //no more rows waiting to be retrieved
        return;
      }
    }
    this.working$.next(true);
    this.currentPage = this.currentPage + 1;
    listFilter.orderTerm = [new OrderTerm('createDate', false)]; //need the most recent n number of records, re-sort and append on return
    listFilter.getAll = false;
    listFilter.currentPage = this.currentPage;

    this.smsSub = this.domainObjectService.getByMethodById('CommunicationEvent', 'ByContactForSms', this.contactId, listFilter)
      .subscribe((result: IResponseBase) => {
        if (result.success) {
          const newData: ICommunicationEvent[] = cloneDeep(this.sortData(result.data));
          if (listFilter.currentPage === 1) {
            this.totalItems = result.metaData && result.metaData.pageMetaData ? result.metaData.pageMetaData.totalItems : 0;
            this.itemsPerPage = result.metaData && result.metaData.pageMetaData ? result.metaData.pageMetaData.itemsPerPage : 0;
            this.setMarkRead(newData);
            this.smsEvents$.next(newData);
          } else {
            //For paging append new results to beginning of array
            const existing: ICommunicationEvent[] = cloneDeep(this.smsEvents$.value);
            this.smsEvents$.next([...newData, ...existing]);
          }
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { console.log(error) }
      );
  }

  getSelectionListData(): void {
    const providerId = this.ups.providerId$.value;

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('documentType', -1)))
      .subscribe((data: IDocumentType[]) => {
        if (data) {
          var filtered = data.length ? data.filter(x => x.isVisible) : data
          var sorted = filtered.length ? orderBy(filtered, ['documentTypeName'], ['asc']) : filtered;
          this.documentTypes$.next(sorted);
        }
      }));

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('communicationShortcut', providerId)))
      .subscribe((data: ICommunicationShortcut[]) => {
        if (data) {
          this.allShortCuts = data.filter(x =>
            x.communicationShortcutCategory_communicationShortcutCategoryId === CommunicationShortcutCategoryId.texting
          );

          const contact = this.contact$.value;
          if (contact && contact.contactType_contactTypeId) {
            this.setShortCuts(contact.contactType_contactTypeId);
          }
        }
      }));

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('communicationDocumentType', providerId)))
      .subscribe((data: ICommunicationDocumentType[]) => {
        if (data) {
          this.allCommDocumentTypes = data.filter(x =>
            x.communicationShortcutCategory_communicationShortcutCategoryId === CommunicationShortcutCategoryId.texting
          );

          const contact = this.contact$.value;
          if (contact && contact.contactType_contactTypeId) {
            this.setCommDocumentTypes(contact.contactType_contactTypeId);
          }
        }
      }));

    this.subscription.add(this.store.select(getListByType('entities')).subscribe((state: IDynamicListState) => {
      if (state.objData && state.objData.length) {
        this.projectEntities = state.objData[0].data;
      }
    }));

  }

  initText(): void {
    if (this.convoText) {
      this.renderer.setProperty(this.convoText.nativeElement, 'value', '');
    }
    this.charactersLeft$.next(this.maxChars);
    this.setIsDisabled();
  }

  //records should display ascending, but are retrieved desc (so last # are displayed first - but in asc order)
  sortData(data: ICommunicationEvent[]): ICommunicationEvent[] {
    if (data.length) {
      data = orderBy(data, ['createDate'], ['asc']);
    }
    return data;
  }

  setShortCuts(contactTypeId: number): void {
    this.shortcuts$.next(this.allShortCuts.filter(x => x.contactType_contactTypeId === contactTypeId));
  }

  setCommDocumentTypes(contactTypeId: number): void {
    //communication_document_types not document_types
    this.commDocumentTypes$.next(this.allCommDocumentTypes.filter(x => x.contactType_contactTypeId === contactTypeId));
  }

  pushActiveDocument(event: IContactProjectSelectEvent = null): void {
    let selectedItems = this.selectedDocumentTypes$.getValue();
    let activeDoc = this.activeDocumentType$.getValue();

    activeDoc.projectId = event ? event.projectId : -1;
    activeDoc.workOrderId = event ? event.workOrderId : -1;
    activeDoc.entityId = !event ? -1 : event.workOrderId > 0 ? event.workOrderId : event.projectId;
    activeDoc.entityLabel = !event ? '' : event.workOrderId > 0 ? 'Work Order' : 'Project';

    selectedItems.push(activeDoc);
    this.selectedDocumentTypes$.next(selectedItems);
    this.activeDocumentType$.next(null);
  }

  showFileInNewTab(data: ICommunicationEventFileData): void {
    if (data && data.imageUrl) {
      window.open(data.imageUrl);
    }
  }

  downloadFile(data: ICommunicationEventFileData): void {
    if (data && data.fileUrl && this.fileDiv) {

      let link = this.renderer.createElement('a');
      this.renderer.setAttribute(link, 'type', 'hidden');
      this.renderer.setProperty(link, 'href', data.fileUrl);
      this.renderer.setProperty(link, 'download', data.fileName);
      this.renderer.appendChild(this.fileDiv.nativeElement, link);
      link.click();
      this.renderer.removeChild(this.fileDiv.nativeElement, link)
    }
  }

  setMarkRead(data: ICommunicationEvent[]): void {
    this.showMarkRead = true;
    if (data.length > 1
      && data[data.length - 1].communicationEventType_communicationEventTypeId === CommunicationEventType.smsOutgoing
      && !data[data.length - 1].communicationEventFreeformFeedback) {
      this.showMarkRead = false;
    }
  }

  saveFileAssociation(data: IContactProjectSelectEvent): void {
    const entityName: string = data.purchaseOrderId > 0 ? SmsEntityName.purchaseOrder :
      data.workOrderId > 0 ? SmsEntityName.workOrder : SmsEntityName.project;
    const entity = this.projectEntities.find(x => x.entityName == entityName);
    const obj: ICommunicationEventFileAssociationViewModel = {
      entityId: entity.entityId,
      entityPrimaryKeyId: data.purchaseOrderId > 0 ? data.purchaseOrderId :
          data.workOrderId > 0 ? data.workOrderId : data.projectId,
      documentTypeId: data.associateToDocumentTypeId,
      communicationEventFileId: data.communicationEventFileId,
      actionIds: data.actionIds
    };

    if (this.textSub) {
      this.textSub.unsubscribe();
    }

    this.errorMessage$.next('');
    this.errorData$.next([]);

    this.textSub = this.domainObjectService.createByMethod('CommunicationEventFile', 'AssociateToEntity', obj)
      .subscribe((result: IResponseBase) => {
        if (result.success) {
          //refresh events 
          this.refreshOn = new Date().toString();
          this.initNewRequest();
          this.getSmsEvents();
        } else {
          this.errorMessage$.next(result.message);
          this.errorData$.next(result.errorData);
        }
      },
        (error: any) => { console.log(error) }
      );
  }

  setCharLeft(): void {
    const input = this.convoText.nativeElement;
    this.charactersLeft$.next(this.maxChars - input.value.length);
  }

  setIsDisabled(): void {
    if (this.convoText) {
      const input = this.convoText.nativeElement;
      this.isDisabled$.next(!this.canText || !input.value);
    }
  }
}
