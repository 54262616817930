<portal-detail-layout-container [portalId]="managerPortalId"
                                [portalEntityId]="'-1'"
                                [title]="title"
                                [tabContainerName]="tabContainerName">

  <div class="admin-links">
      <div *ngFor="let item of (navItems$ | async)" class="admin-link">
        <div *ngIf="item.canAccess" class="admin-link__main" (click)="toggleItem(item)">
          <div class="admin-link__main__icon"><i class="{{ item.icon }}"></i></div>
          <div class="admin-link__main__label">{{ item.label }}</div>
          <div class="admin-link__main__arrow">
            <div class="fas fa-chevron-{{ item.expanded ? 'down' : 'right' }}"></div>
          </div>
        </div>
        <div class="admin-link__subs" *ngIf="item.expanded">
          <ng-container *ngFor="let subItem of item.subItems">
            <div *ngIf="subItem.canAccess"
                 class="admin-link__subs__item"
                 (click)="openTab(subItem)">{{ subItem.label }}
            </div>
        </ng-container>
      </div>
    </div>
  </div>
</portal-detail-layout-container>
