<div class="sms-received {{ smsEvent.contactTypeName === 'Installer' ? 'sms-received--installer' : 'sms-received--customer' }}">
  <div *ngIf="smsEvent.contactTypeName === 'Installer'" class="sms-received__icon sms-received__icon--installer">
    <i class="fad fa-truck"></i>
  </div>
  <div *ngIf="smsEvent.contactTypeName !== 'Installer'" class="sms-received__icon sms-received__icon--customer">
    <i class="fad fa-user"></i>
  </div>
  <div class="sms-received__bubble {{ smsEvent.contactTypeName === 'Installer' ? 'sms-received__bubble--installer' : 'sms-received__bubble--customer' }}"
       (mouseenter)="hoverTools($event, true)"
       (mouseleave)="hoverTools($event, false)"
       (touchstart)="showTools($event)">
    <ng-container *ngIf="showMarkRead">
      <div *ngIf="toolsVisible" class="sms-received__bubble__tools">
        <file-tools [tools]="fileTools"
                    (select)="selectTool($event)">
        </file-tools>
      </div>
    </ng-container>
    <div class="sms-received__bubble--text" [innerHtml]="smsEvent.communicationEventFreeformFeedback"></div>
    <div class="sms-sent__bubble__files">
      <ng-container *ngFor="let data of smsEvent.eventFileData">
        <sms-file *ngIf="documentTypes && documentTypes.length > 0"
                  [fileData]="data"
                  [documentTypes]="documentTypes"
                  [contactId]="smsEvent.contact_contactId"
                  [contactTypeId]="smsEvent.contactType_contactTypeId"
                  (customEvent)="onFileEvent($event, data)">
        </sms-file>
      </ng-container>
    </div>
  </div>
  <div class="sms-received__tail" [ngClass]="{'sms-received__tail--installer': smsEvent.contactTypeName === 'Installer'}"></div>
  <div class="sms-received__meta">
    {{smsEvent.installerName ? smsEvent.installerName : smsEvent.contactName}} {{ smsEvent.createDate | date:'MM/dd/yyyy h:mm a' }}
  </div>
</div>
