
import { IKey } from './i-key';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

/* structure for data object for UPDATE_OBJECT_CUSTOM reducer/action */
export interface IUpdateObjectCustom {
  keyData: IKey,
  objectType: string,
  methodName: string,
  objectData: any,
  arrayName: string; //typically null
  event: IHomEventEmitter;

}

export class UpdateObjectCustomModel implements IUpdateObjectCustom {
  constructor(
    public keyData: IKey,
    public objectType: string,
    public methodName: string,
    public objectData: any,
    public arrayName: string,
    public event: IHomEventEmitter = null

  ) { }
}

//updateCustom(objectType: string, methodName: string, domainObject: IDomainObject, param: any, arrayName: string = null): Observable<IResponseBase> {
//updateCustomByUrl
