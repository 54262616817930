import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { getSelectedRecord, IAllDynamicData } from '../../../../../fw/dynamic-list/store';
import { ISurvey } from '../../../view-models/i-survey';
import { AdminStore, AdminEvent } from '../../enums/admin.enums';
import { ISurveyQuestion } from '../../../view-models/index_two';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { appConstants, IAppConstants } from '../../../../../shared/constants';

import { AdminProviderService } from '../../services/admin-provider.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'survey-questions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-questions.component.html'
})
export class SurveyQuestionsComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;
  subscription: Subscription = new Subscription();
  surveyId: number;
  currentSurvey: ISurvey;
  params: IDetailRouteParameter;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<IAllDynamicData>,
    public adminService: AdminProviderService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.surveyId = +paramMap.get('id');
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
      switch (event.event) {
        case AdminEvent.showQuestionProjectMappings:
          this.openQuestionProjectMappings(event.data);
          break;
        case AdminEvent.showQuestionImportMappings:
          this.openQuestionImportMappings(event.data);
          break;
        default:
          break;
      }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadSurveyQuestionsListDefinition(this.surveyId);
    const parentListDef: IListDefinition = this.surveyId > 0
      ? this.adminService.loadSurveysListDefinition()
      : null;

    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(parentListDef.storeName, -1, parentListDef.rowKeyId, this.surveyId), take(1)))
      .subscribe(entity => {
        this.currentSurvey = this.surveyId > 0 ? entity : null;
      })
    );
  }

  openQuestionProjectMappings(data: ISurveyQuestion): void {
    this.modalService.open({
      title: 'Project Map Fields for Question'.concat(' ', data.surveyQuestionText),
      path: 'survey-proj-map-fields',
      id: data.surveyQuestionId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.getChildOptionalParams(data),
      hasTabs: false,
      isSubModal: this.modalService.opened
    });
  }

  openQuestionImportMappings(data: ISurveyQuestion): void {
    this.modalService.open({
      title: 'Import Mappings for Question'.concat(' ', data.surveyQuestionText),
      path: 'survey-import-mappings',
      id: data.surveyQuestionId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: this.getChildOptionalParams(data),
      hasTabs: false,
      isSubModal: this.modalService.opened
    });
  }

  getChildOptionalParams(data: ISurveyQuestion): IDetailRouteParameter {
    return {
      rowIndex: -1,
      storeName: AdminStore.surveyQuestions,
      key: 'surveyQuestionId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.survey_surveyId 
    }

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
