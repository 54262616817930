import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { IFileTool } from '../../interfaces';
import { FileToolEvent } from '../../enums/file-tool-event.enums';

@Component({
  selector: 'file-tools',
  templateUrl: './file-tools.component.html'
})
export class FileToolsComponent {
  @Input() tools: IFileTool[];

  @Output() public select = new EventEmitter<string>();

  public click(event: FileToolEvent): void {
    this.select.emit(event);
  }

}
