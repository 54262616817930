<ng-container [formGroup]="filterForm" *ngIf="(field.visibleOnSmall || !screenService.isSmall())">

  <div class="dynamic-filter-field__search-type" [ngClass]="{'dynamic-filter-field--hidden': !searchTypeConfig.isVisible }">
    <select #searchTypes [id]="searchTypeKey" [formControlName]="searchTypeKey" (change)="onSearchTypeChange($event.target.value)">
      <option *ngFor="let item of searchTypeConfig.options" [selected]="item.isDefault" [value]="item.value"> {{item.label}} </option>
    </select>
  </div>
  <div [ngStyle]="{'display': (showField$ | async) ? 'block' : 'none'}">
    <ng-container [ngSwitch]="displayType$ | async">
      <ng-container *ngSwitchCase="myConstants.fieldTypeString">
        <!--STRING-->
        <input [formControlName]="field.key" [id]="field.key" type="search"
               maxlength="{{ !field.maxStringLength ? 50 : field.maxStringLength }}"
               placeholder="Search...">
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeTextArea">
        <!--TEXTAREA-->
        <input [formControlName]="field.key" [id]="field.key" type="search" maxlength="50" placeholder="Search...">
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeNumber">
        <!--NUMBER -->
        <div class="dynamic-filter-field__numeric-range">
          <input type="text" digitOnlyMask [formControlName]="field.key" [id]="field.key" name="field.key" placeholder="0">
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeNumericRange">
        <!--NUMERIC RANGE -->
        <div class="dynamic-filter-field__numeric-range">
          <input type="text" digitOnlyMask [formControlName]="field.key.concat('_start')"
                 [id]="field.key.concat('_start')"
                 placeholder="0" (input)="onRangeChange()" />
          <label>to</label>
          <input type="text" digitOnlyMask [formControlName]="field.key.concat('_end')"
                 [id]="field.key.concat('_end')"
                 placeholder="0" (input)="onRangeChange()" />
          <input type="text" [formControlName]="field.key" [id]="field.key" placeholder="0" class="dynamic-filter-field--hidden" />
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeBoolean">
        <!--CHECKBOX for BOOLEAN-->
        <div class="dynamic-filter-field__boolean-dropdown">
          <fw-multiselect-dropdown #ddMultiSelect [options]="field.multiSelectOptions"
                                   [formControlName]="field.key"
                                   [texts]="field.multiSelectLabels"
                                   [settings]="field.multiSelectSettings"></fw-multiselect-dropdown>
        </div>
      </ng-container>


      <ng-container *ngSwitchCase="myConstants.fieldTypeMultiSelect">
        <!--SELECT - DROP DOWN WITH MULTI SELECT -->
        <fw-multiselect-dropdown #ddMultiSelect [options]="field.multiSelectOptions"
                                 [formControlName]="field.key"
                                 [texts]="field.multiSelectLabels"
                                 [settings]="field.multiSelectSettings"
                                 [isDisabled]="!field.multiSelectOptions || disabled"
                                 (onPageFilter)="filterOfFilterEvent($event)"></fw-multiselect-dropdown>
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeSingleDatePicker">
        <!--DATE-->
        <ng-container *ngTemplateOutlet="datePicker"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeDateRangePicker">
        <!-- DATE RANGE CAPABILITY-->
        <ng-container *ngTemplateOutlet="datePicker"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeEmail">
        <!--EMAIL-->
        <input [formControlName]="field.key" [id]="field.key"
               maxlength="{{ !field.maxStringLength ? 50 : field.maxStringLength }}"
               placeholder="Search..."
               pattern="[a-z0-9._.%+-.]+@[a-z0-9.-]+" type="email" />
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeTimePicker">
        <!--TIME-->
        <div class="dynamic-filter-field__time">
          <input type="time" [formControlName]="field.key" [id]="field.key"
                 value=""
                 pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" />
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeTimeSpan">
        <!--TIMESPAN - RANGE-->
        <div class="dynamic-filter-field__time">
          <label>Start Time</label>
          <input type="time" [formControlName]="field.key.concat('_start')" [id]="field.key.concat('_start')"
                 value=""
                 pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                 (change)="changeTimeSpan($event.target.value, 'start')" />
          <label>End Time</label>
          <input type="time" [formControlName]="field.key.concat('_end')" [id]="field.key.concat('_end')"
                 value=""
                 pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                 (change)="changeTimeSpan($event.target.value, 'end')" />
          <input type="search" [formControlName]="field.key" [id]="field.key" value="" class="dynamic-filter-field--hidden" />
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeDoubleDecimal">
        <!--DOUBLEDECIMAL  -->
        <ng-container *ngTemplateOutlet="doubleTemplate"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeCurrency">
        <!--CURRENCY  -->
        <ng-container *ngTemplateOutlet="doubleTemplate"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypeDouble">
        <!--DOUBLE  -->
        <ng-container *ngTemplateOutlet="doubleTemplate"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="myConstants.fieldTypePercentage">
        <!--PERCENTAGE  -->
        <ng-container *ngTemplateOutlet="percentTemplate"></ng-container>
      </ng-container>


      <ng-container *ngSwitchCase="myConstants.fieldTypeDecimalRange">
        <!--DOUBLE DECIMAL / CURRENCY Range -->
        <div class="dynamic-filter-field__numeric-range">
          <input type="text" digitOnlyMask [decimal]="true" [formControlName]="field.key.concat('_start')"
                 [id]="field.key.concat('_start')"
                 class="number-width"
                 (input)="onRangeChange(true)"
                 placeholder="0.00" />
          <label>to</label>
          <input type="text" digitOnlyMask [decimal]="true" [formControlName]="field.key.concat('_end')"
                 [id]="field.key.concat('_end')"
                 class="number-width"
                 (input)="onRangeChange(true)"
                 placeholder="0.00" />
          <input type="search" [formControlName]="field.key" [id]="field.key" value="" class="dynamic-filter-field--hidden" />
        </div>
      </ng-container>

      <div *ngIf="validationMessage" class="alert-box alert-box--danger">
        {{validationMessage}}
      </div>

    </ng-container>

  <ng-template #doubleTemplate>
    <!--DOUBLE DECIMAL / CURRENCY / DOUBLE  -->
    <div class="dynamic-filter-field__numeric-range">
      <input type="text" digitOnlyMask
             [decimal]="true"
             [formControlName]="field.key"
             [id]="field.key"
             class="number-width"
             placeholder="0.00"
             (change)="onDecimalChange()"/>
    </div>
  </ng-template>

  <ng-template #percentTemplate>
    <!-- PERCENTAGE -->
    <div class="dynamic-filter-field__numeric-range">
      <input type="text" digitOnlyMask [decimal]="true" [formControlName]="field.key.concat('_percent')"
             [id]="field.key.concat('_percent')"
             class="number-width"
             placeholder="0.00" />
      <input type="search" [formControlName]="field.key" [id]="field.key" value="" class="dynamic-filter-field--hidden" />
    </div>
  </ng-template>

  <ng-template #datePicker>
    <!--DATE / DATE RANGE-->
    <fw-calendar #calPicker [formControlName]="field.key"
                 [singlePicker]="(displayType$ | async) === myConstants.fieldTypeSingleDatePicker"
                 [name]="field.key"
                 [placeHolder]="'Search...'" 
                 [dateValue]="filterForm.controls[field.key].value"
                 (onSelected)="selectedDate($event)"></fw-calendar>
  </ng-template>
  </div>

</ng-container>
