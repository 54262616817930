<ng-template #multiMenu>
  <div class="multi-menu">
    <div class="multi-tier-item" *ngFor="let item of menu; let i = index;">
      <fw-multi-tier-item [item]="item" [idx]="i" [level]="1" [toggled]="level1Idx === i" (initIdx)="initIdx($event)" (selected)="initSelected($event)"></fw-multi-tier-item>
      <div class="multi-tier-item" *ngFor="let subItem of item.subItems; let j = index;" [ngClass]="{'multi-tier-item--toggled': level1Idx === i}">
        <fw-multi-tier-item [item]="subItem" [idx]="j" [level]="2" [toggled]="level2Idx === j" (initIdx)="initIdx($event)" *ngIf="level1Idx === i" (selected)="initSelected($event)"></fw-multi-tier-item>
        <div class="multi-tier-item" *ngFor="let subSubItem of subItem.subItems; let k = index;" [ngClass]="{'multi-tier-item--toggled': level2Idx === j}">
          <fw-multi-tier-item [item]="subSubItem" [idx]="k" [level]="3" (initIdx)="initIdx($event)" *ngIf="level1Idx === i && level2Idx === j" (selected)="initSelected($event)"></fw-multi-tier-item>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<button fw-popover [template]="multiMenu" [closeOnClickOutside]="true" [showPopover]="open" (onShow)="openOrClose(true)" (onHide)="openOrClose(false)" (click)="openOrClose(!open)" class="app-btn app-btn--white multi-btn {{customMultiBtnCss}}" type="button" [disabled]="disabled" [title]="hoverText">
  {{ label }}
  <i class="fas fa-angle-down multi-btn--icon"></i>
</button>

