/*
	Modules is used for dynamically hosting the compliance containers
*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { InstallerModule } from '../installer/installer.module';

import { FwModule } from '../../../fw/fw.module';

//Include these 2 since will not be routing to them.
import { 
  InstallersComponent
  } from './components/index';


//Only need to import the Containers (maps to the db component names)
import { 
    CertificationComponent,
    GcInstallerRequirementComponent,
    InstallerComplianceComponent, 
    InstallerDashboardComponent,
    } from './containers/index';

@NgModule({
    declarations: [
        CertificationComponent,
        GcInstallerRequirementComponent,
      InstallerComplianceComponent,
        InstallerDashboardComponent,
        InstallersComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
      FwModule,
      InstallerModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        CertificationComponent,
        GcInstallerRequirementComponent,
        InstallerComplianceComponent,
        InstallerDashboardComponent
    ]
})

export class ComplianceContainerModule { }
