<div [ngClass]="{ 'loading-mask': loading$ | async }">
  <div *ngIf="address && metaLoaded && (definitions$ | async) as definitions">
    <fw-section-title [title]="address.uiLabel" [underline]="true" class="contact-address__title"></fw-section-title>
    <form *ngIf="!(showResults$ | async)" [formGroup]="contactService.contactForm.controls[this.address.formGroupName]" novalidate class="contact-address__form">
      <div class="contact-address__street" [ngClass]="{ 'contact-address__street-thin': displayType === contactConstantsService.displayTypePortalSummary }">
        <fw-dynamic-field class="contact-address__street--street"
                          [field]="staticMetaDataService.getFieldDefinition(definitions, 'line1')"
                          [form]="contactService.contactForm.controls[this.address.formGroupName]"
                          [operation]="operation"
                          [autoFocus]="focusLine1$ | async">
        </fw-dynamic-field>
        <div class="contact-address__options">
          <button *ngIf="!address.uiRequired" type="button"
                  class="app-btn-icon app-btn-icon--danger contact-address__options--delete"
                  (click)="deleteRecord()"
                  [disabled]="!contactService.canIDelete(canIEdit, contactConstantsService.contactMechanismCategoryAddress, address) || operation === myConstants.operationTypeDetails"
                  title="Delete">
            <span class="fas fa-trash "></span>
          </button>
          <button type="button" *ngIf="(needToVerify$ | async) && !(isMapped$ | async) && !(isOverridden$ | async)"
                  class="app-btn app-btn--danger contact-address__options--verify"
                  (click)="verifyAddress()"
                  [disabled]="!canIEdit || operation === myConstants.operationTypeDetails || !(canVerify$ | async)"
                  title="Verify Address. The address must be verified before save is allowed">
            <span class="fas fa-map-marker-question fa-lg contact-address__options--verify-icon"></span>
          </button>
          <label *ngIf="(isOverridden$ | async) as isOverridden" class="app-text--warning contact-address__options--verify">Overridden</label>
          <button *ngIf="(isValid$ | async) && !(isOverridden)" type="button"
                  class="app-btn-icon app-btn-icon--success contact-address__options--marker" title="Valid Address" (click)="openMap()">
            <span class="fas fa-map-marker-check fa-lg contact-address__options--verify-icon"></span>
          </button>
          <fw-dynamic-field class="contact-address__options--override"
                            [field]="staticMetaDataService.getFieldDefinition(definitions, 'sanitizeOverride')"
                            [form]="contactService.contactForm.controls[this.address.formGroupName]"
                            [operation]="(isOverridden$ | async) ? operation : myConstants.operationTypeDetails">
          </fw-dynamic-field>
        </div>
      </div>
      <div [ngClass]="{ 'contact-address__city': displayType !== contactConstantsService.displayTypePortalSummary, 'contact-address__city-thin': displayType === contactConstantsService.displayTypePortalSummary }">
        <fw-dynamic-field [ngClass]="{ 'contact-addresss__city--city': displayType=== contactConstantsService.displayTypePortalSummary, 'contact-address__city-thin--city': displayType === contactConstantsService.displayTypePortalSummary }"
                          [field]="staticMetaDataService.getFieldDefinition(definitions, 'city')"
                          [form]="contactService.contactForm.controls[this.address.formGroupName]"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [ngClass]="{ 'contact-addresss__city--state': displayType !== contactConstantsService.displayTypePortalSummary, 'contact-address__city-thin--state': displayType === contactConstantsService.displayTypePortalSummary }"
                          [field]="staticMetaDataService.getFieldDefinition(definitions, 'addressState')"
                          [selectItems]="addressStates"
                          [form]="contactService.contactForm.controls[this.address.formGroupName]"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [ngClass]="{ 'contact-addresss__city--zip': displayType !== contactConstantsService.displayTypePortalSummary, 'contact-address__city-thin--zip': displayType === contactConstantsService.displayTypePortalSummary }"
                          [field]="staticMetaDataService.getFieldDefinition(definitions, 'zipcode5')"
                          [form]="contactService.contactForm.controls[this.address.formGroupName]"
                          [operation]="operation">
        </fw-dynamic-field>
      </div>
      <div class="contact-address__year">
        <fw-dynamic-field *ngIf="contactTypeId === contactConstantsService.contactTypeIdCustomer"
                          [field]="staticMetaDataService.getFieldDefinition(definitions, 'yearBuilt')"
                          [form]="contactService.contactForm.controls[this.address.formGroupName]"
                          [operation]="!(userPriviledgesService.canEditAddressAge$ | async) ? myConstants.operationTypeDetails : operation">
        </fw-dynamic-field>
      </div>
      <div class="contact-address__hidden">
        <fw-dynamic-field [field]="staticMetaDataService.getFieldDefinition(definitions, 'longitude')"
                          [form]="contactService.contactForm.controls[this.address.formGroupName]"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="staticMetaDataService.getFieldDefinition(definitions, 'latitude')"
                          [form]="contactService.contactForm.controls[this.address.formGroupName]"
                          [operation]="operation">
        </fw-dynamic-field>
        <input [formControlName]="verifiedControlName" [id]="verifiedControlName" type="text">
      </div>

    </form>
  </div>

  <div *ngIf="showResults$ | async">
    <address-verify-results [address]="unverifiedAddress"
                            [sanitizedAddr]="verifiedResult"
                            [badAddress]="badAddress"
                            (selectedEvent)="onMatchSelected($event)">
    </address-verify-results>
  </div>
</div>
