/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input } from '@angular/core';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, IListButtonType, OrderTerm } from '../../../../../fw/dynamic-list/interfaces/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { DynamicListMethod } from '../../../../../fw/dynamic-list/enums/dynamic-list.enum';

@Component({
    selector: 'installer-certification',
    templateUrl: './installer-compliance.component.html'
})
export class InstallerComplianceComponent implements OnInit {
  @Input() data: any;

  extraData: any;

  listDefinition: IListDefinition;

  constructor(public dynamicListService: DynamicListService) {}

  ngOnInit() {
    this.loadListDefinition();
  }

  /*
      Define the list to be created
  */
  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Installer Compliance';
    const listObjectController = 'InstallerOldestCertificationViewModel';
    const listStoreName = 'installerOldestCertifications';
    const detailRoutePath = 'installer-compliance-with-certification-range';
    const listRowKeyId = 'installerId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('installerName')];

    let listDefinition = this.dynamicListService.createListDefinition('installerComplianceDetailOutlet',
        listObjectLabel,
        listObjectController,
        listStoreName,
        listRowKeyId,
        defaultListFilter,
        listColumns,
        detailRoutePath); 

    const enabledDefaults: ICustomButton = {
      title: '', icon: '', cssName: '', enabled: true,
      enabledMethod: DynamicListMethod.alwaysEnabled, eventName: ''
    };

    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: enabledDefaults }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    this.listDefinition = listDefinition; 
  }

    /*
        Define the columns in the list to be created.
    */
    loadListColumns(): IListColumn[] {
        let columnDefinitions: Array<IListColumn> = [];

      let colDef = new ListColumn('installerName');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('active');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('certificationLevel');
      colDef.visibleOnSmall = false;
      colDef.visibleOnMedium = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('certificationName');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('certificationEndDate');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

        return columnDefinitions;
    }

}
