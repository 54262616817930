<div *ngIf="installerId" class="portal">
  <div *ngIf="(workCrews$ | async) as workCrews"class="portal__detail--item">
    <installer-work-crew-metrics [installerId]="installerId" [workCrews]="workCrews"></installer-work-crew-metrics>
  </div>
  <div class="portal__detail--item">
    <installer-eligibility-summary [installerId]="installerId"></installer-eligibility-summary>
  </div>
  <div class="portal__detail--item">
    <installer-workcrews [installerId]="installerId" [canEdit]="canIManageInstaller"></installer-workcrews>
  </div>
  <div class="portal__detail--item">
    <installer-work-crew-technicians [providerId]="providerId"
                                     [installerId]="installerId"
                                     [includeOldestRequirement]="includeOldestRequirement"></installer-work-crew-technicians>
  </div>
  <div class="portal__detail--item">
    <installer-work-crew-schedule-exclusions [installerId]="installerId"></installer-work-crew-schedule-exclusions>
  </div>

</div>
