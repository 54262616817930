import { Component, OnInit, Input, OnDestroy} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'project-work-order-item-summary',
  templateUrl: './project-work-order-item-summary.component.html'
})
export class ProjectWorkOrderItemSummaryComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  workOrderId: number = 0;
  projectId: number = 0;
  subscription: Subscription = new Subscription();

  @Input() isSchedule: boolean;

  constructor(
    public activeRoute: ActivatedRoute,
    public projectService: ProjectService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.workOrderId = +paramMap.get('id');
      this.projectId = paramMap.has('projectId') ? +paramMap.get('projectId') : +paramMap.get('portalEntityId');
      this.setListDefinition();
    });
  }

  setListDefinition(): void {
    let listDefinition = this.projectService.loadWorkOrderItemListDefinition(this.workOrderId, false, true, this.isSchedule);
    this.listDefinition$.next(listDefinition);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
