import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IMenuItem } from '../interfaces/i-menu-item';
import { IAppConstants, appConstants } from '../../../shared/constants/index';
import { ITabRequest } from '../../fw-shared/components/fw-app-tabs/interfaces/i-tab-request';
import { TabsService } from '../../fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';
import { HomHubService } from '../../../auth/services';
import { StoreCleanUpService } from '../../../app/portals/portal-shared/services/store-cleanup.service';
import * as fromRoot from '../../../app/store/reducers/index';
import * as fromAuth from '../../../auth/store/index';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(public router: Router,
    public tabsService: TabsService,
    public homHubService: HomHubService,
    public storeCleanupService: StoreCleanUpService,
    public rootStore: Store<fromRoot.IState>,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  items: Array<IMenuItem>;
  showingMiniMainMenu = false;
  isVisible = false;
  hasTabs$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  switchPortal(item: IMenuItem): void {
      const data: ITabRequest = {
        text: item.text,
        portalId: item.portalId,
        portalEntityId: null,
        level2EntityName: null,
        level2EntityId: null, 
        level3EntityName: null,
        level3EntityId: null,
        level2Text: '',
        level3Text: ''
      },
      event: IHomEventEmitter = {
        requestor: 'menu-service',
        event: this.myConstants.emitterEventTabLevel1Open,
        action: '',
        data: data
      };
      this.tabsService.emitTabEvent(event);
      this.showingMiniMainMenu = false;
    }

  toggleVerticalNav(): void {
    this.showingMiniMainMenu = !this.showingMiniMainMenu;
  }
  //Allows the entire menu to be hidden.
  //Usage:  if not authenticated can hide the entire menu
  setVisibility(visible: boolean) {
    this.isVisible = visible;

  }

  logout(): void {
    this.router.navigate(['logout'])
      .then(x => {
        this.homHubService.disconnectHub();
        this.rootStore.dispatch(new fromAuth.Logout({ initLogoutUserSessionEvent: true }));
        this.hasTabs$.next(false);
        this.tabsService.initSavedTabs();
        this.storeCleanupService.cleanUpAllStores();
      });

  }
  
}
