import { Component, Input} from '@angular/core';
import {  IProject } from '../../../../app/portals/view-models';

@Component({
  selector: 'fw-project-customer-contact',
  templateUrl: './project-customer-contact.component.html'
})
export class ProjectCustomerContactComponent  {
  @Input() row: IProject;

 }
