<div [ngClass]="{ 'loading-mask': !(objectData$ | async) || (loading$ | async)}">
  <fw-component-title [title]="'Schedule Moveup'"></fw-component-title>
  <ng-container *ngIf="(objectData$ | async) as objectData">
    <form *ngIf="form && (mds.mdsReady$ | async)" [formGroup]="form" novalidate class="form-wrapper"
          [ngClass]="{'form-wrapper--edit': operation !== myConstants.operationTypeDetails }">
      <fw-dynamic-field [field]="mds.getFieldDefinition('requestedDate')"
                        [form]="form"
                        [operation]="operation">
      </fw-dynamic-field>
      <fw-dynamic-field [field]="mds.getFieldDefinition('immediateMoveUp')"
                        [form]="form"
                        [operation]="operation">
      </fw-dynamic-field>
      <fw-dynamic-field [field]="mds.getFieldDefinition('moveUpText')"
                        [form]="form"
                        [operation]="operation">
      </fw-dynamic-field>
      <div class="alert-box alert-box--danger">*Requested Date OR Move Up Text are required*</div>
    </form>
    <fw-error-box [errorData]="errorData$ | async" [onForm]="true"></fw-error-box>
    <fw-component-footer [lastUpdate]="objectData.hasOwnProperty('updateDate') ? objectData.updateDate : '' "></fw-component-footer>
  </ng-container>

  <div class="app-btn-box" *ngIf="form && (mds.mdsReady$ | async)">
    <button type="button" class="app-btn app-btn--cancel" (click)="cancel()" [title]="operation === myConstants.operationTypeDetails ? 'Close' : 'Cancel'">
      {{ operation === myConstants.operationTypeDetails ? 'Close' : 'Cancel' }}
    </button>
    <button *ngIf="operation !== myConstants.operationTypeDetails" type="button" class="app-btn app-btn--primary"
            (click)="save()" [disabled]="!isValid() ||  !form.dirty || (loading$ | async)" title="Save">
      Save
    </button>
  </div>
</div>
