import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FwModule } from '../../../fw/fw.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../shared/shared.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { ContactModule } from '../../contact/contact.module';
import { SchedulerModule } from '../scheduler/scheduler.module';
import { UserDashboardRoutingModule } from './user-dashboard-routing.module';
import { SmsModule } from "../../sms/sms.module";

import { UserPriviledgesService } from '../../../auth/services/index';
import { UserDashboardService } from './services/user-dashboard.service';

//containers
import {
  UserDashboardComponent,
  DispatchedWorkOrdersComponent,
  OpenExternalNotesComponent,
  UnassignedExternalNotesComponent,
  OpenProjectsComponent,
  PendingClosedProjectsComponent,
  SssInvitesRequiringActionComponent,
  UndispatchedWorkOrdersComponent,
  UnreceivedInventoryComponent,
  UnscheduledWorkOrdersComponent,
  PendingTasksComponent,
 SssWorkOrderInvitationsComponent
} from './containers/index';

import {
  SssOptionsPresentedComponent,
  WorkOrderInvitationSummaryComponent
} from './components/index';

//guards
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';

//resolvers
import { CommunicationShortcutResolver } from '../resolvers';


@NgModule({
  declarations: [
    UserDashboardComponent,
    DispatchedWorkOrdersComponent,
    OpenExternalNotesComponent,
    UnassignedExternalNotesComponent,
    OpenProjectsComponent,
    PendingClosedProjectsComponent,
    SssInvitesRequiringActionComponent,
    UndispatchedWorkOrdersComponent,
    UnreceivedInventoryComponent,
    UnscheduledWorkOrdersComponent,
    PendingTasksComponent,
    SssOptionsPresentedComponent,
    WorkOrderInvitationSummaryComponent,
    SssWorkOrderInvitationsComponent,
    SssInvitesRequiringActionComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      FwModule,
      FwSharedModule,
      SharedModule,
      PortalSharedModule,
      ContactModule,
      SchedulerModule,
      SmsModule,
      UserDashboardRoutingModule
    ],
  providers: [
    IsCurrentGuard,
    UserPriviledgesService,
    UserDashboardService,
    CommunicationShortcutResolver
  ]
})

export class UserDashboardModule {}
