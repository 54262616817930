/*  Use only when have multiple of the same component within the same component
 *  Like Email of Emails and Phone of Phones
 */
import { Injectable, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Store, select } from '@ngrx/store';

import { Subscription,  Observable  } from 'rxjs';
import { debounceTime,  filter,  distinctUntilChanged, map, take } from 'rxjs/operators';
import { cloneDeep }  from 'lodash'; //pulling for just the methods needed is not working at this time, but keep trying

//pipes
import { HomPhonePipe } from 'hom-lib/hom-pipes';

//interfaces
import { IAppConstants, appConstants } from '../../../shared/constants/index';
import { IFieldDefinition } from '../../dynamic-forms/index';
import { IErrorData, ISelectResolverData } from '../../../shared/interfaces/index';

//store
import * as fromSelectionLists from '../../../shared/store/selectionLists/index';
import * as fromMetaData from '../../dynamic-list/store/reducers/meta-data.reducer';
import { getMetaDataByType } from '../../dynamic-list/store/selectors/meta-data.selectors';
import * as fromRoot from '../../../app/store/reducers/index';
import * as fromFeature from '../store/reducers/feature.reducer';
import { ISelectionListByKey } from '../interfaces';
import { ISelectListData } from '../../../shared/store/selectionLists/index';


@Injectable({
  providedIn: 'root'
})
export class StaticMetaDataService  implements  OnDestroy {
  subscription: Subscription = new Subscription();
  parentId: number;

  constructor(
    public datePipe: DatePipe,
    public decimalPipe: DecimalPipe,
    public phonePipe: HomPhonePipe,
    @Inject(appConstants) public myConstants: IAppConstants,
    public store: Store<fromFeature.IAllDynamicData>,
    public rootStore: Store<fromRoot.IState>) {}

  getFieldDefinitions(storeName: string): Observable<fromMetaData.IMetaDataState> {
    return this.store.pipe(
      select(getMetaDataByType(storeName)))
      .pipe(filter((state: fromMetaData.IMetaDataState) => state.fieldDefinitions.length > 0));
  }

  /*
   * Dynamically creates a form group based on display fields, operation, data and field definitions
   * What you do with it is up to you.
   */
  createDynamicFormGroup(fieldDefinitions: IFieldDefinition[], displayFields: string[], row: any, operation: string, selectionLists: ISelectionListByKey[] = null ) {
    let group = {};
    displayFields.forEach(key => {
      const fieldDef = this.getFieldDefinition(fieldDefinitions, key);
      if (!fieldDef) {
        console.log('DEV ERROR:  displayField: ', key, ' does not exist in fieldDefinitions', fieldDefinitions);
      }

      //Set the value for the field.  If need to add any emitters to the field, looks like should do that within this method.  See API for FormControl.
      let fieldValue = !row
        ? this.getDefaultValue(fieldDef.fieldType)
        : this.getDataValue(fieldDefinitions, key, row, operation, selectionLists);
      let isDisabled = this.isDisabled(fieldDefinitions, key, operation);

      //Create the field (formcontrol)
      if (operation === this.myConstants.operationTypeCreate && fieldDef.isId) {
        //for id fields, the value will be set in the back end
        fieldDef.required = false;
        fieldDef.validations = [];
      }
      group[fieldDef.key] = fieldDef.validations.length > 0
        ? new FormControl({ value: fieldValue, disabled: isDisabled }, { validators: fieldDef.validations, updateOn: 'change' }) //if need to add async validators, add as 3rd parm here
        : new FormControl({ value: fieldValue, disabled: isDisabled });
    });

    let  form: FormGroup = new FormGroup(group);

    //After form has been defined, add setting of any custom validation messages
    displayFields.forEach(key => {
      const field = this.getFieldDefinition(fieldDefinitions, key);
      const fieldControl = form.controls[field.key];
      if (!fieldControl) {
        console.log('DEV ERROR:  displayField: ', key, ' does not exist in fieldDefinitions');
      }
      //Debounce just gives the user some time to type in the value before it actually validates 
      //  - so you don't get the error for invalid email when you haven't typed in the full email yet
      // validationMessages contains the POSSIBLE messages this control can receive
      const waitTime: number = field.fieldType === this.myConstants.fieldTypeBoolean ? 0 : 500;
      this.subscription.add(fieldControl.valueChanges.pipe(
        debounceTime(waitTime),
        distinctUntilChanged())
        .subscribe(value => {
          //if a component wants to listen to the change event for any field, they can subscribe to this observable and filter by key (or not)
          if (field.validationMessages) {
            fieldDefinitions= this.initValidationMessage(fieldDefinitions, field.key);
            fieldDefinitions = this.setValidationMessage(fieldDefinitions, field.key, fieldControl);
          }
        }));
    });

    return form;
  }

  /*
   * Returns the row from field definitions with this key
   */
  getFieldDefinition(fieldDefinitions: IFieldDefinition[], key: string): IFieldDefinition {
      if (!key) return null;
    return fieldDefinitions.find(k => k.key == key);
  }

  /*
   * Returns the seeded definition value for a given field using the key for that field and the property name like 'label'
   */
  getPropertyValue(fieldDefinitions: IFieldDefinition[], key: string, definitionName: string): any {
    const fieldDef = this.getFieldDefinition(fieldDefinitions, key);
    if (!fieldDef.hasOwnProperty(definitionName)) {
      console.log('DEV ERROR: The property name you are trying to access does not exist on IFieldDefinition: ', definitionName);
      return null;
    }
    return fieldDef.hasOwnProperty(definitionName) ? fieldDef[definitionName] : this.defaultByType(fieldDef, definitionName);
  }

  /*
   *  Inits the validation message for the requested field 
   */
  initValidationMessage(fieldDefinitions: IFieldDefinition[], key: string): IFieldDefinition[] {
    const idx = fieldDefinitions.findIndex(x => x.key === key);
    fieldDefinitions[idx].validationMessage = '';
    return fieldDefinitions;
  }

  /*
   *  Called when a field changes, checks for matches between the validationMessages for this field.key and the validations set on this field.key
        If match found, sets validation message.
   */
  setValidationMessage(fieldDefinitions: IFieldDefinition[], key: string, c: AbstractControl): IFieldDefinition[] {
    const idx = fieldDefinitions.findIndex(x => x.key === key);
    if (idx > -1) {
      if ((c.touched || c.dirty) && c.errors) {
        fieldDefinitions[idx].validationMessage = Object.keys(c.errors).map(key => fieldDefinitions[idx].validationMessages[key]).join(' ');
      }

      return fieldDefinitions;
    }
  }


  /*
     field:  row from IFieldDefinition for the field you need the value of
     row:  row from your data object
     operation:  crud value as defined in IAppConstants.operation
      selectionList:  key, value where value = ISelectionList - so a list of selection lists accessible by the key to that selection list
  */
  getDataValue(fieldDefinitions: IFieldDefinition[], key: string, row: any, operation: string, selectionLists: ISelectionListByKey[] = null): any {
    const idx = fieldDefinitions.findIndex(x => x.key === key);
    const fieldDef = fieldDefinitions[idx];

    let fieldValue: any;
        //Handle fkeys - only want to use the first part for fkeys.
        //Example: certificationType_lookupValueId.  Field Definition infor wil be under certificationType.
     let fieldKey = fieldDef.key.split('_')[0];
        switch (fieldDef.fieldType){
          case this.myConstants.fieldTypeBoolean:
            fieldValue = !row || !row[fieldKey] ? false : row[fieldKey];
            break;
          case this.myConstants.fieldTypePhone:
            fieldValue = this.phonePipe.transform(row[fieldKey], '');
            break;
          case this.myConstants.fieldTypeDate:
            fieldValue = this.datePipe.transform(row[fieldKey], 'MM/dd/yyyy');
            break;
          case this.myConstants.fieldTypeDateTime:
          case this.myConstants.fieldTypeDateTimeNull:
                fieldValue = this.datePipe.transform(row[fieldKey], 'short');
            break;
          case this.myConstants.fieldTypeDoubleDecimal:
          case this.myConstants.fieldTypeCurrency:
            fieldValue = this.decimalPipe.transform(row[fieldKey], '1.2-2');
            break;
          case this.myConstants.fieldTypeSelect:
          case this.myConstants.fieldTypeEntity:
              fieldValue = null;
              if (!selectionLists) {
                console.log('DEV ERROR:  Selection List bucket for key: ', key, ' with objectType: ', fieldDef.selectListDefinition.objectType, ' and storeName: ', fieldDef.selectListDefinition.storeName, ' is not defined in selectionLists.reducers or Seed data is incorrect');
                break;
            }
            const selectList = selectionLists.find(l => l.key == fieldDef.selectListDefinition.storeName);
              if (!selectList) {
                console.log('DEV ERROR:  Selection List bucket for key: ', key, ' with objectType: ', fieldDef.selectListDefinition.objectType, ' and storeName: ', fieldDef.selectListDefinition.storeName, ' is not defined in selectionLists.reducer', fieldDef);
                break;
            }

            const keyToUse = fieldDef.selectListDefinition.keyField;

            if ((operation === this.myConstants.operationTypeCreate && !row[keyToUse]) ) {
              //may have seed data with defaults
              return null;
            }
            if (fieldDef.selectListDefinition.keyField !== '' && !row.hasOwnProperty(fieldDef.selectListDefinition.keyField) ) {
              console.log('DEV ERROR SEEDING: Verify seed data for keyField in selectionListSettings is correct.  Corresponding row property was not found: ', fieldDef.selectListDefinition.keyField);
            }

            let labelName = fieldDef.selectListDefinition.labelProperty;
            let valueName = fieldDef.selectListDefinition.valueProperty;
            let items = selectList.items;
            if (!items) {
                fieldValue = null;
                break;
            }

            if (row.hasOwnProperty(fieldDef.selectListDefinition.keyField)) {
              const matchOn = fieldDef.selectListDefinition.listType === this.myConstants.dataTypeEnum ? labelName : valueName;
              const selectedItem = items.find(item => item[matchOn] == row[keyToUse]);
              fieldValue = selectedItem;
            } 
            break;
          default:
              fieldValue = row[fieldKey];
              break;
        }
     
        return fieldValue;
  }

  getDefaultDisplayFields(fieldDefinitions: IFieldDefinition[] ): string[] {
     return fieldDefinitions.map(f => f.key);
    }

  isDisabled(fieldDefinitions: IFieldDefinition[], key: string, operation: string): boolean {
      let disabled = false;

    const fieldDef = this.getFieldDefinition(fieldDefinitions, key);

      if (operation === this.myConstants.operationTypeDetails 
          || fieldDef.alwaysReadOnly 
          || !fieldDef.isEditable) {
        disabled = true;
      }
      return disabled;
  }

 
  /* NOT CURRENTLY USED IN A STATIC IMPLEMENTATION
   * Passing in all fields displayed on the form.
   * Method will weed out display fields that are not defined as selection lists
   * requestor:  details or filter
   */
  loadSelectionLists(fieldDefinitions: IFieldDefinition[], displayFields: string[], requestor: string, parentId: number = -1): Observable<ISelectionListByKey[]> {

    let selectionLists: ISelectionListByKey[] = [];
    if (!displayFields.length) {
      displayFields = this.getDefaultDisplayFields(fieldDefinitions);
    }

    let selectionListFields: IFieldDefinition[] = fieldDefinitions
      .filter(field => displayFields.find(k => k == field.key) !== undefined && this.validSelectField(field));

    if (!selectionListFields) return;

    //map to a new array with the spread operator to ensure have a distinct list of stores to fill
    const selectData: ISelectResolverData[] = [...new Set(selectionListFields.map(f => {
      return {
        storeName: f.selectListDefinition.storeName,
        parentDependent: f.selectListDefinition.methodName && f.selectListDefinition.methodByKey ? true : false,
        parentId: -1
      }
    }))];

    return this.rootStore.select('selectionLists')
      .pipe(take(1),
        map((state: fromSelectionLists.IStoreState) => {
        selectData.forEach(item => {
          if (state.hasOwnProperty(item.storeName)) {
            //not handling parent dependent
            const useParentId = item.parentDependent ? this.parentId : -1;
            const objData: ISelectListData = state[item.storeName].objData && state[item.storeName].objData.length > 0
              ? state[item.storeName].objData.find(x => x.parentId == useParentId)
              : null;
            selectionLists.push({ key: item.storeName, items: objData ? objData.data : [] });
          }
        });
          return selectionLists;
      }));
  }

  validSelectField(field: IFieldDefinition): boolean {
    const isSelect = field.fieldType === this.myConstants.fieldTypeSelect ||
      field.fieldType === this.myConstants.fieldTypeEntity ||
      field.fieldType === this.myConstants.fieldTypeEditableSelect ||
      field.fieldType === this.myConstants.fieldTypeFilterableSelect;

    return isSelect && field.visibleOnEdit
      && field.hasOwnProperty('selectListDefinition')
      && field.selectListDefinition !== null
      && field.selectListDefinition.storeName !== ''
      && !field.selectListDefinition.useCustomResolver
      && !field.selectListDefinition.forFilterOnly;
 }
 
   defaultByType(object: any, property: string) {
    return this.getDefaultValue(typeof object[property]);
  }
  
   getDefaultValue(fieldType: string) {
    switch (fieldType) {
      case 'number':
        return 0;
      case 'checkbox':
      case 'boolean':
        return false;
      case 'date':
      case 'dateTime':
        return null;
      case 'entity':
      case 'select':
      case 'object':
        return {};
      default:
        return '';
    }
  }

  clearValidationMessages(fieldDefinitions: IFieldDefinition[], displayFields: string[]): IFieldDefinition[] {
    let copyOf: IFieldDefinition[] = cloneDeep(fieldDefinitions);
    displayFields.forEach(key => {
      const fieldDef = this.getFieldDefinition(copyOf, key);
      fieldDef.validationMessage = '';
    });
    return copyOf;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}


