import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//guards
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';
import { AuthGuard } from '../../../auth/services/auth-guard.service';

//resolvers
import {
  ContactTypeSpecificationResolver,
  ContactEmailMetaDataResolver,
  ContactPhoneMetaDataResolver,
  ContactAddressMetaDataResolver,
  ContactMetaDataResolver
} from '../../contact/resolvers';

import {
  ProviderLocationServiceListResolver,
  SelectionListResolver,  AllMyProviderLocationsResolver,
  CommunicationShortcutResolver,
  CommunicationDocumentTypeResolver,
  DocumentTypeResolver,
  EntityResolver
} from '../resolvers/index';

import {
  InstallerInformationResolver,
  InstallerWorkCrewTechnicianResolver,
  InstallerWorkCrewResolver,
  InstallerTechnicianResolver,  TechnicianInformationResolver,
  InstallerRolesResolver
} from './resolvers/index';

//components
import {
  InstallerTechnicianWithCertificationRangeComponent,
  PlsEntityDetailComponent,
  EmptyComponent
} from '../portal-shared/components/index';

import {
  InstallerCertificationComponent,
  InstallerWorkCrewComponent,
  InstallerWorkCrewScheduleExclusionComponent,
  InstallerWorkCrewWorkCategoryComponent
} from './components/index';

//containers
import {
  InstallerCertificationsComponent,
  InstallerDetailSummaryComponent,
  InstallerNotesComponent,
  TechnicianManagerComponent,
  WorkCrewManagerComponent,
  InstallerWorkCrewTechnicianCreateContainerComponent,
  InstallerCommunicationEventsComponent,
  InstallerWorkCrewTechnicianRoleContainerComponent,
} from './containers/index';
import { SmsConversationComponent } from "../../sms/containers";
import { LoadingPageComponent } from '../../../shared/components';
import { InstallerComponent } from '../portal-shared/containers';


const portalRoutes: Routes = [
  {
    path: 'installer', //componentless route
    children: [
      {
        path: ':portalId/:portalEntityId', //not really needed at this time, but keeping so tab factory can always call the same route structure
        component: InstallerComponent,
        canActivate: [AuthGuard],
        data: {
          includeReadOnly: false
        },
       resolve: {
          infoLoaded: InstallerInformationResolver,
          providerLocations: AllMyProviderLocationsResolver
        },
        //all details
        children: [
          {
            path: 'sms-convo/:id/:phone/:name/:parent',
            outlet: 'chatbar',
            component: SmsConversationComponent,
            resolve: {
              hasShortcuts: CommunicationShortcutResolver,
              hasDocTypes: CommunicationDocumentTypeResolver,
              hasFileTypes: DocumentTypeResolver,
              hasEntities: EntityResolver
           }
          },
          {
            path: 'installer-empty',
            outlet: 'portal-detail',
            component: EmptyComponent
          },
          {
            path: 'loading/:container',
            outlet: 'portal-detail',
            component: LoadingPageComponent
          },
         {
            path: 'installer-detail-summary/:id', 
            outlet: 'portal-detail',
            component: InstallerDetailSummaryComponent,
            children: [
              {
                path: 'installer-technician-with-certification-range/:id',
                //canActivate: [IsCurrentGuard],
                outlet: 'installerTechnicianDetailOutlet',
                component: InstallerTechnicianWithCertificationRangeComponent
              },
              {
                path: 'installer-work-crew/:id',
               component: InstallerWorkCrewComponent,
                outlet: 'workCrewCreateOutlet'
              }
            ]
          },
          {
            path: 'installer-certifications/:id', 
            component: InstallerCertificationsComponent,
            outlet: 'portal-detail',
            data: { certificationAssignment: '0' },
            children: [
              {
                path: 'installer-certification-detail/:id', 
                //canActivate: [IsCurrentGuard],  view
                outlet: 'installerCertificationOutlet', 
                component: InstallerCertificationComponent
              }
              ]
          },
           {
            path: 'installer-gc-requirements/:id', 
            component: InstallerCertificationsComponent,
            outlet: 'portal-detail',
            data: { certificationAssignment: '1' },
            children: [
              {
                path: 'installer-gc-requirement-detail/:id', 
                //canActivate: [IsCurrentGuard],  view
                outlet: 'installerGcRequirementOutlet', 
                component: InstallerCertificationComponent
              }
          ]
          },
          {
            path: 'installer-notes/:id', 
            outlet: 'portal-detail',
            component: InstallerNotesComponent
          },
          {
            path: 'installer-communication-events/:id',
            outlet: 'portal-detail',
            component: InstallerCommunicationEventsComponent
          },
          {
            path: 'workcrew-manager/:id', 
            //to handle dashboard to tech subtab
            outlet: 'portal-detail',
            data: {
              contactTypeId: '4'
            },
            title: 'workcrew-manager',
            resolve: {
              crewLoaded: InstallerWorkCrewResolver,
              hasContactMetaData: ContactMetaDataResolver,
              hasTypeData: ContactTypeSpecificationResolver,
              hadPhoneMetaData: ContactPhoneMetaDataResolver,
              hasEmailMetaData: ContactEmailMetaDataResolver,
              hasAddrMetaData: ContactAddressMetaDataResolver,
              selectListsLoaded: SelectionListResolver,
              techsLoaded: InstallerWorkCrewTechnicianResolver
            },
            component: WorkCrewManagerComponent,
            children: [
              {
                path: 'installer-work-crew-technician-with-certification-range/:id',
                outlet: 'workCrewTechnicianDetailOutlet',
                component: InstallerTechnicianWithCertificationRangeComponent
              },
              {
                path: 'installer-work-crew-technician-create/:id',
                outlet: 'workCrewTechnicianDetailOutlet',
                component: InstallerWorkCrewTechnicianCreateContainerComponent
              },
              {
                path: 'pls-entity-detail/:id',
                outlet: 'workCrewPlServiceOutlet',
                component: PlsEntityDetailComponent,
                data: {
                  includeReadOnly: false
                },
                resolve: {
                  providerLocations: AllMyProviderLocationsResolver,
                  pls: ProviderLocationServiceListResolver
                }
              },
              {
                path: 'installer-work-crew-work-category/:id',
                outlet: 'workCrewWorkCategoryOutlet',
                canActivate: [IsCurrentGuard],
                component: InstallerWorkCrewWorkCategoryComponent,
                resolve: { selectListsLoaded: SelectionListResolver }
              },
              {
                path: 'installer-work-crew-schedule-exclusion/:id',
                outlet: 'workCrewScheduleExclusionOutlet',
                resolve: { selectListsLoaded: SelectionListResolver },
                component: InstallerWorkCrewScheduleExclusionComponent
              }
            ]
          },
          {
          path: 'technician-manager/:id', 
            //called from compliance dash, installer summary, work crew manager
          outlet: 'portal-detail',
            data: {
              contactTypeId: '4'
            },
            title: 'technician-manager',
            resolve: {
              hasContactMetaData: ContactMetaDataResolver,
              hasTypeData: ContactTypeSpecificationResolver,
              hadPhoneMetaData: ContactPhoneMetaDataResolver,
              hasEmailMetaData: ContactEmailMetaDataResolver,
              hasAddrMetaData: ContactAddressMetaDataResolver,
              selectListsLoaded: SelectionListResolver,
              infoLoaded: TechnicianInformationResolver
            },
          component: TechnicianManagerComponent,            //will have children
          children: [
              {
                path: 'installer-technician-certification-detail/:id',
                outlet: 'installerTechnicianCertificationOutlet',
                component: InstallerCertificationComponent
            },
            {
              path: 'installer-technician-gc-requirement-detail/:id',
              outlet: 'installerTechnicianGcRequirementOutlet',
              component: InstallerCertificationComponent
            },
            {
              
              path: 'technician-role/:id',
              outlet: 'techRoleOutlet',
              component: InstallerWorkCrewTechnicianRoleContainerComponent,
              resolve: {
                roles: InstallerRolesResolver
              }
            }
            ]
        }
        ]
      }
    ]
  }
];


@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class InstallerRoutingModule { }
