import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IMetaDataState } from '../../../../fw/dynamic-list/store';
import { IFieldDefinition } from '../../../../fw/dynamic-forms';
import { IContactMechanismPhone, IContactMechanismEmail } from '../../../portals/view-models';

import { ContactConstantsService } from '../../services/index';
import { StaticMetaDataService } from '../../../../fw/dynamic-list/services/static-meta-data.service';

@Component({
  selector: 'contact-information',
  templateUrl: './contact-information.component.html'
})

//if need contact info, pass that as an input
export class ContactInformationComponent implements OnInit {
  @Input() phones: IContactMechanismPhone[];
  @Input() emails: IContactMechanismEmail[];
  @Input() operation: string;
  @Input() requestTime: string; //to handle subsequent create request for the same contact type
  @Input() displayType: string;
  @Input() canIEdit: boolean;

  @Output() public deleteEvent = new EventEmitter<IHomEventEmitter>();

  public phoneFieldDefinitions: IFieldDefinition[];
  public emailFieldDefinitions: IFieldDefinition[];

  constructor(
    public contactContactsService: ContactConstantsService,
    public staticMetaDataService: StaticMetaDataService) { }

  ngOnInit(): void {
    this.staticMetaDataService.getFieldDefinitions('phones').pipe(take(1))
      .subscribe((state: IMetaDataState) => {
        let defs: IFieldDefinition[] = cloneDeep(state.fieldDefinitions)
        this.phoneFieldDefinitions = defs;
      });

    this.staticMetaDataService.getFieldDefinitions('emails').pipe(take(1))
      .subscribe((state: IMetaDataState) => {
        let defs: IFieldDefinition[] = cloneDeep(state.fieldDefinitions);
        this.emailFieldDefinitions = defs;
      });
  }

  public onDelete(event: IHomEventEmitter): void {
    this.deleteEvent.emit(event);
  }

}
