import { Component, Input, OnInit,   OnChanges, SimpleChanges, EventEmitter, Output} from '@angular/core';

import {  INotification } from '../../../view-models/index';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit, OnChanges{
  @Input() notifications: INotification[];

  @Output() closeRequest = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['operation'] && !changes['operation'].isFirstChange()) {
    }
  }

  close() {
    this.closeRequest.emit();
  }
}
