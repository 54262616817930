  <!-- ERROR BOX AS ng-content from project -->
<ng-content></ng-content>
<ng-container *ngIf="(project$ | async) as project">
  <!--extra project check to help reduce errors during recycling-->
  <div class="phls" *ngIf="project" [ngClass]="{ 'loading-mask': loading$ | async }">
    <!-- PROJECT MARGINS -->
    <div class="phls__margins">
      <project-margins-container [projectId]="projectId"
                                 [providerMarginHigh]="project.providerMarginHigh"
                                 [providerMarginLow]="project.providerMarginLow"
                                 [reload]="reload$ | async"
                                 (marginSetEvent)="onMarginSet($event)">
      </project-margins-container>
    </div>
    <div class="phls__scrollcon">
      <!-- CUSTOMER INFO -->
      <div class="phls__scrollcon__customer">
        <i class="fas fa-user-circle phls__scrollcon__customer__icon"></i>
        <fw-button-title-bar title=""
                             [entityLabel]="project.customerName"
                             [entityColor]="flagColor$ | async"
                             [includeBorder]="false"
                             [canIEdit]="canIEdit$  | async"
                             [operation]="operation$ | async"
                             [disabled]="(operation$ | async) === myConstants.operationTypeEdit && !(contactManager.isFormValid$ | async)"
                             [staleData]="hasStaleData$ | async"
                             [showEdit]="false"
                             [showRefresh]="false"
                             [showDelete]="false"
                             [customButtons]="customButtons"
                             [forHighLevelSummary]="true"
                             (customEvent)="onCustom($event)">

          <contact-customer-flag-container *ngIf="project.customer_contactId"
                                           [contactId]="project.customer_contactId"
                                           [canFlagCustomer]="(canIEdit$  | async) && (canFlagCustomer$ | async) && (project.currentProjectStatusCode !== myConstants.projectStatusClosed)"
                                           [hasStaleData]="hasStaleData$ | async">
          </contact-customer-flag-container>
        </fw-button-title-bar>
      </div>
      <div class="phls__scrollcon__pcm" *ngIf="(contact$ | async) as contact">
        <!--{{ preferredContactLabel }}:-->
        <div class="phls__scrollcon__pcm__val">
          <fw-communication-preference [row]="contact"
                                       [forHighLevelSummary]="true"
                                       [displayType]="communicationEventService.displayTypeBoth">
          </fw-communication-preference>
        </div>
      </div>
      <!-- JOB SITE INFO -->
      <div class="phls__scrollcon__jsm">
        <job-site-manager *ngIf="project.customer_contactId"
                          [projectId]="projectId"
                          [contactId]="project.customer_contactId"
                          [canIEdit]="canIEdit$  | async"
                          [hasStaleData]="hasStaleData$ | async"
                          [projectStatus]="project.currentProjectStatusCode"
                          [reload]="reload$ | async">
        </job-site-manager>
      </div>
      <!-- PROJECT INFORMATION -->
      <div class="phls__scrollcon__pi">
        <fw-section-title title="Project Information" [underline]="true" [icon]="dashboardIcon$ | async"></fw-section-title>
        <project-counts-container [projectId]="projectId"
                                  [isWarranty]="isWarranty"
                                  [canIEdit]="(canIEdit$  | async) && (branchProgramsLoaded$ | async)"
                                  [canIEditCbs]="canIEditCbs$ | async">
        </project-counts-container>
      </div>
      <!-- TOOLBOX -->
      <div class="phls__scrollcon__tb">
        <fw-section-title title="{{ project && (project.generalContractorName || '').toLowerCase().startsWith('hom ') ? 'HŌM Solutions Inc.' : project.generalContractorName || ''}} Toolbox"
                          [underline]="true"
                          icon="fa-toolbox"
                          [titleCase]="false">
        </fw-section-title>
        <ng-container *ngIf="!isWarranty">
          <button class="phls__scrollcon__tb__sync-btn"
                  [disabled]="!(poCount$ | async) || !(canIEdit$ | async)"
                  type="button"
                  (click)="syncPurchaseOrders()"
                  title="Synchronize Purchase Orders">
            <i class="fas fa-exchange phls__scrollcon__tb__sync-btn__icon"></i> Synchronize Purchase Orders from {{  project.generalContractorName }}
          </button>
        </ng-container>

        <div class="phls__scrollcon__tb__item">
          <div class="phls__scrollcon__tb__item__label">
            Import POs From:
          </div>
          <div class="phls__scrollcon__tb__item__selection">
            <div class="phls__scrollcon__tb__item__selection__content">
              <select #importGc
                      class="project-toolbox__gcList"
                      [disabled]="!(canIEdit$ | async)">
                <option *ngFor="let gc of generalContractors"
                        [value]="gc.generalContractorId"
                        [selected]="gc.generalContractorId === theHomeDepotId">
                  {{gc.generalContractorName}}
                </option>
              </select>
            </div>
          </div>
          <div class="phls__scrollcon__tb__item__actions">
            <button class="app-btn-icon app-btn-icon--primary phls__scrollcon__tb__item__actions__btn"
                    [disabled]="!(canIEdit$ | async)"
                    type="button"
                    (click)="importGcPos(importGc.value)"
                    title="Import New Purchase Orders">
              <i class="fas fa-cloud-download"></i>
            </button>
          </div>
        </div>

        <div class="phls__scrollcon__tb__item">
          <div class="phls__scrollcon__tb__item__label">
            Generate WOs For:
          </div>
          <div class="phls__scrollcon__tb__item__selection">
            <div class="phls__scrollcon__tb__item__selection__content">
              <select #generateWos
                      class="project-toolbox__gcList"
                      [disabled]="!(canIEdit$ | async) || !(poCount$ | async) || !providerLocations">
                <option *ngFor="let pl of providerLocations"
                        [value]="pl.providerLocationId">
                  {{pl.locationName}}
                </option>
              </select>
            </div>
            <div class="phls__scrollcon__tb__item__selection__content">
              <div class="phls__scrollcon__tb__item__selection__content--checkbox">
                Merge into current work order&nbsp;<fw-check-box #checkBox [config]="cbMerge"
                                                                 [isChecked]="((enableMerge$ | async) ? '' : null)"
                                                                 [isDisabled]="(!(canIEdit$ | async) || !(poCount$ | async) ? '' : null)"
                                                                 (change)="mergeWosEvent(checkBox.value)"></fw-check-box>
              </div>
            </div>
          </div>
          <div class="phls__scrollcon__tb__item__actions">
            <button class="app-btn-icon app-btn-icon--primary phls__scrollcon__tb__item__actions__btn"
                    [disabled]="!(canIEdit$ | async) || !(poCount$ | async) || !providerLocations"
                    type="button"
                    (click)="generateWorkOrders(generateWos.value, mergeWos)"
                    title="Generate Work Orders">
              <i class="fas fa-wrench"></i>
            </button>
          </div>
        </div>

        <!-- PROJECT TASKS-->
        <div class="phls__scrollcon__tasks project-task-container" *ngIf="canViewTasks$ | async">
          <fw-section-title title="Project tasks" [underline]="true" icon="fa-tasks"></fw-section-title>
          <task-manager [projectId]="projectId"
                        [projectStatus]="project.currentProjectStatusCode"
                        [defaultPending]="true"
                        [showCloseAll]="project.showCloseTaskButtons"
                        [canIEdit]="(canIEdit$ | async)">
          </task-manager>
        </div>
      </div>
    </div>
    <fw-error-box [errorData]="errorData$ | async"></fw-error-box>
  </div>
</ng-container>
