import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable, of } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IErrorData } from '../../../../../shared/interfaces';
import { IDashboardCount } from '../../../portal-shared/interfaces';
import { AdminDashCountType, AdminDashTabLabel, AdminDashStore } from '../../enums/admin-dashboard.enums';
import { IProviderUserCountViewModel } from '../../../portal-shared/interfaces/i-provider-user-count-view-model';
import { PoImportStore } from '../../../po-import/enums/po-import.enums';

import * as fromRoot from '../../../../store/reducers/index';
import { DomainObjectService } from '../../../../../shared/services';
import { AdminDashboardService } from '../../services/admin-dashboard.service';

@Component({
  selector: 'admin-dashboard',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './admin-dashboard.component.html'
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
  public portalId: number = 10;
  public tabContainerName = 'admin-dash';
  public title: string = 'Admin Dashboard';
  public dashCounts$: BehaviorSubject<IDashboardCount[]> = new BehaviorSubject(null);
  public loading$: Observable<boolean> = of(true);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errorMessage: string = '';

  subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public domainObjectService: DomainObjectService,
    public adminDashboardService: AdminDashboardService  ) {
  }

  ngOnInit() {
    const dashStores: string[] = Object.keys(AdminDashStore);
    dashStores.push(PoImportStore.poImportGroupedErrors);

    this.loading$ = this.rootStore.select('loadingIndicator').pipe(
      filter(x => dashStores.includes(x.requestor)),
      map(x => x.show));

    this.activeRoute.paramMap.subscribe(() => {
      this.newRequest();
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  //wait for existance of tabs before start looping through count api calls for better ui experience
  public onTabsLoaded(loaded: boolean): void {
    if (loaded) {
      if (!this.adminDashboardService.lastDashCounts || this.adminDashboardService.lastDashCounts.length === 0) {
        this.loadCounts();
      }
    }
  }

  newRequest() {
    if (this.adminDashboardService.lastDashCounts && this.adminDashboardService.lastDashCounts.length > 0) {
      this.dashCounts$.next(this.adminDashboardService.lastDashCounts);
    } else {
      this.setDashCounts();
    }
  }

  setDashCounts(): void {
    const adminDashboard: string = 'Admin Dashboard';

    const dashCounts: IDashboardCount[] = [
      {
        type: AdminDashCountType.erroredLineItemCount, label: AdminDashTabLabel.erroredLineItems, value: -1,
        fromPortalLabel: adminDashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(AdminDashCountType.erroredLineItemCount)
      },
      {
        type: AdminDashCountType.unassignedBranchCount, label: AdminDashTabLabel.unassignedBranches, value: -1,
        fromPortalLabel: adminDashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(AdminDashCountType.unassignedBranchCount)
      },
      {
        type: AdminDashCountType.unassignedProgramCount, label: AdminDashTabLabel.unassignedPrograms, value: -1,
        fromPortalLabel: adminDashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(AdminDashCountType.unassignedProgramCount)
      },
      {
        type: AdminDashCountType.unassignedSkuCount, label: AdminDashTabLabel.unassignedSkus, value: -1,
        fromPortalLabel: adminDashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(AdminDashCountType.unassignedSkuCount)
      },
      {
        type: AdminDashCountType.unassignedMeasureCount, label: AdminDashTabLabel.unassignedMeasures, value: -1,
        fromPortalLabel: adminDashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(AdminDashCountType.unassignedMeasureCount)
      },
    ];

    this.dashCounts$.next(dashCounts);
  }

  getCountByType(countType: IDashboardCount): void {
    const generalContractorId: number = 1; //temp, need drop down
    const params = generalContractorId.toString() + '?type=' + countType.type;

    this.subscription.add(this.domainObjectService.getObjectByMethod('ProviderUserCountViewModel', 'GetAdminCountByType', params)
      .subscribe(result => {
        if (result.success) {
          const data = <IProviderUserCountViewModel>result.data;
          let dashCounts: IDashboardCount[] = this.dashCounts$.getValue();
          let idx: number = dashCounts.findIndex(x => x.type === countType.type);
          if (idx > -1) {
            dashCounts[idx].value = data.dataCount;
          }
          this.dashCounts$.next(dashCounts);
          this.adminDashboardService.lastDashCounts = dashCounts;
        } else {
          this.errorData$.next(result.errorData);
        }
      }, error => this.errorMessage = <any>error)
    );
  }

  loadCounts(): void {
    const dashCounts = this.dashCounts$.getValue();

    dashCounts.forEach(type => {
      this.getCountByType(type);
    })
  }

  getComponentName(countType: string): string {
    switch (countType) {
      case AdminDashCountType.unassignedProgramCount:
        return 'ProgramServiceXmlMappingsComponent';
      case AdminDashCountType.unassignedBranchCount:
        return 'BranchXmlMappingsComponent';
      case AdminDashCountType.unassignedSkuCount:
        return 'SkuXmlMappingsComponent';
      case AdminDashCountType.unassignedMeasureCount:
        return 'UnitMeasureXmlMappingsComponent';
      case AdminDashCountType.erroredLineItemCount:
        return 'ErroredLineItemsComponent';
      default:
        return '';
    }
  }
}
