<form *ngIf="project && form" [formGroup]="form" novalidate class="form-wrapper sms-project__form">
  <div class="sms-project__cust">
    <fw-dynamic-field [field]="mds.getFieldDefinition('customerName')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
  </div>
  <div class="sms-project__item">
    <fw-dynamic-field [field]="mds.getFieldDefinition('branchName')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
  </div>
  <div class="sms-project__item sms-project__item--checkbox">
    <fw-dynamic-field [field]="mds.getFieldDefinition('isWarranty')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
  </div>
  <div class="sms-project__item">
    <fw-dynamic-field [field]="mds.getFieldDefinition('startDate')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
  </div>
  <div class="sms-project__item">
    <fw-dynamic-field [field]="mds.getFieldDefinition('endDate')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
  </div>
</form>
