import { Pipe, PipeTransform } from '@angular/core';
import { isNumeric, isDate} from 'rxjs/internal-compatibility';  //funky location

@Pipe({ name: 'homdate' })
export class HomDatePipe implements PipeTransform {
 //Dates will come in looking like:  /Date(1306380319000)/  need to return a date
  transform(value: string): string {
    let working: string = value;
        // If null values are allowed, and the value passed is null, return the current date.
    if (!working) return "";
    // if ISO date (sql server date), take the string you get from db , from index 6 . this will include )/ at the end but parseInt will exclude it.
    // then return resulting value and let angular date pipe handle formatting.
    if (working.startsWith('/Date')) {
      var dateVal = new Date(parseInt(working.substr(6)));
      return dateVal > new Date('07/04/1900') ? new Date(parseInt(working.substr(6))).toString() : "";
    } else if (isNumeric(working) || isDate(working)) {
        // let default angular date pipe handle formatting
      return working;
    } else {
        //just return as is
      return working;
    }

    }
}
