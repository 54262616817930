
export interface IWarrantySubType {
  warrantySubTypeId: number;
  warrantyType_warrantyTypeId: number;
  warrantySubTypeName: string;
  warrantySubTypeDescription: string;
}


export class WarrantySubType implements IWarrantySubType {
  warrantySubTypeId: number;
  warrantyType_warrantyTypeId: number;
  warrantySubTypeName: string;
  warrantySubTypeDescription: string;

  constructor(warrantyTypeId: number) {
    this.warrantySubTypeId = 0;
    this.warrantyType_warrantyTypeId = warrantyTypeId;
    this.warrantySubTypeName = '';
    this.warrantySubTypeDescription = '';
  }
}
