<div [ngClass]="{ 'loading-mask': (working$ | async)}">
  <div class="sku-create-wizard">
    <fw-component-title [title]="stepName$ | async" [entityLabel]="payoutName ? payoutName : skuName"></fw-component-title>

    <div *ngIf="(stepName$ | async) === stepAskPayoutType" class="app-btn-box app-btn-box--center sku-create-wizard__btns">
      <button type="button"
              class="app-btn app-btn--cancel"
              (click)="cancel()"
              title="Cancel">
        Cancel
      </button>
      <button type="button"
              class="app-btn app-btn--primary"
              title="Link to Existing Payout"
              (click)="goToNextStep(stepLinkPayout)">
        Link to Existing Payout
      </button>
      <button type="button"
              class="app-btn app-btn--primary"
              title="Create New Payout"
              (click)="goToNextStep(stepCreatePayout)">
        Create New Payout
      </button>
    </div>

    <router-outlet name="wizard"></router-outlet>

  </div>
</div>
