<div [ngClass]="{ 'loading-mask': loading$ | async }">
  
  <po-download-import *ngIf="!(activePos$ | async)"
                      [generalContractorName]="generalContractorName"
                      (customEvent)="onCustom($event)">
  </po-download-import>

  <div *ngIf="(activePos$ | async) as pos">
    <fw-component-title [title]="'Downloading ' + generalContractorName + ' Purchase Orders'"></fw-component-title>
    <div class="po-download-box">
      <div *ngFor="let po of pos; let rowIndex = index;  let lastRow = last" class="po-download">
        <div class="po-download__icon">
          <ng-container *ngIf="po.working"><span class="app-icon--primary"><i class="fal fa-spinner spin"></i></span></ng-container>
          <ng-container *ngIf="!po.working && po.error"><span class="app-icon--danger"><i class="fal fa-times-circle"></i></span></ng-container>
          <ng-container *ngIf="!po.working && !po.error"><span class="app-icon--success"><i class="fal fa-check-circle"></i></span></ng-container>
        </div>
        <div class="po-download__item">{{po.purchaseOrderNumber}}</div>
        <div *ngIf="!po.working" class="po-download__status">
          <fw-error-box *ngIf="po.error" [errors]="po.error"></fw-error-box>
          <span *ngIf="!po.error" class="app-text--success">Success</span>
        </div>
      </div>

      <div *ngIf="pos.length === 0">
        <fw-error-box [errors]="'There were no purchase orders to download'"></fw-error-box>
      </div>

      <div class="app-btn-box">
        <button type="button"
                class="app-btn app-btn--cancel"
                [disabled]="(loading$ | async)"
                (click)="onClose()">
          Close
        </button>
      </div>

    </div>
  </div>
</div>
