import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, map, filter} from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { IAppConstants, appConstants } from '../constants/index';
import { IFieldDefinition } from '../../fw/dynamic-forms/index';
import { ISelectResolverData } from '../interfaces/i-select-resolver-data';
import { environment } from '../../environments/environment';

//store actions and reducers
import { ISelectionList, IStoreState } from '../store/selectionLists/selectionLists.reducer';
import * as fromSelectionListActions from '../store/selectionLists/selectionLists.actions';
import * as fromMetaData from '../../fw/dynamic-list/store/reducers/meta-data.reducer';
import { getMetaDataByType } from '../../fw/dynamic-list/store/selectors/meta-data.selectors';
import * as fromRoot from '../../app/store/reducers/index';
import * as fromStore from '../../fw/dynamic-list/store/reducers/feature.reducer';
import { UserPriviledgesService } from '../../auth/services/user-priviledges.service';

interface IWaitingListData {
  list: ISelectionList,
  parentId: number
}

@Injectable()
export class SelectionListService implements OnDestroy  {
  subscription: Subscription = new Subscription();

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public userPriviledgesService: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  public requestListData(storeName: string, parentId: number): IFieldDefinition[] {
    let currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    let defs: IFieldDefinition[] = []
    let seedData = null;
    
    this.subscription.add(this.store.select(getMetaDataByType(storeName))
      .pipe(take(1))
      .subscribe((state: fromMetaData.IMetaDataState) => {
        const storeData = cloneDeep(state);
        defs = storeData.fieldDefinitions;
        seedData = storeData.seedData;
      }));

    let selectionListFields: IFieldDefinition[] = defs.filter(field => this.validSelectField(field));
    if (!selectionListFields) {
      return [];
    }

    this.initSelectItems(selectionListFields);

    this.requestData(selectionListFields, parentId, currentUserId, seedData);
    return selectionListFields;
  }

  public validSelectField(field: IFieldDefinition): boolean {
    const isSelect = field.fieldType === this.myConstants.fieldTypeSelect ||
      field.fieldType === this.myConstants.fieldTypeEntity ||
      field.fieldType === this.myConstants.fieldTypeEditableSelect ||
      field.fieldType === this.myConstants.fieldTypeFilterableSelect;

    return isSelect
      && field.visibleOnEdit
      && field.hasOwnProperty('selectListDefinition')
      && field.selectListDefinition !== null
      && field.selectListDefinition.storeName !== ''
      && !field.selectListDefinition.useCustomResolver
      && !field.selectListDefinition.forFilterOnly;
  }

  public getListNames(storeName: string): ISelectResolverData[] {

    let defs: IFieldDefinition[] = []
    let seedData = null;
    this.subscription.add(this.store.select(getMetaDataByType(storeName))
      .pipe(take(1))
      .subscribe((state: fromMetaData.IMetaDataState) => {
        defs = state.fieldDefinitions;
        seedData = state.seedData;
      }));

    let selectionListFields: IFieldDefinition[] = defs.filter(field => this.validSelectField(field));

    if (!selectionListFields) {
      return [];
    }


    //map to a new array with the spread operator to ensure have a distinct list of stores to fill
    return [...new Set(selectionListFields.map(f => {
      return {
        storeName: f.selectListDefinition.storeName,
        parentDependent: f.selectListDefinition.methodName ? true : false,
        parentId: -1
      }
    }))];
  }

/*
*  Wait for each store we need loaded to be loaded.
 * Once all have loaded, return true - or return the store data?.
*/
  waitForDataToLoad(storeRequest: ISelectResolverData[]):  Observable<boolean> {
  return this.rootStore.select('selectionLists') //get all buckets in the store that we are waiting to load
    .pipe(
      map((state: IStoreState) => {
        const reducerList: IWaitingListData[] = [];
        storeRequest.forEach(n => {
          if (state.hasOwnProperty(n.storeName))
            reducerList.push({ list: state[n.storeName], parentId: n.parentId });
        });
        return reducerList;
      }),
      filter((reducerList: IWaitingListData[]) =>
        reducerList.every(l => l.list.objData !== null
          && l.list.objData.find(x => x.parentId == l.parentId) !== undefined
          && l.list.objData.find(x => x.parentId == l.parentId).data !== null)),
      take(1),
      map((reducerList: IWaitingListData[]) => {
        let selectionLists = reducerList.map(x => x.list);
        storeRequest.forEach(selListType => {
          const selectList = selectionLists.find(l => l.storeName === selListType.storeName);
          if (selectList === undefined) {
            if (!environment.production) {
              console.log('DEV ERROR: SelectionListResolver this  selectList is is not defined in selectionLists.reducers.ts: ',
                selListType.storeName);
            }
          }
          return true;
        });
        return true;
      }));

}

/*
 *  For each field that needs a selection list loaded, check to see if the store should be inited
 * May want to change this to a setting if value based is not flexible enought
 */
initSelectItems(fields: IFieldDefinition[]): void {

  //init any selections lists that should vary by parent id
  fields.forEach(field => {
    if ((field.fieldType === this.myConstants.fieldTypeEntity
      || field.fieldType === this.myConstants.fieldTypeEditableSelect
      || field.fieldType === this.myConstants.fieldTypeFilterableSelect ) &&
      field.selectListDefinition.storeName &&
      field.selectListDefinition.noCache) {
      this.rootStore.dispatch(
        new fromSelectionListActions.InitSelectItems({
          storeName: field.selectListDefinition.storeName,
          parentId: -1  }));
    }
  });
}

/*
 *  For each field that needs a selection list loaded, check the store to see if it is loaded.
 *  Dispatch an action to load it if not loaded.
 */
  requestData(fields: IFieldDefinition[], parentId: number, currentUserId: number, seedData: any): void {
    this.subscription.add(this.rootStore.select('selectionLists') //get all buckets in the store that we are waiting to load
    .pipe(take(1))
      .subscribe((state: IStoreState) => {
      fields.forEach(field => {
        const useParentId: number = this.getParentId(field, parentId);
        if (state.hasOwnProperty(field.selectListDefinition.storeName)) {
          if (field.selectListDefinition.noCache
            || !state[field.selectListDefinition.storeName].objData
            || !state[field.selectListDefinition.storeName].objData.find(x => x.parentId == useParentId)
            || !state[field.selectListDefinition.storeName].objData.find(x => x.parentId == useParentId).data) {
            if (field.fieldType === this.myConstants.fieldTypeEntity
              || field.fieldType === this.myConstants.fieldTypeEditableSelect
              || field.fieldType === this.myConstants.fieldTypeFilterableSelect) {
              //determine the type of method call to make
              if (field.selectListDefinition.methodName) {
                if (field.selectListDefinition.methodByKey) {
                  const id = this.getMethodId(field.selectListDefinition.methodByKey, parentId, currentUserId);
                  this.rootStore.dispatch(new fromSelectionListActions.GetEntityListById(field.selectListDefinition.objectType, field.selectListDefinition.methodName, id, field.selectListDefinition.listFilter, field.selectListDefinition.storeName));
                } else {
                  this.rootStore.dispatch(new fromSelectionListActions.GetEntityListByMethodParams(field.selectListDefinition.objectType, field.selectListDefinition.methodName, '', field.selectListDefinition.listFilter, field.selectListDefinition.storeName));
                }
              } else {
                this.rootStore.dispatch(new fromSelectionListActions.GetEntityList(field.selectListDefinition.objectType, field.selectListDefinition.listFilter, field.selectListDefinition.storeName));
              }
            } else {
              // Working with an enum or a lookup value
              // If create won't have data, just seedData
              let hasSeedDataUrl: boolean = true;
              if (!seedData ||
                !seedData.hasOwnProperty('metaData') ||
                !seedData['metaData'][field.selectListDefinition.objectType]) {
                hasSeedDataUrl = false;
                if (!environment.production) {
                  console.log('DEV WARNING: seed data missing for this object type:', field.selectListDefinition.objectType, ' and parentId is: ', parentId, ' and seedData is: ', seedData, 'Will roll our own api string.');
                  //Did not find the url needed in meta data store seedData/metaData/{objectType}
                }
              }

              const api = hasSeedDataUrl
                ? seedData['metaData'][field.selectListDefinition.objectType]
                : '/Api/'.concat(field.selectListDefinition.listType === 'lookupValue' ? 'LookupTypeName' : 'Enum', '/', field.selectListDefinition.objectType.replace(field.selectListDefinition.objectType[0], field.selectListDefinition.objectType[0].toUpperCase()), '/LookupValues');
              //"/Api/Enum/SkuType/LookupValues"
              //"/Api/LookupTypeName/OrderStatus/LookupValues"
              this.rootStore.dispatch(new fromSelectionListActions.GetLookupList({ typeName: field.selectListDefinition.objectType, api: api }));
              // If this doesn't work, your field seed data is off
            }
          }
        } else {
          if (!environment.production) {
            console.log('DEV ERROR:  selection-list store is not defined: ', field.selectListDefinition.storeName);
          }
        }
      });
    }));
}

getMethodId(methodByKey: string, parentId: number, currentUserId: number): number {
  let id = -1;
  switch (methodByKey.toLowerCase()) {
    case 'provideruserid':
      id = currentUserId;
      break;
    case 'parentid':
      id = parentId;
      break;
    default:
      break;
  }
  return id;
  }

  getParentId(fieldDef: IFieldDefinition, parentId: number): number {
    let outParentId: number = -1;
    if (fieldDef.selectListDefinition) {
      if (fieldDef.fieldType === this.myConstants.fieldTypeEntity ||
        fieldDef.fieldType === this.myConstants.fieldTypeEditableSelect ||
        fieldDef.fieldType === this.myConstants.fieldTypeFilterableSelect) {
        if (fieldDef.selectListDefinition.methodName && fieldDef.selectListDefinition.methodByKey) {
          outParentId = parentId;
        }
      }
    }

    return outParentId;
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

