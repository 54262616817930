<div class="portal">
  <div class="portal__detail--item">
    <fw-dynamic-list *ngIf="listDefinition"
                      [listTitle]="'Unassigned Warranties'"
                      [vmListDefinition]="listDefinition"
                     (customEvent)="onCustom($event)"
                     (filterEvent)="onFilterChange($event)">
    </fw-dynamic-list>
  </div>
</div>
