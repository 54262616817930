import { Component,  Input,  Output, EventEmitter, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IProject } from '../../../view-models';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IContactProjectSelectEvent } from '../../interfaces';
import { ContactProjectService } from '../../services';

@Component({
  selector: 'contact-projects',
  templateUrl: './contact-projects.component.html'
})
export class ContactProjectsComponent implements OnInit, OnChanges {
  @Input() contactId: number;
  @Input() contactTypeId: number;
  @Input() projectId: number;
  @Output() select = new EventEmitter<IContactProjectSelectEvent>();

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  public selectedProject: IProject = null;

  constructor(public contactProjectService: ContactProjectService) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedProject = null;
    this.listDefinition$.next(this.contactProjectService.loadContactProjectsListDefinition(this.contactId, this.contactTypeId, this.projectId));
  }

  public onProjectSelect(event: IHomEventEmitter): void {
    this.selectedProject = event.data;
  }

  public onPage(event): void {
    this.selectedProject = null;
  }

  public confirmSelect(): void {
      if (this.selectedProject) {
      this.select.emit({
        projectId: this.selectedProject.projectId,
        projectStatus: '',
        workOrderId: -1,
        workOrderStatus: '',
        purchaseOrderId: -1,
        purchaseOrderStatus: '',
        actionIds: []
      });
    }
  }

}
