<ng-container *ngIf="tabDefinitions && tabDefinitions.length">
  <div class="content__tabs">
    <fw-app-tabs apptabs
                 [level]="portalLevel"
                 [limit]="limit"
                 [tabDefinition]="tabDefinitions"
                 (tabRequestComplete)="tabRequestComplete($event)"
                 >
    </fw-app-tabs>
  </div>
</ng-container>
<div *ngIf="invalidUserSetup || (tabDefinitions && tabDefinitions.length)" class="content__detail">
  <router-outlet></router-outlet>
</div>
