import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { AdminEvent, AdminStore } from '../../enums/admin.enums';
import { IProviderUser } from '../../../view-models';
import { IErrorData } from '../../../../../shared/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { UpdateObjectCustomModel,  IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IAllDynamicData, UpdateObjectCustomList } from '../../../../../fw/dynamic-list/store';
import { AdminSecurityService } from '../../services/admin-security.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'users-security',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './users-security.component.html'
})
export class UsersSecurityComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);

  constructor(public activeRoute: ActivatedRoute,
    public adminSecurityService: AdminSecurityService,
    public store: Store<IAllDynamicData>,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.viewUserSecurity:
        this.viewUserSecurity(event.data);
        break;
      case AdminEvent.syncLdapUser:
        this.syncWithLadap(event.data);
      default:
        break;
    }
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.adminSecurityService.loadUserSecurityListDefinition());
    });
  }


  ngOnDestroy(): void {
    this.adminSecurityService.initAllRolesAndPrivs();
  }

  viewUserSecurity(data: IProviderUser): void {
    //TBD - work this to show detail off of dynamic list
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: AdminStore.userSecurity,
      key: 'providerUserId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: -1 
    }
    this.modalService.open({
      title: 'User Security Maintenance for '.concat(data.fullName),
      path: 'user-access-manager',
      id: data.providerUserId,
      sizeType: ModalSizeType.xlarge,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

  syncWithLadap(data: IProviderUser): void {
    if (data.isLdapUser) {
      const def: IListDefinition = this.listDefinition$.value;

      const keyData: IKey = { storeName: def.storeName, parentId: def.parentId, key: def.rowKeyId, id: data.providerUserId }
      const emitter: IHomEventEmitter = { requestor: def.detailRoutePath, event: this.myConstants.emitterEventUpdate, action: '', data: null };
      const updateData = new UpdateObjectCustomModel(keyData, def.controllerName, 'SyncLdapUser', data, null, emitter);
      this.store.dispatch(new UpdateObjectCustomList({ updateData }));

    }
  }
}
