// Resolver for a specific installer
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, filter, map, first, mergeMap } from 'rxjs/operators';


//store actions and reducers
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../store/reducers/index';
import { metaDataExists } from '../../../../fw/dynamic-list/store/selectors/meta-data.selectors';
import * as LoadingIndicatorActions from '../../../../shared/store/loadingIndicator/loadingIndicator.actions';

import { SelectionListService } from '../../../../shared/services/selection-list.service';
import { ProjectService } from '../services/project.service';
import { ProjectObjectStore } from '../enums/project.enums';
import { ISelectResolverData } from '../../../../shared/interfaces/i-select-resolver-data';

@Injectable()
export class ProjectInformationResolver  {
  storeName: string = 'projectInformation';

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public selectionListService: SelectionListService,
    public projectService: ProjectService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const projectId = route.paramMap.has('projectId')
      ? +route.paramMap.get('projectId')
      : route.paramMap.has('id')
        ? +route.paramMap.get('id')
        : + route.paramMap.get('portalEntityId');
    const loadingFor: string = route.data['loadingFor'];


    return this.metaLoaded().pipe(
      mergeMap((loaded: boolean) => {
        let resolverData: ISelectResolverData[] = this.selectionListService.getListNames(this.storeName);
        this.selectionListService.requestListData(this.storeName, projectId);
        //projectProviderLocation holds the provider locations at branch level and is loaded from project-high-level summary
        resolverData.forEach(x => x.parentId = x.parentDependent ? projectId : -1);

        return this.selectionListService.waitForDataToLoad(resolverData)
          .pipe(
            map((complete: boolean) => {  return complete }),
            filter((complete: boolean) => complete === true),
            take(1),
            map(() => {
              return true;
            }),
            first()
          )
      })
    );
  }

  metaLoaded(): Observable<boolean> {
    return this.store.select(metaDataExists(this.storeName))
      .pipe(
        map((exists: boolean) => {
          return exists;
        }),
        filter((exists: boolean) => exists === true),
        take(1),
        map(() => { return true; }),
        first()
      );
  }

}
