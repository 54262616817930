import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminSecurityService } from '../../services/admin-security.service';

@Component({
  selector: 'filters-where-used',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './filters-where-used.component.html'
})
export class FiltersWhereUsedComponent implements OnInit {
  public listDefinition: IListDefinition;
  privilegeRuleEntitySpecifierId: number;

  constructor(public activeRoute: ActivatedRoute,
    public securityService: AdminSecurityService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.privilegeRuleEntitySpecifierId = +paramMap.get('id');
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.securityService.loadFilterWhereUsedListDefinition(this.privilegeRuleEntitySpecifierId);
  }

}
