import { Injectable } from '@angular/core';

import { IPlsInstallerWorkCrewViewModel } from '../../../../../installer-portal/src/app/interfaces';
import { UserPriviledgesService } from '../../../auth/services';
import { IUserRoleViewModel } from '../../../app/portals/view-models';
import { InstallerRole } from '../../../app/portals/installer/enums/installer.enums';

@Injectable()
export class ComplianceDynamicListService {

  constructor(public ups: UserPriviledgesService) { }

  handleCustomMethod(methodName: string, row: any, portalEntityId: number): any {
    switch (methodName) {
      case 'canManageInstaller':
        return this.canManageInstaller(row);
      case 'canDeleteRole':
        return this.canDeleteRole(row);
      default:
        return false;
    }
  }

  /*CUSTOM METHODS TO DETERMINE BUTTON ENABLED */
  canManageInstaller(row: IPlsInstallerWorkCrewViewModel): boolean {
    return this.ups.canManageInstallers$.value;
  }

  canDeleteRole(row: IUserRoleViewModel): boolean {
    return row.providerRoleName !== InstallerRole.installer;
  }

}
