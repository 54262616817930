<div [ngClass]="{ 'loading-mask': !mds.fieldDefinitionsLoaded || !(callLogDefinition$ | async) || working$ | async}">
  <div *ngIf="mds.fieldDefinitionsLoaded && (callLogDefinition$ | async)">
    <fw-component-title [title]="title"></fw-component-title>
    <div class="list-wrapper">
      <fw-dynamic-list [vmListDefinition]="callLogDefinition$ | async"></fw-dynamic-list>
    </div>

    <work-order-call-log [projectId]="projectId"
                         [workOrderId]="workOrderId"
                         [displayFields]="displayFields"
                         [providerUsers]="providerUsers$ | async"
                         (createEvent)="onCreate($event)"
                         (cancelEvent)="onCancel($event)">
      <fw-error-box [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
    </work-order-call-log>
  </div>
</div>
