<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <div class="app-card" *ngIf="objectData">
    <div class="app-card__content">
      <div class="note">
        <div class="note--label">Acknowledged</div>
        <div class="note--text">
          <fw-check-box [config]="cbConfig"
                        [isChecked]="objectData.acknowledged"
                        [isDisabled]="(operation !== myConstants.operationTypeEdit || objectData.acknowledged)"
                        (change)="changeAck()"></fw-check-box>
        </div>
        <div class="note--label">From</div>
        <div class="note--text">{{objectData.createUserName}}</div>
        <div class="note--label">Date</div>
        <div class="note--text">{{objectData.createDate | homdate | date | date:'MM/dd/yyyy' }}</div>
        <div class="note--label">Context</div>
        <fw-note-context [row]="objectData"></fw-note-context>
        <p>{{objectData.fks.note.noteText}}</p>
      </div>
    </div>
  </div>
  <fw-crud-buttons [operation]="operation" [disabled]="disabled" [showNext]="false" (cancelEvent)="onCancel($event)" (saveEvent)="onSave($event)"></fw-crud-buttons>
</fw-dynamic-detail-container>
