import { Component, OnInit, Input, Inject, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { IProject } from '../../../portals/view-models';
import { ProjectObjectStore } from '../../../portals/project/enums/project.enums';
import { appConstants, IAppConstants } from '../../../../shared/constants';
import { MetaDataService } from '../../../../fw/dynamic-list/services';

@Component({
  selector: 'sms-project',
  templateUrl: './sms-project.component.html',
  providers: [MetaDataService]
})
export class SmsProjectComponent implements OnInit, OnChanges, OnDestroy  {
  @Input() project: IProject;

  public form: FormGroup;
  public displayFields = ['customerName','branchName', 'startDate', 'endDate', 'isWarranty']; //,
  public operation: string;
  subscription: Subscription = new Subscription();

  constructor(
    public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit(): void {
    this.operation = this.myConstants.operationTypeDetails;
    this.mds.setFieldDefinitions(ProjectObjectStore.projectInformation);
    this.mds.loadSelectionLists(this.displayFields, 'sms-project-summary', this.project.projectId);
    this.subscription.add(this.mds.mdsReady$.subscribe((val) => {
    if (val) {
      this.setFieldData();
      }
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['project'] && !changes['project'].isFirstChange()) {
      this.setFieldData();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setFieldData(): void {
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.project, this.myConstants.operationTypeDetails);
  }

}
