import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { UserRecentsService } from '../services/user-recents.service';
import { IUserRecent } from '../interfaces/i-user-recent';
import { UserRecentEvent } from '../enums/user-recents.enums';

@Component({
  selector: 'user-recent',
  templateUrl: './user-recent.component.html'
})

export class UserRecentComponent {

  constructor(public userRecentsService: UserRecentsService) { }

  @Input() row: IUserRecent;
  @Output() customEvent: EventEmitter<IHomEventEmitter> = new EventEmitter();

  public openTab(): void {
    this.customEvent.emit({
      event: UserRecentEvent.openTab,
      data: this.row,
      action: null,
      requestor: 'user-recent'
    });
  }

  public deleteUserRecent(): void {
    this.userRecentsService.workerItems.push({ entityId: this.row.entity_entityId, entityPkId: this.row.entityPkId });
    this.customEvent.emit({
      event: UserRecentEvent.delete,
      data: this.row.userRecentId,
      action: null,
      requestor: 'user-recent'
    });
  }

}
