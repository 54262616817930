import { Component, Input, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';

@Component({
  selector: 'fw-container',
  templateUrl: './fw-container.component.html'
})

export class FwContainerComponent implements AfterViewInit {

  @Input() disableScrolling: boolean;

  @ViewChild('container', { read: ElementRef }) container: ElementRef<HTMLDivElement>;

  setMaxHeight(): void {
    let int = setInterval(() => {
      if (this.container.nativeElement.getBoundingClientRect().top >= 0) {
        this.container.nativeElement.style.maxHeight = (this.disableScrolling || window.innerWidth <= 1024 ? 'none' : (window.innerHeight - (this.container.nativeElement.getBoundingClientRect().top + 30)) + 'px');
        clearInterval(int);
      }
    });
  }

  ngAfterViewInit() {
    this.setMaxHeight();
  }

  @HostListener('window:resize') resize() {
    this.setMaxHeight();
  }
  
}
