/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit,  Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { ProjectService } from '../../../project/services';

@Component({
  selector: 'work-order-notes',
  templateUrl: './work-order-notes.component.html'
})
export class WorkOrderNotesComponent implements OnInit {
  public projectId: number;
  public workOrderId: number;
  public listDefinition: IListDefinition;
  public action: string = '';
  public projectKey: string = 'project_projectId';

  constructor(
    public activeRoute: ActivatedRoute,
    public projectService: ProjectService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
        this.workOrderId = +paramMap.get('id');
        if (paramMap.hasOwnProperty('projectId')) {
          this.projectId = +paramMap.get('projectId');
        }
        else if (window.history.state && window.history.state.hasOwnProperty('projectId')) {
            this.projectId = window.history.state['projectId'];
        } else {
          this.projectId = +paramMap.get('portalEntityId');
        }

        const operation = paramMap.get('operation');
        if (operation === this.myConstants.operationTypeCreate) {
          this.action = this.myConstants.routeActionAdd;
        }
      });

    this.listDefinition = this.projectService.loadWorkOrderNoteListDefinition(this.workOrderId);
  }
}
