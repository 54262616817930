<div class="available-installers-header">
  <fw-legend [title]="'Color Legend'"
             [items]="legendItems">
  </fw-legend>

</div>
<div class="available-installers-box">
  <div class="available-installers-toolbar">
    <label title="Sort By">Sort By</label>
    <div class="available-installers-toolbar__ranking">
      <select #sortBy (change)="onSortChange(sortBy.value)" [disabled]="unavailableReason || !availableInstallers || !availableInstallers.length">
        <option selected value="installerWorkCrewCompatibilityScore">Ranking</option>
        <option value="installerWorkCrewName">Name</option>
      </select>
    </div>
  </div>

  <div class="available-installers">
    <div *ngIf="unavailableReason" class="available-installers__unavailable">
      {{ unavailableReason }}
    </div>
    <ng-container *ngIf="!unavailableReason">
      <ng-container *ngIf="!availableInstallers || !availableInstallers.length"><div class="available-installers__none">No Records Found</div></ng-container>
      <ng-container *ngIf="availableInstallers">
          <div *ngFor="let installer of availableInstallers" class="available-installer"
               [title]="'Click to select ' + installer.installerName"
               [ngClass]="cardClass(installer)"
               (click)="selectInstaller(installer.installerWorkCrewId)">
            <div class="available-installer__contact-pref">
              <fw-communication-preference [row]="installer"
                  [displayType]="communicationEventService.displayTypeIcon"
                  [cssClass]="selectedId === installer.installerWorkCrewId ? 'app-icon--white' : ''">
              </fw-communication-preference>
            </div>
            <div class="available-installer__name">
              <div class="available-installer__name--installer"
                   [ngClass]="{'available-installer__name--installer--selected': selectedId === installer.installerWorkCrewId }">
                {{ installer.installerName }}
              </div>
              <div class="available-installer__name--crew" >
                {{installer.installerWorkCrewName.replace(installer.installerName, '')}}
              </div>
              <div class="available-installer__name--crew-lead"
                   [ngClass]="{'available-installer__name--crew-lead--selected': selectedId === installer.installerWorkCrewId,
                               'available-installer__name--crew-lead--none': installer.contactId === 0 }">
                  {{installer.installerWorkCrewLeadName}}
              </div>
            </div>
            <div class="available-installer__score"
                 title="Installer Score"
                 [ngClass]="{ 'available-installer__score--warning': installer.compatibilityWarningLevel === 'Warning',
                'available-installer__score--valid': installer.compatibilityWarningLevel === 'Valid',
                'available-installer__score--selected': selectedId === installer.installerWorkCrewId }">
              <div class="available-installer__score--val">
                {{installer.installerWorkCrewCompatibilityScore | number: '1.0-0' }}
              </div>
            </div>
            <div class="available-installer__compatibility-box">
              <crew-work-order-compatibility [compatibilityList]="installer.compatibilityList" [screenIsSmall]="screenIsSmall$ | async"></crew-work-order-compatibility>
            </div>

          </div>
      </ng-container>
      </ng-container>
</div>

</div>
