import { Injectable, Inject } from '@angular/core';

import { IListDefinition, IListFilter, IListColumn, ListColumn, ListFilter, OrderTerm } from '../../../../fw/dynamic-list/interfaces';
import { PricingStore, PricingEvent, SkuCreationWizardEvent } from '../enums/pricing-shared.enums';
import { appConstants, IAppConstants } from '../../../../shared/constants';
import { PayoutMaintEvent } from '../../admin/enums/admin.enums';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { UserPriviledgesService } from '../../../../auth/services';
import { DynamicListService } from '../../../../fw/dynamic-list/services';

@Injectable()
export class PricingSharedService {
  homeDepotGcId: number = 1;

  constructor(public userPriviledgesService: UserPriviledgesService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  /*Load List Methods*/
  loadSkuPricingWizardListDefinition(skuId: number): IListDefinition {
    const listColumns = this.loadSkuPricingWizardListColumns();
    const listObjectLabel = 'Pricing Wizard';
    const listObjectController = 'SkuPrice';
    const listStoreName = PricingStore.skuPrices;
    const detailRoutePath = '';
    const listRowKeyId = 'skuPriceId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;

    defaultListFilter.orderTerm = [
      new OrderTerm('isGlobal', true, 1),
      new OrderTerm('programService_program_programName', true, 2),
      new OrderTerm('programService_service_serviceName', true, 3),
      new OrderTerm('branch_branchName', true, 4),
      new OrderTerm('region_regionName', true, 5)
    ];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'SkuPriceWizard';
    listDefinition.methodParameters = skuId.toString();
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;

    listDefinition.groupSelect = {
      title: 'Update Sku Price',
      enabled: true,
      allowSelectAll: true,
      eventName: PricingEvent.selectSkuToChange
    };

    listDefinition.rowStatus = {
      cssMethod: 'getPriceInErrorCss',
      methodService: 'poImportDynamicListService'
    };

    //no crud buttons
    return listDefinition;
  }

  loadSkuPricingWizardListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('isGlobal');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('programName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceName');
    colDef.visibleOnSmall = false;
    //colDef.visibleOnMedium = false;
    //colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('branchName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);
    //colDef = new ListColumn('branchProgramBranchName');
    //colDef.visibleOnSmall = false;
    //colDef.visibleOnMedium = false;
    //columnDefinitions.push(colDef);

    colDef = new ListColumn('regionName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    //colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('displayText');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('effectiveDeckPrice');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('errorText');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPayoutLinksListDefinition(): IListDefinition {
    const listColumns = this.loadPayoutLinksListColumns();
    const listObjectLabel: string = 'Payout';
    const listObjectController: string = 'Payout';
    const listStoreName: string = PricingStore.payouts;
    const detailRoutePath: string = '';
    const listRowKeyId: string = 'payoutId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.noGet = true;
    listDefinition.openInFilterMode = true;
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.userPriviledgesService.providerId$.value.toString(); //Will always be Hom 

    listDefinition.groupSelect = {
      title: 'Select Payout',
      enabled: true,
      allowSelectAll: false,
      eventName: SkuCreationWizardEvent.selectPayout
    };

    //no crud buttons
    return listDefinition;
  }

  loadPayoutLinksListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('payoutName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('payoutStatus');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }


  loadSkuLinksListDefinition(): IListDefinition {
    const listColumns = this.loadSkuLinksListColumns();
    const listObjectLabel: string = 'Sku';
    const listObjectController: string = 'Sku';
    const listStoreName: string = PricingStore.skus;
    const detailRoutePath: string = '';
    const listRowKeyId: string = 'skuId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.noGet = true;
    listDefinition.openInFilterMode = true;
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.userPriviledgesService.providerId$.value.toString(); //Will always be Hom 

    listDefinition.groupSelect = {
      title: 'Select Sku',
      enabled: true,
      allowSelectAll: false,
      eventName: PayoutMaintEvent.selectSku
    };

    //no crud buttons
    return listDefinition;
  }

  loadSkuLinksListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('generalContractor');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('skuName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('skuStatus');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadSkusListDefinition(generalContractorId: number): IListDefinition {
    const listColumns = this.loadSkusListColumns();
    const listObjectLabel: string = 'Sku';
    const listObjectController: string = 'sku';
    const listStoreName: string = PricingStore.skus;
    const detailRoutePath: string = '';
    const listRowKeyId: string = 'skuId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.searchTerm = [];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByGeneralContractor';
    listDefinition.methodParameters = generalContractorId.toString();

    //no crud buttons
    return listDefinition;
  }

  loadSkusListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('skuName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('skuType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('skuStatus');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('allowNegativePrice');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    //colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitOfMeasure');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('accountingAccountCode');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
  columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceCodeOverride');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPayoutsListDefinition(): IListDefinition {
    const listColumns = this.loadPayoutsListColumns();
    const listObjectLabel: string = 'Payout';
    const listObjectController: string = 'payout';
    const listStoreName: string = PricingStore.payouts;
    const detailRoutePath: string = '';
    const listRowKeyId: string = 'payoutId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.searchTerm = [];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.userPriviledgesService.providerId$.value.toString();

    //no crud buttons
    return listDefinition;
  }

  loadPayoutsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('payoutName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('payoutStatus');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('controlsCapacity');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitOfMeasure');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('glCodeOverride');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceCodeOverride');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadSkuPricesListDefinition(): IListDefinition {
    const listColumns = this.loadSkuPriceListColumns();
    const listObjectLabel: string = 'SkuPrice';
    const listObjectController: string = 'skuPrice';
    const listStoreName: string = PricingStore.skuPrices;
    const detailRoutePath: string = '';
    const listRowKeyId: string = 'skuPriceId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.searchTerm = [];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';

    //no crud buttons
    return listDefinition;
  }

  loadSkuPriceListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('effectiveDeckPrice');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('displayText');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('regionName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('branchName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('programName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
