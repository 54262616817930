<fw-component-title [title]="'Edit Preferred Communication Method'"></fw-component-title>
<div [ngClass]="{ 'loading-mask': (working$ | async)}">
  <div *ngIf="(object$ | async) && (ready$ | async)" class="communication-preference">
    <div class="communication-preference__inputs">
      <div class="float-label">
        <select #preferenceType (change)="changeType(preferenceType.value)" appFocus [autoFocus]="true">
          <option selected value="0">Choose...</option>
          <option *ngFor="let type of types"
                  [value]="type.communicationPreferenceId"
                  [selected]="selectedType && selectedType.communicationPreferenceId === type.communicationPreferenceId">
            {{type.communicationPreferenceDescription}}
          </option>
        </select>
        <label title="Communication Method">Communication Method</label>
      </div>
      <div class="float-label">
        <select #mechanism
                [disabled]="!(mechanisms$ | async)?.length"
                (change)="changeMechanism(mechanism.value)">
          <option selected value="0">Choose...</option>
          <option *ngFor="let mechanism of (mechanisms$ | async)"
                  [value]="mechanism.id"
                  [selected]="selectedMechanismId === mechanism.id">
            {{mechanism.label}}
          </option>
        </select>
        <label title="Peferred Method">With</label>
      </div>
    </div>
    <div class="app-btn-box communication-preference__btns">
      <button type="button"
              class="app-btn app-btn--cancel"
              (click)="cancel()"
              title="Cancel">
        Cancel
      </button>
      <button class="app-btn app-btn--primary"
              type="button"
              (click)="save()"
              [disabled]="!(isValid$ | async)">
        {{ objectId <= 0 ? 'Create' : 'Save' }}
      </button>
    </div>

    <fw-error-box [errorData]="errorData$ | async" [errors]="errors$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
  </div>
</div>
