import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HomPipesModule } from 'hom-lib/hom-pipes';
import { MultiSelectDropdownModule } from './components/fw-multi-select-dropdown/fw-multi-select-dropdown.module';
import { SharedModule } from '../../shared/shared.module';

//services
import { ScreenService } from './services/screen.service';
import { TabsFactoryService } from './components/tabs-factory/services/tabs-factory.service';
import { TabsService } from './components/fw-app-tabs/services/fw-app-tabs.service';
import { MapService } from './components/fw-map/services/map.service';
import { UserRecentsService } from './components/user-recents/services/user-recents.service';


//directives
import { HighlightDirective,
  ScreenSmall,
  ScreenLarge,
  ScreenBelowLarge,
  UnlessDirective, 
  PhoneMaskDirective,
  ZipCodeMaskDirective,
  HomDatePickerDirective,
  DisableControlDirective,
  AppFocusDirective,
  HasErrorDirective,
  AppTabsDirective,
  DecimalMaskDirective,
  DigitOnlyMaskDirective,
  DateMaskDirective,
  InfiniteScrollDirective,
  AsciiOnlyDirective
  } from './directives/index';


//components
import {
  SpinnerComponent,
  EntityStatusComponent,
  Pagination,
  ComponentTitleComponent,
  ComponentSubTitleComponent,
  SectionTitleComponent,
  CalendarComponent,
  ErrorBoxComponent,
  TabsComponent,
  ButtonTitleBarComponent,
  AccordianComponent,
  EditableSelectComponent,
  ComponentFooterComponent,
  FwDynamicElementComponent,
  PhoneComponent,
  EmailComponent,
  CheckBoxComponent,
  RadioButtonComponent,
  FwCalendarComponent,
  CommunicationPreferenceComponent,
  AccordianSingleComponent,
  FwContainerComponent,
  CollapsibleSectionComponent,
  FwUserFilterSelect,  FwMapComponent,
  FwMapContainerComponent
}
  from './components/index';

import {
  CrudButtonsComponent,
  NavButtonsComponent,
  SwitchButtonComponent,
  CustomButtonsComponent,
  CrudIconButtonsComponent
} from './components/fw-buttons/index';

import { TabsFactoryManagerComponent } from './components/tabs-factory/components/manager/tabs-factory-manager.component';

import { MultiTierMenuComponent } from '../fw-menus/fw-multi-tier-menu/fw-multi-tier-menu.component';
import { MultiTierItemComponent } from '../fw-menus/fw-multi-tier-item/fw-multi-tier-item.component';
import { UserRecentsComponent, UserRecentComponent } from './components/user-recents';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HomPipesModule,
    MultiSelectDropdownModule,
    SharedModule
  ],
  declarations: [
    HighlightDirective,
    HomDatePickerDirective,
    PhoneMaskDirective,
    ZipCodeMaskDirective,
    AppTabsDirective,
    DateMaskDirective,
    AsciiOnlyDirective,
    ScreenSmall,
    ScreenLarge,
    ScreenBelowLarge,
    SpinnerComponent,
    EntityStatusComponent,
    Pagination,
    UnlessDirective,
    DisableControlDirective,
    AppFocusDirective,
    HasErrorDirective,
    DecimalMaskDirective,
    DigitOnlyMaskDirective,
    InfiniteScrollDirective,
    TabsFactoryManagerComponent,
    ComponentTitleComponent,
    ComponentSubTitleComponent,
    SectionTitleComponent,
    CrudButtonsComponent,
    CrudIconButtonsComponent,
    CustomButtonsComponent, 
    NavButtonsComponent,
    SwitchButtonComponent,
    CalendarComponent,
    ErrorBoxComponent,
    TabsComponent,
    MultiTierMenuComponent,
    MultiTierItemComponent,
    ButtonTitleBarComponent,
    AccordianComponent,
    EditableSelectComponent,
    ComponentFooterComponent,
    FwDynamicElementComponent,
    FwMapComponent,
    FwMapContainerComponent,
    PhoneComponent,
    EmailComponent,
    CheckBoxComponent,
    RadioButtonComponent,
    FwCalendarComponent,
    CommunicationPreferenceComponent,
    UserRecentComponent,
    UserRecentsComponent,
    AccordianSingleComponent,
    FwContainerComponent,
    CollapsibleSectionComponent,
    FwUserFilterSelect
  ],
  exports: [
    HighlightDirective,
    PhoneMaskDirective,
    ZipCodeMaskDirective,
    HomDatePickerDirective,
    DateMaskDirective,
    AsciiOnlyDirective,
    ScreenSmall,
    ScreenLarge,
    ScreenBelowLarge,
    MultiSelectDropdownModule,
    SpinnerComponent,
    EntityStatusComponent,
    Pagination,
    UnlessDirective,
    DisableControlDirective,
    AppFocusDirective,
    HasErrorDirective,
    DecimalMaskDirective,
    DigitOnlyMaskDirective,
    InfiniteScrollDirective,
    ComponentTitleComponent,
    ComponentSubTitleComponent,
    SectionTitleComponent,
    CrudButtonsComponent,
    CrudIconButtonsComponent,
    CustomButtonsComponent, 
    NavButtonsComponent,
    SwitchButtonComponent,
    TabsFactoryManagerComponent,
    CalendarComponent,
    CalendarComponent,
    ErrorBoxComponent,
    TabsComponent,
    AppTabsDirective,
    MultiTierMenuComponent,
    MultiTierItemComponent,
    ButtonTitleBarComponent,
    AccordianComponent,
    EditableSelectComponent,
    ComponentFooterComponent,
    FwDynamicElementComponent,
    FwMapComponent,
    FwMapContainerComponent,
    PhoneComponent,
    EmailComponent,
    CheckBoxComponent,
    RadioButtonComponent,
    FwCalendarComponent,
    CommunicationPreferenceComponent,
    UserRecentsComponent,
    AccordianSingleComponent,
    FwContainerComponent,
    CollapsibleSectionComponent,
    FwUserFilterSelect
  ]
})
export class FwSharedModule {
  static forRoot(): ModuleWithProviders<FwSharedModule> {
    return {
      ngModule: FwSharedModule,
      providers: [
        ScreenService,
        TabsFactoryService,
        TabsService,
        UserRecentsService,
        MapService]
    };
  }
}
