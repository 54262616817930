import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ServiceGroup, IServiceGroup } from '../../../view-models';
import { AdminStore, ServiceGroupCreationWizardEvent, ServiceGroupCreationWizardAction } from '../../enums/admin.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { IKey, CreateObjectModel, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces/index';

import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';

import { IListObjectData } from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'service-group',
  templateUrl: './service-group.component.html'
})
export class ServiceGroupComponent implements OnInit, OnDestroy {

  public objectData: IServiceGroup;
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  displayFields: string[] = ['serviceGroupName', 'serviceGroupDescription'];
  operation: string;
  objectId: number = 0;
  inWizard: boolean;
  serviceGroupName: string;
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public ups: UserPriviledgesService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) {

    this.subscription.add(emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case ServiceGroupCreationWizardEvent.createServiceGroup:
            this.createRecord(e);
            break;
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case this.myConstants.emitterEventClose:
            this.onCancel();
          default:
            break;
        }
      }));
  }

  ngOnInit() {

    this.inWizard = this.activeRoute.outlet === 'wizard';

    if (this.inWizard) {
      this.activeRoute.parent.paramMap.subscribe(paramMap => {
        this.objectId = +paramMap.get('id');
        this.operation = paramMap.get('operation');
        this.setDetailConfig(paramMap);
        this.getDetail();
      });
    } else {
      this.activeRoute.paramMap.subscribe(paramMap => {
        this.objectId = +paramMap.get('id');
        this.operation = paramMap.get('operation');
        this.setDetailConfig(paramMap);
        this.getDetail();
      });
    }
    this.listenForCreateSuccess();
  }
  
  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    if (this.inWizard) {
      params.customButtons = [
        { title: 'Create and Close', css: 'app-btn--primary', event: ServiceGroupCreationWizardEvent.createServiceGroup, action: ServiceGroupCreationWizardAction.close },
        { title: 'Create and Next', css: 'app-btn--primary', event: ServiceGroupCreationWizardEvent.createServiceGroup, action: ServiceGroupCreationWizardAction.next }
      ];
    }
    params.showErrorBox = true;
    params.showNav = true;
    params.parentId = -1;
    params.showNext = true;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(AdminStore.serviceGroups, -1, 'serviceGroupId', this.objectId)))
      .subscribe(entity => {
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate
          ? new ServiceGroup() : entity;
      })
    );
  }

  createRecord(e: IHomEventEmitter) {
    let rec: IServiceGroup = this.setCommonProperties(e.data);
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const createData = new CreateObjectModel(AdminStore.serviceGroups, -1, 'ServiceGroup', 'Create', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
    this.serviceGroupName = rec.serviceGroupName;
  }

  listenForCreateSuccess() {
    this.subscription.add(this.store.pipe(select(fromDynamicList.getEntityListByParentId(AdminStore.serviceGroups, -1)))
      .subscribe((state: IListObjectData) => {
        if (state && state.data && !state.working && !state.errorData.length && state.event && state.event.event !== this.myConstants.emitterEventListReload) {
          let event = cloneDeep(state.event);
          if (this.inWizard) {
            if (event.action !== ServiceGroupCreationWizardAction.close) {
              const newRecord: IServiceGroup = state.data.find(x => x.serviceGroupName === this.serviceGroupName);
              event.data = { serviceGroupId: newRecord.serviceGroupId, serviceGroupName: this.serviceGroupName };
              event.event = ServiceGroupCreationWizardEvent.serviceGroupCreated;
              this.emitterService.emitServiceGroupWizardEvent(event);
              this.reloadEntityList();
            } else {
              this.onCancel();
            }
          } else {
            if (event.action === this.myConstants.emitterEventClose) {
              this.reloadEntityList();
            }
          }
        }
      }));
  }

  reloadEntityList(): void {
    const emitter: IHomEventEmitter = { requestor: 'admin-service-groups', event: this.myConstants.emitterEventListReload, action: '', data: null };
    this.store.dispatch(new DynamicListActions.SetEventList({
      storeName: AdminStore.serviceGroups,
      parentId: -1,
      event: emitter
    }));
  }

  onCancel() {
    this.reloadEntityList();
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  updateRecord(e: IHomEventEmitter) {
    let rec: IServiceGroup = this.setCommonProperties(e.data);
    const keyData: IKey = { storeName: AdminStore.serviceGroups, parentId: -1, key: 'serviceGroupId', id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, 'ServiceGroup', 'Update', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  setCommonProperties(formData: IServiceGroup): IServiceGroup {
    let rec = new ServiceGroup();
    rec.serviceGroupId = this.objectId;
    rec.serviceGroupName = formData.serviceGroupName;
    rec.serviceGroupDescription = formData.serviceGroupDescription;
    rec.provider_providerId = this.ups.providerId$.value;
    return rec;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
