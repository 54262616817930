
export interface IPrivilegeTemplate {
  privilegeTemplateId: number;
  provider_providerId: number;
  name: string;
  description: string;
}

export class PrivilegeTemplate implements IPrivilegeTemplate {
  privilegeTemplateId: number;
  provider_providerId: number;
  name: string;
  description: string;

  constructor() { }

}
