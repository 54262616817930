import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'shipper-location-payouts',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './shipper-location-payouts.component.html'
})
export class ShipperLocationPayoutsComponent implements OnInit {
  providerLocationShipperInventorySetupId: number = 0;
  public listDefinition: IListDefinition;

  constructor(
    public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService  ) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.providerLocationShipperInventorySetupId = +paramMap.get('id');
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadShipperLocationPayoutsListDefinition(this.providerLocationShipperInventorySetupId);
  }

}
