import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DomainObjectService } from '../../../../../shared/services/domain-object.service';
import { ProjectService } from '../../services/project.service';
import { IResponseBase } from '../../../../../shared/interfaces/index';

export interface IGenTask {
  id: string;
  status: string;
  statusText: string;
}

@Component({
  selector: 'work-orders-generate',
  templateUrl: './work-orders-generate.component.html'
})
export class WorkOrdersGenerateComponent implements OnInit, OnDestroy {
  public genTask: IGenTask = {
    id: 'Generating...',
    status: null,
    statusText: 'Retrieving project infromation from the Home Depot....'
  };
  projectId: string;
  locationId: string;
  merge: string;
  subscription: Subscription = new Subscription();

  constructor(public dos: DomainObjectService,
    public projectService: ProjectService,
    public activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = paramMap.get("id");
      this.locationId = paramMap.get("locationId");
      this.merge = paramMap.get("merge");
    });
    this.generate();
  }

  generate(): void {
    const form: FormData = new FormData;
    form.append('id', this.projectId.toString());
    form.append('providerLocationId', this.locationId);
    form.append('mergeIntoWo', this.merge);
    this.subscription.add(this.dos.createByMethodWithForm('WorkOrder', 'AutoGenWo', form).subscribe((res: IResponseBase) => {
      if (res.success) {
        this.genTask.status = "check-circle";
        this.genTask.statusText = "Complete";
        this.projectService.dispatchPurchaseOrders('work-orders-generate', Number(this.projectId), true);
        this.projectService.dispatchWorkOrders('work-orders-generate', Number(this.projectId), true);
      } else {
        this.genTask.status = "times-circle";
        this.genTask.statusText = res.errorData[0]['value'][0];
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
