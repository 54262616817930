import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'homdecimal' })
export class HomDecimalPipe implements PipeTransform {
  transform(value: string): string {
      let parts: string[] = value.split('.');
      let strVal = value.replace(/\D/g, ''); //also gets rid of .00
    if (parts.length === 1) {
      strVal = strVal.concat('00');
    } else if (parts[1].length === 1) {
      strVal = strVal.concat('0');
    }
    strVal = strVal === '' ? '0' : strVal;
    const newVal: number  = parseInt(strVal.replace(/[^0-9]/g, '')) / 100;

      return (newVal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: "decimal", useGrouping: false });
    }

  }

