import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IOrderTerm, IDynamicListHeaderCol, IDynamicListHeaderColSelection} from '../../interfaces/index';
import { DynamicListEvent, DynamicListAction } from '../../enums/dynamic-list.enum';

@Component({
  selector: 'fw-dynamic-list-header-item',
  templateUrl: './dynamic-list-header-item.component.html'
})
export class DynamicListHeaderItemComponent implements OnChanges {
  @Input() col: IDynamicListHeaderCol;
  @Input() sortable: boolean;

  @Output() public sortEvent = new EventEmitter<IHomEventEmitter>();

  public sortedAsc: boolean = true;

  constructor() {}

  /*
  Emits an event so parent can hit the database to sort the list based on the column clicked
  Most columns will only have one term
*/
  public sortList() {
    const action: string = this.col.singleSort && this.sortedAsc ? DynamicListAction.overrideAll
      : this.col.singleSort && !this.sortedAsc ? DynamicListAction.removeItem
      : this.col.isSorted && this.sortedAsc ? DynamicListAction.changeItem
        : this.col.isSorted && !this.sortedAsc ? DynamicListAction.removeItem
          : DynamicListAction.addItem;

    let outTerms: IOrderTerm[] = [];
    this.col.colTerms.forEach((term: IOrderTerm) => {
      outTerms.push({
        term: term.term,
        orderAsc: this.col.isSorted ? this.sortedAsc ? false : true : true, //action of remove will ignore
        sortOrder: term.sortOrder
      })
    });

    const data: IDynamicListHeaderColSelection = { colTerms: outTerms, singleSort: this.col.singleSort };
    const event: IHomEventEmitter = {
      requestor: 'dynamic-list-header-item',
      event: DynamicListEvent.sortList,
      action: action,
      data: data
    };
    this.sortEvent.emit(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.col) {
      this.sortedAsc = !this.col.isSorted
        ? true
        : this.col.colTerms && this.col.colTerms.length > 0 && this.col.colTerms[0].orderAsc
          ? true : false;
    }
  }

}
