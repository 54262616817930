import { Component, OnInit, OnChanges, SimpleChanges,  OnDestroy, Inject, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IPoImportHeader, IBranchProgram } from '../../../view-models';
import { ILookupData } from '../../../../../shared/interfaces';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'po-import-header',
  templateUrl: './po-import-header.component.html',
  providers: [MetaDataService]
})
export class PoImportHeaderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() poImportHeader: IPoImportHeader;
  @Input() branchPrograms: IBranchProgram[];
  @Input() storeName: string;
  @Input() operation: string;
  @Input() detailConfig: IDetailContainerConfig;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public displayFields: string[] = ['vendorOrderNumber', 'programName', 'branchProgram', 'orderTotal', 'poImportStatus', 'poImportStatusText'];
  public title: string = 'Incoming Purchase Order Edit';
  public form: FormGroup;
  public statuses: ILookupData[];

  constructor(public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.setSelectedBranchProgram();
  }

  initForm(): void {
    this.mds.setFieldDefinitions(this.storeName);
    this.mds.loadSelectionLists(this.displayFields, this.operation, -1);
    this.statuses = this.mds.getSelectItems('poImportStatus');
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.poImportHeader, this.operation);
  }

  setSelectedBranchProgram(): void {
    const match: IBranchProgram = this.poImportHeader.branchProgram_branchProgramId
      ? this.branchPrograms.find(x => x.branchProgramId === this.poImportHeader.branchProgram_branchProgramId)
      : null;

    if (match) {
      const control = this.form.controls['branchProgram'];
      control.setValue(match);
    }
  }

  onSave(action: string): void {
    const formData = this.form.getRawValue();
    let rec: IPoImportHeader = this.setCommonProperties(formData);
    this.customEvent.emit({ requestor: 'po-import-header', event: this.myConstants.emitterEventUpdate, action: action, data: rec });
  }

  onCancel(): void {
    this.customEvent.emit({ requestor: 'po-import-header', event: this.myConstants.emitterEventClose, action: '', data: null });
  }

  setCommonProperties(data: IPoImportHeader): IPoImportHeader {
    let rec: IPoImportHeader = cloneDeep(this.poImportHeader);
    rec.branchProgram_branchProgramId = data.hasOwnProperty('branchProgram')
      && data['branchProgram']
      && data['branchProgram'].hasOwnProperty('branchProgramId')
      ? data['branchProgram']['branchProgramId']
      : null;
    return rec;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['poImportHeader'] && !(changes['poImportHeader'].firstChange)) {
      this.initForm();
      this.setSelectedBranchProgram();
    }
    if (changes['branchPrograms'] && !(changes['branchPrograms'].firstChange)) {
      this.setSelectedBranchProgram();
    }
  }
}
