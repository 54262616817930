import { Component, Input, OnInit, Inject, OnDestroy, Output, EventEmitter} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {  filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IContactMechanismAddress, IAddressState } from '../../../portals/view-models';
import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IFieldDefinition } from '../../../../fw/dynamic-forms';
import { ContactManagerService, ContactConstantsService } from '../../services/index';
import { MetaDataService } from '../../../../fw/dynamic-list/services/index';
import { IContactValueChanged } from '../../../portals/portal-shared/interfaces';

@Component({
  selector: 'contact-addresses',
  templateUrl: './contact-addresses.component.html',
  providers: [MetaDataService]
})
export class ContactAddressesComponent implements OnInit, OnDestroy {
  @Input() addresses: IContactMechanismAddress[];
  @Input() operation: string;
  @Input() contactTypeId: number;
  @Input() requestTime: string; //to handle subsequent create request for the same contact type (id never changes)
  @Input() displayType: string;
  @Input() canIEdit: boolean;

  @Output() public deleteEvent = new EventEmitter<IHomEventEmitter>();

  public activePrimary$: BehaviorSubject<number>;
  public fieldDefinitions: IFieldDefinition[];
  public displayFields = ['line1', 'city', 'zipcode5', 'addressState', 'yearBuilt', 'isPrimary', 'sanitizeOverride', 'contactMechanismAddressId', 'addressId', 'addressType', 'addressTypeId', 'latitude', 'longitude'];
  public states: IAddressState[];
  subscription: Subscription = new Subscription();

  constructor(public contactService: ContactManagerService,
    public contactConstantsService: ContactConstantsService,
    public metaDataService: MetaDataService,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) {
    this.activePrimary$ = new BehaviorSubject(-1);
  }

  ngOnInit(): void {
    //Loading the field definitions once for all addresses and passing them to the child components
    this.metaDataService.setFieldDefinitions('addresses');

    if (this.addresses) {
      this.activePrimary$.next(this.addresses.findIndex(x => x.isPrimary));
    }

    this.subscription.add(this.metaDataService.mdsReady$.pipe(filter(flag => flag === true), take(1))
      .subscribe((ready: boolean) => {
        this.fieldDefinitions = cloneDeep(this.metaDataService.getAllFieldDefinitions());
        this.metaDataService.loadSelectionLists(this.displayFields, 'contact-address', this.addresses && this.addresses.length > 0 ? this.addresses[0].contactId : -1);
        this.states = this.metaDataService.getSelectItems('addressState');
      }));

    //listen for value changes in child
    this.subscription.add(this.emitterService.contactEventEmitted$
      .pipe(filter(e => e.requestor === this.contactConstantsService.requestorTypeContactAddress && e.event === this.myConstants.emitterEventValueChanged)) //filter
      .subscribe((e: IHomEventEmitter) => {
        const data: IContactValueChanged = e.data;
        if (data.valueChanged.key === 'isPrimary' && data.valueChanged.value === true) {
          this.activePrimary$.next(this.contactService.getIndex(this.contactConstantsService.addressFormPrefix, data.groupName));
        }
      }));
    }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onDelete(event: IHomEventEmitter): void {
    this.deleteEvent.emit(event);
  }

}
