
<div class="notelist-item__reply">
  <div class="form-wrapper form-wrapper--edit">
    <span class="textarea--charsleft">{{charactersLeft$ | async }} characters left</span>
    <div class="float-label">
      <textarea #reply id="externalReply" name="externalReply" required 
                [ngClass]="{'has-error': !(hasReplyValue$ | async) }"
                [attr.maxlength]="replyMax"
                rows="3" columns="40"
                 placeholder="Reply" (input)="replyChange(reply.value)"></textarea>
      <label>Reply</label>
    </div>
    <fw-error-box *ngIf="errorId === noteId" [errorData]="errorData"  [ignoreKey]="true"></fw-error-box>
    <div class="app-btn-box">
      <button class="app-btn app-btn--primary"
              title="Reply to External Note"
              [disabled]="!(hasReplyValue$ | async)"
              (click)="sendReply(reply.value)">
        Reply
      </button>
    </div>
  </div>
</div>
