export enum FieldDefinitionPropertyType {
  Text = 'text',
  Flag = 'flag'
}
export enum FieldDefintionTerm {
  DataType = 'dataType',
  DisplayType = 'displayType',
  Flag = 'flag',
  MaxValue = 'maxValue',
  MinValue = 'minValue',
  EntityName = 'entityName',
  KeyField = 'keyField',
  ValueField = 'valueField',
  LabelField = 'labelField',
  MethodName = 'methodName',
  MethodByKey = 'methodByKey',
  FilterFor = 'filterFor',
  FilterContext = 'filterContext',
  NoCache = 'noCache',
  SearchTerm = 'searchTerm',
  DefaultOrderTerm = 'defaultOrderTerm',
  GetAll = 'getAll',
  AccessLevel = 'accessLevel',
  UseCustomResolver = 'useCustomResolver',
  ValidationText = 'validationText',
  PortalId = 'portalId',
  PortalLevel1IdKey = 'portalLevel1IdKey',
  PortalLevel2EntityName = 'portalLevel2EntityName',
  PortalLevel2IdKey = 'portalLevel2IdKey',
  PortalLevel3EntityName = 'portalLevel3EntityName',
  PortalLevel3IdKey = 'portalLevel3IdKey',
  PortalLevel2LabelKey = 'portalLevel2LabelKey',
  PortalLevel3LabelKey = 'portalLevel3LabelKey',
  Column1 = 'column1',
  Column2 = 'column2',
  OperationTerm = 'operationTerm',
  IsLookUp = 'isLookup',
  CustomStore = 'customStore',
  SingleSelect = 'singleSelect',
  IsSubList = 'isSubList',
  ForFilterOnly = 'forFilterOnly',
  SelectLabel = 'selectLabel',
  EventName = 'eventName',
  FilterFieldType = 'filterFieldType',
  Pattern = 'pattern'
}

export enum FieldDefinitionDisplayType {
  Phone = 0,
  Email = 1,
  ZipCode = 2,
  Currency = 3,
  Percentage = 4,
  EditableSelect = 5,
  Date = 6,
  Bool = 7,
  DropDown = 8,
  DecimalInput = 9,
  UnavailableReasons = 10,
  Specialnstructions = 11,
  WorkOrderInfo = 12,
  CustomerAlert = 13,
  CustomerFlag = 14,
  CommunicationPreference = 15,
  CrewCompatibility = 16,
  NoteContext = 17,
  CustomerContact = 18,
  InstallerCertificationExpirationDate = 19,
  FilterableSelect = 20,
  DocumentActions = 21,
  DocumentProperties = 22,
  InstallerCrew = 23
}


//ParameterType.cs
export enum FieldDefinitionFilterFieldType {
  String = 0,
  Int = 1,
  Bool = 2,
  DateTime = 3,
  Enum = 4,
  Double = 5,
  LookupValue = 6,
  DateTimeNull = 7,
  None = 8,
  StaticClass = 9,
  TimeSpan = 10,
  Entity = 11,
  Date = 12,
  Image = 13,
  DoubleDecimal = 14,
  IntNull = 15,
  DoubleNull = 16,
  DoubleDecimalNull = 17,
  BoolNull = 18,
  Color = 19
}
