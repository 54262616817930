import {
  ChangeDetectorRef,
  ComponentRef, 
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewContainerRef,
  ApplicationRef,
  Injector,
  OnDestroy,
  TemplateRef,
  EmbeddedViewRef,
  ElementRef
} from '@angular/core';
import { PopoverPosition } from './fw-popover.placement';
import { PopoverComponent } from './fw-popover.component';

@Directive({
  selector: '[fw-popover]'
})

export class PopoverDirective implements OnChanges, OnDestroy {

  constructor(
    protected viewContainerRef: ViewContainerRef,
    protected cdr: ChangeDetectorRef,
    protected appRef: ApplicationRef,
    private injector: Injector,
    private hostElem: ElementRef<HTMLElement>
  ) { }

  @Input() template: TemplateRef<any>;
  @Input() showPopover: boolean;
  @Input() arrowColor: string = '#fff';
  @Input() closeOnClickOutside: boolean = false;
  @Input() showOnHover: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() position: PopoverPosition = PopoverPosition.default;
  @Input() showFullOnMobile: boolean = false;
  @Input() isDynamic: boolean = false;

  @Output() onShow = new EventEmitter<PopoverDirective>();
  @Output() onHide = new EventEmitter<PopoverDirective>();

  popover: ComponentRef<PopoverComponent>;
  visible: boolean = false;


  ngOnChanges(changes: SimpleChanges) {
    if (changes['showPopover'] && !changes['showPopover'].firstChange) {
      if (!changes['showPopover'].currentValue && this.visible) {
        this.hide();
      }
    }
  }

  @HostListener('click', ['$event']) onClick(evt: Event): void {
    if (this.showOnHover || this.isDisabled || (this.visible && !this.closeOnClickOutside)) return;
    this[!this.visible ? 'show' : 'hide']();
  }

  @HostListener('touchend', ['$event']) onTouch(evt: Event): void {
    evt.stopImmediatePropagation();
    if (!this.showOnHover || this.isDisabled) return;
    this.togglePopover();
  }

  @HostListener('focusin') @HostListener('mouseenter') onHover(): void {
    if (!this.showOnHover || this.isDisabled || (this.showOnHover && this.visible)) return;
    this.show();
  }

  @HostListener('focusout') @HostListener('mouseleave') onHoverOut(): void {
    if (!this.showOnHover || this.isDisabled) return;
    this.hide();
  }

  togglePopover(): void {
    this[!this.visible ? 'show' : 'hide']();
  }

  show(): void {
    this.visible = true;
    this.createComponent();
    this.onShow.emit(this);
  }

  hide(): void {
    this.visible = false;
    this.removeComponent();
    this.onHide.emit(this);
  }

  createComponent(): void {
    const componentRef = this.viewContainerRef.createComponent(PopoverComponent);
    componentRef.instance.popoverDirective = this;
    componentRef.instance.closeOnClickOutside = this.closeOnClickOutside;
    componentRef.instance.template = this.template;
    componentRef.instance.hostElem = this.hostElem;
    componentRef.instance.arrowColor = this.arrowColor;
    componentRef.instance.position = this.position;
    componentRef.instance.showFullOnMobile = this.showFullOnMobile;
    componentRef.instance.isDynamic = this.isDynamic;
    this.popover = componentRef;
    document.body.appendChild((componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement);
  }

  removeComponent(): void {
    this.popover.destroy();
  }

  ngOnDestroy() {
    if (this.visible) this.hide();
  }

}
