import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { IWorkOrder, IPurchaseOrder, IPurchaseOrderInventoryAllocation, IWorkOrderItem, IPurchaseOrderItem, IChargeBack } from '../../../app/portals/view-models/index';
import { UserPriviledgesService } from '../../../auth/services/index';

@Injectable()
export class WarrantyDynamicListService {

  constructor(public userPriviledgesService: UserPriviledgesService,
    public datePipe: DatePipe) { }

  handleCustomMethod(methodName: string, row: any, portalEntityId: number): any {
    switch (methodName) {
      case 'getTBDCss':
        return this.getTBDCss(row);
      case 'canITBD':
        return this.canITBD(row);
      default:
        return false;
    }
  }
  /*CUSTOM WORK ORDER METHODS TO SET CSS */
  getTBDCss(row: IWorkOrder): string {
    return row && row.hasOwnProperty('workOrderWarrantyCount') && row.workOrderWarrantyCount ? 'app-btn-icon--warning' : '';
  }

  /*CUSTOM WORK ORDER METHODS TO DETERMINE BUTTON ENABLED */
  canITBD(row: IWorkOrder): boolean {
    return row && row.hasOwnProperty('workOrderScheduleText') && (row.workOrderScheduleText === '' || row.workOrderScheduleText === 'None');
  }
}
