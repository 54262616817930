import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'loading-page',
 templateUrl: './loading-page.component.html'
}) export class LoadingPageComponent implements AfterViewInit {

  constructor(
    public activeRoute: ActivatedRoute
  ) { }

  @ViewChild('loadingdiv', { read: ElementRef }) loadingDiv: ElementRef<HTMLDivElement>;

  ngAfterViewInit() {
    this.activeRoute.paramMap.subscribe(params => {
      if (params.get('container')) {
        if (params.get('container') === 'modal-content') return;
        const clientRect = document.getElementsByClassName(params.get('container'))[0].getBoundingClientRect();
        this.loadingDiv.nativeElement.style.height = clientRect.height + 'px';
      }
    });
  }

}
