<div class="portal-container" *ngIf="portalBaseService.portalData">
    <fw-container>
      <div class="portal__nav">
          <a  [routerLink]="['/widget-layout-editor', portalBaseService.portalId, { portalEntityId: portalBaseService.portalEntityId}]"
              routerLinkActive="router-link-active" title="Edit Widget Layout" aria-label="Edit Widget Layout">
              <span class="portal__nav--icon"><i class="fas fa-cogs fa-lg" aria-hidden="true"></i></span>
          </a>
      </div>

      <div class="widget-layout">
          <ng-container *ngFor="let section of portalBaseService.portalSections; let j = index;">
            <div class="widget-layout__section" *ngFor="let widget of section.userWidgetLayouts;">
              <div class="widget__header">{{ widget.widgetTitle }}</div>
              <div class="widget__content">
                <dynamic-host [componentSeedData]="widget.componentSeedData"></dynamic-host>
              </div>
            </div>
          </ng-container>
      </div>
    </fw-container>
</div>
