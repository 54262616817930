
export interface IShipper {
  shipperId: number;
  provider_providerId: number;
  shipperName: string;
  defaultLeadTimeDays: number;
  customerNumber: string;
  defaultShipperDesignation: string;
}

export class Shipper implements IShipper {
  shipperId: number;
  provider_providerId: number;
  shipperName: string;
  defaultLeadTimeDays: number;
  customerNumber: string;
  defaultShipperDesignation: string;

  constructor() {
    this.shipperId = 0;
    this.provider_providerId = null;
    this.shipperName = '';
    this.defaultLeadTimeDays = 0;
    this.customerNumber = '';
    this.defaultShipperDesignation = '';
  }
}
