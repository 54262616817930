import { Action } from '@ngrx/store';
import { Auth, IAuthResult } from '../interfaces/index'; //auth interfaces

import { IResponseBase } from '../../shared/interfaces/index'; //auth interfaces
import { IProviderUser, IUnrespondedToContactModel } from '../../app/portals/view-models/index';
import { UpdateObjectModel } from './interfaces/index'; //store interfaces

//[auth] prefix ensures uniqueness - actions must be unique across app
export enum AuthActionTypes {
  TRY_SIGNIN_ACTION = '[auth] TRY_SIGNIN',
  START_SIGNIN_WORK_ACTION = '[auth] START_SIGNIN_WORK',
  TRY_SIGNUP_ACTION = '[auth] TRY_SIGNUP',
  SIGNUP_ACTION = '[auth] SIGNUP',
  SIGNIN_SUCCESS_ACTION = '[auth] SIGNIN_SUCCESS',
  LOGOUT_ACTION = '[auth] LOGOUT',
  LOGOUT_ROUTE_ACTION = '[auth] LOGOUT_ROUTE',
  SET_LOGOUT_ACTION = '[auth] SET_LOGOUT',
  SET_TOKEN_ACTION = '[auth] SET_TOKEN',
  GET_USER_CONFIGURATIONS_ACTION = '[auth] GET_USER_CONFIGURATIONS',
  SET_USER_CONFIGURATIONS_ACTION = '[auth] SET_USER_CONFIGURATIONS',
  SET_SIGNIN_ERROR_ACTION = '[auth] SET_SIGNIN_ERROR',
  REFRESH_ACTION = '[auth] REFRESH_ACTION',
  SET_REFRESHING = '[auth] SET_REFRESHING',
  REFRESH_USER_INFORMATION_ACTION = '[auth] REFRESH_USER_INFORMATION',
  GET_USER_PREFERENCES_ACTION = '[auth] GET_USER_PREFERENCES',
  SET_USER_PREFERENCES_ACTION = '[auth] SET_USER_PREFERENCES',
  UPDATE_USER_PREFERENCES_ACTION = '[auth] UPDATE_USER_PREFERENCES',
  UPDATE_USER_PREFERENCES_COMPLETE_ACTION = '[auth] UPDATE_USER_PREFERENCES_COMPLETE',
  GET_OPEN_NOTIFICATION_COUNT_ACTION = '[auth] GET_OPEN_NOTIFICATION_COUNT',
  SET_OPEN_NOTIFICATION_COUNT_ACTION = '[auth] SET_OPEN_NOTIFICATION_COUNT',
  SET_HUB_OPEN_NOTIFICATION_COUNT_ACTION = '[auth] SET_HUB_OPEN_NOTIFICATION_COUNT_ACTION',
  GET_USER_NOTIFICATIONS_ACTION = '[auth] GET_USER_NOTIFICATIONS',
  SET_USER_NOTIFICATIONS_ACTION = '[auth] SET_USER_NOTIFICATIONS',
  GET_PENDING_CHANGES_COUNT_ACTION = '[auth] GET_PENDING_CHANGES_COUNT',
  SET_PENDING_CHANGES_COUNT_ACTION = '[auth] SET_PENDING_CHANGES_COUNT',
  SET_HUB_PENDING_CHANGES_COUNT_ACTION = '[auth] SET_HUB_PENDING_CHANGES_COUNT_ACTION',
  GET_TOP_BAR_COUNTS_ACTION = '[auth] GET_TOP_BAR_COUNTS',
  GET_ALL_MY_WIDGETS_ACTION = '[auth] GET_ALL_MY_WIDGETS_ACTION',
  SET_ALL_MY_WIDGETS_ACTION = '[auth] SET_ALL_MY_WIDGETS_ACTION',
  GET_PORTAL_TABS = '[auth] GET_PORTAL_TABS',
  SET_PORTAL_TABS = '[auth] SET_PORTAL_TABS', 
  GET_PARAMETER_SEARCH_TYPES = '[auth] GET_PARAMETER_SEARCH_TYPES',
  SET_PARAMETER_SEARCH_TYPES = '[auth] SET_PARAMETER_SEARCH_TYPES',
  SET_ERROR_RETURNED_ACTION = '[auth] SET_ERROR_RETURNED',
  SET_APPLICATION_VERSION_ACTION = '[auth] SET_APPLICATION_VERSION',
  SET_PENDING_UPDATE_DATE_ACTION = '[auth] SET_PENDING_UPDATE_DATE',
  SET_VALID_USER_ACCESS = '[auth] SET_VALID_USER_ACCESS',
  GET_LIST_COMPONENTS = '[auth] GET_LIST_COMPONENTS',
  SET_LIST_COMPONENTS = '[auth] SET_LIST_COMPONENTS',
  GET_USER_RECENTS = '[auth] GET_USER_RECENTS',
  SET_USER_RECENTS = '[auth] SET_USER_RECENTS',
  CREATE_USER_RECENT = '[auth] CREATE_USER_RECENT',
  CREATE_USER_RECENT_COMPLETE = '[auth] CREATE_USER_RECENT_COMPLETE',
  DELETE_USER_RECENT = '[auth] DELETE_USER_RECENT',
  DELETE_USER_RECENT_COMPLETE = '[auth] DELETE_USER_RECENT_COMPLETE',
  CLEAR_USER_RECENT_ERROR = '[auth] CLEAR_USER_RECENT_ERROR',
  SET_HUB_UNRESPONDED_TO_CONTACTS_ACTION = '[auth] SET_HUB_UNRESPONDED_TO_CONTACTS_ACTION',
  REMOVE_UNRESPONDED_TO_CONTACT_ACTION = '[auth] REMOVE_UNRESPONDED_TO_CONTACT_ACTION',
  ADD_UNRESPONDED_TO_CONTACT_ACTION = '[auth] ADD_UNRESPONDED_TO_CONTACT_ACTION',
  GET_USER_LIST_FILTERS = '[auth] GET_USER_LIST_FILTERS',
}


export class SetApplicationVersion implements Action {
  readonly type = AuthActionTypes.SET_APPLICATION_VERSION_ACTION;
  constructor(
    public payload: boolean
  ) { }
}

export class SetPendingUpdateDate implements Action {
  readonly type = AuthActionTypes.SET_PENDING_UPDATE_DATE_ACTION;
  constructor(
    public payload: boolean
  ) { }
}

export class StartSigninWork implements Action {
  readonly type = AuthActionTypes.START_SIGNIN_WORK_ACTION;
}

export class TrySignup implements Action {
  readonly type = AuthActionTypes.TRY_SIGNUP_ACTION;
  constructor(
    public payload: { authData: Auth }
  ) { }
}

export class TrySignin implements Action {
  readonly type = AuthActionTypes.TRY_SIGNIN_ACTION;
  constructor(
    public payload: { authData: Auth }
  ) { }

}

export class Signup implements Action {
  readonly type = AuthActionTypes.SIGNUP_ACTION;
}

export class SigninSuccess implements Action {
  readonly type = AuthActionTypes.SIGNIN_SUCCESS_ACTION;
  constructor(
    public payload: IAuthResult
  ) { }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.LOGOUT_ACTION;
  constructor(
    public payload: { initLogoutUserSessionEvent: boolean }
  ) { }
}

export class LogoutRoute implements Action {
  readonly type = AuthActionTypes.LOGOUT_ROUTE_ACTION;
}

export class SetLogout implements Action {
  readonly type = AuthActionTypes.SET_LOGOUT_ACTION;
}

export class GetUserConfigurations implements Action {
  readonly type = AuthActionTypes.GET_USER_CONFIGURATIONS_ACTION;

  constructor(
    public payload: IAuthResult 
  ) { }
}

export class SetUserConfigurations implements Action {
  readonly type = AuthActionTypes.SET_USER_CONFIGURATIONS_ACTION;

  constructor(
    public payload: { providerUser: IProviderUser, returnUrl: string }
  ) { }
}

export class SetSigninError implements Action {
  readonly type = AuthActionTypes.SET_SIGNIN_ERROR_ACTION;

  constructor(
    public payload: IAuthResult
  ) { }
}

export class RefreshAction implements Action {
  readonly type = AuthActionTypes.REFRESH_ACTION;
}

export class SetRefreshing implements Action {
  readonly type = AuthActionTypes.SET_REFRESHING;
  constructor(
    public payload: boolean
  ) { }
}

export class RefreshUserInformation implements Action {
  readonly type = AuthActionTypes.REFRESH_USER_INFORMATION_ACTION;
  constructor(
    public payload: IAuthResult
  ) { }
}

export class GetUserPreferences implements Action {
  readonly type = AuthActionTypes.GET_USER_PREFERENCES_ACTION;
  constructor(
    public payload: { providerUserId: number }
  ) { }
}

export class SetUserPreferences implements Action {
  readonly type = AuthActionTypes.SET_USER_PREFERENCES_ACTION;
  constructor(
    public payload: { responseBase: IResponseBase }
  ) { }
}

export class UpdateUserPreferences implements Action {
  readonly type = AuthActionTypes.UPDATE_USER_PREFERENCES_ACTION;
  constructor(
    public payload: { updateData: UpdateObjectModel }
    ) { }
}

export class UpdateUserPreferencesComplete implements Action {
  readonly type = AuthActionTypes.UPDATE_USER_PREFERENCES_COMPLETE_ACTION;
  constructor(
    public payload: { responseBase: IResponseBase }
  ) { }
}


export class GetOpenNotificationCount implements Action {
  readonly type = AuthActionTypes.GET_OPEN_NOTIFICATION_COUNT_ACTION;
  constructor(
    public payload: { providerUserId: number }
  ) { }
}

export class SetOpenNotificationCount implements Action {
  readonly type = AuthActionTypes.SET_OPEN_NOTIFICATION_COUNT_ACTION;
  constructor(
    public payload: { responseBase: IResponseBase }
  ) { }
}


export class GetPendingChangesCount implements Action {
  readonly type = AuthActionTypes.GET_PENDING_CHANGES_COUNT_ACTION;
  constructor(
    public payload: { providerUserId: number }
  ) { }
}

export class SetPendingChangesCount implements Action {
  readonly type = AuthActionTypes.SET_PENDING_CHANGES_COUNT_ACTION;
  constructor(
    public payload: { responseBase: IResponseBase }
  ) { }
}

export class SetHubOpenNotificationCount implements Action {
  readonly type = AuthActionTypes.SET_HUB_OPEN_NOTIFICATION_COUNT_ACTION;
  constructor(
    public payload: { count: number }
  ) { }
}


export class SetHubPendingChangesCount implements Action {
  readonly type = AuthActionTypes.SET_HUB_PENDING_CHANGES_COUNT_ACTION;
  constructor(
    public payload: { count: number }
  ) { }
}

//Can get counts separately (above) or together (here) 
//Will set separately
export class GetTopBarCounts implements Action {
  readonly type = AuthActionTypes.GET_TOP_BAR_COUNTS_ACTION;
  constructor(
    public payload: { providerUserId: number }
  ) { }
}

export class GetAllMyWidgets implements Action {
  readonly type = AuthActionTypes.GET_ALL_MY_WIDGETS_ACTION;
}

export class SetAllMyWidgets implements Action {
  readonly type = AuthActionTypes.SET_ALL_MY_WIDGETS_ACTION;
  constructor(
    public payload: {responseBase: IResponseBase }
  ) { }
}

export class GetPortalTabs implements Action {
  readonly type = AuthActionTypes.GET_PORTAL_TABS;
  constructor(
    public payload: { providerId: number }
  ) { }
}

export class SetPortalTabs implements Action {
  readonly type = AuthActionTypes.SET_PORTAL_TABS;
  constructor(
    public payload: { responseBase: IResponseBase }
  ) { }
}


export class GetParameterSearchTypes implements Action {
  readonly type = AuthActionTypes.GET_PARAMETER_SEARCH_TYPES;
  constructor(
    public payload: { providerId: number }
  ) { }
}

export class SetParameterSearchTypes implements Action {
  readonly type = AuthActionTypes.SET_PARAMETER_SEARCH_TYPES;
  constructor(
    public payload: { responseBase: IResponseBase }
  ) { }
}

export class GetListComponents implements Action {
  readonly type = AuthActionTypes.GET_LIST_COMPONENTS;
}

export class SetListComponents implements Action {
  readonly type = AuthActionTypes.SET_LIST_COMPONENTS;
  constructor(
    public payload: { responseBase: IResponseBase }
  ) { }
}

export class SetErrorReturned implements Action {
  readonly type = AuthActionTypes.SET_ERROR_RETURNED_ACTION;
  constructor(
    public payload: {  errors: IAuthResult}
  ) { }
}

export class GetUserNotifications implements Action {
  readonly type = AuthActionTypes.GET_USER_NOTIFICATIONS_ACTION;
  constructor(
    public payload: { providerUserId: number }
  ) { }
}

export class SetUserNotifications implements Action {
  readonly type = AuthActionTypes.SET_USER_NOTIFICATIONS_ACTION;
  constructor(
    public payload: { responseBase: IResponseBase }
  ) { }
}


export class SetValidUserAccess implements Action {
  readonly type = AuthActionTypes.SET_VALID_USER_ACCESS;
  constructor(
    public payload: boolean
  ) { }
}

export class ClearUserRecentError implements Action {
  readonly type = AuthActionTypes.CLEAR_USER_RECENT_ERROR;
}

export class GetUserRecents implements Action {
  readonly type = AuthActionTypes.GET_USER_RECENTS;
  constructor(
    public payload: { providerUserId: number }
  ) { }
}

export class SetUserRecents implements Action {
  readonly type = AuthActionTypes.SET_USER_RECENTS;
  constructor(
    public payload: { responseBase: IResponseBase }
  ) { }
}

export class CreateUserRecent implements Action {
  readonly type = AuthActionTypes.CREATE_USER_RECENT;
  constructor(
    public payload: { updateData: UpdateObjectModel }
  ) { }
}

export class CreateUserRecentComplete implements Action {
  readonly type = AuthActionTypes.CREATE_USER_RECENT_COMPLETE;
  constructor(
    public payload: { responseBase: IResponseBase }
  ) { }
}

export class DeleteUserRecent implements Action {
  readonly type = AuthActionTypes.DELETE_USER_RECENT;
  constructor(
    public payload: { id: number }
  ) { }
}

export class DeleteUserRecentComplete implements Action {
  readonly type = AuthActionTypes.DELETE_USER_RECENT_COMPLETE;
  constructor(
    public payload: { responseBase: IResponseBase, id: number }
  ) { }
}

export class SetHubUnrespondedToContacts implements Action {
  readonly type = AuthActionTypes.SET_HUB_UNRESPONDED_TO_CONTACTS_ACTION;
  constructor(
    public payload: { model: IUnrespondedToContactModel[] }
  ) { }
}

export class RemoveUnrespondedToContact implements Action {
  readonly type = AuthActionTypes.REMOVE_UNRESPONDED_TO_CONTACT_ACTION;
  constructor(
    public payload: { contactId: number }
  ) { }
}

export class AddUnrespondedToContact implements Action {
  readonly type = AuthActionTypes.ADD_UNRESPONDED_TO_CONTACT_ACTION;
  constructor(
    public payload: { model: IUnrespondedToContactModel[] }
  ) { }
}


export class GetUserListFilters implements Action {
  readonly type = AuthActionTypes.GET_USER_LIST_FILTERS;
  constructor(
    public payload: { providerUserId: number }
  ) { }
}


export type AuthActions =
  StartSigninWork |
  Signup |
  SigninSuccess |
  Logout |
  LogoutRoute |
  SetLogout |
  TrySignup |
  TrySignin |
  SetSigninError |
  GetUserConfigurations |
  SetUserConfigurations |
  RefreshUserInformation |
  GetUserPreferences |
  SetUserPreferences |
  UpdateUserPreferences |
  UpdateUserPreferencesComplete |
  GetUserNotifications |
  SetUserNotifications |
  GetOpenNotificationCount |
  SetOpenNotificationCount |
  GetPendingChangesCount |
  SetPendingChangesCount |
  SetHubOpenNotificationCount |
  SetHubPendingChangesCount |
  GetTopBarCounts |
  GetAllMyWidgets |
  SetAllMyWidgets |
  GetPortalTabs |
  SetPortalTabs |
  GetParameterSearchTypes |
  SetParameterSearchTypes |
  GetListComponents |
  SetListComponents |
  SetApplicationVersion |
  SetErrorReturned |
  SetValidUserAccess |
  RefreshAction |
  SetRefreshing |
  SetPendingUpdateDate |
  GetUserRecents |
  SetUserRecents |
  CreateUserRecent |
  CreateUserRecentComplete |
  DeleteUserRecent |
  DeleteUserRecentComplete |
  ClearUserRecentError |
  SetHubUnrespondedToContacts |
  RemoveUnrespondedToContact |
  AddUnrespondedToContact |
  GetUserListFilters;
