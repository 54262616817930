import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminPricingService } from '../../services/admin-pricing.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './charge-back-sku-prices.component.html'
})
export class ChargeBackSkuPricesComponent implements OnInit {

  public listDefinition: IListDefinition;
  chargeBackSkuId: number = 0;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminPricingService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.chargeBackSkuId = +paramMap.get('id');
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadChargeBackSkuPricesListDefinition(this.chargeBackSkuId);
  }


}
