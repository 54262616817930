import { Injectable, OnDestroy} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, map, filter , first } from 'rxjs/operators';

import * as fromSelectionLists from '../../../shared/store/selectionLists/index';
import { IEnum } from '../view-models';

@Injectable()
export class EnumResolver  implements OnDestroy {
  storeName: string = '';
  entityName: string = '';
  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromSelectionLists.IStoreState> ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IEnum[]> {
    this.storeName = route.data['storeName'];
    this.entityName = route.data['entityName'];

    this.requestData();

    return this.waitForDataToLoad();
  }

  requestData(): void {
    this.subscription.add(this.store.select(fromSelectionLists.getSelectionListDataByType(this.storeName, -1))
      .pipe(take(1))
      .subscribe((data: any[]) => {
        if (data === null) {
          const api = '/Api/Enum/' + this.entityName + '/LookupValues';
          this.store.dispatch(new fromSelectionLists.GetLookupList({ typeName: this.storeName, api: api }));
        }
      }));
  }

  waitForDataToLoad(): Observable<IEnum[]> {
    return this.store.select(fromSelectionLists.getSelectionListDataByType(this.storeName, -1))
      .pipe(
        filter((data: any[]) => data !== null),
        map((data: any[]) => { return data; }),
        first()
      );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
