import { IUserListFilterColumn } from ".";

export interface IUserListFilter {
  userListFilterId: number;
  providerUser_providerUserId: number;
  listComponent_listComponentId: number;
  filterName: string;
  isCurrent: boolean;
  isDefault: boolean;
  lastUsed: string;
  usedCount: number;

  //extra data
  filterColumns: IUserListFilterColumn[];
}


export class UserListFilter implements IUserListFilter {
    userListFilterId: number;
    providerUser_providerUserId: number;
    listComponent_listComponentId: number;
    filterName: string;
    isCurrent: boolean;
    isDefault: boolean;
    lastUsed: string;
    usedCount: number;
    filterColumns: IUserListFilterColumn[];
  constructor(providerUserId: number, listComponentId: number, filterName: string, isCurrent: boolean, isDefault: boolean, lastUsed: string, usedCount: number) {
    this.userListFilterId = 0;
    this.providerUser_providerUserId = providerUserId;
    this.listComponent_listComponentId = listComponentId;
    this.filterName = filterName;
    this.isCurrent = isCurrent;
    this.isDefault = isDefault;
    this.lastUsed = lastUsed;
    this.usedCount = usedCount;
  }
}
