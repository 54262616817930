export enum ContactProjectStore {
  contactProjects = 'contactProjects',
  contactProjectWOs = 'contactProjectWOs',
  contactProjectPOs = 'contactProjectPOs'
}

export enum ContactProjectEvent {
  selectProject = 'selectProject',
  selectWorkOrder = 'selectWorkOrder',
  selectPurchaseOrder = 'selectPurchaseOrder'
}
