import { Component, OnInit, ChangeDetectionStrategy, OnChanges, Input, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';

import { ChargeBackDashboardService } from '../../services/charge-back-dashboard.service';

@Component({
  selector: 'charge-back-installments',
  templateUrl: './charge-back-installments.component.html'
})
export class ChargeBackInstallmentsComponent implements OnInit, OnChanges {
  @Input() chargeBackId: number;

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public chargeBacksService: ChargeBackDashboardService) { }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.setListDefinition();
  }

  ngOnChanges(changes:SimpleChanges): void {
    if (changes['chargeBackId'] && !changes['chargeBackId'].isFirstChange()) {
      this.setListDefinition();
    }
  }

  setListDefinition(): void {
    this.listDefinition$.next(this.chargeBacksService.loadDashChargeBackInstallmentListDefinition(this.chargeBackId));
  }
}
