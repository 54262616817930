<div class="task-action">
  <div *ngIf="!projectTaskAction.taskActionTemplateName" class="task-action__btn">
    <button  class="app-btn app-btn--white "
            type="button" [disabled]="!canIEdit"
            (click)="completeAction()">
      {{projectTaskAction.taskActionName}}
    </button>
  </div>
  <task-action-key-rec *ngIf="projectTaskAction.taskActionTemplateName === taskManagerService.keyRecTemplate"
            [projectTaskAction]="projectTaskAction"
            [projectTaskId]="projectTaskId"
            [operation]="operation"
            [canIEdit]="canIEdit"
            (completeEvent)="onTemplateComplete($event)">
  </task-action-key-rec>
  <task-action-schedule-wo *ngIf="projectTaskAction.taskActionTemplateName === taskManagerService.scheduleWoTemplate"
            [projectTaskAction]="projectTaskAction"
            [projectTaskId]="projectTaskId"
            [operation]="operation"
            [canIEdit]="canIEdit"
            (completeEvent)="onTemplateComplete($event)">
  </task-action-schedule-wo>
</div>
