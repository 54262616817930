export interface IChargeBackItem {
  chargeBackItemId: number;   
  chargeBack_chargeBackId: number;
  chargeBackSku_chargeBackSkuId: number;
  name: string;
  chargeBackType_chargeBackTypeId: number;
  workOrder_workOrderId: number;
  units: number;
  unitPrice: number;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
}


export class ChargeBackItem implements IChargeBackItem {
  chargeBackItemId: number;
  chargeBack_chargeBackId: number;
  chargeBackSku_chargeBackSkuId: number;
  name: string;
  chargeBackType_chargeBackTypeId: number;
  workOrder_workOrderId: number;
  units: number;
  unitPrice: number;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  constructor() {
    this.chargeBackItemId = 0;
    this.chargeBack_chargeBackId = null;
    this.chargeBackSku_chargeBackSkuId = null;
    this.chargeBackType_chargeBackTypeId = null;
    this.workOrder_workOrderId = null;
    this.name = '';
    this.units = 0;
    this.unitPrice = 0;

    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';

  }

}

