import { Component, Input, Output,  EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fw-switch-button',
  templateUrl: './fw-switch-button.component.html'
})
export class SwitchButtonComponent implements OnInit  {
  @Input() defaultChecked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() title: string = '';

  @Output() public toggleEvent = new EventEmitter<boolean>();

  public checked$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ngOnInit(): void {
    this.checked$.next(this.defaultChecked);
  }

  public toggleSwitch(val) {
    this.checked$.next(val);
    this.toggleEvent.emit(val);
    }
}
