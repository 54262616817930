import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';
import { cloneDeep, clone } from 'lodash';

import { IDynamicListState, IListObjectData } from '../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { IInstallerWorkCrew, IProject } from '../../view-models';
import { IAppTab } from '../../../../fw/fw-shared/components/fw-app-tabs/interfaces';
import { IObjectData } from '../../../../fw/dynamic-list/store/interfaces';

import { IStoreState } from '../../../../shared/store/selectionLists';
import { ResetAllSelectionListStores, InitSelectItems } from '../../../../shared/store/selectionLists/selectionLists.actions';
import * as fromStore from '../../../../fw/dynamic-list/store/index'
import { InitializeObject, ResetAllObjectStores } from '../../../../fw/dynamic-list/store/actions/dynamic-object.actions';
import { InitializeList, ResetAllListStores } from '../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { getListByType } from '../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { ProjectService } from '../../project/services';
import { ProjectObjectStore, ProjectListStore, ProjectDependentSelectListStore } from '../../project/enums/project.enums';
import { ResetAllMetaStores, getObjectDataByType } from '../../../../fw/dynamic-list/store/index';
import { environment } from '../../../../environments/environment';
import { DispatchCalendarService } from '../../utilities/services/dispatch-calendar.service';
import { UserDashboardService } from '../../user-dashboard/services/user-dashboard.service';
import { AdminDashboardService } from '../../admin-dashboard/services/admin-dashboard.service';
import { WarrantyDashboardService } from '../../warranty-dashboard/services/warranty-dashboard.service';
import { TabsService } from '../../../../fw/fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';
import { TaskManagerService } from './task-manager.service';
import { InstallerStore } from '../../installer/enums/installer.enums';

@Injectable({
  providedIn: 'root'
})
export class StoreCleanUpService implements OnDestroy {
  subscription: Subscription = new Subscription();

  constructor(
    public store: Store<fromStore.IAllDynamicData>,
    public selStore: Store<IStoreState>,
    public projectService: ProjectService,
    public dispatchCalendarService: DispatchCalendarService,
    public userDashboardService: UserDashboardService,
    public adminDashboardService: AdminDashboardService,
    public tabsService: TabsService,
    public taskManagerService: TaskManagerService,
    public warrantyDashboardService: WarrantyDashboardService) { }

  cleanupEntity(entityType: string, entityId: number, tabs: IAppTab[]): void {
    switch (entityType.trim().toLowerCase()) {
      case 'project':
      case 'installer':
        this.cleanupEntityStores(entityType, entityId, tabs);
        break;
      case 'contact':
        this.cleanupContactStores(entityId);
        break;
      case 'compliance':
        this.cleanupComplianceDashboardStores();
        break;
      default:
        break;
    }
  }

  cleanupEntityStores(entityType: string, entityId: number, tabs: IAppTab[]): void {
    this.store.pipe(select(getObjectDataByType(entityType === 'project' ? ProjectObjectStore.projectInformation : InstallerStore.installerInformation)))
      .pipe(map((state) => state),
        take(1))
      .subscribe((store ) => {
        const record = store.objData.find(x => x.objectId === entityId);
        if (record) {
          const objData: IObjectData[] = clone(store.objData);
          if (entityType === 'project') {
            this.cleanupProject(entityId, objData, tabs);
          } else {
            if (record && record.data && record.data.contact_contactId) {
              this.cleanupInstallerStores(entityId, record.data.contact_contactId);
            }
          }
        }
      });
  }

  initStoreCleanup(tabs: IAppTab[], route: string): void {
    const tabType = route.split('/')[0];
    const typeTabs = tabs.filter(x => x.route.split('/')[0] === tabType);
    if (typeTabs.length > environment.entityTabLimit) {
      this.store.pipe(select(getObjectDataByType(tabType === 'project' ? ProjectObjectStore.projectInformation : InstallerStore.installerInformation)))
        .pipe(map((state) => state),
          filter((state) => state.objData && state.objData.length && state.objData.find(x => x.objectId.toString() === route.split('/')[2]) &&
            (tabType === 'project' && state.objData.find(x => x.objectId.toString() === route.split('/')[2]).data.isWarranty ?
              state.objData.find(x => x.objectId === state.objData.find(x => x.objectId.toString() === route.split('/')[2]).data.baseProject) :
              true)
          ),
          take(1))
        .subscribe((store) => {
          const data = cloneDeep(store.objData).sort(function (a, b) { return a.requestTime - b.requestTime });
          const oldestRecord = data[data[0].objectId.toString() !== route.split('/')[2] ? 0 : 1];
          if (oldestRecord) {
            if (tabType === 'project') {
              this.cleanupProject(oldestRecord.objectId, data, tabs);
            } else {
              this.cleanupInstallerStores(oldestRecord.objectId, oldestRecord.data.contact_contactId)
            }
          }
        });
    }
  }

  cleanupProject(projectId: number, data: IObjectData[], tabs: IAppTab[]): void {
    if (data && data.length) {
      const projObject: IObjectData = data.find(x => x.objectId === projectId);
      if (!projObject || !projObject.data) return;

      if (projObject.accessError) {
        this.store.dispatch(new InitializeObject({ storeName: ProjectObjectStore.projectInformation, objectId: projectId }));
        return;
      }
      const project: IProject = projObject.data;
      const baseProject: IObjectData = data.find(x => x.objectId === project.baseProject);
      let contactInfoCleared: boolean = false;
      const warrantyProject: IObjectData = data.find(x => x.data && x.data.baseProject && x.data.baseProject === projectId);
      const baseProjectTab: IAppTab = baseProject
        ? tabs.find(x => baseProject && baseProject.data && x.managerPortalId === 1 && x.portalEntityId === baseProject.data.projectId)
        : null;
        if (baseProject && !baseProjectTab) {
          this.clearProjectStores(baseProject.objectId, baseProject.data ? baseProject.data.customer_contactId : null);
          contactInfoCleared = true;
        }

      if (!warrantyProject) this.clearProjectStores(projectId, contactInfoCleared ? null : project.customer_contactId);
    }
  }

  clearWorkOrderInformation(projectId: number): void {
    if (!projectId) return;

    this.store.pipe(select(getObjectDataByType(ProjectObjectStore.workOrderInformation)))
      .pipe(map((state) => state),
        take(1))
      .subscribe((store) => {
        if (store.objData && store.objData.length > 0) {
          const workOrders = store.objData.filter(x => x.data !== null && x.data.projectId === projectId);
          let i: number = workOrders.length;
          while (i--) {
            this.store.dispatch(new InitializeObject({ storeName: ProjectObjectStore.workOrderInformation, objectId: workOrders[i].objectId }));
          }
        }
      });
  }

  clearProjectStores(projectId: number, contactId: number = null) {
    this.clearWorkOrderInformation(projectId);
    this.store.dispatch(new InitializeObject({ storeName: ProjectObjectStore.projectInformation, objectId: projectId }));
    this.store.dispatch(new InitializeObject({ storeName: ProjectObjectStore.warrantyInformation, objectId: projectId }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectPurchaseOrders, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectWorkOrders, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectChargeBacks, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectNotes, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectUnreceivedInventory, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectFiles, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectHistory, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectContactMechanisms, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectWorkOrderItems, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectPurchaseOrderItems, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectChargeBackItems, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectEntityDocuments, parentId: projectId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: ProjectListStore.projectAQTChanges, parentId: projectId, forOnDestroy: false }));
    
    //branchProgram and projectProviderLocation are now at branch level - do not init, allow reuse by other projects with same branch
    this.selStore.dispatch(new InitSelectItems({ storeName: ProjectDependentSelectListStore.workOrder, parentId: projectId }));
    this.selStore.dispatch(new InitSelectItems({ storeName: ProjectDependentSelectListStore.purchaseOrder, parentId: projectId }));
    this.taskManagerService.removeProjectData(projectId);
    if (contactId) {
      this.cleanupContactStores(contactId);
    }
  }

  cleanupContactStores(contactId: number) {
    this.store.dispatch(new InitializeList({ storeName: 'phones', parentId: contactId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'emails', parentId: contactId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'addresses', parentId: contactId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'contactCustomerFlags', parentId: contactId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'contactNotes', parentId: contactId, forOnDestroy: false }));
    this.store.dispatch(new InitializeObject({ storeName: 'contactInformation', objectId: contactId }));
  }

  cleanupInstallerStores(installerId: number, contactId: number) {
    this.store.dispatch(new InitializeList({ storeName: 'installerCertifications', parentId: installerId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'installerCertificationsByRange', parentId: installerId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'installerEligibility', parentId: installerId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'installerGcRequirements', parentId: installerId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'installerNotes', parentId: installerId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'installerScheduleExclusions', parentId: installerId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'installerTechnicians', parentId: installerId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'installerTechnicianCertifications', parentId: installerId, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'installerTechnicianGcRequirements', parentId: installerId, forOnDestroy: false }));
    this.subscription.add(this.store.pipe(select(getListByType('installerWorkCrews')))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == installerId)),
        take(1)) 
      .subscribe((state: IListObjectData) => {
        const stateObj = cloneDeep(state);
        if (stateObj) {
          const installerCrews: IInstallerWorkCrew[] = stateObj.data;
          installerCrews.forEach(x => {
            this.store.dispatch(new InitializeList({ storeName: 'workCrewEligibility', parentId: installerId, forOnDestroy: false }));
            this.store.dispatch(new InitializeList({ storeName: 'workCrewPlServices', parentId: installerId, forOnDestroy: false }));
            this.store.dispatch(new InitializeList({ storeName: 'workCrewScheduleExclusions', parentId: installerId, forOnDestroy: false }));
            this.store.dispatch(new InitializeList({ storeName: 'workCrewTechnicians', parentId: installerId, forOnDestroy: false }));
            this.store.dispatch(new InitializeList({ storeName: 'workCrewWorkCategories', parentId: installerId, forOnDestroy: false }));
            this.store.dispatch(new InitializeList({ storeName: 'workCrewZones', parentId: installerId, forOnDestroy: false }));
          });
          this.store.dispatch(new InitializeList({ storeName: 'installerWorkCrews', parentId: installerId, forOnDestroy: false }));
        }
      }));
    this.store.dispatch(new InitializeObject({ storeName: 'installerInformation', objectId: installerId }));
    this.cleanupContactStores(contactId);
  }

  cleanupComplianceDashboardStores() {
    this.store.dispatch(new InitializeList({ storeName: 'certifications', parentId: -1, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'generalContractorInstallerRequirements', parentId: -1, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'installers', parentId: -1, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'technicians', parentId: -1, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'installerOldestCertifications', parentId: -1, forOnDestroy: false }));
  }

  cleanupUserMenuStores() {
    this.store.dispatch(new InitializeList({ storeName: 'userDelegateSchedules', parentId: -1, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'userJobs', parentId: -1, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'userJobAlerts', parentId: -1, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'userJobSchedules', parentId: -1, forOnDestroy: false }));
    this.store.dispatch(new InitializeList({ storeName: 'userNotes', parentId: -1, forOnDestroy: false }));
  }

  cleanUpAllStores() {
    this.selStore.dispatch(new ResetAllSelectionListStores());
    this.store.dispatch(new ResetAllListStores());
    this.store.dispatch(new ResetAllObjectStores());
    this.store.dispatch(new ResetAllMetaStores());
    //clear out root level service saved filter info
    this.dispatchCalendarService.initSavedValues();
    this.userDashboardService.initSavedValues();
    this.adminDashboardService.initSavedValues();
    this.warrantyDashboardService.initSavedValues();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
