import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'warranty-sub-types',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './warranty-sub-types.component.html'
})
export class WarrantySubTypesComponent implements OnInit {

  public listDefinition: IListDefinition;

  constructor(
    public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService  ) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      const warrantyTypeId = +paramMap.get('id');
      this.newRequest(warrantyTypeId);
    });
  }

  newRequest(warrantyTypeId: number) {
    this.listDefinition = this.adminService.loadWarrantySubTypesListDefinition(warrantyTypeId);
  }

}
