import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {  Observable } from 'rxjs';
import {  map, filter} from 'rxjs/operators';

import { IErrorData } from '../../../../../../shared/interfaces/index';
import { UpdateObjectModel } from '../../../../../../auth/store/interfaces/index'; //store interfaces
import { IUserPreference } from '../../../../../../app/portals/view-models/index';

//store actions and reducers
import * as fromRoot from '../../../../../store/reducers/index';
import * as fromAuth from '../../../../../../auth/store/index';

@Component({
  selector: 'user-preferences-mgr',
  templateUrl: './user-preferences.component.html'
})
export class UserPreferencesContainerComponent implements OnInit {
  public userPreferences$: Observable<IUserPreference>;
  public errorData$: Observable<IErrorData[]>; 
  public loading$: Observable<boolean>;
  errorMessage: string = '';

  constructor(public rootStore: Store<fromRoot.IState>) {}

  public onSave(userPreference: IUserPreference) {
    this.errorMessage = null;
    const updateData = new UpdateObjectModel('UserPreference', 'Update', userPreference);
    this.rootStore.dispatch(new fromAuth.UpdateUserPreferences({ updateData }));
  }

  ngOnInit() {
     this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(
        filter(x => x.requestor === 'auth'),
        map(x => x.show));

     this.userPreferences$ = this.rootStore.pipe(
      select(fromAuth.getUserPreferences)
    );

    this.errorData$ = this.rootStore.pipe(
      select(fromAuth.getAuthErrorData)
    );
  }
}
