//Add on as we work with more of these on the ui
export enum FlexFieldDefinitionName {
  overrideReasonCode = 'Override Reason Code',
  leadDocumentStatus = 'Lead Document Status',
  externalStatus = 'External Status'
}

export enum LeadDocumentStatus {
  none = '',
  pending = 'pending',
  completed = 'completed',
  canceled = 'canceled'
}

export enum ExternalStatus {
  done = 'done',
  customerCancelled = 'Customer canceled',
  canceledDone = 'Canceled. Done'
}
