<div class="app-btn-box">
  <a *ngFor="let item of buttons;"
     [attr.href]="item.linkUrl || item.fileUrl || null"
     [attr.download]="item.isDownload ? item.hasOwnProperty('fileName') ? item['fileName'] : 'download' : null"
     [attr.target]="item.linkUrl && !item.isDownload ? '_blank' : null"
     [attr.disabled]="!item.enabled ? '' : null"
     class="app-btn-icon {{ item.cssName }}"
     [ngClass]="{'link-disabled': !item.enabled }">
    <i class="fas {{ item.icon }}" (click)="emitEvent(item)" title="{{ item.title }}"></i>
  </a>
  <ng-content></ng-content>
</div>
