import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DynamicListUserFilterComponent, DynamicListUserFiltersComponent } from './components';
import { EntityMetaDataResolver } from '../../app/portals/resolvers';
import { LoadingPageComponent } from '../../shared/components';

const portalRoutes: Routes = [
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'user-list-filter-create/:id',
    outlet: 'modal',
    data: {
      storeName: 'userListFilters',
      entityName: 'UserListFilter'
    },
    resolve: { hasMetaData: EntityMetaDataResolver },
    component: DynamicListUserFilterComponent
  },
  {
    path: 'user-list-filters/:id',
    outlet: 'modal',
    component: DynamicListUserFiltersComponent,
    children: [
      {
        path: 'user-list-filter-edit/:id',
        outlet: 'userFilterDetailOutlet',
        component: DynamicListUserFilterComponent
      }
    ]
  },
  {
    path: 'loading2/:container',
    outlet: 'modal2',
    component: LoadingPageComponent
  },
  {
    path: 'user-list-filter-create/:id',
    outlet: 'modal2',
    data: {
      storeName: 'userListFilters',
      entityName: 'UserListFilter'
    },
    resolve: { hasMetaData: EntityMetaDataResolver },
    component: DynamicListUserFilterComponent
  },
  {
    path: 'user-list-filters/:id',
    outlet: 'modal2',
    component: DynamicListUserFiltersComponent,
    children: [
      {
        path: 'user-list-filter-edit/:id',
        outlet: 'userFilterDetailOutlet',
        component: DynamicListUserFilterComponent
      }
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class DynamicListRoutingModule { }
