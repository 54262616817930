import { Directive, Input, HostListener, SimpleChanges, OnChanges, Self, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[asciiOnlyMask]',
})
export class AsciiOnlyDirective implements OnChanges {
    @Input() disableAsciiOnlyMask: boolean;

  constructor(@Optional() @Self() public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event);
  }

  onInputChange(event) {
    if (this.disableAsciiOnlyMask) return;
    let newVal = event.replace(/[^\x00-\x7F]/g, "");
    this.ngControl.valueAccessor.writeValue(newVal);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disableAsciiOnlyMask'] && !changes['disableAsciiOnlyMask'].isFirstChange()) {
      this.onInputChange(this.ngControl.value);
    }
  }

}
