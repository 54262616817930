<fw-component-title [title]="'Import a New Survey'"></fw-component-title>
<div [ngClass]="{ 'loading-mask': (working$ | async)}">
  <div class="customer-survey-import-box">
    <div class="float-label">
      <select #surveyType (change)="changeType(surveyType.value)" appFocus [autoFocus]="true">
        <option selected value="0">Choose...</option>
        <option *ngFor="let type of surveyTypes"
                [value]="type.surveyId">
          {{type.surveyName}}
        </option>
      </select>
      <label title="Survey Type">Survey Type</label>
    </div>
    <div class="customer-survey-import">
      <div class="customer-survey-import__name">{{ selectedFileName$ | async }}</div>
      <div class="customer-survey-import__btn">
        <label for="newSurvey" class="app-btn app-btn--primary"
               [ngClass]="{'customer-survey-import__disabled': !surveyType.value || surveyType.value <= 0 }">
          Select CSV File<i class="fa fa-cloud-upload customer-survey-import__icon"></i>
        </label>
        <input #newSurvey id="newSurvey" type="file" class="customer-survey-import__input"
               [disabled]="!surveyType.value || surveyType.value <= 0"
               accept=".csv"
               (change)="selectedFile(newSurvey.files[0])" />
      </div>
    </div>
  </div>
  <div *ngIf="(infoMessage$ | async) as infoMsg" class="alert-box alert-box--info ">
    {{ infoMsg }}
  </div>

  <div class="app-btn-box">
    <button type="button"
            class="app-btn app-btn--primary"
            [disabled]="!(canImport$ | async)"
            (click)="importFile()">
      Import
    </button>
    <button type="button" class="app-btn app-btn--cancel" (click)="onCancel()">Cancel</button>
  </div>
  <fw-error-box [errorData]="errorData$ | async" [errors]="errorMessage$ | async" [joinErrors]="false"></fw-error-box>
  <div class="customer-survey-import__info">
    <div *ngFor="let warning of (warnings$ | async)" class="alert-box alert-box--info ">
      {{ warning }}
    </div>
  </div>
</div>
