<div [ngClass]="{ 'loading-mask': loading$ | async }">
  <fw-button-title-bar [title]="title"
                       [entityLabel]="entityLabel"
                       [entityStatus]="entityStatus"
                       [canIEdit]="canIEdit"
                       [operation]="operation"
                       [disabled]="!form.dirty || !form.valid  || staleData"
                       [staleData]="staleData"
                       [showEdit]="canIEdit"
                       [showRefresh]="true"
                       [showDelete]="false"
                       [printUrl]="printUrl"
                       (customEvent)="onCustom($event)">
  </fw-button-title-bar>

  <form *ngIf="form" [formGroup]="form" novalidate class="form portal__form" [ngClass]="{'portal__form--edit': operation !== myConstants.operationTypeDetails}">
    <fw-dynamic-field [field]="mds.getFieldDefinition('workOrderId')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('providerLocation')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="locations">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('service')"
                      [form]="form"
                      [operation]="!(services$ | async) || !(services$ | async).length ? myConstants.operationTypeDetails : operation" 
                      [selectItems]="services$ | async">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('scheduleLocked')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('workOrderItemTotal')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>

  </form>
  <fw-error-box [errorData]="errorData" [onForm]="true" [ignoreKey]="false"></fw-error-box>
  <fw-component-footer [lastUpdate]="workOrder.updateDate"></fw-component-footer>
</div>
