import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FwModule } from '../../../fw/fw.module';
import { SharedModule } from '../../../shared/shared.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { PortalModule } from '../portal/portal.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { ContactModule } from '../../contact/contact.module';
import { ComplianceRoutingModule } from './compliance-routing.module';

//resolvers
import {
  ContactTypeSpecificationResolver,
  ContactEmailMetaDataResolver,
  ContactPhoneMetaDataResolver,
  ContactMetaDataResolver,
  ContactAddressMetaDataResolver
} from '../../contact/resolvers';

import {
  SelectionListResolver,
  AllMyProviderLocationsResolver,
  ProviderLocationServiceListResolver
} from '../resolvers/index';

//components
import { 
    CertificationDetailComponent, 
    PlsCertificationListComponent,
    GcInstallerRequirementDetailComponent
} from './components/index';

//guards
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';


@NgModule({
    declarations: [
      CertificationDetailComponent,
      PlsCertificationListComponent,
      GcInstallerRequirementDetailComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      FwModule,
      PortalSharedModule,
      SharedModule,
      FwSharedModule,
      ContactModule,
      ComplianceRoutingModule,
      PortalModule,
    ],
  providers: [SelectionListResolver,
    AllMyProviderLocationsResolver,
    ProviderLocationServiceListResolver,
    IsCurrentGuard,
    ContactTypeSpecificationResolver,
    ContactEmailMetaDataResolver,
    ContactPhoneMetaDataResolver,
    ContactAddressMetaDataResolver,
    ContactMetaDataResolver
  ],
    //exports: [RouterModule]
})

export class ComplianceModule {}
