<div *ngIf="form ">
  <form [formGroup]="form" novalidate class="form-wrapper">
    <fw-dynamic-field [field]="mds.getFieldDefinition('workOrderId')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <div class="float-label">
      <input type="text" placeholder="Service"disabled [value]="workOrder.workOrderServiceName">
      <label title="Service">Service</label>
    </div>
    <fw-dynamic-field [field]="mds.getFieldDefinition('workOrderScheduleText')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('workOrderInstallerName')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
  </form>
</div>
