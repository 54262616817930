<div [ngClass]="{ 'loading-mask': loading$ | async }">
    <div *ngIf="(objectData$ | async) && (formDefinition$ | async)">
        <fw-dynamic-form-custom
                         [data]="objectData$ | async"
                         [storeName]="storeName"
                         [formDefinition]="formDefinition$ | async"
                         [parentId]="parentId"
                         [displayFields]="displayFields"
                         [operation]="(operation$ | async) "
                         [errorMessage]="errorMessage"
                         [errorData]="errorData$ |  async"
                         [canIEdit]="canIEdit"
                         [showEdit]="showEdit"
                         [showDelete]="showDelete"
                         [showRefresh]="showRefresh"
                         [printUrl]="printUrl"
                         [customIcons]="customIcons" 
                         [staleData]="hasStaleData$ | async"
                         [formCssName]="formCssName"
                        (formEvent)="onFormEvent($event)">
        </fw-dynamic-form-custom>

    </div>
</div>
