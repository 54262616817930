/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, } from '../../../../../fw/dynamic-list/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';

import { UserDashboardService } from '../../services/user-dashboard.service';
import { UserDashEvent } from '../../enums/user-dashboard.enums';

@Component({
  selector: 'pending-closed-projects',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pending-closed-projects.component.html'
})
export class PendingClosedProjectsComponent implements OnInit {

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public userDashboardService: UserDashboardService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case UserDashEvent.peekWOSummary:
        this.userDashboardService.showMiniWOSummary(event.data);
        break;
      case UserDashEvent.peekPOSummary:
        this.userDashboardService.showMiniPOSummary(event.data);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.userDashboardService.loadPendingCloseProjectsListDefinition());
    });
  }
}
