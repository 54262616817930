import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import {  filter, take, map } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { HomDecimalPipe } from 'hom-lib/hom-pipes';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IValueChanged } from '../../../../../fw/dynamic-list/services';
import { IErrorData } from '../../../../../shared/interfaces';
import { PurchaseOrderItem, ISkuPrice, IUnitMeasure, SkuPrice, IEnum, PurchaseOrderInventoryAllocation, IPurchaseOrder } from '../../../view-models/index';
import { MetaDataService } from '../../../../../fw/dynamic-list/services/index'
import { HomCommonUtility } from '../../../../../shared/services/hom-common.utility';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../../store/reducers/index';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import {  UpdateObjectCustomModel, CreateObjectModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { ProjectListStore } from '../../enums/project.enums';


@Component({
  selector: 'purchase-order-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './purchase-order-item.component.html',
  providers: [MetaDataService]
})
export class PurchaseOrderItemComponent implements OnInit, OnDestroy  {
  public displayFields: string[] = ['itemName', 'itemType', 'quantity', 'unitMeasure', 'unitPrice', 'isAllocated', 'estimatedDeliveryDate', 'vendorPoNumber', 'manufacturerName'];
  public skus: ISkuPrice[] = [];
  public skuTypes: IEnum[] = [];
  public unitMeasures: IUnitMeasure[] = [];
  public isMaterial: boolean = false;
  public operation: string;
  public form: FormGroup;
  public showNext: boolean = false;
  public purchaseOrderItem: PurchaseOrderItem = null;
  public itemTotal$: BehaviorSubject<number> = new BehaviorSubject(0);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public skuPrices$: BehaviorSubject<ISkuPrice[]> = new BehaviorSubject(null);
  public purchaseOrder$: BehaviorSubject<IPurchaseOrder> = new BehaviorSubject(null);

  listStore: fromDynamicList.IListObjectData;
  projectId: number = -1;
  purchaseOrderId: number = -1;
  purchaseOrderItemId: number = -1;
  storeName: string = '';
  key: string = '';
  requestTime: string = '';
  controllerName: string = '';
  requestor: string;
  loaded: boolean = false;
  typeMaterials: string = 'materials';
  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public rootStore: Store<fromRoot.IState>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public mds: MetaDataService,
    public homDecimalPipe: HomDecimalPipe,
    public utils: HomCommonUtility,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {}

  public onCancel() {
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public onCreate() {
    const formData = this.form.getRawValue();
    let poi: PurchaseOrderItem = this.setCommonProperties(formData);
    poi.purchaseOrder_purchaseOrderId = this.purchaseOrderId;
    poi.createDate = new Date().toDateString();

    let poia: PurchaseOrderInventoryAllocation = new PurchaseOrderInventoryAllocation();
    poia.isAllocated = formData.hasOwnProperty('isAllocated') ? formData.isAllocated : true;
    poia.vendorPoNumber = formData.hasOwnProperty('vendorPoNumber') ? formData.vendorPoNumber : '';
    poia.manufacturerName = formData.hasOwnProperty('manufacturerName') ? formData.manufacturerName : '';
    poia.estimatedDeliveryDate = formData.hasOwnProperty('estimatedDeliveryDate') ? formData.estimatedDeliveryDate : '';
    const obj = {
      model: poi,
      purchaseOrderInventoryAllocation: poia
    };

    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, this.purchaseOrderId, this.controllerName, 'Create', obj, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }

  public onSave(action: string) {
    const formData = this.form.getRawValue();
    let poi: PurchaseOrderItem = this.setCommonProperties(formData);
    let poia: PurchaseOrderInventoryAllocation = new PurchaseOrderInventoryAllocation();
    poia.purchaseOrderInventoryAllocationId = this.purchaseOrderItem.purchaseOrderInventoryAllocationId;
    poia.isAllocated = formData.hasOwnProperty('isAllocated') ? formData.isAllocated : this.purchaseOrderItem.isAllocated;
    poia.estimatedDeliveryDate = formData.hasOwnProperty('estimatedDeliveryDate') ? formData.estimatedDeliveryDate : this.purchaseOrderItem.estimatedDeliveryDate;
    poia.vendorPoNumber = this.purchaseOrderItem.vendorPoNumber;
    poia.manufacturerName = this.purchaseOrderItem.manufacturerName;
    const obj = {
      model: poi,
      purchaseOrderInventoryAllocation: poia
    };

    const keyData: IKey = { storeName: this.storeName, parentId: this.purchaseOrderId, key: this.key, id: this.purchaseOrderItemId }
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventUpdate, action: action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', obj, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));
  }

  ngOnInit() {
    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.purchaseOrderId = +paramMap.get('id');
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.purchaseOrderItemId = +paramMap.get('id');
      this.projectId = +paramMap.get('portalEntityId');
      this.key = paramMap.get('key');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.showNext = paramMap.get('showNext') === 'true';
      this.purchaseOrderItem = null;

      this.mds.setFieldDefinitions(this.storeName);
      this.setDetailConfig(paramMap);
      this.getPurchaseOrder();
     this.newRequest();
    });

    this.subscription.add(this.purchaseOrder$.subscribe((val: IPurchaseOrder) => {
      if (val) {
        this.getSkuPrices(val.branchProgram_branchProgramId);
      }
    }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.purchaseOrderId;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.wrapsForm = true;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  newRequest(): void {
    this.subscription.add(this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null))
      .subscribe((obj: IValueChanged) => {
        this.handleValueChanged(obj);
      }));

    //For field level errors
    this.subscription.add(this.store.pipe(select(getListByType(this.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.purchaseOrderId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.errorData) {
          this.mds.setErrorMessages(state.errorData)
        }
      }));

    this.getDetail();
  }
  //parent id is purchaseOrderId for this store
  getDetail(): void {
    this.subscription.add(this.store.pipe(
      select(fromStore.getSelectedRecord(this.storeName, this.purchaseOrderId, this.key, this.purchaseOrderItemId)))
      .subscribe(entity => {
        const newRequest: boolean = !this.purchaseOrderItem;
        this.purchaseOrderItem = !entity && this.operation === this.myConstants.operationTypeCreate ? new PurchaseOrderItem() : entity;
        this.isMaterial = this.purchaseOrderItem.itemType.toLowerCase() === this.typeMaterials;
        if (newRequest) {
          this.mds.loadSelectionLists(this.displayFields, this.operation, this.purchaseOrderId);
          this.unitMeasures = this.mds.getSelectItems('unitMeasure');
          this.skuTypes = this.mds.getSelectItems('itemType');
          this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.purchaseOrderItem, this.myConstants.operationTypeCreate);
          this.calcTotal();
        }
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromStore.getSelectedParentListDefinition(this.storeName, this.purchaseOrderId)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.controllerName = listDefinition.controllerName;
        this.requestor = listDefinition.detailRoutePath;
      }
      ));
  }

  getPurchaseOrder(): void {
    this.subscription.add(this.store.pipe(
      select(fromStore.getSelectedRecord(ProjectListStore.projectPurchaseOrders, this.projectId, 'purchaseOrderId', this.purchaseOrderId)
        , take(1)))
      .subscribe(entity => {
        this.purchaseOrder$.next(entity);
      }
      ));
  }

  getSkuPrices(branchProgramId) {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('skuPrice', branchProgramId)))
      .subscribe((data: ISkuPrice[]) => {
        if (data) {
          this.skuPrices$.next(data);
        }
      }));
  }

  handleValueChanged(obj: IValueChanged) {
    switch (obj.key) {
      case 'itemName':
        if (this.utils.isObject(obj.value)) {
          const sku: ISkuPrice = obj.value;
          let priceCtrl = this.form.controls['unitPrice'];
          if (priceCtrl) {
            priceCtrl.setValue(sku.billingPrice);
          }
          let uomCtrl = this.form.controls['unitMeasure'];
          if (uomCtrl) {
            const uom = this.unitMeasures.find(x => x.unitMeasureId == sku.unitMeasureId);
            uomCtrl.setValue(uom);
          }
          let typeCtrl = this.form.controls['itemType'];
          if (typeCtrl) {
            const skuType = this.skuTypes.find(x => x.id == sku.skuTypeId);
            typeCtrl.setValue(skuType);
          }
        } 
        break;
      case 'quantity':
      case 'unitPrice':
        this.calcTotal();
        break;
      case 'itemType':
        const name: string = obj.value && obj.value.hasOwnProperty('name') ? obj.value['name'] : '';
        this.isMaterial =name.toLowerCase() === this.typeMaterials;
        break;
      default:
        break;
    }
  }

  calcTotal(): void {
    let quantity: number = this.form.controls['quantity'].value;
    let unitPrice: number = this.form.controls['unitPrice'].value;
    //if type 9 at end of 1.23, will come in this change event as 1.239, so need to call pipe first
    const qtyVal: number = !quantity ? 0 : parseFloat(this.homDecimalPipe.transform(quantity.toString()));
    const priceVal: number = !unitPrice ? 0 : parseFloat(this.homDecimalPipe.transform(unitPrice.toString()));
    this.itemTotal$.next(qtyVal * priceVal);
  }

  setCommonProperties(formData: PurchaseOrderItem): PurchaseOrderItem {
    let poi = { ...this.purchaseOrderItem };
    if (this.utils.isObject(formData.itemName)) {
      const item: any = formData.itemName;
      const sku: ISkuPrice = item as SkuPrice;
      poi.itemName = sku.skuName;
      poi.sku_skuId = sku.hasOwnProperty('skuId') ? sku['skuId'] : sku.sku_skuId;
      poi.skuDeck_skuDeckId = sku.skuDeckId;
    } else {
      poi.itemName =  formData.itemName;
      poi.sku_skuId =  null;
      poi.skuDeck_skuDeckId = null;
    }

    poi.itemType = formData.hasOwnProperty('itemType') ? formData['itemType']['name'] : null;
    poi.unitMeasure_unitMeasureId = formData.hasOwnProperty('unitMeasure') ? formData['unitMeasure']['unitMeasureId'] : null;
    const qtyStr = formData.quantity.toString();
    const priceStr = formData.unitPrice.toString();
    poi.quantity = !qtyStr ? 0 : parseFloat(this.homDecimalPipe.transform(qtyStr.toString()));
    poi.unitPrice = !priceStr ? 0 : parseFloat(this.homDecimalPipe.transform(priceStr.toString()));

    poi.updateDate = new Date().toDateString();

    return poi;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
