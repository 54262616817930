import { Injectable } from '@angular/core';
import { UserPriviledgesService } from '../../auth/services';
import { MenuService } from '../../fw/fw-menus/services/menu.service';
import { DuplicateInstanceService } from './duplicate-instance.service';
import { RouteToCrmService } from '../../app/route-to-crm/route-to-crm.service';

@Injectable()
export class HomLocalStorageChangeService {

  constructor(
    public window: Window,
    public userPriviledgesService: UserPriviledgesService,
    public menuService: MenuService,
    public duplicateInstanceService: DuplicateInstanceService,
    public routeToCrmService: RouteToCrmService,
  ) { }

  public init(): void {
    // PERFORM ACTIONS WHEN LOCAL STORAGE CHANGES
    this.window.onstorage = () => {
      // REDIRECT USER TO LOGIN WHEN CLEARING CACHE
      if (this.userPriviledgesService.authenticated$.value && !localStorage.length) {
        alert('Authentication lost. You will be redirected to signin.');
        this.duplicateInstanceService.init(); 
        this.menuService.logout();
        return;
      }
      // INIT LINK TO CRM ROUTES
      this.routeToCrmService.initRouteToCrm();
    }
  }

}
