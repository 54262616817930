/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit,ChangeDetectionStrategy } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { WarrantyDashboardService } from '../../services/warranty-dashboard.service';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { WarrantyDashEvent } from '../../enums/warranty-dashboard.enums';

@Component({
  selector: '/open-warranties',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './open-warranties.component.html'
})
export class OpenWarrantiesComponent implements OnInit {
  public listDefinition: IListDefinition;

  constructor(public  warrantyDashboardService: WarrantyDashboardService) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case WarrantyDashEvent.peekWOSummary:
        this.warrantyDashboardService.showMiniWOSummary(event.data);
        break;
      case WarrantyDashEvent.peekPOSummary:
        this.warrantyDashboardService.showMiniPOSummary(event.data);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.listDefinition = this.warrantyDashboardService.loadOpenWarrantiesListDefinition();
  }

  //LEAVE IN, not currently being used
  //onListFilter(event: IHomEventEmitter) {
  //  const searchChange: ISearchTermChange = {
  //    listName: UserDashType.openProject,
  //    searchTerms: event.data
  //  }
  //  this.userDashboardService.searchTermsChanged$.next(searchChange);
  //}

}


