  <div [ngClass]="{ 'loading-mask': operation === myConstants.operationTypeCreate && loading$ | async }">
  <ng-container *ngIf="invalidSetup">
    <div class="app-info">The requested store '{{ storeName }}' was not configured to use saved filters.</div>
  </ng-container>
  <ng-container *ngIf="!invalidSetup">
    <fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
      <!--<div class="user-filter-date-item__filter-description">{{ filterDescription$ | async }}</div>-->
      <form *ngIf="form" [formGroup]="form" class="form-wrapper form-wrapper--{{ operation }}">
        <fw-dynamic-field [field]="mds.getFieldDefinition('filterName')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-check-box #checkBox [config]="cbConfig"
                      [isChecked]="(objectData.isDefault)"
                      (change)="changeIsDefault(checkBox.value)">
        </fw-check-box>
        <ng-container *ngIf="dateTerms && dateTerms.length > 0">
          <!--<fw-info class="user-filter-dates__info" [infoTitle]="'Extended Date Options'">
        <div class="user-filter-dates__info--text">
          For date searches, you have the option to have the date auto-calculated for you.
          If you want to start the day by seeing all items for Today, then select blah...
          If you always want to see today plus 5 days in the future, then select blah..
        </div>
      </fw-info>-->
          <fw-section-title title="Manage Extended Options for Date Searches" [underline]="true"></fw-section-title>
          <div class="user-filter-dates">
            <div class="user-filter-dates__hdr">
              <div>Column</div>
              <div>Search</div>
              <div>Options</div>
            </div>
            <div class="user-filter-dates__item" *ngFor="let filter of dateTerms; index as i">
              <div class="user-filter-dates__item__term-name">{{ filter.termPosition === 0 ? filter.formattedTermName : '' }}</div>
              <div class="user-filter-dates__item__type">{{ filter.termPosition === 0 ? utils.splitWordsByCase(filter.searchType) : 'To' }}</div>
              <div class="user-filter-dates__item__dates">
                <fw-radio-button class="dynamic-list__item__actions--radio"
                                 [groupName]="userFilterConstants.changeTypeDate.concat(filter.termName).concat(i).toString()"
                                 [buttons]="dateTypeBtns[i]"
                                 [isDisabled]="false"
                                 [isHorizontal]="false"
                                 (change)="dateChange(userFilterConstants.changeTypeDate, $event.srcElement.value, filter)">
                </fw-radio-button>
              </div>
              <div class="user-filter-dates__item__sign">
                <fw-radio-button class="dynamic-list__item__actions--radio"
                                 [groupName]="userFilterConstants.changeTypeSign.concat(filter.termName).concat(i).toString()"
                                 [buttons]="dateSignBtns[i]"
                                 [isDisabled]="filter.dateType === userFilterConstants.fixedDateType"
                                 [isHorizontal]="false"
                                 (change)="dateChange(userFilterConstants.changeTypeSign, $event.srcElement.value, filter)">
                </fw-radio-button>
              </div>
              <div class="user-filter-dates__item__days">
                <input type="number" size="3" min="0"
                       digitOnlyMask
                       class="small-input"
                       [value]="filter.dateOffset"
                       [pattern]="'^(0|[1-9][0-9]{0,9})$'"
                       [disabled]="filter.dateType === userFilterConstants.fixedDateType"
                       (input)="dateChange(userFilterConstants.changeTypeOffset, $event.srcElement.value, filter)" /> days.
              </div>
              <div class="user-filter-dates__item__summ"
                   *ngIf="!(filter.searchType === 'Range' && filter.termPosition === 0)">{{ filter.description$ | async }}</div>
            </div>
          </div>
        </ng-container>
        <div class="float-label">
          <textarea rows="5" columns="40" disabled placeholder="Description">{{ filterDescription$ | async }}</textarea>
          <label>Description</label>
        </div>
      </form>
      <fw-error-box [errors]="errors$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
      <fw-crud-buttons [operation]="operation"
                       [disabled]="!isValid() || (loading$ | async) "
                       [showNext]="false"
                       (cancelEvent)="onCancel()"
                       (createEvent)="createRecord()"
                       (saveEvent)="updateRecord()">
      </fw-crud-buttons>
    </fw-dynamic-detail-container>
  </ng-container>
</div>
