
export interface ICommunicationDocumentType {
  communicationDocumentTypeId: number;
  provider_providerId: number;
  communicationShortcutCategory_communicationShortcutCategoryId: number;
  contactType_contactTypeId: number;
  documentTypeName: string;
  documentTypeDescription: string;
  requiresProject: boolean;
  requiresWorkOrder: boolean;
  sortOrder: number;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
}

export class CommunicationDocumentType implements ICommunicationDocumentType {
  communicationDocumentTypeId: number;
  provider_providerId: number;
  communicationShortcutCategory_communicationShortcutCategoryId: number;
  contactType_contactTypeId: number;
  documentTypeName: string;
  documentTypeDescription: string;
  requiresProject: boolean;
  requiresWorkOrder: boolean;
  sortOrder: number;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  constructor() {
    this.communicationDocumentTypeId = 0;
    this.provider_providerId = null;
    this.communicationShortcutCategory_communicationShortcutCategoryId = null;
    this.contactType_contactTypeId = null;
    this.documentTypeName = '';
    this.documentTypeDescription = '';
    this.requiresProject = false;
    this.requiresWorkOrder = false;
    this.sortOrder = 0;
    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';
  }
}

export interface ICommunicationDocumentTypeViewModel {
  communicationDocumentTypeId: number;
  projectId: number;
  workOrderId: number;
}
