<div [ngClass]="{'app-btn-box': wrap}">
  <ng-container *ngIf="operation !== myConstants.operationTypeDetails">
    <button type="button"
            class="app-btn-icon app-btn-icon--primary"
            (click)="crudEvent(cancelType)"
            title="Cancel">
      <i class="fas fa-ban"></i>
    </button>
    <button type="button"
            class="app-btn-icon app-btn-icon--success"
            (click)="crudEvent(saveType)"
            [disabled]="disabled"
            title="Save {{entityLabel}}">
      <i class="fas fa-save "></i>
    </button>
  </ng-container>

  <ng-container *ngIf="operation === myConstants.operationTypeDetails">
    <button *ngIf="showEdit" type="button"
            class="app-btn-icon app-btn-icon--warning"
            (click)="crudEvent(editType)"
            [disabled]="!canIEdit || staleData"
            title="Edit {{entityLabel}}">
      <i class="fas fa-edit "></i>
    </button>
    <!--only handles delete not delete confirm-->
    <button *ngIf="showDelete" class="app-btn-icon app-btn-icon__animated app-icon--danger " title="Delete" (click)="crudEvent(deleteType)">
      <i class="fas fa-trash"></i>
    </button>
    <button *ngIf="showRefresh" type="button"
            class="app-btn-icon app-btn-icon--primary"
            [ngClass]="{ 'app-btn-icon--highlight': staleData }"
            (click)="crudEvent(reloadType)"
            title="Refresh Results">
      <i class="fas fa-sync"></i>
    </button>

    <!-- PRINT ICON -->
    <button *ngIf="printUrl" type="button"
            class="app-btn-icon app-btn-icon--print"
            (click)="printDoc()"
            title="Print  {{entityLabel}}">
      <i class="fas fa-print"></i>
    </button>
  </ng-container>


</div>
