import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../../auth/services/auth-guard.service';
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';

import {
  WarrantyDashboardComponent,
  OpenWarrantiesComponent,
  UnassignedWarrantiesComponent
} from './containers/index';

import {
  WarrantyAssignUserComponent
} from './components/index';

import { PortalComponent } from '../portal/portal.component';

import {  EmptyComponent } from '../portal-shared/components/index';
import { LoadingPageComponent } from '../../../shared/components';

const portalRoutes: Routes = [
  {
    path: 'warranty-dash', //componentless route 
    children: [
      {
        path: ':portalId', //not really needed at this time, but keeping so tab factory can always call the same route structure
        component: WarrantyDashboardComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'warranty-dash-empty', //verify path, result of tab component concatenation
            outlet: 'portal-detail',
            component: EmptyComponent   
          },
          {
            path: 'loading/:container',
            outlet: 'portal-detail',
            component: LoadingPageComponent
          },
          {
            path: 'unassigned-warranties',
            outlet: 'portal-detail',
            component: UnassignedWarrantiesComponent
          },
          {
            path: 'open-warranties',
            outlet: 'portal-detail',
            component: OpenWarrantiesComponent
          }
        ]
      }
    ]
  },
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'user-dash-warranty-assign-user/:id',
    outlet: 'modal',
    component: WarrantyAssignUserComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class WarrantyDashboardRoutingModule { }
