import { Component, OnInit, OnDestroy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { ContactCustomerFlag, IContactCustomerFlag } from '../../../view-models/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { UpdateObjectCustomModel, IKey, CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';

//store actions, reducers, interfaces
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  selector: 'contact-customer-flag-detail',
  templateUrl: './contact-customer-flag-detail.component.html'
})
export class ContactCustomerFlagDetailComponent implements OnInit, OnDestroy  {
  public objectData: IContactCustomerFlag;
  public displayFields = ['isActive', 'customerFlag', 'flagReasonText'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  parentId: number = -1;  
  myPathName: string = '';
  myStoreName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string;
  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {

      this.subscription.add(emitterService.detailEventEmitted$
        .subscribe((e: IHomEventEmitter) => {
          switch (e.event) {
            case this.myConstants.emitterEventUpdate:
              this.updateRecord(e);
              break;
            case this.myConstants.emitterEventCreate:
              this.createRecord(e);
              break;
            default:
              break;
          }
        }));
      }

    /*
        Component Initialization
        Go get the meta data for this object.
        Common component for installer certifcations, installer gc requirements, tech certifications and tech gc requirements
        Updates to distinct stores.  parent id will be installer id for installer records, tech id for tech records.
    */
  ngOnInit() {

    this.subscription.add(this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.parentId = +paramMap.get('id');
    }));

    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');

      if (this.operation === this.myConstants.operationTypeCreate) {
        this.displayFields = ['isActive', 'customerFlag', 'flagReasonText'];
      } else {
        this.displayFields = ['isActive', 'customerFlagName', 'flagReasonText'];
      }
      this.setDetailConfig(paramMap);
      this.getDetail();
    }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.parentId;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.myStoreName, this.parentId, this.key, this.objectId)))
      .subscribe(entity => {
        this.objectData = !entity  && this.operation === this.myConstants.operationTypeCreate ? new ContactCustomerFlag() : entity;
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.myStoreName, this.parentId)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.myPathName = listDefinition.detailRoutePath;
        this.controllerName = listDefinition.controllerName;
      }
    ));

  }


  /*
      Insert the new record
  */
  createRecord(e: IHomEventEmitter) {
    let rec: IContactCustomerFlag = this.setCommonProperties(e.data);
    const formData: IContactCustomerFlag = e.data;
    rec.createDate = new Date().toDateString();
    rec.customerFlag_customerFlagId = formData['customerFlag']['customerFlagId'];
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.myStoreName, this.parentId, this.controllerName, 'Create', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

    /*
        Update the record:  since the data contains both existing and missing certifications, update needs to determine if create or update request
          based on value in installerCertificationId
    */
    updateRecord(e: IHomEventEmitter) {
      let rec: IContactCustomerFlag = this.setCommonProperties(e.data);

      rec.contactCustomerFlagId = this.objectId;
      rec.customerFlag_customerFlagId = this.objectData.customerFlag_customerFlagId;
      const keyData: IKey = { storeName: this.myStoreName, parentId: this.parentId, key: this.key, id: this.objectId }
      const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
      const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', rec, null, emitter);
      this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
    }

    /*
        Set common create/update properties
    */
  setCommonProperties(formData: IContactCustomerFlag): IContactCustomerFlag {

    let rec = new ContactCustomerFlag();
    rec.contact_contactId = this.parentId;
    rec.flagReasonText = formData.flagReasonText;
    rec.isActive = formData.isActive;

    rec.updateDate = new Date().toDateString();
    return rec;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
