import { SelectionListsActions, SelectionListActionTypes } from './selectionLists.actions';
import { IErrorData} from '../../interfaces/index';
import { cloneDeep } from 'lodash';

export interface ISelectionList {
  storeName: string;
  objData: ISelectListData[];
}

class SelectionListState implements ISelectionList {
  storeName: string;
  objData: ISelectListData[];
  keepOne: boolean;

  constructor(storeName: string, keepOne: boolean = false) {
    this.storeName = storeName;
    this.objData = null;
    this.keepOne = keepOne;
  }
}

//keep storenames SINGULAR 
export interface IStoreState {
  accountingInterface: ISelectionList;
  allInstaller: ISelectionList;
  allService: ISelectionList;
  allProgram: ISelectionList;
  allProviderLocation: ISelectionList;
  branch: ISelectionList;
  branchProgram: ISelectionList;
  businessType: ISelectionList;
  callLogResponse: ISelectionList;
  certificationLevel: ISelectionList;
  certificationType: ISelectionList;
  chargeBackSkuPrice: ISelectionList;
  chargeBackStatus: ISelectionList;
  chargeBackType: ISelectionList;
  communicationDocumentType: ISelectionList;
  communicationEventLogEntities: ISelectionList;
  communicationEventType: ISelectionList;
  communicationPreference: ISelectionList;
  communicationShortcut: ISelectionList;
  communicationShortcutCategory: ISelectionList;
  contactMechanismCategoryType: ISelectionList;
  contactType: ISelectionList;
  contactTypeEntities: ISelectionList;
  contactTypeSpecification: ISelectionList;
  customerFlag: ISelectionList;
  dailyFrequencyUnit: ISelectionList;
  dispatchWorkOrderStatus: ISelectionList;
  dispatchService: ISelectionList; //cache one - varies by my read-only or edit services
  dispatchProviderLocation: ISelectionList; //cache one - varies by my read-only or edit provider locations
  docMgtEntities: ISelectionList;
  eventAction: ISelectionList;
  //entity: ISelectionList;
  documentType: ISelectionList;
  fileVMEntities: ISelectionList;
  generalContractor: ISelectionList;
  historyEntity: ISelectionList;
  historyEntityType: ISelectionList;
  homeDepotLswpOverrideReasonCode: ISelectionList;
  installer: ISelectionList;
  installerRole: ISelectionList;
  installerWorkCrewTechnician: ISelectionList;
  itemType: ISelectionList;
  job: ISelectionList;
  jobFrequencyType: ISelectionList;
  jobParameter: ISelectionList;
  jobSchedule: ISelectionList;
  jobScheduleJob: ISelectionList;
  jobScheduleType: ISelectionList;
  jobScheduleJobSubscriptionType: ISelectionList;
  jobScheduleRecurrenceType: ISelectionList;
  ldapUser: ISelectionList;
  lienWaiver: ISelectionList;
  materialPayout: ISelectionList;
  measureService: ISelectionList;
  myBranch: ISelectionList; //cache one - varies by my full access, only needed for dashboard level filter
  nameSuffix: ISelectionList;
  orderStatus: ISelectionList;
  overridePrivilegeId: ISelectionList;
  payout: ISelectionList;
  payoutPrice: ISelectionList;
  payoutStatus: ISelectionList;
  parameterType: ISelectionList;
  poImportStatus: ISelectionList;
  poNumberFormatType: ISelectionList;
  poNumberPrefixType: ISelectionList;
  portalTab: ISelectionList;
  position: ISelectionList;
  privilegeId: ISelectionList;
  privilegeAccessLevel: ISelectionList;
  privilegeRuleId: ISelectionList;
  privilegeRuleEntitySpecifier: ISelectionList;
  privilegeType: ISelectionList;
  program: ISelectionList;
  programService: ISelectionList;
  projectChildren: ISelectionList;
  projectEntity: ISelectionList;
  projectProviderLocation: ISelectionList; //no cache - varies by project id - generate work orders
  projectStatus: ISelectionList;
  provider: ISelectionList;
  providerUser: ISelectionList;
  providerLocation: ISelectionList; //cache one - varies by my full access
  providerLocationBranch: ISelectionList; 
  providerLocationService: ISelectionList;
  purchaseOrder: ISelectionList;
  region: ISelectionList;
  regionType: ISelectionList;
  requirementLevel: ISelectionList;
  service: ISelectionList;
  shipper: ISelectionList;
  shipperDate: ISelectionList;
  shipperPayout: ISelectionList;
  shipperProviderLocation: ISelectionList;
  sku: ISelectionList;
  skuDeck: ISelectionList;
  skuPrice: ISelectionList;
  skuStatus: ISelectionList;
  skuType: ISelectionList;
  sssBranchProgram: ISelectionList;
  sssGeneralContractor: ISelectionList;
  sssGcProgram: ISelectionList;
  sssProgramService: ISelectionList;
  state: ISelectionList;
  startTab: ISelectionList;
  status: ISelectionList; //chargeBackSku Status
  survey: ISelectionList,
  surveyQuestion: ISelectionList;
  surveyQuestionDataType: ISelectionList,
  surveyQuestionChoice: ISelectionList,
  surveyWorkOrder: ISelectionList,
  unitMeasure: ISelectionList;
  warrantyOwner: ISelectionList;
  warrantyType: ISelectionList;
  warrantySubType: ISelectionList;
  workOrder: ISelectionList;
  workCategory: ISelectionList;
  workOrderStatus: ISelectionList;
  workOrderSelfServiceEligibility: ISelectionList;
  zone: ISelectionList;
}

const initialState: IStoreState = {
  accountingInterface: new SelectionListState('accountingInterface'),
  allInstaller: new SelectionListState('allInstaller'),
  allService: new SelectionListState('allService'),
  allProgram: new SelectionListState('allProgram'),
  allProviderLocation: new SelectionListState('allProviderLocation'),
  branch: new SelectionListState('branch'),
  branchProgram: new SelectionListState('branchProgram'),
  businessType: new SelectionListState('businessType'),
  callLogResponse: new SelectionListState('callLogResponse'), 
  chargeBackSkuPrice: new SelectionListState('chargeBackSkuPrice'),
  chargeBackStatus: new SelectionListState('chargeBackStatus'),
  chargeBackType: new SelectionListState('chargeBackType'),
  certificationLevel: new SelectionListState('certificationLevel'),
  certificationType: new SelectionListState('certificationType'),
  communicationDocumentType: new SelectionListState('communicationDocumentType'),
  communicationEventLogEntities: new SelectionListState('communicationEventLogEntities'),
  communicationEventType: new SelectionListState('communicationEventType'),
  communicationPreference: new SelectionListState('communicationPreference'),
  communicationShortcut: new SelectionListState('communicationShortcut'),
  communicationShortcutCategory: new SelectionListState('communicationShortcutCategory'),
  contactMechanismCategoryType: new SelectionListState('contactMechanismCategoryType'),
  contactType: new SelectionListState('contactType'),
  contactTypeEntities: new SelectionListState('contactTypeEntities'),
  contactTypeSpecification: new SelectionListState('contactTypeSpecification'),  
  customerFlag: new SelectionListState('customerFlag'),
  dailyFrequencyUnit: new SelectionListState('dailyFrequencyUnit'),
  dispatchWorkOrderStatus: new SelectionListState('dispatchWorkOrderStatus'),
  dispatchService: new SelectionListState('dispatchService'),
  dispatchProviderLocation: new SelectionListState('dispatchProviderLocation'),
  docMgtEntities: new SelectionListState('docMgtEntities'),
  eventAction: new SelectionListState('eventAction'),
  //entity: new SelectionListState('entity'),
  documentType: new SelectionListState('documentType'),
  fileVMEntities: new SelectionListState('fileVMEntities'),
  generalContractor: new SelectionListState('generalContractor'),
  historyEntity: new SelectionListState('historyEntity'),
  historyEntityType: new SelectionListState('historyEntityType'),
  homeDepotLswpOverrideReasonCode: new SelectionListState('homeDepotLswpOverrideReasonCode'),
  installer: new SelectionListState('installer'),
  installerRole: new SelectionListState('installerRole'),
  installerWorkCrewTechnician: new SelectionListState('installerWorkCrewTechnician'),
  itemType: new SelectionListState('itemType'),
  job: new SelectionListState('job'),
  jobFrequencyType: new SelectionListState('jobFrequencyType'),
  jobParameter: new SelectionListState('jobParameter'),
  jobScheduleType: new SelectionListState('jobScheduleType'),
  jobSchedule: new SelectionListState('jobSchedule'),
  jobScheduleJob: new SelectionListState('jobScheduleJob'),
  jobScheduleJobSubscriptionType: new SelectionListState('jobScheduleJobSubscriptionType'),
  jobScheduleRecurrenceType: new SelectionListState('jobScheduleRecurrenceType'),
  ldapUser: new SelectionListState('ldapUser'),
  lienWaiver: new SelectionListState('lienWaiver'),
  materialPayout: new SelectionListState('materialPayout'),
  measureService: new SelectionListState('measureService'),
  myBranch: new SelectionListState('myBranch'),
  nameSuffix: new SelectionListState('nameSuffix'),
  orderStatus: new SelectionListState('orderStatus'),
  overridePrivilegeId: new SelectionListState('overridePrivilegeId'),
  parameterType: new SelectionListState('parameterType'),
  payout: new SelectionListState('payout'),
  payoutPrice: new SelectionListState('payoutPrice', true), //payout on wo item uses this
  payoutStatus: new SelectionListState('payoutStatus'), 
  poImportStatus: new SelectionListState('poImportStatus'),
  poNumberFormatType: new SelectionListState('poNumberFormatType'),
  poNumberPrefixType: new SelectionListState('poNumberPrefixType'),
  portalTab: new SelectionListState('portalTab'),
  position: new SelectionListState('position'),
  privilegeId: new SelectionListState('privilegeId'),
  privilegeAccessLevel: new SelectionListState('privilegeAccessLevel'),
  privilegeRuleId: new SelectionListState('privilegeRuleId'), //yes Id - it is really an enum
  privilegeRuleEntitySpecifier: new SelectionListState('privilegeRuleEntitySpecifier'),
  privilegeType: new SelectionListState('privilegeType'),
  program: new SelectionListState('program'),
  programService: new SelectionListState('programService'),
  projectChildren: new SelectionListState('projectChildren'),
  projectEntity: new SelectionListState('projectEntity'),
  projectProviderLocation: new SelectionListState('projectProviderLocation'),
  projectStatus: new SelectionListState('projectStatus'),
  provider: new SelectionListState('provider'),
  providerUser: new SelectionListState('providerUser'),
  providerLocation: new SelectionListState('providerLocation'),
  providerLocationBranch: new SelectionListState('providerLocationBranch'),
  providerLocationService: new SelectionListState('providerLocationService'),
  purchaseOrder: new SelectionListState('purchaseOrder'),
  region: new SelectionListState('region'),
  regionType: new SelectionListState('regionType'),
  requirementLevel: new SelectionListState('requirementLevel'),
  service: new SelectionListState('service'),
  shipper: new SelectionListState('shipper'),
  shipperDate: new SelectionListState('shipperDate'),
  shipperPayout: new SelectionListState('shipperPayout'),
  shipperProviderLocation: new SelectionListState('shipperProviderLocation'),
  sku: new SelectionListState('sku'),
  skuDeck: new SelectionListState('skuDeck'),
  skuPrice: new SelectionListState('skuPrice'),
  skuStatus: new SelectionListState('skuStatus'),
  skuType: new SelectionListState('skuType'),
  sssBranchProgram: new SelectionListState('sssBranchProgram'),
  sssGeneralContractor: new SelectionListState('sssGeneralContractor'),
  sssGcProgram: new SelectionListState('sssGcProgram'),
  sssProgramService: new SelectionListState('sssProgramService'),
  state: new SelectionListState('state'),
  startTab: new SelectionListState('startTab'),
  status: new SelectionListState('status'),
  survey: new SelectionListState('survey'),
  surveyQuestion: new SelectionListState('surveyQuestion'),
  surveyQuestionDataType: new SelectionListState('surveyQuestionDataType'),
  surveyQuestionChoice: new SelectionListState('surveyQuestionChoice'),
  surveyWorkOrder: new SelectionListState('surveyWorkOrder'),
  unitMeasure: new SelectionListState('unitMeasure'),
  warrantyOwner: new SelectionListState('warrantyOwner'),
  warrantyType: new SelectionListState('warrantyType'),
  warrantySubType: new SelectionListState('warrantySubType'),
  workCategory: new SelectionListState('workCategory'),
  workOrder: new SelectionListState('workOrder'),
  workOrderSelfServiceEligibility: new SelectionListState('workOrderSelfServiceEligibility'),
  workOrderStatus: new SelectionListState('workOrderStatus'),
  zone: new SelectionListState('zone'),
};

export function selListReducer(state = initialState, action: SelectionListsActions): IStoreState {
  switch (action.type) {

    case SelectionListActionTypes.SET_ENTITY_LIST:
      {
        const storeName = action.payload.storeName;
        const useParentId = action.payload.parentId
        let storeData = cloneDeep(state[storeName]);
        const keepOne = storeData.keepOne;
        //If keepOne, always retain just the recent parentId in the array.
        if (!storeData.objData || keepOne) {
          storeData.objData = [];
        }
        const index = storeData.objData.length === 0 ? -1 : storeData.objData.findIndex(x => x.parentId == useParentId);
        if (index === -1) {
          //add a new objData object to the array for this parent id--not pushing to the store, just to a local property
          storeData.objData.push({
            parentId: useParentId,
            data: action.payload.data,
            metaData: action.payload.listMetaData ? action.payload.listMetaData : null
          });
        } else {
          storeData.objData[index].data = action.payload.data;
          storeData.objData[index].metaData = action.payload.listMetaData ? action.payload.listMetaData : null
        }

        const updatedData = {
          storeName: action.payload.storeName,
          objData: [...storeData.objData],
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
    }

     
    case SelectionListActionTypes.INIT_SELECT_ITEMS:
      {
        const storeName = action.payload.storeName;
        let index = -1;
        let selStore = cloneDeep(state[storeName]);
        if (action.payload.parentId > 0) {
          index = selStore && selStore.objData
            ? selStore.objData.findIndex(x => x.parentId == action.payload.parentId)
            : -1;
          if (index === -1) {
            return state;
          }
        }

        //console.log('INIT SELECT LIST ITEMS for store:', storeName, ' with what index', index, 'do I have a parentid', action.payload.parentId);
        //Either remove this parent dependent store or just init the entire store
        if (index >= 0 && selStore.objData) {
          const result = selStore.objData.filter(x => x.parentId != action.payload.parentId);
        }

        let objData = index >= 0 && selStore.objData
          ? selStore.objData.filter(x => x.parentId != action.payload.parentId)
          : null;

        const updatedData = {
          storeName: action.payload.storeName,
          objData: objData
        }

      return {
        ...state,
        [`${storeName}`]: { ...state[storeName], ...updatedData }
      }
    }

    case SelectionListActionTypes.RESET_ALL_SELECTION_LIST_STORES:
      {
        return initialState
      }


    default:
      //Default is needed so on subscribe you can actually see the state (and always good to have a default in a switch)
      return state;
  }
}

export interface ISelectListData {
  parentId: number;
  data: any[];
  metaData: any;
  errorData: IErrorData[];
}

