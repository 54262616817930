import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';

import { ManagerDashboardService } from '../../services/manager-dashboard.service';

@Component({
  selector: 'inventory-orders',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './inventory-orders.component.html'
})
export class InventoryOrdersComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public managerDashboardService: ManagerDashboardService) { }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.managerDashboardService.loadDashInventoryOrdersListDefinition());
    });
  }
}
