import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IProviderLocationService, ProviderLocationService } from '../../../view-models';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { UpdateObjectCustomModel, IKey, CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';

//store actions, reducers, interfaces
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'provider-location-service',
  templateUrl: './provider-location-service.component.html'
})
export class ProviderLocationServiceComponent implements OnInit, OnDestroy  {
  public objectData: IProviderLocationService;
  public displayFields = [];

  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  parentId: number = 0;
  serviceId: number = 0;
  providerLocationid: number = 0;
  storeName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string;
  detailRoutePath: string = '';
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants  ) {

    this.subscription.add(emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case this.myConstants.emitterEventCreate:
            this.createRecord(e);
            break;
          default:
            break;
        }
      }));
    }

  ngOnInit() {
    this.subscription.add(this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.providerLocationid = +paramMap.get('plId');
      this.serviceId = +paramMap.get('serviceId');
      this.parentId = this.providerLocationid > 0 ? this.providerLocationid : this.serviceId;
      this.displayFields = [this.providerLocationid > 0 ? 'service' : 'providerLocation', 'accountingLocationId', 'slotBuffer', 'keyRecTime'];
    }));

    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');

      this.setDetailConfig(paramMap);
      this.getDetail();
    }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.parentId;
    params.showErrorBox = false;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.storeName, this.parentId, this.key, this.objectId)), take(1))
      .subscribe(entity => {
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate
          ? new ProviderLocationService() : entity;
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.storeName, this.parentId)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.detailRoutePath = listDefinition.detailRoutePath;
        this.controllerName = listDefinition.controllerName;
      }
    ));

  }

  createRecord(e: IHomEventEmitter) {
    let rec: IProviderLocationService = this.setCommonProperties(e.data);
    if (this.providerLocationid > 0) {
      rec.providerLocation_providerLocationId = this.parentId;
    } else {
      rec.service_serviceId = this.parentId;
    }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, this.parentId, this.controllerName, 'Create', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  updateRecord(e: IHomEventEmitter) {
    let rec: IProviderLocationService = this.setCommonProperties(e.data);

    const keyData: IKey = { storeName: this.storeName, parentId: this.parentId, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  setCommonProperties(formData: IProviderLocationService): IProviderLocationService {
    let rec = cloneDeep(this.objectData);
    //will come in with either a plId or a serviceId depending on parent list (ServiceLocations or PLServices)
    rec.providerLocation_providerLocationId = formData['providerLocation'] && formData['providerLocation'].hasOwnProperty('providerLocationId')
      ? formData['providerLocation']['providerLocationId']
      : rec.providerLocation_providerLocationId;
    rec.service_serviceId = formData['service'] && formData['service'].hasOwnProperty('serviceId')
      ? formData['service']['serviceId']
      : rec.service_serviceId;
    rec.accountingLocationId = formData.accountingLocationId
    rec.slotBuffer = formData.slotBuffer;
    rec.keyRecTime = formData.keyRecTime;
    return rec;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
