<div class="nested-panel" [ngClass]="{ 'loading-mask': working$ | async }">
    <fw-component-title [title]="title$ | async"></fw-component-title>
    <div *ngIf="(project$ | async) as project">
      <div class="app-info">
        <i class="fas fa-info-circle app-icon--primary"></i>
        Click to open the originating project <span class="app-link" (click)="openLink(project.projectId)">{{ project.projectId }}</span>
      </div>

      <div class="portal__detail--item">
        <fw-component-title title='Project Purchase Orders'></fw-component-title>
        <purchase-orders-summary [projectId]="project.projectId" [canIEdit]="false"></purchase-orders-summary>
      </div>

      <div class="portal__detail--item">
        <fw-component-title title='Project Work Orders'></fw-component-title>
        <work-orders-summary [projectId]="project.projectId" [canIEdit]="false"></work-orders-summary>
      </div>
      <project-notes [baseProjectId]="project.projectId" [canIEdit]="false"></project-notes>

      <ng-container *ngIf="formDefinition">
        <div class="app-btn-box">
          <button type="button" class="app-btn app-btn--cancel" (click)="onClose()">Close</button>
        </div>
      </ng-container>
    </div>
    <fw-error-box [errorData]="errorData$ | async" [errors]="errorMessage$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
  </div>
  <br />
