import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import {  SkuCreationWizardEvent, PricingStore, SkuCreationWizardAction, SkuCreationWizardOrigin } from '../../enums/pricing-shared.enums';
import { ISku, Sku, ISkuXmlMapping } from '../../../view-models';
import { IPricingCreateResult } from '../../interfaces/i-pricing-create-result';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { AdminStore } from '../../../admin/enums/admin.enums';

import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { listDataExists, getEntityListByParentId, IListObjectData, getSelectedRecord } from '../../../../../fw/dynamic-list/store/index';
import { AdminDashboardService } from '../../../admin-dashboard/services/admin-dashboard.service';
import { AdminPricingService } from '../../../admin/services/admin-pricing.service';
import { PricingSharedService } from '../../services/pricing-shared.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { AdminDashStore } from '../../../admin-dashboard/enums/admin-dashboard.enums';

@Component({
  selector: 'sku-creation-wizard',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sku-creation-wizard.component.html'
})
export class SkuCreationWizardComponent implements OnInit, OnDestroy {
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public stepName$: BehaviorSubject<string> = new BehaviorSubject('');
  public skuData$: BehaviorSubject<ISku> = new BehaviorSubject(null);
  public stepCreateSku: string = 'Create Sku';
  public stepCreateSkuPrice: string = 'Create Price for Sku';
  public stepAskPayoutType: string = 'Create New Payout or Link to Existing Payout?';
  public stepCreatePayout: string = 'Create Payout for Sku';
  public stepLinkPayout: string = 'Link Payout to Sku';
  public stepCreatePayoutPrice: string = 'Create Price for Payout';
  skuMappingId: number = 0;
  gcId: number = 1;
  skuId: number;
  payoutId: number;
  origin: string;
  skuName: string = '';
  payoutName: string = '';
  refreshNeeded: boolean = false;
  storeName: string;
  subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public router: Router,
    public adminDashboardService: AdminDashboardService,
    public adminPricingService: AdminPricingService,
    public pricingService: PricingSharedService,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

   public ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.origin = paramMap.get('origin');
      this.skuMappingId = this.origin === SkuCreationWizardOrigin.skuXmlMapping ? +paramMap.get('id') : 0;
      this.skuId = this.origin === SkuCreationWizardOrigin.skuPayout ? +paramMap.get('id') : 0;
      this.gcId = +paramMap.get('gcId');
      this.newRequest();
    });

    this.subscription.add(this.skuData$.pipe(
      filter((data) => data !== null), take(1))
      .subscribe((data: ISku) => {
        this.goToNextStep(this.origin === SkuCreationWizardOrigin.skuPayout ? this.stepCreatePayout : this.stepCreateSku);
    }));

    this.subscription.add(this.emitterService.skuWizardEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        this.handleEvent(e);
      }));
    }

    public cancel(): void {
      if (this.modalService.opened) {
        this.modalService.close();
      }
    }

  newRequest() {
    this.storeName = this.origin === SkuCreationWizardOrigin.skuXmlMapping ? AdminDashStore.dashSkuMapping
      : this.origin === SkuCreationWizardOrigin.skuPayout ? AdminStore.skuPayouts : AdminStore.skus;

    this.skuData$.next(null);
    this.stepName$.next(this.origin === SkuCreationWizardOrigin.skuPayout ? this.stepCreatePayout : this.stepCreateSku)
    if (this.origin === SkuCreationWizardOrigin.skuXmlMapping) {
      this.getSkuMappingData();
    } else if (this.origin === SkuCreationWizardOrigin.skuPayout) {
      this.getSkuData();
    }  else {
        this.skuData$.next(new Sku(this.gcId)); 
      }
    }

  //set list definitions if none found
    setPayoutListDefinition(): void {
      this.subscription.add(this.store.pipe(
        select(listDataExists(PricingStore.payouts, -1)), take(1))
        .pipe(filter((exists: boolean) => exists === false), take(1))
        .subscribe((exists) => {
          this.store.dispatch(new DynamicListActions.SetListDefinition({ storeName: PricingStore.payouts, parentId: -1, listDefinition: this.pricingService.loadPayoutsListDefinition() }));
        }));
    }

    getSkuMappingData(): void {
      const def: IListDefinition = this.adminDashboardService.loadSkuMappingListDefinition(this.gcId);
      this.subscription.add(this.store.pipe(
        select(getEntityListByParentId(def.storeName, def.parentId)), take(1))
        .subscribe(list => {
          const mapList: IListObjectData = cloneDeep(list);
          const skuMapping: ISkuXmlMapping = mapList.data ? mapList.data.find(x => x[def.rowKeyId] == this.skuMappingId) : null;
          this.skuData$.next(new Sku(skuMapping.generalContractor_generalContractorId, skuMapping.mappingText));
        })
      );
    }

  getSkuData(): void {
    const def: IListDefinition = this.adminPricingService.loadSkuListDefinition(this.gcId);
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(def.storeName, def.parentId, def.rowKeyId, this.skuId)))
      .subscribe(entity => {
        this.skuData$.next(cloneDeep(entity));
      this.skuName = this.skuData$.value ? this.skuData$.value.skuName : '';
      })
    );
  }

  goToNextStep(stepName: string) {
    const routeName: string = this.getRouteName(stepName);
    const params: IDetailRouteParameter = this.getOptionalParms(stepName);
    this.stepName$.next(stepName);
    this.working$.next(true);
    switch (stepName) {
      case this.stepCreateSku:
        this.router.navigate([{
          outlets: { wizard: [routeName, 0, this.gcId, this.skuMappingId, true, params] }
        }], { relativeTo: this.activeRoute, skipLocationChange: true }).then(success => {
          this.working$.next(false);
        });
        break;
      case this.stepLinkPayout:
        this.router.navigate([{
          outlets: { wizard: [routeName, this.skuId, true] }
        }], { relativeTo: this.activeRoute, skipLocationChange: true }).then(success => {
          this.working$.next(false);
        });
        break;
      case this.stepCreatePayout:
        this.setPayoutListDefinition();
        this.router.navigate([{
          outlets: { wizard: [routeName, 0, this.skuId, true, params] }
        }], { relativeTo: this.activeRoute, skipLocationChange: true }).then(success => {
          this.working$.next(false);
        });
        break;
      case this.stepCreatePayoutPrice:
        this.router.navigate([{
          outlets: { wizard: [routeName, this.payoutId, this.gcId, true ] }
        }], { relativeTo: this.activeRoute, skipLocationChange: true }).then(success => {
          this.working$.next(false);
        });
        break;
      default:
        this.router.navigate([{
          outlets: { wizard: [routeName, this.skuId, this.gcId, true] }
        }], { relativeTo: this.activeRoute, skipLocationChange: true }).then(success => {
          this.working$.next(false);
        });
        break;
    }
  }

  getRouteName(stepName: string): string {
    switch (stepName) {
      case this.stepCreateSku:
        return 'sku-create';
      case this.stepCreatePayout:
        return 'sku-payout-create';
      case this.stepLinkPayout:
        return 'sku-payout-link';
      case this.stepCreateSkuPrice:
        return 'sku-price-create';
      case this.stepCreatePayoutPrice:
        return 'payout-price-create';
      default:
        return '';
    }
  }

  getOptionalParms(stepName: string): IDetailRouteParameter {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: '',
      key: '',
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: -1
    }

    switch (stepName) {
      case this.stepCreateSku:
        params.storeName = this.storeName;
        params.key = this.skuMappingId > 0 ? 'skuXmlMappingId' : 'skuId';
        break;
      case this.stepCreatePayout:
        params.storeName = PricingStore.payouts;
        params.key = 'payoutId';
        break;
      case this.stepLinkPayout:
      case this.stepCreateSkuPrice:
      case this.stepCreatePayoutPrice:
        return null; 
      default:
        return null; 
    }

    return params;
}


  handleEvent(e: IHomEventEmitter) {
    switch (e.event) {
      case SkuCreationWizardEvent.skuCreated:
        const createResult: IPricingCreateResult = e.data;
        this.skuId = createResult.parentId;
        this.skuName = createResult.parentName;
        this.emitListRefresh();
       if (e.action === SkuCreationWizardAction.next) {
         this.goToNextStep(this.stepCreateSkuPrice);
        } else {
          this.closeWizard();
        }
        break;
      case SkuCreationWizardEvent.skuPriceCreated:
        if (e.action === SkuCreationWizardAction.next) {
          this.working$.next(true);
          this.router.navigate([{ outlets: { wizard: null } }], {
            relativeTo: this.activeRoute
          }).then(success => {
            this.stepName$.next(this.stepAskPayoutType);
            this.working$.next(false);
          });
        } else {
          this.closeWizard();
        }
        break;
      case SkuCreationWizardEvent.payoutLinked:
        if (e.action === SkuCreationWizardAction.next) {
          this.goToNextStep(this.stepCreatePayoutPrice);
        } else {
          this.closeWizard();
        }
        break;
      case SkuCreationWizardEvent.payoutCreated:
        const result: IPricingCreateResult = e.data;
        this.payoutId = result.parentId;
        this.payoutName = result.parentName;
        if (this.origin === SkuCreationWizardOrigin.skuPayout) {
            this.emitListRefresh();
        }
        if (e.action === SkuCreationWizardAction.next) {
          this.goToNextStep(this.stepCreatePayoutPrice);
        } else {
          this.closeWizard();
        }
        break;
      case SkuCreationWizardEvent.payoutPriceCreated:
        this.closeWizard();
        break;
      case SkuCreationWizardEvent.cancel:
        this.closeWizard();
        break;
      default:
       break;
    }
 }

  emitListRefresh(): void {
    const emitter: IHomEventEmitter = { requestor: 'sku-creation-wizard', event: this.myConstants.emitterEventListReload, action: '', data: null };
    this.store.dispatch(new DynamicListActions.SetEventList({
      storeName: this.storeName,
      parentId: this.origin === SkuCreationWizardOrigin.skuPayout? this.skuId : this.gcId,
      event: emitter
    }));
  }

  closeWizard(): void {
    this.working$.next(false);
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
