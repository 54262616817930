import { Component, OnInit, Inject, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable, of, BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { appConstants, IAppConstants } from '../../../../shared/constants';
import { IProject } from '../../../portals/view-models';
import { ProjectObjectStore } from '../../../portals/project/enums/project.enums';
import { IAllDynamicData, getObjectDataById } from '../../../../fw/dynamic-list/store';
import * as fromRoot from '../../../store/reducers/index';
import { ProjectService } from '../../../portals/project/services';

@Component({
  selector: 'sms-project-summary',
 templateUrl: './sms-project-summary.component.html'
})
export class SmsProjectSummaryComponent implements OnInit, OnDestroy {
  @Input() projectId: number;
  @Output() activated = new EventEmitter<string>(false);

  public warrantyId: number;
  public project$: BehaviorSubject<IProject> = new BehaviorSubject(null);
  public loading$: Observable<boolean> = of(false);
  subscription: Subscription = new Subscription();

  constructor(    
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<IAllDynamicData>,
    public projectService: ProjectService,
    @Inject(appConstants) public myConstants: IAppConstants ) {}

  ngOnInit() {
    this.project$.next(null);
    this.activated.emit(new Date().toTimeString());
    this.dispatchForData();
    this.listenForData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public goToProject(): void {
    this.projectService.openProjectTab(this.projectId, 'sms-project-summary');
  }

  dispatchForData(): void {
    this.projectService.dispatchProjectObject(this.projectId);
    //not using any selection lists, so do not waste hits to load those
  }

  listenForData(): void {
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(filter(x => x.requestor === ProjectObjectStore.projectInformation),
        map(x => x.show));

    this.subscription.add(this.store.pipe(
      select(getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)))
      .subscribe((objData: IProject) => {
        if (objData) {
          this.project$.next(objData);
        }
      })
    );

  }

}
