/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { UserDashboardService } from '../../../user-dashboard/services/user-dashboard.service';
import { UserDashEvent } from '../../../user-dashboard/enums/user-dashboard.enums';
import { IScheduleInstallerWorkCrewAvailabilityViewModel } from '../../../view-models/i-schedule-installer-work-crew-availability-view-model';
import { IListDefinition, IListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { getEntityListByParentId } from '../../../../../fw/dynamic-list/store';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { DomainObjectService } from '../../../../../shared/services';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { UtilitiesService } from '../../../utilities/services/utilities.service';

@Component({
  selector: 'dispatch-manager',
  templateUrl: './dispatch-manager.component.html'
})
export class DispatchManagerComponent implements OnInit, OnDestroy {
  public workOrderId: number;
  public projectId: number;
  public hasPostIts: boolean;
  public selectedCrewId: number = -1;
  public title: string;
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);

  scheduleId: number;
  storeName: string;
  parentDef: IListDefinition;
  parentCurrentFilter: IListFilter;
  subscription: Subscription = new Subscription();

  constructor(public  activeRoute: ActivatedRoute,
    public store: Store<fromFeature.IAllDynamicData>,
    public modalService: ModalService,
    public userDashboardService: UserDashboardService,
    public utiltiesService: UtilitiesService,
    public domainObjectService: DomainObjectService,
    @Inject(appConstants) public  myConstants: IAppConstants) { }

  public onListSelect(event: IHomEventEmitter) {
    switch (event.event) {
      case UserDashEvent.selectRecord:
        const viewModel: IScheduleInstallerWorkCrewAvailabilityViewModel = event.data;
        this.selectedCrewId = viewModel.installerWorkCrewId;
        break;
      default:
        break;
    }
  }

  public onListPage(): void {
    this.selectedCrewId = -1;
  }

  public assignCrew(): void {
    //manual api call, on success, empty list data and close modal
    this.subscription.add(this.domainObjectService.updateByMethod('Schedule', 'Dispatch', { id: this.scheduleId, installerWorkCrewId: this.selectedCrewId })
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.errorData$.next([]);
          this.store.dispatch(new DynamicListActions.GetList({ listDefinition: this.parentDef, listFilter: this.parentCurrentFilter, parentId: this.parentDef.parentId }));
          this.close();
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  public close(): void {
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }


  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.workOrderId = +paramMap.get("id");
      this.projectId = window.history.state['projectId'];
      this.scheduleId = window.history.state['scheduleId'];
      this.hasPostIts = window.history.state['hasPostIts'];
      this.storeName = window.history.state['storeName'];

      this.title = 'Available Work Crews for Work Order '.concat(this.workOrderId.toString());
      this.listDefinition$.next(this.userDashboardService.loadAvailableWorkCrewsListDefinition(this.scheduleId));
    });

    this.subscription.add(this.store
      .pipe(select(getEntityListByParentId(this.storeName, -1)))
      .subscribe(state => {
        this.parentDef = cloneDeep(state.listDefinition);
        this.parentCurrentFilter = cloneDeep(state.listFilter);
      }
    ));

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
