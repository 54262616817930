<div class="dl-sort-item">
  <div class="dl-sort-item__select">
    <div class="dl-sort-item__col">
      <div class="dl-sort-item__col--label"><label title="Column Name">{{ index === 0 ? 'Sort By' : 'Then By' }}</label></div>
      <select #sortItem class="dl-sort-item__col--val" (change)="changeSelection(sortItem.value)" appFocus [autoFocus]="true">
        <option selected value="">Choose...</option>
        <option *ngFor="let item of items"
                [value]="item.colKey"
                [selected]="selectedItem && selectedItem.colKey === item.colKey">
          {{item.label}}
        </option>
      </select>
    </div>
  </div>
  <div class="dl-sort-item__asc">
    <div class="dl-sort-item__col">
      <div class="dl-sort-item__col--label"><label title="Order Ascending">Ascending</label></div>
      <div class="dl-sort-item__col--val dl-sort-item__col--val--center">
        <fw-check-box #isAsc
                      [config]="ascConfig"
                      [isDisabled]="false"
                      [isChecked]="activeAsc"
                      (change)="changeAsc(isAsc.value)">
        </fw-check-box>
      </div>
    </div>
  </div>
  <div class="dl-sort-item__del">
    <button type="button"
            class="app-btn-icon app-btn-icon--danger"
            (click)="delete()"
            title="Delete">
      <span class="fas fa-times"></span>
    </button>
  </div>
</div>
