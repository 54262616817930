<div class="sms-send-doc">
  <div class="sms-send-doc--icon">
    <i class="fad fa-paperclip"></i>
  </div>
  <div class="sms-send-doc--text">{{document.entityLabel.concat(document.entityId > 0 ? ' ' + document.entityId.toString() : '', ' ', document.documentType.documentTypeName) }}</div>
  <div class="sms-send-doc--delete">
    <button type="button"
            class="app-btn-icon app-btn-icon--danger"
            (click)="deleteDocument(document.id)"
            title="Delete Document">
      <span class="fal fa-times"></span>
    </button>
  </div>
</div>
