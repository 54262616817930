<div class="contact-header">

  <div *ngIf="showHeader" class="contact-header__icon">
    <i *ngIf="!contact.fileUpload_fileUploadId" class="fad {{ myIcon }} app-icon--primary contact-header__icon--placeholder"></i>
    <div *ngIf="contact.fileUpload_fileUploadId" class="contact-header__icon--image" style="background-image: url('/Image/Thumb/{{ contact.fileUpload_fileUploadId }}')"></div>
    <div class="contact-header__icon--btn" title="{{ !contact.fileUpload_fileUploadId ? 'Select Image' : 'Change Image' }}">
      <single-file-uploader [objectId]="contact.contactId"
                            [icon]="'fa-camera'"
                            [disabled]="operation !== myConstants.operationTypeDetails || !canIEdit || isImport"
                            (afterUploadEvent)="handleUpload($event)"
                            [fileUploadType]="fileUploadType">
      </single-file-uploader>
    </div>
  </div>

  <form *ngIf="showHeader && contactService.contactForm.controls[formName]"
        [formGroup]="contactService.contactForm.controls[formName]" class="contact-header__form" novalidate>
    <div class="contact-header__group">
      <fw-dynamic-field class="contact-header_group--first"
                        [field]="metaDataService.getFieldDefinition('firstName')"
                        [form]="contactService.contactForm.controls[formName]"
                        [operation]="operation">
      </fw-dynamic-field>
      <fw-dynamic-field *ngIf="(suffixRequested || contact.nameSuffix)"
                        class="contact-header_group--suffix"
                        [field]="metaDataService.getFieldDefinition('nameSuffix')"
                        [selectItems]="metaDataService.getSelectItems('nameSuffix')"
                        [form]="contactService.contactForm.controls[formName]"
                        [operation]="operation">
      </fw-dynamic-field>
    </div>
    <fw-dynamic-field *ngIf="(middleRequested || contact.middleName)"
                      [field]="metaDataService.getFieldDefinition('middleName')"
                      [form]="contactService.contactForm.controls[formName]"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="metaDataService.getFieldDefinition('lastName')"
                      [form]="contactService.contactForm.controls[formName]"
                      [operation]="operation">
    </fw-dynamic-field>
    <div class="contact-header__flags">
      <fw-dynamic-field [field]="metaDataService.getFieldDefinition('optOutSms')"
                        [form]="contactService.contactForm.controls[formName]"
                        [operation]="operation">
      </fw-dynamic-field>
      <fw-dynamic-field [field]="metaDataService.getFieldDefinition('sssOptOut')"
                        [form]="contactService.contactForm.controls[formName]"
                        [operation]="operation">
      </fw-dynamic-field>
    </div>
  </form>
  <div *ngIf="contactService.contactForm.controls[formName]" class="contact-header__commPref"
       [ngClass]="{'contact-header__commPref--unset': !contact.communicationPreferenceValue}">
    <div class="float-label">
      <fw-communication-preference [row]="contact"
                                   [displayType]="communicationEventService.displayTypeBoth"
                                   [allowText]="allowText && !isImport">
      </fw-communication-preference>
      <label [title]="metaDataService.getLabel('communicationPreferenceName')">
        {{ metaDataService.getLabel('communicationPreferenceName') }}
      </label>
    </div>
    <div class="contact-header__commPref__btns">
      <button type="button"
              class="app-btn-icon app-btn-icon--warning"
              title="Edit Preference"
              [disabled]="!canIEdit || operation !== myConstants.operationTypeDetails || isImport"
              (click)="editPreference()">
        <span class="fas fa-edit fa-sm"></span>
      </button>
      <button type="button"
              class="app-btn-icon app-btn-icon--danger"
              title="Delete Preference"
              [disabled]="!canIEdit || isImport || operation !== myConstants.operationTypeDetails
                          || !contact.metaData || !contact.metaData.contactDeletePreferenceUrl
                          || !pcmExists()"
              (click)="deletePreference()">
        <span class="fas fa-trash fa-sm"></span>
      </button>
    </div>
  </div>
</div>
