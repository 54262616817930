import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../app/store/reducers/index';
import * as AuthActions from '../store/auth.actions';
import { Auth } from '../interfaces/auth';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html'
})
export class SignupComponent  {

  constructor(public store: Store<fromRoot.IState>) { }

  public onSignup(form: NgForm) {
    const authData: Auth = new Auth(form.value.userName, form.value.password);
    this.store.dispatch(new AuthActions.TrySignup({ authData }));
  }
}
