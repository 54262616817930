import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import {  map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IProject, INote } from '../../../view-models/index';
import { ProjectService } from '../../services/project.service';
import { IIcon } from '../../../portal-shared/interfaces/index';
import { IPortalObjectBtn } from '../../../portal-shared/interfaces/i-portal-object-btn';
import { InitializeObject, ClearEventList } from '../../../../../fw/dynamic-list/store';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { IDynamicTabInclude } from '../../../portal-shared/interfaces/i-dynamic-tab-include';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as fromDynamicObject from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import {  getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { ProjectObjectStore, ProjectListStore, ProjectEvent, ProjectStatus } from '../../enums/project.enums';
import { UserRecentsService } from '../../../../../fw/fw-shared/components/user-recents/services/user-recents.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { DomainObjectService } from '../../../../../shared/services';
import { TabsService } from '../../../../../fw/fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';

@Component({
  selector: 'project',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project.component.html'
})
export class ProjectComponent implements OnInit, OnDestroy {
  public portalId: number = 1;
  public project$: BehaviorSubject<IProject> = new BehaviorSubject(null);
  public tabContainerName = 'project-detail';
  public title: string;
  public gcBranch: string;
  public titleIcon$: BehaviorSubject<IIcon> = new BehaviorSubject(null);
  public portalBtnConfig$: BehaviorSubject<IPortalObjectBtn[]> = new BehaviorSubject([]);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public portalReload$: BehaviorSubject<string> = new BehaviorSubject('');
  projectStatus$: BehaviorSubject<string> = new BehaviorSubject('');
  projectId: number = -1;
  poCount: number = -1;
  woCount: number = -1;
  cbCount: number = -1;
  deleteUrl: string = '';
  dynamicTabIncludes: IDynamicTabInclude[] = [];
  subscription = new Subscription();
  
  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromFeature.IAllDynamicData>,
    public projectService: ProjectService,
    public userRecentsService: UserRecentsService,
    public userPriviledgesService: UserPriviledgesService,
    public dos: DomainObjectService,
    public tabsService: TabsService,
   @Inject(appConstants) public myConstants: IAppConstants    ) {}

  public onCustomEvent(event: string): void {
    switch (event) {
      case ProjectEvent.deleteProject:
        this.deleteProject();
        break;
      case ProjectEvent.reopenProject:
        this.reopenProject();
        break;
      case ProjectEvent.reloadProject:
        this.portalReload$.next(new Date().toTimeString());
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get('portalEntityId');
      this.newRequest();
    });
  }

  newRequest() {
    this.titleIcon$.next(null);
    this.poCount = this.woCount = this.cbCount = -1;
    this.portalBtnConfig$.next([]);
    this.errorData$.next([]);
    this.errors$.next('');

    this.subscription.add(this.store.pipe(select(fromDynamicObject.getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)))
      .subscribe((objData: IProject) => {
        if (objData && objData.projectId) {
          const project = cloneDeep(objData);
          const isWarranty: boolean = project.warranty_warrantyId && project.warranty_warrantyId > 0;
          this.title = (isWarranty ? ' Warranty ' : '').concat(' Project ', project.projectId.toString());
          this.gcBranch = project.generalContractorName.concat(' ', project.branchName);
          this.deleteUrl = this.userPriviledgesService.getDeleteUrl(project);
          this.projectStatus$.next( project.currentProjectStatusCode);
          if (isWarranty) {
            this.dynamicTabIncludes.push({ componentName: 'OriginatingProjectSummaryComponent', isDefault: true });
          } else {
            this.dynamicTabIncludes = [];
          }
          this.project$.next(project);
        }
      }));

    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectPurchaseOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((objData: IListObjectData) => {
        if (objData && objData.data && !objData.working) {
          this.poCount = objData.data.length;
          this.setPortalBtns();
          if (objData.event && objData.event.event == this.myConstants.emitterEventListReloadPortal && (!objData.errorData || !objData.errorData.length)) {
            this.store.dispatch(new ClearEventList({ storeName: objData.listDefinition.storeName, parentId: objData.listDefinition.parentId }));
            this.portalReload$.next(new Date().toTimeString());
          }
        }
      })
    );

    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectWorkOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((objData) => {
        if (objData && objData.data && !objData.working) {
          this.woCount = objData.data.length;
          this.setPortalBtns();
          if (objData.event && objData.event.event == this.myConstants.emitterEventListReloadPortal && (!objData.errorData || !objData.errorData.length)) {
            this.store.dispatch(new ClearEventList({ storeName: objData.listDefinition.storeName, parentId: objData.listDefinition.parentId }));
            this.portalReload$.next(new Date().toTimeString());
          }
        }
      })
    );

    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectChargeBacks)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((objData) => {
        if (objData && objData.data && !objData.working) {
          this.cbCount = objData.data.length;
          this.setPortalBtns();
        }
      })
    );

    //listening for notes store 
    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectNotes)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe(objData => {
        this.titleIcon$.next(null);
        if (objData && objData.data) {
          //check to see if any of the notes has special instructions
          const note: INote = objData.data.find((note: INote) => note.postIt === true);
          if (note) {
            const titleIcon: IIcon = {
              title: 'Customer Has Special Instructions',
              icon: 'fas fa-siren-on special-instructions--icon',
              cssName: 'app-icon--warning '
            }
            this.titleIcon$.next(titleIcon);
          }
        }
      }));

    this.projectStatus$.subscribe((val: string) => {
      this.setPortalBtns();
    });
  }

  setPortalBtns(): void {
    if (this.poCount === -1 || this.woCount === -1 || this.cbCount === -1) {
      this.portalBtnConfig$.next([]);
    } else {
      let portalBtns: IPortalObjectBtn[] = [];
      if (this.projectStatus$.value.toLowerCase() === ProjectStatus.closed.toLowerCase()) {
        portalBtns.push({
          title: 'Reopen Project '.concat(this.projectId.toString()),
          disabled: !this.userPriviledgesService.canReopenProject$.value,
          disabledTitle: 'You do not have reopen project privileges.',
          event: ProjectEvent.reopenProject,
          icon: 'fas fa-house-person-return',
          css: 'app-btn-icon--success',
          confirm: true
        });
      }
      portalBtns.push({
        title: 'Delete Project '.concat(this.projectId.toString()),
        disabled: !this.userPriviledgesService.deleteProject$.value || (this.poCount + this.woCount + this.cbCount > 0),
        disabledTitle: 'You can only delete a project if you have delete privileges and the project has zero POs, WOs, and Charge Backs',
        event: ProjectEvent.deleteProject,
        icon: 'fas fa-trash',
        css: 'app-btn-icon--danger',
        confirm: true
      });
      portalBtns.push({
        title: 'Reload Project '.concat(this.projectId.toString()),
        disabled: false,
        disabledTitle: '',
        event: ProjectEvent.reloadProject,
        icon: 'fas fa-sync',
        css: 'app-btn-icon--primary',
        confirm: false
      });
      this.portalBtnConfig$.next(portalBtns);
    }
  }

  closeProjectTab(): void {
    const event: IHomEventEmitter = {
      requestor: 'project-high-level-summary',
      event: this.myConstants.emitterEventTabLevel1Close,
      action: '',
      data: null
    };

    this.tabsService.emitTabEvent(event);
  }

  deleteProject(): void {
    if (this.deleteUrl.length > 0) {
      this.working$.next(true);
      this.subscription.add(this.dos.deleteByMethodById('Project', 'Delete', this.projectId)
        .subscribe((response: IResponseBase) => {
          if (response.success) {
            this.closeProjectTab();
            this.store.dispatch(new InitializeObject({ storeName: ProjectObjectStore.projectInformation, objectId: this.projectId }));
          } else {
            this.errorData$.next(response.errorData);
          }
          this.working$.next(false);
        },
          (error: any) => { this.errors$.next(error); }
        ));
    }
  }

  reopenProject(): void {
    if (this.projectId > 0 && this.projectStatus$.value.toLowerCase() === ProjectStatus.closed.toLowerCase()) {
      this.working$.next(true);
      this.subscription.add(this.dos.updateByMethodById('Project', 'Reopen', this.projectId)
        .subscribe((response: IResponseBase) => {
          if (response.success) {
            this.portalReload$.next(new Date().toTimeString());
          } else {
            this.errorData$.next(response.errorData);
          }
          this.working$.next(false);
        },
          (error: any) => { this.errors$.next(error); }
        ));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
