<ng-container *ngIf="(poImportHeader$ | async) as poImportHeader">
  <fw-dynamic-detail-container [detailConfig]="detailConfig$ | async">
    <div *ngIf="poImportHeader">
      <po-import-header-summary [data]="poImportHeader"></po-import-header-summary>
    </div>
    <div *ngIf="(listDefinition$ | async) as listDefinition">
      <div class="list-wrapper">
        <fw-component-title [title]="title" [entityLabel]="entityLabel"></fw-component-title>
        <fw-dynamic-list [vmListDefinition]="listDefinition" (customEvent)="onCustom($event)"></fw-dynamic-list>
      </div>
    </div>
  </fw-dynamic-detail-container>
</ng-container>
