
export interface IWorkOrderItemShipperProviderLocation {
  workOrderItemShipperProviderLocationId: number;
  workOrderItemShipper_workOrderItemShipperId: number;
  providerLocationShipperInventorySetup_providerLocationShipperInventorySetupId: number;
  leadTimeDays: number;
  buffer: number;
  unallocated: number;
}

export class WorkOrderItemShipperProviderLocation implements IWorkOrderItemShipperProviderLocation {
  workOrderItemShipperProviderLocationId: number;
  workOrderItemShipper_workOrderItemShipperId: number;
  providerLocationShipperInventorySetup_providerLocationShipperInventorySetupId: number;
  leadTimeDays: number;
  buffer: number;
  unallocated: number;

  constructor() {
    this.workOrderItemShipperProviderLocationId = 0;
    this.workOrderItemShipper_workOrderItemShipperId = null;
    this.providerLocationShipperInventorySetup_providerLocationShipperInventorySetupId = null;
    this.leadTimeDays = 0;
    this.buffer = 0;
    this.unallocated = 0;
  }
}
