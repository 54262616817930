<fw-dynamic-list *ngIf="listDefinition$ | async"
                 [vmListDefinition]="listDefinition$ | async"
                 [listTitle]="'Work Crew Metrics'">
  <!--ng drop-->
  <div drop-below-list-title>
    <div *ngIf="workCrews && workCrews.length > 0" class="float-label">
      <select #workCrew
              [disabled]="!workCrews"
              (change)="changeCrew(workCrew.value)">
        <option *ngFor="let crew of workCrews; let i = index"
                [value]="crew.installerWorkCrewId"
                [selected]="i === 0">
          {{crew.installerWorkCrewName}}
        </option>
      </select>
      <label title="View By Crew">View By Crew</label>
    </div>
  </div>
</fw-dynamic-list>
<div *ngIf="!workCrews || workCrews.length === 0" class="app-info">
  No work crews.
</div>
