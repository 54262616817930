import { Component, Input, OnInit} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IEntityDocument, IEntityDocumentActionViewModel, IDocumentTypeActionViewModel } from '../../../../app/portals/view-models/index_two';
import { ProjectEvent } from '../../../../app/portals/project/enums/project.enums';
import { IDocumentActionRequest } from '../../interfaces';
import { IProgramServiceDocumentType } from '../../../../app/portals/view-models/i-program-service-document-type';
import { DocumentTypeActionType } from '../../../../app/portals/portal-shared/enums/doc-mgt.enums';
import { UserPriviledgesService } from '../../../../auth/services';

@Component({
  selector: 'fw-document-actions-list-item',
  templateUrl: './document-actions-list-item.component.html'
})
export class DocumentActionsListItemComponent implements OnInit  {
  @Input() row: IEntityDocument | IProgramServiceDocumentType;

  public isInfoOnly: boolean = false;
  public uploadFile: string;
  public getSignature: string;
  public generateFile: string;
  public fillForm: string;
  public actions$: BehaviorSubject<IEntityDocumentActionViewModel[]> = new BehaviorSubject([]);

  constructor(
    public emitterService: HomEventEmitterService,
    public ups: UserPriviledgesService  ) {
    //SET CONSTANTS BASED ON ENUM VALUES
    this.uploadFile = DocumentTypeActionType.uploadFile;
    this.getSignature = DocumentTypeActionType.getSignature;
    this.generateFile = DocumentTypeActionType.generateFile;
    this.fillForm = DocumentTypeActionType.fillForm;
  }

  ngOnInit(): void {
    this.isInfoOnly = this.row.hasOwnProperty('entityDocumentId') ? false : true;
    this.setActions();
  }

  public onClick(action: IEntityDocumentActionViewModel): void {
    if (this.isInfoOnly || !action.isEnabled) return;

    if (!action.canOverride) {
      alert('You do not have security access to complete this action.');
      return;
    }
    //need to bubble up for container that rendered dynamic list to consume this.
    //need entire row for data and action based on action.
    //so document_id 1, request to sign the document.
    const data: IDocumentActionRequest = {
      entityDocument: this.row as IEntityDocument,
      selectedAction: action
    }
    let event: IHomEventEmitter = {
      requestor: 'action-list-item',
      event: action.actionTypeName === DocumentTypeActionType.uploadFile ? ProjectEvent.uploadDocument :
        action.actionTypeName === DocumentTypeActionType.getSignature ? ProjectEvent.signDocument :
          action.actionTypeName === DocumentTypeActionType.generateFile ? ProjectEvent.generateFile :
            action.actionTypeName === DocumentTypeActionType.fillForm ? ProjectEvent.fillForm
          : '',
      action: '',
      data: data
    };
    this.emitterService.emitListCustomEvent(event);
  }

  // Move actions array to a local array so can eliminate string manipulation in html.
  setActions(): void {
    let actions: IEntityDocumentActionViewModel[] = [];
    this.row.actions.forEach(x => {
      let action: IEntityDocumentActionViewModel = x;
      action.canOverride = !x.isInfoOnly && x.allowExpertOverride
        ? x.overridePrivilegeName === this.ups.none || this.ups[x.overridePrivilegeName + '$'].value
          ? true
          : false
        : false;
      actions.push(action);
    });
    this.actions$.next(actions);
  }
 }
