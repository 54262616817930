import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IContactMechanismCategory } from '../../../view-models/i-contact-mechanism-category';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { AdminStore, AdminEvent } from '../../enums/admin.enums';
import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';

import { AdminProviderService } from '../../services/admin-provider.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'contact-mechanism-categories',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './contact-mechanism-categories.component.html'
})
export class ContactMechanismCategoriesComponent implements OnInit {

  public listDefinition: IListDefinition;
  params: IDetailRouteParameter;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.manageContactMechanismCategoryTypes:
        this.openContactMechanismCategoryTypes(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadContactMechanismCategoriesListDefinition();
  }

  openContactMechanismCategoryTypes(cmc: IContactMechanismCategory): void {
    this.modalService.open({
      title: 'Types for Contact Mechanism'.concat(' ', cmc.contactMechanismCategoryName),
      path: 'contact-mech-category-types/' + cmc.contactMechanismCategoryId.toString(),
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false
    });
  }
}
