import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import * as fromRoot from '../../app/store/reducers/index';
import {  IHomUser } from '../interfaces/index';
import { isAuthenticated } from '../store/auth.selectors';
import * as AuthActions from '../store/auth.actions';
import { HomLocalStorage } from '../enums/hom-local-storage.enums';

@Injectable()
export class AuthGuard  {

  constructor(public router: Router,
    public rootStore: Store<fromRoot.IState>) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.rootStore
      .pipe(
        select(isAuthenticated),
        tap(authenticated => {
          if (!authenticated) {
            if (localStorage.getItem(HomLocalStorage.HomInstallerPortal)) {
              //clean up local storage
              localStorage.removeItem(HomLocalStorage.SessionId);
              localStorage.removeItem(HomLocalStorage.HomPrimaryInstance);
              localStorage.removeItem(HomLocalStorage.HomUser);
              localStorage.removeItem(HomLocalStorage.HomLogoutEvent);
              localStorage.removeItem(HomLocalStorage.HomInstallerPortal);
            }
            const storageData = localStorage.getItem(HomLocalStorage.HomUser);
            const homUser: IHomUser = JSON.parse(storageData);
            if (homUser !== null && (homUser.expiry > new Date().getTime())) {
              this.rootStore.dispatch(new AuthActions.RefreshAction());
            } else {
              this.rootStore.dispatch(new AuthActions.Logout({ initLogoutUserSessionEvent: false }));
            }
          } 
        })
      );

  }
}
