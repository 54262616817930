import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../../auth/services/auth-guard.service';
import {
  SelectionListResolver,
  GeneralContractorsResolver,
  MetaDataResolver,
  AllMyProviderLocationsResolver,
  AllProviderUsersResolver,
  ContactTypeEntityResolver,
  DocumentTypeResolver
} from '../resolvers';
import {
  ContactMetaDataResolver, ContactTypeSpecificationResolver,
  ContactPhoneMetaDataResolver, ContactEmailMetaDataResolver, ContactAddressMetaDataResolver
} from '../../contact/resolvers';
import { EmptyComponent } from '../portal-shared/components';

import {
  UserSecurityComponent,
  SkusComponent,
  SkuPayoutsComponent,
  CommunicationShortcutComponent,
  CommunicationDocumentTypeComponent,
  RoleComponent,
  PrivilegeTemplateComponent,
  PrivilegeTemplatePrivilegeComponent,
  PrivilegeTemplatePrivilegeRuleValueComponent,
  PrivilegeFilterComponent,
  ScheduleExclusionsCreateComponent,
  ZoneComponent,
  CustomerFlagComponent,
  LookupValueComponent,
  LookupTypeComponent,
  WorkCategoryComponent,
  ChargeBackTypeComponent,
  ContactMechanismCategoryComponent,
  ContactMechanismCategoryTypeComponent,
  ContactTypeComponent,
  ContactTypeSpecificationComponent,
  WorkCategoryPayoutComponent,
  ServiceGroupComponent,
  GeneralContractorComponent,
  BranchComponent,
  ProgramComponent,
  ProgramServiceComponent,
  RegionComponent,
  ServiceComponent,
  DocumentTypeComponent,
  SurveyComponent,
  SurveyQuestionDataTypeComponent,
  SurveyQuestionComponent,
  SurveyProjectMapFieldComponent,
  SurveyProgramServiceComponent,  ShipperComponent,
  ShipperLocationPayoutComponent,
  ShipperLocationComponent,
  ShipperPayoutComponent,
  ProviderLocationComponent,
  ProviderLocationServiceComponent,
  ChargeBackSkuComponent,
  CommunicationEventTypeComponent,
  CommunicationEventFeedbackComponent,
  SSSGeneralContractorComponent,
  SSSProgramComponent,
  SSSProgramServiceComponent,
  SSSProgramServiceBranchComponent
} from './components';

import {
  AdminComponent,
  UsersSecurityComponent,
  RolesComponent,
  PrivilegeFiltersComponent,
  PrivilegeTemplatesComponent,
  UserAccessManagerComponent,
  ChargeBackSkusComponent,
  ProviderPayoutsComponent,
  GeneralContractorSkusComponent,
  ChargeBackTypesComponent,
  ContactMechanismCategoriesComponent,
  CustomerFlagsComponent,
  GeneralContractorsComponent,
  InstallerScoringComponent,
  InventoryProviderManagerComponent,
  LookupTypesComponent,
  MeasureServicesComponent,
  ProviderLocationsComponent,
  ScheduleExclusionsComponent,
  SelfServiceSchedulingComponent,
  ServiceGroupsComponent,
  SurveysComponent,
  WarrantyTypesComponent,
  WorkCategoriesComponent,
  ZonesComponent,
  CommunicationShortcutsComponent,
  CommunicationDocumentTypesComponent,
  RoleWidgetsComponent,
  RoleUsersComponent,
  RolePrivilegeTemplatesComponent,
  RelatedEntityManagerComponent,
  PrivilegeTemplateRolesComponent,
  PrivilegeTemplatePrivilegesComponent,
  PrivilegeTemplatePrivilegeValuesComponent,
  FiltersWhereUsedComponent,
  PrivilegesWhereUsedComponent,
  PrivilegesComponent,
  ContactTypesComponent,
  ContactMechanismCategoryTypesComponent,
  ContactTypeSpecificationsComponent,
  ServiceGroupServicesComponent,
  BranchesComponent,
  ProgramsComponent,
  ProgramServicesComponent,
  RegionsComponent,
  ServicesComponent,
  BranchRegionsComponent,
  ProviderLocationBranchesComponent,
  BranchProgramsComponent,
  BranchNotesComponent,
  ProgramServiceFilesComponent,
  ProviderLocationServicesComponent,
  AdminContactComponent,
  ServiceGroupCreationWizardComponent,
  DocumentTypesComponent,
  SurveyQuestionDataTypesComponent,
  SurveyQuestionsComponent,
  SurveyProjectMapFieldsComponent,
  SurveyImportMappingsComponent,
  SurveyProgramServicesComponent,  WarrantySubTypesComponent,
  ShipperLocationsComponent,
  ShipperLocationPayoutsComponent,
  ShipperPayoutsComponent,
  ProviderLocationSchedulesComponent,
  ChargeBackSkuPricesComponent,
  ChargeBackSkuPriceManagerComponent,
  CommunicationEventTypesComponent,
  CommunicationEventFeedbacksComponent,
  SSSGeneralContractorsComponent,
  SSSProgramsComponent,
  SSSProgramServicesComponent,
  SSSProgramServiceBranchesComponent,
  PayoutPricesComponent,
  PayoutSkusComponent
} from './containers';

import { SkuComponent, SkuPayoutLinkComponent, PayoutComponent, PayoutPriceComponent } from '../pricing-shared/components';
import { LoadingPageComponent } from '../../../shared/components';
import { FileUploadManagerComponent } from '../../file-upload/containers';
import { ProjectEntityResolver } from '../resolvers/project-entity-resolver.service';
import { WarrantyTypeComponent } from './components/warranty-type/warranty-type.component';
import { WarrantySubTypeComponent } from './components/warranty-sub-type/warranty-sub-type.component';
import { ShipperLocationPayoutResolver } from './resolvers/shipper-location-payout-resolver.service';
import { MaterialPayoutResolver } from './resolvers/material-payout-resolver.service';

const portalRoutes: Routes = [
  {
    path: 'app-admin', //componentless route 
    children: [
      {
        path: ':portalId', //not really needed at this time
        component: AdminComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'admin-empty', //verify path, result of tab component concatenation
            outlet: 'portal-detail',
            component: EmptyComponent
          },
          {
            path: 'loading/:container',
            outlet: 'portal-detail',
            component: LoadingPageComponent
          },
          {
            path: 'user-security',
            outlet: 'portal-detail',
            component: UsersSecurityComponent,
            data: {
              includeReadOnly: false
            },
            resolve: {
              providerLocations: AllMyProviderLocationsResolver
            },
            children: [
              {
                path: 'user-security-detail/:id',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
                outlet: 'userSecurityDetailOutlet',
                component: UserSecurityComponent
              }
            ]
          },
          {
            path: 'roles',
            outlet: 'portal-detail',
            component: RolesComponent,
            children: [
              {
                path: 'provider-role/:id',
                outlet: 'roleOutlet',
                component: RoleComponent
              }
            ]
          },
          {
            path: 'privileges',
            outlet: 'portal-detail',
            component: PrivilegesComponent
          },
          {
            path: 'privilege-filters',
            outlet: 'portal-detail',
            component: PrivilegeFiltersComponent,
            children: [
              {
                path: 'privilege-filter/:id',
                outlet: 'privFilterOutlet',
                component: PrivilegeFilterComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'privilege-templates',
            outlet: 'portal-detail',
            component: PrivilegeTemplatesComponent,
            children: [
              {
                path: 'privilege-template/:id',
                outlet: 'privTemplateOutlet',
                component: PrivilegeTemplateComponent
              }
            ]
          },
          {
            path: 'charge-back-skus',
            outlet: 'portal-detail',
            component: ChargeBackSkusComponent,
            children: [
              {
                path: 'charge-back-sku/:id',  
                outlet: 'chargeBackSkuOutlet',
                component: ChargeBackSkuComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'provider-payouts',
            outlet: 'portal-detail',
            component: ProviderPayoutsComponent,
            children: [
              {
                path: 'payout/:id',
                outlet: 'payoutOutlet',
                component: PayoutComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
             }
            ]
          },
          {
            path: 'gc-skus',
            outlet: 'portal-detail',
            resolve: {
              gcLoaded: GeneralContractorsResolver
            },
            component: GeneralContractorSkusComponent,
            children: [
              {
                path: 'skus/:id',  //gcId
                outlet: 'skusOutlet',
                component: SkusComponent,
                children: [
                  {
                    path: 'sku-detail/:id',
                    canActivate: [AuthGuard],
                    outlet: 'skuDetailOutlet',
                    component: SkuComponent,
                    resolve: {
                      selectListsLoaded: SelectionListResolver
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'admin-charge-back-types',
            outlet: 'portal-detail',
            component: ChargeBackTypesComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'charge-back-type/:id',
                canActivate: [AuthGuard],
                outlet: 'chargeBackTypeOutlet',
                component: ChargeBackTypeComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-comm-event-types',
            outlet: 'portal-detail',
            component: CommunicationEventTypesComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'comm-event-type/:id',
                canActivate: [AuthGuard],
                outlet: 'commEventTypeOutlet',
                component: CommunicationEventTypeComponent
              }
            ]
          },
          {
            path: 'admin-comm-event-feedbacks',
            outlet: 'portal-detail',
            component: CommunicationEventFeedbacksComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'comm-event-feedback/:id',
                canActivate: [AuthGuard],
                outlet: 'commEventFeedbackOutlet',
                component: CommunicationEventFeedbackComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-contact-types',
            outlet: 'portal-detail',
            component: ContactTypesComponent,
            canActivate: [AuthGuard],
            resolve: {
             entitiesLoaded: ContactTypeEntityResolver
            },
            children: [
              {
                path: 'contact-type/:id',
                canActivate: [AuthGuard],
                outlet: 'contactTypeOutlet',
                component: ContactTypeComponent
              }
            ]
          },
          {
            path: 'admin-communication-shortcuts',
            outlet: 'portal-detail',
            canActivate: [AuthGuard],
            component: CommunicationShortcutsComponent,
            children: [
              {
                path: 'communication-shortcut/:id',
                canActivate: [AuthGuard],
                outlet: 'communicationShortcutOutlet',
                component: CommunicationShortcutComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-communication-document-types',
            outlet: 'portal-detail',
            component: CommunicationDocumentTypesComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'communication-document-type/:id',
                canActivate: [AuthGuard],
                outlet: 'communicationDocumentTypeOutlet',
                component: CommunicationDocumentTypeComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-contact-mechanism-categories',
            outlet: 'portal-detail',
            component: ContactMechanismCategoriesComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'contact-mech-category/:id',
                canActivate: [AuthGuard],
                outlet: 'cmcOutlet',
                component: ContactMechanismCategoryComponent
              }
            ]
          },
          { //this is a dup named component
            path: 'admin-customer-flags',
            outlet: 'portal-detail',
            component: CustomerFlagsComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'customer-flag-detail/:id',
                outlet: 'customerFlagOutlet',
                component: CustomerFlagComponent
              }
            ]
          },
          {  
            path: 'admin-document-types',
            outlet: 'portal-detail',
            component: DocumentTypesComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'document-type/:id',
                outlet: 'documentTypeOutlet',
                component: DocumentTypeComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-gcs',
            outlet: 'portal-detail',
            canActivate: [AuthGuard],
            component: GeneralContractorsComponent,
            children: [
              {
                path: 'general-contractor/:id',
                canActivate: [AuthGuard],
                outlet: 'gcOutlet',
                component: GeneralContractorComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-installer-scoring',
            outlet: 'portal-detail',
            canActivate: [AuthGuard],
            component: InstallerScoringComponent
          },
          {
            path: 'admin-inventory-mgr',
            outlet: 'portal-detail',
            canActivate: [AuthGuard],
            component: InventoryProviderManagerComponent,
            children: [
              {
                path: 'shipper/:id',
                outlet: 'shipperOutlet',
                component: ShipperComponent
              }
            ]
          },
          {
            path: 'admin-lookup-types',
            outlet: 'portal-detail',
            canActivate: [AuthGuard],
            component: LookupTypesComponent,
            children: [
              {
                path: 'lookup-type-detail/:id',
                outlet: 'lookupTypeOutlet',
                component: LookupTypeComponent,
                children: [
                  {
                    path: 'lookup-value-detail/:id',
                    outlet: 'lookupValueOutlet',
                    component: LookupValueComponent
                  }
                ]
              }
            ]
          },
          {
            path: 'admin-measure-svs',
            canActivate: [AuthGuard],
            outlet: 'portal-detail',
            component: MeasureServicesComponent
          },
          {
            path: 'admin-provider-locations',
            canActivate: [AuthGuard],
            outlet: 'portal-detail',
            component: ProviderLocationsComponent,
            children: [
              {
                path: 'provider-location/:id',
                outlet: 'providerLocationOutlet',
                component: ProviderLocationComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-schedule-exclusions',
            outlet: 'portal-detail',
            canActivate: [AuthGuard],
            component: ScheduleExclusionsComponent,
            children: [
              {
                path: 'admin-schedule-exclusions-create/:id',
                outlet: 'adminScheduleExclusionsCreateOutlet',
                component: ScheduleExclusionsCreateComponent
              }
            ]
          },
          {
            path: 'admin-sss',
            canActivate: [AuthGuard],
           outlet: 'portal-detail',
            component: SelfServiceSchedulingComponent
          },
          {
            path: 'admin-service-groups',
            outlet: 'portal-detail',
            component: ServiceGroupsComponent,
            children: [
              {
                path: 'service-group-detail/:id',
                outlet: 'serviceGroupOutlet',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
                component: ServiceGroupComponent
              }
            ]
          },
          {
            path: 'admin-surveys',
            canActivate: [AuthGuard],
            outlet: 'portal-detail',
            component: SurveysComponent,
            children: [
              {
                path: 'survey/:id',
                outlet: 'surveyOutlet',
                component: SurveyComponent
              }
            ]
          },
          {
            path: 'admin-survey-data-types',
            outlet: 'portal-detail',
            component: SurveyQuestionDataTypesComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'survey-question-data-type/:id',
                outlet: 'surveyDataTypeOutlet',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
                component: SurveyQuestionDataTypeComponent
              }
            ]
          },
         {
          path: 'admin-warranty-types',
          canActivate: [AuthGuard],
          outlet: 'portal-detail',
           component: WarrantyTypesComponent,
           children: [
             {
               path: 'warranty-type/:id',
               outlet: 'warrantyTypeOutlet',
               component: WarrantyTypeComponent
             }
           ]
          },
          {
            path: 'admin-work-categories',
            outlet: 'portal-detail',
            component: WorkCategoriesComponent,
            children: [
              {
                path: 'work-category-detail/:id',
                outlet: 'workCategoryOutlet',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
                component: WorkCategoryComponent
              }
            ]
          },
          {
            path: 'admin-zones',
            canActivate: [AuthGuard],
            outlet: 'portal-detail',
            component: ZonesComponent,
            children: [
              {
                path: 'zone-detail/:id',
                outlet: 'zonesOutlet',
                canActivate: [AuthGuard],
               component: ZoneComponent
              }
            ]
          },
          {
            path: 'admin-branches',
            outlet: 'portal-detail',
            component: BranchesComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'branch/:id',
                canActivate: [AuthGuard],
                outlet: 'branchOutlet',
                component: BranchComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-programs',
            outlet: 'portal-detail',
            component: ProgramsComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'program/:id',
                canActivate: [AuthGuard],
                outlet: 'pgmOutlet',
                component: ProgramComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-pgm-svcs',
            outlet: 'portal-detail',
            component: ProgramServicesComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'program-service/:id',
                canActivate: [AuthGuard],
                outlet: 'psOutlet',
                component: ProgramServiceComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-regions',
            outlet: 'portal-detail',
            component: RegionsComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'region/:id',
                canActivate: [AuthGuard],
                outlet: 'regionOutlet',
                component: RegionComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-services',
            outlet: 'portal-detail',
            component: ServicesComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'service/:id',
                canActivate: [AuthGuard],
                outlet: 'svcOutlet',
                component: ServiceComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-sss-gcs',
            outlet: 'portal-detail',
            canActivate: [AuthGuard],
            component: SSSGeneralContractorsComponent,
            children: [
              {
                path: 'sss-gc/:id',
                canActivate: [AuthGuard],
                outlet: 'sssGcOutlet',
                component: SSSGeneralContractorComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-sss-pgms',
            outlet: 'portal-detail',
            canActivate: [AuthGuard],
            component: SSSProgramsComponent,
            children: [
              {
                path: 'sss-pgm/:id',
                canActivate: [AuthGuard],
                outlet: 'sssPgmOutlet',
                component: SSSProgramComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          }, 
          {
            path: 'admin-sss-pgm-svcs',
            outlet: 'portal-detail',
            canActivate: [AuthGuard],
            component: SSSProgramServicesComponent,
            children: [
              {
                path: 'sss-pgm-svc/:id',
                canActivate: [AuthGuard],
                outlet: 'sssPgmSvcOutlet',
                component: SSSProgramServiceComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          },
          {
            path: 'admin-sss-ps-branches',
            outlet: 'portal-detail',
            canActivate: [AuthGuard],
            component: SSSProgramServiceBranchesComponent,
            children: [
              {
                path: 'sss-ps-branch/:id',
                canActivate: [AuthGuard],
                outlet: 'sssPsBranchOutlet',
                component: SSSProgramServiceBranchComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          }
      ]
      }
    ]
  },
  //BEGIN MODAL ROUTES
  {
    path: 'service-group-creation-wizard/:id',
    outlet: 'modal',
    component: ServiceGroupCreationWizardComponent,
    children: [
      {
        path: 'service-group-create/:id',
        outlet: 'wizard',
        component: ServiceGroupComponent
      },
      {
        path: 'service-group-service-create/:id',
        outlet: 'wizard',
        component: RelatedEntityManagerComponent
      }
    ]
  },
  {
    path: 'service-group-services/:id',
    outlet: 'modal',
    component: ServiceGroupServicesComponent
  },
  {
    path: 'work-category-detail/:id',
    outlet: 'modal',
    component: WorkCategoryComponent,
    resolve: {
      selectListsLoaded: SelectionListResolver
    },
    children: [
      {
        path: 'work-category-payout-detail/:id',
        outlet: 'workCategoryPayoutOutlet',
        component: WorkCategoryPayoutComponent,
        resolve: {
          selectListsLoaded: SelectionListResolver,
        }
      }
    ]
  },
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'user-access-manager/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    resolve: {
      selectListsLoaded: SelectionListResolver
    },
    component: UserAccessManagerComponent
  },
  {
    path: 'admin-role-widgets/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: RoleWidgetsComponent
  },
  {
    path: 'admin-role-users/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: RoleUsersComponent
  },
  {
    path: 'admin-role-privilege-templates/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: RolePrivilegeTemplatesComponent
  },
  {
    path: 'admin-pt-roles/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: PrivilegeTemplateRolesComponent
  },
  {
    path: 'admin-pt-privs/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: PrivilegeTemplatePrivilegesComponent,
    children: [
      {
        path: 'privilege-template-privilege/:id',
        resolve: {
          selectListsLoaded: SelectionListResolver
        },
        outlet: 'privTempPrivOutlet',
        component: PrivilegeTemplatePrivilegeComponent
      }
    ]
  },
  {
    path: 'admin-pres-used/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: FiltersWhereUsedComponent
  },
  {
    path: 'admin-priv-used/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: PrivilegesWhereUsedComponent
  },
  {
    path: 'sku-payouts/:id/:gcId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    data: {
      storeName: 'skus',
      controller: 'Sku'
    },
    resolve: {
      hasMetaData: MetaDataResolver
    },
    component: SkuPayoutsComponent
  },
  {
    path: 'contact-mech-category-types/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ContactMechanismCategoryTypesComponent,
    children: [
      {
        path: 'contact-mech-category-type/:id',
        outlet: 'cmctOutlet',
        component: ContactMechanismCategoryTypeComponent
      }
    ]
  },
  {
    path: 'contact-type-specs/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ContactTypeSpecificationsComponent,
    children: [
      {
        path: 'contact-type-spec/:id',
        outlet: 'typeSpecOutlet',
        component: ContactTypeSpecificationComponent,
        resolve: {
          selectListsLoaded: SelectionListResolver
        }
      }
    ]
  },
  {
    path: 'gc-branches/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: BranchesComponent
  },
  {
    path: 'gc-programs/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ProgramsComponent
  },
  {
    path: 'gc-regions/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: RegionsComponent
  },
  {
    path: 'branch-contact/:id/:parentId/:typeId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: AdminContactComponent,
    data: {
      contactTypeId: '2'
    },
    title: 'branch-contact-modal',
    resolve: {
      hasContactMetaData: ContactMetaDataResolver,
      hasTypeData: ContactTypeSpecificationResolver,
      hadPhoneMetaData: ContactPhoneMetaDataResolver,
      hasEmailMetaData: ContactEmailMetaDataResolver,
      hasAddrMetaData: ContactAddressMetaDataResolver
    },
  },
  {
    path: 'branch-pls/:branchId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ProviderLocationBranchesComponent
  },
  {
    path: 'branch-pgms/:branchId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: BranchProgramsComponent
  }, 
  {
    path: 'branch-regions/:branchId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: BranchRegionsComponent
  },
  {
    path: 'branch-notes/:branchId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: BranchNotesComponent
  },
  {
    path: 'cb-sku-prices/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ChargeBackSkuPricesComponent,
    children: [
      {
        path: 'cb-sku-price/:id',
        outlet: 'cbSkuPriceOutlet',
        component: ChargeBackSkuPriceManagerComponent
      }
    ]
  },
  {
    path: 'pgm-svcs/:programId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ProgramServicesComponent
  },
  {
    path: 'pgm-branches/:programId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: BranchProgramsComponent
  },
  {
    path: 'ps-branch-pgms/:psId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: BranchProgramsComponent
  },
  {
    path: 'ps-files/:psId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ProgramServiceFilesComponent
  },
  {
    path: 'region-branches/:regionId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: BranchRegionsComponent
  },
  {
    path: 'svc-pgms/:serviceId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ProgramServicesComponent
  },
  {
    path: 'svc-locs/:serviceId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ProviderLocationServicesComponent,
    children: [
      {
        path: 'loc-svc/:id',
        outlet: 'locSvcOutlet',
        component: ProviderLocationServiceComponent,
        resolve: {
          selectListsLoaded: SelectionListResolver
        },
      }
    ]
  },
  {
    path: 'location-contact/:id/:parentId/:typeId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: AdminContactComponent,
    data: {
      contactTypeId: '3'
    },
    title: 'location-contact-modal',
    resolve: {
      hasContactMetaData: ContactMetaDataResolver,
      hasTypeData: ContactTypeSpecificationResolver,
      hadPhoneMetaData: ContactPhoneMetaDataResolver,
      hasEmailMetaData: ContactEmailMetaDataResolver,
      hasAddrMetaData: ContactAddressMetaDataResolver
    },
  },
  {
    path: 'location-hours/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ProviderLocationSchedulesComponent
  },
 {
    path: 'loc-branches/:plId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ProviderLocationBranchesComponent
  },
  {
    path: 'loc-svcs/:plId',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: ProviderLocationServicesComponent,
    children: [
      {
        path: 'loc-svc/:id',
        outlet: 'locSvcOutlet',
        component: ProviderLocationServiceComponent,
        resolve: {
          selectListsLoaded: SelectionListResolver
        },
      }
    ]
  },
  {
    path: 'file-upload/:id/:parentId/:actionId/:type',
    outlet: 'modal',
    resolve: {
      docTypesLoaded: DocumentTypeResolver
    },
    component: FileUploadManagerComponent
  },
  {
    path: 'survey-questions/:id',
    outlet: 'modal',
    component: SurveyQuestionsComponent,
    children: [
      {
        path: 'survey-question/:id',
        resolve: {
          selectListsLoaded: SelectionListResolver
        },
        outlet: 'surveyQuestionOutlet',
        component: SurveyQuestionComponent
      }
    ]
  },
  {
    path: 'survey-pgm-services/:id',
    outlet: 'modal',
    component: SurveyProgramServicesComponent,
    children: [
      {
        path: 'survey-pgm-svc/:id',
        resolve: {
          selectListsLoaded: SelectionListResolver
        },
        outlet: 'surveyPgmSvcOutlet',
        component: SurveyProgramServiceComponent
      }
    ]
  },
  {
    path: 'warranty-sub-types/:id',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: WarrantySubTypesComponent,
    children: [
      {
        path: 'warranty-sub-type/:id',
        outlet: 'warrantySubTypeOutlet',
        component: WarrantySubTypeComponent
      }
    ]
  },
  {
    path: 'shipper-locations/:id',
    outlet: 'modal',
    component: ShipperLocationsComponent,
    children: [
      {
        path: 'shipper-location/:id',
        resolve: {
          selectListsLoaded: SelectionListResolver
        },
        outlet: 'shipperLocationOutlet',
        component: ShipperLocationComponent
      }
    ]
  },
  {
    path: 'shipper-payouts/:id',
    outlet: 'modal',
    component: ShipperPayoutsComponent,
    children: [
      {
        path: 'shipper-payout/:id',
        resolve: {
          payoutsLoaded: MaterialPayoutResolver,
          selectListsLoaded: SelectionListResolver
        },
        outlet: 'shipperPayoutOutlet',
        component: ShipperPayoutComponent
      }
    ]
  },
  {
    path: 'payout-skus/:id',
    outlet: 'modal',
    component: PayoutSkusComponent,
    data: { //parent info
      storeName: 'providerPayouts',
      controller: 'Payout'
    },
    resolve: {
      selectListsLoaded: SelectionListResolver
    }
  },
  {
    path: 'payout-prices/:id',
    outlet: 'modal',
    component: PayoutPricesComponent,
    data: { //parent info
      storeName: 'providerPayouts',
      controller: 'Payout'
    },
    resolve: {
      selectListsLoaded: SelectionListResolver
    },
    children: [
      {
        path: 'payout-price/:id',
        outlet: 'payoutPriceOutlet',
        component: PayoutPriceComponent,
        resolve: {
          selectListsLoaded: SelectionListResolver,
          providerlocations: AllMyProviderLocationsResolver
        }
     }
    ]
  },
  {
    path: 'loading2/:container',
    outlet: 'modal2',
    component: LoadingPageComponent
  },
  {
    path: 'sku-payout-link/:skuId',
    outlet: 'modal2',
    data: {
      storeName: 'payouts',
      controller: 'Payout',
      alwaysGet: true,
      setListMetaData: true
    },
    resolve: {
      hasMetaData: MetaDataResolver
    },
    component: SkuPayoutLinkComponent
  },
  {
    path: 'payout-sku-link/:payoutId',
    outlet: 'modal2',
    data: {
      storeName: 'skus',
      controller: 'Sku',
      alwaysGet: true,
      setListMetaData: true
    },
    resolve: {
      hasMetaData: MetaDataResolver
    },
    component: SkuPayoutLinkComponent
  },
  {
    path: 'security-entity-manager/:id',
    outlet: 'modal2',
    canActivate: [AuthGuard],
    component: RelatedEntityManagerComponent,
    resolve: {
      hasMetaData: AllProviderUsersResolver
    },
  },
  {
    path: 'related-entity-manager/:id',
    outlet: 'modal2',
    canActivate: [AuthGuard],
    component: RelatedEntityManagerComponent
  },
  {
    path: 'ptp-values/:id',
    outlet: 'modal2',
    canActivate: [AuthGuard],
    component: PrivilegeTemplatePrivilegeValuesComponent,
    children: [
      {
        path: 'ptp-value/:id',
        resolve: {
          selectListsLoaded: SelectionListResolver
        },
        outlet: 'ptprvOutlet',
        component: PrivilegeTemplatePrivilegeRuleValueComponent
      }
    ]
  },
  {
    path: 'survey-proj-map-fields/:id',
    outlet: 'modal2',
    component: SurveyProjectMapFieldsComponent,
    children: [
      {
        path: 'survey-proj-map-field/:id',
        resolve: {
          entitiesLoaded: ProjectEntityResolver,
          selectListsLoaded: SelectionListResolver
        },
        outlet: 'surveyProjMapFieldOutlet',
        component: SurveyProjectMapFieldComponent
      }
    ]
  },
  {
    path: 'survey-import-mappings/:id',
    outlet: 'modal2',
    component: SurveyImportMappingsComponent
  },
  {
    path: 'shipper-location-payouts/:id/:shipperId',
    outlet: 'modal2',
    component: ShipperLocationPayoutsComponent,
    children: [
      {
        path: 'shipper-location-payout/:id',
        resolve: {
          locationPayouts: ShipperLocationPayoutResolver,
          selectListsLoaded: SelectionListResolver   
        },
        outlet: 'shipperLocationPayoutOutlet',
        component: ShipperLocationPayoutComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
