<div [ngClass]="{ 'loading-mask': loading$ | async }">
  <ng-container *ngIf="(showExceptionReasons$ | async) && (poList$ | async)">
    <lswp-override-reasons [data]="poList$ | async" (changeEvent)="changeReason($event)"></lswp-override-reasons>
    <div class="app-btn-box">
      <button type="button" class="app-btn app-btn--cancel"
              [disabled]="(!posToChange || posToChange.length === 0)"
              (click)="onSave()">
        {{ pushToGcPreviouslyCompleted ? 'Save and Push' : 'Save and Next' }}
      </button>
      <button *ngIf="!pushToGcPreviouslyCompleted" type="button" class="app-btn app-btn--cancel"
              [disabled]="(posToChange && posToChange.length > 0) || !entityDocument.fileUrl"
              (click)="onNext()">
        Next
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="!(showExceptionReasons$ | async)">
    <fw-component-title [title]="pushToGcPreviouslyCompleted ? 'Push Document for Outstanding Purchase Orders' : 'Purchase Orders To Process'"></fw-component-title>
    <ng-container *ngFor="let po of (poList$ | async)">
      <lswp-purchase-order [item]="po"></lswp-purchase-order>
    </ng-container>
    <div *ngIf="pushToGcPreviouslyCompleted" class="app-btn-box">
      <button type="button" class="app-btn app-btn--cancel"  (click)="onPushToGc()">
        Push Existing For New Pos
      </button>
    </div>
  </ng-container>

  <router-outlet name="lswpOutlet"></router-outlet>

  <div *ngIf="(infoMsg$ | async) as infoMsg" class="alert-box alert-box--info">
    {{ infoMsg }}
  </div>
  <div *ngIf="(staticError$ | async) as staticError" class="alert-box alert-box--danger">
    {{ staticError }}
  </div>
  <fw-error-box [errorData]="errorData$ | async" [errors]="errors$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>
