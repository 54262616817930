<fw-component-title [title]="'Log New Customer Action'"></fw-component-title>
<div *ngIf="logForm && noteForm" class="nested-panel">
  <fw-section-title title="Call Response" [underline]="true" icon="fa-file-signature"></fw-section-title>
  <form [formGroup]="logForm" novalidate class="form-wrapper form-wrapper--edit">
    <fw-dynamic-field [field]="mds.getFieldDefinition('callLogResponse')"
                      [form]="logForm"
                      [operation]="operation"
                      [selectItems]="responseTypes$ | async">
    </fw-dynamic-field>
    <p *ngIf="noteRequired$ | async" class="call-log-info"><span class="app-icon call-log-icon"><i class="fas fa-info-circle"></i></span>A note is required with this response type.</p>
  </form>

  <div *ngIf="noteFormDef">

    <fw-section-title title="Response Note" [underline]="true" icon="fa-sticky-note"></fw-section-title>
    <div class="form-wrapper form-wrapper--edit">
      <ng-container *ngIf="providerUsers">
        <note-recipients [providerUsers]="providerUsers"
                         [isDisabled]="disableRecipients$ | async"
                         (recipientEvent)="onRecipientEvent($event)">
        </note-recipients>
      </ng-container>
      <fw-dynamic-field *ngFor="let key of noteFields"
                        [field]="noteMds.getFieldDefinition(key)"
                        [selectItems]="noteMds.getSelectItems(key)"
                        [form]="noteForm"
                        [operation]="operation">
      </fw-dynamic-field>
    <p class="call-log-info"><span class="app-icon call-log-icon"><i class="fas fa-info-circle"></i></span>If a note is NOT required and you do not enter in all the required note fields, you can still create the call log, but a note will NOT be created.</p>
    </div>
  </div>
  <fw-crud-buttons [operation]="operation"
                   [disabled]="!logForm.valid || ((noteRequired$ | async) && !noteForm.valid)"
                   showNext="false"
                   (cancelEvent)="cancel()"
                   (createEvent)="create()">
  </fw-crud-buttons>
  <ng-content></ng-content>
</div>
