<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <div [ngClass]="{ 'loading-mask': (working$ | async)}">
    <charge-back-sku-price [object]="chargeBackSkuPrice"
                           [operation]="operation"
                           [installers]="allInstallers$ | async"
                           [locations]="allLocations$ | async"
                           [services]="allServices$ | async"
                           [locationServices]="locationServices$ | async"
                           (customEvent)="onCustomEvent($event)">
    </charge-back-sku-price>


    <div *ngIf="operation !== myConstants.operationTypeDetails" class="app-btn-box">
      <button type="button"
              class="app-btn app-btn--cancel"
              (click)="onCancel()"
              title="Cancel">
        Cancel
      </button>
      <button class="app-btn app-btn--primary"
              type="button"
              (click)="onSave()"
              [disabled]="!(isValid$ | async)">
        {{ operation === myConstants.operationTypeCreate ? 'Create' : 'Save' }}
      </button>
    </div>

    <fw-error-box [errorData]="errorData$ | async" [errors]="errors$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
  </div>
</fw-dynamic-detail-container>
