import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IMultiTierMenu } from '../interfaces/i-multi-tier-menu';
import { IMultiTierOuput } from '../interfaces/i-multi-tier-output';

@Component({
    selector: 'fw-multi-tier-menu',
    templateUrl: './fw-multi-tier-menu.component.html'
}) export class MultiTierMenuComponent {

   @Input() menu: IMultiTierMenu[]; 
   @Input() label: string = '';
   @Input() disabled: boolean = false;
   @Input() hoverText: string = '';
   @Input() fix: boolean;
   @Input() customMultiBtnCss: string = '';
   @Output() public selected: EventEmitter<IMultiTierOuput> = new EventEmitter();

  public level1Idx: number; 
  public level2Idx: number;
  public open: boolean = false;
  activeLevel: number = 0;

  public openOrClose(open: boolean): void {
      if (this.open) {
        this.level1Idx = null;
        this.level2Idx = null;
    }
    this.open = open;
  }

    public initIdx(obj: any): void {
        const level: number = this['level' + obj.level + 'Idx'];
        if(obj.level < this.activeLevel) this['level' + this.activeLevel + 'Idx'] = null;
        this.activeLevel = obj.level;
        this['level' + obj.level + 'Idx'] = (level === obj.idx ? null : obj.idx);
    }

   public initSelected(output: IMultiTierOuput) {
     this.selected.emit(output);
     this.openOrClose(false);
   }

}
