import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppActionResultComponent, LoadingPageComponent } from './components';

//Primarily Modal Routes from shared components
const portalRoutes: Routes = [
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'app-action/:type',
    outlet: 'modal',
    component: AppActionResultComponent,
  },
  {
    path: 'loading2/:container',
    outlet: 'modal2',
    component: LoadingPageComponent
  },
  {
    path: 'app-action/:type',
    outlet: 'modal2',
    component: AppActionResultComponent,
  },
  {
    path: 'app-action-msg/:type/:message',
    outlet: 'modal',
    component: AppActionResultComponent,
  },
  {
    path: 'app-action-msg/:type/:message',
    outlet: 'modal2',
    component: AppActionResultComponent,
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
