import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';

import { IListColumn, ListColumn, ListDefinition, IListFilter, ListFilter, IListButtonType, OrderTerm } from '../../../../fw/dynamic-list/interfaces/index';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { PoImportEvent, PoImportStore } from '../enums/po-import.enums';
import { IBranchNameBranchPrograms } from '../interfaces/i-branch-name-branch-programs';
import { IBranchProgram } from '../../view-models';
import { IResponseBase, IHomDictionary } from '../../../../shared/interfaces';
import { DomainObjectService } from '../../../../shared/services';
import { HomErrorHandlerService } from 'hom-lib/hom-error-logger';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { DynamicListMethod } from '../../../../fw/dynamic-list/enums/dynamic-list.enum';
import { appConstants, IAppConstants } from '../../../../shared/constants';

@Injectable()
export class PoImportService {
  public branchProgramsByName: IBranchNameBranchPrograms[] = [];

  constructor(
    private domainObjectService: DomainObjectService,
    private homErrorHandlerService: HomErrorHandlerService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  //List Definition for po-import-header records associated wtih this general contractor grouped by vendorOrderNumber + branchName
  loadPoImportHeaderViewModelListDefinition(generalContractorId: number, onlyErrors: boolean = false, forProject: boolean = false) {
    const listColumns = this.loadPoImportHeaderViewModelListColumns(onlyErrors, forProject);
    const listObjectLabel = 'Branch - Vendor Po';
    const listObjectController = 'PoImportHeaderViewModel';
    const listStoreName = onlyErrors ? PoImportStore.poImportGroupedErrors : PoImportStore.poImportGroupedHeaders;
    const detailRoutePath = 'po-import-headers';
    const listRowKeyId = 'uniqueId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = onlyErrors ? [{ term: 'poImportStatus', value: 'Error', searchType: SearchType.Equals, columnName: 'poImportStats', fieldType: this.myConstants.dataTypeString }] : [];
    defaultListFilter.orderTerm = [new OrderTerm('orderDate')];

    let listDefinition = this.dynamicListService.createListDefinition('poImportHeadersOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.methodParameters = generalContractorId.toString();
    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.showPrevNext = true;
    listDefinition.allowSaveFilter = true;

    if (!onlyErrors) {
      listDefinition.toolButtons.splice(0, 0, 
        {
          title: 'Download and Import',
          icon: 'fas fa-cloud-download',
          cssName: 'app-btn-icon--primary',
          enabled: true,
          eventName: PoImportEvent.showDownloadAndImport
        });
    }

    const delDefaults: ICustomButton = {
      title: '',
      icon: '',
      cssName: '',
      enabled: true,
      enabledMethod: 'canDeletePoImport',
      eventName: PoImportEvent.deletePoImport,
      methodService: 'poImportDynamicListService'
    };
    const viewDefaults: ICustomButton = {
      title: '',
      icon: '',
      cssName: '',
      enabled: true,
      eventName: '',
     enabledMethod: DynamicListMethod.alwaysEnabled
    };

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: viewDefaults },
      { type: ButtonType.delete, defaults: delDefaults }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    let customBtns: ICustomButton[] = [];
    if (!forProject) {
      if (!onlyErrors) {
        listDefinition.rowButtons.splice(0, 0,
          {
            title: 'Go To Project',
            icon: 'fad fa-share-square',
            cssName: 'app-btn-icon--custom-3',
            enabled: true,
            eventName: PoImportEvent.goToProject,
            enabledMethod: 'canGoToProject',
            methodService: 'poImportDynamicListService'
          });
      }
      listDefinition.rowButtons.splice(0, 0,
        {
          title: 'Set/View Contact Information',
          icon: 'fas fa-address-card',
          cssName: 'app-btn-icon--custom-1',
          enabled: true,
          eventName: PoImportEvent.viewContactInformation
        }
      );
    }

    listDefinition.groupSelect = {
      title: 'Import Purchase Order',
      enabled: true,
      allowSelectAll: false,
      enabledMethod: 'canImportPo',
      eventName: PoImportEvent.importPurchaseOrder,
      methodService: 'poImportDynamicListService'
    };

    listDefinition.rowStatus = {
      cssMethod: 'getPoLineRowCss',
      methodService: 'poImportDynamicListService'
    };

    return listDefinition;
  }

  loadPoImportHeaderViewModelListColumns(onlyErrors: boolean, forProject: boolean): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('userName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('orderDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('branchName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerFirstName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerLastName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('addressLine1');
    columnDefinitions.push(colDef);

    if (!onlyErrors) {
      colDef = new ListColumn('poImportStatus');
      columnDefinitions.push(colDef);
    }

    colDef = new ListColumn('poImportStats');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('poImportErrorText');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('hasLinkedCustomer');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    if (!forProject) {
      colDef = new ListColumn('importToProjectIds');
      columnDefinitions.push(colDef);
    }

    return columnDefinitions;
  }

  //List Definition for po-import-header records associated this this vendorOrderName and branch
  //Drill down from grouped po-import-header records
  loadPoImportHeaderListDefinition(vendorOrderNumber: string, branchName: string) {
    const listColumns = this.loadPoImportHeaderListColumns();
    const listObjectLabel = 'Incoming Purchase Order';
    const listObjectController = 'PoImportHeader';
    const listStoreName = PoImportStore.poImportHeaders;
    //for both displaying the list of lines (detail) and edit of a header record
    const detailRoutePath = 'po-import-header';
    const listRowKeyId = 'poImportHeaderId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('vendorOrderNumber')];

    let listDefinition = this.dynamicListService.createListDefinition('poImportHeaderOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByVendorOrder';
    listDefinition.methodParameters = vendorOrderNumber + '?branch=' + branchName;

    //will always reload this list, no merging, destroy on destroy of list control
    listDefinition.parentId = -1;
    listDefinition.parentKey = '';

    listDefinition.showFilter = false;
    listDefinition.showPrevNext = true;
    listDefinition.allowSaveFilter = false;

    //use defaults for title, icon and style
    const deleteCustom: ICustomButton = {
      title: '',
      icon: '',
      cssName: '',
      enabled: true,
      eventName: PoImportEvent.deletePoImportHeader,
      enabledMethod: 'canDeletePoImportHeader',
      methodService: 'poImportDynamicListService'
    };

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: deleteCustom }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowStatus = {
      cssMethod: 'getPoLineRowCss',
      methodService: 'poImportDynamicListService'
    };

    return listDefinition;
  }

  loadPoImportHeaderListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('poNumber');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('programName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('branchProgram');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('orderTotal');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('poImportStatus');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('poImportStatusText');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPoImportLineListDefinition(poImportHeaderId: number) {
    const listColumns = this.loadPoImportLinesListColumns();
    const listObjectLabel = 'Purchase Order Line';
    const listObjectController = 'PoImportLine';
    const listStoreName = PoImportStore.poImportLines;
    const detailRoutePath = 'po-import-line';
    const listRowKeyId = 'poImportLineId';
    const defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition('poImportLineOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByPoImportHeader';
    listDefinition.methodParameters = poImportHeaderId.toString();
    listDefinition.parentId = poImportHeaderId;
    listDefinition.parentKey = 'poImportHeader_poImportHeaderId';

    listDefinition.showFilter = false;
    listDefinition.showPrevNext = true;
    listDefinition.allowSaveFilter = false;

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
      title: 'Pricing Wizard',
      icon: 'fas fa-wand-magic',
      cssName: 'app-btn-icon--custom-1',
      enabled: true,
      eventName: PoImportEvent.showPricingWizard,
      enabledMethod: 'canSeePriceWizard',
      methodService: 'poImportDynamicListService'
      }
    );

    listDefinition.rowStatus = {
      cssMethod: 'getPoLineRowCss',
      methodService: 'poImportDynamicListService'
    };

    return listDefinition;
  }

  loadPoImportLinesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('itemName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('quantity');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitPrice');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitOfMeasure');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitMeasure');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isCustom');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('overrideSystemPrice');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('poImportStatus');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('poImportStatusText');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  setBranchProgramsByBranchName(branchName: string, branchPrograms: IBranchProgram[]) {
    this.branchProgramsByName.push({ branchName: branchName, branchPrograms: branchPrograms });
  }

  getBranchProgramsByBranchName(branchName: string): IBranchProgram[] {
    const row: IBranchNameBranchPrograms = this.branchProgramsByName.find(x => x.branchName == branchName);
    return row ? row.branchPrograms : null;
 }

  removeMyPLS(branchName: string) {
    const exists: number = this.branchProgramsByName.findIndex(x => x.branchName == branchName);
    if (exists >= 0) {
      this.branchProgramsByName.splice(exists, 1);
    }
  }

  requestBranchProgramsByBranchName(branchName: string, gcId: number): Observable<IBranchProgram[]> {
    let params: IHomDictionary[] = [
      { key: 'id', value: branchName },
      { key: 'generalContractorId', value: gcId.toString() }
    ];
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    return this.domainObjectService.getByMethodParams('BranchProgram', 'ByBranchName', params, listFilter)
      .pipe(
        map((result: IResponseBase) => {
          if (result.success) {
            this.setBranchProgramsByBranchName(branchName, result.data);
            return result.data;
          } else {
            this.homErrorHandlerService.handleError({
              name: 'PoImportService',
              message: 'Unable to retrieve branch programs for this branch name: ' + branchName + ' and gc: ' + gcId.toString()
            });
            return of([])
          }

          return true;
        }));
  }


}
