import { Component, OnInit,  Input, Output, EventEmitter} from '@angular/core';
import { IPoLswpOverrideReason } from '../../interfaces';
import { LswpProcessingStatus } from '../../enums/lswp-processing-status.enums';
import { IEnum } from '../../../view-models';
import { HomCommonUtility } from '../../../../../shared/services';

@Component({
  selector: 'lswp-override-reason',
  templateUrl: './lswp-override-reason.component.html'
})
export class LswpOverrideReasonComponent implements OnInit {
  @Input() item: IPoLswpOverrideReason;
  @Input() reasonCodes: IEnum[];

  @Output() public changeEvent = new EventEmitter<number>();

  isWorking: string = '';
  isSuccess: string = '';
  isDisabled: string = '';
  inError: string = '';
  isCompleted: string = '';
  isCanceled: string = '';
  isSet: string = '';

  constructor(public homUtil: HomCommonUtility) { }

  public changeReason(id: number) {
    this.changeEvent.emit(id);
  }

  ngOnInit() {
    this.isWorking = LswpProcessingStatus.working;
    this.isSuccess = LswpProcessingStatus.success;
    this.isDisabled = LswpProcessingStatus.disabled;
    this.inError = LswpProcessingStatus.error;
    this.isCompleted = LswpProcessingStatus.completed;
    this.isCanceled = LswpProcessingStatus.canceled;
    this.isSet = LswpProcessingStatus.set;
  }


}
