import { Action } from '@ngrx/store';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IResponseBase, IErrorData, IListMetaData } from '../../../../shared/interfaces/index';
import { IListFilter, ListDefinition, IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';
import {
  CreateObjectModel,
  UpdateObjectByIdModel,
  DeleteObjectModel,
  IKey,
  UpdateObjectCustomModel,
  UpdateObjectByUrl
}
  from '../interfaces/index';

import { ISplitOnConfig } from '../interfaces/i-split-on-config';

//** ENSURE THESE actions are UNIQUE across ALL actions in the ENTIRE project **//
//** Add namespace by prefixing with [store type]
export enum DynamicListActionTypes {
  SET_LIST_DEFINITION = '[DynamicList] SET_LIST_DEFINITION',
  SET_LIST_FILTER = '[DynamicList] SET_LIST_FILTER',
  GET_LIST = '[DynamicList] GET_LIST', //GENERIC will determine based on list def if should get list by meta url, provider, method
  SET_LIST_RESULTS = '[DynamicList] SET_LIST_RESULTS',
  SET_LIST_LOAD_FAILURE = '[DynamicList] SET_LIST_LOAD_FAILURE',

  CREATE_OBJECT_LIST = '[DynamicList] CREATE_OBJECT_LIST',
  CREATE_OBJECT_BYFORM_LIST = '[DynamicList] CREATE_OBJECT_BYFORM_LIST',
  CREATE_COMPLETE_LIST = '[DynamicList] CREATE_COMPLETE_LIST',
  UPDATE_OBJECT_BY_ID_LIST = '[DynamicList] UPDATE_OBJECT_BY_ID_LIST',
  UPDATE_OBJECT_BY_URL_LIST = '[DynamicList] UPDATE_OBJECT_BY_URL_LIST',
  UPDATE_OBJECT_CUSTOM_LIST = '[DynamicList] UPDATE_OBJECT_CUSTOM_LIST',
  UPDATE_OBJECT_BYFORM_LIST = '[DynamicList] UPDATE_OBJECT_BYFORM_LIST',
  UPDATE_COMPLETE_LIST = '[DynamicList] UPDATE_COMPLETE_LIST',
  DELETE_OBJECT_BY_URL_LIST = '[DynamicList] DELETE_OBJECT_BY_URL_LIST',
  DELETE_COMPLETE_LIST = '[DynamicList] DELETE_COMPLETE_LIST',

  CLEAR_EVENT_LIST = '[DynamicList] CLEAR_EVENT_LIST',
  SET_EVENT_LIST = '[DynamicList] SET_EVENT_LIST',
  SET_WORKING_LIST = '[DynamicList] SET_WORKING_LIST',

  CHECK_FOR_STALE_DATA_LIST = '[DynamicList] CHECK_FOR_STALE_DATA_LIST',
  SET_STALE_DATA_CHECK_RESULT_LIST = '[DynamicList] SET_STALE_DATA_CHECK_RESULT_LIST',
  CLEAR_ERRORS_LIST = '[DynamicList] CLEAR_ERRORS',
  SET_CALCULATED_PROPERTY = '[DynamicList] SET_CALCULATED_PROPERTY',

  INITIALIZE_LIST = '[DynamicList] INITIALIZE_LIST',
  INITIALIZE_LIST_DATA = '[DynamicList] INITIALIZE_LIST_DATA',

  SET_ERROR_RETURNED_LIST = '[DynamicList] SET_ERROR_RETURNED_LIST',
  SET_LIST_META_DATA = '[DynamicList] SET_LIST_META_DATA',
  RESET_ALL_LIST_STORES = '[DynamicList] RESET_ALL_LIST_STORES',
  SORT_IN_MEMORY = '[DynamicList] SORT_IN_MEMORY',
  SORT_IN_MEMORY_COMPLETE = '[DynamicList] SORT_IN_MEMORY_COMPLETE',
  SPLIT_BY_PARENT = '[DynamicList] SPLIT_BY_PARENT',
}
//fetch types direct us to what method to call and what properties needed to make that call
//for all list retrievals will have filter data
//if have incoming params for this action, include in the constructor for the class for that action

export class SetListDefinition implements Action {
  readonly type = DynamicListActionTypes.SET_LIST_DEFINITION;
  constructor(
    public payload: { storeName: string, parentId: number, listDefinition: ListDefinition }
  ) { }
}

export class SetListFilter implements Action {
  readonly type = DynamicListActionTypes.SET_LIST_FILTER;
  constructor(
    public payload: { storeName: string, listFilter: IListFilter, parentId: number }
  ) { }
}

export class SetListResults implements Action {
  readonly type = DynamicListActionTypes.SET_LIST_RESULTS;
  constructor(
    public payload: {
      storeName: string,
      parentId: number,
      data: any[],
      listMetaData: IListMetaData,
      errorData: IErrorData[],
      error: string,
      event?: IHomEventEmitter,
      lastUpdated?: string,
      splitKey?: string
    }
  ) { }
}

export class GetList implements Action {
  readonly type = DynamicListActionTypes.GET_LIST;
  constructor(
    public payload: { listDefinition: ListDefinition, listFilter: IListFilter, parentId: number, event?: IHomEventEmitter, splitOnConfig?: ISplitOnConfig }
  ) { }
}

export class CreateObjectList implements Action {
  readonly type = DynamicListActionTypes.CREATE_OBJECT_LIST;
  constructor(
    public payload: { createData: CreateObjectModel }  //create data  has storeName
  ) { }
}

export class CreateObjectByFormList implements Action {
  readonly type = DynamicListActionTypes.CREATE_OBJECT_BYFORM_LIST;
  constructor(
    public payload: { createData: CreateObjectModel }  //create data  has storeName
  ) { }
}

export class CreateCompleteList implements Action {
  readonly type = DynamicListActionTypes.CREATE_COMPLETE_LIST;
  constructor(
    public payload: { storeName: string, parentId: number, responseBase: IResponseBase, event: IHomEventEmitter }
  ) { }
}

export class UpdateObjectByIdList implements Action {
  readonly type = DynamicListActionTypes.UPDATE_OBJECT_BY_ID_LIST;
  constructor(
    public payload: { updateData: UpdateObjectByIdModel }
  ) { }
}

export class UpdateObjectByUrlList implements Action {
  readonly type = DynamicListActionTypes.UPDATE_OBJECT_BY_URL_LIST;
  constructor(
    public payload: { updateData: UpdateObjectByUrl }
  ) { }
}

export class UpdateObjectCustomList implements Action {
  readonly type = DynamicListActionTypes.UPDATE_OBJECT_CUSTOM_LIST;
  constructor(
    public payload: { updateData: UpdateObjectCustomModel }
  ) { }

}

export class UpdateObjectByFormList implements Action {
  readonly type = DynamicListActionTypes.UPDATE_OBJECT_BYFORM_LIST;
  constructor(
    public payload: { updateData: UpdateObjectCustomModel }
  ) { }

}

export class UpdateCompleteList implements Action {
  readonly type = DynamicListActionTypes.UPDATE_COMPLETE_LIST;
  constructor(
    public payload: { keyData: IKey, responseBase: IResponseBase, event: IHomEventEmitter }
  ) { }

}

export class DeleteObjectByUrlList implements Action {
  readonly type = DynamicListActionTypes.DELETE_OBJECT_BY_URL_LIST;
  constructor(
    public payload: { deleteData: DeleteObjectModel }
  ) { }

}

export class DeleteCompleteList implements Action {
  readonly type = DynamicListActionTypes.DELETE_COMPLETE_LIST;
  constructor(
    public payload: { keyData: IKey, responseBase: IResponseBase, event: IHomEventEmitter }
  ) { }

}

export class SetListLoadFailure implements Action {
  readonly type = DynamicListActionTypes.SET_LIST_LOAD_FAILURE;
  constructor(
    public payload: { storeName: string, parentId: number, error: string }
  ) { }
}

export class SetErrorReturnedList implements Action {
  readonly type = DynamicListActionTypes.SET_ERROR_RETURNED_LIST;
  constructor(
    public payload: { storeName: string, parentId: number, error: string }
  ) { }
}


export class CheckForStaleData implements Action {
  readonly type = DynamicListActionTypes.CHECK_FOR_STALE_DATA_LIST;
  constructor(
    public payload: { storeName: string, parentId: number, isAggregate: boolean, entityId: number, aggregateRootVersion: string }
  ) { }
}


export class SetStaleDataErrorList implements Action {
  readonly type = DynamicListActionTypes.SET_STALE_DATA_CHECK_RESULT_LIST
  constructor(
    public payload: { storeName: string, parentId: number, forRow: boolean, responseBase: IResponseBase }
  ) { }
}


export class SetWorkingList implements Action {
  readonly type = DynamicListActionTypes.SET_WORKING_LIST;
  constructor(
    public payload: { storeName: string, parentId: number, working: boolean }
  ) { }
}

export class InitializeList implements Action {
  readonly type = DynamicListActionTypes.INITIALIZE_LIST;
  constructor(
    public payload: { storeName: string, parentId: number, forOnDestroy: boolean }
  ) { }
}

export class InitializeListData implements Action {
  readonly type = DynamicListActionTypes.INITIALIZE_LIST_DATA;
  constructor(
    public payload: { storeName: string, parentId: number }
  ) { }
}

export class ClearEventList implements Action {
  readonly type = DynamicListActionTypes.CLEAR_EVENT_LIST;
  constructor(
    public payload: { storeName: string, parentId: number }
  ) { }
}

export class SetEventList implements Action {
  readonly type = DynamicListActionTypes.SET_EVENT_LIST;
  constructor(
    public payload: { storeName: string, parentId: number, event: IHomEventEmitter }
  ) { }
}

export class ClearErrorsList implements Action {
  readonly type = DynamicListActionTypes.CLEAR_ERRORS_LIST;
  constructor(
    public payload: { storeName: string, parentId: number }
  ) { }
}


export class SetCalculatedProperty implements Action {
  readonly type = DynamicListActionTypes.SET_CALCULATED_PROPERTY;
  constructor(
    public payload: { keyData: IKey, propertyName: string, propertyValue: any }
  ) { }
}

export class SetListMetaData implements Action {
  readonly type = DynamicListActionTypes.SET_LIST_META_DATA;
  constructor(
    public payload: { storeName: string, parentId: number, metaData: any }
  ) { }
}

export class ResetAllListStores implements Action {
  readonly type = DynamicListActionTypes.RESET_ALL_LIST_STORES;
}

export class SortInMemory implements Action {
  readonly type = DynamicListActionTypes.SORT_IN_MEMORY;
  constructor(
    public payload: { listDefinition: IListDefinition, listFilter: IListFilter, event: IHomEventEmitter, data: any[] }
  ) { }
}

export class SortInMemoryComplete implements Action {
  readonly type = DynamicListActionTypes.SORT_IN_MEMORY_COMPLETE;
  constructor(
    public payload: { storeName: string, parentId: number, listFilter: IListFilter, event: IHomEventEmitter, data: any[] }
  ) { }
}

export class SplitByParent implements Action {
  readonly type = DynamicListActionTypes.SPLIT_BY_PARENT;
  constructor(
    public payload: { storeName: string, splitOnConfig: ISplitOnConfig }
  ) { }
}

export type DynamicListActions = SetListDefinition |
  SetListFilter |
  GetList |
  SetListResults |
  UpdateObjectByIdList |
  DeleteObjectByUrlList |
  CreateObjectList |
  CreateCompleteList |
  UpdateCompleteList |
  DeleteCompleteList |
  SetListLoadFailure |
  UpdateObjectByUrlList |
  UpdateObjectCustomList |
  SetErrorReturnedList |
  SetStaleDataErrorList |
  InitializeList |
  InitializeListData |
  ClearEventList |
  SetEventList |
  SetWorkingList |
  ClearErrorsList |
  CheckForStaleData |
  SetCalculatedProperty |
  SetListMetaData |
  ResetAllListStores |
  SortInMemory |
  SortInMemoryComplete |
  CreateObjectByFormList |
  UpdateObjectByFormList |
  SplitByParent;

