/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit,  ChangeDetectionStrategy, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IListDefinition, IScheduleRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { UserDashEvent, UserDashStore } from '../../enums/user-dashboard.enums';
import { IDispatchedWorkOrderViewModel } from '../../../view-models/i-dispatched-work-order-view-model';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { UserDashboardService } from '../../services/user-dashboard.service';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IAppConstants, appConstants } from '../../../../../shared/constants';

@Component({
  selector: 'dispatched-work-orders',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dispatched-work-orders.component.html'
})
export class DispatchedWorkOrdersComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public modalService: ModalService,
    public userDashboardService: UserDashboardService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case UserDashEvent.dispatchWorkOrder:
        this.dispatch(event.data);
        break;
      case UserDashEvent.scheduleWorkOrder:
        this.showScheduler(event.data);
        break;
    default:
        break;
    }
  }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.userDashboardService.loadDispatchedWorkOrdersListDefinition());
    });
  }

  dispatch(workOrder: IDispatchedWorkOrderViewModel): void {
    if (!this.validModel(workOrder)) return;

    const stateData = { projectId: workOrder.projectId, scheduleId: workOrder.scheduleId, hasPostIts: workOrder.specialInstructionAlert, storeName: UserDashStore.dashDispatchedWorkOrders };
    this.modalService.open({
      title: 'Select Work Crew to Dispatch Work Order',
      path: 'user-dash-dispatch/' + workOrder.workOrderId.toString(),
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.small,
      state: stateData
    });
  }

  showScheduler(data: IDispatchedWorkOrderViewModel) {
    if (!this.validModel(data)) return;
    let params: IScheduleRouteParameter = {
      scheduleId: 0,
      operation: this.myConstants.operationTypeDetails,
      projectId: data.projectId,
      storeName: this.listDefinition$.getValue().storeName,
      parentId: this.listDefinition$.getValue().parentId
    }
    this.modalService.open({
      title: 'Work Order Schedule',
      path: 'user-dash-scheduler',
      id: data.workOrderId,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.xlarge,
      optionalParams: params
    });

  }

  validModel(workOrder: IDispatchedWorkOrderViewModel): boolean {
    const woId: number = workOrder && workOrder.hasOwnProperty('workOrderId') ? workOrder['workOrderId'] : 0;
    if (woId === 0) {
      console.log('DEV ERROR:  missing wo id on event', event);
    }
    return woId > 0;
  }

}
