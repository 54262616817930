
export enum CommunicationEventFor {
  email = 'email',
  phone = 'phone',
  texting = 'texting'
}

export enum CommunicationEventType {
  welcomeLetter = 1,
  sssCustomerSelfScheduleInvitation = 2,
  sssCustomerScheduleConfirmation = 3,
  sssCustomerScheduleRejection = 4,
  readyForInstallation = 5,
  phoneActivity = 6,
  customerLeadPacket = 7,
  installerPacket = 8,
  dailySummary = 9,
  installerCancellation = 10,
  sssCustomerScheduleOptions = 11,
  smsIncoming = 12,
  smsOutgoing = 13
}

export enum CommunicationEventEvent {
  openConversation = 'openConversation',
  closeConversation = 'closeConversation'
}

