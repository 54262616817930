/** Imports for Angular modules */
import { Injectable } from '@angular/core';
import { replace, isArray, toString, set, unset, pickBy, mapValues, cloneDeep } from 'lodash'; 

/** Imports for custom services */
import { HomCommonUtility } from './hom-common.utility';
import { IListFilter } from '../../fw/dynamic-list/interfaces/index'
import { AccessLevel } from '../../fw/dynamic-list/enums/access-level.enums';

/**
 * The HOM Data Service 
 */
@Injectable()
export class HomDataUtility {
    /**
     * Constructor for this class
     */
  constructor(public utils: HomCommonUtility) {
    }


  rowHasCrudMetaData(row: any): boolean {
    if (row && row.hasOwnProperty('metaData') && row['metaData'].hasOwnProperty('crud')) {
      return true;
    }

    return false;
  }

  rowHasMetaData(row: any): boolean {
    if (row && row.hasOwnProperty('metaData')) {
      return true;
    }
    return false;
  }

  getUrlByName(row: any, propertyName: string): string {
    let url = '';
    if (this.rowHasMetaData(row)
      && row['metaData'].hasOwnProperty(propertyName)) {
      url = row['metaData'][propertyName];
    }
    return url;
  }

  hasPendingChildCreate(row: any): boolean {
    if (row && this.rowHasCrudMetaData(row)
      && row['metaData']['crud'].hasOwnProperty('pendingChildUrl')
      && row['metaData']['crud']['pendingChildUrl']
      && row['metaData']['crud']['pendingChildUrl'].length > 0) {
      return true;
    } else {
      return false;
    }
  }

    /**
     * Accepts an object property name and reformats it to make it valid to be passed
     * in an HTTP payload to the CRM API
     * @param propertyName The property name to be reformatted
     */
  fixPropertyNameForApi(propertyName) {

        // Replace underscores with dots and return.
        return propertyName.replace(/_/g, '.');
    }

    /**
     * Accepts a property name and returns only the inner-most child name.
     * For example, passing in "providerUser_providerUserId" will return just "providerUserId".
     * @param propertyName The property name whose inner-most child will be identified
     */
    getChildPropertyName(propertyName) {

        // Split the property name around the underscore character and return the last split.
        var childPropName = propertyName.split("_");
        return childPropName[childPropName.length - 1];
    }

    /**
     * Accepts an object property and reformats it to make it valid to be passed in an
     * HTTP payload to the CRM API
     * @param property The object property to be reformatted
     */
    fixPropertyForApi(property) {

        // If the property is undefined, return an empty string.
        if (this.utils.isUndefined(property)) {
            return '';
        }

        // If the property is a date, format it appropriately and return it.
        if (this.utils.isDate(property)) {
            return this.utils.dateTimeDisplay(property);
        }

        // Otherwise, just return the unmodified property.
        return property;
    }

    /**
     * Accepts an object and reformats all of its properties and property names to make it
     * valid to be passed in an HTTP payload to the CRM API
     * @param obj The object whose properties should be reformatted
     * @param arrayName The name to give the object if it is an array.  Necessary for the
     * controller to read the array data.
     */
    fixPropertiesForApi(obj, arrayName: string = null, parentName: string = null) {
        // Define any properties which should not be sent back to the server.
        var propsToExclude = ["metaData", "fieldMetaData", "extraData", "fks"];

        // Define a new empty object to store our corrected result.
        var fixedObject = {};

        // If the object is an array and the arrayName has been defined...
        var el;
      //if (Object.prototype.toString.call(obj) === "[object Array]" && arrayName != null) {
      if (isArray(obj) && arrayName != null) {
            // Set a property on the fixed object with the given name.
            fixedObject[arrayName] = [];

            // Under that property, append a corrected version of each of the supplied array's elements.
            for (el of obj) {
                fixedObject[arrayName].push(this.fixPropertiesForApi(el));
            }

            // Return the object with the corrected array.
            return fixedObject;

        } // If the object is an array but no arrayName has been defined...
      else if (isArray(obj)) {
            // Create an empty array to store the corrected result.
            var fixedArray = [];

            // Append a corrected version of each of the supplied array's elements onto our empty array.
            for (el of obj) {
                fixedArray.push(this.fixPropertiesForApi(el));
            }

            // Return the corrected array.
            return fixedArray;

      } // If the supplied object is not an array, but an object, iterate through each of its properties.
      else if (typeof (obj) == "object") {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) {

                    // Make sure we don't include any properties that aren't actually part of the data.
                  if (propsToExclude.indexOf(prop) === -1) {
                    var test1 = this.utils.isUndefined(obj[prop]);

                        // Don't send back any undefined values unless those properties are required.
                        if (!this.utils.isUndefined(obj[prop]) ||
                            (obj.hasOwnProperty("metaData") &&
                            obj.metaData.hasOwnProperty("fieldMetaData") &&
                            obj.metaData.fieldMetaData.hasOwnProperty(this.getChildPropertyName(prop)) &&
                            obj.metaData.fieldMetaData[this.getChildPropertyName(prop)].required)) {

                            

                            // Correct this property's name and value and store it in the result object.
                            // If the property is itself an object, we need to make a recursive call to 
                            // fix all the properties of the inner object as well.
                          if (isArray(obj[prop])) {
                            // Set a property on the fixed object with the given name.
                            var arrName = this.fixPropertyNameForApi(prop);
                            fixedObject[arrName] = [];
                            // Under that property, append a corrected version of each of the supplied array's elements.
                            for (el of obj[prop]) {
                              fixedObject[arrName].push(this.fixPropertiesForApi(el));
                            }
                          } else if (this.utils.isObject(obj[prop])) {

                            var result = this.fixPropertiesForApi(obj[prop], null, this.fixPropertyNameForApi(prop));
                            fixedObject = { ...fixedObject, ...result };
                          } 
                          else {
                            var propName = !parentName ? prop : parentName + '_' + prop;
                            fixedObject[this.fixPropertyNameForApi(propName)] = this.fixPropertyForApi(obj[prop]);

                          }
                        }
                    }
                }
            }

            // Return the corrected object.
            return fixedObject;

        } // Otherwise (if the supplied object is actually a primitive), fix its value and return it.
        else {

            return this.fixPropertyForApi(obj);
        }
  }

  

    extractUrlParameter(url, name) {
        var search = new RegExp('[\\?\\&]' + name + '=[\\w:./~]*&?');
        var returnArray = url.match(search, '$1');
        if (!returnArray) return null;
        var returnString = returnArray[0];
        if (returnString)
            return returnString.replace(/([&?])/g, '');
        return returnString;
    };

  formatListFilter(listFilterIn: IListFilter) {
    let listFilter: IListFilter = cloneDeep(listFilterIn);
        if (listFilter.searchTerm && listFilter.searchTerm.length === 0)
            listFilter.searchTerm = null;

        let searchTerms: string = '';

        if (listFilter.searchTerm && isArray(listFilter.searchTerm) ) {

            listFilter.searchTerm.forEach(item => {
              if (searchTerms !== '') {
                searchTerms += '~';
              }
              const searchType = item.searchType ? item.searchType : 'default';
                if (isArray(item.value)) {
                    let listOfItems = toString(item.value);
                    if (item.value && item.value.length > 1) {
                        listOfItems = '^' + listOfItems + '^';
                  }
                  let fixedTerm = fixPropertyKey(item.term, 'term');
                  searchTerms += fixedTerm + ':' + listOfItems + ':' + searchType;
                } else {
                  let fixedTerm = fixPropertyKey(item.term, 'term');
                  searchTerms += fixedTerm + ':' + item.value + ':' + searchType;
                }
            });
            unset(listFilter, 'searchTerm');
        }

        //Remove current search term array and replace with a string
        if (searchTerms !== '') {
          set(listFilter, 'searchTerm', searchTerms);
        }

      //Replace order term wtih fixed for api order term
      if (listFilter.orderTerm  && listFilter.orderTerm.length === 0)
        listFilter.orderTerm = null;
  
      let orderTerms: string = '';
      if (listFilter.orderTerm && isArray(listFilter.orderTerm)) {
        listFilter.orderTerm.forEach(item => {
          if (orderTerms !== '') {
            orderTerms += '~';
          }
          let fixedTerm = fixPropertyKey(item.term, 'term')
          orderTerms += fixedTerm + ':' + item.orderAsc.toString() + ':' + item.sortOrder.toString();
        });
        unset(listFilter, "orderTerm");
      }
    if (orderTerms !== '') {
      set(listFilter, 'orderTerm', orderTerms);
    }
      //remove empty list filter keys
      let scrubbed = {}
      scrubbed = pickBy(listFilter, isEmptyProperty);

      return Object.keys(scrubbed)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(scrubbed[k]))
        .join('&');
    }


  /*
    Formats a simple object for consumption by a controller
  */
    formatSimpleObject(simpleObject: any) {
      //remove empty list filter keys
      let scrubbed = {}
      scrubbed = pickBy(simpleObject, isEmptyProperty);

      //fix the key to conform to server requirements
      var parsedObject = mapValues(scrubbed, fixPropertyKey);

      return Object.keys(parsedObject)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(parsedObject[k]))
        .join('&');

  }
}


function isEmptyProperty(value, key) {
    return (value != null && value !== "");
}

function fixPropertyKey(value, key) {
    return replace(value, '_', '.');
}
