import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable,  Subscription } from 'rxjs';
import { take, filter, map, first } from 'rxjs/operators';

//store actions and reducers
import * as fromRoot from '../../../store/reducers/index';
import * as LoadingIndicatorActions from '../../../../shared/store/loadingIndicator/loadingIndicator.actions';
import { metaDataExists } from '../../../../fw/dynamic-list/store/selectors/meta-data.selectors';
import {  IAllDynamicData, getListByType, IDynamicListState, IListObjectData, SetListDefinition, GetList } from '../../../../fw/dynamic-list/store/index';
import { IListDefinition } from '../../../../fw/dynamic-list/interfaces';
import { UtilitiesService } from '../services/utilities.service';

@Injectable()
export class HomHolidayResolver  {
  storeName: string;
  controller: string = 'SlotScheduleExclusion';
  subscription: Subscription = new Subscription();

  constructor(public rootStore: Store<fromRoot.IState>,
    public store: Store<IAllDynamicData>,
    public utlitiesService: UtilitiesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const listDefinition: IListDefinition = this.utlitiesService.loadHomHolidaysListDefinition();
    this.storeName = listDefinition.storeName;

    this.subscription.add(this.store.pipe(select(getListByType(this.storeName)), take(1))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == -1)))
      .subscribe((store: IListObjectData) => {
        if (!store || !store.data || store.staleData) {
          this.store.dispatch(new SetListDefinition({ storeName: this.storeName, parentId: listDefinition.parentId, listDefinition: listDefinition }));
          this.store.dispatch(new GetList({ listDefinition: listDefinition, listFilter: listDefinition.defaultListFilter, parentId: listDefinition.parentId }));
        }
      }));

    //wait for meta data for this object to load, then request selection list data and wait for it to load.
    return this.metaLoaded();
  }

  metaLoaded(): Observable<boolean> {
    return this.store.select(metaDataExists(this.storeName))
      .pipe(
        map((exists: boolean) => {
          return exists;
        }),
        filter((exists: boolean) => exists === true),
        take(1),
        map(() => {
          return true;
        }),
        first()
      );
  }

}
