import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';

import * as fromRoot from '../../app/store/reducers/index';
import { Auth } from '../interfaces/auth';
import { isAnonymous, getAuthErrors, TrySignin, IAuthError } from '../store/index';

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html'
})
export class SigninComponent implements OnInit {
  returnUrl: string = '';
  public loading$: Observable<boolean>;
  public isAnonymous$: Observable<boolean>;
  public errors$: Observable<IAuthError[]>; //errors for signin come back in a unique way

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>) { }

  public onSignin(form: NgForm) {
    const authData = new Auth(form.value.userName, form.value.password);
    if (this.returnUrl === undefined || this.returnUrl === null) {
      this.returnUrl = '/hom';
    }
    authData.returnUrl = this.returnUrl;
    this.rootStore.dispatch(new TrySignin({ authData }));
  }
  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });

    this.isAnonymous$ = this.rootStore.pipe(select(isAnonymous));

    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(
        filter(x => x.requestor === 'auth'),
        map(x => x.show));

    //if login is successful, will route to home page from auth effects, just capture errors here
    this.errors$ = this.rootStore
      .pipe(
        select(getAuthErrors)
      );
  }
}
