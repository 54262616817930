import { Component, Input, OnInit } from '@angular/core';

import { CommunicationEventFor } from '../../../../app/portals/portal-shared/enums/communication-event.enums';

import { CommunicationEventService } from '../../../../app/portals/portal-shared/services/communication-event.service';

@Component({
  selector: 'fw-email',
  templateUrl: './fw-email.component.html'
})
export class EmailComponent implements OnInit  {
  @Input() emailAddress: string = '';
  @Input() displayType: string = '';
  @Input() cssClass: string = '';
  @Input() forHighLevelSummary: boolean;
  @Input() enabled: boolean = true;

  onlyIcon: boolean = false;
  onlyText: boolean = false;
  showBoth: boolean = false;

  constructor(
     public communicationEventService: CommunicationEventService) {
  }

  public ngOnInit(): void {
    switch (this.displayType) {
      case this.communicationEventService.displayTypeBoth:
        this.showBoth = true;
        break;
      case this.communicationEventService.displayTypeIcon:
        this.onlyIcon = true;
        break;
      default:
        this.onlyText = true;
        break;
    }
  }

  public click(): void {
    this.communicationEventService.requestCommunication(CommunicationEventFor.email, this.emailAddress);
  }
}
