<!-- ADD SHOW FULL ON MOBILE -->
<div>
  <ng-template #calendar>
    <div class="calendar">
      <div class="calendar__row calendar__row--top">
        <div class="calendar__row--top__today">
          <div class="calendar__row--top__today__col calendar__row--top__today__col--txt">TODAY</div>
          <div class="calendar__row--top__today__col calendar__row--top__today__col--date">{{ getDay(today) }}</div>
        </div>
        <div class="calendar__row--top__selections">
          <div class="calendar__row--top__selections__multi" *ngIf="isMultiSelect">
            <div class="calendar__row--top__selections__multi__btn" [ngClass]="{'calendar__row--top__selections__multi__btn--show': showSelections}" (click)="showOrHideSelections()">
              <span>{{ selected.length }}</span>&nbsp;Date{{ selected.length === 1 ? '' : 's' }} selected
              <div class="calendar__row--top__selections__multi__btn__arrow">
                <i class="fal fa-angle-{{ showSelections ? 'down' : 'right' }}"></i>
              </div>
            </div>
            <div class="calendar__row--top__selections__multi__list" *ngIf="showSelections">
              <div class="calendar__row--top__selections__multi__list__item" *ngFor="let item of selected">
                {{ item | date:'MM/dd/yyyy' }}
                <div class="calendar__row--top__selections__multi__list__item__remove" (click)="select(item)">
                  <i class="fa fa-times app-btn-icon app-btn-icon--danger"></i>
                </div>
              </div>
            </div>
          </div>
          <span class="calendar__row--top__selections__single" *ngIf="!isMultiSelect && singlePicker">{{ dateString(0) }}</span>
          <div class="calendar__row--top__selections__range" *ngIf="!isMultiSelect && !singlePicker">
            <div class="calendar__row--top__selections__range__con" [ngClass]="{'calendar__row--top__selections__range__con--empty': !selected.length}">
              {{ dateString(0) }}
            </div>
            <div class="calendar__row--top__selections__range__line"></div>
            <div class="calendar__row--top__selections__range__con" [ngClass]="{'calendar__row--top__selections__range__con--empty': !selected[1]}">
              {{ dateString(1) }}
            </div>
          </div>
        </div>
        <div class="calendar__row--top__select" (click)="initSelection()" [ngClass]="{'calendar__row--top__select--no': !singlePicker && selected.length !== 2}"><i class="fal fa-check"></i></div>
      </div>
      <div class="calendar__row calendar__row--week-days">
        <div class="calendar__row--week-days__week-day" [ngClass]="{'calendar__row--week-days__week-day--active': !isMultiSelect && isDay(0)}">SUN</div>
        <div class="calendar__row--week-days__week-day" [ngClass]="{'calendar__row--week-days__week-day--active': !isMultiSelect && isDay(1)}">MON</div>
        <div class="calendar__row--week-days__week-day" [ngClass]="{'calendar__row--week-days__week-day--active': !isMultiSelect && isDay(2)}">TUE</div>
        <div class="calendar__row--week-days__week-day" [ngClass]="{'calendar__row--week-days__week-day--active': !isMultiSelect && isDay(3)}">WED</div>
        <div class="calendar__row--week-days__week-day" [ngClass]="{'calendar__row--week-days__week-day--active': !isMultiSelect && isDay(4)}">THU</div>
        <div class="calendar__row--week-days__week-day" [ngClass]="{'calendar__row--week-days__week-day--active': !isMultiSelect && isDay(5)}">FRI</div>
        <div class="calendar__row--week-days__week-day" [ngClass]="{'calendar__row--week-days__week-day--active': !isMultiSelect && isDay(6)}">SAT</div>
      </div>
      <div #scrollcon class="calendar__row calendar__row--scroll-con" (scroll)="scrollCalendar()">
        <div class="calendar__row--scroll-con__month" *ngFor="let month of months; index as m;">
          <div class="calendar__row--scroll-con__month__fill" [ngStyle]="{'width': (!isMobile ? ((40 * month.firstDay) + 'px') : ((14.285 * month.firstDay) + '%'))}"></div>
          <div class="calendar__row--scroll-con__month__day" *ngFor="let day of month.days; index as d;" [ngClass]="{'focused': m === 1, 'selected': isSelected(day), 'range': !isMultiSelect ? inRange(day) : false}" (click)="select(day)">{{ d+1 }}</div>
          <div class="calendar__row--scroll-con__month__name" [ngStyle]="{'width': (!isMobile ? ((40 * (7 - month.nextFirst) - 2) + 'px') : ((14.285 * (7 - month.nextFirst)) + '%'))}">
            <div class="calendar__row--scroll-con__month__name__txt" [ngStyle]="{'width': (!isMobile ? '4rem' : (100 / (7 - month.nextFirst) + '%'))}">{{ month.name }}</div>
            <div class="calendar__row--scroll-con__month__name__line"></div>
          </div>
        </div>
      </div>
      <div class="calendar__row calendar__row--m-y">
        <div class="calendar__row--m-y__year"><i class="fal fa-minus" (click)="incYear(-1)"></i>{{ months[1].year }}<i class="fal fa-plus" (click)="incYear(1)"></i></div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 0}" (click)="setMonth(0)">JAN</div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 1}" (click)="setMonth(1)">FEB</div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 2}" (click)="setMonth(2)">MAR</div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 3}" (click)="setMonth(3)">APR</div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 4}" (click)="setMonth(4)">MAY</div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 5}" (click)="setMonth(5)">JUN</div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 6}" (click)="setMonth(6)">JUL</div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 7}" (click)="setMonth(7)">AUG</div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 8}" (click)="setMonth(8)">SEP</div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 9}" (click)="setMonth(9)">OCT</div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 10}" (click)="setMonth(10)">NOV</div>
        <div class="calendar__row--m-y__months" [ngClass]="{'calendar__row--m-y__months--active': months[1].index === 11}" (click)="setMonth(11)">DEC</div>
      </div>
    </div>
  </ng-template>
  <button class="app-btn app-btn--primary" *ngIf="isMultiSelect" fw-popover [template]="calendar" [closeOnClickOutside]="true" [showPopover]="showStartDate$ | async" [showFullOnMobile]="true" (onHide)="hideCalendar()" (click)="openCalendar(true)">
    Select Dates
  </button>
  <!--
  <div class="calendar-btn" *ngIf="isMultiSelect" fw-popover [template]="calendar" [closeOnClickOutside]="true" [showPopover]="showStartDate$ | async" [showFullOnMobile]="true" (onHide)="hideCalendar()" 
              (click)="openCalendar(true)">
    Select Dates&nbsp;
    <i class="fas fa-calendar-alt calendar-btn__icon"></i>
  </div>
    -->
  <div class="calendar-container" [ngClass]="{'calendar__single': singlePicker, 'calendar__range': !singlePicker }" *ngIf="!isMultiSelect">
    <label class="calendar-container__label">{{ label || 'Start Date' }}</label>
    <div class="calendar-input"
         [ngClass]="{'calendar-input--disabled': isDisabled,'calendar-input--focused': startFocused, 'has-error': !dateIsValid || error || (isRequired && !startDate.length), 'calendar-input__date-time': isDateTime}">
      <input class="calendar-input__field" #startElem dateMask
             [disableMask]="isReadOnly"
             [disabled]="isDisabled"
             [readonly]="isReadOnly"
             [ngClass]="customCss"
             [(ngModel)]="startDate"
             (ngModelChange)="onChange()"
             (focus)="onFocus(true)"
             (blur)="onBlur(true)"
             placeholder="mm/dd/yyyy"
             autocomplete="off"
             type="text"
             maxlength="10" />
      <button class="calendar-input__btn calendar-input__btn--clear" [disabled]="isDisabled">
        <div class="calendar-input__btn__clear" (click)="clearInput(true)" [ngStyle]="{'transform': 'scale(' + (!startDate.length ? 0 : 1) + ')'}">
          <div class="calendar-input__btn__clear__line"></div>
          <div class="calendar-input__btn__clear__line"></div>
        </div>
      </button>
      <!-- ADD SHOW FULL ON MOBILE -->
      <!--
          [popover]="calendar"
          [popoverOnHover]="false"
          [showPopover]="showCalendar$ | async"
          (onShown)="openCalendar(true)"
          (onHidden)="hideCalendar()"
          -->
      <button class="calendar-input__btn calendar-input__btn--icon"
              fw-popover [template]="calendar" [closeOnClickOutside]="true" [showPopover]="showStartDate$ | async" [showFullOnMobile]="true" (onHide)="hideCalendar()" 
              (click)="openCalendar(true)"
              [disabled]="isDisabled">
        <i class="fas fa-calendar-alt"></i>
      </button>
    </div>
  </div>

  <div class="calendar-container calendar-container--end" *ngIf="!isMultiSelect && !singlePicker">
    <label class="calendar-container__label">{{ label || 'End Date' }}</label>
    <div class="calendar-input" [ngClass]="{'calendar-input--focused': endFocused, 'calendar-input--invalid': !dateIsValid}">
      <input #endElem dateMask
             [disabled]="isDisabled"
             [readonly]="isReadOnly"
             [ngClass]="customCss"
             [(ngModel)]="endDate"
             (ngModelChange)="onChange()"
             (focus)="onFocus(false)"
             (blur)="onBlur(false)"
             placeholder="mm/dd/yyyy"
             autocomplete="off"
             type="text"
             maxlength="10" />
      <button class="calendar-input__btn calendar-input__btn--clear" [disabled]="isDisabled">
        <div class="calendar-input__btn__clear" (click)="clearInput(false)"
             [ngStyle]="{'transform': 'scale(' + (!endDate || !endDate.length ? 0 : 1) + ')'}">
          <div class="calendar-input__btn__clear__line"></div>
          <div class="calendar-input__btn__clear__line"></div>
        </div>
      </button>
      <button class="calendar-input__btn calendar-input__btn--icon"
              fw-popover [template]="calendar" [closeOnClickOutside]="true" [showPopover]="showEndDate$ | async" [showFullOnMobile]="true" (onHide)="hideCalendar()"
              (click)="openCalendar(false)"
              [disabled]="isDisabled">
        <i class="fas fa-calendar-alt"></i>
      </button>
    </div>
  </div>
  <div *ngIf="!dateIsValid && validationMessage" class="calendar__error alert-box alert-box--danger">
    {{validationMessage}}
  </div>


</div>

