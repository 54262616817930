import { Component, OnInit, OnDestroy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDocumentType, DocumentType } from '../../../view-models';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { UpdateObjectCustomModel, IKey, CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';

//store actions, reducers, interfaces
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { FileUploaderService } from '../../../../file-upload/services/file-uploader.service';
import { FieldSuffix } from '../../../../../fw/dynamic-list/enums/dynamic-field.enums';

@Component({
  selector: 'document-type',
  templateUrl: './document-type.component.html'
})
export class DocumentTypeComponent implements OnInit, OnDestroy  {
  public objectData: IDocumentType;
  public displayFields = ['documentTypeName', 'entity', 'baseViewModel', 'isExternal', 'isOutgoing', 'isAssignable', 'isRequired', 'isVisible', 'isVersioned', 'alwaysCreateNew', 'includeInWorkOrderPacket', 'allowMultiple', 'sortOrder', 'fileUrl'];

  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  storeName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string;
  detailRoutePath: string = '';
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public ups: UserPriviledgesService,
    public fus: FileUploaderService,
    @Inject(appConstants) public myConstants: IAppConstants  ) {

    this.subscription.add(emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case this.myConstants.emitterEventCreate:
            this.createRecord(e);
            break;
          default:
            break;
        }
      }));
    }

  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');

      this.setDetailConfig(paramMap);
      this.getDetail();
    }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.storeName, -1, this.key, this.objectId)))
      .subscribe(entity => {
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate
          ? new DocumentType() : entity;
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.storeName, -1)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.detailRoutePath = listDefinition.detailRoutePath;
        this.controllerName = listDefinition.controllerName;
      }
    ));
  }

  createRecord(e: IHomEventEmitter) {
    let formData = this.setFormData(e.data, false);
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, -1, this.controllerName, 'Create', formData, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectByFormList({ createData }));
  }

  updateRecord(e: IHomEventEmitter) {
    let formData = this.setFormData(e.data, true);

    const keyData: IKey = { storeName: this.storeName, parentId: -1, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', formData, '',  emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectByFormList({ updateData }));
  }

  setFormData(documentType: IDocumentType, existingRow: boolean): FormData {
    let formData: FormData = new FormData();
    const wasFileRemoved: boolean = documentType.fileUrl ? false : true;
    const uploadFile: File = documentType.hasOwnProperty('fileUrl'.concat(FieldSuffix.fileSource))
      ? documentType['fileUrl'.concat(FieldSuffix.fileSource)]
      : null;
    if (existingRow) {
      formData.append('documentTypeId', this.objectId.toString());
    }
    formData.append('documentTypeName', documentType.documentTypeName);
    formData.append('isOutgoing', documentType.isOutgoing.toString());
    formData.append('isAssignable', documentType.isAssignable.toString());
    formData.append('isExternal', documentType.isExternal.toString());
    formData.append('sortOrder', documentType.sortOrder.toString());
    formData.append('isVisible', documentType.isVisible.toString());
    formData.append('isVersioned', documentType.isVersioned.toString());
    formData.append('alwaysCreateNew', documentType.alwaysCreateNew.toString());
    formData.append('includeInWorkOrderPacket', documentType.includeInWorkOrderPacket.toString());
    formData.append('isRequired', documentType.isRequired.toString());
    formData.append('allowMultiple', documentType.allowMultiple.toString());
    formData.append('entity.entityId', documentType['entity'] && documentType['entity'].hasOwnProperty('entityId')
      ? documentType['entity']['entityId'] : null);

    if (documentType['baseViewModel'] && documentType['baseViewModel'].hasOwnProperty('entityId')) {
      formData.append('baseViewModel.entityId',  documentType['baseViewModel']['entityId'])
    }

    //formData is funky, if you need to send in a null value for an entity, don't set it.
    if (this.objectData.createRule_ruleId && this.objectData.createRule_ruleId > 0) {
      formData.append('createRule.ruleId', this.objectData.createRule_ruleId.toString());
    }

    if (!uploadFile && !wasFileRemoved) {
      formData.append('documentTemplate.fileUploadId', this.objectData.documentTemplate_fileUploadId.toString());
    }

    if (uploadFile) {
      formData.append('file', uploadFile);
    }

    return formData;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  } 

}
