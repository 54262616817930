import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { IDynamicListRowBtn } from '../../interfaces';
import { ICustomButton } from '../../../fw-shared/interfaces/i-custom-button';

@Component({
  selector: 'dynamic-list-overflow-btns',
  templateUrl: './dynamic-list-overflow-btns.component.html'
})

export class DynamicListOverflowBtnsComponent {
  @Input() btns: IDynamicListRowBtn[];
  @Input() showInfo: boolean;
  @Input() row: any;
  @Input() listDisabled: boolean;

  @Output() public customEvent = new EventEmitter<ICustomButton>();

  public isVisible: boolean = false;

  constructor() { }

  public toggleOverflow(event): void {
    this.isVisible = !this.isVisible;
    event.stopPropagation();
  }

  public onClick(def: ICustomButton): void {
    this.customEvent.emit(def);
    this.isVisible = false;
 }

}
