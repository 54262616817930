import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { ButtonType } from '../../../enums/button-type.enum';

@Component({
    selector: 'fw-crud-icon-buttons',
    templateUrl: './fw-crud-icon-buttons.component.html'
})
export class CrudIconButtonsComponent {
  @Input()  entityLabel: string = '';
  @Input()  canIEdit: boolean = false;
  @Input()  operation: string;
  @Input()  disabled: boolean = false;
  @Input()  staleData: boolean = false;
  @Input()  showEdit: boolean = true;
  @Input()  showDelete: boolean = false;
  @Input() showRefresh: boolean = true;
  @Input() printUrl: string;
  @Input() wrap: boolean = true;

  @Output() public customEvent: EventEmitter<IHomEventEmitter> = new EventEmitter();

  public editType: string = ButtonType[ButtonType.edit];
  public saveType: string = ButtonType[ButtonType.save];
  public reloadType: string = ButtonType[ButtonType.reload];
  public cancelType: string = ButtonType[ButtonType.cancel];
  public deleteType: string = ButtonType[ButtonType.delete];
  public printType: string = ButtonType[ButtonType.print];

  constructor(
    @Inject(appConstants) public myConstants: IAppConstants) {
  }

  public printDoc(): void {
    if (this.printUrl) {
      var win = window.open(this.printUrl, '_blank');
      win.focus();  
    }
  }

  public crudEvent(type: string): void {
    const data: IHomEventEmitter = {
      event: type,
      requestor: 'custom-button',
      action: '',
      data: null
    };
    this.customEvent.emit(data);
  }

}
