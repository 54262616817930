
export enum AppTabAction {
  ClearSmsNotice = 'ClearSmsNotice',
  SetSmsNotice = 'SetSmsNotice'
}

export enum AppTabIcon {
  Sms = 'fad fa-comments-alt'
}

