import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ScheduleEvent } from '../../enums/schedule.enums';
import { IScheduleInstallerWorkCrewAvailabilityViewModel } from '../../../view-models/i-schedule-installer-work-crew-availability-view-model';
import { SchedulerInstallerTabs } from '../../tab-settings/scheduler-installer.tabs';
import { IPortalTab } from '../../../view-models';
import { SchedulerService } from '../../services/scheduler.service';

@Component({
  selector: 'schedule-installers',
  templateUrl: './schedule-installers.component.html'
})
export class ScheduleInstallersComponent implements OnInit,  OnChanges {
  @Input() availableInstallers: IScheduleInstallerWorkCrewAvailabilityViewModel[]; 
  @Input() workOrderId: number;
  @Input() startDate: string;
  @Input() duration: number;
  @Input() durationType: string;
  @Input() scheduleId: number;
  @Input() unavailableReason: string;
  @Input() hideInstallers: boolean = true;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public tabs$: BehaviorSubject<IPortalTab[]> = new BehaviorSubject(null);
  public showAvailable: boolean = true;
  subscription = new Subscription();

  constructor(public schedulerService: SchedulerService,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public emitterService: HomEventEmitterService) { }

  public onCustom(event: IHomEventEmitter) {
    if (!event) {
      console.log('DEV ERR:  no event on custom Event call');
      return;
    }
    switch (event.event) {
      case ScheduleEvent.selectWorkCrew:
      case ScheduleEvent.installerSort:
        this.customEvent.emit(event);
        break;
      default:
        console.log('DEV ERR: onCustom and this event is not defined');
        break;
    }
  }

  ngOnInit(): void {
    this.setTabs();
  }

  setTabs(): void {
    if (this.hideInstallers) {
      return;
    }
    const hasData: boolean = this.unavailableReason ? false : this.availableInstallers && this.availableInstallers.length > 0;
    let tabs: IPortalTab[] = cloneDeep(SchedulerInstallerTabs);
    tabs[0].isDefault = hasData ? true : false;
    tabs[1].isDefault = hasData ? false : true;
    this.tabs$.next( tabs );
    this.showAvailable = hasData ? true : false;
  }

  onTabChange(newTab: IPortalTab): void {
   this.showAvailable = newTab.portalTabId === 0 ? true : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['availableInstallers'] && !(changes['availableInstallers'].firstChange) ||
        changes['unavailableReason'] && !(changes['unavailableReason'].firstChange) ||
        changes['hideInstallers'] && !(changes['hideInstallers'].firstChange)) {
        this.setTabs();
    }
  }

  }

