<div [ngClass]="{ 'loading-mask': (working$ | async)}">
  <portal-detail-layout-container *ngIf="(project$ | async) as project"
                                  [portalId]="portalId"
                                  [portalEntityId]="project.projectId"
                                  [title]="title"
                                  [subTitle]="gcBranch"
                                  [info]="project.currentProjectStatusCode === myConstants.projectStatusClosed ? project.currentProjectStatusCode : ''"
                                  [tabContainerName]="tabContainerName"
                                  [wideSummary]="true"
                                  [titleIcon]="titleIcon$ | async"
                                  [customBtns]="portalBtnConfig$ | async"
                                  [dynamicTabIncludes]="dynamicTabIncludes"
                                  (customEvent)="onCustomEvent($event)">
    <project-high-level-summary [projectId]="projectId"
                                [portalReload]="portalReload$ | async">
      <fw-error-box [errorData]="errorData$ | async" [errors]="errors$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
    </project-high-level-summary>
  </portal-detail-layout-container>
</div>
