export interface IContactTypeSpecification {
  contactTypeSpecificationId: number;
  contactType_contactTypeId: number;
  contactMechanismCategoryType_contactMechanismCategoryTypeId: number;
  minimum: number;
  maximum?: number;

  entityLabel: string;
  contactMechanismCategoryNameTypeName: string;
  contactTypeName: string;
  contactMechanismCategoryName: string;
  contactMechanismCategoryTypeName: string;
}


export class ContactTypeSpecification implements IContactTypeSpecification {
  contactTypeSpecificationId: number;
  contactType_contactTypeId: number;
  contactMechanismCategoryType_contactMechanismCategoryTypeId: number;
  minimum: number;
  maximum?: number;

  entityLabel: string;
  contactMechanismCategoryNameTypeName: string;
  contactTypeName: string;
  contactMechanismCategoryName: string;
  contactMechanismCategoryTypeName: string;

  constructor() {
    this.contactTypeSpecificationId = 0;
    this.contactType_contactTypeId = null;
    this.contactMechanismCategoryType_contactMechanismCategoryTypeId = null;
    this.minimum = 0;
    this.maximum = null;
  }
}
