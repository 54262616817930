<div *ngIf="data" class="loc-sched">
  <div class="loc-sched--label">{{data.dayName}}</div>
  <fw-switch-button class="loc-sched--switch" [defaultChecked]="data.open"
                    [title]="isEnabled ? 'Open' : 'Closed'"
                    (toggleEvent)="onOpen($event)">
  </fw-switch-button>
  <div *ngIf="!isEnabled" class="loc-sched--icon">
    <span class="fad fa-face-sleeping app-icon--med app-icon--primary"></span>
    <span class="loc-sched--closed">Closed</span>
  </div>
  <div *ngIf="isEnabled">
    <input #startTime type="time"
           pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9]) ([AP]M)$"
           value="{{ +(data.startTime | date:'H') > 12 ? (data.startTime | date: 'H:mm') : (data.startTime | date: 'hh:mm') }}"
           (change)="onStartTimeChange(startTime.value)" />
  </div>
  <div *ngIf="isEnabled">to</div>
  <div *ngIf="isEnabled">
    <input #endTime type="time"
           pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9]) ([AP]M)$"
           value="{{ +(data.endTime | date:'H') > 12 ? (data.endTime | date: 'H:mm') : (data.endTime | date: 'hh:mm') }}"
           (change)="onEndTimeChange(endTime.value)" />
  </div>
</div>
<div *ngIf="invalidTime$ | async" class="alert-box alert-box--danger">
  Start Time must be prior to End Time
</div>
