
export interface IUserDelegate
{
  userDelegateId: number;
  jobSchedule_jobScheduleId: number;
  providerUser_providerUserId: number;
  userDelegateUser_providerUserId: number;
  isActive: boolean;

  //extra data properties
  jobScheduleDescription : string;
  delegateeName: string;
  delegatorName : string;
  delegateName : string;
}

export class UserDelegate {

  constructor(
    public userDelegateId: number = 0,
    public jobSchedule_jobScheduleId: number = 0,
    public providerUser_providerUserId: number = 0,
    public userDelegateUser_providerUserId: number = 0,
    public jobScheduleDescription: string = '',
    public delegateeName: string = '',
    public delegatorName: string = '',
    public delegateName: string = '',
    public isActive: boolean = false
  ) { }
}
