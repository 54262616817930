// Resolver for a specific installer
import { Injectable, OnDestroy} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, of } from 'rxjs';
import { take, filter, map, first, mergeMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

//store actions and reducers
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../store/reducers/index';
import { getObjectDataByType } from '../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { metaDataExists } from '../../../../fw/dynamic-list/store/selectors/meta-data.selectors';
import * as DynamicObjectActions from '../../../../fw/dynamic-list/store/actions/dynamic-object.actions';
import { IFieldDefinition } from '../../../../fw/dynamic-forms/index';

import { SelectionListService } from '../../../../shared/services/selection-list.service';
import { ISelectResolverData } from '../../../../shared/interfaces/i-select-resolver-data';
import { InstallerStore } from '../enums/installer.enums';

@Injectable()
export class InstallerInformationResolver  implements OnDestroy {
  operation: string;
  currentUserId: number;
  fieldDefinitions: IFieldDefinition[] = [];
  seedData: any = {};
  selectStoreNames: string[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public selectionListService: SelectionListService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const portalEntityId = +route.paramMap.get('portalEntityId');
    const displayFields = ['installerName', 'isActive', 'state', 'stateIncorporated', 'businessType', 'taxIdNumber', 'emailWorkPacket', 'wantScheduleDailySummary'];
    
    const objectRequest = {
      controllerName: 'Installer',
      storeName: InstallerStore.installerInformation,
      rowKeyId: 'installerId',
      objectId: portalEntityId,
      displayFields: displayFields,
      metaUrl: '',
      controllerMethod: 'DetailsWithMetaData', //defaults to Index if not specified
      methodParameters: portalEntityId.toString()
    };


    //get object data or check for stale data
    this.subscription.add(this.store.pipe(select(getObjectDataByType(InstallerStore.installerInformation)), take(1))
      .subscribe((store: fromStore.IDynamicObject) => {
        const objData = cloneDeep(store.objData.find(x => x.objectId == portalEntityId));
        if (!objData || !objData.data || objData.staleData) {
          this.store.dispatch(new DynamicObjectActions.GetObject({ detailRequest: objectRequest }));
        }
        else {
          //need to get aggregate value from any of the parent records, so get from 1st one
          const rowVersion: string = objData.data.hasOwnProperty('rowVersion') ? objData.data['rowVersion'] : '';
          const aggregateVersion: string = objData.data.hasOwnProperty('aggregateRootVersion') ? objData.data['aggregateRootVersion'] : '';
          const entityId: number = objData.hasOwnProperty('listMetaData') && objData['listMetaData'].hasOwnProperty('fieldMetaData')
            && objData['listMetaData']['fieldMetaData'].hasOwnProperty('entityId') ? objData['listMetaData']['fieldMetaData']['entityId'] : 0;

          if (!aggregateVersion || aggregateVersion === '' || entityId === 0) {
            //console.log('TEMP DEV INFO: this entity does not have aggregateRootVersion for store: ', this.storeName, ' with parentId: ', portalEntityId, ' and entityId of: ', entityId);
          } else {
            this.store.dispatch(new DynamicObjectActions.CheckForStaleDataObj({ storeName: InstallerStore.installerInformation, objectId: portalEntityId, entityId: entityId, aggregateRootVersion: aggregateVersion, rowVersion: rowVersion }));
          }
        }
      }));

    //wait for meta data for this object to load, then request selection list data and wait for it to load.
    return this.metaLoaded().pipe(
      mergeMap((loaded: boolean) => {
        let resolverData: ISelectResolverData[] = this.selectionListService.getListNames(InstallerStore.installerInformation);
        this.selectionListService.requestListData(InstallerStore.installerInformation, portalEntityId);
        resolverData.forEach(x => x.parentId = x.parentDependent ? portalEntityId : -1);

        return this.selectionListService.waitForDataToLoad(resolverData)
          .pipe(
            map((complete: boolean) => {  return complete }),
            filter((complete: boolean) => complete === true),
            take(1),
            map(() => {
              return true;
            }),
            first()
          )
      })
    );
  }


  metaLoaded(): Observable < boolean > {
    return this.store.select(metaDataExists(InstallerStore.installerInformation))
    .pipe(
      map((exists: boolean) => {
        return exists;
      }),
      filter((exists: boolean) => exists === true),
      take(1),
      map(() => { return true; }),
      first()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
