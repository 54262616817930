import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../../environments/environment';

import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { ScheduleEvent, ScheduleAvailability, DurationType, UnavailableType, UnavailabilityRank, AvailabilityRank } from '../../enums/schedule.enums';
import { IScheduleAvailabilityViewModel, ISlotMap } from '../../../view-models';
import { ILegend } from '../../../../../shared/components/fw-legend/interfaces/i-legend';
import { ICalendarDay, IDateTimeEvent, ISlotAvailability, IWorkingAvailability } from '../../interfaces';
import { UserPriviledgesService } from '../../../../../auth/services';
import { HomCommonUtility } from '../../../../../shared/services';

@Component({
  selector: 'schedule-calendar',
  templateUrl: './schedule-calendar.component.html'
})
export class ScheduleCalendarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() durationType: string;
  @Input() duration: number;
  @Input() pullLength: number;
  @Input() availability: IScheduleAvailabilityViewModel[];
  @Input() defaultStart: string;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  @ViewChild('scrollCon', { read: ElementRef }) scrollCon: ElementRef;

  public days: ICalendarDay[];
  public legendItems: ILegend[] = [
    { icon: 'fas fa-circle', iconCss: 'calendar-legend--available', text: 'Available', textCss: 'installers-legend--all-clear' },
    { icon: 'fas fa-circle', iconCss: 'calendar-legend--warning', text: 'Schedule Buffer Warning', textCss: 'installers-legend--warning' },
    { icon: 'fas fa-circle', iconCss: 'calendar-legend--unavailable', text: 'Unavailable', textCss: 'calendar-legend--unavailable' }
  ];
  public firstDay: number;
  public selectedDay: ICalendarDay;
  public idx: number;
  public dayAvailability: string;
  public month: Date = new Date();
  public scrollInc: number = 0;
  public scrollPos: number = 0;
  public scrollIdxs: number[] = [];
  public disableScrolling: boolean;
  allowedBackDateDays: number = -7;

  constructor(
    public datePipe: DatePipe,
    private ups: UserPriviledgesService,
    public homCommonUtility: HomCommonUtility) { }

  public changeMonth(inc: number): void {
    let i: number = this.scrollIdxs.length;
    while (i--) {
      if (this.scrollIdxs[i] === this.scrollInc) {
        break;
      }
    };
    this.scrollInc = this.scrollIdxs[i + inc];
    this.month = new Date(this.month.getFullYear(), this.month.getMonth() + inc);
    this.disableScrolling = true;
    if (this.scrollCon) {
      this.scrollPos = this.scrollInc * this.scrollCon.nativeElement.children[0].offsetHeight;
      this.scrollCon.nativeElement.scroll(0, this.scrollPos);
    }
  }

  public onScroll(elem): void {
    if (this.disableScrolling) return this.disableScrolling = false, void 0;
    const scrollInc: number = Math.round(elem.scrollTop / elem.children[0].offsetHeight);
    if (this.scrollInc !== scrollInc) {
      let i: number = this.scrollIdxs.length;
      while (i--) if (this.scrollIdxs[i] === scrollInc) {
        this.month = new Date(this.month.getFullYear(), this.month.getMonth() + (elem.scrollTop > this.scrollPos ? 1 : -1));
        this.scrollInc = this.scrollIdxs[i];
        break;
      }
    }
    this.scrollPos = elem.scrollTop;
  }

  /* Clicked on a day in the calendar or auto selected a day */
  public selectDay(day: ICalendarDay, idx: number): void {
    const data: IDateTimeEvent = {
      dateTimeSelected: day.availability ? day.availability.startDateTime : day.date,
      hasAvailabilityData: day.availability ? true : false,
      unavailableType: day.availability ? day.availability.unavailabilityType : UnavailableType.none,
      daySelected: day
    }
    this.selectedDay = day;
    this.idx = idx;

    this.customEvent.emit({
      requestor: 'schedule-calendar',
      event: !day.availability ? ScheduleEvent.setStartDate
        : this.durationType === DurationType.days
          ? ScheduleEvent.setAvailabilityForDay
          : ScheduleEvent.setAvailabilityForTimespan,
      action: '',
      data: data
    });
  }

  ngOnInit() {
    this.newRequest();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pullLength'] && !(changes['pullLength'].firstChange)
      || changes['duration'] && !(changes['duration'].firstChange)
      || changes['durationType'] && !(changes['durationType'].firstChange)) {
      this.newRequest();
    }

    if (changes['availability'] && !(changes['availability'].firstChange)) {
      this.setAvailability();
    }
  }

  ngAfterViewInit(): void {
   if (this.defaultStart) {
      const defaultStartDate = this.defaultStart ? this.datePipe.transform(this.defaultStart, 'MM/dd/yyyy') : null;
      const today = this.datePipe.transform(Date.now(), 'MM/dd/yyyy').toString();
      const weekEnd: Date = this.homCommonUtility.getEndOfWeek(today);
      const startOfMonth = this.homCommonUtility.getStartOfMonth(weekEnd.toString());
      const startDateWeekEnd: Date = this.homCommonUtility.getEndOfWeek(defaultStartDate);
      const startDateStartOfMonth = this.homCommonUtility.getStartOfMonth(startDateWeekEnd.toString());
      const diff = startDateStartOfMonth.getMonth() - startOfMonth.getMonth();
      if (diff > 0) {
        this.changeMonth(diff);
      }
    }
  }


  /* Called when one of the definition type fileds changes: durationType, duration, pullLength */
  newRequest(): void {
    const today = this.datePipe.transform(Date.now(), 'MM/dd/yyyy').toString();
    const defaultStartDate = this.defaultStart ? this.datePipe.transform(this.defaultStart, 'MM/dd/yyyy') : null;
    const selectedDate = this.selectedDay ? this.datePipe.transform(this.selectedDay.date, 'MM/dd/yyyy') : null;

    //if do not have a selected day already working with and if current schedule date > today, use current schedule date
    //if do have a selected day already working with, use the selected day
    //otherwise, default to today
    const startDate = !defaultStartDate && !this.selectedDay ? today
      : this.selectedDay ? selectedDate
        : defaultStartDate < today ? today
          : defaultStartDate;
    if (!this.selectedDay) {
      this.initCalendar(startDate);
    } else {
      this.initAvailability();
    }

    const idx = this.days.findIndex(x => x.date === startDate);
    this.selectDay(idx > -1 ? this.days[idx] : this.days[0], idx > -1 ? idx : 0);
  }

  /* Creates an empty array in ICalendarDay format starting with current date out 150 days  */
  initCalendar(startDate): void {
    const today = this.datePipe.transform(Date.now(), 'MM/dd/yyyy').toString();
    let arr: ICalendarDay[] = [];

    this.firstDay = new Date().addDays(this.allowedBackDateDays).getDay();
    for (var d = this.allowedBackDateDays; d < environment.scheduleDayLimit; d++) {
      const day: string = this.datePipe.transform(new Date().addDays(d), 'MM/dd/yyyy');
      if (new Date(day).getDate() === 1) this.scrollIdxs.push(Math.round((d + this.firstDay) / 7));

      arr.push({
        date: this.datePipe.transform(new Date().addDays(d), 'MM/dd/yyyy'),
        dayCssName: '',
        amCssName: '',
        pmCssName: ''
      });
    }
    const weekEnd: Date = this.homCommonUtility.getEndOfWeek(today);
    const startOfMonth = this.homCommonUtility.getStartOfMonth(weekEnd.toString());
    this.month = startOfMonth;

    this.days = arr;
  }

  initAvailability(): void {
    this.days.forEach(d => {
      d.dayCssName = '';
      d.amCssName = '';
      d.pmCssName = '';
      d.availability = null;
    });
  }

  /* Called when receive changes to availability
   *    Sets the availability in the working this.days array for the current pullLength */
  setAvailability(): void {
    if (!this.availability || this.availability.length === 0) {
      return;
    }
    let workingDays: IWorkingAvailability[] = this.initWorkingDays();
    this.availability.forEach((row) => {
      const dayStatus: string = this.getAvailabilityStatus(row.totalSlots, row.usedSlots, row.scheduleLocked, row.slotBuffer);
      const unavailabilityType: UnavailableType = this.getUnavailableType(row.totalSlots, row.usedSlots, row.scheduleLocked, dayStatus);
      const rowDate: string = this.datePipe.transform(row.startDate, 'MM/dd/yyyy');
      const workingIdx: number = workingDays.findIndex(x => x.date === rowDate);

      let slotAvailability: ISlotAvailability[] = this.durationType === DurationType.days
        ? []
        : this.loadSlotAvailability(row.slotMap, row.scheduleLocked, row.slotBuffer);

      workingDays[workingIdx].date = rowDate;
      workingDays[workingIdx].earliestStartDateTime = this.durationType === DurationType.days
        ? this.datePipe.transform(new Date(row.startDate), 'MM/dd/yyyy h:mm a')
        : slotAvailability.length > 0 ? slotAvailability[0].startTime : this.datePipe.transform(new Date(row.slotMap[0].startDateDisplay), 'MM/dd/yyyy h:mm a');
      workingDays[workingIdx].data = row; //probably only need a couple of fields from here - reduce to those fields when determine
      workingDays[workingIdx].status = dayStatus;
      workingDays[workingIdx].unavailabilityType = unavailabilityType;
      workingDays[workingIdx].slotAvailability = slotAvailability;//need to modify timespan to use this.
    });

    //Now set availability using values in working array, pullLength, durationType and duration
    //only set this.day[indx] for number of days retrieved in pull length, not duration
    for (var i = 0; i < workingDays.length; i++) {
      const idx = this.days.findIndex(x => x.date === workingDays[i].date);
      if (idx < 0) {
        console.log('DEV ERROR: idx: ', idx, ' for this.days: ', this.days, ' and working rec: ', workingDays[i]);
        continue;
      }
      this.days[idx].availability = {
        status: workingDays[i].status,
        unavailabilityType: workingDays[i].unavailabilityType,
        startDateTime: workingDays[i].earliestStartDateTime || this.days[idx].date,
        am: this.durationType === DurationType.days ? '' : this.getHalfDayAvailability(workingDays[i], true),
        pm: this.durationType === DurationType.days ? '' : this.getHalfDayAvailability(workingDays[i], false),
        slotAvailability: workingDays[i].slotAvailability
      };
      this.days[idx].dayCssName = this.getDayCssSuffix(this.days[idx]);
      this.days[idx].amCssName = this.durationType === DurationType.days ? '' : this.getHalfDayCssSuffix(this.days[idx], true);
      this.days[idx].pmCssName = this.durationType === DurationType.days ? '' : this.getHalfDayCssSuffix(this.days[idx], false);
    }

    //Reset selected day so it now contains avail settings
    const idx = this.days.findIndex(x => x.date === this.selectedDay.date);

    //Emit event back to parent
    this.selectDay(this.days[idx], idx);
  }

  /* Create working availability for the availability requested by pull length
   * Non working days are not returned, so this ensures any not found are handled as non-working */
  initWorkingDays(): IWorkingAvailability[] {
    let working: IWorkingAvailability[] = [];
    // Adjust the for loop limit to be the greater of the pull length or duration if duration type is days.
    let jobLength = this.durationType === DurationType.days
      ? this.duration > this.pullLength
        ? this.duration
        : this.pullLength
      : this.pullLength;
    for (var i = 0; i < jobLength; i++) {
      working.push({
        date: this.datePipe.transform(new Date(this.selectedDay.date).addDays(i), 'MM/dd/yyyy'),
        earliestStartDateTime: '',
        data: null,
        status: ScheduleAvailability.unavailable,
        unavailabilityType: UnavailableType.spanNonWorkingDay,
        slotAvailability: []
      });
    }
    return working;
  }


  /* Load slot availability, for duration type of hours or minutes only
   * Used in <schedule-timespan> */
  loadSlotAvailability(slotMap: ISlotMap[], scheduleLocked: boolean, slotBuffer: number): ISlotAvailability[] {

    let slotAvailability: ISlotAvailability[] = [];
    let previousIndex: number = -1;
    slotMap.forEach((val, index) => {
      const slotStatus: string = this.getAvailabilityStatus(val.totalSlots, val.totalUsedSlots, scheduleLocked, slotBuffer);
      const unavailabilityType: UnavailableType = this.getUnavailableType(val.totalSlots, val.totalUsedSlots, scheduleLocked, slotStatus);
      const newHour: boolean = index === 0 ? true : (index - previousIndex) === 4 ? true : false;
      slotAvailability.push({
        startTime: val.startDateDisplay,
        endTime: val.endDateDisplay,
        status: slotStatus,
        availabilityRank: this.getAvailableRank(slotStatus),
        unavailabilityType: unavailabilityType,
        unavailabilityRank: this.getUnavailableRank(unavailabilityType),
        newHour: newHour
      });
      if (newHour) {
        previousIndex = index;
      }
    });
    // Loop through slot availability and
    if (this.durationType !== 'Days') {
      var slotCount = this.durationType === 'Hours' ? this.duration * 4 : this.duration;
      if (slotAvailability.find(x => x.status === 'unavailable') !== undefined) {
        slotAvailability.reverse().forEach((val, index) => {
          if (val.status !== 'unavailable') {
            for (let i = index - 1; (i > (index - slotCount) && i >= 0); i--) {
              slotAvailability[i].status = val.status;
              slotAvailability[i].availabilityRank = val.availabilityRank;
              slotAvailability[i].unavailabilityType = val.unavailabilityType;
              slotAvailability[i].unavailabilityRank = val.unavailabilityRank;
            }
          }
        });
        slotAvailability.reverse();
      }
    }
    return slotAvailability;
  }

  /* Return the availability status based on inputs
       * <=0 is used just in case there are scheduling issues.  < would be enough if didn't ever over allocate
  */
  getAvailabilityStatus(totalSlots: number, usedSlots: number, scheduleLocked: boolean, slotBuffer: number): ScheduleAvailability {
    const canScheduleLockedDays: boolean = this.ups.canScheduleLockedDays$.value;
    return scheduleLocked && !canScheduleLockedDays || totalSlots - usedSlots <= 0 ? ScheduleAvailability.unavailable
      : totalSlots - (slotBuffer + usedSlots) <= 0 ? ScheduleAvailability.warning
        : ScheduleAvailability.available;
  }

  /* Return the availability status ranking based on inputs
   *    Used to simplify best/worse case ranking for timespan duration > 1 */
  getAvailableRank(availableType: string): AvailabilityRank {
    switch (availableType) {
      case ScheduleAvailability.available:
        return AvailabilityRank.available;
      case ScheduleAvailability.warning:
        return AvailabilityRank.warning;
      case ScheduleAvailability.unavailable:
        return AvailabilityRank.unavailable;
      default:
        return AvailabilityRank.available;
    }
  }

  /* Return the unavailable type based on inputs */
  getUnavailableType(totalSlots: number, usedSlots: number, scheduleLocked, dayStatus: string): UnavailableType {
    const canScheduleLockedDays: boolean = this.ups.canScheduleLockedDays$.value;

    return dayStatus === ScheduleAvailability.available ? UnavailableType.none
      : totalSlots - usedSlots <= 0 ? UnavailableType.allInstallersTaken
        : scheduleLocked && !canScheduleLockedDays ? UnavailableType.scheduleDayLocked
          : dayStatus === ScheduleAvailability.warning ? UnavailableType.none //check this after allinstallers take and lock days check
            : UnavailableType.spanNonWorkingDay;
  }

  /* Return the unavailable type ranking based on inputs
   *    Used to simplify best/worse case ranking for timespan duration > 1 */
  getUnavailableRank(unavailableType: UnavailableType): UnavailabilityRank {
    switch (unavailableType) {
      case UnavailableType.none:
        return UnavailabilityRank.allGood;
      case UnavailableType.allInstallersTaken:
        return UnavailabilityRank.allInstallersTaken;
      case UnavailableType.scheduleDayLocked:
        return UnavailabilityRank.scheduleDayLocked;
      case UnavailableType.spanNonWorkingDay:
        return UnavailabilityRank.spanNonWorkingDay;
      default:
        return UnavailabilityRank.allGood;
    }
  }

  /* Return the best case availability for the duration grouping.
   *   For am,  may have to go into the pm hours to determine availability.
   *   If duration is 5 hours and am is only 4 hours in length (8-12), have to look into some pm slots.
   * */
  getHalfDayAvailability(working: IWorkingAvailability, amCheck: boolean): string {
    let filtered: ISlotAvailability[] = [];
    let counter: number = 0;
    let bestGroupRank: AvailabilityRank = AvailabilityRank.unavailable;
    let bestGroupStatus: string = ScheduleAvailability.unavailable;
    let slotIncrement: number = (this.durationType === DurationType.hours ? 4 * this.duration : this.duration / 15);

    if (working.status === ScheduleAvailability.unavailable) {
      return ScheduleAvailability.unavailable;
    } else if (amCheck) {
      //for duration type of hours, need to include slots that accomodate durations that might extend past noon.
      const beforeNoon: number[] = working.slotAvailability
        .filter(x => x.newHour === true && new Date(x.startTime).getHours() < 12)
        .map(x => new Date(x.startTime).getHours());
      const diff: number = this.durationType === DurationType.hours ? this.duration - beforeNoon.length : 0;
      const hourLimit: number = 12 + diff;
      filtered = working.slotAvailability.filter(x => new Date(x.startTime).getHours() < hourLimit);
    } else {
      filtered = working.slotAvailability.filter(x => new Date(x.startTime).getHours() >= 12);
    }

    const len: number = filtered.length;
    for (var i = 0; i < len; i++) {
      counter = counter + 1;
      if (counter === slotIncrement) {
        if (filtered[i].availabilityRank < bestGroupRank) {
          bestGroupRank = filtered[i].availabilityRank;
          bestGroupStatus = filtered[i].status;
          counter = 0;
        } else {
          counter = 0;
        }
      }
    }

    return filtered.length === 0 ? ScheduleAvailability.unavailable : bestGroupStatus;
  }

  /*
  * Returns the css style to use on the ui availability indicator (ball).
  */
  getDayCssSuffix(day: ICalendarDay): string {
    return day.availability && day.availability.status
      ? 'schedule-calendar__days__day__inner__indicator--'.concat(day.availability.status === ScheduleAvailability.available ? 'g' : day.availability.status === ScheduleAvailability.unavailable ? 'r' : 'y')
      : '';
  }

  /*
  * Returns the css style to use on the ui availability indicator (ball).
  */
  getHalfDayCssSuffix(day: ICalendarDay, amCheck: boolean): string {
    let indicator: string = '';
    if (amCheck) {
      indicator = !day.availability ? '' : day.availability.am === 'warning' ? 'y' : day.availability.am === 'unavailable' ? 'r' : 'g';
    } else {
      indicator = !day.availability ? '' : day.availability.pm === 'warning' ? 'y' : day.availability.pm === 'unavailable' ? 'r' : 'g';
    }
    return 'schedule-calendar__days__day__inner__shading__half--'.concat(indicator);
  }


}
