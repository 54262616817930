
export interface IProgram {
  programId: number;
  generalContractor_generalContractorId: number;
  programName: string;
  programDescription: string;
  programPrecedence: number
}


export class Program implements IProgram {
  programId: number;
  generalContractor_generalContractorId: number;
  programName: string;
  programDescription: string;
  programPrecedence: number

  constructor() {
    this.programId = 0;
    this.generalContractor_generalContractorId = null;
    this.programName = '';
    this.programDescription = '';
    this.programPrecedence = null;
  }
}
