import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../../shared/constants/index';
import { IErrorData } from '../../../../../../shared/interfaces/index';
import { IFormDefinition, FormDefinition } from '../../../../../../fw/dynamic-forms/index';

import { JobScheduleJob } from '../../../../view-models/index';
import { CreateObjectModel, UpdateObjectByIdModel, IKey } from '../../../../../../fw/dynamic-list/store/interfaces/index';

//store actions and reducers
import * as fromDynamicList from '../../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromRoot from '../../../../../store/reducers/index';
import * as fromAuth from '../../../../../../auth/store/auth.reducer';
import * as fromStore from '../../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { IDetailContainerConfig } from '../../../../../../fw/dynamic-detail/interfaces';

@Component({
  selector: 'user-schedule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-schedule.component.html'
})
export class UserJobsScheduleComponent implements OnInit, OnDestroy {
  public operation: string;
  public myStoreName: string = '';
  public activeRecord: JobScheduleJob = null;
  public formDefinition$: BehaviorSubject<IFormDefinition> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public displayFields = ['isActive', 'jobScheduleName', 'jobScheduleDescription'];
  public errorMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  authState: fromAuth.IState;
  listStore: fromDynamicList.IListObjectData;
  myPathName: string = '';
  key: string = '';
  activeIndex: number = -1;
  activeRecordId: number = 0;
  activeEvent: IHomEventEmitter;
  subscription: Subscription = new Subscription();

  constructor(
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData> ) {

    this.subscription.add(emitterService.detailEventEmitted$.subscribe(
      (e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case this.myConstants.emitterEventCreate:
            this.createRecord(e);
            break;
          case this.myConstants.emitterEventClose:
            this.emitterService.emitListEvent(e);
            break;
          default:
            break;
        }
      }));
  }

  ngOnInit() {
    this.subscription.add(this.rootStore.select('auth')
      .pipe(take(1))
      .subscribe((authState: fromAuth.IState) => {
        this.authState = authState;
      }));

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.activeIndex = +paramMap.get('rowIndex');
      this.activeRecordId = +paramMap.get('id');
      this.key = paramMap.get('key');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.setDetailConfig(paramMap);

      this.subscription.add(this.store.pipe(select(fromStore.getEntityListByParentId(this.myStoreName, -1)))
        .subscribe((state: fromDynamicList.IListObjectData) => {
          const objData = state;

          this.myPathName = objData.listDefinition.detailRoutePath;
          this.formDefinition$.next(new FormDefinition(objData.listDefinition.objectLabel + (this.operation || this.myConstants.operationTypeDetails), false, false, this.myPathName));

          this.listStore = objData;
          this.activeRecord = this.operation === this.myConstants.operationTypeCreate ? this.activeRecord ? this.activeRecord : new JobScheduleJob()
            : objData.data[this.activeIndex];

          if (this.operation !== this.myConstants.operationTypeCreate &&
            (!this.activeRecord || this.activeRecord[this.key] != this.activeRecordId)) { //keep single = here
            let emitter: IHomEventEmitter = {
              requestor: this.myPathName,
              event: this.myConstants.emitterEventError,
              action: '',
              data: 'DETAIL: UserJobsScheduleComponent detail with no detail record'
            };
            this.emitterService.emitListEvent(emitter);
            return;
          }

          if (objData.errorData || objData.message) {
            this.errorData$.next( objData.errorData );
            this.errorMessage$.next( objData.message);
          } 
        }));
    });

  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.useRouterOutlet = false;
    this.detailConfig$.next(params);
  }

  createRecord(e: IHomEventEmitter) { //TODO - need entity table change so can set these in db
    //will need the fields that can be updated or can I determine?

    this.activeEvent = e;
    this.setCommonProperties(e.data);

    if (this.activeRecord.hasOwnProperty('providerUser_providerUserId')) {
      this.activeRecord.providerUser_providerUserId = this.authState.providerUser.currentUserId;
    }
    if (this.activeRecord.hasOwnProperty('createDate')) {
      this.activeRecord['createDate'] = Date.now();
    }

    const emitter: IHomEventEmitter = { requestor: this.listStore.listDefinition.detailRoutePath, event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(this.myStoreName, -1, this.listStore.listDefinition.controllerName, 'Create', this.activeRecord, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }

  updateRecord(e: IHomEventEmitter) {

    this.activeEvent = e;

    this.setCommonProperties(e.data);
    const keyData: IKey = { storeName: this.myStoreName, parentId: -1, key: this.key, id: this.activeRecordId, }
    const emitter: IHomEventEmitter = { requestor: this.listStore.listDefinition.detailRoutePath, event: this.myConstants.emitterEventUpdate, action: e.action, data: null };
    const updateData = new UpdateObjectByIdModel(keyData, this.listStore.listDefinition.controllerName, 'Update', this.key, this.activeRecordId, this.activeRecord, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectByIdList({ updateData }));
  }

  setCommonProperties(data: JobScheduleJob ) {
    this.errorMessage$.next('');
    this.errorData$.next([]);

    if (this.activeRecord.hasOwnProperty('updateDate')) {
      this.activeRecord['updateDate'] = Date.now();
    }
    if (this.activeRecord.hasOwnProperty('jobScheduleJobName')) {
      this.activeRecord['jobScheduleJobName'] = data['jobScheduleJobName'];
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
