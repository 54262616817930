
export enum AppAction {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
  uploadFailed = 'uploadFailed',
  accessError = 'accessError',
  versionMismatch = 'versionMismatch'
}
