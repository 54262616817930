import { Component, OnInit, Input, Inject, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep, orderBy } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import {  IWorkOrderViewModel } from '../../../view-models';
import { ICalendarGridHeader, ICalendarGrid } from '../../interfaces';
import { UtilitiesStore } from '../../enums/utilities.enums';

import { DispatchCalendarService } from '../../services/dispatch-calendar.service';

@Component({
  selector: 'dispatch-day-view',
  templateUrl: './dispatch-day-view.component.html'
})
export class DispatchDayViewComponent implements OnInit, OnChanges {
  @Input() data: IWorkOrderViewModel[];
  @Input() selectedDate: string;
  @Input() forView: string;
  @Input() holidays: string[];
  @Input() canScheduleLockDays: boolean;
  @Input() showHeader: boolean = true;
  @Input() includeTools: boolean = true;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public headers: ICalendarGridHeader[] = [];
  public gridData$: BehaviorSubject<ICalendarGrid[]> = new BehaviorSubject(null);
  public storeName: string;
  public maxRows: number[];
  public isHoliday: boolean = false;
  subscription = new Subscription();

  constructor(public datePipe: DatePipe,
    public dispatchCalendarService: DispatchCalendarService,
   @Inject(appConstants) public myConstants: IAppConstants) { }


  public onCustom(event: IHomEventEmitter): void {
    this.customEvent.emit(event);
  }

  public showWOPrevArrow(row: IWorkOrderViewModel): boolean {
    let show: boolean = false;
    if (row.durationType === this.dispatchCalendarService.typeDays && row.duration > 1) {
      const startDate = this.datePipe.transform(new Date(row.scheduleStartDate), 'MM/dd/yyy');
      const selectedDate = this.datePipe.transform(new Date(this.selectedDate), 'MM/dd/yyy');
      show = startDate < selectedDate;
    }
    return show;
  }

  public showWONextArrow(row: IWorkOrderViewModel): boolean {
    let show: boolean = false;
    if (row.durationType === this.dispatchCalendarService.typeDays && row.duration > 1) {
      const endDate = this.datePipe.transform(new Date(row.scheduleEndDate), 'MM/dd/yyy');
      const selectedDate = this.datePipe.transform(new Date(this.selectedDate), 'MM/dd/yyy');
      show = endDate > selectedDate;
    }
    return show;
  }

  ngOnInit() {
    this.storeName = UtilitiesStore.dashCalendar;
    this.checkForHoliday();
    this.setHeaders();
    this.setGridPositions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && !(changes['data'].firstChange)) {
    this.setGridPositions();
    }
    if (changes['selectedDate'] && !(changes['selectedDate'].firstChange)) {
     this.checkForHoliday();
    }
  }

  setHeaders(): void {
    this.headers = [
      { colStart: 1, colEnd: 5, label: '7 AM' },
      { colStart: 5, colEnd: 9, label: '8 AM' },
      { colStart: 9, colEnd: 13, label: '9 AM' },
      { colStart: 13, colEnd: 17, label: '10 AM' },
      { colStart: 17, colEnd: 21, label: '11 AM' } ,
      { colStart: 21, colEnd: 25, label: '12 PM' },
      { colStart: 25, colEnd: 29, label: '1 PM' },
      { colStart: 29, colEnd: 33, label: '2 PM' },
      { colStart: 33, colEnd: 37, label: '3 PM' },
      { colStart: 37, colEnd: 41, label: '4 PM' },
      { colStart: 41, colEnd: 45, label: '5 PM' },
      { colStart: 45, colEnd: 49, label: '6 PM'} ];
  }

  setGridPositions(): void {
    this.gridData$.next(null);
    let gridData: ICalendarGrid[] = [];
    const daysData = cloneDeep(this.data.filter(x => x.durationType === this.dispatchCalendarService.typeDays && x.duration > 0));
    //measures are coming across with start and end times the same.
    //resort small jobs because duration type doesn't matter
    let smallJobsData: IWorkOrderViewModel[] =
      orderBy(cloneDeep(this.data.filter(x => x.durationType !== this.dispatchCalendarService.typeDays || (x.durationType === this.dispatchCalendarService.typeDays && x.duration === 0))),
      ['scheduleStartDate', 'durationType', 'duration', 'workOrderId'],
        ['asc', 'asc', 'desc', 'asc']);

    let rowStart: number = 2;
    let rowEnd: number = 3;
    daysData.forEach((item) => {
      //one per row
        gridData.push({
          rowStart: rowStart,
          rowEnd: rowEnd,
          colStart: this.getColStart(item),
          colEnd:  this.getColEnd(item),
          data: item,
          cssName: this.getServiceName(item)
        });
      rowStart = rowStart + 1;
      rowEnd = rowEnd + 1;
    });

   //now load up the small jobs records
    //will need to do multiple loops until all rows have been placed.
    smallJobsData.forEach(x => x.used = false);
    let allUsed: boolean = smallJobsData.length > 0 ? false : true;
    let count: number = 1;
    while (!allUsed) {
      let prevEnd: number = 0;
      smallJobsData.filter(d => d.used === false).forEach((item) => {
        if (!item.used) {
          let colStart = this.getColStart(item);
          let colEnd = this.getColEnd(item);
          if (colStart >= prevEnd) {
            gridData.push({
              rowStart: rowStart,
              rowEnd: rowEnd,
              colStart: colStart,
              colEnd: colEnd,
              data: item,
              cssName: this.getServiceName(item)
            });
            item.used = true;
            prevEnd = colEnd;
          }
        }
      });
      rowStart = rowStart + 1;
      rowEnd = rowEnd + 1;

      const remaining: IWorkOrderViewModel[] = smallJobsData.filter(x => x.used === false);
      if (!remaining || !remaining.length) {
        allUsed = true;
      }

      //In emergency, break glass.  Here in case hit any additional unexpected data cases.
      count = count + 1;
      if (count > smallJobsData.length && !allUsed) {
        console.log('DEV ERROR:  Unexpected data and loop is not handling.  data: ', smallJobsData);
        allUsed = true;
      }
    }

    const orderByTemp = gridData.length ? orderBy(gridData, ['rowEnd'], ['desc']) : null;
    let rows: number = orderByTemp ? orderByTemp[0].rowEnd - 1 : gridData.length;
    this.maxRows = new Array(rows < 3 ? 3 : rows);
    this.gridData$.next(gridData);
  }

  getColStart(item: IWorkOrderViewModel): number {
    return this.dispatchCalendarService.getColByTime(this.datePipe.transform(item.scheduleStartDate, "HH:mm"));
  }

  getColEnd(item: IWorkOrderViewModel): number {
     return  this.dispatchCalendarService.getColByTime(this.datePipe.transform(item.scheduleEndDate, "HH:mm"));
  }

  getServiceName(row: IWorkOrderViewModel): string {
    var serviceName: string = row && row.hasOwnProperty('serviceName') ? row.serviceName.split(" ").join("-").replace("/", "-").trim() : "";
    return serviceName.toLowerCase();
  }

  checkForHoliday(): void {
    const thisDay = this.datePipe.transform(new Date(this.selectedDate), 'MM/dd/yyyy');
    const data = this.holidays.find(x => x == thisDay);
    this.isHoliday =  data ? true : false;
  }

 }
