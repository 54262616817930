<fw-component-title [title]="'Create Warranty'"></fw-component-title>
<div *ngIf="createForm && noteForm" class="nested-panel">
  <fw-section-title title="Warranty Type" [underline]="true" icon="fa-ambulance"></fw-section-title>
  <form [formGroup]="createForm" novalidate class="form-wrapper form-wrapper--edit">

    <fw-dynamic-field [field]="projectMds.getFieldDefinition('warrantyTypeName')"
                      [form]="createForm"
                      [operation]="operation"
                      [selectItems]="warrantyTypes$ | async">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="projectMds.getFieldDefinition('warrantySubTypeName')"
                      [form]="createForm"
                      [selectItems]="warrantySubTypes"
                      [operation]="!(warrantySubTypes) || !(warrantySubTypes).length ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="projectMds.getFieldDefinition('warrantyIsUrgent')"
                      [form]="createForm"
                      [operation]="operation">
    </fw-dynamic-field>
  </form>

  <div *ngIf="noteFormDef">
    <fw-section-title title="Initial Project Note" [underline]="true" icon="fa-sticky-note"></fw-section-title>
    <div class="form-wrapper form-wrapper--edit">
      <fw-dynamic-field *ngFor="let key of noteFields"
                        [field]="noteMds.getFieldDefinition(key)"
                        [form]="noteForm"
                        [operation]="operation">
      </fw-dynamic-field>
    </div>
  </div>
  <fw-crud-buttons [operation]="operation"
                   [disabled]="!createForm.valid || !noteForm.valid"
                   showNext="false"
                   (cancelEvent)="cancel()"
                   (createEvent)="create()">
  </fw-crud-buttons>
  <ng-content></ng-content>
</div>
