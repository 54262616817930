import { Injectable } from '@angular/core';

import { IIconFiles } from '../interfaces/i-icon-files';
import { IFrameworkConfigSettings } from '../interfaces/i-framework-config-settings';

@Injectable()
export class FrameworkConfigService {
    showUserControls = false;
    showSearchBar = false;
    showStatusBar = false;
    showStatusBarBreakpoint = 0;
    isDelegate: false;
    appIcons = new Array<IIconFiles>();

    configure(settings: IFrameworkConfigSettings) : void {
        Object.assign(this, settings);
    }


}
