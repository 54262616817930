<div [ngClass]="{ 'loading-mask': loading$ | async }">
  <ng-container *ngIf="listTitle">
    <fw-component-title [title]="listTitle"
                        [userFilters]="userFilters$ | async"
                        [infoText]="infoText"
                        [childVisible]="childVisible$ | async"
                        (changeFilter)="changeUserFilter($event)">
    </fw-component-title>
  </ng-container>
  <ng-content select="[drop-below-list-title]"></ng-content>

  <ng-container *ngIf="storeData && storeData?.listMetaData && !isSummary">
    <div class="dynamic-list-toolbar-row" [ngClass]="{'dynamic-list-toolbar-row--hasFilter': (activeFilterInfo$ | async) }">
      <div *ngIf="hasStaleData$ | async" class="dynamic-list-toolbar-row__stale-msg dynamic-list-toolbar-row--msg">
        <span class="app-icon--danger"><i class="fad fa-alarm-clock"></i></span>
        The list data was retrieved {{ dataAge$ | async }} minutes ago, please refresh the list to ensure your data is accurate.
      </div>
      <div *ngIf="hasPendingChildCreate" class="dynamic-list-toolbar-row__pending-msg dynamic-list-toolbar-row--msg">
        <span class="app-icon--danger"><i class="fad fa-inbox"></i></span>
        {{ myConstants.errorPendingCreateList }}
      </div>
      <div class="dynamic-list-toolbar-row__filter">
        <div *ngIf="(workingFilterInfo$ | async) as workingFilterInfo" class="dynamic-list-toolbar-row__filter__working">
          <span class="dynamic-list-toolbar-row__filter__label">
            Pending
            <i class="fas fa-filter app-btn-icon--info"></i>: {{ workingFilterInfo }}
          </span>
        </div>
        <div *ngIf="(activeFilterInfo$ | async) as activeFilterInfo" class="dynamic-list-toolbar-row__filter__active">
          <span class="dynamic-list-toolbar-row__filter__label">
            Active
            <i class="fas fa-filter app-icon--success"></i>: {{activeFilterInfo}}
          </span>
        </div>
      </div>

      <fw-dynamic-list-tool-bar class="u-display-contents"
                                [listDefinition]="vmListDefinition"
                                [listMetaData]="storeData?.listMetaData"
                                [staleData]="hasStaleData$ | async"
                                [disabled]="toolbarDisabled"
                                [dataAge]="dataAge$ | async"
                                (showCreate)="showCreate($event)">
      </fw-dynamic-list-tool-bar>
    </div>

    <ng-container *ngIf="storeData && fieldDefinitions.length">
      <div class="dynamic-list-grid">
        <!--LIST VIEW-->
        <div class="dynamic-list dynamic-list--all-cols"
             [ngClass]="{'dynamic-list-hidden': currentChildVisible}"
             [ngStyle]="{'grid-template-columns':  gridTemplateCols$ | async }">
          <fw-dynamic-list-header-row class="dynamic-list-component"
                                      [listDefinition]="vmListDefinition"
                                      [fieldDefinitions]="fieldDefinitions"
                                      [screenSize]="screenSize$ | async"
                                      [inFilterMode]="inFilterMode"
                                      [searchTerms]="listFilter.searchTerm"
                                      [listDisabled]="disabled"
                                      [orderTerms]="listFilter ? listFilter.orderTerm : []"
                                      [hasData]="storeData.data && storeData.data.length > 0 ? true : false">
          </fw-dynamic-list-header-row>

          <fw-dynamic-list-filter-row class="dynamic-list-component"
                                      [ngClass]="{'dynamic-list-hidden': !inFilterMode}"
                                      [listDefinition]="vmListDefinition"
                                      [fieldDefinitions]="fieldDefinitions"
                                      [fieldMetaData]="storeData?.listMetaData?.seedData?.metaData"
                                      [searchTerms]="listFilter.searchTerm"
                                      [screenSize]="screenSize$ | async"
                                      [operation]="currentOperation"
                                      [parentId]="portalEntityId"
                                      [showFilter]="inFilterMode"
                                      [filterDisabled]="vmListDefinition.noGet ? (!pendingTerms || !pendingTerms.length) : false">
          </fw-dynamic-list-filter-row>

          <!--BEGIN RENDERING EACH ROW-->
          <ng-container *ngIf="storeData?.listMetaData">
            <ng-container *ngFor="let row of storeData?.data; let rowIndex = index;  let lastRow = last">
              <fw-dynamic-list-data-row class="dynamic-list-component"
                                        [index]="rowIndex"
                                        [row]="row"
                                        [colCount]="colCount$ | async"
                                        [listDefinition]="vmListDefinition"
                                        [fieldDefinitions]="fieldDefinitions"
                                        [screenSize]="screenSize$ | async"
                                        [portalEntityId]="portalEntityId"
                                        [listDisabled]="disabled"
                                        [isDeleting]="isDeleting"
                                        [activeDeleteId]="deleteId"
                                        [activeIndex]="activeIndex"
                                        [activeOptionIndex]="activeOptionIndex"
                                        [allSelected]="allSelected"
                                        [navClosed]="navClosed"
                                        [detailError]="errorData || errorMessage"
                                        [lastRow]="lastRow">
              </fw-dynamic-list-data-row>
            </ng-container>
          </ng-container>
          <!--END RENDERING EACH ROW-->
        </div>

        <!--CHILD VIEW -->
        <ng-container *ngIf="storeData?.listMetaData && currentChildVisible && currentOperation !== myConstants.operationTypeCreate">
          <div *ngIf="storeData?.data.length && !(screenSize$ | async).screenIsSmall" class="dynamic-list " [ngClass]="{ 'dynamic-list__nav-flat' : navClosed, 'dynamic-list__nav': !navClosed }">
            <!--NAV HEADERS -->
            <div class="dynamic-list__nav__header" [ngClass]="{ 'dynamic-list__nav-flat--hidden' : navClosed }">
              <div class="dynamic-list__nav__header--text" title="{{ vmListDefinition.objectLabel }}">{{ vmListDefinition.objectLabel }}</div>
            </div>
            <!--<div *ngIf="(screenSize$ | async).screenIsMax" class="dynamic-list__nav__header dynamic-list__nav__header-icon" [ngClass]="{ 'dynamic-list__nav__header--collapsed' : navClosed }"></div>-->
            <!--NAV RECORDS-->
            <div class="dynamic-list__nav__items" [ngClass]="{ 'dynamic-list__nav-flat--hidden' : navClosed }">
              <ng-container *ngFor="let row of  storeData?.data; let rowIndex = index">
                <fw-dynamic-list-nav-data-row class="dynamic-list-component"
                                              [index]="rowIndex"
                                              [row]="row"
                                              [listDefinition]="vmListDefinition"
                                              [screenSize]="screenSize$ | async"
                                              [portalEntityId]="portalEntityId"
                                              [activeIndex]="activeIndex"
                                              [activeOptionIndex]="activeOptionIndex"
                                              [activeDeleteId]="deleteId"
                                              [detailError]="errorData || errorMessage"
                                              [navClosed]="navClosed"
                                              [operation]="currentOperation">
                </fw-dynamic-list-nav-data-row>
              </ng-container>
            </div>
            <!--Collapse button-->
            <div *ngIf="navClosed" class="dynamic-list__nav-flat__lbl-box">
              <div class="dynamic-list__nav-flat__label" title="{{ vmListDefinition.objectLabel }}">{{ vmListDefinition.objectLabel }}</div>
            </div>
            <div [ngClass]="{ 'dynamic-list__nav-flat__btn-box' : navClosed, 'dynamic-list__nav__btn-box': !navClosed }">
              <button class="app-btn-icon--primary dynamic-list__nav__btn" [ngClass]="{ 'dynamic-list__nav__btn--collapsed' : navClosed }"
                      (click)="collapseNav()" title="{{ navClosed ? 'Show Navigation' : 'Hide Navigation' }}">
                <span class="fas fa-caret-up"></span>
              </button>
            </div>
          </div>
        </ng-container>

        <!--must be rendered - do not wrap in ngif-->
        <div class="dynamic-list-router-outlet" [ngClass]="{'dynamic-list-router-outlet--visible': currentChildVisible  }" [hidden]="!currentChildVisible">
          <router-outlet [name]="vmListDefinition.routerOutlet"></router-outlet>
        </div>

      </div> <!--end of dynamic-list-grid-->

    </ng-container>
    <div *ngIf="!storeData?.data.length && currentOperation !== myConstants.operationTypeCreate" class="dynamic-list__no-data"
         [ngStyle]="{'grid-column':  '1 / ' + (colCount$ | async) }">
      No Records Found
    </div>
    <div class="dynamic-list__footer">
      <ng-container *ngIf="(pageMetaData$ | async) as pageMetaData">
        <div *ngIf="(pageMetaData.totalItems  || pendingTerms.length > 0) && currentOperation  !== myConstants.operationTypeCreate " class="dynamic-list__footer--count-text">
          {{ pageMetaData.totalItems }} Items
        </div>
      </ng-container>
      <ng-container *ngIf="!(pageMetaData$ | async)">
        <div *ngIf="storeData?.data.length && currentOperation !== myConstants.operationTypeCreate">
          <div class="dynamic-list__footer--count-text">{{ storeData.data.length }} Items  </div>
        </div>
      </ng-container>
      <fw-component-footer class="dynamic-list__footer--date"
                           [ngClass]="{'dynamic-list__footer--date--stale': (hasStaleData$ | async) }"
                           [lastUpdate]="(lastUpdated$ | async)"
                           [isFormatted]="true"
                           [label]="'List Loaded On'">
      </fw-component-footer>
    </div>

    <ng-container *ngIf="(pageMetaData$ | async) as pageMetaData">
      <pagination *ngIf="pageMetaData.totalItems && !currentChildVisible"
                  [restrict]="false"
                  [config]="pageMetaData"
                  (action)="pageChanged(e = $event)">
      </pagination>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isSummary && storeData?.data.length">
    <div class="dynamic-list__summary">
      <div class="dynamic-list__summary--label">
        Total Items:
      </div>
      <ng-container *ngIf="(pageMetaData$ | async) as pageMetaData">
        <div *ngIf="(pageMetaData.totalItems  || pendingTerms.length > 0)" class="dynamic-list__summary--cnt">
          {{ pageMetaData.totalItems }}
        </div>
      </ng-container>
      <div *ngIf="!(pageMetaData$ | async)" class="dynamic-list__summary--cnt">
        {{ storeData.data.length }}
      </div>
      <ng-container *ngIf="(activeFilterInfo$ | async) as activeFilterInfo">
        <div class="dynamic-list__summary--label">
          With Filter:
        </div>
        <div class="dynamic-list__summary--filter">
          {{activeFilterInfo}}
        </div>
      </ng-container>
    </div>
  </ng-container>

  <fw-error-box *ngIf="!currentChildVisible" [errorData]="errorData$ | async"></fw-error-box>
</div>
