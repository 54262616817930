import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IPayout, ISku } from '../../../view-models';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { SkuCreationWizardAction, SkuCreationWizardEvent, PricingStore } from '../../enums/pricing-shared.enums';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { AdminStore, PayoutMaintEvent } from '../../../admin/enums/admin.enums';
import { SetListDefinition, SetWorkingList, SetEventList } from '../../../../../fw/dynamic-list/store';

import { DomainObjectService } from '../../../../../shared/services';
import { PricingSharedService } from '../../services/pricing-shared.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'sku-payout-link',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sku-payout-link.component.html'
})
export class SkuPayoutLinkComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public selectedPayouts: IPayout[] = [];
  public selectedSkus: ISku[] = [];
  subscription: Subscription = new Subscription();
  skuId: number = 0;
  payoutId: number = 0;
  inWizard: boolean = false;
  next: string;
  close: string;

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public rootStore: Store<fromRoot.IState>,
    public pricingService: PricingSharedService,
    public emitterService: HomEventEmitterService,
    public domainObjectService: DomainObjectService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public ngOnInit() {
    //local to ensure any label changes stay consistent
    this.next = SkuCreationWizardAction.next;
    this.close = SkuCreationWizardAction.close;

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.skuId = +paramMap.get('skuId');
      this.payoutId = +paramMap.get('payoutId');
      this.inWizard = paramMap.get('wiz') ? true : false;
      this.setListDefinition();
    });
  }

  public onFilter(event: any): void {
    this.selectedSkus = this.selectedPayouts = [];
    this.errorData$.next([]);
    this.errorMessage$.next('');
  }

  public linkItems(action: string): void {
    if (this.skuId > 0) {
      this.linkPayouts(action);
    } else {
      this.linkSkus(action);
    }
  }

  public cancel(): void {
    const e: IHomEventEmitter = this.skuId > 0
      ? { requestor: 'sku-payout-link', event: SkuCreationWizardEvent.cancel, action: '', data: null }
      : { requestor: 'payout-sku-link', event: this.myConstants.emitterEventClose, action: '', data: null };

    if (this.skuId > 0) {
      const e: IHomEventEmitter = { requestor: 'sku-payout-link', event: SkuCreationWizardEvent.cancel, action: '', data: null };
      this.emitterService.emitSkuWizardEvent(e);
    } 
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  public onSelect(event: IHomEventEmitter) {
    switch (event.event) {
      case SkuCreationWizardEvent.selectPayout:
        this.updateSelected(event);
        break;
      case PayoutMaintEvent.selectSku:
        this.updateSelected(event);
        break;
      default:
        break;
    }
  }

  public onListPage(event: IHomEventEmitter): void {
    this.selectedSkus = this.selectedPayouts = [];
    this.errorData$.next([]);
    this.errorMessage$.next('');
  }

  setListDefinition(): void {
    this.selectedSkus = this.selectedPayouts = [];
    const listDefinition: IListDefinition = this.skuId > 0
      ? this.pricingService.loadPayoutLinksListDefinition()
      : this.pricingService.loadSkuLinksListDefinition();
    this.store.dispatch(new SetListDefinition({ storeName: listDefinition.storeName, parentId: listDefinition.parentId, listDefinition: listDefinition }));
    this.store.dispatch(new SetWorkingList({ storeName: listDefinition.storeName, parentId: listDefinition.parentId, working: false }));
    this.listDefinition$.next(listDefinition);
  }

  updateSelected(event: IHomEventEmitter): void {
    const item: any = this.skuId > 0 ? event.data as IPayout : event.data as ISku;
    const exists: number = this.skuId > 0
      ? this.selectedPayouts.findIndex(x => x.payoutId === item.payoutId)
      : this.selectedSkus.findIndex(x => x.skuId === item.skuId);
    if (event.action) {
      if (exists === -1) {
        if (this.skuId > 0) {
          this.selectedPayouts.push(item);
        } else {
          this.selectedSkus.push(item);
        }
      }
    } else {
      if (exists > -1) {
        if (this.skuId > 0) {
          this.selectedPayouts.splice(exists, 1);
        } else {
          this.selectedSkus.splice(exists, 1);
        }
      }
    }
  }

  linkPayouts(action: string): void {
    this.working$.next(true);
    this.errorData$.next([]);
    this.errorMessage$.next('');
    let obj = { skuId: this.skuId, payoutIds: this.selectedPayouts.map(x => x.payoutId) };
    this.subscription.add(this.domainObjectService.updateByMethod('SkuPayout', 'LinkPayouts', obj)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          this.selectedPayouts = [];
          //emit next back to creation wizard
          if (this.inWizard) {
            const e: IHomEventEmitter = { requestor: 'sku-payout-link', event: SkuCreationWizardEvent.payoutLinked, action: action, data: null }
            this.emitterService.emitSkuWizardEvent(e);
          } else {
            const emitter: IHomEventEmitter = { requestor: 'sku-creation-wizard', event: this.myConstants.emitterEventListReload, action: '', data: null };
            this.store.dispatch(new SetEventList({
              storeName: AdminStore.skuPayouts,
              parentId: this.skuId,
              event: emitter
            }));
            if (this.modalService.opened) {
              this.modalService.close();
            }
          }
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errorMessage$.next(error); }
      ));
  }

  linkSkus(action: string): void {
    this.working$.next(true);
    this.errorData$.next([]);
    this.errorMessage$.next('');
    let obj = { payoutId: this.payoutId, skuIds: this.selectedSkus.map(x => x.skuId) };
    this.subscription.add(this.domainObjectService.updateByMethod('SkuPayout', 'LinkSkus', obj)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          this.selectedSkus = [];
          const emitter: IHomEventEmitter = {
            requestor: 'payout-sku-link',
            event: this.myConstants.emitterEventListReload,
            action: '',
            data: null
          };
          this.store.dispatch(new SetEventList({
            storeName: PricingStore.payoutSkus,
            parentId: this.payoutId,
            event: emitter
          }));
          //action is only applicable for wizard at this time
          //so for linking skus, can always close modal.
          if (this.modalService.opened) {
            this.modalService.close();
          }
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errorMessage$.next(error); }
      ));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
