// Resolver if going from outside installer portal into a tab request for a technician
import { Injectable,  OnDestroy} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable,  Subscription, of} from 'rxjs';
import { take, filter, map, first, mergeMap } from 'rxjs/operators';

import { ProjectService } from '../services/project.service';
import { SelectionListService } from '../../../../shared/services/selection-list.service';

//store actions and reducers
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../store/reducers/index';
import * as fromDynamicList from '../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IDynamicListState, IListObjectData } from '../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { metaDataExists } from '../../../../fw/dynamic-list/store/selectors/meta-data.selectors';

import { IFieldDefinition } from '../../../../fw/dynamic-forms/index';
import { IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';

import * as LoadingIndicatorActions from '../../../../shared/store/loadingIndicator/loadingIndicator.actions';
import { ISelectResolverData } from '../../../../shared/interfaces';


@Injectable()
export class WorkOrderResolver  implements OnDestroy {
  storeName: string;
  operation: string;
  myId: number;
  parentId: number;
  currentUserId: number;
  fieldDefinitions: IFieldDefinition[] = [];
  seedData: any = {};
  selectStoreNames: string[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public projectService: ProjectService,
    public selectionListService: SelectionListService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const projectId = +route.parent.paramMap.get('portalEntityId');
    const woId = +route.paramMap.get('id');

    const listDefinition: IListDefinition = this.projectService.loadProjectWorkOrderListDefinition(projectId, true, false, false);
    this.storeName = listDefinition.storeName;


    //clear out any list level errors received
    this.store.dispatch(new fromStore.ClearErrorsList({ storeName: this.storeName, parentId: projectId }));

    this.subscription.add(this.store.pipe(select(fromDynamicList.getListByType(this.storeName)), take(1))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == projectId)))
      .subscribe((store: IListObjectData) => {
        if (!store || !store.data || store.staleData) {
          //set the bare min for this resolver
          this.store.dispatch(new fromStore.SetListDefinition({ storeName: this.storeName, parentId: projectId, listDefinition: listDefinition }));
          this.store.dispatch(new fromStore.GetList({ listDefinition: listDefinition, listFilter: listDefinition.defaultListFilter, parentId: projectId }));
        }
      }));

    //wait for meta data for this object to load, then request selection list data and wait for it to load.
    return this.metaLoaded().pipe(
      mergeMap((loaded: boolean) => {
        let resolverData: ISelectResolverData[] = this.selectionListService.getListNames(this.storeName);
        this.selectionListService.requestListData(this.storeName, projectId);
        resolverData.forEach(x => x.parentId = x.parentDependent ? projectId : -1);

        return this.selectionListService.waitForDataToLoad(resolverData)
          .pipe(
            map((complete: boolean) => {return complete }),
            filter((complete: boolean) => complete === true),
            take(1),
            map(() => {
              return true;
            }),
            first()
          )
      })
    );
  }

  metaLoaded(): Observable<boolean> {
    return this.store.select(metaDataExists(this.storeName))
      .pipe(
        map((exists: boolean) => {
          return exists;
        }),
        filter((exists: boolean) => exists === true),
        take(1),
        map(() => { return true; }),
        first()
      );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
