import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'project-charge-back-items',
  templateUrl: './project-charge-back-items.component.html'
})

export class ProjectChargeBackItemsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() chargeBackId: number;
  @Input() canIEdit: boolean;

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  subscription: Subscription = new Subscription();

  constructor(
    public projectService: ProjectService) {}

  ngOnInit() {
    this.setListDefinition();
  }

  setListDefinition(): void {
    this.listDefinition$.next(this.projectService.loadChargeBackItemListDefinition(this.chargeBackId, this.canIEdit));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['canIEdit'] && !changes['canIEdit'].isFirstChange()) {
      this.setListDefinition();
    }
    if (changes['chargeBackId'] && !changes['chargeBackId'].isFirstChange()) {
      this.setListDefinition();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
