export interface ISssGeneralContractorProgram {
  sssGeneralContractorProgramId: number;
  sssGeneralContractor_sssGeneralContractorId: number;
  program_programId: number;
  excludeAllProgramServices: boolean;
  dateRange: number;
  allowSlotBufferReservations: boolean;
  etaBuffer: number;
  minimumDuration: number;

  //extraData
  generalContractorId: number;
  programName: string;
  gcExcludingPrograms: boolean;
}

export class SssGeneralContractorProgram implements ISssGeneralContractorProgram {
  sssGeneralContractorProgramId: number;
  sssGeneralContractor_sssGeneralContractorId: number;
  program_programId: number;
  excludeAllProgramServices: boolean;
  dateRange: number;
  allowSlotBufferReservations: boolean;
  etaBuffer: number;
  minimumDuration: number;
  //extraData
  generalContractorId: number;
  programName: string;
  gcExcludingPrograms: boolean;

  constructor() {
    this.sssGeneralContractorProgramId = 0;
    this.sssGeneralContractor_sssGeneralContractorId = null;
    this.program_programId = null;
    this.excludeAllProgramServices = false;
    this.dateRange = 0;
    this.allowSlotBufferReservations = false;
    this.etaBuffer = 0;
    this.minimumDuration = 0;

    //extraData
    this.generalContractorId = 0;
  }
}
