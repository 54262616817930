import { Injectable, Inject } from '@angular/core';
import { IListFilter, OrderTerm } from '../../../../fw/dynamic-list/interfaces';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { appConstants, IAppConstants } from '../../../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ProviderUserService   {
  storeName: string = 'providerUser';

  defaultListFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: true,
      currentPage: 1,
      searchTerm: [{ term: 'contact_contactId', value: null, searchType: SearchType.Equals, columnName: 'contact', fieldType: this.myConstants.dataTypeInt }],
      orderTerm: [new OrderTerm('lastName')]
    };

  constructor(@Inject(appConstants) public myConstants: IAppConstants) { }

}

