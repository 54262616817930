<div class="component__detail-box">
  <fw-component-title title="Installer Portal Technician Role Create"></fw-component-title>
  <installer-work-crew-technician-role [installerWorkCrewTechnicianId]="installerWorkCrewTechnicianId"
                                       [installerRoles]="availableRoles$ | async"
                                       [operation]="operation"
                                       (cancelEvent)="cancel()"
                                       (createEvent)="create($event)">
  </installer-work-crew-technician-role>
  <fw-error-box [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>

