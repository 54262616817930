import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject,  ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IUserRoleViewModel, IProviderRole, IInstallerWorkCrewTechnician} from '../../../view-models/index';
import { IErrorData } from '../../../../../shared/interfaces/index';

import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromRoot from '../../../../../app/store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { UserPriviledgesService } from '../../../../../auth/services';
import { IStoreState, getSelectionListByType, ISelectionList } from '../../../../../shared/store/selectionLists';
import { InstallerRole, InstallerSelectList, InstallerStore } from '../../enums/installer.enums';
import { CreateObjectList, getObjectDataById } from '../../../../../fw/dynamic-list/store/index';

@Component({
  selector: 'installer-work-crew-technician-role-container',
  templateUrl: './installer-work-crew-technician-role-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstallerWorkCrewTechnicianRoleContainerComponent implements OnInit, OnDestroy {

  public operation: string = ''; //will always come in as create
  public installerWorkCrewTechnicianId: number;
  public providerUserId: number;
  public providerRoleId: number;
  public availableRoles$: BehaviorSubject<IProviderRole[]> = new BehaviorSubject(null);
  public currentRoleIds: number[] = [];
  public errorData$: BehaviorSubject<IErrorData[]>;

  storeName: string = '';
  key: string = '';
  allRoles: IProviderRole[] = [];
  subscription: Subscription = new Subscription();

  constructor(public readonly rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public selStore: Store<IStoreState>,
    public emitterService: HomEventEmitterService,
    public userPriviledgesService: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {
        this.errorData$ = new BehaviorSubject([]);
    }

  //only create for this container, either add role or delete role
  ngOnInit() {
    this.subscription.add(this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.installerWorkCrewTechnicianId = +paramMap.get('id');
    }));

    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.providerRoleId = +paramMap.get('id');  
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      //if needed portalEntityId = installerId

      this.setSubscribes();
    }));
  }


  public cancel(): void {
    let emitter: IHomEventEmitter = {
      requestor: 'iwct-role',
      event: this.myConstants.emitterEventClose,
      action: '',
      data: null
    };
    this.emitterService.emitListEvent(emitter);
  }

  public create(data: IProviderRole) {
    if (this.providerUserId === 0) {
      //not allowed, must be aprovider user before adding additional roles.
      return;
    }
    let model: IUserRoleViewModel = {
      providerUserId: this.providerUserId,
      providerId: this.userPriviledgesService.providerId$.value,
      providerRoleId: data.providerRoleId,
      providerUserName: '', //not needed for create, really just need providerUserId and roleId
      providerRoleName: data.roleName,
    }

    const emitter: IHomEventEmitter = {
      requestor: 'installer-work-crew-technician-role',
      event: this.myConstants.emitterEventCreate, action: '', data: null
    };
    const createData = new CreateObjectModel(this.storeName, this.installerWorkCrewTechnicianId,
      'UserRoleViewModel', 'Create', model, null, emitter);
    this.store.dispatch(new CreateObjectList({ createData }));
  }

  setSubscribes(): void {
    this.subscription.add(this.selStore.pipe(
      select(getSelectionListByType(InstallerSelectList.installerRole)),
      take(1))
      .subscribe((data: ISelectionList) => {
        this.allRoles = data.objData[0].data;
      }));

    //get the technician - need his providerUserId
    this.subscription.add(this.store.pipe(select(getObjectDataById(InstallerStore.technicianInformation, this.installerWorkCrewTechnicianId)), take(1))
      .subscribe((data: IInstallerWorkCrewTechnician) => {
        this.providerUserId = data.providerUserId;
      }));

    //subscribe to watch for errors and events
    this.subscription.add(this.store.pipe(select(fromStore.getListByType(this.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId === this.installerWorkCrewTechnicianId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          if (state.data && !state.event) {
            const currentRoles: IUserRoleViewModel[] = state.data ? cloneDeep(state.data) : [];
            const currentRoleIds: number[] = currentRoles.map(x => x.providerRoleId);
            this.setAvailableRoles(currentRoleIds);
          }
          if (state.event && (!state.errorData || !state.errorData.length)) {
            const event: IHomEventEmitter = cloneDeep(state.event);
            this.emitterService.emitListEvent(event);
            this.store.dispatch(new fromStore.ClearEventList({ storeName: this.storeName, parentId: this.installerWorkCrewTechnicianId }));
          }
          if (state.errorData) {
            this.errorData$.next(state.errorData);
          }
        }
      }));
  }
  //Can not select installer role from user interface
  //Installer role must be assigned during technician enrollment in Installer Portal
  setAvailableRoles(currentRoleIds: number[]): void {
    this.availableRoles$.next(this.allRoles.filter(x => !currentRoleIds.includes(x.providerRoleId) && x.roleName !== InstallerRole.installer));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
