import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../../auth/services/auth-guard.service';
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';
import { PoImportContactIManagerComponent } from './containers';
import {
  ContactMetaDataResolver,
  ContactPhoneMetaDataResolver,
  ContactEmailMetaDataResolver,
  ContactAddressMetaDataResolver,
  ContactTypeSpecificationResolver
} from '../../contact/resolvers';
import { LoadingPageComponent } from '../../../shared/components';

const poImportRoutes: Routes = [
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'po-import-contact-info/:id/:errorsOnly',
    outlet: 'modal',
    component: PoImportContactIManagerComponent,
    canActivate: [AuthGuard],
    data: {
      contactTypeId: '1'
    },
    title: 'po-import-contact-info',
    resolve: {
      hasContactMetaData: ContactMetaDataResolver,
      hadPhoneMetaData: ContactPhoneMetaDataResolver,
      hasEmailMetaData: ContactEmailMetaDataResolver,
      hasAddrMetaData: ContactAddressMetaDataResolver,
      hasTypeData: ContactTypeSpecificationResolver
    }
  },
  {
    path: 'loading2/:container',
    outlet: 'modal2',
    component: LoadingPageComponent
  },
  {
    path: 'po-import-contact-info/:id/:errorsOnly',
    outlet: 'modal2',
    component: PoImportContactIManagerComponent,
    canActivate: [AuthGuard],
    data: {
      contactTypeId: '1'
    },
    title: 'po-import-contact-info2',
    resolve: {
      hasContactMetaData: ContactMetaDataResolver,
      hadPhoneMetaData: ContactPhoneMetaDataResolver,
      hasEmailMetaData: ContactEmailMetaDataResolver,
      hasAddrMetaData: ContactAddressMetaDataResolver,
      hasTypeData: ContactTypeSpecificationResolver
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(poImportRoutes)
  ],
  exports: [RouterModule]
})
export class PoImportRoutingModule { }
