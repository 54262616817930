<ng-container *ngIf="joinErrors">
  <div *ngIf="errorMessage" class="alert-box alert-box--danger " [ngClass]="{ 'form__alert-box': onForm }">
    {{ errorMessage }}
  </div>
</ng-container>
<ng-container *ngIf="!joinErrors && errorData && errorData.length">
  <div *ngFor="let error of errorData">
    <div *ngFor="let value of error.value" class="alert-box alert-box--danger" [ngClass]="{ 'form__alert-box': onForm }">
      {{ value }}
    </div>
  </div>
</ng-container>

