// Resolver for a specific installer
import { Injectable, OnDestroy} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, filter, map, first, mergeMap } from 'rxjs/operators';

//store actions and reducers
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../store/reducers/index';
import { metaDataExists } from '../../../../fw/dynamic-list/store/selectors/meta-data.selectors';
import * as DynamicObjectActions from '../../../../fw/dynamic-list/store/actions/dynamic-object.actions';
import { IFieldDefinition } from '../../../../fw/dynamic-forms/index';

import { SelectionListService } from '../../../../shared/services/selection-list.service';
import { ISelectResolverData } from '../../../../shared/interfaces/i-select-resolver-data';
import { InstallerStore } from '../enums/installer.enums';

@Injectable()
export class TechnicianInformationResolver  implements OnDestroy {
  operation: string;
  currentUserId: number;
  fieldDefinitions: IFieldDefinition[] = [];
  seedData: any = {};
  selectStoreNames: string[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public selectionListService: SelectionListService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const technicianId = +route.paramMap.get('id');
    const objectRequest = {
      controllerName: 'InstallerWorkCrewTechnician',
      storeName: InstallerStore.technicianInformation,
      rowKeyId: 'installerWorkCrewTechnicianId',
      objectId: technicianId,
      displayFields: [],
      metaUrl: '',
      controllerMethod: 'DetailsWithMetaData', //defaults to Index if not specified
      methodParameters: technicianId.toString()
    };


    //get object data or check for stale data
    this.store.dispatch(new DynamicObjectActions.GetObject({ detailRequest: objectRequest }));

    //wait for meta data for this object to load, then request selection list data and wait for it to load.
    return this.metaLoaded().pipe(
      mergeMap((loaded: boolean) => {
        let resolverData: ISelectResolverData[] = this.selectionListService.getListNames(InstallerStore.technicianInformation);
        this.selectionListService.requestListData(InstallerStore.technicianInformation, technicianId);
        resolverData.forEach(x => x.parentId = x.parentDependent ? technicianId : -1);

        return this.selectionListService.waitForDataToLoad(resolverData)
          .pipe(
            map((complete: boolean) => {  return complete }),
            filter((complete: boolean) => complete === true),
            take(1),
            map(() => {
              return true;
            }),
            first()
          )
      })
    );
  }


  metaLoaded(): Observable < boolean > {
    return this.store.select(metaDataExists(InstallerStore.technicianInformation))
    .pipe(
      map((exists: boolean) => {
        return exists;
      }),
      filter((exists: boolean) => exists === true),
      take(1),
      map(() => { return true; }),
      first()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
