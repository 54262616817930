export interface IInstallerCompatibilityPreference {
  installerCompatibilityPreferenceId: number;
  provider_providerId: number;
  workCategoryCompatibilityWeight: number;
  locationServicePreferenceCompatibilityWeight: number;
  locationServiceFlexibilityWeight: number;
  zonePreferenceCompatibilityWeight: number;
  warrantyCallbackRateWeight: number;
  averageCustomerSurveyResponseWeight: number;
  jobHistoryConsiderationAssignmentThreshold: number;
  maximumJobHistoryConsiderationDays: number;
}

export class InstallerCompatibilityPreference implements IInstallerCompatibilityPreference {
  installerCompatibilityPreferenceId: number;
  provider_providerId: number;
  workCategoryCompatibilityWeight: number;
  locationServicePreferenceCompatibilityWeight: number;
  locationServiceFlexibilityWeight: number;
  zonePreferenceCompatibilityWeight: number;
  warrantyCallbackRateWeight: number;
  averageCustomerSurveyResponseWeight: number;
  jobHistoryConsiderationAssignmentThreshold: number;
  maximumJobHistoryConsiderationDays: number;


  constructor() {
    this.installerCompatibilityPreferenceId = 0;
    this.provider_providerId = null;
    this.workCategoryCompatibilityWeight = 0;
    this.locationServicePreferenceCompatibilityWeight = 0;
    this.locationServiceFlexibilityWeight = 0;
    this.zonePreferenceCompatibilityWeight = 0;
    this.warrantyCallbackRateWeight = 0;
    this.averageCustomerSurveyResponseWeight = 0;
    this.jobHistoryConsiderationAssignmentThreshold = 0;
    this.maximumJobHistoryConsiderationDays = 0;
  }

}


export interface IInstallerScore {
  fieldKey: string;
  type: string;
  weight: number;
  descriptor: string;
}
