import { Component, OnInit,  ChangeDetectionStrategy } from '@angular/core';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
  selector: 'email-imports',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './email-imports.component.html'
})
export class EmailImportsComponent implements OnInit {
  public listDefinition: IListDefinition;

  constructor(public utilitiesService: UtilitiesService) { }

  ngOnInit() {
    this.listDefinition = this.utilitiesService.loadEmailImportsListDefinition();
  }
}
