<div class="single-file-img" [ngClass]="{'single-file-img--disabled': disabled}">
  <label class="single-file-img__btn {{customCss}}">
    <i class="fas {{ icon }} single-file-img__btn__icon"></i>
    <input #file type="file"
           [disabled]="disabled"
           class="single-file-img__input"
           (change)="uploadFile(file.files[0])">
  </label>
</div>



