import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../shared/constants';
import { ITabStyleRequest, IAppTabStyle } from '../../../fw/fw-shared/components/fw-app-tabs/interfaces/index';
import { AppTabAction, AppTabIcon } from '../../../fw/fw-shared/components/fw-app-tabs/enums/fw-app-tabs.enums';
import * as fromRoot from '../../store/reducers/index';
import { getUnrespondedToInstallers, getUnrespondedToProjects } from '../../../auth/store/auth.selectors';
import { UserPriviledgesService } from '../../../auth/services';

@Injectable({
  providedIn: 'root'
})
export class SmsService implements OnDestroy   {
  subscription: Subscription = new Subscription();
  unrespondedToProjectIds: number[] = [];
  unrespondedToInstallerIds: number[] = [];

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public emitterService: HomEventEmitterService,
    public userPriviledgesService: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  init(): void {
    this.subscription.add(this.rootStore.pipe(select(getUnrespondedToProjects), distinctUntilChanged())
      .subscribe((projectIds: number[]) => {
        //need to clear out all existing, then set new
        this.emitTabStyleClearRequest(1);
        this.unrespondedToProjectIds = projectIds;
        projectIds.forEach((id: number) => {
          this.emitTabStyleRequest(1, id);
        });
    }));

    this.subscription.add(this.rootStore.pipe(select(getUnrespondedToInstallers), distinctUntilChanged())
      .subscribe((installerIds: number[]) => {
        //need to clear out all existing, then set new
        this.emitTabStyleClearRequest(7);
        this.unrespondedToInstallerIds = installerIds;
        installerIds.forEach((id: number) => {
          this.emitTabStyleRequest(7, id);
        });
   }));
  }

  emitTabStyleClearRequest(portalId: number) {
    const data: ITabStyleRequest = {
      portalId: portalId,
      portalEntityId: 0,
      tabStyle: null
    };
    const request: IHomEventEmitter = {
      requestor: 'sms',
      event: this.myConstants.emitterEventTabLevel1Change,
      action: AppTabAction.ClearSmsNotice,
      data: data
    };
    this.emitterService.emitTabEvent(request);
  }

  emitTabStyleRequest(portalId: number, entityId: number) {
   const data: ITabStyleRequest = {
      portalId: portalId,
      portalEntityId: entityId,
      tabStyle: this.getUnrespondedToSmsStyle()
    };
    const request: IHomEventEmitter = {
      requestor: 'sms',
      event: this.myConstants.emitterEventTabLevel1Change,
      action: AppTabAction.SetSmsNotice,
      data: data
    };
    this.emitterService.emitTabEvent(request);
  }

  getUnrespondedToSmsStyle(): IAppTabStyle {
    return {
      icon: AppTabIcon.Sms,
      iconCss: 'app-icon--warning',
      cssName: '',
      label: '',
      iconTitle: 'Has Unresponded To Text(s)'
    };
  }

  isInUnrespondedList(portalId: number, entityId: number): boolean {
    let isInList: boolean = false

    switch (portalId) {
      case 1:
        isInList = this.unrespondedToProjectIds.findIndex(x => x === entityId) > -1;
        break;
      case 7:
        isInList = this.unrespondedToInstallerIds.findIndex(x => x === entityId) > -1;
        break;
      default:
        break;
    }

    return isInList;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
