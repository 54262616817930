import { Injectable } from '@angular/core';

import {  IWorkOrderViewModel } from '../../../app/portals/view-models/index';
import { UserPriviledgesService } from '../../../auth/services';

@Injectable()
export class UtilitiesDynamicListService {

  constructor(public userPriviledgesService: UserPriviledgesService) { }

  handleCustomMethod(methodName: string, row: any, portalEntityId: number): any {
    switch (methodName) {
      case 'getImmediateMoveUpCss':
        return this.getImmediateMoveUpCss(row);
      case 'getDispatchServiceCss':
        return this.getDispatchServiceCss(row);
      case 'getWoPacketCss':
        return this.getWoPacketCss(row);
      case 'canViewWoPacket':
        return this.canViewWoPacket(row);
      case 'canDispatch':
        return this.canDispatch(row);
      case 'canDeleteSchedule':
        return this.canDeleteSchedule(row);
      default:
        return false;
    }
  }

  

/*CUSTOM WORK ORDER METHODS TO SET CSS */
  getImmediateMoveUpCss(row: any): string {
    return row.immediateMoveUp ? 'dynamic-list__item--danger' : '';
  }

  getDispatchServiceCss(row: IWorkOrderViewModel): string {
    var serviceName = row && row.hasOwnProperty('serviceName') ? row.serviceName.split(" ").join("-").replace("/", "-").trim() : "";
    return serviceName ? 'dynamic-list__item--'.concat(serviceName.toLowerCase()) : '';
  }


  getWoPacketCss(row: IWorkOrderViewModel): string {
    return row && row.hasOwnProperty('emailWorkPacketSent') && row.emailWorkPacketSent ? 'app-btn-icon--success' : '';
  }

/*CUSTOM WORK ORDER METHODS TO SET ENABLED */
  canViewWoPacket(row: IWorkOrderViewModel): boolean {
    return row && row.hasOwnProperty('metaData') && row['metaData'].hasOwnProperty('workOrderWorkPacket') && row['metaData']['workOrderWorkPacket'];
  }

  canDispatch(row: IWorkOrderViewModel): boolean {
    if (!row) return false;
    return row.canEdit && this.userPriviledgesService.canEditWorkOrderViaView(row);
  }

  canDeleteSchedule(row: IWorkOrderViewModel): boolean {
    if (!row) return false;
    return row.canEdit && this.userPriviledgesService.canIDelete(row);
  }
}
