import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { UtilitiesStore } from '../../enums/utilities.enums';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { getListByType } from '../../../../../fw/dynamic-list/store/index';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
  selector: 'installer-vacations',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './installer-vacations.component.html'
})
export class InstallerVacationsComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public utilitiesService: UtilitiesService) { }

  subscription: Subscription = new Subscription();

  ngOnInit() {
    this.subscription.add(this.store.pipe(select(getListByType(UtilitiesStore.dashInstallerVacations))).pipe(take(1)).subscribe((data) => {
      if (!data.objData || !data.objData.length) {
        this.listDefinition$.next(this.utilitiesService.loadInstallerVacationsListDefinition());
      } else {
        this.listDefinition$.next(data.objData[0].listDefinition);
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
