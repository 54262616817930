import { Component, OnInit, OnDestroy, Inject, Input} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import {  IErrorData } from '../../../../shared/interfaces/index';

import { MetaDataService } from '../../../../fw/dynamic-list/services/index'
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';

//store actions, reducers, interfaces
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../../app/store/reducers/index';
import * as fromDynamicList from '../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IListObjectData, IDynamicListState } from '../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';

//use when do not wrap in dynamic-detail
@Component({
  selector: 'fw-detail-container',
 templateUrl: './detail-container.component.html',
  providers: [MetaDataService]  //unique instance for whatever it is wrapping
})
export class DetailContainerComponent implements OnInit, OnDestroy  {
  @Input() displayFields: string[];
  @Input() parentId: number;
  @Input() storeName: string;
  @Input() ignoreKeyOnError: boolean = false;

  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  public loading$: Observable<boolean>;
  subscription: Subscription = new Subscription();

  constructor(public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public modalService: ModalService,
    public mds: MetaDataService,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(filter(x => x.requestor === this.storeName), map(x => x.show));

    this.mds.mdsReady$.next(false);
    this.newRequest();

  }

  newRequest(): void {
    this.listenToStore();
    this.mds.setFieldDefinitions(this.storeName);
  }

  //listen to store for errors or events
  //event existence without error signifies close
  listenToStore() {
    this.subscription.add(this.store.pipe(select(fromDynamicList.getListByType(this.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.parentId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          if (state.event && (!state.errorData || !state.errorData.length)) {
            if (this.modalService.opened) {
              this.modalService.close();
            } else {
              this.emitterService.emitListEvent(state.event);
            }
            this.store.dispatch(new fromStore.ClearEventList({ storeName: this.storeName, parentId: this.parentId }));
          }
          if (state.errorData) {
            this.mds.setErrorMessages(state.errorData);
            this.errorData$.next(state.errorData);
          }
        }
      }));

  }

  public ngOnDestroy(): void {
    if (this.displayFields && this.displayFields.length) {
      this.mds.clearValidationMessages(this.displayFields);
    }
    this.store.dispatch(new fromStore.ClearErrorsList({ storeName: this.storeName, parentId: this.parentId }));

    this.subscription.unsubscribe();
  }
}
