<ng-container [formGroup]="form" *ngIf="eligibleField()">

  <!--Using ngSwitch to handle each field type, add new field types here
  Update DynamicFieldService to support more types
  -->
  <ng-container [ngSwitch]="displayType">
    <!--STRING-->
    <ng-container *ngSwitchCase="myConstants.fieldTypeString">
      <div class="float-label">
        <input type="text" placeholder="{{ field.label }}"
               [formControlName]="field.key" [id]="field.key" maxlength="{{field.maxStringLength || 50}}"
               [ngClass]="{'has-error': field.validationMessage || ( !isReadOnly() && !form.controls[field.key].valid) }"
               [readonly]="isReadOnly()"
               [pattern]="field.pattern !== '' ? field.pattern : ''"
               appFocus [autoFocus]="autoFocus" />
        <label [htmlFor]="field.key" [title]="field.placeholder">{{ field.label }}</label>
      </div>
    </ng-container>

    <!--COLOR-->
    <ng-container *ngSwitchCase="myConstants.fieldTypeColor">
      <div class="float-label">
        <input type="color"
               [formControlName]="field.key" [id]="field.key"
               [ngClass]="{'has-error': field.validationMessage || ( !isReadOnly() && !form.controls[field.key].valid) }"
               [readonly]="isReadOnly()"
               appFocus [autoFocus]="autoFocus" />
        <label [htmlFor]="field.key" [title]="field.placeholder">{{ field.label }}</label>
      </div>
    </ng-container>

    <!--NUMBER-->
    <ng-container *ngSwitchCase="myConstants.fieldTypeNumber">
      <div class="float-label">
        <input type="text" digitOnlyMask
               class="number-width-small"
               [placeholder]="field.required ? '0' : ''"
               [formControlName]="field.key" [id]="field.key"
               [ngClass]="{'has-error': field.validationMessage || (!isReadOnly() && !form.controls[field.key].valid && !form.controls[field.key].disabled) }"
               [readonly]="isReadOnly()"
               [pattern]="field.pattern !== '' ? field.pattern : null"
               appFocus [autoFocus]="autoFocus" />
        <label [htmlFor]="field.key" [title]="field.placeholder">{{ field.label }}</label>
      </div>
    </ng-container>

    <!--SINGLE CHECKBOX for BOOLEAN TODO - make this checkbox (sans label) into a component-->
    <ng-container *ngSwitchCase="myConstants.fieldTypeBoolean">
      <!--if want to display an image button - add if deviation here-->
      <fw-check-box [config]="btnConfig"
                    [isDisabled]="form.controls[field.key].disabled"
                    [formControlName]="field.key"
                    [autoFocus]="autoFocus"></fw-check-box>
    </ng-container>

    <!--SELECT - DROP DOWN -->
    <ng-container *ngSwitchCase="myConstants.fieldTypeSelect">
      <div class="float-label">
        <select [id]="field.key" [formControlName]="field.key"
                [ngClass]="{'has-error': field.validationMessage || ( !isReadOnly()  && !form.controls[field.key].valid) }"
                appFocus [autoFocus]="autoFocus">
          <option [ngValue]="null">{{myConstants.choose}}</option>
          <option *ngFor="let item of selectItems" [ngValue]="item">{{item[field.selectListDefinition.labelProperty]}}</option>
        </select>
        <label [htmlFor]="field.key">{{ field.label }}</label>
      </div>
    </ng-container>

    <!--EDITABLE SELECT - DROP DOWN -->
    <ng-container *ngSwitchCase="myConstants.fieldTypeEditableSelect">
      <fw-editable-select [form]="form" [field]="field"
                          [list]="selectItems"
                          [readOnly]="isReadOnly()"
                          [disabled]="form.controls[field.key].disabled"
                          [valid]="(field.validationMessage || ( !isReadOnly()  && !form.controls[field.key].valid))"
                          [useContains]="useContains"
                          *ngIf="form && field && selectItems"
                          appFocus [autoFocus]="autoFocus">
      </fw-editable-select>
    </ng-container>

    <!--FILTERABLE SELECT - DROP DOWN -->
    <ng-container *ngSwitchCase="myConstants.fieldTypeFilterableSelect">
      <fw-editable-select *ngIf="form && field && selectItems"
                          appFocus
                          [autoFocus]="autoFocus"
                          [form]="form"
                          [field]="field"
                          [list]="selectItems"
                          [readOnly]="isReadOnly() || form.controls[field.key].disabled"
                          [disabled]="form.controls[field.key].disabled"
                          [valid]="(field.validationMessage || ( !isReadOnly() && !form.controls[field.key].valid))"
                          [useContains]="useContains">
      </fw-editable-select>
    </ng-container>

    <!--DATE or DATETIME-->
    <!--REPLACE WITH INTERNALLY CREATED DATE PICKER-->
    <ng-container *ngSwitchCase=myConstants.fieldTypeSingleDatePicker>
      <fw-calendar [formControlName]="field.key"
                   [placeHolder]="field.label"
                   [isDisabled]="disabled$ | async"
                   [isReadOnly]="isReadOnly()"
                   [isRequired]="field.required"
                   [error]="error || (field.validationMessage || ( !isReadOnly() && !form.controls[field.key].valid))"
                   [singlePicker]="true"
                   [name]="field.key"
                   [label]="field.label"
                   [isDateTime]="field.fieldType === myConstants.fieldTypeDateTime"
                   [customCss]="{'has-error': field.validationMessage || ( !isReadOnly() && !form.controls[field.key].valid)}"></fw-calendar>
    </ng-container>

    <!--TIME-->
    <ng-container *ngSwitchCase=myConstants.fieldTypeTimePicker>
      <div class="float-label float-label--time">
        <input type="time" placeholder="{{ field.label }}"
               [formControlName]="field.key" [id]="field.key"
               [ngClass]="{'has-error': field.validationMessage || ( !isReadOnly()  && !form.controls[field.key].valid) }"
               [readonly]="isReadOnly()"
               pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
               appFocus [autoFocus]="autoFocus" />
        <label [htmlFor]="field.key" [title]="field.placeholder">{{ field.label }}</label>
      </div>
    </ng-container>

    <!--PHONE-->
    <ng-container *ngSwitchCase="myConstants.fieldTypePhone">
      <div class="float-label">
        <input *ngIf="operation !== myConstants.operationTypeDetails"
               type="tel" placeholder="{{ field.label }}"
               phoneMask [formControlName]="field.key" [id]="field.key" maxlength="{{field.maxStringLength}}"
               [ngClass]="{'has-error': field.validationMessage || ( !isReadOnly()  && !form.controls[field.key].valid) }"
               [readonly]="isReadOnly()"
               pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
               appFocus [autoFocus]="autoFocus" />
        <fw-phone *ngIf="operation === myConstants.operationTypeDetails" [phoneNumber]="form.controls[this.field.key].value"></fw-phone>
        <label [htmlFor]="field.key">{{ field.label }}</label>
      </div>
    </ng-container>

    <!--EMAIL-->
    <!--Example of pattern to use for email:  pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"-->
    <ng-container *ngSwitchCase="myConstants.fieldTypeEmail">
      <div class="float-label">
        <input *ngIf="operation !== myConstants.operationTypeDetails"
               type="email" placeholder="{{ field.label }}"
               [formControlName]="field.key" [id]="field.key" maxlength="{{field.maxStringLength}}"
               [ngClass]="{'has-error': field.validationMessage || ( !isReadOnly()  && !form.controls[field.key].valid) }"
               pattern="[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9.-]+(\.[a-zA-Z0-9-]+)"
               [readonly]="isReadOnly()"
               appFocus [autoFocus]="autoFocus" />
        <fw-email *ngIf="operation === myConstants.operationTypeDetails" [emailAddress]="form.controls[this.field.key].value"></fw-email>
        <label [htmlFor]="field.key">{{ field.label }}</label>
      </div>
    </ng-container>

    <!--ZIPCODE-->
    <ng-container *ngSwitchCase="myConstants.fieldTypeZipCode">
      <div class="float-label">
        <input type="text" placeholder="{{ field.label }}"
               zipCodeMask [formControlName]="field.key" [id]="field.key" maxlength="{{field.maxStringLength}}"
               [ngClass]="{'has-error': field.validationMessage || ( !isReadOnly()  && !form.controls[field.key].valid) }"
               pattern="^\d{5}(-\d{4})?$"
               [readonly]="isReadOnly()"
               appFocus [autoFocus]="autoFocus" />
        <label [htmlFor]="field.key">{{ field.label }}</label>
      </div>
    </ng-container>

    <!--TEXTAREA-->
    <ng-container *ngSwitchCase="myConstants.fieldTypeTextArea">
      <div class="float-label">
        <textarea asciiOnlyMask [formControlName]="field.key" [id]="field.key"
                  [disableAsciiOnlyMask]="!asciiOnly"
                  [ngClass]="{'has-error': field.validationMessage || ( !isReadOnly()  && !form.controls[field.key].valid) }"
                  maxlength="{{field.maxStringLength}}" rows="{{field.textRows}}" columns="40"
                  [readonly]="isReadOnly()"
                  placeholder="{{ field.label }}"
                  appFocus [autoFocus]="autoFocus"></textarea>
        <label>{{ field.label }}</label>
      </div>
    </ng-container>

    <!--DOUBLEDECIMAL  -->
    <ng-container *ngSwitchCase="myConstants.fieldTypeDoubleDecimal">
      <ng-container *ngTemplateOutlet="doubleTemplate"></ng-container>
    </ng-container>

    <!--CURRENCY  -->
    <ng-container *ngSwitchCase="myConstants.fieldTypeCurrency">
      <ng-container *ngTemplateOutlet="doubleTemplate"></ng-container>
    </ng-container>

    <!--DOUBLE example longitude-->
    <ng-container *ngSwitchCase="myConstants.fieldTypeDouble">
      <ng-container *ngTemplateOutlet="doubleTemplate"></ng-container>
    </ng-container>

    <!--MUTUALLY EXCLUSIVE OPTIONS NOT USED ANYWHERE YET
       Value changes should be listening
  -->
    <ng-container *ngSwitchCase="myConstants.fieldTypeRadioGroup">
      <fieldset [ngClass]="{'has-error': operation != 'details' && !form.controls[field.key].valid}">
        <legend>Select a {{ field.label }}</legend>
        <fw-radio-button [groupName]="'dfGroup'"
                         [buttons]="dfOptions"
                         [isDisabled]="(disabled$ | async)">
        </fw-radio-button>
      </fieldset>
    </ng-container>

    <!--MULTIPLE CHECKBOXES TODO-->
    <ng-container *ngSwitchCase="myConstants.fieldTypeMultiCheckbox">
      <fieldset [ngClass]="{'has-error': operation != 'details' && !form.controls[field.key].valid}">
        <legend>Choose the {{ field.label }}s you prefer</legend>
        <label for="{{ field.key }}" class="check-box">
          {{ field.label }}
          <input type="checkbox"
                 id="{{ field.key }}"
                 [formControlName]="field.key">
          <span class="check-box--checkmark"></span>
        </label>
      </fieldset>
    </ng-container>

    <!--IMAGE -->
    <ng-container *ngSwitchCase="myConstants.fieldTypeImage">
      <div class="float-label">
        <div class="with-float-label"></div>
        <label [title]="field.placeholder">{{ field.label }}</label>
        <div class="field-file-upload">
          <span class="field-file-upload__name">{{ form.controls[field.key].value || 'Not Selected Yet' }}</span>
          <button type="button"
                  class="app-btn-icon app-btn-icon--primary field-file-upload__add"
                  [ngClass]="{'has-error': field.validationMessage || ( !isReadOnly() && !form.controls[field.key].valid) }"
                  [title]="'Select ' + field.label"
                  (click)="fileUpload.click()">
            <i class="fas fa-paperclip"></i>
          </button>
          <button type="button"
                  class="app-btn-icon app-btn-icon--danger field-file-upload__del"
                  [title]="'Remove Selected ' + field.label"
                  (click)="onRemoveFile()">
            <i class="fas fa-trash"></i>
          </button>
          <input #fileUpload type="file" class="field-hidden" [id]="'file_DummyInput'" (change)="onFileChange($event)" />
        </div>
      </div>
    </ng-container>


    <div *ngIf="field.validationMessage" class="alert-box alert-box--danger">
      {{field.validationMessage}}
    </div>
  </ng-container>

   
  <ng-template #doubleTemplate>
    <!--DOUBLE DECIMAL / CURRENCY / DOUBLE  -->
    <div class="float-label">
      <input type="text" digitOnlyMask [decimal]="true"
             class="number-width"
             [formControlName]="field.key"
             [id]="field.key"
             [ngClass]="{'has-error': field.validationMessage || (!isReadOnly() && !form.controls[field.key].valid && !form.controls[field.key].disabled) }"
             [readonly]="isReadOnly()"
             [placeholder]="field.required ? '0.00' : ''"
             (change)="onDecimalChange()"
             appFocus [autoFocus]="autoFocus" />
      <label [htmlFor]="field.key" [title]="field.placeholder">{{ field.label }}</label>
    </div>
  </ng-template>

</ng-container>
