import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../../shared/constants/index';
import { IErrorData } from '../../../../../../shared/interfaces/index';
import { IDetailContainerConfig } from '../../../../../../fw/dynamic-detail/interfaces/index';

import { JobScheduleJobParameter, IJobScheduleJobParameter } from  '../../../../view-models/index';
import { CreateObjectModel, UpdateObjectByIdModel, IKey } from '../../../../../../fw/dynamic-list/store/interfaces/index';
import { DynamicDetailService } from '../../../../../../fw/dynamic-detail/services/dynamic-detail.service';

//store actions, reducers, interfaces
import * as fromStore from '../../../../../../fw/dynamic-list/store/index';
import * as fromDynamicList from '../../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';

@Component({
  selector: 'job-schedule-job-parameter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './job-schedule-job-parameter.component.html'
})
export class JobScheduleJobParameterComponent implements OnInit, OnDestroy  {
  public objectData$: BehaviorSubject<JobScheduleJobParameter> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public displayFields = ['jobParameter', 'parameterValue'];

  listStore: fromDynamicList.IListObjectData;
  myPathName: string = '';
  myStoreName: string = '';
  key: string = '';
  activeIndex:number = -1;
  operation: string;
  jobScheduleJobParameterId: number = 0;
  parentId: number;
  requestTime: string = '';
  errorMessage: string = '';
  errorData: IErrorData[] = null;
  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {

      this.subscription.add(emitterService.detailEventEmitted$
        .subscribe((e: IHomEventEmitter) => {
                if (e.requestor !== this.myPathName) {
                  return;
                } //can change this to create event listeners for each object controller in stead
                  switch (e.event) {
                      case this.myConstants.emitterEventUpdate:
                              this.updateRecord(e);
                          break;
                      case this.myConstants.emitterEventCreate:
                          this.createRecord(e);
                          break;

                      default:
                          break;
                  }
              }));
      }

  ngOnInit() {
    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      //jobScheduleJobId
      this.parentId = +paramMap.get('id');
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.activeIndex = +paramMap.get('rowIndex');
      this.key = paramMap.get('key');
      this.jobScheduleJobParameterId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.setDetailConfig(paramMap);
      this.getDetail();
    });
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig =  this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.parentId;
    params.useRouterOutlet = false;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(select(fromStore.getEntityListByParentId(this.myStoreName, this.parentId)))
      .subscribe((state: fromDynamicList.IListObjectData) => {
        this.listStore = state;

        this.myPathName = this.listStore.listDefinition.detailRoutePath;
        //handle updated data and initial data
        const data: IJobScheduleJobParameter = this.objectData$.getValue();
        this.objectData$.next(this.operation === this.myConstants.operationTypeCreate
          ? data ? data : new JobScheduleJobParameter()
          : this.listStore.data.find(data => data[this.key] == this.jobScheduleJobParameterId)); //keep this as == for it to match
      }));
  }

    /*
        Insert the new record
    */
  createRecord(e: IHomEventEmitter) {
      let obj: IJobScheduleJobParameter = this.setCommonProperties(e.data);

      obj.jobScheduleJob_jobScheduleJobId = this.parentId;
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
      const createData = new CreateObjectModel(this.myStoreName, this.parentId, this.listStore.listDefinition.controllerName, 'Create', obj, null, emitter);
      this.store.dispatch(new fromStore.CreateObjectList({ createData }));
    }

    /*
        Update the record
    */
  updateRecord(e: IHomEventEmitter) {
      let obj: IJobScheduleJobParameter =  this.setCommonProperties(e.data);
      const keyData: IKey = { storeName: this.myStoreName, parentId: this.parentId, key: this.key, id: this.jobScheduleJobParameterId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
      const updateData = new UpdateObjectByIdModel(keyData, this.listStore.listDefinition.controllerName, 'Update', this.key, this.jobScheduleJobParameterId, obj, null, emitter);
      this.store.dispatch(new fromStore.UpdateObjectByIdList({ updateData }));
    }

    /*
        Set common create/update properties
    */
  setCommonProperties(formData: JobScheduleJobParameter): IJobScheduleJobParameter {
    let obj: IJobScheduleJobParameter = { ...this.objectData$.getValue() };
    this.errorMessage = null;
    this.errorData = null;

    if (formData.hasOwnProperty('jobParameter')) {
      obj.jobParameter_jobParameterId = formData['jobParameter'].jobParameterId;
    }
    obj.parameterValue = formData.parameterValue;

    return obj;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
