import { cloneDeep } from 'lodash';
import { IErrorData } from '../../shared/interfaces/index';
import { IUserPreference, IProviderUser,  IPortalTabContainer, IUnrespondedToContactModel } from '../../app/portals/view-models/index';
import { IWidgetManager } from '../../app/portals/portal/interfaces/index';
import { AuthActions, AuthActionTypes } from './auth.actions';
import { IParameterSearchType, IListComponent } from '../../fw/dynamic-list/interfaces';
import { IUserRecent } from '../../fw/fw-shared/components/user-recents/interfaces/i-user-recent';


export interface IAuthError { key: string; value: string; }

export interface IState {
  token: string;
  returnUrl: string;
  showReset: boolean;
  authenticated: boolean;
  validUserSecurity: boolean;
  providerUser: IProviderUser;
  userPreferences: IUserPreference;
  openNotificationCount: any; //any so can default to null to know have not retrieved
  pendingChangesCount: any; //any so can default to null to know have not retrieved
  myWidgets: IWidgetManager[];
  portalTabContainers: IPortalTabContainer[];
  parameterSearchTypes: IParameterSearchType[];
  listComponents: IListComponent[];
  errors: { key: string, value: string; }[];  
  errorData: IErrorData[];
  crmVersion: string;
  initReload: boolean;
  initRedirect: boolean;
  refreshing: boolean;
  userRecents: IUserRecent[];
  unrespondedToContacts: IUnrespondedToContactModel[];
}

const initialAuthState: IState = {
  token: null,
  returnUrl: '',
  showReset: false,
  authenticated: false,
  validUserSecurity: false,
  providerUser: null,
  userPreferences: null,
  openNotificationCount: null,
  pendingChangesCount: null,
  myWidgets: [],
  portalTabContainers: [],
  parameterSearchTypes: [],
  listComponents: [],
  errors: [],
  errorData: [],
  crmVersion: '',
  initReload: false,
  initRedirect: false,
  refreshing: false,
  userRecents: [],
  unrespondedToContacts: []
};

export function authReducer(state = initialAuthState, action: AuthActions)  {
  switch (action.type) {


        case AuthActionTypes.START_SIGNIN_WORK_ACTION:
            {
                return {
                    ...state,
                    errors: [],
                    errorData:  [],
                    token: null,
                    authenticated: false,
                    validUserSecurity: false
                }
            }

        //case AuthActionTypes.SIGNUP:
        case AuthActionTypes.SIGNIN_SUCCESS_ACTION:
            {
        //console.log('In SIGNIN_SUCCESS signin: ', action);
                return {
                    ...state,
                    providerUser: action.payload.providerUser,
                    errors: [],
                    errorData:  [],
                    authenticated: true,
                    validUserSecurity: true,
                    returnUrl: action.payload.returnUrl,
                    token: action.payload.token,
                    crmVersion: action.payload.crmVersion,
                    refreshing: false
             }
            }

        case AuthActionTypes.SET_LOGOUT_ACTION:
            {
                return {
                    ...state,
                    errors:  [],
                    errorData:  [],
                    token: null,
                    userPreferences: null,
                    pendingChangesCount: null,
                    openNotificationCount: null,
                    returnUrl: '',
                    crmVersion: '',
                    authenticated: false,
                    providerUser: null,
                    myWidgets: [],
                    portalTabContainers: [],
                    parameterSearchTypes: [],
                    listComponents: [],
                    refreshing: false,
                    validUserSecurity: false,
                    userRecents: [],
                    unrespondedToContacts: []
                }
            }

        case AuthActionTypes.SET_SIGNIN_ERROR_ACTION:
            {
              //  console.log('In Set Logon Error with: ', action);
              let errors = [];
              if (action.payload && !action.payload.errors.length) {
                return state;
              }
                if (!action.payload) {
                  errors.push({ 'key': '__Model', 'value': 'Login error' });
                } else {
                  errors = action.payload.errors;
                }

              return {
                    ...state,
                    authenticated: false,
                    errors: errors,
                    errorData:  [],
                    token: null,
                    providerUser: null,
                }
        }

      case AuthActionTypes.SET_APPLICATION_VERSION_ACTION:
      {
          return {
            ...state,
            initReload: action.payload
          }
        }

      case AuthActionTypes.SET_PENDING_UPDATE_DATE_ACTION:
        {
          return {
            ...state,
            initRedirect: action.payload
          }
        }

        case AuthActionTypes.SET_USER_CONFIGURATIONS_ACTION:
        {
          //console.log('Provider user configurations are: ', state, action);
                return {
                  ...state,
                    providerUser: action.payload == null ? null : action.payload.providerUser,  //full providerUser object
                }
            }

        case AuthActionTypes.SET_USER_PREFERENCES_ACTION:
        {
          const responseBase = action.payload.responseBase;
              
          return {
              ...state,
            userPreferences: responseBase.success ? responseBase.data : state.userPreferences,
            errorData: action.payload.responseBase.success ?  [] : action.payload.responseBase.errorData
          }
        }

        case AuthActionTypes.UPDATE_USER_PREFERENCES_COMPLETE_ACTION:
        {
          const responseBase = action.payload.responseBase;

            return {
                ...state,
              userPreferences: responseBase.success ? responseBase.data : state.userPreferences,
              errorData: action.payload.responseBase.success ?  [] : action.payload.responseBase.errorData
            }
          }

        case AuthActionTypes.SET_OPEN_NOTIFICATION_COUNT_ACTION:
        {
            return {
                ...state,
              openNotificationCount: action.payload.responseBase.success ? action.payload.responseBase.data.notificationCount : null,
              errorData: action.payload.responseBase.success ?  [] : action.payload.responseBase.errorData
            }
        }

        case AuthActionTypes.SET_PENDING_CHANGES_COUNT_ACTION:
          {
            //console.log('SET_PENDING_CHANGES_COUNT', action);
            const response = action.payload.responseBase;

            return {
              ...state,
              pendingChangesCount: response.success && response.hasOwnProperty("metaData") && response.metaData.hasOwnProperty("totalItems")
                ? response.metaData.totalItems : null,
              errorData: response.success ? [] : response.errorData
            }
          }

        case AuthActionTypes.SET_HUB_PENDING_CHANGES_COUNT_ACTION:
          {
            return {
              ...state,
              pendingChangesCount: action.payload.count
            }
          }

        case AuthActionTypes.SET_HUB_OPEN_NOTIFICATION_COUNT_ACTION:
          {
            return {
              ...state,
              openNotificationCount: action.payload.count
            }
          }
        
        case AuthActionTypes.SET_ALL_MY_WIDGETS_ACTION:
          {
            const response = action.payload.responseBase;

            return {
              ...state,
              myWidgets: response.success ? response.data : [],
              validUserSecurity: response.success && response.data.length > 0 ? true : false,
              errorData: response.success ? [] : response.errorData
            }
          }

        case AuthActionTypes.SET_PORTAL_TABS:
          {
            //console.log('SET_PORTAL_TABS with: ',  action);
            const response = action.payload.responseBase;

            return {
              ...state,
              portalTabContainers: response.success ? response.data : state.portalTabContainers,
              errorData: response.success ? [] : response.errorData
            }
          }

      case AuthActionTypes.SET_PARAMETER_SEARCH_TYPES:
        {
          //console.log('SET_PARAMETER_SEARCH_TYPES with: ',  action);
          const response = action.payload.responseBase;

          return {
            ...state,
            parameterSearchTypes: response.success ? response.data : state.parameterSearchTypes,
            errorData: response.success ? [] : response.errorData
          }
        }

      case AuthActionTypes.SET_LIST_COMPONENTS:
        {
          const response = action.payload.responseBase;

          return {
            ...state,
            listComponents: response.success ? response.data : state.listComponents,
            errorData: response.success ? [] : response.errorData
          }
        }

       case AuthActionTypes.SET_ERROR_RETURNED_ACTION:
        {
            //console.log('auth.reducer SET_ERROR_RETURNED ', action.payload);
          let errors = [];
          if (!state.authenticated) {
            errors.push({ 'key': '__Model', 'value': 'User was not authenticated' });
          }
          if (!action.payload) {
            errors.push({ 'key': '__Model', 'value': 'Error retrieving user information' });
          } else {
            errors.push(action.payload.errors);
          }
          return {
              ...state,
              errors: errors
          }
        }

      case AuthActionTypes.SET_VALID_USER_ACCESS:
      {
          return {
            ...state,
            refreshing: false,
            validUserSecurity: action.payload
          }
      }

    case AuthActionTypes.SET_USER_RECENTS:
      {
        const responseBase = action.payload.responseBase,
              errorData = cloneDeep(state.errorData);
        if (!responseBase.success) errorData.push({ key: 'user-recent', value: ['Failed to retrieve bookmarks'] });
        return {
          ...state,
          userRecents: responseBase.success ? responseBase.data : state.userRecents,
          errorData: errorData
        }
      }

    case AuthActionTypes.CREATE_USER_RECENT_COMPLETE:
      {
        const responseBase = action.payload.responseBase,
          errorData = cloneDeep(state.errorData);
        if (!responseBase.success) errorData.push({ key: 'user-recent', value: ['Failed to create bookmark'] });
        return {
          ...state,
          userRecents: responseBase.success ? [ ...state.userRecents, responseBase.data ] : state.userRecents,
          errorData: errorData
        }
      }

    case AuthActionTypes.DELETE_USER_RECENT_COMPLETE:
      {
        const responseBase = action.payload.responseBase;
        if (responseBase.success) {
          const userRecents = cloneDeep(state.userRecents),
            idx = userRecents.findIndex(x => x.userRecentId === action.payload.id);
          if (idx === -1) {
            return state;
          } else {
            userRecents.splice(idx, 1);
            return {
              ...state,
              userRecents: userRecents,
              errorData: []
            }
          }
        } else {
          const errorData = cloneDeep(state.errorData);
          errorData.push({ key: 'user-recent', value: ['Failed to remove bookmark'] });
          return {
            ...state,
            errorData: errorData
          }
        }
      }

    case AuthActionTypes.CLEAR_USER_RECENT_ERROR:
      {
        const errorData = cloneDeep(state.errorData),
          idx = errorData.findIndex(x => x.key === 'user-recent');
        if (idx === -1) {
          return state;
        } else {
          errorData.splice(idx, 1);
          return {
            ...state,
            errorData: errorData
          }
        }
      }

    case AuthActionTypes.SET_REFRESHING:
        {
          return {
            ...state,
            refreshing: action.payload
          }
        }

    case AuthActionTypes.SET_HUB_UNRESPONDED_TO_CONTACTS_ACTION:
      {
        return {
          ...state,
          unrespondedToContacts: action.payload.model
        }
      }

    case AuthActionTypes.REMOVE_UNRESPONDED_TO_CONTACT_ACTION:
      {

        const unrespondedToContacts = cloneDeep(state.unrespondedToContacts.filter(x => x.ContactId !== action.payload.contactId));

        return {
          ...state,
          unrespondedToContacts: unrespondedToContacts
        }
      }

    case AuthActionTypes.ADD_UNRESPONDED_TO_CONTACT_ACTION:
      {
        return {
          ...state,
          unrespondedToContacts: [...state.unrespondedToContacts, ...action.payload.model]
        }
      }

    default:
        return state;

 }


}

