/** Common services for comopnets that use the job schedule */
import { Injectable, Inject, OnInit } from '@angular/core';

//interfaces
import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { JobSchedule } from '../../view-models/index';
import { IJobScheduleViewModel } from '../../view-models/i-job-schedule-view-model';
import { HomCommonUtility } from '../../../../shared/services';

//interface to allow access to the applicable selection list based on the field key
export  interface IDaysOfWeek {
  shortName: string,
  longName: string;
  dayNbr: number;
}

@Injectable()
export class JobScheduleService  implements OnInit {

  constructor(
    public utils: HomCommonUtility,
   @Inject(appConstants) public myConstants: IAppConstants) {}

  _daysOfWeek: IDaysOfWeek[] = [
    { shortName: 'Sun', longName: 'Sunday', dayNbr: 0 },
    { shortName: 'Mon', longName: 'Monday', dayNbr: 1 },
    { shortName: 'Tue', longName: 'Tuesday', dayNbr: 2 },
    { shortName: 'Wed', longName: 'Wednesday', dayNbr: 3 },
    { shortName: 'Thu', longName: 'Thursday', dayNbr: 4 },
    { shortName: 'Fri', longName: 'Friday', dayNbr: 5 },
    { shortName: 'Sat', longName: 'Saturday', dayNbr: 6 }
  ]

  ngOnInit(): void {  }

  public get daysOfWeek(): IDaysOfWeek[] {
    return this._daysOfWeek;
  }

  jobFrequencyTypeLabel(type: string, unit1: number): string {
    var returnText = '';
    switch (type) {
      case 'Daily':
        returnText = unit1 > 1 ? 'days' : 'day';
        break;
      case 'Weekly':
        returnText = unit1 > 1 ? 'weeks' : 'week';
        break;
      case 'Monthly':
        returnText = unit1 > 1 ? 'months' : 'month';
        break;
      default: break;
    }
    return returnText;
  }

  jobScheduleSummary(jobSchedule: JobSchedule | IJobScheduleViewModel): string {
    var returnText = 'Occurs ';
    // Deal with one-time or recurring
    switch (jobSchedule.jobScheduleRecurrenceType) {
        case 'OneTime':
          returnText += 'on ';
          returnText += jobSchedule.jobStartDate;
          return returnText;
        case 'Recurring':
          returnText += 'every ';
          break;
        default: break;
      }
      // Deal with frequency type
    if (jobSchedule.jobFrequencyRecurUnit1 > 0) {
      returnText += jobSchedule.jobFrequencyRecurUnit1 + ' ';
    }
    const frequencyType = this.utils.isObject(jobSchedule.jobFrequencyType)
      ? jobSchedule.jobFrequencyType.hasOwnProperty('name') ? jobSchedule.jobFrequencyType['name'] : ''
      : jobSchedule.jobFrequencyType;
    switch (frequencyType) {
      case 'Daily':
        returnText += (jobSchedule.jobFrequencyRecurUnit1 > 1) ? 'days' : 'day';
        break;
      case 'Weekly':
        returnText += (jobSchedule.jobFrequencyRecurUnit1 > 1) ? 'weeks' : 'week';
        if (jobSchedule.jobScheduleWeekdays.length > 0) {
          returnText += ' on ';
          for (var i = 0; i < jobSchedule.jobScheduleWeekdays.length; i++) {
            returnText += jobSchedule.jobScheduleWeekdays[i] + ', ';
          }
          returnText = returnText.substring(0, returnText.length - 2);
        }
        break;
      case 'Monthly':
        returnText += jobSchedule.jobFrequencyRecurUnit1 > 1 ? 'months' : 'month';
        returnText += ' on day ' + jobSchedule.jobFrequencyRecurUnit3 + ' of that month';
        break;
      default: break;
    }
    // Deal with daily frequency
    if (jobSchedule.allDayEvent) {
      returnText += '';
    }
    else if (jobSchedule.dailyFrequency == 'once') { //not currently in an implementation - wait till this is needed to 
      returnText += ' at ' + jobSchedule.dailyFrequencyTime;
    }
    else {
      returnText += ' every ' + jobSchedule.jobFrequencyRecurUnit2 + ' ';
      returnText += (jobSchedule.jobFrequencyRecurUnit2 > 1) ? jobSchedule.dailyFrequencyUnit.toString() + 's' : jobSchedule.dailyFrequencyUnit.toString();
      returnText += ' between ';
      returnText += jobSchedule.dailyFrequencyStartTime;
      returnText += ' and ' + jobSchedule.dailyFrequencyEndTime;
    }
    returnText += '.';
    // Deal with duration
    if (jobSchedule.jobEndDate) {
      returnText += '  Schedule will be used between ' + jobSchedule.jobStartDate + ' and ' + jobSchedule.jobEndDate + '.';
      return returnText;
    }
    returnText += '  Schedule will be used starting ' + jobSchedule.jobStartDate + '.';
    return returnText;
  }



  }




