<div  class="sku-payouts">
  <fw-component-title title="Payout Details"></fw-component-title>

  <provider-payout></provider-payout>

  <div class="sku-payouts__list">
    <div class="list-wrapper">
      <fw-dynamic-list [vmListDefinition]="listDefinition"
                        [listTitle]="'Linked Skus'"
                        (customEvent)="onCustom($event)">
      </fw-dynamic-list>
    </div>
  </div>
  <fw-error-box [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>
