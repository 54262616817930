/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription,  BehaviorSubject } from 'rxjs';
import { filter, map,  take } from 'rxjs/operators';
import { orderBy, cloneDeep } from 'lodash';  

import { IContactCustomerFlag, ContactCustomerFlag } from '../../../view-models/index';
import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, IListButtonType } from '../../../../../fw/dynamic-list/interfaces/index';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { listDataExists } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IListObjectData, IDynamicListState } from '../../../../../fw/dynamic-list/store';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'contact-customer-flag-container',
  templateUrl: './contact-customer-flag-container.component.html',
})
export class ContactCustomerFlagContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() contactId: number;
  @Input() canFlagCustomer: boolean;
  @Input() hasStaleData: boolean;

  public objectData$: BehaviorSubject<ContactCustomerFlag> = new BehaviorSubject(null);
  controllerName: string = 'ContactCustomerFlag';
  loaded: boolean = false;
  myStoreName: string = 'contactCustomerFlags';
  detailVisible: boolean = false;
  operation: string = '';
  subscription: Subscription = new Subscription();

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromFeature.IAllDynamicData>,
    public modalService: ModalService,
    public dynamicListService: DynamicListService ) { }

  public onShowDetail() {
    this.detailVisible = true;
    this.modalService.open({ title: 'Customer Flags', path: 'customer-flags/' + this.contactId.toString(), sizeType: ModalSizeType.medium, onExit: null, castExit: false, hasTabs: false });
  }

  ngOnInit() {
    this.initiNewRequest();
  }

  //move this to container and push the data into here
  initiNewRequest() {
    this.objectData$.next(null);

    //dispatch get request if data not in store
    this.subscription.add(this.store.select(listDataExists(this.myStoreName, this.contactId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe((exists) => {
        let listDefinition = this.loadListDefinition();
        this.store.dispatch(new DynamicListActions.SetListDefinition({ storeName: this.myStoreName, parentId: listDefinition.parentId, listDefinition: listDefinition }));
        this.store.dispatch(new DynamicListActions.GetList({ listDefinition: listDefinition, listFilter: listDefinition.defaultListFilter, parentId: listDefinition.parentId }));
      }));

    this.subscription.add(this.store.pipe(select(fromDynamicList.getListByType(this.myStoreName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.contactId)))
      .subscribe((state: IListObjectData) => {
        const listData = cloneDeep(state);
        if (listData) {
          const recs: IContactCustomerFlag[] = listData.data;
          const recList = orderBy(recs, ['createDate'], ['desc']);
          this.objectData$.next( !recList.length ? new ContactCustomerFlag() : recList[0]);
        }
      }));
  }

  //retrieves existing and missing (missing will have a 0 in installerCertificationId) certifications
  loadListDefinition(): IListDefinition {

    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Customer Flags';
    const listObjectController = 'ContactCustomerFlag';
    const listStoreName = 'contactCustomerFlags';
    const detailRoutePath = 'contact-customer-flag-detail';
    const detailRouterOutlet = 'contactCustomerFlagOutlet';
    const listRowKeyId = 'contactCustomerFlagId';
    const defaultListFilter: IListFilter = new ListFilter();
    const parentId: number = this.contactId;

    let listDefinition = this.dynamicListService.createListDefinition(detailRouterOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByContact';
    listDefinition.methodParameters = parentId.toString();
    listDefinition.parentId = parentId;
    listDefinition.parentKey = 'contactId';

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    if (newTools.length) {
      //add create to start of array, reload is defaulted on create
      listDefinition.toolButtons.splice(0, 0, newTools[0]);
    }

    return listDefinition;
  }

  /*
      Define the columns in the list to be created.
  */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('isActive');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerFlagName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('flagReasonText');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }


  ngOnChanges(changes: SimpleChanges) {
    //when customer associated with project changes, handle
    if (changes['contactId'] && !changes['contactId'].isFirstChange()) {
      this.initiNewRequest();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
