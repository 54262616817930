import { Component, OnInit } from '@angular/core';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'zones',
  templateUrl: './zones.component.html'
})
export class ZonesComponent implements OnInit {

  listDefinition: IListDefinition;

  constructor(public adminProviderService: AdminProviderService) {
  }

  ngOnInit() {
    this.listDefinition = this.adminProviderService.loadZonesListDefinition();
  }

}
