import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter, Inject, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take} from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { ICustomButton } from '../../../fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../fw-shared/enums/button-type.enum';

//store actions, reducers, interfaces
import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { GenericObjectData, IErrorData } from '../../../../shared/interfaces/index';
import { IFormDefinition } from '../../../dynamic-forms/index';
import { IListDefinition } from '../../../dynamic-list/interfaces';

//store actions and reducers
import * as fromStore from '../../../dynamic-list/store/index';
import * as fromRoot from '../../../../app/store/reducers/index';
import { IDynamicListState, IListObjectData } from '../../../dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromDynamicList from '../../../dynamic-list/store/selectors/dynamic-list.selectors';
import * as DynamicListActions from '../../../dynamic-list/store/actions/dynamic-list.actions';

@Component({
  selector: 'fw-dynamic-detail-custom-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dynamic-detail-custom-container.component.html'
})

//Assumes list store data already exists
export class DynamicDetailCustomContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() storeName: string;
  @Input() title: string;
  @Input() entityLabel: string = '';
  @Input() entityStatus: string = '';
  @Input() displayFields: string[];
  @Input() requestor: string;
  @Input() storeKeyName: string;
  @Input() parentId: number = -1;
  @Input() objectId: number = 0;
  @Input() canIEdit: boolean;
  @Input() showEdit: boolean = false;
  @Input() showDelete: boolean = false;
  @Input() showRefresh: boolean = false;
  @Input() printUrl: string = '';
  @Input() customIcons: ICustomButton = null;
  @Input() formCssName: string = '';

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public loading$: Observable<boolean> = new BehaviorSubject(false);
  public objectData$: BehaviorSubject<GenericObjectData> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public hasStaleData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public formDefinition$: BehaviorSubject<IFormDefinition> = new BehaviorSubject(null);
  public operation$: BehaviorSubject<string> = new BehaviorSubject(this.myConstants.operationTypeDetails);
  errorMessage: string = ''; 
  controllerName: string;
  currentOperation: string = '';
  listDefinition: IListDefinition;
  subscription: Subscription = new Subscription();

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onFormEvent(event: IHomEventEmitter): void {
    switch (event.event) {
      case ButtonType.edit:
        this.operation$.next(this.myConstants.operationTypeEdit);
        this.customEvent.emit(event);
        break;
      case ButtonType.reload:
        this.store.dispatch(new DynamicListActions.GetList({ listDefinition: this.listDefinition, listFilter: this.listDefinition.defaultListFilter, parentId: this.parentId }));
        break;
      case ButtonType.cancel:
        this.store.dispatch(new DynamicListActions.ClearErrorsList({ storeName: this.storeName, parentId: this.parentId }));
        this.operation$.next(this.myConstants.operationTypeDetails);
        this.customEvent.emit(event);
        break;
      default:
        //PARENT will handle all other requests emit and parent container will handle - update/save dispatches
        //THIS container will respond to errors from that event, if any are hit
        this.customEvent.emit(event);
        break;
    }
  }

  ngOnInit() {
    this.operation$.subscribe(val => this.currentOperation = val);
         
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(filter(x => x.requestor === this.storeName),map(x => x.show));

    this.getDetailFromListStore();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['objectId'] && !changes['objectId'].firstChange) {
      this.objectId = changes['objectId'].currentValue;
      this.getDetailFromListStore();
    }

    if ((changes['title'] && !changes['title'].firstChange) ||
      (changes['entityLabel'] && !changes['entityLabel'].firstChange) ||
      (changes['entityStatus'] && !changes['entityStatus'].firstChange) )   {
      this.setFormDefinition();
    }
  }

  getDetailFromListStore() {
    this.subscription.add(this.store.pipe(select(fromDynamicList.getListByType(this.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId ==  this.parentId)))
      .subscribe((state: IListObjectData) => {
         if (state && state.data) {
          let data = cloneDeep(this.currentOperation === this.myConstants.operationTypeCreate ? new GenericObjectData() : state.data.find(row => row[this.storeKeyName] == this.objectId));
          this.objectData$.next(data);
          this.errorData$.next(state.errorData);
           this.hasStaleData$.next(state.staleData);
          if (state.event && !state.errorData.length) {
            //have an event and no errors, so update/create was successful, remit as close/complete and let parent container handle as needed 
            const newEvent: string = state.event.event === this.myConstants.emitterEventCreate ? this.myConstants.emitterEventCreateComplete
              : state.event.event === this.myConstants.emitterEventUpdate ? this.myConstants.emitterEventUpdateComplete
                : this.myConstants.emitterEventClose;
            const newEmitter: IHomEventEmitter = { action: state.event.action, data: state.event.data, event: newEvent, requestor: state.event.requestor };
            this.customEvent.emit(newEmitter);

            this.store.dispatch(new DynamicListActions.ClearEventList({ storeName: this.storeName, parentId: this.parentId }));
            this.operation$.next(this.myConstants.operationTypeDetails);
          }
        }
      }));      

    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.storeName, this.parentId)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
          this.listDefinition = listDefinition;
          this.setFormDefinition();
        }
      ));
  }

  setFormDefinition(): void {
    this.formDefinition$.next({
      formTitle: this.title,
      showNext: false,
      showPrev: false,
      requestor: this.requestor,
      entityLabel: this.entityLabel,
      entityStatus: this.entityStatus
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
