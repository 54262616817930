/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';

import { AdminDashboardService } from '../../services/admin-dashboard.service';

@Component({
  selector: 'unit-measure-xml-mappings',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './unit-measure-xml-mappings.component.html'
})
export class UnitMeasureXmlMappingsComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public adminDashboardService: AdminDashboardService) { }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.adminDashboardService.loadUnitMeasureMappingListDefinition());
    });
  }
}
