import { Component,  Input,  Output, EventEmitter } from '@angular/core';

import { IContactCustomerFlag } from '../../../view-models/index';

@Component({
  selector: 'contact-customer-flag',
  templateUrl: './contact-customer-flag.component.html'
})
export class ContactCustomerFlagComponent  {
  @Input() contactId: number;
  @Input() canFlagCustomer: boolean;
  @Input() hasStaleData: boolean;
  @Input() objectData: IContactCustomerFlag;

  @Output() detailEvent = new EventEmitter<string>();

  constructor() {}

  getFlagColor(): string {
    return this.objectData && this.objectData.customerFlagColor ? this.objectData.customerFlagColor : 'inherit';
  }

  getFlagDesc(canEdit: boolean): string {
    let desc: string = '';
    if (canEdit) {
      desc = this.objectData && this.objectData.customerFlagName ? this.objectData.customerFlagName.concat(' (click to edit)') : 'Set Customer Flag';
    } else {
      desc = this.objectData ? this.objectData.customerFlagName : '';
    }
    return desc;
  }

  showDetail() {
    this.detailEvent.emit();
  }
}
