<div [ngClass]="{ 'loading-mask':  (working$ | async)}">
  <div *ngIf="(listDefinition$ | async)">
    <fw-dynamic-list [vmListDefinition]="listDefinition$ | async"
                     (selectEvent)="onSelect($event)"
                     (pageEvent)="onPage($event)">
    </fw-dynamic-list>
    <div class="app-btn-box">
      <button class="app-btn app-btn--primary"
              type="button"
              (click)="confirmSelect()"
              [disabled]="!selectedPo">
        Select
      </button>
    </div>
  </div>
</div>
