import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IGeneralContractor } from '../../../view-models/i-general-contractor';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/i-list-definition';

import * as fromRoot from '../../../../store/reducers/index';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'general-contractor-skus',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './general-contractor-skus.component.html'
})
export class GeneralContractorSkusComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public generalContractors: IGeneralContractor[];
  subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public adminService: AdminService,
    public router: Router) { }

  public changeGC(generalContractorId: number) {
    this.router.navigate([{ outlets: { skusOutlet: ['skus', generalContractorId] } }], {
      relativeTo: this.activeRoute, skipLocationChange: true
    });
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
      this.changeGC(1); //default to Home Depot
    });
  }

  newRequest(): void {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('generalContractor')))
      .subscribe((data) => {
        this.generalContractors = data;
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
