/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { IListDefinition} from '../../../../../fw/dynamic-list/interfaces/index';
import { ProjectService } from '../../services';

@Component({
  selector: 'project-communication-events',
  templateUrl: './project-communication-events.component.html'
})
export class ProjectCommunicationEventsComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(
    public activeRoute: ActivatedRoute,
    public projectService: ProjectService  ) {
  }

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
        const projectId = +paramMap.get('id');
        this.listDefinition$.next(null);
        this.listDefinition$.next(this.projectService.loadProjectCommunicationEventsListDefinition(projectId));
        }
      );
  }

  ngOnDestroy() {}
}
