import { Component, OnInit, ChangeDetectionStrategy,  Inject, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { cloneDeep, isArray } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, ListFilter, OrderTerm } from '../../../../../fw/dynamic-list/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { AdminEvent, AdminStore, PrivilegeRuleEntitySpecifierType } from '../../enums/admin.enums';
import { IProviderUser, IUserRoleViewModel, IProviderUserPrivilegeViewModel} from '../../../view-models';
import { IErrorData, IResponseBase, IHomDictionary } from '../../../../../shared/interfaces';
import { IAllDynamicData, getSelectedRecord } from '../../../../../fw/dynamic-list/store';
import { AdminSecurityService } from '../../services/admin-security.service';
import { DomainObjectService } from '../../../../../shared/services';

@Component({
  selector: 'user-access-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-access-manager.component.html'
})
export class UserAccessManagerComponent implements OnInit, OnDestroy {
  public providerUserId: number;
  public providerUser: IProviderUser;
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public userRoles$: BehaviorSubject<IUserRoleViewModel[]> = new BehaviorSubject([]);
  public primaryGeneralContractors$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  public secondaryGeneralContractors$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  public primaryServices$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  public secondaryServices$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  public primaryLocations$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  public secondaryLocations$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  public primaryBranches$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  public secondaryBranches$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  public warrantyLocations$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  public currentAccess$: BehaviorSubject<IUserRoleViewModel[] | IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  public currentChildAccess$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  //working flags - so each section can have a working indicator
  public workingInRoles$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public workingInPrimarySvcs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public workingInSecondarySvcs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public workingInPrimaryGcs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public workingInSecondaryGcs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public workingInPrimaryLocs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public workingInSecondaryLocs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public workingInPrimaryBranch$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public workingInSecondaryBranch$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public workingInWarrantyLocs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public workingInCreate$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public haveAllRoles$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public haveAllGcs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public haveAllLocations$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public haveAllBranches$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public haveAllServices$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  //privilegeRuleEntitySpecifierTypes
  public prest_primaryProviderLocation: number;
  public prest_secondaryProviderLocation: number;
  public prest_warrantyBranch: number;
  public prest_primaryGeneralContractor: number;
  public prest_secondaryGeneralContractor: number;
  public prest_primaryService: number;
  public prest_secondaryService: number;
  public prest_userRole: number;

  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminSecurityService,
    public domainObjectService: DomainObjectService,
    public store: Store<IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.saveUserAccess:
        if (event.action === PrivilegeRuleEntitySpecifierType.userRole.toString()) {
          this.saveUserRoles(event.data, event.event);
        } else {
          if (isNaN(Number(event.action))) {
            console.log('DEV ERROR:  event action must be a valid PrivilegeRuleEntitySpecifierType number and is not: ', event);
            break;
          }
          if (this.adminService.isLocationType(+event.action)) {
            this.saveUserPrivLocationsAndBranches(event.data, event.event, +event.action);
          } else {
            this.saveUserPrivItems(event.data, event.event, +event.action);
          }
        }
        break;
      case AdminEvent.deleteUserAccess:
        if (event.action === PrivilegeRuleEntitySpecifierType.userRole.toString()) {
          this.saveUserRoles([event.data], event.event);
        } else {
          if (isNaN(Number(event.action))) {
            console.log('DEV ERROR:  event action must be a valid PrivilegeRuleEntitySpecifierType number and is not: ', event);
            break;
          }
          if (this.adminService.isLocationType(+event.action)) {
            this.saveUserPrivLocationsAndBranches(isArray(event.data) ? event.data : [event.data], event.event, +event.action);
          } else {
            this.saveUserPrivItems([event.data], event.event, +event.action);
          }
        }
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.providerUserId = +paramMap.get('id');
      this.setPrestConstants();
      this.getProviderUser();
      this.getUserRoles();
      this.getData(PrivilegeRuleEntitySpecifierType.primaryGeneralContractor);
      this.getData(PrivilegeRuleEntitySpecifierType.secondaryGeneralContractor);
      this.getData(PrivilegeRuleEntitySpecifierType.primaryService);
      this.getData(PrivilegeRuleEntitySpecifierType.secondaryService);
      this.getData(PrivilegeRuleEntitySpecifierType.primaryProviderLocation);
      this.getData(PrivilegeRuleEntitySpecifierType.primaryBranch);
      this.getData(PrivilegeRuleEntitySpecifierType.secondaryProviderLocation);
      this.getData(PrivilegeRuleEntitySpecifierType.secondaryBranch);
      this.getData(PrivilegeRuleEntitySpecifierType.warrantyBranch);

      //at end run the get alls TODO - these will be used to add additional roles, svs, gcs, locs, branches
      this.getAllRoles();
      this.getAllLocations();
      this.getAllGeneralContractors();
      this.getAllServices();
      this.getAllBranches()
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setPrestConstants(): void {
    this.prest_primaryGeneralContractor = PrivilegeRuleEntitySpecifierType.primaryGeneralContractor;
    this.prest_secondaryGeneralContractor = PrivilegeRuleEntitySpecifierType.secondaryGeneralContractor;
    this.prest_primaryProviderLocation = PrivilegeRuleEntitySpecifierType.primaryProviderLocation;
    this.prest_secondaryProviderLocation = PrivilegeRuleEntitySpecifierType.secondaryProviderLocation;
    this.prest_warrantyBranch = PrivilegeRuleEntitySpecifierType.warrantyBranch;
    this.prest_primaryService = PrivilegeRuleEntitySpecifierType.primaryService;
    this.prest_secondaryService = PrivilegeRuleEntitySpecifierType.secondaryService;
    //user role is not a privilege rule entity specifier - plugged this into the ui so could genericize components.
    this.prest_userRole = PrivilegeRuleEntitySpecifierType.userRole;
  }

  getProviderUser(): void {
    const def: IListDefinition = this.adminService.loadUserSecurityListDefinition();
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(def.storeName, -1, def.rowKeyId, this.providerUserId)))
      .subscribe((entity: IProviderUser) => {
        this.providerUser = cloneDeep(entity);
      })
    );
  }

  getUserRoles(): void {
    this.workingInRoles$.next(true);
    this.errorData$.next([]);
    let listFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.orderTerm = [new OrderTerm('providerRoleName')];
    this.subscription.add(this.domainObjectService.getByMethodById('UserRoleViewModel', 'ByUser', this.providerUserId, listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.userRoles$.next(response.data);
        } else {
          this.errorData$.next(response.errorData);
        }
        this.workingInRoles$.next(false);
      }));
  }

  getData(specifierType: PrivilegeRuleEntitySpecifierType ): void {
    this.setWorking(specifierType, true);

    this.errorData$.next([]);
    let params: IHomDictionary[] = [
      { key: 'providerUserId', value: this.providerUserId },
      { key: 'privilegeRuleEntitySpecifierType', value: specifierType }
      ]
    this.subscription.add(this.domainObjectService.getByMethodParams('ProviderUserPrivilegeViewModel', 'GetByProviderUserBySpecifierType', params)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.setData(specifierType, response.data);
        } else {
          this.errorData$.next(response.errorData);
        }
        this.setWorking(specifierType, false);
      }));
  }

  setData(specifierType: PrivilegeRuleEntitySpecifierType, data: IProviderUserPrivilegeViewModel[]): void {
    switch (specifierType) {
      case PrivilegeRuleEntitySpecifierType.primaryGeneralContractor:
        this.primaryGeneralContractors$.next(data);
        break
      case PrivilegeRuleEntitySpecifierType.secondaryGeneralContractor:
        this.secondaryGeneralContractors$.next(data);
        break
      case PrivilegeRuleEntitySpecifierType.primaryProviderLocation:
        this.primaryLocations$.next(data);
        break
      case PrivilegeRuleEntitySpecifierType.secondaryProviderLocation:
        this.secondaryLocations$.next(data);
        break
      case PrivilegeRuleEntitySpecifierType.primaryService:
        this.primaryServices$.next(data);
        break
      case PrivilegeRuleEntitySpecifierType.secondaryService:
        this.secondaryServices$.next(data);
        break
      case PrivilegeRuleEntitySpecifierType.primaryBranch:
        this.primaryBranches$.next(data);
        break
      case PrivilegeRuleEntitySpecifierType.secondaryBranch:
        this.secondaryBranches$.next(data);
        break
      case PrivilegeRuleEntitySpecifierType.warrantyBranch:
        this.warrantyLocations$.next(data);
        break
      default:
        break;
    }
  }

  setWorking(specifierType: PrivilegeRuleEntitySpecifierType, working: boolean): void {
    switch (specifierType) {
      case PrivilegeRuleEntitySpecifierType.primaryGeneralContractor:
        this.workingInPrimaryGcs$.next(working);
        break
      case PrivilegeRuleEntitySpecifierType.secondaryGeneralContractor:
        this.workingInSecondaryGcs$.next(working);
        break
      case PrivilegeRuleEntitySpecifierType.primaryProviderLocation:
        this.workingInPrimaryLocs$.next(working);
        break
      case PrivilegeRuleEntitySpecifierType.secondaryProviderLocation:
        this.workingInSecondaryLocs$.next(working);
        break
      case PrivilegeRuleEntitySpecifierType.primaryService:
        this.workingInPrimarySvcs$.next(working);
        break
      case PrivilegeRuleEntitySpecifierType.secondaryService:
        this.workingInSecondarySvcs$.next(working);
        break
      case PrivilegeRuleEntitySpecifierType.primaryBranch:
        this.workingInPrimaryBranch$.next(working);
        break
      case PrivilegeRuleEntitySpecifierType.secondaryBranch:
        this.workingInSecondaryBranch$.next(working);
        break
      case PrivilegeRuleEntitySpecifierType.warrantyBranch:
        this.workingInWarrantyLocs$.next(working);
        break
      default:
        break;
    }
  }

  getCurrentAccessByType(specifierType: number): IUserRoleViewModel[] | IProviderUserPrivilegeViewModel[] {
    switch (specifierType) {
      case PrivilegeRuleEntitySpecifierType.userRole:
        return this.userRoles$.value;
      case PrivilegeRuleEntitySpecifierType.primaryProviderLocation:
        return this.primaryLocations$.value;
      case PrivilegeRuleEntitySpecifierType.secondaryProviderLocation:
        return this.secondaryLocations$.value;
      case PrivilegeRuleEntitySpecifierType.primaryBranch:
        return this.primaryBranches$.value;
      case PrivilegeRuleEntitySpecifierType.secondaryBranch:
        return this.secondaryBranches$.value;
      case PrivilegeRuleEntitySpecifierType.warrantyBranch:
        return this.warrantyLocations$.value;
      case PrivilegeRuleEntitySpecifierType.primaryGeneralContractor:
        return this.primaryGeneralContractors$.value;
      case PrivilegeRuleEntitySpecifierType.secondaryGeneralContractor:
        return this.secondaryGeneralContractors$.value;
      case PrivilegeRuleEntitySpecifierType.primaryService:
        return this.primaryServices$.value;
      case PrivilegeRuleEntitySpecifierType.secondaryService:
        return this.secondaryServices$.value;
      default:
        break;
    }
  }

  //*GET ALLs*//
  getAllRoles(): void {
    if (this.adminService.allRoles.length > 0) {
      this.haveAllRoles$.next(true);
      return;
    }
    this.errorData$.next([]);
    let listFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.orderTerm = [new OrderTerm('roleName')];
    this.subscription.add(this.domainObjectService.getListByMethod('ProviderRole', 'Index', null, listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.adminService.allRoles = response.data;
          this.haveAllRoles$.next(true);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  getAllGeneralContractors(): void {
    if (this.adminService.allGcs.length > 0) {
      this.haveAllGcs$.next(true);
      return;
    }
   this.errorData$.next([]);
    let params: IHomDictionary[] = [
      { key: 'privilegeRuleEntitySpecifierType', value: PrivilegeRuleEntitySpecifierType.primaryGeneralContractor }
    ];
  this.subscription.add(this.domainObjectService.getByMethodParams('ProviderUserPrivilegeViewModel', 'GetAllBySpecifierType', params)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.adminService.allGcs = response.data;
          this.haveAllGcs$.next(true);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  getAllServices(): void {
    if (this.adminService.allServices.length > 0) {
      this.haveAllServices$.next(true);
     return;
    }
    this.errorData$.next([]);
    let params: IHomDictionary[] = [
      { key: 'privilegeRuleEntitySpecifierType', value: PrivilegeRuleEntitySpecifierType.primaryService }
    ];
    this.subscription.add(this.domainObjectService.getByMethodParams('ProviderUserPrivilegeViewModel', 'GetAllBySpecifierType', params)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.adminService.allServices = response.data;
          this.haveAllServices$.next(true);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  getAllLocations(): void {
    if (this.adminService.allLocations.length > 0) {
      this.haveAllLocations$.next(true);
   return;
    }
   this.errorData$.next([]);
    let params: IHomDictionary[] = [
      { key: 'privilegeRuleEntitySpecifierType', value: PrivilegeRuleEntitySpecifierType.primaryProviderLocation }
    ];
    this.subscription.add(this.domainObjectService.getByMethodParams('ProviderUserPrivilegeViewModel', 'GetAllBySpecifierType', params)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.adminService.allLocations = response.data;
          this.haveAllLocations$.next(true);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  getAllBranches(): void {
    if (this.adminService.allBranches.length > 0) {
      this.haveAllBranches$.next(true);
     return;
    }
  this.errorData$.next([]);
    let params: IHomDictionary[] = [
      { key: 'privilegeRuleEntitySpecifierType', value: PrivilegeRuleEntitySpecifierType.primaryBranch }
    ];
   this.subscription.add(this.domainObjectService.getByMethodParams('ProviderUserPrivilegeViewModel', 'GetAllBySpecifierType', params)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.adminService.allBranches = response.data;
         this.haveAllBranches$.next(true);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  saveUserPrivLocationsAndBranches(items: IProviderUserPrivilegeViewModel[], event: string, specifierType: number): void {
    //distinguish between branches and locations
    let newLocations: IProviderUserPrivilegeViewModel[] = items.filter(x => x.privilegeRuleEntitySpecifierId === PrivilegeRuleEntitySpecifierType.primaryProviderLocation
      || x.privilegeRuleEntitySpecifierId === PrivilegeRuleEntitySpecifierType.secondaryProviderLocation);
    let newBranches: IProviderUserPrivilegeViewModel[] = items.filter(x => x.privilegeRuleEntitySpecifierId === PrivilegeRuleEntitySpecifierType.primaryBranch
      || x.privilegeRuleEntitySpecifierId === PrivilegeRuleEntitySpecifierType.secondaryBranch);
      if (newLocations && newLocations.length) {
        this.saveUserPrivItems(newLocations, event, specifierType);
    }
    if (newBranches && newBranches.length) {
      this.saveUserPrivItems(newBranches, event, specifierType === PrivilegeRuleEntitySpecifierType.primaryProviderLocation
        ? PrivilegeRuleEntitySpecifierType.primaryBranch : PrivilegeRuleEntitySpecifierType.secondaryBranch);
    }
 }

  //*DB UPDATES*//
  saveUserPrivItems(items: IProviderUserPrivilegeViewModel[], event: string, specifierType: number): void {
    this.errorData$.next([]);
    let newPrivIds: number[] = [];
    const currentAccessValues = this.getCurrentAccessByType(specifierType) as IProviderUserPrivilegeViewModel[];
    let currentPrivIds: number[] = currentAccessValues.map(r => r.entityId);
    if (event === AdminEvent.deleteUserAccess) {
      let workingPrivIds = cloneDeep(currentPrivIds.map(r => r));
      items.forEach(item => {
        const idx = workingPrivIds.findIndex(x => x === item.entityId);
        if (idx >= 0) {
          workingPrivIds.splice(idx, 1);
          newPrivIds = workingPrivIds;
        }
      });
    }  else {
      newPrivIds = items.map(r => r.entityId);
      newPrivIds = [...newPrivIds, ...currentPrivIds];
    }

    this.setWorking(specifierType, true);
    this.subscription.add(this.domainObjectService.createByMethod('PrivilegeRuleEntitySpecifier', 'UpdatePresv',
      {
        'privilegeRuleEntitySpecifierId': specifierType,
        'currentUserId': this.providerUserId.toString(),
        'currentEntitySelectList': newPrivIds
      })
      .subscribe((response: IResponseBase) => {
        if (response.success) {
            this.getData(specifierType);
          } else {
            this.errorData$.next(response.errorData);
        }
        this.setWorking(specifierType, false);
        }));

  }

  saveUserRoles(roles: IUserRoleViewModel[], event: string): void {
    if (!roles.length) {
      console.log('DEV ERROR:  no roles to save. ', roles, event);
      return;
    }
    this.workingInRoles$.next(true);
    this.errorData$.next([]);

    let newRoleIds: number[] = [];
    let currentRoleIds: number[] = this.userRoles$.value.map(r => r.providerRoleId);
    if (event === AdminEvent.deleteUserAccess) {
      //delete one role at a time only, so only one record is in array
      const idx = currentRoleIds.findIndex(x => x === roles[0].providerRoleId);
      if (idx >= 0) {
        currentRoleIds.splice(idx, 1);
        newRoleIds = currentRoleIds;
      }
    } else {
      newRoleIds = roles.map(r => r.providerRoleId);
      newRoleIds = [...newRoleIds, ...currentRoleIds];
    }

    this.subscription.add(this.domainObjectService
      .createByMethod('UserRoleViewModel', 'UpdateUserRoles', { 'userId': this.providerUserId, 'roleIds': newRoleIds })
      .subscribe((response: IResponseBase) => {
        if (response.success) {
            this.getUserRoles();
          } else {
            this.errorData$.next(response.errorData);
        }
        this.workingInRoles$.next(false);
        }));
  }
}
