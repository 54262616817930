<portal-detail-layout-container [portalId]="managerPortalId"
                                [portalEntityId]="'-1'"
                                [title]="title"
                                [tabContainerName]="tabContainerName"
                                (tabsLoadedEvent)="onTabsLoaded($event)">
  <div class="user-dash-items">
    <div>
      <fw-section-title title="Filter Dashboard Results By" [underline]="true"></fw-section-title>
      <div class="user-dash-filters" [ngClass]="{ 'loading-mask-no-spinner': loading$ | async }">
        <div class="user-dash-filter">
          <div title="Provider Locations" class="user-dash-filter__label">Location(s)</div>
          <fw-multiselect-dropdown #locations
                                   [options]="locationOptions"
                                   [texts]="multiSelectLabels"
                                   [settings]="multiSelectSettings"
                                   [isDisabled]="!locationOptions.length"
                                   (onAdded)="addLocation($event)"
                                   (onRemoved)="removeLocation($event)"
                                   (onUncheckAll)="uncheckedAllLocations()">
          </fw-multiselect-dropdown>
        </div>

        <div class="user-dash-filter">
          <div title="Branches" class="user-dash-filter__label">Branch(es)</div>
          <fw-multiselect-dropdown #branches
                                   [options]="branchOptions$ | async"
                                   [texts]="multiSelectLabels"
                                   [settings]="multiSelectSettings"
                                   [isDisabled]="!(branchOptions$ | async).length"
                                   (onAdded)="addBranch($event)"
                                   (onRemoved)="removeBranch($event)">
          </fw-multiselect-dropdown>
        </div>
        <div class="app-btn-box">
          <button type="button"
                  class="app-btn app-btn--primary"
                  (click)="runFilter(locations.selectedCount === 0 && branches.selectedCount === 0)"
                  title="Apply Filter">
            Apply Filter
          </button>
        </div>
      </div>
    </div>

    <div>
      <fw-section-title title="Dashboard Counts" [underline]="true"></fw-section-title>
      <div [ngClass]="{ 'loading-mask-no-spinner': loading$ | async }">
        <div class="fw-info-box">
          <fw-info [infoTitle]="'Count Information'">
            <div class="fw-info-box--info">
              <p>The counts reflect the dashboard level filters, if any.</p>
              <p>Filters applied within each list are not reflected in these counts.</p>
            </div>
          </fw-info>
        </div>
        <dashboard-counts *ngIf="(dashCounts$ | async) as dashCounts"
                          [dashCounts]="dashCounts"
                          [errorData]="errorData$ | async"
                          [errorMessage]="errorMessage">
        </dashboard-counts>
        <div class="user-dash-links">
          <div class="user-dash-link" (click)="openLink('PendingTasksComponent')">
            {{ pendingTasksLabel }}
          </div>
          <!--<div class="user-dash-link" (click)="openLink('SssInvitesRequiringActionComponent')">{{sssInvitesLabel}}</div>-->
        </div>
      </div>
    </div>
  </div>
</portal-detail-layout-container>

