import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { ScreenService } from '../fw-shared/services/screen.service';
import { MenuService } from '../fw-menus/services/menu.service';

import { isAuthenticated, hasUserPreferences, hasValidSecurityAccess } from '../../auth/store/auth.selectors';
import * as fromRoot from '../../app/store/reducers/index';

@Component({
    selector: 'fw-header',
    templateUrl: './fw-header.component.html'
})
export class HeaderComponent implements OnInit {
  public authenticated$: Observable<boolean>;
  public hasUserPreferences$: Observable<boolean>;
  public hasValidSecurityAccess$: Observable<boolean>;
  subscription: Subscription = new Subscription();

  constructor( public screenService: ScreenService,
    public rootStore: Store<fromRoot.IState>,
    public menuService: MenuService  ) { }

  //public headerTitle: string = 'H&#0332;M Solutions Angular Portal!!';
    ngOnInit(): void {

      this.authenticated$ = this.rootStore.pipe(select(isAuthenticated));
      this.hasUserPreferences$ = this.rootStore.pipe(select(hasUserPreferences));
      this.hasValidSecurityAccess$ = this.rootStore.pipe(select(hasValidSecurityAccess));

      this.subscription.add(this.authenticated$.subscribe((val) => {
        this.menuService.setVisibility(val);
      }));
  }
}
