<!--DELETE HANDLING ONCE CLICK REQUEST DELETE -- START-->
<ng-container *ngIf="(row[listDefinition.rowKeyId] === activeDeleteId)">
  <div *ngIf="!isDeleting"
       class="dynamic-list__item dynamic-list__deleting"
       [ngStyle]="{'grid-row': (index + 3) + ' / ' + (index + 4), 'grid-column':  '1 / ' + colCount.toString() }">
    <div class="dynamic-list__deleting--text">
      Confirm Deletion: {{ row['entityLabel'] }}
    </div>
  </div>
  <div *ngIf="isDeleting"
       class="dynamic-list__item dynamic-list__deleting"
       [ngStyle]="{'grid-row': (index + 3) + ' / ' + (index + 4), 'grid-column':  '1 / ' + colCount.toString()  }">
    <div class="dynamic-list__deleting--text">
      Deleting: {{ row['entityLabel'] }}
    </div>
  </div>
  <div class="dynamic-list__item"
       [ngStyle]="{'grid-row': (index + 3) + ' / ' + (index + 4) }">
    <div class="dynamic-list__item__actions">
      <button class="app-btn-icon app-btn-icon--neutral dynamic-list__item__actions--icon"
              (click)="cancelDelete()"
              [disabled]="isDeleting"
              title="Cancel Delete">
        <i class="fas fa-ban "></i>
      </button>
      <button class="app-btn-icon app-btn-icon--danger dynamic-list__item__actions--icon"
              (click)="deleteRecord()"
              [disabled]="isDeleting"
              title="Delete">
        <span class="fas fa-trash "></span>
      </button>
      <span *ngIf="deleteError">{{ deleteError }}</span>
    </div>
  </div>
</ng-container>
<!--DELETE HANDLING ONCE CLICK REQUEST DELETE -- END-->

<ng-container *ngIf="(row[listDefinition.rowKeyId] !== activeDeleteId)">
  <ng-container *ngFor="let col of (colData$ | async); let j = index;  let lastCol = last; let firstCol = first;">
    <div *ngIf="col.visible" class="dynamic-list__item {{ lastRow ? 'dynamic-list__item--last-row' : '' }} {{ j === 0 ? 'dynamic-list__item--col1' : '' }}"
         [ngClass]="rowDef.rowClass"
         [ngStyle]="{'grid-row': (index + 3) + ' / ' + (index + 4), 'grid-column': col.gridCol }">
      <fw-dynamic-list-item *ngIf="col.fieldDefinition"
                            class="dynamic-list__item--text"
                            [col]="col"
                            [row]="row"
                            [rowIndex]="index"
                            [activeIndex]="activeIndex"
                            [detailError]="detailError"
                            [storeName]="listDefinition.storeName"
                            [screenIsSmall]="screenSize.screenIsSmall">
      </fw-dynamic-list-item>
    </div>
  </ng-container>

  <div *ngIf="showRowTools || showInfo"
       class="dynamic-list__item dynamic-list__row-tools {{ lastRow ? 'dynamic-list__item--last-row' : '' }}"
       [ngStyle]="{'grid-row': (index + 3) + ' / ' + (index + 4) }">
    <div class="dynamic-list__item__actions">
      <fw-entity-status *ngIf="rowDef && rowDef.entityStatus" [vmEntityStatus]="row.entityStatus"></fw-entity-status>
      <!--row buttons-->
      <ng-container *ngIf="overflowBtns.length > 0">
        <dynamic-list-overflow-btns [btns]="overflowBtns"
                                    [showInfo]="showInfo"
                                    [row]="row"
                                    [listDisabled]="listDisabled"
                                    (customEvent)="onRowBtnClick($event)">
        </dynamic-list-overflow-btns>
      </ng-container>
      <ng-container *ngIf="(rowBtns$ | async) as rowBtns">
        <ng-container *ngFor="let btn of rowBtns; let i = index; ">
          <ng-container>
            <button *ngIf="!btn.definition.isDownload"
                    class="{{ btn.css }}"
                    [disabled]="btn.disabled"
                    title="{{btn.definition.title}}"
                    (click)="onRowBtnClick(btn.definition)">
              <i class="{{btn.definition.icon}}"></i>
            </button>
            <a *ngIf="btn.definition.isDownload"
               download="{{row['fileName']}}"
               title="{{btn.definition.title}}"
               href="{{row['fileUrl']}}"
               class="app-btn-icon  dynamic-list__item__actions--icon {{ btn.css }}"
               [attr.disabled]="btn.disabled ? '' : null">
              <i class="{{btn.definition.icon}}"></i>
            </a>
        </ng-container>
        </ng-container>
        <dynamic-list-data-row-info *ngIf="showInfo && overflowBtns.length === 0" [row]="row" class="dynamic-list__item__actions--info"></dynamic-list-data-row-info>
      </ng-container>

      <ng-container *ngIf="listDefinition.groupSelect">
        <fw-check-box #groupSelect class="dynamic-list__item__actions--cb"
                      [config]="cbConfig"
                      [isDisabled]="rowDef.selectDisabled"
                      [isChecked]="allSelected || isSelected"
                      (change)="selectClick($event)"></fw-check-box>
      </ng-container>

      <ng-container *ngIf="listDefinition.singleSelect" >
        <fw-radio-button #singleSelect class="dynamic-list__item__actions--radio"
                         [groupName]="'listOption'.concat(listDefinition.parentKey ? row[listDefinition.parentKey] : '').toString()"
                         [buttons]="optBtn"
                         [isDisabled]="rowDef.optionDisabled"
                         (change)="onOptionChange($event.srcElement.value)">
        </fw-radio-button>
      </ng-container>

    </div>
  </div>
</ng-container>
