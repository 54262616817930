<div class="accordian">
  <div class="accordian__section" [ngClass]="{'accordian__section--inactive': !contentVisible }">
    <div class="accordian__header" (click)="toggleActive()">
      <div class="accordian__header__title" [ngClass]="{'accordian__header__title--active': contentVisible }">
        {{title}}
        <span *ngIf="subTitle" class="accordian__header__title--sep">-</span>
        <span *ngIf="subTitle" class="accordian__header__title--sub">{{ subTitle }}</span>
      </div>
      <ng-container *ngIf="customBtn">
        <button type="button"
                class="app-btn-icon {{ customBtn.cssName }} accordian__header__btn"
                [disabled]="!customBtn.enabled"
                [title]="customBtn.title"
                (click)="onCustom()">
          <i class="{{ customBtn.icon }}"></i>
        </button>
      </ng-container>
      <div class="accordian__header__toggle" [ngClass]="{'accordian__header__toggle--active': contentVisible }">
        <i class="fas fa-chevron-{{ contentVisible ? 'down' : 'right' }}"></i>
      </div>
    </div>
    <div *ngIf="contentVisible" class="accordian__content {{ wrapPad ? 'accordian__content--pad' : '' }}" [ngClass]="{'accordian__content--noscroll': !scollable }">
      <!--height and scrolling handled by content itself -->
      <ng-content></ng-content>
    </div>
  </div>
</div>
