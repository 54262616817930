<div class="crm-form-entry">
  <div class="crm-form-entry_row1">
    <fw-component-title [title]="sectionTitle">
    </fw-component-title>
    <div *ngIf="sectionInstructions" class="crm-form-entry_row1_section_instructions">
      <fw-section-title [title]="sectionInstructions"
                        [underline]="true">
      </fw-section-title>
    </div>
  </div>
  <div class="crm-form-entry_row2">
    <ng-container *ngFor="let question of entityFormSectionQuestions">
      <crm-form-question [crmFormQuestion]="question | async"
                         [entityDocumentId]="entityDocumentId"
                         (customEvent)="answerQuestion($event)">
      </crm-form-question>
    </ng-container>
  </div>
  <div class="crm-form-entry_row3">
    <div class="app-group-btn-box">
      <button type="button"
              *ngFor="let section of entityCrmForm.crmFormSections"
              class="app-group-btn app-btn--primary"
              [ngClass]="{'app-group-btn--selected': (this.nextUncompletedSectionId$ | async) === section.crmFormSectionId }"
              (click)="changeSection(section.crmFormSectionId)">
        {{ section.sectionTitle }}
      </button>
    </div>
    <div class="app-group-btn-box" *ngIf="formComplete$ | async">
      <button type="button"
              class="app-group-btn app-btn--primary"
              (click)="submitForm()">
        Submit
      </button>
    </div>
  </div>
</div>
