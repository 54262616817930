<ng-container *ngIf="!hideInstallers">
  <fw-app-tabs *ngIf="(tabs$ | async) as tabs"
               apptabs
               [portalId]="-1"
               [portalEntityId]="-1"
               [level]="1"
               [limit]="tabs.length"
               [tabDefinition]="tabs"
               [isLocallyDefined]="true"
               (tabChange)="onTabChange($event)">
  </fw-app-tabs>

  <div class="app-panel app-panel--concat-tab schedule-installers">
    <div class="schedule-installers__content">
      <available-installers *ngIf="showAvailable"
                            [availableInstallers]="availableInstallers"
                            [unavailableReason]="unavailableReason"
                            (customEvent)="onCustom($event)">
      </available-installers>
      <unavailable-installers *ngIf="!showAvailable"
                              [workOrderId]="workOrderId"
                              [startDate]="startDate"
                              [duration]="duration"
                              [durationType]="durationType"
                              [scheduleId]="scheduleId">
      </unavailable-installers>
    </div>
  </div>
</ng-container>
