import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { filter, map } from 'rxjs/operators';

import { IInstaller, Installer, IInstallerUpdateViewModel } from '../../../view-models/index';
import { IPortalObjectBtn } from '../../../portal-shared/interfaces';
import { InstallerEvent, InstallerStore } from '../../../installer/enums/installer.enums';
import { IErrorData } from '../../../../../shared/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IDynamicObject } from '../../../../../fw/dynamic-list/store/reducers/dynamic-object.reducer';
import { IDetailRequest } from '../../../../../fw/dynamic-detail/interfaces';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as DynamicObjectActions from '../../../../../fw/dynamic-list/store/actions/dynamic-object.actions';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicObject from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { IHomEventEmitter, HomEventEmitterService } from 'hom-lib/hom-event-emitter';
import { UpdateObjectByIdModel, CreateObjectModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { getListByType, IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store';
import { UserPriviledgesService } from '../../../../../auth/services';
import { InstallerDetailComponent } from '../../components';

@Component({
  selector: 'installer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './installer.component.html'
})
export class InstallerComponent implements OnInit, OnDestroy {
  @ViewChild('installerDetail') public installerDetail: InstallerDetailComponent;

  public portalId: number = 6;
  public installer$: BehaviorSubject<IInstaller> = new BehaviorSubject(null)
  public portalBtnConfig$: BehaviorSubject<IPortalObjectBtn[]> = new BehaviorSubject([]);
  public portalReload$: BehaviorSubject<string> = new BehaviorSubject('');
  public title: string = 'Installer';
  public displayFields = ['installerName', 'state', 'stateIncorporated',
    'businessType', 'taxIdNumber', 'isActive', 'emailWorkPacket', 'wantScheduleDailySummary',
    'contactName', 'addressLine1', 'addressCity', 'addressStateId', 'addressZipcode5',
    'phoneNumber', 'emailAddress'];
  public loading$: Observable<boolean>;
  public hasStaleData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public tabContainerName = 'installer-detail';
  public operation$: BehaviorSubject<string> = new BehaviorSubject(this.myConstants.operationTypeDetails);
  subscription = new Subscription();
  installerId: number;
  installerSub: Subscription;
  storeName: string = '';

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromFeature.IAllDynamicData>,
    public activeRoute: ActivatedRoute,
    public ups: UserPriviledgesService,
    public emitterService: HomEventEmitterService,
   @Inject(appConstants) public myConstants: IAppConstants  ) {
  }

  public onLayoutEvent(val: string): void {
    switch (val) {
      case InstallerEvent.edit:
        this.operation$.next(this.myConstants.operationTypeEdit);
        this.setPortalBtns();
        break;
      case InstallerEvent.cancel:
        this.operation$.next(this.myConstants.operationTypeDetails);
        this.setPortalBtns();
        break;
      case InstallerEvent.save:
        const model = this.installerDetail.getFormModel();
        this.onSave(model);
        break;
      case InstallerEvent.dirty:
        this.setPortalBtns(true);
        break;
      case InstallerEvent.reload:
        this.onReloadRequest();
        break;
      case InstallerEvent.updateComplete:
        this.operation$.next(this.myConstants.operationTypeDetails)
        break;
      default:
        break;
    }
  }

  public onDirty(): void {
    this.setPortalBtns(true);
  }

  public onCancel() {
    if (this.operation$.value === this.myConstants.operationTypeCreate) {
      //create button is only displayed when buttons are on bottom of form - so compliance portal, not installer portal
      let emitter: IHomEventEmitter = { requestor: 'installer', event: this.myConstants.emitterEventClose, action: '', data: null };
      this.emitterService.emitListEvent(emitter);
    } else {
      //push the original installer back out
      const originalInstaller = this.installer$.value;
      this.installer$.next(originalInstaller);
      this.store.dispatch(new DynamicObjectActions.ClearErrorsObj({ storeName: this.storeName, objectId: this.installerId }));
    }
  }

  public onCreate(model: IInstallerUpdateViewModel) {
    const emitter: IHomEventEmitter = { requestor: 'installer-detail', event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, this.installerId, 'Installer', 'Create', model, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }


  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      const operation: string = paramMap.get('operation');
      this.installerId = operation === this.myConstants.operationTypeCreate ? 0 : +paramMap.get('portalEntityId');
      //handle create from list of installers and read/edit from installer portal
      this.storeName = paramMap.get('storeName') || InstallerStore.installerInformation;
      this.newRequest();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.installerSub) {
      this.installerSub.unsubscribe();
    }
  }

  setPortalBtns(isDirty: boolean = false): void {
    let portalBtns: IPortalObjectBtn[] = [];
    if (this.ups.canManageInstallers$.value) {
      const currentOperation = this.operation$.value;
      switch (currentOperation) {
        case this.myConstants.operationTypeDetails:
          //edit
          portalBtns.push({
            title: 'Edit Installer',
            disabled: false,
            disabledTitle: '',
            event: InstallerEvent.edit,
            icon: 'fa-edit fas',
            css: 'app-btn-icon--secondary',
            confirm: false
          });
          break;
        case this.myConstants.operationTypeEdit:
          //cancel and save
          portalBtns.push({
            title: 'Cancel Edit',
            disabled: false,
            disabledTitle: '',
            event: InstallerEvent.cancel,
            icon: 'fas fa-ban',
            css: 'app-btn-icon--primary',
            confirm: false
          });
          portalBtns.push({
            title: 'Save Changes',
            disabled: !isDirty, //based on if a change arrive
            disabledTitle: '',
            event: InstallerEvent.save,
            icon: 'fas fa-save',
            css: 'app-btn-icon--success',
            confirm: false
          });
          break;
        default:
          break;
      }
    }

    portalBtns.push({
      title: 'Reload Installer',
      disabled: false,
      disabledTitle: '',
      event: InstallerEvent.reload,
      icon: 'fas fa-sync',
      css: 'app-btn-icon--primary',
      confirm: false
    });
      this.portalBtnConfig$.next(portalBtns);
    }

  onSave(model: IInstallerUpdateViewModel) {
    const keyData: IKey = { storeName: this.storeName, parentId: this.installerId, key: 'installerId', id: this.installerId }
    const emitter: IHomEventEmitter = { requestor: 'installer-detail', event: this.myConstants.emitterEventDoUpdateById, action: '', data: null };
    const updateData = new UpdateObjectByIdModel(keyData, 'Installer', 'Update', 'installerId', this.installerId, model, null, emitter);
    this.store.dispatch(new DynamicObjectActions.UpdateObjectByIdObj({ updateData }));
  }

  onReloadRequest() {
    const objectRequest: IDetailRequest = {
      controllerName: 'Installer',
      storeName: this.storeName,
      rowKeyId: 'installerId',
      objectId: this.installerId,
      displayFields: this.displayFields,
      metaUrl: '',
      controllerMethod: 'DetailsWithMetaData', //defaults to Index if not specified
      methodParameters: this.installerId.toString()
    };
    this.store.dispatch(new DynamicObjectActions.GetObject({ detailRequest: objectRequest }));
  }

  newRequest() {
    this.operation$.next(this.installerId === 0 ? this.myConstants.operationTypeCreate : this.myConstants.operationTypeDetails);
    this.hasStaleData$.next(false);
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(filter(x => x.requestor === this.storeName), map(x => x.show));

    if (this.installerId > 0) {
      if (this.installerSub) {
        this.installerSub.unsubscribe();
      }
      //store is the installerInformation dynamic object store
      this.installerSub = this.store.pipe(select(fromDynamicObject.getObjectDataById(this.storeName, this.installerId)))
        .subscribe((data: IInstaller) => {
          if (data) {
            let installer = cloneDeep(data);
            if (installer.addressLine1) {
              installer.addressLine1 = this.concatAddressLines(installer);
            }
            this.installer$.next(installer);
          this.setPortalBtns();
          }
        });
    } else {
      this.installer$.next(new Installer());
    }

    //special to watch for event
    if (this.installerId > 0) {
      //store is the installerInformation dynamic object store
      this.subscription.add(this.store.pipe(select(fromDynamicObject.getObjectDataByType(this.storeName)))
        .subscribe((store: IDynamicObject) => {
          const allStore = cloneDeep(store);
          const objData = allStore.objData.find(x => x.objectId == this.installerId);
          if (objData) {
            const staleData = !objData.data ? false : objData.staleData;
            this.hasStaleData$.next(staleData);
            this.errorData$.next(objData.errorData);
            if (objData && objData.event) {
              this.store.dispatch(new DynamicObjectActions.ClearEventObj({ storeName: this.storeName, objectId: this.installerId }));

              if (this.operation$.value !== this.myConstants.operationTypeCreate) {
                this.operation$.next(this.myConstants.operationTypeDetails);
                this.setPortalBtns();
              }
            }
          }
        }
      ));
    }
    else {
      //for create, will be from list store
      this.subscription.add(this.store.pipe(select(getListByType(this.storeName)))
        .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == -1)))
        .subscribe((state: IListObjectData) => {
          if (state) {
            if (state.event && (!state.errorData || !state.errorData.length)) {
              const event: IHomEventEmitter = cloneDeep(state.event);
              this.emitterService.emitListEvent(event);
              this.store.dispatch(new DynamicListActions.ClearEventList({ storeName: this.storeName, parentId: -1 }));
            }
            if (state.errorData) {
              this.errorData$.next(state.errorData);
            }
          }
        }));
    }

  }

  concatAddressLines(installer: IInstaller): string {
    let addr = installer.addressLine1
    if (installer.addressLine2) {
      addr = addr.concat('\r', installer.addressLine2);
    }
    if (installer.addressLine3) {
      addr = addr.concat('\r', installer.addressLine3);
    }

    return addr;
  }


}
