<fw-dynamic-detail-custom-container *ngIf="workCrewId && displayFields"
                                 [title]="title"
                                 [entityLabel]="entityLabel"
                                 [storeName]="myStoreName"
                                 [requestor]="requestor"
                                 [displayFields]="displayFields"
                                 [canIEdit]="canIEdit"
                                 [parentId]="installerId"
                                 [storeKeyName]="storeKeyName"
                                 [objectId]="workCrewId"
                                 [showEdit]="true"
                                 [showDelete]="false"
                                 [showRefresh]="true"
                                 (customEvent)="onCustom($event)">
</fw-dynamic-detail-custom-container>

