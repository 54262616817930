import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UserPriviledgesService } from '../../../../../auth/services/user-priviledges.service';

@Component({
  selector: 'project-file-uploads',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './project-file-uploads.component.html'
})

export class ProjectFileUploadsComponent implements OnInit {
  public providerUserId: number;

  constructor(public  userPrivileges: UserPriviledgesService) { } 

  ngOnInit(): void {
    this.providerUserId = this.userPrivileges.currentUserId$.getValue();
  }

}
