import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ICommunicationEventFileData } from '../../../portals/view-models/i-communication-event-file-data';
import { IFileTool, IContactProjectSelectEvent } from '../../../portals/portal-shared/interfaces';
import { FileToolEvent } from '../../../portals/portal-shared/enums/file-tool-event.enums';
import { IDocumentType } from '../../../portals/view-models';
import { ISmsFileTypeSelectEvent } from '../../interfaces/i-sms-entity-select-event';
import { SmsEntityName, SmsEvent } from '../../enums/sms.enums';

@Component({
  selector: 'sms-file',
  templateUrl: './sms-file.component.html'
})
export class SmsFileComponent implements OnInit, OnChanges {
  @Input() fileData: ICommunicationEventFileData;
  @Input() contactId: number;
  @Input() contactTypeId: number
  @Input() documentTypes: IDocumentType[];
  @Input() projectId: number;
  @ViewChild('toggle') public toggleBtn: ElementRef;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public isImage: boolean = false;
  public isPdf: boolean = false;
  public isWord: boolean = false;
  public isText: boolean = false;
  public isExcel: boolean = false;
  public subTitle: string = '';
  public appInitial: string = '';
  public cssName: string = '';
  public fileTools: IFileTool[] = [];
  public toolsVisible: boolean = false;
  public showAssociations: boolean = false;
  public showPOs: boolean = false;
  public showWOs: boolean = false;
  public showProjects: boolean = true;
  public fileTitle: string;
  public isAssociated: boolean = false;
  selectedActionIds: number[] = [];

  public activeDocumentTypeId$: BehaviorSubject<number> = new BehaviorSubject(-1);

  public showTools(event): void {
    this.toolsVisible = !this.toolsVisible;
    event.preventDefault();
  }
  public hoverTools(event, show: boolean): void {
    this.toolsVisible = show;
    event.preventDefault();
  }

  public selectTool(event: FileToolEvent): void {
    this.activeDocumentTypeId$.next(-1);

    switch (event) {
      case FileToolEvent.associate:
        //show popover so they can associate to the correct entity.
        if (this.toggleBtn) {
          this.toggleBtn.nativeElement.click();
        }
       break;
      default:
        this.customEvent.emit({ requestor: 'sms-communication-file', action: '', event: event, data: null });
        break;
    }
  }

  public showOrHideAssociations(show: boolean): void {
    this.showAssociations = show;
  }

  public selectFileType(event: ISmsFileTypeSelectEvent): void {
    if (event.fileTypeId > 0) {
      this.showPOs = event.entityName === SmsEntityName.purchaseOrder;
      this.showProjects = event.entityName === SmsEntityName.project;
      this.showWOs = event.entityName === SmsEntityName.workOrder;
      this.activeDocumentTypeId$.next(event.fileTypeId);
      this.selectedActionIds = event.actionIds;
    }
    if (event instanceof Event) {
      event.stopPropagation();
    }
  }

  public selectEntity(event: IContactProjectSelectEvent): void {
    this.showAssociations = false;
    let outEvent = event;
    outEvent.communicationEventFileId = this.fileData.communicationEventFileId;
    outEvent.associateToDocumentTypeId = this.activeDocumentTypeId$.value;
    outEvent.actionIds = this.selectedActionIds;
    //emit event back to parent to associate file to entity selected
    this.customEvent.emit({
      requestor: 'sms-communication-file',
      action: '',
      event: FileToolEvent.saveAssociation,
      data: outEvent
    });
  }

  ngOnInit(): void {
    this.isAssociated = this.fileData.entityPkId && this.fileData.entityPkId > 0;

    this.fileTitle = this.fileData.fileName.concat(' ', this.isAssociated ? this.fileData.entityName.concat(' ', this.fileData.entityPkId.toString()) : 'UNASSOCIATED');
    this.fileTools.push({ icon: 'fas fa-download', title: 'Download', event: FileToolEvent.download, disabled: false });
    this.fileTools.push({ icon: 'fas fa-link', title: 'Associate', event: FileToolEvent.associate, disabled: this.isAssociated });
    this.fileTools.push({ icon: 'fas fa-external-link', title: 'Open in a new tab', event: FileToolEvent.openInNewTab, disabled: false });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const fileTypeParts: string[] = this.fileData.fileType.split('/');
    const fileNameParts: string[] = this.fileData.fileName.split('.');
    this.toolsVisible = false;
    this.isImage = fileTypeParts[0] === 'image';
    this.isPdf = fileTypeParts.length > 1 && fileTypeParts[1] === 'pdf';
    this.isWord = fileTypeParts.length > 1 && fileTypeParts[1].includes('word');
    this.isExcel = fileTypeParts.length > 1 && fileTypeParts[1].includes('excel');
    this.isText = fileTypeParts[0] === 'text';

    this.subTitle = this.isImage ? ''
      : this.isPdf ? 'PDF Document'
        : this.isWord ? 'Word Document'
          : this.isExcel ? 'Excel File'
            : this.isText ? 'Text File'
              : '';

    this.appInitial = this.isImage || this.isPdf ? ''
      : this.isWord ? 'W'
        : this.isExcel ? 'X'
          : this.isText ? 'TXT'
            : fileNameParts.length > 0 ? fileNameParts[fileNameParts.length -1] : '?';

    this.cssName = this.isImage || this.isPdf ? ''
      : this.isWord ? 'sms-file__logo--word'
        : this.isExcel ? 'sms-file__logo--excel'
          : this.isText ? 'sms-file__logo--text'
            : 'sms-file__logo--text';
  }

}
