<footer class="footer">
  <div class="footer__company">
    &copy; HŌM Solutions Inc.
  </div>
  <div *ngIf="userPriviledgesService.canOneClickDial$ | async" class="footer__phone app-icon--small app-icon--white">
    <!--<i class="fad fa-phone-alt"></i>-->
  </div>
  <div class="footer__right">
    <div class="footer__right__db" *ngIf="(dataBaseName$ | async)">{{ 'Database Name: ' + (dataBaseName$ | async) }}</div>
    <i *ngIf="homHubService.connectionEstablished$ | async"  class="fas fa-signal-stream app-icon--small app-icon--white"></i>
  </div>
</footer>
