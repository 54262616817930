import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, filter , first, take } from 'rxjs/operators';

//store actions and reducers
import * as fromRoot from '../../../../app/store/reducers/index';
import * as fromSelectionLists from '../../../../shared/store/selectionLists/index';
import { GetEntityListByMethodParams, getSelectionListDataByType, InitSelectItems } from '../../../../shared/store/selectionLists/index';
import { IListFilter, OrderTerm, IListDefinition } from '../../../../fw/dynamic-list/interfaces';
import { IWorkOrderSurvey } from '../../view-models/index_two';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { AdminDashboardService } from '../services/admin-dashboard.service';
import {getSelectedRecord } from '../../../../fw/dynamic-list/store';

//Special Circumstance Lookup resolver
@Injectable()
export class WorkOrderSurveyResolver  {
  workOrderSurveyId: number;
  storeName: string;
  key: string;
  subscription: Subscription = new Subscription();
  filter: IListFilter = {
    isLookup: false,
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true, //do not want paged data
    currentPage: 1,
    searchTerm: [],
    orderTerm: [new OrderTerm('workOrderId')]
  };

  constructor(public selectionListStore: Store<fromSelectionLists.IStoreState>,
    public rootStore: Store<fromRoot.IState>,
    public adminDashService: AdminDashboardService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    //always reget
    this.workOrderSurveyId = +route.paramMap.get('id');
    this.key = route.paramMap.get('key');
    const listDefinition: IListDefinition = this.adminDashService.loadWorkOrderSurveyListDefinition(true);
    this.storeName = listDefinition.storeName;
    let purchaseOrderNumber: string = '';
    let surveyId: number = 0;
    this.selectionListStore.dispatch(new InitSelectItems({ storeName: 'surveyWorkOrder', parentId: -1 }));

    this.subscription.add(this.rootStore.pipe(
      select(getSelectedRecord(this.storeName, -1, listDefinition.rowKeyId, this.workOrderSurveyId)), take(1))
      .subscribe((woSurvey: IWorkOrderSurvey) => {
        purchaseOrderNumber = woSurvey ? woSurvey.identifierId : '';
        surveyId = woSurvey ? woSurvey.survey_surveyId : 0;
        this.requestData(purchaseOrderNumber, surveyId);
      })
    );

    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<any[]> {
    return this.selectionListStore.select(getSelectionListDataByType('surveyWorkOrder', -1))
      .pipe(
        filter((data: any[]) => data !== null),
        map((data: any[]) => { return data; }),
        first()
      );

  }

  requestData(purchaseOrderNumber: string, surveyId: number): void {
    //returns a custom list of work order info
    this.selectionListStore.dispatch(new GetEntityListByMethodParams(
      'WorkOrderSurvey',
      'WorkOrders',
      '?id=' + purchaseOrderNumber + '&surveyId=' + surveyId,
      this.filter,
      'surveyWorkOrder' ));
  }

}
