<div *ngIf="(isAnonymous$ | async)" id="signin-con">
  <div class="signin" [ngClass]="{'signin--with-errors': errors$ | async}">
    <h3 class="signin__header">Please Sign In</h3>
    <div class="signin__content" [ngClass]="{ 'loading-mask': loading$ | async }">
      <form (ngSubmit)="onSignin(f)" #f="ngForm" class="form signin__form">

        <div class="float-label">
          <input type="text" id="userName" name="userName" required ngModel
                 placeholder="Username"
                 autocomplete="off" />
          <label for="userName">Username</label>
        </div>

        <div class="float-label">
          <input type="password" id="password" name="password" required ngModel
                 placeholder="Password"
                 autocomplete="off" />
          <label for="password">Password</label>
        </div>

        <div class="app-btn-box">
          <button class="app-btn app-btn--primary" type="submit" [disabled]="!f.valid">Sign In</button>
        </div>
      </form>

      <div class="alert-box alert-box--danger" *ngIf="f.valid && (errors$ | async).length > 0">
        <div *ngFor="let error of errors$ | async ">
          {{ error.value }}
          <div class="app-btn-box app-btn-box--center" *ngIf="error.key === 'InstallerAccessDenied'">
            <a class="app-btn app-btn--primary" href="/InstallerPortal">Go To Installer Portal</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
