export interface ILookupValue {
  lookupValueId: number;
  lookupType_lookupTypeId: number;
  lookupCode: string;
  lookupMeaning: string;
}

export class LookupValue implements ILookupValue {
  lookupValueId: number;
  lookupType_lookupTypeId: number;
  lookupCode: string;
  lookupMeaning: string;

  constructor() {
    this.lookupValueId = 0;
    this.lookupType_lookupTypeId = 0;
    this.lookupCode = '';
    this.lookupMeaning = '';
  }

}
