<form *ngIf="form && dataLoaded()"
      [formGroup]="form" novalidate
      class="form-wrapper"
      [ngClass]="{'form-wrapper--edit': operation !== myConstants.operationTypeDetails }">
  <fw-dynamic-field *ngFor="let key of displayFields; let i = index"
                    [field]="mds.getFieldDefinition(key)"
                    [selectItems]="mds.getSelectItems(key)"
                    [form]="form"
                    [operation]="operation"
                    [submitted]="submitted"
                    [autoFocus]="i === 0 && operation !== myConstants.operationTypeDetails">
  </fw-dynamic-field>
</form>
<!--Drop another component here - see customer-survey -->
<ng-content></ng-content>

<fw-error-box [errorData]="errorData" [errors]="errorMessage" [ignoreKey]="false"></fw-error-box>
<fw-component-footer [lastUpdate]="vm && vm.hasOwnProperty('updateDate') ? vm.updateDate : '' "></fw-component-footer>

<div class="app-btn-box" *ngIf="form && dataLoaded() && vmFormDefinition">
  <button type="button"
          class="app-btn app-btn--cancel"
          (click)="onCancel()"
          [title]="operation === myConstants.operationTypeDetails ? 'Close' : 'Cancel'">
    {{ operation === myConstants.operationTypeDetails ? 'Close' : 'Cancel' }}
  </button>

  <ng-container *ngIf="(!vmFormDefinition.customButtons || !vmFormDefinition.customButtons.length)
                                    &&
                                    (operation === myConstants.operationTypeCreate
                                    || operation === myConstants.operationTypeEdit
                                    || operation === myConstants.operationTypeSingleEdit)">
    <button *ngIf="operation === myConstants.operationTypeEdit"
            type="button"
            class="app-btn app-btn--primary"
            title="Save and Hold"
            (click)="onSave('hold')" [disabled]="!form.dirty || !form.valid">
      Save
    </button>
    <ng-container *ngIf="includeNavButtons">
      <button *ngIf="operation === myConstants.operationTypeEdit  || operation === myConstants.operationTypeSingleEdit"
              type="button"
              class="app-btn app-btn--primary"
              [title]="operation === myConstants.operationTypeEdit ? 'Save & Close' : 'Save'"
              (click)="onSave('close')" [disabled]="!form.dirty || !form.valid">
        {{ operation === myConstants.operationTypeEdit ? 'Save & Close' : 'Save' }}
      </button>
      <button *ngIf="operation === myConstants.operationTypeEdit "
              type="button"
              class="app-btn app-btn--primary"
              title="Save and Next"
              (click)="onSave('next')" [disabled]="!form.dirty || !form.valid || !vmFormDefinition.showNext">
        Save & Next
      </button>
    </ng-container>
    <button *ngIf="operation === myConstants.operationTypeCreate"
            type="button"
            class="app-btn app-btn--primary"
            title="Create"
            (click)="onCreate()" [disabled]="!form.valid">
      Create
    </button>
  </ng-container>

  <ng-container *ngIf="vmFormDefinition.customButtons">
    <ng-container *ngFor="let btn of vmFormDefinition.customButtons; let i = index">
      <button type="button"
              class="app-btn {{btn.css}}"
              [title]="btn.title"
              (click)="onCustomBtn(btn)" [disabled]="!form.dirty || !form.valid">
        {{ btn.title }}
      </button>
    </ng-container>
  </ng-container>
</div>
