//TBD:  incomplete list of stores as added post implementation of installer
export enum InstallerStore {
  installerInformation = 'installerInformation',
  technicianInformation = 'technicianInformation',
  installerTechnicians = 'installerTechnicians',
  workCrewTechnicians = 'workCrewTechnicians',
  technicianRoles = 'technicianRoles'
}

export enum InstallerRole {
  installer = 'Installer',
  installerAdministrator = 'Installer Administrator'
}

export enum InstallerSelectList {
  installerRole = 'installerRole'
}

export enum InstallerEvent {
  reload = 'reload',
  edit = 'edit',
  cancel = 'cancel',
  save = 'save',
  create = 'create',
  dirty = 'dirty',
  updateComplete = 'updateComplete'
}
