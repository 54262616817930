<fw-dynamic-form-custom [data]="userPreferences"
                        [storeName]="storeName"
                        [formDefinition]="formDefinition"
                        [parentId]="userPriviledgesService.currentUserId$ | async"
                        [showEdit]="true"
                        [operation]="operation"
                        [displayFields]="displayFields"
                        (formEvent)="onCustom($event)">
</fw-dynamic-form-custom>
<div class="form-wrapper user-preferences">
  <label>CRM Version</label>
  {{ crmVersion$ | async }}
</div>
