/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input,  ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';

import * as fromRoot from '../../../../store/reducers/index';

import { getProviderId, canIManageInstallers } from '../../../../../auth/store/auth.selectors';
import { IInputButton } from '../../../../../fw/fw-shared/interfaces/i-input-button';

@Component({
    selector: 'installer-dashboard',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './installer-dashboard.component.html'
})
export class InstallerDashboardComponent implements OnInit, OnDestroy {
  @Input() data: any;

  public showInstallers: boolean = false;
  public showTechnicians: boolean = false;
  public includeOldestRequirement: boolean = false;
  public includeOldestRequirement$: BehaviorSubject<boolean>;
  public providerId$: Observable<number>;
  public canIManagerInstaller$: Observable<boolean>;
  public cbConfig: IInputButton;

  subscription: Subscription = new Subscription();
  providerId: number = -1;

  constructor(public readonly rootStore: Store<fromRoot.IState>) {
    this.includeOldestRequirement$ = new BehaviorSubject(false);
  }

  public toggleInstallers() {
    this.showInstallers = !this.showInstallers;
  }

  public toggleTechnicians() {
    this.showTechnicians = !this.showTechnicians;
  }

  public includeChange(event) {
    this.includeOldestRequirement$.next(event);
  }

  ngOnInit() {
    this.subscription.add(this.includeOldestRequirement$.subscribe());
    this.providerId$ = this.rootStore.pipe(select(getProviderId));
    this.canIManagerInstaller$ = this.rootStore.pipe(select(canIManageInstallers));
    this.cbConfig = {
      label: {
        label: 'Include Installers Oldest Requirement In Pull?',
        alignRight: false
      }
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
