import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition} from '../../../../../fw/dynamic-list/interfaces';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { AdminStore, AdminEvent } from '../../enums/admin.enums';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';

import { IPrivilegeRuleEntitySpecifier } from '../../../view-models';
import { AdminSecurityService } from '../../services/admin-security.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'privilege-filters',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './privilege-filters.component.html'
})
export class PrivilegeFiltersComponent implements OnInit {
  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public securityService: AdminSecurityService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.showFiltersWhereUsed:
        this.openFiltersWhereUsed(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.securityService.loadPrivilegeFiltersListDefinition();
  }

  openFiltersWhereUsed(specifier: IPrivilegeRuleEntitySpecifier): void {
    this.modalService.open({
      title: 'Where Used for Filter'.concat(' ', specifier.name),
      path: 'admin-pres-used/'.concat(specifier.privilegeRuleEntitySpecifierId.toString()),
      id: specifier.privilegeRuleEntitySpecifierId,
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      hasTabs: false
    });
  }

}
