<div [ngClass]="{'portal': !baseProjectId || baseProjectId <= 0 }">
  <div [ngClass]="{'portal__detail--item': !action}">
    <fw-component-title title='Project Notes'></fw-component-title>
    <notes-manager *ngIf="ready$ | async"
                   [listDefinition]="listDefinition"
                   [startAction]="action"
                   [canIEdit]="canIEditOut"
                   [portalId]="1"
                   [grandParentKey]="grandParentKey"
                   [grandParentId]="grandParentId"
                   [contactId]="contactId"
                   [hasExternalInterface]="hasExternalInterface">
    </notes-manager>
  </div>
</div>


