export interface IInstaller {
  installerId: number;
  installerName: string;
  contactName: string;
  businessType: string;
  categoryText: string;
  //contact_contactId: number;
  emailWorkPacket: boolean;
  wantScheduleDailySummary: boolean;
  address_addressId: number;
  email_emailId: number;
  phone_phoneId: number
  installerLocations: string[];
  installerServices: string[];
  entityLabel: string;
  minimumRequirementDate: string;
  numberWorkCrews: number;
  oldestRequirementLevel: string;
  oldestRequirementName: string;
  provider_providerId: number;
  stateIncorporated: string;
  state_stateId: number;
  taxIdNumber: string;
  warning: any;

  active: boolean;
  isActive: boolean;
  isPendingDelete: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  //extra data fields
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressCity: string;
  addressStateId: number;
  addressZipcode5: string;
  phoneNumber: string;
  emailAddress: string;
}

export class Installer implements IInstaller {
  installerId: number;
  installerName: string;
  contactName: string;
  businessType: string;
  categoryText: string;
  //contact_contactId: number;
  emailWorkPacket: boolean;
  wantScheduleDailySummary: boolean;
  address_addressId: number;
  email_emailId: number;
  phone_phoneId: number
  installerLocations: string[];
  installerServices: string[];
  entityLabel: string;
  minimumRequirementDate: string;
  numberWorkCrews: number;
  oldestRequirementLevel: string;
  oldestRequirementName: string;
  provider_providerId: number;
  stateIncorporated: string;
  state_stateId: number;
  taxIdNumber: string;
  warning: any;
  active: boolean;
  isActive: boolean;
  isPendingDelete: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  //extra data fields
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressCity: string;
  addressStateId: number;
  addressZipcode5: string;
  phoneNumber: string;
  emailAddress: string;

  constructor() {
    this.installerId = 0;
    this.installerName = '';
    this.businessType = null;
    this.categoryText = '';
    this.contactName = '';
    this.emailWorkPacket = false;
    this.wantScheduleDailySummary = false;
    this.installerLocations = [];
    this.installerServices=  [];
    this.entityLabel = '';
    this.minimumRequirementDate= '';
    this.numberWorkCrews = 0;
    this.oldestRequirementLevel= '';
    this.oldestRequirementName = '';
    this.provider_providerId = 0;
    this.stateIncorporated= null;
    this.state_stateId = null;
    this.address_addressId = null;
    this.phone_phoneId = null;
    this.email_emailId = null;
    this.taxIdNumber= '';
    this.warning = null;
    this.active = true;
    this.isPendingDelete = false;
    this.rowVersion = '';
    this.aggregateRootVersion = '';

  }

}
