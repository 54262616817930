import { Component, Input, OnInit } from '@angular/core';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { SchedulerService } from '../../services/scheduler.service';

@Component({
  selector: 'work-order-work-categories',
  templateUrl: './work-order-work-categories.component.html'
})

export class WorkOrderWorkCategoriesComponent implements OnInit {
  constructor(private scheduleService: SchedulerService) { }

  @Input() workOrderId: number;
  listDefinition: IListDefinition;

  ngOnInit(): void {
    this.listDefinition = this.scheduleService.loadWorkOrderWorkCategoriesListDefinition(this.workOrderId);
  }

}
