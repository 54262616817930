import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {  BehaviorSubject } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'project-purchase-order-item-summary',
  templateUrl: './project-purchase-order-item-summary.component.html'
})
export class ProjectPurchaseOrderItemSummaryComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  purchaseOrderId: number = 0;
  projectId: number = 0;

  constructor(
    public activeRoute: ActivatedRoute,
    public projectService: ProjectService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.purchaseOrderId = +paramMap.get('id');
      this.projectId = +paramMap.get('portalEntityId');
      this.setListDefinition();
    });
  }

  setListDefinition(): void {
    let listDefinition = this.projectService.loadPurchaseOrderItemListDefinition(this.purchaseOrderId, false, true);
    this.listDefinition$.next(listDefinition);
  }
}
