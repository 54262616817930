import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserSettingsTabs } from '../tab-settings/user-settings.tabs';
import { IPortalTab } from '../../../view-models';

@Component({
  selector: 'user-settings',
  templateUrl: './user-settings.component.html'
})
export class UserSettingsComponent implements OnInit {
  public authorizedTabs: IPortalTab[] = null;

  constructor(public activeRoute: ActivatedRoute,
    public router: Router  ) { }

  ngOnInit() {
    this.authorizedTabs = UserSettingsTabs;
  }
}
