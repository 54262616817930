
export interface ISlotScheduleCalendar {
  slotScheduleCalendarId: number;
  slotSchedule_slotScheduleId: number;
  day: string;
  totalPreferredSlots: number;
  totalSecondarySlots: number;
  isLocked: boolean;
  generalContractor_generalContractorId: number;

  //extradata
  providerLocationName: string
}


export class SlotScheduleCalendar implements ISlotScheduleCalendar {
  slotScheduleCalendarId: number;
  slotSchedule_slotScheduleId: number;
  day: string;
  totalPreferredSlots: number;
  totalSecondarySlots: number;
  isLocked: boolean;
  generalContractor_generalContractorId: number;
  providerLocationName: string

  constructor() {
    this.slotScheduleCalendarId = 0;
    this.slotSchedule_slotScheduleId = null;
    this.day = '';
    this.totalPreferredSlots = 0;
    this.totalSecondarySlots = 0;
    this.isLocked = true;
    this.generalContractor_generalContractorId = null;
    this.providerLocationName = '';
  }

}
