<div *ngIf="project$ | async" class="portal">
  <div class="portal__detail--item" *ngIf="isWarranty$ | async">
    <warranty-container [warrantyId]="warrantyId" [canIEdit]="canIEdit$ | async"></warranty-container>
  </div>

  <fw-collapsible-section [title]="'Project Required Documents'" (toggled)="onSectionToggle($event)">
    <project-required-documents app-section-detail
                                [projectId]="projectId"
                                [canIEdit]="canIEditSansStatus$ | async"
                                [isSummary]="false"
                                [detailActive]="(reqdDocsOpen$ | async)">
      <!--keep value for isSummary static-->
    </project-required-documents>
    <div *ngIf="!(reqdDocsOpen$ | async)" app-section-summary>
      <project-required-documents app-section-detail
                                  [projectId]="projectId"
                                  [canIEdit]="canIEditSansStatus$ | async"
                                  [isSummary]="true"
                                  [detailActive]="(reqdDocsOpen$ | async)">
      </project-required-documents>
    </div>
  </fw-collapsible-section>

  <div class="portal__detail--item" *ngIf="(hasUnreceivedInventory$ | async)">
    <fw-component-title title='Unreceived Inventory'></fw-component-title>
    <project-unreceived-inventory [projectId]="projectId"
                                  [canIEdit]="canIEdit$ | async">
    </project-unreceived-inventory>
  </div>
  <div class="portal__detail--item">
    <fw-component-title title='Project Purchase Order Summary'></fw-component-title>
    <project-purchase-orders [projectId]="projectId"
                             [canIEdit]="canIEdit$ | async"
                             [purchaseOrders]="pos$ | async"
                             [errorData]="acctErrors$ | async">
    </project-purchase-orders>
  </div>
  <div class="portal__detail--item">
    <fw-component-title title='Project Work Order Summary'></fw-component-title>
    <project-work-orders [projectId]="projectId"
                         [canIEdit]="canIEdit$ | async"
                         [workOrders]="wos$ | async"
                         [workOrderItems]="woItems$ | async">
    </project-work-orders>
  </div>
  <div class="portal__detail--item" *ngIf="isWarranty$ | async">
    <fw-component-title title='Project Charge Back Summary'></fw-component-title>
    <project-charge-backs [projectId]="projectId"
                          [chargeBacks]="cbs$ | async"
                          [canIEdit]="canIEditCbs$ | async">
    </project-charge-backs>
  </div>
</div>
