import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../../auth/services/auth-guard.service';
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';

import {
  ProgramServiceXmlMappingComponent,
  BranchXmlMappingComponent,
  SkuXmlMappingComponent,
  UnitMeasureXmlMappingComponent,  CustomerSurveyComponent
} from './components/index';

import {
  AdminDashboardComponent,
  ErroredLineItemsComponent,
  BranchXmlMappingsComponent,
  UnitMeasureXmlMappingsComponent,
  ProgramServiceXmlMappingsComponent,
  SkuXmlMappingsComponent,  CustomerSurveysComponent,
  CustomerSurveyImportComponent
} from './containers/index';
import {
  PoImportGroupedHeadersComponent,
  PoImportHeadersComponent,
  PoImportHeaderContainerComponent,
  PoImportLineContainerComponent
} from '../po-import/containers';

import {  EmptyComponent } from '../portal-shared/components/index';
import { SelectionListResolver, GeneralContractorsResolver } from '../resolvers';
import { PoImportHeaderResolver } from '../po-import/resolvers/po-import-header-resolver.service';
import { SkuTypeResolver } from './resolvers/sku-type-resolver.service';
import { SkuStatusResolver } from './resolvers/sku-status-resolver.service';
import { LoadingPageComponent } from '../../../shared/components';
import { SurveyResolver } from './resolvers/survey-resolver.service';
import { WorkOrderSurveyResolver } from './resolvers/work-order-survey-resolver.service';


const portalRoutes: Routes = [
  {
    path: 'admin-dash', //componentless route 
    children: [
      {
        path: ':portalId', //not really needed at this time, but keeping so tab factory can always call the same route structure
        component: AdminDashboardComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'admin-dash-empty', //verify path, result of tab component concatenation
            outlet: 'portal-detail',
            component: EmptyComponent  
          },
          {
            path: 'loading/:container',
            outlet: 'portal-detail',
            component: LoadingPageComponent
          },
          {
            path: 'program-service-xml-mappings',
            outlet: 'portal-detail',
            component: ProgramServiceXmlMappingsComponent,
            children: [
              {
                path: 'dash-program-service-mapping/:id',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
                outlet: 'dashProgramServiceMappingOutlet',
                component: ProgramServiceXmlMappingComponent
              }
            ]
          },
          {
            path: 'branch-xml-mappings',
            outlet: 'portal-detail',
            component: BranchXmlMappingsComponent,
            children: [
              {
                path: 'dash-branch-mapping/:id',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
                outlet: 'dashBranchMappingOutlet',
                component: BranchXmlMappingComponent
              }
            ]
          },
          {
            path: 'sku-xml-mappings',
            outlet: 'portal-detail',
            component: SkuXmlMappingsComponent,
            resolve: {
              typesLoaded: SkuTypeResolver,
              statusesLoaded: SkuStatusResolver,
            }, //special case to handle extra data fields for sku creation wizard
            children: [
              {
                path: 'dash-sku-mapping/:id',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
                outlet: 'dashSkuMappingOutlet',
                component: SkuXmlMappingComponent
              }
            ]
          },
          {
            path: 'unit-measure-xml-mappings',
            outlet: 'portal-detail',
            component: UnitMeasureXmlMappingsComponent,
            children: [
              {
                path: 'dash-unit-measure-mapping/:id',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
                outlet: 'dashUnitMeasureMappingOutlet',
                component: UnitMeasureXmlMappingComponent
              }
            ]
          },
          {
            path: 'errored-line-items',
            outlet: 'portal-detail',
            component: ErroredLineItemsComponent,
          },
          {
            path: 'po-import-errors/:id/:projectId/:errorsOnly',
            outlet: 'portal-detail',
            component: PoImportGroupedHeadersComponent,
            resolve: {
              gcs: GeneralContractorsResolver
            },
            canActivate: [AuthGuard],
                children: [
                 { //view all po-import-headers associated to this vendor Po + branch
                    path: 'po-import-headers/:id',
                    outlet: 'poImportHeadersOutlet',
                    data: {
                      storeName: 'poImportHeaders'
                    },
                    resolve: {
                      infoLoaded: PoImportHeaderResolver
                    },
                    component: PoImportHeadersComponent,
                    children: [
                      {
                        path: 'po-import-header/:id',
                        outlet: 'poImportHeaderOutlet',
                        component: PoImportHeaderContainerComponent,
                        children: [
                          {
                            path: 'po-import-line/:id',
                            outlet: 'poImportLineOutlet',
                            component: PoImportLineContainerComponent,
                            resolve: {
                              selectListsLoaded: SelectionListResolver
                            }
                          }
                        ]
                      },
                    ]
                  },
                ]
          },
          {
            path: 'customer-surveys',
            outlet: 'portal-detail',
            component: CustomerSurveysComponent,
            children: [
              {
                path: 'dash-wo-survey/:id',
                resolve: {
                  wosLoaded: WorkOrderSurveyResolver,
                  selectListsLoaded: SelectionListResolver
                },
                outlet: 'dashWOSurveyOutlet',
                component: CustomerSurveyComponent
              }
            ]
          }
       ]
      }
    ]
  },
  {
    path: 'customer-survey-import/:id',
    outlet: 'modal',
    component: CustomerSurveyImportComponent,
    resolve: {
      listLoaded: SurveyResolver
    }
  },

];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class AdminDashboardRoutingModule { }
