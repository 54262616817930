<div class="task-header">
  Project Synchronization<div class="task-header__status">Status</div>
</div>
<div class="sync-task {{ task.status === 'times-circle' ? 'dynamic-list__item--danger' : '' }}" *ngFor="let task of syncTasks">
  <div class="sync-task__indicator">
    <i class="fal fa-{{ !task.status ? 'spinner fa-spin fa-fw' : task.status }}"></i>
  </div>
  <div class="sync-task__name">{{ task.title }}</div>
  <div class="sync-task__status {{ task.status ? 'sync-task__status--done' : '' }}">{{ task.statusText }}</div>
</div>
