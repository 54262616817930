import { Component, OnInit, Input} from '@angular/core';
import { IScheduleInstallerWorkCrewUnavailabilityViewModel } from '../../../../app/portals/view-models';

@Component({
  selector: 'fw-unavailable-reasons',
  templateUrl: './unavailable-reasons.component.html'
})
export class UnavailableReasonsComponent implements OnInit {
  @Input() row: IScheduleInstallerWorkCrewUnavailabilityViewModel;

  public reasons: string[] = [];
  constructor() { }

  ngOnInit() {
    this.reasons = this.row.briefReasons.split(",");
  }

 }
