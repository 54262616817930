<form [formGroup]="filterForm$ | async" novalidate class="filter-form" *ngIf="filterForm$ | async">

  <fw-dynamic-filter-field *ngIf="(field$ | async) && (filterForm$ | async)"
                           [field]="field$ | async"
                           [filterForm]="filterForm$ | async"
                           [operation]="operation"
                           [searchTypeKey]="mySearchTypeKey"
                           [searchTypeConfig]="searchTypeConfig"
                           [disabled]="isDisabled">
  </fw-dynamic-filter-field>
  <span style="display: none;">
    <button type="submit" (click)="onAdd($event)" title="Add to Search List"></button>
  </span>
</form>
