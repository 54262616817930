export interface IInstallerWorkCrew {
  installerWorkCrewId: number;
  isActive: boolean;
  doesWarranty: boolean;
  installer_installerId: number;
  installerWorkCrewName: string;
  entityLabel: string;

  active: boolean;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;
  rowVersion: string;
}

export class InstallerWorkCrew implements IInstallerWorkCrew {
  installerWorkCrewId: number;
  isActive: boolean;
  doesWarranty: boolean;
  installer_installerId: number;
  installerWorkCrewName: string;
  entityLabel: string;

  active: boolean;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;
  rowVersion: string;

  constructor() {
        this.installerWorkCrewId = 0,
        this.isActive = false,
        this.doesWarranty = false,
        this.installer_installerId = 0,
        this.installerWorkCrewName = '',
        this.active = true,
        this.createDate = '',
        this.updateDate = '',
        this.createdBy = 0,
        this.updatedBy = 0,
        this.rowVersion = ''
    }
}
