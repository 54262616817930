import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IResponseBase, ResponseBase } from './interfaces/i-response-base';

const httpOptions = {
  headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
};

/**
 * Initial migration of HomDomainAccess to a shared library
 */
@Injectable()
export class HomDataAccessService {

  constructor(public http: HttpClient) { }

  baseUrl: string = '..';


  createByMethodWithForm(objectType: string, methodName: string, formData: FormData): Observable<IResponseBase> {
    return this.http.post(`${this.baseUrl}/${objectType}/${methodName}`, formData)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }

  handleError(error: any): Observable<IResponseBase> {
    let errMsg: string;

    const response = new ResponseBase();
    response.success = false;

    if (error instanceof HttpErrorResponse) {
      errMsg = error.message;
    } else {
      errMsg = 'Fatal error occurred during data access.';
    }
    let value: string[] = [];
    value.push(errMsg);
    response.errorData.push({ 'key': '__Model', 'value': value });
    return of(response);
  }

}

