import { Component, OnInit, Input, Output, Inject, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { cloneDeep } from 'lodash';

import { IUserPreference } from '../../../../../../app/portals/view-models/index'; 
import { FormDefinition, IFormDefinition } from '../../../../../../fw/dynamic-forms';
import { IAppConstants, appConstants } from '../../../../../../shared/constants/index';
import { ButtonType } from '../../../../../../fw/fw-shared/enums/button-type.enum';
import { getCrmVersion } from '../../../../../../auth/store/auth.selectors';
import * as fromRoot from '../../../../../../app/store/reducers/index';
import { UserPriviledgesService } from '../../../../../../auth/services';

@Component({
  selector: 'user-preferences',
  templateUrl: './user-preferences.component.html'
})
export class UserPreferencesComponent implements OnInit, OnChanges {
  @Input() userPreferences: IUserPreference;
 
  @Output() public saveEvent = new EventEmitter<IUserPreference>();

  public operation: string = '';
  public storeName: string = 'userPreferences';
  public displayFields: string[] = ['pageSize',  'startTab'];
  public formDefinition: IFormDefinition;
  public crmVersion$: Observable<string>;

  constructor(@Inject(appConstants) public myConstants: IAppConstants,
    public userPriviledgesService: UserPriviledgesService,
    public rootStore: Store<fromRoot.IState>) { }

  public onCustom(e): void {
    switch (e.event) {
      case this.myConstants.operationTypeEdit:
        this.operation = e.event;
        break;
      case ButtonType.cancel:
        this.operation = this.myConstants.operationTypeDetails;
        break;
      case ButtonType.save:
        this.onSave(e.data);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.operation = this.myConstants.operationTypeDetails;
    this.formDefinition = new FormDefinition('User Preferences', false, false, 'user-preferences');
    this.crmVersion$ = this.rootStore.pipe(select(getCrmVersion));
  }

  onSave(data: IUserPreference): void {
    let obj: IUserPreference = cloneDeep(this.userPreferences);
    obj.numFavorites = data.numFavorites
    obj.numRecents = data.numRecents;
    obj.pageSize = data.pageSize
    obj.startTab_portalTabId = data.hasOwnProperty('startTab')
      && data['startTab'].hasOwnProperty('portalTabId') ? data['startTab']['portalTabId'] : 0;
    this.saveEvent.emit(obj);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userPreferences'] && !changes['userPreferences'].isFirstChange()) {
      this.operation = this.myConstants.operationTypeDetails;
    }
  }

}
