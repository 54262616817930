import { createSelector} from '@ngrx/store';
  
import * as fromFeature from '../reducers/feature.reducer';
import * as fromDynamicList from '../reducers/dynamic-list.reducer';

//Gets all entity lists within dynamicLists
export const getAllDynamicLists = createSelector(
  fromFeature.getAllDynamicData,
  allStores  => allStores.dynamicLists
);

//Gets the requested entity lists
//  ex:  certifications or installercertifications
export const getListByType = (name: string) => createSelector(
  getAllDynamicLists,
  (allLists: fromDynamicList.IListsState) => allLists[name]
);

//Retrieves all the objData for an entitylist
//Use for globalList where there isn't a parent id - or can use getSelected
export const getEntityData = (name: string) => createSelector(
  getListByType(name),
  (entityList: fromDynamicList.IDynamicListState) => entityList.objData
);

//Use when want to get the list of work crews for a specific installer
//  Not applicable for global lists like certifications
//  Is applicable for installer_certifications
//name: name of the store ex: installerworkcrews
//parentId: id of the parent ex:  installerId of 387
//  use a parentId of -1 for global lists 
export const getEntityListByParentId = (name: string, parentId: number) => createSelector(
  getListByType(name),
  (entityLists: fromDynamicList.IDynamicListState)  =>  entityLists.objData.find(e => e.parentId == parentId)
); 

export const listDataExists = (name: string, parentId: number) => createSelector(
  getEntityListByParentId(name, parentId),
  (objData: fromDynamicList.IListObjectData) => {
    return objData && objData.data ? true : false;
  });

//Use when drilling down from a list to a detail record
//name: name of the store ex: certifications
//parentId: id of the parent - -1 for global, else applicable parent id like installerId
//key:  key to access the returned list by like certificationId or installerWorkCrewId
//objectId:  the id of the detail record
export const getSelectedRecord = (name: string, parentId: number, key: string, objectId: number) => createSelector(
  getEntityListByParentId(name, parentId),
  (entityList: fromDynamicList.IListObjectData) => {
    return entityList === undefined ? null : entityList.hasOwnProperty('data') && entityList.data && entityList.data.length > 0 ? entityList.data.find(x => x[key] == objectId) : null
  }  
);

//For list, errors are at the parent id since can only open one detail at a time --not triggering except with subscribe
export const getSelectedParentErrors  = (name: string, parentId: number) => createSelector(
  getEntityListByParentId(name, parentId),
  (entityList: fromDynamicList.IListObjectData) => {
    return entityList === undefined ? [] :  entityList.errorData
  } 
);


//For list, errors are at the parent id since can only open one detail at a time
export const getSelectedParentListDefinition  = (name: string, parentId: number) => createSelector(
  getEntityListByParentId(name, parentId),
  (entityList: fromDynamicList.IListObjectData) =>  {
    return entityList === undefined ? null :  entityList.listDefinition
} 
);

//Leaving these here, but updates to the store for these fields are not triggering changes in the components.
//Entity list is working
export const isSelectedParentWorking = (name: string, parentId: number) => createSelector(
  getEntityListByParentId(name, parentId),
  (entityList: fromDynamicList.IListObjectData) => {
    return entityList === undefined ? null :  entityList.working
  } 
);


//Entity list has stale Data
export const isSelectedParentStale = (name: string, parentId: number) => createSelector(
  getEntityListByParentId(name, parentId),
  (entityList: fromDynamicList.IListObjectData) => {
    return entityList === undefined ? false :  entityList.staleData
  } 
);


//Entity list has an event
export const getSelectedParentEvent = (name: string, parentId: number) => createSelector(
  getEntityListByParentId(name, parentId),
  (entityList: fromDynamicList.IListObjectData) => {
    return entityList === undefined ? null :  entityList.event
  } 
);

