export interface ISku {
  skuId: number;
  generalContractor_generalContractorId: number;
  skuName: string;
  skuDescription: string;
  skuStatus_lookupValueId: number;
  skuType: string; 
  allowNegativePrice: boolean;
  unitMeasure_unitMeasureId: number;
  accountingAccountCode: string;
  serviceCodeOverride: string;

}

export class Sku implements ISku {
  skuId: number;
  generalContractor_generalContractorId: number;
  skuName: string;
  skuDescription: string;
  skuStatus_lookupValueId: number;
  skuType: string; 
  allowNegativePrice: boolean;
  unitMeasure_unitMeasureId: number;
  accountingAccountCode: string;
  serviceCodeOverride: string;


  constructor(generalContractorId: number, skuName: string = '', defaultStatusId: number = -1) {
    this.skuId = 0;
    this.generalContractor_generalContractorId = generalContractorId;
    this.skuName = skuName;
    this.skuDescription = skuName;
    this.skuStatus_lookupValueId = defaultStatusId;
    this.skuType = '';
    this.allowNegativePrice = false;
    this.unitMeasure_unitMeasureId = 0;
    this.accountingAccountCode = '';
    this.serviceCodeOverride = '';
  }
}


