import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { cloneDeep } from 'lodash';
import { IProviderLocationSchedule } from '../../../view-models/index_two';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'provider-location-schedule',
  templateUrl: './provider-location-schedule.component.html'
})
export class ProviderLocationScheduleComponent implements OnInit  {
  @Input() data: IProviderLocationSchedule;
  @Output() public changeEvent = new EventEmitter<IProviderLocationSchedule>();
  @Output() public errorEvent = new EventEmitter<IProviderLocationSchedule>();

  public isEnabled: boolean = false;
  public invalidTime$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  newSchedule: IProviderLocationSchedule;

  constructor(public datePipe: DatePipe ) {}

  ngOnInit() {
    this.isEnabled = this.data.open;
    this.newSchedule = cloneDeep(this.data);
  }

  public onOpen(checked: boolean): void {
    this.isEnabled = checked;
    this.newSchedule.open = checked;
    this.newSchedule.startTime = '';
    this.newSchedule.endTime = '';
    this.changeEvent.emit(this.newSchedule);
  }

  public onStartTimeChange(value: string): void {
    if (value) {
      this.newSchedule.startTime = this.resetDatePart(value);
      this.newSchedule.endTime = this.resetDatePart(this.newSchedule.endTime);
      this.emitEvent();
    }
  }

  public onEndTimeChange(value: string): void {
    if (value) {
      this.newSchedule.endTime = this.resetDatePart(value);
      this.newSchedule.startTime = this.resetDatePart(this.newSchedule.startTime);
      this.emitEvent();
    }
  }

  //add the date part back on with current date
  resetDatePart(timeVal: string): string {
    const yyyy = new Date().getFullYear();
    const mm = new Date().getMonth() + 1;
    const dd = new Date().getDate();

    const justTime: string = timeVal.length && timeVal.length > 5
      ? this.datePipe.transform(timeVal, 'H:mm')
      : timeVal;

    return this.datePipe.transform(mm.toString().concat('/', dd.toString(), '/', yyyy.toString(), ' ', justTime), 'MM/dd/yyyy H:mm').toString();
  }

  emitEvent(): void {
    this.validateTimes();
    if (this.invalidTime$.value) {
      this.errorEvent.emit(this.newSchedule);
    }
    else {
      this.changeEvent.emit(this.newSchedule);
    }
  }

  validateTimes(): void {
    if (new Date(this.newSchedule.startTime).getTime() >= new Date(this.newSchedule.endTime).getTime()) {
      this.invalidTime$.next(true);
    } else {
      this.invalidTime$.next(false);
    }
  }

}
