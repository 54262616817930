export enum WorkOrderStatus {
  toBeSchedule = 'To Be Scheduled',
  scheduled = 'Scheduled',
  dispatched = 'Dispatched',
  noGoods = 'No Goods',
  workComplete = 'Work Complete'
}

export enum ScheduleEvent {
  cancelEdit = 'cancelEdit',
  cancelMoveUp = 'cancelMoveUp',
  createSchedule = 'createSchedule',
  createSuccessful = 'createSuccessful',
  editSchedule = 'editSchedule ',
  deleteSchedule = 'deleteSchedule',
  deleteSuccessful = 'deleteSuccessful',
  getAvailableSlots = 'getAvailableSlots',
  getAvailableCrews = 'getAvailableCrews',
  getUnavailableCrews = 'getUnavailableCrews',
  installerSort = 'installerSort',
  moveUpUpdateSuccessful = 'moveUpUpdateSuccessful',
  requestMoveup = 'requestMoveup',
  resetAvailability = 'resetAvailability',
  saveSchedule = 'saveSchedule',
  selectWorkCrew = 'selectWorkCrew',
  showMoveUp = 'showMoveUp',
  saveMoveUp = 'saveMoveUp',
  setStartDate = 'setStartDate',
  setTimespan = 'setTimespan',
  updateSuccessful = 'updateSuccessful',
  viewPoMeasures = 'viewPoMeasures',
  unlock = 'unlock',
  setAvailabilityForDay = 'setAvailabilityForDay',
  setAvailabilityForTimespan = 'setAvailabilityForTimespan'
}

export enum ScheduleAvailability {
  available = 'available',
  unavailable = 'unavailable',
  warning = 'warning'
}

export enum AvailabilityRank {
  available = 0,
  warning = 1,
  unavailable = 2
}

export enum DurationType {
  days = 'Days',
  hours = 'Hours',
  minutes = 'Minutes'
}

export enum ScheduleStore {
  schedules = 'schedules',
  workCategories = 'workCategories',
  providerLocationScheduleMoveups = 'providerLocationScheduleMoveups',
  scheduleMeasures = 'scheduleMeasures',
  unavailableWorkCrews = 'unavailableWorkCrews',
  slotScheduleCalendarLockedDays = 'slotScheduleCalendarLockedDays'
}

export enum UnavailableType {
  allInstallersTaken = 'allInstallersTaken',
  scheduleDayLocked = 'scheduleDayLocked',
  spanNonWorkingDay = 'spanNonWorkingDay',
  none = ''
}

export enum UnavailabilityRank {
  allGood = 0,
  spanNonWorkingDay = 1,
  scheduleDayLocked = 2,
  allInstallersTaken = 3
}

export enum AvailabilityPullRange {
  max = 7,
  min = 1
}

export enum DurationMin {
  days = 1,
  hours = 1,
  minutes = 15
}

export enum DurationMax {
  days = 7,
  hours = 23,
  minutes = 45
}

