import { IEntityDocumentActionViewModel } from './i-entity-document-action-view-model';
import { IEntityDocumentPropertyViewModel } from './i-entity-document-property-view-model';

export interface IEntityDocument {
  entityDocumentId: number;
  documentType_documentTypeId: number;
  entityPkid: number;
  parentEntity_entityId: number;
  parentEntityPkid: number;
  includeInWorkOrderPacket: boolean;

  //extra data field
  documentTypeName: string;
  isRequired: boolean;
  entityIdLabel: string;
  entityLabel: string;
  entityName: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  fileUrl: string;
  imageUrl: string;
  uploadDate: string;
  latestFileUploadId: number; 
  latestVersionId: number;
  latestVersionNumber: number;
  customerName: string;
  metaData: any;

  actions: IEntityDocumentActionViewModel[];
  properties: IEntityDocumentPropertyViewModel[];
}

export class EntityDocument implements IEntityDocument {
  entityDocumentId: any;
  documentType_documentTypeId: number;
  entityPkid: number;
  parentEntity_entityId: number;
  parentEntityPkid: number;
  includeInWorkOrderPacket: boolean;

  //extra data field
  documentTypeName: string;
  isRequired: boolean;
  entityIdLabel: string;
  entityLabel: string;
  entityName: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  fileUrl: string;
  imageUrl: string;
  uploadDate: string;
  latestFileUploadId: number;
  latestVersionId: number;
  latestVersionNumber: number;
  customerName: string;
  metaData: any;
  actions: IEntityDocumentActionViewModel[];
  properties: IEntityDocumentPropertyViewModel[];

  constructor() {
    this.entityDocumentId = 0;
    this.documentType_documentTypeId = null;
    this.entityPkid = 0;
    this.includeInWorkOrderPacket = false;
    this.parentEntity_entityId = null;
    this.parentEntityPkid = 0;
  }

}

