import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { take  } from 'rxjs/operators';
import { Subscription, BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, ListDefinition, IListColumn, ListColumn, IListFilter, ListFilter, IListButtonType } from '../../../../../../fw/dynamic-list/interfaces/index';
import { ICustomButton } from '../../../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../../../fw/fw-shared/enums/button-type.enum';

//store actions and reducers
import * as fromRoot from '../../../../../store/reducers/index';
import * as fromAuth from '../../../../../../auth/store/auth.reducer';
import { IJobScheduleJob } from '../../../../view-models';
import { ModalSizeType } from '../../../../../../fw/fw-modal/interfaces/i-modal';
import { ModalService } from '../../../../../../fw/fw-modal/services/fw-modal.service';
import { DynamicListService } from '../../../../../../fw/dynamic-list/services';

@Component({
  selector: 'user-jobs-jobs',
  templateUrl: './user-jobs.component.html'
})
export class UserJobsJobsComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  authState: fromAuth.IState;
  subscription: Subscription = new Subscription();
  showParameters: string = 'showParameters';

  constructor(public rootStore: Store<fromRoot.IState>,
    public modalService: ModalService,
    public dynamicListService: DynamicListService ) { }

  ngOnInit() {
    this.subscription.add(this.rootStore.select('auth')
      .pipe(take(1))
      .subscribe((authState: fromAuth.IState) => {
        this.authState = authState;
        this.loadListDefinition();
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case this.showParameters:
        this.showJobParameters(event.data);
        break;
      default:
        break;
    }
  }

  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Jobs';
    const listObjectController = 'JobScheduleJob';
    const listStoreName = 'userJobs';
    const detailRoutePath = 'user-job';
    const listRowKeyId = 'jobScheduleJobId';
    const defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition('commonDetailOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.methodParameters = this.authState.providerUser.currentUserId.toString();
    listDefinition.showFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0, 
      {
        title: 'Job Schedule Parameters',
        icon: 'far fa-list-ol',
        cssName: 'app-btn-icon--custom-1',
        enabled: true,
        eventName: this.showParameters
      }
    );

    this.listDefinition$.next( listDefinition );
  }

  /*
      Define the columns in the list to be displayed.  Columns pulled back are set by the db.
      May change this to be defined in the db.
  */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('job');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('jobScheduleJobName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('jobSchedule');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('numberSubscribers');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('haveAlert');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  showJobParameters(data: IJobScheduleJob): void {
    this.modalService.open({
      title: 'Parameters for '.concat(data.jobScheduleJobName),
      path: 'job-schedule-job-parameters/' + data.jobScheduleJobId.toString(),
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.small,
      isSubModal: true
    });

  }

}
