import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IProviderRole } from '../../../view-models';

@Component({
  selector: 'installer-work-crew-technician-role',
  templateUrl: './installer-work-crew-technician-role.component.html'
})
export class InstallerWorkCrewTechnicianRoleComponent  {
  @Input() installerWorkCrewTechnicianId: number;
  @Input() installerRoles: IProviderRole[];
  @Input() operation: string;

  @Output() public createEvent = new EventEmitter<IProviderRole>();
  @Output() public cancelEvent = new EventEmitter();
  selectedRole: IProviderRole;

  constructor() {}

  public selectRole(providerId: number): void {
    this.selectedRole = this.installerRoles.find(x => x.providerRoleId == providerId);
  }

  public onCancel(): void {
    this.cancelEvent.emit();
  }

  public onCreate(): void {
    if (this.selectedRole) {
      this.createEvent.emit(this.selectedRole);
    }
  }

}
