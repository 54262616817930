import { Component, OnInit, OnDestroy, ElementRef, QueryList, ViewChildren, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { sum, cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomErrorHandlerService, HomErrorLevel } from 'hom-lib/hom-error-logger';

import { IListDefinition, IListFilter, IDetailRouteParameter, ListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { IShipperPurchaseOrder, IInventoryRollupViewModel, IInventoryOrderRequest, IWorkOrderItemShipperProviderLocation, IInventoryOrder, IInventoryOrderViewModel } from '../../../view-models';
import { IListMetaData, IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/index';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';

//store actions, reducers, interfaces
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DomainObjectService } from '../../../../../shared/services';
import { ManagerDashboardService } from '../../services/manager-dashboard.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'inventory-order-request',
  templateUrl: './inventory-order-request.component.html'
})
export class InventoryOrderRequestComponent implements OnInit, OnDestroy {
  @ViewChildren('orderAmount', { read: ElementRef }) public orderAmounts: QueryList<ElementRef>;

  public title: string = '';
  public workingInventoryOrders: IInventoryOrder[] = [];
  public canOrderMoreThanRecommendedInventory: boolean = false;
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public objectData$: Observable<IShipperPurchaseOrder>
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  inData: IInventoryOrderRequest;
  selectedRecords: IInventoryRollupViewModel[] = [];
  listMetaData: IListMetaData;
  newShipperPurchaseOrderId: number;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public managerDashboardService: ManagerDashboardService,
    public store: Store<fromStore.IAllDynamicData>,
    public domainObjectService: DomainObjectService,
    public userPriviledgesService: UserPriviledgesService,
    public datePipe: DatePipe,
    public modalService: ModalService,
    public emitterService: HomEventEmitterService,
    public errorHandlerService: HomErrorHandlerService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public submitOrder(): void {
    this.working$.next(true);
    let newOrder: IInventoryOrderViewModel[] = [];
    if (this.orderAmounts && this.orderAmounts.length) {
      const items = this.orderAmounts.toArray();
      this.workingInventoryOrders.forEach((row, index) => {
        if (index <= items.length - 1) {
          const item: HTMLInputElement = items[index].nativeElement;
          const amount: number = +item.value;

          if (amount > 0) {
            newOrder.push({
              materialName: row.workOrderItemName,
              payoutId: row.payoutId,
              orderAmountEstimate: row.orderAmountEstimate,
              actualOrderAmount: amount,
              adjustedOrderAmount: 0,
              runningTotal: 0
            });
          }
        }
      });
    };

    if (newOrder.length) {
      this.subscription.add(this.domainObjectService.updateByMethod("InventoryRollupViewModel", 'CreateOrder',
        {
          providerLocationShipperInventorySetupId: this.inData.providerLocationShipperInventorySetupId,
          shipDate: this.inData.orderDate,
          rangeStartDate: this.inData.searchStartDate,
          rangeEndDate: this.inData.searchEndDate,
          materials: newOrder
        })
        .subscribe((response: IResponseBase) => {
          if (response.success) {
            //display result
            this.handleOrderSuccess(response.data.shipperPurchaseOrderId)
          } else {
            this.errorData$.next(response.errorData);
            this.working$.next(false);
            console.log('DEV ERROR: Unable to retrieve WorkOrderItemShipperProviderLocations for ProviderLocationShipperInventorySetupId: ', this.inData.providerLocationShipperInventorySetupId.toString());
          }
        }));
    }
  }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.inData = window.history.state;
      this.title = 'Order Form for Inventory Delivery on '.concat(this.datePipe.transform(this.inData.orderDate, 'MM/dd/yyyy'));
      this.canOrderMoreThanRecommendedInventory = this.userPriviledgesService.canOrderMoreThanRecommendedInventory$.getValue();
      this.getRecords();
      this.getSupplemental();
    });
  }

  getRecords(): void {
    const def = this.managerDashboardService.loadDashInventoryNeedsListDefinition(this.inData.searchStartDate, this.inData.searchEndDate, this.inData.providerLocationShipperInventorySetupId);

    this.subscription.add(this.store.pipe(
      select(getListByType(def.storeName)), take(1))
      .subscribe((entityList: fromStore.IDynamicListState) => {
        this.selectedRecords = entityList.objData[0].data.filter(x => this.inData.selectedOrderIds.includes(x[def.rowKeyId]));
        this.listMetaData = entityList.objData[0].listMetaData;
      }));
  }

  getSupplemental(): void {
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.isLookup = true;

    this.subscription.add(this.domainObjectService.getListByMethod('WorkOrderItemShipperProviderLocation',
      'ByProviderLocationShipperInventorySetup',
      this.inData.providerLocationShipperInventorySetupId.toString(),
      listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.loadData(response.data);
        } else {
          this.errorData$.next(response.errorData);
          console.log('DEV ERROR: Unable to retrieve WorkOrderItemShipperProviderLocations for ProviderLocationShipperInventorySetupId: ', this.inData.providerLocationShipperInventorySetupId.toString());
        }
      }));
  }

  loadData(workOrderItemShipperProviderLocations: IWorkOrderItemShipperProviderLocation[]): void {
    if (!this.inData) {
      return;
    }
    const uniqueItems: string[] = [...new Set(this.selectedRecords.map(x => x.workOrderItemName))];

    uniqueItems.forEach(item => {
      const amount: number = sum(this.selectedRecords.filter(x => x.workOrderItemName == item).map(y => y.orderAmount));
      const inventoryRollUp: IInventoryRollupViewModel = this.selectedRecords.find(x => x.workOrderItemName == item);
      const workOrderItemShipperProviderLocation: IWorkOrderItemShipperProviderLocation =
        workOrderItemShipperProviderLocations.find(x => x.workOrderItemShipperProviderLocationId == inventoryRollUp.workOrderItemShipperProviderLocationId);
      let actualOrderAmount = workOrderItemShipperProviderLocation
        ? amount - workOrderItemShipperProviderLocation.unallocated + workOrderItemShipperProviderLocation.buffer
        : amount;

     if (!workOrderItemShipperProviderLocation) {
        this.errorHandlerService.handleError({
          name: 'DataError',
          message: 'Inventory Order Request and missing wo item shipper location for item: ' + item + ' for inventoryRollUp wo item shipper location id:' + inventoryRollUp.workOrderItemShipperProviderLocationId.toString()
          },
          HomErrorLevel.info
        );
      }
      const inventoryOrder: IInventoryOrder = {
        workOrderItemName: item,
        payoutId: inventoryRollUp.payoutId,
        orderAmountEstimate: amount,
        orderAmount: actualOrderAmount < 0 ? 0 : actualOrderAmount,
        buffer: workOrderItemShipperProviderLocation.buffer,
        unallocated: workOrderItemShipperProviderLocation.unallocated,
        workOrderItemShipperProviderLocationId:  inventoryRollUp.workOrderItemShipperProviderLocationId
      }
      this.workingInventoryOrders.push(inventoryOrder);
    });
  }



  handleOrderSuccess(shipperPurchaseOrderId: number): void {
    let emitter: IHomEventEmitter = { requestor: 'inventory-order-request', event: this.myConstants.emitterEventSuccessfulOrder, action: '', data: null };
    this.emitterService.emitInventoryOrderEvent(emitter);

    const orderDef: IListDefinition = this.managerDashboardService.loadDashInventoryOrdersListDefinition();
    this.store.dispatch(new fromStore.SetListDefinition({ storeName: orderDef.storeName, parentId: orderDef.parentId, listDefinition: orderDef }));
    this.store.dispatch(new fromStore.GetList({ listDefinition: orderDef, listFilter: orderDef.defaultListFilter, parentId: orderDef.parentId }));

    this.subscription.add(this.store.pipe(select(fromStore.getListByType(orderDef.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == orderDef.parentId)))
      .subscribe((state: IListObjectData) => {
        const objData = cloneDeep(state);
        if (objData && objData.data && objData.data.length) {
          this.goToNewOrder(shipperPurchaseOrderId);
        }
      }));
  }

  goToNewOrder(shipperPurchaseOrderId: number): void {
    const def: IListDefinition = this.managerDashboardService.loadDashInventoryOrdersListDefinition();
    const stateData = { standAlone: true };

    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: def.storeName,
      key: def.rowKeyId,
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: -1
    }

    this.working$.next(false);
    this.modalService.open({
      title: 'Your order was successfully submitted. ',
      path: 'mgr-dash-inventory-request-result',
      id: shipperPurchaseOrderId,
      sizeType: ModalSizeType.small,
      castExit: false,
      optionalParams: params,
      hasTabs: false,
      showLoading: true,
      state: stateData
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
