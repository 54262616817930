/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, ListFilter, IListFilter, IDetailRouteParameter, IListButtonType, OrderTerm} from '../../../../../fw/dynamic-list/interfaces/index';
import { ICertification } from '../../../view-models';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'certification',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './certification.component.html'
})
export class CertificationComponent implements OnInit {
    @Input() data: any;   

    public listDefinition: IListDefinition;
    showPlsEvent: string = 'showPlsEvent';

  constructor(public activeRoute: ActivatedRoute,
    public modalService: ModalService,
    public dynamicListService: DynamicListService,
    public userPriviledgesService: UserPriviledgesService) { }

    ngOnInit() {
        this.loadListDefinition();
    }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case this.showPlsEvent:
        this.showPlsList(event.data);
        break;
      default:
        break;
    }
  }

  /*
        Define the list to be created
    */
    loadListDefinition() {

      const listColumns = this.loadListColumns();
      const listObjectLabel = 'Certification';
      const listObjectController = 'Certification';
      const listStoreName = 'certifications';   
	    const detailRoutePath = 'certification-detail';
      const listRowKeyId = 'certificationId';
      let defaultListFilter: IListFilter = new ListFilter();
      defaultListFilter.orderTerm = [new OrderTerm('certificationName')];

      let listDefinition = this.dynamicListService.createListDefinition('certificationOutlet',
          listObjectLabel,
          listObjectController,
          listStoreName,
          listRowKeyId,
          defaultListFilter,
          listColumns,
          detailRoutePath);

      if (this.userPriviledgesService.canManageInstallers$.value) {
        const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
        listDefinition.toolButtons.splice(0, 0, newTools[0]);

        let crudButtons: IListButtonType[] = [
          { type: ButtonType.detail, defaults: null },
          { type: ButtonType.edit, defaults: null },
          { type: ButtonType.delete, defaults: null }
        ];
        listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
      }
      listDefinition.rowButtons.splice(0, 0,
        {
          title: 'Requiring Locations',
          icon: 'far fa-globe-americas',
          cssName: 'app-btn-icon--custom-1',
          enabled: true,
          eventName: this.showPlsEvent
        }
      );


      this.listDefinition = listDefinition; //set at end to signify ready to instantiate the list.
    }

    /*
        Define the columns in the list to be displayed.  Columns pulled back are set by the db.
        May change this to be defined in the db.
    */
    loadListColumns(): IListColumn[] {
        let columnDefinitions: Array<IListColumn> = [];

        let colDef = new ListColumn('certificationName');
        columnDefinitions.push(colDef);

        colDef = new ListColumn('isRequired');
        colDef.visibleOnSmall = false;
        columnDefinitions.push(colDef);

        colDef = new ListColumn('certificationDescription');
        colDef.visibleOnSmall = false;
        colDef.visibleOnMedium = false;
        columnDefinitions.push(colDef);

        colDef = new ListColumn('certificationType');
        colDef.visibleOnSmall = false;
        columnDefinitions.push(colDef);

        colDef = new ListColumn('certificationLevel');
        colDef.visibleOnSmall = false;
        columnDefinitions.push(colDef);

        colDef = new ListColumn('validatesParent');
        colDef.visibleOnSmall = false;
        colDef.visibleOnMedium = false;
        columnDefinitions.push(colDef);

        colDef = new ListColumn('global');
        colDef.visibleOnSmall = false;
        columnDefinitions.push(colDef);

        return columnDefinitions;
    }

  showPlsList(data: ICertification): void {
    this.modalService.open({
      title: 'Requiring Locations for '.concat(data.certificationName),
      path: 'pls-certification-list/' + data.certificationId.toString(),
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.medium
    });

  }

}
