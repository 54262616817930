import { Component, Input, Output, OnInit, OnDestroy, ChangeDetectionStrategy, Inject, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription, of, Observable, BehaviorSubject} from 'rxjs';
import { take, filter, map } from 'rxjs/operators';
import { cloneDeep, sortBy } from 'lodash';
import { HomErrorHandlerService, HomErrorLevel } from 'hom-lib/hom-error-logger';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';

import {
  Contact, ContactMechanismPhone, IContactTypeSpecification,
  ContactMechanismAddress, ContactMechanismEmail,
  IContact, IContactMechanismPhone,
  IContactMechanismAddress, IContactMechanismEmail,
  IContactInfoViewModel, IPostContactMechanismAddress,
  IPostContactMechanismEmail, IPostContactMechanismPhone,
  ISeedContactInformation, ISeedContactAddress,
  ISeedContactPhone, ISeedContactEmail
} from '../../../portals/view-models/index';
import { UpdateObjectByIdModel, DeleteObjectModel, IKey, CreateObjectModel } from '../../../../fw/dynamic-list/store/interfaces/index';
import { IErrorData, IResponseBase } from '../../../../shared/interfaces/index';
import { IMultiTierOuput } from '../../../../fw/fw-menus/interfaces/i-multi-tier-output';
import { IMultiTierMenu } from '../../../../fw/fw-menus/interfaces/i-multi-tier-menu';
import { ContactEventAction, ContactEvent, ContactStore } from '../../enums/contact.enums';
import { IListFilter, ListFilter } from '../../../../fw/dynamic-list/interfaces/index';

//store actions and reducers
import * as fromRoot from '../../../../app/store/reducers/index';
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as fromSelectionLists from '../../../../shared/store/selectionLists/index';
import { listDataExists,  getEntityListByParentId  } from '../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as DynamicObjectActions from '../../../../fw/dynamic-list/store/actions/dynamic-object.actions';
import {  getObjectDataById, getObjectDataByType } from '../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { IDynamicObject } from '../../../../fw/dynamic-list/store/reducers/dynamic-object.reducer';
import { IListObjectData } from '../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { IObjectData } from '../../../../fw/dynamic-list/store/interfaces/index';
import { IDeleteContactMechanism } from '../../../portals/portal-shared/interfaces';
import { IAddressZips, IAddressLines } from '../../interfaces';
import { ContactManagerService, ContactConstantsService, ContactUtilityService } from '../../services/index';
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { DomainObjectService, HomCommonUtility } from '../../../../shared/services';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

interface IAddData {
  mechanism: string;
  type: string;
}

@Component({
  selector: 'contact-manager',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './contact-manager.component.html',
  providers: [ContactManagerService]
})
//Wrapper Requirements:
export class ContactManagerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges  {
  @Input() contactId: number; 
  @Input() isOrganization: boolean;//needed for create
  @Input() contactTypeId: number;//needed for create
  @Input() operationIn: string;
  @Input() displayType: string;
  @Input() canIEdit: boolean;
  @Input() checkForMatch: boolean = false;
  @Input() seedData: ISeedContactInformation = null;
  @Input() allowText: boolean = true;
  @Input() isPoImport: boolean = false;
  @Input() defaults: ISeedContactInformation = null;

  @Output() public managerEvent = new EventEmitter<IHomEventEmitter>();

  subscription: Subscription = new Subscription();
  contactSub: Subscription;
  contactEventSub: Subscription;
  addrSub: Subscription;
  emailSub: Subscription;
  phoneSub: Subscription;
  typeSub: Subscription;

  public loading$: Observable<boolean>;
  public contact$: BehaviorSubject<IContact> = new BehaviorSubject(null);
  public phones$: BehaviorSubject<ContactMechanismPhone[]> = new BehaviorSubject([]);
  public emails$: BehaviorSubject<ContactMechanismEmail[]> = new BehaviorSubject([]);
  public addresses$: BehaviorSubject<ContactMechanismAddress[]> = new BehaviorSubject([]);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public phoneDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public suffixRequested$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public middleRequested$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public searchData$: BehaviorSubject<IContactInfoViewModel> = new BehaviorSubject(null);
  public requestTime$: BehaviorSubject<string> = new BehaviorSubject('');
  public isFormValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public addTitle: string = '+ Add More';
  public addItems: IMultiTierMenu[] = [];
  public forceReadOnly: boolean = false;
  public operation: string;

  mechName: string = 'Name';
  typeSuffix: string = 'Suffix';
  typeMiddle: string = 'Middle Name';
  key: string = 'contactId';

  typeSpecifications: IContactTypeSpecification[];
  headerFormInited: boolean = false;
  phoneFormInited: boolean = false;
  emailFormInited: boolean = false;
  addressFormInited: boolean = false;
  viewInited: boolean = false;
  contactMatchAction: string = ''
  defaultListFilter: IListFilter = new ListFilter();
  error: string;

  constructor(public  activeRoute: ActivatedRoute,
    public  rootStore: Store<fromRoot.IState>,
    public  store: Store<fromStore.IAllDynamicData>,
    public  selStore: Store<fromSelectionLists.IStoreState>,
    public  emitterService: HomEventEmitterService,
    public  contactService: ContactManagerService,
    public contactConstantsService: ContactConstantsService,
    public contactUtilityService: ContactUtilityService,
    public modalService: ModalService,
    public domainObjectService: DomainObjectService,
    public homUtils: HomCommonUtility,
    public dynamicListService: DynamicListService,
    public errorHandlerService: HomErrorHandlerService,
    public changeDetectorRef: ChangeDetectorRef,
    @Inject(appConstants) public  myConstants: IAppConstants) {
      //Listen for contact events
      this.subscription.add(this.emitterService.contactEventEmitted$
        .subscribe(
          (e: IHomEventEmitter) => {
            switch (e.event) {
              case ContactEvent.deleteContactPreference:
                this.deleteContactPreference();
                break;
              case ContactEvent.uploadContactFile:
                if (e.data.success) {
                  this.onReloadRequest();
                }
                break;
              case ContactEvent.isContactValid:
                this.contactService.contactForm.updateValueAndValidity();
                this.setFormValidity(this.contactService.contactForm.status);
                this.managerEvent.emit(e);
                break;
             default:
                break;
            }
          }));
    }

  /*PUBLIC METHODS*/
  public formIsValid(): boolean {
    return this.contactService.contactForm && this.contactService.contactForm.dirty && this.contactService.contactForm.valid;
  }


  public onDelete(event: IHomEventEmitter): void {
    this.deleteContactMechanism(event);
  }

  //leave in for debugging
  public tempLog() {
    console.log('contactForm:', this.contactService.contactForm);
  }

  public onReloadRequest(): void {
    this.contactUtilityService.dispatchGetContact(this.contactId);
    this.dispatchListGets();
  }

  public onCancel(): void {
    if (this.modalService.opened && (this.contactId === 0 || this.isPoImport)) {
      this.modalService.close();
      return;
    }

    //Display Type Default contains Save and Cancel buttons
    //The Other Display Types would contain a control like fw-title-bar-button that contains their buttons
    //Those other display types do not need to consume cancelEvent.
    if (this.displayType !== this.contactConstantsService.displayTypeDefault) {
      this.headerFormInited = this.phoneFormInited = this.emailFormInited = this.addressFormInited = false;
      this.phones$.next([]);
      this.emails$.next([]);
      this.addresses$.next([]);
      this.contact$.next(null);
      this.changeDetectorRef.detectChanges();

      const keys: string[] = Object.keys(this.contactService.contactForm.controls);
      keys.forEach(key => {
        this.contactService.contactForm.removeControl(key);
      });
      this.contactService.contactForm.updateValueAndValidity();
      
      this.onReloadRequest();
    } else {
      let event: IHomEventEmitter = {
        requestor: 'contact-manager',
        event: ContactEvent.cancel,
        action: '',
        data: null
      };
      this.managerEvent.emit(event);
    }
    this.store.dispatch(new DynamicObjectActions.ClearErrorsObj({ storeName: ContactStore.contactInformation, objectId: this.contactId }));
 }


  public onSave(): void {
    let model: IContactInfoViewModel = this.getContactModel();

    const keyData: IKey = { storeName: ContactStore.contactInformation, parentId: this.contactId, key: this.key, id: this.contactId }
    const emitter: IHomEventEmitter = { requestor: 'contact-manager', event: this.myConstants.emitterEventDoUpdateById, action: '', data: null };
    const updateData = new UpdateObjectByIdModel(keyData, 'Contact', 'Update', this.key, this.contactId, model, null, emitter);
    this.store.dispatch(new DynamicObjectActions.UpdateObjectByIdObj({ updateData }));
  }

  public onCreate(): void {
    let model: IContactInfoViewModel = this.getContactModel();
    const emitter: IHomEventEmitter = { requestor: 'contact-manager', event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(ContactStore.contactInformation, -1, 'Contact', 'Create', model, null, emitter);
    this.store.dispatch(new DynamicObjectActions.CreateObjectObj({ createData }));
  }

  public getContactModel(): IContactInfoViewModel {
    let contact: IContact = this.setContactProperties();
    contact.createDate = new Date().toDateString();

    let phones: IPostContactMechanismPhone[] = this.setPhoneProperties();
    let emails: IPostContactMechanismEmail[] = this.setEmailProperties();
    let addresses: IPostContactMechanismAddress[] = this.setAddressProperties();

    let viewModel: IContactInfoViewModel = {
      contact: contact,
      contactMechanismAddresses: addresses,
      contactMechanismEmails: emails,
      contactMechanismPhones: phones
    };

    return viewModel;
  }

  public onAddItem(e: IMultiTierOuput) {
    const data: IAddData = e.item;
    //const data:
    switch (data.mechanism) {
      case this.contactConstantsService.contactMechanismCategoryAddress:
        this.addAddress(data.mechanism, data.type);
        break;
      case this.contactConstantsService.contactMechanismCategoryEmail:
        this.addEmail(data.mechanism, data.type);
        break;
      case this.contactConstantsService.contactMechanismCategoryPhone:
        this.addPhone(data.mechanism, data.type);
        break;
      case this.mechName:
        const field: string = data.type === this.typeSuffix ? 'nameSuffix' : data.type === this.typeMiddle ? 'middleName' : '';
        if (field) {
          this.enableFormField(field);
        }
        break;
      default:
        break;
    }

    if (data.mechanism === this.mechName) {
      this.disableAddItem(data.mechanism, data.type);
    } else if (this.hasHitLimit(data.mechanism, data.type)) {
      this.disableAddItem(data.mechanism, data.type);
    }
  }

  public onMatchEvent(event: IHomEventEmitter): void {
    switch (event.event) {
      case ContactEvent.selectMatch:
        this.handleMatch(event);
        break;
      case ContactEvent.refreshMatchData:
        this.setSearchData();
        break;
      default:
        break;
    }
  }

/*End Public Methods*/

  ngOnInit(): void {
    //subscribe to the active route so can watch for changes to the active route
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.operation = paramMap.has('operation')
        ? paramMap.get('operation')
        : this.operationIn
          ? this.operationIn
          : this.contactId > 0
            ? this.myConstants.operationTypeDetails
            : this.myConstants.operationTypeCreate;

      if (this.operation === this.myConstants.operationTypeCreate) {
        this.forceReadOnly = this.isPoImport ? this.seedData === null ? false : true : false;
        this.requestTime$.next(paramMap.has('requestTime') ? paramMap.get('requestTime') : Date.now().toString());
        this.newRequest();
      }
    });

    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(filter(x => (x.requestor === ContactStore.contactInformation || x.requestor === ContactStore.phones || x.requestor === ContactStore.emails || x.requestor === ContactStore.addresses)),
        map(x => x.show));

    this.addresses$.subscribe((val) => {
      if (this.viewInited && !this.contactMatchAction) {
        this.setSearchData();
      }
    });

    this.emails$.subscribe((val) => {
      if (this.viewInited && !this.contactMatchAction) {
        this.setSearchData();
      }
    });

    this.phones$.subscribe((val) => {
      if (this.viewInited && !this.contactMatchAction) {
        this.setSearchData();
      }
    });

    this.subscription.add(this.contactService.contactForm.statusChanges.subscribe(
      result => {
        this.setFormValidity(result);
       }
    ));
  }

  ngAfterViewInit(): void {
    if (this.contactId === 0) {
      if (this.isPoImport && this.seedData !== null) {
        this.contactService.contactForm.markAllAsTouched();
        this.contactService.contactForm.markAsDirty();
      }
    }
    this.setSearchData();
    this.viewInited = true;

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['operationIn']) {
      this.operation = changes['operationIn'].currentValue;
    }

    if (changes['checkForMatch'] && !changes['checkForMatch'].isFirstChange()) {
      if (!this.isPoImport && this.checkForMatch) {
        this.requestTime$.next(Date.now().toString());
        this.setSearchData();
      }
      this.forceReadOnly = false;
    }

    if ((changes['contactId'])
      || (changes['seedData'] && !changes['seedData'].isFirstChange())) {
      this.requestTime$.next(Date.now().toString());
      this.newRequest(changes['seedData'] && changes['seedData'].currentValue ? true : false);
    }
  }

  setFormValidity(result: string): void {
    //if in details mode, form is not "valid"
    let isValid: boolean = false;
    if (this.operation !== this.myConstants.operationTypeDetails) {
      isValid = result === this.contactConstantsService.formStatusValid && this.contactService.contactForm.dirty;
    }
    this.isFormValid$.next(isValid);
  }

  newRequest(useSeedData: boolean = false) {
    this.phones$.next(null);
    this.emails$.next(null);
    this.addresses$.next(null);
    this.contactService.contactForm = new FormGroup({});

    //portal display is skinnier
    if (!this.displayType) {
      this.displayType = this.contactConstantsService.displayTypeDefault;
    }

    if (this.operation === this.myConstants.operationTypeCreate) {
      const newContact = new Contact(this.contactTypeId, this.contactService.contactType(this.contactTypeId), this.isOrganization, this.defaults);
      this.contact$.next(this.seedData ? this.loadContactInfoFromSeedData(newContact) : newContact);
    } else {
      //get data from store
      if (this.contactSub) {
        this.contactSub.unsubscribe();
      }
      this.contactSub = this.store.select(getObjectDataById(ContactStore.contactInformation, this.contactId))
        .subscribe(state => {
          this.contact$.next(cloneDeep(state));
        });
    }

    if (this.typeSub) {
      this.typeSub.unsubscribe();
    }
    this.typeSub = this.selStore.pipe(
      select(fromSelectionLists.getSelectionListDataByType('contactTypeSpecification', this.contactTypeId)),
      take(1))
      .subscribe(data => {
        this.typeSpecifications = data;
      });

    if (this.contactEventSub) {
      this.contactEventSub.unsubscribe();
    }
    this.contactEventSub = this.store.pipe(select(getObjectDataByType(ContactStore.contactInformation)))
      .pipe(map((state: IDynamicObject) => state.objData.find(x => this.operation === this.myConstants.operationTypeCreate ? x.objectId === -1 : x.objectId == this.contactId)))
      .subscribe((state: IObjectData) => {
        if (state) {
          if (state.errorData && state.errorData.length) {
            this.errors$.next(state.errorData[0].value[0]);
          } else {
            if (this.errors$.value) this.errors$.next(null);
          }
        }
        if (state && (state.objectId === this.contactId || (this.operation === this.myConstants.operationTypeCreate && state.objectId === -1))) {
          const storeData = cloneDeep(state);
          if (storeData.event && (!storeData.errorData || !storeData.errorData.length)) {
            //successful update
            this.store.dispatch(new fromStore.ClearEventObj({ storeName: ContactStore.contactInformation, objectId: this.operation === this.myConstants.operationTypeCreate ? -1 : this.contactId }));

            if (this.operation === this.myConstants.operationTypeEdit) {
              this.contactService.contactForm.updateValueAndValidity();
              this.onReloadRequest();
            }

            if (this.operation === this.myConstants.operationTypeCreate) {
              const newContactId: number = storeData.data['contactId'];
              let event: IHomEventEmitter = {
                requestor: 'contact-manager',
                event: ContactEvent.createWithContact,
                action: '',
                data: newContactId
              };
              this.managerEvent.emit(event);

            } else {
              let event: IHomEventEmitter = {
                requestor: 'contact-manager',
                event: ContactEvent.updated,
                action: '',
                data: null
              };
              this.managerEvent.emit(event);
            }
          }
        }
      });

    if (this.operation === this.myConstants.operationTypeCreate) {
      this.phones$.next(this.loadRequiredPhones());
      this.emails$.next(this.loadRequiredEmails());
      this.addresses$.next(this.loadRequiredAddresses());
      this.loadAddItems();
    } else {
      this.initForExisting(useSeedData);
    }

  }

  loadContactInfoFromSeedData(newContact: IContact): IContact {
    let contact: IContact = cloneDeep(newContact);
    contact.contactId = this.seedData.contactId;
    contact.firstName = this.seedData.firstName;
    contact.middleName = this.seedData.middleName;
    contact.lastName = this.seedData.lastName;
    contact.birthDate = this.seedData.birthDate;
    contact.optOutSms = this.seedData.optOutSms;
    contact.sssOptOut = this.seedData.sssOptOut;
    return contact;
  }

  initForExisting(useSeedData: boolean = false) {
    this.dispatchDataRequests();

    if (this.phoneSub) {
      this.phoneSub.unsubscribe();
    }
    this.phoneSub = this.store.select(getEntityListByParentId(ContactStore.phones, this.contactId))
      .subscribe((state: IListObjectData) => {
        const objData = state;
        if (objData && objData.data && objData.parentId === this.contactId) {
          const data = cloneDeep(objData.data);
          let uncommitted: ContactMechanismPhone[] = [];
          if (!objData.data.length || (objData.event && objData.event.event == this.myConstants.emitterEventDelete)) {
            //don't lose the uncommitted ones
            uncommitted = this.getUncommittedPhones(data);
          }
          this.initPhoneContactMechanism();
          let phones = this.loadDataToFormGroup(this.contactConstantsService.contactMechanismCategoryPhone, this.contactConstantsService.phoneFormPrefix, 'phoneType', data);
          //add in the non-committed emails
          uncommitted.forEach(x => phones.push(x));
          if (useSeedData && this.seedData && this.seedData.contactMechanismPhones) {
            //need to add xtra seed data (po import only data) to existing contacts phones type
            phones = this.addPhonesFromSeedData(phones, true);
          }
          this.phones$.next(phones);
          this.loadAddItems(this.contactConstantsService.contactMechanismCategoryPhone);
          if (uncommitted.length > 0) {
            this.homUtils.markGroupDirty(this.contactService.contactForm.controls['headerForm'] as FormGroup);
          }
        }
      });

    if (this.emailSub) {
      this.emailSub.unsubscribe();
    }
    this.emailSub = this.store.select(getEntityListByParentId(ContactStore.emails, this.contactId))
      .subscribe((state: IListObjectData) => {
        const objData = state;
        if (objData && objData.data && objData.parentId === this.contactId) {
         const data = cloneDeep(objData.data);
          let uncommitted: ContactMechanismEmail[] = [];
          if (!objData.data.length || (objData.event && objData.event.event == this.myConstants.emitterEventDelete)) {
              //don't lose the uncommitted ones
              uncommitted = this.getUncommittedEmails(data);
          }
          this.initEmailContactMechanism();
          let emails = this.loadDataToFormGroup(this.contactConstantsService.contactMechanismCategoryEmail, this.contactConstantsService.emailFormPrefix, 'emailType', data);
          //add in the non-committed emails
          uncommitted.forEach(e => emails.push(e));
          if (useSeedData && this.seedData && this.seedData.contactMechanismEmails) {
            //need to add xtra seed data (po import only data) to existing contact emails
            emails = this.addEmailsFromSeedData(emails, true);
          }
          this.emails$.next(emails);
          this.loadAddItems(this.contactConstantsService.contactMechanismCategoryEmail);
          if (uncommitted.length > 0) {
            this.homUtils.markGroupDirty(this.contactService.contactForm.controls['headerForm'] as FormGroup);
          }
       }
      });

    if (this.addrSub) {
      this.addrSub.unsubscribe();
    }
    let initing: boolean = true;
    this.addrSub = this.store.select(getEntityListByParentId(ContactStore.addresses, this.contactId))
      .subscribe((state: IListObjectData) => {
        const objData = state;
        if (objData && objData.data && objData.parentId === this.contactId) {
         const data = cloneDeep(objData.data);
          let uncommitted: ContactMechanismAddress[] = [];
          if (!objData.data.length || (objData.event && objData.event.event == this.myConstants.emitterEventDelete)) {
              //don't lose the uncommitted ones
              uncommitted = this.getUncommittedAddresses(data);
          }
          this.initAddressContactMechanism();
          let addrs = this.loadDataToFormGroup(this.contactConstantsService.contactMechanismCategoryAddress, this.contactConstantsService.addressFormPrefix, 'addressType', data);
          uncommitted.forEach(a => addrs.push(a));
          if (useSeedData && this.seedData && this.seedData.contactMechanismAddresses) {
            //need to add xtra seed data (po import only data) to existing contact addresses
            addrs = this.addAddressesFromSeedData(addrs, true);
          }
          this.addresses$.next(addrs);
          this.loadAddItems(this.contactConstantsService.contactMechanismCategoryAddress);
          if (uncommitted.length > 0) {
            this.homUtils.markGroupDirty(this.contactService.contactForm.controls['headerForm'] as FormGroup);
          } else if (!initing && this.operation !== this.myConstants.operationTypeDetails) {
            this.emitMergedAddressesValid();
          }
          initing = false;
        }
      });
  }


  getUncommittedPhones(committed: IContactMechanismPhone[]): ContactMechanismPhone[] {
    const existing = cloneDeep(this.phones$.getValue());
    let uncommitted = existing
      ? existing.filter(x => x.contactMechanismPhoneId == 0 && !committed.find(y => y.phoneNumber == x.phoneNumber))
      : [];
    if (uncommitted.length > 0) {
      uncommitted.forEach(item => {
        const form: FormGroup = this.contactService.contactForm.controls[item.formGroupName] as FormGroup;
        if (form) {
          const formValue: IContactMechanismPhone = form.getRawValue();
          item.phone.phoneNumber = item.phoneNumber = formValue.phoneNumber;
          item.phoneExtension = formValue.phoneExtension;
          item.phoneTypeId = formValue.phoneTypeId;
          item.phoneType = formValue.phoneType;
          item.isPreferred = formValue.isPreferred;
        }
      });
    }
    return uncommitted;
  }

  getUncommittedEmails(committed: IContactMechanismEmail[]): ContactMechanismEmail[] {
    const existing = cloneDeep(this.emails$.getValue());
    let uncommitted = existing
      ? existing.filter(x => x.contactMechanismEmailId == 0 && !committed.find(y => y.emailAddress == x.emailAddress))
      : [];
    if (uncommitted.length > 0) {
      uncommitted.forEach(item => {
        const form: FormGroup = this.contactService.contactForm.controls[item.formGroupName] as FormGroup;
        if (form) {
          const formValue: IContactMechanismEmail = form.getRawValue();
          item.email.emailAddress = item.emailAddress = formValue.emailAddress;
          item.emailTypeId = formValue.emailTypeId;
          item.emailType = formValue.emailType;
          item.isPreferred = formValue.isPreferred;
        }
      });
    }
    return uncommitted;
  }

  getUncommittedAddresses(committed: IContactMechanismAddress[]): ContactMechanismAddress[] {
    const existing = cloneDeep(this.addresses$.getValue());
    let uncommitted = existing
      ? existing.filter(x => x.contactMechanismAddressId == 0
        && !committed.find(y => y.line1 == x.line1 && y.city == x.city))
      : [];
    if (uncommitted.length > 0) {
      uncommitted.forEach(item => {
        const form: FormGroup = this.contactService.contactForm.controls[item.formGroupName] as FormGroup;
        if (form) {
          const formValue: IContactMechanismAddress = form.getRawValue();
          item.address.line1 = item.line1 = formValue.line1;
          item.address.city = item.city = formValue.city;
          item.addressState = formValue.addressState;
          item.addressStateId = this.contactUtilityService.getStateId(formValue['addressState']);
          item.address.zipcode5 = item.zipcode5 = formValue.zipcode5;
          item.address.zipcode4 = item.zipcode4 = formValue.zipcode4;
          item.addressTypeId = formValue.addressTypeId;
          item.addressType = formValue.addressType;
          item.isPrimary = formValue.isPrimary;
          item.yearBuilt = formValue.yearBuilt;
       }
      });
    }
    return uncommitted;
  }

  dispatchListGets() {
    //have to get updated addresses, phones, emails after an update because of how we get data in contact controller
    this.contactUtilityService.dispatchEntityData(this.contactConstantsService.contactMechanismCategoryPhone, this.contactId);
    this.contactUtilityService.dispatchEntityData(this.contactConstantsService.contactMechanismCategoryEmail, this.contactId);
    this.contactUtilityService.dispatchEntityData(this.contactConstantsService.contactMechanismCategoryAddress, this.contactId);
  }


  loadDataToFormGroup(type: string, prefix: string, typeProp: string, data: any[]): any[] {
    let dataOut = cloneDeep(data);
    const typeSpecs: IContactTypeSpecification[] = this.typeSpecifications.filter(x => x.contactType_contactTypeId === this.contactTypeId && x.contactMechanismCategoryName === type);
    const minType: { type: string, min: number, count: number }[] = [];
    typeSpecs.forEach(spec => {
      if (spec.minimum !== null && spec.minimum > 0) {
        minType.push({ type: spec.contactMechanismCategoryTypeName, min: spec.minimum, count: 0 });
      }
    });

    dataOut = this.sortData(dataOut, type, typeProp);

    //add in custom mgt properties to each row
    let i = 0;
    dataOut.forEach(row => {
      row.uiLabel = row['entityLabel'];
      row.formGroupName = prefix.concat(i.toString());
      let idx = minType.findIndex(x => x.type === row[typeProp]);
      if (idx > -1 && minType[idx].count < minType[idx].min) {
          row.uiRequired = true;
          minType[idx].count++;
        }
        i++;
    });

    return dataOut;
  }

  //Sort data by preferred/primary first, then alpha by type
  sortData(data: any[], type: string,  typeProp: string): any[] {
    let dataOut = cloneDeep(data);
    const sortCol: string = type === this.contactConstantsService.contactMechanismCategoryAddress ? 'isPrimary' : 'isPreferred';
    dataOut.sort((a, b) => {
      const aPrimary: number = a[sortCol] ? 1 : 0;
      const bPrimary: number = b[sortCol] ? 1 : 0;
      if (aPrimary < bPrimary) {
        return 1;
      }
      else if (aPrimary > bPrimary) {
        return -1;
      }
      else {
        if (a[typeProp] < b[typeProp]) {
          return -1;
        }
        else if (a[typeProp] > b[typeProp]) {
          return 1;
        }
        else {
          return 0;
        }
      }
    });
    return dataOut;
  }

  displayHeader(): boolean {
    return this.contactTypeId === this.contactConstantsService.contactTypeIdBranch || this.contactTypeId === this.contactConstantsService.contactTypeIdProviderLocation ? false : true;
  }

  //for create - load array with the phone type(s) required
  //Example: if one phone of type business is required, insert that into the phones objects
  loadRequiredPhones(): ContactMechanismPhone[] {
    let phones: ContactMechanismPhone[] = [];
    const typeSpecs = this.typeSpecifications.filter(x => x.contactType_contactTypeId === this.contactTypeId
      && x.contactMechanismCategoryName === this.contactConstantsService.contactMechanismCategoryPhone);
    let i = 1;
    typeSpecs.forEach(spec => {
      if (spec.minimum !== null && spec.minimum > 0) {
        do {
          let isPreferred = this.operation === this.myConstants.operationTypeCreate && i === 1;
          phones.push(new ContactMechanismPhone(
            this.contactConstantsService.contactMechanismCategoryPhone,
            spec.contactMechanismCategoryTypeName,
            spec.contactMechanismCategoryType_contactMechanismCategoryTypeId,
            spec.entityLabel,
            this.contactConstantsService.phoneFormPrefix.concat((i - 1).toString()),
            true,
            isPreferred
          ));
          i++ // updating control variable
        } while (i < spec.minimum)
      }
    });

    //if have seed data for po import, it needs to take precedence over required items.
    if (this.seedData && this.seedData.contactMechanismPhones) {
      phones = this.addPhonesFromSeedData(phones);
    }
    return phones;
  }


  //for create - load array with the phone type(s) required
  loadRequiredEmails(): ContactMechanismEmail[] {
    let emails: ContactMechanismEmail[] = [];
    const typeSpecs = this.typeSpecifications.filter(x => x.contactType_contactTypeId === this.contactTypeId
      && x.contactMechanismCategoryName === this.contactConstantsService.contactMechanismCategoryEmail);
    let i = 1;
    typeSpecs.forEach(spec => {
      if (spec.minimum !== null && spec.minimum > 0) {
        do {
          let isPreferred = this.operation === this.myConstants.operationTypeCreate && i === 1;
          emails.push(new ContactMechanismEmail(
            this.contactConstantsService.contactMechanismCategoryEmail,
            spec.contactMechanismCategoryTypeName,
            spec.contactMechanismCategoryType_contactMechanismCategoryTypeId,
            spec.entityLabel,
            this.contactConstantsService.emailFormPrefix.concat((i - 1).toString()),
            true,
            isPreferred
          ));
          i++ // updating control variable
        } while (i < spec.minimum)
      }
    });

    //if have seed data for po import, it needs to take precedence over required items.
    if (this.seedData && this.seedData.contactMechanismEmails) {
      emails = this.addEmailsFromSeedData(emails);
    }

    return emails;
  }

  //for create - load array with the phone type(s) required
  loadRequiredAddresses(): ContactMechanismAddress[] {
    let addrs: ContactMechanismAddress[] = [];
    const typeSpecs = this.typeSpecifications.filter(x => x.contactType_contactTypeId === this.contactTypeId
      && x.contactMechanismCategoryName === this.contactConstantsService.contactMechanismCategoryAddress);
    let i = 1;
    
    typeSpecs.forEach(spec => {
      if (spec.minimum !== null && spec.minimum > 0) {
        do {
          let isPreferred = this.operation === this.myConstants.operationTypeCreate && i === 1;
          addrs.push(new ContactMechanismAddress(
            this.contactConstantsService.contactMechanismCategoryAddress,
            spec.contactMechanismCategoryTypeName,
            spec.contactMechanismCategoryType_contactMechanismCategoryTypeId,
            spec.entityLabel,
            this.contactConstantsService.addressFormPrefix.concat((i - 1).toString()),
            true,
            isPreferred
          ));
          i++ // updating control variable
        } while (i < spec.minimum)
      }
    });
    //if have seed data for po import, it needs to take precedence over required items.
    if (this.seedData && this.seedData.contactMechanismAddresses) {
      addrs = this.addAddressesFromSeedData(addrs);
    }

    return addrs;
  }

  addPhonesFromSeedData(phones: IContactMechanismPhone[], isMerge: boolean = false): IContactMechanismPhone[] {
    const anyCommitted: boolean = phones.find(p => p.contactMechanismPhoneId > 0) ? true : false;
    let phonesOut: IContactMechanismPhone[] = anyCommitted ? cloneDeep(phones) : [];
    const typeSpecs = this.typeSpecifications.filter(x => x.contactType_contactTypeId === this.contactTypeId
      && x.contactMechanismCategoryName === this.contactConstantsService.contactMechanismCategoryPhone);
    const hasPreferred: boolean = phonesOut.find(p => p.isPreferred === true) ? true : false;
    let i: number = phonesOut.length;

    if (!this.seedData.contactMechanismPhones) return phonesOut;

    this.seedData.contactMechanismPhones.forEach(x => {
      //add in check for duplicate here, if found skip
      if (isMerge && this.isPhoneDuplicate(phonesOut, x)) {
        return;
      }
      const typeCount: number = phonesOut.filter(p => p.phoneTypeId === x.phoneTypeId).length;
      const typeSpec = typeSpecs.find(ts => ts.contactMechanismCategoryType_contactMechanismCategoryTypeId === x.phoneTypeId);
      let availSpec = typeSpecs.find(ts => ts.maximum == null || ts.maximum > typeCount);
      if (availSpec == null) {
        const message: string = 'Merging did not import phone due to all type maximums have been reached: ' + x.phoneTypeId.toString() + ' for phone:' + x.phone.phoneNumber
        this.errorHandlerService.handleError({ name: 'DataError', message: message }, HomErrorLevel.fatal);
        this.errors$.next(message);
        return;
      }
      const useTypeSpec = typeSpec ? (typeSpec.maximum == null || typeCount < typeSpec.maximum) : false;
      let phone = new ContactMechanismPhone(
        this.contactConstantsService.contactMechanismCategoryPhone,
        useTypeSpec ? typeSpec.contactMechanismCategoryTypeName : availSpec.contactMechanismCategoryNameTypeName,
        useTypeSpec ? x.phoneTypeId : availSpec.contactMechanismCategoryType_contactMechanismCategoryTypeId,
        useTypeSpec ? typeSpec.entityLabel : availSpec.entityLabel,
        this.contactConstantsService.phoneFormPrefix.concat((i).toString()), //formgroup must be unique
        false,
        hasPreferred ? false : x.isPreferred,
        x.phoneExtension
      );
      phone.phoneId = x.phone.phoneId;
      phone.phoneNumber = x.phone.phoneNumber;
      phone.phoneTypeId = x.phoneTypeId;
      phone.phoneExtension = x.phoneExtension;
      phone.canSms = x.canSms;
      phonesOut.push(phone);
      i++;

    });

    return phonesOut;
  }

  addEmailsFromSeedData(emails: IContactMechanismEmail[], isMerge: boolean = false): IContactMechanismEmail[] {
    const anyCommitted: boolean = emails.find(e => e.contactMechanismEmailId > 0) ? true : false;
    let emailsOut: IContactMechanismEmail[] = anyCommitted ? cloneDeep(emails) : [];
    const typeSpecs = this.typeSpecifications.filter(x => x.contactType_contactTypeId === this.contactTypeId
      && x.contactMechanismCategoryName === this.contactConstantsService.contactMechanismCategoryEmail);
    const hasPreferred: boolean = emailsOut.find(e => e.isPreferred === true) ? true : false;
    let i: number = emailsOut.length;

    if (!this.seedData.contactMechanismEmails) return emailsOut;

    this.seedData.contactMechanismEmails.forEach(x => {
      if (isMerge && this.isEmailDuplicate(emailsOut, x)) {
        return;
      }
      const typeCount: number = emailsOut.filter(e => e.emailTypeId === x.emailTypeId).length;
      const typeSpec = typeSpecs.find(ts => ts.contactMechanismCategoryType_contactMechanismCategoryTypeId === x.emailTypeId);
      let availSpec = typeSpecs.find(ts => ts.maximum == null || ts.maximum > typeCount);
      if (availSpec == null) {
        const message: string = 'Merging did not import email failed due to all type maximums have been reached: ' + x.emailTypeId.toString() + ' for email:' + x.email.emailAddress
        this.errorHandlerService.handleError({ name: 'DataError', message: message }, HomErrorLevel.fatal);
        this.errors$.next(message);
        return;
      }

      const useTypeSpec = typeSpec ? (typeSpec.maximum == null || typeCount < typeSpec.maximum) : false;
      let email = new ContactMechanismEmail(
        this.contactConstantsService.contactMechanismCategoryEmail,
        useTypeSpec ? typeSpec.contactMechanismCategoryTypeName : availSpec.contactMechanismCategoryNameTypeName,
        useTypeSpec ? x.emailTypeId : availSpec.contactMechanismCategoryType_contactMechanismCategoryTypeId,
        useTypeSpec ? typeSpec.entityLabel : availSpec.entityLabel,
        this.contactConstantsService.emailFormPrefix.concat((i).toString()),
        false,
        hasPreferred ? false : x.isPreferred,
      );
      email.emailId = x.email.emailId;
      email.emailAddress = x.email.emailAddress;
      email.emailTypeId = x.emailTypeId;
      emailsOut.push(email);
      i++;
    });

    return emailsOut;
  }

  addAddressesFromSeedData(addresses: IContactMechanismAddress[], isMerge: boolean = false): IContactMechanismAddress[] {
    const anyCommitted: boolean = addresses.find(a => a.contactMechanismAddressId > 0) ? true : false;
    let addrsOut: IContactMechanismAddress[] = anyCommitted ? cloneDeep(addresses) : [];
    let i: number = addrsOut.length;
    const hasPrimary: boolean = addrsOut.find(a => a.isPrimary === true) ? true : false;
    const typeSpecs = this.typeSpecifications.filter(x => x.contactType_contactTypeId === this.contactTypeId
      && x.contactMechanismCategoryName === this.contactConstantsService.contactMechanismCategoryAddress);

    if (this.seedData.contactMechanismAddresses) {
      this.seedData.contactMechanismAddresses.forEach(x => {
        if (isMerge && this.isAddressDuplicate(addrsOut, x)) {
          let match = this.isAddressDuplicate(addrsOut, x);
          match.yearBuilt = x.address.yearBuilt <= match.yearBuilt ? x.address.yearBuilt : match.yearBuilt;
          return;
        }
        const typeCount: number = addrsOut.filter(addr => addr.addressTypeId === x.addressTypeId).length;
        const typeSpec = typeSpecs.find(ts => ts.contactMechanismCategoryType_contactMechanismCategoryTypeId === x.addressTypeId);
        let availSpec = typeSpecs.find(ts => ts.maximum == null || ts.maximum > typeCount);
        if (availSpec == null) {
          const message: string = 'Merging did not import address due to all type maximums have been reached: ' + x.addressTypeId.toString() + ' for line 1:' + x.address.line1
          this.errorHandlerService.handleError({ name: 'DataError', message: message }, HomErrorLevel.fatal);
          this.errors$.next(message);
          return;
        }
        const useTypeSpec = typeSpec ? (typeSpec.maximum == null || typeCount < typeSpec.maximum) : false;
        let address = new ContactMechanismAddress(
          this.contactConstantsService.contactMechanismCategoryAddress,
          useTypeSpec ? typeSpec.contactMechanismCategoryTypeName : availSpec.contactMechanismCategoryNameTypeName,
          useTypeSpec ? x.addressTypeId : availSpec.contactMechanismCategoryType_contactMechanismCategoryTypeId,
          useTypeSpec ? typeSpec.entityLabel : availSpec.entityLabel,
          this.contactConstantsService.addressFormPrefix.concat((i).toString()),
          false,
          hasPrimary ? false : x.isPrimary
        );
        address.addressId = x.address.addressId;
        address.line1 = x.address.line1;
        address.line2 = x.address.line2;
        address.line3 = x.address.line3;
        address.city = x.address.city;
        address.addressStateId = x.address.state.stateId;
        address.addressState = x.address.state.stateAbbr;
        address.zipcode5 = x.address.zipcode5;
        address.zipcode4 = x.address.zipcode4;
        address.longitude = x.address.longitude;
        address.latitude = x.address.latitude;
        address.sanitizeOverride = x.address.sanitizeOverride;
        address.yearBuilt = x.address.yearBuilt;
        addrsOut.push(address);
        i++;
      });
    }

    if (isMerge && addresses.length === addrsOut.length) {
      this.emitMergedAddressesValid();
    }
    return addrsOut;
  }

  emitMergedAddressesValid(): void {
    let event: IHomEventEmitter = {
      requestor: 'contact-manager',
      event: ContactEvent.isContactValid,
      action: '',
      data: true
    };
    this.managerEvent.emit(event);
  }

  isPhoneDuplicate(existingPhones: IContactMechanismPhone[], newPhone: ISeedContactPhone): boolean {
    let isDup: boolean = false;
    const match = existingPhones.find(x =>
      x.phoneTypeId === newPhone.phoneTypeId
      && x.phoneNumber === newPhone.phone.phoneNumber
      && ((x.phoneExtension == null ? '' : x.phoneExtension.toLocaleLowerCase()) === (newPhone.phoneExtension == null ? '' : newPhone.phoneExtension.toLocaleLowerCase())));
    return match ? true : false;
  }

  isEmailDuplicate(existingEmails: IContactMechanismEmail[], newEmail: ISeedContactEmail): boolean {
    let isDup: boolean = false;
    const match = existingEmails.find(x =>
      x.emailTypeId === newEmail.emailTypeId
      && x.emailAddress.toLowerCase() === newEmail.email.emailAddress.toLowerCase());

    return match ? true : false;
  }

  isAddressDuplicate(existingAddresses: IContactMechanismAddress[], newAddress: ISeedContactAddress): IContactMechanismAddress {
    let isDup: boolean = false;
    const match = existingAddresses.find(x =>
      x.addressTypeId === newAddress.addressTypeId
      && x.line1.toLowerCase() === newAddress.address.line1.toLowerCase()
      && ((x.line2 == null ? '' : x.line2.toLocaleLowerCase()) === (newAddress.address.line2 == null ? '' : newAddress.address.line2.toLocaleLowerCase()))
      && ((x.line3 == null ? '' : x.line3.toLocaleLowerCase()) === (newAddress.address.line3 == null ? '' : newAddress.address.line3.toLocaleLowerCase()))
      && ((x.city == null ? '' : x.city.toLocaleLowerCase()) === (newAddress.address.city.toLocaleLowerCase() == null ? '' : newAddress.address.city.toLocaleLowerCase()))
      && x.addressState === newAddress.address.state.stateAbbr
      && x.zipcode5 === newAddress.address.zipcode5
    );

    return match;
  }

  dispatchDataRequests(): void {
    //take(1)  will get one and complete.
    this.getContact();
    //phones
    this.subscription.add(this.store.select(listDataExists(ContactStore.phones, this.contactId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe(() => { this.contactUtilityService.dispatchEntityData(this.contactConstantsService.contactMechanismCategoryPhone, this.contactId); }));
    //emails
    this.subscription.add(this.store.select(listDataExists(ContactStore.emails, this.contactId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe(() => { this.contactUtilityService.dispatchEntityData(this.contactConstantsService.contactMechanismCategoryEmail, this.contactId); }));
    //addresses
    this.subscription.add(this.store.select(listDataExists(ContactStore.addresses, this.contactId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe(() => { this.contactUtilityService.dispatchEntityData(this.contactConstantsService.contactMechanismCategoryAddress, this.contactId); }));
  }

  getContact() {
    this.subscription.add(this.store.pipe(select(getObjectDataById(ContactStore.contactInformation, this.contactId)), take(1))
      .subscribe((data: Contact) => {
        if (!data) {
          this.contactUtilityService.dispatchGetContact(this.contactId);
        }
      }));
  }

  //stub to handle making specific fields visible for input - fields seldom used
  enableFormField(fieldName: string): void {
    switch (fieldName) {
      case  'nameSuffix':
        this.suffixRequested$.next(true);
        break;
      case 'middleName':
        this.middleRequested$.next(true);
        break;
      default:
        break;
    }
  }

  disableAddItem(mechanism: string, type: string): void {
    //if only have 1 type for the mech, disable the item
    // else disable the sub item (type)
    let items: IMultiTierMenu = this.addItems.find(x => x.label === mechanism);
    items.subItems.forEach((x: IMultiTierMenu) => {
      x.disabled = x.label === type ? true : x.disabled;
    });

    let temp: IMultiTierMenu[] = this.addItems.filter(x => x.label !== mechanism)
    temp.push(items);
    temp = temp.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

    this.addItems = temp;
  }

  //for existing will load these after get existing so will know what we can load
  //for new will load on init
  loadAddItems(mechanism?: string) {
    let existing: IMultiTierMenu[] = this.addItems;
    if ((!existing || existing.length === 0) && !this.isOrganization) {
      existing = this.loadNameAddItems();
    }

    const mechanisms: string[] = mechanism ? [mechanism]
      :   Array.from(new Set(this.typeSpecifications.filter(x => x.contactType_contactTypeId === this.contactTypeId).map(s => s.contactMechanismCategoryName)));

    mechanisms.forEach(mech => {
      const typeSpecs = this.typeSpecifications.filter(x => x.contactType_contactTypeId === this.contactTypeId && x.contactMechanismCategoryName === mech);
      const subItems: IMultiTierMenu[] = []
      typeSpecs.forEach(spec => {
        const count: number = this.getCountByMechanismByType(spec.contactMechanismCategoryName, spec.contactMechanismCategoryTypeName);
        const data: IAddData = { mechanism: spec.contactMechanismCategoryName, type: spec.contactMechanismCategoryTypeName };
        if (!spec.maximum || count < spec.maximum) {
          subItems.push({
            label: spec.contactMechanismCategoryTypeName,
            disabled: false,
            data: data
          });
        }
      });
      if (existing.find(x => x.label === mech)) {
        //remove existing items for this mech type and reload
        //needed as this is called from an observable for exisitng mechs
        existing = existing.filter(x => x.label !== mech);
      }
      existing.push({
        label: mech,
        subItems: subItems.length ? subItems.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)) : subItems,
        disabled: !subItems.length,
        data: null
      });
      this.addItems = existing.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    });
  }

  loadNameAddItems(): IMultiTierMenu[] {
    let items: IMultiTierMenu[] = [];
    const suffixData: IAddData = { mechanism: this.mechName, type: this.typeSuffix};
    const middleData: IAddData = { mechanism: this.mechName, type: this.typeMiddle };
    items.push({
      label: this.mechName,
      subItems: [
        {
          label: this.typeSuffix,
          subItems: null,
          disabled: false,
          data: suffixData
        },
        {
          label: this.typeMiddle,
          subItems: null,
          disabled: false,
          data: middleData
        }
      ],
      disabled: false,
      data: null
    });
    return items;
  }




  addPhone(mechanism: string, type: string): void {
    const spec = this.getSpecByMechanismByType(mechanism, type);
    if (!spec) {
      console.log('DEV ERROR: requesting a mech and type that is not applicable for this contact type: ', mechanism, type);
      return;
    }
    const index = this.getMaxIndex(mechanism) + 1;
    let phone: ContactMechanismPhone = new ContactMechanismPhone(
      this.contactConstantsService.contactMechanismCategoryPhone,
      spec.contactMechanismCategoryTypeName,
      spec.contactMechanismCategoryType_contactMechanismCategoryTypeId,
      spec.entityLabel,
      this.contactConstantsService.phoneFormPrefix.concat(index.toString()));
      let local = !this.phones$.getValue() ? [] : cloneDeep(this.phones$.getValue());
      if (!local.length) {
        phone.isPreferred = true;
    }
     local.push(phone);
      this.phones$.next(local);
  }

  addEmail(mechanism: string, type: string): void {
    const spec = this.getSpecByMechanismByType(mechanism, type);
    if (!spec) {
      console.log('DEV ERROR: requesting a mech and type that is not applicable for this contact type: ', mechanism, type);
      return;
    }
    const index = this.getMaxIndex(mechanism) + 1;
   let email: ContactMechanismEmail = new ContactMechanismEmail(
     this.contactConstantsService.contactMechanismCategoryEmail,
      spec.contactMechanismCategoryTypeName,
      spec.contactMechanismCategoryType_contactMechanismCategoryTypeId,
       spec.entityLabel,
     this.contactConstantsService.emailFormPrefix.concat(index.toString())
    );
    let local = !this.emails$.getValue() ? [] : cloneDeep(this.emails$.getValue());
    if (!local.length) {
      email.isPreferred = true;
    }
    email.emailAddress = '';
    email.emailId = 0;
    local.push(email);

    this.emails$.next(local);
  }

  addAddress(mechanism: string, type: string): void {
    const spec = this.getSpecByMechanismByType(mechanism, type);
    if (!spec) {
      console.log('DEV ERROR: requesting a mech and type that is not applicable for this contact type: ', mechanism, type);
      return;
    }
    const index = this.getMaxIndex(mechanism) + 1;
    let address: ContactMechanismAddress = new ContactMechanismAddress(
      this.contactConstantsService.contactMechanismCategoryAddress,
      spec.contactMechanismCategoryTypeName,
      spec.contactMechanismCategoryType_contactMechanismCategoryTypeId,
      spec.entityLabel,
      this.contactConstantsService.addressFormPrefix.concat(index.toString())
    );
    let local = !this.addresses$.getValue() ? [] : cloneDeep(this.addresses$.getValue());
    if (!local.length) {
      address.isPrimary = true;
    }

    local.push(address);
    this.addresses$.next(local);
  }

  deleteContactMechanism(e: IHomEventEmitter): void {
    const data: IDeleteContactMechanism = e.data;
    //no confirmation
    const index: number = this.findIndex(data.mechanism, data.groupName);
    if (data.id > 0) {
      if (!data.url) {
        console.log('DEV ERROR: Delete url is not defined;');
      } else {
        const keyData: IKey = {
          storeName: this.getEntityStore(data.mechanism),
          parentId: this.contactId,
          key: this.contactUtilityService.getEntityKey(data.mechanism),
          id: data.id
        }
        const emitter: IHomEventEmitter = { requestor: 'contact-manager', event: this.myConstants.emitterEventDelete, action: '', data: null };
        const deleteData = new DeleteObjectModel(keyData, data.url, emitter);
        this.store.dispatch(new fromStore.DeleteObjectByUrlList({ deleteData }));
        //updates will occur when component sees changes to store
      }
    } else {
      //uncommitted removal
      this.removeDataRow(data.mechanism, index);
      this.contactService.contactForm.removeControl(data.groupName);
      this.loadAddItems(data.mechanism);
      this.contactService.contactForm.updateValueAndValidity();
      if (!this.contactService.contactForm.invalid) {
        //for Listeners
        this.emitMergedAddressesValid();
      }
    }
  }

  deleteContactPreference(): void {
    const contact = this.contact$.value;
    const url: string = contact.hasOwnProperty('metaData') && contact['metaData'].hasOwnProperty('contactDeletePreferenceUrl')
      ? contact['metaData']['contactDeletePreferenceUrl']
      : '';

    if (!url) {
      return;
    }
    this.working$.next(true);
    this.subscription.add(this.domainObjectService.delete(url)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          this.contactUtilityService.dispatchGetContact(this.contactId, true);
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));
  }

  initPhoneContactMechanism(): void {
    let rows: IContactMechanismPhone[] = cloneDeep(this.phones$.getValue());
    if (!rows || rows.length === 0) {
      return;
    }
    rows.forEach(item => {
      this.contactService.contactForm.removeControl(item.formGroupName);
    });
    this.phones$.next([]);
  }

  initEmailContactMechanism(): void {
    let rows: IContactMechanismEmail[] = cloneDeep(this.emails$.getValue());
    if (!rows || rows.length === 0) {
      return;
    }
    rows.forEach(item => {
      this.contactService.contactForm.removeControl(item.formGroupName);
    });
    this.emails$.next([]);
  }

  initAddressContactMechanism(): void {
    let rows: IContactMechanismAddress[] = cloneDeep(this.addresses$.getValue());
    if (!rows || rows.length === 0) {
      return;
    }
    rows.forEach(item => {
      this.contactService.contactForm.removeControl(item.formGroupName);
    });
    this.addresses$.next([]);
  }

  removeDataRow(mechanism: string, index: number) {
    switch (mechanism) {
      case this.contactConstantsService.contactMechanismCategoryAddress:
        let addrs = cloneDeep(this.addresses$.getValue());
        addrs.splice(index, 1);
        this.addresses$.next(addrs);
        break;
      case this.contactConstantsService.contactMechanismCategoryEmail:
        let emails = cloneDeep(this.emails$.getValue());
        emails.splice(index, 1);
        this.emails$.next(emails);
        break;
      case this.contactConstantsService.contactMechanismCategoryPhone:
        let phones = cloneDeep(this.phones$.getValue());
        phones.splice(index, 1);
        this.phones$.next(phones);
        break;
      default:
        break;
    }
  }

  setContactProperties(): IContact {
    const form: FormGroup = this.contactService.contactForm.controls['headerForm'] as FormGroup;
    if (!form) {
      return;
    }
    const contactForm: IContact = form.getRawValue();
    let contact: IContact = null;

    if (this.operation === this.myConstants.operationTypeCreate) {
      contact = new Contact(this.contactTypeId, this.contactService.contactType(this.contactTypeId), this.isOrganization, this.defaults);
    } else {
      const existing = cloneDeep(this.contact$.getValue());
      contact = existing;
    }

    contact.firstName = contactForm.firstName ? contactForm.firstName : '';
    contact.lastName =  contactForm.lastName ? contactForm.lastName : '';
    contact.middleName = contactForm.middleName ? contactForm.middleName : '';
    contact.nameSuffix_lookupValueId = this.isOrganization ? null :
      !contactForm ? contact.nameSuffix_lookupValueId : contactForm['nameSuffix'] && contactForm['nameSuffix'].hasOwnProperty('lookupValueId') ? contactForm['nameSuffix']['lookupValueId'] : null;
    contact.sssOptOut = this.contactTypeId === this.contactConstantsService.contactTypeIdCustomer ? true : contactForm.sssOptOut;
    contact.optOutSms = contactForm.optOutSms;

    return contact;
  }

  setPhoneProperties(): IPostContactMechanismPhone[] {
    let phones: IPostContactMechanismPhone[] = [];
    let subject: IContactMechanismPhone[] = this.phones$.getValue();
    if (!subject || subject.length === 0) {
      return [];
    }

    let idx = 0;
    subject.forEach(item => {
      const form: FormGroup = this.contactService.contactForm.controls[item.formGroupName] as FormGroup;
      if (form) {
        const formValue: IContactMechanismPhone = form.getRawValue();
        const number = formValue.phoneNumber.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');

        let phone: IPostContactMechanismPhone = {
          contactMechanismPhoneId: item.contactMechanismPhoneId,
          contactMechanism_contactMechanismId: item.contactMechanism_contactMechanismId,
          contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId: item.phoneTypeId,
          contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName: item.phoneType,
          contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName: this.contactConstantsService.contactMechanismCategoryPhone,
          phone: { phoneId: item.phoneId, phoneNumber: number },
          isPreferred: formValue.isPreferred,
          canSms: formValue.canSms,
          phoneExtension: formValue.phoneExtension,
          rowVersion: item.rowVersion,
          isSmsVerified: item.isSmsVerified,
          isDirty: form.dirty || item.contactMechanismPhoneId === 0
        }
        phones.push(phone);
      }
      idx++;
    });

    return phones;
  }

  setEmailProperties(): IPostContactMechanismEmail[] {
    let emails: IPostContactMechanismEmail[] = [];
    let subject: IContactMechanismEmail[] = this.emails$.getValue();
    if (!subject || subject.length === 0) {
      return [];
    }

    let idx = 0;
    subject.forEach(item => {
      const form: FormGroup = this.contactService.contactForm.controls[item.formGroupName] as FormGroup;
      if (form) {
        const formValue: IContactMechanismEmail = form.getRawValue();

        let email: IPostContactMechanismEmail = {
          contactMechanismEmailId: item.contactMechanismEmailId,
          contactMechanism_contactMechanismId: item.contactMechanism_contactMechanismId,
          contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId: item.emailTypeId,
          contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName: item.emailType,
          contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName: this.contactConstantsService.contactMechanismCategoryEmail,
          email: { emailId: item.emailId, emailAddress: formValue.emailAddress },
          isPreferred: formValue.isPreferred,
          rowVersion: item.rowVersion,
          isDirty: form.dirty || item.contactMechanismEmailId === 0
        }
        emails.push(email);
      }
     idx++;
    });

    return emails;
  }

  setAddressProperties(): IPostContactMechanismAddress[] {
    let addresses: IPostContactMechanismAddress[] = [];
    let subject: IContactMechanismAddress[] = this.addresses$.getValue();
    if (!subject || subject.length === 0) {
      return [];
    }

    let idx = 0;
    subject.forEach(item => {
      const form: FormGroup = this.contactService.contactForm.controls[item.formGroupName] as FormGroup;
      if (form) {
        const formValue: IContactMechanismAddress = form.getRawValue();
        const lines: IAddressLines = this.contactUtilityService.getAddressLines(formValue.line1);
        const zips: IAddressZips = this.contactUtilityService.getAddressZips(formValue.zipcode5);

        let address: IPostContactMechanismAddress = {
          contactMechanismAddressId: item.contactMechanismAddressId,
          contactMechanism_contactMechanismId: item.contactMechanism_contactMechanismId,
          contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId: item.addressTypeId,
          contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName: item.addressType,
          contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName: this.contactConstantsService.contactMechanismCategoryAddress,
          address: {
            addressId: item.addressId,
            line1: lines.line1,
            line2: lines.line2,
            line3: lines.line3,
            city: formValue.city.trim(),
            state_stateId: this.contactUtilityService.getStateId(formValue['addressState']),
            stateAbbr: formValue['addressState'],
            zipcode5: !zips ? '' : zips.zip5.toString(),
            zipcode4: !zips ? '' : zips.zip4 ? zips.zip4.toString() : null,
            yearBuilt: formValue.yearBuilt > 0 ? formValue.yearBuilt : null,
            sanitizeOverride: formValue.sanitizeOverride,
            latitude: formValue.latitude,
            longitude: formValue.longitude
          },
          isPrimary: formValue.isPrimary,
          rowVersion: item.rowVersion,
          isDirty: form.dirty || item.contactMechanismAddressId === 0
        }
        addresses.push(address);
      }
      idx++;
    });

    return addresses;
  }

  //mechanism: phone, address, email
  //type: business, residential, mobile, etc
  getSpecByMechanismByType(mechanism: string, type: string): IContactTypeSpecification {
    const typeSpecs = this.typeSpecifications.filter(x => x.contactType_contactTypeId === this.contactTypeId
      && x.contactMechanismCategoryName === mechanism
      && x.contactMechanismCategoryTypeName === type);

    return !typeSpecs ? null : typeSpecs[0];
  }

  findIndex(mechanism: string, groupName: string): number {
    switch (mechanism) {
      case this.contactConstantsService.contactMechanismCategoryAddress:
        return this.addresses$.getValue() ? this.addresses$.getValue().findIndex(x => x.formGroupName === groupName) : -1;
      case this.contactConstantsService.contactMechanismCategoryEmail:
        return this.emails$.getValue() ? this.emails$.getValue().findIndex(x => x.formGroupName === groupName) : -1;
      case this.contactConstantsService.contactMechanismCategoryPhone:
        return this.phones$.getValue() ? this.phones$.getValue().findIndex(x => x.formGroupName === groupName): -1;
      default:
        return 0;
    }
  }

  getMaxIndex(mechanism: string): number {
    switch (mechanism) {
      case this.contactConstantsService.contactMechanismCategoryAddress:
        const addrs = cloneDeep(this.addresses$.value);
        const addrList: number[] = addrs
          ? sortBy(addrs.map(x => +this.contactService.getIndex(this.contactConstantsService.addressFormPrefix, x.formGroupName)))
          : null;
        return !addrList || !addrList.length ? -1 : addrList.pop();
      case this.contactConstantsService.contactMechanismCategoryEmail:
        const emails = cloneDeep(this.emails$.value);
        const emailList = emails
          ? sortBy(emails.map(x => +this.contactService.getIndex(this.contactConstantsService.emailFormPrefix, x.formGroupName)))
          : null;
        return !emailList || !emailList.length ? -1 : emailList.pop();
      case this.contactConstantsService.contactMechanismCategoryPhone:
        const phones = cloneDeep(this.phones$.value);
        const phoneList = phones
          ? sortBy(phones.map(x => +this.contactService.getIndex(this.contactConstantsService.phoneFormPrefix, x.formGroupName)))
          : null;
        return !phoneList || !phoneList.length ? -1 : phoneList.pop();
      default:
        return 0;
    }
  }

  getMinAllowed(mechanism: string, type: string): number {
    const spec = this.getSpecByMechanismByType(mechanism, type);
    return !spec || !spec.minimum ? 0 : spec.minimum;
  }

  getMaxAllowed(mechanism: string, type: string): number {
    const spec = this.getSpecByMechanismByType(mechanism, type);
    return !spec  ? 0 : spec.maximum;
  }

  //current phone count by type
  getCountByMechanismByType(mechanism: string, type: string): number {
    const matches: number =
      mechanism === this.contactConstantsService.contactMechanismCategoryPhone ? !this.phones$.getValue() ? 0 : this.phones$.getValue().filter(x => x.phoneType === type).length
        : mechanism === this.contactConstantsService.contactMechanismCategoryEmail ? !this.emails$.getValue() ? 0 : this.emails$.getValue().filter(x => x.emailType === type).length
          : mechanism === this.contactConstantsService.contactMechanismCategoryAddress ? !this.addresses$.getValue() ? 0 :this.addresses$.getValue().filter(x => x.addressType === type).length : 0

    return !matches ? 0 : matches;
  }

  //determine if have hit the limit for this mechanism (phone, address, email) and type (Business, Residential, etc)
  hasHitLimit(mechanism: string, type: string): boolean {
    const current = this.getCountByMechanismByType(mechanism, type);
    const max: number = this.getMaxAllowed(mechanism, type);
    return !max ? false : current >= max;
  }

  getEntityStore(mechanism: string): string {
    switch (mechanism) {
      case this.contactConstantsService.contactMechanismCategoryPhone:
        return ContactStore.phones;
      case this.contactConstantsService.contactMechanismCategoryEmail:
        return ContactStore.emails;
      case this.contactConstantsService.contactMechanismCategoryAddress:
        return ContactStore.addresses;
      default:
        return ContactStore.contactInformation;
    }
  }

  getCategoryName(type: string): string {
    switch (type.toLowerCase()) {
      case 'phone':
        return this.contactConstantsService.contactMechanismCategoryPhone;
      case 'email':
        return this.contactConstantsService.contactMechanismCategoryEmail;
      case 'address':
        return this.contactConstantsService.contactMechanismCategoryAddress;
      default:
        return '';
    }
  }

  isValidForMatchSearch(searchData: IContactInfoViewModel): boolean {
    if (Object.keys(this.contactService.contactForm.controls).length === 0) {
      return false;
    }
    if (this.contactService.contactForm.controls['headerForm'] === null) {
      return false;
    }
    return this.isNameSearchable(searchData) || this.isAddressSearchable(searchData) || this.isEmailSearchable(searchData) || this.isPhoneSearchable(searchData);
  }

  setSearchData(): void {
    if (!this.checkForMatch || (this.isPoImport && this.contactId > 0)) {
      return;
    }

    const addrs = this.addresses$.getValue();
    const phones = this.phones$.getValue();
    const emails = this.emails$.getValue();
    let searchData: IContactInfoViewModel = {
      contact: Object.keys(this.contactService.contactForm.controls).length === 0 ? null : this.setContactProperties(),
      contactMechanismAddresses: !addrs || addrs.length === 0 || this.contactService.contactForm.controls[addrs[0].formGroupName] === null ? [] : this.setAddressProperties(),
      contactMechanismEmails: !emails || emails.length === 0 || this.contactService.contactForm.controls[emails[0].formGroupName] === null ? [] : this.setEmailProperties(),
      contactMechanismPhones: !phones || phones.length === 0 || this.contactService.contactForm.controls[phones[0].formGroupName] === null  ? [] : this.setPhoneProperties()
    }
    this.searchData$.next(this.isValidForMatchSearch(searchData) ? searchData : null);
    this.changeDetectorRef.detectChanges();
  }


  isNameSearchable(searchData: IContactInfoViewModel): boolean {
    return searchData && searchData.contact
      && ( (searchData.contact.firstName && searchData.contact.firstName != 'First' && searchData.contact.firstName.length > 0
            && ((searchData.contact.middleName && searchData.contact.middleName != 'Middle' && searchData.contact.middleName.length > 0) ||
            (searchData.contact.lastName && searchData.contact.lastName != 'Last' && searchData.contact.lastName.length > 2)))
        || (searchData.contact.lastName && searchData.contact.lastName != 'Last' && searchData.contact.lastName.length > 2) );
  };

  isAddressSearchable(searchData: IContactInfoViewModel): boolean {
    return searchData && searchData.contactMechanismAddresses
      && searchData.contactMechanismAddresses.length > 0
      && searchData.contactMechanismAddresses[0].address
      && searchData.contactMechanismAddresses[0].address.line1 != 'Street'
      && searchData.contactMechanismAddresses[0].address.line1.length > 5;
  };

  isEmailSearchable(searchData: IContactInfoViewModel): boolean {
    return searchData && searchData.contactMechanismEmails
      && searchData.contactMechanismEmails.length > 0
      && searchData.contactMechanismEmails[0].email
      && searchData.contactMechanismEmails[0].email.emailAddress
      && searchData.contactMechanismEmails[0].email.emailAddress.length > 5;
  };

  isPhoneSearchable(searchData: IContactInfoViewModel): boolean {
    return searchData && searchData.contactMechanismPhones
      && searchData.contactMechanismPhones.length > 0
      && searchData.contactMechanismPhones[0].phone
      && searchData.contactMechanismPhones[0].phone.phoneNumber
      && searchData.contactMechanismPhones[0].phone.phoneNumber.length > 9;
  };

  //user can select a contact match to merge po data with, if any,
  //or(if in po import) select to use existing po data
  handleMatch(event: IHomEventEmitter): void {
    if (event.action === ContactEventAction.mergeWithExisting  
        || event.action === ContactEventAction.linkWithExisting
        || event.action === ContactEventAction.createNewContact) {
      //bubble up 
      this.managerEvent.emit(event);
      this.contactMatchAction = event.action;
    } else {
      if (this.contactMatchAction) {
      //Already had match selected - so user is just clicking between matches 
      //need to reload based on seed data if user is clicking useSelected and now usePo
        this.managerEvent.emit(event);
      }
      this.contactMatchAction = event.action;
    }
    this.forceReadOnly = false; //only true on initial load of po import seed data
  }

  runMatches(): void {
    this.setSearchData();
    let event: IHomEventEmitter = {
      requestor: 'contact-manager',
      event: ContactEvent.ranNewContactMatchCheck,
      action: '',
      data: ''
    };
    this.managerEvent.emit(event);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.contactSub) {
      this.contactSub.unsubscribe();
    }
    if (this.contactEventSub) {
      this.contactEventSub.unsubscribe();
    }
    if (this.typeSub) {
      this.typeSub.unsubscribe();
    }
    if (this.addrSub) {
      this.addrSub.unsubscribe();
    }
    if (this.emailSub) {
      this.emailSub.unsubscribe();
    }
    if (this.phoneSub) {
      this.phoneSub.unsubscribe();
    }
  }

}
