import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DuplicateInstanceService {

  constructor() {}

  public init(): void {
    let bc = new BroadcastChannel('hom-app');

    bc.onmessage = (event) => {
      if (event.data === `HomAppInstance`) {
        //this instance is the first instance
        bc.postMessage(`DupInstance`);
      }
      if (event.data === `DupInstance`) {
        //this instance is the duplicate insance.
        return window.location.href = window.location.origin + "/duplicate-instance.html", void 0;
      }
    };

    bc.postMessage(`HomAppInstance`);
  }
}
