import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, map, filter, first } from 'rxjs/operators';

//store actions and reducers
import * as fromSelectionLists from '../../../shared/store/selectionLists/index';
import { ISelectListData } from '../../../shared/store/selectionLists/index';
import { IEntity } from '../../../shared/interfaces';
import { ListFilter, IListFilter } from '../../../../../installer-portal/src/app/interfaces';
import { OrderTerm } from '../../../fw/dynamic-list/interfaces';

@Injectable()
export class ContactTypeEntityResolver  {
  storeName: string = 'contactTypeEntities';
  subscription: Subscription = new Subscription();
  constructor(public store: Store<fromSelectionLists.IStoreState>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IEntity[]> {
    this.requestData();

    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<IEntity[]> {
    return this.store.select(fromSelectionLists.getSelectionListDataByType(this.storeName, -1))
      .pipe(
        filter((data: any[]) => data !== null),
        map((data: any[]) => { return data; }),
        first()
      );
  }

  requestData(): void {
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true; //method returns a subset.
    listFilter.orderTerm = [new OrderTerm('entityName', true, 1)];

    this.subscription.add(this.store.select(fromSelectionLists.getSelectionListByTypeByParent(this.storeName, -1))
      .pipe(take(1))
      .subscribe((list: ISelectListData) => {
        if (!list || !list.data) {
          this.store.dispatch(
            new fromSelectionLists.GetEntityListByMethodParams('Entity', 'ContactTypeEntities', '', listFilter, this.storeName));
        }
      }));
  }
}
