import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { ISku, ISkuPayout } from '../../../view-models';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { SkuMaintEvent } from '../../enums/admin.enums';
import { SkuCreationWizardOrigin } from '../../../pricing-shared/enums/pricing-shared.enums';

import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { SetEventList } from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { MetaDataService } from '../../../../../fw/dynamic-list/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { DomainObjectService } from '../../../../../shared/services';
import { AdminPricingService } from '../../services/admin-pricing.service';

@Component({
  selector: 'sku-payouts',
  templateUrl: './sku-payouts.component.html'
})
export class SkuPayoutsComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public sku$: BehaviorSubject<ISku> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public displayFields: string[] = ['skuName', 'skuStatus', , 'allowNegativePrice', 'skuType', 'unitMeasure', 'accountingAccountCode', 'serviceCodeOverride'];
  public operation: string; 
  subscription: Subscription = new Subscription();
  skuId: number = 0;
  gcId: number = 0;
  public form: FormGroup;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public adminPricingService: AdminPricingService,
    public mds: MetaDataService,
    public modalService: ModalService,
    public dos: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public ngOnInit() {
    this.operation = this.myConstants.operationTypeDetails;
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.skuId = +paramMap.get('id');
      this.gcId = +paramMap.get('gcId');
      this.newRequest();
    });

    this.subscription.add(this.sku$.pipe(
      filter((data) => data !== null))
      .subscribe((data: ISku) => {
        this.form = this.mds.loadDynamicFormGroup(this.displayFields, data, this.myConstants.operationTypeDetails);
      }));
   }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case SkuMaintEvent.unlinkPayout:
        this.unlinkPayout(event.data);
        break;
      case SkuMaintEvent.linkPayout:
        this.linkPayout();
        break;
      case SkuMaintEvent.createAndLinkPayout:
        this.createAndLinkPayout(event.data);
        break;
      default:
        break;
    }
  }

  newRequest(): void {
    this.getSku();
    const def: IListDefinition = this.adminPricingService.loadSkuListDefinition(this.gcId);
    this.mds.setFieldDefinitions(def.storeName);
    this.mds.loadSelectionLists(this.displayFields, 'sku-payouts', this.gcId);
    this.setListDefinition();
  }

  getSku(): void {
    const def: IListDefinition = this.adminPricingService.loadSkuListDefinition(this.gcId);
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(def.storeName, def.parentId, def.rowKeyId, this.skuId)))
        .subscribe(entity => {
          this.sku$.next(cloneDeep(entity));
        })
      );
  }

  setListDefinition(): void {
    let listDefinition: IListDefinition = this.adminPricingService.loadSkuPayoutsListDefinition(this.skuId);
    this.listDefinition$.next(listDefinition);
  }

  unlinkPayout(data: ISkuPayout): void {
    if (!data || !data.skuPayoutId) {
      this.errorData$.next([{ key: '__Model', value: ['Contact Support.  Sku Payout Id not found.'] }]);
      return;
    }

    this.errorData$.next([]);
    this.subscription.add(this.dos.deleteByMethodById('SkuPayout', 'Delete', data.skuPayoutId)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          const def: IListDefinition = this.listDefinition$.value;
          const emitter: IHomEventEmitter = { requestor: 'sku-payouts', event: this.myConstants.emitterEventListReload, action: '', data: null };
          this.store.dispatch(new SetEventList({
            storeName: def.storeName,
            parentId: def.parentId,
            event: emitter
          }));
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  linkPayout(): void {
    this.modalService.open({
      title: 'Payouts',
      path: 'sku-payout-link/' + this.skuId.toString(),
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      isSubModal: true,
      sizeType: ModalSizeType.small,
    });
  }

  createAndLinkPayout(data: ISkuPayout): void {
    this.modalService.open({
      title: 'Payout Creation Wizard',
      path: 'sku-creation-wizard/' + this.skuId.toString() + '/' + this.gcId.toString() + '/' + SkuCreationWizardOrigin.skuPayout,
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      isSubModal: true,
      sizeType: ModalSizeType.small,
    });
  }
}
