import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from "@angular/router";
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { cloneDeep, includes, uniq } from 'lodash';
import { filter } from 'rxjs/operators';

import { IFrameworkConfigSettings } from '../fw/fw-config/interfaces/i-framework-config-settings';
import { initialMenuItems } from './app.menu';

import { getAllMyWidgets } from '../auth/store/index';
import { IWidgetManager } from './portals/portal/interfaces';
import * as fromRoot from './store/reducers/index';
import * as fromAuth from '../auth/store/index';
import { FrameworkConfigService } from '../fw/fw-config/services/framework-config.service';
import { MenuService } from '../fw/fw-menus/services/menu.service';
import { DuplicateInstanceService, GeolocationService } from "../shared/services";
import { UserSessionEventService } from '../shared/services';
import { RouteToCrmService } from './route-to-crm/route-to-crm.service';
import { HomLocalStorageChangeService } from '../shared/services/hom-local-storage-change.service';

/**
 * App Component is the starting point for all other components in our application
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  authenticated$: Observable<boolean>;
  hasDashItems: boolean;
  subscription: Subscription = new Subscription();
  constructor(public router: Router,
    public frameworkConfigService: FrameworkConfigService,
    public menuService: MenuService,
    public rootStore: Store<fromRoot.IState>,
    public duplicateInstanceService: DuplicateInstanceService,
    public userSessionEventService: UserSessionEventService,
    public geolocationService: GeolocationService,
    public routeToCrmService: RouteToCrmService,
    public homLocalStorageChangeService: HomLocalStorageChangeService
  ) { }

  ngOnInit() {
    this.geolocationService.init();
    this.duplicateInstanceService.init();
    this.routeToCrmService.init();
    this.homLocalStorageChangeService.init();
    this.authenticated$ = this.rootStore.pipe(select(fromAuth.isAuthenticated));

    let config: IFrameworkConfigSettings = {
      appIcons: [
        { name: 'userSettings', icon: 'fal fa-cog', alt: 'My Settings', link: 'userSettings', selector: 'user-settings', modal: true },
        { name: 'userRecents', icon: 'fal fa-bookmark', alt: 'My Bookmarks', link: 'userRecents', selector: 'user-recents', modal: false },
        { name: 'userJobs', icon: 'fal fa-atom-alt', alt: 'My Jobs', link: 'userJobs', selector: 'user-jobs', modal: true },
        { name: 'pendingChanges', icon: 'fal fa-inbox', alt: 'Pending Changes', link: 'pendingChanges', selector: 'user-pending-changes', modal: true },
        { name: 'userNotifications', icon: 'fal fa-paper-plane', alt: 'My Notifications', link: 'userNotifications', selector: 'user-notifications', modal: true }
      ],
      showStatusBarBreakpoint: 800
    };

    this.frameworkConfigService.configure(config);

    this.authenticated$.subscribe(authenticated => {
      if (authenticated) {
        if (!this.menuService.items) {
          this.setMenu();
        }
        this.userSessionEventService.init();
      }
    });
  }

  setMenu(): void {
    this.subscription.add(this.rootStore.pipe(select(getAllMyWidgets),
      filter((data: IWidgetManager[]) => data && data.length > 0))
      .subscribe((data: IWidgetManager[]) => {
        const availPortalIds: number[] = uniq(data.map(x => x.managerPortal_managerPortalId));
        let menuItems = cloneDeep(initialMenuItems);
        this.menuService.items = menuItems.filter(x => includes(availPortalIds, x.portalId));
    }));
  }

}
