<div *ngIf="form" class="sku-payouts">
  <fw-component-title title="Sku Details"></fw-component-title>
  <form [formGroup]="form" novalidate class="form-wrapper">
    <fw-dynamic-field [field]="mds.getFieldDefinition('skuName')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <div class="sku-payouts__two-fields">
      <fw-dynamic-field [field]="mds.getFieldDefinition('skuStatus')"
                        [form]="form"
                        [operation]="operation"
                        [selectItems]="mds.getSelectItems('skuStatus')">
      </fw-dynamic-field>
      <fw-dynamic-field [field]="mds.getFieldDefinition('skuType')"
                        [form]="form"
                        [operation]="operation"
                        [selectItems]="mds.getSelectItems('skuType')">
      </fw-dynamic-field>
    </div>
    <div class="sku-payouts__two-fields">
      <fw-dynamic-field [field]="mds.getFieldDefinition('accountingAccountCode')"
                        [form]="form"
                        [operation]="operation">
      </fw-dynamic-field>
      <fw-dynamic-field [field]="mds.getFieldDefinition('serviceCodeOverride')"
                        [form]="form"
                        [operation]="operation">
      </fw-dynamic-field>
    </div>
    <div class="sku-payouts__two-fields">
      <fw-dynamic-field [field]="mds.getFieldDefinition('unitMeasure')"
                        [form]="form"
                        [operation]="operation"
                        [selectItems]="mds.getSelectItems('unitMeasure')">
      </fw-dynamic-field>
      <fw-dynamic-field class="sku-payouts--bool"
                        [field]="mds.getFieldDefinition('allowNegativePrice')"
                        [form]="form"
                        [operation]="operation">
      </fw-dynamic-field>
    </div>
  </form>

  <div *ngIf="(listDefinition$ | async)">
    <div class="sku-payouts__list">
      <div class="list-wrapper">
        <fw-dynamic-list [vmListDefinition]="listDefinition$ | async"
                         [listTitle]="'Payouts'"
                         (customEvent)="onCustom($event)">
        </fw-dynamic-list>
      </div>
    </div>
  </div>
  <fw-error-box [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>
