<div class="portal">
  <div class="portal__detail--item">
    <div [ngClass]="{'loading-mask': !(stage$ | async)}">
      <iframe #doc
              [ngStyle]="{'height.px': (documentHeight$ | async), 'opacity': (stage$ | async)}"
              style="width: 100%"
              frameborder="0"
              src="/Projects/ReportsManager"
              (load)="setContent(doc)">
      </iframe>
    </div>
  </div>
</div>
