import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFieldDefinition } from '../../../dynamic-forms/interfaces/i-field-definition';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'fw-editable-select',
  templateUrl: './fw-editable-select.component.html'
})

export class EditableSelectComponent implements OnInit, AfterViewInit {
  
  @Input() list: any[];
  @Input() form: FormGroup;
  @Input() field: IFieldDefinition;
  @Input() disabled: boolean;
  @Input() readOnly: boolean;
  @Input() valid: boolean;
  @Input() useContains: boolean;

  @Output() public selectEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('editableSelect', { read: ElementRef }) editableSelect: ElementRef<HTMLInputElement> | any;
  @ViewChild('results', { read: ElementRef }) results: ElementRef<HTMLInputElement> | any;
  @ViewChild('btn', { read: ElementRef }) btn: ElementRef<HTMLInputElement> | any;

  public filteredResults: any[] = [];
  public showEllipsis: boolean = true;
  public visible: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showFilteredResults: boolean = false;
  subscription$: Subscription = new Subscription();

  ngOnInit() {
    this.subscription$.add(this.visible.subscribe((visible: boolean) => {
      if (visible) {
        document.addEventListener('mousedown', this.onClickOutside);
      } else {
        document.removeEventListener('mousedown', this.onClickOutside);
      }
    }));
  }

  ngAfterViewInit() {
    const val = this.editableSelect.nativeElement.value;
    if (val) {
      var obj = this.list.find(x => x[this.field.selectListDefinition.labelProperty] === val);
      if(obj) this.emitSelection(obj, this.editableSelect.nativeElement);
    }
  }

  public focus(): void {
    if (this.disabled || this.readOnly) return;
    this.showEllipsis = false;
    this.editableSelect.nativeElement.focus();
  }

  public initList(): void {
    if (this.readOnly || this.disabled) return;
    this.visible.next(!this.visible.value);
    this.showFilteredResults = false;
    if (!this.visible.value) this.emitSelection(this.editableSelect.nativeElement.value || '');
  }

  public emitSelection(val: any, input: HTMLInputElement = null): void {
    this.visible.next(false);
    this.showEllipsis = true;
    this.form.controls[this.field.key].setValue(val);
    if (input) this.editableSelect.nativeElement.value = val[this.field.selectListDefinition.labelProperty];
    if (this.selectEvent) this.selectEvent.emit(val);
  }

  public filterList(evt: Event, val: string): void {
    if (evt['key'] === 'Enter') {
      this.emitSelection(val);
      return;
    }
    this.showFilteredResults = val.length > 0;
    if (!this.showFilteredResults) return;
    if (!this.visible.value) this.visible.next(true);
    let i: number = 0;
    const list: any[] = this.list,
      len: number = list.length,
      charLen: number = val.length,
      uppercased: string = val.toUpperCase(),
      arr: string[] = [],
      labelProp: string = this.field.selectListDefinition.labelProperty;
    if (this.useContains) for (; i < len; i++) if (list[i][labelProp].toUpperCase().includes(uppercased)) arr.push(list[i]);
    if (!this.useContains) for (; i < len; i++) if (list[i][labelProp].toUpperCase().substring(0, charLen) === uppercased) arr.push(list[i]);
    if (!arr.length && this.visible.value) this.visible.next(false);
    this.filteredResults = arr;
  }

  onClickOutside = (evt: Event): void => {
    if (!this.results) return;
    if (this.editableSelect.nativeElement.contains(evt.target) || this.results.nativeElement.contains(evt.target) || this.btn.nativeElement.contains(evt.target)) return;
    this.emitSelection(this.editableSelect.nativeElement.value);
  }

}
