/** Common services for contact manager components **/
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

//interfaces
import { ISanitizedAddress } from '../../portals/view-models';
import * as fromStore from '../../../fw/dynamic-list/store/index';
import { ContactConstantsService } from './contact-constants.service';
import { ContactUtilityService } from './contact-utility.service';

export interface ISuggestedAddress {
  use: boolean;
  override: boolean;
  reason: string;
}

@Injectable()
//DO NOT MAKE THIS SERVICE A SINGLETON: if you have a method that can be
//used as a static, put in contactUtilityService
export class ContactManagerService   {
  contactForm: FormGroup;
  _verifyResults: ISanitizedAddress = null;

  constructor(public contactConstantsService: ContactConstantsService,
    public contactUtilityService: ContactUtilityService,
    public store: Store<fromStore.IAllDynamicData> ) { }

  contactType(contactTypeId: number): string {
    switch (contactTypeId) {
      case this.contactConstantsService.contactTypeIdBranch:
        return this.contactConstantsService.contactTypeBranch;
      case this.contactConstantsService.contactTypeIdCustomer:
        return this.contactConstantsService.contactTypeCustomer;
      case this.contactConstantsService.contactTypeIdInstaller:
        return this.contactConstantsService.contactTypeInstaller;
      case this.contactConstantsService.contactTypeIdProviderLocation:
        return this.contactConstantsService.contactTypeProviderLocation;
      default:
        return this.contactConstantsService.contactTypeCustomer;
    }
  }

  public get verifyResults(): ISanitizedAddress {
    return this._verifyResults;
  }

  public set verifyResults(value: ISanitizedAddress) {
    this._verifyResults = value;
  }

  //strip off the string part of the form group name and will just have a number;
  getIndex(prefix: string, formGroupName: string) {
    return +formGroupName.replace(prefix, '');
  }

  canIDelete(canIManage: boolean, mechanism: string, row: any): boolean {
;    if (!canIManage || !row ) {
      return false;
    }
    const key = this.contactUtilityService.getEntityKey(mechanism);
    if ( row[key] === 0 ) {
      return true;
    }
    let metaData = row.hasOwnProperty('metaData') ? row['metaData'] : null;
    if (!metaData) {
      return false;
    }
    if (metaData && !metaData.hasOwnProperty('crud'))
      return false;
    else if (!metaData.crud.hasOwnProperty('deleteUrl'))
      return false;

    return metaData.crud.deleteUrl !== '';
  }

  getDeleteUrl(mechanism: string, row: any): string {
    if (!row) {
      return '';
    }
    const key = this.contactUtilityService.getEntityKey(mechanism);
    if (row[key] === 0) {
      return '';
    }

    if (!row.metaData.crud.deleteUrl || row.metaData.crud.deleteUrl.length <= 0) {
      return '';
    } else {
      if (row.metaData.crud.deleteUrl && row.metaData.crud.deleteUrl.length > 0) {
        return row.metaData.crud.deleteUrl;
      }
    }
  }

  setDirty(): void {
    if (this.contactForm && this.contactForm.controls['headerForm']) {
      this.contactForm.markAsDirty();
      this.contactForm.controls['headerForm'].markAsDirty();
    }
  }

}
