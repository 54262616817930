export interface ICommunicationEventType {
  communicationEventTypeId: number;
  provider_providerId: number;
  eventTypeName: string;
}

export class CommunicationEventType implements ICommunicationEventType {
  communicationEventTypeId: number;
  provider_providerId: number;
  eventTypeName: string;

  constructor() {
    this.communicationEventTypeId = 0;
    this.provider_providerId = null;
    this.eventTypeName = '';
  }

}

