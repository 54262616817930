import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DomainObjectService } from '../../../../../shared/services/domain-object.service';
import { ProjectService } from '../../services/project.service';
import { IResponseBase } from '../../../../../shared/interfaces/index';

export interface syncTask {
  statusCode: string;
  title: string;
  status: string;
  statusText: string;
}

@Component({
  selector: 'project-purchase-order-sync',
  templateUrl: './project-purchase-order-sync.component.html'
})
export class ProjectPurchaseOrderSyncComponent implements OnInit, OnDestroy {
  public syncTasks: syncTask[] = [
    { statusCode: 'poStat', title: 'Purchase Order Status', status: null, statusText: 'Synchronizing with Home Deopt...' },
    { statusCode: 'poTot', title: 'Purchase Order Totals', status: null, statusText: 'Checking with Home Depot...' },
    { statusCode: 'poSched', title: 'Purchase Order Schedule Dates', status: null, statusText: 'Synchronizing with Home Depot...' },
    { statusCode: 'poNote', title: 'Purchase Order Notes', status: null, statusText: 'Synchronizing with Home Depot...' },
    { statusCode: 'poNew', title: 'New Purchase Orders', status: null, statusText: 'Checking with Home Depot...' },
    { statusCode: 'poFlex', title: 'Update External Data', status: null, statusText: 'Updating with Home Depot...' }
  ];
  projectId: number;
  count: number = 0;
  subscription: Subscription = new Subscription();

  constructor(public dos: DomainObjectService,
    public projectService: ProjectService,
    public activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get("id");
    });
    this.syncPurchaseOrders();
  }

  syncPurchaseOrders(): void {
    let i: number = 0;
    const syncTasks: syncTask[] = this.syncTasks,
      len: number = syncTasks.length,
      data: FormData = new FormData();
      data.append("projectId", this.projectId.toString());
      for (; i < len; i++) this.doTask(syncTasks[i], data);
  }

  doTask(syncTask: syncTask, data: FormData): void {
    data.set("externalSyncCode", syncTask.statusCode);
    this.subscription.add(this.dos.createByMethodWithForm("Project", "ExternalSync", data).subscribe((result: IResponseBase) => {
      if (result.success) {
        syncTask.status = "check-circle";
        syncTask.statusText = result.message;
      } else {
        syncTask.status = "times-circle";
        syncTask.statusText = !result.errorData[0] ? 'Error Processing Request.' : result.errorData[0]['value'][0];
      }
      if (++this.count === this.syncTasks.length) {
        this.projectService.dispatchPurchaseOrders('project-purchase-order-sync', Number(this.projectId), true);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
