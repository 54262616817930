import { Component, Input, OnInit,  ChangeDetectionStrategy,  Inject, OnDestroy, ViewChild, OnChanges, SimpleChanges, AfterViewInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable} from 'rxjs';
import {  filter, map  } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';


import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { GenericObjectData, IErrorData } from '../../../../shared/interfaces/index';
import { IFormDefinition, FormDefinition } from '../../../dynamic-forms/index';
import {  IFieldDefinition } from '../../../dynamic-forms/index';
import { IDetailTab } from '../../interfaces/i-detail-tab';
import { TabsComponent } from '../../../fw-shared/components';
import { IPortalTab } from '../../../../app/portals/view-models';
import { IDetailContainerConfig } from '../../interfaces';
import { IListDefinition } from '../../../dynamic-list/interfaces';

//store actions and reducers
import * as fromStore from '../../../dynamic-list/store/index';
import * as fromMetaData from '../../../dynamic-list/store/reducers/meta-data.reducer';
import { IDynamicListState, IListObjectData }from '../../../dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromDynamicList from '../../../dynamic-list/store/selectors/dynamic-list.selectors';
import * as DynamicListActions from '../../../dynamic-list/store/actions/dynamic-list.actions';
import { DynamicDetailService } from '../../services/dynamic-detail.service';


@Component({
  selector: 'fw-dynamic-detail-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dynamic-detail-container.component.html'
})

//Wrapper Requirements:
//  •	Display title: always - needs store data
//•	Support tabs: as applicable
//o	Needs tab config
//o	Need to have a router outlet
//•	Support cancel/close: always
//o	Needs operation type
//•	Support Save/Update: optional
//o	Needs operation type
//•	Support prev/next
//o	Needs form definition
export class DynamicDetailContainerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() detailConfig: IDetailContainerConfig;

  @ViewChild('detailTabs') public tabComponent: TabsComponent;

  public tabsFileName: string = '';
  public listDefinition: IListDefinition;
  public objectData$: Observable<GenericObjectData>;
  public entityLabel$: BehaviorSubject<string> = new BehaviorSubject('');
  public formDefinition$: BehaviorSubject<IFormDefinition> = new BehaviorSubject(null);
  public authorizedTabs$: BehaviorSubject<any[]> = new BehaviorSubject(null);;
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public fieldDefinitions: IFieldDefinition[] = [];
  subscription: Subscription = new Subscription();
  lblSubscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public router: Router,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public detailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.tabsFileName = this.activeRoute.snapshot.data['tabsFileName'];
    this.errorData$.next([]);
    this.working$.next(false);
    this.formDefinition$.next(null);
    this.setTabs();
    this.setParentListeners();
    this.setChildListeners();
    this.setLocalListeners();
  }

  ngAfterViewInit(): void {
    if (this.detailConfig.useRouterOutlet) {
      this.defaultToDetail();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['detailConfig'] && !(changes['detailConfig'].isFirstChange())) {
      const prevVal: IDetailContainerConfig = changes['detailConfig'].previousValue ? changes['detailConfig'].previousValue : -1;
      const newVal: IDetailContainerConfig = changes['detailConfig'].currentValue;
      if ((prevVal.id !== newVal.id) || (prevVal.operation !== newVal.operation)) {
        this.setChildListeners();
      }
      if ((prevVal.showNext !== newVal.showNext) || (prevVal.showPrev !== newVal.showPrev)) {
        this.setFormDefinition();
      }
    }
  }

  ngOnDestroy(): void {
    this.lblSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.store.dispatch(new fromStore.ClearErrorsList({ storeName: this.detailConfig.storeName, parentId: this.detailConfig.parentId }));
  }


  public onCancel(event: Event) {
    this.store.dispatch(new DynamicListActions.ClearErrorsList({ storeName: this.detailConfig.storeName, parentId: this.detailConfig.parentId }));

    let emitter: IHomEventEmitter = { requestor: this.listDefinition.detailRoutePath, event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public onNavigate(action: string) {
    let emitter: IHomEventEmitter = {
      requestor: this.listDefinition.detailRoutePath,
      event: this.myConstants.emitterEventNavigation,
      action: action,
      data: null
    };
    this.emitterService.emitListEvent(emitter);
  }

  //Set Authorized Tab Visibility
  setTabs(): void {
    let tabs: IPortalTab[];
    this.authorizedTabs$.next(tabs);
    const tabDef: IDetailTab = this.tabsFileName && this.detailService.tabDefinitions && this.detailService.tabDefinitions.length
      ? this.detailService.tabDefinitions.find(x => x.tabName.toLowerCase() === this.tabsFileName.toLowerCase())
      : null;
    if (tabDef) {
      tabs = tabDef.tabs;
    }
    if (tabs) {
      this.setTabProperties(tabs);
    }
  }

  setTabProperties(tabs: IPortalTab[]) {
    let finalTabs: IPortalTab[] = [];
    finalTabs.push(tabs[0]);

    if (this.tabsFileName !== '' && this.detailConfig.operation === this.myConstants.operationTypeDetails) {
      //append the current active detail id to the route(s)
      tabs.forEach((tab, index) => {
        if (index !== 0) {
          //detail wrapper, so will always need the active Id in the route
          tab = { ...tab, routeName: tab.routeName.concat('/', this.detailConfig.id.toString()) };
          finalTabs.push(tab);
        }
      });
    }
    this.authorizedTabs$.next(finalTabs);
  }

  setParentListeners(): void {
    //Listen for Events and Errors
    //Subscribe at lower level not seeing change in store object
    let counter: number = 0;
    this.subscription.add(this.store.pipe(select(fromStore.getListByType(this.detailConfig.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.detailConfig.parentId)))
      .subscribe((state: IListObjectData) => {
        const listStore = cloneDeep(state);
        if (listStore) {
          if (listStore.event && (!listStore.errorData || !listStore.errorData.length)) {
            let event: IHomEventEmitter = cloneDeep(listStore.event);
            this.store.dispatch(new fromStore.ClearEventList({ storeName: this.detailConfig.storeName, parentId: this.detailConfig.parentId }));
            event.data = counter.toString();
            this.emitterService.emitListEvent(event);
          }
          if (listStore.working) {
            this.working$.next(listStore.working);
          }
          //object level errors
          this.errorData$.next(cloneDeep(listStore.errorData));
          //}
        }
      }));

    this.subscription.add(this.store.pipe(select(fromStore.getMetaDataByType(this.detailConfig.storeName)))
      .pipe(filter((state: fromMetaData.IMetaDataState)  => state.fieldDefinitions.length > 0))
      .subscribe((state: fromMetaData.IMetaDataState) => {
          this.fieldDefinitions = state.fieldDefinitions;
        }
      ));

    this.subscription.add(this.store.pipe(select(fromDynamicList.getSelectedParentListDefinition(this.detailConfig.storeName, this.detailConfig.parentId)),
        filter(listDefinition => listDefinition !== null))
      .subscribe(listDefinition => {
        this.listDefinition = cloneDeep(listDefinition);
        this.setFormDefinition();
        }
    ));
  }

  setChildListeners(): void {
    this.lblSubscription.unsubscribe();

    this.lblSubscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.detailConfig.storeName, this.detailConfig.parentId, this.detailConfig.key, this.detailConfig.id)),
      map(entity => {
        return entity === undefined && this.detailConfig.operation === this.myConstants.operationTypeCreate ? new GenericObjectData() : entity
      }))
      .subscribe((entity) => {
        this.entityLabel$.next(this.detailConfig.operation === this.myConstants.operationTypeCreate ? '' : entity ? entity['entityLabel'] : '');
      })
    );
  }


  setLocalListeners(): void {
    this.subscription.add(this.emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventClose:
            this.emitterService.emitListEvent(e);
            break;
          default:
            break;
        }
      }));

    this.subscription.add(this.entityLabel$
      .subscribe((val) => {
        if (val && this.detailConfig.useRouterOutlet) {
          this.defaultToDetail();
        }
      }));
  }

   defaultToDetail() {
    if (this.authorizedTabs$.getValue() && this.detailConfig.operation !== this.myConstants.operationTypeCreate && !this.tabComponent) {
      return;
    }
    this.router.navigate(['detail'], { relativeTo: this.activeRoute });
  }

  setFormDefinition(): void {
    this.formDefinition$.next(new FormDefinition(this.listDefinition.objectLabel + ' ' + (this.detailConfig.operation || this.myConstants.operationTypeDetails),
      this.detailConfig.showPrev,
      this.detailConfig.showNext,
      this.listDefinition.detailRoutePath,
      '',
      '',
      this.detailConfig.customButtons));
  }

}
