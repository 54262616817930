import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IUploadFile } from '../../interfaces';
import { FileUploadType } from '../../enums/file-upload.enums';
import { IInputButton } from '../../../../fw/fw-shared/interfaces';
import { FileUploaderService } from '../../services/file-uploader.service';
import { IEntityDocument } from '../../../portals/view-models/index_two';

@Component({
  selector: 'file-preview-item',
  templateUrl: './file-preview-item.component.html'
})

export class FilePreviewItemComponent implements OnInit, OnChanges {
  @Input() uploadFile: IUploadFile;
  @Input() listType: string;
  @Input() entityDocument: IEntityDocument;

  @Output() uploadEvent = new EventEmitter<IUploadFile>();
  @Output() deleteEvent = new EventEmitter<IUploadFile>();
  @Output() changeEvent = new EventEmitter<IUploadFile>();

  public deleteMode: boolean = false;
  public rotateValue$: BehaviorSubject<number> = new BehaviorSubject(0);
  public uploadDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public imageCss$: BehaviorSubject<string> = new BehaviorSubject('');
  public caption: string = '';
  public canIncludeInWOPacket: boolean = true;
  public includeInWorkOrderPacket: boolean = false;
  public entityId: number;
  public showEntityIdInput: boolean = false;
  public entityIdPlaceHolder: string = 'Entity Id...';
  public cbConfig: IInputButton;

  constructor(
    public fus: FileUploaderService) { }

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['uploadFile'] && !(changes['uploadFile'].firstChange)) {
      this.initComponent();
    }
  }

  public rotate(): void {
    const currentValue: number = this.rotateValue$.value;
    this.rotateValue$.next(currentValue + (currentValue < 4 ? 1 : 1));
  }

  public changeCaption(caption: string): void {
    this.caption = caption;
  }

  public changeInclude(value: boolean): void {
    this.includeInWorkOrderPacket = value;
  }

  public uploadItem(): void {
    let fileOut: IUploadFile = cloneDeep(this.uploadFile);
    fileOut.imageCaption = this.caption;
    fileOut.rotateClockwise = this.rotateValue$.value;
    fileOut.entityId = this.entityId;
    fileOut.includeInWorkOrderPacket = this.includeInWorkOrderPacket;
    this.uploadEvent.emit(fileOut);
  }

  public removeItem(): void {
    if (this.deleteMode) {
      this.deleteEvent.emit();
    } else {
      this.deleteMode = true;
    }
  }

  public cancelDelete(): void {
    this.deleteMode = false;
  }

  public setEntityId(val: number): void {
    this.entityId = val;
    if (this.uploadFile.errorData) {
      this.changeEvent.emit(this.uploadFile);
    }
    this.uploadDisabled$.next(!this.showEntityIdInput ? false : this.entityId > 0 ? false : true);
  }

  initComponent(): void {
    this.showEntityIdInput = this.listType === FileUploadType.emailFileImport;
    this.entityIdPlaceHolder = this.listType === FileUploadType.emailFileImport ? 'Project Id...' : 'Entity Id...';
    this.deleteMode = false;
    this.uploadFile.rotateClockwise = this.rotateValue$.value;
    this.caption = this.caption || this.uploadFile.name;
    this.includeInWorkOrderPacket = this.entityDocument
      ? this.entityDocument.includeInWorkOrderPacket
      : this.includeInWorkOrderPacket;
    this.cbConfig = {
      label: {
        label: 'Include in Work Order Packet',
        alignRight: true,
        title: 'Include in Work Order Packet'
      }
    };
    this.canIncludeInWOPacket = this.entityDocument
      ? false
      : this.fus.validateSelected(this.uploadFile.file ? this.uploadFile.file.type : this.uploadFile.fileType, this.fus.woPacketAllowedFileTypes);
    this.uploadDisabled$.next(!this.showEntityIdInput ? false : this.entityId > 0 ? false : true);
  }
}
