/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, ISearchTerm, ListFilter } from '../../../../../fw/dynamic-list/interfaces/index';
import { WarrantyDashEvent } from '../../enums/warranty-dashboard.enums';
import * as fromRoot from '../../../../store/reducers/index';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { IListMetaData } from '../../../../../shared/interfaces';
import { IWarrantySubType } from '../../../view-models';
import { getSelectionListDataByType, GetEntityListById, SetEntityList} from '../../../../../shared/store/selectionLists/index';
import { WarrantyDashboardService } from '../../services/warranty-dashboard.service';
import { getListByType, IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store';

@Component({
  selector: '/unassigned-warranties',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './unassigned-warranties.component.html'
})
export class UnassignedWarrantiesComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;
  public metaData: IListMetaData;
  public subscription: Subscription = new Subscription();

  wstSub: Subscription;

  constructor(
    public warrantyDashboardService: WarrantyDashboardService,
    public store: Store<fromFeature.IAllDynamicData>,
    public rootStore: Store<fromRoot.IState>
  ) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case WarrantyDashEvent.showAssignUser: 
        this.warrantyDashboardService.showAssignUser(event.data);
        break;
    }
  }

  ngOnInit() {
    this.listDefinition = this.warrantyDashboardService.loadUnassignedWarrantiesListDefinition();
  }

  public onFilterChange(e: IHomEventEmitter): void {
    if (e.action === 'warrantyType' && e.data && e.data.hasOwnProperty('searchTerm')) {
      const searchTerm: ISearchTerm = e.data['searchTerm'];
      const warrantyTypeId: number = searchTerm.value && searchTerm.value.length ? searchTerm.value[0] : 0;
      if (warrantyTypeId > 0) {
        this.getWarrantySubTypes(warrantyTypeId);
      }
    }
  }

  listenForWarrantyTypeChanges(): void {
    this.subscription.add(this.store.pipe(select(getListByType(this.listDefinition.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.listDefinition.parentId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.working) {
          return;
        }
        const storeData = cloneDeep(state);
        if (storeData && storeData.listFilter) {
          const newListFilter = cloneDeep(storeData.listFilter);
          const warrantyTypeNameTerm: ISearchTerm = newListFilter.searchTerm
            ? newListFilter.searchTerm.find(x => x.term === 'warranty_warrantyType_warrantyTypeId')
            : null;
          if (warrantyTypeNameTerm && warrantyTypeNameTerm.value && warrantyTypeNameTerm.value.length > 0) {
            this.getWarrantySubTypes(warrantyTypeNameTerm.value[0]);
          }
        }
      }));
  }

  getWarrantySubTypes(id: number): void {
    let dispatched: boolean = false;
    let genericSet: boolean = false;

    if (this.wstSub) {
      this.wstSub.unsubscribe();
    }
    this.wstSub = this.rootStore.pipe(select(getSelectionListDataByType('warrantySubType', id)))
      .subscribe((data) => {
        if (data && !genericSet) {
          genericSet = true;
          this.setGenericSubListStore(data);
        } else if (!data && !dispatched) {
          //init generic sub list store (parentId -1)
          dispatched = true;
          this.setGenericSubListStore([]);
          let listFilter = new ListFilter();
          listFilter.getAll = true;
          this.store.dispatch(new GetEntityListById('WarrantySubType', 'ByWarrantyType', id, listFilter, 'warrantySubType'));
        }
      });
  }

  setGenericSubListStore(data: IWarrantySubType[]): void {
    this.store.dispatch(new SetEntityList({
      storeName: 'warrantySubType',
      parentId: -1,  //this parentId is what filter form listens for to know the active values.
      data: data,
      listMetaData: null,  //only needed if allowing paging of selection list items
      errorData: null
    }));

  }

  ngOnDestroy(): void {
    if (this.wstSub) {
      this.wstSub.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
