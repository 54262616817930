//import { ActivatedRouteSnapshot, RouterStateSnapshot, Params } from '@angular/router'
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
//import * as fromRouter from '@ngrx/router-store';

import * as fromAuth from '../../../auth/store/auth.reducer';
import * as fromSelectionLists from '../../../shared/store/selectionLists/selectionLists.reducer';
import * as fromLoadingIndicator from '../../../shared/store/loadingIndicator/loadingIndicator.reducers';
import { environment } from '../../../environments/environment'; //points to a file that holds a boolean constant - production with a value of false for dev;


export interface IState {
  auth: fromAuth.IState;
  selectionLists: fromSelectionLists.IStoreState;
  loadingIndicator: fromLoadingIndicator.IState;
};

export const rootReducers: ActionReducerMap<IState> = {
  auth: fromAuth.authReducer,
  selectionLists: fromSelectionLists.selListReducer,
  loadingIndicator: fromLoadingIndicator.loadingIndicatorReducer,
};

export const metaReducers: MetaReducer<IState>[] = !environment.production ? []: [];

export const getAuthState = createFeatureSelector<fromAuth.IState>('auth');
export const getSelectionListsState = createFeatureSelector<fromSelectionLists.IStoreState>('selectionLists');
export const getLoadingIndicatorState = createFeatureSelector<fromLoadingIndicator.IState>('loadingIndicator');



