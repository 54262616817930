import { Component, OnInit,  OnDestroy, Inject, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IProviderUserPrivilegeViewModel } from '../../../view-models';
import { PrivilegeRuleEntitySpecifierType, AdminEvent } from '../../enums/admin.enums';
import { AdminSecurityService } from '../../services/admin-security.service';

@Component({
  selector: 'user-locations',
  templateUrl: './user-locations.component.html'
})
export class UserLocationsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() userLocations: IProviderUserPrivilegeViewModel[];
  @Input() userBranches: IProviderUserPrivilegeViewModel[];
  @Input() specifierType: number;
  @Input() isWorkingLoc: boolean;
  @Input() isWorkingBranch: boolean;
  @Input() haveAllLocations: boolean;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public title: string;
  public orphanTitle: string;
  public workingLocations$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  public orphanBranches$: BehaviorSubject<IProviderUserPrivilegeViewModel[]> = new BehaviorSubject([]);
  
  requestor: string = 'user-locations';
  subscription: Subscription = new Subscription();

  constructor(public adminService: AdminSecurityService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onEvent(event: IHomEventEmitter) {
    let eventOut: IHomEventEmitter = cloneDeep(event);
    eventOut.action = this.specifierType.toString();

    this.customEvent.emit(eventOut);
  }

  public onLocationDelete(event: IHomEventEmitter) {
    let eventOut: IHomEventEmitter = cloneDeep(event);
    eventOut.action = this.specifierType.toString();
    const data = eventOut.data as IProviderUserPrivilegeViewModel;

    //For location deletes, also delete branches, unless branch is associated to multiple parents.
    var item: IProviderUserPrivilegeViewModel = this.workingLocations$.value.find(x => x.entityId == data.entityId);
    let branchesToDelete: IProviderUserPrivilegeViewModel[] = [];
    if (item && item.workingBranches && item.workingBranches.length) {
      item.workingBranches.forEach(branch => {
        if (branch.parentEntityId && branch.parentEntityId.length === 1) {
          branchesToDelete.push(branch);
        }
      });
    }

    if (branchesToDelete.length > 0) {
      this.customEvent.emit({ requestor: this.requestor, event: AdminEvent.deleteUserAccess, action: this.specifierType.toString(), data: branchesToDelete });
    }

    //now emit the location delete
    this.customEvent.emit(eventOut);
  }

  ngOnInit() {
    const type: string = this.specifierType === PrivilegeRuleEntitySpecifierType.warrantyBranch ? 'Warranty'
      : this.specifierType === PrivilegeRuleEntitySpecifierType.primaryProviderLocation ? 'Primary' : 'Secondary';
    this.title = type.concat(' Location');
    this.orphanTitle = 'Orphan '.concat(type, ' Branch');
    this.setWorkingData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['userLocations'] && !(changes['userLocations'].firstChange))
      || (changes['userBranches'] && !(changes['userBranches'].firstChange))) {
      this.setWorkingData();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setWorkingData(): void {
    if (this.specifierType === PrivilegeRuleEntitySpecifierType.warrantyBranch) {
      this.workingLocations$.next(cloneDeep(this.userLocations));
    } else if (this.userLocations && this.userLocations.length > 0 && this.userBranches && this.userBranches.length > 0) {
      this.workingLocations$.next(this.adminService.setWorkingBranches(this.userLocations, this.userBranches));
      this.orphanBranches$.next(this.adminService.setOrphanBranches(this.userLocations, this.userBranches));
    }
  }

}
