
export enum AdminDashType {
  unassignedProgram = 'unassignedProgram',
  unassignedBranch = 'unassignedBranch',
  unassignedSku = 'unassignedSku',
  unassignedMeasure = 'unassignedMeasure',
  erroredLineItem = 'erroredLineItem'
}

export enum AdminDashCountType {
  unassignedProgramCount = 'unassignedProgramCount',
  unassignedBranchCount = 'unassignedBranchCount',
  unassignedSkuCount = 'unassignedSkuCount',
  unassignedMeasureCount = 'unassignedMeasureCount',
  erroredLineItemCount = 'erroredLineItemCount'
}

export enum AdminDashTabLabel {
  unassignedPrograms = 'Program Mappings',
  unassignedBranches = 'Branch Mappings',
  unassignedSkus = 'Sku Mappings',
  unassignedMeasures = 'Unit of Measure Mappings',
  erroredLineItems = 'Errored Line Items'
}

export enum AdminDashStore {
  dashProgamServiceMapping = 'dashProgamServiceMapping',
  dashBranchMapping = 'dashBranchMapping',
  dashSkuMapping = 'dashSkuMapping',
  dashUnitMeasureMapping = 'dashUnitMeasureMapping',
  dashWorkOrderSurveys = 'dashWorkOrderSurveys',
  dashWorkOrderSurveyResponses = 'dashWorkOrderSurveyResponses'
}

export enum AdminDashEvent {
  createSku = 'createSku',
  goToSku = 'goToSku',
  launchSurveyImport = 'launchSurveyImport'
}
