import { Injectable} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, map, filter , first } from 'rxjs/operators';

import { IListFilter } from '../../../fw/dynamic-list/interfaces/index';
import { IDocumentType } from '../view-models';

//store actions and reducers
import * as fromStore from '../../../fw/dynamic-list/store/index';
import * as fromSelectionLists from '../../../shared/store/selectionLists/index';
import { AccessLevel } from '../../../fw/dynamic-list/enums/access-level.enums';

@Injectable()
export class ProjectEntityResolver  {

  listFilter: IListFilter = {
    isLookup: false,
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true, //do not want paged data
    currentPage: 1,
    searchTerm: [],
    orderTerm: []
  };

  constructor(public selectionListStore: Store<fromSelectionLists.IStoreState>,
    public store: Store<fromStore.IAllDynamicData>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IDocumentType[]> {
    this.requestData();
    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<IDocumentType[]> {
    return this.selectionListStore.select(fromSelectionLists.getSelectionListDataByType('projectEntity', -1))
      .pipe(
        filter((data: any[]) => data !== null),
        map((data: any[]) => { return data; }),
        first()
      );
  }

  requestData(): void {
    this.selectionListStore.select(fromSelectionLists.getSelectionListDataByType('projectEntity', -1))
      .pipe(take(1))
      .subscribe((data: any[]) => {
        if (data === null) {
          this.store.dispatch(new fromSelectionLists.GetEntityListByMethodParams('Entity',  'ProjectEntities', '', this.listFilter, 'projectEntity'));
        }
      });
  }
}
