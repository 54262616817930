<form [formGroup]="form" novalidate class="form-wrapper">
  <fw-dynamic-field [field]="mds.getFieldDefinition('payoutName')"
                    [form]="form"
                    [operation]="operation">
  </fw-dynamic-field>
  <fw-dynamic-field [field]="mds.getFieldDefinition('controlsCapacity')"
                    [form]="form"
                    [operation]="operation">
  </fw-dynamic-field>
  <div class="app-two-col">
    <fw-dynamic-field [field]="mds.getFieldDefinition('payoutStatus')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="mds.getSelectItems('payoutStatus')">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('unitMeasure')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="mds.getSelectItems('unitMeasure')">
    </fw-dynamic-field>
  </div>
  <div class="app-two-col">
    <fw-dynamic-field [field]="mds.getFieldDefinition('glCodeOverride')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field class="sku-payouts--bool"
                      [field]="mds.getFieldDefinition('serviceCodeOverride')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
  </div>
</form>
