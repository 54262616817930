import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomPipesModule } from 'hom-lib/hom-pipes';
import { FwModule } from '../../../fw/fw.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../shared/shared.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { PoImportModule } from '../po-import/po-import.module';
import { PoImportHeaderResolver } from '../po-import/resolvers/po-import-header-resolver.service';
import { FileUploadModule } from '../../file-upload/file-upload.module';
import { SchedulerModule } from '../scheduler/scheduler.module';
import { ContactModule } from '../../contact/contact.module';
import { SmsModule } from "../../sms/sms.module";

import { ProjectRoutingModule } from './project-routing.module';
import { ProjectService} from '../project/services/index';
import { UserPriviledgesService } from '../../../auth/services/index'; 
import { PoImportService } from '../po-import/services/po-import.service';
import { FileViewerService } from '../../file-upload/services/file-viewer.service';
import {
  ContactTypeSpecificationResolver, ContactEmailMetaDataResolver,
  ContactPhoneMetaDataResolver, ContactAddressMetaDataResolver,
  ContactMetaDataResolver
} from '../../contact/resolvers/index';

//resolvers
import {
  SelectionListResolver,
  MetaDataResolver,
  ProviderLocationResolver,
  ProviderLocationServiceListResolver,
  EntityResolver,  CommunicationShortcutResolver,
  ProjectChildrenResolver
} from '../resolvers/index';

import {
  ProjectContactMechanismResolver,
  ProjectInformationResolver,
  WorkOrderInformationResolver,
  PurchaseOrderResolver,
  ProjectChargeBackResolver,
  ProjectNotesResolver
} from './resolvers/index';

//components
import {
  ProjectMarginComponent,
  ProjectCountComponent,
  ProjectWorkOrderCreateComponent,
  ProjectChargeBackCreateComponent,
  WarrantyScriptComponent,
  WarrantyCreateComponent,
  WorkOrderSummaryComponent,
  HomeDepotMeasuresComponent,
   WarrantyGeneralInformationComponent,
  WorkOrderGeneralInformationComponent,
  WorkOrderItemComponent,
  WorkOrderPushComponent,
  PurchaseOrderItemComponent,
  ChargeBackItemComponent,
  WorkOrdersGenerateComponent,
  PurchaseOrderGeneralInformationComponent,
  CrmFormQuestionComponent
} from './components/index';

//containers
import { 
  OriginatingProjectSummaryComponent,
  ProjectContactManagerComponent,
  ProjectFileViewerComponent,
  ProjectDetailSummaryComponent,
  ProjectTasksComponent,
  ProjectHistoryComponent,
  ProjectNotesComponent,
  ProjectCommunicationEventsComponent,
  ChargeBackManagerComponent,
  WorkOrderManagerComponent,
  PurchaseOrderManagerComponent,
  ProjectHighLevelSummaryComponent,
  ProjectCountsContainerComponent,
  ProjectWorkOrdersComponent,
  ProjectPurchaseOrdersComponent,
  ProjectChargeBacksComponent,
  WarrantyContainerComponent,
  ProjectMarginsContainerComponent,
  ProjectPurchaseOrderCreateContainerComponent,
  ProjectWorkOrderCreateContainerComponent,
  WorkOrderPushContainerComponent,
  WarrantyScriptsContainerComponent,
  ProjectCreateContainerComponent,
  ProjectSummaryComponent,
  WarrantyProjectSummaryComponent,
  ProjectPurchaseOrderItemsComponent,
  ProjectWorkOrderItemsComponent,
  ProjectPurchaseOrderItemSummaryComponent,
  PurchaseOrderNotesComponent,
  PurchaseOrderFileUploaderComponent,
  PurchaseOrderMeasuresComponent,
  ProjectUnreceivedInventoryComponent,
  ProjectChargeBackItemsComponent,  ProjectPurchaseOrderSyncComponent,  PurchaseOrderFilesComponent,  PoViewExternalDataComponent,  CommunicationEventActionsComponent,  ProjectComponent,  DeleteReasonNoteComponent,  ProjectAQTChangesComponent,  CrmFormEntryComponent,  CrmFormsManagerComponent  } from './containers/index';

//guards
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';
import { ProjectGuard } from './services/project-guard.service';
import { FileUploaderService } from '../../file-upload/services/file-uploader.service';

@NgModule({
  declarations: [
    ProjectMarginComponent,
    ProjectCountComponent,
    ProjectWorkOrderCreateComponent,
    PurchaseOrderGeneralInformationComponent,
    ProjectChargeBackCreateComponent,
    OriginatingProjectSummaryComponent,
    ProjectContactManagerComponent,
    ProjectFileViewerComponent,
    ProjectDetailSummaryComponent,
    ProjectTasksComponent,
    ProjectHistoryComponent,
    ProjectNotesComponent,
    ProjectCommunicationEventsComponent,
    ChargeBackManagerComponent,
    WorkOrderManagerComponent,
    PurchaseOrderManagerComponent,
    ProjectCountsContainerComponent,
    ProjectWorkOrdersComponent,
    ProjectPurchaseOrdersComponent,
    ProjectChargeBacksComponent,
    WarrantyContainerComponent,
    ProjectMarginsContainerComponent,
    ProjectHighLevelSummaryComponent,
    ProjectPurchaseOrderCreateContainerComponent,
    ProjectWorkOrderCreateContainerComponent,
    WorkOrderPushContainerComponent,
    WarrantyScriptComponent,
    ProjectSummaryComponent,
    WarrantyProjectSummaryComponent,
    WarrantyCreateComponent,
    WorkOrderSummaryComponent,
    HomeDepotMeasuresComponent,
    WorkOrderGeneralInformationComponent,
    WorkOrderItemComponent,
    WorkOrderPushComponent,
    PurchaseOrderItemComponent,
    WarrantyScriptsContainerComponent,
    WarrantyGeneralInformationComponent,
    ProjectCreateContainerComponent,
    ProjectPurchaseOrderItemsComponent,
    ProjectWorkOrderItemsComponent,
    ProjectPurchaseOrderItemSummaryComponent,
    PurchaseOrderNotesComponent,
    PurchaseOrderFileUploaderComponent,
    PurchaseOrderMeasuresComponent,
    ProjectUnreceivedInventoryComponent,
    ChargeBackItemComponent,
    ProjectChargeBackItemsComponent,
    ProjectPurchaseOrderSyncComponent,
    WorkOrdersGenerateComponent,
    PurchaseOrderFilesComponent,
    PoViewExternalDataComponent,
    CommunicationEventActionsComponent,
    DeleteReasonNoteComponent,
    ProjectAQTChangesComponent,
    ProjectComponent,
    CrmFormEntryComponent,
    CrmFormQuestionComponent,
    CrmFormsManagerComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HomPipesModule,
    FwModule,
    FwSharedModule,
    SharedModule,
    PortalSharedModule,
    PoImportModule,
    FileUploadModule,
    ContactModule,
    SchedulerModule,
    SmsModule,
    ProjectRoutingModule
  ],
  providers: [SelectionListResolver,
    ProviderLocationResolver,
    ProviderLocationServiceListResolver,
    IsCurrentGuard,
    ProjectContactMechanismResolver,
    ProjectInformationResolver,
    WorkOrderInformationResolver,
    PurchaseOrderResolver,
    ContactTypeSpecificationResolver,
    ContactEmailMetaDataResolver,
    ContactPhoneMetaDataResolver,
    ContactAddressMetaDataResolver,
    ContactMetaDataResolver,
    CommunicationShortcutResolver,
    FileViewerService,
    FileUploaderService,
    ProjectService,
    PoImportService,
    UserPriviledgesService,
    MetaDataResolver,
    EntityResolver,
    ProjectChargeBackResolver,
    PoImportHeaderResolver,
    ProjectNotesResolver,
    ProjectChildrenResolver,
    ProjectGuard
  ]
})

export class ProjectModule {} 
