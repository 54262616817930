import { Component, OnDestroy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { DomainObjectService } from '../../../../shared/services';
import { FileUploaderService } from '../../services/file-uploader.service';
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { FileUploadType } from '../../enums/file-upload.enums';
import { IResponseBase } from '../../../../shared/interfaces';
import { IFileUploadConfig } from '../../interfaces/i-file-upload-config';
import { ModalSizeType } from '../../../../fw/fw-modal/interfaces/i-modal';
import { AppAction } from '../../../../shared/enums/app-action.enums';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

@Component({
  selector: 'single-file-uploader',
  templateUrl: './single-file-uploader.component.html',
  providers: [FileUploaderService]
})

export class SingleFileUploaderComponent implements OnChanges, OnDestroy {

  constructor(public dos: DomainObjectService,
    public fileUploaderService: FileUploaderService,
    public emitterService: HomEventEmitterService,
    public modalService: ModalService) { }

  @Input() fileUploadType: FileUploadType;
  @Input() objectId: number;
  @Input() icon: string;
  @Input() customCss: string = '';
  @Input() disabled: boolean;
  @Output() afterUploadEvent: EventEmitter<IResponseBase> = new EventEmitter();

  fileUploadConfig: IFileUploadConfig;
  subscription: Subscription = new Subscription();

  public uploadFile(file: File): void {
    if (file) {
      const formData: FormData = new FormData();
      formData.append(this.fileUploadConfig.objKey, this.objectId.toString());
      formData.append(this.fileUploadConfig.fileKey, file);
      this.subscription.add(this.dos.createByMethodWithForm(this.fileUploadConfig.controllerName, this.fileUploadConfig.methodName, formData)
        .subscribe((result: IResponseBase) => {
        this.afterUploadEvent.emit(result);
        if (!result.success) {
          this.modalService.open({
            title: 'Upload Failed',
            path: 'app-action/' + AppAction.uploadFailed,
            sizeType: ModalSizeType.xsmall,
            castExit: true,
            hasTabs: false,
            isSubModal: this.modalService.opened
          });
        } 
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fileUploadType']) {
      this.fileUploadConfig = this.fileUploaderService.fileUploadConfigs.find(x => x.uploadType === this.fileUploadType);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
