<div class="portal">
  <div class="portal__detail--item">
    <fw-dynamic-list *ngIf="listDefinition"
                     [listTitle]="'Self Service Scheduling Branches'"
                     [vmListDefinition]="listDefinition"
                     [infoText]="'Highlighted rows are associated with a General Contractor or Program or Service who is excluding from SSS.'"
                     (filterEvent)="onFilterChange($event)">
    </fw-dynamic-list>
  </div>
</div>
