/*A child component for Installer and Technician Dashboard*/
import { Component, OnInit, Input,  SimpleChanges, OnChanges } from '@angular/core';

import { InstallerStore } from '../../enums/installer.enums';
import { IListColumn, ListColumn, IListDefinition, IListFilter, ListFilter, IListButtonType, OrderTerm }
  from '../../../../../fw/dynamic-list/interfaces/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'installer-work-crew-technician-roles',
  templateUrl: './installer-work-crew-technician-roles.component.html'
})
export class InstallerWorkCrewTechnicianRolesComponent implements OnInit, OnChanges {
  @Input() installerWorkCrewTechnicianId: number;
  @Input() providerUserId: number;
  @Input() canIEdit: boolean = false;

  listDefinition: IListDefinition;
  title: string = 'Installer Portal Technician Roles';

  constructor(public dynamicListService: DynamicListService) { }

  ngOnInit() {
    this.loadListDefinition();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['installerWorkCrewTechnicianId'] && !(changes['installerWorkCrewTechnicianId'].firstChange)) {
      this.loadListDefinition();
    }
  }

  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Installer Portal Technician Role';
    const listObjectController = 'UserRoleViewModel';
    const listStoreName = InstallerStore.technicianRoles;
    const detailRoutePath = 'technician-role';
    const listRowKeyId = 'providerRoleId';
    const detailRouterOutlet = 'techRoleOutlet';
    const defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.orderTerm = [new OrderTerm('providerRoleName')];

    let listDefinition = this.dynamicListService.createListDefinition(detailRouterOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = this.installerWorkCrewTechnicianId;
    listDefinition.parentKey = 'installerWorkCrewTechnicianId';
    listDefinition.controllerMethod = 'ByUser';
    listDefinition.methodParameters = this.providerUserId.toString();
    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;
    listDefinition.revokeSort = true;

    let enabledDefaults: ICustomButton = {
      title: '', icon: '', cssName: '', enabled: this.canIEdit,
      enabledMethod: 'canDeleteRole', eventName: '',
      methodService: 'complianceDynamicListService'
    };
    if (this.canIEdit) {
     let crudButtons: IListButtonType[] = [
       { type: ButtonType.delete, defaults: enabledDefaults }
      ];

      listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
      enabledDefaults.enabled = this.providerUserId > 0; 
      enabledDefaults.methodService = enabledDefaults.enabledMethod = '';
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([
        {
          type: ButtonType.create,
          defaults: enabledDefaults
        }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);
   }
    this.listDefinition = listDefinition; //set at end to signify ready to instantiate the list.
  }

  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('providerRoleName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
