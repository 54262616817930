<div class="fw-info-cast" *ngIf="isVisible" (click)="toggle($event)"></div>

<ng-template #info>
  <div class="fw-info">
    <div *ngIf="infoTitle" class="fw-info__title">{{ infoTitle }}</div>
    <div class="fw-info__content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>

<button fw-popover
        [template]="info"
        [showOnHover]="!isMobileOrTablet"
        [closeOnClickOutside]="isMobileOrTablet"
        [arrowColor]="'#ddd'"
        type="button"
        class="app-btn-icon fw-info--icon {{ customCss }}"
        [ngStyle]="{'color': customColor}"
        [title]="''"
        (click)="toggle($event)">
  <i class="{{ icon }}"></i>
</button>
