import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { IChargeBack } from '../../../view-models';
import { IErrorData } from '../../../../../shared/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IKey, DeleteObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IListObjectData, IDynamicListState } from '../../../../../fw/dynamic-list/store';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { ProjectEvent } from '../../enums/project.enums';
import { ProjectService } from '../../services/project.service';
import { UserPriviledgesService } from '../../../../../auth/services/index';
import { SharedDashboardService } from '../../../portal-shared/services/shared-dashboard.service';

@Component({
  selector: 'project-charge-backs',
  templateUrl: './project-charge-backs.component.html'
})
export class ProjectChargeBacksComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() canIEdit: boolean;
  @Input() chargeBacks: IChargeBack[] = [];

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subscription = new Subscription();

  constructor(
    public projectService: ProjectService, 
    public store: Store<fromFeature.IAllDynamicData>,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public sharedDashboardService: SharedDashboardService) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case ProjectEvent.printChargeBackNotice:
        this.print(event.data);
        break;
      case ProjectEvent.deleteChargeBack:
        if (confirm('Are you sure you want to delete Charge Back ' + event.data.chargeBackId)) {
          this.deleteChargeBack(event.data);
        }
      default:
        break;
    }
  }

  ngOnInit() {
    let listDefinition: IListDefinition = this.projectService.loadProjectChargeBackListDefinition(this.projectId, this.canIEdit);
    listDefinition.noGet = true;
    this.listDefinition$.next(listDefinition);
    this.subscription.add(this.store.pipe(select(getListByType(listDefinition.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == listDefinition.parentId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          this.working$.next(state.working);
          this.errorData$.next(state.errorData);
        }
      }));
  }

  deleteChargeBack(chargeBack: IChargeBack): void {
    const deleteUrl = this.ups.getDeleteUrl(chargeBack);
    if (deleteUrl.length) {
      const listDefinition = this.listDefinition$.value;
      const keyData: IKey = { storeName: listDefinition.storeName, parentId: listDefinition.parentId, key: listDefinition.rowKeyId, id: chargeBack.chargeBackId }
      const event: IHomEventEmitter = { requestor: 'project-chargeBacks', event: this.myConstants.emitterEventDelete, action: '', data: null };
      const deleteData = new DeleteObjectModel(keyData, deleteUrl, event);
      this.store.dispatch(new DynamicListActions.DeleteObjectByUrlList({ deleteData }));
    }
  }

  print(chargeBack: IChargeBack): void {
    this.sharedDashboardService.printDoc(chargeBack, 'chargeBackNoticeUrl');
  }

  ngOnChanges(changes: SimpleChanges) {
  //  if (changes['projectId'] && !changes['projectId'].isFirstChange()) {
  //  }
  }
}
