<div class="crm-form-question" [ngClass]="{'loading-mask-no-spinner': !crmFormQuestion.enabled }">
  <div class="crm-form-question_col1">
    <i class="far" [ngClass]="crmFormQuestion.isAnswered ? 'fa-square-check app-icon--success' : crmFormQuestion.required ? 'fa-asterisk app-icon--danger': ''"></i>
  </div>
  <div class="crm-form-question_col2">
    <span>
      {{ crmFormQuestion.questionText }}
    </span>
    <span>
      - ({{ questionInstructions }})
    </span>
  </div>

  <div class="crm-form-question_col1"></div>
  <div class="crm-form-question_col2">
    <div class="app-group-btn-box">
      <button type="button"
              *ngFor="let answerOption of crmFormQuestion.crmFormQuestionAnswers"
              class="app-group-btn app-btn--primary"
              [disabled]="!crmFormQuestion.enabled"
              [ngClass]="{'app-group-btn--selected': answerOption.selected }"
              (click)="answerQuestion(answerOption.answerText)">
        {{ answerOption.answerLabel }}
      </button>
    </div>
    <div *ngIf="crmFormQuestion.parameterType === 'String' && crmFormQuestion.crmFormQuestionAnswers.length === 0">
      <textarea #blah asciiOnlyMask [readonly]="!crmFormQuestion.enabled"
                (change)="answerQuestion(blah.value)"
                cols="100">{{ crmFormQuestion.answerText }}</textarea>
    </div>
    <div *ngIf="crmFormQuestion.actionId">
      <router-outlet name="lswpOutlet"></router-outlet>
      <div class="app-group-btn-box">
        <button type="button"
                *ngIf="!crmFormQuestion.isAnswered"
                class="app-group-btn app-btn--primary"
                (click)="routeToSignature()">
          <span>Click to Sign</span>
        </button>
        <span *ngIf="crmFormQuestion.isAnswered">Completed</span>
      </div>
    </div>
  </div>
</div>
