import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { ISmsContactViewModel } from '../../../portals/view-models/i-sms-contact-view-model';
import { SmsEvent } from '../../enums/sms.enums';

@Component({
  selector: 'sms-contact',
  templateUrl: './sms-contact.component.html'
})
export class SmsContactComponent implements OnInit {
  @Input() contact: ISmsContactViewModel;
  @Input() activeId: number;
  @Input() mgrFiltered: boolean = false;

  @Output() public selected = new EventEmitter<IHomEventEmitter>();

  ngOnInit(): void {}

  public selectItem(): void {
    const emitter: IHomEventEmitter = { requestor: 'sms-contact', event: SmsEvent.selectContact, action: '', data: this.contact };
    this.selected.emit(emitter);
  }

  public changSub(event): void {
    event.stopPropagation(); //so will not execute selectItem()
    const emitter: IHomEventEmitter = {
      requestor: 'sms-contact',
      event: this.contact.activeSubscription ? SmsEvent.unSubscribe : SmsEvent.subscribe,
      action: '',
      data: this.contact.contactId
    };
    this.selected.emit(emitter);
  }

}
