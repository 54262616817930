import { Component, OnInit,  Input} from '@angular/core';
import { IPoLswpOverrideReason } from '../../interfaces';
import { LswpProcessingStatus } from '../../enums/lswp-processing-status.enums';

@Component({
  selector: 'lswp-purchase-order',
  templateUrl: './lswp-purchase-order.component.html'
})
export class LswpPurchaseOrderComponent implements OnInit {
  @Input() item: IPoLswpOverrideReason;  
  isCompleted: string = '';
  isCanceled: string = '';

  constructor() { }

  ngOnInit() {
    this.isCompleted = LswpProcessingStatus.completed;
    this.isCanceled = LswpProcessingStatus.canceled;
  }


}
