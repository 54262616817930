import { IListColumn, IListFilter, IRowStatus, IGroupSelect, ISingleSelect} from './index';
import { ISearchTerm } from './i-search-term';
import { ICustomButton } from '../../fw-shared/interfaces/i-custom-button';

export interface IListDefinition {
    routerOutlet: string;
    objectLabel: string; 
    controllerName: string;
    storeName: string; 
    rowKeyId: string; 
    defaultListFilter: IListFilter;
    listColumns: IListColumn[];
    detailRoutePath: string; 
    metaUrl: string;
    controllerMethod: string; //defaults to Index if not specified
    methodParameters: string;
    parentKey: string;
    parentId: number;
    createRoutePath: string;
    rowButtons: ICustomButton[];
    toolButtons: ICustomButton[];
    itemsPerPage: number;
    showPrevNext: boolean;
    showFilter: boolean;
    groupSelect: IGroupSelect;
    singleSelect: ISingleSelect;
    rowStatus: IRowStatus;
    noGet: boolean;
    requiredSearchTerms: ISearchTerm[];
    openInFilterMode: boolean;
    revokeSort: boolean;
    filterWithoutTerms: boolean;
    allowSaveFilter: boolean;
}

export class ListDefinition implements IListDefinition {
  routerOutlet: string;
  objectLabel: string;
  controllerName: string;
  storeName: string;
  rowKeyId: string;
  defaultListFilter: IListFilter;
  listColumns: IListColumn[];
  detailRoutePath: string;
  metaUrl: string;
  controllerMethod: string;
  methodParameters: string;
  parentKey: string;
  parentId: number;
  createRoutePath: string;
  rowButtons: ICustomButton[];
  toolButtons: ICustomButton[];
  showFilter: boolean;
  itemsPerPage: number;
  showPrevNext: boolean;
  groupSelect: IGroupSelect;
  singleSelect: ISingleSelect;
  rowStatus: IRowStatus;
  noGet: boolean;
  requiredSearchTerms: ISearchTerm[];
  openInFilterMode: boolean;
  revokeSort: boolean;
  filterWithoutTerms: boolean;
  allowSaveFilter: boolean;
  constructor(routerOutlet: string, objectLabel: string, controllerName: string, storeName: string, rowKeyId: string, defaultListFilter: IListFilter, listColumns: IListColumn[], detailRoutePath: string = '') {
    this.routerOutlet = routerOutlet;
    this.objectLabel = objectLabel;
    this.controllerName = controllerName; //defaults to Index if not specified
    this.storeName = storeName;
    this.rowKeyId = rowKeyId;
    this.defaultListFilter = defaultListFilter;
    this.listColumns = listColumns;
    this.detailRoutePath = detailRoutePath; //path set here must be defined in app.routing or a sub routing component
    this.metaUrl = '';
    this.controllerMethod = ''; //defaults to ByProvider if this and metaUrl are not specified
    this.methodParameters = '';
    this.parentKey = '';
    this.parentId = -1;
    this.createRoutePath = ''; //path set here must be defined in app.routing or a sub routing component
    this.rowButtons = [];
    this.toolButtons = [];
    this.showFilter = true;
    this.itemsPerPage = 10;
    this.showPrevNext = true;
    this.groupSelect = null;
    this.singleSelect = null;
    this.rowStatus = null;
    this.noGet = false;
    this.requiredSearchTerms = [];
    this.openInFilterMode = false;
    this.revokeSort = false;
    this.allowSaveFilter = true;
  }
}
