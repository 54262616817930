import {  Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { orderBy } from 'lodash';

import { IPurchaseOrder, IWorkOrderViewModel, IUserPriviledges } from '../../app/portals/view-models/index';
import { IWorkOrder } from '../../app/portals/view-models/index';
import * as fromRoot from '../../app/store/reducers/index';
import * as fromAuth from '../../auth/store/index';
import { IParameterSearchType, IListComponent } from '../../fw/dynamic-list/interfaces';
import { IWidgetManager } from '../../app/portals/portal/interfaces';
import { HomDataUtility } from '../../shared/services';

@Injectable()
export class UserPriviledgesService implements OnDestroy  {

  constructor(public rootStore: Store<fromRoot.IState>,
    public dataUtils: HomDataUtility  ) {
    this.subscription.add(this.rootStore.pipe(select(fromRoot.getAuthState))
      .subscribe((authState: fromAuth.IState) => {

        const hasPriv = authState.authenticated && authState.providerUser !== null && authState.providerUser.currentUserId > 0;
        const userPriviledges: IUserPriviledges = hasPriv && authState.providerUser.hasOwnProperty('userPriviledges')
          ? authState.providerUser.userPriviledges : null;
        this.authenticated$.next(authState.authenticated);
        this.manageProviders$.next(hasPriv &&  this.getPrivilegeByType('manageProviders', userPriviledges));
        this.manageSecurity$.next(hasPriv && this.getPrivilegeByType('manageSecurity', userPriviledges));
        this.manageEntities$.next(hasPriv && this.getPrivilegeByType('manageEntities', userPriviledges));
        this.manageTasks$.next(hasPriv && this.getPrivilegeByType('manageTasks', userPriviledges));
        this.managePricing$.next(hasPriv && this.getPrivilegeByType('managePricing', userPriviledges));
        this.editUnitPrice$.next(hasPriv && this.getPrivilegeByType('editUnitPrice', userPriviledges));
        this.canCreateJobSchedules$.next(hasPriv && this.getPrivilegeByType('canCreateJobSchedules', userPriviledges));
        this.canEditAddressAge$.next(hasPriv && this.getPrivilegeByType('canEditAddressAge', userPriviledges));
        this.canDelegateOtherUsers$.next(hasPriv && this.getPrivilegeByType('canDelegateOtherUsers', userPriviledges));
        this.canScheduleLockedDays$.next(hasPriv && this.getPrivilegeByType('canScheduleLockedDays', userPriviledges));
        this.canLockScheduleDays$.next(hasPriv && this.getPrivilegeByType('canLockScheduleDays', userPriviledges));
        this.deleteProject$.next(hasPriv && this.getPrivilegeByType('deleteProject', userPriviledges));
        this.haveDeveloperAccess$.next(hasPriv && this.getPrivilegeByType('haveDeveloperAccess', userPriviledges));
        this.canManageInstallers$.next(hasPriv && this.getPrivilegeByType('manageInstallers', userPriviledges));
        this.assignWarrantyProjects$.next(hasPriv && this.getPrivilegeByType('assignWarrantyProjects', userPriviledges));
        this.canExtendScheduleCallDue$.next(hasPriv && this.getPrivilegeByType('canExtendScheduleCallDue', userPriviledges));
        this.canChargeBackInstaller$.next(hasPriv && this.getPrivilegeByType('canChargeBackInstaller', userPriviledges));
        this.canViewPoBalance$.next(hasPriv && this.getPrivilegeByType('canViewPoBalance', userPriviledges));
        this.manageWarrantyProjects$.next(hasPriv && this.getPrivilegeByType('manageWarrantyProjects', userPriviledges));
        this.canFlagCustomers$.next(hasPriv && this.getPrivilegeByType('canFlagCustomers', userPriviledges));
        this.canBlockKeyReq$.next(hasPriv && this.getPrivilegeByType('canBlockKeyReq', userPriviledges));
        this.canBypassExternalPriceCheck$.next(hasPriv && this.getPrivilegeByType('canBypassExternalPriceCheck', userPriviledges));
        this.canOrderMoreThanRecommendedInventory$.next(hasPriv && this.getPrivilegeByType('canOrderMoreThanRecommendedInventory', userPriviledges));
        this.canApproveApprovalQueue$.next(hasPriv && this.getPrivilegeByType('canApproveApprovalQueue', userPriviledges));
        this.canAuditSms$.next(hasPriv && this.getPrivilegeByType('canAuditSms', userPriviledges));
        this.canManageWorkCategories$.next(hasPriv && this.getPrivilegeByType('canManageWorkCategories', userPriviledges));
        this.canDeleteStagedPoImports$.next(hasPriv && this.getPrivilegeByType('canDeleteStagedPoImports', userPriviledges));
        this.canManageLSWP$.next(hasPriv && this.getPrivilegeByType('canManageLSWP', userPriviledges));
        this.canReopenProject$.next(hasPriv && this.getPrivilegeByType('canReopenProject', userPriviledges));
        this.canDeleteWithoutNote$.next(hasPriv && this.getPrivilegeByType('canDeleteWithoutNote', userPriviledges));
        this.providerId$.next(hasPriv ? authState.providerUser.providerId : null);
        this.currentUserId$.next(hasPriv ? authState.providerUser.currentUserId : null);
        this.currentUserName$.next(hasPriv ? authState.providerUser.currentUserName : '');
        this.canOneClickDial$.next(hasPriv && authState.providerUser.extension !== '');
        this.parameterSearchTypes$.next(authState.parameterSearchTypes);
        this.listComponents$.next(authState.listComponents);
        this.myWidgets$.next(authState.myWidgets);
      }));
  }

  subscription: Subscription = new Subscription();
  authenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  manageProviders$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  manageSecurity$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  manageEntities$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  manageTasks$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  managePricing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  editUnitPrice$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canCreateJobSchedules$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canEditAddressAge$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canDelegateOtherUsers$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canScheduleLockedDays$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canLockScheduleDays$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  deleteProject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  haveDeveloperAccess$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canManageInstallers$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  assignWarrantyProjects$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canExtendScheduleCallDue$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canChargeBackInstaller$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canViewPoBalance$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  manageWarrantyProjects$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canFlagCustomers$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canBlockKeyReq$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canBypassExternalPriceCheck$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canOrderMoreThanRecommendedInventory$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canApproveApprovalQueue$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentUserId$: BehaviorSubject<number> = new BehaviorSubject(null);
  currentUserName$: BehaviorSubject<string> = new BehaviorSubject('');
  providerId$: BehaviorSubject<number> = new BehaviorSubject(null);
  canOneClickDial$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canAuditSms$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canManageWorkCategories$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canDeleteStagedPoImports$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canManageLSWP$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canReopenProject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canDeleteWithoutNote$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  parameterSearchTypes$: BehaviorSubject<IParameterSearchType[]> = new BehaviorSubject([]);
  myWidgets$: BehaviorSubject<IWidgetManager[]> = new BehaviorSubject([]);
  listComponents$: BehaviorSubject<IListComponent[]> = new BehaviorSubject([]);
  none: string = 'none';

  /*
      Using the componentName set in widget_manager_portals.component_name
      returns true or false if that widget is found in the authStore in myWidgets object.
      Refer to widget.enums for list of component names.
      Only call if not a stand alone component (not a tab, as tabs are handled elsewhere).
  */
  canIByComponentName(componentName: string): boolean {
    //console.log('TEMP: canIByComponentName check with component: ', componentName, ' and findIndex of: ', this.myWidgets$.getValue().findIndex(w => w.componentName === componentName) );
    return this.myWidgets$.getValue().findIndex(w => w.componentName === componentName) > -1;
  }

  getSearchTypesByParameterType(parameterType: string): IParameterSearchType[]{
    return orderBy(this.parameterSearchTypes$.getValue().filter(x => x.parameterType.toLowerCase() === parameterType.toLowerCase()), ['searchType'], ['asc']);
  }

  getSearchTypeDefault(parameterType: string): IParameterSearchType {
    return this.parameterSearchTypes$.getValue().find(x => x.parameterType.toLowerCase() === parameterType.toLowerCase() && x.isDefault === true);
  }

  getListComponentByStore(storeName: string): IListComponent {
    return this.listComponents$.getValue().find(x => x.storeName.toLowerCase() == storeName.toLowerCase());
  }

  getListComponentByComponentId(listComponentId: number): IListComponent {
    return this.listComponents$.getValue().find(x => x.listComponentId === listComponentId);
  }

  getPrivilegeByType(type: string, userPriviledges: IUserPriviledges): boolean {
    return userPriviledges.hasOwnProperty(type) ? userPriviledges[type] : false;
  }

  canICreate(row: any) {
    if (this.dataUtils.rowHasCrudMetaData(row)
      && row['metaData']['crud'].hasOwnProperty('createUrl')
      && row['metaData']['crud']['createUrl'] !== null
      && row['metaData']['crud']['createUrl'] !== '') {
      return true;
    }

    return false;
  }

  canIEdit(row: any) {
    if (this.dataUtils.rowHasCrudMetaData(row)
      && row['metaData']['crud'].hasOwnProperty('updateUrl')
      && row['metaData']['crud']['updateUrl'] !== null
      && row['metaData']['crud']['updateUrl'] !== '') {
      return true;
    }

    return false;
  }

  canIPrint(row: any) {
    if (this.dataUtils.rowHasCrudMetaData(row)
      && row['metaData']['crud'].hasOwnProperty('printUrl')
      && row['metaData']['crud']['printUrl'] !== null
      && row['metaData']['crud']['printUrl'] !== '') {
      return true;
    }

    return false;
  }

  canIDelete(row: any) {
    if (this.dataUtils.rowHasCrudMetaData(row)
      && row['metaData']['crud'].hasOwnProperty('deleteUrl')
      && row['metaData']['crud']['deleteUrl'] !== null
      && row['metaData']['crud']['deleteUrl'] !== '') {
      return true;
    }

    return false;
  }

  canISee(row: any) {
    if (this.dataUtils.rowHasCrudMetaData(row)
      && row['metaData']['crud'].hasOwnProperty('detailUrl')
      && row['metaData']['crud']['detailUrl'] !== null
      && row['metaData']['crud']['detailUrl'] !== '') {
      return true;
    }

    return false;
  }

  getDeleteUrl(row: any): string {
    let url = '';
    if (this.dataUtils.rowHasCrudMetaData(row)
      && row['metaData']['crud'].hasOwnProperty('deleteUrl')) {
      url = row['metaData']['crud']['deleteUrl'];
    }
    return url;
  }

  getPrintUrl(row: any): string {
    let url = '';
    if (this.dataUtils.rowHasCrudMetaData(row)
      && row['metaData']['crud'].hasOwnProperty('printUrl')) {
      url = row['metaData']['crud']['printUrl'];
    }
    return url;
  }

  canEditWorkOrder(row: IWorkOrder): boolean {
    return row && row.workOrderStatusText !== 'Work Complete' && row.workOrderStatusText !== 'AccountingError';
  }

  canEditWorkOrderViaView(row: IWorkOrderViewModel): boolean {
    return row && row.workOrderStatus !== 'Work Complete' && row.workOrderStatus !== 'AccountingError';
  }

  canEditWorkOrderItem(row: IWorkOrder): boolean {
    return this.canEditWorkOrder(row) && row.canAddItems;
  }

  canEditPurchaseOrder(row: IPurchaseOrder): boolean {
    return row && row.purchaseOrderStatus !== 'Closed' && row.purchaseOrderStatus !== 'AccountingError';
  }

  canEditPurchaseOrderItem(row: IPurchaseOrder): boolean {
    return this.canEditPurchaseOrder(row) && row.poCanAddItems;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
