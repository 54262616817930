import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IWorkCategory, WorkCategory } from '../../../view-models';
import { AdminStore } from '../../enums/admin.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { IKey, CreateObjectModel, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces/index';

import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';

import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'work-category',
  templateUrl: './work-category.component.html'
})
export class WorkCategoryComponent implements OnInit, OnDestroy {

  public objectData: IWorkCategory;
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public showPayouts: boolean;
  displayFields: string[];
  operation: string;
  objectId: number = 0;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) {

    this.subscription.add(emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventCreate:
            this.createRecord(e);
            break;
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          default:
            break;
        }
      }));
  }

  ngOnInit() {
    this.showPayouts = this.activeRoute.outlet === 'modal';
    if (!this.showPayouts) {
      this.displayFields = ['workCategoryName', 'workCategoryDescription', 'unitMeasure', 'minimumPercentMetForSelfService', 'determinesJobLength'];
    } else {
      this.displayFields = ['workCategoryName', 'unitMeasure', 'minimumPercentMetForSelfService', 'determinesJobLength'];
    }

    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.setDetailConfig(paramMap);
      this.getDetail();
    }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.showErrorBox = true;
    params.showNav = !this.showPayouts;
    params.parentId = -1;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(AdminStore.workCategories, -1, 'workCategoryId', this.objectId)))
      .subscribe(entity => {
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate
          ? new WorkCategory() : entity;
      })
    );
  }

  createRecord(e: IHomEventEmitter) {
    let rec: IWorkCategory = this.setCommonProperties(e.data);
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(AdminStore.workCategories, -1, 'WorkCategory', 'Create', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  updateRecord(e: IHomEventEmitter) {
    let rec: IWorkCategory = this.setCommonProperties(e.data);
    const keyData: IKey = { storeName: AdminStore.workCategories, parentId: -1, key: 'workCategoryId', id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, 'WorkCategory', 'Update', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  setCommonProperties(formData: IWorkCategory): IWorkCategory {
    let rec = new WorkCategory();
    rec.workCategoryId = this.objectId;
    rec.workCategoryName = formData.workCategoryName;
    rec.workCategoryDescription = formData.workCategoryDescription;
    rec.provider_providerId = this.ups.providerId$.value;
    rec.determinesJobLength = formData.determinesJobLength;
    rec.unitMeasure_unitMeasureId = formData['unitMeasure']['unitMeasureId'];
    rec.minimumPercentMetForSelfService = formData.minimumPercentMetForSelfService;
    return rec;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
