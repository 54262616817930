export interface IContactMechanismCategory {
  contactMechanismCategoryId: number;
  provider_providerId: number;
  contactMechanismCategoryName: string;
}

export class ContactMechanismCategory implements IContactMechanismCategory {
  contactMechanismCategoryId: number;
  provider_providerId: number;
  contactMechanismCategoryName: string;

  constructor() {
    this.contactMechanismCategoryId = 0;
    this.provider_providerId = null;
    this.contactMechanismCategoryName = '';
  }
}
