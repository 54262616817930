<div *ngIf="installer && mds.fieldDefinitionsLoaded && mds.selectionListsLoaded">
  <form *ngIf="form" [formGroup]="form" novalidate class="form portal__form" [ngClass]="{'portal__form--edit': operation !== myConstants.operationTypeDetails}">
    <div class="app-two-col">
      <div class="app-two-col--span2">
        <fw-dynamic-field [field]="mds.getFieldDefinition('installerName')" [form]="form" [operation]="operation"></fw-dynamic-field>
      </div>
      <div class="app-two-col--col1">
        <fw-dynamic-field [field]="mds.getFieldDefinition('businessType')" [form]="form" [operation]="operation" [selectItems]="businessTypes"></fw-dynamic-field>
      </div>
      <div class="app-two-col--col2">
        <fw-dynamic-field [field]="mds.getFieldDefinition('taxIdNumber')" [form]="form" [operation]="operation"></fw-dynamic-field>
      </div>
    </div>
    <div class="app-three-col">
      <div class="app-three-col--span2">
        <fw-dynamic-field *ngIf="operation !== myConstants.operationTypeDetails"
                          [field]="mds.getFieldDefinition('state')" [form]="form" [operation]="operation" [selectItems]="states"></fw-dynamic-field>
        <fw-dynamic-field *ngIf="operation === myConstants.operationTypeDetails"
                          [field]="mds.getFieldDefinition('stateIncorporated')" [form]="form" [operation]="operation"> </fw-dynamic-field>
      </div>
      <div class="app-three-col--col3 installer-detail__isactive">
        <fw-dynamic-field [field]="mds.getFieldDefinition('isActive')" [form]="form" [operation]="operation"></fw-dynamic-field>
      </div>
    </div>
    <div class="app-two-col">
      <div class="app-two-col--span2">
        <fw-dynamic-field [field]="mds.getFieldDefinition('contactName')" [form]="form" [operation]="operation"></fw-dynamic-field>
      </div>
      <div class="app-two-col--span2">
        <fw-dynamic-field [field]="mds.getFieldDefinition('addressLine1')" [form]="form" [operation]="operation"></fw-dynamic-field>
      </div>
    </div>
    <div class="app-three-col">
      <div class="app-three-col--span2">
        <fw-dynamic-field [field]="mds.getFieldDefinition('addressCity')" [form]="form" [operation]="operation"></fw-dynamic-field>
      </div>
      <div class="app-three-col--col3">
        <fw-dynamic-field [field]="mds.getFieldDefinition('addressStateId')" [form]="form" [operation]="operation" [selectItems]="states"></fw-dynamic-field>
      </div>
    </div>
    <div class="app-two-col">
      <div class="app-two-col--col1">
        <fw-dynamic-field [field]="mds.getFieldDefinition('addressZipcode5')" [form]="form" [operation]="operation"></fw-dynamic-field>
      </div>
      <div class="app-two-col--span2">
        <fw-dynamic-field [field]="mds.getFieldDefinition('phoneNumber')" [form]="form" [operation]="operation"></fw-dynamic-field>
      </div>
      <div class="app-two-col--span2">
        <fw-dynamic-field [field]="mds.getFieldDefinition('emailAddress')" [form]="form" [operation]="operation"></fw-dynamic-field>
        <div *ngIf="(operation == myConstants.operationTypeDetails && !installer.emailAddress)">
          <div class="installer-detail__nodata">Missing Email</div>
        </div>
      </div>
    </div>
    <div class="installer-detail__cbs">
      <div class="installer-detail__cbs--col1">
        <fw-dynamic-field [field]="mds.getFieldDefinition('emailWorkPacket')" [form]="form" [operation]="operation"> </fw-dynamic-field>
      </div>
      <div class="installer-detail__cbs--col2">
        <fw-dynamic-field [field]="mds.getFieldDefinition('wantScheduleDailySummary')" [form]="form" [operation]="operation"></fw-dynamic-field>
      </div>
    </div>
  </form>
  <div *ngIf="operation === myConstants.operationTypeCreate">
    <div class="app-btn-box">
      <button type="button" class="app-btn app-btn--cancel" (click)="onCancelCreate()">Cancel</button>
      <button type="button" class="app-btn app-btn--primary"
              [disabled]="!form.dirty || !form.valid "
              (click)="onCreate()">
        Create
      </button>
    </div>
  </div>
  <fw-component-footer [lastUpdate]="installer.updateDate"></fw-component-footer>
</div>
