import {  Component, OnInit, Input, OnChanges, SimpleChanges,  Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, } from 'rxjs';
import { take, filter } from 'rxjs/operators';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { MetaDataService} from '../../../../../fw/dynamic-list/services/index'
import { InstallerWorkCrewTechnician, IInstallerWorkCrewTechnician} from '../../../view-models/index';

@Component({
  selector: 'installer-work-crew-technician-create',
  templateUrl: './installer-work-crew-technician-create.component.html',
  providers: [MetaDataService]
})
export class InstallerWorkCrewTechnicianCreateComponent implements OnInit, OnChanges  {
  @Input() requestTime: string; //to handle subsequent create request for the same contact type (id never changes)
  @Input() installerId: number;
  @Input() workCrewId: number;

  public title: string = 'Installer Work Crew Technician';
  public operation: string;
  public technicianForm: FormGroup;

  metaLoaded: boolean = false;
  storeName: string = 'installerTechnicians';
  technician: IInstallerWorkCrewTechnician;
  displayFields: string[] = ['technicianDescription', 'isActive', 'isWorkCrewLead'];
  subscription: Subscription = new Subscription();

  constructor(
    public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants) {  }

  ngOnInit(): void {
    this.operation = this.myConstants.operationTypeCreate;
    this.technician = null;
    this.initMetaData();
    if (this.metaLoaded) {
      //mds already loaded just reload form to init values
      this.initForm();
    }

    //GET FIELD DEFS
    this.subscription.add(this.mds.mdsReady$.pipe(filter(flag => flag === true), take(1))
      .subscribe((ready: boolean) => {
        this.initForm();
      }));
  }

  initMetaData(): void {
    this.mds.setFieldDefinitions(this.storeName);
  }

  //just once
  initForm(): void {
    this.technician = new InstallerWorkCrewTechnician();
    this.technicianForm = this.mds.loadDynamicFormGroup(this.displayFields, this.technician, this.myConstants.operationTypeCreate);
    this.metaLoaded = true;
  }

  setTechnicianProperties(): IInstallerWorkCrewTechnician {
    let technician: IInstallerWorkCrewTechnician = new InstallerWorkCrewTechnician();
    const form: IInstallerWorkCrewTechnician = this.technicianForm.getRawValue();
    technician.isActive = form.isActive;
    technician.technicianDescription = form.technicianDescription;
    technician.installer_installerId = this.installerId;
    technician.installerWorkCrew_installerWorkCrewId = this.workCrewId;
    technician.isWorkCrewLead = form.isWorkCrewLead;
    return technician;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['requestTime'] && !(changes['requestTime'].firstChange)) {
      //new create request.
      this.initForm();
    }
  }

  ngOnDestroy(): void {
  }

  //public methods
  formIsValid(): boolean {
    return this.technicianForm && this.technicianForm.dirty && this.technicianForm.valid;
  }

  getTechnicianModel(): IInstallerWorkCrewTechnician {
    let technician: IInstallerWorkCrewTechnician = this.setTechnicianProperties();

    return technician;
  }
}
