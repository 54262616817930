import { Injectable } from '@angular/core';
import { Actions, ofType,createEffect } from '@ngrx/effects';
import {  HttpClient } from '@angular/common/http';
import { Store, Action } from '@ngrx/store'; //Action
import { Observable } from 'rxjs';
import { cloneDeep } from 'lodash';
import { switchMap,  map,   mergeMap, tap, filter} from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

/** Imports for custom services */
import { IResponseBase, IHomDictionary, HomDictionary } from '../../../../shared/interfaces/index';
import { IDetailRequest } from '../../../dynamic-detail/interfaces/index';
import { CreateObjectModel, UpdateObjectByIdModel, DeleteObjectModel, IKey, UpdateObjectCustomModel } from '../interfaces/index';
import * as LoadingIndicatorActions from '../../../../shared/store/loadingIndicator/loadingIndicator.actions';

import * as fromMetaData from '../actions/meta-data.actions';
import * as fromFeature from '../reducers/feature.reducer';
import * as fromDynamicObject from '../actions/dynamic-object.actions';
import { DynamicObjectActionTypes } from '../actions/dynamic-object.actions';
import { DomainObjectService } from '../../../../shared/services/index';

 //Actions used in these Effects that need to trigger Spinner hide/show
type ShowSpinnerTypes = 
  | fromDynamicObject.GetObject
  | fromDynamicObject.UpdateObjectByIdObj
  | fromDynamicObject.UpdateObjectCustomObj
  | fromDynamicObject.CreateObjectObj
  | fromDynamicObject.DeleteObjectByUrlObj;

const showSpinnerActions = [
  DynamicObjectActionTypes.GET_OBJECT,
  DynamicObjectActionTypes.UPDATE_OBJECT_BY_ID_OBJ,
  DynamicObjectActionTypes.UPDATE_OBJECT_CUSTOM_OBJ,
  DynamicObjectActionTypes.CREATE_OBJECT_OBJ,
  DynamicObjectActionTypes.DELETE_OBJECT_BY_URL_OBJ
];

type HideSpinnerTypes =
  | fromDynamicObject.SetObject
  | fromDynamicObject.UpdateCompleteObj
  | fromDynamicObject.CreateCompleteObj
  | fromDynamicObject.DeleteCompleteObj
  | fromDynamicObject.SetErrorReturnedObj

const hideSpinnerActions = [
  DynamicObjectActionTypes.SET_OBJECT,
  DynamicObjectActionTypes.UPDATE_COMPLETE_OBJ,
  DynamicObjectActionTypes.CREATE_COMPLETE_OBJ,
  DynamicObjectActionTypes.DELETE_COMPLETE_OBJ,
  DynamicObjectActionTypes.SET_ERROR_RETURNED_OBJ
];


//using Effects so can assign actions to an observable result set
@Injectable()
export class DynamicObjectEffects {

  constructor(
    public http: HttpClient,
    public actions$: Actions,
    public domainObjectService: DomainObjectService,
    public store: Store<fromFeature.IAllDynamicData>  ) {}

  /********************************************************************************************************************************************
   * Triggered when included in ShowSpinnerTypes/ HideSpinnerTypes - cannot use a common spinner here as lists
   * are dynamic and each need their own spinner indicator
   * either modify the spinner to have dynamic values or keep as is.
  **********************************************************************************************************************************************/
  showSpinner$ = createEffect(() => this.actions$.pipe(
      ofType<ShowSpinnerTypes>(...showSpinnerActions),
      map((action) => {
        //pull the store name out of the incominb payload
        //console.log('object spinner SHOW with: ',action);
        const firstObj = action.payload[Object.keys(action.payload)[0]];
        const requestor =   action.payload.hasOwnProperty('requestor') && action.payload['requestor'].hasOwnProperty('storeName') ? action.payload['requestor']['storeName'] 
          :  firstObj && firstObj.hasOwnProperty('storeName') ? firstObj['storeName']
          :  firstObj && firstObj.hasOwnProperty('keyData') && firstObj['keyData'].hasOwnProperty('storeName') 
                  ? firstObj['keyData']['storeName'] 
              : action.payload;
        const id = action.payload.hasOwnProperty('requestor') && action.payload['requestor'].hasOwnProperty('id') ? action.payload['requestor']['id']
          : firstObj && firstObj.hasOwnProperty('parentId') ? firstObj['parentId']
            : firstObj && firstObj.hasOwnProperty('keyData') && firstObj['keyData'].hasOwnProperty('parentId')
              ? firstObj['keyData']['parentId']
              : action.payload;

        return new LoadingIndicatorActions.ShowSpinner({ requestor: requestor, id: id });
      }
    ))
    );

  hideSpinner$ = createEffect(() =>this.actions$.pipe(
      ofType<HideSpinnerTypes>(...hideSpinnerActions),
      map((action) => {
        //console.log('object spinner HIDE with: ',action);
        const requestor = action.payload.hasOwnProperty('storeName') ? action.payload['storeName']
          : action.payload.hasOwnProperty('keyData') && action.payload['keyData'].hasOwnProperty('storeName') ? action.payload['keyData']['storeName']
            : action.payload;
        const id = action.payload.hasOwnProperty('parentId') ? action.payload['parentId']
          : action.payload.hasOwnProperty('keyData') && action.payload['keyData'].hasOwnProperty('parentId') ? action.payload['keyData']['parentId']
            : action.payload;
      return new LoadingIndicatorActions.HideSpinner({ requestor: requestor, id: id });
    }
  ))
  );


  /*********************************************************************************************************************************************
  GET_OBJECT Effect
  **********************************************************************************************************************************************/
  objGet$ = createEffect(() => this.actions$.pipe(
      ofType<fromDynamicObject.GetObject>(DynamicObjectActionTypes.GET_OBJECT),
      mergeMap((action) => {
        // Construct the URL to be called.
        const request: IDetailRequest = action.payload.detailRequest;
        const event: IHomEventEmitter = action.payload.event ? cloneDeep(action.payload.event) : null;

       let result: Observable<IResponseBase>;
        if (request.controllerMethod) {
          result = this.domainObjectService.getObjectByMethod(request.controllerName, request.controllerMethod, request.methodParameters);
        } else if (request.metaUrl) {
          result = this.domainObjectService.getObjectByMetaUrl(request.metaUrl);
        } else { 
          console.log('DEV ERROR: Invalid get request for get object', action.payload);
        }

        return result.pipe(
          mergeMap((response) => {
            let metaData = {};
            if (response.success) {
              metaData = response.hasOwnProperty('metaData') ? response.metaData : response.data.hasOwnProperty('metaData') ? response.data.metaData : {};
              if (Object.keys(metaData).length === 0) {
                console.log('DEV WARNING:  fromDynamicObject.GetList and no metaData returned', response);
              }
            }
            const accessError: boolean = !response.success && response.errorData.hasOwnProperty('accessError');
            const data = {
              storeName: request.storeName,
              objectId: request.objectId,
              data: response.success ? response.data : null,
              errorData: response.success ? [] : accessError ? [] : response.errorData,
              error: response.success ? null : accessError ? response.errorData['errorMessage'] : response.message,
              event: event,
              accessError: accessError
            };

            if (!response.success || data.errorData.length) {
              return [
                { type: DynamicObjectActionTypes.SET_ERROR_RETURNED_OBJ, payload:  data }
              ];
            } 
            else if (response.metaData.hasOwnProperty('fieldMetaData') && response.metaData.fieldMetaData) {
              return [
                { type: DynamicObjectActionTypes.SET_OBJECT, payload: data },
                { type: fromMetaData.GET_FIELD_DEFINITIONS, payload: { storeName: request.storeName, listDefinition: null, metaData: response.metaData } }
              ];
            }
            else {
                return [
                  { type: DynamicObjectActionTypes.SET_OBJECT, payload: data }
                ];
            }
          }))
      })
    )
  );


  /*********************************************************************************************************************************************
   *UPDATE_OBJECT_BY_ID 
   * Call an object's custom update method in the API - uses id to know what record in store to update post update.
   * @param objectType The object type whose controller implements the custom "update" method
   * @param methodName The name of the custom "update" method to call
   * @param objectData The updated object to submit as an HTTP payload
   * @param arrayName The name to give the array parameter, if domainObject is an array
  **********************************************************************************************************************************************/
  updateObjectObj$ = createEffect(() => this.actions$.pipe(
    ofType<fromDynamicObject.UpdateObjectByIdObj>(DynamicObjectActionTypes.UPDATE_OBJECT_BY_ID_OBJ), 
    map(action => action.payload.updateData),
    tap((updateData: UpdateObjectCustomModel) => {
      this.store.dispatch(new fromDynamicObject.SetWorkingObj({storeName: updateData.keyData.storeName, objectId: updateData.keyData.parentId}));
    }),
    switchMap((updateData: UpdateObjectByIdModel ) => 
      //const keyData: IKey = updateData.keyData;
      this.domainObjectService
        .updateByMethod(updateData.objectType, updateData.methodName, updateData.objectData, updateData.arrayName)
        .pipe(
          //catchError(error => {
          //  return [
          //    { type: fromDynamicObject.SET_ERROR_RETURNED_OBJ, payload:  { storeName: updateData.keyData.storeName, error: "Update record failed" } },
          //  ];
          //}),
          map((data) => new fromDynamicObject.UpdateCompleteObj({ keyData: updateData.keyData, responseBase: data, event: updateData.event })
          )
        ))
      )
  );

  
  /*********************************************************************************************************************************************
   *UPDATE_OBJECT_CUSTOM
   * Call an object's custom update method in the API
   * @param objectType The object type whose controller implements the custom "update" method
   * @param methodName The name of the custom "update" method to call
   * @param objectData The updated object or array to submit as an HTTP payload
   * @param arrayName The name to give the array parameter, if domainObject is an array
  **********************************************************************************************************************************************/
  updateObjectCustomObj$ = createEffect(() => this.actions$.pipe(
      ofType(DynamicObjectActionTypes.UPDATE_OBJECT_CUSTOM_OBJ), //public payload: { IUpdateObjectById }
      map((action: fromDynamicObject.UpdateObjectCustomObj) => action.payload.updateData),
      switchMap((updateData: UpdateObjectCustomModel ) => {
        const keyData: IKey = updateData.keyData;
        return this.domainObjectService
          .updateByMethod(updateData.objectType, updateData.methodName, updateData.objectData, updateData.arrayName)
          .pipe(
            mergeMap((data) => {
              const returnData = { keyData: keyData, responseBase: data, event: updateData.event };
              return [
                { type: DynamicObjectActionTypes.UPDATE_COMPLETE_OBJ, payload: returnData }
              ];
            }))
            //catchError(error => {
            //  return [
            //    { type: fromDynamicObject.SET_ERROR_RETURNED_OBJ, payload:  { storeName: keyData.storeName, parentId: updateData.keyData.parentId, error: "Update record failed" } },
            //  ];
            //});
      })
  )
  );


  /*********************************************************************************************************************************************
    CREATE_OBJECT Effect
   * @param objectType The object type whose controller implements the custom "update" method
   * @param methodName The name of the custom "update" method to call
   * @param objectData The updated object to submit as an HTTP payload
   * @param arrayName The name to give the array parameter, if domainObject is an array
  **********************************************************************************************************************************************/
  createObjectObj$ = createEffect(() => this.actions$.pipe(
      ofType(DynamicObjectActionTypes.CREATE_OBJECT_OBJ), //public payload: { CreateObjectModel }
      map((action: fromDynamicObject.CreateObjectObj) => action.payload.createData),
      //tap((createData: CreateObjectModel) => {
      //    this.store.dispatch(new fromDynamicObject.SetWorkingObj({storeName: createData.storeName, objectId: createData.parentId}));
      //}),
      switchMap((createData: CreateObjectModel) => {
           return this.domainObjectService
             .createByMethod(createData.objectType, createData.methodName, createData.objectData, createData.arrayName)
             .pipe(
               mergeMap((data) => {
                 const returnData = { storeName: createData.storeName,objectId: createData.parentId, responseBase: data, event: createData.event };
                 return [
                   { type: DynamicObjectActionTypes.CREATE_COMPLETE_OBJ, payload: returnData }
                 ];
               }))
               //catchError(error => {
               //   return [
               //     { type: fromDynamicObject.SET_ERROR_RETURNED_OBJ, payload:  { storeName: createData.storeName, parentId: createData.parentId, error: "Create record failed" } },
               //   ];
               //});
      })
  )
  );

  /*********************************************************************************************************************************************
    DELETE_OBJECT_BY_URL Effect
   @param url to call to delete - will include the id in it.  metaData.crud.deleteUrl
  **********************************************************************************************************************************************/
  deleteObjectByUrlObj$ = createEffect(() => this.actions$.pipe(
       ofType(DynamicObjectActionTypes.DELETE_OBJECT_BY_URL_OBJ) ,
        map((action: fromDynamicObject.DeleteObjectByUrlObj) => action.payload.deleteData),
        switchMap((deleteData: DeleteObjectModel) => {
          // Strip and reformat the domainObject so that it's valid to be passed as an HTTP payload.
          return this.domainObjectService
            .delete(deleteData.url)
            .pipe(
              //map(data => new fromDynamicObject.DeleteComplete({  keyData: deleteData.keyData, responseBase: data }))
              mergeMap((data) => {
                const returnData = {  keyData: deleteData.keyData, responseBase: data };
                return [
                  { type: DynamicObjectActionTypes.DELETE_COMPLETE_OBJ, payload: returnData }
                ];
              }))
              //catchError(error => {
              //  return [
              //    { type: fromDynamicObject.SET_ERROR_RETURNED_OBJ, payload:  { storeName: deleteData.keyData.storeName, parentId: deleteData.keyData.parentId, error: "Delete record failed" } },
              //  ];
              //});
      })
  )
  );
    
  /*********************************************************************************************************************************************
*CHECK_FOR_STALE_DATA 
* Check to see if the parent entity is stale and return the result to the store to handle
**********************************************************************************************************************************************/
  checkForStale$ = createEffect(() => this.actions$.pipe(
    ofType<fromDynamicObject.CheckForStaleDataObj>(DynamicObjectActionTypes.CHECK_FOR_STALE_DATA_OBJ), 
    mergeMap((action) => {
      let params: IHomDictionary[] = [];
      params.push(new HomDictionary('entityId',  action.payload.entityId.toString() ));
      params.push(new HomDictionary('pkId', action.payload.objectId.toString() ));
      params.push(new HomDictionary('rowVersion', action.payload.rowVersion));
      params.push(new HomDictionary('aggregateRootVersion', action.payload.aggregateRootVersion));

      return this.domainObjectService.getByMethodParams('Entity', 'IsCurrent', params)
        .pipe(
          filter(Boolean),
          mergeMap((result) => {
              return [
                {
                  type: fromDynamicObject.DynamicObjectActionTypes.SET_STALE_DATA_CHECK_RESULT_OBJ,
                  payload: {
                    storeName: action.payload.storeName,
                    objectId: action.payload.objectId,
                    forRow: false,
                    responseBase: result
                  }
                }
              ]
            }
          ))
    })
  )
  );


}

