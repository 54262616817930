<div class="portal" [ngClass]="{ 'loading-mask': working$ | async }">
  <fw-component-title title='Delete Reason'></fw-component-title>
  <form *ngIf="form" [formGroup]="form" autocomplete="off" method="post"
        novalidate class="form delete-reason"
        (ngSubmit)="onCreate()">
    <div class="float-label">
      <input type="text" id="noteSubject" formControlName="noteSubject"
             placeholder="Subject"
             autoComplete="off"
             maxlength="100"
             [ngClass]="{'has-error': !form.controls['noteSubject'].valid && form.controls['noteSubject'].touched }" />
      <label for="email">Subject</label>
    </div>
    <div *ngIf="!form.controls['noteSubject'].valid && form.controls['noteSubject'].touched" class="alert-box alert-box--danger">
      Subject is required
    </div>
    <div class="float-label">
      <textarea id="noteText" formControlName="noteText"
                placeholder="Note"
                autoComplete="off"
                maxlength="4000"
                rows="4"
                columns="40"
                [ngClass]="{'has-error': !form.controls['noteText'].valid }"></textarea>
      <label for="noteText">Note</label>
    </div>
    <div *ngIf="!form.controls['noteText'].valid && form.controls['noteText'].touched" class="alert-box alert-box--danger">
      Note is required
    </div>
  </form>

  <div class="app-btn-box">
    <button class="app-btn app-btn--cancel" type="button" (click)="onCancel()">Cancel</button>
    <button class="app-btn app-btn--primary" type="button" [disabled]="!form.dirty || !form.valid" (click)="onCreate()">Create</button>
  </div>
  <fw-error-box [errorData]="errorData$ | async" [errors]="" [onForm]="true" [ignoreKey]="true"></fw-error-box>
  <div class="app-info">
    <i class="fas fa-info-circle app-icon--primary"></i>
    A note is required before the item can be deleted.
  </div>

</div>


