export interface INotification {
  notificationId: number;
  entityLabel: string;
  note_noteId: number;
  providerUser_providerUserId: number,
  isEmail: boolean,
  acknowledged: boolean;
  emailVerificationId: string;
  updateDate: string;
  createUserName: string;
  recipientUserName: string;
  context: { entityLabel: string, entityName: string, portalId: number, noteContext: string };
}

export class Notification implements INotification {
  notificationId: number;
  entityLabel: string;
  note_noteId: number;
  providerUser_providerUserId: number;
  isEmail: boolean;
  acknowledged: boolean;
  emailVerificationId: string;
  updateDate: string;
  createUserName: string;
  recipientUserName: string;
  context: { entityLabel: string, entityName: string, portalId: number, noteContext: string };

  constructor() {
    this.notificationId = 0;
    this.note_noteId = 0;
    this.providerUser_providerUserId = null;
    this.isEmail = false;
    this.acknowledged = false;
    this.emailVerificationId = null;
    this.updateDate = '';
    this.createUserName = '';
    this.recipientUserName = '';
    this.context = { entityLabel:  '', entityName: '', portalId: 0, noteContext: '' };
  }
}
