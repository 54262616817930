import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { Subscription,  BehaviorSubject, Observable, of } from 'rxjs';
import { take, map, filter } from 'rxjs/operators';
import { cloneDeep, orderBy } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IErrorData, IResponseBase } from '../../../../../shared/interfaces';
import {  IDashboardCount } from '../../../portal-shared/interfaces';
import { IProviderLocation, IBranch, IPortalTabContainer, IPortalTab, IProviderLocationBranch } from '../../../view-models/index';
import { IProviderUserCountViewModel } from '../../../portal-shared/interfaces/i-provider-user-count-view-model';
import { UserDashCountType, UserDashTabLabel, UserDashStore } from '../../enums/user-dashboard.enums';
import { IListDefinition, IListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { IDashSearchTerm } from '../../interfaces/i-dash-search-term';
import { DynamicListEvent } from '../../../../../fw/dynamic-list/enums/dynamic-list.enum';
import {
  IMultiSelectOption, MultiSelectSettings,
  MultiSelectTexts, MultiSelectDropdown
} from '../../../../../fw/fw-shared/components/fw-multi-select-dropdown';
import { appConstants, IAppConstants } from '../../../../../shared/constants';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DomainObjectService } from '../../../../../shared/services';
import { UserPriviledgesService } from '../../../../../auth/services';
import { SharedDashboardService } from '../../../portal-shared/services/shared-dashboard.service';
import { getTabContainerByName } from '../../../../../auth/store';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import { UserDashboardService } from '../../services/user-dashboard.service';
import { TabsService } from '../../../../../fw/fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';

@Component({
  selector: 'user-dashboard',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-dashboard.component.html'
})
export class UserDashboardComponent implements OnInit, OnDestroy {
  public managerPortalId: number = 2;
  public tabContainerName = 'user-dash';
  public title: string = 'User Dashboard';
  public errorMessage: string = '';
  public pendingTasksLabel: string;
  public sssInvitesLabel: string;
  public dashCounts$: BehaviorSubject<IDashboardCount[]> = new BehaviorSubject(null);
  public filterApplied: boolean = false;
  public providerLocations: IProviderLocation[] = null;
  public branches: IBranch[] = null;
  public multiSelectLabels: MultiSelectTexts = new MultiSelectTexts();
  public multiSelectSettings: MultiSelectSettings = new MultiSelectSettings();
  public locationOptions: IMultiSelectOption[] = [];
  public branchOptions$: BehaviorSubject<IMultiSelectOption[]> = new BehaviorSubject([])
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$: Observable<boolean> = of(true);
  @ViewChild('locations') public locMultiSelect: MultiSelectDropdown;
  @ViewChild('branches') public branchMultiSelect: MultiSelectDropdown;

  subscription = new Subscription();
  myTabContainer: IPortalTabContainer;
  allProviderLocationBranches: IProviderLocationBranch[] = null;
  
  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public domainObjectService: DomainObjectService,
    public userPriviledgesService: UserPriviledgesService,
    public userDashService: UserDashboardService,
    public sharedDashboardService: SharedDashboardService,
    public appTabsService: TabsService,
    public datePipe: DatePipe,
    @Inject(appConstants) public myConstants: IAppConstants
    ) { }

  public ngOnInit() {
    this.pendingTasksLabel = UserDashTabLabel.myPendingTasks;
    this.sssInvitesLabel = UserDashTabLabel.sssInvitesRequiringAction;
    const dashStores: string[] = Object.keys(UserDashStore);
    this.loading$ = this.rootStore.select('loadingIndicator').pipe(
      filter(x => dashStores.includes(x.requestor)),
      map(x => x.show));

    this.getTabContainer();

    this.activeRoute.paramMap.subscribe(() => {
      this.newRequest();
    });

  }

 public openLink(componentName: string): void {
    const label: string = componentName === 'SssInvitesRequiringActionComponent' ? UserDashTabLabel.sssInvitesRequiringAction : UserDashTabLabel.myPendingTasks;
    this.sharedDashboardService.openDashboardTab(this.managerPortalId, label, componentName, 'user-dashboard');
  }

  //wait for existance of tabs before start looping through count api calls
  //for better ui experience
  public onTabsLoaded(loaded: boolean): void {
    if (loaded) {
      if (!this.userDashService.lastDashCounts || this.userDashService.lastDashCounts.length === 0) {
        this.loadCounts();
      }
    }
  }

  public addLocation(id: number): void {
    const loc: IProviderLocation = this.providerLocations.find(x => x.providerLocationId == id);
    if (!loc) {
      return;
    }
    const exists: IDashSearchTerm = this.userDashService.filterForLocations.find(x => x.listId === id);
    if (!exists) {
      this.loadPLBranches(id, true, []);
      this.userDashService.filterForLocations = [...this.userDashService.filterForLocations, { listId: loc.providerLocationId, label: loc.locationName }];
    }
  }

  public removeLocation(id: number): void {
    const loc: IProviderLocation = this.providerLocations.find(x => x.providerLocationId == id);
    if (!loc) {
      return;
    }
    const exists: number = this.userDashService.filterForLocations.findIndex(x => x.listId === id);
    if (exists > -1) {
      this.loadPLBranches(id, false, []);
      this.userDashService.filterForLocations.splice(exists, 1);
    }
  }

  //remove all branches;
  public uncheckedAllLocations(): void {
    if (this.branchMultiSelect) {
      this.branchMultiSelect.uncheckAll();
    }
  }

  public addBranch(id: number): void {
    const plBranch: IProviderLocationBranch = this.allProviderLocationBranches.find(x => x.providerLocationBranchId == id);
    if (!plBranch) {
      return;
    }
    const exists: IDashSearchTerm = this.userDashService.filterForBranches.find(x => x.listId === id);
    if (!exists) {
      this.userDashService.filterForBranches = [...this.userDashService.filterForBranches,
        {
          listId: plBranch.providerLocationBranchId,
          label: plBranch.branchName,
          providerLocationId: plBranch.providerLocation_providerLocationId,
          branchId: plBranch.branch_branchId
        }];
    }
  }

  public removeBranch(id: number): void {
    const plBranch: IProviderLocationBranch = this.allProviderLocationBranches.find(x => x.providerLocationBranchId == id);
    if (!plBranch) {
      return;
    }
    const exists: number = this.userDashService.filterForBranches.findIndex(x => x.listId === id);
    if (exists > -1) {
      this.userDashService.filterForBranches.splice(exists, 1);
    }
  }

  public runFilter(clearing: boolean): void {
    this.filterApplied = clearing ? false : true;
    this.dispatchReload();
    this.loadCounts();
  }

  getTabContainer(): void {
    this.subscription.add(this.rootStore.pipe(select(getTabContainerByName(this.tabContainerName)), take(1))
      .subscribe((container: IPortalTabContainer) => {
        this.myTabContainer = container;
      }));
  }

  newRequest() {
    this.multiSelectLabels = new MultiSelectTexts();
    this.multiSelectSettings = new MultiSelectSettings();
    this.multiSelectSettings.selectionLimit = 0;
    this.multiSelectSettings.closeOnSelect = false;
    this.multiSelectSettings.autoUnselect = false;
    this.multiSelectSettings.showCheckAll = true;

    this.getSelectionLists();
   if (this.userDashService.lastDashCounts && this.userDashService.lastDashCounts.length > 0) {
      this.dashCounts$.next(this.userDashService.lastDashCounts);
    } else {
      this.setDashCounts();
    }
  }

  getSelectionLists(): void {
    const locTerms: IDashSearchTerm[] = this.userDashService.filterForLocations;
    const branchTerms: IDashSearchTerm[] = this.userDashService.filterForBranches;

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('providerLocation')))
      .subscribe((data) => {
        if (data) {
            this.providerLocations = data;
            this.locationOptions = [];
          data.forEach((loc: IProviderLocation) => {
            let selected: boolean = false;
            if (locTerms) {
              const match = locTerms.find(x => x.listId == loc.providerLocationId);
              selected = match ? true : false;
            }
            this.locationOptions.push({ id: loc.providerLocationId, name: loc.locationName, selected: selected });
          });
        }
      }));

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('providerLocationBranch')))
      .subscribe((data) => {
        this.branchOptions$.next([]);
       if (data) {
         this.allProviderLocationBranches = data;
         locTerms.forEach(locTerm => {
           const selectedPLBIds: number[] = branchTerms && branchTerms.length > 0
             ? branchTerms.filter(x => x.providerLocationId === locTerm.listId).map(x => x.listId)
             : [];
           this.loadPLBranches(locTerm.listId, true, selectedPLBIds);
         });
        }
      }));
  }

  //Load Provider Location Branches for the selected provider loction
  loadPLBranches(providerLocationId: number, addLocation: boolean, selectedPLBIds: number[]): void {
    let options = cloneDeep(this.branchOptions$.value);
    const plbs: IProviderLocationBranch[] = this.allProviderLocationBranches.filter(x => x.providerLocation_providerLocationId === providerLocationId);

    plbs.forEach(plb => {
      const indx: number = options.findIndex(x => x.id === plb.providerLocationBranchId);
      const selected: number = selectedPLBIds.findIndex(x => x === plb.providerLocationBranchId);
      if (addLocation) {
        if (indx === -1) {
          const name: string = plb.locationName.trim() === plb.branchName.trim()
            ? plb.branchName.concat(' ', plb.generalContractorName)
            : plb.locationName.concat(' ', plb.branchName);
          options.push({ id: plb.providerLocationBranchId, name: name, selected: selected > -1 });
        }
      } else {
        if (indx > -1) {
          options.splice(indx, 1);
        }
      }
    });

    if (!addLocation) {
      this.userDashService.filterForBranches = this.userDashService.filterForBranches.filter(x => x.providerLocationId != providerLocationId);
    }
    this.branchOptions$.next(orderBy(options, 'name'));
  }

  setDashCounts(): void {
    const tomorrow: string = this.datePipe.transform(new Date().addDays(1), 'MM/dd');

    //load up the count types to get
    const dashCounts: IDashboardCount[] = [
      {
        type: UserDashCountType.openProjectCount, label: UserDashTabLabel.myOpenProjects, value: -1,
        fromPortalLabel: 'User Dashboard', fromPortalId: this.managerPortalId,
        fromComponentName: this.getComponentName(UserDashCountType.openProjectCount)
      },
      {
        type: UserDashCountType.unreceivedInventoryCount, label: UserDashTabLabel.myUnreceivedInventory, value: -1,
        fromPortalLabel: 'User Dashboard', fromPortalId: this.managerPortalId,
        fromComponentName: this.getComponentName(UserDashCountType.unreceivedInventoryCount)
      },
      {
        type: UserDashCountType.unacknowledgedExternalNoteCount, label: UserDashTabLabel.myOpenExternalNotes, value: -1,
        fromPortalLabel: 'User Dashboard', fromPortalId: this.managerPortalId,
        fromComponentName: this.getComponentName(UserDashCountType.unacknowledgedExternalNoteCount)
      },
      {
        type: UserDashCountType.unassignedExternalNoteCount, label: UserDashTabLabel.unassignedExternalNotes, value: -1,
        fromPortalLabel: 'User Dashboard', fromPortalId: this.managerPortalId,
        fromComponentName: this.getComponentName(UserDashCountType.unassignedExternalNoteCount)
      },
      {
        type: UserDashCountType.toBeScheduledScheduleCount, label: UserDashTabLabel.myUnscheduledWorkOrders, value: -1, 
        fromPortalLabel: 'User Dashboard', fromPortalId: this.managerPortalId,
        fromComponentName: this.getComponentName(UserDashCountType.toBeScheduledScheduleCount)
      },
      {
        type: UserDashCountType.toBeDispatchedCount, label: UserDashTabLabel.myUndispatchedProjects.concat(' for ', tomorrow), value: - 1,
        fromPortalLabel: 'User Dashboard', fromPortalId: this.managerPortalId,
        fromComponentName: this.getComponentName(UserDashCountType.toBeDispatchedCount)
      },
      {
        type: UserDashCountType.dispatchScheduleCount, label: UserDashTabLabel.myDispatchedProjects, value: -1,
        fromPortalLabel: 'User Dashboard', fromPortalId: this.managerPortalId,
        fromComponentName: this.getComponentName(UserDashCountType.dispatchScheduleCount)
      },
      {
        type: UserDashCountType.pendingCloseCount, label: UserDashTabLabel.myPendingCloseProjects, value: -1,
        fromPortalLabel: 'User Dashboard', fromPortalId: this.managerPortalId,
        fromComponentName: this.getComponentName(UserDashCountType.pendingCloseCount)
      }
    ];

    this.dashCounts$.next(dashCounts);
  }

  getCountByType(countType: IDashboardCount, userId: number): void {
    const params = userId.toString() + '?type=' + countType.type;
    const listFilter: IListFilter = this.getListFilterByType(countType.type);

    this.subscription.add(this.domainObjectService.getListByMethod('ProviderUserCountViewModel', 'GetUserCountByType', params, listFilter)
      .subscribe(result => {
        if (result.success) {
          const data = <IProviderUserCountViewModel>result.data;
          let dashCounts: IDashboardCount[] = this.dashCounts$.getValue();
          let idx: number = dashCounts.findIndex(x => x.type === countType.type);
          if (idx > -1) {
            dashCounts[idx].value = data.dataCount;
          }
          this.dashCounts$.next(dashCounts);
          this.userDashService.lastDashCounts = dashCounts;
        } else {
          this.errorData$.next(result.errorData);
        }
      }, error => this.errorMessage = <any>error )
    );
  }

  loadCounts(): void {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const dashCounts = this.dashCounts$.getValue();

    dashCounts.forEach(type => {
      this.getCountByType(type, currentUserId);
    })
  }

  getComponentName(countType: string): string {
    switch (countType) {
      case UserDashCountType.openProjectCount:
        return 'OpenProjectsComponent';
      case UserDashCountType.unreceivedInventoryCount:
        return 'UnreceivedInventoryComponent';
      case UserDashCountType.unacknowledgedExternalNoteCount:
        return 'OpenExternalNotesComponent';
      case UserDashCountType.unassignedExternalNoteCount: 
        return 'UnassignedExternalNotesComponent';
      case UserDashCountType.toBeScheduledScheduleCount:
        return 'UnscheduledWorkOrdersComponent';
      case UserDashCountType.toBeDispatchedCount:
        return 'UndispatchedWorkOrdersComponent';
      case UserDashCountType.dispatchScheduleCount:
        return 'DispatchedWorkOrdersComponent';
      case UserDashCountType.pendingCloseCount:
        return 'PendingClosedProjectsComponent';
      default:
        return '';
    }
  }

  getListDefinition(componentName: string): IListDefinition {
    switch (componentName) {
      case 'OpenProjectsComponent':
       return this.userDashService.loadOpenProjectsListDefinition();
      case 'UnreceivedInventoryComponent':
        return this.userDashService.loadUnreceivedInventoryListDefinition();
      case 'OpenExternalNotesComponent':
        return this.userDashService.loadOpenExternalNotesListDefinition();
      case 'UnassignedExternalNotesComponent':
        return this.userDashService.loadUnassignedExternalNotesListDefinition();
      case 'UnscheduledWorkOrdersComponent':
        return this.userDashService.loadUnscheduledWorkOrdersListDefinition();
      case 'UndispatchedWorkOrdersComponent':
        return this.userDashService.loadUnDispatchedWorkOrdersListDefinition();
      case 'DispatchedWorkOrdersComponent':
        return this.userDashService.loadDispatchedWorkOrdersListDefinition();
      case 'PendingClosedProjectsComponent':
        return this.userDashService.loadPendingCloseProjectsListDefinition();
      case 'PendingTasksComponent':
        return this.userDashService.loadPendingTasksListDefinition();
      default:
        return null;
    }
  }

  getActiveTab(): IPortalTab {
    const urlPart: string[] = this.router.url.split('portal-detail:');
    const routeName: string = urlPart[urlPart.length - 1].replace(')', '');
    const activeTab: IPortalTab = this.myTabContainer.portalTabs.find(x => x.routeName == routeName);

    return activeTab;
  }

  //determine the active tab and reload with the dash filters, if any
  dispatchReload(): void {
    var activeTab: IPortalTab = this.getActiveTab();
    const allComponents: string[] = ['OpenProjectsComponent', 'UnreceivedInventoryComponent', 'OpenExternalNotesComponent',
      'UnassignedExternalNotesComponent', 'UnscheduledWorkOrdersComponent', 'UndispatchedWorkOrdersComponent', 'DispatchedWorkOrdersComponent',
      'PendingClosedProjectsComponent', 'PendingTasksComponent'];

    allComponents.forEach(name => {
      const listDef: IListDefinition = this.getListDefinition(name);
      if (listDef) {
        if (activeTab && activeTab.componentName === name) {
          let event: IHomEventEmitter = {
            requestor: 'user-dashboard',
            event: DynamicListEvent.applyFilter,
            action: this.myConstants.emitterActionClearCurrent,
            data: null
          };
          this.store.dispatch(new fromStore.SetWorkingList({ storeName: listDef.storeName, parentId: listDef.parentId, working: true }));
          this.store.dispatch(new fromStore.SetListDefinition({ storeName: listDef.storeName, listDefinition: listDef, parentId: listDef.parentId }));
          this.store.dispatch(new fromStore.GetList({
            listDefinition: listDef,
            listFilter: listDef.defaultListFilter,
            parentId: listDef.parentId,
            event: event
          }));

        } else {
          this.store.dispatch(new fromStore.InitializeList({ storeName: listDef.storeName, parentId: listDef.parentId, forOnDestroy: false }));
        }
      }
    });
  }

  getListFilterByType(countType: string): IListFilter {
    let def: IListDefinition = null;
    switch (countType) {
      case UserDashCountType.openProjectCount:
        def = this.userDashService.loadOpenProjectsListDefinition();
        break;
      case UserDashCountType.unreceivedInventoryCount:
        def = this.userDashService.loadUnreceivedInventoryListDefinition();
        break;
      case UserDashCountType.unacknowledgedExternalNoteCount:
        def = this.userDashService.loadOpenExternalNotesListDefinition();
        break;
      case UserDashCountType.unassignedExternalNoteCount:
        def = this.userDashService.loadUnassignedExternalNotesListDefinition();
        break;
      case UserDashCountType.toBeScheduledScheduleCount:
        def = this.userDashService.loadUnscheduledWorkOrdersListDefinition(true);
        break;
      case UserDashCountType.toBeDispatchedCount:
        def = this.userDashService.loadUnDispatchedWorkOrdersListDefinition();
        break;
      case UserDashCountType.dispatchScheduleCount:
        def = this.userDashService.loadDispatchedWorkOrdersListDefinition(true);
        break;
      case UserDashCountType.pendingCloseCount:
        def = this.userDashService.loadPendingCloseProjectsListDefinition();
        break;
      default:
        break;
    }
    return def ? def.defaultListFilter : null;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
