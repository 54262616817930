import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomErrorHandlerService, HomErrorLevel } from 'hom-lib/hom-error-logger';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { WorkOrder, IWorkOrder, IProviderLocation, IProject } from '../../../view-models/index';
import { ProjectObjectStore } from '../../enums/project.enums';
import { IService } from '../../../view-models/index';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { IListObjectData, IDynamicListState } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import { CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IListFilter, ListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { getSelectionListDataByType, GetEntityListById } from '../../../../../shared/store/selectionLists/index';
import { getObjectDataById } from '../../../../../fw/dynamic-list/store/index';

@Component({
  selector: 'project-work-order-create-container',
  templateUrl: './project-work-order-create-container.component.html'
})
export class ProjectWorkOrderCreateContainerComponent implements OnInit, OnDestroy {
  public projectId: number = -1;
  public myStoreName: string = '';
  public requestTime: string = '';
  public title: string = 'Project Work Order';
  public displayFields: string[] = ['providerLocation', 'service'];
  public providerLocations$: BehaviorSubject<IProviderLocation[]> = new BehaviorSubject(null);
  public services$: BehaviorSubject<IService[]> = new BehaviorSubject(null);
  project$: BehaviorSubject<IProject> = new BehaviorSubject(null);
  myPathName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  controllerName: string;
  canIEdit: boolean = false;
  subscription: Subscription = new Subscription();
  svcSub: Subscription;
  projectSub: Subscription;
  plSub: Subscription;

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public modalService: ModalService,
    public emitterService: HomEventEmitterService,
    public errorHandlerService: HomErrorHandlerService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) { }

  //get the services for this location
  public onLocationChange(event: { id: number, listFilter: IListFilter }): void {
    this.services$.next([]);
    if (event.id && event.id > 0) {
      this.getServicesByProviderLocation(event.id, event.listFilter);
    }
  }

  public onCancel(): void {
    if (this.modalService.opened) {
      this.modalService.close();
    } else {
      this.store.dispatch(new DynamicListActions.ClearErrorsList({ storeName: this.myStoreName, parentId: this.projectId }));

      let emitter: IHomEventEmitter = { requestor: this.myPathName, event: this.myConstants.emitterEventClose, action: '', data: null };
      this.emitterService.emitListEvent(emitter);
    }
  }

  public onCreateRecord(e: IHomEventEmitter) {
    let rec: IWorkOrder = new WorkOrder();
    const formData: IWorkOrder = e.data;
    rec.project_projectId = this.projectId;
    rec.providerLocation_providerLocationId = formData['providerLocation']['providerLocationId'];
    rec.service_serviceId = formData['service']['serviceId'];
    rec.scheduleLocked = false;
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const createData = new CreateObjectModel(this.myStoreName, this.projectId, this.controllerName, 'Create', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  ngOnInit(): void {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = this.myConstants.operationTypeCreate;
      this.myStoreName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.projectId = +paramMap.get('portalEntityId');  
      this.newRequest();
    }));
  }

  newRequest(): void {
    this.operation = this.myConstants.operationTypeCreate;
    this.providerLocations$.next(null);
    this.services$.next([]); //will be loaded once location is selected
    this.getStoreData();
  }

  //data will already exist in store
  //Event and Error handling are performed in wrapper component 
  getStoreData() {
    //get project
    if (this.projectSub) {
      this.projectSub.unsubscribe();
    }
    this.projectSub = this.store.pipe(select(getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)))
      .subscribe((objData: IProject) => {
        const project = this.project$.value;
        if (objData && this.projectId === objData.projectId && !project ) {
          this.project$.next(objData);
        }
      });

    this.subscription.add(this.project$.subscribe((val: IProject) => {
      if (val) {
        if (!val.branch_branchId) {
          //log
          this.errorHandlerService.handleError({
            name: 'DataError',
            message: 'Project with id: '.concat( (val.projectId ? val.projectId.toString() : 'NULL'), ' changed with missing data in branch of: ', (val.branch_branchId ? val.branch_branchId.toString() : 'NULL'))
          }, HomErrorLevel.fatal);
        } else {
          this.getProviderLocationsByBranch(val.branch_branchId);
        }
      }
    }));

    //get wo structure info
    this.subscription.add(this.store.pipe(select(fromDynamicList.getListByType(this.myStoreName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((state: IListObjectData) => {
        const listData = cloneDeep(state);
        if (listData && listData.listDefinition) {
          //will return a record per contact mechanism
          this.myPathName = listData.listDefinition.detailRoutePath;
          this.controllerName = listData.listDefinition.controllerName;
        }
      }));
  }

  getProviderLocationsByBranch(branchId: number): void {
    let listFilter = new ListFilter();
    listFilter.filterContext = 'WorkOrder';
    listFilter.filterFor = 'ProviderLocation'; //I should only see the provider locations I have acess to for this project
    listFilter.getAll = true;
    this.providerLocations$.next(null);

    if (this.plSub) {
      this.plSub.unsubscribe();
    }
    this.plSub = this.rootStore.pipe(select(getSelectionListDataByType('projectProviderLocation', branchId)))
      .subscribe((data) => {
        if (data) {
          this.providerLocations$.next(data);
        } else {
          this.store.dispatch(new GetEntityListById('ProviderLocation', 'ByProjectBranch', branchId, listFilter, 'projectProviderLocation'));
        } 
      });
  }

  getServicesByProviderLocation(providerLocationId: number, listFilter: IListFilter): void {
    /* Subscribe to the services - triggered during change of provider location  */
    if (this.svcSub) {
      this.svcSub.unsubscribe();
    }
    this.svcSub = this.rootStore.pipe(select(getSelectionListDataByType('providerLocationService', providerLocationId)))
      .subscribe((data) => {
        if (data) {
          this.services$.next(data);
        } else {
          //dispatch
          this.store.dispatch(new fromSelectionLists.GetEntityListById('Service', 'ByProviderLocation', providerLocationId, listFilter, 'providerLocationService'));
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.svcSub) {
      this.svcSub.unsubscribe();
    }
    if (this.plSub) {
      this.plSub.unsubscribe();
    }
    if (this.projectSub) {
      this.projectSub.unsubscribe();
    }
  }
}
