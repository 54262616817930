<div *ngIf="listDefinition$ | async" class="app-modal-panel">
  <fw-dynamic-list [vmListDefinition]="listDefinition$ | async"
                   (selectEvent)="onSelect($event)"
                   (pageEvent)="onPage($event)">
  </fw-dynamic-list>
  <div class="app-btn-box" *ngIf="!showSent">
    <button class="app-btn app-btn--primary"
            type="button"
            (click)="ackAndDelete()"
            [disabled]="!selectedItems.length">
      Acknowledge
    </button>
  </div>
</div>

