/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IProject } from '../../../view-models/index';
import { ContactEvent } from '../../../../contact/enums/contact.enums';
import { IErrorData, IResponseBase } from '../../../../../shared/interfaces';
import { AppAction } from '../../../../../shared/enums/app-action.enums';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { UserPriviledgesService } from '../../../../../auth/services/index';
import { ProjectService } from '../../services/project.service';
import { ContactConstantsService, ContactUtilityService } from '../../../../contact/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { DomainObjectService } from '../../../../../shared/services';

//store actions, reducers, interfaces
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getObjectDataById } from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { ProjectObjectStore } from '../../enums/project.enums';

@Component({
  selector: 'project-contact-manager',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './project-contact-manager.component.html'
})
export class ProjectContactManagerComponent implements OnInit {
  public canIEditProject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public project$: Observable<IProject>;
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);;
  public contactId$: BehaviorSubject<number> = new BehaviorSubject(0);
  public originalContactId: number;
  public checkForMatch$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  projectId: number;
  subscription: Subscription = new Subscription();
  projSub: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromFeature.IAllDynamicData>,
    public ups: UserPriviledgesService,
    public projectService: ProjectService,
    public contactConstantsService: ContactConstantsService,
    public modalService: ModalService,
    public domainObjectService: DomainObjectService,
    public contactUtilityService: ContactUtilityService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get('id');
      this.getProject();
    });
  }

  public onCrudEvent(event: IHomEventEmitter) {
    switch (event.event) {
      case ButtonType.cancel:
       this.contactId$.next(this.originalContactId);
       this.checkForMatch$.next(false);
       break;
      case this.myConstants.emitterEventUpdateComplete:
        this.checkForMatch$.next(false);
        this.requestReload(this.contactId$.value);
        break;
      case this.myConstants.emitterEventCreateComplete:
        this.contactId$.next(event.data);
        this.updateProjectContact(event.data);
        break;
      case ContactEvent.linkContactToParent:
        //if (this.contactId$.value === 0) detail-contact will call save on contact manager.
        if (this.contactId$.value > 0) {
          this.updateProjectContact(event.data);
        }
        break;
      case ContactEvent.unLinkContactToParent:
        this.checkForMatch$.next(true);
        break;
      case ContactEvent.selectMatch:
        this.contactId$.next(event.data ? event.data.contactId : 0);
        break;
      default:
        break;
    }
  }

  getProject(): void {
    this.project$ = this.store.pipe(
      select(getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)),
      tap((objData: IProject) => {
        if (objData) {
          this.originalContactId = objData.customer_contactId;
          this.contactId$.next(objData.customer_contactId);
          this.canIEditProject$.next(this.ups.canIEdit(objData) && objData.currentProjectStatusCode !== this.myConstants.projectStatusClosed);
        }
      })
    );
  }

  updateProjectContact(contactId: number): void {
    const params: string = this.projectId + '?customerId=' + contactId.toString();
    this.errorData$.next([]);
    this.subscription.add(this.domainObjectService.updateByMethodParams('Project', 'UpdateCustomer', params)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.checkForMatch$.next(false);
          this.requestReload(contactId);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  requestReload(contactId): void {
    //just updated the project, reload the project
    //just set a new contact id, entire contact needs to be reloaded and the component needs to know what the contact id is
    this.projectService.dispatchProjectObject(this.projectId);
  }

}
