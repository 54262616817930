import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IListColumn, ListColumn, IListFilter, IDetailRouteParameter, ListFilter, OrderTerm } from '../../../../fw/dynamic-list/interfaces/index';
import { IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';
import { IDashboardCount } from '../../portal-shared/interfaces';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { WarrantyDashStore, WarrantyDashEvent } from '../enums/warranty-dashboard.enums';
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { IProject, IWorkOrderViewModel } from '../../view-models';
import { ModalSizeType } from '../../../../fw/fw-modal/interfaces/i-modal';
import { ProjectListStore } from '../../project/enums/project.enums';

import { UserPriviledgesService } from '../../../../auth/services/index';
import { TabsService } from '../../../../fw/fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';
import * as fromFeature from '../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { DynamicListService } from '../../../../fw/dynamic-list/services';

@Injectable()
export class WarrantyDashboardService {

  lastDashCounts: IDashboardCount[] = [];

  constructor(public userPriviledgesService: UserPriviledgesService,
    public tabsService: TabsService,
    public emitterService: HomEventEmitterService,
    public modalService: ModalService,
    public store: Store<fromFeature.IAllDynamicData>,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public initSavedValues(): void {
    //clean up for log off
    this.lastDashCounts = [];
  }

  loadUnassignedWarrantiesListDefinition(): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadUnassignedWarrantiesListColumns(); 
    const listObjectLabel = 'Unassigned Warranties';
    const listObjectController = 'Project';
    const listStoreName = WarrantyDashStore.dashUnassignedWarranties;
    const detailRoutePath = '';
    const listRowKeyId = 'projectId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [
      { term: 'projectStatus.LookupCode', value: 'open', searchType: SearchType.Equals, columnName: 'projectStatus', fieldType: this.myConstants.dataTypeString },
      { term: 'isWarranty', value: 'true', searchType: SearchType.Equals, columnName: 'isWarranty', fieldType: this.myConstants.dataTypeBool },
      { term: 'warrantyOwner', value: null, searchType: SearchType.Equals, columnName: 'warrantyOwner', displayValues: 'Not Assigned', fieldType: this.myConstants.dataTypeString }
    ];
    defaultListFilter.orderTerm = [new OrderTerm('startDate')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.showFilter = true;
    listDefinition.controllerMethod = 'ByProviderUserSlim';
    listDefinition.methodParameters = currentUserId.toString();
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;
    
    listDefinition.rowButtons = [
      {
        title: 'Assign User',
        icon: 'fas fa-user',
        cssName: 'app-btn-icon--primary',
        enabled: true,
        eventName: WarrantyDashEvent.showAssignUser
      }
    ];

    //no crud buttons
    return listDefinition;
  }

  loadUnassignedWarrantiesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('projectId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('warrantyTypeName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('warrantySubTypeName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('baseService');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('startDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadOpenWarrantiesListDefinition(): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const currentUserName = this.userPriviledgesService.currentUserName$.getValue();
    const listColumns = this.loadOpenWarrantiesListColumns();
    const listObjectLabel = 'My Open Warranties';
    const listObjectController = 'WarrantyViewModel';
    const listStoreName = WarrantyDashStore.dashOpenWarranties;
    const detailRoutePath = '';
    const listRowKeyId = 'uniqueId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [
      {
        term: 'projectStatus.LookupCode',
        value: 'Open',
        searchType: SearchType.Equals,
        fieldType: this.myConstants.dataTypeString,
        columnName: 'projectStatus'
      },
      {
        term: 'warranty.AssignedUser.ProviderUserId',
        value: currentUserId.toString(),
        searchType: SearchType.Equals,
        columnName: 'assignedUser',
        fieldType: this.myConstants.dataTypeInt,
        displayValues: currentUserName
      }
    ];

    defaultListFilter.orderTerm = [new OrderTerm('createDate', false)];  //warranty create date and project createdate will be the same

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;

    //readonly
    listDefinition.showFilter = true;
    listDefinition.controllerMethod = 'WithActiveWorkOrders';
    listDefinition.requiredSearchTerms = [
      { term: 'projectStatus.LookupCode', value: 'open', searchType: SearchType.Equals, columnName: 'projectStatus', fieldType: this.myConstants.dataTypeString }
    ];

    listDefinition.rowButtons = [
      {
        title: 'Peek at Purchase Orders',
        icon: 'fad fa-ballot',
        cssName: 'app-btn-icon--custom-3',
        enabled: true,
        eventName: WarrantyDashEvent.peekPOSummary
      },
      {
        title: 'Peek at Work Orders',
        icon: 'fad fa-clipboard-list-check',
        cssName: 'app-btn-icon--custom-2',
        enabled: true,
        eventName: WarrantyDashEvent.peekWOSummary
      }
    ];

    //no crud buttons
    return listDefinition;
  }

  loadOpenWarrantiesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
     
    let colDef = new ListColumn('projectId');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customer');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('assignedUser');
    colDef.visibleOnSmall = false;
   columnDefinitions.push(colDef);

    colDef = new ListColumn('originalInstallerName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectCity');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    colDef.slowSort = true;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('scheduleStartDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workCrewName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('createDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  showAssignUser(data: any): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: WarrantyDashStore.dashUnassignedWarranties,
      key: 'project_projectId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.projectId //to show wos by project id
    }

    this.modalService.open({
      title: 'Warranty Project'.concat(' ', data.projectId.toString()),
      path: 'user-dash-warranty-assign-user',
      id: data.warranty_warrantyId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

  /*Custom Methods Consumed by Multiple Lists */
  showMiniWOSummary(data: IProject | IWorkOrderViewModel): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.projectWorkOrders,
      key: 'project_projectId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.projectId //to show wos by project id
    }

    this.modalService.open({
      title: 'Work Order Summary for Project'.concat(' ', data.projectId.toString()),
      path: 'user-dash-work-order-summary',
      id: data.projectId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

  showMiniPOSummary(data: IProject | IWorkOrderViewModel): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.projectPurchaseOrders,
      key: 'project_projectId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.projectId //to show wos by project id
    }

    this.modalService.open({
      title: 'Purchase Order Summary for Project'.concat(' ', data.projectId.toString()),
      path: 'user-dash-purchase-order-summary',
      id: data.projectId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

}
