export interface ISkuPrice {
  skuPriceId: number;
  sku_skuId: number;
  isGlobal: boolean;
  programService_programServiceId: number;
  branch_branchId: number;
  region_regionId: number;

  //extra data 
  skuName: string;
  billingPrice: number;
  unitMeasureId: number;
  skuTypeId: number;
  maxEffectiveDate: string;
  effectiveDate: string;
  skuDeckId: number;
  generalContractoId: number;
  errorText: string
}

export class SkuPrice implements ISkuPrice {
  skuPriceId: number;
  sku_skuId: number;
  isGlobal: boolean;
  programService_programServiceId: number;
  branch_branchId: number;
  region_regionId: number;

  skuName: string;
  billingPrice: number;
  unitMeasureId: number;
  skuTypeId: number;
  maxEffectiveDate: string;
  effectiveDate: string;
  skuDeckId: number;
  generalContractoId: number;
  errorText: string

  constructor() {}
}


export interface ISkuPriceUpdateModel {
  skuPriceId: number;
  skuPrice: number;
}
