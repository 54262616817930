<div class="portal">
  <div class="portal__detail--item">
    <fw-dynamic-list *ngIf="listDefinition$ | async"
                     [listTitle]="'My UnDispatched Work Orders'"
                     [vmListDefinition]="listDefinition$ | async"
                     [infoText]="'Filtering by <b>Start Date</b> will return work orders that are scheduled to start on that date or should be in progress on that date.'"
                     (customEvent)="onCustom($event)">
    </fw-dynamic-list>
  </div>
</div>
