import { Injectable, OnDestroy} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, map, filter , first } from 'rxjs/operators';

import { IListFilter, ListFilter } from '../../../fw/dynamic-list/interfaces/index';
import { IProviderUser } from '../view-models/index';
import { AccessLevel } from '../../../fw/dynamic-list/enums/access-level.enums';

//store actions and reducers
import * as fromSelectionLists from '../../../shared/store/selectionLists/index';

@Injectable({
  providedIn: 'root',
})
export class AllProviderUsersResolver  implements OnDestroy {
  storeName: string = 'providerUser';
  filter: IListFilter;
  subscription: Subscription = new Subscription();

  constructor( public store: Store<fromSelectionLists.IStoreState> ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProviderUser[]> {
    this.filter = new ListFilter();
    this.filter.getAll = true;
    //this.filter.orderTerm = 'entityLabel';

    this.filter.accessLevel = AccessLevel.ReadOnly;
    this.requestData();
    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<IProviderUser[]> {
    return this.store.select(fromSelectionLists.getSelectionListDataByType(this.storeName, -1))
      .pipe(
        filter((data: any[]) => data !== null),
        map((data: any[]) => { return data; }),
        first()
      );
  }

  requestData(): void {
    this.subscription.add(this.store.select(fromSelectionLists.getSelectionListDataByType(this.storeName, -1))
      .pipe(take(1))
      .subscribe((data: any[]) => {
        if (data === null) {
          this.store.dispatch(
            new fromSelectionLists.GetEntityList('ProviderUser', this.filter, this.storeName ));
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
