<div class="file-tools">
  <button *ngFor="let tool of tools"
          type="button"
          class="app-btn-icon {{tool.color ? tool.color : 'app-btn-icon--grey-dark-4'}}"
          title="{{tool.title}}"
          [disabled]="tool.disabled"
          (click)="click(tool.event)">
    <i class="{{tool.icon}}"></i>
  </button>
</div>
