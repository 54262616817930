export enum ProjectObjectStore {
  projectInformation = 'projectInformation',
  workOrderInformation = 'workOrderInformation',
  warrantyInformation = 'warrantyInformation'
}

export enum ProjectListStore {
  projectHistory = 'projectHistory',
  projectPurchaseOrderItems = 'projectPurchaseOrderItems',
  projectWorkOrderItems = 'projectWorkOrderItems',
  projectPurchaseOrders = 'projectPurchaseOrders',
  projectWorkOrders = 'projectWorkOrders',
  projectChargeBacks = 'projectChargeBacks',
  projectChargeBackItems = 'projectChargeBackItems',
  projectNotes = 'projectNotes',
  projectUnreceivedInventory = 'projectUnreceivedInventory',
  workOrderCallLogResponses = 'workOrderCallLogResponses',
  workOrderItems = 'workOrderItems',
  workOrderWarranties = 'workOrderWarranties',
  purchaseOrderItems = 'purchaseOrderItems',
  chargeBackItems = 'chargeBackItems',
  purchaseOrderExternalData = 'purchaseOrderExternalData',
  purchaseOrderFiles = 'purchaseOrderFiles',
  purchaseOrderMeasures = 'purchaseOrderMeasures',
  projectFiles = 'projectFiles',
  projectCommunicationEvents = 'projectCommunicationEvents',
  communicationEventActions = 'communicationEventActions',
  projectContactMechanisms = 'projectContactMechanisms',
  projectEntityDocuments = 'projectEntityDocuments',
  projectAQTChanges = 'projectAQTChanges',
  workOrderAQTChanges = 'workOrderAQTChanges'
}

export enum ProjectDependentSelectListStore {
  workOrder = 'workOrder',
  purchaseOrder = 'purchaseOrder'
}

export enum ProjectCalculatedProperty {
  calcWOItemTotal = 'calcWOItemTotal',
  calcPOBalance = 'calcPOBalance',
  calcPOItemTotal = 'calcPOItemTotal'
}
export enum ProjectEvent {
  addNote = 'addNote',
  openWarrantyScript = 'openWarrantyScript',
  logCustomerAction = 'logCustomerAction',
  closeWorkOrder = 'closeWo',
  peekWOItemSummary = 'peekWOItemSummary',
  showWOPacket = 'showWOPacket',
  uploadExternalDoc = 'UploadExternalDoc',
  viewMeasures = 'viewMeasures', //TAGGED FOR REMOVAL WITH DOC MGT IMPLEMENTATION
  peekPOItemSummary = 'peekPOItemSummary',
  closePurchaseOrder = 'closePurchaseOrder',
  closePOAndBypassPriceCheck = 'closePOAndBypassPriceCheck',
  selectMeasure = 'selectMeasure',
  downloadFile = 'downloadFile',
  launchFileViewer = 'launchFileViewer',
  receiveInventory = 'receiveInventory',
  deleteWorkOrder = 'deleteWorkOrder',
  printChargeBackNotice = 'printChargeBackNotice',
  deletePurchaseOrder = 'deletePurchaseOrder',
  viewExternalData = 'viewExternalData',
  printInstallerWarrantyNotice = 'printInstallerWarrantyNotice',
  deleteChargeBack = 'deleteChargeBack',
  uploadDocument = 'uploadDocument',
  signDocument = 'signDocument',
  printDocument = 'printDocument',
  uploadMeasure = 'uploadMeasure',
  fillForm = 'fillForm',
  showFile = 'showFile',
  generateFile = 'generateFile',
  markActionComplete = 'markActionComplete',
  deleteEntityDocument = 'deleteEntityDocument',
  deleteProject = 'deleteProject',
  reopenProject = 'reopenProject',
  reloadProject = 'reloadProject'
}

export enum ProjectStatus {
  open = 'Open',
  closed = 'Closed'
}
