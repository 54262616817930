<div [ngClass]="{ 'loading-mask':  (loading$ | async) }">
  <file-uploader *ngIf="entityDocumentId <= 0 || (entityDocumentId > 0 && entityDocument)"
                 [entityDocument]="entityDocument"
                 [allowedTypes]="allowedFileTypes"
                 (addEvent)="onAddEvent($event)">
  </file-uploader>
  <file-previewer *ngIf="(uploads$ | async)"
                  #filePreviewer
                  [entityDocument]="entityDocument"
                  [files]="(uploads$ | async)"
                  [allowMultiple]="entityDocumentId <= 0"
                  [listType]="listType"
                  (deleteEvent)="removeItem($event)"
                  (uploadEvent)="uploadItem($event)"
                  (changeEvent)="changeItem($event)">
  </file-previewer>
  <div class="app-btn-box">
    <button *ngIf="showCancel" class="app-btn app-btn--danger"
            title="Cancel"
            (click)="close()">
      Cancel
    </button>
    <button *ngIf="entityDocumentId <= 0"
            title="Upload all"
            class="app-btn app-btn--primary"
            [disabled]="!(uploads$ | async).length ? true : false"
            (click)="uploadAll()">
      Upload all
    </button>
    <button *ngIf="entityDocumentId <= 0"
            title="Remove all"
            class="app-btn app-btn--danger"
            [disabled]="!(uploads$ | async).length ? true : false"
            (click)="removeAll()">
      Remove all
    </button>
  </div>
</div>
