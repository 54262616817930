import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { IKey, CreateObjectModel, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { ILookupType, LookupType } from '../../../view-models/i-lookup-type';

import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';

import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'lookup-type',
  templateUrl: './lookup-type.component.html'
})
export class LookupTypeComponent implements OnInit, OnDestroy {

  public objectData: ILookupType;
  public displayFields = ['lookupTypeName', 'lookupTypeDescription', 'locked'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  storeName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string;
  detailRoutePath: string = '';
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) {

    this.subscription.add(emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventCreate:
            this.createRecord(e);
            break;
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          default:
            break;
        }
      }));
  }

  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.setDetailConfig(paramMap);
      this.getDetail();
    }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.storeName, -1, this.key, this.objectId)))
      .subscribe(entity => {
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate
          ? new LookupType() : entity;
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.storeName, -1)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.detailRoutePath = listDefinition.detailRoutePath;
        this.controllerName = listDefinition.controllerName;
      }
      ));
  }

  createRecord(e: IHomEventEmitter) {
    let rec: ILookupType = this.setCommonProperties(e.data);
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, -1, this.controllerName, 'Create', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  updateRecord(e: IHomEventEmitter) {
    let rec: ILookupType = this.setCommonProperties(e.data);
    const keyData: IKey = { storeName: this.storeName, parentId: -1, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  setCommonProperties(formData: ILookupType): ILookupType {
    let rec = new LookupType();
    rec.lookupTypeId = this.objectId;
    rec.lookupTypeName = formData.lookupTypeName;
    rec.lookupTypeDescription = formData.lookupTypeDescription;
    rec.provider_providerId = this.ups.providerId$.value;
    rec.locked = formData.locked;
    return rec;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
