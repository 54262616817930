import { createSelector} from '@ngrx/store';
import { flatten } from 'lodash';

import * as fromRoot from '../../app/store/reducers/root.reducer';
import * as fromAuth from './auth.reducer';
import { IWidgetManager } from '../../app/portals/portal/interfaces/index';
import { IPortalTabContainer, IUnrespondedToContactModel } from '../../app/portals/view-models/index';

export const getAuth = createSelector(
  fromRoot.getAuthState, auth => {
    return auth;
  }
);
export const getUserRecents = createSelector(
  fromRoot.getAuthState,
  (state: fromAuth.IState) => state.userRecents);


export const getInitReload = createSelector(
  fromRoot.getAuthState, auth => {
    return auth.initReload;
  }
);

export const getInitRedirect = createSelector(
  fromRoot.getAuthState, auth => {
    return auth.initRedirect;
  }
);

export const getCrmVersion = createSelector(
  fromRoot.getAuthState, auth => {
    return auth.crmVersion;
  }
);

export const getDatabaseName = createSelector(
  fromRoot.getAuthState, auth => {
    return auth.providerUser ? auth.providerUser.databaseName : null
  }
)

export const isAuthenticated = createSelector(
  fromRoot.getAuthState,
  auth =>  auth.authenticated && auth.providerUser !== null
);

export const hasUserPreferences = createSelector(
  fromRoot.getAuthState,
  auth => auth.authenticated && auth.providerUser !== null && auth.userPreferences !== null
);

export const hasUserPriviledges = createSelector(
  fromRoot.getAuthState,
  auth => auth.authenticated && auth.providerUser !== null && auth.providerUser.userPriviledges !== null
);

export const isAnonymous = createSelector(
  isAuthenticated,
  authenticated => !authenticated
);


export const hasValidSecurityAccess = createSelector(
  fromRoot.getAuthState,
  auth => auth.validUserSecurity
);


export const getAuthErrors = createSelector(
  fromRoot.getAuthState,
  auth => auth.errors
);

export const getAuthErrorData = createSelector(
  fromRoot.getAuthState,
  auth => auth.errorData
);

export const getProviderUser = createSelector(
    fromRoot.getAuthState,
    auth => auth.providerUser
)

export const isDelegate = createSelector(
  getProviderUser,
  providerUser => {
    return providerUser !== null && providerUser.hasOwnProperty('myDelegates') && providerUser.myDelegates.length > 0 ? true : false;
  }
);

export const isNotDelegate = createSelector(
  isDelegate,
  auth => !isDelegate
);


export const getCurrentUserId = createSelector(
  getProviderUser,
  providerUser => providerUser === null ? '' : providerUser.currentUserId
);

export const getCurrentUserName = createSelector(
  getProviderUser,
  providerUser => providerUser === null ? '' : providerUser.currentUserName
);

export const getProviderId = createSelector(
  getProviderUser,
  providerUser => providerUser === null ? 1 : providerUser.providerId
);


export const getOpenNotificationsCount = createSelector(
  fromRoot.getAuthState,
  auth => auth.openNotificationCount === null ? -1 : auth.openNotificationCount
);

export const getPendingChangesCount = createSelector(
  fromRoot.getAuthState,
  auth => auth.pendingChangesCount === null ? -1 : auth.pendingChangesCount
);

export const getUserPreferences = createSelector(
  fromRoot.getAuthState,
  auth => auth.userPreferences
);

export const getDefaultStartPage = createSelector(
  getUserPreferences,
  userPreferences => userPreferences === null ? null : userPreferences.startTabInfo
);

export const getMaxTabs = createSelector(
  getUserPreferences,
  userPreferences => userPreferences === null ? null : userPreferences.maxTabs
);

export const isRefreshing = createSelector(
  fromRoot.getAuthState, auth => {
    return auth.refreshing;
  }
);

export const getUserPriviledges = createSelector(
  getProviderUser,
  providerUser => providerUser === null ? null : providerUser.userPriviledges === null ? null : providerUser.userPriviledges
);

//specific user priviledge checks
//continue to grow based on properties available in i-user-priviledges 
export const canIManageInstallers = createSelector(
  getUserPriviledges,
  userPriviledges => userPriviledges && userPriviledges.manageInstallers
);

export const canIDeleteProjects = createSelector(
  getUserPriviledges,
  userPriviledges => userPriviledges && userPriviledges.deleteProject
);

export const canIEditAddressAge = createSelector(
  getUserPriviledges,
  userPriviledges => userPriviledges && userPriviledges.canEditAddressAge
);

export const canIViewPOBalance = createSelector(
  getUserPriviledges,
  userPriviledges => userPriviledges && userPriviledges.canViewPoBalance
);

export const canIFlagCustomer = createSelector(
  getUserPriviledges,
  userPriviledges => userPriviledges && userPriviledges.canFlagCustomers
);

export const canICreateJobSchedules = createSelector(
  getUserPriviledges,
  userPriviledges => userPriviledges && userPriviledges.canCreateJobSchedules
);

//application tabs
export const getAllTabContainers = createSelector(
  fromRoot.getAuthState,
  (state: fromAuth.IState) => state.portalTabContainers);

export const getTabContainerByName = (name: string) => createSelector(
  getAllTabContainers,
  (containers: IPortalTabContainer[]) => {
    return containers && containers.length > 0 ? containers.find(c => c.tabContainerName == name) : null
  });

export const getTabsByContainer = (name: string) => createSelector(
  getTabContainerByName(name),
  (container: IPortalTabContainer) => {
    return container ? container.portalTabs : []
  });


//widgets I can see
export const getAllMyWidgets =  createSelector(
  fromRoot.getAuthState,
  (state: fromAuth.IState) => state.myWidgets
);

export const getAllMyComponentNames = createSelector(
  getAllMyWidgets,
  (widgets: IWidgetManager[]) => {
    return widgets ? widgets.map(w => w.componentName) : []
  });

export const getMyPortalWidgets = (portalId: number) => createSelector(
    fromRoot.getAuthState,
  (state: fromAuth.IState) => {
      const portalIdList: number[] = portalId === 7 ? [7,8,9] : [portalId];
      return state.myWidgets.filter(w => portalIdList.includes(w.managerPortal_managerPortalId));
  }
);

export const getMyPortalComponentNames = (portalId: number) => createSelector(
  getMyPortalWidgets(portalId),
  (widgets: IWidgetManager[]) => {
    return widgets ? widgets.map(w => w.componentName) : []
  });

export const canISeeComponent = (portalId: number, componentName: string) => createSelector(
  getMyPortalComponentNames(portalId),
  (componentNames: string[]) => componentNames.includes(componentName));

export const getMyWidgetTitle = (portalId: number, componentName: string) => createSelector(
  getMyPortalWidgets(portalId),
  (myWidgets: IWidgetManager[]) => {
    if (!myWidgets) {return '';}

    const widget = myWidgets.find(w => w.componentName == componentName);
    return !widget ? '' : widget.widgetManagerPortalTitle;
  });

export const getCanIEdit = (portalId: number, priviledgeName: string) => createSelector(
  getUserPriviledges,
  (userPriviledges) => priviledgeName !== null && userPriviledges.hasOwnProperty(priviledgeName)
    ? userPriviledges[priviledgeName]
    : null
); //or undefined?

export const hasUnrespondedToContacts = createSelector(
  fromRoot.getAuthState,
  auth => auth.authenticated && auth.unrespondedToContacts !== null
    && auth.unrespondedToContacts.length > 0
);

export const getUnrespondedToContacts = createSelector(
  fromRoot.getAuthState,
  (state: fromAuth.IState) => state.unrespondedToContacts);

export const getUnrespondedToContactIds = createSelector(
  fromRoot.getAuthState,
  (state: fromAuth.IState) => state.unrespondedToContacts === null || state.unrespondedToContacts.length === 0
    ? null
    : state.unrespondedToContacts.map(x => x.ContactId)
);

export const getUnrespondedToProjects = createSelector(
  getUnrespondedToContacts,
  (unrespondedToContacts: IUnrespondedToContactModel[]) => {
    return unrespondedToContacts === null || unrespondedToContacts.length === 0
      ? []
      : flatten(unrespondedToContacts.filter(x => x.ContactTypeId === 1).map(x => x.EntityIds));
  });

export const getUnrespondedToInstallers = createSelector(
  getUnrespondedToContacts,
  (unrespondedToContacts: IUnrespondedToContactModel[]) => {
    return unrespondedToContacts === null || unrespondedToContacts.length === 0
      ? []
      : flatten(unrespondedToContacts.filter(x => x.ContactTypeId === 4).map(x => x.EntityIds));
  });


