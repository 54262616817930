import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, IListButtonType } from '../../../../../../fw/dynamic-list/interfaces/index';
import { SearchType } from '../../../../../../fw/dynamic-list/enums/search-type.enums';
import { ButtonType } from '../../../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../../../fw/fw-shared/interfaces/i-custom-button';
import { DynamicListService } from '../../../../../../fw/dynamic-list/services';
import { appConstants, IAppConstants } from '../../../../../../shared/constants';

@Component({
  selector: 'job-schedule-job-parameters',
  templateUrl: './job-schedule-job-parameters.component.html'
})
export class JobScheduleJobParametersComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  jobScheduleJobId: number = 0;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.jobScheduleJobId = +paramMap.get('id');
      this.loadListDefinition();  //this detail page is a sublist
    });
  }

  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Job Parameters';
    const listObjectController = 'JobScheduleJobParameter';
    const listStoreName = 'jobScheduleJobParameters';
    const detailRoutePath = 'job-schedule-job-parameter';
    const listRowKeyId = 'jobScheduleJobParameterId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [{ term: 'IsSearchTerm', value: 'true', searchType: SearchType.Equals, columnName: 'isSearchTerm', fieldType: this.myConstants.dataTypeBool }];

    let listDefinition = this.dynamicListService.createListDefinition('jobScheduleJobParameterOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = this.jobScheduleJobId;
    listDefinition.parentKey = 'jobScheduleJob_jobScheduleJobId';
    listDefinition.controllerMethod = 'ByJobScheduleJob';
    listDefinition.methodParameters = this.jobScheduleJobId.toString();
    listDefinition.showFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    this.listDefinition$.next( listDefinition );
  }

  /*
      Define the columns in the list to be displayed.  Columns pulled back are set by the db.
      May change this to be defined in the db.
  */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('jobParameter');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('parameterValue');
    columnDefinitions.push(colDef);

    //colDef = new ListColumn('parameterDisplayName');
    //columnDefinitions.push(colDef);
    
    return columnDefinitions;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
