<ng-container *ngIf="(!fileData.fileId || fileData.fileId === 0) && fileData.documentTypeDescription">
  <div class="sms-file">
    <div class="sms-file__icon">
      <img src="/Scripts/clients/hom-app/assets/hom-3.png" />
    </div>
    <div class="sms-file__title">{{ fileData.documentTypeDescription }}</div>
    <div class="sms-file__entity">{{fileData.entityName }} {{ fileData.entityPkId > 0 ? fileData.entityPkId : '' }}</div>
  </div>
</ng-container>

<ng-container *ngIf="documentTypes && documentTypes.length > 0 && fileData.fileId && fileData.fileId > 0">
  <div class="sms-file sms-file--selectable" [ngClass]="{'sms-file--image': isImage, 'sms-file--unassoc': !isImage && !isAssociated}"
       (mouseenter)="hoverTools($event, true)"
       (mouseleave)="hoverTools($event, false)"
       (touchstart)="showTools($event)">
    <ng-container *ngIf="isImage">
      <img class="sms-file__thumbnail"
           src="{{fileData.thumbUrl}}"
           alt="{{fileData.fileName}}"
           [title]="fileTitle"
           [ngClass]="{'sms-file__thumbnail--unassoc': !isAssociated}" />
    </ng-container>

    <ng-container *ngIf="!isImage">
      <div [ngClass]="{'sms-file__icon': isPdf, 'sms-file__logo': !isPdf }" class="{{cssName}}">
        <ng-container *ngIf="isPdf">
          <img src="/Scripts/clients/hom-app/assets/adobe_small.png" class="sms-file__icon--pdf" />
        </ng-container>
        <ng-container *ngIf="!isPdf">
          <div class="sms-file__logo--initial">{{appInitial}}</div>
        </ng-container>
      </div>
      <div class="sms-file__title">{{ fileData.fileName }}</div>
      <div class="sms-file__entity">{{ subTitle }}</div>
    </ng-container>

    <div *ngIf="toolsVisible" class="sms-file__tools" [ngClass]="{'sms-file__tools--image': isImage}">
      <file-tools [tools]="fileTools"
                  (select)="selectTool($event)">
      </file-tools>
    </div>
    <button #toggle type="button"
            class="app-btn-icon app-btn-icon--white"
            fw-popover
            [isDynamic]="true"
            [template]="associateToBox"
            [closeOnClickOutside]="true"
            [showPopover]="showAssociations"
            (onShow)="showOrHideAssociations(true)"
            (onHide)="showOrHideAssociations(false)"></button>
  </div>
</ng-container>

<ng-template #associateToBox>
  <ng-container *ngIf="(activeDocumentTypeId$ | async) <= 0">
  <div class="sms-convo__popover">
    <sms-file-type-selection [documentTypes]="documentTypes"
                             (select)="selectFileType($event)">
    </sms-file-type-selection>
  </div>
  </ng-container>
  <ng-container *ngIf="(activeDocumentTypeId$ | async) > 0">
    <div class="sms-convo__popover">
      <sms-entity-selection [contactId]="contactId"
                            [contactTypeId]="contactTypeId"
                            [requiresWO]="showWOs"
                            [requiresPO]="showPOs"
                            [requiresProject]="showProjects"
                            [projectId]="projectId"
                            (select)="selectEntity($event)">
      </sms-entity-selection>
    </div>
  </ng-container>
</ng-template>
