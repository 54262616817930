<div class="contact-info">
  <fw-component-title *ngIf="displayType === contactContactsService.displayTypeDefault" title="Contact Information"></fw-component-title>
  <contact-phones *ngIf="phones && phones.length && phoneFieldDefinitions"
                  [fieldDefinitions]="phoneFieldDefinitions"
                  [operation]="operation"
                  [phones]="phones"
                  [canIEdit]="canIEdit"
                  [requestTime]="requestTime"
                  (deleteEvent)="onDelete($event)"></contact-phones>
  <contact-emails *ngIf="emails && emails.length && emailFieldDefinitions"
                  [fieldDefinitions]="emailFieldDefinitions"
                  [operation]="operation"
                  [emails]="emails"
                  [displayType]="displayType"
                  [canIEdit]="canIEdit"
                  [requestTime]="requestTime"
                  (deleteEvent)="onDelete($event)"></contact-emails>
</div>

