<div class="unavailable-installers__header">
  <fw-legend [title]="'Reasons Legend'"
             [items]="legendItems">
    <div class="unavailable-installers__missing">
      <div class="unavailable-installers__missing--title">Missing Installer?</div>
      <div class="unavailable-installers__missing--content">
        A score less than 0 will result in the work crew being excluded.<br /><br />
        If you do not see your installer, they are not setup to work this program/service or they are inactive.
      </div>
    </div>
  </fw-legend>
</div>

<div *ngIf="listDefinition$ | async">
  <fw-dynamic-list [vmListDefinition]="listDefinition$ | async"></fw-dynamic-list>
</div>
