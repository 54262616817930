import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, Inject} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { IErrorData } from '../../../../../shared/interfaces';
import { InstallerWorkCrewTechnician, IInstallerWorkCrewTechnician } from '../../../view-models/index';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { InstallerStore } from '../../enums/installer.enums';

import { MetaDataService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'technician-general-information',
  templateUrl: './technician-general-information.component.html',
  providers: [MetaDataService]
})
export class TechnicianGeneralInformationComponent implements OnInit  {
  @Input() technician: InstallerWorkCrewTechnician;
  @Input() operation: string;
  @Input() canIEdit: boolean = false;
  @Input() errorData: IErrorData[];

  @Output() public customEvent: EventEmitter<IHomEventEmitter> = new EventEmitter();

  public requestor: string = 'technician-general-information';
  public storeName: string;
  public storeKeyName: string = 'installerWorkCrewTechnicianId';
  public displayFields: string[] = ['technicianDescription', 'isActive', 'isWorkCrewLead', 'membershipUserName'];
  public form: FormGroup;

  controllerName: string = 'InstallerWorkCrewTechnician';

  constructor(public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants  ) {
    this.storeName = InstallerStore.technicianInformation
  }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case ButtonType.cancel:
        this.initForm();
        this.customEvent.emit(event);
        break;
      case ButtonType.save:
        this.setDataAndEmit(event);
        break;
      default:
        this.customEvent.emit(event);
        break;
    }
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['technician'] && !(changes['technician'].firstChange)) {
      this.initForm();
    }

    if (changes['errorData'] && !(changes['errorData'].firstChange)) {
      this.mds.setErrorMessages(this.errorData);
    }
  }

  public initForm(): void {
    this.mds.setFieldDefinitions(this.storeName);
    this.mds.loadSelectionLists(this.displayFields, this.operation, this.technician.installerWorkCrew_installerWorkCrewId);
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.technician, this.operation);
  }

  setDataAndEmit(event: IHomEventEmitter): void {
    let saveEvent = event;
    let iwc = this.setCommonProperties();
    saveEvent.data = iwc;
    this.customEvent.emit(saveEvent)
  }

  setCommonProperties(): IInstallerWorkCrewTechnician {
    let iwc = { ...this.technician };
    const formData: IInstallerWorkCrewTechnician = this.form.getRawValue();

    iwc.technicianDescription = formData.technicianDescription;
    iwc.isActive = formData.isActive;
    iwc.isWorkCrewLead = formData.isWorkCrewLead;
    return iwc;
  }

}
