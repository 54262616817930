// Resolver for all selection lists in an object's field definitions
import { Injectable, Inject} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable} from 'rxjs';
import { take, map, filter, first, mergeMap} from 'rxjs/operators';

import {  IFieldDefinition } from '../../../fw/dynamic-forms/index';

//store actions and reducers
import * as fromStore from '../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../app/store/reducers/index';
import { SelectionListService } from '../../../shared/services/selection-list.service';
import { metaDataExists } from '../../../fw/dynamic-list/store/selectors/meta-data.selectors';

import * as LoadingIndicatorActions from '../../../shared/store/loadingIndicator/loadingIndicator.actions';
import { IAppConstants, appConstants } from '../../../shared/constants/index';
import { ISelectResolverData } from '../../../shared/interfaces/i-select-resolver-data';

@Injectable()
export class SelectionListResolver  {
  portalId: number;
  storeName: string = '';
  operation: string;
  myId: number;
  parentId: number;
  currentUserId: number;
  fieldDefinitions: IFieldDefinition[] = [];
  seedData: any = {};

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants,
    public selectionListService: SelectionListService) {
  }
  /* can set storeName to resolve within route def (route.data['storeName']),if store name of parent list and child list differ */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
   let resolveWith: string = route.data.hasOwnProperty('resolveWith') ? route.data['resolveWith'] : '';

    this.portalId = route.paramMap.has('portalId') ? +route.paramMap.get('portalId')
      : route.parent.paramMap.has('portalId') ? +route.parent.paramMap.get('portalId')
        : -1;
    this.operation = route.paramMap.has('operation') ? route.paramMap.get('operation')
      : route.parent.paramMap.has('operation') ? route.parent.paramMap.get('operation')
        : this.myConstants.operationTypeDetails;
    this.storeName = route.data['storeName'] ? route.data['storeName']
      : route.paramMap.has('storeName') ? route.paramMap.get('storeName')
      : route.parent.paramMap.has('storeName') ? route.parent.paramMap.get('storeName')
        : this.portalId === 1 ? 'projectInformation' : this.portalId === 7 ? 'installerInformation' : '';
    this.myId = route.paramMap.has('id') ? +route.paramMap.get('id')
      : route.parent.paramMap.has('id') ? +route.parent.paramMap.get('id')
        : -1;
    this.parentId = resolveWith === 'parentId' && route.parent.paramMap.has('id') ? +route.parent.paramMap.get('id')
      : route.paramMap.has('portalEntityId') ? +route.paramMap.get('portalEntityId')
      : route.parent.paramMap.has('portalEntityId') ? +route.parent.paramMap.get('portalEntityId')
          : -1;
    if (!this.parentId || this.parentId === -1) {
      this.parentId = +route.parent.paramMap.get('id') | -1;
    }

    if (resolveWith !== '' && this.parentId === -1) {
      console.log('DEV WARNING: parentId is set to -1.  Does the data value resolveWith need to be set on the route? Working with storeName: ', this.storeName);
    }
    this.rootStore.dispatch(new LoadingIndicatorActions.ShowSpinner({ requestor: this.storeName, id: this.parentId }));

    return this.metaLoaded().pipe(
      mergeMap((loaded: boolean) => {
        let resolverData: ISelectResolverData[] = this.selectionListService.getListNames(this.storeName);
        this.selectionListService.requestListData(this.storeName, this.parentId);
        resolverData.forEach(x => x.parentId = x.parentDependent ? this.parentId : -1);
        return this.selectionListService.waitForDataToLoad(resolverData)
          .pipe(
            map((complete: boolean) => {  return complete }),
            filter((complete: boolean) => complete === true),
            take(1),
            map(() => {
              this.rootStore.dispatch(new LoadingIndicatorActions.HideSpinner({ requestor: this.storeName, id: this.parentId }));
              return true;
            }),
            first()
          )
      })
    );
  }

  metaLoaded(): Observable<boolean> {
    return this.store.select(metaDataExists(this.storeName))
      .pipe(
        map((exists: boolean) => {
          return exists;
        }),
        filter((exists: boolean) => exists === true),
        take(1),
        map(() => { return true; }),
        first()
      );
  }

}
