<project-count [projectId]="projectId"
               [entityName]="myConstants.entityTypePurchaseOrder"
               title="Purchase Orders"
               buttonTitle="Add Purchase Order"
               [canIEdit]="canIEdit"
               [parentData]="pos$ | async"
               [itemData]="poItems$ | async"
               (goToEvent)="onGoTo($event)"
               (addEvent)="onAdd($event)">
</project-count>

<project-count [projectId]="projectId"
               [entityName]="myConstants.entityTypeWorkOrder"
               title="Work Orders"
               buttonTitle="Add Work Order"
               [canIEdit]="canIEdit"
               [parentData]="wos$ | async"
               [itemData]="woItems$ | async"
               (goToEvent)="onGoTo($event)"
               (addEvent)="onAdd($event)">
</project-count>

<ng-container *ngIf="(aqtChanges$ | async) as aqtChanges">
  <project-count *ngIf="aqtChanges && aqtChanges.length > 0"
                 [projectId]="projectId"
                 [entityName]="myConstants.entityTypeAQT"
                 title="Finalized Approvals"
                 [canIEdit]="false"
                 [parentData]="aqtChanges"
                 (goToEvent)="onGoTo($event)">
  </project-count>
</ng-container>

<ng-container *ngIf="isWarranty">
  <project-count [projectId]="projectId"
                 [entityName]="myConstants.entityTypeChargeBack"
                 title="Charge Backs"
                 buttonTitle="Add Charge Back"
                 [canIEdit]="canIEditCbs"
                 [parentData]="chargeBacks$ | async"
                 [itemData]="chargeBackItems$ | async"
                 (goToEvent)="onGoTo($event)"
                 (addEvent)="onAdd($event)">
  </project-count>
</ng-container>

<project-count [projectId]="projectId"
               [entityName]="myConstants.entityTypeNote"
               title="Notes"
               buttonTitle="Add Note"
               [canIEdit]="true"
               [itemData]="notes$ | async"
               (goToEvent)="onGoTo($event)"
               (addEvent)="onAdd($event)">
</project-count>

