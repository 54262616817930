import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IListDefinition, IGenericStateData } from '../../../../../fw/dynamic-list/interfaces/index';
import { IKey, UpdateObjectCustomModel, DeleteObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IPoImportHeaderViewModel, IProject, IPoImportUpdateViewModel, IGeneralContractor } from '../../../view-models';
import { IErrorData } from '../../../../../shared/interfaces/index';
import { ProjectObjectStore } from '../../../project/enums/project.enums';
import { PoImportEvent, PoImportStore } from '../../enums/po-import.enums';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';

import { UserPriviledgesService } from '../../../../../auth/services';
import { ProjectService } from '../../../project/services';
import { PoImportService } from '../../services/po-import.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { getObjectDataById } from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';

interface IImportToProject {
  uniqueId: number,
  vendorOrderNumber: string;
  branchName: string;
  projectId: number;
}

@Component({
  selector: 'po-import-grouped-headers',
  templateUrl: './po-import-grouped-headers.component.html'
})
export class PoImportGroupedHeadersComponent implements OnInit, OnDestroy {

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public selectedPos: IPoImportHeaderViewModel[] = [];
  public generalContratorName: string;
  importToProjects: IImportToProject[] = [];
  subscription = new Subscription();
  generalContractorId: number;
  projectId: number;
  canIEdit: boolean;
  errorsOnly: boolean = false;
  reloadSub: Subscription;
  fromProjectPortal: boolean = false;

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
   public poImportService: PoImportService,
    public projectService: ProjectService,
    public userPriviledgesService: UserPriviledgesService,
    public modalService: ModalService,
    public store: Store<fromFeature.IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
        this.generalContractorId = +paramMap.get('id');
        this.projectId = +paramMap.get('projectId');
        this.fromProjectPortal = this.projectId > 0;
        this.errorsOnly = paramMap.get('errorsOnly') === 'true';
        this.initWorkingProperties();
        this.setListDefinition();
      });
  }

  public importPOs(): void {
    const def = this.listDefinition$.getValue();
    if (this.reloadSub) {
      this.reloadSub.unsubscribe();
    }
    if (this.projectId > 0 && this.fromProjectPortal) {
      this.listenForReload(def);
    }
    const keyData: IKey = { storeName: def.storeName, parentId: -1, key: def.rowKeyId, id: this.selectedPos[0].uniqueId, skipDataUpdate: true };
    const emitter: IHomEventEmitter = { requestor: 'po-import-container', event: this.myConstants.emitterEventListReload, action: '', data: null };
    let viewModel: IPoImportUpdateViewModel[] = [];
    this.selectedPos.forEach((item, index) => {
      //for each grouping of vendor ordernumbers, get the selected project id, if any, else will create a new project 
      const importToProject: IImportToProject = this.fromProjectPortal ? undefined : this.importToProjects.find(x => x.uniqueId === item.uniqueId);

      const updateObj: IPoImportUpdateViewModel = {
        vendorOrderNumber: item.vendorOrderNumber,
        branchName: item.branchName,
        projectId: this.fromProjectPortal ? this.projectId : importToProject ? importToProject.projectId : -1
      };
      viewModel.push(updateObj);
    });
    const updateData = new UpdateObjectCustomModel(keyData, def.controllerName, 'ImportToProjectByVendorPos', viewModel, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));

    this.selectedPos = [];
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case PoImportEvent.viewContactInformation:
        this.viewContactInfo(event.data);
        break;
      case PoImportEvent.selectImportToProject:
        this.updateImportToProjectList(event);  //event, not event.data
        break;
      case PoImportEvent.goToProject:
        this.openProjectPortal(event.data);
        break;
      case PoImportEvent.deletePoImport:
        if (confirm('Are you sure you want to delete PO Import ' + event.data.vendorOrderNumber)) {
          this.deletePoImport(event.data);
        }
        break;
      case PoImportEvent.showDownloadAndImport:
        this.openDownloadAndImport();
        break;
      default:
        break;
    }
  }

  public onSelect(event: IHomEventEmitter) {
    switch (event.event) {
      case PoImportEvent.importPurchaseOrder:
        this.updateSelectedPos(event);
        break;
      default:
        break;
    }
  }

  public onPage(): void {
    this.initWorkingProperties();
  }


  deletePoImport(data: IPoImportHeaderViewModel): void {
    const deleteUrl = this.userPriviledgesService.getDeleteUrl(data);
    if (deleteUrl.length) {
      const listDefinition = this.listDefinition$.value;
      const keyData: IKey = { storeName: listDefinition.storeName, parentId: listDefinition.parentId, key: listDefinition.rowKeyId, id: data.uniqueId }
      const event: IHomEventEmitter = { requestor: 'po-import-grouped-headers', event: this.myConstants.emitterEventDelete, action: '', data: null };
      const deleteData = new DeleteObjectModel(keyData, deleteUrl, event);
      this.store.dispatch(new DynamicListActions.DeleteObjectByUrlList({ deleteData }));
    }
  }

  initWorkingProperties(): void {
    this.selectedPos = [];
    this.importToProjects = [];
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('generalContractor')))
      .subscribe((data) => {
        const gc: IGeneralContractor = data ? data.find(x => x.generalContractorId == this.generalContractorId) : 1;
        this.generalContratorName = gc ? gc.generalContractorName : '';
      }));

  }

  setListDefinition(): void {
    this.selectedPos = [];
    let listDefinition: IListDefinition = this.poImportService.loadPoImportHeaderViewModelListDefinition(this.generalContractorId, this.errorsOnly, this.projectId > 0);
    this.listDefinition$.next(listDefinition);

    if (this.projectId > 0) {
      this.subscription.add(this.store.pipe(
        select(getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)), take(1))
        .subscribe((objData: IProject) => {
          if (objData) {
            this.canIEdit = this.userPriviledgesService.canIEdit(objData) && objData.currentProjectStatusCode !== this.myConstants.projectStatusClosed;
          }
        })
      );
    }
  }

  updateSelectedPos(event: IHomEventEmitter): void {
    const vm: IPoImportHeaderViewModel = event.data;
    const exists: number = this.selectedPos.findIndex(x => x.vendorOrderNumber === vm.vendorOrderNumber);
    if (event.action) {
      if (exists === -1) {
        this.selectedPos.push(vm);
      }
    } else {
      if (exists > -1) {
        this.selectedPos.splice(exists, 1);
      }
    }
  }

  openProjectPortal(data: IPoImportHeaderViewModel): void {
    this.projectService.openProjectTab(data.linkedProjectId, 'po-import-grouped-headers');
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  updateImportToProjectList(event: IHomEventEmitter): void {
    const row: IPoImportHeaderViewModel = event.data['row'];
    const projectId: number = event.data['projectId'];
    const exists: number = this.importToProjects.findIndex(x => x.uniqueId === row.uniqueId);
    if (exists === -1 && projectId > 0) {
      this.importToProjects.push({ uniqueId: row.uniqueId, vendorOrderNumber: row.vendorOrderNumber, branchName: row.branchName, projectId: projectId });
    } else if (exists > -1) {
      if (projectId > 0) {
        //update
        this.importToProjects[exists].projectId = projectId;
      } else {
        //remove
        this.importToProjects.splice(exists, 1);
      }
    }
  }

  //list for request to reload this list, then dispatch a request to reload purchase orders for the project.
  listenForReload(listDefinition: IListDefinition): void {
    let dispatched: boolean = false;

    this.reloadSub = this.store.pipe(select(getListByType(listDefinition.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == -1)))
      .subscribe((state: IListObjectData) => {
        if (state && state.event && (!state.errorData || !state.errorData.length)) {
          if (state.event.event === this.myConstants.emitterEventListReload && !dispatched) {
            //refresh purchase order data for this project
            this.projectService.dispatchPurchaseOrders('po-import-grouped-headers', this.projectId, this.canIEdit)
            dispatched = true;
          }
        }
      });
  }


  viewContactInfo(data: IPoImportHeaderViewModel): void {
    const listDef = this.listDefinition$.getValue();
    let stateData: IGenericStateData = {
      storeName: listDef.storeName,
      key: listDef.rowKeyId,
      parentId: listDef.parentId
    }

    this.modalService.open({
      title: 'Contact Information',
      path: 'po-import-contact-info/' + data.uniqueId.toString() + (this.errorsOnly ? '/true' : '/false'),
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      hasTabs: false,
      state: stateData,
      isSubModal: this.modalService.opened

    });
  }

  openDownloadAndImport(): void {
    this.modalService.open({
      title: 'Manual PO Download and Import',
      path: 'po-download-import/' + this.generalContractorId.toString(),
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      hasTabs: false,
      isSubModal: this.modalService.opened
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.reloadSub) {
      this.reloadSub.unsubscribe();
    }
  }

}
