import { Component, OnInit, Input, ChangeDetectionStrategy, Inject, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map} from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IErrorData, GenericObjectData } from '../../../../shared/interfaces/index';
import { IFormDefinition, FormDefinition } from '../../../dynamic-forms/index';

//store actions and reducers
import * as fromStore from '../../../dynamic-list/store/index';
import { IDynamicListState, IListObjectData } from '../../../dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromDynamicList from '../../../dynamic-list/store/selectors/dynamic-list.selectors';
import { IDetailContainerConfig } from '../../interfaces';
import { IListDefinition } from '../../../dynamic-list/interfaces';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'fw-dynamic-detail',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dynamic-detail.component.html'
})
export class DynamicDetailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() displayFields: string[];
  @Input() detailConfig: IDetailContainerConfig;
  @Input() defaultObjData: any = null; //passed in only if need to set some default values on create  
  @Input() forceDirty: boolean = false;
  @Input() forceInvalid: boolean = false;

  public listDefinition: IListDefinition;
  public errorMessage: string = '';
  public formDefinition$: BehaviorSubject<IFormDefinition>;
  public objectData$: Observable<GenericObjectData>;
  public errorData$: BehaviorSubject<IErrorData[]>;
  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit() {
    this.formDefinition$ = new BehaviorSubject(null);
    this.errorData$ = new BehaviorSubject([]);
    this.errorMessage = '';
    this.setParentListeners();
    this.setChildListeners();
    this.setLocalListeners();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['detailConfig'] && !(changes['detailConfig'].isFirstChange())) {
      const prevVal: IDetailContainerConfig = changes['detailConfig'].previousValue ? changes['detailConfig'].previousValue : -1;
      const newVal: IDetailContainerConfig = changes['detailConfig'].currentValue;
      if ((prevVal.id !== newVal.id) || (prevVal.operation !== newVal.operation)) {
        this.setChildListeners();
      }
      if ((prevVal.showNext !== newVal.showNext) || (prevVal.showPrev !== newVal.showPrev)) {
        this.setFormDefinition();
      }
    }
  }

   ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setParentListeners(): void {
    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.detailConfig.storeName, this.detailConfig.parentId)),
        filter(listDefinition => listDefinition !== null))
      .subscribe(listDefinition => {
        this.listDefinition = cloneDeep(listDefinition);
        this.setFormDefinition();
      }));

    //For field level errors
    this.subscription.add(this.store.pipe(select(fromStore.getListByType(this.detailConfig.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.detailConfig.parentId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.errorData) {
          this.errorData$.next(state.errorData);
        }
      }));
  }

  setChildListeners(): void {
    this.objectData$ = this.detailConfig.operation === this.myConstants.operationTypeCreate ? this.defaultObjData ? of(this.defaultObjData) : of(new GenericObjectData())
      : this.store.pipe(
        select(fromDynamicList.getSelectedRecord(this.detailConfig.storeName, this.detailConfig.parentId, this.detailConfig.key, this.detailConfig.id)),
        map(entity => { return entity; }));
  }

  setLocalListeners(): void {
    this.subscription.add(this.emitterService.detailEventEmitted$.subscribe(
      (e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventClose:
            this.emitterService.emitListEvent(e);
            break;
          default:
            break;
        }
      }));
  }

  setFormDefinition(): void {
    this.formDefinition$.next(new FormDefinition(this.listDefinition.objectLabel + ' ' + (this.detailConfig.operation || this.myConstants.operationTypeDetails),
      this.detailConfig.showPrev,
      this.detailConfig.showNext,
      this.listDefinition.detailRoutePath,
      '',
      '',
      this.detailConfig.customButtons));
  }

}
