import { Component, Inject, Output, EventEmitter, Input } from '@angular/core';

import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { PoImportEvent } from '../../enums/po-import.enums';

@Component({
  selector: 'po-download-import',
  templateUrl: './po-download-import.component.html'
})
export class PoDownloadImportComponent {
  @Input() public generalContractorName: string;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public errorMessage: string = '';

  constructor() { }

  public onDownload(purchaseOrderNumbers: string): void {
    const pos: string[] = this.getPOArray(purchaseOrderNumbers);

    if (this.isValid(pos)) {
      this.errorMessage = '';
      this.customEvent.emit({
        requestor: 'po-download-import',
        event: PoImportEvent.downloadAndImport,
        action: '',
        data: pos
      });
    } else {
      this.errorMessage = 'Please enter up to 10 purchase order numbers separated by commas.'
    }
  }

  public onCancel(): void {
    this.customEvent.emit({ requestor: 'po-download-import', event: PoImportEvent.cancel, action: '', data: null });
  }

  //Remove any empty items, handle empty string, remove ticks and quotes
  getPOArray(purchaseOrderNumbers: string): string[] {
    return purchaseOrderNumbers || purchaseOrderNumbers.length
      ? purchaseOrderNumbers.split(',')
        .filter(x => x)
        .map(y => y.replace(/'/g, '').replace(/"/g, '').replace(/ /g, '').replace(/`/g, ''))
      : [];
  }

  //To process the purchase order list, there must be at least one item in the array up to 10 items in the arry.
  isValid(purchaseOrders: string[]): boolean {
    return purchaseOrders && purchaseOrders.length && purchaseOrders.length < 11 ? true : false;
  }
}
