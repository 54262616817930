import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IListFilter } from '../../../../../fw/dynamic-list/interfaces/index';
import { UtilitiesEvent, UtilitiesStore } from '../../enums/utilities.enums';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { ContactStore } from '../../../../contact/enums/contact.enums';
import { IContact } from '../../../view-models';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { getListByType } from '../../../../../fw/dynamic-list/store/index';
import * as MetaDataActions from '../../../../../fw/dynamic-list/store/actions/meta-data.actions';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicObject from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { UtilitiesService } from '../../services/utilities.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'customer-search',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './customer-search.component.html'
})
export class CustomerSearchComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public filterInfo: string = '';
  subscription: Subscription = new Subscription();
  reloadSub: Subscription;
  dashSub: Subscription;
  contactId: number;
  doReload: boolean;

  constructor(
    public utilitiesService: UtilitiesService,
    public store: Store<fromStore.IAllDynamicData>,
    public ups: UserPriviledgesService,
    public modalService: ModalService) {
    this.filterInfo = '<div class="app-ul"><ul><li>'.concat('If the search yields <b>over 500 results</b>, filtering will return NO results.',
      '</li><li>',
      'For example, filtering on Preferred Phone with a search value of "303" will return no results.  ',
      '</li><li>',
      'Filtering of Preferred Address only filters on Address (not city, state or zip).', '</li></ul></div>');
  }
  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case UtilitiesEvent.viewContactInfo:
        this.viewContactInfo(event.data);
        break;
      case UtilitiesEvent.viewCustomerFlags:
        this.viewCustomerFlags(event.data);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.subscription.add(this.store.pipe(select(getListByType(UtilitiesStore.dashCustomers))).pipe(take(1)).subscribe((data) => {
      if (!data.objData || !data.objData.length) {
        this.store.dispatch(new MetaDataActions.GetMetaData({ storeName: UtilitiesStore.dashCustomers, url: 'Entity/GetEntityMetaData?entityName=Contact', setListMetaData: true }));
        this.subscription.add(this.store.pipe(select(fromStore.getMetaDataByType(UtilitiesStore.dashCustomers))).subscribe((state: fromStore.IMetaDataState) => {
          if (state.fieldDefinitions.length && !this.listDefinition$.value) {
            let listDefinition: IListDefinition = this.utilitiesService.loadCustomerSearchListDefinition(this.ups.currentUserId$.value, this.ups.canFlagCustomers$.value);
            this.store.dispatch(new fromStore.SetListDefinition({ storeName: UtilitiesStore.dashCustomers, parentId: -1, listDefinition: listDefinition }));
            this.listDefinition$.next(listDefinition);
          }
        }));
      } else {
        this.listDefinition$.next(data.objData[0].listDefinition);
      }
    }));
  }

  listenForObjReload(): void {
    //was being triggered as a new subscription for each contact info opened, fixed, use this singular approach for this scenario.
    this.reloadSub = this.store.pipe(select(fromDynamicObject.getObjectEventById(ContactStore.contactInformation, this.contactId)))
      .subscribe((event: IHomEventEmitter) => {
        if (event) {
          this.doReload = true;
          this.reloadList();
        }
      });
  }

  reloadList(): void {
    this.dashSub = this.store.pipe(select(getListByType(UtilitiesStore.dashCustomers))).subscribe((data) => {
    if (this.doReload) {
        this.doReload = false;
        const listFilter: IListFilter = cloneDeep(data.objData[0].listFilter);
        this.store.dispatch(new DynamicListActions.GetList({ listDefinition: this.listDefinition$.value, listFilter: listFilter, parentId: -1 }));
      }
    });
  }

  viewContactInfo(data: IContact): void {
    this.contactId = data.contactId;
    if (this.reloadSub) {
      this.reloadSub.unsubscribe();
    }
    if (this.dashSub) {
      this.dashSub.unsubscribe();
    }
    this.listenForObjReload();
    this.modalService.open({ title: 'Contact Information', path: 'contact-info/' + data.contactId.toString(), sizeType: ModalSizeType.medium, onExit: null, castExit: false, hasTabs: false });
  }

  viewCustomerFlags(data: IContact): void {
    this.contactId = data.contactId;
    this.modalService.open({ title: 'Customer Flags', path: 'customer-flags/' + data.contactId.toString(), sizeType: ModalSizeType.medium, onExit: null, castExit: false, hasTabs: false });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.reloadSub) {
      this.reloadSub.unsubscribe();
    }
    if (this.dashSub) {
      this.dashSub.unsubscribe();
    }
} 
    
}
