<div class="fw-radio-button-box" [ngClass]="{'fw-radio-button-box--vert': !isHorizontal }">
  <ng-container *ngFor="let btn of buttons; let idx = index">
    <label [htmlFor]="btn.id" class="fw-radio-button" title="Select {{ btn.label ? btn.label : 'Row' }}">
      {{  btn.label }}
      <input type="radio"
             [id]="btn.id"
             [name]="groupName"
             [disabled]="isDisabled"
             [attr.checked]="btn.checked ? '' : null"
             [value]="btn.value"
             title="Select {{ btn.label ? btn.label : 'Row' }}"
             (click)="onClick(btn.value)">
      <span class="fw-radio-button--checkmark"></span>
    </label>
  </ng-container>
</div>
