import { Component, OnInit,   OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';

import { ManagerDashboardService } from '../../services/manager-dashboard.service';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import {  IInventoryRollupViewModel } from '../../../view-models';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';

@Component({
  selector: 'work-orders-requiring-inventory',
  templateUrl: './work-orders-requiring-inventory.component.html'
})
export class WorkOrdersRequiringInventoryComponent implements OnInit, OnDestroy {
  public displayFields: string[] = ['startDate', 'locationName', 'workOrderItemName', 'orderAmount']
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public objectData$: BehaviorSubject<IInventoryRollupViewModel> = new BehaviorSubject(null);
  public operation: string;
  public storeName: string = '';

  key: string = ''; 
  uniqueId: number = 0;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public managerDashboardService: ManagerDashboardService,
    public dynamicDetailService: DynamicDetailService,
    public store: Store<fromStore.IAllDynamicData>) { }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.uniqueId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.setDetailConfig(paramMap);
      this.getRow();
    });
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1; //for dashShipperPos (not items)
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.showCancel = true;
    this.detailConfig$.next(params);
  }

  getRow() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.storeName, -1, this.key, this.uniqueId)), take(1))
      .subscribe((data: IInventoryRollupViewModel) => {
        const providerLocationId = data.providerLocationId;
        const startDate = data.startDate;
        const materialName = data.workOrderItemName;
        this.objectData$.next(data);
        this.listDefinition$.next(this.managerDashboardService.loadDashInventoryWorkOrdersListDefinition(this.uniqueId, providerLocationId, startDate, materialName));
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
