/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, SimpleChanges, Input, Inject, EventEmitter, Output} from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IPurchaseOrder, IPurchaseOrderItem, IWorkOrder, IWorkOrderItem, INote, IChargeBack, IChargeBackItem } from '../../../view-models/index';
import { IMultiTierOuput } from '../../../../../fw/fw-menus/interfaces/i-multi-tier-output';
import { IMultiTierMenu } from '../../../../../fw/fw-menus/interfaces/i-multi-tier-menu';
import { ITabRequest } from '../../../../../fw/fw-shared/components/fw-app-tabs/interfaces/index';
import { IApprovalQueueTransaction } from '../../../../../shared/interfaces';

@Component({
  selector: 'project-count',
  templateUrl: './project-count.component.html',
})
export class ProjectCountComponent implements OnInit {
  @Input() projectId: number;
  @Input() entityName: string;
  @Input() title: string;
  @Input() buttonTitle: string;
  @Input() canIEdit: boolean;
  @Input() parentData: null | IPurchaseOrder[] | IWorkOrder[] | IChargeBack[] | IApprovalQueueTransaction[];
  @Input() itemData: number | IPurchaseOrderItem[] | IWorkOrderItem[] | IChargeBackItem[];

  @Output() public addEvent = new EventEmitter<IHomEventEmitter>();
  @Output() public goToEvent = new EventEmitter<IMultiTierOuput>();

  public totalLabel$: BehaviorSubject<string> = new BehaviorSubject('');
  public countLabel$: BehaviorSubject<string> = new BehaviorSubject('');
  public itemList$: BehaviorSubject<IMultiTierMenu[]> = new BehaviorSubject(null);

  constructor(
    public currencyPipe: CurrencyPipe,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  public addItem(): void {
    let addEvent: IHomEventEmitter = { requestor: 'project-count', event: 'addEvent', action: '', data: this.entityName };
    this.addEvent.emit(addEvent);
  }

  public onSelectedItem(output: IMultiTierOuput): void {
    this.goToEvent.emit(output);
  }

  ngOnInit() {
    this.setValues();
  }

  setValues() {
    this.itemList$.next(null);
    this.setTotalLabel();
    this.setCountLabel();
    switch (this.entityName) {
      case this.myConstants.entityTypePurchaseOrder:
        this.loadPOList();
        break;
      case this.myConstants.entityTypeWorkOrder:
        this.loadWOList();
        break;
      case this.myConstants.entityTypeChargeBack:
        this.loadChargeBackList();
        break;
      case this.myConstants.entityTypeAQT:
        this.loadAQTList();
        break;

      default:
        break;
    }
  }

  setTotalLabel(): void {
    const entityTotal: number = this.getEntityTotal();

    switch (this.entityName) {
      case this.myConstants.entityTypePurchaseOrder:
      case this.myConstants.entityTypeWorkOrder:
      case this.myConstants.entityTypeChargeBack:
        this.totalLabel$.next('Total: ' + this.currencyPipe.transform(entityTotal, 'USD', 'symbol', '1.2-2' ))
        break;
      case this.myConstants.entityTypeNote:
        this.totalLabel$.next(entityTotal + (entityTotal > 0 ? ' Note' + (entityTotal > 1 ? 's' : '') : '') );
        break;
      case this.myConstants.entityTypeAQT:
        this.totalLabel$.next(entityTotal + (entityTotal > 0 ? ' Item' + (entityTotal > 1 ? 's' : '') : '') );
        break;
      default:
        break;
    }
  }

  setCountLabel(): void {
    const count: number = this.parentData && this.parentData.length ? this.parentData.length : 0;

    switch (this.entityName) {
      case this.myConstants.entityTypePurchaseOrder:
      case this.myConstants.entityTypeWorkOrder:
      case this.myConstants.entityTypeChargeBack:
      case this.myConstants.entityTypeAQT:
        this.countLabel$.next(count.toString() + ' Item' + (count > 1 ? 's' : ''));
        break;
      case this.myConstants.entityTypeNote:
        this.countLabel$.next(''); //not applicable
        break;

      default:
        break;
    }
  }
  
  getEntityTotal(): number {
    switch (this.entityName) {
      case this.myConstants.entityTypePurchaseOrder:
        let poTotal: number = 0;
        if (this.itemData && (this.itemData as IPurchaseOrderItem[]).length > 0) {
          const poItems: IPurchaseOrderItem[] = this.itemData as IPurchaseOrderItem[];
          const profitPoItems: IPurchaseOrderItem[] = poItems.filter(x => x.itemType === 'Labor');
          profitPoItems.forEach((item: IPurchaseOrderItem) => poTotal += item.quantity * item.unitPrice);
        }
        return poTotal;

      case this.myConstants.entityTypeWorkOrder:
        let woTotal: number = 0;
        if (this.itemData && (this.itemData as IWorkOrderItem[]).length > 0) {
          const woItems: IWorkOrderItem[] = this.itemData as IWorkOrderItem[];
          woItems.forEach((item: IWorkOrderItem) => woTotal += item.quantity * item.unitPrice);
        }
        return woTotal;

      case this.myConstants.entityTypeChargeBack:
        let chargeBackTotal: number = 0;
        if (this.itemData && (this.itemData as IChargeBackItem[]).length > 0) {
          const cbItems: IChargeBackItem[] = this.itemData as IChargeBackItem[];
          cbItems.forEach((item: IChargeBackItem) => chargeBackTotal += item.units * item.unitPrice);
        }
        return chargeBackTotal;

      case this.myConstants.entityTypeAQT:
        return this.parentData ? this.parentData.length : 0;

      case this.myConstants.entityTypeNote:
        return (this.itemData as number) || 0;
      default:
        return 0;
    }
  }

  loadPOList() {
    let items: IMultiTierMenu[] = [];
    //const distinctPos: IPurchaseOrderItem[] = this.getDistinctPOList();
    const data = this.parentData ? this.parentData as IPurchaseOrder[] : [];
    data.forEach((poItem: IPurchaseOrder) => {
      const data: ITabRequest = {
        text: 'Project ' + this.projectId.toString(),
        portalId: 1,
        portalEntityId: this.projectId,
        level2EntityId: poItem.purchaseOrderId,
        level2EntityName: this.myConstants.entityTypePurchaseOrder,
        level2Text: poItem.purchaseOrderNumber.toString(),
        level3EntityId: null,
        level3EntityName: null,
        level3Text: null
      }
      items.push({
        label: poItem.purchaseOrderNumber.toString(),
        subItems: null,
        disabled: false,
        data: data
      });
    });
    this.itemList$.next(items);
  }

  loadWOList() {
    let items: IMultiTierMenu[] = [];
    //const distinctWos: IWorkOrderItem[] = this.getDistinctWOList();

    const data = this.parentData ? this.parentData as IWorkOrder[] : [];
    data.forEach((wo: IWorkOrder) => {
      const data: ITabRequest = {
        text: 'Project ' + this.projectId.toString(),
        portalId: 1,
        portalEntityId: this.projectId,
        level2EntityId: wo.workOrderId,
        level2EntityName: this.myConstants.entityTypeWorkOrder,
        level2Text: wo.workOrderId.toString(),
        level3EntityId: null,
        level3EntityName: null,
        level3Text: null
      }
      items.push({
        label: wo.workOrderId.toString(),
        subItems: null,
        disabled: false,
        data: data
      });
    });
    this.itemList$.next(items);
  }

  loadChargeBackList() {
    let items: IMultiTierMenu[] = [];
    //const distinctItems: IChargeBackItem[] = this.getDistinctChargeBackList();
    const data = this.parentData ? this.parentData as IChargeBack[] : [];
    data.forEach((item: IChargeBack) => {
      const data: ITabRequest = {
        text: 'Project ' + this.projectId.toString(),
        portalId: 1,
        portalEntityId: this.projectId,
        level2EntityId: item.chargeBackId,
        level2EntityName: this.myConstants.entityTypeChargeBack,
        level2Text: item.chargeBackId.toString(),
        level3EntityId: null,
        level3EntityName: null,
        level3Text: null
      }
      items.push({
        label: item.chargeBackId.toString(),
        subItems: null,
        disabled: false,
        data: data
      });
    });
    this.itemList$.next(items);
  }

  loadAQTList() {
    let items: IMultiTierMenu[] = [];
    const data = this.parentData ? this.parentData as IApprovalQueueTransaction[] : [];
    data.forEach((item: IApprovalQueueTransaction) => {
      const data: ITabRequest = {
        text: 'Project ' + this.projectId.toString(),
        portalId: 1,
        portalEntityId: this.projectId,
        level2EntityId: item.parentPrimaryKeyId,
        level2EntityName: item.parentEntityName,
        level2Text: item.parentPrimaryKeyId.toString(),
        level3EntityId: null,
        level3EntityName: null,
        level3Text: null
      };
      
      items.push({
        label: item.parentEntityName === 'PurchaseOrder' ? item.parentContext : item.parentPrimaryKeyId.toString(),
        labelIcon: item.status === 'Denied' ? 'app-icon--danger fa-times-square fas' : 'app-icon--success fa-check-square fas',
        subItems: null,
        disabled: false,
        data: data
      });
    });
    this.itemList$.next(items);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['entityName'] && !(changes['entityName'].firstChange)) {
      this.setValues();
    }
    if (changes['parentData'] && !(changes['parentData'].firstChange)) {
      this.setValues();
    }
    if (changes['itemData'] && !(changes['itemData'].firstChange)) {
      this.setValues();
    }
  }
}
