<div class="file-viewer" *ngIf="files && files.length">
  <div class="file-viewer__main">
    <div class="file-viewer__main__pics">
      <file-viewer-carousel [data]="files"
                            [idx]="activeIdx"
                            [currentPage]="currentPage"
                            [pages]="pages"
                            (pageEvent)="showPage($event)"
                            (slideEvent)="setActive($event)">
      </file-viewer-carousel>
    </div>
    <div class="file-viewer__main__detail">
      <file-viewer-detail [item]="files[activeIdx]"
                          [storeName]="storeName"
                          [storeKeyName]="storeKeyName"
                          [parentId]="parentId"
                          [operation]="operation$ | async"
                          [errorData]="errorData$ | async"
                          (customEvent)="onCustom($event)">
      </file-viewer-detail>
    </div>
  </div>
  <div class="file-viewer__thumbnails"> 
    <file-viewer-thumbnails [data]="files"
                            [idx]="activeIdx"
                            (selectEvent)="setActive($event)">
    </file-viewer-thumbnails>
  </div>
</div>



