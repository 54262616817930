import { Component, Input, OnInit,  EventEmitter, Output } from '@angular/core';
import {  BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { NoteService } from '../../services';
import { IErrorData } from '../../../../../shared/interfaces';
import { NoteEvent, NoteAction, NoteReplyLength } from '../../enums/note.enums';
import { INoteReply } from '../../interfaces';

@Component({
  selector: 'note-reply',
  templateUrl: './note-reply.component.html'
})
export class NoteReplyComponent implements OnInit {
  @Input() noteId: number;
  @Input() isPoImportNote: boolean = false;
  @Input() errorId: number = 0;
  @Input() errorData: IErrorData[] = null;

  @Output() public replyEvent = new EventEmitter<IHomEventEmitter>();
  
  public hasReplyValue$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public charactersLeft$: BehaviorSubject<number> = new BehaviorSubject(0);
  public replyMax: number = 0;

  constructor(
    public noteService: NoteService) {
      this.charactersLeft$.next(NoteReplyLength.externalMax);
    }

  public replyChange(val: string): void {
    this.hasReplyValue$.next(val.length ? true : false);
    this.charactersLeft$.next(this.replyMax - val.length);
  }

  public sendReply(val: string): void {
    const data: INoteReply = { noteId: this.noteId, reply: val };
    const emitter: IHomEventEmitter = { requestor: 'note-reply', event: NoteEvent.sendReply, action: NoteAction.fromReply, data: data };
    this.replyEvent.emit(emitter);
  }

  ngOnInit() {
    this.replyMax = this.isPoImportNote ? NoteReplyLength.unassignedMax : NoteReplyLength.externalMax;
    this.charactersLeft$.next(this.replyMax);
  }
}
