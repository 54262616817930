/** Imports for Angular modules */
import { Injectable, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Store, select } from '@ngrx/store';

import { Subscription, BehaviorSubject} from 'rxjs';
import { debounceTime,  take, filter,  distinctUntilChanged } from 'rxjs/operators';
import { cloneDeep }  from 'lodash'; //pulling for just the methods needed is not working at this time, but keep trying

//pipes
import { HomPhonePipe } from 'hom-lib/hom-pipes';

//interfaces
import { IAppConstants, appConstants } from '../../../shared/constants/index';
import { IFieldDefinition } from '../../dynamic-forms/index';
import { IErrorData, ISelectResolverData } from '../../../shared/interfaces/index';
import { ISelectionListByKey } from '../interfaces';
import { ISelectListData } from '../../../shared/store/selectionLists/index';

//enums
import { FieldSuffix } from '../enums/dynamic-field.enums';

//store
import * as fromSelectionLists from '../../../shared/store/selectionLists/index';
import * as fromMetaData from '../../dynamic-list/store/reducers/meta-data.reducer';
import { getMetaDataByType } from '../../dynamic-list/store/selectors/meta-data.selectors';
import * as fromRoot from '../../../app/store/reducers/index';
import * as fromFeature from '../store/reducers/feature.reducer';

export interface IValueChanged {
  key: string;
  value: any;
}

@Injectable()
export class MetaDataService  implements OnInit, OnDestroy {
  public mdsReady$: BehaviorSubject<boolean>;
  public valueChanged$: BehaviorSubject<IValueChanged>;

  constructor(
    public datePipe: DatePipe,
    public decimalPipe: DecimalPipe,
    public phonePipe: HomPhonePipe,
    @Inject(appConstants) public myConstants: IAppConstants,
    public store: Store<fromFeature.IAllDynamicData>,
    public rootStore: Store<fromRoot.IState>) {
    this.mdsReady$ = new BehaviorSubject(false);
    this.valueChanged$ = new BehaviorSubject(null);
}

  subscription: Subscription = new Subscription();
  fieldDefinitions: IFieldDefinition[] = [];
  seedData: any = {};  //holds crud info and api calls for enums
  selectionLists: ISelectionListByKey[] = [];
  parentId: number;

  ngOnInit(): void {
  }

  public get fieldDefinitionsLoaded(): boolean {
    return this.fieldDefinitions.length > 0;
  }

  public get selectionListsLoaded(): boolean {
    return this.selectionLists.length > 0;
  }

  public getSeedData() {
    return this.seedData;
  }

  public hasSelectionLists(displayFields: string[]): boolean {
    let hasLists: boolean = false;
    displayFields.forEach(key => {
      const fieldDef = this.getFieldDefinition(key);
      if (fieldDef.fieldType === this.myConstants.fieldTypeEntity || fieldDef.fieldType === this.myConstants.fieldTypeSelect) {
        hasLists = true;
        return true;
      }
    });
    return hasLists;
  }

  public setFieldDefinitions(storeName: string): void {
    if (!storeName) {
      console.log('DEV ERROR: Call to setFieldDefinitions and storeName is not set ', storeName);
      return;
    }
    this.subscription.add(this.store.pipe(
      select(getMetaDataByType(storeName)))
      .pipe(filter((state: fromMetaData.IMetaDataState) => state.fieldDefinitions.length > 0))
      .subscribe((state: fromMetaData.IMetaDataState) => {
        this.fieldDefinitions = cloneDeep(state.fieldDefinitions);
        this.seedData = cloneDeep(state.seedData);
        if (this.fieldDefinitions.length > 0) {
          this.mdsReady$.next(true);
        }
      }
      ));
  }

  public setFieldDefinitionsManually(fieldDefinitions: IFieldDefinition[], seedData: any): void {
        this.fieldDefinitions = cloneDeep(fieldDefinitions);
        this.seedData = cloneDeep(seedData);
        this.mdsReady$.next(true);

  }

  hasRequiredFields(displayFields: string[]): boolean {
    let found: boolean = false;
    displayFields.forEach(key => {
      const fieldDef = this.getFieldDefinition(key);
      if (fieldDef && fieldDef.required) {
        return true;
      }
    });
    return found;
  }
  /*
   * Dynamically creates a form group based on display fields, operation, data and field definitions
   * What you do with it is up to you.
   */
  public loadDynamicFormGroup(displayFields: string[], row: any, operation: string): FormGroup {
    let group = {};
    displayFields.forEach(key => {
      const fieldDef = this.getFieldDefinition(key);
      if (!fieldDef) {
        console.log('DEV ERROR:  displayField: ', key, ' does not exist in fieldDefinitions', this.fieldDefinitions);
      }
      //Set the value for the field.  If need to add any emitters to the field, looks like should do that within this method.  See API for FormControl.
      let fieldKey = fieldDef.key.split('_')[0];
      //handle coming in with entity name like unitMeasure, but data value is under unitMeasure_unitMeasureId
      if (row && !row[fieldKey]) {
        if (row[key]) {
          fieldKey = key;
        } else if (row[fieldKey + '_' + fieldKey + 'Id']) {
          fieldKey = fieldKey + '_' + fieldKey + 'Id';
        }
      }
      let fieldValue = !row || (operation === this.myConstants.operationTypeCreate && !row[fieldKey])
        ? this.getDefaultValue(fieldDef.fieldType)
        : this.getDataValue(key, row, operation, this.selectionLists);
      let isDisabled: boolean = this.isDisabled(key, operation);

      //Create the field (formcontrol)
      if (operation === this.myConstants.operationTypeCreate && fieldDef.isId) {
        //for id fields, the value will be set in the back end
        fieldDef.required = false;
        fieldDef.validations = [];
      }
      group[fieldDef.key] = fieldDef.validations.length > 0
        ? new FormControl({ value: fieldValue, disabled: isDisabled }, { validators: fieldDef.validations, updateOn: 'change' }) //if need to add async validators, add as 3rd parm here
        : new FormControl({ value: fieldValue, disabled: isDisabled });

      //add hidden field to form to hold file itself.
      if (fieldDef.fieldType === this.myConstants.fieldTypeImage) {
        group[fieldDef.key.concat(FieldSuffix.fileSource)] = new FormControl({ value: '', disabled: isDisabled }, { updateOn: 'change' });
      }

    });

    let  form: FormGroup = new FormGroup(group);
    //After form has been defined, add setting of any custom validation messages
    displayFields.forEach(key => {
      const field = this.getFieldDefinition(key);
      const fieldControl = form.controls[field.key];
      if (!fieldControl) {
        console.log('DEV ERROR:  displayField: ', key, ' does not exist in fieldDefinitions');
      }
      //Debounce just gives the user some time to type in the value before it actually validates 
      //  - so you don't get the error for invalid email when you haven't typed in the full email yet
      // validationMessages contains the POSSIBLE messages this control can receive
      const waitTime: number = field.fieldType === this.myConstants.fieldTypeBoolean ? 0 : 500;
      this.subscription.add(fieldControl.valueChanges.pipe(
        debounceTime(waitTime),
        distinctUntilChanged())
        .subscribe(value => {
          //if a component wants to listen to the change event for any field, they can subscribe to this observable and filter by key (or not)
          this.valueChanged$.next({ key: field.key, value: value });
          if (field.validationMessages) {
            this.initValidationMessage(field.key);
            this.setValidationMessage(field.key, fieldControl);
          }
        }));
    });

    //special listener for hidden generated fields:  initially just _fileSource
    displayFields.forEach(key => {
      const field = this.getFieldDefinition(key);
      if (field.fieldType === this.myConstants.fieldTypeImage) {
        const fieldControl = form.controls[field.key.concat(FieldSuffix.fileSource)];
        if (fieldControl) {
          this.subscription.add(fieldControl.valueChanges.pipe(
            debounceTime(0),
            distinctUntilChanged())
            .subscribe(value => {
              //if a component wants to listen to the change event for any field, they can subscribe to this observable and filter by key
              //emit the File value
              this.valueChanged$.next({ key: field.key, value: fieldControl.value });
            }));
        }
      }
    });

    return form;
  }

  isDecimalField(fieldType: string): boolean {
    return fieldType === this.myConstants.fieldTypeDoubleDecimal || fieldType === this.myConstants.fieldTypeCurrency;
  }

  /*
   * Returns the field definition array
   * Assumes this service is a provider within the component - component-level sandbox
   */
  public getAllFieldDefinitions(): IFieldDefinition[] {
    if (!this.fieldDefinitions) {
      console.log('DEV ERROR: fieldDefinitions are empty.  Calling component did not call setFieldDefinitions');
    }
      return this.fieldDefinitions;
  }

  /*
   * Returns the row from field definitions with this key
   */
  public getFieldDefinition(key: string): IFieldDefinition {
      if (!key) return null;
    return this.fieldDefinitions.find(k => k.key == key);
  }

  /*
   * Returns the seeded definition value for a given field using the key for that field and the property name like 'label'
   */
  public getPropertyValue( key: string, definitionName: string): any {
    const fieldDef = this.getFieldDefinition(key);
    if (!fieldDef.hasOwnProperty(definitionName)) {
      console.log('DEV ERROR: The property name you are trying to access does not exist on IFieldDefinition: ', definitionName);
      return null;
    }
    return fieldDef.hasOwnProperty(definitionName) ? fieldDef[definitionName] : this.defaultByType(fieldDef, definitionName);
  }

  /*
   *  Inits the validation message for the requested field 
   */
  initValidationMessage(key: string): void {
    this.fieldDefinitions.find(x => x.key === key).validationMessage = '';
  }

  /*
   *  Returns the validation message currently displayed, if any, for the requested field 
   */
  public getValidationMessage(key: string) {
    return this.fieldDefinitions.find(x => x.key === key).validationMessage;
  }
  /*
   *  Called when a field changes, checks for matches between the validationMessages for this field.key and the validations set on this field.key
        If match found, sets validation message.
   */
  public setValidationMessage(key: string, c: AbstractControl) {
    const def = this.fieldDefinitions.find(x => x.key === key);
    if (!def) {
      return;
    } else if ((c.touched || c.dirty) && c.errors) {
      this.fieldDefinitions.find(x => x.key === key).validationMessage = Object.keys(c.errors).map(key =>  def.validationMessages[key]).join(' ');
    }
  }

  public clearValidationMessages(displayFields: string[]) {
    displayFields.forEach(key => {
      const fieldDef = this.getFieldDefinition(key);
      fieldDef.validationMessage='';
    });
  }

  /*
   *  Takes errors returned from the server and applies them to the form or field, as applicable
   */
  public mergeServerErrors(displayFields: string[], errorData: IErrorData[]) {
    let errorMessage = '';
    displayFields.forEach(key => {
      const fieldDef = this.getFieldDefinition(key);
      let errorItem = errorData.find(x => x.key.replace('model_', '').split('_')[0] === fieldDef.key);
      if (!errorItem) {
        if (errorData.find(x => x.key.replace('model_', '').split('_').length > 1)) {
          errorItem = errorData.find(x => x.key.replace('model_', '').split('_')[1] === fieldDef.key);
        }
      }

      if (errorItem) {
        fieldDef.validationMessage = errorItem.value.join(", ");
      }
    });

    const modelMsg = errorData.find(x => x["key"] === '__Model');
    if (modelMsg) {
      errorMessage = modelMsg.value.join(", ");
    } 
    return errorMessage;
  }
  /*
     field:  row from IFieldDefinition for the field you need the value of
     row:  row from your data object
     operation:  crud value as defined in IAppConstants.operation
      selectionList:  key, value where value = ISelectionList - so a list of selection lists accessible by the key to that selection list
  */
  getDataValue(key: string, row: any, operation: string, selectionLists: ISelectionListByKey[] = null) {
    const fieldDef = this.getFieldDefinition(key);
      let fieldValue: any;
        //Handle fkeys - only want to use the first part for fkeys.
        //Example: certificationType_lookupValueId.  Field Definition infor wil be under certificationType.
     let fieldKey = fieldDef.key.split('_')[0];
        switch (fieldDef.fieldType){
          case this.myConstants.fieldTypeBoolean:
            fieldValue = !row || !row[fieldKey] ? false : row[fieldKey];
            break;
          case this.myConstants.fieldTypePhone:
            fieldValue = this.phonePipe.transform(row[fieldKey], '');
            break;
          case this.myConstants.fieldTypeDate:
            fieldValue = this.datePipe.transform(row[fieldKey], 'MM/dd/yyyy');
            break;
          case this.myConstants.fieldTypeImage:
            //displayed as a text field since input type=file values cannot be set programmatically.
            //actual image select field is hidden. See FieldSuffix.fileSource where used.
            fieldValue = row.hasOwnProperty('fileName') ? row['fileName'] : row[fieldKey];
            break;
          case this.myConstants.fieldTypeDateTime:
          case this.myConstants.fieldTypeDateTimeNull:
            fieldValue = this.datePipe.transform(row[fieldKey], 'short');
            break;
          case this.myConstants.fieldTypeTimeSpan:
            fieldValue = +this.datePipe.transform(row[fieldKey], 'H') > 12
              ? this.datePipe.transform(row[fieldKey], 'H:mm')
              : this.datePipe.transform(row[fieldKey], 'hh:mm');
            break;
          case this.myConstants.fieldTypeDoubleDecimal:
          case this.myConstants.fieldTypeCurrency:
            let newVal = this.decimalPipe.transform(row[fieldKey], '1.2-2');
            newVal = !newVal ? '0' : newVal.toString().replace(',', '');
            fieldValue = newVal;
            break;
          case this.myConstants.fieldTypeSelect:
          case this.myConstants.fieldTypeEntity:
            fieldValue = null;
            if (!fieldDef.selectListDefinition) {
              break;
            }
            if (!selectionLists) {
              console.log('DEV ERROR:  Selection List bucket for key: ', key, ' with objectType: ', fieldDef.selectListDefinition.objectType, ' and storeName: ', fieldDef.selectListDefinition.storeName, ' is not defined in selectionLists.reducers or Seed data is incorrect');
                break;
            }
            const selectList = selectionLists.find(l => l.key == fieldDef.selectListDefinition.storeName);
              if (!selectList) {
               // console.log('DEV ERROR:  Selection List bucket for key: *', key, '* with objectType: *', fieldDef.selectListDefinition.objectType, ' and storeName: ', fieldDef.selectListDefinition.storeName, '* is not defined in selectionLists.reducer', fieldDef, selectList);
                break;
            }

            const keyToUse = fieldDef.selectListDefinition.keyField;

            if ((operation === this.myConstants.operationTypeCreate && !row[keyToUse]) ) {
              //may have seed data with defaults
              return null;
            }
            if (fieldDef.selectListDefinition.keyField !== '' && !row.hasOwnProperty(fieldDef.selectListDefinition.keyField) ) {
              console.log('DEV ERROR SEEDING: Verify seed data for keyField in selectionListSettings is correct.  Corresponding row property was not found: ', fieldDef.selectListDefinition.keyField, ' row: ', row);
            }

            let labelName = fieldDef.selectListDefinition.labelProperty;
            let valueName = fieldDef.selectListDefinition.valueProperty;
            let items = selectList.items;
            if (!items) {
                fieldValue = null;
                break;
            }
            if (row.hasOwnProperty(fieldDef.selectListDefinition.keyField)) {
              const matchOn = fieldDef.selectListDefinition.listType === this.myConstants.dataTypeEnum ? labelName : valueName;
              const selectedItem = items.find(item => item[matchOn] == row[fieldDef.selectListDefinition.keyField]);
              //console.log('Leave In to Verify seeding set correctly: getDataValue and matchOn: ', matchOn, ' valueName: ', valueName, ' keyToUse: ', keyToUse, ' selected item: ', selectedItem);
              fieldValue = selectedItem ? selectedItem : null;
            }
            break;
        default:
            fieldValue = row[fieldKey];
            break;
        }

        return fieldValue;
  }

  public getDefaultDisplayFields() {
     return this.fieldDefinitions.map(f => f.key);
    }

  public isDisabled(key: string, operation: string) {
    let disabled = false;

    const fieldDef = this.getFieldDefinition(key);

    if (operation === this.myConstants.operationTypeDetails 
        || fieldDef.alwaysReadOnly 
        || !fieldDef.isEditable) {
      disabled = true;
    }
    return disabled;
  }

  public isValid(form: FormGroup, key: string, operation: string) {
    const control = form.controls[key];
    const disabled = this.isDisabled(key, operation);
    if (disabled) {
      control.disable();
    } else {
      control.enable();
    }
    if (disabled || operation === this.myConstants.operationTypeDetails) return true;
      
    return control.valid;
  }


  eligibleField(key: string, operation: string) {
    const fieldDef = this.getFieldDefinition(key);

    return fieldDef &&
      //this.selectItems &&
      (
        (operation === this.myConstants.operationTypeCreate && !fieldDef.isId) ||
          (operation === this.myConstants.operationTypeDetails) ||
          (
            (operation === this.myConstants.operationTypeEdit ||
                operation === this.myConstants.operationTypeSingleEdit ||
                operation === this.myConstants.operationTypeCreate) &&
              fieldDef.visibleOnEdit));
  }
  
  /* Passing in all fields displayed on the form.
   * Method will weed out display fields that are not defined as selection lists
   * requestor:  details or filter
   */
  public loadSelectionLists(displayFields: string[], requestor: string, parentId: number = -1): void {
    this.selectionLists = [];
    this.parentId = parentId;
    if (!displayFields.length) {
      displayFields = this.getDefaultDisplayFields();
    }

    let selectionListFields: IFieldDefinition[] = this.fieldDefinitions
      .filter(field => displayFields.find(k => k == field.key) !== undefined && this.validSelectField(field));
    if (!selectionListFields) return;

    //map to a new array with the spread operator to ensure have a distinct list of stores to fill
    const selectData: ISelectResolverData[] = [...new Set(selectionListFields.map(f => {
      return {
        storeName: f.selectListDefinition.storeName,
        parentDependent: f.selectListDefinition.methodName && f.selectListDefinition.methodByKey ? true : false,
        parentId: -1
      }
    }))];

    this.subscription.add(this.rootStore.select('selectionLists') 
      .pipe(take(1))
      .subscribe((state: fromSelectionLists.IStoreState) => {
        selectData.forEach(item => {
          if (state.hasOwnProperty(item.storeName)) {
            //not handling parent dependent
            const useParentId = item.parentDependent ? this.parentId : -1;
            const objData: ISelectListData = state[item.storeName].objData && state[item.storeName].objData.length > 0
              ? state[item.storeName].objData.find(x => x.parentId == useParentId)
              : null;
            this.selectionLists.push({ key: item.storeName, items: objData ? objData.data : [] });
          }
        //  else {
        //    console.log('DEV ERROR:  Selection List bucket for: ', item.storeName, ' is not defined in selectionLists.reducers');
        //  }
        });
      }));

  }

  validSelectField(field: IFieldDefinition): boolean {
    const isSelect = field.fieldType === this.myConstants.fieldTypeSelect ||
      field.fieldType === this.myConstants.fieldTypeEntity ||
      field.fieldType === this.myConstants.fieldTypeEditableSelect ||
      field.fieldType === this.myConstants.fieldTypeFilterableSelect;

    return isSelect && field.visibleOnEdit
      && field.hasOwnProperty('selectListDefinition')
      && field.selectListDefinition !== null
      && field.selectListDefinition.storeName !== ''
      && !field.selectListDefinition.forFilterOnly;
  }

  overrideLabel(key: string, label: string) {
    let def = this.fieldDefinitions.find(x => x.key === key);
    if (def) {
      def.label = label;
      //this.fieldDefinitions.find(x => x.key === key).label = label;
      //this.fieldDefinitions.find(x => x.key === key).placeholder = label;
    }
  }

  overridePattern(key: string, pattern: RegExp) {
    let def = this.fieldDefinitions.find(x => x.key === key);
    if (def) {
      def.pattern = pattern;
    }
  }

  overrideStringLength(key: string, length: number) {
    let def = this.fieldDefinitions.find(x => x.key === key);
    if (def) {
      def.maxStringLength = length;
    }
  }

  /*
   * types can be: homNbrRange, maxlength, pattern, required, homIsObject, homMax
   * overrides the type within the object validationMessages (note plural)
  */
  overrideValidationMessage(key: string, type: string, message: string): void {
    let def = this.fieldDefinitions.find(x => x.key === key);
    if (def && def.validationMessages) {
        def.validationMessages[type] = message;
    }
  }

  /*
   * Returns the items associated to the requested selection list via the key for the field
   */
  getSelectItems(key: string): any[] {
      if (!key) {
        console.log('DEV ERROR: passed in key is invalid: ', key);
        return [];
    }

    const fieldDef = this.getFieldDefinition(key);
    if (!fieldDef) {
      console.log('DEV ERROR: no field def for key: ', key);
      return [];
    }
    //Not a selection list:  type and required definition checks
    if (fieldDef.fieldType !== this.myConstants.fieldTypeEntity &&
      fieldDef.fieldType !== this.myConstants.fieldTypeSelect &&
      fieldDef.fieldType !== this.myConstants.fieldTypeEditableSelect &&
      fieldDef.fieldType !== this.myConstants.fieldTypeFilterableSelect) {
      return [];
    }
    if (!fieldDef.hasOwnProperty('selectListDefinition') || !fieldDef.selectListDefinition) {
      console.log('DEV ERROR: key: ', key, ' definition is undefined. ', fieldDef);
      return [];
    }

    const selectList = this.selectionLists.find(l => l.key == fieldDef.selectListDefinition.storeName);
    return selectList ? selectList.items : [];
  }

  /*
   * Loads all the the selection lists as specified in the seeded field definitions for this component
   * Loads data from the selection list store
   * Assumes the route contains the selection list resolver
 */
  getSelectionLists(fieldDefinitions: IFieldDefinition[], displayFields: string[]) {
    let selectionLists: ISelectionListByKey[] = [];

    let selectionListFields: IFieldDefinition[] = this.fieldDefinitions
      .filter(field => displayFields.find(k => k == field.key) !== undefined && this.validSelectField(field));

    if (!selectionListFields) return;

    const selectData: ISelectResolverData[] = [...new Set(selectionListFields.map(f => {
      return {
        storeName: f.selectListDefinition.storeName,
        parentDependent: f.selectListDefinition.methodName ? true : false,
        parentId: -1
      }
    }))];

    this.subscription.add(this.rootStore.select('selectionLists') 
      .pipe(take(1))
      .subscribe((state: fromSelectionLists.IStoreState) => {
        selectData.forEach(item => {
          if (state.hasOwnProperty(item.storeName)) {
            const useParentId = item.parentDependent ? this.parentId : -1;
            const objData: ISelectListData = state[item.storeName].objData && state[item.storeName].objData.length > 0
              ? state[item.storeName].objData.find(x => x.parentId == useParentId)
              : null;
            selectionLists.push({ key: item.storeName, items: objData ? objData.data : [] });
          //  if (!state[item.storeName].objData || !state[item.storeName].objData.length) {
          //    console.log('DEV WARNING: Selection List bucket for: ', item.storeName, ' is empty.  If it should have data, check selection-list.resolver and selectSettings seed data');
          //  }
          }
        //  else {
        //    console.log('DEV ERROR:  Selection List bucket for: ', item.storeName, ' is not defined in selectionLists.reducer');
        //  }
        });
      }));

    return selectionLists;
  }

  getLabel(key: string) {
    const fieldDef = this.getFieldDefinition(key);
    return fieldDef ? fieldDef.label : "Undefined";
  }

  getHint(key: string) {
    const fieldDef = this.getFieldDefinition(key);
    return fieldDef ? fieldDef.placeholder : "Undefined";
  }

  getSelectListLabel(key: string) {
    const fieldDef = this.getFieldDefinition( key);
    return fieldDef ? fieldDef.selectListDefinition.labelProperty : "Undefined";
  }
  
   defaultByType(object: any, property: string) {
    return this.getDefaultValue(typeof object[property]);
  }
  
  getDefaultValue(fieldType: string) {
    switch (fieldType) {
      case this.myConstants.fieldTypeNumber:
        return 0;
      case this.myConstants.fieldTypeBoolean:
      case 'checkbox':
      case 'boolean':
        return false;
      case this.myConstants.fieldTypeDate:
      case this.myConstants.fieldTypeDateTime:
        return '';
      case this.myConstants.fieldTypeEntity:
      case this.myConstants.fieldTypeSelect:
      case 'object':
        return null;
      case this.myConstants.fieldTypeColor:
        return '#000000';
      case this.myConstants.fieldTypeTimeSpan:
        return '08:00';
      default:
        return '';
    }
  }

  //fieldTypeSelect: 'select',
  //fieldTypeEntity: 'entity',
  //fieldTypeDate: 'date',
  //fieldTypeDateTime: 'dateTime',
  //fieldTypeDateTimeNull: 'dateTimeNull',
  //fieldTypeString: 'string',
  //fieldTypeEmail: 'email',
  //fieldTypeNumber: 'number',
  //fieldTypeNumericRange: 'numericrange',
  //fieldTypeDecimalRange: 'decimalrange',
  //fieldTypeMultiSelect: 'multiselect',
  //fieldTypeBoolean: 'bool',
  //fieldTypeTextArea: 'textarea',
  //fieldTypeDateRangePicker: 'daterangepicker',
  //fieldTypeSingleDatePicker: 'singledatepicker',
  //fieldTypeRadioGroup: 'radiogroup',
  //fieldTypeMultiCheckbox: 'multicheckbox',
  //fieldTypePhone: 'phone',
  //fieldTypeZipCode: 'zipCode',
  //fieldTypeImage: 'image',
  //fieldTypeCurrency: 'currency',
  //fieldTypePercentage: 'percentage',
  //fieldTypeDouble: 'double',
  //fieldTypeEditableSelect: 'editableSelect',
  //fieldTypeFilterableSelect: 'filterableSelect',
  //fieldTypeExtra: 'extra',
  //fieldTypeDoubleDecimal: 'doubleDecimal',
  //fieldTypeTimeSpan: 'timeSpan',
  //fieldTypeTimePicker: 'timePicker',
  //fieldTypeIntNull: 'intNull',
  //fieldTypeColor: 'color',


  /*
   * Set the display type based onthe field type
   * Allows display type to vary for filters:  date because date range, select because multip select.
   */
  displayType(key: string, callee: string): string 
  {
    const fieldDef = this.getFieldDefinition( key);
    const isFilter = callee === 'filter';
    let displayType: string = fieldDef.fieldType;

    switch (fieldDef.fieldType) {
      case this.myConstants.fieldTypeNumber:
        displayType = isFilter? this.myConstants.fieldTypeNumericRange : this.myConstants.fieldTypeNumber;
        break;
      case this.myConstants.fieldTypeSelect:
      case this.myConstants.fieldTypeEntity:
        displayType = isFilter? this.myConstants.fieldTypeMultiSelect : this.myConstants.fieldTypeSelect;
        break;
      case this.myConstants.fieldTypeDate:
      case this.myConstants.dataTypeDateTime:
        displayType = isFilter ? this.myConstants.fieldTypeDateRangePicker : this.myConstants.fieldTypeSingleDatePicker;
        break;
      default:
        break;
    }

    if (isFilter && displayType === fieldDef.fieldType) {
      //type was not modified, do an additional check for a filter specific display type
      //if this field definition includes select settings then it could be extra data that needs to be filtered (custom filter)
      if (fieldDef.selectListDefinition && fieldDef.selectListDefinition.hasOwnProperty('storeName') && fieldDef.selectListDefinition.storeName !== '')
        displayType = this.myConstants.fieldTypeMultiSelect;
    }
    return displayType;
  }

  isReadOnly(key: string, operation: string) {
    return operation === this.myConstants.operationTypeDetails ||
      this.getPropertyValue(key, 'isId') ||
      this.getPropertyValue(key, 'alwaysReadOnly') ||
      !this.getPropertyValue(key, 'isEditable');
  }
  
  validationMessage(key: string) {
    const fieldDef = this.getFieldDefinition(key);
    return fieldDef ? fieldDef.validationMessage : '';
  }

  // Sets error messages at the field level
  setErrorMessages(errorData: IErrorData[]): void {
    if (!this.fieldDefinitionsLoaded) {
      return;
    }

    if (!errorData || !errorData.length) return;


    this.fieldDefinitions.forEach(def => {
      let errorItem = errorData.find(x => x.key.replace('model_', '').split('_')[0] == def.key);
      if (!errorItem) {
        if (errorData.find(x => x.key.replace('model_', '').split('_').length > 1)) {
          errorItem = errorData.find(x => x.key.replace('model_', '').split('_')[1] === def.key);
        }
      }

      if (errorItem) {
        def.validationMessage = errorItem.value.join(", ");
      }
    });
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}


