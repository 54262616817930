import { Injectable } from '@angular/core';
import { IFieldDefinition } from '../../../../fw/dynamic-forms';
import { FieldDefinitionService } from '../../../../shared/services';
import { IHomDictionary, IListMetaData, IPageMetaData } from '../../../../shared/interfaces';
import { IProjectTaskViewModel } from '../../view-models';
import { IListFilter } from '../../../../fw/dynamic-list/interfaces';

export enum TaskSearchType { 
  showAll = 'showAll',
  showPending = 'showPending'
}
export enum TaskStatus {
  normal = 'Normal',
  overDue = 'OverDue',
  warning = 'Warning'
}
export interface ITaskComplete {
  projectTaskId: number;
  projectTaskActionId: number;
  data: IHomDictionary[];
}
@Injectable({
  providedIn: 'root'
})
export class TaskManagerService   {
  keyRecTemplate: string = 'hom-taskActionKeyRecTemplate';
  scheduleWoTemplate: string = 'hom-taskActionScheduleWoTemplate';
  taskFieldDefinitions: IFieldDefinition[] = [];
  projectData: { projectId: number, data: IProjectTaskViewModel[], listFilter: IListFilter, pageMetaData: IPageMetaData }[] = [];
  hasMetaData: boolean;

  constructor(public fieldDefinitionService: FieldDefinitionService) { }

  public get fieldDefinitions(): IFieldDefinition[] {
    return this.taskFieldDefinitions;
  }

  addProjectData(projectId, data: IProjectTaskViewModel[], listFilter: IListFilter, pageMetaData: IPageMetaData): void {
    if (!this.projectData.find(x => x.projectId === projectId)) {
      this.projectData.push({ projectId: projectId, data: data, listFilter: listFilter, pageMetaData: pageMetaData });
    } else {
      this.projectData.find(x => x.projectId === projectId).data = data;
      this.projectData.find(x => x.projectId === projectId).listFilter = listFilter;
      this.projectData.find(x => x.projectId === projectId).pageMetaData = pageMetaData;
    }
  }

  removeProjectData(projectId: number): void {
    this.projectData = this.projectData.filter(x => x.projectId !== projectId);
  }

  setMetaData(metaData): void {
    if (!this.hasMetaData) {
      if (!metaData || this.fieldDefinitions.length > 0) {
        return;
      }
      this.hasMetaData = true;
      const displayFields: string[] = Object.keys(metaData.fieldMetaData);
      this.taskFieldDefinitions = this.fieldDefinitionService.loadFieldDefinitions(displayFields, metaData.fieldMetaData, null);
    }
  }
}
