<div class="multi-item">
    <div title="{{item.label}}"
         class="multi-item__inner" 
         [ngClass]="{'multi-item__inner--toggled': toggled, 'multi-item__inner--disabled': item.disabled}"
         (click)="toggleItem()"><span *ngIf="item.labelIcon"><i class="{{ item.labelIcon }}"></i>&nbsp;&nbsp;</span>{{ item.label }}
        <i *ngIf="item.subItems && item.subItems.length"
           class="fas fa-angle-right multi-item__inner__icon"
           [ngClass]="{'multi-item__inner__icon--toggled': toggled}"></i>
    </div>
</div> 
