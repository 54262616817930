import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'work-orders-summary-container',
  templateUrl: './work-orders-summary-container.component.html'
})
export class WorkOrdersSummaryContainerComponent implements OnInit {
  public projectId: number;
  public title: string;

  constructor(public  activeRoute: ActivatedRoute  ) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get("id");
      this.title = 'Work Orders for Project '.concat(this.projectId.toString());
    });
  }

}
