import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { IModal } from '../interfaces/i-modal';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ModalService {
  public ready$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  _definition: IModal;
  _definition2: IModal;
  _opened: boolean = false;
  _opened2: boolean = false;
  isSubModal: boolean = false;
  body: HTMLBodyElement = (<HTMLBodyElement>document.body);
  renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);
  constructor(
    public router: Router,
    public rendererFactory: RendererFactory2) { }


  public get opened(): boolean {
    return this._opened;
  }

  public get definition(): IModal {
    return this._definition;
  }

  public open(definition: IModal): void {
    const state = definition.state ? definition.state : null;
    definition.showLoading = definition.hasOwnProperty('showLoading') ? definition.showLoading : false;
    this.isSubModal = definition.isSubModal || false;
    this.router.navigate([{ outlets: !definition.isSubModal ? { 'modal': 'loading/modal__content__overflow' } : { 'modal2': 'loading2/modal__content__overflow' } }]).then(success => {
      if (success) {
        !definition.isSubModal ? (
          this._definition = definition,
          this._opened = true
        ) : (
          this._definition2 = definition,
          this._opened2 = true
        );
        this.renderer.addClass(this.body, 'hide-scrollbar');
        this.ready$.next(true);
       this.router.navigate([{
          outlets: !definition.isSubModal ? {
            modal: (definition.optionalParams ? [definition.path, definition.id, definition.optionalParams] : definition.path)
          } : { modal2: (definition.optionalParams ? [definition.path, definition.id, definition.optionalParams] : definition.path) }
        }], { state: state }).catch(e => {
          console.log('Route not found, navigation stopped with error', e, 'from definition',definition);
        });
      }
    }).catch(e => {
      console.log('Route not found, navigation stopped with no error raised', definition);
    });
  }

  public close(): void {
    if (!this.isSubModal) {
      this.router.navigate([{ outlets: { modal: null } }]);
      this._opened = false;
    } else {
      this.router.navigate([{ outlets: { modal2: null } }]);
      this._opened2 = false;
      this.isSubModal = false;
    }
    this.renderer.removeClass(this.body, 'hide-scrollbar');
    if (this.definition.onExit) {
      this.definition.onExit();
    }
  }

  public castClose(): void {
    if (!this._definition.castExit) return; 
    this.close();
  }
}
