export enum HomLocalStorage {
  SessionId = 'sessionId', //if this is just for this browser session, store it in the user-session-event.service - do not need local storage
  HomUser = 'homuser',
  HomLogoutEvent = 'logoutEvent',
  HomGlobalSearch = 'hsuh',
  HomVersion = 'hom-version',
  HomPrimaryInstance = 'hom-primaryInstance',
  HomInstance = 'hom-instance',
  HomInstallerPortal = 'hom-installer-portal',
  HomRouteToCrm = 'hom-routeToCrm'
}







