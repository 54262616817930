import { Input, Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { ContactStore, ContactEvent } from '../../enums/contact.enums';
import { getObjectDataById, IAllDynamicData } from '../../../../fw/dynamic-list/store';
import { IContact } from '../../../portals/view-models';
import { ContactManagerComponent } from '../../containers/index';
import { ContactConstantsService } from '../../services/index';
import { UserPriviledgesService } from '../../../../auth/services';

@Component({
  selector: 'portal-contact-manager',
  templateUrl: './portal-contact-manager.component.html'
})
export class PortalContactManagerComponent implements OnInit, OnDestroy  {

  @ViewChild('contactManager') public contactManager: ContactManagerComponent;

  public title$: BehaviorSubject<string> = new BehaviorSubject('');
  public entity: string = 'Contact';
  public currentOperation: string;
  public customButtons: ICustomButton[] = [];
  public contactId: number
  public canIEdit: boolean;
  public viewOnly: boolean = false;
  parent: string = '';
  contactTypeId: number;
  subscription: Subscription = new Subscription();
  operation$: BehaviorSubject<string>;

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<IAllDynamicData>,
    public contactConstantsService: ContactConstantsService,
    public userPriviledgesService: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) {
      this.operation$ = new BehaviorSubject(myConstants.operationTypeDetails);
    }

  public onTitleBarEvent(event: IHomEventEmitter): void {
    switch (event.event) {
      case ButtonType.edit:
        this.operation$.next(this.myConstants.operationTypeEdit);
        break;
      case ButtonType.reload:
        this.contactManager.onReloadRequest();
        break;
      case ButtonType.cancel:
        this.onTitleBarCancel();
        break;
      case ButtonType.save:
        this.contactManager.onSave();
        break;
      default:
        break;
    }
  }

  public onManagerEvent(event: IHomEventEmitter): void {
    switch (event.event) {
      case ContactEvent.updated:
        this.operation$.next(this.myConstants.operationTypeDetails);
       break;
      default:
    }
  }

  ngOnInit() {
    this.activeRoute.data
      .subscribe(d => {
        //this is a property on the route
        this.contactTypeId = d.hasOwnProperty('contactTypeId') ? +d['contactTypeId'] : 0;
        this.parent = d.hasOwnProperty('parent') ? d['parent'] : '';
        this.viewOnly = this.parent === 'sms' ? true : false;
        this.canIEdit = this.viewOnly ? false
          : this.contactTypeId === this.contactConstantsService.contactTypeIdInstaller
            ? this.userPriviledgesService.canManageInstallers$.value
            : true;
      });

    this.activeRoute.paramMap
      .subscribe(paramMap => {
        this.contactId = +paramMap.get('id');
        this.operation$.next(this.myConstants.operationTypeDetails);
      });

    this.subscription.add(this.store.select(getObjectDataById(ContactStore.contactInformation, this.contactId))
      .subscribe((contact: IContact) => {
        if (contact) {
          let title: string = 'Contact Information';
          if (this.parent === 'sms') {
             title = contact.isOrganization && this.contactTypeId === this.contactConstantsService.contactTypeIdInstaller
                ? contact.installerName : contact.entityLabel;

          } 
          this.title$.next(title);
        }
      }));


    //subscribing here, just once instead of 3 times on the form with | aysnc;
    this.subscription.add(this.operation$.subscribe(val => this.currentOperation = val));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onTitleBarCancel() {
    this.operation$.next(this.myConstants.operationTypeDetails);
    this.contactManager.onCancel();
  }


}
