import { IEmail } from './i-email';

export interface IContactMechanismEmail {
  contactMechanismEmailId: number;
  contactMechanism_contactMechanismId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName: string;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName: string;
 isPreferred: boolean;
  active: boolean;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;
  rowVersion: string;
  aggregateRootVersion: string;
  email: IEmail;
  //extra data new
  emailType: string;
  emailTypeId: number;
  emailId: number;
  emailAddress: string;
  uiLabel: string;
  formGroupName: string;
  uiRequired: boolean;
}


export class ContactMechanismEmail implements IContactMechanismEmail {
  contactMechanismEmailId: number;
  contactMechanism_contactMechanismId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName: string;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName: string;
 isPreferred: boolean;
  active: boolean;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;
  rowVersion: string;
  aggregateRootVersion: string;
  email: IEmail;
  emailType: string;
  emailTypeId: number;
  emailId: number;
  emailAddress: string;
  uiLabel: string;
  formGroupName: string;
  uiRequired: boolean;

  constructor(contactMechanismCategoryName: string,
    contactMechanismCategoryTypeName: string,
    contactMechanismCategoryTypeId: number,
    uiLabel: string,
    formGroupName: string,
    uiRequired: boolean = false,
    preferred: boolean = false
  ) {
      this.contactMechanismEmailId = 0;
      this.contactMechanism_contactMechanismId = 0;
      this.contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId = contactMechanismCategoryTypeId;
      this.contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName = contactMechanismCategoryTypeName;
      this.contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName = contactMechanismCategoryName;
      this.isPreferred = preferred;
      this.active = true;
      this.createDate = ''; 
      this.updateDate = '';
      this.createdBy = 0;
      this.updatedBy = 0;
      this.rowVersion = '';
      this.email = { emailId: 0, emailAddress: '' };;
      this.aggregateRootVersion = '';
      this.emailId = 0;;
      this.emailType = contactMechanismCategoryTypeName;
      this.emailTypeId = contactMechanismCategoryTypeId;  
      this.emailAddress = '';
      this.uiLabel = uiLabel;
      this.formGroupName = formGroupName;
      this.uiRequired = uiRequired;
  }
}
