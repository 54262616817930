import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lookup-values',
  templateUrl: './lookup-values.component.html'
})
export class LookupValuesComponent implements OnInit, OnChanges {

  public listDefinition: IListDefinition;
  lookupTypeId: number;

  @Input() canEdit: boolean;

  constructor(
    public adminProviderService: AdminProviderService,
    public activeRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(params => {
      this.lookupTypeId = +params.get('id');
      this.listDefinition = this.adminProviderService.loadLookupValuesListDefinition(this.lookupTypeId, this.canEdit);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['canEdit'] && !changes['canEdit'].isFirstChange()) {
      this.listDefinition = this.adminProviderService.loadLookupValuesListDefinition(this.lookupTypeId, this.canEdit);
    }
  }

}
