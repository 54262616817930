<div class="lswp-reason">
  <div class="lswp-reason__status">
    <span *ngIf="item.uiStatus === isSuccess || item.uiStatus === isCompleted"
          class="app-icon--success" title="Upload is complete"><i class="fal fa-check-circle"></i></span>
    <span *ngIf="item.uiStatus === inError" class="app-icon--danger"><i class="fal fa-times-circle" title="Upload was not successful"></i></span>
    <span *ngIf="item.uiStatus === isCanceled" class="app-icon--danger"><i class="fal fa-ban" title="Purchase Order was canceled"></i></span>
    <span *ngIf="item.uiStatus === isWorking" class="app-icon--primary" title="Upload is progress"><i class="fal fa-spinner spin"></i></span>
    <span *ngIf="!item.uiStatus || item.uiStatus === isSet" class="app-icon--primary"><i class="fas fa-inbox-full" title="Needs Document"></i></span>
  </div>
  <div class="lswp-reason__po">
    {{ item.purchaseOrderNumber }}
  </div>
  <div class="lswp-reason__code">
    <select #reasonCode
            [disabled]="item.uiStatus === isCompleted || item.uiStatus === isCanceled"
            (change)="changeReason(reasonCode.value)">
      <option *ngFor="let code of reasonCodes"
              [selected]="code.id === item.reasonCodeId"
              [value]="code.id">
        {{homUtil.splitWordsByCase(code.name)}}
      </option>
    </select>
  </div>
</div>
<fw-error-box [errorData]="item.error" [errors]="" [onForm]="true" [ignoreKey]="true"></fw-error-box>

