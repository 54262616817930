import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { AdminEvent } from '../../enums/admin.enums';
import { ISurvey } from '../../../view-models/i-survey';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { AdminProviderService } from '../../services/admin-provider.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'surveys',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './surveys.component.html'
})
export class SurveysComponent implements OnInit {

  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadSurveysListDefinition();
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.showSurveyQuestions:
        this.openSurveyQuestions(event.data);
        break;
      case AdminEvent.showProgramServices:
        this.openSurveyProgramServices(event.data);
        break;
      default:
        alert('Under Construction');
       break;
    }
  }

  openSurveyQuestions(survey: ISurvey): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: this.listDefinition.storeName,
      key: this.listDefinition.rowKeyId,
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: survey.surveyId,
    }
    this.modalService.open({
      title: 'Questions for Survey'.concat(' ', survey.surveyName),
      path: 'survey-questions',
      id: survey.surveyId,
      sizeType: ModalSizeType.xlarge,
      castExit: false,
      optionalParams: params,
      hasTabs: false,
    });
  }

  openSurveyProgramServices(survey: ISurvey): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: this.listDefinition.storeName,
      key: this.listDefinition.rowKeyId,
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: survey.surveyId,
    }
    this.modalService.open({
      title: 'Program Services for Survey'.concat(' ', survey.surveyName),
      path: 'survey-pgm-services',
      id: survey.surveyId,
      sizeType: ModalSizeType.medium,
      castExit: false,
      optionalParams: params,
      hasTabs: false,
    });
  }

}
