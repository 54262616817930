import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep, orderBy } from 'lodash';
import { HomEventEmitterService } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IProviderLocationSchedule } from '../../../view-models/index_two';
import { IErrorData, IResponseBase } from '../../../../../shared/interfaces';
import { DomainObjectService } from '../../../../../shared/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

interface IValidSchedule {
  dayOfWeek: number;
  isValid: boolean;
}

@Component({
  templateUrl: './provider-location-schedules.component.html'
})
export class ProviderLocationSchedulesComponent implements OnInit, OnDestroy  {
  public schedules: IProviderLocationSchedule[];
  providerLocationId: number = 0;
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public plSchedules$: BehaviorSubject<IProviderLocationSchedule[]> = new BehaviorSubject([]);
  public updatedSchedules: IProviderLocationSchedule[] = [];
  public validityList: IValidSchedule[] = [];
  public canSave$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public emitterService: HomEventEmitterService,
    public domainObjectService: DomainObjectService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants  ) {
    }

  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.providerLocationId = +paramMap.get('id');

      this.getAllSchedules();
    }));
  }

  public onChange(schedule: IProviderLocationSchedule): void {
    if (schedule) {
      let indx: number = this.updatedSchedules.findIndex(x => x.dayOfWeek == schedule.dayOfWeek);
      if (indx >= 0) {
        this.updatedSchedules[indx].open = schedule.startTime && schedule.endTime ? true : false;
        this.updatedSchedules[indx].startTime = schedule.startTime;
        this.updatedSchedules[indx].endTime = schedule.endTime;
      }
      indx = this.validityList.findIndex(x => x.dayOfWeek == schedule.dayOfWeek);
      if (indx >= 0) {
        this.validityList[indx].isValid = true;
      }
    }
    this.setCanSave();
  }

  public onError(schedule: IProviderLocationSchedule): void {
    if (schedule) {
      let indx: number  = this.validityList.findIndex(x => x.dayOfWeek == schedule.dayOfWeek);
      if (indx >= 0) {
        this.validityList[indx].isValid = false;
      }
    }
    this.setCanSave();
  }

  public onCancel(): void {
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  public onSave(): void {
    this.commitUpdates();
  }

  getAllSchedules() {
    this.working$.next(true);
    this.subscription.add(this.domainObjectService.getByMethodById('ProviderLocationSchedule', 'ByProviderLocation', this.providerLocationId)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          this.setModelForUi(result.data);
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));
  }

  commitUpdates(): void {
    this.working$.next(true);
    this.subscription.add(this.domainObjectService.updateByMethod('ProviderLocationSchedule', 'BulkUpdate', this.updatedSchedules)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          if (this.modalService.opened) {
            this.modalService.close();
          }
        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      }));
  }

  setModelForUi(schedules: IProviderLocationSchedule[]): void {
    let schedulesOut: IProviderLocationSchedule[] = cloneDeep(schedules);

    for (var i = 0; i < 7; i++) {
      if (!schedulesOut.find(x => x.dayOfWeek === i)) {
        schedulesOut.push({
          providerLocationScheduleId: 0,
          providerLocation_providerLocationId: this.providerLocationId,
          dayOfWeek: i,
          open: false,
          startTime: '',
          endTime: '',
          dayName: this.getDayName(i)
        })
       };
      }

    schedulesOut.forEach((x: IProviderLocationSchedule) => {
      x.open = x.startTime && x.endTime ? true : false;
      x.dayName = this.getDayName(x.dayOfWeek);
    });

    this.plSchedules$.next(orderBy(schedulesOut, ['dayOfWeek']));
    this.updatedSchedules = cloneDeep(this.plSchedules$.value);
    this.validityList = this.updatedSchedules.map(x => <IValidSchedule>{
      dayOfWeek: x.dayOfWeek,
      isValid: true
    });
  }
  
  getDayName(dayNbr: number): string {
    switch (dayNbr) {
      case 0:
        return 'SUN';
      case 1:
        return 'MON';
      case 2:
        return 'TUE';
      case 3:
        return 'WED';
      case 4:
        return 'THU';
      case 5:
        return 'FRI';
      case 6:
        return 'SAT';
      default:
        return '';
    }
  }

  setCanSave(): void {
    this.canSave$.next(this.validityList.find(x => !x.isValid) ? false : true);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
