<div *ngIf="form && operation && operation !== myConstants.operationTypeDelete" class="note__form">
  <!--recipient selection area-->
  <!-- Notify & Email (only visible on create)-->
  <div *ngIf="operation === myConstants.operationTypeCreate && showRecipients" class="note-edit__recipients" id="recipient-area">
    <div class="note-edit__recipients--select">
      <form *ngIf="multiSelectOptions" [formGroup]="sendForm" novalidate>
        <div class="form-group">
          <label for="providerUserMultiSelect" [htmlFor]="providerUserMultiSelect">
            <span class="note-edit__recipients--icon"><i class="fas fa-paper-plane"></i></span>Recipients
          </label>
          <div *ngIf="multiSelectOptions && multiSelectSettings">
            <!--ensure have all value fields fully defined before triggering constructor of component-->
            <fw-multiselect-dropdown #puMultiSelect
                                     class="note-edit__dropdown"
                                     [options]="multiSelectOptions"
                                     id="providerUserMultiSelect"
                                     name="providerUserMultiSelect"
                                     [formControl]="sendForm.controls['providerUserMultiSelect']"
                                     [texts]="multiSelectLabels"
                                     [settings]="multiSelectSettings"
                                     (onAdded)="addSendToEvent($event)"
                                     (onRemoved)="removeSendToEvent($event)"
                                     required>
            </fw-multiselect-dropdown>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="sendToList.length" class="note-edit__recipients--list">
      <ul *ngFor="let user of sendToList">
        <li class="note-edit__recipients--list-item">
          <button type="button" class="app-btn-icon" [ngClass]="{'app-icon-neutral': !user.sendEmail, 'app-icon--success': user.sendEmail }"
                  (click)="onMailBox(user.providerUser.providerUserId)" [disabled]="!user.providerUser.emailAddress">
            <span *ngIf="user.sendEmail" title="Email will be sent"><i class="fas fa-envelope fa-sm"></i></span>
            <span *ngIf="!user.sendEmail && user.providerUser.emailAddress" title="Click to also send an email"><i class="far fa-envelope-open fa-sm"></i></span>
            <span *ngIf="!user.sendEmail  && !user.providerUser.emailAddress" title="An email address does not exist for this user"><i class="fas fa-ban fa-sm"></i></span>
          </button>{{user.providerUser.entityLabel}}
        </li>
      </ul>
    </div>
  </div>

  <!--note form -->
  <form [formGroup]="form" novalidate class="form-wrapper" [ngClass]="{'form-wrapper--edit': operation !== myConstants.operationTypeDetails }">
    <div *ngIf="noteConfigFields.isProjectNote && operation === myConstants.operationTypeCreate && projectContext === contextProject">
      <fieldset>
        <legend>Project Context</legend>
        <div class="note__options">
          <fw-radio-button [groupName]="'noteContext'"
                           [buttons]="contextButtons"
                           [isDisabled]="false"
                           (change)="selectContext($event.srcElement.value)">
          </fw-radio-button>
        </div>

        <div *ngIf="(forWo$ | async)" class="note__select">
          <fw-dynamic-field [field]="mds.getFieldDefinition('workOrder')"
                            [form]="form"
                            [selectItems]="workOrders"
                            [operation]="!(forWo$ | async) || !workOrders?.length ? myConstants.operationTypeDetails : operation">
          </fw-dynamic-field>
        </div>
        <div *ngIf="(forPo$ | async)" class="note__select">
          <fw-dynamic-field [field]="mds.getFieldDefinition('purchaseOrder')"
                            [form]="form"
                            [selectItems]="purchaseOrders"
                            [operation]="!(forPo$ | async) || !purchaseOrders?.length ? myConstants.operationTypeDetails : operation">
          </fw-dynamic-field>
        </div>
        <!--just a ui field-->
        <div class="check-box-field note__options--option" *ngIf="forPo$ | async">
          <fw-check-box #checkBox [config]="cbConfig"
                        [isDisabled]="!noteConfigFields.hasExternalInterface"
                        (change)="onSendExternal(checkBox.value)">
          </fw-check-box>
        </div>
      </fieldset>
    </div>
    <fw-dynamic-field [field]="mds.getFieldDefinition('noteSubject')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <span  class="textarea--charsleft">{{charactersLeft$ | async }} characters left</span>
    <fw-dynamic-field [field]="noteDef$ | async"
                      [form]="form"
                      [asciiOnly]="sendExternal"
                      [operation]="operation">
    </fw-dynamic-field>
    <fieldset>
      <legend>Options</legend>
      <div class="note__options">
        <fw-dynamic-field class="note__options--option"
                          [field]="mds.getFieldDefinition('isPrivate')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field *ngIf="noteConfigFields.isProjectNote" class="note__options--option"
                          [field]="mds.getFieldDefinition('postIt')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <!--Apply to ALL projects-->
        <fw-dynamic-field *ngIf="!noteConfigFields.isProjectNote" class="note__options--option"
                          [field]="mds.getFieldDefinition('applyToProject')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
      </div>
    </fieldset>
    <div *ngIf="sendToList?.length" class="note-edit__recipients--list">
      <div class="note-edit__recipients--subtitle">
        On save, your note will be visible to the selected recipients. <br />Emails will also be sent to the recipients you selected to receive an email.
      </div>
    </div>

    <fw-crud-buttons [operation]="operation"
                     [disabled]="!form.dirty || !form.valid"
                     [showNext]="false"
                     (cancelEvent)="onCancel($event)"
                     (createEvent)="onCreate($event)"
                     (saveEvent)="onUpdate($event)">
    </fw-crud-buttons>
    <fw-error-box [errorData]="errorData"></fw-error-box>
  </form>
</div>
