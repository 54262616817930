import { IAddress } from './i-address';

export interface IContactMechanismAddress {
  contactMechanismAddressId: number;
  contactId: number;
  contactMechanism_contactMechanismId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName: string;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName: string;
  isPrimary: boolean;
  active: boolean;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;
  rowVersion: string;
  aggregateRootVersion: string;
  //extra data new  --- review
  addressType: string;
  addressTypeId: number;
  //address: string; //for displaying values of line1-3, city, state, zip on one line
  addressId: number;
  line1: string;
  line2: string;
  line3: string;
  city: string;
  addressState: string;
  addressStateId: number;
  zipcode4: string;
  zipcode5: string;
  longitude: number;
  latitude: number;
  sanitizeOverride: boolean;
  yearBuilt: number;
  uiLabel: string;
  formGroupName: string;
  uiRequired: boolean;

  //for update
  address: IAddress;
}

export class ContactMechanismAddress implements IContactMechanismAddress {
  contactMechanismAddressId: number;
  contactId: number;
  contactMechanism_contactMechanismId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName: string;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName: string;
  isPrimary: boolean;
  active: boolean;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;
  rowVersion: string;
  aggregateRootVersion: string;
  addressType: string;
  addressTypeId: number;
  address: IAddress;
  addressId: number;
  addressState: string;
  addressStateId: number;
  line1: string;
  line2: string;
  line3: string;
  city: string;
  zipcode4: string;
  zipcode5: string;
  longitude: number;
  latitude: number;
  sanitizeOverride: boolean;
  yearBuilt: number;
  uiLabel: string;
  formGroupName: string;
  uiRequired: boolean;

  //ContactMechanism.ContactMechanismCategoryType.ContactMechanismCategoryTypeId
  constructor(contactMechanismCategoryName: string,
    contactMechanismCategoryTypeName: string,
    contactMechanismCategoryTypeId: number,
    uiLabel: string,
    formGroupName: string,
    uiRequired: boolean = false,
    isPrimary: boolean = false
    ) {
      this.contactMechanismAddressId = 0;
      this.contactId = 0;
      this.contactMechanism_contactMechanismId = 0;
      this.contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId = contactMechanismCategoryTypeId;
      this.contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName = contactMechanismCategoryTypeName;
      this.contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName = contactMechanismCategoryName;

      this.isPrimary = isPrimary;
      this.active = true;
      this.createDate = ''; 
      this.updateDate = '';
      this.createdBy = 0;
      this.updatedBy = 0;
      this.rowVersion = '';
      this.aggregateRootVersion = '';
      this.addressType = contactMechanismCategoryTypeName;
      this.addressTypeId = contactMechanismCategoryTypeId;
      this.address = { addressId: 0, line1: '', line2: '', line3: '', city: '', state_stateId: null, stateAbbr: '', zipcode4: null, zipcode5: null, sanitizeOverride: false, yearBuilt: null, latitude: null, longitude: null };
    //extra data to flatten
      this.addressId = 0;
      this.line1 = '';
      this.line2 = null;
      this.line3 = null;
      this.city = '';
      this.addressState = '';
      this.addressStateId = 0;
      this.zipcode4 = null;
      this.zipcode5 = null;
      this.longitude = 0;
      this.latitude = 0;
      this.sanitizeOverride = false;
      this.yearBuilt = null;
      this.uiLabel = uiLabel;
      this.formGroupName = formGroupName;
      this.uiRequired = uiRequired;
  }
}
