import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomErrorEvent } from 'hom-lib/hom-error-logger';

import { isAnonymous, getInitReload, getInitRedirect, getUserPreferences } from '../../auth/store/auth.selectors';
import * as fromRoot from '../../app/store/reducers/index';
import { IUserPreference } from '../../app/portals/view-models';
import { AppAction } from '../../shared/enums/app-action.enums';
import { ModalSizeType } from '../fw-modal/interfaces/i-modal';
import { UserPriviledgesService } from '../../auth/services';
import { SetApplicationVersion } from '../../auth/store';
import { ModalService } from '../fw-modal/services/fw-modal.service';

@Component({
  selector: 'fw-content',
  templateUrl: './fw-content.component.html'
})
export class FwContentComponent implements OnInit, OnDestroy {

  public userPreferences$: Observable<IUserPreference>;
  public isAnonymous$: Observable<boolean>;
  public doReload$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public loggedIn: boolean;
  subscription: Subscription = new Subscription();

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public router: Router,
    public ups: UserPriviledgesService,
    public emitterService: HomEventEmitterService,
    public modalService: ModalService  ) { }

  ngOnInit(): void {
    this.doReload$ = this.rootStore.pipe(select(getInitReload));
    this.isAnonymous$ = this.rootStore.pipe(select(isAnonymous));

    this.userPreferences$ = this.rootStore.pipe(select(getUserPreferences),
      filter(userPreferences => userPreferences !== null));
    this.getInitRedirect();
    this.getInitReload();

    //Listen for response from hom-error-logger
    this.subscription.add(this.emitterService.errorEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e) {
          switch (e.event) {
            case HomErrorEvent.VersionMismatchEvent:
              this.modalService.open({
                title: 'Version Mismatch!',
                path: 'app-action/' + AppAction.versionMismatch,
                sizeType: ModalSizeType.xsmall,
                castExit: true,
                hasTabs: false,
                isSubModal: this.modalService.opened,
                onExit: this.refreshApp
              });
              break;
            default:
              break;
          }
        }
      }));
  }

  refreshApp(): void {
    window.location.reload();
  }

  getInitRedirect(): void {
    this.subscription.add(this.rootStore.pipe(select(getInitRedirect)).subscribe((initRedirect: boolean) => {
      if (initRedirect) window.location.href = window.location.origin + "/maintenance.html";
    }));
  }

  getInitReload(): void {
    this.subscription.add(this.rootStore.pipe(select(getInitReload)).subscribe((initReload: boolean) => {
      if (initReload) {
        this.router.navigate(['hom']).then((success) => {
          if (success) {
            if (!this.loggedIn) {
              this.loggedIn = true;
            } else {
              alert('PLEASE CLICK OK BUTTON TO RELOAD PAGE');
            }
            window.location.reload();
          }
        })
      }
    }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
