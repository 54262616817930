import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { take, filter, map, tap} from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IWorkOrder, IProject, IProjectCreate, IWarrantySubType } from '../../../view-models/index';
import { IDetailRouteParameter, ListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { ISelectList, IResponseBase } from '../../../../../shared/interfaces/index';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ProjectService } from '../../services/project.service';
import { IErrorData } from '../../../../../shared/interfaces/index';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as fromDynamicObject from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as DynamicObjectActions from '../../../../../fw/dynamic-list/store/actions/dynamic-object.actions';
import { metaDataExists } from '../../../../../fw/dynamic-list/store/selectors/meta-data.selectors';
import { listDataExists } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import {  CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IDynamicObject } from '../../../../../fw/dynamic-list/store/index';
import { ProjectObjectStore, ProjectListStore } from '../../enums/project.enums';
import { DomainObjectService } from '../../../../../shared/services';

@Component({
  selector: 'project-create-container',
  templateUrl: './project-create-container.component.html'
})
export class ProjectCreateContainerComponent implements OnInit, OnDestroy  {
  public displayFields: string[] = ['warrantyTypeName', 'warrantySubTypeName', 'warrantyIsUrgent']; //projectInformation store, projects entity seeding
  public project$: Observable<IProject>;
  public workOrder$: Observable<IWorkOrder>;
  public loading$: Observable<boolean>;
  public warrantySubTypes$: BehaviorSubject<IWarrantySubType[]> = new BehaviorSubject(null);
  public noteMetaExists$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  projectId: number = -1;
  workOrderId: number = -1;
  purchaseOrderId: number = -1;
  isWarranty: boolean = false;
  storeName: string = '';
  key: string = '';
  operation: string;
  requestTime: string = '';

  title: string = '';
  canIEdit: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromFeature.IAllDynamicData>,
    public projectService: ProjectService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public dos: DomainObjectService,
    public activeRoute: ActivatedRoute) {}

  public onCancel(): void {
    //return to the calling list
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.workOrderWarranties,
      key: 'workOrder_workOrderId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.projectId
    }

    //ByWarrantyWorkOrder
    this.modalService.open({ title: 'Work Order Warranty Projects', path: 'warranty-scripts', id: this.workOrderId, onExit: null, castExit: false, optionalParams: params, hasTabs: false });
  }

  public onCreate(e: IHomEventEmitter) {
    let data: IProjectCreate = e.data;

    const createData = new CreateObjectModel(ProjectObjectStore.projectInformation, -1, 'Project', 'Create', data, null, e);
    this.store.dispatch(new DynamicObjectActions.CreateObjectObj({ createData }));
  }

  ngOnInit(): void {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.storeName = paramMap.get('storeName');
      this.key = paramMap.get('key');
      this.projectId = +paramMap.get('portalEntityId');
      this.workOrderId = paramMap.has('workOrderId') ? +paramMap.get('workOrderId') : -1;
      this.purchaseOrderId = paramMap.has('purchaseOrderId') ? +paramMap.get('purchaseOrderId') : -1;
      this.isWarranty = paramMap.has('isWarranty') ? !!paramMap.get('isWarranty') : false;
      this.operation = this.myConstants.operationTypeCreate;
      this.requestTime = paramMap.get('requestTime');
      this.newRequest();
    }));

    }

  newRequest(): void {
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(filter(x => x.requestor === ProjectObjectStore.projectInformation), map(x => x.show));

    this.operation = this.myConstants.operationTypeCreate;
    this.title = this.isWarranty ? 'New Warranty Project' : 'New Project';

    this.getStoreData();

    this.warrantySubTypes$.next([]); //will be loaded once location is selected
  }

  //data will already exist in store
 //Event and Error handling is performed in wrapper component 
  getStoreData() {
    this.project$ = this.store.pipe(
      select(fromDynamicObject.getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)));

    this.workOrder$ = this.store.pipe(select(fromStore.getSelectedRecord(ProjectListStore.projectWorkOrders, this.projectId, 'workOrderId', this.workOrderId)));

    //ensure we have seed data for project notes
    const noteListDef = this.projectService.loadProjectNoteListDefinition(this.projectId);
    this.subscription.add(this.store.select(listDataExists(ProjectListStore.projectNotes, this.projectId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe((exists) => {
        this.store.dispatch(new DynamicListActions.SetListDefinition({ storeName: noteListDef.storeName, parentId: noteListDef.parentId, listDefinition: noteListDef }));
        this.store.dispatch(new DynamicListActions.GetList({ listDefinition: noteListDef, listFilter: noteListDef.defaultListFilter, parentId: noteListDef.parentId }));
      }));

    this.noteMetaExists$.next(false);
    this.subscription.add(this.store.select(metaDataExists(noteListDef.storeName))
      .pipe( filter((exists: boolean) => exists === true), take(1))
      .subscribe((exists) => {
        this.noteMetaExists$.next(true);
      }));

    this.subscription.add(this.store.pipe(select(fromStore.getObjectDataByType(ProjectObjectStore.projectInformation)))
      .pipe(map((objState: IDynamicObject) => objState.objData.find(x => x.objectId == -1)))
      .subscribe((data) => {
        if (data) {
          if (data.event && (!data.errorData || !data.errorData.length)) {
            this.store.dispatch(new fromStore.ClearEventObj({ storeName: ProjectObjectStore.projectInformation, objectId: -1 }));
            this.createComplete();
          }
          if (data.errorData) {
            this.errorData$.next(data.errorData);
          }
        }
      }));
  }

  createComplete(): void {
    //Refresh list of warranty project for this wo
    const warrantyListDef = this.projectService.loadWorkOrderWarrantyListDefinition(this.workOrderId);
    this.store.dispatch(new DynamicListActions.GetList({
      listDefinition: warrantyListDef,
      listFilter: warrantyListDef.defaultListFilter, parentId: warrantyListDef.parentId
    }));

    this.projectService.dispatchWorkOrders('warranty-create', this.projectId, this.canIEdit);

    this.onCancel();
  }

  onWarrantyTypeChange(id: number): void {
    //get the sub types for this type
    this.warrantySubTypes$.next([]);
    if (id && id > 0) {
      if (!this.projectService.warrantySubTypes.find(x => x.id === id)) {
        let listFilter = new ListFilter();
        listFilter.getAll = true;
        this.dos.getByMethodById('WarrantySubType', 'ByWarrantyType', id, listFilter).subscribe((response: IResponseBase) => {
          if (response.success) {
            this.projectService.warrantySubTypes.push({ id: id, data: response.data });
            this.warrantySubTypes$.next(response.data);
          } else {
            this.errorData$.next(response.errorData);
          }
        })
      } else {
        this.warrantySubTypes$.next(this.projectService.warrantySubTypes.find(x => x.id === id).data);
      }
    }

  }

  public ngOnDestroy(): void {
    this.store.dispatch(new fromStore.ClearEventObj({ storeName: ProjectObjectStore.projectInformation, objectId: -1 }));
    this.store.dispatch(new fromStore.ClearErrorsObj({ storeName: ProjectObjectStore.projectInformation, objectId: -1 }));
    this.subscription.unsubscribe();
  }
}
