  <fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
    <form *ngIf="multiSelectOptions && availableProviderLocations" [formGroup]="form" novalidate class="form pls-entity-services">
      <div class="form-group pls-entity-services__form">
        <div class="pls-entity-services__form--pl">
          <label for="providerLocations">Location</label>
          <select id="providerLocations" name="providerLocations"
                  [formControl]="form.controls['providerLocations']"
                  [ngClass]="{'has-error': operation !== myConstants.operationTypeDetails && !form.controls['providerLocations'].valid }"
                  (change)="onLocationChange($event)">
            <option [selected]="operation === myConstants.operationTypeCreate" [value]="0">{{myConstants.choose}}</option>
            <option *ngFor="let item of availableProviderLocations" [value]="item.providerLocationId">{{item.locationName}}</option>
            <!--item will be the full select item-->
          </select>
        </div>
        <div class="pls-entity-services__form--pls">
          <label for="serviceMultiSelect" [htmlFor]="serviceMultiSelect">Services</label>
          <div *ngIf="multiSelectOptions && multiSelectSettings">
            <fw-multiselect-dropdown #serviceMultiSelect
                                     class="pls-entity-services__dropdown"
                                     id="serviceMultiSelect"
                                     name="serviceMultiSelect"
                                     [formControl]="form.controls['serviceMultiSelect']"
                                     [options]="multiSelectOptions"
                                     [texts]="multiSelectLabels"
                                     [settings]="multiSelectSettings"
                                     [isDisabled]="(operation === myConstants.operationTypeDetails || selectedLocationId <= 0)"
                                     (onAdded)="addServiceEvent($event)"
                                     (onRemoved)="removeServiceEvent($event)"
                                     required>
            </fw-multiselect-dropdown>
          </div>
        </div>
      </div>

      <div *ngIf="selectedPLServices">
        <div class="pls-entity-services__selected">
          <div class="pls-entity-services__selected--header">Selected Services</div>
          <ul class="pls-entity-services__selected--items">
            <li *ngFor="let item of selectedPLServices" class="pls-entity-services__selected--item">{{item.serviceName}}</li>
          </ul>
        </div>
      </div>
      <fw-error-box *ngIf="invalid || errorMessage" [errors]="errorMessage"></fw-error-box>
    </form>
    <fw-crud-buttons [operation]="operation" [disabled]="formDisabled()" [showNext]="showNext" (cancelEvent)="onCancel($event)" (createEvent)="onCreate($event)" (saveEvent)="onSave($event)"></fw-crud-buttons>
  </fw-dynamic-detail-container>
