import { Component, Input, OnInit, OnDestroy, Inject, OnChanges, SimpleChanges, EventEmitter, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { NoteEvent, NoteAction } from '../../enums/note.enums';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { INote, IPoImportNote } from '../../../view-models/index';
import { NoteService } from '../../services';
import { IInputButton } from '../../../../../fw/fw-shared/interfaces/i-input-button';

@Component({
  selector: 'note-list-item',
  templateUrl: './note-list-item.component.html'
})
export class NoteListItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() note: INote | IPoImportNote;
  @Input() isProjectNote: boolean;
  @Input() isPoImportNote: boolean = false;

  @Output() public postItEvent = new EventEmitter<IHomEventEmitter>();
  @Output() public replyEvent = new EventEmitter<IHomEventEmitter>();

  public actionFromReply: string;
  public createUser: string = '';
  public createDate: string = '';
  public noteText: string = '';
  public origination: string = '';
  public externalUser: string = '';
  public externalDateCreated: string = '';
  public externalDateClosed: string = '';
  public key: string;
  public cbConfig: IInputButton;

  subscription: Subscription = new Subscription();

  constructor(
    public  noteService: NoteService,
    @Inject(appConstants) public  myConstants: IAppConstants) {
    this.actionFromReply = NoteAction.fromReply;
    }

  public changePostIt(): void {
    if (this.isPoImportNote) {
      return;
    }
    const note: INote = cloneDeep(this.note) as INote;
    if (!note) return;
    const emitter: IHomEventEmitter = { requestor: 'note-list-item', event: NoteEvent.postIt, action: NoteAction.fromList, data: note };
    this.postItEvent.emit(emitter);
  }

  public onSendReply(e: IHomEventEmitter): void {
    const emitter: IHomEventEmitter = { requestor: 'note-list-item', event: NoteEvent.sendReply, action: e.action, data: e.data };
    this.replyEvent.emit(emitter);
  }

  ngOnInit() {
    this.setFields();
    this.cbConfig = {
      label: {
        label: 'Project Special Instructions',
        alignRight: true,
        title: 'Click to Change Project Special Instructions'
      }
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['note'] && !(changes['note'].firstChange)) {
      this.setFields();
    }
  }

  public getContext() {
    if (this.isPoImportNote) {
      return '';
    }
    let returnString = '';
    const note: INote = cloneDeep(this.note) as INote; //this.notes.find(x => x.noteId == noteId);
    if (!note || !note.hasOwnProperty('noteLabels')) return '';

    for (var i in note.noteLabels) {
      if (note.noteLabels.hasOwnProperty(i)) {
        returnString += returnString.length !== 0 && note.noteLabels[i] ? ', ' : '';
        returnString += note.noteLabels[i] ? note.noteLabels[i] : '';
      }
    }
    if (returnString.endsWith(',')) {
      returnString = returnString.slice(0, -1);
    }
    return returnString;
  }

  setFields(): void {
    this.key = this.isPoImportNote ? 'poImportNoteId' : 'noteId';

    if (this.isPoImportNote) {
      const poNote = this.note as IPoImportNote;
      this.createUser = poNote.createUser;
      this.createDate = poNote.dateCreated;
      this.noteText = poNote.noteText;
      this.origination = poNote.origination;
      this.externalUser = poNote.createUser;
      this.externalDateCreated = poNote.dateCreated;
      this.externalDateClosed = poNote.dateClosed;
    } else {
      const note = this.note as INote;
      this.createUser = note.createUserName;
      this.createDate = note.createDate;
      this.noteText = note.noteText;
      this.origination = note.externalData.origination;
      this.externalUser = note.externalData.createUser;
      this.externalDateCreated = note.externalData.dateCreated;
      this.externalDateClosed = note.externalData.dateClosed;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
