import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { BehaviorSubject, Subscription } from 'rxjs';
import { RelatedParentType, AdminStore } from '../enums/admin.enums';
import * as fromRoot from '../../../store/reducers/index';
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import { getEntityListByParentId, IListObjectData } from '../../../../fw/dynamic-list/store/index';
import { IResponseBase, IErrorData } from '../../../../shared/interfaces';
import { getSelectionListDataByType } from '../../../../shared/store/selectionLists';
import { IListDefinition, ListFilter } from '../../../../fw/dynamic-list/interfaces';
import { UserPriviledgesService } from '../../../../auth/services';
import { AdminSecurityService } from './admin-security.service';
import { AdminProviderService } from './admin-provider.service';
import { DomainObjectService } from '../../../../shared/services';

@Injectable()
export class RelatedEntityService implements OnDestroy {
  parentId: number;
  parentType: RelatedParentType;
  generalContractorId: number = 1;

  subscription: Subscription = new Subscription();
  //types of: IWidgetProviderRole[] | IPrivilegeTemplateProviderRole[] | IUserRoleViewModel[] | IBranchProgram[]
  currentEntities$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  //types of: IWidget[] | IPrivilegeTemplate[] | IProviderUser[] | IProgramService | IBranch
  allEntities$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  errors$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public rootStore: Store<fromRoot.IState>,
    public userPriviledgesService: UserPriviledgesService,
    public securityService: AdminSecurityService,
    public providerService: AdminProviderService,
    public dos: DomainObjectService
 ) { }

  setParentListDef(): IListDefinition {
    switch (this.parentType) {
      case RelatedParentType.providerRole:
        return this.securityService.loadProvideRolesListDefinition();
      case RelatedParentType.privilegeTemplate:
        return this.securityService.loadPrivilegeTemplatesListDefinition();
      case RelatedParentType.branch:
        return this.providerService.loadBranchesListDefinition();
      case RelatedParentType.programService:
        return this.providerService.loadProgramServicesListDefinition();
      case RelatedParentType.providerLocation:
        return this.providerService.loadProviderLocationsListDefinition();
      case RelatedParentType.region:
        return this.providerService.loadRegionsListDefinition();
      case RelatedParentType.service:
        return this.providerService.loadServiceGroupsListDefinition();
      default:
        return null;
    }
  }

  setParentType(storeName: string): void {
    switch (storeName) {
      case AdminStore.rolePrivilegeTemplates:
      case AdminStore.roleUsers:
      case AdminStore.roleWidgets:
        this.parentType = RelatedParentType.providerRole;
        break;
      case AdminStore.privilegeTemplateRoles:
        this.parentType = RelatedParentType.privilegeTemplate;
        break;
      case AdminStore.branchPrograms:
      case AdminStore.branchRegions:
      case AdminStore.branchProviderLocation:
        this.parentType = RelatedParentType.branch;
        break;
      case AdminStore.psBranchPrograms:
      case AdminStore.psFiles:
        this.parentType = RelatedParentType.programService;
        break;
      case AdminStore.regionBranches:
        this.parentType = RelatedParentType.region;
        break;
      case AdminStore.serviceGroupServices:
        this.parentType = RelatedParentType.service;
        break;
      case AdminStore.locationBranches:
        this.parentType = RelatedParentType.providerLocation;
        break;
     default:
        break;
    }
  }

  //generic method for getAll, as applicable
  getWorkingLists(storeName: string): void {
    const currentListDef: IListDefinition = this.getParentListDef(storeName);
    const controllerName: string = this.getSelectListControllerName(storeName);
    const methodName: string = this.getSelectListMethodName(storeName);
    const parentId: number = this.getSelectListParentId(storeName);
    const defaultCurrentEntitiesEmpty = this.defaultCurrentEntitiesEmpty(storeName);
    let listFilter = new ListFilter();
    listFilter.getAll = true;
    //TODO:  set/log dev error if parent any of the above are not set, it is a setup issue

    this.subscription.add(this.store.pipe(
      select(getEntityListByParentId(currentListDef.storeName, this.parentId)))
      .subscribe((objData: IListObjectData) => {
        if (objData && objData.data) {
          this.currentEntities$.next(objData.data);
        } else if (defaultCurrentEntitiesEmpty) {
          this.currentEntities$.next([]);
        }
      })
    );
    
    //Adding existing program service to this branch - pull all.
    this.subscription.add(this.dos.getByMethodById(controllerName, methodName, parentId, listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.allEntities$.next(response.data);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  getAllWidgets(): void {
    const currentListDef = this.securityService.loadWidgetsForProviderRoleListDefinition(this.parentId);
    let listFilter = new ListFilter();
    listFilter.getAll = true;

    this.subscription.add(this.store.pipe(
      select(getEntityListByParentId(currentListDef.storeName, this.parentId)))
      .subscribe((objData: IListObjectData) => {
        if (objData && objData.data) {
          this.currentEntities$.next(objData.data);
        }
      })
    );

    //must retrieve each time
    this.subscription.add(this.dos.getListByMethod('Widget', 'Index', '', listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.allEntities$.next(response.data);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  getAllPrivilegeTemplates(): void {
    const currentListDef = this.securityService.loadPrivilegeTemplatesForProviderRoleListDefinition(this.parentId);
    let listFilter = new ListFilter();
    listFilter.getAll = true;

    this.subscription.add(this.store.pipe(
      select(getEntityListByParentId(currentListDef.storeName, this.parentId)))
      .subscribe((objData: IListObjectData) => {
        if (objData && objData.data) {
          this.currentEntities$.next(objData.data);
        }
      })
    );

    //must retrieve each time
    this.subscription.add(this.dos.getListByProvider('PrivilegeTemplate', this.userPriviledgesService.providerId$.value, listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.allEntities$.next(response.data);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  getAllProviderUsersTemplates(): void {
    const currentListDef = this.securityService.loadUsersForProviderRoleListDefinition(this.parentId);
    this.subscription.add(this.store.pipe(
      select(getEntityListByParentId(currentListDef.storeName, this.parentId)))
      .subscribe((objData: IListObjectData) => {
        if (objData && objData.data) {
          this.currentEntities$.next(objData.data);
        }
      })
    );

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('providerUser')))
      .subscribe((data) => {
        if (data) {
          this.allEntities$.next(data);
        }
      }));
  }

  getAllProviderRoles(): void {
    const currentListDef = this.securityService.loadProviderRolesForPrivilegeTemplateListDefinition(this.parentId);
    let listFilter = new ListFilter();
    listFilter.getAll = true;

    this.subscription.add(this.store.pipe(
      select(getEntityListByParentId(currentListDef.storeName, this.parentId)))
      .subscribe((objData: IListObjectData) => {
        if (objData && objData.data) {
          this.currentEntities$.next(objData.data);
        }
      })
    );

    //must retrieve each time
    this.subscription.add(this.dos.getListByProvider('ProviderRole', this.userPriviledgesService.providerId$.value, listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.allEntities$.next(response.data);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  getParentListDef(storeName: string): IListDefinition {
    switch (storeName) {
      case AdminStore.branchPrograms:
      case AdminStore.psBranchPrograms:
        return this.providerService.loadBranchProgramsListDefinition(
          this.parentType === RelatedParentType.branch ? this.parentId : 0,
          this.parentType === RelatedParentType.programService ? this.parentId : 0);
      case AdminStore.branchProviderLocation:
      case AdminStore.locationBranches:
        return this.providerService.loadProviderLocationBranchesListDefinition(
          this.parentType === RelatedParentType.branch ? this.parentId : 0,
          this.parentType === RelatedParentType.providerLocation ? this.parentId : 0  );
      case AdminStore.branchRegions:
      case AdminStore.regionBranches:
        return this.providerService.loadBranchRegionsListDefinition(
          this.parentType === RelatedParentType.region ? this.parentId : 0,
          this.parentType === RelatedParentType.branch ? this.parentId : 0);
      case AdminStore.serviceGroupServices:
        return this.providerService.loadServiceGroupServicesListDefinition(this.parentId);
      case AdminStore.psFiles:
        return this.providerService.loadProgramServiceFilesListDefinition(this.parentId);
      default:
        return null;
    }
  }

  //Set the controller name for the selection list entities you want to associate
  getSelectListControllerName(storeName: string): string {
    switch (storeName) {
      case AdminStore.branchPrograms:
        return 'ProgramService';
      case AdminStore.psBranchPrograms:
      case AdminStore.regionBranches:
        return 'Branch';
      case AdminStore.branchProviderLocation:
        return 'ProviderLocation';
      case AdminStore.locationBranches:
        return 'Branch';
      case AdminStore.branchRegions:
        return 'Region';
      case AdminStore.serviceGroupServices:
        return 'Service';
      case AdminStore.psFiles:
        return 'DocumentType';
      default:
        return null;
    }
  }

  //Set the controller name for the selection list entities you want to associate
  getSelectListMethodName(storeName: string): string {
    switch (storeName) {
      case AdminStore.branchPrograms:
      case AdminStore.psBranchPrograms:
      case AdminStore.regionBranches:
      case AdminStore.branchRegions:
        return 'ByGeneralContractor';
      case AdminStore.branchProviderLocation:
        return 'ByProvider';
      default:
        return 'ByProvider';
    }
  }

  getSelectListParentId(storeName: string): number {
    switch (storeName) {
      case AdminStore.branchPrograms:
        return this.generalContractorId;  
      case AdminStore.psBranchPrograms:
      case AdminStore.regionBranches:
        return this.generalContractorId;  
      case AdminStore.branchProviderLocation:
      case AdminStore.locationBranches:
        return this.userPriviledgesService.providerId$.value
      case AdminStore.branchRegions:
        return this.generalContractorId; 
      default:
        return this.userPriviledgesService.providerId$.value;
    }
  }

  defaultCurrentEntitiesEmpty(storeName: string): boolean {
    switch (storeName) {
      case AdminStore.serviceGroupServices:
        return true;
      default:
        return false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
