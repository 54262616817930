import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';

import { IPortalTab } from '../../../view-models';
import { UserJobsTabs } from '../tab-settings/user-jobs.tabs';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'user-jobs',
  templateUrl: './user-jobs.component.html'
})
export class UserJobsComponent implements OnInit, OnDestroy{
  public authorizedTabs: IPortalTab[] = null;
  subscription: Subscription = new Subscription();

  constructor(
    public userPriviledgesService: UserPriviledgesService) { }

  ngOnInit() {
    let allTabs: IPortalTab[] = UserJobsTabs;
    const canCreateJobSchedules = this.userPriviledgesService.canCreateJobSchedules$.value;
    let tabIndex = allTabs.findIndex(t => t.portalTabId === 2);
    if (!canCreateJobSchedules && tabIndex > -1) {
      allTabs.splice(2, 1);
    }
    this.authorizedTabs = allTabs;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
