import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FwModule } from '../../../fw/fw.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../shared/shared.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { PricingSharedModule } from '../pricing-shared/pricing-shared.module';
import { ContactModule } from '../../contact/contact.module';
import { AdminRoutingModule } from './admin-routing.module';

import {
  AdminTitleBarComponent,
  UserAccessItemComponent,
  UserSecurityComponent,
  UserRolesComponent,
  UserGeneralContractorsComponent,
  UserLocationsComponent,
  UserServicesComponent,
  UserAccessCreateSubItemComponent,
  UserAccessCreateItemComponent,
  UserAccessCreateItemsComponent,  SkusComponent,
  SkuPayoutsComponent,
  CommunicationShortcutComponent,
  CommunicationDocumentTypeComponent,
  RoleComponent,
  RelatedEntityCreateComponent,
  PrivilegeTemplateComponent,
  PrivilegeTemplatePrivilegeComponent,
  PrivilegeTemplatePrivilegeRuleValueComponent,
  PrivilegeFilterComponent,
  ScheduleExclusionsCreateComponent,
  ZoneComponent,
  CustomerFlagComponent,
  LookupTypeComponent,
  LookupValueComponent,
  WorkCategoryComponent,
  InstallerScoringItemsComponent,
  InstallerScoringEditComponent,
  ChargeBackTypeComponent,
  ContactMechanismCategoryComponent,
  ContactMechanismCategoryTypeComponent,
  WorkCategoryPayoutComponent,
  ContactTypeComponent,
  ContactTypeSpecificationComponent,
  ServiceGroupComponent,
  ServiceGroupServiceComponent,
  GeneralContractorComponent,
  BranchComponent,
  ProgramComponent,
  ProgramServiceComponent,
  RegionComponent,
  ServiceComponent,
  DocumentTypeComponent,
  SurveyComponent,
  SurveyQuestionDataTypeComponent,
  SurveyQuestionComponent,
  SurveyProjectMapFieldComponent,  
  SurveyProgramServiceComponent,
  WarrantyTypeComponent,
  WarrantySubTypeComponent,
  ShipperComponent,
  ShipperLocationComponent,
  ShipperLocationPayoutComponent,
  DeliveryScheduleComponent,
  ShipperPayoutComponent,
  ProviderLocationComponent,
  ProviderLocationServiceComponent,
  ProviderLocationScheduleComponent,
  ChargeBackSkuComponent,
  ChargeBackSkuPriceComponent,
  CommunicationEventTypeComponent,
  CommunicationEventFeedbackComponent,
  SSSGeneralContractorComponent,
  SSSProgramComponent,
  SSSProgramServiceComponent,
  SSSProgramServiceBranchComponent,
  ProviderPayoutComponent
} from './components/index';  

import {
  AdminComponent,
  UsersSecurityComponent,
  PrivilegeFiltersComponent,
  PrivilegeTemplatesComponent,
  RolesComponent,
  UserAccessManagerComponent,
  GeneralContractorsComponent,
  GeneralContractorSkusComponent,
  ChargeBackSkusComponent,
  ProviderPayoutsComponent,
  ChargeBackTypesComponent,
  ContactTypesComponent,
  ContactMechanismCategoriesComponent,
  ContactMechanismCategoryTypesComponent,
  CustomerFlagsComponent,
  DocumentTypesComponent,
  InstallerScoringComponent,
  InventoryProviderManagerComponent,
  LookupTypesComponent,
  MeasureServicesComponent,
  ProviderLocationsComponent,
  ScheduleExclusionsComponent,
  SelfServiceSchedulingComponent,
  ServiceGroupsComponent,
  SurveysComponent,
  WarrantyTypesComponent,
  WorkCategoriesComponent,
  ZonesComponent,
  CommunicationShortcutsComponent,
  CommunicationDocumentTypesComponent,  RoleWidgetsComponent,
  RoleUsersComponent,
  RolePrivilegeTemplatesComponent,
  RelatedEntityManagerComponent,
  PrivilegeTemplateRolesComponent,
  PrivilegeTemplatePrivilegesComponent,
  PrivilegeTemplatePrivilegeValuesComponent,
  FiltersWhereUsedComponent,
  PrivilegesComponent,  PrivilegesWhereUsedComponent,
  LookupValuesComponent,
  WorkCategoryPayoutsComponent,
  ContactTypeSpecificationsComponent,
  ServiceGroupServicesComponent,
  BranchesComponent,
  ProgramsComponent,
  ProgramServicesComponent,
  RegionsComponent,
  ServicesComponent,
  BranchRegionsComponent,
  ProviderLocationBranchesComponent,
  BranchProgramsComponent,
  BranchNotesComponent,
  ProgramServiceFilesComponent,
  ProviderLocationServicesComponent,
  AdminContactComponent,
  ServiceGroupCreationWizardComponent,
  SurveyQuestionDataTypesComponent,  SurveyQuestionsComponent,
  SurveyProjectMapFieldsComponent,
  SurveyImportMappingsComponent,
  SurveyProgramServicesComponent,
  WarrantySubTypesComponent,
  ShipperLocationsComponent,
  ShipperPayoutsComponent,
  ShipperLocationPayoutsComponent,
  ProviderLocationSchedulesComponent,
  ChargeBackSkuPricesComponent,
  ChargeBackSkuPriceManagerComponent,
  CommunicationEventTypesComponent,
  CommunicationEventFeedbacksComponent,
  SSSGeneralContractorsComponent,
  SSSProgramsComponent,
  SSSProgramServicesComponent,
  SSSProgramServiceBranchesComponent,  
  PayoutPricesComponent,
  PayoutSkusComponent
  } from './containers/index';

//guards and services
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';
import { AdminService } from './services/admin.service';
import { AdminSecurityService } from './services/admin-security.service';
import { AllProviderUsersResolver, ContactTypeEntityResolver } from '../resolvers';
import { AdminPricingService } from './services/admin-pricing.service';
import { AdminProviderService } from './services/admin-provider.service';
import { RelatedEntityService } from './services/related-entity.service';
import { FileUploaderService } from '../../file-upload/services/file-uploader.service';
import { ProjectEntityResolver } from '../resolvers/project-entity-resolver.service';
import { ShipperLocationPayoutResolver } from './resolvers/shipper-location-payout-resolver.service';
import { MaterialPayoutResolver } from './resolvers/material-payout-resolver.service';
import { AdminSssService } from './services/admin-sss.service';

@NgModule({
  declarations: [
    AdminTitleBarComponent,
    UserAccessItemComponent,
    UserSecurityComponent,
    UserRolesComponent,
    UserGeneralContractorsComponent,
    UserLocationsComponent,
    UserServicesComponent,
    UserAccessCreateSubItemComponent,
    UserAccessCreateItemComponent,
    UserAccessCreateItemsComponent,
    AdminComponent,
    UsersSecurityComponent,
    PrivilegeFiltersComponent,
    PrivilegeTemplatesComponent,
    RolesComponent,
    UserAccessManagerComponent,
    SkusComponent,
    SkuPayoutsComponent,
    GeneralContractorsComponent,
    BranchesComponent,
    ProgramsComponent,
    ProgramServicesComponent,
    RegionsComponent,
    ServicesComponent,
    GeneralContractorSkusComponent,
    ChargeBackSkusComponent,
    ProviderPayoutsComponent,
    ChargeBackTypesComponent,
    ContactTypesComponent,
    ContactMechanismCategoriesComponent,
    ContactMechanismCategoryTypesComponent,
    CustomerFlagsComponent,
    DocumentTypesComponent,
    InstallerScoringComponent,
    InventoryProviderManagerComponent,
    LookupTypesComponent,
    MeasureServicesComponent,
    ProviderLocationsComponent,
    ScheduleExclusionsComponent,
    SelfServiceSchedulingComponent,
    ServiceGroupsComponent,
    SurveysComponent,
    WarrantyTypesComponent,
    WorkCategoriesComponent,
    ZonesComponent,
    CommunicationShortcutComponent,
    CommunicationShortcutsComponent,
    CommunicationDocumentTypeComponent,
    CommunicationDocumentTypesComponent,
    RoleComponent,
    RelatedEntityCreateComponent,
    RoleWidgetsComponent,
    RoleUsersComponent,
    RolePrivilegeTemplatesComponent,
    RelatedEntityManagerComponent,
    PrivilegeTemplateComponent,
    PrivilegeTemplateRolesComponent,
    PrivilegeTemplatePrivilegeComponent,
    PrivilegeTemplatePrivilegesComponent,
    PrivilegeTemplatePrivilegeRuleValueComponent,
    PrivilegeTemplatePrivilegeValuesComponent,
    PrivilegeFilterComponent,
    FiltersWhereUsedComponent,
    ScheduleExclusionsCreateComponent,
    PrivilegesComponent,
    PrivilegesWhereUsedComponent,
    ZoneComponent,
    CustomerFlagComponent,
    LookupValuesComponent,
    LookupTypeComponent,
    LookupValueComponent,
    WorkCategoryComponent,
    InstallerScoringItemsComponent,
    InstallerScoringEditComponent,
    ChargeBackTypeComponent,
    ContactMechanismCategoryComponent,
    ContactMechanismCategoryTypeComponent,
    WorkCategoryPayoutsComponent,
    WorkCategoryPayoutComponent,
    ContactTypeComponent,
    ContactTypeSpecificationComponent,
    ContactTypeSpecificationsComponent,
    ServiceGroupComponent,
    ServiceGroupServicesComponent,
    ServiceGroupServiceComponent,
    GeneralContractorComponent,
    AdminContactComponent,
    BranchRegionsComponent,
    ProviderLocationBranchesComponent,
    BranchProgramsComponent,
    BranchNotesComponent,
    ProgramServiceFilesComponent,
    ProviderLocationServicesComponent,
    ProviderLocationServiceComponent,
    BranchComponent,
    ProgramComponent,
    ProgramServiceComponent,
    RegionComponent,
    ServiceComponent,
    DocumentTypeComponent,
    ServiceGroupCreationWizardComponent,
    SurveyQuestionDataTypesComponent,
    SurveyComponent,
    SurveyQuestionDataTypeComponent,
    SurveyQuestionComponent,
    SurveyQuestionsComponent,
    SurveyProjectMapFieldsComponent,
    SurveyProjectMapFieldComponent,
    SurveyImportMappingsComponent,
    SurveyProgramServicesComponent,
    SurveyProgramServiceComponent,
    WarrantyTypesComponent,
    WarrantySubTypesComponent,
    WarrantyTypeComponent,
    WarrantySubTypeComponent,
    ShipperLocationsComponent,
    ShipperPayoutsComponent,
    ShipperLocationPayoutsComponent,
    ShipperLocationComponent,
    ShipperLocationPayoutComponent,
    DeliveryScheduleComponent,
    ShipperPayoutComponent,
    ShipperComponent,
    ProviderLocationComponent,
    ProviderLocationScheduleComponent,
    ProviderLocationSchedulesComponent,
    ChargeBackSkuComponent,
    ChargeBackSkuPricesComponent,
    ChargeBackSkuPriceComponent,
    ChargeBackSkuPriceManagerComponent,
    CommunicationEventTypesComponent,
    CommunicationEventTypeComponent,
    CommunicationEventFeedbacksComponent,
    CommunicationEventFeedbackComponent,
    SSSGeneralContractorsComponent,
    SSSProgramsComponent,
    SSSProgramServicesComponent,
    SSSProgramServiceBranchesComponent,
    SSSGeneralContractorComponent,
    SSSProgramComponent,
    SSSProgramServiceComponent,
    SSSProgramServiceBranchComponent,
    PayoutPricesComponent,
    ProviderPayoutComponent,
    PayoutSkusComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FwModule,
    FwSharedModule,
    SharedModule,
    PortalSharedModule,
    PricingSharedModule,
    ContactModule,
    AdminRoutingModule
  ],
  providers: [
    IsCurrentGuard,
    AdminService,
    AdminSecurityService,
    AdminPricingService,
    AdminProviderService,
    AdminSssService,
    AllProviderUsersResolver,
    ContactTypeEntityResolver,
    RelatedEntityService,
    FileUploaderService,
    ProjectEntityResolver,
    ShipperLocationPayoutResolver,
    MaterialPayoutResolver
  ]
})

export class AdminModule {
  static forRoot(): ModuleWithProviders<AdminModule> {
    return {
      ngModule: AdminModule,
      providers: [AdminSecurityService, AdminPricingService, AdminProviderService, AdminSssService]
    };
  }
}
