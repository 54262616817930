import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MultiSelectDropdown } from './fw-multi-select-dropdown.component';

import { MultiSelectSearchFilter } from './search-filter.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [MultiSelectDropdown, MultiSelectSearchFilter],
  declarations: [MultiSelectDropdown,  MultiSelectSearchFilter]
})
export class MultiSelectDropdownModule { }
