import { Component, Input } from '@angular/core';

@Component({
  selector: 'dynamic-list-data-row-info',
  templateUrl: './dynamic-list-data-row-info.component.html'
})

export class DynamicListDataRowInfoComponent  {
  @Input() row: any;

  constructor() {}
}
