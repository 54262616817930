import { Component, OnInit, OnDestroy,  ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { cloneDeep, upperFirst } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IEntityProperty, IApprovalQueueTransaction, IEntityPropertyUpper } from '../../../../../shared/interfaces/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IFieldDefinition } from '../../../../../fw/dynamic-forms';
import { IKey, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { ProjectListStore } from '../../../project/enums/project.enums';

import { FieldDefinitionService, HomCommonUtility } from '../../../../../shared/services';
import { MetaDataService, ManagerDashDynamicListService } from '../../../../../fw/dynamic-list/services';
import { ManagerDashboardService } from '../../../manager-dashboard/services/manager-dashboard.service';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  selector: 'aqt-change',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './aqt-change.component.html'
})
export class AQTChangeComponent implements OnInit, OnDestroy {
  public operation: string;
  public form$: BehaviorSubject<FormGroup> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public activeRecord: IApprovalQueueTransaction = null;
  public entityProperties: IEntityProperty[] = null;
  public canApprove: boolean = true;
  public displayFields: string[] = [];
  public data: any = {};
  public showGoToLink: boolean = true;
  parentRouteInId: number = -1;
  parentId: number = -1;
  portalEntityId: number = -1;

  storeName: string = '';
  key: string = '';
  subscription: Subscription = new Subscription();
  approvalQueueTransactionId: number;
  fieldDefinitions: IFieldDefinition[] = null;

  constructor(public activeRoute: ActivatedRoute,
    public dynamicDetailService: DynamicDetailService,
    public store: Store<fromStore.IAllDynamicData>,
    public fieldDefinitionService: FieldDefinitionService,
    public metaDataService: MetaDataService,
    public emitterService: HomEventEmitterService,
    public managerDashboardService: ManagerDashboardService,
    public homCommonUtility: HomCommonUtility,
    public managerDashDynamicListService: ManagerDashDynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants  ) { }

  public cancel() {
    this.store.dispatch(new DynamicListActions.ClearErrorsList({ storeName: this.detailConfig$.getValue().storeName, parentId: this.detailConfig$.getValue().parentId }));
    let emitter: IHomEventEmitter = { requestor: 'aqt-change', event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public approve(): void {
    let rec: IApprovalQueueTransaction = cloneDeep(this.activeRecord);
    const formData = this.form$.getValue().getRawValue();

    let returnProperties: IEntityProperty[] = cloneDeep(this.entityProperties);
    let propertiesOut: IEntityPropertyUpper[] = [];
    returnProperties.forEach(x => {
      propertiesOut.push({
        PropertyName: upperFirst(x.propertyName),
        PropertyLabel: x.propertyLabel,
        PropertyValue: x.propertyValue,
        UpdatedValue: formData[x.propertyName] || x.propertyValue,
        IsEditable: x.isEditable
      })
    });

    const stringifiedProperties = JSON.stringify(propertiesOut);
    this.submitUpdate(rec, 'Commit', stringifiedProperties);
  }

  public reject(): void {
    let rec: IApprovalQueueTransaction = cloneDeep(this.activeRecord);
    rec.denyReason = '';
    this.submitUpdate(rec, 'Deny', null);
  }

  ngOnInit() {
    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.parentRouteInId = +paramMap.get('id');
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.approvalQueueTransactionId = +paramMap.get('id');
      this.key = paramMap.get('key');
      this.operation = paramMap.get('operation');
      this.portalEntityId = +paramMap.get('portalEntityId');
      this.storeName = paramMap.get('storeName');
      this.parentId = this.storeName === ProjectListStore.workOrderAQTChanges ? this.parentRouteInId : -1;
      this.showGoToLink = this.storeName !== ProjectListStore.workOrderAQTChanges;
      this.setDetailConfig(paramMap);

      this.subscription.add(this.store.pipe(select(fromStore.getEntityListByParentId(this.storeName, this.parentId)))
        .pipe(take(1))
        .subscribe((state: fromDynamicList.IListObjectData) => {
          const storeData: IApprovalQueueTransaction = cloneDeep(state.data.find(x => x[this.key] == this.approvalQueueTransactionId));
          this.activeRecord = storeData;
          this.canApprove = this.managerDashDynamicListService.canIApproveApprovalQueue(this.activeRecord);
          this.entityProperties =storeData.entityProperties;
          this.displayFields = storeData.entityProperties.filter(x => x.isEditable === true).map(x => x.propertyName);
          const entityMetaData = storeData.entityMetaData;
          this.fieldDefinitions = this.fieldDefinitionService.loadFieldDefinitions(this.displayFields, entityMetaData, null);
          this.setSelectFieldsToString();

          this.data = {};
          this.entityProperties.forEach((kvp: IEntityProperty) => {
            if (kvp.isEditable) {
              this.data[kvp.propertyName] = kvp.updatedValue || kvp.propertyValue;
            }
          });
          this.initForm();
        }));

      this.subscription.add(this.metaDataService.mdsReady$.pipe(filter(flag => flag === true), take(1))
        .subscribe((ready: boolean) => {
          this.loadForm();
        }));
    });
  }

  initForm() {
    this.metaDataService.setFieldDefinitionsManually(this.fieldDefinitions, {});
  }

  loadForm() {
    this.metaDataService.loadSelectionLists(this.displayFields, this.operation, this.parentId);
    this.form$.next( this.metaDataService.loadDynamicFormGroup(this.displayFields, this.data, this.operation));
  }

 setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.parentId;
    params.useRouterOutlet = false;
    params.wrapsForm = true;
    params.showCancel = this.operation === this.myConstants.operationTypeDetails;
    params.showErrorBox = true;
    this.detailConfig$.next( params );
  }

  //current implementation does not support select fields, so render select type fields as string
  setSelectFieldsToString(): void {
    this.fieldDefinitions.forEach(x => {
      if (x.selectListDefinition) {
        x.fieldType = this.myConstants.fieldTypeString;
        x.selectListDefinition = null;
      }
    });
  }

  submitUpdate(rec: IApprovalQueueTransaction, methodName: string, stringifiedProperties: string) {
    const def: IListDefinition = this.managerDashboardService.loadDashPendingApprovalListDefinition();

    const keyData: IKey = { storeName: def.storeName, parentId: def.parentId, key: def.rowKeyId, id: this.approvalQueueTransactionId }
    const emitter: IHomEventEmitter = { requestor: 'aqt-change', event: this.myConstants.emitterEventListReload, action: '', data: null }; //keep as reload, not update as needs to be removed from list (filtered out)
    const objectData = stringifiedProperties ? { model: rec, stringifiedProperties: stringifiedProperties } : rec; 
    const updateData = new UpdateObjectCustomModel(keyData, def.controllerName, methodName, objectData, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
