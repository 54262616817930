import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { IAccordianItems } from '../../../../../fw/fw-shared/components/fw-accordian/interfaces/i-accordian-items';

@Component({
  selector: 'warranty-scripts',
  templateUrl: './warranty-scripts.component.html'
})
export class WarrantyScriptComponent implements OnInit, OnChanges  {
  @Input() workOrderId: number;
  @Input() defaultWarm: boolean;
  
  @Output() cancelEvent = new EventEmitter<IHomEventEmitter>();

  scriptItems$: BehaviorSubject<IAccordianItems[]> = new BehaviorSubject([]);

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.loadScriptItems();
  }

  cancel(): void {
    this.cancelEvent.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workOrderId'] && !(changes['workOrderId'].firstChange)) {
      this.initForm();
    }
  }

  loadScriptItems(): void {
    let items: IAccordianItems[] = [];
    items.push({
      title: 'Warm Handoff',
      isActive: this.defaultWarm,
      content: "Mr. / Mrs. <span class='app-text--danger'>[Insert customer last name]</span>, I apologize for any inconvenience this issue has caused you.".concat("<br /> <br />",
      "Please rest assured our Project Resolution Team will work diligently to resolve this matter quickly and with as little interruption into your daily schedule as possible.", "<br /> <br />",
      "I would like to connect you to a Project Resolution Team member immediately, who will be glad to help you.", "<br />",
        "I will be placing you on a brief hold, contacting them, and will return shortly.  Is this ok ?")
    });
    items.push({
      title: 'Cold Handoff',
      isActive: !this.defaultWarm,
      content: "<p>".concat("Mr. / Mrs. <span class='app-text--danger'>[Insert customer last name]</span>, ", "I apologize for the wait, all our PRT members are currently on the phone.",
        "<br />",
        "I would be happy to initiate the resolution request so the Project Resolution Team can work diligently to resolve this matter quickly and with as little interruption into your daily schedule as possible.",
        "<br />",
        "In addition to resolving this concern quickly, our Project Resolution Team will also provide a free inspection of all project areas to ensure you have the best quality standards possible.",
        "<br /><br / >",
        "I do have some additional questions if you have a moment?", "<br /><br />",
      "</p>",
      "The list of questions will vary from project to project but common items to ask are the following:", "<br />",
      "<div class='app-ul'>",
        "<ul>",
          "<li>“Can you please describe in detail the concern you have with your project?”</li>",
        "<li>“Do you have additional project materials on site that can be used if necessary ?”</li>",
      "<li>“Is there a day of the week or time window that works best for your schedule ? Our goal is to have the smallest disruption possible.”</li>",
        "<li> ",
   " “If you have a moment, would you mind sending pictures of the effective areas ?”",
      "<ul> ",
      "<li><i class='fas fa-exclamation app-icon--primary'></i> All pictures are to be archived in the CRM system, and sent to Quality Control before the scheduled appointment.</li>",
        "</ul>",
        "</li>",
        "<li> ",
    "“Are there any important events scheduled in your home that would that we will want to accommodate in our scheduling ?”",
      "<ul> ",
      "<li> PRT should evaluate throughout the conversation if the original technician is eligible to return to the project, and capable of performing the corrective actions necessary to satisfy the customer.</li>",
        "<li> ",
    "At times, it may not be clear if the original technician can return and it will need to be directly asked.",
      "<ul> ",
      "<li>“Do you have any concerns with the original technician returning to resolve this matter for you ?”</li>",
      "<li> They are trained well in these areas and will be very familiar with your project.</li>",
        "</ul>",
       " </li>",
       " </ul>",
       " </li>",
      "<li>“Can you please describe in detail the concern you have with your project ?”</li>",
      "  </ul>",
       " </div>",
       "<p> ",
    "The cold call will conclude with the employee setting firm contact expectations with the customer. <br /> ",
    "The employee will explain to the customer that the PRT will contact them within 8 business hours to resolve their concerns.<br /> ",
        "<i class='fas fa-exclamation app-icon--primary'></i><b> The employee should confirm all contact information to ensure we have the most recent and updated information. </b>",
      "</p>")
    });

    this.scriptItems$.next(items);
  }


}
