<!-- Edit/Create  && listStore.selectionListDataFetched **add this back into the ngIf when get the entity properties set for this guy-->
<div class="app-modal-panel" *ngIf="(activeRecord$ | async) && storeName" >  
    <fw-dynamic-form 
        [vm]="activeRecord$ | async"
        [storeName]="storeName"
        [vmFormDefinition]="formDefinition$ | async"
        [parentId]="authState.providerUser.currentUserId"
        [operation]="operation"
        [errorMessage]="errorMessage$ | async"
        [errorData]="errorData$ | async">
    </fw-dynamic-form>
</div>

