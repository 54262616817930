import { IErrorData } from './i-error-data';

export interface IResponseBase {
  success: boolean;
  errorData: IErrorData[];
  data: any;
  metaData: any;
  message: string;
  extraData: any;
  storeName: string;

}


export class ResponseBase implements IResponseBase {

  constructor(
    public success: boolean = false,
    public errorData: IErrorData[] = [],
    public data: any = null,
    public metaData: any = null,
    public message: string = '',
    public extraData: any = null,
    public storeName: string = ''
  ) {
  }

}
