import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject, EventEmitter, Output, OnDestroy} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { PurchaseOrder,  IPoDefaults, IBranchProgram } from '../../../view-models/index';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services/index'
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

@Component({
  selector: 'purchase-order-create',
  templateUrl: './purchase-order-create.component.html',
  providers: [MetaDataService]
})
export class PurchaseOrderCreateComponent implements OnInit, OnChanges, OnDestroy {
  @Input() branchPrograms: IBranchProgram[];
  @Input() isWarranty: boolean;
  @Input() storeName: string;
  @Input() displayFields: string[] = ['branchProgram', 'purchaseOrderNumber'];
  @Input() poDefaults: IPoDefaults;
  @Input() requestTime: string;
  @Input() showButtons: boolean = true;

  @Output() changeEvent = new EventEmitter<IValueChanged>();
  @Output() public createEvent = new EventEmitter();
  @Output() public cancelEvent = new EventEmitter<IHomEventEmitter>();

  public operation: string = this.myConstants.operationTypeCreate;
  public createForm: FormGroup;

  subscription: Subscription = new Subscription();

  //meta data service is instanced in container parent via providers property of component
  constructor(public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public initForm(): void {
    let po = new PurchaseOrder();
    this.mds.setFieldDefinitions(this.storeName);

    if (!this.isWarranty) {
      this.mds.overrideLabel('purchaseOrderNumber', 'Purchase Order Number'.concat(' (', this.poDefaults.poNumPrefix.toString(), ')'));
      let poRegExValue: unknown = this.poDefaults.poRegexString ? this.poDefaults.poRegexString : null;
      this.mds.overridePattern('purchaseOrderNumber', <RegExp>poRegExValue);
      this.mds.overrideValidationMessage('purchaseOrderNumber', 'pattern', 'The purchase order must be numeric and 6 digits.');
      this.mds.overrideStringLength('purchaseOrderNumber', this.poDefaults.poNumLength);
    } else {
      po.purchaseOrderNumber = this.poDefaults.poNumPrefix.toString() + this.poDefaults.poNumber.toString();
    }
    this.createForm = this.mds.loadDynamicFormGroup(this.displayFields, po, this.myConstants.operationTypeCreate);
  }

  public cancel(): void {
    this.cancelEvent.emit();
  }

  public create(): void {
    let event: IHomEventEmitter = { requestor: this.storeName, event: this.myConstants.emitterEventCreate, action: '', data: this.createForm.value };
    this.createEvent.emit(event);
  }

  listenForChanges(): void {
    this.subscription.add(this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null)).subscribe((obj: IValueChanged) => {
      if (obj.value) {
        if (obj.key === 'branchProgramMgr' || obj.key === 'branchProgram' || obj.key === 'purchaseOrderNumber') {
          this.changeEvent.emit(obj);
        }
      }
    }));
  }

  ngOnInit(): void {
    this.initForm();
    this.listenForChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['requestTime'] && !(changes['requestTime'].firstChange)) {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
