<div [ngClass]="{ 'loading-mask': (loading$ | async) || (working$ | async) }">
  <div class="contact-manager" [ngClass]="{'contact-manager-grid': checkForMatch }">
    <div class="contact-manager-box">
      <form *ngIf="contactService.contactForm" [formGroup]="contactService.contactForm" class="form contact-manager__form" novalidate
            [ngClass]="{'portal__form': displayType !== contactConstantsService.displayTypeDefault, 'contact-manager__form--edit': displayType === contactConstantsService.displayTypeDefault && operation !== myConstants.operationTypeDetails, 'portal__form--edit': displayType !== contactConstantsService.displayTypeDefault && operation !== myConstants.operationTypeDetails}">
        <fw-error-box [errorData]="errorData$ | async" [errors]="errors$ | async" [onForm]="true"></fw-error-box>
        <contact-header *ngIf="contact$ | async"
                        [operation]="forceReadOnly ?  myConstants.operationTypeDetails : operation"
                        [contact]="contact$ | async"
                        [displayType]="displayType"
                        [suffixRequested]="suffixRequested$ | async"
                        [middleRequested]="middleRequested$ | async"
                        [requestTime]="requestTime$ | async"
                        [canIEdit]="canIEdit"
                        [allowText]="allowText">
        </contact-header>
        <ng-content></ng-content>
        <contact-information *ngIf="(phones$ | async) || (emails$ | async)"
                             [operation]="forceReadOnly ?  myConstants.operationTypeDetails : operation"
                             [phones]="phones$ | async"
                             [emails]="emails$ | async"
                             [displayType]="displayType"
                             [canIEdit]="canIEdit"
                             [requestTime]="requestTime$ | async"
                             (deleteEvent)="onDelete($event)">
        </contact-information>
        <contact-addresses *ngIf="addresses$ | async"
                           [contactTypeId]="contactTypeId"
                           [operation]="forceReadOnly ?  myConstants.operationTypeDetails : operation"
                           [addresses]="addresses$ | async"
                           [displayType]="displayType"
                           [canIEdit]="canIEdit"
                           [requestTime]="requestTime$ | async"
                           (deleteEvent)="onDelete($event)">
        </contact-addresses>
        <fw-error-box [errorData]="errorData$ | async" [errors]="errors$ | async" [onForm]="true"></fw-error-box>
      </form>
      <div class="app-btn-box contact-manager__btn-box" [ngClass]="{'contact-manager__btn-box--portal': displayType !== contactConstantsService.displayTypeDefault}">
        <div *ngIf="operation !== myConstants.operationTypeDetails" class="contact-manager__addMore">
          <fw-multi-tier-menu [menu]="addItems"
                              [label]="addTitle"
                              [disabled]="!canIEdit || !addItems.length || forceReadOnly"
                              [fix]="true"
                              (selected)="onAddItem($event)">
          </fw-multi-tier-menu>
        </div>
        <ng-content select=".app-btn-box"></ng-content>
      </div>
    </div>

    <div *ngIf="checkForMatch " class="contact-match-box">
      <div *ngIf="!isPoImport && contactId === 0" class="app-btn-box app-btn-box--center">
        <button type="button"
                class="app-btn app-btn--primary"
                title="Search For Existing Customer Matches"
                [disabled]="!(isFormValid$ | async)"
                (click)="runMatches()">
          Search For Matches
        </button>
      </div>
      <ng-container *ngIf="(searchData$ | async)">
        <fw-section-title title="Possible Contact Matches" [underline]="true"></fw-section-title>
        <contact-matches [searchData]="searchData$ | async"
                         [currentContactId]="contactId"
                         [isPoImport]="isPoImport"
                         [isContactValid]="operation === myConstants.operationTypeDetails || (isFormValid$ | async)"
                         (matchEvent)="onMatchEvent($event)">
        </contact-matches>
        <div class="app-text" *ngIf="!(searchData$ | async)">(Search will trigger when required fields are set.)</div>
      </ng-container>
    </div>

  </div>

  <div *ngIf="displayType === contactConstantsService.displayTypeDefault && operation === myConstants.operationTypeEdit"
       class="app-btn-box ">
    <button type="button" class="app-btn app-btn--cancel" (click)="onCancel()">
      {{ operation === myConstants.operationTypeDetails ? 'Close' : 'Cancel'  }}
    </button>
    <button type="button" class="app-btn app-btn--primary" [disabled]="!canIEdit" (click)="onSave()"> Save</button>
  </div>
</div>
