import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IListColumn, ListColumn, IListDefinition, IListFilter, ListFilter, IListButtonType } from '../../../../../fw/dynamic-list/interfaces/index';

import { IKey, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';

@Component({
   changeDetection: ChangeDetectionStrategy.OnPush,
   selector: 'installer-work-crew-technicians',
    templateUrl: './installer-work-crew-technicians.component.html'
})
export class InstallerWorkCrewTechniciansComponent implements OnInit, OnChanges {
  @Input() providerId: number;
  @Input() installerId: number = -1;
  @Input() workCrewId: number = -1;
  @Input() includeOldestRequirement: boolean = false;
  @Input() canCreate: boolean = false;

  public listDefinition$: BehaviorSubject<IListDefinition>;

  extraData: any;
  removeFromCrewUrlName: string = 'RemoveFromWorkCrew';

  constructor(public  store: Store<fromStore.IAllDynamicData>,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) {
      this.listDefinition$ = new BehaviorSubject(null);
    }

  public onCustom(event: IHomEventEmitter): void {
    if (!event.data) {
      return;
    }
    switch (event.event) {
      case this.removeFromCrewUrlName:
        this.removeTechFromCrew(event);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.loadListDefinition();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['includeOldestRequirement']) {
      this.includeOldestRequirement = changes['includeOldestRequirement'].currentValue;
      this.loadListDefinition();
    }
    if (changes['workCrewId'] && !(changes['workCrewId'].firstChange)) {
      this.loadListDefinition();
    }
    if (changes['installerId'] && !(changes['installerId'].firstChange)) {
      this.loadListDefinition();
    }
  }

  /*
      Define the list to be created:  ALL Technicians with Expiry for Dashboard
  */
  loadListDefinition() {
      const listColumns = this.loadListColumns();
      const listObjectLabel =  'Technicians';
      const listObjectController = 'InstallerWorkCrewTechnician';
      const listStoreName = this.workCrewId > 0 ? 'workCrewTechnicians' : this.installerId > 0 ? 'installerTechnicians' : 'technicians';
      //used by compliance, installer, work crew - at thist time
      const detailRoutePath = this.workCrewId > 0 ? 'installer-work-crew-technician-with-certification-range'
        : this.installerId > 0 ? 'installer-technician-with-certification-range'
          : 'compliance-technician-with-certification-range';
        const listRowKeyId = 'installerWorkCrewTechnicianId';
      const detailRouterOutlet = this.workCrewId > 0 ? 'workCrewTechnicianDetailOutlet'
        : this.installerId > 0 ? 'installerTechnicianDetailOutlet'
          : 'complianceTechnicianDetailOutlet';
      const createRoutePath = this.workCrewId > 0 ? 'installer-work-crew-technician-create' : '';

      const defaultListFilter: IListFilter = new ListFilter();

      let listDefinition = this.dynamicListService.createListDefinition(detailRouterOutlet,
          listObjectLabel,
          listObjectController,
          listStoreName,
          listRowKeyId,
          defaultListFilter,
          listColumns,
          detailRoutePath); 

      listDefinition.parentId = this.workCrewId > 0 ? this.workCrewId : this.installerId; //distinct stores
      listDefinition.parentKey = this.workCrewId > 0 ? 'installerWorkCrew_installerWorkCrewId' : 'installer_installerId';
      listDefinition.controllerMethod = this.workCrewId > 0 ? 'ByInstallerWorkCrew'
        : this.installerId > 0 ? 'ByInstaller'
          : this.includeOldestRequirement ? 'ByProviderWithoutOldestCertification'
            : 'ByProvider';
      listDefinition.methodParameters = this.workCrewId > 0 ? this.workCrewId.toString()
        : this.installerId > 0 ? this.installerId.toString()
          : this.providerId.toString();

      //custom create route to child settings
      listDefinition.createRoutePath = createRoutePath;

      listDefinition.allowSaveFilter = this.workCrewId > 0 || this.installerId > 0 ? false : true;

      if (this.canCreate && this.workCrewId > 0) {
        const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
        listDefinition.toolButtons.splice(0, 0, newTools[0]);
      }

      let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
      listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

      if (this.workCrewId > 0) {
        listDefinition.rowButtons.splice(0, 0,
          {
            title: 'Remove Technician',
            icon: 'fas fa-unlink',
            cssName: 'app-btn-icon--secondary',
            enabled: this.canCreate,
            eventName: this.removeFromCrewUrlName,
          }
        );
      }

      this.listDefinition$.next(listDefinition); //set at end to signify ready to instantiate the list.
    }

    /*
        Define the columns in the list to be created.
    */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('isActive');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('contactName'); 
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isWorkCrewLead');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    if (this.workCrewId === -1) {
      colDef = new ListColumn('installerWorkCrew');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);
    }

    colDef = new ListColumn('technicianDescription');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('communicationPreferenceValue');
    colDef.visibleOnSmall = true;
    columnDefinitions.push(colDef);

    if (this.includeOldestRequirement) {
      colDef = new ListColumn('minimumRequirementDate');
      colDef.visibleOnSmall = false;
      colDef.visibleOnMedium = false;
      columnDefinitions.push(colDef);
    }

    return columnDefinitions;
  }

  removeTechFromCrew(event: IHomEventEmitter): void {
    const listDef: IListDefinition = this.listDefinition$.getValue();
    const id: number = event.data.hasOwnProperty(listDef.rowKeyId) ? event.data[listDef.rowKeyId] : -1;
    if (id === -1) {
      console.log('row id not found');
      return;
    }
    const keyData: IKey = { storeName: listDef.storeName, parentId: listDef.parentId, key: listDef.rowKeyId, id: id };
    const emitter: IHomEventEmitter = { requestor: listDef.detailRoutePath, event: this.myConstants.emitterEventListReload, action: '', data: null };

    const updateData = new UpdateObjectCustomModel(keyData, listDef.controllerName, this.removeFromCrewUrlName, event.data, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));

  }
}
