import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IContactType } from '../../../view-models/i-contact-type';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { AdminEvent } from '../../enums/admin.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'contact-types',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './contact-types.component.html'
})
export class ContactTypesComponent implements OnInit {

  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.manageContactTypeSpecifications:
        this.openContactTypeSpecifications(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadContactTypesListDefinition();
  }

  openContactTypeSpecifications(type: IContactType): void {
    this.modalService.open({
      title: 'Specifications for Contact Type'.concat(' ', type.contactTypeName),
      path: 'contact-type-specs/' + type.contactTypeId.toString(),
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false
    });
  }
}
