import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { ManagerDashEvent } from '../../enums/manager-dashboard.enums';
import { ICommunicationEventLog } from '../../../view-models/i-communication-event-log';
import { IKey, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { ManagerDashboardService } from '../../services/manager-dashboard.service';


@Component({
  selector: 'communication-event-logs',
  templateUrl: './communication-event-logs.component.html'
})

export class CommunicationEventLogsComponent implements OnInit {
  listDefinition: IListDefinition;

  constructor(public managerDashboardService: ManagerDashboardService,
    public store: Store<fromStore.IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.listDefinition = this.managerDashboardService.loadCommunicationEventLogsListDefinition();
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case ManagerDashEvent.markResolved:
        this.markResolved(event.data);
        break;
      default:
        break;
    }
  }

  markResolved(data: ICommunicationEventLog): void {
    //refresh parent list should refresh on successful update
    const def: IListDefinition = cloneDeep(this.listDefinition);
    const keyData: IKey = { storeName: def.storeName, parentId: -1, key: def.rowKeyId, id: data.communicationEventLogId };
    const emitter: IHomEventEmitter = { requestor: 'communication-event-logs', event: this.myConstants.emitterEventListReload, action: '', data: null };
    const updateData = new UpdateObjectCustomModel(keyData, def.controllerName, 'MarkResolved', data, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));
  }

}
