  /*
Common Methods Used by Admin Dashboard components
*/
import { Injectable, Inject } from '@angular/core';
import { IListColumn, ListColumn, ListDefinition, IListDefinition, IListFilter, ListFilter, IListButtonType, OrderTerm } from '../../../../fw/dynamic-list/interfaces';
import { AdminDashStore, AdminDashEvent } from '../enums/admin-dashboard.enums';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { IDashboardCount } from '../../portal-shared/interfaces';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { appConstants, IAppConstants } from '../../../../shared/constants';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';

import { DynamicListService } from '../../../../fw/dynamic-list/services';

@Injectable()
export class AdminDashboardService {
  public homeDepotGcId: number = 1;
  lastDashCounts: IDashboardCount[] = [];

  constructor(@Inject(appConstants) public myConstants: IAppConstants,
      public dynamicListService: DynamicListService) { }

  public initSavedValues(): void {
    //clean up for log off
    this.lastDashCounts = [];
  }

    /*Load List Methods*/
  public loadProgramServiceMappingListDefinition(): IListDefinition {
    const listColumns = this.loadProgramServiceMappingListColumns();
    const listObjectLabel = 'Program Service Mapping';
    const listObjectController = 'ProgramServiceXmlMapping';
    const listStoreName = AdminDashStore.dashProgamServiceMapping;
    const detailRoutePath = 'dash-program-service-mapping';
    const listRowKeyId = 'programServiceXmlMappingId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [{
      term: 'programService_programServiceId',
      value: null,
      searchType: SearchType.Equals,
      columnName: 'programServiceName',
      fieldType: this.myConstants.dataTypeInt,
      displayValues: this.myConstants.notAssigned
    }];
    defaultListFilter.orderTerm = [new OrderTerm('mappingText')];

    let listDefinition = this.dynamicListService.createListDefinition('dashProgramServiceMappingOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';
    listDefinition.rowStatus = {
      cssMethod: 'getIsNotMappedCss',
      methodService: 'adminDashDynamicListService'
    };

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadProgramServiceMappingListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('programServiceName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('mappingText');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isPadProgram');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('generalContractor');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadBranchMappingListDefinition(): IListDefinition {
    const listColumns = this.loadBranchMappingListColumns();
    const listObjectLabel = 'Branch Mapping';
    const listObjectController = 'BranchXmlMapping';
    const listStoreName = AdminDashStore.dashBranchMapping;
    const detailRoutePath = 'dash-branch-mapping';
    const listRowKeyId = 'branchXmlMappingId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [{
      term: 'branch_branchId',
      value: null, searchType: SearchType.Equals,
      columnName: 'branch_branchName',
      fieldType: this.myConstants.dataTypeInt,
      displayValues: this.myConstants.notAssigned
    }];
    defaultListFilter.orderTerm = [new OrderTerm('mappingText')];

    let listDefinition = this.dynamicListService.createListDefinition('dashBranchMappingOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';

    listDefinition.rowStatus = {
      cssMethod: 'getIsNotMappedCss',
      methodService: 'adminDashDynamicListService'
    };

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadBranchMappingListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('branch');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('mappingText');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('generalContractor');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadSkuMappingListDefinition(gcId: number): IListDefinition {
    const listColumns = this.loadSkuMappingListColumns();
    const listObjectLabel = 'Sku Mapping';
    const listObjectController = 'SkuXmlMapping';
    const listStoreName = AdminDashStore.dashSkuMapping;
    const detailRoutePath = 'dash-sku-mapping';
    const listRowKeyId = 'skuXmlMappingId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [{
      term: 'sku_skuId',
      value: null, searchType: SearchType.Equals,
      columnName: 'skuNameEntity',
      fieldType: this.myConstants.dataTypeInt,
      displayValues: this.myConstants.notAssigned
    }];
    defaultListFilter.orderTerm = [new OrderTerm('mappingText')];

    let listDefinition = this.dynamicListService.createListDefinition('dashSkuMappingOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = gcId;
    listDefinition.parentKey = 'generalContractor_generalContractorId';

    listDefinition.controllerMethod = 'ByGeneralContractor';
    listDefinition.methodParameters = gcId.toString();
    listDefinition.rowStatus = {
      cssMethod: 'getIsNotMappedCss',
      methodService: 'adminDashDynamicListService'
    };

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0, 
      {
        title: 'Create Sku',
        icon: 'fad fa-wand-magic',
        cssName: 'app-btn-icon--success',
        enabled: true,
        enabledMethod: 'canCreateSku',
        methodService: 'adminDashDynamicListService',
        eventName: AdminDashEvent.createSku
      });


   return listDefinition;
  }

  loadSkuMappingListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('skuNameEntity');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('mappingText');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('ignore');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('generalContractor');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadUnitMeasureMappingListDefinition(): IListDefinition {
    const listColumns = this.loadUnitMeasureMappingListColumns();
    const listObjectLabel = 'Unit of Measure Mapping';
    const listObjectController = 'UnitMeasureXmlMapping';
    const listStoreName = AdminDashStore.dashUnitMeasureMapping;
    const detailRoutePath = 'dash-unit-measure-mapping';
    const listRowKeyId = 'unitMeasureXmlMappingId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [{
      term: 'unitMeasure_unitMeasureId',
      value: null, searchType: SearchType.Equals,
      columnName: 'measureDescription',
      fieldType: this.myConstants.dataTypeInt,
      displayValues: this.myConstants.notAssigned
    }];
    defaultListFilter.orderTerm = [new OrderTerm('mappingText')];

    let listDefinition = this.dynamicListService.createListDefinition('dashUnitMeasureMappingOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';
    listDefinition.rowStatus = {
      cssMethod: 'getIsNotMappedCss',
      methodService: 'adminDashDynamicListService'
    };

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadUnitMeasureMappingListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('measureDescription');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('mappingText');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('generalContractor');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadWorkOrderSurveyListDefinition(showUnassigned: boolean): IListDefinition {
    const listColumns = this.loadWorkOrderSurveyListColumns();
    const listObjectLabel = 'Customer Survey';
    const listObjectController = 'WorkOrderSurvey';
    const listStoreName = AdminDashStore.dashWorkOrderSurveys;
    const detailRoutePath = 'dash-wo-survey';
    const listRowKeyId = 'workOrderSurveyId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [{
      term: 'workOrder_workOrderId',
      value: null,
      searchType: SearchType.NoValue,
      columnName: 'workOrder',
      fieldType: this.myConstants.dataTypeIntNull,
      displayValues: this.myConstants.notAssigned
    }];
    defaultListFilter.orderTerm = [new OrderTerm('workOrder_workOrderId', true)];

    let listDefinition = this.dynamicListService.createListDefinition('dashWOSurveyOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns, 
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';

    listDefinition.controllerMethod = 'Index';
    listDefinition.methodParameters = '';

    listDefinition.toolButtons.splice(0, 0,
      {
        title: 'Import Surveys',
        icon: 'fas fa-cloud-upload',
        cssName: 'app-btn-icon--primary',
        enabled: true,
        eventName: AdminDashEvent.launchSurveyImport
      });

    const editDefaults: ICustomButton = {
      title: '',
      icon: '',
      cssName: '',
      enabled: true,
      enabledMethod: 'canEditSurvey',
      eventName: '',
      methodService: 'adminDashDynamicListService'
   }

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: editDefaults },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadWorkOrderSurveyListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('identifierId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('identiferMetaData');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrder');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('surveyDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('numberResponses');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('surveyLocked');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadWorkOrderSurveyResponsesListDefinition(workOrderSurveyId: number): IListDefinition {
    const listColumns = this.loadWorkOrderSurveyResponseListColumns();
    const listObjectLabel = 'Customer Survey Responses';
    const listObjectController = 'WorkOrderSurveyResponse';
    const listStoreName = AdminDashStore.dashWorkOrderSurveyResponses;
    const detailRoutePath = '';
    const listRowKeyId = 'workOrderSurveyResponseId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('surveyQuestion_surveyQuestionText', true)];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = workOrderSurveyId;
    listDefinition.parentKey = 'workOrderSurveyId';

    listDefinition.controllerMethod = 'ByWorkOrderSurvey';
    listDefinition.methodParameters = workOrderSurveyId.toString();

    return listDefinition;
  }

  loadWorkOrderSurveyResponseListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('surveyQuestion');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('responseText');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
