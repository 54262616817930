import { Component, Input,  OnChanges, OnInit, SimpleChanges, ChangeDetectionStrategy, Inject } from '@angular/core';
import {  FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../shared/constants/index';
import { IErrorData } from '../../../shared/interfaces/index';
import {  IFormDefinition  } from '../../dynamic-forms/index';
import { ICustomDetailButton } from '../../dynamic-detail/interfaces';
import { MetaDataService } from '../../dynamic-list/services/index';

@Component({
    selector: 'fw-dynamic-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './dynamic-form.component.html',
    providers: [ MetaDataService ]
})
export class DynamicFormComponent implements OnChanges, OnInit{
  @Input() vm: any; //could change to get based on id and storename
  @Input() storeName: string; //storename for data and metada - should match
  @Input() parentId: number;
  @Input() vmFormDefinition: IFormDefinition;
  @Input() operation: string;
  @Input() errorMessage: string;
  @Input() errorData: Array<IErrorData>;
  @Input() displayFields: string[];
  @Input() requestTime: string;
  @Input() includeNavButtons: boolean = true;
  @Input() forceDirty: boolean = false;
  @Input() forceInvalid: boolean = false;

  public form: FormGroup;
  public submitted = false;
  vmCopy: any;

  constructor(
    public  mds: MetaDataService,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  public dataLoaded(): boolean {
    const hasLists: boolean = this.mds.hasSelectionLists(this.displayFields);
    return this.mds.fieldDefinitionsLoaded &&
      (!hasLists || (hasLists && this.mds.selectionListsLoaded)
      );
  }

  public onSave(action: string) {
    this.submitted = true;
    if (this.form.valid) {
      let emitter: IHomEventEmitter = { requestor: this.vmFormDefinition.requestor, event: this.myConstants.emitterEventUpdate, action: action, data: this.form.getRawValue() };
      this.emitterService.emitDetailEvent(emitter);
    }
  }

  public onCancel() {
    this.errorMessage = null;
    this.mds.clearValidationMessages(this.displayFields);
    let backEvent: IHomEventEmitter = { requestor: this.vmFormDefinition.requestor, event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitDetailEvent(backEvent);
  }

  //NOTE: For events emitted via emitterService, your component will need to listen for the events.
  public onCreate() {
    this.submitted = true;
    if (this.form.valid) {
      let emitter: IHomEventEmitter = { requestor: this.vmFormDefinition.requestor, event: this.myConstants.emitterEventCreate, action: '', data: this.form.getRawValue() };
      this.emitterService.emitDetailEvent(emitter);
    }
  }

  public onCustomBtn(btn: ICustomDetailButton): void {
    this.submitted = true;
    if (this.form.valid) {
      let emitter: IHomEventEmitter = { requestor: this.vmFormDefinition.requestor, event: btn.event, action: btn.action, data:  this.form.getRawValue() };
      this.emitterService.emitDetailEvent(emitter);
    }
  }
  /*
    Form init, will create the fields and load the values.
    detail component will subscribe to params
*/
  ngOnInit() {
    this.mds.setFieldDefinitions(this.storeName);

    if (this.mds.fieldDefinitionsLoaded) {
   //if specific fields were not passed in, use all fields definied in field definition
      if (!this.displayFields || this.displayFields.length === 0) {
        console.log('DEV WARNING:  displays fields not set by component, dynamic form will use all fields defined in field definitions');
      }
      this.loadForm();
    }
  }

/*
        Watches for changes to any of the input parameters.
        When an error is returned, will reload the form
        ** ensure you have set the view model input field to what you sent to the server before setting either error input field
    */
  ngOnChanges(changes: SimpleChanges) {
        //a global error message was returned
        //field level error message(s) were returned
      if (changes['errorData']
        && !changes['errorData'].isFirstChange()
        && changes['errorData'].currentValue
      ) {
        this.mds.setErrorMessages(changes['errorData'].currentValue);
      }

    if ((changes["vm"] && !changes['vm'].isFirstChange())
      ||
      (changes["requestTime"] && !changes['requestTime'].isFirstChange())
      ) {
        this.loadForm();
      }

      //updated a record but keeping detail open
      if (changes['operation']
        && !changes['operation'].isFirstChange()
        ) {
        this.loadForm();
      }

      if (changes['forceDirty']
        && !changes['forceDirty'].isFirstChange()
        && changes['forceDirty'].currentValue ) {
        this.form.markAsDirty();
      }

      if (changes['forceInvalid']
        && !changes['forceInvalid'].isFirstChange()) {
        this.form.updateValueAndValidity();
        if (this.form.invalid || this.forceInvalid) {
          this.form.setErrors({ 'invalid': true });
        }
      }
   }


    /*
    Clears the form and loads the fields and values
*/
  loadForm() {
    this.operation = this.operation === this.myConstants.operationTypeRefreshOnSave
      ? this.myConstants.operationTypeEdit
      : this.operation;

    this.vmCopy = cloneDeep(this.vm);

    if (!this.mds.fieldDefinitionsLoaded) {
      console.log('DEV ERROR:  dynamic-form and missing vmDefinition');
    }
    this.mds.loadSelectionLists(this.displayFields, this.myConstants.requestorTypeDetails, this.parentId || -1);
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.vmCopy, this.operation);
  }

  onEdit() {
      let event: IHomEventEmitter = { requestor: this.vmFormDefinition.requestor, event: this.myConstants.emitterEventEdit, action: '', data: null };
      this.emitterService.emitDetailEvent(event);
    }

    onRefresh() {
      let event: IHomEventEmitter = { requestor: this.vmFormDefinition.requestor, event: this.myConstants.emitterEventRefresh, action: '', data: null };
      this.emitterService.emitDetailEvent(event);

    }

}
