import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { INotification  } from '../../../../view-models/index';
import { IAppConstants, appConstants } from '../../../../../../shared/constants/index';
import { IKey, UpdateObjectCustomModel } from '../../../../../../fw/dynamic-list/store/interfaces/index';
import { IInputButton } from '../../../../../../fw/fw-shared/interfaces/i-input-button';
import { IDetailContainerConfig } from '../../../../../../fw/dynamic-detail/interfaces';
import { IListDefinition } from '../../../../../../fw/dynamic-list/interfaces';

import { DynamicDetailService } from '../../../../../../fw/dynamic-detail/services/dynamic-detail.service';

import * as fromStore from '../../../../../../fw/dynamic-list/store/index';
import * as fromDynamicList from '../../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';

@Component({
  selector: 'user-notification',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-notification.component.html'
})
export class UserNotificationComponent implements OnInit, OnDestroy {
  public disabled: boolean = true;
  public operation: string;
  public objectData: INotification;
  public cbConfig: IInputButton = { label: { label: '', alignRight: true } };
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  isReady: boolean = false;
  myStoreName: string = '';
  key: string = '';
  activeIndex:number = -1;
  objectId: number = 0;
  showNext: boolean = true;
  listDefinition: IListDefinition;
  subscription: Subscription = new Subscription();

  constructor(
    public  activeRoute: ActivatedRoute,
    public  store: Store<fromStore.IAllDynamicData>,
    public  emitterService: HomEventEmitterService,
    public  dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public  myConstants: IAppConstants) {
  }

  public changeAck(): void {
    this.disabled = false;
  }

  public onSave() : void {
    let data: INotification = cloneDeep(this.objectData);
    data.acknowledged = true;
    data.updateDate = new Date().toDateString();
    const keyData: IKey = { storeName: this.myStoreName, parentId: -1, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: 'user-notification', event: this.myConstants.emitterEventUpdate, action: '', data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.listDefinition.controllerName, 'Update', data, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));
  }

  public onCancel(): void{
    const emitter: IHomEventEmitter = { requestor: 'user-notification', event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.activeIndex = +paramMap.get('rowIndex');
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.showNext = paramMap.get('showNext') === 'true';
      this.setDetailConfig(paramMap);
      this.getData();
    });
  }

  getData(): void {
    this.subscription.add(this.store.pipe(select(fromStore.getEntityListByParentId(this.myStoreName, -1)),take(1))
      .subscribe((state: fromDynamicList.IListObjectData) => {
        if (state && state.data) {
          this.listDefinition = cloneDeep(state.listDefinition);
          this.objectData = cloneDeep(state.data.find(data => data[this.key] == this.objectId));
        }
      }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.useRouterOutlet = false;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
