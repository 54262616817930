  <div *ngFor="let email of emails">
    <contact-email 
                   [fieldDefinitions]="fieldDefinitions"
                   [email]="email"
                   [operation]="operation"
                   [displayType]="displayType"
                   [requestTime]="requestTime"
                   [canIEdit]="canIEdit"
                   [activePreferred]="activePreferred$ | async"
                   (deleteEvent)="onDelete($event)">
    </contact-email>
  </div>

