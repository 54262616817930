export interface ISchedule {
  scheduleId: number;
  project_projectId: number;
  workOrder_workOrderId: number;
  providerLocationService_providerLocationServiceId: number
  allDay: boolean;
  durationType: string;
  duration: number;
  scheduleStartDate: string;
  scheduleEndDate: string;
  reservationId: number;
  expiration: string;
  communicationFeedbackEvent_communicationFeedbackEventId: number;
  communicationEventFreeformFeedback: string;
  selfServiceState: string;
  scheduleDuration: string;
  projectMargin: string;
  specialInstructionAlert: boolean;
  sum: number;
  workOrderCapacityText: string;
  workOrderProgramText: string;
  customerName: string;
  serviceName: string;
  contactId: number;
  installerId: number;
  installerName: string;
  installerCrewsName: string;
  installerWorkCrewLeadName: string;
  installerWorkCrewId: number;
  installerEmailWorkPacket: boolean;
  workOrderLabel: string;
  workOrderStatus: string;
  hasMoveUp: boolean;
  communicationPreferenceName: string;
  communicationPreferenceValue: string;

  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
}


export class Schedule implements ISchedule {
  scheduleId: number;
  project_projectId: number;
  workOrder_workOrderId: number;
  providerLocationService_providerLocationServiceId: number
  allDay: boolean;
  durationType: string;
  duration: number;
  scheduleStartDate: string;
  scheduleEndDate: string;
  reservationId: number;
  expiration: string;
  communicationFeedbackEvent_communicationFeedbackEventId: number;
  communicationEventFreeformFeedback: string;
  selfServiceState: string;
  scheduleDuration: string;
  projectMargin: string;
  specialInstructionAlert: boolean;
  sum: number;
  workOrderCapacityText: string;
  workOrderProgramText: string;
  customerName: string;
  serviceName: string;
  contactId: number;
  installerId: number;
  installerName: string;
  installerCrewsName: string;
  installerWorkCrewLeadName: string;
  installerWorkCrewId: number;
  installerEmailWorkPacket: boolean;
  workOrderLabel: string;
  workOrderStatus: string;
  hasMoveUp: boolean;
  communicationPreferenceName: string;
  communicationPreferenceValue: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;


  constructor(projectId: number, workOrderId: number, durationType: string, duration: number) {
    this.scheduleId = 0;
    this.project_projectId = projectId;
    this.workOrder_workOrderId = workOrderId;
    this.providerLocationService_providerLocationServiceId = null;  
    this.allDay = true;
    this.durationType = durationType;
    this.duration = duration;
    this.scheduleStartDate = '';
    this.scheduleEndDate = '';
    this.expiration = '';

    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';
  }

}

