
export enum ContactEvent {
  cancel = 'cancel',
  createWithContact = 'createWithContact',
  updated = 'updated',
  selectMatch = 'selectMatch',
  linkContactToPo = 'linkContactToPo',
  unLinkContactToPo = 'unLinkContactToPo',
  linkContactToParent = 'linkContactToParent',
  unLinkContactToParent = 'unLinkContactToParent',
  deleteContactPreference = 'deleteContactPreference',
  reloadEvent = 'reloadEvent',
  uploadContactFile = 'uploadContactFile',
  initContactInfo = 'initContactInfo',
  refreshMatchData = 'refreshMatchData',
  ranNewContactMatchCheck = 'ranNewContactMatchCheck',
  isContactValid = 'isContactValid'
}

export enum ContactEventAction {
  mergeWithExisting = 'mergeWithExisting',
  createNewContact = 'createNewContact',
  linkWithExisting = 'linkWithExisting' //project customer manager
}

export enum CommunicationPreference {
  email = 'Email',
  phone = 'Phone',
  sms = 'Sms'
}

export enum ContactStore {
  contactInformation = 'contactInformation',
  phones = 'phones',
  emails = 'emails',
  addresses = 'addresses'
}

export enum ContactEditType {
  linkWithExisting = 'linkWithExisting',
  mergeWithExisting = 'mergeWithExisting',
  standard = 'standard'
}
