import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { take  } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { IListDefinition, ListDefinition, IListColumn, ListColumn, IListFilter, ListFilter, IListButtonType, OrderTerm } from '../../../../../fw/dynamic-list/interfaces/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromAuth from '../../../../../auth/store/auth.reducer';
import { SearchType } from '../../../../../fw/dynamic-list/enums/search-type.enums';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';
import { appConstants, IAppConstants } from '../../../../../shared/constants';

@Component({
  selector: 'user-pending-changes',
  templateUrl: './user-pending-changes.component.html'
})
export class UserPendingChangesComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;
  authState: fromAuth.IState;

  subscription: Subscription = new Subscription();

  constructor(public rootStore: Store<fromRoot.IState>,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants ) { }

  ngOnInit() {
    this.subscription.add(this.rootStore.select('auth')
      .pipe(take(1))
      .subscribe((authState: fromAuth.IState) => {
        this.authState = authState;
        this.loadListDefinition();
      }));
  }

  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'My Pending Changes';
    const listObjectController = 'ApprovalQueueTransaction';
    const listStoreName = 'userPendingChanges';
    const detailRoutePath = 'user-pending-change';
    const listRowKeyId = 'approvalQueueTransactionId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [
      { term: 'Status', value: 'Pending', searchType: SearchType.Equals, columnName: 'status', fieldType: this.myConstants.dataTypeString },
      { term: 'Expired', value: 'false', searchType: SearchType.Equals, columnName: 'expired', fieldType: this.myConstants.dataTypeBool }];
    defaultListFilter.orderTerm = [new OrderTerm('expiresOn')];

    let listDefinition = this.dynamicListService.createListDefinition('commonDetailOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.methodParameters = this.authState.providerUser.currentUserId.toString();
    listDefinition.showFilter = false;
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;

    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    this.listDefinition = listDefinition;
  }

  /*
      Define the columns in the list to be displayed.  Columns pulled back are set by the db.
  */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('entityName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('transactionContext');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('parentContext');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('createDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('approvalRequestUser');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('expiresOn');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
