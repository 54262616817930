import { Component, OnInit, ChangeDetectionStrategy,  Inject, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services/index'
import { JobScheduleService, IDaysOfWeek } from '../../../portal-shared/services/index';
import { IEnum, IProviderLocationShipperInventorySetup } from '../../../view-models/index';
import { IInputButton } from '../../../../../fw/fw-shared/interfaces';
import { IJobScheduleViewModel } from '../../../view-models/i-job-schedule-view-model';
import { IJobScheduleEvent } from '../../interfaces';

//store actions, reducers, interfaces
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { HomCommonUtility } from '../../../../../shared/services';
import { UserPriviledgesService } from '../../../../../auth/services';

interface ISelectedDay {
  dayOfWeek: IDaysOfWeek,
  checked: boolean,
  cbConfig: IInputButton
}

@Component({
  selector: 'delivery-schedule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './delivery-schedule.component.html',
  providers: [MetaDataService]
})
export class DeliveryScheduleComponent implements OnInit, OnChanges {
  @Input() model: IProviderLocationShipperInventorySetup
  @Input() operation: string;
  @Input() storeName: string;
  @Input() parentId: number;
  @Output() modelChange = new EventEmitter<IJobScheduleEvent>();

  public form: FormGroup;
  public showNext: boolean = false;
  public jobFrequencyUnits: any[] = [];
  public daysOfWeek: IDaysOfWeek[] = [];
  public selectedDaysOfWeek$: BehaviorSubject<ISelectedDay[]> = new BehaviorSubject([]);
  public enableDays$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public scheduleSummary$: BehaviorSubject<string> = new BehaviorSubject('');
  public duration$: BehaviorSubject<string> = new BehaviorSubject('');
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public displayFields: string[] = ['jobScheduleName', 'jobFrequencyType', 'isActive', 'jobFrequencyRecurUnit1', 'jobStartDate', 'jobEndDate'];
  jobFrequencyTypes: IEnum[] = [];
  isInValidRange: boolean = false;
  errors: string;

  loaded: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(
    public activatedRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public datePipe: DatePipe,
    public mds: MetaDataService,
    public jobScheduleService: JobScheduleService,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public utils: HomCommonUtility,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public updateSelectedDays(index: number, checked: boolean) {
    let selectedDaysOfWeek: ISelectedDay[] = cloneDeep(this.selectedDaysOfWeek$.value);
    selectedDaysOfWeek[index].checked = checked;
    this.selectedDaysOfWeek$.next(selectedDaysOfWeek);
    this.emitChange();
  }

  public getModel(): IJobScheduleViewModel {
    const formData: IProviderLocationShipperInventorySetup = this.form.getRawValue();

    //Only fields on form:
    //['jobScheduleName', 'jobFrequencyType', 'isActive', 'jobFrequencyRecurUnit1', 'jobStartDate', 'jobEndDate']
    let jobSchedule: IJobScheduleViewModel = {
      jobScheduleName: formData.jobScheduleName,
      isActive: formData.isActive,
      jobFrequencyType: formData.jobFrequencyType,
      jobFrequencyRecurUnit1: formData.jobFrequencyRecurUnit1,
      jobStartDate: formData.jobStartDate,
      jobEndDate: formData.jobEndDate,
      jobScheduleWeekdays: this.selectedDaysOfWeek$.value.filter(x => x.checked).map(y => y.dayOfWeek.longName),

      jobScheduleId: this.model.jobScheduleId || 0,
      providerId: this.model.providerId || this.ups.providerId$.value,
      jobScheduleSummary: '', //derived field
      allDayEvent: this.model.allDayEvent || true,
      jobScheduleType: this.model.jobScheduleType,
      jobScheduleRecurrenceType: this.model.jobScheduleRecurrenceType, 
      jobFrequencyRecurUnit2: 1,
      jobFrequencyRecurUnit3: 1,
      dailyFrequencyTime: this.model.dailyFrequencyTime,
      dailyFrequencyUnit: this.model.dailyFrequencyUnit,
      dailyFrequencyStartTime: this.model.dailyFrequencyStartTime,
      dailyFrequencyEndTime: this.model.dailyFrequencyEndTime,
      dailyFrequency: ''
    }

    return jobSchedule;
  }

  ngOnInit() {
    this.initForm();

    this.subscription.add(this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null))
      .subscribe((obj: IValueChanged) => {
        if (this.form.dirty && this.operation !== this.myConstants.operationTypeDetails) {
          this.handleValueChanged(obj);
        }
      }));
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['model'] && !(changes['model'].firstChange)) {
      this.loadForm();
    }
  }

  initForm() {
    this.mds.setFieldDefinitions(this.storeName);
    this.initSelectedDaysOfWeek();

    this.duration$.next(' day');

    this.loadForm();
  }

  loadForm() {
    this.mds.loadSelectionLists(this.displayFields, this.operation, this.parentId);
    let types: IEnum[] = this.mds.getSelectItems('jobFrequencyType');
    this.jobFrequencyTypes = types.filter(x => x.name.toLowerCase() !== 'monthly');

    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.model, this.operation);
    this.setDaysEnabled(this.model.jobFrequencyType);
    this.setDuration(this.model.jobFrequencyType);
    this.setCheckedDays(this.model.jobScheduleWeekdays);
    this.scheduleSummary$.next(this.model.jobScheduleSummary);

    this.loaded = true;
  }

  initSelectedDaysOfWeek(): void {
    this.daysOfWeek = this.jobScheduleService.daysOfWeek;
    let selectedDaysOfWeek: ISelectedDay[] = [];
    this.daysOfWeek.forEach(day => {
      const cbConfig = {
        label: {
          label: day.shortName,
          alignRight: false,
          title: day.longName
        }
      };
      selectedDaysOfWeek.push({ dayOfWeek: day, checked: false, cbConfig: cbConfig });
    });

    this.selectedDaysOfWeek$.next(selectedDaysOfWeek);
  }

  handleValueChanged(obj: IValueChanged) {
      switch (obj.key) {
        case 'jobFrequencyType':
          const indx = obj.value ? this.jobFrequencyTypes.findIndex(x => x.id == obj.value['id']) : -1;
          const type = indx > -1 ? this.jobFrequencyTypes[indx].name : '';
          this.initSelectedDaysOfWeek;
          this.setDuration(type);
          this.setDaysEnabled(type);
        break;
        case 'jobFrequencyRecurUnit1':
          const cntrl: AbstractControl = this.form.controls['jobFrequencyType'];
          const freqType: IEnum = cntrl ? cntrl.value : null
          this.setDuration(freqType ? freqType.name : '');
          break;
        case 'jobStartDate':
          this.checkValidStartEnd();
          break;
        case 'jobEndDate':
          this.checkValidStartEnd();
          break;
        default:
          break;
    }

    //emit changed event
    this.emitChange();
  }

  setSummary(): void {
    this.scheduleSummary$.next(this.jobScheduleService.jobScheduleSummary(this.getModel()))
  }

  emitChange(): void {
    this.setSummary();

    if (this.operation !== this.myConstants.operationTypeDetails) {
      const event: IJobScheduleEvent = {
        model: this.getModel(),
        isValid: this.form.valid && !this.isInValidRange
      }
      this.modelChange.emit(event);
    }
 }

  setDaysEnabled(jobFrequencyType: string): void {
    if (this.operation === this.myConstants.operationTypeDetails) {
      this.enableDays$.next(false);
    } else {
      this.enableDays$.next(jobFrequencyType && jobFrequencyType.toLowerCase() == 'weekly' ? true : false);
    }
  }

  setCheckedDays(days: number[]): void {
    let daysOfWeek = cloneDeep(this.selectedDaysOfWeek$.value);
    daysOfWeek.forEach((obj, index) => {
      obj.checked = days.includes(index);
    });

    this.selectedDaysOfWeek$.next(daysOfWeek);
  }

  //end date is not required, but if set must be after start date
  //custom validation error:  distinct fields but used as a range
  checkValidStartEnd(): void {
    let isGreaterThan: boolean = false;
    const jobStartDateCntrl: AbstractControl = this.form.controls['jobStartDate'];
    const jobEndDateCntrl: AbstractControl = this.form.controls['jobEndDate'];
    if (this.form.valid && jobEndDateCntrl && jobEndDateCntrl.value && jobStartDateCntrl && jobStartDateCntrl.value) {
     isGreaterThan = new Date(jobStartDateCntrl.value) > new Date(jobEndDateCntrl.value);
    }
    if (isGreaterThan) {
      this.errors = 'End Date can not be prior to the Start Date';
    } else {
      this.errors = null;
    }
    this.isInValidRange = isGreaterThan;
    this.emitChange();

  }

  setDuration(jobFrequencyType: string) {
    let duration: string = 'day';
    const jobFrequencyRecurUnit1Control: AbstractControl = this.form.controls['jobFrequencyRecurUnit1'];
    switch (jobFrequencyType) {
      case 'Daily':
        duration = jobFrequencyRecurUnit1Control && jobFrequencyRecurUnit1Control.value > 1 ? 'days' : 'day';
        break;
      case 'Weekly':
        duration = jobFrequencyRecurUnit1Control && jobFrequencyRecurUnit1Control.value > 1 ? 'weeks' : 'week';
        break;
      default:
    }
    this.duration$.next(duration);
  }


}
