import { Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';
import { IProjectTaskActionViewModel } from '../../../view-models';
import { IHomDictionary } from '../../../../../shared/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { MetaDataService } from '../../../../../fw/dynamic-list/services';
import { FormGroup } from '@angular/forms';
import { TaskManagerService, ITaskComplete } from '../../services';

interface ITaskActionScheduleWo {
  dueDateOverride: string;
  dueDateOverrideReason: string;
}

@Component({
  selector: 'task-action-schedule-wo',
  templateUrl: './task-action-schedule-wo.component.html',
  providers: [MetaDataService]
})
export class TaskActionScheduleWoComponent implements OnInit  {
  @Input() projectTaskId: number;
  @Input() projectTaskAction: IProjectTaskActionViewModel;
  @Input() operation: string;
  @Input() canIEdit: boolean;
  @Output() public completeEvent = new EventEmitter<ITaskComplete>();

  public displayFields: string[] = ['dueDateOverride', 'dueDateOverrideReason'];
  public form: FormGroup;

  constructor(public metaDataService: MetaDataService,
    public taskManagerService: TaskManagerService,
  @Inject(appConstants) public myConstants: IAppConstants) { }

  public completeAction(): void {
    const val: ITaskActionScheduleWo = this.form.getRawValue();
    let dictionary: IHomDictionary[] = [
      { key: 'dueDateOverride', value: val.dueDateOverride },
      { key: 'dueDateOverrideReason', value: val.dueDateOverrideReason }
    ];
    const taskComplete: ITaskComplete = { projectTaskId: this.projectTaskId, projectTaskActionId: this.projectTaskAction.projectTaskActionId, data: dictionary }
    this.completeEvent.emit(taskComplete);
  }

ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.metaDataService.setFieldDefinitionsManually(this.taskManagerService.fieldDefinitions, {});
    let obj: ITaskActionScheduleWo = { 'dueDateOverride': null, 'dueDateOverrideReason': '' };
    this.form = this.metaDataService.loadDynamicFormGroup(this.displayFields, obj, this.operation);
  }

}
