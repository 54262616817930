import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive()
@Injectable() export class CalendarService {

  public opened$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  singlePicker: boolean;
  currentSelection: string;

  elem: HTMLElement;

  @Output() selected: EventEmitter<string>;

  init(): void {
    this.opened$.next(!this.opened$.value);
  }

}
