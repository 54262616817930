import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { AdminEvent } from '../../enums/admin.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { IWarrantyType } from '../../../view-models';

@Component({
  selector: 'warranty-types',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './warranty-types.component.html'
})
export class WarrantyTypesComponent implements OnInit {
  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.manageWarrantyTypeSubTypes:
        this.openWarrantySubTypes(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadWarrantyTypesListDefinition();
  }

  openWarrantySubTypes(type: IWarrantyType): void {
    this.modalService.open({
      title: 'Sub Types for Warranty Type'.concat(' ', type.warrantyTypeName),
      path: 'warranty-sub-types/' + type.warrantyTypeId.toString(),
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false
    });
  }
}
