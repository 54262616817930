/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'communication-shortcuts',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './communication-shortcuts.component.html'
})
export class CommunicationShortcutsComponent implements OnInit {
  public listDefinition: IListDefinition;

  contactId: number;

  constructor(public activeRoute: ActivatedRoute,
    public adminProviderService: AdminProviderService  ) { }

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
        this.loadListDefinition();
      });
  }

  loadListDefinition() {
    this.listDefinition = this.adminProviderService.loadCommunicationShortcutsListDefinition();
  }

}
