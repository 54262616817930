export interface IPoImportLine {
  poImportLineId: number;
  poImportHeader_poImportHeaderId: number;
  itemName: string;
  quantity: number;
  unitPrice: number;
  unitOfMeasure: string;
  poImportStatus_poImportSatusId: number;
  poImportStatusText: string;
  isCustom: boolean;
  sku_skuId: number;
  unitMeasure_unitMeasureId: number;
  overrideSystemPrice: boolean;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  //extra data
  poImportStatus: string;
  branchName: string;
  branchProgramId: number;
  programName: string;
  poNumber: string;
  userId: number;
  importDate: string;
  userName: string;
  generalContractorId: number;
}

export class PoImportLine implements IPoImportLine {
  poImportLineId: number;
  poImportHeader_poImportHeaderId: number;
  itemName: string;
  quantity: number;
  unitPrice: number;
  unitOfMeasure: string;
  poImportStatus_poImportSatusId: number;
  poImportStatusText: string;
  isCustom: boolean;
  sku_skuId: number;
  unitMeasure_unitMeasureId: number;
  overrideSystemPrice: boolean;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  //extra data
  poImportStatus: string;
  branchName: string;
  branchProgramId: number;
  programName: string;
  poNumber: string;
  userId: number;
  importDate: string;
  userName: string;
  generalContractorId: number;

  constructor() {
    this.poImportLineId = 0;
    this.poImportHeader_poImportHeaderId = null;
    this.itemName = '';
    this.quantity = 0;
    this.unitPrice = 0;
    this.unitOfMeasure = '';
    this.poImportStatus_poImportSatusId = null;
    this.poImportStatusText = '';
    this.isCustom = false;
    this.sku_skuId = null;
    this.unitMeasure_unitMeasureId = null;
    this.overrideSystemPrice = false;

    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';
  }
}
