import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevComponent } from './dev.component';
import { DevRoutingModule } from './dev-routing.module';
import { FwSharedModule } from '../../fw/fw-shared/fw-shared.module';
//import { PortalSharedModule } from '../portals/portal-shared/portal-shared.module';

@NgModule({
    declarations: [
        DevComponent
    ],
    imports: [
      CommonModule, 
      DevRoutingModule,
      FwSharedModule,
      //PortalSharedModule
    ]
})
export class DevModule { }
