export interface IProgramService {
  programServiceId: number;
  service_serviceId: number; 
  program_programId: number;
  lienWaiver: string;
  providerMarginLow: number;
  providerMarginHigh: number;
  measureService_measureServiceId: number; 
  canRequirePermit: boolean;
  allowScheduleBeforeInventory: boolean;

  //extra data fields
  generalContractorId: number;
  generalContractorName: string;
  serviceName: string;
  programServiceName: string;
  measureServiceName: string;
}

export class ProgramService implements IProgramService {
  programServiceId: number;
  service_serviceId: number;
  program_programId: number;
  lienWaiver: string;
  providerMarginLow: number;
  providerMarginHigh: number;
  measureService_measureServiceId: number;
  canRequirePermit: boolean;
  allowScheduleBeforeInventory: boolean;

  //extra data fields
  generalContractorId: number;
  generalContractorName: string;
  serviceName: string;
  programServiceName: string;
  measureServiceName: string;

  constructor() { }
}
