<div class="quick-work-order-tools-cast" *ngIf="isVisible" (click)="toggle($event)"></div>

<ng-template #quickWoTools>
  <div class="quick-work-order-tools">
    <div class="quick-work-order-tools__title">{{ title }}</div>
    <div class="quick-work-order-tools__entity">
      <div class="quick-work-order-tools__entity--id">{{ row.workOrderId }} - </div>
      <div class="quick-work-order-tools__entity--status">{{ row.workOrderStatus }}</div>
    </div>
    <div *ngIf="!row.canEdit" class="quick-work-order-tools__access">Read Only Access</div>
    <div class="quick-work-order-tools__items">
      <ng-container *ngFor="let tool of tools; let i = index">
        <div class="quick-work-order-tools__item"  [ngClass]="{ 'quick-work-order-tools__item--disabled': tool.disabled }"
             (click)="!tool.disabled ? useTool(tool.eventName) : return">
          <div class="quick-work-order-tools__item--btn">
            <button [title]="tool.title"
                    class="app-btn-icon {{tool.cssName }}"
                    [disabled]="tool.disabled"
                    (click)="useTool(tool.eventName)">
              <i class="{{ tool.icon }}"></i>
            </button>
          </div>
          <div class="quick-work-order-tools__item--label" [title]="tool.title">{{ tool.label }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<button type="button"  
        class="app-btn-icon app-btn-icon--primary  quick-work-order-tools--icon "
        fw-popover [template]="quickWoTools" [showPopover]="isVisible" [closeOnClickOutside]="true"
        [title]="title"
        (click)="toggle($event)">
  <i class="fas fa-tools"></i>
</button>
