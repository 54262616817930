import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminEvent } from '../../enums/admin.enums';
import { IProviderLocationBranch, IProviderLocation, IBranch } from '../../../view-models';
import { getSelectedRecord, IAllDynamicData } from '../../../../../fw/dynamic-list/store';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'provider-location-branches',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './provider-location-branches.component.html'
})
export class ProviderLocationBranchesComponent implements OnInit, OnDestroy {

  public listDefinition: IListDefinition;
  public title: string;
  subscription: Subscription = new Subscription();
  branchId: number = 0;
  providerLocationId: number = 0;
  currentBranch: IBranch;
  currentPL: IProviderLocation;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<IAllDynamicData>,
    public adminService: AdminProviderService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.branchId = +paramMap.get('branchId');
      this.providerLocationId = +paramMap.get('plId');
      this.title = this.branchId > 0 ? 'Provider Locations' : 'Branches';
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.showRelatedEntityManager:
        this.openEntityManager(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadProviderLocationBranchesListDefinition(this.branchId, this.providerLocationId);
    const parentListDef = this.branchId > 0
      ? this.adminService.loadBranchesListDefinition()
      : this.adminService.loadProviderLocationsListDefinition();

    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(parentListDef.storeName, -1, parentListDef.rowKeyId,
        this.branchId > 0 ? this.branchId : this.providerLocationId), take(1)))
      .subscribe(entity => {
        this.currentBranch = this.branchId > 0 ? entity : null;
        this.currentPL = this.providerLocationId > 0 ? entity : null; 
      })
    );
  }

  openEntityManager(plb: IProviderLocationBranch): void {
    const parentName: string = this.branchId > 0
      ? 'Branch '.concat(this.currentBranch.branchName)
      : 'Provider Location '.concat(this.currentPL.locationName);
    const parentId: number = this.branchId > 0 ? this.branchId : this.providerLocationId;
    this.adminService.openRelatedEntityManager(this.listDefinition, parentName, parentId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
