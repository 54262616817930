import { Component, OnInit, OnDestroy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { UpdateObjectCustomModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { WorkOrderSurvey, IWorkOrderSurvey } from '../../../view-models/index_two';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';

//store actions, reducers, interfaces
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { AdminDashboardService } from '../../services/admin-dashboard.service';

@Component({
  selector: 'customer-survey',
  templateUrl: './customer-survey.component.html'
})
export class CustomerSurveyComponent implements OnInit, OnDestroy  {
  public objectData: IWorkOrderSurvey;
  public displayFields = ['identiferMetaData', 'identifierId', 'assignedWorkOrderId', 'surveyDate', 'surveyLocked', 'surveyComments'];
  public listDefinition: IListDefinition;

  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  storeName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string;
  detailRoutePath: string = '';
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public adminDashService: AdminDashboardService,
    @Inject(appConstants) public myConstants: IAppConstants) {

    this.subscription.add(emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          default:
            break;
        }
      }));
    }

  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.setDetailConfig(paramMap);

      this.getDetail();
      this.listDefinition = this.adminDashService.loadWorkOrderSurveyResponsesListDefinition(this.objectId);
   }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.storeName, -1, this.key, this.objectId)))
      .subscribe(entity => {
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate
          ? new WorkOrderSurvey() : entity;
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.storeName, -1)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.detailRoutePath = listDefinition.detailRoutePath;
        this.controllerName = listDefinition.controllerName;
      }
    ));
  }

  updateRecord(e: IHomEventEmitter) {
    let data = this.setCommonData(e.data);
    const keyData: IKey = { storeName: this.storeName, parentId: -1, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', data, '', emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  setCommonData(survey: IWorkOrderSurvey): IWorkOrderSurvey {
    let rec = cloneDeep(this.objectData);

    rec.workOrder_workOrderId = survey.hasOwnProperty('assignedWorkOrderId') && survey.assignedWorkOrderId.hasOwnProperty('workOrderId')
      ? survey.assignedWorkOrderId['workOrderId']
      : '';
    return rec;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  } 

}
