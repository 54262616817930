import { Injectable } from '@angular/core';
import { DatePipe } from "@angular/common";
import { UserPriviledgesService } from "../../auth/services";
import { Store } from '@ngrx/store';

import * as fromRoot from '../../app/store/reducers/index';
import { HomLocalStorage } from '../../auth/enums/hom-local-storage.enums';
import { MenuService } from '../../fw/fw-menus/services/menu.service';

@Injectable({
  providedIn: 'root'
})

export class UserSessionEventService {

  constructor(
    public document: Document,
    public datePipe: DatePipe,
    public ups: UserPriviledgesService,
    public menuService: MenuService,
    public rootStore: Store<fromRoot.IState>) { }

  initialized: boolean;

  public init(): void {
    if (this.initialized) return;
    this.initialized = true;
    this.document.onvisibilitychange = () => {
     if (document.visibilityState == 'hidden') {
        if (this.ups.authenticated$.value && localStorage.getItem(HomLocalStorage.SessionId)) {
          localStorage.setItem(HomLocalStorage.HomLogoutEvent, localStorage.getItem(HomLocalStorage.SessionId) + ',' + this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm:ss') + ',' + JSON.parse(localStorage.getItem(HomLocalStorage.HomUser)).providerUser.userId);
        }
      } else if (document.visibilityState == 'visible') {
        if (this.ups.authenticated$.value && localStorage.getItem(HomLocalStorage.HomInstallerPortal)) {
          //logout of the application.  can not be logged into both hom-app and installer portal
          this.menuService.logout();
        }
      }
    }
  }

}
