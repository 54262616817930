import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import {  IErrorData } from '../../../../shared/interfaces/index';

@Component({
  selector: 'fw-error-box',
  templateUrl: './fw-error-box.component.html'
})

//Sets model level of passed in generic error message
export class ErrorBoxComponent implements OnInit, OnChanges {
  @Input() errors: string = '';
  @Input() errorData: IErrorData[] = [];
  @Input() onForm: boolean = true;
  @Input() ignoreKey: boolean = false;
  @Input() joinErrors: boolean = true;

  public errorMessage: string; 

  ngOnInit(): void {
    this.errorMessage = this.getErrorMessage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['errors'] && !(changes['errors'].firstChange)) {
      this.errorMessage = this.getErrorMessage();
    }
    if (changes['errorData'] && !(changes['errorData'].firstChange)) {
      this.errorMessage = this.getErrorMessage();
    }
  }

  getErrorMessage(): string {
    let allErrors = this.errors ? this.errors : '';

    if (this.errorData && this.errorData.length) {
      const modelMessage: IErrorData[] = this.ignoreKey ? this.errorData : this.errorData.filter(x => x['key'] === '__Model');
      if (modelMessage) {
        modelMessage.forEach(e => {
          allErrors += e.value.join(' , ');
        });
      }
    }

    return allErrors;
  }

}
