import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ITabStore } from '../interfaces/i-tab-store';
import { IAppTab } from '../interfaces/i-app-tab';
import { IPortalTab } from '../../../../../app/portals/view-models';
import { ITabRequest } from '../interfaces/index';

import { UserPriviledgesService } from '../../../../../auth/services';

export interface ITabNavigationEnd {
  level: number;
  ended: boolean
}

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  savedTabs: ITabStore[] = [];
  navigationEnd$: BehaviorSubject<ITabNavigationEnd>;
  doNotSetActive: string = 'doNotSetActive';

  constructor(
    public emitterService: HomEventEmitterService,
    public userPrivilegeService: UserPriviledgesService ) {
    this.navigationEnd$ = new BehaviorSubject({ level: -1, ended: false });
  }

  getSavedTabs(portalContainerId: number, routeName: string): ITabStore {
      return this.savedTabs.find(x => x.portalTabContainerId === portalContainerId && x.routeName === routeName);
  }

  saveTabs(portalContainerId: number, parentRouteName: string, tabs: IAppTab[]): void {
    if (!this.userPrivilegeService.currentUserId$.value) {
      //logging out
      return;
    }
    const tabStore: ITabStore = this.getSavedTabs(portalContainerId, parentRouteName);
    if (parentRouteName === '/signin' || parentRouteName === '/') {
      return;
    }
    tabStore ? tabStore.tabs = tabs : this.savedTabs.push({
      portalTabContainerId: portalContainerId,
      routeName: parentRouteName,
      tabs: tabs
    });
    //console.log("SAVED TABS: ", this.savedTabs);
  }

  deleteSavedTab(route: string): void {
    //console.log("DELETING SAVED TAB: ", ('/' + route));
    //console.log("BEFORE: ", this.savedTabs);
    this.savedTabs = this.savedTabs.filter(x => x.routeName !== ('/' + route));
    //console.log("AFTER: ", this.savedTabs);
    /*
    const tabs: ITabStore[] = this.savedTabs.filter(x => x.routeName === '/' + route);
      tabs.forEach(x => {
          const idx = this.savedTabs.findIndex(y => y.portalTabContainerId === x.portalTabContainerId);
          this.savedTabs.splice(idx+1, 1);
      });
    */
  }

  initSavedTabs(): void {
    this.savedTabs = [];
    this.navigationEnd$ = new BehaviorSubject({ level: -1, ended: false });
  }

  //takes a route definition and
  //replaces: portalEntityId, if exists, with the value in the request, if exists
  //replaces: id, if exists, with the value in the request, if exists
  //Dashboard and compliance will not have values in these fields
  public setItemRoute(item: IPortalTab, level: number, portalEntityId: number = null, data: ITabRequest = null): string {
    let tabItem: IPortalTab = Object.assign({}, item);
    let route: string = tabItem.routeName.replace(':portalId', item.managerPortalId.toString())
      .replace(':portalEntityId', (portalEntityId ? portalEntityId : data && data.portalEntityId ? data.portalEntityId : '').toString())
      .replace(':id', data && data[level === 1 ? 'portalEntityId' : ('level' + level + 'EntityId')] ? data[level === 1 ? 'portalEntityId' : ('level' + level + 'EntityId')].toString() : '');

    return route;
  }


  emitTabEvent(request: IHomEventEmitter) { 
        this.emitterService.emitTabEvent(request);
    }

}
