// Resolver if going from outside installer portal into a tab request for a technician
import { Injectable,  OnDestroy} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable,  Subscription, of} from 'rxjs';
import { take, filter, map, mergeMap, tap } from 'rxjs/operators';

//store actions and reducers
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../store/reducers/index';
import * as fromDynamicList from '../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IDynamicListState, IListObjectData } from '../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { metaDataExists } from '../../../../fw/dynamic-list/store/selectors/meta-data.selectors';

import { IFieldDefinition } from '../../../../fw/dynamic-forms/index';
import { IListDefinition, ListDefinition, IListButtonType } from '../../../../fw/dynamic-list/interfaces/index';

import * as LoadingIndicatorActions from '../../../../shared/store/loadingIndicator/loadingIndicator.actions';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';


@Injectable()
export class InstallerWorkCrewTechnicianResolver  implements OnDestroy {
  myStoreName: string = 'workCrewTechnicians'; //there is an installerTechnicians (parent id of installerid) and workCrewTechnicians (parent if of workcrew id)
  operation: string;
  myId: number;
  parentId: number;
  currentUserId: number;
  fieldDefinitions: IFieldDefinition[] = [];
  seedData: any = {};
  selectStoreNames: string[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public dynamicListService: DynamicListService ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const workCrewId = +route.paramMap.get('id');

    this.subscription.add(this.store.pipe(select(fromDynamicList.getListByType(this.myStoreName)), take(1))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == workCrewId)))
      .subscribe((store: IListObjectData) => {
        if (!store || !store.data || store.staleData) {
          //set the bare min for this resolver
          let listDefinition: IListDefinition = this.dynamicListService.createListDefinition('', '',
            'InstallerWorkCrewTechnician', this.myStoreName, 'installerWorkCrewTechnicianId', null, null, '');
          listDefinition.controllerMethod = 'ByInstallerWorkCrew';
          listDefinition.methodParameters = workCrewId.toString();
          listDefinition.parentId = workCrewId;
          listDefinition.parentKey = 'installerWorkCrew_installerWorkCrewId';

          const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
          listDefinition.toolButtons.splice(0, 0, newTools[0]);

          let crudButtons: IListButtonType[] = [
            { type: ButtonType.detail, defaults: null },
            { type: ButtonType.edit, defaults: null },
            { type: ButtonType.delete, defaults: null }
          ];
          listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

          this.store.dispatch(new fromStore.SetListDefinition({ storeName: this.myStoreName, parentId: workCrewId, listDefinition: listDefinition  }));
          this.store.dispatch(new fromStore.GetList({ listDefinition: listDefinition, listFilter: null, parentId: workCrewId }));
        }
      }));
    
    return this.store.select(metaDataExists(this.myStoreName))
      .pipe(
        map((exists: boolean) => {
          return exists;
        }),
        filter((exists: boolean) => exists === true),
        take(1),
        mergeMap(() => {
          return of(true)
        })
      );
  }
 
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
