import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomDecimalPipe } from 'hom-lib/hom-pipes';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IValueChanged } from '../../../../../fw/dynamic-list/services';
import { IErrorData } from '../../../../../shared/interfaces';
import { ChargeBackItem, IChargeBackSkuPrice, ChargeBackSkuPrice, IChargeBackType } from '../../../view-models/index';
import { MetaDataService } from '../../../../../fw/dynamic-list/services/index'
import { HomCommonUtility } from '../../../../../shared/services/hom-common.utility';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { UpdateObjectCustomModel, CreateObjectModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';

@Component({
  selector: 'charge-back-item',
  templateUrl: './charge-back-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MetaDataService]
})

export class ChargeBackItemComponent implements OnInit, OnDestroy {
  @Input() projectId: number;
  @Input() chargeBackId: number;
  @Input() canIEdit: boolean;

  public operation: string;
  public objectData: ChargeBackItem = null;
  public showNext: boolean = false;
  public displayFields: string[] = ['name', 'chargeBackType', 'units', 'unitPrice'];
  public skus: IChargeBackSkuPrice[] = []; // ICHARGEBACKSKUPRICE
  public skuTypes: IChargeBackType[] = []; // ICHARGEBACKTYPE
  public form: FormGroup;
  public itemTotal$: BehaviorSubject<number> = new BehaviorSubject(0);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  listStore: fromDynamicList.IListObjectData;
  chargeBackItemId: number = -1;
  storeName: string;
  key: string = '';
  requestTime: string = ''; 
  controllerName: string = '';
  requestor: string;
  loaded: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public mds: MetaDataService,
    public homDecimalPipe: HomDecimalPipe,
    public utils: HomCommonUtility,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) { }

  public onCancel() {
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public onCreate() {
    const data = this.form.getRawValue();
    let cbi: ChargeBackItem = this.setCommonProperties(data);
    cbi.chargeBack_chargeBackId = this.chargeBackId;
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, this.chargeBackId, this.controllerName, 'Create', cbi, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }

  public onSave(action: string) {
    const data = this.form.getRawValue();
    let cbi: ChargeBackItem = this.setCommonProperties(data);
    const keyData: IKey = { storeName: this.storeName, parentId: this.chargeBackId, key: this.key, id: this.chargeBackItemId }
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventUpdate, action: action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', cbi, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));
  }

ngOnInit() {
    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.chargeBackId = +paramMap.get('id');
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.chargeBackItemId = +paramMap.get('id');
      this.projectId = +paramMap.get('portalEntityId');
      this.key = paramMap.get('key'); 
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.showNext = paramMap.get('showNext') === 'true';
      this.objectData = null;
      this.setDetailConfig(paramMap);
      this.mds.setFieldDefinitions(this.storeName);
      this.newRequest();
    });
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.chargeBackId;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.wrapsForm = true;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }
  newRequest(): void {
    this.subscription.add(this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null))
      .subscribe((obj: IValueChanged) => {
        this.handleValueChanged(obj);
      }));

    //For field level errors
    this.subscription.add(this.store.pipe(select(getListByType(this.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.chargeBackId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.errorData) {
          this.mds.setErrorMessages(state.errorData)
        }
      }));

    this.getDetail();
  }
  //parent id is chargeBackId for this store
  getDetail(): void {
    this.subscription.add(this.store.pipe(
      select(fromStore.getSelectedRecord(this.storeName, this.chargeBackId, this.key, this.chargeBackItemId)))
      .subscribe(entity => {
        const newRequest: boolean = !this.objectData;
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate ? new ChargeBackItem() : entity;
        if (newRequest) {
          this.mds.loadSelectionLists(this.displayFields, this.operation, this.projectId);
          this.skus = this.mds.getSelectItems('name');
          this.skuTypes = this.mds.getSelectItems('chargeBackType');
          this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.objectData, this.myConstants.operationTypeCreate);
          this.calcTotal();
        }
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromStore.getSelectedParentListDefinition(this.storeName, this.chargeBackId)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.controllerName = listDefinition.controllerName;
        this.requestor = listDefinition.detailRoutePath;
      }
      ));
  }

  handleValueChanged(obj: IValueChanged) {
    switch (obj.key) {
      case 'name':
        if (this.utils.isObject(obj.value)) {
          const sku: IChargeBackSkuPrice = obj.value;
          
          let priceCtrl = this.form.controls['unitPrice'];
          if (priceCtrl) {
            priceCtrl.setValue(sku.price.toFixed(2));
          }
         
          let typeCtrl = this.form.controls['chargeBackType'];
          if (typeCtrl) {
            const skuType = this.skuTypes.find(x => x.chargeBackTypeId == sku.chargeBackSku_chargeBackSkuId);
            typeCtrl.setValue(skuType); 
          }
        }
        break;
      case 'units':
      case 'unitPrice':
        this.calcTotal();
        break;
      default:
        break;
    }
  }

  calcTotal(): void {
    let quantity: number = this.form.controls['units'].value;
    let unitPrice: number = this.form.controls['unitPrice'].value;
    this.itemTotal$.next(quantity * unitPrice);
  }

  setCommonProperties(formData: ChargeBackItem): ChargeBackItem {
    let cbi = { ...this.objectData };
    const qtyStr = formData.units.toString();
    const priceStr = formData.unitPrice.toString();
    if (this.utils.isObject(formData.name)) {
      const item: any = formData.name,
        sku: IChargeBackSkuPrice = item as ChargeBackSkuPrice;
      cbi.name = sku.chargeBackSkuName;
      cbi.chargeBackSku_chargeBackSkuId = sku.chargeBackSku_chargeBackSkuId;
    } else {
      cbi.name = formData.name;
      cbi.chargeBackSku_chargeBackSkuId = null;
    }
    cbi.chargeBackType_chargeBackTypeId = formData['chargeBackType']['chargeBackTypeId'];
    cbi.units = !qtyStr ? 0 : parseFloat(this.homDecimalPipe.transform(qtyStr.toString()));
    cbi.unitPrice = !priceStr ? 0 : parseFloat(this.homDecimalPipe.transform(priceStr.toString()));
    return cbi;
  }
 
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}


