import { Component, OnInit,  Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { ISelectList } from '../../../../../shared/interfaces/index';
import {  IProjectCallLogResponse, IProjectCallLogViewModel, IProviderUser } from '../../../view-models/index';
import { IErrorData } from '../../../../../shared/interfaces/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as fromRoot from '../../../../store/reducers/index';
import * as  fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { ProjectListStore } from '../../../project/enums/project.enums';
import { ProviderUserService } from '../../services';
import { MetaDataService } from '../../../../../fw/dynamic-list/services/index'
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ProjectService } from '../../../project/services';

@Component({
  selector: 'work-order-call-logs',
  templateUrl: './work-order-call-logs.component.html',
  providers: [MetaDataService]
})
export class WorkOrderCallLogsComponent implements OnInit, OnDestroy {
  public  projectId: number;
  public workOrderId: number;
  public title: string;
  public displayFields = ['callLogResponse'];
  public noteDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public callLogDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public showNote$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public providerUsers$: BehaviorSubject<IProviderUser[]> = new BehaviorSubject(null);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  action: string = '';
  projectKey: string = 'project_projectId';
  responseTypes: ISelectList[] = [];
  projectCallLogResponse: IProjectCallLogResponse;
  metaLoaded: boolean = false;
  noteCreated: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(public rootStore: Store<fromRoot.IState>,
    public activeRoute: ActivatedRoute,
    public store: Store<fromFeature.IAllDynamicData>,
    public projectService: ProjectService,
    public mds: MetaDataService,
    public modalService: ModalService,
    public providerUserService: ProviderUserService,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) {}


  public initForm(): void {
    this.mds.loadSelectionLists(this.displayFields, this.myConstants.operationTypeCreate, this.projectId);
    this.metaLoaded = true;
  }

  public onCreate(e: IHomEventEmitter): void {
    const def = this.projectService.loadWorkOrderCallLogResponseListDefinition(this.workOrderId);
    const data: IProjectCallLogViewModel = e.data;
    this.noteCreated = data.note !== null;
    const createData = new CreateObjectModel(def.storeName, this.workOrderId, 'ProjectCallLogResponse', 'CreateNew', data, null, e);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
    this.working$.next(true);
  }

  public onCancel(): void {
    this.modalService.close();
  }

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
        this.workOrderId = +paramMap.get('id');
        this.projectId = +paramMap.get('portalEntityId');
        this.title = 'Call Logs for Work Order '.concat(this.workOrderId.toString());

        this.callLogDefinition$.next(this.projectService.loadWorkOrderCallLogResponseListDefinition(this.workOrderId));
        this.noteDefinition$.next(this.projectService.loadWorkOrderNoteListDefinition(this.workOrderId));
      });

      this.initMetaData();
      if (this.metaLoaded) {
        this.initForm();
      }

   //GET FIELD DEFS
    this.subscription.add(this.mds.mdsReady$.pipe(filter(flag => flag === true), take(1))
      .subscribe((ready: boolean) => {
        this.initForm();
      }));

    //subscribe to watch for errors and events 
    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.workOrderCallLogResponses)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId === this.workOrderId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          if (state.event && (!state.errorData || !state.errorData.length)) {
            if (this.noteCreated) {
              const noteDef = this.projectService.loadProjectNoteListDefinition(this.projectId);
              this.store.dispatch(new fromStore.GetList({ listDefinition: noteDef, listFilter: noteDef.defaultListFilter, parentId: this.projectId }));
            }
            let emitter: IHomEventEmitter = {
             requestor: 'work-order-call-logs', event: this.myConstants.emitterEventCallLogged, action: '', data: null
            };
            this.emitterService.emitCallLogEvent(emitter);
            this.store.dispatch(new fromStore.ClearEventList({ storeName: ProjectListStore.workOrderCallLogResponses, parentId: this.workOrderId }));
            this.working$.next(false);
            this.modalService.close();
          }
          if (state.errorData) {
            this.working$.next(false);
            this.errorData$.next(state.errorData);
          }
        }
      }));

    //check to see if need to load provider users to cached store
    this.subscription.add(this.rootStore.select(fromSelectionLists.listExistsByType(this.providerUserService.storeName))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe((exists) => {
        this.rootStore.dispatch(new fromSelectionLists.GetEntityList('ProviderUser', this.providerUserService.defaultListFilter));
      }));

    //listen for provider users
    this.subscription.add(this.rootStore.select(fromSelectionLists.getSelectionListDataByType(this.providerUserService.storeName))
      .pipe(filter((data: IProviderUser[]) => data !== null && data.length > 0), take(1))
      .subscribe((data) => {
        this.providerUsers$.next(data);
      }));
}

  initMetaData(): void {
    this.mds.setFieldDefinitions(ProjectListStore.workOrderCallLogResponses);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
