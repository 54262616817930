import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import {  filter, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

//store actions and reducers
import * as fromRoot from '../../../../store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { ISelectListData } from '../../../../../shared/store/selectionLists/index';
import { ListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';

import { InstallerWorkCrewTechnician, IInstallerWorkCrewTechnician } from '../../../view-models/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { UserPriviledgesService } from '../../../../../auth/services';
import { InstallerStore } from '../../enums/installer.enums';

@Component({
  selector: 'workcrew-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './workcrew-manager.component.html'
})
export class WorkCrewManagerComponent implements OnInit {
  public portalHeader: string = 'Work Crew Manager';
  public installerId: number;
  public providerId: number;
  public workCrewId$: BehaviorSubject<number> = new BehaviorSubject(-1);;
  public installerId$: BehaviorSubject<number> = new BehaviorSubject(-1);;
  public availableTechnicians$: BehaviorSubject<InstallerWorkCrewTechnician[]> = new BehaviorSubject([]);;
  public form: FormGroup;
  public loading$: Observable<boolean>;

  workCrewId: number;
  includeOldestRequirement: boolean = true;
  technicianStore: any;
  subscription: Subscription = new Subscription();

  constructor( public  activeRoute: ActivatedRoute,
    public  rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public userPriviledgesService: UserPriviledgesService,
    @Inject(appConstants) public  myConstants: IAppConstants) {  }

  public onAdd() {
  //on selection of a tech from the list of unattached techs, update the tech record to link tech to this crew
  //dynamic list will see the reload event request and get reload itself
    const formControl = this.form.controls['availableTechnicians'];
    const technicianId: number = formControl.value;

    const availableList = this.availableTechnicians$.value;
    let technician = cloneDeep(availableList.find(x => x.installerWorkCrewTechnicianId == technicianId));
    technician.installerWorkCrew_installerWorkCrewId = this.workCrewId;
    const emitter: IHomEventEmitter = { requestor: 'workcrew-manager', event: this.myConstants.emitterEventListReload, action: '', data: null };

    const createData = new CreateObjectModel('workCrewTechnicians', this.workCrewId, 'InstallerWorkCrewTechnician', 'Update', technician, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }

  ngOnInit() {
    this.form = new FormGroup({ 'availableTechnicians': new FormControl('') });
    this.setFormDisabled();

    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.installerId$.next(+paramMap.get('portalEntityId'));
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.workCrewId$.next(+paramMap.get('id'));
    });

    this.subscription.add(this.workCrewId$.subscribe(val => this.workCrewId = val));
    this.subscription.add(this.installerId$.subscribe(val => {  this.installerId = val;  }));

    this.loading$ = this.rootStore.select('loadingIndicator').pipe(filter(x => x.requestor === InstallerStore.workCrewTechnicians), map(x => x.show));

    this.getAvailableInstallers();

    //subscripe to the selection list store for available installers
    this.subscription.add(this.rootStore.select(fromSelectionLists.getSelectionListByTypeByParent('installerWorkCrewTechnician', this.installerId))
      .subscribe((objData: ISelectListData) => {
        let installerTechnicians = !objData || !objData.data ? null : objData.data.filter(x => x.installer_installerId === this.installerId);
        this.availableTechnicians$.next(installerTechnicians)
        this.setFormDisabled();
      }
      ));

    //subscript to the dynamic list store for installers associated with this tech, on change with reload event, need to get updated list of available installers
    this.subscription.add(this.store.pipe(select(fromStore.getListByType(InstallerStore.workCrewTechnicians)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.workCrewId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.event && state.event.event === this.myConstants.emitterEventListReload) {
          this.getAvailableInstallers();
        }
      }));
  }

  getAvailableInstallers() {
    let listFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.isLookup = false; //needs contactName from extra data
    this.rootStore.dispatch(new fromSelectionLists.GetEntityListById(
       'InstallerWorkCrewTechnician',
       'AvailableByInstaller',
       this.installerId,
       listFilter
    ));
  }

  setFormDisabled(): void {
    const availableTechs: IInstallerWorkCrewTechnician[] = this.availableTechnicians$.value;
    if (!this.userPriviledgesService.canManageInstallers$.value || !availableTechs || availableTechs.length === 0) {
      this.form.controls['availableTechnicians'].disable();
    } else {
      this.form.controls['availableTechnicians'].enable();
    }
  }

}
