<div class="task-manager" [ngClass]="{ 'loading-mask': loading$ | async }">
  <div class="task-manager__btns">
    <div class="task-manager__btns__dev">
      <button type="button"
              class="app-btn-icon app-btn-icon--primary"
              title="Update Project Tasks"
              (click)="updateTasks()">
        <i class="fad fa-list-check task-manager__btns__dev--icon"></i>
      </button>
    </div>
    <div class="task-manager__btns__pending">
      <div class="task-manager__btns--label">Only Show Pending</div>
      <fw-switch-button [defaultChecked]="onlyPending"
                        [title]="'Click to toggle between pending and all'"
                        (toggleEvent)="onSearchTypeToggle($event)">
      </fw-switch-button>
    </div>
  </div>
  <div *ngIf="(tasks$ | async) as tasks">
    <ng-container *ngFor="let task of tasks; let i = index">
      <task [projectTask]="task"
            [index]="i"
            [canIEdit]="canIEdit"
            (completeEvent)="onComplete($event)"
            (deleteEvent)="onDelete($event)">
      </task>
    </ng-container>
    <div *ngIf="(pageMetaData$ | async) as pageMetaData" class="task__pager">
      <pagination [restrict]="false"
                  [config]="pageMetaData"
                  (action)="onPageChanged(e = $event)">
      </pagination>
    </div>
  </div>
  <div *ngIf="!(loading$ | async) && !(tasks$ | async)?.length" class="task__no-data">
    No Records Found
  </div>
  <fw-error-box [errorData]="errorData$ | async"
                [ignoreKey]="true">
  </fw-error-box>
  <div *ngIf="(tasks$ | async) && showCloseAll" class="app-btn-box">
    <button type="button"
            class="app-btn app-btn--primary"
            [disabled]="!canIEdit"
            (click)="closePending()">
      Close Pending
    </button>
    <button type="button"
            class="app-btn app-btn--primary"
            [disabled]="!canIEdit"
            (click)="deletePending()">
      Delete Pending
    </button>
  </div>
</div>
