
export interface IPrivilegeTemplatePrivilege {
  privilegeTemplatePrivilegeId: number;
  privilegeTemplate_privilegeTemplateId: number;
  privilegeId: string;
  privilegeAccessLevel: string;
  privilegeId_privilegeIdId: number;
  privilegeAccessLevel_privilegeAccessLevelId: number;
  privilegeTemplateName: string;
  privilegeDefinitionType: string;
}

export class PrivilegeTemplatePrivilege implements IPrivilegeTemplatePrivilege {
  privilegeTemplatePrivilegeId: number;
  privilegeTemplate_privilegeTemplateId: number;
  privilegeId: string;
  privilegeAccessLevel: string;
  privilegeId_privilegeIdId: number;
  privilegeAccessLevel_privilegeAccessLevelId: number;
  privilegeTemplateName: string;
  privilegeDefinitionType: string;

  constructor() { }

}
