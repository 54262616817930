import { Component, OnInit, Input, Inject, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IWorkOrderViewModel, ISlotScheduleCalendar } from '../../../view-models';
import { UtilitiesEvent } from '../../enums/utilities.enums';
import { IWeekViewData } from '../../interfaces';

import { DispatchCalendarService } from '../../services/dispatch-calendar.service';
import { HomCommonUtility } from '../../../../../shared/services';

@Component({
  selector: 'dispatch-week-view',
  templateUrl: './dispatch-week-view.component.html'
})
export class DispatchWeekViewComponent implements OnInit, OnChanges {
  @Input() data: IWorkOrderViewModel[];
  @Input() selectedDate: string;
  @Input() holidays: string[];
  @Input() canScheduleLockDays: boolean;
  @Input() slotCalendars: ISlotScheduleCalendar[];

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public days$: BehaviorSubject<IWeekViewData[]> = new BehaviorSubject([]);
  public myView: string;

  constructor(public datePipe: DatePipe,
    public dispatchCalendarService: DispatchCalendarService,
    public homCommonUtility: HomCommonUtility,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public goTo(day: string): void {
    const emitter: IHomEventEmitter = {
      requestor: 'dispatch-week-view',
      event: UtilitiesEvent.goToDay,
      data: day,
      action: ''
    }
    this.customEvent.emit(emitter);
  }

  public lockDay( day: string): void {
    const emitter: IHomEventEmitter = {
      requestor: 'dispatch-week-view',
      event: UtilitiesEvent.showLockDayManager,
      data: day,
      action: ''
    }
    this.customEvent.emit(emitter);
  }

  public printPackets(day: string): void {
    const emitter: IHomEventEmitter = {
      requestor: 'dispatch-week-view',
      event: UtilitiesEvent.showWoPacketsForDay,
      data: this.getDataForDay(day),
      action: ''
    }
    this.customEvent.emit(emitter);

  }

  public onCustom(event: IHomEventEmitter): void {
    this.customEvent.emit(event);
  }

  ngOnInit() {
    this.myView = this.dispatchCalendarService.viewWeek;
    this.setData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && !(changes['data'].firstChange)) {
      this.setData();
    }
  }

  setData(): void {
    if (!this.selectedDate) {
      return;
    }

    let current: Date = this.homCommonUtility.getStartOfWeek(this.selectedDate);
    let days: string[] = [];
    days.push(this.datePipe.transform(current, 'MM/dd/yyyy'));

    while (days.length <= 6) {
      current = current.addDays(1);
      days.push(this.datePipe.transform(current, 'MM/dd/yyyy').toString());
    }

    let data: IWeekViewData[] = [];
    days.forEach(day => {
      data.push({
        day: day,
        isLocked: this.isDayLocked(day),
        canEdit: true,
        data: this.getDataForDay(day),
      })
    });
    this.days$.next(data);
  }

  getDataForDay(day: string): IWorkOrderViewModel[] {
    const thisDay = this.datePipe.transform(new Date(day), 'MM/dd/yyyy');
    const data = this.data.filter(x => thisDay >= this.datePipe.transform(x.scheduleStartDate, 'MM/dd/yyyy') && thisDay <= this.datePipe.transform(x.scheduleEndDate, 'MM/dd/yyyy'));
    return data ? data : [];
  }

  //TODO:  need list of slotScheduleCalendar records for the active filter
  isDayLocked(day: string): boolean {
      const dayData = this.slotCalendars.find(x => this.datePipe.transform(new Date(x.day), 'MM/dd/yyyy') == day);
      return dayData && dayData.isLocked;
    }
}
