import { Component, Input,  OnInit, OnDestroy, ChangeDetectionStrategy, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription, BehaviorSubject} from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { TaskSearchType } from '../../services/task-manager.service';

import { IProjectTaskViewModel, IProjectTaskActionViewModel, IProjectTaskAction, ProjectTaskAction } from '../../../view-models/index';
import { IErrorData, IResponseBase, IPageMetaData } from '../../../../../shared/interfaces/index';
import {  IListFilter } from '../../../../../fw/dynamic-list/interfaces/index';
import { DomainObjectService } from '../../../../../shared/services/index';
import { TaskManagerService, ITaskComplete } from '../../services/task-manager.service';
import { ProjectService } from '../../../project/services';
import { SearchType } from '../../../../../fw/dynamic-list/enums/search-type.enums';
import { AccessLevel } from '../../../../../fw/dynamic-list/enums/access-level.enums';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'task-manager',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './task-manager.component.html'
})
export class TaskManagerComponent implements OnInit, OnChanges, OnDestroy  {
  @Input() projectId: number;
  @Input() projectStatus: string;
  @Input() canIEdit: boolean;
  @Input() defaultPending: boolean;
  @Input() showCloseAll: boolean;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public tasks$: BehaviorSubject<IProjectTaskViewModel[]> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  public pageMetaData$: BehaviorSubject<IPageMetaData> = new BehaviorSubject(null);
  public listFilter$: BehaviorSubject<IListFilter> = new BehaviorSubject(null);
  onlyPending: boolean = true;
  initialLoad: boolean = true;
  subscription: Subscription = new Subscription();
  taskSub: Subscription = new Subscription();
  taskEventSub: Subscription = new Subscription();
  listFilterSub: Subscription = new Subscription();

  constructor(
    public domainObjectService: DomainObjectService,
    public taskManagerService: TaskManagerService,
    public emitterService: HomEventEmitterService,
    public projectService: ProjectService,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) { }


  ngOnInit(): void {

    this.subscription.add(this.tasks$.subscribe((tasks: IProjectTaskViewModel[]) => {
      if (tasks !== null) {
        let regetProject: boolean = false;
        if (this.onlyPending && !tasks.length && this.projectStatus !== this.myConstants.projectStatusClosed) {
          regetProject = !this.initialLoad;
        } else if (tasks.length > 0 && tasks[0].projectStatus !== this.projectStatus) {
          regetProject = !this.initialLoad;
        }
        if (regetProject) {
          this.projectService.dispatchProjectObject(this.projectId);
        }
      }
    }));

    if (!this.taskManagerService.projectData.find(x => x.projectId === this.projectId)) {
      this.newRequest();
    } else {
      this.tasks$.next(this.taskManagerService.projectData.find(x => x.projectId === this.projectId).data);
      this.initialLoad = false;
      this.onlyPending = this.taskManagerService.projectData.find(x => x.projectId === this.projectId).listFilter.searchTerm[0].value === TaskSearchType.showPending;
      this.pageMetaData$.next(this.taskManagerService.projectData.find(x => x.projectId === this.projectId).pageMetaData || null);
      this.listFilter$.next(this.taskManagerService.projectData.find(x => x.projectId === this.projectId).listFilter);
      this.listFilterSub = this.listFilter$.subscribe(x => {
        if (this.initialLoad) this.getData();
      });
      this.taskEventSub = this.emitterService.taskEventEmitted$.subscribe((e: IHomEventEmitter) => {
        this.initialLoad = true;
        this.getData();
      });
    }
  }

  public onSearchTypeToggle(val: boolean) {
    let listFilter = this.listFilter$.getValue();
    this.onlyPending = val;
    this.initialLoad = true;
    listFilter.currentPage = 1;
    listFilter.searchTerm = [{ term: 'searchType', value: val ? TaskSearchType.showPending : TaskSearchType.showAll, searchType: SearchType.Equals, columnName: 'searchType', fieldType: this.myConstants.dataTypeString }];
    this.listFilter$.next(listFilter);
  }

  public onPageChanged(pageNumber: number): void {
    this.initialLoad = true;
    let listFilter = this.listFilter$.getValue();
    if (pageNumber > 0) {
      listFilter.currentPage = pageNumber;
      this.listFilter$.next(listFilter);
    }
  }

  public closePending(): void {
    this.loading$.next(true);
    this.subscription.add(this.domainObjectService.updateByMethod('ProjectTask', 'ClosePending', { projectId: this.projectId })
      .subscribe((response: IResponseBase) => {
        this.loading$.next(false);
        if (response.success) {
       this.getData();
          this.projectService.dispatchProjectObject(this.projectId);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  public deletePending(): void {
    this.loading$.next(true);
    this.subscription.add(this.domainObjectService.updateByMethod('ProjectTask', 'DeletePending', { projectId: this.projectId })
      .subscribe((response: IResponseBase) => {
        this.loading$.next(false);
        if (response.success) {
       this.getData();
          this.projectService.dispatchProjectObject(this.projectId);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  public onDelete(projectTaskId: number): void {
    this.loading$.next(true);
    this.subscription.add(this.domainObjectService.deleteByMethodById('ProjectTask', 'Delete', projectTaskId)
      .subscribe((response: IResponseBase) => {
        this.loading$.next(false);
        if (response.success) {
         this.getData();
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  public onComplete(completeEvent: ITaskComplete): void {
    this.loading$.next(true);
    const projectTask: IProjectTaskViewModel = this.tasks$.getValue().find(x => x.projectTaskId == completeEvent.projectTaskId);
    const projectTaskAction: IProjectTaskActionViewModel = projectTask.projectTaskActions.find(x => x.projectTaskActionId == completeEvent.projectTaskActionId);
    if (!projectTaskAction) {
      console.log('DEV ERROR:  Complete cancelled no projectTaskAction record', projectTaskAction);
    }
    let updatedObj: IProjectTaskAction = this.setProjectTaskAction(completeEvent.projectTaskActionId, projectTaskAction);

    let obj = {
      'id': this.projectId.toString(),
      'projectTaskAction': updatedObj,
      'taskActionExtraData': completeEvent.data
    }
    this.subscription.add(this.domainObjectService.updateByMethod('ProjectTaskAction', 'Complete', obj)
      .subscribe((response: IResponseBase) => {
        this.loading$.next(false);
        if (response.success) {
        this.getData();
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  //only visible if have Developer access at this time
  public updateTasks(): void {
    this.loading$.next(true);
    this.subscription.add(this.domainObjectService.updateByMethodById('ProjectTask', 'UpdateProjectTasks', this.projectId)
      .subscribe((response: IResponseBase) => {
        this.loading$.next(false);
        if (response.success) {
          this.getData();
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }


  getData(): void {
    this.loading$.next(true);
    if (this.taskSub) {
      this.taskSub.unsubscribe();
    }
    this.taskSub = this.domainObjectService.getByMethodById('ProjectTaskViewModel', 'ByProject', this.projectId, this.listFilter$.getValue()).subscribe((response: IResponseBase) => {
      this.errorData$.next(null);
      if (response.success) {
        let data: IProjectTaskViewModel[] = response.data;
        this.tasks$.next(data);
        this.taskManagerService.addProjectData(this.projectId, data, this.listFilter$.value, response.metaData.pageMetaData || null);
        this.taskManagerService.setMetaData(response.metaData);
        this.pageMetaData$.next(response.metaData && response.metaData.hasOwnProperty('pageMetaData') ? response.metaData.pageMetaData : null);
      } else {
        this.errorData$.next(response.errorData);
      }
      this.loading$.next(false);
      this.initialLoad = false;
    });
  }

  newRequest(): void {
    this.loading$.next(true);
    if (this.listFilterSub) {
      this.listFilterSub.unsubscribe();
    }
    if (this.taskEventSub) {
      this.taskEventSub.unsubscribe();
    }
    this.listFilter$.next({
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false,
      currentPage: 1,
      searchTerm: [{ term: 'searchType', value: TaskSearchType.showPending, searchType: SearchType.Equals, columnName: 'searchType', fieldType: this.myConstants.dataTypeString }],
      orderTerm: null
    });
    this.listFilterSub = this.listFilter$.subscribe(x => {
      this.initialLoad = true;
      this.getData();
    });
    this.taskEventSub = this.emitterService.taskEventEmitted$.subscribe((e: IHomEventEmitter) => {
      this.initialLoad = true;
      this.getData();
    });
  }


  setProjectTaskAction(projectTaskId: number, projectTaskAction: IProjectTaskActionViewModel): IProjectTaskAction {
    let working: IProjectTaskAction = new ProjectTaskAction();
    working.projectTaskActionId = projectTaskAction.projectTaskActionId;
    working.projectTask_projectTaskId = projectTaskId;
    working.taskAction_taskActionId = projectTaskAction.taskActionId;
    working.actionCompletedDate = projectTaskAction.actionCompletedDate;
    working.actionSuccessful = projectTaskAction.actionSuccessful;
    working.actionMessage = projectTaskAction.actionMessage;
    working.actionAutoClosed = projectTaskAction.actionAutoClosed;
    working.entityPkId = projectTaskAction.entityPkid;
    working.rowVersion = projectTaskAction.rowVersion;
    working.aggregateRootVersion = projectTaskAction.aggregateRootVersion;
    return working;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] && !(changes['projectId'].firstChange)) {
      this.newRequest();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.taskSub.unsubscribe();
    this.taskEventSub.unsubscribe();
    this.listFilterSub.unsubscribe();
  }

}
