export enum PricingStore {
  skuPrices = 'skuPrices',
  skus = 'skus',
  payouts = 'payouts',
  providerPayouts = 'providerPayouts',
  payoutSkus = 'payoutSkus',
  payoutPrices = 'payoutPrices'
}

export enum PricingEvent {
  selectSkuToChange = 'selectSkuToChange',
  deckPriceChange = 'deckPriceChange', //value used in seeding, do not change
  viewSkuPayouts = 'viewSkuPayouts',
  managePayoutPrices = 'managePayoutPrices'
}

export enum SkuCreationWizardEvent {
  createSku = 'createSku',
  skuCreated = 'skuCreated',
  skuPriceCreated = 'skuPriceCreated',
  selectPayout = 'selectPayout',
  payoutLinked = 'payoutLinked',
  createPayout = 'createPayout',
  payoutCreated = 'payoutCreated',
  payoutPriceCreated = 'payoutPriceCreated',
  cancel = 'cancel',
}

export enum SkuCreationWizardAction {
  close = 'close',
  next = 'next'
}

export enum SkuCreationWizardOrigin {
  skuXmlMapping = 'skuXmlMapping',
  skus = 'skus',
  skuPayout = 'skuPayout'
}


