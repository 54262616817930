<div [ngClass]="{ 'loading-mask': !(ready$ | async) }">
  <fw-error-box [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
  <detail-contact-manager *ngIf="(ready$ | async)"
                          [canIEdit]="canIEdit$ | async"
                          [contactId]="contactId$ | async"
                          [isOrganization]="false"
                          [contactTypeId]="contactConstantsService.contactTypeIdCustomer"
                          [checkForMatch]="!linkedContact"
                          [seedData]="poImportContactData"
                          [linkedContact]="linkedContact"
                          [isPoImport]="true"
                          [allowUnlink]="true"
                          (crudEvent)="onCrudEvent($event)">
  </detail-contact-manager>
  <fw-error-box [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>

