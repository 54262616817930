import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import {  filter, take, map } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomDecimalPipe } from 'hom-lib/hom-pipes';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IValueChanged } from '../../../../../fw/dynamic-list/services';
import { WorkOrderItem, IPayoutPriceForWoLookup, IUnitMeasure, PayoutPriceForWoLookup } from '../../../view-models/index';
import { MetaDataService } from '../../../../../fw/dynamic-list/services/index'
import { HomCommonUtility } from '../../../../../shared/services/hom-common.utility';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import {  UpdateObjectCustomModel, CreateObjectModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  selector: 'work-order-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './work-order-item.component.html',
  providers: [MetaDataService]
})
export class WorkOrderItemComponent implements OnInit, OnDestroy  {
  public showNext: boolean = false;
  public objectData: WorkOrderItem = null;
  public operation: string;
  public displayFields: string[] = ['itemName', 'quantity', 'unitMeasure', 'unitPrice'];
  public payouts: IPayoutPriceForWoLookup[] = [];
  public unitMeasures: IUnitMeasure[] = [];
  public form: FormGroup;
  public itemTotal$: BehaviorSubject<number> = new BehaviorSubject(0);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  listStore: fromDynamicList.IListObjectData;
  projectId: number = -1;
  workOrderId: number = -1;
  workOrderItemId: number = -1;
  storeName: string = '';
  key: string = '';
  requestTime: string = '';
  controllerName: string = '';
  requestor: string;
  loaded: boolean = false;
  subscription: Subscription = new Subscription();
  detailLoaded: boolean = false;

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public mds: MetaDataService,
    public homDecimalPipe: HomDecimalPipe,
    public utils: HomCommonUtility,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {}

  public onCancel() {
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public onCreate() {
    const data = this.form.getRawValue();
    let woi: WorkOrderItem = this.setCommonProperties(data);
    woi.workOrder_workOrderId = this.workOrderId;
    woi.createDate = new Date().toDateString();

    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, this.workOrderId, this.controllerName, 'Create', woi, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }

  public onSave(action: string) {
    const data = this.form.getRawValue();
    let woi: WorkOrderItem = this.setCommonProperties(data);
    const keyData: IKey = { storeName: this.storeName, parentId: this.workOrderId, key: this.key, id: this.workOrderItemId }
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventUpdate, action: action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', woi, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));
  }

  ngOnInit() {
    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.workOrderId = +paramMap.get('id');
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.workOrderItemId = +paramMap.get('id');
      this.projectId = +paramMap.get('portalEntityId');
      this.key = paramMap.get('key');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.showNext = paramMap.get('showNext') === 'true';
      this.objectData = null;
      this.setDetailConfig(paramMap);
      this.mds.setFieldDefinitions(this.storeName);
      this.getDetail();
    });

    this.subscription.add(this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null))
      .subscribe((obj: IValueChanged) => {
        this.handleValueChanged(obj);
      }));

    //For field level errors
    this.subscription.add(this.store.pipe(select(getListByType(this.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.workOrderId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.errorData) {
          this.mds.setErrorMessages(state.errorData)
        }
      }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.workOrderId;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.wrapsForm = true;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  //parent id is workOrderId for this store
  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromStore.getSelectedRecord(this.storeName, this.workOrderId, this.key, this.workOrderItemId)))
      .subscribe(entity => {
        const newRequest: boolean = !this.objectData;
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate ? new WorkOrderItem() : entity;
        if (newRequest) {
          this.mds.loadSelectionLists(this.displayFields, this.operation, this.workOrderId);
          this.payouts = this.mds.getSelectItems('itemName');
          this.unitMeasures = this.mds.getSelectItems('unitMeasure');
          this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.objectData, this.myConstants.operationTypeCreate);
          this.calcTotal();
        }
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromStore.getSelectedParentListDefinition(this.storeName, this.workOrderId)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.controllerName = listDefinition.controllerName;
        this.requestor = listDefinition.detailRoutePath;
     }
    ));
  }

  handleValueChanged(obj: IValueChanged) {
    switch (obj.key) {
      case 'itemName':
        if (this.detailLoaded) {
          if (this.utils.isObject(obj.value)) {
            const payout: IPayoutPriceForWoLookup = obj.value;
            let priceCtrl = this.form.controls['unitPrice'];
            if (priceCtrl) {
              priceCtrl.setValue(payout.billingPrice);
            }
            let uomCtrl = this.form.controls['unitMeasure'];
            if (uomCtrl) {
              const uom = this.unitMeasures.find(x => x.unitMeasureId == payout.unitMeasureId);
              uomCtrl.setValue(uom);
            }
          } else {
            if (this.form.controls['itemName'].value) {
              this.form.controls['unitPrice'].reset();
              this.form.controls['unitMeasure'].reset();
            }
          }
        } else {
          this.detailLoaded = true;
        }
        break;
      case 'quantity':
      case 'unitPrice':
        this.calcTotal();
        break;
      default:
        break;
    }
  }

  calcTotal(): void {
    let quantity: number = this.form.controls['quantity'].value;
    let unitPrice: number = this.form.controls['unitPrice'].value;
    //if type 9 at end of 1.23, will come in this change event as 1.239, so need to call pipe first
    const qtyVal: number = !quantity ? 0 : parseFloat(this.homDecimalPipe.transform(quantity.toString()));
    const priceVal: number = !unitPrice ? 0 : parseFloat(this.homDecimalPipe.transform(unitPrice.toString()));
    this.itemTotal$.next(qtyVal * priceVal);
  }

  setCommonProperties(formData: WorkOrderItem): WorkOrderItem {
    let woi = { ...this.objectData };

    if (this.utils.isObject(formData.itemName)) {
      const item: any = formData.itemName;
      const payout: IPayoutPriceForWoLookup = item as PayoutPriceForWoLookup;
      woi.itemName = payout.payoutName;
      woi.payout_payoutId = payout.payoutId;
      woi.payoutDeck_payoutDeckId = payout.payoutDeckId;
    } else {
      woi.itemName =  formData.itemName;
      woi.payout_payoutId =  null;
      woi.payoutDeck_payoutDeckId = null;
    }

    woi.unitMeasure_unitMeasureId = formData.hasOwnProperty('unitMeasure') && formData['unitMeasure']
        && formData['unitMeasure'].hasOwnProperty('unitMeasureId')
      ? formData['unitMeasure']['unitMeasureId']
      : null;
    const qtyStr = !formData.quantity ? '0' : formData.quantity.toString();
    const priceStr = !formData.unitPrice ? '0' : formData.unitPrice.toString();
    woi.quantity = !qtyStr ? 0 : parseFloat(this.homDecimalPipe.transform(qtyStr.toString()));
    woi.unitPrice = !priceStr ? 0 : parseFloat(this.homDecimalPipe.transform(priceStr.toString()));

    woi.updateDate = new Date().toDateString();

    return woi;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
