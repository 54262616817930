import { Component, OnInit,  Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IProviderUserPrivilegeViewModel } from '../../../view-models';
import { AdminEvent } from '../../enums/admin.enums';
import { IAddUserAccessItem, IAddUserAccessItemKey } from '../../services/admin-security.service';

@Component({
  selector: 'user-access-create-sub-item',
  templateUrl: './user-access-create-sub-item.component.html'
})
export class UserAccessCreateSubItemComponent implements OnInit, OnChanges  {
  @Input() item: IProviderUserPrivilegeViewModel;
  @Input() name: string;
  @Input() parentIsSelected: boolean = false;
  @Input() parentSelectAll: boolean = false;

  @Output() public selectEvent = new EventEmitter<IHomEventEmitter>();

  public isSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  requestor: string = 'user-access-create-sub-item';

  constructor() { }

  public selectItem(event): void {
    this.emitSelect(!this.isSelected$.value);
  }

  emitSelect(val: boolean): void {
    let key: IAddUserAccessItemKey = { id: this.item['entityId'], key: 'entityId' };
    const data: IAddUserAccessItem = {
      add: val,
      key: key,
      item: this.item
    }
    this.isSelected$.next(val);
    this.selectEvent.emit({ requestor: this.requestor, event: AdminEvent.selectNewAccess, action: null, data: data });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parentSelectAll'] && !(changes['parentSelectAll'].firstChange)) {
      if (this.parentSelectAll || (!this.parentSelectAll && this.isSelected$.value)) {
        this.emitSelect(this.parentSelectAll);
      }
    }

    if (changes['parentIsSelected'] && !(changes['parentIsSelected'].firstChange)) {
      //select can be selected outside of select all - if one item is selected - the check box shows selected but with a distinct css.
      //special case:  select all is false and item is selected - so parent is selected in a non-select all status
      //then unclick parent - parentSelectAll will not trigger as it didn't change - still false.
      if ((!this.parentIsSelected && this.isSelected$.value)) {
        this.emitSelect(this.parentIsSelected);
      }
    }
  }

}
