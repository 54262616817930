import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { take  } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { IListDefinition, ListDefinition, IListColumn, ListColumn, IListFilter, ListFilter, OrderTerm } from '../../../../../../fw/dynamic-list/interfaces/index';

//store actions and reducers
import * as fromRoot from '../../../../../store/reducers/index';
import * as fromAuth from '../../../../../../auth/store/auth.reducer';
import { DynamicListService } from '../../../../../../fw/dynamic-list/services';

@Component({
  selector: 'user-delegates',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-delegates.component.html'
})
export class UserDelegatesComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;
  authState: fromAuth.IState;

  subscription: Subscription = new Subscription();

  constructor(public rootStore: Store<fromRoot.IState>,
    public dynamicListService: DynamicListService ) { }

  ngOnInit() {
    this.subscription.add(this.rootStore.select('auth')
      .pipe(take(1))
      .subscribe((authState: fromAuth.IState) => {
        this.authState = authState;
        this.loadListDefinition();
      }));
  }

  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Delegate Schedules';
    const listObjectController = 'UserDelegate';
    const listStoreName = 'userDelegateSchedules';
    const detailRoutePath = 'user-delegate';
    const listRowKeyId = 'userDelegateId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('delegateName')];

    let listDefinition = this.dynamicListService.createListDefinition(null,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.methodParameters = this.authState.providerUser.currentUserId.toString();
    listDefinition.showFilter = false;

    //no crud buttons
    this.listDefinition = listDefinition;
  }

  /*
      Define the columns in the list to be displayed.  Columns pulled back are set by the db.
      May change this to be defined in the db.
  */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    //?actionDescription needed
    let colDef = new ListColumn('delegateeName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('jobScheduleDescription');  //needs a mini description for small
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('delegatorName');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
