import { Action } from '@ngrx/store';

import { IResponseBase, IErrorData } from '../../../../shared/interfaces/index';
import { IDetailRequest } from '../../../dynamic-detail/interfaces/index';
import { CreateObjectModel, 
  UpdateObjectByIdModel, 
  DeleteObjectModel, 
  IKey, 
  UpdateObjectCustomModel, 
  UpdateObjectByUrl  } 
  from '../interfaces/index';

import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

//** ENSURE THESE actions are UNIQUE across ALL actions in the ENTIRE project **//
//** Add namespace by prefixing with [store type]
export enum DynamicObjectActionTypes {
  GET_OBJECT = '[DynamicObject] GET_OBJECT', 
  SET_OBJECT = '[DynamicObject] SET_OBJECT',

  CREATE_OBJECT_OBJ= '[DynamicObject] CREATE_OBJECT_OBJ',
  CREATE_COMPLETE_OBJ = '[DynamicObject] CREATE_COMPLETE_OBJ',
  UPDATE_OBJECT_BY_ID_OBJ = '[DynamicObject] UPDATE_OBJECT_BY_ID_OBJ',
  UPDATE_OBJECT_BY_URL_OBJ = '[DynamicObject] UPDATE_OBJECT_BY_URL_OBJ',
  UPDATE_OBJECT_CUSTOM_OBJ = '[DynamicObject] UPDATE_OBJECT_CUSTOM_OBJ',
  UPDATE_COMPLETE_OBJ = '[DynamicObject] UPDATE_COMPLETE_OBJ',
  DELETE_OBJECT_BY_URL_OBJ = '[DynamicObject] DELETE_OBJECT_BY_URL_OBJ', 
  DELETE_COMPLETE_OBJ = '[DynamicObject] DELETE_COMPLETE_OBJ', 

  SET_WORKING_OBJ = '[DynamicObject] SET_WORKING_OBJ',
  CLEAR_EVENT_OBJ = '[DynamicObject] CLEAR_EVENT_OBJ',
  CLEAR_ERRORS_OBJ = '[DynamicObject] CLEAR_ERRORS_OBJ',

  INITIALIZE_OBJECT = '[DynamicObject] INITIALIZE_OBJECT', 

  SET_ERROR_RETURNED_OBJ = '[DynamicObject] SET_ERROR_RETURNED_OBJ', 
  CHECK_FOR_STALE_DATA_OBJ = '[DynamicObject] CHECK_FOR_STALE_DATA_OBJ', 
  SET_STALE_DATA_CHECK_RESULT_OBJ = '[DynamicObject] SET_STALE_DATA_CHECK_RESULT_OBJ', 
  RESET_ALL_OBJECT_STORES = '[DynamicObject] RESET_ALL_OBJECT_STORES'
}

//fetch types direct us to what method to call and what properties needed to make that call
//for all list retrievals will have filter data
//if have incoming params for this action, include in the constructor for the class for that action

export class SetObject implements Action {
  readonly type = DynamicObjectActionTypes.SET_OBJECT;
  constructor(
    public payload: { storeName: string, objectId: number, data: any, errorData: IErrorData[], error: string, event: IHomEventEmitter }
  ) {}
}

export class GetObject implements Action {
  readonly type = DynamicObjectActionTypes.GET_OBJECT;
  constructor(
    public payload: { detailRequest: IDetailRequest, event?: IHomEventEmitter }
  ) { }
}

export class CreateObjectObj implements Action {
  readonly type = DynamicObjectActionTypes.CREATE_OBJECT_OBJ;
  constructor(
    public payload: {  createData: CreateObjectModel }  //create data  has storeName
  ) { }
}

export class CreateCompleteObj implements Action {
  readonly type = DynamicObjectActionTypes.CREATE_COMPLETE_OBJ;
  constructor(
    public payload: { storeName: string, objectId: number, responseBase: IResponseBase, event: IHomEventEmitter} 
  ) { }
}

export class UpdateObjectByIdObj implements Action {
  readonly type = DynamicObjectActionTypes.UPDATE_OBJECT_BY_ID_OBJ;
  constructor(
    public payload: { updateData: UpdateObjectByIdModel }
  ) { }
}

export class UpdateObjectByUrlObj implements Action {
  readonly type = DynamicObjectActionTypes.UPDATE_OBJECT_BY_URL_OBJ;
  constructor(
    public payload: { updateData: UpdateObjectByUrl}
  ) { }
}

export class UpdateObjectCustomObj implements Action {
  readonly type = DynamicObjectActionTypes.UPDATE_OBJECT_CUSTOM_OBJ;
  constructor(
    public payload: { updateData: UpdateObjectCustomModel }
  ) { }

}

export class UpdateCompleteObj implements Action {
  readonly type = DynamicObjectActionTypes.UPDATE_COMPLETE_OBJ;
  constructor(
    public payload: { keyData: IKey, responseBase: IResponseBase, event: IHomEventEmitter } 
  ) { }

}

export class DeleteObjectByUrlObj implements Action {
  readonly type = DynamicObjectActionTypes.DELETE_OBJECT_BY_URL_OBJ;
  constructor(
    public payload: { deleteData: DeleteObjectModel } 
  ) { }

}

export class DeleteCompleteObj implements Action {
  readonly type = DynamicObjectActionTypes.DELETE_COMPLETE_OBJ;
  constructor(
    public payload: { keyData: IKey, responseBase: IResponseBase } 
  ) { }

}


export class SetWorkingObj implements Action {
  readonly type = DynamicObjectActionTypes.SET_WORKING_OBJ;
  constructor(
    public payload: { storeName: string, objectId: number }
  ) { }
}

export class SetErrorReturnedObj implements Action {
  readonly type = DynamicObjectActionTypes.SET_ERROR_RETURNED_OBJ;
  constructor(
    public payload: { storeName: string, objectId: number, data: any, errorData: IErrorData[], error: string, event: any, accessError: boolean }
  ) { }
}


export class InitializeObject implements Action {
  readonly type = DynamicObjectActionTypes.INITIALIZE_OBJECT;
  constructor(
    public payload: { storeName: string, objectId: number }
  ) { }
}

export class ClearEventObj implements Action {
  readonly type = DynamicObjectActionTypes.CLEAR_EVENT_OBJ;
  constructor(
    public payload: { storeName: string, objectId: number }
  ) { }
}

export class ClearErrorsObj implements Action {
  readonly type = DynamicObjectActionTypes.CLEAR_ERRORS_OBJ;
  constructor(
    public payload: { storeName: string, objectId: number }
  ) { }
}

export class CheckForStaleDataObj implements Action {
  readonly type = DynamicObjectActionTypes.CHECK_FOR_STALE_DATA_OBJ
  constructor(
    public payload: { storeName: string, objectId: number, entityId: number, aggregateRootVersion: string,  rowVersion: string}
  ) { }
}

export class SetStaleDataCheckResultObj implements Action {
  readonly type = DynamicObjectActionTypes.SET_STALE_DATA_CHECK_RESULT_OBJ
  constructor(
    public payload: { storeName: string, objectId: number,  responseBase: IResponseBase }
  ) { }
}

export class ResetAllObjectStores implements Action {
  readonly type = DynamicObjectActionTypes.RESET_ALL_OBJECT_STORES;
}

export type DynamicObjectActions =
  GetObject |
  SetObject |
  CreateObjectObj |
  UpdateObjectByIdObj |
  DeleteObjectByUrlObj |
  CreateCompleteObj |
  UpdateCompleteObj |
  DeleteCompleteObj |
  UpdateObjectByUrlObj |
  UpdateObjectCustomObj |
  SetErrorReturnedObj |
  InitializeObject |
  SetWorkingObj |
  ClearErrorsObj |
  ClearEventObj |
  CheckForStaleDataObj |
  SetStaleDataCheckResultObj |
  ResetAllObjectStores;
