import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, of } from 'rxjs';
import { take, filter, map} from 'rxjs/operators';

import { AppAction } from '../../../../shared/enums/app-action.enums';
import { ModalSizeType } from '../../../../fw/fw-modal/interfaces/i-modal';
import { ProjectObjectStore } from '../enums/project.enums';
import { IObjectData } from '../../../../fw/dynamic-list/store/interfaces';

//store actions and reducers
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../store/reducers/index';

import { getObjectByTypeById, InitializeObject } from '../../../../fw/dynamic-list/store/index';
import { ProjectService } from '../services/project.service';
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { UserPriviledgesService } from '../../../../auth/services';

@Injectable()
export class ProjectGuard  {
  subscription: Subscription = new Subscription();
  constructor(public router: Router,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public modalService: ModalService,
    public projectService: ProjectService,
    public userPriviledgesService: UserPriviledgesService  ) { }

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const projectId = route.paramMap.has('projectId') ? +route.paramMap.get('projectId') : route.paramMap.has('id') ? +route.paramMap.get('id') : + route.paramMap.get('portalEntityId');

    //needed to handle browser refresh, was leaving spinner on
    if (!this.userPriviledgesService.authenticated$.value) {
      return of(false);
     }

     //if no data, go get data 
     this.subscription.add(this.store.pipe(select(getObjectByTypeById(ProjectObjectStore.projectInformation, projectId)), take(1))
       .subscribe((objData: IObjectData) => {
         if (!objData || !objData.data) {
            //go get data
            this.projectService.dispatchProjectObject(projectId);
        }
      }));

    return this.waitForDataToLoad(projectId);
  }

  waitForDataToLoad(projectId: number): Observable<boolean> {
    return this.store.select(getObjectByTypeById(ProjectObjectStore.projectInformation, projectId))
      .pipe(
        filter((objData: IObjectData) => objData !== null),
        take(1),
        map((objData: IObjectData) => {
          if (objData.accessError) {
            this.modalService.open({
              title: 'Access Error',
              path: 'app-action-msg/' + AppAction.accessError + '/Project ' + projectId.toString(),
              sizeType: ModalSizeType.xsmall,
              castExit: true,
              hasTabs: false,
              isSubModal: this.modalService.opened
            });
            this.rootStore.dispatch(new InitializeObject({ storeName: ProjectObjectStore.projectInformation, objectId: projectId }));
            return false;
          } else  {
            return true;
          }
        })
      );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
