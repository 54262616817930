import { Component, OnInit, OnChanges, SimpleChanges,  OnDestroy, Inject, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IBranchProgram, IPurchaseOrder } from '../../../view-models';
import { ILookupData } from '../../../../../shared/interfaces';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { ProjectListStore } from '../../enums/project.enums';
//store actions, reducers, interfaces
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'purchase-order-general-information',
  templateUrl: './purchase-order-general-information.component.html',
  providers: [MetaDataService]
})
export class PurchaseOrderGeneralInformationComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() purchaseOrder: IPurchaseOrder;
  @Input() branchPrograms: IBranchProgram[];
  @Input() projectId: number;
  @Input() canIEdit: boolean;
  @Input() operation: string;
  @Input() staleData: boolean;
  @Input() printUrl: string;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public displayFields: string[] = ['branchProgram', 'orderDate', 'orderStatus', 'sum'];
  public title: string = 'Overview';
  public form: FormGroup;
  public orderStatuses: ILookupData[];

  constructor(public emitterService: HomEventEmitterService,
    public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.setSelectedProgram();
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case ButtonType.save:
        this.savePo(event);
        break;
      default:
        //let manager handle
        this.customEvent.emit(event);
        break;
    }
  }

  initForm(): void {
    this.mds.setFieldDefinitions(ProjectListStore.projectPurchaseOrders);
    this.mds.loadSelectionLists(this.displayFields, this.operation, this.projectId);
    this.orderStatuses = this.mds.getSelectItems('orderStatus');
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.purchaseOrder, this.operation);
  }

  setSelectedProgram(): void {
    const match: IBranchProgram = this.purchaseOrder.branchProgram_branchProgramId
      ? this.branchPrograms.find(x => x.branchProgramId === this.purchaseOrder.branchProgram_branchProgramId) : null;

    if (match) {
      const control = this.form.controls['branchProgram'];
      control.setValue(match);
    }
  }

  savePo(event: IHomEventEmitter): void {
    const formData = this.form.getRawValue();
    let po = { ...this.purchaseOrder };

    po.branchProgram_branchProgramId = formData.hasOwnProperty('branchProgram')
      && formData['branchProgram'].hasOwnProperty('branchProgramId')
      ? formData['branchProgram']['branchProgramId']
      : null;

    let outEvent = { ...event };
    outEvent.data = po;

    this.customEvent.emit(outEvent);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['purchaseOrder'] && !(changes['purchaseOrder'].firstChange)) {
      this.initForm();
      this.setSelectedProgram();
    }
    if (changes['branchPrograms'] && !(changes['branchPrograms'].firstChange)) {
      this.setSelectedProgram();
    }
  }
}
