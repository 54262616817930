import { Component, OnInit, Inject, Input} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import {  IWorkOrder} from '../../../view-models/index';
import { ProjectListStore } from '../../enums/project.enums';

import { MetaDataService } from '../../../../../fw/dynamic-list/services/index'
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'work-order-summary',
  templateUrl: './work-order-summary.component.html',
  providers: [ MetaDataService ]
})
export class WorkOrderSummaryComponent implements OnInit  {
  @Input() workOrder: IWorkOrder;
  @Input() operation: string;

  public displayFields: string[] = ['workOrderId', 'workOrderScheduleText', 'workOrderInstallerName']; //'workOrderServiceName', removed 09/30
  public form: FormGroup;

  constructor(public  mds: MetaDataService,
    public  projectService: ProjectService,
    @Inject(appConstants) public  myConstants: IAppConstants) { }

  ngOnInit(): void {
    this.mds.setFieldDefinitions(ProjectListStore.projectWorkOrders);
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.workOrder, this.myConstants.operationTypeDetails);
  }


}
