import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IListDefinition, IListFilter, ListFilter, IListColumn, ListColumn, OrderTerm } from '../../../../../fw/dynamic-list/interfaces/index';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'installer-communication-events',
  templateUrl: './installer-communication-events.component.html'
})
export class InstallerCommunicationEventsComponent implements OnInit {

  constructor(
    public activeRoute: ActivatedRoute,
    public dynamicListService: DynamicListService
  ) { }

  public listDefinition: IListDefinition;

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition = this.loadInstallerCommunicationEventsListDefinition(+paramMap.get('id'));
      }
    );
  }
  
  loadInstallerCommunicationEventsListDefinition(installerId: number) {
    const listColumns = this.loadInstallerCommunicationEventListColumns();
    const listObjectLabel = 'Communication Events';
    const listObjectController = 'CommunicationEvent';
    const listStoreName = 'installerCommunicationEvents';
    const detailRoutePath = '';
    const listRowKeyId = 'communicationEventId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('createDate', false)];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByInstaller';
    listDefinition.methodParameters = installerId.toString();
    listDefinition.parentId = installerId;
    listDefinition.parentKey = 'installer_installerId';
    listDefinition.showFilter = true;
    listDefinition.allowSaveFilter = false;

    listDefinition.rowStatus = {
      cssMethod: 'getCommunicationEventCss',
      methodService: 'projectDynamicListService'
    };

    return listDefinition;
  }

  loadInstallerCommunicationEventListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('workOrder');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('communicationEventType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('createDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('hasErrorCondition');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('feedback');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);
    return columnDefinitions;
  }
  

}
