import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { ISmsDocument } from '../../interfaces/i-sms-document';

@Component({
  selector: 'sms-send-document',
  templateUrl: './sms-send-document.component.html'
})
export class SmsSendDocumentComponent  {
  @Input() document: ISmsDocument;

  @Output() public delete = new EventEmitter<number>();

  public deleteDocument(id: number): void {
    this.delete.emit(id);
  }

}
