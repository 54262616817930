import { Component, OnInit } from '@angular/core';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { ManagerDashboardService } from '../../services/manager-dashboard.service';

@Component({
  selector: 'outstanding-entity-documents',
  templateUrl: './outstanding-entity-documents.component.html'
})

export class OutstandingEntityDocumentsComponent implements OnInit {
  listDefinition: IListDefinition;

  constructor(public managerDashboardService: ManagerDashboardService) { }

  ngOnInit() {
    this.listDefinition = this.managerDashboardService.loadOutstandingDocumentsListDefinition();
  }

}
