import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, Inject} from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { MetaDataService } from '../../../../fw/dynamic-list/services/index'
import { ContactManagerService, ContactConstantsService } from '../../services/index';
import { CommunicationEventService } from '../../../portals/portal-shared/services/communication-event.service';
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { appConstants, IAppConstants } from '../../../../shared/constants';
import { Contact } from '../../../portals/view-models';
import { IAddFormGroup } from '../../../portals/portal-shared/interfaces';
import { IResponseBase } from '../../../../shared/interfaces';
import { ModalSizeType } from '../../../../fw/fw-modal/interfaces/i-modal';
import { ContactEvent } from '../../enums/contact.enums';
import { FileUploadType } from '../../../file-upload/enums/file-upload.enums';

@Component({
  selector: 'contact-header',
  templateUrl: './contact-header.component.html',
  providers: [MetaDataService] 
})

export class ContactHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() operation: string;
  @Input() contact: Contact;
  @Input() displayType: string;
  @Input() requestTime: string; //to handle subsequent create request for the same contact type (id never changes)
  @Input() suffixRequested: boolean;
  @Input() middleRequested: boolean;
  @Input() canIEdit: boolean;
  @Input() allowText: boolean;

  public displayFields = ['firstName', 'lastName', 'middleName', 'sssOptOut', 'nameSuffix', 'optOutSms', 'communicationPreferenceName'];
  public fileUploadType: FileUploadType = FileUploadType.contactImage;
  public isImport: boolean = false;
  public isTechnician: boolean = false;
  public showHeader: boolean = true;

  formName: string = 'headerForm';
  metaStore: string = 'contactInformation';
  suffixes: any[] = [];
  lastNameInitialLabel: string = '';
  metaLoaded: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(
    public metaDataService: MetaDataService,
    public contactService: ContactManagerService,
    public contactConstantsService: ContactConstantsService,
    public modalService: ModalService,
    public emitterService: HomEventEmitterService,
    public communicationEventService: CommunicationEventService,
    @Inject(appConstants) public myConstants: IAppConstants) {
  }

  public ngOnInit() {
    this.showHeader = this.contact.contactType_contactTypeId !== this.contactConstantsService.contactTypeIdBranch
      && this.contact.contactType_contactTypeId !== this.contactConstantsService.contactTypeIdProviderLocation;
    this.initMetaData();
    this.isImport = !this.contact.hasOwnProperty('contactCommunicationPreferenceId');
    this.isTechnician = this.contact.contactType_contactTypeId === this.contactConstantsService.contactTypeIdInstaller;

    this.subscription.add(this.metaDataService.mdsReady$.pipe(filter(flag => flag === true), take(1))
      .subscribe((ready: boolean) => {
        this.initForm();
      }));
  }

  public handleUpload(response: IResponseBase): void {
    this.emitterService.emitContactEvent({
      requestor: 'single-file-uploader',
      event: ContactEvent.uploadContactFile,
      action: null,
      data: response
    });
  }

  public get myIcon(): string {
    return this.contact.contactType_contactTypeId === this.contactConstantsService.contactTypeIdInstaller ? ' fa-person-carry '
        : ' fa-smile ';
  }

  public pcmExists(): boolean {
    return this.contact.hasOwnProperty('contactCommunicationPreferenceId') && this.contact.communicationPreferenceValue !== '' && this.contact['contactCommunicationPreferenceId'] !== 0;
  }

  public editPreference(): void {
    const commPref: number = this.contact.hasOwnProperty('contactCommunicationPreferenceId') ? this.contact.contactCommunicationPreferenceId : 0;
    this.modalService.open({
      title: 'Preferred Communication Method',
      path: 'communication-preference/' + this.contact.contactId.toString() + '/' + commPref.toString(),
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      isSubModal: this.modalService.opened,
      sizeType: ModalSizeType.xsmall,
    });
  }

  public deletePreference(): void {
    if (!this.contact['metaData'] || !this.contact['metaData']['contactDeletePreferenceUrl']) {
      return;
    }
    let event: IHomEventEmitter = { requestor: 'contact-header', event: ContactEvent.deleteContactPreference, action: '', data: null };
    this.emitterService.emitContactEvent(event);
  }

  formGroup(): AbstractControl {
    return this.contactService.contactForm.controls[this.formName];
  }

  initMetaData() {
    this.metaDataService.mdsReady$.next(false);
    this.metaDataService.setFieldDefinitions(this.metaStore);
    this.lastNameInitialLabel = this.metaDataService.getFieldDefinition('lastName').label;
  }

  initForm() {
    this.metaDataService.loadSelectionLists(this.displayFields, this.operation, this.contact.contactId);
    this.suffixes = this.metaDataService.getSelectItems('nameSuffix');
    let form: FormGroup = this.metaDataService.loadDynamicFormGroup(this.displayFields, this.contact, this.operation);
    let data: IAddFormGroup = { groupName: this.formName, formGroup: form };
    this.contactService.contactForm.addControl(data.groupName, data.formGroup);
    this.metaLoaded = true;
}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['requestTime'] && !(changes['requestTime'].firstChange)) {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
