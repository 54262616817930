import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ITabFactory } from '../interfaces/i-tab-factory';
import { ModalService } from '../../../../fw-modal/services/fw-modal.service';

@Injectable() export class TabsFactoryService {

  constructor(
    public  router: Router,
    public  modalService: ModalService  ) {}
  
  arr: [Array<ITabFactory>, Array<ITabFactory>] = [[],[]];
  startPage: ITabFactory = null;
  pending: ITabFactory = null;
  maxTabs: number;
  portal: number;
 
  tabs(): ITabFactory[] {
    return this.startPage  ? [this.startPage].concat(this.arr[0].concat(this.arr[1])) : [];
  }

  appendTab(item: ITabFactory): void {
    console.log('appendTab with: ', item);
    const tabs: Array<ITabFactory> = this.tabs();

    let i = tabs.length;
    while(i--) if(tabs[i].text === item.text) return this.delegateRoute(item);
    tabs.length+1 < this.maxTabs ? (
        this.delegateRoute(item),
        this.arr[1].push(item)
    ) : (
        this.pending = item,
        this.modalService.open({ title: 'Tab Manager', path: 'tab-manager', onExit: this.reset(), castExit: true, hasTabs: false })
    );
  }

  removeTab(tab: ITabFactory): void {
    const arr: Array<ITabFactory> = this.arr[tab.pinned ? 0 : 1];
    if(tab[!tab.childId ? 'portalId' : 'childId'] !== this.portal) {
      this.arr[tab.pinned ? 0 : 1].splice(arr.indexOf(tab), 1);
      return;
    }
    const tabs: Array<ITabFactory> = this.tabs(),
          idx: number = tabs.indexOf(tab),
          item: ITabFactory = tabs[idx + (idx < tabs.length-1 ? 1 : -1)];
    this.arr[tab.pinned ? 0 : 1].splice(arr.indexOf(tab), 1);
    this.delegateRoute(item);
  }

  pinTab(tab: ITabFactory): void {
    this.arr[!tab.pinned ? 0 : 1].push(tab);
    this.arr[!tab.pinned ? 1 : 0].splice(this.arr[!tab.pinned ? 1 : 0].indexOf(tab), 1);
    tab.pinned = !tab.pinned;
  }

  delegateRoute(item: ITabFactory): void {
    const id: number = item.portalId,
          portalEntityId: number = this.portal =  !item.childId ? id : item.childId,
          route: string = '/'+ item.routeName + '/';

    this.router.navigate(!item.childId ? [route, id] : [route, id, {portalEntityId: portalEntityId}]);
  }

  reset(): void {
    const tabs: Array<ITabFactory> = this.tabs();
    let i: number = tabs.length;
    while(i--) if(tabs[i].selected) tabs[i].selected = false;
    if(this.pending !== null) this.pending = null;
  }

}
