//widget_manager_portals.component_name
export enum WidgetComponentName {
  AccountingChargeBacksComponent = 'AccountingChargeBacksComponent',
  CertificationComponent = 'CertificationComponent',
  CustomerSearchComponent = 'CustomerSearchComponent',
  EmailImportsComponent = 'EmailImportsComponent',
  ExpertToolBoxComponent = 'ExpertToolBoxComponent',
  GcInstallerRequirementComponent = 'GcInstallerRequirementComponent',
  InstallerCertificationsComponent = 'InstallerCertificationsComponent',
  InstallerComplianceComponent = 'InstallerComplianceComponent',
  InstallerDashboardComponent = 'InstallerDashboardComponent',
  InstallerGcRequirementsComponent = 'InstallerGcRequirementsComponent',
  InstallerGeneralInformationComponent = 'InstallerGeneralInformationComponent',
  InstallerNotesComponent = 'InstallerNotesComponent',
  InstallerSearchComponent = 'InstallerSearchComponent',
  InstallerWorkCrewsComponent = 'InstallerWorkCrewsComponent',
  InventoryNeedsComponent = 'InventoryNeedsComponent',
  InventoryOrdersComponent = 'InventoryOrdersComponent',
  ManagerToolboxComponent = 'ManagerToolboxComponent',
  ProjectChargeBacksComponent = 'ProjectChargeBacksComponent',
  ProjectCustomerDetailsComponent = 'ProjectCustomerDetailsComponent',
  ProjectFileUploadsComponent = 'ProjectFileUploadsComponent',
  ProjectFileViewerComponent = 'ProjectFileViewerComponent',
  ProjectPurchaseOrdersComponent = 'ProjectPurchaseOrdersComponent',
  ProjectTasksComponent = 'ProjectTasksComponent',
  ProjectWarrantiesComponent = 'ProjectWarrantiesComponent',
  ProjectWorkOrdersComponent = 'ProjectWorkOrdersComponent',
  ReportViewerComponent = 'ReportViewerComponent',
  ScheduleMoveupsComponent = 'ScheduleMoveupsComponent',
  SssExpertWidgetComponent = 'SssExpertWidgetComponent',
  TechnicianCertificationsComponent = 'TechnicianCertificationsComponent',
  TechnicianGcRequirementsComponent = 'TechnicianGcRequirementsComponent',
  TechnicianGeneralInformationComponent = 'TechnicianGeneralInformationComponent',
  WarrantyExpertToolboxComponent = 'WarrantyExpertToolboxComponent',
  WorkCrewGeneralInformationComponent = 'WorkCrewGeneralInformationComponent',
  WorkCrewScheduleExclusionsComponent = 'WorkCrewScheduleExclusionsComponent',
  WorkCrewServicesComponent = 'WorkCrewServicesComponent',
  WorkCrewTechniciansComponent = 'WorkCrewTechniciansComponent',
  XmlMappingsComponent = 'XmlMappingsComponent',
}







