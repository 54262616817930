import { Component, OnInit, Input, OnChanges, SimpleChanges , ChangeDetectionStrategy} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, OrderTerm } from '../../../../../fw/dynamic-list/interfaces/index';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
   changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'installers',
    templateUrl: './installers.component.html'
})
export class InstallersComponent implements OnInit, OnChanges {
  @Input() providerId: number;
  @Input() includeOldestRequirement: boolean = false;
  @Input() canCreate: boolean = false;

  listDefinition$: BehaviorSubject<IListDefinition>;

  constructor(public dynamicListService: DynamicListService) {
    this.listDefinition$ = new BehaviorSubject(null);
  }

  ngOnInit() {
    this.loadListDefinition();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['includeOldestRequirement']) {
      this.listDefinition$.next(null); //set at end to signify ready to instantiate the list.

      this.includeOldestRequirement = changes['includeOldestRequirement'].currentValue;
      this.loadListDefinition();
    }
  }

  /*
      Define the list to be created
  */
  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Installers';
    const listObjectController = 'Installer';
    const listStoreName = 'installers';
    const detailRoutePath = 'installer'; 
    const listRowKeyId = 'installerId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('installerName')];

    let listDefinition = this.dynamicListService.createListDefinition('installerOutlet',
        listObjectLabel,
        listObjectController,
        listStoreName,
        listRowKeyId,
        defaultListFilter,
        listColumns,
        detailRoutePath);

    listDefinition.controllerMethod = this.includeOldestRequirement ? 'ByProvider' :  'ByProviderWithoutOldestCertification';
    listDefinition.methodParameters =  this.providerId.toString() ;

    if (this.canCreate) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);
    }

    this.listDefinition$.next(listDefinition); //set at end to signify ready to instantiate the list.

    }

    /*
        Define the columns in the list to be created.
    */
    loadListColumns(): IListColumn[] {
        let columnDefinitions: Array<IListColumn> = [];

      let colDef = new ListColumn('installerName');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('isActive');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('contactName');
      colDef.visibleOnSmall = false;
      colDef.visibleOnMedium = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('installerLocations');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('installerServices'); 
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      if (this.includeOldestRequirement) {
        colDef = new ListColumn('minimumRequirementDate');
        colDef.visibleOnSmall = false;
        colDef.visibleOnMedium = false;
        columnDefinitions.push(colDef);
      }

      colDef = new ListColumn('numberWorkCrews');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

        return columnDefinitions;
    }

}
