<ng-container *ngIf="!hideTimespan && daySelected.availability.slotAvailability && daySelected.availability.slotAvailability.length > 0">
  <div class="schedule-timespan">
    <div *ngFor="let item of daySelected.availability.slotAvailability; let i = index;" class="schedule-timespan__slot">
      <div class="schedule-timespan__slot__top">
        <div *ngIf="item.newHour" class="schedule-timespan__slot__top__label">{{ item.startTime | date:'h' }}</div>
        <div class="schedule-timespan__slot__top__tick" [ngClass]="{'schedule-timespan__slot__top__tick--bold': item.newHour }"></div>
      </div>
      <div class="schedule-timespan__slot__bottom schedule-timespan__slot__bottom--{{ item.status }}"
           [ngClass]="{'schedule-timespan__slot__bottom--active': i >= startIdx && i <= (endIdx + (endIdx === daySelected.availability.slotAvailability.length - 1 ? 1 : 0)) }"
           (click)="adjustSpan(i)">
      </div>
    </div>
  </div>
  <div  class="app-btn-box time-range">
    <div *ngIf="startIdx > -1" class="time-range__txt">
      {{ daySelected.availability.slotAvailability[startIdx].startTime | date: 'shortTime' }} - {{ daySelected.availability.slotAvailability[endIdx].endTime | date: 'shortTime' }}
    </div>
    <button class="app-btn app-btn--primary" (click)="setTimeSpan()" title="Click to Set Time">Set Time</button>
  </div>
</ng-container>


