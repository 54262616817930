<fw-info [infoTitle]="title" [customColor]="'#333333'">
  <div class="info-box">
    <div class="info-box__label">{{ mds.getFieldDefinition('serviceName').label }}:</div>
    <div class="info-box__value">{{ row.serviceName }}</div>

    <div class="info-box__label">{{ mds.getFieldDefinition('capacityUnits').label }}:</div>
    <div class="info-box__value">{{ row.capacityUnits  | number: '1.2-2' }}</div>

    <div class="info-box__label">{{ mds.getFieldDefinition('workOrderCity').label }}:</div>
    <div class="info-box__value">{{row.workOrderCity }}</div>

    <div class="info-box__label">{{ mds.getFieldDefinition('installerName').label }}:</div>
    <div class="info-box__value">{{ row.installerName }}</div>

    <div class="info-box__label">{{ mds.getFieldDefinition('workCrewName').label }}:</div>
    <div class="info-box__value">{{ row.workCrewName.replace(row.installerName, '') }}</div>

    <div class="info-box__label">{{ mds.getFieldDefinition('branchName').label }}:</div>
    <div class="info-box__value">{{ row.branchName }}</div>

    <div class="info-box__label">{{ mds.getFieldDefinition('workOrderStatus').label }}:</div>
    <div class="info-box__value">{{row.workOrderStatus }}</div>

    <div class="info-box__label">{{ mds.getFieldDefinition('scheduleStartDate').label }}:</div>
    <div class="info-box__value">{{ row.scheduleStartDate | date:'short' }}</div>

    <div class="info-box__label">{{ mds.getFieldDefinition('scheduleEndDate').label }}:</div>
    <div class="info-box__value">{{ row.scheduleEndDate  | date:'short' }}</div>

    <div class="info-box__label">{{ mds.getFieldDefinition('customerName').label }}:</div>
    <div class="info-box__value">{{ row.customerName }}</div>
  </div>
</fw-info>
