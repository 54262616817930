import { Component, OnInit,  ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { ISssOptionGroupCountsViewModel, ISssInvitationByWorkOrderViewModel, ICommunicationEventSssUnrespondedToViewModel } from '../../../view-models';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { ProjectService } from '../../../project/services';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { getSelectedRecord } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';

@Component({
  selector: 'sss-workorder-invitations',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sss-workorder-invitations.component.html'
})
export class SssWorkOrderInvitationsComponent implements OnInit, OnDestroy {
  public workOrderId: number;
  public projectId: number;
  public contactId: number;
  public customerName: string;
  public options$: BehaviorSubject<ISssOptionGroupCountsViewModel> = new BehaviorSubject(null);
  public invitations$: BehaviorSubject<ISssInvitationByWorkOrderViewModel> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  storeName: string;
  key: string;
  subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public dynamicDetailService: DynamicDetailService,
    public projectService: ProjectService) { }

  public goToProject(): void {
    this.projectService.openProjectTab(this.projectId, 'sss-workorder-inviations');
  }

  public goToWorkOrder(): void {
    this.projectService.openProjectWorkOrderTab(this.projectId, this.workOrderId, 'sss-workorder-inviations');
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.workOrderId = +paramMap.get('id');
      this.storeName = paramMap.get('storeName'),
      this.key = paramMap.get('key'),
      this.getParentRecord();
      this.setDetailConfig(paramMap);
    });
  }

  getParentRecord(): void {
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(this.storeName, -1, this.key, this.workOrderId)))
      .subscribe((entity: ICommunicationEventSssUnrespondedToViewModel) => {
        if (entity) {
          this.projectId = entity.projectId;
          this.contactId = entity.contactId;
          this.customerName = entity.customerName;
        }
      })
    );
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.showCancel = true;
    this.detailConfig$.next(params);
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
