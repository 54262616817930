
<div class="sms-popover-box">
  <div class="sms-popover">
    <fw-component-title [title]="title"></fw-component-title>
    <div class="sms-popover__items  sms-popover__items--types sms-file-type">
      <div class="sms-file-type__entities">
        <ng-container *ngFor="let btn of entityTypeBtns">
          <label class="fw-radio-button">
            {{ btn.label }}
            <input type="radio"
                   [name]="'ftgroup'"
                   [id]="btn.id"
                   title="'Select '{{ btn.label }}"
                   (click)="selectEntityType(btn.value)">
            <span class="fw-radio-button--checkmark"></span>
          </label>
        </ng-container>
      </div>
      <div class="float-label sms-file-type__types">
        <select #fileType
                [disabled]="!(fileTypes$ | async)?.length"
                (change)="selectFileType(fileType.value)">
          <option [value]="-1">{{myConstants.choose}}</option>
          <option *ngFor="let ft of (fileTypes$ | async)"
                  [value]="ft.documentTypeId">
            {{ft.documentTypeName}}
          </option>
        </select>
        <label>File Type</label>
      </div>
      <ng-container *ngIf="(cbButtons$ | async) as cbButtons">
        <div *ngIf="cbButtons.length > 0" class="sms-file-type__action-title">Check All the File Contains</div>
        <div class="sms-file-type__actions">
          <ng-container *ngFor="let cbBtn of cbButtons">
            <fw-check-box #cbAction [config]="cbBtn"
                          (change)="onActionSelect(cbBtn.label?.label, cbAction.value)">
            </fw-check-box>
          </ng-container>
        </div>
      </ng-container>
      <div class="app-btn-box sms-file-type__btns">
        <button class="app-btn app-btn--primary"
                type="button"
                (click)="onNext()"
                [disabled]="!(isValid$ | async)">
          Next
        </button>
      </div>
    </div>

  </div>
</div>
