<div class="portal">
  <div class="portal__detail--item">
    <fw-component-title title='Inventory Needs'></fw-component-title>
    <div class="inventory-selectors">
      <div class="float-label inventory-selectors__item">
        <select id="providerLocation" name="providerLocation" #providerLocation
                [disabled]="!(locations$ | async)"
                appFocus [autoFocus]="true"
                (change)="onLocationChange(providerLocation.value)">
          <option selected value="0">{{myConstants.choose}}</option>
          <option *ngFor="let item of (locations$ | async)" [value]="item.providerLocationShipperInventorySetupId">{{ item.entityLabel }}</option>
        </select>
        <label for="providerLocation">Location</label>
      </div>

      <div class="float-label">
        <select id="shipDate" name="shipDate" #shipDate
                [disabled]="!(shipperDates$ | async)"
                (change)="onDateChange(shipDate.value)">
          <option selected value="">{{myConstants.choose}}</option>
          <option *ngFor="let item of (shipperDates$ | async)" [ngValue]="item">{{ item.deliveryDate | homdate | date | date: 'MM/dd/yyyy' }}</option>
        </select>
        <label for="shipDate">Delivery Date</label>
      </div>
    </div>
    <h4 *ngIf="!(searchInfo$ | async)">The list will render after you have chosen a location and applicable ship date.</h4>
    <div *ngIf="(searchInfo$ | async) as searchInfo">
      <h4>{{ searchInfo }}</h4>
      <fw-dynamic-list *ngIf="listDefinition$ | async"
                       [listTitle]="'Materials'"
                       [vmListDefinition]="listDefinition$ | async"
                       (selectEvent)="onSelect($event)"
                       (pageEvent)="onPage($event)"
                       (showChildEvent)="onShowChild($event)"></fw-dynamic-list>
      <div class="app-btn-box">
        <button class="app-btn app-btn--primary"
                type="button"
                (click)="previewOrder()"
                [disabled]="!selectedItems.length || showingChild">
          Preview Order
        </button>
      </div>
    </div>

  </div>
</div>

