<div *ngIf="(installer$ | async) as installer">
  <!--Installer Portal-->
  <portal-detail-layout-container *ngIf="installer.installerId > 0"
                                  [portalId]="portalId"
                                  [portalEntityId]="installer.installerId"
                                  [title]="installer.installerName"
                                  [wideSummary]="true"
                                  [tabContainerName]="tabContainerName"
                                  [customBtns]="portalBtnConfig$ | async"
                                  (customEvent)="onLayoutEvent($event)">

    <div *ngIf="(installer$ | async)" [ngClass]="{ 'loading-mask': loading$ | async }">
      <ng-container *ngTemplateOutlet="detailTemplate"></ng-container>
    </div>
  </portal-detail-layout-container>

  <!--Installer Create-->
  <div *ngIf="installer.installerId === 0">
    <ng-container *ngTemplateOutlet="detailTemplate"></ng-container>
  </div>
</div>


<ng-template #detailTemplate>
  <installer-detail #installerDetail
                    [installer]="installer$ | async"
                    [operation]="operation$ | async"
                    [canIEdit]="ups.canManageInstallers$ | async"
                    [storeName]="storeName"
                    [displayFields]="displayFields"
                    [staleData]="hasStaleData$ | async"
                    [errorData]="errorData$ | async"
                    (dirtyEvent)="onDirty()"
                    (createEvent)="onCreate($event)"
                    (cancelEvent)="onCancel()">
  </installer-detail>
  <fw-error-box [errorData]="errorData$ | async"></fw-error-box>
</ng-template>
