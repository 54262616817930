import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Inject, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { orderBy, cloneDeep } from 'lodash'; //pulling for just the methods needed is not working at this time, but keep trying
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { PlsInstallerWorkCrewViewModel, PlsCertificationViewModel, IProviderLocationService, IProviderLocation } from '../../../view-models/index';
import { IListFilter, ListFilter } from '../../../../../fw/dynamic-list/interfaces/index';
import {
  IMultiSelectOption, MultiSelectTexts,
  MultiSelectSettings, MultiSelectDropdown
} from '../../../../../fw/fw-shared/components/fw-multi-select-dropdown/index';
import { UpdateObjectCustomModel, CreateObjectModel, DeleteObjectModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { getListByType } from '../../../../../fw/dynamic-list/store/index';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromRoot from '../../../../store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists/index';

//provider location services detail for installer-work-crew or for certification
@Component({
  templateUrl: './pls-entity-detail.component.html'
})
export class PlsEntityDetailComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('serviceMultiSelect') public serviceMultiSelect: MultiSelectDropdown;

  public multiSelectOptions: IMultiSelectOption[];
  public multiSelectLabels: MultiSelectTexts;
  public multiSelectSettings: MultiSelectSettings;
  public form: FormGroup;
  public operation: string;
  public myPLServices$: BehaviorSubject<IProviderLocationService[]> = new BehaviorSubject([]);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public availableProviderLocations: IProviderLocation[] = [];
  public showNext: boolean = true;
  public selectedPLServices: IProviderLocationService[] = [];
  public selectedLocationId: number = -1;
  public invalid: boolean = false;
  public errorMessage: string = '';

  listStore: fromDynamicList.IListObjectData;
  initialLoadCompleted: boolean = false;
  parentId: number = -1;
  myPathName: string = '';
  myStoreName: string = '';
  key: string = '';
  installerId: number = 0;
  providerLocationId: number = 0;
  requestTime: string = '';
  objectData: PlsInstallerWorkCrewViewModel | PlsCertificationViewModel = null;
  requestor: string = 'pls-entity-detail';
  selectedServices: string;
  allProviderLocations: IProviderLocation[] = [];
  subscription: Subscription = new Subscription();
  svcSub: Subscription;

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public  store: Store<fromStore.IAllDynamicData>,
    public  emitterService: HomEventEmitterService,
    public  dynamicDetailService: DynamicDetailService,
    public  changeDetectorRef: ChangeDetectorRef,
    @Inject(appConstants) public  myConstants: IAppConstants,
    public  activatedRoute: ActivatedRoute) {
    }

  public formDisabled() {
    return !this.form.dirty || !this.form.valid || (this.operation === this.myConstants.operationTypeCreate && this.selectedPLServices.length === 0)
  }

  public onLocationChange(e) {
    this.selectedLocationId = +e.target.value;

    this.selectedPLServices = [];
    this.selectedServices = '';
    this.myPLServices$.next([]);

    if (e.target.value > 0) {
      this.getServicesByProviderLocation(this.selectedLocationId);
    }
  }

  public onCancel() {
    const emitter: IHomEventEmitter = { requestor: 'installer-work-crew-pl-service', event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public onCreate() {
    let newRecord = this.myStoreName === 'plsCertifications' ? this.setCertificationCommonProperties() : this.setIWCCommonProperties();
    this.isValid('create', newRecord);
    if (this.invalid) return;

    const keyData: IKey = { storeName: this.myStoreName, parentId: this.parentId, key: this.key, id: this.providerLocationId }
    const emitter: IHomEventEmitter = {
      requestor: this.requestor,
      event: this.myConstants.emitterEventCreate,
      action: '',
      data: null
    };
    const createData = new CreateObjectModel(this.myStoreName, this.parentId, this.listStore.listDefinition.controllerName, 'BulkUpdate', newRecord, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }

  /*
      Update the record:  since the data contains both existing and missing certifications, update needs to determine if create or update request
        based on value in installerCertificationId
  */
  public onSave(type: string) {
    let updatedRecord = this.myStoreName === 'plsCertifications' ? this.setCertificationCommonProperties() : this.setIWCCommonProperties();
    this.isValid('update', updatedRecord);
    if (this.invalid) return;

    const keyData: IKey = { storeName: this.myStoreName, parentId: this.parentId, key: this.key, id: this.providerLocationId }
    const emitter: IHomEventEmitter = {
      requestor: this.requestor,
      event: updatedRecord.providerLocationServiceIds.length === 0 ? this.myConstants.emitterEventListReload : this.myConstants.emitterEventUpdate,
      action: updatedRecord.providerLocationServiceIds.length === 0 || type === 'close' ? '' : type,
      data: null
    };

    if (updatedRecord.providerLocationServiceIds.length === 0) {
      const deleteUrl = this.getDeleteUrl();
      if (deleteUrl) {
        const deleteData = new DeleteObjectModel(keyData, this.objectData['deleteUrl'], emitter);
        this.store.dispatch(new fromStore.DeleteObjectByUrlList({ deleteData }));
      } else {
        console.log('DEV ERROR: onSave delete attempt without a delete url defined', this.objectData);
      }
    } else {
      const updateData = new UpdateObjectCustomModel(keyData, this.listStore.listDefinition.controllerName, 'BulkUpdate', updatedRecord, null, emitter);
      this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));
    }
  }

  ngOnInit() {
    this.subscription.add(this.store.select(getListByType('workCrewPlServices')).subscribe((data) => {
      if (data && data.objData && data.objData.length) {
        if (data.objData[0].errorData.length) {
          this.errorMessage = data.objData[0].errorData[0].value[0];
        }
      }
    }));

    this.form = new FormGroup({
      'serviceMultiSelect': new FormControl(),
      'providerLocations': new FormControl()
    });

    this.activatedRoute.data.pipe(map(data => data))
      .subscribe((data) => {
        this.allProviderLocations = data['providerLocations']; //the pl services that i offer, so have to work within those
        this.myPLServices$.next(data['pls']);
      });

    /* Subscribe to the parent id  */
    this.activatedRoute.parent.paramMap.subscribe(paramMap => {
      //would be certid
      this.parentId = +paramMap.get('id');
    });

    /* Subscribe to this objects route params  */
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.providerLocationId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName'); //plsCertifications while objectcontroller will be providerLocation
      this.requestTime = paramMap.get('requestTime');
      this.showNext = paramMap.get('showNext') === 'true';
      this.installerId = +paramMap.get('portalEntityId');
      this.setDetailConfig(paramMap);
      this.getDetail();
    });


    this.subscription.add(this.myPLServices$.subscribe((val: IProviderLocationService[]) => {
      this.initServiceMultiSelect(val);
      if (this.serviceMultiSelect) {
        this.serviceMultiSelect.isDisabled = this.selectedLocationId <= 0;
      }
      this.setSelectedServices();
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['parentId'] && !(changes['parentId'].firstChange)) {
      this.initialLoadCompleted = false;
      this.parentId = changes['parentId'].currentValue;
      this.getDetail();
    }
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.parentId;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.wrapsForm = true;
    params.showCancel = false;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.selectedServices = '';
    this.subscription.add(this.store.pipe(select(fromStore.getEntityListByParentId(this.myStoreName, this.parentId)))
      .subscribe((state: fromDynamicList.IListObjectData) => {
        this.listStore = state;
        this.myPathName = this.requestor;
        this.objectData = this.operation === this.myConstants.operationTypeCreate ? new PlsInstallerWorkCrewViewModel()
          : cloneDeep(this.listStore.data.find(data => data[this.key] == this.providerLocationId) );
        this.selectedServices = !this.objectData && this.operation === this.myConstants.operationTypeCreate ? '' : this.objectData.serviceNames;
        if (!this.listStore || (this.listStore && !this.listStore.working)) {
          this.setProviderLocations();
        }

        this.setSelectedServices();

      }));
  }


  setProviderLocations() {
    this.availableProviderLocations = [];
    let plsAvailabile = [];
    const formControl = this.form.controls['providerLocations'];
    formControl.setValue(0);

    if (this.operation === this.myConstants.operationTypeCreate) {
      this.selectedLocationId = 0;
      //filter for the provider locations that are not already setup for this parent (installer) + work crew
      const usedPlses = this.listStore ? this.listStore.data : [];
      this.allProviderLocations.forEach((pl: IProviderLocation) => {
        if (usedPlses.find(pls => pls.providerLocationId == pl.providerLocationId) === undefined) {
          plsAvailabile.push(pl);
        }
      });
    } else {
      this.selectedLocationId = this.allProviderLocations.find(pl => pl.providerLocationId == this.providerLocationId).providerLocationId;
      this.allProviderLocations.forEach(pl => { plsAvailabile.push(pl) });
      this.getServicesByProviderLocation(this.providerLocationId);
    }

    plsAvailabile.forEach(l => this.availableProviderLocations.push(l));
    if (this.operation !== this.myConstants.operationTypeCreate) {
      formControl.disable()
    }
    formControl.setValue(this.selectedLocationId);
  }

  getServicesByProviderLocation(providerLocationId: number): void {
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;

    if (this.svcSub) {
      this.svcSub.unsubscribe();
    }
    this.svcSub = this.rootStore.pipe(select(getSelectionListDataByType('providerLocationService', providerLocationId)))
      .subscribe((data: IProviderLocationService[]) => {
        if (data) {
          this.myPLServices$.next(data);
        } else {
          //dispatch only if no data found
          this.store.dispatch(new fromSelectionLists.GetEntityListById('ProviderLocationService', 'ByProviderLocation', providerLocationId, listFilter, 'providerLocationService'));
        }
      });
  }

  //TODO:  modify to work with new ability to push options in with selected value
  setSelectedServices() {
    if (!this.serviceMultiSelect) return;

    if (this.selectedServices) {
      //set the values in the drop down
      let nameList: string[] = this.selectedServices.split(',').map(n => n.trim());
      nameList.forEach(n => {
        let index = this.serviceMultiSelect.options.findIndex(o => o.name === n);
        if (index >= 0) {
          this.serviceMultiSelect.setSelected(this.serviceMultiSelect.options[index]);
        }
      });
    }
    this.serviceMultiSelect.isDisabled = this.operation === this.myConstants.operationTypeDetails || this.selectedLocationId <= 0;
  }

  initServiceMultiSelect(plServices: IProviderLocationService[]) {
    let multiSelectLabels = new MultiSelectTexts();
    multiSelectLabels.defaultTitle = 'Select...';
    let multiSelectSettings = new MultiSelectSettings(true, false, false, 1, plServices ? plServices.length : 0, plServices ? plServices.length : 0);
    multiSelectSettings.totalItems = plServices.length;
    multiSelectSettings.itemsPerPage = plServices.length;
    multiSelectSettings.enableSearch = true;
    multiSelectSettings.dynamicTitleMaxItems = 1;
    multiSelectSettings.uncheckAllOnReload = true;
    multiSelectSettings.showPagingInfo = false;

    let multiSelectOptions: IMultiSelectOption[] = [];
    plServices.forEach((pls: IProviderLocationService) => {
      multiSelectOptions.push({ id: pls.service_serviceId, name: pls.serviceName });
    });

    this.multiSelectLabels = multiSelectLabels;
    this.multiSelectSettings = multiSelectSettings;
    this.multiSelectOptions = multiSelectOptions;
    this.changeDetectorRef.detectChanges();
  }

  //LISTENER:  Event emitted by multi-select
  addServiceEvent(plsServiceId: number) {
    const plServices: IProviderLocationService[] = this.myPLServices$.value;
    const pls: IProviderLocationService = plServices.find(x => x.service_serviceId === plsServiceId);
    if (pls) {
      this.selectedPLServices.push(pls);
      const orderedList: IProviderLocationService[] = orderBy(this.selectedPLServices, ['serviceName'], ['asc']);
      this.selectedPLServices = orderedList.map(x => Object.assign({}, x));
    }
  }

  //LISTENER:  Event emitted by multi-select
  removeServiceEvent(plsServiceId: number) {
    const index = this.selectedPLServices.findIndex(x => x.service_serviceId === plsServiceId);
    if (index > -1) {
      this.selectedPLServices.splice(index, 1);
    }
  }

  setCertificationCommonProperties(): PlsCertificationViewModel {
    let updatedRecord = cloneDeep(this.objectData) as PlsCertificationViewModel;

    updatedRecord.certificationId = this.parentId;
    updatedRecord.providerLocationServiceIds = this.getPlServiceIds();
    updatedRecord.providerLocationId = this.selectedLocationId;

    return updatedRecord;
  }

  setIWCCommonProperties(): PlsInstallerWorkCrewViewModel {
    let updatedRecord = cloneDeep(this.objectData) as PlsInstallerWorkCrewViewModel;

    updatedRecord.installerId = this.installerId;
    updatedRecord.installerWorkCrewId = this.parentId;
    updatedRecord.providerLocationServiceIds = this.getPlServiceIds();
    updatedRecord.providerLocationId = this.selectedLocationId;

    return updatedRecord;
  }

  isValid(type: string, data: PlsInstallerWorkCrewViewModel | PlsCertificationViewModel): boolean {
    let valid: boolean = true;
    this.errorMessage = '';
    //if (data.providerLocationServiceIds.length === 0) {
    //  this.errorMessage = 'At least one service must be selected';
    //  valid = false;
    //}
    if (type === 'create' && data.providerLocationId <= 0) {
      this.errorMessage = 'At least one service must be selected';
      valid = false;
    }

    return true;
  }

  getPlServiceIds(): number[] {
    let serviceIds: number[] = [];
    this.selectedPLServices.forEach(pls => serviceIds.push(pls.providerLocationServiceId));
    return serviceIds;
  }


  getDeleteUrl(): string {
    let deleteUrl: string = '';

    if (this.objectData.hasOwnProperty('metaData')
      && this.objectData['metaData'].hasOwnProperty('crud')
      && this.objectData['metaData']['crud'].hasOwnProperty('deleteUrl')
      && this.objectData['deleteUrl'].length > 0) {
      deleteUrl = this.objectData['metaData']['crud']['deleteUrl'];
    }

    return deleteUrl;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.svcSub) {
      this.svcSub.unsubscribe();
    }
  }


}
