import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { AdminEvent } from '../../enums/admin.enums';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IProviderLocationShipperInventorySetup } from '../../../view-models';
import { AdminProviderService } from '../../services/admin-provider.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'shipper-locations',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './shipper-locations.component.html'
})
export class ShipperLocationsComponent implements OnInit {
  shipperId: number = 0;
  public listDefinition: IListDefinition;

  constructor(
    public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService,
    public modalService: ModalService  ) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.shipperId = +paramMap.get('id');
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.manageShipperLocationPayouts:
        this.openShipperLocationPayouts(event.data);
        break;
      default:
        break;
    }
  }

  //in db, the payout for a shipper location is labeled as WorkOrderItemShipperProviderLocation
  openShipperLocationPayouts(shipperLocation: IProviderLocationShipperInventorySetup): void {
    this.modalService.open({
      title: 'Payouts for Shipper Location'.concat(' ', shipperLocation.entityLabel),
      path: 'shipper-location-payouts/' + shipperLocation.providerLocationShipperInventorySetupId.toString() + '/' + this.shipperId.toString(),
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false,
      isSubModal: this.modalService.opened
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadShipperLocationsListDefinition(this.shipperId);
  }

}
