import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { take, map, filter } from 'rxjs/operators';
import { cloneDeep, split } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../shared/constants';
import { UpdateObjectCustomModel, IKey } from '../../store/interfaces';
import { IListDefinition, IListComponent, IUserListFilter, ISearchTerm, IListFilter } from '../../interfaces';
import {
  IAllDynamicData, getEntityListByParentId, IListObjectData, getMetaDataByType,
  IMetaDataState, GetList, UpdateObjectCustomList, IDynamicListState, getListByType, SplitByParent
} from '../../store';
import { DynamicListEvent, DynamicListStore } from '../../enums/dynamic-list.enum';
import * as fromRoot from '../../../../app/store/reducers/index';
import { IFieldDefinition, DynamicFieldService } from '../../../dynamic-forms/index';
import { DynamicListService } from '../../services/dynamic-list.service';
import { ModalService } from '../../../fw-modal/services/fw-modal.service';
import { UserPriviledgesService } from '../../../../auth/services';

@Component({
  selector: 'fw-dynamic-list-user-filters',
  templateUrl: './dynamic-list-user-filters.component.html'
})
export class DynamicListUserFiltersComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public loading$: Observable<boolean>;
  public invalidSetup: boolean = false;
  parentId: number;
  storeName: string;
  rowKey: string;
  objectLabel: string;
  currentFromFilter: IListFilter;
  fromListDefinition: IListDefinition;
  fromFieldDefinitions: IFieldDefinition[];
  listComponentId: number;
  selectedUserFilter: IUserListFilter = null;
  showingChild: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<IAllDynamicData>,
    public dynamicListService: DynamicListService,
    public dynamicFieldService: DynamicFieldService,
    public userPriviledgesService: UserPriviledgesService,
    public emitterService: HomEventEmitterService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }


  public onListSelect(event: IHomEventEmitter): void {
    switch (event.event) {
      case DynamicListEvent.selectUserFilter:
        this.selectedUserFilter = event.data;
        break;
      default:
        break;
    }
  }

  public onListPage(): void {
    this.selectedUserFilter = null;
  }

  public onShowChild(val: boolean): void {
    this.showingChild = val;
  }

  public onDeleting(event: IHomEventEmitter): void {
        this.selectedUserFilter = null;
  }

  public applyFilter(): void {
    let searchTerms: ISearchTerm[] = this.dynamicListService.loadSavedFilterToSearchTerms(this.selectedUserFilter, this.fromListDefinition.requiredSearchTerms, this.fromFieldDefinitions);
    let newFilter: IListFilter = cloneDeep(this.currentFromFilter);
    newFilter.currentPage = 1;
    newFilter.searchTerm = searchTerms;
    newFilter.searchTerm.forEach(term => {
      const values: string[] = split(term.value, ',');
      term.value = values.length && values.length > 1 ? values : term.value;
    });

    let event: IHomEventEmitter = { requestor: 'dynamic-list-user-filters', event: DynamicListEvent.applyFilter, action: '', data: this.selectedUserFilter.filterName };
    this.store.dispatch(new GetList({ listDefinition: this.fromListDefinition, listFilter: newFilter, parentId: this.fromListDefinition.parentId, event: event }));

    //update selected filter to be the current filter
    const def = this.listDefinition$.value;
    const keyData: IKey = { storeName: def.storeName, parentId: def.parentId, key: def.rowKeyId, id: this.selectedUserFilter.userListFilterId }
    const emitter: IHomEventEmitter = { requestor: def.detailRoutePath, event: this.myConstants.emitterEventListReload, action: this.myConstants.emitterEventModalClose, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, 'UserListFilter', 'UpdateCurrent', this.selectedUserFilter, null, emitter);
    this.store.dispatch(new UpdateObjectCustomList({ updateData }));
  }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
    this.storeName = paramMap.get('storeName');
    this.parentId = +paramMap.get('portalEntityId');

    const listComponent: IListComponent = this.userPriviledgesService.getListComponentByStore(this.storeName);
    this.listComponentId = listComponent ? listComponent.listComponentId : -1;
    if (this.listComponentId === -1) {
      this.invalidSetup = true;
      console.log('DEVERROR: This storename was not added to list_components', this.storeName);
    } else {
      this.getFromListDefinition();
      this.getFromFieldDefintion();
      this.listDefinition$.next(this.dynamicListService.loadUserListFiltersDefinition(this.listComponentId));
      this.setListeners();
    }
    });
  }

  setListeners(): void {
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(
        filter(x => x.requestor === DynamicListStore.userListFilters),
        map(x => x.show));

  }

  getFromListDefinition(): void {
    this.subscription.add(this.store.pipe(select(getEntityListByParentId(this.storeName, this.parentId)), take(1))
      .subscribe((state: IListObjectData) => {
        const objData = cloneDeep(state);
        if (objData && objData.listDefinition) {
          this.currentFromFilter = objData.listFilter
          this.objectLabel = objData.listDefinition.objectLabel;
          this.fromListDefinition = objData.listDefinition;
        }
      }));
  }

  getFromFieldDefintion(): void {
    this.subscription.add(this.store.pipe(select(getMetaDataByType(this.storeName)), take(1))
      .subscribe((state: IMetaDataState) => {
        const data = cloneDeep(state);
        if (data && data.fieldDefinitions) {
          this.fromFieldDefinitions = data.fieldDefinitions
        }
      }));
  }

}
