<div class="portal">
  <div class="portal__detail--item">
    <fw-component-title title='Manage Personal Filters For {{ objectLabel }}'></fw-component-title>
    <ng-container *ngIf="invalidSetup">
      <div class="app-info">The requested store '{{ storeName }}' was not configured to use saved filters.</div>
    </ng-container>
    <ng-container *ngIf="!invalidSetup">

      <fw-dynamic-list *ngIf="listDefinition$ | async"
                       [vmListDefinition]="listDefinition$ | async"
                       (selectEvent)="onListSelect($event)"
                       (pageEvent)="onListPage($event)"
                       (showChildEvent)="onShowChild($event)"
                       (deletingEvent)="onDeleting($event)">
      </fw-dynamic-list>
      <div class="app-btn-box">
        <button class="app-btn app-btn--primary"
                type="button"
                (click)="applyFilter()"
                [disabled]="!selectedUserFilter || (loading$ | async) || showingChild">
          Apply
        </button>
      </div>
    </ng-container>
  </div>
</div>
