/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit,   ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { IFormDefinition, FormDefinition } from '../../../../../fw/dynamic-forms';

@Component({
  selector: 'warranty-project-summary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './warranty-project-summary.component.html'
})
export class WarrantyProjectSummaryComponent implements OnInit {
  public formDefinition$: BehaviorSubject<IFormDefinition> = new BehaviorSubject(null);
  public projectId$: BehaviorSubject<number> = new BehaviorSubject(-1);

  constructor(public activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId$.next( +paramMap.get('id'));
      this.formDefinition$.next(new FormDefinition('Work Order Summary', false, false, 'project-summary'));
    });
  }

}
