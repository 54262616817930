import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { AdminEvent } from '../../enums/admin.enums';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IAllDynamicData, getSelectedRecord } from '../../../../../fw/dynamic-list/store';
import { IProviderRole } from '../../../view-models';
import { AdminSecurityService } from '../../services/admin-security.service';
import { IPrivilegeTemplate } from '../../../view-models/i-privilege-template';

@Component({
  selector: 'privilege-template-roles',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './privilege-template-roles.component.html'
})
export class PrivilegeTemplateRolesComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;
  privilegeTemplateId: number;
  subscription: Subscription = new Subscription();
  currentTemplate: IPrivilegeTemplate;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<IAllDynamicData>,
    public securityService: AdminSecurityService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.privilegeTemplateId = +paramMap.get('id');
     this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.showRelatedEntityManager:
        this.securityService.openSecurityEntityManager(this.listDefinition, this.currentTemplate);
        break;
      default:
        break;
    }
  }

  newRequest() {
    //get parent privilege template list def
    const ptListDef = this.securityService.loadPrivilegeTemplatesListDefinition();
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(ptListDef.storeName, -1, ptListDef.rowKeyId, this.privilegeTemplateId), take(1)))
      .subscribe(entity => {
        this.currentTemplate = entity;
      })
    );
    //get list def for the roles for the selected template.
    this.listDefinition = this.securityService.loadProviderRolesForPrivilegeTemplateListDefinition(this.privilegeTemplateId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
