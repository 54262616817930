import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { IListColumn, ListColumn, IListButtonType, OrderTerm } from '../../../../fw/dynamic-list/interfaces/index';
import { IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { ProjectListStore, ProjectEvent } from '../../project/enums/project.enums';
import { ScheduleStore } from '../../scheduler/enums/schedule.enums';
import { DocumentTypeName } from '../../../file-upload/enums/file-upload.enums';

import * as DynamicListActions from '../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromFeature from '../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { appConstants, IAppConstants } from '../../../../shared/constants';
import { UserPriviledgesService } from '../../../../auth/services';

@Injectable()
export class RequiredDocumentService {

  constructor(public store: Store<fromFeature.IAllDynamicData>,
    public dynamicListService: DynamicListService,
    public userPriviledgesService: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  dispatchRequiredDocumentsGet(projectId: number, canIEdit: boolean, forScheduler: boolean, workOrderId: number = 0): void {
    const docListDef: IListDefinition = forScheduler
      ? this.loadSchedulerWORequiredDocumentsListDefinition(projectId, workOrderId)
      : this.loadProjectRequiredDocumentsListDefinition(projectId, canIEdit);
      this.store.dispatch(new DynamicListActions.GetList({ listDefinition: docListDef, listFilter: docListDef.defaultListFilter, parentId: docListDef.parentId }));
  }

  // canEdit for Required Documents does not include check for project status
  // can modified required documents when a project is closed.
  loadProjectRequiredDocumentsListDefinition(projectId: number, canEdit: boolean) {
    const listColumns = this.loadProjectRequiredDocumentsListColumns();
    const listObjectLabel = 'Required Document';
    const listObjectController = 'EntityDocument';
    const listStoreName = ProjectListStore.projectEntityDocuments;
    const detailRoutePath = 'required-document-create'; //for create only
    const listRowKeyId = 'entityDocumentId';  
    const defaultListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false,
      currentPage: 1,
      searchTerm: [],
      orderTerm: [new OrderTerm('documentType_entity_entityName', true), new OrderTerm('documentType_sortOrder', true)]
    };

    let listDefinition = this.dynamicListService.createListDefinition('reqdDocCreateOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'RequiredByProject';
    listDefinition.methodParameters = projectId.toString();
    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'parentEntityPkid';
    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;
    listDefinition.revokeSort = true;

    if (canEdit) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);
    }

    //For LSWP docs, only allow user with canManageLSWP priv to delete.
    //For delete required a delete reason, unless canManageLSWP then just allow delete normally
    const delDefaults: ICustomButton = {
      title: '',
      icon: '',
      cssName: '',
      enabled: canEdit,
      enabledMethod: 'canDeleteRequiredDocument',  
      methodService: 'projectDynamicListService',
      eventName: ProjectEvent.deleteEntityDocument,
      inOverflow: false
    }

    let crudButtons: IListButtonType[] = [{ type: ButtonType.delete, defaults: delDefaults }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'View File',
        icon: 'fad fa-file-image',
        cssName: 'app-btn-icon--primary',
        enabled: true,
        eventName: ProjectEvent.showFile,
        enabledMethod: 'canDownloadDocument',  //use same method as download, same enabler
        methodService: 'projectDynamicListService'
      }
    );

    return listDefinition;
  }

  loadProjectRequiredDocumentsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('entityName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('entityIdLabel');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('documentType');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('properties');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('actions');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadSchedulerWORequiredDocumentsListDefinition(projectId: number, workOrderId: number) {
    const listColumns = this.loadSchedulerWORequiredDocumentsListColumns();
    const listObjectLabel = 'Measures';
    const listObjectController = 'EntityDocument';
    const listStoreName = ScheduleStore.scheduleMeasures;
    const detailRoutePath = '';
    const listRowKeyId = 'entityDocumentId';
    const defaultListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: false,
      currentPage: 1,
      searchTerm: [
        { term: 'documentType_documentTypeName', value: DocumentTypeName.measure, searchType: SearchType.Equals, columnName: 'documentType_documentTypeName', fieldType: this.myConstants.dataTypeString }
      ],
      orderTerm: []
    };
    //orderTerm: [new OrderTerm('documentType_entity_entityName', true), new OrderTerm('documentType_sortOrder', true)]

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByPurchaseOrderForWorkOrder';
    listDefinition.methodParameters = workOrderId.toString();
    listDefinition.parentId = projectId;
    listDefinition.parentKey = 'parentEntityPkid';
    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;
    listDefinition.revokeSort = true;

    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'View File',
        icon: 'fad fa-file-image',
        cssName: 'app-btn-icon--primary',
        enabled: true,
        eventName: ProjectEvent.showFile,
        enabledMethod: 'canDownloadDocument',  //use same method as download, same enabler
        methodService: 'projectDynamicListService'
      }
    );

    return listDefinition;
  }

  loadSchedulerWORequiredDocumentsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('entityIdLabel');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('actions');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }


}
