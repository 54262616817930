
export interface IJobScheduleJob
{
  jobScheduleJobId: number;
  jobScheduleJobName: string;
  jobSchedule_jobScheduleId: number;
  job_jobId: number;
  providerUser_providerUserId: number;

  //  extra data properties
  jobName: string;
  scheduleName: string;
  haveAlert: boolean;
  jobScheduleDescription: string;
  numberSubscribers: number;

}

export class JobScheduleJob implements IJobScheduleJob {

  constructor(
    public jobScheduleJobId: number = 0,
    public jobScheduleJobName: string = '',
    public jobSchedule_jobScheduleId: number = 0,
    public providerUser_providerUserId: number = 0,
    public job_jobId: number = 0,
    public jobName: string = '',
    public scheduleName: string = '',
    public haveAlert: boolean = false,
    public jobScheduleDescription: string = '',
    public numberSubscribers: number = 0

  ) { }
}
