import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ProjectService } from '../../services/project.service';
import { IProjectRouteParameter } from '../../interfaces/index';

//store actions, reducers, interfaces
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { ProjectListStore } from '../../enums/project.enums';

@Component({
  selector: 'warranty-scripts-container',
  templateUrl: './warranty-scripts-container.component.html'
})
export class WarrantyScriptsContainerComponent implements OnInit, OnDestroy { 
  public  workOrderId: number = 0;
  public title: string;
  public listDefinition: IListDefinition;
  projectId: number = -1;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public modalService: ModalService,
    public projectService: ProjectService,
    @Inject(appConstants) public myConstants: IAppConstants ) { }

  //Event and Error handling is performed in wrapper component 
  public onCancel(): void {
    this.modalService.close();
  }

  public newWarranty(): void {
    let params: IProjectRouteParameter = {
      storeName: ProjectListStore.workOrderWarranties,
      key: 'workOrder_workOrderId',
      portalId: 1,
      operation: this.myConstants.operationTypeCreate,
      portalEntityId: this.projectId,
      workOrderId: this.workOrderId,
      isWarranty: true,
      requestTime: new Date()
    }
    //ByWarrantyWorkOrder
    this.modalService.open({
      title: 'New Warranty Project',
      path: 'project-create',
      id: 0,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

  ngOnInit(): void {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.workOrderId = +paramMap.get('id');
      this.projectId = +paramMap.get('portalEntityId');
      this.newRequest();
    }));
  }

  newRequest(): void {
    this.title = 'Warranty Projects Applied to Work Order '.concat(this.workOrderId.toString());
    this.listDefinition = this.projectService.loadWorkOrderWarrantyListDefinition(this.workOrderId);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  } 
}
