import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, Inject, EventEmitter, SimpleChange, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IWorkOrder, IWarrantyType, IWarrantySubType, IWarranty, Warranty, WorkOrder, IProviderUser } from '../../../view-models/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { ProjectEvent } from '../../enums/project.enums';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services/index';
import { UserPriviledgesService } from '../../../../../auth/services';
import { SharedDashboardService } from '../../../portal-shared/services/shared-dashboard.service';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';

@Component({
  selector: 'warranty-general-information',
  templateUrl: './warranty-general-information.component.html',
  providers: [MetaDataService]
})

export class WarrantyGeneralInformationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() operation: string;
  @Input() warranty: IWarranty;
  @Input() warrantySubTypes: IWarrantySubType[];

  @Output() public warrantyTypeChangeEvent: EventEmitter<number> = new EventEmitter();
  @Output() public editEvent: EventEmitter<null> = new EventEmitter();
  @Output() public saveEvent: EventEmitter<IWarranty> = new EventEmitter();
  @Output() public cancelEvent: EventEmitter<null> = new EventEmitter();
  @Output() public reloadEvent = new EventEmitter();

  public canIEdit: boolean;
  public customButtons$: BehaviorSubject<ICustomButton[]> = new BehaviorSubject([]);
  public displayFields: string[] = ['warrantyWorkOrder', 'isUrgent', 'assignedUser', 'warrantyType', 'warrantySubType'];
  public warrantyTypes: IWarrantyType[];
  public assignedUsers: IProviderUser[];
  public baseWorkOrders: IWorkOrder[];
  public form: FormGroup;
  subscription: Subscription = new Subscription();

  constructor(public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public ups: UserPriviledgesService,
    public sharedDashboardService: SharedDashboardService  ) { }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case ButtonType.reload:
        this.reloadEvent.emit();
        break;
      case ButtonType.save:
        this.emitSave();
        break;
      case ButtonType.cancel:
        this.emitCancel();
        break;
      case ButtonType.edit:
        this.editEvent.emit();
        break;
      case ProjectEvent.printInstallerWarrantyNotice:
        this.sharedDashboardService.printDoc(this.warranty, 'warrantyNoticeUrl');
       break;
      default:
        void 0;
        break;
    }
  }

  ngOnInit(): void {
    this.canIEdit = this.ups.canIEdit(this.warranty);
    this.getMetaData();
    this.loadForm();
    this.setCustomButtons();
    this.warrantyTypeChangeListen();
  }

  setCustomButtons(): void {
    const btn: ICustomButton = {
      title: 'Print Installer Warranty Claim Notice',
      icon: 'fas fa-print',
      cssName: 'app-btn-icon--print',
      enabled: true,
      eventName: ProjectEvent.printInstallerWarrantyNotice
    }

    const btns: ICustomButton[] = [btn];
    this.customButtons$.next(btns);
  }

  emitSave() {
    const values: FormData = this.form.getRawValue();
    let warranty: Warranty = cloneDeep(this.warranty);
    warranty.warrantyWorkOrder_workOrderId = values.hasOwnProperty('warrantyWorkOrder') && values['warrantyWorkOrder']
          && values['warrantyWorkOrder'].hasOwnProperty('workOrderId')
      ? values['warrantyWorkOrder'].workOrderId
      : warranty.warrantyWorkOrder_workOrderId;
    warranty.assignedUser_providerUserId = values.hasOwnProperty('assignedUser') && values['assignedUser']
        && values['assignedUser'].hasOwnProperty('providerUserId')
      ? values['assignedUser'].providerUserId
      : null;
    warranty.isUrgent = values['isUrgent'];
    warranty.warrantyType_warrantyTypeId = values.hasOwnProperty('warrantyType') && values['warrantyType']
        && values['warrantyType'].hasOwnProperty('warrantyTypeId')
      ? values['warrantyType'].warrantyTypeId
      : null;
    warranty.warrantySubType_warrantySubTypeId = values.hasOwnProperty('warrantySubType') && values['warrantySubType']
        && values['warrantySubType'].hasOwnProperty('warrantySubTypeId')
      ? values['warrantySubType'].warrantySubTypeId
      : null;
    this.saveEvent.emit(warranty);
  }

  emitCancel() {
    this.cancelEvent.emit();
  }

  getMetaData(): void {
    this.mds.setFieldDefinitions('warrantyInformation');
    this.mds.loadSelectionLists(this.displayFields, this.operation, this.warranty.parentProjectId);
  }

  loadForm(): void {
    this.baseWorkOrders = this.mds.getSelectItems('warrantyWorkOrder');
    this.assignedUsers = this.mds.getSelectItems('assignedUser');
    this.warrantyTypes = this.mds.getSelectItems('warrantyType');
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.warranty, this.operation);
    if (this.warrantySubTypes && this.warranty.warrantySubType_warrantySubTypeId > 0) {
      const subType = this.warrantySubTypes.find(x => x.warrantySubTypeId === this.warranty.warrantySubType_warrantySubTypeId);
      this.form.controls['warrantySubType'].setValue(subType);
    }
  }

  warrantyTypeChangeListen(): void {
    this.subscription.add(this.mds.valueChanged$
      .subscribe((obj: IValueChanged) => {
        if (obj && obj.key === 'warrantyType') {
          if (obj.value) {
            const data: IWarrantyType = obj.value as IWarrantyType;
            this.warrantyTypeChangeEvent.emit(data.warrantyTypeId);
          } else {
            this.warrantyTypeChangeEvent.emit(0);
          }
        }
      }));
   }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['warranty'] && !changes['warranty'].isFirstChange()) {
      this.getMetaData();
      this.loadForm();
    }
    if (changes['warrantySubTypes'] && !changes['warrantySubTypes'].isFirstChange()) {
      if (this.warrantySubTypes && this.warranty.warrantySubType_warrantySubTypeId > 0) {
        const subType = this.warrantySubTypes.find(x => x.warrantySubTypeId === this.warranty.warrantySubType_warrantySubTypeId);
        this.form.controls['warrantySubType'].setValue(subType);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
