 <fw-component-title title="Create" entityLabel="Installer Work Crew Technician"></fw-component-title>
 <contact-manager #contactManager
                  [contactId]="0"
                  [isOrganization]="false"
                  [operationIn]="operation"
                  [canIEdit]="userPriviledgesService.canManageInstallers$ | async"
                  [contactTypeId]="contactConstantsService.contactTypeIdInstaller"
                  (managerEvent)="onManagerEvent($event)">
   <!--ng-content drop-->
   <installer-work-crew-technician-create #technicianCreate
                                          [requestTime]="requestTime"
                                          [installerId]="installerId"
                                          [workCrewId]="workCrewId">
   </installer-work-crew-technician-create>
   <!--ng-content drop-->
   <!--ng-content drop-->
   <div class="app-btn-box" *ngIf="ready">
     <button type="button" class="app-btn app-btn--cancel"
             (click)="onCancel()">
       Cancel
     </button>
     <button type="button" class="app-btn app-btn--primary"
             (click)="onCreate()"
             [disabled]="!(contactManager.isFormValid$ | async) || !technicianCreate.formIsValid()">
       Create
     </button>
   </div>
   <!--ng-content drop-->
 </contact-manager>

<fw-error-box [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>

