import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'project-file-viewer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project-file-viewer.component.html'
})
export class ProjectFileViewerComponent implements OnInit {
  public projectId: number;
  public canEdit: boolean = true;

  constructor(public  activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get('id');
    });

  }
}
