import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IErrorData, IResponseBase } from '../../../../../shared/interfaces/index';
import { IWorkOrder, IInstaller } from '../../../view-models/index';
import { getListByType } from "../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors";
import { getObjectByTypeById } from '../../../../../fw/dynamic-list/store';
import { IDynamicListState, IListObjectData } from "../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer";
import { UpdateObjectByIdModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { ProjectListStore, ProjectObjectStore } from '../../enums/project.enums';
import * as LoadingIndicatorActions from '../../../../../shared/store/loadingIndicator/loadingIndicator.actions';
import * as fromRoot from '../../../../store/reducers/index';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { DomainObjectService } from '../../../../../shared/services/domain-object.service'

@Component({
  selector: 'work-order-push-container',
  templateUrl: './work-order-push-container.component.html'
})
export class WorkOrderPushContainerComponent implements OnInit, OnDestroy  {
  @Input() workOrder: IWorkOrder;
  @Input() canIEdit: boolean;

  public installers$: BehaviorSubject<IInstaller[]> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public error$: BehaviorSubject<string> = new BehaviorSubject('');
  public loading$: Observable<boolean>;
  public isWarranty: boolean;
  subscription: Subscription = new Subscription();

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromFeature.IAllDynamicData>,
    public dos: DomainObjectService,
    public datePipe: DatePipe,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  public onPush(installerId: number) {
    const wo: IWorkOrder = { ...this.workOrder };
    const obj = {
      id: this.workOrder.workOrderId,
      installerId: installerId
    }
    const keyData: IKey = { storeName: ProjectListStore.projectWorkOrders, parentId: this.workOrder.project_projectId, key: 'workOrderId', id: this.workOrder.workOrderId }
    const emitter: IHomEventEmitter = { requestor: 'push-work-order', event: this.myConstants.emitterEventListReloadPortal, action: '', data: null };
    const updateData = new UpdateObjectByIdModel(keyData, 'WorkOrder', 'PushToAccountingNew', 'workOrderId', this.workOrder.workOrderId, obj, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectByIdList({ updateData }));
  }

  ngOnInit(): void {
    this.loading$ = this.rootStore.select('loadingIndicator').pipe(filter(x => x.requestor === 'push-wo'), map(x => x.show));
    this.rootStore.dispatch(new LoadingIndicatorActions.ShowSpinner({ requestor: 'push-wo', id: -1 }));
    this.getInstallers();
    //listen for state errors for push to accounting.
    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectWorkOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId === this.workOrder.project_projectId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          if (state.errorData.length) {
            this.errorData$.next(state.errorData);
          }
        }
      }));

    this.subscription.add(this.store.pipe(select(getObjectByTypeById(ProjectObjectStore.projectInformation, this.workOrder.project_projectId))).subscribe((objData) => {
      this.isWarranty = objData.data.isWarranty;
    }));
  }

  getInstallers(): void {
    this.error$.next('');
    this.errorData$.next([]);
    const today: string = this.datePipe.transform(Date.now(), 'MM/dd/yyyy');
    const url: string = 'Installer/'.concat(this.workOrder.providerLocation_providerLocationId.toString(), '/', this.workOrder.service_serviceId.toString(), '/', this.workOrder.generalContractorId.toString(), '?effectiveDate=', today);
    this.subscription.add(this.dos.getByUrl(url)
      .subscribe((result: IResponseBase) => {
        this.rootStore.dispatch(new LoadingIndicatorActions.HideSpinner({ requestor: 'push-wo', id: -1 }));
        if (result.success) {
          this.installers$.next(result.data);
        } else {
          this.errorData$.next(result.errorData);
        }
      },
        (error: any) => { this.error$.next(error) }
      ));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
