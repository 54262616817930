export interface IServiceGroup {
  serviceGroupId: number;
  provider_providerId: number;
  serviceGroupName: string;
  serviceGroupDescription: string;
}

export class ServiceGroup implements IServiceGroup {
  serviceGroupId: number;
  provider_providerId: number;
  serviceGroupName: string;
  serviceGroupDescription: string;

  constructor() {
    this.serviceGroupId = 0;
    this.provider_providerId = 0;
    this.serviceGroupName = '';
    this.serviceGroupDescription = '';
  }

}
