import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ManagerDashboardService } from '../../services/manager-dashboard.service';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { ICommunicationEventLog } from '../../../view-models/i-communication-event-log';

@Component({
  selector: 'communication-event-log',
  templateUrl: './communication-event-log.component.html'
})

export class CommunicationEventLogComponent implements OnInit, OnDestroy {

  public objectData: ICommunicationEventLog;
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public displayFields: string[] = ['communicationEventType', 'contactName', 'contactType', 'entity', 'entityPrimaryKeyId', 'logMessage', 'communicationText', 'providerUser', 'logDate'];
  public operation: string;
  public storeName: string = '';

  subscription: Subscription = new Subscription();

  key: string;
  objectId: number;
  myStoreName: string;

  constructor(public activeRoute: ActivatedRoute,
    public managerDashboardService: ManagerDashboardService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public store: Store<fromStore.IAllDynamicData>) { }

  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.setDetailConfig(paramMap);
      this.getDetail();
    }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.showNav = true;
    params.showTitle = true;
    params.wrapsForm = true;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.myStoreName, -1, this.key, this.objectId)))
      .subscribe(entity => {
        this.objectData = entity;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
