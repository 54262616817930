import { Component, OnInit,  ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IShipperPurchaseOrder } from '../../../view-models';

import { ManagerDashboardService } from '../../services/manager-dashboard.service';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';

@Component({
  selector: 'inventory-order-items',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './inventory-order-items.component.html'
})
export class InventoryOrderItemsComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public objectData$: Observable<IShipperPurchaseOrder>
  public displayFields: string[] = ['shipperName', 'purchaseOrderNumber', 'locationName', 'orderDate', 'expectedDate']
  public operation: string;
  public storeName: string = '';

  key: string = ''; 
  shipperPurchaseOrderId: number = 0;
  stateData: any;

  constructor(public activeRoute: ActivatedRoute,
    public managerDashboardService: ManagerDashboardService,
    public dynamicDetailService: DynamicDetailService,
    public store: Store<fromStore.IAllDynamicData>) { }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
     this.stateData = window.history.state;
     this.key = paramMap.get('key');
      this.shipperPurchaseOrderId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.setDetailConfig(paramMap);
      this.getRow();
      this.listDefinition$.next(this.managerDashboardService.loadDashInventoryOrderItemsListDefinition(this.shipperPurchaseOrderId));
    });
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    const standAlone = this.stateData ? this.stateData['standAlone'] : false;
    params.parentId = -1; //for dashShipperPos (not items)
    params.useRouterOutlet = false;
    params.showNav = standAlone ? false : true;
    params.showTitle = true;
    params.showCancel = standAlone ? false : true;
    this.detailConfig$.next(params);
  }

  getRow() {
    this.objectData$ = this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.storeName, -1, this.key, this.shipperPurchaseOrderId)));
  }
}
