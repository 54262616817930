import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from './services/fw-modal.service';
import { Store } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as fromRoot from '../../app/store/reducers/index';

@Component({
  selector: 'fw-modal',
  templateUrl: './fw-modal.component.html'
})

export class FwModalComponent implements OnInit, OnDestroy {
  public loading$: Observable<boolean>;
  subscription: Subscription = new Subscription();

  constructor(public modalService: ModalService,
    public rootStore: Store<fromRoot.IState>) { }

  ngOnInit(): void {
    this.subscription.add(this.modalService.ready$.pipe(filter((val: boolean) => val === true))
      .subscribe(val => {
        this.initLoading();
      }));
  }

  initLoading(): void {
    const storeName: string = this.modalService.definition.optionalParams && this.modalService.definition.optionalParams.hasOwnProperty('storeName')
      ? this.modalService.definition.optionalParams['storeName']
      : 'fw-content';

    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(filter(x => x.requestor === storeName), map(x => x.show));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
