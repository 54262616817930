import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ProjectService } from '../../services/project.service';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';

@Component({
  selector: 'po-view-external-data',
 templateUrl: './po-view-external-data.component.html'
})
export class PoViewExternalDataComponent implements OnInit {
  public listDefinition: IListDefinition;
  purchaseOrderId: number;

  constructor(public readonly activeRoute: ActivatedRoute,
    public projectService: ProjectService) { }


  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.purchaseOrderId = +paramMap.get('id'); 
    });
    this.listDefinition = this.projectService.loadPoExternalDataListDefinition(this.purchaseOrderId);
  }
   
}
