import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter,  take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { HomDecimalPipe } from 'hom-lib/hom-pipes';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IPoImportLine, PoImportLine, ISkuPrice, SkuPrice } from '../../../view-models/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { IListDefinition, ListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { PoImportService } from '../../services/po-import.service';
import { HomCommonUtility } from '../../../../../shared/services';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

//store actions, reducers, interfaces
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import {  getSelectedRecord } from '../../../../../fw/dynamic-list/store/index';
import { IKey, UpdateObjectByIdModel } from '../../../../../fw/dynamic-list/store/interfaces/index';

@Component({
  selector: 'po-import-line-container',
  templateUrl: './po-import-line-container.component.html'
})
export class PoImportLineContainerComponent implements OnInit, OnDestroy  {
  public poImportLine$: BehaviorSubject<IPoImportLine> = new BehaviorSubject(null);
  public skusPrices$: BehaviorSubject<ISkuPrice[]> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  poImportHeaderId: number = -1;
  poImportLineId: number = -1;
  storeName: string = '';
  key: string = '';
  operation: string;
  requestor: string;
  controller: string;
  subscription: Subscription = new Subscription();

  constructor(
    public selStore: Store<fromSelectionLists.IStoreState>,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public poImportService: PoImportService,
    public dynamicDetailService: DynamicDetailService,
    public utils: HomCommonUtility,
    public homDecimalPipe: HomDecimalPipe,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscription.add(this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.poImportHeaderId = +paramMap.get('id');
    }));

    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.poImportLineId = +paramMap.get('id');
      this.key = paramMap.get('key');
      this.storeName = paramMap.get('storeName');
      this.operation = paramMap.get('operation');
      this.setDetailConfig(paramMap);
      this.getDetail();
    }));

    this.subscription.add(this.poImportLine$.pipe(filter((entity) => entity !== null))
      .subscribe((entity: IPoImportLine) => {
        this.getSkuPrices(entity.branchProgramId);
      }));
  }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case this.myConstants.emitterEventUpdate:
        this.updateRecord(event);
        break;
      case this.myConstants.emitterEventClose:
        this.emitterService.emitListEvent(event);
        break;
      default:
        break;
    }
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.poImportHeaderId;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  getDetail() {
     this.subscription.add(this.store.pipe(
      select(getSelectedRecord(this.storeName, this.poImportHeaderId, this.key, this.poImportLineId)))
       .subscribe((entity) => {
        this.poImportLine$.next(entity === undefined && this.operation === this.myConstants.operationTypeCreate ? new PoImportLine() : cloneDeep(entity));
      }));

    const listDef: IListDefinition = this.poImportService.loadPoImportLineListDefinition(this.poImportHeaderId);
    this.requestor = listDef.detailRoutePath;
    this.controller = listDef.controllerName;
  }

  getSkuPrices(id: number) {
    this.skusPrices$.next(null);
    if (id === 0) {
      this.skusPrices$.next([]);
      this.errors$.next('Unable to retrieve pricing when parent program is not set');
      return;
    } else {
      this.errors$.next('');
    }
    this.subscription.add(this.selStore.pipe(
      select(fromSelectionLists.getSelectionListDataByType('skuPrice', id)))
      .subscribe(data => {
        if (data) {
          this.skusPrices$.next(data);
        } else {
          let listFilter = new ListFilter();
          listFilter.getAll = true;
          this.store.dispatch(new fromSelectionLists.GetEntityListById('SkuPrice', 'BranchProgramLookup', id, listFilter));
        }
      })
    );
  }

  updateRecord(e: IHomEventEmitter): void  {
    const keyData: IKey = { storeName: this.storeName, parentId: this.poImportHeaderId, key: this.key, id: this.poImportLineId, }
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventUpdate, action: e.action, data: null };
    const updateData = new UpdateObjectByIdModel(keyData, this.controller, 'Update', this.key, this.poImportLineId, e.data, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectByIdList({ updateData }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
