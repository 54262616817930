export interface IWarranty {
  warrantyId: number;   
  warrantyWorkOrder_workOrderId: number;   
  warrantyType_warrantyTypeId: number;
  warrantySubType_warrantySubTypeId: number;
  isUrgent: boolean;
  assignedUser_providerUserId: number;
  parentProjectId: number;
  active: boolean;
  approvalQueueTransactionId: number;

  updateDate: string;
}

export class Warranty implements IWarranty {
  warrantyId: number;
  warrantyWorkOrder_workOrderId: number;
  warrantyType_warrantyTypeId: number;
  warrantySubType_warrantySubTypeId: number;
  isUrgent: boolean;
  assignedUser_providerUserId: number;
  parentProjectId: number;
  active: boolean;
  approvalQueueTransactionId: number;

  updateDate: string;
  constructor() {
    this.warrantyId = 0;
    this.warrantyWorkOrder_workOrderId = null;
    this.warrantyType_warrantyTypeId = null;
    this.warrantySubType_warrantySubTypeId = null;
    this.isUrgent = false;
    this.assignedUser_providerUserId = null;
    this.parentProjectId = null;
    this.active = true;
    this.approvalQueueTransactionId = null;
  }

}
