export interface IContactType {
  contactTypeId: number;
  provider_providerId: number;
  contactTypeName: string;
  entity_entityId: number;
}

export class ContactType implements IContactType {
  contactTypeId: number;
  provider_providerId: number;
  contactTypeName: string;
  entity_entityId: number;

  constructor() {
    this.contactTypeId = 0;
    this.provider_providerId = null;
    this.contactTypeName = '';
    this.entity_entityId = null;
  }
}
