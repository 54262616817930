import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, filter , first } from 'rxjs/operators';

//store actions and reducers
import * as fromRoot from '../../../../app/store/reducers/index';
import * as fromSelectionLists from '../../../../shared/store/selectionLists/index';
import { GetEntityListByMethodParams, getSelectionListDataByType, InitSelectItems } from '../../../../shared/store/selectionLists/index';
import { IListFilter, OrderTerm } from '../../../../fw/dynamic-list/interfaces';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { AdminProviderService } from '../services/admin-provider.service';

//Special Circumstance Lookup resolver
@Injectable()
export class ShipperLocationPayoutResolver  {
  shipperId: number;
  storeName: string;
  key: string;
  subscription: Subscription = new Subscription();
  filter: IListFilter = {
    isLookup: false,
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true, //do not want paged data
    currentPage: 1,
    searchTerm: [],
    orderTerm: [new OrderTerm('payout.payoutName')]
  };

  constructor(public selectionListStore: Store<fromSelectionLists.IStoreState>,
    public rootStore: Store<fromRoot.IState>,
    public adminService: AdminProviderService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    //always reget since in admin module
    this.shipperId = +route.parent.paramMap.get('shipperId');
    this.key = route.paramMap.get('key');
    this.storeName = route.paramMap.get('storeName');
    this.selectionListStore.dispatch(new InitSelectItems({ storeName: 'shipperPayout', parentId: -1 }));

    this.requestData();

    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<any[]> {
    return this.selectionListStore.select(getSelectionListDataByType('shipperPayout', -1))
      .pipe(
        filter((data: any[]) => data !== null),
        map((data: any[]) => { return data; }),
        first()
      );

  }

  requestData(): void {
    //returns the payouts for this shipper, payout must exist to assign to a location for this shipper
    this.selectionListStore.dispatch(new GetEntityListByMethodParams(
      'WorkOrderItemShipper',
      'ByShipper',
      '?id=' + this.shipperId.toString(),
      this.filter,
      'shipperPayout' ));
  }

}
