<ng-container *ngIf="item">
  <fw-button-title-bar [title]="'Project File'"
                       [entityLabel]="item.fileName"
                       [canIEdit]="canIEdit"
                       [operation]="operation"
                       [showEdit]="true"
                       [showRefresh]="false"
                       [showDelete]="canIDelete"
                       [customButtons]="customButtons"
                       (customEvent)="onFileDetailEvent($event)">
  </fw-button-title-bar>

  <form *ngIf="form" [formGroup]="form" novalidate class="form portal__form"
        [ngClass]="{'portal__form--edit': operation !== myConstants.operationTypeDetails, 'file-viewer-detail--hidden': operation !== myConstants.operationTypeEdit}">
    <fw-dynamic-field [field]="mds.getFieldDefinition('fileName')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('includeInWorkOrderPacket')"
                      [form]="form"
                      [operation]="(canIncludeInWOPacket$ | async) ? operation : myConstants.operationTypeDetails">
    </fw-dynamic-field>
  </form>
  <fw-error-box [errorData]="errorData" [onForm]="true" [ignoreKey]="false"></fw-error-box>
  <fw-component-footer [lastUpdate]="item.updateDate"></fw-component-footer>
</ng-container>
