import { Component, OnInit, Input, Inject, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IWorkOrderViewModel, ISlotScheduleCalendar } from '../../../view-models';
import { IWeekViewData, IMonthViewData } from '../../interfaces';
import { UtilitiesEvent } from '../../enums/utilities.enums';

import { DispatchCalendarService } from '../../services/dispatch-calendar.service';
import { HomCommonUtility } from '../../../../../shared/services';

@Component({
  selector: 'dispatch-month-view',
  templateUrl: './dispatch-month-view.component.html'
})
export class DispatchMonthViewComponent implements OnInit, OnChanges {
  @Input() data: IWorkOrderViewModel[];
  @Input() selectedDate: string;
  @Input() holidays: string[];
  @Input() canScheduleLockDays: boolean;
  @Input() slotCalendars: ISlotScheduleCalendar[];

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public nbrWeeks: number;
  public daysOfWeek: string[] = [];
  public data$: BehaviorSubject<IMonthViewData[]> = new BehaviorSubject([]);
  public myView: string;

  constructor(public datePipe: DatePipe,
    public dispatchCalendarService: DispatchCalendarService,
    public homCommonUtility: HomCommonUtility,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public goToWeek(day: string): void {
    const emitter: IHomEventEmitter = {
      requestor: 'dispatch-month-view',
      event: UtilitiesEvent.goToWeek,
      data: day,
      action: ''
    }
    this.customEvent.emit(emitter);
  }

  public goToDay(day: string): void {
    const emitter: IHomEventEmitter = {
      requestor: 'dispatch-month-view',
      event: UtilitiesEvent.goToDay,
      data: day,
      action: ''
    }
    this.customEvent.emit(emitter);
  }

  public onCustom(event: IHomEventEmitter): void {
    this.customEvent.emit(event);
  }

  public lockDay(day: string): void {
    const emitter: IHomEventEmitter = {
      requestor: 'dispatch-month-view',
      event: UtilitiesEvent.showLockDayManager,
      data: day,
      action: ''
    }
    this.customEvent.emit(emitter);
  }


  ngOnInit() {
    this.myView = this.dispatchCalendarService.viewMonth;
    this.nbrWeeks = this.homCommonUtility.getWeeksInMonth(this.selectedDate);
    this.setDaysOfWeek();
    this.setData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( (changes['data'] && !(changes['data'].firstChange)) || 
      (changes['slotCalendars'] && !(changes['slotCalendars'].firstChange)) ) {
      this.setData();
    }
  }

  public setDaysOfWeek(): void {
    this.daysOfWeek = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  }

  setData(): void {
    if (!this.selectedDate) {
      return;
    }

    //create structure
    let counter: number = 1;
    let weeks: { weekInYear: string, firstDayOf: string, days: string[] }[] = [];
    let firstDayOfMonth: Date = this.homCommonUtility.getStartOfMonth(this.selectedDate);
    let theDay: Date = this.homCommonUtility.getStartOfWeek(this.datePipe.transform(firstDayOfMonth, 'MM/dd/yyyy'));

    while (counter <= this.nbrWeeks) {
      let dayCount: number = 1;
      let days: string[] = [];

      while (dayCount <= 7 ) {
        days.push( this.datePipe.transform(theDay, 'MM/dd/yyyy').toString() );
        theDay = theDay.addDays(1);
        dayCount = dayCount + 1;
      }

      weeks.push({
        weekInYear: this.datePipe.transform(this.homCommonUtility.getStartOfWeek(days[0]), 'w'),
        firstDayOf: this.datePipe.transform(this.homCommonUtility.getStartOfWeek(days[0]), 'MM/dd/yyyy'),
        days: days
      });
      counter = counter + 1;
    }

    //load with data
    let data: IMonthViewData[] = [];
    weeks.forEach(item => {
      data.push({
        weekInYear: item.weekInYear,
        firstDayOf: item.firstDayOf,
        days: this.getWeekViewData(item.days)
      })
    });
    this.data$.next(data);
  }

  getWeekViewData(days: string[]): IWeekViewData[] {
    let data: IWeekViewData[] = [];
    days.forEach(day => {
      data.push({
      day: day,
      isLocked: this.isDayLocked(day),
      canEdit: true,
      data: this.getDataForDay(day)
      })
    });
    return data;
  }

  getDataForDay(day: string): IWorkOrderViewModel[] {
    const thisDay = this.datePipe.transform(new Date(day), 'MM/dd/yyyy');
    const data = this.data.filter(x => thisDay >= this.datePipe.transform(x.scheduleStartDate, 'MM/dd/yyyy') && thisDay <= this.datePipe.transform(x.scheduleEndDate, 'MM/dd/yyyy'));
    return data ? data : [];
  }

  isDayLocked(day: string): boolean {
    const dayData = this.slotCalendars.find(x => this.datePipe.transform(new Date(x.day), 'MM/dd/yyyy') == day);
    return dayData && dayData.isLocked;
  }

}
