export interface ISurveyQuestionDataType {
  surveyQuestionDataTypeId: number;
  provider_providerId: number;
  questionTypeName: string;
  parameterType: string;
  canAggregate: boolean;
  minValue: number;
  maxValue: number;
}

export class SurveyQuestionDataType implements ISurveyQuestionDataType {
  surveyQuestionDataTypeId: number;
  provider_providerId: number;
  questionTypeName: string;
  parameterType: string;
  canAggregate: boolean;
  minValue: number;
  maxValue: number;

  constructor() {
    this.surveyQuestionDataTypeId = 0;
    this.provider_providerId = 1;
    this.questionTypeName = '';
    this.parameterType = '';
    this.canAggregate = false;
    this.minValue = 0;
  }
}
