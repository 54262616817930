import { Component, OnInit, OnDestroy, Inject, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { AdminStore } from '../../enums/admin.enums';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { AdminProviderService } from '../../services/admin-provider.service';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'communication-document-types',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './communication-document-types.component.html'
})
export class CommunicationDocumentTypesComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public userPriviledgesService: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public adminProviderService: AdminProviderService) { }

  subscription: Subscription = new Subscription();

  ngOnInit() {
    this.listDefinition = this.adminProviderService.loadCommunicationDocumentTypesListDefinition();
    this.subscription.add(this.store.pipe(select(fromStore.getListByType(AdminStore.communicationDocumentTypes)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == -1)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          if (state.event && state.event.event == this.myConstants.emitterEventDelete && (!state.errorData || !state.errorData.length) && state.data && state.data.length) {
            const listDefinition = this.listDefinition;
            this.store.dispatch(new DynamicListActions.GetList({ listDefinition: listDefinition, listFilter: listDefinition.defaultListFilter, event: null, parentId: -1 }))
          }
        }
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
