export interface IChargeBackSku {
  chargeBackSkuId: number;
  chargeBackSkuName: string;
  chargeBackSkuDescription: string;
  status: string;
  unitMeasure_unitMeasureId: number;
  chargeBackType_chargeBackTypeId: number;
  provider_providerId: number;
}

export class ChargeBackSku implements IChargeBackSku {
  chargeBackSkuId: number;
  chargeBackSkuName: string;
  chargeBackSkuDescription: string;
  status: string;
  unitMeasure_unitMeasureId: number;
  chargeBackType_chargeBackTypeId: number;
  provider_providerId: number;
}



