import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ISkuPayout } from '../../../view-models';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IErrorData, IResponseBase } from '../../../../../shared/interfaces';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { PayoutMaintEvent } from '../../enums/admin.enums';
import { appConstants, IAppConstants } from '../../../../../shared/constants';

import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { SetEventList } from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { DomainObjectService } from '../../../../../shared/services';
import { AdminPricingService } from '../../services/admin-pricing.service';

@Component({
  selector: 'payout-skus',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './payout-skus.component.html'
})
export class PayoutSkusComponent implements OnInit {
  public listDefinition: IListDefinition = null;
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  subscription: Subscription = new Subscription();
  payoutId: number = 0;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public adminPricingService: AdminPricingService,
    public modalService: ModalService,
    public dos: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.payoutId = +paramMap.get('id');
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case PayoutMaintEvent.unlinkSku:
        this.unlinkSku(event.data);
        break;
      case PayoutMaintEvent.linkSku:
        this.linkSku();
        break;
      default:
        break;
    }
  }

  newRequest(): void {
    this.listDefinition = this.adminPricingService.loadPayoutSkusListDefinition(this.payoutId);
  }

  unlinkSku(data: ISkuPayout): void {
    if (!data || !data.skuPayoutId) {
      this.errorData$.next([{ key: '__Model', value: ['Contact Support.  Sku Payout Id not found.'] }]);
      return;
    }

    this.errorData$.next([]);
    this.subscription.add(this.dos.deleteByMethodById('SkuPayout', 'Delete', data.skuPayoutId)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          const emitter: IHomEventEmitter = { requestor: 'payout-skus', event: this.myConstants.emitterEventListReload, action: '', data: null };
          this.store.dispatch(new SetEventList({
            storeName: this.listDefinition.storeName,
            parentId: this.listDefinition.parentId,
            event: emitter
          }));
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  linkSku(): void {
    this.modalService.open({
      title: 'Skus',
      path: 'payout-sku-link/' + this.payoutId.toString(),
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      isSubModal: true,
      sizeType: ModalSizeType.small,
    });
  }
}
