import { IMenuItem } from '../fw/fw-menus/interfaces/i-menu-item';

// uses fontawesome icons
export let initialMenuItems: Array<IMenuItem> = [
      {
        text: 'Admin Dashboard',
        routeName: 'admin-dash',
        icon: 'fa-user-shield',
        portalId: 10,
        subMenu: null
      },
      {
        text: 'Compliance',
        routeName: 'compliance',
        icon: 'fa-clipboard-check',
        portalId: 6,
        subMenu: null
      },
      {
        text: 'Conversations',
        routeName: 'all-sms-convos',
        icon: 'fa-comments-alt',
        portalId: 15,
        subMenu: null
      },
      {
        text: 'Manager Dashboard',
        routeName: 'mgr-dash',
        icon: 'fa-poll-people',
        portalId: 11,
        subMenu: null
      },
      {
        text: 'User Dashboard',
        routeName: 'user-dash',
        icon: 'fa-user',
        portalId: 2,
        subMenu: null
      },
      {
        text: 'Utilities',
        routeName: 'utilities',
        icon: 'fa-lightbulb',
        portalId: 13,
        subMenu: null
      },
      {
        text: 'Warranty Dashboard',
        routeName: 'warranty-dash',
        icon: 'fa-exclamation-triangle',
        portalId: 12,
        subMenu: null
      },
      {
        text: 'Administration',
        routeName: 'app-admin',
        icon: 'fa-user-shield',
        portalId: 14,
        subMenu: null
      }
];

