import {
  Component,
  ViewChild,
  TemplateRef,
  OnDestroy,
  ElementRef,
  ViewContainerRef,
  ApplicationRef,
  Injector,
  AfterViewInit
} from '@angular/core';
import { PopoverPosition } from './fw-popover.placement';
import { PopoverDirective } from './fw-popover.directive';

@Component({
  selector: 'fw-popover',
  templateUrl: './fw-popover.component.html'
})

export class PopoverComponent implements AfterViewInit, OnDestroy {

  constructor(protected element: ElementRef,
    protected viewContainerRef: ViewContainerRef,
    protected appRef: ApplicationRef,
    protected injector: Injector
  ) { }

  @ViewChild('popoverDiv', { read: ElementRef }) popoverDiv: ElementRef<HTMLElement>;
  @ViewChild('popoverArrow', { read: ElementRef }) popoverArrow: ElementRef<HTMLElement>;

  popoverDirective: PopoverDirective;
  hostElem: ElementRef<HTMLElement> | any;
  template: TemplateRef<any>;
  closeOnClickOutside: boolean;
  arrowColor: string;
  yPosMethod: Function | any;
  xPosMethod: Function | any;
  position: PopoverPosition;
  showFullOnMobile: boolean;
  isDynamic: boolean;
  popoverHeight: number;
  popoverWidth: number;
  public observer;


  toBottom = (): void => {
    this.popoverArrow.nativeElement.classList.add('popover-arrow--bottom');
    const arrowPos: ClientRect = this.popoverArrow.nativeElement.getBoundingClientRect();
    const hostPos: ClientRect = this.hostElem.nativeElement.getBoundingClientRect();
    this.popoverArrow.nativeElement.style.top = hostPos.top + hostPos.height + 'px';
    this.popoverDiv.nativeElement.style.top = hostPos.top + hostPos.height + arrowPos.height - 1 + 'px';
  }

  toTop = (): void => {
    this.popoverArrow.nativeElement.classList.add('popover-arrow--top');
    const arrowPos: ClientRect = this.popoverArrow.nativeElement.getBoundingClientRect();
    const hostPos: ClientRect = this.hostElem.nativeElement.getBoundingClientRect();
    this.popoverArrow.nativeElement.style.top = hostPos.top - arrowPos.height + 'px';
    this.popoverDiv.nativeElement.style.bottom = window.innerHeight - hostPos.top + arrowPos.height - 1 + 'px';
  }

  toSideY = (): void => {
    const popoverPos: ClientRect = this.popoverDiv.nativeElement.getBoundingClientRect();
    const arrowPos: ClientRect = this.popoverArrow.nativeElement.getBoundingClientRect();
    const hostPos: ClientRect = this.hostElem.nativeElement.getBoundingClientRect();
    const topRoom: number = hostPos.top + (hostPos.height / 2) - (popoverPos.height / 2);
    const bottomRoom: number = window.innerHeight - (hostPos.top + (hostPos.height / 2) + (popoverPos.height / 2));
    this.popoverArrow.nativeElement.style.top = hostPos.top + (hostPos.height / 2) - (arrowPos.height / 2) + 'px';
    this.popoverDiv.nativeElement.style.top = (topRoom < 0 ? 5 : bottomRoom < 0 ? (topRoom + bottomRoom - 5) : topRoom) + 'px';
  }

  toCenter = (): void => {
    const popoverPos: ClientRect = this.popoverDiv.nativeElement.getBoundingClientRect();
    const arrowPos: ClientRect = this.popoverArrow.nativeElement.getBoundingClientRect();
    const hostPos: ClientRect = this.hostElem.nativeElement.getBoundingClientRect();
    if (hostPos.left + (hostPos.width / 2) < window.innerWidth / 2) {
      this.popoverArrow.nativeElement.style.left = hostPos.left + (hostPos.width / 2) - (arrowPos.width / 2) + 'px';
      this.popoverDiv.nativeElement.style.left = (hostPos.left + (hostPos.width / 2) - (popoverPos.width / 2) < 0 ? 5 : hostPos.left + (hostPos.width / 2) - (popoverPos.width / 2)) + 'px';
    } else {
      this.popoverArrow.nativeElement.style.right = (window.innerWidth - hostPos.right) + (hostPos.width / 2) - (arrowPos.width / 2) + 'px';
      this.popoverDiv.nativeElement.style.right = (hostPos.left + (hostPos.width / 2) + (popoverPos.width / 2) > window.innerWidth ? 5 : (window.innerWidth - hostPos.right) + (hostPos.width / 2) - (popoverPos.width / 2)) + 'px';
    }
  }

  toLeft = (): void => {
    const popoverPos: ClientRect = this.popoverDiv.nativeElement.getBoundingClientRect();
    const arrowPos: ClientRect = this.popoverArrow.nativeElement.getBoundingClientRect();
    const hostPos: ClientRect = this.hostElem.nativeElement.getBoundingClientRect();
    this.popoverArrow.nativeElement.style.left = (this.position === PopoverPosition.left ? (hostPos.left - arrowPos.width) : (hostPos.left + (hostPos.width / 2) - (arrowPos.width / 2))) + 'px';
    if (hostPos.left > window.innerWidth / 2 && this.position === PopoverPosition.left) {
      this.popoverDiv.nativeElement.style.right = (window.innerWidth - hostPos.left) + arrowPos.width + 'px';
    } else {
      this.popoverDiv.nativeElement.style.left = (this.position === PopoverPosition.left ? (hostPos.left - arrowPos.width - popoverPos.width) : (hostPos.left - (popoverPos.width - hostPos.width))) + 'px';
    }
  }

  toRight = (): void => {
    const arrowPos: ClientRect = this.popoverArrow.nativeElement.getBoundingClientRect();
    const hostPos: ClientRect = this.hostElem.nativeElement.getBoundingClientRect();
    this.popoverArrow.nativeElement.style.left = (this.position === PopoverPosition.right ? (hostPos.right) : (hostPos.left + (hostPos.width / 2) - (arrowPos.width / 2))) + 'px';
    this.popoverDiv.nativeElement.style.left = (this.position === PopoverPosition.right ? (hostPos.right + arrowPos.width) : (hostPos.left)) + 'px';
  }

  toScreenCenterY = (): void => {
    const hostPos: ClientRect = this.hostElem.nativeElement.getBoundingClientRect();
    const popoverPos: ClientRect = this.popoverDiv.nativeElement.getBoundingClientRect();
    const center = hostPos.top + (hostPos.height / 2) - (popoverPos.height / 2);
    this.popoverDiv.nativeElement.style.top = (center >= 0 ? center : 5) + 'px';
  }

  toScreenCenterX = (): void => {
    const popoverPos: ClientRect = this.popoverDiv.nativeElement.getBoundingClientRect();
    this.popoverDiv.nativeElement.style.left = ((window.innerWidth - popoverPos.width) / 2) + 'px';
  }

  toScreenRight = (): void => {
    const arrowPos: ClientRect = this.popoverArrow.nativeElement.getBoundingClientRect();
    const hostPos: ClientRect = this.hostElem.nativeElement.getBoundingClientRect();
    this.popoverArrow.nativeElement.style.left = hostPos.left + (hostPos.width / 2) - (arrowPos.width / 2) + 'px';
    this.popoverDiv.nativeElement.style.right = '0px';
  }

  setPosition(isReset: boolean = false): void {
    if (isReset) {
      this.position = PopoverPosition.default;
      this.popoverDiv.nativeElement.style.removeProperty('top');
      this.popoverDiv.nativeElement.style.removeProperty('bottom');
      this.popoverDiv.nativeElement.style.removeProperty('left');
      this.popoverDiv.nativeElement.style.removeProperty('right');
      this.popoverArrow.nativeElement.style.removeProperty('top');
      this.popoverArrow.nativeElement.style.removeProperty('bottom');
      this.popoverArrow.nativeElement.style.removeProperty('left');
      this.popoverArrow.nativeElement.style.removeProperty('right');
      this.popoverArrow.nativeElement.classList.remove('popover-arrow--left');
      this.popoverArrow.nativeElement.classList.remove('popover-arrow--bottom');
      this.popoverArrow.nativeElement.classList.remove('popover-arrow--top');
      this.popoverArrow.nativeElement.classList.remove('popover-arrow--right');
      document.removeEventListener('scroll', this.yPosMethod, { capture: true });
    }
    if (this.position !== PopoverPosition.default) {
      if (this.position = PopoverPosition.screenRight) {
        this.yPosMethod = this.toBottom;
        this.xPosMethod = this.toScreenRight;
      } else {
        const positionParts: string[] = this.position.split('-');
        this.yPosMethod = this[positionParts[0] === PopoverPosition.top ? 'toTop' : positionParts[0] === PopoverPosition.bottom ? 'toBottom' : 'toSideY'];
        this.xPosMethod = this[positionParts.length > 1 ? positionParts[1] === PopoverPosition.left ? 'toLeft' : 'toRight' : positionParts[0] === PopoverPosition.left ? 'toLeft' : positionParts[0] === PopoverPosition.right ? 'toRight' : 'toCenter'];
      }
    } else {
      const popoverPos: ClientRect = this.popoverDiv.nativeElement.getBoundingClientRect();
      const arrowPos: ClientRect = this.popoverArrow.nativeElement.getBoundingClientRect();
      const hostPos: ClientRect = this.hostElem.nativeElement.getBoundingClientRect();
      const windowHeight: number = window.innerHeight;
      const windowWidth: number = window.innerWidth;
      const canTop: boolean = hostPos.top - arrowPos.height - popoverPos.height >= 0;
      const canBottom: boolean = hostPos.bottom + arrowPos.height + popoverPos.height <= windowHeight;
      const isLeft: boolean = hostPos.left + (hostPos.width / 2) < windowWidth / 2;

      if (canTop || canBottom) {
        this.popoverArrow.nativeElement.classList.add((canTop && canBottom) || canBottom ? 'popover-arrow--bottom' : 'popover-arrow--top');
        this.yPosMethod = this[(canTop && canBottom) || canBottom ? 'toBottom' : 'toTop'];
        this.xPosMethod = this.toCenter;
      } else {
        const leftRoom: number = hostPos.left - arrowPos.width - popoverPos.width;
        const rightRoom: number = windowWidth - (hostPos.right + arrowPos.width + popoverPos.width);
        if (leftRoom >= 0 || rightRoom >= 0) {
          this.position = PopoverPosition[leftRoom > rightRoom ? 'left' : 'right'];
          this.popoverArrow.nativeElement.classList.add('popover-arrow--' + (leftRoom > rightRoom ? 'left' : 'right'));
          this.yPosMethod = this.toSideY;
          this.xPosMethod = this[leftRoom > rightRoom ? 'toLeft' : 'toRight'];
        } else {
          this.popoverArrow.nativeElement.classList.add('popover-arrow--hidden');
          this.yPosMethod = this.toScreenCenterY;
          this.xPosMethod = this.toScreenCenterX;
        }
      }
    }
    this.yPosMethod();
    this.xPosMethod();
    if (isReset) document.addEventListener('scroll', this.yPosMethod, { capture: true });
  }

  onMouseDown = (evt: Event): void => {
    if (this.element.nativeElement.contains(evt.target) || this.hostElem.nativeElement.contains(evt.target)) return;
    this.popoverDirective.hide();
  }

  ngAfterViewInit(): void {
    if (this.showFullOnMobile && window.innerWidth <= 500) {
      this.popoverArrow.nativeElement.classList.add('popover-arrow--hidden');
      this.popoverDiv.nativeElement.style.top = '0px';
      this.popoverDiv.nativeElement.style.left = '0px';
    } else {
      const clientRect: ClientRect = this.popoverDiv.nativeElement.getBoundingClientRect();
      this.popoverHeight = clientRect.height;
      this.popoverWidth = clientRect.width;
      if (this.isDynamic) {
        const callback = () => {
          const clientRect: ClientRect = this.popoverDiv.nativeElement.getBoundingClientRect();
          if (clientRect.height !== this.popoverHeight || clientRect.width !== this.popoverWidth) {
            this.setPosition(true);
            this.popoverHeight = clientRect.height;
            this.popoverWidth = clientRect.width;
          }
        }
        this.observer = new MutationObserver(callback);
        this.observer.observe(this.popoverDiv.nativeElement, { attributes: false, childList: true, subtree: true });
      }
      this.setPosition();
      document.addEventListener('scroll', this.yPosMethod, { capture: true });
    }
    if (this.closeOnClickOutside) document.addEventListener('mousedown', this.onMouseDown);
  }

  ngOnDestroy(): void {
    if (!this.showFullOnMobile && window.innerWidth <= 500) document.removeEventListener('scroll', this.yPosMethod, { capture: true });
    if (this.closeOnClickOutside) document.removeEventListener('mousedown', this.onMouseDown);
  }

}
