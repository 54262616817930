import { ICustomDetailButton } from '../../dynamic-detail/interfaces';

 export interface IFormDefinition {
   formTitle: string;
    showPrev: boolean;
    showNext: boolean;
    requestor: string; //unique per component:  path name
   entityLabel: string;
   entityStatus: string;
}


 export class FormDefinition implements IFormDefinition {
   formTitle: string;
   showPrev: boolean;
   showNext: boolean;
   requestor: string; 
   entityLabel: string;
   entityStatus: string;
   customButtons: ICustomDetailButton[];

   constructor(formTitle: string, showPrev: boolean = false, showNext: boolean = false, requestor: string, entityLabel: string = '', entityStatus: string = '', customButtons: ICustomDetailButton[] = null) {
     this.formTitle = formTitle;
     this.entityLabel = entityLabel;
     this.showPrev =showPrev;
     this.showNext = showNext; 
     this.requestor = requestor;
     this.entityLabel = entityLabel;
     this.entityStatus = entityStatus;
     this.customButtons = customButtons;
   }
 }
