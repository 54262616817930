import { IDocumentTypeActionViewModel } from './i-document-type-action-view-model';
import { IDocumentTypePropertyViewModel } from './i-document-type-property-view-model';

export interface IDocumentType {
  documentTypeId: number;
  entity_entityId: number;
  documentTypeName: string;
  isExternal: boolean;
  isOutgoing: boolean;
  baseViewModel_entityId: number;
  isAssignable: boolean;
  createRule_ruleId: number;
  documentTemplate_fileUploadId: number;
  sortOrder: number;
  isVisible: boolean;
  isVersioned: boolean;
  alwaysCreateNew: boolean;
  includeInWorkOrderPacket: boolean;
  isRequired: boolean;
  allowMultiple: boolean;
  //extra data fields
  entityName: string;
  thumbUrl: string;
  fileUrl: string;
  fileUrl_fileSource: any; //used in ui only to hold file
  actions: IDocumentTypeActionViewModel[];
  properties: IDocumentTypePropertyViewModel[];
}

export class DocumentType implements IDocumentType {
  documentTypeId: number;
  entity_entityId: number;
  documentTypeName: string;
  isExternal: boolean;
  isOutgoing: boolean;
  baseViewModel_entityId: number;
  isAssignable: boolean;
  createRule_ruleId: number;
  documentTemplate_fileUploadId: number;
  sortOrder: number;
  isVisible: boolean;
  isVersioned: boolean;
  alwaysCreateNew: boolean;
  includeInWorkOrderPacket: boolean;
  isRequired: boolean;
  allowMultiple: boolean;

  entityName: string;
  thumbUrl: string;
  fileUrl: string;
  fileUrl_fileSource: any
  actions: IDocumentTypeActionViewModel[];
  properties: IDocumentTypePropertyViewModel[];

  constructor() {
    this.documentTypeId = 0;
    this.entity_entityId = null;
    this.documentTypeName = '';
    this.isExternal = false;
    this.isAssignable = false;
    this.isOutgoing = false;
    this.baseViewModel_entityId = null;
    this.sortOrder = 0;
    this.isVisible = true;
    this.isVersioned = false;
    this.alwaysCreateNew = false;
    this.includeInWorkOrderPacket = false;
    this.createRule_ruleId = null;
    this.isRequired = false;
    this.allowMultiple = false;
  }
}
