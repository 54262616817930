  <fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <fw-dynamic-detail *ngIf="objectData"
            [displayFields]="displayFields"
            [detailConfig]="detailConfig">
  </fw-dynamic-detail>
</fw-dynamic-detail-container>

  <div class="app-info">
    <i class="fas fa-info-circle app-icon--primary"></i>
    If the document type needs a rule associated to it, you will need to manually create the rule and associate it at this time.
  </div>
