import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { orderBy } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { AdminStore, RelatedParentType } from '../../enums/admin.enums';
import { getSelectedRecord } from '../../../../../fw/dynamic-list/store/index';
import { IResponseBase } from '../../../../../shared/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';

import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { DomainObjectService } from '../../../../../shared/services';
import { RelatedEntityService } from '../../services/related-entity.service';

@Component({
  selector: 'related-entity-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './related-entity-manager.component.html',
  providers: [RelatedEntityService]
})
export class RelatedEntityManagerComponent implements OnInit, OnDestroy {
  public availableEntities$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public type: string = 'Entity';
  public title: string;
  public entityLabel: string = '';
  controllerName: string;
  subscription: Subscription = new Subscription();
  storeName: string;
  key: string;
  currentParent: any; 
  currentEntityKey: string;
  allEntityKey: string;
  labelKey: string;
  valueKey: string;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public dos: DomainObjectService,
    public modalService: ModalService,
    public relatedEntityService: RelatedEntityService,
    @Inject(appConstants) public myConstants: IAppConstants ) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.relatedEntityService.parentId = +paramMap.get('id');
      this.storeName = paramMap.get('storeName');
      this.key = paramMap.get('key');
      this.newRequest();
    });
  }

  public addEntities(entities: any[]): void {
    this.relatedEntityService.errorData$.next(null);
    this.relatedEntityService.errors$.next('');
    if (!entities || entities.length === 0) {
      return;
    }

    this.working$.next(true);

    const model = this.setModel(entities);
    const methodName = this.setMethodName();

    this.subscription.add(this.dos.updateByMethod(this.controllerName, methodName, model)
      .subscribe((response: IResponseBase) => {
        this.working$.next(false);
        if (response.success) {
          this.reloadEntityList();
          this.cancel();
        } else {
          this.relatedEntityService.errorData$.next(response.errorData);
        }
      }));
  }

  public cancel(): void {
    this.modalService.close();
  }

  newRequest() {
    this.relatedEntityService.setParentType(this.storeName);
    const parentListDef = this.relatedEntityService.setParentListDef();
    if (parentListDef == null) {
      console.log('DEV ERROR:  Configuration is wrong.  Missing parent list definition.  See method setParentListDef in relatedEntityService');
    }
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(parentListDef.storeName, -1, parentListDef.rowKeyId, this.relatedEntityService.parentId)))
      .subscribe(entity => {
        this.currentParent = entity;
        this.relatedEntityService.generalContractorId = entity.hasOwnProperty('generalContractor_generalContractorId')
          ? entity['generalContractor_generalContractorId']
          : entity.hasOwnProperty('generalContractorId')
            ? entity['generalContractorId']
            : 0;
      })
    );
    this.setListeners();
    this.getAllEntities();
  }

  setModel(entities: any[]): any {
    switch (this.relatedEntityService.parentType) {
      case RelatedParentType.providerRole:
        return { roleId: this.relatedEntityService.parentId, ids: entities.map(x => x.value) };
      case RelatedParentType.privilegeTemplate:
        return { privilegeTemplateId: this.relatedEntityService.parentId, ids: entities.map(x => x.value) };
      default:
        return { id: this.relatedEntityService.parentId, ids: entities.map(x => x.value) };
    }
  }

  //BulkCreate is the default
  setMethodName(): any {
    switch (this.relatedEntityService.parentType) {
      case RelatedParentType.privilegeTemplate:
        return 'BulkCreateByTemplate';
      case RelatedParentType.programService:
        return 'BulkCreateByProgramService';
      case RelatedParentType.service:
        return 'BulkCreateByService';
      case RelatedParentType.branch:
      case RelatedParentType.region:
        switch (this.storeName) {
          case AdminStore.branchProviderLocation:
            return 'BulkCreateByBranch';
          case AdminStore.regionBranches:
            return 'BulkCreateByRegion';
          default:
            return 'BulkCreate';
        }
      default:
        return 'BulkCreate';  
    }
  }

  //NO CACHING - Must know these are fresh
  //Provider Users is the only thing cached - for security-entity-manager (resolver)
  getAllEntities(): void {
    switch (this.storeName) {
      case AdminStore.rolePrivilegeTemplates:
        this.title = 'Role Entity Manager';
        this.type = 'Privilege Template';
        this.controllerName = 'PrivilegeTemplateProviderRole';
        this.currentEntityKey = 'privilegeTemplate_privilegeTemplateId';
        this.allEntityKey = 'privilegeTemplateId';
        this.labelKey = 'name';
        this.valueKey = 'privilegeTemplateId';
        this.relatedEntityService.getAllPrivilegeTemplates();
        break;
      case AdminStore.roleUsers:
        this.title = 'Role Entity Manager';
        this.type = 'Provider User';
        this.controllerName = 'UserRoleViewModel';
        this.currentEntityKey = 'providerUserId';
        this.allEntityKey = 'providerUserId';
        this.labelKey = 'entityLabel';
        this.valueKey = 'providerUserId';
        this.relatedEntityService.getAllProviderUsersTemplates();
       break;
      case AdminStore.roleWidgets:
        this.title = 'Role Entity Manager';
        this.type = 'Widget';
        this.controllerName = 'WidgetProviderRole';
        this.currentEntityKey = 'widget_widgetId';
        this.allEntityKey = 'widgetId';
        this.labelKey = 'widgetName';
        this.valueKey = 'widgetId';
        this.relatedEntityService.getAllWidgets();
        break;
      case AdminStore.privilegeTemplateRoles:
        this.title = 'Privilege Template Entity Manager';
        this.type = 'Provider Role';
        this.controllerName = 'PrivilegeTemplateProviderRole';
        this.currentEntityKey = 'providerRole_providerRoleId';
        this.allEntityKey = 'providerRoleId';
        this.labelKey = 'roleName';
        this.valueKey = 'providerRoleId';
        this.relatedEntityService.getAllProviderRoles();
        break;
      case AdminStore.branchPrograms:
        this.title = 'Branch Entity Manager';
        this.type = 'Program';
        this.controllerName = 'BranchProgram';
        this.currentEntityKey = 'programService_programServiceId';
        this.allEntityKey = 'programServiceId';
        this.labelKey = 'entityLabel';
        this.valueKey = 'programServiceId';
        this.relatedEntityService.getWorkingLists(this.storeName);
        break;
      case AdminStore.psBranchPrograms:
        this.title = 'Program Service Entity Manager';
        this.type = 'Branch';
        this.controllerName = 'BranchProgram';
        this.currentEntityKey = 'branch_branchId';
        this.allEntityKey = 'branchId';
        this.labelKey = 'branchName';
        this.valueKey = 'branchId';
        this.relatedEntityService.getWorkingLists(this.storeName);
        break;
      case AdminStore.branchProviderLocation:
        this.title = 'Branch Entity Manager';
        this.type = 'Provider Location';
        this.controllerName = 'ProviderLocationBranch';
        this.currentEntityKey = 'providerLocation_providerLocationId';
        this.allEntityKey = 'providerLocationId';
        this.labelKey = 'locationName';
        this.valueKey = 'providerLocationId';
        this.relatedEntityService.getWorkingLists(this.storeName);
        break;
      case AdminStore.branchRegions:
        this.title = 'Branch Entity Manager';
        this.type = 'Region';
        this.controllerName = 'BranchRegion';
        this.currentEntityKey = 'region_regionId';
        this.allEntityKey = 'regionId';
        this.labelKey = 'regionName';
        this.valueKey = 'regionId';
        this.relatedEntityService.getWorkingLists(this.storeName);
        break;
      case AdminStore.regionBranches:
        this.title = 'Region Entity Manager';
        this.type = 'Branch';
        this.controllerName = 'BranchRegion';
        this.currentEntityKey = 'branch_branchId';
        this.allEntityKey = 'branchId';
        this.labelKey = 'branchName';
        this.valueKey = 'branchId';
        this.relatedEntityService.getWorkingLists(this.storeName);
        break;
      case AdminStore.serviceGroupServices:
        this.title = 'Service Group Service Entity Manager';
        this.type = 'Service';
        this.controllerName = 'ServiceGroupService';
        this.currentEntityKey = 'service_serviceId';
        this.allEntityKey = 'serviceId';
        this.labelKey = 'serviceName';
        this.valueKey = 'serviceId';
        this.relatedEntityService.getWorkingLists(this.storeName);
        break;
      case AdminStore.psFiles:
        this.title = 'Program Service Document Type Manager';
        this.type = 'Document Type';
        this.controllerName = 'ProgramServiceDocumentType';
        this.currentEntityKey = 'documentType_documentTypeId';
        this.allEntityKey = 'documentTypeId';
        this.labelKey = 'documentTypeName';
        this.valueKey = 'documentTypeId';
        this.relatedEntityService.getWorkingLists(this.storeName);
        break;
      case AdminStore.locationBranches:
        this.title = 'Provider Location Entity Manager';
        this.type = 'Branch';
        this.controllerName = 'ProviderLocationBranch';
        this.currentEntityKey = 'branch_branchId';
        this.allEntityKey = 'branchId';
        this.labelKey = 'branchName';
        this.valueKey = 'branchId';
        this.relatedEntityService.getWorkingLists(this.storeName);
        break;
      default:
        break;
    }
  }

  setListeners(): void {
    this.subscription.add(this.relatedEntityService.currentEntities$.subscribe((data: any[]) => {
      if (data && this.relatedEntityService.allEntities$.value) {
        this.loadAvailableEntities();
      }
    }));
    this.subscription.add(this.relatedEntityService.allEntities$.subscribe((data: any[]) => {
      if (data && this.relatedEntityService.currentEntities$.value) {
        this.loadAvailableEntities();
      }
    }));
  }

  loadAvailableEntities(): void {
    let options: any[] = [];
    const usedIds: number[] = this.relatedEntityService.currentEntities$.value.map(x => x[this.currentEntityKey]);
    const unUsed: any[] = [];
    this.relatedEntityService.allEntities$.value.forEach((item: any) => {
      if (!usedIds.includes(item[this.allEntityKey])) {
        unUsed.push(item);
      }
    });
    unUsed.forEach(item => {
      const option: any = { label: item[this.labelKey], value: item[this.valueKey] };
      options.push(option);
    });
    this.availableEntities$.next(orderBy(options, ['label'], ['asc']));
  }

  reloadEntityList(): void {
    const emitter: IHomEventEmitter = { requestor: 'related-entity-manager', event: this.myConstants.emitterEventListReload, action: '', data: null };
    this.store.dispatch(new DynamicListActions.SetEventList({
      storeName: this.storeName,
      parentId: this.relatedEntityService.parentId,
      event: emitter
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
