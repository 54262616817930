import { Component, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ISmsFileTypeSelectEvent } from '../../interfaces/i-sms-entity-select-event';
import { IRadioButton } from '../../../../fw/fw-shared/components/fw-radio-button/interfaces/i-radio-button';
import { SmsEntityName } from '../../enums/sms.enums';
import { IDocumentType } from '../../../portals/view-models';
import { appConstants, IAppConstants } from '../../../../shared/constants';
import { IInputButton } from '../../../../fw/fw-shared/interfaces';
import { DocumentTypeActionTypeName } from '../../../file-upload/enums/file-upload.enums';
import { IDocumentTypeActionViewModel } from '../../../portals/view-models/index_two';

@Component({
  selector: 'sms-file-type-selection',
  templateUrl: './sms-file-type-selection.component.html'
})
export class SmsFileTypeSelectionComponent implements OnInit {
  @Input() documentTypes: IDocumentType[];

  @Output() public select = new EventEmitter<ISmsFileTypeSelectEvent>(); //entity file type id

  public title: string = 'Associate the File';
  public entityTypeBtns: IRadioButton[] = [];
  public cbButtons$: BehaviorSubject<IInputButton[]> = new BehaviorSubject([]);
  public fileTypes$: BehaviorSubject<IDocumentType[]> = new BehaviorSubject([]);
  public isValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedDocumentTypeId: number = -1;
  selectedEntityName: string = '';
  selectedActionIds: number[] = [];
  subscription = new Subscription();

  constructor(@Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit(): void {
    this.setRadioButtonConfigs();
    this.selectedEntityName = '';
    this.selectedDocumentTypeId = -1;
  }

  public selectEntityType(val: string): void {
    this.selectedDocumentTypeId = -1;
    this.selectedEntityName = val;
    this.loadFileTypes(val);
    this.cbButtons$.next([]);
    this.selectedActionIds = [];
    this.setIsValid();
  }

  public selectFileType(id: number): void {
    this.selectedDocumentTypeId = id;
    this.selectedActionIds = [];
    this.configureCheckBoxes(id);
    this.setIsValid();
  }

  public onActionSelect(actionName: string, value: any): void {
    //add to list of selected
    const selectedDocumentType = this.documentTypes.find(x => x.documentTypeId == this.selectedDocumentTypeId);
    if (!selectedDocumentType || !selectedDocumentType.actions) return;

    var selectedAction = selectedDocumentType.actions.find(x => x.actionName == actionName);
    if (selectedAction) {
      const indx = this.selectedActionIds.findIndex(x => x === selectedAction.documentTypeActionId);
      if (indx === -1 && value) {
        this.selectedActionIds.push(selectedAction.documentTypeActionId);
      }
      else if (indx > -1 && !value) {
        this.selectedActionIds.splice(indx, 1);
      }
    }
  }

  public onNext(): void {
    if (this.selectedDocumentTypeId > 0 && this.selectedEntityName !== '') {

      this.select.emit({
        fileTypeId: this.selectedDocumentTypeId,
        entityName: this.selectedEntityName,
        actionIds: this.selectedActionIds
      });
    }
 }

  setRadioButtonConfigs(): void {
    this.entityTypeBtns = [
      {
        label: 'Project',
        value: SmsEntityName.project,
        id: SmsEntityName.project,
        checked: false
      },
      {
        label: 'Purchase Order',
        value: SmsEntityName.purchaseOrder,
        id: SmsEntityName.purchaseOrder,
        checked: false
      },
      {
        label: 'Work Order',
        value: SmsEntityName.workOrder,
        id: SmsEntityName.workOrder,
        checked: false
      }
    ];
  }

  configureCheckBoxes(documentTypeId: number): void {
    this.cbButtons$.next([]);
    let selectableBtns: IInputButton[] = [];
    const selectedDocumentType = this.documentTypes.find(x => x.documentTypeId == documentTypeId);

    if (selectedDocumentType && selectedDocumentType.actions && selectedDocumentType.actions.length > 0) {
      selectedDocumentType.actions.forEach(x => {
        if (this.isSelectableActionType(x)) {
          selectableBtns.push({
            label: {
              label: x.actionName,
              alignRight: true,
              title: 'Click to Complete ' + x.actionName
            }
          });
      }
      });
    }

    this.cbButtons$.next(selectableBtns);
  }

  isSelectableActionType(action: IDocumentTypeActionViewModel): boolean {
    return action.actionTypeName === DocumentTypeActionTypeName.generateFile
      || action.actionTypeName === DocumentTypeActionTypeName.uploadFile
      || action.actionTypeName === DocumentTypeActionTypeName.automatic ? false : true;
  }

  loadFileTypes(entityName: string): void {
    //filter file types based on selected project or po file
    if (!entityName || !this.documentTypes || this.documentTypes.length === 0) {
      this.fileTypes$.next([]);
      return;
    }
    this.fileTypes$.next(this.documentTypes.filter(x => x.isAssignable && x.entityName.toLowerCase() === entityName.toLowerCase() ));
   }

  setIsValid(): void {
    this.isValid$.next(this.selectedDocumentTypeId > 0 && this.selectedEntityName !== '');
  }
}
