<div [ngClass]="{ 'loading-mask': (loading$ | async)  }">
  <fw-component-title [title]="'Push Work Order To Accounting'"></fw-component-title>
  <br />
  <work-order-push *ngIf="(installers$ | async)"
                   [workOrder]="workOrder"
                   [installers]="installers$ | async"
                   [canIEdit]="canIEdit"
                   [isWarranty]="isWarranty"
                   (pushEvent)="onPush($event)">
  </work-order-push>
  <fw-error-box [errorData]="errorData$ | async" [errors]="error$ | async" [ignoreKey]="true"></fw-error-box>
</div>
