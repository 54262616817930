import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IPayout } from '../../../view-models';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { AdminPricingService } from '../../services/admin-pricing.service';
import { MetaDataService } from '../../../../../fw/dynamic-list/services';
//READONLY - NO BUTTONS - CUSTOM DETAIL FOR use above list of child records

@Component({
  selector: 'provider-payout',
  templateUrl: './provider-payout.component.html',
  providers: [MetaDataService]
})
export class ProviderPayoutComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public payout: IPayout = null;
  public displayFields: string[] = ['payoutName', 'payoutStatus', 'unitMeasure', 'controlsCapacity', 'glCodeOverride', 'serviceCodeOverride'];
  public operation: string;
  subscription: Subscription = new Subscription();
  payoutId: number = 0;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public adminPricingService: AdminPricingService,
    public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public ngOnInit() {
    this.operation = this.myConstants.operationTypeDetails;
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.payoutId = +paramMap.get('id');
      this.newRequest();
    });
  }

  newRequest(): void {
    this.getPayout();
    const def: IListDefinition = this.adminPricingService.loadProviderPayoutsListDefinition();
    this.mds.setFieldDefinitions(def.storeName);
    this.mds.loadSelectionLists(this.displayFields, 'provider-payouts', -1);
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.payout, this.myConstants.operationTypeDetails);
  }

  getPayout(): void {
    const def: IListDefinition = this.adminPricingService.loadProviderPayoutsListDefinition();
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(def.storeName, def.parentId, def.rowKeyId, this.payoutId)))
      .subscribe(entity => {
        this.payout = cloneDeep(entity);
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
