/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges  } from '@angular/core';

import { Subscription, BehaviorSubject } from 'rxjs';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, OrderTerm } from '../../../../../fw/dynamic-list/interfaces/index';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';

@Component({
  selector: 'installer-workcrews',
  templateUrl: './installer-workcrews.component.html'
})
export class InstallerWorkCrewsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() installerId: number;
  @Input() canEdit: boolean;

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  subscription: Subscription = new Subscription();

  constructor(public dynamicListService: DynamicListService) { }

  ngOnInit() {
      this.loadListDefinition();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['installerId'] && !changes['installerId'].isFirstChange()) {
      this.loadListDefinition();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Installer Work Crews';
    const listObjectController = 'InstallerWorkCrew';
    const listStoreName = 'installerWorkCrews';
    const detailRoutePath = 'installer-work-crew'; //for create only
    const listRowKeyId = 'installerWorkCrewId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('installerWorkCrewName')];

    let listDefinition = this.dynamicListService.createListDefinition('workCrewCreateOutlet',
        listObjectLabel,
        listObjectController,
        listStoreName,
        listRowKeyId,
        defaultListFilter,
        listColumns,
        detailRoutePath);

    listDefinition.controllerMethod = 'ByInstaller';
    listDefinition.methodParameters = this.installerId.toString();
    listDefinition.parentId = this.installerId;
    listDefinition.parentKey = 'installer_installerId';
    listDefinition.allowSaveFilter = false;

    if (this.canEdit) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);
    }

    this.listDefinition$.next(listDefinition);
  }

  loadListColumns(): IListColumn[] {
      let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('installerWorkCrewName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('numberTechnicians');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isActive');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('locationServices');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

      return columnDefinitions;
  }

}
