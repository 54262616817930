export interface ICertification {
  certificationId: number;
  isRequired: boolean;
  certificationName: string,
  certificationDescription: string,
  global: boolean,
  certificationLevel: string,
  certificationTypeName: string,
  validatesParent: boolean,
  createDate: string, 
  updateDate: string,
  createdBy: number,
  updatedBy: number,
  provider_providerId: number,
  certificationType_lookupValueId: number,
  rowVersion: string,
  metaData: any,
  entityLabel: string,

  isActive: boolean
}

export class Certification implements ICertification {

    constructor(
        public certificationId:number = 0,
        public isRequired = false,
        public certificationName = '',
        public certificationDescription = '',
        public global = false,
        public certificationLevel = '',
        public certificationTypeName = '',
        public validatesParent = false,
        public createDate: string = '', //example: "/Date(1306380319000)/",
        public updateDate: string = '',
        public createdBy: number = 0,
        public updatedBy: number = 0,
        public provider_providerId: number = 0,
        public certificationType_lookupValueId: number = 0,
        public rowVersion: string = '',
        public metaData: any = [],
        public entityLabel: string = '',

        public isActive: boolean = false
    ) {
    }

}
