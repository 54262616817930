export interface IContactMechanismCategoryType {
  contactMechanismCategoryTypeId: number;
  contactMechanismCategory_contactMechanismCategoryId: number;
  contactMechanismCategoryTypeName: string;
}

export class ContactMechanismCategoryType implements IContactMechanismCategoryType {
  contactMechanismCategoryTypeId: number;
  contactMechanismCategory_contactMechanismCategoryId: number;
  contactMechanismCategoryTypeName: string;

  constructor() {
    this.contactMechanismCategoryTypeId = 0;
    this.contactMechanismCategory_contactMechanismCategoryId = null;
    this.contactMechanismCategoryTypeName = '';
  }
}
