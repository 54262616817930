export interface ISssGeneralContractor {
  sssGeneralContractorId: number;
  provider_providerId: number;
  generalContractor_generalContractorId: number;
  excludeAllPrograms: boolean;
  dateRange: number;
  allowSlotBufferReservations: boolean;
  etaBuffer: number;
  minimumDuration: number;
}

export class SssGeneralContractor implements ISssGeneralContractor {
  sssGeneralContractorId: number;
  provider_providerId: number;
  generalContractor_generalContractorId: number;
  excludeAllPrograms: boolean;
  dateRange: number;
  allowSlotBufferReservations: boolean;
  etaBuffer: number;
  minimumDuration: number;

  constructor() {
    this.sssGeneralContractorId = 0;
    this.provider_providerId = null;
    this.generalContractor_generalContractorId = null;
    this.excludeAllPrograms = false;
    this.dateRange = 0;
    this.allowSlotBufferReservations = false;
    this.etaBuffer = 0;
    this.minimumDuration = 0;
  }
}
