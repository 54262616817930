export interface IChargeBackSkuPrice {
  chargeBackSkuPriceId: number;
  chargeBackSkuName: string;
  entityLabel: string;
  isGlobal: boolean;
  price: number;
  chargeBackSku_chargeBackSkuId: number;
  service_serviceId: number;
  providerLocation_providerLocationId: number;
  installer_installerId: number;
}

export class ChargeBackSkuPrice implements IChargeBackSkuPrice {
  chargeBackSkuPriceId: number;
  chargeBackSkuName: string;
  entityLabel: string;
  isGlobal: boolean;
  price: number;
  chargeBackSku_chargeBackSkuId: number;
  service_serviceId: number;
  providerLocation_providerLocationId: number;
  installer_installerId: number;
}



