import { IPurchaseOrderExternalData } from './index_two';

export interface IPoDefaults {
  measureTemplate: any,
  poNumLength: number,
  poNumFormatType: string,
  poNumber: number,
  poNumPrefix: number,
  poRegexString: string
}

export interface IPurchaseOrder {
  purchaseOrderId: number;   
  project_projectId: number;
  branchProgram_branchProgramId: number;
  purchaseOrderNumber: string;
  orderStatus_lookupValueId: number;
  orderDate: string;
  closeDate: string;
  flexField1: string;
  flexField2: string;
  measureService_measureServiceId: number;
  permitNumber: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
 

  //extra data
  sum: number;
  providerMarginHigh: number;
  providerMarginLow: number;
  purchaseOrderStatus: string;
  poMeasureEligible: boolean;
  poCloseEligible: boolean;
  poCanAddItems: boolean;
  hasExternalExtraData: boolean;
  hasExternalDocs: boolean;
  accountingPaymentTotal: number;
  measureTemplate: string;
  purchaseOrderAccntSyncLog: string;
  purchaseOrderProgramServiceName: string;
  externalExtraData: IPurchaseOrderExternalData[];
}


export class PurchaseOrder implements IPurchaseOrder {
  purchaseOrderId: number;
  project_projectId: number;
  branchProgram_branchProgramId: number;
  purchaseOrderNumber: string;
  orderStatus_lookupValueId: number;
  orderDate: string;
  closeDate: string;
  flexField1: string;
  flexField2: string;
  measureService_measureServiceId: number;
  permitNumber: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
  //extra data
  sum: number;
  providerMarginHigh: number;
  providerMarginLow: number;
  purchaseOrderStatus: string;
  poMeasureEligible: boolean;
  poCloseEligible: boolean;
  poCanAddItems: boolean;
  hasExternalExtraData: boolean;
  hasExternalDocs: boolean;
  accountingPaymentTotal: number;
  measureTemplate: string;
  purchaseOrderAccntSyncLog: string;
  purchaseOrderProgramServiceName: string;
  externalExtraData: IPurchaseOrderExternalData[];

  constructor() {
    this.purchaseOrderId = 0;
    this.project_projectId = null;
    this.branchProgram_branchProgramId = null;
    this.orderStatus_lookupValueId = null;
    this.purchaseOrderNumber = '';
    this.orderDate = '';
    this.closeDate = '';
    this.flexField1 = null;
    this.flexField2 = null;
    this.measureService_measureServiceId = null;
    this.permitNumber = '';

    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';

  }

}

