export interface ISurveyQuestion {
  surveyQuestionId: number;
  survey_surveyId: number;
  surveyQuestionText: string;
  required: boolean;
  allowMultiple: boolean;
  surveyQuestionDataType_surveyQuestionDataTypeId: number;
  usedInScoring: boolean;
  isSurveyDate: boolean;
  impactsCrewAssignmentRate: boolean;
}

export class SurveyQuestion implements ISurveyQuestion {
  surveyQuestionId: number;
  survey_surveyId: number;
  surveyQuestionText: string;
  required: boolean;
  allowMultiple: boolean;
  surveyQuestionDataType_surveyQuestionDataTypeId: number;
  usedInScoring: boolean;
  isSurveyDate: boolean;
  impactsCrewAssignmentRate: boolean;

  constructor() {
    this.surveyQuestionId = 0;
    this.survey_surveyId = null;
    this.surveyQuestionText = '';
    this.required = false;
    this.surveyQuestionDataType_surveyQuestionDataTypeId  = null;
    this.usedInScoring = false;
    this.isSurveyDate = false;
    this.impactsCrewAssignmentRate = false;
  }
}
