import { Component, OnInit, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IPurchaseOrderInventoryAllocation } from '../../../view-models';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IKey, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { UserDashEvent } from '../../enums/user-dashboard.enums';

import { UserDashboardService } from '../../services/user-dashboard.service';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';

@Component({
  selector: 'unreceived-inventory',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './unreceived-inventory.component.html'
})
export class UnreceivedInventoryComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public selectedItems: IPurchaseOrderInventoryAllocation[] = [];

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromFeature.IAllDynamicData>,
    public userDashboardService: UserDashboardService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public receiveInventory(): void {
    const def = this.listDefinition$.getValue();
    const keyData: IKey = { storeName: def.storeName, parentId: def.parentId, key: def.rowKeyId, id: this.selectedItems[0].purchaseOrderInventoryAllocationId, skipDataUpdate: true };
    const emitter: IHomEventEmitter = { requestor: 'project-unreceived-inventory', event: this.myConstants.emitterEventListReload, action: '', data: null };
    const updateObj = { purchaseOrderInventoryAllocationIds: this.selectedItems.map(x => x.purchaseOrderInventoryAllocationId) }
    const updateData = new UpdateObjectCustomModel(keyData, def.controllerName, 'ReceiveItems', updateObj, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
    this.selectedItems = [];
  }

  public onPage(): void {
    this.selectedItems = [];
  }

  public onSelect(event: IHomEventEmitter) {
    switch (event.event) {
      case UserDashEvent.receiveInventory:
        this.updateSelected(event);
        break;
      default:
        break;
    }
  }

ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.userDashboardService.loadUnreceivedInventoryListDefinition());
    });
  }

  updateSelected(event: IHomEventEmitter): void {
    const item: IPurchaseOrderInventoryAllocation = event.data;
    const exists: number = this.selectedItems.findIndex(x => x.purchaseOrderInventoryAllocationId === item.purchaseOrderInventoryAllocationId);
    if (event.action) {
      if (exists === -1) {
        this.selectedItems.push(item);
      }
    } else {
      if (exists > -1) {
        this.selectedItems.splice(exists, 1);
      }
    }
  }
}
