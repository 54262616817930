import { Injectable } from '@angular/core';

@Injectable()
export class BodyService {
  isWorking = false;

  //Allows entire body to be masked and protected while background work is performed
  setWorking(visible: boolean) {
    this.isWorking = visible;
  }
}
