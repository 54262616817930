import { Component, Input, OnInit, EventEmitter, Output, OnChanges, SimpleChanges} from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { ICustomButton } from '../../interfaces/i-custom-button';

@Component({
  selector: 'fw-accordian-single',
  templateUrl: './fw-accordian-single.component.html'
})
export class AccordianSingleComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() title: string;
  @Input() activeItem: string;
  @Input() scollable: boolean = true;
  @Input() subTitle: string = '';
  @Input() customBtn: ICustomButton = null;
  @Input() wrapPad: boolean = true;

  @Output() toggled = new EventEmitter<string>();
  @Output() customEvent = new EventEmitter<IHomEventEmitter>();

  contentVisible: boolean;
  customClicked: boolean;

  ngOnInit() {
    this.contentVisible = this.name === this.activeItem;
  }

  toggleActive(): void {
    if (this.customClicked) {
      this.contentVisible = true;
      this.customClicked = false;
    } else {
      this.contentVisible = !this.contentVisible;
      this.toggled.emit(this.name);
    }
  }

  onCustom(): void {
    this.customClicked = true;
    const event: IHomEventEmitter = { requestor: 'fw-accordian-single', event: this.customBtn.eventName, action: '', data: this.name };
    this.customEvent.emit(event);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activeItem'] && !changes['activeItem'].isFirstChange()) {
      this.contentVisible = this.name === this.activeItem;
    }
  }

}
