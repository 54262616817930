import { Component, OnInit, OnDestroy, Inject  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, IListButtonType } from '../../../../../fw/dynamic-list/interfaces/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { DynamicListMethod } from '../../../../../fw/dynamic-list/enums/dynamic-list.enum';
import { UserPriviledgesService } from '../../../../../auth/services';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'pls-certification-list',
  templateUrl: './pls-certification-list.component.html'
})
export class PlsCertificationListComponent implements OnInit,  OnDestroy  {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public emitterService: HomEventEmitterService,
    public userPriviledgesService: UserPriviledgesService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
     const certificationId = +paramMap.get('id');
      this.listDefinition$.next(null);
      this.listDefinition$.next(this.loadListDefinition(certificationId));
    });
  }

  loadListDefinition(certificationId: number): IListDefinition {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Requiring Locations';
    const listObjectController = 'ProviderLocationServiceCertificationViewModel';  
    const listStoreName = 'plsCertifications';   
	  const detailRoutePath = 'pls-entity-detail';  
    const listRowKeyId = 'providerLocationId';
    const canIManage: boolean = this.userPriviledgesService.canManageInstallers$.value;
    let defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition('plsCertificationOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = certificationId;
    listDefinition.parentKey = 'certificationId';

    listDefinition.controllerMethod = 'GetProviderLocationServicesByCertification';
    listDefinition.methodParameters = certificationId.toString();
    listDefinition.showFilter = false;
    listDefinition.showPrevNext = true;

    if (canIManage) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);

      const enabledDefaults: ICustomButton = {
        title: '', icon: '', cssName: '', enabled: true,
        enabledMethod: DynamicListMethod.alwaysEnabled, eventName: ''
      };
      let crudButtons: IListButtonType[] = [
        { type: ButtonType.detail, defaults: enabledDefaults },
        { type: ButtonType.edit, defaults: enabledDefaults },
        { type: ButtonType.delete, defaults: null }
      ];
      listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    }

    return listDefinition;
  }

    loadListColumns(): IListColumn[] {
        let columnDefinitions: Array<IListColumn> = [];

        let colDef = new ListColumn('locationName');
        columnDefinitions.push(colDef);

        colDef = new ListColumn('serviceNames');
        //this is a group by locationName, concatenation of all service names - ExtraData in the controller
        columnDefinitions.push(colDef);

        return columnDefinitions;
    }
  

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
