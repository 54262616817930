import { Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { take, map, filter } from 'rxjs/operators';
import { cloneDeep, isArray, toString } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../shared/constants';
import { IDetailContainerConfig, DetailContainerConfig } from '../../../dynamic-detail/interfaces';
import {
  IUserListFilter, UserListFilter, IListComponent, IListDefinition,
  ISearchTerm, IUserListFilterViewModel, IUserListFilterColumnViewModel, IUserListFilterDateViewModel, IUserListFilterColumn, IRelativeDateParameters
} from '../../interfaces';
import { IRadioButton } from '../../../fw-shared/components/fw-radio-button/interfaces/i-radio-button';
import { IInputButton } from '../../../fw-shared/interfaces';
import { IFieldDefinition, DynamicFieldService } from '../../../dynamic-forms';
import {
  IAllDynamicData, getListByType, getEntityListByParentId, IListObjectData, SetListDefinition,
  UpdateObjectCustomList, CreateObjectList, IDynamicListState, getSelectedRecord, GetList, getMetaDataByType, IMetaDataState
} from '../../store';
import { UpdateObjectCustomModel, IKey, CreateObjectModel } from '../../store/interfaces';

import * as fromRoot from '../../../../app/store/reducers/index';
import { DynamicListService, MetaDataService, IValueChanged } from '../../services';
import { DynamicDetailService } from '../../../dynamic-detail/services/dynamic-detail.service';
import { UserPriviledgesService } from '../../../../auth/services';
import { DynamicListStore } from '../../enums/dynamic-list.enum';
import { ModalService } from '../../../fw-modal/services/fw-modal.service';
import { HomCommonUtility } from '../../../../shared/services';

@Component({
  selector: 'fw-dynamic-list-user-filter',
  templateUrl: './dynamic-list-user-filter.component.html',
  providers: [MetaDataService]
})

export class DynamicListUserFilterComponent implements OnInit, OnDestroy {
  public objectData: IUserListFilter;
  public displayFields = ['filterName', 'isDefault'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public loading$: Observable<boolean>;
  public invalidSetup: boolean = false;
  public dateTypeBtns: Array<Array<IRadioButton>> = new Array<Array<IRadioButton>>();
  public dateSignBtns: Array<Array<IRadioButton>> = new Array<Array<IRadioButton>>();
  public form: FormGroup;
  public cbConfig: IInputButton;

  userListFilterId: number;
  parentId: number;
  parentStoreName: string;
  parentFieldDefinitions: IFieldDefinition[] = [];
  storeName: string;
  operation: string;
  filterDescription$: BehaviorSubject<string> = new BehaviorSubject('');
  errors$: BehaviorSubject<string> = new BehaviorSubject('');
  searchTerms: ISearchTerm[] = [];
  listComponentId: number;
  userListFiltersDefinition: IListDefinition;
  subscription: Subscription = new Subscription();
  cols: IUserListFilterColumnViewModel[] = [];
  dateTerms: IUserListFilterDateViewModel[] = [];
  userFilterConstants = {
  relativeDateType:  'relative',
  fixedDateType: 'fixed',
  rangeSearchType: 'Range',
  changeTypeDate: 'dateType',
  changeTypeSign: 'dateSign',
  changeTypeOffset: 'dateOffset'
  }

  constructor(public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<IAllDynamicData>,
    public dynamicListService: DynamicListService,
    public dynamicDetailService: DynamicDetailService,
    public dynamicFieldService: DynamicFieldService,
    public userPriviledgesService: UserPriviledgesService,
    public emitterService: HomEventEmitterService,
    public modalService: ModalService,
    public mds: MetaDataService,
    public utils: HomCommonUtility,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.userListFilterId = +paramMap.get('id');
      this.operation = paramMap.get('operation');

      //these values and how used vary based on coming in on create (store and parent will be from the parent list)
      //and edit (store and parent will be from the userListFilter list)
      this.storeName = paramMap.get('storeName');
      this.parentId = +paramMap.get('portalEntityId'); 

      //fix this so can handle create and detail using the same properties after get out of this if.  Should not need to get the list def.
      if (this.operation === this.myConstants.operationTypeCreate) {
        const listComponent: IListComponent = this.userPriviledgesService.getListComponentByStore(this.storeName);
        if (listComponent == null || listComponent.listComponentId === -1) {
          this.invalidSetup = true;
          return;
        }
        this.listComponentId = listComponent.listComponentId;
        this.getUserFilterListDefinition();
        this.getFromListLastSavedFilter();
        this.setErrors();
        this.objectData = new UserListFilter(this.userPriviledgesService.currentUserId$.value, this.listComponentId, '', true, false, '', 0);
      } else {
        this.listComponentId = this.parentId;
        this.userListFiltersDefinition = this.dynamicListService.loadUserListFiltersDefinition(this.listComponentId);
        const listComponent: IListComponent = this.userPriviledgesService.getListComponentByComponentId(this.listComponentId);
        this.parentStoreName = listComponent ? listComponent.storeName : null;
        this.getUserFilterRecord();
      }
      this.setDetailConfig(paramMap);
      this.setSubscribes();

      this.cbConfig = {
        label: {
          label: 'Default',
          alignRight: true,
          title: 'Is Default'
        }
      };
      this.cols = this.getUserListFilterColumns();
      this.filterDescription$.next(this.getUserSearchTermDescription(this.cols));
      this.setDateFields();
      this.mds.setFieldDefinitions(this.userListFiltersDefinition.storeName);
      this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.objectData, this.myConstants.operationTypeCreate);
    });

    this.subscription.add(this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null))
      .subscribe((obj: IValueChanged) => {
        this.setErrors();
      }));
  }

  changeIsDefault(newValue: boolean): void {
    this.objectData.isDefault = newValue;
    this.setErrors();
  }

  // Used to process date form changes
  dateChange(changeType: string, dateChange: string, dateCol: IUserListFilterDateViewModel): void {
    switch (changeType) {
      case (this.userFilterConstants.changeTypeDate):
        if (dateChange === 'current') {
          dateCol.termValue = this.myConstants.userListFilterRelativeDate;
          dateCol.dateType = this.userFilterConstants.relativeDateType;
        } else {
          dateCol.termValue = dateCol.originalTermValue;
          dateCol.dateType = this.userFilterConstants.fixedDateType;
        }
        break;
      case (this.userFilterConstants.changeTypeSign):
        dateCol.dateSign = dateChange;
        break;
      case (this.userFilterConstants.changeTypeOffset):
        dateCol.dateOffset = +dateChange;
        break;
    };
    // if we are dealing with a range, make sure the other value is the same date type
    let otherDateCol = this.dateTerms.filter(x => x.termName === dateCol.termName && x.dateType !== dateCol.dateType && x.termPosition !== dateCol.termPosition)[0];
    if (dateCol.searchType === this.userFilterConstants.rangeSearchType && changeType === this.userFilterConstants.changeTypeDate) {
      if (otherDateCol) {
        otherDateCol.dateType = dateCol.dateType;
        if (dateCol.dateType === this.userFilterConstants.relativeDateType) otherDateCol.termValue = this.myConstants.userListFilterRelativeDate; else otherDateCol.termValue = otherDateCol.originalTermValue;
      }
      // Now lets find the button to make checked
      let working = cloneDeep(this.dateTypeBtns);
      working.forEach((buttons: IRadioButton[]) => {
        buttons.forEach((button: IRadioButton) => {
          if (button.id.startsWith(otherDateCol.dateType.concat('_').concat(otherDateCol.termName))) button.checked = true;
          else if (button.id.includes(otherDateCol.termName)) button.checked = false;
        })
      });
      this.dateTypeBtns = cloneDeep(working);
    }

    this.setErrors();

    // create new overall description
    let workingCols = cloneDeep(this.cols);
    let constructedDate = '';
    let oldTermName = '';
    this.dateTerms.filter(x => x.dateType === dateCol.dateType).forEach((term: IUserListFilterDateViewModel) => {
      if (oldTermName !== term.termName) constructedDate = '';
      oldTermName = term.termName;
      let colTerm = workingCols.filter(x => x.term === term.termName)[0];
      let constructedDateTerm = dateCol.dateType === this.userFilterConstants.relativeDateType ? this.myConstants.userListFilterRelativeDate : term.originalTermValue;
      if (dateCol.dateType === this.userFilterConstants.relativeDateType) {
        if (term.dateOffset && term.dateOffset > 0) {
          constructedDateTerm = constructedDateTerm.concat(term.dateSign || '+').concat(term.dateOffset.toString());
        }
      }
      term.termValue = constructedDateTerm;
      constructedDate = constructedDate ? constructedDate.concat(' - ').concat(constructedDateTerm) : constructedDateTerm;
      colTerm.searchValue = constructedDate;
    });
    this.filterDescription$.next(this.getUserSearchTermDescription(workingCols));

    // fix search term descriptions
    let newDesc = '';
    this.dateTerms.forEach((term: IUserListFilterDateViewModel) => {
      newDesc = term.termPosition !== 0 ? newDesc : '';
      const constructedValue = dateCol.dateType === this.userFilterConstants.relativeDateType ? this.myConstants.userListFilterRelativeDate.concat(term.dateOffset > 0 ? (term.dateSign || '+') : '').concat(term.dateOffset > 0 ? term.dateOffset.toString() : '') : term.originalTermValue;
      const desc = this.dynamicListService.getFormattedFilterTermInfo(<ISearchTerm>{
              term: term.termName,
              value: constructedValue,
              searchType: term.searchType,
              displayValues: '',
              fieldType: term.fieldType,
              columnName: term.modelName
              });
      newDesc = newDesc ? newDesc + desc.replace(term.formattedTermName.concat(' ').concat('Between'), ' and') : desc;
      term.description$.next(newDesc)
    });
  }

  setSubscribes(): void {
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(
        filter(x => x.requestor ===  DynamicListStore.userListFilters),
        map(x => x.show));

    //subscribe to watch for errors and events 
    this.subscription.add(this.store.pipe(select(getListByType(DynamicListStore.userListFilters)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.listComponentId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          if (state.event && (!state.errorData || !state.errorData.length)) {
            if (state.event.event === this.myConstants.emitterEventListReload) {
              const storeData = cloneDeep(state);
              this.store.dispatch(new GetList({ listDefinition: storeData.listDefinition, listFilter: storeData.listFilter, parentId: storeData.listDefinition.parentId, event: null }));
              if (this.modalService.opened && state.event.action === this.myConstants.emitterEventModalSaveAndClose) {
                this.modalService.close();
              }
            }
          }
        }
      }));
    if (this.parentStoreName)
      this.subscription.add(this.store.pipe(select(getMetaDataByType(this.parentStoreName), take(1)))
          .subscribe((state: IMetaDataState) => {
              this.parentFieldDefinitions = state.fieldDefinitions;
            }
          ));
  }   

  getFromListLastSavedFilter(): void {
    this.subscription.add(this.store.pipe(select(getEntityListByParentId(this.storeName, this.parentId)), take(1))
      .subscribe((state: IListObjectData) => {
        const objData = cloneDeep(state);
        if (objData && objData.listFilter) {
          this.searchTerms = this.dynamicListService.getDateNormalizedUserFilterTerms(objData.listFilter.searchTerm);
        }
      }));
  }

  getUserFilterListDefinition(): void {
    this.subscription.add(this.store.pipe(select(getEntityListByParentId(DynamicListStore.userListFilters, this.listComponentId)), take(1))
      .subscribe((state: IListObjectData) => {
        if (!state || !state.listDefinition) {
          //set list definition
          this.userListFiltersDefinition = this.dynamicListService.loadUserListFiltersDefinition(this.listComponentId);
          this.store.dispatch(new SetListDefinition({ storeName: this.userListFiltersDefinition.storeName, parentId: this.userListFiltersDefinition.parentId, listDefinition: this.userListFiltersDefinition }));
        } else {
          const objData = cloneDeep(state);
          this.userListFiltersDefinition = objData.listDefinition;
        }
      }));
  }

  getUserSearchTermDescription(cols: IUserListFilterColumnViewModel[]): string {
    return this.dynamicListService.getFormattedFilterInfo(cols.map((term: IUserListFilterColumnViewModel) => <ISearchTerm> {
          term: term.term,
          value: term.searchValue,
          searchType: term.searchType,
          columnName: term.modelName,
          fieldType: term.fieldType,
          displayValues: term.displayValue
        }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let config: IDetailContainerConfig = new DetailContainerConfig(this.userListFilterId,
      DynamicListStore.userListFilters, this.userListFiltersDefinition.rowKeyId,
      this.operation,
      false,
      false,
      new Date().toString(),
      -1);
    config.parentId = this.listComponentId;
    this.detailConfig$.next(config);
  }

  getUserFilterRecord() {
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(DynamicListStore.userListFilters, this.listComponentId, this.userListFiltersDefinition.rowKeyId, this.userListFilterId)))
      .subscribe(entity => {
        this.objectData = cloneDeep(entity);
      })
    );
  }

  public onCancel() {
    if (this.modalService.opened) {
      this.modalService.close();
      this.errors$.next('');
      return;
      }
  }

  public getErrors(): string[] {
    let formValid = !this.form || !this.form.controls || !this.form.controls['filterName'] ? false : this.form.controls['filterName'].valid;
    let errorArray: string[] = [];
    if (!formValid) errorArray.push('Name is required.'); 
    // if we have any date terms with range, make sure they are in the proper order (low -> high)
    if (this.dateTerms && this.dateTerms.length > 0 && this.dateTerms.every(x => x.dateOffset < 0)) errorArray.push('Date offsets must be greater than zero');
    if (this.dateTerms.filter(x => x.searchType === this.userFilterConstants.rangeSearchType).length > 0) {
      var groupByTerm: IUserListFilterDateViewModel[][] = [];
      this.dateTerms.filter(x => x.searchType === this.userFilterConstants.rangeSearchType).forEach(function (a) {
        groupByTerm[a.termName] = groupByTerm[a.termName] || [];
        groupByTerm[a.termName].push(a);
      });
      for (var key in groupByTerm) {
        if (!(groupByTerm[key].every(v => v.dateType === this.userFilterConstants.relativeDateType) || groupByTerm[key].every(v => v.dateType === this.userFilterConstants.fixedDateType)))
          errorArray.push('All like date terms must be either fixed or relative.');
        let temp = groupByTerm[key].sort((a, b) => a.termPosition - b.termPosition);
        if (temp.length != 2) errorArray.push('Invalid date term arguments.');
        if (temp[0].dateType === this.userFilterConstants.relativeDateType) {
          let firstValue = +temp[0].dateSign.concat('1') * temp[0].dateOffset;
          let secondValue = +temp[1].dateSign.concat('1') * temp[1].dateOffset;
          if (firstValue >= secondValue) errorArray.push('First term in a date range must be less than the second term.');
        }
        else {
          let firstValue = new Date(temp[0].termValue);
          let secondValue = new Date(temp[1].termValue);
          if (firstValue >= secondValue) errorArray.push('First term in a date range must be less than the second term.');
        }
      }
    }
    return errorArray;
  }

  public isValid(): boolean {
    return this.form && this.form.valid && !this.errors$.value ? true : false;
  }

  setErrors(): void {
    var errors = this.getErrors();
    this.errors$.next(errors.length === 0 ? '' : errors.join('  '));
    if (errors.length === 0) {
    }
  }

  createRecord() {
    if (!this.isValid()) return;
    let userListFilter: IUserListFilter = this.setCommonProperties(this.form.getRawValue());

    // apply checkbox value to form field
    userListFilter.isDefault = this.objectData.isDefault;

    // Now we can start applying the date parameters to the cols (if any)
    if (this.dateTerms.length > 0) {
      this.dateTerms.filter(x => x.dateType === this.userFilterConstants.relativeDateType).forEach((term: IUserListFilterDateViewModel) => {
        let colTerm = this.cols.filter(x => x.term === term.termName)[0];
        let constructedDate = this.myConstants.userListFilterRelativeDate;
        if (term.dateOffset && term.dateOffset > 0) {
          constructedDate = constructedDate.concat(term.dateSign || '+').concat(term.dateOffset.toString());
        }
        colTerm.searchValue = colTerm.searchValue.replace(term.originalTermValue, constructedDate);
      });
    }
    let viewModel: IUserListFilterViewModel = { userListFilter: userListFilter, userListFilterColumns: this.cols };

    userListFilter.providerUser_providerUserId = this.userPriviledgesService.currentUserId$.value;
    userListFilter.listComponent_listComponentId = this.listComponentId;

    const emitter: IHomEventEmitter = { requestor: 'dynamic-list-user-filter', event: this.myConstants.emitterEventListReload, action: this.myConstants.emitterEventModalSaveAndClose, data: null };
    const createData = new CreateObjectModel(DynamicListStore.userListFilters, this.listComponentId, this.userListFiltersDefinition.controllerName, 'Create', viewModel, null, emitter);
    this.store.dispatch(new CreateObjectList({ createData }));
  }

  updateRecord() {
    if (!this.isValid()) return;
    let rec: IUserListFilter = this.setCommonProperties(this.form.getRawValue());

    // Now we can start applying the date parameters to the cols (if any)
    if (this.dateTerms.length > 0) {
      let constructedDate: string = '';
      let oldTermName = '';
      this.dateTerms.forEach((term: IUserListFilterDateViewModel) => {
        if (oldTermName !== term.termName) constructedDate = '';
        oldTermName = term.termName;
        let colTerm = this.cols.filter(x => x.term === term.termName)[0];
        constructedDate = constructedDate ? constructedDate.concat(' - ').concat(term.termValue) : term.termValue;
        colTerm.searchValue = constructedDate;
      });
    }

    // apply checkbox value to form field
    rec.isDefault = this.objectData.isDefault;
    let viewModel: IUserListFilterViewModel = { userListFilter: rec, userListFilterColumns: this.cols };

    const keyData: IKey = { storeName: DynamicListStore.userListFilters, parentId: this.listComponentId, key: this.userListFiltersDefinition.rowKeyId, id: this.userListFilterId }
    const emitter: IHomEventEmitter = { requestor: 'dynamic-list-user-filter', event: this.myConstants.emitterEventListReload, action: this.myConstants.emitterEventModalSaveAndClose, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.userListFiltersDefinition.controllerName, 'Update', viewModel, null, emitter);
    this.store.dispatch(new UpdateObjectCustomList({ updateData }));
  }

  setDateFields(): void {
    // Make our custom object based on the date column fields
    this.cols.filter(x => x.fieldType === this.myConstants.dataTypeDate || x.fieldType === this.myConstants.dataTypeDateTime).forEach((term: IUserListFilterColumnViewModel) => {
      const dateParts = this.dynamicFieldService.getDateNormalizedFilterTerm(term.searchValue);
      dateParts.dateParts.forEach((dateTerm: IRelativeDateParameters, index) => {
        this.dateTerms.push({
          termName: term.term,
          formattedTermName: term.formattedTermName,
          searchType: term.searchType,
          termPosition: index,
          originalTermValue: dateTerm.fixedDate,
          termValue: dateParts.isRelativeDateTerm ? dateTerm.relativeDate : dateTerm.fixedDate,
          fieldType: term.fieldType,
          modelName: term.modelName,
          dateType: dateParts.isRelativeDateTerm ? this.userFilterConstants.relativeDateType : this.userFilterConstants.fixedDateType,
          dateSign: dateTerm.dateOffsetSign,
          dateOffset: dateTerm.dateOffset,
          description$: new BehaviorSubject(this.dynamicListService.getFormattedFilterTermInfo(<ISearchTerm>{
          term: term.term,
          value: term.searchValue,
          searchType: term.searchType,
          displayValues: term.displayValue,
          fieldType: term.fieldType,
          columnName: term.modelName
          }))
        });
      })
    });
    // now we build our radio buttons
    this.dateTerms.forEach((term: IUserListFilterDateViewModel) => {
      let dateTypeRow = new Array<IRadioButton>();
      dateTypeRow.push({
        label: term.originalTermValue,
        value: term.originalTermValue,
        id: this.userFilterConstants.fixedDateType.concat('_').concat(term.termName).concat(term.termPosition.toString()),
        checked: term.dateType === this.userFilterConstants.fixedDateType
      }, {
        label: 'Today',
        value: 'current',
        id: this.userFilterConstants.relativeDateType.concat('_').concat(term.termName).concat(term.termPosition.toString()),
        checked: term.dateType === this.userFilterConstants.relativeDateType
        });
      this.dateTypeBtns.push(dateTypeRow);
      let dateSignRow = new Array<IRadioButton>();
      dateSignRow.push({
        label: 'plus',
        value: '+',
        id: 'plus_'.concat(term.termName).concat(term.termPosition.toString()),
        checked: term.dateSign === '+'
      }, {
        label: 'minus',
        value: '-',
        id: 'minus_'.concat(term.termName).concat(term.termPosition.toString()),
        checked: term.dateSign === '-'});
      this.dateSignBtns.push(dateSignRow);
    });
  }

  getUserListFilterColumns(): IUserListFilterColumnViewModel[] {
    let cols: IUserListFilterColumnViewModel[] = [];
    if (this.searchTerms.length > 0)
      this.searchTerms.forEach((term: ISearchTerm) => {
          cols.push({
            term: term.term,
            formattedTermName: this.dynamicListService.getFormattedFieldName(term),
            searchType: term.searchType,
            searchValue: isArray(term.value) ? toString(term.value) : term.value,
            displayValue: isArray(term.displayValues) ? toString(term.displayValues) : term.displayValues,
            fieldType: term.fieldType,
            modelName: term.columnName
          });
      });
    else if (this.objectData.filterColumns.length > 0)
      this.objectData.filterColumns.forEach((term: IUserListFilterColumn) => {
        const fieldType = this.parentFieldDefinitions.find(x => x.key === term.term);
        cols.push({
          term: term.term,
          formattedTermName: this.dynamicListService.getFormattedFieldName(<ISearchTerm>{ term: term.term, value: term.searchValue, searchType: term.searchType, columnName: term.modelName, fieldType: fieldType ? fieldType.fieldType : '', displayValues: term.displayValue }),
          searchType: term.searchType,
          searchValue: isArray(term.searchValue) ? toString(term.searchValue) : term.searchValue,
          displayValue: isArray(term.displayValue) ? toString(term.displayValue) : term.displayValue,
          fieldType: fieldType ? fieldType.fieldType : '',
          modelName: term.modelName
        });
      });
    return cols;
  }

  setCommonProperties(formData: IUserListFilter): IUserListFilter {

    let rec = cloneDeep(this.objectData);
    rec.filterName = formData.filterName;
    rec.isDefault = formData.isDefault;
    rec['description'] = this.filterDescription$.value.replace(/ \(.*?\)/g, '');
    return rec;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
