export interface IOrderTerm {
    term: string;
    orderAsc: boolean;
    sortOrder: number;
}

export class OrderTerm implements IOrderTerm {
  term: string;
  orderAsc: boolean;
  sortOrder: number;

  constructor(term = '', orderAsc = true, sortOrder = 1) {
    this.term = term;
    this.orderAsc = orderAsc;
    this.sortOrder = sortOrder;
  }

}
