<div [ngClass]="{ 'loading-mask':  (loading$ | async) }">
  <ng-container *ngIf="operation === myConstants.operationTypeEdit">
    <po-import-header *ngIf="(poImportHeader$ | async) && (branchPrograms$ | async)"
                      [poImportHeader]="poImportHeader$ | async"
                      [branchPrograms]="branchPrograms$ | async"
                      [storeName]="storeName"
                      [operation]="operation"
                      [detailConfig]="detailConfig$ | async"
                      (customEvent)="onCustom($event)">
    </po-import-header>
  </ng-container>
  <ng-container *ngIf="operation === myConstants.operationTypeDetails">
    <fw-dynamic-detail-container [detailConfig]="detailConfig$ | async">
      <div *ngIf="(listDefinition$ | async)">
        <div class="po-import-headers__list">
          <div class="list-wrapper">
            <fw-dynamic-list [vmListDefinition]="listDefinition$ | async" (customEvent)="onCustom($event)"></fw-dynamic-list>
          </div>
        </div>
      </div>
    </fw-dynamic-detail-container>
  </ng-container>
</div>
