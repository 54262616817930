<div [ngClass]="{ 'loading-mask': !form }">
  <fw-button-title-bar [title]="'Warranty Base Work Order'"
                       [canIEdit]="canIEdit"
                       [showEdit]="true"
                       [showDelete]="false"
                       [showRefresh]="true"
                       [operation]="operation"
                       [disabled]="!form.dirty || !form.valid"
                       [customButtons]="customButtons$ | async"
                       (customEvent)="onCustom($event)">
  </fw-button-title-bar>
  <form *ngIf="form" [formGroup]="form" class="form-wrapper form-wrapper--below-title-bar" novalidate
        [ngClass]="{'form-wrapper--edit': operation !== myConstants.operationTypeDetails }">
    <fw-dynamic-field [field]="mds.getFieldDefinition('isUrgent')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>

    <fw-dynamic-field [field]="mds.getFieldDefinition('warrantyWorkOrder')"
                      [form]="form"
                      [operation]="!baseWorkOrders || baseWorkOrders.length === 0 ? myConstants.operationTypeDetails : operation"
                      [selectItems]="baseWorkOrders">
    </fw-dynamic-field>

    <fw-dynamic-field [field]="mds.getFieldDefinition('assignedUser')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="assignedUsers">
    </fw-dynamic-field>

    <fw-dynamic-field [field]="mds.getFieldDefinition('warrantyType')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="warrantyTypes">
    </fw-dynamic-field>

    <fw-dynamic-field [field]="mds.getFieldDefinition('warrantySubType')"
                      [form]="form"
                      [selectItems]="warrantySubTypes"
                      [operation]="!(warrantySubTypes) || !(warrantySubTypes).length ? myConstants.operationTypeDetails : operation">
    </fw-dynamic-field>
  </form>

  <fw-component-footer [lastUpdate]="warranty && warranty.hasOwnProperty('updateDate') ? warranty.updateDate : '' "></fw-component-footer>
</div>
