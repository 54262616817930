import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { UtilitiesService } from '../../services/utilities.service';
import { IGeneralContractor } from '../../../view-models/index';

import * as fromRoot from '../../../../store/reducers/index';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';

@Component({
  selector: 'po-imports',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './po-imports.component.html'
})
export class PoImportsComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public generalContractors: IGeneralContractor[];
  subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
   public utilitiesService: UtilitiesService,
    public router: Router) { }

  public changeGC(generalContractorId: number) {
    this.router.navigate([{ outlets: { importDetail: ['po-imports-all',generalContractorId] } }], {
      relativeTo: this.activeRoute
    });
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
      this.changeGC(1);
    });
  }

  newRequest(): void {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('generalContractor')))
      .subscribe((data) => {
        this.generalContractors = data;
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
