<admin-title-bar
      [title]="title"
      [info]="title"
      [disabled]="isWorking || !haveAllGcs"
      [currentAccess]="userGcs"
      [specifierType]="specifierType"
   (btnEvent)="onEvent($event)">
</admin-title-bar>

<div [ngClass]="{ 'loading-mask': isWorking }">
  <div class="user-access-items">
    <ng-container *ngFor="let gc of userGcs">
      <user-access-item customClass="user-access-item--{{gc.name.replace(' ', '-').replace(',', '').replace('.', '').toLowerCase()}}"
                        [item]="gc"
                        [name]="gc.name"
                       (deleteEvent)="onEvent($event)">
      </user-access-item>
    </ng-container>
  </div>
</div>
