
export enum DynamicListEvent {
  createRow = 'createRow',
  editRow = 'editRow',
  showDetail = 'showDetail',
  requestDelete = 'requestDelete',
  deleteRow = 'deleteRow',
  cancelDelete = 'cancelDelete',
  checkedRow = 'checkedRow',
  selectedRow = 'selectedRow', //mutually exclusive option
  sortList = 'sortList',
  filterList = 'filterList',
  showFilter = 'showFilter',
  selectAll = 'selectAll',
  runFilter = 'runFilter',
  clearAllFilters = 'clearAllFilters',
  createSavedFilter = 'createSavedFilter',
  manageSavedFilters = 'manageSavedFilters',
  selectUserFilter = 'selectUserFilter',
  applyFilter = 'applyFilter',
  inMemorySort = 'inMemorySort',
  showInfo = 'showInfo',
  printRow = 'printRow',
  reloadList = 'reloadList'
}

export enum DynamicListStore {
  userListFilters = 'userListFilters'
}


export enum DynamicListMethod {
  alwaysEnabled = 'alwaysEnabled'
}

export enum DynamicListAction {
  removeItem = 'removeItem',
  addItem = 'addItem',
  changeItem = 'changeItem',
  overrideAll = 'overrideAll'
}

