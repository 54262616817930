export interface IContactCommunicationPreference {
  contactCommunicationPreferenceId: number;
  communicationPreference_communicationPreferenceId: number;
  contactMechanism_contactMechanismId: number;

  createDate: string;
  updateDate: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
}

export class ContactCommunicationPreference implements IContactCommunicationPreference {
  contactCommunicationPreferenceId: number;
  communicationPreference_communicationPreferenceId: number;
  contactMechanism_contactMechanismId: number;

  createDate: string;
  updateDate: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;


  constructor() {
    this.contactCommunicationPreferenceId = 0;
    this.communicationPreference_communicationPreferenceId = 0;
    this.contactMechanism_contactMechanismId = 0;
    this.createDate = '';
    this.updateDate = '';
    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';
  }

}
