/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import {  map } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import {  IPurchaseOrder, IPurchaseOrderItem, IWorkOrder, IWorkOrderItem, INote, IChargeBack, IChargeBackItem } from '../../../view-models/index';
import { ITabRequest } from '../../../../../fw/fw-shared/components/fw-app-tabs/interfaces/index';
import { IMultiTierOuput } from '../../../../../fw/fw-menus/interfaces/i-multi-tier-output';
import { ProjectService } from '../../services/project.service';
import { TabsService } from '../../../../../fw/fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { IApprovalQueueTransaction } from '../../../../../shared/interfaces';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IDynamicListState } from '../../../../../fw/dynamic-list/store';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { ProjectListStore } from '../../enums/project.enums';

@Component({
  selector: 'project-counts-container',
  templateUrl: './project-counts-container.component.html',
})
export class ProjectCountsContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() projectId: number;
  @Input() isWarranty: boolean;
  @Input() canIEdit: boolean;
  @Input() canIEditCbs: boolean;

  //not sure what this should look like, maybe new interface specific to count, or just entity interfaces[]
  public pos$: Observable<IPurchaseOrder[]>;
  public wos$: Observable<IWorkOrder[]>;
  public chargeBacks$: Observable<IChargeBack[]>;
  public poItems$: Observable<IPurchaseOrderItem[]>;
  public woItems$: Observable<IWorkOrderItem[]>;
  public chargeBackItems$: Observable<IChargeBackItem[]>;
  public notes$: Observable<number>;
  public aqtChanges$: Observable<IApprovalQueueTransaction[]>;
  subscription: Subscription = new Subscription();

  constructor(
    public store: Store<fromFeature.IAllDynamicData>,
    public projectService: ProjectService,
    public tabsService: TabsService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onAdd(event: IHomEventEmitter) {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: '',
      key: 'project_projectId',
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.projectId
    }

    switch (event.data) {
      case this.myConstants.entityTypePurchaseOrder:
        params.storeName = ProjectListStore.projectPurchaseOrders;
        this.modalService.open({
          title: 'Add Purchase Order', path: 'project-purchase-order-create', id: 0, onExit: null, castExit: false, sizeType: ModalSizeType.small, optionalParams: params, hasTabs: false
        });
        break;
      case this.myConstants.entityTypeWorkOrder:
        params.storeName = ProjectListStore.projectWorkOrders;
        this.modalService.open({ title: 'Add Work Order', path: 'project-work-order-create', id: 0, onExit: null, castExit: false, sizeType: ModalSizeType.small, optionalParams: params, hasTabs: false });
        break;
      case this.myConstants.entityTypeChargeBack:
        params.storeName = ProjectListStore.projectChargeBacks;
        this.modalService.open({ title: 'Add Charge Back', path: 'project-charge-back-create', id: 0, onExit: null, castExit: false, sizeType: ModalSizeType.small, optionalParams: params, hasTabs: false });
        break;
      case this.myConstants.entityTypeNote:
        //this.openNoteInAdd();  //this approach requires what mods to tab to handle additional route param?
        this.modalService.open({ title: 'Project Notes', path: 'project-notes/' + this.projectId.toString() + '/' + this.myConstants.routeActionAdd, optionalParams: null, onExit: null, castExit: false, hasTabs: false });
        break;
      default:
        break;
    }
  }

  public onGoTo(output: IMultiTierOuput): void {
    //open selected item via tab request
    const tabRequest: ITabRequest = output.item;
    const event: IHomEventEmitter = {
      requestor: 'project-counts',
      event: this.myConstants.emitterEventTabLevel1Open,
      action: this.myConstants.routeActionAdd,
      data: tabRequest
    };
    this.tabsService.emitTabEvent(event);
  }

  ngOnInit() {
    this.newRequest();
  }

  newRequest() {
    //listening for purchase order item  store
    this.poItems$ = this.store.pipe(select(getListByType(ProjectListStore.projectPurchaseOrderItems)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map(objData => objData && objData.data ? objData.data : null)
      );

    //listening for work order item store
    this.woItems$ = this.store.pipe(select(getListByType(ProjectListStore.projectWorkOrderItems)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map(objData => objData && objData.data ? objData.data : null)
      );

    //listening for charge back item  store
    this.chargeBackItems$ = this.store.pipe(select(getListByType(ProjectListStore.projectChargeBackItems)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map(objData => objData && objData.data ? objData.data : null)
      );

    //listening for purchase order item  store
    this.pos$ = this.store.pipe(select(getListByType(ProjectListStore.projectPurchaseOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map(objData => objData && objData.data ? objData.data : null)
      );

    //listening for work order item store
    this.wos$ = this.store.pipe(select(getListByType(ProjectListStore.projectWorkOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map(objData => objData && objData.data ? objData.data : null)
      );

    //listening for charge back item  store
    this.chargeBacks$ = this.store.pipe(select(getListByType(ProjectListStore.projectChargeBacks)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map(objData => objData && objData.data ? objData.data : null)
      );

    //listening for notes store 
    this.notes$ = this.store.pipe(select(getListByType(ProjectListStore.projectNotes)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map(objData => objData && objData.data ? objData.listMetaData.totalItems : null)
      );

    //listening for project approval queue transaction store 
    this.aqtChanges$ = this.store.pipe(select(getListByType(ProjectListStore.projectAQTChanges)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map(objData => objData && objData.data ? objData.data : null)
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] && !(changes['projectId'].firstChange)) {
      this.newRequest();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
