import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminEvent } from '../../enums/admin.enums';
import { IChargeBackSku } from '../../../view-models/index_two';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { UserPriviledgesService } from '../../../../../auth/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { AdminPricingService } from '../../services/admin-pricing.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './charge-back-skus.component.html'
})
export class ChargeBackSkusComponent implements OnInit {

  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public userPriviledgesService: UserPriviledgesService,
    public adminService: AdminPricingService,
    public modalService: ModalService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadChargeBackSkusListDefinition();
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.manageChargeBackPrices:
        this.openChargeBackPrices(event.data);
        break;
      default:
        break;
    }
  }

  openChargeBackPrices(cbs: IChargeBackSku): void {
    this.modalService.open({
      title: 'Prices for Charge Back Sku'.concat(' ', cbs.chargeBackSkuName),
      path: 'cb-sku-prices/'.concat(cbs.chargeBackSkuId.toString()),
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      hasTabs: false
    });
  }


}
