import { Component, Input, Inject, Output, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import {  BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

//interfaces
import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IListDefinition,  IDynamicListRow, IDynamicListRowBtn, IListShowDetail, IListCustomSelectEvent, IListScreenSize } from '../../interfaces/index';
import { IFieldDefinition } from '../../../dynamic-forms/index';
import { IInputButton } from '../../../fw-shared/interfaces/i-input-button';
import { IDynamicListDataCol } from '../../interfaces/i-dynamic-list-data-col';
import { DynamicListEvent } from '../../enums/dynamic-list.enum';
import { IOperationTerm } from '../../../dynamic-forms/interfaces';
import { IRadioButton } from '../../../fw-shared/components/fw-radio-button/interfaces/i-radio-button';
import { ICustomButton } from '../../../fw-shared/interfaces/i-custom-button';

// services
import { DynamicListService } from '../../services/dynamic-list.service'; 
import { HomDataUtility } from '../../../../shared/services';

//components
import { CheckBoxComponent, RadioButtonComponent } from '../../../fw-shared/components';

@Component({
  selector: 'fw-dynamic-list-data-row',
  templateUrl: './dynamic-list-data-row.component.html',
})
export class DynamicListDataRowComponent implements OnInit, OnChanges {
  @Input() index: number;
  @Input() row: any;
  @Input() colCount: number;
  @Input() listDefinition: IListDefinition;
  @Input() screenSize: IListScreenSize;
  @Input() fieldDefinitions: IFieldDefinition[];
  @Input() portalEntityId: number;
  @Input() detailError: boolean;
  @Input() activeIndex: number;
  @Input() activeOptionIndex: number;
  @Input() listDisabled: boolean;
  @Input() allSelected: boolean;
  @Input() isDeleting: boolean;
  @Input() activeDeleteId: number;
  @Input() navClosed: boolean;
  @Input() lastRow: boolean;

  @ViewChild('groupSelect', { read: CheckBoxComponent }) public groupSelect: CheckBoxComponent;
  @ViewChild('singleSelect', { read: RadioButtonComponent }) public singleSelect: RadioButtonComponent;

  public cbConfig: IInputButton;
  public rowDef: IDynamicListRow;
  public colData$: BehaviorSubject<IDynamicListDataCol[]> = new BehaviorSubject(null);
  public rowBtns$: BehaviorSubject<IDynamicListRowBtn[]> = new BehaviorSubject(null);
  public isSelected: boolean = false;
  public optBtn: IRadioButton[] = [];
  public showRowTools: boolean = true;
  public overflowBtns: IDynamicListRowBtn[] = []
  public showInfo: boolean = false;

  constructor(
    public  dynamicListService: DynamicListService, //do not change this name
    public emitterService: HomEventEmitterService,
    public dataUtils: HomDataUtility,
    @Inject(appConstants) public  myConstants: IAppConstants) {}

  ngOnInit() {
    this.showRowTools = this.dynamicListService.showRowTools(this.listDefinition);

    this.cbConfig = {
      label: { label: '', alignRight: true, title: 'Select Row' }
    };

    this.setUIData();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if ((changes['listDefinition'] && !changes['listDefinition'].firstChange) ||
      (changes['fieldDefinitions'] && !changes['fieldDefinitions'].firstChange) ||
      (changes['row'] && !changes['row'].firstChange) ||
      (changes['screenSize'] && !changes['screenSize'].firstChange) ||
      (changes['activeOptionIndex'] && !changes['activeOptionIndex'].firstChange) ||
      (changes['listDisabled'] && !changes['listDisabled'].firstChange) ) {
      this.setUIData();
    }

    if ((changes['allSelected'] && !changes['allSelected'].firstChange)) {
      this.isSelected = this.allSelected;
      this.setUIData();
    }
  }

  public onRowBtnClick(event: ICustomButton) {
    switch (event.eventName) {
      case DynamicListEvent.printRow:
        //not yet implemented
        break;
      case DynamicListEvent.showDetail:
        this.showDetail(this.myConstants.operationTypeDetails);
        break;
      case DynamicListEvent.editRow:
        this.showDetail(this.myConstants.operationTypeEdit);
       break;
      case DynamicListEvent.requestDelete:
        let delEvent: IHomEventEmitter = { requestor: 'dynamic-list-row', event: DynamicListEvent.requestDelete, action: '', data: this.row[this.listDefinition.rowKeyId] };
        this.emitterService.emitListEvent(delEvent);
       break;
      default:
        let defaultEvent: IHomEventEmitter = { requestor: 'dynamic-list-row', event: event.eventName, action: '', data: this.row };
        this.emitterService.emitListCustomEvent(defaultEvent);
        break;
    }
  }

  public selectClick(event: any) {
    if (!this.listDefinition.groupSelect) {
      return;
    }
    let chkBox: CheckBoxComponent = this.groupSelect;
    this.isSelected = chkBox.value;
    this.setRowData();

    let data: IListCustomSelectEvent = { definition: this.listDefinition.groupSelect, row: this.row, selected: chkBox.value, index: this.index };
    let selectEvent: IHomEventEmitter = { requestor: 'dynamic-list-row', event: DynamicListEvent.checkedRow, action: '', data: data };
    this.emitterService.emitListEvent(selectEvent);
  }

  //Instance is of a row, so this.index will be the value.
  public onOptionChange(value: string) {
    if (!this.listDefinition.singleSelect) {
      return;
    }
    //mark this row selected and unselect all others
    let data: IListCustomSelectEvent = { definition: this.listDefinition.singleSelect, row: this.row, selected: true, index: this.index };
    let event: IHomEventEmitter = { requestor: 'dynamic-list-row', event: DynamicListEvent.selectedRow, action: '', data: data };
    this.emitterService.emitListEvent(event);
  }

  public cancelDelete(): void {
    let event: IHomEventEmitter = { requestor: 'dynamic-list-row', event: DynamicListEvent.cancelDelete, action: '', data: null };
    this.emitterService.emitListEvent(event);
  }

  public deleteRecord(): void {
    let event: IHomEventEmitter = { requestor: 'dynamic-list-row', event: DynamicListEvent.deleteRow, action: '', data: this.row };
    this.emitterService.emitListEvent(event);
  }

  setUIData(): void {
    this.optBtn = [
      {
        label: '',
        value: this.index.toString(),
        id: 'list'.concat(this.index.toString(), '_', this.row[this.listDefinition.rowKeyId]),
        checked: this.index === this.activeOptionIndex
      }
    ];
    this.setColData();
    this.setRowData();
    this.setRowBtns();
  }

  setRowData(): void {
    let rowDef: IDynamicListRow = {
      index: this.index,
      rowClass: this.dynamicListService.rowClass(this.listDefinition, this.row, this.activeDeleteId, this.index, this.portalEntityId, this.allSelected || this.isSelected, this.activeOptionIndex, this.navClosed, -1),
      entityStatus: this.dynamicListService.getEntityStatus(this.row, 'Column'),
      hasPending: this.dynamicListService.hasPending(this.row),
      selectDisabled: this.dynamicListService.selectDisabled(this.listDefinition, this.row, this.portalEntityId),
      optionDisabled: this.dynamicListService.optionDisabled(this.listDefinition, this.row, this.portalEntityId)
    };
    
    this.rowDef = rowDef;
  }

  setColData(): void {
    let colData: IDynamicListDataCol[] = [];

    this.listDefinition.listColumns.forEach((col) => {
      const fieldDefinition: IFieldDefinition = this.dynamicListService.getFieldDefinition(this.fieldDefinitions, col.key);
      colData.push({
        key: col.key,
        fieldDefinition: fieldDefinition,
        gridCol: this.dynamicListService.getGridCol(this.listDefinition, this.screenSize, col),
        visible: this.dynamicListService.isVisible(this.screenSize, col),
        colClass: this.getColClass(fieldDefinition, col.key),
        isLink: this.isLink(fieldDefinition, col.key),
        title: this.getColTitle(fieldDefinition, col.key),
        value: this.getColVal(fieldDefinition, col.key)
      });
    });

    this.colData$.next(colData);
  }

  setRowBtns(): void {
    let btns: IDynamicListRowBtn[] = [];

    this.listDefinition.rowButtons.forEach((btn) => {
      let rowBtn = cloneDeep(btn);
      rowBtn.icon = this.getBtnIcon(rowBtn);
      rowBtn.title = this.getBtnTitle(rowBtn);
      rowBtn.isDownload = rowBtn.isDownload ? rowBtn.isDownload : false;
      rowBtn.inOverflow = rowBtn.inOverflow ? true : false; //may not be set
      rowBtn.inOverflow = this.screenSize.screenIsSmall ? true : rowBtn.inOverflow;
      btns.push({
        definition: rowBtn,
        disabled: this.isBtnDisabled(rowBtn),
        css: this.getBtnCss(rowBtn)
      });
    });

    let overflowItems: IDynamicListRowBtn[] = btns.filter(x => x.definition.inOverflow === true);
    const cnt: number = overflowItems ? overflowItems.length : 0;
    this.showInfo = !this.row.createdByName || !this.row.updatedByName
      ? false
      : this.row.createdByName.length && this.row.updatedByName.length ? true : false;

    if (!this.screenSize.screenIsSmall) {
      //do not engage overflow if only have one item in it
      if ((this.showInfo && cnt === 0) || (!this.showInfo && cnt === 1)) {
        btns.forEach(x => x.definition.inOverflow = false);
        overflowItems = [];
      }
    }

    this.overflowBtns = overflowItems;
    this.rowBtns$.next(btns.filter(x => x.definition.inOverflow === false));
  }

  getBtnIcon(def: ICustomButton): string {
    let icon: string = def.icon;

    switch (def.eventName) {
      case DynamicListEvent.editRow:
        if (this.dynamicListService.hasPending(this.row)) {
          icon = 'fas fa-pause';
        }
        break;
      default:
        break;
    }

    return icon;
  }

  getBtnTitle(def: ICustomButton): string {
    let title: string = def.title;

    switch (def.eventName) {
      case DynamicListEvent.editRow:
        if (this.dynamicListService.hasPending(this.row)) {
          title = 'Pending Approval';
        }
        break;
      default:
        break;
    }

    return title;
  }

  showDetail(operation: string) {
    let data: IListShowDetail = { id: +this.row[this.listDefinition.rowKeyId], rowIndex: this.index, operation: operation }
    let btnEvent: IHomEventEmitter = { requestor: 'dynamic-list-row', event: DynamicListEvent.showDetail, action: '', data: data };
    this.emitterService.emitListEvent(btnEvent);
  }

  getBtnCss( def: ICustomButton): string {
    let css = 'app-btn-icon ';
    if (this.isBtnDisabled(def)) {
      css.concat(' ', 'link-disabled');
    }
    let methodCss = '';

    if (def.cssMethod && def.methodService) {
      let result = this.dynamicListService.handleCustomMethod(def.methodService, def.cssMethod, this.row, this.portalEntityId);
      methodCss = result ? result : '';
    }
    return css.concat(' ', methodCss ? methodCss : def.cssName, ' dynamic-list__item__actions--icon');
  }

  isBtnDisabled(def: ICustomButton): boolean {
    let disabled = false;

    if (!def.enabled || this.listDisabled) {
      return true;
    }

    //Disable edit if have pending.  Leave delete and detail to enable based on existance of url value.
    if (def.eventName === DynamicListEvent.editRow && this.dynamicListService.hasPending(this.row)) {
      return true;
    }

    if (def.enabledMethod && def.methodService) {
      let result = this.dynamicListService.handleCustomMethod(def.methodService, def.enabledMethod, this.row, this.portalEntityId);
      disabled = !result ? true : false;
    }
    return disabled;
  }

  /*
      Returns the css class to use for the column
  */
  getColClass(fieldDefinition: IFieldDefinition, key: string) {
    if (!fieldDefinition) return '';
    let cssName = '';
   if (this.isLink(fieldDefinition, key)) {
      cssName = 'dynamic-list__item--link ';
    }

    if (this.isNumberField(fieldDefinition.fieldType) && !this.isLink(fieldDefinition, key)) {
      cssName += ' dynamic-list__item--nbr';
    }
    return cssName;
  }

  isNumberField(fieldType: string): boolean {
   switch (fieldType) {
      case this.myConstants.fieldTypeCurrency:
      case this.myConstants.fieldTypeNumber:
      case this.myConstants.fieldTypePercentage:
      case this.myConstants.fieldTypeDouble:
      case this.myConstants.fieldTypeDoubleDecimal:
        return true;
      default:
        return false;
    }
  }

  getColTitle(fieldDefinition: IFieldDefinition, key: string) {
    if (!fieldDefinition) return '';
    if (this.isLink(fieldDefinition, key)) {
      return 'Go to ' + this.getColVal(fieldDefinition, key);
    } else if (fieldDefinition.fieldType === this.myConstants.fieldTypeImage) {
      return this.row.hasOwnProperty('fileName') ? this.row['fileName'] : this.row.entityLabel;
    } else
      return this.getColVal(fieldDefinition, key);
  }


  isLink(fieldDefinition: IFieldDefinition, key: string): boolean {
    if (!fieldDefinition) return false;
    if (!this.getColVal(fieldDefinition, key)) return false;
    const level2EntityName = fieldDefinition.linkSettings.portalLevel2EntityName;
    if (!fieldDefinition.linkSettings.flag) return false;
    if (!level2EntityName) return fieldDefinition.linkSettings.flag;

    //if this field is a link, verify the user has access to that componentcolClass
    //if they do not, do not render as a link
    return true;
  }

  /*
      Returns the value for this column - useful for fkeys
  */
  getColVal(fieldDefinition: IFieldDefinition, key: string): string {
    if (!fieldDefinition) return '';
    if (fieldDefinition.calcSettings && fieldDefinition.calcSettings.flag) {
      return (this.getCalcValue(fieldDefinition, key));
    } else {
      return this.getStringValue(fieldDefinition, key);
    }
  }

  getCalcValue(fieldDefinition: IFieldDefinition, key: string): string {
    if (!fieldDefinition) return '';
    let col1 = this.getStringValue(fieldDefinition, fieldDefinition.calcSettings.column1);
    let col2 = this.getStringValue(fieldDefinition, fieldDefinition.calcSettings.column2);
    if (isNaN(+col1)) {
      col1 = '0';
    }
    if (isNaN(+col2)) {
      col2 = '0';
    }
    switch (fieldDefinition.calcSettings.operationTerm) {
      case IOperationTerm.multiply:
        return (+col1 * +col2).toString();
      case IOperationTerm.add:
        return (+col1 + +col2).toString();
      case IOperationTerm.subtract:
        return (+col1 - +col2).toString();
      default:
        return '0';
    }
  }

  getStringValue(fieldDefinition: IFieldDefinition, key: string): string {
    if (!fieldDefinition) return '';
    let colName = key.split('@');
    let value = this.row[colName[0]];
    if (colName.length > 1) {
      //see if working with an object
      if (this.row[colName[0]].hasOwnProperty(colName[1])) {
        value = this.row[colName[0]][colName[1]];
      }

    } else {
      //working with a fkey?
      if (this.row.fks.hasOwnProperty(colName[0])) {
        value = this.row.fks[colName[0]]['entityLabel'];
      }
    }

    return !value ? this.getDefaultValue(fieldDefinition, value) : value.toString().trim();
  }

  getDefaultValue(fieldDefinition: IFieldDefinition, value: any): string {
    if (!fieldDefinition) return '';
    if (this.isNumberField(fieldDefinition.fieldType)
      && !fieldDefinition.linkSettings.flag) {
      return value === null || value == undefined ? this.myConstants.dash : '0';
    }
    else {
      return '';
    }
  }
}
