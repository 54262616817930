import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth/services';

import { ContactCommunicationPreferenceComponent } from './components';
import { LoadingPageComponent } from '../../shared/components';

import { MetaDataResolver } from '../portals/resolvers';


const contactManagerRoutes: Routes = [
  //BEGIN MODAL route tos
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'communication-preference/:id/:cpId',
    outlet: 'modal',
    component: ContactCommunicationPreferenceComponent,
    data: {
      storeName: 'contactCommunicationPreference',
      controller: 'ContactCommunicationPreference'
    },
    resolve: {
      hasMetaData: MetaDataResolver
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'loading2/:container',
    outlet: 'modal2',
    component: LoadingPageComponent,
    title: 'loading2'
  },
  { //SUB MODAL ROUTE
    path: 'communication-preference/:id/:cpId',
    outlet: 'modal2',
    component: ContactCommunicationPreferenceComponent,
    data: {
      storeName: 'contactCommunicationPreference',
      controller: 'ContactCommunicationPreference'
    },
    resolve: {
      hasMetaData: MetaDataResolver 
    },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(contactManagerRoutes)
  ],
  exports: [RouterModule]
})
export class ContactRoutingModule { }
