import { Component, OnInit,  Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { ScheduleStore } from '../../enums/schedule.enums';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { ILegend } from '../../../../../shared/components/fw-legend/interfaces/i-legend';
import { IAllDynamicData, getMetaDataByType, SetListDefinition, IMetaDataState } from '../../../../../fw/dynamic-list/store';
import * as MetaDataActions from '../../../../../fw/dynamic-list/store/actions/meta-data.actions';

import { SchedulerService } from '../../services/scheduler.service';

@Component({
  selector: 'unavailable-installers',
  templateUrl: './unavailable-installers.component.html'
})
export class UnavailableInstallersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() workOrderId: number;
  @Input() startDate: string;
  @Input() duration: number;
  @Input() durationType: string;
  @Input() scheduleId: number;

  public legendItems: ILegend[] = [];
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  listSub: Subscription;

  constructor(
    public schedulerService: SchedulerService,
    public store: Store<IAllDynamicData>) { }

  ngOnInit() {
    this.legendItems.push({ icon: 'fas fa-circle', iconCss: 'unavailable-installers-legend--compliance', text: 'Compliance', textCss: 'unavailable-installers-legend--compliance' });
    this.legendItems.push({ icon: 'fas fa-circle', iconCss: 'unavailable-installers-legend--vacation', text: 'Vacation', textCss: 'unavailable-installers-legend--vacation' });
    this.legendItems.push({ icon: 'fas fa-circle', iconCss: 'unavailable-installers-legend--scheduled', text: 'Scheduled Elsewhere', textCss: 'unavailable-installers-legend--scheduled' });
    this.legendItems.push({ icon: 'fas fa-circle', iconCss: 'unavailable-installers-legend--score', text: 'Score', textCss: 'unavailable-installers-legend--score' });
    this.legendItems.push({ icon: 'fas fa-circle', iconCss: 'unavailable-installers-legend--sss', text: 'SSS', textCss: 'unavailable-installers-legend--sss' });

    this.newRequest();
  }

  newRequest(): void {
    let listDefinition: IListDefinition = this.schedulerService.loadUnavailableInstallersListDefinition(this.workOrderId, this.startDate, this.duration, this.durationType, this.scheduleId);
    this.store.dispatch(new SetListDefinition({ storeName: ScheduleStore.unavailableWorkCrews, parentId: -1, listDefinition: listDefinition }));
    this.store.dispatch(new MetaDataActions.GetMetaData({
      storeName: ScheduleStore.unavailableWorkCrews,
      url: 'ScheduleInstallerWorkCrewUnavailabilityViewModel/GetMetaData',
      setListMetaData: true
    }));

    if (this.listSub) {
      this.listSub.unsubscribe();
    }
    this.listSub =this.store.pipe(select(getMetaDataByType(ScheduleStore.unavailableWorkCrews)))
      .subscribe((state: IMetaDataState) => {
        if (state.fieldDefinitions.length) {
          this.listDefinition$.next(listDefinition);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workOrderId'] && !(changes['workOrderId'].firstChange) ||
      changes['startDate'] && !(changes['startDate'].firstChange) ||
      changes['duration'] && !(changes['duration'].firstChange) ||
      changes['durationType'] && !(changes['durationType'].firstChange) ||
      changes['scheduleId'] && !(changes['scheduleId'].firstChange)) {
      this.newRequest();
    }
  }

  ngOnDestroy() {
    this.listDefinition$.next(null);
    if (this.listSub) {
      this.listSub.unsubscribe();
    }
  }
}
