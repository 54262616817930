<fw-dynamic-detail-container *ngIf="detailConfig" [detailConfig]="detailConfig">
  <form *ngIf="form" [formGroup]="form" novalidate class="form">
    <fw-dynamic-field [field]="mds.getFieldDefinition('itemName')"
                      [form]="form"
                      [operation]="operation"
                      [useContains]="true"
                      [selectItems]="skuPrices">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('quantity')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('unitPrice')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('unitOfMeasure')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('unitMeasure')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="uoms">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('isCustom')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('overrideSystemPrice')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('poImportStatus')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="statuses">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('poImportStatusText')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
  </form>
  <fw-component-footer [lastUpdate]="poImportLine && poImportLine.hasOwnProperty('updateDate')
                       ? poImportLine.updateDate
                       : '' ">
  </fw-component-footer>
  <fw-crud-buttons *ngIf="form"
                   [operation]="operation"
                   [disabled]="!form.dirty || !form.valid"
                   [showNext]="operation !== myConstants.operationTypeCreate"
                   (cancelEvent)="onCancel($event)"
                   (saveEvent)="onSave($event)">
  </fw-crud-buttons>
</fw-dynamic-detail-container>
