export interface IWorkCategoryPayout {
  workCategoryPayoutId: number;
  workCategory_workCategoryId: number;
  payout_payoutId: number;
  payoutName: string;
}

export class WorkCategoryPayout implements IWorkCategoryPayout {
  workCategoryPayoutId: number;
  workCategory_workCategoryId: number;
  payout_payoutId: number;
  payoutName: string;

  constructor() {
    this.workCategoryPayoutId = 0;
    this.workCategory_workCategoryId = 0;
    this.payout_payoutId = 0;
    this.payoutName = '';
  }

}
