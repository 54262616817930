<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <fw-dynamic-detail *ngIf="objectData"
                     [displayFields]="displayFields"
                     [detailConfig]="detailConfig"
                     [forceDirty]="forceDirty$ | async"
                     [forceInvalid]="forceInvalid$ | async">
    <div dynamic-detail-drop>
      <delivery-schedule [model]="objectData"
                         [operation]="operation"
                         [storeName]="storeName"
                         [parentId]="providerLocationShipperInventorySetupId"
                         (modelChange)="onScheduleChange($event)">
      </delivery-schedule>

    </div>
  </fw-dynamic-detail>
</fw-dynamic-detail-container>
