
export interface IRegion {
  regionId: number;
  regionType_lookupValueId: number;
  regionTypeName: string;
  generalContractor_generalContractorId: number;
  regionName: string;
  regionDescription: string;
}

export class Region implements IRegion {
  regionId: number;
  regionType_lookupValueId: number;
  regionTypeName: string;
  generalContractor_generalContractorId: number;
  regionName: string;
  regionDescription: string;

  constructor() {
    this.regionId = 0;
    this.regionType_lookupValueId = null;
    this.regionTypeName = '';
    this.generalContractor_generalContractorId = null
    this.regionName = '';
    this.regionDescription = '';
  }
}
