import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FwModule } from '../../../fw/fw.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { InstallerRoutingModule } from './installer-routing.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { ContactModule } from '../../contact/contact.module';
import { SharedModule } from '../../../shared/shared.module';
import { SmsModule } from "../../sms/sms.module";

//resolvers
import {
  ContactTypeSpecificationResolver,
  ContactEmailMetaDataResolver,
  ContactPhoneMetaDataResolver,
  ContactAddressMetaDataResolver,
  ContactMetaDataResolver
} from '../../contact/resolvers';

import {
  SelectionListResolver,
  AllMyProviderLocationsResolver,
  ProviderLocationServiceListResolver,  CommunicationShortcutResolver
} from '../resolvers/index';

import {
  InstallerInformationResolver,
  InstallerWorkCrewTechnicianResolver,
  InstallerWorkCrewResolver,
  InstallerTechnicianResolver,
  TechnicianInformationResolver,
  InstallerRolesResolver
} from './resolvers/index';

//containers
import { 
  InstallerDetailSummaryComponent,
  InstallerNotesComponent,
  InstallerCertificationsComponent,
  InstallerWorkCrewsComponent,
  WorkCrewManagerComponent,
  TechnicianManagerComponent,
  InstallerWorkCrewTechnicianCreateContainerComponent,
  InstallerWorkCrewTechniciansComponent,
  InstallerWorkCrewWorkCategoriesComponent,
  InstallerWorkCrewPLServicesComponent,
  InstallerWorkCrewScheduleExclusionsComponent,
  InstallerCommunicationEventsComponent,
  InstallerWorkCrewTechnicianRolesComponent,
  InstallerWorkCrewTechnicianRoleContainerComponent
  } from './containers/index';

//components
import { 
  InstallerEligibilitySummaryComponent,
  InstallerCertificationComponent,
  InstallerWorkCrewComponent,
  WorkCrewGeneralInformationComponent,
  InstallerWorkCrewWorkCategoryComponent,
  InstallerWorkCrewZonesComponent,
  InstallerWorkCrewScheduleExclusionComponent,
  InstallerWorkCrewTechnicianCreateComponent,
  TechnicianGeneralInformationComponent,
  InstallerWorkCrewMetricsComponent,
  InstallerWorkCrewTechnicianRoleComponent
} from './components/index';

//guards
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';
import { JobScheduleService } from '../portal-shared/services/index';


@NgModule({
    declarations: [
      InstallerDetailSummaryComponent,
      InstallerNotesComponent,
      InstallerWorkCrewsComponent,
      InstallerEligibilitySummaryComponent,
      InstallerCertificationsComponent,
      InstallerCertificationComponent,
      InstallerWorkCrewComponent,
      InstallerWorkCrewTechniciansComponent,
      WorkCrewGeneralInformationComponent,
      InstallerWorkCrewWorkCategoriesComponent,
      InstallerWorkCrewWorkCategoryComponent,
      InstallerWorkCrewZonesComponent,
      InstallerWorkCrewScheduleExclusionsComponent,
      InstallerWorkCrewScheduleExclusionComponent,
      InstallerWorkCrewPLServicesComponent,
      TechnicianGeneralInformationComponent,
      WorkCrewManagerComponent,
      TechnicianManagerComponent,
      InstallerWorkCrewTechnicianCreateComponent,
      InstallerWorkCrewTechnicianCreateContainerComponent,
      InstallerWorkCrewMetricsComponent,
      InstallerCommunicationEventsComponent,
      InstallerWorkCrewTechnicianRolesComponent,
      InstallerWorkCrewTechnicianRoleContainerComponent,
      InstallerWorkCrewTechnicianRoleComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      FwModule,
      SharedModule,
      FwSharedModule,
      PortalSharedModule,
      ContactModule,
      SmsModule,
      InstallerRoutingModule
  ],
    //exporst are used by both the compliance dashboard and the installer portal
  exports: [
    InstallerWorkCrewTechniciansComponent
  ],
  providers: [SelectionListResolver,
    AllMyProviderLocationsResolver,
    ProviderLocationServiceListResolver,
    InstallerInformationResolver,
    InstallerWorkCrewTechnicianResolver,
    InstallerWorkCrewResolver,
    InstallerTechnicianResolver,
    TechnicianInformationResolver,
    InstallerRolesResolver,
    IsCurrentGuard,
    JobScheduleService,
    ContactTypeSpecificationResolver,
    ContactEmailMetaDataResolver,
    ContactPhoneMetaDataResolver,
    ContactMetaDataResolver,
    ContactAddressMetaDataResolver,
    CommunicationShortcutResolver
  ]
})

export class InstallerModule {
  static forRoot(): ModuleWithProviders<InstallerModule> {
    return {
      ngModule: InstallerModule,
      providers: [  JobScheduleService ]
    };
  }
}
