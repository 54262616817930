import { Component, OnInit, SimpleChanges, OnChanges, ViewChild, ElementRef, Input, Output, EventEmitter, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ScheduleEvent } from '../../enums/schedule.enums';
import { IScheduleInstallerWorkCrewAvailabilityViewModel } from '../../../view-models/i-schedule-installer-work-crew-availability-view-model';
import { IInstallerSortEvent } from '../../interfaces';
import { IScreenBreakpoints } from '../../../../../fw/fw-shared/services/i-screen-breakpoints';
import { ILegend } from '../../../../../shared/components/fw-legend/interfaces/i-legend';
import { ScreenService } from '../../../../../fw/fw-shared/services/screen.service';
import { SchedulerService } from '../../services/scheduler.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { CommunicationEventService } from "../../../portal-shared/services/communication-event.service";
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

@Component({
  selector: 'available-installers',
  templateUrl: './available-installers.component.html'
})
export class AvailableInstallersComponent implements OnInit, OnChanges {
  @Input() availableInstallers: IScheduleInstallerWorkCrewAvailabilityViewModel[];
  @Input() unavailableReason: string;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();
  @ViewChild('sortBy') public sortBy: ElementRef;

  public selectedId: number = 0;
  public legendItems: ILegend[] = [];
  public screenIsSmall$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  activeSortRequest: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public el: ElementRef,
    public screenService: ScreenService,
    public emitterService: HomEventEmitterService,
    public schedulerService: SchedulerService,
    public modalService: ModalService,
    public communicationEventService: CommunicationEventService  ) { }

  public selectInstaller(id: number): void {
    this.selectedId = id;
    const row: IScheduleInstallerWorkCrewAvailabilityViewModel = this.availableInstallers.find(x => x.installerWorkCrewId == id);
    if (row) {
      this.customEvent.emit({ requestor: 'available-installers', event: ScheduleEvent.selectWorkCrew, action: '', data: row });
    }
  }

  public cardClass(row: IScheduleInstallerWorkCrewAvailabilityViewModel): string {
    let className = row.compatibilityWarningLevel === 'Warning' ? ' available-installer--warning'
      : row.compatibilityWarningLevel === 'Valid'  ? ' available-installer--valid'
        : '';

    className += this.selectedId === row.installerWorkCrewId ? ' available-installer--selected' : '';
    className += this.modalService.opened ? ' available-installer__modal' : '';
    return className;
  }

  public onSortChange(val: string): void {
    const data: IInstallerSortEvent = { sortBy: val, sortAsc: val === 'installerWorkCrewCompatibilityScore' ? false : true };
    this.activeSortRequest = true;
    this.customEvent.emit({ requestor: 'available-installers', event: ScheduleEvent.installerSort, action: '', data: data });
  }

  ngOnInit() {
    this.legendItems.push({ icon: 'fas fa-circle', iconCss: 'installers-legend--all-clear', text: 'All Clear', textCss: 'installers-legend--all-clear' });
    this.legendItems.push({ icon: 'fas fa-circle', iconCss: 'installers-legend--warning', text: 'Compatibility', textCss: 'installers-legend--warning' });
    this.legendItems.push({ icon: 'fas fa-circle', iconCss: 'installers-legend--selected', text: 'Selected', textCss: 'installers-legend--selected' });

    this.setSize();

    this.subscription.add(this.screenService.resize$
      .subscribe((e: IScreenBreakpoints) => {
        this.setSize();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['availableInstallers'] && !(changes['availableInstallers'].firstChange)) {
      this.selectedId = 0;
      if (this.sortBy && !this.activeSortRequest) {
        this.sortBy.nativeElement.value = 'installerWorkCrewCompatibilityScore';
      } else {
        this.activeSortRequest = false;
      }
    }
  }

  setSize(): void {
    const myLeft: number = this.el.nativeElement.getBoundingClientRect().left;
    this.screenIsSmall$.next(this.screenService.isSmall(myLeft))
  }

}
