import { Inject, Injectable, OnDestroy} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {  map, take, filter  } from 'rxjs/operators';
import { Subscription, Observable, of } from 'rxjs';

import { IHomDictionary, HomDictionary, ResponseBase, IResponseBase } from '../../../shared/interfaces/index';
import { IAppConstants, appConstants } from '../../../shared/constants/index';
import { DomainObjectService } from '../../../shared/services/index';
 
//store actions and reducers
import * as fromStore from '../../../fw/dynamic-list/store/index';
import * as fromDynamicList from '../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { cloneDeep } from 'lodash';

@Injectable()
export class IsCurrentGuard  implements OnDestroy {
  subscription: Subscription = new Subscription();

  constructor(public router: Router,
    public store: Store<fromStore.IAllDynamicData>,
    public domainObjectService: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants  ) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let myStoreName: string ='';
    let pkId: number = -1;
    let key: string = '';
    let operation: string = '';
    let parentId: number = -1;
    let parentEntityId: number = -1;

    let hasParams:boolean = false;
    if (route.paramMap.has('storeName')) {
      hasParams = true;
      pkId = +route.paramMap.get("id");
      myStoreName = route.paramMap.get('storeName');
      key = route.paramMap.get("key");
      operation = route.paramMap.get("operation");
      parentId = route.parent.paramMap.has('id') ? +route.parent.paramMap.get('id') : -1;
      parentEntityId = route.paramMap.has('portalEntityId') ? +route.paramMap.get('portalEntityId') : -1;
    }

    if (parentId === -1) {
      parentId = parentEntityId;
    }
      //Fatal Error:  Dev Error
      if (!hasParams) {
          console.log('DEV ERROR: IsCurrentGuard and Route does not contain the params required to validate isCurrent. Sending thru: ', route, state);
          return of(true);
      }

    if (operation === this.myConstants.operationTypeCreate) {
      return of(true);
    }

    let objData: fromDynamicList.IListObjectData; 
    let rec: {} = null;

    this.subscription.add(this.store.select(fromStore.getListByType(myStoreName))
      .pipe(take(1),
        map((listsState: fromDynamicList.IDynamicListState) => listsState.objData.find(x => x.parentId == parentId)))
        .subscribe((state: fromDynamicList.IListObjectData) => {
          objData = cloneDeep(state);
          rec = cloneDeep( objData ? objData.data.find(data => data[key] == pkId) : null);
          if (!objData || !rec) {
            const response = new ResponseBase();
            response.success = false;
            this.store.dispatch(new fromStore.SetStaleDataErrorList({ storeName: myStoreName, parentId: parentId, forRow: true, responseBase: response }));
            return false;
          } else if ((rec && !rec.hasOwnProperty('aggregateRootVersion') || rec['aggregateRootVersion'] === null) && !rec.hasOwnProperty('rowVersion')) {
              return of(true);
          }
    }));
    
    //Currently only looks at isCurrent value to see if store values are current
    let params: IHomDictionary[] = [];
    params.push(new HomDictionary('entityId', objData['listMetaData'].fieldMetaData['entityId']));
    params.push(new HomDictionary('pkId', pkId.toString() ));
    params.push(new HomDictionary('rowVersion', rec['rowVersion']));
    params.push(new HomDictionary('aggregateRootVersion', rec['aggregateRootVersion']));


    return this.domainObjectService.getByMethodParams('Entity', 'IsCurrent', params)
      .pipe(
        filter(Boolean), //if store is empty, stops stream (without filter will throw an error)
        map((result: IResponseBase) => {
          if (result.success && result.data.isCurrent && result.data.isAggregateRootCurrent) {
            return true;
          } else {
            this.store.dispatch(new fromStore.SetStaleDataErrorList({
              storeName: myStoreName,
              parentId: parentId,
              forRow: true,
              responseBase: result
            }));
            return false;
          }
        }
      ));
  }

  
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
