import { Component, Input, OnInit, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { IAppConstants, appConstants } from '../../../shared/constants/index';
import { IFieldDefinition } from '../interfaces/i-field-definition';
import { DynamicFieldService } from '../services/dynamic-field.service';
import { IInputButton } from '../../fw-shared/interfaces/i-input-button';
import { IRadioButton } from '../../fw-shared/components/fw-radio-button/interfaces/i-radio-button';
import { FieldSuffix } from '../../dynamic-list/enums/dynamic-field.enums';

@Component({
    selector: 'fw-dynamic-field',
  templateUrl: './dynamic-field.component.html'
})
export class DynamicFieldComponent implements OnInit, OnChanges {
  @Input() field: IFieldDefinition; 
  @Input() selectItems: any[] = [];
  @Input() form: FormGroup;
  @Input() operation: string;
  @Input() submitted: boolean; //dynamic form still need?
  @Input() autoFocus: boolean = false;
  @Input() error: boolean;
  @Input() useContains: boolean;
  @Input() asciiOnly: boolean = false;
  

  public btnConfig: IInputButton;
  public dfOptions: IRadioButton[] = [];
  public disabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public displayType: string = 'string';
  selectItemsChanged: boolean = false;

  constructor(public dynamicFieldService: DynamicFieldService,
    @Inject(appConstants) public myConstants: IAppConstants  ) { }

  public eligibleField() {
    return this.field &&
      this.selectItems &&
      (
        (this.operation === this.myConstants.operationTypeCreate && !this.field.isId) ||
        (this.operation === this.myConstants.operationTypeDetails) ||
        (
          (this.operation === this.myConstants.operationTypeEdit ||
            this.operation === this.myConstants.operationTypeSingleEdit ||
            this.operation === this.myConstants.operationTypeCreate) &&
          this.field.visibleOnEdit));
  }

  public isReadOnly(): boolean {
    return this.operation === this.myConstants.operationTypeDetails || this.field.isId || this.field.alwaysReadOnly || !this.field.isEditable;
  }

  public onDecimalChange(): void {
    if (this.field.fieldType === this.myConstants.fieldTypeDouble) return;
    const fieldControl = this.form.controls[this.field.key];
    this.dynamicFieldService.onDecimalChange(fieldControl);
  }

  ngOnInit() {
    if (!this.operation) {
      console.log('DEV ERROR: Operation is required for dynamic-field to work properly. Please pass in a valid value for operation.');
    }
    this.displayType = this.dynamicFieldService.displayType(this.field, 'form');
    this.btnConfig = {
      label: {
        label: this.field.label,
        alignRight: true
      },
      form: {
        form: this.form,
        name: this.field.key
      }
    };
  }


  ngOnChanges(changes: SimpleChanges) {
    //duct tape:  relook at this.  contact manager is the one requiring this 
    if (changes['operation']) {
      this.setDisabled(this.isReadOnly());
    }

    //selectItems Default handling by operation type
    if (changes['selectItems'] 
      && (this.field.fieldType === this.myConstants.fieldTypeSelect || this.field.fieldType === this.myConstants.fieldTypeEntity)) {
      if (!changes['selectItems'].isFirstChange()) {
        this.selectItemsChanged = true;
      }
      let defaulted: boolean = false;
      if (this.operation === this.myConstants.operationTypeCreate) {
        if (this.selectItems && this.selectItems.length === 1) {
          this.form.controls[this.field.key].setValue(this.selectItems[0]);
          defaulted = true;
        }
      } else if (this.operation !== this.myConstants.operationTypeDetails && !this.selectChoose()) {
        if (this.selectItems && this.selectItems.length === 1) {
          this.form.controls[this.field.key].setValue(this.selectItems[0]);
          defaulted = true;
          this.form.markAsDirty();
        }
      }
      if (!defaulted && this.selectChoose()) {
        this.form.controls[this.field.key].setValue(null);
      }
    }

  }

  setFocus(): AbstractControl {
    return this.form.controls[this.field.key];
  }

  //needed to get around a disabled issue for reactive forms for select and checkbox type fields.
  //Issue occurs when go from detail to edit or vice versa
  setDisabled(disable: boolean) {
    this.disabled$.next(disable);
    if (disable) {
      this.form.controls[this.field.key].disable();
    } else {
      this.form.controls[this.field.key].enable();
    }
  }

  isDisabled(): boolean {
    return this.form.controls[this.field.key].disabled;
  }

  selectChoose(): boolean {
    switch (this.operation) {
      case this.myConstants.operationTypeCreate:
        return ((!this.form.controls[this.field.key].value || this.selectItemsChanged)
          && this.selectItems != null);
      case this.myConstants.operationTypeEdit:
        return ((!this.form.controls[this.field.key].value)
          && this.selectItems
          && (this.selectItems.length === 0));
     case this.myConstants.operationTypeDetails:
        return false;
      default:
        return true;
    }
  }

  //fileSourceKey is not rendered but is part of the form: refer where it is added to the form in meta-data.service
  onFileChange(event): void {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      const fileSourceKey = this.field.key.concat(FieldSuffix.fileSource);
      this.form.controls[fileSourceKey].setValue(file);
      this.form.controls[this.field.key].setValue(file ? file.name : '');
      //faking user interaction on this field, so have to manually mark as dirty
      this.form.controls[this.field.key].markAsDirty();
    }
  }

  //fileSourceKey is not rendered but is part of the form: refer where it is added to the form in meta-data.service
  onRemoveFile(): void {
    const fileSourceKey = this.field.key.concat(FieldSuffix.fileSource);
    this.form.controls[fileSourceKey].setValue('');
    this.form.controls[this.field.key].setValue('');
    this.form.controls[this.field.key].markAsDirty();
  }
}
