import { Directive, HostListener, Input, Output, EventEmitter, ElementRef, Renderer2, AfterViewInit, QueryList, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";

import { IInfiniteScrollOptions } from "../interfaces";

@Directive({
  selector: '[infiniteScroll]'
})
export class InfiniteScrollDirective implements AfterViewInit, OnDestroy {
  @Input() public options: IInfiniteScrollOptions = { scrollDown: true };
  @Input() public listContainer: QueryList<ElementRef>;
  @Input() public refreshOn: string = '';
  @Output() public scrolledToEnd = new EventEmitter();

  subscription: Subscription = new Subscription();
  lastScrollPos: number = 0;
  lastScrollHeight: number = 0;
  lastRefreshOn: string;

  constructor(
    private host: ElementRef,
    private renderer: Renderer2) { }

  public get element() {
    return this.host.nativeElement;
  }

  @HostListener('scroll', ['$event']) private onScroll(event: Event): void {
    if (this.options.scrollDown) {
      // How close to the top/end do you want to trigger the scroll
      // Needs to work with multiple page sizes: 5, 10, 15, etc.
      const threshold: number = 3;
      if ((this.element.offsetHeight + threshold) + this.element.scrollTop >= this.element.scrollHeight) {
        this.lastScrollPos = this.element.offsetHeight;
        this.lastScrollHeight = this.element.scrollHeight;
        this.scrolledToEnd.emit();
      }
    } else {
      if (this.element.scrollTop === 0) {
        this.lastScrollPos = this.element.scrollHeight;
        this.lastScrollHeight = this.element.scrollHeight;
        this.scrolledToEnd.emit()
      }
    }
  };

  ngAfterViewInit(): void {
    const options = {
      root: this.isHostScrollable() ? this.host.nativeElement : null,
      ...this.options
    };
    let currentPage = 1;

    this.subscription.add(this.listContainer.changes.subscribe((list: QueryList<ElementRef>) => {
      let newVal = this.element.scrollHeight - this.lastScrollHeight;

      //in case of reload
      if (this.lastRefreshOn !== this.refreshOn || newVal < 0 || this.lastScrollPos === 0) {
        currentPage = 1;
        this.lastRefreshOn = this.refreshOn;
      }
      if (this.options.scrollDown) {
        this.scrollToVal(currentPage === 1 ? 0 : this.lastScrollPos);
      } else {
        this.scrollToVal(currentPage === 1 ? this.element.scrollHeight : newVal - 3);
      }

      currentPage = currentPage + 1;
    }));

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isHostScrollable(): boolean {
    const style = window.getComputedStyle(this.element);

    return style.getPropertyValue('overflow') === 'auto' ||
      style.getPropertyValue('overflow') === 'scroll' ||
      style.getPropertyValue('overflow-y') === 'auto' ||
      style.getPropertyValue('overflow-y') === 'scroll';
  }

  scrollToVal(val: number): void {
    this.renderer.setProperty(this.element, 'scrollTop', val);
  }
}
