export interface IDetailContainerConfig {
  id: number;
  rowIndex: number;
  storeName: string;
  key: string;
  operation: string;
  showNext: boolean;
  showPrev: boolean;
  requestTime: string;

  useRouterOutlet: boolean;
  showCancel: boolean;
  wrapsForm: boolean;
  showNav: boolean;
  showTitle: boolean;
  showErrorBox: boolean;
  parentId: number;
  customButtons: ICustomDetailButton[];
}

export interface ICustomDetailButton {
  title: string;
  css: string;
  event: string;
  action: string;
}



export class DetailContainerConfig implements IDetailContainerConfig {
  id: number;
  storeName: string;
  key: string;
  operation: string;
  showNext: boolean;
  showPrev: boolean;
  requestTime: string;
  rowIndex: number;

  useRouterOutlet: boolean;
  showCancel: boolean;
  wrapsForm: boolean;
  showNav: boolean;
  showTitle: boolean;
  showErrorBox: boolean;
  parentId: number;
  customButtons: ICustomDetailButton[];

  constructor(id: number, storeName: string, key: string, operation: string, showNext: boolean, showPrev: boolean, requestTime: string, rowIndex: number = -1) {
    this.id = id;
    this.storeName = storeName;
    this.key = key;
    this.operation = operation;
    this.showNext = showNext;
    this.showPrev = showPrev;
    this.requestTime = requestTime;
    this.rowIndex = rowIndex;
    this.useRouterOutlet = false;
    this.showCancel = false;
    this.wrapsForm = false;
    this.showNav = true;
    this.showErrorBox = false;
    this.showTitle = true;
  }
}
