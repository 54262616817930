<ng-container *ngIf="hasPostIts && workOrderId">
  <fw-component-title [title]="'Special Instructions'"></fw-component-title>
  <div class="app-card app-card--warning">
    <post-it-notes [projectId]="projectId" [workOrderId]="workOrderId"></post-it-notes>
  </div>
</ng-container>

<div class="nested-panel dispatch-manager">
  <fw-dynamic-list *ngIf="listDefinition$ | async"
                   [listTitle]="title"
                   [vmListDefinition]="listDefinition$ | async"
                   (selectEvent)="onListSelect($event)"
                   (pageEvent)="onListPage($event)">
  </fw-dynamic-list>
  <fw-error-box *ngIf="(errorData$ | async)?.length" [errorData]="errorData$ | async" [joinErrors]="false"></fw-error-box> 
  <div class="app-btn-box">
    <button type="button" class="app-btn app-btn--cancel" (click)="close()">Close</button>
    <button class="app-btn app-btn--primary"
            type="button"
            (click)="assignCrew()"
            [disabled]="selectedCrewId < 0">
      Assign
    </button>
  </div>
</div>
