<ng-container *ngIf="!row.communicationPreferenceValue">
  <div>{{ onlyIcon ? '' : 'Preferred Contact Method Not Set' }}</div>
</ng-container>
<ng-container *ngIf="row.communicationPreferenceValue && (forPhoneCall || forTexting)">
  <fw-phone
    [phoneNumber]="row.communicationPreferenceValue"
    [displayType]="displayType"
    [forTexting]="forTexting"
    [data]="row"
    [cssClass]="cssClass"
    [enabled]="enabled"
    [allowText]="allowText">
  </fw-phone>
</ng-container>
<ng-container *ngIf="row.communicationPreferenceValue && forEmail">
  <fw-email
      [emailAddress]="row.communicationPreferenceValue"
      [displayType]="displayType"
      [cssClass]="cssClass"
      [forHighLevelSummary]="forHighLevelSummary">
  </fw-email>
</ng-container>
