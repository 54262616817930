import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import { ITitleListItem } from './interfaces/i-title-list-item';

@Component({
    selector: 'fw-component-title',
    templateUrl: './fw-component-title.component.html'
})
export class ComponentTitleComponent implements OnChanges  {
  @Input() title: string = '';
  @Input() entityLabel: string = '';
  @Input() entityColor: string = '';
  @Input() entityStatus: string = '';
  @Input() forHighLevelSummary: boolean = false;
  @Input() collapsible: boolean = false; 
  @Input() userFilters: ITitleListItem[] = [];
  @Input() infoText: string = '';
  @Input() childVisible: boolean = false;

  @Output() public toggleEvent = new EventEmitter<boolean>();
  @Output() public changeFilter = new EventEmitter<number>();

  public isCollapsed: boolean = false;
  public appliedFilterName: string = '';
  public showUserFilterPopover: boolean = false;

  constructor() { }

  public toggle(): void {
    this.isCollapsed = !this.isCollapsed;
    this.toggleEvent.emit(this.isCollapsed);
  }

  public changeUserFilter(id: number): void {
    this.changeFilter.emit(+id);
    this.showOrHidePopover(false);
  }

  //You needed to implement OnChanges in order to use it (like inheriting a class)
  ngOnChanges(changes: SimpleChanges) {
    if (changes['userFilters']) {
      let activeFilter = this.userFilters ? this.userFilters.find((x: ITitleListItem) => x.isActive) : null;
      this.appliedFilterName = activeFilter ? activeFilter.label : '';
    }
  }

  public showOrHidePopover(show: boolean): void {
    this.showUserFilterPopover = show;
  }

}
