<div class="portal">
  <div [ngClass]="{ 'loading-mask': loading$ | async }">
    <div class="portal__detail--item dispatch-calendar__filters">
      <dispatch-calendar-filters [selectedDate]="selectedDate"
                                 [displayView]="displayView"
                                 [locations]="locations"
                                 [services]="services"
                                 [woStatuses]="woStatuses"
                                 [generalContractors]="generalContractors"
                                 [autoFilter]="autoFilter"
                                 (customEvent)="onCustom($event)">
      </dispatch-calendar-filters>
    </div>
    <div *ngIf="displayFilterText" class="dispatch-calendar__active-filter">
      <i class="fas fa-filter app-icon--success"></i>
      <span class="dispatch-calendar__active-filter--label">Active Filter:</span>
      <span class="dispatch-calendar__active-filter--text">{{ displayFilterText }}</span>
    </div>

    <div *ngIf="displayView" class="dispatch-calendar__toolbar">
      <!--PREV-->
      <button class="app-btn-icon app-btn-icon--primary dispatch-calendar__toolbar__prev  dispatch-calendar__toolbar--icon"
              (click)="goToPrevNext(true)"
              title="Go To Previous {{ displayView }}">
        <i class="fas fa-chevron-left"></i>
      </button>
      <!--NEXT-->
      <button class="app-btn-icon app-btn-icon--primary dispatch-calendar__toolbar__next  dispatch-calendar__toolbar--icon"
              (click)="goToPrevNext(false)"
              title="Go To Next {{ displayView }}">
        <i class="fas fa-chevron-right"></i>
      </button>
      <!--TODAY-->
      <div class="app-btn-icon dispatch-calendar__toolbar__today" (click)="goToToday()" title="Go To Today">
        <div class="dispatch-calendar__toolbar__today__label">
          <div class="dispatch-calendar__toolbar__today__label--text">Today</div>
        </div>
        <div class="dispatch-calendar__toolbar__today__value">
          <div class="dispatch-calendar__toolbar__today__value--text">{{ today | date: 'd' }}</div>
        </div>
      </div>
      <!--DISPLAY DATE-->
      <div class="dispatch-calendar__toolbar__header">
        <div class="dispatch-calendar__toolbar__header--label">
          {{ displayDateRange }}
        </div>
        <div class="dispatch-calendar__toolbar__header--count">
          ({{(data$ | async)?.length }} Items)
        </div>
      </div>
      <!--LOCK DAY-->
      <ng-container *ngIf="userPriviledgesService.canLockScheduleDays$ | async">
        <button *ngIf="displayView === dispatchCalendarService.viewDay"
                class="app-btn-icon dispatch-calendar__toolbar__lock  dispatch-calendar__toolbar--icon"
                [ngClass]="{'app-btn-icon--success': !(isDayLocked$ | async), 'app-btn-icon--danger': (isDayLocked$ | async) }"
                (click)="lockDay()"
                title="{{ (isDayLocked$ | async) ? 'Unlock' :  'Lock' }} Day">
          <i class="fas fa-lock-{{ !(isDayLocked$ | async) ? 'open-' : '' }}alt"></i>
        </button>
      </ng-container>
      <ng-container *ngIf="displayView === dispatchCalendarService.viewDay && !(userPriviledgesService.canLockScheduleDays$ | async)">
        <div class="dispatch-calendar__toolbar__lock  dispatch-calendar__toolbar--icon"
             [ngClass]="{'app-icon--success': !(isDayLocked$ | async), 'app-icon--danger': (isDayLocked$ | async) }"
             title="{{ (isDayLocked$ | async) ?  'Day is Locked' : 'Day is Unlocked'  }} Day">
          <i class="fas fa-lock-{{ !(isDayLocked$ | async) ? 'open-' : '' }}alt"></i>
        </div>
      </ng-container>
      <!--PRINT-->
      <button *ngIf="displayView === dispatchCalendarService.viewDay"
              class="app-btn-icon app-btn-icon--print dispatch-calendar__toolbar__print  dispatch-calendar__toolbar--icon"
              [disabled]="!(data$ | async)?.length"
              (click)="printPackets()"
              title="Print All Work Order Packets For Day">
        <i class="fas fa-print"></i>
      </button>
      <!--DISPLAY VIEW-->
      <div class="app-group-btn-box dispatch-calendar__toolbar__timeframe">
        <button type="button"
                class="app-group-btn app-btn--primary"
                [ngClass]="{'app-group-btn--selected': displayView === dispatchCalendarService.viewDay }"
                (click)="changeDisplayView(dispatchCalendarService.viewDay)">
          {{  dispatchCalendarService.viewDay }}
        </button>
        <button type="button"
                class="app-group-btn app-btn--primary"
                [ngClass]="{'app-group-btn--selected': displayView === dispatchCalendarService.viewWeek }"
                (click)="changeDisplayView(dispatchCalendarService.viewWeek)">
          {{ dispatchCalendarService.viewWeek }}
        </button>
        <button type="button"
                class="app-group-btn app-btn--primary"
                [ngClass]="{'app-group-btn--selected': displayView === dispatchCalendarService.viewMonth }"
                (click)="changeDisplayView(dispatchCalendarService.viewMonth)">
          {{ dispatchCalendarService.viewMonth }}
        </button>
      </div>
    </div>

    <!--DAY VIEW-->
    <ng-container *ngIf="displayView === dispatchCalendarService.viewDay">
      <div *ngIf="(data$ | async) as data" class="portal__detail--item">
        <dispatch-day-view [data]="data"
                           [selectedDate]="selectedDate"
                           [forView]="displayView"
                           [holidays]="homHolidays$ | async"
                           [canScheduleLockDays]="userPriviledgesService.canLockScheduleDays$ | async"
                           (customEvent)="onCustom($event)">
        </dispatch-day-view>
      </div>
    </ng-container>

    <!--WEEK VIEW-->
    <ng-container *ngIf="displayView === dispatchCalendarService.viewWeek">
      <div *ngIf="(data$ | async)  && (slotScheduleCalendars$ | async)" class="portal__detail--item">
        <dispatch-week-view [data]="data$ | async"
                            [selectedDate]="selectedDate"
                            [holidays]="homHolidays$ | async"
                            [canScheduleLockDays]="userPriviledgesService.canLockScheduleDays$ | async"
                            [slotCalendars]="slotScheduleCalendars$ | async"
                            (customEvent)="onCustom($event)">
        </dispatch-week-view>
      </div>
    </ng-container>

    <!--MONTH VIEW-->
    <ng-container *ngIf="displayView === dispatchCalendarService.viewMonth">
      <div *ngIf="(data$ | async) && (slotScheduleCalendars$ | async)" class="portal__detail--item">
        <dispatch-month-view [data]="data$ | async"
                             [selectedDate]="selectedDate"
                             [holidays]="homHolidays$ | async"
                             [canScheduleLockDays]="userPriviledgesService.canLockScheduleDays$ | async"
                             [slotCalendars]="slotScheduleCalendars$ | async"
                             (customEvent)="onCustom($event)">
        </dispatch-month-view>
      </div>
    </ng-container>
  </div>
  <br />
  <div *ngIf="data$ | async" class="portal__detail--item  dispatch-calendar__filters">
    <div class="dispatch-calendar__accordion" (click)="toggleList()">
      <fw-component-title class="dispatch-calendar__accordion__title" title="Dispatch Calendar List View"></fw-component-title>
      <div class="dispatch-calendar__accordion__btn">
        <button class="app-btn-icon app-btn-icon--primary"
                title="{{ showList ? 'Collapse ' : 'Show' }} Filters">
          <i class="fas fa-chevron-{{ showList ? 'down' : 'right' }}"></i>
        </button>
      </div>
    </div>
    <div *ngIf="showList && (listDefinition$ | async)">
      <fw-dynamic-list [vmListDefinition]="listDefinition$ | async"
                       (customEvent)="onCustom($event)">
      </fw-dynamic-list>
    </div>
  </div>
  <fw-error-box [errorData]="errorData$ | async" [joinErrors]="true"></fw-error-box>
</div>

