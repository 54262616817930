import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IInstallerCompatibilityPreference, IInstallerScore, InstallerCompatibilityPreference } from '../../../view-models/installer-compatibility-preference';
import { UpdateObjectCustomModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/index';

import { AdminStore } from '../../enums/admin.enums';
import { AdminProviderService } from '../../services/admin-provider.service';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';

@Component({
  selector: 'installer-scoring',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './installer-scoring.component.html'
})
export class InstallerScoringComponent implements OnInit, OnDestroy {
  public operation: string;
  public objectData: IInstallerCompatibilityPreference = null;
  public scoring$: BehaviorSubject<IInstallerScore[]> = new BehaviorSubject([])

  subscription = new Subscription();
  controllerName: string = 'InstallerCompatibilityPreference';

  constructor(
    public activatedRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public adminService: AdminProviderService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onEdit(): void {
    this.operation = this.myConstants.operationTypeEdit;
  }

  public onCancel(): void {
    this.operation = this.myConstants.operationTypeDetails;
  }

  public onSave(e: IHomEventEmitter) {
    let rec: IInstallerCompatibilityPreference = new InstallerCompatibilityPreference();
    const formData: IInstallerCompatibilityPreference = e.data;
    rec.installerCompatibilityPreferenceId = this.objectData.installerCompatibilityPreferenceId;
    rec.provider_providerId = this.objectData.provider_providerId;;
    rec.workCategoryCompatibilityWeight = formData.workCategoryCompatibilityWeight;
    rec.locationServicePreferenceCompatibilityWeight = formData.locationServicePreferenceCompatibilityWeight;
    rec.locationServiceFlexibilityWeight = formData.locationServiceFlexibilityWeight;
    rec.zonePreferenceCompatibilityWeight = formData.zonePreferenceCompatibilityWeight;
    rec.warrantyCallbackRateWeight = formData.warrantyCallbackRateWeight;
    rec.averageCustomerSurveyResponseWeight = formData.averageCustomerSurveyResponseWeight;
    rec.jobHistoryConsiderationAssignmentThreshold = formData.jobHistoryConsiderationAssignmentThreshold;
    rec.maximumJobHistoryConsiderationDays = formData.maximumJobHistoryConsiderationDays;

    const listDef = this.adminService.loadInstallerCompatibilityPreferenceListDefinition();
    const keyData: IKey = { storeName: AdminStore.installerCompatibilityPreferences, parentId: -1, key: listDef.rowKeyId, id: this.objectData.installerCompatibilityPreferenceId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  newRequest() {
    this.operation = this.myConstants.operationTypeDetails;
    //go get the data:  not a true list, just one record
    const listDef = this.adminService.loadInstallerCompatibilityPreferenceListDefinition();
    this.store.dispatch(new fromStore.SetListDefinition({
      storeName: AdminStore.installerCompatibilityPreferences,
      parentId: listDef.parentId,
      listDefinition: listDef
    }));
    this.store.dispatch(new fromStore.GetList({ listDefinition: listDef, listFilter: listDef.defaultListFilter, parentId: listDef.parentId }));

    this.subscription.add(this.store.pipe(select(fromStore.getListByType(listDef.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == listDef.parentId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.working) {
          return;
        }
        if (state && state.data && state.data.length > 0) {
          if (!state.errorData || !state.errorData.length) {
            if (state.event ) {
              this.onCancel();
              this.store.dispatch(new fromStore.ClearEventList({ storeName: AdminStore.installerCompatibilityPreferences, parentId: -1 }));
            }
            this.objectData = cloneDeep(state.data[0]);
            this.loadScoring();
          }
        }
    }));
  }

  loadScoring(): void {
    let scoring: IInstallerScore[] = [];
    scoring.push({ fieldKey: 'workCategoryCompatibilityWeight', type: this.myConstants.fieldTypePercentage, weight: .2857, descriptor: 'of score'});
    scoring.push({ fieldKey: 'locationServicePreferenceCompatibilityWeight', type: this.myConstants.fieldTypePercentage, weight: .1143, descriptor: 'of score' });
    scoring.push({ fieldKey: 'locationServiceFlexibilityWeight', type: this.myConstants.fieldTypePercentage, weight: .1143, descriptor: 'of score' });
    scoring.push({ fieldKey: 'zonePreferenceCompatibilityWeight', type: this.myConstants.fieldTypePercentage, weight: .857, descriptor: 'of score' });
    scoring.push({ fieldKey: 'warrantyCallbackRateWeight', type: this.myConstants.fieldTypePercentage, weight: .2000, descriptor: 'of score' });
    scoring.push({ fieldKey: 'averageCustomerSurveyResponseWeight', type: this.myConstants.fieldTypePercentage, weight: .2000, descriptor: 'of score' });
    scoring.push({ fieldKey: 'jobHistoryConsiderationAssignmentThreshold', type: this.myConstants.fieldTypeNumber, weight: 5, descriptor: 'jobs' });
    scoring.push({ fieldKey: 'maximumJobHistoryConsiderationDays', type: this.myConstants.fieldTypeNumber, weight: 100, descriptor: 'days' });

    this.scoring$.next(scoring);
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
