import { IDetailRouteParameter, IScheduleRouteParameter } from '../../dynamic-list/interfaces/index';
import { IProjectRouteParameter } from '../../../app/portals/project/interfaces/i-project-route-parameter';

export enum ModalSizeType {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
  full = 'full',
}

export interface IModal {
  title: string;
  path: string;
  castExit: boolean;
  hasTabs: boolean; //renders a container wrapper with overflow handling when false
  onExit?: any;
  sizeType?: ModalSizeType; 
  id?: number;
  optionalParams?: IDetailRouteParameter | IProjectRouteParameter | IScheduleRouteParameter;
  state?: any;
  data?: any;
  showLoading?: boolean;
  isSubModal?: boolean;
}
