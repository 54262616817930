import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../../auth/services/auth-guard.service';
import { ContactMetaDataResolver } from '../../contact/resolvers/contact-meta-data-resolver.service';
import { ContactPhoneMetaDataResolver } from '../../contact/resolvers/contact-phone-meta-data-resolver.service';
import { ContactEmailMetaDataResolver } from '../../contact/resolvers/contact-email-meta-data-resolver.service';
import { ContactAddressMetaDataResolver } from '../../contact/resolvers/contact-address-meta-data-resolver.service';
import { ContactTypeSpecificationResolver } from '../../contact/resolvers/contact-type-specification-resolver.service';
import { PoImportHeaderResolver } from '../po-import/resolvers/po-import-header-resolver.service';

import {
  SelectionListResolver,
  MetaDataResolver,
  EntityResolver,  GeneralContractorsResolver,
  CommunicationShortcutResolver,
  CommunicationDocumentTypeResolver,
  DocumentTypeResolver,
  ProjectChildrenResolver
} from '../resolvers/index';


import {
  ProjectInformationResolver,
  WorkOrderInformationResolver,
  PurchaseOrderResolver,
  ProjectChargeBackResolver,  ProjectNotesResolver
} from './resolvers/index';


//components
import {
  ContactCustomerFlagDetailComponent,
  EmptyComponent,  AQTChangeComponent
} from '../portal-shared/components/index';

import {
  ProjectChargeBackCreateComponent,
  WorkOrderItemComponent,
  PurchaseOrderItemComponent,
  ChargeBackItemComponent,
  WorkOrdersGenerateComponent
} from './components/index';

import { ContactNotesComponent } from '../../contact/components';

//containers
import {
  ContactCustomerFlagsComponent,
  WorkOrderCallLogsComponent,
  WorkOrderNotesComponent,  RequiredDocumentCreateContainerComponent
  } from '../portal-shared/containers/index';

import {
  ProjectComponent,
  OriginatingProjectSummaryComponent,
  ChargeBackManagerComponent,
  ProjectContactManagerComponent,
  ProjectDetailSummaryComponent,
  ProjectFileViewerComponent,
  ProjectHistoryComponent,
  ProjectNotesComponent,
  PurchaseOrderManagerComponent,
  ProjectPurchaseOrderCreateContainerComponent,
  ProjectWorkOrderCreateContainerComponent,
  WorkOrderManagerComponent,
  WarrantyScriptsContainerComponent,
  ProjectCreateContainerComponent,
  ProjectWorkOrderItemSummaryComponent,
  PurchaseOrderNotesComponent,
  ProjectPurchaseOrderItemSummaryComponent,
  PurchaseOrderMeasuresComponent,
  ProjectPurchaseOrderSyncComponent,
  PoViewExternalDataComponent,
  WarrantyProjectSummaryComponent,
  ProjectCommunicationEventsComponent,
  CommunicationEventActionsComponent,
  DeleteReasonNoteComponent
} from './containers/index';

import {
  PoImportGroupedHeadersComponent,
  PoImportHeadersComponent,
  PoImportHeaderContainerComponent,
  PoImportLineContainerComponent,  PoDownloadImportManagerComponent
} from '../po-import/containers';
import { FileViewerContainerComponent, FileUploadManagerComponent } from '../../file-upload/containers';
import { SmsConversationComponent } from "../../sms/containers";

//guards
import { ProjectGuard } from './services/project-guard.service';
import { LoadingPageComponent } from '../../../shared/components';
import { CrmFormsManagerComponent } from './containers/crm-forms-manager/crm-forms-manager.component';
import { CrmFormEntryComponent } from './containers/crm-form-entry/crm-form-entry.component';


const portalRoutes: Routes = [
{
    path: 'project', //componentless route 
    children: [
      {
        path: ':portalId/:portalEntityId', //not really needed at this time, but keeping so tab factory can always call the same route structure
        component: ProjectComponent,
        canActivate: [AuthGuard, ProjectGuard],
        data: {
          contactTypeId: '1'
        },
        resolve: {
          infoLoaded: ProjectInformationResolver
        },
        children: [
          {
            path: 'sms-convo/:id/:phone/:name/:parent',
            outlet: 'chatbar',
            component: SmsConversationComponent,
            resolve: {
              hasShortcuts: CommunicationShortcutResolver,
              hasDocTypes: CommunicationDocumentTypeResolver,
              hasFileTypes: DocumentTypeResolver,
              hasEntities: EntityResolver
            }
          },
          {
            path: 'project-empty',
            outlet: 'portal-detail',
            component: EmptyComponent
          },
          {
            path: 'loading/:container',
            outlet: 'portal-detail',
            component: LoadingPageComponent
          },
          {
            path: 'project-detail-summary/:id',
            outlet: 'portal-detail',
            component: ProjectDetailSummaryComponent,
            children: [
              {
                path: 'work-order-create/:id',
                outlet: 'workOrderCreateOutlet',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
                component: ProjectWorkOrderCreateContainerComponent
              },
              {
                path: 'purchase-order-create/:id',
                outlet: 'purchaseOrderCreateOutlet',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
                component: ProjectPurchaseOrderCreateContainerComponent
              },
              {
                path: 'charge-back-create/:id',
                outlet: 'chargeBackCreateOutlet',
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },
                component: ProjectChargeBackCreateComponent
              },
              {
                path: 'required-document-create/:id',
                outlet: 'reqdDocCreateOutlet',
                resolve: {
                  selectListsLoaded: SelectionListResolver,
                  childrenLoaded: ProjectChildrenResolver
                },
                component: RequiredDocumentCreateContainerComponent
              }
            ]
          },
          {
            path: 'project-file-viewer/:id',
            outlet: 'portal-detail',
           component: ProjectFileViewerComponent,
            children: [
              {
                path: 'file-uploader/:id',
                outlet: 'projectFilesOutlet',
                component: FileUploadManagerComponent,
                data: {
                  storeName: 'projectFiles',
                  controller: 'projectFile'
                },
                resolve: {
                  docTypesLoaded: DocumentTypeResolver
                }
              }
            ]
          },
          {
            path: 'project-contact-manager/:id',
            outlet: 'portal-detail',
            component: ProjectContactManagerComponent,
            data: {
              contactTypeId: '1' //customer
            },
            title: 'project-contact-manager',
            resolve: {
              hasContactMetaData: ContactMetaDataResolver,
              hasTypeData: ContactTypeSpecificationResolver,
              hadPhoneMetaData: ContactPhoneMetaDataResolver,
              hasEmailMetaData: ContactEmailMetaDataResolver,
              hasAddrMetaData: ContactAddressMetaDataResolver
            },
          },
          {
            path: 'project-notes/:id',
            outlet: 'portal-detail',
            component: ProjectNotesComponent,
            resolve: {
              hasNotes: ProjectNotesResolver
            }
          },
          {
            path: 'project-communication-events/:id',
            outlet: 'portal-detail',
            component: ProjectCommunicationEventsComponent,
            children: [
              {
                path: 'project-communication-event/:id',
                outlet: 'communicationEventOutlet',
                component: CommunicationEventActionsComponent
              }
            ]
          },
          {
            path: 'project-history/:id',
            outlet: 'portal-detail',
            component: ProjectHistoryComponent
          },
          {
            path: 'originating-project-summary/:id',
            outlet: 'portal-detail',
            component: OriginatingProjectSummaryComponent
          },
         {
            path: 'work-order-manager/:id',
            outlet: 'portal-detail',
            resolve: {
              woLoaded: WorkOrderInformationResolver
           },
            component: WorkOrderManagerComponent,
            children: [
              {
                path: 'work-order-item-detail/:id',
                outlet: 'workOrderItemDetailOutlet',
                component: WorkOrderItemComponent,
                data: {
                  resolveWith: 'parentId' //will be workOrderId
                },
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              },
              {
                path: 'wo-aqt-chgs/:id',
                outlet: 'aqtChgsOutlet',
                component: AQTChangeComponent
              }
            ]
          },
          {
            path: 'purchase-order-manager/:id',
            outlet: 'portal-detail',
            resolve: {
              poLoaded: PurchaseOrderResolver
            },
            component: PurchaseOrderManagerComponent,
            children: [
              {
                path: 'purchase-order-item-detail/:id',
                outlet: 'purchaseOrderItemDetailOutlet',
                component: PurchaseOrderItemComponent,
                data: {
                  resolveWith: 'parentId' //will be purchaseOrderId
                },
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]

          },
          {
            path: 'charge-back-manager/:id',
            outlet: 'portal-detail',
            resolve: {
              cbLoaded: ProjectChargeBackResolver
            },
            //to handle dashboard to po subtab - uses view  - no iscurrent guard
            component: ChargeBackManagerComponent,
            children: [
              {
                path: 'charge-back-item-detail/:id',
                outlet: 'chargeBackItemDetailOutlet',
                component: ChargeBackItemComponent,
                data: {
                  resolveWith: 'parentId' 
                },
                resolve: {
                  selectListsLoaded: SelectionListResolver
                }
              }
            ]
          }
        ]
      }
    ]
  },  // BEGIN MODAL ROUTES
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'customer-flags/:id',
    outlet: 'modal',
    component: ContactCustomerFlagsComponent,
    //canActivate: [AuthGuard],
    children: [
      {
        path: 'customer-flag-detail/:id',
        outlet: 'customerFlagOutlet',
        resolve: {
          selectListsLoaded: SelectionListResolver
        },
        component: ContactCustomerFlagDetailComponent
      }
    ]
  },
  {
    path: 'contact-notes/:id/:action',
    outlet: 'modal',
    component: ContactNotesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project-notes/:id/:action',
    outlet: 'modal',
    component: ProjectNotesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'work-order-notes/:id',
    outlet: 'modal',
    component: WorkOrderNotesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'purchase-order-notes/:id',
    outlet: 'modal',
    component: PurchaseOrderNotesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'delete-reason/:id/:poId/:woId',
    outlet: 'modal',
    component: DeleteReasonNoteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'crm-forms-manager/:id/:projectId',
    outlet: 'modal',
    component: CrmFormsManagerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project-purchase-order-create/:id',
    outlet: 'modal',
    resolve: {
      selectListsLoaded: SelectionListResolver
    },
    component: ProjectPurchaseOrderCreateContainerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project-work-order-create/:id',
    outlet: 'modal',
    resolve: {
      selectListsLoaded: SelectionListResolver
    },
    component: ProjectWorkOrderCreateContainerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project-charge-back-create/:id',
    outlet: 'modal',
    resolve: {
      selectListsLoaded: SelectionListResolver
    },
    component: ProjectChargeBackCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'file-viewer/:id',
    outlet: 'modal',
    component: FileViewerContainerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'warranty-scripts/:id',
    outlet: 'modal',
    component: WarrantyScriptsContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'project-summary/:id',
        outlet: 'woWarrantyOutlet',
        canActivate: [ProjectGuard],
        resolve: {
          infoLoaded: ProjectInformationResolver
        },
        component: WarrantyProjectSummaryComponent,

      }
    ]
  },
  {
    path: 'project-create/:id',
    outlet: 'modal',
    component: ProjectCreateContainerComponent,
    canActivate: [AuthGuard],
    resolve: {
      selectListsLoaded: SelectionListResolver,
    },
  },
  {
    path: 'work-order-call-log/:id',
    outlet: 'modal',
    component: WorkOrderCallLogsComponent,
    canActivate: [AuthGuard],
    data: {
      storeName: 'workOrderCallLogResponses',
      controller: 'projectCallLogResponse'
    },
    resolve: {
      hasMeta: MetaDataResolver,
      selectListsLoaded: SelectionListResolver
    },
  },
  {
    path: 'po-import/:id/:projectId',
    outlet: 'modal',
    component: PoImportGroupedHeadersComponent,
    resolve: {
      gcs: GeneralContractorsResolver
    },
    canActivate: [AuthGuard],
    children: [
      { //view all po-import-headers associated to this vendor Po + branch
        path: 'po-import-headers/:id',
        outlet: 'poImportHeadersOutlet',
        data: {
          storeName: 'poImportHeaders'
        },
        resolve: {
          infoLoaded: PoImportHeaderResolver
        },
        component: PoImportHeadersComponent,
        children: [
          {
            path: 'po-import-header/:id',
            outlet: 'poImportHeaderOutlet',
            component: PoImportHeaderContainerComponent,
            children: [
              {
                path: 'po-import-line/:id',
                outlet: 'poImportLineOutlet',
                component: PoImportLineContainerComponent,
                resolve: {
                  selectListsLoaded: SelectionListResolver
                },

              },
            ]

          },
        ]
      },
    ]
  },
  {
    path: 'po-sync/:id',
    outlet: 'modal',
    component: ProjectPurchaseOrderSyncComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'wos-generate/:id/:locationId/:merge',
    outlet: 'modal',
    component: WorkOrdersGenerateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project-work-order-item-summary/:id',
    outlet: 'modal',
    component: ProjectWorkOrderItemSummaryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'po-external-data/:id',
    outlet: 'modal',
    component: PoViewExternalDataComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project-purchase-order-item-summary/:id',
    outlet: 'modal',
    component: ProjectPurchaseOrderItemSummaryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'file-upload/:id/:parentId/:actionId/:type/:actionIds',
    outlet: 'modal',
    resolve: {
      docTypesLoaded: DocumentTypeResolver
    },
    component: FileUploadManagerComponent
  },
  {
    path: 'file-upload/:id/:parentId/:actionId/:type',
    outlet: 'modal',
    resolve: {
      docTypesLoaded: DocumentTypeResolver
    },
    component: FileUploadManagerComponent
  },
  {
    path: 'po-measures/:id/:poId/:parentId/:actionId/:type',
    outlet: 'modal',
    resolve: {
      entitiesLoaded: EntityResolver //TBD: still needed?
    },
    component: PurchaseOrderMeasuresComponent
  },
  {
    path: 'po-download-import/:id',
    outlet: 'modal2',
    component: PoDownloadImportManagerComponent,
    resolve: {
      gcs: GeneralContractorsResolver
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class ProjectRoutingModule { }
