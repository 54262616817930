<fw-detail-container [displayFields]="displayFields"
                     [storeName]="myStoreName"
                     [parentId]="parentId"
                     [ignoreKeyOnError]="true">
  <purchase-order-create *ngIf="(branchPrograms$ | async) && (poDefaults$ | async)"
                                 [branchPrograms]="branchPrograms$ | async"
                                 [isWarranty]="(isWarranty$ | async)"
                                 [displayFields]="displayFields"
                                 [storeName]="myStoreName"
                                 [poDefaults]="poDefaults$ | async"
                                 [requestTime]="requestTime"
                                 (cancelEvent)="onCancel($event)"
                                 (createEvent)="onCreateRecord($event)">
  </purchase-order-create>
</fw-detail-container>
