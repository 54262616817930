import {  Injectable, Inject } from '@angular/core';

import { IFieldDefinition } from '../interfaces/i-field-definition';
import { IAppConstants, appConstants } from '../../../shared/constants/index';
import { SearchType } from '../../dynamic-list/enums/search-type.enums';
import { AbstractControl } from '@angular/forms';
import { DecimalPipe, DatePipe } from '@angular/common';
import { isArray } from 'lodash';
import { IRelativeDateParameters, IRelativeDate } from '../../dynamic-list/interfaces';

@Injectable()
export class DynamicFieldService {

  constructor(public  decimalPipe: DecimalPipe,
    public datePipe: DatePipe,
    @Inject(appConstants) public  myConstants: IAppConstants ) { }

    /*
        Based on the field type and form type, Returns the html input type that should be created
    */
    displayType(field: IFieldDefinition, callee: string, searchType: string = ''): string {
      const isFilter = callee === 'filter';
      let displayType: string = field.fieldType;
        switch (field.fieldType) {
          case this.myConstants.fieldTypeNumber:
          case this.myConstants.dataTypeIntNull:
            displayType = isFilter && searchType === SearchType.Range ? this.myConstants.fieldTypeNumericRange : this.myConstants.fieldTypeNumber;
            break;
          case this.myConstants.fieldTypePhone:
            displayType = isFilter ? this.myConstants.fieldTypeString : field.fieldType;
            break;
          case this.myConstants.fieldTypeDoubleDecimal:
          case this.myConstants.dataTypeDoubleDecimalNull:
          case this.myConstants.fieldTypeCurrency:
          case this.myConstants.fieldTypePercentage:
            displayType = isFilter && searchType === SearchType.Range ? this.myConstants.fieldTypeDecimalRange : (field.fieldType === this.myConstants.dataTypeDoubleDecimalNull ? this.myConstants.fieldTypeDoubleDecimal : field.fieldType);
            break;
          case this.myConstants.fieldTypeSelect:
          case this.myConstants.fieldTypeEditableSelect:
          case this.myConstants.fieldTypeFilterableSelect:
            displayType = isFilter ? this.myConstants.fieldTypeMultiSelect : field.fieldType;
            break;
          case this.myConstants.fieldTypeEntity:
            //render as multiselect if filter and have select definition, else will render as number for filter
            //if filter and search type is range, will render as numeric range
            //if display is not for filter, just typical select
            if (isFilter) {
              displayType = field.selectListDefinition === null ?
                searchType === SearchType.Range ?
                  this.myConstants.fieldTypeNumericRange : this.myConstants.fieldTypeNumber
                : this.myConstants.fieldTypeMultiSelect;

            } else {
              displayType =  this.myConstants.fieldTypeSelect;
            }
            break;
          case this.myConstants.fieldTypeDate:
          case this.myConstants.dataTypeDateTime:
          case this.myConstants.dataTypeDateTimeNull:
            displayType = isFilter && searchType === SearchType.Range ? this.myConstants.fieldTypeDateRangePicker : this.myConstants.fieldTypeSingleDatePicker;
            break;
          case this.myConstants.fieldTypeTimeSpan:
            displayType = isFilter && searchType === SearchType.Range ? this.myConstants.fieldTypeTimeSpan : this.myConstants.fieldTypeTimePicker;
            break;
          case this.myConstants.fieldTypeColor:
            displayType = (isFilter ? this.myConstants.fieldTypeString : this.myConstants.fieldTypeColor);
            break;
          case this.myConstants.dataTypeBoolNull:
            displayType = this.myConstants.fieldTypeBoolean;
            break;
            default:
                break;
        }

      if (isFilter && displayType === field.fieldType) {
        //type was not modified, do an additional check for a filter specific display type
        //if this field definition includes select settings then it could be extra data that needs to be filtered (custom filter)
        //console.log('DynamicFieldService Getting field type for filter with: ', field, displayType, callee);
        if (field.selectListDefinition && field.selectListDefinition.hasOwnProperty('storeName') && field.selectListDefinition.storeName !== '')
          displayType = this.myConstants.fieldTypeMultiSelect;
      }
        return displayType;
    }

  /*
   * Used in determining the applicable search types for this field type
  */
  getParameterTypeToFieldTypeMapping(fieldType: string, hasSelectSettings: boolean): string {

    switch (fieldType) {
      case this.myConstants.fieldTypeNumber:
      case this.myConstants.fieldTypeZipCode:
        return this.myConstants.dataTypeInt;
      case this.myConstants.fieldTypeCurrency:
      case this.myConstants.fieldTypePercentage:
        return this.myConstants.fieldTypeDoubleDecimal;
      case this.myConstants.fieldTypeTextArea:
      case this.myConstants.fieldTypeEmail:
      case this.myConstants.fieldTypePhone:
        return this.myConstants.fieldTypeString;
      case this.myConstants.fieldTypeEntity:
        return hasSelectSettings ? this.myConstants.fieldTypeEntity : this.myConstants.dataTypeInt;
      default:
        return fieldType;
    }
  }

  onDecimalChange(fieldControl: AbstractControl): void {
    if (!fieldControl.value || isNaN(fieldControl.value)) return;
    let newVal: any = this.decimalPipe.transform(Number(fieldControl.value), '1.2-2');
    newVal = this.cleanDecimal(newVal);
    fieldControl.setValue(newVal); 
  }

  cleanDecimal(val): number {
    let workingVal = val;
    workingVal = !val ? 0 : val;
    return workingVal.toString().replace(',', '');
  }

  getDateNormalizedFilterTerm(searchTermValue: any): IRelativeDate {
    const pattern = new RegExp(this.myConstants.userListFilterRelativeDate + '(?<offset>[\\+\\-]\\d+)?$');
    var dateParts: IRelativeDateParameters[] = [];
    if (searchTermValue && (searchTermValue !== '' && !isArray(searchTermValue)) && !(typeof searchTermValue === 'boolean')) {
      let workingTerm = searchTermValue as string;
      if (searchTermValue.includes(this.myConstants.userListFilterRelativeDate)) {
        if (workingTerm.includes(this.myConstants.userListFilterRelativeDate)) {
          let workingTermRange = workingTerm.split(' - ');
          var newString = '';
          workingTermRange.forEach((date: string) => {
            newString += newString ? ' - ' : '';
            const match = date.match(pattern);
            let offset = +match.groups.offset || 0;
            newString += this.datePipe.transform(new Date().addDays(offset),'MM/dd/yyyy')
            dateParts.push({fixedDate: this.datePipe.transform(new Date().addDays(offset),'MM/dd/yyyy'), dateOffset: Math.abs(offset), dateOffsetSign: offset > 0 ? '+' : '-', relativeDate: date});
          })
          return {fixedTerm: newString, relativeTerm: searchTermValue, isRelativeDateTerm: true, dateParts: dateParts};
        }
      }
      // Dealing with dates
      let workingTermRange = workingTerm.split(' - ');
      workingTermRange.forEach((date: string) => {
        dateParts.push({fixedDate: date, dateOffset: 0, dateOffsetSign: '+', relativeDate: ''});
      });
    }
    return { isRelativeDateTerm: false, fixedTerm: '', relativeTerm: '', dateParts: dateParts };
  }
  getFilterTerm(def: IFieldDefinition): string {
    let term = def.filterTerm;

    if (!term) {
      term = def.key;
      switch (def.fieldType) {
        case this.myConstants.fieldTypeEntity:
        case this.myConstants.fieldTypeEditableSelect:
          term = def.isExtraData ? term : def.selectListDefinition.objectType + '_' + def.selectListDefinition.valueProperty;
          break;
        case this.myConstants.fieldTypeSelect:
          term = def.selectListDefinition.listType !== 'enum'
            ? def.selectListDefinition.objectType + '_' + def.selectListDefinition.valueProperty
            : term;
          break;
        default:
          break;
      }
    }
    return term;
  }

  public isSelectListField(def: IFieldDefinition): boolean {
    const fieldType = def.filterType ? def.filterType : def.fieldType;
    return (fieldType === this.myConstants.fieldTypeEntity
      || fieldType === this.myConstants.fieldTypeEditableSelect
      || fieldType === this.myConstants.fieldTypeFilterableSelect
      || (fieldType=== this.myConstants.fieldTypeSelect && def.selectListDefinition.listType !== 'enum'));
  }
}
