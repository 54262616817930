
export interface IInstallerWorkCrewTechnician {
  installerWorkCrewTechnicianId: number;
  technicianDescription: string;
  isActive: boolean;
  installer_installerId: number;
  installerWorkCrew_installerWorkCrewId: number;
  contact_contactId: number;
  isWorkCrewLead: boolean;
  entityLabel: string;

  active: boolean;
  createDate: string;
  updateDate: string;
  rowVersion: string;
  aggregateRootVersion: string;

  removeFromWorkCrewUrl: string;
  contactName: string;
  providerUserId: number;
}

export class InstallerWorkCrewTechnician implements IInstallerWorkCrewTechnician {
  installerWorkCrewTechnicianId: number;
  technicianDescription: string;
  isActive: boolean;
  installer_installerId: number;
  installerWorkCrew_installerWorkCrewId: number;
  contact_contactId: number;
  isWorkCrewLead: boolean;
  entityLabel: string;

  active: boolean;
  createDate: string;
  updateDate: string;
  rowVersion: string;
  aggregateRootVersion: string;

  removeFromWorkCrewUrl: string;
  contactName: string;
  providerUserId: number;

  constructor() {
    this.installerWorkCrewTechnicianId = 0,
    this.isActive = false,
    this.installer_installerId = 0,
    this.installerWorkCrew_installerWorkCrewId = 0,
    this.contact_contactId = 0,
    this.isWorkCrewLead = false,
    this.technicianDescription = '',
    this.active = true,
    this.createDate = '',
    this.updateDate = '',
    this.rowVersion = '',

    //extra data
    this.removeFromWorkCrewUrl = '',
    this.contactName = '',
    this.providerUserId = 0
  }

}
