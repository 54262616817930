<admin-title-bar
      [title]="'Role'"
      [info]="'Role'"
      [disabled]="isWorking || !haveAllRoles"
      [currentAccess]="userRoles"
      [specifierType]="specifierType"
     (btnEvent)="onEvent($event)">
</admin-title-bar>

<div [ngClass]="{ 'loading-mask': isWorking }">
  <div class="user-access-items">
    <ng-container *ngFor="let role of userRoles">
      <user-access-item customClass="user-access-item--roles"
                        [name]="role.providerRoleName"
                        [item]="role"
                        (deleteEvent)="onEvent($event)">
      </user-access-item>
    </ng-container>
  </div>
</div>
