import { ISearchTerm } from './i-search-term';
import { AccessLevel } from '../enums/access-level.enums';
import { IOrderTerm } from './i-order-term';

export interface IListFilter {
    isLookup: boolean;
    getCount: boolean;
    filterFor: string;
    filterContext: string;
    accessLevel: string;  //None= 0, //ReadOnly, //FullAccess,
    getAll: boolean; //false if want paged, default is false
    currentPage: number;
    searchTerm: ISearchTerm[];
    orderTerm: IOrderTerm[];
}

export class ListFilter implements IListFilter {
  isLookup: boolean;
  getCount: boolean;
  filterFor: string;
  filterContext: string;
  accessLevel: string;   
  getAll: boolean;  
  currentPage: number;
  searchTerm: ISearchTerm[];
  orderTerm: IOrderTerm[];

  constructor() {
    this.isLookup = false;
    this.getCount = false;
    this.filterFor = '';
    this.filterContext = '';
    this.accessLevel = AccessLevel.ReadOnly;  
    this.getAll = false;
    this.currentPage = 1;
    this.searchTerm = [];
    this.orderTerm = [];
  }

}
