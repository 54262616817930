export interface IProject {
  projectId: number;   
  branchName: string;
  branch_branchId: number;
  currentProjectStatusCode: string;
  customerName: string;
  customer_contactId: number;
  endDate: string;
  entityLabel: string;
  projectStatus_lookupValueId: number;
  providerMarginHigh: number;
  providerMarginLow: number;
  showCloseTaskButtons: boolean;
  startDate: string;
  vendorOrderNumber: string;
  warranty_warrantyId: number;
  welcomeEmailSent: boolean;

  active: boolean;
  isActive: boolean;
  isPendingDelete: boolean;
  //wantScheduleDailySummary: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  //extra data
  warrantyTypeName: string;
  warrantySubTypeName: string;
  warrantyIsUrgent: boolean;
  baseService: string;
  baseGeneralContractor: string;
  baseBranch: string;
  baseProject: number;
  assignedUserText: string;
  generalContractorId: string;
  generalContractorName: string;
  hasExternalData: boolean;
  hasExternalInterface: boolean;
  wosMissingInstaller: string;
  wosMissingSchedule: string;
  wosMissingCallLog: string;
  customerContact: string;

}

/*
 * fks:
branch: {entityLabel: "1501", branchName: "1501", accountingCustomerMapId: null}
customer: {entityLabel: "Bryce Anderson", isOrganization: false, firstName: "Bryce", lastName: "Anderson", middleName: null, …}
projectStatus: {entityLabel: "Opened", lookupCode: "open", lookupMeaning: "Opened"}
__proto__: Object
generalContractorId: "Home Depot"
generalContractorName: "Home Depot"
hasExternalData: true
hasExternalInterface: true
*/
export class Project implements IProject {
  projectId: number;
  branchName: string;
  branch_branchId: number;
  currentProjectStatusCode: string;
  customerName: string;
  customer_contactId: number;
  endDate: string;   
  entityLabel: string;
  projectStatus_lookupValueId: number;
  providerMarginHigh: number;
  providerMarginLow: number;
  showCloseTaskButtons: boolean;
  startDate: string;
  vendorOrderNumber: string;
  warranty_warrantyId: number;
  welcomeEmailSent: boolean;
  active: boolean;
  isActive: boolean;
  isPendingDelete: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
  //extra data
  warrantyTypeName: string;
  warrantySubTypeName: string;
  warrantyIsUrgent: boolean;
  baseService: string;
  baseGeneralContractor: string;
  baseBranch: string;
  baseProject: number;
  assignedUserText: string;
  generalContractorId: string;
  generalContractorName: string;
  hasExternalData: boolean;
  hasExternalInterface: boolean;
  wosMissingInstaller: string;
  wosMissingSchedule: string;
  wosMissingCallLog: string;
  customerContact: string;


  constructor() {
    this.projectId = 0;
    this.active = true;
    this.isPendingDelete = false;
    this.rowVersion = '';
    this.aggregateRootVersion = '';

  }

}

