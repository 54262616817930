<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <fw-dynamic-detail *ngIf="objectData"
                     [displayFields]="displayFields"
                     [detailConfig]="detailConfig">
    <div dynamic-detail-drop>
      <fw-dynamic-list *ngIf="listDefinition && operation === myConstants.operationTypeDetails"
                       [listTitle]="'Customer Survey Responses'"
                       [vmListDefinition]="listDefinition">
      </fw-dynamic-list>
    </div>
  </fw-dynamic-detail>
</fw-dynamic-detail-container>
