import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'fw-collapsible-section',
  templateUrl: './fw-collapsible-section.component.html'
})
export class CollapsibleSectionComponent implements OnInit {
  @Input() title: string;
  @Output() toggled = new EventEmitter<boolean>();

  public isOpen: boolean = true;

  ngOnInit() {
    this.isOpen = true;
  }

  public toggle(val: boolean): void {
    this.isOpen = !this.isOpen;
    this.toggled.emit(this.isOpen);
  }


}
