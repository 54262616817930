import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IProgramService } from '../../../view-models';
import { AdminStore, AdminEvent } from '../../enums/admin.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'program-services',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './program-services.component.html'
})
export class ProgramServicesComponent implements OnInit {
  /*Used by 3 different parents:  ProgramServices, Progams and Services*/
  public title: string;
  public listDefinition: IListDefinition;
  programId: number;
  serviceId: number;
  params: IDetailRouteParameter;

  constructor(public activeRoute: ActivatedRoute,
    public userPriviledgesService: UserPriviledgesService,
    public adminService: AdminProviderService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.programId = +paramMap.get('programId');
      this.serviceId = +paramMap.get('serviceId');
      this.title = this.programId > 0 ? 'Services' :this.serviceId > 0 ? 'Programs' : 'Program Services';
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.managePSBranchPrograms:
        this.openPSBranchPrograms(event.data);
        break;
      case AdminEvent.managePSFiles:
        this.openPSFiles(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadProgramServicesListDefinition(this.programId, this.serviceId);
    this.params = {
      rowIndex: -1,
      storeName: AdminStore.programServices,
      key: 'programServiceId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: -1
    }
}

  openPSBranchPrograms(p: IProgramService): void {
    this.modalService.open({
      title: 'Branches for Program Service'.concat(' ', p.programServiceName),
      path: 'ps-branch-pgms',
      id: p.programServiceId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }

  openPSFiles(p: IProgramService): void {
    this.modalService.open({
      title: 'Document Types for Program Service'.concat(' ', p.programServiceName),
      path: 'ps-files',
      id: p.programServiceId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }

}
