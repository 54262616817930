import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ITitleListItem } from '../fw-component-title/interfaces/i-title-list-item';

@Component({
  selector: 'fw-user-filter-select',
  templateUrl: './fw-user-filter-select.component.html'
})
export class FwUserFilterSelect implements OnInit {
  @Input() userFilters: ITitleListItem[] = [];

  @Output() public changeMyFilter = new EventEmitter<number>();


  constructor() {
  }

  ngOnInit(): void {
  }

  selectFilter(id: number) {
    this.changeMyFilter.emit(id);
  }
 }
