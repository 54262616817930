import { Component, Input} from '@angular/core';
import { IInstallerCompatibility } from '../../../app/portals/view-models';

@Component({
  selector: 'crew-work-order-compatibility',
  templateUrl: './crew-work-order-compatibility.component.html',
})
export class CrewWorkOrderCompatibilityComponent  {
  @Input() compatibilityList: IInstallerCompatibility[];
  @Input() screenIsSmall: boolean;

  constructor() {}

}
