import { Component, Input, forwardRef, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IInputButton } from '../../interfaces/i-input-button';

const CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckBoxComponent),
  multi: true
};

@Component({
  selector: 'fw-check-box',
  templateUrl: './fw-check-box.component.html',
  providers: [CHECKBOX_VALUE_ACCESSOR]
})
export class CheckBoxComponent implements ControlValueAccessor, OnInit, OnChanges {

  @Input() config: IInputButton;
  @Input() isDisabled: boolean = false;
  @Input() isChecked: boolean = false;
  @Input() autoFocus: boolean = false;

  public get disabled(): boolean {
    return this.isDisabled;
  }

  public value: boolean;

  onModelChange = (checked: boolean) => { };
  onModelTouched: Function = () => { };

  ngOnInit(): void {
    this.value = this.isChecked;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['isChecked'] && !changes['isChecked'].firstChange)) {
      this.value = this.isChecked;
    }
  }

  registerOnChange(fn: (checked: boolean) => void): void {
    this.onModelChange = fn; 
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }
   
  writeValue(value: any): void {
    this.value = value;
  }

  initChange(val: any): void {
    this.value = val;
    this.onModelChange(this.value);
    this.onModelTouched();
  }

  //setDisabledState?(isDisabled: boolean): void {
  //  throw new Error("Method not implemented.");
  //}

}
