<div *ngIf="(project$ | async) as project" class="scheduler">
  <div *ngIf="(workOrder$ | async) as workOrder" class="scheduler-summary">
    <div class="scheduler-summary__header">
      <div class="scheduler-summary__header__main">
        <div class="scheduler-summary__header__main--title">
          Work Order {{workOrderId}}
        </div>
        <div class="scheduler-summary__header__main--info">{{ workOrder.workOrderStatusText}}</div>
      </div>
      <div class="scheduler-summary__header__sub">
        <div class="scheduler-summary__header__sub--title">{{ workOrder.workOrderProgramText === 'Not Found' ? '' : workOrder.workOrderProgramText  }} {{ workOrder.workOrderServiceName }}</div>
      </div>
    </div>

    <div class="scheduler-summary__content">

      <div class="phls__scrollcon__customer">
        <i class="fas fa-user-circle phls__scrollcon__customer__icon"></i>
        <fw-component-title [title]="''"
                            [entityLabel]="project.customerName"
                            [forHighLevelSummary]="true">
        </fw-component-title>
      </div>

      <div class="phls__scrollcon__pcm" *ngIf="(contact$ | async) as contact">
        {{ preferredContactLabel }}:
        <div class="phls__scrollcon__pcm__val">
          <fw-communication-preference [row]="contact"
                                       [forHighLevelSummary]="true"
                                       [displayType]="communicationEventService.displayTypeBoth">
          </fw-communication-preference>
        </div>
      </div>

      <div class="scheduler-summary__content__item">
        <job-site-manager [projectId]="projectId"
                          [contactId]="project.customer_contactId"
                          [canIEdit]="false"
                          [hasStaleData]="false"
                          [projectStatus]="project.currentProjectStatusCode"
                          [reload]="false"
                          [justInfo]="true">
        </job-site-manager>
      </div>

      <div class="scheduler-summary__content__item">
        <fw-section-title title="Work Order Items" [underline]="true" icon="fa-clipboard-list-check"></fw-section-title>
        <project-work-order-item-summary [isSchedule]="true"></project-work-order-item-summary>
      </div>

      <div class="scheduler-summary__content__item">
        <fw-section-title title="Purchase Order Measures" [underline]="true" icon="fa-drafting-compass"></fw-section-title>
        <project-required-documents [projectId]="projectId"
                                    [workOrderId]="workOrderId"
                                    [forScheduler]="true"
                                    [canIEdit]="canIEdit$ | async">
        </project-required-documents>
      </div>

      <div *ngIf="!project.warranty_warrantyId || project.warranty_warrantyId <= 0 " class="scheduler-summary__content__item">
        <fw-section-title title="{{ project.generalContractorName.startsWith('HOM ') ? 'HŌM Solutions Inc.' : project.generalContractorName }} Toolbox"
                          [underline]="true"
                          icon="fa-toolbox">
        </fw-section-title>
        <div class="scheduler-summary__content__item__toolbox">
          <button class="app-btn-icon scheduler-summary__content__item__toolbox__btn"
                  [disabled]="!(canIEdit$ | async) || project.currentProjectStatusCode === myConstants.projectStatusClosed"
                  type="button"
                  (click)="togglePoSync()"
                  [title]="!syncVisible ? 'Synchronize Purchase Orders' : 'Collapse Sync Results'">
            <span [ngClass]="{'fas fa-exchange  app-btn-icon--primary': !syncVisible, 'fad fa-compress-arrows-alt  app-btn-icon--danger': syncVisible}"></span>
          </button>
          <div class="scheduler-summary__content__item__toolbox__label">Synchronize Purchase Orders from {{  project.generalContractorName }}</div>
        </div>
      </div>
      <router-outlet name="sidebar"></router-outlet>
    </div>
  </div>

  <div class="scheduler__spacer"></div>
  <div class="scheduler-detail" *ngIf="(workOrder$ | async) as workOrder">
    <div class="portal__detail--item">
      <schedule-manager [workOrder]="workOrder"
                        [operation]="operation"
                        (customEvent)="onCustom($event)">
      </schedule-manager>
    </div>

    <div class="portal__detail--item">
      <fw-component-title [title]="'Project Notes'"></fw-component-title>
      <notes-manager [listDefinition]="noteListDef"
                     [startAction]=""
                     [canIEdit]="canIEdit$ | async"
                     [portalId]="1"
                     [grandParentKey]="'project_projectId'"
                     [grandParentId]="projectId"
                     [contactId]="project.customer_contactId"
                     [hasExternalInterface]="project.hasExternalInterface">
      </notes-manager>
    </div>

    <div class="portal__detail--item">
      <fw-component-title title='Work Order Categories'></fw-component-title>
      <work-order-work-categories [workOrderId]="workOrderId"></work-order-work-categories>
    </div>
  </div>
  <div class="scheduler-chat">
    <router-outlet name="chatmodal"></router-outlet>
  </div>
</div>
