import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'survey-program-services',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './survey-program-services.component.html'
})
export class SurveyProgramServicesComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  surveyQuestionId: number;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.surveyQuestionId = +paramMap.get('id');
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition$.next(this.adminService.loadSurveyProgramServicesListDefinition(this.surveyQuestionId));
  }


}
