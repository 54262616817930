/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit,  Inject, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, BehaviorSubject  } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IFormDefinition, FormDefinition } from '../../../../../fw/dynamic-forms/index';
import {
  IProjectCallLogResponse, ProjectCallLogResponse, ProjectCallLog,
  INote, Note, ICallLogResponse, IProjectCallLogViewModel,
  IProviderUser, INotification, Notification
} from '../../../view-models/index';
import { ProjectListStore } from '../../../project/enums/project.enums';
import { NoteEvent } from '../../enums/note.enums';
import { ISendToUser } from '../../interfaces';

import { MetaDataService } from '../../../../../fw/dynamic-list/services/index'
import { ProjectService } from '../../../project/services';
import { ProviderUserService } from '../../services';

@Component({
  selector: 'work-order-call-log',
  templateUrl: './work-order-call-log.component.html',
  providers: [{ provide: 'mdsInstance1', useClass: MetaDataService },
  { provide: 'mdsInstance2', useClass: MetaDataService }]

})
export class WorkOrderCallLogComponent implements OnInit, OnDestroy {
  @Input() workOrderId: number;
  @Input() projectId: number;
  @Input() displayFields: string[];
  @Input() providerUsers: IProviderUser[];

  @Output() public createEvent = new EventEmitter<IHomEventEmitter>();
  @Output() public cancelEvent = new EventEmitter();

  public logForm: FormGroup;
  public noteForm: FormGroup;
  public noteFormDef: IFormDefinition;
  public noteFields = ['postIt','noteSubject', 'noteText', 'isPrivate'];
  public operation: string;
  public noteRequired$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public responseTypes$: BehaviorSubject<ICallLogResponse[]> = new BehaviorSubject([]);
  public disableRecipients$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  noteStoreName: string;
  sendToList: ISendToUser[] = [];
  subscription: Subscription = new Subscription();

  constructor(@Inject('mdsInstance1') public  logMds: MetaDataService,
    @Inject('mdsInstance2') public  noteMds: MetaDataService,
    public  projectService: ProjectService,
    public mds: MetaDataService,
    public providerUserService: ProviderUserService,
    @Inject(appConstants) public  myConstants: IAppConstants) {
  }

  public cancel(): void {
    this.cancelEvent.emit();
  }

  public create(): void {
    let projectCallLog = new ProjectCallLog();
    let projectCallLogResponse = new ProjectCallLogResponse();
    let note = new Note(); //set this to null if no note
    let notifyList: INotification[] = [];
    const logData: IProjectCallLogResponse = this.logForm.value;
    const noteData: INote = this.noteForm.value;

    projectCallLog.project_projectId = this.projectId;
    projectCallLog.workOrder_workOrderId = this.workOrderId;
    projectCallLog.callLogType_callLogTypeId = 3; //schedule
    projectCallLogResponse.callLogResponse_callLogResponseId = logData['callLogResponse']['callLogResponseId'];

    if (noteData.noteSubject && noteData.noteText) {
      note.project_projectId = this.projectId;
      note.workOrder_workOrderId = this.workOrderId;
      note.noteSubject = noteData.noteSubject;
      note.noteText = noteData.noteText;
      note.isPrivate = noteData.isPrivate;
      note.postIt = noteData.postIt;
      this.sendToList.forEach((x: ISendToUser) => {
        let item: INotification = new Notification();
        item.providerUser_providerUserId = x.providerUser.providerUserId;
        item.isEmail = x.sendEmail ? x.sendEmail : false;
        notifyList.push(item);
      });
    } else {
      note = null;
    }
    let vm = {
      projectCallLog: projectCallLog,
      projectCallLogResponse: projectCallLogResponse,
      note: note,
      noteNotifyList: notifyList
    }
    let event: IHomEventEmitter = { requestor: 'project-call-log-create', event: this.myConstants.emitterEventCreate, action: '', data: vm };
    this.createEvent.emit(event);
  }

  public initForm(): void {
    let projectCallLogResponse = new ProjectCallLogResponse();
    let note = new Note();
    this.logMds.setFieldDefinitions(ProjectListStore.workOrderCallLogResponses);
    this.noteMds.setFieldDefinitions(ProjectListStore.projectNotes);
    this.logMds.loadSelectionLists(this.displayFields, this.operation, this.projectId);
    const types = this.logMds.getSelectItems('callLogResponse');
    this.responseTypes$.next(types ? types : []);
    this.logForm = this.mds.loadDynamicFormGroup(this.displayFields, projectCallLogResponse, this.myConstants.operationTypeCreate);
    this.noteForm = this.noteMds.loadDynamicFormGroup(this.noteFields, note, this.myConstants.operationTypeCreate);
  }

  public onRecipientEvent(event: IHomEventEmitter): void {
    switch (event.event) {
      case NoteEvent.addRecipient:
        this.addRecipient(event.data);
        break;
      case NoteEvent.removeRecipient:
        this.removeRecipient(event.data);
        break;
      case NoteEvent.changeRecipientNotification:
        this.changeRecipientMailTo(event.data);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.operation = this.myConstants.operationTypeCreate;
    this.noteFormDef = new FormDefinition('Note Detail', false, false, ProjectListStore.projectNotes); // 'userNote, installerNote, etc'
    this.noteStoreName = ProjectListStore.projectNotes;
    this.initForm();

    this.subscription.add(this.logForm.controls['callLogResponse'].valueChanges.subscribe(val => {
      if (val) {
        this.responseTypeSelected(val);
      }
    }));

    this.subscription.add(this.noteForm.controls['noteText'].valueChanges.subscribe(val => {
      this.disableRecipients$.next( val ? false : true );
    }));
  }

  responseTypeSelected(type: ICallLogResponse): void {
    this.noteRequired$.next(type.noteRequired);
  }

  addRecipient(providerUserId: number) {
    let providerUser = this.providerUsers.find(x => x.providerUserId === providerUserId);
    if (providerUser) {
      this.sendToList.push({ providerUser: providerUser, sendEmail: false });
    }
  }

  //LISTENER:  Event emitted by multi-select
  removeRecipient(providerUserId: number) {
    const index = this.sendToList.findIndex(x => x.providerUser.providerUserId === providerUserId);
    if (index > -1) {
      this.sendToList.splice(index, 1);
    }
  }

  // Add/Remove user from the list of users to send emails to
  changeRecipientMailTo(providerUserId: number) {
    let sendToUser = this.sendToList.find(x => x.providerUser.providerUserId === providerUserId);
    if (sendToUser) {
      sendToUser.sendEmail = !sendToUser.sendEmail;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
