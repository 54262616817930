
export interface IHomDictionary
{
  key: string;
  value: any;
}

export class HomDictionary implements IHomDictionary {
  key: string;
  value: any;

  constructor(key: string, value: any) {
    this.key = key;
    this.value = value;
  }

  public pushTo(pair: IHomDictionary) {
  
}
}
