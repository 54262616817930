import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, IListButtonType } from '../../../../../fw/dynamic-list/interfaces/index';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';

@Component({
  selector: 'installer-work-crew-schedule-exclusions',
  templateUrl: './installer-work-crew-schedule-exclusions.component.html',
})
export class InstallerWorkCrewScheduleExclusionsComponent implements OnInit, OnChanges {
  @Input() installerId: number = -1;
  @Input() workCrewId: number = -1;
  @Input() canIEdit: boolean = false;

  listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public dynamicListService: DynamicListService) { }

  ngOnInit(): void {
    this.loadListDefinition();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['installerId'] && !(changes['installerId'].firstChange)) {
      this.loadListDefinition();
    }
    if (changes['workCrewId'] && !(changes['workCrewId'].firstChange)) {
      this.loadListDefinition();
    }
  }

  /*
      Define the list to be created:  ALL Technicians with Expiry for Dashboard
  */
  loadListDefinition(): void {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Schedule Exclusions';
    const listObjectController = 'InstallerWorkCrewScheduleExclusionViewModel';
    const listStoreName = this.workCrewId > 0 ? 'workCrewScheduleExclusions' : 'installerScheduleExclusions';
    const detailRoutePath = 'installer-work-crew-schedule-exclusion'; //only applicable from work crew 
    const listRowKeyId = 'installerWorkCrewScheduleExclusionId';
    const detailRouterOutlet = 'workCrewScheduleExclusionOutlet';
    const defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition(detailRouterOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = this.workCrewId > 0 ? this.workCrewId : this.installerId; //distinct stores
    listDefinition.parentKey = this.workCrewId > 0 ? 'installerWorkCrewId' : 'installerId';
    listDefinition.controllerMethod = this.workCrewId > 0 ? 'ByInstallerWorkCrew' : 'ByInstaller';
    listDefinition.methodParameters = this.workCrewId > 0 ? this.workCrewId.toString() : this.installerId.toString();

    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;

    if (this.workCrewId > 0) {
      const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);

      let crudButtons: IListButtonType[] = [
        { type: ButtonType.delete, defaults: null }
      ];
      listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    }

    this.listDefinition$.next(listDefinition);

  }

  /*
      Define the columns in the list to be created.
  */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef;
    if (this.workCrewId <= 0) {
      colDef = new ListColumn('installerWorkCrewName');
      columnDefinitions.push(colDef);
    }
    colDef = new ListColumn('jobScheduleSummary');
    columnDefinitions.push(colDef);

    
    return columnDefinitions;
  }

}
