import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { InstallerWorkCrewWorkCategory } from '../../../view-models/index';
import { UpdateObjectCustomModel, CreateObjectModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';


@Component({
  selector: 'installer-work-crew-work-category',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './installer-work-crew-work-category.component.html'
})
export class InstallerWorkCrewWorkCategoryComponent implements OnInit, OnDestroy  {
  public objectData: InstallerWorkCrewWorkCategory = null;
  public displayFields = ['workCategory', 'dailyCrewCapacity', 'capacityComments'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  listStore: fromDynamicList.IListObjectData;
  installerId: number = -1;
  workCrewId: number = -1;
  myStoreName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string = '';
  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public  activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.subscription.add(this.emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case this.myConstants.emitterEventCreate:
            this.createRecord(e);
            break;
          default:
            break;
        }
      }));

    //handle a change in either portalEntityId (installerId) or objectId (workcrewId)
    this.subscription.add(this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.workCrewId = +paramMap.get('id');
      if (this.workCrewId > 0) {
        this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
          this.installerId = +paramMap.get('portalEntityId');
            this.key = paramMap.get('key');
          this.objectId = +paramMap.get('id');
          this.operation = paramMap.get('operation');
          this.myStoreName = paramMap.get('storeName');
          this.requestTime = paramMap.get('requestTime');
          this.setDetailConfig(paramMap);
          this.objectData = null;
          this.getDetail();
        }));
      }
    }));
   
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.workCrewId;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    this.detailConfig$.next(params);
  }

  //parent id is workcrewid for this store
  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromStore.getSelectedRecord(this.myStoreName, this.workCrewId, this.key, this.objectId)))
      .subscribe(entity => {
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate ? new InstallerWorkCrewWorkCategory() : entity;
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromStore.getSelectedParentListDefinition(this.myStoreName, this.workCrewId)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.controllerName = listDefinition.controllerName;
      }
      ));
  }

    /*
        Insert the new record - create not allowed at this level
    */
  createRecord(e: IHomEventEmitter) {

    let iwcwc: InstallerWorkCrewWorkCategory = this.setCommonProperties(e.data);

    iwcwc.createDate = new Date().toDateString();

    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.myStoreName, this.workCrewId, this.controllerName, 'Create', iwcwc, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }
    /*
        Update the record:  since the data contains both existing and missing certifications, update needs to determine if create or update request
          based on value in installerCertificationId
    */
  updateRecord(e: IHomEventEmitter) {
      let iwcwc: InstallerWorkCrewWorkCategory = this.setCommonProperties(e.data);
      const keyData: IKey = { storeName: this.myStoreName, parentId: this.workCrewId, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
      const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', iwcwc, null, emitter);
      this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));
    }

    /*
        Set common create/update properties
    */
  setCommonProperties(formData: InstallerWorkCrewWorkCategory): InstallerWorkCrewWorkCategory{
    const existingObj = cloneDeep(this.objectData);

    let iwcwc: InstallerWorkCrewWorkCategory = existingObj;

    iwcwc.installerWorkCrew_installerWorkCrewId = this.workCrewId;
    if (formData.hasOwnProperty('workCategory')) {
      iwcwc.workCategory_workCategoryId = formData['workCategory']['workCategoryId'];
    }
    iwcwc.dailyCrewCapacity = formData.dailyCrewCapacity;
    iwcwc.capacityComments = formData.capacityComments;

    return iwcwc;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
