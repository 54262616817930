/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute,  } from '@angular/router';
import { Subscription } from 'rxjs';

import { IListDefinition, IListFilter, ListFilter, IListButtonType, OrderTerm } from '../../../../fw/dynamic-list/interfaces/index';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { DynamicListService } from '../../../../fw/dynamic-list/services';

@Component({
  selector: 'contact-notes',
  templateUrl: './contact-notes.component.html'
})
export class ContactNotesComponent implements OnInit, OnDestroy {
  public  contactId: number;
  public action: string = '';
  public listDefinition: IListDefinition;
  public title: string; 
  public projectKey:string = '';
  public projectId: number = 0;
  public portalId: number = 0;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public dynamicListService: DynamicListService  ) {}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = window.history.state && window.history.state.hasOwnProperty('projectId') ? +window.history.state['projectId'] : 0;
      this.projectKey = this.projectId > 0 ? 'project_projectId' : '';
      this.portalId = this.projectId > 0 ? 1 : 0;
      this.contactId = +paramMap.get('id');
      this.action = paramMap.has('action') ? paramMap.get('action') : '';
      this.loadListDefinition();
    });
  }

  loadListDefinition() {
    const listObjectLabel = 'Customer Notes ';
    const listObjectController = 'Note';
    const listStoreName = 'contactNotes';
    const detailRoutePath = '';
    const listRowKeyId = 'noteId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('createDate', false)];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      null,
      detailRoutePath);

    listDefinition.parentId = this.contactId;
    listDefinition.parentKey = 'contact_contactId';
    listDefinition.controllerMethod = this.projectId > 0 ? 'ByProjectContact' : 'ByContact';
    listDefinition.methodParameters = this.projectId > 0 ? this.projectId.toString() : this.contactId.toString();

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    this.listDefinition = listDefinition;  
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
