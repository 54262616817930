import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject,  ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IInstallerWorkCrewTechnicianCreateViewModel, IContactInfoViewModel} from '../../../view-models/index';
import { IErrorData } from '../../../../../shared/interfaces/index';
import { ContactEvent } from '../../../../contact/enums/contact.enums';

import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromRoot from '../../../../../app/store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as LoadingIndicatorActions  from '../../../../../shared/store/loadingIndicator/loadingIndicator.actions';
import { CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { InstallerWorkCrewTechnicianCreateComponent  } from '../../components/installer-work-crew-technician-create/installer-work-crew-technician-create.component';
import { ContactConstantsService } from '../../../../contact/services';
import { ContactManagerComponent } from '../../../../contact/containers';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'installer-work-crew-technician-create-container',
  templateUrl: './installer-work-crew-technician-create-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstallerWorkCrewTechnicianCreateContainerComponent implements OnInit, OnDestroy {
  @ViewChild('contactManager') public contactManager: ContactManagerComponent;
  @ViewChild('technicianCreate') public technicianCreate: InstallerWorkCrewTechnicianCreateComponent;

  public operation: string = ''; //will always come in as create
  public requestTime: string = '';
  public ready: boolean = false;
  public  installerId: number;
  public workCrewId: number;
  public loading$: Observable<boolean>;
  public working$: BehaviorSubject<boolean>;
  public errorData$: BehaviorSubject<IErrorData[]>;

  storeName: string = '';
  key: string = '';
  subscription: Subscription = new Subscription();

  constructor(public readonly rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public contactConstantsService: ContactConstantsService,
    public userPriviledgesService: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {
        this.errorData$ = new BehaviorSubject([]);
        this.working$ = new BehaviorSubject(false);
    }

  public onManagerEvent(event: IHomEventEmitter) {
    switch (event.event) {
      case ContactEvent.cancel:
        this.onCancel();
        break;
      case ContactEvent.createWithContact:
        this.createWithContact();
        break;
      default:
        break;
    }
  }

  public onCancel(): void {
    let emitter: IHomEventEmitter = {
      requestor: 'installer-work-crew-technician',
      event: this.myConstants.emitterEventClose,
      action: '',
      data: null
    };
    this.emitterService.emitListEvent(emitter);
  }

  public onCreate(): void {
    this.createWithContact();
  }

  createWithContact() {
    const contactModel: IContactInfoViewModel = this.contactManager.getContactModel();

    let model: IInstallerWorkCrewTechnicianCreateViewModel = {
      installerWorkCrewTechnician: this.technicianCreate.getTechnicianModel(),
      contact: contactModel.contact,
      contactMechanismAddresses: contactModel.contactMechanismAddresses,
      contactMechanismEmails: contactModel.contactMechanismEmails,
      contactMechanismPhones: contactModel.contactMechanismPhones
    }

    const emitter: IHomEventEmitter = { requestor: 'installer-work-crew-technician-create', event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, this.workCrewId, 'InstallerWorkCrewTechnician', 'CreateWithContact', model, null, emitter);

    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  //only create for this container
  ngOnInit() {
    this.subscription.add(this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.workCrewId = +paramMap.get('id');
    }));

    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.ready = false;
      this.key = paramMap.get('key');
      this.installerId = +paramMap.get('portalEntityId');

      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime'); //for new, new to ensure will init on second new - may need as part of push

      this.rootStore.dispatch(new LoadingIndicatorActions.HideSpinner({ requestor: this.storeName, id: this.workCrewId }));

      //subscribe to watch for errors and events 
      this.subscription.add(this.store.pipe(select(fromStore.getListByType(this.storeName)))
        .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId === this.workCrewId)))
        .subscribe((state: IListObjectData) => {
          if (state) {
            if (state.event && (!state.errorData || !state.errorData.length)) {
              const event: IHomEventEmitter = cloneDeep(state.event);
              this.emitterService.emitListEvent(event);
              this.store.dispatch(new fromStore.ClearEventList({ storeName: this.storeName, parentId: this.workCrewId }));
            }
            if (state.working) {
              this.working$.next(state.working);
            }
            if (state.errorData) {
              this.errorData$.next(state.errorData);
            }
          }
        }));

      this.ready = true;

    }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
