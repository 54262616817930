<form *ngIf="metaLoaded && formName()" [formGroup]="formName()" novalidate class="contact-phone__form">
  <fw-dynamic-field class="contact-phone__number"
                    [field]="metaDataService.getFieldDefinition(definitions, 'phoneNumber')"
                    [form]="formName()"
                    [operation]="operation"
                    [autoFocus]="focusOn$ | async">
  </fw-dynamic-field>
  <fw-dynamic-field *ngIf="operation !== myConstants.operationTypeDetails" class="contact-phone__ext"
                    [field]="metaDataService.getFieldDefinition(definitions, 'phoneExtension')"
                    [form]="formName()"
                    [operation]="operation">
  </fw-dynamic-field>
  <div *ngIf="operation === myConstants.operationTypeDetails" class="float-label contact-phone__ext contact-phone__ext--ro">
    <div class="with-float-label"> {{ phone.phoneExtension }}</div>
    <label [title]="metaDataService.getPropertyValue(definitions, 'phoneExtension', 'placeholder')">
      {{ metaDataService.getPropertyValue(definitions, 'phoneExtension', 'label') }}
    </label>
  </div>
  <div class="contact-phone__options">
    <button type="button"
            class="app-btn-icon app-btn-icon--danger contact-phone__options--action"
            (click)="deleteRecord()"
            [disabled]="phone.uiRequired || !contactService.canIDelete(canIEdit, contactConstantsService.contactMechanismCategoryPhone, phone) || operation === myConstants.operationTypeDetails"
            title="Delete">
      <span class="fas fa-trash"></span>
    </button>
    <fw-dynamic-field class="contact-phone__options--sms"
                      [field]="metaDataService.getFieldDefinition(definitions, 'canSms')"
                      [form]="formName()"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field class="contact-phone__options--preferred"
                      [field]="metaDataService.getFieldDefinition(definitions, 'isPreferred')"
                      [form]="formName()"
                      [operation]="operation">
    </fw-dynamic-field>
  </div>
</form>
