<fw-component-title title='Sort Settings'></fw-component-title>
<div class="form-wrapper form-wrapper--edit dl-sort-box">
    <fieldset>
      <legend>Multi-sort Items</legend>
      <ng-container *ngFor="let item of (currentMsSettings$ | async); let itemIndex = index;">
        <dynamic-list-sort-setting [items]="multiSortOptions"
                                   [index]="itemIndex"
                                   [selectedItem]="item"
                                   (changeEvent)="onChange($event)"
                                   (deleteEvent)="onDelete($event)"></dynamic-list-sort-setting>
      </ng-container>
      <div *ngIf="(currentMsSettings$ | async).length < 3" class="app-btn-box app-btn-box--left">
        <button type="button"
                class="app-btn-icon app-btn-icon--success"
                (click)="addItem()"
                title="Add Level">
          <span class="fas fa-plus"></span>
        </button>
      </div>
    </fieldset>
    <ng-container *ngIf="singleSortBtns && singleSortBtns.length > 0">
      <div class="app-info">
        <i class="fas fa-info-circle app-icon--primary"></i>
        You can either apply multi-sort selections or a single-sort selection.
      </div>
      <fieldset>
        <legend>Single-sort Items</legend>
        <div class="dl-sort-single">
          <fw-radio-button [groupName]="'sortOption'"
                           [buttons]="singleSortBtns"
                           [isDisabled]="false"
                           [isHorizontal]="false"
                           (change)="selectSSOption($event.srcElement.value)">
          </fw-radio-button>
          <fw-check-box #isAsc
                        [config]="ascConfig"
                        [isDisabled]="false"
                        [isChecked]="activeAsc"
                        (change)="changeSSAsc(isAsc.value)">
          </fw-check-box>
        </div>
      </fieldset>
    </ng-container>

  <div class="app-btn-box">
    <button type="button"
            class="app-btn app-btn--cancel"
            title="Cancel"
            (click)="cancel()">
      Cancel
    </button>
    <button type="button"
            class="app-btn app-btn--primary"
            title="Apply"
            [disabled]="!(isValid$ | async)"
            (click)="apply()">
      Apply
    </button>
  </div>
  <fw-error-box [errors]="errorMessage" [ignoreKey]="true"></fw-error-box>
</div>
