import { Component, Input,  Inject, EventEmitter, Output, OnInit, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep, includes, orderBy } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IListDefinition, IListScreenSize, IDynamicListHeaderCol, ISearchTerm, IOrderTerm, OrderTerm, IWorkingColTerms, IListColumn, IDynamicListHeaderColSelection} from '../../interfaces/index';
import { IFieldDefinition } from '../../../dynamic-forms/index';
import { DynamicListEvent, DynamicListAction } from '../../enums/dynamic-list.enum';
import { IInputButton } from '../../../fw-shared/interfaces/i-input-button';
import { CheckBoxComponent } from '../../../fw-shared/components';

//services
import { DynamicListService } from '../../services/dynamic-list.service';

@Component({
  selector: 'fw-dynamic-list-header-row',
  templateUrl: './dynamic-list-header-row.component.html'
})
export class DynamicListHeaderRowComponent implements OnInit, OnChanges {
  @Input() listDefinition: IListDefinition;
  @Input() fieldDefinitions: IFieldDefinition[];
  @Input() screenSize: IListScreenSize;
  @Input() inFilterMode: boolean;
  @Input() searchTerms: ISearchTerm[];
  @Input() listDisabled: boolean;
  @Input() orderTerms: IOrderTerm[];
  @Input() hasData: boolean;

  @ViewChild('selectAllItems') public selectAllItems: CheckBoxComponent;

  public colData$: BehaviorSubject<IDynamicListHeaderCol[]> = new BehaviorSubject(null);
  public showSortSettings$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectAllConfig: IInputButton;
  public showRowTools: boolean = true;
  public canBulkSort: boolean = false;

  constructor(
    public  dynamicListService: DynamicListService,
    public  emitterService: HomEventEmitterService,
    @Inject(appConstants) public  myConstants: IAppConstants) {
  }

  public showFilter(): void {
    let event: IHomEventEmitter = { requestor: 'dynamic-list-header', event: DynamicListEvent.showFilter, action: '', data: null };
    this.emitterService.emitListEvent(event);
  }
  
  /* If the List Definition supports group select, allow select all (from the items listed).  */
  public selectAll(event: any) {
    if (!this.listDefinition.groupSelect) {
      return;
    }
    let allBox: CheckBoxComponent = this.selectAllItems;

    let selectEvent: IHomEventEmitter = { requestor: 'dynamic-list-row', event: DynamicListEvent.selectAll, action: '', data: allBox.value };
    this.emitterService.emitListEvent(selectEvent);
  }

  public onSortApply(event: IHomEventEmitter): void {
    this.showSortSettings$.next(false);
    this.onColSort(event);
  }

  public onSortCancel(): void {
   this.showSortSettings$.next(false);
  }

  //If incoming is a single sort, the action handles that: override or change action.
  //If incoming action is add and a multi sort, remove any single sort, if found.
  public onColSort(event: IHomEventEmitter): void {
    let newTerms: IOrderTerm[] = cloneDeep(this.orderTerms) || [];
    let selData: IDynamicListHeaderColSelection = event.data;
    const colDefs: IDynamicListHeaderCol[] = this.colData$.value;
    const singleSortTerms = colDefs.filter(x => x.singleSort);

    switch (event.action) {
      case DynamicListAction.addItem:
        if (!selData.singleSort && singleSortTerms && singleSortTerms.length) {
          //remove the single sort term (will only be one col, but one col can have multiple terms )
          singleSortTerms.forEach(col => {
            col.colTerms.forEach(term => {
              newTerms = newTerms.filter(x => x.term !== term.term);
            });
          });
        }
        let maxOrderTerm: number = Math.max(...newTerms.map(o => o.sortOrder), 0);
        selData.colTerms.forEach((term: IOrderTerm) => {
          maxOrderTerm = maxOrderTerm + 1;
          term.sortOrder = maxOrderTerm;
          newTerms.push(term);
        });
        break;
      case DynamicListAction.changeItem:
        selData.colTerms.forEach((term: IOrderTerm) => {
          var idx = this.orderTerms.findIndex(x => x.term === term.term);
          newTerms[idx] = term;
        });

        break;
      case DynamicListAction.removeItem:
        const removeTerms: string[] = selData.colTerms.map(x => x.term);
        newTerms = newTerms.filter(x => includes(removeTerms, x.term) === false);
        break;
      case DynamicListAction.overrideAll:
        newTerms = selData.colTerms;
        break;
      default:
        break;
    }

    //reorder
    newTerms.forEach((item, index) => {
      item.sortOrder = index + 1;
    });

    const eventOut: IHomEventEmitter = {
      requestor: 'dynamic-list-header-row',
      event: DynamicListEvent.sortList,
      action: '',
      data: newTerms
    };

    this.emitterService.emitListEvent(eventOut);
  }

  public toggleSortSettings(): void {
    this.showSortSettings$.next(!this.showSortSettings$.value);
  }

  public sortApply(terms: IOrderTerm[]): void {
    this.showSortSettings$.next(false);
  }

  public sortCancel(): void {
    this.showSortSettings$.next(false);
  }

  ngOnInit(): void {
    this.showRowTools = this.dynamicListService.showRowTools(this.listDefinition);

    this.selectAllConfig = {
      label: {  label: '', alignRight: true, title: 'Click to Select All' }
    };

    this.setColData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['listDefinition'] && !changes['listDefinition'].firstChange) ||
      (changes['searchTerms'] && !changes['searchTerms'].firstChange) ||
      (changes['orderTerms'] && !changes['orderTerms'].firstChange) ||
      (changes['screenSize'] && !changes['screenSize'].firstChange)) {
      this.setColData();
    }
  }

   /*handle:
     col1(sort on 1 term - sort order of 1), col2(sort on 2 terms - sort order of 2 and 3), col3(sort on 1 term - sort order of 4)
     that is correct for backend needs, for front end should result in:
     col1(sort order of 1) col2(sort order of 2) col3(sort order of 3)
   */
  setColData(): void {
    let colData: IDynamicListHeaderCol[] = [];
    let workingColTerms: IWorkingColTerms[] = [];
    const workingTerms: IOrderTerm[] = orderBy(cloneDeep(this.orderTerms), ['sortOrder'], ['asc']) || [];

    //for each list col, pull out the ones that have an active filter
    this.listDefinition.listColumns.forEach((col: IListColumn) => {
      const colSortKeys: string[] = this.dynamicListService.getSortKey(this.fieldDefinitions, col.key);
      const colActiveTerms: IOrderTerm[] = workingTerms && colSortKeys && colSortKeys.length > 0
        ? orderBy(workingTerms.filter(x => includes(colSortKeys, x.term)), ['sortOrder'], ['asc']) : [];
      if (colActiveTerms && colActiveTerms.length > 0) {
        workingColTerms.push({ key: col.key, colTerms: colActiveTerms, sortOrder: colActiveTerms[0].sortOrder });
      }
    });

    //set visible sort order, may vary from what needs to return to backend.
    //orderTerms arrive in sorted asc order, however listdefinion columns are in display order, so resort
    workingColTerms = orderBy(workingColTerms, ['sortOrder'], ['asc']);
    workingColTerms.forEach((col, index) => {
      //to handle 2 terms on one column: workingColTerms sortOrder could be 1,2,4 at this point
      //since sortorder 3 is associated to the second term on the "2" column.
      col.sortOrder = index + 1;
    });

    this.listDefinition.listColumns.forEach((col) => {
      //one column can now have multiple sort terms like lastName and firstName for ProviderUser col.
      let colSortKeys: string[] = this.dynamicListService.getSortKey(this.fieldDefinitions, col.key);

      //See if column is currently sorted on
      const colActiveTerms: IOrderTerm[] = workingTerms && colSortKeys && colSortKeys.length > 0
        ? workingTerms.filter(x => includes(colSortKeys, x.term)) : [];

      //Set col order term info merged with its active asc/sort order values, if applicable
      let colTerms: IOrderTerm[] = [];
      if (colSortKeys && colSortKeys.length) {
        colSortKeys.forEach(key => {
          const activeTerm: IOrderTerm = colActiveTerms.find(x => x.term === key);
          colTerms.push(new OrderTerm(key, activeTerm ? activeTerm.orderAsc : true, activeTerm ? activeTerm.sortOrder : 0));
        });
      }
      const matchFound = workingColTerms && workingColTerms.length > 0 ? workingColTerms.find(x => x.key === col.key) : undefined;
      
      colData.push({
        key: col.key,
        fieldDefinition: this.dynamicListService.getFieldDefinition(this.fieldDefinitions, col.key),
        listColumn: col,
        sortable: this.dynamicListService.colSortable(this.fieldDefinitions, col.key) && !this.listDefinition.revokeSort,
        isFiltered: this.dynamicListService.isFiltered(this.searchTerms, col.key),
        colTerms: colTerms,
        visible: this.dynamicListService.isVisible(this.screenSize, col),
        isSorted: colActiveTerms && colActiveTerms.length > 0,
        sortOrder: matchFound ? matchFound.sortOrder : 0,
        singleSort: this.dynamicListService.isSingleSort(this.fieldDefinitions, col.key)
      });
    });

    this.canBulkSort = colData.find(x => x.sortable) ? true : false;
    this.colData$.next(colData);
  }
}

