
<div *ngIf="contactId" class="sms-popover-box">
  <div class="sms-popover">
    <fw-component-title [title]="title"></fw-component-title>
    <div class="sms-popover__items  sms-popover__items--sellist">
      <ng-container *ngIf="!requiresWO && !requiresPO">
        <contact-projects [contactId]="contactId"
                          [contactTypeId]="contactTypeId"
                          [projectId]="projectId"
                          (select)="onSelect($event)">
        </contact-projects>
      </ng-container>
      <ng-container *ngIf="requiresWO">
        <contact-project-work-orders
                          [contactId]="contactId"
                          [contactTypeId]="contactTypeId"
                          [projectId]="projectId"
                          (selectEvent)="onSelect($event)">
        </contact-project-work-orders>
      </ng-container>
      <ng-container *ngIf="requiresPO">
        <contact-project-purchase-orders
                          [contactId]="contactId"
                          [contactTypeId]="contactTypeId"
                          [projectId]="projectId"
                          (selectEvent)="onSelect($event)">
        </contact-project-purchase-orders>
      </ng-container>
    </div>
  </div>
</div>
