export interface IProviderLocationScheduleMoveUp {
  providerLocationScheduleMoveUpId: number;
  providerLocation_providerLocationId: number;
  schedule_scheduleId: number;
  requestedDate: string;
  immediateMoveUp: boolean;
  moveUpText: string;
  createDate: string;
  updateDate: string;

  //extra data
  customerName: string;
  currentSchedule: string;
  locationService: string;
  projectId: number;
  program: string;
  workCategories: string;
  workOrderId: number;
}
export class ProviderLocationScheduleMoveUp implements IProviderLocationScheduleMoveUp {
  providerLocationScheduleMoveUpId: number;
  providerLocation_providerLocationId: number;
  schedule_scheduleId: number;
  requestedDate: string;
  immediateMoveUp: boolean;
  moveUpText: string;
  createDate: string;
  updateDate: string;

  //extra data
  customerName: string;
  currentSchedule: string;
  locationService: string;
  projectId: number;
  program: string;
  workCategories: string;
  workOrderId: number;

  constructor(providerLocationId: number, scheduleId: number) {
    this.providerLocation_providerLocationId = providerLocationId;
    this.schedule_scheduleId = scheduleId;
    this.immediateMoveUp = false;
    this.moveUpText = '';
    this.requestedDate = '';
    this.createDate = '';
    this.updateDate = '';
  }

}
