/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit,  OnDestroy, ChangeDetectionStrategy, Inject, Input  } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IChargeBack,  IEnum } from '../../../view-models/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { IListDefinition, IListFilter } from '../../../../../fw/dynamic-list/interfaces/index';
import { IErrorData } from '../../../../../shared/interfaces/index';
import { IKey, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces/index';

import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IRadioButton } from '../../../../../fw/fw-shared/components/fw-radio-button/interfaces/i-radio-button';
import { ChargeBackInstallmentTypeLabel, ChargeBackStore } from '../../enums/charge-back-dashboard.enums';

import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services';
import { ChargeBackDashboardService } from '../../services/charge-back-dashboard.service';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../../app/store/reducers/index';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';

export interface IPaymentEstimate {
  nbr: number;
  amount: number;
  date: string;
}

@Component({
  selector: 'charge-back-installment-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './charge-back-installment-manager.component.html',
  providers: [MetaDataService]
})
export class ChargeBackInstallmentManagerComponent implements OnInit,  OnDestroy {
  public displayFields: string[] = ['customerName', 'installerName', 'chargeBackStatus', 'chargeBackNotes', 'acknowledged', 'submitted', 'amountTotal', 'numInstallments', 'installmentFrequency', 'installmentAmount', 'remainingBalance', 'lastInstallmentDue'];
  public optionsTitle: string = 'Charge Back Payment Options';
  public form: FormGroup;
  public chargeBackStatuses: IEnum[];
  public isFullAmt: boolean;
  public isSubmitted: boolean = false;
  public operation: string;
  public defaultOption: number = 0;
  public payOptions: IRadioButton[] = [];

  public chargeBack$: BehaviorSubject<IChargeBack> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  public isValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public installAmtDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public installNumDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public installFreqDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public installEstimates$: BehaviorSubject<IPaymentEstimate[]> = new BehaviorSubject(null);
  public hitMaxLimit$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$: Observable<boolean>;

  chargeBackId: number;
  projectId: number;
  storeName: string;
  key: string;
  operationIn: string;
  listStore: fromDynamicList.IListObjectData;
  requestTime: string = '';
  fullAmountOptionLabel: string;
  partialAmountOptionLabel: string;
  closeLabel: string = 'Cancel';
  optionFullAmount = 'fullAmount';
  optionInstallments = 'installments';
  controllerName: string = '';
  subscription: Subscription = new Subscription();
  listDef: IListDefinition;

  constructor(public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public dynamicDetailService: DynamicDetailService,
    public chargeBacksService: ChargeBackDashboardService,
    public mds: MetaDataService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) { }

  public selectOption(type: string): void {
    this.isFullAmt = (type === this.optionFullAmount);
    this.initInstallmentFields(type === this.optionFullAmount);
    this.isValid$.next(type === this.optionFullAmount || (type === this.optionInstallments && this.checkForValid()));
  }

  public close(): void {
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  public submit(): void {
    const formData = this.form.getRawValue();
    let updatedRecord: IChargeBack = cloneDeep(this.chargeBack$.getValue());

    updatedRecord.numInstallments = formData.numInstallments;
    updatedRecord.installmentFrequency = this.isFullAmt ? 1 : formData.installmentFrequency;
    updatedRecord.installmentAmount = this.isFullAmt ? updatedRecord.amountTotal : formData.installmentAmount;

    const keyData: IKey = { storeName: this.storeName, parentId: -1, key: this.key, id: this.chargeBackId }
    const emitter: IHomEventEmitter = { requestor: 'charge-back-installment-manager', event: this.myConstants.emitterEventUpdate, action: this.myConstants.emitterEventClose, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Submit', updatedRecord, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.chargeBackId = +paramMap.get('id');
      this.projectId = +paramMap.get('portalEntityId');
      this.storeName = paramMap.get('storeName');
      this.key = paramMap.get('key');
      this.operationIn = paramMap.get('operation');
      this.getListInfo();
      this.setDetailConfig(paramMap);
      this.setSubscribes();
      this.getDetail();
      this.initForm();
    });
  }

  getListInfo(): void {
    this.listDef = this.chargeBacksService.loadDashChargeBackSummaryListDefinition(this.storeName);
    this.controllerName = this.listDef.controllerName;
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.useRouterOutlet = false;
    params.showCancel = true;
    params.showNav = false;
    params.showTitle = true;
    params.wrapsForm = true;
    this.detailConfig$.next(params);
  }

  setSubscribes(): void {
    this.loading$ = this.rootStore.select('loadingIndicator').pipe(
      filter(x => x.requestor === this.listDef.storeName && x.id === this.listDef.parentId),
      map(x => x.show));

    this.subscription.add(this.mds.mdsReady$.pipe(filter(flag => flag === true), take(1))
      .subscribe((ready: boolean) => {
        this.loadForm();
      }));

    this.subscription.add(this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null))
      .subscribe((obj: IValueChanged) => {
        this.handleValueChanged(obj);
      }));

    this.subscription.add(this.store.pipe(select(getListByType(this.listDef.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId === -1)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          if (state.event && !state.errorData.length) {
            this.mds.setErrorMessages([]);
            if (this.modalService.opened) {
              this.store.dispatch(new fromStore.ClearEventList({ storeName: this.listDef.storeName, parentId: this.listDef.parentId }));
              this.store.dispatch(new fromStore.GetList({ listDefinition: this.listDef, listFilter: this.listDef.defaultListFilter, parentId: this.listDef.parentId }));
              const emitter: IHomEventEmitter = { requestor: 'charge-back-installment-manager', event: this.myConstants.emitterEventListReloadCustom, action: '', data: null };
              this.store.dispatch(new fromStore.SetEventList({storeName: ChargeBackStore.dashChargeBacks, parentId: -1, event: emitter}))
              this.modalService.close();
            }
          } else if (state.errorData.length) {
            this.errorData$.next(cloneDeep(state.errorData));
            this.mds.setErrorMessages(this.errorData$.getValue());
          }
        }
      }));
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromStore.getSelectedRecord(this.storeName, -1, this.key, this.chargeBackId)))
      .subscribe((entity: IChargeBack) => {
        this.isSubmitted = entity ? entity.submitted : false;
        this.fullAmountOptionLabel = this.isSubmitted ? ChargeBackInstallmentTypeLabel.fullAmountSubmitted : ChargeBackInstallmentTypeLabel.fullAmount;
        this.partialAmountOptionLabel = this.isSubmitted ? ChargeBackInstallmentTypeLabel.partialAmountSubmitted : ChargeBackInstallmentTypeLabel.partialAmount;
        this.setRadioButtonConfig();

        this.closeLabel = this.isSubmitted ? 'Cancel' : 'Close';
        this.optionsTitle = this.isSubmitted ? 'Charge Back Payment' : 'Charge Back Payment Options';
        this.operation = this.isSubmitted || entity.chargeBackStatus !== 'Apply' ? this.myConstants.operationTypeDetails : this.operationIn;
        this.chargeBack$.next(cloneDeep(entity));
        this.isFullAmt = (this.chargeBack$.value.submitted && this.chargeBack$.value.amountTotal === this.chargeBack$.value.installmentAmount);
        //disable installment fields - do not allow submit with $0 chargeback
        if (this.operation === this.myConstants.operationTypeDetails || this.chargeBack$.getValue().amountTotal <= 0) {
          this.installNumDisabled$.next(true);
          this.installAmtDisabled$.next(true);
          this.installFreqDisabled$.next(true);
        }
      })
    );
  }

  initForm() {
    this.mds.setFieldDefinitions(this.storeName);
  }

  loadForm() {
    this.mds.loadSelectionLists(this.displayFields, this.operation, this.chargeBackId);
    this.chargeBackStatuses = this.mds.getSelectItems('chargeBackStatus');
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.chargeBack$.getValue(), this.operation);
  }

  handleValueChanged(obj: IValueChanged) {
    switch (obj.key) {
      case 'numInstallments':
        this.installAmtDisabled$.next(obj.value > 0 || this.isFullAmt || this.operation === this.myConstants.operationTypeDetails);
        break;
      case 'installmentAmount':
        this.installNumDisabled$.next(obj.value > 0 || this.isFullAmt || this.operation === this.myConstants.operationTypeDetails);
        break;
      default:
        break;
    }
    this.isValid$.next(this.checkForValid());
    if (this.isValid$.getValue()) {
      if (!this.isFullAmt) {
        this.estimatePayments();
      }
    }
  }

  initNumberField(key: string) {
    const cntrl = this.form.controls[key];
    if (!cntrl) {
      return;
    }
    cntrl.setValue(0);
  }

  checkForValid(): boolean {
    if (this.isFullAmt) {
      return true;
    }
    const nbrCntrl = this.form.controls['numInstallments'];
    const amtCntrl = this.form.controls['installmentAmount'];
    const frequencyCntrl = this.form.controls['installmentFrequency'];

    if (nbrCntrl.value > this.chargeBack$.getValue().chargeBackInstallmentsMax) {
      this.hitMaxLimit$.next(true);
      return false;
    }
    this.hitMaxLimit$.next(false);
    return frequencyCntrl.value > 0 && (nbrCntrl.value > 0 || amtCntrl.value);
  }

  initInstallmentFields(disabled: boolean): void {
    this.installEstimates$.next(null);
    this.hitMaxLimit$.next(false);
    this.initNumberField('numInstallments');
    this.initNumberField('installmentFrequency');
    this.initNumberField('installmentAmount');

    this.installNumDisabled$.next(disabled);
    this.installAmtDisabled$.next(disabled);
    this.installFreqDisabled$.next(disabled);
  }

  estimatePayments(): void {
    const chargeBack: IChargeBack = this.chargeBack$.getValue();
    const balanceDue: number = chargeBack.remainingBalance ? chargeBack.remainingBalance : chargeBack.amountTotal;

    const nbrCntrl = this.form.controls['numInstallments'];
    const amtCntrl = this.form.controls['installmentAmount'];
    const frequencyCntrl = this.form.controls['installmentFrequency'];
    let numberOfInstallments: number = 0;
    let installmentAmount: number = 0;
    let lastInstallmentAmount: number = 0;

    // Split into X installments
    if (nbrCntrl.value > 0) {
      numberOfInstallments = nbrCntrl.value;
      installmentAmount = (balanceDue / numberOfInstallments); //parseInt((self.amountTotal() / numberOfInstallments) * 100);
      lastInstallmentAmount = balanceDue - ((numberOfInstallments - 1) * installmentAmount);
    } // OR deduct $x
    else if (amtCntrl.value > 0) {
       installmentAmount = amtCntrl.value; 
      numberOfInstallments = Math.ceil(balanceDue / installmentAmount);
      if (balanceDue % installmentAmount > 0) {
        lastInstallmentAmount = balanceDue % installmentAmount;
      }
    }

      //verify valid # of installments
    if (numberOfInstallments > chargeBack.chargeBackInstallmentsMax) {
      this.isValid$.next(false);
      this.hitMaxLimit$.next(true);
      return;
    }
    this.hitMaxLimit$.next(false);

    // load up installments
    let installments: IPaymentEstimate[] = [];
    const frequency: number = frequencyCntrl.value;
    let instDate = new Date();
    // populate array of installments
    for (var i = 0; i < numberOfInstallments - 1; i++) {
      const estimate: IPaymentEstimate = {
        nbr: i + 1,
        amount: installmentAmount,
        date: instDate.toDateString()
      };
      installments.push(estimate);
      instDate = instDate.addDays(7 * frequency); // number of weeks between installments
    }
    // any amount less than 1 cent is ignored for the preview, handled w/more complex scheme internally
    if (lastInstallmentAmount >= 1) {
      installments.push({
        nbr: numberOfInstallments,
        amount: lastInstallmentAmount,
        date: instDate.toDateString()
      });
    }
    this.installEstimates$.next(installments);
  }

  setRadioButtonConfig(): void {
    this.defaultOption = this.isFullAmt ? 0 : 1;
    this.payOptions = [
      {
        label: this.fullAmountOptionLabel,
        value: this.optionFullAmount,
        id: 'fullAmt',
        checked: false
      },
      {
        label: this.partialAmountOptionLabel,
        value: this.optionInstallments,
        id: 'partAmt',
        checked: true
      }
    ];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
