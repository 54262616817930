
import { IKey } from './i-key';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

/* structure for data object for DELETE_BY_URL reducer/action */
export interface IDeleteObject {
  keyData: IKey,
  url: string,
  event: IHomEventEmitter

}

export class DeleteObjectModel implements IDeleteObject {
  constructor(
    public keyData: IKey,
    public url: string,
    public event: IHomEventEmitter = null
  ) { }
}
