import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//components
import {
  EmptyComponent,
  ContactCustomerFlagDetailComponent,
  ScheduleLockedDaysCreateComponent
} from '../portal-shared/components/index';
import {
  EmailImportsDetailComponent,
  CustomerSearchDetailComponent,
  CustomerContactInfoComponent
} from './components';

//containers
import {
  UtilitiesComponent,
  CustomerSearchComponent,
  EmailImportsComponent,
  ProjectFileUploadsComponent,
  ReportViewerComponent,
  ScheduleMoveupsComponent,
  DispatchCalendarComponent,  InstallerVacationsComponent,
  PoImportsComponent
} from './containers/index';

import {
  ContactCustomerFlagsComponent,
  DispatchManagerComponent,
  WorkOrderNotesComponent,
  ScheduleLockedDaysComponent
} from '../portal-shared/containers';
import { ProjectPurchaseOrderSyncComponent } from '../project/containers';

import { SchedulePortalManagerComponent } from '../scheduler/containers';
import {
  PoImportGroupedHeadersComponent,
  PoImportHeadersComponent,
  PoImportHeaderContainerComponent,
  PoImportLineContainerComponent,
  PoDownloadImportManagerComponent
} from '../po-import/containers';

//guards
import { AuthGuard } from '../../../auth/services/auth-guard.service';
import { ProjectGuard } from '../project/services/project-guard.service';

//resolvers
import {
  ContactAddressMetaDataResolver,
  ContactEmailMetaDataResolver,
  ContactMetaDataResolver, ContactPhoneMetaDataResolver,
  ContactTypeSpecificationResolver,
} from '../../contact/resolvers';

import {
  ProjectInformationResolver,
  WorkOrderInformationResolver
} from '../project/resolvers';

import {
  SelectionListResolver,
  CalendarWorkOrderStatusResolver,
  MetaDataResolver,
  GeneralContractorsResolver,
  AllMyServicesResolver,
  AllMyProviderLocationsResolver,  CommunicationShortcutResolver,
  CommunicationDocumentTypeResolver,
  DocumentTypeResolver,
  EntityResolver
} from '../resolvers/index';

import { HomHolidayResolver } from './resolvers/hom-holiday-resolver.service';
import { PoImportHeaderResolver } from '../po-import/resolvers/po-import-header-resolver.service';
import { SmsConversationComponent } from '../../sms/containers';
import { LoadingPageComponent } from '../../../shared/components';

const portalRoutes: Routes = [
  {
    path: 'utilities', //componentless route 
    children: [
      {
        path: ':portalId', //not really needed at this time, but keeping so tab factory can always call the same route structure
        component: UtilitiesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'utilities-empty', //verify path, result of tab component concatenation
            outlet: 'portal-detail',
            component: EmptyComponent  
          },
          {
            path: 'loading/:container',
            outlet: 'portal-detail',
            component: LoadingPageComponent
          },
          {
            path: 'customer-search',
            outlet: 'portal-detail',
            component: CustomerSearchComponent,
            children: [
              {
                path: 'customer-search-detail/:id',
                outlet: 'customerSearchDetailOutlet',
                component: CustomerSearchDetailComponent
              }
            ]
          },
          {
            path: 'email-imports',
            outlet: 'portal-detail',
            component: EmailImportsComponent,
            children: [
              {
                path: 'email-imports-detail/:id',
                outlet: 'emailImportsDetailOutlet',
                component: EmailImportsDetailComponent
              }
            ]
          },
          {
            path: 'project-file-uploads',
            outlet: 'portal-detail',
            component: ProjectFileUploadsComponent
          },
          {
            path: 'report-viewer',
            outlet: 'portal-detail',
            component: ReportViewerComponent
          },
          {
            path: 'schedule-moveups',
            outlet: 'portal-detail',
            component: ScheduleMoveupsComponent, 
          },
          {
            path: 'dispatch-calendar',
            outlet: 'portal-detail',
            component: DispatchCalendarComponent,
            data: {
              storeName: 'dashCalendar',
              controller: 'WorkOrderViewModel',
              includeReadOnly: true
            },
            resolve: {
              //hasMeta: MetaDataResolver,
              gcs: GeneralContractorsResolver,
              woStatuses: CalendarWorkOrderStatusResolver,
              hasHolidays: HomHolidayResolver,
              providerLocations: AllMyProviderLocationsResolver, 
              services: AllMyServicesResolver
            }
          },
          {
            path: 'installer-vacations',  
            outlet: 'portal-detail',
            component: InstallerVacationsComponent,
            data: {
              includeReadOnly: false
            },
            resolve: {
              providerLocations: AllMyProviderLocationsResolver
            }
          },
          {
            path: 'po-imports',
            outlet: 'portal-detail',
            component: PoImportsComponent,
            resolve: {
              gcs: GeneralContractorsResolver
            },
            children: [
              {
                path: 'po-imports-all/:id',  //gcId
                outlet: 'importDetail',
                component: PoImportGroupedHeadersComponent,
                canActivate: [AuthGuard],
                children: [
                  { //view all po-import-headers associated to this vendor Po + branch
                    path: 'po-import-headers/:id',
                    outlet: 'poImportHeadersOutlet',
                    data: {
                      storeName: 'poImportHeaders'
                    },
                    resolve: {
                      infoLoaded: PoImportHeaderResolver
                    },
                    component: PoImportHeadersComponent,
                    children: [
                      {
                        path: 'po-import-header/:id',
                        outlet: 'poImportHeaderOutlet',
                        component: PoImportHeaderContainerComponent,
                        children: [
                          {
                            path: 'po-import-line/:id',
                            outlet: 'poImportLineOutlet',
                            component: PoImportLineContainerComponent,
                            resolve: {
                              selectListsLoaded: SelectionListResolver
                            }
                          }
                        ]
                      },
                    ]
                  },
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'contact-info/:id',
    outlet: 'modal',
    component: CustomerContactInfoComponent,
    data: {
      contactTypeId: '1' 
    },
    title: 'contact-info',
    resolve: { 
      hasContactMetaData: ContactMetaDataResolver,
      hadPhoneMetaData: ContactPhoneMetaDataResolver,
      hasEmailMetaData: ContactEmailMetaDataResolver,
      hasAddrMetaData: ContactAddressMetaDataResolver,
      hasTypeData: ContactTypeSpecificationResolver
    }
  },
  {
    path: 'customer-flags/:id',
    outlet: 'modal',
    component: ContactCustomerFlagsComponent,
    //canActivate: [AuthGuard],
    children: [
      {
        path: 'customer-flag-detail/:id',
        outlet: 'customerFlagOutlet',
        resolve: {
          selectListsLoaded: SelectionListResolver
        },
        component: ContactCustomerFlagDetailComponent
      }
    ]
  },
  {
    path: 'utilities-scheduler/:id',
    outlet: 'modal',
    canActivate: [AuthGuard, ProjectGuard],
    component: SchedulePortalManagerComponent,
    resolve: {
      hasProject: ProjectInformationResolver,
      hasWo: WorkOrderInformationResolver
    },
    children: [
      {
        path: 'wo-po-sync/:id',
        outlet: 'sidebar',
        component: ProjectPurchaseOrderSyncComponent
      },
      {
        path: 'sms-convo/:id/:phone/:name/:parent',
        outlet: 'chatmodal',
        component: SmsConversationComponent,
        resolve: {
          hasShortcuts: CommunicationShortcutResolver,
          hasDocTypes: CommunicationDocumentTypeResolver,
          hasFileTypes: DocumentTypeResolver,
          hasEntities: EntityResolver
        }
      }
    ]
  },
  {
    path: 'utilities-dispatch/:id',
    outlet: 'modal',
    component: DispatchManagerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'utilities-work-order-notes/:id/add',
    outlet: 'modal',
    component: WorkOrderNotesComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: 'schedule-locked-days/:id/:day',
    outlet: 'modal',
    component: ScheduleLockedDaysComponent,
    children: [
      {
        path: 'schedule-locked-days-create/:id',
        outlet: 'scheduleLockedDaysCreateOutlet',
        component: ScheduleLockedDaysCreateComponent,
        resolve: {
          selectListsLoaded: SelectionListResolver
        }
      }
    ]
  },
  {
    path: 'sms-cust-convo/:id/:phone/:name/:noclose/:parent',
    outlet: 'modal',
    component: SmsConversationComponent,
     resolve: {
       hasShortcuts: CommunicationShortcutResolver,
       hasDocTypes: CommunicationDocumentTypeResolver,
       hasFileTypes: DocumentTypeResolver,
       hasEntities: EntityResolver
    }
  },
  {
    path: 'po-download-import/:id',
    outlet: 'modal',
    component: PoDownloadImportManagerComponent,
    resolve: {
      gcs: GeneralContractorsResolver
    }
  },
  {
    path: 'loading2/:container',
    outlet: 'modal2',
    component: LoadingPageComponent
  },
  {
    path: 'sms-cust-convo/:id/:phone/:name/:noclose/:parent',
    outlet: 'modal2',
    component: SmsConversationComponent,
    resolve: {
      hasShortcuts: CommunicationShortcutResolver,
      hasDocTypes: CommunicationDocumentTypeResolver,
      hasFileTypes: DocumentTypeResolver,
      hasEntities: EntityResolver
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class UtilitiesRoutingModule { }
