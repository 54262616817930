import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})

export class LocalStorage {
    name: string;
    register(name: string, type: any): void {
        this.name = name;
        if(!localStorage.getItem(name)) localStorage.setItem(name, JSON.stringify(type));
    }
    asset(): any {
        return JSON.parse(localStorage.getItem(this.name));
    }
    update(asset): void {
        localStorage.setItem(this.name, JSON.stringify(asset));
    }
}
