import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import {  IChargeBack, IShipperPurchaseOrderItem, IShipperPurchaseOrder } from '../../../app/portals/view-models/index';
import { UserPriviledgesService } from '../../../auth/services/index';
import { IApprovalQueueTransaction } from '../../../shared/interfaces';

@Injectable()
export class ManagerDashDynamicListService {

  constructor(public userPriviledgesService: UserPriviledgesService,
    public datePipe: DatePipe) { }

  handleCustomMethod(methodName: string, row: any, portalEntityId: number): any {
    switch (methodName) {
      case 'getChargeBackStatusCss':
        return this.getChargeBackStatusCss(row);
      case 'canPrintInstallerNotice':
        return this.canPrintInstallerNotice(row);
      case 'getIsOrderQtyOutOfRangeCss':
        return this.getIsOrderQtyOutOfRangeCss(row);
      case 'getIsOrderItemQtyOutOfRangeCss':
        return this.getIsOrderItemQtyOutOfRangeCss(row);
      case 'getIsApprovalWindowOpenCss':
        return this.getIsApprovalWindowOpenCss(row);
      case 'canIApproveApprovalQueue':
        return this.canIApproveApprovalQueue(row);
      default:
        return false;
    }
  }
  /*CUSTOM  METHODS TO SET CSS */
  getChargeBackStatusCss(row: IChargeBack): string {
    if (!row) {
      return '';
    }
    const today = new Date();
    const createdOn = new Date(this.datePipe.transform(row.createDate, 'MM/dd/yyyy'));
    return row.chargeBackStatus === 'Apply' && !row.submitted && createdOn.addDays(5) <= today ? 'dynamic-list__item--danger'
      : row.chargeBackStatus === 'Hold' ? 'dynamic-list__item--warning'
      : '';
  }

  getIsOrderQtyOutOfRangeCss(row: IShipperPurchaseOrder): string {
    if (!row) {
      return '';
    }
    return row.hasOverAllocation ? 'dynamic-list__item--danger' : '';
  }

  getIsOrderItemQtyOutOfRangeCss(row: IShipperPurchaseOrderItem): string {
    if (!row || Number.isNaN(row.suggestedAmount) || Number.isNaN(row.quantity)) {
      return '';
    }
    return row.suggestedAmount - row.quantity < 0 ? 'dynamic-list__item--danger' : '';
  }

  getIsApprovalWindowOpenCss(row: IApprovalQueueTransaction): string {
    if (!row) {
      return '';
    }
    return !row.approvalWindowOpen ? 'dynamic-list__item--warning' : '';
  }
  canIApproveApprovalQueue(row: IApprovalQueueTransaction): boolean {
    if (!row) {
      return false;
    }
    return row.approvalWindowOpen && this.userPriviledgesService.canApproveApprovalQueue$.getValue();

  }

/*CUSTOM  METHODS TO DETERMINE BUTTON ENABLED */
  canPrintInstallerNotice(row: IChargeBack): boolean {
    return !this.userPriviledgesService.getPrintUrl(row) ? false : true;
  }
}
