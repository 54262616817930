import { Component, OnInit,  Inject, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IProviderUserPrivilegeViewModel } from '../../../view-models';
import { PrivilegeRuleEntitySpecifierType } from '../../enums/admin.enums';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'user-services',
  templateUrl: './user-services.component.html'
})
export class UserServicesComponent implements OnInit {
  @Input() userServices: IProviderUserPrivilegeViewModel[];
  @Input() specifierType: number;
  @Input() isWorking: boolean;
  @Input() haveAllServices: boolean;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public title: string;
  public accessType: string;
  requestor: string = 'user-services';
  subscription: Subscription = new Subscription();

  constructor(public adminService: AdminService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onEvent(event: IHomEventEmitter) {
    let eventOut: IHomEventEmitter = cloneDeep(event);
    eventOut.action = this.specifierType.toString();

    this.customEvent.emit(eventOut);

  }

  ngOnInit() {
    const type: string = this.specifierType === PrivilegeRuleEntitySpecifierType.primaryService ? 'Primary' : 'Secondary';
    this.title = type.concat(' ', 'Service');
 }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
