
  <fw-component-title [title]="'Warranty Base Work Order'" *ngIf="!(listsReady$ | async) || !((warranty$ | async) )"></fw-component-title>
  <div [ngClass]="{ 'loading-mask': (loading$ | async) || !(listsReady$ | async) || !(warranty$ | async) || !(haveSubTypes$ | async) }">
    <warranty-general-information *ngIf="(listsReady$ | async) && (warranty$ | async) && (haveSubTypes$ | async)"
                                  [warranty]="(warranty$ | async)"
                                  [warrantySubTypes]="(warrantySubTypes$ | async)"
                                  [operation]="(operation$ | async)"
                                  (warrantyTypeChangeEvent)="dispatchSubTypes($event)"
                                  (editEvent)="edit($event)"
                                  (saveEvent)="updateRecord($event)"
                                  (cancelEvent)="cancel()"
                                  (reloadEvent)="reload()">
    </warranty-general-information>
    <fw-error-box [errorData]="errorData$ | async"></fw-error-box>
    <div class="app-info" *ngIf="warranty">
      <i class="fas fa-info-circle app-icon--primary"></i>
      Click to open the originating project
      <span class="app-link" (click)="openLink(warranty.parentProjectId)">
        {{ warranty.parentProjectId }}
      </span>
    </div>
  </div>



