/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'errored-line-items',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './errored-line-items.component.html'
})
export class ErroredLineItemsComponent implements OnInit {
  constructor(
    public activeRoute: ActivatedRoute,
    public router: Router) {
  }

    ngOnInit() {
      this.activeRoute.paramMap.subscribe(paramMap => {
        const generalContractorId: number = 1;
        //component po import grouped was set up as a route to - so routing to it.
        this.router.navigate([{ outlets: { 'portal-detail': 'loading/portal-detail-detail__outlet' } }], { relativeTo: this.activeRoute.parent }).then(success => {
          if (success) {
            this.router.navigate([{ outlets: { 'portal-detail': `po-import-errors/${generalContractorId}/-1/true` } }], { relativeTo: this.activeRoute.parent });
          }
        });
      });
    }

}
