import { Component, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { DomainObjectService } from '../../../../../shared/services';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

@Component({
  selector: 'schedule-exclusions-create',
  templateUrl: './schedule-exclusions-create.component.html'
})

export class ScheduleExclusionsCreateComponent {
  constructor(
    public dos: DomainObjectService,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants
  ) { }

  public selectedDates$: BehaviorSubject<Date[]> = new BehaviorSubject([]);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public responseItems: any[] = [];

  public hasError(date: Date): boolean {
    return this.responseItems.find(x => x.result.WasFailure && x.date === date.toLocaleDateString());
  }
  
  public onSelected(selectedDates: Date[]) {
    this.selectedDates$.next(selectedDates.sort((a, b) => {
      return <any>a - <any>b;
    }));
  }

  public getResponseMessage(date: Date): any {
    const item = this.responseItems.find(x => x.date === date.toLocaleDateString());
    if (!item) return false;
    return item.result.WasFailure ? item.result.Message : "Success.";
  }

  public onCreate() {
    let selectedDates: string[] = [];
    this.selectedDates$.value.forEach((x) => {
      const responseItem = this.responseItems.find(y => y.date === x.toLocaleDateString());
      if (!responseItem || responseItem.result.WasFailure) {
        selectedDates.push(x.toLocaleDateString());
      }
    });
    this.loading$.next(true);
    if (!selectedDates.length) {
      this.onCancel();
      return;
    }
    this.selectedDates$.next(this.selectedDates$.value.filter(x =>
      selectedDates.find(y => y === x.toLocaleDateString()) ? true : false
    ));
    this.dos.createByMethod('SlotScheduleExclusion', 'CreateBulk', { 'dates': selectedDates })
      // RESPONSE STRUCTURE
      .subscribe((response: {
        data: {
          date: string;
          result: {
            WasSuccessful: boolean,
            WasFailure: boolean,
            Meassage: string
          }
        }[]
      }) => {
      this.loading$.next(false);
      const failedItems = response.data.filter(x => x.result.WasFailure);
      if (failedItems.length) {
        this.responseItems = response.data;
      } else {
        this.onCancel();
      }
    });
    
  }

  public onCancel() {
    const emitter: IHomEventEmitter = { requestor: 'admin-schedule-exclusions-create', event: this.myConstants.emitterEventListReload, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

}
