import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import {  filter, take } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { InstallerWorkCrew } from '../../../view-models/index';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import {  UpdateObjectCustomModel, CreateObjectModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  selector: 'installer-work-crew',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './installer-work-crew.component.html'
})
export class InstallerWorkCrewComponent implements OnInit, OnDestroy  {
  public displayFields: string[] = ['installerWorkCrewName', 'isActive', 'doesWarranty'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public objectData: InstallerWorkCrew = null;

  listStore: fromDynamicList.IListObjectData;
  installerId: number = -1;
  workCrewId: number = -1;
  myStoreName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  controllerName: string = '';
  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {}

  ngOnInit() {
    //handle a change in either portalEntityId (installerId) or objectId (workcrewId)
    this.subscription.add(this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.workCrewId = +paramMap.get('id');
      if (this.workCrewId > 0) {
        this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
          this.installerId = +paramMap.get('portalEntityId');
          this.key = paramMap.get('key');
          this.objectId = +paramMap.get('id');
          this.operation = paramMap.get('operation');
          this.myStoreName = paramMap.get('storeName');
          this.setDetailConfig(paramMap);
          this.objectData = null;
          this.getDetail();
        }));
      }
    }));

    this.subscription.add(this.emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case this.myConstants.emitterEventCreate:
            this.createRecord(e);
            break;
          default:
            break;
        }
      }));
  }

  setDetailConfig(paramMap: ParamMap): void {
      let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
      params.parentId = this.installerId;
      params.useRouterOutlet = false;
      params.showNav = true;
      params.showTitle = true;
      this.detailConfig$.next(params);
  }

  //parent id is installerId for this store
  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromStore.getSelectedRecord(this.myStoreName, this.workCrewId, this.key, this.objectId)))
      .subscribe(entity => {
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate ? new InstallerWorkCrew() : entity;
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromStore.getSelectedParentListDefinition(this.myStoreName, this.workCrewId)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.controllerName = listDefinition.controllerName;
      }
      ));
  }

    /*
        Insert the new record
    */
  createRecord(e: IHomEventEmitter) {

    let iwc: InstallerWorkCrew = this.setCommonProperties(e.data);

    iwc.createDate = new Date().toDateString();

    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.myStoreName, this.installerId, this.controllerName, 'Create', iwc, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }
    /*
        Update the record:  since the data contains both existing and missing certifications, update needs to determine if create or update request
          based on value in installerCertificationId
    */
  updateRecord(e: IHomEventEmitter) {
      let iwcwc: InstallerWorkCrew = this.setCommonProperties(e.data);
      const keyData: IKey = { storeName: this.myStoreName, parentId: this.workCrewId, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
      const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', iwcwc, null, emitter);
      this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));

    }

    /*
        Set common create/update properties
    */
  setCommonProperties(formData: InstallerWorkCrew) {

    let iwc = new InstallerWorkCrew();
    iwc.installer_installerId = this.installerId

    iwc.installerWorkCrewName = formData.installerWorkCrewName;
    iwc.isActive = formData.isActive;
    iwc.doesWarranty = formData.doesWarranty;

    iwc.createDate = new Date().toDateString();

    return iwc;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
