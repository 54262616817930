import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition} from '../../../../../fw/dynamic-list/interfaces';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { AdminStore, AdminEvent } from '../../enums/admin.enums';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IPrivilegeViewModel } from '../../../view-models/i-privilege-view-model';
import { IPrivilegeRuleEntitySpecifier } from '../../../view-models';

import { AdminSecurityService } from '../../services/admin-security.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'privileges',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './privileges.component.html'
})
export class PrivilegesComponent implements OnInit {
  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public securityService: AdminSecurityService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.showPrivilegesWhereUsed:
        this.openWhereUsed(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.securityService.loadPrivilegesListDefinition();
  }

  openWhereUsed(data: IPrivilegeViewModel): void {
    this.modalService.open({
      title: 'Where Used for Privilege'.concat(' ', data.privilegeId),
      path: 'admin-priv-used/'.concat(data.uniqueId.toString()),
      id: data.uniqueId,
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      hasTabs: false
    });
  }

}
