<div *ngIf="purchaseOrderId" class="portal" [ngClass]="{ 'loading-mask': loading$ | async }">
  <div class="portal__detail--item">
    <purchase-order-general-information *ngIf="(purchaseOrder$ | async) && (branchPrograms$ | async)"
                          [purchaseOrder]="(purchaseOrder$ | async)"
                          [branchPrograms]="branchPrograms$ | async"
                          [projectId]="projectId"
                          [canIEdit]="canIEdit$ | async"
                          [printUrl]="printUrl"
                          [staleData]="staleData"
                          [operation]="operation$ | async"
                          (customEvent)="onCustom($event)">
    </purchase-order-general-information>
  </div>

  <div class="portal__detail--item" *ngIf="(purchaseOrder$ | async) as po">
    <fw-component-title [title]="'Purchase Order Line Items'"></fw-component-title>
    <project-purchase-order-items [purchaseOrderId]="purchaseOrderId"
                                  [canIEdit]="canIEdit$ | async">
    </project-purchase-order-items>
  </div>
</div>
