export enum ManagerDashStore {
  dashPendingApprovals = 'dashPendingApprovals',
  dashShipperPos = 'dashShipperPos',
  dashShipperPoItems = 'dashShipperPoItems',
  dashInventoryNeeds = 'dashInventoryNeeds',
  dashInventoryWos = 'dashInventoryWos',
  dataSlice = 'dataSlice',
  dashCommunicationEventLogs = 'dashCommunicationEventLogs',
  dashOutstandingDocuments = 'dashOutstandingDocuments'
}

export enum ManagerDashEvent {
  approvePendingChange = 'approvePendingChange',
  denyPendingChange = 'denyPendingChange',
  includeInOrder = 'includeInOrder',
  openLink = 'openLink',
  markResolved = 'markResolved'
}

