import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { IListDefinition } from '../../../../fw/dynamic-list/interfaces';
import { IUploadFile } from '../../interfaces';
import { IEntityDocument } from '../../../portals/view-models/index_two';
import { appConstants, IAppConstants } from '../../../../shared/constants';
import { IResponseBase } from '../../../../shared/interfaces';
import { IListFilter } from '../../../../../../installer-portal/src/app/interfaces';
import { IDocumentType } from '../../../portals/view-models';

import { FileUploadType, DocumentTypeName } from '../../enums/file-upload.enums';
import { ProjectListStore } from '../../../portals/project/enums/project.enums';
import { UtilitiesStore } from '../../../portals/utilities/enums/utilities.enums';
import { FilePreviewerComponent } from '../../components';

import * as fromRoot from '../../../store/reducers/index';
import * as fromFeature from '../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as DynamicListActions from '../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { getSelectedRecord, getListByType, IDynamicListState, IListObjectData } from '../../../../fw/dynamic-list/store';
import { getSelectionListDataByType } from '../../../../shared/store/selectionLists';
import { ScheduleStore } from '../../../portals/scheduler/enums/schedule.enums';

import { UserPriviledgesService } from '../../../../auth/services';
import { DomainObjectService } from '../../../../shared/services';
import { ProjectService } from '../../../portals/project/services';
import { FileUploaderService } from '../../services/file-uploader.service';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'file-upload-manager',
  templateUrl: './file-upload-manager.component.html'
})
export class FileUploadManagerComponent implements OnInit, OnDestroy {
  @ViewChild('filePreviewer') public filePreviewer: FilePreviewerComponent;
  public listFilter: IListFilter;
  public listDefinition: IListDefinition;
  public entityDocumentId: number;
  public entityDocument: IEntityDocument;
  public otherActionIds: number[];
  public uploads$: BehaviorSubject<IUploadFile[]> = new BehaviorSubject([]);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  documentTypes: IDocumentType[];
  parentId: number = -1;
  actionId: number;
  listType: number;
  storeName: string;
  subscription: Subscription = new Subscription();
  allowedFileTypes: string[] = [];
  bulkUpload: boolean = false;
  showCancel: boolean = true; 

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromFeature.IAllDynamicData>,
    public ups: UserPriviledgesService,
    public projectService: ProjectService,
    public fus: FileUploaderService,
    public dos: DomainObjectService,
    public emitterService: HomEventEmitterService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit(): void {
    this.bulkUpload = false;
    //get the ids, determine the store, get the entitydocument, unless create (id == 0)
    //based on existence of entityDocumentId and based on type,
    //  set if uploading one or more
    //this.isOutgoing = this.activeRoute.snapshot.data.hasOwnProperty('outgoing') ? this.activeRoute.snapshot.data['outgoing'] : false;

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('documentType')))
      .subscribe((data) => {
        this.documentTypes = data;
      }));

    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      if (paramMap.has('id')) {
        this.parentId = +paramMap.get('id');
      }
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
     this.entityDocumentId = +paramMap.get('id');
     if (paramMap.has('parentId')) {
       this.parentId = +paramMap.get('parentId');
     }
     if (paramMap.has('actionId')) {
       this.actionId = +paramMap.get('actionId');
     } else {
       this.actionId = -1; 
     }
     if (paramMap.has('type')) {
       this.listType = +paramMap.get('type');
     } else if (this.parentId > 0) {
       this.listType = +FileUploadType.projectLocal;
     } else {
       this.listType = +FileUploadType.projectFileImport;
      }
      const actionIds = paramMap.get('actionIds');
      this.otherActionIds = !actionIds ? [] : actionIds.split(",")
        .map(x => {
          return parseInt(x);
        });

     this.newRequest();

    });
  }

  public get canUploadAll(): boolean {
    const files: IUploadFile[] = this.uploads$.value || [];
    if (files.find(x => x.documentTypeId === null)) {
      return false;
    }
    return true;
  }

  public onAddEvent(file: File): void {
    this.formatAddFile(file);
  }

  public changeItem(uploadFile: IUploadFile): void {
    //TBD:  do anything here?
  }

  public removeItem(id: string): void {
    let uploads: IUploadFile[] = this.uploads$.value;
    uploads = uploads.filter(x => x.id !== id);
    this.uploads$.next(uploads);

    if (this.bulkUpload) {
      if (uploads && uploads.length == 0) {
        //if no more items, close
        this.bulkUpload = false;
        this.loading$.next(false);
        this.dispatchClose();
      }
    } else {
      this.loading$.next(false);
    }
  }

  public uploadItem(file: IUploadFile): void {
    this.loading$.next(true);
    if (this.entityDocumentId) {
      this.uploadFileToExistingDocument(file);
    } else {
      this.createDocumentAndUploadFile(file);
    }
  }

  //UploadAll will always be upload and createDocumentAndUploadFile
  //one to one relationship between current document version and file upload
  public uploadAll(): void {
    if (this.filePreviewer) {
      this.bulkUpload = true;
      this.filePreviewer.uploadAll();
    }
  }

  public removeAll(): void {
    this.uploads$.next([]);
  }

  public close(): void {
    //if working with an entityDocument, will need to request a reload
    //else just a close and that will reload since in create mode.
    //TBD
    this.dispatchClose();
    this.closeModal();
  }

  //TODO DOC MGT:  rework
  public hasErroredItems(): boolean {
    const items = this.uploads$.value;
    if (items.find(x => x.errorData !== null)) {
      return true;
    }

    return false;
  }

  newRequest(): void {
    this.setStoreNameByType();
    this.setAllowedTypes();

    this.subscription.add(this.store.pipe(select(getListByType(this.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.parentId)), take(1))
      .subscribe((objData: IListObjectData) => {
        if (objData) {
          this.listFilter = objData.listFilter;
          this.listDefinition = objData.listDefinition;
        }
      }));

    if (this.entityDocumentId > 0) {
      this.getEntityDocument();
    } 
  }

  getEntityDocument(): void {
    //go get record
    this.subscription.add(this.store.pipe(select(getSelectedRecord(this.storeName, this.parentId, 'entityDocumentId', this.entityDocumentId)))
      .subscribe((row: IEntityDocument) => {
        this.entityDocument = row;
      }));
  }

  dispatchClose(): void {
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: this.listDefinition, listFilter: this.listFilter, parentId: this.parentId }));
    this.emitterService.emitListEvent({
      requestor: 'file-upload-manager',
      event: this.myConstants.emitterEventClose,
      action: '',
      data: null
    });
  }

  closeModal(): void {
    if (this.entityDocumentId > 0) {
      if (this.modalService.opened) {
        this.modalService.close();
      }
    }
  }

  setAllowedTypes(): void {
    if (this.entityDocumentId > 0) {
      this.allowedFileTypes = this.fus.woPacketAllowedFileTypes;
    } else {
      this.allowedFileTypes = this.fus.projectAllowedFileTypes;
    }
  }

  setStoreNameByType(): void {
    let storeName: string = '';
    switch (this.listType) {
      case +FileUploadType.projectRequired:
        storeName = ProjectListStore.projectEntityDocuments;
        break;
      case +FileUploadType.projectLocal:
        storeName = ProjectListStore.projectFiles;
        break;
      case +FileUploadType.projectFileImport:
        storeName = UtilitiesStore.dashFileUploads;
        break;
      case +FileUploadType.poMeasure:
        storeName = ScheduleStore.scheduleMeasures;
        break;
      default:
        break;
    }

    this.storeName = storeName;
  }

  formatAddFile(fileIn: File): void {
    const documentTypeId: number = this.getDocumentType();
    const myArray = new Uint32Array(1);
    let uploadFile: IUploadFile = {
      id: crypto.getRandomValues(myArray).toString(),  //so always know which row to update
      file: fileIn,
      name: fileIn.name,
      imageSize: null, 
      imageCaption: "",
      documentTypeId: documentTypeId,
      rotateClockwise: 0,
      includeInWorkOrderPacket: false
    };

    if (fileIn.type.split("/")[0] === "image" || fileIn.name.split('.')[fileIn.name.split('.').length - 1] === 'heic') {
      let fileReader: FileReader = new FileReader();
      fileReader.readAsDataURL(fileIn);
      fileReader.onload = () => {
        uploadFile.previewUrl = fileReader.result;
        this.updateUploads(uploadFile);
      }
    } else {
      this.updateUploads(uploadFile);
    }
  }

  //Handling promise dely of fileReader
  //update uploads$ array on completion.
  updateUploads(addFile: IUploadFile): void {
    if (this.entityDocument) {
      //single file upload
      this.uploads$.next([addFile]);
    } else {
      //add to uploads
      let uploads: IUploadFile[] = this.uploads$.value || [];
      uploads.push(addFile);
      this.uploads$.next(uploads);
    }
  }

  createDocumentAndUploadFile(file: IUploadFile): void {
    let formData: FormData = this.setFormData(file);
    const documentTypeId: number = this.getDocumentType();
    formData.append('documentTypeId', file.documentTypeId.toString() || documentTypeId.toString());
    formData.append('entityPkid', this.parentId.toString());

    this.subscription.add(this.dos.createByMethodWithForm('EntityDocument', 'CreateAndUpload', formData)
      .subscribe((result: IResponseBase) => {
        if (result.success) {
          this.removeItem(file.id);
        } else {
          this.handleError(file.id, result.errorData);
        }
        const stillWorking: boolean = this.bulkUpload ? this.isStillUploading() : false;
        if (this.bulkUpload) {
          this.bulkUpload = stillWorking;
        }
        this.loading$.next(stillWorking);
      }));
  }

  uploadFileToExistingDocument(file: IUploadFile): void {
    let formData: FormData = this.setFormData(file);
    formData.append('id', this.entityDocumentId.toString());
    formData.append('actionId', this.actionId.toString());
    for (const index in this.otherActionIds) {
      formData.append(`actionIds[${index}]`, this.otherActionIds[index].toString());
    }
    this.loading$.next(true);
    //this does no parsing of the object.
    this.subscription.add(this.dos.createByMethodWithForm('EntityDocument', 'UploadFile', formData)
      .subscribe((result: IResponseBase) => {
        if (result.success) {
          this.removeItem(file.id);
          if (this.entityDocument.isRequired) {
            this.projectService.emitTaskReloadEvent('file-upload-manager', this.parentId);
          }
          this.close();
        } else {
          this.handleError(file.id, result.errorData);
        }
        this.loading$.next(false);
      }));
  }

  handleError(id: string, errorData: any): void {
    let uploads: IUploadFile[] = this.uploads$.value;
    const index: number = uploads.findIndex(x => x.id === id);
    if (index > -1) {
      uploads[index].errorData = errorData;
      this.uploads$.next(uploads);
    }
  }

  isStillUploading(): boolean {
    if (this.bulkUpload) {
      const allFiles = this.uploads$.value;
      const anyUnprocessed = allFiles.find(x => !x.errorData);
      return anyUnprocessed ? true : false;
    }

    return false;
  }

  setFormData(uploadFile: IUploadFile): FormData {
    let formData: FormData = new FormData();
    const isPdf = this.fus.isPdf(uploadFile.file);
    formData.append('file', uploadFile.file);
    formData.append('fileData', isPdf ? uploadFile.file :  this.fus.base64regex.test(uploadFile.previewUrl) ? this.fus.base64ToBlob(uploadFile.previewUrl) : uploadFile.file );
    formData.append('name', uploadFile.name);
    formData.append('rotateClockwise', uploadFile.rotateClockwise.toString());
    formData.append('includeInWorkOrderPacket', (uploadFile.hasOwnProperty('includeInWorkOrderPacket') ? uploadFile.includeInWorkOrderPacket : false).toString());
    var imageCaption: string = uploadFile.imageCaption;
    if (imageCaption.split('.').length === 1) {
      var parts: string[] = uploadFile.name.split('.');
      if (parts.length > 1) {
        imageCaption = imageCaption.concat('.',parts[parts.length - 1]);
      }
    }
    formData.append('imageCaption', imageCaption || uploadFile.name);
    return formData;
  }

  getDocumentType(): number {
    if (this.entityDocument) {
      //add a log here if document type id is null
      return this.entityDocument.documentType_documentTypeId;
    } else {
      var localProjectFileDocumentType = this.documentTypes && this.documentTypes.length
        ? this.documentTypes.find(x => x.documentTypeName == DocumentTypeName.localProjectFile)
        : null;
      if (localProjectFileDocumentType != null) {
        switch (this.listType) {
          case +FileUploadType.projectLocal:
            return localProjectFileDocumentType.documentTypeId;
          default:
            return localProjectFileDocumentType.documentTypeId;
        }
      } else {
        return localProjectFileDocumentType.documentTypeId;
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
