<div class="portal-detail" [ngClass]="{'portal-detail--wide': wideSummary}">
  <div class="portal-detail-summary">
    <div class="portal-detail-summary__header" [ngClass]="{'portal-detail-summary__header--wide': wideSummary}">
      <div class="portal-detail-summary__header__items">
        <!--do do change this be load a dynamic icon via a new i-icon interface:  title, class, icon props-->
        <div class="portal-detail-summary__header__item--title">
          <span *ngIf="titleIcon" class="portal-detail-summary__header__item--icon app-icon {{titleIcon.cssName}}" title="{{titleIcon.title}}">
            <i class="fas {{titleIcon.icon}}"></i>
          </span>
          {{title}}
        </div>
        <div class="portal-detail-summary__header__item--info">{{info}}</div>
        <div class="portal-detail-summary__header__item__btns">
          <ng-container *ngFor="let btn of customBtns">
            <button type="button"
                    class="app-btn-icon {{ btn.disabled ? 'app-btn-icon--neutral' : btn.css }}"
                    (click)="customClick(btn)"
                    [disabled]="btn.disabled"
                    title="{{ btn.disabled ? btn.disabledTitle : btn.title }}">
              <span class="{{ btn.icon }} "></span>
            </button>
          </ng-container>
        </div>
      </div>
      <div *ngIf="subTitle" class="portal-detail-summary__sub-header">
        <div class="portal-detail-summary__sub-header--title">{{subTitle}}</div>
      </div>
    </div>
    <div class="portal-detail-summary__content" [ngClass]="{'portal-detail-summary__content--with-sub-head': subTitle}">
      <!--content projection for the high-level summary component here-->
      <fw-container [disableScrolling]="portalId === 1">
        <ng-content></ng-content>
      </fw-container>
    </div>
  </div>
  <div class="portal-detail__spacer"></div>
  <div class="portal-detail-detail" [ngClass]="{'portal-detail-detail--visible': (myTabs$ | async).length }">
    <div class="portal-detail-detail__outlet">
      <fw-container>
        <router-outlet name="portal-detail"></router-outlet>
      </fw-container>
    </div>
    <div class="portal-detail-detail__tabs" [ngClass]="{'portal-detail-detail__tabs--wide': wideSummary}">
      <fw-app-tabs apptabs
                   [portalId]="portalId"
                   [portalEntityId]="portalEntityId"
                   [level]="portalLevel"
                   [limit]="maxTabs"
                   [tabDefinition]="myTabs$ | async">
      </fw-app-tabs>
    </div>
  </div>
  <div class="portal-detail-chat">
    <router-outlet name="chatbar"></router-outlet>
  </div>
</div>
