<div [ngClass]="{ 'loading-mask': loading$ | async }">
    <warranty-create *ngIf="noteMetaExists$ | async"
                     [displayFields]="displayFields"
                     [project]="project$ | async"
                     [workOrder]="workOrder$ | async"
                     [warrantySubTypes]="warrantySubTypes$ | async"
                     (warrantyTypeChangeEvent)="onWarrantyTypeChange($event)"
                     (cancelEvent)="onCancel($event)"
                     (createEvent)="onCreate($event)">
      <!--drop error box in ng-content area-->
      <fw-error-box [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
    </warranty-create>

  <fw-component-title [title]="'Current Project Information'"></fw-component-title>
  <div class="nested-panel" *ngIf="(project$ | async)">
    <job-site-manager [projectId]="(project$ | async).projectId"
                      [contactId]="(project$ | async).customer_contactId"
                      [canIEdit]="false"
                      [hasStaleData]="false"
                      [projectStatus]="(project$ | async).currentProjectStatusCode"
                      [justInfo]="true">
    </job-site-manager>

    <fw-section-title title="Work Order Details" [underline]="true" icon="fa-project-diagram"></fw-section-title>
    <work-order-summary *ngIf="workOrder$ | async"
                        [operation]="operation"
                        [workOrder]="workOrder$ | async">
    </work-order-summary>
  </div>
</div>
