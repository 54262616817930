
export interface IWorkOrderItemShipper {
  workOrderItemShipperId: number;
  shipper_shipperId: number;
  payout_payoutId: number;
  shipperItemProductDesignation: string;
  inventoryItemUnit: number;
}

export class WorkOrderItemShipper implements IWorkOrderItemShipper {
  workOrderItemShipperId: number;
  shipper_shipperId: number;
  payout_payoutId: number;
  shipperItemProductDesignation: string;
  inventoryItemUnit: number;

  constructor() {
    this.workOrderItemShipperId = 0;
    this.shipper_shipperId = null;
    this.payout_payoutId = null;
    this.shipperItemProductDesignation = '';
    this.inventoryItemUnit = 0;
  }
}
