import { Injectable } from '@angular/core';

import {
  IListColumn, ListColumn,
  IListFilter, IListButtonType, IListDefinition,
  ListFilter,  OrderTerm
} from '../../../../fw/dynamic-list/interfaces';
import { AdminStore } from '../enums/admin.enums';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { UserPriviledgesService } from '../../../../auth/services';

@Injectable()
export class AdminSssService {

  constructor(public dynamicListService: DynamicListService,
    public ups: UserPriviledgesService  ) { }

  public loadSSSGeneralContractorsListDefinition(): IListDefinition {
    const listColumns = this.loadSSSGeneralContractorsListColumns();
    const listObjectLabel = 'Sss General Contractor';
    const listObjectController = 'SssGeneralContractor';
    const listStoreName = AdminStore.sssGeneralContractors;
    const detailRoutePath = 'sss-gc';
    const listRowKeyId = 'sssGeneralContractorId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [];
    defaultListFilter.orderTerm = [new OrderTerm('generalContractor_generalContractorName')];

    let listDefinition = this.dynamicListService.createListDefinition('sssGcOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadSSSGeneralContractorsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('generalContractor');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('excludeAllPrograms');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('dateRange');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('allowSlotBufferReservations');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('etaBuffer');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('minimumDuration');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadSSSProgramsListDefinition(): IListDefinition {
    const listColumns = this.loadSSSProgramsListColumns();
    const listObjectLabel = 'Sss Program';
    const listObjectController = 'SssGeneralContractorProgram';
    const listStoreName = AdminStore.sssPrograms;
    const detailRoutePath = 'sss-pgm';
    const listRowKeyId = 'sssGeneralContractorProgramId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [];
    defaultListFilter.orderTerm = [new OrderTerm('sssGeneralContractor_generalContractor_generalContractorName'), new OrderTerm('program_programName')];

    let listDefinition = this.dynamicListService.createListDefinition('sssPgmOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowStatus = {
      cssMethod: 'getGcExcludingProgramsCss',
      methodService: 'adminDynamicListService'
    };

    return listDefinition;
  }

  loadSSSProgramsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('sssGeneralContractor');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('program');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('excludeAllProgramServices');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('dateRange');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('allowSlotBufferReservations');
    colDef.visibleOnLarge = false;
    colDef.visibleOnMedium = false;
   colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('etaBuffer');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('minimumDuration');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadSSSProgramServicesListDefinition(): IListDefinition {
    const listColumns = this.loadSSSProgramServicesListColumns();
    const listObjectLabel = 'Sss Program Service';
    const listObjectController = 'SssProgramService';
    const listStoreName = AdminStore.sssProgramServices;
    const detailRoutePath = 'sss-pgm-svc';
    const listRowKeyId = 'sssProgramServiceId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [];
    defaultListFilter.orderTerm = [new OrderTerm('generalContractorName'), new OrderTerm('programService_program_programName'), new OrderTerm('programService_service_serviceName')];

    let listDefinition = this.dynamicListService.createListDefinition('sssPgmSvcOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowStatus = {
      cssMethod: 'getPgmExcludingServicesCss',
      methodService: 'adminDynamicListService'
    };

    return listDefinition;
  }

  loadSSSProgramServicesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('generalContractorName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    //colDef = new ListColumn('sssGeneralContractorProgram');
    //columnDefinitions.push(colDef);

    colDef = new ListColumn('programService');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('excludeAllBranchPrograms');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('dateRange');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('allowSlotBufferReservations');
    colDef.visibleOnLarge = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('etaBuffer');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('minimumDuration');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadSSSBranchProgramsListDefinition(): IListDefinition {
    const listColumns = this.loadSSSBranchProgramsListColumns();
    const listObjectLabel = 'SSS Branch Program';
    const listObjectController = 'SssBranchProgram';
    const listStoreName = AdminStore.sssPSBranches;
    const detailRoutePath = 'sss-ps-branch';
    const listRowKeyId = 'sssBranchProgramId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [];
    defaultListFilter.orderTerm = [
        new OrderTerm('generalContractorName'),
      new OrderTerm('sssProgramService_programService_program_programName'),
      new OrderTerm('sssProgramService_programService_service_serviceName'),
      new OrderTerm('branchProgram_branch_branchName')];

    let listDefinition = this.dynamicListService.createListDefinition('sssPsBranchOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    listDefinition.rowStatus = {
      cssMethod: 'getBranchExcludingServicesCss',
      methodService: 'adminDynamicListService'
    };

    return listDefinition;
  }

  loadSSSBranchProgramsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('generalContractorName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('sssProgramService');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('branchName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('dateRange');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('allowSlotBufferReservations');
    colDef.visibleOnLarge = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('etaBuffer');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('minimumDuration');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
