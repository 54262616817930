import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { AdminEvent } from '../../enums/admin.enums';
import { IProviderLocation } from '../../../view-models';

import { AdminProviderService } from '../../services/admin-provider.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ContactConstantsService } from '../../../../contact/services';
import { IAppConstants, appConstants } from '../../../../../shared/constants';

@Component({
  selector: 'provider-locations',
  templateUrl: './provider-locations.component.html'
})
export class ProviderLocationsComponent implements OnInit {

  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService,
    public modalService: ModalService,
    public contactConstantsService: ContactConstantsService,
    @Inject(appConstants) public myConstants: IAppConstants  ) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.manageLocationContact:
        this.openContactManager(event.data);
        break;
      case AdminEvent.manageLocationHours:
        this.openLocationHours(event.data);
        break;
      case AdminEvent.manageLocationBranches:
        this.openLocationBranches(event.data);
        break;
      case AdminEvent.manageLocationServices:
        this.openLocationServices(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadProviderLocationsListDefinition();
  }

  openContactManager(location: IProviderLocation): void {
    const contactId: number = location.providerLocationContact_contactId === null ? 0 : location.providerLocationContact_contactId;
    const contactTypeId: number = this.contactConstantsService.contactTypeIdProviderLocation;
    this.modalService.open({
      title: 'Contact Information for Location'.concat(' ', location.locationName),
      path: 'location-contact/'.concat(contactId.toString(), '/', location.providerLocationId.toString(), '/', contactTypeId.toString()),
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      hasTabs: false
    });
  }

  openLocationHours(location: IProviderLocation): void {
    this.modalService.open({
      title: 'Business Hours for '.concat(' ', location.locationName),
      path: 'location-hours/'.concat(location.providerLocationId.toString()),
      sizeType: ModalSizeType.xsmall,
      onExit: null,
      castExit: false,
      hasTabs: false
    });
  }

  openLocationBranches(location: IProviderLocation): void {
      this.modalService.open({
        title: 'Branches for Location'.concat(' ', location.locationName),
        path: 'loc-branches/' + location.providerLocationId.toString(),
        sizeType: ModalSizeType.small,
        onExit: null,
        castExit: false,
        optionalParams: null,
        hasTabs: false
      });
  }

  openLocationServices(location: IProviderLocation): void {
    this.modalService.open({
      title: 'Services for Location'.concat(' ', location.locationName),
      path: 'loc-svcs/' + location.providerLocationId.toString(),
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false
    });
  }
}
