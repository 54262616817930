<fw-dynamic-detail-container *ngIf="detailConfig" [detailConfig]="detailConfig">
  <form *ngIf="form" [formGroup]="form" novalidate class="form">
    <fw-dynamic-field [field]="mds.getFieldDefinition('vendorOrderNumber')"
                      [form]="form"
                      [operation]="operation"
                      [useContains]="true">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('programName')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('branchProgram')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="branchPrograms">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('orderTotal')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('poImportStatus')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="statuses">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('poImportStatusText')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>

  </form>
  <fw-component-footer [lastUpdate]="poImportHeader && poImportHeader.hasOwnProperty('updateDate')
                       ? poImportHeader.updateDate
                       : '' ">
  </fw-component-footer>
  <fw-crud-buttons *ngIf="form"
                   [operation]="operation"
                   [disabled]="!form.dirty || !form.valid"
                   [showNext]="true"
                   (cancelEvent)="onCancel($event)"
                   (saveEvent)="onSave($event)">
  </fw-crud-buttons>
</fw-dynamic-detail-container>
