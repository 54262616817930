import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';

import { UserDashboardService } from '../../services/user-dashboard.service';

@Component({
  selector: 'pending-tasks',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pending-tasks.component.html'
})
export class PendingTasksComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public userDashboardService: UserDashboardService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.userDashboardService.loadPendingTasksListDefinition());
    });
  }
}
