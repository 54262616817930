<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <form *ngIf="form" [formGroup]="form" class="form-wrapper form-wrapper--{{ operation }}">
    <fw-dynamic-field [field]="mds.getFieldDefinition('zoneName')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>

    <div class="zone-filters-con" *ngIf="operation !== myConstants.operationTypeDetails">
      <label>Filter Available Zip Codes</label>
      <div class="zone-filters">
        <div class="zone-filters__item">
          <label>State</label>
          <select class="zone-filters__item__select" #states [disabled]="(states$ | async) ? null : true" (change)="onStateChange(states.value)">
            <option [value]="-1">Search...</option>
            <option *ngFor="let item of (states$ | async)" [value]="item.stateId">{{ item.stateName }}</option>
          </select>
        </div>
        <div class="zone-filters__item">
          <label>City</label>
          <select class="zone-filters__item__select" #cities [disabled]="(cities$ | async) ? null : true" (change)="onCityChange(cities.value)">
            <option [value]="-1">Search...</option>
            <option *ngFor="let item of (cities$ | async)" [value]="item">{{ item }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="zone-selections">

      <div class="zone-selections__con" *ngIf="operation !== myConstants.operationTypeDetails">
        <label>Add New Zip Codes</label>
        <div class="zone-selections__con__list" [ngClass]="{'loading-mask': (availableZipCodesLoading$ | async)}">
          <ul class="zone-selections__con__list__ul" *ngIf="(availableZipCodes$ | async)">
            <li class="zone-selections__con__list__ul__li" *ngFor="let item of (availableZipCodes$ | async)" [ngClass]="{'zone-selections__con__list__ul__li--hidden': !((filterCity === -1 || !filterCity) ? true : item.cityName === filterCity)}">
              <div class="zone-selections__con__list__ul__li__txt" [ngClass]="{'zone-selections__con__list__ul__li__txt--selected': isSelected(item.zipCodeName, true)}" (click)="stageZipCode(item, true)"> 
                {{ (item.zipCodeName + ' ' + item.cityName + ', ' + item.state) }}
              </div>
              <button class="app-btn-icon app-btn-icon--success" title="Add" (click)="addZipCode(item)">
                <i class="far fa-plus"></i>
              </button>
            </li>
          </ul>
        </div>
        <div class="app-btn-box zone-selections__con__btn">
          <button class="app-btn app-btn--success" [disabled]="!stagedZipCodes.length ? true : null" (click)="addStagedZipCodes()">ADD {{ stagedZipCodes.length + (stagedZipCodes.length === 1 ? ' ITEM' : ' ITEMS') }}</button> 
        </div>
      </div>

      <div class="zone-selections__con">
        <label>Current Zip Codes</label>
        <div class="zone-selections__con__list" [ngClass]="{'loading-mask': (zipCodesLoading$ | async), 'zone-selections__con__list--disabled': operation === myConstants.operationTypeDetails}">
          <ul class="zone-selections__con__list__ul" *ngIf="(zipCodes$ | async)">
            <li class="zone-selections__con__list__ul__li" *ngFor="let item of (zipCodes$ | async)">
              <div class="zone-selections__con__list__ul__li__txt" [ngClass]="{'zone-selections__con__list__ul__li__txt--selected': isSelected(item.zipCodeName, false), 'zone-selections__con__list__ul__li__txt--disabled': operation === myConstants.operationTypeDetails}" (click)="stageZipCode(item, false)">
                {{ (item.zipCodeName + ' ' + item.cityName + ', ' + item.state) }}
              </div>
              <button class="app-btn-icon app-btn-icon--danger" title="Remove" (click)="removeZipCode(item)" *ngIf="operation !== myConstants.operationTypeDetails">
                <i class="far fa-times"></i>
              </button>
            </li>
          </ul>
        </div>
        <div class="app-btn-box zone-selections__con__btn" *ngIf="operation !== myConstants.operationTypeDetails">
          <button class="app-btn app-btn--danger" [disabled]="!removedZipCodes.length ? true : null" (click)="removeStagedZipCodes()">REMOVE {{ removedZipCodes.length + (removedZipCodes.length === 1 ? ' ITEM' : ' ITEMS') }}</button>
        </div>
      </div>

    </div>
  </form>
  <fw-crud-buttons [operation]="operation" [disabled]="!isValid()" [showNext]="false" (cancelEvent)="onCancel()" (createEvent)="onCreate()" (saveEvent)="onSave($event)"></fw-crud-buttons>
</fw-dynamic-detail-container>

