<div class="month-controls">
  <button class="month-controls__btn" [disabled]="!scrollInc" (click)="changeMonth(-1)">
    <i class="fas fa-chevron-circle-left"></i>
  </button>
  <div class="month-controls__label">{{ month | date:"MMM" }}</div>
  <button class="month-controls__btn" [disabled]="scrollInc === scrollIdxs[scrollIdxs.length-1]" (click)="changeMonth(1)">
    <i class="fas fa-chevron-circle-right"></i>
  </button>
</div>
<div class="schedule-calendar">
  <div class="schedule-calendar__header">
    <div class="schedule-calendar__header__day">S</div>
    <div class="schedule-calendar__header__day">M</div>
    <div class="schedule-calendar__header__day">T</div>
    <div class="schedule-calendar__header__day">W</div>
    <div class="schedule-calendar__header__day">T</div>
    <div class="schedule-calendar__header__day">F</div>
    <div class="schedule-calendar__header__day">S</div>
  </div>
  <div #scrollCon class="schedule-calendar__days" (scroll)="onScroll(scrollCon)">
    <div class="schedule-calendar__days__start" [ngStyle]="{'width': 14.275 * firstDay + '%'}"></div>
    <div *ngFor="let day of days; let i = index;" title="Click to Select Day" class="schedule-calendar__days__day" [ngClass]="{'schedule-calendar__days__day--shaded': pullLength === 7 && (i >= idx && i <= idx + 6)}">
      <div class="schedule-calendar__days__day__inner" [ngClass]="{'schedule-calendar__days__day__inner--active': day.date === selectedDay.date}" (click)="selectDay(day, i)">
        <div *ngIf="durationType !== 'Days' && day.availability" class="schedule-calendar__days__day__inner__shading">
          <div class="schedule-calendar__days__day__inner__shading__half {{ day.amCssName }}"></div>
          <div class="schedule-calendar__days__day__inner__shading__half {{ day.pmCssName }}"></div>
        </div>
        <div *ngIf="!i || (day.date | date:'d') == '1'" class="schedule-calendar__days__day__inner__month">{{ day.date | date:"MMM" }}</div>
            {{ day.date | date:"d" }}
        <div *ngIf="durationType === 'Days'" class="schedule-calendar__days__day__inner__indicator {{ day.dayCssName }}"></div>
      </div>
    </div>
  </div>
</div>
<div class="schedule-calendar-legend">
  <fw-legend [title]="'Color Legend'"
             [items]="legendItems">
  </fw-legend>
</div>

