
export enum NoteEvent {
  getPagedData = 'getPagedData',
  getPurchaseOrders = 'getPurchaseOrders',
  getWorkOrders = 'getWorkOrders',
  editNote = 'editNote',
  changeNotePrivacy = 'changeNotePrivacy',
  deleteConfirm = 'deleteConfirm',
  deleteCancel = 'deleteCancel',
  deleteNote = 'deleteNote',
  closeDetail = 'closeDetail',
  viewNotifications = 'viewNotifications',
  postIt = 'postIt', //project special instructions
  sendReply = 'sendReply',
  addRecipient = 'addRecipient',
  removeRecipient = 'removeRecipient',
  changeRecipientNotification = 'changeRecipientNotification'
}

export enum NoteAction {
  fromList = 'fromList',
  fromNote = 'fromNote',
  fromReply = 'fromReply',
}

export enum NoteContext {
  contact = 'contact',
  workOrder = 'workOrder',
  purchaseOrder = 'purchaseOrder',
  project = 'project'
}

export enum NoteReplyLength {
  internalMax = 4000,
  externalMax = 3950,
  unassignedMax = 200
}

export enum NoteSubjectDefault {
  entityDocumentDelete = 'Deleting Required Document'
}
