
export interface IWorkOrderSurvey {
  workOrderSurveyId: number;
  workOrder_workOrderId: number;
  survey_surveyId: number;
  surveyDate: string;
  surveyComments: string;
  surveyLocked: boolean;
  //extra data
  assignedWorkOrderId: number;
  identifierId: string;
  identifierEntityName: string;
  identiferMetaData: string;

}

export class WorkOrderSurvey implements IWorkOrderSurvey {
  workOrderSurveyId: number;
  workOrder_workOrderId: number;
  survey_surveyId: number;
  surveyDate: string;
  surveyComments: string;
  surveyLocked: boolean;
  //extra data
  assignedWorkOrderId: number;
  identifierId: string;
  identifierEntityName: string;
  identiferMetaData: string;

  constructor() {
    this.workOrderSurveyId = 0;
    this.workOrder_workOrderId = null;
    this.survey_surveyId = null;
    this.surveyDate = '';
    this.surveyComments = '';
    this.surveyLocked = false;
  }
}
