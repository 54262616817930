  <fw-dynamic-detail-container *ngIf="(note$ | async) as note" [detailConfig]="detailConfig$ | async">
  <note-list-item [note]="note"
                  [isProjectNote]="true"
                  [isPoImportNote]="isPoImportNote"
                  (postItEvent)="onChangePostIt($event)"
                  (replyEvent)="onSendReply($event)">
  </note-list-item>
  <!--<fw-error-box *ngIf="invalid" [errors]="errorMessage"></fw-error-box>-->
  <div class="app-btn-box">
    <button type="button" class="app-btn app-btn--cancel" (click)="onCancel()">Cancel</button>
  </div>
</fw-dynamic-detail-container>
