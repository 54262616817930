export interface IPurchaseOrderItem {
  purchaseOrderItemId: number;   
  purchaseOrder_purchaseOrderId: number;
  sku_skuId: number;
  skuDeck_skuDeckId: number;
  unitMeasure_unitMeasureId: number;
  itemType_lookupValueId: number;
  itemName: string;
  quantity: number;
  unitPrice: number;
  overrideSystemPrice: boolean;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;

  //extra data
  itemType: string;
  isReceived: boolean;
  workOrderId: number;
  purchaseOrderNumber: number;
  projectId: number;
  measuerDescription: string;
  purchaseOrderInventoryAllocationId: number;
  vendorPoNumber: string;
  manufacturerName: string;
  estimatedDeliveryDate: string;
  isAllocated: boolean;
}


export class PurchaseOrderItem implements IPurchaseOrderItem {
  purchaseOrderItemId: number;
  purchaseOrder_purchaseOrderId: number;
  sku_skuId: number;
  skuDeck_skuDeckId: number;
  unitMeasure_unitMeasureId: number;
  itemType_lookupValueId: number;
  itemName: string;
  quantity: number;
  unitPrice: number;
  overrideSystemPrice: boolean;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;

  //extra data
  itemType: string;
  isReceived: boolean;
  workOrderId: number;
  purchaseOrderNumber: number;
  projectId: number;
  measuerDescription: string;
  purchaseOrderInventoryAllocationId: number;
  vendorPoNumber: string;
  manufacturerName: string;
  estimatedDeliveryDate: string;
  isAllocated: boolean;

  constructor() {
    this.purchaseOrderItemId = 0;
    this.purchaseOrder_purchaseOrderId = null;
    this.sku_skuId = null;
    this.skuDeck_skuDeckId = null;
    this.itemType = '';
    this.unitMeasure_unitMeasureId = null;
    this.itemType_lookupValueId = null;
    this.itemName = '';
    this.quantity = 0;
    this.unitPrice = 0;
    this.overrideSystemPrice = false;
    this.isAllocated = false;
    this.estimatedDeliveryDate = null;
    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';

  }

}

