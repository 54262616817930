import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { AdminEvent, AdminStore } from '../../enums/admin.enums';
import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IProviderRole } from '../../../view-models';
import { UserPriviledgesService } from '../../../../../auth/services';
import { AdminSecurityService } from '../../services/admin-security.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'roles',
  changeDetection: ChangeDetectionStrategy.OnPush,
 templateUrl: './roles.component.html'
})
export class RolesComponent implements OnInit {
  public listDefinition: IListDefinition;
  params: IDetailRouteParameter;

  constructor(public activeRoute: ActivatedRoute,
    public userPriviledgesService: UserPriviledgesService,
    public securityService: AdminSecurityService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.manageRoleWidgets:
        this.openRoleWidgets(event.data);
        break;
      case AdminEvent.manageRoleUsers:
        this.openRoleUsers(event.data);
        break;
      case AdminEvent.manageRolePrivilegeTemplates:
        this.openRolePrivilegeTemplates(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.params = {
      rowIndex: -1,
      storeName: AdminStore.providerRoles,
      key: 'providerRoleId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: -1
    }

   this.listDefinition = this.securityService.loadProvideRolesListDefinition();
  }

  openRoleWidgets(providerRole: IProviderRole): void {
    this.modalService.open({
      title: 'Widgets for Role'.concat(' ', providerRole.roleName),
      path: 'admin-role-widgets',
      id: providerRole.providerRoleId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }

  openRoleUsers(providerRole: IProviderRole): void {
    this.modalService.open({
      title: 'Users for Role'.concat(' ', providerRole.roleName),
      path: 'admin-role-users',
      id: providerRole.providerRoleId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }

  openRolePrivilegeTemplates(providerRole: IProviderRole): void {
    this.modalService.open({
      title: 'Privilege Templates for Role'.concat(' ', providerRole.roleName),
      path: 'admin-role-privilege-templates',
      id: providerRole.providerRoleId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }
}
