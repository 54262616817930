import { Component, Input,  OnInit, OnDestroy, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import {  filter, map, take, tap  } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IErrorData, IResponseBase, IPageMetaData } from '../../../../../shared/interfaces/index';
import {  IProviderUser, INoteViewModel, IWorkOrder, IPurchaseOrder } from '../../../../portals/view-models/index';

import { INote, Note, INotification } from '../../../view-models/index';
import { IListFilter, IListDefinition, ListFilter } from '../../../../../fw/dynamic-list/interfaces/index';
import { IFieldDefinition } from '../../../../../fw/dynamic-forms/index';
import { UpdateObjectByIdModel, CreateObjectModel, DeleteObjectModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { DomainObjectService } from '../../../../../shared/services/domain-object.service'
import { ProjectService } from './../../../project/services/project.service';
import { NoteService } from '../../services';

import * as LoadingIndicatorActions from '../../../../../shared/store/loadingIndicator/loadingIndicator.actions';

//store actions and reducers
import * as fromRoot from '../../../../store/reducers/index';
import * as  fromSelectionLists from  '../../../../../shared/store/selectionLists/index';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { listDataExists, getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import {  metaDataExists } from '../../../../../fw/dynamic-list/store/selectors/meta-data.selectors';
import { IListObjectData, IDynamicListState } from '../../../../../fw/dynamic-list/store';
import { cloneDeep } from 'lodash';
import { NoteEvent, NoteContext } from '../../enums/note.enums';
import { INoteConfigFields, INoteReply } from '../../interfaces';
import { AccessLevel } from '../../../../../fw/dynamic-list/enums/access-level.enums';
import { ProviderUserService } from '../../services/provider-user.service';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists/index';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'notes-manager',
  templateUrl: './notes-manager.component.html'
})
export class NotesManagerComponent implements OnInit, OnDestroy {
  @Input() listDefinition: IListDefinition;
  @Input() startAction: string = '';
  //when inserting a note for a contact (from project portal), po or work order, will have to set the value of project as well, woId/poId/contactId will be set in listDef
  @Input() canIEdit: boolean = true;
  @Input() portalId: number;
  @Input() grandParentKey: string = '';
  @Input() grandParentId: number = 0;
  @Input() contactId: number = 0;
  @Input() hasExternalInterface: boolean = false;

  public loading$: Observable<boolean>;
  public providerUsers$: Observable<IProviderUser[]>;
  public notes$: Observable<INote[]>;
  public pageMetaData$: BehaviorSubject<IPageMetaData> = new BehaviorSubject(null);
  public metaDataExists$: Observable<boolean>;
  public fieldDefinitions$: Observable<IFieldDefinition[]>;
  public operation$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorNoteId$: BehaviorSubject<number> = new BehaviorSubject(0);
  public errorAction$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  public errorMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  public activeNote$: BehaviorSubject<INote> = new BehaviorSubject(null);
  public activeNotifications$: BehaviorSubject<INotification[]> = new BehaviorSubject([]);
  public pos$: BehaviorSubject<IPurchaseOrder[]> = new BehaviorSubject([]);
  public wos$: BehaviorSubject<IWorkOrder[]> = new BehaviorSubject([]);
  public noteConfigFields: INoteConfigFields;
  public noteContext: string;
  public newNote: INote = new Note();
  //must define enums as local member to use in html
  public contextProject: string = '';

  allNotifications: INotification[] = [];
  activeDelete: boolean = false;
  completedRouteRequests: boolean = false;
  subscription: Subscription = new Subscription();
  woSub: Subscription;
  poSub: Subscription;

  constructor(
    public rootStore: Store<fromRoot.IState>,
    public  store: Store<fromFeature.IAllDynamicData>,
    public  domainObjectService: DomainObjectService,
    public  projectService: ProjectService,
    public noteService: NoteService,
    public providerUserService: ProviderUserService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  //get fresh data from server
  public dispatchGet(pageNumber: number): void {
    let listFilter = cloneDeep(this.listDefinition.defaultListFilter);
    if (pageNumber > 0) {
      listFilter.currentPage = pageNumber;
    }
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: this.listDefinition, listFilter: listFilter, parentId: this.listDefinition.parentId }));
  }

  public addNote() {
    this.activeNote$.next(new Note());
    this.operation$.next(this.myConstants.operationTypeCreate);
  }

  public print(): void {
    //can not print user notes, so only applicable for notes associated with project.
    const projectId: number = this.grandParentId !== 0 ? this.grandParentId : this.listDefinition.parentId;
    const printUrl = '/Api/Note/PrintProjectNotes/'.concat(projectId.toString());
    var win = window.open(printUrl, '_blank');
    win.focus();
  }


  public onNoteEvent(e: IHomEventEmitter): void {
    switch (e.event) {
      case this.myConstants.emitterEventUpdate:
        this.updateRecord(e.data, e.action);
        break;
      case this.myConstants.emitterEventCreate:
        this.createRecord(e);
        break;
      case this.myConstants.emitterEventClose:
        this.closeDetail();
        break;
      case NoteEvent.getPurchaseOrders:
        this.getPos();
        break;
      case NoteEvent.getWorkOrders:
        this.getWos();
        break;
      default:
        break;
    }
  }

  public onNoteListEvent(e: IHomEventEmitter): void {
    switch (e.event) {
      case NoteEvent.getPagedData:
        if (Number(e.data)) {
          this.dispatchGet(e.data);
        }
        break;
      case NoteEvent.editNote:
        this.editNote(e.data);
        break;
      case NoteEvent.changeNotePrivacy:
        this.changeNotePrivacy(e);
        break;
      case NoteEvent.deleteConfirm:
        this.deleteConfirm(e.data);
        break;
      case NoteEvent.deleteCancel:
        this.deleteCancel();
        break;
      case NoteEvent.deleteNote:
        this.deleteNote(e.data);
        break;
      case NoteEvent.closeDetail:
        this.closeDetail();
        break;
      case NoteEvent.viewNotifications:
        this.viewNotifications(e.data);
        break;
      case NoteEvent.postIt:
        this.changeNotePostIt(e);
        break;
      case NoteEvent.sendReply:
        this.sendExternalReply(e);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.completedRouteRequests = false;
    this.contextProject = NoteContext.project;
    this.pos$.next([]);
    this.wos$.next([]);
    this.noteConfigFields = {
      isProjectNote: this.portalId === 1,
      storeName: this.listDefinition.storeName,
      parentKey: this.listDefinition.parentKey,
      parentId: this.listDefinition.parentId,
      grandParentKey: this.grandParentKey,
      grandParentId: this.grandParentId,
      contactId: this.contactId,
      hasExternalInterface: this.hasExternalInterface
    }
    this.noteContext = this.noteService.findContext(this.noteConfigFields);
    this.handleStartAction();
    this.loading$ = this.rootStore.select('loadingIndicator').pipe(filter(x => x.requestor === this.listDefinition.storeName), map(x => x.show),
      tap(x => {
        if (!x && !this.completedRouteRequests) {
          this.handleStartAction();
        }
      }));

    //check to see if need to load provider users to cached store
    this.subscription.add(this.rootStore.select(fromSelectionLists.listExistsByType(this.providerUserService.storeName))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe((exists) => {
        this.rootStore.dispatch(new fromSelectionLists.GetEntityList('ProviderUser', this.providerUserService.defaultListFilter));
      }));

    //listen for provider users
    this.providerUsers$ = this.rootStore.select(fromSelectionLists.getSelectionListDataByType(this.providerUserService.storeName));

    //listen for errors and events
    this.subscription.add(this.store.pipe(select(getListByType(this.listDefinition.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.listDefinition.parentId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          if (state.event && (!state.errorData || !state.errorData.length)) {
            if (state.event.event === this.myConstants.emitterEventListReload) {
              this.dispatchGet(0);
            }

            this.store.dispatch(new DynamicListActions.ClearEventList({ storeName: this.listDefinition.storeName, parentId: this.listDefinition.parentId }));
            if (this.grandParentId > 0 && this.grandParentKey) {
              this.dispatchGrandParentGet();
            }
           
            this.closeDetail();
          } else if (this.activeDelete && !state.errorData.length) {
            this.activeDelete = false;
            this.closeDetail();
          }
          this.errorAction$.next(!state.errorData || !state.errorData.length ? '' : state.event.action);
          this.errorNoteId$.next(!state.errorData || !state.errorData.length ? 0 : state.event.data && state.event.data.hasOwnProperty('noteId') ? state.event.data.noteId : 0);
          
          this.errorData$.next(cloneDeep(state.errorData));
          this.errorMessage$.next(state.message);
        }
      }));

    //check to see if need to go get data
    this.metaDataExists$ = this.store.select(metaDataExists(this.listDefinition.storeName));


    this.subscription.add(this.store.select(listDataExists(this.listDefinition.storeName, this.listDefinition.parentId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe((exists) => {
        this.store.dispatch(new DynamicListActions.SetListDefinition({ storeName: this.listDefinition.storeName, parentId: this.listDefinition.parentId, listDefinition: this.listDefinition }));
        this.dispatchGet(0);
      }));

    //listening for notes
    this.notes$ = this.store.pipe(select(getListByType(this.listDefinition.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.listDefinition.parentId)),
        tap((state) => {
          this.pageMetaData$.next(state && state.listMetaData ? state.listMetaData.pageMetaData : null);
        }),
        map(objData => objData ? objData.data : null)
      );
  }


  dispatchGrandParentGet(): void {
    if (this.grandParentKey.endsWith('projectId')) {
      const projectNotesListDef = this.projectService.loadProjectNoteListDefinition(this.grandParentId, this.dynamicListService.hasEditBtn(this.listDefinition));
      this.store.dispatch(new DynamicListActions.GetList({ listDefinition: projectNotesListDef, listFilter: projectNotesListDef.defaultListFilter, parentId: this.grandParentId }));
    }
  }

  handleStartAction(): void {
    if (this.startAction && !this.completedRouteRequests) {
      switch (this.startAction) {
        case this.myConstants.routeActionAdd:
          this.completedRouteRequests = true;
            this.addNote();
          break;
        default:
          this.completedRouteRequests = true;
          break;
      }
    }
  }

  viewNotifications(note: INote) {
    if (!note) {
      return;
    }
    const notifications: INotification[] = this.allNotifications.filter(x => x.note_noteId === note.noteId);
    if (notifications && notifications.length) {
      //notification information already loaded, no need for retrieval
      this.activeNote$.next(note);
      this.activeNotifications$.next(notifications);
      return;
    }

    this.activeNote$.next(note);
    this.loadNotifications(note.noteId);
  }

  loadNotifications(noteId: number): void {

    this.activeNotifications$.next(null);
      this.rootStore.dispatch(new LoadingIndicatorActions.ShowSpinner({ requestor: this.listDefinition.storeName, id: 0 }));
      const url = `/Api/Notification/ByNote/${noteId}`;

      this.subscription.add(this.domainObjectService.getListByMetaUrl(url)
        .subscribe((response: IResponseBase) => {
          let result = cloneDeep(response);
          if (result.success) {
            this.activeNotifications$.next(result.data);
            this.allNotifications.push(result.data);
          } else {
;            this.errorData$.next(result.errorData);
          }
          this.rootStore.dispatch(new LoadingIndicatorActions.HideSpinner({ requestor: this.listDefinition.storeName, id: 0 }));
        },
          (error: any) => { this.errorMessage$.next(error); }
        ));
  }

  editNote(note: INote) {
    this.operation$.next(this.myConstants.operationTypeSingleEdit);
    this.activeNote$.next(note);
  }

  changeNotePrivacy(e: IHomEventEmitter): void {
    let note: INote = cloneDeep(e.data);
    note.isPrivate = !note.isPrivate;
    note.updateDate = new Date().toDateString();
    this.updateRecord(note, e.action);
  }

  changeNotePostIt(e: IHomEventEmitter): void {
    let note: INote = cloneDeep(e.data);
    note.postIt = !note.postIt;
    note.updateDate = new Date().toDateString();
    this.updateRecord(note, e.action);
  }

  sendExternalReply(e: IHomEventEmitter): void {
    const reply: INoteReply = cloneDeep(e.data);
    const data: any = { noteId: reply.noteId, noteReplyText: reply.reply };
    const emitter: IHomEventEmitter = { requestor: 'note', event: this.myConstants.emitterEventListReload, action: '', data: null  };
    const keyData: IKey = { storeName: this.listDefinition.storeName, parentId: this.listDefinition.parentId, key: this.listDefinition.rowKeyId, id: reply.noteId, skipDataUpdate: true }
    const updateData = new UpdateObjectByIdModel(keyData, this.listDefinition.controllerName, 'Acknowledge', this.listDefinition.rowKeyId, reply.noteId, data, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectByIdList({ updateData }));
  }

  deleteConfirm(note: INote): void {
    this.operation$.next(this.myConstants.operationTypeDelete);
    this.activeNote$.next(note);
  }

  deleteCancel(): void {
    this.operation$.next('');
  }

  deleteNote(url: string) {
    const note = this.activeNote$.getValue();
    if (url && url.length > 0) {
      this.activeDelete = true;
      const keyData: IKey = { storeName: this.listDefinition.storeName, parentId: this.listDefinition.parentId, key: this.listDefinition.rowKeyId, id: note.noteId }
      const deleteData = new DeleteObjectModel(keyData, url);
      this.store.dispatch(new DynamicListActions.DeleteObjectByUrlList({ deleteData }));
    }
  }

  closeDetail() {
    this.operation$.next('');
    this.activeNote$.next(null);
    this.activeNotifications$.next(null);
  }

  createRecord(e: IHomEventEmitter) {
    let vm: INoteViewModel = cloneDeep(e.data);
    //set emitter event to refresh list after create
    const emitter: IHomEventEmitter = {
      requestor: 'note', event: this.myConstants.emitterEventListReload, action: e.action, data: { noteId: 0 } };
    const createData = new CreateObjectModel(this.listDefinition.storeName, this.listDefinition.parentId, this.listDefinition.controllerName, 'Create', vm, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  updateRecord(note: INote, action: string) {
    const emitter: IHomEventEmitter = { requestor: 'note', event: this.myConstants.emitterEventUpdate, action: action, data: { noteId: note.noteId } };
    const keyData: IKey = { storeName: this.listDefinition.storeName, parentId: this.listDefinition.parentId, key: this.listDefinition.rowKeyId, id: note.noteId }
    const updateData = new UpdateObjectByIdModel(keyData, this.listDefinition.controllerName, 'Update', this.listDefinition.rowKeyId, note.noteId, note, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectByIdList({ updateData }));
  }

  getPos(): void {
    let listFilter: IListFilter = new ListFilter();
    listFilter.isLookup = true;
    listFilter.getAll = true;

    if (this.poSub) {
      this.poSub.unsubscribe();
    }
    this.poSub = this.rootStore.pipe(select(getSelectionListDataByType('purchaseOrder', this.grandParentId !== 0 ? this.grandParentId : this.listDefinition.parentId)))
      .subscribe((data) => {
        if (data) {
          this.pos$.next(data);
        } else {
          this.store.dispatch(new fromSelectionLists.GetEntityListById('PurchaseOrder', 'ByProject', this.grandParentId !== 0 ? this.grandParentId : this.listDefinition.parentId, listFilter));
        }
      });
  }

  getWos(): void {
    let listFilter: IListFilter = new ListFilter();
    listFilter.isLookup = true;
    listFilter.getAll = true;

    if (this.woSub) {
      this.woSub.unsubscribe();
    }
    this.woSub = this.rootStore.pipe(select(getSelectionListDataByType('workOrder', this.grandParentId !== 0 ? this.grandParentId : this.listDefinition.parentId)))
      .subscribe((data) => {
        if (data) {
          this.wos$.next(data);
        } else {
          this.store.dispatch(new fromSelectionLists.GetEntityListById('WorkOrder', 'ByProject', this.grandParentId !== 0 ? this.grandParentId : this.listDefinition.parentId, listFilter));
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.poSub) {
      this.poSub.unsubscribe();
    }
    if (this.woSub) {
      this.woSub.unsubscribe();
    }
  }

}
