import { Component, OnInit, Input, Inject, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { cloneDeep, orderBy } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IWorkOrder, ISchedule, Schedule, IScheduleAvailabilityViewModel } from '../../../view-models';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IErrorData, IResponseBase } from '../../../../../shared/interfaces';
import {  ScheduleEvent, ScheduleStore, DurationType } from '../../enums/schedule.enums';
import { metaDataExists, IAllDynamicData, GetFieldDefinitions, GetMetaData, listDataExists, SetListDefinition, GetList } from '../../../../../fw/dynamic-list/store/index';
import { IScheduleInstallerWorkCrewAvailabilityViewModel } from '../../../view-models/i-schedule-installer-work-crew-availability-view-model';
import { IRequestAvailabilityEvent, IScheduleSaveEvent, IInstallerSortEvent } from '../../interfaces';
import { IScreenBreakpoints } from '../../../../../fw/fw-shared/services/i-screen-breakpoints';
import { UserPriviledgesService } from '../../../../../auth/services';
import { SchedulerService } from '../../services/scheduler.service';
import { DomainObjectService, HomDataUtility } from '../../../../../shared/services';
import { ScreenService } from '../../../../../fw/fw-shared/services/screen.service';

@Component({
  selector: 'schedule-manager',
  templateUrl: './schedule-manager.component.html'
})
export class ScheduleManagerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() workOrder: IWorkOrder;
  @Input() operation: string = '';

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public canIEdit$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIDelete$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public cannotSchedule: boolean;
  public selectedSchedule: ISchedule;
  public moveUpVisible: boolean;
  public activeScheduleId: number = 0;
  public schedules$: BehaviorSubject<ISchedule[]> = new BehaviorSubject([]);
  public activeSchedule$: BehaviorSubject<ISchedule> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public availability$: BehaviorSubject<IScheduleAvailabilityViewModel[]> = new BehaviorSubject(null); 
  public availableInstallers$: BehaviorSubject<IScheduleInstallerWorkCrewAvailabilityViewModel[]> = new BehaviorSubject(null)
  public screenIsSmall$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  moveUpsLoaded: boolean = false;
  title: string = 'Schedule(s) for Work Order'; 
  subscription: Subscription = new Subscription();

  constructor(public store: Store<IAllDynamicData>,
    public userPriviledgesService: UserPriviledgesService,
    public scheduleService: SchedulerService,
    public domainObjectService: DomainObjectService,
    public homDataUtility: HomDataUtility,
    public screenService: ScreenService,
    public el: ElementRef,
    @Inject(appConstants) public myConstants: IAppConstants  ) { }

/* Clicked on create new schedule button */
  public createSchedule(): void {
    this.operation = this.myConstants.operationTypeCreate;
  }

  /* Handle custom events from here */
  public onCustom(eventIn: IHomEventEmitter) {
    let event: IHomEventEmitter = cloneDeep(eventIn);
    switch (event.event) {
      case ScheduleEvent.editSchedule:
        this.setActiveSchedule(event.data);
        this.operation = this.myConstants.operationTypeEdit;
        break;
      case ScheduleEvent.showMoveUp:
        if (!this.moveUpsLoaded) {
          this.prepMoveUp();
        }
        this.setActiveSchedule(event.data);
        this.moveUpVisible = true;
        break;
      case ScheduleEvent.moveUpUpdateSuccessful:
        this.moveUpVisible = false;
        this.activeSchedule$.next(null);
        this.getSchedules();
        this.loadMoveups();
        break;
      case ScheduleEvent.cancelEdit:
      case ScheduleEvent.cancelMoveUp:
        this.activeSchedule$.next(null);
        this.moveUpVisible = false;
        this.operation = this.myConstants.operationTypeDetails;
        this.errorData$.next([]);
        break;
      case ScheduleEvent.deleteSchedule:
        this.deleteRecord(event.data);
        break;
      case ScheduleEvent.saveSchedule:
        this.saveSchedule(event.data);
        break;
      case ScheduleEvent.getAvailableCrews:
        this.getAvailableInstallers(event.data);
        break;
      case ScheduleEvent.getAvailableSlots:
        this.getAvailableSlots(event.data);
        break;
      case ScheduleEvent.resetAvailability:
        this.availability$.next(null);
        this.availableInstallers$.next(null);
        break;
      case ScheduleEvent.installerSort:
        this.sortInstallers(event.data);
        break;
      default:
        console.log('DEV ERR: onCustom and this event is not defined');
        break;
    }
  }

  ngOnInit(): void {
    this.newRequest();
    this.subscription.add(this.activeSchedule$.subscribe((schedule: ISchedule) => this.activeScheduleId = schedule ? schedule.scheduleId : 0));

    this.subscription.add(this.screenService.resize$
      .subscribe((e: IScreenBreakpoints) => {
        if (this.screenService) {
          this.setSize();
        }
      })
    );

  }
  
  ngOnChanges(changes: SimpleChanges) {
    if ((changes['workOrder'] && !(changes['workOrder'].firstChange))
      || (changes['operation'] && !(changes['operation'].firstChange))) {
      this.operation = this.myConstants.operationTypeDetails;
      this.newRequest();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  newRequest(): void {
    this.title = 'Schedule(s) for Work Order '.concat(this.workOrder.workOrderId.toString());
    if (!this.operation || (this.operation === this.myConstants.operationTypeCreate && this.workOrder.notSchedulableReason) ) {
      this.operation = this.myConstants.operationTypeDetails;
    }
    this.canIEdit$.next(this.userPriviledgesService.canIEdit(this.workOrder) && this.scheduleService.canSchedule(this.workOrder));
    this.canIDelete$.next(this.userPriviledgesService.canIDelete(this.workOrder) && this.scheduleService.canSchedule(this.workOrder));
    if (this.operation === this.myConstants.operationTypeCreate) {
      this.haveMetaData();
      //need to get meta data
    } else {
      this.getSchedules();
    }
    this.setSize();
  }

  haveMetaData(): void {
    //check to see if we have meta data, if not retrieve
    this.subscription.add(this.store.select(metaDataExists(ScheduleStore.schedules))
      .pipe(take(1))
      .subscribe((exists: boolean) => {
        if (!exists) {
          this.store.dispatch(new GetMetaData({ storeName: ScheduleStore.schedules, url: 'Entity/GetEntityMetaData?entityName=Schedule', setListMetaData: false })); //will get and set field Definitions
        }
      }));
  }

  getSchedules(): void {
    this.working$.next(true);
    this.errorData$.next([]);
    this.subscription.add(this.domainObjectService.getByMethodById('Schedule', 'ByWorkOrder', this.workOrder.workOrderId)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          const metaData = response.hasOwnProperty('metaData') ? response.metaData : response.data.hasOwnProperty('metaData') ? response.data.metaData : {};
          this.store.dispatch(new GetFieldDefinitions({ storeName: ScheduleStore.schedules, metaData: metaData })); //will get and set field Definitions
          this.schedules$.next(this.sortSchedules(response.data));
        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      }));
  }


  getAvailableSlots(request: IRequestAvailabilityEvent): void {
    this.working$.next(true);
    this.subscription.add(this.scheduleService.getAvailableSlots(request)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          if (response.data.length > 0) {
            this.availability$.next(response.data);
          } else {
            this.availableInstallers$.next(null);
            this.availability$.next(null);
          }
          this.errorData$.next([]);
        } else {
          this.availability$.next(null);
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      }));
  }

  getAvailableInstallers(request: IRequestAvailabilityEvent): void {
    //distinct working to handle double hits for getting availability and installers
    this.subscription.add(this.scheduleService.getAvailableInstallers(request)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.availableInstallers$.next(response.data);
          this.errorData$.next([]);
        } else {
          this.availableInstallers$.next(null);
          this.errorData$.next(response.errorData);
        }
      }));
  }

  // In memory sort
  sortInstallers(request: IInstallerSortEvent): void {
    const temp: IScheduleInstallerWorkCrewAvailabilityViewModel[] = this.availableInstallers$.getValue();
    this.availableInstallers$.next(orderBy(temp, [request.sortBy], [request.sortAsc ? 'asc' : 'desc']));
  }

  sortSchedules(data: ISchedule[]): ISchedule[] {
    return orderBy(data, 'scheduleStartDate', ['asc']);
  }

  deleteRecord(scheduleId: number): void {
    const schedule: ISchedule = this.schedules$.getValue().find(x => x.scheduleId == scheduleId);
    const deleteUrl: string = this.userPriviledgesService.getDeleteUrl(schedule);
    if (schedule && deleteUrl) {
      this.working$.next(true);
      this.subscription.add(this.domainObjectService.delete(deleteUrl)
        .subscribe((response: IResponseBase) => {
          if (response.success) {
            this.getSchedules();
            this.customEvent.emit({ requestor: 'schedule-manager', event: ScheduleEvent.deleteSuccessful, action: '', data: null });
        } else {
            this.errorData$.next(response.errorData);
          }
          this.working$.next(false);
        }));
    }
  }

  setActiveSchedule(scheduleId: number): void {
    this.activeSchedule$.next(this.schedules$.getValue().find(x => x.scheduleId == scheduleId));
  }

  prepMoveUp(): void {
    this.subscription.add(this.store.select(listDataExists(ScheduleStore.providerLocationScheduleMoveups, this.workOrder.workOrderId))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe(() => {
        this.loadMoveups();
      }));

    this.subscription.add(this.store.select(listDataExists(ScheduleStore.providerLocationScheduleMoveups, this.workOrder.workOrderId))
      .pipe(filter((exists: boolean) => exists === true), take(1))
      .subscribe(() => {
        this.moveUpsLoaded = true;
      }));
  }

  loadMoveups(): void {
    const listDefinition = this.scheduleService.loadScheduleMoveUpListDefinition(this.workOrder.workOrderId);
    this.store.dispatch(new SetListDefinition({ storeName: ScheduleStore.providerLocationScheduleMoveups, parentId: listDefinition.parentId, listDefinition: listDefinition }));
    this.store.dispatch(new GetList({ listDefinition: listDefinition, listFilter: listDefinition.defaultListFilter, parentId: listDefinition.parentId }));
  }

  setSize(): void {
    const myLeft: number = this.el.nativeElement.getBoundingClientRect().left;
    this.screenIsSmall$.next(  this.screenService.isSmall(myLeft));
  }

  saveSchedule(data: IScheduleSaveEvent): void {
    this.working$.next(true);
    let schedule: ISchedule = data.operation === this.myConstants.operationTypeCreate
      ? new Schedule(this.workOrder.project_projectId, this.workOrder.workOrderId, DurationType.days, 1)
      : cloneDeep(this.activeSchedule$.getValue());

    schedule.duration = data.duration;
    schedule.durationType = data.durationType;
    schedule.scheduleStartDate = data.scheduleStartDate;
    const installerWorkCrewIds: number[] = data.installerWorkCrewId > 0 ? [data.installerWorkCrewId] : [];

    this.errorData$.next([]);
    const methodName: string = this.operation === this.myConstants.operationTypeCreate ? 'Create' : 'Update';
    this.subscription.add(this.domainObjectService.createByMethod('Schedule', methodName, { 'model': schedule, 'installerWorkCrewIds': installerWorkCrewIds })
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.operation = this.myConstants.operationTypeDetails;
          this.activeSchedule$.next(null);
          this.getSchedules();
           //schedule-portal-manager listens this event
          this.customEvent.emit({ requestor: 'schedule-manager', event: ScheduleEvent.updateSuccessful, action: '', data: null });
        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      }));
  }
}
