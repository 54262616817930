<nav class="user-menu" *ngIf="frameworkConfigService.showUserControls">

  <!--appIcons set in app.component.ts-->   
  <ng-container *ngIf="hasValidSecurityAccess">
    <button type="button"
            class="app-btn-icon user-menu__item {{ (hasUnrespondedToContacts$ | async) ? 'app-btn-icon--warning' : 'app-btn-icon--white'}}"
            [title]="(hasUnrespondedToContacts$ | async) ? 'Unresponded To Contacts' : 'Conversations'"
            (click)="openConvoTab()">
      <i class="user-menu__item--icon fa fa-comments-alt"></i>
    </button>
    <ng-container *ngFor="let icon of frameworkConfigService.appIcons">
      <button *ngIf="icon.name !== 'userRecents'"
              class="app-btn-icon app-btn-icon--white user-menu__item"
              type="button"
              title="{{icon.alt}}"
              (click)="handleMenuClick(icon.selector, icon.alt)">
        <i class="user-menu__item--icon {{icon.icon}}"></i>
        <ng-container *ngIf="icon.name === 'userNotifications'">
          <span *ngIf="(openNotificationCount$ | async) > 0" class="user-menu__item--count">{{openNotificationCount$ | async}}</span>
        </ng-container>
        <ng-container *ngIf="icon.name === 'pendingChanges'">
          <span *ngIf="(pendingChangesCount$ | async) > 0" class="user-menu__item--count">{{pendingChangesCount$ | async }}</span>
        </ng-container>
      </button>
      <div *ngIf="icon.name === 'userRecents'">
        <ng-template #userRecents>
          <user-recents></user-recents>
        </ng-template>
        <button fw-popover [template]="userRecents" [closeOnClickOutside]="true" [showPopover]="userRecentsService.showPopover" (onHide)="hideUserRecents()"
                type="button"
                class="app-btn-icon app-btn-icon--white user-menu__item user-menu__item--stacked"
                title="{{ icon.alt }}"
                (click)="showUserRecents()">
          <i class="user-menu__item--icon {{icon.icon}} user-menu__item--icon--stacked"></i>
          <i class="user-menu__item--icon {{icon.icon}} user-menu__item--icon--stacked"></i>
        </button>
      </div>
    </ng-container>
  </ng-container>

  <span class="user-menu__user ">
    <i class="user-menu__user--icon fas" [ngClass]="{ 'fa-users user-menu__user--delegate ': (isDelegate$ | async), 'fa-user-circle' : (notDelegate$ | async) }"></i>
    <span class="user-menu__user--name">{{ currentUserName$ | async }}</span>
  </span>

  <button class="app-btn-icon app-btn-icon--white user-menu__item"
          type="button"
          (click)="onLogout()"
          title="Logout">
    <i class="user-menu__item--icon fas fa-sign-out-alt"></i>
  </button>

</nav>
