import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { PoImportEvent, PoImportStore } from '../../enums/po-import.enums';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { IDynamicListState, IListObjectData, getListByType, GetList } from '../../../../../fw/dynamic-list/store';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as fromRoot from '../../../../store/reducers/index';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import { IGeneralContractor } from '../../../view-models';
import { IPurchaseOrderDownload } from '../../interfaces/i-purchase-order-download';

import { DomainObjectService } from '../../../../../shared/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'po-download-import-manager',
  templateUrl: './po-download-import-manager.component.html'
})

export class PoDownloadImportManagerComponent implements OnInit, OnDestroy {
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public activePos$: BehaviorSubject<IPurchaseOrderDownload[]> = new BehaviorSubject(null);
  public generalContractorId: number;
  public generalContractorName: string;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromFeature.IAllDynamicData>,
    public rootStore: Store<fromRoot.IState>,
    public dos: DomainObjectService,
    public modalService: ModalService  ) { }


  ngOnInit(): void {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.generalContractorId = +paramMap.get('id');
      this.getGCName();
      this.loading$.next(false);
    }));
  }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case PoImportEvent.downloadAndImport:
        this.processDownloadAndImportRequest(event.data);
        break;
      case PoImportEvent.cancel:
        this.modalService.close();
        break;
      default:
        break;
    }
  }

  public onClose(): void {
    this.modalService.close();
  }

  getGCName(): void {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('generalContractor')))
      .subscribe((data) => {
        const gc: IGeneralContractor = data ? data.find(x => x.generalContractorId == this.generalContractorId) : 1;
        this.generalContractorName = gc ? gc.generalContractorName : '';
      }));

  }
  processDownloadAndImportRequest(purchaseOrderNumbers: string[]): void {
    let activePos: IPurchaseOrderDownload[] = [];
    purchaseOrderNumbers.forEach(x => {
      activePos.push({ purchaseOrderNumber: x, working: true, error: '' });
    });

    this.activePos$.next(activePos);

    if (!purchaseOrderNumbers || !purchaseOrderNumbers.length) {
      this.loading$.next(false);
      return;
    }

    //dos call, interrogate results, display
    this.loading$.next(true);
    this.errorData$.next([]);
    const params: string = this.generalContractorId + '?purchaseOrderNumbers=' + purchaseOrderNumbers;
    this.dos.updateByMethod('GeneralContractor', 'ImportPurchaseOrdersByPONumber', {
      generalContractorId: this.generalContractorId,
      purchaseOrderNumbers: purchaseOrderNumbers
    }).subscribe((response: IResponseBase) => {
      //if have 3 pos, and 1 fail, the response is error
      //determine if 1 succcedded and if so, reload list.

      let activePos: IPurchaseOrderDownload[] = cloneDeep(this.activePos$.getValue());
      activePos.forEach(x => {
        const errorData: IErrorData = response.success
          ? null
          : response.errorData.find(e => e.key.toLowerCase() === x.purchaseOrderNumber.toLowerCase());
        x.working = false;
        x.error = errorData ? errorData.value.join(',') : '';
      });
      this.activePos$.next(activePos);

      if (response.success || activePos.find(x => x.error === '') !== undefined) {
         this.reloadPoImportList();
      }
      this.loading$.next(false);
    });
  }

  reloadPoImportList(): void {
    this.subscription.add(this.store.pipe(select(getListByType(PoImportStore.poImportGroupedHeaders)), take(1))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == -1)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          const storeData = cloneDeep(state);
          this.store.dispatch(new GetList({
            listDefinition: storeData.listDefinition,
            listFilter: storeData.listFilter, parentId: storeData.parentId
          }));
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
