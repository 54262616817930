import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IDetailRequest } from '../../../fw/dynamic-detail/interfaces';
import { ContactStore, ContactEvent } from '../enums/contact.enums';
import { IListFilter, ListFilter } from '../../../fw/dynamic-list/interfaces';
import { IAddressLines, IAddressZips } from '../interfaces';

import * as fromStore from '../../../fw/dynamic-list/store/index';
import { GetObject } from '../../../fw/dynamic-list/store/index';
import { DynamicListService } from '../../../fw/dynamic-list/services';
import { ContactConstantsService } from './contact-constants.service';

@Injectable({
  providedIn: 'root'
})
export class ContactUtilityService   {

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public contactConstantsService: ContactConstantsService,
    public dynamicListService: DynamicListService) { }

  dispatchGetContact(contactId: number, reloadEvent: boolean = false): void {
    if (contactId && contactId > 0) {
      const objectRequest: IDetailRequest = {
        controllerName: 'Contact',
        storeName: ContactStore.contactInformation,
        rowKeyId: 'contactId',
        objectId: contactId,
        displayFields: [],
        metaUrl: '',
        controllerMethod: 'DetailsWithMetaData',
        methodParameters: contactId.toString(),
      };
      let event: IHomEventEmitter = null;
      if (reloadEvent) {
        event = { requestor: 'contact-manager', event: ContactEvent.reloadEvent, action: '', data: null };
      }
      this.store.dispatch(new GetObject({ detailRequest: objectRequest, event: event }));
    }
  }

  dispatchEntityData(mechanism: string, contactId: number): void {
    //set list definition
    const key: string = 'contactId';
    const storeName: string = this.getEntityStore(mechanism);
    let defaultListFilter: IListFilter = new ListFilter();
    let listDefinition = this.dynamicListService.createListDefinition('', this.getEntityLabel(mechanism),
      this.getEntityController(mechanism),
      storeName, 'contactMechanism_contactMechanismId',
      defaultListFilter, null, '');

    listDefinition.parentId = contactId;
    listDefinition.parentKey = key;
    listDefinition.controllerMethod = 'ByContact';
    listDefinition.methodParameters = contactId.toString();

    this.store.dispatch(new fromStore.SetListDefinition({ storeName: storeName, parentId: listDefinition.parentId, listDefinition: listDefinition }));
    this.store.dispatch(new fromStore.GetList({ listDefinition: listDefinition, listFilter: defaultListFilter, parentId: listDefinition.parentId }));
  }

  getEntityStore(mechanism: string): string {
    switch (mechanism) {
      case this.contactConstantsService.contactMechanismCategoryPhone:
        return ContactStore.phones;
      case this.contactConstantsService.contactMechanismCategoryEmail:
        return ContactStore.emails;
      case this.contactConstantsService.contactMechanismCategoryAddress:
        return ContactStore.addresses;
      default:
        return ContactStore.contactInformation;
    }
  }

  getEntityLabel(type: string): string {
    switch (type.toLowerCase()) {
      case 'phone':
        return 'Phones';
      case 'email':
        return 'Emails';
      case 'address':
        return 'Addresses';
      default:
        return '';
    }
  }

  getEntityController(type: string): string {
    switch (type.toLowerCase()) {
      case 'phone':
        return 'ContactMechanismPhone';
      case 'email':
        return 'ContactMechanismEmail';
      case 'address':
        return 'ContactMechanismAddress';
      default:
        return '';
    }
  }

  getEntityKey(mechanism: string): string {
    switch (mechanism) {
      case this.contactConstantsService.contactMechanismCategoryPhone:
        return 'contactMechanismPhoneId';
      case this.contactConstantsService.contactMechanismCategoryEmail:
        return 'contactMechanismEmailId';
      case this.contactConstantsService.contactMechanismCategoryAddress:
        return 'contactMechanismAddressId';
      default:
        return 'contactId';
    }
  }

  getParentType(contactTypeId: number, isOrganization: boolean, isPoImport: boolean): string {
    switch (contactTypeId) {
      case this.contactConstantsService.contactTypeIdBranch:
        return 'Branch';
      case this.contactConstantsService.contactTypeIdProviderLocation:
        return 'Provider Location';
      case this.contactConstantsService.contactTypeIdInstaller:
        return isOrganization ? 'Installer' : 'Technician';
      case this.contactConstantsService.contactTypeIdCustomer:
        return isPoImport ? 'PO' : 'Project';
      default:
        return '';
    }
  }

  //if lines[0] > 100 and lines[1] = '', set line1 to substring of 100 and line2 to the remaing up to 100, then line 3
  getAddressLines(value: string): IAddressLines {
    const lines: string[] = value.replace(/\r\n/g, "\r").replace(/\n/g, "\r").split(/\r/);
    return {
      line1: lines[0].length > 100 ? lines[0].substring(0, 99).trim() : lines[0].trim(),
      line2: lines.length <= 1 ? ''
        : lines[0].length > 100 ? lines[0].substring(100, 199).trim() //handle the need to split line 1 into 2 lines
          : lines.length > 1 && lines[1].length <= 100 ? lines[1].trim()
            : lines[1].length > 100 ? lines[1].substring(0, 99).trim()
              : '',
      line3: lines.length <= 2 ? ''
        : lines.length > 2 && lines[2].length <= 100 ? lines[2].trim()
          : lines[2].length > 100 ? lines[2].substring(0, 99).trim()
            : ''
    };
  }

  getAddressZips(value: string): IAddressZips {
    if (!value) {
      return null;
    }
    const zips: string[] = value.toString().includes("-") ? value.split('-') : [value.toString()];
    return {
      zip5: +zips[0],
      zip4: zips.length > 1 ? +zips[1] : null,
    }
  }

  getStateId(value: any): number {
    return value ? value.hasOwnProperty('stateId') ? +value['stateId'] : 0 : 0;
  }
}
