import { Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';

import {  IProjectTaskViewModel } from '../../../view-models/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { ITaskComplete, TaskStatus } from '../../services/task-manager.service';

@Component({
  selector: 'task',
  templateUrl: './task.component.html'
})
export class TaskComponent {
  @Input() projectTask: IProjectTaskViewModel;
  @Input() index: number;
  @Input() canIEdit: boolean;

  @Output() public completeEvent = new EventEmitter<ITaskComplete>();
  @Output() public deleteEvent = new EventEmitter<number>();

  constructor(@Inject(appConstants) public  myConstants: IAppConstants) { }

  public get isOdd(): boolean {
    return (this.index % 2) === 1;
  }
  public get isWarning(): boolean {
    return this.projectTask.taskStatus === TaskStatus.warning.toString();
  }
  public get isOverDue(): boolean {
    return this.projectTask.taskStatus === TaskStatus.overDue.toString();
  }

  public deleteTask(): void {
    this.deleteEvent.emit(this.projectTask.projectTaskId);
  }

  public onCompleteTaskAction(completeEvent: ITaskComplete): void {
    this.completeEvent.emit(completeEvent);
  }

}
