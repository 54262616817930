import { Component, Input, OnInit} from '@angular/core';
import {  IWorkOrderViewModel } from '../../../../app/portals/view-models';
import { ISmsContactViewModel } from '../../../../app/portals/view-models/i-sms-contact-view-model';

@Component({
  selector: 'fw-special-instructions',
  templateUrl: './special-instructions.component.html'
})
export class SpecialInstructionsComponent implements OnInit {
  @Input() row: IWorkOrderViewModel | ISmsContactViewModel;
  @Input() includeAnimation: boolean = true;

  public specialIcon: string = '';

  ngOnInit(): void {
    this.specialIcon = this.row.specialInstructionText
      ? 'fas fa-siren-on app-icon--black'.concat(this.includeAnimation ? ' special-instructions--icon' : '') : '';
  }
 }
