<ng-container *ngFor="let col of (colData$ | async)">
  <div *ngIf="col.visible" class="dynamic-list__filter-item">
    <fw-dynamic-filter-form *ngIf="col.fieldDefinition && col.filterable" class="dynamic-list__filter-item__col"
                            [fieldMetaData]="fieldMetaData"
                            [vmFieldDefinition]="col.fieldDefinition"
                            [operation]="operation"
                            [showFilter]="showFilter"
                            [defaultValue]="defaultValues[col.idx].value"
                            [parentId]="parentId">
    </fw-dynamic-filter-form>
    <div *ngIf="!col.fieldDefinition || !col.filterable" class="dynamic-list__filter-item__col"></div>
  </div>
</ng-container>

<div class="dynamic-list__filter-item">
  <div class="dynamic-list__filter-item__actions">
      <button class="app-btn-icon app-btn-icon--primary dynamic-list__filter-item__actions--run"
              (click)="runFilter()"
              [disabled]="filterDisabled"
              title="Apply Filter">
        <i class="fas fa-play"></i>
      </button>
      <button class="app-btn-icon app-btn-icon--primary dynamic-list__filter-item__actions--erase"
              [disabled]="!searchTerms || !searchTerms.length"
              (click)="clearAllFilters()"
              title="Clear Filters">
        <i class="fas fa-eraser"></i>
      </button>
      <button *ngIf="listDefinition.allowSaveFilter" class="app-btn-icon dynamic-list__filter-item__actions--add"
              (click)="createSavedFilter()"
              [disabled]="!searchTerms || !searchTerms.length"
              title="Create Personal Filter">
        <i class="fas fa-filter  {{ (!searchTerms || !searchTerms.length) ? '' : 'app-btn-icon--primary'  }}"></i>
        <i class="fas fa-plus {{ (!searchTerms || !searchTerms.length) ? '' : 'app-btn-icon--success'  }} app-btn-icon--stacked"></i>
      </button>
      <button *ngIf="listDefinition.allowSaveFilter" class="app-btn-icon dynamic-list__filter-item__actions--save"
              (click)="manageSavedFilters()"
              title="Manage Personal Filters">
        <i class="fas fa-filter  {{ filterDisabled ?  '' : 'app-btn-icon--primary' }}"></i>
        <i class="fas fa-wrench {{ filterDisabled ? '' : 'app-btn-icon--neutral'  }} app-btn-icon--stacked"></i>
      </button>
  </div>
</div>
