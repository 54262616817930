<div class="portal">
  <div class="portal__detail--item">
    <fw-component-title [title]="title"></fw-component-title>
    <div  [ngClass]="{'loading-mask': !(availableEntities$ | async) || (working$ | async)}">
      <related-entity-create *ngIf="(availableEntities$ | async)"
                          [entityType]="type"
                          [availableEntities]="availableEntities$ | async"
                          [errorData]="relatedEntityService.errorData$ | async"
                          [errors]="relatedEntityService.errors$ | async"
                          (createEvent)="addEntities($event)"
                          (cancelEvent)="cancel()">
      </related-entity-create>
    </div>
  </div>
</div>
