<div class="da-list-items">
  <div class="da-list-item" *ngFor="let action of (actions$ | async)">
    <div class="da-list-item__label">{{action.actionName }}<span *ngIf="!isInfoOnly">:</span></div>
    <div *ngIf="!isInfoOnly" class="da-list-item__value" [ngClass]="{ 'app-text--danger': !action.completionDate }">
      {{ action.completionDate ? (action.completionDate | date:'MM/dd/yyyy') : 'Missing'}}
    </div>
    <div *ngIf="action.canOverride" class="da-list-item__btn">
      <ng-container [ngSwitch]="action.actionTypeName">
        <ng-container *ngSwitchCase="uploadFile">
          <!--upload document -->
          <button class="app-btn-icon app-btn-icon--custom-3"
                  [disabled]="!action.isEnabled"
                  (click)="onClick(action)"
                  title="{{ action.actionName }}">
            <i class="fas fa-paperclip"></i>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="getSignature">
          <!--sign document -->
          <button class="app-btn-icon app-btn-icon--custom-4"
                  [disabled]="!action.isEnabled"
                  (click)="onClick(action)"
                  title="{{ action.actionName }}">
            <i class="fad fa-file-signature "></i>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="generateFile">
          <!-- generate and upload file - custom interface/method reqd -->
          <button class="app-btn-icon app-btn-icon--success"
                  [disabled]="!action.isEnabled"
                  (click)="onClick(action)"
                  title="{{ action.actionName }}">
            <i class="fad fa-file-plus "></i>
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="fillForm">
          <!-- generate and upload file - custom interface/method reqd -->
          <button class="app-btn-icon app-btn-icon--success"
                  [disabled]="!action.isEnabled"
                  (click)="onClick(action)"
                  title="{{ action.actionName }}">
            <i class="fad fa-file-plus "></i>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
