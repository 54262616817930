import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomPhonePipe } from './hom-phone.pipe';
import { HomDatePipe } from './hom-date.pipe';
import { HomDecimalPipe } from './hom-decimal.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    HomDatePipe,
    HomPhonePipe,
    HomDecimalPipe
  ],
  exports: [
    HomDatePipe,
    HomPhonePipe,
    HomDecimalPipe
  ]

})
export class HomPipesModule {}
