
<div class="sms-popover-box">
  <div class="sms-popover">
    <fw-component-title title='Select a document type to send'></fw-component-title>
    <div class="sms-popover__items sms-popover__items--types">
      <ng-container *ngFor="let item of documentTypes; index as i">
        <div class="sms-popover__item"
             (click)="selectDoc(item.communicationDocumentTypeId)">
          {{item.documentTypeDescription}}
        </div>
      </ng-container>
    </div>
  </div>
  <p class="sms-popover-box__info">
    <span class="app-icon app-info--icon">
      <i class="fas fa-info-circle"></i>
    </span>
    {{ maxInfo }}
  </p>
  <fw-error-box  [errors]="errorMessage" [ignoreKey]="true"></fw-error-box>
</div>
