import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import {  take  } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../../shared/constants/index';
import * as fromSelectionLists from '../../../../../../shared/store/selectionLists/index';
import * as fromAuth from '../../../../../../auth/store/auth.reducer';

import { IJobScheduleJob, JobScheduleJobSubscription } from '../../../../view-models/index';
import { CreateObjectModel, UpdateObjectByIdModel, IKey } from '../../../../../../fw/dynamic-list/store/interfaces/index';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromRoot from '../../../../../store/reducers/index';
import * as fromStore from '../../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { IDetailContainerConfig } from '../../../../../../fw/dynamic-detail/interfaces';
import { IInputButton } from '../../../../../../fw/fw-shared/interfaces/i-input-button';
import { ISelectListData } from '../../../../../../shared/store/selectionLists/index';

@Component({
  selector: 'user-alert',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-alert.component.html'
})
export class UserAlertComponent implements OnInit, OnDestroy  {
  public operation: string;
  public jobName: string = '';
  public objectData: JobScheduleJobSubscription = null;
  public jobScheduleJobs: IJobScheduleJob[] = [];
  public cbConfig: IInputButton;
  public jobForm = new FormGroup({
    jobScheduleJob: new FormControl(),
    jobName: new FormControl(''),
    jobScheduleName: new FormControl(''),
    alertOnlyWhenOutput: new FormControl(false)
  });
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  listStore: fromDynamicList.IListObjectData;
  authState: fromAuth.IState;
  myPathName: string = '';
  myStoreName: string = '';
  key: string = '';
  activeIndex:number = -1;
  objectId: number = 0;
  scheduleName: string = '';
  subscription: Subscription = new Subscription();

  public formDisabled() {
    return !this.jobForm.dirty || !this.jobForm.valid || this.listStore.working;
  }

  constructor(
    public  rootStore: Store<fromRoot.IState>,
    public  store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) { }

  public onChange(e) {
    const id = e.target.value;
    const jobScheduleJob = this.jobScheduleJobs.find(j => j.jobScheduleJobId == id);
    this.scheduleName = jobScheduleJob ? jobScheduleJob.scheduleName : '';
    this.jobName = jobScheduleJob ? jobScheduleJob.jobName : '';
  }

  public onCreate(e: IHomEventEmitter) {
    this.objectData.jobScheduleJobSubscriptionId = 0;
    this.setCommonProperties();

    //Set create specific defaults
    this.objectData.providerUser_providerUserId = this.authState.providerUser.currentUserId;
    const emitter: IHomEventEmitter = { requestor: this.listStore.listDefinition.detailRoutePath, event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(this.myStoreName, -1, this.listStore.listDefinition.controllerName, 'Create', this.objectData, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }

  public onCancel() {
    const emitter: IHomEventEmitter = { requestor: this.listStore.listDefinition.detailRoutePath, event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public onSave(navType: string) {
    this.setCommonProperties();

    const keyData: IKey = { storeName: this.myStoreName, parentId: -1, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: this.listStore.listDefinition.detailRoutePath, event: this.myConstants.emitterEventUpdate, action: navType, data: null };
    const updateData = new UpdateObjectByIdModel(keyData, this.listStore.listDefinition.controllerName, 'Update', this.key, this.objectId, this.objectData, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectByIdList({ updateData }));
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.activeIndex = +paramMap.get('rowIndex');
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
      params.parentId = -1;
      params.useRouterOutlet = false;
      params.wrapsForm = true;
      params.showErrorBox = true;
      this.detailConfig$.next(params);
    });

    this.subscription.add(this.rootStore.select('auth').pipe(take(1))
      .subscribe((authState: fromAuth.IState) => {
        this.authState = authState;
      }));

    this.subscription.add(this.rootStore.select(fromSelectionLists.getSelectionListByTypeByParent('jobScheduleJob', -1))
      .subscribe((jobs: ISelectListData) => {
        this.jobScheduleJobs = jobs.data;
        if (this.objectData && this.scheduleName === '') {
          this.setSelectValue();
        }
      }));

    this.subscription.add(this.store.pipe(select(fromStore.getEntityListByParentId(this.myStoreName, -1)))
      .subscribe((state: fromDynamicList.IListObjectData) => {
        this.listStore = state;
        if (!this.listStore.working) {
          this.myPathName = this.listStore.listDefinition.detailRoutePath;
          //handle updated data and initial data
          this.objectData = this.operation === this.myConstants.operationTypeCreate
            ? this.objectData ? this.objectData : new JobScheduleJobSubscription
            : this.listStore.data.find(data => data[this.key] == this.objectId); //keep this as == for it to match

          if (this.jobScheduleJobs && this.scheduleName === '') {
            this.setSelectValue();
          }
        }
      }));

    this.cbConfig = {
      label: {
        label: 'Alert Only When Output',
        alignRight: true
      },
      form: { 
        form: this.jobForm,
        name: 'alertOnlyWhenOutput'
      }
    };
  }
  
  setSelectValue() {
    const formControl = this.jobForm.controls['jobScheduleJob'];
    const selectedJob = this.jobScheduleJobs.find(j => j.jobScheduleJobId == this.objectData.jobScheduleJob_jobScheduleJobId);
    if (selectedJob) {
      formControl.setValue(selectedJob.jobScheduleJobId);
    }
    this.scheduleName = this.objectData.scheduleName;
    this.jobName = this.objectData.jobNameNew;
  }

    setCommonProperties() {
      if (this.objectData.hasOwnProperty('jobScheduleJob_jobScheduleJobId')) {
        this.objectData['jobScheduleJob_jobScheduleJobId'] = this.jobForm.value['jobScheduleJob'];
      }      
      if (this.objectData.hasOwnProperty('alertOnlyWhenOutput')) {
        this.objectData['alertOnlyWhenOutput'] = this.jobForm.value['alertOnlyWhenOutput'];
      }      
    }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
