import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, Inject, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IWorkOrder, ISchedule, Schedule, IScheduleAvailabilityViewModel } from '../../../view-models';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { ScheduleEvent, DurationType, UnavailableType, DurationMax, AvailabilityPullRange, DurationMin } from '../../enums/schedule.enums';
import { IRequestAvailabilityEvent, IDateTimeEvent, IScheduleSaveEvent, ICalendarDay } from '../../interfaces';
import { IScheduleInstallerWorkCrewAvailabilityViewModel } from '../../../view-models/i-schedule-installer-work-crew-availability-view-model';
import { IErrorData } from '../../../../../shared/interfaces';
import { SchedulerService } from '../../services/scheduler.service';

@Component({
  selector: 'schedule-editor',
  templateUrl: './schedule-editor.component.html'
})
export class ScheduleEditorComponent implements OnInit, OnChanges {
  @Input() workOrder: IWorkOrder;
  @Input() currentSchedule: ISchedule;
  @Input() operation: string;
  @Input() availability: IScheduleAvailabilityViewModel[];
  @Input() availableInstallers: IScheduleInstallerWorkCrewAvailabilityViewModel[];
  @Input() scheduleCount: number;
  @Input() screenIsSmall: boolean = false;
  @Input() errorData: IErrorData[];

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public workingSchedule$: BehaviorSubject<ISchedule> = new BehaviorSubject(null);
  public selectedInstaller$: BehaviorSubject<IScheduleInstallerWorkCrewAvailabilityViewModel> = new BehaviorSubject(null);
  public pullLength: number = 1;
  public duration: number = 1;
  public durationType: string;
  public startDateTime: string = '';
  public firstRequest: boolean = true;
  public unavailableReason: string;
  public hideInstallers: boolean = true;
  public title: string = 'Schedule';
  public requestingInstallers: boolean = true;
  public activePage: number = 1;
  public minDuration: number;
  public maxDuration: number;
  public durationPattern: RegExp;
  public typeDays: string;
  public timeSet: boolean = false;
  public daySelected: ICalendarDay = null;
  public installerWarning: string = '';
  regExDays: RegExp = /^[1-7]$/;
  regExHours: RegExp = /^((?:[1-9]|1[0-9]|2[0-3])(?:\.\d{1,2})?|23?)$/;

  constructor(public changeDetectorRef: ChangeDetectorRef,
    public scheduleService: SchedulerService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onRangeChange(val: boolean): void {
    this.pullLength = val === true ? AvailabilityPullRange.max : AvailabilityPullRange.min;
    this.reset();
  }

  public onDurationTypeChange(val: string): void {
    this.durationType = val;
    this.duration = this.durationType === DurationType.minutes ? 15 : 1;
    this.minDuration = this.durationType === DurationType.days ? DurationMin.days
      : this.durationType === DurationType.hours ? DurationMin.hours
        : DurationMin.minutes;
    this.maxDuration = this.durationType === DurationType.days ? DurationMax.days
      : this.durationType === DurationType.hours ? DurationMax.hours
        : DurationMax.minutes;
    this.durationPattern = this.getDurationPattern();
    this.reset();
  }

  public onDurationNbrChange(val: string): void {
    if (!val || val === '0') {
      this.duration = this.durationType === DurationType.days ? DurationMin.days
        : this.durationType === DurationType.hours ? DurationMin.hours
          : DurationMin.minutes;
    } else {
      this.duration = +val;
    }
    this.reset();
  }

  public onDurationSelChange(val: string): void {
    this.duration =  +val;
    this.reset();
  }

  public findAvailability(hasAvailabilityData: boolean = false): void {
    const requestData: IRequestAvailabilityEvent = {
      workOrderId: this.workOrder.workOrderId,
      startDate: this.startDateTime,
      duration: this.duration,
      durationType: this.durationType,
      pullLength: this.pullLength,
      currentWorkCrewId: this.operation === this.myConstants.operationTypeCreate ? 0 : this.currentSchedule.installerWorkCrewId,
      scheduleId: this.operation === this.myConstants.operationTypeCreate ? 0 : this.currentSchedule.scheduleId,
      sortBy:  'installerWorkCrewCompatibilityScore',
      sortAsc: false
    }
    this.requestingInstallers = this.firstRequest ? true : hasAvailabilityData;
    this.customEvent.emit({
      requestor: 'schedule-editor',
      event: hasAvailabilityData ? ScheduleEvent.getAvailableCrews : ScheduleEvent.getAvailableSlots,
      action: '',
      data: requestData
    });
    this.firstRequest = false;
  }

  public onCustom(eventIn: IHomEventEmitter) {
    let event: IHomEventEmitter = cloneDeep(eventIn);
    if (!event) {
      console.log('DEV ERR:  no event on custom Event call');
      return;
    }
    switch (event.event) {
      case ScheduleEvent.setStartDate:
      case ScheduleEvent.setTimespan:
        const dateData: IDateTimeEvent = event.data;
        if (event.event === ScheduleEvent.setStartDate) {
          this.daySelected = dateData.hasAvailabilityData ? dateData.daySelected : null;
        }
        this.handleDateTimeEventCommon(event.event, dateData);
        if (!this.firstRequest && !dateData.unavailableType) {
          this.findAvailability(dateData.hasAvailabilityData);
        }
        break;
      case ScheduleEvent.setAvailabilityForDay:
        const availDayData: IDateTimeEvent = event.data;
        this.handleDateTimeEventCommon(event.event, availDayData);
        if (!availDayData.unavailableType) {
          //get installers
          this.findAvailability(true);
        } else {
          this.requestingInstallers = false;
        }
        break;
      case ScheduleEvent.setAvailabilityForTimespan:
        const eventData: IDateTimeEvent = event.data;
        this.handleDateTimeEventCommon(event.event, eventData);
        this.daySelected = eventData.daySelected;
        this.requestingInstallers = false;
        break;
      case ScheduleEvent.selectWorkCrew:
        const selectedCrew: IScheduleInstallerWorkCrewAvailabilityViewModel = cloneDeep(event.data);
        this.selectedInstaller$.next(selectedCrew);
        this.setWorking();
        break;
      case ScheduleEvent.installerSort:
        this.customEvent.emit(eventIn);
        break;
      default:
        console.log('DEV ERR: onCustom and this event is not defined');
        break;
    }
  }

  public page(direction: string, el: HTMLDivElement): void {
    switch (direction) {
      case 'prev':
        this.activePage = this.activePage - 1;
        break;
      case 'next':
        this.activePage = this.activePage + 1;
        break;
      default:
        break;
    }
    if (el) {
      el.scrollTo()
    }
  }

  public cancel(): void {
    this.customEvent.emit({ requestor: 'schedule-editor', event: ScheduleEvent.cancelEdit, action: '', data: null });
  }

  public save(): void {
    const installer: IScheduleInstallerWorkCrewAvailabilityViewModel = this.selectedInstaller$.getValue();
    const data: IScheduleSaveEvent = {
      scheduleId: this.myConstants.operationTypeCreate ? 0 : this.currentSchedule.scheduleId,
      scheduleStartDate: this.startDateTime,
      duration: this.duration,
      durationType: this.durationType,
      installerWorkCrewId: installer ? installer.installerWorkCrewId : 0,
      operation: this.operation
    }
    this.customEvent.emit({ requestor: 'schedule-editor', event: ScheduleEvent.saveSchedule, action: '', data: data });
  }

  ngOnInit() {
    this.title = this.operation.concat(' Schedule');
    this.installerWarning = this.operation === this.myConstants.operationTypeCreate && this.scheduleCount > 0
      ? 'When adding a secondary schedule, the schedule must be assigned to the installer associated with the existing schedule.'
      : this.operation === this.myConstants.operationTypeEdit && this.scheduleCount > 1
        ? 'When editing an existing schedule and there are multiple schedules, all schedules must be associated with the same installer.'
        : '';
    this.typeDays = DurationType.days;
    this.duration = this.operation === this.myConstants.operationTypeEdit ? this.currentSchedule.duration : 1;
    this.durationType = this.operation === this.myConstants.operationTypeEdit ? this.currentSchedule.durationType : DurationType.days;
    this.minDuration = this.operation === this.myConstants.operationTypeEdit ? this.getDefaultDurationMin() : DurationMin.days;
    this.maxDuration = this.operation === this.myConstants.operationTypeEdit ? this.getDefaultDurationMax() : DurationMax.days;
    this.durationPattern = this.operation === this.myConstants.operationTypeEdit ? this.getDurationPattern() : this.regExDays;
    this.startDateTime = this.operation === this.myConstants.operationTypeEdit ? this.currentSchedule.scheduleStartDate : '';
    this.setWorking();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['availability'] && !(changes['availability'].firstChange)) {
      this.resetSelected();
    }

    if (changes['availableInstallers'] && !(changes['availableInstallers'].firstChange)) {
      this.requestingInstallers = false;
      this.resetSelected();
    }
    if (changes['screenIsSmall'] && !(changes['screenIsSmall'].firstChange)) {
      this.activePage = 1;
    }

    if (changes['errorData'] && !(changes['errorData'].firstChange)) {
      this.requestingInstallers = false;
      this.hideInstallers = this.errorData && this.errorData.length > 0;
      this.resetSelected();
    }
  }

  handleDateTimeEventCommon(type: string, data: IDateTimeEvent): void {
    this.startDateTime = type === ScheduleEvent.setAvailabilityForTimespan ? data.daySelected.date : data.dateTimeSelected ? data.dateTimeSelected : null;
    this.timeSet = type === ScheduleEvent.setTimespan;
    this.hideInstallers = this.setHideInstallers(data);
    this.resetSelected();
    this.unavailableReason = this.getUnavailableReason(data.unavailableType);
    this.setWorking();
  }

  setHideInstallers(data: IDateTimeEvent): boolean {
    return this.durationType !== DurationType.days && !this.timeSet
      ? true
      : data.unavailableType === UnavailableType.spanNonWorkingDay || data.unavailableType === UnavailableType.scheduleDayLocked
        ? true : false;
  }

  setWorking(): void {
    let schedule: ISchedule = new Schedule(this.workOrder.project_projectId, this.workOrder.workOrderId, DurationType.days, DurationMin.days);
    schedule.scheduleStartDate = this.startDateTime;
    schedule.scheduleDuration = this.durationType;
    schedule.duration = this.duration;
    schedule.durationType = this.durationType;
    const installer: IScheduleInstallerWorkCrewAvailabilityViewModel = cloneDeep(this.selectedInstaller$.value);
    schedule.installerCrewsName = installer ? installer.installerWorkCrewName : '';
    schedule.contactId = installer ? installer.contactId : 0;
    schedule.installerWorkCrewLeadName = installer ? installer.installerWorkCrewLeadName : '';
    this.workingSchedule$.next(schedule);
    this.changeDetectorRef.detectChanges();
  }

  getUnavailableReason(unavailableType: UnavailableType): string {
    switch (unavailableType) {
      case UnavailableType.allInstallersTaken:
        return 'All installers taken';
      case UnavailableType.scheduleDayLocked:
        return 'Schedule day(s) is locked';
      case UnavailableType.spanNonWorkingDay:
        return 'Proposed schedule will span non-working day';
      default:
        return '';
    }
  }

  reset(): void {
    this.startDateTime = '';
    this.firstRequest = true;
    this.resetSelected();
    this.timeSet = false;
    this.customEvent.emit({ requestor: 'schedule-editor', event: ScheduleEvent.resetAvailability, action: '', data: null });
  }

  resetSelected(): void {
    this.selectedInstaller$.next(null);
    let schedule: ISchedule = this.workingSchedule$.getValue();
    schedule.installerCrewsName = '';
    this.workingSchedule$.next(schedule);
  }

  getDefaultDurationMin(): DurationMin {
    return this.currentSchedule.durationType === DurationType.days ? DurationMin.days
      : this.currentSchedule.durationType === DurationType.hours ? DurationMin.hours
        : DurationMin.minutes;
  }

  getDefaultDurationMax(): DurationMax {
    return this.currentSchedule.durationType === DurationType.days ? DurationMax.days
      : this.currentSchedule.durationType === DurationType.hours ? DurationMax.hours
        : DurationMax.minutes;
  }

  getDurationPattern(): RegExp {
    return this.durationType === DurationType.days ? this.regExDays
      : this.durationType === DurationType.hours ? this.regExHours
        : this.regExDays;
  }

  isValidDuration(val: string): boolean {
    const re = this.currentSchedule.durationType === DurationType.days ? this.regExDays
      : this.currentSchedule.durationType === DurationType.hours ? this.regExHours : this.regExDays;
    return re.test(val);
  }
}
