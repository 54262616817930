import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription} from 'rxjs';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';

@Component({
  selector: 'manager-dashboard',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './manager-dashboard.component.html'
})
export class ManagerDashboardComponent implements OnInit, OnDestroy {
  public tabContainerName = 'mgr-dash';
  public title: string = 'Manager Dashboard';

  subscription = new Subscription();
  portalId: number = 11;

  constructor( public  store: Store<fromFeature.IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  newRequest() { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
