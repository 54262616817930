import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IService, IProviderLocation } from '../../../view-models';
import { getSelectedRecord, IAllDynamicData } from '../../../../../fw/dynamic-list/store';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'provider-location-services',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './provider-location-services.component.html'
})
export class ProviderLocationServicesComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;
  public title: string;
  subscription: Subscription = new Subscription();
  serviceId: number = 0;
  providerLocationId: number = 0;
  currentService: IService;
  currentPL: IProviderLocation;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<IAllDynamicData>,
    public adminService: AdminProviderService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.serviceId = +paramMap.get('serviceId');
      this.providerLocationId = +paramMap.get('plId');
      this.title = this.serviceId > 0 ? 'Provider Locations' : 'Services';
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadProviderLocationServicesListDefinition(this.serviceId, this.providerLocationId);
    const parentListDef = this.serviceId > 0
      ? this.adminService.loadServicesListDefinition()
      : this.adminService.loadProviderLocationsListDefinition();

    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(parentListDef.storeName, -1, parentListDef.rowKeyId,
        this.serviceId > 0 ? this.serviceId : this.providerLocationId), take(1)))
      .subscribe(entity => {
        this.currentService = this.serviceId > 0 ? entity : null;
        this.currentPL = this.providerLocationId > 0 ? entity : null;
      })
    );
 }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
