<div *ngIf="(menuService.hasTabs$ | async) && menuService.isVisible && !screenService.isSmall()"
     class="app-nav-btn"
     title="Click to toggle Menu"
     [ngClass]="{'app-nav__btn--open': menuService.showingMiniMainMenu }"
     (click)="menuService.toggleVerticalNav()">
  <i class="fas fa-home"></i>Menu
</div>

<div class="close-app-menu" *ngIf="(menuService.hasTabs$ | async) && menuService.isVisible && menuService.showingMiniMainMenu" (click)="menuService.toggleVerticalNav()"></div>

<nav class="app-nav" *ngIf="(menuService.hasTabs$ | async) && menuService.isVisible && menuService.showingMiniMainMenu">
  <ul *ngIf="menuService.items">
    <li *ngFor="let item of menuService.items" class="app-nav__item" (click)="openLink(item)">
      <i class="fas {{ item.icon }} app-nav__item--icon"></i>
      <span class="app-nav__item--text">{{ item.text }}</span>
    </li>
  </ul>
</nav>

