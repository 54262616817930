import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'self-service-scheduling',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './self-service-scheduling.component.html'
})
export class SelfServiceSchedulingComponent implements OnInit, OnDestroy {

  subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  newRequest() { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
