<div class="accordian" *ngIf="(items$ | async).length" [ngStyle]="{'height': componentHeight}">
  <div *ngFor="let item of (items$ | async); let i = index;" class="accordian__section" [ngClass]="{'accordian__section--inactive': !item.isActive }" >
    <div class="accordian__header" (click)="toggleActive(i)">
      <div class="accordian__header__title" [ngClass]="{'accordian__header__title--active': item.isActive }">{{item.title}}</div>
      <div class="accordian__header__toggle" [ngClass]="{'accordian__header__toggle--active': item.isActive }">
        <i class="fas fa-chevron-{{ item.isActive ? 'down' : 'right' }}"></i>
      </div>
    </div>
    <div class="accordian__content accordian__content--pad" *ngIf="item.isActive" [innerHTML]="item.content" >
    </div>
  </div>
</div>
