import { Injectable } from '@angular/core';

import {  IProgramServiceXmlMapping, IBranchXmlMapping, ISkuXmlMapping, IUnitMeasureXmlMapping } from '../../../app/portals/view-models/index';
import { IWorkOrderSurvey } from '../../../app/portals/view-models/index_two';

@Injectable()
export class AdminDashDynamicListService {

  constructor() { }

  handleCustomMethod(methodName: string, row: any, portalEntityId: number): any {
    switch (methodName) {
      case 'getIsNotMappedCss':
        return this.getIsNotMappedCss(row);
      case 'canCreateSku':
        return this.canCreateSku(row);
      case 'canGoToSku':
        return this.canGoToSku(row);
      case 'canEditSurvey':
        return this.canEditSurvey(row);
     default:
        return false;
    }
  }
  /*CUSTOM WORK ORDER METHODS TO SET CSS */
  getIsNotMappedCss(row: IProgramServiceXmlMapping | IBranchXmlMapping | ISkuXmlMapping | IUnitMeasureXmlMapping): string {
    return row && row.hasOwnProperty('notMapped') && row.notMapped ? 'dynamic-list__item--danger' : '';
  }

  /*CUSTOM METHODS TO DETERMINE BUTTON ENABLED */
  canCreateSku(row: ISkuXmlMapping): boolean {
    return row && row.hasOwnProperty('sku_skuId') && row.sku_skuId === null;
  }

  canGoToSku(row: ISkuXmlMapping): boolean {
    return row && row.hasOwnProperty('sku_skuId') && row.sku_skuId !== null;
  }

  canEditSurvey(row: IWorkOrderSurvey): boolean {
    return row && row.hasOwnProperty('surveyLocked') && row.surveyLocked === false;
  }

}
