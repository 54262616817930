import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../../auth/services/auth-guard.service';
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';

import { SelectionListResolver, 
      ProviderLocationServiceListResolver,
      AllMyProviderLocationsResolver
  } from '../resolvers/index';

import {
  CertificationDetailComponent,
  PlsCertificationListComponent,
  GcInstallerRequirementDetailComponent,
} from './components/index';

import {
  InstallerTechnicianWithCertificationRangeComponent,
  PlsEntityDetailComponent
} from '../portal-shared/components/index';
import { PortalComponent } from '../portal/portal.component';
import { LoadingPageComponent } from '../../../shared/components';
import { InstallerComponent } from '../portal-shared/containers';

const portalRoutes: Routes = [
  {
    path: 'compliance', //componentless route 
    children: [
      {
        path: ':portalId',
        component: PortalComponent,
        canActivate: [AuthGuard],
        children: [
          //these are not full paths, just names, the component def will lead it to correct location
          {
            path: 'installer/:id',
            outlet: 'installerOutlet',
            resolve: {
              infoLoaded: SelectionListResolver,
            },
            component: InstallerComponent
          },
          {
            path: 'certification-detail/:id',
            outlet: 'certificationOutlet',
            component: CertificationDetailComponent,
            canActivate: [IsCurrentGuard],
            resolve: { selectListsLoaded: SelectionListResolver }
          },
          {
            path: 'gc-installer-requirement-detail/:id',
            outlet: 'gcInstallerRequirementOutlet',
            component: GcInstallerRequirementDetailComponent,
            canActivate: [IsCurrentGuard],
            resolve: { selectListsLoaded: SelectionListResolver }
          },
          {
            path: 'compliance-technician-with-certification-range/:id',
            outlet: 'complianceTechnicianDetailOutlet',
            component: InstallerTechnicianWithCertificationRangeComponent
          },
          {
            path: 'installer-compliance-with-certification-range/:id',
            outlet: 'installerComplianceDetailOutlet',
            component: InstallerTechnicianWithCertificationRangeComponent
          }
          //No routing involved fro the installer and tech dashboard component, so not listed here
        ]
      }
    ]
  },  // BEGIN MODAL ROUTES
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'pls-certification-list/:id',
    outlet: 'modal',
    component: PlsCertificationListComponent,
    children: [
      {
      path: 'pls-entity-detail/:id',
      outlet: 'plsCertificationOutlet',
      component: PlsEntityDetailComponent,
        data: {
          includeReadOnly: false
        },
     resolve: {
        providerLocations: AllMyProviderLocationsResolver,
        pls: ProviderLocationServiceListResolver
      }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class ComplianceRoutingModule { }
