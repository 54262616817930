
export class InstallerWorkCrewWorkCategory {

    constructor(
        public installerWorkCrewWorkCategoryId: number = 0,
        public installerWorkCrew_installerWorkCrewId: number = 0,
        public workCategory_workCategoryId: number = 0,
        public dailyCrewCapacity: number = 0,
        public capacityComments: string = '',
        public active = true,
        public createDate: string = '', //example: "/Date(1306380319000)/",
        public updateDate: string = '',
        public createdBy: number = 0,
        public updatedBy: number = 0,
        public rowVersion: string = '',
        public aggregateRootVersion: string = ''
    ) {
    }

}
//public class InstallerWorkCrewWorkCategory : BaseEntity
//{
//        public virtual int InstallerWorkCrewWorkCategoryId { get; set; }
//        public virtual InstallerWorkCrew InstallerWorkCrew { get; set; }
//        public virtual WorkCategory WorkCategory { get; set; }
//        public virtual int DailyCrewCapacity { get; set; }
//        public virtual string CapacityComments { get; set; }
//}
