import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { cloneDeep, uniq } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';


import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import * as fromRoot from '../../../../../app/store/reducers/index';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IListDefinition, ListFilter, OrderTerm } from '../../../../../fw/dynamic-list/interfaces';
import { IHomDictionary, IResponseBase } from '../../../../../shared/interfaces';
import { CreateObjectModel, IKey, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces';
import { IZone, Zone } from '../../../view-models/i-zone';
import { IZipCode } from '../../interfaces/i-zip-code';
import { AdminStore } from '../../enums/admin.enums';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { MetaDataService } from '../../../../../fw/dynamic-list/services';
import { AdminProviderService } from '../../services/admin-provider.service';
import { DomainObjectService } from '../../../../../shared/services';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'zone',
  templateUrl: './zone.component.html'
})

export class ZoneComponent implements OnInit, OnDestroy {

  constructor(
    public activeRoute: ActivatedRoute,
    public mds: MetaDataService,
    public adminProviderService: AdminProviderService,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public selStore: Store<fromSelectionLists.IStoreState>,
    public dos: DomainObjectService,
    public emitterService: HomEventEmitterService,
    public ups: UserPriviledgesService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants
  ) { }

  public operation: string;
  displayFields: string[] = ['zoneName'];
  public loading$: Observable<boolean>;
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  zone$: BehaviorSubject<IZone> = new BehaviorSubject(null);
  states$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  zipCodes$: BehaviorSubject<IZipCode[]> = new BehaviorSubject(null);
  availableZipCodes$: BehaviorSubject<IZipCode[]> = new BehaviorSubject(null);
  public zipCodesLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public availableZipCodesLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  cities$: BehaviorSubject<string[]> = new BehaviorSubject(null);
  form: FormGroup;
  subscription: Subscription = new Subscription();
  stagedZipCodes = [];
  removedZipCodes = [];
  filterState: number = null;
  filterCity: string = null;
  zoneId: number;
  cityName: number;
  listDefinition: IListDefinition;
  
   
  ngOnInit() {
    this.listDefinition = this.adminProviderService.loadZonesListDefinition();
    this.activeRoute.paramMap.subscribe((params) => {
      this.operation = params.get('operation');
      this.mds.setFieldDefinitions(this.listDefinition.storeName);
      this.getStates();
      this.setDetailConfig(params);
      if (this.operation !== this.myConstants.operationTypeCreate) {
        this.zoneId = +params.get('id');
        this.getZipCodes();
        this.getZone();
        this.subscription.add(this.zone$.pipe(
          filter((data) => data !== null))
          .subscribe((data) => {
            this.form = this.mds.loadDynamicFormGroup(this.displayFields, data, this.operation);
          }));
      } else {
        this.zipCodes$.next([]);
        this.zipCodesLoading$.next(false);
        this.form = this.mds.loadDynamicFormGroup(this.displayFields, { zoneId: 0, zoneName: "", providerId: this.ups.providerId$.value }, this.operation);
      } 
      
    });
    
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(filter(x => (x.requestor === 'zone-detail')),
        map(x => x.show));

  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.wrapsForm = true;
    params.showCancel = false;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  getZipCodes(): void {
    const listFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.orderTerm = [new OrderTerm('cityName', true)];
    this.zipCodesLoading$.next(true);
    this.dos.getByMethodById('ZipCode', 'ByZone', this.zoneId, listFilter).subscribe((response: IResponseBase) => {
      if (response.success) {
        this.zipCodes$.next(response.data);
      }
      this.zipCodesLoading$.next(false);
    });
  }

  getZone(): void {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.listDefinition.storeName, this.listDefinition.parentId, this.listDefinition.rowKeyId, this.zoneId)))
      .subscribe(entity => {
        this.zone$.next(cloneDeep(entity));
      })
    );
  }

  getStates(): void {
    this.subscription.add(this.selStore.pipe(select(fromSelectionLists.getSelectionListByType('state'))).subscribe((data) => {
      if (data && data.objData) {
        this.states$.next(data.objData[0].data.filter(x => x.stateName !== 'Not Applicable'));
      } else {
        const listFilter = new ListFilter();
        listFilter.getAll = true;
        this.selStore.dispatch(new fromSelectionLists.GetEntityList('State', listFilter, 'state'));
      }
    }));
  }

  onStateChange(stateId: string): void {
    const val: number = +stateId;
    this.stagedZipCodes = [];
    if (val !== -1) {
      this.filterState = val;
      const listFilter = new ListFilter();
      listFilter.getAll = true;
      const params: IHomDictionary[] = [
        { key: 'id', value: val },
        { key: 'listFilter', value: listFilter }
      ];
      this.availableZipCodesLoading$.next(true);
      this.dos.getByMethodParams('ZipCode', 'ByState', params).subscribe((response: IResponseBase) => {
        if (response.success) {
          let cities: string[] = uniq(response.data.map(x => x.cityName).sort());
          let zipCodes: IZipCode[] = this.zipCodes$.value;
          this.cities$.next(cities);
          this.availableZipCodes$.next(response.data.filter(x => !zipCodes.find(y => y.zipCodeName === x.zipCodeName))
                                                    .sort((a, b) => a.cityName.localeCompare(b.cityName)));
        } else {
          this.availableZipCodes$.next(null);
          this.cities$.next(null);
          this.filterState = null;
          this.filterCity = null;
        }
        this.availableZipCodesLoading$.next(false);
      });
    } else {
      this.availableZipCodes$.next(null);
      this.cities$.next(null);
      this.filterState = null;
      this.filterCity = null;
    }
  }

  public onCityChange(cityId: string): void {
    const val: string = cityId;
    this.filterCity = (val != '-1' ? val : null);
  }

  public removeZipCode(item: IZipCode): void { 
    if (this.filterState === item.state_stateId && (this.filterCity === null || (item.cityName === this.filterCity))) {
      const availableZipCodes = this.availableZipCodes$.value;
      availableZipCodes.push(item);
      this.availableZipCodes$.next(availableZipCodes.sort((a, b) => a.cityName.localeCompare(b.cityName)));
    }
    this.removedZipCodes = this.removedZipCodes.filter(x => x.zipCodeName !== item.zipCodeName);
    this.zipCodes$.next(this.zipCodes$.value.filter(x => x.zipCodeName != item.zipCodeName));
  }

  public addZipCode(item: IZipCode): void {
    const zipCodes: IZipCode[] = this.zipCodes$.value;
    if (!zipCodes.find(x => x.zipCodeName == item.zipCodeName)) {
      zipCodes.push(item);
    } 
    this.stagedZipCodes = this.stagedZipCodes.filter(x => x.zipCodeName !== item.zipCodeName);
    this.availableZipCodes$.next(this.availableZipCodes$.value.filter(x => x.zipCodeName !== item.zipCodeName).sort((a, b) => a.cityName.localeCompare(b.cityName)));
    this.zipCodes$.next(zipCodes.sort((a, b) => a.cityName.localeCompare(b.cityName)));
  }

  public onCancel() {
    const emitter: IHomEventEmitter = { requestor: 'zone-detail', event: this.myConstants.emitterEventListReload, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public isValid() {
    return this.form.controls['zoneName'] && (this.zipCodes$.value && this.zipCodes$.value.length);
  }

  public onCreate() {
    const zone: IZone = new Zone();
    zone.provider_providerId = this.ups.providerId$.value;
    zone.zoneName = this.form.controls['zoneName'].value;
    let zipCodes = [];
    this.zipCodes$.value.forEach(item => {
      zipCodes.push(item.zipCodeId);
    });
    
    const emitter: IHomEventEmitter = { requestor: 'zone-detail', event: this.myConstants.operationTypeCreate, action: '', data: null };
    const createData = new CreateObjectModel(AdminStore.zones, -1, 'Zone', 'Create', { model: zone, selectedZipCodeIds: zipCodes }, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  public onSave(e) {
    let model = this.zone$.value;
    let zipCodes = [];
    const zone: IZone = new Zone();
    zone.zoneId = model.zoneId;
    zone.provider_providerId = this.ups.providerId$.value;
    zone.zoneName = this.form.controls['zoneName'].value;
    this.zipCodes$.value.forEach(item => {
      zipCodes.push(item.zipCodeId);
    });

    const keyData: IKey = { storeName: AdminStore.zones, parentId: -1, key: 'zoneId', id: this.zoneId };
    const emitter: IHomEventEmitter = { requestor: 'zone-detail', event: e, action: '', data: null };
    const updateData = new UpdateObjectCustomModel(keyData, 'Zone', 'Update', { model: zone, selectedZipCodeIds: zipCodes }, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  public stageZipCode(item: IZipCode, isStaging: boolean): void {
    if (this.operation === this.myConstants.operationTypeDetails) return;
    if (!this[!isStaging ? 'removedZipCodes' : 'stagedZipCodes'].find(x => x.zipCodeName === item.zipCodeName)) {
      this[!isStaging ? 'removedZipCodes' : 'stagedZipCodes'].push(item);
    } else {
      this[!isStaging ? 'removedZipCodes' : 'stagedZipCodes'] = this[!isStaging ? 'removedZipCodes' : 'stagedZipCodes'].filter(x => x.zipCodeName !== item.zipCodeName);
    }
  }

  public isSelected(zipCodeName: string, isStaging: boolean): boolean {
    return this[!isStaging ? 'removedZipCodes' : 'stagedZipCodes'].find(x => x.zipCodeName === zipCodeName);
  }

  public addStagedZipCodes(): void {
    const zipCodes = this.zipCodes$.value;
    const availableZipCodes = this.availableZipCodes$.value;
    this.stagedZipCodes.forEach(item => {
      const idx = availableZipCodes.findIndex(x => x.zipCodeName === item.zipCodeName);
      availableZipCodes.splice(idx, 1);
      zipCodes.push(item);
    });
    this.availableZipCodes$.next(availableZipCodes);
    this.zipCodes$.next(zipCodes.sort((a, b) => a.cityName.localeCompare(b.cityName)));
    this.stagedZipCodes = [];
  }

  public removeStagedZipCodes(): void {
    const zipCodes = this.zipCodes$.value;
    const availableZipCodes = this.availableZipCodes$.value;
    this.removedZipCodes.forEach(item => {
      const idx = zipCodes.findIndex(x => x.zipCodeName === item.zipCodeName);
      zipCodes.splice(idx, 1);
      availableZipCodes.push(item);
    });
    this.availableZipCodes$.next(availableZipCodes.sort((a, b) => a.cityName.localeCompare(b.cityName)));
    this.zipCodes$.next(zipCodes);
    this.removedZipCodes = [];
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
