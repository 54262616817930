import { NgModule, ErrorHandler, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomErrorHandlerService } from './hom-error-handler.service';
import { HomDataAccessService } from 'hom-lib/hom-data-access';
import { HomEventEmitterService } from 'hom-lib/hom-event-emitter';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    HomDataAccessService,
    HomEventEmitterService,
    { provide: ErrorHandler, useClass: HomErrorHandlerService }
  ]

})
export class HomErrorLoggerModule {
  static forRoot(): ModuleWithProviders<HomErrorLoggerModule> {
    return {
      ngModule: HomErrorLoggerModule,
      providers: [
        HomDataAccessService,
        HomEventEmitterService,
        { provide: ErrorHandler, useClass: HomErrorHandlerService }
      ]
    };
  }

}
