<div class="user-filter-select-popover">
  <div class="user-filter-select">
    <fw-component-title title="Available Filters"></fw-component-title>
    <div class="user-filter-select__items">
      <ng-container *ngFor="let item of userFilters; index as i">
        <div class="user-filter-select__item" title="{{ item.description }}" (click)="selectFilter(item.id)">
          <span class="user-filter-select__item-active">
            <i class="fas fa-filter app-icon--success" *ngIf="item.isActive"></i>
          </span>
          <span class="user-filter-select__item-name">
            {{item.label}}
          </span>
          <span class="user-filter-select__item-default" *ngIf="item.isDefault">
            (DEFAULT)
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
