import { Component, OnInit, Input, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ProjectService } from '../../services/project.service';
import { SelectionListService } from '../../../../../shared/services/selection-list.service';
import { DomainObjectService } from '../../../../../shared/services/domain-object.service';
import { MetaDataService } from '../../../../../fw/dynamic-list/services/index';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { metaDataExists } from '../../../../../fw/dynamic-list/store/selectors/meta-data.selectors';
import * as DynamicObjectActions from '../../../../../fw/dynamic-list/store/actions/dynamic-object.actions';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';

import { Warranty, IWarrantySubType } from '../../../view-models/index';
import { IErrorData, IResponseBase, ISelectResolverData } from '../../../../../shared/interfaces/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IWarranty } from '../../../view-models';
import { IDynamicObject } from '../../../../../fw/dynamic-list/store/reducers/dynamic-object.reducer';
import { UpdateObjectByIdModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import * as fromDynamicObject from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';


@Component({
  selector: 'warranty-container',
  templateUrl: './warranty-container.component.html',
  providers: [MetaDataService]
})

export class WarrantyContainerComponent implements OnInit, OnDestroy {
  @Input() warrantyId: number;
  @Input() canIEdit: boolean;

  public warranty: IWarranty;
  public loading$: Observable<boolean>;
  public listsReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public haveSubTypes$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public operation$: BehaviorSubject<string> = new BehaviorSubject(this.myConstants.operationTypeDetails);
  public warranty$: BehaviorSubject<IWarranty> = new BehaviorSubject(null);
  public warrantySubTypes$: BehaviorSubject<IWarrantySubType[]> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  key: string = 'warrantyId';
  storeName: string = 'warrantyInformation';
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public projectService: ProjectService,
    public store: Store<fromFeature.IAllDynamicData>,
    public rootStore: Store<fromRoot.IState>,
    public selectionListService: SelectionListService,
    public mds: MetaDataService,
    public dos: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public openLink(projectId: number): void {
    this.projectService.openProjectTab(projectId, 'project-summary');
  }

  public cancel() {
    this.operation$.next(this.myConstants.operationTypeDetails);
    this.reload();
  }

  public edit(): void {
    this.operation$.next(this.myConstants.operationTypeEdit);
  }

  public reload(): void {
    this.haveSubTypes$.next(false);
    this.warranty = null;
    this.dispatchGetWarranty();
  }

  public updateRecord(warranty: Warranty): void {
    const keyData: IKey = { storeName: 'warrantyInformation', parentId: this.warrantyId, key: 'warrantyId', id: this.warrantyId }
    const emitter: IHomEventEmitter = { requestor: 'self', event: this.myConstants.emitterEventDoUpdateById, action: '', data: null };
    const updateData = new UpdateObjectByIdModel(keyData, 'Warranty', 'Update', 'warrantyId', this.warrantyId, warranty, null, emitter);
    this.store.dispatch(new DynamicObjectActions.UpdateObjectByIdObj({ updateData }));
  }

  public dispatchSubTypes(id: number): void {
    this.warrantySubTypes$.next([]);
   if (id && id > 0) {
     if (!this.projectService.warrantySubTypes.find(x => x.id === id)) {
       this.dos.getByMethodById('WarrantySubType', 'ByWarrantyType', id).subscribe((response: IResponseBase) => {
         if (response.success) {
           this.projectService.warrantySubTypes.push({ id: id, data: response.data });
           this.warrantySubTypes$.next(response.data);
         } else {
           this.errorData$.next(response.errorData);
         }
       })
     } else {
       this.warrantySubTypes$.next(this.projectService.warrantySubTypes.find(x => x.id === id).data);
     }
     this.haveSubTypes$.next(true);
    }
  }

  ngOnInit(): void {
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(
        filter(x => x.requestor === this.storeName),
        map(x => x.show));
    this.store.dispatch(new fromSelectionLists.InitSelectItems({ storeName: 'warrantySubType', parentId: -1 }));

    this.warranty$.subscribe((val: IWarranty) => {
      if (val && !this.listsReady$.getValue()) {
        this.waitForMetaData(val.parentProjectId);
     }
    });

    this.getData();
  }

  loadSelectionLists(parentProjectId: number): void {
    let resolverData: ISelectResolverData[] = this.selectionListService.getListNames(this.storeName);
    this.selectionListService.requestListData(this.storeName, parentProjectId);
    resolverData.forEach(x => x.parentId = x.parentDependent ? parentProjectId : -1);

    this.subscription.add(this.selectionListService.waitForDataToLoad(resolverData)
      .pipe(
        map((complete: boolean) => { return complete }),
        filter((complete: boolean) => complete === true),
        take(1))
      .subscribe((complete: boolean) => {
        this.listsReady$.next(true);
      }));
  }

  getData(): void {
    this.subscription.add(this.store.pipe(select(fromDynamicObject.getObjectDataByType(this.storeName)))
      .subscribe((store: IDynamicObject) => {
      const allStore = cloneDeep(store);
      const objData = allStore.objData.find(x => x.objectId == this.warrantyId);
      if (objData) {
        if (!objData.errorData.length) {
          if (objData.event) {
            this.store.dispatch(new DynamicObjectActions.ClearEventObj({ storeName: this.storeName, objectId: this.warrantyId }));
            this.operation$.next(this.myConstants.operationTypeDetails);
          } else {
            const data: IWarranty = objData.data;
            this.warranty$.next(this.warranty = data);
            this.dispatchSubTypes(this.warranty.warrantyType_warrantyTypeId);
         }
        } else {
          this.errorData$.next(objData.errorData);
          this.mds.setErrorMessages(objData.errorData);
        }
      } else {
        this.dispatchGetWarranty();
      }
      }
    ));
  }

  dispatchGetWarranty() {
    this.store.dispatch(new DynamicObjectActions.GetObject({
      detailRequest: {
        controllerName: 'Warranty',
        storeName: this.storeName,
        rowKeyId: this.key,
        objectId: this.warrantyId,
        displayFields: [],
        metaUrl: '',
        controllerMethod: 'DetailsWithMetaData',
        methodParameters: this.warrantyId.toString()
      }
    }));
  }

  waitForMetaData(projectId: number): void {
    this.subscription.add(this.store.select(metaDataExists(this.storeName))
      .pipe(
        map((exists: boolean) => {
          return exists;
        }),
        filter((exists: boolean) => exists === true),
        take(1))
      .subscribe((exists: boolean) => {
        this.loadSelectionLists(projectId);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
