export interface IMultiSelectSettings {
  enableSearch: boolean;
  fixedTitle: boolean;
  showPagingInfo: boolean;
  dynamicTitleMaxItems: number;
  totalItems: number;
  itemsPerPage: number;
  maxHeight?: string;
  showCheckAll?: boolean;
  showUncheckAll?: boolean;
  closeOnSelect?: boolean;
  selectionLimit?: number;
  autoUnselect?: boolean;
  displayAllSelectedText?: boolean;
  checkedStyle?: 'checkboxes' | 'glyphicon' | 'fontawesome';
  buttonClasses?: string;
  itemClasses?: string;
  containerClasses?: string;
  searchButtonClasses?: string;
  searchGlyphiconClasses?: string;
  pageInfoClasses?: string;
  uncheckAllOnReload?: boolean;
  pullRight?: boolean;
  showDropDownOnLoad?: boolean;
}

export class MultiSelectSettings implements IMultiSelectSettings {

  constructor(
    public enableSearch: boolean = false,
    public fixedTitle: boolean = false,
    public showPagingInfo: boolean = true,
    public dynamicTitleMaxItems: number = 1,
    public totalItems: number =  0,
    public itemsPerPage: number =  0,
    public maxHeight: string = '30rem', //300px is the default
    public showCheckAll: boolean = false,
    public showUncheckAll: boolean = true,
    public closeOnSelect: boolean = false,
    public displayAllSelectedText: boolean = true, //Display the allSelected text when all options are selected
    public autoUnselect: boolean = false,
    public selectionLimit: number =  0, //0: no limit
    public itemClasses: string = '',
    public containerClasses: string = 'dropdown__content--openbelow', //default is 'dropdown-inline'
    public checkedStyle: any = 'fontawesome',
    public buttonClasses: string = 'app-btn app-btn--secondary app-btn-box',
    public searchButtonClasses: string = 'app-btn app-btn--secondary app-btn-box ', //btn-sm
    public searchGlyphiconClasses: string = 'fas fa-filter app-icon--primary',
    public pageInfoClasses: string = 'app-dropdown-paging-info',
    public uncheckAllOnReload: boolean = false,
    public pullRight: boolean = false,
    ) {
  }
}