import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FwModule } from '../../../../fw/fw.module';
import { FwSharedModule } from '../../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../../shared/shared.module';
import { DynamicListModule } from '../../../../fw/dynamic-list/dynamic-list.module';
import { DynamicFormModule } from '../../../../fw/dynamic-forms/dynamic-form.module';
import { PortalSharedModule } from '../../portal-shared/portal-shared.module';

//containers
import { UserSettingsComponent,
      UserPreferencesContainerComponent } from './index';

//components
import { UserDelegatesComponent } from './components/user-delegates/user-delegates.component';
import { UserDelegateComponent } from './components/user-delegate/user-delegate.component';
import { UserNotesComponent } from './components/user-notes/user-notes.component';
import { UserPreferencesComponent } from './components/user-preferences/user-preferences.component';

//guards
import { IsCurrentGuard } from '../../../store/services/is-current-guard.service';

//resolvers
import { EntityMetaDataResolver } from '../../resolvers';
import { LoadingPageComponent } from '../../../../shared/components';

export const ROUTES = [
  {
    path: 'user-settings',
    outlet: 'modal',
    component: UserSettingsComponent,
    children: [
      {
        path: 'loading/:container',
        component: LoadingPageComponent
      },
      {
        path: 'user-preferences',
        data: {
          storeName: 'userPreferences',
          entityName: 'UserPreference'
        },
        resolve: { hasMetaData: EntityMetaDataResolver },
        component: UserPreferencesContainerComponent
      },
      {
        path: 'user-notes', component: UserNotesComponent
      },
      {
        path: 'user-delegates', component: UserDelegatesComponent,
        children: [
          {
            path: 'user-delegate/:id',
            outlet: 'commonDetailOutlet',  //for dynamic-list, must add to navigateDetail in  dynamic-list.component.ts
            component: UserDelegateComponent,
            canActivate: [IsCurrentGuard]
          }
        ],
      },
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FwSharedModule,
    SharedModule,
    FwModule,
    DynamicListModule,
    DynamicFormModule,
    PortalSharedModule,
    RouterModule.forChild(ROUTES)
  ],
  declarations: [
    UserDelegatesComponent,
    UserDelegateComponent,
    UserNotesComponent,
    UserPreferencesComponent,
    UserPreferencesContainerComponent,
    UserSettingsComponent
  ],
  providers: [IsCurrentGuard,
    EntityMetaDataResolver
  ]
})
export class UserSettingsModule { }
