import { Component, OnInit, Input, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import {  map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ISchedule, IProviderLocationScheduleMoveUp, ProviderLocationScheduleMoveUp } from '../../../view-models/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IErrorData } from '../../../../../shared/interfaces';
import { ScheduleEvent, ScheduleStore } from '../../enums/schedule.enums';
import {
  IAllDynamicData,
  UpdateObjectCustomList,
  CreateObjectList,
  getListByType,
  IDynamicListState,
  IListObjectData,
  ClearEventList
} from '../../../../../fw/dynamic-list/store/index';
import { UpdateObjectCustomModel, IKey, CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { MetaDataService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'schedule-moveup',
  templateUrl: './schedule-moveup.component.html',
  providers: [MetaDataService]
})
export class ScheduleMoveupComponent implements OnInit, OnDestroy {
  @Input() schedule: ISchedule;
  @Input() workOrderId: number;
  @Input() providerLocationId: number;
  @Input() canIEdit: boolean;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public form: FormGroup;
  public operation: string;
  public objectData$: BehaviorSubject<IProviderLocationScheduleMoveUp> = new BehaviorSubject(null);
  public displayFields: string[] = ['requestedDate', 'immediateMoveUp', 'moveUpText'];
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  key: string = 'schedule_scheduleId';
  subscription: Subscription = new Subscription();

  constructor(public store: Store<IAllDynamicData>,
    public dynamicDetailService: DynamicDetailService,
    public emitterService: HomEventEmitterService,
    public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public isValid(): boolean {
    let data: IProviderLocationScheduleMoveUp = this.form.getRawValue();
    return !data.requestedDate && !data.moveUpText ? false : true;
  }

  public cancel(): void {
    this.customEvent.emit({ requestor: 'schedule-moveup', event: ScheduleEvent.cancelMoveUp, action: '', data: null });
  }

  public save(): void {
    this.loading$.next(true);
    let rec: IProviderLocationScheduleMoveUp = this.setCommonProperties(this.form.getRawValue());
    const event: string = this.operation === this.myConstants.operationTypeCreate ? this.myConstants.emitterEventCreate : this.myConstants.emitterEventUpdate;
    const emitter: IHomEventEmitter = { requestor: 'schedule-moveup', event: event, action: '', data: null };

    if (!this.objectData$.value.providerLocationScheduleMoveUpId) {
      rec.createDate = new Date().toDateString();
      const createData = new CreateObjectModel(ScheduleStore.providerLocationScheduleMoveups, this.workOrderId, 'ProviderLocationScheduleMoveUp', 'Create', rec, null, emitter);
      this.store.dispatch(new CreateObjectList({ createData }));
    } else {
      const keyData: IKey = { storeName: ScheduleStore.providerLocationScheduleMoveups, parentId: this.workOrderId, key: this.key, id: this.schedule.scheduleId }
      const updateData = new UpdateObjectCustomModel(keyData, 'ProviderLocationScheduleMoveUp', 'Update', rec, null, emitter);
      this.store.dispatch(new UpdateObjectCustomList({ updateData }));
    }
  }

  ngOnInit(): void {
    this.mds.setFieldDefinitions(ScheduleStore.providerLocationScheduleMoveups);
    this.newRequest();
    this.mds.mdsReady$.subscribe((ready: boolean) => {
      if (ready) {
        this.loadForm(); 
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  newRequest(): void {
    this.operation = !this.schedule.hasMoveUp ? this.myConstants.operationTypeCreate : this.canIEdit ? this.myConstants.operationTypeEdit : this.myConstants.operationTypeDetails,

    this.objectData$.next(null);

    //listen for updates to this store
    this.subscription.add(this.store.pipe(select(getListByType(ScheduleStore.providerLocationScheduleMoveups)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.workOrderId)))
      .subscribe((state: IListObjectData) => {
        this.loading$.next(false);
        const stateData = cloneDeep(state);
        if (stateData && !stateData.working) {
          if (!this.objectData$.value && stateData.listMetaData) {
            const entity = stateData.data.find(x => x[this.key] == this.schedule.scheduleId);
            this.objectData$.next(!entity || entity === undefined ? new ProviderLocationScheduleMoveUp(this.providerLocationId, this.schedule.scheduleId) : cloneDeep(entity));
          }
          this.errorData$.next(stateData.errorData);
          this.mds.setErrorMessages(state.errorData);
          if (!stateData.errorData || !stateData.errorData.length) {
            if (stateData.event) {
              this.customEvent.emit({ requestor: 'schedule-moveup', event: ScheduleEvent.moveUpUpdateSuccessful, action: '', data: null });
              this.store.dispatch(new ClearEventList({ storeName: ScheduleStore.providerLocationScheduleMoveups, parentId: this.workOrderId }));
            }
          }
        }
      }));
  }

  loadForm() {
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.objectData$.value, this.operation);
  }

  setCommonProperties(formData: IProviderLocationScheduleMoveUp): IProviderLocationScheduleMoveUp {
    let rec = cloneDeep(this.objectData$.value);
    rec.requestedDate = formData.requestedDate;
    rec.immediateMoveUp = formData.immediateMoveUp;
    rec.moveUpText = formData.moveUpText;
    return rec;
  }
}
