import { Injectable } from '@angular/core';

import { IPoImportHeaderViewModel, IPoImportLine, ISkuPrice, IPoImportHeader } from '../../../app/portals/view-models/index';
import { UserPriviledgesService } from '../../../auth/services/index';

@Injectable()
export class PoImportDynamicListService {

  constructor(public userPriviledgesService: UserPriviledgesService) { }

  handleCustomMethod(methodName: string, row: any, portalEntityId: number): any {
    switch (methodName) {
      case 'getPoLineRowCss':
        return this.getPoLineRowCss(row);
      case 'getPriceInErrorCss':
        return this.getPriceInErrorCss(row);
      case 'canImportPo':
        return this.canImportPo(row);
      case 'canGoToProject':
        return this.canGoToProject(row);
      case 'canSeePriceWizard':
        return this.canSeePriceWizard(row);
      case 'canDeletePoImport':
        return this.canDeletePoImport(row);
      case 'canDeletePoImportHeader':
        return this.canDeletePoImportHeader(row);
      default:
        return false;
    }
  }
  
/*
      Loaded,
      Matched,
      Staged,
      Error,
      Skipped,
      Duplicate
*/
  canDeletePoImport(row: IPoImportHeaderViewModel): boolean {
    return row && (row.poImportStatus === 'Loaded' || row.poImportStatus === 'Error' || ( this.userPriviledgesService.canDeleteStagedPoImports$.value && row.poImportStatus == 'Staged'));
  }

  canDeletePoImportHeader(row: IPoImportHeader): boolean {
    return row && (row.poImportStatus === 'Loaded' || row.poImportStatus === 'Error' || row.poImportStatus === 'Duplicate' || ( this.userPriviledgesService.canDeleteStagedPoImports$.value && row.poImportStatus == 'Staged'));  }

  canImportPo(row: IPoImportHeaderViewModel): boolean {
    return row && (row.poImportStatus !== 'Loaded' && row.poImportStatus !== 'Skipped' && row.poImportStatus !== 'Duplicate');
  }

  canGoToProject(row: IPoImportHeaderViewModel): boolean {
    return row && row.linkedProjectId > 0;
  }

  canSeePriceWizard(row: IPoImportLine): boolean {
    return row && (row.poImportStatus === 'Error' && !row.overrideSystemPrice && row.sku_skuId > 0);
  }

  getPoLineRowCss(row: IPoImportHeaderViewModel): string {
    let css: string = '';
    if (row) {
      switch (row.poImportStatus) {
        case 'Loaded':
          css = 'dynamic-list__item--success';
          break;
        case 'Error':
          css = 'dynamic-list__item--danger';
          break;
        case 'Skipped':
          css = 'dynamic-list__item--disabled';
          break;
        default:
          css = '';
      }
    }
    return css;
  }

  getPriceInErrorCss(row: ISkuPrice): string {
    return row && row.hasOwnProperty('errorText') && row.errorText.length > 0 ? 'dynamic-list__item--danger' : '';
  }

}
