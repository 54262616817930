<fw-info>
  <div class="fw-legend">
    <fieldset>
      <legend>{{title}}</legend>
      <div *ngFor="let item of items" class="fw-legend__item">
        <div class="fw-legend__item--icon {{ item.iconCss }}"><i class="{{ item.icon }}"></i></div>
        <div class="fw-legend__item--text {{ item.textCss }}">{{item.text }}</div>
      </div>
    </fieldset>
  </div>
  <ng-content></ng-content>
  </fw-info>
