import { Component, Input, Output, EventEmitter, OnChanges, OnInit, SimpleChanges,  Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../shared/constants/index';
import { IErrorData } from '../../../shared/interfaces/index';
import { IFormDefinition  } from '../../dynamic-forms/index';
import { ICustomButton } from '../../fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../fw-shared/enums/button-type.enum';
import { MetaDataService } from '../../dynamic-list/services/index';

@Component({
    selector: 'fw-dynamic-form-custom',
    templateUrl: './dynamic-form-custom.component.html',
    providers: [ MetaDataService ] //set providers for metadata service in the custom component if need to monitor change events at that level
})
export class DynamicFormCustomComponent implements OnChanges, OnInit{

  @Input() data: any;  
  @Input() storeName: string;
  @Input() displayFields: string[];
  @Input() operation: string;
  @Input() formDefinition: IFormDefinition = null; //if don't pass in will not render title-bar
  @Input() parentId: number;
  @Input() canIEdit: boolean = true;
  @Input() staleData: boolean = false;
  @Input() showEdit: boolean = false;
  @Input() showDelete: boolean = false;
  @Input() showRefresh: boolean = false;
  @Input() printUrl: string = '';
  @Input() customIcons: ICustomButton = null;
  @Input() formCssName: string = '';
  @Input() errorMessage: string = '';
  @Input() errorData: Array<IErrorData> = [];

  @Output() public formEvent = new EventEmitter<IHomEventEmitter>();

  public form: FormGroup;
  public submitted = false;
  dataCopy: any;

  constructor(
    public  mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants) {
  }

  //notify the container of the event
  //container is listening to the store
  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case ButtonType.cancel:
        //reset
        this.mds.clearValidationMessages(this.displayFields);
        this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.data, this.myConstants.operationTypeDetails);
        this.formEvent.emit(event);
        break;
      case ButtonType.save:
        //return the data in the form
        const data = this.form.value;
        const newEvent: IHomEventEmitter = { event: event.event, action: event.action, data: data, requestor: event.requestor };
        this.formEvent.emit(newEvent);
        break;
      default:
        this.formEvent.emit(event);
        break;
    }
  }

    /*
        Watches for changes to any of the input parameters.
        When an error is returned, will reload the form
        ** ensure you have set the view model input field to what you sent to the server before setting either error input field
    */
  ngOnChanges(changes: SimpleChanges) {
        //field level error message(s) were returned
      if (changes['errorData']
        && !changes['errorData'].isFirstChange()
        && changes['errorData'].currentValue
      ) {
       // this.errorData = changes['errorData'];
        this.mds.setErrorMessages(changes['errorData'].currentValue);
      }

    if ((changes["data"] && !changes['data'].isFirstChange())
      ||
      (changes["requestTime"] && !changes['requestTime'].isFirstChange())
      ) {
        this.loadForm();
      }

      //updated a record but keeping detail open
      if (changes['operation']
        && !changes['operation'].isFirstChange()
        ) {
        this.loadForm();
      }
    }

    /*
        Form init, will create the fields and load the values.
        detail component will subscribe to params
    */
    ngOnInit() {
      this.mds.setFieldDefinitions(this.storeName);

      if (this.mds.fieldDefinitionsLoaded) {
          //this.mds.fieldDefinitions = this.fieldDefinitions;
          //if specific fields were not passed in, use all fields definied in field definition
        if (!this.displayFields || this.displayFields.length === 0) {
            console.log('DEV WARNING:  displays fields not set by component, dynamic form will use all fields defined in field definitions');
          }
          this.mds.loadSelectionLists(this.displayFields, this.myConstants.requestorTypeDetails, this.parentId);
          this.loadForm();
        }
    }

  /*
    Clears the form and loads the fields and values
  */
  loadForm() {

    this.dataCopy = cloneDeep(this.data);

    if (!this.mds.fieldDefinitionsLoaded) {
      console.log('DEV ERROR:  dynamic-form and missing definition');
    }

    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.dataCopy, this.operation);
  }

  dataLoaded(): boolean {
    const hasLists: boolean = this.mds.hasSelectionLists(this.displayFields);
    return this.mds.fieldDefinitionsLoaded &&
      (!hasLists || (hasLists && this.mds.selectionListsLoaded)
      );
  }
}
