import { Injectable, Inject} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {  map, filter, first, tap, take} from 'rxjs/operators';

import { IListFilter } from '../../../fw/dynamic-list/interfaces/index';
import { IAppConstants, appConstants } from '../../../shared/constants/index';
import { IProviderLocationService } from '../view-models/index';

//store actions and reducers
import * as fromSelectionLists from '../../../shared/store/selectionLists/index';
import { AccessLevel } from '../../../fw/dynamic-list/enums/access-level.enums';
import { ISelectListData } from '../../../shared/store/selectionLists/index';

@Injectable()
export class ProviderLocationServiceListResolver  {

  providerLocationId: number = 0;
  filter: IListFilter = {
    isLookup: false, 
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true, //do not want paged data
    currentPage: 1,
    searchTerm: [],
    orderTerm: null
  };
  //isLookup is false because we need extra data

  constructor(public store: Store<fromSelectionLists.IStoreState>,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProviderLocationService[]> {
    this.providerLocationId = +route.paramMap.get('id');
    const operation: string = route.paramMap.get('operation');

    if (!this.providerLocationId || this.providerLocationId === 0 || operation === this.myConstants.operationTypeCreate) {
      return of([]);
    }

    this.requestData();

    return this.waitForDataToLoad();
  }

  requestData(): void {
    this.store.select(fromSelectionLists.getSelectionListByTypeByParent('providerLocationService', this.providerLocationId))
      .pipe(take(1))
      .subscribe((listData: ISelectListData) => {
        if (!listData || listData.data === null) {
          this.store.dispatch(
            new fromSelectionLists.GetEntityListById('ProviderLocationService', 'ByProviderLocation', this.providerLocationId, this.filter));
        }
      });
  }

  waitForDataToLoad(): Observable<IProviderLocationService[]> {
    return this.store.select(fromSelectionLists.getSelectionListByTypeByParent('providerLocationService', this.providerLocationId))
      .pipe(
        filter((list: ISelectListData) => list && list.data !== null),
        map((list: ISelectListData) => { return list.data; }),
        first()
      );
  }
}
