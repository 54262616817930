/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { ISkuXmlMapping } from '../../../view-models';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { AdminDashEvent } from '../../enums/admin-dashboard.enums';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { SkuCreationWizardOrigin } from '../../../pricing-shared/enums/pricing-shared.enums';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'sku-xml-mappings',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sku-xml-mappings.component.html'
})
export class SkuXmlMappingsComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public adminDashboardService: AdminDashboardService,
    public modalService: ModalService,
    public store: Store<fromStore.IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants) { }


  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminDashEvent.createSku:
        this.createSku(event.data);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(() => {
      this.listDefinition$.next(this.adminDashboardService.loadSkuMappingListDefinition(this.adminDashboardService.homeDepotGcId));
    });
  }

  createSku(data: ISkuXmlMapping): void {
    this.modalService.open({
      title: 'Sku Creation Wizard',
      path: 'sku-creation-wizard/' + data.skuXmlMappingId.toString() + '/' + this.adminDashboardService.homeDepotGcId + '/' + SkuCreationWizardOrigin.skuXmlMapping,
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.small,
    });
  }
}
