import { Component, OnInit, Input } from '@angular/core';
import { ScreenService } from '../../../fw/fw-shared/services/screen.service';

@Component({
  selector: 'fw-info',
  templateUrl: './fw-info.component.html'
})

export class InfoComponent implements OnInit {

  constructor(public screenService: ScreenService) { }

  @Input() infoTitle: string = ''; 
  @Input() icon: string = 'fas fa-info-circle';
  @Input() customColor: string = '';
  @Input() customCss: string = '';


  public isVisible: boolean = false;
  isMobileOrTablet: boolean = false;
  ngOnInit() {
    this.isMobileOrTablet = window.innerWidth <= this.screenService.largeBreakpoint;
  }

  public toggle(event): void {
    this.isVisible = !this.isVisible;
    event.stopPropagation();
  }

}
