import { Component, Output, EventEmitter, Input,  Inject } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { ICustomButton } from '../../interfaces/i-custom-button';

@Component({
  selector: 'fw-button-title-bar',
  templateUrl: './fw-button-title-bar.component.html'
})
export class ButtonTitleBarComponent {
  @Input() title: string = '';
  @Input() entityLabel: string = '';
  @Input() entityColor: string = '';
  @Input() entityStatus: string = '';
  @Input() includeBorder: boolean = true;
  @Input() canIEdit: boolean = true;
  @Input() operation: string = '';
  @Input() disabled: boolean = false;
  @Input() staleData: boolean = false;
  @Input() showEdit: boolean = true;
  @Input() showDelete: boolean = false;
  @Input() showRefresh: boolean = false;
  @Input() printUrl: string;
  @Input() customButtons: ICustomButton[];
  @Input() forHighLevelSummary: boolean = false;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  constructor(
    @Inject(appConstants) public myConstants: IAppConstants) {}

  public onEvent(event: IHomEventEmitter): void {
    this.customEvent.emit(event);
  }
}
