<div *ngIf="listDefinition" class="warranty-scripts">
  <div class="warranty-scripts__list">
    <div class="list-wrapper">
      <fw-dynamic-list *ngIf="listDefinition"
                [listTitle]="title"
                [vmListDefinition]="listDefinition">
      </fw-dynamic-list>
    </div>
  </div>
  <div class="warranty-scripts__actions">
    <i class='fas fa-first-aid app-icon--warning'></i>
    <span (click)="newWarranty()" class="app-link" title="Open New Warranty">Open New Warranty</span>
    <span class="app-text">(You will be directed to a new page)</span>
  </div>
  <div class=" warranty-scripts__scripts">
    <warranty-scripts [workOrderId]="workOrderId"
                      [defaultWarm]="true"
                      (cancelEvent)="onCancel($event)">
    </warranty-scripts>
  </div>
</div>
