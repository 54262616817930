import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject  } from 'rxjs';
import {  take, filter,   } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../../shared/constants/index';
import { IJob, IJobSchedule, JobScheduleJob, IJobScheduleJob } from '../../../../view-models/index';

//store actions, reducers, interfaces
import * as DynamicListActions from '../../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromStore from '../../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getSelectedRecord, getSelectedParentListDefinition } from '../../../../../../fw/dynamic-list/store/index';
import { CreateObjectModel, UpdateObjectCustomModel, IKey } from '../../../../../../fw/dynamic-list/store/interfaces/index';
import { IDetailContainerConfig } from '../../../../../../fw/dynamic-detail/interfaces';
import { DynamicDetailService } from '../../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { UserPriviledgesService } from '../../../../../../auth/services';

@Component({
  selector: 'user-job',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-job.component.html'
})
export class UserJobsJobComponent implements OnInit, OnDestroy  {
  public operation: string;
  public objectData$: BehaviorSubject<IJobScheduleJob> = new BehaviorSubject(null);
  public displayFields = ['job', 'jobScheduleJobName', 'jobSchedule', 'numberSubscribers', 'haveAlert'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public jobs: IJob[] = [];
  public jobSchedules: IJobSchedule[] = [];

  initialLoadCompleted: boolean = false;
  myPathName: string = '';
  myStoreName: string = '';
  key: string = '';
  objectId: number = 0;
  controllerName: string;
  subscription: Subscription = new Subscription();

  constructor(
      public activatedRoute: ActivatedRoute,
      public store: Store<fromStore.IAllDynamicData>,
      public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public userPriviledgesService: UserPriviledgesService,
      @Inject(appConstants) public myConstants: IAppConstants) { }


  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.setDetailConfig(paramMap);
      this.getDetail();
      this.setListeners();
    });
  }

  getDetail(): void {
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(this.myStoreName, -1, this.key, this.objectId)), take(1))
      .subscribe(entity => {
        this.objectData$.next(!entity && this.operation === this.myConstants.operationTypeCreate ? new JobScheduleJob() : entity);
      }));

    this.subscription.add(this.store
      .pipe(select(getSelectedParentListDefinition(this.myStoreName, -1)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.myPathName = listDefinition.detailRoutePath;
        this.controllerName = listDefinition.controllerName;
      }
      ));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    this.detailConfig$.next(params);
  }

  setListeners(): void {
    this.subscription.add(this.emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e.requestor !== this.myPathName) {
          return;
        } //can change this to create event listeners for each object controller in stead
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case this.myConstants.emitterEventCreate:
            this.createRecord(e);
            break;
          default:
            break;
        }
      }));
  }

  /*
    Insert the new record
*/
  createRecord(e: IHomEventEmitter): void {
    let obj = this.setCommonProperties(e.data);

    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.myStoreName, -1, this.controllerName, 'Create', obj, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  /*
      Update the record
  */
  updateRecord(e: IHomEventEmitter): void {
    let obj = this.setCommonProperties(e.data);
    const keyData: IKey = { storeName: this.myStoreName, parentId: -1, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', obj, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  setCommonProperties(data: IJobScheduleJob): IJobScheduleJob {
    let obj: IJobScheduleJob = cloneDeep(this.objectData$.value);
    obj.job_jobId = data['job']['jobId'];
    obj.jobSchedule_jobScheduleId = data['jobSchedule']['jobScheduleId'];
    obj.providerUser_providerUserId = this.userPriviledgesService.currentUserId$.value;
    obj.jobScheduleJobName = data.jobScheduleJobName;

    return obj;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
