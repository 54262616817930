import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { ISku, Sku, ISkuXmlMapping } from '../../../view-models';
import { ILookupData } from '../../../../../shared/interfaces';

//store actions, reducers, interfaces
import { CreateObjectModel, UpdateObjectByIdModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import * as fromRoot from '../../../../store/reducers/index';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getSelectedRecord, getEntityListByParentId } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import { SkuCreationWizardEvent, SkuCreationWizardAction } from '../../enums/pricing-shared.enums';
import { IListObjectData, ClearEventList, CreateObjectList, UpdateObjectByIdList } from '../../../../../fw/dynamic-list/store';
//services
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { AdminDashboardService } from '../../../admin-dashboard/services/admin-dashboard.service';
import { PricingSharedService } from '../../services/pricing-shared.service';
import { AdminService } from '../../../admin/services/admin.service';
import { IPricingCreateResult } from '../../interfaces/i-pricing-create-result';

@Component({
  selector: 'sku',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sku.component.html'
})
export class SkuComponent implements OnInit, OnDestroy {
  myPathName: string = '';
  myStoreName: string = '';
  key: string = '';
  activeIndex: number = -1;
  operation: string;
  objectId: number = 0;
  skuXmlMappingId: number = 0;
  gcId: number = 0;
  paramMap: ParamMap;
  defaultStatus: ILookupData;
  actionOnSuccess: string;
  inWizard: boolean = false;
  skuMapping: ISkuXmlMapping;
  newSkuName: string;
  subscription: Subscription = new Subscription();

  public objectData$: BehaviorSubject<ISku> = new BehaviorSubject(null);
  //may be coming in as skuXmlMapping or sku: these fields must be seeded for either
  public displayFields: string[] = ['skuName', 'skuStatus', 'skuDescription', 'allowNegativePrice', 'skuType',  'unitMeasure',  'accountingAccountCode', 'serviceCodeOverride'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public adminDashboardService: AdminDashboardService,
    public adminService: AdminService,
    public pricingService: PricingSharedService,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public rootStore: Store<fromRoot.IState>,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    //can be coming in with create from skuList by GC or skuXmlMappings (techincally by GC, though only loads for HD)
    //or with skuId > 0 for detail/edit
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.paramMap = paramMap;
      this.activeIndex = +paramMap.get('rowIndex');
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.inWizard = paramMap.get('wiz') ? true : false;
      this.gcId = +paramMap.get('gcId') || this.adminDashboardService.homeDepotGcId;
      this.skuXmlMappingId = +paramMap.get('mapId');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      if (this.objectId === 0) {
        this.getDefaultStatus();
      }
      if (this.skuXmlMappingId > 0) {
        this.getSkuMappingData();
      } else {
        this.getDetail();
      }
    });

    this.subscription.add(this.objectData$.pipe(
      filter((data) => data !== null))
      .subscribe((data: ISku) => {
        this.setDetailConfig();
      }));


    this.subscription.add(this.emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventClose:
            if (this.inWizard) {
              e.event = SkuCreationWizardEvent.cancel;
              this.emitterService.emitSkuWizardEvent(e);
            } else {
              this.emitterService.emitListEvent(e);
            }
            break;
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case SkuCreationWizardEvent.createSku:
            this.actionOnSuccess = e.action;
            if (this.skuXmlMappingId > 0) {
              this.createSkuUpdateMappingRecord(e);
            } else {
              this.createRecord(e);
            }
            break;
          case this.myConstants.emitterEventCreate:
              this.createRecord(e);
            break;
          default:
            break;
        }
      }));

    this.listenForUpdateSuccess();
  }

  setDetailConfig(): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(this.paramMap);
    params.parentId = this.gcId;
    if (this.inWizard) {
      params.showTitle = false;
      params.customButtons = [
        { title: 'Create and Close', css: 'app-btn--primary', event: SkuCreationWizardEvent.createSku, action: SkuCreationWizardAction.close },
        { title: 'Create and Next', css: 'app-btn--primary', event: SkuCreationWizardEvent.createSku, action: SkuCreationWizardAction.next }
      ];
    } 
    this.detailConfig$.next(params);
  }

  getDefaultStatus(): void {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('skuStatus')))
      .subscribe((data: ILookupData[]) => {
        this.defaultStatus = data ? data.find(x => x.lookupMeaning === 'Current') : null;
      }));
  }

  getSkuMappingData(): void {
    const listDef: IListDefinition = this.adminDashboardService.loadSkuMappingListDefinition(this.gcId);

    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(listDef.storeName, this.gcId, listDef.rowKeyId, this.skuXmlMappingId)), take(1))
      .subscribe(entity => {
        this.skuMapping = cloneDeep(entity);
        this.objectData$.next(new Sku(this.skuMapping.generalContractor_generalContractorId, this.skuMapping.mappingText, this.defaultStatus ? this.defaultStatus.lookupValueId : -1));
      })
    );
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.myStoreName, this.gcId, this.key, this.objectId)))
      .subscribe(entity => {
        this.objectData$.next(!entity && this.operation === this.myConstants.operationTypeCreate
          ? new Sku(this.gcId, '', this.defaultStatus.lookupValueId)
          : cloneDeep(entity));
      })
    );
  }

  /*
      Insert the new record
  */
  createRecord(e: IHomEventEmitter) {
    let sku = this.setCommonProperties(e.data);

    const createData = new CreateObjectModel(this.myStoreName, this.gcId, 'Sku', 'Create', sku, null, e);
    this.store.dispatch(new CreateObjectList({ createData }));
  }
  /*
      Insert the new sku and Update sku xml mapping
  */
  createSkuUpdateMappingRecord(e: IHomEventEmitter) {
    let sku = this.setCommonProperties(e.data);

    const keyData: IKey = { storeName: this.myStoreName, parentId: this.gcId, key: this.key, id: this.skuXmlMappingId }
    const obj = { model: this.skuMapping, sku: sku };
    const updateData = new UpdateObjectByIdModel(keyData, 'SkuXmlMapping', 'CreateSku', this.key, this.skuXmlMappingId, obj, null, e);
    this.store.dispatch(new UpdateObjectByIdList({ updateData }));
  }

  /*
      Update the record
  */
  updateRecord(e: IHomEventEmitter) {
    let sku = this.setCommonProperties(e.data);
    sku.skuId = this.objectId;
    const keyData: IKey = { storeName: this.myStoreName, parentId: this.gcId, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectByIdModel(keyData, 'Sku', 'Update', this.key, this.objectId, sku, null, emitter);
    this.store.dispatch(new UpdateObjectByIdList({ updateData }));
  }

  /*
      Set common create/update properties
  */
  setCommonProperties(formData: ISku): ISku {
    let data = cloneDeep(this.objectData$.value);
    this.newSkuName = formData.skuName;
    data.skuName = formData.skuName;
    data.skuDescription = formData.skuDescription;
    data.skuType = formData.skuType['name'];
    if (formData.hasOwnProperty('skuStatus')) {
      data.skuStatus_lookupValueId = formData['skuStatus'].lookupValueId;
    }
    data.unitMeasure_unitMeasureId = formData['unitMeasure']['unitMeasureId'];
    data.allowNegativePrice = formData.allowNegativePrice;
    data.accountingAccountCode = formData.accountingAccountCode;
    data.serviceCodeOverride = formData.serviceCodeOverride;
    return data;
  }

  listenForUpdateSuccess() {
    this.subscription.add(this.store.pipe(select(getEntityListByParentId(this.myStoreName, this.gcId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.data && !state.working && !state.errorData.length && state.event && this.inWizard) {
          const event: IHomEventEmitter = cloneDeep(state.event);
          if (event.event !== this.myConstants.emitterEventListReload) {
            //emit event to container
            const data = cloneDeep(state.data);
            let result: IPricingCreateResult = { parentId: 0, parentName: ''};
            if (this.skuXmlMappingId > 1) {
              //get sku id from sku mapping record
              const skuXmlMapping: ISkuXmlMapping = data.find(x => x.skuXmlMappingId == this.skuXmlMappingId);
              result.parentId = skuXmlMapping ? skuXmlMapping.sku_skuId : 0;
              result.parentName = skuXmlMapping ? skuXmlMapping.skuName : ''
            } else {
              //get sku id from sku record
              const sku = data.find(x => x.skuName == this.newSkuName);
              result.parentId = sku ? sku.skuId : 0;
              result.parentName = sku ? sku.skuName : '';
            }
            event.event = SkuCreationWizardEvent.skuCreated;
            event.data = result;
            this.emitterService.emitSkuWizardEvent(event);
            this.store.dispatch(new ClearEventList({ storeName: this.myStoreName, parentId: this.gcId }));
          }
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
