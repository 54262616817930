/** Imports for Angular modules */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/** Imports for services */
import { DomainObjectService } from '../../../shared/services/domain-object.service';

/** Imports for components */
import { PortalBaseService } from './services/portal-base.service';

/**
 * Component for the Compliance Manager Portal
 */
@Component({
    templateUrl: './portal.component.html'
})
export class PortalComponent implements OnInit {
  editLayout: boolean = false;

    // Component constructor, for injecting services
  constructor(public activeRoute: ActivatedRoute,
        public portalBaseService: PortalBaseService,
        public domainObjectService: DomainObjectService
    ) { }

  ngOnInit(): void {
    this.portalBaseService.portalSections = [];
    this.portalBaseService.portalData = null;

    this.portalBaseService.errorMessage = '';
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.portalBaseService.portalId = +paramMap.get('portalId');
      this.portalBaseService.portalEntityId = paramMap.has('portalEntityId ') ? +paramMap.get('portalEntityId ') : -1;
      if (Number.isNaN(this.portalBaseService.portalEntityId)) {
        this.portalBaseService.portalEntityId = -1;
      }
      this.portalBaseService.getManagerPortalDetails();
    });
  }


    /**
     * 
     * Listener:  attached to <widget-layout-editor> html tag, on event emission from <widget-layout-editor this will be executed
     *          Will hide/show the layout section
     */
    onLayoutEditMode(inEdit: boolean) {
        this.editLayout = inEdit;
    }

    /**
     * 
     * Listener:  attached to <widget-layout-editor> html tag, on event emission from <widget-layout-editor this will be executed
     *          Will reload the page - eventually
     */
    onLayoutUpdated(updated: boolean) {
        //always true at this time, not sure if ever should not be - not sure if need to pass in anything
        // Load Portal
        this.portalBaseService.getPortalSections();
    }
}
