import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IEmailFileImport, IDocumentType } from '../../../view-models/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces/index';
import { IFormDefinition, FormDefinition } from '../../../../../fw/dynamic-forms';
import { IListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { IUploadFile, IUploadStatus } from '../../../../file-upload/interfaces';
import { FileUploadType, DocumentTypeName } from '../../../../file-upload/enums/file-upload.enums';

import { UtilitiesStore } from '../../enums/utilities.enums';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { DomainObjectService } from '../../../../../shared/services/domain-object.service';
import { UtilitiesService } from '../../services/utilities.service';
import { FilePreviewerComponent } from '../../../../file-upload/components';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';

@Component({
  selector: 'email-imports-detail',
  templateUrl: './email-imports-detail.component.html'
})
export class EmailImportsDetailComponent implements OnInit, OnDestroy {
  @ViewChild('filePreviewer') public filePreviewer: FilePreviewerComponent;

  public projectId: string;
  public storeName: string;
  public listType: string;
  public displayFields: string[] = ["fromUser", "subject", "sendDate"];
  public uploadFiles$: BehaviorSubject<IUploadFile[]> = new BehaviorSubject(null);
  public senderInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public formDefinition$: BehaviorSubject<IFormDefinition> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public uploadStatuses: IUploadStatus[] = [];
  documentTypes: IDocumentType[];
  emailImportId: number;
  listFilter: IListFilter;
  subscription: Subscription = new Subscription();
  bulkUpload: boolean = false;

  constructor(public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants,
    public emitterService: HomEventEmitterService,
    public dos: DomainObjectService,
    public dynamicDetailService: DynamicDetailService,
    public utilitiesServive: UtilitiesService) {

    this.storeName = UtilitiesStore.dashEmailImports;
    this.listType = FileUploadType.emailFileImport;
  }

  public setProjectId(val: string): void {
    this.projectId = val;
  }

  public removeItem(id: string): void {
    this.loading$.next(true);
    let uploads: IUploadFile[] = this.uploadFiles$.value;
    const index: number = uploads.findIndex(x => +x.id === +id);
    if (index > -1) {
      this.subscription.add(this.dos.deleteByMethodById('EmailFileImport', 'Delete', +uploads[index].id)
        .subscribe((response: IResponseBase) => {
          if (response.success) {
            uploads.splice(index, 1);

          } else {
            uploads[index].errorData = response.errorData
          }
          this.uploadFiles$.next(uploads);
          this.loading$.next(false);
      }));
    }
  }

  public uploadAll(): void {
    const allFiles: IUploadFile[] = this.uploadFiles$.value;
    this.bulkUpload = true;
    this.uploadStatuses = [];
    allFiles.forEach(file => {
      this.uploadStatuses.push({ id: file.id, processed: false });
    })
    if (this.filePreviewer) {
      this.filePreviewer.uploadAll();
    }
 }

  public uploadItem(fileUpload: IUploadFile): void {
    this.loading$.next(true);
    if (!this.bulkUpload) {
      this.uploadStatuses = [];
    }
    this.createDocumentAndUploadFile(fileUpload);
  }

  public clearError(file: IUploadFile): void {
    let data: IUploadFile[] = this.uploadFiles$.value;
    let item: IUploadFile = data.find(x => x.id === file.id);
    item.errorData = null;
    this.uploadFiles$.next(data);
  }

  ngOnInit() {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('documentType')))
      .subscribe((data) => {
        this.documentTypes = data;
      }));

    this.subscription.add(this.emitterService.listEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e.event === this.myConstants.emitterEventClose) {
          const listDefinition = this.utilitiesServive.loadEmailImportsListDefinition();
          this.store.dispatch(new DynamicListActions.GetList({ listDefinition: listDefinition, listFilter: this.listFilter, parentId: listDefinition.parentId }));
        }
      }));

    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.loading$.next(true);
      this.emailImportId = +paramMap.get('id');
      this.setDetailConfig(paramMap);
      this.getData(this.emailImportId);
      this.subscription.add(this.store.pipe(select(fromStore.getListByType(UtilitiesStore.dashEmailImports))).subscribe((objData) => {
        const data = objData.objData[0].data[+paramMap.get('rowIndex')];
        if (!this.listFilter) this.listFilter = objData.objData[0].listFilter;
        this.senderInfo$.next(data);
        this.formDefinition$.next(new FormDefinition(objData.objData[0].objectLabel + this.myConstants.operationTypeDetails, false, false, 'email-imports-detail', data ? data['entityLabel'] : ''));
        this.loading$.next(false);
      }));
    }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.useRouterOutlet = false;
    params.wrapsForm = true;
    params.showCancel = true;
    params.showNav = true;
    params.parentId = -1;
    params.showTitle = true;
    this.detailConfig$.next(params);
  }

  getData(id: number): void {
    let arr: IUploadFile[] = [];
    this.errorData$.next([]);
    this.errors$.next('');
    var localProjectFileDocumentType = this.documentTypes && this.documentTypes.length
      ? this.documentTypes.find(x => x.documentTypeName == DocumentTypeName.localProjectFile)
      : null;
    this.subscription.add(this.dos.getByMethodById('EmailFileImport', 'ByEmailImport', id)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          const emailFileImports: IEmailFileImport[] = response.data;  
          //move data into working/generic structure
          emailFileImports.forEach(item => {
            arr.push({
              id: item.emailFileImportId.toString(),
              file: null, //not needed stored in db already
              name: item.fileName,
              imageSize: item.imageSize,
              imageCaption: item.captionText,
              rotateClockwise: 0,
              previewUrl: item.thumbUrl,
              documentTypeId: localProjectFileDocumentType ? localProjectFileDocumentType.documentTypeId : null,
              entityId: null,
              fileType: item.fileMimeType
            });
          });
        } else {
          this.errorData$.next(response.errorData);
        }
        this.uploadFiles$.next(arr);
      },
        (error: any) => { this.errors$.next(error); }
    ));
  }

  createDocumentAndUploadFile(fileUpload: IUploadFile): void {

    if (fileUpload) {
      const uploadModel = {
        emailFileImportId: fileUpload.id,
        projectId: this.bulkUpload ? this.projectId.toString() : fileUpload.entityId.toString(),
        imageSize: fileUpload.imageSize,
        caption: fileUpload.imageCaption,
        rotateClockwise: fileUpload.rotateClockwise.toString(),
        includeInWorkOrderPacket: fileUpload.includeInWorkOrderPacket.toString()
      }
      const viewModel = {
        emailImportId: this.emailImportId.toString(),
        emailImportFile: uploadModel
      };

      this.subscription.add(this.dos.createByMethod('EmailImport', 'ImportFile', viewModel)
        .subscribe((response: IResponseBase) => {
          let uploads: IUploadFile[] = cloneDeep(this.uploadFiles$.value);
          const fileIdx: number = uploads.findIndex(x => +x.id === +fileUpload.id);
          const eventIdx: number = this.uploadStatuses.findIndex(x => +x.id === +fileUpload.id);

          if (response.success) {
            uploads = uploads.filter(x => x.id !== viewModel.emailImportFile.emailFileImportId);
          } else {
            if (fileIdx > -1) {
              uploads[fileIdx].errorData = response.errorData
            }
          }
          if (eventIdx > -1) {
            this.uploadStatuses[eventIdx].processed = true;
          }

          this.uploadFiles$.next(uploads);

          const stillWorking: boolean = this.bulkUpload ? this.isStillUploading() : false;
          if (!stillWorking) {
            //cleanup
            this.bulkUpload = false;
            this.loading$.next(false);
          }
        }));
    }
  }

  isStillUploading(): boolean {
    const working = cloneDeep(this.uploadStatuses);
    const anyUnprocessed = working.find(x => x.processed === false);
    return anyUnprocessed ? true : false;
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
