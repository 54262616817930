import { Injectable} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, map, filter , first } from 'rxjs/operators';

//store actions and reducers
import * as fromRoot from '../../../../app/store/reducers/index';
import * as fromSelectionLists from '../../../../shared/store/selectionLists/index';
import { ILookupData } from '../../../../shared/interfaces';
import { GetLookupList, getSelectionListDataByType } from '../../../../shared/store/selectionLists/index';

//Special Circumstance Lookup resolver
@Injectable()
export class SkuTypeResolver  {

  constructor(public selectionListStore: Store<fromSelectionLists.IStoreState>,
    public rootStore: Store<fromRoot.IState>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ILookupData[]> {
    this.requestData();
    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<ILookupData[]> {
    return this.selectionListStore.select(getSelectionListDataByType('skuType', -1))
      .pipe(
        filter((data: ILookupData[]) => data !== null),
        map((data: ILookupData[]) => { return data; }),
        first()
      );
  }

  requestData(): void {
    this.selectionListStore.select(getSelectionListDataByType('skuType', -1))
      .pipe(take(1))
      .subscribe((data: ILookupData[]) => {
        if (data === null) {
          this.rootStore.dispatch(new GetLookupList({ typeName: 'skuType', api: '/Api/LookupValue/ByEnum/SkuType' }));
        }
      });
  }
}
