import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index'; 
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'project-work-order-items',
  templateUrl: './project-work-order-items.component.html'
})
export class ProjectWorkOrderItemsComponent implements OnInit, OnDestroy {
  @Input() workOrderId: number;
  @Input() canIEdit: boolean;

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  subscription: Subscription = new Subscription();

  constructor(
    public projectService: ProjectService) {
  }

  ngOnInit() {
    this.setListDefinition();
  }

  setListDefinition(): void {
    this.listDefinition$.next(this.projectService.loadWorkOrderItemListDefinition(this.workOrderId, this.canIEdit));
}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['canIEdit'] && !changes['canIEdit'].isFirstChange()) {
      this.setListDefinition();
    }
    if (changes['workOrderId'] && !changes['workOrderId'].isFirstChange()) {
      this.setListDefinition();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
