import { IPortalTab } from "../../view-models";

export let SchedulerInstallerTabs: IPortalTab[] = [
  {
    portalLevel: 1,
    portalTabContainerId: -1,
    portalTabId: 0,
    managerPortalName: 'scheduler-tabs',
    managerPortalId: -1,
    tabLabel: 'Available Installers',
    tabIcon: '',
    isDefault: true,
    isDynamic: false,
    routeName: '',
    securityDependent: false,
    componentName: '',
    entityName: '',
    tabOrder: 1,
    entityId: 0
  },
  {
    portalLevel: 1,
    portalTabContainerId: -1,
    portalTabId: 1,
    managerPortalName: 'scheduler-tabs',
    managerPortalId: -1,
    tabLabel: 'Unavailable Installers',
    tabIcon: '',
    isDefault: false,
    isDynamic: false,
    routeName: '',
    securityDependent: false,
    componentName: '',
    entityName: '',
    tabOrder: 2,
    entityId: 0
  }
]
