import { cloneDeep } from 'lodash';
import { DynamicObjectActions, DynamicObjectActionTypes } from '../actions/dynamic-object.actions';
import { IErrorData } from '../../../../shared/interfaces/index';
import { IObjectData } from '../interfaces/index';

export interface IDynamicObject {
  storeName: string;
  objData: IObjectData[];
}

export class DynamicObject implements IDynamicObject {
  storeName: string;
  objData: IObjectData[];

  constructor(storeName: string) {
    this.storeName = storeName;
    this.objData = [];
    }
}

export interface IObjectsState {
  contactInformation: IDynamicObject;
  installerInformation: IDynamicObject;
  projectInformation: IDynamicObject;
  technicianInformation: IDynamicObject;
  warrantyInformation: IDynamicObject;
  workOrderInformation: IDynamicObject;
  providerLocationScheduleMoveUpInformation: IDynamicObject;
}

const initialState: IObjectsState = {
  contactInformation: new DynamicObject('contactInformation'),
  installerInformation: new DynamicObject('installerInformation'),
  projectInformation: new DynamicObject('projectInformation'),
  technicianInformation: new DynamicObject('technicianInformation'),
  warrantyInformation: new DynamicObject('warrantyInformation'),
  workOrderInformation: new DynamicObject('workOrderInformation'),
  providerLocationScheduleMoveUpInformation: new DynamicObject('providerLocationScheduleMoveUpInformation'),
};

export function dynamicObjectReducer(state = initialState, action: DynamicObjectActions) {
  switch (action.type) {

      case DynamicObjectActionTypes.SET_OBJECT:
        {
          const storeName = action.payload.storeName;
          let objStore = cloneDeep(state[storeName]);

          let index = objStore.objData.findIndex(x => x.objectId == action.payload.objectId);
          if (index === -1) {
              //add a new objData object to the array for this parent id
            objStore.objData.push({
              objectId: action.payload.objectId,
              data: {},
              errorData: [],
              extraData: null,
              message: '',
              staleData: false,
              working: false,
              event: null,
              accessError: false,
              requestTime: null
              });

            index = objStore.objData.findIndex(x => x.objectId == action.payload.objectId);
          }

          //if working with a merge, do not want to append to any records for this parent id - want to overlay
          objStore.objData[index].data = action.payload.data;
          objStore.objData[index].errorData = action.payload.errorData;
          objStore.objData[index].message = action.payload.error;
          objStore.objData[index].staleData = false;
          objStore.objData[index].working = false;
          objStore.objData[index].event = action.payload.event ? action.payload.event : null;
          objStore.objData[index].accessError = false;
          objStore.objData[index].requestTime = new Date();

          const updatedData = {
            storeName: action.payload.storeName,
            objData: [...objStore.objData],
        }

          return {
            ...state,
            [`${storeName}`]: { ...state[storeName], ...updatedData }
          }
        }
      

      case DynamicObjectActionTypes.UPDATE_COMPLETE_OBJ:
      {
        const keyData = action.payload.keyData;
        const storeName =keyData.storeName;

        const responseBase = action.payload.responseBase;
          let objStore = cloneDeep(state[storeName]);
        const objectId = +keyData.id; 
        const index = objStore.objData.findIndex(x => x.objectId == objectId); 
        if (index === -1) {
          console.log('***dynamic-object.reducer.UPDATE_COMPLETE  ERROR store for this object id not found', objectId, action.payload);
          return state;
        }

        //merge current values with returned values from update to create the updated record
          if (responseBase.success) {
            objStore.objData[index].data = responseBase.data;
            objStore.objData[index].errorData =  [];
            objStore.objData[index].event = action.payload.event;
        } else {
          objStore.objData[index].errorData = action.payload.responseBase.errorData;
        }
        objStore.objData[index].working = false;

        const updatedData = {
          storeName: storeName,
          objData: [...objStore.objData]
        }


        return {
          ...state, 
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }


      case DynamicObjectActionTypes.CREATE_COMPLETE_OBJ:
        {
          const storeName = action.payload.storeName;
          let objStore = cloneDeep(state[storeName]);
          const objectId = action.payload.objectId;

          const index = objStore.objData.findIndex(x => x.objectId == objectId);
          if (index !== -1) {
            objStore.objData[index].data = action.payload.responseBase.success ? action.payload.responseBase.data : {},
              objStore.objData[index].errorData = action.payload.responseBase.success ? '' : action.payload.responseBase.errorData,
              objStore.objData[index].extraData = null,
              objStore.objData[index].message = '',
              objStore.objData[index].staleData = false,
              objStore.objData[index].working = false,
              objStore.objData[index].event = action.payload.event,
              objStore.objData[index].accessError = false
        } else {
            //add a new objData object to the array for this parent id
            objStore.objData.push({
              objectId: action.payload.objectId,
              data: action.payload.responseBase.success ? action.payload.responseBase.data : {},
              errorData: action.payload.responseBase.success ? '' : action.payload.responseBase.errorData,
              extraData: null,
              message: '',
              staleData: false,
              working: false,
              event: action.payload.event,
              accessError: false
            });
          }


          const updatedData = {
            objData: [...objStore.objData]
          }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

      case DynamicObjectActionTypes.DELETE_COMPLETE_OBJ:
      {
          const storeName = action.payload.keyData.storeName;
          let objStore = cloneDeep(state[storeName]);
          const objectId = +action.payload.keyData.id; 
          const index = objStore.objData.findIndex(x => x.objectId == objectId);
          if (index === -1) {
            console.log('***dynamic-object.reducer.DELETE_COMPLETE  ERROR store for this parent id not found', objectId, action.payload);
            return state;
          }

        let newObjStore = objStore.objData[index];
          if (action.payload.responseBase.success) {
            //remove this record from  state
          newObjStore = objStore.objData[index].filter(x => x.objectId != objectId);
        }

        const updatedData = {
          objData: [...newObjStore.objData]
        }
          return {
            ...state,
            [`${storeName}`]: { ...state[storeName], ...updatedData }
          }
        }


      case DynamicObjectActionTypes.SET_ERROR_RETURNED_OBJ:
      {
        const storeName = action.payload.storeName;
        let objStore = cloneDeep(state[storeName]);
        let objectId = +action.payload.objectId; 

        let index = objStore.objData.findIndex(x => x.objectId == objectId);
        if (index === -1) {
              //add a new objData object to the array for this parent id
              objStore.objData.push({
                objectId: action.payload.objectId,
                data: null,
                errorData: [],
                extraData: null,
                message: '',
                staleData: false,
                working: false,
                event: null,
                accessError: false
              });
              index = objStore.objData.findIndex(x => x.objectId == action.payload.objectId);
          }

        objStore.objData[index].errorData = action.payload.errorData;
        objStore.objData[index].message = action.payload.error;
        objStore.objData[index].accessError = action.payload.accessError;

        const updatedData = {
          objData: [...objStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

      case DynamicObjectActionTypes.SET_STALE_DATA_CHECK_RESULT_OBJ:
    {
      const storeName = action.payload.storeName;
      const result = action.payload.responseBase;

          let objStore = cloneDeep(state[storeName]);
      const objectId = +action.payload.objectId; 
      const index =objStore.objData.findIndex(x => x.objectId == objectId);
      if (index === -1) {
        console.log('***dynamic-object.reducer.SET_STALE_DATA_ERROR  ERROR store for this parent id not found', action.payload);
        return state;
      }

      const error = 'Data is stale.  Please refresh your data.'

      let value: string[] = [];
      let errorData: IErrorData[] = [];
      value.push(error);
          errorData.push({ 'key': '__Model', 'value': value });

      objStore.objData[index].staleData = !result.success ? true : !result.data.isCurrent || !result.data.isAggregateRootCurrent ? true : false;
      objStore.objData[index].errorData = !result.success ? errorData : !result.data.isCurrent || !result.data.isAggregateRootCurrent ? errorData : [];

      const updatedData = {
        objData: [...objStore.objData]
      }

      return {
        ...state,
        [`${storeName}`]: { ...state[storeName], ...updatedData }
      }
    }

      case DynamicObjectActionTypes.INITIALIZE_OBJECT:
        {
          const storeName = action.payload.storeName;
          const objectId = +action.payload.objectId; 
          let objStore = cloneDeep(state[storeName]);

          const index = state[storeName] && state[storeName].objData ? state[storeName].objData.findIndex(x => x.objectId == objectId) : -1;
          if (index === -1) {
            return state;
          }

          //filter out data for this objectId
          let objData = objStore.objData.filter(x => x.objectId !== objectId);
          const updatedData = {
            storeName: action.payload.storeName,
            objData: [...objData]
          }

          return {
            ...state,
            [`${storeName}`]: { ...state[storeName], ...updatedData }

          }
        }

      case DynamicObjectActionTypes.SET_WORKING_OBJ:
      {
        const storeName = action.payload.storeName;
          let objStore = cloneDeep(state[storeName]);
        const objectId = +action.payload.objectId; 
        const index =objStore.objData.findIndex(x => x.objectId == objectId);
        if (index === -1) {
          console.log('***dynamic-object.reducer.SET_WORKING  ERROR store for this parent id not found', action.payload);
          return state;
        }

        objStore.objData[index].working = true;
        objStore.objData[index].errorData = [];
        objStore.objData[index].message = '';

        const updatedData = {
          objData: [...objStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }

      case DynamicObjectActionTypes.CLEAR_EVENT_OBJ:
      {
        const storeName = action.payload.storeName;
        let objStore = cloneDeep(state[storeName]);
        const objectId = +action.payload.objectId; 
        const index = objStore && objStore.objData ? objStore.objData.findIndex(x => x.objectId == objectId) : -1;
        if (index === -1) {
          return state;
        }

        objStore.objData[index].event = null;

        const updatedData = {
          objData: [...objStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
      }
   
      case DynamicObjectActionTypes.CLEAR_ERRORS_OBJ:
      {
        const storeName = action.payload.storeName;
        let objStore = cloneDeep(state[storeName]);
        const objectId = +action.payload.objectId; 
        const index = objStore && objStore.objData ? objStore.objData.findIndex(x => x.objectId == objectId) : -1;
        if (index === -1) {
          return state;
        }

        objStore.objData[index].errorData = [];
        objStore.objData[index].message = '';
        objStore.objData[index].event = null;
        objStore.objData[index].accessError = false;

        const updatedData = {
          objData: [...objStore.objData]
        }

        return {
          ...state,
          [`${storeName}`]: { ...state[storeName], ...updatedData }
        }
        }


      case DynamicObjectActionTypes.RESET_ALL_OBJECT_STORES:
        {
          return initialState
        }

      
  //end default return value 
  default:
  return state;
  }
}



//export const getObjectData = (state: IDynamicObject) => state.objData;
