<div class="portal">
  <div class="portal__detail--item">
    <fw-dynamic-detail-container *ngIf="projectId && (detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
      <div class="sss-invite-item">
        <fw-section-title [title]="customerName"></fw-section-title>
        <div class="sss-invite-header">
          <div class="sss-invite-header__item">
            <span class="sss-invite-header__item--label">Project:</span>
            <span class="sss-invite-header__item--value"  (click)="goToProject()">{{projectId}}</span>
          </div>
          <div class="sss-invite-header__item">
            <span class="sss-invite-header__item--label" >Work Order:</span>
            <span class="sss-invite-header__item--value" (click)="goToWorkOrder()">{{workOrderId}}</span>
          </div>
        </div>
        <job-site-manager [projectId]="projectId"
                          [contactId]="contactId"
                          [canIEdit]="false"
                          [hasStaleData]="false"
                          [projectStatus]="open"
                          [reload]="false"
                          [showTitle]="false"
                          [justInfo]="true">
        </job-site-manager>
      </div>

      <fw-section-title title="Invitations Sent"></fw-section-title>
      <workorder-invitation-summary [workOrderId]="workOrderId"></workorder-invitation-summary>

      <div class="sss-invite-item">
        <fw-section-title title="Schedule Dates Clicked"></fw-section-title>
        <sss-options-presented [workOrderId]="workOrderId"></sss-options-presented>
      </div>

    </fw-dynamic-detail-container>
  </div>
</div>
