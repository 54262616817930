import { Component, OnInit,  Inject, OnDestroy, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';

import { IAppConstants, appConstants } from '../../../shared/constants/index';
import { IMenuItem } from '../interfaces/i-menu-item';
import { ModalSizeType } from '../../fw-modal/interfaces/i-modal';
import { HomHubService } from '../../../auth/services/hom-hub.service';
import { UserRecentsService } from '../../fw-shared/components/user-recents/services/user-recents.service';
import { MenuService } from '../services/menu.service';
import { FrameworkConfigService } from '../../fw-config/services/framework-config.service';
import { ModalService } from '../../fw-modal/services/fw-modal.service';
import { UserPriviledgesService } from '../../../auth/services';

import * as fromRoot from '../../../app/store/reducers/index';
import * as fromAuth from '../../../auth/store/index';

@Component({
  selector: 'fw-user-menu',
  templateUrl: './fw-user-menu.component.html'
})

export class UserMenuComponent implements OnInit, OnDestroy {
  @Input() hasValidSecurityAccess: boolean;

  public isDelegate$: Observable<boolean>
  public notDelegate$: Observable<boolean>
  public openNotificationCount$: Observable<number>;
  public pendingChangesCount$: Observable<number>;
  public hasUnrespondedToContacts$: Observable<boolean>;
  public currentUserName$: Observable<string>;
  public anonymous$: Observable<boolean>;
  public authenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  intervalWorking: boolean = false;
  getCounts$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subscription: Subscription = new Subscription();

  constructor( public frameworkConfigService: FrameworkConfigService,
    public modalService: ModalService,
    public rootStore: Store<fromRoot.IState>,
    public homHubService: HomHubService,
    public userRecentsService: UserRecentsService,
    public menuService: MenuService,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) {
  }

  public onLogout() {
    this.menuService.logout();
  }

  public showUserRecents() {
    this.userRecentsService.showPopover = true;
  }

  public handleMenuClick(selector, title) {
    this.showModal(selector, title);
  }

  public openConvoTab() {
    const item: IMenuItem = this.menuService.items.find(x => x.portalId === 15);
    if (item) {
      this.menuService.switchPortal(item);
    }
  }

  public hideUserRecents() {
    this.userRecentsService.showPopover = false;
  }

  ngOnInit() {
    this.isDelegate$ = this.rootStore.pipe(select(fromAuth.isDelegate));
    this.notDelegate$ = this.rootStore.pipe(select(fromAuth.isNotDelegate));
    this.openNotificationCount$ = this.rootStore.pipe(select(fromAuth.getOpenNotificationsCount));
    this.pendingChangesCount$ = this.rootStore.pipe(select(fromAuth.getPendingChangesCount));
    this.currentUserName$ = this.rootStore.pipe(select(fromAuth.getCurrentUserName));
    this.hasUnrespondedToContacts$ = this.rootStore.pipe(select(fromAuth.hasUnrespondedToContacts));

    this.subscription.add(this.rootStore.pipe(select(fromRoot.getAuthState))
      .subscribe((authState: fromAuth.IState) => {
        var validUserAccess = authState && authState.validUserSecurity;
        var authenticated = authState && authState.authenticated;
        this.authenticated$.next(authenticated && validUserAccess);
        this.frameworkConfigService.showUserControls =
          this.frameworkConfigService.showSearchBar =
        this.frameworkConfigService.showStatusBar = authenticated;
    }));  

    this.authenticated$.subscribe(val => {
      var connectionEstablished = this.homHubService.connectionEstablished$.getValue();
      var connectionRequested = this.homHubService.connectionRequested$.getValue();
      if (val && this.ups.currentUserId$.value > 0 && !connectionEstablished && !connectionRequested) {
        this.homHubService.initHubConnection();
      }
      if (!val && connectionEstablished) {
        this.homHubService.disconnectHub();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showModal(modalName: string, title: string) {
    this.modalService.open({ title: title, path: modalName, onExit: null, castExit: false, sizeType: ModalSizeType.large, hasTabs: true });
  }


}
