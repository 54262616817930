import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EntityResolver, DocumentTypeResolver } from '../resolvers';
import { PurchaseOrderMeasuresComponent } from '../project/containers';
import { LoadingPageComponent } from '../../../shared/components';
import { FileUploadManagerComponent } from '../../file-upload/containers';
import { FwMapComponent } from '../../../fw/fw-shared/components';

const schedulerRoutes: Routes = [
  {
    path: 'loading2/:container',
    outlet: 'modal2',
    component: LoadingPageComponent
  },
  {
    path: 'schedule-po-measures/:id/:poId/:parentId/:actionId/:type',
    outlet: 'modal2',
    resolve: {
      entitiesLoaded: EntityResolver 
    },
    component: PurchaseOrderMeasuresComponent
  },
  {
    path: 'file-upload/:id/:parentId/:actionId/:type/:actionIds',
    outlet: 'modal2',
    resolve: {
      docTypesLoaded: DocumentTypeResolver
    },
    component: FileUploadManagerComponent
  },
  {
    path: 'file-upload/:id/:parentId/:actionId/:type',
    outlet: 'modal2',
    resolve: {
      docTypesLoaded: DocumentTypeResolver
    },
    component: FileUploadManagerComponent
  },
 {
    path: 'hom-map',
    outlet: 'modal2',
   component: FwMapComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(schedulerRoutes)
  ],
  exports: [RouterModule]
})
export class SchedulerRoutingModule { }
