
export interface IJobScheduleJobSubscription {
  jobScheduleJobSubscriptionId: number;
  jobScheduleJob_jobScheduleJobId: number;
  providerUser_providerUserId: number;
  alertOnlyWhenOutput: boolean;

  //extra data properties
  jobNameNew: string;
  jobScheduleJobName: string;
  scheduleName: string;
  jobScheduleDescription: string;
}


export class JobScheduleJobSubscription {

  constructor(
    public jobScheduleJobSubscriptionId: number = 0,
    public jobScheduleJob_jobScheduleJobId: number = 0,
    public providerUser_providerUserId: number = 0,
    public workOrder_workOrderId: number = 0,
    public alertOnlyWhenOutput = false,
    public jobNameNew: string = '',
    public jobScheduleJobName: string = '',
    public scheduleName: string = '',
    public jobScheduleDescription: string = '',
  ) { }
}