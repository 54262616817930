import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { IDynamicListSortSetting, IDynamicListSortSettingSelection } from '../../interfaces';
import { IInputButton } from '../../../fw-shared/interfaces';

@Component({
  selector: 'dynamic-list-sort-setting',
  templateUrl: './dynamic-list-sort-setting.component.html'
})
export class DynamicListSortSettingComponent implements OnInit, OnChanges {
  @Input() items: IDynamicListSortSetting[];
  @Input() index: number;
  @Input() selectedItem: IDynamicListSortSetting;

  @Output() public changeEvent = new EventEmitter<IDynamicListSortSettingSelection>();
  @Output() public deleteEvent = new EventEmitter<number>();

  public ascConfig: IInputButton;
  activeSelection: IDynamicListSortSetting = null;
  activeAsc: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.ascConfig = {
      label: {
        label: '',
        alignRight: true,
        title: 'Ascending'
      }
    };
    this.newRequest();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['items'] && !changes['items'].firstChange) ||
      (changes['selectedItem'] && !changes['selectedItem'].firstChange)) {
      this.newRequest();
      }
  }

  public changeSelection(colKey: string): void {
    this.activeSelection = !colKey ? null : this.items.find(x => x.colKey === colKey);
    this.emitChange();
  }

  public changeAsc(val: boolean): void {
    this.activeAsc = val;
    this.emitChange();
  }

  public delete(): void {
    this.deleteEvent.emit(this.index);
  }

  newRequest(): void {
    this.activeSelection = this.selectedItem;
    this.activeAsc = this.selectedItem && this.selectedItem.orderTerms && this.selectedItem.orderTerms.length > 0
      ? this.selectedItem.orderTerms[0].orderAsc : true;
  }

  emitChange(): void {
    let selectedItem: IDynamicListSortSetting = this.activeSelection;
    if (selectedItem) {
      selectedItem.orderTerms.forEach(term => {
        term.orderAsc = this.activeAsc;
        term.sortOrder = this.index;
      })
    }
    this.changeEvent.emit({ index: this.index, item: selectedItem})
  }

}
