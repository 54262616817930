import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomPipesModule } from 'hom-lib/hom-pipes';
import { FwSharedModule } from '../fw-shared/fw-shared.module';

//services
import { BodyService } from '../fw-body/services/fw-body.service';
import { DynamicFieldService } from '../dynamic-forms/services/dynamic-field.service';

//components
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormCustomComponent } from './dynamic-form-custom/dynamic-form-custom.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    HomPipesModule,
    FwSharedModule
  ],
  declarations: [
    DynamicFieldComponent,
    DynamicFormComponent,
    DynamicFormCustomComponent
  ],
  exports: [
    DynamicFieldComponent,
    DynamicFormComponent,
    DynamicFormCustomComponent
  ],

})
export class DynamicFormModule {
  static forRoot(): ModuleWithProviders<DynamicFormModule> {
    return {
      ngModule: DynamicFormModule,
      providers: [DynamicFieldService, BodyService ]
    };
  }
}
