import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import {
  IMultiSelectOption, MultiSelectTexts,
  MultiSelectSettings, MultiSelectDropdown
} from '../../../../../fw/fw-shared/components/fw-multi-select-dropdown';
import { IErrorData, IResponseBase } from '../../../../../shared/interfaces';
import { UpdateObjectCustomModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces';
import { UpdateObjectCustomList, SetListFilter } from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { IProviderLocation, SlotScheduleCalendar } from '../../../view-models';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { getListByType } from '../../../../../fw/dynamic-list/store/index';
import { IListFilter, ListFilter, ISearchTerm } from '../../../../../fw/dynamic-list/interfaces';
import { SearchType } from '../../../../../fw/dynamic-list/enums/search-type.enums';
import { ScheduleStore } from '../../../scheduler/enums/schedule.enums';

import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { DomainObjectService } from '../../../../../shared/services';

@Component({
  selector: 'schedule-locked-days-create',
  templateUrl: './schedule-locked-days-create.component.html'
})

export class ScheduleLockedDaysCreateComponent implements OnInit, OnDestroy {

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public selStore: Store<fromSelectionLists.IStoreState>,
    public dos: DomainObjectService,
    public mds: MetaDataService,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants
  ) { }

  @ViewChild('serviceMultiSelect') public serviceMultiSelect: MultiSelectDropdown;

  public multiSelectOptions: IMultiSelectOption[];
  public multiSelectLabels: MultiSelectTexts;
  public multiSelectSettings: MultiSelectSettings;
  public lockedDayForm: FormGroup;
  public providerLocations: IProviderLocation[];
  public selectedServices: number[] = [];
  public selectedServices$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public generalContractors: any[] = [];
  public plSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public operation: string;
  public displayFields = ['day', 'providerLocationName', 'generalContractor'];
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  public providerLocationId: number;
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.operation = this.myConstants.operationTypeCreate; 
    this.initMetaData();
    this.initForm();
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.setDetailConfig(paramMap);
    });

    this.subscription.add(this.store.select(getListByType(ScheduleStore.slotScheduleCalendarLockedDays)).subscribe((data) => {
      if (data.objData[0].event) {
        if (!data.objData[0].errorData.length) {
          let listFilter: IListFilter = cloneDeep(data.objData[0].listFilter);
          const listDefinition = cloneDeep(data.objData[0].listDefinition);
          let dayTerm: ISearchTerm = listFilter.searchTerm.find(x => x.term === 'day');
          dayTerm
            ? dayTerm.value = this.lockedDayForm.controls['day'].value
            : listFilter.searchTerm.push({ term: 'day', value: this.lockedDayForm.controls['day'].value, searchType: SearchType.Equals, columnName: 'day', fieldType: this.myConstants.dataTypeDate });
          this.store.dispatch(new fromStore.GetList({ listDefinition: listDefinition, listFilter: listFilter, parentId: -1 }));
          this.emitterService.emitListEvent({
            requestor: 'schedule-locked-days-create', event: this.myConstants.emitterEventClose, action: '', data: null
          });
        } else {
          this.errorData$.next(data.objData[0].errorData);
        }
      }
    }));

    this.subscription.add(this.mds.valueChanged$
      .subscribe((obj: IValueChanged) => {
        if (obj && obj.key === 'providerLocationName') {
          this.onLocationChange(obj.value);
        }
      }));
  }

  public initMetaData(): void {
    this.mds.setFieldDefinitions(ScheduleStore.slotScheduleCalendarLockedDays);
  }

  public setMultiSelectProperties(): void {
    let multiSelectLabels = new MultiSelectTexts();
    this.multiSelectLabels = new MultiSelectTexts();
    this.multiSelectSettings = new MultiSelectSettings();
    multiSelectLabels.defaultTitle = 'Select...';
    this.multiSelectSettings.selectionLimit = 0;
    this.multiSelectSettings.closeOnSelect = false;
    this.multiSelectSettings.autoUnselect = true;
    this.multiSelectOptions = [];
    this.multiSelectLabels = multiSelectLabels;
  }

  public setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.wrapsForm = true;
    params.showCancel = false;
    this.detailConfig$.next(params);
  }

  public initForm(): void {
    this.mds.loadSelectionLists(this.displayFields, this.operation, -1);
    this.providerLocations = this.mds.getSelectItems('providerLocationName');
    this.generalContractors = this.mds.getSelectItems('generalContractor');
    this.lockedDayForm = this.mds.loadDynamicFormGroup(this.displayFields, new SlotScheduleCalendar(), this.myConstants.operationTypeCreate);
    this.lockedDayForm.addControl('servicesMultiSelect', new FormControl());
    this.setMultiSelectProperties();
  }

  public addService(serviceId: number): void {
    const service = this.multiSelectOptions.find(x => x.id === serviceId);
    if (service) {
      this.selectedServices.push(serviceId);
    }
  }

  public removeService(serviceId: number): void {
    const index = this.selectedServices.findIndex(x => x === serviceId);
    if (index > -1) {
      this.selectedServices.splice(index, 1);
    }
    
  }

  public onCreate(): void {
    const data = {
      serviceIds: this.serviceMultiSelect.model,
      providerLocationId: this.providerLocationId,
      generalContractorId: this.lockedDayForm.controls['generalContractor'].value['generalContractorId'],
      slotDay: this.lockedDayForm.controls['day'].value
    },
      keyData: IKey = { storeName: ScheduleStore.slotScheduleCalendarLockedDays, parentId: -1, key: 'slotScheduleCalendarId', id: -1, skipDataUpdate: true },
      emitter: IHomEventEmitter = { requestor: 'schedule-locked-days-create', event: this.myConstants.emitterEventCreateComplete, action: '', data: null },
      updateData = new UpdateObjectCustomModel(keyData, 'SlotScheduleCalendar', 'Lock', data, null, emitter);
    this.store.dispatch(new UpdateObjectCustomList({ updateData }));

  }

  public formValid(): boolean {
    return this.lockedDayForm.valid && this.plSelected$.value && this.selectedServices.length > 0;
  }

  public onCancel(): void {
    this.emitterService.emitListEvent({ requestor: 'schedule-locked-days-create', event: this.myConstants.emitterEventClose, action: '', data: null });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onLocationChange(location: IProviderLocation): void {
    this.selectedServices = [];
    if (this.serviceMultiSelect) {
      this.serviceMultiSelect.uncheckAll();
    }
    this.plSelected$.next(false);
    if (location && location.providerLocationId > 0) {
      this.providerLocationId = location.providerLocationId;
      this.subscription.add(this.selStore.pipe(
        select(fromSelectionLists.getSelectionListDataByType('providerLocationService', location.providerLocationId)))
        .subscribe(data => {
          if (data) {
            const options: IMultiSelectOption[] = [];
            data.forEach(service => {
              options.push({ id: service.service_serviceId, name: service['serviceName'] });
            });
            this.multiSelectOptions = options;
            this.plSelected$.next(true);
          } else {
            let listFilter = new ListFilter();
            listFilter.getAll = true;
            this.store.dispatch(new fromSelectionLists.GetEntityListById('ProviderLocationService', 'ByProviderLocation', location.providerLocationId, listFilter));
          }
        })
      );
    }

  }
}
