import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IPoImportHeader, IPoImportLine, IBranchProgram } from '../../../view-models/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { PoImportEvent } from '../../enums/po-import.enums';
import { IKey, UpdateObjectByIdModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IListObjectData } from '../../../../../fw/dynamic-list/store/index';
import { IFormDefinition, FormDefinition } from '../../../../../fw/dynamic-forms';
import { IListDefinition, IGenericStateData, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { PoImportService } from '../../services/po-import.service';


@Component({
 selector: 'po-import-header-container',
 templateUrl: './po-import-header-container.component.html'
})
export class PoImportHeaderContainerComponent implements OnInit, OnDestroy  {
  public poImportHeader$: BehaviorSubject<IPoImportHeader> = new BehaviorSubject(null);
  public storeName: string = '';
  public operation: string;
  public formDefinition$: BehaviorSubject<IFormDefinition> = new BehaviorSubject(null);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public branchPrograms$: BehaviorSubject<IBranchProgram[]> = new BehaviorSubject(null);
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  poImportHeaderId: number = -1;
  key: string = '';
  requestor: string;
  controller: string;
  subscription: Subscription = new Subscription();
  bpSub: Subscription;

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public poImportService: PoImportService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case PoImportEvent.showPricingWizard:
        this.showPricingWizard(event.data);
        break;
      case this.myConstants.emitterEventUpdate:
        this.updateRecord(event);
        break;
      case this.myConstants.emitterEventClose:
        this.emitterService.emitListEvent(event);
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.poImportHeaderId = +paramMap.get('id');
      this.key = paramMap.get('key');
      this.storeName = paramMap.get('storeName');
      this.operation = paramMap.get('operation');
      this.setDetailConfig(paramMap);
      this.branchPrograms$.next(null);
      this.loading$.next(true);
      this.setSubscribes();
      switch (this.operation) {
        case this.myConstants.operationTypeDetails:
          this.initForLineList();
          break;
        case this.myConstants.operationTypeEdit:
          this.initForEdit();
          break;
        default:
          break;
      }
    }));
  }

  setSubscribes(): void {
    this.subscription.add(this.poImportHeader$.subscribe((val: IPoImportHeader) => {
      if (val) {
        this.getBranchPrograms(val.branchName, val.generalContractorId);
        this.loading$.next(false);
      }
    }));
  }

  initForLineList(): void {
    this.listDefinition$.next(null);
    this.setListDefinition();
    }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.useRouterOutlet = false;
    params.wrapsForm = true;
    params.showCancel = this.operation === this.myConstants.operationTypeDetails;
    params.showNav = this.operation === this.myConstants.operationTypeDetails;
    params.parentId = -1;
    params.showTitle = true;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  setListDefinition(): void {
      let listDefinition: IListDefinition = this.poImportService.loadPoImportLineListDefinition(this.poImportHeaderId);
      this.listDefinition$.next(listDefinition);
      this.loading$.next(false);
    }

  initForEdit(): void {
    this.subscription.add(this.store.pipe(select(fromStore.getEntityListByParentId(this.storeName, -1)))
      .subscribe((state: IListObjectData) => {
        const objData = cloneDeep(state);
        this.requestor = objData.listDefinition.detailRoutePath;
        this.controller = objData.listDefinition.controllerName;
        if (!this.poImportHeader$.value) {
          this.poImportHeader$.next( objData.data.find(x => x[this.key] == this.poImportHeaderId));
        }
        this.formDefinition$.next(new FormDefinition(objData.listDefinition.objectLabel + this.operation, false, false, this.requestor, objData ? objData.data['entityLabel'] : ''));
      }));
  }

  getBranchPrograms(branchName: string, gcId: number): void {
    this.branchPrograms$.next(null);
    if (branchName) {
      const bps = this.poImportService.getBranchProgramsByBranchName(branchName);
      if (bps) {
        this.branchPrograms$.next(bps);
      } else {
        if (this.bpSub) {
          this.bpSub.unsubscribe();
        }
        this.bpSub = this.poImportService.requestBranchProgramsByBranchName(branchName, gcId)
          .subscribe((result: IBranchProgram[]) => {
            this.branchPrograms$.next(result);
          },
            (error: any) => { console.log(error) }
          );
      }
    }
  }

  showPricingWizard(data: IPoImportLine): void {
    const def: IListDefinition = this.listDefinition$.getValue();
    let stateData: IGenericStateData = {
      storeName: def.storeName,
      key: 'sku_skuId',// to pull by
      parentId: def.parentId
    }

    this.modalService.open({
      title: 'Sku Price Wizard',
      path: 'sku-pricing-wizard/' + data.sku_skuId.toString() + '/' + data.generalContractorId.toString(),
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      hasTabs: false,
      state: stateData
    });
  }

  updateRecord(e: IHomEventEmitter) {
    const keyData: IKey = { storeName: this.storeName, parentId: -1, key: this.key, id: this.poImportHeaderId, }
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventUpdate, action: e.action, data: null };
    const updateData = new UpdateObjectByIdModel(keyData, this.controller, 'Update', this.key, this.poImportHeaderId, e.data, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectByIdList({ updateData }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.bpSub) {
      this.bpSub.unsubscribe();
    }
 }
}
