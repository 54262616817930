import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { take  } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../../shared/constants/index';
import { IErrorData } from '../../../../../../shared/interfaces/index';
import { IFormDefinition, FormDefinition } from '../../../../../../fw/dynamic-forms/index';
import { UserDelegate, IUserDelegate } from '../../../../view-models/index';
import { CreateObjectModel, UpdateObjectByIdModel, IKey } from '../../../../../../fw/dynamic-list/store/interfaces/index';

//store actions and reducers
import * as fromDynamicList from '../../../../../..//fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromRoot from '../../../../../store/reducers/index';
import * as fromAuth from '../../../../../../auth/store/auth.reducer';
import * as fromStore from '../../../../../../fw/dynamic-list/store/index';

// Used for user alert detail and user job detail.
@Component({
  selector: 'user-delegate',
  templateUrl: './user-delegate.component.html'
})

export class UserDelegateComponent implements OnInit, OnDestroy {
  public storeName: string = '';
  public operation: string;
  public activeRecord$: BehaviorSubject<IUserDelegate> = new BehaviorSubject(null);
  public formDefinition$: BehaviorSubject<IFormDefinition> = new BehaviorSubject(null);
  public errorMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);

  authState: fromAuth.IState;
  listStore: fromDynamicList.IListObjectData;
  myPathName: string = '';
  key: string = '';
  activeIndex: number = -1;
  activeRecordId: number = 0;
  activeEvent: IHomEventEmitter;
  subscription: Subscription = new Subscription();

  constructor( public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData> ) {

    this.subscription.add(emitterService.detailEventEmitted$.subscribe(
      (e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case this.myConstants.emitterEventCreate:
            this.createRecord(e);
            break;
          case this.myConstants.emitterEventClose:
            this.emitterService.emitListEvent(e);
            break;
          default:
            break;
        }
      }));
  }

  ngOnInit() {
    this.subscription.add(this.rootStore.select('auth')
      .pipe(take(1))
      .subscribe((authState: fromAuth.IState) => {
        this.authState = authState;
      }));

    //if we don't expect params to change, do not need to subscribe - just get
    this.subscription.add(this.activeRoute.params.subscribe(params => {
      this.activeIndex = params['rowIndex'];
      this.activeRecordId = params['id'];
      this.key = params['key'];
      this.operation = params['operation'];
      this.storeName = params['storeName'];


      this.subscription.add(this.store.pipe(select(fromStore.getEntityListByParentId(this.storeName, -1)))
        .subscribe((state: fromDynamicList.IListObjectData) => {
          const objData = state;

          this.myPathName = objData.listDefinition.detailRoutePath;
          this.formDefinition$.next(new FormDefinition(objData.listDefinition.objectLabel + (this.operation || this.myConstants.operationTypeDetails), false, false, this.myPathName));

          this.listStore = objData;
          let current: IUserDelegate = this.activeRecord$.getValue();
          this.activeRecord$.next( this.operation === this.myConstants.operationTypeCreate ? current ? current : new UserDelegate()
            : objData.data[this.activeIndex]);

          current = this.activeRecord$.getValue();
          if (this.operation !== this.myConstants.operationTypeCreate &&
            (!current || current[this.key] != this.activeRecordId)) { //keep single = here
            let emitter: IHomEventEmitter = {
              requestor: this.myPathName,
              event: this.myConstants.emitterEventError,
              action: '',
              data: 'DETAIL: UserDelegateComponent detail with no detail record'
            };
            this.emitterService.emitListEvent(emitter);
            return;
          }

          if (objData.errorData || objData.message) {
            this.errorData$.next( objData.errorData);
            this.errorMessage$.next( objData.message);

          } else if (this.activeEvent) {
            const emitEvent = this.activeEvent;
            this.activeEvent = null;

            if (this.activeRecordId === 0) {
              this.emitterService.emitListEvent(emitEvent);

            } else if (emitEvent.action === this.myConstants.emitterActionNext) {
              this.operation = this.myConstants.operationTypeRefreshOnSave;
            }

            if (emitEvent.action === this.myConstants.emitterActionHold) {
             // this.changeDetectorRef.detectChanges();
            } else {
              //let the parent know as well
              emitEvent.data = this.activeRecord$.getValue();
              this.emitterService.emitListEvent(emitEvent);
            }
          }
        }));
    }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /*
      Insert the new record
  */
  createRecord(e: IHomEventEmitter) { //TODO - need entity table change so can set these in db
    //will need the fields that can be updated or can I determine?

    this.activeEvent = e;
    const userDelegate: IUserDelegate = this.setCommonProperties(cloneDeep(e.data));

    if (userDelegate.hasOwnProperty('providerUser_providerUserId')) {
      userDelegate.providerUser_providerUserId = this.authState.providerUser.currentUserId;
    }
    if (userDelegate.hasOwnProperty('createDate')) {
      userDelegate['createDate'] = Date.now();
    }

    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, -1, this.listStore.listDefinition.controllerName, 'Create', userDelegate, null, emitter);
    this.store.dispatch(new fromStore.CreateObjectList({ createData }));
  }

  updateRecord(e: IHomEventEmitter) {
    this.activeEvent = e;
    const userDelegate: IUserDelegate = this.setCommonProperties(cloneDeep(e.data));

    const keyData: IKey = { storeName: this.storeName, parentId: -1, key: this.key, id: this.activeRecordId, }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectByIdModel(keyData, this.listStore.listDefinition.controllerName, 'Update', this.key, this.activeRecordId, userDelegate, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectByIdList({ updateData }));
  }

  setCommonProperties(data: UserDelegate): IUserDelegate {
    const userDelegate: UserDelegate = { ...this.activeRecord$.getValue() };

    this.errorMessage$.next('');
    this.errorData$.next([]);

    if (userDelegate.hasOwnProperty('updateDate')) {
      userDelegate['updateDate'] = Date.now();
    }

    return userDelegate;
  }
}
