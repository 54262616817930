<div class="portal">
  <div class="portal__detail--item">
    <fw-component-title title='Tools'></fw-component-title>
    <div class="mgr-tools">
      <div *ngFor="let tool of mgrTools" class="mgr-tool-card" (click)="onClick(tool.event)">
        <div class="mgr-tool-card__icon">
          <i class="{{ tool.icon }}" [ngClass]="{'mgr-tool-card__icon--stacked': tool.stackedIcon }"></i>
          <i *ngIf="tool.stackedIcon" class="{{ tool.stackedIcon }} mgr-tool-card__icon--stacked"></i>
        </div>
        <div class="mgr-tool-card__title">{{ tool.title }}</div>
        <div class="mgr-tool-card__desc">{{ tool.description }}</div>
      </div>
    </div>
  </div>
</div>
