/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { IProject } from '../../../view-models';
import { ProjectService } from '../../services/project.service';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as fromDynamicObject from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';

@Component({
  selector: 'project-notes',
  templateUrl: './project-notes.component.html'
})
export class ProjectNotesComponent implements OnInit, OnDestroy {
  @Input() baseProjectId: number;
  @Input() canIEdit: boolean = true;

  public grandParentKey: string = '';
  public grandParentId: number = 0;
  public action: string = '';
  public canIEditOut: boolean = false;
  public hasExternalInterface: boolean = false;
  public contactId: number = 0;
  public ready$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  projectId: number;
  listDefinition: IListDefinition;
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromFeature.IAllDynamicData>,
    public projectService: ProjectService) {}

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
        this.ready$.next(false);
        this.projectId = this.baseProjectId ? this.baseProjectId : +paramMap.get('id');
        this.action = paramMap.has('action') ? paramMap.get('action') : '';
        this.grandParentId = this.projectId;
        this.grandParentKey = 'project_projectid';
        this.getProject();
        }
      );

    this.listDefinition = this.projectService.loadProjectNoteListDefinition(this.projectId, this.canIEdit);
  }

  getProject(): void {
    this.subscription.add(this.store.pipe(
      select(fromDynamicObject.getObjectDataById('projectInformation', this.projectId)), take(1))
      .subscribe((objData: IProject) => {
        if (objData) {
          this.contactId = objData.customer_contactId;
          this.hasExternalInterface = objData.hasExternalInterface
          this.canIEditOut = true;//notes, so true 
          this.ready$.next(true);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
