
import { IKey } from './i-key';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

/* structure for data object for UPDATE_DETAIL_BY_ID reducer/action */
export interface IUpdateObjectById {
  keyData: IKey,
  objectType: string,
  methodName: string,
  key: string, 
  id: number,
  objectData: any,
  arrayName: string; //typically null
  event: IHomEventEmitter;
}

export class UpdateObjectByIdModel implements IUpdateObjectById {
  constructor(
    public keyData: IKey,
    public objectType: string,
    public methodName: string,
    public key: string,
    public id: number,
    public objectData: any,
    public arrayName: string = 'models',
    public event: IHomEventEmitter = null
  ) { }
}

//    createCustom(objectType: string, methodName: string, domainObject: IDomainObject, arrayName: string = null):
