import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import {  map } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { IKey, UpdateObjectCustomModel, DeleteObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { IWorkOrder, IWorkOrderItem } from '../../../view-models';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IErrorData } from '../../../../../shared/interfaces/index';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IListObjectData, IDynamicListState } from '../../../../../fw/dynamic-list/store';
import { ProjectEvent, ProjectListStore } from '../../enums/project.enums';
import * as fromRoot from '../../../../store/reducers/index';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'project-work-orders',
  templateUrl: './project-work-orders.component.html'
})
export class ProjectWorkOrdersComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() canIEdit: boolean;
  @Input() workOrders: IWorkOrder[];
  @Input() workOrderItems: IWorkOrderItem[];

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public selectedWos: IWorkOrder[] = [];
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  subscription = new Subscription();

  constructor(
    public projectService: ProjectService,
    public modalService: ModalService,
    public store: Store<fromFeature.IAllDynamicData>,
    public rootStore: Store<fromRoot.IState>,
   @Inject(appConstants) public myConstants: IAppConstants) { }

  public closeWOs(): void {
    const def = this.listDefinition$.getValue();
    const keyData: IKey = { storeName: def.storeName, parentId: this.projectId, key: def.rowKeyId, id: this.selectedWos[0].workOrderId, skipDataUpdate: true };
    const emitter: IHomEventEmitter = { requestor: 'project-work-orders', event: this.myConstants.emitterEventListReloadPortal, action: '', data: null };
    const updateObj = { workOrderIds: this.selectedWos.map(x => x.workOrderId) }
    const updateData = new UpdateObjectCustomModel(keyData, def.controllerName, 'ReceiveLienWaivers', updateObj, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
    this.selectedWos = [];
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case ProjectEvent.logCustomerAction:
        this.logCustomerAction(event.data);
        break;
      case ProjectEvent.addNote:
        this.addNote(event.data);
        break;
      case ProjectEvent.showWOPacket:
        this.showWoPacket(event.data);
        break;
      case ProjectEvent.openWarrantyScript:
        this.openWarrantyScript(event.data);
        break;
      case ProjectEvent.peekWOItemSummary:
        this.showMiniItemSummary(event.data);
        break;
      case ProjectEvent.deleteWorkOrder:
        const data: IWorkOrder = event.data;
        if (confirm('Are you sure you want to delete Work Order ' + data.workOrderId.toString())) {
          this.deleteWorkOrder(event.data);
        }
        break;
      default:
        const temp: string = 'event '.concat(event.event, ' is under construction');
        alert(temp);
        break;
    }
  }

  public onSelect(event: IHomEventEmitter) {
    switch (event.event) {
      case ProjectEvent.closeWorkOrder:
        this.updateSelected(event);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.newRequest();
  }

  newRequest(): void {
    let listDefinition: IListDefinition = this.projectService.loadProjectWorkOrderListDefinition(this.projectId, this.canIEdit, false, false);
    listDefinition.noGet = true;
    this.listDefinition$.next(listDefinition);
    //wos and pos- will be loaded as part of high - level summary load - and that is always loaded
    //listen for working, loading will be handled by dynamic-list, but since this is a noGet, need to check working to set the mask correctly
    this.subscription.add(this.store.pipe(select(getListByType(listDefinition.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == listDefinition.parentId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          this.working$.next(state.working);
        }
      }));
  }

  updateSelected(event: IHomEventEmitter): void {
    const wo: IWorkOrder = event.data;
    const exists: number = this.selectedWos.findIndex(x => x.workOrderId === wo.workOrderId);
    if (event.action) {
      if (exists === -1) {
        this.selectedWos.push(wo);
      }
    } else {
      if (exists > -1) {
        this.selectedWos.splice(exists, 1);
      }
    }
  }

  deleteWorkOrder(data: IWorkOrder): void {
    const listDefinition = this.projectService.loadProjectWorkOrderListDefinition(this.projectId, this.canIEdit, false, false);

    const keyData: IKey = { storeName: listDefinition.storeName, parentId: listDefinition.parentId, key: listDefinition.rowKeyId, id: data.workOrderId }
    const event: IHomEventEmitter = { requestor: 'project-purchase-orders', event: this.myConstants.emitterEventDelete, action: '', data: null };

    const api: string = '/Api/WorkOrder/Delete/'.concat(data.workOrderId.toString());
    const deleteData = new DeleteObjectModel(keyData, api, event);

    this.store.dispatch(new DynamicListActions.DeleteObjectByUrlList({ deleteData }));
  }

  logCustomerAction(data: IWorkOrder): void {
    const woId: number = data && data.hasOwnProperty('workOrderId') ? data['workOrderId'] : 0;
    if (woId === 0) {
      console.log('DEV ERROR:  missing wo id on event', event);
      return;
    }

    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.workOrderCallLogResponses,
      key: 'project_call_log_response_id',
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.projectId
    }
    this.modalService.open({
      title: 'Work Order Customer Action Call Log',
      path: 'work-order-call-log',
      id: data.workOrderId,
      optionalParams: params,
      onExit: null,
      castExit: false,
      hasTabs: false,
      state: { projectId: this.projectId }

    });
  }

  addNote(data: IWorkOrder): void {
    const woId: number = data && data.hasOwnProperty('workOrderId') ? data['workOrderId'] : 0;
    if (woId === 0) {
      console.log('DEV ERROR:  missing wo id on event', event);
      return;
    }

    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: 'workOrderNotes',
      key: 'workOrder_workOrderId',
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.projectId
    }
    this.modalService.open({ title: 'Work Order Notes', path: 'work-order-notes', id: data.workOrderId, optionalParams: params, onExit: null, castExit: false, hasTabs: false });
  }

  showWoPacket(data: IWorkOrder): void {
    //launch work order packet in a new tab
    const url = data.hasOwnProperty('metaData') && data['metaData'].hasOwnProperty('workOrderWorkPacket') && data['metaData']['workOrderWorkPacket'];
    if (url) {
      window.open(url);
    } else {
      const msg: string = 'Work Order Packet Not Found for WO #: '.concat(data.workOrderId.toString());
      alert(msg);
    }
  }

  openWarrantyScript(data: IWorkOrder): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.workOrderWarranties,
      key: 'workOrder_workOrderId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.projectId
    }

    this.modalService.open({
      title: 'Work Order Warranty Projects',
      path: 'warranty-scripts',
      id: data.workOrderId,
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false      
    });
  }

  showMiniItemSummary(data: IWorkOrder): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.workOrderItems,
      key: 'workOrder_workOrderId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.projectId
    }

    this.modalService.open({
      title: 'Line Item Summary for WO'.concat(' ', data.workOrderId.toString()),
      path: 'project-work-order-item-summary',
      id: data.workOrderId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] && !changes['projectId'].isFirstChange()) {
      this.newRequest();
    }
    if (changes['workOrders'] && !changes['workOrders'].isFirstChange()) {
      this.selectedWos = [];
    }
  }
}
