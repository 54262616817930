import { Injectable } from '@angular/core';

import {
  IListColumn, ListColumn,
  IListFilter, IListButtonType, IListDefinition,
  ListFilter,  OrderTerm
} from '../../../../fw/dynamic-list/interfaces';
import { SkuMaintEvent, AdminStore, AdminEvent, PayoutMaintEvent } from '../enums/admin.enums';
import { PricingStore, PricingEvent } from '../../pricing-shared/enums/pricing-shared.enums';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { UserPriviledgesService } from '../../../../auth/services';

@Injectable()
export class AdminPricingService {

  constructor(public dynamicListService: DynamicListService,
    public ups: UserPriviledgesService  ) { }

  public loadSkuListDefinition(gcId: number): IListDefinition {
    const listColumns = this.loadSkuListColumns();
    const listObjectLabel = 'Sku';
    const listObjectController = 'Sku';
    const listStoreName = AdminStore.skus;
    const detailRoutePath = 'sku-detail';
    const listRowKeyId = 'skuId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = [];
    defaultListFilter.orderTerm = [new OrderTerm('skuName')];

    let listDefinition = this.dynamicListService.createListDefinition('skuDetailOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = gcId;
    listDefinition.controllerMethod = 'ByGeneralContractor';
    listDefinition.methodParameters = gcId.toString();

    listDefinition.toolButtons.splice(0, 0,
      {
        title: 'Sku Creation Wizard',
        icon: 'fad fa-wand-magic',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: SkuMaintEvent.launchSkuWizard
      }
    );

    let rowButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null }
    ];
    //does not currently handle cascade delete, so no delete button allowed.
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(rowButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Pricing Wizard',
        icon: 'fad fa-wand-magic',
        cssName: 'app-btn-icon--custom-1',
        enabled: true,
        eventName: SkuMaintEvent.launchPricingWizard
      },
      {
        title: 'Maintain Sku Payouts',
        icon: 'far fa-sack-dollar',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: SkuMaintEvent.maintainSkuPayouts
      }
    );

    return listDefinition;
  }

  loadSkuListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('skuName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('skuType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('skuStatus');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('allowNegativePrice');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitMeasure');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('accountingAccountCode');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceCodeOverride');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadSkuPayoutsListDefinition(skuId: number): IListDefinition {
    const listColumns = this.loadSkuPayoutsListColumns();
    const listObjectLabel: string = 'Sku Payout';
    const listObjectController: string = 'SkuPayout';
    const listStoreName: string = AdminStore.skuPayouts;
    const detailRoutePath: string = '';
    const listRowKeyId: string = 'skuPayoutId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.orderTerm = [new OrderTerm('payout_payoutName')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = skuId;
    listDefinition.parentKey = 'sku_skuId';

    listDefinition.showFilter = false;
    listDefinition.controllerMethod = 'BySku';
    listDefinition.methodParameters = skuId.toString();

    //add before the default reload button
    listDefinition.toolButtons.splice(0, 0,
      {
        title: 'Link to Existing Payout',
        icon: 'fad fa-link',
        cssName: 'app-btn-icon--neutral',
        enabled: true,
        eventName: SkuMaintEvent.linkPayout
      },
      {
        title: 'Create Payout Wizard',
        icon: 'fad fa-wand-magic',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: SkuMaintEvent.createAndLinkPayout
      },
    );

    listDefinition.rowButtons = [
      {
        title: 'Unlink Payout from Sku',
        icon: 'fad fa-unlink',
        cssName: 'app-btn-icon--secondary',
        enabled: true,
        eventName: SkuMaintEvent.unlinkPayout
      }
    ]
    return listDefinition;
  }

  loadSkuPayoutsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('payoutName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('payoutStatus');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadChargeBackSkusListDefinition(): IListDefinition {
    const listColumns = this.loadChargeBackSkusListColumns();
    const listObjectLabel = 'Charge Back Sku';
    const listObjectController = 'ChargeBackSku';
    const listStoreName = AdminStore.chargeBackSkus;
    const detailRoutePath = 'charge-back-sku';
    const listRowKeyId = 'chargeBackSkuId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('chargeBackSkuName')];

    let listDefinition = this.dynamicListService.createListDefinition('chargeBackSkuOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = true;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'Manage Charge Back Sku Prices',
        icon: 'fad fa-square-dollar',
        cssName: 'app-btn-icon--success',
        eventName: AdminEvent.manageChargeBackPrices,
        enabled: true
      }
    );

    return listDefinition;
  }

  loadChargeBackSkusListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    //let colDef = new ListColumn('provider');
    //colDef.visibleOnSmall = false;
    //colDef.visibleOnMedium = false;
    //columnDefinitions.push(colDef);

    let colDef = new ListColumn('chargeBackSkuName');
    columnDefinitions.push(colDef);


    colDef = new ListColumn('chargeBackSkuDescription');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('chargeBackType');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('status');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitMeasure');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadChargeBackSkuPricesListDefinition(chargeBackSkuId: number): IListDefinition {
    const listColumns = this.loadChargeBackSkuPricesListColumns();
    const listObjectLabel = 'Charge Back Sku Price';
    const listObjectController = 'ChargeBackSkuPrice';
    const listStoreName = AdminStore.chargeBackSkuPrices;
    const detailRoutePath = 'cb-sku-price';
    const listRowKeyId = 'chargeBackSkuPriceId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    //defaultListFilter.orderTerm = [new OrderTerm('chargeBackSkuName')];

    let listDefinition = this.dynamicListService.createListDefinition('cbSkuPriceOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = chargeBackSkuId;
    listDefinition.parentKey = 'chargeBackSkuId';
    listDefinition.controllerMethod = 'ByChargeBackSku';
    listDefinition.methodParameters = chargeBackSkuId.toString();
    listDefinition.allowSaveFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadChargeBackSkuPricesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

     let colDef = new ListColumn('price');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isGlobal');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('installer');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('providerLocation');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('service');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('displayText');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadProviderPayoutsListDefinition(): IListDefinition {
    const listColumns = this.loadProviderPayoutsListColumns();
    const listObjectLabel = 'Provider Payout';
    const listObjectController = 'Payout';
    const listStoreName = PricingStore.providerPayouts;
    const detailRoutePath = 'payout';
    const listRowKeyId = 'payoutId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('payoutName')];

    let listDefinition = this.dynamicListService.createListDefinition('payoutOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.controllerMethod = 'ByProvider';
    listDefinition.methodParameters = this.ups.providerId$.value.toString();
    listDefinition.allowSaveFilter = false;

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let rowButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(rowButtons, listDefinition.objectLabel);
    listDefinition.rowButtons.splice(0, 0,
      {
        title: 'View Related Sku Payouts',
        icon: 'far fa-sack-dollar',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: PricingEvent.viewSkuPayouts
      },
      {
        title: 'Maintain Payout Prices',
        icon: 'fad fa-square-dollar',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: PricingEvent.managePayoutPrices
      }
    );

    return listDefinition;
  }

  loadProviderPayoutsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('payoutName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('payoutDescription');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('payoutStatus');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('unitMeasure');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('controlsCapacity');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('glCodeOverride');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceCodeOverride');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPayoutSkusListDefinition(payoutId: number): IListDefinition {
    const listColumns = this.loadPayoutSkusListColumns();
    const listObjectLabel: string = 'Payout Sku';
    const listObjectController: string = 'SkuPayout';
    const listStoreName: string = PricingStore.payoutSkus;
    const detailRoutePath: string = '';
    const listRowKeyId: string = 'skuPayoutId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;
    defaultListFilter.orderTerm = [new OrderTerm('sku_skuName')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = payoutId;
    listDefinition.parentKey = 'payoutId_payoutId';

    listDefinition.showFilter = false;
    listDefinition.controllerMethod = 'ByPayout';
    listDefinition.methodParameters = payoutId.toString();

    //add before the default reload button
    listDefinition.toolButtons.splice(0, 0,
      {
        title: 'Link to Existing Sku',
        icon: 'fad fa-link',
        cssName: 'app-btn-icon--neutral',
        enabled: true,
        eventName: PayoutMaintEvent.linkSku
      }
    //  ,  NOT IMPLEMENTED  YET
    //  {
    //    title: 'Create Payout Wizard',
    //    icon: 'fad fa-wand-magic',
    //    cssName: 'app-btn-icon--success',
    //    enabled: true,
    //    eventName: PayoutMaintEvent.createAndLinkPayout
    //  },
    );

    listDefinition.rowButtons = [
      {
        title: 'Unlink Sku from Payout',
        icon: 'fad fa-unlink',
        cssName: 'app-btn-icon--secondary',
        enabled: true,
        eventName: PayoutMaintEvent.unlinkSku
      }
    ]
    return listDefinition;
  }

  loadPayoutSkusListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('generalContractorName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('sku');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPayoutPricesListDefinition(payoutId: number): IListDefinition {
    const listColumns = this.loadPayoutPricesListColumns();
    const listObjectLabel: string = 'Payout Price';
    const listObjectController: string = 'PayoutPrice';
    const listStoreName: string = PricingStore.payoutPrices;
    const detailRoutePath: string = 'payout-price';
    const listRowKeyId: string = 'payoutPriceId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;

    defaultListFilter.orderTerm = [
      new OrderTerm('isGlobal', true, 1),
      new OrderTerm('providerLocation_locationName', true, 2),
      new OrderTerm('service_serviceName', true, 3)
    ];

    let listDefinition = this.dynamicListService.createListDefinition('payoutPriceOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = payoutId;
    listDefinition.parentKey = 'payoutId_payoutId';

    listDefinition.showFilter = false;
    listDefinition.revokeSort = true;
    listDefinition.controllerMethod = 'ByPayout';
    listDefinition.methodParameters = payoutId.toString();

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadPayoutPricesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('isGlobal');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('providerLocation');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('service');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('effectiveDeckPrice');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
