import {ModuleWithProviders, NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DynamicListModule } from '../fw/dynamic-list/dynamic-list.module';
import { DynamicListService } from '../fw/dynamic-list/services';

import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { RefreshUserComponent } from './refresh-user/refresh-user.component';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';

import * as fromAuth from './store/auth.reducer';
import { AuthGuard, UserPriviledgesService, HomHubService} from './services/index';
import { AuthEffects } from './store/auth.effects';



@NgModule({
  declarations: [
    SigninComponent,
    SignupComponent,
    RefreshUserComponent
  ],
  imports: [
    FormsModule, 
    CommonModule,
    SharedModule,
    DynamicListModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', fromAuth.authReducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  exports: [
    SigninComponent,
    SignupComponent,
    RefreshUserComponent
  ],
  providers: [
    DynamicListService
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [ AuthGuard, UserPriviledgesService, HomHubService]
    }
  }
}
