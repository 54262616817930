import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DragulaModule, DragulaService} from 'ng2-dragula';
import { FwSharedModule } from '../../fw/fw-shared/fw-shared.module';

import { WidgetLayoutEditorComponent } from './widget-layout-editor.component';

@NgModule({
    declarations: [
        WidgetLayoutEditorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        DragulaModule,
        FwSharedModule
    ],
    providers: [
        DragulaService
    ]
})

export class WidgetLayoutModule {}
