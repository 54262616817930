export const environment = {
  production: true,
  qa: false,
  //apiUrl: 'https://crm.homsolutionsinc.com/Api'
 // loginUrl: 'https://crm.homsolutionsinc.com'
  apiUrl: '../Api',
  loginUrl: '..',
  hubUrl: 'https://homhub.homsolutionsinc.com',
  smsInstallerEnabled: true,
  smsCustomerEnabled: true,
  smsInstallerSendDocEnabled: true,
  smsCustomerSendDocEnabled: true,
  entityTabLimit: 10,
  scheduleDayLimit: 180,
  adminDeleteEnabled: false
};
