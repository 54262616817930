import { Component, Input, ViewChild, AfterViewInit, OnDestroy, ViewContainerRef } from '@angular/core';

import { DynamicComponentDirective } from './dynamic-component.directive';
import { DynamicItem } from './dynamic-item';
import { DomainObjectService } from '../../shared/services/domain-object.service';

@Component({
    selector: 'dynamic-host',
    template: `
              <div>
                <ng-template dynamic-component-host></ng-template>
              </div>
            `
})
export class DynamicHostComponent implements  AfterViewInit, OnDestroy {
    @Input() componentSeedData: DynamicItem; //just seed data

  @ViewChild(DynamicComponentDirective) homHost: DynamicComponentDirective;

    interval: any;

  constructor(public viewContainerRef: ViewContainerRef,
      public domainObjectService: DomainObjectService) {
    }

  ngAfterViewInit() {
        //console.log("dynamic host in after view init");
        if (this.componentSeedData) {
            //TODO:  really just want to call loadComponent - but because we use this same host for all our components,not working without the fake method..YET!!
            this.callFakeMethod();
        }
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    // Creates a new instance of the passed in component and loads it with the data in componentData
    loadComponent() {

        //let viewContainerRef = this.homHost.viewContainerRef;
        //viewContainerRef.clear();

      const componentRef = this.viewContainerRef.createComponent(this.componentSeedData.component);

        //(<IDynamicObject>componentRef.instance).data = this.componentData.data;
        //TODO:  part of the stupid 
        clearInterval(this.interval);

    }

    // Get Component Data by id
    callFakeMethod() : void {
        //console.log("dynamic host - In getComponentData");

        //TODO: THIS IS STUPID, BUT WITHOUT IT RECEIVES AN ERROR
        //ERROR Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'undefined'. Current value: 'undefined'. It seems like the view has been created after its parent and its children have been dirty checked. Has it been created in a change detection hook ?
        //can use this interval or go get some data for the heck of it.
        this.interval = setInterval(() => {
            this.loadComponent();
        }, 1);

    }


}
