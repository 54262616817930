import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IDynamicListState, IListObjectData } from '../../../../fw/dynamic-list/store';
import { DeleteObjectModel, UpdateObjectByIdModel, IKey } from '../../../../fw/dynamic-list/store/interfaces/index';
import { IListDefinition, IListFilter } from '../../../../fw/dynamic-list/interfaces';
import { IUserPreference } from '../../../../app/portals/view-models/index';
import { IEntityDocument } from '../../../portals/view-models/index_two';
import { IErrorData } from '../../../../shared/interfaces';
import * as fromRoot from '../../../../auth/store/index';
import * as fromStore from '../../../../fw/dynamic-list/store/index';
import * as fromAuth from '../../../../auth/store/index';
import * as DynamicListActions from '../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';

@Component({
  selector: 'file-viewer-container',
  templateUrl: './file-viewer-container.component.html'
})

export class FileViewerContainerComponent implements OnInit, OnDestroy {
  public pages: number = 0;
  public currentPage: number = 1;
  public activeIdx: number;
  public files: IEntityDocument[];

  fileId: number;
  parentId: number;
  storeName: string;
  storeKeyName: string;
  pageSize: number;
  listDefinition: IListDefinition;
  listFilter: IListFilter;
  subscription: Subscription = new Subscription();
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public operation$: BehaviorSubject<string> = new BehaviorSubject(this.myConstants.operationTypeDetails);

  constructor(
    public  activeRoute: ActivatedRoute,
    @Inject(appConstants) public  myConstants: IAppConstants,
    public  rootStore: Store<fromRoot.IState>,
    public  store: Store<fromStore.IAllDynamicData>) { }

  public onCustom(event: IHomEventEmitter): void {
    this.errorData$.next([]);
    switch (event.event) {
      case ButtonType.save:
        this.updateDocument(event.data);
        break;
      case ButtonType.delete:
        this.deleteFile(event.data);
        break;
      case this.myConstants.operationTypeEdit:
        this.operation$.next( this.myConstants.operationTypeEdit);
        break;
      case ButtonType.cancel:
        this.operation$.next(this.myConstants.operationTypeDetails);
        break;
      case this.myConstants.emitterEventError:
        this.operation$.next(this.myConstants.operationTypeEdit);
        break;
      default:
        break;
    }
  }
  public setActive(idx: number): void {
    this.fileId = this.files[this.activeIdx = idx].latestFileUploadId;
    this.operation$.next(this.myConstants.operationTypeDetails);
    this.errorData$.next([]);
}

  public showPage(page: number): void {
    this.listFilter.currentPage = this.currentPage += page;
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: this.listDefinition, listFilter: this.listFilter, parentId: this.parentId }));
    this.operation$.next(this.myConstants.operationTypeDetails);
    this.errorData$.next([]);
 }

  public updateDocument(entityDocument: IEntityDocument): void {
    if (!entityDocument) return;

    var event: IHomEventEmitter = { requestor: this.storeName, event: this.myConstants.emitterEventUpdate, action: this.myConstants.emitterEventClose, data: null };
    var keyData: IKey = {
      storeName: this.storeName,
      parentId: this.parentId,
      key: this.storeKeyName,
      id: entityDocument.entityDocumentId
    };
    var data = {
      model: entityDocument,
      caption: entityDocument.fileName //extra data field must put in own property to return
    }
    const updateData = new UpdateObjectByIdModel(keyData, 'EntityDocument', 'UpdateWithCaption', this.storeKeyName, entityDocument.entityDocumentId, data, null, event);
    this.store.dispatch(new DynamicListActions.UpdateObjectByIdList({ updateData }));
  }

  //TODO DOC MGT:  add check for existence of delete url - in common util
  public deleteFile(file: IEntityDocument): void {
    const event: IHomEventEmitter = {
      requestor: this.listDefinition.detailRoutePath,
      event: this.myConstants.emitterEventDelete,
      action: '', data: null
    };
    const deleteData = new DeleteObjectModel({
      storeName: this.storeName, parentId: this.parentId, key: this.storeKeyName,
      id: file[this.storeKeyName]
    }, file.metaData.crud.deleteUrl, event);
    this.store.dispatch(new DynamicListActions.DeleteObjectByUrlList({ deleteData }));
  }

ngOnInit() {
  this.activeRoute.paramMap.subscribe(paramMap => {
      this.fileId = +paramMap.get('id');
      this.parentId = +paramMap.get('portalEntityId');
      this.storeName = paramMap.get('storeName');
      this.storeKeyName = paramMap.get('key');
    });

    this.subscription.add(this.rootStore.pipe(select(fromAuth.getUserPreferences)).subscribe((data: IUserPreference) => {
      this.pageSize = data.pageSize;
    }));
     
    this.subscription.add(this.store.pipe(select(fromStore.getListByType(this.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId === this.parentId)))
      .subscribe((objData: IListObjectData) => {
        if (objData.data && objData.data.length) {
          const data: IEntityDocument[] = objData.data,
            activeIdx: number = data.findIndex(x => x.latestFileUploadId === this.fileId);
          this.listDefinition = cloneDeep(objData.listDefinition);
          this.listFilter = cloneDeep(objData.listFilter);
          this.pages = Math.ceil(objData.listMetaData.totalItems / this.pageSize);
          if (this.pages > 1) this.currentPage = cloneDeep(objData.listMetaData.pageMetaData.currentPage);
          this.files = data;
          this.activeIdx = (activeIdx !== -1 ? activeIdx : 0);
        }
        if (objData.errorData && objData.errorData.length > 0) {
          this.errorData$.next(objData.errorData);
        } else {
          this.errorData$.next([]);
          this.operation$.next(this.myConstants.operationTypeDetails);
        }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
