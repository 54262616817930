import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IWorkOrderViewModel } from '../../../view-models';
import { UtilitiesEvent } from '../../enums/utilities.enums';
import { IQuickWorkOrderTool } from '../../interfaces';
import { WorkOrderStatus } from '../../../scheduler/enums/schedule.enums';

import { DispatchCalendarService } from '../../services/dispatch-calendar.service';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'quick-work-order-tools',
  templateUrl: './quick-work-order-tools.component.html'
})

export class QuickWorkOrderToolsComponent  implements OnInit {
  @Input() title: string = '';
  @Input() row: IWorkOrderViewModel;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public isVisible: boolean = false;
  public tools: IQuickWorkOrderTool[] = [];

  constructor(
    public dispatchCalendarService: DispatchCalendarService,
    public userPrivilegeService: UserPriviledgesService ) { }

  public toggle(event): void {
    this.isVisible = !this.isVisible;
    event.stopPropagation();
  }

  public useTool(toolEvent: string): void {
    let event: IHomEventEmitter = { requestor: 'dispatch-day-view', event: toolEvent, action: '', data: this.row };
    this.customEvent.emit(event);
    this.isVisible = false;
 }

  ngOnInit(): void {
    this.loadTools();
  }

  loadTools(): void {
    const canModify: boolean = this.dispatchCalendarService.canModify(this.row);
    const hasDeleteUrl: boolean = this.userPrivilegeService.canIDelete(this.row);
    let tools: IQuickWorkOrderTool[] = [];
    tools.push({
      label: 'Go Work Order',
      icon: 'fad fa-share-square',
      cssName: 'app-btn-icon--custom-3',
      eventName: UtilitiesEvent.goToWorkOrder,
      disabled: false,
      title: 'Go Work Order'});
    tools.push({
      label: 'Print Packet',
      icon: 'fas fa-print',
      cssName: 'app-btn-icon--custom-1',
      eventName: UtilitiesEvent.showWoPacket,
      disabled: this.row.workOrderStatus === WorkOrderStatus.workComplete || this.row.workOrderStatus == WorkOrderStatus.dispatched ? false : true,
      title: this.row.workOrderStatus === WorkOrderStatus.workComplete || this.row.workOrderStatus === WorkOrderStatus.dispatched ? 'Print Packet' : 'Unable to Print Packets For Work Orders in ' + this.row.workOrderStatus + ' Status'
    });
    tools.push({
      label: 'Reschedule',
      icon: 'fad fa-calendar-alt',
      cssName: 'app-btn-icon--custom-2',
      eventName: UtilitiesEvent.rescheduleWorkOrder,
      disabled: !canModify,
      title: 'Reschedule'
    });
    tools.push({
      label: 'Add Note',
      icon: 'fas fa-comment-plus',
      cssName: 'app-btn-icon--primary',
      eventName: UtilitiesEvent.addWorkOrderNote,
      disabled: false,
      title: 'Add Note'
    });
    tools.push({
      label: 'Dispatch',
      icon: 'far fa-people-carry',
      cssName: 'app-btn-icon--neutral',
      eventName: UtilitiesEvent.dispatchWorkOrder,
      disabled: !canModify,
      title: 'Dispatch'
    });
    tools.push({
      label: 'UnDispatch',
      icon: 'fad fa-unlink',
      cssName: 'app-btn-icon--secondary',
      eventName: UtilitiesEvent.unDispatchWorkOrder,
      disabled: !canModify || !this.row.installerName,
      title: this.row.installerName ? 'UnDispatch' : 'Installer Not Set'
    });
    tools.push({
      label: 'Delete',
      icon: 'fas fa-trash',
      cssName: 'app-btn-icon--danger',
      eventName: UtilitiesEvent.deleteSchedule,
      disabled: !canModify || !hasDeleteUrl,
      title: 'Delete'
    });
    this.tools = tools;
  }

}
