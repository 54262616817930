
export interface IProviderRole {
  providerRoleId: number;
  provider_providerId: number;
  roleName: string;
  createDate: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
}

export class ProviderRole implements IProviderRole {
  providerRoleId: number;
  provider_providerId: number;
  roleName: string;
  createDate: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  constructor() {
    this.providerRoleId = 0;
    this.provider_providerId = null;
    this.roleName = '';
    this.createDate = '';
    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';
  }
}
