import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'contact-mechanism-category-types',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './contact-mechanism-category-types.component.html'
})
export class ContactMechanismCategoryTypesComponent implements OnInit {

  public listDefinition: IListDefinition;

  constructor(
    public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService  ) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      const contactMechanismCategoryId = +paramMap.get('id');
      this.newRequest(contactMechanismCategoryId);
    });
  }

  newRequest(contactMechanismCategoryId: number) {
    this.listDefinition = this.adminService.loadContactMechanismCategoryTypesListDefinition(contactMechanismCategoryId);
  }

}
