
export enum AdminStore {
  userSecurity = 'userSecurity',
  skus = 'skus',
  skuPayouts = 'skuPayouts',
  communicationShortcuts = 'communicationShortcuts',
  communicationDocumentTypes = 'communicationDocumentTypes',
  customerFlags = 'customerFlags',
  lookupTypes = 'lookupTypes',
  lookupValues = 'lookupValues',
  providerRoles = 'providerRoles',
  roleWidgets = 'roleWidgets',
  roleUsers = 'roleUsers',
  rolePrivilegeTemplates = 'rolePrivilegeTemplates',
  privilegeTemplates = 'privilegeTemplates',
  privilegeTemplateRoles = 'privilegeTemplateRoles',
  privilegeTemplatePrivileges = 'privilegeTemplatePrivileges',
  privilegeTemplatePrivilegeValues = 'privilegeTemplatePrivilegeValues',
  privilegeRuleEntitySpecifiers = 'privilegeRuleEntitySpecifiers',
  providerScheduleExclusions = 'providerScheduleExclusions',
  privilegeFilterWhereUsed = 'privilegeFilterWhereUsed',
  privileges = 'privileges',
  privilegeWhereUsed = 'privilegeWhereUsed',
  zones = 'zones',
  installerCompatibilityPreferences = 'installerCompatibilityPreferences',
  chargeBackTypes = 'chargeBackTypes',
  communicationEventTypes = 'communicationEventTypes',
  communicationEventFeedbacks = 'communicationEventFeedbacks',
  contactMechanismCategories = 'contactMechanismCategories',
  contactMechanismCategoryTypes = 'contactMechanismCategoryTypes',
  workCategories = 'workCategories',
  workCategoryPayouts = 'workCategoryPayouts',
  contactTypes = 'contactTypes',
  contactTypeSpecifications = 'contactTypeSpecifications',
  serviceGroups = 'serviceGroups',
  serviceGroupServices = 'serviceGroupServices',
  generalContractors = 'generalContractors',
  branches = 'branches',
  programs = 'programs',
  programServices = 'programServices',
  regions = 'regions',
  services = 'services',
  servicePrograms = 'servicePrograms',
  regionBranches = 'regionBranches',
  branchRegions = 'branchRegions',
  branchPrograms = 'branchPrograms',
  psBranchPrograms = 'psBranchPrograms',
  branchProviderLocation = 'branchProviderLocation',
  branchSkuPrices = 'branchSkuPrices',
  psSkuPrices = 'psSkuPrices',
  regionSkuPrices = 'regionSkuPrices',
  psFiles = 'psFiles',
  servicePls = 'servicePls',
  branchNotes = 'branchNotes',
  documentTypes = 'documentTypes',
  surveys = 'surveys',
  surveyQuestionDataTypes = 'surveyQuestionDataTypes',
  surveyQuestions = 'surveyQuestions',
  surveyProjectMapFields = 'surveyProjectMapFields',
  surveyImportMappings = 'surveyImportMappings',
  surveyProgramServices = 'surveyProgramServices',
  warrantyTypes = 'warrantyTypes',
  warrantySubTypes = 'warrantySubTypes',
  shippers = 'shippers',
  shipperLocations = 'shipperLocations',
  shipperPayouts = 'shipperPayouts',
  shipperLocationPayouts = 'shipperLocationPayouts',
  providerLocations = 'providerLocations',
  locationBranches = 'locationBranches',
  locationServices = 'locationServices',
  chargeBackSkus = 'chargeBackSkus',
  chargeBackSkuPrices = 'chargeBackSkuPrices',
  sssGeneralContractors = 'sssGeneralContractors',
  sssPrograms = 'sssPrograms',
  sssProgramServices = 'sssProgramServices',
  sssPSBranches = 'sssPSBranches'
}

export enum AdminEvent {
  viewUserSecurity = 'viewUserSecurity',
  editUserSecurity = 'editUserSecurity',
  syncLdapUser = 'syncLdapUser',
  cancelEdit = 'cancelEdit',
  cancelAdd = 'cancelAdd',
  requestAdd = 'requestAdd',  
  selectNewAccess = 'selectNewAccess',
  deleteUserAccess = 'deleteUserAccess',
  saveUserAccess = 'saveUserAccess',
  addRole = 'addRole',
  addGc = 'addGc',
  addService = 'addService',
  addLocation = 'addLocation',
  addBranch = 'addBranch',
  deleteRole = 'deleteRole',
  deleteGc = 'deleteGc',
  deleteService = 'deleteService',
  deleteLocation = 'deleteLocation',
  deleteBranch = 'deleteBranch',
  manageRoleWidgets = 'manageRoleWidgets',
  manageRoleUsers = 'manageRoleUsers',
  manageRolePrivilegeTemplates = 'manageRolePrivilegeTemplates',
  showRelatedEntityManager = 'showRelatedEntityManager',
  managePrivTemplateProviderRoles = 'managePrivTemplateProviderRoles',
  managePrivTemplatePrivileges = 'managePrivTemplatePrivileges',
  managePrivilegeValues = 'managePrivilegeValues',
  showFiltersWhereUsed = 'showFiltersWhereUsed',
  showPrivilegesWhereUsed = 'showPrivilegesWhereUsed',
  showWorkCategoryPayouts = 'showWorkCategoryPayouts',
  manageContactMechanismCategoryTypes = 'manageContactMechanismCategoryTypes',
  manageContactTypeSpecifications = 'manageContactTypeSpecifications',
  showServiceGroupServices = 'showServiceGroupServices',
  launchServiceGroupsWizard = 'launchServiceGroupsWizard',
  manageBranchContact = 'manageBranchContact',
  manageBranchPrograms = 'manageBranchPrograms',
  manageBranchPLs = 'manageBranchPLs',
  manageBranchRegions = 'manageBranchRegions',
  manageBranchNotes = 'manageBranchNotes',
  manageServicePrograms = 'manageServicePrograms',
  manageServicePLs = 'manageServicePLs',
  manageRegionBranches = 'manageRegionBranches',
  managePSFiles = 'managePSFiles',
  managePSBranchPrograms = 'managePSBranchPrograms',
  manageProgramServices = 'manageProgramServices',
  manageGCBranches = 'manageGCBranches',
  manageGCPrograms = 'manageGCPrograms',
  manageGCRegions = 'manageGCRegions',
  showFile = 'showFile',
  downloadFile = 'downloadFile',
  printDocument = 'printDocument',
  uploadFile = 'uploadFile',
  launchSurveyWizard = 'launchSurveyWizard',
  showSurveyQuestions = 'showSurveyQuestions',
  showProgramServices = 'showProgramServices',
  showQuestionProjectMappings = 'showQuestionProjectMappings',
  showQuestionImportMappings = 'showQuestionImportMappings',
  manageWarrantyTypeSubTypes = 'manageWarrantyTypeSubTypes',
  manageShipperPayouts = 'manageShipperPayouts',
  manageShipperLocations = 'manageShipperLocations',
  manageShipperLocationPayouts = 'manageShipperLocationPayouts',
  manageLocationContact = 'manageLocationContact',
  manageLocationHours = 'manageLocationHours',
  manageLocationBranches = 'manageLocationBranches',
  manageLocationServices = 'manageLocationServices',
  manageChargeBackPrices = 'manageChargeBackPrices'
}

export enum ServiceGroupCreationWizardAction {
  close = 'close',
  next = 'next'
}

export enum ServiceGroupCreationWizardEvent {
  createServiceGroup = 'createServiceGroup',
  showServiceGroupServices = 'showServiceGroupServices',
  manageGCBranches = 'manageGCBranches',
  manageGCPrograms = 'manageGCPrograms',
  manageGCRegions = 'manageGCRegions',
  manageBranchContact = 'manageBranchContact',
  manageBranchPLs = 'manageBranchPLs',
  manageBranchPrograms = 'manageBranchPrograms',
  manageBranchRegions = 'manageBranchRegions',
  manageBranchNotes = 'manageBranchNotes',
  manageProgramServices = 'manageProgramServices',
  manageRegionBranches = 'manageRegionBranches',
  manageServicePrograms = 'manageServicePrograms',
  manageServicePLs = 'manageServicePLs',
  managePSBranchPrograms = 'managePSBranchPrograms',
  managePSFiles = 'managePSFiles',
  serviceGroupCreated = 'serviceGroupCreated'
}

export enum PrivilegeRuleEntitySpecifierType {
  primaryService = 5,
  secondaryService = 6,
  primaryProviderLocation = 7,
  secondaryProviderLocation = 8,
  primaryBranch = 9,
  secondaryBranch = 10,
  warrantyBranch = 11,
  primaryGeneralContractor = 12,
  secondaryGeneralContractor = 13,

  //ui custom to genericize access updates
  userRole = 1776
}

export enum SkuMaintEvent {
  launchSkuWizard = 'launchSkuWizard',
  launchPricingWizard = 'launchPricingWizard',
  maintainSkuPayouts = 'maintainSkuPayouts',
  unlinkPayout = 'unlinkPayout',
  linkPayout = 'linkPayout',
  createAndLinkPayout = 'createAndLinkPayout'
}

export enum PayoutMaintEvent {
  unlinkSku = 'unlinkSku',
  linkSku = 'linkSku',
  selectSku = 'selectSku'
}

export enum RelatedParentType {
  providerRole = 'providerRole',
  privilegeTemplate = 'privilegeTemplate',
  generalContractor = 'generalContractor',
  branch = 'branch',
  program = 'program',
  programService = 'programService',
  region = 'region',
  service = 'service',
  providerLocation = 'providerLocation'
}
