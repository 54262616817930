<div class="cc-flag">
  <span *ngIf="!canFlagCustomer || hasStaleData" title={{getFlagDesc(false)}}
        class="app-icon--neutral cc-flag--readonly" [style.color]="getFlagColor()">
    <i class="fas fa-flag"></i>
  </span>
  <button *ngIf="canFlagCustomer && !hasStaleData" class="app-btn-icon app-btn-icon--neutral"
          [style.color]="getFlagColor()"
          (click)="showDetail()" title={{getFlagDesc(true)}}>
    <i class="fas fa-flag"></i>
  </button>
</div>
