import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect} from '@ngrx/effects';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Action, Store} from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, mergeMap, map, tap, catchError } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import * as Bowser from 'bowser';

;import { IResponseBase } from '../../shared/interfaces/index';
import {  IAuthResult} from '../interfaces/index';
import { UpdateObjectModel } from './interfaces/index'; //store interfaces
import { IListFilter, ListFilter, OrderTerm } from '../../fw/dynamic-list/interfaces/index'
import { IAuthError } from './auth.reducer';
import { AccessLevel } from '../../fw/dynamic-list/enums/access-level.enums';
import { SearchType } from '../../fw/dynamic-list/enums/search-type.enums';
import { environment } from './../../environments/environment';
import { HomLocalStorage } from '../enums/hom-local-storage.enums';

import * as fromRoot from '../../app/store/reducers/index';
import * as AuthActions from './auth.actions';
import * as SelectionListActions from '../../shared/store/selectionLists/selectionLists.actions';
import * as LoadingIndicatorActions from '../../shared/store/loadingIndicator/loadingIndicator.actions';
import { DomainObjectService, GeolocationService } from '../../shared/services/index';
import { DynamicListService } from '../../fw/dynamic-list/services';

// Actions used in these Effects that need to trigger Spinner hide/show
type ShowSpinnerTypes = | AuthActions.TrySignin
  | AuthActions.GetUserConfigurations
  | AuthActions.GetUserNotifications
  | AuthActions.GetUserPreferences
  | AuthActions.UpdateUserPreferences
  | AuthActions.GetAllMyWidgets
  | AuthActions.GetPortalTabs
  | AuthActions.GetParameterSearchTypes
  | AuthActions.RefreshUserInformation
  | AuthActions.GetListComponents
  | AuthActions.GetUserRecents
  | AuthActions.CreateUserRecent
  | AuthActions.DeleteUserRecent;

const showSpinnerActions = [
  AuthActions.AuthActionTypes.TRY_SIGNIN_ACTION,
  AuthActions.AuthActionTypes.GET_USER_CONFIGURATIONS_ACTION,
  AuthActions.AuthActionTypes.GET_USER_NOTIFICATIONS_ACTION,
  AuthActions.AuthActionTypes.GET_USER_PREFERENCES_ACTION,
  AuthActions.AuthActionTypes.UPDATE_USER_PREFERENCES_ACTION,
  AuthActions.AuthActionTypes.GET_ALL_MY_WIDGETS_ACTION,
  AuthActions.AuthActionTypes.GET_PORTAL_TABS,
  AuthActions.AuthActionTypes.GET_PARAMETER_SEARCH_TYPES,
  AuthActions.AuthActionTypes.REFRESH_USER_INFORMATION_ACTION,
  AuthActions.AuthActionTypes.GET_LIST_COMPONENTS,
  AuthActions.AuthActionTypes.GET_USER_RECENTS,
  AuthActions.AuthActionTypes.CREATE_USER_RECENT,
  AuthActions.AuthActionTypes.DELETE_USER_RECENT
];

type HideSpinnerTypes =
  | AuthActions.SigninSuccess
  | AuthActions.SetSigninError
  | AuthActions.SetErrorReturned
  | AuthActions.SetAllMyWidgets
  | AuthActions.SetUserConfigurations
  | AuthActions.SetUserPreferences
  | AuthActions.UpdateUserPreferencesComplete
  | AuthActions.SetPortalTabs
  | AuthActions.SetParameterSearchTypes
  | AuthActions.SetListComponents
  | AuthActions.SetUserRecents
  | AuthActions.CreateUserRecentComplete
  | AuthActions.DeleteUserRecentComplete

const hideSpinnerActions = [
  AuthActions.AuthActionTypes.SIGNIN_SUCCESS_ACTION,
  AuthActions.AuthActionTypes.SET_SIGNIN_ERROR_ACTION,
  AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
  AuthActions. AuthActionTypes.SET_ALL_MY_WIDGETS_ACTION,
  AuthActions.AuthActionTypes.SET_USER_CONFIGURATIONS_ACTION,
  AuthActions.AuthActionTypes.SET_USER_PREFERENCES_ACTION,
  AuthActions.AuthActionTypes.SET_USER_NOTIFICATIONS_ACTION,
  AuthActions.AuthActionTypes.UPDATE_USER_PREFERENCES_COMPLETE_ACTION,
  AuthActions.AuthActionTypes.SET_PORTAL_TABS,
  AuthActions.AuthActionTypes.SET_PARAMETER_SEARCH_TYPES,
  AuthActions.AuthActionTypes.SET_LIST_COMPONENTS,
  AuthActions.AuthActionTypes.SET_USER_RECENTS,
  AuthActions.AuthActionTypes.CREATE_USER_RECENT_COMPLETE,
  AuthActions.AuthActionTypes.DELETE_USER_RECENT_COMPLETE
];

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
  //headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
  //headers: new HttpHeaders().set('Authorization', 'Bearer asdfasfasfasdfaskj') //example of sending an auth header with a token
};

//using Effects so can assign actions to an observable result set
@Injectable()
export class AuthEffects {
  baseUrl: string = environment.loginUrl;  //login doesn't prefix with api'
  baseApiUrl: string = environment.apiUrl;
  defaultListFilter: IListFilter;

  constructor(
    public http: HttpClient,
    public actions$: Actions,
    public router: Router,
    public domainObjectService: DomainObjectService,
    public geolocationService: GeolocationService,
    public dynamicListService: DynamicListService,
    public rootStore: Store<fromRoot.IState> ) {
    this.defaultListFilter = new ListFilter();
  }
  /****************************************************************************
 * Sets header values for geolocation and logoutevent depending on thier existence during login
****************************************************************************/
  setAuthHeaders() {
    const browser = Bowser.getParser(window.navigator.userAgent),
      browserName = browser.getBrowserName(),
      browserVersion = browser.getBrowserVersion();
    const logoutEvent = localStorage.getItem(HomLocalStorage.HomLogoutEvent);
    if (this.geolocationService.hasLocation && !logoutEvent) {
      return { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', 'Browser-Name': browserName, 'Browser-Version': browserVersion, 'Geolocation': ('longitude: ' + this.geolocationService.longitude + ', latitude: ' + this.geolocationService.latitude) })}
    }
    if (!this.geolocationService.hasLocation && logoutEvent) {
      return { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', 'Browser-Name': browserName, 'Browser-Version': browserVersion, 'Logout-Event': logoutEvent }) }
    }
    if (this.geolocationService.hasLocation && logoutEvent) {
      return { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', 'Browser-Name': browserName, 'Browser-Version': browserVersion, 'Geolocation': ('longitude: ' + this.geolocationService.longitude + ', latitude: ' + this.geolocationService.latitude), 'Logout-Event': logoutEvent }) }
    }
    return { headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', 'Browser-Name': browserName, 'Browser-Version': browserVersion }) }
  }

/****************************************************************************
 * Triggered when included in ShowSpinnerTypes/ HideSpinnerTypes
****************************************************************************/

  showSpinner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ShowSpinnerTypes>(...showSpinnerActions),
      map(() => new LoadingIndicatorActions.ShowSpinner({ requestor: 'auth', id: -1 })));
  });

  hideSpinner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<HideSpinnerTypes>(...hideSpinnerActions),
      map(() => new LoadingIndicatorActions.HideSpinner({ requestor: 'auth', id: -1 })));
  });


/****************************************************************************
    Signin
              return this.http.post(url, '', httpOptions).toPromise();
****************************************************************************/
  authSignin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AuthActions.TrySignin>(AuthActions.AuthActionTypes.TRY_SIGNIN_ACTION),
      mergeMap((action: AuthActions.TrySignin) =>
        this.http.post(`${this.baseUrl}/Account/LogOn`, JSON.stringify(action.payload.authData), this.setAuthHeaders()).pipe(
          mergeMap((data: IAuthResult) => {
            let doReload: boolean = false;
            if (data.success) {
              const version = localStorage.getItem(HomLocalStorage.HomVersion) || null;
              const mismatch: boolean = !version ? false : version !== data.crmVersion;
              doReload = (environment.production || environment.qa) ? mismatch : false;
              localStorage.setItem(HomLocalStorage.HomVersion, data.crmVersion);
              localStorage.setItem(HomLocalStorage.HomUser, JSON.stringify({ providerUser: data.providerUser, token: data.token, expiry: new Date().addDays(1).setHours(0, 0, 1) }));
              localStorage.setItem(HomLocalStorage.SessionId, data.sessionId);
              localStorage.removeItem(HomLocalStorage.HomInstallerPortal);
            }
            return [
              { type: AuthActions.AuthActionTypes[data.success ? 'SIGNIN_SUCCESS_ACTION' : 'SET_SIGNIN_ERROR_ACTION'], payload: data },
              { type: AuthActions.AuthActionTypes[data.success && !doReload ? 'GET_USER_CONFIGURATIONS_ACTION' : 'SET_USER_CONFIGURATIONS_ACTION'], payload: data.success ? data : null },
              { type: AuthActions.AuthActionTypes['SET_APPLICATION_VERSION_ACTION'], payload: doReload } //sets init reload not version
            ];
          })
        )
      )
    );
  });

/****************************************************************************
 Get User Configurations
          Begins the load of user specific settings

****************************************************************************/
  getUserConfigurations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AuthActions.GetUserConfigurations>(AuthActions.AuthActionTypes.GET_USER_CONFIGURATIONS_ACTION),
      switchMap((action) =>
        this.domainObjectService.getListByMetaUrl(`${this.baseApiUrl}/User/default/Providers`).pipe(
          mergeMap((response: IResponseBase) => {
            const data = { providerUser: response.success ? response.data[0] : null, returnUrl: action.payload.returnUrl };
            if (!response.success) {
              const error: IAuthError[] = [];
              const result: IAuthResult = { success: false, token: null, errors: error, providerUser: null, returnUrl: '' };
              return [
                { type: AuthActions.AuthActionTypes.LOGOUT_ACTION, payload: result },
                { type: AuthActions.AuthActionTypes.SET_SIGNIN_ERROR_ACTION, payload: result }
              ];
            } else {
              let lookupFilter: IListFilter = new ListFilter();
              lookupFilter.isLookup = true;
              lookupFilter.accessLevel = AccessLevel.FullAccess;
              lookupFilter.searchTerm = [{ term: 'contact_contactId', value: null, searchType: SearchType.Equals, columnName: 'contact', fieldType: 'int' }];
              lookupFilter.getAll = true;

              return [
                { type: AuthActions.AuthActionTypes.SET_USER_CONFIGURATIONS_ACTION, payload: data },
                { type: AuthActions.AuthActionTypes.GET_USER_PREFERENCES_ACTION, payload: { providerUserId: data.providerUser.currentUserId } },
                { type: AuthActions.AuthActionTypes.GET_PORTAL_TABS, payload: { providerId: data.providerUser.providerId } },
                { type: AuthActions.AuthActionTypes.GET_ALL_MY_WIDGETS_ACTION },
                { type: AuthActions.AuthActionTypes.GET_PARAMETER_SEARCH_TYPES, payload: { providerId: data.providerUser.providerId } },
                { type: AuthActions.AuthActionTypes.GET_LIST_COMPONENTS },
                {
                  type: SelectionListActions.SelectionListActionTypes.GET_ENTITY_LIST, payload: {
                    entityName: 'providerUser',
                    listFilter: lookupFilter,
                    storeName: 'providerUser',
                  }
                },
                {
                  type: AuthActions.AuthActionTypes.GET_USER_RECENTS,
                  payload: { providerUserId: data.providerUser.currentUserId }
                },
                {
                  type: AuthActions.AuthActionTypes.GET_USER_LIST_FILTERS,
                  payload: { providerUserId: data.providerUser.currentUserId }
                }
              ];
            }
          }))));

  });

/****************************************************************************
An effect that is called when the user is logged in successfully, then does something like Refreshes the page.
****************************************************************************/
  refreshUserInformation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AuthActions.RefreshUserInformation>(AuthActions.AuthActionTypes.REFRESH_USER_INFORMATION_ACTION),
      mergeMap((action: AuthActions.RefreshUserInformation) =>
        this.http.post(`${this.baseUrl}/Account/RefreshUser`, JSON.stringify({ userName: action.payload.providerUser.name }), httpOptions).pipe(
          mergeMap((data: IAuthResult) => {
            if (data.success) {
              return [{ type: AuthActions.AuthActionTypes.SIGNIN_SUCCESS_ACTION, payload: action.payload },
              { type: AuthActions.AuthActionTypes.GET_USER_CONFIGURATIONS_ACTION, payload: action.payload }
              ]
            }
            else {
             return [{ type: AuthActions.AuthActionTypes.SET_SIGNIN_ERROR_ACTION, payload: data },
               { type: AuthActions.AuthActionTypes.LOGOUT_ACTION, payload: { initLogoutUserSessionEvent: true } }
              ]
            }
          })
        )
      )
    );
  });

/****************************************************************************
GET USER RECENTS
****************************************************************************/
  getUserRecents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AuthActions.GetUserRecents>(AuthActions.AuthActionTypes.GET_USER_RECENTS),
      map((action: AuthActions.GetUserRecents) => action.payload),
      mergeMap((payload) => {
        const providerUserId = payload.providerUserId;
        let listFilter = new ListFilter();
        listFilter.getAll = true;
        listFilter.orderTerm = [new OrderTerm('timestamp', false)];
        return this.domainObjectService.getByMethodById('UserRecent', 'ByProviderUser', providerUserId, listFilter)
          .pipe(
            map((response: IResponseBase) => new AuthActions.SetUserRecents({ responseBase: response })
            )
          );
      }),
      catchError(() => of({
        type: AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
        payload: { errors: { 'key': '__Model', 'value': 'Failed to retrieve user recents' } }
      }))
    );
  });
/****************************************************************************
CREATE USER RECENT
****************************************************************************/
  createUserRecent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AuthActions.CreateUserRecent>(AuthActions.AuthActionTypes.CREATE_USER_RECENT),
      map((action: AuthActions.CreateUserRecent) => action.payload.updateData),
      switchMap((updateData: UpdateObjectModel) => {
        return this.domainObjectService.updateByMethod(updateData.objectType, updateData.methodName, updateData.objectData)
          .pipe(
            map((response: IResponseBase) => new AuthActions.CreateUserRecentComplete({ responseBase: response })
            )
          );
      }),
      catchError(() => of({
        type: AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
        payload: { errors: { 'key': '__Model', 'value': 'Failed to create user recent' } }
      }))
    );
  });
/****************************************************************************
DELETE USER RECENT
****************************************************************************/
  deleteUserRecent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AuthActions.DeleteUserRecent>(AuthActions.AuthActionTypes.DELETE_USER_RECENT),
      map((action: AuthActions.DeleteUserRecent) => action.payload.id),
      switchMap((id: number) => {
        return this.domainObjectService.deleteByMethodById('UserRecent', 'Delete', id)
          .pipe(
            map((response: IResponseBase) => new AuthActions.DeleteUserRecentComplete({ responseBase: response, id: id })
            )
          );
      }),
      catchError(() => of({
        type: AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
        payload: { errors: { 'key': '__Model', 'value': 'Failed to delete user recent' } }
      }))
    );
  });

/****************************************************************************
    Logout
****************************************************************************/
  authLogout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AuthActions.Logout>(AuthActions.AuthActionTypes.LOGOUT_ACTION),
      map((action: AuthActions.Logout) => {
        if (action && action.payload.initLogoutUserSessionEvent) {
          localStorage.removeItem(HomLocalStorage.HomUser);
          let url = `${this.baseUrl}/Account/LogOff`;
          return this.http.post(url, '', httpOptions).toPromise();
        } else {
          return of(true);
        }
      }),
      mergeMap(() => {
        return [
          { type: AuthActions.AuthActionTypes.LOGOUT_ROUTE_ACTION },
          { type: AuthActions.AuthActionTypes.SET_LOGOUT_ACTION }
        ];
      })
    );
  });

/****************************************************************************
    User is refreshing the page
****************************************************************************/
  userRefresh$ = createEffect(() => this.actions$.pipe(
      ofType<AuthActions.RefreshAction>(AuthActions.AuthActionTypes.REFRESH_ACTION),
    tap(() => {
      this.router.navigate(['refresh']);
    })
  ),
    { dispatch: false }
  );

/****************************************************************************
    Logout Route - no dispatch
****************************************************************************/
  logoutRoute$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.LogoutRoute>(AuthActions.AuthActionTypes.LOGOUT_ROUTE_ACTION),
    tap(() => {
      this.router.navigate(['signin']);
    })
  ),
    { dispatch: false }
  );

/****************************************************************************
  GET_USER_PREFERENCES Effect
****************************************************************************/
  getUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
    ofType<AuthActions.GetUserPreferences>(AuthActions.AuthActionTypes.GET_USER_PREFERENCES_ACTION),
    map((action: AuthActions.GetUserPreferences) => action.payload),
    mergeMap((payload) => {
      const providerUserId = payload.providerUserId;
      return this.domainObjectService.getByMethodById('UserPreference', 'Details', providerUserId)
        .pipe(
          map((response: IResponseBase) => new AuthActions.SetUserPreferences({ responseBase: response })
          )
        );
    }),
      catchError(() => of({
        type: AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
        payload: { errors: { 'key': '__Model', 'value': 'Failed to retrieve user preferences' } }
      }))
  )
  );

/****************************************************************************
   *UPDATE_USER_PREFERENCES
   * Call an object's custom update method in the API
   * @param objectType The object type whose controller implements the custom "update" method
   * @param methodName The name of the custom "update" method to call
   * @param objectData The updated object to submit as an HTTP payload
   * @param arrayName The name to give the array parameter, if domainObject is an array
****************************************************************************/
  updateUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
    ofType<AuthActions.UpdateUserPreferences>(AuthActions.AuthActionTypes.UPDATE_USER_PREFERENCES_ACTION),
    map((action: AuthActions.UpdateUserPreferences) => action.payload.updateData),
    switchMap((updateData: UpdateObjectModel) => {
      return this.domainObjectService.updateByMethod(updateData.objectType, updateData.methodName, updateData.objectData)
        .pipe(
          map((response: IResponseBase) => new AuthActions.UpdateUserPreferencesComplete({ responseBase: response })
          )
        );
    }),
      catchError(() => of({
        type: AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
        payload: { errors: { 'key': '__Model', 'value': 'Failed to update user preferences' } }
      }))
    )
  );

/****************************************************************************
  GET_TOP_BAR_COUNTS Effect
  for this provider user
****************************************************************************/

  getTopBarCounts$ = createEffect(() =>
    this.actions$.pipe(
        ofType<AuthActions.GetTopBarCounts>(AuthActions.AuthActionTypes.GET_TOP_BAR_COUNTS_ACTION),
        map((action: AuthActions.GetTopBarCounts) => action.payload),
        mergeMap((payload) => {
          return [
            { type: AuthActions.AuthActionTypes.GET_OPEN_NOTIFICATION_COUNT_ACTION, payload: payload },
            { type: AuthActions.AuthActionTypes.GET_PENDING_CHANGES_COUNT_ACTION, payload: payload }  
          ];
        }),
        //catchError(() => of({ type: AuthActions.SET_ERROR_RETURNED, payload: { errors:{ 'key': '__Model', 'value': 'Failed to retrieve top bar counts' } } }))
    )
  );

/****************************************************************************
  GET_OPEN_NOTIFICATION_COUNT Effect
  for this provider user
****************************************************************************/
  getOpenNotificationCount$ = createEffect(() =>
    this.actions$.pipe(
          ofType<AuthActions.GetOpenNotificationCount>(AuthActions.AuthActionTypes.GET_OPEN_NOTIFICATION_COUNT_ACTION),
           map((action: AuthActions.GetOpenNotificationCount) => action.payload),
          switchMap((payload) => {
          const providerUserId = payload.providerUserId;
          return this.domainObjectService.getListByMethod('Notification', 'GetOpenNotifications', providerUserId.toString())
          .pipe(
            map((response: IResponseBase) => new AuthActions.SetOpenNotificationCount({responseBase: response } )
            )
            );
          }),
      catchError(() => of({
        type: AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
        payload: { errors: { 'key': '__Model', 'value': 'Failed to retrieve open notifications count' } }
      }))
    )
    );

/****************************************************************************
  GET_PENDING_CHANGES_COUNT Effect
  for this provider user
****************************************************************************/
  getPendingChangesCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthActions.GetPendingChangesCount>(AuthActions.AuthActionTypes.GET_PENDING_CHANGES_COUNT_ACTION),
      map((action: AuthActions.GetPendingChangesCount) => action.payload),
      switchMap((payload) => {
        const providerUserId = payload.providerUserId;
        const listFilter: IListFilter = {
          isLookup: false,
          getCount: true,
          filterFor: '',
          filterContext: '',
          accessLevel: AccessLevel.ReadOnly,
          getAll: true,
          currentPage: 1,
          searchTerm: [
            { term: 'Status', value: 'Pending', searchType: SearchType.Equals, columnName: 'status', fieldType: 'string' },
            { term: 'expired', value: 'false', searchType: SearchType.Equals, columnName: 'expired', fieldType: 'bool' }
          ],
          orderTerm: null
        };
        return this.domainObjectService.getListByMethod('ApprovalQueueTransaction',
          'ByProviderUser',
          providerUserId.toString(),
          listFilter)
          .pipe(
            map((response: IResponseBase) => new AuthActions.SetPendingChangesCount({ responseBase: response })
            ));
      }),
      catchError(() =>
        of({
          type: AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
          payload: { errors: { 'key': '__Model', 'value': 'Failed to retrieve pending changes' } }
        }))
    )
  );;
  
/****************************************************************************
GET_MY_WIDGETS Effect to retrieve the widgets for the current user for the specificed portal
for this provider user
****************************************************************************/
  getMyWidgets$ = createEffect(() =>
    this.actions$.pipe(
    ofType<AuthActions.GetAllMyWidgets>(AuthActions.AuthActionTypes.GET_ALL_MY_WIDGETS_ACTION),
    mergeMap((action) => 
      this.domainObjectService.getListByMetaUrl( `${this.baseApiUrl}/WidgetManagerPortal/Index`).pipe(
        mergeMap((response: IResponseBase) => {
            return [ { type: AuthActions.AuthActionTypes.SET_ALL_MY_WIDGETS_ACTION, payload: {responseBase: response} } ]
          }
        ),
        catchError(() => 
          of({
            type: AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
            payload: { errors: { 'key': '__Model', 'value': `Failed to retrieve all my available widgets ` } }
          })
        )
    ))
    )
  );

/****************************************************************************
GET_PORTAL_TABS Effect to retrieve the tab information for the application
for the providerId associated to the user
****************************************************************************/
  getPortalTabs$ = createEffect(() =>
    this.actions$.pipe(
    ofType<AuthActions.GetPortalTabs>(AuthActions.AuthActionTypes.GET_PORTAL_TABS),
    map((action: AuthActions.GetPortalTabs) => action.payload),
    mergeMap((payload) => {
      const providerId = payload.providerId;
      return this.domainObjectService.getListByProvider('PortalTabContainer', providerId)
        .pipe(
          map((response: IResponseBase) => new AuthActions.SetPortalTabs({ responseBase: response })
          )
        );
    }),
      catchError(() => of({
        type: AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
        payload: { errors: { 'key': '__Model', 'value': 'Failed to retrieve portal tabs' } }
      }))
    )
  );

/****************************************************************************
GET_PARAMETER_SEARCH_TYPES Effect to retrieve the searchTypes
(startsWith, Equal To) that can be used for a parameter type (string)
****************************************************************************/
  getParameterSearchTypes = createEffect(() =>
    this.actions$.pipe(
    ofType<AuthActions.GetParameterSearchTypes>(AuthActions.AuthActionTypes.GET_PARAMETER_SEARCH_TYPES),
    map((action: AuthActions.GetParameterSearchTypes) => action.payload),
    mergeMap((payload) => {
      const providerId = payload.providerId;
      const listFilter = cloneDeep(this.defaultListFilter);
      listFilter.isLookup = true;
      listFilter.getAll = true;
      return this.domainObjectService.getListByProvider('ParameterSearchType', providerId, listFilter)
        .pipe(
          map((response: IResponseBase) => new AuthActions.SetParameterSearchTypes({ responseBase: response })
          )
        );
    }),
      catchError(() => of({
        type: AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
        payload: { errors: { 'key': '__Model', 'value': 'Failed to retrieve parameter search types' } }
      }))
    )
  );

  /****************************************************************************
  GET_LIST_COMPONENTS Effect retrieves the list component to store xref table
  ****************************************************************************/
  getListComponents = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthActions.GetListComponents>(AuthActions.AuthActionTypes.GET_LIST_COMPONENTS),
      mergeMap((action) => {
        const listFilter = cloneDeep(this.defaultListFilter);
        listFilter.isLookup = true;
        listFilter.getAll = true;
        return this.domainObjectService.getListByMethod('ListComponent', 'Index', null, listFilter)
          .pipe(
            map((response: IResponseBase) => new AuthActions.SetListComponents({ responseBase: response })
            )
          );
      }),
      catchError(() => of({
        type: AuthActions.AuthActionTypes.SET_ERROR_RETURNED_ACTION,
        payload: { errors: { 'key': '__Model', 'value': 'Failed to retrieve list components' } }
      }))
    )
  );

/*********************************************************************************
GET_USER_LIST_FILTERS Effect retrieves all user list filters for all list components
--no dispatch action on this one
**************************************************************************************/
  getuserListFilters = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthActions.GetUserListFilters>(AuthActions.AuthActionTypes.GET_USER_LIST_FILTERS),
      tap(() => {
        this.dynamicListService.dispatchUserListFiltersGet();
      })
    ),
    { dispatch: false }
  );
}
