import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IProviderUser, ProviderUser, ILdapUser } from '../../../view-models';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { IAllDynamicData, getSelectedRecord, getSelectedParentListDefinition, CreateObjectList, UpdateObjectCustomList } from '../../../../../fw/dynamic-list/store';
import { UpdateObjectCustomModel, CreateObjectModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { UserPriviledgesService } from '../../../../../auth/services';
//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'user-security',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-security.component.html'
})
export class UserSecurityComponent implements OnInit, OnDestroy {

  public displayFields: string[] = ['newAdUsers', 'isLdapUser', 'firstName', 'middleName', 'lastName', 'membershipUserName', 'supervisor', 'phoneNumber', 'extension', 'emailAddress'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public objectData: IProviderUser;
  public ldapUsers: ILdapUser[] = [];
  public supervisors: IProviderUser[] = [];
  public form$: BehaviorSubject<FormGroup> = new BehaviorSubject(null);

  providerUserId: number;
  myStoreName: string;
  key: string;
  operation: string;
  controllerName: string;
  requestor: string = 'user-security';
  isLdapUser: boolean = false;
  isExternalUser: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public userPriviledgesService: UserPriviledgesService,
    public mds: MetaDataService,
   @Inject(appConstants) public myConstants: IAppConstants
   ) { }

  public onCancel() {
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public onCreate() {
    const data = this.form$.value.getRawValue();
    let row: IProviderUser = this.setCommonProperties(data);
    row.providerId = this.userPriviledgesService.providerId$.value;
    row.isLdapUser = this.isLdapUser;
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(this.myStoreName, -1, this.controllerName, 'Create', row, null, emitter);
    this.store.dispatch(new CreateObjectList({ createData }));

  }

  public onSave(action: string) {
    const data = this.form$.value.getRawValue();
    let row: IProviderUser = this.setCommonProperties(data);

    const keyData: IKey = { storeName: this.myStoreName, parentId: -1, key: this.key, id: this.providerUserId }
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventUpdate, action: action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', row, null, emitter);
    this.store.dispatch(new UpdateObjectCustomList({ updateData }));
 }


  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.objectData = null;
      this.form$.next(null);
      this.detailConfig$.next(null);
      this.providerUserId = +paramMap.get('id');
      this.key = paramMap.get('key');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.mds.setFieldDefinitions(this.myStoreName);
      this.getDetail();
      this.setDetailConfig(paramMap);
    }));

    this.subscription.add(this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null))
      .subscribe((obj: IValueChanged) => {
        this.handleValueChanged(obj);
      }));


    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('ldapUser')))
      .subscribe((data) => {
        this.ldapUsers = data;
      }));

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('providerUser')))
      .subscribe((data) => {
        this.supervisors = data;
      }));

    
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.wrapsForm = true;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  //parent id is installerId for this store
  getDetail() {
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(this.myStoreName, -1, this.key, this.providerUserId)))
      .subscribe( (entity: IProviderUser) => {
        const newRequest: boolean = !this.objectData;
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate ? new ProviderUser() : entity;
        if (newRequest) {
          this.isLdapUser = this.operation === this.myConstants.operationTypeCreate ? false : entity.isLdapUser;
          this.isExternalUser = this.operation === this.myConstants.operationTypeCreate ? false : entity.contact_contactId !== null;
          this.mds.loadSelectionLists(this.displayFields, this.operation, this.providerUserId);
          this.form$.next( this.mds.loadDynamicFormGroup(this.displayFields, this.objectData, this.operation) );
        }
     })
    );

    this.subscription.add(this.store
      .pipe(select(getSelectedParentListDefinition(this.myStoreName, -1)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.controllerName = listDefinition.controllerName;
      }
      ));
  }

  handleValueChanged(obj: IValueChanged) {
    switch (obj.key) {
      case 'newAdUsers':
        if (!obj.value) {
          break;
        }
        const user: ILdapUser = obj.value;
        this.isLdapUser = true;
        let providerUser = cloneDeep(this.objectData);
        providerUser.firstName = user.firstName;
        providerUser.lastName = user.lastName;
        providerUser.middleName = '';
        providerUser.membershipUserName = user.userName;
        providerUser.phoneNumber = user.phoneNumber.replace('+1', '').replace('.', '').trim();
        providerUser.extension = user.extension;
        providerUser.emailAddress = user.email;
        this.objectData = providerUser;
        this.form$.next(this.mds.loadDynamicFormGroup(this.displayFields, this.objectData, this.operation));
        this.form$.value.markAsDirty();
     break;
      default:
        break;
    }
  }

  setCommonProperties(formData: IProviderUser) {
    let row: IProviderUser = cloneDeep(this.objectData);
    row.firstName = formData.firstName;
    row.middleName = formData.middleName;
    row.lastName = formData.lastName;
    row.isLdapUser = formData.isLdapUser;
    row.firstName = formData.firstName;
    row.firstName = formData.firstName;
    row.emailAddress = formData.emailAddress;
    row.phoneNumber = formData.phoneNumber;
    row.membershipUserName = formData.membershipUserName;
    row.supervisor_providerUserId = formData.hasOwnProperty('supervisor') && formData['supervisor'] ? formData['supervisor']['providerUserId'] : null;
    row.extension = formData.extension;
    row.isSystemAdmin = false;

    return row;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
