<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <div [ngClass]="{ 'loading-mask': loading$ | async }">
    <form *ngIf="lockedDayForm" [formGroup]="lockedDayForm" novalidate>
      <fw-dynamic-field [field]="mds.getFieldDefinition('day')"
                        [form]="lockedDayForm"
                        [operation]="operation">
      </fw-dynamic-field>
      <div class="lock-day-field">
        <fw-dynamic-field [field]="mds.getFieldDefinition('generalContractor')"
                          [form]="lockedDayForm"
                          [operation]="operation"
                          [selectItems]="generalContractors">
        </fw-dynamic-field>
      </div>
      <div class="lock-day-field">
        <fw-dynamic-field [field]="mds.getFieldDefinition('providerLocationName')"
                          [form]="lockedDayForm"
                          [selectItems]="providerLocations"
                          [operation]="operation">
        </fw-dynamic-field>
      </div>
      <div class="lock-day-field">
        <label for="servicesMultiSelect">
          Services
        </label>
        <div *ngIf="multiSelectOptions && multiSelectSettings">
          <fw-multiselect-dropdown #serviceMultiSelect [options]="multiSelectOptions"
                                   [texts]="multiSelectLabels"
                                   [settings]="multiSelectSettings"
                                   (onAdded)="addService($event)"
                                   [isDisabled]="!(plSelected$ | async)"
                                   (onRemoved)="removeService($event)"
                                   required>
          </fw-multiselect-dropdown>
        </div>
      </div>
    </form>
    <fw-error-box [errorData]="errorData$ | async"></fw-error-box>
    <fw-crud-buttons [operation]="operation"
                     [disabled]="!formValid()"
                     [showNext]="false"
                     (cancelEvent)="onCancel($event)"
                     (createEvent)="onCreate($event)">
    </fw-crud-buttons>
  </div>
</fw-dynamic-detail-container>
