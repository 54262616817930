import { Component, Input, EventEmitter, Output} from '@angular/core';
import {  DecimalPipe } from '@angular/common';

import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { IDashboardCount } from '../../interfaces';

@Component({
  selector: 'dashboard-count',
  templateUrl: './dashboard-count.component.html',
})
export class DashboardCountComponent  {
  @Input() dashCount: IDashboardCount;

  @Output() public clickEvent = new EventEmitter<IHomEventEmitter>();

  constructor(public readonly decimalPipe: DecimalPipe) {}

  openLink(): void {
    const data: IDashboardCount = Object.assign({}, this.dashCount);
    let clickEvent: IHomEventEmitter = { requestor: 'dash-count', event: 'clickEvent', action: '', data: data };
    this.clickEvent.emit(clickEvent);
  }

}
