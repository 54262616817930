import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges  } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { UserDashboardService } from '../../services/user-dashboard.service';

@Component({
  selector: 'sss-options-presented',
  templateUrl: './sss-options-presented.component.html'
})
export class SssOptionsPresentedComponent implements OnInit, OnChanges, OnDestroy {
  @Input() workOrderId: number;

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  subscription: Subscription = new Subscription();

  constructor(
    public readonly userDashboardService: UserDashboardService) {
  }

  ngOnInit() {
    this.setListDefinition();
  }

  setListDefinition(): void {
    this.listDefinition$.next(this.userDashboardService.loadSssOptionsPresentedListDefinition(this.workOrderId));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['workOrderId'] && !changes['workOrderId'].isFirstChange()) {
      this.setListDefinition();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
