import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[decimalMask]'
})
export class DecimalMaskDirective {
  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(val) {
    this.onChange(val.toString());
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e) {
    if ((e.which < 48 || e.which > 57) && !(this.isAllowed)) {
      e.preventDefault();
    } 
  }

  //non-numerics in exclusionary range that are allowed
  //delete, backspace, decimal, comma, numeric keypad
  isAllowed(val: number) {
    return val === 8 || val === 46 || val === 57 || val === 110 || val === 188 || (val > 95 && val <105);
  }

  onChange(event) {
    // remove all mask characters (commas, decimals) (keep only numeric), then reformat
    let parts: string[] = event.split('.');
    let strVal = event.replace(/\D/g, ''); //also gets rid of .00
    if (parts.length === 1) {
      strVal = strVal.concat('00');
    } else if (parts[1].length === 1) {
      strVal = strVal.concat('0');
    }
    strVal = strVal === '' ? '0' : strVal;
    const newVal: number = parseInt(strVal.replace(/[^0-9]/g, '')) / 100;
    this.ngControl.valueAccessor.writeValue(newVal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: "decimal", useGrouping: true }));
  }

}
    //pattern = "^\d{0,3}(\,\d{3})+?\.[0-9]{2}?"
