<div class="note-recip">
  <form *ngIf="multiSelectOptions" [formGroup]="sendForm" novalidate>
    <div class="form-group">
      <label>
        <span class="note-recip--icon"><i class="fas fa-paper-plane"></i></span>Recipients
      </label>
      <div *ngIf="multiSelectOptions && multiSelectSettings">
        <!--ensure have all value fields fully defined before triggering constructor of component-->
        <fw-multiselect-dropdown #puMultiSelect
                                 class="note-recip__dropdown"
                                 [options]="multiSelectOptions"
                                 [formControl]="sendForm.controls['providerUserMultiSelect']"
                                 [texts]="multiSelectLabels"
                                 [settings]="multiSelectSettings"
                                 [isDisabled]="isDisabled"
                                 (onAdded)="addSendToEvent($event)"
                                 (onRemoved)="removeSendToEvent($event)"
                                 required>
        </fw-multiselect-dropdown>
      </div>
    </div>
  </form>
</div>
<div *ngIf="sendToList.length" class="note-recip__list">
  <ul *ngFor="let user of sendToList">
    <li class="note-recip__list--item">
      <button type="button" class="app-btn-icon" [ngClass]="{'app-icon-neutral': !user.sendEmail, 'app-icon--success': user.sendEmail }"
              (click)="onMailBox(user.providerUser.providerUserId)" [disabled]="!user.providerUser.emailAddress">
        <span *ngIf="user.sendEmail" title="Email will be sent"><i class="fas fa-envelope fa-sm"></i></span>
        <span *ngIf="!user.sendEmail && user.providerUser.emailAddress" title="Click to also send an email"><i class="far fa-envelope-open fa-sm"></i></span>
        <span *ngIf="!user.sendEmail  && !user.providerUser.emailAddress" title="An email address does not exist for this user"><i class="fas fa-ban fa-sm"></i></span>
      </button>{{user.providerUser.entityLabel}}
    </li>
  </ul>
</div>
