import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'communication-event-types',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './communication-event-types.component.html'
})
export class CommunicationEventTypesComponent implements OnInit {

  public listDefinition: IListDefinition;

  constructor(
    public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService  ) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadCommunicationEventTypesListDefinition();
 }


}
