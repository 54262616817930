import { Component, Input,  Inject, OnInit,  OnChanges, SimpleChanges} from '@angular/core';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IListDefinition, IListScreenSize,  IDynamicListRow, IListShowDetail} from '../../interfaces/index';

//services
import { DynamicListService } from '../../services/dynamic-list.service';
import { DynamicListEvent } from '../../enums/dynamic-list.enum';
import { HomDataUtility } from '../../../../shared/services';

@Component({
  selector: 'fw-dynamic-list-nav-data-row',
  templateUrl: './dynamic-list-nav-data-row.component.html'
})
export class DynamicListNavDataRowComponent implements OnInit, OnChanges {
  @Input() index: number;
  @Input() row: any;
  @Input() listDefinition: IListDefinition;
  @Input() screenSize: IListScreenSize;
  @Input() portalEntityId: number;
  @Input() activeIndex: number;
  @Input() activeOptionIndex: number;
  @Input() activeDeleteId: number;
  @Input() detailError: boolean;
  @Input() navClosed: boolean;
  @Input() operation: string;

  public rowDef: IDynamicListRow;

  constructor(
    public  dynamicListService: DynamicListService,
    public emitterService: HomEventEmitterService,
    public dataUtils: HomDataUtility,
    @Inject(appConstants) public  myConstants: IAppConstants) {
  }

  public showDetail() {
    let data: IListShowDetail = { id: +this.row[this.listDefinition.rowKeyId], rowIndex: this.index, operation: this.operation }
    let btnEvent: IHomEventEmitter = { requestor: 'dynamic-list-nav-data-row', event: DynamicListEvent.showDetail, action: '', data: data };
    this.emitterService.emitListEvent(btnEvent);
  }

  ngOnInit(): void {
    this.setRowData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['row'] && !changes['row'].firstChange) ||
      (changes['listDefinition'] && !changes['listDefinition'].firstChange) ||
      (changes['activeIndex'] && !changes['activeIndex'].firstChange) ||
      (changes['detailError'] && !changes['detailError'].firstChange) ||
      (changes['navClosed'] && !changes['navClosed'].firstChange)  ||
      (changes['screenSize'] && !changes['screenSize'].firstChange)) {
      this.setRowData();
    }
  }

  setRowData(): void {
   let rowDef: IDynamicListRow = {
      index: this.index,
      rowClass: this.dynamicListService.rowClass(this.listDefinition, this.row, this.activeDeleteId, this.index, this.portalEntityId, false, this.activeOptionIndex, this.navClosed, -1),
      entityStatus: this.dynamicListService.getEntityStatus(this.row, 'Column'),
      hasPending: this.dynamicListService.hasPending(this.row),
      selectDisabled: false, //n/a
      optionDisabled: false //n/a
    };
    this.rowDef = rowDef;
  }
}

