  <div [ngClass]="{ 'loading-mask': working$ | async }">
    <div *ngIf="listDefinition$ | async">
      <fw-dynamic-list [vmListDefinition]="listDefinition$ | async" (customEvent)="onCustom($event)" (selectEvent)="onSelect($event)"></fw-dynamic-list>
      <div class="app-btn-box">
        <button class="app-btn app-btn--primary"
                type="button"
                (click)="closePOs()"
                [disabled]="!selectedPos.length">
          Close Selected
        </button>
        <button *ngIf="userPriviledgesService.canBypassExternalPriceCheck$ | async"
                class="app-btn app-btn--primary" type="button"
                (click)="closePOs(true)"
                [disabled]="!selectedPos.length">
          Close And Bypass Price Check
        </button>
      </div>

      <fw-error-box *ngIf="errorData && errorData.length" [errorData]="errorData" [joinErrors]="false"></fw-error-box> <!--accounting custom errors-->
    </div>
  </div>
