export interface ILookupType {
  lookupTypeId: number;
  provider_providerId: number;
  lookupTypeName: string;
  lookupTypeDescription: string;
  locked: boolean;
}

export class LookupType implements ILookupType {
  lookupTypeId: number;
  provider_providerId: number;
  lookupTypeName: string;
  lookupTypeDescription: string;
  locked: boolean;

  constructor() {
    this.lookupTypeId = 0;
    this.provider_providerId = 0;
    this.lookupTypeName = '';
    this.lookupTypeDescription = '';
    this.locked = false;
  }

}
