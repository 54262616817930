  <div [ngClass]="{ 'loading-mask': loading$ | async }">
    <div *ngIf="purchaseOrder" class="po-measures">
      <div *ngIf="!(usingDefault$ | async)" class="hd-measures">
        <home-depot-measures [externalData]="externalData$ | async"
                             [canEdit]="canEdit"
                             [loading]="loading$ | async"
                             (viewMeasure)="onView($event)"
                             (useDefault)="onUseDefault($event)"
                             (selectEvent)="onSelected($event)"
                             (deleteEvent)="onDeleted($event)">
        </home-depot-measures>

      </div>
    </div>

  <fw-error-box [errorData]="errorData$ | async"  [ignoreKey]="true"></fw-error-box>
</div>
