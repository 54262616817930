<div class="dl-overflow-cast" *ngIf="isVisible" (click)="toggleOverflow($event)"></div>

<ng-template #overflowBtns>
  <div class="dl-overflow__box">
    <ng-container *ngFor="let btn of btns; let i = index">
      <div class="dl-overflow-item" [ngClass]="{ 'dl-overflow-item--disabled': btn.disabled }"
           (click)="!btn.disabled ? onClick(btn.definition) : return">
        <div class="dl-overflow-item__btn">
          <button *ngIf="!btn.definition.isDownload"
                  class="{{ btn.css }}"
                  [disabled]="btn.disabled"
                  title="{{btn.definition.title}}"
                  (click)="onClick(btn.definition)">
            <i class="{{btn.definition.icon}}"></i>
          </button>
          <a *ngIf="btn.definition.isDownload"
             download="{{row['fileName']}}"
             title="{{btn.definition.title}}"
             href="{{row['fileUrl']}}"
             class="app-btn-icon  dynamic-list__item--actions-icon {{ btn.css }}"
             [disabled]="btn.disabled">
            <i class="{{btn.definition.icon}}"></i>
          </a>
        </div>
        <div class="dl-overflow-item__label" [title]="btn.definition.title">{{ btn.definition.title }}</div>
      </div>
    </ng-container>
    <div *ngIf="showInfo" class="dl-overflow-item dl-overflow-item--info">
      <div class="dl-overflow-item__btn">
        <dynamic-list-data-row-info [row]="row"></dynamic-list-data-row-info>
      </div>
      <div class="dl-overflow-item__label" title="Row Information">Row Information</div>
    </div>
  </div>
</ng-template>

<button type="button"  
        class="app-btn-icon app-btn-icon--primary  dynamic-list__item--actions-icon"
        [disabled]="listDisabled"
        fw-popover [template]="overflowBtns" [showPopover]="isVisible" [closeOnClickOutside]="true"
        title="More..."
        (click)="toggleOverflow($event)">
  <i class="fad fa-ellipsis-h"></i>
</button>
