import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomPhonePipe } from 'hom-lib/hom-pipes';

import { CommunicationEventFor, CommunicationEventEvent } from '../enums/communication-event.enums';
import {
  IContact,
  IScheduleInstallerWorkCrewUnavailabilityViewModel,
  IScheduleInstallerWorkCrewAvailabilityViewModel,
  ISchedule,  IInstallerWorkCrewTechnician
} from '../../view-models';
import { IAppConstants, appConstants } from '../../../../shared/constants';
import { IConversationRequest } from '../interfaces/i-conversation-request';

import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';

@Injectable({
  providedIn: 'root'
})
export class CommunicationEventService  {
  displayTypeValue: string = 'value';
  displayTypeIcon: string = 'icon';
  displayTypeBoth: string = 'both';

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public modalService: ModalService,
    public phonePipe: HomPhonePipe,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants  ) { }

  requestCommunication(type: string, communicationTo: string,
    data: IContact | IScheduleInstallerWorkCrewAvailabilityViewModel | IScheduleInstallerWorkCrewUnavailabilityViewModel | ISchedule = null): void {
    switch (type) {
      case CommunicationEventFor.phone:
        alert('This functionality is not implemented yet. ');
        break;
      case CommunicationEventFor.texting:
          if (!data) {
            alert('Launching chat without a contact is not yet implemented.  Missing contact.');
          } else {
            this.launchSmsConversation(communicationTo, data)
          }
        break;
      case CommunicationEventFor.email:
        this.mailTo(communicationTo);
        break;
      default:
    }
  }

  //launch the conversation modal for this one
  //strongly typing to explicitly note what models support this functionality
  launchSmsConversation(toPhone: string,
    data: IContact | IInstallerWorkCrewTechnician | IScheduleInstallerWorkCrewAvailabilityViewModel | IScheduleInstallerWorkCrewUnavailabilityViewModel | ISchedule): void {
    const toName = data['installerWorkCrewLeadName'] ? data['installerWorkCrewLeadName']
      : data['contactName'] ? data['contactName']
        : data['firstName'] ? data['firstName'].concat(' ', data['lastName'])
          : data['entityLabel'];
    const contactId: number = data.hasOwnProperty('contactId') ? data['contactId']
      : data.hasOwnProperty('contact_contactId') ? data['contact_contactId'] : 0;
    const parentName: string = data['installerName'] || ''; //if working with tech, need installer name
    const request: IConversationRequest = { contactId: contactId, phone: toPhone, name: toName, parentName: parentName }
    let emitter: IHomEventEmitter = {
      requestor: 'communication-event',
      event: CommunicationEventEvent.openConversation, action: '', data: request
    };
    this.emitterService.emitSmsEvent(emitter);
  }
  mailTo(emailAddress: string) {
    window.location.href = ('mailTo:'.concat(emailAddress) + '?subject=HOM Solutions Customer Service');
  }

}
