/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';

import { IDashboardCount } from '../../../portal-shared/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { filter, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { ChargeBackStore, ChargeBackCountType, ChargeBackCountTabLabel, ChargeBacksEvent, ChargeBackSummaryType } from '../../enums/charge-back-dashboard.enums';
import { IProviderUserCountViewModel } from '../../../portal-shared/interfaces/i-provider-user-count-view-model';
import { IErrorData } from '../../../../../shared/interfaces';
import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IChargeBack } from '../../../view-models';

import { ChargeBackDashboardService } from '../../services/charge-back-dashboard.service';
import { DomainObjectService } from '../../../../../shared/services';
import { UserPriviledgesService } from '../../../../../auth/services';
import { SharedDashboardService } from '../../../portal-shared/services/shared-dashboard.service';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';

@Component({
  selector: 'accounting-charge-backs',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './accounting-charge-backs.component.html'
})
export class AccountingChargeBacksComponent implements OnInit {
  public portalId: number = 11; //manager portal
  public title: string;
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public dashCounts$: BehaviorSubject<IDashboardCount[]> = new BehaviorSubject(null);
  public loading$: Observable<boolean> = of(true);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errorMessage: string = '';
  subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public domainObjectService: DomainObjectService,
    public userPriviledgesService: UserPriviledgesService,
    public chargeBacksService: ChargeBackDashboardService,
    public sharedDashboardService: SharedDashboardService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants)
  {}

  ngOnInit() {
    const dashStores: string[] = Object.keys(ChargeBackStore);

    this.loading$ = this.rootStore.select('loadingIndicator').pipe(
      filter(x => dashStores.includes(x.requestor)),
      map(x => x.show));

    this.subscription.add(this.store.pipe(select(fromStore.getListByType(ChargeBackStore.dashChargeBacks)))
      .subscribe((data) => {
        if (data.objData && data.objData.length && data.objData[0].event) {
          if (data.objData[0].event.event == this.myConstants.emitterEventListReloadCustom && data.objData[0].errorData !== "" && !data.objData[0].errorData.length) {
            this.reloadCurrentList();
            this.loadCounts();
          }
        }
      }));

    this.activeRoute.paramMap.subscribe(() => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case ChargeBacksEvent.printChargeBack:
        this.showInstallerChargeBackNotice(event.data);
        break;
      case ChargeBacksEvent.submitChargeBack:
        this.showChargeBackInstallmentDetail(event.data);
        break;
      default:
        break;
    }
  }

  public countClicked(event: IHomEventEmitter): void {
    const dashboardCount: IDashboardCount = event.data;
    this.setListByType(dashboardCount.type);
    this.loadCounts();
  }

  public refresh(event: IHomEventEmitter): void {
    if (event.event === ButtonType.reload) {
      this.reloadCurrentList();
      this.loadCounts();
    }
  }

  newRequest() {
    this.setListByType(ChargeBackCountType.unSubmittedNotRefrain);

    if (this.chargeBacksService.lastDashCounts && this.chargeBacksService.lastDashCounts.length > 0) {
      this.dashCounts$.next(this.chargeBacksService.lastDashCounts);
    } else {
      this.setDashCountDefinitions();
      this.loadCounts();
    }
  }

  setListByType(countType: string): void {
    const storeName: string = this.setStoreByType(countType);
    this.title = this.chargeBacksService.setTitleByType(storeName);
    this.listDefinition$.next(null);
    this.listDefinition$.next(this.chargeBacksService.loadDashChargeBackSummaryListDefinition(storeName));
  }

  setDashCountDefinitions(): void {
    const dashboard: string = 'Chargeback Counts';

    const dashCounts: IDashboardCount[] = [
      {
        //original/default pull
        type: ChargeBackCountType.unSubmittedNotRefrain, label: ChargeBackCountTabLabel.unSubmittedNotRefrain, value: -1,
        fromPortalLabel: dashboard, fromPortalId: this.portalId,
        fromComponentName: 'AccountingChargeBacksComponent'
      },
      {
        type: ChargeBackCountType.unAckChargeBacks, label: ChargeBackCountTabLabel.unAckChargeBacks, value: -1,
        fromPortalLabel: dashboard, fromPortalId: this.portalId,
        fromComponentName: 'AccountingChargeBacksComponent'
      },
      {
        type: ChargeBackCountType.ackUnSubmitChargeBacks, label: ChargeBackCountTabLabel.ackUnSubmitChargeBacks, value: -1,
        fromPortalLabel: dashboard, fromPortalId: this.portalId,
        fromComponentName: 'AccountingChargeBacksComponent'
      },
      {
        type: ChargeBackCountType.onHoldChargeBacks, label: ChargeBackCountTabLabel.onHoldChargeBacks, value: -1,
        fromPortalLabel: dashboard, fromPortalId: this.portalId,
        fromComponentName: 'AccountingChargeBacksComponent'
      }
    ];

    this.dashCounts$.next(dashCounts);
  }

  getCountByType(countType: IDashboardCount): void {
    const params = this.userPriviledgesService.currentUserId$.value + '?type=' + countType.type;
    this.subscription.add(this.domainObjectService.getObjectByMethod('ProviderUserCountViewModel', 'GetChargeBackCountByType', params)
      .subscribe(result => {
        if (result.success) {
          const data = <IProviderUserCountViewModel>result.data;
          const dashCounts: IDashboardCount[] = this.dashCounts$.getValue();
          let idx: number = dashCounts.findIndex(x => x.type === countType.type);
          if (idx > -1) {
            dashCounts[idx].value = data.dataCount;
          }
          this.dashCounts$.next(dashCounts);
          this.chargeBacksService.lastDashCounts = dashCounts;
        } else {
          this.errorData$.next(result.errorData);
        }
      }, error => this.errorMessage = <any>error)
    );
  }

  loadCounts(): void {
    const dashCounts = this.dashCounts$.getValue();

    dashCounts.forEach(type => {
      this.getCountByType(type);
    })
  }

  reloadCurrentList(): void {
    const currentDef: IListDefinition = this.listDefinition$.value;
    if (currentDef) {
      const emitter: IHomEventEmitter = { requestor: 'accounting-charge-backs', event: this.myConstants.emitterEventListReload, action: '', data: null };
      this.store.dispatch(new fromStore.SetEventList({ storeName: currentDef.storeName, parentId: -1, event: emitter }))
    }
  }

  setStoreByType(countType: string): string {
    switch (countType) {
      case ChargeBackCountType.unSubmittedNotRefrain:
        return ChargeBackStore.dashChargeBacks;
      case ChargeBackCountType.ackUnSubmitChargeBacks:
        return ChargeBackStore.dashAckUnSubmitChargeBacks;
      case ChargeBackCountType.onHoldChargeBacks:
        return ChargeBackStore.dashOnHoldChargeBacks;
      case ChargeBackCountType.unAckChargeBacks:
        return ChargeBackStore.dashUnAckChargeBacks;
      default:
        return ChargeBackStore.dashChargeBacks;
    }
  }

  showInstallerChargeBackNotice(row: IChargeBack): void {
    this.sharedDashboardService.printDoc(row, 'chargeBackNoticeUrl');
  }

  showChargeBackInstallmentDetail(data: IChargeBack): void {
    const def: IListDefinition = cloneDeep(this.listDefinition$.getValue());
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: def.storeName,
      key: def.rowKeyId,
      operation: data.submitted ? this.myConstants.operationTypeDetails : this.myConstants.operationTypeEdit,
      showNext: !data.submitted,
      showPrev: !data.submitted,
      requestTime: new Date(),
      portalEntityId: data.project_projectId //to show wos by project id
    }

    this.modalService.open({
      title: 'Payment Details for Charge Back'.concat(' ', data.chargeBackId.toString()),
      path: 'mgr-dash-charge-back-installments',
      id: data.chargeBackId,
      sizeType: ModalSizeType.large,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }
}
