import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { BehaviorSubject } from 'rxjs';
import { IHistoryTypeViewModel } from '../../../view-models';
import { IListDefinition, ISearchTerm } from '../../../../../fw/dynamic-list/interfaces';
import { ProjectService } from '../../services';

//store actions and reducers
import * as fromRoot from '../../../../store/reducers/index';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import { ISelectListData } from '../../../../../shared/store/selectionLists/index';

@Component({
  selector: 'project-history',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project-history.component.html'
})
export class ProjectHistoryComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public entities$: BehaviorSubject<IHistoryTypeViewModel[]> = new BehaviorSubject(null);
  projectId: number;
  subscription: Subscription = new Subscription();

  constructor(
    public  activeRoute: ActivatedRoute,
    public  rootStore: Store<fromRoot.IState>,
    public  projectService: ProjectService) {
  }

  public onFilterChange(e: IHomEventEmitter): void {
    if (e.action === 'historyEntity' && e.data && e.data.hasOwnProperty('searchTerm')) {
      const searchTerm: ISearchTerm = e.data['searchTerm'];
      this.setHistorySubTypes(searchTerm.value[0]);
    }
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get('id');
      this.newRequest();
    });
  }

  newRequest(): void {
    this.getHistoryTypes();
    this.setListDefinition();

    this.subscription.add(this.rootStore.select(fromSelectionLists.getSelectionListByTypeByParent('historyEntity', -1))
      .subscribe((objData: ISelectListData) => {
        if (objData && objData.data) {
          const data: IHistoryTypeViewModel[] = cloneDeep(objData.data);
          this.entities$.next(data);
        }
      })
    );
  }

  setListDefinition(): void {
    let listDefinition = this.projectService.loadProjectHistoryListDefinition(this.projectId);
    this.listDefinition$.next(listDefinition);
  }

  getHistoryTypes(): void {
      this.rootStore.dispatch(new fromSelectionLists.GetEntityListByMethodParams(
           'HistoryTypeViewModel',
           'ByAggRoot',
           '?id=' + this.projectId.toString() + '&entityName=Project',
            null,
           'historyEntity'
        ));
  }

  setHistorySubTypes(type: string): void {
    const historyEntities = this.entities$.getValue();
    let obj: any[] = [];
    if (type) {
      const subType = historyEntities.find(e => e.entityName === type);
      if (subType) {
        subType.entityList.forEach(d => obj.push({ pkId: d.pkid, label: d.label }))
      }
    }

    //will have an array of Pkid, EntityName, Label - display Label, with id of PkId
    this.rootStore.dispatch(new fromSelectionLists.SetEntityList({
      storeName: 'historyEntityType',
      parentId: -1,
      data: obj,
      listMetaData: historyEntities[0]['metaData'],
      errorData: null
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
