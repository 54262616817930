export interface IWorkCategory {
  workCategoryId: number;
  provider_providerId: number;
  workCategoryName: string;
  workCategoryDescription: string;
  unitMeasure_unitMeasureId: number;
  minimumPercentMetForSelfService: number;
  determinesJobLength: boolean;
}

export class WorkCategory implements IWorkCategory {
  workCategoryId: number;
  provider_providerId: number;
  workCategoryName: string;
  workCategoryDescription: string;
  unitMeasure_unitMeasureId: number;
  minimumPercentMetForSelfService: number;
  determinesJobLength: boolean;

  constructor() {
    this.workCategoryId = 0;
    this.provider_providerId = 0;
    this.workCategoryName = '';
    this.workCategoryDescription = '';
    this.unitMeasure_unitMeasureId = 0;
    this.minimumPercentMetForSelfService = 0;
    this.determinesJobLength = false;
  }

}
