export interface IProviderLocationShipperInventorySetup {
  providerLocationShipperInventorySetupId: number;   
  providerLocation_providerLocationId: number;
  shipperDesignation: string;
  shipper_shipperId : number;
  leadTimeDays : number;
  shipperWarehouseDesignation : string;
  timeCutoff : string;
  deliverySchedule_deliveryScheduleId: number;
  entityLabel: string;

  //extra data for job schedule
  jobScheduleId: number;
  providerId: number;
  jobScheduleSummary: string;
  jobScheduleName: string;
  isActive: boolean;
  allDayEvent: boolean;
  jobScheduleType: string;
  jobScheduleRecurrenceType: string;
  jobFrequencyType: string;
  jobFrequencyRecurUnit1: number;
  jobFrequencyRecurUnit2: number;
  jobFrequencyRecurUnit3: number;
  dailyFrequencyTime: string;
  dailyFrequencyUnit: number;
  dailyFrequencyStartTime: string;
  dailyFrequencyEndTime: string;
  jobStartDate: string;
  jobEndDate: string;
  jobScheduleWeekdays: number[];
}

export class ProviderLocationShipperInventorySetup implements IProviderLocationShipperInventorySetup {
  providerLocationShipperInventorySetupId: number;
  providerLocation_providerLocationId: number;
  shipperDesignation: string;
  shipper_shipperId: number;
  leadTimeDays: number;
  shipperWarehouseDesignation: string;
  timeCutoff: string;
  deliverySchedule_deliveryScheduleId: number;
  entityLabel: string;

  //extra data for job schedule
  jobScheduleId: number;
  providerId: number;
  jobScheduleSummary: string;
  jobScheduleName: string;
  isActive: boolean;
  allDayEvent: boolean;
  jobScheduleType: string;
  jobScheduleRecurrenceType: string;
  jobFrequencyType: string;
  jobFrequencyRecurUnit1: number;
  jobFrequencyRecurUnit2: number;
  jobFrequencyRecurUnit3: number;
  dailyFrequencyTime: string;
  dailyFrequencyUnit: number;
  dailyFrequencyStartTime: string;
  dailyFrequencyEndTime: string;
  jobStartDate: string;
  jobEndDate: string;
  jobScheduleWeekdays: number[];

  constructor() {
    this.providerLocationShipperInventorySetupId = 0;
    this.shipper_shipperId = null;
    this.providerLocation_providerLocationId = null;
    this.shipperDesignation = '';
    this.leadTimeDays = 0;
    this.shipperWarehouseDesignation = '';
    this.timeCutoff = '';
    this.deliverySchedule_deliveryScheduleId = null;

    //extra data fields for job schedule need inited too
    this.jobScheduleId = 0;
    this.providerId = null;
    this.jobScheduleSummary = '';
    this.jobScheduleName = '';
    this.isActive = true;
    this.allDayEvent = true;
    this.jobScheduleType = '';
    this.jobScheduleRecurrenceType = '';
    this.jobFrequencyType = '';
    this.jobFrequencyRecurUnit1 = 1;
    this.jobFrequencyRecurUnit2 = 1;
    this.jobFrequencyRecurUnit3 = 1;
    this.dailyFrequencyTime = '';
    this.dailyFrequencyUnit = 1;
    this.dailyFrequencyStartTime = '';
    this.dailyFrequencyEndTime = '';
    this.jobStartDate = '';
    this.jobEndDate = '';
    this.jobScheduleWeekdays = [];

  }
}
