/** Imports for Angular modules */
import { OnInit, Component, OnDestroy  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/** Imports for third-party modules and services */
import { Subscription, pipe } from 'rxjs';
import { tap,map, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { DragulaService } from 'ng2-dragula';

/** Imports for services */
import { DomainObjectService } from '../../shared/services/domain-object.service';
import { IErrorData } from '../../shared/interfaces/index';

import {
    IManagerPortal,
    IPortalSection,
    ISectionWidget,
    IWidgetManager,
    IPortalLayoutUpdateRequest
} from '../portals/portal/interfaces/index';


/**
 * The component which houses and manages widgets on a given portal
 */
@Component({
    selector: 'widget-layout-editor',
    templateUrl: './widget-layout-editor.component.html',
    viewProviders: [DragulaService]
})
export class WidgetLayoutEditorComponent implements OnInit, OnDestroy  {

    // Details for the portal where this dashboard is contained.
    // We still allow the ID to be passed directly, because the portal
    // object is populated asynchronously, and we might need to use the
    // portal ID before the asynchronous call returns.
    portalId: number;
    portalEntityId: number;
    portalData: IManagerPortal;

    // display debugging elements
    debugWidgetLayoutEditor: boolean;

    // todo: make this extendable property for components
    // log to console
    logWidgetLayoutEditor: boolean;

    // limit for number of widgets within a section - in UI for now
    widgetsDragDropBag: string = 'widgetsDragDropBag';
    maxWidgetsPerSection: number = 2;  

    // Details for the widgets and widget layout for this dashboard.
    availWidgetManager: IWidgetManager[] = []; // WidgetManagerPortal - AvailableByUserAngular
    availableWidgets: IPortalSection[] = []; // UserWidgetLayoutSection - AvailableByUserAngular + Seeding
    layoutSections: IPortalSection[] = []; // UserWidgetLayoutSection - CurrentByUser

    // UI arrays for Dragula Model binding
    lastDropPlaceholderSection: any;
    // track where the last dragula placeholder was positioned to recalculate columns on shadow event
    lastDropDebug: string;

    // Set when an error is encountered in a service
    errorMessage: string;
    // The sections and their widgets that are associated to this portal
    errorData: IErrorData[] = [];
   

    subscription: Subscription = new Subscription();

    // Constructor for injecting services, etc.
    constructor(public activeRoute: ActivatedRoute,
      public router: Router,
      public domainObjectService: DomainObjectService,
      public dragulaService: DragulaService) {

      this.debugWidgetLayoutEditor = false;

        dragulaService.createGroup(this.widgetsDragDropBag, { revertOnSpill: true,
          accepts: function (el, target, source) {
            let   maxWidgetsPerSection: number = 2;   //keep in here

            let isSectionItem = target.classList.contains("widget-layout-editor__section-items--drag");

            if (!isSectionItem) {
              //can always drop into available widgets
              return true;
            }

            let existingWidgets = target.querySelectorAll("div.widget-drag__container > div.widget-drag__item");

            if (existingWidgets.length > maxWidgetsPerSection) {
              //dropping this widget into this section will violate the max allowed so reject
              return false;
            }

            return true;

          }
        });
    }

    /*
        Component Initialization
        Go get the meta data for this object.
    */
    ngOnInit() {
       this.activeRoute.params.subscribe(params => {
          this.portalId = params['id'];
           this.portalEntityId = params.hasOwnProperty('portalEntityId') ? params['portalEntityId'] : -1;
        });

        this.getPortalData();
    }


    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
  

    /*
        Retrieve the data for this portal
    */
  getPortalData() {
    this.subscription.add(this.domainObjectService.get("ManagerPortal", this.portalId)
            .subscribe(result => {
              if (result.success) {
                this.portalData = <IManagerPortal>result.data;
                    this.loadComponent();

                } else {
                    this.errorData = result.errorData;
                }
            }, error => this.errorMessage = <any>error));

    }

    /**
     * Primary entry point for loading of the component
     */
    loadComponent(): void {

        this.errorData = [];
        this.availWidgetManager = []; 
        this.availableWidgets = []; 
        this.layoutSections = []; 

       this.getWidgetsForThisPortal();
    }

   
    /**
     * Stuff retrieved IWidgetManager object into IPortalSection object so both ui objects are of the same type.
       Needed for Dragula
     */
    seedPortalSectionObject(): void {

        this.availableWidgets = new Array<IPortalSection>();

        let unUsedWidgets: ISectionWidget[] = new Array<ISectionWidget>();
        let i: number = 0;

      this.availWidgetManager.forEach(w => {
        //console.log("seedPortalSectionObject", w, w.widgetManagerComponentName);
        let newSectionWidget: ISectionWidget = {
          userWidgetLayoutId: null,
          orderInSection: -1,
          widgetId: w.widgetManagerPortalId,
          widgetTitle: w.widgetManagerPortalTitle,
          componentName: w.componentName,
          widgetManagerPortal:
          {
            widgetManagerPortalId: w.widgetManagerPortalId,
            widgetManagerPortalTitle: w.widgetManagerPortalTitle,
            widgetManagerComponentName: w.componentName
          }
        }

        unUsedWidgets.push(newSectionWidget);
      });

        // one place holder portal section
        let newSection: IPortalSection = {
            userWidgetLayoutSectionId: null,
            providerUser_providerUserId: null,
            managerPortal_managerPortalId: this.portalId,
            orderInPortal: 0,
            numColumns: 0,
            userWidgetLayouts: cloneDeep(unUsedWidgets)
        }

        this.availableWidgets.push(newSection);
    }

    /**
     * Toggle editing of the widget layout
     */
  cancelLayoutChanges(): void {
        this.returnToPortal();
    }

    returnToPortal() {
      /*
       * 1	Project Manager
          2	User Portal
          5	Reports Portal
          6	Compliance Manager
          7	Installer Manager
       */
      const portal = this.portalId === 2 ? 'user-dash' : 'compliance';
      if (this.portalEntityId > 0) {
        this.router.navigate([portal, this.portalId, { portalEntityId: this.portalEntityId }]); //example Installer Manager portal - widgets generic across all installers, but data specific to installer id
      } else {
        this.router.navigate([portal, this.portalId]);
      }
    }

    // flatten layout for acceptance by mvc/json
    // mvc will not acce
    flattenLayout(): IPortalLayoutUpdateRequest[] {
        let updatedPortal: IPortalLayoutUpdateRequest[] = [];

        var i = 0;
        for (let s of this.layoutSections) {
            for (let w of s.userWidgetLayouts) {
                let newWidget: IPortalLayoutUpdateRequest = {
                    userWidgetLayoutSectionId: s.userWidgetLayoutSectionId,
                    orderInPortal: s.orderInPortal,
                    numColumns: s.numColumns,
                    providerUser_providerUserId: s.providerUser_providerUserId,
                    managerPortal_managerPortalId: s.managerPortal_managerPortalId,
                    userWidgetLayoutId: w.userWidgetLayoutId,
                    orderInSection: w.orderInSection,
                    widgetId: w.widgetId,
                    widgetTitle: w.widgetTitle,
                    componentName: w.componentName
                }
                updatedPortal.push(newWidget);
            }
        }
        return updatedPortal;
    }

    // validate layout before save
    validateLayout(updatedSections: IPortalSection[]): boolean {
        let result: boolean = true;

        if (updatedSections == null || updatedSections.length === 0) {
            this.errorMessage = "You must have at least one widget placed in your portal.";
            result = false;
        }

        return result;
    }

/**
 * Add a new section for widgets to be dragged into - button clicked
 *   '+' button below sections clicked 
 */
    addWidgetSection(): void {
        
        // create new section and append to end of array
        let newSection: IPortalSection = {
            userWidgetLayoutSectionId: null,
            providerUser_providerUserId: null,
            managerPortal_managerPortalId: this.portalId,
            orderInPortal: this.layoutSections.length,
            numColumns: 0,
            userWidgetLayouts: new Array<ISectionWidget>()
        }

        this.layoutSections.push(newSection);
    }

    /**
     * Remove an existing section
     *   'X' button on section container clicked
     */
    removeWidgetSection(section: IPortalSection): void {

        // remove the section
        let idx = this.layoutSections.indexOf(section);
        let sectionToRemove = this.layoutSections.splice(idx, 1);

        // remove any widgets from the section and return them to available
        let widgetsToRemove = sectionToRemove[0].userWidgetLayouts;
        for (let w of widgetsToRemove) {
            this.availableWidgets[0].userWidgetLayouts.push(w);
        }

        // if last section was removed add an empty section for a drop target
        if (this.layoutSections.length === 0) {
            this.addWidgetSection();
        }
    }

    /**
     * Remove a widget
     *   'X' button on widget clicked
     */
    removeWidgetPosition(section: IPortalSection, index: number): void {

        // remove widget from section array and add back to available
        var w = section.userWidgetLayouts.splice(index, 1);
        this.availableWidgets[0].userWidgetLayouts.push(w[0]);
    }


    /* SERVICE CALLS */

    /**
    * Retrieves ALL the widgets which are valid for this portal, multiple calls
    */
    getWidgetsForThisPortal(): void {
        // Translation: Get all the WidgetManagerPortals (available to the user)
      // where ManagerPortal has ID [portalId].
      this.subscription.add( this.domainObjectService
            .getByMethodById("WidgetManagerPortal", "AvailableByUserAngular", this.portalId)
            .subscribe(result => {
              if (result.success) {
                    //load the available section
                const widgets = result.data;
                  this.availWidgetManager = widgets.filter(x => x['componentName'] !== null && x['componentName'].trim() !== '');
                    //map that data into IPortalSection object type
                    this.seedPortalSectionObject();
                    // Getting used/selected widgets 
                    this.getLayoutSectionsForThisPortal();
                } else {
                    this.errorData = result.errorData;
                }
            },
            error => this.errorMessage = <any>error));
    }

    /**
     * Retrieves all the widget layout sections which the user has configured for this portal.
     * We are assuming this comes back ordered by OrderInPortal.
     */
    getLayoutSectionsForThisPortal(): void {
        // Get all sections which this user has laid out for the current portal.
        // The 'getWidgetsForAllSections' call ensures that once the sections have
        // been pulled back, each section's widgets have been appended to the data.
       this.subscription.add( this.domainObjectService
            .getByMethodById("UserWidgetLayoutSection", "CurrentByUser", this.portalId)
            .subscribe(result => {
              if (result.success) {
                this.layoutSections = <IPortalSection[]>result.data;
                } else {
                    this.errorData = result.errorData;
                }

            },
            error => this.errorMessage = <any>error));
    }


    /**
     * Save any changes made to the widget layout
     */
    saveLayoutChanges(): void {

        let updatedPortal: IPortalLayoutUpdateRequest[] = this.flattenLayout().splice(0);
        // Send the sections and widget layouts to the server to be updated.
        // Assuming success, the response contains the updated data.
        // Remap it and re-pull the current and available widgets.
      // If there are no sections on update, add an empty one to the UI.
      this.subscription.add(this.domainObjectService
            .updateByMethod("UserWidgetLayoutSection", "UpdatePortalLayout", updatedPortal, "models")
            .subscribe(result => {
              if (result.success) {
                    this.returnToPortal();
                } else {
                    this.errorData = result.errorData;
                }
            }, error => this.errorMessage = <any>error));
    }

}
