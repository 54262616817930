/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition, ListDefinition, IListFilter, ListFilter, IListButtonType, OrderTerm } from '../../../../../fw/dynamic-list/interfaces/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'installer-notes',
  templateUrl: './installer-notes.component.html'
})
export class InstallerNotesComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;

  installerId: number;

  constructor(
    public activeRoute: ActivatedRoute,
    public dynamicListService: DynamicListService ) {}

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
          this.installerId = +paramMap.get('id'); 
        }
      );

    const listObjectLabel = 'Installer Notes ';
    const listObjectController = 'Note';
    const listStoreName = 'installerNotes';
    const detailRoutePath = '';
    const listRowKeyId = 'noteId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('createDate', false)];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      null,
      detailRoutePath);

    listDefinition.parentId = this.installerId;
    listDefinition.parentKey = 'installer_installerId';
    listDefinition.controllerMethod = 'ByInstaller';
    listDefinition.methodParameters= this.installerId.toString();
    listDefinition.allowSaveFilter = false;

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    this.listDefinition = listDefinition; 
  }


  ngOnDestroy() {}
}
