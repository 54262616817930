/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { UserPriviledgesService } from '../../../../../auth/services/index';

//store actions, reducers, interfaces
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getListByType } from '../../../../../fw/dynamic-list/store/index';
import { UtilitiesStore } from '../../enums/utilities.enums';
import { ContactConstantsService } from '../../../../contact/services';

@Component({
  selector: 'customer-contact-info',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './customer-contact-info.component.html'
})
export class CustomerContactInfoComponent implements OnInit, OnDestroy {
  public contactId: number;
  public canIEdit: boolean;
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute, 
    public store: Store<fromFeature.IAllDynamicData>,
    public ups: UserPriviledgesService,
    public contactConstantsService: ContactConstantsService,
    @Inject(appConstants) public myConstants: IAppConstants) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.contactId = +paramMap.get('id');
      this.subscription.add(this.store.select(getListByType(UtilitiesStore.dashCustomers)).subscribe((data) => {
        const items = data.objData[0].data;
        let i = items.length;
        while (i--) if (items[i].contactId === this.contactId) return this.canIEdit = this.ups.canIEdit(items[i]);
      }));
    });
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
