import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { AdminEvent, AdminStore } from '../../enums/admin.enums';
import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IPrivilegeTemplatePrivilege } from '../../../view-models';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';

import { AdminSecurityService } from '../../services/admin-security.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'privilege-template-privileges',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './privilege-template-privileges.component.html'
})
export class PrivilegeTemplatePrivilegesComponent implements OnInit {
  public listDefinition: IListDefinition;
  privilegeTemplateId: number;
  params: IDetailRouteParameter;

  constructor(public activeRoute: ActivatedRoute,
    public securityService: AdminSecurityService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.privilegeTemplateId = +paramMap.get('id');
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.managePrivilegeValues:
        this.openPrivilegeValues(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.securityService.loadPrivilegesForPrivilegeTemplateListDefinition(this.privilegeTemplateId);

    this.params = {
        rowIndex: -1,
        storeName: AdminStore.privilegeTemplatePrivileges,
        key: 'privilegeTemplatePrivilegeId',
        operation: this.myConstants.operationTypeDetails,
        showNext: false,
        showPrev: false,
        requestTime: new Date(),
        portalEntityId: -1
      }
  }

  openPrivilegeValues(priv: IPrivilegeTemplatePrivilege): void {
      this.modalService.open({
        title: 'Rule Values for Privilege'.concat(' ', priv.privilegeId),
        path: 'ptp-values',
        id: priv.privilegeTemplatePrivilegeId,
        sizeType: ModalSizeType.small,
        onExit: null,
        castExit: false,
        optionalParams: this.params,
        hasTabs: false,
        isSubModal: true
      });
    }

}
