import { Component, Input } from '@angular/core';
import { ILegend } from './interfaces/i-legend';

@Component({
  selector: 'fw-legend',
  templateUrl: './fw-legend.component.html'
})

export class LegendComponent  {
  @Input() title: string;
  @Input() items: ILegend[]

  public isVisible: boolean = false;

  public toggle(): void {
    this.isVisible = !this.isVisible;
  }

}
