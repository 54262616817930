import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

import { InstallerCertificationViewModel } from '../../../view-models/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';

//store actions, reducers, interfaces
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import {  UpdateObjectCustomModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  selector: 'installer-certification',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './installer-certification.component.html'
})
export class InstallerCertificationComponent implements OnInit, OnDestroy  {
  public initialLoadCompleted: boolean = false;
  public displayFields = [];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  listStore: fromDynamicList.IListObjectData;
  parentId: number = -1;
  myPathName: string = '';
  myStoreName: string = '';
  key: string = '';
  realKey: string = 'installerCertificationId'; //incoming key is certificationId to handle certs that are "missing", so not associated to the installer, but needs to be
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  objectData: InstallerCertificationViewModel = null;

  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.subscription.add(this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.parentId = +paramMap.get('portalEntityId');
      if (!this.parentId || this.parentId <= 0) {
        //when routing from a child level
        this.parentId = +paramMap.get('id');
      }
    }));
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.objectData = null;
      this.initialLoadCompleted = false;
      this.setDisplayFields();
      this.setDetailConfig(paramMap);
      this.getDetail();

    }));

    if (this.operation === this.myConstants.operationTypeCreate) {
      alert('Create is not allowed');
      return;
    }

    this.subscription.add(this.emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          default:
            break;
        }
      }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.parentId;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  setDisplayFields() {
    if (this.myStoreName.toLowerCase().includes('certifications')) {
      this.displayFields = ['certificationName', 'isRequired', 'expirationDate', 'isMet', 'certificationDescription', 'issuingAuthority', 'licenseNumber'];
    } else {
      this.displayFields = ['certificationName', 'isRequired', 'expirationDate', 'isMet', 'certificationDescription', 'licenseNumber'];
    }
  }

  getDetail() {
    this.subscription.add(this.store.pipe(select(fromStore.getListByType(this.myStoreName)))
      .pipe(map((listsState: fromDynamicList.IDynamicListState) => listsState.objData.find(x => x.parentId ==  this.parentId)))
      .subscribe((state: fromDynamicList.IListObjectData) => {
        this.listStore = cloneDeep(state);

        this.myPathName = this.listStore.listDefinition.detailRoutePath;

        this.objectData =  cloneDeep(this.objectData ? this.objectData :  this.listStore.data.find(data => data[this.key] == this.objectId));
        this.initialLoadCompleted = true;
      }));

  }

    /*
        Insert the new record - create not allowed at this level
    */

    /*
        Update the record:  since the data contains both existing and missing certifications, update needs to determine if create or update request
          based on value in installerCertificationId
    */
  updateRecord(e: IHomEventEmitter) {
      this.setCommonProperties(e.data);
      //access store by key, but update by realKey Update and Create utilize id in the model
      const keyData: IKey = { storeName: this.myStoreName, parentId: this.parentId, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
      const updateData = new UpdateObjectCustomModel(keyData, this.listStore.listDefinition.controllerName, 'Update', this.objectData, null, emitter);
      this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));

    }

    /*
        Set common create/update properties
    */
    setCommonProperties(formData: InstallerCertificationViewModel) {
      //set installerId or tech id, but not both as these tables hold records applicable to either installler or  tech
      this.objectData.installerId = this.myStoreName.toLowerCase().includes('technician')  ? null : this.parentId;
      this.objectData.installerWorkCrewTechnicianId = this.myStoreName.toLowerCase().includes('technician') ? this.parentId : null;
      this.objectData.expirationDate = formData.expirationDate;
      this.objectData.isMet = formData.isMet;
      if (this.myStoreName.toLowerCase().includes('certifications')) {
        this.objectData.issuingAuthority = formData.issuingAuthority;
      }
      this.objectData.licenseNumber = formData.licenseNumber;
    }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
