export interface IProjectCallLogResponse {
  projectCallLogResponseId: number;
  callLogResponse_callLogResponseId: number;
  projectCallLog_projectCallLogId: number;

  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
}

export class ProjectCallLogResponse implements IProjectCallLogResponse {
  projectCallLogResponseId: number;
  callLogResponse_callLogResponseId: number;
  projectCallLog_projectCallLogId: number;

  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  constructor() {
    this.projectCallLogResponseId = 0;
    this.callLogResponse_callLogResponseId = null;
    this.projectCallLog_projectCallLogId = null;
    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';

  }

}
