import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IInstallerCompatibilityPreference, IInstallerScore } from '../../../view-models/installer-compatibility-preference';

import { MetaDataService } from '../../../../../fw/dynamic-list/services/meta-data.service';
import { AdminStore } from '../../enums/admin.enums';

@Component({
  selector: 'installer-scoring-items',
  templateUrl: './installer-scoring-items.component.html',
  providers: [MetaDataService]
})
export class InstallerScoringItemsComponent implements OnInit, OnDestroy {
  @Input() objectData: IInstallerCompatibilityPreference;
  @Input() scoring: IInstallerScore[];
  @Input() operation: string;

  public displayFields: string[] = ['workCategoryCompatibilityWeight', 'locationServicePreferenceCompatibilityWeight', 'locationServiceFlexibilityWeight', 'zonePreferenceCompatibilityWeight', 'warrantyCallbackRateWeight', 'averageCustomerSurveyResponseWeight', 'jobHistoryConsiderationAssignmentThreshold', 'maximumJobHistoryConsiderationDays'];
  public form$: BehaviorSubject<FormGroup> = new BehaviorSubject(null);
  subscription = new Subscription();

  constructor(
    public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.mds.setFieldDefinitions(AdminStore.installerCompatibilityPreferences);

    this.subscription.add(this.mds.mdsReady$.pipe(filter(flag => flag === true), take(1))
      .subscribe((ready: boolean) => {
        this.loadForm();
      }));
  }

  loadForm() {
    this.form$.next(this.mds.loadDynamicFormGroup(this.displayFields, this.objectData, this.operation));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
