import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { cloneDeep, orderBy } from 'lodash';

import * as fromRoot from '../../../../store/reducers/index';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import { IEnum } from '../../../view-models';
import { IPoLswpOverrideReason } from '../../interfaces';

@Component({
  selector: 'lswp-override-reasons',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './lswp-override-reasons.component.html'
})
export class LswpOverrideReasonsComponent implements OnInit, OnDestroy {
  @Input() data: IPoLswpOverrideReason[];
  @Output() public changeEvent = new EventEmitter<IPoLswpOverrideReason>();

  subscription = new Subscription();
  public overrideReasonCodes: IEnum[] = [];

  constructor(public rootStore: Store<fromRoot.IState>) { }

  public onChangeEvent(item: IPoLswpOverrideReason, reasonCodeId: number): void {
    let itemOut = cloneDeep(item);
    itemOut.reasonCodeId = reasonCodeId;

    //just bubble up
    this.changeEvent.emit(itemOut)
  }

  ngOnInit() {
    this.newRequest();
  }

  newRequest(): void {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('homeDepotLswpOverrideReasonCode')))
      .subscribe((data: IEnum[]) => {
        this.overrideReasonCodes = orderBy(data.filter(x => x.name.toLowerCase() != 'none'), ['name'], ['asc']) ;
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
