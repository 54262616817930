import { Component, OnInit, OnDestroy, Inject, EventEmitter, Output, Input } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IErrorData, IResponseBase, IHomDictionary, HomDictionary, IDomainObject } from '../../../../../shared/interfaces';
import { DomainObjectService } from '../../../../../shared/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { EntityCrmForm, IEntityCrmForm, IEntityCrmFormSectionQuestionViewModel, EntityCrmFormSectionQuestionViewModel, ICrmFormSectionViewModel, CrmFormSectionViewModel } from '../../../view-models/index_two';
import { IHomEventEmitter, HomEventEmitterService } from '../../../../../../../hom-lib/hom-event-emitter';
import { HomSignatureEvent } from 'hom-lib/hom-signature';
import { CrmFormsEvent } from './crm-forms.enums';

@Component({
  selector: 'crm-form-entry',
  templateUrl: './crm-form-entry.component.html'
})
export class CrmFormEntryComponent implements OnInit, OnDestroy {
  @Input() entityDocumentVersionId: number = 0;
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  public errorMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subscription: Subscription = new Subscription();
  entityCrmForm: IEntityCrmForm = new EntityCrmForm();
  entityDocumentId: number;
  entityFormSectionQuestions: BehaviorSubject<IEntityCrmFormSectionQuestionViewModel>[] = [];
  nextUncompletedSectionId$: BehaviorSubject<number> = new BehaviorSubject(0);
  sectionTitle: string = '';
  sectionInstructions: string = '';
  displayTitleOnForm: boolean = true;
  formComplete$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public modalService: ModalService,
    public dos: DomainObjectService,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) {
  }
  ngOnInit() {
    this.working$.next(true);
    this.addVariableSubscriptions();
    this.newRequest();
  }

  addVariableSubscriptions(): void {
    this.subscription.add(this.nextUncompletedSectionId$.subscribe((val: number) => {
      if (val)
        this.getQuestionData(val);
    }));
    this.subscription.add(this.formComplete$.subscribe((val: boolean) => {
      if (val)
        this.markFormComplete();
    }))
  }

 
  changeSection(val): void {
    this.nextUncompletedSectionId$.next(val);
  }

  public answerQuestion(event: IHomEventEmitter): void {
    let crmFormQuestion: IEntityCrmFormSectionQuestionViewModel = event.data;
    switch (event.event) {
      case "answer":
      case "formSignatureSuccess":
        for (let question of this.entityFormSectionQuestions) {
          if (question.value.crmFormQuestionId == crmFormQuestion.crmFormQuestionId) {
            question.next(crmFormQuestion);
            this.subscription.add(this.dos.createByMethod('EntityCrmFormSectionQuestionViewModel', 'Update', question.value)
            .subscribe((response: IResponseBase) => {
            if (response.success) {
              for (let question of this.entityFormSectionQuestions) {
                if (question.value.crmFormQuestionId == crmFormQuestion.crmFormQuestionId) {
                  question.next(response.data);
                }
              }
              for (let question of this.entityFormSectionQuestions) {
                let temp = question.value;
                if (temp.crmFormQuestionDependencies.some(x => x.crmFormQuestionId === crmFormQuestion.crmFormQuestionId)) {
                  if (crmFormQuestion.crmFormQuestionAnswers.filter(x => x.selected).some(x => temp.crmFormQuestionDependencies.some(y => y.answerText === x.answerText))) {
                    temp.enabled = true;
                    temp.required = true;
                  }
                  else {
                    temp.enabled = false;
                    temp.required = false;
                    temp.isAnswered = false;
                    temp.answerText = '';
                  }
                  question.next(temp);
                }
              }
            } else {
                this.errorData$.next(response.errorData);
              }
              // Lets figure out if the section is completed or not
              for (let section of this.entityCrmForm.crmFormSections) {
                if (section.crmFormSectionId === this.nextUncompletedSectionId$.value) {
                  section.isComplete = !this.entityFormSectionQuestions.some(x => x.value.required && x.value.enabled && x.value.isAnswered === false);
                  break;
                }
              }
              // now we see if the form is complete
              let formComplete = !this.entityCrmForm.crmFormSections.some(x => x.isComplete === false)
              this.formComplete$.next(formComplete);
              this.working$.next(false);
            },
              (error: any) => {  }
            ));
            break;
          }
        }
        break;
      default:
        break;
    }

  }
  newRequest() : void {
    this.subscription.add(this.dos.getByMethodById('EntityCrmForm', 'DetailsByEntityDocumentVersion', this.entityDocumentVersionId)
      .subscribe((response: IResponseBase) => {
      if (response.success) {
        this.entityCrmForm = response.data;
        this.entityDocumentId = this.entityCrmForm.entityDocumentId;
        // get the number of sections
        const numberSections = this.entityCrmForm.crmFormSections.length;
        // get the lowest section order that is not completed yet
        for (let crmSection of this.entityCrmForm.crmFormSections) {
          if (!crmSection.isComplete || crmSection.sectionOrder >= numberSections) {
            this.nextUncompletedSectionId$.next(crmSection.crmFormSectionId);
            this.sectionTitle = crmSection.sectionTitle;
            this.sectionInstructions = crmSection.sectionInstructions;
            this.displayTitleOnForm = true;
            break;
          }
        };
        let formComplete = !this.entityCrmForm.crmFormSections.some(x => x.isComplete === false)
        this.formComplete$.next(formComplete);
        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => {  }
      ));
  }

  markFormComplete(): void {
    let params: IDomainObject = { entityDocumentVersionId: this.entityDocumentVersionId };
    this.subscription.add(this.dos.createByMethod('EntityCrmForm', 'MarkComplete', params)
      .subscribe((response: IResponseBase) => {
      if (response.success) {        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => {  }
      ));
  }

  getQuestionData(sectionId: number): void {
    let params: IHomDictionary[] = [
      { key: 'entityCrmFormId', value: this.entityCrmForm.entityCrmFormId },
      { key: 'crmFormSectionId', value: sectionId }
      ]
    this.subscription.add(this.dos.getByMethodParams('EntityCrmFormSectionQuestionViewModel', 'ByCrmFormSection', params)
      .subscribe((response: IResponseBase) => {
      if (response.success) {
        let questions:IEntityCrmFormSectionQuestionViewModel[] = response.data;
        this.entityFormSectionQuestions = [];
        for (let question of questions) {
          this.entityFormSectionQuestions.push(new BehaviorSubject<IEntityCrmFormSectionQuestionViewModel>(question))
        }
        for (let crmSection of this.entityCrmForm.crmFormSections) {
          if (sectionId === crmSection.crmFormSectionId) {
            this.sectionTitle = crmSection.sectionTitle;
            this.sectionInstructions = crmSection.sectionInstructions;
            this.displayTitleOnForm = crmSection.displayTitleOnForm;
            break;
          }
        };
        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => {  }
      ));
  }

  submitForm(): void {
    let params: IDomainObject = { entityDocumentVersionId: this.entityDocumentVersionId };
    this.subscription.add(this.dos.createByMethod('EntityCrmForm', 'SubmitForm', params)
      .subscribe((response: IResponseBase) => {
      if (response.success) {        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => {  }
      ));
  }

  public ngOnDestroy(): void {
    this.emitterService.emitCrmFormEvent({ requestor: 'project-required-documents', event: CrmFormsEvent.crmFormChange, action: '', data: null});
    this.subscription.unsubscribe();
  }

}
