import { Component, OnInit,  Inject, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {  Observable, BehaviorSubject, Subscription } from 'rxjs';
import {  take } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IProject } from '../../../view-models/index';
import { IFormDefinition } from '../../../../../fw/dynamic-forms/index';

//store actions, reducers, interfaces
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getObjectDataById, getObjectErrorsById, getObjectErrorMessageById } from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { ProjectObjectStore } from '../../enums/project.enums';
import { IErrorData } from '../../../../../shared/interfaces';
import { ProjectService } from '../../services/project.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'project-summary',
  templateUrl: './project-summary.component.html'
})
export class ProjectSummaryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() projectId: number;
  @Input() formDefinition: IFormDefinition = null;

  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public project$: BehaviorSubject<IProject> = new BehaviorSubject(null);
  public title$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: Observable<IErrorData[]>;
  public errorMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  public operation: string;
  openLinkRequested: boolean = false;
  projSub: Subscription = new Subscription();
  subscription = new Subscription();

  constructor(
    public router: Router,
    public store: Store<fromFeature.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public projectService: ProjectService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants ) { }

  public openLink(projectId: number) {
    this.projectService.openProjectTab(projectId, 'project-summary');
    this.openLinkRequested = true;
    this.subscription.add(this.router.events.subscribe((val) => {
      if (this.openLinkRequested && val instanceof NavigationEnd) {
        if (this.modalService.opened) {
          this.modalService.close();
        }
      }
    }));
  }

  public onClose() {
    let emitter: IHomEventEmitter = { requestor: 'project-summary', event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  public onNavigate(action: string) {
    let emitter: IHomEventEmitter = {
      requestor: 'project-summary',
      event: this.myConstants.emitterEventNavigation, action: action,
      data: null
    };
    this.emitterService.emitListEvent(emitter);
  }

  ngOnInit() {
    this.operation = this.myConstants.operationTypeDetails;
    this.openLinkRequested = false;
    this.project$.next(null);
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] && !(changes['projectId'].firstChange)) {
      this.openLinkRequested = false;
      this.project$.next(null);
      this.getData();
    }
  }

  getData(): void {
    this.errorData$ = this.store.pipe(
      select(getObjectErrorsById(ProjectObjectStore.projectInformation, this.projectId)));

    this.subscription.add(this.store.pipe(
      select(getObjectErrorMessageById(ProjectObjectStore.projectInformation, this.projectId)))
      .subscribe(msg => {
        if (msg.startsWith('Error Accessing')) {
          this.errorMessage$.next('Project has been deleted.');
        } else {
          this.errorMessage$.next(msg);
        }
        if (msg) {
          if (!this.title$.value) {
            this.title$.next('Unable to retrieve Project '.concat(this.projectId.toString()))
          }
          this.working$.next(false);
        }
     }));

    if (this.projSub) {
      this.projSub.unsubscribe();
    }

   this.projSub = this.store.pipe(select(getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)), take(1))
      .subscribe((objData: IProject) => {
        if (!objData) {
          this.working$.next(true);
          this.projectService.dispatchProjectObject(this.projectId);
        }
      } 
    );

    this.subscription.add(this.store.pipe(select(getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)))
      .subscribe((objData: IProject) => {
        if (objData) {
          if (objData.projectId) {
            let title = !this.formDefinition ? '' : 'Originating Project Summary for ';
            this.title$.next(title.concat(objData.customerName.concat(objData.warranty_warrantyId && objData.warranty_warrantyId > 0 ? ' Warranty ' : '', ' Project ', this.projectId.toString())));
            this.project$.next(objData);
          }
          this.working$.next(false);
        }
      }));
    }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.projSub.unsubscribe();
  }

}
