/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import {
  IListColumn, ListColumn, IListDefinition,
  ListDefinition, IListFilter, ListFilter, IListButtonType, OrderTerm
} from '../../../../../fw/dynamic-list/interfaces/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
    selector: 'gc-installer-requirements',
    templateUrl: './gc-installer-requirement.component.html'
})
export class GcInstallerRequirementComponent implements OnInit {
  @Input() data: any;

  public listDefinition: IListDefinition;

  extraData: any;

  constructor(public router: Router,
    public dynamicListService: DynamicListService,
    public userPriviledgesService: UserPriviledgesService ) { }

    ngOnInit() {
        this.loadListDefinition();
    }

    /*
        Define the list to be created
    */
    loadListDefinition() {
        const listColumns = this.loadListColumns();
        const listObjectLabel = 'Installer Requirement';
        const listObjectController = 'GeneralContractorInstallerRequirement';
        const listStoreName = 'generalContractorInstallerRequirements';
        const detailRoutePath = 'gc-installer-requirement-detail';
        const listRowKeyId = 'generalContractorInstallerRequirementId';
        let defaultListFilter: IListFilter = new ListFilter();
        defaultListFilter.orderTerm = [new OrderTerm('installerRequirementName')];

      let listDefinition = this.dynamicListService.createListDefinition('gcInstallerRequirementOutlet',
            listObjectLabel,
            listObjectController,
            listStoreName,
            listRowKeyId,
            defaultListFilter,
            listColumns,
            detailRoutePath); 

      if (this.userPriviledgesService.canManageInstallers$.value) {
        const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
        listDefinition.toolButtons.splice(0, 0, newTools[0]);

        let crudButtons: IListButtonType[] = [
          { type: ButtonType.detail, defaults: null },
          { type: ButtonType.edit, defaults: null },
          { type: ButtonType.delete, defaults: null }
        ];

        listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);
      }

      this.listDefinition = listDefinition;
    }

    /*
        Define the columns in the list to be created.
    */
    loadListColumns(): IListColumn[] {

        let columnDefinitions: Array<IListColumn> = [];
 
      let colDef: ListColumn = new ListColumn('installerRequirementName');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('required');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('installerRequirementDescription');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('generalContractor');
      colDef.visibleOnSmall = false;
      colDef.visibleOnMedium = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('requirementLevel');
      colDef.visibleOnSmall = false;
      colDef.visibleOnMedium = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('validatesParent');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      return columnDefinitions;

    }
    

}
