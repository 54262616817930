import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { AdminEvent, AdminStore } from '../../enums/admin.enums';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IAllDynamicData, getSelectedRecord } from '../../../../../fw/dynamic-list/store';
import { IProviderRole } from '../../../view-models';
import { AdminSecurityService } from '../../services/admin-security.service';

@Component({
  selector: 'role-widgets',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './role-widgets.component.html'
})
export class RoleWidgetsComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;
  roleId: number;
  subscription: Subscription = new Subscription();
  currentRole: IProviderRole;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<IAllDynamicData>,
    public securityService: AdminSecurityService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.roleId = +paramMap.get('id');
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.showRelatedEntityManager:
        this.securityService.openSecurityEntityManager(this.listDefinition, this.currentRole);
        break;
      default:
        break;
    }
  }

  newRequest() {
    const prListDef = this.securityService.loadProvideRolesListDefinition();
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(prListDef.storeName, -1, prListDef.rowKeyId, this.roleId), take(1)))
      .subscribe(entity => {
        this.currentRole = entity;
      })
    );
    this.listDefinition = this.securityService.loadWidgetsForProviderRoleListDefinition(this.roleId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
