import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { ProjectService } from '../../../project/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'work-orders-summary',
  templateUrl: './work-orders-summary.component.html'
})
export class WorkOrdersSummaryComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() canIEdit: boolean = false;
  
  public  listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(
    public projectService: ProjectService,
    public modalService: ModalService) { }

  ngOnInit() {
      this.setListDefinition();
  }

  setListDefinition(): void {
    let listDefinition = this.projectService.loadProjectWorkOrderListDefinition(this.projectId, this.canIEdit, true, this.modalService.opened);
    this.listDefinition$.next(listDefinition);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] && !changes['projectId'].isFirstChange()) {
     this.setListDefinition();
    }
  }
  
}
