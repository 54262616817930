import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { IDashboardCount } from '../../../portal-shared/interfaces';
import { IErrorData, IResponseBase } from '../../../../../shared/interfaces';
import { IProviderUserCountViewModel } from '../../../portal-shared/interfaces/i-provider-user-count-view-model';
import { WarrantyDashStore, WarrantyDashCountType, WarrantyDashTabLabel } from '../../enums/warranty-dashboard.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { WarrantyDashboardService } from '../../services/warranty-dashboard.service';
import { DomainObjectService } from '../../../../../shared/services';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'warranty-dashboard',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './warranty-dashboard.component.html'
})
export class WarrantyDashboardComponent  {
  public portalId: number = 12;
  public tabContainerName = 'warranty-dash';
  public title: string = 'Warranty Dashboard';

  public dashCounts$: BehaviorSubject<IDashboardCount[]> = new BehaviorSubject(null);
  public loading$: Observable<boolean> = of(true);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errorMessage: string = '';

  subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public domainObjectService: DomainObjectService,
    public userPriviledgesService: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public warrantyDashboardService: WarrantyDashboardService) {
  }

  ngOnInit() {
    const dashStores: string[] = Object.keys(WarrantyDashStore);

    this.loading$ = this.rootStore.select('loadingIndicator').pipe(
      filter(x => dashStores.includes(x.requestor)),
      map(x => x.show));

    this.subscription.add(this.store.pipe(select(fromStore.getListByType(WarrantyDashStore.dashUnassignedWarranties)))
      .subscribe((data) => {
        if (data.objData && data.objData.length && data.objData[0].event) {
          if (data.objData[0].event.event == this.myConstants.emitterEventListReload && data.objData[0].errorData !== "" && !data.objData[0].errorData.length) {
            this.loadCounts();
          }
        }
      }));

   this.activeRoute.paramMap.subscribe(() => {
      this.newRequest();
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  //wait for existance of tabs before start looping through count api calls for better ui experience
  public onTabsLoaded(loaded: boolean): void {
    if (loaded) {
      if (!this.warrantyDashboardService.lastDashCounts || this.warrantyDashboardService.lastDashCounts.length === 0) {
        this.loadCounts();
      }
    }
  }

  newRequest() {
    if (this.warrantyDashboardService.lastDashCounts && this.warrantyDashboardService.lastDashCounts.length > 0) {
      this.dashCounts$.next(this.warrantyDashboardService.lastDashCounts);
    } else {
      this.setDashCounts();
    }
  }

  setDashCounts(): void {
    const dashboard: string = 'Warranty Dashboard';

    const dashCounts: IDashboardCount[] = [
      {
        type: WarrantyDashCountType.unassignedWarrantyCount, label: WarrantyDashTabLabel.unassignedWarranties, value: -1,
        fromPortalLabel: dashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(WarrantyDashCountType.unassignedWarrantyCount)
      },
      {
        type: WarrantyDashCountType.openWarrantyCount, label: WarrantyDashTabLabel.openWarranties, value: -1,
        fromPortalLabel: dashboard, fromPortalId: this.portalId,
        fromComponentName: this.getComponentName(WarrantyDashCountType.openWarrantyCount)
      }
    ];

    this.dashCounts$.next(dashCounts);
  }

  getCountByType(countType: IDashboardCount): void {
    const params = this.userPriviledgesService.currentUserId$.value + '?type=' + countType.type;
    this.subscription.add(this.domainObjectService.getObjectByMethod('ProviderUserCountViewModel', 'GetWarrantyCountByType', params)
      .subscribe(result => {
        if (result.success) {
          const data = <IProviderUserCountViewModel>result.data;
          const dashCounts: IDashboardCount[] = this.dashCounts$.getValue();
          let idx: number = dashCounts.findIndex(x => x.type === countType.type);
          if (idx > -1) {
            dashCounts[idx].value = data.dataCount;
          }
          this.dashCounts$.next(dashCounts);
          this.warrantyDashboardService.lastDashCounts = dashCounts;
        } else {
          this.errorData$.next(result.errorData);
        }
      }, error => this.errorMessage = <any>error)
    );
  }

  loadCounts(): void {
    const dashCounts = this.dashCounts$.getValue();

    dashCounts.forEach(type => {
      this.getCountByType(type);
    })
  }

  getComponentName(countType: string): string {
    switch (countType) {
      case WarrantyDashCountType.unassignedWarrantyCount:
        return 'UnassignedWarrantiesComponent';
      case WarrantyDashCountType.openWarrantyCount:
        return 'OpenWarrantiesComponent';
      default:
        return '';
    }
  }


}
