import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IEntityDocument } from '../../../portals/view-models/index_two';

@Component({
  selector: 'file-viewer-carousel',
  templateUrl: './file-viewer-carousel.component.html'
}) export class FileViewerCarouselComponent {

  @Input() data: IEntityDocument[];
  @Input() idx: number;
  @Input() pages: number;
  @Input() currentPage: number;
  @Output() slideEvent: EventEmitter<number> = new EventEmitter();
  @Output() pageEvent: EventEmitter<number> = new EventEmitter();

  slide(idx: number): void {
    if (!this.data || !this.data.length) return;
    const num: number = this.idx + idx,
      len: number = this.data.length;
    this.slideEvent.emit(this.idx + (num < len ? (num + 1) ? idx : len - 1 : -this.idx));
  }

  showPage(page: number) {
    this.pageEvent.emit(page);
  }

  isPdf(str: string): boolean {
    return str.split(".")[1] === "pdf";
  }
  
}

  


