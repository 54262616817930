<ng-container *ngIf="!screenIsSmall">
  <ng-container *ngTemplateOutlet="compatTemplate"></ng-container>
</ng-container>
<ng-container *ngIf="screenIsSmall">
  <fw-info [infoTitle]="'Compatibiity Details'">
    <ng-container *ngTemplateOutlet="compatTemplate"></ng-container>
  </fw-info>
</ng-container>

<ng-template #compatTemplate>
  <div *ngFor="let item of compatibilityList" class="crew-compatibility-list"
       [ngClass]="{ 'crew-compatibility-list--danger': item.categoryWarningLevel === 'SssFilter'}">
    <div class="crew-compatibility-list__name">
      {{ item.categoryName }}
    </div>
    <div class="crew-compatibility-list__val">
      {{ item.categoryComments }}
    </div>
  </div>
</ng-template>
