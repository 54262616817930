import { Component, OnInit,  Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { IListDefinition, IGenericStateData } from '../../../../../fw/dynamic-list/interfaces';
import { SkuMaintEvent } from '../../enums/admin.enums';
import { ISku } from '../../../view-models';
import { SkuCreationWizardOrigin } from '../../../pricing-shared/enums/pricing-shared.enums';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';

import * as fromRoot from '../../../../store/reducers/index';

import { AdminPricingService } from '../../services/admin-pricing.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'skus',
  templateUrl: './skus.component.html'
})
export class SkusComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  subscription: Subscription = new Subscription();
  generalContractorId: number;

  constructor(public adminPricingService: AdminPricingService,
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }


 public ngOnInit() {
   this.activeRoute.paramMap
     .subscribe(paramMap => {
       this.generalContractorId = +paramMap.get('id');
      this.setListDefinition();
     });
 }

 public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case SkuMaintEvent.launchSkuWizard:
        this.launchSkuWizard();
        break;
      case SkuMaintEvent.launchPricingWizard:
        this.launchPricingWizard(event.data);  
        break;
      case SkuMaintEvent.maintainSkuPayouts:
        this.maintainSkuPayouts(event.data);
        break;
      default:
        break;
    }
  }

  setListDefinition(): void {
    let listDefinition: IListDefinition = this.adminPricingService.loadSkuListDefinition(this.generalContractorId);
    this.listDefinition$.next(listDefinition);
  }

  launchSkuWizard(): void {
    this.modalService.open({
      title: 'Sku Creation Wizard',
      path: 'sku-creation-wizard/0/' + this.generalContractorId.toString() + '/' + SkuCreationWizardOrigin.skus,
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.small,
    });
  }

  launchPricingWizard(data: ISku): void {
    const def: IListDefinition = this.listDefinition$.getValue();
    let stateData: IGenericStateData = {
      storeName: def.storeName,
      key: def.rowKeyId,
      parentId: def.parentId
    }
    this.modalService.open({
      title: 'Sku Pricing Wizard',
      path: 'sku-pricing-wizard/' + data.skuId.toString() + '/' + this.generalContractorId.toString(),
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.large,
      state: stateData
    });
  }

  maintainSkuPayouts(data: ISku): void {
    this.modalService.open({
      title: 'Sku Payouts',
      path: 'sku-payouts/' + data.skuId.toString() + '/' + this.generalContractorId.toString(),
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.small,
    });
  }
}
