/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter } from '../../../../../fw/dynamic-list/interfaces/index';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'installer-eligibility-summary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './installer-eligibility-summary.component.html'
})
export class InstallerEligibilitySummaryComponent implements OnInit, OnChanges {

  @Input() installerId: number;
  @Input() workCrewId: number = -1;
  @Input() canIEdit: boolean = false;

  public dateValue: string;
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  title: string = 'Installer Eligibility Summary';

  constructor(public datePipe: DatePipe,
    public dynamicListService: DynamicListService  ) {}

  public selectedDate(val: string): void {
    if (!val) return;

    //always create a new one so push will work
    this.dateValue = val;
    const methodParameters = this.workCrewId > 0 ? this.workCrewId.toString() : this.installerId.toString() + '?date=' + (this.dateValue);
    this.listDefinition$.next(this.setListDefinition(methodParameters));
  }

  ngOnInit() {
    this.initListDefinition();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workCrewId'] && !(changes['workCrewId'].firstChange)) {
      this.initListDefinition();
    }
    if (changes['installerId'] && !(changes['installerId'].firstChange)) {
      this.initListDefinition();
    }
  }

  initListDefinition() {
    const todayDate = this.datePipe.transform(Date.now(), 'MM/dd/yyyy');
    this.dateValue = todayDate;
    const methodParameters = this.workCrewId > 0 ? this.workCrewId.toString() : this.installerId.toString() + '?date=' + todayDate;

    this.listDefinition$.next( this.setListDefinition(methodParameters) );
  }

  setListDefinition(methodParameters: any): IListDefinition {
    //For push to work, must create a new for an object to be recognized as changed
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Installer Eligibility Summary';
    const listObjectController = 'InstallerEligibilityModel';
    const listStoreName = this.workCrewId > 0 ? 'workCrewEligibility' : 'installerEligibility';
    const detailRoutePath = '';
    const listRowKeyId = '';//InstallerEligibilityModel no id, no drill down
    const defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = this.workCrewId > 0 ? this.workCrewId : this.installerId;
    listDefinition.parentKey = this.workCrewId > 0 ? 'installerWorkCrewId' : 'installerId';
    listDefinition.controllerMethod = this.workCrewId > 0 ? 'WorkCrewEligibilityByDate' : 'InstallerEligibilityByDate';
    listDefinition.methodParameters = methodParameters;

    //readonly, no crud buttons
    listDefinition.allowSaveFilter = false;

    return listDefinition;
  }

    /*
        Define the columns in the list to be created.
    */
    loadListColumns(): IListColumn[] {
      let columnDefinitions: Array<IListColumn> = [];
      let colDef = null;

      if (this.workCrewId <= 0) {
        colDef = new ListColumn('workCrewName');
        columnDefinitions.push(colDef);
      } 

      colDef =  new ListColumn('generalContractorName');
      colDef.visibleOnSmall = false;
      colDef.visibleOnMedium = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('locationName');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('serviceName');
      columnDefinitions.push(colDef);

      return columnDefinitions;
    }

}
