import { Component, Input, OnInit } from '@angular/core';

import { CommunicationEventFor } from '../../../../app/portals/portal-shared/enums/communication-event.enums';
import {
  IContact,
  IScheduleInstallerWorkCrewUnavailabilityViewModel,
  IScheduleInstallerWorkCrewAvailabilityViewModel,  ISchedule
} from '../../../../app/portals/view-models';

import { CommunicationEventService } from '../../../../app/portals/portal-shared/services/communication-event.service';
import { UserPriviledgesService } from '../../../../auth/services';

@Component({
  selector: 'fw-phone',
  templateUrl: './fw-phone.component.html'
})
export class PhoneComponent implements OnInit {
  @Input() phoneNumber: string = '';
  @Input() forTexting: boolean = false;
  @Input() displayType: string = '';
  @Input() data: IContact
    | IScheduleInstallerWorkCrewAvailabilityViewModel
    | IScheduleInstallerWorkCrewUnavailabilityViewModel
    | ISchedule = null;
  @Input() cssClass: string = '';
  @Input() allowText: boolean = true;
  @Input() enabled: boolean = true;

  public onlyIcon: boolean = false;
  public onlyText: boolean = false;
  public showBoth: boolean = false;

  constructor(
    public userPriviledgesService: UserPriviledgesService,
    public communicationEventService: CommunicationEventService) {
  }

  public ngOnInit(): void {
    switch (this.displayType) {
      case this.communicationEventService.displayTypeBoth:
        this.showBoth = true;
        break;
      case this.communicationEventService.displayTypeIcon:
        this.onlyIcon = true;
        break;
      default:
        this.onlyText = true;
        break;
    }
  }

  public click(): void {
    if ((this.forTexting && !this.allowText) || (!this.forTexting && !this.userPriviledgesService.canOneClickDial$.value)) {
      return;
    }
    const eventFor: string = this.forTexting ? CommunicationEventFor.texting : CommunicationEventFor.phone;
    this.communicationEventService.requestCommunication(eventFor, this.phoneNumber, this.data);
  }
}
