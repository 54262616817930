import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, Inject, AfterViewInit, Output, EventEmitter} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash'; 
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import {  IValueChanged } from '../../../../fw/dynamic-list/services/index'
import { ContactMechanismAddress, IAddressState, ISanitizedAddress, IContactMechanismAddress, IAddress } from '../../../portals/view-models';
import { IAddFormGroup, IDeleteContactMechanism } from '../../../portals/portal-shared/interfaces';
import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IFieldDefinition } from '../../../../fw/dynamic-forms';
import { ContactEvent } from '../../enums/contact.enums';
import { ContactConstantsService, ContactManagerService, ISuggestedAddress,  ContactUtilityService } from '../../services/index';
import { DomainObjectService } from '../../../../shared/services/index';
import { StaticMetaDataService } from '../../../../fw/dynamic-list/services/static-meta-data.service';
import { IResponseBase } from '../../../../shared/interfaces/index';
import { ISelectionListByKey } from '../../../../fw/dynamic-list/interfaces';
import { UserPriviledgesService } from '../../../../auth/services';
import { MapService } from '../../../../fw/fw-shared/components/fw-map/services/map.service';
import { IAddressZips, IAddressLines } from '../../interfaces';

@Component({
  selector: 'contact-address',
  templateUrl: './contact-address.component.html'
})
export class ContactAddressComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() address: ContactMechanismAddress;
  @Input() fieldDefinitions: IFieldDefinition[];
  @Input() operation: string;
  @Input() contactTypeId: number;
  @Input() requestTime: string;
  @Input() displayType: string;
  @Input() activePrimary: number = -1;
  @Input() canIEdit: boolean;
  @Input() addressStates: IAddressState[];

  @Output() public deleteEvent = new EventEmitter<IHomEventEmitter>();

  public metaLoaded: boolean = false;
  public verifiedResult: ISanitizedAddress;
  public badAddress: boolean = false;
  public verifiedControlName: string = 'isVerified';
  public unverifiedAddress: IAddress = null;
  public showResults$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public failedMapping$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isMapped$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isOverridden$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public needToVerify$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canVerify$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public displayFields = ['line1', 'city', 'zipcode5', 'addressState', 'yearBuilt', 'isPrimary', 'sanitizeOverride', 'contactMechanismAddressId', 'addressId', 'addressType', 'addressTypeId', 'latitude', 'longitude'];
  public definitions$: BehaviorSubject<IFieldDefinition[]> = new BehaviorSubject(null);
  public focusLine1$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  addressStore: string = 'addresses';
  yearBuiltRequired: boolean = false;
  subscription: Subscription = new Subscription();
  isLoading: boolean = false;
  selectedNo: boolean = false;
  operationChanged: boolean = false;
  //used from manager to determine if address form group is valid - required field
  haveSubscribes: boolean = false;
  overrideWatch: { line1: boolean, city: boolean, zipcode5: boolean, addressState: boolean, yearBuilt: boolean }
    = { line1: false, city: false, zipcode5: false, addressState: false, yearBuilt: false };
  
  constructor(
    public  contactConstantsService: ContactConstantsService,
    public contactService: ContactManagerService,
    public contactUtility: ContactUtilityService,
    public staticMetaDataService: StaticMetaDataService,
    public  emitterService: HomEventEmitterService,
    public  domainObjectService: DomainObjectService,
    public  userPriviledgesService: UserPriviledgesService,
    public mapService: MapService,
    @Inject(appConstants) public myConstants: IAppConstants) {
  }
  //if !e.use and !e.override - no change to input values
  //if !e.use and e.override - no change to input values, but override defaults to true
  //if e.use then update form with the verified values
  public onMatchSelected(e: ISuggestedAddress): void {
    if (e.use) {
      this.overrideFormValues();
      this.selectedNo = false;
      let event: IHomEventEmitter = {
        requestor: this.contactConstantsService.requestorTypeContactAddress,
        event: ContactEvent.isContactValid,
        action: '',
        data: true
      };
      this.emitterService.emitContactEvent(event);
    } else if (e.override) {
      let addrForm: FormGroup = this.contactService.contactForm.controls[this.address.formGroupName] as FormGroup;
      addrForm.controls['sanitizeOverride'].setValue(true);
      this.handleOverrideChange(true);
    } else {
      this.selectedNo = true;
      this.verifiedResult = null;
      this.failedMapping$.next(true);
      this.isMapped$.next(false);
    }

    this.showResults$.next(false);
  }


  public openMap(): void {
    this.mapService.openMap({
      latitude: this.address.latitude,
      longitude: this.address.longitude,
      address: this.address.line1
    });
  }

  public setNeedToVerify(): void {
    this.needToVerify$.next((this.contactService.contactForm.controls[this.address.formGroupName].dirty
      || (this.address.addressId === 0 && this.address.line1.length > 0))
      && !this.isOverridden$.value );
  }

  public setIsValid(): void {
    this.isValid$.next((!this.contactService.contactForm.controls[this.address.formGroupName].dirty && this.address.addressId > 0)
      || (this.contactService.contactForm.controls[this.address.formGroupName].dirty && this.isMapped$.getValue()
      ));
  }

  public setCanVerify(): void {
    this.canVerify$.next(this.contactService.contactForm.controls[this.address.formGroupName]
      && (this.contactService.contactForm.controls[this.address.formGroupName].dirty
        || (this.address.addressId === 0 && this.address.line1.length > 0))
      && this.requiredFieldsFilled());
  }

  public setOverridden(): void {
    const addrForm: FormGroup = this.contactService.contactForm.controls[this.address.formGroupName] as FormGroup;
    this.isOverridden$.next(addrForm.controls['sanitizeOverride'].value);
    this.setNeedToVerify();
  }

  ngOnInit() {
    this.showResults$.next(false);
    this.initOverrideWatch();
    this.definitions$.next(cloneDeep(this.fieldDefinitions));
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.isLoading = false;
  }

  initForm() {
    this.isLoading = true;
    this.initFlags();
    //concat multiple properties into applicable single fields
    let modifiedAddr: IContactMechanismAddress = this.mergeAddressLines();
    let selectionLists: ISelectionListByKey[] = [{ key: 'state', items: this.addressStates }];
    const form = this.staticMetaDataService.createDynamicFormGroup(this.definitions$.getValue(), this.displayFields, modifiedAddr, this.operation, selectionLists);

    const verifiedVal: string = this.address.addressId > 0 ? this.address.sanitizeOverride ? 'overridden' : 'verified' : '';
    form.addControl(this.verifiedControlName, new FormControl({ value: verifiedVal, disabled: false }, [Validators.required]));
    let data: IAddFormGroup = { groupName: this.address.formGroupName, formGroup: form };
    this.contactService.contactForm.addControl(data.groupName, data.formGroup);
    this.contactService.contactForm.controls[this.address.formGroupName].updateValueAndValidity({ onlySelf: true });
    this.focusLine1$.next(this.operation === this.myConstants.operationTypeEdit ? true : false);

    this.isMapped$.next(this.haveLongNLat());
    this.setSubscribes();

    this.metaLoaded = true;
    if (this.operation !== this.myConstants.operationTypeDetails && this.address.addressId === 0) {
      this.setCanVerify();
    }
  }

  haveLongNLat(): boolean {
    const noLat = !this.contactService.contactForm.controls[this.address.formGroupName].get('latitude').value
      || this.contactService.contactForm.controls[this.address.formGroupName].get('latitude').value === 0;
    const noLong = !this.contactService.contactForm.controls[this.address.formGroupName].get('longitude').value
      || this.contactService.contactForm.controls[this.address.formGroupName].get('longitude').value === 0;
    return noLat && noLong ? false : true;
  }

  initFlags(): void {
   this.isMapped$.next(false);
    this.failedMapping$.next(false);
    this.showResults$.next(false);
  }

  setSubscribes() {
    const addrForm: FormGroup = this.contactService.contactForm.controls[this.address.formGroupName] as FormGroup;

    this.subscription.add(addrForm.controls['line1'].valueChanges.subscribe(val => { this.handleFormChange('line1', val); }));
    this.subscription.add(addrForm.controls['city'].valueChanges.subscribe(val => { this.handleFormChange('city', val); }));
    this.subscription.add(addrForm.controls['addressState'].valueChanges.subscribe(val => { this.handleFormChange('addressState', val); }));
    this.subscription.add(addrForm.controls['zipcode5'].valueChanges.subscribe(val => { this.handleFormChange('zipcode5', val); }));
    this.subscription.add(addrForm.controls['isPrimary'].valueChanges.subscribe(val => { this.handlePrimaryChanged(val); }));
    this.subscription.add(addrForm.controls['sanitizeOverride'].valueChanges.subscribe(val => { this.handleFormChange('sanitizeOverride', val); }));

    this.subscription.add(this.isMapped$.subscribe(val => {
      //initing lat and long will trigger a revalidation on backend
      if (!val) {
          if (this.addrForm().controls['latitude'].value || this.addrForm().controls['longitude'].value) {
            this.addrForm().controls['latitude'].setValue(null);
            this.addrForm().controls['longitude'].setValue(null);
          }
        this.verifiedResult = null;
        this.setVerified((this.isLoading && this.address.addressId > 0) || this.isOverridden$.value ? 'verified' : '');
        }
    }));
    this.haveSubscribes = true;
}

  addrForm(): FormGroup {
    return this.contactService.contactForm.controls[this.address.formGroupName] as FormGroup;
  }


  handleFormChange(key: string, val: any) {
    if (!this.contactService.contactForm.controls[this.address.formGroupName].dirty) {
      return;
    }

    if (this.isLoading && key !== 'sanitizeOverride') {
      this.setKeyLoadedTrue(key);
      this.isLoading = !this.overridesComplete();
      return;
    }

    this.setCanVerify();

    if (key === 'sanitizeOverride'
        && (!this.verifiedResult || (this.verifiedResult && !this.matchesCurrent()))) {
      this.handleOverrideChange(val);
      return;
    }

    if (this.selectedNo && key === 'addressState') {
      //handles a bug in angular, after selection no, angular sends a value changes on state and shouldn't
      this.selectedNo = false;
      return;
    }
    if (!this.isOverridden$.value && (!this.verifiedResult || (this.verifiedResult && !this.matchesCurrent()))) {
        this.isMapped$.next(false);
        this.failedMapping$.next(false);
    }
  }

  matchesCurrent(): boolean {
    let doesMatch: boolean = true;
    if (!this.verifiedResult) {
      return false;
    }

    const addrForm: FormGroup = this.contactService.contactForm.controls[this.address.formGroupName] as FormGroup;
    const stateId = this.contactUtility.getStateId(addrForm.controls['addressState'].value);

    if (addrForm.controls['line1'].value !== this.concatVerifiedLines() ||
      addrForm.controls['city'].value !== this.verifiedResult.city ||
      stateId !== this.verifiedResult.state_stateId ||
      addrForm.controls['zipcode5'].value !== this.verifiedResult.zipcode5.toString()) {
      doesMatch = false;
    }

    return doesMatch;
  }

  mergeAddressLines(): IContactMechanismAddress {
    let obj: IContactMechanismAddress = cloneDeep(this.address);

    if (obj.line2) {
      obj.line1 = obj.line1.concat('\r', obj.line2);
    }
    if (obj.line3) {
      obj.line1 = obj.line1.concat('\r', obj.line3);
    }

    if (obj.zipcode4 && obj.zipcode5) {
      obj.zipcode5 = obj.zipcode5.toString().concat('-', obj.zipcode4.toString());
    }
    return obj;
  }

  setKeyLoadedTrue(key: string): void {
    if (this.overrideWatch.hasOwnProperty(key)) {
      this.overrideWatch[key] = true;
    }
  }

  overridesComplete(): boolean {
    return (this.overrideWatch.addressState === true
      && this.overrideWatch.city === true
      && this.overrideWatch.line1 === true
      && this.overrideWatch.zipcode5 === true
      && this.overrideWatch.yearBuilt === true
    );
  }

  public  deleteRecord(): void {
    //if id > 0, db delete request
    //else, remove object from form
    let data: IDeleteContactMechanism = {
      mechanism: this.contactConstantsService.contactMechanismCategoryAddress,
      type: this.address.addressType,
      groupName: this.address.formGroupName,
      id: this.address.contactMechanismAddressId,
      url: this.operation === this.myConstants.operationTypeCreate ? '' : this.contactService.getDeleteUrl(this.contactConstantsService.contactMechanismCategoryAddress, this.address)
    };
    this.definitions$.next(this.staticMetaDataService.clearValidationMessages(this.definitions$.getValue(), this.displayFields) );
    let event: IHomEventEmitter = { requestor: this.contactConstantsService.requestorTypeContactAddress, event: this.myConstants.emitterEventDeleteFormGroup, action: '', data: data };
    this.deleteEvent.emit(event);
  }

  handlePrimaryChanged(val: any) {
    if ( !this.haveSubscribes) {
      return;
    }

    if (val) {
      let obj: IValueChanged = { key: 'isPrimary', value: val };
      let data = {
        mechanism: this.contactConstantsService.contactMechanismCategoryAddress,
        groupName: this.address.formGroupName,
        id: this.address.addressId,
        valueChanged: obj
      };

      //only emit on true
      let event: IHomEventEmitter = { requestor: this.contactConstantsService.requestorTypeContactAddress, event: this.myConstants.emitterEventValueChanged, action: '', data: data };
      this.emitterService.emitContactEvent(event);
    }
  }

  handleOverrideChange(val: any) {
    this.setVerified(val === true ? 'verified' : '');
    this.setOverridden();

    if (val) {
      this.isMapped$.next(false);
      this.failedMapping$.next(false);
    }
    let event: IHomEventEmitter = {
      requestor: this.contactConstantsService.requestorTypeContactAddress,
      event: ContactEvent.isContactValid,
      action: '',
      data: val
    };
    this.emitterService.emitContactEvent(event);
  }

  requiredFieldsFilled(): boolean {
    const addrForm: FormGroup = this.contactService.contactForm.controls[this.address.formGroupName] as FormGroup;
    const formValue = addrForm.getRawValue();
    const stateId = this.contactUtility.getStateId(formValue['addressState']);
    if ((formValue.line1 && formValue.zipcode5 && addrForm.controls['line1'].valid && addrForm.controls['zipcode5'].valid)
      || (formValue.line1 && formValue.city && stateId > 0 && addrForm.controls['city'].valid && addrForm.controls['addressState'].valid)) {
      return true;
    }
    return false;
  }

  setPrimary() {
    //strip off the string part of the group name and will just have a number;
    const index = +this.address.formGroupName.replace(this.contactConstantsService.addressFormPrefix, '');
    if (this.activePrimary !== index) {
      const control = this.contactService.contactForm.controls[this.address.formGroupName].get('isPrimary');
      control.setValue(false);
    }
  }

  public verifyAddress(): void {
    this.loading$.next(true);
    let address: IContactMechanismAddress = this.contactService.contactForm.controls[this.address.formGroupName].value;
    this.verifiedResult = null;
    this.failedMapping$.next(false);
    this.isMapped$.next(false);

    const lines: IAddressLines = this.contactUtility.getAddressLines(address.line1);
    const zips: IAddressZips = this.contactUtility.getAddressZips(address.zipcode5);
    const model: IAddress = {
      addressId: address.addressId,
      line1: lines.line1,
      line2: lines.line2,
      line3: lines.line3,
      city: address.city,
      state_stateId: this.contactUtility.getStateId(address.addressState),
      stateAbbr: address.addressState === null ? '' : address.addressState['stateAbbr'],
      zipcode5: !zips ? '' : zips.zip5.toString(),
      zipcode4: !zips ? '' : zips.zip4 ? zips.zip4.toString() : null
    }
    this.unverifiedAddress = model;

    //local only - no store involved
    this.subscription.add(this.domainObjectService.updateByMethod('SanitizedAddress', 'Search', model)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          let result: ISanitizedAddress = response.data;
          this.verifiedResult = response.data;
          this.handleResults(true);
        } else {
          this.handleResults(false);
        }
        this.loading$.next(false);

      }));
  }

  handleResults(success: boolean): void {
    if (!success) {
      this.failedMapping$.next(true);
      this.isMapped$.next(false);
      return;
    }

    this.badAddress = false;

    if (this.verifiedResult.addressFound) {

      this.setVerified('verified');
      this.overrideFormValues();
      this.selectedNo = false;
      let event: IHomEventEmitter = {
        requestor: this.contactConstantsService.requestorTypeContactAddress,
        event: ContactEvent.isContactValid,
        action: '',
        data: true
      };
      this.emitterService.emitContactEvent(event);
    } else if (!this.verifiedResult.addressFound && this.verifiedResult.isMatched) {
      this.showResults$.next(true);
    } else if (this.verifiedResult.line1 && this.verifiedResult.city && this.verifiedResult.state && this.verifiedResult.zipcode5) {
      this.showResults$.next(true);
    } else {
      this.showResults$.next(true);
      this.badAddress = true;
    }
  }


  initOverrideWatch() {
    this.overrideWatch.addressState =
      this.overrideWatch.city =
      this.overrideWatch.line1 =
      this.overrideWatch.zipcode5 =
    this.overrideWatch.yearBuilt = false;
  }

  overrideFormValues() {
    this.isLoading = true;
    this.initOverrideWatch();
    this.setVerified('verified');

    let addr = this.concatVerifiedLines();
    let zip: string = this.verifiedResult.zipcode5.toString();
    if (this.verifiedResult.zipcode4) {
      zip = zip.concat('-', this.verifiedResult.zipcode4.toString());
    }
    this.contactService.contactForm.controls[this.address.formGroupName]['controls']['line1'].setValue(addr);
    this.contactService.contactForm.controls[this.address.formGroupName]['controls']['city'].setValue(this.verifiedResult.city);
    let val = this.addressStates.find(x => x.stateId === this.verifiedResult.state_stateId);
    this.contactService.contactForm.controls[this.address.formGroupName]['controls']['addressState'].setValue(val);
    this.contactService.contactForm.controls[this.address.formGroupName]['controls']['zipcode5'].setValue(zip);
    if (this.verifiedResult.yearBuilt) {
      this.contactService.contactForm.controls[this.address.formGroupName]['controls']['yearBuilt'].setValue(this.verifiedResult.yearBuilt);
    } else {
      this.overrideWatch.yearBuilt = true;
    }
    this.contactService.contactForm.controls[this.address.formGroupName]['controls']['latitude'].setValue(this.verifiedResult.latitude);
    this.contactService.contactForm.controls[this.address.formGroupName]['controls']['longitude'].setValue(this.verifiedResult.longitude);
    this.isMapped$.next(true);
  }

  setVerified(val: string): void {
    const setVal: string = this.isOverridden$.value || this.isMapped$.getValue() ? 'verified' : val;
    this.contactService.contactForm.controls[this.address.formGroupName]['controls'][this.verifiedControlName].setValue(setVal);
  }

  concatVerifiedLines(): string {
    if (!this.verifiedResult) {
      return '';
    }
    let addr = this.verifiedResult.line1;
    if (this.verifiedResult.line2) {
      addr = addr.concat('\r', this.verifiedResult.line2);
    }
    if (this.verifiedResult.line3) {
      addr = addr.concat('\r', this.verifiedResult.line3);
    }

    return addr;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['requestTime'] && !(changes['requestTime'].firstChange)) {
      this.initForm();
      this.isLoading = false;
    }

    if (changes['activePrimary'] && !(changes['activePrimary'].firstChange)) {
      this.setPrimary();
    }

    if (changes['operation'] && !(changes['operation'].firstChange)) {
      if (changes['operation'].currentValue !== this.myConstants.operationTypeDetails) {
        this.focusLine1$.next(true);
      }
      if (changes['operation'].currentValue === this.myConstants.operationTypeCreate
        && changes['operation'].previousValue === this.myConstants.operationTypeDetails) {
        this.contactService.contactForm.controls[this.address.formGroupName].markAsDirty();
      }
    }

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
