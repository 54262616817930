<div class="modal-cast" *ngIf="modalService._opened"></div>
<div class="modal {{'modal--' + (modalService._definition ? modalService._definition.sizeType || 'medium' : 'medium')}}"
  [ngClass]="{  'modal--opened': modalService._opened, 'loading-mask': modalService._definition && modalService._definition.showLoading && loading$ | async }">
  <div class="modal__header">
    <h2 class="modal__header__title">{{ modalService._definition ? modalService._definition.title : '' }}</h2>
    <div class="modal__header__exit" (click)="modalService.close()">
      <div class="modal__header__exit__line"></div>
      <div class="modal__header__exit__line"></div>
    </div>
  </div>
  <div class="modal__content">
    <div class="modal__content__overflow" [ngClass]="{'modal__content__overflow--no-tabs': modalService._definition && !modalService._definition.hasTabs && modalService._definition.sizeType !== 'full'}">
      <router-outlet name="modal"></router-outlet>
    </div>
  </div>
</div>

<div class="modal-cast" *ngIf="modalService._opened2"></div>
<div class="modal {{'modal--' + (modalService._definition2 ? modalService._definition2.sizeType || 'medium' : 'medium')}}"
     [ngClass]="{'modal--opened': modalService._opened2,  'loading-mask': modalService._definition2 && modalService._definition2.showLoading && loading$ | async }">
  <div class="modal__header">
    <h2 class="modal__header__title">{{ modalService._definition2 ? modalService._definition2.title : '' }}</h2>
    <div class="modal__header__exit" (click)="modalService.close()">
      <div class="modal__header__exit__line"></div>
      <div class="modal__header__exit__line"></div>
    </div>
  </div>
  <div class="modal__content">
    <div class="modal__content__overflow" [ngClass]="{'modal__content__overflow--no-tabs': modalService._definition2 && !modalService._definition2.hasTabs && modalService._definition2.sizeType !== 'full'}">
      <router-outlet name="modal2"></router-outlet>
    </div>
  </div>
</div>


