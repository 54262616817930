import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FwModule } from '../../fw/fw.module';
import { SharedModule } from '../../shared/shared.module';
import { FwSharedModule } from '../../fw/fw-shared/fw-shared.module';

//services
import { FileViewerService } from './services/file-viewer.service';
import { FileUploaderService } from './services/file-uploader.service';


//components
import {
  FilePreviewItemComponent,
  FilePreviewerComponent,
  FileUploaderComponent,
  FileViewerCarouselComponent,
  ProjectFilesComponent,
  FileViewerDetailComponent,
  FileViewerThumbnailsComponent,
  SingleFileUploaderComponent
} from './components/index';

//containers
import {
  FileViewerContainerComponent,
  FileUploadManagerComponent
 } from './containers/index';


@NgModule({
  declarations: [
    FilePreviewItemComponent,
    FilePreviewerComponent,
    FileUploaderComponent,
    FileViewerCarouselComponent,
    ProjectFilesComponent,
    FileViewerDetailComponent,
    FileViewerThumbnailsComponent,
    FileViewerContainerComponent,
    SingleFileUploaderComponent,
    FileUploadManagerComponent
],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FwSharedModule,
    FwModule,
    SharedModule
  ],
  exports: [
    FilePreviewerComponent,
    FileUploaderComponent,
    FileViewerCarouselComponent,
    ProjectFilesComponent,
    FileViewerDetailComponent,
    FileViewerThumbnailsComponent,
    FileViewerContainerComponent,
    SingleFileUploaderComponent,
    FileUploadManagerComponent
 ]
})
export class FileUploadModule {
  static forRoot(): ModuleWithProviders<FileUploadModule> {
    return {
      ngModule: FileUploadModule,
      providers: [FileViewerService, FileUploaderService]
    };
  }
}
