import { Component, OnInit, Input,  Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { ScheduleEvent, ScheduleStore } from '../../enums/schedule.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { ISchedule } from '../../../view-models';
import { MetaDataService } from '../../../../../fw/dynamic-list/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { HomCommonUtility } from '../../../../../shared/services';
import { CommunicationEventService } from "../../../portal-shared/services/communication-event.service";

@Component({
  selector: 'schedule',
  templateUrl: './schedule.component.html',
  providers: [MetaDataService]
})
export class ScheduleComponent implements OnInit {
  @Input() schedule: ISchedule;
  @Input() showButtons: boolean;
  @Input() canIEdit: boolean;
  @Input() canIDelete: boolean;
  @Input() unavailableReason: string = '';
  @Input() customCardCss: string = '';
  @Input() customIconCss: string = '';
  @Input() providerLocationId: number = 0;
  @Input() moveUpVisible: boolean = false;
  @Input() miniMe: boolean = false;
  @Input() nonWorking: boolean = false;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public form: FormGroup;
  public editSchedule: string;
  public deleteSchedule: string;
  public showMoveUp: string;
  constructor(public mds: MetaDataService,
    public modalService: ModalService,
    public homCommonUtility: HomCommonUtility,
    public communicationEventService: CommunicationEventService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public request(eventType: string): void {
    const emitter: IHomEventEmitter = { requestor: 'schedule', event: eventType, action: '', data: this.schedule.scheduleId };
    this.customEvent.emit(emitter);
  }

  public onCustom(event: IHomEventEmitter): void {
    this.customEvent.emit(event);
  }

  ngOnInit() {
    this.editSchedule = ScheduleEvent.editSchedule;
    this.deleteSchedule = ScheduleEvent.deleteSchedule;
    this.showMoveUp = ScheduleEvent.showMoveUp;
    this.mds.setFieldDefinitions(ScheduleStore.schedules);
  }

}
