import { Component, OnInit, OnDestroy,Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getSelectedRecord } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import {  DocumentTypeActionTypeName, FileUploadType } from '../../../../file-upload/enums/file-upload.enums';
import { ActivatedRoute, Router } from '@angular/router';
import { IEntityDocument, IEntityDocumentActionViewModel } from '../../../view-models/index_two';
import { IInputButton } from '../../../../../fw/fw-shared/interfaces';
import { ProjectListStore } from '../../../project/enums/project.enums';
import { ScheduleStore } from '../../../scheduler/enums/schedule.enums';

import { RequiredDocumentService } from '../../services';

@Component({
  selector: 'document-upload-manager',
  templateUrl: './document-upload-manager.component.html',
})
export class DocumentUploadManagerComponent implements OnInit, OnDestroy {
  public otherActions: IEntityDocumentActionViewModel[] = [];
  public cbButtons: IInputButton[] = [];
  selectedActionIds: number[] = [];
  entityDocumentId: number;
  parentId: number;
  entityDocumentActionId: number;
  uploadType: string; //FileUploadType
  entityDocument: IEntityDocument;
  subscription: Subscription = new Subscription();

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public store: Store<fromFeature.IAllDynamicData>,
    public rds: RequiredDocumentService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.entityDocumentId = +paramMap.get('id');
      this.parentId = +paramMap.get('parentId');
      this.entityDocumentActionId = +paramMap.get('actionId');
      this.uploadType = paramMap.get('type');
      this.newRequest();
    });
  }

  public onCancel(): void {
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  public onSelect(actionName: string, value: any): void {
    //add to list of selected
    var selectedAction = this.otherActions.find(x => x.actionName == actionName);
    if (selectedAction) {
      const indx = this.selectedActionIds.findIndex(x => x === selectedAction.entityDocumentActionId);
      if (indx === -1 && value) {
        this.selectedActionIds.push(selectedAction.entityDocumentActionId);
      }
      else if (indx > -1 && !value) {
          this.selectedActionIds.splice(indx, 1);
      }
    }
  }

  public onNext(): void {
    this.router.navigate([{ outlets: { 'modal': `file-upload/${this.entityDocumentId}/${this.parentId}/${this.entityDocumentActionId}/${this.uploadType}/${this.selectedActionIds.toString()}` } }],
      { relativeTo: this.activeRoute.parent });
  }

  newRequest(): void {
    const storeName: string = this.uploadType === FileUploadType.poMeasure
      ? ScheduleStore.scheduleMeasures
      : ProjectListStore.projectEntityDocuments;
    //get the entity document record
    this.subscription.add(this.store.pipe(select(getSelectedRecord(storeName, this.parentId, 'entityDocumentId', this.entityDocumentId)))
      .subscribe((row: IEntityDocument) => {
        this.entityDocument = row;
       //check to see if it has any actions outside of this one that need to be completed
        this.otherActions = this.entityDocument && this.entityDocument.actions
          ? this.entityDocument.actions.filter(x => x.entityDocumentActionId !== this.entityDocumentActionId
            && x.actionTypeName !== DocumentTypeActionTypeName.automatic
          )
          : null
        if (this.otherActions) {
          this.configureCheckBoxes();
        }
      }));
  }

  configureCheckBoxes(): void {
    this.cbButtons = [];
    this.otherActions.forEach(x => {
      if (x.actionTypeName !== DocumentTypeActionTypeName.generateFile) {
        this.cbButtons.push({
          label: {
            label: x.actionName,
            alignRight: true,
            title: 'Click to Complete ' + x.actionName
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
