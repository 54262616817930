<div class="portal">
  <div class="portal__detail--item">
    <fw-dynamic-list *ngIf="listDefinition$ | async"
                     [listTitle]="'My Unreceived Inventory'"
                     [vmListDefinition]="listDefinition$ | async"
                     (selectEvent)="onSelect($event)"
                    (pageEvent)="onPage($event)">
    </fw-dynamic-list>
    <div class="app-btn-box">
      <button class="app-btn app-btn--primary"
              type="button"
              (click)="receiveInventory()"
              [disabled]="!selectedItems.length">
        Receive Selected
      </button>
    </div>

    <!--<fw-error-box *ngIf="errors" [errors]="errors" [ignoreKey]="true"></fw-error-box>-->
  </div>
</div>
