/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit,  ChangeDetectionStrategy,  Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IResponseBase } from '../../../../../shared/interfaces';
import { IListFilter, ListFilter, OrderTerm } from '../../../../../fw/dynamic-list/interfaces';
import { IInstallerWorkCrew } from '../../../view-models';

import { UserPriviledgesService } from '../../../../../auth/services';
import { DomainObjectService } from '../../../../../shared/services';

//Component is routed to
@Component({
  selector: 'installer-detail-summary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './installer-detail-summary.component.html'
})
export class InstallerDetailSummaryComponent implements OnInit, OnDestroy {
  public installerId: number;
  public includeOldestRequirement: boolean = true;
  public canIManageInstaller: boolean = false;
  public providerId: number;
  public workCrews$: BehaviorSubject<IInstallerWorkCrew[]> = new BehaviorSubject(null);

  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public userPriviledgesService: UserPriviledgesService,
    public domainObjectService: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants  ) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.installerId = +paramMap.get('id');
      this.workCrews$.next(null);
      this.getWorkCrewSelectList();
    });

    this.providerId = this.userPriviledgesService.providerId$.value;
    this.canIManageInstaller = this.userPriviledgesService.canManageInstallers$.value;
  }

  getWorkCrewSelectList(): void {
    let listFilter: IListFilter = new ListFilter();
    listFilter.orderTerm = [new OrderTerm('installerWorkCrewName')];
    listFilter.getAll = true;
    this.subscription.add(this.domainObjectService.getByMethodById('InstallerWorkCrew', 'ByInstaller', this.installerId, listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.workCrews$.next(response.data);
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
