<div [ngClass]="{ 'loading-mask': loading$ | async }">
  <div *ngIf="(mds.mdsReady$ | async)" class="app-panel">
    <div class="app-panel__content">
      <div class="form-wrapper">
        <ng-content></ng-content>
        <fw-error-box [errorData]="errorData$ | async" [ignoreKey]="ignoreKeyOnError"></fw-error-box>
      </div>
    </div>
  </div>
</div>
