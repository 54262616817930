<div class="tabs-wrapper">
  <div class="tabs" #tabContainer (dragenter)="dragEnter($event)" (dragover)="dragOver($event)">
    <div #tabElem *ngFor="let tab of !screenService.isSmall() ? tabs : getActive(); let i = index;"
         class="tab {{ (tab.tabStyle.cssName || '') + (tab.active ? 'tab--active' : '') }}"
         draggable="true"
         [id]="i" 
         [ngStyle]="{'left.px': -(i * 5)}"
         (dragstart)="dragStart($event)"
         (dragend)="dragEnd($event, i, tabElem)">
      <div class="tab__side">
        <div class="tab__side__inner"></div>
      </div>
      <div class="tab__conts">
        <i *ngIf="userRecentsService.isWorkerItem(tab.entityId, tab.portalEntityId)"
           class="fal fa-spinner tab__conts__spinner app-btn-icon--{{ tab.active ? 'primary' : 'grey-light-3' }}" ></i>
        <i *ngIf="tab.canBookmark && !userRecentsService.isWorkerItem(tab.entityId, tab.portalEntityId)"
           class="{{ !tab.isBookmarked ? 'far fa-bookmark app-icon--grey-light-3' : 'fas fa-bookmark app-icon--primary' }} tab__conts__btn"
           title="{{ (tab.isBookmarked ? 'Remove' : 'Add') + ' bookmark for ' + tab.tabStyle.label }}"
           (click)="setBookmark(tab)"></i>
        <i *ngIf="tab.tabStyle.icon"
           class="{{ tab.tabStyle.icon }} tab__conts__icon {{ tab.tabStyle.iconCss || 'tab__conts__icon--default' }}"
           title="{{ tab.tabStyle.iconTitle }}" ></i>
        <div class="tab__conts__label"
             title="{{tab.tabStyle.label}}"
             (click)="activateTab(i)">
          {{ tab.active ? (activeTab$ | async).tabStyle.label : tab.tabStyle.label }}
        </div>
        <i *ngIf="tab.closeable"
           class="far fa-times tab__conts__exit"
           title="Remove tab"
           (click)="removeTab(i)"></i>
      </div>
      <div class="tab__side">
        <div class="tab__side__inner">
          <div *ngIf="tabs[i+1] ? !tabs[i+1].active : !tab.active" class="tab__side__inner__sep" ></div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #burgerMenu>
    <div class="menu" [ngClass]="{'menu--active': toggled, 'menu--level2': level !== 1}">
      <div class="menu__item">{{ getActiveLabel() }}</div>
      <div *ngFor="let tab of tabs; let i = index;"
           class="menu__item"
            title="{{tab.tabStyle.label}}"
           [ngClass]="{'menu__item--hidden': tab.active}"
           (click)="activateTab(i)">{{ tab.tabStyle.label }}</div>
    </div>
  </ng-template>
  <div *ngIf="showBurger() || (tabs.length > 1 && screenService.isSmall())"
       class="burger {{ 'burger--level'.concat(level)}}"
       [ngClass]="{'burger--toggled': toggled,  'burger--toggled--level2': level !== 1 && toggled}"
       fw-popover
       [template]="burgerMenu"
       [closeOnClickOutside]="true"
       [showPopover]="toggled"
       [position]="'screen-right'"
       (onShow)="showBurgerMenu()"
       (onHide)="hideBurgerMenu()">
    <div class="burger__lines" title="View All Tab Items">
      <div class="burger__lines__line"></div>
      <div class="burger__lines__line"></div>
      <div class="burger__lines__line"></div>
    </div>
  </div>
</div>


