import { Component,  Input, OnInit, OnChanges, SimpleChanges, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash'; 
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import {  IValueChanged } from '../../../../fw/dynamic-list/services/index'
import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IFieldDefinition } from '../../../../fw/dynamic-forms';
import { ContactManagerService, ContactConstantsService } from '../../services/index';
import { StaticMetaDataService } from '../../../../fw/dynamic-list/services/static-meta-data.service';
import { ContactMechanismEmail } from '../../../portals/view-models';
import { IAddFormGroup, IDeleteContactMechanism } from '../../../portals/portal-shared/interfaces';

@Component({
  selector: 'contact-email',
  templateUrl: './contact-email.component.html'
})
export class ContactEmailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fieldDefinitions: IFieldDefinition[];
  @Input() email: ContactMechanismEmail;
  @Input() operation: string;
  @Input() displayType: string;
  @Input() requestTime: string; //to handle subsequent create request for the same contact type (id never changes)
  @Input() activePreferred: number = -1;
  @Input() canIEdit: boolean;

  @Output() public deleteEvent = new EventEmitter<IHomEventEmitter>();

  public displayFields = ['isPreferred', 'emailAddress', 'contactMechanismEmailId', 'emailId', 'emailType', 'emailTypeId'];
  public definitions: IFieldDefinition[];
  public focusOn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  emailStore: string = 'emails';
  subscription: Subscription = new Subscription();
  haveSubscribes: boolean = false;

  constructor(
    public contactService: ContactManagerService,
    public contactConstantsService: ContactConstantsService,
    public metaDataService: StaticMetaDataService,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) {
  }
  

  ngOnInit() {
    this.definitions = cloneDeep(this.fieldDefinitions);
    this.definitions.find(d => d.key === 'emailAddress').label = this.email.uiLabel;
    this.initForm();
  }

  initForm() {
    const form = this.metaDataService.createDynamicFormGroup(this.definitions, this.displayFields, this.email, this.operation);

    let data: IAddFormGroup = { groupName: this.email.formGroupName, formGroup: form };
    this.contactService.contactForm.addControl(data.groupName, data.formGroup);
    this.contactService.contactForm.controls[this.email.formGroupName].updateValueAndValidity({ onlySelf: true });
    this.focusOn$.next(this.operation === this.myConstants.operationTypeEdit ? true : false);
   this.setSubscribes();
  }

  setSubscribes(): void {
    this.subscription.add(this.contactService.contactForm.controls[this.email.formGroupName].get('isPreferred').valueChanges.subscribe(val => {
      this.handlePreferredChanged(val);
    }));
  }

  deleteRecord(): void {
    //if id > 0, db delete request
    //else, remove object from form
    let data: IDeleteContactMechanism = {
      mechanism: this.contactConstantsService.contactMechanismCategoryEmail,
      type: this.email.emailType,
      groupName: this.email.formGroupName,
      id: this.email.contactMechanismEmailId,
      url: this.operation === this.myConstants.operationTypeCreate ? '' : this.contactService.getDeleteUrl(this.contactConstantsService.contactMechanismCategoryEmail, this.email)
    };

    let event: IHomEventEmitter = { requestor: this.contactConstantsService.requestorTypeContactEmail, event: this.myConstants.emitterEventDeleteFormGroup, action: '', data: data };
    this.deleteEvent.emit(event);
  }


  handlePreferredChanged(val: any) {
    let valChanged: IValueChanged = { key: 'isPreferred', value: val };
    let data = {
      mechanism: this.contactConstantsService.contactMechanismCategoryEmail,
      groupName: this.email.formGroupName,
      id: this.email.emailId,
      valueChanged: valChanged
    };
    if (data.valueChanged.value) {
          //only emit on true
      let event: IHomEventEmitter = { requestor: this.contactConstantsService.requestorTypeContactEmail, event: this.myConstants.emitterEventValueChanged, action: '', data: data };
          this.emitterService.emitContactEvent(event);
      }
  }

    setPreferred() {
    //strip off the string part of the group name and will just have a number;
      const index = this.contactService.getIndex(this.contactConstantsService.emailFormPrefix, this.email.formGroupName)
    if (this.activePreferred !== index) {
      const control = this.contactService.contactForm.controls[this.email.formGroupName].get('isPreferred');
      control.setValue(false);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['requestTime'] && !(changes['requestTime'].firstChange)) {
      this.initForm();
    }
    if (changes['activePreferred'] && !(changes['activePreferred'].firstChange)) {
       this.setPreferred();
    }
    if (changes['operation'] && !(changes['operation'].firstChange)) {
      if (changes['operation'].currentValue === this.myConstants.operationTypeEdit) {
        this.focusOn$.next(true);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
