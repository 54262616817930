/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IWorkOrder, IService, IProject, IProviderLocation } from '../../../view-models/index';
import { IErrorData, IApprovalQueueTransaction } from '../../../../../shared/interfaces/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import { IListFilter, ListFilter, IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { UpdateObjectByIdModel, IObjectData } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IDetailRequest } from '../../../../../fw/dynamic-detail/interfaces';
import { ProjectObjectStore, ProjectListStore } from '../../enums/project.enums';

import { ProjectService } from '../../services/project.service';
import { UserPriviledgesService } from '../../../../../auth/services/index';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as fromDynamicObject from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as DynamicObjectActions from '../../../../../fw/dynamic-list/store/actions/dynamic-object.actions';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { getObjectDataById, getObjectErrorsById } from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { getSelectionListDataByType, GetEntityListById } from '../../../../../shared/store/selectionLists/index';
import { GetList } from '../../../../../fw/dynamic-list/store';
import { HomDataUtility } from '../../../../../shared/services';


@Component({
  selector: 'work-order-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './work-order-manager.component.html'
})
export class WorkOrderManagerComponent implements OnInit, OnDestroy {

  public workOrderId: number;
  public woDisplayFields: string[] = ['workOrderId', 'providerLocation', 'service', 'scheduleLocked', 'startDate', 'workOrderItemTotal'];
  public woOperation$: BehaviorSubject<string> = new BehaviorSubject('');
  public providerLocations$: BehaviorSubject<IProviderLocation[]> = new BehaviorSubject(null);
  public services$: BehaviorSubject<IService[]> = new BehaviorSubject(null);
  public workOrder$: BehaviorSubject<IWorkOrder> = new BehaviorSubject(null);
  public woItemCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  public aqtChangesCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  public hasStaleData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canPushToAccounting$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEdit$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEditWo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEditProject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEditSansStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorData$: Observable<IErrorData[]>;
  public customError$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public hasPendingCreate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public storeName: string;
  projectId: number;
  storeKeyName: string = 'workOrderId';
  subscription: Subscription = new Subscription();
  svcSub: Subscription;

  constructor(public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromFeature.IAllDynamicData>,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public projectService: ProjectService,
    public dataUtils: HomDataUtility) { }

  //get the services for this location
  public onLocationChange(event: { id: number, listFilter: IListFilter }): void {
    this.services$.next([]);
    if (event.id && event.id > 0) {
      this.getServicesByProviderLocation(event.id, event.listFilter);
    }
  }

  //custom wo general information events
  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case ButtonType.edit:
        this.woOperation$.next(this.myConstants.operationTypeEdit);
        break;
      case ButtonType.reload:
        this.reloadWoMgr();
        break;
      case ButtonType.save:
        if (event.data instanceof UpdateObjectByIdModel) {
          this.store.dispatch(new DynamicObjectActions.UpdateObjectByIdObj({ updateData: event.data }));
        }
        break;
      case ButtonType.cancel:
        this.store.dispatch(new DynamicObjectActions.ClearEventObj({ storeName: this.storeName, objectId: this.workOrderId }));
        this.woOperation$.next(this.myConstants.operationTypeDetails);
        break;
      default:
        break;
    }
  }

  public onScheduleChange(event: IHomEventEmitter): void {
    this.projectService.dispatchWorkOrders('work-order-manager', this.projectId, this.canIEditProject$.value);
    this.projectService.dispatchRequiredDocumentsGet(this.projectId, this.canIEditSansStatus$.value);
    let objectRequest: IDetailRequest = this.projectService.loadWorkOrderObjectRequest(this.workOrderId, this.projectService.woDisplayFields);
    this.store.dispatch(new DynamicObjectActions.GetObject({ detailRequest: objectRequest }));
  }

  ngOnInit() {
    this.woOperation$ = new BehaviorSubject(this.myConstants.operationTypeDetails);
    this.hasStaleData$ = new BehaviorSubject(false);
    this.storeName = ProjectObjectStore.workOrderInformation;

    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get('portalEntityId');
      this.getProject();
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.workOrderId = +paramMap.get('id');
      this.getData();
    });

    this.subscription.add(this.workOrder$.subscribe((wo: IWorkOrder) => {
      this.canPushToAccounting$.next(wo && wo.workOrderStatusText === 'To Be Scheduled' && this.woItemCount$.getValue() > 0);
      this.hasPendingCreate$.next(this.dataUtils.hasPendingChildCreate(wo));
    }));

    this.subscription.add(this.woItemCount$.subscribe((count: number) => {
      const wo: IWorkOrder = this.workOrder$.getValue();
      this.canPushToAccounting$.next(!wo ? false : wo.workOrderStatusText === 'To Be Scheduled' && count > 0);
    }));

    //multiple objservable dependencies will determine editability
    this.subscription.add(this.canIEditProject$.subscribe(val => {
      this.canIEdit$.next(this.canIEditWo$.getValue() && val);
    }));
    this.subscription.add(this.canIEditWo$.subscribe(val => {
      this.canIEdit$.next(this.canIEditProject$.getValue() && val);
    }));

    //listen for change to project work orders, if any, then need to reload this work order.
    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectWorkOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((objData) => {
        if (objData && objData.data && !objData.working) {
          if (objData.event && objData.event.event == this.myConstants.emitterEventListReloadPortal
            && (!objData.errorData || !objData.errorData.length)) {
            //reload wo object and  wo items
            this.reloadWoMgr();
          }
        }
      })
    );
  }

  //get wo data from store
  getData(): void {
    this.subscription.add(this.store.pipe(
      select(fromDynamicObject.getObjectDataById(ProjectObjectStore.workOrderInformation, this.workOrderId)))
      .subscribe((objData: IWorkOrder) => {
        const wo: IWorkOrder = cloneDeep(objData);
        if (wo) {
          if (!wo.workOrderId) {
            //SECURITY ERROR
            let errorData: IErrorData[] = [{ key: 'modal', value: ['Security Access Error.  Contact Support.'] }];
            this.customError$.next(errorData);
          } else {
            this.canIEditWo$.next(wo && this.ups.canEditWorkOrder(wo));
            this.workOrder$.next(wo)
          }
        };
      }));

    this.errorData$ = this.store.pipe(
      select(getObjectErrorsById(ProjectObjectStore.workOrderInformation, this.workOrderId)));

    this.subscription.add(this.store.pipe(
      select(fromDynamicObject.getObjectEventById(ProjectObjectStore.workOrderInformation, this.workOrderId)))
      .subscribe((event: IHomEventEmitter) => {
        if (event) {
          this.woOperation$.next(this.myConstants.operationTypeDetails);
          if (event.event === this.myConstants.emitterEventListReload) {
            this.projectService.dispatchPurchaseOrders('work-order-manager', this.projectId, this.canIEdit$.getValue());
          }
          this.store.dispatch(new DynamicObjectActions.ClearEventObj({ storeName: ProjectObjectStore.workOrderInformation, objectId: this.workOrderId }));
        }
      }));

    //listen for event changes to wo item store and reload this work order if receive any
    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.workOrderItems)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId === this.workOrderId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.data) {
          this.woItemCount$.next(state.data ? state.data.length : 0);
          if (state.event && !state.errorData.length) {
            //have an event and no errors, so update/create was successful, remit as close/complete and let parent container handle as needed 
            let objectRequest: IDetailRequest = this.projectService.loadWorkOrderObjectRequest(this.workOrderId, this.projectService.woDisplayFields);
            this.store.dispatch(new DynamicObjectActions.GetObject({ detailRequest: objectRequest }));
            this.projectService.dispatchWorkOrders('work-order-manager', this.projectId, this.canIEdit$.getValue());
          }
        }
      }));

    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectAQTChanges)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId === this.projectId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.data) {
          const data: IApprovalQueueTransaction[] = state && state.data ? state.data.filter(x => x['parentPrimaryKeyId'] == this.workOrderId) : null;
          this.aqtChangesCount$.next(data ? data.length : 0);
        }
      }));
  }

  getProject(): void {
    this.subscription.add(this.store.pipe(
      select(getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)))
      .subscribe((objData: IProject) => {
        if (objData) {
          this.canIEditProject$.next(this.ups.canIEdit(objData) && objData.currentProjectStatusCode !== this.myConstants.projectStatusClosed);
          this.canIEditSansStatus$.next(this.ups.canIEdit(objData));
          if (!this.providerLocations$.value && objData.branch_branchId) {
            this.getProviderLocationsByBranch(objData.branch_branchId);
          }
        }
      })
    );
  }

  getProviderLocationsByBranch(branchId: number): void {
    let listFilter = new ListFilter();
    listFilter.filterContext = 'WorkOrder';
    listFilter.filterFor = 'ProviderLocation'; //I should only see the provider locations I have acess to for this project
    this.providerLocations$.next(null);
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('projectProviderLocation', branchId)))
      .subscribe((data) => {
        if (data) {
          this.providerLocations$.next(data);
        } else {
          this.store.dispatch(new GetEntityListById('ProviderLocation', 'ByProjectBranch', branchId, listFilter, 'projectProviderLocation'));
        }
      }));
  }

  getServicesByProviderLocation(providerLocationId: number, listFilter: IListFilter): void {
    /* Subscribe to the services - triggered during change of provider location  */
    if (this.svcSub) {
      this.svcSub.unsubscribe();
    }
    this.svcSub = this.rootStore.pipe(select(getSelectionListDataByType('providerLocationService', providerLocationId)))
      .subscribe((data) => {
        if (data) {
          this.services$.next(data);
        } else {
          this.store.dispatch(new fromSelectionLists.GetEntityListById('Service', 'ByProviderLocation', providerLocationId, listFilter, 'providerLocationService'));
        }
      });
  }

  reloadWoMgr(): void {
    let objectRequest: IDetailRequest = this.projectService.loadWorkOrderObjectRequest(this.workOrderId, this.projectService.woDisplayFields);
    this.store.dispatch(new DynamicObjectActions.GetObject({ detailRequest: objectRequest }));
    const woItemListDef: IListDefinition = this.projectService.loadWorkOrderItemListDefinition(this.workOrderId, this.canIEdit$.value, false, false);
    this.store.dispatch(new GetList({
      listDefinition: woItemListDef,
      listFilter: woItemListDef.defaultListFilter, parentId: woItemListDef.parentId
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.svcSub) {
      this.svcSub.unsubscribe();
    }
  }

}
