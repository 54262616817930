<div class="portal">
  <div class="portal__detail--item" [ngClass]="{ 'loading-mask': !(entities$ | async) }">
    <div [ngClass]="{ 'loading-mask': !(entities$ | async) }">
      <div *ngIf="(listDefinition$ | async) && (entities$ | async)">
        <fw-dynamic-list
                  [listTitle]="'Project History'"
                  [vmListDefinition]="listDefinition$ | async"
                  (filterEvent)="onFilterChange($event)">
        </fw-dynamic-list>
      </div>
    </div>
  </div>
</div>


