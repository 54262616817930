import { Component, OnInit, ViewChild, Inject, forwardRef, ElementRef, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, Validator } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IMonth } from '../calendar/interfaces/i-month';
import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { HomCommonUtility } from '../../../../shared/services';

const CALENDAR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FwCalendarComponent),
  multi: true
};
const CALENDAR_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => FwCalendarComponent),
  multi: true
};
@Component({
  selector: 'fw-calendar',
  templateUrl: './fw-calendar.component.html',
  providers: [CALENDAR_VALUE_ACCESSOR, CALENDAR_VALIDATOR]
})
export class FwCalendarComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  constructor(
    @Inject(appConstants) public myConstants: IAppConstants,
    public utils: HomCommonUtility) {}

  @Input() dateValue: string;
  @Input() multiSelectDates: Date[] = [];
  @Input() name: string;
  @Input() label: string;
  @Input() isRequired: boolean;
  @Input() singlePicker: boolean;
  @Input() isMultiSelect: boolean;
  @Input() isDisabled: boolean;
  @Input() error: boolean;
  @Input() isReadOnly: boolean;
  @Input() placeHolder: string;
  @Input() customCss: any;
  @Input() test: boolean;
  @Input() isDateTime: boolean;

  //output only needed for containers that do not use dynamic-field/form.
  @Output() onSelected: EventEmitter<any> = new EventEmitter(); 

  @ViewChild('scrollcon', { read: ElementRef }) public scrollCon: ElementRef;
  @ViewChild('startElem', { read: ElementRef }) public startElem: ElementRef<HTMLInputElement>;
  @ViewChild('endElem', { read: ElementRef }) public endElem: ElementRef<HTMLInputElement>;
  
  scrollingInitialized: boolean;
  subscription: Subscription = new Subscription();

  public months: IMonth[];
  public selected: Date[];
  public today: Date;
  public isMobile: boolean = document.body.offsetWidth <= 500;
  public showCalendar$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showStartDate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showEndDate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public focused: boolean;
  public startDate: string = "";
  public endDate: string = "";
  public startFocused: boolean;
  public endFocused: boolean;
  public dateIsValid: boolean = true;
  public validationMessage: string = '';
  public isStartDate: boolean;
  public showSelections: boolean;
  
  onModelChange = (val: string) => {

  };

  onModelTouched: Function = () => { };

  registerOnChange(fn: (val: string) => void): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  writeValue(value: any): void {
    if (this.singlePicker && value) this.startDate = (this.isDateTime ? value.replace(',', ' ') : value);
  }

  validate({ value }: FormControl) {
    return (!this.dateIsValid || this.missingRequired()) && {
      invalid: true 
    }
  }

  ngOnInit(): void {
    const isMultiSelect = this.isMultiSelect;
    if (!isMultiSelect && (this.singlePicker && this.dateValue)) this.startDate = this.dateValue;
    const selected = !isMultiSelect ? [new Date()] : this.multiSelectDates;
    this.today = new Date();
    this.initMonths(!isMultiSelect ? selected[selected.length - 1] : new Date(new Date().getFullYear(), 0, 1));
    this.selected = selected;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isMultiSelect && changes['multiselectDates']) {
      if (JSON.stringify(this.multiSelectDates) !== JSON.stringify(this.selected)) {
        this.selected = this.multiSelectDates;
      }
    }
    if (changes['dateValue']) {
      if (this.dateValue) {
        if (this.singlePicker) {
          this.startDate = (this.isDateTime ? this.dateValue.replace(',', ' ') : this.dateValue);
        } else {
          this.startDate = this.dateValue.split(' - ')[0];
          this.endDate = this.dateValue.split(' - ')[1];
        }
      }
    }
  }

  public showOrHideSelections() {
    this.showSelections = !this.showSelections;
  }

  public removeSelection(item: Date) {
    this.selected = this.selected.filter(x => x.toLocaleDateString() !== item.toLocaleDateString()).sort((a, b) => {
      return <any>a - <any>b;
    });
  }

  public openCalendar(isStartDate: boolean): void {
    const val: string = this[isStartDate ? 'startDate' : 'endDate'];
    let arr: string[] = !val ? [] : val.split("/");
    const isMultiSelect: boolean = this.isMultiSelect;
    let selected: Date[] = [];
    if (!isMultiSelect) {
      if (this.singlePicker) {
        if (!this.utils.isValidDate(val)) arr = null;
      } else {
        const otherVal: string = this[isStartDate ? 'endDate' : 'start'];
        if (this.utils.isValidDate(val) && this.utils.isValidDate(otherVal)) arr = (val + " - " + otherVal).split(" - ").join("/").split("/");
        if (!this.utils.isValidDate(val) && !this.utils.isValidDate(otherVal)) arr = null;
        if (!this.utils.isValidDate(val) && this.utils.isValidDate(otherVal)) arr = otherVal.split("/");
        if (this.utils.isValidDate(val) && !this.utils.isValidDate(otherVal)) arr = val.split("/");
      }
      selected = arr === null ? [new Date()] : (() => {
        const firstSelection: Date = new Date(Number(arr[2]), Number(arr[0].split("")[0] === "0" ? arr[0].split("")[1] :
          arr[0]) - 1, Number(arr[1].split("")[0] === "0" ? arr[1].split("")[1] : arr[1]));
        return arr.length === 3 ? [firstSelection] : [firstSelection, new Date(Number(arr[5]), Number(arr[3].split("")[0] === "0" ?
          arr[3].split("")[1] : arr[3]) - 1, Number(arr[4].split("")[0] === "0" ? arr[4].split("")[1] : arr[4]))];
      })();
    } else {
      selected = this.multiSelectDates;
    }
    /*
    const selected: Date[] = arr === null ? [new Date()] : (() => {
      const firstSelection: Date = new Date(Number(arr[2]), Number(arr[0].split("")[0] === "0" ? arr[0].split("")[1] :
        arr[0]) - 1, Number(arr[1].split("")[0] === "0" ? arr[1].split("")[1] : arr[1]));
      return arr.length === 3 ? [firstSelection] : [firstSelection, new Date(Number(arr[5]), Number(arr[3].split("")[0] === "0" ?
        arr[3].split("")[1] : arr[3]) - 1, Number(arr[4].split("")[0] === "0" ? arr[4].split("")[1] : arr[4]))];
    })();
    */
    this.today = new Date();
    this.initMonths(!isMultiSelect ? selected[selected.length - 1] : new Date(new Date().getFullYear(), 0, 1));
    this.selected = selected;
    this[(this.isStartDate = isStartDate) ? 'showStartDate$' : 'showEndDate$'].next(true);
    let int = setInterval(() => {
      if (this.scrollCon) {
        this.scrollCon.nativeElement.scroll(0, (<HTMLDivElement>this.scrollCon.nativeElement.children[1]).offsetTop - 40);
        clearInterval(int);
      }
    });
  }
   
  public hideCalendar(): void {
    this[this.isStartDate ? 'showStartDate$' : 'showEndDate$'].next(false);
    this.showSelections = false;
    this.showCalendar$.next(false);
  }

  public incYear(inc: number): void {
    const months = this.months;
    this.initMonths(new Date(months[1].year + inc, months[1].index + 1, 0));
  }

  public select(day: Date): void {
    if (this.isMultiSelect) {
      if (this.selected.find(x => x.toLocaleDateString() === day.toLocaleDateString())) {
        this.selected = this.selected.filter(x => x.toLocaleDateString() !== day.toLocaleDateString()).sort((a, b) => {
          return <any>a - <any>b;
        });
      } else {
        this.selected.push(day);
        this.selected = this.selected.sort((a, b) => {
          return <any>a - <any>b;
        });
      }
      this.onSelected.emit(this.selected);
    } else {
      const selected: Date[] = this.selected;
      if (this.singlePicker) {
        !this.isSelected(day) ? this.selected = [day] : this.initSelection();
      } else {
        if (selected.length === 2) {
          this.selected = [day];
          return;
        }
        this.isSelected(day) ? this.selected.splice(selected.indexOf(day), 1) : !selected.length ? this.selected = [day] : selected.length < 2 ? this.selected[day.getTime() > selected[0].getTime() ? 'push' : 'unshift'](day) : void 0;
      }
    }
    
    
  }

  public initSelection(): void {
    if (this.isMultiSelect) {
      this.hideCalendar();
      return;
    }
    const selected = this.selected;
    if (this.singlePicker || selected.length === 2) {
      this.startDate = this.formatDate(selected[0]);
      if (selected.length > 1) {
        this.endDate = this.formatDate(selected[1]);
      }
      this.setValidator();
      if (this.dateIsValid) {
        const newVal = this.formatDate(selected[0]) + (selected.length === 1 ? "" : " - " + this.formatDate(selected[1]));
        this.onModelChange(newVal);
        this.onSelected.emit(newVal);
      }
      this.hideCalendar();
    }
  }

  public setMonth(month: number): void {
    month === this.months[1].index ? void 0 : this.initMonths(new Date(this.months[1].year, month + 1, 0));
  }
  // NEEDED TO SIMULATE FOCUS EFFECT ON INPUT DIV
  public onBlur(isStartDate: boolean): void {
    this[isStartDate ? 'startFocused' : 'endFocused'] = false;
  }
  
  // NEEDED TO SIMULATE FOCUS EFFECT ON INPUT DIV
  public onFocus(isStartDate: boolean): void {
    this[isStartDate ? 'startFocused' : 'endFocused'] = true;
  }

  public clearInput(isStartDate: boolean, reFocus: boolean = true): void {
    this[isStartDate ? 'startDate' : 'endDate'] = "";
    this.setValidator();
    if (this.dateIsValid) {
      //if field is required, will be invalid date
      this.onModelChange('');
      this.onSelected.emit("");
    }
    if (reFocus) {
      this[isStartDate ? 'startElem' : 'endElem'].nativeElement.focus();
    }
  }

  public onChange(): void {
   this.setValidator();
    if (this.dateIsValid) {
      const newVal = !this.singlePicker ? (this.startDate + " - " + this.endDate) : this.startDate;
      this.onModelChange(newVal);
      this.onSelected.emit(newVal);
    } else {
      if (!this.singlePicker) {
        this.onModelChange('');
        this.onSelected.emit('');
      }
    }
  }

  public createMonth(date: Date, mod: number): IMonth {
    const newDate: Date = new Date(date.getFullYear(), (date.getMonth() + 1) + mod, 0),
      year: number = newDate.getFullYear(),
      month: number = newDate.getMonth(),
      nextMonth: Date = new Date(year, month + 1, 1);
    return {
      name: nextMonth.toDateString().split(" ")[1].toUpperCase(),
      year: year,
      index: month,
      firstDay: new Date(year, month, 1).getDay(),
      nextFirst: nextMonth.getDay(),
      days: this.getDays(year, month)
    };
  }

  public getDays(year: number, month: number): Date[] {
    const arr: Date[] = [],
      len: number = new Date(year, month + 1, 0).getDate() + 1;
    let i: number = 1;
    for (; i < len; i++) arr.push(new Date(year, month, i));
    return arr;
  }

  public getDay(day: Date): number {
    return day.getDate();
  }

  public getToday(): void {
    const today = this.today;
    this.initMonths(today);
    this.selected = [today];
  }

  public clearRange(idx: number): void {
    this.selected.splice(idx, 1);
  }

  public initMonths(date: Date): void {
    if (date) this.months = [this.createMonth(date, -1), this.createMonth(date, 0), this.createMonth(date, 1)];
  }

  public isSelected(day: Date): boolean {
    if (this.isMultiSelect) {
      const selected = this.selected.find(x => x.toLocaleDateString() === day.toLocaleDateString()) ? true : false;
      //console.log("SELECTED: ", day, selected);
      return selected;
    } else {
      const selected = this.selected;
      return selected[0] && day.toLocaleDateString() === selected[0].toLocaleDateString() || selected[1] && day.toLocaleDateString() === selected[1].toLocaleDateString();
    }
  }

  public dateString(index: number): string {
    return !this.selected[index] ? !index ? "START DATE" : "END DATE" : this.selected[index].toDateString().split(" ").splice(1, 3).join(" ");
  }

  public isDay(day: number): boolean {
    const selected = this.selected;
    return selected[0] && day === selected[0].getDay() || selected[1] && day === selected[1].getDay();
  }

  public formatDate(date: Date): string {
    const parts: string[] = date.toISOString().substring(0, 10).split("-");
    return [parts[1], parts[2], parts[0]].join("/");
  }

  public inRange(day: Date): boolean {
    if (this.selected.length !== 2) return false;
    return day.getTime() > this.selected[0].getTime() && day.getTime() < this.selected[1].getTime();
  }

  public scrollCalendar(): void {
    if (this.scrollingInitialized) {
      const scrollCon: HTMLDivElement = this.scrollCon.nativeElement;
      const bool: boolean = scrollCon.scrollTop <= 40 ? false : scrollCon.scrollTop >= (<HTMLDivElement>scrollCon.children[2]).offsetTop - 120 ? true : null;
      if (bool !== null) {
        const months: IMonth[] = this.months,
          month: IMonth = months[bool ? 2 : 0];
        this.months = [
          bool ? months[1] : this.createMonth(new Date(month.year, month.index + 1, 0), -1),
          month,
          !bool ? months[1] : this.createMonth(new Date(month.year, month.index + 1, 0), 1)
        ];
      }
    } else {
      this.scrollingInitialized = true;
    }
  }

  setValidator(): void {
    if (this.singlePicker) {
      this.dateIsValid = !this.startDate.length ? true : this.startDate.length && this.utils.isValidDate(this.startDate);
      this.validationMessage = this.dateIsValid ? '' : 'Invalid Date';
    } else {
      //no dates in both is ok
      if ((!this.startDate || !this.startDate.length) && (!this.endDate || !this.endDate.length)) {
        this.dateIsValid = true;
        this.validationMessage = '';
        return;
      }
      const haveJustOne: boolean = (this.startDate.length > 0 && this.endDate.length === 0) || (this.startDate.length === 0 && this.endDate.length > 0);
      const validDates: boolean = !haveJustOne && this.utils.isValidDate(this.startDate) && this.utils.isValidDate(this.endDate) ? true : false;
      const validRange: boolean = validDates ? new Date(this.startDate) < new Date(this.endDate) : true;
      this.dateIsValid = !haveJustOne && validDates && validRange
      this.validationMessage = haveJustOne ? 'Both Start and End Dates are required'
        : !validRange ? 'End Date can not be greater than or equal to Start Date'
        : !validDates ? 'Invalid Date' : '';
    }
  }

  missingRequired(): boolean {
    return this.isRequired && !this.startDate.length;
  }

}
