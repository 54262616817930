
export enum FileToolEvent {
  download = 'download',
  associate = 'associate',
  saveAssociation = 'saveAssociation',
  openInNewTab = 'openInNewTab',
  markRead = 'markRead'
}


