import { Component, Input, OnInit} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IAccordianItems } from './interfaces/i-accordian-items';

@Component({
    selector: 'fw-accordian',
    templateUrl: './fw-accordian.component.html'
})
export class AccordianComponent implements OnInit  {
  @Input() accordianItems: IAccordianItems[]
  @Input() componentHeight: string = '50vh';

  items$: BehaviorSubject<IAccordianItems[]> = new BehaviorSubject([]);

  ngOnInit(): void {
    this.items$.next(this.accordianItems);
  }

  toggleActive(idx: number): void {
    let items: IAccordianItems[] = this.items$.getValue();
    items.forEach((item: IAccordianItems, index: number) => idx === index ? item.isActive = !item.isActive : item.isActive = false);
    this.items$.next(items);
  }

}
