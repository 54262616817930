
export interface ICommunicationShortcut {
  communicationShortcutId: number;
  provider_providerId: number;
  communicationShortcutCategory_communicationShortcutCategoryId: number;
  contactType_contactTypeId: number;
  communicationShortcutName: string;
  communicationShortcutShortcut: string;
  communicationShortcutTemplate: string;
  createDate: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  //extra data
  communicationShortcutCategoryName: string;
  contactTypeName: string;
}

export class CommunicationShortcut implements ICommunicationShortcut {
  communicationShortcutId: number;
  provider_providerId: number;
  communicationShortcutCategory_communicationShortcutCategoryId: number;
  contactType_contactTypeId: number;
  communicationShortcutName: string;
  communicationShortcutShortcut: string;
  communicationShortcutTemplate: string;
  createDate: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  communicationShortcutCategoryName: string;
  contactTypeName: string;

  constructor() {
    this.communicationShortcutId = 0;
    this.provider_providerId = null;
    this.communicationShortcutCategory_communicationShortcutCategoryId = null;
    this.contactType_contactTypeId = null;
    this.communicationShortcutName = '';
    this.communicationShortcutShortcut = '';
    this.communicationShortcutTemplate = '';
    this.createDate = '';
    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';
  }
}
