  <div *ngIf="(contact$ | async) as contact"
     class="sms-convo {{ contact.contactTypeName === 'Installer' ? 'sms-convo--installer' : 'sms-convo--customer' }}"
     [ngClass]="{ 'sms-convo--modal': modalService.opened, 'sms-convo--mgr': fromMgr, 'loading-mask': (texting$ | async) }">
  <div class="sms-convo__title {{ contact.contactTypeName === 'Installer' ? 'sms-convo__title--installer' : 'sms-convo__title--customer' }}">
    <div *ngIf="contact.contactTypeName === 'Installer'" class="sms-convo__title__icon">
      <i class="fad fa-truck"></i>
    </div>
    <div *ngIf="contact.contactTypeName !== 'Installer'" class="sms-convo__title__icon">
      <i class="fad fa-user"></i>
    </div>
    <div class="sms-convo__title__title">Text {{ contact.contactTypeName }}</div>
    <button *ngIf="!noClose" type="button"
            class="app-btn-icon app-btn-icon--white sms-convo__title__exit"
            (click)="close()"
            title="Close Conversation Window">
      <span class="fal fa-times sms-convo__title__exit--inner {{ contact.contactTypeName === 'Installer' ? 'sms-convo__title__exit--inner--installer' : '' }}"></span>
    </button>
  </div>
  <div class="sms-convo__to">
    <div *ngIf="parentName" class="sms-convo__to--parent">{{ parentName }}</div>
    <div class="sms-convo__to--contact">{{ toName }}</div>
    <div class="sms-convo__to--phone">{{ phoneNumber | homphone }}</div>
  </div>

  <div class="sms-convo__history--outer" [ngClass]="{ 'loading-mask': (working$ | async) || (documentTypes$ | async)?.length < 0 }">
    <ng-container *ngIf="(smsEvents$ | async).length > 0">
      <div *ngIf="(contact$ | async) as contact"
           class="sms-convo__history"
           [ngClass]="{'sms-convo__history--modal': modalService.opened, 'sms-convo__history--mgr': fromMgr }"
           infiniteScroll
           [options]="scrollOptions"
           [listContainer]="listContainer"
           [refreshOn]="refreshOn"
           (scrolledToEnd)="onScrollEnd($event)">
        <div #fileDiv *ngIf="(documentTypes$ | async) as documentTypes" class="sms-convo__history__contents" [ngClass]="{'sms-convo__history__contents--modal': modalService.opened }">
          <ng-container #listContainer *ngFor="let smsEvent of (smsEvents$ | async); let j = index;  let lastText = last;">
            <ng-container *ngIf="smsEvent.communicationEventType_communicationEventTypeId === incomingId">
              <sms-received [smsEvent]="smsEvent"
                            [documentTypes]="documentTypes"
                            [showMarkRead]="lastText && showMarkRead"
                            (customEvent)="onCustomEvent($event, smsEvent)">
              </sms-received>
            </ng-container>
            <ng-container *ngIf="smsEvent.communicationEventFreeformFeedback && smsEvent.communicationEventType_communicationEventTypeId === outGoingId">
              <sms-sent [smsEvent]="smsEvent"></sms-sent>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="sms-convo__create">
    <span class="textarea--charsleft sms-convo__create--info">{{charactersLeft$ | async }} characters left</span>
    <div class="sms-convo__create__box">
      <div class="sms-convo__create__box--btn">
        <button type="button"
                class="app-btn-icon app-btn-icon--neutral sms-convo__create__box--btn--btn"
                title="Communication Helpers"
                fw-popover [template]="shortcutBox" [closeOnClickOutside]="true" [showPopover]="shortcutsVisible"
                (click)="toggleShortCuts()">
          <span class="far fa-bolt "></span>
        </button>
      </div>
      <textarea #convoText class="sms-convo__create__box--input"
                maxlength="350" rows="5" columns="100"
                placeholder="Message {{ toName }}"
                appFocus [autoFocus]="true"
                (input)="msgChange(convoText.value)"></textarea>
    </div>


    <ng-template #emojiBox>
      <emoji-mart title="Pick your emoji…"
                  [hideObsolete]="false"
                  [isNative]="false"
                  [set]="'apple'"
                  [color]="'#659dbd'"
                  (emojiSelect)="addEmoji($event)">
      </emoji-mart>
    </ng-template>

    <ng-template #shortcutBox>
      <div class="sms-convo__popover">
        <sms-shortcuts [shortcuts]="shortcuts$ | async"
                       [timestamp]="timestamp"
                       (select)="selectTemplate($event)">
        </sms-shortcuts>
      </div>
    </ng-template>

    <ng-template #docTypeBox>
      <ng-container *ngIf="!(activeDocumentType$ | async)">
        <div class="sms-convo__popover">
          <sms-document-types [documentTypes]="commDocumentTypes$ | async"
                              [errorMessage]="docTypeError$ | async"
                              (select)="selectDocumentType($event)">
          </sms-document-types>
        </div>
      </ng-container>
      <ng-container *ngIf="(activeDocumentType$ | async) as activeDocumentType">
        <div class="sms-convo__popover">
          <sms-entity-selection [contactId]="contact.contactId"
                                [contactTypeId]="contact.contactType_contactTypeId"
                                [requiresWO]="activeDocumentType.documentType.requiresWorkOrder"
                                [requiresProject]="activeDocumentType.documentType.requiresProject
                                                && !activeDocumentType.documentType.requiresWorkOrder"
                                [projectId]="projectId"
                                (select)="selectEntity($event)">
          </sms-entity-selection>
        </div>
      </ng-container>
    </ng-template>

    <div class="sms-convo__btns">
      <div class="app-btn-box app-btn-box--left">
        <button fw-popover
                [template]="emojiBox"
                [showPopover]="emojisVisible"
                [closeOnClickOutside]="true"
                (click)="showOrHidePopover('emojisVisible', true)"
                (onHide)="showOrHidePopover('emojisVisible', false)"
                type="button"
                class="app-btn-icon app-btn-icon--neutral"
                title="Select an Emoji">
          <i class="far fa-smile"></i>
        </button>
        <button type="button"
                class="app-btn-icon app-btn-icon--neutral"
                title="Attachments"
                fw-popover
                [template]="docTypeBox"
                [closeOnClickOutside]="true"
                [showPopover]="docTypesVisible"
                [disabled]="!(smsSendDocEnabled$ | async)"
                (onShow)="showOrHideDocTypes(true)"
                (onHide)="showOrHideDocTypes(false)"
                [isDynamic]="true">
          <span class="fal fa-paperclip "></span>
        </button>
      </div>

      <div class="app-btn-box">
        <button type="button"
                class="app-btn app-btn--primary"
                title="Send"
                [disabled]="isDisabled$ | async"
                (click)="sendText(convoText.value)">
          {{ canText ? 'Send' : 'Texting Disabled'}}
        </button>
      </div>
    </div>

  </div>
  <div class="sms-convo__errors">
    <fw-error-box [errorData]="errorData$ | async" [errors]="errorMessage$ | async" [ignoreKey]="true"></fw-error-box>
  </div>
  <div class="sms-convo__docs">
    <ng-container *ngFor="let doc of (selectedDocumentTypes$ | async);">
      <sms-send-document [document]="doc"
                         (delete)="deleteDocument($event)">
      </sms-send-document>
    </ng-container>
  </div>

</div>


