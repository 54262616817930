export enum UtilitiesStore {
  dashScheduleMoveUps = 'dashScheduleMoveUps',
  dashCustomers = 'dashCustomers',
  dashCustomerProjects = 'dashCustomerProjects',
  dashCalendar = 'dashCalendar',
  homHolidays = 'homHolidays',
  dashInstallerVacations = 'dashInstallerVacations',
  dashFileUploads = 'dashFileUploads',
  dashEmailImports = 'dashEmailImports'
}

export enum UtilitiesEvent {
  scheduleWorkOrder = 'scheduleWorkOrder',
  peekWOSummary = 'peekWOSummary',
  peekPOSummary = 'peekPOSummary',
  viewCustomerFlags = 'viewCustomerFlags',
  viewContactInfo = 'viewContactInfo',
  viewWOInfo = 'viewWOInfo',
  goToProject = 'goToProject',
  goToWorkOrder = 'goToWorkOrder',
  showWoPacket = 'showWoPacket',
  showWoPacketsForDay = 'showWoPacketsForDay',
  cancelWorkOrderSchedule = 'cancelWorkOrderSchedule',
  rescheduleWorkOrder = 'rescheduleWorkOrder',
  addWorkOrderNote = 'addWorkOrderNote',
  dispatchWorkOrder = 'dispatchWorkOrder',
  unDispatchWorkOrder = 'unDispatchWorkOrder',
  deleteSchedule = 'deleteSchedule',
  filterChanged = 'filterChanged',
  filterTextChanged = 'filterTextChanged',
  goToDay = 'goToDay',
  goToWeek = 'goToWeek',
  showLockDayManager = 'showLockDayManager'
}
