import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { INote, Note, INoteViewModel } from '../../../view-models';
import { IErrorData, IResponseBase } from '../../../../../shared/interfaces';
import { FormGroup,  FormBuilder, Validators } from '@angular/forms';
import { INoteRequiredData } from '../../../portal-shared/interfaces/i-note-required-data';
import { NoteSubjectDefault } from '../../../portal-shared/enums/note.enums';
import { DomainObjectService } from '../../../../../shared/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ProjectService } from '../../services';

@Component({
  selector: 'delete-reason-note',
  templateUrl: './delete-reason-note.component.html'
})
export class DeleteReasonNoteComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public projectId: number = 0;
  public workOrderId: number = 0;
  public purchaseOrderId: number = 0;
  public controllerName: string;
  public entityPkId: number;
  public entityLabel: string;
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  public errorMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  public noteCreated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public fb: FormBuilder,
    public modalService: ModalService,
    public dos: DomainObjectService,
    public projectService: ProjectService,
    @Inject(appConstants) public myConstants: IAppConstants) {
  }

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
        const stateData: INoteRequiredData = window.history.state;
        if (stateData) {
          //Required data for delete
          this.controllerName = stateData.entityType;
          this.entityPkId = stateData.entityPkId;
          this.entityLabel = stateData.entityLabel;
        }
        this.projectId = +paramMap.get('id');
        this.workOrderId = +paramMap.get('woId');
        this.purchaseOrderId = +paramMap.get('poId');
        //initial creation for deleteing from non-note list for project required documents
        const subj: string = NoteSubjectDefault.entityDocumentDelete.concat(': ', this.entityLabel);
        this.form = this.fb.group({
          noteSubject: [{ value: subj, disabled: subj === '' ? false : true }, [Validators.required]],
          noteText: [{ value: '', disabled: false }, [Validators.required]],
        }, {});
      }
    );

    this.subscription.add(this.noteCreated$.subscribe(val => {
      if (val) {
        this.deleteEntity();
      }
    }));
  }

  public onCancel() {
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  public onCreate() {
    event.stopPropagation();
    if (!this.form.valid) {
      return;
    }
    let note: INote = new Note();
    note.project_projectId =  this.projectId,
    note.workOrder_workOrderId = this.workOrderId > 0 ? this.workOrderId : null,
    note.purchaseOrder_purchaseOrderId =  this.purchaseOrderId > 0 ? this.purchaseOrderId : null,
      note.noteSubject = this.form.controls['noteSubject'].value,
      note.noteText = this.form.controls['noteText'].value,
    note.isPrivate =  true,
    note.isExternal =  false

    let vm: INoteViewModel = {
      model: note,
      noteNotifyList: [],
      sendExternal: false
    }
    this.working$.next(true);
    this.errorData$.next([]);
    this.subscription.add(this.dos.createByMethod('Note', 'Create', vm)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.noteCreated$.next(true);
        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      }));
  }

  deleteEntity(): void {
    if (!this.haveRequiredFields()) {
      this.errorMessage$.next('The values required to process the delete were not found.  Delete aborted.  Contact Support.');
      return;
    }
    this.working$.next(true);
    this.subscription.add(this.dos.deleteByMethodById(this.controllerName, 'Delete', this.entityPkId)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.dispatchRelatedStoreGets();
          this.onCancel();
        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      }));
  }

  haveRequiredFields(): boolean {
    return this.controllerName != '' && this.entityPkId > 0;
  }

  dispatchRelatedStoreGets(): void {
    if (this.controllerName === 'EntityDocument') {
      this.projectService.dispatchRequiredDocumentsGet(this.projectId, true);
      this.projectService.emitTaskReloadEvent('delete-reason-note', this.projectId);
    };
    this.projectService.dispatchProjectNotesGet(this.projectId, true);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
