<ng-container *ngIf="(plSchedules$ | async).length > 0">
  <div *ngFor="let item of (plSchedules$ | async); let rowIndex = index;  let lastRow = last">
    <provider-location-schedule [data]="item"
                                (changeEvent)="onChange($event)"
                                (errorEvent)="onError($event)">
    </provider-location-schedule>

  </div>
  <div class="app-btn-box">
    <button class="app-btn app-btn--danger" title="Cancel" (click)="onCancel()">
      Cancel
    </button>
    <button title="Save" class="app-btn app-btn--primary"  [disabled]="!(canSave$ | async)" (click)="onSave()">
      Save
    </button>
  </div>
</ng-container>
