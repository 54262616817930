<form *ngIf="contactService.contactForm.controls[this.email.formGroupName] && definitions"
      [ngClass]="{ 'contact-email__form': displayType !== contactConstantsService.displayTypePortalSummary, 'contact-email__thin-form': displayType === contactConstantsService.displayTypePortalSummary }"
      [formGroup]="contactService.contactForm.controls[email.formGroupName]" novalidate>
  <fw-dynamic-field class="contact-email__email"
                    [field]="metaDataService.getFieldDefinition(definitions, 'emailAddress')"
                    [form]="contactService.contactForm.controls[this.email.formGroupName]"
                    [operation]="operation"
                    [autoFocus]="focusOn$ | async" >
  </fw-dynamic-field>
  <div class="contact-email__options">
    <button *ngIf="!email.uiRequired" type="button"
            class="app-btn-icon app-btn-icon--danger contact-email__options--action"
            (click)="deleteRecord()"
            [disabled]="!contactService.canIDelete(canIEdit, contactConstantsService.contactMechanismCategoryEmail, email) || operation === myConstants.operationTypeDetails"
            title="Delete">
      <i class="fas fa-trash"></i>
    </button>
    <fw-dynamic-field class="contact-email__options--preferred"
                      [field]="metaDataService.getFieldDefinition(definitions, 'isPreferred')"
                      [form]="contactService.contactForm.controls[this.email.formGroupName]"
                      [operation]="operation">
    </fw-dynamic-field>
  </div>
</form>
