
export interface IService {
  serviceId: number;
  provider_providerId: number;
  serviceName: string;
  serviceDescription: string;
  accountingServiceCode: string;
  capacityMultiplier: number;
  unitMeasure_unitMeasureId: number;
  customerEmailTemplate: string;
  customerMeasureEmailTemplate: string;
  servicePrecedence: number;
  sssScheduleDuration: number;
}

export class Service implements IService {
  serviceId: number;
  provider_providerId: number;
  serviceName: string;
  serviceDescription: string;
  accountingServiceCode: string;
  capacityMultiplier: number;
  unitMeasure_unitMeasureId: number;
  customerEmailTemplate: string;
  customerMeasureEmailTemplate: string;
  servicePrecedence: number;
  sssScheduleDuration: number;

  constructor() {
    this.serviceId = 0;
    this.provider_providerId = null
    this.serviceName = '';
    this.serviceDescription = '';
    this.accountingServiceCode = '';
    this.capacityMultiplier = null;
    this.unitMeasure_unitMeasureId = null;
    this.customerEmailTemplate = '';
    this.customerMeasureEmailTemplate = '';
    this.servicePrecedence = null;
    this.sssScheduleDuration = null;
  }
}
