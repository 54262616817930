import { Component,  Inject, Input, Output, EventEmitter} from '@angular/core';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { ISuggestedAddress } from '../../services/index';
import { ISanitizedAddress, IAddress } from '../../../portals/view-models';

@Component({
  selector: 'address-verify-results',
  templateUrl: './address-verify-results.component.html'
})
export class AddressResultsComponent {
  @Input() address: IAddress;
  @Input() sanitizedAddr: ISanitizedAddress;
  @Input() badAddress: boolean;
  @Output() public selectedEvent = new EventEmitter<ISuggestedAddress>();

  public componentTitle: string = "Address Verification";

  constructor(
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onChoice(use: boolean, override: boolean = false): void {
    let response: ISuggestedAddress = { use: use, override: override, reason: '' };
    this.selectedEvent.emit(response);
  }

}
