import { Component, Input, Inject, Output, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras, ParamMap } from '@angular/router';
import { DecimalPipe, DatePipe } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, filter, take } from 'rxjs/operators';
import { size, filter as lodashFilter, isArray, cloneDeep } from 'lodash'; //pulling for just the methods needed is not working at this time, but keep trying
import { HomErrorHandlerService} from 'hom-lib/hom-error-logger';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

//interfaces
import { IAppConstants, appConstants } from '../../../shared/constants/index';
import {
  IListDefinition, IDetailRouteParameter, IListFilter, IFilterChange,
  IListShowDetail, IListCustomSelectEvent, IListScreenSize, IUserListFilter 
} from '../interfaces/index';
import { IErrorData, IPageMetaData, IResponseBase } from '../../../shared/interfaces/index';
import { IFieldDefinition } from '../../dynamic-forms/index';

import { ISearchTerm, IOrderTerm } from '../../dynamic-list/interfaces/index';

import { IScreenBreakpoints } from '../../fw-shared/services/i-screen-breakpoints';
import { DeleteObjectModel, IKey, UpdateObjectCustomModel } from '../store/interfaces/index';
import { ModalService } from '../../fw-modal/services/fw-modal.service';
import { ModalSizeType } from '../../fw-modal/interfaces/i-modal';
import { DynamicListEvent, DynamicListStore } from '../enums/dynamic-list.enum';
import { ITitleListItem } from '../../fw-shared/components/fw-component-title/interfaces/i-title-list-item';

// services
import { DynamicListService, INavChange } from '../services/dynamic-list.service';
import { ScreenService } from '../../fw-shared/services/screen.service';
import { HomCommonUtility, DomainObjectService } from '../../../shared/services';

//store actions and reducers
import * as fromStore from '../store/index'
import * as fromRoot from '../../../app/store/reducers/index';
import * as fromDynamicList from '../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { UpdateObjectCustomList } from '../store/index';

interface IClearAll {
  key: string
  cleared: boolean;
}

@Component({
  selector: 'fw-dynamic-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HomEventEmitterService], //want a new instance for each instance of this componentimports: StoreModule.provideStore(appReducer),
  templateUrl: './dynamic-list.component.html',
})
export class DynamicListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() vmListDefinition: IListDefinition;
  @Input() isSummary: boolean = false;
  @Input() listTitle: string = '';
  @Input() infoText: string = '';

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();
  @Output() public selectEvent = new EventEmitter<IHomEventEmitter>();
  @Output() public filterEvent = new EventEmitter<IHomEventEmitter>();
  @Output() public pageEvent = new EventEmitter();
  @Output() public showChildEvent = new EventEmitter();
  @Output() public deletingEvent = new EventEmitter();

  public pendingTerms: ISearchTerm[] = [];
  public colCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  public operation$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public hasStaleData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public dataAge$: BehaviorSubject<number> = new BehaviorSubject(0);
  public childVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$: Observable<boolean>;
  public pageMetaData$: BehaviorSubject<IPageMetaData> = new BehaviorSubject(null);
  public activeFilterInfo$: BehaviorSubject<string> = new BehaviorSubject('');
  public userFilters$: BehaviorSubject<ITitleListItem[]> = new BehaviorSubject([]);
  public lastUpdated$: BehaviorSubject<string> = new BehaviorSubject(null);
  public storeData: fromDynamicList.IListObjectData = null;
  public fieldDefinitions: IFieldDefinition[] = [];
  public hasPendingChildCreate: boolean = false;

  public screenSize$: BehaviorSubject<IListScreenSize> = new BehaviorSubject({
    screenIsSmall: false,
    screenIsMedium: false,
    screenIsLarge: false,
    screenIsMax: false,
    screenisGreaterThanMax: false
  });
  public gridTemplateCols$: BehaviorSubject<string> = new BehaviorSubject('');
  public workingFilterInfo$: BehaviorSubject<string> = new BehaviorSubject('');
  public isDeleting: boolean = false;
  public deleteSelected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public navClosed: boolean = false;
  public currentOperation: string;
  public currentChildVisible: boolean;
  public listFilter: IListFilter;
  public defaultFilter: IListFilter;
  public requiredTerms: ISearchTerm[];
  public inFilterMode: boolean = false;
  public portalEntityId: number = -1;
  public activeIndex: number = -1;
  public deleteError: string;
  public deleteId: number;
  public activeOptionIndex: number;
  public allSelected: boolean = false;

  subscription: Subscription = new Subscription();
  clearAllRequest: boolean = false;
  clearAllList: IClearAll[] = [];
  inRoute: boolean = false;
  routePath: string = '';
  activeChild: string = '';
  loadedOnTimestamp: number = 0;
  timeLeft: number;
  interval;
  defaultUserFilter: IUserListFilter = null;
  allUserListFilters : IUserListFilter[] = [];
  ranFirstGetList: boolean = false;
  haveMetaData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  checkedForDefaultUserFilter$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  needToGetData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  listComponentId: number;
  haveSubUserFilters: boolean = false;
  haveRetrievedData: boolean = false; //just for user filters

  constructor(
    public el: ElementRef,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public decimalPipe: DecimalPipe,
    public screenService: ScreenService,
    public dynamicListService: DynamicListService, //do not change this name
    public emitterService: HomEventEmitterService,
    public homCommonUtility: HomCommonUtility,
    public changeDetectorRef: ChangeDetectorRef,
    public modalService: ModalService,
    public datePipe: DatePipe,
    public homErrorHandlerService: HomErrorHandlerService,
    public dos: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants
  ) { }

  public get toolbarDisabled(): boolean {
    return this.currentChildVisible;
  }

  public get disabled(): boolean {
    return this.hasStaleData$.value;
  }

  public setGridTemplateCols(restretch: boolean = false): void {
    let cols: number = this.colCount$.value - 1;
    const showRowTools: boolean = !this.dynamicListService.showRowTools(this.vmListDefinition);
    const screenSize = this.screenSize$.getValue();
    const myWidth: number = this.el.nativeElement.getBoundingClientRect().width;
    const filterWidth: number = screenSize.screenIsSmall || !showRowTools ? 30 : 140;
    const maxColWidth: number = ((myWidth - filterWidth) / cols);
    let repeater: string = '';
    if (restretch) {
      repeater = "repeat(" + cols.toString() + ", minmax(min-content, 1fr))";
    } else {
      repeater = "repeat(" + cols.toString() + ", minmax(min-content, autofill))";
    }
    const filter: string = screenSize.screenIsSmall || !showRowTools ? " minmax(min-content, 3rem)": " minmax(min-content, 14rem)";

    this.gridTemplateCols$.next(repeater.concat(filter));
  }

  public collapseNav(): void {
    this.navClosed = !this.navClosed;
    //nav is on parent, child that now has more or less room is in new router outlet
    this.dynamicListService.navChange$.next({ closed: this.navClosed, storeName: this.vmListDefinition.storeName, childOutlet: this.vmListDefinition.routerOutlet });
  }

  public pageChanged(page: number) {
    let newFilter: IListFilter = cloneDeep(this.listFilter);
    newFilter.currentPage = page;
    this.getComponentData(newFilter);
    //emit paging event so containers will know a change in result set has been requested  
    this.pageEvent.emit();
  }

  /*
    Create a new record for this object
*/
  public showCreate() {
    if (this.errorData$.value) {
      this.store.dispatch(new fromStore.ClearErrorsList({ storeName: this.vmListDefinition.storeName, parentId: this.vmListDefinition.parentId }));
    }

    this.childVisible$.next(true)
    this.operation$.next(this.myConstants.operationTypeCreate);

    this.activeIndex = -1;

    const optionalParameters: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: this.vmListDefinition.storeName,
      key: this.vmListDefinition.rowKeyId,
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.portalEntityId
    }

    this.navigateToDetail(0, optionalParameters);
  }
  /*END PUBLIC METHODS*/

  /*
  Clear contents of all the visible filters.
*/
  clearAllFilters() {
    this.setClearAll();
    this.operation$.next(this.myConstants.operationTypeResetFilter);
  }

  /*
    trigger event and return search terms to force list reload with filters requested
  */
  runFilter() {
    this.filterList();
  }

  /*
Create a new saved filter for the user.  Will use search terms in the store - so only active terms, not pending.
*/
  showCreateSaveFilter() {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: this.vmListDefinition.storeName,
      key: this.vmListDefinition.rowKeyId,
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.vmListDefinition.parentId
    }
    this.modalService.open({
      title: 'Create Personal Filter for ' + this.vmListDefinition.objectLabel,
      path: 'user-list-filter-create',
      id: 0, //create
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false,
      isSubModal: this.modalService.opened
    });

  }

  /*
Create a new saved filter for the user.  Will use search terms in the store - so only active terms, not pending.
*/
  showSavedFilterList() {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: this.vmListDefinition.storeName,
      key: this.vmListDefinition.rowKeyId,
      operation: '',
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.vmListDefinition.parentId
    }
    this.modalService.open({
      title: 'Manage Personal Filters',
      path: 'user-list-filters',
      id: 0, //forced by modal to have this, not applicable here
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false,
      isSubModal: this.modalService.opened
    });

  }

  /*
   if detailUrl contains a value, user can view details for this record.
   verify have a routePath (should be defined in routing module) and route to that component.

*/
  showDetail(data: IListShowDetail) {
    this.activeChild = this.vmListDefinition.storeName;

    this.activeIndex = data.rowIndex;
    const operation = data.operation === '' ? this.currentOperation : data.operation;
    //use the custom create route path - custom would be set if differs from detail only
    const path = operation === this.myConstants.operationTypeCreate && this.vmListDefinition.createRoutePath ? this.vmListDefinition.createRoutePath : this.routePath;

    if (path && path.length > 0) {
      this.operation$.next(operation);
      this.childVisible$.next(true)
      const optionalParameters = this.optionalRouteParameters(data.rowIndex);
      this.navigateToDetail(data.id, optionalParameters);
    } else {
      this.homErrorHandlerService.handleError({
        name: 'RouteError',
        message: 'showDetail and no route path for this store: ' + this.vmListDefinition.storeName + ' for id:' + data.id.toString() + ' for operation:' + operation
      });
    }
  }

  showDeleteQuestion(id: number): void {
    this.deleteId = id;
    this.deleteError = '';
    this.deleteSelected$.next(true);

  }

  /*
  On first click of delete, will highlight the row and force a confirmation click.  
  Cancel delete request during confirmation
*/
  cancelDelete() {
    this.isDeleting = false;
    this.deleteId = null;
    this.deleteSelected$.next(false);
  }

  /*
    On delete confirmation, will use the deleteUrl found in the metadata to delete the record then refresh the list
*/
  deleteRecord(row: any) {
    if (!row.metaData.crud || !row.metaData.crud.deleteUrl || row.metaData.crud.deleteUrl.length <= 0) {
      this.deleteError = 'DeleteUrl is undefined.  Delete cancelled.';
    } else {
      this.isDeleting = true;

      if (row.metaData.crud.deleteUrl && row.metaData.crud.deleteUrl.length > 0) {
        const keyData: IKey = { storeName: this.vmListDefinition.storeName, parentId: this.vmListDefinition.parentId, key: this.vmListDefinition.rowKeyId, id: this.deleteId }
        const event: IHomEventEmitter = { requestor: this.vmListDefinition.detailRoutePath, event: this.myConstants.emitterEventDelete, action: '', data: null };

        const deleteData = new DeleteObjectModel(keyData, row.metaData.crud.deleteUrl, event);
        this.store.dispatch(new fromStore.DeleteObjectByUrlList({ deleteData }));
      }
    }
  }
  //reevaluate so can make list level changes if needed
  selectClick(e: IListCustomSelectEvent) {
    if (!this.vmListDefinition.groupSelect) {
      return;
    }
    //const action = this.rowSelected(index) ? 'selected' : '';
    let btnEvent: IHomEventEmitter = { requestor: 'dynamic-list', event: this.vmListDefinition.groupSelect.eventName, action: e.selected ? 'selected' : '', data: e.row };
    this.selectEvent.emit(btnEvent);
  }

  optionClick(e: IListCustomSelectEvent) {
    if (!this.vmListDefinition.singleSelect) {
      return;
    }
    //mark this row selected and unselect all others
    let btnEvent: IHomEventEmitter = { requestor: 'dynamic-list', event: this.vmListDefinition.singleSelect.eventName, action: 'selected', data: e.row };
    this.selectEvent.emit(btnEvent);

    this.activeOptionIndex = e.index;
  }

  getPendingSearchTerms(): ISearchTerm[] {
    //return cloneDeep(this.pendingTerms);
    return cloneDeep(this.pendingTerms);
  }


  /*
    subscribe to param changes so know what area (portal) we are working with
        init statup values
    subscribe to the store
        init / detect changes to values that should vary as the store is modiifed
  */
  ngOnInit() {
    let parentParams: ParamMap = null;
    let objectParams: ParamMap = null;
    this.inFilterMode = this.vmListDefinition.openInFilterMode;
    this.timeLeft = this.dynamicListService.listTimeout;

    //subscribe to parent my route changes
    this.activatedRoute.paramMap
      .subscribe(paramMap => {
        parentParams = paramMap;
        this.setParentIds(parentParams, objectParams);
      });

    //subscribe to parent route changes
    this.activatedRoute.parent.paramMap
      .subscribe(paramMap => {
        objectParams = paramMap;
        this.setParentIds(parentParams, objectParams);
      });

    this.addVariableSubscriptions();
    this.addServiceSubscriptions();

    this.initList();

    this.addStoreSubscriptions();

    this.setSizes();
    this.setColCount();

  }

  addVariableSubscriptions(): void {
    this.subscription.add(this.operation$.subscribe((val: string) => { this.currentOperation = val; }));

    this.subscription.add(this.childVisible$.subscribe((val: boolean) => {
      this.currentChildVisible = val;
      this.showChildEvent.emit(val);
      if (!val) {
        this.navClosed = false;
      }
    }));

    this.subscription.add(this.deleteSelected$.subscribe((val: boolean) => {
      const event: IHomEventEmitter = { requestor: 'dynamic-list', event: this.myConstants.emitterEventDelete, action: '', data: this.deleteId };
      this.deletingEvent.emit(event);
    }));


    this.subscription.add(this.dynamicListService.navChange$.subscribe((val: INavChange) => {
      if (val && val.childOutlet !== this.vmListDefinition.routerOutlet) {
        if (this.router.url.indexOf(val.childOutlet) > 0) {
          this.setGridTemplateCols(val.closed);
        }
      }
    }));
  }

  addServiceSubscriptions(): void {
    this.subscription.add(this.emitterService.listEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        this.manageEvents(e);
      }));

    this.subscription.add(this.emitterService.listCustomEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        //reemit so component that rendered list can consume
        //used for custom dynamic-list-items and custom btns
        this.customEvent.emit(e);
      }));

    this.subscription.add(this.screenService.resize$
      .pipe(distinctUntilChanged())
      .subscribe((e: IScreenBreakpoints) => {
        this.setSizes();
        this.setColCount();
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  subscribeLoadUserFilters(): void {
    this.haveSubUserFilters = true;
    if (this.listComponentId > 0 && this.vmListDefinition.showFilter && this.vmListDefinition.allowSaveFilter && this.vmListDefinition.storeName !== DynamicListStore.userListFilters) {
      this.subscription.add(this.store.pipe(select(fromStore.getListByType(DynamicListStore.userListFilters)))
        .pipe(map((listsState: fromDynamicList.IDynamicListState) => listsState.objData.find(x => x.parentId == this.listComponentId)))
        .subscribe((state: fromDynamicList.IListObjectData) => {
          let data = cloneDeep(state);
          if (data && !data.working) {
            if (data.event && data.event.event === this.myConstants.emitterEventListReload && data.event.action === 'UserFiltersReload') {
              //reload the list
              this.store.dispatch(new fromStore.GetList({ listDefinition: data.listDefinition, listFilter: data.listDefinition.defaultListFilter, parentId: data.listDefinition.parentId }));
              this.store.dispatch(new fromStore.ClearEventList({ storeName: data.listDefinition.storeName, parentId: data.listDefinition.parentId }));
              return;
            } else {
              const defaultUserFilter = data.data.find((x: IUserListFilter) => x.isDefault);
              const haveMultipleCurrentFilters = data.data.filter((x: IUserListFilter) => x.isCurrent);
              let forceFalse: boolean = false;
              if (haveMultipleCurrentFilters && haveMultipleCurrentFilters.length > 1) {
                forceFalse = true;
              }
              this.allUserListFilters = data.data;
              let userFilters: ITitleListItem[] =
                data.data.map((val: IUserListFilter) => <ITitleListItem>{
                    id: val.userListFilterId,
                    label: val.filterName,
                    isActive: this.isActiveFilter(defaultUserFilter, val, forceFalse),
                    lastUsed: val.lastUsed,
                    usedCount: val.usedCount,
                    isDefault: val.isDefault,
                    description: this.dynamicListService.getFormattedFilterInfo(this.dynamicListService.loadSavedFilterToSearchTerms(val, this.requiredTerms, this.fieldDefinitions))
                });
              userFilters.sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));
              this.userFilters$.next(userFilters);
              this.defaultUserFilter = defaultUserFilter;
            }
          }
          if (!this.checkedForDefaultUserFilter$.value) {
            this.checkedForDefaultUserFilter$.next(true); 
          }
        }));
    } else {
      if (!this.checkedForDefaultUserFilter$.value) {
        this.checkedForDefaultUserFilter$.next(true);
      }
    }
  }

  isActiveFilter(defaultUserFilter: IUserListFilter, filterItem: IUserListFilter, forceFalse: boolean): boolean {
    let isActive: boolean = false;
    if (forceFalse) {
      return false;
    }
    if (this.vmListDefinition.noGet && !this.haveRetrievedData) {
      //first time thru on a noget, always return false
      isActive = false;
    } else if (!this.haveRetrievedData) {
      //first time through, no data in store, use defaultFilter as active else set to false
      isActive = defaultUserFilter ? filterItem.userListFilterId === defaultUserFilter.userListFilterId : false;
    } else {
      isActive = filterItem.isCurrent;
    }

    return isActive;
  }
  addStoreSubscriptions(): void {
    this.listComponentId = this.dynamicListService.getListComponentId(this.vmListDefinition.storeName);
    
    const url: string = this.vmListDefinition.controllerName.endsWith('Model')
      ? this.vmListDefinition.controllerName.concat('/', 'GetMetaData')
      : 'Entity/GetEntityMetaData?entityName=' + this.vmListDefinition.controllerName;

    //Check for existing Entity List data
    //Take(1) Check for existing data. 
    this.subscription.add(this.store
      .pipe(select(fromStore.getEntityListByParentId(this.vmListDefinition.storeName, this.vmListDefinition.parentId)), take(1))
      .subscribe(entityList => {
        const listStore = cloneDeep(entityList)
        if (!this.vmListDefinition.noGet && (!listStore || !listStore.data || listStore.staleData)) {
          this.needToGetData$.next(true);
        } else if (listStore && listStore.listFilter) {
          this.needToGetData$.next(false);
          this.haveRetrievedData = listStore.data ? true : false;
          this.ranFirstGetList = true;
          this.pendingTerms = cloneDeep(entityList.listFilter ? entityList.listFilter.searchTerm : []);
          this.listFilter = cloneDeep(entityList.listFilter);
          //set to true to ensure we use current filter
          this.checkedForDefaultUserFilter$.next(true)
        }
      }));


    //Initiate MetaData get.
    //Loads to meta-data store
    this.subscription.add(this.store.pipe(select(fromStore.metaDataExists(this.vmListDefinition.storeName)), take(1))
      .subscribe((exists: boolean) => {
        if (!exists) {
          //set it only in the meta-data store not the dynamic-list store
          this.store.dispatch(new fromStore.GetMetaData({ storeName: this.vmListDefinition.storeName, url: url, setListMetaData: false }));
        }
      }
      ));

    //Listens for load complete of meta data from meta-data store.
    this.subscription.add(this.store.pipe(select(fromStore.getMetaDataByType(this.vmListDefinition.storeName)))
      .pipe(filter((state: fromStore.IMetaDataState) => state.fieldDefinitions.length > 0))
      .subscribe((state: fromStore.IMetaDataState) => {
       if (!this.haveMetaData$.value  && state.fieldDefinitions.length && (!this.fieldDefinitions || !this.fieldDefinitions.length)) {
         this.fieldDefinitions = state.fieldDefinitions;
         this.haveMetaData$.next(true);
        }
      }));

    this.subscription.add(this.haveMetaData$.subscribe((x: boolean) => {
      if (x) {
        //we know we have fieldDefinitions now
        if (!this.haveSubUserFilters) {
          this.subscribeLoadUserFilters();
        }
        if (this.checkedForDefaultUserFilter$.value && this.needToGetData$.value) {
          //we may or may not have an applied filter but, either way, we are ready to proceed
          this.runFirstGetList();
        }
      }
    }));

    this.subscription.add(this.checkedForDefaultUserFilter$.subscribe((x: boolean) => {
      if (x) {
        //we know we have checked to see if we have an applied filter
        if (this.haveMetaData$.value && this.needToGetData$.value) {
            this.runFirstGetList();
        } 
      }
    }));

    //Primary data change listener
    this.subscription.add(this.store.pipe(select(fromStore.getListByType(this.vmListDefinition.storeName)))
      .pipe(map((listsState: fromDynamicList.IDynamicListState) => listsState.objData.find(x => x.parentId == this.vmListDefinition.parentId)))
      .subscribe((state: fromDynamicList.IListObjectData) => {
        if (state && state.working) {
          return;
        }
        this.storeData = cloneDeep(state);
        if (this.storeData) {
          if (!this.storeData.data) {
            this.storeData.data = [];
          }
          this.loadedOnTimestamp = this.storeData.lastUpdated ? this.storeData.lastUpdated : 0;
          this.setTimestampPretty();
          this.hasPendingChildCreate = this.dynamicListService.listHasPendingChildCreates(this.storeData.data);
          this.pageMetaData$.next(this.storeData.listMetaData && this.storeData.listMetaData.pageMetaData ? this.storeData.listMetaData.pageMetaData : null);
          const eventIn = cloneDeep(this.storeData.event);
          if (eventIn) {
            switch (eventIn.event) {
              case this.myConstants.emitterEventListReload:
                if (!this.storeData.errorData || !this.storeData.errorData.length) {
                  this.getComponentData();
                  this.store.dispatch(new fromStore.ClearEventList({ storeName: this.vmListDefinition.storeName, parentId: this.vmListDefinition.parentId }));
                  if (eventIn.action === this.myConstants.emitterEventModalClose && this.modalService.opened) {
                    //make sure dispatch to reload is executed before closing modal and destroying list.
                    //applicable for modal containing a list that your main list is dependent on having the latest values.
                    this.modalService.close();
                  }
                  return;
                }
                break;
              case this.myConstants.emitterEventDelete:
                this.initDeleteSuccess();
                return;
              case DynamicListEvent.applyFilter:
              case DynamicListEvent.inMemorySort:
                this.pendingTerms = cloneDeep(this.storeData.listFilter ? this.storeData.listFilter.searchTerm : []);
                this.listFilter = cloneDeep(this.storeData.listFilter);
                //default filter does not typically change, but may, so update it.
                this.defaultFilter = cloneDeep(this.storeData.listDefinition.defaultListFilter);
                this.requiredTerms = cloneDeep(this.storeData.listDefinition.requiredSearchTerms);
                if (eventIn && eventIn.event === DynamicListEvent.applyFilter) {
                  this.inFilterMode = false;
                  if (eventIn.action && eventIn.action === this.myConstants.emitterActionClearCurrent) {
                    this.initUserFilters();
                  }
                }
                this.store.dispatch(new fromStore.ClearEventList({ storeName: this.vmListDefinition.storeName, parentId: this.vmListDefinition.parentId }));
                return;
              default:
                break;
            }
          } else {
            const newListFilter = cloneDeep(this.storeData.listFilter ? this.storeData.listFilter : this.vmListDefinition.defaultListFilter);
            this.pendingTerms = cloneDeep(newListFilter ? newListFilter.searchTerm : []);
            this.listFilter = newListFilter;
          }

          this.activeFilterInfo$.next(this.storeData && this.storeData.listFilter ? this.dynamicListService.getFormattedFilterInfo(this.storeData.listFilter.searchTerm) : '');
          this.workingFilterInfo$.next(this.dynamicListService.getFormattedFilterInfo(this.pendingTerms));

          this.errorData$.next(this.storeData.errorData);

          //2 ways data could be stale, list has not been refreshed in a while and clicked on a record and the rowVersion is out of sync
          this.hasStaleData$.next(this.storeData.staleData);
          this.resetStaleDataTimer();
          this.startStaleDataTimer();
          this.checkListDataAge();

          if (this.childVisible$.value && this.storeData.staleData) {
            this.activeIndex = -1;
            this.childVisible$.next(false)
            this.operation$.next('');
          }

          if (this.isDeleting) {
            this.isDeleting = false;
            this.deleteId = null;
          }

          if (this.fieldDefinitions.length) {
            this.changeDetectorRef.detectChanges();
          }

          this.setSizes();
          this.setColCount();

        }
      }));
  }

  runFirstGetList(): void {
    if (this.ranFirstGetList) {
      return;
    }
    this.store.dispatch(new fromStore.SetListDefinition({ storeName: this.vmListDefinition.storeName, parentId: this.vmListDefinition.parentId, listDefinition: this.vmListDefinition }));

    let newListFilter = cloneDeep(this.vmListDefinition.defaultListFilter);
    if (this.defaultUserFilter) {
      const userDefaultSearchTerms: ISearchTerm[] = this.dynamicListService.loadSavedFilterToSearchTerms(this.defaultUserFilter, this.vmListDefinition.requiredSearchTerms, this.fieldDefinitions);
      newListFilter.searchTerm = userDefaultSearchTerms;

      // Needed to update db with current filter application
      this.updateUserFilters(this.defaultUserFilter);
    } else if (this.listComponentId > 0 && this.vmListDefinition.allowSaveFilter) {
      this.initUserFilters();
    }

    this.getComponentData(newListFilter);
    this.ranFirstGetList = true;
  }

  setTimestampPretty(): void {
    this.lastUpdated$.next(this.loadedOnTimestamp > 0 ? this.datePipe.transform(new Date(this.loadedOnTimestamp), 'MM/dd h:mm a') : '');
  }

  //check for stale data every 1 minute
  startStaleDataTimer(): void {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.hasStaleData$.next(true);
        const currentTime: number = new Date().getTime();
        const timeDiff = this.getDateAgeDiff(currentTime);
        this.dataAge$.next(timeDiff);
      }
    }, 60000)
  }

  //interval must be reset to release subscription
  resetStaleDataTimer(): void {
    clearInterval(this.interval);
    this.timeLeft = this.dynamicListService.listTimeout;
  }

  //Is elapsed time is over the stale data time limit
  checkListDataAge(): void {
    const currentTime: number = new Date().getTime();
    const timeDiff = this.getDateAgeDiff(currentTime);
    if (timeDiff > this.dynamicListService.listTimeout
      && new Date(this.loadedOnTimestamp).addMinutes(this.dynamicListService.listTimeout).getTime() < currentTime) {
      this.hasStaleData$.next(true);
      this.dataAge$.next(timeDiff);
    }
  }

  getDateAgeDiff(currentTime: number): number {
    return this.loadedOnTimestamp > 0 ? new Date(currentTime - this.loadedOnTimestamp).getMinutes() : 0;
  }

  initDeleteSuccess() {
    const totalItems: number = this.storeData.listMetaData.pageMetaData ? this.storeData.listMetaData.pageMetaData.totalItems | 0 : 0;
    const itemsPerPage: number = this.storeData.listMetaData.pageMetaData ? this.storeData.listMetaData.pageMetaData.itemsPerPage | 0 : 0;
    const pages: number = totalItems > 0 && itemsPerPage > 0 ? Math.ceil(totalItems / itemsPerPage) : 0;
    this.store.dispatch(new fromStore.ClearEventList({ storeName: this.vmListDefinition.storeName, parentId: this.vmListDefinition.parentId }));
    if (!this.storeData.data.length && pages > 1) {
      let newFilter = cloneDeep(this.listFilter);
      newFilter.currentPage += (newFilter.currentPage < pages ? 0 : -1);
      this.getComponentData(newFilter);
    }
  }

  setParentIds(parentParms: ParamMap, objectParams: ParamMap) {
    if (parentParms && objectParams) {

      //use parent id defined in list def - example workcrewid or tech id, instead of portalEntityId (like installerId) - if found
      this.portalEntityId = parentParms.has('portalEntityId') ? +parentParms.get('portalEntityId') : objectParams.has('portalEntityId') ? +objectParams.get('portalEntityId') : -1;
      if (Number.isNaN(this.portalEntityId)) {
        this.portalEntityId = -1;
      }
      //lists like those on dashboards will not have parents
      if (!this.vmListDefinition.parentId || this.vmListDefinition.parentId === 0) {
        this.vmListDefinition.parentId = -1;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    //List Definition will change when clicking thru (next / prev) for a sublist nav item.  The parent id is contained in the list definition, so changes with each click.
    //Example: InstallerCertificationWithRangeComponent
    if (changes['vmListDefinition'] && !changes['vmListDefinition'].firstChange) {
      const prevDef: IListDefinition = cloneDeep(changes['vmListDefinition'].previousValue);
      this.defaultFilter = cloneDeep(this.vmListDefinition.defaultListFilter);
      this.requiredTerms = cloneDeep(this.vmListDefinition.requiredSearchTerms);
      this.cleanUpStore(prevDef.parentId);
      this.initList();

      //request to load list does not include retrieval of data, just listener
      if (!this.vmListDefinition.noGet) {
        this.store.dispatch(new fromStore.SetListDefinition({ storeName: this.vmListDefinition.storeName, parentId: this.vmListDefinition.parentId, listDefinition: this.vmListDefinition }));
        this.getComponentData();
      }
    }
  }

  initList() {
    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(
        filter(x => x.requestor === this.vmListDefinition.storeName && x.id === this.vmListDefinition.parentId),
        map(x => x.show));

    //Starting screen size
    this.setSizes();
    this.childVisible$.next(false);
    this.operation$.next("");
    this.pendingTerms = cloneDeep(this.vmListDefinition.defaultListFilter
      ? this.vmListDefinition.defaultListFilter.searchTerm
      : []);
    this.listFilter = cloneDeep(this.vmListDefinition.defaultListFilter);
    this.defaultFilter = cloneDeep(this.vmListDefinition.defaultListFilter);
    this.requiredTerms = cloneDeep(this.vmListDefinition.requiredSearchTerms);

    this.isDeleting = false;
    this.deleteId = null;

    this.routePath = this.vmListDefinition.detailRoutePath;
  }

  setSizes(): void {
    const myLeft: number = this.el.nativeElement.getBoundingClientRect().left;
    const myWidth: number = this.el.nativeElement.getBoundingClientRect().width;
    let sizes: IListScreenSize = {
      screenIsSmall: this.screenService.isSmall(myLeft, myWidth),
      screenIsMedium: this.screenService.isMedium(myLeft, myWidth),
      screenIsLarge: this.screenService.isLarge(myLeft, myWidth),
      screenIsMax: this.screenService.isMax(myLeft, myWidth),
      screenisGreaterThanMax: this.screenService.isGtrThanMax(myLeft, myWidth)
    }
    //Ensure only one is set
    sizes.screenIsSmall = sizes.screenisGreaterThanMax || sizes.screenIsMax || sizes.screenIsLarge || sizes.screenIsMedium
      ? false : sizes.screenIsSmall;
    sizes.screenIsMedium = sizes.screenisGreaterThanMax || sizes.screenIsMax || sizes.screenIsLarge ? false : sizes.screenIsMedium;
    sizes.screenIsLarge = sizes.screenisGreaterThanMax || sizes.screenIsMax ? false : sizes.screenIsLarge;
    sizes.screenIsMax = sizes.screenisGreaterThanMax ? false : sizes.screenIsMax;
    //Check for matching true values in sizes and this.screenSize$ | return if matching
    for (var size in sizes) if (sizes[size] && this.screenSize$.value[size]) return;
    this.screenSize$.next(sizes);
  }

  //Handle bubble up events
  manageEvents(e: IHomEventEmitter) {
    switch (e.event) {
      case this.myConstants.emitterEventNavigation:
        this.handleNavigationOnDetail(e);
        break;
      case this.myConstants.emitterEventDetailDirty:
        this.handleDetailDirty(e);
        break;
      case this.myConstants.emitterEventCreate:
      case this.myConstants.emitterEventClose:
        this.handleDetailClose(e);
        break;
      case this.myConstants.emitterEventUpdate:
        //updates can also include navigation or stay open
        switch (e.action.toLowerCase()) {
          case this.myConstants.emitterActionNext:
            this.handleNavigationOnDetail(e);
            break;
          case this.myConstants.emitterActionHold:
            break;
          default:
            this.handleDetailClose(e);
            break;
        }
        break;
      default:
        this.handleListEvent(e);
        break;
    }
  }

  selectAll(isChecked: boolean): void {
    this.allSelected = isChecked;
    this.storeData.data.forEach((row) => {
      let btnEvent: IHomEventEmitter = { requestor: 'dynamic-list', event: this.vmListDefinition.groupSelect.eventName, action: isChecked ? 'selected' : '', data: row };
      this.selectEvent.emit(btnEvent);
    });
  }

  /*
        Called on new, edit and detail to correctly navigate to the child route within the correct named router outlet
    */
  navigateToDetail(id: number, optionalParameters: IDetailRouteParameter) {
    //use the custom create route path - custom would be set if differs from detail only
    const path = id === 0 && this.vmListDefinition.createRoutePath ? this.vmListDefinition.createRoutePath : this.routePath;

    const navigationExtras: NavigationExtras = {
      relativeTo: this.activatedRoute
    };

    const outletObj = JSON.parse('[{"outlets":{"' + this.vmListDefinition.routerOutlet + '": ["' + path + '","' + id + '",' + JSON.stringify(optionalParameters) + '] }}]');
    this.router.navigate(outletObj, navigationExtras);
  }

  /*
    the detail components subscribe to the store, so we need to ensure they are destroyed when the detail is closed.
    also, reset the active index to turn off any css using it and show the full list again
  */
  closeDetail() {
    const outletObj = JSON.parse('[{"outlets":{"' + this.vmListDefinition.routerOutlet + '": null }}]');
    const navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute };

    this.router.navigate(outletObj, navigationExtras);

    this.activeIndex = -1;
    this.childVisible$.next(false)
  }

  /*
      Creates the object for optional route parameters needed by the detail component
  */
  optionalRouteParameters(index: number): IDetailRouteParameter {
    if (this.routePath && this.routePath.length > 0) {

      let optionalParameters: IDetailRouteParameter = {
        rowIndex: index,
        storeName: this.vmListDefinition.storeName,
        key: this.vmListDefinition.rowKeyId,
        operation: this.currentOperation === "" ? this.myConstants.operationTypeDetails : this.currentOperation,
        showNext: this.vmListDefinition.showPrevNext && (this.vmListDefinition.itemsPerPage !== index + 1 && this.storeData.data.length != index + 1),
        showPrev: this.vmListDefinition.showPrevNext && index !== 0,
        requestTime: new Date(),
        portalEntityId: this.portalEntityId === -1 ? this.vmListDefinition.parentId : this.portalEntityId
      }
      return optionalParameters;
    } else {
      this.homErrorHandlerService.handleError({
        name: 'RouteError',
        message: 'optionalRouteParameters and no route path for store:' + this.vmListDefinition.storeName + ' for operation:' + this.currentOperation + ' and rowIndex: ' + index.toString()
      })
      return null;
    }
  }

  changeUserFilter(id: number): void {
    const selectedUserFilter = this.allUserListFilters.find(x => x.userListFilterId == id);
    if (!selectedUserFilter) return;
    this.updateUserFilters(selectedUserFilter);

    let newListFilter = cloneDeep(this.vmListDefinition.defaultListFilter);
    const searchTerms: ISearchTerm[] = this.dynamicListService.loadSavedFilterToSearchTerms(selectedUserFilter, this.vmListDefinition.requiredSearchTerms, this.fieldDefinitions);
    newListFilter.searchTerm = searchTerms;
    this.getComponentData(newListFilter);
  }

  updateUserFilters(userFilter: IUserListFilter): void {
    const userListFiltersDefinition = this.dynamicListService.loadUserListFiltersDefinition(this.listComponentId);
    const keyData: IKey = { storeName: userListFiltersDefinition.storeName, parentId: this.listComponentId, key: userListFiltersDefinition.rowKeyId, id: userFilter.userListFilterId };
    const emitter: IHomEventEmitter = { requestor: 'dynamic-list', event: this.myConstants.emitterEventListReload, action: 'UserFiltersReload', data: null };
    const updateData = new UpdateObjectCustomModel(keyData, 'UserListFilter', 'UpdateCurrent', userFilter, null, emitter);
    this.store.dispatch(new UpdateObjectCustomList({ updateData }));
  }

  //on initial load of list, no filter is current, update all to false
  initUserFilters(): void {
    const savedFilters = this.userFilters$.value;
    if (!savedFilters || !savedFilters.length) {
      return;
    }

    const userListFiltersDefinition = this.dynamicListService.loadUserListFiltersDefinition(this.listComponentId);
    this.subscription.add(this.dos.updateByMethodParams('UserListFilter', 'ClearCurrent', this.listComponentId.toString())
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.store.dispatch(new fromStore.GetList({
            listDefinition: userListFiltersDefinition,
            listFilter: userListFiltersDefinition.defaultListFilter,
            parentId: userListFiltersDefinition.parentId
          }));
        } else {
          this.homErrorHandlerService.handleError({
            name: 'UserListFilter ClearCurrent',
            message: 'Clearing out is current failed for ListComponentId: ' + this.listComponentId.toString()
          });
        }
      } ));
 }
  /*
    filter triggered from multiple places, isolate to single method
    always include required search terms, if any
    
  */
  filterList(clearAll: boolean = false): void {
    let newFilter: IListFilter = cloneDeep(this.listFilter);
    const requiredTerms: ISearchTerm[] = cloneDeep(!this.requiredTerms ? [] : this.requiredTerms);
    const newTerms: ISearchTerm[] = cloneDeep(!this.pendingTerms ? [] : this.pendingTerms);
    if (this.userFilters$.value) {
      let userFilters = cloneDeep(this.userFilters$.value);
      userFilters.forEach(filter => {
        filter.isActive = false;
      })
      this.userFilters$.next(userFilters);
      this.initUserFilters();
    }
    newFilter.currentPage = 1;
    newFilter.searchTerm = newTerms;

    //make sure required terms are still in filter, if they were modified, keep the modified value;
    requiredTerms.forEach(term => {
      if (newTerms.findIndex(x => x.term.toLowerCase() === term.term.toLowerCase()) === -1) {
        newFilter.searchTerm.push(term);
      }
    });

    //if newTerms exist in filter, update to include new values and searchTypes
    newTerms.forEach(term => {
      const indx = newFilter.searchTerm.findIndex(x => x.term.replace('-', '.').toLowerCase() === term.term.toLowerCase());
      if (indx === -1) {
        newFilter.searchTerm.push(term);
      } else {
        newFilter.searchTerm[indx].value = term.value;
        newFilter.searchTerm[indx].displayValues = term.displayValues;
        newFilter.searchTerm[indx].searchType = term.searchType;
      }
    });

    if (this.vmListDefinition.noGet && clearAll) {
      this.store.dispatch(new fromStore.SetListFilter({ storeName: this.vmListDefinition.storeName, listFilter: newFilter, parentId: this.vmListDefinition.parentId }));
      this.store.dispatch(new fromStore.InitializeListData({ storeName: this.vmListDefinition.storeName, parentId: this.vmListDefinition.parentId }));
    } else {
      this.getComponentData(newFilter);
    }
  }

  /*
      LISTENER: Filter enabled and a filter term was changed - need term (column key), searchType and value
      update the list of search terms with this term, appending or adding to.
      Keeps track of search term changes but doesn't trigger a filter event.

  */
  handleFilterChange(filterChange: IFilterChange) {
    const searchTerm = filterChange.searchTerm;
    let pendingIndx = this.pendingTerms.findIndex(x =>
      x.term.toLowerCase() === searchTerm.term.toLowerCase()
      || x.columnName.toLowerCase() === searchTerm.columnName.toLowerCase()
    );

    const noVal = searchTerm.value === undefined ||
      searchTerm.value === null ||
      searchTerm.value === "" ||
      (isArray(searchTerm.value) && searchTerm.value.length === 0);

    if (pendingIndx > -1) {
      if (noVal) {
        //remove term
        this.pendingTerms.splice(pendingIndx, 1);
      } else {
        this.pendingTerms[pendingIndx].value = searchTerm.value;
        this.pendingTerms[pendingIndx].searchType = searchTerm.searchType;
        this.pendingTerms[pendingIndx].displayValues = searchTerm.displayValues
          && searchTerm.displayValues.length ? searchTerm.displayValues : null;
      }
    } else if (searchTerm.value && searchTerm.value !== "" || (isArray(searchTerm.value) && searchTerm.value.length !== 0)) {
        this.pendingTerms.push(searchTerm);
    }

    if (this.clearAllRequest) {
      //update this key with the clear all
      this.clearAllList.filter(x => x.key === filterChange.key).map(y => y.cleared = true);

      if (this.clearAllList.findIndex(x => x.cleared === false) === -1) {
        this.clearAllRequest = false;
        this.pendingTerms = cloneDeep(this.defaultFilter ? this.defaultFilter.searchTerm : []);
        this.operation$.next('');
        this.filterList(true);
      }
    }

    this.workingFilterInfo$.next(this.dynamicListService.getFormattedFilterInfo(this.pendingTerms));
  }

  setClearAll(): void {
    this.clearAllList = [];
    this.clearAllRequest = true;

    let visibleCols: IFieldDefinition[] = [];

    this.vmListDefinition.listColumns.forEach((col) => {
      if (this.dynamicListService.isVisible(this.screenSize$.value, col)) {
        visibleCols.push(this.dynamicListService.getFieldDefinition(this.fieldDefinitions, col.key))
      }
    });

    visibleCols.forEach(col => {
      const fieldDef = this.fieldDefinitions.find(x => x.key == col.key);
      if (fieldDef && fieldDef.filterable) {
        this.clearAllList.push({ key: col.key, cleared: false });
      }
    })
  }

  handleDetailClose(e: IHomEventEmitter) {
    this.closeDetail();

    this.operation$.next('');
    this.changeDetectorRef.detectChanges();
  }


  //TODO: not implemented yet
  handleDetailDirty(e) {
    //  //set active record to dirty with a cool visual
  }

  //List Events for Detail will have events and those events can have actions (navigation for example)
  handleNavigationOnDetail(e: IHomEventEmitter) {
    //Determine what to do based on navigation action requested
    switch (e.action.toLowerCase()) {
      case this.myConstants.emitterActionNext:
        //load the next record
        const newNext: number = this.activeIndex + 1;
        let nextRow = this.storeData.data[newNext];
        if (nextRow) {
          this.activeIndex = newNext;
          this.showDetail({
            id: nextRow[this.vmListDefinition.rowKeyId],
            rowIndex: this.activeIndex,
            operation: this.operation$.getValue()
          });
        }
        break;

      case this.myConstants.emitterActionPrevious:
        const newPrev: number = this.activeIndex <= 0 ? 0 : this.activeIndex - 1; //added a failsafe check in case lose active index - should not happen
        let previousRow = this.storeData.data[newPrev];
        if (previousRow) {
          this.activeIndex = newPrev;
          this.showDetail({
            id: previousRow[this.vmListDefinition.rowKeyId],
            rowIndex: this.activeIndex,
            operation: this.currentOperation
          });
        }
        break;

      default:
        break;
    }
  }

  //handle bubble up list events
  handleListEvent(e: IHomEventEmitter) {
    switch (e.event) {
      case this.myConstants.emitterEventListReload:
        if (this.childVisible$.getValue()) {
          this.handleDetailClose(e);
        }
        let newListFilter = cloneDeep(this.defaultFilter);
        // pull into seperate function?
        if (this.vmListDefinition.storeName !== DynamicListStore.userListFilters) {
          if (this.defaultUserFilter) {
            const userDefaultSearchTerms: ISearchTerm[] = this.dynamicListService.loadSavedFilterToSearchTerms(this.defaultUserFilter, this.vmListDefinition.requiredSearchTerms, this.fieldDefinitions);
            newListFilter.searchTerm = userDefaultSearchTerms;
            // Needed to update db with current filter application
            this.updateUserFilters(this.defaultUserFilter);
            // the listener will hear these updates and fix the title
          } else {
            var userFilters = cloneDeep(this.userFilters$.value)
            if (userFilters) {
              userFilters.forEach(x => {
                x.isActive = this.defaultUserFilter && this.defaultUserFilter.userListFilterId ? true : false;
              })
              this.userFilters$.next(userFilters);
            }
          }
          this.getComponentData(newListFilter);
        }
        //emit paging event so containers will know a change in result set has been requested  
        this.pageEvent.emit();
        break;
      case this.myConstants.emitterEventListShowCreate:
        this.showCreate();
        break;
      case DynamicListEvent.sortList:
        const orderTerms: IOrderTerm[] = e.data;
        let newFilter: IListFilter = cloneDeep(this.listFilter);
        newFilter.orderTerm = cloneDeep(orderTerms);
        if (newFilter.getAll) {
          this.sortInMemory(newFilter);
        } else {
          this.getComponentData(newFilter);
        }
        break;
      case DynamicListEvent.showFilter:
        this.operation$.next('');
        this.inFilterMode = !this.inFilterMode;
        break;
      case DynamicListEvent.selectAll:
        this.selectAll(e.data);
        break;
      case this.myConstants.emitterEventFilterChanged:
        this.handleFilterChange(e.data);
        //emit filter event for containers who need to take action on a filter value change
        this.filterEvent.emit(e);
        break;
      case DynamicListEvent.clearAllFilters:
        this.clearAllFilters()
        break;
      case DynamicListEvent.runFilter:
        this.runFilter();
        break;
      case DynamicListEvent.createSavedFilter:
        this.showCreateSaveFilter();
        break;
      case DynamicListEvent.manageSavedFilters:
        this.showSavedFilterList();
        break;
      case DynamicListEvent.showDetail:
        this.showDetail(e.data);
        break;
      case DynamicListEvent.checkedRow: //via checkbox, so can selecte multiple rows
        this.selectClick(e.data);
        break;
      case DynamicListEvent.selectedRow: //via ooption, mutually exclusive
        this.optionClick(e.data);
        break;
      case DynamicListEvent.requestDelete:
        this.showDeleteQuestion(e.data);
        break;
      case DynamicListEvent.cancelDelete:
        this.cancelDelete();
        break;
      case DynamicListEvent.deleteRow:
        this.deleteRecord(e.data);
        break;
      case this.myConstants.emitterEventError:
        //if (isObject(e.data)) {
        //  this.errorMessage = '';
        //  this.errorData = e.data;
        //  if (e.requestor === this.vmListDefinition.detailRoutePath) {
        //    this.errorMessage += `${e.data.key}:  ${e.data.value.join(', ')}.`; //make note of the funky ticks, don't change those'
        //  }
        //} else {
        //  this.errorMessage = e.data;
        //}
        this.childVisible$.next(false)
        break;

      default:
        break;
    }

    this.changeDetectorRef.detectChanges();
  }

  setColCount() {
    if (!this.storeData) return;

    const plusOne: number = this.storeData.listDefinition
      && (this.vmListDefinition.showFilter || this.dynamicListService.showRowTools(this.vmListDefinition))
        ? 1 : 0;  //add 1 for the button column
    let maxCount = this.storeData.listDefinition ? size(lodashFilter(this.storeData.listDefinition.listColumns, c => !c.onlyOnSmall)) : 0;
    let largeCount = this.storeData.listDefinition ? size(lodashFilter(this.storeData.listDefinition.listColumns, c => c.visibleOnLarge)) : 0;
    let medCount = this.storeData.listDefinition ? size(lodashFilter(this.storeData.listDefinition.listColumns, c => c.visibleOnMedium)) : 0;
    let smallCount = this.storeData.listDefinition ? size(lodashFilter(this.storeData.listDefinition.listColumns, c => c.visibleOnSmall || c.onlyOnSmall)) : 0;
    const screenSize = this.screenSize$.getValue();
    this.colCount$.next((screenSize.screenIsSmall ? smallCount : screenSize.screenIsMedium ? medCount : screenSize.screenIsLarge ? largeCount : maxCount) + plusOne);
    this.setGridTemplateCols();
  }


  /*
      retrieves the list and meta data for the object instantiating this component.
      default is to utilize the Index method for the object.
  */
  getComponentData(newListFilter: IListFilter = null) {
    let useListFilter = newListFilter ? newListFilter : cloneDeep(this.listFilter);
    this.haveRetrievedData = true;
    this.store.dispatch(new fromStore.SetWorkingList({ storeName: this.vmListDefinition.storeName, parentId: this.vmListDefinition.parentId, working: true }));
    this.store.dispatch(new fromStore.GetList({ listDefinition: this.vmListDefinition, listFilter: useListFilter, parentId: this.vmListDefinition.parentId }));
    this.operation$.next('');
    //store data that is subscribed to in onInit will be updated wtih the above call and triggerr the refresh

    if (this.vmListDefinition.groupSelect && this.vmListDefinition.groupSelect.allowSelectAll) {
      if (this.allSelected) {
        this.allSelected = false;
      }
    }
  }

  /*
   * sort in memory if list is a get all 
  */
  sortInMemory(newListFilter: IListFilter): void {
    const event: IHomEventEmitter = { requestor: 'dynamic-list', event: DynamicListEvent.inMemorySort, action: '', data: null };
    this.store.dispatch(new fromStore.SortInMemory({
      listDefinition: this.vmListDefinition,
      listFilter: newListFilter,
      event: event,
      data: this.storeData.data
    }));
  }

  cleanUpStore(parentId: number): void {
    this.store.dispatch(new fromStore.InitializeList({ storeName: this.vmListDefinition.storeName, parentId: parentId, forOnDestroy: true }));
  }
  /*
    remove all subscriptions on close of this puppy
  */
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.resetStaleDataTimer();
    this.cleanUpStore(this.vmListDefinition.parentId);
  }

  getFieldDef(modelName: string): string {
    const keySplit: string[] = modelName ? modelName.split('.') : [''];
    const result: IFieldDefinition = this.fieldDefinitions && keySplit.length >= 0
      ? this.fieldDefinitions.find(x => x.key === keySplit[0])
      : null;
    return result ? result.fieldType : '';
  }
}
