import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { FwModule } from '../../../fw/fw.module';
import { ComplianceContainerModule } from '../compliance/compliance-container.module'; 
import { PortalComponent } from '../portal/portal.component';


@NgModule({
    declarations: [
      PortalComponent,
    ],
    imports: [
    RouterModule,
    CommonModule,
    FwModule,
    ComplianceContainerModule
    ],
    exports: [PortalComponent]
})

export class PortalModule {}
