
export interface IPrivilegeRuleEntitySpecifier {
  privilegeRuleEntitySpecifierId: number;
  provider_providerId: number;
  privilegeRuleId: string;
  name: string;
  description: string;
}

export class PrivilegeRuleEntitySpecifier implements IPrivilegeRuleEntitySpecifier {
  privilegeRuleEntitySpecifierId: number;
  provider_providerId: number;
  privilegeRuleId: string;
  name: string;
  description: string;


  constructor() {}
}
