/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IPoImportHeaderViewModel, ISeedContactInformation, IContactInfoViewModel } from '../../../view-models/index';
import { PoImportStore } from '../../enums/po-import.enums';
import { IGenericStateData } from '../../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { ContactEvent } from '../../../../contact/enums/contact.enums';
import { AppAction } from '../../../../../shared/enums/app-action.enums';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';

import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { UserPriviledgesService } from '../../../../../auth/services/index';
import { DomainObjectService } from '../../../../../shared/services';
import { ContactConstantsService, ContactUtilityService } from '../../../../contact/services';

//store actions, reducers, interfaces
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getSelectedRecord, getListByType, IDynamicListState, IListObjectData, GetList } from '../../../../../fw/dynamic-list/store/index';

@Component({
  selector: 'po-import-contact-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './po-import-contact-manager.component.html'
})
export class PoImportContactIManagerComponent implements OnInit, OnDestroy {
  public contactId$: BehaviorSubject<number> = new BehaviorSubject(0);
  public ready$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);;
  public canIEdit$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public poImportContactData: ISeedContactInformation;
  public linkedContact: boolean = false;

  uniqueId: number;
  errorsOnly: boolean = false;
  poImportHeaderId: number;
  poImportHeaderData: IPoImportHeaderViewModel;
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromFeature.IAllDynamicData>,
    public userPriviledgesService: UserPriviledgesService,
    public contactConstantsService: ContactConstantsService, //used on html
    public domainObjectService: DomainObjectService,
    public modalService: ModalService,
    public contactUtilityService: ContactUtilityService,
    public changeDetectorRef: ChangeDetectorRef,
    @Inject(appConstants) public myConstants: IAppConstants) {
  }

  public onCrudEvent(event: IHomEventEmitter) {
    switch (event.event) {
      case ButtonType.cancel:
        if (this.modalService.opened) {
          this.modalService.close();
        }
        break;
      case this.myConstants.emitterEventUpdateComplete:
        this.reloadPoImportList();
        this.onSuccess();
        break;
      case this.myConstants.emitterEventCreateComplete:
        this.updatePoImportHeader(event.data);
        break;
      case ContactEvent.linkContactToPo:
        this.mergePoImportData(event.data);
        break;
      case ContactEvent.unLinkContactToPo:
        //ui unlink, action does not save to db because the use ultimately associate a contact to the po here
        this.unLinkContact();
        break;
      case ContactEvent.selectMatch:
        this.contactId$.next(event.data ? event.data.contactId : 0);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.uniqueId = +paramMap.get('id');
      this.errorsOnly = paramMap.get('errorsOnly') === 'true';
      const stateData: IGenericStateData = window.history.state;
      this.ready$.next(false);
      this.getDetail(stateData);
    });
  }

  getDetail(stateData: IGenericStateData): void {
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(stateData.storeName, stateData.parentId, stateData.key, this.uniqueId)))
      .subscribe((entity: IPoImportHeaderViewModel) => {
        this.poImportHeaderData = cloneDeep(entity);
        if (!entity) {
          console.log('DEV ERROR:  Did parent modal close? If it did, it inited the store: ', stateData.storeName);
        } else {
          this.contactId$.next(entity.customerId | 0);
          this.canIEdit$.next(this.userPriviledgesService.canIEdit(entity));
          this.linkedContact = this.contactId$.value > 0;

          if (this.contactId$.value === 0) {
            this.loadSeedData();
          } else {
            this.ready$.next(true);
          }
        }
      })
    );
  }

  loadSeedData(): void {
    this.errorData$.next([]);
    const params: string = this.poImportHeaderData.vendorOrderNumber + '?branch=' + this.poImportHeaderData.branchName;
    this.subscription.add(this.domainObjectService.getListByMethod('PoImportHeader', 'GetContactFields', params, null)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.poImportContactData = cloneDeep(response.data);
          this.poImportContactData.middleName = this.poImportHeaderData ? this.poImportHeaderData.customerMiddleName : '';
          this.poImportContactData.birthDate = this.poImportHeaderData ? this.poImportHeaderData.birthDate : '';
          //init long and lat to ensure will revalidate.
          if (this.poImportContactData && this.poImportContactData.contactMechanismAddresses && this.poImportContactData.contactMechanismAddresses.length > 0) {
            this.poImportContactData.contactMechanismAddresses[0].address.latitude = null
            this.poImportContactData.contactMechanismAddresses[0].address.longitude = null
          }
        } else {
          this.errorData$.next(response.errorData);
        }
        this.ready$.next(true);
      }));
  }

  unLinkContact(): void {
    //reset contact id to 0, load seed data, set associated to false, operation type
    this.ready$.next(false);
    this.contactId$.next(0);
    //this.canIEdit$.next(false);
    this.linkedContact = false;
    this.loadSeedData();
  }

  updatePoImportHeader(contactId: number): void {
    const params: string = this.poImportHeaderData.vendorOrderNumber + '?branch=' + this.poImportHeaderData.branchName + '&customerId=' + contactId.toString();
    this.errorData$.next([]);
    this.subscription.add(this.domainObjectService.createByMethodParams('PoImportHeader', 'UpdateCustomer', params)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.reloadPoImportList();
          this.onSuccess();
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  mergePoImportData(contactModel: IContactInfoViewModel): void {
    this.errorData$.next([]);
    const data = {
      id: this.poImportHeaderData.vendorOrderNumber,
      branch: this.poImportHeaderData.branchName,
      model: contactModel
    }

    this.subscription.add(this.domainObjectService.updateByMethod('PoImportHeader', 'MergeCustomer', data, null)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.reloadContact(contactModel.contact.contactId);
          this.reloadPoImportList();
          this.onSuccess();
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  reloadPoImportList(): void {
    this.subscription.add(this.store.pipe(select(getListByType(this.errorsOnly ? PoImportStore.poImportGroupedErrors : PoImportStore.poImportGroupedHeaders)),take(1))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == -1)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          const storeData = cloneDeep(state);
          this.store.dispatch(new GetList({ listDefinition: storeData.listDefinition, listFilter: storeData.listFilter, parentId: storeData.parentId }));
        }
      }));
  }

  //Need to refresh the contact information:  contact, phone, addresses, emails post merge
  reloadContact(contactId: number): void {
    this.contactUtilityService.dispatchGetContact(contactId);
    this.contactUtilityService.dispatchEntityData(this.contactConstantsService.contactMechanismCategoryPhone, contactId);
    this.contactUtilityService.dispatchEntityData(this.contactConstantsService.contactMechanismCategoryEmail, contactId);
    this.contactUtilityService.dispatchEntityData(this.contactConstantsService.contactMechanismCategoryAddress, contactId);
  }

  onSuccess(): void {
    this.modalService.open({
      title: 'Action Result',
      path: 'app-action/' + AppAction.success,
      sizeType: ModalSizeType.xsmall,
      castExit: false,
      hasTabs: false,
      isSubModal: false //successful update should close the current modal and open this one.
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
