 <div class="form-wrapper" [ngClass]="{'form-wrapper--edit': operation !== myConstants.operationTypeDetails }">
  <div class="float-label">
    <select #overrideType (change)="changeOverride(overrideType.value)" appFocus [autoFocus]="true"
            [disabled]="operation === myConstants.operationTypeDetails">
      <option selected value="0">Choose...</option>
      <option *ngFor="let type of overrideTypes"
              [value]="type"
              [selected]="selectedType === type">
        {{type}}
      </option>
    </select>
    <label title="Override Type">Override Type</label>
  </div>

  <div *ngIf="overrideType.value === installerType" class="float-label">
    <select #installer
            [disabled]="operation === myConstants.operationTypeDetails || (installers && !installers.length)"
            (change)="changeInstaller(installer.value)">
      <option selected value="0">Choose...</option>
      <option *ngFor="let i of installers"
              [value]="i.installerId"
              [selected]="!this.object ? false : this.object.installer_installerId === i.installerId">
        {{i.installerName}}
      </option>
    </select>
    <label title="Narrow by Installer">Narrow by Installer</label>
  </div>

  <div *ngIf="overrideType.value === locType" class="float-label">
    <select #loc
            [disabled]="operation === myConstants.operationTypeDetails || (locations && !locations.length)"
            (change)="changeLocation(loc.value)">
      <option selected value="0">Choose...</option>
      <option *ngFor="let l of locations"
              [value]="l.providerLocationId"
              [selected]="!this.object ? false : this.object.providerLocation_providerLocationId === l.providerLocationId">
        {{l.locationName}}
      </option>
    </select>
    <label title="Narrow by Location">Narrow by Location</label>
  </div>

  <div *ngIf="overrideType.value === serviceType" class="float-label">
    <select #svc
            [disabled]="operation === myConstants.operationTypeDetails || (services && !services.length)"
            (change)="changeService(svc.value)">
      <option selected value="0">Choose...</option>
      <option *ngFor="let s of services"
              [value]="s.serviceId"
              [selected]="!this.object ? false : this.object.service_serviceId === s.serviceId">
        {{s.serviceName}}
      </option>
    </select>
    <label title="Narrow by Service">Narrow by Service</label>
  </div>

  <div *ngIf="(overrideType.value == serviceType || overrideType.value === locType) && locationServices" class="float-label">
    <label title="Narrow by Location Service">Narrow by Location Service</label>
    <fw-multiselect-dropdown #locServices [options]="locationServices"
                             [texts]="multiSelectLabels"
                             [settings]="multiSelectSettings"
                             [isDisabled]="!locationServices.length"
                             (onAdded)="addLocService($event)"
                             (onRemoved)="removeLocService($event)">
    </fw-multiselect-dropdown>
  </div>

  <div class="float-label">
    <input #payoutPrice type="text" digitOnlyMask [decimal]="true"
           class="number-width"
           id="skuPrice"
           required
           [disabled]="operation === myConstants.operationTypeDetails"
           placeholder="0.00"
           [value]="!object ? '0.00' : object.price || '0.00'"
           (change)="priceChange(payoutPrice.value)" />
    <label title="Price">Price</label>
  </div>
</div>
