  <div class="note-acks">
    <div class="note-acks__header">
      <div class="note-acks__header--col1">Sent To</div>
      <div class="note-acks__header--col2">Email Sent</div>
      <div class="note-acks__header--col3">Acknowledged</div>
    </div>
    <div class="note-acks__item" *ngFor="let notification of notifications">
      <div class="note-acks__item--col1">{{notification.recipientUserName}}</div>
      <div class="note-acks__item--col2"><span class="center app-icon--primary" *ngIf="notification.isEmail"><i class="fas fa-check"></i></span></div>
      <div class="note-acks__item--col3"><span class="center app-icon--primary" *ngIf="notification.acknowledged"><i class="fas fa-check"></i></span></div>
    </div>
    <div class="note-acks__footer">
      <div class="note-acks__footer--text" *ngIf="!notifications">
        No records found
      </div>
    </div>
  </div>
  <div class="app-btn-box">
    <button type="button" class="app-btn app-btn--cancel" title="Close" (click)="close()">
      Close
    </button>
  </div>
