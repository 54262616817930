import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { INote } from '../../../view-models';
import { IErrorData } from '../../../../../shared/interfaces';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { INoteReply } from '../../interfaces';
import { NoteReplyLength } from '../../enums/note.enums';

import {  getListByType} from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/index';
import { UpdateObjectByIdModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  selector: 'note-reply-manager',
  templateUrl: './note-reply-manager.component.html'
})
export class NoteReplyManagerComponent implements OnInit {
  public isPoImportNote: boolean = false;
  public errorData: IErrorData[] = null;
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public note$: BehaviorSubject<INote> = new BehaviorSubject(null);

  noteId: number;
  storeName: string;
  key: string;
  errorId: number = 0;
  maxReplyLength: number = 0;
  operation: string;
  requestTime: string = '';
  showNext: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public dynamicDetailService: DynamicDetailService,
    public emitterService: HomEventEmitterService,
      @Inject(appConstants) public myConstants: IAppConstants) {
    }

  //not applicable for poImportNote, no relationship to post it to
  public onChangePostIt(e: IHomEventEmitter): void {
    let note: INote = e.data;
    note.postIt = !note.postIt;
    note.updateDate = new Date().toDateString();
    const emitter: IHomEventEmitter = { requestor: 'note-reply-manager', event: this.myConstants.emitterEventUpdate, action: this.myConstants.emitterActionHold, data: { noteId: note.noteId } };
    const keyData: IKey = { storeName: this.storeName, parentId: -1, key: this.key, id: this.noteId }
    const updateData = new UpdateObjectByIdModel(keyData, 'Note', 'Update', this.key, this.noteId, note, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectByIdList({ updateData }));
  }

  public onSendReply(e: IHomEventEmitter): void {
    const reply: INoteReply = e.data;
    const entityName: string = this.isPoImportNote ? 'PoImportNote' : 'Note';
    const data: any = this.isPoImportNote ? { poImportNoteId: reply.noteId, responseText: reply.reply } : { noteId: reply.noteId, noteReplyText: reply.reply };
    const emitter: IHomEventEmitter = { requestor: 'note-reply-manager', event: this.myConstants.emitterEventListReload, action: '', data: null };
    const keyData: IKey = { storeName: this.storeName, parentId: -1, key: this.key, id: this.noteId, skipDataUpdate: true };
    const updateData = new UpdateObjectByIdModel(keyData, entityName, 'Acknowledge', this.key, this.noteId, data, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectByIdList({ updateData }));
  }

  public onCancel() {
    this.store.dispatch(new DynamicListActions.ClearErrorsList({ storeName: this.storeName, parentId: -1 }));
    this.closeManager();
  }

  ngOnInit() {
    this.activeRoute.data.subscribe(data => {
      this.maxReplyLength = data.hasOwnProperty('replyLength') ? +data['replyLength'] : NoteReplyLength.externalMax;
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.noteId = +paramMap.get('id');
      this.key = paramMap.get('key');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.showNext = paramMap.get('showNext') === 'true';
      this.note$.next(null);
      this.isPoImportNote = this.key === 'poImportNoteId';
      this.setDetailConfig(paramMap);
      this.listenForReload();
    });
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.wrapsForm = true;
    this.detailConfig$.next(params);
  }

  closeManager(): void {
    let emitter: IHomEventEmitter = { requestor: 'note-reply-manager', event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  listenForReload(): void {
    this.subscription.add(this.store.pipe(select(getListByType(this.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == -1)))
      .subscribe((state: IListObjectData) => {
        if (state && state.event && state.data && (!state.errorData || !state.errorData.length)) {
          if (state.event.event === this.myConstants.emitterEventListReload) {
            this.closeManager();
          }
        } else if (state && state.data && (!state.errorData || !state.errorData.length)) {
          const note = cloneDeep(state.data.find(x => x[this.key] == this.noteId));
          this.note$.next(note);
        }
        }));
    }
}
