  <div class="upload-area">
    <div #dropArea class="upload-area__drop" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
      <span class="upload-area__drop--icon"><i class="fas fa-plus"></i></span>
      <div class="upload-area__drop__label">
        <div class="upload-area__drop__label--left">drag</div>
        <div class="upload-area__drop__label--right">drop</div>
      </div>
    </div>
  </div>
  <div class="app-btn-box">
    <label class="upload-area__add app-btn app-btn--primary">
      Select file{{ entityDocument ? '' : 's'}}
      <input #files type="file"
             [attr.multiple]="!entityDocument ? '' : null"
             (change)="onSelected(files.files)">
    </label>
  </div>
