export interface IMarginResults {
  belowMargin: boolean;
  atMargin: boolean;
  aboveMargin: boolean;
}


export interface IWorkOrderItem {
  workOrderItemId: number;   
  workOrder_workOrderId: number;
  payout_payoutId: number;
  payoutDeck_payoutDeckId: number;
  purchaseOrderItem_purchaseOrderItemId: number;
  skuPayout_skuPayoutId: number;
  unitMeasure_unitMeasureId: number;
  itemName: string;
  quantity: number;
  unitPrice: number;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;

}


export class WorkOrderItem implements IWorkOrderItem {
  workOrderItemId: number;
  workOrder_workOrderId: number;
  payout_payoutId: number;
  payoutDeck_payoutDeckId: number;
  purchaseOrderItem_purchaseOrderItemId: number;
  skuPayout_skuPayoutId: number;
  unitMeasure_unitMeasureId: number;
  itemName: string;
  quantity: number;
  unitPrice: number;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;

  constructor() {
    this.workOrderItemId = 0;
    this.workOrder_workOrderId = null;
    this.payout_payoutId = null;
    this.payoutDeck_payoutDeckId = null;
    this.purchaseOrderItem_purchaseOrderItemId = null;
    this.skuPayout_skuPayoutId = null;
    this.unitMeasure_unitMeasureId = null;
    this.quantity = 0;
    this.unitPrice = 0;

    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';

  }

}

