
<div *ngIf="!loading" class="hd-measures__info">
  <i class="fas fa-info-circle app-icon--primary"></i>
  Can't find your measure?  Use <span class="app-link" (click)="switchToDefault()">default</span> measure service and upload your own.
</div>

<div *ngIf="loading" class="hd-measures__wait">
  <i class="fas fa-info-circle app-icon--primary"></i>
  <span *ngIf="externalData?.length">Working with the Home Depot to process your selection...</span>
  <span *ngIf="!externalData">Retrieving measures from the Home Depot....</span>
</div>

<div *ngIf="externalData?.length">
  <table class="app-table app-table__compact  app-table__lined hd-measures__table">
    <thead>
      <tr>
        <th>Calculation Time</th>
        <th></th>
        <th>Line</th>
        <th>Flooring</th>
        <th>Description</th>
        <th>Width</th>
        <th>Pattern Match</th>
        <th>Amount Material</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of externalData; let rowIndex = index" class="hd-measures__body" [ngClass]="{ 'hd-measure__item--line': row.numberLines === 1 || (row.numberLines > 1 && row.LineNumber === 1) }">
        <td *ngIf="row.numberLines === 1 || (row.numberLines > 1 && row.lineNumber === 1)" [attr.rowspan]="row.numberLines"
            class="hd-measure__item--key"  [ngClass]="{ 'hd-measure__item--selected': row.entityDocumentId.id !== '0' && row.entityDocumentId.line === '0' }">
          <span class="app-link hd-measure__item--link" (click)="openLink(rowIndex, -1)">
            {{ row.calcDate | date:'MM/dd/yyyy hh:mm'  }}
          </span>
        </td>
        <td *ngIf="row.numberLines === 1 || (row.numberLines > 1 && row.lineNumber === 1)" [attr.rowspan]="row.numberLines" class="hd-measure__item--key">
          <button *ngIf="row.entityDocumentId.id === '0'" type="button"
                  class="app-btn-icon app-btn-icon--success"
                  (click)="selectItem(rowIndex, -1)"
                  [disabled]="!canEdit"
                  title="Select Measure">
            <span class="fas fa-check "></span>
          </button>
          <button *ngIf="row.entityDocumentId.id !== '0' && row.entityDocumentId.line === '0'" type="button"
                  class="app-btn-icon app-btn-icon--danger"
                  (click)="unSelectItem(rowIndex, -1)"
                  [disabled]="!canEdit"
                  title="Remove Measure Selection">
            <span class="fas fa-times app-btn-icon--danger"></span>
          </button>
        </td>
        <td>{{row.lineNumber}} </td>
        <td>{{row.materialType}} </td>
        <td>{{row.materialDescription}} </td>
        <td>{{row.materialWidth}} </td>
        <td>{{row.patternMatch}} </td>
        <td [ngClass]="{ 'hd-measure__item--selected': row.entityDocumentId.id !== '0' && row.entityDocumentId.line === row.lineNumber.toString() }">
          <span class="app-link hd-measure__item--link" (click)="openLink(rowIndex, row.lineNumber)">
            {{row.materialAmount}}
          </span>
        </td>
        <td>
          <button *ngIf="row.entityDocumentId.id === '0'" type="button"
                  class="app-btn-icon app-btn-icon--success"
                  (click)="selectItem(rowIndex, row.lineNumber)"
                  [disabled]="!canEdit"
                  title="Select Measure">
            <span class="fas fa-check "></span>
          </button>
          <button *ngIf="row.entityDocumentId.id !== '0' && row.entityDocumentId.line === row.lineNumber.toString()" type="button"
                  class="app-btn-icon app-btn-icon--danger"
                  (click)="unSelectItem(rowIndex, row.lineNumber)"
                  [disabled]="!canEdit"
                  title="Remove Measure Selection">
            <span class="fas fa-times"></span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="!loading && !externalData?.length" class="hd-measures__info dynamic-list__no-data">
  No Measures Returned from the Home Depot
</div>

