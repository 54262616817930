import { Component, OnInit, Input, Output, Inject, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IEntityDocument } from '../../../portals/view-models/index_two';
import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';
import { IErrorData } from '../../../../shared/interfaces';

import { UserPriviledgesService } from '../../../../auth/services';
import { FileUploaderService } from '../../services/file-uploader.service';
import { MetaDataService } from '../../../../fw/dynamic-list/services';
import * as fromFeature from '../../../../fw/dynamic-list/store/reducers/feature.reducer';

@Component({
  selector: 'file-viewer-detail',
  templateUrl: './file-viewer-detail.component.html',
  providers: [MetaDataService]
})

export class FileViewerDetailComponent implements OnInit, OnChanges {

  @Input() item: IEntityDocument;
  @Input() storeName: string;
  @Input() storeKeyName: string;
  @Input() parentId: number;
  @Input() operation: string;
  @Input() errorData: IErrorData[];

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public fileName: string;
  public canIEdit: boolean;
  public canIDelete: boolean;
  public displayFields: string[] = ['fileName', 'includeInWorkOrderPacket'];
  public canIncludeInWOPacket$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public form: FormGroup;

  constructor(public store: Store<fromFeature.IAllDynamicData>,
    public ups: UserPriviledgesService,
    public mds: MetaDataService,
    public fus: FileUploaderService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public customButtons: ICustomButton[] = [];

  public onFileDetailEvent(emitterEvent: IHomEventEmitter): void {
    let emitter: IHomEventEmitter = {
      requestor: 'file-viewer-detail',
      event: emitterEvent.event,
      action: '',
      data: null
    }
    switch (emitterEvent.event) {
      case ButtonType.save:
        this.emitSave(emitterEvent);
        break;
      case ButtonType.delete:
        emitter.data = this.item;
        this.customEvent.emit(emitter);
        break;
      default:
        this.customEvent.emit(emitterEvent);
        break;
    }
 }

  ngOnInit(): void {
    this.newRequest();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item'] && !(changes['item'].firstChange)) {
      this.newRequest();
    }
  }

  newRequest(): void {
    this.setCustomButtons();
    this.canIEdit = this.ups.canIEdit(this.item);
    this.canIDelete = this.ups.canIDelete(this.item);
    this.canIncludeInWOPacket$.next(this.fus.validateSelected(this.item.fileType, this.fus.woPacketAllowedFileTypes));

    this.mds.setFieldDefinitions(this.storeName);
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.item, this.myConstants.operationTypeDetails);

  }

  setCustomButtons(): void {
    this.customButtons = [
      {
        title: 'Preview',
        icon: 'fa-file-image',
        cssName: 'app-btn-icon--primary',
        enabled: true,
        eventName: 'preview',
        linkUrl: this.fus.isPdf2(this.item.fileName) ? this.item.fileUrl : this.item.imageUrl
      },
      {
        title: 'Download',
        icon: 'fa-download',
        cssName: 'app-btn-icon--custom-1',
        enabled: true,
        eventName: 'download',
        linkUrl: this.item.fileUrl,
        isDownload: true,
        fileName: this.item.fileName
      }
    ];
  }

  emitSave(event: IHomEventEmitter): void {
    let entityDocument = this.setCommonProperties();
    let emitter: IHomEventEmitter = {
      requestor: 'file-viewer-detail',
      event: event.event,
      action: '',
      data: entityDocument
    }
    this.customEvent.emit(emitter);
  }

  setCommonProperties() {
    let entityDocument = { ...this.item };
    const formData: IEntityDocument = this.form.getRawValue(); //just 2 fields at this time

    entityDocument.fileName = formData.hasOwnProperty('fileName') ? formData.fileName : this.item.fileName;
    entityDocument.includeInWorkOrderPacket = formData.hasOwnProperty('includeInWorkOrderPacket') ? formData.includeInWorkOrderPacket : this.item.includeInWorkOrderPacket;
    return entityDocument;
  }
}
