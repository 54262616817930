<fw-component-title [title]="'Sku'" [entityLabel]="entityLabel"></fw-component-title>
<div *ngIf="(listDefinition$ | async)" [ngClass]="{ 'loading-mask': loading$ | async }">
  <div class="price-wizard">
      <div class="float-label">
        <input #globalPriceEl type="text" digitOnlyMask [decimal]="true"
               class="number-width"
               [disabled]="!selectedSkus.length"
               placeholder="0.00"
               [value]="defaultGlobalPrice"
               (change)="onGlobalPriceChange($event.target.value)" />
        <label htmlFor="globalPriceEl" title="'Apply To All Selected Skus">Apply To All Selected</label>
      </div>
      <button type="button"
              class="app-btn app-btn--primary price-wizard--apply"
              [disabled]="!selectedSkus.length"
              title="Apply price locally"
              (click)="applyGlobalPrice()">
        Apply To Selected
      </button>
      <fw-info [infoTitle]="'Apply Information'">
        <div class="price-wizard--info">
          <p>Select one or more skus then click Apply to apply price locally.</p>
          <p>Click Update to save changes.</p>
        </div>
      </fw-info>
  </div>

  <div class="app-btn-box">
    <button class="app-btn app-btn--primary"
            type="button"
            (click)="updateSkuPrices()"
            [disabled]="!selectedSkus.length">
      Update Selected
    </button>
  </div>
  <div class="list-wrapper">
    <fw-dynamic-list [vmListDefinition]="listDefinition$ | async"
                     [listTitle]="'Pricing'"
                     (customEvent)="onListCustom($event)"
                     (selectEvent)="onListItemSelect($event)"
                     (pageEvent)="onListPage()">
    </fw-dynamic-list>
    <div class="app-btn-box">
      <button class="app-btn app-btn--primary"
              type="button"
              (click)="updateSkuPrices()"
              [disabled]="!selectedSkus.length">
        Update Selected
      </button>
    </div>
    <fw-error-box *ngIf="(errorData$ | async) as errorData" [errorData]="errorData" [ignoreKey]="true"></fw-error-box>
  </div>
  </div>

