import {  Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { IPortalTab, IPortalTabContainer, IUserPreference } from '../../../app/portals/view-models';
import { getTabContainerByName, getAllMyWidgets, hasValidSecurityAccess } from '../../../auth/store/auth.selectors';
import { SetValidUserAccess } from '../../../auth/store';
import * as fromRoot from '../../../app/store/reducers/index';
import { MenuService } from '../../fw-menus/services/menu.service';
import { RouteToCrmService } from "../../../app/route-to-crm/route-to-crm.service";
import { TabsService } from "../../fw-shared/components/fw-app-tabs/services/fw-app-tabs.service";
import { SmsService } from '../../../app/sms/services/sms.service';
import { UserPriviledgesService } from '../../../auth/services';

@Component({
  selector: 'fw-app-content',
  templateUrl: './fw-app-content.component.html'
})
export class FwAppContentComponent implements OnInit, OnDestroy {
  @Input() userPreferences: IUserPreference;

  public portalLevel: number;
  public limit: number;
  public tabDefinitions: IPortalTab[];
  public invalidUserSetup: boolean = false;

  container$: BehaviorSubject<IPortalTabContainer> = new BehaviorSubject(null);
  subscription: Subscription = new Subscription();

  constructor(
    public router: Router,
    public rootStore: Store<fromRoot.IState>,
    public menuService: MenuService,
    public routeToCrmService: RouteToCrmService,
    public tabsService: TabsService,
    public smsService: SmsService,
    public ups: UserPriviledgesService   ) { }

  ngOnInit(): void {
    this.invalidUserSetup = false;

    this.subscription.add(this.rootStore.pipe(
      select(getTabContainerByName('app-tabs')),
      filter(container => container !== null), take(1))
      .subscribe((container: IPortalTabContainer) => {
        if (container) {
          this.portalLevel = container.portalLevel;
        }
        this.container$.next(container);
      }));

    this.subscription.add(this.container$.subscribe((val) => {
      if (val) {
        this.setTabs(val);
      }
    }));

    this.subscription.add(this.rootStore.pipe(select(hasValidSecurityAccess),
      filter(val => val === false))
      .subscribe(val => {
        if (this.ups.authenticated$.value) {
          this.invalidUserSetup = true;
          this.router.navigate(['invalid-user']);
        }
      }));

    this.smsService.init();
  }

  public tabRequestComplete(complete: boolean): void {
    if (complete) {
      if (this.menuService.hasTabs$.value === false) {
        this.menuService.hasTabs$.next(true);
      }
      if (this.routeToCrmService.handleUnauthenticatedRequest) {
        this.routeToCrmService.handleRouting();
      }
    }
  }
  

  //only do this on init, not on changes to user preferences
  setTabs(container) {
    this.subscription.add(this.rootStore.pipe(select(getAllMyWidgets), filter(data => data && data.length > 0))
      .subscribe((widgets) => {
        let allTabs: IPortalTab[] = cloneDeep(container.portalTabs);
        let myTabs: IPortalTab[] = [];
        let hasDefinedDefault: boolean = widgets.filter(x => x.managerPortal_managerPortalId === this.userPreferences.startTabInfo.managerPortalId).length > 0;
        let hasDefaultSet: boolean = false;

        allTabs.forEach(tab => {
          //only add the tab if at least one widget for that portal id exists.
          if (widgets.filter(x => x.managerPortal_managerPortalId === tab.managerPortalId).length > 0) {
            if ((!hasDefinedDefault && tab.managerPortalId === 2) || (tab.managerPortalId === this.userPreferences.startTabInfo.managerPortalId)) {
              tab.isDefault = true;
              tab.isDynamic = false;
              tab.closeable = false;
              hasDefaultSet = true;
            } else {
              tab.closeable = !tab.isDefault && tab.isDynamic;
            }
            myTabs.push(tab);
          }
        });

        if (this.routeToCrmService.params) {
          myTabs.find(x => x.isDefault).isDefault = false;
          myTabs.push(this.routeToCrmService.createPortalTab());
        }
        
        this.tabDefinitions = [...myTabs];
        const defaultTab: IPortalTab = myTabs.find(x => x.isDefault == true);
        //if manager portal tab does not have sub tabs, then set hasTab$ to true now;
        if (defaultTab && defaultTab.managerPortalId === 6 || defaultTab.managerPortalId === 15) {
          this.menuService.hasTabs$.next(true);
        }
        this.rootStore.dispatch(new SetValidUserAccess(hasDefaultSet));
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

