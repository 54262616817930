<div id="eid" [ngClass]="{ 'loading-mask':  (loading$ | async) }">
  <fw-dynamic-detail-container *ngIf="(detailConfig$ | async)" [detailConfig]="detailConfig$ | async">

    <fw-dynamic-form *ngIf="formDefinition$ | async"
                     [vm]="senderInfo$ | async"
                     [storeName]="storeName"
                     [vmFormDefinition]="formDefinition$ | async"
                     [parentId]="(detailConfig$ | async).parentId"
                     [displayFields]="displayFields"
                     [operation]="myConstants.operationTypeDetails">
    </fw-dynamic-form>

    <div *ngIf="(uploadFiles$ | async) as uploadFiles" class="component__detail-box">

      <fw-component-title [title]="'Import Files'" [entityLabel]="uploadFiles.length + ' Items'"></fw-component-title>

      <div *ngIf="uploadFiles.length" class="import-all">
        <input #projectInput class="import-all__input" type="text" digitOnlyMask value="" placeholder="Project Id..." (input)="setProjectId(projectInput.value)" />
        <button [disabled]="!projectId" class="app-btn app-btn--primary" (click)="uploadAll()" title="Import All">Import All</button>
      </div>

      <file-previewer #filePreviewer
                      [files]="uploadFiles"
                      [allowMultiple]="true"
                      [listType]="listType"
                      (uploadEvent)="uploadItem($event)"
                      (deleteEvent)="removeItem($event)"
                      (changeEvent)="clearError($event)">
      </file-previewer>
      <div class="app-btn-box">
        <button *ngIf="showCancel" class="app-btn app-btn--danger"
                title="Cancel"
                (click)="close()">
          Cancel
        </button>
      </div>
    </div>

  </fw-dynamic-detail-container>
  <fw-error-box [errorData]="errorData$ | async" [errors]="errors$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>
