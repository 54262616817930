import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { AdminEvent } from '../../enums/admin.enums';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { IShipper } from '../../../view-models/i-shipper';

@Component({
  selector: 'inventory-provider-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './inventory-provider-manager.component.html'
})
export class InventoryProviderManagerComponent implements OnInit {
  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService,
    public modalService: ModalService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.manageShipperLocations:
        this.openShipperLocations(event.data);
        break;
      case AdminEvent.manageShipperPayouts:
        this.openShipperPayouts(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadShippersListDefinition();
  }

  openShipperLocations(shipper: IShipper): void {
    this.modalService.open({
      title: 'Locations for Shipper'.concat(' ', shipper.shipperName),
      path: 'shipper-locations/' + shipper.shipperId.toString(),
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false
    });
  }

  openShipperPayouts(shipper: IShipper): void {
    this.modalService.open({
      title: 'Payouts for Shipper'.concat(' ', shipper.shipperName),
      path: 'shipper-payouts/' + shipper.shipperId.toString(),
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false
    });
  }
}
