<div *ngIf="technician">
  <fw-button-title-bar [title]="'Overview'"
                       [entityLabel]="'Technician'"
                       [canIEdit]="canIEdit"
                       [operation]="operation"
                       [disabled]="!form.dirty || !form.valid"
                       [staleData]="false"
                       [showEdit]="true"
                       [showRefresh]="true"
                       [showDelete]="false"
                       (customEvent)="onCustom($event)">
  </fw-button-title-bar>

  <form *ngIf="form" [formGroup]="form" novalidate class="form portal__form" [ngClass]="{'portal__form--edit': operation !== myConstants.operationTypeDetails}">
    <ng-container>
      <fw-dynamic-field [field]="mds.getFieldDefinition('technicianDescription')" [form]="form" [operation]="operation"> </fw-dynamic-field>
    </ng-container>
    <fw-dynamic-field [field]="mds.getFieldDefinition('membershipUserName')" [form]="form" [operation]="operation"></fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('isActive')" [form]="form" [operation]="operation"></fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('isWorkCrewLead')" [form]="form" [operation]="operation"></fw-dynamic-field>
  </form>
  <fw-component-footer [lastUpdate]="technician.updateDate"></fw-component-footer>
</div>
