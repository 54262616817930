import { Component, OnInit } from '@angular/core';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './work-category-payouts.component.html',
  selector: 'work-category-payouts'
})
export class WorkCategoryPayoutsComponent implements OnInit {

  public listDefinition: IListDefinition;
  workCategoryId: number;

  constructor(
    public adminProviderService: AdminProviderService,
    public activeRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.workCategoryId = +paramMap.get('id');
      this.listDefinition = this.adminProviderService.loadWorkCategoryPayoutsListDefinition(this.workCategoryId);
    });
  }

}
