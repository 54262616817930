//FileUploadType is used in ui only to determine callee request type,
//does not correspond to anything in the backend
export enum FileUploadType {
  contactImage = '1',
  projectRequired = '2',
  projectLocal = '3',
  projectFileImport = '4',
  emailFileImport = '5',
  poMeasure = '6',
  survey = '7'
}

export enum DocumentTypeName {
  measure = 'Measure',
  lienWaiver = 'Lien Waiver',
  permit = 'Permit',
  leadSafeForm = 'LSWP Lead Safe Form',
  localProjectFile = 'Local Project File',
  leadExceptionForm = 'LSWP Exception Form',
  smsIncoming = 'Sms Incoming File'
}

export enum DocumentTypeActionTypeName {
  uploadFile = 'UploadFile',
  getSignature = 'GetSignature',
  automatic = 'Automatic',
  generateFile = 'GenerateFile',
  markComplete = 'MarkComplete'
}

//others:  program service file
export enum ProjectFileEvent {
  launchFileViewer = 'launchFileViewer',
  downloadFile = 'downloadFile'
}

