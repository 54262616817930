import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomDecimalPipe } from 'hom-lib/hom-pipes';

import {
  IService, IPayoutPrice, PayoutPrice, IGeneralContractor,
  IProviderLocation, IProviderLocationService
} from '../../../view-models';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import {
  IMultiSelectOption, MultiSelectTexts,
  MultiSelectSettings, MultiSelectDropdown
} from '../../../../../fw/fw-shared/components/fw-multi-select-dropdown';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { SkuCreationWizardEvent, PricingStore } from '../../enums/pricing-shared.enums';

import * as fromRoot from '../../../../store/reducers/index';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import { PricingSharedService } from '../../services/pricing-shared.service';
import { DomainObjectService } from '../../../../../shared/services';
import { CreateObjectModel, IKey, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces';
import { CreateObjectList, UpdateObjectCustomList } from '../../../../../fw/dynamic-list/store';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  selector: 'payout-price',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './payout-price.component.html'
})
export class PayoutPriceComponent implements OnInit, OnDestroy {
  @ViewChild('services') public svcMultiSelect: MultiSelectDropdown;

  public globalType: string = 'Global';
  public gcType: string = 'General Contractor';
  public plType: string = 'Provider Location';
  public serviceType: string = 'Service';
  public overrideTypes: string[] = [];
  public generalContractors: IGeneralContractor[] = [];
  public providerLocations: IProviderLocation[] = [];
  public services: IService[] = [];
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public objectData$: BehaviorSubject<IPayoutPrice> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public selectedOverride: string = '';
  public operation: string;

  public multiSelectLabels: MultiSelectTexts = new MultiSelectTexts();
  public multiSelectSettings: MultiSelectSettings = new MultiSelectSettings();
  public multiSelectOptions: IMultiSelectOption[] = [];
  selectedGcId: number = 0;
  selectedProviderLocationId: number = 0;
  selectedServiceId: number = 0;
  selectedLocationServiceIds: number[] = [];
  price: number = 0;

  subscription = new Subscription();
  parentId: number = -1;
  payoutId: number = 0;
  payoutPriceId: number = 0;
  gcId: number = 0;
  inWizard: boolean = false;
  storeName: string = '';
  key: string = '';
  requestTime: string = '';
  detailRoutePath: string = '';
  actionOnSuccess: string;
  defaultGc: IGeneralContractor = null;

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public pricingService: PricingSharedService,
    public emitterService: HomEventEmitterService,
    public domainObjectService: DomainObjectService,
    public dynamicDetailService: DynamicDetailService,
    public homDecimalPipe: HomDecimalPipe,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  public ngOnInit() {
    //originally created for the wizard
    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.parentId = +paramMap.get('id') || -1;
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.payoutPriceId = paramMap.has('id') ? +paramMap.get('id') :  0;
      this.payoutId = paramMap.has('payoutId') ? +paramMap.get('payoutId') : this.parentId;
      this.gcId = +paramMap.get('gcId');
      this.inWizard = paramMap.get('wiz') ? true : false;
      this.key = paramMap.has('key') ? paramMap.get('key') : 'payoutPriceId';
      this.operation = paramMap.has('operation') ? paramMap.get('operation') : this.myConstants.operationTypeCreate;
      this.storeName = paramMap.has('storeName') ? paramMap.get('storeName') : PricingStore.payoutPrices;
      this.requestTime = paramMap.has ? paramMap.get('requestTime') : '';
      if (!this.inWizard) {
        this.setDetailConfig(paramMap);
      }
      this.newRequest();
    });
  }

  public changeOverride(val: string): void {
    this.selectedOverride = val;
    this.selectedLocationServiceIds = [];
    this.selectedGcId =  this.selectedProviderLocationId = this.selectedServiceId = 0;
    this.multiSelectOptions = [];
    //init gc selection
    const gcList = cloneDeep(this.generalContractors);
    this.generalContractors = [];
    this.generalContractors = gcList;
   //init pl selection
    const plList = cloneDeep(this.providerLocations);
    this.providerLocations = [];
    this.providerLocations = plList;
    //init svc selection
    const svcList = cloneDeep(this.services);
    this.services = [];
    this.services = svcList;

    this.isValid$.next(this.getIsValid());
  }

  public changeGc(id: number): void {
    this.selectedGcId = id;
    this.isValid$.next(this.getIsValid());
  }

  public changeProviderLocation(id: number): void {
    this.selectedProviderLocationId = id;
    this.multiSelectOptions = [];
    if (this.svcMultiSelect) {
      this.svcMultiSelect.uncheckAll();
    }
    //load related provider location services
    this.loadLocationServices(id);
    this.isValid$.next(this.getIsValid());
  }

  public addLocService(id: number): void {
    this.selectedLocationServiceIds = [...this.selectedLocationServiceIds, id];
    this.isValid$.next(this.getIsValid());
  }

  public removeLocService(id: number): void {
    const exists: number = this.selectedLocationServiceIds.findIndex(x => x === id);
      if (exists > -1) {
        this.selectedLocationServiceIds.splice(exists, 1);
    }
    this.isValid$.next(this.getIsValid());
  }

  public changeService(id: number): void {
    this.selectedServiceId = id;
    this.isValid$.next(this.getIsValid());
  }

  public priceChange(val: any): void {
    this.price = val;
  }

  public onCancel(): void {
    if (this.inWizard) {
      const e: IHomEventEmitter = { requestor: 'payout-price', event: SkuCreationWizardEvent.cancel, action: '', data: null }
      this.emitterService.emitSkuWizardEvent(e);
    } else {
      //emit list event
      const closeEvent: IHomEventEmitter = { requestor: 'payout-price', event: this.myConstants.emitterEventClose, action: '', data: null };
      this.emitterService.emitListEvent(closeEvent);
    }
  }

  public onSave(): void {
    if (this.operation === this.myConstants.operationTypeCreate) {
      this.createRecord();
    } else {
      this.updateRecord();
    }
  }

  newRequest() {
    this.getDetail();
    this.overrideTypes = [this.globalType, this.gcType, this.plType, this.serviceType];
    this.multiSelectLabels = new MultiSelectTexts();
    this.multiSelectLabels.defaultTitle = 'None...';

    this.multiSelectSettings = new MultiSelectSettings();
    this.multiSelectSettings.selectionLimit = 0;
    this.multiSelectSettings.closeOnSelect = false;
    this.multiSelectSettings.autoUnselect = false;
    this.multiSelectSettings.showCheckAll = true;
    this.loadStaticSelectionLists();
  }

  //not set for wizard
  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.payoutId;
    
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.storeName, this.payoutId, this.key, this.payoutPriceId)))
      .subscribe(entity => {
        this.objectData$.next(!entity && this.operation === this.myConstants.operationTypeCreate
          ? new PayoutPrice()
          : cloneDeep(entity));
        const payoutPrice: IPayoutPrice = this.objectData$.value;
        //Location then Service then Gc then Global - order of precedence
        this.selectedOverride = payoutPrice.providerLocation_providerLocationId ? this.plType
          : payoutPrice.service_serviceId ? this.serviceType : payoutPrice.generalContractor_generalContractorId
            ? this.gcType : payoutPrice.isGlobal ? this.globalType : this.globalType;
        this.selectedGcId = payoutPrice.generalContractor_generalContractorId;
        this.selectedProviderLocationId = payoutPrice.providerLocation_providerLocationId;
        this.selectedServiceId = payoutPrice.service_serviceId;
        this.price = payoutPrice.defaultDeckPrice;
        this.isValid$.next(this.getIsValid());
      })
    );
  }

  loadStaticSelectionLists(): void {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('generalContractor')))
      .subscribe((data: IGeneralContractor[]) => {
        this.generalContractors = data;
        this.defaultGc = this.generalContractors ? this.generalContractors.find(x => x.generalContractorId === this.gcId) : null
      }));

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('providerLocation')))
      .subscribe((data: IProviderLocation[]) => {
        this.providerLocations = data;
      }));


    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('allService')))
      .subscribe((data: IService[]) => {
        this.services = data;
      }));
  }

  loadLocationServices(id: number): void {
    let multiSelectOptions: IMultiSelectOption[] = [];
    this.working$.next(true);
    this.subscription.add(this.domainObjectService.getByMethodById('ProviderLocationService', 'ByProviderLocation', id)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          result.data.forEach((pls: IProviderLocationService) => {
            multiSelectOptions.push({ id: pls.providerLocationServiceId, name: pls.serviceName });
          });
          this.multiSelectOptions = multiSelectOptions;
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));
  }

  getIsValid(): boolean {
    let isValid: boolean = false;
    switch (this.selectedOverride) {
      case this.globalType:
        isValid = true;
        break;
      case this.gcType:
        isValid = this.selectedGcId > 0;
        break;
      case this.plType:
        isValid = this.selectedProviderLocationId > 0;
        break;
      case this.serviceType:
        isValid = this.selectedServiceId > 0;
        break;
      default:
        break;
    }
    return isValid;
  }

  createRecord(): void {
    const payoutPrice: IPayoutPrice = this.setCommonProperties();
    const defaultDeckPrice = this.setDefaultDeckPrice();
    let obj = {
      model: payoutPrice,
      selectedLocationServices: this.selectedLocationServiceIds.length > 0 ? this.selectedLocationServiceIds : null,
      defaultDeckPrice: defaultDeckPrice
    };

    if (this.inWizard) {
      this.createForWiz(obj);
    } else {
      const e: IHomEventEmitter = { requestor: 'payout-price', event: this.myConstants.emitterEventCreate, action: '', data: null };
      const createData = new CreateObjectModel(this.storeName, this.payoutId, 'PayoutPrice', 'Create', obj, null, e);
      this.store.dispatch(new CreateObjectList({ createData }));
    }
  }

  createForWiz(obj: any): void {
    this.working$.next(true);
    this.subscription.add(this.domainObjectService.updateByMethod('PayoutPrice', 'Create', obj)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          //emit next back to creation wizard
          const e: IHomEventEmitter = { requestor: 'payout-price', event: SkuCreationWizardEvent.payoutPriceCreated, action: '', data: null }
          this.emitterService.emitSkuWizardEvent(e);
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
    ));
  }

  updateRecord(): void {
    const payoutPrice: IPayoutPrice = this.setCommonProperties();
    const defaultDeckPrice = this.setDefaultDeckPrice();
    let obj = {
      model: payoutPrice,
      selectedLocationServices: this.selectedLocationServiceIds.length > 0 ? this.selectedLocationServiceIds : null,
      defaultDeckPrice: defaultDeckPrice
    };
    const keyData: IKey = { storeName: this.storeName, parentId: this.payoutId, key: this.key, id: this.payoutPriceId }
    const emitter: IHomEventEmitter = { requestor: 'payout-price', event: this.myConstants.emitterEventUpdate, action: 'close', data: null };
    const updateData = new UpdateObjectCustomModel(keyData, 'PayoutPrice', 'Update', obj, null, emitter);
    this.store.dispatch(new UpdateObjectCustomList({ updateData }));
  }

  setDefaultDeckPrice(): number {
    return !this.price ? 0 : parseFloat(this.homDecimalPipe.transform(this.price.toString()));
  }

  setCommonProperties(): IPayoutPrice {
    let data = cloneDeep(this.objectData$.value);
    data.payout_payoutId = this.payoutId;
    data.isGlobal = this.selectedOverride === this.globalType;
    data.generalContractor_generalContractorId = this.selectedGcId > 0 ? this.selectedGcId : null;
    data.providerLocation_providerLocationId = this.selectedProviderLocationId > 0 ? this.selectedProviderLocationId : null;
    data.service_serviceId = this.selectedServiceId > 0 ? this.selectedServiceId : null;
    return data;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
