/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take  } from 'rxjs/operators';

import { IListDefinition, ListDefinition, IListFilter, ListFilter, IListButtonType, OrderTerm } from '../../../../../../fw/dynamic-list/interfaces/index';
import { ButtonType } from '../../../../../../fw/fw-shared/enums/button-type.enum';

//store actions and reducers
import * as fromRoot from '../../../../../store/reducers/index';
import * as fromAuth from '../../../../../../auth/store/auth.reducer';
import { DynamicListService } from '../../../../../../fw/dynamic-list/services';

@Component({
  selector: 'user-notes',
  templateUrl: './user-notes.component.html'
})
export class UserNotesComponent implements OnInit, OnDestroy {
  @Input() installerId: number;

  public listDefinition: IListDefinition;
  public title: string = 'User Notes';
  authState: fromAuth.IState;
  subscription: Subscription = new Subscription();

  constructor(
    public  activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public dynamicListService: DynamicListService ) {}

  ngOnInit() {
    this.subscription.add(this.rootStore.select('auth').pipe(take(1))
      .subscribe((authState: fromAuth.IState) => {
        this.authState = authState;
        this.initDefinition();
      }));
  }

  initDefinition() {
    const listObjectLabel = 'User Notes ';
    const listObjectController = 'Note';
    const listStoreName = 'userNotes';
    const detailRoutePath = '';
    const listRowKeyId = 'noteId';
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('createDate', false)];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      null,
      detailRoutePath);

    listDefinition.parentId = this.authState.providerUser.currentUserId;
    listDefinition.parentKey = 'providerUser_providerUserId';
    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.methodParameters= this.authState.providerUser.currentUserId.toString();
    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    this.listDefinition = listDefinition; 
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
