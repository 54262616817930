/* This store is used to turn on off the loading indicator */

import { Action } from '@ngrx/store';

export const SPINNER_SHOW = '[loadingIndicator] SPINNER_SHOW';
export const SPINNER_HIDE = '[loadingIndicator] SPINNER_HIDE';

export class HideSpinner implements Action {
  readonly type = SPINNER_HIDE;
  constructor(
    public payload: { requestor: string, id: number  }
  ) { }

}

export class ShowSpinner implements Action {
  readonly type = SPINNER_SHOW;
  constructor(
    public payload: { requestor: string, id: number  }
  ) { }

}

export type LoadingIndicatorActions = ShowSpinner | HideSpinner;
