<div class="app-btn-box" *ngIf="operation === myConstants.operationTypeDetails || operation === myConstants.operationTypeEdit">
    <button type="button"
            class="app-btn app-btn--primary"
            title="Next"
            [disabled]="disabled || !formDefinition.showNext"
            (click)="onNavigate('next')">
      Next
    </button>
    <button
            type="button"
            class="app-btn app-btn--primary"
            title="Previous"
            [disabled]="disabled || !formDefinition.showPrev"
            (click)="onNavigate('previous')"> 
      Previous
    </button>
</div>

