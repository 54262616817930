/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import {  BehaviorSubject } from 'rxjs';

import { IWorkOrderItem, IPurchaseOrderItem, IMarginResults } from '../../../view-models/index';

@Component({
  selector: 'project-margin',
  templateUrl: './project-margin.component.html',
})
export class ProjectMarginComponent implements OnInit, OnChanges {
  @Input() providerMarginHigh: number;
  @Input() providerMarginLow: number;
  @Input() canIEdit: boolean;
  @Input() poItems: IPurchaseOrderItem[];
  @Input() woItems: IWorkOrderItem[];

  @Output() marginSetEvent = new EventEmitter<IMarginResults>();

  projectNet$: BehaviorSubject<number> = new BehaviorSubject(0);
  projectMargin$: BehaviorSubject<number> = new BehaviorSubject(0);
  atMargin$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  aboveMargin$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  belowMargin$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  ngOnInit() {
    this.calcMargins();
  }

  calcMargins() {
    let poTotal: number = 0;
    let woTotal: number = 0;
    if (this.poItems && this.poItems.length > 0) {
      const profitPoItems: IPurchaseOrderItem[] = this.poItems.filter(x => x.itemType === 'Labor');
      profitPoItems.forEach((item: IPurchaseOrderItem) => poTotal += item.quantity * item.unitPrice);
    }
    if (this.woItems && this.woItems.length > 0) {
      this.woItems.forEach((item: IWorkOrderItem) => woTotal += item.quantity * item.unitPrice);
    }

    let projectTotal: number = poTotal - woTotal;
    let projectMargin: number = (projectTotal / poTotal);
    this.projectNet$.next(projectTotal);
    this.projectMargin$.next(poTotal > 0 ? projectMargin : 0);
    this.belowMargin$.next(poTotal > 0 ? (projectTotal / poTotal * 100) < this.providerMarginLow : false);
    this.atMargin$.next(poTotal > 0
      ? ((projectTotal / poTotal * 100) >= this.providerMarginLow) && ((projectTotal / poTotal * 100) <= this.providerMarginHigh)
      : false);
    this.aboveMargin$.next(poTotal > 0 ? (projectTotal / poTotal * 100) > this.providerMarginHigh : false);
    const marginResults: IMarginResults = { belowMargin: this.belowMargin$.getValue(), atMargin: this.atMargin$.getValue(), aboveMargin: this.aboveMargin$.getValue() };
    this.marginSetEvent.emit(marginResults);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['providerMarginHigh'] && !(changes['providerMarginHigh'].firstChange)) {
      this.calcMargins();
    }
    if (changes['providerMarginLow'] && !(changes['providerMarginLow'].firstChange)) {
      this.calcMargins();
    }
    if (changes['poItems'] && !(changes['poItems'].firstChange)) {
      this.calcMargins();
    }
    if (changes['woItems'] && !(changes['woItems'].firstChange)) {
      this.calcMargins();
    }
  }

}
