import { Component, Input, OnInit, Inject, OnChanges, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FormGroup, AbstractControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IFieldDefinition } from '../../../dynamic-forms/interfaces/i-field-definition';
import { ISearchTypeConfig, ISearchTypeOption } from '../../interfaces/i-search-type-config';
import { MultiSelectDropdown } from '../../../fw-shared/components/fw-multi-select-dropdown';
import { SearchType } from '../../enums/search-type.enums';
import { DynamicFieldService } from '../../../dynamic-forms/services/dynamic-field.service';
import { ScreenService } from '../../../fw-shared/services/screen.service';
import { FwCalendarComponent } from '../../../fw-shared/components';

@Component({
  selector: 'fw-dynamic-filter-field',
  templateUrl: './dynamic-filter-field.component.html'
})
export class DynamicFilterFieldComponent implements OnInit, OnChanges, OnDestroy {
  @Input() field: IFieldDefinition;
  @Input() filterForm: FormGroup;
  @Input() operation: string;
  @Input() searchTypeKey: string;
  @Input() disabled: boolean;
  @Input() searchTypeConfig: ISearchTypeConfig;

  @ViewChild('ddMultiSelect') public ddMultiSelect: MultiSelectDropdown;
  @ViewChild('calPicker') public calPicker: FwCalendarComponent;

  public validationMessage: string = '';
  public displayType$: BehaviorSubject<string> = new BehaviorSubject('string');
  public showRangeFields$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showField$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  subscription: Subscription;

  constructor(
    public dynamicFieldService: DynamicFieldService,
    public screenService: ScreenService,
    public datePipe: DatePipe,
    public emitterService: HomEventEmitterService,
    public decimalPipe: DecimalPipe,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public get isValid() { return this.filterForm.controls[this.field.key].valid; }

  public selectedDate(val: string) {
    this.filterForm.controls[this.field.key].setValue(val);
  }

  public onRangeChange(isDecimal: boolean = false): void {
    this.setValidationMessage('');
    const fieldControl = this.filterForm.controls[this.field.key];
    const start = this.filterForm.controls[this.field.key.concat('_start')].value;
    const end = this.filterForm.controls[this.field.key.concat('_end')].value;
    if (!start && !end) {
      return;
    }
    if (((start && !end) || (!start && end)) || ((isNaN(start) || isNaN(end))) || (Number(start) >= Number(end))) {
      this.setValidationMessage('Start must be > End');
    } else {
      if (!isDecimal) {
        fieldControl.setValue(start + ' - ' + end);
      } else {
        this.setDecimalRangeReturnValue(fieldControl, this.decimalPipe.transform(start, '1.2-2'), this.decimalPipe.transform(end, '1.2-2'));
      }
    }
  }

  public onDecimalChange(): void {
    switch (this.field.fieldType) {
      case this.myConstants.fieldTypeDouble:
        break;
      case this.myConstants.fieldTypePercentage:
        this.onPercentChange();
        break;
      default:
        const key = this.field.key;
        const fieldControl = this.filterForm.controls[key];
        this.dynamicFieldService.onDecimalChange(fieldControl);
        break;
    }
  }

  //percentages arrive as .3825, but are displayed using the percent pipe as 38.25
  //for filtering, need to return the filter request as .3825 even though the user will type in 38.25 (to match what they see in the list)
  //this.listenForChanges listens for change to percent
  public onPercentChange(): void {
    const displayKey = this.field.key.concat('_percent');
    const displayControl = this.filterForm.controls[displayKey];
    const fieldControl = this.filterForm.controls[this.field.key];
    if (displayControl) {
      const value = displayControl.value;
      let returnVal = value ? this.dynamicFieldService.cleanDecimal(this.decimalPipe.transform(+value / 100, '1.4-4')) : '';
      fieldControl.setValue(returnVal);
    }
  }

  public changeTimeSpan(newVal: string, timeOf: string): void {
    let isValid = true;
    this.setValidationMessage('');
    const fieldControl = this.filterForm.controls[this.field.key];
    switch (timeOf) {
      case 'start':
        const endControl = this.filterForm.controls[this.field.key.concat('_end')];
        const endVal = endControl.value ? endControl.value : '';
        isValid = this.isTimeSpanValid(newVal, endVal);
        if (isValid) {
          fieldControl.setValue(newVal + ' - ' + endVal);
        }
        break;
      case 'end':
        const startControl = this.filterForm.controls[this.field.key.concat('_start')];
        const startVal = startControl.value ? startControl.value : '';
        isValid = this.isTimeSpanValid(startVal, newVal);
        if (isValid) {
          fieldControl.setValue(startVal + ' - ' + newVal);
        }
        break;
      default:
        break
    }
  }

  //LISTENER:  Event only emitted for multi-selects that have search fields visible.
  //          The search field will only be visible if there are too many items to reasonably included
  //          in the multi-select at one time.  This event is listening for the filter within this filter field.
  //
  //          Filter results for this filter field (drop down)
  public filterOfFilterEvent(searchText: string) {
    let emitter: IHomEventEmitter = {
      requestor: 'dynamic-filter-field',
      event: this.myConstants.emitterEventFilterFieldFiltered,
      action: this.field.selectListDefinition && this.field.selectListDefinition.storeName ? this.field.selectListDefinition.storeName : '',
      data: searchText
    };
    this.emitterService.emitFilterEvent(emitter);
  }

  public onSearchTypeChange(val: string) {
    const oldSearchType = this.displayType$.value;
    this.showField$.next(val !== SearchType.NoValue);
    this.showRangeFields$.next(val.toLowerCase() === 'range');
    this.displayType$.next(this.dynamicFieldService.displayType(this.field, 'filter', val));
    this.setValidationMessage('');

    if (this.field.fieldType === this.myConstants.fieldTypePercentage) {
      this.onPercentChange();
    }
  }

  init(): void {
    const defaultType: ISearchTypeOption = this.searchTypeConfig.isVisible ? this.searchTypeConfig.options.find(x => x.isDefault) : null;
    this.displayType$.next(this.dynamicFieldService.displayType(this.field, 'filter', !defaultType ? '' : defaultType.value));
    this.listenForChanges();
    this.showField$.next(this.searchTypeConfig.options.find(x => x.isDefault).value !== SearchType.NoValue); 
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filterForm'] && !changes['filterForm'].isFirstChange()) {
      this.init();
    }
    
    if (changes['operation'] &&
      changes['operation'].currentValue &&
      (changes['operation'].currentValue === this.myConstants.operationTypeResetFilter)) {
      this.resetForm();
    }
  }

  //When these custom fields change, will result in an update to the primary field that the form is listening for changes to
  listenForChanges(): void {
    const displayControl = this.filterForm.controls[this.field.key.concat('_percent')];

    if (displayControl) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscription = new Subscription();
      //custom field for percent
      this.subscription.add(displayControl.valueChanges
        .subscribe(value => {
          this.onPercentChange();
      }));
    }
  }

  getTimeKey(instance: number): string {
    return this.field.key.concat(instance.toString());
  }

  isTimeSpanValid(startVal: string, endVal: string): boolean {
    let valid: boolean = true;
    if (startVal && endVal && startVal >= endVal) {
      this.setValidationMessage('Start must be before End');
      valid = false;
    }
    return valid;
  }

  isRangeValid(startVal: number, endVal: number): boolean {
    let valid: boolean = true;
    if (startVal && endVal && startVal >= endVal) {
      this.setValidationMessage('Start must be > End');
      valid = false;
    }
    return valid;
  }

  setValidationMessage(msg: string): void {
    this.validationMessage = msg;
  }

  resetForm(resetSearchType: boolean = true): void {
    const fieldControl = this.filterForm.controls[this.field.key];

    if (!fieldControl) {
      return;
    }
    const displayType = this.displayType$.getValue();
    switch (displayType) {
      case this.myConstants.fieldTypeMultiSelect:
      case this.myConstants.fieldTypeBoolean:
        if (this.ddMultiSelect) {
          this.ddMultiSelect.uncheckAll();
        }
        break;
      case this.myConstants.fieldTypeSelect:
      case this.myConstants.fieldTypeEntity:
      case this.myConstants.fieldTypeEditableSelect:
      case this.myConstants.fieldTypeFilterableSelect:
        fieldControl.setValue([]);
        fieldControl.reset([]);
        break;
      case this.myConstants.fieldTypeSingleDatePicker:
        if (this.calPicker) {
          this.calPicker.clearInput(true);
        }
        break;
      case this.myConstants.fieldTypeDateRangePicker:
        if (this.calPicker) {
          this.calPicker.clearInput(true);
          this.calPicker.clearInput(false);
        }
        break;
      default:
        fieldControl.setValue('');
        break;
    }

    //reset extra fields that may exist
    const startCtrl = this.filterForm.controls[this.field.key.concat('_start')];
    const endCtrl = this.filterForm.controls[this.field.key.concat('_end')];
    const percentCtrl = this.filterForm.controls[this.field.key.concat('_percent')];
    const searchTypeControl = this.filterForm.controls[this.searchTypeKey];
    if (startCtrl) {
      startCtrl.setValue('');
    }
    if (endCtrl) {
      endCtrl.setValue('');
    }
    if (percentCtrl) {
      percentCtrl.setValue('');
    }
    if (resetSearchType) {
      if (searchTypeControl) {
        const indx = this.searchTypeConfig.options.findIndex(x => x.isDefault);
        const val = indx > -1 ? this.searchTypeConfig.options[indx].value : '';
        searchTypeControl.setValue(val);
        this.onSearchTypeChange(val);
      }
    }
  }

  setDecimalRangeReturnValue(fieldControl: AbstractControl, startVal, endVal) {
    if (this.field.fieldType === this.myConstants.fieldTypePercentage) {
      fieldControl.setValue(this.decimalPipe.transform(+this.dynamicFieldService.cleanDecimal(startVal) / 100, '1.4-4') + ' - ' + this.decimalPipe.transform(+this.dynamicFieldService.cleanDecimal(endVal) / 100, '1.4-4'));
    } else {
      fieldControl.setValue(this.decimalPipe.transform(this.dynamicFieldService.cleanDecimal(startVal), '1.2-2') + ' - ' + this.decimalPipe.transform(this.dynamicFieldService.cleanDecimal(endVal), '1.2-2'));
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
