
export enum WarrantyDashType {
  unassignedWarranty = 'unassignedWarranty',
  openWarranty = 'openWarranty'
}

export enum WarrantyDashCountType {
  unassignedWarrantyCount = 'unassignedWarrantyCount',
  openWarrantyCount = 'openWarrantyCount'
}

export enum WarrantyDashStore {
  dashOpenWarranties = 'dashOpenWarranties',
  dashUnassignedWarranties = 'dashUnassignedWarranties'
}

export enum WarrantyDashEvent {
  peekWOSummary = 'peekWOSummary',
  peekPOSummary = 'peekPOSummary',
  showAssignUser = 'showAssignUser'
}

export enum WarrantyDashTabLabel {
  unassignedWarranties = 'Unassigned Warranties',
  openWarranties = 'Open Warranties'
}
