/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { IApprovalQueueTransaction } from '../../../../../shared/interfaces';
import { IKey, UpdateObjectCustomModel, UpdateObjectByIdModel } from '../../../../../fw/dynamic-list/store/interfaces/index';

import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { ManagerDashEvent } from '../../enums/manager-dashboard.enums';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';

import { ManagerDashboardService } from '../../services/manager-dashboard.service';
import { SharedDashboardService } from '../../../portal-shared/services/shared-dashboard.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { ITabRequest } from '../../../../../fw/fw-shared/components/fw-app-tabs/interfaces';
import { TabsService } from '../../../../../fw/fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';

@Component({
  selector: 'pending-approvals',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pending-approvals.component.html'
})
export class PendingApprovalsComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public managerDashboardService: ManagerDashboardService,
    public store: Store<fromStore.IAllDynamicData>,
    public modalService: ModalService,
    public tabsService: TabsService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case ManagerDashEvent.approvePendingChange:
        this.approvePendingChange(event.data);
        break;
      case ManagerDashEvent.denyPendingChange:
        this.showDenyConfirmation(event.data);
        break;
      case ManagerDashEvent.openLink:
        this.managerDashboardService.openLink(event.data);
      default:
        break;
    }
  }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.managerDashboardService.loadDashPendingApprovalListDefinition());
    });
  }

  openLink(data: IApprovalQueueTransaction): void {
    const tabRequest: ITabRequest = {
      text: data.aggregateRootEntityName.concat(' ', data.aggregateRootPrimaryKeyId.toString()),
      portalId: data.entityPortalId,
      portalEntityId: data.aggregateRootPrimaryKeyId,
      level2EntityName: data.parentEntityName,
      level2EntityId: data.parentPrimaryKeyId,
      level2Text: data.parentPrimaryKeyId.toString(),
      level3EntityName: null,
      level3EntityId: null,
      level3Text: null
    }
    const event: IHomEventEmitter = {
      requestor: 'pending-approvals',
      event: this.myConstants.emitterEventTabLevel1Open,
      action: '',
      data: tabRequest
    };
    this.tabsService.emitTabEvent(event);
  }

  approvePendingChange(data: IApprovalQueueTransaction): void {
    //refresh parent list should refresh on successful update
    const def: IListDefinition = cloneDeep(this.listDefinition$.getValue());
    const keyData: IKey = { storeName: def.storeName, parentId: -1, key: def.rowKeyId, id: data.approvalQueueTransactionId };
    const emitter: IHomEventEmitter = { requestor: 'pending-approvals', event: this.myConstants.emitterEventListReload, action: '', data: null };
    const updateData = new UpdateObjectCustomModel(keyData, def.controllerName, 'Commit', data, null, emitter);
    this.store.dispatch(new fromStore.UpdateObjectCustomList({ updateData }));
  }

  showDenyConfirmation(data: IApprovalQueueTransaction): void {
    const def: IListDefinition = cloneDeep(this.listDefinition$.getValue());
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: def.storeName,
      key: def.rowKeyId,
      operation: this.myConstants.operationTypeEdit,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.entity_entityId
    }

    this.modalService.open({
      title: 'Reject Pending Change',
      path: 'mgr-dash-deny-pending-change',
      id: data.approvalQueueTransactionId,
      sizeType: ModalSizeType.small,
      castExit: false,
      optionalParams: params,
      hasTabs: false,
      showLoading: true
    });
  }
}
