import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class GeolocationService {

  constructor(

  ) { }

  public longitude: number;
  public latitude: number;
  public hasLocation: boolean;

  public init(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.hasLocation = true;
        this.longitude = position.coords.longitude;
        this.latitude = position.coords.latitude;
      })
    } 
  }

}
