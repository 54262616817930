import { Component, OnInit, OnDestroy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { UpdateObjectCustomModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IApprovalQueueTransaction } from '../../../../../shared/interfaces';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';

//store actions, reducers, interfaces
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { getListByType, getSelectedRecord } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { ManagerDashboardService } from '../../services/manager-dashboard.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'pending-change-deny',
  templateUrl: './pending-change-deny.component.html'
})
export class PendingChangeDenyComponent implements OnInit, OnDestroy  {
  public displayFields = ['denyReason'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public objectData: IApprovalQueueTransaction;

  parentId: number = -1;  
  myPathName: string = '';
  storeName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
      public store: Store<fromStore.IAllDynamicData>,
      public emitterService: HomEventEmitterService,
      public dynamicDetailService: DynamicDetailService,
      public managerDashboardService: ManagerDashboardService,
     @Inject(appConstants) private myConstants: IAppConstants,
      public modalService: ModalService) {}

  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation =  paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.setDetailConfig(paramMap);
      this.getDetail();
    }));

    this.subscription.add(this.emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case this.myConstants.emitterEventClose:
            this.closeModal();
          default:
            break;
        }
      }));

    this.subscription.add(this.store.pipe(select(getListByType(this.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == -1)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          if (state.event && !state.errorData.length) {
            if (this.modalService.opened) {
              this.modalService.close();
            }
          }
        }
      }));

  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.parentId;
    params.useRouterOutlet = false;
    params.showNav = false;
    params.showTitle = false;
    params.showNext = false;
    params.showPrev = false;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(this.storeName, this.parentId, this.key, this.objectId)))
      .subscribe((entity: IApprovalQueueTransaction) => {
        this.objectData = entity;
      })
    );
  }

  updateRecord(e: IHomEventEmitter) {
    let formData: IApprovalQueueTransaction = e.data;
    let rec: IApprovalQueueTransaction = cloneDeep(this.objectData);
    const def: IListDefinition = this.managerDashboardService.loadDashPendingApprovalListDefinition();

    rec.denyReason = formData.denyReason;
    const keyData: IKey = { storeName: this.storeName, parentId: this.parentId, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: this.myConstants.emitterEventListReload, action: '', data: null };
    const updateData = new UpdateObjectCustomModel(keyData, def.controllerName, 'Deny', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  closeModal() {
    this.store.dispatch(new DynamicListActions.ClearErrorsList({ storeName: this.storeName, parentId: this.parentId }));

    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
