<div *ngIf="!row.warranty_warrantyId" class="proj-cust-contact">
  <div [title]="row.wosMissingCallLog ? row.wosMissingCallLog : 'The customer has been contacted for all work orders.'">
    <i class="app-icon fad {{ row.wosMissingCallLog ? 'app-icon--danger fa-phone-slash fa-rotate-270' : 'app-icon--success fa-phone-alt fa-swap-opacity'}} "></i>
  </div>
  <div [title]="row.wosMissingSchedule ? row.wosMissingSchedule : 'All work orders are scheduled.'">
    <span *ngIf="row.wosMissingSchedule" class="app-icon--danger fa-stack">
      <i class="fad fa-calendar-alt fa-stack-1x"></i>
      <i class="fas fa-slash fa-stack-1x fa-rotate-90 proj-cust-contact--missing" ></i>
    </span>
    <i *ngIf="!row.wosMissingSchedule" class="app-icon fad app-icon--success fa-calendar-alt"></i>
  </div>
  <div [title]="row.wosMissingInstaller ? row.wosMissingInstaller : 'All work orders have an assigned installer.'">
    <span *ngIf="row.wosMissingInstaller" class="app-icon--danger fa-stack">
      <i class="fas fa-truck-moving fa-stack-1x"></i>
      <i class="fas fa-slash fa-stack-1x fa-rotate-90 proj-cust-contact--missing"></i>
    </span>
    <i *ngIf="!row.wosMissingInstaller" class="app-icon fad app-icon--success fa-truck-moving "></i>
  </div>
</div>
