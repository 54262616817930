import { Component, Input, OnInit} from '@angular/core';
import { IScheduleInstallerWorkCrewUnavailabilityViewModel } from '../../../../app/portals/view-models';

@Component({
  selector: 'fw-installer-crew-list-item',
  templateUrl: './installer-crew-list-item.component.html'
})
export class InstallerCrewListItemComponent implements OnInit {
  @Input() row: IScheduleInstallerWorkCrewUnavailabilityViewModel;

  public crewName: string;

  constructor() {}

  ngOnInit(): void {
    this.crewName = this.row.installerWorkCrewName.replace(this.row.installerName, '');
  }

 } 
