import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl  } from '@angular/forms';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import {
  IMultiSelectOption, MultiSelectTexts,
  MultiSelectSettings, MultiSelectDropdown
} from '../../../../../fw/fw-shared/components/fw-multi-select-dropdown/index';
import { IProviderUser } from '../../../view-models';
import { ISendToUser } from '../../interfaces';
import { NoteEvent } from '../../enums/note.enums';

@Component({
  selector: 'note-recipients',
  templateUrl: './note-recipients.component.html'
})
export class NoteRecipientsComponent implements OnInit {
  @Input() providerUsers: IProviderUser[];
  @Input() isDisabled: boolean = true;

  @Output() public recipientEvent = new EventEmitter<IHomEventEmitter>();

  public sendForm = new FormGroup({ 'providerUserMultiSelect': new FormControl() });
  public sendToList: ISendToUser[] = [];
  public multiSelectOptions: IMultiSelectOption[];
  public multiSelectLabels: MultiSelectTexts;
  public multiSelectSettings: MultiSelectSettings;

  constructor() {}

  ngOnInit() {
    this.setMultiSelectProperties();
  }

  //Set Multi Select Overrides
  setMultiSelectProperties() {
    let multiSelectLabels = new MultiSelectTexts();
    multiSelectLabels.defaultTitle = 'Select...';

    let multiSelectSettings = new MultiSelectSettings(true, false, false, 1, this.providerUsers.length, this.providerUsers.length);

    multiSelectSettings.totalItems = this.providerUsers.length;
    multiSelectSettings.itemsPerPage = this.providerUsers.length;
    multiSelectSettings.enableSearch = true;
    multiSelectSettings.dynamicTitleMaxItems = 1;
    multiSelectSettings.uncheckAllOnReload = true;
    multiSelectSettings.showPagingInfo = false;


    let multiSelectOptions: IMultiSelectOption[] = [];
    this.providerUsers.forEach(user => {
      //multiSelectOptions.push({ id: user.providerUserId, name: user.fullName });
      multiSelectOptions.push({ id: user.providerUserId, name: user['entityLabel'] });
    });

    this.multiSelectLabels = multiSelectLabels;
    this.multiSelectSettings = multiSelectSettings;
    this.multiSelectOptions = multiSelectOptions;
  }


  //LISTENER:  Event emitted by multi-select
  addSendToEvent(providerUserId: number) {
    let providerUser = this.providerUsers.find(x => x.providerUserId === providerUserId); 
    if (providerUser) {
      this.sendToList.push({ providerUser: providerUser, sendEmail: false });
    }
    const emitter: IHomEventEmitter = { requestor: 'note-recipients', event: NoteEvent.addRecipient, action: '', data: providerUserId };
    this.recipientEvent.emit(emitter)
  }

  //LISTENER:  Event emitted by multi-select
  removeSendToEvent(providerUserId: number) {
    const index = this.sendToList.findIndex(x => x.providerUser.providerUserId === providerUserId);
    if (index > -1) {
      this.sendToList.splice(index, 1);
    }
    const emitter: IHomEventEmitter = { requestor: 'note-recipients', event: NoteEvent.removeRecipient, action: '', data: providerUserId };
    this.recipientEvent.emit(emitter)
  }

  // Add/Remove user from the list of users to send emails to
  onMailBox(providerUserId: number) {
    let sendToUser = this.sendToList.find(x => x.providerUser.providerUserId === providerUserId);
    if (sendToUser) {
      sendToUser.sendEmail = !sendToUser.sendEmail;
    }
    const emitter: IHomEventEmitter = { requestor: 'note-recipients', event: NoteEvent.changeRecipientNotification, action: '', data: providerUserId };
    this.recipientEvent.emit(emitter)
  }
}
