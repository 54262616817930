import { Injectable } from '@angular/core';
import { RequestEventEmitter, ResponseEventEmitter } from './http.emitter';

@Injectable()
export class HttpPubSubService {

    beforeRequest: RequestEventEmitter;
    afterRequest: ResponseEventEmitter;

    constructor() {
        this.beforeRequest = new RequestEventEmitter();
        this.afterRequest = new ResponseEventEmitter();
    }
}