
<fw-component-title title='Change LSWP Override Reason'></fw-component-title>
<ng-container *ngIf="overrideReasonCodes">
  <ng-container *ngFor="let item of data">
    <lswp-override-reason [item]="item"
                          [reasonCodes]="overrideReasonCodes"
                          (changeEvent)="onChangeEvent(item, $event)">
    </lswp-override-reason>
  </ng-container>
</ng-container>

