<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <div *ngIf="activeRecord && myStoreName">
    <fw-dynamic-form [vm]="activeRecord"
                     [storeName]="myStoreName"
                     [vmFormDefinition]="formDefinition$ | async"
                     [parentId]="authState.providerUser.currentUserId"
                     [displayFields]="displayFields"
                     [operation]="operation"
                     [errorMessage]="errorMessage$ | async"
                     [errorData]="errorData$ | async">
    </fw-dynamic-form>
  </div>
</fw-dynamic-detail-container>

