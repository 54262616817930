import { Component, OnInit,  OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take  } from 'rxjs/operators';

import {  IFieldDefinition } from '../../../../../../fw/dynamic-forms/index';
import { IListDefinition, ListDefinition, IListColumn, ListColumn, IListFilter, ListFilter, IListButtonType } from '../../../../../../fw/dynamic-list/interfaces/index';
import { ButtonType } from '../../../../../../fw/fw-shared/enums/button-type.enum';

//store actions and reducers
import * as fromRoot from '../../../../../store/reducers/index';
import * as fromAuth from '../../../../../../auth/store/auth.reducer';
import { DynamicListService } from '../../../../../../fw/dynamic-list/services';

@Component({
  selector: 'user-alerts',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-alerts.component.html'
})
export class UserAlertsComponent  implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;
  authState: fromAuth.IState;
  fieldDefinitions: Array<IFieldDefinition>;
  operation: string;
  subscription: Subscription = new Subscription();

  constructor(public rootStore: Store<fromRoot.IState>,
    public dynamicListService: DynamicListService ) {}
  
  ngOnInit() {
      this.subscription.add(this.rootStore.select('auth')
        .pipe(take(1))
        .subscribe((authState: fromAuth.IState) => {
          this.authState = authState;
          this.loadListDefinition();
        }));
  }
  
  loadListDefinition() {
      const listColumns = this.loadListColumns();
      const listObjectLabel = 'Alerts';
      const listObjectController = 'JobScheduleJobSubscription';
      const listStoreName = 'userJobAlerts';
      const detailRoutePath = 'user-alert';
      const listRowKeyId = 'jobScheduleJobSubscriptionId';
                                         
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;

    let listDefinition = this.dynamicListService.createListDefinition('commonDetailOutlet',
        listObjectLabel,
        listObjectController,
        listStoreName,
        listRowKeyId,
        defaultListFilter,
        listColumns,
        detailRoutePath);

    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.methodParameters = this.authState.providerUser.currentUserId.toString();
    listDefinition.showFilter = false;

    const crudButtons: IListButtonType[] = [{ type: ButtonType.delete, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    this.listDefinition = listDefinition;
  }
  
  /*
      Define the columns in the list to be displayed.  Columns pulled back are set by the db.
      May change this to be defined in the db.
  */
  loadListColumns(): IListColumn[] {
      let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('jobScheduleJobName'); //JobScheduleJobSubscription.JobScheduleJob.JobScheduleJobName 
    columnDefinitions.push(colDef);

    colDef = new ListColumn('jobNameNew'); //Job.job_name
    columnDefinitions.push(colDef);

      colDef = new ListColumn('scheduleName'); //JobScheduleJobSubscription.JobScheduleJob.JobSchedule.JobScheduleName
      columnDefinitions.push(colDef);

      colDef = new ListColumn('jobScheduleDescription');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('alertOnlyWhenOutput');
      columnDefinitions.push(colDef);

      return columnDefinitions;
    }
  
  ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }

}
