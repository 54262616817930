import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import {
  IListDefinition, IListFilter, ISearchTerm, ListFilter,
  IListColumn, ListColumn, IListButtonType
} from '../../../../../fw/dynamic-list/interfaces';
import { ActivatedRoute } from '@angular/router';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { UpdateObjectCustomModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces';
import { UpdateObjectCustomList } from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { UtilitiesStore } from '../../../utilities/enums/utilities.enums';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { DynamicListMethod } from '../../../../../fw/dynamic-list/enums/dynamic-list.enum';
import { getListByType } from '../../../../../fw/dynamic-list/store/index';
import { SearchType } from '../../../../../fw/dynamic-list/enums/search-type.enums';
import { ScheduleStore } from '../../../scheduler/enums/schedule.enums';
import { DispatchCalendarSearchTerm } from '../../../utilities/enums/dispatch-calendar.enums';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as MetaDataActions from '../../../../../fw/dynamic-list/store/actions/meta-data.actions';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'schedule-locked-days',
  templateUrl: './schedule-locked-days.component.html'
})

export class ScheduleLockedDaysComponent implements OnInit, OnDestroy {
  constructor(
    public store: Store<fromStore.IAllDynamicData>,
    public activeRoute: ActivatedRoute,
    public datePipe: DatePipe,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants ) { }

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((params) => {
      if (params.get('id')) {
        switch (params.get('id')) {
          case 'dispatch-calendar':
            this.initForDispatchCalendar(this.datePipe.transform(new Date(params.get('day')), 'MM/dd/yyyy'));
            break;
          default:
            break;
        }
      } else {
        this.initWithoutTerms();
      }
    });
  }

  loadScheduleLockedDaysListDefinition(searchTerm: ISearchTerm[] = null): IListDefinition {
    const listColumns = this.loadScheduleLockedDaysListColumns();
    const listObjectLabel = 'Locked Day';
    const listObjectController = 'SlotScheduleCalendar';
    const listStoreName = ScheduleStore.slotScheduleCalendarLockedDays;
    const detailRoutePath = 'schedule-locked-days-create';
    const listRowKeyId = 'slotScheduleCalendarId';
    const searchTerms: any = [];
    if (searchTerm) {
      let i: number = 0;
      const len: number = searchTerm.length;
      for (; i < len; i++) searchTerms.push(searchTerm[i]);
    }
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.searchTerm = searchTerms;

    let listDefinition = this.dynamicListService.createListDefinition('scheduleLockedDaysCreateOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath,
      false );

    listDefinition.parentId = -1;
    listDefinition.parentKey = '';
    listDefinition.createRoutePath = 'schedule-locked-days-create';
    listDefinition.controllerMethod = 'Index';
    listDefinition.requiredSearchTerms = [{ term: 'isLocked', value: true, searchType: SearchType.Equals, columnName: 'isLocked', fieldType: this.myConstants.dataTypeBool }];
    listDefinition.noGet = !searchTerm ? true : false;
    listDefinition.openInFilterMode = !searchTerm ? true : false;
    listDefinition.showFilter = searchTerm ? false : true;

    const defaults: ICustomButton = {
      title: '', icon: '', cssName: '', enabled: true,
      enabledMethod: DynamicListMethod.alwaysEnabled, eventName: ''
    };
    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: defaults }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [{ type: ButtonType.delete, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadScheduleLockedDaysListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];
    let colDef = new ListColumn('day');
    columnDefinitions.push(colDef);
    colDef = new ListColumn('providerLocationName');
    columnDefinitions.push(colDef);
    colDef = new ListColumn('serviceName');
    columnDefinitions.push(colDef);
    colDef = new ListColumn('generalContractor');
    columnDefinitions.push(colDef);
    return columnDefinitions;
  }
   
  initForDispatchCalendar(day: string): void {
    this.subscription.add(this.store.select(getListByType(UtilitiesStore.dashCalendar)).subscribe((data) => {
      if (data && data.objData && data.objData.length > 0) {
        if (data.objData[0].listFilter) {
          this.listDefinition$.next(this.loadScheduleLockedDaysListDefinition(this.applySearchTerms(data.objData[0].listFilter.searchTerm, day)));
        }
      }
    }));
  }

  initWithoutTerms(): void {
    this.store.dispatch(new MetaDataActions.GetMetaData({ storeName: ScheduleStore.slotScheduleCalendarLockedDays, url: 'Entity/GetEntityMetaData?entityName=SlotScheduleCalendar', setListMetaData: true }));
    this.subscription.add(this.store.pipe(select(fromStore.getMetaDataByType(ScheduleStore.slotScheduleCalendarLockedDays))).subscribe((state: fromStore.IMetaDataState) => {
      if (state.fieldDefinitions.length && !this.listDefinition$.value) {
        let listDefinition: IListDefinition = this.loadScheduleLockedDaysListDefinition();
        this.store.dispatch(new fromStore.SetListDefinition({ storeName: ScheduleStore.slotScheduleCalendarLockedDays, parentId: -1, listDefinition: listDefinition }));
        this.listDefinition$.next(listDefinition);
      }
    }));
  }

  applySearchTerms(searchTerms: ISearchTerm[], day: string): ISearchTerm[] {
    let i: number = 0,
      listDefinition: IListDefinition = this.loadScheduleLockedDaysListDefinition();
    const len: number = searchTerms.length,
      terms: ISearchTerm[] = listDefinition.requiredSearchTerms;
    for (; i < len; i++) {
      if (searchTerms[i].term === DispatchCalendarSearchTerm.scheduleStartDate) {
        terms.push({ term: 'day', value: day, searchType: SearchType.Equals, columnName: 'day', fieldType: this.myConstants.dataTypeDate });
      }
      if (searchTerms[i].term === DispatchCalendarSearchTerm.service) {
        terms.push({ term: 'slotSchedule_providerLocationService_service_serviceId', value: searchTerms[i].value, searchType: SearchType.Equals, displayValues: searchTerms[i].displayValues, columnName: 'serviceName', fieldType: this.myConstants.dataTypeInt });
      }
      if (searchTerms[i].term === DispatchCalendarSearchTerm.providerLocation) {
        terms.push({ term: 'slotSchedule_providerLocationService_providerLocation_providerLocationId', value: searchTerms[i].value, searchType: SearchType.Equals, displayValues: searchTerms[i].displayValues, columnName: 'providerLocationName', fieldType: this.myConstants.dataTypeInt });
      }
      if (searchTerms[i].term === DispatchCalendarSearchTerm.generalContractor) {
        terms.push({
          term: 'generalContractor_generalContractorId', value: searchTerms[i].value, searchType: SearchType.Equals, displayValues: searchTerms[i].displayValues, columnName: 'generalContractor', fieldType: this.myConstants.dataTypeInt });
      }
    }
    return terms;
  }

  unlock(data): void {
    const keyData: IKey = {
      storeName: ScheduleStore.slotScheduleCalendarLockedDays, parentId: -1, key: 'slotScheduleCalendarId', id: data['slotScheduleCalendarId'], skipDataUpdate: true
    };
    const emitter: IHomEventEmitter = { requestor: 'schedule-locked-days-create', event: this.myConstants.emitterEventListReload, action: '', data: null };
    const updateData = new UpdateObjectCustomModel(keyData, 'SlotScheduleCalendar', 'Unlock', { slotScheduleCalendarId: data['slotScheduleCalendarId'] }, null, emitter);
    this.store.dispatch(new UpdateObjectCustomList({ updateData }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
