import { Component } from '@angular/core';
import { IMultiTierMenu } from '../../fw/fw-menus/interfaces/i-multi-tier-menu';
import { IMultiTierOuput } from '../../fw/fw-menus/interfaces/i-multi-tier-output';

@Component({
    selector: 'dev-component',
    templateUrl: './dev.component.html'
}) export class DevComponent {

  title: string = "Add More";

  items: IMultiTierMenu[] = [
    {
      label: 'item 1',
      subItems: [
        {
          label: 'item 1 sub', 
          subItems: [
            {
              label: 'item 1 subSub', 
            }
          ]
        },
        {
          label: 'item 1 sub 2', 
          subItems: [
            {
              label: 'item 1 sub 2 sub'
            }
          ]
        }
      ]
    },
    {
      label: 'item 2',
      subItems: [
        {
          label: 'item 2 sub'
        }
      ]
    },
    {
      label: 'item 3',
      subItems: [
        {
          label: 'item 3 sub'
        }
      ]
    }
  ];

  disable(item: IMultiTierMenu) {
    item.disabled = !item.disabled;
  }

  selected(output: IMultiTierOuput) {
    alert(JSON.stringify(output));
  }

}







