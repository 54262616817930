/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { UtilitiesService } from '../../services/utilities.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import {  IListDefinition, IDetailRouteParameter, IScheduleRouteParameter } from '../../../../../fw/dynamic-list/interfaces/index';
import { UtilitiesEvent, UtilitiesStore } from '../../enums/utilities.enums';
import { IProviderLocationScheduleMoveUp } from '../../../view-models/i-provider-location-schedule-move-up';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';

@Component({
  selector: '/schedule-moveups',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './schedule-moveups.component.html'
})
export class ScheduleMoveupsComponent implements OnInit {
  public listDefinition: IListDefinition;

  constructor(public utilitiesService: UtilitiesService,
    public ups: UserPriviledgesService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case UtilitiesEvent.scheduleWorkOrder:
        this.showScheduler(event.data);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.listDefinition = this.utilitiesService.loadScheduleMoveUpsListDefinition(this.ups.currentUserId$.value);
  }

  showScheduler(data: IProviderLocationScheduleMoveUp) {
    let params: IScheduleRouteParameter = {
      scheduleId: 0,
      operation: this.myConstants.operationTypeDetails,
      projectId: data.projectId,
      storeName: this.listDefinition.storeName,
      parentId: this.listDefinition.parentId
    }
    this.modalService.open({
      title: 'Work Order Schedule',
      path: 'utilities-scheduler',
      id: data.workOrderId,
      optionalParams: params,
      onExit: null,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.xlarge,
    });
  }

}
