<div *ngIf="(form$ | async) as form" [formGroup]="form" novalidate>
  <ng-container *ngIf="scoring">
    <div *ngFor="let row of scoring" class="app-card">
      <div class="app-card__header">
        <div class="app-card__header--title">
          {{mds.getLabel(row.fieldKey)}}:
          <span class="installer-score-title"> {{objectData[row.fieldKey]}}</span>
        </div>
      </div>
      <div class="app-card__content">
        <div class="installer-score">
          <label class="installer-score__label">
            {{row.type === myConstants.fieldTypePercentage ? 'Weight' : 'Threshold'}}:
          </label>
          <div *ngIf="row.type === myConstants.fieldTypePercentage" class="installer-score__val">{{ row.weight | percent: '1.2-2'  }}</div>
          <div *ngIf="row.type !== myConstants.fieldTypePercentage" class="installer-score__val">{{ row.weight }}</div>
          <div class="installer-score__of">{{ row.descriptor }}</div>
        </div>
        <div class="installer-score">
          <label class="installer-score__label">Description:</label>
          <div class="installer-score__value">{{mds.getHint(row.fieldKey)}}</div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
