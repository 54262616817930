import { INotification } from "./i-notification";

export interface IExternalNoteData {
     dateCreated: string;
    dateClosed: string;
    noteNumber: string;
    createUser: string;
    origination: string;
    acknowledgeNoteUrl: string;
  reply: string;
}

export interface INote {
  noteId: number;
  noteSubject: string;
  noteText: string;
  project_projectId: number;
  contact_contactId: number;
  providerUser_providerUserId: number;
  installer_installerId: number;
  purchaseOrder_purchaseOrderId: number;
  workOrder_workOrderId: number;
  branch_branchId: number;
  certificationType_lookupValueId: number;
  isPrivate: boolean;
  applyToProject: boolean;
  parentNote_noteId: number;
  postIt: boolean;
  externalNoteData_externalNoteDataId: number;
  isExternal: boolean;
  hasNotifications: boolean;
  hasUnacknowledgedNotifications: boolean;
  createDate: string;
  createdBy: number;
  updateDate: string;
  updatedBy: number;
  active: boolean;
  customerName: string;
  purchaseOrderNumber: string;
  createUserName: string;
  noteLabels: any;
  externalData: IExternalNoteData;
  metaData: any;

}

export class Note implements INote {
  noteId: number;
  noteSubject: string;
  noteText: string;
  project_projectId: number;
  contact_contactId: number;
  providerUser_providerUserId: number;
  installer_installerId: number;
  purchaseOrder_purchaseOrderId: number;
  workOrder_workOrderId: number;
  branch_branchId: number;
  certificationType_lookupValueId: number;
 parentNote_noteId: number;
  externalNoteData_externalNoteDataId: number;
  isPrivate: boolean;
  applyToProject: boolean;
  postIt: boolean;
  isExternal: boolean;
  createDate: string;
  createdBy: number;
  updateDate: string;
  updatedBy: number;
  active: boolean;
  hasNotifications: boolean;
  hasUnacknowledgedNotifications: boolean;
  customerName: string;
  purchaseOrderNumber: string;
  createUserName: string;
  noteLabels: any;
  externalData: IExternalNoteData;
  metaData: any;

  constructor() {
    this.noteId = 0;
    this.noteSubject = '';
    this.noteText = '';
    this.project_projectId = null;
    this.providerUser_providerUserId = null;
    this.contact_contactId = null;
    this.installer_installerId = null;
    this.purchaseOrder_purchaseOrderId = null;
    this.workOrder_workOrderId = null;
    this.branch_branchId = null;
    this.parentNote_noteId = null;
    this.externalNoteData_externalNoteDataId = null;
    this.certificationType_lookupValueId = null;
    this.isPrivate = true;
    this.applyToProject = false;
    this.postIt = false;
    this.isExternal = false;
    this.active = true;
    this.hasNotifications = false;
    this.hasUnacknowledgedNotifications = false;
    this.externalData = null;
    this.metaData = {};
    this.customerName = null;
    this.purchaseOrderNumber = null;
    this.createUserName = null;
    this.noteLabels = {};

    this.createDate = '';
    this.updateDate = '';
    this.createdBy = 0;
    this.updatedBy = 0;
  }

}

export interface INoteViewModel {
  model: INote;
  noteNotifyList: INotification[]; 
  sendExternal: boolean;
}
