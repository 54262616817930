import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FwModule } from '../../../fw/fw.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { FileUploadModule } from '../../file-upload/file-upload.module';
import { SharedModule } from '../../../shared/shared.module';
import { ContactModule } from '../../contact/contact.module';
import { SchedulerModule } from '../scheduler/scheduler.module';
import { UtilitiesRoutingModule } from './utilities-routing.module';
import { UserPriviledgesService } from '../../../auth/services/index';
import { UtilitiesService } from './services/utilities.service';
import { CalendarWorkOrderStatusResolver, GeneralContractorsResolver, CommunicationShortcutResolver } from '../resolvers';
import { DispatchCalendarService } from './services/dispatch-calendar.service';
import { HomHolidayResolver } from './resolvers/hom-holiday-resolver.service';

//containers
import {
  UtilitiesComponent,
  CustomerSearchComponent,
  EmailImportsComponent,
  ProjectFileUploadsComponent,
  ReportViewerComponent,
  ScheduleMoveupsComponent,
  DispatchCalendarComponent,
  InstallerVacationsComponent,
  PoImportsComponent
} from './containers/index';

//components
import { 
  EmailImportsDetailComponent,
  CustomerSearchDetailComponent,
  CustomerContactInfoComponent,
  DispatchCalendarFiltersComponent,
  DispatchDayViewComponent,
  QuickWorkOrderToolsComponent,
  DispatchWeekViewComponent,
  DispatchMonthViewComponent
} from './components/index';

//guards
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';

@NgModule({
  declarations: [
    EmailImportsDetailComponent,
    CustomerSearchDetailComponent,
    CustomerContactInfoComponent,
    DispatchCalendarFiltersComponent,
    DispatchDayViewComponent,
    QuickWorkOrderToolsComponent,
    DispatchWeekViewComponent,
    DispatchMonthViewComponent,
    DispatchCalendarComponent,
    InstallerVacationsComponent,
    CustomerSearchComponent,
    EmailImportsComponent,
    ProjectFileUploadsComponent,
    ReportViewerComponent,
    ScheduleMoveupsComponent,
    PoImportsComponent,
    UtilitiesComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FwModule,
    FwSharedModule,
    SharedModule,
    PortalSharedModule,
    FileUploadModule,
    ContactModule,
    SchedulerModule,
    UtilitiesRoutingModule
  ],
  providers: [
    IsCurrentGuard,
    UserPriviledgesService,
    CalendarWorkOrderStatusResolver,
    HomHolidayResolver,
    UtilitiesService,
    DispatchCalendarService,
    GeneralContractorsResolver,
    CommunicationShortcutResolver
  ]
})

export class UtilitiesModule {}
