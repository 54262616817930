<div class="lswp-po">
  <div class="lswp-po__status">
    <span *ngIf="item.uiStatus === isCompleted" class="app-icon--success" title="Upload is complete"><i class="fal fa-check-circle"></i></span>
    <span *ngIf="item.uiStatus === isCanceled" class="app-icon--danger"><i class="fal fa-ban" title="Purchase Order was canceled"></i></span>
    <span *ngIf="item.uiStatus !== isCompleted && item.uiStatus !== isCanceled" class="app-icon--primary"><i class="fas fa-inbox-full" title="Needs Document"></i></span>
  </div>
  <div class="lswp-po__po">
    {{ item.purchaseOrderNumber }}
  </div>
</div>

