import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces/index';
import { PurchaseOrder, IPurchaseOrder, IPoDefaults, IProject, IBranchProgram} from '../../../view-models/index';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { DomainObjectService } from '../../../../../shared/services/index';

//store actions, reducers, interfaces
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { IListObjectData, IDynamicListState } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as fromDynamicObject from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import {  CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';

@Component({
  selector: 'project-purchase-order-create-container',
  templateUrl: './project-purchase-order-create-container.component.html'
})
export class ProjectPurchaseOrderCreateContainerComponent implements OnInit, OnDestroy  {
  public parentId: number = -1;
  public myStoreName: string = '';
  public requestTime: string = '';
  public title: string = 'Project Purchase Order';
  public displayFields: string[] = ['branchProgram', 'purchaseOrderNumber'];
  public canIEdit: boolean = false;
  public branchPrograms$: BehaviorSubject<IBranchProgram[]> = new BehaviorSubject(null);
  public isWarranty$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public branchId$: BehaviorSubject<number> = new BehaviorSubject(-1);
  public poDefaults$: BehaviorSubject<IPoDefaults> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  myPathName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  controllerName: string;
  projectStoreName: string = 'projectInformation';
  subscription: Subscription = new Subscription();
  bpSub: Subscription;

  constructor(
    public store: Store<fromStore.IAllDynamicData>,
    public selListStore: Store<fromSelectionLists.IStoreState>,
    public  modalService: ModalService,
    public  dos: DomainObjectService,
    public  emitterService: HomEventEmitterService,
    @Inject(appConstants) public  myConstants: IAppConstants,
    public  activeRoute: ActivatedRoute) {}

  public onCancel(): void {
    if (this.modalService.opened) {
      this.modalService.close();
    } else {
      this.store.dispatch(new DynamicListActions.ClearErrorsList({ storeName: this.myStoreName, parentId: this.parentId }));

      let emitter: IHomEventEmitter = { requestor: this.myPathName, event: this.myConstants.emitterEventClose, action: '', data: null };
      this.emitterService.emitListEvent(emitter);
    }
  }

  public onCreateRecord(e: IHomEventEmitter) {
    let rec: IPurchaseOrder = new PurchaseOrder();
    const formData: IPurchaseOrder = e.data;
    rec.project_projectId = this.parentId;
    rec.branchProgram_branchProgramId = formData['branchProgram']['branchProgramId'];
    const defaults = this.poDefaults$.getValue();
    if (this.isWarranty$.getValue()) {
      rec.purchaseOrderNumber = defaults.poNumPrefix.toString() + defaults.poNumber.toString();
    } else {
      rec.purchaseOrderNumber = defaults.poNumPrefix.toString() + formData.purchaseOrderNumber;
    }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.myStoreName, this.parentId, this.controllerName, 'Create', rec, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  ngOnInit(): void {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = this.myConstants.operationTypeCreate;
      this.myStoreName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.parentId = +paramMap.get('portalEntityId'); //projectId
      this.newRequest();
    }));
  }

  newRequest(): void {
    this.operation = this.myConstants.operationTypeCreate;
    this.getStoreData();
    this.getPODefaults();
  }

  //data will already exist in store
 //Event and Error handling is performed in wrapper component 
  getStoreData() {
    this.subscription.add(this.store.pipe(select(fromDynamicList.getListByType(this.myStoreName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.parentId)))
      .subscribe((state: IListObjectData) => {
        const listData = cloneDeep(state);
        if (listData && listData.listDefinition) {
          //will return a record per contact mechanism
          this.myPathName = listData.listDefinition.detailRoutePath;
          this.controllerName = listData.listDefinition.controllerName;
        }
      }));

    this.subscription.add(this.store.pipe(select(fromDynamicObject.getObjectDataById(this.projectStoreName, this.parentId)), take(1))
      .subscribe((objData: IProject) => {
        if (objData) {
          this.isWarranty$.next(objData.warranty_warrantyId && objData.warranty_warrantyId > 0 ? true : false);
          this.branchId$.next(objData.branch_branchId);
        }
      }));

    this.branchId$.subscribe((val) => {
      if (val && val > 0) {
        this.listenForBranchProgram(val);
      }
    })
  }

  getPODefaults() {
    this.subscription.add( this.dos.getByUrl('PurchaseOrder/GetPoDefaults?projectId='.concat(this.parentId.toString()))
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          let data = response.data;
          if (!data['poNumber']) {
            data['poNumber'] = '000000';
          }
          this.poDefaults$.next( data );
        } else {
          this.errorData$.next( response.errorData );
        }
      }));
  }


    // LISTEN FOR BRANCH PROGRAM DATA -- Should always be set
  listenForBranchProgram(branchId: number) {
    if (this.bpSub) {
      this.bpSub.unsubscribe();
    }
    this.bpSub = this.selListStore.pipe(select(fromSelectionLists.getSelectionListDataByType('branchProgram', branchId)), take(1))
      .subscribe((data: IBranchProgram[]) => {
        if (data) {
          this.branchPrograms$.next( data);
        }
      });

  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.bpSub) {
      this.bpSub.unsubscribe();
    }
 }
}
