export interface ISssProgramService {
  sssProgramServiceId: number;
  sssGeneralContractorProgram_sssGeneralContractorProgramId: number;
  programService_programServiceId: number;
  excludeAllBranchPrograms: boolean;
  dateRange: number;
  allowSlotBufferReservations: boolean;
  etaBuffer: number;
  minimumDuration: number;

  //extraData
  sssGeneralContractorId: number;
  generalContractorName: string;
  gcExcludingPrograms: boolean;
  pgmExcludingServices: boolean;
  programId: number;
  generalContractorId: number;
}

export class SssProgramService implements ISssProgramService {
  sssProgramServiceId: number;
  sssGeneralContractorProgram_sssGeneralContractorProgramId: number;
  programService_programServiceId: number;
  excludeAllBranchPrograms: boolean;
  dateRange: number;
  allowSlotBufferReservations: boolean;
  etaBuffer: number;
  minimumDuration: number;
  //extraData
  sssGeneralContractorId: number;
  generalContractorName: string;
  gcExcludingPrograms: boolean;
  pgmExcludingServices: boolean;
  programId: number;
  generalContractorId: number;

  constructor() {
    this.sssProgramServiceId = 0;
    this.sssGeneralContractorProgram_sssGeneralContractorProgramId = null;
    this.programService_programServiceId = null;
    this.excludeAllBranchPrograms = false;
    this.dateRange = 0;
    this.allowSlotBufferReservations = false;
    this.etaBuffer = 0;
    this.minimumDuration = 0;
    //extraData
    this.sssGeneralContractorId = 0;
    this.generalContractorName = '';
    this.programId = 0;
    this.generalContractorId = 0;
  }
}
