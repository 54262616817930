import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HomPipesModule } from 'hom-lib/hom-pipes';
import { FwSharedModule } from '../../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../../shared/shared.module';
import { DynamicListModule } from '../../../../fw/dynamic-list/dynamic-list.module';
import { DynamicDetailModule } from '../../../../fw/dynamic-detail/dynamic-detail.module';

//containers && components
import { UserNotificationsComponent, UserNotificationComponent, UserNotificationsContainerComponent} from './index';

//guards
import { IsCurrentGuard } from '../../../store/services/is-current-guard.service';
import { LoadingPageComponent } from '../../../../shared/components';

export const ROUTES = [
  {
    path: 'user-notifications',
    outlet: 'modal',
    component: UserNotificationsContainerComponent,
    children: [
      {
        path: 'loading/:container',
        component: LoadingPageComponent
      },
      {
        path: 'sent-notifications/:type',
        component: UserNotificationsComponent,
        children: [
          {
            path: 'user-notification/:id',
            outlet: 'commonDetailOutlet',   
            component: UserNotificationComponent,
            canActivate: [IsCurrentGuard]
          }
        ],
      }, {
        path: 'rcvd-notifications/:type',
        component: UserNotificationsComponent,
        children: [
          {
            path: 'user-notification/:id',
            outlet: 'commonDetailOutlet',   
            component: UserNotificationComponent,
            canActivate: [IsCurrentGuard]
          }
        ],
      },
    ]
  }
];


@NgModule({
  imports: [
    CommonModule,
    HomPipesModule,
    FwSharedModule,
    SharedModule,
    DynamicListModule,
    DynamicDetailModule,
    RouterModule.forChild(ROUTES)
  ],
  declarations: [
    UserNotificationsComponent,
    UserNotificationComponent,
    UserNotificationsContainerComponent
  ],
  providers: [ IsCurrentGuard],
})
export class UserNotificationsModule { }
