/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, IListButtonType } from '../../../../../fw/dynamic-list/interfaces/index';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';

@Component({
  selector: 'contact-customer-flags',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './contact-customer-flags.component.html'
})
export class ContactCustomerFlagsComponent implements OnInit {
  public listDefinition: IListDefinition;

  contactId: number;

  constructor(public activeRoute: ActivatedRoute,
    public dynamicListService: DynamicListService) { }

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
        this.contactId = +paramMap.get('id');  
        this.loadListDefinition();
      });
  }

  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Customer Flags';
    const listObjectController = 'ContactCustomerFlag';
    const listStoreName = 'contactCustomerFlags';
    const detailRoutePath = 'customer-flag-detail';
    const detailRouterOutlet = 'customerFlagOutlet';
    const listRowKeyId = 'contactCustomerFlagId';
    const defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition(detailRouterOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod =  'ByContact';
    listDefinition.methodParameters = this.contactId.toString();
    listDefinition.parentId = this.contactId;
    listDefinition.parentKey = 'contactId';

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: null }], listDefinition.objectLabel);
    listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: null },
      { type: ButtonType.edit, defaults: null },
      { type: ButtonType.delete, defaults: null }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    this.listDefinition =listDefinition; 

  }

  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('isActive');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerFlag');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('flagReasonText');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
