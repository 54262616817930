/*A child component for Installer and Technician Dashboard*/
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { DynamicListMethod } from '../../../../../fw/dynamic-list/enums/dynamic-list.enum';
import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, IListButtonType } from '../../../../../fw/dynamic-list/interfaces/index';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'installer-work-crew-pl-services',
  templateUrl: './installer-work-crew-pl-services.component.html'
})
export class InstallerWorkCrewPLServicesComponent implements OnInit, OnChanges {
  @Input() installerId: number;
  @Input() workCrewId: number;
  @Input() canIEdit: boolean = false;

  listDefinition: IListDefinition;

  constructor(public dynamicListService: DynamicListService) {}

  ngOnInit() {
    this.loadListDefinition();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workCrewId'] && !(changes['workCrewId'].firstChange)) {
      this.workCrewId = changes['workCrewId'].currentValue;
      this.loadListDefinition();
    }
  }
  /*
      Work Crew is parent id in list 
  */
  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Locations and Services';
    const listObjectController = 'ProviderLocationServiceInstallerWorkCrewViewModel';
    const listStoreName = 'workCrewPlServices';
    const detailRoutePath = 'pls-entity-detail';
    const listRowKeyId = 'providerLocationId';
    const detailRouterOutlet = 'workCrewPlServiceOutlet';

    const defaultListFilter: IListFilter = new ListFilter();


    let listDefinition = this.dynamicListService.createListDefinition(detailRouterOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = this.workCrewId;
    listDefinition.parentKey ='installerWorkCrewId';
    listDefinition.controllerMethod = 'GetProviderLocationServicesByInstallerWorkCrew';
    listDefinition.methodParameters = this.workCrewId.toString();

    listDefinition.showPrevNext = true;
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;

    const enabledDefaults: ICustomButton = {
      title: '', icon: '', cssName: '', enabled: true,
      enabledMethod: DynamicListMethod.alwaysEnabled, eventName: ''
    };
    const manageDefaults: ICustomButton = {
      title: '', icon: '', cssName: '', enabled: true,
      enabledMethod: 'canManageInstaller', eventName: '',
      methodService: 'complianceDynamicListService'
    };

    const newTools: ICustomButton[] = this.dynamicListService.loadListToolButtons([{ type: ButtonType.create, defaults: manageDefaults }], listDefinition.objectLabel);
      listDefinition.toolButtons.splice(0, 0, newTools[0]);

    let crudButtons: IListButtonType[] = [
      { type: ButtonType.detail, defaults: enabledDefaults },
      { type: ButtonType.edit, defaults: manageDefaults },
      { type: ButtonType.delete, defaults: manageDefaults }
    ];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    this.listDefinition = listDefinition; //set at end to signify ready to instantiate the list.

  }

  /*
      Define the columns in the list to be created.
  */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('locationName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceNames');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
