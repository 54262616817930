<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <div *ngIf="(objectData$ | async)">
    <fw-dynamic-form-custom [data]="objectData$ | async"
                            [storeName]="storeName"
                            [parentId]="uniqueId"
                            [displayFields]="displayFields"
                            [operation]="operation">
    </fw-dynamic-form-custom>
  </div>
  <fw-dynamic-list *ngIf="listDefinition$ | async"
                   [listTitle]="'Work Orders Requiring Inventory'"
                   [vmListDefinition]="listDefinition$ | async">
  </fw-dynamic-list>
</fw-dynamic-detail-container>
