export interface ISurveyProgramService {
  surveyProgramServiceId: number;
  survey_surveyId: number;
  programService_programServiceId: number;
}

export class SurveyProgramService implements ISurveyProgramService {
  surveyProgramServiceId: number;
  survey_surveyId: number;
  programService_programServiceId: number;

  constructor() {
    this.surveyProgramServiceId = 0;
    this.survey_surveyId = null;
    this.programService_programServiceId = null;
  }
}
