import { Component, Input,  Inject, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IListDefinition } from '../../interfaces/i-list-definition';
import { IListMetaData } from '../../../../shared/interfaces/index';
import { ICustomButton } from '../../../fw-shared/interfaces/i-custom-button';
import { DynamicListEvent } from '../../enums/dynamic-list.enum';
import { DynamicListService } from '../../services';

@Component({
  selector: 'fw-dynamic-list-tool-bar',
  templateUrl: './dynamic-list-tool-bar.component.html'
})
export class DynamicListToolBarComponent implements OnInit, OnChanges {
  @Input() listDefinition: IListDefinition;
  @Input() listMetaData: IListMetaData;
  @Input() disabled: boolean;
  @Input() staleData: boolean;
  @Input() dataAge: number;

  public reloadEvent: string;
  public toolButtons: ICustomButton[] = [];

  constructor(
    public emitterService: HomEventEmitterService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) {
    this.reloadEvent = DynamicListEvent.reloadList;
  }

  ngOnInit(): void {
    this.setToolBtns();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['listDefinition'] && !changes['listDefinition'].firstChange) ||
      (changes['listMetaData'] && !changes['listMetaData'].firstChange) ||
      (changes['disabled'] && !changes['disabled'].firstChange) ||
      (changes['staleData'] && !changes['staleData'].firstChange)) {
      this.setToolBtns();
    }
  }

  public onClick(def: ICustomButton): void {
    switch (def.eventName) {
      case DynamicListEvent.createRow:
        this.createRecord();
        break;
      case DynamicListEvent.reloadList:
        this.reloadList();
        break;
      default:
        let event: IHomEventEmitter = { requestor: 'dynamic-list-tool-bar', event: def.eventName, action: '', data: null };
        this.emitterService.emitListCustomEvent(event);
        break;
    }
  }

  createRecord() {
    let emitter: IHomEventEmitter = {
      requestor: this.listDefinition.detailRoutePath,
      event: this.myConstants.emitterEventListShowCreate,
      action: '',
      data: ''
    };
    this.emitterService.emitListEvent(emitter);
  }

  reloadList() {
    let emitter: IHomEventEmitter = {
      requestor: this.listDefinition.detailRoutePath,
      event: this.myConstants.emitterEventListReload,
      action: '',
      data: ''
    };
    this.emitterService.emitListEvent(emitter);
  }

  setToolBtns(): void {
    let btns: ICustomButton[] = [];

    this.listDefinition.toolButtons.forEach((btn) => {
      let toolBtn: ICustomButton = cloneDeep(btn);
      toolBtn.title = this.getBtnTitle(toolBtn);
      toolBtn.cssName = this.getBtnCss(toolBtn);
      toolBtn.enabled = this.isBtnEnabled(toolBtn);
      btns.push(toolBtn);
    });

    this.toolButtons = btns;
  }

  isBtnEnabled(def: ICustomButton): boolean {
    if (!def.enabled || this.disabled) {
      return false;
    }

    if (this.staleData && def.eventName !== DynamicListEvent.reloadList) {
      return false;
    }

    let enabled = true;
    if (def.enabledMethod && def.methodService) {
      enabled = this.dynamicListService.handleCustomMethod(def.methodService, def.enabledMethod, this.listMetaData, this.listDefinition.parentId);
    }

    return enabled;
  }

  getBtnCss(def: ICustomButton): string {
    let css: string = def.cssName;

    switch (def.eventName) {
      case DynamicListEvent.reloadList:
        css = this.staleData ? 'app-btn-icon--danger fa-spin' : 'app-btn-icon--primary';
        break;
      default:
        break;
    }
    return css;
  }

  getBtnTitle(def: ICustomButton): string {
    let title: string = def.title;

    switch (def.eventName) {
      case DynamicListEvent.reloadList:
        title = this.staleData ? 'The list data was retrieved '.concat(this.dataAge.toString(), ' minutes ago. Refresh Results.') : 'Refresh Results';
        break;
      default:
        break;
    }

    return title
  }

}
