import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { ContactConstantsService, ContactManagerService } from '../../services/index';
import { IFieldDefinition } from '../../../../fw/dynamic-forms';
import { StaticMetaDataService, IValueChanged } from '../../../../fw/dynamic-list/services/index';
import { IContactMechanismPhone } from '../../../portals/view-models';
import { IDeleteContactMechanism, IAddFormGroup } from '../../../portals/portal-shared/interfaces';

@Component({
  selector: 'contact-phone',
  templateUrl: './contact-phone.component.html'
})
export class ContactPhoneComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fieldDefinitions: IFieldDefinition[];
  @Input() phone: IContactMechanismPhone;
  @Input() operation: string;
  @Input() requestTime: string; //to handle subsequent create request for the same contact type
  @Input() activePreferred: number = -1;
  @Input() canIEdit: boolean;

  @Output() public deleteEvent = new EventEmitter<IHomEventEmitter>();

  public displayFields = ['isPreferred', 'phoneNumber', 'phoneExtension', 'canSms', 'contactMechanismPhoneId', 'phoneId', 'phoneType', 'phoneTypeId'];
  public definitions: IFieldDefinition[];
  public metaLoaded: boolean = false;
  public focusOn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  haveSubscribes: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(
    public  contactConstantsService: ContactConstantsService,
    public  contactService: ContactManagerService,
    public metaDataService: StaticMetaDataService,
    public  emitterService: HomEventEmitterService,
      @Inject(appConstants) public myConstants: IAppConstants) {
    }

  public deleteRecord(): void {
    //if id > 0, db delete request
    //else, remove object from form
    let data: IDeleteContactMechanism = {
      mechanism: this.contactConstantsService.contactMechanismCategoryPhone,
      type: this.phone.phoneType,
      groupName: this.phone.formGroupName,
      id: this.phone.contactMechanismPhoneId,
      url: this.operation === this.myConstants.operationTypeCreate ? '' : this.contactService.getDeleteUrl(this.contactConstantsService.contactMechanismCategoryPhone, this.phone)
    };
    let event: IHomEventEmitter = { requestor: this.contactConstantsService.requestorTypeContactPhone, event: this.myConstants.emitterEventDeleteFormGroup, action: '', data: data };
    this.deleteEvent.emit(event);
  }

  ngOnInit() {
    this.definitions = cloneDeep(this.fieldDefinitions);
    this.definitions.find(d => d.key === 'phoneNumber').label = this.phone.uiLabel;
    this.initForm();
  }

  initForm() {
    const formGroup = this.metaDataService.createDynamicFormGroup(this.definitions, this.displayFields, this.phone, this.operation);
    let data: IAddFormGroup = { groupName: this.phone.formGroupName, formGroup: formGroup };
    this.contactService.contactForm.addControl(data.groupName, data.formGroup);
    this.contactService.contactForm.controls[this.phone.formGroupName].updateValueAndValidity({ onlySelf: true });
    this.focusOn$.next(this.operation === this.myConstants.operationTypeEdit ? true : false);
    this.setSubscribes();
    this.metaLoaded = true;
}

  setSubscribes(): void {
    this.subscription.add(this.contactService.contactForm.controls[this.phone.formGroupName].get('isPreferred').valueChanges.subscribe(val => {
      this.handlePreferredChanged(val);
    }));
    this.haveSubscribes = true;
  }

  getLabel(): string {
    return this.definitions.find(k => k.key == 'phoneNumber').label;
  }

  getFieldDefinition( key: string): IFieldDefinition {
    if (!key) return null;
    return this.definitions.find(k => k.key == key);
  }

  formName() {
    return this.contactService.contactForm.controls[this.phone.formGroupName];
  }

  handlePreferredChanged(val: any) {
    let valChanged: IValueChanged = { key: 'isPreferred', value: val };
    let data = {
      mechanism: this.contactConstantsService.contactMechanismCategoryPhone,
      groupName: this.phone.formGroupName,
      id: this.phone.phoneId,
      valueChanged: valChanged
    };
    if (data.valueChanged.value) {
      //only emit on true
      let event: IHomEventEmitter = { requestor: this.contactConstantsService.requestorTypeContactPhone, event: this.myConstants.emitterEventValueChanged, action: '', data: data };
      this.emitterService.emitContactEvent(event);
    }
  }

  setPreferred() {
    const index = this.contactService.getIndex(this.contactConstantsService.phoneFormPrefix, this.phone.formGroupName)
    if (this.activePreferred !== index) {
      const control = this.contactService.contactForm.controls[this.phone.formGroupName].get('isPreferred');
      control.setValue(false);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['requestTime'] && !(changes['requestTime'].firstChange)) {
      this.initForm();
    }
    if (changes['activePreferred'] && !(changes['activePreferred'].firstChange)) {
      this.setPreferred();
    }
    if (changes['operation'] && !(changes['operation'].firstChange)) {
      if (changes['operation'].currentValue === this.myConstants.operationTypeEdit) {
        this.focusOn$.next(true);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
