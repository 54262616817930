import { Component, Input, Inject, Output, EventEmitter} from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IDashboardCount } from '../../interfaces';
import { IErrorData } from '../../../../../shared/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { SharedDashboardService } from '../../services/shared-dashboard.service';

@Component({
  selector: 'dashboard-counts',
  templateUrl: './dashboard-counts.component.html',
})
export class DashboardCountsComponent  {
  @Input() dashCounts: IDashboardCount[];
  @Input() errorData: IErrorData[];
  @Input() errorMessage: string;
  @Input() openTab: boolean = true;
  @Input() isVertical: boolean = true;

  @Output() public clicked = new EventEmitter<IHomEventEmitter>();

  constructor(
    public sharedDashboardService: SharedDashboardService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  public onClick(event: IHomEventEmitter): void {
    if (this.openTab) {
      this.openUserDashTab(event.data);
    } else {
      this.emitCountEvent(event.data);
    }
  }

    //open requested tab
  openUserDashTab(dashboardCount: IDashboardCount): void {
    this.sharedDashboardService.openDashboardTab(dashboardCount.fromPortalId, dashboardCount.fromPortalLabel, dashboardCount.fromComponentName, 'dashboard-counts');
  }

  emitCountEvent(dashboardCount: IDashboardCount): void {
    let emitter: IHomEventEmitter = {
      requestor: 'dashboard-counts', event: this.myConstants.emitterEventCountClicked, action: '', data: dashboardCount
    };
    this.clicked.emit(emitter);
  }
}
