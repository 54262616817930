import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';

import { IHomUser, IAuthResult } from '../interfaces';
import { HomLocalStorage } from '../enums/hom-local-storage.enums';

import * as fromRoot from '../../app/store/reducers/index';
import * as AuthActions from '../store/auth.actions';
import { IState } from '../store';
import { getAuthState } from '../../app/store/reducers/index';

@Component({
  templateUrl: './refresh-user.component.html'
})
export class RefreshUserComponent implements OnInit, OnDestroy  {
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>) { }

  ngOnInit(): void {
    this.subscription.add(this.rootStore.pipe(select(getAuthState))
      .subscribe((state: IState) => {
        const auth: IState = cloneDeep(state);
        if (!auth.authenticated && !auth.refreshing) {
          const storageData = localStorage.getItem(HomLocalStorage.HomUser);
          const homUser: IHomUser = JSON.parse(storageData);
          if (homUser !== null && (homUser.expiry > new Date().getTime())) {
            const authResult: IAuthResult = {
              errors: [],
              providerUser: homUser.providerUser,
              returnUrl: '',
              success: true,
              token: homUser.token,
              crmVersion: localStorage.getItem(HomLocalStorage.HomVersion)
            }
            this.rootStore.dispatch(new AuthActions.SetRefreshing(true));
            this.rootStore.dispatch(new AuthActions.RefreshUserInformation(authResult));
          }
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
