import { Component,  ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IConversationRequest } from "../../../portal-shared/interfaces/i-conversation-request";
import { CommunicationEventEvent } from "../../../portal-shared/enums/communication-event.enums";
import { ModalSizeType } from "../../../../../fw/fw-modal/interfaces/i-modal";
import { ModalService } from "../../../../../fw/fw-modal/services/fw-modal.service";

@Component({
  selector: '/utilities',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './utilities.component.html'
})
export class UtilitiesComponent implements OnInit  {
  public portalId: number = 13;
  public tabContainerName = 'utilities';
  public title: string = 'Utilities';
  subscription = new Subscription();

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public emitterService: HomEventEmitterService,
    public modalService: ModalService  ) { };

  ngOnInit() {
    this.subscription.add(this.emitterService.smsEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e.event === CommunicationEventEvent.openConversation) {
          this.showConvo(e.data);
        }
      }));
  }

  showConvo(request: IConversationRequest): void {
    this.modalService.open({
      title: 'Texting',
      path: 'sms-cust-convo/' + request.contactId.toString() + '/' + request.phone + '/'+ request.name + '/true' + '/' + request.parentName,
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      isSubModal: this.modalService.opened,
      sizeType: ModalSizeType.xsmall,
    });
  }
}
