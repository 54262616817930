<fw-detail-container *ngIf="providerLocations$ | async" [displayFields]="displayFields" [storeName]="myStoreName" [parentId]="projectId">
  <project-work-order-create [projectId]="projectId" 
                             [displayFields]="displayFields"
                             [providerLocations]="providerLocations$ | async"
                             [services]="services$ | async"
                             [requestTime]="requestTime"
                             [storeName]="myStoreName"
                             (locationChangeEvent)="onLocationChange($event)"
                             (cancelEvent)="onCancel()"
                             (createEvent)="onCreateRecord($event)">
  </project-work-order-create>
</fw-detail-container>
