import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter, HomEventEmitterService } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IListFilter, OrderTerm } from '../../../../../fw/dynamic-list/interfaces';
import { AdminPricingService } from '../../services/admin-pricing.service';
import { IService, IProviderLocation, IInstaller, IProviderLocationService, IChargeBackSkuPrice, ChargeBackSkuPrice } from '../../../view-models';
import { ChargeBackSkuPriceEvent, ChargeBackSkuPriceType } from '../../enums/charge-back-sku-price.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IMultiSelectOption } from '../../../../../fw/fw-shared/components/fw-multi-select-dropdown';
import { ListFilter } from '../../../../../../../installer-portal/src/app/interfaces';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { IErrorData, IResponseBase } from '../../../../../shared/interfaces';
import { CreateObjectModel, IKey, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists/index';
import { DomainObjectService } from '../../../../../shared/services';
import { getSelectedRecord, ClearErrorsList, UpdateObjectCustomList, CreateObjectList } from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './charge-back-sku-price-manager.component.html'
})
export class ChargeBackSkuPriceManagerComponent implements OnInit, OnDestroy {
  public allServices$: BehaviorSubject<IService[]> = new BehaviorSubject(null);
  public allLocations$: BehaviorSubject<IProviderLocation[]> = new BehaviorSubject(null);
  public allInstallers$: BehaviorSubject<IInstaller[]> = new BehaviorSubject(null);
  public locationServices$: BehaviorSubject<IMultiSelectOption[]> = new BehaviorSubject([]);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  listDefinition: IListDefinition;
  chargeBackSkuId: number = 0;
  chargeBackSkuPrice: IChargeBackSkuPrice = null;
  selectedOverrideType: ChargeBackSkuPriceType = null;
  selectedServiceId: number = 0;
  selectedLocationId: number = 0;
  selectedInstallerId: number = 0;
  selectedPLSIds: number[] = [];
  enteredPrice: number = 0.00;
  storeName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public selListStore: Store<fromSelectionLists.IStoreState>,
    public adminService: AdminPricingService,
    public domainObjectService: DomainObjectService,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants
  ) { }

  ngOnInit() {
    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.chargeBackSkuId = +paramMap.get('id');
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.objectId = +paramMap.get('id');
      this.key = paramMap.get('key');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.setDetailConfig(paramMap);
      this.newRequest();
    });
  }

  public onSave(): void {
    this.setIsValid();
    if (!this.isValid$.value) return;
    if (this.operation === this.myConstants.operationTypeCreate) {
      this.createRecord();
    } else {
      this.updateRecord();
    }
  }

  public onCancel(): void {
    this.store.dispatch(new ClearErrorsList({ storeName: this.detailConfig$.getValue().storeName, parentId: this.detailConfig$.getValue().parentId }));
    let closeEvent: IHomEventEmitter = { requestor: 'charge-back-sku-prices', event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(closeEvent);
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.chargeBackSkuId
    params.useRouterOutlet = false;
    params.wrapsForm = true;
    params.showCancel = this.operation === this.myConstants.operationTypeDetails;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  onCustomEvent(event: IHomEventEmitter): void {
    switch (event.event) {
      case ChargeBackSkuPriceEvent.changePrice:
        this.enteredPrice = event.data;
        break;
      case ChargeBackSkuPriceEvent.changePriceType:
        this.selectedOverrideType = event.data as ChargeBackSkuPriceType;
        switch (event.data) {
          case ChargeBackSkuPriceType.global:
            //disable all the other drop downs, reset selected entity
            this.selectedInstallerId = this.selectedLocationId = this.selectedServiceId = 0;
            this.selectedPLSIds = [];
            break;
          case ChargeBackSkuPriceType.installer:
            //disable all the other drop downs, reset selected entity
            this.selectedInstallerId = this.selectedLocationId = this.selectedServiceId = 0;
            this.selectedPLSIds = [];
            this.getAllInstallers();
            break;
          case ChargeBackSkuPriceType.providerLocation:
            //disable all the other drop downs, reset selected entity
            this.selectedInstallerId = this.selectedLocationId = this.selectedServiceId = 0;
            this.selectedPLSIds = [];
            this.getAllLocations();
            break;
          case ChargeBackSkuPriceType.service:
            //disable all the other drop downs, reset selected entity
            this.selectedInstallerId = this.selectedLocationId = this.selectedServiceId = 0;
            this.selectedPLSIds = [];
            this.getAllServices();
            break;
          default:
            break;
        }
        break;
      case ChargeBackSkuPriceEvent.selectInstaller:
        this.selectedInstallerId = +event.data;
        break;
      case ChargeBackSkuPriceEvent.selectLocation:
        this.selectedLocationId = +event.data;
        this.getLocationServices(true);
        break;
      case ChargeBackSkuPriceEvent.selectService:
        this.selectedServiceId = +event.data;
        this.getLocationServices(false);
        break;
      case ChargeBackSkuPriceEvent.selectLocationService:
        this.selectedPLSIds = [...this.selectedPLSIds, +event.data];
        break;
      case ChargeBackSkuPriceEvent.removeLocationService:
        const exists: number = this.selectedPLSIds.findIndex(x => x === +event.data);
        if (exists > -1) {
          this.selectedPLSIds.splice(exists, 1);
        }
        break;
      default:
        break;
    }

    this.setIsValid();
  }

  newRequest() {
    this.getDetail();
    this.listenForSelectionLists();
  }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(this.storeName, this.chargeBackSkuId, this.key, this.objectId)))
      .subscribe(entity => {
        this.chargeBackSkuPrice = !entity && this.operation === this.myConstants.operationTypeCreate
          ? new ChargeBackSkuPrice() : entity;
        this.setExistingPriceType();
      })
    );
  }

  listenForSelectionLists(): void {
    this.subscription.add(this.selListStore.pipe(select(getSelectionListDataByType('allService')))
      .subscribe((data: IService[]) => {
        this.allServices$.next(data);
        this.working$.next(false);
      }));
    this.subscription.add(this.selListStore.pipe(select(getSelectionListDataByType('allInstaller')))
      .subscribe((data: IInstaller[]) => {
        this.allInstallers$.next(data);
        this.working$.next(false);
      }));
    this.subscription.add(this.selListStore.pipe(select(getSelectionListDataByType('allProviderLocation')))
      .subscribe((data: IProviderLocation[]) => {
        this.allLocations$.next(data);
        this.working$.next(false);
      }));
  }

  getAllServices(): void {
    if (this.allServices$.value && this.allServices$.value.length > 0) {
      return;
    }
    this.working$.next(true);
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.orderTerm = [new OrderTerm('serviceName')]
    this.subscription.add(this.selListStore.select(getSelectionListDataByType('allService', -1))
      .pipe(take(1))
      .subscribe((data: any[]) => {
        if (data === null) {
          this.selListStore.dispatch(
            new fromSelectionLists.GetEntityList('Service', listFilter, 'allService'));
        } else {
          this.working$.next(false);
        }
      }));
  }

  getAllLocations(): void {
    if (this.allLocations$.value && this.allLocations$.value.length > 0) {
      return;
    }
    this.working$.next(true);
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.orderTerm = [new OrderTerm('locationName')]
    this.subscription.add(this.selListStore.select(getSelectionListDataByType('allProviderLocation', -1))
      .pipe(take(1))
      .subscribe((data: any[]) => {
        if (data === null) {
          this.selListStore.dispatch(
            new fromSelectionLists.GetEntityList('ProviderLocation', listFilter, 'allProviderLocation'));
        } else {
          this.working$.next(false);
        }
      }));
 }

  getAllInstallers(): void {
    if (this.allInstallers$.value && this.allInstallers$.value.length > 0) {
      return;
    }
    this.working$.next(true);
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.orderTerm = [new OrderTerm('installerName')]
    this.subscription.add(this.selListStore.select(getSelectionListDataByType('allInstaller', -1))
      .pipe(take(1))
      .subscribe((data: any[]) => {
        if (data === null) {
          this.selListStore.dispatch(
            new fromSelectionLists.GetEntityList('Installer', listFilter, 'allInstaller'));
        } else {
          this.working$.next(false);
        }
      }));
  }

  getLocationServices(byLocation: boolean): void {
    if (byLocation) {
      if (this.selectedLocationId == 0) {
        this.errors$.next('Select a Location');
        return;
      }
    } else if (this.selectedServiceId == 0) {
      this.errors$.next('Select a Service');
      return;
    }
    this.working$.next(true);
    const methodName: string = byLocation ? 'ByProviderLocation' : 'ByService';
    const id: number = byLocation ? this.selectedLocationId : this.selectedServiceId;
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.orderTerm = [new OrderTerm(byLocation ? 'service_serviceName': 'providerLocation_locationName')]
    this.subscription.add(this.domainObjectService.getByMethodById('ProviderLocationService', methodName, id, listFilter)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
            let multiSelectOptions: IMultiSelectOption[] = [];
            response.data.forEach((pls: IProviderLocationService) => {
              multiSelectOptions.push({ id: pls.providerLocationServiceId, name: byLocation? pls.serviceName : pls.locationName});
            });
            this.locationServices$.next( multiSelectOptions);
        } else {
          this.errorData$.next(response.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));

  }

  setExistingPriceType(): void {
    if (this.chargeBackSkuPrice.isGlobal) {
      this.selectedOverrideType = ChargeBackSkuPriceType.global;
    }
    else if (this.chargeBackSkuPrice.installer_installerId && this.chargeBackSkuPrice.installer_installerId > 0) {
      this.selectedOverrideType = ChargeBackSkuPriceType.installer;
    }
    else if (this.chargeBackSkuPrice.providerLocation_providerLocationId && this.chargeBackSkuPrice.providerLocation_providerLocationId > 0) {
      this.selectedOverrideType = ChargeBackSkuPriceType.providerLocation;
    }
    else if (this.chargeBackSkuPrice.service_serviceId && this.chargeBackSkuPrice.service_serviceId > 0) {
      this.selectedOverrideType = ChargeBackSkuPriceType.service;
    }
    else '';
  }

  setIsValid(): void {
    switch (this.selectedOverrideType) {
      case ChargeBackSkuPriceType.global:
        this.isValid$.next(true);
        break;
      case ChargeBackSkuPriceType.installer:
        this.isValid$.next(this.selectedInstallerId != 0);
        break;
      case ChargeBackSkuPriceType.providerLocation:
        this.isValid$.next(this.selectedLocationId != 0);
        break;
      case ChargeBackSkuPriceType.service:
        this.isValid$.next(this.selectedServiceId != 0);
        break;
      default:
        break;
    }
  }

  createRecord() {
    let rec: IChargeBackSkuPrice = this.setCommonProperties();
    rec.chargeBackSku_chargeBackSkuId = this.chargeBackSkuId;
    const obj = { model: rec, providerLocationServiceIds: this.selectedPLSIds }
    const emitter: IHomEventEmitter = { requestor: 'charge-back-sku-price', event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, this.chargeBackSkuId, 'ChargeBackSkuPrice', 'Create', obj, null, emitter);
    this.store.dispatch(new CreateObjectList({ createData }));
  }

  updateRecord() {
    let rec: IChargeBackSkuPrice = this.setCommonProperties();
    const obj = { model: rec, providerLocationServiceIds: this.selectedPLSIds }
    const keyData: IKey = { storeName: this.storeName, parentId: this.chargeBackSkuId, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: 'charge-back-sku-price', event: this.myConstants.emitterEventUpdate, action: 'close', data: null };
    const updateData = new UpdateObjectCustomModel(keyData, 'ChargeBackSkuPrice', 'Update', obj, null, emitter);
    this.store.dispatch(new UpdateObjectCustomList({ updateData }));
  }

  setCommonProperties(): IChargeBackSkuPrice {
    let rec = cloneDeep(this.chargeBackSkuPrice);
    rec.isGlobal = this.selectedOverrideType === ChargeBackSkuPriceType.global ? true : false;
    rec.installer_installerId = this.selectedOverrideType === ChargeBackSkuPriceType.installer ? this.selectedInstallerId : null;
    rec.providerLocation_providerLocationId = this.selectedOverrideType === ChargeBackSkuPriceType.providerLocation ? this.selectedLocationId : null;
    rec.service_serviceId = this.selectedOverrideType === ChargeBackSkuPriceType.service ? this.selectedServiceId : null;
    rec.price = this.enteredPrice || 0;
    return rec;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
