import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IHomEventEmitter } from './i-hom-event-emitter';

@Injectable({
  providedIn: 'root'
})
export class HomEventEmitterService {

  // Observable string sources
  emitChangeSource = new Subject<IHomEventEmitter>(); //generic
  emitDetailContainerEventSource = new Subject<IHomEventEmitter>();
  emitDetailEventSource = new Subject<IHomEventEmitter>();
  emitListEventSource = new Subject<IHomEventEmitter>();
  emitListContainerEventSource = new Subject<IHomEventEmitter>();
  emitFilterEventSource = new Subject<IHomEventEmitter>();
  emitToolBarEventSource = new Subject<IHomEventEmitter>();
  emitTabEventSource = new Subject<IHomEventEmitter>();
  emitContactEventSource = new Subject<IHomEventEmitter>();
  emitTabStyleChangeSource = new Subject<IHomEventEmitter>();
  emitTaskEventSource = new Subject<IHomEventEmitter>();
  emitInventoryOrderEventSource = new Subject<IHomEventEmitter>();
  emitListCustomEventSource = new Subject<IHomEventEmitter>();
  emitSkuWizardEventSource = new Subject<IHomEventEmitter>();
  emitServiceGroupWizardEventSource = new Subject<IHomEventEmitter>();
  emitSmsEventSource = new Subject<IHomEventEmitter>();
  emitCallLogEventSource = new Subject<IHomEventEmitter>();
  emitSignatureEventSource = new Subject<IHomEventEmitter>();
  emitErrorEventSource = new Subject<IHomEventEmitter>();
  emitCrmFormChangeEventSource = new Subject<IHomEventEmitter>();

  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  detailEventEmitted$ = this.emitDetailEventSource.asObservable();
  detailContainerEventEmitted$ = this.emitDetailContainerEventSource.asObservable();
  listEventEmitted$ = this.emitListEventSource.asObservable();
  listContainerEventEmitted$ = this.emitListContainerEventSource.asObservable();
  filterEventEmitted$ = this.emitFilterEventSource.asObservable();
  toolBarEventEmitted$ = this.emitToolBarEventSource.asObservable();
  tabEventEmitted$ = this.emitTabEventSource.asObservable();
  contactEventEmitted$ = this.emitContactEventSource.asObservable();
  tabStyleEventEmitted$ = this.emitTabStyleChangeSource.asObservable();
  taskEventEmitted$ = this.emitTaskEventSource.asObservable();
  inventoryOrderEventEmitted$ = this.emitInventoryOrderEventSource.asObservable();
  listCustomEventEmitted$ = this.emitListCustomEventSource.asObservable();
  skuWizardEventEmitted$ = this.emitSkuWizardEventSource.asObservable();
  smsEventEmitted$ = this.emitSmsEventSource.asObservable();
  callLogEventEmitted$ = this.emitCallLogEventSource.asObservable();
  signatureEventEmitted$ = this.emitSignatureEventSource.asObservable();
  serviceGroupWizardEventEmitted$ = this.emitServiceGroupWizardEventSource.asObservable();
  errorEventEmitted$ = this.emitErrorEventSource.asObservable();
  crmFormChangeEmitted$ = this.emitCrmFormChangeEventSource.asObservable();

  // Service message commands
  emitChange(change: IHomEventEmitter) {
    this.emitChangeSource.next(change);
  }

  // Event that should be emitted by Detail captured by DetailContainer
  emitDetailEvent(change: IHomEventEmitter) {
    this.emitDetailEventSource.next(change);
  }

  // Event that should be emitted by DetailContainer and captured by Detail
  emitDetailContainerEvent(change: IHomEventEmitter) {
    this.emitDetailContainerEventSource.next(change);
  }

  // Event that should be captured by List
  emitListEvent(change: IHomEventEmitter) {
    this.emitListEventSource.next(change);
  }

  // Event that should be captured by List
  emitListCustomEvent(change: IHomEventEmitter) {
    this.emitListCustomEventSource.next(change);
  }

  // Events that should be captured by List Conductor
  emitListContainerEvent(change: IHomEventEmitter) {
    this.emitListContainerEventSource.next(change);
  }

  // Events that should be captured by List Conductor
  emitFilterEvent(change: IHomEventEmitter) {
    this.emitFilterEventSource.next(change);
  }

  // Events that should be captured by Top Tool Bar
  emitToolBarEvent(change: IHomEventEmitter) {
    this.emitToolBarEventSource.next(change);
  }

  // Events that should be captured by the Tab Component 
  emitTabEvent(change: IHomEventEmitter) {
    this.emitTabEventSource.next(change);
  }

  // Events that should be captured by the Contact-Manager Component
  emitContactEvent(change: IHomEventEmitter) {
    this.emitContactEventSource.next(change);
  }

  emitTabStyleChangeEvent(change: IHomEventEmitter) {
    this.emitTabStyleChangeSource.next(change);
  }

  // Events that should be captured by the task-manager component
  emitTaskEvent(change: IHomEventEmitter) {
    this.emitTaskEventSource.next(change);
  }
  // Events that should be captured by the inventory-needs or inventory-orders components
  emitInventoryOrderEvent(change: IHomEventEmitter) {
    this.emitInventoryOrderEventSource.next(change);
  }

  emitSkuWizardEvent(change: IHomEventEmitter) {
    this.emitSkuWizardEventSource.next(change);
  }

  emitServiceGroupWizardEvent(change: IHomEventEmitter) {
    this.emitServiceGroupWizardEventSource.next(change);
  }

  emitSmsEvent(change: IHomEventEmitter) {
    this.emitSmsEventSource.next(change);
  }

  emitCallLogEvent(change: IHomEventEmitter) {
    this.emitCallLogEventSource.next(change);
  }

  emitSignatureEvent(change: IHomEventEmitter) {
    this.emitSignatureEventSource.next(change);
  }

  emitErrorEvent(change: IHomEventEmitter) {
    this.emitErrorEventSource.next(change);
  }

  emitCrmFormEvent(change: IHomEventEmitter) {
  this.emitCrmFormChangeEventSource.next(change);
  }

}
