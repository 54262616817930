<div class="sms-filters">
  <div class="sms-filter sms-filter__top">
    <div class="sms-filter__top__loc">
      <div title="Provider Locations" class="sms-filter--label">Location(s)</div>
      <fw-multiselect-dropdown #locations
                               [options]="locationOptions"
                               [texts]="multiSelectLabels"
                               [settings]="multiSelectSettings"
                               [isDisabled]="!locationOptions.length"
                               (onAdded)="addLocation($event)"
                               (onRemoved)="removeLocation($event)"
                               (onUncheckAll)="uncheckedAllLocations()">
      </fw-multiselect-dropdown>
    </div>
    <fw-info class="sms-filter__top__info" [infoTitle]="''">
      <div class="sms-filters--info">The results will only contain contacts you have or the selected user has sent at least one text to and whose current communication preference is Texting.<br /><br />Location and Branch filters only apply to customers.</div>
    </fw-info>
  </div>
  <div class="sms-filter">
    <div title="Branches" class="sms-filter--label">Branch(es)</div>
    <fw-multiselect-dropdown #branches
                             [options]="branchOptions$ | async"
                             [texts]="multiSelectLabels"
                             [settings]="multiSelectSettings"
                             [isDisabled]="!(branchOptions$ | async).length"
                             (onAdded)="addBranch($event)"
                             (onRemoved)="removeBranch($event)">
    </fw-multiselect-dropdown>
  </div>
  <div *ngIf="userPriviledgesService.canAuditSms$ | async" class="sms-filter">
    <div title="Provider User" class="sms-filter--label">Provider User</div>
    <select #providerUser (change)="selectProviderUser(providerUser.value)">
      <option selected value="0">Search...</option>
      <option *ngFor="let p of providerUsers"
              [value]="p.providerUserId">
        {{p.entityLabel}}
      </option>
    </select>
  </div>
  <div class="sms-filter">
    <div title="Customer" class="sms-filter--label">Contact</div>
    <div class="sms-filter__customer">
      <select #searchType class="sms-filter__customer--type" (change)="selectSearchType(searchType.value)">
        <option *ngFor="let type of searchTypes"
                [value]="type.parameterSearchTypeId">
          {{ homCommonUtility.splitWordsByCase(type.searchType)}}
        </option>
      </select>
      <input type="search" #contactName
             class="form-control sms-filter__customer--val"
             placeholder="Enter at least 3 letters..."
             (search)="changeName(contactName.value)"
             (keyup)="changeName(contactName.value)" />
    </div>
  </div>
  <div class="sms-filter">
    <div title="Exclude" class="sms-filter--label">Exclude</div>
    <select #excludeId (change)="selectExcludeId(excludeId.value)">
      <option *ngFor="let item of excludeIds"
              [value]="item.id"
              [selected]="item.id === 0">
        {{ item.value}}
      </option>
    </select>
  </div>
  <div class="sms-filter">
    <div class="check-box-field" title="Show Only Active Subscriptions">
      <fw-check-box #onlyActiveSubs
                    [config]="activeSubsConfig"
                    [isChecked]="true"
                    (change)="onChangeSubView(onlyActiveSubs.value)">
      </fw-check-box>
    </div>
  </div>

  <div class="app-btn-box">
    <button type="button"
            class="app-btn app-btn--primary"
            title="Reset"
            (click)="resetFilter()">
      Reset Filter
    </button>
    <button type="button"
            class="app-btn app-btn--primary"
            title="Apply Filter"
            [disabled]="!(isValid$ | async)"
            (click)="runFilter()">
      Apply Filter
    </button>
  </div>
</div>
