  /* Common Methods Used by User Dashboard components */
import { Injectable, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';

import { UserDashStore, UserDashEvent, UserDashTabLabel } from '../enums/user-dashboard.enums';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { ProjectEvent, ProjectListStore, ProjectStatus } from '../../project/enums/project.enums';
import { WorkOrderStatus } from '../../scheduler/enums/schedule.enums';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IListColumn, ListColumn, IListFilter,  IDetailRouteParameter, ListFilter, ISearchTerm, IListButtonType, OrderTerm } from '../../../../fw/dynamic-list/interfaces/index';
import { IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';
import { IDashSearchTerm } from '../interfaces/i-dash-search-term';

import { IProject} from '../../view-models';
import { IDashboardCount } from '../../portal-shared/interfaces';
import { ModalSizeType } from '../../../../fw/fw-modal/interfaces/i-modal';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';

import { UserPriviledgesService } from '../../../../auth/services/index';
import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { DynamicListService } from '../../../../fw/dynamic-list/services';

@Injectable({
  providedIn: 'root'
})
export class UserDashboardService {
  openProjectsFilter: IListFilter = null;
  filterForLocations: IDashSearchTerm[] = [];
  filterForBranches: IDashSearchTerm[] = [];
  lastDashCounts: IDashboardCount[] = [];

  constructor(public datePipe: DatePipe,
    public userPriviledgesService: UserPriviledgesService,
    public modalService: ModalService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public initSavedValues(): void {
    //clean up for log off
    this.openProjectsFilter = null;
    this.filterForLocations = this.filterForBranches = this.lastDashCounts = [];
  }

  loadOpenProjectsListDefinition(): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadOpenProjectsListColumns();
    const listObjectLabel = 'My Open Projects';
    const listObjectController = 'Project';
    const listStoreName = UserDashStore.dashOpenProjects;
    const detailRoutePath = '';
    const listRowKeyId = 'projectId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.searchTerm = [{ term: 'projectStatus.LookupCode', value: ProjectStatus.open, searchType: SearchType.Equals, columnName: 'projectStatus', fieldType: this.myConstants.dataTypeString }];
    defaultListFilter.orderTerm = [new OrderTerm('startDate')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    const locSearchTerm = this.loadDashLocationFilterTerms('locations', 'locations');
    const branchSearchTerm = this.loadDashBranchFilterTerms('branch_branchId', 'branches');
   if (locSearchTerm) {
      defaultListFilter.searchTerm.push(locSearchTerm);
    }
    if (branchSearchTerm) {
      defaultListFilter.searchTerm.push(branchSearchTerm);
    }

    listDefinition.parentId = -1;

    listDefinition.controllerMethod = 'ByProviderUserSlim';
    listDefinition.methodParameters = currentUserId.toString();
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;

    //no crud buttons
    listDefinition.rowButtons.push({
      title: 'Peek at Purchase Orders',
      icon: 'fad fa-ballot',
      cssName: 'app-btn-icon--custom-3',
      enabled: true,
      eventName: UserDashEvent.peekPOSummary
    },
    {
      title: 'Peek at Work Orders',
      icon: 'fad fa-clipboard-list-check',
      cssName: 'app-btn-icon--custom-2',
      enabled: true,
      eventName: UserDashEvent.peekWOSummary
    });

    return listDefinition;
  }

  loadOpenProjectsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('projectId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectCity');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    colDef.slowSort = true;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('locations');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('branches');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('programs');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('services');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isWarranty');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('hasLeadPaint');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    colDef.slowSort = true;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('startDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerContact');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadUnreceivedInventoryListDefinition(): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadUnreceivedInventoryListColumns();
    const listObjectLabel = 'My Unreceived Inventory';
    const listObjectController = 'PurchaseOrderInventoryAllocation';
    const listStoreName = UserDashStore.dashUnreceivedInventory;
    const detailRoutePath = '';
    const listRowKeyId = 'projectId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('estimatedDeliveryDate')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    const locSearchTerm = this.loadDashLocationFilterTerms('providerlocation', 'providerLocation');
    const branchSearchTerm = this.loadDashBranchFilterTerms('branch', 'branch');
    if (locSearchTerm) {
      defaultListFilter.searchTerm.push(locSearchTerm);
    }
    if (branchSearchTerm) {
      defaultListFilter.searchTerm.push(branchSearchTerm);
    }

    listDefinition.parentId = -1;

    listDefinition.controllerMethod = 'UnreceivedByProviderUser';
    listDefinition.methodParameters = currentUserId.toString();
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;

    listDefinition.groupSelect = {
      title: 'Receive Inventory',
      enabled: true,
      allowSelectAll: false,
      eventName: UserDashEvent.receiveInventory
    };

    listDefinition.rowStatus = {
      cssMethod: 'getOverdueInventoryCss',
      methodService: 'userDashDynamicListService'
    };

    return listDefinition;
  }

  loadUnreceivedInventoryListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('projectId');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('purchaseOrderNumber');
    colDef.visibleOnSmall = true;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('materialName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('vendorPoNumber');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('manufacturerName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('units');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('measureDescription');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('estimatedDeliveryDate');
    colDef.visibleOnSmall = true;
    columnDefinitions.push(colDef);
    
    colDef = new ListColumn('workOrderScheduleText');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);
    

    return columnDefinitions;
  }

  loadUnscheduledWorkOrdersListDefinition(forCount: boolean = false): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadUnscheduledWorkOrdersListColumns();
    const listObjectLabel = 'My Unscheduled Work Orders';
    const listObjectController = 'WorkOrderViewModel';
    const listStoreName = UserDashStore.dashUnscheduledWorkOrders;
    const detailRoutePath = '';
    const listRowKeyId = 'workOrderId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.searchTerm = [{ term: 'workOrderStatus_LookupValueId', value: ['22', '23'], searchType: SearchType.Equals, displayValues: [WorkOrderStatus.toBeSchedule, WorkOrderStatus.noGoods], columnName: 'workOrderStatus', fieldType: this.myConstants.dataTypeInt }];
    defaultListFilter.orderTerm = [new OrderTerm('projectId')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    const locSearchTerm = this.loadDashLocationFilterTerms('providerLocation_ProviderLocationId', 'locationName');
    const branchSearchTerm = this.loadDashBranchFilterTerms('project_Branch_BranchId', 'branchName');
    if (locSearchTerm) {
      defaultListFilter.searchTerm.push(locSearchTerm);
    }
    if (branchSearchTerm) {
      defaultListFilter.searchTerm.push(branchSearchTerm);
    }

    listDefinition.parentId = -1;
    listDefinition.showFilter = true;
    listDefinition.controllerMethod = 'UnscheduledByProviderUser';
    listDefinition.methodParameters = currentUserId.toString();
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;

    //no crud buttons
    listDefinition.rowButtons.push(
      {
        title: 'Schedule Work Order',
        icon: 'fad fa-calendar-alt',
        cssName: 'app-btn-icon--custom-2',
        enabled: true,
        eventName: UserDashEvent.scheduleWorkOrder
      },
      {
        title: 'Log Customer Action',
        icon: 'fas fa-file-signature',
        cssName: 'app-btn-icon--custom-4',
        enabled: true,
        eventName: ProjectEvent.logCustomerAction,
      });

    listDefinition.rowStatus = {
      cssMethod: 'getOverdueCallLogCss',
      methodService: 'userDashDynamicListService'
    };

    return listDefinition;
  }

  loadUnscheduledWorkOrdersListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('projectId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectCreateDate');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderCity');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('programName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderStatus');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);
    
    colDef = new ListColumn('inventoryExpectedDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('capacityUnits');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isCallLogOverDue');
    colDef.visibleOnSmall = false;
   columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadUnDispatchedWorkOrdersListDefinition(): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadUnDispatchedWorkOrdersListColumns();
    const listObjectLabel = 'My UnDispatched Work Orders';
    const listObjectController = 'WorkOrderViewModel';
    const listStoreName = UserDashStore.dashUndispatchedWorkOrders;
    const detailRoutePath = '';
    const listRowKeyId = 'workOrderId';
    const tomorrow: string = this.datePipe.transform(new Date().addDays(1), 'MM/dd/yyyy');

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.searchTerm = [
      {
        term: 'workOrderStatus_LookupValueId',
        value: ['21', '22'],
        searchType: SearchType.Equals,
        displayValues: [WorkOrderStatus.scheduled, WorkOrderStatus.noGoods],
        fieldType: this.myConstants.dataTypeInt,
        columnName: 'workOrderStatus'
      },
      {
        term: 'ScheduleStartDate',
        value: tomorrow,
        searchType: SearchType.Equals,
        fieldType: this.myConstants.dataTypeDate,
        columnName: 'scheduleStartDate'
      },
    ];
    defaultListFilter.orderTerm = [new OrderTerm('projectId')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    const locSearchTerm = this.loadDashLocationFilterTerms('providerLocation_ProviderLocationId', 'locationName');
    const branchSearchTerm = this.loadDashBranchFilterTerms('project_Branch_BranchId', 'branchName');
   if (locSearchTerm) {
      defaultListFilter.searchTerm.push(locSearchTerm);
    }
    if (branchSearchTerm) {
      defaultListFilter.searchTerm.push(branchSearchTerm);
    }

    listDefinition.parentId = -1;
    listDefinition.showFilter = true;
    listDefinition.controllerMethod = 'UnDispatchedByProviderUser'; //scheduled but no installer
    listDefinition.methodParameters = currentUserId.toString();
    listDefinition.requiredSearchTerms = [
      {
        term: 'workOrderStatus_LookupValueId',
        value: ['21', '22'],
        searchType: SearchType.Equals,
        fieldType: this.myConstants.dataTypeInt,
        displayValues: [WorkOrderStatus.scheduled, WorkOrderStatus.noGoods], columnName: 'workOrderStatus'
      },
    ];;

    //no crud buttons
    listDefinition.rowButtons.push({
      title: 'Add Note',
      icon: 'fas fa-comment-plus',
      cssName: 'app-btn-icon--primary',
      enabled: true,
      eventName: ProjectEvent.addNote,
    },
    {
      title: 'Reschedule Work Order',
      icon: 'fad fa-calendar-alt',
      cssName: 'app-btn-icon--custom-2',
      enabled: true,
      eventName: UserDashEvent.scheduleWorkOrder
    },
    {
      title: 'Dispatch',
      icon: 'far fa-people-carry',
      cssName: 'app-btn-icon--neutral',
      enabled: true,
      enabledMethod: 'canDispatch',
      methodService: 'userDashDynamicListService',
      eventName: UserDashEvent.dispatchWorkOrder,
    });

    listDefinition.rowStatus = {
      cssMethod: 'getSpecialInstructionsCss',
      methodService: 'userDashDynamicListService'
    };

    return listDefinition;
  }

  loadUnDispatchedWorkOrdersListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('specialInstructionAlert');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectId');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('scheduleStartDate');
    colDef.visibleOnSmall = false;
   colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('durationText');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderCity');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('programName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderStatus');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('inventoryExpectedDate');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('capacityUnits');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadAvailableWorkCrewsListDefinition(scheduleId: number): IListDefinition {
    const listColumns = this.loadAvailableWorkCrewsListColumns();
    const listObjectLabel = 'Available Work Crews For Schedule';
    const listObjectController = 'ScheduleInstallerWorkCrewAvailabilityViewModel';
    const listStoreName = UserDashStore.dashAvailableWorkCrews;
    const detailRoutePath = '';
    const listRowKeyId = 'installerWorkCrewId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('installerWorkCrewName')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.showFilter = false;
    listDefinition.controllerMethod = 'BySchedule';
    listDefinition.methodParameters = scheduleId.toString();

    listDefinition.singleSelect = {
      title: 'Assign Work Crew',
      enabled: true,
      eventName: UserDashEvent.selectRecord
    };

    //no crud buttons
    return listDefinition;
  }

  loadAvailableWorkCrewsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('installerWorkCrewName');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadDispatchedWorkOrdersListDefinition(forCount: boolean = false): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadDispatchedWorkOrdersListColumns();
    const listObjectLabel = 'My Dispatched Work Orders';
    const listObjectController = 'DispatchedWorkOrderViewModel';
    const listStoreName = UserDashStore.dashDispatchedWorkOrders;
    const detailRoutePath = '';
    const listRowKeyId = 'workOrderId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('projectId')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    const locSearchTerm = this.loadDashLocationFilterTerms(forCount ? 'providerLocation_ProviderLocationId':'providerLocationId', 'providerLocationId');
    const branchSearchTerm = this.loadDashBranchFilterTerms(forCount ? 'project_Branch_BranchId' : 'branchId', 'branchId');
    if (locSearchTerm) {
        defaultListFilter.searchTerm.push(locSearchTerm);
      }
    if (branchSearchTerm) {
      defaultListFilter.searchTerm.push(branchSearchTerm);
    }

    listDefinition.parentId = -1;

    //readonly
    listDefinition.showFilter = true;
    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.methodParameters = currentUserId.toString();

    //no crud buttons
    listDefinition.rowButtons.push(
      {
        title: 'Schedule Work Order',
        icon: 'fad fa-calendar-alt',
        cssName: 'app-btn-icon--custom-2',
        enabled: true,
        eventName: UserDashEvent.scheduleWorkOrder
      },
      {
        title: 'Dispatch',
        icon: 'far fa-people-carry',
        cssName: 'app-btn-icon--neutral',
        enabled: true,
        enabledMethod: 'canDispatch',
        methodService: 'userDashDynamicListService',
        eventName: UserDashEvent.dispatchWorkOrder,
      });

    listDefinition.rowStatus = {
      cssMethod: 'getSpecialInstructionsCss',
      methodService: 'userDashDynamicListService'
    };

    return listDefinition;
  }

  loadDispatchedWorkOrdersListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('customerWarningText');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectId');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectMargin');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderCity');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('scheduleStartDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workCrewName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderStatus');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPendingCloseProjectsListDefinition(): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadPendingCloseProjectsListColumns();
    const listObjectLabel = 'My Pending Close Projects';
    const listObjectController = 'Project';
    const listStoreName = UserDashStore.dashPendingCloseProjects;
    const detailRoutePath = '';
    const listRowKeyId = 'projectId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.searchTerm = [{ term: 'pendingClose', value: 'true', searchType: SearchType.Equals, columnName: 'pendingClose', fieldType: this.myConstants.dataTypeBool }];
    defaultListFilter.orderTerm = [new OrderTerm('startDate')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    const locSearchTerm = this.loadDashLocationFilterTerms('locations', 'locations');
    const branchSearchTerm = this.loadDashBranchFilterTerms('branch_branchId', 'branches');
   if (locSearchTerm) {
      defaultListFilter.searchTerm.push(locSearchTerm);
    }
    if (branchSearchTerm) {
      defaultListFilter.searchTerm.push(branchSearchTerm);
    }

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByProviderUserSlim';
    listDefinition.methodParameters = currentUserId.toString();
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;

    //no crud buttons
    listDefinition.rowButtons.push(
    {
      title: 'Peek at Purchase Orders',
      icon: 'fad fa-ballot',
      cssName: 'app-btn-icon--custom-3',
      enabled: true,
      eventName: UserDashEvent.peekPOSummary
    },
    {
      title: 'Peek at Work Orders',
      icon: 'fad fa-clipboard-list-check',
      cssName: 'app-btn-icon--custom-2',
      enabled: true,
      eventName: UserDashEvent.peekWOSummary
    });

    return listDefinition;
  }

  loadPendingCloseProjectsListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('projectId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectCity');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.slowSort = true;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isWarranty');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('startDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectAgeDays');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('hasLeadPaint');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.slowSort = true;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadOpenExternalNotesListDefinition(): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadOpenExternalNotesListColumns();
    const listObjectLabel = 'My Open External Notes';
    const listObjectController = 'Note';
    const listStoreName = UserDashStore.dashOpenExternalNotes;
    const detailRoutePath = 'reply-to-external-note';
    const listRowKeyId = 'noteId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.searchTerm = [{ term: 'Acknowledged', value: 'false', searchType: SearchType.Equals, columnName: 'acknowledged', fieldType: this.myConstants.dataTypeBool }]; //custom search term
    defaultListFilter.orderTerm = [new OrderTerm('project')];

    let listDefinition = this.dynamicListService.createListDefinition('openExternalNotesOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    const locSearchTerm = this.loadDashLocationFilterTerms('endLocationId', 'endLocationId');
    const branchSearchTerm = this.loadDashBranchFilterTerms('endBranchId', 'endBranchId');
    if (locSearchTerm) {
      defaultListFilter.searchTerm.push(locSearchTerm);
    }
    if (branchSearchTerm) {
      defaultListFilter.searchTerm.push(branchSearchTerm);
    }

    listDefinition.parentId = -1;
    listDefinition.showPrevNext = true;
    listDefinition.controllerMethod = 'ExternalNotesByProviderUser';
    listDefinition.methodParameters = currentUserId.toString();
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;


    listDefinition.rowStatus = {
      cssMethod: 'getOverdueOpenExternalNote',
      methodService: 'userDashDynamicListService'
    };

    let crudButtons: IListButtonType[] = [{ type: ButtonType.edit, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadOpenExternalNotesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('project');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('purchaseOrderNumber');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('noteText');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('createDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadUnassignedExternalNotesListDefinition(): IListDefinition {
    const listColumns = this.loadUnassignedExternalNotesListColumns();
    const listObjectLabel = 'My Unassigned External Notes';
    const listObjectController = 'PoImportNote';
    const listStoreName = UserDashStore.dashUnassignedExternalNotes;
    const detailRoutePath = 'reply-to-unassigned-external-note';
    const listRowKeyId = 'poImportNoteId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.searchTerm = [
      { term: 'poImportStatus', value: 'Staged', searchType: SearchType.Equals, columnName: 'poImportStatus', fieldType: this.myConstants.dataTypeString },
      { term: 'responseText', value: 'null', searchType: SearchType.Equals, columnName: 'responseText', fieldType: this.myConstants.dataTypeString }];
    defaultListFilter.orderTerm = [new OrderTerm('dateCreated')];


    let listDefinition = this.dynamicListService.createListDefinition('unassignedExternalNotesOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);
    //this will have branchId, but not po number in most cases
    const locSearchTerm = this.loadDashLocationFilterTerms('providerLocation', 'providerLocation');
    const branchSearchTerm = this.loadDashBranchFilterTerms('branchId', 'branchId');
    if (locSearchTerm) {
      defaultListFilter.searchTerm.push(locSearchTerm);
    }
    if (branchSearchTerm) {
      defaultListFilter.searchTerm.push(branchSearchTerm);
    }

    listDefinition.parentId = -1;
    listDefinition.showPrevNext = true;
    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.methodParameters = '1';
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;

    listDefinition.rowStatus = {
      cssMethod: 'getOverdueUnassignedExternalNote',
      methodService: 'userDashDynamicListService'
    };

    let crudButtons: IListButtonType[] = [{ type: ButtonType.edit, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadUnassignedExternalNotesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('storeNumber');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('purchaseOrderNumber');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('programName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('createUser');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('noteText');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('dateCreated');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadPendingTasksListDefinition(): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadPendingTasksListColumns();
    const listObjectLabel = 'My Pending Tasks';
    const listObjectController = 'ProviderUserProjectTaskRollupModel';
    const listStoreName = UserDashStore.dashPendingTasks;
    const detailRoutePath = '';
    const listRowKeyId = 'projectId';

    const locSearchTerm = this.loadDashLocationFilterTerms('locationId', 'locationId');
    const branchSearchTerm = this.loadDashBranchFilterTerms('branchId', 'branchId');
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.searchTerm = [{ term: 'projectStatus.LookupCode', value: 'open', searchType: SearchType.Equals, columnName: 'projectStatus', fieldType: this.myConstants.dataTypeString }];
    defaultListFilter.orderTerm = [new OrderTerm('projectId')];

    if (locSearchTerm) {
      defaultListFilter.searchTerm.push(locSearchTerm);
    }
    if (branchSearchTerm) {
      defaultListFilter.searchTerm.push(branchSearchTerm);
    }

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.showPrevNext = false;
    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.methodParameters = currentUserId.toString();
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;

    listDefinition.rowStatus = {
      cssMethod: 'getOverDueTaskCss',
      methodService: 'userDashDynamicListService'
    };

    //no crud buttons
    return listDefinition;
  }

  loadPendingTasksListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('projectId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    colDef.visibleOnSmall = false;
   columnDefinitions.push(colDef);

    colDef = new ListColumn('branchName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('totalTasks');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('overdueTasks');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('dueTodayTasks');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadSssInvitesActionNeededListDefinition(): IListDefinition {
    const currentUserId = this.userPriviledgesService.currentUserId$.getValue();
    const listColumns = this.loadSssInvitesActionNeededListColumns();
    const listObjectLabel = UserDashTabLabel.sssInvitesRequiringAction;
    const listObjectController = 'CommunicationEventSssUnrespondedToViewModel';
    const listStoreName = UserDashStore.dashSSSRequiringActions;
    const detailRoutePath = 'invites-by-workorder';
    const listRowKeyId = 'workOrderId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('projectId')];

    let listDefinition = this.dynamicListService.createListDefinition('invitesByWoOutlet',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.showPrevNext = true;
    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.methodParameters = currentUserId.toString();

    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    return listDefinition;
  }

  loadSssInvitesActionNeededListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('customerName');
    colDef.visibleOnSmall = false;
  columnDefinitions.push(colDef);

    colDef = new ListColumn('projectId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('locationName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('programName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('serviceName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('numberOfInvitationsSent');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('dateEarliestInvitationSent');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('dateCurrentInvitationSent');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('sssCommunicationEventStatus');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('providerUserName');
    colDef.visibleOnSmall = false;
   columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadWorkOrderInvitationListDefinition(workOrderId: number): IListDefinition {
    const listColumns = this.loadWorkOrderInvitationListColumns();
    const listObjectLabel = 'Invitations Sent';
    const listObjectController = 'SssInvitationByWorkOrderViewModel';
    const listStoreName = UserDashStore.dashSSSInvites;
    const detailRoutePath = '';
    const listRowKeyId = 'communicationEventId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('sendDate')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = workOrderId;
    listDefinition.parentKey = 'workOrderId';
    listDefinition.showPrevNext = false;
    listDefinition.showFilter = false;
    listDefinition.controllerMethod = 'GetSssInvitationInfoByWorkOrder';
    listDefinition.methodParameters = workOrderId.toString();

    //no row buttons

    return listDefinition;
  }

  loadWorkOrderInvitationListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('sendDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('openDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('dateRangeOffered');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('feedback');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadSssOptionsPresentedListDefinition(workOrderId: number): IListDefinition {
    const listColumns = this.loadSssOptionsPresentedListColumns();
    const listObjectLabel = 'Schedule Dates Clicked';
    const listObjectController = 'SssOptionGroupCountsViewModel';
    const listStoreName = UserDashStore.dashSSSOptionCounts;
    const detailRoutePath = '';
    const listRowKeyId = 'selectDate';  

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('sendDate')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = workOrderId;
    listDefinition.parentKey = 'workOrderId';
    listDefinition.showPrevNext = false;
    listDefinition.showFilter = false;

    listDefinition.controllerMethod = 'GetSssOptionsPresentedByWorkOrder';
    listDefinition.methodParameters = workOrderId.toString();

    //no crud buttons
    return listDefinition;
  }

  loadSssOptionsPresentedListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('selectDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('count');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  /*List Filter State Management Methods*/

  getOpenProjectsSavedFilter(): IListFilter {
    return this.openProjectsFilter;
  }

  saveOpenProjectsFilter(listFilter: IListFilter): void {
    this.openProjectsFilter = listFilter;
  }

  clearOpenProjectsFilter(): void {
    this.openProjectsFilter = null;
  }


/*Custom Methods Consumed by Multiple Lists */
  showMiniWOSummary(data: IProject): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.projectWorkOrders,
      key: 'project_projectId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.projectId //to show wos by project id
    }

    this.modalService.open({
      title: 'Work Order Summary for Project'.concat(' ', data.projectId.toString()),
      path: 'user-dash-work-order-summary',
      id: data.projectId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

  showMiniPOSummary(data: IProject): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.projectPurchaseOrders,
      key: 'project_projectId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.projectId //to show wos by project id
    }

    this.modalService.open({
      title: 'Purchase Order Summary for Project'.concat(' ', data.projectId.toString()),
      path: 'user-dash-purchase-order-summary',
      id: data.projectId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

  loadDashLocationFilterTerms(locationTerm: string, columnName: string): ISearchTerm {
    if (this.filterForLocations.length === 0) {
      return null;
    }
    const ids: string[] = this.filterForLocations.map(x => x.listId.toString());
    const displayValues: string[] = this.filterForLocations.map(x => x.label);
    return { term: locationTerm, value: ids, searchType: SearchType.Equals, columnName: columnName, displayValues: displayValues, fieldType: this.myConstants.dataTypeInt }
  }

  loadDashBranchFilterTerms(branchTerm: string, columnName: string): ISearchTerm {
    if (this.filterForBranches.length === 0) {
      return null;
    }
    const ids: string[] = this.filterForBranches.map(x => x.branchId.toString());
    const displayValues: string[] = this.filterForBranches.map(x => x.label);
    return { term: branchTerm, value: ids, searchType: SearchType.Equals, columnName: columnName, displayValues: displayValues, fieldType: this.myConstants.dataTypeInt }
  }

}
