<div class="user-access-create-title-bar">
  <div class="user-access-create-title-bar__title">Modify {{ title }} Access</div>
  <div class="user-access-create-title-bar__btn">
    <button type="button"
            class="app-btn-icon app-btn-icon--primary"
            title="Cancel"
          (click)="cancel()">
      <i class="fas fa-ban"></i>
    </button>
    <button type="button"
            class="app-btn-icon app-btn-icon--success"
            (click)="save()"
            [disabled]="!selectedItems.length"
            title="Save {{ title }} Access">
      <i class="fas fa-save "></i>
    </button>
  </div>
</div>

<div  class="user-access-create-items">
  <div class="user-access-create-items__selectAll">
    <div class="user-access-create-items__selectAll__name" [ngClass]="{'user-access-create-items__selectAll--selected': isAllSelected }">Select All</div>
    <div></div>
    <!--CONTINUE HERE
        [ngClass]="{'check-box-field user-access-create-items__selectAll__input--notall': (count$ | async) !== '0' && (count$ | async) !== 'All' }"
        -->
    <div class="check-box-field user-access-create-items__selectAll__input"
         title="Select All Locations and Branches">
      <fw-check-box #selectAllItems
                    [config]="selectAllConfig"
                    [isDisabled]="!(workingItems$ | async)"
                    (change)="selectAll($event)">
      </fw-check-box>
    </div>
    <div></div>
  </div>
  <ng-container *ngFor="let item of (workingItems$ | async)">
    <user-access-create-item [customClass]="getItemClass(item)"
                             [item]="item"
                             [name]="getItemName(item)"
                             [isAllSelected]="isAllSelected"
                             (selectEvent)="selectItem($event)">
    </user-access-create-item>
  </ng-container>
</div>
