import {  Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { IDetailContainerConfig, DetailContainerConfig } from '../interfaces';
import { IDetailTab } from '../interfaces/i-detail-tab';

@Injectable({
  providedIn: 'root'
})

export class DynamicDetailService  {

  tabDefinitions: IDetailTab[] = [];

  setDetailConfig(paramMap: ParamMap): IDetailContainerConfig {
    return new DetailContainerConfig(+paramMap.get('id'),
      paramMap.get('storeName'),
      paramMap.get('key'),
      paramMap.get('operation'),
      paramMap.get('showNext') === 'true',
      paramMap.get('showPrev') === 'true',
      paramMap.get('requestTime'),
      +paramMap.get('rowIndex'));
  }


}
