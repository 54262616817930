import { Component, OnInit, OnDestroy, Inject, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { DomainObjectService } from '../../../../../shared/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { IHomEventEmitter, HomEventEmitterService } from '../../../../../../../hom-lib/hom-event-emitter';
import { CrmFormsEvent } from '../../../project/containers/crm-form-entry/crm-forms.enums';
import { ProjectService } from '../../services';

@Component({
  selector: 'crm-forms-manager',
  templateUrl: './crm-forms-manager.component.html'
})
export class CrmFormsManagerComponent implements OnInit, OnDestroy {
  public entityDocumentVersionId: number = 0;
  public projectId: number = 0;
  subscription: Subscription = new Subscription();

    constructor(
    public activeRoute: ActivatedRoute,
    public modalService: ModalService,
    public dos: DomainObjectService,
    public emitterService: HomEventEmitterService,
    public projectService: ProjectService,
    @Inject(appConstants) public myConstants: IAppConstants) {
  }
  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.entityDocumentVersionId = +paramMap.get('id');
      this.projectId = +paramMap.get('projectId');
    });
    this.homeEventListener();
  }


  homeEventListener(): void {
    this.subscription.add(this.emitterService.crmFormChangeEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e) {
          switch (e.event) {
            case CrmFormsEvent.crmFormChange:
              if (e.requestor === 'project-required-documents') this.reloadList();
              break;
            default:
              break;
          }
        }
      }));
  }

  reloadList(): void {
    this.projectService.dispatchRequiredDocumentsGet(this.projectId, true);
    this.projectService.emitTaskReloadEvent('project-required-documents', this.projectId);
  }

  public onCancel() {
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  public onCreate() {
    event.stopPropagation();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
