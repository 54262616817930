<div class="list-wrapper">
  <fw-dynamic-list *ngIf="listDefinition"
                   [listTitle]="'Lookup Values'"
                   [vmListDefinition]="listDefinition">
  </fw-dynamic-list>
</div>




