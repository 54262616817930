import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FwModule } from '../../../fw/fw.module';
import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../shared/shared.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { ContactModule } from '../../contact/contact.module';
import { SmsModule } from '../../sms/sms.module';

import { SchedulerRoutingModule } from './scheduler-routing.module';

//services
import { SchedulerService } from './services/scheduler.service';

//components
import {
  AvailableInstallersComponent,
  UnavailableInstallersComponent,
  ScheduleComponent,
  ScheduleIneligibleComponent,
  ScheduleCalendarComponent,
  ScheduleMoveupComponent,
  ScheduleTimespanComponent,
  WorkOrderWorkCategoriesComponent
} from './components/index';

//containers
import {
  ScheduleEditorComponent,
  ScheduleInstallersComponent,
  ScheduleManagerComponent,
  SchedulePortalManagerComponent} from './containers/index';
import { RequiredDocumentService } from '../portal-shared/services';

@NgModule({
  declarations: [
    AvailableInstallersComponent,
    UnavailableInstallersComponent,
    ScheduleComponent,
    ScheduleIneligibleComponent,
    ScheduleCalendarComponent,
    ScheduleMoveupComponent,
    ScheduleTimespanComponent,
    ScheduleInstallersComponent,
    ScheduleEditorComponent,
    ScheduleManagerComponent,
    SchedulePortalManagerComponent,
    WorkOrderWorkCategoriesComponent
],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FwSharedModule,
    FwModule,
    SharedModule,
    PortalSharedModule,
    ContactModule,
    SmsModule,
    SchedulerRoutingModule
  ],
  exports: [ 
    AvailableInstallersComponent,
    UnavailableInstallersComponent,
    ScheduleComponent,
    ScheduleIneligibleComponent,
    ScheduleCalendarComponent,
    ScheduleMoveupComponent,
    ScheduleTimespanComponent,
    ScheduleInstallersComponent,
    ScheduleEditorComponent,
    ScheduleManagerComponent,
    SchedulePortalManagerComponent,
    WorkOrderWorkCategoriesComponent
 ]
})
export class SchedulerModule {
  static forRoot(): ModuleWithProviders<SchedulerModule> {
    return {
      ngModule: SchedulerModule,
      providers: [
        SchedulerService,
        RequiredDocumentService
      ]
    };
  }
}
