
export interface ISurvey {
  surveyId: number;
  provider_providerId: number;
  surveyName: string;
  isActive: boolean;
  //extra data fields
  numQuestions: string;
}

export class Survey implements ISurvey {
  surveyId: number;
  provider_providerId: number;
  surveyName: string;
  isActive: boolean;
  //extra data fields
  numQuestions: string;

  constructor() {
    this.surveyId = 0;
    this.provider_providerId = 1;
    this.surveyName = '';
    this.isActive = false;
  }
}
