  <div *ngIf="(chargeBack$ | async) as chargeBack" [ngClass]="{ 'loading-mask': loading$ | async }">
    <form *ngIf="form && (mds.mdsReady$ | async)" [formGroup]="form" novalidate>
      <div class="list-wrapper">
        <fw-component-title [title]="'Overview'"
                            [entityLabel]="'Charge Back'"
                            [entityStatus]="chargeBack.chargeBackStatus">
        </fw-component-title>
        <div class="form-wrapper">
          <fw-dynamic-field [field]="mds.getFieldDefinition('customerName')"
                            [form]="form"
                            [operation]="myConstants.operationTypeDetails">
          </fw-dynamic-field>
          <fw-dynamic-field [field]="mds.getFieldDefinition('installerName')"
                            [form]="form"
                            [operation]="myConstants.operationTypeDetails">
          </fw-dynamic-field>
          <div class="field-side-by-side installment__details">
            <fw-dynamic-field [field]="mds.getFieldDefinition('chargeBackStatus')"
                              [form]="form"
                              [operation]="myConstants.operationTypeDetails"
                              [selectItems]="chargeBackStatuses">
            </fw-dynamic-field>
            <fw-dynamic-field [field]="mds.getFieldDefinition('acknowledged')"
                              [form]="form"
                              [operation]="myConstants.operationTypeDetails">
            </fw-dynamic-field>
            <fw-dynamic-field [field]="mds.getFieldDefinition('submitted')"
                              [form]="form"
                              [operation]="myConstants.operationTypeDetails">
            </fw-dynamic-field>
          </div>
          <fw-dynamic-field [field]="mds.getFieldDefinition('chargeBackNotes')"
                            [form]="form"
                            [operation]="myConstants.operationTypeDetails">
          </fw-dynamic-field>
          <div class="installment-status">
            <fw-dynamic-field [field]="mds.getFieldDefinition('amountTotal')"
                              [form]="form"
                              [operation]="myConstants.operationTypeDetails">
            </fw-dynamic-field>
            <fw-dynamic-field *ngIf="chargeBack.submitted && chargeBack.numInstallments > 0"
                              [field]="mds.getFieldDefinition('remainingBalance')"
                              [form]="form"
                              [operation]="myConstants.operationTypeDetails">
            </fw-dynamic-field>
            <fw-dynamic-field *ngIf="chargeBack.submitted && chargeBack.numInstallments > 0"
                              [field]="mds.getFieldDefinition('lastInstallmentDue')"
                              [form]="form"
                              [operation]="myConstants.operationTypeDetails">
            </fw-dynamic-field>
          </div>
        </div>
      </div>

      <div class="list-wrapper">
        <fw-component-title [title]="optionsTitle" class="installment__title"></fw-component-title>
        <div class="field-side-by-side">
          <fw-radio-button [groupName]="'payOption'"
                           [buttons]="payOptions"
                           [isDisabled]="(chargeBack.submitted || chargeBack.amountTotal === 0 || operation === myConstants.operationTypeDetails)"
                           (change)="selectOption($event.srcElement.value)">
          </fw-radio-button>
        </div>
        <fieldset>
          <legend>Installment Plan</legend>
          <div class="installment-plan">
            <div>
              <div class="field-side-by-side">
                <fw-dynamic-field [field]="mds.getFieldDefinition('numInstallments')"
                                  [form]="form"
                                  [operation]="(installNumDisabled$ | async) ? myConstants.operationTypeDetails : operation">
                </fw-dynamic-field>
                <label class="installment__mid-label"> installments OR </label>
                <fw-dynamic-field [field]="mds.getFieldDefinition('installmentAmount')"
                                  [form]="form"
                                  [operation]="(installAmtDisabled$ | async) ? myConstants.operationTypeDetails : operation">
                </fw-dynamic-field>
              </div>
              <div class="field-side-by-side">
                <fw-dynamic-field [field]="mds.getFieldDefinition('installmentFrequency')"
                                  [form]="form"
                                  [operation]="(installFreqDisabled$ | async) ? myConstants.operationTypeDetails : operation">
                </fw-dynamic-field>
                <label class="installment__mid-label">week(s)</label>
              </div>
              <div *ngIf="hitMaxLimit$ | async" class="alert-box alert-box--danger">
                The maximum number of installments can not be greater than: {{ chargeBack.chargeBackInstallmentsMax }}.
              </div>
              <div *ngIf="!chargeBack.submitted && chargeBack.amountTotal <= 0" class="alert-box alert-box--danger">
                Charge Backs with a Total Amount of $0 can not be Submitted.
              </div>
              <div *ngIf="!chargeBack.submitted && isFullAmt" class="alert-box alert-box--info">
                The Full Amount of {{ chargeBack.remainingBalance ? chargeBack.remainingBalance : chargeBack.amountTotal }} will be submitted.
              </div>
            </div>
            <div *ngIf="form.valid && !isSubmitted && (isValid$ | async) && !isFullAmt" class="installment-plan__estimate">
              <div class="installment-estimate">
                <h4 class="installment-estimate__title"><span class="installment-estimate__title--text">Installment Payment Preview</span></h4>
                <div class="installment-estimate__nbr installment-estimate__header">#</div>
                <div class="installment-estimate__amount installment-estimate__header">Amount</div>
                <div class="installment-estimate__date installment-estimate__header">Date</div>
                <ng-container *ngFor="let row of (installEstimates$ | async)">
                  <div class="installment-estimate__nbr">{{ row.nbr }}</div>
                  <div class="installment-estimate__amount">{{ row.amount | number: '1.2-2' }}</div>
                  <div class="installment-estimate__date">{{ row.date | date | date:'MM/dd/yyyy' }}</div>
                </ng-container>
              </div>
              <div class="installment-plan__info">This is an estimation only!</div>
              <div class="installment-plan__info">Actual dollar values will be displayed after submitting the Charge Back.</div>
              <div class="installment-plan__info">Click the <b>Submit</b> button below to set up the installment plan.</div>
            </div>
          </div>
        </fieldset>
        <div class="list-wrapper" *ngIf="isSubmitted">
          <charge-back-installments [chargeBackId]="chargeBack.chargeBackId"></charge-back-installments>
        </div>

        <fw-error-box *ngIf="(errorData$ | async)?.length" [errorData]="errorData$ | async" [joinErrors]="true"></fw-error-box>
        <div class="app-btn-box">
          <button type="button" class="app-btn app-btn--cancel" (click)="close()">{{closeLabel}}</button>
          <button class="app-btn app-btn--primary"
                  type="button"
                  (click)="submit()"
                  [disabled]="(!isFullAmt && !form.valid) || isSubmitted || !(isValid$ | async)  || operation === myConstants.operationTypeDetails">
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
