export interface IPayoutPrice {
  payoutPriceId: number;
  payout_payoutId: number;
  isGlobal: boolean;
  generalContractor_generalContractorId: number;
  providerLocation_providerLocationId: number;
  service_serviceId: number;

  //extra data 
  defaultDeckPrice: number;
  effectivePayoutDeckId: number;
  effectiveEndDate: string;
  effectiveDeckPrice: number;
  providerLocationServiceName: string;
  providerLocationLocationName: string;
}

export class PayoutPrice implements IPayoutPrice {
  payoutPriceId: number;
  payout_payoutId: number;
  isGlobal: boolean;
  generalContractor_generalContractorId: number;
  providerLocation_providerLocationId: number;
  service_serviceId: number;

  //extra data 
  defaultDeckPrice: number;
  effectivePayoutDeckId: number;
  effectiveEndDate: string;
  effectiveDeckPrice: number;
  providerLocationServiceName: string;
  providerLocationLocationName: string;

  constructor() {
    this.payoutPriceId = 0;
    this.payout_payoutId = 0;
    this.isGlobal = true;
    this.generalContractor_generalContractorId = null;
    this.providerLocation_providerLocationId = null;
    this.service_serviceId = null;
  }
}

