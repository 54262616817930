import { Component, OnInit,  OnDestroy, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { DomainObjectService } from '../../../../../shared/services';
import { IResponseBase } from '../../../../../shared/interfaces';

@Component({
  selector: 'post-it-notes',
  templateUrl: './post-it-notes.component.html'
})
export class PostItNotesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() projectId: number;
  @Input() workOrderId: number;

  public postIts$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  subscription: Subscription = new Subscription();

  constructor(public domainObjectService: DomainObjectService) { }

  ngOnInit() {
    this.getNotes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workOrderId'] && !changes['workOrderId'].isFirstChange()) {
      this.getNotes();
    }
  }

  getNotes() {
    this.subscription.add(this.domainObjectService.getByMethodById('Note', 'PostItsByProject', this.projectId)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.postIts$.next(response.data);
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
