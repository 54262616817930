import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef, ChangeDetectorRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { getSelectionListDataByType, listExistsByType, GetEntityList } from '../../../../shared/store/selectionLists';
import {
  IProviderLocation, IProviderLocationBranch,
  IProviderUser,
  IProjectMin
} from '../../../portals/view-models';
import { IErrorData, IResponseBase, IPageMetaData, IHomDictionary } from '../../../../shared/interfaces';
import { ISmsContactViewModel } from '../../../portals/view-models/i-sms-contact-view-model';
import { IListFilter, ListFilter } from '../../../../fw/dynamic-list/interfaces';
import { SmsEvent } from '../../enums/sms.enums';
import { IInfiniteScrollOptions } from '../../../../fw/fw-shared/interfaces';
import { ISmsFilterEvent } from '../../interfaces/i-sms-filter-event';
import { IAppConstants, appConstants } from '../../../../shared/constants';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { IScreenBreakpoints } from '../../../../fw/fw-shared/services/i-screen-breakpoints';

import * as fromRoot from '../../../store/reducers/index';
import { DomainObjectService } from '../../../../shared/services';
import { UserPriviledgesService } from '../../../../auth/services';
import { ProviderUserService } from '../../../portals/portal-shared/services/provider-user.service';
import { ScreenService } from '../../../../fw/fw-shared/services/screen.service';

@Component({
  selector: 'sms-manager',
  templateUrl: './sms-manager.component.html'
})
export class SmsManagerComponent implements OnInit, OnDestroy {
  @ViewChildren('listContainer') listContainer: QueryList<ElementRef>
  public providerLocations$: BehaviorSubject<IProviderLocation[]> = new BehaviorSubject(null);
  public branches$: BehaviorSubject<IProviderLocationBranch[]> = new BehaviorSubject(null);
  public providerUsers$: Observable<IProviderUser[]>;
  public scrollOptions: IInfiniteScrollOptions = { scrollDown: true };
  public activeListItem$: BehaviorSubject<string> = new BehaviorSubject('');
  public activeDetailItem$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public contacts$: BehaviorSubject<ISmsContactViewModel[]> = new BehaviorSubject([]);
  public projectMins$: BehaviorSubject<IProjectMin[]> = new BehaviorSubject([]);
  public pageMetaData$: BehaviorSubject<IPageMetaData> = new BehaviorSubject(null);
  public totalItems$: BehaviorSubject<number> = new BehaviorSubject(-1);
  public newMessageAlert$: BehaviorSubject<string> = new BehaviorSubject('');
  public resetTimestamp$: BehaviorSubject<string> = new BehaviorSubject('');
  public screenIsSmall$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public refreshOn: string = '';
  public activeFilterText: string;
  public mgrFilter: ISmsFilterEvent = null;
  public refreshBtn: ICustomButton = null;
  public uiPage: number = 1;

  subscription: Subscription = new Subscription();
  filtersItem: string = 'filters';
  contactsItem: string = 'contacts';
  contactInfoItem: string = 'contactInfo';
  projectItem: string = 'project';
  smsSub: Subscription = new Subscription();
  projSub: Subscription = new Subscription();
  activeContact: ISmsContactViewModel = null;
  currentPage: number = 0;
  itemsPerPage: number = 0;
  providerUsersStore: string = 'providerUser';
  timestamp: string;

  constructor(
    public activeRoute: ActivatedRoute,
    public router: Router,
    public rootStore: Store<fromRoot.IState>,
    public domainObjectService: DomainObjectService,
    public userPriviledgesService: UserPriviledgesService,
    public emitterService: HomEventEmitterService,
    public changeDetector: ChangeDetectorRef,
    public providerUserService: ProviderUserService,
    public screenService: ScreenService,
    public el: ElementRef,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit(): void {
    this.refreshBtn = {
      eventName: SmsEvent.refreshContacts,
      title: 'Refresh Contacts',
      cssName: 'app-btn-icon--white',
      icon: 'fas fa-sync',
      enabled: true,
    };

    this.activeRoute.paramMap.subscribe((paramMap) => {
      this.routeToEmpty();
      this.newRequest();
    });

    this.subscription.add(this.emitterService.smsEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case SmsEvent.receivedNewText:
            this.newMessageAlert$.next('*New Messages Received.  Refresh Contacts*');
            break;
          case SmsEvent.sentText:
            //These are always from you so from this component. Refresh Contacts.
            this.refreshList(true);
            break;
          default:
            break;
        }
      }));

    this.subscription.add(this.screenService.resize$
      .subscribe((e: IScreenBreakpoints) => {
        if (this.screenService) {
          this.setSize();
        }
      })
    );

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.router.url.includes('chat:')) {
      this.router.navigate([{ outlets: { chatbar: null } }],
        { relativeTo: this.activeRoute.parent });
    }
  }

  public onToggleListItem(item: string): void {
    this.activeListItem$.next(item);
  }

  public onToggleDetailItem(item: string, itemId: number = 0): void {
    this.activeDetailItem$.next(item);
  }

  public onActivated(timestamp: string): void {
    this.changeDetector.detectChanges();
  }

  public onScrollEnd(): void {
    this.getContacts();
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case SmsEvent.selectContact:
        const initRelated: boolean = this.activeContact === null ? false : true;
        this.activeContact = event.data;
        this.uiPage = 2;
        //set detail list default to contact info
        if (initRelated) {
          this.routeToEmpty();
          this.projectMins$.next([]);
          this.activeDetailItem$.next(this.contactInfoItem);
        }
        this.routeToConvo();
        this.getProjectMins();
        break;
      case SmsEvent.filterContacts:
        this.mgrFilter = event.data;
        this.refreshList();
        break;
      case SmsEvent.refreshContacts:
        this.refreshContacts();
        break;
      case SmsEvent.unSubscribe:
        this.changeContactSubscription(event.data, true);
        break;
      case SmsEvent.subscribe:
        this.changeContactSubscription(event.data, false);
        break;
      default:
        break;
    }
  }


  public changeUiPage(loc: string): void {
    this.uiPage = loc === 'contacts'
      ? 1
      : loc === 'convo'
        ? 2
        : loc === 'info'
          ? 3 : this.uiPage;
  }

  initList(): void {
    this.currentPage = 0;
    this.totalItems$.next(-1);
    this.contacts$.next([]);
    this.activeListItem$.next(this.contactsItem);
    this.activeContact = null;
    this.projectMins$.next([]);
  }

  refreshContacts(): void {
    this.mgrFilter = null;
    this.refreshList();
    this.resetTimestamp$.next(new Date().toTimeString());
  }

  refreshList(skipRoute: boolean = false): void {
    this.initList();
    if (!skipRoute) {
      this.routeToEmpty();
    }
    this.setFilterText();
    this.getContacts();
    this.newMessageAlert$.next('');
  }

  getSelectionListData(): void {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('providerLocation')))
      .subscribe((data) => {
        if (data) {
          this.providerLocations$.next(data);
        }
      }));

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('providerLocationBranch')))
      .subscribe((data) => {
        this.branches$.next(data);
      }));

    //check to see if need to load provider users to cached store
    this.subscription.add(this.rootStore.select(listExistsByType(this.providerUserService.storeName))
      .pipe(filter((exists: boolean) => exists === false), take(1))
      .subscribe((exists) => {
        this.rootStore.dispatch(new GetEntityList('ProviderUser', this.providerUserService.defaultListFilter));
      }));

    //listen for provider users
    this.providerUsers$ = this.rootStore.select(getSelectionListDataByType(this.providerUsersStore));
  }

  newRequest() {
    this.activeListItem$.next(this.contactsItem);
    this.activeDetailItem$.next(this.contactInfoItem);
    this.getSelectionListData();
    this.mgrFilter = null;
    this.currentPage = 0;
    this.getContacts();
    this.uiPage = 1;
    this.setSize();
  }

  getContacts(): void {
    if (this.smsSub) {
      this.smsSub.unsubscribe();
    }

    const totalItems: number = this.totalItems$.value;
    if (totalItems > -1) {
      if (totalItems === 0 || (this.itemsPerPage * this.currentPage >= totalItems)) {
       //no more rows waiting to be retrieved
        return;
      }
    }

    this.working$.next(true);
    this.currentPage = this.currentPage + 1;
    this.errorData$.next([]);

    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = false;
    listFilter.currentPage = this.currentPage;
    const locationIds: string = this.mgrFilter && this.mgrFilter.providerLocations ? this.mgrFilter.providerLocations.map(x => x.providerLocationId).toString() : '';
    const branchIds: string = this.mgrFilter && this.mgrFilter.providerLocationBranches ? this.mgrFilter.providerLocationBranches.map(x => x.branch_branchId).toString() : '';
    const providerUserId: number = this.mgrFilter && this.mgrFilter.providerUser
      ? this.mgrFilter.providerUser.providerUserId
      : this.userPriviledgesService.currentUserId$.value;
    const searchType: string = this.mgrFilter && this.mgrFilter.searchType ? this.mgrFilter.searchType.searchType : '';
    const searchName: string = this.mgrFilter && this.mgrFilter.searchName ? this.mgrFilter.searchName : '';

    let params: IHomDictionary[] = [
      { key: 'providerUserId', value: providerUserId }
    ];

    if (locationIds) {
      params.push({ key: 'providerLocationIds', value: locationIds });
    }
    if (branchIds) {
      params.push({ key: 'branchIds', value: branchIds });
    }
    if (searchType && searchName) {
      params.push(
        { key: 'nameSearchType', value: searchType },
        { key: 'nameSearch', value: searchName }
      );
    }

    //When not viewing your conversations (so manager is filtering for an SE's conversations)
    //return subscribed to and unsubscribed to conversations.
    params.push({ key: 'allSubs', value: this.mgrFilter ? this.mgrFilter.onlyActiveSubs ? false : true : false });
    params.push({ key: 'excludeId', value: this.mgrFilter ? this.mgrFilter.excludeId : 0 });

    this.smsSub = this.domainObjectService.getByMethodParams('SmsContactViewModel', 'GetSmsContacts', params, listFilter)
      .subscribe((result: IResponseBase) => {
        if (result.success) {
          this.totalItems$.next(result.metaData ? this.getTotalItems(result.metaData) : 0 );
          this.itemsPerPage = result.metaData && result.metaData.pageMetaData ? result.metaData.pageMetaData.itemsPerPage : this.totalItems$.value;
          const existing: ISmsContactViewModel[] = this.contacts$.value;
          this.contacts$.next([...existing, ...result.data]);
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { console.log(error) }
      );
  }

  getTotalItems(metaData: any): number {
    return metaData.hasOwnProperty('totalItems') ? metaData['totalItems']
      : metaData.pageMetaData ? metaData.pageMetaData.totalItems : 0;
  }

  getProjectMins(): void {
    if (this.projSub) {
      this.projSub.unsubscribe();
    }

    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.currentPage = 1;
    this.errorData$.next([]);
    const methodName: string = this.activeContact.contactTypeId === 4 ? 'ProjectIdsByWorkCrew' : 'ProjectIdsByCustomer';
    this.projSub = this.domainObjectService.getByMethodById('Project', methodName, this.activeContact.contactId)
      .subscribe((result: IResponseBase) => {
        if (result.success) {
          this.projectMins$.next(result.data);
        } else {
          this.errorData$.next(result.errorData);
        }
      },
        (error: any) => { console.log(error) }
      );
  }

  changeContactSubscription(contactId: number, unsubscribe: boolean): void {
    const params: string = '?providerUserId=' + this.userPriviledgesService.currentUserId$.value.toString() + '&contactId=' + contactId.toString();
    const methodName: string = unsubscribe ? 'UnSubscribe' : 'ReSubscribe';
    this.working$.next(true);
    this.domainObjectService.updateByMethodParams('ConversationSubscription', methodName, params)
      .subscribe((result: IResponseBase) => {
        if (result.success) {
          this.refreshList(); //retains filter
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { console.log(error) }
      );
  }

  setFilterText(): void {
    if (!this.mgrFilter) {
      this.activeFilterText = '';
      return;
    }

    let displayFilterText: string = '';
    displayFilterText = displayFilterText + (this.mgrFilter.onlyActiveSubs ? ' Active' : ' All').concat(' Subscriptions');

    if (this.mgrFilter.providerLocations.length === this.providerLocations$.value.length) {
      displayFilterText = displayFilterText + ' for All Provider Locations ';
    } else {
      const locNames: string = this.mgrFilter.providerLocations.map(x => x.locationName).join(', ');
      let locationFilter: string = '';
      if (locNames) {
        locationFilter = ' for Provider Location '.concat(SearchType.Equals, ' ', locNames);
      }
      displayFilterText = displayFilterText + locationFilter;
    }

    if (this.mgrFilter.providerLocationBranches.length === this.branches$.value.length) {
      displayFilterText = displayFilterText + ' and All Branches ';
    } else {
      const branchNames: string = this.mgrFilter.providerLocationBranches.map(x => x.branchName).join(', ');
      let branchFilter: string = '';
      if (branchNames) {
        branchFilter = ' and Branch '.concat(SearchType.Equals, ' ', branchNames);
      }
      displayFilterText = displayFilterText + branchFilter;
    }

    let userFilter: string = '';
    if (this.mgrFilter.providerUser) {
      userFilter = ' and User '.concat(SearchType.Equals, ' ', this.mgrFilter.providerUser['entityLabel']);
    }
    displayFilterText = displayFilterText + userFilter;

    let nameFilter: string = '';
    if (this.mgrFilter.searchName) {
      nameFilter = ' and Contact Last Name '.concat(this.mgrFilter.searchType.searchType, ' ', this.mgrFilter.searchName);
    }
    displayFilterText = displayFilterText + nameFilter;

    if (this.mgrFilter.excludeId > 0) {
      displayFilterText = displayFilterText + ' Excluding '.concat(this.mgrFilter.excludeId == 1 ? 'Customers' : 'Installers');
    }

    this.activeFilterText = displayFilterText;
  }


  routeToEmpty(): void {
    this.router.navigate([{ outlets: { chat: ['sms-convo-empty'] } }], {
      relativeTo: this.activeRoute
    }).then(success => {
      this.router.navigate([{ outlets: { infobar: ['sms-contact-empty'] } }], {
        relativeTo: this.activeRoute
      });
    }).catch(e => {
      console.log('Route not found, route stopped with no error raised', e);
    });
  }

  routeToConvo(): void {
   this.router.navigate([{
      outlets: {
        chat: ['sms-convo', this.activeContact.contactId.toString(), this.activeContact.phoneNumber,
          this.activeContact.firstName.concat(' ', this.activeContact.lastName), this.activeContact.installerName, 'true']
      }
    }],
      { relativeTo: this.activeRoute })
     .then(success => {
       this.routeToContactInfo()
     })
      .catch(e => {
        console.log('Route not found, route stopped with no error raised', e);
      });
  }

  routeToContactInfo(): void {
   const routeName: string = this.activeContact.contactTypeId === 4 ? 'sms-installer-mgr' : 'sms-customer-mgr';
    this.router.navigate([{
      outlets: {
        infobar: [routeName, this.activeContact.contactId.toString()]
      }
    }],
      { relativeTo: this.activeRoute })
      .catch(e => {
        console.log('Route not found, route stopped with no error raised', e);
      });
  }

  setSize(): void {
    const myLeft: number = this.el.nativeElement.getBoundingClientRect().left;
    this.screenIsSmall$.next(this.screenService.isSmall(myLeft));
  }

}
