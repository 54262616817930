  /*
Custom Methods Used by Dynamic-List specific to app admin and its child entities.
Set in ICustomButton object within the list definition, row specific
*/
import { Injectable } from '@angular/core';
import { IProviderUser, IPrivilegeTemplatePrivilege, IDocumentType } from '../../../app/portals/view-models';
import { ISssGeneralContractorProgram, ISssProgramService, ISssBranchProgramService } from '../../../app/portals/view-models/index_two';

@Injectable()
export class AdminDynamicListService {

  constructor() { }

  handleCustomMethod(methodName: string, row: any, portalEntityId: number): any {
    switch (methodName) {

      case 'canSyncUser':
        return this.canSyncUser(row);
      case 'canViewUserSecurity':
        return true; //Created for Installer role, need to determine security changes. Set to true for now.  //this.canViewUserSecurity(row);
      case 'canViewPrivFilters':
        return this.canViewPrivFilters(row);
      case 'canViewTemplate':
        return this.canViewTemplate(row);
      case 'getGcExcludingProgramsCss':
        return this.getGcExcludingProgramsCss(row);
      case 'getPgmExcludingServicesCss':
        return this.getPgmExcludingServicesCss(row);
      case 'getBranchExcludingServicesCss':
        return this.getBranchExcludingServicesCss(row);
      default:
        return false;
    }
  }

/*CUSTOM  METHODS TO DETERMINE BUTTON ENABLED */
  canSyncUser(row: IProviderUser): boolean {
    const isExternalUser = row && row.hasOwnProperty('contact_contactId') && row.contact_contactId !== null;
    return row && row.hasOwnProperty('isLdapUser') && row.isLdapUser && !isExternalUser;
  }

  canViewUserSecurity(row: IProviderUser): boolean {
    const isExternalUser = row && row.hasOwnProperty('contact_contactId') && row.contact_contactId !== null;
    return !isExternalUser;
  }

  canViewPrivFilters(row: IPrivilegeTemplatePrivilege): boolean {
    return row.privilegeDefinitionType.toLowerCase() === 'execute' ? false : true;
  }

  canViewTemplate(row: IDocumentType): boolean {
    return row.fileUrl ? true : false;
  }

  getGcExcludingProgramsCss(row: ISssGeneralContractorProgram): string {
    if (!row) {
      return '';
    }
    return row.gcExcludingPrograms ? 'dynamic-list__item--danger' : '';
  }

  getPgmExcludingServicesCss(row: ISssProgramService): string {
    if (!row) {
      return '';
    }
    return row.gcExcludingPrograms || row.pgmExcludingServices ? 'dynamic-list__item--danger' : '';
  }

  getBranchExcludingServicesCss(row: ISssBranchProgramService): string {
    if (!row) {
      return '';
    }
    return row.gcExcludingPrograms || row.pgmExcludingServices || row.psExcludingBranches ? 'dynamic-list__item--danger' : '';
  }

}
