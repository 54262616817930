/* This store contains lists that are used by multiple components in the app */
import { Action } from '@ngrx/store';

import { IErrorData, IListMetaData } from '../../interfaces/index';
import { IListFilter } from '../../../fw/dynamic-list/interfaces/index';

export enum SelectionListActionTypes {
  GET_ENTITY_LIST = '[selectionLists] GET_ENTITY_LIST',
  GET_LOOKUP_LIST = '[selectionLists] GET_LOOKUP_LIST',
  GET_ENTITY_LIST_BY_ID = '[selectionLists] GET_ENTITY_LIST_BY_ID',
  GET_ENTITY_LIST_BY_METHODPARAMS = '[selectionLists] GET_ENTITY_LIST_BY_METHODPARAMS',
  INIT_SELECT_ITEMS = '[selectionLists] INIT_SELECT_ITEMS',
  SET_ENTITY_LIST = '[selectionLists] SET_ENTITY_LIST',
  SET_LOOKUP_LIST = '[selectionLists] SET_LOOKUP_LIST',
  RESET_ALL_SELECTION_LIST_STORES = '[selectionLists] RESET_ALL_SELECTION_LIST_STORES'
}

export class GetEntityList implements Action {
  readonly type = SelectionListActionTypes.GET_ENTITY_LIST;
  public payload: {
    entityName: string;
    listFilter: IListFilter;
    storeName: string;
  }
  constructor(entityName, listFilter, storeName = '') {
    this.payload = {
      entityName: entityName,
      listFilter: listFilter,
      storeName: storeName
    }
  }
}

export class GetEntityListById implements Action {
  readonly type = SelectionListActionTypes.GET_ENTITY_LIST_BY_ID;
  public payload: {
    entityName: string;
    methodName: string;
    id: number;
    listFilter: IListFilter;
    storeName: string;
  }
  constructor(entityName, methodName, id, listFilter = null, storeName = '') {
    this.payload = {
      entityName: entityName,
      methodName: methodName,
      id: id,
      listFilter: listFilter,
      storeName: storeName
    }
  }
}

export class GetEntityListByMethodParams implements Action {
  readonly type = SelectionListActionTypes.GET_ENTITY_LIST_BY_METHODPARAMS;
  public payload: { entityName: string, methodName: string, methodParams: string, listFilter: IListFilter, storeName: string; parentId: number }
  constructor(entityName, methodName, methodParams, listFilter = null, storeName = '', parentId = -1) {
    this.payload = {
      entityName: entityName,
      methodName: methodName,
      methodParams: methodParams,
      listFilter: listFilter,
      storeName: storeName,
      parentId: parentId
    }
  }
}

export class GetLookupList implements Action {
  readonly type = SelectionListActionTypes.GET_LOOKUP_LIST;
  constructor(
    public payload: { typeName: string, api: string }
  ) { }
}

export class SetEntityList implements Action {
  readonly type = SelectionListActionTypes.SET_ENTITY_LIST;
  constructor(
    public payload: { storeName: string, parentId: number, data: any[], listMetaData: IListMetaData, errorData: IErrorData[] }
  ) { }
}


export class InitSelectItems implements Action {
  readonly type = SelectionListActionTypes.INIT_SELECT_ITEMS;
  constructor(
    public payload: { storeName: string, parentId: number }
  ) { }
}


export class ResetAllSelectionListStores implements Action {
  readonly type = SelectionListActionTypes.RESET_ALL_SELECTION_LIST_STORES;
}


export type SelectionListsActions = GetEntityList |
  GetEntityListById |
  GetEntityListByMethodParams |
  GetLookupList |
  SetEntityList |
  InitSelectItems |
  ResetAllSelectionListStores;
