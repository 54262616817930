import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild, Inject, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IGenericStateData, } from '../../../../../fw/dynamic-list/interfaces';
import { IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { ISkuPrice, ISkuPriceUpdateModel } from '../../../view-models';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces';
import { PricingEvent } from '../../enums/pricing-shared.enums';

//store actions, reducers, interfaces
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { getSelectedRecord} from '../../../../../fw/dynamic-list/store/index';
import { CheckBoxComponent } from '../../../../../fw/fw-shared/components';

import { PricingSharedService } from '../../services/pricing-shared.service';
import { DomainObjectService } from '../../../../../shared/services';

interface IModifiedSkuPrice {
  skuPriceId: number,
  newPrice: number;
}

interface IBulkResponse {
  skuPriceId: number,
  errorText: string;
}

@Component({
  selector: 'sku-pricing-wizard',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sku-pricing-wizard.component.html'
})
export class SkuPricingWizardComponent implements OnInit, OnDestroy {
  @ViewChild('globalPriceEl') public globalPriceEl: ElementRef;
  @ViewChild('applyPrice') public applyPrice: CheckBoxComponent;
  
  skuId: number;
  generalContractorId: number;
  globalPrice: number = -1;
  defaultGlobalPrice: number = 0.00;
  globalApplied: boolean = false;
  public entityLabel: string = '';
  public selectedSkus: ISkuPrice[] = [];
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  modifiedPrices: IModifiedSkuPrice[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public selectionListStore: Store<fromSelectionLists.IStoreState>,
    public store: Store<fromFeature.IAllDynamicData>,
    public datePipe: DatePipe,
    public pricingService: PricingSharedService,
    public domainObjectService: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      const stateData: IGenericStateData = window.history.state;
      this.skuId = +paramMap.get('id');
      this.generalContractorId = +paramMap.get('gcId') || 1;
      this.initWorkingProperties();
      this.getDetail(stateData);
      this.initListDefinition();
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public updateSkuPrices(): void {
    if (!this.selectedSkus.length) {
      return;
    }
    const def = this.listDefinition$.getValue();
    let viewModel: ISkuPriceUpdateModel[] = [];

    this.selectedSkus.forEach((item, index) => {
      let itemPrice: number = -1;
      if (!this.globalApplied) {
        const modifiedItem: IModifiedSkuPrice = this.modifiedPrices.find(x => x.skuPriceId == item.skuPriceId);
        itemPrice = modifiedItem ? modifiedItem.newPrice : -1;
      }
      const newPrice = this.globalApplied ? this.globalPrice : itemPrice;

      if (newPrice > -1) {
        //Allows for a price of 0
        const updateObj: ISkuPriceUpdateModel = {
          skuPriceId: item.skuPriceId,
          skuPrice: newPrice
        };
        viewModel.push(updateObj);
      }
    });

    if (!viewModel.length) {
      return;
    }
    this.loading$.next(true);
    var model = { skuPrices: viewModel, skuId: this.skuId };
    this.domainObjectService.updateByMethod(def.controllerName, 'BulkUpdate', model)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          let result: IBulkResponse[] = response.data;
          const errorList: IBulkResponse[] = result.filter(x => x.errorText && x.errorText.length > 0);
          if (errorList.length > 0) {
            this.setErrorText(errorList);
          } else {
            this.initWorkingProperties();
            this.initListDefinition();
          }
        } else {
          this.errorData$.next(response.errorData);
        }
        this.loading$.next(false);
      });
  }

  public onListCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case PricingEvent.deckPriceChange:
        this.updateModifiedPriceList(event);  //event, not event.data
        break;
      default:
        break;
    }
  }

  public onListItemSelect(event: IHomEventEmitter) {
    switch (event.event) {
      case PricingEvent.selectSkuToChange:
        this.updateSelectedPrices(event);
        break;
      default:
        break;
    }
  }

  public onListPage(): void {
    this.initWorkingProperties();
  }

  public updateSelectedPrices(event: IHomEventEmitter): void {
    const vm: ISkuPrice = event.data;
    const exists: number = this.selectedSkus.findIndex(x => x.skuPriceId === vm.skuPriceId);
    if (event.action) {
      if (exists === -1) {
        this.selectedSkus.push(vm);
        if (this.globalApplied) {
          this.updateStoreById(vm.skuPriceId);
        }
      }
    } else {
      if (exists > -1) {
        this.selectedSkus.splice(exists, 1);
      }
    }
  }

  public updateModifiedPriceList(event: IHomEventEmitter): void {
    const row: ISkuPrice = event.data['row'];
    const newPrice: number = event.data['value'];
    const exists: number = this.modifiedPrices.findIndex(x => x.skuPriceId === row.skuPriceId);
    if (exists === -1) {
      this.modifiedPrices.push({ skuPriceId: row.skuPriceId, newPrice: newPrice });
    } else if (exists > -1) {
      this.modifiedPrices[exists].newPrice = newPrice;
    }
  }

  public applyGlobalPrice(): void {
    if (!this.globalApplied) {
      //local store update  
      this.updateStoreForAll();
    }
    this.globalApplied = true;
  }

  public onGlobalPriceChange(value: number): void {
    this.globalPrice = value;
    if (this.globalApplied) {
      this.updateStoreForAll();
    }
  }

  getDetail(stateData: IGenericStateData): void {
    this.store.pipe(
      select(getSelectedRecord(stateData.storeName, stateData.parentId, stateData.key, this.skuId)))
      .subscribe((entity) => {
        if (entity) {
          const data = cloneDeep(entity);
          this.entityLabel = data.entityLabel;
        }
      });
  }

  initWorkingProperties(): void {
    this.globalPrice = -1
    this.globalApplied = false;
    if (this.globalPriceEl) {
      let priceEl: HTMLInputElement = this.globalPriceEl.nativeElement;
      priceEl.value = this.defaultGlobalPrice.toString();
    }
    if (this.applyPrice) {
      let applEl: CheckBoxComponent = this.applyPrice;
      applEl.value = false;
    }

    this.selectedSkus = [];
    this.modifiedPrices = [];
  }

  initListDefinition() {
    this.listDefinition$.next(this.pricingService.loadSkuPricingWizardListDefinition(this.skuId));
  }

  updateStoreForAll(): void {
    this.selectedSkus.forEach(item => {
      this.updateStoreById(item.skuPriceId)
    });
  }

    //update the store (so just local store update not back end)
  updateStoreById(id: number): void {
    const def = this.listDefinition$.getValue();
    const keyData: IKey = { storeName: def.storeName, parentId: -1, key: def.rowKeyId, id: id };
    this.store.dispatch(new DynamicListActions.SetCalculatedProperty({ keyData: keyData, propertyName: 'effectiveDeckPrice', propertyValue: this.globalPrice }));
  }


  setErrorText(data: IBulkResponse[]): void {
    const def = this.listDefinition$.getValue();
    data.forEach((item: IBulkResponse) => {
        const keyData: IKey = { storeName: def.storeName, parentId: -1, key: def.rowKeyId, id: item.skuPriceId };
        this.store.dispatch(new DynamicListActions.SetCalculatedProperty({ keyData: keyData, propertyName: 'errorText', propertyValue: item.errorText }));
    });
  }
}

