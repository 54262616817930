import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IWorkOrder } from '../../../view-models';
import { IListDefinition,} from '../../../../../fw/dynamic-list/interfaces';
import { IContactProjectSelectEvent } from '../../interfaces';

import { ContactProjectService } from '../../services';

@Component({
  selector: 'contact-project-work-orders',
  templateUrl: './contact-project-work-orders.component.html'
})
export class ContactProjectWorkOrdersComponent {
  @Input() contactId: number;
  @Input() contactTypeId: number;
  @Input() projectId: number;

  @Output() selectEvent = new EventEmitter<IContactProjectSelectEvent>();

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectedWo: IWorkOrder = null;

  constructor(public contactProjectService: ContactProjectService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedWo = null;
    this.listDefinition$.next(this.contactProjectService.loadContactProjectWorkOrderListDefinition(this.contactId, this.contactTypeId, this.projectId));
  }

  public onSelect(event: IHomEventEmitter): void {
    this.selectedWo = event.data;
  }

  public onPage(event): void {
    this.selectedWo = null;
  }

  public confirmSelect(): void {
    if (this.selectedWo) {
      this.selectEvent.emit({
        projectId: this.selectedWo.project_projectId,
        projectStatus: this.selectedWo.hasOwnProperty('projectStatus') ? this.selectedWo['projectStatus'] : '',
        workOrderId: this.selectedWo.workOrderId,
        workOrderStatus: this.selectedWo.hasOwnProperty('workOrderStatusText') ? this.selectedWo.workOrderStatusText : '',
        purchaseOrderId: -1,
        purchaseOrderStatus: '',
        actionIds: []
      });
    }
  }

}
