<div *ngIf="listDefinition$ | async">
  <fw-dynamic-list [vmListDefinition]="listDefinition$ | async" (selectEvent)="onSelect($event)"></fw-dynamic-list>
  <div class="app-btn-box">
    <button class="app-btn app-btn--primary"
            type="button"
            (click)="receiveInventory()"
            [disabled]="!canIEdit || !selectedItems.length">
      Receive Selected
    </button>
  </div>
</div>

