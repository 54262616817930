/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';

import { UserDashboardService } from '../../services/user-dashboard.service';

@Component({
  selector: 'unassigned-external-notes',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './unassigned-external-notes.component.html'
})
export class UnassignedExternalNotesComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public userDashboardService: UserDashboardService) { }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.userDashboardService.loadUnassignedExternalNotesListDefinition());
    });
  }
}
