<div [ngClass]="{ 'loading-mask':  !(providerUsers$ | async) }">
  <div class="nested-panel">
    <fw-section-title title="Assign User" [underline]="true" icon="fa-file-signature"></fw-section-title>
    <form *ngIf="(providerUsers$ | async) as providerUsers" class="form-wrapper form-wrapper--edit" >
      <div class="float-label">
        <select #providers [ngClass]="{'has-error': !assignedId }" (change)="selectUser(providers.value)">
          <option selected value="0">{{myConstants.choose}}</option>
          <option *ngFor="let item of providerUsers" value="{{ item.providerUserId }}">{{ item.entityLabel }}</option>
        </select>
        <label>Provider Users</label>
      </div>
    </form>
    <fw-error-box [errorData]="errorData$ | async" [ignoreKey]="true"></fw-error-box>
    <div class="app-btn-box">
      <button type="button" class="app-btn app-btn--cancel" (click)="onCancel()">Cancel</button>
      <button type="button" class="app-btn app-btn--primary" [disabled]="!assignedId" (click)="onSave()">Save</button>
    </div>
  </div>
</div>
