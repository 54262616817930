 <div class="app-padding" [ngClass]="{ 'loading-mask': (working$ | async)}">
  <div class="float-label">
    <select #overrideType (change)="changeOverride(overrideType.value)" appFocus [autoFocus]="true">
      <option selected value="0">Choose...</option>
      <option *ngFor="let type of overrideTypes"
              [value]="type">
        {{type}}
      </option>
    </select>
    <label title="Override Type">Override Type</label>
  </div>

  <div class="float-label">
    <select #service
            [disabled]="!services.length || (overrideType.value !== serviceType)"
            (change)="changeService(service.value)">
      <option selected value="0">Choose...</option>
      <option *ngFor="let service of services"
              [value]="service.serviceId">
        {{service.serviceName}}
      </option>
    </select>
    <label title="Narrow by Service">Narrow by Service</label>
  </div>

  <div class="float-label">
    <select #programService
            [disabled]="!programServices.length || (overrideType.value !== psType)"
            (change)="changeProgramService(programService.value)">
      <option selected value="0">Choose...</option>
      <option *ngFor="let ps of programServices"
              [value]="ps.programServiceId">
        {{ps.entityLabel}}
      </option>
    </select>
    <label title="Narrow by Program Service">Narrow by Program Service</label>
  </div>

  <div class="float-label">
    <select #region
            [disabled]="!(regions$ | async).length || !(overrideType.value === psType || overrideType.value == regionType) || (overrideType.value === psType && selectedProgramServiceId <= 0)"
            (change)="changeRegion(region.value)">
      <option selected value="0">None...</option>
      <option *ngFor="let r of (regions$ | async)"
              [value]="r.regionId">
        {{r.regionName}}
      </option>
    </select>
    <label title="Narrow by Region">Narrow by Region</label>
  </div>

  <div *ngIf="overrideType.value === psType" class="float-label">
    <label title="Narrow by Branch">Narrow by Branch Program</label>
    <fw-multiselect-dropdown #branchPrograms [options]="multiSelectOptions"
                             [texts]="multiSelectLabels"
                             [settings]="multiSelectSettings"
                             [isDisabled]="!multiSelectOptions.length || (overrideType.value !== psType)  || (overrideType.value === psType && selectedProgramServiceId <= 0)"
                             (onAdded)="addBranchPgm($event)"
                             (onRemoved)="removeBranchPgm($event)">
    </fw-multiselect-dropdown>
  </div>

  <div *ngIf="overrideType.value !== psType" class="float-label">
    <select #branch
            [disabled]="!(branches$ | async).length || !(overrideType.value === regionType || overrideType.value == branchType)"
            (change)="changeBranch(branch.value)">
      <option selected value="0">None...</option>
      <option *ngFor="let b of (branches$ | async)"
              [value]="b.branchId">
        {{b.branchName}}
      </option>
    </select>
    <label title="Narrow by Branch">Narrow by Branch</label>
  </div>

  <div class="float-label">
    <input #skuPrice type="text" digitOnlyMask [decimal]="true"
           class="number-width"
           id="skuPrice"
           required
           [disabled]="!(isValid$ | async)"
           placeholder="0.00"
           (change)="priceChange(skuPrice.value)"/>
    <label title="Price">Price</label>
  </div>

  <div class="app-btn-box">
    <button type="button"
            class="app-btn app-btn--cancel"
            (click)="cancel()"
            title="Cancel">
      Cancel
    </button>
    <button class="app-btn app-btn--primary"
            type="button"
            (click)="create(close)"
            [disabled]="!(isValid$ | async)">
      Create and Close
    </button>
    <button class="app-btn app-btn--primary"
            type="button"
            (click)="create(next)"
            [disabled]="!(isValid$ | async)">
      Create and Next
    </button>
  </div>


  <fw-error-box [errorData]="errorData$ | async" [errors]="errors$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>
