import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { orderBy } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import {
  IMultiSelectOption,
  MultiSelectDropdown,
  MultiSelectTexts,
  MultiSelectSettings
} from '../../../../../fw/fw-shared/components/fw-multi-select-dropdown';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IErrorData } from '../../../../../shared/interfaces';

@Component({
  selector: 'related-entity-create',
  templateUrl: './related-entity-create.component.html'
})
export class RelatedEntityCreateComponent implements OnInit  {
  @Input() entityType: string;
  @Input() availableEntities: any[];
  @Input() errorData: IErrorData[];
  @Input() errors: string;

  @Output() public createEvent = new EventEmitter<any[]>();
  @Output() public cancelEvent = new EventEmitter();

  @ViewChild('entityMultiSelect') public entityMultiSelect: MultiSelectDropdown;
  public selectedEntities$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public multiSelectOptions: IMultiSelectOption[];
  public multiSelectLabels: MultiSelectTexts;
  public multiSelectSettings: MultiSelectSettings;
  public form: FormGroup;
  public operation: string;
  public showNext: boolean = false;

  constructor(@Inject(appConstants) public myConstants: IAppConstants) {
    this.operation = this.myConstants.operationTypeCreate;
  }

  ngOnInit() {
    this.form = new FormGroup({
      'entityMultiSelect': new FormControl()
    });
    this.initServiceMultiSelect();
  }

  public addEntity(id: number): void {
    let selectedEntities = this.selectedEntities$.value;
    const found: any = selectedEntities.find(x => x.value === id);
    const entity: any = this.availableEntities.find(x => x.value === id);
    if (!found && entity) {
      selectedEntities.push(entity);
      this.selectedEntities$.next( orderBy(selectedEntities, ['label'], ['asc']));
    }
  }

  public removeEntity(id: number): void {
    let selectedEntities = this.selectedEntities$.value;
    const index = selectedEntities.findIndex(x => x.value === id);
    if (index > -1) {
      selectedEntities.splice(index, 1);
      this.selectedEntities$.next(selectedEntities);
    }
  }

  public onCreate(): void {
    const selectedEntities = this.selectedEntities$.value;
    if (!selectedEntities || !selectedEntities.length) {
      this.onCancel();
    } else {
      this.createEvent.emit(selectedEntities);
    }
  }

  public onCancel(): void {
    this.cancelEvent.emit();
  }

  initServiceMultiSelect() {
    let multiSelectLabels = new MultiSelectTexts();
    multiSelectLabels.defaultTitle = 'Select...';
    let multiSelectSettings = new MultiSelectSettings(true, false, false, 1,
      this.availableEntities ? this.availableEntities.length : 0,
      this.availableEntities ? this.availableEntities.length : 0);
    multiSelectSettings.uncheckAllOnReload = true;
    multiSelectSettings.showPagingInfo = false;

    let multiSelectOptions: IMultiSelectOption[] = [];
    this.availableEntities.forEach((entity: any) => {
      multiSelectOptions.push({ id: entity.value, name: entity.label });
    });


    this.multiSelectLabels = multiSelectLabels;
    this.multiSelectSettings = multiSelectSettings;
    this.multiSelectOptions = multiSelectOptions;
    this.loading$.next(false);
  }

}
