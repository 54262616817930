import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../app/store/reducers/index';
import { getDatabaseName } from '../../auth/store/auth.selectors';
import { HomHubService, UserPriviledgesService } from '../../auth/services';

@Component({
    selector: 'fw-footer',
    templateUrl: './fw-footer.component.html'
})
export class FooterComponent implements OnInit  {

  constructor(public  homHubService: HomHubService,
    public userPriviledgesService: UserPriviledgesService,
    public rootStore: Store<fromRoot.IState>
  ) {}
  public dataBaseName$: Observable<string>;

  ngOnInit() {
    this.dataBaseName$ = this.rootStore.pipe(select(getDatabaseName));
  }
}
