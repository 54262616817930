  <div class="schedule__mask" *ngIf="moveUpVisible"></div>
  <div class="schedule {{ customCardCss }}" [ngClass]="{ 'schedule__modal': modalService.opened, 'schedule__mini': miniMe }">
    <div class="app-btn-box schedule__btns" *ngIf="showButtons">
      <button type="button" class="app-btn-icon app-btn-icon--primary"
              (click)="request(editSchedule)"
              [disabled]="!canIEdit"
              title="Edit Schedule">
        <i class="fas fa-pencil"></i>
      </button>
      <ng-template #moveUp>
        <div class="schedule__moveup">
          <schedule-moveup *ngIf="moveUpVisible" [schedule]="schedule"
                           [workOrderId]="schedule.workOrder_workOrderId"
                           [providerLocationId]="providerLocationId"
                           [canIEdit]="canIEdit"
                           (customEvent)="onCustom($event)">
          </schedule-moveup>
        </div>
      </ng-template>
      <button fw-popover
              [template]="moveUp"
              [showPopover]="moveUpVisible"
              type="button"
              class="app-btn-icon"
              [ngClass]="{'app-btn-icon--success': schedule.hasMoveUp, 'app-btn-icon--neutral': !schedule.hasMoveUp }"
              (click)="request(showMoveUp)"
              [disabled]="!canIEdit"
              title="Request Move Up for Schedule">
        <i class="fas fa-arrow-up"></i>
      </button>
      <button type="button" class="app-btn-icon app-btn-icon--danger"
              (click)="request(deleteSchedule)"
              [disabled]="!canIDelete"
              title="Delete Schedule">
        <i class="fas fa-trash"></i>
      </button>
    </div>
    <div class="schedule__date" [ngClass]="{ 'schedule__date__mini': miniMe }">
      <div class="schedule__date__icon">
        <span class="fad fa-calendar  {{ customIconCss }}" [ngClass]="{ 'schedule__date__icon--mini': miniMe }"></span>
      </div>
      <div class="schedule__date__val" [ngClass]="{ 'schedule__date__val__mini': miniMe }">
        <span *ngIf="!miniMe" class="schedule__date__val--mth">{{ schedule.scheduleStartDate | date:'MMM' }}</span><!--SEP-->
        <span class="schedule__date__val--day">{{ schedule.scheduleStartDate | date:'d' }}</span><!--16-->
      </div>
    </div>
    <div class="schedule__longdate" [ngClass]="{ 'schedule__longdate__mini': miniMe }">{{ schedule.scheduleStartDate | date:'EEEE'}}<br />{{ schedule.scheduleStartDate | date }}</div><!--Wed, September 16-->
    <div class="schedule__time-dur" [ngClass]="{ 'schedule__time-dur__mini': miniMe, 'schedule__time-dur__non-working': nonWorking }">
      <div *ngIf="!nonWorking" class="schedule__time-dur__time">
        <label class="schedule__time-dur__label" [title]="mds.getFieldDefinition('scheduleStartDate').placeholder">{{ mds.getFieldDefinition('scheduleStartDate').label }}</label>
        <div class="schedule__time-dur__val">{{ schedule.scheduleStartDate | date:'shortTime' }} </div>
      </div><!--8:00 AM-->
      <div class="schedule__time-dur__dur" [ngClass]="{ 'schedule__time-dur__dur__non-working': nonWorking }">
        <label class="schedule__time-dur__label" [title]="mds.getFieldDefinition('duration').placeholder">{{ mds.getFieldDefinition('duration').label }}</label>
        <div class="schedule__time-dur__val">{{ schedule.duration }} {{ schedule.durationType }}</div>
      </div>
    </div>
    <div class="schedule__installer" [ngClass]="{ 'schedule__installer__mini': miniMe }">
      <ng-container *ngIf="unavailableReason">
        <div class="schedule__installer--unavailable" [ngClass]="{ 'schedule__installer--unavailable--mini': miniMe }">{{ unavailableReason }}</div>
      </ng-container>
      <ng-container *ngIf="!unavailableReason">
        <div *ngIf="!miniMe" class="app-icon--primary schedule__installer--icon"><i class="fad fa-truck"></i></div>
        <div class="schedule__installer--name" [ngClass]="{ 'schedule__installer--name--mini': miniMe }">{{ schedule.installerCrewsName }}</div>
        <div class="schedule__installer--crew-lead"
             [ngClass]="{ 'schedule__installer--crew-lead--mini': miniMe, 'schedule__installer--crew-lead--none': schedule.contactId === 0 }">
          {{ schedule.installerWorkCrewLeadName }}
        </div>
        <div class="schedule__installer--contact-pref" [ngClass]="{ 'schedule__installer--contact-pref--mini': miniMe }">
          <fw-communication-preference [row]="schedule"
                                       [displayType]="communicationEventService.displayTypeIcon">
          </fw-communication-preference>
        </div>
      </ng-container>
    </div>
    <div class="schedule__sss" [ngClass]="{ 'schedule__sss__mini': miniMe }">{{ schedule.selfServiceState ? homCommonUtility.splitWordsByCase(schedule.selfServiceState) : '' }}</div>
  </div>
