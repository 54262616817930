import { Component, OnInit } from '@angular/core';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'customer-flags',
  templateUrl: './customer-flags.component.html'
})
export class CustomerFlagsComponent implements OnInit {

  listDefinition: IListDefinition;

  constructor(public adminProviderService: AdminProviderService) {
  }

  ngOnInit() {
    this.listDefinition = this.adminProviderService.loadCustomerFlagsListDefinition();
  }

}
