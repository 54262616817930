import { Injectable, Inject } from '@angular/core';

import { IListColumn, ListColumn, IListFilter, ListFilter, OrderTerm } from '../../../../fw/dynamic-list/interfaces/index';
import { IListDefinition } from '../../../../fw/dynamic-list/interfaces/index';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { IDashboardCount } from '../../portal-shared/interfaces';

import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { ChargeBacksEvent, ChargeBackStore } from '../enums/charge-back-dashboard.enums';
import { IAppConstants, appConstants } from '../../../../shared/constants';

@Injectable()
export class ChargeBackDashboardService {
  lastDashCounts: IDashboardCount[] = [];

  constructor(public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public loadDashChargeBackSummaryListDefinition(storeName: string): IListDefinition {
    const listColumns = this.loadDashChargeBackSummaryListColumns(storeName);
    const listObjectLabel = this.setTitleByType(storeName);
    const listObjectController = 'ChargeBack';
    const listStoreName = storeName;
    const detailRoutePath = '';
    const listRowKeyId = 'chargeBackId';

    const listFilter = this.setFilterByType(storeName);
    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      listFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'Index';
    listDefinition.rowButtons = [
      {
        title: 'Print Installer Charge Back Notice',
        icon: 'fas fa-print',
        cssName: 'app-btn-icon--print',
        enabled: true,
        enabledMethod: 'canPrintInstallerNotice',
        eventName: ChargeBacksEvent.printChargeBack
      },
      {
        title: 'Submit Charge Back / View Charge Back Submission',
        icon: 'fas fa-file-invoice-dollar',
        cssName: 'app-btn-icon--success',
        enabled: true,
        eventName: ChargeBacksEvent.submitChargeBack
      }
    ];
    listDefinition.rowStatus = {
      cssMethod: 'getChargeBackStatusCss',
      methodService: 'managerDashDynamicListService'
    };

    if (storeName !== ChargeBackStore.dashChargeBacks) {
      //restrict filtering to always include these terms
      listDefinition.requiredSearchTerms = listFilter.searchTerm;
      listDefinition.allowSaveFilter = false;
   }

    return listDefinition;
  }

  loadDashChargeBackSummaryListColumns(storeName: string): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('project');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('chargeBackId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('locations');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('installerName');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isInstallerActive');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    if (storeName !== ChargeBackStore.dashUnAckChargeBacks && storeName !== ChargeBackStore.dashAckUnSubmitChargeBacks) {
      colDef = new ListColumn('acknowledged');
      columnDefinitions.push(colDef);
    }

    if (storeName !== ChargeBackStore.dashAckUnSubmitChargeBacks) {
      colDef = new ListColumn('submitted');
      columnDefinitions.push(colDef);
    }

    colDef = new ListColumn('remainingBalance');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('amountTotal');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('lastInstallmentDue');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    if (storeName !== ChargeBackStore.dashOnHoldChargeBacks) {
      colDef = new ListColumn('chargeBackStatus');
      columnDefinitions.push(colDef);
    }

    colDef = new ListColumn('createDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public loadDashChargeBackInstallmentListDefinition(chargeBackId: number): IListDefinition {
    const listColumns = this.loadDashChargeBackInstallmentListColumns();
    const listObjectLabel = 'Charge Back Installments';
    const listObjectController = 'ChargeBackInstallment';
    const listStoreName = ChargeBackStore.dashChargeBackInstallments;
    const detailRoutePath = '';
    const listRowKeyId = 'chargeBackInstallmentId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('InstallmentDate')];


    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = chargeBackId;
    listDefinition.parentKey = 'chargeBackId';
    listDefinition.controllerMethod = 'ByChargeBack';
    listDefinition.methodParameters = chargeBackId.toString();

    //readonly, no crud buttons
    listDefinition.showFilter = false;

    return listDefinition;
  }

  loadDashChargeBackInstallmentListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef  = new ListColumn('installmentNumber');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('installmentAmount');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('installmentDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('installmentSendDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  setFilterByType(storeName: string): IListFilter {
    let listFilter: IListFilter = new ListFilter();

    switch (storeName) {
      case ChargeBackStore.dashChargeBacks:
        listFilter.searchTerm = [
          { term: 'chargeBackStatus', value: ['Apply', 'Hold'], searchType: SearchType.Equals, columnName: 'chargeBackStatus', fieldType: this.myConstants.dataTypeString },
          { term: 'submitted', value: false, searchType: SearchType.Equals, columnName: 'submitted', fieldType: this.myConstants.dataTypeBool }
        ];
        break;
      case ChargeBackStore.dashAckUnSubmitChargeBacks:
        listFilter.searchTerm = [
          { term: 'acknowledged', value: true, searchType: SearchType.Equals, columnName: 'acknowledged', fieldType: this.myConstants.dataTypeBool },
          { term: 'submitted', value: false, searchType: SearchType.Equals, columnName: 'submitted', fieldType: this.myConstants.dataTypeBool }
        ];
        break;
      case ChargeBackStore.dashOnHoldChargeBacks:
        listFilter.searchTerm = [
          { term: 'chargeBackStatus', value: ['Hold'], searchType: SearchType.Equals, columnName: 'chargeBackStatus', fieldType: this.myConstants.dataTypeString }
        ];
        break;
      case ChargeBackStore.dashUnAckChargeBacks:
        listFilter.searchTerm = [
          { term: 'chargeBackStatus', value: ['Apply', 'Hold'], searchType: SearchType.Equals, columnName: 'chargeBackStatus', fieldType: this.myConstants.dataTypeString },
          { term: 'acknowledged', value: false, searchType: SearchType.Equals, columnName: 'acknowledged', fieldType: this.myConstants.dataTypeBool },
        ];
        break;
      default:
        break;
    }

    listFilter.orderTerm = [new OrderTerm('createDate')];
    return listFilter;
  }

  setTitleByType(storeName: string): string {
    let title: string = 'Charge Back Summary';

    switch (storeName) {
      case ChargeBackStore.dashChargeBacks:
        title = '(Default) Unsubmitted Chargeback Summary';
        break;
      case ChargeBackStore.dashAckUnSubmitChargeBacks:
        title = 'Acknowledged Unsubmitted Chargeback Summary';
        break;
      case ChargeBackStore.dashOnHoldChargeBacks:
        title = 'Chargebacks On Hold Summary';
        break;
      case ChargeBackStore.dashUnAckChargeBacks:
        title = 'Unacknowledged Chargeback Summary';
        break;
      default:
        break;
    }

    return title;
  }
}
