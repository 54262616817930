<div class="dynamic-list__header__item" [ngClass]="{'dynamic-list__header__item--sortable': sortable}">
  <span class="dynamic-list__header__item__text"
        (click)="sortable && sortList()"
        [title]="col.fieldDefinition.placeholder ? col.fieldDefinition.placeholder : col.fieldDefinition.label"
        [ngClass]="{'dynamic-list__header__item__text--sortable': sortable}">{{col.fieldDefinition.label}}</span>
  <div *ngIf="sortable" class="dynamic-list__header__item__icons" (click)="sortable && sortList(sortable)">
    <i *ngIf="col.listColumn.slowSort" class="fad fa-turtle dynamic-list__header__item__icons--slow-sort" title="Slow running sort/filter"></i>
    <i *ngIf="(col.isSorted && sortedAsc)" class="fas fa-caret-down dynamic-list__header__item__icons--arrow" title="Sorted ascending"></i>
    <i *ngIf="(col.isSorted && !sortedAsc)" class="fas fa-caret-up dynamic-list__header__item__icons--arrow" title="Sorted descending"></i>
    <span *ngIf="(col.isSorted)" class="dynamic-list__header__item__icons--sort-order">{{col.sortOrder}}</span>
  </div>
</div>
