import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import {  map, take, filter } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IErrorData } from '../../../../../shared/interfaces';
import { IProject, IWorkOrder, IContact } from '../../../view-models';
import { IListDefinition, IListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { ScheduleEvent } from '../../enums/schedule.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IListObjectData, IDynamicListState } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { ProjectObjectStore } from '../../../project/enums/project.enums';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { IDynamicObject } from '../../../../../fw/dynamic-list/store/reducers/dynamic-object.reducer';
import {  getObjectDataByType, getObjectDataById } from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { CommunicationEventEvent } from "../../../portal-shared/enums/communication-event.enums";
import { ContactStore } from "../../../../contact/enums/contact.enums";
import { getMetaDataByType, IMetaDataState } from "../../../../../fw/dynamic-list/store/index";
import { IFieldDefinition } from "../../../../../fw/dynamic-forms";
import { IConversationRequest } from "../../../portal-shared/interfaces/i-conversation-request";

import { UserPriviledgesService } from '../../../../../auth/services';
import { ProjectService } from '../../../project/services';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { CommunicationEventService } from "../../../portal-shared/services/communication-event.service";
import { ContactUtilityService } from "../../../../contact/services";

@Component({
  selector: 'schedule-portal-manager',
  templateUrl: './schedule-portal-manager.component.html'
})
export class SchedulePortalManagerComponent implements OnInit, OnDestroy {
  public title: string;
  public preferredContactLabel: string;
  public project$: BehaviorSubject<IProject> = new BehaviorSubject(null);
  public contact$: BehaviorSubject<IContact> = new BehaviorSubject(null);
  public workOrder$: BehaviorSubject<IWorkOrder> = new BehaviorSubject(null);
  public loading$: Observable<boolean>;
  public errorData$: Observable<IErrorData[]>;
  public canIEdit$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  workOrderId: number;
  scheduleId: number;
  operation: string;
  projectId: number;
  storeName: string;
  parentId: number;
  isWarranty: boolean = false;
  noteListDef: IListDefinition;
  updateSuccessful: boolean = false;
  syncVisible: boolean = false;
  subscription = new Subscription();

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public modalService: ModalService,
    public projectService: ProjectService,
    public userPriviledgesService: UserPriviledgesService,
    public communicationEventService: CommunicationEventService,
    public emitterService: HomEventEmitterService,
    public contactUtilityService: ContactUtilityService,
    @Inject(appConstants) public myConstants: IAppConstants ) { }

  public togglePoSync(): void {
    if (!this.syncVisible) {
      this.router.navigate([{ outlets: { sidebar: ['wo-po-sync', this.projectId] } }], {
        relativeTo: this.activeRoute
      });
    } else {
      this.router.navigate([{ outlets: { sidebar: null } }], {
        relativeTo: this.activeRoute
      });
    }
    this.syncVisible = !this.syncVisible;
  }

  public onCustom(eventIn: IHomEventEmitter) {
    let event: IHomEventEmitter = cloneDeep(eventIn);
    if (!event) {
      console.log('DEV ERR:  no event on custom Event call');
      return;
    }

    switch (event.event) {
      case ScheduleEvent.updateSuccessful:
      case ScheduleEvent.deleteSuccessful:
        this.updateSuccessful = true;
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.workOrderId = +paramMap.get("id");
      this.scheduleId = +paramMap.get("scheduleId");
      this.projectId = +paramMap.get('projectId');
      this.operation = paramMap.get("operation");
      this.storeName = paramMap.get("storeName");
      this.parentId = +paramMap.get('parentId');

      this.title = 'Scheduler for Work Order '.concat(this.workOrderId.toString());
      this.updateSuccessful = false;
      this.newRequest();
    });

    this.subscription.add(this.project$.subscribe((p: IProject) => {
      if (p) {
        this.getContact(p.customer_contactId);
      }
    }));

    this.subscription.add(this.emitterService.smsEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e.event === CommunicationEventEvent.openConversation) {
          this.showConvo(e.data);
        }
      }));
  }

  ngOnDestroy(): void {
    this.closeConversation();
    this.subscription.unsubscribe();
    if (this.updateSuccessful && this.storeName) {
      //reload parent list
      this.store.pipe(select(getListByType(this.storeName)))
        .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.parentId)), take(1))
        .subscribe((state: IListObjectData) => {
          if (state) {
            const def: IListDefinition = cloneDeep(state.listDefinition);
            const filter: IListFilter = cloneDeep(state.listFilter);
            this.store.dispatch(new fromStore.GetList({ listDefinition: def, listFilter: filter, parentId: def.parentId }));
          }
        })
      .unsubscribe();
    }
  }

  newRequest() {
    this.noteListDef = this.projectService.loadProjectNoteListDefinition(this.projectId, this.canIEdit$.getValue());

    this.subscription.add(this.store.pipe(select(getObjectDataByType(ProjectObjectStore.projectInformation))) 
      .subscribe((store: IDynamicObject) => {
        const objData = cloneDeep(store.objData.find(x => x.objectId == this.projectId));
        if (objData) {
          this.project$.next(objData.data);
        }
      }
    ));

    this.subscription.add(this.store.pipe(select(getObjectDataByType(ProjectObjectStore.workOrderInformation))) 
      .subscribe((store: IDynamicObject) => {
        const objData = cloneDeep(store.objData.find(x => x.objectId == this.workOrderId));
        if (objData) {
          this.workOrder$.next(objData.data);
          this.canIEdit$.next(this.userPriviledgesService.canIEdit(objData.data));
        }
      }
      ));
  }

  getContact(contactId: number): void {
    let requested: boolean = false;
    this.subscription.add(this.store.pipe(select(getObjectDataById(ContactStore.contactInformation, contactId)))
      .subscribe((data: IContact) => {
        if (!data && !requested) {
          this.contactUtilityService.dispatchGetContact(contactId);
        } else {
          this.contact$.next(data);
        }
      }));

    this.subscription.add(this.store.pipe(
      select(getMetaDataByType(ContactStore.contactInformation)),
      filter((data: IMetaDataState) => data.fieldDefinitions.length > 0))
      .subscribe((data: IMetaDataState) => {
        if (data) {
          const lblDef: IFieldDefinition = data.fieldDefinitions.find(x => x.key == 'communicationPreferenceValue');
          this.preferredContactLabel = lblDef ? lblDef.label : 'Contact Method';
        }
      }));
  }

  showConvo(request: IConversationRequest): void {
    this.router.navigate([{ outlets: { chatmodal: ['sms-convo', request.contactId.toString(), request.phone, request.name, request.parentName] } }], {
      relativeTo: this.activeRoute
    }).catch(e => {
      console.log('Route not found, route stopped with no error raised', request);
    });

  }

  closeConversation(): void {
    if (this.router.url.includes('chatmodal:')) {
      this.router.navigate([{ outlets: { chatmodal: null } }],
        { relativeTo: this.activeRoute.parent });
    }
  }

}
