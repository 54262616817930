<div class="component-title" [ngClass]="{ 'component-title__high-level-summary': forHighLevelSummary }">
  <span class="component-title__object-action" [title]="title | titlecase">{{ title | titlecase }}</span>
  <span class="component-title__spacer" *ngIf="title && entityLabel">-</span>
  <span class="component-title__object-name" *ngIf="entityLabel" [style.color]="entityColor" [title]="entityLabel">{{ entityLabel }}</span>
  <span class="component-title__object-status" *ngIf="entityStatus">({{ entityStatus }})</span>
  <span class="component-title__object-filter_name" *ngIf="appliedFilterName">({{ appliedFilterName }})</span>
  <span class="component-title__object-filter_dropdown" *ngIf="userFilters.length > 0 && !(childVisible)">
    <button type="button"
            class="app-btn-icon app-btn-icon--neutral"
            title="Saved Filters"
            fw-popover
            [template]="userFilterList"
            [closeOnClickOutside]="true"
            [showPopover]="showUserFilterPopover"
            [disabled]="false"
            (click)="showOrHidePopover(true)"
            (onHide)="showOrHidePopover(false)">
      <span class="fad fa-filters"></span>
    </button>
  </span>
  <div *ngIf="infoText" class="component-title__object-info">
    <fw-info [infoTitle]="'Helper'">
      <div class="component-title__object-info--info" [innerHTML]="infoText"></div>
    </fw-info>
  </div>

  <ng-template #userFilterList>
    <div class="user-filter-select__popover">
      <fw-user-filter-select
                             [userFilters]="userFilters"
                             (changeMyFilter)="changeUserFilter($event)">
      </fw-user-filter-select>
    </div>
  </ng-template>

  <ng-container *ngIf="collapsible">
    <button type="button"
            class="app-btn-icon app-btn-icon--primary component-title__toggle"
            [title]="isCollapsed ? 'Show Summary' : 'Show Detail'"
            (click)="toggle()">
      <i *ngIf="!isCollapsed" class="fas fa-chevron-down"></i>
      <i *ngIf="isCollapsed" class="fas fa-chevron-right"></i>
    </button>
  </ng-container>
</div>
