/** Imports for Angular modules */
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

/**
 * The HOM Utilities Service provides standard utilities which are used
 * throughout the application.
 */
@Injectable()
export class HomCommonUtility {
  constructor(public datePipe: DatePipe) {}

    /**
     * Identifies whether the supplied value is undefined
     * @param val
     */
    isUndefined(val: any): boolean {

        // Return a boolean indicating whether the value is null or a null-value string.
        return val === null || val === '' || val === "null";
    }

    /**
     * Identifies whether the supplied value is a date
     * @param val
     */
    isDate(val: any): boolean {

        // Check the type of the value and return a boolean indicating whether it is a date.
        var regExp = /\/Date\(-?\d+\)\//gi;
        return Object.prototype.toString.call(val) === '[object Date]' || regExp.test(val);
  }

  isValidDate(val: any): boolean {
    if (!(/^\d{2}\/\d{2}\/\d{4}$/).test(val)) return false;
    const parts: string[] = val.split("/"),
      month = parts[0].split("")[0] === "0" ? Number(parts[0].split("")[1]) : Number(parts[0]),
      days = parts[1].split("")[0] === "0" ? Number(parts[1].split("")[1]) : Number(parts[1]);
    if (month > 12 || days > 31) return false;
    return days <= new Date(Number(parts[2]), month, 0).getDate();
  }



    /**
     * Identifies whether the supplied value is a javascript object
     * @param val
     */
    isObject(val: any): boolean {

        // Check the type of the value and return a boolean indicating whether it is an object.
        return (typeof val === 'object' && val != null) ||
               (Object.prototype.toString.call(val) === '[object Object]');
    }

    /**
     * Identifies whether the supplied value is a number
     * @param val
     */
    isNumber(val: any): boolean {

        // Check the type of the value and return a boolean indicating whether it is a number.
      return !isNaN(val);
    }

    /**
     * Accepts a value, likely a string, and attempts to convert it into a Javascript date
     * @param val
     * @param allowNull
     */
    toJavascriptDate(val, allowNull: boolean = true) {
        
        // If null values are allowed, and the value passed is null, return the current date.
        if (!val) return allowNull ? null : new Date();

        // If supplied value is already a number or a date, return it unmodified.
        if (this.isNumber(val) || (this.isDate(val) && typeof val != 'string'))
            return val;
        
        // Create a placeholder for the formatted value.
        var returnDate;
        
        // Using regular expressions, attempt to convert the value from a .NET date value
        // to a javascript date.
        if (val.match(/\/Date\(-?\d+\)\//gi)) {
            returnDate = new Date(+val.replace(/\/Date\((\d+)\)\//, '$1'));
            if (!this.isDate(returnDate)) return val;
        };

        // If returnDate is undefined, the previous conversion attempt failed.
        // Try the conversion assuming the value is a SQL Server date.
        if (returnDate === undefined) {
            if (val.match(/(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})(\.\d{3})?/gi)) {
                returnDate = new Date(val.replace(/(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})(\.\d{3})?/, '$1T$2'));
            };
        };

        // If returnDate is undefined, the previous conversion attempt failed.
        // Try a plain javascript conversion.
        if (returnDate === undefined) { 
            returnDate = new Date(val);
            if (!this.isDate(returnDate)) return val;
        };
        
        // If returnData is not a number, all the previous conversion attempts failed.
        // Return null (or the current date if nulls aren't allowed).
        if (isNaN(returnDate)) return allowNull ? null : new Date();

        // Otherwise, one of the conversion attempts was successful.  Return the converted date.
        else return returnDate;
    }

    /**
     * Accepts a date-time value and formats it for display
     * @param value
     * @param allowNull
     */
    dateTimeDisplay(val, allowNull: boolean = true) {

        // Attempt to convert the supplied value to a Javascript readable date, and return NULL
        // if the conversion fails (or if the original value is null) and null values are allowed.
        var thisDate = this.toJavascriptDate(val, allowNull);
        if (thisDate === null) return null;

        // If the converted parameter isn't a date, just return it essentially unmodified.
        if (!this.isDate(thisDate))
            return thisDate;

        // Format the date and time portions separately and return their values concatenated.
        var dateDisplay = this.dateDisplay(val);
        if (dateDisplay == 'None') return 'N/A';
        return dateDisplay + ' ' + this.timeDisplay(val);
    }

    /**
     * Accepts a date value and formats it for display
     * @param val
     * @param allowNull
     */
    dateDisplay(val, allowNull: boolean = true) {
        
        // Attempt to convert the supplied value to a Javascript readable date, and return NULL
        // if the conversion fails (or if the original value is null) and null values are allowed.
        var thisDate = this.toJavascriptDate(val, allowNull);
        if (thisDate === null) return null;

        // If the converted parameter isn't a date type, just return it essentially unmodified.
        if (!this.isDate(thisDate) || thisDate == undefined)
            return thisDate;

        // Extract each of the individual date components.
        var month = thisDate.getMonth() + 1;
        var day = thisDate.getDate();
        var year = thisDate.getFullYear();

        // If the provided date is more than 100 years from today, it's likely set to an end-of-time value.
        // Javascript may have trouble handling such a date, so just return "N/A".
        if (year > new Date().addMonths(1200).getFullYear()) return 'N/A';

        // Concatenate the date components into human readable format and return.
        return month + '/' + (day < 10 ? '0' + day : day) + '/' + year;
    };

    /**
     * Accepts a time value and formats it for display
     * @param val
     * @param allowNull
     */
    timeDisplay(val, allowNull: boolean = true) {

        // Attempt to convert the supplied value to a Javascript readable date, and return NULL
        // if the conversion fails (or if the original value is null) and null values are allowed.
        var thisDate = this.toJavascriptDate(val, allowNull);
        if (thisDate === null) return null;

        // If the converted parameter isn't a date type, just return it essentially unmodified.
        if (!this.isDate(thisDate) || thisDate == undefined)
            return thisDate;

        // Extract each of the individual time components.
        var hours = thisDate.getHours();
        var hourDisplay = (hours < 13 ? hours : (hours - 12));
        var minutes = thisDate.getMinutes();
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        // Concatenate the time components into human readable format and return.
        return (hourDisplay === 0 ? '12' : hourDisplay) + ':' + minutes + ' ' + (hours < 12 ? 'AM' : 'PM');
    };

    /**
     * Accepts an object or array and returns a deep copy of that object or array
     * @param arr
     */
    deepCopy(obj: any): any {

        // Create a copy of the supplied value (in case it isn't actually an object).
        var newObj = obj;

        // If the supplied value -is- an object...
        if (obj && typeof obj === "object") {

            // Reset the copy to the appropriate empty type
            newObj = Object.prototype.toString.call(obj) === "[object Array]" ? [] : {};

            // Create deep copies of each of the object's property's (or array's items)
            // TODO does this need to differentiate between object or array?
            for (var i in obj) {
                newObj[i] = this.deepCopy(obj[i]);
            }
        }

        // Return the copied object.
        return newObj;
    }

    /**
     * Accepts a byte array and returns the array converted into a hex string
     * @param byteArray The byte array to be converted
     */
    toHexString(byteArray) {

        return byteArray.map(byte => ('0' + (byte & 0xFF).toString(16)).slice(-2)).join('');
  }

  splitWordsByCase(word: string = ''): string {
    if (!word) {
      return '';
    }
    let spaceIndx = -1;
    let splitWord = '';
    for (var i = 0; i < word.length; i++) {
      if (word[i] === ' ') {
        spaceIndx = i;
      }

      let spacer: string = word[i] !== ' ' && (i - 1 !== spaceIndx) && word[i] === word[i].toUpperCase() && i > 0 ? ' ' : '';
      splitWord = splitWord.concat(spacer, word[i]);
    }
    return splitWord;
  }

  //Date utilities 
  //granular so can use outside of getDateSearchValue method 
  getStartOfMonth(dateValue: string): Date {
    let selectedDate: Date = new Date(dateValue);
    return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
  }

  getStartOfWeek(dateValue: string): Date {
    let selectedDate: Date = new Date(dateValue);
    const dayInWeek = this.getDayInWeek(dateValue);
    let startDate = selectedDate.addDays(-(dayInWeek - 1));
    return startDate;
  }

  getEndOfWeek(dateValue: string): Date {
    let weekStart = this.getStartOfWeek(dateValue);
    return new Date(weekStart.addDays(6));
  }

  getWeeksInMonth(dateValue: string): number {
    let selectedDate = new Date(dateValue);
    let lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
    return +this.datePipe.transform(lastDay, 'W');
  }

  getWeekInMonth(dateValue: string): number {
    let selectedDate = new Date(dateValue);
    return +this.datePipe.transform(selectedDate, 'W');
  }

  getDayInWeek(dateValue: string): number {
    let selectedDate = new Date(dateValue);
    const weekDay = this.datePipe.transform(selectedDate, 'EEEE');
    let dayInWeek: number = 1;

    switch (weekDay) {
      case 'Sunday':
        dayInWeek = 1;
        break;
      case 'Monday':
        dayInWeek = 2;
        break;
      case 'Tuesday':
        dayInWeek = 3;
        break;
      case 'Wednesday':
        dayInWeek = 4;
        break;
      case 'Thursday':
        dayInWeek = 5;
        break;
      case 'Friday':
        dayInWeek = 6;
        break;
      case 'Saturday':
        dayInWeek = 7;
        break;
      default:
        break;
    }
    return dayInWeek;
  }


  markGroupDirty(formGroup: FormGroup) {
    if (!formGroup || !formGroup.controls) {
      return;
    }
    Object.keys(formGroup.controls).forEach(key => {
      switch (formGroup.get(key).constructor.name) {
        case "FormGroup":
          this.markGroupDirty(formGroup.get(key) as FormGroup);
          break;
        case "FormArray":
          this.markArrayDirty(formGroup.get(key) as FormArray);
          break;
        case "FormControl":
          this.markControlDirty(formGroup.get(key) as FormControl);
          break;
      }
    });
  }
  markArrayDirty(formArray: FormArray) {
    formArray.controls.forEach(control => {
      switch (control.constructor.name) {
        case "FormGroup":
          this.markGroupDirty(control as FormGroup);
          break;
        case "FormArray":
          this.markArrayDirty(control as FormArray);
          break;
        case "FormControl":
          this.markControlDirty(control as FormControl);
          break;
      }
    });
  }

  markControlDirty(formControl: FormControl) {
    formControl.markAsDirty();
  }

}


/*----- Native Javascript Object Extenders -----*/

// Extend the native object definitions with the necessary properties/values.
declare global {
    interface Date {
        addMonths(days: number): Date;
        addDays(days): Date;
        addHours(days: number): Date;
        addMinutes(days: number): Date;
    }
}

/**
 * Adds the supplied number of months to a date
 * @param months
 * @returns {} 
 */
Date.prototype.addMonths = function (months) {

    // Capture the date to be modified, add the supplied number of months, and return it.
    var copiedDate = new Date(this.getTime());
    copiedDate.setMonth(copiedDate.getMonth() + months);
    return copiedDate;
};

/**
 * Adds the supplied number of days to a date
 * @param days 
 * @returns {} 
 */
Date.prototype.addDays = function (days) {

    // Capture the date to be modified, add the supplied number of days, and return it.
    var copiedDate = new Date(this.getTime());
    copiedDate.setDate(copiedDate.getDate() + parseInt(days));
    return copiedDate;
};

/**
 * Adds the supplied number of hours to a date
 * @param hours 
 * @returns {} 
 */
Date.prototype.addHours = function (hours) {

    // Capture the date to be modified, add the supplied number of hours, and return it.
    var copiedDate = new Date(this.getTime());
    copiedDate.setHours(copiedDate.getHours() + hours);
    return copiedDate;
};

/**
 * Adds the supplied number of minutes to a date
 * @param minutes 
 * @returns {} 
 */
Date.prototype.addMinutes = function (minutes) {

    // Capture the date to be modified, add the supplied number of minutes, and return it.
    var copiedDate = new Date(this.getTime());
    copiedDate.setMinutes(copiedDate.getMinutes() + minutes);
    return copiedDate;
};
