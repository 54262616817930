<div *ngIf="chargeBackId" class="portal">
  <div class="portal__detail--item">
    <fw-dynamic-detail-custom-container *ngIf="chargeBack"
                                     [title]="title"
                                     [entityLabel]="entityLabel"
                                     [entityStatus]="entityStatus$ | async"
                                     [storeName]="storeName"
                                     [requestor]="requestor"
                                     [displayFields]="displayFields"
                                     [canIEdit]="canIEditCb$ | async"
                                     [parentId]="projectId"
                                     [storeKeyName]="storeKeyName"
                                     [objectId]="chargeBackId"
                                     [showEdit]="true"
                                     [showDelete]="false"
                                     [showRefresh]="true"
                                     [printUrl]="printUrl"
                                     (customEvent)="onCustom($event)">
    </fw-dynamic-detail-custom-container>
  </div>
  <div class="portal__detail--item">
    <fw-component-title [title]="'Charge Back Line Items'"></fw-component-title>
    <project-charge-back-items [chargeBackId]="chargeBackId"
                               [canIEdit]="canIEdit$ | async">
    </project-charge-back-items>
  </div>
</div>
