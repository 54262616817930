import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as fromMetaData from '../../../../../fw/dynamic-list/store/selectors/meta-data.selectors';
import * as MetaDataActions from '../../../../../fw/dynamic-list/store/actions/meta-data.actions';
import * as SelectionListActions from '../../../../../shared/store/selectionLists/selectionLists.actions';

import { IListFilter } from '../../../../../fw/dynamic-list/interfaces';
import { IGeneralContractor, IBranch, IContactInfoViewModel, IBranchProgram } from '../../../view-models';
import { AccessLevel } from '../../../../../fw/dynamic-list/enums/access-level.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IResponseBase, IErrorData } from '../../../../../shared/interfaces/index';
import { PurchaseOrder, IPurchaseOrder, IPoDefaults } from '../../../view-models/index';
import { ProjectObjectStore } from '../../../project/enums/project.enums';
import { ISelectListData } from '../../../../../shared/store/selectionLists/index';
import { ContactEvent, ContactEventAction } from '../../../../contact/enums/contact.enums';

import { ContactManagerComponent } from '../../../../contact/containers';

import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services';
import { DomainObjectService } from '../../../../../shared/services/index';
import { ProjectService } from '../../../project/services';
import { ContactConstantsService } from '../../../../contact/services';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'purchase-order-creation-wizard',
  templateUrl: './purchase-order-creation-wizard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MetaDataService]
})

export class PurchaseOrderCreationWizardComponent implements OnInit, OnDestroy {

  constructor(
    public activeRoute: ActivatedRoute,
    public modalService: ModalService,
    public projectMds: MetaDataService,
    public metaDataStore: Store<fromStore.IMetaDataState>,
    public dos: DomainObjectService,
    public selectionListStore: Store<fromSelectionLists.IStoreState>,
    public projectService: ProjectService,
    public contactConstantsService: ContactConstantsService,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public gc$: BehaviorSubject<IGeneralContractor> = new BehaviorSubject(null);
  public branch: IBranch = null;
  public program: IBranchProgram = null;
  public poNumber: string = null;
  public poDisplayFields: string[] = ['branchProgram', 'purchaseOrderNumber'];
  public displayFields: string[] = ['generalContractorId', 'branch', 'programs'];
  public operation: string = this.myConstants.operationTypeCreate;
  public storeName: string = ProjectObjectStore.projectInformation;
  public generalContractors: IGeneralContractor[];
  public allBranches: IBranch[];
  public programs: IBranchProgram[];
  public branches: IBranch[];
  public step: number = 1;
  public projectId: number = -1;
  public purchaseOrderId: number;
  public purchaseOrderNumber: string;
  public existingContactId: number;
  public listFilter: IListFilter = {
    isLookup: false,
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true, //do not want paged data
    currentPage: 1,
    searchTerm: [],
    orderTerm: null
  };
  public providerUserId = this.ups.currentUserId$.value;

  subscription: Subscription = new Subscription();
  bpSub: Subscription;
  public poDefaults$: BehaviorSubject<IPoDefaults> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public dataLoaded$: BehaviorSubject<{ projectMetaDataLoaded: boolean, purchaseOrderMetaDataLoaded: boolean, gcsLoaded: boolean, branchesLoaded: boolean }> = new BehaviorSubject({ projectMetaDataLoaded: false, purchaseOrderMetaDataLoaded: false, gcsLoaded: false, branchesLoaded: false });
  public branchPrograms$: BehaviorSubject<IBranchProgram[]> = new BehaviorSubject(null);
  public form: FormGroup;

  @ViewChild('contactManager') public contactManager: ContactManagerComponent;

  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
        this.projectId = -1;
        this.getData();
        this.listenForChanges();
      }));
  }

  public onManagerEvent(event: IHomEventEmitter): void {
    switch (event.event) {
      case ContactEvent.selectMatch:
        this.matchEvent(event);
      default:
    }
  }

  public cancel(): void {
    this.modalService.close();
  }

  public reset(): void {
    this.step = 1;
    this.gc$.next(null);
    this.branch = null;
    this.program = null;
    this.poNumber = null;
    this.existingContactId = null;
    this.poDefaults$.next(null);
    this.form.reset();
  }

  public next(): void {
    this.step = 2;
  }

  public previous(): void {
    this.step = 1;
  }

  public changeEvent(obj: IValueChanged): void {
    if (obj.key === 'purchaseOrderNumber') {
      this.poNumber = (!isNaN(obj.value) && obj.value.length === this.poDefaults$.value.poNumLength ? obj.value : null);
    } else {
      this.program = obj.value;
    }
  }

  public goToPo(): void {
    this.modalService.close();
    this.projectService.openProjectPurchaseOrderTab(this.projectId, this.purchaseOrderId, this.purchaseOrderNumber, 'create-purchase-order');
  }

  public create(): void {
    this.loading$.next(true);
    const contactModel: IContactInfoViewModel = this.contactManager.getContactModel();
    if (this.existingContactId) {
        this.createPo(this.existingContactId);
    } else {
      this.errorData$.next(null);
      this.dos.createByMethod('Contact', 'Create', contactModel).subscribe((response: IResponseBase) => {
        if (response.success) {
          this.createPo(response.data.contactId);
        } else {
          this.errorData$.next(response.errorData);
        }
      });
    }
  }

  initForm() {
    this.projectMds.setFieldDefinitions(this.storeName);
    this.form = this.projectMds.loadDynamicFormGroup(this.displayFields, null, this.operation);
  }

  getData(): void {
    this.loading$.next(true);
    // GET GC SELECTION LIST DATA
    this.subscription.add(this.selectionListStore.pipe(select(fromSelectionLists.getSelectionListDataByType('generalContractor', -1)))
      .subscribe((data: IGeneralContractor[]) => {
        if (!data) {
          this.selectionListStore.dispatch(new SelectionListActions.GetEntityList('GeneralContractor', this.listFilter, 'generalContractor'));
        } else {
          this.generalContractors = data;
          const dataLoaded = cloneDeep(this.dataLoaded$.value);
          dataLoaded.gcsLoaded = true;
          this.dataLoaded$.next(dataLoaded);
        }
      }));

    // GET BRANCH SELECTION LIST DATA
    this.subscription.add(this.selectionListStore.pipe(select(fromSelectionLists.getSelectionListDataByType('branch')))
      .subscribe((data: any[]) => {
        if (!data) {
          this.selectionListStore.dispatch(new SelectionListActions.GetEntityList('Branch', this.listFilter, 'branch'));
        } else {
          this.allBranches = data;
          const dataLoaded = cloneDeep(this.dataLoaded$.value);
          dataLoaded.branchesLoaded = true;
          this.dataLoaded$.next(dataLoaded);
        }
      }));

    // GET PROJECTINFORMATION METADATA
    this.subscription.add(this.metaDataStore.pipe(select(fromMetaData.getMetaDataByType(this.storeName)))
      .subscribe((data) => {
        if (data && data.fieldDefinitions.length) {
          const dataLoaded = cloneDeep(this.dataLoaded$.value);
          dataLoaded.projectMetaDataLoaded = true;
          this.dataLoaded$.next(dataLoaded);
        } else {
          this.metaDataStore.dispatch(new MetaDataActions.GetMetaData({ storeName: this.storeName, url: 'Project/GetMetaData', setListMetaData: false }));
        }
      }));

    // GET PURCHASEORDER METADATA
    this.subscription.add(this.metaDataStore.pipe(select(fromMetaData.getMetaDataByType('projectPurchaseOrders')))
      .subscribe((data) => {
        if (data && data.fieldDefinitions.length) {
          const dataLoaded = cloneDeep(this.dataLoaded$.value);
          dataLoaded.purchaseOrderMetaDataLoaded = true;
          this.dataLoaded$.next(dataLoaded);
        } else {
          this.metaDataStore.dispatch(new MetaDataActions.GetMetaData({ storeName: 'projectPurchaseOrders', url: 'PurchaseOrder/GetMetaData', setListMetaData: false }));
        }
      }));

    // LISTEN FOR LOADED ASSETS
    this.subscription.add(this.dataLoaded$.subscribe((val) => {

      if (val.projectMetaDataLoaded && val.purchaseOrderMetaDataLoaded && val.gcsLoaded && val.branchesLoaded) {
        this.initForm();
        this.loading$.next(false);
      }
    }));
  }

  matchEvent(e: IHomEventEmitter) {
    switch (e.action) {
      case ContactEventAction.mergeWithExisting:
      case ContactEventAction.linkWithExisting:
        if (e.data) {
          //contactId is hard-coded as 0 on the html for contact-manager
          //this should never be hit.  not sure why it is here.
          this.existingContactId = e.data.contactId;
        }
        break;
      case ContactEventAction.createNewContact:
        this.existingContactId = null;
        break;
     default:
        break;
    }
  }

  listenForChanges(): void {
    this.subscription.add(this.projectMds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null)).subscribe((obj: IValueChanged) => {
      if (obj.key === 'generalContractorId' && obj.value) {
        this.gc$.next(null);
        this.poDefaults$.next(null);
        this.branch = null;
        this.program = null;
        this.poNumber = null;
        this.gc$.next(obj.value);
        this.branches = this.allBranches.filter(x => x.generalContractor_generalContractorId === this.gc$.value.generalContractorId);
      }
      if (obj.key === 'branch' && obj.value) {
        this.poDefaults$.next(null);
        this.branch = obj.value;
        this.poNumber = null;
        this.getPODefaults();
        this.getBranchPrograms();
      }
    }));
  }

  getBranchPrograms(): void {
    if (!this.branch || !this.branch.branchId) {
      console.log('DEV ERROR: Should not call this if do not have a branch Id');
      return;
    }
    this.branchPrograms$.next(null);
    this.loading$.next(true);
    this.subscription.add(this.selectionListStore
      .pipe(select(fromSelectionLists.getSelectionListDataByType('branchProgram', this.branch.branchId)), take(1))
      .subscribe((data: IBranchProgram[]) => {
        if (!data) {
          this.selectionListStore.dispatch(new SelectionListActions
              .GetEntityListById('BranchProgram', 'ByBranch', this.branch.branchId, this.listFilter, 'branchProgram'));
        } 
      }));

    this.listenForBranchProgram(this.branch.branchId);
  }

  getPODefaults() {
    this.loading$.next(true);
    this.dos.getByUrl('PurchaseOrder/GetPoDefaults?branchId='.concat(this.branch.branchId.toString()))
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          let data = response.data;
          if (!data['poNumber']) {
            data['poNumber'] = '000000';
          }
          this.poDefaults$.next(data);
        } else {
          this.errorData$.next(response.errorData);
        }
        this.loading$.next(false);
      });
  }

  createPo(customerId: number): void {
    const po: IPurchaseOrder = new PurchaseOrder();
    po.branchProgram_branchProgramId = this.program.branchProgramId;
    po.purchaseOrderNumber = this.poNumber;
    this.dos.createByMethod('PurchaseOrder', 'Create', { model: po, customerId: customerId }).subscribe((response: IResponseBase) => {
      if (response.success) {
        this.projectId = response.data.projectId;
        this.purchaseOrderId = response.data.purchaseOrderId;
        this.purchaseOrderNumber = response.data.purchaseOrderNumber;
        this.step = 3;
      } else {
        this.errorData$.next(response.errorData);
      }
      this.loading$.next(false);
    });
  }


  listenForBranchProgram(branchId: number) {
    if (this.bpSub) {
      this.bpSub.unsubscribe();
    }
    // LISTEN FOR BRANCH PROGRAM DATA --only triggered when select a branch
    this.bpSub = this.selectionListStore.pipe(select(fromSelectionLists.getSelectionListDataByType('branchProgram', branchId)))
      .subscribe((data: IBranchProgram[]) => {
        if (data && this.gc$.value) {
          this.branchPrograms$.next(data);
          this.loading$.next(false);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.bpSub) {
      this.bpSub.unsubscribe();
    }
  }

}
