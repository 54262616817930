import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { take,  filter, map } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { ProjectService } from '../../services/project.service';
import { UserPriviledgesService } from '../../../../../auth/services/index';
import { DomainObjectService } from '../../../../../shared/services/domain-object.service';

//store actions, reducers, interfaces
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { IPurchaseOrder, IHomeDepotMeasure } from '../../../view-models';
import { IResponseBase, IHomDictionary, HomDictionary, IErrorData } from '../../../../../shared/interfaces/index';
import {  IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { FileUploadType } from '../../../../file-upload/enums/file-upload.enums';
import * as fromRoot from '../../../../../app/store/reducers/index';
import * as LoadingIndicatorActions from '../../../../../shared/store/loadingIndicator/loadingIndicator.actions';
import { ProjectListStore } from '../../enums/project.enums';
import { IEntityDocument } from '../../../view-models/index_two';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'purchase-order-measures',
  templateUrl: './purchase-order-measures.component.html'
})
export class PurchaseOrderMeasuresComponent implements OnInit, OnDestroy {
  public purchaseOrder: IPurchaseOrder;
  public entityDocument: IEntityDocument;
  public canEdit: boolean;
  public usingDefault$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public externalData$: BehaviorSubject<IHomeDepotMeasure> = new BehaviorSubject(null);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public loading$: Observable<boolean>;
  entityDocumentId: number;
  projectId: number;
  purchaseOrderId: number;  
  actionId: number;
  uploadType: string;
  subscription: Subscription = new Subscription();
  reqdDocsSub: Subscription;
  posSub: Subscription;

  constructor(
    public router: Router,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public projectService: ProjectService,
    public modalService: ModalService,
    public userPriviledgesService: UserPriviledgesService,
    public dos: DomainObjectService,
    public activeRoute: ActivatedRoute) { }

  public onUseDefault(): void {
    this.usingDefault$.next(true);
  }

  public onView(event: IHomEventEmitter): void {
    let row: IHomeDepotMeasure = event.data;
    let params: IHomDictionary[] = [];
    let lineNumber = event.action === 'bulk' ? '0' : row.lineNumber.toString();
    let viewExisting = row.entityDocumentId.id.toString() !== '0' && lineNumber === row.lineNumber.toString();
    params.push(new HomDictionary('calcDateTime', row.calcTimeStamp));
    params.push(new HomDictionary('lineNumber', lineNumber));
    params.push(new HomDictionary('viewExisting', viewExisting));

    let formatted = this.dos.formatParams(params);

    let url = '/Api/EntityDocument/ViewMeasure/'.concat(this.entityDocument.entityDocumentId.toString(), '?', formatted);
    var win = window.open(url, '_blank');
    win.focus();
  }

  public onSelected(event: IHomEventEmitter): void {
    this.rootStore.dispatch(new LoadingIndicatorActions.ShowSpinner({ requestor: 'po-measures', id: -1 }));

    let row: IHomeDepotMeasure = event.data;
    let params: IHomDictionary[] = [];
    params.push(new HomDictionary('actionId', this.actionId.toString()));
    params.push(new HomDictionary('calcDateTime', row[0].calcTimeStamp));
    params.push(new HomDictionary('lineNumber', event.action === 'bulk' ? '0' : row[0].lineNumber.toString()));

    let formatted = this.dos.formatParams(params);

    //Inserts the selected file into file upload
    let url = 'EntityDocument/SelectMeasure/'.concat(this.entityDocumentId.toString(), '?', formatted);
    this.subscription.add(this.dos.getByUrl(url)
      .subscribe((response: IResponseBase) => {
        this.rootStore.dispatch(new LoadingIndicatorActions.HideSpinner({ requestor: 'po-measures', id: -1 }));
        if (response.success) {
          this.newRequest();
          this.projectService.dispatchRequiredDocumentsGet(this.projectId, true);
          this.projectService.emitTaskReloadEvent('purchase-order-measures', this.projectId);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  public onDeleted(event: IHomEventEmitter): void {
    this.rootStore.dispatch(new LoadingIndicatorActions.ShowSpinner({ requestor: 'po-measures', id: -1 }));
    let row: IHomeDepotMeasure = event.data;
    let url = '/Api/EntityDocument/DeleteFile/'.concat(row[0].entityDocumentId.id.toString());
    this.subscription.add(this.dos.delete(url)
      .subscribe((response: IResponseBase) => {
        this.rootStore.dispatch(new LoadingIndicatorActions.HideSpinner({ requestor: 'po-measures', id: -1 }));
        if (response.success) {
          this.newRequest();
          this.projectService.dispatchRequiredDocumentsGet(this.projectId, true); 
          this.projectService.emitTaskReloadEvent('purchase-order-measures', this.projectId);
        } else {
          this.errorData$.next(response.errorData);
        }
      }));
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.entityDocumentId = +paramMap.get('id');
      this.purchaseOrderId = +paramMap.get('poId');
      this.projectId = +paramMap.get('parentId');
      this.actionId = +paramMap.get('actionId');
      this.uploadType = paramMap.get('type');
      this.newRequest();

    });

    this.subscription.add(this.usingDefault$.subscribe(val => {
      if (!this.purchaseOrder) return;

      if (val) {
        //route to file upload manager
        if (this.uploadType === FileUploadType.poMeasure) {
          this.router.navigate([{
            outlets: {
              modal2:
                ['file-upload', this.entityDocumentId.toString(), this.projectId.toString(), this.actionId.toString(), this.uploadType.toString()]
            }
          }], { relativeTo: this.activeRoute.parent })
            .catch(e => {
              console.log('Route not found, route stopped with no error raised', e);
            });

        } else {
          this.router.navigate([{
            outlets: {
              modal:
                ['file-upload', this.entityDocumentId.toString(), this.projectId.toString(), this.actionId.toString(), this.uploadType.toString()]
            }
          }], { relativeTo: this.activeRoute.parent })
            .catch(e => {
              console.log('Route not found, route stopped with no error raised', e);
            });

        }
      } else {
        this.getExternalData();
      }
    }));

    this.loading$ = this.rootStore.select('loadingIndicator')
      .pipe(
        filter(x => x.requestor === 'po-measures'),
        map(x => x.show));
  }

  getExternalData(): void {
    this.rootStore.dispatch(new LoadingIndicatorActions.ShowSpinner({ requestor: 'po-measures', id: -1 }));
    const params: IHomDictionary[] = [
      { key: 'id', value: this.purchaseOrderId.toString() },
      { key: 'entityDocumentId', value: this.entityDocumentId.toString() }
    ];

    this.subscription.add(this.dos.getByMethodParams('PurchaseOrder', 'AvailableMeasures',params)
    .subscribe((response: IResponseBase) => {
      if (response.success) {
        this.externalData$.next(response.data);
      this.errorData$.next([]);
      } else {
        this.errorData$.next(response.errorData);
      }
      this.rootStore.dispatch(new LoadingIndicatorActions.HideSpinner({ requestor: 'po-measures', id: -1 }));
    }));
  }

  getPurchaseOrder(): void {
    if (this.posSub) {
      this.posSub.unsubscribe();
    }
    this.posSub = this.dos.getByMethodById('PurchaseOrder', 'Details', this.purchaseOrderId)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.purchaseOrder = response.data;
          this.canEdit = this.userPriviledgesService.canIEdit(this.purchaseOrder);
          this.usingDefault$.next(this.purchaseOrder.measureTemplate !== 'hom-projectPoDisplayMeasureTemplate');
        } else {
          this.errorData$.next(response.errorData);
        }
      });
  }

  newRequest(): void {
    this.getPurchaseOrder();

    if (this.reqdDocsSub) {
      this.reqdDocsSub.unsubscribe();
    }
    this.reqdDocsSub = this.store.pipe(select(fromStore.getEntityListByParentId(ProjectListStore.projectEntityDocuments, this.projectId)),take(1))
      .subscribe((state: IListObjectData) => {
        this.entityDocument = state && state.data
          ? state.data.find(data => data['entityDocumentId'] == this.entityDocumentId)
          : null;
      });

  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.posSub) {
      this.posSub.unsubscribe();
    }
    if (this.reqdDocsSub) {
      this.reqdDocsSub.unsubscribe();
    }
  }
}
