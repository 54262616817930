import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IPurchaseOrder } from '../../../view-models';
import { ContactProjectEvent, ContactProjectStore } from '../../enums/contact-project.enums';
import { IListColumn, ListColumn, IListDefinition, IListFilter, ListFilter, ListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AccessLevel } from '../../../../../fw/dynamic-list/enums/access-level.enums';
import { IContactProjectSelectEvent } from '../../interfaces';

import { ContactProjectService } from '../../services';

@Component({
  selector: 'contact-project-purchase-orders',
  templateUrl: './contact-project-purchase-orders.component.html'
})
export class ContactProjectPurchaseOrdersComponent {
  @Input() contactId: number;
  @Input() contactTypeId: number;
  @Input() projectId: number;

  @Output() selectEvent = new EventEmitter<IContactProjectSelectEvent>();

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectedPo: IPurchaseOrder = null;

  constructor(public contactProjectService: ContactProjectService) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedPo = null;
    this.listDefinition$.next(this.contactProjectService.loadContactProjectPurchaseOrderListDefinition(this.contactId, this.contactTypeId, this.projectId));
  }

  public onSelect(event: IHomEventEmitter): void {
    this.selectedPo = event.data;
  }

  public onPage(event): void {
    this.selectedPo = null;
  }

  public confirmSelect(): void {
    if (this.selectedPo) {
      this.selectEvent.emit({
        projectId: this.selectedPo.project_projectId,
        projectStatus: this.selectedPo.hasOwnProperty('projectStatus') ? this.selectedPo['projectStatus'] : '',
        workOrderId: -1,
        workOrderStatus: '',
        purchaseOrderId: this.selectedPo.purchaseOrderId,
        purchaseOrderStatus: this.selectedPo.hasOwnProperty('purchaseOrderStatus') ? this.selectedPo.purchaseOrderStatus : '',
        actionIds: []
      });
    }
  }
}
