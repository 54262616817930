import { Injectable} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, map, filter, first } from 'rxjs/operators';

import { IListFilter } from '../../../fw/dynamic-list/interfaces/index';
import { AccessLevel } from '../../../fw/dynamic-list/enums/access-level.enums';

//store actions and reducers
import * as fromRoot from '../../store/reducers/index';
import * as fromSelectionLists from '../../../shared/store/selectionLists/index';
import { IContactTypeSpecification } from '../../portals/view-models';

@Injectable()
export class ContactTypeSpecificationResolver  {
  contactTypeId: number;
  storeName: string = 'contactTypeSpecification';
  listFilter: IListFilter = {
    isLookup: false,
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true, //do not want paged data
    currentPage: 1,
    searchTerm: [],
    orderTerm: null,
  };

  constructor(public rootStore: Store<fromRoot.IState>,
    public store: Store<fromSelectionLists.IStoreState> ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IContactTypeSpecification[]> {
    this.contactTypeId = +route.data['contactTypeId'];
    this.requestData();
    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<IContactTypeSpecification[]> {
    return this.store.select(fromSelectionLists.getSelectionListDataByType(this.storeName, this.contactTypeId))
      .pipe(
        filter((data: IContactTypeSpecification[]) => data !== null),
        map((data: IContactTypeSpecification[]) => { return data; }),
        first()
      );
  }

  requestData(): void {
    this.store.select(fromSelectionLists.getSelectionListDataByType(this.storeName, this.contactTypeId))
      .pipe(take(1))
      .subscribe((data: any[]) => {
        if (!data || data.length === 0) {
          this.store.dispatch(new fromSelectionLists.GetEntityListById('ContactTypeSpecification', 'ByContactType', this.contactTypeId, this.listFilter));
        }
      });
  }

}
