import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

/* structure for data object for UPDATE_DETAIL_BY_ID reducer/action */
export interface ICreateObject {
  storeName: string,
  parentId: number,
  objectType: string,
  methodName: string,
  objectData: any,
  arrayName: string; //typically null
  event: IHomEventEmitter;

}

export class CreateObjectModel implements ICreateObject {
  constructor(
    public storeName: string,
    public parentId: number,
    public objectType: string,
    public methodName: string,
    public objectData: any,
    public arrayName: string = 'models',
    public event: IHomEventEmitter = null

  ) { }
}
