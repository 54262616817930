import { ErrorHandler } from '@angular/core';
import { DatePipe, TitleCasePipe, CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { UrlSerializer, DefaultUrlSerializer } from '@angular/router';

import { HomDatePipe, HomDecimalPipe, HomPhonePipe } from 'hom-lib/hom-pipes';
import { HomErrorHandlerService } from 'hom-lib/hom-error-logger';
import { HomEventEmitterService } from 'hom-lib/hom-event-emitter';
// When a service is added here, it does not need to be added to the providers section of any other components in the application
// Guards
import { AuthGuard } from '../auth/services/auth-guard.service';
//import { AuthInterceptor, LoggingInterceptor  } from '../shared/interceptors/index';

import { DomainObjectService } from '../shared/services/domain-object.service';
import { HttpPubSubService, HomCommonUtility, HomDataUtility, DuplicateInstanceService } from '../shared/services/index';
import { FieldDefinitionService } from '../shared/services/index';

import { ScreenService } from '../fw/fw-shared/services/screen.service';
import { PageTitleService } from '../fw/fw-header/services/page-title.service';
import { BodyService } from '../fw/fw-body/services/fw-body.service';
import { ModalService } from '../fw/fw-modal/services/fw-modal.service';
import { DynamicFieldService } from '../fw/dynamic-forms/index';
import { NoteService, RequiredDocumentService } from './portals/portal-shared/services';

import { appConstants, appInjectedConstants } from '../shared/constants/index';

import { DecimalMaskDirective, DigitOnlyMaskDirective } from '../fw/fw-shared/directives/index';
import { RouteToCrmService } from './route-to-crm/route-to-crm.service';
import { ContactUtilityService } from './contact/services/contact-utility.service';
import { SmsService } from './sms/services/sms.service';
import { ProviderUserService } from './portals/portal-shared/services/provider-user.service';
import { HomLocalStorageChangeService } from '../shared/services/hom-local-storage-change.service';

export const appProviders = [
  DomainObjectService,
  FieldDefinitionService,
  DatePipe,
  TitleCasePipe,
  CurrencyPipe,
  DecimalPipe,
  PercentPipe,
  HomDatePipe,
  HomPhonePipe,
  HomDecimalPipe,
  DecimalMaskDirective,
  DigitOnlyMaskDirective,
  ScreenService,
  PageTitleService,
  BodyService,
  ModalService,
  NoteService,
  HomDataUtility,
  HomCommonUtility,
  DynamicFieldService,
  RouteToCrmService,
  ContactUtilityService,
  DuplicateInstanceService,
  SmsService,
  ProviderUserService,
  RequiredDocumentService,
  HomLocalStorageChangeService,
  HomEventEmitterService,
  {
    //recommended way to use constants in the application
    provide: appConstants,
    useValue: appInjectedConstants
  },
  { provide: UrlSerializer, useClass: DefaultUrlSerializer }, //if ever need a custom one, change the useClass here.
  { provide: HttpPubSubService, useClass: HttpPubSubService },
  { provide: Window, useValue: window },
  { provide: Document, useValue: document },
  { provide: ErrorHandler, useClass: HomErrorHandlerService },
  AuthGuard
];

