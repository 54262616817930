<div class="file-viewer-carousel">
  <div class="file-viewer-carousel__control">
    <div class="file-viewer-carousel__control__icons">
      <i class="fal fa-angle-right" (click)="slide(-1)" title="Previous"></i>
      <i class="fal fa-angle-double-right" *ngIf="pages > 1 && currentPage > 1" (click)="showPage(-1)" title="Previous Page"></i>
    </div>
  </div>
  <div class="file-viewer-carousel__pics">
    <div class="file-viewer-carousel__pics__slider" [ngStyle]="{'transform': 'translateX(' + (idx * -100) + '%)'}">
      <div class="file-viewer-carousel__pics__slider__pic" *ngFor="let item of data; let i = index;"
           [ngStyle]="{'opacity': i === idx ? 1 : 0, 'background-image': 'url(' + item['imageUrl'] + ')', 'background-size': 'contain'}">
      </div>
    </div>
  </div>
  <div class="file-viewer-carousel__control">
    <div class="file-viewer-carousel__control__icons">
      <i class="fal fa-angle-right" (click)="slide(1)" title="Next"></i> 
      <i class="fal fa-angle-double-right" *ngIf="pages > 1 && currentPage < pages" (click)="showPage(1)" title="Next Page"></i>
    </div>
  </div>
</div>


