import { Component, OnInit, OnDestroy,  Inject, Input, EventEmitter, Output } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig, DetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { IInstallerCompatibilityPreference } from '../../../view-models/installer-compatibility-preference';
import { AdminStore } from '../../enums/admin.enums';

@Component({
 selector: 'installer-scoring-edit',
  templateUrl: './installer-scoring-edit.component.html'
})
export class InstallerScoringEditComponent implements OnInit, OnDestroy  {
  @Input() row: IInstallerCompatibilityPreference;
  @Input() operation: string;
  @Input() controllerName: string;
  @Output() public cancel = new EventEmitter();
  @Output() public save = new EventEmitter<IHomEventEmitter>();

  public displayFields: string[] = ['workCategoryCompatibilityWeight', 'locationServicePreferenceCompatibilityWeight', 'locationServiceFlexibilityWeight', 'zonePreferenceCompatibilityWeight', 'warrantyCallbackRateWeight', 'averageCustomerSurveyResponseWeight', 'jobHistoryConsiderationAssignmentThreshold', 'maximumJobHistoryConsiderationDays'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  subscription: Subscription = new Subscription();

  constructor(
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants  ) {

    this.subscription.add(emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventClose:
            this.cancel.emit();
            break;
          case this.myConstants.emitterEventUpdate:
            this.save.emit(e);
            break;
          default:
            break;
        }
      }));
    }

  ngOnInit() {
    this.setDetailConfig();
  }

  setDetailConfig(): void {
    let params: IDetailContainerConfig = new DetailContainerConfig(this.row.installerCompatibilityPreferenceId,
      AdminStore.installerCompatibilityPreferences,
      'installerCompatibilityPreferenceId',
      this.operation,
      false,
      false,
      new Date().toTimeString(),
      0);
      params.parentId = -1;
      params.useRouterOutlet = false;
      params.showCancel = false;
      params.showNav = false;
      params.showTitle = false;
      params.wrapsForm = true;
      params.showErrorBox = false;
      this.detailConfig$.next(params);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
