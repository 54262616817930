import { Injectable, Inject } from '@angular/core';
import { IListColumn, ListColumn, ListDefinition, IListDefinition, ListFilter, IListFilter, OrderTerm } from '../../../../fw/dynamic-list/interfaces';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { ContactProjectEvent, ContactProjectStore } from '../enums/contact-project.enums';
import { UserPriviledgesService } from '../../../../auth/services';
import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { DynamicListService } from '../../../../fw/dynamic-list/services';
import { appConstants, IAppConstants } from '../../../../shared/constants';


@Injectable({
  providedIn: 'root'
})
export class ContactProjectService   {

  constructor(public userPriviledgesService: UserPriviledgesService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  loadContactProjectsListDefinition(contactId: number, contactTypeId: number, projectId: number = null): IListDefinition {
    const providerId = this.userPriviledgesService.providerId$.getValue();
    const listColumns = this.loadContactProjectsListColumns(contactTypeId);
    const listObjectLabel = 'Contact Projects';
    const listObjectController = 'Project';
    const listStoreName = ContactProjectStore.contactProjects;
    const detailRoutePath = '';
    const listRowKeyId = 'projectId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('startDate')];

    if (projectId !== null && contactTypeId == 4) {
      defaultListFilter.searchTerm = [{
        term: 'project_projectId',
        value: projectId,
        searchType: SearchType.Equals,
        fieldType: this.myConstants.dataTypeInt,
        columnName: 'projectId'
      }];
    }

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = contactId;
    listDefinition.parentKey = 'customer_contactId';
    listDefinition.controllerMethod = contactTypeId == 4 ? 'ByInstaller' : 'ByCustomer';
    listDefinition.methodParameters = contactId.toString();
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;
    listDefinition.revokeSort = true;
    listDefinition.singleSelect =
    {
      title: 'Select Project',
      enabled: true,
      eventName: ContactProjectEvent.selectProject
    };

    //no crud buttons
    return listDefinition;
  }

  loadContactProjectsListColumns(contactTypeId: number): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('projectId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectStatus');
    columnDefinitions.push(colDef);


    if (contactTypeId === 4) {
      colDef = new ListColumn('customerName');
      columnDefinitions.push(colDef);
    }

    colDef = new ListColumn('locations');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnLarge = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('branches');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('programs');
    colDef.visibleOnSmall = false;
    colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('services');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('isWarranty');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('startDate');
    colDef.visibleOnSmall = false;
    //colDef.visibleOnMedium = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadContactProjectWorkOrderListDefinition(contactId: number, contactTypeId: number, projectId: number = null): IListDefinition {
    const listColumns = this.loadContactProjectWorkOrderListColumns();
    const listObjectLabel = contactTypeId === 4 ? 'Installer' : 'Customer'.concat(' Work Orders');
    const listObjectController = 'WorkOrder';
    const listStoreName = ContactProjectStore.contactProjectWOs;
    const detailRoutePath = '';
    const listRowKeyId = 'workOrderId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('workOrderScheduleText')];

    if (projectId !== null) {
      defaultListFilter.searchTerm = [{
        term: 'project_projectId', value: projectId, searchType: SearchType.Equals, columnName: 'projectId', fieldType: this.myConstants.dataTypeInt
      }];
    }

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;   
    listDefinition.showFilter = true;
    listDefinition.allowSaveFilter = false;
    listDefinition.controllerMethod = contactTypeId === 4 ? 'ByInstallerLimited' : 'ByContactLimited';
    listDefinition.methodParameters = contactId.toString();
    listDefinition.singleSelect =
    {
      title: 'Select Work Order',
      enabled: true,
      eventName: ContactProjectEvent.selectWorkOrder
    };

    //no crud buttons
    return listDefinition;
  }

  loadContactProjectWorkOrderListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('workOrderId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectId');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectStatus');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderCustomerName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderCity');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderServiceName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('workOrderScheduleText');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  loadContactProjectPurchaseOrderListDefinition(contactId: number, contactTypeId: number, projectId: number = null): IListDefinition {
    const listColumns = this.loadContactProjectPurchaseOrderListColumns();
    const listObjectLabel = contactTypeId === 4 ? 'Installer' : 'Customer'.concat(' Purchase Orders');
    const listObjectController = 'PurchaseOrder';
    const listStoreName = ContactProjectStore.contactProjectPOs;
    const detailRoutePath = '';
    const listRowKeyId = 'purchaseOrderId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.accessLevel = AccessLevel.FullAccess;
    defaultListFilter.orderTerm = [new OrderTerm('purchaseOrderNumber', false)];
    if (projectId !== null) {
      defaultListFilter.searchTerm = [{
        term: 'project_projectId', value: projectId, searchType: SearchType.Equals, columnName: 'projectId', fieldType: this.myConstants.dataTypeInt
      }];
    }

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;
    listDefinition.revokeSort = true;
    listDefinition.controllerMethod = contactTypeId === 4 ? 'ByInstallerLimited' : 'ByContactLimited';
    listDefinition.methodParameters = contactId.toString();
    listDefinition.singleSelect =
    {
      title: 'Select Purchase Order',
      enabled: true,
      eventName: ContactProjectEvent.selectPurchaseOrder
    };

    //no crud buttons
    return listDefinition;
  }

  loadContactProjectPurchaseOrderListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('purchaseOrderNumber');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('purchaseOrderStatus');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('project');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('projectStatus');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('customerCity');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('purchaseOrderProgramServiceName');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);


    return columnDefinitions;
  }

}

