/*A child component for Installer and Technician Dashboard*/
import { Component, OnInit, Input , OnChanges, SimpleChanges} from '@angular/core';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter } from '../../../../../fw/dynamic-list/interfaces/index';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'installer-work-crew-zones',
  templateUrl: './installer-work-crew-zones.component.html'
})
export class InstallerWorkCrewZonesComponent implements OnInit , OnChanges{
  @Input() installerId: number;
  @Input() workCrewId: number;

  listDefinition: IListDefinition;

  constructor(public dynamicListService: DynamicListService) {}

  ngOnInit() {
    this.loadListDefinition();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workCrewId'] && !(changes['workCrewId'].firstChange)) {
      this.workCrewId = changes['workCrewId'].currentValue;
      this.loadListDefinition();
    }
  }

  /*
      Parent is Work Crew Id
  */
  loadListDefinition() {

    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Preferred Zones';
    const listObjectController = 'InstallerWorkCrewZone';
    const listStoreName = 'workCrewZones';
    const detailRoutePath = '';
    const listRowKeyId = 'installerWorkCrewZoneId';
    const detailRouterOutlet = '';

    const defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition(detailRouterOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = this.workCrewId;
    listDefinition.parentKey = 'installerWorkCrewId';
    listDefinition.controllerMethod = 'ByInstallerWorkCrew';
    listDefinition.methodParameters = this.workCrewId.toString();

    //readonly, no crud buttons
    listDefinition.allowSaveFilter = false;

    this.listDefinition = listDefinition; //set at end to signify ready to instantiate the list.  

  }

  /*
      Define the columns in the list to be created.
  */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('zone');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('zipcodes');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
