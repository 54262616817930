<div class="portal">
  <div class="portal__detail--item">
    <fw-dynamic-list *ngIf="listDefinition"
                     [listTitle]="'Work Categories'"
                     [vmListDefinition]="listDefinition"
                     (customEvent)="onCustom($event)">
    </fw-dynamic-list>
  </div>
</div> 





