import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//If hit any issues where these services not being found - change to import individual and not through barrel.
//services
import { FrameworkConfigService } from './fw-config/services/framework-config.service';
import { MenuService } from './fw-menus/services/menu.service';
import { ModalService } from './fw-modal/services/fw-modal.service';
import { TabsFactoryService } from './fw-shared/components/tabs-factory/services/tabs-factory.service';
import { CalendarService } from './fw-shared/components/calendar/services/calendar.service';
import { TabsService } from './fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';
import { SharedModule } from '../shared/shared.module';
import { FwSharedModule } from './fw-shared/fw-shared.module';
import { DynamicListModule } from './dynamic-list/dynamic-list.module';
import { DynamicFormModule } from './dynamic-forms/dynamic-form.module';
import { DynamicDetailModule } from './dynamic-detail/dynamic-detail.module';

//directives
import { DynamicComponentDirective, DynamicHostComponent } from './dynamic-host/index';

//components
import { FwBodyComponent } from './fw-body/fw-body.component';
import { FwContentComponent } from './fw-content/fw-content.component';
import { FwModalComponent } from './fw-modal/fw-modal.component';
import { HeaderComponent } from './fw-header/fw-header.component';
import { UserMenuComponent } from './fw-menus/fw-user-menu/fw-user-menu.component';
import { AppMenuComponent } from './fw-menus/fw-app-menu/fw-app-menu.component';
import { FooterComponent } from './fw-footer/fw-footer.component';
import { SearchBarComponent } from './fw-search-bar/fw-search-bar.component';

import { DetailContainerComponent } from './detail/index';
import { FwAppContentComponent } from './fw-content/fw-app-content/fw-app-content.component';

@NgModule({
    declarations: [
      FwContentComponent,
      HeaderComponent,
      UserMenuComponent,
      SearchBarComponent,
      FooterComponent,
      AppMenuComponent,
      DynamicHostComponent,
      DynamicComponentDirective,
      FwBodyComponent,
      FwModalComponent,
      FwAppContentComponent,
      DetailContainerComponent
        ],
    imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      SharedModule,
      FwSharedModule,
      DynamicListModule.forRoot(),
      DynamicFormModule.forRoot(),
      DynamicDetailModule.forRoot(),
    ],
    providers: [
      FrameworkConfigService,
        MenuService,
        ModalService,
      TabsFactoryService,
      CalendarService,
      TabsService
    ],
    exports: [
      FwBodyComponent,
      FwModalComponent,
      FwAppContentComponent,
      DynamicComponentDirective,
      DynamicHostComponent,
      FwSharedModule,
      DynamicListModule,
      DynamicFormModule,
      DynamicDetailModule,
      DetailContainerComponent
    ]
})
export class FwModule { }
