/*
 * Forked from Angular 2 Dropdown Multiselect for Bootstrap
 * Simon Lindh
 * https://github.com/softsimon/angular-2-dropdown-multiselect
 */
import {
  Component, DoCheck, ElementRef, EventEmitter, forwardRef, HostListener, Input, IterableDiffers,
  OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { cloneDeep } from 'lodash';

import { MultiSelectSearchFilter } from './search-filter.pipe';
import { IMultiSelectOption } from './interfaces/i-multi-select-option';
import { MultiSelectSettings } from './interfaces/i-multi-select-settings';
import { MultiSelectTexts } from './interfaces/i-multi-select-texts';

const MULTISELECT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultiSelectDropdown),
    multi: true
};

@Component({
  selector: 'fw-multiselect-dropdown',
  templateUrl: './fw-multi-select-dropdown.component.html',
  providers: [MULTISELECT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectDropdown implements OnInit, OnChanges, DoCheck, ControlValueAccessor, Validator {
  @Input() options: Array<IMultiSelectOption>;
  @Input() settings: MultiSelectSettings;
  @Input() texts: MultiSelectTexts;
  @Input() isDisabled: boolean = false;
  @Output() selectionLimitReached = new EventEmitter();
  @Output() dropdownClosed = new EventEmitter();
  @Output() dropdownOpened = new EventEmitter();
  @Output() onAdded = new EventEmitter();
  @Output() onRemoved = new EventEmitter();
  @Output() onPageFilter = new EventEmitter();
  @Output() onUncheckAll = new EventEmitter();
  @ViewChildren('listItems') public listItems: QueryList<ElementRef>;
  @ViewChild('scrollCon') scrollCon: ElementRef<HTMLElement>;
  @ViewChild('elem') elem: ElementRef<HTMLDivElement>;

  tabIdx: number = 0;
  model: any[] = [];
  prevModel: any[] = [];
  isVisible: boolean = false;
  searchFilterText: string = '';
  title: string;
  filterTxt: string = "";
  parents: any[];
  differ: any;
  numSelected: number = 0;
  defaultSettings: MultiSelectSettings = new MultiSelectSettings();
  defaultTexts: MultiSelectTexts = new MultiSelectTexts();
  useOptions: IMultiSelectOption[] = [];

  constructor(public element: ElementRef,
    private differs: IterableDiffers,
    private cdRef: ChangeDetectorRef ) {
    this.differ = differs.find([]).create(null);
    this.settings = this.defaultSettings;
    this.texts = this.defaultTexts;
  }

  @HostListener('document: click', ['$event']) onClick(e) {
    let target = e.target;
    if (!this.isVisible) return;
    let parentFound = false;
    while (target != null && !parentFound) {
      if (target === this.element.nativeElement) {
        parentFound = true;
      }
      target = target.parentElement;
    }
    if (!parentFound) {
      this.filterTxt = '';
      this.isVisible = false;
      this.dropdownClosed.emit();
    }
  }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.useOptions = cloneDeep(this.options || []);
      this.parents = cloneDeep(this.options)
        .filter(option => typeof option.parentId === 'number')
        .map(option => option.parentId);

      if (!changes['options'].isFirstChange()) {
        if (this.settings.uncheckAllOnReload) {
          this.uncheckAll();
        }
      }

      this.loadModelWithSelected();

      if (this.texts) {
        this.updateTitle();
      }

      this.fireModelChange();
    }

    if (changes['texts']) {
      this.texts = { ...this.defaultTexts, ...this.texts };
      if (!changes['texts'].isFirstChange()) {
        this.updateTitle();
      }
    }

    if (changes['isDisabled'] && !changes['isDisabled'].isFirstChange()) {
      this.isDisabled = changes['isDisabled'].currentValue;
    }
  }

  public ngDoCheck() {
    const changes = this.differ.diff(this.model);
    if (changes) {
      this.updateNumSelected();
      this.updateTitle();
    }
  }

 public get selectedCount(): number {
    return this.model ? this.model.length : 0;
  }

  public onTabFocus() {
    this.tabIdx = 0;
    this.toggleDropdown();
  }

  public toggleDropdown() {
    if (this.isVisible) {
      this.isVisible = false;
      this.dropdownClosed.emit();
      this.scrollCon.nativeElement.blur();
    } else {
      let int: any = setInterval(() => {
        if (this.scrollCon) {
          this.scrollCon.nativeElement.focus();
          clearInterval(int);
        }
      });
      this.tabIdx = 0;
      this.isVisible = true;
      this.dropdownOpened.emit();
    }
  }

  public onKeyDown(e) {
    e.preventDefault();
    switch (e.keyCode) {
      case 9:
        this.toggleDropdown();
        break;
      case 13:
        this.filterTxt = "";
        this.setSelected(this.useOptions[this.tabIdx]);
        break;
      case 40:
        const elem1 = this.listItems.toArray()[this.tabIdx += (this.tabIdx + 1 <= this.useOptions.length - 1 ? 1 : 0)];
        if (elem1.nativeElement.offsetTop + elem1.nativeElement.offsetHeight > this.scrollCon.nativeElement.offsetHeight + this.scrollCon.nativeElement.scrollTop) {
          this.scrollCon.nativeElement.scroll(0, elem1.nativeElement.offsetTop - (this.scrollCon.nativeElement.offsetHeight - elem1.nativeElement.offsetHeight));
        }
        break;
      case 38:
        const elem2 = this.listItems.toArray()[this.tabIdx += (this.tabIdx - 1 >= 0 ? -1 : 0)];
        if (elem2.nativeElement.offsetTop < this.scrollCon.nativeElement.scrollTop) {
          this.scrollCon.nativeElement.scroll(0, elem2.nativeElement.offsetTop);
        }
        break;
      default:
        if (this.isVisible) {
          if (e.keyCode === 8 && this.filterTxt.length) {
            this.filterTxt = this.filterTxt.substring(0, this.filterTxt.length - 1);
          } else {
            if (e.key.length === 1) {
              this.filterTxt += e.key;
              let i: number = 0;
              const options = this.useOptions,
                len: number = options.length;
              for (; i < len; i++) {
                if (options[i].name.substring(0, this.filterTxt.length).toLowerCase() === this.filterTxt.toLowerCase()) {
                  this.tabIdx = i;
                  this.scrollCon.nativeElement.scroll(0, this.listItems.toArray()[i].nativeElement.offsetTop);
                  return;
                }
              }
              this.filterTxt = "";
            }
          }
        }   
    }
  }

  public filterMatch(val: string): boolean {
    return val.substring(0, this.filterTxt.length).toLowerCase() === this.filterTxt.toLowerCase();
  }

  public getFilterTxt(val: string): string {
    return val.substring(0, this.filterTxt.length);
  }

  public toggleSelected(event: Event, idx: number) {
    this.tabIdx = idx;
    const option: IMultiSelectOption = this.useOptions[idx];
    this.filterTxt = "";
    this.setSelected(option);
    event.stopPropagation();
  }

  public checkAll() {
   if (!this.model) {
      this.model = [];
    }
    let checkedOptions = (!this.searchFilterApplied() ? this.useOptions :
      (new MultiSelectSearchFilter()).transform(this.useOptions, this.searchFilterText))
      .filter((option: IMultiSelectOption) => {
        if (this.model.indexOf(option.id) === -1) {
          this.onAdded.emit(option.id);
          return true;
        }
        return false;
      }).map((option: IMultiSelectOption) => option.id);

    this.model = this.model.concat(checkedOptions);
    this.reconcileOptionToModel();
    this.onModelChange(this.model);
    this.onModelTouched();
  }

  public uncheckAll() {
   if (!this.model) {
      this.model = [];
    }
    let unCheckedOptions = (!this.searchFilterApplied() ? this.model
      : (new MultiSelectSearchFilter()).transform(this.useOptions, this.searchFilterText).map((option: IMultiSelectOption) => option.id)
    );
    this.model = this.model.filter((id: any) => {
      if (unCheckedOptions.indexOf(id) < 0) {
        return true;
      } else {
        this.onRemoved.emit(id);
        return false;
      }
    });

    this.onModelChange(this.model);
    this.onModelTouched();
    this.reconcileOptionToModel();
    this.updateNumSelected();
    this.updateTitle();
    this.onUncheckAll.emit();
  }

  public isSelected(option: IMultiSelectOption): boolean {
    return this.model && this.model.indexOf(option.id) > -1;
  }

  public setSelected(option: IMultiSelectOption) {
    if (!this.model) {
      this.model = [];
    }
    const index = this.model.indexOf(option.id);
    if (index > -1) {
      this.model.splice(index, 1);
      this.onRemoved.emit(option.id);
      const parentIndex = option.parentId && this.model.indexOf(option.parentId);
      if (parentIndex >= 0) {
        this.model.splice(parentIndex, 1);
        this.onRemoved.emit(option.parentId);
      } else if (this.parents.indexOf(option.id) > -1) {
        let childIds = this.useOptions.filter(child => this.model.indexOf(child.id) > -1 && child.parentId == option.id).map(child => child.id);
        this.model = this.model.filter(id => childIds.indexOf(id) < 0);
        if (childIds) {
          childIds.forEach(childId => this.onRemoved.emit(childId));
        }
      }
    } else {
      if (this.settings.selectionLimit === 0 || (this.settings.selectionLimit && this.model.length < this.settings.selectionLimit)) {
        this.model.push(option.id);
        this.onAdded.emit(option.id);
        if (option.parentId) {
          let children = this.useOptions.filter(child => child.id !== option.id && child.parentId == option.parentId);
          if (children.every(child => this.model.indexOf(child.id) > -1)) {
            this.model.push(option.parentId);
            this.onAdded.emit(option.parentId);
          }
        } else if (this.parents.indexOf(option.id) > -1) {
          let children = this.useOptions.filter(child => this.model.indexOf(child.id) < 0 && child.parentId == option.id);
          if (children) {
            children.forEach(child => {
              this.model.push(child.id);
              this.onAdded.emit(child.id);
            });
          }
        }
      } else {
        if (this.settings.autoUnselect) {
          this.model.push(option.id);
          this.onAdded.emit(option.id);
          const removedOption = this.model.shift();
          this.onRemoved.emit(removedOption);
        } else {
          this.selectionLimitReached.emit(this.model.length);
          return;
        }
      }
    }
    if (this.settings.closeOnSelect) {
      this.toggleDropdown();
    }
    this.model = this.model.slice();
    this.reconcileOptionToModel();
    this.onModelChange(this.model);
    this.onModelTouched();
 }

  fireModelChange(): void {
    if (this.model != this.prevModel) {
      this.prevModel = this.model;
      this.onModelChange(this.model);
      this.onModelTouched();
      this.cdRef.markForCheck();
    }
  }

  onModelChange: Function = (_: any) => { };
  onModelTouched: Function = () => { };

  writeValue(value: any): void {
  }

  registerOnChange(fn: Function): void {
      this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
      this.onModelTouched = fn;
  }

  registerOnValidatorChange(_fn: () => void): void {
    throw new Error('Method not implemented.');
  }

  validate(_c: AbstractControl): { [key: string]: any; } {
    return (this.model && this.model.length) ? null : {
      required: {
        valid: false,
      },
    };
  }

  reconcileOptionToModel(): void {
    this.useOptions.forEach(o => {
      const match = this.model.find(m => m == o.id);
      o.selected = match === undefined ? false : true;
    });
  }

  loadModelWithSelected(): void {
    const checkedOptions = this.useOptions.filter((o: IMultiSelectOption) => o.selected)
      .map((option: IMultiSelectOption) => option.id);
    this.model = checkedOptions;
    this.updateNumSelected();
  }

  clearSearch(event: Event) {
      event.stopPropagation();
      this.searchFilterText = '';
  }

  updateNumSelected() {
    this.numSelected = this.model && this.model.filter(id => this.parents.indexOf(id) < 0).length || 0;
  }

  updateTitle() {
    let numSelectedOptions = this.useOptions.length;

    if (this.numSelected === 0 || this.settings.fixedTitle) {
      this.title = this.texts ? this.texts.defaultTitle : '';
    } else if (this.settings.displayAllSelectedText && this.model.length === numSelectedOptions) {
      this.title = this.texts ? this.texts.allSelected : '';
    } else if (this.settings.dynamicTitleMaxItems && this.settings.dynamicTitleMaxItems >= this.numSelected) {
      const useOptions = this.useOptions;

      let titleSelections: Array<IMultiSelectOption>;
      titleSelections = useOptions.filter((option: IMultiSelectOption) => this.model.indexOf(option.id) > -1);

      this.title = titleSelections.map((option: IMultiSelectOption) => option.name).join(', ');
    } else {
      this.title = this.numSelected + ' ' +
        (this.numSelected === 1 ? this.texts.checked : this.texts.checkedPlural);
    }
    this.cdRef.markForCheck();
 }

  searchFilterApplied() {
      return !this.settings.showPagingInfo && this.settings.enableSearch && this.searchFilterText && this.searchFilterText.length > 0;
  }

  preventCheckboxCheck(event: Event, option: IMultiSelectOption) {
      if (this.settings.selectionLimit && !this.settings.autoUnselect &&
          this.model.length >= this.settings.selectionLimit &&
          this.model.indexOf(option.id) === -1
      ) {
          event.preventDefault();
      }
  }

  runPagingFilter(event: Event) {
      this.onPageFilter.emit(this.searchFilterText);
  }
}
