
export enum LswpProcessingStatus {
  none = '',
  working = 'working',
  success = 'success',
  disabled = 'disabled',
  error = 'error',
  set = 'set',
  completed = 'completed',
  canceled = 'canceled'
}

