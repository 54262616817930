  <div *ngIf="createForm">
  <form [formGroup]="createForm" novalidate>
    <fw-dynamic-field [field]="mds.getFieldDefinition('branchProgram')"
                      [form]="createForm"
                      [operation]="operation"
                      [selectItems]="branchPrograms">
    </fw-dynamic-field>
    <fw-dynamic-field *ngIf="!isWarranty"
                      [field]="mds.getFieldDefinition('purchaseOrderNumber')"
                      [form]="createForm"
                      [operation]="operation">
    </fw-dynamic-field>
    <div *ngIf="isWarranty">
      Purchase Order Number {{poDefaults.poNumPrefix }} {{ poDefaults.poNumber }}
    </div>
  </form>
  <fw-crud-buttons *ngIf="showButtons" [operation]="operation"
            [disabled]="!createForm.valid"
            showNext="false"
            (cancelEvent)="cancel()"
            (createEvent)="create()">
  </fw-crud-buttons>
</div>
