import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import {  IProjectTaskActionViewModel } from '../../../view-models/index';
import { TaskManagerService, ITaskComplete } from '../../services/index';

@Component({
  selector: 'task-action',
  templateUrl: './task-action.component.html'
})
export class TaskActionComponent {
  @Input() projectTaskId: number;
  @Input() projectTaskAction: IProjectTaskActionViewModel;
  @Input() operation: string;
  @Input() canIEdit: boolean;

  @Output() public completeEvent = new EventEmitter<ITaskComplete>();

  constructor(public taskManagerService: TaskManagerService) { }

  public completeAction(): void {
    const taskComplete: ITaskComplete = { projectTaskId: this.projectTaskId, projectTaskActionId: this.projectTaskAction.projectTaskActionId, data: null }
    this.completeEvent.emit(taskComplete);
  }

  public onTemplateComplete(taskComplete: ITaskComplete): void {
    this.completeEvent.emit(taskComplete);
  }
}
