import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../auth/services';

import {
  SkuComponent,
  PayoutComponent,
  SkuPayoutLinkComponent,
  SkuPriceComponent,
  PayoutPriceComponent
} from './components';

import {
  SkuPricingWizardComponent,
  SkuCreationWizardComponent
} from './containers';

import {
  SkuDeckResolver,
  RegionResolver,  MetaDataResolver,
  AllMyProviderLocationsResolver
} from '../resolvers';
import { LoadingPageComponent } from '../../../shared/components';


const portalRoutes: Routes = [
  //BEGIN MODAL route tos
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'sku-pricing-wizard/:id/:gcId',
    outlet: 'modal',
    component: SkuPricingWizardComponent,
    resolve: {
      skuDecks: SkuDeckResolver, //custom
      regions: RegionResolver, //custom
    },
    canActivate: [AuthGuard]
  },
  {//MODAL
    path: 'sku-creation-wizard/:id/:gcId/:origin',
    outlet: 'modal',
    canActivate: [AuthGuard],
    component: SkuCreationWizardComponent,
    children: [
      {
        path: 'sku-create/:id/:gcId/:mapId/:wiz',
        outlet: 'wizard',
        data: {
          storeName: 'skus',
          controller: 'Sku'
        },
        resolve: {
          hasMetaData: MetaDataResolver
        },
        component: SkuComponent
      },
      {
        path: 'sku-price-create/:skuId/:gcId/:wiz',
        outlet: 'wizard',
        data: {
          storeName: 'skuPrices',
          controller: 'SkuPrice'
        },
        resolve: {
          hasMetaData: MetaDataResolver
        },
        component: SkuPriceComponent
      },
      {
        path: 'sku-payout-create/:id/:skuId/:wiz',
        outlet: 'wizard',
        data: {
          storeName: 'payouts',
          controller: 'Payout',
          alwaysGet: true,
          setListMetaData: true
        },
        resolve: {
          hasMetaData: MetaDataResolver
        },
        component: PayoutComponent
      },
      {
        path: 'sku-payout-link/:skuId/:wiz',
        outlet: 'wizard',
        data: {
          storeName: 'payouts',
          controller: 'Payout',
          alwaysGet: true,
          setListMetaData: true
        },
        resolve: {
          hasMetaData: MetaDataResolver
        },
        component: SkuPayoutLinkComponent
      },
      {
        path: 'payout-price-create/:payoutId/:gcId/:wiz',
        outlet: 'wizard',
        data: {
          storeName: 'payoutPrices',
          controller: 'PayoutPrice',
          includeReadOnly: false
        },
       resolve: {
          hasMetaData: MetaDataResolver,
          providerlocations: AllMyProviderLocationsResolver
        },
        component: PayoutPriceComponent
      },
    ]
  },
  {
    path: 'loading2/:container',
    outlet: 'modal2',
    component: LoadingPageComponent
  },
  { //SUB MODAL ROUTE
    path: 'sku-creation-wizard/:id/:gcId/:origin',
    outlet: 'modal2',
    canActivate: [AuthGuard],
    component: SkuCreationWizardComponent,
    children: [
      {
        path: 'sku-payout-create/:id/:skuId/:wiz',
        outlet: 'wizard',
        data: {
          storeName: 'payouts',
          controller: 'Payout',
          alwaysGet: true,
          setListMetaData: true
        },
        resolve: {
          hasMetaData: MetaDataResolver
        },
        component: PayoutComponent
      },
      {
        path: 'sku-payout-link/:skuId/:wiz',
        outlet: 'wizard',
        data: {
          storeName: 'payouts',
          controller: 'Payout',
          alwaysGet: true,
          setListMetaData: true
        },
        resolve: {
          hasMetaData: MetaDataResolver
        },
        component: SkuPayoutLinkComponent
      },
      {
        path: 'payout-price-create/:payoutId/:gcId/:wiz',
        outlet: 'wizard',
        data: {
          storeName: 'payoutPrices',
          controller: 'PayoutPrice',
          includeReadOnly: false
        },
        resolve: {
          hasMetaData: MetaDataResolver,
          providerlocations: AllMyProviderLocationsResolver
        },
        component: PayoutPriceComponent
      }
    ]
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class PricingSharedRoutingModule { }
