<div class="editable-select" [formGroup]="form">
  <label class="editable-select__label" [htmlFor]="field.key">{{ field.label }}</label>
  <div class="editable-select__field" [ngClass]="{'editable-select__field--disabled': disabled, 'has-error': field.validationMessage || ( !readOnly  && !disabled && !form.controls[field.key].valid) }">
    <input #editableSelect class="editable-select__field__input"
           autocomplete="off"
           type="text"
           placeholder="{{ field.label }}..."
           [id]="field.key"
           [formControlName]="field.key"
           [attr.list]="field.key.concat('List')"
           [attr.maxlength]="field.maxStringLength ? field.maxStringLength : 200"
           [attr.readonly]="readOnly || showEllipsis ? '' : null"
           (click)="focus()"
           (keyup)="filterList($event, editableSelect.value)"/>
    <button #btn class="editable-select__field__btn" (click)="initList()" [disabled]="disabled">
      <i class="fas fa-angle-down"></i>
    </button>
  </div>
  <div #results class="editable-select__results" *ngIf="!readOnly && !disabled ? (visible | async) : false">
    <div class="editable-select__results__item" *ngFor="let item of (showFilteredResults ? filteredResults : list)" (click)="emitSelection(item, editableSelect)">
      <div class="editable-select__results__item__txt" *ngIf="!useContains">
        {{ !showFilteredResults ? '' : item[field.selectListDefinition.labelProperty].substring(0, editableSelect.value.length) }}
      </div>
      {{ item[field.selectListDefinition.labelProperty] }}
    </div>
  </div> 
</div>

