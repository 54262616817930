<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <!--
   Job Name: drop down of jobScheduleJobs - on change loads the related scheduleName to the scheduleName ro field
   Schedule Name:  label of value corresponding to job schedle job selecteed
    Alert Only When Output:  bool, editable
   -->
  <form *ngIf="jobScheduleJobs && objectData" [formGroup]="jobForm" novalidate class="form ">

    <label>Alert Name</label>
    <select id="jobScheduleJob" name="jobScheduleJob" formControlName="jobScheduleJob"
            required
            [disabled]="operation == myConstants.operationTypeDetails"
            (change)="onChange($event)"
            [ngClass]="{'has-error': operation !== myConstants.operationTypeDetails && !jobForm.controls['jobScheduleJob'].valid}">
      <option [selected]="operation === myConstants.operationTypeCreate" value="">{{myConstants.choose}}</option>
      <option *ngFor="let item of jobScheduleJobs" [value]="item.jobScheduleJobId">{{item.jobScheduleJobName}} </option>
    </select>

    <label>Job Name</label>
    <input type="text" [readonly]="true" value="{{jobName}}" formControlName="jobName">

    <label>Schedule Name</label>
    <input type="text" [readonly]="true" value="{{scheduleName}}" formControlName="jobScheduleName">

    <fw-check-box [config]="cbConfig"
                  [formControlName]="alertOnlyWhenOutput"
                  [isChecked]="objectData.alertOnlyWhenOutput"></fw-check-box>
  </form>
  <fw-crud-buttons [operation]="operation" [disabled]="formDisabled()" (cancelEvent)="onCancel($event)" (createEvent)="onCreate($event)" (saveEvent)="onSave($event)"></fw-crud-buttons>
</fw-dynamic-detail-container>
