<div class="paging" *ngIf="pages.length && pages.length > 1">
  <div class="paging__control paging__control--prev" (click)="getPage(currentPage-1)">
    <i class="fal fa-angle-left"></i>
  </div>
  <div class="paging__pages">
    <div #slider class="paging__pages__slider" [ngStyle]="{'transform': 'translateX(calc(' + ((currentPage-1) * -10) + '%' + ' - ' + (currentPage-1) + 'px))'}">
      <div class="paging__pages__slider__page" *ngFor="let page of pages" (click)="getPage(page)">{{ page }}</div>
    </div>
    <form class="paging__pages__input" (ngSubmit)="getPage(pageinput.value)">
      <input #pageinput class="paging__pages__input__elem" value="{{currentPage}}">
    </form>
  </div>
  <div class="paging__control paging__control--next" (click)="getPage(currentPage+1)">
    <i class="fal fa-angle-right"></i>
  </div>
  <div class="paging__end paging__end--first" *ngIf="currentPage > 5" (click)="getPage(1)">1</div>
  <div class="paging__end paging__end--last" *ngIf="currentPage < pages.length-4" (click)="getPage(pages.length)">{{ pages.length }}</div>
</div>
