<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <form [formGroup]="form" novalidate class="form">
    <fw-dynamic-field [field]="mds.getFieldDefinition('itemName')"
                      [form]="form"
                      [operation]="operation"
                      [useContains]="true"
                      [selectItems]="skuPrices$ | async">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('itemType')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="skuTypes">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('quantity')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('unitMeasure')"
                      [form]="form"
                      [operation]="operation"
                      [selectItems]="unitMeasures">
    </fw-dynamic-field>
    <fw-dynamic-field [field]="mds.getFieldDefinition('unitPrice')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field *ngIf="isMaterial" [field]="mds.getFieldDefinition('isAllocated')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field *ngIf="isMaterial" [field]="mds.getFieldDefinition('estimatedDeliveryDate')"
                      [form]="form"
                      [operation]="operation">
    </fw-dynamic-field>
    <fw-dynamic-field *ngIf="isMaterial" [field]="mds.getFieldDefinition('vendorPoNumber')"
                      [form]="form"
                      [operation]="operation === myConstants.operationTypeCreate ? operation : myConstants.operationTypeDetails">
    </fw-dynamic-field>
    <fw-dynamic-field *ngIf="isMaterial" [field]="mds.getFieldDefinition('manufacturerName')"
                      [form]="form"
                      [operation]="operation === myConstants.operationTypeCreate ? operation : myConstants.operationTypeDetails">
    </fw-dynamic-field>

    <div class="float-label">
      <input type="text" placeholder="Total" id="calcTotal" readonly [value]="(itemTotal$ | async) | currency:'USD':'symbol':'1.2-2' ">
      <label for="calcTotal">Total</label>
    </div>
  </form>
    <fw-component-footer [lastUpdate]="objectData && objectData.hasOwnProperty('updateDate') ? objectData.updateDate : '' "></fw-component-footer>
    <fw-crud-buttons [operation]="operation"
                     [disabled]="!form.dirty || !form.valid"
                     [showNext]="showNext"
                     (cancelEvent)="onCancel($event)"
                     (createEvent)="onCreate($event)"
                     (saveEvent)="onSave($event)">
    </fw-crud-buttons>

  </fw-dynamic-detail-container>
