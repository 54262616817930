import { Component, OnInit, Input} from '@angular/core';
import { IWorkOrderViewModel } from '../../../../app/portals/view-models';
import { ISmsContactViewModel } from '../../../../app/portals/view-models/i-sms-contact-view-model';
import { ICustomerFlag } from '../../../../app/portals/view-models/i-customer-flag';

@Component({
  selector: 'fw-customer-flag-list-item',
  templateUrl: './customer-flag-list-item.component.html'
})
export class CustomerFlagListItemComponent implements OnInit {
  @Input() row: IWorkOrderViewModel | ISmsContactViewModel | ICustomerFlag;

  public text: string;

  ngOnInit() {
    const showIcon = this.row.hasOwnProperty('customerWarningText');
    this.text = showIcon ? this.row['customerWarningText'] : this.row['customerFlagName'];
  }

 }
