/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit,  Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'purchase-order-notes',
  templateUrl: './purchase-order-notes.component.html'
})
export class PurchaseOrderNotesComponent implements OnInit {
  public projectId: number;
  public listDefinition: IListDefinition;
  public action: string = '';
  public projectKey: string = 'project_projectId';
  purchaseOrderId: number;

  constructor(
    public activeRoute: ActivatedRoute,
    public projectService: ProjectService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
        this.purchaseOrderId = +paramMap.get('id');
        this.projectId = +paramMap.get('portalEntityId');
        const operation = paramMap.get('operation');
        if (operation === this.myConstants.operationTypeCreate) {
          this.action = this.myConstants.routeActionAdd;
        }
      });

    this.listDefinition = this.projectService.loadPurchaseOrderNoteListDefinition(this.purchaseOrderId);
  }
}
