<div class="portal">
  <div class="portal__detail--item">
    <fw-dynamic-list *ngIf="(listDefinition$ | async)"
                     [listTitle]="'Customer Search'"
                     [vmListDefinition]="(listDefinition$ | async)"
                     [infoText]="filterInfo"
                     (customEvent)="onCustom($event)">
    </fw-dynamic-list>
  </div>
</div>
