//Retrieves meta data for object - required for create 
// Assumes controller method of GetMetaData
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take, filter, map, first,  mergeMap } from 'rxjs/operators';

//store actions and reducers
import * as fromRoot from '../../store/reducers/index';
import * as fromStore from '../../../fw/dynamic-list/store/index';
import * as MetaDataActions from '../../../fw/dynamic-list/store/actions/meta-data.actions';
import { metaDataExists } from '../../../fw/dynamic-list/store/selectors/meta-data.selectors';
import * as LoadingIndicatorActions from '../../../shared/store/loadingIndicator/loadingIndicator.actions';
import { SelectionListService } from '../../../shared/services/selection-list.service';
import { ISelectResolverData } from '../../../shared/interfaces/i-select-resolver-data';

@Injectable()
export class ContactMetaDataResolver  {
  dispatched: boolean = false;
  storeName: string = 'contactInformation';
  controller: string = 'Contact';
  subscription: Subscription = new Subscription();

  constructor(public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public selectionListService: SelectionListService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = this.controller.concat('/', 'GetMetaData');
    this.rootStore.dispatch(new LoadingIndicatorActions.ShowSpinner({ requestor: 'fw-content-contact-meta-data', id:3}));
    this.dispatched = false;

    this.subscription.add(this.store.pipe(select(metaDataExists(this.storeName)), take(1))
      .subscribe((exists: boolean) => {
        if (!exists && !this.dispatched) {
          this.store.dispatch(new MetaDataActions.GetMetaData({ storeName: this.storeName, url: url, setListMetaData: false }));
          this.dispatched = true;
        }
      }
      ));

    //wait for meta data for this object to load, then request selection list data and wait for it to load.
    return this.metaLoaded().pipe(
      mergeMap((loaded: boolean) => {
        let resolverData: ISelectResolverData[] = this.selectionListService.getListNames(this.storeName);
        this.selectionListService.requestListData(this.storeName, -1);
        resolverData.forEach(x => x.parentId = -1);

        return this.selectionListService.waitForDataToLoad(resolverData)
        .pipe(
          map((complete: boolean) => {  return complete }),
          filter((complete: boolean) => complete === true),
          take(1),
          map(() => {
            this.rootStore.dispatch(new LoadingIndicatorActions.HideSpinner({ requestor: 'fw-content-contact-meta-data', id: 3 }));
            return true;
          }),
          first()
        )
      })
    );
  }

  metaLoaded(): Observable<boolean> {
    return this.store.select(metaDataExists(this.storeName))
      .pipe(
        map((exists: boolean) => {
          return exists;
        }),
        filter((exists: boolean) => exists === true),
        take(1),
        map(() => {  return true; }),
        first()
      );
  }
}
