import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy, OnChanges, SimpleChanges, ViewChild,  EventEmitter, Output } from '@angular/core';
import { Router, RouterOutlet, ActivationStart,  ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';
import { orderBy, cloneDeep } from 'lodash';

import { IWidgetManager } from '../../../portal/interfaces/index';
import { IPortalTabContainer, IPortalTab } from '../../../../portals/view-models/index';
import { IIcon } from '../../interfaces/index';
import { IPortalObjectBtn } from '../../interfaces/i-portal-object-btn';
import { IDynamicTabInclude } from '../../interfaces/i-dynamic-tab-include';

import * as fromRoot from '../../../../store/reducers/index';
import { getMyPortalComponentNames, getTabContainerByName } from '../../../../../auth/store/auth.selectors';

@Component({
  selector: 'portal-detail-layout-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './portal-detail-layout-container.component.html'
})

//Wrapper Requirements:
//  •	pull in route params to know:  portalId, objectId (installerid, projectid, etc),
//  •	Retrieve widgets I can use for this portal
//  •	Only Project and Installer Portals utilize manager portal and widget manager portal, so isolating this check and subsequent tab reduction to this container
export class PortalDetailLayoutContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() portalId: number;
  @Input() portalEntityId: number;
  @Input() title: string;
  @Input() info: string = '';
  @Input() subTitle: string = '';
  @Input() tabContainerName: string;
  @Input() titleIcon: IIcon = null;
  @Input() wideSummary: boolean = false;
  @Input() customBtns: IPortalObjectBtn[] = [];
  @Input() dynamicTabIncludes: IDynamicTabInclude[] = []; //include if want to launch a dynamic tab 
  @ViewChild(RouterOutlet) public outlet: RouterOutlet;
  @Output() public tabsLoadedEvent = new EventEmitter<boolean>();
  @Output() public customEvent = new EventEmitter<string>();

  public myTabs$: BehaviorSubject<IPortalTab[]> = new BehaviorSubject([]);
  public maxTabs: number = 0;
  public portalLevel: number;

  availableWidgets: IWidgetManager[];
  subscription: Subscription = new Subscription();

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>) { }

  ngOnInit() {
    this.newRequest();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['portalId'] && !changes['portalId'].isFirstChange()) || (changes['portalEntityId'] && !changes['portalEntityId'].isFirstChange())) {
      this.newRequest();
    }
  }

  public customClick(btnConfig: IPortalObjectBtn): void {
    if (btnConfig && btnConfig.disabled) {
      return;
    }
    if (!btnConfig.confirm || (btnConfig.confirm && confirm('Are you sure you want to ' + btnConfig.title))) {
      this.customEvent.emit(btnConfig.event);
    }
  }

  newRequest() {
    this.subscription.add(this.getTabContainers().subscribe((container) => {
      if (container) {
        this.setMyTabs(container);
      }
    }));
  }

  getTabContainers(): Observable<IPortalTabContainer> {
    return this.rootStore.pipe(select(getTabContainerByName(this.tabContainerName)), filter(container => container !== null), take(1),
      map((container: IPortalTabContainer) => {
        return container;
      }));
  }

  setMyTabs(container: IPortalTabContainer) {

    this.subscription.add(this.rootStore.pipe(select(getMyPortalComponentNames(this.portalId)),
      filter((componentNames: string[]) => componentNames !== null && componentNames.length > 0),
      take(1))
      .subscribe((componentNames: string[]) => {
        this.maxTabs = container.defaultMaxTabs;
        this.portalLevel = container.portalLevel;

        if (componentNames) {
          const tabs: IPortalTab[] = orderBy(container.portalTabs, ['tabOrder'], ['asc']);

          let newTabs: IPortalTab[] = [];
          const dynamicIncludeIsDefault: boolean = this.dynamicTabIncludes.find(x => x.isDefault == true) ? true : false;
          tabs.forEach(tab => {
            if (!tab.securityDependent || (tab.securityDependent && componentNames.includes(tab.componentName))) {
              let clonedTab = cloneDeep(tab);
              const dynamicIncludeTab: IDynamicTabInclude = this.dynamicTabIncludes.find(x => x.componentName == clonedTab.componentName);
              clonedTab.isDynamic = dynamicIncludeTab ? false : clonedTab.isDynamic;
              clonedTab.isDefault = dynamicIncludeTab && dynamicIncludeTab.isDefault ? true : dynamicIncludeIsDefault ? false : clonedTab.isDefault;
              clonedTab.closeable = !clonedTab.isDefault && clonedTab.isDynamic;
              newTabs.push(clonedTab);
            }
          });
          this.myTabs$.next(newTabs);
          this.tabsLoadedEvent.emit(true);
        }
      }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
