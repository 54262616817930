import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep, orderBy } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomDecimalPipe } from 'hom-lib/hom-pipes';

import { ISkuPrice, IService, IProgramService, IRegion, IBranch, SkuPrice, IBranchProgram } from '../../../view-models';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import {
  IMultiSelectOption, MultiSelectTexts,
  MultiSelectSettings, MultiSelectDropdown
} from '../../../../../fw/fw-shared/components/fw-multi-select-dropdown';
import { IResponseBase, IErrorData, IHomDictionary } from '../../../../../shared/interfaces';
import { SkuCreationWizardEvent, SkuCreationWizardAction } from '../../enums/pricing-shared.enums';
import { ListFilter, IListFilter } from '../../../../../fw/dynamic-list/interfaces';

import * as fromRoot from '../../../../store/reducers/index';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import { PricingSharedService } from '../../services/pricing-shared.service';
import { DomainObjectService } from '../../../../../shared/services';

@Component({
  selector: 'sku-price',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sku-price.component.html'
})
export class SkuPriceComponent implements OnInit, OnDestroy {
  @ViewChild('branchPrograms') public bpMultiSelect: MultiSelectDropdown;

  public globalType: string = 'Global';
  public serviceType: string = 'Service';
  public psType: string = 'Program Service';
  public regionType: string = 'Region';
  public branchType: string = 'Branch';
  public overrideTypes: string[] = [];
  public services: IService[] = [];
  public programServices: IProgramService[] = [];
  public regions$: BehaviorSubject<IRegion[]> = new BehaviorSubject([]);
  public branches$: BehaviorSubject<IBranch[]> = new BehaviorSubject([]);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public multiSelectLabels: MultiSelectTexts = new MultiSelectTexts();
  public multiSelectSettings: MultiSelectSettings = new MultiSelectSettings();
  public multiSelectOptions: IMultiSelectOption[] = [];
  selectedOverride: string;
  selectedServiceId: number = 0;
  selectedProgramServiceId: number = 0
  selectedRegionId: number = 0
  selectedBranchProgramIds: number[] = [];
  selectedBranchId: number = 0
  price: number = 0;

  subscription = new Subscription();
  next: string;
  close: string;
  storeName: string;
  actionOnSuccess: string;
  gcId: number;
  skuId: number;

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public pricingService: PricingSharedService,
    public emitterService: HomEventEmitterService,
    public domainObjectService: DomainObjectService,
    public homDecimalPipe: HomDecimalPipe,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  public ngOnInit() {
    this.next = SkuCreationWizardAction.next;
    this.close = SkuCreationWizardAction.close;
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.skuId = +paramMap.get('skuId');
      this.gcId = +paramMap.get('gcId');
      this.newRequest();
    });
  }

  public changeOverride(val: string): void {
    this.selectedOverride = val;
    this.selectedBranchProgramIds = [];
    this.selectedProgramServiceId = this.selectedServiceId = this.selectedRegionId = this.selectedBranchId = 0;
    this.regions$.next([]);
    this.branches$.next([]);
    this.multiSelectOptions = [];
    //init sevice selection
    const svcList = cloneDeep(this.services);
    this.services = [];
    this.services = svcList;
    //init ps selection
    const psList = cloneDeep(this.programServices);
    this.programServices = [];
    this.programServices = psList;
   switch (val) {
      case this.regionType:
        this.loadRegions();
        this.loadBranches();
        break;
      case this.branchType:
        this.loadBranches();
        break;
      default:
        break;
    }
    this.isValid$.next(this.getIsValid());
  }

  public changeService(id: number): void {
    this.selectedServiceId = id;
    this.isValid$.next(this.getIsValid());
  }

  public changeProgramService(id: number): void {
    this.selectedProgramServiceId = id;
    this.selectedBranchProgramIds = [];
    this.selectedRegionId = this.selectedBranchId = 0;
    this.multiSelectOptions = [];
    this.branches$.next([]);
    this.regions$.next([]);
    if (this.bpMultiSelect) {
      this.bpMultiSelect.uncheckAll();
    }
   //load related regions and branches
    this.loadRegions(id);
    this.loadBranchPrograms(this.psType, id);

    this.isValid$.next(this.getIsValid());
  }

  public changeRegion(id: number): void {
    this.selectedRegionId = id;
    this.selectedBranchId = 0;
    this.selectedBranchProgramIds = [];
    this.branches$.next([]);
    this.multiSelectOptions = [];
   if (this.bpMultiSelect) {
      this.bpMultiSelect.uncheckAll();
    }
    if (this.selectedOverride === this.psType) {
      this.loadBranchPrograms(this.regionType, id);
    } else {
      this.loadBranches(id);
    }
    this.isValid$.next(this.getIsValid());
  }

  public changeBranch(id: number): void {
    this.selectedBranchId = id;
    this.isValid$.next(this.getIsValid());
  }

  public addBranchPgm(id: number): void {
    this.selectedBranchProgramIds = [...this.selectedBranchProgramIds, id];
    this.isValid$.next(this.getIsValid());
  }

  public removeBranchPgm(id: number): void {
    const exists: number = this.selectedBranchProgramIds.findIndex(x => x === id);
      if (exists > -1) {
        this.selectedBranchProgramIds.splice(exists, 1);
    }
    this.isValid$.next(this.getIsValid());
  }

  public priceChange(val: number): void {
    this.price = val;
  }

  public cancel(): void {
    const e: IHomEventEmitter = { requestor: 'sku-price', event: SkuCreationWizardEvent.cancel, action: '', data: null }
    this.emitterService.emitSkuWizardEvent(e);
  }

  public create(action: string): void {
    this.working$.next(true);
    const outPrice = !this.price ? 0 : parseFloat(this.homDecimalPipe.transform(this.price.toString()));
    let skuPrice: ISkuPrice = new SkuPrice();
    skuPrice.sku_skuId = this.skuId;
    skuPrice.isGlobal = this.selectedOverride === this.globalType;
    skuPrice.programService_programServiceId = this.selectedProgramServiceId > 0 ? this.selectedProgramServiceId : null;
    skuPrice.region_regionId = this.selectedRegionId > 0 ? this.selectedRegionId : null;
    skuPrice.branch_branchId = this.selectedBranchId > 0 ? this.selectedBranchId : null;
    let obj = {
      model: skuPrice,
      selectedBranchPrograms: this.selectedBranchProgramIds.length > 0 ? this.selectedBranchProgramIds : null,
      serviceId: this.selectedServiceId > 0 ? this.selectedServiceId : 0,
      defaultDeckPrice: outPrice
    };
    this.subscription.add(this.domainObjectService.updateByMethod('SkuPrice', 'Create', obj)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          //emit next back to creation wizard
          const e: IHomEventEmitter = { requestor: 'sku-price', event: SkuCreationWizardEvent.skuPriceCreated, action: action, data: null }
          this.emitterService.emitSkuWizardEvent(e);
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));

  }

  newRequest() {
    this.overrideTypes = [this.branchType, this.globalType, this.psType, this.regionType, this.serviceType];
    this.multiSelectLabels = new MultiSelectTexts();
    this.multiSelectLabels.defaultTitle = 'None...';

    this.multiSelectSettings = new MultiSelectSettings();
    this.multiSelectSettings.selectionLimit = 0;
    this.multiSelectSettings.closeOnSelect = false;
    this.multiSelectSettings.autoUnselect = false;
    this.multiSelectSettings.showCheckAll = true;

    this.loadStaticSelectionLists();
  }

  loadStaticSelectionLists(): void {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('allService')))
      .subscribe((data: IService[]) => {
        this.services = orderBy(data, ['serviceName'], ['asc']);
      }));
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('programService')))
      .subscribe((data: IProgramService[]) => {
        this.programServices = orderBy(data, ['entityLabel'], ['asc']);
      }));

  }

  loadBranchPrograms(type: string = '', id: number = -1): void {
    let multiSelectOptions: IMultiSelectOption[] = [];
    let listFilter: IListFilter = new ListFilter();
    this.working$.next(true);
    listFilter.isLookup = true;
    listFilter.getAll = true;
    const method: string = type === this.psType ? 'ByProgramService' : type === this.regionType ? 'ByRegion' : 'ByGeneralContractor';
    const parentId: number = type === '' ? this.gcId : id;
    let params: IHomDictionary[] = [{ key: 'id', value: parentId }];
    if (type === this.regionType) {
      params.push({ key: 'programServiceId', value: this.selectedProgramServiceId })
    }
    this.subscription.add(this.domainObjectService.getByMethodParams('BranchProgram', method, params)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          const branchPrograms: IBranchProgram[] = orderBy(result.data, ['branchName'], ['asc']);
          branchPrograms.forEach((bp: IBranchProgram) => {
            multiSelectOptions.push({ id: bp.branchProgramId, name: bp.branchName });
          });
          this.multiSelectOptions = multiSelectOptions;
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));
  }

  loadRegions(programServiceId: number = -1): void {
    this.working$.next(true);
    let listFilter: IListFilter = new ListFilter();
    listFilter.isLookup = true;
    listFilter.getAll = true;
    const method: string = programServiceId > 0 ? 'ByProgramService' : 'ByGeneralContractor';
    const parentId: number = programServiceId > 0 ? programServiceId : this.gcId;
   this.subscription.add(this.domainObjectService.getByMethodById('Region',  method,  parentId, listFilter)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          this.regions$.next(orderBy(result.data, ['regionName'], ['asc']));
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));
  }

  loadBranches(regionId: number = -1): void {
    this.working$.next(true);
    let listFilter: IListFilter = new ListFilter();
    listFilter.isLookup = true;
    listFilter.getAll = true;
    const method: string = regionId > 0 ? 'ByRegion' :  'ByGeneralContractor';
    const parentId: number = regionId > 0 ? regionId : this.gcId;
    this.subscription.add(this.domainObjectService.getByMethodById('Branch', method, parentId, listFilter)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          this.branches$.next(orderBy(result.data, ['branchName'], ['asc']));
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));
  }

  getIsValid(): boolean {
    let isValid: boolean = false;
    switch (this.selectedOverride) {
      case this.globalType:
        isValid = true;
        break;
      case this.serviceType:
        isValid = this.selectedServiceId > 0;
        break;
      case this.psType:
        isValid = this.selectedProgramServiceId > 0;
        break;
      case this.regionType:
        isValid = this.selectedRegionId > 0;
        break;
      case this.branchType:
        isValid = this.selectedBranchId > 0;
        break;
      default:
        break;
    }
    return isValid;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
