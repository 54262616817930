export interface ISurveyProjectMapField {
  surveyProjectMapFieldId: number;
  survey_surveyId: number;
  entity_entityId: number;
  surveyQuestion_surveyQuestionId: number;
  position: string;
  length: number;
  positionOrder: number;
}

export class SurveyProjectMapField implements ISurveyProjectMapField {
  surveyProjectMapFieldId: number;
  survey_surveyId: number;
  entity_entityId: number;
  surveyQuestion_surveyQuestionId: number;
  position: string;
  length: number;
  positionOrder: number;

  constructor() {
    this.surveyProjectMapFieldId = 0;
    this.survey_surveyId = null;
    this.entity_entityId = null;
    this.surveyQuestion_surveyQuestionId = null;
    this.position  = '';
    this.length = 0;
    this.positionOrder = 0;
  }
}
