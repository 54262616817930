import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, BehaviorSubject, Subscription} from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

import { InstallerStore } from '../../enums/installer.enums';
import { getObjectDataById, UpdateObjectByIdObj, ClearEventObj, GetObject, getObjectErrorsById, getObjectEventById, SetEventList } from '../../../../../fw/dynamic-list/store';
import { IInstallerWorkCrewTechnician } from '../../../view-models';
import { IKey, UpdateObjectByIdModel } from '../../../../../fw/dynamic-list/store/interfaces';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { IErrorData } from '../../../../../shared/interfaces';
//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import * as fromAuth from '../../../../../auth/store/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { ContactConstantsService } from '../../../../contact/services';

@Component({
  selector: 'technician-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './technician-manager.component.html'
})
export class TechnicianManagerComponent implements OnInit, OnDestroy {
  public canIManagerInstaller$: Observable<boolean>;
  public portalHeader: string = 'Technician Manager';
  public technician$: BehaviorSubject<IInstallerWorkCrewTechnician> = new BehaviorSubject(null);
  public operation$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: Observable<IErrorData[]>;
  storeKeyName: string = 'installerWorkCrewTechnicianId';
  installerId: number;
  technicianId: number;
  subscription = new Subscription();

  constructor(
    public  activeRoute: ActivatedRoute,
    public  rootStore: Store<fromRoot.IState>,
    public  store: Store<fromStore.IAllDynamicData>,
    public  contactConstantsService: ContactConstantsService,
    @Inject(appConstants) public myConstants: IAppConstants) {
    this.operation$.next(this.myConstants.operationTypeDetails);
    }

  ngOnInit() {
    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.installerId = +paramMap.get('portalEntityId');
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.technicianId = +paramMap.get('id');
      this.newRequest();
    });

    this.canIManagerInstaller$ = this.rootStore.pipe(
      select(fromAuth.canIManageInstallers)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case ButtonType.edit:
        this.operation$.next(this.myConstants.operationTypeEdit);
        break;
      case ButtonType.reload:
        this.reloadMgr();
        break;
      case ButtonType.save:
        this.update(event.data);
        break;
      case ButtonType.cancel:
        this.store.dispatch(new ClearEventObj({ storeName: InstallerStore.technicianInformation, objectId: this.technicianId }));
        this.operation$.next(this.myConstants.operationTypeDetails);
        break;
      default:
        break;
    }
  }

  update(technician: IInstallerWorkCrewTechnician): void {
    if (!technician) return;
    const keyData: IKey = { storeName: InstallerStore.technicianInformation, parentId: this.technicianId, key: this.storeKeyName, id: this.technicianId }
    const emitter: IHomEventEmitter = { requestor: 'technician-manager', event: this.myConstants.emitterEventUpdate, action: '', data: null };
    const updateData = new UpdateObjectByIdModel(keyData, 'InstallerWorkCrewTechnician', 'Update', this.storeKeyName, this.technicianId, technician, null, emitter);
    this.store.dispatch(new UpdateObjectByIdObj({ updateData }));
  }

  newRequest(): void {
    this.operation$.next(this.myConstants.operationTypeDetails);

    this.subscription.add(this.store.pipe(select(getObjectDataById(InstallerStore.technicianInformation, this.technicianId)))
      .subscribe((data: IInstallerWorkCrewTechnician) => {
        //if already have tech, then are seeing a change, reload lists
        this.technician$.next(cloneDeep(data));
      }));

    this.errorData$ = this.store.pipe(
      select(getObjectErrorsById(InstallerStore.technicianInformation, this.technicianId)));

    this.subscription.add(this.store.pipe(
      select(getObjectEventById(InstallerStore.technicianInformation, this.technicianId)))
      .subscribe((event: IHomEventEmitter) => {
        if (event) {
          this.operation$.next(this.myConstants.operationTypeDetails);
          const technician: IInstallerWorkCrewTechnician = this.technician$.getValue();
          const emitter: IHomEventEmitter = { requestor: 'technician-manager', event: this.myConstants.emitterEventListReload, action: '', data: null };
          if (technician) {
            this.store.dispatch(new SetEventList({ storeName: InstallerStore.installerTechnicians, parentId: this.installerId, event: emitter }));
            this.store.dispatch(new SetEventList({ storeName: InstallerStore.workCrewTechnicians, parentId: technician.installerWorkCrew_installerWorkCrewId, event: emitter }));
          }
          this.store.dispatch(new ClearEventObj({ storeName: InstallerStore.technicianInformation, objectId: this.technicianId }));
        }
      }));
  }

  reloadMgr(): void {
    const objectRequest = {
      controllerName: 'InstallerWorkCrewTechnician',
      storeName: InstallerStore.technicianInformation,
      rowKeyId: 'installerWorkCrewTechnicianId',
      objectId: this.technicianId,
      displayFields: [],
      metaUrl: '',
      controllerMethod: 'DetailsWithMetaData', //defaults to Index if not specified
      methodParameters: this.technicianId.toString()
    };
    this.store.dispatch(new GetObject({ detailRequest: objectRequest }));

  }

}
