<div class="user-access-manager" *ngIf="providerUser">
  <fw-component-title [title]="'User Security Maintenance'" [entityLabel]="providerUser.fullName" class="user-access-manager__title"></fw-component-title>
  <div class="user-access-manager__roles">
    <user-roles [userRoles]="userRoles$ | async"
                [specifierType]="prest_userRole"
                [isWorking]="workingInRoles$ | async"
                [haveAllRoles]="haveAllRoles$ | async"
                (customEvent)="onCustom($event)">
    </user-roles>
  </div>
  <div class="user-access-manager__pgcs">
    <user-general-contractors [userGcs]="primaryGeneralContractors$ | async"
                              [specifierType]="prest_primaryGeneralContractor"
                              [isWorking]="workingInPrimaryGcs$ | async"
                              [haveAllGcs]="haveAllGcs$ | async"
                              (customEvent)="onCustom($event)">
    </user-general-contractors>
  </div>
  <div class="user-access-manager__sgcs">
    <user-general-contractors [userGcs]="secondaryGeneralContractors$ | async"
                              [specifierType]="prest_secondaryGeneralContractor"
                              [isWorking]="workingInSecondaryGcs$ | async"
                              [haveAllGcs]="haveAllGcs$ | async"
                              (customEvent)="onCustom($event)">
    </user-general-contractors>
  </div>
  <div class="user-access-manager__psvcs">
    <user-services [userServices]="primaryServices$ | async"
                   [specifierType]="prest_primaryService"
                   [isWorking]="workingInPrimarySvcs$ | async"
                   [haveAllServices]="haveAllServices$ | async"
                   (customEvent)="onCustom($event)">
    </user-services>
  </div>
  <div class="user-access-manager__ssvcs">
    <user-services [userServices]="secondaryServices$ | async"
                   [specifierType]="prest_secondaryService"
                   [isWorking]="workingInSecondarySvcs$ | async"
                   [haveAllServices]="haveAllServices$ | async"
                   (customEvent)="onCustom($event)">
    </user-services>
  </div>
  <div class="user-access-manager__plocs">
    <user-locations [userLocations]="primaryLocations$ | async"
                    [userBranches]="primaryBranches$ | async"
                    [specifierType]="prest_primaryProviderLocation"
                    [isWorkingLoc]="workingInPrimaryLocs$ | async"
                    [isWorkingBranch]="workingInPrimaryBranch$ | async"
                    [haveAllLocations]="haveAllLocations$ | async"
                    (customEvent)="onCustom($event)">
    </user-locations>
  </div>
  <div class="user-access-manager__slocs">
    <user-locations [userLocations]="secondaryLocations$ | async"
                    [userBranches]="secondaryBranches$ | async"
                    [specifierType]="prest_secondaryProviderLocation"
                    [isWorkingLoc]="workingInSecondaryLocs$ | async"
                    [isWorkingBranch]="workingInSecondaryBranch$ | async"
                    [haveAllLocations]="haveAllLocations$ | async"
                    (customEvent)="onCustom($event)">
    </user-locations>
  </div>
  <div class="user-access-manager__wlocs">
    <user-locations [userLocations]="warrantyLocations$ | async"
                    [specifierType]="prest_warrantyBranch"
                    [isWorkingLoc]="false"
                    [isWorkingBranch]="workingInWarrantyLocs$ | async"
                    [haveAllLocations]="haveAllLocations$ | async"
                    (customEvent)="onCustom($event)">
    </user-locations>
  </div>
</div>
<fw-error-box [errorData]="errorData$ | async" [onForm]="false" [ignoreKey]="true"></fw-error-box>
