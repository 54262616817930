import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IBranch } from '../../../view-models';
import { AdminStore, AdminEvent } from '../../enums/admin.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { AdminProviderService } from '../../services/admin-provider.service';
import { ContactConstantsService } from '../../../../contact/services';

@Component({
  selector: 'branches',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './branches.component.html'
})
export class BranchesComponent implements OnInit {

  public listDefinition: IListDefinition;
  generalContractorId: number = 0;
  regionId: number = 0;
  params: IDetailRouteParameter;

  constructor(public activeRoute: ActivatedRoute,
    public userPriviledgesService: UserPriviledgesService,
    public adminService: AdminProviderService,
    public modalService: ModalService,
    public contactConstantsService: ContactConstantsService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.generalContractorId = +paramMap.get('id');
      this.regionId = +paramMap.get('regionId');
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadBranchesListDefinition(this.generalContractorId);
    this.params = {
      rowIndex: -1,
      storeName: AdminStore.branches,
      key: 'branchId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.generalContractorId > 0 ? this.generalContractorId : this.regionId > 0 ? this.regionId : -1
    }
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.manageBranchContact:
        this.openBranchContact(event.data);
        break;
      case AdminEvent.manageBranchPLs:
        this.openBranchProviderLocations(event.data);
        break;
      case AdminEvent.manageBranchPrograms:
        this.openBranchPrograms(event.data);
        break;
      case AdminEvent.manageBranchRegions:
        this.openBranchRegions(event.data);
        break;
      case AdminEvent.manageBranchNotes:
        this.openBranchNotes(event.data);
        break;
      default:
        break;
    }
  }

  openBranchContact(b: IBranch): void {
    const contactId: number = b.branchContact_contactId === null ? 0 : b.branchContact_contactId;
    const contactTypeId: number = this.contactConstantsService.contactTypeIdBranch;
    this.modalService.open({
      title: 'Contact Information for Branch'.concat(' ', b.branchName),
      path: 'branch-contact/'.concat(contactId.toString(), '/', b.branchId.toString(), '/', contactTypeId.toString()), 
      sizeType: ModalSizeType.large,
      onExit: null,
      castExit: false,
      hasTabs: false
    });
  }


  openBranchProviderLocations(b: IBranch): void {
    this.modalService.open({
      title: 'Provider Locations for Branch'.concat(' ', b.branchName),
      path: 'branch-pls',
      id: b.branchId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }

  openBranchPrograms(b: IBranch): void {
    this.modalService.open({
      title: 'Program Services for Branch'.concat(' ', b.branchName),
      path: 'branch-pgms',
      id: b.branchId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }

  openBranchRegions(b: IBranch): void {
    this.modalService.open({
      title: 'Regions for Branch'.concat(' ', b.branchName),
      path: 'branch-regions',
      id: b.branchId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }

  openBranchNotes(b: IBranch): void {
    this.modalService.open({
      title: 'Notes for Branch'.concat(' ', b.branchName),
      path: 'branch-notes',
      id: b.branchId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }
}
