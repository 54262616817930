import { Injectable } from '@angular/core';

import { NoteContext } from '../enums/note.enums';
import { INoteConfigFields } from '../interfaces';

@Injectable()
export class NoteService   {
  noteDisplayFields: string[] = ['createUserName', 'createDate', 'noteText', 'isExternal', 'externalData.origination', 'externalData.createUser', 'externalData.dateCreated', 'externalData.dateClosed']
  poImportNoteDisplayFields: string[] = ['createUser', 'dateCreated', 'noteText', 'doesnothaveisexternal', 'origination', 'createUser', 'dateCreated', 'dateClosed']

  constructor() { }

  findContext(def: INoteConfigFields): string {
    if (def.parentKey.endsWith('contactId')) {
      return NoteContext.contact;
    }
    if (def.parentKey.endsWith('workOrderId')) {
      return NoteContext.workOrder;
    }
    if (def.parentKey.endsWith('purchaseOrderId')) {
      return NoteContext.purchaseOrder;
    }
    return def.isProjectNote ? NoteContext.project : '';
  }
}

