<!--<error-list [errorData]="errorData" [errorMessage]="errorMessage"></error-list>-->
<fw-error-box [errorData]="errorData" [errors]="errorMessage"  [ignoreKey]="true"></fw-error-box>

<p>{{lastDropDebug}}</p>
<!-- Widget layout editor -->
<!-- dragula drake container element -->

<div class="widget-layout-editor" *ngIf="portalData">
  <header class="widget-layout-editor__header">
    <h2 class="widget-layout-editor__header--title">
      <i class="fas fa-cog widget-layout-editor__header--title-icon"></i>{{portalData?.managerPortalName}} Widget Layout
    </h2>
    <div class="widget-layout-editor__header--buttons app-btn-box">
      <button type="button" class="app-btn app-btn--cancel" (click)="cancelLayoutChanges()">Cancel</button>
      <button type="button" class="app-btn app-btn--primary" (click)="saveLayoutChanges()">Save</button>
    </div>
  </header>


  <!-- Available widgets -->
  <div class="widget-layout-editor__content">
    <h3 class="widget-layout-editor__available-title">Widgets available for {{portalData?.managerPortalName}}</h3>
    <!--should just be 1, not sure why the ngFor-->
    <ng-container *ngFor="let availSection of availableWidgets">
      <div id="availableWidgets" [dragula]="widgetsDragDropBag" [(dragulaModel)]="availSection.userWidgetLayouts"
           class="widget-layout-editor__available-items widget-layout-editor__available-items--drag widget-drag__container">
        <div *ngFor="let widget of availSection.userWidgetLayouts"
             class="widget-layout-editor__available-items--item widget-drag__item">
          {{widget.widgetTitle}}
        </div>
      </div>
    </ng-container>
    <h3 class="widget-layout-editor__sections-title">Drag Widgets Below</h3>
    <div class="widget-layout-editor__info">[ max of {{ maxWidgetsPerSection }} widgets allowed per section ]</div>
    <div class="widget-layout-editor__sections ">
      <div class="widget-layout-editor__section-items " *ngFor="let section of layoutSections; let j = index;">

        <div id="selectedWidgets" class="widget-layout-editor__section-items--drag widget-drag__container"
             [dragula]="widgetsDragDropBag"
             [(dragulaModel)]="section.userWidgetLayouts"
             [attr.widget_section_idx]="j">
          <div *ngFor="let widget of section.userWidgetLayouts; let i = index" class="widget-layout-editor__section-items--item widget-drag__item">
            <div class="widget-layout-editor__section-items--item-box">
              <span class="widget-layout-editor__section-items--item-title">{{widget.widgetTitle}}</span>
            </div>
            <button class="widget-layout-editor__button widget-layout-editor__remove-item-button app-btn--cancel"
                    title="Remove Widget"
                    (click)="removeWidgetPosition(section, i)">
              X
            </button>
          </div>
          <div *ngIf="section.userWidgetLayouts.length === 0" class="widget-layout-editor__empty-container">
            Drag Widgets Here
          </div>
        </div>
        <button [disabled]="(layoutSections.length === 1)"
                class="widget-layout-editor__button widget-layout-editor__remove-section-button app-btn--cancel-light"
                title="Remove Section" (click)="removeWidgetSection(section)">
          X
        </button>
      </div>
    </div><!--end selected widget section-->

    <div class="widget-layout-editor__footer">
      <button class="widget-layout-editor__button widget-layout-editor__add-section-button app-btn--primary" title="Add Section" (click)="addWidgetSection()">+</button>
    </div>

  </div><!-- end contentBox -->

</div><!-- end widget-layout-editor -->
