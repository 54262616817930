import { Component, Input, OnInit } from '@angular/core';

import { IEntityStatus } from '../interfaces/i-entity-status';

@Component({
  selector: 'fw-entity-status',
  templateUrl: './entity-status.component.html'
})
export class EntityStatusComponent implements OnInit {
    @Input() vmEntityStatus: IEntityStatus[];  

  ngOnInit() {
  }

    /*
    Returns the icon and color to use for the entity status indicator
    */
  setStatusIndicatorClass(entityStatus: IEntityStatus) {
        let cssName = '';
        let iconColorClass = '';
        //do not care if column or row, just severity
        iconColorClass = entityStatus.severity === 'Error' ? 'app-icon--danger' : entityStatus.severity === 'Warning' ? 'app-icon--warning' : 'app-icon--info';
        cssName = entityStatus.severity === 'Error' ? 'fas fa-exclamation-triangle' : entityStatus.severity === 'Warning' ? 'fas fa-exclamation-circle' : 'fas fa-info-circle';
        cssName += ' ' +  iconColorClass;
        return cssName.trim();
    }
}
