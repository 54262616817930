<div [ngClass]="{ 'loading-mask':  (working$ | async)}">
  <div *ngIf="(listDefinition$ | async)">
    <fw-dynamic-list [vmListDefinition]="listDefinition$ | async" (customEvent)="onCustom($event)" (selectEvent)="onSelect($event)"></fw-dynamic-list>
    <div class="app-btn-box">
      <button class="app-btn app-btn--primary"
              type="button"
              (click)="closeWOs()"
              [disabled]="!selectedWos.length">
        Close Selected
      </button>
    </div>
  </div>
</div>
