import { Component, OnInit, Input, Inject, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, AfterViewInit} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import {  IProviderLocation, IService, IGeneralContractor } from '../../../view-models';
import { ILookupData } from '../../../../../shared/interfaces';
import { ISearchTypeOption } from '../../../../../fw/dynamic-list/interfaces/i-search-type-config';
import { IInputButton } from '../../../../../fw/fw-shared/interfaces/i-input-button';
import {
  IMultiSelectOption, MultiSelectTexts,
  MultiSelectSettings, MultiSelectDropdown
} from '../../../../../fw/fw-shared/components/fw-multi-select-dropdown';
import { ICalendarFilterRequest } from '../../interfaces';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import { IListFilter, ISearchTerm, IParameterSearchType, ListFilter, OrderTerm } from '../../../../../fw/dynamic-list/interfaces';
import { SearchType } from '../../../../../fw/dynamic-list/enums/search-type.enums';
import { UtilitiesEvent } from '../../enums/utilities.enums';
import { DispatchCalendarService } from '../../services/dispatch-calendar.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { HomCommonUtility } from '../../../../../shared/services';

@Component({
  selector: 'dispatch-calendar-filters',
  templateUrl: './dispatch-calendar-filters.component.html'
})
export class DispatchCalendarFiltersComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() selectedDate: string;
  @Input() displayView: string;
  @Input() locations: IProviderLocation[];
  @Input() services: IService[];
  @Input() woStatuses: ILookupData[];
  @Input() generalContractors: IGeneralContractor[];
  @Input() autoFilter: boolean = false;

  @ViewChild('searchType') public searchType: ElementRef;
  @ViewChild('searchVal') public searchVal: ElementRef;
  @ViewChild('locationMultiSelect') public locationMultiSelect: MultiSelectDropdown;
  @ViewChild('services') public serviceMultiSelect: MultiSelectDropdown;
  @ViewChild('woStatuses') public statusMultiSelect: MultiSelectDropdown;
  @ViewChild('generalContractors') public gcMultiSelect: MultiSelectDropdown;

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public title: string;
  public dateValue: string = '';
  public scheduleConfig: IInputButton;
  public vacationConfig: IInputButton;
  public selectedDisplayView: string;
  public multiSelectLabels: MultiSelectTexts = new MultiSelectTexts();
  public multiSelectSettings: MultiSelectSettings = new MultiSelectSettings();
  public locMultiSelectSettings: MultiSelectSettings = new MultiSelectSettings();
  public locationMultiSelectOptions: IMultiSelectOption[] = [];
  public serviceMultiSelectOptions: IMultiSelectOption[] = [];
  public statusMultiSelectOptions: IMultiSelectOption[] = [];
  public gcMultiSelectOptions: IMultiSelectOption[] = [];
  public selectedLocations: IProviderLocation[] = [];
  public selectedServices: IService[] = [];
  public selectedStatuses: ILookupData[] = [];
  public selectedGcs: IGeneralContractor[] = [];
  public installerSearchTypes: ISearchTypeOption[] = [];
  public showFilters: boolean = true;
  public pendingFilters: string = '';
  public schedulesLabel: string = 'Schedules';
  public typeLocation: string = 'location';
  public typeService: string = 'service';
  public typeStatus: string = 'woStatus';
  public typeGc: string = 'gc';
  public selectedLocationIndx: number = 0;
  public locationError: boolean = false;
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public disabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  subscription = new Subscription();
  maxLocations: number = 5;

  constructor(public rootStore: Store<fromRoot.IState>,
    public datePipe: DatePipe,
    public userPriviledgesService: UserPriviledgesService,
    public dispatchCalendarService: DispatchCalendarService,
    public homCommonUtility: HomCommonUtility,
   @Inject(appConstants) public myConstants: IAppConstants) { }

  public toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  public selectDate(val: string): void {
    this.dateValue = val;
    this.setDisabled();
    if (val) {
      this.pendingFilters = this.createFilterText();
    }
  }

  public changeDisplayView(newView: string): void {
    this.selectedDisplayView = newView;
    this.pendingFilters = this.createFilterText();
  }

  public addItem(type: string, val: any): void {
   switch (type) {
      case this.typeLocation:
        var loc: IProviderLocation = this.locations.find(x => x.providerLocationId == val);
        if (loc) {
          this.selectedLocations.push(loc);
          this.setDisabled();
        }
        break;
      case this.typeService:
        var svc: IService = this.services.find(x => x.serviceId == val);
        if (svc) {
          this.selectedServices.push(svc);
        }
        break;
      case this.typeStatus:
        var woStatus: ILookupData = this.woStatuses.find(x => x.lookupValueId == val);
        if (woStatus) {
          this.selectedStatuses.push(woStatus);
        }
        break;
      case this.typeGc:
        var gc: IGeneralContractor = this.generalContractors.find(x => x.generalContractorId == val);
        if (gc) {
          this.selectedGcs.push(gc);
        }
        break;
      default:
        break;
    }
    this.pendingFilters = this.createFilterText();
  }

  public removeItem(type: string, val: any): void {
  switch (type) {
    case this.typeLocation:
        var idx = this.selectedLocations.findIndex(x => x.providerLocationId === val);
        if (idx >= 0) {
          this.selectedLocations.splice(idx, 1);
          this.setDisabled();
       }
        break;
      case this.typeService:
        var idx = this.selectedServices.findIndex(x => x.serviceId === val);
        if (idx >= 0) {
          this.selectedServices.splice(idx, 1);
        }
        break;
      case this.typeStatus:
        var idx = this.selectedStatuses.findIndex(x => x.lookupValueId === val);
        if (idx >= 0) {
          this.selectedStatuses.splice(idx, 1);
        }
        break;
      case this.typeGc:
        var idx = this.selectedGcs.findIndex(x => x.generalContractorId === val);
        if (idx >= 0) {
          this.selectedGcs.splice(idx, 1);
        }
      default:
        break;
    }
    this.pendingFilters = this.createFilterText();
  }

  public changeInstaller(event): void {
    this.pendingFilters = this.createFilterText();
  }

  public createFilterText(): string {
    let displayFilterText: string = '';

    //set date filter based on selected time frame and selected date
    const dateSearchValue: string = this.dispatchCalendarService.getDateSearchValueByTimeFrame(this.selectedDisplayView, this.dateValue);
    displayFilterText = 'Schedule Start Date '.concat(this.selectedDisplayView === this.dispatchCalendarService.viewDay ? SearchType.Equals : SearchType.Range, ' ', dateSearchValue);

    //set installer filter based on search type and search val
    if (this.searchType && this.searchVal) {
      const installerSearchType = this.searchType.nativeElement.value;
      const installerSearchVal = this.searchVal.nativeElement.value;
      let installerFilter: string = '';
      if (installerSearchVal) {
        installerFilter = ' and Installer Name '.concat(this.homCommonUtility.splitWordsByCase(installerSearchType), ' ', installerSearchVal)
      }
      displayFilterText = displayFilterText + installerFilter;
    }

    //set search terms for all the select lists
    if (this.selectedLocations.length === this.locations.length) {
      displayFilterText = displayFilterText + ' and All Provider Locations ';
    } else {
      const locNames: string = this.selectedLocations.map(x => x.locationName).join(', ');
      let locationFilter: string = '';
      if (locNames) {
        locationFilter = ' and Provider Location '.concat(SearchType.Equals, ' ', locNames);
      }
      displayFilterText = displayFilterText + locationFilter;
    }

    const svcNames: string = this.selectedServices.map(x => x.serviceName).join(', ');
    let serviceFilter: string = '';
    if (svcNames) {
      serviceFilter = ' and Service '.concat(SearchType.Equals, ' ', svcNames);
    }
    displayFilterText = displayFilterText + serviceFilter;

    const statuses: string = this.selectedStatuses.map(x => x.lookupMeaning).join(', ');
    let statusFilter: string = '';
    if (statuses) {
      statusFilter = ' and Status '.concat(SearchType.Equals, ' ', statuses);
    }
    displayFilterText = displayFilterText + statusFilter;

    const gcs: string = this.selectedGcs.map(x => x.generalContractorName).join(', ');
    let gcFilter: string = '';
    if (gcs) {
      gcFilter = ' and General Contractor '.concat(SearchType.Equals, ' ', gcs);
    }
    displayFilterText = displayFilterText + gcFilter;
    return displayFilterText;
  }
  
  public runFilter(viaChanges: boolean = false): void {
   let searchTerms: ISearchTerm[] = [];

    if (this.disabled$.value) {
      return;
    }
    //set date filter based on selected time frame and selected date
    const dateSearchValue: string = this.dispatchCalendarService.getDateSearchValueByTimeFrame(this.selectedDisplayView, this.dateValue);
    searchTerms.push({
      term: 'scheduleStartDate',
      value: dateSearchValue,
      searchType: this.selectedDisplayView === this.dispatchCalendarService.viewDay ? SearchType.Equals : SearchType.Range,
      fieldType: this.myConstants.dataTypeDate,
      columnName: 'scheduleStartDate'
    });

    //set installer filter based on search type and search val
    const installerSearchType = this.searchType.nativeElement.value;
    const installerSearchVal = this.searchVal.nativeElement.value;
    if (installerSearchVal) {
      searchTerms.push({ term: 'installerName', value: installerSearchVal, searchType: installerSearchType, columnName: 'installerName', fieldType: this.myConstants.dataTypeString });
    }

    //set search terms for all the select lists
    const locationIds: number[] = this.selectedLocations.map(x => x.providerLocationId);
    const serviceIds: number[] = this.selectedServices.map(x => x.serviceId);
    const statusIds: number[] = this.selectedStatuses.map(x => x.lookupValueId);
    const gcIds: number[] = this.selectedGcs.map(x => x.generalContractorId);

    if (locationIds.length) {
      const locationNames: string[] = this.selectedLocations.map(x => x.locationName);
      searchTerms.push({ term: 'providerLocation_ProviderLocationId', value: locationIds, searchType: SearchType.Equals, displayValues: locationNames, columnName: 'locationName', fieldType: this.myConstants.dataTypeInt });
 }

    if (serviceIds.length) {
      const serviceNames: string[] = this.selectedServices.map(x => x.serviceName);
      searchTerms.push({ term: 'service_ServiceId', value: serviceIds, searchType: SearchType.Equals, displayValues: serviceNames, columnName: 'serviceName', fieldType: this.myConstants.dataTypeInt });
    }

    if (statusIds.length) {
      const statusNames: string[] = this.selectedStatuses.map(x => x.lookupMeaning);
      searchTerms.push({ term: 'workOrderStatus_LookupValueId', value: statusIds, searchType: SearchType.Equals, displayValues: statusNames, columnName: 'workOrderStatus', fieldType: this.myConstants.dataTypeInt });
    }

    if (gcIds.length) {
      const gcNames: string[] = this.selectedGcs.map(x => x.generalContractorName);
    searchTerms.push({ term: 'project_branch_generalContractor_generalContractorId', value: gcIds, searchType: SearchType.Equals, displayValues: gcNames, columnName: 'generalContractor', fieldType: this.myConstants.dataTypeInt });
    }

    let listFilter: IListFilter = new ListFilter();
    listFilter.searchTerm = searchTerms;
    listFilter.orderTerm.push(new OrderTerm('ScheduleStartDate'));
    listFilter.getAll = true;

    let data: ICalendarFilterRequest = { scheduleStartDate: this.dateValue, viewType: this.selectedDisplayView, listFilter: listFilter, displayFilterText: this.createFilterText() }

    const emitter: IHomEventEmitter = {
      requestor: 'dispatch-calendar-filters',
      event: UtilitiesEvent.filterChanged,
      data: data,
      action: this.selectedDisplayView
    }
    this.customEvent.emit(emitter);
    if (!viaChanges) {
      this.showFilters = !this.showFilters;
    }
  }

  ngOnInit() {
    const todayDate = this.datePipe.transform(Date.now(), 'MM/dd/yyyy');
    this.dateValue = this.selectedDate ? this.selectedDate : todayDate;
    this.selectedDisplayView = this.dispatchCalendarService.viewDay;
    this.loadSelectLists();
    this.setConfigs();
    this.setDisabled();
    this.pendingFilters = this.createFilterText();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['selectedDate'] && changes['displayView'] && !(changes['selectedDate'].firstChange) && !(changes['displayView'].firstChange)) {
      this.selectDate(this.selectedDate);
      this.changeDisplayView(this.displayView);
      this.handleParentChanges();
      const prevDate: string = (changes['selectedDate'].previousValue);
      const prevView: string = (changes['displayView'].previousValue);
      if (this.autoFilter && ((prevDate && this.selectedDate !== prevDate) || (prevView && this.displayView !== prevView))) this.runFilter(true);
    } else if (changes['selectedDate'] && !(changes['selectedDate'].firstChange)) {
      this.selectDate(this.selectedDate);
      this.handleParentChanges();
      const prevDate: string = (changes['selectedDate'].previousValue);
      if (this.autoFilter && prevDate && this.selectedDate !== prevDate) {
        this.runFilter(true);
      }
    } else if (changes['displayView'] && !(changes['displayView'].firstChange)) {
      this.changeDisplayView(this.displayView);
      this.handleParentChanges();
      const prevView: string = (changes['displayView'].previousValue);
      if (this.autoFilter && prevView && this.displayView !== prevView) {
        this.runFilter(true);
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.dispatchCalendarService.lastFilter) {
      this.resetFromSavedSettings();
      this.toggleFilters();
    }
  }

  initSelectedLocations(): void {
    this.selectedLocations = [];
  }

  defaultSelectedLocation(name: string = ''): void {
    this.initSelectedLocations();
    if (this.locationMultiSelect) {
      this.locationMultiSelect.uncheckAll();
      const searchFor: string = name ? name : this.locations[0].locationName;
      let index = this.locationMultiSelect.options.findIndex(o => o.name === searchFor);
      if (index >= 0) {
        this.locationMultiSelect.setSelected(this.locationMultiSelect.options[index]);
      }
    }
  }

  //selectAllLocations(): void {
  //  if (this.displayView !== this.dispatchCalendarService.viewMonth) {
  //    this.initSelectedLocations();
  //    if (this.locationMultiSelect) {
  //      this.locationMultiSelect.checkAll();
  //    }
  //  }
  //}

  loadSelectLists(): void {
    this.multiSelectLabels = new MultiSelectTexts();  
    this.multiSelectSettings = this.locMultiSelectSettings = new MultiSelectSettings();
    this.multiSelectSettings.selectionLimit = 0;
    this.multiSelectSettings.closeOnSelect = this.locMultiSelectSettings.closeOnSelect = false;
    this.multiSelectSettings.autoUnselect = this.locMultiSelectSettings.autoUnselect = false;
    this.multiSelectSettings.showCheckAll = true;
    this.locMultiSelectSettings.selectionLimit = this.maxLocations;
    this.locMultiSelectSettings.showCheckAll = false;

    let multiSelectOptions: IMultiSelectOption[] = [];
    this.locations.forEach(item => {
      multiSelectOptions.push({ id: item.providerLocationId, name: item.locationName });
    });
    this.locationMultiSelectOptions = multiSelectOptions;

    multiSelectOptions = [];
    this.services.forEach(item => {
      multiSelectOptions.push({ id: item.serviceId, name: item.serviceName });
    });
    this.serviceMultiSelectOptions = multiSelectOptions;

    multiSelectOptions = [];
    this.woStatuses.forEach(item => {
      multiSelectOptions.push({ id: item.lookupValueId, name: item.lookupMeaning });
    });
    this.statusMultiSelectOptions = multiSelectOptions;

    multiSelectOptions = [];
    this.generalContractors.forEach(item => {
      multiSelectOptions.push({ id: item.generalContractorId, name: item.generalContractorName });
    });
    this.gcMultiSelectOptions = multiSelectOptions;
  }

  setConfigs(): void {
    const searchTypes: IParameterSearchType[] = this.userPriviledgesService.getSearchTypesByParameterType(this.myConstants.fieldTypeString.toLowerCase());
    if (searchTypes) {
      searchTypes.forEach(t => {
        this.installerSearchTypes.push({ value: t.searchType, label: this.homCommonUtility.splitWordsByCase(t.searchType), isDefault: t.searchType === SearchType.StartsWith });
      });
    }

    this.scheduleConfig = {
      label: {
        label: this.schedulesLabel,
        alignRight: true
      }
    };
    this.vacationConfig = {
      label: {
        label: 'Vacations',
        alignRight: true
      }
    };
  }


  resetFromSavedSettings(): void {
    const searchTerms: ISearchTerm[] = this.dispatchCalendarService.lastFilter ? this.dispatchCalendarService.lastFilter.searchTerm : [];
    this.selectedDisplayView = this.dispatchCalendarService.lastDisplayView;
    searchTerms.forEach(term => {
      switch (term.term) {
        case 'providerLocation_ProviderLocationId':
          const plIds: number[] = term.value;
          plIds.forEach(id => {
            const index = this.locationMultiSelect.options.findIndex(o => o.id === id);
            if (index >= 0) {
              this.locationMultiSelect.setSelected(this.locationMultiSelect.options[index]);
            }
         });
          break;
        case 'service_ServiceId':
          const svcIds: number[] = term.value;
          svcIds.forEach(id => {
            const index = this.serviceMultiSelect.options.findIndex(o => o.id === id);
            if (index >= 0) {
              this.serviceMultiSelect.setSelected(this.serviceMultiSelect.options[index]);
            }
         });
          break;
        case 'installerName':
          //searchtype can vary so will need that too
          if (this.searchType && this.searchVal) {
            this.searchType.nativeElement.value = term.searchType;
            this.searchVal.nativeElement.value = term.value;
          }
          break;
        case 'workOrderStatus_LookupValueId':
          const statusIds: number[] = term.value;
          statusIds.forEach(id => {
            const index = this.statusMultiSelect.options.findIndex(o => o.id === id);
            if (index >= 0) {
              this.statusMultiSelect.setSelected(this.statusMultiSelect.options[index]);
            }
          });
         break;
        case 'project_branch_generalContractor_generalContractorId':
          const gcIds: number[] = term.value;
          gcIds.forEach(id => {
            const index = this.gcMultiSelect.options.findIndex(o => o.id === id);
            if (index >= 0) {
              this.gcMultiSelect.setSelected(this.gcMultiSelect.options[index]);
            }
          });
         break;
        default:
          break;
      }
    })
  }

  handleParentChanges(): void {
    const newActiveFilter = this.pendingFilters;
    const emitter: IHomEventEmitter = {
      requestor: 'dispatch-calendar-filters',
      event: UtilitiesEvent.filterTextChanged,
      data: newActiveFilter,
      action:''
    }

    this.customEvent.emit(emitter);
  }

  checkForErrors(): boolean {
    let haveErrors: boolean = false;
    this.errors$.next('');
    if (this.selectedDisplayView === this.dispatchCalendarService.viewWeek && this.selectedLocations.length > 3) {
      this.errors$.next('Please reduce the number of locations for Week pulls to no more than 3 locations.');
      haveErrors = true;
    }

    return haveErrors;
  }

  setDisabled(): void {
    let isDisabled: boolean = false;
    let errors: string = '';
    if (!this.dateValue) {
      isDisabled = true;
      errors = errors ? errors + ' ' : '';
      errors = errors + 'Date is required.';
    }

    //provider location checks
    if (this.selectedLocations.length === 0) {
      isDisabled = true;
      errors = errors ? errors + ' ' : '';
      errors = errors + 'A location selection is required';
    } else if (this.selectedLocations.length > this.maxLocations) {
      isDisabled = true;
      errors = errors ? errors + ' ' : '';
      errors = errors + 'Only ' + this.maxLocations.toString() + ' may be selected.';
    } else {
      const denver = this.locations.find(x => x.locationName.toLowerCase() == 'denver');
      const seattle = this.locations.find(x => x.locationName.toLowerCase() == 'seattle');
      if (denver && seattle) {
        const locs = this.selectedLocations.filter(x => x.providerLocationId === denver.providerLocationId || x.providerLocationId === seattle.providerLocationId);
        if (locs && locs.length > 1) {
          isDisabled = true;
          errors = errors ? errors + ' ' : '';
          errors = errors + 'Selection of both ' + denver.locationName + ' and ' + seattle.locationName + ' are not allowed due to volume in these two locations.';
        }
      }
    }
    this.disabled$.next(isDisabled);
    this.errors$.next(errors);
  }


 }

