import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomSignaturePadComponent } from './hom-signature-pad.component';
import { HomSignatureManagerComponent } from './hom-signature-manager.component';
import { HomDataAccessService } from 'hom-lib/hom-data-access';
import { HomEventEmitterService } from 'hom-lib/hom-event-emitter';

@NgModule({
  declarations: [
    HomSignaturePadComponent,
    HomSignatureManagerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    HomDataAccessService,
    HomEventEmitterService
  ]
})

export class HomSignatureModule {
  static forRoot(): ModuleWithProviders<HomSignatureModule> {
    return {
      ngModule: HomSignatureModule,
      providers: [HomDataAccessService, HomEventEmitterService]
    };
  }
}
