
export enum ButtonType {
  edit = 'edit',
  cancel = 'cancel',
  save = 'save',
  reload = 'reload',
  delete = 'delete',
  create = 'create',
  print = 'print',
  detail = 'detail',
  info = 'info'
}

