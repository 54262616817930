
<div class="sms-popover-box">
  <div class="sms-popover">
    <fw-component-title title='Communication Helpers'></fw-component-title>
      <input #shortSearch
          class="sms-popover__search"
          placeholder="Search..."
          (keyup)="onKeyup(shortSearch.value)"
          (keydown.enter)="selectFirstItem($event)">
    <div class="sms-popover__items">
      <ng-container *ngFor="let item of (filteredValues$ | async); index as i">
        <div #shortOption class="sms-popover__item" [ngClass]="{'sms-popover--active': i === 0 && shortSearch.value != '' }" (click)="selectTemplate(item.communicationShortcutId)">
          {{item.communicationShortcutName}}
        </div>
      </ng-container>
    </div>
  </div>
</div>
