<div *ngIf="technicianId" class="portal" >
  <div class="portal__detail--item" [ngClass]="{ 'loading-mask': !(technician$ | async)}">
    <technician-general-information *ngIf="(technician$ | async) as technician"
                                    [technician]="technician"
                                    [canIEdit]="canIManagerInstaller$ | async"
                                    [operation]="operation$ | async"
                                    [errorData]="errorData$ | async"
                                    (customEvent)="onCustom($event)">
    </technician-general-information>
  </div>
  <ng-container *ngIf="(technician$ | async) as technician">
    <installer-work-crew-technician-roles [installerWorkCrewTechnicianId]="technician.installerWorkCrewTechnicianId"
                                          [providerUserId]="technician.providerUserId"
                                          [canIEdit]="canIManagerInstaller$ | async">
    </installer-work-crew-technician-roles>

    <div class="portal__detail--item">
      <detail-contact-manager *ngIf="technician && technician.contact_contactId > 0"
                              [canIEdit]="canIManagerInstaller$ | async"
                              [contactId]="technician.contact_contactId"
                              [isOrganization]="false"
                              [contactTypeId]="contactConstantsService.contactTypeIdInstaller"
                              [allowUnlink]="false">
      </detail-contact-manager>
    </div>
    <installer-certifications [installerId]="technician.installer_installerId"
                              [installerWorkCrewTechnicianId]="technician.installerWorkCrewTechnicianId"
                              [certificationAssignment]="0">
    </installer-certifications>
    <installer-certifications [installerId]="technician.installer_installerId"
                              [installerWorkCrewTechnicianId]="technician.installerWorkCrewTechnicianId"
                              [certificationAssignment]="1">
    </installer-certifications>
  </ng-container>
</div>

