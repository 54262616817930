import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject, Output, EventEmitter  } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { IKey, UpdateObjectCustomModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { ProjectService } from '../../services/project.service';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { IPurchaseOrderInventoryAllocation } from '../../../view-models';
import { IListObjectData, IDynamicListState } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { ProjectListStore, ProjectEvent } from '../../enums/project.enums';

@Component({
  selector: 'project-unreceived-inventory',
  templateUrl: './project-unreceived-inventory.component.html'
})
export class ProjectUnreceivedInventoryComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() canIEdit: boolean;

  @Output() public receivedEvent = new EventEmitter();

  public selectedItems: IPurchaseOrderInventoryAllocation[] = [];
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  reloadWo: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(
    public projectService: ProjectService,
    public store: Store<fromFeature.IAllDynamicData>,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public receiveInventory(): void {
    const def = this.listDefinition$.getValue();
    const keyData: IKey = { storeName: def.storeName, parentId: this.projectId, key: def.rowKeyId, id: this.selectedItems[0].purchaseOrderInventoryAllocationId, skipDataUpdate: true };
    const emitter: IHomEventEmitter = { requestor: 'project-unreceived-inventory', event: this.myConstants.emitterEventListReload, action: '', data: null };
    const updateObj = { purchaseOrderInventoryAllocationIds: this.selectedItems.map(x => x.purchaseOrderInventoryAllocationId) }
    const updateData = new UpdateObjectCustomModel(keyData, def.controllerName, 'ReceiveItems', updateObj, null, emitter);
    this.reloadWo = true;
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
    this.selectedItems = [];
  }

  public onSelect(event: IHomEventEmitter) {
    switch (event.event) {
      case ProjectEvent.receiveInventory:
        this.updateSelected(event);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.setListDefinition();

    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectUnreceivedInventory)))
      .pipe( map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)) )
      .subscribe((state: IListObjectData) => {
        //wait for event to clear then dispatch an event to reload the dependent list data - wo
        if (state && !state.event && this.reloadWo) {
          this.reloadWo = false;
          this.projectService.dispatchWorkOrders('project-unreceived-inventory', this.projectId, this.canIEdit);
        }
      }));
  }

  setListDefinition(): void {
    this.selectedItems = [];
    const listDefinition = this.projectService.loadProjectUnreceivedInventoryListDefinition(this.projectId, this.canIEdit);
    listDefinition.noGet = true; //parent retrieves initial load
    this.listDefinition$.next(listDefinition);
  }


  updateSelected(event: IHomEventEmitter): void {
    const item: IPurchaseOrderInventoryAllocation = event.data;
    const exists: number = this.selectedItems.findIndex(x => x.purchaseOrderInventoryAllocationId === item.purchaseOrderInventoryAllocationId);
    if (event.action) {
      if (exists === -1) {
        this.selectedItems.push(item);
      }
    } else {
      if (exists > -1) {
        this.selectedItems.splice(exists, 1);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] && !changes['projectId'].isFirstChange()) {
      this.setListDefinition();
    }
  }
}
