import { Component } from '@angular/core';

@Component({
  selector: 'invalid-user',
  templateUrl: './invalid-user.component.html'
})
export class InvalidUserComponent {
    public title: string = 'Invalid User Security Settings';

}
