import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkOrderStatus, ScheduleStore, ScheduleEvent, DurationType } from '../enums/schedule.enums';
import { IWorkOrder } from '../../view-models';
import { IListDefinition, ListDefinition, IListFilter, IListColumn, ListColumn,  ListFilter, OrderTerm } from '../../../../fw/dynamic-list/interfaces';
import { IRequestAvailabilityEvent } from '../interfaces';
import { IHomDictionary, IResponseBase } from '../../../../shared/interfaces';
import { HomDataUtility, DomainObjectService } from '../../../../shared/services';
import { DynamicListService } from '../../../../fw/dynamic-list/services';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

  constructor(public homDataUtility: HomDataUtility,
    public domainObjectService: DomainObjectService,
    public dynamicListService: DynamicListService) {}

  editableStatus(workOrder: IWorkOrder): boolean {
    let isValid: boolean = false;
    switch (workOrder.workOrderStatusText) {
      case WorkOrderStatus.toBeSchedule:
      case WorkOrderStatus.scheduled:
      case WorkOrderStatus.dispatched:
      case WorkOrderStatus.noGoods:
        isValid = true;
        break;
      default:
        isValid = false;
        break;
    }
    return isValid;  
  }

  canSchedule(workOrder: IWorkOrder): boolean {
    return this.editableStatus(workOrder) && !workOrder.notSchedulableReason ? true : false;
  }

  loadWorkOrderWorkCategoriesListDefinition(id: number): IListDefinition {
    const listColumns = this.loadWorkCategoriesListColumns();
    const listObjectLabel = 'Work Category';
    const listObjectController = 'WorkOrderWorkCategoryViewModel';
    const listStoreName = ScheduleStore.workCategories;
    const detailRoutePath = '';
    const listRowKeyId = 'workOrderId';

    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.getAll = true;

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = -1;
    listDefinition.controllerMethod = 'ByWorkOrder';
    listDefinition.methodParameters = id.toString();
    listDefinition.showFilter = false;
    listDefinition.allowSaveFilter = false;

    //no crud buttons
    return listDefinition;
  }

  loadWorkCategoriesListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('workCategoryName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('units');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  } 
   
  loadScheduleMoveUpListDefinition(workOrderId: number): IListDefinition {
    const listColumns = this.loadScheduleMoveUpListColumns();
   const listObjectLabel = 'Work Order Schedule Move Ups';
    const listObjectController = 'ProviderLocationScheduleMoveUp';
    const listStoreName = ScheduleStore.providerLocationScheduleMoveups;
    const detailRoutePath = '';
    const listRowKeyId = 'scheduleId';

    const defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = workOrderId;
    listDefinition.allowSaveFilter = false;
    listDefinition.controllerMethod = 'ByWorkOrder';
    listDefinition.methodParameters = workOrderId.toString();

    //no crud buttons
    return listDefinition;
  }

  loadScheduleMoveUpListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('requestedDate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('immediateMoveUp');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('moveUpText');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  getAvailableSlots(request: IRequestAvailabilityEvent): Observable<IResponseBase> {
    let params: IHomDictionary[] = [
      { key: 'workOrderId', value: request.workOrderId.toString() },
      { key: 'startDate', value: request.startDate },
      { key: 'duration', value: request.duration.toString() },
      { key: 'durationType', value: request.durationType },
      {
        key: 'pullLength', value: request.durationType === DurationType.days && request.pullLength < request.duration
          ? request.duration.toString()
          : request.pullLength.toString()
      }
    ];

    if (request.currentWorkCrewId) {
      params.push({ key: 'currentWorkCrewId', value: request.currentWorkCrewId.toString() });
    } else {
      params.push({ key: 'currentWorkCrewId', value: 0 });
    }

    return this.domainObjectService.getByMethodParams('WorkOrder', 'GetScheduleAvailability', params);
  }

  getAvailableInstallers(request: IRequestAvailabilityEvent): Observable<IResponseBase> {
    const params: IHomDictionary[] = [
      { key: 'workOrderId', value: request.workOrderId.toString() },
      { key: 'startDateTime', value: request.startDate },
      { key: 'duration', value: request.duration.toString() },
      { key: 'durationType', value: request.durationType },
      { key: 'scheduleId', value: request.scheduleId.toString() }
    ];
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.orderTerm = [new OrderTerm(request.sortBy)];

    return this.domainObjectService
      .getByMethodParams('ScheduleInstallerWorkCrewAvailabilityViewModel', 'GetAvailableInstallerWorkCrews', params, listFilter);
  }

  loadUnavailableInstallersListDefinition(workOrderId: number, startDate: string, duration: number, durationType: string, scheduleId: number) {
    const listColumns = this.UnavailableInstallersListColumns();

    const listObjectLabel = 'Unavailable Installers';
    const listObjectController = 'ScheduleInstallerWorkCrewUnavailabilityViewModel';
    const listStoreName = ScheduleStore.unavailableWorkCrews ;
    const detailRoutePath = '';
    const listRowKeyId = 'installerWorkCrewId';
    let defaultListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('installerWorkCrewName')];

    let listDefinition = this.dynamicListService.createListDefinition('',
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'GetUnavailableInstallerWorkCrews';
    listDefinition.methodParameters = '?workOrderId=' +workOrderId.toString() + '&startDateTime=' + startDate + '&duration=' + duration.toString()
      + '&durationType=' + durationType + '&scheduleId=' + scheduleId.toString();

    listDefinition.parentId = -1
    listDefinition.parentKey = ''
    listDefinition.showFilter = true
    listDefinition.allowSaveFilter = false;
    listDefinition.noGet = true;
    listDefinition.openInFilterMode = true;

    //no crud buttons
    return listDefinition;  
  }

  UnavailableInstallersListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('installerWorkCrewName');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('complianceReason');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('vacationReason');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('scheduleReason');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('sssReason');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('score');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('briefReasons');
    colDef.visibleOnLarge = false;
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    colDef.onlyOnSmall = true;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('communicationPreferenceValue');
    colDef.visibleOnSmall = true;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
