import { Injectable} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, filter , first } from 'rxjs/operators';

import { IListFilter, OrderTerm } from '../../../fw/dynamic-list/interfaces/index';
import { IProjectChildrenViewModel } from '../view-models/index_two';
import { AccessLevel } from '../../../fw/dynamic-list/enums/access-level.enums';

//store actions and reducers
import { InitSelectItems, GetEntityListById, getSelectionListDataByType } from '../../../shared/store/selectionLists/index';
import * as fromSelectionLists from '../../../shared/store/selectionLists/index';

@Injectable()
export class ProjectChildrenResolver  {
  projectId: number;
  listFilter: IListFilter = {
      isLookup: false,
      getCount: false,
      filterFor: '',
      filterContext: '',
      accessLevel: AccessLevel.ReadOnly,
      getAll: true, //do not want paged data
      currentPage: 1,
      searchTerm: [],
      orderTerm: [new OrderTerm('EntityPkLabel')]
  };

  constructor(public selectionListStore: Store<fromSelectionLists.IStoreState>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProjectChildrenViewModel[]> {
    this.projectId = +route.paramMap.get('portalEntityId') || -1;
    //init data - always reget
    this.selectionListStore.dispatch(new InitSelectItems({ storeName: 'projectChildren', parentId: this.projectId }));
    this.requestData();
    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<IProjectChildrenViewModel[]> {
    return this.selectionListStore.select(getSelectionListDataByType('projectChildren', this.projectId))
      .pipe(
        filter((data: any[]) => data !== null),
        map((data: any[]) => { return data; }),
        first()
      );
  }

  //always get fresh
  requestData(): void {
    this.selectionListStore.dispatch(new GetEntityListById('ProjectChildrenViewModel', 'ByProject', this.projectId, this.listFilter,'projectChildren'));
  }
}
