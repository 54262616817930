import { Component, Input} from '@angular/core';

@Component({
    selector: 'fw-section-title',
    templateUrl: './fw-section-title.component.html'
})
export class SectionTitleComponent  {
    @Input() title: string = '';
    @Input() underline: boolean = false;
    @Input() icon: string = '';
    @Input() titleCase: boolean = true;

    constructor() { }

}
