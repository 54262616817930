<div class="portal__detail--item">
  <fw-dynamic-list *ngIf="providerUserId > 0 && listDefinition"
                   [listTitle]="title"
                   [vmListDefinition]="listDefinition">
  </fw-dynamic-list>

  <fw-component-title *ngIf="providerUserId === 0" [title]="title"> </fw-component-title>
  <div *ngIf="providerUserId === 0" class="app-info">
    <i class="fas fa-info-circle app-icon--primary"></i>
    This technician is not currently a registered user of the Installer Portal.
  </div>
</div>
