import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactConstantsService   {
  requestorTypeContactAddress: string = 'contact-address';
  requestorTypeContactEmail: string = 'contact-email';
  requestorTypeContactPhone: string = 'contact-phone';

  addressFormPrefix: string = 'addressForm';
  emailFormPrefix: string = 'emailForm';
  phoneFormPrefix: string = 'phoneForm';

  displayTypeDefault: string = 'default';
  displayTypePortalDetail: string = 'portal-detail';
  displayTypePortalSummary: string = 'portal-summary';

  contactTypeCustomer: string = 'Customer';
  contactTypeBranch: string = 'Branch';
  contactTypeProviderLocation: string = 'Provider Location';
  contactTypeInstaller: string = 'Installer';
  contactTypeInstallerTechnician: string = 'InstallerTechnician';
  contactTypeIdCustomer: number = 1;
  contactTypeIdBranch: number = 2;
  contactTypeIdProviderLocation: number = 3;
  contactTypeIdInstaller: number = 4;

  contactMechanismCategoryPhone: string = 'Phone';
  contactMechanismCategoryEmail: string = 'Email';
  contactMechanismCategoryAddress: string = 'Address';

  eventFormGroupAdded: string = 'Form Group Added';
  
  formStatusValid: string = 'VALID';
  formStatusInValid: string = 'INVALID';
  formStatusPending: string = 'PENDING';
  formStatusDisabled: string = 'DISABLED';
  constructor() { }
}
