import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FwSharedModule } from '../../../../fw/fw-shared/fw-shared.module';
import { SharedModule } from '../../../../shared/shared.module';
import { DynamicListModule } from '../../../../fw/dynamic-list/dynamic-list.module';
import { DynamicFormModule } from '../../../../fw/dynamic-forms/dynamic-form.module';
import { DynamicDetailModule } from '../../../../fw/dynamic-detail/dynamic-detail.module';

//containers
import {  UserJobsComponent } from './containers/user-jobs.component';

//components
import {
  UserAlertComponent,
  UserJobsJobComponent, 
  JobScheduleJobParameterComponent,
  UserJobsScheduleComponent,
  UserJobsJobsComponent,
  UserAlertsComponent,
  JobScheduleJobParametersComponent,
  UserSchedulesComponent  
  } 
from './index';
import {  } from './components/user-schedules/user-schedules.component';
import {  } from './components/user-schedule/user-schedule.component';

//resolvers
import { SelectionListResolver } from '../../resolvers/index'; 

//guards
import { IsCurrentGuard } from '../../../store/services/is-current-guard.service';
import { LoadingPageComponent } from '../../../../shared/components';

export const ROUTES = [
  {
    path: 'user-jobs',
    outlet: 'modal',
    component: UserJobsComponent,
    children: [
      {
        path: 'loading/:container',
        component: LoadingPageComponent
      },
      {
        path: 'user-alerts',
        component: UserAlertsComponent,
        children: [
          {
            path: 'user-alert/:id',
            outlet: 'commonDetailOutlet', //for dynamic-list, must add to navigateDetail in  dynamic-list.component.ts
            canActivate: [IsCurrentGuard],
            data: {
              componentFields: ['jobScheduleJobSubscriptionId', 'jobName', 'scheduleName', 'alertOnlyWhenOutput']
            },
            resolve: {
              selectListsLoaded: SelectionListResolver,
            },
            component: UserAlertComponent,
          }
        ],
      },
      {
        path: 'user-jobs',
        component: UserJobsJobsComponent,
        children: [
          {
            path: 'user-job/:id',
            outlet: 'commonDetailOutlet',
            //component: UserJobsJobBinComponent,
            component: UserJobsJobComponent,
            resolve: { selectListLoaded: SelectionListResolver },
            canActivate: [IsCurrentGuard],
            //data: { tabsFileName: 'UserJobTabs' }, //must exist in tab.service and tab.settings for this object
          }
        ]
      },
      {
        path: 'user-schedules', component: UserSchedulesComponent, children: [
          {
            path: 'user-schedule/:id',
            outlet: 'commonDetailOutlet',  //for dynamic-list, must add to navigateDetail in  dynamic-list.component.ts
            data: { componentFields: ['jobScheduleId', 'isActive', 'jobScheduleName'] },
            component: UserJobsScheduleComponent,
            canActivate: [IsCurrentGuard]
          }
        ],
      }
    ]
  }, //Begin Modal Routes
  {
    path: 'job-schedule-job-parameters/:id',
    component: JobScheduleJobParametersComponent,
    outlet: 'modal2',
    children: [
      {
        path: 'job-schedule-job-parameter/:id',
        component: JobScheduleJobParameterComponent,
        canActivate: [IsCurrentGuard],
        data: {
          resolveWith: 'parentId'
        },
        resolve: { selectListLoaded: SelectionListResolver },
        outlet: 'jobScheduleJobParameterOutlet',
      }
    ]
  }

];


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FwSharedModule,
    SharedModule,
    DynamicListModule,
    DynamicDetailModule,
    DynamicFormModule,
    RouterModule.forChild(ROUTES)
  ],
  declarations: [
    UserJobsComponent,
    UserAlertsComponent,
    UserAlertComponent,
    UserJobsJobsComponent,
    UserJobsJobComponent,
    UserSchedulesComponent,
    UserJobsScheduleComponent,
    JobScheduleJobParameterComponent,
    JobScheduleJobParametersComponent
  ],
  providers: [SelectionListResolver, IsCurrentGuard],
})
export class UserJobsModule { }
