import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//components
import { EmptyComponent } from '../portal-shared/components/index';

//containers
import {
  UserDashboardComponent,
  DispatchedWorkOrdersComponent,
  OpenExternalNotesComponent,
  UnassignedExternalNotesComponent,
  OpenProjectsComponent,
  PendingClosedProjectsComponent,
  SssInvitesRequiringActionComponent,
  UndispatchedWorkOrdersComponent,
  UnreceivedInventoryComponent,
  UnscheduledWorkOrdersComponent,
  PendingTasksComponent,
  SssWorkOrderInvitationsComponent
} from './containers/index';
import {
  PurchaseOrdersSummaryContainerComponent,
  WorkOrdersSummaryContainerComponent,
  WorkOrderCallLogsComponent,
  WorkOrderNotesComponent,
  DispatchManagerComponent,
  NoteReplyManagerComponent
} from '../portal-shared/containers';

import { SchedulePortalManagerComponent } from '../scheduler/containers';
import { ProjectPurchaseOrderSyncComponent } from '../project/containers';

//guards
import { AuthGuard } from '../../../auth/services/auth-guard.service';
import { ProjectGuard } from '../project/services/project-guard.service';

//resolvers
import {
  WorkOrderInformationResolver,
  ProjectNotesResolver,
  ProjectInformationResolver} from '../project/resolvers';
import {
  MetaDataResolver,
  AllMyProviderLocationsResolver,
  CalendarWorkOrderStatusResolver,
  AllMyProviderLocationBranchesResolver,
  CommunicationShortcutResolver,
  CommunicationDocumentTypeResolver,
  DocumentTypeResolver,
  EntityResolver
} from '../resolvers';
import { SmsConversationComponent } from "../../sms/containers/sms-conversation/sms-conversation.component";
import { LoadingPageComponent } from '../../../shared/components';

const portalRoutes: Routes = [
  {
    path: 'user-dash', //componentless route 
    children: [
      {
        path: ':portalId', //not really needed at this time, but keeping so tab factory can always call the same route structure
        component: UserDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          includeReadOnly: false
        },
       resolve: {
          hasLocs: AllMyProviderLocationsResolver,
          hasLocBs: AllMyProviderLocationBranchesResolver
        },
        children: [
          {
            path: 'user-dash-empty', //verify path, result of tab component concatenation
            outlet: 'portal-detail',
            component: EmptyComponent  
          },
          {
            path: 'loading/:container',
            outlet: 'portal-detail',
            component: LoadingPageComponent
          },
          {
            path: 'open-projects',
            outlet: 'portal-detail',
            component: OpenProjectsComponent
          },
          {
            path: 'unreceived-inventory',
            outlet: 'portal-detail',
            component: UnreceivedInventoryComponent
          },
          {
            path: 'open-external-notes',
            outlet: 'portal-detail',
            component: OpenExternalNotesComponent,
            children: [
              {
                path: 'reply-to-external-note/:id',
                outlet: 'openExternalNotesOutlet',
                component: NoteReplyManagerComponent
              }
            ]
          },
          {
            path: 'unassigned-external-notes',
            outlet: 'portal-detail',
            component: UnassignedExternalNotesComponent,
            children: [
              {
                path: 'reply-to-unassigned-external-note/:id',
                data: {
                  replyLength: '200'
                },
                outlet: 'unassignedExternalNotesOutlet',
                component: NoteReplyManagerComponent
              }
            ]
          },
          {
            path: 'unscheduled-work-orders',
            outlet: 'portal-detail',
            component: UnscheduledWorkOrdersComponent
          },
          {
            path: 'undispatched-work-orders',
            outlet: 'portal-detail',
            component: UndispatchedWorkOrdersComponent
          },
          {
            path: 'dispatched-work-orders',
            outlet: 'portal-detail',
            component: DispatchedWorkOrdersComponent,
            resolve: {
              woStatuses: CalendarWorkOrderStatusResolver
            },
          },
          {
            path: 'pending-closed-projects',
            outlet: 'portal-detail',
            component: PendingClosedProjectsComponent
          },
          {
            path: 'pending-tasks',
            outlet: 'portal-detail',
            component: PendingTasksComponent
          },
          {
            path: 'sss-invites-requiring-action',  
            outlet: 'portal-detail',
            component: SssInvitesRequiringActionComponent,
            children: [
              {
                path: 'invites-by-workorder/:id',
                outlet: 'invitesByWoOutlet',
                component: SssWorkOrderInvitationsComponent
              }
            ]
          }
        ]
      }
    ]
  },
  //BEGIN MODAL route tos
  
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  
  {
    path: 'user-dash-work-order-summary/:id',
    outlet: 'modal',
    component: WorkOrdersSummaryContainerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-dash-purchase-order-summary/:id',
    outlet: 'modal',
    component: PurchaseOrdersSummaryContainerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-dash-scheduler/:id',
    outlet: 'modal',
    canActivate: [AuthGuard, ProjectGuard],
    component: SchedulePortalManagerComponent,
    resolve: {
      hasProject: ProjectInformationResolver,
      hasWo: WorkOrderInformationResolver
    },
    children: [
      {
        path: 'wo-po-sync/:id',
        outlet: 'sidebar',
        component: ProjectPurchaseOrderSyncComponent
      },
      {
        path: 'sms-convo/:id/:phone/:name/:parent',
        outlet: 'chatmodal',
        component: SmsConversationComponent,
        resolve: {
          hasShortcuts: CommunicationShortcutResolver,
          hasDocTypes: CommunicationDocumentTypeResolver,
          hasFileTypes: DocumentTypeResolver,
          hasEntities: EntityResolver
        }
      }
    ]
  },
  {  
    path: 'user-dash-work-order-call-log/:id',
    outlet: 'modal',
    component: WorkOrderCallLogsComponent,
    canActivate: [AuthGuard],
    data: {
      storeName: 'workOrderCallLogResponses',
      controller: 'projectCallLogResponse'
      //for metadataresolver
      },
      resolve: {
        hasWo: WorkOrderInformationResolver,
        hasNotes: ProjectNotesResolver,
        hasMeta: MetaDataResolver
      },
  },
  {
    path: 'user-dash-work-order-notes/:id/add',
    outlet: 'modal',
    component: WorkOrderNotesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-dash-dispatch/:id',
    outlet: 'modal',
    component: DispatchManagerComponent,
    canActivate: [AuthGuard]
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class UserDashboardRoutingModule { }
