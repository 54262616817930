import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'contact-type-specifications',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './contact-type-specifications.component.html'
})
export class ContactTypeSpecificationsComponent implements OnInit {

  public listDefinition: IListDefinition;

  constructor(
    public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService  ) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      const contactTypeId = +paramMap.get('id');
      this.newRequest(contactTypeId);
    });
  }

  newRequest(contactTypeId: number) {
    this.listDefinition = this.adminService.loadContactTypeSpecificationsListDefinition(contactTypeId);
  }

}
