

 export interface IAuth {
  userName: string;
  password: string;
  rememberMe: boolean;
  emailAddress: string ;
  returnUrl: string;
  showReset: boolean;
  isExternal: boolean;
}

export class Auth implements IAuth {
  userName: string;
  password: string;
  rememberMe: boolean;
  emailAddress: string ;
  returnUrl: string;
  showReset: boolean;
  isExternal: boolean;

  constructor(userName: string, password: string) {
    this.userName = userName;
    this.password = password;
    this.rememberMe = false;
    this.emailAddress = '';
    this.returnUrl = '';
    this.showReset = false;
    this.isExternal = false;
  }
}

 
