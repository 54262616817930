import { Component, OnInit, Inject } from '@angular/core';

import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { AdminEvent } from '../../enums/admin.enums';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IWorkCategory } from '../../../view-models';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'work-categories',
  templateUrl: './work-categories.component.html'
})
export class WorkCategoriesComponent implements OnInit {

  listDefinition: IListDefinition;

  constructor(
    public adminProviderService: AdminProviderService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants
  ) {
  }

  ngOnInit() {
    this.listDefinition = this.adminProviderService.loadWorkCategoriesListDefinition();
  }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case AdminEvent.showWorkCategoryPayouts:
        this.showWorkCategoryPayouts(event.data);
        break;
      default:
        break;
    }
  }

  showWorkCategoryPayouts(data: IWorkCategory): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: this.listDefinition.storeName,
      key: this.listDefinition.rowKeyId,
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.workCategoryId,
    }
    this.modalService.open({
      title: 'Work Category',
      path: 'work-category-detail',
      id: data.workCategoryId,
      sizeType: ModalSizeType.large,
      castExit: false,
      optionalParams: params,
      hasTabs: false,
    });
  }

}
