<div class="phls-count">
  <div class="phls-count__entity">
    <div class="phls-count__entity__label"> {{title}}</div>
    <div class="phls-count__entity__total">{{totalLabel$ | async}}</div>
  </div>
  <div class="phls-count__list">
    <fw-multi-tier-menu *ngIf="entityName !== myConstants.entityTypeNote"
                        [menu]="itemList$ | async"
                        [label]="countLabel$ | async"
                        [disabled]="!(itemList$ | async)"
                        (selected)="onSelectedItem($event)">
    </fw-multi-tier-menu>
  </div>
  <div class="phls-count__action">
    <button *ngIf="entityName !== myConstants.entityTypeAQT"
            class="app-btn-icon app-btn-icon--success"
            title="{{buttonTitle}}"
            [disabled]="!canIEdit"
            (click)="addItem()">
      <i class="far fa-plus"></i>
    </button>
    <div *ngIf="entityName === myConstants.entityTypeAQT"
         class="app-icon app-icon--primary"
         title="{{buttonTitle}}">
      <i class="fad fa-swap"></i>
    </div>
  </div>
</div>

