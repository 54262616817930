import { Component, OnInit } from '@angular/core';
import {  Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { IMenuItem } from '../interfaces/i-menu-item';
import { IScreenBreakpoints } from '../../fw-shared/services/i-screen-breakpoints';

import { MenuService } from '../services/menu.service';
import { ScreenService } from '../../fw-shared/services/screen.service';

@Component({
  selector: 'fw-app-menu',
  templateUrl: './fw-app-menu.component.html'
})

export class AppMenuComponent implements OnInit {
  subscription: Subscription = new Subscription();

  constructor(public  menuService: MenuService,
    public  screenService: ScreenService) { }

  public openLink(item: IMenuItem): void {
    if (item.subMenu) return;
    this.menuService.switchPortal(item);
  }

  ngOnInit() {
    this.subscription.add(this.screenService.resize$
      .pipe(distinctUntilChanged())
      .subscribe((e: IScreenBreakpoints) => {
        if (this.menuService.showingMiniMainMenu) {
          this.menuService.toggleVerticalNav();
        }
      })
    );
  }


}
