import { Injectable } from '@angular/core';
import {  HttpClient } from '@angular/common/http';
import { Store, select} from '@ngrx/store'; 
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable} from 'rxjs';
import { switchMap, map,  mergeMap, withLatestFrom, filter} from 'rxjs/operators';
import { cloneDeep, orderBy } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IResponseBase, IHomDictionary, HomDictionary } from '../../../../shared/interfaces/index';
import { IListDefinition, IListFilter, IOrderTerm } from '../../../../fw/dynamic-list/interfaces/index';

import {
  CreateObjectModel, UpdateObjectByIdModel, DeleteObjectModel,
  IKey, UpdateObjectCustomModel
} from '../interfaces/index';

/** Imports for custom services */
import { HomCommonUtility } from '../../../../shared/services/hom-common.utility';
import { HomDataUtility } from '../../../../shared/services/hom-data.utility';
import { DomainObjectService } from '../../../../shared/services/index';
import { UserPriviledgesService } from '../../../../auth/services';
import * as fromMetaData from '../actions/meta-data.actions';
import * as fromDynamicList from '../actions/dynamic-list.actions';
import * as fromRoot from '../../../../app/store/reducers/index';
import * as LoadingIndicatorActions from '../../../../shared/store/loadingIndicator/loadingIndicator.actions';
import * as fromAuth from '../../../../auth/store/index';

 //Actions used in these Effects that need to trigger Spinner hide/show
type ShowSpinnerTypes = 
  | fromDynamicList.GetList
  | fromDynamicList.UpdateObjectByIdList
  | fromDynamicList.UpdateObjectCustomList
  | fromDynamicList.UpdateObjectByUrlList
  | fromDynamicList.CreateObjectList
  | fromDynamicList.CreateObjectByFormList
  | fromDynamicList.UpdateObjectByFormList
  | fromDynamicList.DeleteObjectByUrlList
  | fromDynamicList.SortInMemory;

const showSpinnerActions = [
  fromDynamicList.DynamicListActionTypes.GET_LIST,
  fromDynamicList.DynamicListActionTypes.UPDATE_OBJECT_BY_ID_LIST,
  fromDynamicList.DynamicListActionTypes.UPDATE_OBJECT_CUSTOM_LIST,
  fromDynamicList.DynamicListActionTypes.UPDATE_OBJECT_BY_URL_LIST,
  fromDynamicList.DynamicListActionTypes.UPDATE_OBJECT_BYFORM_LIST,
  fromDynamicList.DynamicListActionTypes.CREATE_OBJECT_LIST,
  fromDynamicList.DynamicListActionTypes.CREATE_OBJECT_BYFORM_LIST,
  fromDynamicList.DynamicListActionTypes.DELETE_OBJECT_BY_URL_LIST,
  fromDynamicList.DynamicListActionTypes.SORT_IN_MEMORY
];

type HideSpinnerTypes =
  | fromDynamicList.SetListResults
  | fromDynamicList.UpdateCompleteList
  | fromDynamicList.CreateCompleteList
  | fromDynamicList.DeleteCompleteList
  | fromDynamicList.SetErrorReturnedList
  | fromDynamicList.SortInMemoryComplete

const hideSpinnerActions = [
  fromDynamicList.DynamicListActionTypes.SET_LIST_RESULTS,
  fromDynamicList.DynamicListActionTypes.UPDATE_COMPLETE_LIST,
  fromDynamicList.DynamicListActionTypes.CREATE_COMPLETE_LIST,
  fromDynamicList.DynamicListActionTypes.DELETE_COMPLETE_LIST,
  fromDynamicList.DynamicListActionTypes.SET_ERROR_RETURNED_LIST,
  fromDynamicList.DynamicListActionTypes.SORT_IN_MEMORY_COMPLETE
];


//using Effects so can assign actions to an observable result set
@Injectable()
export class DynamicListEffects {

  constructor(
    public http: HttpClient,
    public actions$: Actions,
    public domainObjectService: DomainObjectService,
    public utils: HomCommonUtility,
    public data: HomDataUtility,
    public rootStore: Store<fromRoot.IState>,
    public userPriviledgesService: UserPriviledgesService  ) { }

  /********************************************************************************************************************************************
   * Triggered when included in ShowSpinnerTypes/ HideSpinnerTypes - cannot use a common spinner here as lists
   * are dynamic and each need their own spinner indicator
   * either modify the spinner to have dynamic values or keep as is.
  **********************************************************************************************************************************************/
  //showSpinner$ = createEffect(() => this.actions$.pipe(
  //  ofType<ShowSpinnerTypes>(...showSpinnerActions),
  //  map(() =>  new LoadingIndicatorActions.ShowSpinner({ requestor: 'auth', id: -1 }))));

  //hideSpinner$ = createEffect(() => this.actions$.pipe(
  //  ofType<HideSpinnerTypes>(...hideSpinnerActions),
  //  map(() => new LoadingIndicatorActions.HideSpinner({ requestor: 'auth', id: -1 }))));
  
  
  showSpinner$ = createEffect(() => this.actions$.pipe(
    ofType<ShowSpinnerTypes>(...showSpinnerActions),
    map((action) => {
        //pull the store name out of the incominb payload
        const firstObj = action.payload[Object.keys(action.payload)[0]];
        const requestor =  action.payload.hasOwnProperty('listDefinition') ? action.payload['listDefinition']['storeName'] 
          : action.payload.hasOwnProperty('requestor') && action.payload['requestor'].hasOwnProperty('storeName') ? action.payload['requestor']['storeName'] 
          :  firstObj && firstObj.hasOwnProperty('storeName') ? firstObj['storeName']
          :  firstObj && firstObj.hasOwnProperty('keyData') && firstObj['keyData'].hasOwnProperty('storeName') 
                  ? firstObj['keyData']['storeName'] 
              : action.payload;
      const id = action.payload.hasOwnProperty('listDefinition') ? action.payload['listDefinition']['parentId']
        : action.payload.hasOwnProperty('requestor') && action.payload['requestor'].hasOwnProperty('id') ? action.payload['requestor']['id']
          : firstObj && firstObj.hasOwnProperty('parentId') ? firstObj['parentId']
            : firstObj && firstObj.hasOwnProperty('keyData') && firstObj['keyData'].hasOwnProperty('parentId')
              ? firstObj['keyData']['parentId']
              : -1;
        return new LoadingIndicatorActions.ShowSpinner({ requestor: requestor, id: id });
      }
  )));

  hideSpinner$ = createEffect(() => this.actions$.pipe(
    ofType<HideSpinnerTypes>(...hideSpinnerActions),
    map((action) => {
      const requestor = action.payload.hasOwnProperty('storeName') ? action.payload['storeName']
        : action.payload.hasOwnProperty('keyData') && action.payload['keyData'].hasOwnProperty('storeName') ? action.payload['keyData']['storeName']
          : action.payload;
      const id = action.payload.hasOwnProperty('parentId') ? +action.payload['parentId']
        : action.payload.hasOwnProperty('keyData') && action.payload['keyData'].hasOwnProperty('parentId') ? +action.payload['keyData']['parentId']
          : -1;
 return new LoadingIndicatorActions.HideSpinner({ requestor: requestor, id: id });
  }
  )));


  /*********************************************************************************************************************************************
  GET_LIST Effect
  **********************************************************************************************************************************************/
  listGet$ = createEffect(() => this.actions$.pipe(
    ofType<fromDynamicList.GetList>(fromDynamicList.DynamicListActionTypes.GET_LIST),
    withLatestFrom(this.rootStore.pipe(select(fromAuth.getProviderId))),
    mergeMap(([action, providerId]) => {
      const listDefinition: IListDefinition = action.payload.listDefinition;
      const listFilter: IListFilter = cloneDeep(action.payload.listFilter);
      const event: IHomEventEmitter = action.payload.event ? cloneDeep(action.payload.event) : null;
      let myProviderId = +providerId;
      let result: Observable<IResponseBase>;

      if (!this.userPriviledgesService.authenticated$.value) {
        console.log('DEV WARNING: User is not authenticated but trying to retrieve list data for store:', listDefinition.storeName, '. Check subscription for missing unsubscribe.');
        return [
          { type: fromDynamicList.DynamicListActionTypes.INITIALIZE_LIST_DATA, payload: { storeName: listDefinition.storeName, parentId: listDefinition.parentId } }
        ];
      }
      if (listDefinition.controllerMethod) {
        result = this.domainObjectService.getListByMethod(listDefinition.controllerName, listDefinition.controllerMethod, listDefinition.methodParameters, listFilter);
      } else if (listDefinition.metaUrl) {
        result = this.domainObjectService.getListByMetaUrl(listDefinition.metaUrl, listFilter);
      } else {
        result = this.domainObjectService.getListByProvider(listDefinition.controllerName, myProviderId, listFilter);
      }
        return result.pipe(
          mergeMap((response) => {
            let metaData = {};
            if (response.success) {
              metaData = response.hasOwnProperty('metaData') ? response.metaData : response.data.hasOwnProperty('metaData') ? response.data.metaData : {};
            //  if (Object.keys(metaData).length === 0 ) {
            //    console.log('DEV WARNING:  fromDynamicList.GetList and no metaData returned', response);
            //  }
            }

            const data = {
              storeName: listDefinition.storeName,
              parentId: action.payload.parentId,
              data: response.success ? response.data : [],
              listMetaData: metaData,
              errorData: response.success ? [] : response.errorData,
              error: response.success ? null : response.message,
              event: event,
              lastUpdated: new Date().getTime()
            };

            let returnArray = [];

            if (data.errorData.length) {
              returnArray.push(
                { type: fromDynamicList.DynamicListActionTypes.SET_ERROR_RETURNED_LIST, payload: data }
              );
            }
            else {
              returnArray.push(
                { type: fromDynamicList.DynamicListActionTypes.SET_LIST_FILTER, payload: { storeName: listDefinition.storeName, listFilter: listFilter, parentId: listDefinition.parentId } },
                { type: fromDynamicList.DynamicListActionTypes.SET_LIST_RESULTS, payload: data },
              );
              if (response.metaData && response.metaData.hasOwnProperty('fieldMetaData') && response.metaData.fieldMetaData) {
                returnArray.push(
                  { type: fromMetaData.GET_FIELD_DEFINITIONS, payload: { storeName: listDefinition.storeName, listDefinition: listDefinition, metaData: response.metaData } },
                );
              }
              if (action.payload.splitOnConfig) {
                returnArray.push(
                  { type: fromDynamicList.DynamicListActionTypes.SPLIT_BY_PARENT, payload: { storeName: listDefinition.storeName, splitOnConfig: action.payload.splitOnConfig } },
                );
              }
            }
            return returnArray;
          })
          //catchError(error => {
          //  const data = {
          //    storeName: listDefinition.storeName,
          //    parentId: payload.parentId,
          //    data:  null,
          //    listMetaData:  {},
          //    errorData: null,
          //    error:  'Retrieval of list data failed'
          //  };
          //  return [
          //    { type: fromDynamicList.SET_ERROR_RETURNED_LIST, payload:  data }
          //  ];
          //})
        );
      })
    ));


  /*********************************************************************************************************************************************
   *UPDATE_OBJECT_BY_ID 
   * Call an object's custom update method in the API - uses id to know what record in store to update post update.
   * @param objectType The object type whose controller implements the custom "update" method
   * @param methodName The name of the custom "update" method to call
   * @param objectData The updated object to submit as an HTTP payload
   * @param arrayName The name to give the array parameter, if domainObject is an array
  **********************************************************************************************************************************************/
  updateObject$ = createEffect(() => this.actions$.pipe(
        ofType(fromDynamicList.DynamicListActionTypes.UPDATE_OBJECT_BY_ID_LIST), //public payload: { IUpdateObjectById }
          map((action: fromDynamicList.UpdateObjectByIdList) => action.payload.updateData),
          //tap((updateData: UpdateObjectByIdModel) => {
          //   this.store.dispatch(new fromDynamicList.SetWorkingList({storeName: updateData.keyData.storeName, parentId: updateData.keyData.parentId}));
          //}),
          switchMap((updateData: UpdateObjectByIdModel ) => {
            const keyData: IKey = updateData.keyData;
            return this.domainObjectService
              .updateByMethod(updateData.objectType, updateData.methodName, updateData.objectData, updateData.arrayName)
              .pipe(
                mergeMap((data) => {
                  const returnData = { keyData: keyData, responseBase: data, event: updateData.event };
                  //action handles errors
                    return [
                      { type: fromDynamicList.DynamicListActionTypes.UPDATE_COMPLETE_LIST, payload: returnData }
                    ];
                    }))
                //catchError(error => {
                //  return [
                //    { type: fromDynamicList.SET_ERROR_RETURNED_LIST, payload:  { storeName: keyData.storeName, error: "Update record failed" } },
                //  ];
                //});
              })
            ));

  
  /*********************************************************************************************************************************************
   *UPDATE_OBJECT_CUSTOM
   * Call an object's custom update method in the API
   * @param objectType The object type whose controller implements the custom "update" method
   * @param methodName The name of the custom "update" method to call
   * @param objectData The updated object or array to submit as an HTTP payload
   * @param arrayName The name to give the array parameter, if domainObject is an array
  **********************************************************************************************************************************************/
  updateObjectCustom$ = createEffect(() => this.actions$.pipe(
    ofType(fromDynamicList.DynamicListActionTypes.UPDATE_OBJECT_CUSTOM_LIST), //public payload: { IUpdateObjectById }      
    map((action: fromDynamicList.UpdateObjectCustomList) => action.payload.updateData),
      //tap((updateData: UpdateObjectCustomModel) => {
      //  this.store.dispatch(new fromDynamicList.SetWorkingList({storeName: updateData.keyData.storeName, parentId: updateData.keyData.parentId}));
      //}),
      switchMap((updateData: UpdateObjectCustomModel ) => {
        const keyData: IKey = updateData.keyData;
        return this.domainObjectService
          .updateByMethod(updateData.objectType, updateData.methodName, updateData.objectData, updateData.arrayName)
          .pipe(
            mergeMap((data) => {
              const returnData = { keyData: keyData, responseBase: data, event: updateData.event };
              return [
                { type: fromDynamicList.DynamicListActionTypes.UPDATE_COMPLETE_LIST, payload: returnData },
              ];
            }))
            //catchError((error) => {
            //  return [
            //    { type: fromDynamicList.SET_ERROR_RETURNED_LIST, payload:  { storeName: keyData.storeName, parentId: updateData.keyData.parentId, error: "Update record failed" } },
            //  ];
            //});
      })
   ) );


  /*********************************************************************************************************************************************
   *UPDATE_OBJECT_BYFORM
   * Call an object's custom update method in the API
   * @param objectType The object type whose controller implements the custom "update" method
   * @param methodName The name of the custom "update" method to call
   * @param objectData as FormData
  **********************************************************************************************************************************************/
  updateObjectByFormCustom$ = createEffect(() => this.actions$.pipe(
    ofType(fromDynamicList.DynamicListActionTypes.UPDATE_OBJECT_BYFORM_LIST), //public payload: { IUpdateObjectById }      
    map((action: fromDynamicList.UpdateObjectByFormList) => action.payload.updateData),
    switchMap((updateData: UpdateObjectCustomModel) => {
      const keyData: IKey = updateData.keyData;
      return this.domainObjectService
        .updateByMethodWithForm(updateData.objectType, updateData.methodName, updateData.objectData)
        .pipe(
          mergeMap((data) => {
            const returnData = { keyData: keyData, responseBase: data, event: updateData.event };
            return [
              { type: fromDynamicList.DynamicListActionTypes.UPDATE_COMPLETE_LIST, payload: returnData },
            ];
          }))
    })
  ));

  /*********************************************************************************************************************************************
    CREATE_OBJECT Effect
   * @param objectType The object type whose controller implements the custom "update" method
   * @param methodName The name of the custom "update" method to call
   * @param objectData The updated object to submit as an HTTP payload
   * @param arrayName The name to give the array parameter, if domainObject is an array
  **********************************************************************************************************************************************/
  createObject$ = createEffect(() => this.actions$.pipe(
    ofType(fromDynamicList.DynamicListActionTypes.CREATE_OBJECT_LIST), //public payload: { CreateObjectModel }
   map((action: fromDynamicList.CreateObjectList) => action.payload.createData),
    //tap((createData: CreateObjectModel) => {
    //  this.store.dispatch(new fromDynamicList.SetWorkingList({storeName: createData.storeName, parentId: createData.parentId}));
    //}),
     switchMap((createData: CreateObjectModel) => {
           return this.domainObjectService
             .createByMethod(createData.objectType, createData.methodName, createData.objectData, createData.arrayName)
             .pipe(
               mergeMap((data) => {
                 const returnData = { storeName: createData.storeName,parentId: createData.parentId, responseBase: data, event: createData.event };
                 return [
                   { type: fromDynamicList.DynamicListActionTypes.CREATE_COMPLETE_LIST, payload: returnData },
                 ];
               }))
               //catchError((error) => {
               //   return [
               //     { type: fromDynamicList.SET_ERROR_RETURNED_LIST, payload:  { storeName: createData.storeName, parentId: createData.parentId, error: "Create record failed" } },
               //   ];
               //});
      })
   ) );

  /*********************************************************************************************************************************************
    CREATE_OBJECT Effect
   * @param objectType The object type whose controller implements the custom "update" method
   * @param methodName The name of the custom "update" method to call
   * @param objectData The updated object to submit as an HTTP payload
   * @param arrayName The name to give the array parameter, if domainObject is an array
  **********************************************************************************************************************************************/
  createObjectByForm$ = createEffect(() => this.actions$.pipe(
    ofType(fromDynamicList.DynamicListActionTypes.CREATE_OBJECT_BYFORM_LIST), //public payload: { CreateObjectModel }
    map((action: fromDynamicList.CreateObjectByFormList) => action.payload.createData),
    switchMap((createData: CreateObjectModel) => {
      return this.domainObjectService
        .createByMethodWithForm(createData.objectType, createData.methodName, createData.objectData)
        .pipe(
          mergeMap((data) => {
            const returnData = { storeName: createData.storeName, parentId: createData.parentId, responseBase: data, event: createData.event };
            return [
              { type: fromDynamicList.DynamicListActionTypes.CREATE_COMPLETE_LIST, payload: returnData },
            ];
          }))
    })
  ));
  /*********************************************************************************************************************************************
    DELETE_OBJECT_BY_URL Effect
   @param url to call to delete - will include the id in it.  metaData.crud.deleteUrl
  **********************************************************************************************************************************************/
  deleteObjectByUrl$ = createEffect(() => this.actions$.pipe(
      ofType(fromDynamicList.DynamicListActionTypes.DELETE_OBJECT_BY_URL_LIST) ,
        map((action: fromDynamicList.DeleteObjectByUrlList) => action.payload.deleteData),
        switchMap((deleteData: DeleteObjectModel) => {
          // Strip and reformat the domainObject so that it's valid to be passed as an HTTP payload.
          return this.domainObjectService
            .delete(deleteData.url)
            .pipe(
              mergeMap((data) => {
                const returnData = { keyData: deleteData.keyData, responseBase: data, event: deleteData.event };
                return [
                  { type: fromDynamicList.DynamicListActionTypes.DELETE_COMPLETE_LIST, payload: returnData },
                ];
              }))
              //catchError((error) => {
              //  return [
              //    { type: fromDynamicList.SET_ERROR_RETURNED_LIST, payload:  { storeName: deleteData.keyData.storeName, parentId: deleteData.keyData.parentId, error: "Delete record failed" } },
              //  ];
              //});
      })
  ));
    
  /*********************************************************************************************************************************************
   *CHECK_FOR_STALE_DATA 
   * Check to see if the parent entity is stale and return the result to the store to handle
  **********************************************************************************************************************************************/
  checkForStale$ = createEffect(() => this.actions$.pipe(
        ofType<fromDynamicList.CheckForStaleData>(fromDynamicList.DynamicListActionTypes.CHECK_FOR_STALE_DATA_LIST), 
          mergeMap((action) => {
              let params: IHomDictionary[] = [];
                params.push(new HomDictionary('entityId',  action.payload.entityId.toString() ));
                params.push(new HomDictionary('pkId', action.payload.parentId.toString() ));
                params.push(new HomDictionary('rowVersion', ''));
                params.push(new HomDictionary('aggregateRootVersion', action.payload.aggregateRootVersion));

              return this.domainObjectService.getByMethodParams('Entity', 'IsCurrent', params)
                .pipe(
                  filter(Boolean),
                  mergeMap((result) => {
                      return [
                        {
                          type: fromDynamicList.DynamicListActionTypes.SET_STALE_DATA_CHECK_RESULT_LIST,
                          payload: {
                            storeName: action.payload.storeName,
                            parentId: action.payload.parentId,
                            forRow: false,
                            responseBase: result
                          }
                        }
                      ]
                    }
                  ))
            })
  ));

  /*********************************************************************************************************************************************
    SORT_IN_MEMORY Effect
 
**********************************************************************************************************************************************/
  listSortInMemory$ = createEffect(() => this.actions$.pipe(
    ofType<fromDynamicList.SortInMemory>(fromDynamicList.DynamicListActionTypes.SORT_IN_MEMORY),
    mergeMap((action) => {
      let sortData: any[] = cloneDeep(action.payload.data)
      const terms: IOrderTerm[] = cloneDeep(action.payload.listFilter.orderTerm);
      const keys: string[] = terms.map(x => x.term);
      const directions: any[] = terms.map(x => x.orderAsc ? 'asc' : 'desc');
      let flatKeys: string[] = [];
      //term must be in data
      keys.forEach(x => {
        const splitKey: string[] = x.split('_');
        flatKeys.push(splitKey[splitKey.length - 1]);
      })

      const data = {
        storeName: action.payload.listDefinition.storeName,
        parentId: action.payload.listDefinition.parentId,
        listFilter: action.payload.listFilter,
        event: action.payload.event,
        data: orderBy(sortData, flatKeys, directions)
      };
      return [
        { type: fromDynamicList.DynamicListActionTypes.SORT_IN_MEMORY_COMPLETE, payload: data }
      ];
    })
  ));

}

