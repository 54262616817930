import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { UserRecentsService } from '../services/user-recents.service';
import { UserRecentEvent } from '../enums/user-recents.enums';

@Component({
  selector: 'user-recents',
  templateUrl: './user-recents.component.html'
})

export class UserRecentsComponent {
  constructor(public userRecentsService: UserRecentsService) { }

  subscription: Subscription = new Subscription();

  public mobileTitle: string = null;

  public showUserRecentsList(title: string = null): void {
    this.mobileTitle = title;
  }

  public customEvent(event: IHomEventEmitter): void {
    switch (event.event) {
      case UserRecentEvent.delete:
        this.userRecentsService.deleteUserRecent(event.data);
        break;
      case UserRecentEvent.openTab:
        this.userRecentsService.openUserRecentTab(event.data);
        break;
      default:
        return;
    }
  }

}
