  <div *ngIf="form" class="po-import-header-summary">
    <form [formGroup]="form" novalidate class="form-wrapper">
      <div class="po-import-header-summary__key">
        <fw-dynamic-field [field]="mds.getFieldDefinition('branchName')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('vendorOrderNumber')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('owner')"
                          [form]="form"
                          [operation]="operation"
                          [selectItems]="owners">
        </fw-dynamic-field>
      </div>
      <div class="po-import-header-summary__name">
        <fw-dynamic-field [field]="mds.getFieldDefinition('customerFirstName')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('customerLastName')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
      </div>
      <div class="po-import-header-summary__addr">
        <div class="po-import-header-summary__addr__lines">
          <fw-dynamic-field [field]="mds.getFieldDefinition('addressLine1')"
                            [form]="form"
                            [operation]="operation">
          </fw-dynamic-field>
          <fw-dynamic-field [field]="mds.getFieldDefinition('addressLine2')"
                            [form]="form"
                            [operation]="operation">
          </fw-dynamic-field>
        </div>
        <div class="po-import-header-summary__addr__csz">
          <fw-dynamic-field [field]="mds.getFieldDefinition('addressCity')"
                            [form]="form"
                            [operation]="operation">
          </fw-dynamic-field>
          <fw-dynamic-field [field]="mds.getFieldDefinition('addressState')"
                            [form]="form"
                            [operation]="operation">
          </fw-dynamic-field>
          <fw-dynamic-field [field]="mds.getFieldDefinition('addressZip')"
                            [form]="form"
                            [operation]="operation">
          </fw-dynamic-field>
        </div>
      </div>
    </form>
  </div>
