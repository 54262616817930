import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DevComponent } from './dev.component';

@NgModule({
  imports: [
    RouterModule.forChild([{
      path: 'dev', 
      component: DevComponent
    }])
  ],
  exports: [RouterModule]
}) export class DevRoutingModule {}
