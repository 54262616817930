import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { IContact } from '../../../../app/portals/view-models/i-contact';
import { IScheduleInstallerWorkCrewAvailabilityViewModel } from "../../../../app/portals/view-models/i-schedule-installer-work-crew-availability-view-model";
import { IScheduleInstallerWorkCrewUnavailabilityViewModel } from "../../../../app/portals/view-models/i-schedule-installer-work-crew-unavailability-view-model";
import { ISchedule } from "../../../../app/portals/view-models/i-schedule";
import { CommunicationPreference } from '../../../../app/contact/enums/contact.enums';
import { CommunicationEventService } from '../../../../app/portals/portal-shared/services/communication-event.service';
import { UserPriviledgesService } from '../../../../auth/services';

@Component({
  selector: 'fw-communication-preference',
  templateUrl: './fw-communication-preference.component.html'
})
export class CommunicationPreferenceComponent implements OnInit, OnChanges {
  @Input() row: IContact
    | IScheduleInstallerWorkCrewAvailabilityViewModel
    | IScheduleInstallerWorkCrewUnavailabilityViewModel
    | ISchedule;
  @Input() displayType: string;
  @Input() cssClass: string = '';
  @Input() allowText: boolean = true;
  @Input() forHighLevelSummary: boolean;

  public forPhoneCall: boolean = false;
  public forTexting: boolean = false;
  public forEmail: boolean = false;
  public onlyIcon: boolean = false;
  public enabled: boolean = true;

  constructor(
    public userPriviledgesService: UserPriviledgesService,
    public communicationEventService: CommunicationEventService) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['row'] && !(changes['row'].firstChange) ||
      changes['displayType'] && !(changes['displayType'].firstChange)) {
      this.initForm();
    }
  }

  initForm(): void {
    this.forTexting = this.forPhoneCall = this.forEmail = false;
    this.onlyIcon = this.displayType === this.communicationEventService.displayTypeIcon;

    if (!this.row.hasOwnProperty('communicationPreferenceName') || !this.row.hasOwnProperty('communicationPreferenceValue')) {
      return;
    }

    switch (this.row.communicationPreferenceName) {
      case CommunicationPreference.email:
        this.forEmail = true;
        break;
      case CommunicationPreference.phone:
        this.forPhoneCall = true;
        break;
      case CommunicationPreference.sms:
        this.forTexting = true;
        break;
      default:
        break;
    }
  }

  setEnabled(): void {
    const contactId: number = this.row.hasOwnProperty('contact_id') ? this.row.contactId
      : this.row.hasOwnProperty('contact_contactId') ? this.row['contact_contactId'] : 0;
    this.enabled = contactId > 0;
    console.log('enabled', this.enabled);
  }
}
