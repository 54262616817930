import { Component, OnInit, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { AdminSssService } from '../../services/admin-sss.service';

@Component({
  selector: 'sss-program-services',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sss-program-services.component.html'
})
export class SSSProgramServicesComponent implements OnInit {
  public listDefinition: IListDefinition;
  params: IDetailRouteParameter;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminSssService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadSSSProgramServicesListDefinition();
  }
}
