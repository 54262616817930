import { createSelector} from '@ngrx/store';

import * as fromRoot from '../../../app/store/reducers/root.reducer';
import * as fromSelectionLists from './selectionLists.reducer';

export const getAllLists = createSelector(
  fromRoot.getSelectionListsState,
  allStores => allStores
);

export const getSelectionListByType = (type: string) => {
  return createSelector(
    fromRoot.getSelectionListsState,
    (state: fromSelectionLists.IStoreState) => state[type]);
}

export const getSelectionListByTypeByParent = (type: string, parentId) => {
  return createSelector(
    getSelectionListByType(type),
    (list: fromSelectionLists.ISelectionList) => list && list.objData ? list.objData.find(x => x.parentId === parentId) : null);
}

export const getSelectionListDataByType = (type: string, parentId: number = -1) => {
  return createSelector(
    fromRoot.getSelectionListsState,
    (state: fromSelectionLists.IStoreState) => state[type] === undefined || state[type].objData === null
      ? null
      : state[type].objData.find(x => x.parentId === parentId)
        ? state[type].objData.find(x => x.parentId === parentId).data
        : null);
}

export const listExistsByType = (type: string, parentId: number = -1) => createSelector(
  getSelectionListDataByType(type, parentId),
    (data: any[]) => {
      return data && data.length > 0 ? true : false;
  });

export const listLoadedByType = (type: string, parentId: number = -1) => createSelector(
  getSelectionListDataByType(type, parentId),
  (data: any[]) => {
    return data ? true : false;
  });

