export interface IProviderLocationService {
  providerLocationServiceId: number;
  locationName:string;
  providerLocation_providerLocationId: number;
  serviceName: string;
  service_serviceId: number;
  accountingLocationId: number;
  active: boolean;
  slotBuffer: number;
  keyRecTime: number;
  displayName: string;
  entityLabel: string;
  entityId: number;
  rowVersion: string;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;
}


export class ProviderLocationService implements IProviderLocationService {

  constructor(
    public providerLocationServiceId:number = 0,
    public locationName = "",
    public providerLocation_providerLocationId: number = 0,
    public serviceName = "",
    public service_serviceId: number = 0,
    public accountingLocationId: number = 0,
    public active: boolean = true,
    public slotBuffer: number = 0,
    public keyRecTime: number = 0,
    public displayName = "",
    public entityLabel = "",
    public entityId: number = 0,
    public rowVersion: string = "",
    public createDate: string = "", //example: "/Date(1306380319000)/",
    public updateDate: string = "",
    public createdBy: number = 0,
    public updatedBy: number = 0,
  ) {
  }

}
