<div class="admin-title-bar">
  <div class="admin-title-bar__title">{{ title }}s</div>
  <div class="admin-title-bar__info">
    <fw-info [infoTitle]="(title + ' Helper')">
      {{ info }}
    </fw-info>
  </div>
  <div class="admin-title-bar__btn">
    <div class="user-access-create-cast" *ngIf="inCreateMode$ | async"></div>
    <ng-template #userAccessAdd>
      <div class="user-access-create-popover">
        <user-access-create-items *ngIf="inCreateMode$ | async"
                                  [title]="title"
                                  [currentAccess]="currentAccess"
                                  [specifierType]="specifierType"
                                  [isWorking]="workingInCreate$ | async"
                                  (customEvent)="onCustom($event)">
        </user-access-create-items>
      </div>
    </ng-template>

    <button *ngIf="showBtns" type="button"
            class="app-btn-icon app-btn-icon--success"
            fw-popover
            [template]="userAccessAdd"
            [closeOnClickOutside]="false"
            [showPopover]="(inCreateMode$ | async)"
            [disabled]="disabled"
            (click)="showCreate()"
            title="Add {{ title }} To User">
      <i class="fas fa-plus "></i>
    </button>
  </div>
</div>

