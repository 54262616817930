import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { AdminStore } from '../../enums/admin.enums';
import { IListObjectData } from '../../../../../fw/dynamic-list/store/index';
import { ISelectionList } from '../../../../../shared/store/selectionLists/index';
import { IWorkCategory, IWorkCategoryPayout, WorkCategoryPayout, IPayout } from '../../../view-models';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { ListFilter } from '../../../../../fw/dynamic-list/interfaces';

import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';

import { MetaDataService } from '../../../../../fw/dynamic-list/services';
import { DomainObjectService } from '../../../../../shared/services';
import { IResponseBase } from '../../../../../shared/interfaces';

@Component({
  selector: 'work-category-payout',
  templateUrl: './work-category-payout.component.html'
})
export class WorkCategoryPayoutComponent implements OnInit, OnDestroy {

  public errors: string;
  public payouts: IPayout[];
  public selectedPayouts$: BehaviorSubject<{ payoutName: string, payoutId: number }[]> = new BehaviorSubject([]);
  public form: FormGroup;
  workCategoryId: number;
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public mds: MetaDataService,
    public selStore: Store<fromSelectionLists.IStoreState>,
    public dos: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit() {
    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.workCategoryId = +paramMap.get('id');
    });
    this.mds.setFieldDefinitions(AdminStore.workCategoryPayouts);
    this.form = this.mds.loadDynamicFormGroup(['payout'], new WorkCategoryPayout(), this.myConstants.operationTypeCreate);
    this.getWorkCategoryPayouts();
    this.getWorkCategoryUnitType();
    this.listenForStoreEvents();
  }

  public removeItem(payoutId: number): void {
    const selectedPayouts = this.selectedPayouts$.value;
    this.selectedPayouts$.next(selectedPayouts.filter(x => x.payoutId !== payoutId));
  }

  public selectEvent(payout: IPayout): void {
    if (typeof payout === 'object') {
      let selectedPayouts = this.selectedPayouts$.value;
      if (!selectedPayouts.find(x => x.payoutId === payout.payoutId)) selectedPayouts.push({ payoutName: payout.payoutName, payoutId: payout.payoutId });
      this.selectedPayouts$.next(selectedPayouts);
    }
  }

  public onCreate() {
    const obj = { workCategoryId: this.workCategoryId, payoutIds: this.selectedPayouts$.value.map(x => x.payoutId) };
    const emitter: IHomEventEmitter = { requestor: 'work-category-payout-detail', event: this.myConstants.emitterEventCreate, action: '', data: null };
    const createData = new CreateObjectModel(AdminStore.workCategoryPayouts, -1, 'WorkCategoryPayout', 'CreateBulk', obj, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  public onCancel() {
    const emitter: IHomEventEmitter = { requestor: 'work-category-payout-detail', event: this.myConstants.emitterEventListReload, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
  }

  getWorkCategoryPayouts(): void {
    const listFilter = new ListFilter();
    listFilter.getAll = true;
    this.dos.getByMethodById('WorkCategoryPayout', 'ByWorkCategory', this.workCategoryId, listFilter).subscribe((response: IResponseBase) => {
      if (response.success) {
        let selectedPayouts = [];
        response.data.forEach((item: IWorkCategoryPayout) => {
          selectedPayouts.push({ payoutName: item.payoutName, payoutId: item.payout_payoutId });
        });
        this.selectedPayouts$.next(selectedPayouts);
      } else {
        this.errors = response.errorData[0].value[0];
      }
    });
  }

  getPayouts(unitMeasureId: number): void {
    this.subscription.add(this.selStore.pipe(
      select(fromSelectionLists.getSelectionListByType('payout')),
      take(1))
      .subscribe((data: ISelectionList) => {
        this.payouts = data.objData[0].data.filter(x => x.unitMeasure_unitMeasureId === unitMeasureId);
      }));
  }

  getWorkCategoryUnitType(): void {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(AdminStore.workCategories, -1, 'workCategoryId', this.workCategoryId)), take(1))
      .subscribe((workCategory: IWorkCategory) => {
        this.getPayouts(workCategory.unitMeasure_unitMeasureId);
      })
    );
  }

  listenForStoreEvents(): void {
    this.subscription.add(this.store.pipe(select(fromDynamicList.getEntityListByParentId(AdminStore.workCategoryPayouts, -1)))
      .subscribe((state: IListObjectData) => {
        if (state && state.working) {
          return;
        }
        if (state && state.data) {
          if (!state.errorData || !state.errorData.length) {
            if (state.event) {
              this.onCancel();
            }
          } else {
            this.errors = state.errorData[0].value[0];
          }
        }
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
