<div class="portal" [ngClass]="{ 'loading-mask-no-spinner': loading$ | async }">
  <div *ngIf="(listDefinition$ | async) as listDefinition" class="portal__detail--item">
    <fw-button-title-bar [title]="'Chargeback Summary'"
                         [operation]="myConstants.operationTypeDetails"
                         [showEdit]="false"
                         [showRefresh]="true"
                         [showDelete]="false"
                         [includeBorder]="false"
                         (customEvent)="refresh($event)">
    </fw-button-title-bar>
    <fw-section-title title="Chargeback Counts" [underline]="true"></fw-section-title>
    <dashboard-counts *ngIf="(dashCounts$ | async) as dashCounts"
                      [dashCounts]="dashCounts"
                      [errorData]="errorData$ | async"
                      [errorMessage]="errorMessage"
                      [openTab]="false"
                      [isVertical]="false"
                      (clicked)="countClicked($event)">
    </dashboard-counts>
  </div>
  <div *ngIf="(listDefinition$ | async) as listDefinition" class="portal__detail--item">
    <fw-dynamic-list *ngIf="listDefinition.storeName === 'dashChargeBacks'"
                      [listTitle]="title"
                      [vmListDefinition]="listDefinition$ | async"
                      (customEvent)="onCustom($event)">
    </fw-dynamic-list>
    <fw-dynamic-list *ngIf="listDefinition.storeName === 'dashUnAckChargeBacks'"
                      [listTitle]="title"
                      [vmListDefinition]="listDefinition$ | async"
                      (customEvent)="onCustom($event)">
    </fw-dynamic-list>
    <fw-dynamic-list *ngIf="listDefinition.storeName === 'dashOnHoldChargeBacks'"
                      [listTitle]="title"
                      [vmListDefinition]="listDefinition$ | async"
                      (customEvent)="onCustom($event)">
    </fw-dynamic-list>
    <fw-dynamic-list *ngIf="listDefinition.storeName === 'dashAckUnSubmitChargeBacks'"
                      [listTitle]="title"
                      [vmListDefinition]="listDefinition$ | async"
                      (customEvent)="onCustom($event)">
    </fw-dynamic-list>
  </div>
</div>
