import { Injectable } from '@angular/core';

import { IWidgetManager } from '../../portal/interfaces';
import { IAdminNavItem, IAdminNavSubItem } from '../interfaces/index';

import { UserPriviledgesService } from '../../../../auth/services';

@Injectable()
export class AdminService {

  constructor(public userPriviledgesService: UserPriviledgesService ) {}

  getAdminNavItems(): IAdminNavItem[] {
    let items: IAdminNavItem[] = [
      {
        label: 'General Contractor Configurations',
        icon: 'fas fa-industry-alt',
        canAccess: false,
        expanded: false,
        subItems: this.loadGCConfigItems()
      },
      {
        label: 'Pricing Administration',
        icon: 'fas fa-usd-square',
        canAccess: false,
        expanded: false,
        subItems: this.loadPricingItems()
      },
      {
        label: 'Provider Configurations',
        icon: 'fas fa-cogs',
        canAccess: false,
        expanded: false,
        subItems: this.loadProviderConfigItems()
      },
      {
        label: 'Security Administration',
        icon: 'fas fa-lock',
        canAccess: false,
        expanded: false,
        subItems: this.loadSecurityItems()
      },
      {
        label: 'SSS Configurations',
        icon: 'fas fa-hand-pointer',
        canAccess: false,
        expanded: false,
        subItems: this.loadSSSItems()
      }
    ];

    //update parent canAccess based on  at least one child having access
    items.forEach((item: IAdminNavItem) => {
      const firstItem: IAdminNavSubItem = item.subItems ? item.subItems.find(x => x.canAccess) : null;
      item.canAccess = firstItem ? true : false;
    });

    //TBD:  LATER
    //going to need to grab saved tabs and set that one expanded or, if no saved tabs, grab the tab def and set the default tab's parent to expanded
    return items;
  }

  hasComponentAccess(componentName: string): boolean {
    const myWidgets: IWidgetManager[] = this.userPriviledgesService.myWidgets$.value;
    const match: number = myWidgets.findIndex(x => x.componentName === componentName);
    return match > -1;
  }

  loadPricingItems(): IAdminNavSubItem[] {
    return [
      {
        label: 'Charge Back Skus',
        icon: '',
        canAccess: this.hasComponentAccess('ChargeBackSkusComponent'),
        componentName: 'ChargeBackSkusComponent'
      },
      {
        label: 'Provider Payouts',
        icon: '',
        canAccess: this.hasComponentAccess('ProviderPayoutsComponent'),
        componentName: 'ProviderPayoutsComponent'
      },
      {
        label: 'General Contractor Skus',
        icon: '',
        canAccess: this.hasComponentAccess('GeneralContractorSkusComponent'),
        componentName: 'GeneralContractorSkusComponent'
      }
    ];
  }

  loadProviderConfigItems(): IAdminNavSubItem[] {
    return [
      {
        label: 'Charge Back Types',
        icon: '',
        canAccess: this.hasComponentAccess('ChargeBackTypesComponent'),
        componentName: 'ChargeBackTypesComponent'
      },
      {
        label: 'Communication Event Types',
        icon: '',
        canAccess: this.hasComponentAccess('CommunicationEventTypesComponent'),
        componentName: 'CommunicationEventTypesComponent'
      },
      {
        label: 'Communication Event Feedbacks',
        icon: '',
        canAccess: this.hasComponentAccess('CommunicationEventFeedbacksComponent'),
        componentName: 'CommunicationEventFeedbacksComponent'
      },
      {
        label: 'Contact Types',
        icon: '',
        canAccess: this.hasComponentAccess('ContactTypesComponent'),
        componentName: 'ContactTypesComponent'
      },
      {
        label: 'Communication Document Types',
        icon: '',
        canAccess: this.hasComponentAccess('CommunicationDocumentTypesComponent'),
        componentName: 'CommunicationDocumentTypesComponent'
      },
      {
        label: 'Communication Shortcuts',
        icon: '',
        canAccess: this.hasComponentAccess('CommunicationShortcutsComponent'),
        componentName: 'CommunicationShortcutsComponent'
      },
      {
        label: 'Contact Mechanism Categories',
        icon: '',
        canAccess: this.hasComponentAccess('ContactMechanismCategoriesComponent'),
        componentName: 'ContactMechanismCategoriesComponent'
      },
      {
        label: 'Customer Flags',
        icon: '',
        canAccess: this.hasComponentAccess('CustomerFlagsComponent'),
        componentName: 'CustomerFlagsComponent'
      },
      {
        label: 'Document Types',
        icon: '',
        canAccess: this.hasComponentAccess('DocumentTypesComponent'),
        componentName: 'DocumentTypesComponent'
      },
      {
        label: 'Installer Scoring',
        icon: '',
        canAccess: this.hasComponentAccess('InstallerScoringComponent'),
        componentName: 'InstallerScoringComponent'
      },
      {
        label: 'Inventory Provider Manager',
        icon: '',
        canAccess: this.hasComponentAccess('InventoryProviderManagerComponent'),
        componentName: 'InventoryProviderManagerComponent'
      },
      {
        label: 'Lookup Types',
        icon: '',
        canAccess: this.hasComponentAccess('LookupTypesComponent'),
        componentName: 'LookupTypesComponent'
      },
      {
        label: 'Measure Services',
        icon: '',
        canAccess: this.hasComponentAccess('MeasureServicesComponent'),
        componentName: 'MeasureServicesComponent'
      },
      {
        label: 'Provider Locations',
        icon: '',
        canAccess: this.hasComponentAccess('ProviderLocationsComponent'),
        componentName: 'ProviderLocationsComponent'
      },
      {
        label: 'Schedule Exclusions',
        icon: '',
        canAccess: this.hasComponentAccess('ScheduleExclusionsComponent'),
        componentName: 'ScheduleExclusionsComponent'
      },
      {
        label: 'Self Service Scheduling',
        icon: '',
        canAccess: this.hasComponentAccess('SelfServiceSchedulingComponent'),
        componentName: 'SelfServiceSchedulingComponent'
      },
      {
        label: 'Service Groups',
        icon: '',
        canAccess: this.hasComponentAccess('ServiceGroupsComponent'),
        componentName: 'ServiceGroupsComponent'
      },
      {
        label: 'Surveys',
        icon: '',
        canAccess: this.hasComponentAccess('SurveysComponent'),
        componentName: 'SurveysComponent'
      },
      {
        label: 'Survey Question Data Types',
        icon: '',
        canAccess: this.hasComponentAccess('SurveyQuestionDataTypesComponent'),
        componentName: 'SurveyQuestionDataTypesComponent'
      },
      {
        label: 'Warranty Types',
        icon: '',
        canAccess: this.hasComponentAccess('WarrantyTypesComponent'),
        componentName: 'WarrantyTypesComponent'
      },
      {
        label: 'Work Categories',
        icon: '',
        canAccess: this.hasComponentAccess('WorkCategoriesComponent'),
        componentName: 'WorkCategoriesComponent'
      },
      {
        label: 'Zones',
        icon: '',
        canAccess: this.hasComponentAccess('ZonesComponent'),
        componentName: 'ZonesComponent'
      }
    ];
  }

  loadSecurityItems(): IAdminNavSubItem[] {
    return [
      {
        label: 'Privileges',
        icon: '',
        canAccess: this.hasComponentAccess('PrivilegesComponent'),
        componentName: 'PrivilegesComponent'
      },
      {
        label: 'Privilege Filters',
        icon: '',
        canAccess: this.hasComponentAccess('PrivilegeFiltersComponent'),
        componentName: 'PrivilegeFiltersComponent'
      },
      {
        label: 'Privilege Templates',
        icon: '',
        canAccess: this.hasComponentAccess('PrivilegeTemplatesComponent'),
        componentName: 'PrivilegeTemplatesComponent'
      },
      {
        label: 'Roles',
        icon: '',
        canAccess: this.hasComponentAccess('RolesComponent'),
        componentName: 'RolesComponent'
      },
      {
        label: 'User Security',
        icon: '',
        canAccess: this.hasComponentAccess('UserSecurityComponent'),
        componentName: 'UserSecurityComponent'
      }
    ];
  }

  loadGCConfigItems(): IAdminNavSubItem[] {
    return [
      {
        label: 'General Contractors',
        icon: '',
        canAccess: this.hasComponentAccess('GeneralContractorsComponent'),
        componentName: 'GeneralContractorsComponent'
      },
      {
        label: 'Branches',
        icon: '',
        canAccess: this.hasComponentAccess('BranchesComponent'),
        componentName: 'BranchesComponent'
      },
      {
        label: 'Programs',
        icon: '',
        canAccess: this.hasComponentAccess('ProgramsComponent'),
        componentName: 'ProgramsComponent'
      },
      {
        label: 'Program Services',
        icon: '',
        canAccess: this.hasComponentAccess('ProgramServicesComponent'),
        componentName: 'ProgramServicesComponent'
      },
      {
        label: 'Regions',
        icon: '',
        canAccess: this.hasComponentAccess('RegionsComponent'),
        componentName: 'RegionsComponent'
      },
      {
        label: 'Services',
        icon: '',
        canAccess: this.hasComponentAccess('ServicesComponent'),
        componentName: 'ServicesComponent'
      }
    ];
  }

  loadSSSItems(): IAdminNavSubItem[] {
    return [
      {
        label: 'General Contractors',
        icon: '',
        canAccess: this.hasComponentAccess('SSSGeneralContractorsComponent'),
        componentName: 'SSSGeneralContractorsComponent'
      },
      {
        label: 'Programs',
        icon: '',
        canAccess: this.hasComponentAccess('SSSProgramsComponent'),
        componentName: 'SSSProgramsComponent'
      },
      {
        label: 'Program Services',
        icon: '',
        canAccess: this.hasComponentAccess('SSSProgramServicesComponent'),
        componentName: 'SSSProgramServicesComponent'
      },
      {
        label: 'PS Branches',
        icon: '',
        canAccess: this.hasComponentAccess('SSSProgramServiceBranchesComponent'),
        componentName: 'SSSProgramServiceBranchesComponent'
      }
    ];
  }

}
