import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IRegion } from '../../../view-models';
import { AdminStore, AdminEvent } from '../../enums/admin.enums';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'regions',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './regions.component.html'
})
export class RegionsComponent implements OnInit {

  public listDefinition: IListDefinition;
  generalContractorId: number = 0;
  params: IDetailRouteParameter;

  constructor(public activeRoute: ActivatedRoute,
    public userPriviledgesService: UserPriviledgesService,
    public adminService: AdminProviderService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.generalContractorId = +paramMap.get('id');
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadRegionsListDefinition(this.generalContractorId);
    this.params = {
      rowIndex: -1,
      storeName: AdminStore.regions,
      key: 'regionId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: this.generalContractorId > 0 ? this.generalContractorId : -1
    }
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.manageRegionBranches:
        this.openRegionBranches(event.data);
        break;
      default:
        break;
    }
  }

  openRegionBranches(r: IRegion): void {
    this.modalService.open({
      title: 'Branches for Region'.concat(' ', r.regionName),
      path: 'region-branches',
      id: r.regionId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }

}
