import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../auth/services';
import { SmsConversationComponent, SmsManagerComponent, SmsProjectSummaryComponent } from "./containers";
import { SmsEmptyComponent } from "./components";
import { PortalContactManagerComponent } from '../contact/components';
import { ContactMetaDataResolver, ContactPhoneMetaDataResolver, ContactEmailMetaDataResolver, ContactAddressMetaDataResolver, ContactTypeSpecificationResolver } from '../contact/resolvers';
import { ProjectInformationResolver } from '../portals/project/resolvers';
import { ProjectGuard } from '../portals/project/services/project-guard.service';
import {
  ProviderLocationResolver,
  AllMyProviderLocationBranchesResolver,
  CommunicationShortcutResolver,
  CommunicationDocumentTypeResolver,
  DocumentTypeResolver,
  EntityResolver
} from '../portals/resolvers';
import { LoadingPageComponent } from '../../shared/components';


const smsRoutes: Routes = [
  {
    path: 'all-sms-convos', //componentless route 
    children: [
      {
        path: ':portalId',
        component: SmsManagerComponent,
        canActivate: [AuthGuard],
        resolve: {
          hasLocs: ProviderLocationResolver,
          hasLocBs: AllMyProviderLocationBranchesResolver
        },
       children: [
          {
           path: 'sms-convo/:id/:phone/:name/:parent/:noclose',
            outlet: 'chat',
            component: SmsConversationComponent,
            data: {
              isMgr: 'true'
            },
            resolve: {
              hasShortcuts: CommunicationShortcutResolver,
              hasDocTypes: CommunicationDocumentTypeResolver,
              hasFileTypes: DocumentTypeResolver,
              hasEntities: EntityResolver
            }
         },
          {
            path: 'sms-convo-empty',
            outlet: 'chat',
            component: SmsEmptyComponent
          },
          {
            path: 'sms-contact-empty',
            outlet: 'infobar',
            component: SmsEmptyComponent
          },
          {
            path: 'sms-project-empty',
            outlet: 'projbar',
            component: SmsEmptyComponent
          },
          {
            path: 'sms-installer-mgr/:id',
            outlet: 'infobar',
            data: {
              contactTypeId: '4',
              parent: 'sms'
            },
            title: 'sms-installer-mgr',
            component: PortalContactManagerComponent,
            resolve: {
              hasContactMetaData: ContactMetaDataResolver,
              hasTypeData: ContactTypeSpecificationResolver,
              hadPhoneMetaData: ContactPhoneMetaDataResolver,
              hasEmailMetaData: ContactEmailMetaDataResolver,
              hasAddrMetaData: ContactAddressMetaDataResolver
            },
          },
          {
            path: 'sms-customer-mgr/:id',
            outlet: 'infobar',
            data: {
              contactTypeId: '1',
              parent: 'sms'
            },
            component: PortalContactManagerComponent,
            title: 'sms-customer-mgr',
            resolve: {
              hasContactMetaData: ContactMetaDataResolver,
              hasTypeData: ContactTypeSpecificationResolver,
              hadPhoneMetaData: ContactPhoneMetaDataResolver,
              hasEmailMetaData: ContactEmailMetaDataResolver,
              hasAddrMetaData: ContactAddressMetaDataResolver
            }
          },
          {
            path: 'sms-project-info/:id',
            canActivate: [AuthGuard, ProjectGuard],
            outlet: 'projbar',
            component: SmsProjectSummaryComponent,
            data: {
              contactTypeId: '1',
              showLoading: 'projbar'
            },
            resolve: {
              infoLoaded: ProjectInformationResolver
            }
          }
        ]
      }
    ]
  },  // BEGIN MODAL ROUTES
  {
    path: 'loading/:container',
    outlet: 'modal',
    component: LoadingPageComponent
  },
  {
    path: 'sms-convo/:id/:phone',
    outlet: 'modal',
    component: SmsConversationComponent,
    resolve: {
      hasShortcuts: CommunicationShortcutResolver,
      hasDocTypes: CommunicationDocumentTypeResolver,
      hasFileTypes: DocumentTypeResolver,
      hasEntities: EntityResolver
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(smsRoutes)
  ],
  exports: [RouterModule]
})
export class SmsRoutingModule { }
