import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserNotificationsTabs } from '../tab-settings/user-notifications.tabs';
import { IPortalTab } from '../../../view-models';

@Component({
  selector: 'user-notifications-mgr',
  templateUrl: './user-notifications.component.html'
})
export class UserNotificationsContainerComponent implements OnInit {
  public authorizedTabs: IPortalTab[] = null;

  constructor( 
    public activeRoute: ActivatedRoute,
    public router: Router ) { }

  ngOnInit() {
    this.authorizedTabs = UserNotificationsTabs;
  }

}
