import { IUserPriviledges } from './i-user-priviledges';

export interface IProviderUser {
  providerUserId: number;
  providerId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  extension: string;
  membershipUserName: string;
  membershipUserId: number;
  emailAddress: string;
  isLdapUser: boolean;
  isSystemAdmin: boolean;
  supervisor_providerUserId: number;
  //set for external users only
  contact_contactId: number;
  updateDate: string;
  databaseName: string;

  //derived properties for current user
  currentUserId: number;
  currentUserName: string;
  userPageSize: number;
  sendEmail: boolean;
  version: string;
  customerContactSpan: string;
  delegateNames: any; //todo
  isProduction: boolean;
  userPriviledges: IUserPriviledges;
  myDelegates: Array<any>[];

  //derived properties, general
  fullName: string;
  supervisorName: string;

  //derived properties for security
  providerName: string; //like Hom Solutions
  chargeBackLaborSku_chargeBackSkuId: string;
  primaryLocations: string;
  primaryStores: string;
}

export class ProviderUser implements IProviderUser {
  providerUserId: number;
  providerId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  extension: string;
  membershipUserName: string;
  membershipUserId: number;
  supervisor_providerUserId: number;
  contact_contactId: number;
  emailAddress: string;
  isLdapUser: boolean;
  isSystemAdmin: boolean;
  updateDate: string;
  databaseName: string;

  //derived properties for current user (provider controller)
  currentUserId: number;
  currentUserName: string;
  userPageSize: number;
  sendEmail: boolean;
  version: string;
  customerContactSpan: string;
  delegateNames: any; //todo
  isProduction: boolean;
  userPriviledges: IUserPriviledges;
  myDelegates: Array<any>[];

  //derived properties, general
  fullName: string;
  supervisorName: string;
  providerName: string; //like Hom Solutions
  chargeBackLaborSku_chargeBackSkuId: string;

  //derived properties for security
  primaryLocations: string;
  primaryStores: string;


  constructor() { }

}

