<div class="task" [ngClass]="{ 'task--odd': isOdd, 'app-text--warning': isWarning, 'app-text--danger':  isOverDue  }">
  <div class="task__title">
    <div class="task__title__label">{{ projectTask.taskName}}
    <span *ngIf="isOverDue" class="fas fa-exclamation-triangle app-icon--danger"></span>
    <span *ngIf="isWarning" class="fas fa-exclamation-circle app-icon--warning"></span>
    </div>
    <button class="app-btn-icon app-btn-icon--danger task__title__btn"
            [disabled]="!canIEdit || projectTask.preventUserDelete"
            title="Delete Task"
            (click)="deleteTask()">
      <span class="fas fa-trash "></span>
    </button>
  </div>
  <div class="task__subName">{{ projectTask.taskSubName }}</div>
  <div *ngIf="projectTask.targetCompletionDate" class="task__field">
    <div class="task__field--label">Due</div>
    <div class="task__field--value">
      {{ projectTask.targetCompletionDate  | homdate | date | date:'MM/dd/yyyy' }}
    </div>
  </div>
  <div *ngIf="projectTask.completionDate" class="task__field">
    <div class="task__field--label">Completed On</div>
    <div class="task__field--value">{{ projectTask.completionDate  | homdate | date | date:'MM/dd/yyyy' }}</div>
  </div>
  <ng-container *ngIf="projectTask.targetCompletionDate && !projectTask.completionDate && projectTask.projectTaskActions.length">
    <ng-container *ngFor="let taskAction of projectTask.projectTaskActions" class="task-action">
      <task-action [projectTaskAction]="taskAction"
                   [projectTaskId]="projectTask.projectTaskId"
                   [canIEdit]="canIEdit"
                   [operation]="canIEdit ? myConstants.operationTypeEdit : myConstants.operationTypeDetails"
                   (completeEvent)="onCompleteTaskAction($event)">
      </task-action>
    </ng-container>
  </ng-container>
</div>
