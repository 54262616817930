    <header class="fw-header">
  <div class="fw-header__logo-box">
    <img class="fw-header__logo-box--icon"
         src="/Scripts/clients/hom-app/assets/HOMsolutions-Logo-202010-RGB.png"
         alt="HŌM Solutions Inc." title="HŌM Solutions Inc." />
  </div>

  <ng-container *ngIf="(menuService.hasTabs$ | async) && (hasValidSecurityAccess$ | async)">
    <fw-user-menu *ngIf="(authenticated$ | async) && (hasUserPreferences$ | async)"
                  class="user-menu-grid-loc"
                  [hasValidSecurityAccess]="hasValidSecurityAccess$ | async">
    </fw-user-menu>
      <fw-search-bar *ngIf="(authenticated$ | async) && (hasUserPreferences$ | async)" class="search-bar-grid-loc"></fw-search-bar>

      <nav *ngIf="menuService.isVisible && screenService.isSmall()" class="app-menu-vertical">
        <input type="checkbox"
               class="app-menu-vertical__checkbox"
               id="navi-toggle"
               (click)="menuService.toggleVerticalNav()"
               [checked]="menuService.showingMiniMainMenu">

        <label for="navi-toggle" class="app-menu-vertical__button" title="Click to toggle menu">
          <span class="app-menu-vertical__icon">&nbsp;</span>
        </label>
      </nav>

      <fw-app-menu  *ngIf="menuService.isVisible" class="app-menu-grid-loc"></fw-app-menu>
  </ng-container>

</header>
