
export interface IWarrantyType {
  warrantyTypeId: number;
  provider_providerId: number;
  warrantyTypeName: string;
  warrantyTypeDescription: string;
  impactsCrewAssignmentRate: boolean;
}

export class WarrantyType implements IWarrantyType {
  warrantyTypeId: number;
  provider_providerId: number;
  warrantyTypeName: string;
  warrantyTypeDescription: string;
  impactsCrewAssignmentRate: boolean;

  constructor() {
    this.warrantyTypeId = 0;
    this.provider_providerId = null;
    this.warrantyTypeName = '';
    this.warrantyTypeDescription = '';
    this.impactsCrewAssignmentRate = false;
  }
}
