/*  Service used for back end data retrievel when do not want to store the values */
/** Imports for Angular modules */
import { Injectable } from '@angular/core';
import {  HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, map, switchMap  } from 'rxjs/operators';

/** Imports for custom services */
//import { HttpService } from './http.service';
import { HomCommonUtility } from './hom-common.utility';
import { HomDataUtility } from './hom-data.utility';
import { IDomainObject, IResponseBase, ResponseBase, IHomDictionary} from '../interfaces/index';
import { IListFilter } from '../../fw/dynamic-list/interfaces/index'
import { environment } from './../../environments/environment';
import { cloneDeep } from 'lodash';

const httpOptions = {
  headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
};

/**
 * The Domain Object Service handles basic interactions with the domain models
 * on the server side and returns custom object instances where necessary, to
 * avoid needing to create a new javascript object definition for each model.
 */
@Injectable()
export class DomainObjectService {

  // Base URL that points to the CRM API
  baseUrl: string = environment.apiUrl;


  /**
   * Constructor for injecting services and such
   */
  constructor(public http: HttpClient,
    public homDataUtility: HomDataUtility) {}


  /**
   * Calls the index method on the provided object
   * to return all instances of that object
   * @param objectType The object type for which all objects should be retrieved
   * @param providerId The currently logged in users provider id
   * @param listFilter The list filter object loaded as applicable.
   */


  getListByProvider(objectType: string, providerId: number, listFilter: IListFilter = null): Observable<IResponseBase> {
    // Construct the URL to be called.
    let url = `${this.baseUrl}/${objectType}/ByProvider/${providerId}`;
    let parameters = `?`;

    // Format the list filter for consumption 
    if (listFilter) {
      let allFilters: IListFilter = cloneDeep(listFilter);
      parameters += this.homDataUtility.formatListFilter(allFilters);
    }

    // Add parameters to the URL, if there are any.
    if (parameters !== `?`)
      url += parameters;


    // Use the CRM API to call the index method of the
    // [objectName] controller, and return the mapped results.

    return this.http.get<IResponseBase>(url, httpOptions)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((err) => {
          return this.handleError(err);
        })) as any
      );
  }

  /**
* Calls the [method] method on the provided object using the supplied
* parameter to return the results of that method in a JSON format.
* @param objectType The object type whose controller implements the custom "get" method
* @param method The name of the custom "get" method to call
* @param param The parameter(s) to supply to the custom "get" method. Example 1?property1=true&property2=false
*/
  getObjectByMethod(objectType: string, method: string, params: string = ''): Observable<IResponseBase> {

    let url = `${this.baseUrl}/${objectType}/${method}`;
    if (params !== '') {
      if (!params.startsWith('?')) {
        url += `/${params}`;
      } else {
        url += `${params}`;
      }
    }
    const emptyResponse: IResponseBase = new ResponseBase();
    emptyResponse.success = false;

    return this.http.get<IResponseBase>(url, httpOptions)
      .pipe(
        //tap(data => console.log('in domain-object getObjectByMethod result: ', data, url)),
        map((response: IResponseBase) => response),
        (catchError(err => {
          return this.handleError(err);
        })) as any
      );
  }

  /**
* Utilizes the fully formated url string to return all instances of that object
*/
  getByUrl(url: string): Observable<IResponseBase> {

    let fullUrl = `${this.baseUrl}/${url}`;

    return this.http.get<IResponseBase>(`${fullUrl}`, httpOptions)
      .pipe(
        //tap(data => console.log('in domain-object getObjectByUrl result: ', data)),
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }


  /**
* Utilizes the fully formated url string to return all instances of that object
*/
  getObjectByMetaUrl(url: string): Observable<IResponseBase> {
    // Use the CRM API to call the details method of the
    // [objectName] controller, and return the mapped results.
    return this.http.get<IResponseBase>(`${url}`, httpOptions)
      .pipe(
        //tap(data => console.log('in domain-object getObjectByUrl result: ', data)),
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }

  /**
* Calls the [method] method on the provided object using the supplied
* parameter to return the results of that method in a JSON format.
* @param objectType The object type whose controller implements the custom "get" method
* @param method The name of the custom "get" method to call
* @param param The parameter(s) to supply to the custom "get" method. Example 1?property1=true&property2=false
* @param listFilter The list filter object loaded as applicable.  
*/
  getListByMethod(objectType: string, method: string, param: string = '', listFilter: IListFilter = null):
    Observable<IResponseBase> {


    let url = `${this.baseUrl}/${objectType}/${method}`;
    if (param !== '') url += `/${param}`;

    let parameters = !param || param.indexOf('?') === -1 ? `?` : '';

    // Format the list filter for consumption 
    if (listFilter) {
      if (parameters !== `?`) parameters += `&`;
      let allFilters: IListFilter = cloneDeep(listFilter);
      parameters += this.homDataUtility.formatListFilter(allFilters);
    }

    // Add parameters to the URL, if there are any.
    if (parameters !== `?`)
      url += parameters;

    return this.http.get<IResponseBase>(url, httpOptions)
      .pipe(
        //tap(data => console.log('in domain-object getListByMethod result: ', data, url)),
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }

  /**
* Utilizes the fully formated url string to return all instances of that object
     * @param listFilter The list filter object loaded as applicable.  
*/
  getListByMetaUrl(url: string, listFilter: IListFilter = null): Observable<IResponseBase> {
    let parameters = `?`;

    // Format the list filter for consumption 
    if (listFilter) {
      if (parameters !== `?`) parameters += '&';
      let allFilters: IListFilter = cloneDeep(listFilter);;
      parameters += this.homDataUtility.formatListFilter(allFilters);
    }

    // Add parameters to the URL, if there are any.
    if (parameters !== `?`) {
      url += parameters;
    }

    // Use the CRM API to call the details method of the
    // [objectName] controller, and return the mapped results.
    return this.http.get<IResponseBase>(`${url}`, httpOptions)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          const response = this.handleError(caught);
          //upgrade to 7.0 return in catcherror is no longer working - and shouldn't have to wrap this in an any.
          return response;
        })) as any
      );
  }

  /**
   * Calls the by[searchParameter] method on the provided object
   * to return all instances of that object which satisfy the given search
   * @param objectType The object type whose controller implements the "getBy" method
   * @param searchParameter The name of the parameter by which objects are to be retrieved
   * @param searchValue The value of the parameter by which objects are to be retrieved
   * @param params  Additional method parameters, separated by & Example: 'returnSentNotifications =true&newPurchases=false'
   */
  getByMethodById(objectType: string, method: string, id: number, listFilter: IListFilter = null, params: string = ''):
    Observable<IResponseBase> {
    let url = `${this.baseUrl}/${objectType}/${method}/${id}`;

    let parameters = `?`;

    if (params) {
      parameters += params;
    }
    // Format the list filter for consumption 
    if (listFilter) {
      if (parameters !== `?`) parameters += '&';
      let allFilters: IListFilter = listFilter;
      parameters += this.homDataUtility.formatListFilter(allFilters);
    }

    // Add parameters to the URL, if there are any.
    if (parameters !== `?`)
      url += parameters;

    return this.http.get<IResponseBase>(`${url}`, httpOptions)
      .pipe(
        map((val: IResponseBase) => val),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }


  /**
   * Calls the details method on the provided object
   * to return a specific instance of that object
   * @param objectType The object type whose controller implements the "get" method
   * @param id The ID of the object to retrieve
   */
  get(objectType: string, id: number): Observable<IResponseBase> {
    // Use the CRM API to call the details method of the
    // [objectName] controller, and return the mapped results.
    return this.http.get(`${this.baseUrl}/${objectType}/${id}`, httpOptions)
      .pipe(
        map((val: IResponseBase) => val),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }


  /**
   * Updates the supplied object in the database
   * @param domainObject The updated object to be submitted as an HTTP payload
   */

  

  update(domainObject: IDomainObject): Observable<IResponseBase> {

    // Strip and reformat the domainObject so that it's valid to be passed as an HTTP payload.
    var parsedObject = this.homDataUtility.fixPropertiesForApi(domainObject);

    // Call the object's update API URL (as detailed in the metadata), passing the parsed object.
    return this
      .http.post(`..${domainObject["metaData"].crud.updateUrl}`, parsedObject, httpOptions)
      .pipe(
        map((res: IResponseBase) => res),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }

  /**
   * Call an object's custom update method in the API
   * @param objectType The object type whose controller implements the custom "update" method
   * @param methodName The name of the custom "update" method to call
   * @param domainObject The updated object to submit as an HTTP payload
   * @param arrayName The name to give the array parameter, if domainObject is an array
   */
  updateByMethod(objectType: string, methodName: string, domainObject: IDomainObject, arrayName: string = null):
    Observable<IResponseBase> {
    // Strip and reformat the domainObject so that it's valid to be passed as an HTTP payload.
    const parsedObject = this.homDataUtility.fixPropertiesForApi(domainObject, arrayName);
    const bodyString = JSON.stringify(parsedObject); // Stringify payload

    return this.http.post<IResponseBase>(`${this.baseUrl}/${objectType}/${methodName}`, bodyString, httpOptions)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }

  updateByMethodById(objectType: string, method: string, id: number): Observable<IResponseBase> {
    let url = `${this.baseUrl}/${objectType}/${method}/${id}`;

    return this.http.post<IResponseBase>(`${url}`, httpOptions)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }

  updateByMethodParams(objectType: string, methodName: string, params: string): Observable<IResponseBase> {

    return this.http.post(`${this.baseUrl}/${objectType}/${methodName}/${params}`, httpOptions)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }

  updateByMethodWithForm(objectType: string, methodName: string, formData: FormData): Observable<IResponseBase> {
    return this.http.post(`${this.baseUrl}/${objectType}/${methodName}`, formData)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }


  /**
   * Call an object's custom create method in the API
   * @param objectType The object type whose controller implements the custom "update" method
   * @param methodName The name of the custom "update" method to call
   * @param domainObject The updated object to submit as an HTTP payload
   * @param arrayName The name to give the array parameter, if domainObject is an array
   */
  createByMethod(objectType: string, methodName: string, domainObject: IDomainObject, arrayName: string = null, parseIt: boolean = true): Observable<IResponseBase> {
    //TBD: PARSEIT change
    // Strip and reformat the domainObject so that it's valid to be passed as an HTTP payload.
    var parsedObject = parseIt ? this.homDataUtility.fixPropertiesForApi(domainObject, arrayName) : domainObject;
    let bodyString = JSON.stringify(parsedObject); // Stringify payload

    return this.http.post(`${this.baseUrl}/${objectType}/${methodName}`, bodyString, httpOptions)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
    );
  }

  createByMethodWithForm(objectType: string, methodName: string, formData: FormData): Observable<IResponseBase> {
    return this.http.post(`${this.baseUrl}/${objectType}/${methodName}`, formData)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }

  /**
* Calls the [method] method on the provided object using the supplied
* parameter to return the results of that method in a JSON format.
* @param objectType The object type whose controller implements the custom "get" method
* @param method The name of the custom "get" method to call
* @param param The parameter(s) to supply to the custom "get" method. Example 1?property1=true&property2=false
*/
  createByMethodParams(objectType: string, methodName: string, params: string) : Observable<IResponseBase> {

    return this.http.post(`${this.baseUrl}/${objectType}/${methodName}/${params}`, httpOptions)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }

  /**
   * Calls the [method] method on the provided object using the supplied
   * parameter to return the results of that method in a JSON format.
   * @param objectType The object type whose controller implements the custom "get" method
   * @param method The name of the custom "get" method to call
   * @param params definition example
   *     let params: IHomDictionary[] = [];
          params.push(new HomDictionary('entityId', entityId.toString()));
   */
  getByMethodParams(objectType: string, method: string, params: IHomDictionary[] = [], listFilter: IListFilter = null): Observable<IResponseBase> {
    let url = `${this.baseUrl}/${objectType}/${method}`;

    let parameters = `?`;
    let encoded = '';
    if (params.length) {
      params.forEach((p, idx) => {
        encoded = encodeURIComponent(p.value);
        if (idx > 0) parameters += `&`;
        parameters += `${p.key}=${encoded}`;
      });
    }

    // Format the list filter for consumption 
    if (listFilter) {
      if (parameters !== `?`) parameters += '&';
      let allFilters: IListFilter = cloneDeep(listFilter);
      parameters += this.homDataUtility.formatListFilter(allFilters);
    }

    if (parameters !== `?`)
      url += parameters;

    return this.http.get<IResponseBase>(url, httpOptions)
      .pipe(
        map((val: IResponseBase) => val),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );

  }

  /**
   * Calls the [method] method on the provided object using the supplied
   * parameter to return the results of that method in a JSON format.
   * @param objectType The object type whose controller implements the custom "get" method
   * @param method The name of the custom "get" method to call
   * @param param The parameter to supply to the custom "get" method
   */
  getCustomSimple(objectType: string, method: string): Observable<IResponseBase> {
    // Use the CRM API to call the [method] method of the
    // [objectType] controller, and return the mapped results.
    return this.http.get(`${this.baseUrl}/${objectType}/${method}`, httpOptions)
      .pipe(
        map((res: IResponseBase) => res),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }


  /**
  * Call an object's  delete method in the API
  * @param url to call to delete - will include the id in it.  metaData.crud.deleteUrl
  */
  delete(url: string): Observable<IResponseBase> {

    // Use the CRM API to call the details method of the
    // [objectName] controller, and return the mapped results.

    return this.http.get(`${url}`, httpOptions)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }

  /**
  * Call an object's  delete method in the API
  * @param url to call to delete - will include the id in it.  metaData.crud.deleteUrl
  */
  deleteByMethodById(objectType: string, method: string, id: number): Observable < IResponseBase > {
      let url = `${this.baseUrl}/${objectType}/${method}/${id}`;
      return this.delete(url);
  }

  /**
  * Call an object's  print method in the API
  * @param url to call to print - will include the id in it.  metaData.crud.printUrl
  */
  print(url: string): Observable<IResponseBase> {

    // Use the CRM API to call the details method of the
    // [objectName] controller, and return the mapped results.

    return this.http.get(`${url}`, httpOptions)
      .pipe(
        map((response: IResponseBase) => response),
        (catchError((caught: Observable<IResponseBase>) => {
          return this.handleError(caught);
        })) as any
      );
  }

  handleError(error: any): Observable<IResponseBase> {
    let errMsg: string;

    const response = new ResponseBase();
    response.success = false;

    if (error instanceof HttpErrorResponse) {
      errMsg = error.message;
    } else {
      errMsg = 'Fatal error occurred during data access.';
    }
    let value: string[] = [];
    value.push(errMsg);
    response.errorData.push({ 'key': '__Model', 'value': value });
    //response.message = errMsg;
    return of(response);
  }

  formatParams( params: IHomDictionary[]): string {
    let formatted: string = ''
    let encoded = '';
    if (params.length) {
      params.forEach((p, idx) => {
        encoded = encodeURIComponent(p.value);
        if (idx > 0) formatted += `&`;
        formatted += `${p.key}=${encoded}`;
      });
    }

    return formatted;
  }

}

