import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IListFilter, ListFilter, OrderTerm } from '../../../../../fw/dynamic-list/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IInventoryRollupViewModel, IProviderLocationShipperInventorySetup, IInventoryOrderRequest } from '../../../view-models';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';

import { ManagerDashEvent } from '../../enums/manager-dashboard.enums';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ManagerDashboardService } from '../../services/manager-dashboard.service';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';

@Component({
  selector: 'inventory-needs',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './inventory-needs.component.html'
})
export class InventoryNeedsComponent implements OnInit, OnDestroy {
  @ViewChild('providerLocation') public providerLocation: ElementRef;
  
  public selectedItems: IInventoryRollupViewModel[] = [];
  public locations$: BehaviorSubject<IProviderLocationShipperInventorySetup[]> = new BehaviorSubject(null); //need this interface
  public shipperDates$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public searchInfo$: BehaviorSubject<string> = new BehaviorSubject('');
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  deliveryDate: string;
  searchStartDate: string;
  searchEndDate: string;
  selectedLocationId: number;
  selectedLocation: string;
  showingChild: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public  selStore: Store<fromSelectionLists.IStoreState>,
    public  managerDashboardService: ManagerDashboardService,
    public datePipe: DatePipe,
    public  modalService: ModalService,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) { }


  public onLocationChange(providerLocationShipperInventorySetupId: number = 0): void {
    this.initProperties(false);
    if (providerLocationShipperInventorySetupId <= 0) {
      return;
    }

    this.getInventoryDates(providerLocationShipperInventorySetupId);
    this.selectedLocationId = providerLocationShipperInventorySetupId;
    const loc: IProviderLocationShipperInventorySetup = this.locations$.getValue().find(x => x.providerLocationShipperInventorySetupId == providerLocationShipperInventorySetupId);
    if (loc) {
      this.selectedLocation = loc.entityLabel;
    }
  }

  public onDateChange(val: string): void {
    if (!val || this.selectedLocationId <= 0) {
      return;
    }

    this.initProperties(true);

    this.deliveryDate = val;
    const shipperDate = this.shipperDates$.getValue().find(x => this.datePipe.transform(x.deliveryDate, 'MM/dd/yyyy') == val);

    if (shipperDate) {
      this.searchStartDate = this.datePipe.transform(shipperDate['searchStartDate'], 'MM/dd/yyyy');
      this.searchEndDate = this.datePipe.transform(shipperDate['searchEndDate'], 'MM/dd/yyyy');
      this.searchInfo$.next('List results are for search range of '.concat(this.searchStartDate, ' to ', this.searchEndDate));
      this.setListDefinition();
    }
  }

  public onSelect(event: IHomEventEmitter): void {
    switch (event.event) {
      case ManagerDashEvent.includeInOrder:
        this.updateSelected(event);
        break;
      default:
        break;
    }
  }

  public onPage(event: IHomEventEmitter): void {
    this.selectedItems = [];
  }

  public onShowChild(val: boolean): void {
    this.showingChild = val;
  }


  public previewOrder(): void {
    const stateData: IInventoryOrderRequest = {
      searchStartDate: this.searchStartDate,
      searchEndDate: this.searchEndDate,
      providerLocationShipperInventorySetupId: this.selectedLocationId,
      orderDate: this.deliveryDate,
      selectedOrderIds: this.selectedItems.map(x => x.uniqueId)
    };

    this.modalService.open({
      title: 'Order Inventory for '.concat(this.selectedLocation),
      path: 'mgr-dash-inventory-order-request',
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.medium,
      state: stateData
    });
  }

ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.initProperties(false);
      this.getShipperLocations();
    });

    this.subscription.add(this.selStore.pipe(select(fromSelectionLists.getSelectionListDataByType('shipperProviderLocation')))
      .subscribe(data => {
        this.locations$.next(data);
      }));

    this.subscription.add(this.selStore.pipe(
      select(fromSelectionLists.getSelectionListDataByType('shipperDate')))
      .subscribe(data => {
        this.shipperDates$.next(data);
      }));

    this.subscription.add(this.emitterService.inventoryOrderEventEmitted$.subscribe(
      (e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventSuccessfulOrder:
            this.initProperties(false);
            this.resetLocation();
            break;
          default:
            break;
        }
      }));
  }
  initProperties(justDateChange: boolean): void {
    this.listDefinition$.next(null);
    this.selectedItems = [];
    this.searchStartDate = '';
    this.searchEndDate = '';
    this.searchInfo$.next('');
    if (!justDateChange) {
      this.selStore.dispatch(new fromSelectionLists.InitSelectItems({ storeName: 'shipperDate', parentId: -1 }));
      this.selectedLocationId = -1;
    }
  }

  resetLocation(): void {
    if (this.providerLocation) {
      this.providerLocation.nativeElement.value = '';
    }
  }

  getShipperLocations() {
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.isLookup = true;
    listFilter.orderTerm = [new OrderTerm('providerLocation_locationName')];

    this.selStore.dispatch(new fromSelectionLists.GetEntityList('ProviderLocationShipperInventorySetup', listFilter, 'shipperProviderLocation'));
  }

  getInventoryDates(providerLocationShipperInventorySetupId: number) {
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.isLookup = true;
    
    let tomorrow = new Date().addDays(1);

    this.selStore.dispatch(new fromSelectionLists.GetEntityListByMethodParams(
      'ShipperPurchaseOrder',
      'GetDeliveryDate',
      providerLocationShipperInventorySetupId + '?proposedDate=' + this.datePipe.transform(tomorrow, 'MM/dd/yyyy'),
      listFilter,
      'shipperDate'));
  }

  setListDefinition(): void {
    this.listDefinition$.next(this.managerDashboardService.loadDashInventoryNeedsListDefinition(this.searchStartDate, this.searchEndDate, this.selectedLocationId));
  }

  updateSelected(event: IHomEventEmitter): void {
    const row: IInventoryRollupViewModel = event.data;
    const exists: number = this.selectedItems.findIndex(x => x.uniqueId === row.uniqueId);
    if (event.action) {
      if (exists === -1) {
        this.selectedItems.push(row);
      }
    } else {
      if (exists > -1) {
        this.selectedItems.splice(exists, 1);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
