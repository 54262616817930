<div class="portal">
  <div class="app-btn-box app-btn-box--left">
      <button class="app-btn app-btn--primary app-btn--wrap" [ngClass]="{'app-btn--success': showInstallers}" type="button" (click)="toggleInstallers()">
          <span class="fal fa-truck-moving"></span>  Installers
      </button>
      <button class="app-btn app-btn--primary app-btn--wrap" [ngClass]="{'app-btn--success': showTechnicians}" type="button" (click)="toggleTechnicians()">
          <span class="far fa-people-carry"></span>  Technicians
      </button>
  </div>
  <div class="installer-dashboard__include">
      <fw-check-box #checkBox [config]="cbConfig"
                    (change)="includeChange(checkBox.value)"></fw-check-box>
      <span class="fad fa-turtle  installer-dashboard__include--icon app-icon--med" title="Including oldest requirement is a slow request" ></span>
  </div>

  <ng-container *ngIf="showInstallers">
    <div class="portal__detail--item">
        <installers 
            [includeOldestRequirement]="includeOldestRequirement$ | async"
            [providerId]="providerId$ | async"
            [canCreate]="canIManagerInstaller$ | async"></installers>
      </div>
  </ng-container>

  <ng-container *ngIf="showTechnicians">
    <div class="portal__detail--item">
        <installer-work-crew-technicians 
                  [includeOldestRequirement]="includeOldestRequirement$ | async"
                  [providerId]="providerId$ | async"
                  [canCreate]="canIManagerInstaller$ | async"></installer-work-crew-technicians>
    </div>
  </ng-container>
</div>  
