  <div class="contact-phones__phone" *ngFor="let phone of phones">
    <contact-phone
                   [fieldDefinitions]="fieldDefinitions"
                   [phone]="phone"
                   [operation]="operation"
                   [requestTime]="requestTime"
                   [canIEdit]="canIEdit"
                   [activePreferred]="activePreferred$ | async"
                   (deleteEvent)="onDelete($event)">
    </contact-phone>
  </div>
