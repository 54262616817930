import { ISeedContactInformation } from './i-seed-contact-information';

export interface IContact {
  contactId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  provider_providerId: number;
  nameSuffix_lookupValueId: number;
  contactType_contactTypeId: number;
  contactType_contactTypeName: string;
  contactFor_contactId: number;
  birthDate: string;
  isOrganization: boolean;
  fileUpload_fileUploadId: number;
  sssOptOut: boolean;
  optOutSms: boolean;
  active: boolean;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;
  rowVersion: string;
  aggregateRootVersion: string;

  //extra data fields
  contactCommunicationPreferenceId: number;
  communicationPreferenceId: number;
  communicationPreferenceName: string;
  communicationPreferenceValue: string;
  contactTypeName: string;
  installerName: string;
  entityLabel: string;
}


export class Contact implements IContact {
  contactId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  provider_providerId: number;
  nameSuffix_lookupValueId: number;
  contactType_contactTypeId: number;
  contactType_contactTypeName: string;
  contactFor_contactId: number;
  birthDate: string;
  isOrganization: boolean;
  fileUpload_fileUploadId: number;
  sssOptOut: boolean;
  optOutSms: boolean;
  active: boolean;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;
  rowVersion: string;
  aggregateRootVersion: string;

  //extra data fields
  contactCommunicationPreferenceId: number;
  communicationPreferenceId: number;
  communicationPreferenceName: string;
  communicationPreferenceValue: string;
  contactTypeName: string;
  installerName: string;
  entityLabel: string;

  //Defaults are used for organizations where input of first, last, etc is not allowed.  Defaults will contain the values to use.
  constructor(contactTypeId: number, contactType: string, isOrganization: boolean = false, defaults: ISeedContactInformation = null) {
    this.contactId = 0;
    this.firstName = defaults ? defaults.firstName : '';
    this.lastName = defaults ? defaults.lastName : '';
    this.middleName = defaults ? defaults.middleName : '';
    this.provider_providerId = null;
    this.nameSuffix_lookupValueId = null;
    this.contactType_contactTypeId = contactTypeId;
    this.contactType_contactTypeName = contactType;
    this.contactFor_contactId = null;
    this.birthDate = '';
    this.isOrganization = isOrganization;  
    this.fileUpload_fileUploadId = null;
    this.sssOptOut = defaults ? defaults.sssOptOut : false;
    this.optOutSms = defaults ? defaults.optOutSms :  true;
    this.active = true;
    this.createDate = '';
    this.updateDate = '';
    this.createdBy = 0;
    this.updatedBy = 0;
    this.rowVersion = '';
    this.aggregateRootVersion = '';
     }
  }
