import { Component, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import {
  IListColumn, ListColumn, IListDefinition,
  ListDefinition, IListFilter, ListFilter
} from '../../../../../fw/dynamic-list/interfaces/index';
import { IInstallerWorkCrew } from '../../../view-models';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'installer-work-crew-metrics',
  templateUrl: './installer-work-crew-metrics.component.html'
})
export class InstallerWorkCrewMetricsComponent implements OnInit, OnChanges {
  @Input() installerId: number;
  @Input() workCrews: IInstallerWorkCrew[];

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  activeCrewId: number;

  constructor(public dynamicListService: DynamicListService) {}

  ngOnInit() {
    this.activeCrewId = -1;
    this.listDefinition$.next(null);
    this.newRequest();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workCrews']) {
      this.newRequest();
    }
  }

  public changeCrew(id: number): void {
    this.activeCrewId = id;
    this.loadListDefinition();
  }

  newRequest(): void {
    if (this.workCrews.length > 0) {
      this.activeCrewId = this.workCrews[0].installerWorkCrewId;
      this.loadListDefinition();
    }
  }

  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = 'Work Crew Metrics';
    const listObjectController = 'InstallerWorkCrewMetricsViewModel';
    const listStoreName = 'workCrewMetrics';
    const detailRoutePath = '';
    const listRowKeyId = 'installerWorkCrewId';
    const detailRouterOutlet = '';

    const defaultListFilter: IListFilter = new ListFilter();

    let listDefinition = this.dynamicListService.createListDefinition(detailRouterOutlet,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      defaultListFilter,
      listColumns,
      detailRoutePath);

    listDefinition.parentId = this.installerId;
    listDefinition.parentKey = 'installerId';
    listDefinition.controllerMethod = 'GetAllMetricsByWorkCrew';
    listDefinition.methodParameters = this.activeCrewId.toString();

    //readonly, no crud buttons
    listDefinition.allowSaveFilter = false;
    listDefinition.showFilter = false;

    this.listDefinition$.next( listDefinition );

  }

  /*
      Define the columns in the list to be created.
  */
  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('metricPullType');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('averageVocScore');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('warrantyCallBackRate');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('completedJobCount');
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

}
