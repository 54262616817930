import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IDocumentType } from '../../../view-models';
import { IEntityDocument, EntityDocument, IProjectChildrenViewModel } from '../../../view-models/index_two';
import { IValueChanged } from '../../../../../fw/dynamic-list/services';
import { IErrorData } from '../../../../../shared/interfaces';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/index';

//store actions, reducers, interfaces
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import * as fromRoot from '../../../../store/reducers/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  selector: 'required-document-create-container',
  templateUrl: './required-document-create-container.component.html',
})
export class RequiredDocumentCreateContainerComponent implements OnInit, OnDestroy  {
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public documentTypes$: BehaviorSubject<IDocumentType[]> = new BehaviorSubject([]);
  public applicableChildren$: BehaviorSubject<IProjectChildrenViewModel[]> = new BehaviorSubject([]);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);

  projectChildren: IProjectChildrenViewModel[] = [];
  parentId: number = -1;
  myPathName: string = '';
  myStoreName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string;
  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public rootStore: Store<fromRoot.IState>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = this.myConstants.operationTypeCreate;
      this.myStoreName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.parentId = +paramMap.get('portalEntityId'); //projectId
      this.setDetailConfig(paramMap);
      this.getStoreData();
    });
  }

  public onChange(obj: IValueChanged): void {
    if (obj.key === 'documentType') {
      const applicableChildren = this.projectChildren.filter(x => x.entityId === obj.value['entity_entityId']);
      this.applicableChildren$.next(applicableChildren);
      this.errorData$.next([]);
    }
  }

  public onCancel(): void {
    const emitter: IHomEventEmitter = { requestor: 'required-document-create', event: this.myConstants.emitterEventClose, action: '', data: null };
    this.emitterService.emitListEvent(emitter);
    this.store.dispatch(new DynamicListActions.ClearErrorsList({ storeName: this.myStoreName, parentId: this.parentId }));
  }

  public onCreate(e: IHomEventEmitter) {
    let rec: IEntityDocument = new EntityDocument();
    const documentType: IDocumentType = e.data['documentType'];
    const selectedEntity: IProjectChildrenViewModel = e.data['children'];
    const projectEntity: IProjectChildrenViewModel = this.projectChildren && this.projectChildren.length
      ? this.projectChildren.find(x => x.entityName === 'Project') : null;
    rec.parentEntity_entityId = projectEntity ? projectEntity.entityId : 0;
    rec.parentEntityPkid = this.parentId;
    rec.documentType_documentTypeId = documentType == null ? 0 : documentType.documentTypeId;
    rec.includeInWorkOrderPacket = documentType == null ? false : documentType.includeInWorkOrderPacket;
    rec.entityPkid = selectedEntity == null ? 0 : selectedEntity.entityPkid;
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.myStoreName, this.parentId, this.controllerName, this.myConstants.emitterEventCreate, rec, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.parentId;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.wrapsForm = true;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  getStoreData() {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('documentType')))
      .subscribe((data: IDocumentType[]) => {
        const requiredTypes = data == null ? [] : data.filter(x => x.isRequired);
        this.documentTypes$.next(requiredTypes);
      }));

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('projectChildren', this.parentId)))
      .subscribe((data: IProjectChildrenViewModel[]) => {
        this.projectChildren = data;
      }));

    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.myStoreName, this.parentId)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.myPathName = listDefinition.detailRoutePath;
        this.controllerName = listDefinition.controllerName;
      }
    ));

    //listen for updates to the list store
    this.subscription.add(this.store.pipe(select(fromStore.getListByType(this.myStoreName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId === this.parentId)))
      .subscribe((objData: IListObjectData) => {
        if (objData.errorData && objData.errorData.length > 0) {
          this.errorData$.next(objData.errorData);
        } else {
          this.errorData$.next([]);
          if (objData.event) {
            this.onCancel();
          }
        }
      }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
