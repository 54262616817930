import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminEvent } from '../../enums/admin.enums';
import { IBranchProgram, IBranch, IProgramService } from '../../../view-models';
import { getSelectedRecord, IAllDynamicData } from '../../../../../fw/dynamic-list/store';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'branch-programs',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './branch-programs.component.html'
})
export class BranchProgramsComponent implements OnInit, OnDestroy {
  public listDefinition: IListDefinition;
  public title: string;
  branchId: number = 0;
  programServiceId: number = 0;
  subscription: Subscription = new Subscription();
  currentBranch: IBranch;
  currentProgramService: IProgramService;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<IAllDynamicData>,
   public adminService: AdminProviderService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.branchId = +paramMap.get('branchId');
      this.programServiceId = +paramMap.get('psId');
      this.title =  this.branchId > 0 ? 'Program Services' : 'Branches';
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.showRelatedEntityManager:
        this.openEntityManager(event.data);
        break;
      default:
        break;
   }
  }

  newRequest() {
    const parentListDef = this.branchId > 0
      ? this.adminService.loadBranchesListDefinition()
      : this.adminService.loadProgramServicesListDefinition();

    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(parentListDef.storeName, -1, parentListDef.rowKeyId, this.branchId > 0 ? this.branchId : this.programServiceId), take(1)))
      .subscribe(entity => {
        this.currentBranch = this.branchId > 0 ? entity : null;
        this.currentProgramService = this.programServiceId > 0 ? entity : null;
      })
    );
    this.listDefinition = this.adminService.loadBranchProgramsListDefinition(this.branchId, this.programServiceId);
  }

  openEntityManager(bp: IBranchProgram): void {
    const parentName: string = this.branchId > 0
      ? 'Branch '.concat(this.currentBranch.branchName)
      : 'Program Service '.concat(this.currentProgramService['entityLabel']);
    const parentId: number = this.branchId > 0 ? this.branchId :  this.programServiceId;
    this.adminService.openRelatedEntityManager(this.listDefinition, parentName, parentId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
