import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FwSharedModule } from '../../../fw/fw-shared/fw-shared.module';
import { FwModule } from '../../../fw/fw.module';
import { SharedModule } from '../../../shared/shared.module';
import { PortalSharedModule } from '../portal-shared/portal-shared.module';
import { PricingSharedRoutingModule } from './pricing-shared-routing.module';

import { PricingSharedService } from './services/pricing-shared.service';

//components
import {
  SkuComponent,
  PayoutComponent,
  SkuPayoutLinkComponent,
  SkuPriceComponent,
  PayoutPriceComponent
} from './components';

//containers
import {
  SkuPricingWizardComponent,
  SkuCreationWizardComponent
} from './containers/index';

//resolvers
import {
  SkuDeckResolver,
  RegionResolver
} from '../resolvers';

//guards
import { IsCurrentGuard } from '../../store/services/is-current-guard.service';

@NgModule({
  declarations: [
    SkuComponent,
    PayoutComponent,
    SkuPayoutLinkComponent,
    SkuPriceComponent,
    PayoutPriceComponent,
    SkuPricingWizardComponent,
    SkuCreationWizardComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      FwModule,
      FwSharedModule,
      SharedModule,
      PricingSharedRoutingModule,
      PortalSharedModule
    ],
  providers: [
    IsCurrentGuard,
    SkuDeckResolver,
    RegionResolver,
    PricingSharedService
  ],
  exports: [
    SkuComponent,
    PayoutComponent,
    SkuPayoutLinkComponent,
    SkuPriceComponent,
    PayoutPriceComponent,
    SkuPricingWizardComponent,
    SkuCreationWizardComponent
  ]
})

export class PricingSharedModule {}
