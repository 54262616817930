import { ActionReducerMap, createFeatureSelector, MetaReducer} from '@ngrx/store';

import * as fromDynamicList from './dynamic-list.reducer';
import * as fromMetaData from './meta-data.reducer';
import * as fromDynamicObject from './dynamic-object.reducer';
//import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '../../../../environments/environment';

export interface IAllDynamicData {
  dynamicLists: fromDynamicList.IListsState;
  metaData: fromMetaData.IMetasState;
  dynamicObjects: fromDynamicObject.IObjectsState;
}


export const dynamicDataReducers: ActionReducerMap<IAllDynamicData> = {
  dynamicLists: fromDynamicList.dynamicListReducer,
  metaData: fromMetaData.metaDataReducer,
  dynamicObjects: fromDynamicObject.dynamicObjectReducer,
};

//only one reducer for this feature
export const getAllDynamicData = createFeatureSelector<IAllDynamicData>('dynamicData');

//export const metaReducer: MetaReducer<IAllDynamicData>[] = !environment.production ? [storeFreeze]: [];
