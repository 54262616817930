import { PopoverDirective } from './fw-popover.directive';
import { PopoverComponent } from './fw-popover.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    PopoverDirective,
    PopoverComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PopoverComponent,
    PopoverDirective
  ]
})
export class PopoverModule { }
