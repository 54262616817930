import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IListScreenSize, IDynamicListFilterCol, ISearchTerm} from '../../interfaces/index';
import { IFieldDefinition } from '../../../dynamic-forms/index';
import { DynamicListEvent } from '../../enums/dynamic-list.enum';

//services
import { DynamicListService } from '../../services/dynamic-list.service';

@Component({
  selector: 'fw-dynamic-list-filter-row',
  templateUrl: './dynamic-list-filter-row.component.html'
})
export class DynamicListFilterRowComponent implements OnInit, OnChanges {
  @Input() listDefinition: IListDefinition;
  @Input() fieldDefinitions: IFieldDefinition[];
  @Input() screenSize: IListScreenSize;
  @Input() searchTerms: ISearchTerm[];
  @Input() fieldMetaData: any; //varies
  @Input() operation: string;
  @Input() parentId: number;
  @Input() filterDisabled: boolean;
  @Input() showFilter: boolean;

  public colData$: BehaviorSubject<IDynamicListFilterCol[]> = new BehaviorSubject(null);
  public defaultValues: { key: string, value: ISearchTerm }[] = [];

  constructor(public emitterService: HomEventEmitterService,
    public  dynamicListService: DynamicListService) {}

  public runFilter(): void {
    let event: IHomEventEmitter = { requestor: 'dynamic-list-filter-row', event: DynamicListEvent.runFilter, action: '', data: null };
    this.emitterService.emitListEvent(event);
  }

  public clearAllFilters(): void {
    let event: IHomEventEmitter = { requestor: 'dynamic-list-filter-row', event: DynamicListEvent.clearAllFilters, action: '', data: null };
    this.emitterService.emitListEvent(event);
  }

  public createSavedFilter(): void {
    let event: IHomEventEmitter = { requestor: 'dynamic-list-filter-row', event: DynamicListEvent.createSavedFilter, action: '', data: null };
    this.emitterService.emitListEvent(event);
  }

  public manageSavedFilters(): void {
    let event: IHomEventEmitter = { requestor: 'dynamic-list-filter-row', event: DynamicListEvent.manageSavedFilters, action: '', data: null };
    this.emitterService.emitListEvent(event);
  }

  ngOnInit(): void {
    this.setColData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchTerms'] && !changes['searchTerms'].isFirstChange()) {
      this.updateDefaultValues();
      this.setColData();
    }
    if ((changes['screenSize'] && !changes['screenSize'].firstChange)) {
      this.setColData();
    }
  }

  setColData(): void {
    let colData: IDynamicListFilterCol[] = [];
    this.listDefinition.listColumns.forEach((col, idx) => {
      colData.push({
        key: col.key,
        fieldDefinition: this.dynamicListService.getFieldDefinition(this.fieldDefinitions, col.key),
        filterable: this.dynamicListService.colFilterable(this.fieldDefinitions, col.key),
        visible: this.dynamicListService.isVisible(this.screenSize, col),
        defaultValue: this.searchTerms.find(x => x.term === col.key || x.columnName === col.key)
          ? this.searchTerms.find(x => x.term === col.key || x.columnName === col.key) : null,
        idx: idx
      });  
    });
    this.defaultValues = colData.map((item) => { return { key: item.key, value: item.defaultValue } });
    this.colData$.next(colData);
  }

  updateDefaultValues(): void {
    this.defaultValues.forEach(item => {
      const searchTerm = this.searchTerms.find(x => x.term === item.key || x.columnName === item.key);
      if (searchTerm) {
        item.value = searchTerm;
      } else {
        item.value = (item.value === null ? undefined : null);
      }
    });
  }
}
