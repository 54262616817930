import { Component, OnInit, Inject, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/index';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { ProjectService } from '../../services';

@Component({
  selector: 'project-aqt-changes',
  templateUrl: './project-aqt-changes.component.html'
})
export class ProjectAQTChangesComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() workOrderId: number = 0;

  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subscription = new Subscription();

  constructor(public store: Store<fromStore.IAllDynamicData>,
    public projectService: ProjectService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.newRequest();
  }

  newRequest(): void {
    let listDefinition = this.projectService.loadAQTChangesListDefinition(this.projectId, this.workOrderId);
    this.listDefinition$.next(listDefinition);

    //list is loaded from project high-level summary not here.
    this.subscription.add(this.store.pipe(select(getListByType(listDefinition.storeName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == listDefinition.parentId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          this.working$.next(state.working);
        }
      }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] && !changes['projectId'].isFirstChange()) {
      this.newRequest();
    }
  }

}
