
export enum UserDashType {
  openProject = 'openProject',
  unreceivedInventory = 'unreceivedInventory',
  unacknowledgedExternalNote = 'unacknowledgedExternalNote',
  unassignedExternalNote = 'unassignedExternalNote',
  toBeScheduledSchedule = 'toBeScheduledSchedule',
  toBeDispatched = 'toBeDispatched',
  dispatchSchedule = 'dispatchSchedule',
  pendingClose = 'pendingClose',
}

export enum UserDashCountType {
  openProjectCount = 'openProjectCount',
  unreceivedInventoryCount = 'unreceivedInventoryCount',
  unacknowledgedExternalNoteCount = 'unacknowledgedExternalNoteCount',
  unassignedExternalNoteCount = 'unassignedExternalNoteCount',
  toBeScheduledScheduleCount = 'toBeScheduledScheduleCount',
  toBeDispatchedCount = 'toBeDispatchedCount',
  dispatchScheduleCount = 'dispatchScheduleCount',
  pendingCloseCount = 'pendingCloseCount'
}

export enum UserDashStore {
  dashOpenProjects = 'dashOpenProjects',
  dashUnreceivedInventory = 'dashUnreceivedInventory',
  dashUnscheduledWorkOrders = 'dashUnscheduledWorkOrders',
  dashUndispatchedWorkOrders = 'dashUndispatchedWorkOrders',
  dashAvailableWorkCrews = 'availableWorkCrews', //TBD on the location of this one
  dashDispatchedWorkOrders = 'dashDispatchedWorkOrders',
  dashPendingCloseProjects = 'dashPendingCloseProjects',
  dashOpenExternalNotes = 'dashOpenExternalNotes',
  dashUnassignedExternalNotes = 'dashUnassignedExternalNotes',
  dashPendingTasks = 'dashPendingTasks',
  dashSSSRequiringActions = 'dashSSSRequiringActions',
  dashSSSOptionCounts = 'dashSSSOptionCounts',
  dashSSSInvites = 'dashSSSInvites'
}

export enum UserDashEvent {
  peekWOSummary = 'peekWOSummary',
  peekPOSummary = 'peekPOSummary',
  receiveInventory = 'receiveInventory',
  scheduleWorkOrder = 'scheduleWorkOrder',
  dispatchWorkOrder = 'dispatchWorkOrder',
  selectRecord = 'selectRecord'
}

export enum UserDashTabLabel {
  myPendingTasks = 'Pending Tasks',
  sssInvitesRequiringAction = 'SSS Invites Needing Action',
  myOpenProjects = 'Open',
  myUnreceivedInventory = 'Unreceived Inventory',
  myOpenExternalNotes = 'Open External Notes',
  unassignedExternalNotes = 'Unassigned External Notes',
  myUnscheduledWorkOrders = 'Unscheduled',
  myUndispatchedProjects = 'Undispatched',
  myDispatchedProjects = 'Dispatched',
  myPendingCloseProjects =  'Pending Close'
}
