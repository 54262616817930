 <div class="app-padding">
  <div class="float-label">
    <select #role (change)="selectRole(role.value)" appFocus [autoFocus]="true">
      <option selected value="0">Choose...</option>
      <option *ngFor="let role of installerRoles"
              [value]="role.providerRoleId">
        {{role.roleName}}
      </option>
    </select>
    <label title="Installer Role">Installer Role</label>
  </div>

  <div *ngIf="installerRoles && installerRoles.length === 0" class="app-info">
    <i class="fas fa-info-circle app-icon--primary"></i>
    All available installer roles have already been assigned to this technician.
  </div>

  <fw-crud-buttons [operation]="operation"
                   [disabled]="(!role.value || role.value <= '0')"
                   [showNext]="false"
                   (cancelEvent)="onCancel()"
                   (createEvent)="onCreate()">
  </fw-crud-buttons>
</div>
