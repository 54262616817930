<ng-container *ngFor="let col of (colData$ | async);">
  <ng-container *ngIf="col.visible && col.fieldDefinition">
    <fw-dynamic-list-header-item class="dynamic-list__header"
                                 [ngClass]="{'dynamic-list__header--filteredby': col.isFiltered }"
                                 [col]="col"
                                 [sortable]="(listDefinition.noGet && !hasData) ? false : col.sortable"
                                 (sortEvent)="onColSort($event)">
    </fw-dynamic-list-header-item>
  </ng-container>
</ng-container>

<ng-template #sortSettings>
  <div class="dynamic-list__popover">
    <dynamic-list-sort-settings [listDefinition]="listDefinition"
                                [fieldDefinitions]="fieldDefinitions"
                                [currentTerms]="orderTerms"
                                (applyEvent)="onSortApply($event)"
                                (cancelEvent)="onSortCancel()">
    </dynamic-list-sort-settings>
  </div>
</ng-template>

<div class="dynamic-list__header dynamic-list__header__actions">
  <div class="dynamic-list__header__action dynamic-list__header__action--col1">
    <button fw-popover
           type="button"
           class="dynamic-list__header__action--btn dynamic-list__header__action--btn--pad"
           title="Set Sort Settings"
           [ngClass]="{ 'dynamic-list__header__action--btn-active' : (showSortSettings$ | async) }"
           [hidden]="listDefinition.revokeSort || !canBulkSort"
           [template]="sortSettings"
           [showPopover]="showSortSettings$ | async"
           [closeOnClickOutside]="true"
           [disabled]="(listDefinition.noGet && !hasData)"
           (click)="toggleSortSettings()">
     <span class="fas fa-sort-alt"></span>
   </button>
  </div>
  <div class="dynamic-list__header__action dynamic-list__header__action--col2">
    <button class="dynamic-list__header__action--btn"
            title="{{ inFilterMode ? 'Hide Filters' : 'Show Filters' }}"
            [ngClass]="{ 'dynamic-list__header__action--btn-active' : inFilterMode }"
            [hidden]="!listDefinition.showFilter"
            (click)="showFilter()">
      <span class="fas fa-filter"></span>
    </button>
  </div>
  <div *ngIf="listDefinition.groupSelect && listDefinition.groupSelect.allowSelectAll"
       class="dynamic-list__header__action dynamic-list__header__action--col3">
    <label class="dynamic-list__header__action--label">Select All</label>
    <div class="check-box-field dynamic-list__header__action--chk" title="Select All">
      <fw-check-box #selectAllItems
                    [config]="selectAllConfig"
                    [isDisabled]="(listDisabled ? '' : null)"
                    (change)="selectAll($event)">
      </fw-check-box>
    </div>
  </div>
</div>

