import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FwModule } from '../../fw/fw.module';
import { SharedModule } from '../../shared/shared.module';
import { PortalSharedModule } from '../portals/portal-shared/portal-shared.module';
import { FwSharedModule } from '../../fw/fw-shared/fw-shared.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { ContactRoutingModule } from './contact-routing.module';
import { SmsModule } from "../sms/sms.module";

//services
import { ContactManagerService } from './services';

//components
import {
  AddressResultsComponent,
  ContactAddressComponent,
  ContactAddressesComponent,
  ContactEmailComponent,
  ContactEmailsComponent,
  ContactHeaderComponent,
  ContactInformationComponent,
  ContactNotesComponent,
  ContactPhoneComponent,
  ContactPhonesComponent,
  DetailContactManagerComponent,
  PortalContactManagerComponent,
  ContactCommunicationPreferenceComponent
} from './components/index';

//containers
import {
  ContactMatchesComponent,
  ContactManagerComponent,
  JobSiteManagerComponent
} from './containers/index';


@NgModule({
  declarations: [
    AddressResultsComponent,
    ContactAddressComponent,
    ContactAddressesComponent,
    ContactEmailComponent,
    ContactEmailsComponent,
    ContactHeaderComponent,
    ContactInformationComponent,
    ContactNotesComponent,
    ContactPhoneComponent,
    ContactPhonesComponent,
    DetailContactManagerComponent,
    PortalContactManagerComponent,
    JobSiteManagerComponent,
    ContactMatchesComponent,
    ContactCommunicationPreferenceComponent,
    ContactManagerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PortalSharedModule,
    FwSharedModule,
    FwModule,
    SharedModule,
    FileUploadModule,
    SmsModule,
    ContactRoutingModule
  ],
  exports: [
    AddressResultsComponent,
    ContactAddressComponent,
    ContactAddressesComponent,
    ContactEmailComponent,
    ContactEmailsComponent,
    ContactHeaderComponent,
    ContactInformationComponent,
    ContactNotesComponent,
    ContactPhoneComponent,
    ContactPhonesComponent,
    ContactCommunicationPreferenceComponent,
    DetailContactManagerComponent,
    PortalContactManagerComponent,
    JobSiteManagerComponent,
    ContactManagerComponent
 ]
})
export class ContactModule {
  static forRoot(): ModuleWithProviders<ContactModule> {
    return {
      ngModule: ContactModule,
      providers: [ContactManagerService]
    };
  }
}
