import { Component, Input, OnInit, Output, OnChanges, SimpleChanges, OnDestroy, Inject, ViewChild, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IErrorData } from '../../../../../shared/interfaces/index';
import { IProviderUser, IWorkOrder, IPurchaseOrder } from '../../../../portals/view-models/index';
import { Note, INote, INoteViewModel, INotification, Notification } from '../../../view-models/index';
import { INoteConfigFields, ISendToUser } from '../../interfaces';
import { IFieldDefinition } from '../../../../../fw/dynamic-forms';
import { NoteEvent, NoteAction, NoteContext, NoteReplyLength } from '../../enums/note.enums';
import { IInputButton } from '../../../../../fw/fw-shared/interfaces/i-input-button';
import { IRadioButton } from '../../../../../fw/fw-shared/components/fw-radio-button/interfaces/i-radio-button';

import {
  IMultiSelectOption, MultiSelectTexts,
  MultiSelectSettings, MultiSelectDropdown
} from '../../../../../fw/fw-shared/components/fw-multi-select-dropdown/index';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services/index';
import { UserPriviledgesService } from '../../../../../auth/services/index';
import { NoteService } from '../../services';

@Component({
  selector: 'note',
  templateUrl: './note.component.html',
  providers: [MetaDataService]
})
export class NoteComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() note: Note;
  @Input() operation: string;
  @Input() noteConfigFields: INoteConfigFields;
  @Input() errorData: IErrorData[] = null;
  @Input() errorAction: string;
  @Input() errorId: number;
  @Input() errorMessage: string = '';
  //the following inputs are only needed for create, at this time
  @Input() providerUsers: IProviderUser[] = null;
  @Input() workOrders: IWorkOrder[] = null;
  @Input() purchaseOrders: IPurchaseOrder[] = null;
  @Input() showRecipients: boolean = true;

  @ViewChild('puMultiSelect') public puMultiSelect: MultiSelectDropdown;

  @Output() public noteEvent = new EventEmitter<IHomEventEmitter>();

  public multiSelectOptions: IMultiSelectOption[];
  public multiSelectLabels: MultiSelectTexts;
  public multiSelectSettings: MultiSelectSettings;
  public form: FormGroup;
  public displayFields: string[] = ['postIt', 'noteSubject', 'noteText', 'isPrivate', 'applyToProject', 'purchaseOrder', 'workOrder'];
  public sendToList: ISendToUser[] = [];
  public cbConfig: IInputButton;
  public contextContact: string = '';
  public contextProject: string = '';
  public contextPurchaseOrder: string = '';
  public contextWorkOrder: string = '';
  public contextButtons: IRadioButton[] = [];
  public forWo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public forPo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public charactersLeft$: BehaviorSubject<number> = new BehaviorSubject(0);
  public noteDef$: BehaviorSubject<IFieldDefinition> = new BehaviorSubject(null);

  printUrl: string;
  projectContext: string;
  defaultContext: number = 0;
  sendExternal: boolean = false;
  sendForm = new FormGroup({ 'providerUserMultiSelect': new FormControl() });
  selectedContext: string;
  contextModified: boolean = false;
  subscription: Subscription = new Subscription();
  initialized: boolean;

  constructor(public mds: MetaDataService,
    public ups: UserPriviledgesService,
    public noteService: NoteService,
    @Inject(appConstants) public myConstants: IAppConstants) {
    this.charactersLeft$.next(NoteReplyLength.externalMax);
  }

  public onCancel(): void {
    const emitter: IHomEventEmitter = { requestor: 'note', event: this.myConstants.emitterEventClose, action: '', data: null };
    this.noteEvent.emit(emitter)
  }

  //collect the data and send back to the parent
  public onCreate(): void {
    const formData: INote = this.form.getRawValue();
    let note = this.setCommonProperties(formData);

    //grandparent is currently only applicable to project dependent notes like wo, po, contact
    //coded to handle others
    if (this.noteConfigFields.grandParentId !== 0 && this.noteConfigFields.grandParentKey) {
      note[this.noteConfigFields.grandParentKey] = this.noteConfigFields.grandParentId;
    }

    if (this.projectContext === NoteContext.project) {
      //the user got to choose the context
      note.workOrder_workOrderId = this.selectedContext === NoteContext.workOrder &&
        formData['workOrder'] && formData['workOrder'].hasOwnProperty('workOrderId') ? formData['workOrder']['workOrderId'] : null;
      note.purchaseOrder_purchaseOrderId = this.selectedContext === NoteContext.purchaseOrder &&
        formData['purchaseOrder'] && formData['purchaseOrder'].hasOwnProperty('purchaseOrderId') ? formData['purchaseOrder']['purchaseOrderId'] : null;
      note.contact_contactId = this.selectedContext === NoteContext.contact ? this.noteConfigFields.contactId : null;
    } else {
      //if not project context sensitive, the list definition/config will contain the parent id
      note[this.noteConfigFields.parentKey] = this.noteConfigFields.parentId;
    }

    note.createDate = new Date().toDateString();
    let notifyList: INotification[] = [];
    this.sendToList.forEach((x: ISendToUser) => {
      let item: INotification = new Notification();
      item.providerUser_providerUserId = x.providerUser.providerUserId;
      item.isEmail = x.sendEmail ? x.sendEmail : false;
      notifyList.push(item);
    });
    let vm: INoteViewModel = {
      model: note,
      noteNotifyList: notifyList,
      sendExternal: this.forPo$.getValue() ? this.sendExternal : false
    }
    const emitter: IHomEventEmitter = { requestor: 'note', event: this.myConstants.emitterEventCreate, action: NoteAction.fromNote, data: vm };
    this.noteEvent.emit(emitter)
  }

  public onUpdate(): void {
    const formData: INote = this.form.getRawValue();
    let note = this.setCommonProperties(formData);
    const emitter: IHomEventEmitter = { requestor: 'note', event: this.myConstants.emitterEventUpdate, action: NoteAction.fromNote, data: note };
    this.noteEvent.emit(emitter)
  }

  ngOnInit() {
    this.contextContact = NoteContext.contact;
    this.contextProject = NoteContext.project;
    this.contextPurchaseOrder = NoteContext.purchaseOrder;
    this.contextWorkOrder = NoteContext.workOrder;
    this.projectContext = this.noteService.findContext(this.noteConfigFields);
    this.initForm();
    this.setMultiSelectProperties();
    const maxChars: number = this.sendExternal ? NoteReplyLength.externalMax : NoteReplyLength.internalMax;

    this.charactersLeft$.next(maxChars - this.note.noteText.length);

    this.subscription.add(this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null))
      .subscribe((obj: IValueChanged) => {
        if (obj.key === 'noteText') {
          if (this.forPo$.getValue() && this.sendExternal) {
            this.charactersLeft$.next(NoteReplyLength.externalMax - obj.value.length);
          } else {
            this.charactersLeft$.next(NoteReplyLength.internalMax - obj.value.length);
          }
        }
      }));
  }

  ngAfterViewInit(): void {
    if (this.operation === this.myConstants.operationTypeCreate || !this.noteConfigFields.isProjectNote) {
      this.selectContext('');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['operation'] && !changes['operation'].isFirstChange()) {
      this.initForm();
    }

    if (changes['errorId'] || changes['errorAction'] || changes['errorData']) {
      if (this.note.noteId === this.errorId) {
        this.mds.setErrorMessages(this.errorData);
      }
    }
  }

  initForm() {
    this.setRadioButtonConfigs();
    this.mds.setFieldDefinitions(this.noteConfigFields.storeName);

    if (this.showRecipients && this.puMultiSelect) {
      this.puMultiSelect.uncheckAll();
    }
    this.sendToList = [];
    this.printUrl = this.ups.getPrintUrl(this.note);
    this.noteDef$.next(this.mds.getFieldDefinition('noteText'));

    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.note, this.operation);
    this.resetWoPoValidators();
  }

  setRadioButtonConfigs(): void {
    this.defaultContext = 3;
    this.contextButtons = [
      {
        label: 'Customer',
        value: NoteContext.contact,
        id: 'cust',
        checked: false
      },
      {
        label: 'Purchase Order',
        value: NoteContext.purchaseOrder,
        id: 'po',
        checked: false
      },
      {
        label: 'Work Order',
        value: NoteContext.workOrder,
        id: 'wo',
        checked: false
      },
      {
        label: 'Project',
        value: NoteContext.project,
        id: 'proj',
        checked: true
      }
    ];

    this.cbConfig = {
      label: {
        label: 'Send Externally',
        alignRight: true,
        title: !this.noteConfigFields.hasExternalInterface ? 'External interface not set up for this provider.  Cannot send externally' : 'Send note to general contractor'
      }
    };
  }

  //LISTENER:  Event emitted by multi-select
  addSendToEvent(providerUserId: number) {
    let providerUser = this.providerUsers.find(x => x.providerUserId === providerUserId); 
    if (providerUser) {
      this.sendToList.push({ providerUser: providerUser, sendEmail: false });
    }
  }

  //LISTENER:  Event emitted by multi-select
  removeSendToEvent(providerUserId: number) {
    const index = this.sendToList.findIndex(x => x.providerUser.providerUserId === providerUserId);
    if (index > -1) {
      this.sendToList.splice(index, 1);
    }
  }

  // Add/Remove user from the list of users to send emails to
  onMailBox(providerUserId: number) {
    let sendToUser = this.sendToList.find(x => x.providerUser.providerUserId === providerUserId);
    if (sendToUser) {
      sendToUser.sendEmail = !sendToUser.sendEmail;
    }
  }

  onSendExternal(val: boolean): void {
    this.sendExternal = val;
    const maxLength = this.sendExternal ? NoteReplyLength.externalMax : NoteReplyLength.internalMax;
    let def: IFieldDefinition = this.mds.getFieldDefinition('noteText');
    def.maxStringLength = maxLength;
    this.noteDef$.next(def);
    //update note count
    const cntrl: AbstractControl = this.form.controls['noteText'];
    if (cntrl) {
      if (this.forPo$.getValue() && this.sendExternal) {
        this.charactersLeft$.next(NoteReplyLength.externalMax - cntrl.value.length);
      } else {
        this.charactersLeft$.next(NoteReplyLength.internalMax - cntrl.value.length);
      }
    }

  }

  //radio button selection
  selectContext(context: string): void {
    if (!this.contextModified && context) this.contextModified = true;
    this.selectedContext = context;
    this.forWo$.next(context === NoteContext.workOrder);
    this.forPo$.next(context === NoteContext.purchaseOrder);
    const event = context === NoteContext.workOrder && (!this.workOrders || !this.workOrders.length) ? NoteEvent.getWorkOrders
      : context === NoteContext.purchaseOrder && (!this.purchaseOrders || !this.purchaseOrders.length) ? NoteEvent.getPurchaseOrders
        : '';

    if (context && event) {
      const emitter: IHomEventEmitter = { requestor: 'note', event: event, action: '', data: null };
      this.noteEvent.emit(emitter);
    }

    this.resetWoPoValidators();
  }

  resetWoPoValidators(): void {
    const forWo: boolean = this.forWo$.getValue();
    const forPo: boolean = this.forPo$.getValue();
    let woCtrl = this.form.controls['workOrder'];
    let poCtrl = this.form.controls['purchaseOrder'];

    if (forWo) {
      woCtrl.enable();
    } else {
      woCtrl.disable();
      if (woCtrl.hasError) {
        woCtrl.setErrors(null);
      }
    }
    if (forPo) {
      poCtrl.enable();
    } else {
      poCtrl.disable();
      if (poCtrl.hasError) {
        poCtrl.setErrors(null);
        this.onSendExternal(false);
      }
    }
  }

  //Set Multi Select Overrides
  setMultiSelectProperties() {
    if (!this.showRecipients || !this.providerUsers) return;

    let multiSelectLabels = new MultiSelectTexts();
    multiSelectLabels.defaultTitle = 'Select...';

    let multiSelectSettings = new MultiSelectSettings(true, false, false, 1, this.providerUsers.length, this.providerUsers.length);

    multiSelectSettings.totalItems = this.providerUsers.length;
    multiSelectSettings.itemsPerPage = this.providerUsers.length;
    multiSelectSettings.enableSearch = true;
    multiSelectSettings.dynamicTitleMaxItems = 1;
    multiSelectSettings.uncheckAllOnReload = true;
    multiSelectSettings.showPagingInfo = false;


    let multiSelectOptions: IMultiSelectOption[] = [];
    this.providerUsers.forEach(user => {
      //multiSelectOptions.push({ id: user.providerUserId, name: user.fullName });
      multiSelectOptions.push({ id: user.providerUserId, name: user['entityLabel'] });
    });

    this.multiSelectLabels = multiSelectLabels;
    this.multiSelectSettings = multiSelectSettings;
    this.multiSelectOptions = multiSelectOptions;
  }

  setCommonProperties(formData: INote): INote {
    let note = { ...this.note };
    note.noteSubject = formData.noteSubject;
    note.noteText = formData.noteText;
    note.isPrivate = formData.isPrivate;
    note.postIt = formData.postIt;
    note.applyToProject = formData.applyToProject;
    note.updateDate = new Date().toDateString();
    note.externalData = null;

    return note;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
