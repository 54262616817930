import { Injectable} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, filter, first, take } from 'rxjs/operators';

import { IProviderRole } from '../../view-models';
import { IListFilter, OrderTerm } from '../../../../fw/dynamic-list/interfaces';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
import { ISelectListData, IStoreState, getSelectionListByTypeByParent, GetEntityListByMethodParams }
  from '../../../../shared/store/selectionLists/index';
import { InstallerSelectList } from '../enums/installer.enums';


@Injectable()
export class InstallerRolesResolver  {

  filter: IListFilter = {
    isLookup: false, 
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true, //do not want paged data
    currentPage: 1,
    searchTerm: [],
    orderTerm: [new OrderTerm('providerRoleName')]
  };

  constructor(public rootStore: Store<IStoreState>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IProviderRole[]> {
    this.requestData();

    return this.waitForDataToLoad();
  }

  requestData(): void {
    this.rootStore.select(getSelectionListByTypeByParent(InstallerSelectList.installerRole, -1))
      .pipe(take(1))
      .subscribe((list: ISelectListData) => {
        if (!list || !list.data) {
          this.rootStore.dispatch(
            new GetEntityListByMethodParams('ProviderRole', 'GetIWCTProviderRoles', '', this.filter, InstallerSelectList.installerRole));
        }
      });
  }

  waitForDataToLoad(): Observable<IProviderRole[]> {
    return this.rootStore.select(getSelectionListByTypeByParent(InstallerSelectList.installerRole, -1))
      .pipe(
        filter((list: ISelectListData) => list !== null && list.data !== null),
        map((list: ISelectListData) => { return list.data; }),
        first()
      );
  }
}
