

<div [ngClass]="{ 'loading-mask': ((loading$ | async)  || !(metaDataExists$ | async)) }">
  <div *ngIf="listDefinition" class="notes">
    <div class="app-list-toolbar">
      <div class="app-list-toolbar__item">
        <button type="button" class="app-btn-icon app-btn-icon--success "
                [ngClass]="{'app-btn-icon__animated' : (providerUsers$ | async)?.length}"
                (click)="addNote()"
                title="Add New Note"
                [disabled]="!(providerUsers$ | async)?.length || (operation$ | async) || !canIEdit">
          <i class="fas fa-plus"></i>
        </button>
        <button class="app-btn-icon app-btn-icon--primary"
                (click)="dispatchGet()" title="Refresh Results"
                [disabled]="!(providerUsers$ | async)?.length || (operation$ | async)">
          <i class="fas fa-sync"></i>
        </button>
        <button *ngIf="noteContext === contextProject" class="app-btn-icon app-btn-icon--print"
                (click)="print()" title="Print Project Notes"
                [disabled]="!(notes$ | async)?.length">
          <i class="fas fa-print"></i>
        </button>
      </div>
    </div>

    <!--for create-->
    <div *ngIf="(operation$ | async) === myConstants.operationTypeCreate" [ngClass]="{ 'loading-mask': ( !(providerUsers$ | async)?.length  || !(metaDataExists$ | async) ) }">
      <note *ngIf="(metaDataExists$ | async) && (providerUsers$ | async)?.length && (activeNote$ | async) "
            [note]="newNote"
            [operation]="operation$ | async"
            [noteConfigFields]="noteConfigFields"
            [providerUsers]="providerUsers$ | async"
            [errorData]="errorData$ | async"
            [errorAction]="errorAction$ | async"
            [errorId]="errorNoteId$ | async"
            [errorMessage]="errorMessage"
            [purchaseOrders]="pos$ | async"
            [workOrders]="wos$ | async"
            (noteEvent)="onNoteEvent($event)">
      </note>
    </div>

    <notes *ngIf="(notes$ | async)?.length"
           [noteConfigFields]="noteConfigFields"
           [notes]="notes$ | async"
           [pageMetaData]="pageMetaData$ | async"
           [operation]="operation$ | async"
           [activeNote]="activeNote$ | async"
           [activeNotifications]="activeNotifications$ | async"
           [canIEdit]="canIEdit"
           [errorData]="errorData$ | async"
           [errorAction]="errorAction$ | async"
           [errorId]="errorNoteId$ | async"
           [errorMessage]="errorMessage$ | async"
           (noteListEvent)="onNoteListEvent($event)"
           (noteEvent)="onNoteEvent($event)">
    </notes>
    <div *ngIf="!(notes$ | async)?.length" class="dynamic-list__no-data">
      No Records Found
    </div>
  </div>
</div>
