
export interface IProviderLocation {
  providerLocationId: number;
  accountingInterface_accountingInterfaceId: any;
  entityLabel: string;
  entityId: number;
  fks: {};
  isPrimary: boolean;
  locationName: string;
  metaData: {};
  providerLocationContact_contactId: number;
  provider_providerId: number;
  rowVersion: string;
  serviceIds: string;
  serviceNames: string;
  warrantyBranch_branchId: number;
  createDate: string, //example: "/Date(1306380319000)/",
  updateDate: string,
  createdBy: number,
  updatedBy: number,

  //properties used in update and create - middle-tier expectations
  updateCertifications: boolean,
  selectedLocationServiceCertifications: number[],
  certificationId: number,
}



export class ProviderLocation implements IProviderLocation {

  constructor(
    public providerLocationId:number = 0,
    public accountingInterface_accountingInterfaceId: number = 0,
    public entityLabel = "",
    public entityId: number = 0,
    public fks: any = {},
    public isPrimary: boolean = false,
    public locationName = "",
    public metaData: any = {},
    public providerLocationContact_contactId: number = 0,
    public provider_providerId: number = 0,
    public rowVersion: string = "",
    public serviceIds = "",
    public serviceNames = "",
    public warrantyBranch_branchId: number = 0,
    public createDate: string = "", //example: "/Date(1306380319000)/",
    public updateDate: string = "",
    public createdBy: number = 0,
    public updatedBy: number = 0,

  //properties used in update and create - middle-tier expectations
  public updateCertifications: boolean = false,
  public selectedLocationServiceCertifications: number[] = [],
  public certificationId: number = 0,

  ) {
  }

}
