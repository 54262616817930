import { Component, Input} from '@angular/core';
import { IEntityDocument } from '../../../../app/portals/view-models/index_two';
import { HomCommonUtility } from '../../../../shared/services';

@Component({
  selector: 'fw-document-properties-list-item',
  templateUrl: './document-properties-list-item.component.html'
})
export class DocumentPropertiesListItemComponent  {
  @Input() row: IEntityDocument;

  constructor(
    public homUtil: HomCommonUtility
  ) { }

 } 
