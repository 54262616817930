import { Component, Input, Output,  EventEmitter,  Inject} from '@angular/core';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IFormDefinition } from '../../../../dynamic-forms/index';

@Component({
    selector: 'fw-nav-buttons',
  templateUrl: './fw-nav-buttons.component.html'
})
export class NavButtonsComponent  {
  @Input() operation: string;
  @Input() disabled: boolean = false;
  @Input() formDefinition: IFormDefinition;

  @Output() public navigateEvent = new EventEmitter<string>();

    constructor(
      @Inject(appConstants) public myConstants: IAppConstants) { }

    public onNavigate(action: string) {
      this.navigateEvent.emit(action);
    }

}
