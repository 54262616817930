import { Component, OnInit, Input, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces/index';
import { ProjectListStore } from '../../../project/enums/project.enums';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IProject } from '../../../view-models';
import { UtilitiesEvent } from '../../enums/utilities.enums';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { UtilitiesService } from '../../services/utilities.service';
import * as fromStore from '../../../../../fw/dynamic-list/store/index';

@Component({
  selector: 'customer-search-detail',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './customer-search-detail.component.html'
})
export class CustomerSearchDetailComponent implements OnInit, OnDestroy {

  public listDefinition: IListDefinition;
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  customerName: string;
  contactId: number;
  subscription: Subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public utilitiesService: UtilitiesService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public store: Store<fromStore.IAllDynamicData>,
    public modalService: ModalService,
    public dynamicDetailService: DynamicDetailService ) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case UtilitiesEvent.peekWOSummary:
        this.showMiniWOSummary(event.data);
        break;
      case UtilitiesEvent.peekPOSummary:
        this.showMiniPOSummary(event.data);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition = this.utilitiesService.loadCustomerSearchDetailListDefinition(this.contactId = +paramMap.get("id"));
      this.setDetailConfig(paramMap);
    });
  }
  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.useRouterOutlet = false;
    params.showNav = true;
    params.showTitle = true;
    params.showCancel = true;
    this.detailConfig$.next(params);
  }

  showMiniWOSummary(data: IProject): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.projectWorkOrders,
      key: 'project_projectId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.projectId //to show wos by project id
    }

    this.modalService.open({
      title: 'Work Order Summary for Project'.concat(' ', data.projectId.toString()),
      path: 'user-dash-work-order-summary',
      id: data.projectId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

  showMiniPOSummary(data: IProject): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: ProjectListStore.projectPurchaseOrders,
      key: 'project_projectId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.projectId //to show wos by project id
    }

    this.modalService.open({
      title: 'Purchase Order Summary for Project'.concat(' ', data.projectId.toString()),
      path: 'user-dash-purchase-order-summary',
      id: data.projectId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: params,
      hasTabs: false
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
