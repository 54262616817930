<div class="user-recents-container">
  <fw-component-title [title]="'My Bookmarks'"></fw-component-title>
  <div class="user-recents" [ngClass]="{'user-recents--visible': mobileTitle}">

    <nav class="user-recents__nav" [ngClass]="{'user-recents__nav--hidden': mobileTitle}">
      <ul class="user-recents__nav__ul">
        <li class="user-recents__nav__ul__li" (click)="showUserRecentsList('Projects')">
          Projects
          <i class="far fa-angle-right user-recents__nav__ul__li__btn"></i>
        </li>
        <li class="user-recents__nav__ul__li" (click)="showUserRecentsList('Installers')">
          Installers
          <i class="far fa-angle-right user-recents__nav__ul__li__btn"></i>
        </li>
      </ul>
    </nav>


    <div class="user-recents__con user-recents__con--projects" [ngClass]="{'user-recents__con--visible': mobileTitle === 'Projects'}">
      <button class="app-btn-icon app-btn-icon--primary user-recents__con__btn" (click)="showUserRecentsList()">
        <i class="far fa-angle-left"></i>
      </button>
      <div class="user-recents__con__wrapper">
        <fw-section-title title="Projects" [underline]="true"></fw-section-title>
        <div class="user-recents__con__wrapper__items">
          <div class="user-recents__con__wrapper__items__item user-recents__con__wrapper__items__item--none" *ngIf="(userRecentsService.projectUserRecents$ | async).length === 0">No bookmarks found</div>
          <div class="user-recents__con__wrapper__items__item" *ngFor="let item of (userRecentsService.projectUserRecents$ | async)">
            <user-recent [row]="item" (customEvent)="customEvent($event)"></user-recent>
          </div>
        </div>
        </div>
    </div>

    <div class="user-recents__con user-recents__con--installers" [ngClass]="{'user-recents__con--visible': mobileTitle === 'Installers'}">
      <button class="app-btn-icon app-btn-icon--primary user-recents__con__btn" (click)="showUserRecentsList()">
        <i class="far fa-angle-left"></i>
      </button>
      <div class="user-recents__con__wrapper">
        <fw-section-title title="Installers" [underline]="true"></fw-section-title>
        <div class="user-recents__con__wrapper__items">
          <div class="user-recents__con__wrapper__items__item user-recents__con__wrapper__items__item--none" *ngIf="(userRecentsService.installerUserRecents$ | async).length === 0">No bookmarks found</div>
          <div class="user-recents__con__wrapper__items__item" *ngFor="let item of (userRecentsService.installerUserRecents$ | async)">
            <user-recent [row]="item" (customEvent)="customEvent($event)"></user-recent>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

