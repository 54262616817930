import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { HomPipesModule } from 'hom-lib/hom-pipes';
import { HomSignatureModule } from 'hom-lib/hom-signature';
import { SharedModule } from '../../shared/shared.module';
import { FwSharedModule } from '../fw-shared/fw-shared.module';
import { DynamicFormModule } from '../dynamic-forms/dynamic-form.module';
import { DynamicDetailModule } from '../dynamic-detail/dynamic-detail.module';
import { DynamicListRoutingModule } from './dynamic-list-routing.module';

import { dynamicDataReducers } from './store/reducers/feature.reducer'; 
import { DynamicListEffects, DynamicObjectEffects, MetaDataEffects } from './store/index'; 

//containers
import { DynamicListComponent  } from './containers/dynamic-list.component';

//services
import {
  DynamicListService,
  ProjectDynamicListService,
  PoImportDynamicListService,
  AdminDashDynamicListService,
  ManagerDashDynamicListService,
  UserDashDynamicListService,
  UtilitiesDynamicListService,
  WarrantyDynamicListService,
  AdminDynamicListService,
  ComplianceDynamicListService
} from './services/index';

import { DynamicFieldService } from '../dynamic-forms/services/dynamic-field.service';  
import { UserPriviledgesService } from '../../auth/services/index'; 

//components
import {
  DynamicFilterFieldComponent,
  DynamicFilterFormComponent,
  DynamicListHeaderItemComponent,
  DynamicListHeaderRowComponent,
  DynamicListFilterRowComponent,
  DynamicListDataRowComponent,
  DynamicListNavDataRowComponent,
  DynamicListItemComponent,
  DynamicListToolBarComponent,
  DynamicListDropDownComponent,
  UnavailableReasonsComponent,
  SpecialInstructionsComponent,
  CustomerFlagListItemComponent,
  CustomerAlertComponent,
  WorkOrderInfoComponent,
  DynamicListUserFilterComponent,
  DynamicListUserFiltersComponent,  ProjectCustomerContactComponent,
  InstallerCertificationsExpirationDateComponent,
  DynamicListOverflowBtnsComponent,
  DynamicListDataRowInfoComponent,
  DynamicListSortSettingComponent,
  DynamicListSortSettingsComponent,
  DocumentActionsListItemComponent,
  DocumentPropertiesListItemComponent,
  InstallerCrewListItemComponent
} from './components/index';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    HomPipesModule,
    SharedModule,
    FwSharedModule,
    DynamicFormModule,
    DynamicDetailModule,
    DynamicListRoutingModule,
    HomSignatureModule,
    StoreModule.forFeature('dynamicData', dynamicDataReducers,),
    EffectsModule.forFeature([DynamicListEffects, DynamicObjectEffects, MetaDataEffects])

  ],
  declarations: [
    DynamicFilterFieldComponent,
    DynamicFilterFormComponent,
    DynamicListFilterRowComponent,
    DynamicListHeaderItemComponent,
    DynamicListHeaderRowComponent,
    DynamicListDataRowComponent,
    DynamicListNavDataRowComponent,
    DynamicListDropDownComponent,
    DynamicListItemComponent,
    DynamicListToolBarComponent,
    UnavailableReasonsComponent,
    SpecialInstructionsComponent,
    WorkOrderInfoComponent,
    CustomerFlagListItemComponent,
    CustomerAlertComponent,
    ProjectCustomerContactComponent,
    DynamicListUserFilterComponent,
    DynamicListUserFiltersComponent,
    DynamicListComponent,
    InstallerCertificationsExpirationDateComponent,
    DynamicListOverflowBtnsComponent,
    DynamicListDataRowInfoComponent,
    DynamicListSortSettingComponent,
    DynamicListSortSettingsComponent,
    DocumentActionsListItemComponent,
    DocumentPropertiesListItemComponent,
    InstallerCrewListItemComponent
  ],
  exports: [
    DynamicFilterFieldComponent,
    DynamicFilterFormComponent,
    DynamicListFilterRowComponent,
    DynamicListHeaderRowComponent,
    DynamicListDataRowComponent,
    DynamicListNavDataRowComponent,
    DynamicListItemComponent,
    DynamicListDropDownComponent,
    DynamicListToolBarComponent,
    UnavailableReasonsComponent,
    SpecialInstructionsComponent,
    CustomerFlagListItemComponent,
    CustomerAlertComponent,
    WorkOrderInfoComponent,
    ProjectCustomerContactComponent,
    DynamicListUserFilterComponent,
    InstallerCertificationsExpirationDateComponent,
    DynamicListOverflowBtnsComponent,
    DynamicListDataRowInfoComponent,
    DynamicListComponent
  ],

})
export class DynamicListModule {
  static forRoot(): ModuleWithProviders<DynamicListModule> {
    return {
      ngModule: DynamicListModule,
      providers: [
        DynamicListService,
        DynamicFieldService,
        UserPriviledgesService,
        ProjectDynamicListService,
        PoImportDynamicListService,
        AdminDashDynamicListService,
        ManagerDashDynamicListService,
        UserDashDynamicListService,
        UtilitiesDynamicListService,
        WarrantyDynamicListService,
        AdminDynamicListService,
        ComplianceDynamicListService
      ]
    };
  }
}
