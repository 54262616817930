<admin-title-bar [title]="title"
                 [info]="title"
                 [disabled]="isWorkingLoc || isWorkingBranch || !haveAllLocations"
                 [currentAccess]="workingLocations$ | async"
                 [specifierType]="specifierType"
                 (btnEvent)="onEvent($event)">
</admin-title-bar>
<div [ngClass]="{ 'loading-mask': isWorkingLoc || isWorkingBranch }">
  <div class="user-access-items">
    <ng-container *ngFor="let location of (workingLocations$ | async)">
      <user-access-item customClass="user-access-item--{{location.name.replace(' ', '-').replace('.','').toLowerCase()}}"
                        [item]="location"
                        [name]="location.name"
                        (deleteEvent)="onLocationDelete( $event)">
      </user-access-item>
      <ng-container *ngFor="let branch of location.workingBranches">
        <user-access-item customClass="user-access-item--{{location.name.replace(' ', '-').replace('.','').replace(',', '').toLowerCase()}} user-access-item--sub-item"
                          [item]="branch"
                          [name]="branch.name"
                          (deleteEvent)="onEvent( $event)">
        </user-access-item>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="(orphanBranches$ | async).length > 0">
    <admin-title-bar [title]="orphanTitle"
                     [info]="'Under Construction'"
                     [disabled]="true"
                     [currentAccess]="orphanBranches$ | async"
                     [specifierType]="specifierType"
                     [showBtns]="false"
                     (btnEvent)="onEvent($event)">
    </admin-title-bar>

    <div class="user-access-items">
      <ng-container *ngFor="let branch of (orphanBranches$ | async)">
        <user-access-item customClass="user-access-item--sub-item"
                          [item]="branch"
                          [name]="branch.name"
                          (deleteEvent)="onEvent($event)">
        </user-access-item>
      </ng-container>
    </div>
  </ng-container>

</div>

