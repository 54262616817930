export interface IMultiSelectTexts {
    checkAll: string;
    uncheckAll: string;
    checked: string;
    checkedPlural: string;
    searchPlaceholder: string;
    defaultTitle: string;
    allSelected: string;
    findHelpText: string;
    findTitle: string;
}

  export class MultiSelectTexts implements IMultiSelectTexts {

     constructor(
        public checkAll: string =  'Select all',
        public uncheckAll: string =  'Unselect all',
        public checked: string =  'item selected',
        public checkedPlural: string =  'items selected',
        public searchPlaceholder: string =  'Search...',
        public defaultTitle: string =  'Search...',
        public allSelected: string =  'All selected',
        public findHelpText: string =  'Use Search to locate by name.',
        public findTitle: string = 'Run Search'
      ) { }

}