import { Component, OnInit, Input,    AfterViewInit, ViewChild } from '@angular/core';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { PoImportEvent } from '../../../../app/portals/po-import/enums/po-import.enums';

@Component({
  selector: 'fw-dynamic-list-dropdown',
  templateUrl: './dynamic-list-dropdown.component.html'
})
export class DynamicListDropDownComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() row: any;
  @Input() eventName: string;
  @Input() unselectedLabel: string = 'Choose.....';

  @ViewChild('dlddSelect') dlddSelect: HTMLSelectElement;

  constructor(public emitterService: HomEventEmitterService) { }

  ngOnInit() {
    this.eventName = !this.eventName ? PoImportEvent.selectImportToProject : this.eventName;
  }

  ngAfterViewInit(): void {
    if (this.data && this.data.length > 0 && this.dlddSelect) {
      let selectEl: HTMLSelectElement = this.dlddSelect['nativeElement'];
      if (selectEl && selectEl.options.length > 1) {
        selectEl.options[1].selected = true;
        this.onChange(this.data[0]);
      }
    }
  }

  /* Emit a custom list event so list event knows to roll up to parent component */
  onChange(projectId: number) {
    let event: IHomEventEmitter = {
      requestor: 'dynamic-list-dropdown', event: this.eventName, action: '', data: { row: this.row, projectId: projectId }
    };
    this.emitterService.emitListCustomEvent(event);
  }
 }
