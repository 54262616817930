<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <div [ngClass]="{ 'loading-mask': (working$ | async)}">
    <ng-container *ngIf="objectData && mds.fieldDefinitionsLoaded && mds.selectionListsLoaded">
      <form *ngIf="form" [formGroup]="form" novalidate class="form portal__form" [ngClass]="{'portal__form--edit': operation !== myConstants.operationTypeDetails}">
        <fw-dynamic-field [field]="mds.getFieldDefinition('generalContractorName')"
                          [form]="form"
                          [operation]="operation"
                          [selectItems]="generalContractors">
        </fw-dynamic-field>
        <div class="float-label">
          <select #pgm
                  [disabled]="operation === myConstants.operationTypeDetails"
                  (change)="changeProgram(pgm.value)">
            <option selected value="0">Choose...</option>
            <option *ngFor="let pgm of (programs$ | async)"
                    [value]="pgm.programId"
                    [selected]="!objectData ? false : objectData.program_programId === pgm.programId">
              {{pgm.programName}}
            </option>
          </select>
          <label [title]="mds.getLabel('program')">{{ mds.getLabel('program') }}</label>
        </div>
        <fw-dynamic-field [field]="mds.getFieldDefinition('service')"
                          [form]="form"
                          [operation]="operation"
                          [selectItems]="services">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('lienWaiver')"
                          [form]="form"
                          [operation]="operation"
                          [selectItems]="lienWaivers">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('measureService')"
                          [form]="form"
                          [operation]="operation"
                          [selectItems]="measureServices">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('allowScheduleBeforeInventory')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('canRequirePermit')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('providerMarginLow')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
        <fw-dynamic-field [field]="mds.getFieldDefinition('providerMarginHigh')"
                          [form]="form"
                          [operation]="operation">
        </fw-dynamic-field>
      </form>
      <fw-component-footer [lastUpdate]="objectData && objectData.hasOwnProperty('updateDate') ? objectData.updateDate : '' "></fw-component-footer>
      <fw-crud-buttons *ngIf="form"
                       [operation]="operation"
                       [disabled]="!form.dirty || !form.valid || selectedProgramId === 0"
                       [showNext]="operation !== myConstants.operationTypeCreate"
                       (cancelEvent)="onCancel($event)"
                       (createEvent)="onCreate($event)"
                       (saveEvent)="onSave($event)">
      </fw-crud-buttons>
    </ng-container>
  </div>
</fw-dynamic-detail-container>
