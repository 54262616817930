import {  AbstractControl, ValidatorFn } from '@angular/forms';

// homNbrRange is the custom validator
export function ValidateNumericRange(min: number, max: number, isDecimal: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let newVal: string = !control  || !control.value ? '0' : control.value.toString();
    newVal = newVal.replace(/(?!^-)\D/g, '');

    newVal = newVal === '' ? '0' : newVal;
    if (isDecimal) {
      newVal = (parseInt(newVal.replace(/(?!^-)[^0-9]/g, '')) / 100)
        .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: "decimal", useGrouping: false });
    }
    const overMax = isDecimal ? parseFloat(newVal) > max : parseInt(newVal) > max;
    const belowMin = isDecimal ? parseFloat(newVal) < min : parseInt(newVal) < min;
    return overMax || belowMin ? { homNbrRange: true } : null;
  };
}
