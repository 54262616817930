
/* structure for data object for UPDATE in auth store reducer/action */
export interface IUpdateObject {
  objectType: string,
  methodName: string,
  objectData: any,
  arrayName: string; //typically null
}

export class UpdateObjectModel implements IUpdateObject {
  constructor(
    public objectType: string,
    public methodName: string,
    public objectData: any,
    public arrayName: string = 'models'
  ) { }
}
