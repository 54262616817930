import { Component, Input} from '@angular/core';
import {  IWorkOrderViewModel } from '../../../../app/portals/view-models';
import { ISmsContactViewModel } from '../../../../app/portals/view-models/i-sms-contact-view-model';

@Component({
  selector: 'fw-customer-alert',
  templateUrl: './customer-alert.component.html'
})
export class CustomerAlertComponent  {
  @Input() row: IWorkOrderViewModel | ISmsContactViewModel;

 }
