import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomEventEmitterService } from './hom-event-emitter.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
  ],
  exports: [
  ],
  providers: [
    HomEventEmitterService
  ]

})
export class HomEventEmitterModule {
  static forRoot(): ModuleWithProviders<HomEventEmitterModule> {
    return {
      ngModule: HomEventEmitterModule,
      providers: [
        HomEventEmitterService
      ]
    };
  }

}
