<div *ngIf="(listDefinition$ | async)">
  <fw-dynamic-list [vmListDefinition]="listDefinition$ | async"
                   [listTitle]="'Eligibility Summary'">
    <!--ng drop-->
    <div drop-below-list-title>
      <fw-calendar [singlePicker]="true"
                   [name]="'eligibilityAsOf'"
                   [label]="'Eligibility As Of'"
                   [placeHolder]="'Eligibility As Of'"
                   [isRequired]="true"
                   [dateValue]="dateValue"
                   (onSelected)="selectedDate($event)"></fw-calendar>
    </div>
  </fw-dynamic-list>
</div>

