import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, ListDefinition, IListColumn, ListColumn, ListFilter, ISearchTerm, IListButtonType, OrderTerm } from '../../../../../../fw/dynamic-list/interfaces/index';
import { ButtonType } from '../../../../../../fw/fw-shared/enums/button-type.enum';

import * as fromRoot from '../../../../../store/reducers/index';
import { SearchType } from '../../../../../../fw/dynamic-list/enums/search-type.enums';
import { UserPriviledgesService } from '../../../../../../auth/services';
import { IAppConstants, appConstants } from '../../../../../../shared/constants';
import { IKey, UpdateObjectCustomModel } from '../../../../../../fw/dynamic-list/store/interfaces/index';
import * as DynamicListActions from '../../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { INotification } from '../../../../view-models';
import { DynamicListService } from '../../../../../../fw/dynamic-list/services';

@Component({
  selector: 'user-notifications', 
  templateUrl: './user-notifications.component.html'
})
export class UserNotificationsComponent implements OnInit, OnDestroy {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  showSent: boolean = false;
  subscription: Subscription = new Subscription();
  public selectedItems: INotification[] = [];

  constructor( public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public userPriviledgesService: UserPriviledgesService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      const listType = paramMap.get('type');
      this.showSent = listType === 'sent';
      this.listDefinition$.next(null);
     this.listDefinition$.next(this.loadListDefinition());  
    });
  }

  loadListDefinition() {
    const listColumns: IListColumn[] = this.loadListColumns();
    const listObjectLabel: string = 'Notification';
    const listObjectController: string = 'Notification';
    const listStoreName: string = this.showSent ? 'sentNotifications' : 'notifications';
    const detailRoutePath = 'user-notification'; 
    const listRowKeyId = 'notificationId';
    const defaultSearch: ISearchTerm[] = this.showSent ? [{ term: 'Acknowledged', value: 'false', searchType: SearchType.Equals, columnName: 'acknowledged', fieldType: this.myConstants.dataTypeBool }] : [];

    let listFilter = new ListFilter;
    listFilter.isLookup = false;
    listFilter.getAll = false;
    listFilter.orderTerm = [new OrderTerm('createDate')];
    listFilter.searchTerm = defaultSearch;
    
    let listDefinition = this.dynamicListService.createListDefinition(null,
      listObjectLabel,
      listObjectController,
      listStoreName,
      listRowKeyId,
      listFilter, 
      listColumns,
      detailRoutePath);

    listDefinition.controllerMethod = 'ByProviderUser';
    listDefinition.routerOutlet = 'commonDetailOutlet';
    listDefinition.methodParameters = this.userPriviledgesService.currentUserId$.value.toString();
    if (this.showSent) {
      listDefinition.methodParameters += '?returnSentNotifications=true';
    }
    listDefinition.showFilter = true;

    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    if (!this.showSent) {
      crudButtons.push({ type: ButtonType.edit, defaults: null });
    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    if (!this.showSent) {
      listDefinition.groupSelect = {
        title: 'Acknowledge',
        enabled: true,
        allowSelectAll: false,
        eventName: 'acknowledge'
      };
    }

    return listDefinition;
  }

  loadListColumns(): IListColumn[] {
    let columnDefinitions: Array<IListColumn> = [];

    let colDef = new ListColumn('createDate');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    colDef = new ListColumn('noteSubject');
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    if (!this.showSent) {
      colDef = new ListColumn('createUserName');
      colDef.visibleOnMedium = false;
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);
    }

    if (this.showSent) {
      colDef = new ListColumn('recipientUserName');
      colDef.visibleOnMedium = false;
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);
    }

    colDef = new ListColumn('context@noteContext');
    columnDefinitions.push(colDef);

    colDef = new ListColumn('acknowledged');
    colDef.visibleOnMedium = false;
    colDef.visibleOnSmall = false;
    columnDefinitions.push(colDef);

    return columnDefinitions;
  }

  public onSelect(event: IHomEventEmitter) {
    switch (event.event) {
      case 'acknowledge':
        this.updateSelected(event);
        break;
      default:
        break;
    }
  }

  updateSelected(event: IHomEventEmitter): void {
    const item: INotification = event.data;
    const exists: number = this.selectedItems.findIndex(x => x.notificationId === item.notificationId);
    if (event.action) {
      if (exists === -1) {
        this.selectedItems.push(item);
      }
    } else {
      if (exists > -1) {
        this.selectedItems.splice(exists, 1);
      }
    }
  }

  public ackAndDelete() {
    const def = this.listDefinition$.getValue();
    const keyData: IKey = { storeName: def.storeName, parentId: def.parentId, key: def.rowKeyId, id: this.selectedItems[0].notificationId, skipDataUpdate: true };
    const emitter: IHomEventEmitter = { requestor: 'user-notifications', event: this.myConstants.emitterEventListReload, action: '', data: null };
    const updateObj = { notificationIds: this.selectedItems.map(x => x.notificationId) };
    const updateData = new UpdateObjectCustomModel(keyData, 'Notification', 'AcknowledgeAndDelete', updateObj, null, emitter);
    this.rootStore.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
    this.selectedItems = [];
    
  }

  public onPage(): void {
    this.selectedItems = [];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
