import { Component, OnInit, OnDestroy, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import {  map,  filter, take  } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

import { Certification } from '../../../view-models/index';
import { CreateObjectModel, UpdateObjectByIdModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';
//store actions, reducers, interfaces
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';

@Component({
  selector: 'certification-detail',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './certification-detail.component.html'
})
export class CertificationDetailComponent implements OnInit, OnDestroy  {

  myPathName: string = '';
  myStoreName: string = '';
  key: string = '';
  activeIndex:number = -1;
  operation: string;
  objectId: number = 0;
  controllerName: string;
  subscription: Subscription = new Subscription();

  public objectData$: Observable<Certification>;
  public displayFields = ['certificationName', 'isRequired', 'certificationDescription', 'certificationType', 'certificationLevel', 'validatesParent', 'global'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);

  constructor(
    public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public  dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.activeIndex = +paramMap.get('rowIndex');
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.myStoreName = paramMap.get('storeName');
      this.setDetailConfig(paramMap);
      this.getDetail();
    });

    this.subscription.add(this.emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e.requestor !== this.myPathName) {
          return;
        } //can change this to create event listeners for each object controller in stead
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            this.updateRecord(e);
            break;
          case this.myConstants.emitterEventCreate:
            this.createRecord(e);
            break;

          default:
            break;
        }
      }));
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    this.detailConfig$.next(params);
  }

  getDetail() {
    this.objectData$ = this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.myStoreName, -1, this.key, this.objectId)),
      map(entity => {
        return entity === undefined && this.operation === this.myConstants.operationTypeCreate ? new Certification() : entity
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.myStoreName, -1)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
          this.myPathName = listDefinition.detailRoutePath;
          this.controllerName = listDefinition.controllerName;
        }
      ));
  }

    /*
        Insert the new record
    */
    createRecord(e: IHomEventEmitter) {
      let certification =  this.setCommonProperties(e.data);
      certification.createDate = new Date().toDateString();

      const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
      const createData = new CreateObjectModel(this.myStoreName, -1, this.controllerName, 'Create', certification, null, emitter);
      this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
    }

    /*
        Update the record
    */
    updateRecord(e: IHomEventEmitter) {
      let certification =  this.setCommonProperties(e.data);
      certification.certificationId = this.objectId;
      const keyData: IKey = { storeName: this.myStoreName, parentId: -1, key: this.key, id: this.objectId }
      const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
      const updateData = new UpdateObjectByIdModel(keyData, this.controllerName, 'Update', this.key, this.objectId, certification, null, emitter);
      this.store.dispatch(new DynamicListActions.UpdateObjectByIdList({ updateData }));
    }

    /*
        Set common create/update properties
    */
  setCommonProperties(formData: Certification) {
    let certification = new Certification();

    certification.certificationName = formData.certificationName;
    certification.isRequired = formData.isRequired;
    certification.certificationDescription = formData.certificationDescription;
    if (formData.hasOwnProperty('certificationType')) {
      certification.certificationType_lookupValueId = formData['certificationType'].lookupValueId;
      certification.certificationTypeName = formData['certificationType'].lookupMeaning;
    }
    certification.certificationLevel = formData.certificationLevel['name'];
    certification.global = formData.global;
    certification.validatesParent = formData.hasOwnProperty('validatesParent') ? formData.validatesParent : certification.validatesParent;

    certification.updateDate = new Date().toDateString();

    return certification;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
