import { Component, Inject, Input, OnDestroy, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { cloneDeep, isArray, replace, isObject } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListFilter, ISearchTerm, IFilterChange, ListFilter, IParameterSearchType, OrderTerm } from '../../interfaces/index';
import { IResponseBase } from '../../../../shared/interfaces/index';
import {
  IMultiSelectOption,
  MultiSelectTexts, MultiSelectSettings
} from '../../../fw-shared/components/fw-multi-select-dropdown/index';
import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { SearchType } from '../../enums/search-type.enums';
import { ISearchTypeConfig, SearchTypeConfig, ISearchTypeOption } from '../../interfaces/i-search-type-config';
import { ISelectionList, ISelectListData } from '../../../../shared/store/selectionLists/selectionLists.reducer';

import * as fromRoot from '../../../../app/store/reducers/index';
import { getSelectionListByType, getSelectionListByTypeByParent } from '../../../../shared/store/selectionLists/selectionLists.selectors';
import { GetEntityListById, GetEntityListByMethodParams, GetEntityList, GetLookupList } from '../../../../shared/store/selectionLists/selectionLists.actions';
import { IFieldDefinition, DynamicFieldService } from '../../../dynamic-forms/index';
import { DomainObjectService } from '../../../../shared/services/domain-object.service'
import { UserPriviledgesService } from '../../../../auth/services/user-priviledges.service';
import { HomCommonUtility } from '../../../../shared/services/index';

@Component({
  selector: 'fw-dynamic-filter-form',
  templateUrl: './dynamic-filter-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFilterFormComponent implements OnChanges, OnDestroy {
  @Input() fieldMetaData: any;
  @Input() vmFieldDefinition: IFieldDefinition;
  @Input() operation: string;
  @Input() parentId: number;
  @Input() showFilter: boolean;
  @Input() defaultValue: ISearchTerm;

  public searchTypeConfig: ISearchTypeConfig;
  public mySearchTypeKey: string = 'searchTypes'; //this.searchTypeKey;
  public loading$: Observable<boolean>;
  public field$: BehaviorSubject<IFieldDefinition> = new BehaviorSubject(null);
  public filterForm$: BehaviorSubject<FormGroup> = new BehaviorSubject(null);
  public searchTypeSet: boolean = false;

  workingField: IFieldDefinition;
  searchTypeKey: string = 'searchTypes';
  selectList: { metaData: any; items: any[] } = { metaData: null, items: [] };
  defaultSearchType: ISearchTypeOption;
  //for multiselect only
  unFilteredTotalItems: number = 0;
  subscription: Subscription = new Subscription();
  selListSub: Subscription;

  constructor(public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public domainObjectService: DomainObjectService,
    public rootStore: Store<fromRoot.IState>,
    public userPriviledgesService: UserPriviledgesService,
    public homCommonUtility: HomCommonUtility,
    public decimalPipe: DecimalPipe,
    public dynamicFieldService: DynamicFieldService
  ) {

    this.subscription.add(emitterService.filterEventEmitted$.subscribe(
      (e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventFilterFieldFiltered:
            this.filterOfFilterEvent(e);
            break;
          default:
            break;
        }
      }));
  }

  public get isDisabled(): boolean {
    const hasForm: boolean = this.filterForm$.getValue() ? true : false;
    return !hasForm ? true
      : this.workingField.selectListDefinition && this.workingField.selectListDefinition.storeName && this.selectList.items.length > 0 ? false
        : !this.workingField.selectListDefinition || !this.workingField.selectListDefinition.storeName ? false
          : true;
  }

  resetForm(): void {
    const fieldControl = this.filterForm$.value.controls[this.vmFieldDefinition.key];
    if (!fieldControl) return;
    fieldControl.setValue('');
    //reset extra fields that may exist
    const startCtrl = this.filterForm$.value.controls[this.vmFieldDefinition.key.concat('_start')];
    const endCtrl = this.filterForm$.value.controls[this.vmFieldDefinition.key.concat('_end')];
    const percentCtrl = this.filterForm$.value.controls[this.vmFieldDefinition.key.concat('_percent')];
    if (startCtrl) {
      startCtrl.setValue('');
    }
    else if (endCtrl) {
      endCtrl.setValue('');
    }
    else if (percentCtrl) {
      percentCtrl.setValue('');
    } 
  }

  /*
    Adds the filter selection to the list of selectors. Blank is ok.
    Handles both search type and search value changes.
*/
  public onAdd(event: any) {
    if (!this.filterForm$.value || !this.vmFieldDefinition) {
      return;
    }
    // reset range field before processing search term
    // values have to be reset if switching between range/non-range or non-range/range
    this.resetRangeFields();
    if (this.filterForm$.value.controls[this.mySearchTypeKey].value !== SearchType.NoValue && this.filterForm$.value.controls[this.vmFieldDefinition.key].value === 'null') {
      this.resetForm();
    }
 
    const fieldControl = this.filterForm$.value.controls[this.vmFieldDefinition.key];
    const searchTypeControl = this.filterForm$.value.controls[this.mySearchTypeKey];
    const isAnArray: boolean = isArray(fieldControl.value);
    const term = this.dynamicFieldService.getFilterTerm(this.vmFieldDefinition);
    const convertedValue = this.getSearchValue();
    const requiredValuesSet: boolean = !searchTypeControl.value || !convertedValue ? false : true;

    let searchTerm: ISearchTerm = {
      term: term,
      value: requiredValuesSet ? convertedValue : '',
      searchType: requiredValuesSet ? searchTypeControl.value : '',
      fieldType: this.vmFieldDefinition.fieldType,
      columnName: this.vmFieldDefinition.key
    }

    //if value != '' and value is an array, need to load up array with pretty values not ids
    searchTerm.displayValues = isAnArray && searchTerm.value !== '' ? this.getDisplayValues(searchTerm.value) : null;
    this.onChanged(searchTerm);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.selListSub) {
      this.selListSub.unsubscribe();
    }
  }

  dispatchGet(entityName: string, storeName: string): void {
    this.subscription.add(this.rootStore.select(getSelectionListByType(storeName)) //get all buckets in the store that we are waiting to load
      .pipe(take(1))
      .subscribe((storeList: ISelectionList) => {
        if (storeList === undefined) {
          console.log('DEV ERROR:  Selection List bucket for: ', storeName, ' is not defined in selectionLists.reducer');
          return;
        }
        //if using custom resolver, container will need to resolve the list, filter will not resolve
        const parentId: number = this.getParentId();
        const data: any[] = storeList && storeList.objData
          ? storeList.objData.find(x => x.parentId === parentId)
            ? storeList.objData.find(x => x.parentId === parentId).data
            : null
          : null;
        if (!data) {
          if (this.workingField.selectListDefinition) {
            if (this.workingField.fieldType === this.myConstants.fieldTypeEntity ||
              this.workingField.fieldType === this.myConstants.fieldTypeEditableSelect ||
              this.workingField.fieldType === this.myConstants.fieldTypeFilterableSelect) {
              //determine the dynamic-list action to call
              const parentType = this.workingField.selectListDefinition.methodByKey.toLowerCase();
              if (this.workingField.selectListDefinition.methodName) {
                if (parentType.toLowerCase() === 'parentid') {
                  this.rootStore.dispatch(new GetEntityListById(entityName, this.workingField.selectListDefinition.methodName, this.parentId, this.workingField.selectListDefinition.listFilter, storeName));
                } else {
                  this.rootStore.dispatch(new GetEntityListByMethodParams(entityName, this.workingField.selectListDefinition.methodName, '', this.workingField.selectListDefinition.listFilter, storeName));
                }
              } else {
                this.rootStore.dispatch(new GetEntityList(entityName, this.workingField.selectListDefinition.listFilter, storeName));
              }
            } else {
              this.rootStore.dispatch(new GetLookupList({ typeName: entityName, api: this.fieldMetaData[this.workingField.selectListDefinition.objectType] }));
            }
          }
        }
      }));
  }

  /*
      Clears the form and loads the field and its value(s), if applicable
  */
  
  convertDefaultValue(): any {
    if (!this.defaultValue) return null;

    const defaultValue = this.defaultValue.value;
    if (defaultValue === null) return null;

    const displayType = this.dynamicFieldService.displayType(this.workingField, 'filter', '');
    switch (displayType) {
      case this.myConstants.fieldTypeMultiSelect:
      case this.myConstants.fieldTypeSelect:
      case this.myConstants.fieldTypeBoolean:
        if (!isArray(defaultValue)) {
          return this.workingField.fieldType === this.myConstants.fieldTypeBoolean ? [eval(defaultValue.toString().toLowerCase())] : [Number(defaultValue)];
        }
        return defaultValue;
      case this.myConstants.fieldTypeDateRangePicker:
      case this.myConstants.fieldTypeSingleDatePicker:
        const dateParts = this.dynamicFieldService.getDateNormalizedFilterTerm(defaultValue);
        return dateParts.isRelativeDateTerm ? dateParts.fixedTerm : defaultValue;
      default:
        return defaultValue;
    }
  }

  resetRangeFields(): void {
    const searchTypeControl = this.filterForm$.value.controls[this.mySearchTypeKey];
    let fieldControl = this.filterForm$.value.controls[this.vmFieldDefinition.key];
    if (!fieldControl || !searchTypeControl || !this.searchTypeConfig || !this.searchTypeConfig.options) {
      return;
    }
    const displayType = this.dynamicFieldService.displayType(this.workingField, 'filter', '');
    const hasRangeType: boolean = this.searchTypeConfig.options
      .find(x => x.value === SearchType.Range) === undefined ? false : true;
    const currentIsRangeType: boolean = searchTypeControl.value === SearchType.Range;

    if (hasRangeType) {
      //reset start and end as applicable
      const values = fieldControl.value ? fieldControl.value.split(' - ') : [];
      const hasStartAndEnd: boolean = values.length > 1;

      //update form field if changing from range type to non-range type
      //working with the date control if hasStartAndEnd and !currentIsRangeType
      if (hasStartAndEnd && !currentIsRangeType)  {
       
        let endControl = this.filterForm$.value.controls[this.workingField.key.concat('_end')];
        if (endControl) {
          endControl.setValue('');
        }
       fieldControl.setValue(values[0]);
      }

      //update form field if changing from non-range type to range type
      if (!hasStartAndEnd && currentIsRangeType) {
        let startControl = this.filterForm$.value.controls[this.workingField.key.concat('_start')];
        if (startControl) {
          startControl.setValue('');
        }
      }

      //no changes if going from non-range to non-range type
    }
  }

  getSearchValue(): any {
    const fieldControl = this.filterForm$.getValue().controls[this.vmFieldDefinition.key];
    const searchTypeControl = this.filterForm$.getValue().controls[this.mySearchTypeKey];
    const displayType = this.dynamicFieldService.displayType(this.workingField, 'filter', '');
    const hasRangeType: boolean = this.searchTypeConfig.options
      .find(x => x.value.toLowerCase().endsWith(SearchType.Range.toLowerCase())) === undefined
      ? false : true;
    const currentIsRangeType: boolean = searchTypeControl.value === SearchType.Range;
    const isMultiSelect: boolean = this.workingField.hasOwnProperty('multiSelectOptions') ? true : false;

    let value: any = cloneDeep(fieldControl.value);
    const values: any[] = isMultiSelect || !value ? [] : value.split(' - ');

    //if change is from non-range to range, wipe out value, it is invalid to search on
    //unitl both start and end are entered.
    if (hasRangeType && currentIsRangeType && values.length <= 1) {
      return '';
    }

    if (isMultiSelect) {
      value = this.formatMultiSelectReturn(value);
    } else if (this.workingField.fieldType === this.myConstants.fieldTypeCurrency
      || this.workingField.fieldType === this.myConstants.fieldTypeDoubleDecimal
      || this.workingField.fieldType === this.myConstants.fieldTypeNumber
      || displayType === this.myConstants.fieldTypeNumber
      || this.workingField.fieldType === this.myConstants.fieldTypeDouble) {

      value = this.formatNumericReturn(value);

    } else if (this.workingField.fieldType == this.myConstants.fieldTypeTimeSpan
      || this.workingField.fieldType == this.myConstants.fieldTypeTimePicker) {

      value = replace(replace(value.toString(), ':', ' '), ':', ' ');//single replace not working

    } else if (this.workingField.fieldType == this.myConstants.fieldTypePercentage) {

      if (!value || (value && value == 0)) {
        value = '';
      }
    }
    return value;
  }

  formatMultiSelectReturn(valueIn: any): any {
    let value: any = valueIn;
    if (isArray(value)) {
      //if the user has selected all options, remove the search term - term is no longer relevant
      if (value.length >= this.workingField['multiSelectOptions'].length) {
        value = '';
      }
      //if the array is empty, remove the search term
      if (!value.length) {
        value = '';
      }
    }

    return value;
  }

  formatNumericReturn(valueIn: any): any {
    let value: any = valueIn;
    const searchTypeControl = this.filterForm$.getValue().controls[this.mySearchTypeKey];
    const displayType = this.dynamicFieldService.displayType(this.workingField, 'filter', '');

    if (searchTypeControl.value !== SearchType.Range) {
      if ((value && isNaN(value)) || !value) {
        value = '';
      } else if (this.workingField.fieldType !== this.myConstants.fieldTypeNumber && displayType !== this.myConstants.fieldTypeNumber) {
        value = !value ? 0 : Number(value);
        value = this.decimalPipe.transform(value.toString().replace(',', ''), '1.2-2');
      }
    }

    return value;
  }

  getDisplayValues(searchValues: string[]): string[] {
    let values: string[] = [];
    if (searchValues && searchValues.length > 0 && this.selectList.items.length > 0 &&
      (this.vmFieldDefinition.fieldType === this.myConstants.fieldTypeEntity
        || this.vmFieldDefinition.fieldType === this.myConstants.fieldTypeEditableSelect
        || this.workingField.fieldType === this.myConstants.fieldTypeFilterableSelect
        || (this.vmFieldDefinition.fieldType === this.myConstants.fieldTypeSelect && this.vmFieldDefinition.selectListDefinition.listType !== 'enum'))) {
      searchValues.forEach((val) => {
        const item: string = this.selectList.items.find(x => x[this.vmFieldDefinition.selectListDefinition.valueProperty] === val);
        if (item && isObject(item)) {
          values.push(item[this.vmFieldDefinition.selectListDefinition.labelProperty]);
        }
      });
    }
    return values;
  }
  /* 
        Keeps track of search term changes but doesn't trigger a filter event
  */
  onChanged(searchTerm: ISearchTerm) {
    const searchType: SearchType = this.filterForm$.value.controls[this.mySearchTypeKey].value;
    let term = searchTerm;
    if (searchType === SearchType.NoValue) {
      term.value = 'null';
      term.searchType = searchType;
    } 
    const filterChange: IFilterChange = {
      key: this.workingField.key,
      searchTerm: term
    }
    let emitter: IHomEventEmitter = {
      requestor: 'dynamic-filter-form',
      event: this.myConstants.emitterEventFilterChanged,
      action: this.workingField.selectListDefinition && this.workingField.selectListDefinition.storeName ? this.workingField.selectListDefinition.storeName : '',
      data: filterChange
    };

    this.emitterService.emitListEvent(emitter);
  }

  //triggers repull of selected items in a drop down
  filterOfFilterEvent(e: IHomEventEmitter) {
    this.filterSelectList(e.data);
  }

  setDefaultFormValue(form: FormGroup) {
    const defaultValue = this.convertDefaultValue();

    if (defaultValue ) {
      if (this.workingField.fieldType === this.myConstants.fieldTypePercentage) {
        if (this.defaultValue.searchType === SearchType.Range) {
          const values = defaultValue.split(' - ');
          values.forEach((x, idx) => {
            form.controls[this.workingField.key.concat(idx === 0 ? '_start' : '_end')].setValue((Number(x) * 100).toFixed(2).toString());
          });
        } else {
          form.controls[this.workingField.key.concat('_percent')].setValue((Number(defaultValue) * 100).toFixed(2).toString());
        }
      } else {
        if (this.defaultValue.searchType === SearchType.Range) {
          const values = defaultValue.split(' - ');
          values.forEach((x, idx) => {
            form.controls[this.workingField.key.concat(idx === 0 ? '_start' : '_end')].setValue(x);
          });
        } else {
          form.controls[this.workingField.key].setValue(defaultValue);
        }
      }
    }
  }

  setFieldSpecialSettings(form: FormGroup) {
    const displayType = this.dynamicFieldService.displayType(this.workingField, 'filter', '');

    switch (displayType) {
      case this.myConstants.fieldTypeSingleDatePicker:
        this.setExtraDateProperties(form);
        break;
      case this.myConstants.fieldTypeMultiSelect:
      case this.myConstants.fieldTypeBoolean:
      case this.myConstants.fieldTypeSelect:
        this.setExtraSelectProperties();
        break;
      case this.myConstants.fieldTypeTimeSpan:
      case this.myConstants.fieldTypeTimePicker:
        this.setExtraTimeProperties(form);
        break;
      default:
        this.setDefaultFormValue(form);
        break;
    }
    
  }

  usesSearchType(): boolean {
    let doesUse = true;
    const hasSelectSettings = this.workingField.selectListDefinition !== null;
    switch (this.workingField.fieldType) {
      case this.myConstants.fieldTypeSelect:
      case this.myConstants.fieldTypeEditableSelect:
      case this.myConstants.fieldTypeFilterableSelect:
      case this.myConstants.fieldTypeBoolean:
        doesUse = false;
        break;
      case this.myConstants.fieldTypeEntity:
        doesUse = hasSelectSettings ? false : true;
        break;
      default:
        break;
    }
    return doesUse;
  }

  configureSearchType(): void {
    const hasSelectSettings = this.workingField.selectListDefinition !== null;
    const parameterType = this.dynamicFieldService.getParameterTypeToFieldTypeMapping(this.workingField.filterType ? this.workingField.filterType : this.workingField.fieldType, hasSelectSettings);
    const searchTypes = this.userPriviledgesService.getSearchTypesByParameterType(parameterType.toLowerCase());
    const includeTypes: string[] = !this.vmFieldDefinition.includeSearchTypes ? [] : this.vmFieldDefinition.includeSearchTypes.split(',');
    const excludeTypes: string[] = !this.vmFieldDefinition.excludeSearchTypes ? [] : this.vmFieldDefinition.excludeSearchTypes.split(',');
    this.searchTypeConfig = new SearchTypeConfig(searchTypes.length > 0 && this.usesSearchType());

    searchTypes.forEach(t => {
      let pushMe: boolean = false;
      if (includeTypes.length > 0) {
        if (includeTypes.findIndex(x => x === t.searchType) > -1) {
          pushMe = true;
        }
      } else if (excludeTypes.length > 0) {
        if (excludeTypes.findIndex(x => x === t.searchType) === -1) {
          pushMe = true;
        }
      } else {
        pushMe = true;
      }

      if (pushMe) {
        this.searchTypeConfig.options.push({ value: t.searchType, label: this.homCommonUtility.splitWordsByCase(t.searchType), isDefault: t.isDefault });
      }
    });

    if (parameterType === this.myConstants.fieldTypeString && this.workingField.required) {
      this.searchTypeConfig.options = this.searchTypeConfig.options.filter(x => x.value !== SearchType.NoValue);
    }

    if (searchTypes.length === 0 || this.searchTypeConfig.options.length === 0) {
      this.searchTypeConfig.options.push(this.defaultSearchType = { value: SearchType.Equals, label: SearchType.Equals, isDefault: true });
      return;
    }

    if (this.searchTypeConfig.options.length && !this.searchTypeConfig.options.find(x => x.isDefault === true)) {
      this.searchTypeConfig.options[0].isDefault = true;
    }
    this.defaultSearchType = this.searchTypeConfig.options.find(x => x.isDefault);
    this.searchTypeSet = true;
  }

  /*
      Add special field properties for the component used for filtering of dates
  */
  setExtraDateProperties(form: FormGroup) {
    let dateRangeOptions: any = {
      locale: { format: 'MM/DD/YYYY' },
      alwaysShowCalendars: false,
      startDate: null,
      endDate: null,
      noDefaultRangeSelected: true,
      //dateLimit: //(object) The maximum span between the selected start and end dates.Can have any property you can add to a moment object (i.e.days, months) 
      opens: 'center', //left, right or center
      drops: 'up', //up or down
      //buttonClasses: 'app-btn-primary',
      //isInvalidDate: (function) A function that is passed each date in the two calendars before they are displayed, and may return true or false to indicate whether that date should be available for selection or not. 
      autoUpdateInput: false //initially display the range as empty
    };
    this.workingField['dateRangeOptions'] = dateRangeOptions;

    const defaultValue = this.convertDefaultValue();

    if (defaultValue) {
      form.controls[this.workingField.key].setValue(defaultValue);
    }

  }

  /*
     Add special field properties for the component used for filtering of timespan
  */
  setExtraTimeProperties(form: FormGroup) {
    //can also be setup using the config service to apply to multiple pickers
    const durations: number[] = [1, 2, 3, 4, 5, 10, 15, 30, 45];
    const durationTypes: string[] = ['Minutes', 'Hours', 'Days'];
    const defaultValue = this.convertDefaultValue();
    this.workingField['durations'] = durations;
    this.workingField['durationTypes'] = durationTypes;
    
    if (defaultValue) {
      const values = defaultValue.split(' - ');
      values.forEach((x, idx) => {
        form.controls[this.workingField.key.concat(idx === 0 ? '_start' : '_end')].setValue(x.split(' ').join(':'));
      });
    }
  }

  /*
      Add special field properties for the component used for filtering of fields via a multi select component.
  */
  setExtraSelectProperties() {
    const isSingleSelect: boolean = this.workingField.selectListDefinition && this.workingField.selectListDefinition.filterSettings
      ? this.workingField.selectListDefinition.filterSettings.singleSelect
      : false;

    //object will be of type ISearchTerm or null.
    const defaultInIsObject: boolean = isObject(this.defaultValue);
    const convertedDefault = this.convertDefaultValue();
    //MULTISELECT PROPERTIES 
    let optionsModel: number[] = []; //index of default selected options
    let multiSelectOptions: IMultiSelectOption[] = [];
    let multiSelectLabels: MultiSelectTexts = new MultiSelectTexts();
    let multiSelectSettings: MultiSelectSettings = new MultiSelectSettings();

    //Special for drop downs to work with multi select drop down https://github.com/softsimon/angular-2-dropdown-multiselect
    if (this.workingField.selectListDefinition && this.workingField.selectListDefinition.storeName) {
      if (this.selectList.metaData && this.selectList.metaData.hasOwnProperty('pageMetaData')) {
        if (this.unFilteredTotalItems === 0) { this.unFilteredTotalItems = this.selectList.metaData['pageMetaData'].totalItems };
        multiSelectSettings.totalItems = this.selectList.metaData['pageMetaData'].totalItems;
        multiSelectSettings.itemsPerPage = this.selectList.metaData['pageMetaData'].itemsPerPage;
        //multiSelectSettings.enableSearch = (this.unFilteredTotalItems > 10);
        //pagingData will display the count of total span and show the run filter button.
        //enableSearch displays search box, this can be displayed at any time - for now displaying when total items in drop down is > 10
        multiSelectSettings.showPagingInfo = (multiSelectSettings.enableSearch && this.unFilteredTotalItems > multiSelectSettings.itemsPerPage);
      }

      //Handle Not Assigned selection plus incoming default search term.
      let notAssignedIdx: number = -1;
      let hasNullDefaultValue: boolean = false;
      //distinguish between no default in ( devaultValue = null)
      //and a defaultValue.value of null.
      if (!this.workingField.required && defaultInIsObject && !convertedDefault) {
        notAssignedIdx = this.selectList.items.findIndex(x => x[this.workingField.selectListDefinition.valueProperty] === null);
      }
      if (!this.workingField.required && defaultInIsObject) {
        //handle Not Assigned item
        hasNullDefaultValue = !convertedDefault ? true : convertedDefault.findIndex(x => x === null) >= 0;
      }

      this.selectList.items.forEach((item, index) => {
        let isSelected: boolean = false;
        if (convertedDefault) {
          const match = convertedDefault.find(x => x == item[this.workingField.selectListDefinition.valueProperty]);
          if (match) {
            isSelected = true;
          } 
        }

        if (!isSelected) {
          //handle Not Assigned item
          if (!this.workingField.required
              && defaultInIsObject
              && hasNullDefaultValue
              && item[this.workingField.selectListDefinition.valueProperty] === null) {
              isSelected = true;
          }
        }

        multiSelectOptions.push({
          id: item[this.workingField.selectListDefinition.valueProperty],
          name: item[this.workingField.selectListDefinition.labelProperty] || '',
          selected: isSelected
        });
      });
    }
    else if (this.workingField.fieldType === this.myConstants.fieldTypeBoolean || this.workingField.fieldType === this.myConstants.dataTypeBoolNull) {
      if (this.workingField.fieldType === this.myConstants.dataTypeBoolNull) {
        multiSelectOptions.push({ id: 'null', name: 'No Value', selected: (convertedDefault && convertedDefault[0] === 'null' ? true : false) });
      }
      multiSelectOptions.push({ id: true, name: 'True', selected: (convertedDefault && convertedDefault[0] === true ? true : false) });
      multiSelectOptions.push({ id: false, name: 'False', selected: (convertedDefault && convertedDefault[0] === false ? true : false) });
    }
    multiSelectSettings.selectionLimit = isSingleSelect ? 1 : 0;
    multiSelectSettings.closeOnSelect = isSingleSelect;
    multiSelectSettings.autoUnselect = isSingleSelect;
    multiSelectLabels.uncheckAll = isSingleSelect ? 'Unselect' : multiSelectLabels.uncheckAll;

    this.workingField['optionsModel'] = (!convertedDefault ? optionsModel : convertedDefault);
    this.workingField['multiSelectLabels'] = multiSelectLabels;
    this.workingField['multiSelectOptions'] = multiSelectOptions;
    this.workingField['multiSelectSettings'] = multiSelectSettings;
  }

  ///TBD:  THIS IS FILTERING OF THE FILTER AND WE DON'T CURRENTLY HAVE THIS IN USE ANYWHERE
  /* This filter field has requested its own list of items be filtered - not utilized yet */
  filterSelectList(searchText: string) {
    const field = this.field$.getValue();
    let listFilter: IListFilter = this.listFilter(searchText);
    let fieldSplit = field.key.split('@');
    let fieldKey = fieldSplit[0];
    let objectName = fieldKey.replace(fieldKey[0], fieldKey[0].toUpperCase());

    console.log('CHANGE TO UTILIZE STORE HERE', field, fieldKey, objectName);

    this.subscription.add(this.domainObjectService.getListByProvider(objectName, this.userPriviledgesService.providerId$.getValue(), listFilter)
      .subscribe((result: IResponseBase) => {
        this.selectList.items = result.data;
        this.selectList.metaData = result.metaData;
        this.operation = 'updatelist' + searchText; //forces a change
      },
        (error: any) => { console.log(error) }
      ));
  }

  /* Define the list filter */
  listFilter(searchText): IListFilter {
    const field = this.field$.getValue();
    let listFilter: IListFilter = new ListFilter();
    listFilter.searchTerm = searchText === '' ? []
      : [{
        term: field.selectListDefinition.labelProperty,
        value: searchText,
        searchType: SearchType.Default,
        fieldType: field.fieldType,
        columnName: field.key
      }];
    listFilter.orderTerm = [new OrderTerm(field.selectListDefinition.labelProperty)];

    return listFilter;
  }

  getParentId(): number {
    let parentId: number = -1;
    if (this.workingField.selectListDefinition) {
      if (this.workingField.fieldType === this.myConstants.fieldTypeEntity ||
        this.workingField.fieldType === this.myConstants.fieldTypeEditableSelect ||
        this.workingField.fieldType === this.myConstants.fieldTypeFilterableSelect) {
        if (this.workingField.selectListDefinition.methodName) {
          parentId = this.parentId;
        }
      }
    }

    return parentId;
  }

  initSelect(): void {
    this.selectList.items = [];
    this.selectList.metaData = null;
    const storeName = this.workingField.selectListDefinition.storeName;
    const entityName = this.workingField.selectListDefinition.objectType;

    if (!this.workingField.selectListDefinition.useCustomResolver) {
      this.dispatchGet(entityName, storeName);
    }

    if (this.selListSub) {
      this.selListSub.unsubscribe();
    }

    this.selListSub = this.rootStore.select(getSelectionListByTypeByParent(storeName, -1))
      .subscribe((state: ISelectListData) => {
        if (state && state.data) {
          this.selectList.items = cloneDeep(state.data);
          if (this.selectList.items.length && !this.workingField.required) {
            let nullItem = cloneDeep(this.selectList.items[0]);
            nullItem[this.workingField.selectListDefinition.labelProperty]
              = this.workingField.fieldType === this.myConstants.fieldTypeEntity
                ? this.myConstants.notAssigned : '';
            nullItem[this.workingField.selectListDefinition.valueProperty]
              = this.workingField.fieldType === this.myConstants.fieldTypeEntity
                ? null : '';
            this.selectList.items.unshift(nullItem);
          }
          this.selectList.metaData = state.metaData;
          this.loadForm();
        } else {
          this.selectList.items = [];
          this.loadForm();
        }
      });
  }

  loadForm() {
    if (!this.searchTypeSet) {
      this.mySearchTypeKey = this.searchTypeKey.concat('_', this.workingField.key);
      this.configureSearchType();
    }

    let workingForm: FormGroup = this.setFormGroup();
    this.setFieldSpecialSettings(workingForm);
    this.field$.next(this.workingField);
    this.filterForm$.next(workingForm);
    this.setFieldSubscribes(workingForm);
  }

  setFormGroup(): FormGroup {
    let group = {};
    let defaultSearchType = this.defaultSearchType;
    group[this.workingField.key] = new FormControl();
    if (this.defaultValue) {
      let defaultOption = this.searchTypeConfig.options.find(x => x.value === this.defaultValue.searchType);
      if (defaultOption) {
        this.searchTypeConfig.options.find(x => x.isDefault).isDefault = false;
        defaultOption.isDefault = true;
        defaultSearchType = defaultOption;
      }
    } else {
      let defaultOption = this.searchTypeConfig.options.find(x => x.isDefault);
      if (defaultOption.value !== this.defaultSearchType.value) {
        defaultOption.isDefault = false;
        this.searchTypeConfig.options.find(x => x.value === this.defaultSearchType.value).isDefault = true;
      }
    }
    group[this.mySearchTypeKey] = new FormControl({ value: defaultSearchType.value, disabled: false });
    if (this.searchTypeConfig.options.find(x => x.value.toLowerCase().endsWith(SearchType.Range.toLowerCase()))) {
      group[this.workingField.key.concat('_start')] = new FormControl();
      group[this.workingField.key.concat('_end')] = new FormControl();
    }
    if (this.workingField.fieldType === this.myConstants.fieldTypePercentage) {
      group[this.workingField.key.concat('_percent')] = new FormControl();
    }
    return new FormGroup(group);
  }

  setFieldSubscribes(workingForm: FormGroup): void {
    const fieldControl = workingForm.controls[this.workingField.key];
    if (fieldControl) {
      switch (this.workingField.fieldType) {
        case this.myConstants.fieldTypeSelect:
        case this.myConstants.fieldTypeEntity:
        case this.myConstants.fieldTypeBoolean: 
        case this.myConstants.fieldTypeDateTime:
        case this.myConstants.fieldTypeDateTimeNull:
        case this.myConstants.fieldTypeDate:
        case this.myConstants.fieldTypeNumber:
        case this.myConstants.fieldTypeCurrency:
        case this.myConstants.fieldTypeDoubleDecimal:
        case this.myConstants.fieldTypePercentage:
          this.subscription.add(fieldControl.valueChanges
            .subscribe(value => {
             this.onAdd(fieldControl.valueChanges);
            }
            ));
          break;
        default:
          this.subscription.add(fieldControl.valueChanges
            .pipe(debounceTime(700))
            .subscribe(value => {
              this.onAdd(fieldControl.valueChanges);
            }));
          break;
      }

      const searchTypeControl = workingForm.controls[this.mySearchTypeKey];
      if (this.searchTypeConfig.options && this.searchTypeConfig.options.length && searchTypeControl) {
        this.subscription.add(searchTypeControl.valueChanges
          .subscribe(value => {
            this.onAdd(searchTypeControl.valueChanges);
          }));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const formExists: boolean = this.filterForm$.value ? true : false;

    if (changes['showFilter'] && changes['showFilter'].currentValue === true) {
      if (!formExists) {
        this.workingField = cloneDeep(this.vmFieldDefinition);
        this.workingField.fieldType = this.workingField.filterType ? this.workingField.filterType : this.workingField.fieldType;
        if ((this.workingField.selectListDefinition && this.workingField.selectListDefinition.storeName)) {
          this.initSelect();
        } else {
          this.loadForm();
        }
      }
    }

    if (formExists && changes['defaultValue'] && !changes['defaultValue'].isFirstChange()) {
      this.loadForm();
    }
    
  }

}
