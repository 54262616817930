import { Component } from '@angular/core';
import { Location } from '@angular/common';


@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html'
})
export class PageNotFoundComponent {
    public pageTitle: string = 'Page Not Found';

  constructor(public location: Location) { }

    goBack(): void {
        this.location.back();
    }

}
