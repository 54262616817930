import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { includes, orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IOrderTerm, IListColumn, IDynamicListSortSetting, IDynamicListSortSettingSelection, OrderTerm, IDynamicListHeaderColSelection } from '../../interfaces';
import { IFieldDefinition } from '../../../dynamic-forms';
import { IRadioButton } from '../../../fw-shared/components/fw-radio-button/interfaces/i-radio-button';
import { DynamicListEvent, DynamicListAction } from '../../enums/dynamic-list.enum';
import { IInputButton } from '../../../fw-shared/interfaces';
import { DynamicListService } from '../../services';

@Component({
  selector: 'dynamic-list-sort-settings',
  templateUrl: './dynamic-list-sort-settings.component.html'
})
export class DynamicListSortSettingsComponent implements OnInit, OnChanges {
  @Input() listDefinition: IListDefinition;
  @Input() fieldDefinitions: IFieldDefinition[];
  @Input() currentTerms: IOrderTerm[];

  @Output() public applyEvent = new EventEmitter<IHomEventEmitter>();
  @Output() public cancelEvent = new EventEmitter();

  public multiSortOptions: IDynamicListSortSetting[] = [];
  public singleSortOptions: IDynamicListSortSetting[] = [];
  public singleSortBtns: IRadioButton[] = [];
  public defaultSSBtn: number = -1;
  public errorMessage: string = '';
  public ascConfig: IInputButton;
  public currentMsSettings$: BehaviorSubject<IDynamicListSortSetting[]> = new BehaviorSubject([]);
  public currentSingleSort$: BehaviorSubject<IDynamicListSortSetting> = new BehaviorSubject(null);
  public isValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isMultiSelect: boolean = true;
  activeAsc: boolean = true;

  constructor(public dynamicListService: DynamicListService) { }

  ngOnInit(): void {
    this.ascConfig = {
      label: {
        label: 'Ascending',
        alignRight: true,
        title: 'Ascending'
      }
    };
   this.newRequest();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['listDefinition'] && !changes['listDefinition'].firstChange) ||
      (changes['currentTerms'] && !changes['currentTerms'].firstChange)) {
      this.newRequest();
    }
  }

  public addItem(): void {
    let currentSettings: IDynamicListSortSetting[] = this.currentMsSettings$.value || [];
    currentSettings.push({ colKey: '', label: '', orderTerms: [], firstOrder: currentSettings.length + 1 });
    this.currentMsSettings$.next(currentSettings);
    this.clearSingleSort();
    this.setIsValid();
  }

  public onChange(selection: IDynamicListSortSettingSelection): void {
    let currentSettings: IDynamicListSortSetting[] = this.currentMsSettings$.value;
    currentSettings[selection.index] = selection.item;
    this.currentMsSettings$.next(currentSettings);
    this.clearSingleSort();
    this.setIsValid();
  }

  public onDelete(index: number): void {
    let current: IDynamicListSortSetting[] = this.currentMsSettings$.value;
    current.splice(index, 1);
    this.currentMsSettings$.next(current);
    this.clearSingleSort();
    this.setIsValid();
  }

  public selectSSOption(colKey: string): void {
    this.currentSingleSort$.next(this.singleSortOptions.find(x => x.colKey === colKey));
    this.defaultSSBtn = this.singleSortOptions.findIndex(x => x.colKey === colKey);
    this.clearMultiSort();
    this.setRadioButtonConfig();
    this.setIsValid();
  }

  public changeSSAsc(val: boolean): void {
    this.activeAsc = val;
    this.clearMultiSort();
    this.setIsValid();
  }

  public cancel(): void {
    this.cancelEvent.emit();
  }

  public apply(): void {
    const msSettings: IDynamicListSortSetting[] = this.currentMsSettings$.value;
    const ssSetting: IDynamicListSortSetting = this.currentSingleSort$.value;

    let newTerms: IOrderTerm[] = [];
    if (this.isMultiSelect) {
      if (msSettings && msSettings.length > 0) {
        msSettings.forEach(x => {
          x.orderTerms.forEach(t => {
            newTerms.push(t);
          });
        });
      }
    } else {
      //a single column may still have multiple order terms
      if (ssSetting) {
        ssSetting.orderTerms.forEach(t => newTerms.push({ term: t.term, orderAsc: this.activeAsc, sortOrder: 1 }));
      }
    }

    const data: IDynamicListHeaderColSelection = { colTerms: newTerms, singleSort: !this.isMultiSelect };

    const event: IHomEventEmitter = {
      requestor: 'dynamic-list-header-item',
      event: DynamicListEvent.sortList,
      action: DynamicListAction.overrideAll,
      data: data
    };

    this.applyEvent.emit(event);
  }

  newRequest(): void {
    if (!this.fieldDefinitions || !this.listDefinition
        || !this.listDefinition.listColumns || this.listDefinition.listColumns.length === 0) {
      return;
    }
    let currentSettings: IDynamicListSortSetting[] = [];
    let isSingleSelect: boolean = false;
    this.currentSingleSort$.next(null);
    this.currentMsSettings$.next([]);

    this.fieldDefinitions.forEach((def: IFieldDefinition) => {
      const listCol: IListColumn = this.listDefinition.listColumns.find(x => x.key === def.key);
      if (def.sortable && listCol) {
        let terms: IOrderTerm[] = [];
        if (def.orderTerms && def.orderTerms.length > 0) {
          def.orderTerms.forEach(t => {
            terms.push({ term: t, sortOrder: 0, orderAsc: true });
          });
        } else {
          terms.push(new OrderTerm(def.key));
        }
        if (def.singleSort) {
          this.singleSortOptions.push({ colKey: def.key, label: def.label, orderTerms: terms, firstOrder: terms[0].sortOrder });
        } else {
          this.multiSortOptions.push({ colKey: def.key, label: def.label, orderTerms: terms, firstOrder: terms[0].sortOrder });
        }

        if (this.currentTerms && this.currentTerms.length > 0) {
         const colSortKeys: string[] = this.dynamicListService.getSortKey(this.fieldDefinitions, listCol.key);
          if (colSortKeys && colSortKeys.length > 0) {
            const colActiveTerms: IOrderTerm[] = this.currentTerms.filter(x => includes(colSortKeys, x.term));
            if (colActiveTerms && colActiveTerms.length > 0) {
              isSingleSelect = def.singleSort;
              currentSettings.push({ colKey: listCol.key, label: def.label, orderTerms: colActiveTerms, firstOrder: colActiveTerms[0].sortOrder });
            }
          }
        }
      }
    });
    this.multiSortOptions = orderBy(this.multiSortOptions, ['label'], ['asc'])

    if (currentSettings.length > 0) {
      if (isSingleSelect) {
        this.currentSingleSort$.next(currentSettings[0]);
      } else {
        this.currentMsSettings$.next(orderBy(currentSettings, ['firstOrder'], ['asc']));
      }
    } 

    this.setRadioButtonConfig();
  }

  setRadioButtonConfig(): void {
    const currentSingleSort: IDynamicListSortSetting = this.currentSingleSort$.value;
    this.singleSortBtns = [];
    this.singleSortOptions = orderBy(this.singleSortOptions, ['label'], ['asc']);
    this.singleSortOptions.forEach(x => {
      this.singleSortBtns.push({
        label: x.label,
        value: x.colKey,
        id: x.colKey,
        checked: currentSingleSort ? x.colKey === currentSingleSort.colKey : false
      })
    });
    this.defaultSSBtn = currentSingleSort ? this.singleSortBtns.findIndex(x => x.id === currentSingleSort.colKey) : -1;
  }

  setIsValid(): void {
    let isValid: boolean = true;
    const msSettings: IDynamicListSortSetting[] = this.currentMsSettings$.value;
    const ssSettings: IDynamicListSortSetting = this.currentSingleSort$.value;

    if ((!msSettings || !msSettings.length) && (!ssSettings)) {
      this.isValid$.next(false);
      return;
    }

    if (this.isMultiSelect) {
      msSettings.forEach(x => {
        if (!x || x.colKey === '') {
          isValid = false;
        }
      });
    } 
    this.isValid$.next(isValid);
  }

  clearMultiSort(): void {
    this.currentMsSettings$.next([]);
    this.isMultiSelect = false;
  }

  clearSingleSort(): void {
    this.currentSingleSort$.next(null);
    this.isMultiSelect = true;
    this.defaultSSBtn = -1;
  }

}
