<div class="fw-check-box" *ngIf="config" title="{{ config.label && config.label.title ? config.label.title : '' }}">
  <label *ngIf="config.label && config.label.label" class="fw-check-box__label" [ngStyle]="{'order': config.label.alignRight ? 1 : 0}">{{ config.label.label }}</label>
  <div class="fw-check-box__btn" [ngClass]="{'fw-check-box__btn--checked': value, 'fw-check-box__btn--disabled': isDisabled}">
    <input #checkBox type="checkbox" class="fw-check-box__btn__input" 
           [attr.checked]="value ? '' : null"
           [disabled]="isDisabled"
           [(ngModel)]="value"
           (change)="initChange(checkBox.checked)" 
           appFocus [autoFocus]="autoFocus">
    <div class="fw-check-box__btn__check"></div>
  </div>
</div>
