<fw-dynamic-detail-container *ngIf="(detailConfig$ | async) as detailConfig" [detailConfig]="detailConfig">
  <div *ngIf="activeRecord">
    <div *ngIf="showGoToLink" class="app-info  aqt-change-link">
      Click to open
      <span class="app-link" (click)="managerDashboardService.openLink(activeRecord)">
        {{ homCommonUtility.splitWordsByCase(activeRecord.parentEntityName) + ' ' + activeRecord.parentPrimaryKeyId }}
      </span>
    </div>
    <div class="aqt-change-box">
      <div class="aqt-change-summary">
        <div class="aqt-change-summary__col1">Description</div>
        <div class="aqt-change-summary__col2"
             [ngClass]="{'app-text--danger': activeRecord.operation === 'Delete' }">
          {{ homCommonUtility.splitWordsByCase(activeRecord.entityName) }}
        </div>
        <div class="aqt-change-summary__col3">Affected Context</div>
        <div class="aqt-change-summary__col4">{{ activeRecord.transactionContext}}</div>
      </div>

      <div class="aqt-change-summary">
        <div class="aqt-change-summary__col1">Submitter</div>
        <div class="aqt-change-summary__col2">{{ activeRecord.createUserText}}</div>
        <div class="aqt-change-summary__col3">Date Submitted</div>
        <div class="aqt-change-summary__col4">{{ activeRecord.createDate | homdate | date | date:'MM/dd/yyyy'  }}</div>
      </div>

      <div class="aqt-change-summary">
        <div class="aqt-change-summary__col1">Status</div>
        <div class="aqt-change-summary__col2">{{ activeRecord.status}}</div>
        <div class="aqt-change-summary__col3">Expires On</div>
        <div class="aqt-change-summary__col4">{{ activeRecord.expiresOn | homdate | date | date:'MM/dd/yyyy' }}</div>
      </div>
    </div>

    <fw-component-title title="Details"></fw-component-title>
    <div *ngIf="metaDataService.mdsReady$ | async" class="aqt-change-detail__box">
      <form *ngIf="(form$ | async) as form" [formGroup]="form" novalidate class="aqt-change-detail__form">
        <table class="app-table" *ngIf="entityProperties">
          <thead>
            <tr>
              <th></th>
              <th *ngFor="let row of entityProperties">{{ row.propertyLabel }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="activeRecord.operation !== 'Create' " [ngClass]="{'app-text--danger': activeRecord.operation === 'Delete' }">
              <th scope="row">Current</th>
              <td *ngFor="let row of entityProperties">{{ row.propertyValue }}</td>
            </tr>
            <tr *ngIf="activeRecord.operation !== 'Delete' " class="app-text">
              <th scope="row" class="app-text--warning">Proposed</th>
              <ng-container *ngFor="let row of entityProperties">
                <td *ngIf="row.isEditable && operation === myConstants.operationTypeEdit">
                  <fw-dynamic-field [field]="metaDataService.getFieldDefinition(row.propertyName)"
                                    [form]="form"
                                    [operation]="operation"
                                    [selectItems]="metaDataService.getSelectItems(row.propertyName)">
                  </fw-dynamic-field>
                </td>
                <td *ngIf="!row.isEditable || operation !== myConstants.operationTypeEdit"
                    [ngClass]="{'app-text--warning': row.updatedValue }">
                  {{ row.updatedValue || row.propertyValue }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
  <div class="app-btn-box" *ngIf="operation === myConstants.operationTypeEdit">
    <button type="button" class="app-btn app-btn--cancel" (click)="cancel()">Cancel</button>
    <button type="button" class="app-btn app-btn--primary" [disabled]="!canApprove"
              (click)="approve()" >
        Approve
      </button>
      <button type="button" class="app-btn app-btn--primary"
              (click)="reject()" >
        Reject
        </button>
  </div>
</fw-dynamic-detail-container>
