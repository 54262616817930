export interface ISssBranchProgramService {
  sssBranchProgramServiceId: number;
  sssProgramService_sssProgramServiceId: number;
  branchProgram_branchProgramId: number;
  dateRange: number;
  allowSlotBufferReservations: boolean;
  etaBuffer: number;
  minimumDuration: number;

  //extraData
  sssGeneralContractorId: number;
  generalContractorName: string;
  gcExcludingPrograms: boolean;
  pgmExcludingServices: boolean;
  psExcludingBranches: boolean;
  programServiceId: number;
  generalContractorId: number;
}

export class SssBranchProgramService implements ISssBranchProgramService {
  sssBranchProgramServiceId: number;
  sssProgramService_sssProgramServiceId: number;
  branchProgram_branchProgramId: number;
  dateRange: number;
  allowSlotBufferReservations: boolean;
  etaBuffer: number;
  minimumDuration: number;

  //extraData
  sssGeneralContractorId: number;
  generalContractorName: string;
  gcExcludingPrograms: boolean;
  pgmExcludingServices: boolean;
  psExcludingBranches: boolean;
  programServiceId: number;
  generalContractorId: number;

  constructor() {
    this.sssBranchProgramServiceId = 0;
    this.sssProgramService_sssProgramServiceId = null;
    this.branchProgram_branchProgramId = null;
    this.dateRange = 0;
    this.allowSlotBufferReservations = false;
    this.etaBuffer = 0;
    this.minimumDuration = 0;
    //extraData
    this.sssGeneralContractorId = 0;
    this.generalContractorName = '';
    this.programServiceId = 0;
    this.generalContractorId = 0;
  }
}
