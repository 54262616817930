
export class GcInstallerRequirement {
    constructor(
        public generalContractorInstallerRequirementId: number = 0,
        public required: boolean = false,
        public installerRequirementName = '',
        public installerRequirementDescription = '',
        public requirementLevel = '',
        public validatesParent = false,
        public createDate: string = '', //example: "/Date(1306380319000)/",
        public updateDate: string = '',
        public createdBy: number = 0,
        public updatedBy: number = 0,
        public generalContractor_generalContractorId: number = 0,
        public generalContractorName = '',
        public rowVersion: string = '',
        public metaData: any = [],

        //special for fkey handling
        public generalContractor = ''
    ) {}

}