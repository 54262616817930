<div *ngIf="notes?.length" class="notelist">
  <fw-error-box *ngIf="(errorAction === actionFromList && errorId === 0) || errorAction === ''" [errorData]="errorData"></fw-error-box>

  <div *ngFor="let note of notes">
    <div *ngIf="(operation !== myConstants.operationTypeSingleEdit) || (activeNote?.noteId !== note.noteId)" class="app-card"
         [ngClass]="{'notelist__item--is-postIt': note.postIt, 'notelist__item--is-external': note.isExternal}">
      <div class="app-card__header" id="heading_{{note.noteId}}">
        <div class="app-card__header--title">
          <button class="app-btn-icon app-icon--primary notelist__action"
                  title="Change Privacy"
                  (click)="changePrivacy(note.noteId)"
                  [disabled]="operation || (isDeleting$ | async) || !canIEdit ">
            <i class="fas " [ngClass]="{'fa-lock' : note.isPrivate, 'fa-lock-open': !note.isPrivate }"></i>
          </button>{{note.noteSubject}}
        </div>
        <div class="app-card__header--btn-box app-btn-box">
          <button class="app-btn-icon app-btn-icon--primary"
                  [ngClass]="{'app-btn-icon__animated' : !(isDeleting$ | async) }"
                  [disabled]="operation !== '' || (isDeleting$ | async) || !note.hasNotifications"
                  title="View Notification Acknowledgements"
                  (click)="viewNotifications(note.noteId)">
            <i class="fas fa-paper-plane" [ngClass]="{'app-btn-icon--warning' : note.hasUnacknowledgedNotifications, 'app-btn-icon---primary' : !note.hasUnacknowledgedNotifications}"></i>
          </button>
          <button class="app-btn-icon app-icon--warning "
                  [ngClass]="{'app-btn-icon__animated' : !(isDeleting$ | async) }"
                  title="Edit"
                  (click)="onEdit(note.noteId)"
                  [disabled]="operation || (isDeleting$ | async) || !canIEdit ||  noteConfigFields.isProjectNote">
            <i class="fas fa-edit "></i>
          </button>
          <button class="app-btn-icon app-btn-icon__animated app-icon--danger "
                  title="Delete"
                  (click)="onDeleteRequest(note.noteId)"
                  [hidden]="(isDeleting$ | async)"
                  [disabled]="operation || !canIEdit || noteConfigFields.isProjectNote">
            <i class="fas fa-trash "></i>
          </button>
          <button class="app-btn-icon app-btn-icon__animated app-icon--primary "
                  title="Cancel"
                  (click)="onDeleteCancel()"
                  [hidden]="!(isDeleting$ | async)  || activeNote?.noteId !== note.noteId">
            <i class="fas fa-ban "></i>
          </button>
          <button class="app-btn-icon app-btn-icon__animated app-icon--danger "
                  title="Confirm Delete"
                  (click)="onDeleteConfirm(note.noteId)"
                  [hidden]="!(isDeleting$ | async)  || activeNote?.noteId !== note.noteId">
            <i class="fas fa-trash "></i>
          </button>
        </div>
      </div>
      <div class="app-card__content"
           [hidden]="operation === myConstants.operationTypeSingleEdit && activeNote?.noteId ===  note.noteId "
           [ngClass]="{'notelist--activeDelete': (isDeleting$ | async) && activeNote?.noteId ===  note.noteId}">
          <note-list-item
                          [note]="note"
                          [isProjectNote]="noteConfigFields.isProjectNote"
                          (postItEvent)="onPostIt($event)"
                          (replyEvent)="onSendReply($event)">
          </note-list-item>

         <notifications *ngIf="(showNotifications$ | async) && activeNote?.noteId === note.noteId"
                       [notifications]="activeNote?.noteId === note.noteId ? activeNotifications : null"
                       (closeRequest)="onCloseNotification($event)">
         </notifications>
      </div>
      <fw-error-box *ngIf="(errorAction === actionFromList && errorId === note.noteId)" [errorData]="errorData" [ignoreKey]="true"></fw-error-box>
    </div>
    <div *ngIf="activeNote?.noteId === note.noteId && ((operation$ | async) !== myConstants.operationTypeCreate) ">
      <note [note]="activeNote"
            [operation]="operation"
            [noteConfigFields]="noteConfigFields"
            [errorData]="errorData"
            [errorAction]="errorAction"
            [errorId]="errorId"
            [errorMessage]="errorMessage"
            (noteEvent)="onNoteEvent($event)">
      </note>
    </div>
  </div>
  <div *ngIf="pageMetaData && operation  !== myConstants.operationTypeCreate " class="dynamic-list__footer">
    <div class="dynamic-list__footer--count-text">{{pageMetaData.totalItems}} Items  </div>
  </div>
  <pagination *ngIf="pageMetaData"
              [restrict]="false"
              [config]="pageMetaData"
              (action)="pageChanged(e = $event)">
  </pagination>

</div>
