<div class="portal-detail-summary__header portal-just-tabs__header">
  <fw-container>
    <ng-content></ng-content>
  </fw-container>
</div>
<div class="portal-detail-detail" [ngClass]="{'portal-detail-detail--visible': (myTabs$ | async).length }">
  <div class="portal-detail-detail__outlet">
    <fw-container>
      <router-outlet name="portal-detail"></router-outlet>
    </fw-container>
  </div>
  <div class="portal-detail-detail__tabs portal-just-tabs__tabs">
    <fw-app-tabs apptabs
                 [portalId]="portalId"
                 [portalEntityId]="portalEntityId"
                 [level]="portalLevel"
                 [limit]="maxTabs"
                 [tabDefinition]="myTabs$ | async">
    </fw-app-tabs>
  </div>
</div>
