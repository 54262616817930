import { Component, OnInit, ChangeDetectionStrategy,  Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { IListDefinition, IDetailRouteParameter, ISearchTerm, IListFilter, ListFilter, OrderTerm } from '../../../../../fw/dynamic-list/interfaces';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { SearchType } from '../../../../../fw/dynamic-list/enums/search-type.enums';
import * as fromRoot from '../../../../store/reducers/index';
import { AdminSssService } from '../../services/admin-sss.service';
import { GetEntityListByMethodParams, InitSelectItems } from '../../../../../shared/store/selectionLists';
import { UserPriviledgesService } from '../../../../../auth/services';

@Component({
  selector: 'sss-program-service-branches',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sss-program-service-branches.component.html'
})
export class SSSProgramServiceBranchesComponent implements OnInit {
  public listDefinition: IListDefinition;
  params: IDetailRouteParameter;

  constructor(public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public adminService: AdminSssService,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onFilterChange(e: IHomEventEmitter): void {
    if (e.action === 'sssProgramService' && e.data && e.data.hasOwnProperty('searchTerm')) {
      const searchTerm: ISearchTerm = e.data['searchTerm'];
      if (searchTerm.value) {
        this.requestSSSBranchPrograms(searchTerm.value);
      } else {
        this.resetBranchProgramList();
      }
    }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadSSSBranchProgramsListDefinition();
  }

  public requestSSSBranchPrograms(programServiceIds: number[]): void {
    let listFilter: IListFilter = new ListFilter();
    listFilter.getAll = true;
    listFilter.orderTerm = [new OrderTerm('branchProgram_branch_branchName')];
    let searchTerms: ISearchTerm[] = [];
    programServiceIds.forEach(x => {
      searchTerms.push({ term: 'sssProgramService_sssProgramServiceId', columnName: 'sssProgramService_sssProgramServiceId', searchType: SearchType.Equals, value: x, fieldType: this.myConstants.dataTypeInt});
    });
    listFilter.searchTerm = searchTerms;

    this.rootStore.dispatch(new GetEntityListByMethodParams(
      'SssBranchProgram',
      'ByProvider',
      '?id=' + this.ups.providerId$.value.toString(),
      listFilter,
      'sssBranchProgram'
    ));
  }

  public resetBranchProgramList(): void {
    this.rootStore.dispatch(new InitSelectItems({ storeName: 'sssBranchProgram', parentId: - 1}));
  }

}
