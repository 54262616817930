<div class="user-access-create-item {{ customClass }}"
              [ngClass]="{'user-access-create-item--noclick': hasSubItems }"
              (click)="selectItem()">
<div class="user-access-create-item__name" [ngClass]="{'user-access-create-item--selected': isSelected }">{{name}}</div>
<div class="user-access-create-item__count" title="'Number of Stores / Branches Selected">{{ count$ | async }}</div>
<div *ngIf="hasSubItems"
     class="check-box-field user-access-create-item__input"
     [ngClass]="{'check-box-field user-access-create-item__input--notall': (count$ | async) !== '0' && (count$ | async) !== 'All' }"
    title="Select All Stores / Branches For This Location">
  <fw-check-box #selectAllSubItems
                [config]="selectAllConfig"
                [isDisabled]="false"
                (change)="selectAll($event)">
  </fw-check-box>
</div>
<ng-container *ngIf="hasSubItems">
  <button type="button"
          class="app-btn-icon  user-access-create-item__btn"
          title="Show Stores / Branches"
          (click)="toggleSubItems()">
    <span class="fas" [ngClass]="{ 'fa-caret-up': isExpanded, 'fa-caret-down': !isExpanded }"></span>
  </button>
</ng-container>
<ng-container *ngIf="!hasSubItems">
  <div *ngIf="isSelected" class="app-icon app-icon--success user-access-create-item__icon">
    <span class="fas fa-check"></span>
  </div>
</ng-container>
</div>
<div *ngIf="hasSubItems"
     class="user-access-create-sub-list {{ 'user-access-create-sub-list--'.concat(item['name'].replace(' ', '-').replace('.', '').toLowerCase()) }}"
     [ngClass]="{'user-access-create-sub-list--collapsed': !isExpanded}">
  <ng-container *ngFor="let subItem of item.workingBranches">
    <user-access-create-sub-item [item]="subItem"
                                 [name]="subItem.name"
                                 [parentIsSelected]="isSelected"
                                 [parentSelectAll]="isParentSelectAll"
                                 (selectEvent)="onSelectSubItem($event)">
    </user-access-create-sub-item>
  </ng-container>
</div>
