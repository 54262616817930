/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy,  Inject, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IChargeBack, IProject } from '../../../view-models/index';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { IErrorData } from '../../../../../shared/interfaces/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { UpdateObjectByIdModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';

import { ProjectService } from '../../services/project.service';
import { UserPriviledgesService } from '../../../../../auth/services/index';

//store actions, reducers, interfaces
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { getObjectDataById } from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../../app/store/reducers/index';
import { ProjectObjectStore, ProjectListStore } from '../../enums/project.enums';
import { HomDataUtility } from '../../../../../shared/services';


@Component({
  selector: 'charge-back-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './charge-back-manager.component.html'
})
export class ChargeBackManagerComponent implements OnInit, OnDestroy {
  public  chargeBack: IChargeBack;
  public displayFields: string[] = ['installerName', 'chargeBackStatus', 'chargeBackNotes', 'acknowledged', 'submitted'];
  public storeKeyName: string = 'chargeBackId';
  public requestor: string = 'project';
  public title: string = 'Overview';
  public entityLabel: string = 'Chargeback';
  public chargeBackId: number;
  public projectId: number;
  public storeName: string;
  public operation$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  public hasStaleData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public entityStatus$: BehaviorSubject<string> = new BehaviorSubject('');
  public canIEdit$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEditCb$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEditProject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public printUrl: string;
  subscription: Subscription = new Subscription();

  constructor(public store: Store<fromRoot.IState>,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public projectService: ProjectService,
    public activeRoute: ActivatedRoute,
    public dataUtils: HomDataUtility) { }

  public onCustom(event: IHomEventEmitter): void {
    const chargeBackListDef: IListDefinition = this.projectService.loadProjectChargeBackListDefinition(this.projectId, this.canIEdit$.getValue(), false);
    switch (event.event) {
      case ButtonType.edit:
        this.operation$.next(this.myConstants.operationTypeEdit);
        break;
      case ButtonType.reload:
        this.store.dispatch(new DynamicListActions.GetList({ listDefinition: chargeBackListDef, listFilter: chargeBackListDef.defaultListFilter, parentId: chargeBackListDef.parentId }));
        break;
      case ButtonType.save:
        this.saveChargeBack(event);
        break;
      case ButtonType.cancel:
        this.store.dispatch(new DynamicListActions.ClearErrorsList({ storeName: chargeBackListDef.storeName, parentId: chargeBackListDef.parentId }));
        this.operation$.next(this.myConstants.operationTypeDetails);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.storeName = ProjectListStore.projectChargeBacks;
    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get('portalEntityId');
      this.getProject();
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.chargeBackId = +paramMap.get('id');
    });

    //multiple objservable dependencies will determine editability
    this.subscription.add(this.canIEditProject$.subscribe(val => {
      this.canIEdit$.next(this.canIEditCb$.getValue() && val);
    }));
    this.subscription.add(this.canIEditCb$.subscribe(val => {
      this.canIEdit$.next(this.canIEditProject$.getValue() && val);
    }));


    this.getData();
  }

  getProject(): void {
    this.subscription.add(this.store.pipe(
      select(getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)))
      .subscribe((objData: IProject) => {
        if (objData) {
          this.canIEditProject$.next(this.ups.canIEdit(objData));
        }
      })
    );
  }

  getData(): void {
    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectChargeBacks)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.data) {
          this.errorData$.next(state.errorData);
          if (state.event) {
            this.operation$.next(this.myConstants.operationTypeDetails);
            this.store.dispatch(new DynamicListActions.ClearEventList({ storeName: ProjectListStore.projectChargeBacks, parentId: this.projectId }));
          }
          this.hasStaleData$.next(state.staleData);
          if (!state.errorData || !state.errorData.length) {
            this.chargeBack = state.data.find(x => x[this.storeKeyName] == this.chargeBackId);
            this.printUrl = this.dataUtils.getUrlByName(this.chargeBack, 'chargeBackNoticeUrl');
            this.entityStatus$.next(this.chargeBack ? this.chargeBack['chargeBackStatus'] : '');
            this.canIEditCb$.next(this.chargeBack && this.chargeBack.canAddItems && this.ups.manageWarrantyProjects$.getValue());
          }
        }
      }));

    //listen for event changes to Charge Back ITEM store and reload this project charge back item store if receive any
    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.chargeBackItems)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId === this.chargeBackId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          if (state.event && !state.errorData.length) {
            const cbListDef: IListDefinition = this.projectService.loadProjectChargeBackListDefinition(this.projectId, this.canIEdit$.getValue(), false);
            this.store.dispatch(new DynamicListActions.GetList({ listDefinition: cbListDef, listFilter: cbListDef.defaultListFilter, parentId: cbListDef.parentId }));
            //temp: refresh all the charge back items
            const cbItemListDef: IListDefinition = this.projectService.loadProjectChargeBackItemListDefinition(this.projectId, this.canIEdit$.getValue());
            this.store.dispatch(new DynamicListActions.GetList({ listDefinition: cbItemListDef, listFilter: cbItemListDef.defaultListFilter, parentId: cbItemListDef.parentId }));
          }
        }
      }));

  }

  saveChargeBack(event: IHomEventEmitter): void {
    const chargeBack = {...this.chargeBack},
      formData: IChargeBack = event.data;
    chargeBack.chargeBackNotes = formData.chargeBackNotes;
    chargeBack['chargeBackStatus'] = formData['chargeBackStatus']['name'];
    const keyData: IKey = { storeName: ProjectListStore.projectChargeBacks, parentId: this.projectId, key: this.storeKeyName, id: this.chargeBack.chargeBackId }
    const emitter: IHomEventEmitter = { requestor: this.requestor, event: this.myConstants.emitterEventUpdate, action: '', data: null };
    const updateData = new UpdateObjectByIdModel(keyData, 'ChargeBack', 'Update', this.storeKeyName, this.chargeBack.chargeBackId, chargeBack, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectByIdList({ updateData: updateData }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
