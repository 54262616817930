import {  Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IScreenBreakpoints } from './i-screen-breakpoints';

@Injectable()
export class ScreenService {
    emitResizeSource = new Subject<IScreenBreakpoints>(); //subject is an observable that can fire off events for an observable - may want this to be more robust.
    resize$ = this.emitResizeSource.asObservable(); //our observable - public property that anyone using screenservice can subscribe to.

    //App works with a screen size of 1920X1080 max
    maxBreakpoint: number = 1920; //pixel value we choose for max handled window resolution width
    largeBreakpoint: number = 1180; //pixel value we choose for large windows
    mediumBreakpoint: number  = 900; //pixel value we choose for ipad in horizonal model
    smallBreakpoint: number  = 712; //pixel value we choose for  phones
    screenWidth: number  = 1920; //max width (resolution really) to start with
    screenHeight: number  = 1080; //max width to start with

    breakPointChange: boolean = false;

    constructor() {
        try {
            this.screenWidth = window.innerWidth;
            this.screenHeight = window.innerHeight;
            window.addEventListener('resize', (event) => {
            this.onResize(event);
          });
        }
        catch (e) {
            // we're going with default screen dimensions
        }
    }

    currentBreakpoints: IScreenBreakpoints = {
      isSmall: this.isSmall(),
      isMedium: this.isMedium(),
      isLarge: this.isLarge()
    }

  isGtrThanMax(elLeft: number = 0, elWidth: number = 0): boolean {
    return (this.getWidth(elWidth) - elLeft) > this.maxBreakpoint;
  }

  isLessThanMax(elLeft: number = 0, elWidth: number = 0): boolean {
    return (this.getWidth(elWidth) - elLeft) < this.maxBreakpoint;
  }

  isMax(elLeft: number = 0, elWidth: number = 0): boolean {
    return (this.getWidth(elWidth) - elLeft) > this.largeBreakpoint;
  }


  isLarge(elLeft: number = 0, elWidth: number = 0): boolean {
    return (this.getWidth(elWidth) - elLeft) <= this.largeBreakpoint && (this.screenWidth - elLeft) > this.mediumBreakpoint;
  }

  isLessThanLarge(elLeft: number = 0, elWidth: number = 0): boolean {
    return (this.getWidth(elWidth) - elLeft)  < this.largeBreakpoint;
    }

  isMedium(elLeft: number = 0, elWidth: number = 0): boolean {
    return (this.getWidth(elWidth) - elLeft) <= this.mediumBreakpoint && (this.screenWidth - elLeft)  > this.smallBreakpoint;
    }

  isSmall(elLeft: number = 0, elWidth: number = 0): boolean {
    return (this.getWidth(elWidth) - elLeft) <= this.smallBreakpoint;
  }


  getWidth(elWidth: number): number {
  //  return elWidth > 0 ? elWidth : this.screenWidth;
    return this.screenWidth;
  }

  onResize(e: UIEvent): void {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;

        let currentSettings: IScreenBreakpoints = {
          isSmall: this.isSmall(),
          isMedium: this.isMedium(),
          isLarge: this.isLarge()
        }


        if (currentSettings !== this.currentBreakpoints) {
          //Only emit an event if breakpoints have changed  
          this.emitResizeSource.next(currentSettings);  
          //any subscribers (screenService.resize$.subscribe() will get notified that the screen has resized and can act accordingly.
          //if we need more info to be sent (or less, modify IScreenBreakpoints accordingly)
          this.currentBreakpoints = currentSettings;
        }

    }
  
}
