import { Component, OnInit, Inject } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { AdminEvent } from '../../enums/admin.enums';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { IServiceGroup } from '../../../view-models';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';

import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'service-groups',
  templateUrl: './service-groups.component.html'
})
export class ServiceGroupsComponent implements OnInit {

  listDefinition: IListDefinition;

  constructor(
    public adminProviderService: AdminProviderService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants
  ) {
  }

  ngOnInit() {
    this.listDefinition = this.adminProviderService.loadServiceGroupsListDefinition();
  }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case AdminEvent.showServiceGroupServices:
        this.showServiceGroupServices(event.data);
        break;
      case AdminEvent.launchServiceGroupsWizard:
        this.launchServiceGroupsWizard();
      default:
        break;
    }
  }

  launchServiceGroupsWizard(): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: this.listDefinition.storeName,
      key: this.listDefinition.rowKeyId,
      operation: this.myConstants.operationTypeCreate,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: -1,
    }
    this.modalService.open({
      title: 'Service Group Creation Wizard',
      path: 'service-group-creation-wizard',
      id: -1,
      sizeType: ModalSizeType.medium,
      castExit: false,
      optionalParams: params,
      hasTabs: false,
    });
  }

  showServiceGroupServices(data: IServiceGroup): void {
    let params: IDetailRouteParameter = {
      rowIndex: -1,
      storeName: this.listDefinition.storeName,
      key: this.listDefinition.rowKeyId,
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: data.serviceGroupId,
    }
    this.modalService.open({
      title: 'Service Group Services For ' + data.serviceGroupName,
      path: 'service-group-services',
      id: data.serviceGroupId,
      sizeType: ModalSizeType.medium,
      castExit: false,
      optionalParams: params,
      hasTabs: false,
    });
  }

}
