<div *ngIf="(objectData$ | async) && (formDefinition$ | async)">
  <fw-dynamic-form [vm]="objectData$ | async"
                   [storeName]="detailConfig.storeName"
                   [vmFormDefinition]="formDefinition$ | async"
                   [parentId]="detailConfig.parentId"
                   [displayFields]="displayFields"
                   [operation]="detailConfig.operation"
                   [requestTime]="detailConfig.requestTime"
                   [includeNavButtons]="detailConfig.showNav"
                   [errorMessage]="errorMessage"
                   [errorData]="errorData$ |  async"
                   [forceDirty]="forceDirty"
                   [forceInvalid]="forceInvalid">
    <ng-content select="[dynamic-detail-drop]"></ng-content>
  </fw-dynamic-form>
</div>

