export interface IZone {
  zoneId: number;
  provider_providerId: number;
  zoneName: string;
}

export class Zone implements IZone {
  zoneId: number;
  provider_providerId: number;
  zoneName: string;

  constructor() {
    this.zoneId = 0;
    this.provider_providerId = 0;
    this.zoneName = '';
  }
}


