import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { AdminStore, AdminEvent } from '../../enums/admin.enums';
import { IPrivilegeTemplate } from '../../../view-models/i-privilege-template';

import { UserPriviledgesService } from '../../../../../auth/services';
import { AdminSecurityService } from '../../services/admin-security.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';

@Component({
  selector: 'privilege-templates',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './privilege-templates.component.html'
})
export class PrivilegeTemplatesComponent implements OnInit {
  public listDefinition: IListDefinition;
  params: IDetailRouteParameter;

  constructor(public activeRoute: ActivatedRoute,
    public userPriviledgesService: UserPriviledgesService,
    public securityService: AdminSecurityService,
    public modalService: ModalService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.managePrivTemplateProviderRoles:
        this.openPrivTemplateRoles(event.data);
        break;
      case AdminEvent.managePrivTemplatePrivileges:
        this.openPrivTemplatePrivileges(event.data);
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.params = {
      rowIndex: -1,
      storeName: AdminStore.privilegeTemplates,
      key: 'privilegeTemplateId',
      operation: this.myConstants.operationTypeDetails,
      showNext: false,
      showPrev: false,
      requestTime: new Date(),
      portalEntityId: -1
    }

    this.listDefinition = this.securityService.loadPrivilegeTemplatesListDefinition();
  }

  openPrivTemplateRoles(template: IPrivilegeTemplate): void {
    this.modalService.open({
      title: 'Provider Roles for Privilege Template'.concat(' ', template.name),
      path: 'admin-pt-roles',
      id: template.privilegeTemplateId,
      sizeType: ModalSizeType.small,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }

  openPrivTemplatePrivileges(template: IPrivilegeTemplate): void {
    this.modalService.open({
      title: 'Privileges for Privilege Template'.concat(' ', template.name),
      path: 'admin-pt-privs',
      id: template.privilegeTemplateId,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: this.params,
      hasTabs: false
    });
  }
}
