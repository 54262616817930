
export enum ChargeBackSkuPriceEvent {
  changePriceType = 'changePriceType',
  selectInstaller = 'selectInstaller',
  selectLocation = 'selectLocation',
  selectService = 'selectService',
  selectLocationService = 'selectLocationService',
  removeLocationService = 'removeLocationService',
  changePrice = 'changePrice'
}


export enum ChargeBackSkuPriceType {
  global = 'Global',
  installer = 'Installer',
  providerLocation = 'Provider Location',
  service = 'Service'
}
