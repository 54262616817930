import { Component, OnInit, OnDestroy,  Inject } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces/index';
import { UpdateObjectCustomModel, IKey, CreateObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IProviderLocationShipperInventorySetup, ProviderLocationShipperInventorySetup } from '../../../view-models';
import { IJobScheduleViewModel } from '../../../view-models/i-job-schedule-view-model';
import { JobScheduleService, IDaysOfWeek } from '../../../portal-shared/services';
import { IJobScheduleEvent } from '../../interfaces';

//store actions, reducers, interfaces
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as fromDynamicList from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { UserPriviledgesService } from '../../../../../auth/services';

interface IReturnObject {
  model: IProviderLocationShipperInventorySetup,
  jobSchedule: IJobScheduleViewModel,
  jobScheduleWeekdays: string[]
}

@Component({
  selector: 'shipper-location',
  templateUrl: './shipper-location.component.html'
})
export class ShipperLocationComponent implements OnInit, OnDestroy  {
  public objectData: IProviderLocationShipperInventorySetup;
  public displayFields = ['providerLocation', 'shipperDesignation', 'shipperWarehouseDesignation', 'leadTimeDays', 'timeCutoff'];
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  public forceDirty$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public forceInvalid$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  storeName: string = '';
  key: string = '';
  operation: string;
  parentId: number = 0; //shipperId  
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string;
  detailRoutePath: string = '';
  jobSchedule: IJobScheduleViewModel = null;
  jobScheduleFormValid: boolean = false;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public emitterService: HomEventEmitterService,
    public dynamicDetailService: DynamicDetailService,
    public ups: UserPriviledgesService,
    public jobScheduleService: JobScheduleService,
    @Inject(appConstants) public myConstants: IAppConstants  ) {

    this.subscription.add(emitterService.detailEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        switch (e.event) {
          case this.myConstants.emitterEventUpdate:
            if (this.jobScheduleFormValid) {
              this.updateRecord(e);
            } else {
              alert('The Delivery Schedule is invalid.  Please resolve before saving.');
            }
            break;
          case this.myConstants.emitterEventCreate:
            if (this.jobScheduleFormValid) {
              this.createRecord(e);
            } else {
              alert('The Delivery Schedule is invalid.  Please resolve before saving.');
            }
            break;
          default:
            break;
        }
      }));
  }

  ngOnInit() {
    this.subscription.add(this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.parentId = +paramMap.get('id');
    }));

    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.getDetail();
      this.setDetailConfig(paramMap);
    }));
  }

  public onScheduleChange(event: IJobScheduleEvent): void {
    //update the working model;
    this.jobSchedule = event.model;
    this.jobScheduleFormValid = event.isValid;
    this.forceDirty$.next(true);
    this.forceInvalid$.next(!event.isValid)
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = this.parentId;
    params.showErrorBox = false;
    this.detailConfig$.next(params);
 }

  getDetail() {
    this.subscription.add(this.store.pipe(
      select(fromDynamicList.getSelectedRecord(this.storeName, this.parentId, this.key, this.objectId)))
      .subscribe(entity => {
        this.objectData = !entity && this.operation === this.myConstants.operationTypeCreate
          ? new ProviderLocationShipperInventorySetup() : entity;
        this.setJobScheduleModel();
      })
    );

    this.subscription.add(this.store
      .pipe(select(fromDynamicList.getSelectedParentListDefinition(this.storeName, this.parentId)),
        filter(listDefinition => listDefinition !== null),
        take(1))
      .subscribe(listDefinition => {
        this.detailRoutePath = listDefinition.detailRoutePath;
        this.controllerName = listDefinition.controllerName;
      }
    ));
  }

  setJobScheduleModel(): void {
    const daysOfWeek: IDaysOfWeek[] = this.jobScheduleService.daysOfWeek;

    let jobSchedule: IJobScheduleViewModel = {
      jobScheduleName: this.objectData.jobScheduleName,
      isActive: this.objectData.isActive,
      jobFrequencyType: this.objectData.jobFrequencyType,
      jobFrequencyRecurUnit1: this.objectData.jobFrequencyRecurUnit1,
      jobStartDate: this.objectData.jobStartDate,
      jobEndDate: this.objectData.jobEndDate,

      //convert numeric incoming to outgoing string for enum list
      jobScheduleWeekdays: daysOfWeek
        .filter(x => this.objectData.jobScheduleWeekdays.includes(x.dayNbr))
        .map(y => y.longName),

      jobScheduleId: this.objectData.jobScheduleId || 0,
      providerId: this.objectData.providerId || this.ups.providerId$.value,
      jobScheduleSummary: '', //derived field
      allDayEvent: this.objectData.allDayEvent || true,
      jobScheduleType: this.objectData.jobScheduleType,
      jobScheduleRecurrenceType: this.objectData.jobScheduleRecurrenceType, //Set default on create in backend
      jobFrequencyRecurUnit2: 1,//Set default on create in backend
      jobFrequencyRecurUnit3: 1,//Set default on create in backend
      dailyFrequencyTime: this.objectData.dailyFrequencyTime,//Set default on create in backend
      dailyFrequencyUnit: this.objectData.dailyFrequencyUnit,//Set default on create in backend
      dailyFrequencyStartTime: this.objectData.dailyFrequencyStartTime, //Set default on create in backend
      dailyFrequencyEndTime: this.objectData.dailyFrequencyEndTime, //Set default on create in backend
      dailyFrequency: ''//Set default on create in backend
    }

    this.jobSchedule = jobSchedule;
  }


  createRecord(e: IHomEventEmitter) {
    let rec: IProviderLocationShipperInventorySetup = this.setCommonProperties(e.data);
    rec.shipper_shipperId = this.parentId;
    const obj: IReturnObject = this.createReturnObject(rec);
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: '', data: null };
    const createData = new CreateObjectModel(this.storeName, this.parentId, this.controllerName, 'Create', obj, null, emitter);
    this.store.dispatch(new DynamicListActions.CreateObjectList({ createData }));
  }

  updateRecord(e: IHomEventEmitter) {
    let rec: IProviderLocationShipperInventorySetup = this.setCommonProperties(e.data);
    const obj: IReturnObject = this.createReturnObject(rec);
    const keyData: IKey = { storeName: this.storeName, parentId: this.parentId, key: this.key, id: this.objectId }
    const emitter: IHomEventEmitter = { requestor: e.requestor, event: e.event, action: e.action, data: null };
    const updateData = new UpdateObjectCustomModel(keyData, this.controllerName, 'Update', obj, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectCustomList({ updateData }));
  }

  setCommonProperties(formData: IProviderLocationShipperInventorySetup): IProviderLocationShipperInventorySetup {
    let rec = cloneDeep(this.objectData);
    rec.providerLocation_providerLocationId = formData.hasOwnProperty('providerLocation') && formData['providerLocation'].hasOwnProperty('providerLocationId')
      ? formData['providerLocation']['providerLocationId']
      : null;
    rec.shipperDesignation = formData.shipperDesignation;
    rec.leadTimeDays = formData.leadTimeDays;
    rec.timeCutoff = formData.timeCutoff;
    rec.shipperWarehouseDesignation = formData.shipperWarehouseDesignation;
    rec.deliverySchedule_deliveryScheduleId = formData.hasOwnProperty('deliverySchedule') && formData['deliverySchedule'].hasOwnProperty('deliveryScheduleId')
      ? formData['deliverySchedule']['deliveryScheduleId']
      : null;

    return rec;
  }

  createReturnObject(shipperLocation: IProviderLocationShipperInventorySetup): IReturnObject {
    let js = cloneDeep(this.jobSchedule);
    js.jobScheduleWeekdays = null; //extra data
    js.jobFrequencyType = js.jobFrequencyType.hasOwnProperty('name') ? js.jobFrequencyType['name'] : js.jobFrequencyType;

    return {
      model: shipperLocation,
      jobSchedule: js,
      jobScheduleWeekdays: this.jobSchedule.jobScheduleWeekdays || []
    }

  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

} 

