import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { HomPipesModule } from 'hom-lib/hom-pipes';
import { FwModule } from '../../fw/fw.module';
import { SharedModule } from '../../shared/shared.module';
import { FwSharedModule } from '../../fw/fw-shared/fw-shared.module';
import { PortalSharedModule } from '../portals/portal-shared/portal-shared.module';
import { SmsRoutingModule } from './sms-routing.module';

//components
import {
  SmsReceivedComponent,
  SmsSentComponent,
  SmsEmptyComponent,
  SmsFiltersComponent,
  SmsContactComponent,
  SmsProjectComponent,
  SmsShortcutsComponent,
  SmsDocumentTypesComponent,
  SmsEntitySelectionComponent,
  SmsSendDocumentComponent,
  SmsFileComponent,
  SmsFileTypeSelectionComponent
} from './components/index';

//containers
import {
  SmsConversationComponent,
  SmsProjectSummaryComponent,
  SmsManagerComponent
} from './containers/index';

import {
  CommunicationShortcutResolver,
  CommunicationDocumentTypeResolver,
  DocumentTypeResolver
} from '../portals/resolvers';


@NgModule({
  declarations: [
    SmsReceivedComponent,
    SmsSentComponent,
    SmsEmptyComponent,
    SmsConversationComponent,
    SmsFiltersComponent,
    SmsContactComponent,
    SmsProjectComponent,
    SmsShortcutsComponent,
    SmsDocumentTypesComponent,
    SmsEntitySelectionComponent,
    SmsSendDocumentComponent,
    SmsFileComponent,
    SmsFileTypeSelectionComponent,
    SmsProjectSummaryComponent,
    SmsManagerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PickerModule,
    FwModule,
    FwSharedModule,
    PortalSharedModule,
    SharedModule,
    SmsRoutingModule,
    HomPipesModule,
    RouterModule
  ],
  exports: [
    SmsConversationComponent,
    SmsProjectSummaryComponent,
    SmsManagerComponent
  ],
  providers: [
    CommunicationShortcutResolver,
    CommunicationDocumentTypeResolver,
    DocumentTypeResolver
  ]
})
export class SmsModule {
  static forRoot(): ModuleWithProviders<SmsModule> {
    return {
      ngModule: SmsModule
    };
  }
}
