import { Injectable, Inject } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, filter , first, take } from 'rxjs/operators';

import { SearchType } from '../../../../fw/dynamic-list/enums/search-type.enums';
import { IAppConstants, appConstants } from '../../../../shared/constants';
import { IListFilter, OrderTerm } from '../../../../fw/dynamic-list/interfaces';
import { AccessLevel } from '../../../../fw/dynamic-list/enums/access-level.enums';
//store actions and reducers
import * as fromRoot from '../../../../app/store/reducers/index';
import * as fromSelectionLists from '../../../../shared/store/selectionLists/index';
import { GetEntityListByMethodParams, getSelectionListDataByType } from '../../../../shared/store/selectionLists/index';
import { UserPriviledgesService } from '../../../../auth/services';

//Custom Resolver to get just the Padding Payout items
@Injectable()
export class MaterialPayoutResolver  {
  shipperId: number;
  storeName: string;
  key: string;
  subscription: Subscription = new Subscription();
  filter: IListFilter = {
    isLookup: false,
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true, //do not want paged data
    currentPage: 1,
    searchTerm: [{ term: 'sku_skuType', value: 'Materials', searchType: SearchType.Equals, columnName: 'sku_skuType', fieldType: this.myConstants.dataTypeEnum }],
    orderTerm: [new OrderTerm('payout_payoutName')]
  };
//  

  constructor(public selectionListStore: Store<fromSelectionLists.IStoreState>,
    public rootStore: Store<fromRoot.IState>,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
    this.requestData();

    return this.waitForDataToLoad();
  }

  waitForDataToLoad(): Observable<any[]> {
    return this.selectionListStore.select(getSelectionListDataByType('materialPayout', -1))
      .pipe(
        filter((data: any[]) => data !== null),
        map((data: any[]) => {
          return data;
        }),
        first()
      );
  }

  requestData(): void {
    this.selectionListStore.select(fromSelectionLists.getSelectionListDataByType('materialPayout', -1))
      .pipe(take(1))
      .subscribe((data: any[]) => {
        if (data === null) {
          this.selectionListStore.dispatch(new GetEntityListByMethodParams(
            'SkuPayout',
            'ByProvider',
            '?id=' + this.ups.providerId$.value.toString(),
            this.filter,
            'materialPayout'));
        }
      });

   
  }

}
