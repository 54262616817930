import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { FwSharedModule } from '../fw-shared/fw-shared.module';
import { DynamicFormModule } from '../dynamic-forms/dynamic-form.module';

//services
import { BodyService } from '../fw-body/services/fw-body.service';

//components
import {
  DynamicDetailContainerComponent,
  DynamicDetailComponent
} from './index';
import { DynamicDetailCustomContainerComponent } from './containers/dynamic-detail-custom-container/dynamic-detail-custom-container.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FwSharedModule,
    DynamicFormModule
  ],
  declarations: [
    DynamicDetailContainerComponent,
    DynamicDetailComponent,
    DynamicDetailCustomContainerComponent
  ],
  exports: [
    DynamicDetailContainerComponent,
    DynamicDetailComponent,
    DynamicDetailCustomContainerComponent
  ],

})
export class DynamicDetailModule {
  static forRoot(): ModuleWithProviders<DynamicDetailModule> {
    return {
      ngModule: DynamicDetailModule,
      providers: [BodyService ]
    };
  }
}
