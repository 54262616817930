export interface ICommunicationEventFeedback {
  communicationEventFeedbackId: number;
  communicationEventType_communicationEventTypeId: number;
  communicationEventFeedbackText: string;
}

export class CommunicationEventFeedback implements ICommunicationEventFeedback {
  communicationEventFeedbackId: number;
  communicationEventType_communicationEventTypeId: number;
  communicationEventFeedbackText: string;

  constructor() {
    this.communicationEventFeedbackId = 0;
    this.communicationEventType_communicationEventTypeId = null;
    this.communicationEventFeedbackText = '';
  }

}

