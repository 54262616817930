import { Component, Input, OnInit, Inject, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {  filter } from 'rxjs/operators';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { ContactConstantsService, ContactManagerService } from '../../services/index';
import { IFieldDefinition } from '../../../../fw/dynamic-forms';
import { IContactMechanismEmail } from '../../../portals/view-models';
import { IContactValueChanged } from '../../../portals/portal-shared/interfaces';

@Component({
  selector: 'contact-emails',
  templateUrl: './contact-emails.component.html',
})
export class ContactEmailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fieldDefinitions: IFieldDefinition[];
  @Input() emails: IContactMechanismEmail[];
  @Input() operation: string;
  @Input() displayType: string;
  @Input() requestTime: string; //to handle subsequent create request for the same contact type (id never changes)
  @Input() canIEdit: boolean;

  @Output() public deleteEvent = new EventEmitter<IHomEventEmitter>();

  public activePreferred$: BehaviorSubject<number> = new BehaviorSubject(-1);
  //sortedEmails$: BehaviorSubject<IContactMechanismEmail[]> = new BehaviorSubject(null);
  subscription: Subscription = new Subscription();

  constructor(public contactConstantsService: ContactConstantsService,
    public contactService: ContactManagerService,
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit(): void {
    this.initForm();
  }

  public onDelete(event: IHomEventEmitter): void {
    this.deleteEvent.emit(event);
  }

  initForm() {
    if (this.emails) {
      this.activePreferred$.next(this.emails.findIndex(x => x.isPreferred));
    }

    //listen for value changes in child
    this.subscription.add(this.emitterService.contactEventEmitted$
      .pipe(filter(e => e.requestor === this.contactConstantsService.requestorTypeContactEmail && e.event === this.myConstants.emitterEventValueChanged)) //filter
      .subscribe((e: IHomEventEmitter) => {
        const data: IContactValueChanged = e.data;
        if (data.valueChanged.key === 'isPreferred' && data.valueChanged.value === true) {
          this.activePreferred$.next(this.contactService.getIndex(this.contactConstantsService.emailFormPrefix, data.groupName));
        }
      }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['requestTime'] && !(changes['requestTime'].firstChange)) {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
