
export enum SmsEvent {
  selectContact = 'selectContact',
  filterContacts = 'filterContacts',
  refreshContacts = 'refreshContacts',
  goToProject = 'goToProject',
  sentText = 'sentText',
  receivedNewText = 'receivedNewText',
  selectShortcut = 'selectShortcut',
  unSubscribe = 'unSubscribe',
  subscribe = 'subscribe',
  smsSendError = 'smsSendError'
}

export enum ContactType {
  customer = 'Customer',
  installer = 'Installer'
}

export enum SmsMessages {
  maxDocs = 'A maximum of 10 documents may be appended to a text.',
  maxDocsError = 'A maximum of 10 documents have already been appended to text.'
}

export enum SmsEntityName {
  project = 'Project',
  purchaseOrder = 'PurchaseOrder',
  workOrder = 'WorkOrder'
}
