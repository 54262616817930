<div class="task-header">
  Generating Work Orders<div class="task-header__status">Status</div>
</div>
<div class="sync-task {{ genTask.status === 'times-circle' ? 'dynamic-list__item--danger' : '' }}">
  <div class="sync-task__indicator">
    <i class="fal fa-{{ !genTask.status ? 'spinner' : genTask.status }}"></i>
  </div>
  <div class="sync-task__name">{{ genTask.id }}</div>
  <div class="sync-task__status {{ genTask.status ? 'sync-task__status--done' : '' }}">{{ genTask.statusText }}</div>
</div>
