import { Component, Input, Inject } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { INotification } from '../../../app/portals/view-models';
import { appConstants, IAppConstants } from '../../constants';
import { ITabRequest } from '../../../fw/fw-shared/components/fw-app-tabs/interfaces';

import { ModalService } from '../../../fw/fw-modal/services/fw-modal.service';
import { TabsService } from '../../../fw/fw-shared/components/fw-app-tabs/services/fw-app-tabs.service';

@Component({
  selector: 'fw-note-context',
  templateUrl: './note-context.component.html'
})
export class NoteContextComponent  {
  @Input() row: INotification;

  constructor(
    public modalService: ModalService,
    public tabsService: TabsService,
  @Inject(appConstants) public myConstants: IAppConstants) {}

  public openLink() {
    if (this.row.context.portalId <= 0) {
      return;
    }

    const portalId = this.row.hasOwnProperty('context') && this.row['context'].hasOwnProperty('portalId')
      ? this.row['context']['portalId']
      : 0; 

    const entityType = this.row.hasOwnProperty('context') && this.row['context'].hasOwnProperty('entityName')
      ? this.row['context']['entityName']
      : ''; 

    const entityLabel = this.row.hasOwnProperty('context') && this.row['context'].hasOwnProperty('entityLabel')
      ? this.row['context']['entityLabel']
      : ''; 

    const pkId = this.row.hasOwnProperty('context') && this.row['context'].hasOwnProperty('entityPkid')
      ? this.row['context']['entityPkid']
      : 0; 

    if (portalId === 0 || !entityType || pkId === 0) {
        return;
      }
      //entityPkid
      const data: ITabRequest = {
        text: entityType.concat(' ', entityLabel),
        portalId: portalId,
        portalEntityId: pkId,
        level2EntityName: entityType.toLowerCase(),
        level2EntityId: 0,
        level3EntityName: null,
        level3EntityId: null,
        level2Text: '',
        level3Text: ''
      },
      event: IHomEventEmitter = {
        requestor: 'note-context',
        event: this.myConstants.emitterEventTabLevel1Open,
        action: '',
        data: data
      };

      if (this.modalService.opened) this.modalService.close();
      this.tabsService.emitTabEvent(event);
    }

 }
