import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { filter, map, tap, take } from 'rxjs/operators';
import { orderBy, cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { HomErrorHandlerService, HomErrorLevel } from 'hom-lib/hom-error-logger';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IErrorData, IHomDictionary, HomDictionary, } from '../../../../../shared/interfaces/index';
import { IProject, IContactCustomerFlag, IMarginResults, IGeneralContractor, IProviderLocation, IWorkOrder, IContact, IBranchProgram } from '../../../view-models/index';
import { ICustomButton } from '../../../../../fw/fw-shared/interfaces/i-custom-button';
import { IListDefinition, ListFilter, IListFilter } from '../../../../../fw/dynamic-list/interfaces/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';

import * as DynamicObjectActions from '../../../../../fw/dynamic-list/store/actions/dynamic-object.actions';
import { IDynamicObject } from '../../../../../fw/dynamic-list/store/reducers/dynamic-object.reducer';
import { IListObjectData, IDynamicListState } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import { getObjectDataById, getObjectDataByType, getObjectErrorsById } from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import * as SelectionListActions from '../../../../../shared/store/selectionLists/selectionLists.actions';
import { listDataExists, getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { ProjectObjectStore, ProjectListStore, ProjectEvent } from '../../enums/project.enums';
import { IInputButton } from '../../../../../fw/fw-shared/interfaces/i-input-button';
import { WidgetComponentName } from '../../../../../auth/enums/widget.enums';
import { ContactStore } from '../../../../contact/enums/contact.enums';
import { getMetaDataByType, IMetaDataState } from '../../../../../fw/dynamic-list/store';
import { IFieldDefinition } from '../../../../../fw/dynamic-forms';
import { CommunicationEventEvent } from '../../../portal-shared/enums/communication-event.enums';
import { IConversationRequest } from '../../../portal-shared/interfaces/i-conversation-request';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import * as fromAuth from '../../../../../auth/store/index';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';

import { CommunicationEventService } from '../../../portal-shared/services/communication-event.service';
import { MetaDataService } from '../../../../../fw/dynamic-list/services/index'
import { UserPriviledgesService } from '../../../../../auth/services/index';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ProjectService } from '../../services/project.service';
import { ContactUtilityService } from '../../../../contact/services';
import { AccessLevel } from '../../../../../fw/dynamic-list/enums/access-level.enums';


@Component({
  selector: 'project-high-level-summary',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './project-high-level-summary.component.html',
  providers: [MetaDataService]
})
export class ProjectHighLevelSummaryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() projectId: number;
  @Input() portalReload: string = '';

  public generalContractors: IGeneralContractor[];
  public providerLocations: IProviderLocation[] = null;
  public contactId: number;
  public isWarranty: boolean = false;
  public mergeWos: boolean = false;
  public displayFields = ['projectId', 'branch_branchId', 'branchName', 'generalContractorName', 'customer_contactId', 'projectStatus_lookupValueId', 'startDate', 'endDate', 'vendorOrderNumber',
    'welcomeEmailSent', 'currentProjectStatusCode', 'warranty_warrantyId', 'providerMarginHigh', 'providerMarginLow'];
  public cbMerge: IInputButton = { label: { label: '', alignRight: true } };
  public customButtons: ICustomButton[] = [];
  public preferredContactLabel: string;
  public theHomeDepotId: number = 1;
  public loading$: Observable<boolean>;
  public errorData$: Observable<IErrorData[]>;
  public project$: BehaviorSubject<IProject> = new BehaviorSubject(null);
  public operation$: BehaviorSubject<string> = new BehaviorSubject(this.myConstants.operationTypeDetails);
  public hasStaleData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public flagColor$: BehaviorSubject<string> = new BehaviorSubject('');
  public reload$: BehaviorSubject<string> = new BehaviorSubject('');
  public dashboardIcon$: BehaviorSubject<string> = new BehaviorSubject('');
  public canDeleteProject$: Observable<boolean>;
  public canEditAddressAge$: Observable<boolean>;
  public canFlagCustomer$: Observable<boolean>;
  public canIEdit$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEditSansStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEditCbs$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public ready$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public poCount$: Observable<number>;
  public enableMerge$: Observable<boolean>;
  public canViewTasks$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public contact$: BehaviorSubject<IContact> = new BehaviorSubject(null);
  public branchProgramsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  flagStoreName: string = 'contactCustomerFlags';
  subscription = new Subscription();
  contactSub: Subscription;
  flagSub: Subscription;
  projectSub: Subscription;
  plSub: Subscription;
  bpSub: Subscription;
  cmFlag: Subscription;

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromFeature.IAllDynamicData>,
    public mds: MetaDataService,
    public modalService: ModalService,
    public projectService: ProjectService,
    public emitterService: HomEventEmitterService,
    public userPriviledgesService: UserPriviledgesService,
    public communicationEventService: CommunicationEventService,
    public contactUtilityService: ContactUtilityService,
    public errorHandlerService: HomErrorHandlerService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onMarginSet(event: IMarginResults): void {
    let dashIcon = event.aboveMargin ? ' fa-tachometer-alt-fast app-icon--success'
      : event.atMargin ? 'fa-tachometer-alt-average'
        : event.belowMargin ? ' fa-tachometer-alt-slow app-icon--danger'
          : 'fa-tachometer-alt-average';

    this.dashboardIcon$.next(dashIcon);
  }

  public onCustom(event: IHomEventEmitter): void {
    switch (event.event) {
      case ProjectEvent.addNote:
        this.addCustomerProjectNote();
        break;
      case ButtonType.reload:
        this.reloadPortal();
        break;
      default:
        break;
    }
  }

  public importGcPos(generalContractorId: number): void {
    let gc: IGeneralContractor = this.generalContractors.find(x => x.generalContractorId == generalContractorId);
    const title: string = 'Import New Purchase Order'.concat(gc ? ' from ' + gc.generalContractorName || '' : '');
    this.modalService.open({ title: title, path: 'po-import/' + generalContractorId.toString() + '/' + this.projectId.toString(), optionalParams: null, onExit: null, castExit: false, hasTabs: false, sizeType: ModalSizeType.xlarge });
  }

  public syncPurchaseOrders(): void {
    this.modalService.open({ title: 'Sync Purchase Orders', path: 'po-sync/' + this.projectId.toString(), data: this.canIEdit$.getValue(), castExit: true, hasTabs: false });
  }

  public generateWorkOrders(val: string, merge: boolean): void {
    this.modalService.open({ title: 'Generate Work Orders', path: 'wos-generate/' + this.projectId.toString() + '/' + val + '/' + JSON.stringify(merge), castExit: true, hasTabs: false });
  }

  public mergeWosEvent(evt): void {
    this.mergeWos = evt;
  }

  ngOnInit() {
    this.newRequest();
    this.subscription.add(this.emitterService.smsEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e.event === CommunicationEventEvent.openConversation) {
          this.showConvo(e.data);
        }
      }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['portalReload'] && !changes['portalReload'].isFirstChange()) {
      this.reloadPortal();
    }
    if (changes['projectId'] && !changes['projectId'].isFirstChange()) {
      this.newRequest();
    }
  }

  newRequest() {
    const myStores: string[] = [ProjectObjectStore.projectInformation, ProjectListStore.projectChargeBacks, ProjectListStore.projectWorkOrders, ProjectListStore.projectPurchaseOrders];
    this.loading$ = this.rootStore.select('loadingIndicator').pipe(
      filter(x => myStores.includes(x.requestor) && x.id === this.projectId),
      map(x => x.show));

    this.dashboardIcon$.next('fa-tachometer-alt-average');
    this.operation$.next(this.myConstants.operationTypeDetails);
    this.customButtons = [];
    this.customButtons.push({ title: 'Add Customer Note', icon: 'far fa-comment-plus', cssName: 'app-btn-icon--primary', enabled: true, eventName: ProjectEvent.addNote });

    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('generalContractor')))
      .subscribe((data) => {
        this.generalContractors = data;
      }));

    this.canFlagCustomer$ = this.rootStore.pipe(
      select(fromAuth.canIFlagCustomer)
    );

    this.canViewTasks$.next(this.userPriviledgesService.canIByComponentName(WidgetComponentName.ProjectTasksComponent));

    if (this.projectSub) {
      this.projectSub.unsubscribe();
    }
    //not take one. needs to be able to handle close event and set canIEdit correctly.
    this.projectSub = this.store.pipe(select(getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)))
      .subscribe((objData: IProject) => {
        let project = cloneDeep(objData);
        if (project && this.projectId === project.projectId) {
          this.project$.next(project);
          this.isWarranty = project.warranty_warrantyId && project.warranty_warrantyId > 0 ? true : false;
          this.canIEdit$.next(this.userPriviledgesService.canIEdit(project) && project.currentProjectStatusCode !== this.myConstants.projectStatusClosed);
          this.canIEditSansStatus$.next(this.userPriviledgesService.canIEdit(project));
          this.canIEditCbs$.next(this.userPriviledgesService.canIEdit(project) && this.userPriviledgesService.manageWarrantyProjects$.value);
          //cb canAddItems comes into play on the charge-back manager, this is just for create cbs
          //this.canViewPoBalance = this.userPriviledgesService.canIEdit(project);
          this.ready$.next(true);
        }
      });

    this.subscription.add(this.project$.subscribe((val: IProject) => {
      if (val) {
        if (!val.branch_branchId) {
          //log
          this.errorHandlerService.handleError({
            name: 'DataError',
            message: 'Project with id: '.concat(val && val.projectId ? val.projectId.toString() : ' null value ', ' changed with missing data in branch')
          }, HomErrorLevel.fatal);
          //  message: 'Project with id: '.concat(val.projectId.toString(), ' changed with missing data in branch of: ', val.branch_branchId ? val.branch_branchId.toString() : ' NULL')
        } else {
          this.dispatchProviderLocations(val.branch_branchId);
          this.getBranchPrograms(val.branch_branchId);
          if (!this.contactId || val.customer_contactId !== this.contactId) {
            this.contactId = val.customer_contactId;
            this.getContact();
          }
        }
      }
    }));

    this.subscription.add(this.contact$.subscribe((val: IContact) => {
      if (val) {
        this.setCommunicationPreferenceLabel();
        this.listenForContactFlagChanges();
        }
    }));

    //special to watch for event
    this.subscription.add(this.store.pipe(select(getObjectDataByType(ProjectObjectStore.projectInformation)))
      .subscribe((store: IDynamicObject) => {
        const objData = cloneDeep(store.objData.find(x => x.objectId == this.projectId));
        if (objData) {
          const staleData = !objData.data ? false : objData.staleData;
          this.hasStaleData$.next(staleData);
          if (objData.data) {
            //Listen for change to contact within this project
            const currentContactId: number = this.project$.value ? this.project$.value.customer_contactId : 0;
            const project: IProject = cloneDeep(objData.data);
            if (currentContactId !== project.customer_contactId) {
              this.project$.next(project);
            }
          }
          if (objData.event) {
            this.store.dispatch(new DynamicObjectActions.ClearEventObj({ storeName: ProjectObjectStore.projectInformation, objectId: this.projectId }));
            this.operation$.next(this.myConstants.operationTypeDetails);
          }
        }
      }
      ));

    this.errorData$ = this.store.pipe(
      select(getObjectErrorsById(ProjectObjectStore.projectInformation, this.projectId)),
      tap(errorData => {
        //set fieldlevel errors
        this.mds.setErrorMessages(errorData);
      })
    );

    let retrieved: boolean = false;
    this.subscription.add(this.ready$.subscribe((val) => {
      if (val && !retrieved) {
        retrieved = true;
        this.getComponentData();
      }
    }));

    this.poCount$ = this.store.pipe(select(getListByType(ProjectListStore.projectPurchaseOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map((objData) => {
          return objData && objData.data ? objData.data.length : 0
        })
      );

    this.enableMerge$ = this.store.pipe(select(getListByType(ProjectListStore.projectWorkOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)),
        map((objData) => {
          return objData && objData.data ? this.enableMerge(objData.data) : false
        })
      );

  }

  reloadPortal(): void {
    //reload all
    this.getProject();
    this.dispatchPoGet();
    this.dispatchWoGet();
    this.dispatchChargeBackGet();
    this.dispatchPoItemGet();
    this.dispatchWoItemGet();
    this.dispatchUnrcvInvGet();
    this.dispatchChargeBackItemGet();
    this.dispatchNoteGet();
    this.dispatchAQTChangesGet();
    this.projectService.dispatchRequiredDocumentsGet(this.projectId, this.canIEditSansStatus$.value);
    const data: IHomDictionary = new HomDictionary('projectId', this.projectId);
    let emitter: IHomEventEmitter = {
      requestor: 'project-high-level-summary', event: this.myConstants.emitterEventListReload, action: '', data: data
    };
    this.emitterService.emitTaskEvent(emitter);
    //push out request to child components who listen for reload
    this.reload$.next(new Date().toTimeString());
  }

    //get branch programs for this branch, used in po summary and create po
  getBranchPrograms(branchId): void {
   const listFilter: IListFilter = {
    isLookup: false,
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true, //do not want paged data
    currentPage: 1,
    searchTerm: [],
    orderTerm: null
    };

   this.subscription.add(this.rootStore
      .pipe(select(getSelectionListDataByType('branchProgram', branchId)), take(1))
      .subscribe((data: IBranchProgram[]) => {
        if (!data) {
          this.branchProgramsLoaded$.next(false);
          this.rootStore.dispatch(new SelectionListActions
            .GetEntityListById('BranchProgram', 'ByBranch', branchId, listFilter, 'branchProgram'));
        }
      }));

    this.listenForBranchPrograms(branchId);
  }

  enableMerge(data: IWorkOrder[]): boolean {
    let i: number = 0,
      status: string;
    const len: number = data.length;
    for (; i < len; i++) {
      status = data[i].workOrderStatusText;
      if (status !== 'Work Complete' && status !== 'Accounting Error') return true;
    }
    return false;
  }

  getContact(): void {
    if (this.contactSub) {
      this.contactSub.unsubscribe();
    }
    this.contactSub = this.store.pipe(select(getObjectDataById(ContactStore.contactInformation, this.contactId)))
      .subscribe((data: IContact) => {
        if (!data && !this.contact$.value) {
          //get the contact object
          this.contactUtilityService.dispatchGetContact(this.contactId);
        } else {
          this.contact$.next(data);
        }
      });

  }

  setCommunicationPreferenceLabel(): void {
    if (this.cmFlag) {
      this.cmFlag.unsubscribe();
    }
    this.cmFlag = this.store.pipe(
      select(getMetaDataByType(ContactStore.contactInformation)),
      filter((data: IMetaDataState) => data.fieldDefinitions.length > 0))
      .subscribe((data: IMetaDataState) => {
        if (data) {
          const lblDef: IFieldDefinition = data.fieldDefinitions.find(x => x.key == 'communicationPreferenceValue');
          this.preferredContactLabel = lblDef ? lblDef.label : 'Contact Method';
        }
      });
  }

  listenForContactFlagChanges(): void {
    //watch for flag settings so can set color of customer name appropriately
    if (this.flagSub) {
      this.flagSub.unsubscribe();
    }
    this.flagSub = this.store.pipe(select(getListByType(this.flagStoreName)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.contactId)))
      .subscribe((state: IListObjectData) => {
        const listData = cloneDeep(state);
        if (listData) {
          const recs: IContactCustomerFlag[] = listData.data;
          const recList: IContactCustomerFlag[] = orderBy(recs, ['createDate'], ['desc']);
          this.flagColor$.next(recList && recList.length ? recList[0].customerFlagColor : '');
        }
      });
  }

  getComponentData() {
    const canIEdit = this.canIEdit$.getValue();
    const poListDef: IListDefinition = this.projectService.loadProjectPurchaseOrderListDefinition(this.projectId, canIEdit, false, true);
    const woListDef: IListDefinition = this.projectService.loadProjectWorkOrderListDefinition(this.projectId, canIEdit, false, true);
    const chargeBackListDef: IListDefinition = this.projectService.loadProjectChargeBackListDefinition(this.projectId, this.canIEditCbs$.value, true);

    const poItemListDef: IListDefinition = this.projectService.loadProjectPurchaseOrderItemListDefinition(this.projectId, canIEdit);
    const woItemListDef: IListDefinition = this.projectService.loadProjectWorkOrderItemListDefinition(this.projectId, canIEdit);
    const chargeBackItemListDef: IListDefinition = this.projectService.loadProjectChargeBackItemListDefinition(this.projectId, this.canIEditCbs$.value);
    const noteListDef: IListDefinition = this.projectService.loadProjectNoteListDefinition(this.projectId);

    let dataSub: Subscription = new Subscription();

    let componentData$: BehaviorSubject<{ store: string, complete: boolean, methodName: string, listDef?: IListDefinition }[]> = new BehaviorSubject([
      { store: ProjectListStore.projectPurchaseOrders, listDef: poListDef, complete: false, methodName: 'dispatchPoGet' },
      { store: ProjectListStore.projectWorkOrders, listDef: woListDef, complete: false, methodName: 'dispatchWoGet' },
      { store: ProjectListStore.projectChargeBacks, listDef: chargeBackListDef, complete: false, methodName: 'dispatchChargeBackGet' },
      { store: ProjectListStore.projectPurchaseOrderItems, listDef: poItemListDef, complete: false, methodName: 'dispatchPoItemGet' },
      { store: ProjectListStore.projectWorkOrderItems, listDef: woItemListDef, complete: false, methodName: 'dispatchWoItemGet' },
      { store: ProjectListStore.projectChargeBackItems, listDef: chargeBackItemListDef, complete: false, methodName: 'dispatchChargeBackItemGet' },
      { store: ProjectListStore.projectNotes, listDef: noteListDef, complete: false, methodName: 'dispatchNoteGet' }
    ]);

    let componentData = componentData$.value;

    componentData.forEach(x => {
      dataSub.add(this.store.select(listDataExists(x.store, this.projectId)).subscribe((exists) => {
        if (exists) {
          x.complete = true;
          componentData$.next(componentData);
        } else {
          this.store.dispatch(new DynamicListActions.SetListDefinition({ storeName: x.store, parentId: this.projectId, listDefinition: x.listDef }));
          this[x.methodName]();
        }
      }));
    });

    componentData$.pipe(filter(data => !data.find(x => !x.complete)), take(1)).subscribe((data) => {
      dataSub.unsubscribe();
    });

    //always get latest AQT data
    this.dispatchAQTChangesGet();
    
  }

  //parents
  dispatchPoGet(): void {
    const poListDef: IListDefinition = this.projectService.loadProjectPurchaseOrderListDefinition(this.projectId, this.canIEdit$.getValue());
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: poListDef, listFilter: poListDef.defaultListFilter, parentId: poListDef.parentId }));
  }
  dispatchWoGet(): void {
    const woListDef: IListDefinition = this.projectService.loadProjectWorkOrderListDefinition(this.projectId, this.canIEdit$.getValue(), false, true);
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: woListDef, listFilter: woListDef.defaultListFilter, parentId: woListDef.parentId }));
  }
  dispatchChargeBackGet(): void {
    const chargeBackListDef: IListDefinition = this.projectService.loadProjectChargeBackListDefinition(this.projectId, this.canIEditCbs$.getValue());
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: chargeBackListDef, listFilter: chargeBackListDef.defaultListFilter, parentId: chargeBackListDef.parentId }));
  }
  //items
  dispatchPoItemGet(): void {
    const poItemListDef: IListDefinition = this.projectService.loadProjectPurchaseOrderItemListDefinition(this.projectId, this.canIEdit$.getValue());
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: poItemListDef, listFilter: poItemListDef.defaultListFilter, parentId: poItemListDef.parentId }));
  }
  dispatchUnrcvInvGet(): void {
    const listDef: IListDefinition = this.projectService.loadProjectUnreceivedInventoryListDefinition(this.projectId, this.canIEdit$.getValue());
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: listDef, listFilter: listDef.defaultListFilter, parentId: listDef.parentId }));
  }
  dispatchWoItemGet(): void {
    const woItemListDef: IListDefinition = this.projectService.loadProjectWorkOrderItemListDefinition(this.projectId, this.canIEdit$.getValue());
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: woItemListDef, listFilter: woItemListDef.defaultListFilter, parentId: woItemListDef.parentId }));
  }
  dispatchChargeBackItemGet(): void {
    const chargeBackItemListDef: IListDefinition = this.projectService.loadProjectChargeBackItemListDefinition(this.projectId, this.canIEditCbs$.getValue());
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: chargeBackItemListDef, listFilter: chargeBackItemListDef.defaultListFilter, parentId: chargeBackItemListDef.parentId }));
  }
  //notes
  dispatchNoteGet(): void {
    const noteListDef: IListDefinition = this.projectService.loadProjectNoteListDefinition(this.projectId);
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: noteListDef, listFilter: noteListDef.defaultListFilter, parentId: noteListDef.parentId }));
  }
  dispatchAQTChangesGet(): void {
    const aqtListDef: IListDefinition = this.projectService.loadAQTChangesListDefinition(this.projectId);
    this.store.dispatch(new DynamicListActions.SetListDefinition({ storeName: aqtListDef.storeName, parentId: aqtListDef.parentId, listDefinition: aqtListDef }));
    this.store.dispatch(new DynamicListActions.GetList({ listDefinition: aqtListDef, listFilter: aqtListDef.defaultListFilter, parentId: aqtListDef.parentId }));
  }

  //provider locations
  dispatchProviderLocations(branchId: number): void {
    let listFilter = new ListFilter();
    listFilter.filterContext = 'WorkOrder';
    listFilter.filterFor = 'ProviderLocation'; //I should only see the provider locations I have acess to for this project
    listFilter.getAll = true;
    this.providerLocations = null;

    if (this.plSub) {
      this.plSub.unsubscribe();
    }
    this.plSub = this.rootStore.pipe(select(getSelectionListDataByType('projectProviderLocation', branchId)))
      .subscribe((data) => {
        if (data) {
          this.providerLocations = data;
        } else {
          this.store.dispatch(new SelectionListActions.GetEntityListById('ProviderLocation', 'ByProjectBranch', branchId, listFilter, 'projectProviderLocation'));
        }
      });
 }

  addCustomerProjectNote(): void {
    const data = { projectId: this.projectId };
    this.modalService.open({
      title: 'Project Customer Notes',
      path: 'contact-notes/' + this.contactId.toString() + '/' + this.myConstants.routeActionAdd,
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      state: data
    });
  }

  getProject(): void {
    this.projectService.dispatchProjectObject(this.projectId, this.displayFields);
  }

  listenForBranchPrograms(branchId: number): void {
    if (this.bpSub) {
      this.bpSub.unsubscribe();
    }
    // LISTEN FOR BRANCH PROGRAM DATA --only triggered when select a branch
    this.bpSub = this.rootStore.pipe(select(getSelectionListDataByType('branchProgram', branchId)))
      .subscribe((data: IBranchProgram[]) => {
        if (data) {
          this.branchProgramsLoaded$.next(true);
        }
      });
  }

  showConvo(request: IConversationRequest): void {
    this.router.navigate([{ outlets: { chatbar: ['sms-convo', request.contactId.toString(), request.phone, request.name, request.parentName] } }], {
      relativeTo: this.activeRoute
    }).catch(e => {
      console.log('Route not found, route stopped with no error raised', request);
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    //Since contact id can change, have specific subscriptions to maintain them individually
    if (this.projectSub) {
      this.projectSub.unsubscribe();
    }
    if (this.contactSub) {
      this.contactSub.unsubscribe();
    }
    if (this.flagSub) {
      this.flagSub.unsubscribe();
    }
    if (this.cmFlag) {
      this.cmFlag.unsubscribe();
    }
    if (this.plSub) {
      this.plSub.unsubscribe();
    }
    if (this.bpSub) {
      this.bpSub.unsubscribe();
    }
    if (this.router.url.includes('chatbar:')) {
      this.router.navigate([{ outlets: { chatbar: null } }],
        { relativeTo: this.activeRoute.parent });
    }

  }
}
