import { IPortalTab } from "../../../view-models";

const managerPortalName: string = 'user-notification-tabs';
export let UserNotificationsTabs: IPortalTab[] = [
  {
    portalLevel: 1,
    portalTabContainerId: -1,
    portalTabId: 0,
    managerPortalName: managerPortalName,
    managerPortalId: -1,
    tabLabel: 'My Received Notifications',
    tabIcon: '',
    isDefault: true,
    isDynamic: false,
    routeName: 'rcvd-notifications/rcvd',
    securityDependent: false,
    componentName: '',
    entityName: '',
    tabOrder: 1,
    entityId: 0
  },
  {
    portalLevel: 1,
    portalTabContainerId: -1,
    portalTabId: 1,
    managerPortalName: managerPortalName,
    managerPortalId: -1,
    tabLabel: 'My Sent Notifications',
    tabIcon: '',
    isDefault: false,
    isDynamic: false,
    routeName: 'sent-notifications/sent',
    securityDependent: false,
    componentName: '',
    entityName: '',
    tabOrder: 2,
    entityId: 0
  }
]
