export interface IListColumn {
    key: string;
    visibleOnSmall: boolean;
    visibleOnMedium: boolean;
    visibleOnLarge: boolean;
    fieldDefinition: any;
    slowSort: boolean;
    slowFilter: boolean;
    onlyOnSmall: boolean;
}

export class ListColumn implements IListColumn {
  key: string;
  visibleOnSmall: boolean;
  visibleOnMedium: boolean;
  visibleOnLarge: boolean;
  fieldDefinition: any;
  slowSort: boolean;
  slowFilter: boolean;
  onlyOnSmall: boolean;

  constructor(key: string) {
    this.key = key;
    this.visibleOnSmall = true;
    this.visibleOnMedium = true;
    this.visibleOnLarge = true;
    this.fieldDefinition = {};
    this.slowSort = false;
    this.slowFilter = false;
    this.onlyOnSmall = false;
  }
}
