import { Component, OnInit, Input, Inject} from '@angular/core';
import {  IWorkOrderViewModel } from '../../../../app/portals/view-models';
import { appConstants, IAppConstants } from '../../../../shared/constants';
import { MetaDataService } from '../../services';

@Component({
  selector: 'fw-work-order-info',
  templateUrl: './work-order-info.component.html',
  providers: [MetaDataService]
})
export class WorkOrderInfoComponent implements OnInit {
  @Input() row: IWorkOrderViewModel;
  @Input() storeName: string;

  public displayFields: string[] = ['serviceName', 'capacityUnits', 'workOrderCity', 'installerName', 'workCrewName', 'branchName', 'workOrderStatus', 'scheduleStartAndEndDate', 'customerName'];
  public title: string;
  
  constructor(public mds: MetaDataService,
   @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.title = 'Work Order '.concat(this.row.workOrderId.toString(), ' Info');
    this.mds.setFieldDefinitions(this.storeName);
  }
  
 }
