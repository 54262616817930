
export interface IProjectTaskAction {
  projectTaskActionId: number;
  projectTask_projectTaskId: number;
  taskAction_taskActionId: number;
  actionCompletedDate: string;
  actionSuccessful: boolean;
  actionMessage: string;
  actionAutoClosed: boolean;
  entityPkId: number;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;
}

export class ProjectTaskAction implements IProjectTaskAction {
  projectTaskActionId: number;
  projectTask_projectTaskId: number;
  taskAction_taskActionId: number;
  actionCompletedDate: string;
  actionSuccessful: boolean;
  actionMessage: string;
  actionAutoClosed: boolean;
  entityPkId: number;

  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  constructor() {
    this.projectTaskActionId = 0;
    this.projectTask_projectTaskId = null;
    this.taskAction_taskActionId = null;
    this.actionCompletedDate = '';
    this.actionSuccessful = false;
    this.actionMessage = '';
    this.actionAutoClosed = false;
    this.entityPkId = null;

    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';
  }
}
