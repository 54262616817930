<div class="sms-contact"
     title="Select Contact"
     [ngClass]="{'sms-contact--unresponded': contact.communicationEventTypeId === 12, 'sms-contact--selected': contact.contactId === activeId }"
     (click)="selectItem()">
  <button type="button"
          class="app-btn-icon sms-contact__sub "
          [ngClass]="{'app-btn-icon--success': contact.activeSubscription, 'app-btn-icon--danger': !contact.activeSubscription}"
          [disabled]="mgrFiltered"
          [title]="(contact.activeSubscription ? 'Unsubscribe' : 'Re-Subscribe') + ' to this conversation'"
          (click)="changSub($event)">
    <i *ngIf="contact.activeSubscription" class="fad fa-bell fa-swap-opacity"></i>
    <i *ngIf="!contact.activeSubscription" class="fad fa-bell-slash fa-swap-opacity"></i>
  </button>
  <div class="sms-contact__icon" [ngClass]="{'sms-contact__icon--installer': contact.contactTypeId === 4, 'sms-contact__icon--customer': contact.contactTypeId === 1}">
    <i *ngIf="contact.contactTypeId === 1" class="fad fa-user"></i>
    <i *ngIf="contact.contactTypeId === 4" class="fad fa-truck"></i>
  </div>
  <div *ngIf="contact.installerName" class="sms-contact__parent">
    {{ contact.installerName }}
  </div>
  <div class="sms-contact__name">
    {{ contact.firstName }} {{ contact.lastName}}
  </div>
  <div class="sms-contact__phone">{{ contact.phoneNumber | homphone }}</div>
  <div class="sms-contact__alerts">
    <fw-customer-alert [row]="contact"></fw-customer-alert>
  </div>
  <div class="sms-contact__timestamp">{{ contact.lastActivityOn | date:'MM/dd/yyyy h:mm a'}}</div>
</div>

