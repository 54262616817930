import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { HomPhonePipe } from 'hom-lib/hom-pipes';

import { IAppConstants, appConstants } from '../../../../shared/constants';

import * as fromRoot from '../../../store/reducers/index';
import * as fromStore from '../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getSelectionListDataByType } from '../../../../shared/store/selectionLists';
import { ICommunicationPreference, IContactMechanismPhone, IContactMechanismEmail, IContactCommunicationPreference, ContactCommunicationPreference, IContact } from '../../../portals/view-models';
import { CommunicationPreference, ContactStore } from '../../enums/contact.enums';
import { IErrorData, IResponseBase } from '../../../../shared/interfaces';
import { getListByType, IDynamicListState, IListObjectData, getObjectDataById } from '../../../../fw/dynamic-list/store';

import { ModalService } from '../../../../fw/fw-modal/services/fw-modal.service';
import { ContactConstantsService, ContactUtilityService } from '../../services';
import { DomainObjectService } from '../../../../shared/services';

interface IMechanism {
  id: number;
  label: string;
}
@Component({
  selector: 'contact-communication-preference',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './contact-communication-preference.component.html'
})
export class ContactCommunicationPreferenceComponent implements OnInit, OnDestroy {
  public types: ICommunicationPreference[] = [];
  public phoneMechanisms: IContactMechanismPhone[] = [];
  public emailMechanisms: IContactMechanismEmail[] = [];
  public mechanisms$: BehaviorSubject<IMechanism[]> = new BehaviorSubject(null);
  public object$: BehaviorSubject<IContactCommunicationPreference> = new BehaviorSubject(null);
  public ready$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public errors$: BehaviorSubject<string> = new BehaviorSubject('');
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public hoverText$: BehaviorSubject<string> = new BehaviorSubject('');
  contactId: number;
  contact: IContact;
  selectedType: ICommunicationPreference;
  selectedMechanismId: number = 0;
  objectId: number = 0;
  subscription = new Subscription();

  constructor(
    public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public phonePipe: HomPhonePipe,
    public modalService: ModalService,
    public contactConstantsService: ContactConstantsService,
    public contactUtilityService: ContactUtilityService,
    public domainObjectService: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.contactId = +paramMap.get('id');
      this.objectId = +paramMap.get('cpId');
      this.getContact();
      this.loadStaticSelectionLists();
      this.loadSelectionListsByContact();
      this.getDetail();
    });
  }

  public changeType(preferenceId: number): void {
    let items: IMechanism[] = [];
    this.selectedType = preferenceId === 0 ? null : this.types.find(x => x.communicationPreferenceId == preferenceId);
    this.selectedMechanismId = 0;
    if (!this.selectedType) {
      this.mechanisms$.next([]);
      this.isValid$.next(this.getIsValid());
     return;
    }

    switch (this.selectedType.communicationPreferenceName) {
      case CommunicationPreference.sms:
      case CommunicationPreference.phone:
        this.phoneMechanisms.forEach((phone: IContactMechanismPhone) => {
          if ((this.selectedType.communicationPreferenceName === CommunicationPreference.sms  && phone.canSms === true)
            || this.selectedType.communicationPreferenceName === CommunicationPreference.phone) {
            const label: string = this.phonePipe.transform(phone.phoneNumber, '').concat(!phone.phoneExtension ? '' : ' x'.concat(phone.phoneExtension));
            items.push({
              id: phone.contactMechanism_contactMechanismId,
              label: label
            });
          }
        });
        this.mechanisms$.next(items);
        break;
      case CommunicationPreference.email:
        this.emailMechanisms.forEach((email: IContactMechanismEmail) => {
          items.push({
            id: email.contactMechanism_contactMechanismId,
            label: email.emailAddress,
          });
        });
        this.mechanisms$.next(items);
        break;
      default:
        this.mechanisms$.next(null);
        break;
    }
    this.setHoverText();
    this.isValid$.next(this.getIsValid());
  }

  public changeMechanism(id: number): void {
    this.selectedMechanismId = id;
    this.isValid$.next(this.getIsValid());
  }

  public cancel(): void {
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  public save(): void {
    if (this.objectId === 0) {
      this.create();
    } else {
      this.update();
    }
}

  create(): void {
    this.working$.next(true);
    let model: IContactCommunicationPreference = cloneDeep(this.object$.value);
    model.communicationPreference_communicationPreferenceId = this.selectedType.communicationPreferenceId;
    model.contactMechanism_contactMechanismId = this.selectedMechanismId;
    const obj = { model: model, contactId: this.contactId }
    this.subscription.add(this.domainObjectService.createByMethod('ContactCommunicationPreference', 'Create', obj)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          this.requestReloadAndClose();
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));
  }

  update(): void {
    this.working$.next(true);
    let obj = this.setCommonProperties();
    this.subscription.add(this.domainObjectService.updateByMethod('ContactCommunicationPreference', 'Update', obj)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          this.requestReloadAndClose();
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));
  }

  requestReloadAndClose(): void {
    this.contactUtilityService.dispatchGetContact(this.contactId, true);
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  getContact() {
    this.subscription.add(this.store.pipe(select(getObjectDataById(ContactStore.contactInformation, this.contactId)), take(1))
      .subscribe((data: IContact) => {
        this.contact = cloneDeep(data);
      }));
  }

  getDetail(): void {
    if (!this.objectId || this.objectId === 0) {
      this.object$.next( new ContactCommunicationPreference() );
      return;
    }
    this.working$.next(true);
    this.subscription.add(this.domainObjectService.getByMethodById('ContactCommunicationPreference', 'Details', this.objectId)
      .subscribe((response: IResponseBase) => {
        let result = cloneDeep(response);
        if (result.success) {
          const pref: IContactCommunicationPreference = result.data;
          this.changeType(pref.communicationPreference_communicationPreferenceId);
          this.selectedMechanismId = pref.contactMechanism_contactMechanismId;
          this.object$.next(pref);
        } else {
          this.errorData$.next(result.errorData);
        }
        this.working$.next(false);
      },
        (error: any) => { this.errors$.next(error); }
      ));
    }

  loadStaticSelectionLists(): void {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('communicationPreference')))
      .subscribe((data: ICommunicationPreference[]) => {
        if (this.contact && this.contact.optOutSms) {
          //remove Texting from the options
          this.types = data.filter(x => x.communicationPreferenceName !== CommunicationPreference.sms);
        } else {
          this.types = data;
        }
        this.ready$.next(true);
      }));
  }

  loadSelectionListsByContact(): void {
    this.subscription.add(this.store.pipe(select(getListByType(ContactStore.phones)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.contactId)))
      .subscribe((objData: IListObjectData) => {
        this.phoneMechanisms = null;
        if (objData && objData.data) {
          this.phoneMechanisms = cloneDeep(objData.data);
        }
      }));
    this.subscription.add(this.store.pipe(select(getListByType(ContactStore.emails)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.contactId)))
      .subscribe((objData: IListObjectData) => {
        this.emailMechanisms = null;
        if (objData && objData.data) {
          this.emailMechanisms = cloneDeep(objData.data);
        }
      }));
  }

  setCommonProperties(): IContactCommunicationPreference {
    let pref: IContactCommunicationPreference = cloneDeep(this.object$.value);
    pref.communicationPreference_communicationPreferenceId = this.selectedType.communicationPreferenceId;
    pref.contactMechanism_contactMechanismId = this.selectedMechanismId;
    return pref;
  }

  getIsValid(): boolean {
    return this.selectedType !== null && this.selectedMechanismId > 0;
  }

  setHoverText(): void {
    this.hoverText$.next(this.mechanisms$.value === null
      ? 'This customer does not have any'.concat(this.selectedType.communicationPreferenceDescription, ' items')
      : 'Select a different '.concat(this.selectedType.communicationPreferenceDescription, ' item for this contact'));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
