import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'branch-notes',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './branch-notes.component.html'
})
export class BranchNotesComponent implements OnInit {
  public listDefinition: IListDefinition;
  branchId: number;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService  ) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.branchId = +paramMap.get('branchId');
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadBranchNotesListDefinition(this.branchId);
  }
}
