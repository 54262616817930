<div class="portal" [ngClass]="{ 'sms-project-summary__loading loading-mask': loading$ | async }">
  <fw-component-title title='Project Information'></fw-component-title>
  <div class="app-info sms-project-summary__link">
    Click to open project
    <span class="app-link" (click)="goToProject()">
      {{ projectId }}
    </span>
  </div>
  <ng-container *ngIf="(project$ | async) as project">
    <sms-project [project]="project" (customEvent)="onCustom($event)"></sms-project>

    <fw-component-title title='Purchase Order Summary'></fw-component-title>
    <purchase-orders-summary [projectId]="projectId" [canIEdit]="false"></purchase-orders-summary>

    <fw-component-title title='Work Order Summary'></fw-component-title>
    <work-orders-summary [projectId]="projectId" [canIEdit]="false"></work-orders-summary>

    <ng-container *ngIf="(project$ | async)?.warrantyId > 0">
      <fw-component-title title='Charge Back Summary'></fw-component-title>
      <charge-backs-summary [projectId]="projectId" [canIEdit]="false"></charge-backs-summary>
    </ng-container>
  </ng-container>
</div>
