//Placeholder object data
export interface IGenericObjectData {
  entityLabel: string;
  fk: {}; 
  metaData: any[];
}

export class GenericObjectData {

  constructor(
    public entityLabel = '',
    public metaData: any = [],
    public fk = null
  ) { }
}