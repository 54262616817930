/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IPurchaseOrder, IProject, IBranchProgram } from '../../../view-models/index';
import { IListDefinition, ListFilter } from '../../../../../fw/dynamic-list/interfaces/index';
import { IErrorData } from '../../../../../shared/interfaces/index';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { UpdateObjectByIdModel, IKey } from '../../../../../fw/dynamic-list/store/interfaces/index';

import { ProjectService } from '../../services/project.service';
import { UserPriviledgesService } from '../../../../../auth/services/index';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import * as fromSelectionLists from '../../../../../shared/store/selectionLists/index';
import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { IDynamicListState, IListObjectData } from '../../../../../fw/dynamic-list/store/reducers/dynamic-list.reducer';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';
import { getListByType } from '../../../../../fw/dynamic-list/store/selectors/dynamic-list.selectors';
import { getObjectDataById } from '../../../../../fw/dynamic-list/store/selectors/dynamic-object.selectors';
import { ProjectListStore, ProjectObjectStore } from '../../enums/project.enums';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists/index';


@Component({
  selector: 'purchase-order-manager',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './purchase-order-manager.component.html'
})
export class PurchaseOrderManagerComponent implements OnInit, OnDestroy {
  public purchaseOrderId: number;
  public projectId: number;
  public purchaseOrder$: BehaviorSubject<IPurchaseOrder> = new BehaviorSubject(null);
  public storeKeyName: string = 'purchaseOrderId';
  public printUrl: string;
  public storeName: string;
  public operation$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  public hasStaleData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEdit$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEditPo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canIEditProject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public branchPrograms$: BehaviorSubject<IBranchProgram[]> = new BehaviorSubject(null);
  public loading$: Observable<boolean>;
  branchId$: BehaviorSubject<number> = new BehaviorSubject(-1);
  subscription: Subscription = new Subscription();
  bpSub: Subscription;
  spSub: Subscription;
  retreivedSkuPrices: boolean = false;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromFeature.IAllDynamicData>,
    public rootStore: Store<fromRoot.IState>,
    public selListStore: Store<fromSelectionLists.IStoreState>,
    public ups: UserPriviledgesService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public projectService: ProjectService) { }

  public onCustom(event: IHomEventEmitter): void {
    const poListDef: IListDefinition = this.projectService.loadProjectPurchaseOrderListDefinition(this.projectId, this.canIEdit$.getValue(), false);
    switch (event.event) {
      case ButtonType.edit:
        this.operation$.next(this.myConstants.operationTypeEdit);
        break;
      case ButtonType.reload:
        this.store.dispatch(new DynamicListActions.GetList({ listDefinition: poListDef, listFilter: poListDef.defaultListFilter, parentId: poListDef.parentId }));
        break;
      case ButtonType.save:
        this.savePo(event);
        break;
      case ButtonType.cancel:
        this.store.dispatch(new DynamicListActions.ClearErrorsList({ storeName: poListDef.storeName, parentId: poListDef.parentId }));
        this.operation$.next(this.myConstants.operationTypeDetails);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.operation$ = new BehaviorSubject(this.myConstants.operationTypeDetails);
    this.hasStaleData$ = new BehaviorSubject(false);
    this.storeName = ProjectListStore.projectPurchaseOrders;
    this.retreivedSkuPrices = false;

    this.loading$ = this.rootStore.select('loadingIndicator').pipe(
      filter(x => x.requestor === this.storeName),
      map(x => x.show));

    this.activeRoute.parent.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get('portalEntityId');
      this.getProject();
    });

    this.activeRoute.paramMap.subscribe(paramMap => {
      this.purchaseOrderId = +paramMap.get('id');
      this.getData();
    });

    //multiple observable dependencies will determine editability
    this.subscription.add(this.canIEditProject$.subscribe(val => {
      this.canIEdit$.next(this.canIEditPo$.getValue() && val);
    }));

    this.subscription.add(this.canIEditPo$.subscribe(val => {
      this.canIEdit$.next(this.canIEditProject$.getValue() && val);
    }));

    this.subscription.add(this.purchaseOrder$.subscribe((val: IPurchaseOrder) => {
      if (val && !this.retreivedSkuPrices) {
        this.getSkuPrices(val.branchProgram_branchProgramId);
      }
    }));

}

  getData(): void {
    //For field level events && errors
    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.projectPurchaseOrders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == this.projectId)))
      .subscribe((state: IListObjectData) => {
        if (state && state.data) {
          this.errorData$.next(state.errorData);
          if (state.event) {
            this.operation$.next(this.myConstants.operationTypeDetails);
            this.store.dispatch(new DynamicListActions.ClearEventList({ storeName: ProjectListStore.projectPurchaseOrders, parentId: this.projectId }));
            this.projectService.emitTaskReloadEvent('purchase-order-manager', this.projectId);
          }
          this.hasStaleData$.next(state.staleData);
          if (!state.errorData || !state.errorData.length) {
            const purchaseOrder = state.data.find(x => x[this.storeKeyName] == this.purchaseOrderId);
            this.printUrl = this.ups.getPrintUrl(purchaseOrder);
            this.canIEditPo$.next(purchaseOrder && this.ups.canEditPurchaseOrder(purchaseOrder));
            this.purchaseOrder$.next(purchaseOrder);
          }
        }
      }));

    //listen for event changes to Purchase Order ITEM store and reload this purchase order if receive any
    this.subscription.add(this.store.pipe(select(getListByType(ProjectListStore.purchaseOrderItems)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId === this.purchaseOrderId)))
      .subscribe((state: IListObjectData) => {
        if (state) {
          if (state.event && !state.errorData.length) {
            //have an event and no errors, so update/create was successful, remit as close/complete and let parent container handle as needed 
            //get just this one purchase order and update the list of projectPurchaseOrders with it.
            this.projectService.dispatchPurchaseOrders('purchase-order-manager', this.projectId, this.canIEdit$.getValue());
          }
        }
      }));

    this.subscription.add(this.branchId$.subscribe((val: number) => {
      if (val > 0) {
        this.listenForBranchProgram(val);
      }
    }));
  }

  getProject(): void {
    this.subscription.add(this.store.pipe(
      select(getObjectDataById(ProjectObjectStore.projectInformation, this.projectId)))
      .subscribe((objData: IProject) => {
        if (objData) {
          this.canIEditProject$.next(this.ups.canIEdit(objData) && objData.currentProjectStatusCode !== this.myConstants.projectStatusClosed);
          this.branchId$.next(objData.branch_branchId);
        }
      })
    );
  }

  getSkuPrices(branchProgramId): void {
    this.subscription.add(this.rootStore
      .pipe(select(getSelectionListDataByType('skuPrice', branchProgramId)), take(1))
      .subscribe((data: IBranchProgram[]) => {
        if (!data) {
          this.retreivedSkuPrices = false;
          let listFilter = new ListFilter();
          listFilter.getAll = true;
         this.rootStore.dispatch(new fromSelectionLists
            .GetEntityListById('SkuPrice', 'BranchProgramLookup', branchProgramId, listFilter, 'skuPrice'));
        }
      }));
  }

  listenForBranchProgram(branchId: number) {
    if (this.bpSub) {
      this.bpSub.unsubscribe();
    }
    this.bpSub = this.selListStore.pipe(select(fromSelectionLists.getSelectionListDataByType('branchProgram', branchId)))
      .subscribe((data: IBranchProgram[]) => {
        if (data) {
          this.branchPrograms$.next(data);
        }
      });
  }

  savePo(event: IHomEventEmitter): void {
    let po = this.purchaseOrder$.getValue();
    const keyData: IKey = {
      storeName: ProjectListStore.projectPurchaseOrders,
      parentId: po.project_projectId,
      key: this.storeKeyName,
      id: po.purchaseOrderId
    }
    const emitter: IHomEventEmitter = {
      requestor: 'purchase-order-manager',
      event: this.myConstants.emitterEventUpdate, action: '',
      data: null
    };
    const updateData = new UpdateObjectByIdModel(keyData,
      'PurchaseOrder', 'Update', this.storeKeyName, po.purchaseOrderId,
      event.data, null, emitter);
    this.store.dispatch(new DynamicListActions.UpdateObjectByIdList({ updateData: updateData }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.bpSub) {
      this.bpSub.unsubscribe();
    }
    if (this.spSub) {
      this.spSub.unsubscribe();
    }
}
}
