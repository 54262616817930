/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input,  Output, EventEmitter, Inject } from '@angular/core';
import { Subscription,  BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IContactInfoViewModel, IContactMatch, ISeedContactInformation } from '../../../portals/view-models';
import { IAppConstants, appConstants } from '../../../../shared/constants';
import { IResponseBase, IErrorData } from '../../../../shared/interfaces';
import { ContactEventAction, ContactEvent } from '../../../contact/enums/contact.enums';
import { DomainObjectService } from '../../../../shared/services';
import { ContactConstantsService } from '../../../contact/services';

@Component({
  selector: 'contact-matches',
  templateUrl: './contact-matches.component.html',
})
export class ContactMatchesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchData: IContactInfoViewModel;
  @Input() currentContactId: number;
  @Input() isPoImport: boolean = false;
  @Input() isContactValid: boolean = false;

  @Output() public matchEvent = new EventEmitter<IHomEventEmitter>();

  public matchData$: BehaviorSubject<IContactMatch[]> = new BehaviorSubject([]);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loaded: boolean = false;
  public hasExactMatch: boolean = false;
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);;
  public selectedIndex: number = -1;

  subscription: Subscription;

  constructor(public domainObjectService: DomainObjectService,
    public contactConstants: ContactConstantsService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public selectRow(i: number): void {
    if (i === 1776 && this.matchData$.value.length > 1) {
      if (!confirm('Multiple matches were found.  Are you sure you want to create a new contact card?')) {
        return;
      }
    }
    this.selectedIndex = i;
    this.sendSelected();
  }

  ngOnInit() {
    if (this.searchData) {
      this.initNewRequest();
    }
  }

  initNewRequest(): void {
    this.loading$.next(true);
    this.loaded = false;
    this.matchData$.next(null);
    this.selectedIndex = -1;
    this.errorData$.next([]);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.domainObjectService.updateByMethod('IndexedContact', 'Search', this.searchData)
      .subscribe((response: IResponseBase) => {
        if (response.success) {
          this.loaded = true;
          const data: IContactMatch[] = response.data.length > 0 ? response.data : null;
          if (this.isPoImport && !data) {
            this.sendSelected();
          }
          this.hasExactMatch = data === null ? false : data.find(x => x.index === 1) ? true : false;
          this.matchData$.next(data);
        } else {
          this.errorData$.next(response.errorData);
        }
        this.loading$.next(false);
      });
  }

  sendSelected(): void {
    const seedData: ISeedContactInformation = this.selectedIndex === 1776 || this.selectedIndex === -1 ? null : this.loadSeedData();
    const action: string = this.selectedIndex === 1776 || this.selectedIndex === -1
      ? ContactEventAction.createNewContact
      : this.isPoImport ? ContactEventAction.mergeWithExisting
                        : ContactEventAction.linkWithExisting;
    let event: IHomEventEmitter = { requestor: 'contact-matches', event: ContactEvent.selectMatch, action: action, data: seedData };
    this.matchEvent.emit(event);
  }

  //For Po Import and Contact Unlink and Link to new Match
  loadSeedData(): ISeedContactInformation {
    const selectedItem: IContactMatch = this.matchData$.getValue()[this.selectedIndex];
    //return in format of IContactInfoViewModel
    return {
      contactId: selectedItem.contactId,
      birthDate: selectedItem.birthDate,
      firstName: selectedItem.firstName,
      middleName: selectedItem.middleName,
      lastName: selectedItem.lastName,
      sssOptOut: selectedItem.sssOptOut,
      contactMechanismAddresses: this.formatAddresses(selectedItem),
      contactMechanismEmails: this.formatEmails(selectedItem),
      contactMechanismPhones: this.formatPhones(selectedItem),
      optOutSms: selectedItem.optOutSms
    }
  }

  formatAddresses(selectedItem: IContactMatch): any {
    let addresses: any[] = [];
    selectedItem.contactMechanismAddresses.forEach(addr => {
      let formatted = {
        addressTypeId: addr.addressTypeId,
        isPrimary: addr.isPrimary,
        address: {
          addressId: addr.addressId,
          line1: addr.line1,
          line2: addr.line2,
          line3: addr.line3,
          city: addr.city,
          zipcode5: addr.zipcode5,
          zipcode4: addr.zipcode4,
          sanitizeOverride: addr.sanitizeOverride,
          yearBuilt: addr.yearBuilt,
          latitude: addr.latitude,
          longitude: addr.longitude,
          state: { stateId: addr.state.stateId, stateAbbr: addr.state.stateAbbr, stateName: addr.state.stateName }
        }
      };
      addresses.push(formatted);
    });
    return addresses;
  }


  formatPhones(selectedItem: IContactMatch): any {
    let phones: any[] = [];
    selectedItem.contactMechanismPhones.forEach(phone => {
      let formatted = {
        phoneTypeId: phone.phoneTypeId,
        isPreferred: phone.isPreferred,
        phone: {
          phoneNumber: phone.phoneNumber,
          phoneId: phone.phoneId
        }
      };
      phones.push(formatted);
    });
    return phones;
  }

  formatEmails(selectedItem: IContactMatch): any {
    let emails: any[] = [];
    selectedItem.contactMechanismEmails.forEach(email => {
      let formatted = {
        emailTypeId: email.emailTypeId,
        isPreferred: email.isPreferred,
        email: {
          emailAddress: email.emailAddress,
          emailId: email.emailId
        }
      };
      emails.push(formatted);
    });
    return emails;
  }

  runMatches(): void {
    let event: IHomEventEmitter = { requestor: 'contact-matches', event: ContactEvent.refreshMatchData, action: '', data: null };
    this.matchEvent.emit(event);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchData'] && !changes['searchData'].firstChange) {
      this.initNewRequest();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
