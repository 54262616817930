import { Injectable} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, map, filter , first } from 'rxjs/operators';

import { IListFilter, OrderTerm } from '../../../fw/dynamic-list/interfaces/index';
import { IRegion } from '../view-models/i-region';

//store actions and reducers
import * as fromStore from '../../../fw/dynamic-list/store/index';
import * as fromSelectionLists from '../../../shared/store/selectionLists/index';
import { AccessLevel } from '../../../fw/dynamic-list/enums/access-level.enums';

@Injectable()
export class RegionResolver  {

  listFilter: IListFilter = {
    isLookup: true,
    getCount: false,
    filterFor: '',
    filterContext: '',
    accessLevel: AccessLevel.ReadOnly,
    getAll: true, //do not want paged data
    currentPage: 1,
    searchTerm: [],
    orderTerm: [new OrderTerm('regionName')],
  };

  constructor(public selectionListStore: Store<fromSelectionLists.IStoreState>,
    public store: Store<fromStore.IAllDynamicData>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IRegion[]> {
    const generalContractorId = +route.paramMap.get('generalContractorId') || 1;
    this.requestData(generalContractorId);
    return this.waitForDataToLoad(generalContractorId);
  }

  waitForDataToLoad(generalContractorId: number): Observable<IRegion[]> {
    return this.selectionListStore.select(fromSelectionLists.getSelectionListDataByType('region', generalContractorId))
      .pipe(
        filter((list: IRegion[]) => list !== null ),
        map((list: IRegion[]) => { return list; }),
        first()
      );
  }

  requestData(generalContractorId: number): void {
    this.selectionListStore.select(fromSelectionLists.getSelectionListDataByType('region', generalContractorId))
      .pipe(take(1))
      .subscribe((data: any[]) => {
        if (!data || data.length === 0) {
          this.store.dispatch(new fromSelectionLists.GetEntityListById('Region', 'ByGeneralContractor', generalContractorId, this.listFilter));
        }
      });
  }
}
