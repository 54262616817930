import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomSignatureManagerComponent } from 'hom-lib/hom-signature';

import { DocumentUploadManagerComponent, LswpDocumentMangagerComponent } from './containers';
import { EnumResolver } from '../resolvers';

//Routes from shared components
const portalRoutes: Routes = [
  {
    path: 'doc-upload-mgr/:id/:parentId/:actionId/:type',
    outlet: 'modal',
    component: DocumentUploadManagerComponent
  },
  {
    path: 'doc-upload-mgr/:id/:parentId/:actionId/:type/:sched',
    outlet: 'modal2',
    component: DocumentUploadManagerComponent
  },
  {
    path: 'lswp-mgr/:id/:actionId/:projectId',
    outlet: 'modal',
    data: {
      storeName: 'homeDepotLswpOverrideReasonCode',
      entityName: 'HomeDepotLswpOverrideReasonCode'
    },
    resolve: {
      hasCodes: EnumResolver
    },
    component: LswpDocumentMangagerComponent,
    children: [
      {
        path: 'sign-doc/:id/:actionId/:type',
        outlet: 'lswpOutlet',
        component: HomSignatureManagerComponent
      }
    ]
  },
  {
    path: 'sign-doc/:id/:actionId/:type/:crmForm/:crmFormQuestionId',
    outlet: 'modal',
    component: HomSignatureManagerComponent
  },
  {
    path: 'sign-doc/:id/:actionId/:type/:crmForm/:crmFormQuestionId',
    outlet: 'modal2',
    component: HomSignatureManagerComponent
  }

];

@NgModule({
  imports: [
    RouterModule.forChild(portalRoutes)
  ],
  exports: [RouterModule]
})
export class PortalSharedRoutingModule {
}
