import { Component, OnInit, OnChanges, SimpleChanges, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { WorkOrder, IService, IProviderLocation } from '../../../view-models/index';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services/index'
import { IFieldDefinition } from '../../../../../fw/dynamic-forms';
import { IListFilter } from '../../../../../fw/dynamic-list/interfaces';

@Component({
  selector: 'project-work-order-create',
  templateUrl: './project-work-order-create.component.html',
  providers: [MetaDataService]
})
export class ProjectWorkOrderCreateComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() storeName: string;
  @Input() displayFields: string[];
  @Input() requestTime: string;
  @Input() providerLocations: IProviderLocation[]
  @Input() services: IService[];

  @Output() public locationChangeEvent = new EventEmitter<{ id: number, listFilter: IListFilter }>();
  @Output() public createEvent = new EventEmitter<IHomEventEmitter>();
  @Output() public cancelEvent = new EventEmitter();

  public operation: string;
  public createForm: FormGroup;
  public availableServices$: BehaviorSubject<IService[]> = new BehaviorSubject([]);
  subscription: Subscription = new Subscription();

  constructor(public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit(): void {
    this.operation = this.myConstants.operationTypeCreate;
    this.initForm();

    this.subscription.add(this.mds.valueChanged$
      .subscribe((obj: IValueChanged) => {
        if (obj && obj.key === 'providerLocation') {
          const data: IProviderLocation = obj.value ? obj.value as IProviderLocation : null;
          this.locationSelected(data ? data.providerLocationId : 0);
        }
      }));
  }

  public initForm(): void {
    let wo = new WorkOrder();
    this.mds.setFieldDefinitions(this.storeName);
    this.mds.loadSelectionLists(this.displayFields, this.operation, this.projectId);
    if (this.providerLocations.length === 1) {
      //default provider location to first one
      wo.providerLocation_providerLocationId = this.providerLocations[0]['providerLocationId'];
    }
    this.createForm = this.mds.loadDynamicFormGroup(this.displayFields, wo, this.myConstants.operationTypeCreate);
  }

  public cancel(): void {
    this.cancelEvent.emit();
  }

  public create(): void {
    let event: IHomEventEmitter = { requestor: this.storeName, event: this.myConstants.emitterEventCreate, action: '', data: this.createForm.value };
    this.createEvent.emit(event);
  }

  locationSelected(id: number): void {
    const def: IFieldDefinition = this.mds.getFieldDefinition('service');
    const filter: IListFilter = def && def.selectListDefinition ? def.selectListDefinition.listFilter : null;
    this.locationChangeEvent.emit({ id: id, listFilter: filter });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['requestTime'] && !(changes['requestTime'].firstChange)) {
      this.initForm();
    }
    if (changes['services'] && !(changes['services'].firstChange)) {
      this.mds.loadSelectionLists(['service'], this.operation);
      this.availableServices$.next(this.services);
    }
  }

}
