import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';
import { appConstants, IAppConstants } from '../../../../../shared/constants';

@Component({
  selector: 'survey-question-data-types',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './survey-question-data-types.component.html'
})
export class SurveyQuestionDataTypesComponent implements OnInit {
  public listDefinition: IListDefinition;

  constructor(public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.newRequest();
    });
  }

  newRequest() {
    this.listDefinition = this.adminService.loadSurveyQuestionDataTypesListDefinition();
  }

}
