import { Component, OnInit, ChangeDetectionStrategy, Inject, Input, Output, EventEmitter } from '@angular/core';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IService, IProviderLocation, IInstaller, IChargeBackSkuPrice } from '../../../view-models';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { ChargeBackSkuPriceEvent, ChargeBackSkuPriceType } from '../../enums/charge-back-sku-price.enums';
import { MultiSelectTexts, MultiSelectSettings, IMultiSelectOption } from '../../../../../fw/fw-shared/components/fw-multi-select-dropdown';

@Component({
  selector: 'charge-back-sku-price',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './charge-back-sku-price.component.html'
})
export class ChargeBackSkuPriceComponent implements OnInit {
  @Input() object: IChargeBackSkuPrice;
  @Input() operation: string;
  @Input() services: IService[];
  @Input() installers: IInstaller[];
  @Input() locations: IProviderLocation[];
  @Input() locationServices: IMultiSelectOption[];
  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  public overrideTypes: string[] = [];
  public selectedType: string = '';
  public multiSelectLabels: MultiSelectTexts = new MultiSelectTexts();
  public multiSelectSettings: MultiSelectSettings = new MultiSelectSettings();

  //since cannot use enums in html
  locType: string;
  globalType: string;
  installerType: string;
  serviceType: string;

  constructor(
    public emitterService: HomEventEmitterService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  public ngOnInit() {
    this.overrideTypes = [ChargeBackSkuPriceType.global, ChargeBackSkuPriceType.installer, ChargeBackSkuPriceType.providerLocation, ChargeBackSkuPriceType.service]
    this.locType = ChargeBackSkuPriceType.providerLocation;
    this.globalType = ChargeBackSkuPriceType.global;
    this.installerType = ChargeBackSkuPriceType.installer;
    this.serviceType = ChargeBackSkuPriceType.service;
    if (this.object) {
      this.selectedType = this.object.isGlobal ? ChargeBackSkuPriceType.global
        : this.object.installer_installerId && this.object.installer_installerId > 0 ? ChargeBackSkuPriceType.installer
          : this.object.providerLocation_providerLocationId && this.object.providerLocation_providerLocationId > 0 ? ChargeBackSkuPriceType.providerLocation
            : this.object.service_serviceId && this.object.service_serviceId > 0 ? ChargeBackSkuPriceType.service
              : '';
    }
    this.multiSelectLabels = new MultiSelectTexts();
    this.multiSelectLabels.defaultTitle = 'None...';

    this.multiSelectSettings = new MultiSelectSettings();
    this.multiSelectSettings.selectionLimit = 0;
    this.multiSelectSettings.closeOnSelect = false;
    this.multiSelectSettings.autoUnselect = false;
    this.multiSelectSettings.showCheckAll = true;
 }

  public changeOverride(val: string): void {
    const event: IHomEventEmitter = {
      requestor: 'charge-back-sku-price',
      event: ChargeBackSkuPriceEvent.changePriceType,
      action: '',
      data: val
    };
    this.customEvent.emit(event);
  }

  public changeInstaller(val: number): void {
    const event: IHomEventEmitter = {
      requestor: 'charge-back-sku-price',
      event: ChargeBackSkuPriceEvent.selectInstaller,
      action: '',
      data: val
    };
    this.customEvent.emit(event);
  }

  public changeLocation(val: number): void {
    const event: IHomEventEmitter = {
      requestor: 'charge-back-sku-price',
      event: ChargeBackSkuPriceEvent.selectLocation,
      action: '',
      data: val
    };
    this.customEvent.emit(event);
  }

  public changeService(val: number): void {
    const event: IHomEventEmitter = {
      requestor: 'charge-back-sku-price',
      event: ChargeBackSkuPriceEvent.selectService,
      action: '',
      data: val
    };
    this.customEvent.emit(event);
  }

  public priceChange(val: number): void {
    const event: IHomEventEmitter = {
      requestor: 'charge-back-sku-price',
      event: ChargeBackSkuPriceEvent.changePrice,
      action: '',
      data: val
    };
    this.customEvent.emit(event);
  }

  public addLocService(id: number): void {
    const event: IHomEventEmitter = {
      requestor: 'charge-back-sku-price',
      event: ChargeBackSkuPriceEvent.selectLocationService,
      action: '',
      data: id
    };
    this.customEvent.emit(event);
 }

  public removeLocService(id: number): void {
    const event: IHomEventEmitter = {
      requestor: 'charge-back-sku-price',
      event: ChargeBackSkuPriceEvent.removeLocationService,
      action: '',
      data: id
    };
    this.customEvent.emit(event);
  }
}
