import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IErrorData } from '../../../../../shared/interfaces';
import { appConstants, IAppConstants } from '../../../../../shared/constants';

import * as fromStore from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';

import { AdminPricingService } from '../../services/admin-pricing.service';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { DomainObjectService } from '../../../../../shared/services';

@Component({
  selector: 'payout-prices',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './payout-prices.component.html'
})
export class PayoutPricesComponent implements OnInit {
  public listDefinition: IListDefinition = null;
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject([]);
  public detailOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  subscription: Subscription = new Subscription();
  payoutId: number = 0;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<fromStore.IAllDynamicData>,
    public adminPricingService: AdminPricingService,
    public modalService: ModalService,
    public dos: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.payoutId = +paramMap.get('id');
      this.newRequest();
    });
  }

  public onDetailToggle(isOpen: boolean): void {
    this.detailOpen$.next(isOpen);
  }

  newRequest(): void {
    this.listDefinition = this.adminPricingService.loadPayoutPricesListDefinition(this.payoutId);
  }

}
