import { IPortalTab } from "../../../view-models";

//    //{
//    //    text: 'Jobs',
//    //    icon: 'fa-object-ungroup',
//    //    route: 'user-jobs',
//    //},

const managerPortalName: string = 'user-settings-tabs';
export let UserSettingsTabs: IPortalTab[] = [
  {
    portalLevel: 1,
    portalTabContainerId: -1,
    portalTabId: 0,
    managerPortalName: managerPortalName,
    managerPortalId: -1,
    tabLabel: 'Preferences',
    tabIcon: 'fas fa-cog',
    isDefault: true,
    isDynamic: false,
    routeName: 'user-preferences',
    securityDependent: false,
    componentName: '',
    entityName: '',
    tabOrder: 0,
    entityId: 0
  },
  {
    portalLevel: 1,
    portalTabContainerId: -1,
    portalTabId: 1,
    managerPortalName: managerPortalName,
    managerPortalId: -1,
    tabLabel: 'Notes',
    tabIcon: 'fas fa-sticky-note',
    isDefault: false,
    isDynamic: false,
    routeName: 'user-notes',
    securityDependent: false,
    componentName: '',
    entityName: '',
    tabOrder: 2,
    entityId: 0
  }
//  ,  Hiding while we see if access for all works effectively
//  {
//    portalLevel: 1,
//    portalTabContainerId: -1,
//    portalTabId: 2,
//    managerPortalName: managerPortalName,
//    managerPortalId: -1,
//    tabLabel: 'Delegates',
//    tabIcon: 'fas fa-users',
//    isDefault: false,
//    isDynamic: false,
//    routeName: 'user-delegates',
//    securityDependent: false,
//    componentName: '',
//    entityName: '',
//    tabOrder: 3,
//    entityId: 0
//  }
]
