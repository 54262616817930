import { ICrmFormSectionViewModel } from './i-crm-form-section-view-model';

export interface IEntityCrmForm {
  entityCrmFormId: number;
  entityDocumentId: number;

  // extra data
  crmFormName: string;
  crmFormDescription: string;
  formComplete: boolean;
  crmFormSections: ICrmFormSectionViewModel[]
}
export class EntityCrmForm implements IEntityCrmForm {
  entityCrmFormId: number;
  entityDocumentId: number;
  crmFormName: string;
  crmFormDescription: string;
  formComplete: boolean;
  crmFormSections: ICrmFormSectionViewModel[];
}
