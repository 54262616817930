<admin-title-bar [title]="title"
                 [info]="title"
                 [disabled]="isWorking || !haveAllServices"
                  [currentAccess]="userServices"
                 [specifierType]="specifierType"
                 (btnEvent)="onEvent($event)">
</admin-title-bar>

<div [ngClass]="{ 'loading-mask': isWorking }">
  <div class="user-access-items">
    <ng-container *ngFor="let service of userServices">
      <user-access-item customClass="user-access-item--flooring"
                        [item]="service"
                        [name]="service.name"
                        (deleteEvent)="onEvent($event)">
      </user-access-item>
    </ng-container>
  </div>
</div>
