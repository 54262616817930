<div *ngIf="fieldDefinitions && (formDefinition$ | async)" [ngClass]="{ 'component__detail-box': detailConfig.showNav }">
  <fw-component-title *ngIf="detailConfig.showTitle" [title]="(formDefinition$ | async).formTitle" [entityLabel]="entityLabel$ | async"></fw-component-title>
  <fw-app-tabs *ngIf="(authorizedTabs$ | async) as authorizedTabs"
               #detailTabs
               apptabs
               [portalId]="-1"
               [portalEntityId]="-1"
               [level]="1"
               [limit]="authorizedTabs.length"
               [tabDefinition]="authorizedTabs"
               [isLocallyDefined]="true">
  </fw-app-tabs>
  <div *ngIf="detailConfig.useRouterOutlet" class="app-panel app-panel--concat-tab">
    <div class="component--wrapper" 
       [ngClass]="{ 'form-wrapper': detailConfig.wrapsForm, 'form-wrapper--edit': detailConfig.wrapsForm && detailConfig.operation !== myConstants.operationTypeDetails }">
      <!--for animation-->
      <router-outlet></router-outlet>
    </div>
  </div>

  <div *ngIf="!detailConfig.useRouterOutlet" [ngClass]="{ 'form-wrapper': detailConfig.wrapsForm, 'form-wrapper--edit': detailConfig.wrapsForm && detailConfig.operation !== myConstants.operationTypeDetails }">
    <ng-content></ng-content>
    <!--only used when other crud buttons not applicable and not displaying a child list i.e. job parameters of a user job detail from list of user jobs-->
    <div class="app-btn-box" *ngIf="detailConfig.showCancel">
      <button type="button" class="app-btn app-btn--cancel" (click)="onCancel()">{{ detailConfig.operation === myConstants.operationTypeDetails ? 'Close' : 'Cancel' }}</button>
    </div>
  </div>
  <fw-error-box *ngIf="detailConfig.showErrorBox" [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>

  <!-- only include navigational buttons here -->
  <div *ngIf="detailConfig.showNav && detailConfig.operation === myConstants.operationTypeDetails">
    <fw-nav-buttons [operation]="detailConfig.operation" [formDefinition]="formDefinition$ | async" [disabled]="(working$ | async)" (navigateEvent)="onNavigate($event)"></fw-nav-buttons>
  </div>
</div>
