import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sms-empty',
  templateUrl: './sms-empty.component.html'
})
export class SmsEmptyComponent implements OnInit  {
  constructor() { }

  ngOnInit(): void {
   }
}
