import { Component, OnInit, OnDestroy,  Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IWorkOrderSurvey, ISurvey } from '../../../view-models/index_two';
import { IErrorData, IResponseBase } from '../../../../../shared/interfaces';
import { FileUploadType } from '../../../../file-upload/enums/file-upload.enums';

//store actions, reducers, interfaces
import * as fromRoot from '../../../../store/reducers/index';
import { getSelectionListDataByType } from '../../../../../shared/store/selectionLists';

import * as fromStore from '../../../../../fw/dynamic-list/store/index';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { FileUploaderService } from '../../../../file-upload/services/file-uploader.service';
import { DomainObjectService } from '../../../../../shared/services';

@Component({
  templateUrl: './customer-survey-import.component.html'
})
export class CustomerSurveyImportComponent implements OnInit, OnDestroy  {
  public objectData: IWorkOrderSurvey;
  public fileUploadType: FileUploadType = FileUploadType.survey;
  public surveyTypes: ISurvey[] = [];
  public canImport$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectedFileName$: BehaviorSubject<string> = new BehaviorSubject('');
  public infoMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorMessage$: BehaviorSubject<string> = new BehaviorSubject('');
  public errorData$: BehaviorSubject<IErrorData[]> = new BehaviorSubject(null);
  public warnings$: BehaviorSubject<string[]> = new BehaviorSubject(null);
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  storeName: string = '';
  key: string = '';
  operation: string;
  objectId: number = 0;
  requestTime: string = '';
  controllerName: string;
  detailRoutePath: string = '';
  selectedSurveyId: number;
  file: File;
  subscription: Subscription = new Subscription();

  constructor(public activeRoute: ActivatedRoute,
    public rootStore: Store<fromRoot.IState>,
    public store: Store<fromStore.IAllDynamicData>,
    public modalService: ModalService,
    public fus: FileUploaderService,
    public dos: DomainObjectService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  ngOnInit() {
    this.subscription.add(this.activeRoute.paramMap.subscribe(paramMap => {
      this.key = paramMap.get('key');
      this.objectId = +paramMap.get('id');
      this.operation = paramMap.get('operation');
      this.storeName = paramMap.get('storeName');
      this.requestTime = paramMap.get('requestTime');
      this.getDetail();
   }));
  }

  public changeType(id: number): void {
    this.selectedSurveyId = id;
  }

  public selectedFile(file: File): void {
    this.errorMessage$.next('');
    this.errorData$.next(null);
    this.infoMessage$.next('');
    if (file) {
      const fileExt = file.type && file.type.length > 3
        ? this.fus.getFileExtension(file.name)
        : file.type;
      if (this.fus.validateSelected(fileExt, ['csv'])) {
        this.errorMessage$.next('');
        this.file = file;
        this.selectedFileName$.next(file.name);
        this.canImport$.next(true);
      } else {
        this.errorMessage$.next('Invalid file type selected.  Only CSV file types are valid at this time.');
        this.canImport$.next(false);
      }
    }
  }

  public uploadFile(): void {
    if (this.file) {
      this.importFile();
    } else {
      this.errorMessage$.next('Please select a CSV file to upload.');
    }
  }

  public onCancel(): void {
    if (this.modalService.opened) {
      this.modalService.close();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  getDetail() {
    this.subscription.add(this.rootStore.pipe(select(getSelectionListDataByType('survey')))
      .subscribe((data: ISurvey[]) => {
        this.surveyTypes = cloneDeep(data);
      }));

  }

  importFile(): void {
    const formData: FormData = new FormData();
    const fileUploadConfig = this.fus.fileUploadConfigs.find(x => x.uploadType === FileUploadType.survey);
    this.working$.next(true);

    formData.append(fileUploadConfig.objKey, this.selectedSurveyId.toString());
    formData.append(fileUploadConfig.fileKey, this.file);

    this.subscription.add(this.dos.createByMethodWithForm(fileUploadConfig.controllerName, fileUploadConfig.methodName, formData)
      .subscribe((result: IResponseBase) => {
        this.working$.next(false);
        if (result.success) {
          this.errorData$.next(null);
          this.infoMessage$.next(result.message);
          this.warnings$.next(result.data && result.data.hasOwnProperty('warnings') ? result.data['warnings'] : []);
        } else {
          if (result.errorData.length > 0) {
            let errorData: IErrorData[] = cloneDeep(result.errorData);
            const countIndx: number = errorData[0].value.findIndex(x => x.includes('Count:'));
            // errors.ErrorForModel($"Count:
            //should be the last one
            if (countIndx > -1) {
              this.infoMessage$.next(errorData[0].value[countIndx].replace('Count: ', ''));
              errorData[0].value.splice(countIndx, 1);
              this.errorData$.next(errorData);

            } else {
              this.errorData$.next(result.errorData);

            }
          }
        }
        this.canImport$.next(false);

      }));

  }

}
