<div class="project-margins" [ngClass]="{'project-margins--below': (belowMargin$ | async), 'project-margins--above': (aboveMargin$ | async) }">
  <div class="project-margins__con">
    Net:
    <div class="project-margins__con__val">{{ (projectNet$ | async) | currency:'USD':'symbol':'1.2-2' }}</div>
  </div>
  <div class="project-margins__spacer"></div>
  <div class="project-margins__con">
    Margin:
    <div class="project-margins__con__val">{{ (projectMargin$ | async) | percent: '1.2-2' }}</div>
  </div>
</div>


