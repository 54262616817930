import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'purchase-orders-summary-container',
  templateUrl: './purchase-orders-summary-container.component.html'
})
export class PurchaseOrdersSummaryContainerComponent implements OnInit {
  public projectId: number;
  public title: string;

  constructor(public activeRoute: ActivatedRoute  ) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.projectId = +paramMap.get("id");
      this.title = 'Purchase Orders for Project '.concat(this.projectId.toString());
   });
  }

}
