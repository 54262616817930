export interface IChargeBackType {
  chargeBackTypeId: number;
  provider_providerId: number;
  chargeBackTypeName: string;
  glAccountPrefix: string;

  entityLabel: string;
}

export class ChargeBackType implements IChargeBackType {
  chargeBackTypeId: number;
  provider_providerId: number;
  chargeBackTypeName: string;
  glAccountPrefix: string;
  active: boolean;
  rowVersion: string;
  aggregateRootVersion: string;

  entityLabel: string;

  constructor() {
    this.chargeBackTypeId = 0;
    this.provider_providerId = null;
    this.chargeBackTypeName = '';
    this.glAccountPrefix = '';
    this.active = true;
    this.rowVersion = '';
    this.aggregateRootVersion = '';
  }
}
