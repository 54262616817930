<fw-button-title-bar [title]="title$ | async"
                [canIEdit]="canIEdit"
                [operation]="currentOperation"
                [disabled]="currentOperation === myConstants.operationTypeEdit && !(contactManager.isFormValid$ | async)"
                [showEdit]="true"
                [showRefresh]="true"
                [showDelete]="false"
                [customButtons]="customButtons"
                (customEvent)="onTitleBarEvent($event)">
</fw-button-title-bar>

<contact-manager #contactManager
                 [contactId]="contactId"
                 [operationIn]="currentOperation"
                 [isOrganization]="contactTypeId === contactConstantsService.contactTypeIdInstaller ? true : false"
                 [contactTypeId]="contactTypeId"
                 [displayType]="contactConstantsService.displayTypePortalSummary"
                 [canIEdit]="canIEdit"
                 [allowText]="!viewOnly"
                 (managerEvent)="onManagerEvent($event)">
</contact-manager>
