import { Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import {  map } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { IKey, UpdateObjectCustomModel, DeleteObjectModel } from '../../../../../fw/dynamic-list/store/interfaces/index';
import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces/index';
import { PoImportStore } from '../../enums/po-import.enums';
import { IPoImportHeaderViewModel, IPoImportHeader } from '../../../view-models';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { PoImportEvent } from '../../enums/po-import.enums';
import { DynamicDetailService } from '../../../../../fw/dynamic-detail/services/dynamic-detail.service';
import { PoImportService } from '../../services/po-import.service';
import { UserPriviledgesService } from '../../../../../auth/services';
import * as DynamicListActions from '../../../../../fw/dynamic-list/store/actions/dynamic-list.actions';

import * as fromFeature from '../../../../../fw/dynamic-list/store/reducers/feature.reducer';
import { getSelectedRecord, IDynamicListState, getListByType, IListObjectData } from '../../../../../fw/dynamic-list/store';

@Component({
  selector: 'po-import-headers',
  templateUrl: './po-import-headers.component.html'
})
export class PoImportHeadersComponent implements OnInit, OnDestroy {

  public title: string;
  public entityLabel: string;
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);
  public poImportHeader$: BehaviorSubject<IPoImportHeader> = new BehaviorSubject(null);
  public detailConfig$: BehaviorSubject<IDetailContainerConfig> = new BehaviorSubject(null);
  selectedPos: IPoImportHeaderViewModel[] = [];
  subscription = new Subscription();
  vendorOrderNumber: string;
  branchName: string;
  uniqueId: number;
  storeName: string;

  constructor(
    public activeRoute: ActivatedRoute,
    public poImportService: PoImportService,
    public dynamicDetailService: DynamicDetailService,
    @Inject(appConstants) public myConstants: IAppConstants,
    public userPriviledgesService: UserPriviledgesService,
    public store: Store<fromFeature.IAllDynamicData>) { }

  ngOnInit() {
    this.activeRoute.paramMap
      .subscribe(paramMap => {
        this.uniqueId = +paramMap.get('id');
        this.storeName = paramMap.get('storeName');
        this.poImportHeader$.next(null);
        this.listDefinition$.next(null);
        this.vendorOrderNumber = '';
        this.branchName = '';
        this.setDetailConfig(paramMap);
        this.getKeysFromStore();
      });

    this.subscription.add(this.poImportHeader$.subscribe((data) => {
      if (data) {
        this.setListDefinition();
      }
    }));
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case PoImportEvent.deletePoImportHeader:
        if (confirm('Are you sure you want to delete PO ' + event.data.poNumber)) {
          this.deletePoImportHeader(event.data);
        }
        break;
      default:
        break;
    }
  }

  deletePoImportHeader(data: IPoImportHeader): void {
    const deleteUrl = this.userPriviledgesService.getDeleteUrl(data);
    if (deleteUrl.length) {
      const listDefinition = this.listDefinition$.value;
      const keyData: IKey = { storeName: listDefinition.storeName, parentId: listDefinition.parentId, key: listDefinition.rowKeyId, id: data.poImportHeaderId }
      const event: IHomEventEmitter = { requestor: 'po-import-headers', event: this.myConstants.operationTypeDelete, action: '', data: null };
      const deleteData = new DeleteObjectModel(keyData, deleteUrl, event);
      this.store.dispatch(new DynamicListActions.DeleteObjectByUrlList({ deleteData }));
    }
  }

  setDetailConfig(paramMap: ParamMap): void {
    let params: IDetailContainerConfig = this.dynamicDetailService.setDetailConfig(paramMap);
    params.parentId = -1;
    params.useRouterOutlet = false;
    params.wrapsForm = true;
    params.showCancel = true;
    params.showNav = true;
    params.parentId = -1;
    params.showTitle = true;
    params.showErrorBox = true;
    this.detailConfig$.next(params);
  }

  getKeysFromStore(): void {
    //get grouped record from store so can retrieve vendorOrderNumber and branchName
    //NOTE:  grouped header store name will either be poImportGroupedHeaders or poImportGroupedErrors
    this.subscription.add(this.store.pipe(select(getSelectedRecord(this.storeName, -1, 'uniqueId', this.uniqueId)))
      .subscribe((row: IPoImportHeaderViewModel) => {
        this.vendorOrderNumber = row.vendorOrderNumber;
        this.branchName = row.branchName;
        this.title = 'Pos For This Store - Vendor';
        this.entityLabel = this.branchName.concat('-', this.vendorOrderNumber);
        this.listenToStore();
      }));
  }

  setListDefinition(): void {
    let listDefinition: IListDefinition = this.poImportService.loadPoImportHeaderListDefinition(this.vendorOrderNumber, this.branchName);
    //resolver retrieved data
    listDefinition.noGet = true;
    this.listDefinition$.next(listDefinition);
  }

  listenToStore(): void {
    this.subscription.add(this.store.pipe(select(getListByType(PoImportStore.poImportHeaders)))
      .pipe(map((listsState: IDynamicListState) => listsState.objData.find(x => x.parentId == -1)))
      .subscribe((entityList: IListObjectData) => {
        if (entityList && entityList.hasOwnProperty('data')
          && entityList.data
          && entityList.data.length > 0
          && this.vendorOrderNumber && this.branchName) {
          let data: IPoImportHeader = entityList.data.find(x => x['vendorOrderNumber'] == this.vendorOrderNumber && x['branchName'] == this.branchName);
          if (data) {
            this.poImportHeader$.next(data);
          }
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
