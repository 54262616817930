import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { UserPriviledgesService } from '../../../../auth/services';
import { IColToTimeXref } from '../interfaces';
import { IWorkOrderViewModel, ISlotScheduleCalendar } from '../../view-models';
import { DispatchCalendarSearchTerm } from '../enums/dispatch-calendar.enums';
import { ISearchTerm, IListFilter } from '../../../../fw/dynamic-list/interfaces';
import { HomCommonUtility } from '../../../../shared/services';

@Injectable({
  providedIn: 'root'
})
export class DispatchCalendarService {
  public viewDay: string = 'Day';
  public viewWeek: string = 'Week';
  public viewMonth: string = 'Month';
  public typeDays: string = 'Days';
  public lastFilter: IListFilter;
  public lastSelectedDate: string = '';
  public lastFilterText: string = '';
  public lastDisplayView: string = '';
  public lastSlotScheduleCalendars: ISlotScheduleCalendar[];

  dayViewXref: IColToTimeXref[] = [];

  constructor(public datePipe: DatePipe,
    public userPriviledgesService: UserPriviledgesService,
    public homCommonUtility: HomCommonUtility  ) {
    this.loadDayViewXref();
  }

  //Any time after 7pm will be thrown in to the last column
  public getColByTime(time: string): number {
    const maxCol = this.dayViewXref[this.dayViewXref.length - 1].col;
    const row: IColToTimeXref = this.dayViewXref.find(x => x.time == time);
    return row ? row.col : maxCol;
  }

  
  public getXrefFirstCol(view: string): number {
    switch (view) {
      case this.viewDay:
        return this.dayViewXref.length ? this.dayViewXref[0].col : 1;
      default:
        return 1;
    }
  }

  public getXrefLastCol(view: string): number {
    switch (view) {
      case this.viewDay:
        return this.dayViewXref.length ? this.dayViewXref.length +1: -1;
      default:
        return -1;
    }
  }

  public initSavedValues(): void {
    //clean up for log off
    this.lastFilter = null;
    this.lastSelectedDate =  this.lastFilterText = this.lastDisplayView = '';
    this.lastSlotScheduleCalendars = null;
  }

  //create an xref of grid columns that correspond to 15 minute increments
  //starting at 7: 00am and ending at 7: 00pm - 24 hour time
  loadDayViewXref(): void {
    this.dayViewXref = [];
    let col: number = 1;
    let i: number = 7;
    while (i < 19) {
      let j: number = 0;
      while (j < 60) {
        this.dayViewXref.push({ col: col, time: (i < 10 ? '0' : '').concat(i.toString(), ':', j === 0 ? '00' : j.toString()) });
        col = col+1;
        j = j + 15;
      }
      i = i+1;
    }
  }

  getDateSearchValueByTimeFrame(timeFrame: string, dateValue: string): string {
    let selectedDate = new Date(dateValue);
    switch (timeFrame) {
      case this.viewDay:
        return dateValue;

      case this.viewWeek:
        let weekStart = this.homCommonUtility.getStartOfWeek(dateValue);
        let weekEnd = this.homCommonUtility.getEndOfWeek(dateValue);
        return this.datePipe.transform(weekStart, 'MM/dd/yyyy').concat(' - ', this.datePipe.transform(weekEnd, 'MM/dd/yyyy').toString());

      case this.viewMonth:
        let firstDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        let lastDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
        return this.datePipe.transform(firstDay, 'MM/dd/yyyy').toString().concat(' - ', this.datePipe.transform(lastDay, 'MM/dd/yyyy').toString());

      default:
        break;
    }
  }


  canModify(row: IWorkOrderViewModel): boolean {
    return row.canEdit && this.userPriviledgesService.canEditWorkOrderViaView(row);
  }


  getCalendarFilterValueByTerm(filter: IListFilter, term: DispatchCalendarSearchTerm): ISearchTerm {
    return filter.searchTerm.find(x => x.term == term);
  }

}
