<div [ngClass]="{ 'loading-mask': loading$ | async }" class="match-box-box">

  <div *ngIf="loaded && !(matchData$ | async)">
    <div class="match-box__empty">No Matches were Found</div>
  </div>

  <div *ngIf="loaded && (matchData$ | async)">
    <div class="match-box__warning" *ngIf="selectedIndex === -1">If matches are found, please select the best match.</div>
    <div class="match-box__warning" *ngIf="selectedIndex > 0 && selectedIndex !== 1776">After Selecting a Match,<br />Click on the Link button to associate the contact to the project.</div>
    <div class="match-box__warning" *ngIf="selectedIndex === 1776">
      <span>After setting the required contact information,</span>
      <span *ngIf="!isPoImport"><br />Click <b>Search For Matches</b> to Continue then,</span>
      <span><br />Click on the Link button to associate the contact to the {{isPoImport ? 'purchase order' : 'project' }}.</span>
    </div>
  </div>

  <div *ngFor="let item of (matchData$ | async); let i= index;">
    <div class="match-box" [ngClass]="{'match-box--selected': i === selectedIndex }" (click)="selectRow(i)">
      <div class="match-box__option">
        <span class="match-box__icon" [ngClass]="{ 'fas fa-check-circle app-icon--success': i === selectedIndex, 'fal fa-circle app-icon--disabled': i !== selectedIndex }"></span>
        <ng-container *ngIf="currentContactId === item.contactId && selectedIndex === -1">
          <!--only highlight when have not clicked on anything -->
          <span class="match-box__current">This is the currently selected contact</span>
        </ng-container>
        <ng-container *ngIf="currentContactId !== item.contactId || selectedIndex > -1">
          {{ isPoImport ? 'Merge with ' : 'Link to '  }}Existing Contact Card
        </ng-container>
      </div>
      <div class="match-box__name">
        {{ item.firstName }} {{ item.middleName }} {{ item.lastName }}
      </div>
      <div class="match-box__percent">{{ item.index | percent: '1.2-2'  }} Match</div>
      <div class="match-box__sub-title">Addresses</div>
      <div class="match-box__item">
        <ng-container *ngFor="let addr of (item.contactMechanismAddresses)">
          <div class="match-box__type">{{ addr.addressTypeName }}</div>
          <div>{{ addr.line1 }}</div>
          <div *ngIf="addr.line2">{{addr.line2}}</div>
          <div *ngIf="addr.line3">{{addr.line3}}</div>
          <div>
            <span>{{addr.city}}</span>
            <span *ngIf="addr.city && addr.state">, </span>
            <span>{{addr.state.stateAbbr}}</span>
            <span *ngIf="addr.city || addr.state">&nbsp;&nbsp;</span>
            <span>{{addr.zipcode5}}</span>
          </div>
        </ng-container>
      </div>
      <div *ngIf="item.contactMechanismPhones && item.contactMechanismPhones.length" class="match-box__sub-title">Phone Numbers</div>
      <div class="match-box__item">
        <ng-container *ngFor="let phone of (item.contactMechanismPhones)">
          <fw-phone [phoneNumber]="phone.phoneNumber" [allowText]="false" [enabled]="false"></fw-phone>
        </ng-container>
      </div>
      <div *ngIf="item.contactMechanismEmails && item.contactMechanismEmails.length" class="match-box__sub-title">Emails</div>
      <div class="match-box__item">
        <ng-container *ngFor="let email of (item.contactMechanismEmails)">
          <fw-email [emailAddress]="email.emailAddress" [enabled]="false"></fw-email>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="loaded && ((isPoImport && !hasExactMatch) || (!isPoImport)) && (matchData$ | async)">
    <div class="match-box match-box__option" [ngClass]="{'match-box--selected': selectedIndex === 1776 }"
         (click)="selectRow(1776)">
      <span class="match-box__icon"
            [ngClass]="{ 'fas fa-check-circle app-icon--success': selectedIndex === 1776, 'fal fa-circle app-icon--disabled': selectedIndex !== 1776 }"></span>
      Create New Contact Card
    </div>
  </div>

  <fw-error-box [errorData]="errorData$ | async" [onForm]="true" [ignoreKey]="true"></fw-error-box>
</div>
