/*This container name is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition, IDetailRouteParameter, IScheduleRouteParameter } from '../../../../../fw/dynamic-list/interfaces';
import { UserDashEvent, UserDashStore } from '../../enums/user-dashboard.enums';
import { ProjectEvent } from '../../../project/enums/project.enums';
import { IWorkOrderViewModel } from '../../../view-models';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { UserDashboardService } from '../../services/user-dashboard.service';
import { DatePipe } from '@angular/common';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';

@Component({
  selector: 'undispatched-work-orders',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './undispatched-work-orders.component.html'
})
export class UndispatchedWorkOrdersComponent implements OnInit {
  public listDefinition$: BehaviorSubject<IListDefinition> = new BehaviorSubject(null);

  constructor(public activeRoute: ActivatedRoute,
    public modalService: ModalService,
    public userDashboardService: UserDashboardService,
    public datePipe: DatePipe,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case UserDashEvent.scheduleWorkOrder: //reschedule
        this.showScheduler(event.data);
        break;
      case ProjectEvent.addNote:
        this.addNote(event.data);
        break;
      case UserDashEvent.dispatchWorkOrder:
        this.dispatch(event.data);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.listDefinition$.next(null);
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.listDefinition$.next(this.userDashboardService.loadUnDispatchedWorkOrdersListDefinition());
    });
  }

  addNote(workOrder: IWorkOrderViewModel): void {
    if (!this.validModel) return;

    const stateData = { projectId: workOrder.projectId };
    const scheduleDate = workOrder.scheduleStartDate ? this.datePipe.transform(workOrder.scheduleStartDate, 'MM/dd/yyyy') : '';
    this.modalService.open({
      title: 'Schedule Notes for Work Order '.concat(workOrder.workOrderId.toString(), scheduleDate ? ' on '.concat(scheduleDate) : ''),
      path: 'user-dash-work-order-notes/' + workOrder.workOrderId.toString() + '/' + this.myConstants.routeActionAdd,
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      state: stateData
    });
  }

  showScheduler(data: IWorkOrderViewModel) {
    if (!this.validModel) return;

    const workOrderId: number = data && data.hasOwnProperty('workOrderId') ? data['workOrderId'] : 0;
    if (workOrderId === 0) {
      console.log('DEV ERROR:  missing wo id on event', event);
      return;
    }

    let params: IScheduleRouteParameter = {
      scheduleId: 0,
      operation: this.myConstants.operationTypeDetails,
      projectId: data.projectId,
      storeName: this.listDefinition$.getValue().storeName,
      parentId: this.listDefinition$.getValue().parentId
    }
    this.modalService.open({
      title: 'Work Order Schedule',
      path: 'user-dash-scheduler',
      id: workOrderId,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.xlarge,
      optionalParams: params
    });

  }


  dispatch(workOrder: IWorkOrderViewModel): void {
    if (!this.validModel) return;

    const stateData = { projectId: workOrder.projectId, scheduleId: workOrder.scheduleId, hasPostIts: workOrder.specialInstructionAlert, storeName: UserDashStore.dashUndispatchedWorkOrders };
    this.modalService.open({
      title: 'Select Work Crew to Dispatch Work Order ',
      path: 'user-dash-dispatch/' + workOrder.workOrderId.toString(), 
      optionalParams: null,
      onExit: null,
      castExit: false,
      hasTabs: false,
      sizeType: ModalSizeType.small,
      state: stateData
    });
  }

  validModel(workOrder: IWorkOrderViewModel): boolean {
    const woId: number = workOrder && workOrder.hasOwnProperty('workOrderId') ? workOrder['workOrderId'] : 0;
    if (woId === 0) {
      console.log('DEV ERROR:  missing wo id on event', event);
    }
    return woId > 0;
  }
}
