<div class="portal">
  <div class="portal__detail--item">
    <fw-dynamic-list *ngIf="listDefinition"
                     [listTitle]="'Zones'"
                     [vmListDefinition]="listDefinition">
    </fw-dynamic-list>
  </div>
</div>


