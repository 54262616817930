import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { IProgramServiceDocumentType } from '../../../view-models/i-program-service-document-type';
import { AdminEvent } from '../../enums/admin.enums';
import { getSelectedRecord, IAllDynamicData } from '../../../../../fw/dynamic-list/store';
import { IProgramService } from '../../../view-models';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'program-service-files',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './program-service-files.component.html'
})
export class ProgramServiceFilesComponent implements OnInit {
  public listDefinition: IListDefinition;
  programServiceId: number;
  subscription: Subscription = new Subscription();
  currentProgramservice: IProgramService;

  constructor(public activeRoute: ActivatedRoute,
    public store: Store<IAllDynamicData>,
    public adminService: AdminProviderService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      this.programServiceId = +paramMap.get('psId');
      this.newRequest();
    });
  }

  public onCustom(event: IHomEventEmitter) {
    switch (event.event) {
      case AdminEvent.showFile:
        this.showFile(event.data);
        break;
      case AdminEvent.showRelatedEntityManager:
        this.openEntityManager();
        break;
      default:
        break;
    }
  }

  newRequest() {
    this.listDefinition = this.adminService.loadProgramServiceFilesListDefinition(this.programServiceId);

    const parentListDef = this.adminService.loadProgramServicesListDefinition();
    this.subscription.add(this.store.pipe(
      select(getSelectedRecord(parentListDef.storeName, -1, parentListDef.rowKeyId, this.programServiceId), take(1)))
      .subscribe(entity => {
        this.currentProgramservice = entity;
      })
    );
  }

  showFile(data: IProgramServiceDocumentType): void {
    if (!data) return;

      if (data.hasOwnProperty('fileUrl')) {
        var w = window.open(data.fileUrl, '_blank');
        w.focus();
      }
  }

  openEntityManager(): void {
    const parentName: string = 'Program Service '.concat(this.currentProgramservice.programServiceName);
    const parentId: number = this.programServiceId;
    this.adminService.openRelatedEntityManager(this.listDefinition, parentName, parentId);
  }
}
