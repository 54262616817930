import { Component, OnInit,   Inject, Input, Output, EventEmitter  } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { appConstants, IAppConstants } from '../../../../../shared/constants';
import { AdminEvent } from '../../enums/admin.enums';
import { IUserRoleViewModel, IProviderUserPrivilegeViewModel } from '../../../view-models';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'admin-title-bar',
  templateUrl: './admin-title-bar.component.html'
})
export class AdminTitleBarComponent implements OnInit  {
  @Input() title: string
  @Input() info: string = '';
  @Input() disabled: boolean;
  @Input() currentAccess: IUserRoleViewModel[] | IProviderUserPrivilegeViewModel[];
  @Input() specifierType: number;
  @Input() showBtns: boolean = true;

  @Output() public btnEvent = new EventEmitter<IHomEventEmitter>();

  public inCreateMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  requestor: string = 'admin-title-bar';

  constructor(public adminService: AdminService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public showCreate() {
    this.inCreateMode$.next( true );
 }

  public onCustom(event: IHomEventEmitter) {
    let eventOut: IHomEventEmitter = cloneDeep(event);
    eventOut.action = this.specifierType.toString();

    switch (event.event) {
      case AdminEvent.cancelAdd:
        this.inCreateMode$.next( false );
        break;
      case AdminEvent.saveUserAccess:
        this.btnEvent.emit(eventOut);
        this.inCreateMode$.next(false);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
  }
}
