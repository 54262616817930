import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IListDefinition } from '../../../../../fw/dynamic-list/interfaces';
import { AdminProviderService } from '../../services/admin-provider.service';

@Component({
  selector: 'shipper-payouts',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './shipper-payouts.component.html'
})
export class ShipperPayoutsComponent implements OnInit {

  public listDefinition: IListDefinition;

  constructor(
    public activeRoute: ActivatedRoute,
    public adminService: AdminProviderService  ) {
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(paramMap => {
      const shipperId = +paramMap.get('id');
      this.newRequest(shipperId);
    });
  }

  newRequest(shipperId: number) {
    this.listDefinition = this.adminService.loadShipperPayoutsListDefinition(shipperId);
  }

}
