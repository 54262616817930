<div class="app-wrapper">
  <fw-content class="content-grid-loc"></fw-content>
  <fw-header></fw-header>
  <fw-footer class="footer-grid-loc"></fw-footer>
  <fw-modal></fw-modal>
</div>




