import { Input, Component, OnInit,  ViewChild,  Inject, OnChanges, SimpleChanges, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { ICustomButton } from '../../../../fw/fw-shared/interfaces/i-custom-button';
import { ISeedContactInformation } from '../../../portals/view-models';
import { ContactEvent, ContactEditType } from '../../enums/contact.enums';
import { ButtonType } from '../../../../fw/fw-shared/enums/button-type.enum';

import { ContactConstantsService } from '../../services/contact-constants.service';
import { ContactManagerComponent } from '../../containers/index';
import { ContactUtilityService } from '../../services';

@Component({
  selector: 'detail-contact-manager',
  templateUrl: './detail-contact-manager.component.html'
})
export class DetailContactManagerComponent implements OnInit, AfterViewInit, OnChanges  {
  @Input() contactId: number
  @Input() canIEdit: boolean;
  @Input() isOrganization: boolean;
  @Input() contactTypeId: number;
  @Input() checkForMatch: boolean = false;
  @Input() linkedContact: boolean = false;
  @Input() seedData: ISeedContactInformation  = null;
  @Input() isPoImport: boolean = false;
  @Input() allowUnlink: boolean = false;
  @Input() defaults: ISeedContactInformation = null;

  @Output() public crudEvent = new EventEmitter<IHomEventEmitter>();

  @ViewChild('contactManager') public contactManager: ContactManagerComponent;

  public title: string = 'Contact Information';
  public operation$: BehaviorSubject<string> = new BehaviorSubject('');
  public reset$: BehaviorSubject<string> = new BehaviorSubject('');
  public currentOperation: string;
  public customButtons$: BehaviorSubject<ICustomButton[]> = new BehaviorSubject([]);
  public seedDataOut$: BehaviorSubject<ISeedContactInformation> = new BehaviorSubject(null);
  public forceShowCancel$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isBarDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public ready: boolean = false;
  subscription: Subscription = new Subscription();
  public editType: string = '';

  constructor(
    public contactConstantsService: ContactConstantsService,
    public contactUtilityService: ContactUtilityService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public onTitleBarEvent(event: IHomEventEmitter): void {
    switch (event.event) {
      case ButtonType.edit:
        this.operation$.next(this.myConstants.operationTypeEdit);
        this.editType = ContactEditType.standard;
        this.forceShowCancel$.next(false);
        this.setCustomButtons(true, false); //do not allow link/unlink if in typical edit mode
        break;
      case ButtonType.reload:
        this.setCustomButtons(this.allowUnlink, this.linkedContact ? true : this.isPoImport ? false : true);
        this.contactManager.onReloadRequest();
        break;
      case ButtonType.cancel:
        this.cancel();
        this.crudEvent.emit(event);
        break;
      case ButtonType.save:
        if (this.contactId > 0) {
          this.contactManager.onSave();
        } else  {
          this.contactManager.onCreate();
        }
        break;
      case ContactEvent.linkContactToPo:
        this.linkToPo(event);
        break;
      case ContactEvent.linkContactToParent:
        this.linkToProject(event);
        break;
      case ContactEvent.unLinkContactToPo:
      case ContactEvent.unLinkContactToParent:
        //just unhooks in the ui, no db update for po for this event
        //operation change to edit when select a match
        this.operation$.next(this.myConstants.operationTypeDetails);
        this.forceShowCancel$.next(true);
        this.setCustomButtons(false, false);
        this.crudEvent.emit(event);
        break;
      default:
        break;
    }
  }

  public onManagerEvent(event: IHomEventEmitter) {
   switch (event.event) {
      case ContactEvent.selectMatch:
        this.handleSelectMatch(event);
        break;
      case ContactEvent.ranNewContactMatchCheck:
        this.setCustomButtons(false, true);
        break;
      case ContactEvent.isContactValid:
       //on create, project forces a match check even if valid(i.e. ranNewContactMatchCheck)
       if (this.editType === '' && this.contactId > 0) {
          this.resetButtons();
        }
        else if (this.editType === ContactEditType.standard) {
         this.setCustomButtons(true, false);
       } else if (this.contactId > 0 || this.isPoImport) {
          this.setCustomButtons(false, event.data);
        }
        break;
      case ContactEvent.createWithContact:
        this.createWithContact(event.data);
        break;
      case ContactEvent.cancel:
        this.cancel();
        break;
      case ContactEvent.updated:
        this.updated();
        break;
      default:
        break;
    }
  }

  updated() {
    this.resetButtons();
    const event: IHomEventEmitter = { requestor: 'detail-contact-manager', event: this.myConstants.emitterEventUpdateComplete, action: '', data: this.contactId };
    this.crudEvent.emit(event);
  }

  createWithContact(newContactId: number) {
    this.resetButtons();
    const event: IHomEventEmitter = { requestor: 'detail-contact-manager', event: this.myConstants.emitterEventCreateComplete, action: '', data: newContactId };
    this.forceShowCancel$.next(this.isPoImport ? true : false);
    this.crudEvent.emit(event);
  }

  cancel() {
    if (this.editType !== ContactEditType.mergeWithExisting) {
      this.contactManager.onCancel();
    }
    if (!this.isPoImport) {
      this.resetButtons();
    }
 }

  linkToPo(event: IHomEventEmitter): void {
    if (this.contactId === 0) {
      this.contactManager.onCreate();
    } else {
      const model = this.contactManager.getContactModel();
      const mergeEvent: IHomEventEmitter = {
        requestor: 'detail-contact-manager',
        event: event.event,
        action: '',
        data: model
      };
      this.crudEvent.emit(mergeEvent);
    }
  }

  linkToProject(event: IHomEventEmitter): void {
    if (this.contactId === 0) {
      this.contactManager.onCreate();
    } else {
      const linkEvent: IHomEventEmitter = {
        requestor: 'detail-contact-manager',
        event: event.event,
        action: '',
        data: this.contactId
      };
      this.crudEvent.emit(linkEvent);
    }
  }

  ngOnInit() {
    if (this.contactId === 0 ) {
      this.editType = this.allowUnlink ? ContactEditType.linkWithExisting : '';
    }
    this.subscription.add(this.operation$.subscribe(val => {
      if (val === this.myConstants.operationTypeDetails) {
        this.editType = '';
      }
    }));

    this.operation$.next(this.contactId > 0
      ? this.myConstants.operationTypeDetails
      : this.myConstants.operationTypeCreate);

    this.seedDataOut$.next(this.seedData === null ? null : cloneDeep(this.seedData));
    //for po import, if do not have a linked contact already, the link/unlink button has to be disabled.  that button
    //cannot be enabled for po import until an action is taken.  for project, will always have a linked contact, so
    //will always be ready to unlink.
    this.forceShowCancel$.next(this.isPoImport ? true : false);
    this.setCustomButtons(this.linkedContact, this.linkedContact ? true : this.isPoImport ? false : this.contactId > 0);
    this.ready = true;
  }

  ngAfterViewInit(): void {
    this.subscription.add(this.contactManager.isFormValid$.subscribe(val => {
        this.setTitleBarDisabled();
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['checkForMatch'] && !(changes['checkForMatch'].firstChange)
      && changes['checkForMatch'].previousValue === true && changes['checkForMatch'].currentValue === false) {
        this.resetButtons();
    }
  }

  resetButtons(): void {
    this.forceShowCancel$.next(this.isPoImport ? true : false);
    this.operation$.next(this.myConstants.operationTypeDetails);
    this.setCustomButtons(this.allowUnlink, true);
  }

  setCustomButtons(unlink: boolean, enabled: boolean = true): void {
    if (!this.allowUnlink) {
      this.customButtons$.next([]);
      return;
    }

    const parentType: string = this.contactUtilityService.getParentType(this.contactTypeId, this.isOrganization, this.isPoImport);
    const unlinkText: string = this.isPoImport ? 'UnAssociate Contact To PO' : 'Link to Different Contact';
    const linkText: string = this.contactId === 0
      ? 'Create and Link Contact to '.concat(parentType)
      : this.isPoImport
        ? 'Merge and Link Contact to PO'
        : 'Link Contact to '.concat(parentType);
                                                      
    const btn: ICustomButton = {
      title: unlink ? unlinkText : linkText,
      icon: unlink ? 'fas fa-unlink' : 'fas fa-link',
      cssName: '',
      enabled: enabled && this.canIEdit && (this.editType !== ContactEditType.standard),
      eventName: unlink ? this.isPoImport ? ContactEvent.unLinkContactToPo : ContactEvent.unLinkContactToParent
        : this.isPoImport ? ContactEvent.linkContactToPo : ContactEvent.linkContactToParent
    }
    const btns: ICustomButton[] = [btn];
    this.customButtons$.next(btns);
  }

  setTitleBarDisabled(): void {
    const isFormValid: boolean = this.contactManager ? this.contactManager.isFormValid$.value : false;
    this.isBarDisabled$.next(this.forceShowCancel$.value ||
      (this.editType === ContactEditType.linkWithExisting && this.contactId === 0)
      || (!isFormValid));
  }

  //Works for both project contact and po import.
  //project contact will not have seed data
  handleSelectMatch(event: IHomEventEmitter): void {
    const data: ISeedContactInformation = cloneDeep(event.data);
    this.customButtons$.next([]);
    //will merge the selected record wtih the po import data
    this.seedDataOut$.next(cloneDeep(this.isPoImport ? this.seedData : data));
    if (!data) {
      this.operation$.next(this.myConstants.operationTypeCreate);
      this.editType = this.allowUnlink ? ContactEditType.linkWithExisting : this.editType;
      this.setCustomButtons(false, false);
    } else {
      if (this.isPoImport) {
        this.operation$.next(this.myConstants.operationTypeEdit);
        //for project or admin entity, can only link, no editing
      } else if (this.operation$.value !== this.myConstants.operationTypeDetails) {
        //if flipping between link, then create, then link, reset to details.
        this.operation$.next(this.myConstants.operationTypeDetails);
      }
      this.editType = this.isPoImport
        ? ContactEditType.mergeWithExisting
        : ContactEditType.linkWithExisting;
      this.setCustomButtons(false, this.seedData ? false : true);
    }

    this.crudEvent.emit(event);
  }

}
