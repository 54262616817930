
export enum SearchType {
  StartsWith = "StartsWith",
  Contains = "Contains",
  Default = "Default", //should never need this - tbd if need to remove
  Equals = "Equals",
  GreaterThan = "GreaterThan",
  GreaterThanEqual = "GreaterThanEqual",
  LessThan = "LessThan",
  LessThanEqual = "LessThanEqual",
  Range = "Range",
  EndsWith = "EndsWith",
  NotEqual = "NotEqual",
  NoValue = "NoValue"
}

