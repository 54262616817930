<form *ngIf="multiSelectOptions && availableEntities" [formGroup]="form" novalidate class="form related-entity-create">
  <div class="form-group related-entity-create__select">
    <label for="entityMultiSelect" [htmlFor]="entityMultiSelect">Available {{entityType}}s</label>
    <div *ngIf="multiSelectOptions && multiSelectSettings">
      <fw-multiselect-dropdown #entityMultiSelect
                               id="entityMultiSelect"
                               name="entityMultiSelect"
                               [formControl]="form.controls['entityMultiSelect']"
                               [options]="multiSelectOptions"
                               [texts]="multiSelectLabels"
                               [settings]="multiSelectSettings"
                               (onAdded)="addEntity($event)"
                               (onRemoved)="removeEntity($event)"
                               required>
      </fw-multiselect-dropdown>
    </div>
  </div>

  <div *ngIf="(selectedEntities$ | async) as selectedEntities">
    <div class="related-entity-create__selected">
      <div class="related-entity-create__selected--header">Selected {{entityType}}s</div>
      <ul class="related-entity-create__selected--items">
        <li *ngFor="let item of selectedEntities" class="related-entity-create__selected--item">{{item.label}}</li>
      </ul>
    </div>
  </div>
</form>
<fw-crud-buttons [operation]="operation"
                 [disabled]="!form.dirty || !form.valid || (selectedEntities$ | async).length === 0"
                 [showNext]="showNext"
                 (cancelEvent)="onCancel()"
                 (createEvent)="onCreate()">
</fw-crud-buttons>
<fw-error-box [errorData]="errorData" [errors]="errors" [onForm]="true" [ignoreKey]="true"></fw-error-box>
