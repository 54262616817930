  <div class="sms-sent">
  <div class="sms-sent__icon"><i class="fad fa-house fa-flip-horizontal"></i></div>
  <div class="sms-sent__tail"></div>
  <div class="sms-sent__container">
    <div class="sms-sent__inner-grid">
      <div class="sms-sent__container__bubble">
        <div class="sms-sent__container__bubble--text" [innerHtml]="smsEvent.communicationEventFreeformFeedback"></div>
        <div class="sms-sent__container__bubble__files">
          <ng-container *ngFor="let data of smsEvent.eventFileData">
            <sms-file [fileData]="data">
            </sms-file>
          </ng-container>
        </div>
      </div>
      <div class="sms-sent__container__alert" *ngIf="smsEvent.hasErrorCondition">
        <fw-info [infoTitle]="'SMS Delivery Alert'" [customCss]="'sms-sent__container__info'" [icon]="'fal fa-exclamation-circle'">
          <div class="sms-sent__container__info--text">{{ smsEvent.errorMessage}}</div>
        </fw-info>
      </div>
    </div>
  </div>
  <div class="sms-sent__meta">{{smsEvent.createUserName}} {{ smsEvent.createDate | date:'MM/dd/yyyy h:mm a' }} -- <span class="sms-sent__meta--text-emphasis" [ngClass]="{'sms-sent__meta--text-emphasis--warn' : smsEvent.hasErrorCondition}">{{smsEvent.statusText}}</span> </div>
</div>
