<ng-container *ngIf="files">
  <ng-container *ngFor="let file of files; let i = index;">
    <div [ngClass]="{'has-error': file.errorData}">
      <file-preview-item #previewItem
                         [uploadFile]="file"
                         [entityDocument]="entityDocument"
                         [listType]="listType"
                         (deleteEvent)="removeItem(file.id)"
                         (uploadEvent)="uploadItem($event)"
                         (changeEvent)="changeItem($event)">
      </file-preview-item>
    </div>
  </ng-container>
</ng-container>

