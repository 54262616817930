
<ng-container *ngIf="col.fieldDefinition" [ngSwitch]="displayType">

  <ng-container *ngSwitchCase="myConstants.fieldTypeNumber">
    <span (click)="col.isLink ? openLink() : return" [ngClass]="col.colClass" [title]="col.title">{{ col.value }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.fieldTypeBoolean">
    <span class="dynamic-list__item--bool " [ngClass]="col.colClass" (click)="col.isLink ? openLink() : return">
      <span *ngIf="row[col.key]"><i class="fas app-icon--primary" [ngClass]="{'fa-check': row[col.key]}"></i></span>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.fieldTypePhone">
    <fw-phone [phoneNumber]="col.value"></fw-phone>
  </ng-container>


  <ng-container *ngSwitchCase="myConstants.fieldTypeEmail">
    <fw-email [emailAddress]="col.value"></fw-email>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.fieldTypeDateTime">
    <span [ngClass]="col.colClass" (click)="col.isLink ? openLink() : return">
      {{ row[col.key] | date:'MM/dd/yyyy h:mm a' }}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.fieldTypeTimeSpan">
    <span [ngClass]="col.colClass" (click)="col.isLink ? openLink() : return">
      {{ row[col.key] | date:'h:mm:ss a' }}
    </span>
  </ng-container>
  

  <ng-container *ngSwitchCase="myConstants.fieldTypeDate">
    <span [ngClass]="col.colClass" (click)="col.isLink ? openLink() : return">
      {{ row[col.key] | homdate | date | date:'MM/dd/yyyy' }}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.fieldTypeImage">
    <img *ngIf="col.value" class="dynamic-list__item--thumbnail" [ngClass]="col.colClass"
         (click)="col.isLink ? openLink() : return" src="{{row[col.key]}}" alt="{{row['fileName']}}" [title]="col.title" />
    <span *ngIf="!col.value" [ngClass]="col.colClass" [title]="col.title" [innerHtml]="col.value"></span>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.fieldTypeCurrency">
    <span [ngClass]="col.colClass" (click)="col.isLink ? openLink() : return">
      {{ col.value === myConstants.dash ? col.value : col.value |  currency:'USD':'symbol':'1.2-2' }}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.fieldTypePercentage">
    <span [ngClass]="col.colClass" (click)="col.isLink ? openLink() : return">
      {{ col.value === myConstants.dash ? col.value : col.value | percent: '1.2-2'  }}
    </span>
  </ng-container>


  <ng-container *ngSwitchCase="myConstants.fieldTypeDoubleDecimal">
    <span [ngClass]="col.colClass" (click)="col.isLink ? openLink() : return">
      {{col.value === myConstants.dash ? col.value :  col.value | number: '1.2-2'  }}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.fieldTypeDouble">
    <span [ngClass]="col.colClass" (click)="col.isLink ? openLink() : return">
      {{ col.value === myConstants.dash ? col.value : col.value  }}
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideDropDown">
    <fw-dynamic-list-dropdown [data]="dropDownData"
                              [row]="row"
                              [eventName]="dropDownEvent"
                              [unselectedLabel]="dropDownLabel">
    </fw-dynamic-list-dropdown>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideDecimalInput">
    <input type="text" digitOnlyMask [decimal]="true"
           class="number-width"
           placeholder="0.00"
           [value]="!col.value || col.value == myConstants.dash ? 0 : col.value | number: '1.2-2' "
           (change)="onDecimalChange($event.target.value)" />
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideUnavailableReasons">
    <fw-unavailable-reasons [row]="row"></fw-unavailable-reasons>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideCrewCompatibility">
    <crew-work-order-compatibility [compatibilityList]="row.compatibilityList" [screenIsSmall]="screenIsSmall"></crew-work-order-compatibility>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideSpecialInstructions">
    <fw-special-instructions [row]="row"></fw-special-instructions>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideWorkOrderInfo">
    <fw-work-order-info [row]="row" [storeName]="storeName"></fw-work-order-info>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideCustomerFlag">
    <fw-customer-flag-list-item [row]="row"></fw-customer-flag-list-item>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideCustomerAlert">
    <fw-customer-alert [row]="row"></fw-customer-alert>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideCommunicationPreference">
    <fw-communication-preference [row]="row" [displayType]="screenIsSmall ? communicationEventService.displayTypeIcon : communicationEventService.displayTypeBoth"></fw-communication-preference>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideNoteContext">
    <fw-note-context [row]="row"></fw-note-context>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideCustomerContact">
    <fw-project-customer-contact [row]="row"></fw-project-customer-contact>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideInstallerCertificationExpirationDate">
    <installer-certifications-expiration-date [row]="row"></installer-certifications-expiration-date>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideDocumentActions">
    <fw-document-actions-list-item [row]="row"></fw-document-actions-list-item>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideDocumentProperties">
    <fw-document-properties-list-item [row]="row"></fw-document-properties-list-item>
  </ng-container>

  <ng-container *ngSwitchCase="myConstants.listOverrideInstallerCrew">
    <fw-installer-crew-list-item [row]="row"></fw-installer-crew-list-item>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <span (click)="col.isLink ? openLink() : return" [ngClass]="col.colClass" [title]="col.title" [innerHtml]="col.value"></span>
  </ng-container>

</ng-container>

