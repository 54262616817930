<div *ngIf="(listDefinition$ | async)" [ngClass]="{'portal': projectId <= 0}">
  <div [ngClass]="{'portal__detail--item': projectId <= 0}">
    <fw-dynamic-list [vmListDefinition]="listDefinition$ | async"
                     [listTitle]="generalContratorName.concat(' Incoming Pos Grouped By Store and Vendor Po')"
                     (customEvent)="onCustom($event)"
                     (selectEvent)="onSelect($event)"
                     (pageEvent)="onPage()"></fw-dynamic-list>
    <div class="app-btn-box">
      <button class="app-btn app-btn--primary"
              type="button"
              [disabled]="!selectedPos.length"
              (click)="importPOs()">
        Import Selected
      </button>
    </div>
  </div>
</div>

