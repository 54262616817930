<detail-contact-manager *ngIf="defaults"
                        [canIEdit]="true"
                        [contactId]="(contactId$ | async)"
                        [isOrganization]="true"
                        [contactTypeId]="contactTypeId"
                        [allowUnlink]="false"
                        [checkForMatch]="false"
                        [isPoImport]="false"
                        [linkedContact]="(contactId$ | async) > 0 "
                        [defaults]="defaults"
                 (crudEvent)="onCrudEvent($event)">
</detail-contact-manager>
