import { Component,   Inject, Input, Output, EventEmitter } from '@angular/core';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import {  IInstaller, IWorkOrder } from '../../../view-models/index';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'work-order-push',
  templateUrl: './work-order-push.component.html',
})
export class WorkOrderPushComponent  {
  @Input() workOrder: IWorkOrder;
  @Input() installers: IInstaller[];
  @Input() canIEdit: boolean;
  @Input() isWarranty: boolean;

  @Output() public pushEvent = new EventEmitter<number>();

  public canPush$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(@Inject(appConstants) public myConstants: IAppConstants) { }

  public onPush(installerId: number): void {
    this.pushEvent.emit(installerId);
  }

  public onChange(e: any): void {
    this.canPush$.next(e.target.value);
  }

}
