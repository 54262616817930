import { IPhone } from './i-phone';

export interface IContactMechanismPhone {
  contactMechanismPhoneId: number;
  contactId: number;
  contactMechanism_contactMechanismId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName: string;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName: string;
 phone_phoneId: number;
  phoneType: string;
  phoneTypeId: number;
  phone: IPhone;
  isPreferred: boolean;
  canSms: boolean;
  isSmsVerified: boolean;
  phoneExtension: string;
  active: boolean;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;
  rowVersion: string;
  aggregateRootVersion: string;
  //extra data new
  phoneNumber: string;
  phoneId: number;
  uiLabel: string;
  formGroupName: string;
  uiRequired: boolean;
}


export class ContactMechanismPhone implements IContactMechanismPhone {
  contactMechanismPhoneId: number;
  contactId: number;
  contactMechanism_contactMechanismId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId: number;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName: string;
  contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName: string;
  phone_phoneId: number;
  phoneType: string;
  phoneTypeId: number;
  phone: IPhone;
  isPreferred: boolean;
  canSms: boolean;
  isSmsVerified: boolean;
  phoneExtension: string;
  active: boolean;
  createDate: string;
  updateDate: string;
  createdBy: number;
  updatedBy: number;
  rowVersion: string;
  aggregateRootVersion: string;
  phoneNumber: string;
  phoneId: number;
  uiLabel: string;
  formGroupName: string;
  uiRequired: boolean;

  constructor(contactMechanismCategoryName: string,
    contactMechanismCategoryTypeName: string,
    contactMechanismCategoryTypeId: number,
    uiLabel: string,
    formGroupName: string,
    uiRequired: boolean = false,
    preferred: boolean = false,
    phoneExtension: string = null
    ) {
      this.contactMechanismPhoneId = 0;
      this.contactId = 0;
      this.contactMechanism_contactMechanismId = 0;
      this.contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeId = contactMechanismCategoryTypeId;
      this.contactMechanism_contactMechanismCategoryType_contactMechanismCategoryTypeName = contactMechanismCategoryTypeName;
      this.contactMechanism_contactMechanismCategoryType_contactMechanismCategory_contactMechanismCategoryName = contactMechanismCategoryName;
      this.phone_phoneId = 0;
      this.phoneType = contactMechanismCategoryTypeName;
      this.phoneTypeId = contactMechanismCategoryTypeId;  
      this.phone = {phoneId: 0, phoneNumber: ''};
      this.isPreferred = preferred;
      this.canSms = true;
      this.isSmsVerified = false;
      this.active = true;
      this.createDate = ''; 
      this.updateDate = '';
      this.createdBy = 0;
      this.updatedBy = 0;
      this.rowVersion = '';
      this.aggregateRootVersion = '';
      this.phoneNumber = '';
      this.phoneId = 0;
      this.uiLabel = uiLabel;
      this.formGroupName = formGroupName;
      this.uiRequired = uiRequired;
    this.phoneExtension = phoneExtension;
  }
}
