import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import {  IPoImportHeader, IProviderUser} from '../../../view-models/index';
import { PoImportStore } from '../../enums/po-import.enums';

import { MetaDataService } from '../../../../../fw/dynamic-list/services/index'
import { PoImportService } from '../../services/po-import.service';

@Component({
  selector: 'po-import-header-summary',
  templateUrl: './po-import-header-summary.component.html',
  providers: [ MetaDataService ]
})
export class PoImportHeaderSummaryComponent implements OnInit, OnChanges  {
  @Input() data: IPoImportHeader;

  public header$: BehaviorSubject<IPoImportHeader> = new BehaviorSubject(null);
  public displayFields: string[] = ['branchName', 'vendorOrderNumber', 'owner', 'customerFirstName', 'customerLastName', 'addressLine1', 'addressLine2', 'addressCity', 'addressState', 'addressZip'];
  public form: FormGroup;
  public owners: IProviderUser[] = [];
  public operation: string;

  constructor(public  mds: MetaDataService,
    public  poImportService: PoImportService,
    @Inject(appConstants) public  myConstants: IAppConstants) { }

  ngOnInit(): void {
    this.header$.next(this.data);
    this.operation = this.myConstants.operationTypeDetails;
    this.mds.setFieldDefinitions(PoImportStore.poImportHeaders);
    this.mds.loadSelectionLists(this.displayFields, 'po-import-header-summary', this.data.generalContractorId);
    this.mds.mdsReady$.subscribe((val) => {
      if (val) {
        this.owners = this.mds.getSelectItems('owner');
        this.setFieldData();
      }
    });
  }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['data'] && !changes['data'].isFirstChange()) {
        this.header$.next(changes['data'].currentValue);
        this.setFieldData();
      }
  }

  setFieldData(): void {
    this.form = this.mds.loadDynamicFormGroup(this.displayFields, this.data, this.myConstants.operationTypeDetails);
  }
}
