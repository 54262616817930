import { Input, OnInit, Inject, Component, EventEmitter, Output } from '@angular/core';
import { HomCommonUtility } from '../../../../../shared/services';
import { IAppConstants, appConstants } from '../../../../../shared/constants';
import { UserPriviledgesService } from '../../../../../auth/services';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../store/reducers/index';
import { IEntityCrmFormSectionQuestionViewModel } from '../../../view-models/index_two';
import { IHomEventEmitter, HomEventEmitterService } from 'hom-lib/hom-event-emitter';
import { ModalService } from '../../../../../fw/fw-modal/services/fw-modal.service';
import { ModalSizeType } from '../../../../../fw/fw-modal/interfaces/i-modal';
import { HomSignatureEvent } from 'hom-lib/hom-signature';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'crm-form-question',
  templateUrl: './crm-form-question.component.html'
})

export class CrmFormQuestionComponent implements OnInit {
  @Input() crmFormQuestion: IEntityCrmFormSectionQuestionViewModel;
  @Input() entityDocumentId: number;

  questionInstructions: string;
  subscription: Subscription = new Subscription();

  @Output() public customEvent = new EventEmitter<IHomEventEmitter>();

  constructor(
    public modalService: ModalService,
    public rootStore: Store<fromRoot.IState>,
    public userPriviledgesService: UserPriviledgesService,
    public homCommonUtility: HomCommonUtility,
    public emitterService: HomEventEmitterService,
  @Inject(appConstants) public myConstants: IAppConstants) { }

  answerQuestion(answerText: string): void {
    if (this.crmFormQuestion.crmFormQuestionAnswers.length > 0)
      for (let answer of this.crmFormQuestion.crmFormQuestionAnswers) {
        if (!this.crmFormQuestion.allowMultipleAnswers)
          answer.selected = false;
        if (answer.answerText === answerText)
          answer.selected = !answer.selected;
      }
    else if (this.crmFormQuestion.parameterType === 'String')
      this.crmFormQuestion.answerText = answerText;
    this.customEvent.emit({requestor: null, event: 'answer', action: null, data: this.crmFormQuestion})
  }
  ngOnInit(): void {
    if (this.crmFormQuestion.dependentQuestionVerbiage)
      this.questionInstructions = this.crmFormQuestion.dependentQuestionVerbiage;
    else if (this.crmFormQuestion.allowMultipleAnswers && this.crmFormQuestion.required)
      this.questionInstructions = 'Must choose one or more asnwers';
    else if (this.crmFormQuestion.allowMultipleAnswers && !this.crmFormQuestion.required)
      this.questionInstructions = 'May choose many or no answers';
    else if (!this.crmFormQuestion.allowMultipleAnswers && this.crmFormQuestion.required)
      this.questionInstructions = 'Must choose only one asnwer';
    else if (!this.crmFormQuestion.allowMultipleAnswers && !this.crmFormQuestion.required)
      this.questionInstructions = 'May choose any or no answer';
    this.homeEventListener();
  }

  homeEventListener(): void {
    this.subscription.add(this.emitterService.signatureEventEmitted$
      .subscribe((e: IHomEventEmitter) => {
        if (e) {
          switch (e.event) {
            case HomSignatureEvent.CaptureSuccessful:
              this.customEvent.emit({requestor: null, event: 'formSignatureSuccess', action: null, data: this.crmFormQuestion})
              break;
            case HomSignatureEvent.CaptureFailed:
              this.customEvent.emit({requestor: null, event: 'formSignatureFailed', action: null, data: this.crmFormQuestion})
              break;
            default:
              break;
          }
        }
      }));
  }

  routeToSignature(): void {
    const path: string = 'sign-doc/' + this.entityDocumentId.toString() + '/' + this.crmFormQuestion.actionId.toString() + '/1/true/' + this.crmFormQuestion.crmFormQuestionId;

    this.modalService.open({
      title: 'Sign',
      path: path,
      sizeType: ModalSizeType.medium,
      onExit: null,
      castExit: false,
      optionalParams: null,
      hasTabs: false,
      isSubModal: true
    });
  }
}
