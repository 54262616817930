import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject, EventEmitter, Output, OnDestroy} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IDocumentType } from '../../../view-models/index';
import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { EntityDocument, IProjectChildrenViewModel } from '../../../view-models/index_two';
import { IDetailContainerConfig } from '../../../../../fw/dynamic-detail/interfaces';
import { MetaDataService, IValueChanged } from '../../../../../fw/dynamic-list/services/index'

@Component({
  selector: 'required-document-create',
  templateUrl: './required-document-create.component.html',
  providers: [MetaDataService]
})
export class RequiredDocumentCreateComponent implements OnInit, OnChanges, OnDestroy {
  @Input() documentTypes: IDocumentType[];
  @Input() projectChildren: IProjectChildrenViewModel[];
  @Input() storeName: string;
  @Input() detailConfig: IDetailContainerConfig;

  @Output() public changeEvent = new EventEmitter<IValueChanged>();
  @Output() public createEvent = new EventEmitter();
  @Output() public cancelEvent = new EventEmitter<IHomEventEmitter>();

  public displayFields: string[] = ['documentType', 'children'];
  public operation: string = this.myConstants.operationTypeCreate;
  public createForm: FormGroup;

  subscription: Subscription = new Subscription();

  constructor(public mds: MetaDataService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public cancel(): void {
    this.cancelEvent.emit();
  }

  public create(): void {
    let event: IHomEventEmitter = {
      requestor: this.storeName,
      event: this.myConstants.emitterEventCreate,
      action: '',
      data: this.createForm.value
    };
    this.createEvent.emit(event);
  }

  ngOnInit(): void {
    this.initForm();
    this.listenForChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['requestTime'] && !(changes['requestTime'].firstChange)) {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm(): void {
    let entityDocument = new EntityDocument();
      this.mds.setFieldDefinitions(this.storeName);

      this.createForm = this.mds.loadDynamicFormGroup(this.displayFields, entityDocument, this.myConstants.operationTypeCreate);
  }

  listenForChanges(): void {
    this.subscription.add(this.mds.valueChanged$.pipe(filter((obj: IValueChanged) => obj !== null)).subscribe((obj: IValueChanged) => {
      if (obj.value) {
        if (obj.key === 'documentType' || obj.key === 'documentType') {
          this.changeEvent.emit(obj);
        } 
      }
    }));
  }
}
