import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, Inject, ElementRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HomEventEmitterService, IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IPortalTab } from '../../../../app/portals/view-models/index';
import { IAppTab, IAppTabStyle, ITabStyleRequest } from './interfaces/index';
import { ITabStore } from './interfaces/i-tab-store';
import { ITabRequest } from './interfaces/i-tab-request';
import { IScreenBreakpoints } from '../../services/i-screen-breakpoints';
import { IAppConstants, appConstants } from '../../../../shared/constants/index';
import { IUserRecent } from '../user-recents/interfaces/i-user-recent';
import { AppTabAction, AppTabIcon } from './enums/fw-app-tabs.enums';

import { TabsService } from './services/fw-app-tabs.service';
import { ScreenService } from '../../services/screen.service';
import { StoreCleanUpService } from '../../../../app/portals/portal-shared/services/store-cleanup.service';
import { HomCommonUtility } from '../../../../shared/services';
import { UserRecentsService } from '../user-recents/services/user-recents.service';
import { SmsService } from '../../../../app/sms/services/sms.service';

@Component({
  selector: 'fw-app-tabs',
  templateUrl: './fw-app-tabs.component.html'
})

export class TabsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() portalId: number;
  @Input() tabDefinition: IPortalTab[];
  @Input() portalEntityId: number;
  @Input() level: number;
  @Input() limit: number;
  @Input() isLocallyDefined: boolean = false;

  @Output() public tabChange = new EventEmitter<IPortalTab>(); 
  @Output() public tabRequestComplete = new EventEmitter<boolean>(); 

  @ViewChild('tabContainer', { static: true }) public tabContainer: ElementRef; 

  public tabs: IAppTab[] = [];
  public toggled: boolean = false;
  public activeTab$: BehaviorSubject<IAppTab> = new BehaviorSubject(null);
  overflow: number = -1;
  element: ElementRef;
  parentRouteName: string = '';
  activeTabRequest: IHomEventEmitter;
  subscription: Subscription = new Subscription();
  cleanupSub: Subscription;
  cancelCleanupSub$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  dragPos: number;

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    public location: Location,
    public emitterService: HomEventEmitterService,
    public tabsService: TabsService,
    public screenService: ScreenService,
    public storeCleanupService: StoreCleanUpService,
    public homCommonUtility: HomCommonUtility,
    public userRecentsService: UserRecentsService,
    public smsService: SmsService,
    @Inject(appConstants) public myConstants: IAppConstants) { }

  public dragEnter(evt: DragEvent): void {
    evt.preventDefault();
  }
  public dragOver(evt: DragEvent): void {
    evt.preventDefault();
  }

  public dragStart(evt: DragEvent): void {
    this.dragPos = evt.clientX;
  }

  public dragEnd(evt: DragEvent, idx: number, elem: HTMLDivElement): void {
    const newIdx = (idx + Math.round((evt.clientX - this.dragPos) / elem.getBoundingClientRect().width));
    if (newIdx >= 0 && newIdx < this.tabs.length - this.getOverFlow() - 1) this.tabs.splice(newIdx, 0, this.tabs.splice(idx, 1)[0]);
  }

  public toggleMenu(): void {
    this.toggled = !this.toggled; 
  }

  public showBurgerMenu(): void {
    this.toggled = true;
  }

  public hideBurgerMenu(): void {
    this.toggled = false;
  }

  public removeTab(idx: number): void {
    const currentActive: number = this.tabs.findIndex(x => x.active);
    const newActive: number = idx === 0 ? 0 : idx !== currentActive && currentActive < idx ? currentActive : idx + (idx < this.tabs.length - 1 ? 0 : -1);
    const appTab: IAppTab = cloneDeep(this.tabs[idx]);

    if (appTab.closeable) {
      const tabs: IAppTab[] = cloneDeep(this.tabs);
      this.tabs.splice(idx, 1);
      if (idx === currentActive) this.activateTab(newActive, null, true);
      if (this.level === 1) {
        const routeFields: string[] = appTab.route.split('/');
        const entityId = routeFields.length > 2 && this.homCommonUtility.isNumber(routeFields[2]) ? +routeFields[2] : -1;
        this.tabsService.deleteSavedTab(appTab.route);
        if (idx === currentActive) {
          this.cleanupSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              this.storeCleanupService.cleanupEntity(routeFields[0], entityId, tabs);
              this.cancelCleanupSub$.next(true);
            }
          });
        } else {
          this.storeCleanupService.cleanupEntity(routeFields[0], entityId, tabs);
        }
      }  
    }
  }

  public removeInaccessibleTab(idx: number): void {
    const currentActive = this.tabs.findIndex(x => x.active);
    const newActive: number = idx === 0
      ? 0
      : idx !== currentActive && currentActive < idx ? currentActive : idx + (idx < this.tabs.length - 1 ? 0 : -1);

    this.tabs[newActive].active = true;
    this.activeTab$.next(this.tabs[newActive]);
    //remove inaccessible tab
    this.tabs.splice(idx, 1);
    this.activateTab(newActive, null, false, true);
  }

  public activateTab(idx: number, request: ITabRequest = null, isOnRemove: boolean = false, voidPrevious: boolean = false): void {
    
    if (!this.isLocallyDefined) {
      this.tabsService.saveTabs(this.tabDefinition[0].portalTabContainerId, this.parentRouteName, this.tabs);
    }
    
    //exit if just reclicked the current tab
    if (this.tabs[idx].active && !voidPrevious) {
      this.reEmitRequest(); 
      return;
    }
    //reset current active tab, if found
    const current = this.tabs.findIndex(x => x.active);
    if (current > -1) {
      this.tabs[current].active = false;
    }
     
    this.tabs[idx].active = true;
    this.activeTab$.next(this.tabs[idx]);
    const navigationExtras: NavigationExtras = { relativeTo: this.activeRoute };
    this.navigateToRoute(idx, navigationExtras, voidPrevious);

    if (this.toggled) this.toggled = false;
    const route = this.tabs[idx].route;
    if (this.level === 1 && !isOnRemove && (route.split('/')[0] === 'project' || route.split('/')[0] === 'installer')) {
      this.storeCleanupService.initStoreCleanup(this.tabs, route);
    }
    
  }

  getActive(): IAppTab[] {
    return this.tabs.filter(x => x.active);
  }

  getActiveLabel(): string {
    const tab: IAppTab = this.tabs.find(x => x.active);
    return !tab ? 'Not Found' : tab.tabStyle.label;
  }

  getOverFlow(): number {
    return Math.floor((this.tabs.length * 220  - this.tabContainer.nativeElement.offsetWidth) / 220);
  }

  initResponsive(): void {
    const activeIdx = this.tabs.findIndex(x => x.active);
    if (activeIdx >= Math.floor(this.tabContainer.nativeElement.offsetWidth / 220)) { 
      const item: IAppTab = this.tabs[activeIdx];
      this.tabs.splice(activeIdx, 1);
      this.tabs.unshift(item);
    } else {
      if(this.toggled) this.toggled = false;
    }
  }

  navigateToRoute(idx: number, navigationExtras: NavigationExtras, voidPrevious: boolean = false) {
    const route = this.tabs[idx].route;
    if (voidPrevious) {
      this.router.navigate([route], navigationExtras).then(success => {
        if (success) {
          this.handleNavigateEnd();
        } else {
          this.removeInaccessibleTab(idx);
        }
      });
      return;
    }
    if (!route) {
      const tabDef: IPortalTab = this.tabDefinition.find(d => d.portalTabId == this.tabs[idx].id)
      this.tabChange.emit(tabDef);
      return;
    }
    const urlParts: string[] = route.split('/');
    const sameRoute: boolean = this.router.url.includes(urlParts[0]);
    if (this.level === 1) {
      this.handleNavigate(idx, navigationExtras);
    } else if (this.level === 2) {
      if (sameRoute) {
        const parentParts: string[] = this.parentRouteName.split('/');
        //angular routing not handling moving from wo detail 1 to wo detail 2 (as an example) -- temp work around waiting for fix
        const emptyRoute: string = parentParts[1].concat('-empty');
        this.router.navigate([{ outlets: { 'portal-detail': emptyRoute } }], navigationExtras).then(success => {
          if (success) {
            this.handleNavigate(idx, navigationExtras, route);
          }
        });
      } else {
        this.handleNavigate(idx, navigationExtras);
      }
    }
  }

  handleNavigate(idx: number, navigationExtras: NavigationExtras, routeParam: string = null) {
    const route = this.tabs[idx].route;
    const isModal = this.router.url.includes('(modal:');
    if (isModal) {
      if (this.level === 1) {
        this.router.navigate(['loading/modal-content'], navigationExtras).then(success => {
          if (success) {
            this.router.navigate([route], navigationExtras).then(success => {
              if (success) {
                this.handleNavigateEnd();
              } else {
                this.removeInaccessibleTab(idx);
              }
            });
          }
        });
      } else {
        this.router.navigate([{ outlets: { 'portal-detail': routeParam || route } }], navigationExtras).then(success => {
          if (success) {
            this.handleNavigateEnd();
          }
        });
      }
    } else {
      if (this.level === 1) {
        this.router.navigate(['loading/content__detail'], navigationExtras).then(success => {
          if (success) {
            this.router.navigate([route], navigationExtras).then(success => {
              if (success) {
                this.handleNavigateEnd();
              } else {
                this.removeInaccessibleTab(idx);
              }
            });
          }
        });
        
      } else {
        this.router.navigate([{ outlets: { 'portal-detail': 'loading/portal-detail-detail__outlet' } }], navigationExtras).then(success => {
          if (success) {
            this.router.navigate([{ outlets: { 'portal-detail': routeParam || route } }], navigationExtras).then(success => {
              if (success) {
                this.handleNavigateEnd();
              }
            });
          }
        });
      }
    }
  }

  handleNavigateEnd(): void {
    if (!this.isLocallyDefined) {
      this.tabsService.navigationEnd$.next({ level: this.level, ended: true });
      //handle when fkey link from a modal
      //if modal is open due to an app-action (alert type modal - needs user action), do not close that modal.
      if (this.router.url.includes('(modal:') && !this.router.url.includes('app-action')) {
        this.router.navigate([{ outlets: { modal: null } }]);
      }
    }
    this.initResponsive();
  }
   
  setItemProps(item: IPortalTab, request: ITabRequest = null): IAppTab {
    //dynamic labels will be specified with a prefix of : in the db definition. - -handle uniquely
    //IPortalTab = db definition
    const canBookmark: boolean = this.level === 1 && (item.managerPortalId === 7 || item.managerPortalId === 1);
    const dynamicStyle: IAppTabStyle = this.getDynamicTabStyle(item.managerPortalId, request ? request.portalEntityId : item.entityId);

    const tab: IAppTab = {
      id: item.portalTabId,
      route: this.tabsService.setItemRoute(item, this.level, this.portalEntityId, request),
      closeable: item.closeable,
      active: item.isDefault,
      tabStyle: {
        label: !request ? item.tabLabel
          : this.level === 1 ? this.setLabel(item, request.text)
            : this.level === 2 ? this.setLabel(item, request.level2Text)
              : this.setLabel(item, request.level3Text),
        cssName: dynamicStyle === null ? '' : dynamicStyle.cssName,
        icon: dynamicStyle === null ? item.tabIcon || '' : dynamicStyle.icon,
        iconCss: dynamicStyle === null ? '' : dynamicStyle.iconCss,
        iconTitle: dynamicStyle === null ? '' : dynamicStyle.iconTitle,
      },
      canBookmark: canBookmark,
      isBookmarked: canBookmark && request && this.userRecentsService.isBookmarked(item.managerPortalId, request.portalEntityId),
      portalEntityId: request ? request.portalEntityId : null,
      entityId: item.entityId,
      managerPortalId: item.managerPortalId
    };
    return tab;
  }

  initTabs(): void {
    this.parentRouteName = this.router.url;
    const tabDef: IPortalTab[] = cloneDeep(this.tabDefinition),
      tabStore: ITabStore = this.isLocallyDefined ? null : this.tabsService.getSavedTabs(tabDef[0].portalTabContainerId, this.parentRouteName) || null;
    this.tabs = tabStore ? tabStore.tabs : (() => {
      let i: number = 0;
      const len: number = tabDef.length,
        arr: IAppTab[] = [];
      for (; i < len; i++) {
        if (!tabDef[i].isDynamic || tabDef[i].isDefault) {
          arr.push(this.setItemProps(tabDef[i]));
        }
      }
      return arr;
    })();
    //if have an active request, will be pushing all the tabs as well as pushing a new one
    const navigationExtras: NavigationExtras = { relativeTo: this.activeRoute };

    const activeTab: IAppTab[] = this.tabs.filter(x => x.active);
      
    if (activeTab.length > 1) {
      this.tabs[0].active = false;
    } else if (activeTab.length === 0 && this.tabs.length > 0) {
      this.tabs[0].active = true;
    }

    const idx = this.tabs.findIndex(x => x.active);
    this.activeTab$.next(this.tabs[idx]);
    if (idx === -1) {
      console.log('DEV ERROR:  no active tab', this.tabs);
    }

    //  keep here, may need to return
    //const newRoute: string = this.tabs[idx].route;
    //if (this.parentRouteName.startsWith('/'.concat(newRoute))) {
    //  console.log('parent url starts with new route');
    //  return;
    //}
    this.navigateToRoute(idx, navigationExtras);
  }

  listenToBookMarks(): void {
    this.subscription.add(this.userRecentsService.projectUserRecents$.subscribe((data: IUserRecent[]) => {
      this.tabs.filter(t => t.canBookmark && t.managerPortalId === 1).forEach(t => {
        t.isBookmarked = data.findIndex(x => x.entityPkId == t.portalEntityId) > -1 ? true : false;
      });
    }));

    this.subscription.add(this.userRecentsService.installerUserRecents$.subscribe((data: IUserRecent[]) => {
      this.tabs.filter(t => t.canBookmark && t.managerPortalId === 7 ).forEach(t => {
        t.isBookmarked = data.findIndex(x => x.entityPkId == t.portalEntityId) > -1 ? true : false;
      });
    }));

  }

  public setBookmark(item: IAppTab): void {
    this.userRecentsService.workerItems.push({ entityId: item.entityId, entityPkId: item.portalEntityId });
    if (!this.userRecentsService.isBookmarked(item.managerPortalId, item.portalEntityId)) {
      this.userRecentsService.createUserRecent(item.entityId, item.portalEntityId);
    } else {
      this.userRecentsService.deleteUserRecent(this.userRecentsService[item.managerPortalId === 1 ? 'projectUserRecents$' : 'installerUserRecents$'].value.find(x => x.entityPkId === item.portalEntityId).userRecentId);
    }
  }

  setLabel(def: IPortalTab, text: string): string {
    let prefix: string = '';
    if (def.tabLabel.startsWith(':')) {
        prefix = def.tabLabel.replace(':', '');
    }
    return prefix && !text.toString().startsWith(prefix) ? prefix.concat(' ', text) : !text ? def.tabLabel : text;
  }

  appendTab(request: ITabRequest, action: string): void {
    const tabLevel = this.level,
      tabDef: IPortalTab = this.tabDefinition.find(d => tabLevel === 1 ? (d.portalLevel == 1 && d.managerPortalId == request.portalId)
        : request['level' + tabLevel + 'ComponentName'] ? (request['level' + tabLevel + 'ComponentName'] == d.componentName)
          : (d.entityName.toLowerCase() == request['level' + tabLevel + 'EntityName'].toLowerCase()));
    if (!tabDef) {
      console.log('DEV ERROR: tab definition not found for this request: ', request);
      return;
    }

    const route: string = this.tabsService.setItemRoute(tabDef, this.level, this.portalEntityId, request);

    this.activeTabRequest = null;

    if (tabLevel < 3 && request['level' + (tabLevel + 1) + 'EntityId'] || request['level' + (tabLevel + 1) + 'ComponentName'] ) {
      this.activeTabRequest = {
        requestor: 'tab-component',
        event: this.myConstants['emitterEventTabLevel' + (tabLevel + 1) + 'Open'],
        action: action,
        data: request
      };
    }
    const idx = this.tabs.findIndex(t => t.route === route);
    if (idx === -1) {
      /*
      if (!this.isLocallyDefined) {
        console.log("SAVING TAB");
        this.tabsService.saveTabs(this.tabDefinition[0].portalTabContainerId, this.parentRouteName, this.tabs);
      }
      */
      /*
      if (!this.isLocallyDefined) {
        this.tabsService.deleteSavedTab(route);
      }
      */
      this.tabs.push(this.setItemProps(tabDef, request));
      if (!action || action !== this.tabsService.doNotSetActive) {
        this.activateTab(this.tabs.length - 1, request);
      }
    } else {
      if (!action || action !== this.tabsService.doNotSetActive) {
          this.activateTab(idx);
      }
    }
  }

  isActive(idx: number): boolean {
    return this.tabs[idx] && this.tabs[idx].active;
  }

  reEmitRequest() {
    if (this.activeTabRequest) {
      this.emitterService.emitTabEvent(this.activeTabRequest);
      this.activeTabRequest = null;
    }
  }
   
  showMenu(): boolean {
    return this.showBurger() && this.toggled;
  }

  showBurger(): boolean {
    return this.tabs.length > Math.floor(this.tabContainer.nativeElement.offsetWidth / 220);
  }

  //currently only handles sms dynamic styling
  //when additional dynamic needs are implemented, implement with precedence
  getDynamicTabStyle(portalId: number, entityId: number): IAppTabStyle {
    return this.level === 1 && this.smsService.isInUnrespondedList(portalId, entityId)
      ? this.smsService.getUnrespondedToSmsStyle()
      : null;
  }

  //Assumes icon can be used for multiple reasons and action will determine what to do
  //currently only handles sms dynamic styling
  //when additional dynamic needs are implemented, implement with precedence
  changeTabStyle(action: string, request: ITabStyleRequest): void {
    switch (action) {
      case AppTabAction.ClearSmsNotice:
        if (this.level === 1) {
          //remove all sms icons from all tabs
          this.tabs.forEach((t: IAppTab) => {
            if (t.managerPortalId === request.portalId && t.tabStyle.icon === AppTabIcon.Sms) {
              t.tabStyle.icon = '';
              t.tabStyle.iconCss = '';
            }
          });
        }
        break;
      case AppTabAction.SetSmsNotice:
        if (this.level === 1) {
          this.tabs.forEach((t: IAppTab) => {
            if (t.managerPortalId === request.portalId && t.portalEntityId === request.portalEntityId) {
              t.tabStyle.icon = request.tabStyle.icon;
              t.tabStyle.iconCss = request.tabStyle.iconCss;
            }
          });
        }
       break;
      default:
        break;
    }
  }


  ngOnInit(): void {

    this.activeRoute.paramMap.subscribe(paramMap => {
      /*
      if (this.tabs.length && !this.isLocallyDefined) {
        this.tabsService.saveTabs(this.tabDefinition[0].portalTabContainerId, this.parentRouteName, this.tabs);
      }
      */
    }); 

    this.subscription.add(this.emitterService.tabEventEmitted$
      .pipe(filter(request => request.event === this.myConstants['emitterEventTabLevel' + this.level + 'Open'])).subscribe((request: IHomEventEmitter) => {
     if (request && request.data) {
          this.appendTab(request.data, request.action);
        }
    }));

    this.subscription.add(this.emitterService.tabEventEmitted$
      .pipe(filter(request => request.event === this.myConstants['emitterEventTabLevel' + this.level + 'Change'])).subscribe((request: IHomEventEmitter) => {
        if (request && request.data) {
          this.changeTabStyle(request.action, request.data);
        }
      }));

    this.subscription.add(this.emitterService.tabEventEmitted$
      .pipe(filter(request => request.event === this.myConstants['emitterEventTabLevel' + this.level + 'Close'])).subscribe((request: IHomEventEmitter) => {
        if (request) {
          this.removeTab(this.tabs.findIndex(x => x.active));
        }
      }));

    this.subscription.add(this.cancelCleanupSub$.pipe(filter(cancled => cancled === true)).subscribe((cancled: boolean) => {
      this.cleanupSub.unsubscribe();
      this.cancelCleanupSub$.next(false);
    }));
      
    this.subscription.add(this.tabsService.navigationEnd$.subscribe(result => {
      
      if (result.level > this.level && result.ended) {
        if (this.activeTabRequest) {
          this.reEmitRequest();
        } else {
          //All levels are complete for this tab request
          //console.log('All levels of the tab request have completed. level', result, ' request: ', this.activeTabRequest);
          this.tabRequestComplete.emit(true);
        }
      }
      
    }));

    this.subscription.add(this.screenService.resize$
      .subscribe((e: IScreenBreakpoints) => {
        this.initResponsive();
      })
    );

    this.initTabs();
    if (this.level === 1) {
      this.listenToBookMarks();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tabDefinition && !changes.tabDefinition.isFirstChange()) {
      this.initTabs();
    }
  }

  ngOnDestroy() {
    /*
    if (!this.isLocallyDefined) {
      this.tabsService.saveTabs(this.tabDefinition[0].portalTabContainerId, this.parentRouteName, this.tabs);
    }
    */
    this.subscription.unsubscribe();
  }

}
