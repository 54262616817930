  <ng-container *ngIf="addresses && fieldDefinitions && states">
  <div *ngFor="let address of addresses">
    <contact-address 
                     [address]="address"
                     [fieldDefinitions]="fieldDefinitions"
                     [addressStates]="states"
                     [operation]="operation"
                     [contactTypeId]="contactTypeId"
                     [requestTime]="requestTime"
                     [displayType]="displayType"
                     [canIEdit]="canIEdit"
                     [activePrimary]="activePrimary$ | async"
                     (deleteEvent)="onDelete($event)">
    </contact-address>
  </div>
</ng-container>
