<div *ngIf="listDefinition && listMetaData" class="dynamic-list-toolbar-row__btns">
  <ng-container *ngFor="let btn of toolButtons; let i = index; ">
    <button class="dynamic-list-toolbar-row__btns--btn app-btn-icon {{ btn.cssName }}"
            title="{{btn.title}}"
            [disabled]="!btn.enabled"
            (click)="onClick(btn)">
      <i class="{{btn.icon}}"></i>
    </button>
  </ng-container>
</div>
