import { InjectionToken } from '@angular/core';

import { IAppConstants } from './i-app-constants';

//recommendation style for constants is 
export const appInjectedConstants: IAppConstants = {
  notAssigned: 'Not Assigned',
  choose: 'Choose...',
  dash: '-',

  portalIdServiceExpertDashboard: 2,
  portalIdProject: 1,
  portalIdInstaller: 7,
  portalIdComplianceDashboard: 6,
  portalIdReports: 5,

  portalNameServiceExpertDashboard: 'User Dashboard',
  portalNameProject: 'Project',
  portalNameInstaller: 'Installer',
  portalNameComplianceDashboard: 'Compliance',
  portalNameReports: 'Reports',

  entityTypeProject: 'Project',
  entityTypeInstaller: 'Installer',
  entityTypeInstallerWorkCrew: 'InstallerWorkCrew',
  entityTypeInstallerTechnician: 'InstallerTecnhician',
  entityTypeWorkOrder: 'WorkOrder',
  entityTypePurchaseOrder: 'PurchaseOrder',
  entityTypeChargeBack: 'ChargeBack',
  entityTypeNote: 'Note',
  entityTypeAQT: 'ApprovalQueueTransaction',

  dataTypeEnum: 'enum',
  dataTypeEntity: 'entity',
  dataTypeDate: 'date',
  dataTypeDateTime: 'dateTime',
  dataTypeDateTimeNull: 'dateTimeNull',
  dataTypeString: 'string',
  dataTypeEmail: 'email',
  dataTypeLookup: 'lookupValue',
  dataTypeInt: 'int',
  dataTypeInt32: 'int32',
  dataTypeBool: 'bool',
  dataTypeBoolean: 'boolean',
  dataTypeImage: 'image',
  dataTypeEditableSelect: 'editableSelect',
  dataTypeFilterableSelect: 'filterableSelect',
  dataTypeDoubleDecimalNull: 'doubleDecimalNull',
  dataTypeDoubleNull: 'doubleNull',
  dataTypeBoolNull: 'boolNull',
  dataTypeIntNull: 'intNull',


  operationTypeCreate: 'create',
  operationTypeDetails: 'details',
  operationTypeEdit: 'edit',
  operationTypeSingleEdit: 'singleEdit',
  operationTypeRefreshOnSave: 'refreshOnSave',
  operationTypeResetFilter: 'resetFilter',
  operationTypeLoadFilter: 'loadFilter',
  operationTypeResetForm: 'resetForm',
  operationTypeDelete: 'delete',
  operationTypePrint: 'print',

  fieldTypeSelect: 'select',
  fieldTypeEntity: 'entity',
  fieldTypeDate: 'date',
  fieldTypeDateTime: 'dateTime',
  fieldTypeDateTimeNull: 'dateTimeNull',
  fieldTypeString: 'string',
  fieldTypeEmail: 'email',
  fieldTypeNumber: 'number',
  fieldTypeNumericRange: 'numericrange',
  fieldTypeDecimalRange: 'decimalrange',
  fieldTypeMultiSelect: 'multiselect',
  fieldTypeBoolean: 'bool',
  fieldTypeTextArea: 'textarea',
  fieldTypeDateRangePicker: 'daterangepicker',
  fieldTypeSingleDatePicker: 'singledatepicker',
  fieldTypeRadioGroup: 'radiogroup',
  fieldTypeMultiCheckbox: 'multicheckbox',
  fieldTypePhone: 'phone',
  fieldTypeZipCode: 'zipCode',
  fieldTypeImage: 'image',
  fieldTypeCurrency: 'currency',
  fieldTypePercentage: 'percentage',
  fieldTypeDouble: 'double',
  fieldTypeEditableSelect: 'editableSelect',
  fieldTypeFilterableSelect: 'filterableSelect',
  fieldTypeExtra: 'extra',
  fieldTypeDoubleDecimal: 'doubleDecimal',
  fieldTypeTimeSpan: 'timeSpan',
  fieldTypeTimePicker: 'timePicker',
  fieldTypeIntNull: 'intNull',
  fieldTypeColor: 'color',

  //listTypeOverrides
  listOverrideDropDown: 'dropDown',
  listOverrideDecimalInput: 'decimalInput',
  listOverrideUnavailableReasons: 'unavailableReasons',
  listOverrideCrewCompatibility: 'crewCompatibility',
  listOverrideSpecialInstructions: 'specialInstructions',
  listOverrideWorkOrderInfo: 'workOrderInfo',
  listOverrideCustomerAlert: 'customerAlert',
  listOverrideCustomerFlag: 'customerFlag',
  listOverrideCommunicationPreference: 'communicationPreference',
  listOverrideNoteContext: 'noteContext',
  listOverrideCustomerContact: 'customerContact',
  listOverrideInstallerCertificationExpirationDate: 'InstallerCertificationExpirationDate',
  listOverrideDocumentActions: 'listOverrideDocumentActions',
  listOverrideDocumentProperties: 'listOverrideDocumentProperties',
  listOverrideInstallerCrew: 'listOverrideInstallerCrew',

  //form requestor
  requestorTypeDetails: 'details',
  requestorTypeFilter: 'filter',

  projectStatusOpen: 'open',
  projectStatusClosed: 'closed',

  emitterActionHold: 'hold',
  emitterActionNext: 'next',
  emitterActionPrevious: 'previous',
  emitterActionClearCurrent: 'clearCurrent',

  emitterEventSelect: 'select',
  emitterEventDelete: 'delete',
  emitterEventCreate: 'create',
  emitterEventUpdate: 'update',
  emitterEventClose: 'close',
  emitterEventDoUpdateById: 'doUpdateById',
  emitterEventDoUpdateCustom: 'doUpdateCustom',
  emitterEventDoCreate: 'doCreate',
  emitterEventNavigation: 'navigation',
  emitterEventFilterChanged: 'filterChanged',
  emitterEventDetailDirty: 'detailDirty',
  emitterEventEdit: 'edit', //request to edit - so not from a list
  emitterEventRefresh: 'refresh',
  emitterEventCreateComplete: 'createComplete',
  emitterEventUpdateComplete: 'updateComplete',

  emitterEventListShowCreate: 'showCreate',
  emitterEventListPage: 'page',
  emitterEventListReload: 'reload',
  emitterEventListReloadPortal: 'reloadPortal',
  emitterEventListFilter: 'filter',
  emitterEventListLoadFilter: 'loadFilter',
  emitterEventCallLogged: 'callLogged',
  emitterEventListReloadCustom: 'reloadCustom',

  emitterEventFilterFieldFiltered: 'filterOfFilter',
  emitterEventError: 'error',

  emitterEventModalOpen: 'modalOpen',
  emitterEventModalClose: 'modalClose',
  emitterEventModalSaveAndClose: 'modalSaveAndClose',

  emitterEventTabLevel1Open: 'tabLevel1Open',
  emitterEventTabLevel1Change: 'tabLevel1Change',
  emitterEventTabLevel2Open: 'tabLevel2Open',
  emitterEventTabLevel2Change: 'tabLevel2Change',
  emitterEventTabLevel3Open: 'tabLevel3Open',
  emitterEventTabLevel3Change: 'tabLevel3Change',
  emitterEventTabLevel1Close: 'tabLevel1Close',
  emitterEventTabLevel2Close: 'tabLevel2Close',

  emitterEventAddFormGroup: 'addFormGroup',
  emitterEventDeleteFormGroup: 'deleteFormGroup',
  emitterEventValueChanged: 'valueChanged',

  emitterEventAddressChanged: 'addressChanged',
  emitterEventEmailChanged: 'emailChanged',
  emitterEventPhoneChanged: 'phoneChanged',

  emitterEventSuccessfulOrder: 'successfulOrder',
  emitterEventCountClicked: 'dashCountClicked',

  routeActionAdd: 'add',

  errorPendingCreateEntity: 'This entity has an outstanding pending create.',
  errorPendingCreateList: 'There is at least one pending create outstanding for this list.',

  userListFilterRelativeDate: 'CURRENTDATE'
};

export let appConstants = new InjectionToken<IAppConstants>('app.constants');

