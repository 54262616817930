/*This container is defined in the database - any name changes to this component also have to be made in the db */
import { Component, OnInit, Input, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { IListColumn, ListColumn, IListDefinition, ListDefinition, IListFilter, ListFilter, IListButtonType, OrderTerm } from '../../../../../fw/dynamic-list/interfaces/index';
import { IAppConstants, appConstants} from '../../../../../shared/constants';
import { ButtonType } from '../../../../../fw/fw-shared/enums/button-type.enum';
import { UserPriviledgesService } from '../../../../../auth/services';
import { DynamicListService } from '../../../../../fw/dynamic-list/services';

@Component({
  selector: 'installer-certifications',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './installer-certifications.component.html'
})
export class InstallerCertificationsComponent implements OnInit {
  @Input() installerId: number;
  @Input() installerWorkCrewTechnicianId: number = -1;
  @Input() certificationAssignment: number;

  public listDefinition$: BehaviorSubject<IListDefinition>;

  constructor(
    public datePipe: DatePipe,
    public activeRoute: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef,
    public userPriviledgesService: UserPriviledgesService,
    public dynamicListService: DynamicListService,
    @Inject(appConstants) public myConstants: IAppConstants) {
    this.listDefinition$ = new BehaviorSubject(null);
  }

  public title() {
    return this.certificationAssignment === 0 ? 'Installer Certifications' : 'Installer General Contractor Requirements';
  }

  ngOnInit() {
    //if have input properties set, ignore routing, use input
    if (!this.installerId || this.installerId <= 0) {
      this.activeRoute.data
        .pipe(take(1))
        .subscribe(d => {
          //this is a property on the route
          this.certificationAssignment = d.hasOwnProperty('certificationAssignment') ? +d['certificationAssignment'] : 0;
        });

      //handle params existing on this route
      this.activeRoute.paramMap
        .subscribe(paramMap => {
          //if installerId is coming in as an input, use that else this id could be installer or technician id
            this.installerId = +paramMap.get('id'); //TODO: assuming generic
        });
    }
    this.loadListDefinition();
  }

  //component is pushed to from tech manager and routed to from installer
  ngOnChanges(changes: SimpleChanges) {
    if (changes['installerWorkCrewTechnicianId'] && !(changes['installerWorkCrewTechnicianId'].firstChange)) {
      this.installerWorkCrewTechnicianId = changes['installerWorkCrewTechnicianId'].currentValue;
      this.loadListDefinition();
    }
  }

  //retrieves existing and missing (missing will have a 0 in installerCertificationId) certifications
  loadListDefinition() {
    const listColumns = this.loadListColumns();
    const listObjectLabel = this.certificationAssignment === 0 ? 'Installer Certifications' : 'Installer General Contractor Requirements';
    const listObjectController = 'InstallerCertificationViewModel';
    const listStoreName =
      this.installerWorkCrewTechnicianId > 0 ?
        this.certificationAssignment === 0 ? 'installerTechnicianCertifications' : 'installerTechnicianGcRequirements'
        : this.certificationAssignment === 0 ? 'installerCertifications' : 'installerGcRequirements';

    const detailRoutePath =
        this.installerWorkCrewTechnicianId > 0 ?
          this.certificationAssignment === 0 ? 'installer-technician-certification-detail' : 'installer-technician-gc-requirement-detail'
          : this.certificationAssignment === 0 ? 'installer-certification-detail' : 'installer-gc-requirement-detail';

    const detailRouterOutlet =
        this.installerWorkCrewTechnicianId > 0 ?
          this.certificationAssignment === 0 ? 'installerTechnicianCertificationOutlet' : 'installerTechnicianGcRequirementOutlet'
          : this.certificationAssignment === 0 ? 'installerCertificationOutlet' : 'installerGcRequirementOutlet';

    const listRowKeyId = 'certificationId';

    const todayDate = this.datePipe.transform(Date.now(), 'MM/dd/yyyy');
    const parentId: number = this.installerWorkCrewTechnicianId > 0 ? this.installerWorkCrewTechnicianId : this.installerId;
    let defaultListFilter: IListFilter = new ListFilter();
    defaultListFilter.orderTerm = [new OrderTerm('certificationName')];
    let listDefinition = this.dynamicListService.createListDefinition(detailRouterOutlet,
        listObjectLabel,
        listObjectController,
        listStoreName,
        listRowKeyId,
        defaultListFilter,
        listColumns,
        detailRoutePath);

    this.listDefinition$.next(null);
    listDefinition.controllerMethod = this.installerWorkCrewTechnicianId > 0 ? 'ByInstallerWorkCrewTechnician' : 'ByInstaller';
    listDefinition.methodParameters = parentId.toString() + '?certificationAssignment=' + this.certificationAssignment.toString();
    listDefinition.parentId = parentId;
    listDefinition.parentKey = this.installerWorkCrewTechnicianId > 0 ? 'installerWorkCrewTechnicianId' : 'installerId';
    listDefinition.allowSaveFilter = false;
    listDefinition.requiredSearchTerms = defaultListFilter.searchTerm;

    let crudButtons: IListButtonType[] = [{ type: ButtonType.detail, defaults: null }];
    if (this.userPriviledgesService.canManageInstallers$.value) {
      crudButtons.push(
        { type: ButtonType.edit, defaults: null },
        { type: ButtonType.delete, defaults: null }
      );
    }
    listDefinition.rowButtons = this.dynamicListService.loadListCrudButtons(crudButtons, listDefinition.objectLabel);

    this.listDefinition$.next(listDefinition); //set at end to signify ready to instantiate the list.

    }

    /*
        Define the columns in the list to be created.
    */
    loadListColumns(): IListColumn[] {
        let columnDefinitions: Array<IListColumn> = [];

      let colDef = new ListColumn('certificationName');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('isRequired');
      colDef.visibleOnSmall = false;
      columnDefinitions.push(colDef);

      colDef = new ListColumn('expirationDate');
      columnDefinitions.push(colDef);

      colDef = new ListColumn('certificationDescription');
      colDef.visibleOnSmall = false;
      colDef.visibleOnMedium = false;
      colDef.visibleOnLarge = false;
      columnDefinitions.push(colDef);

      if (this.certificationAssignment === 0) {
        colDef = new ListColumn('issuingAuthority');
        colDef.visibleOnSmall = false;
        columnDefinitions.push(colDef);
      }

      colDef = new ListColumn('licenseNumber');
      columnDefinitions.push(colDef);

        return columnDefinitions;
    }

}
