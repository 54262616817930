import { Component, OnInit, Input } from '@angular/core';

import { ICommunicationEvent } from "../../../portals/view-models/i-communication-event";

@Component({
  selector: 'sms-sent',
  templateUrl: './sms-sent.component.html'
})
export class SmsSentComponent   {
  @Input() smsEvent: ICommunicationEvent;

}
