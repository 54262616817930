import { Component, Input, OnInit, OnDestroy, Inject, OnChanges, SimpleChanges, EventEmitter, Output, ViewChild } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';

import { IAppConstants, appConstants } from '../../../../../shared/constants/index';
import { INote,  INotification } from '../../../view-models/index';
import { INoteConfigFields } from '../../interfaces';
import { IErrorData, IPageMetaData } from '../../../../../shared/interfaces/index';
import { NoteService } from '../../services';
import { NoteEvent, NoteAction } from '../../enums/note.enums';

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html'
})
export class NotesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() notes: INote[];
  @Input() pageMetaData: IPageMetaData;
  @Input() noteConfigFields: INoteConfigFields;
  @Input() operation: string;
  @Input() activeNote: INote;
  @Input() activeNotifications: INotification[]
  @Input() canIEdit: boolean = true;
  @Input() errorAction: string = ''
  @Input() errorId: number = 0;
  @Input() errorData: IErrorData[] = null;
  @Input() errorMessage: string = '';

  @Output() public noteEvent = new EventEmitter<IHomEventEmitter>();
  @Output() public noteListEvent = new EventEmitter<IHomEventEmitter>();

  public isDeleting$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public showNotifications$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public actionFromList: string = '';
  subscription: Subscription = new Subscription();

  constructor(
    public noteService: NoteService,
    @Inject(appConstants) public myConstants: IAppConstants) {}

  public pageChanged(page: number) {
    const emitter: IHomEventEmitter = { requestor: 'notes', event: NoteEvent.getPagedData, action: '', data: page };
    this.noteListEvent.emit(emitter);
  }

  public changePrivacy(noteId: number): void {
    let note: INote = this.notes.find(n => n.noteId == noteId);
    if (!note) return;
    const emitter: IHomEventEmitter = { requestor: 'notes', event: NoteEvent.changeNotePrivacy, action: NoteAction.fromList, data: note };
    this.noteListEvent.emit(emitter);
  }


  public onEdit(noteId: number) {
    const note = this.notes.find(x => x.noteId == noteId);
    const emitter: IHomEventEmitter = { requestor: 'notes', event: NoteEvent.editNote, action: '', data: note };
    this.noteListEvent.emit(emitter);
  }

  public onDeleteRequest(noteId: number) {
    const note = noteId > 0 ? this.notes.find(n => n.noteId == noteId) : null;
    this.isDeleting$.next(true);
    const emitter: IHomEventEmitter = { requestor: 'notes', event: NoteEvent.deleteConfirm, action: NoteAction.fromList, data: note };
    this.noteListEvent.emit(emitter);
  }

  public onDeleteConfirm(noteId: number) {
    if (noteId !== this.activeNote.noteId) {
      console.log('Error:  active note and confirm delete mismatch');
      this.onDeleteCancel();
    }
    if (!this.activeNote || !this.activeNote.metaData.hasOwnProperty('crud')) {
      console.log('Error: crud property does not exist');
      return;
    }
    if (!this.activeNote.metaData['crud'].hasOwnProperty('deleteUrl')) {
      console.log('Error: note.metaData.crud.deleteUrl does not exist');
      return;
    }
    const emitter: IHomEventEmitter = { requestor: 'notes', event: NoteEvent.deleteNote, action: NoteAction.fromList, data: this.activeNote.metaData['crud']['deleteUrl'] };
    this.noteListEvent.emit(emitter);
  }

  public onDeleteCancel() {
    //active note stays the same
    this.isDeleting$.next(false);
    const emitter: IHomEventEmitter = { requestor: 'notes', event: NoteEvent.deleteCancel, action: '', data: null };
    this.noteListEvent.emit(emitter);
  }

  public viewNotifications(noteId: number) {
    const note = this.notes.find(n => n.noteId == noteId);
    const emitter: IHomEventEmitter = { requestor: 'notes', event: NoteEvent.viewNotifications, action: NoteAction.fromList, data: note };
    this.noteListEvent.emit(emitter);
    this.showNotifications$.next(true);
  }

  public onCloseNotification() {
    const emitter: IHomEventEmitter = { requestor: 'notes', event: NoteEvent.closeDetail, action: '', data: null };
    this.noteListEvent.emit(emitter);
    this.showNotifications$.next(false);
  }

  //pass these events on up
  public onSendReply(e: IHomEventEmitter): void {
    this.noteListEvent.emit(e);
  }

  public onPostIt(e: IHomEventEmitter): void {
    this.noteListEvent.emit(e);
  }

  public onNoteEvent(e: IHomEventEmitter): void {
    this.noteEvent.emit(e);
  }

ngOnInit() {
    this.actionFromList = NoteAction.fromList; //for html usage
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activeNote'] && !changes['activeNote'].isFirstChange() && !changes['activeNote'].currentValue) {
      this.isDeleting$.next(false);
    }
  }

  context(noteId: number) {
    let returnString = '';
    const note = this.notes.find(x => x.noteId == noteId);
    if (!note || !note.hasOwnProperty('noteLabels')) return '';

    for (var i in note.noteLabels) {
      if (note.noteLabels.hasOwnProperty(i)) {
        returnString += returnString.length !== 0 && note.noteLabels[i] ? ', ' : '';
        returnString += note.noteLabels[i] ? note.noteLabels[i] : '';
      }
    }
    if (returnString.endsWith(',')) {
      returnString = returnString.slice(0, -1);
    }
    return returnString;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
