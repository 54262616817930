<div class="dispatch-month">
  <div class="dispatch-month__day-of-week__blank">&nbsp;</div>
  <div *ngFor="let day of daysOfWeek; let d = index" class="dispatch-month__day-of-week__day" [ngStyle]="{'grid-column': (d+2) + '/' + (d+3) }">
    {{day}}
  </div>
  <ng-container *ngFor="let week of (data$ | async); let i = index"  >
    <div class="dispatch-month__week__header" [ngStyle]="{'grid-row': (i+2) + '/' + (i+3) }" (click)="goToWeek(week.firstDayOf)" title="Go To Week {{ week.weekInYear }}">
      <div class="dispatch-month__week__header--text">{{ week.weekInYear }}</div>
    </div>
    <div *ngFor="let day  of week.days; let d = index" class="dispatch-month__week__day" [ngStyle]="{'grid-row': (i+2) + '/' + (i+3), 'grid-column': (d+2) + '/' + (d+3) }">
      <div class="dispatch-month__week__day__header">
        <div class="dispatch-month__week__day__header--date" (click)="goToDay(day.day)" title="Go To {{ day.day | date:'MM/dd' }}">{{ day.day | date:'MM/dd' }}</div>
        <div class="dispatch-month__week__day__header--count" title="{{ day.data.length }} Items">{{ day.data.length }} Items</div>
        <div class="dispatch-month__week__day__header--btns">
          <!--LOCK DAY-->
          <ng-container *ngIf="canScheduleLockDays">
            <button class="app-btn-icon"
                    [ngClass]="{'app-btn-icon--success': !day.isLocked, 'app-btn-icon--danger': day.isLocked }"
                    title="{{ day.isLocked ? 'Unlock' :  'Lock' }} Day"
                    (click)="lockDay(day.day)">
              <i class="fas fa-lock-{{ !day.isLocked ? 'open-' : '' }}alt"></i>
            </button>
          </ng-container>
          <ng-container *ngIf="!(canScheduleLockDays)">
            <div  [ngClass]="{'app-icon--success': !day.isLocked, 'app-icon--danger': day.isLocked }"
                 title="{{ day.isLocked ?  'Day is Locked' : 'Day is Unlocked'  }} Day">
              <i class="fas fa-lock-{{ !day.isLocked ? 'open-' : '' }}alt"></i>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="dispatch-month__week__day__view">
        <dispatch-day-view [data]="day.data"
                            [selectedDate]="day.day"
                            [forView]="myView"
                            [holidays]="holidays"
                            [showHeader]="false"
                            [includeTools]="false"
                            (customEvent)="onCustom($event)">
        </dispatch-day-view>
      </div>
    </div>
</ng-container>
</div>
