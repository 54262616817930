import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IHomEventEmitter } from 'hom-lib/hom-event-emitter';
import { ICustomButton } from '../../../interfaces/i-custom-button';

@Component({
    selector: 'fw-custom-buttons',
    templateUrl: './fw-custom-buttons.component.html'
}) export class CustomButtonsComponent {
    
  @Input() buttons: ICustomButton[];

  @Output() public customEvent: EventEmitter<IHomEventEmitter> = new EventEmitter();

  public emitEvent(item: ICustomButton) {
      const data: IHomEventEmitter = {
          event: item.eventName,
          requestor: 'custom-button',
          action: '',
          data: null
      };
      this.customEvent.emit(data);
  }
}
